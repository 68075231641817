import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './state'

export const messagingChannelState = createFeatureSelector<state.State>('messaging-channel')

export const selectMessagingChannelsLoaded = createSelector(
  messagingChannelState,
  (state: state.State) => state.isLoaded
)
export const selectMessagingChannels = createSelector(messagingChannelState, (state: state.State) => state.data || {})
