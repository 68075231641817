import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { PromptDialogComponent } from './prompt-dialog.component'
import { PromptDialogConfig } from './prompt-dialog.interfaces'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable()
export class PromptDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: PromptDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { ...config },
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(PromptDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
