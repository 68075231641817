import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { AttributeDescriptionResponse } from './model'

export interface State extends EntityState<AttributeDescriptionResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<AttributeDescriptionResponse> = createEntityAdapter<AttributeDescriptionResponse>({
  sortComparer: false,
  selectId: (attr: AttributeDescriptionResponse) => attr.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
