import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReviewService } from './store/service'

const services = [ReviewService]

@NgModule({
  imports: [CommonModule],
  providers: [...services],
})
export class ReviewsLibraryModule {}
