import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { combineLatest, of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators'
import {
  AddHoldEvent,
  AddHoldEventComplete,
  DeleteHoldEvent,
  DeleteHoldEventComplete,
  DeleteHoldEvents,
  DeleteHoldEventsComplete,
  LoadCalendarEventsByRental,
  LoadCalendarEventsByRentalComplete,
  LoadCalendarRange,
  LoadCalendarRangeComplete,
  LoadHoldEvents,
  LoadHoldEventsComplete,
  ReceateRecurringHoldEvents,
  ShareCalendar,
  UpdateHoldEvent,
  UpdateHoldEventComplete,
} from './calendar.actions'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { InfoDialogService, Toaster } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectSharedContentLoaded } from '@tv3/store/shared-content/shared-content.selectors'
import { LoadSharedContent } from '@tv3/store/shared-content/shared-content.actions'
import { SharedContentService } from '@tv3/store/shared-content/shared-content.service'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Injectable()
export class CalendarEffects {
  @Effect()
  loadCalendarEventsByRental$ = this.actions$.pipe(
    ofType(LoadCalendarEventsByRental),
    switchMap(({ id }) =>
      this.calendar.byRental(id).pipe(
        map((events) => LoadCalendarEventsByRentalComplete({ events })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadCalendarEventsByRange$ = this.actions$.pipe(
    ofType(LoadCalendarRange),
    exhaustMap((payload) =>
      this.calendar.byRange(payload).pipe(
        map((calendarEvents) =>
          LoadCalendarRangeComplete({ events: calendarEvents, uid: payload.uid, reset: payload.reset })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect({ dispatch: false })
  shareCalendar$ = this.actions$.pipe(
    ofType(ShareCalendar),
    switchMap(({ request, protect }) => {
      return this.sharedContent.share(request).pipe(
        tap((res: any) => this.toast.success(`Calendar shared successfully.`)),
        tap(({ url }) => {
          const sharedUrl = !protect ? url : url.replace(/&passkey=.+$/, '')

          this.infoDialog.open({
            title:
              '<i class="fal fa-calendar-alt"></i> Share Calendar <a href="https://help.tokeet.com/calendars/your-tokeet-calendar" target="_blank"><i class="fal fa-question-circle"></i></a>',
            body: `Below is the URL to your shared calendar. Please copy this URL and send it to anyone
                who you wish to have access to this calendar.
                ${
                  protect
                    ? 'They will need to enter the password you provided to access the calendar.'
                    : 'Since you did not provide a password anyone with the URL may access your calendar.'
                }
                <br/><br/>
                <a target="_blank" href="${sharedUrl}">${sharedUrl}</a>
                <br/><br/>
                You may disable, or delete this share at anytime by going to your Sharing Settings.`,
            closeText: 'Cancel',
          })
        }),
        tap(() => {
          this.store
            .pipe(select(selectSharedContentLoaded), take(1))
            .subscribe((isLoaded) => isLoaded && this.store.dispatch(LoadSharedContent()))
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  loadHolds$ = this.actions$.pipe(
    ofType(LoadHoldEvents),
    switchMap(({ rentalId }) =>
      this.calendar.allHolds(rentalId).pipe(
        map((events) => LoadHoldEventsComplete({ events })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addHolds$ = this.actions$.pipe(
    ofType(AddHoldEvent),
    switchMap(({ payloads, recurring, holdId }) => {
      return combineLatest([
        recurring ? this.calendar.createRecurring(payloads) : this.calendar.create(payloads),
        holdId ? this.calendar.delete(holdId) : of(''),
      ]).pipe(
        switchMap(([events]) => {
          const actions: any[] = [AddHoldEventComplete({ events })]
          if (holdId) {
            actions.push(DeleteHoldEventComplete({ id: holdId }))
          }
          return actions
        }),
        tap(() => {
          this.amplitudeService.logEvent('add-hold')
        }),
        tap(() => this.toast.success('Events created successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  recurring$ = this.actions$.pipe(
    ofType(ReceateRecurringHoldEvents),
    switchMap(({ payloads, group_id }) => {
      return combineLatest([this.calendar.deleteRecurring(group_id), this.calendar.createRecurring(payloads)]).pipe(
        switchMap(([deletedItems, createdItems]) => {
          return [
            DeleteHoldEventsComplete({ ids: deletedItems.map((t) => t.id) }),
            AddHoldEventComplete({ events: createdItems }),
          ]
        }),
        tap(() => this.toast.success('Events updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateHoldEvent),
    switchMap(({ holdId, payload, group_id }) => {
      if (!payload) return of(ActionSkipped())
      if (!group_id) {
        return this.calendar.update(holdId, payload).pipe(
          map((hold) => UpdateHoldEventComplete({ updates: [{ id: hold.id, changes: hold }] })),
          tap(() => this.toast.success('All events updated successfully!')),
          catchError((error) => of(ActionFailed({ error })))
        )
      } else {
        return this.calendar.updateRecurring(group_id, payload).pipe(
          map((items) => UpdateHoldEventComplete({ updates: items.map((hold) => ({ id: hold.id, changes: hold })) })),
          tap(() => this.toast.success('Event updated successfully!')),
          catchError((error) => of(ActionFailed({ error })))
        )
      }
    })
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteHoldEvent),
    switchMap(({ id, silent, group_id }) => {
      if (group_id) {
        return this.calendar.deleteRecurring(group_id).pipe(
          map((items) => DeleteHoldEventsComplete({ ids: items.map((t) => t.id) })),
          tap(() => !silent && this.toast.success('All events deleted successfully!')),
          catchError((error) => of(ActionFailed({ error })))
        )
      } else {
        return this.calendar.delete(id).pipe(
          map(() => DeleteHoldEventComplete({ id })),
          tap(() => !silent && this.toast.success('Event deleted successfully!')),
          catchError((error) => of(ActionFailed({ error })))
        )
      }
    })
  )

  @Effect()
  deleteBatch$ = this.actions$.pipe(
    ofType(DeleteHoldEvents),
    switchMap(({ ids }) =>
      this.calendar.deleteBatch(ids).pipe(
        map((ids) => DeleteHoldEventsComplete({ ids })),
        tap(() => this.toast.success('Events deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private calendar: CalendarService,
    private store: Store<fromRoot.State>,
    private sharedContent: SharedContentService,
    private infoDialog: InfoDialogService,
    private toast: Toaster,
    private amplitudeService: AmplitudeService
  ) {}
}
