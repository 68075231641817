import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CancelAirbnbBookingDialogComponent } from './cancel-airbnb-booking-dialog.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CancelAirbnbBookingDialogService {
  constructor(private dialog: MatDialog) {}

  open(inquiry: Inquiry, rental: Rental) {
    let defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { inquiry, rental },
    }

    return this.dialog.open(CancelAirbnbBookingDialogComponent, defaultConfig)
  }
}
