import { Component, Injectable, Input, NgZone, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import * as R from 'ramda'
import { get } from 'lodash'
import { TemplateType } from '@tokeet-frontend/templates'
import { AttachmentDialogService } from '@tv3/shared/attachment/attachment-dialog.service'
import { Attachment, defaultTinyMCEOptions, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { secureFileLink } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { TinyMceEditorOptions, TinyMceEditor, TinyMceEditorComponent } from '@tokeet-frontend/tv3-platform'
import { DataDictionaryDialogService } from '@tv3/shared/data-dictionary-dialog/data-dictionary-dialog.service'
import { EditorImageDialogService } from '@tv3/shared/dialogs/editor-image/editor-image-dialog.service'
import { SharedModule } from '@tv3/shared/shared.module'

@Injectable({ providedIn: SharedModule })
export class EditorRichComponentDependencies {
  constructor(
    public store: Store<any>,
    public ngZone: NgZone,
    public attachmentDialog: AttachmentDialogService,
    public editorImageDialog: EditorImageDialogService,
    public dataDictionaryDialog: DataDictionaryDialogService
  ) {}
}

@Component({
  template: '',
})
export abstract class EditorRichComponent extends Destroyable {
  @ViewChild('tinyMceEditor') tinyMceEditor: TinyMceEditorComponent
  @Input() type = TemplateType.Payload
  @Input() contentCtrl: FormControl
  @Input() subjectCtrl: FormControl
  @Input() attachmentsCtrl: FormControl

  @Input() dictToken$: Observable<string>

  editorOptions: TinyMceEditorOptions = {
    toolbar: `${defaultTinyMCEOptions.toolbar} | attachment image | datadic`,
    automatic_uploads: true,
    images_reuse_filename: true,
    file_picker_types: 'image',
    images_upload_handler: function (blobInfo, success, failure) {
      var xhr, formData

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${environment.config.cloudinaryURL}/${environment.config.cloudinaryCloudName}/upload`)

      xhr.onload = function () {
        var json

        if (xhr.status != 200) {
          failure('HTTP Error: ' + xhr.status)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.secure_url != 'string' || typeof json.url != 'string') {
          failure('Invalid JSON: ' + xhr.responseText)
          return
        }

        success(json.secure_url || json.url)
      }

      formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      formData.append('upload_preset', `${environment.config.cloudinaryUploadPreset}`)

      xhr.send(formData)
    },
  }

  isPlain = true
  types = TemplateType

  protected store: Store<any>
  protected ngZone: NgZone
  protected attachmentDialog: AttachmentDialogService
  protected editorImageDialog: EditorImageDialogService
  protected dataDictionaryDialog: DataDictionaryDialogService

  constructor(dependencies: EditorRichComponentDependencies) {
    super()

    this.store = dependencies.store
    this.ngZone = dependencies.ngZone
    this.attachmentDialog = dependencies.attachmentDialog
    this.editorImageDialog = dependencies.editorImageDialog
    this.dataDictionaryDialog = dependencies.dataDictionaryDialog
  }

  onEditorSetup(editor: TinyMceEditor) {
    editor.on('init', () => {
      this.patchEditorWindowOpen(editor)
    })

    editor.ui.registry.addButton('datadic', {
      icon: 'book',
      tooltip: `You may insert data tokens using data dictionary.`,
      onAction: () => this.ngZone.run(() => this.handleDataDict(editor)),
    })

    editor.ui.registry.addButton('attachment', {
      icon: 'paperclip',
      tooltip: `Attachments`,
      onAction: () => this.ngZone.run(() => this.handleAttachment(editor)),
    })
  }

  patchEditorWindowOpen(editor: TinyMceEditor) {
    const _open = editor.windowManager.open

    editor.windowManager.open = (...args) => {
      if (args[0].title === 'Insert/Edit Image') {
        return this.ngZone.run(() => this.handleImage(editor, args[0]))
      }

      return _open.call(editor.windowManager, ...args)
    }
  }

  handleImage(editor: TinyMceEditor, options: any) {
    const data = get(options, ['initialData'])
    const src = get(data, ['src', 'value']) || ''
    const alt = get(data, ['alt']) || ''
    const width = +get(data, ['dimensions', 'width']) || null
    const height = +get(data, ['dimensions', 'height']) || null

    this.editorImageDialog
      .open({
        image: { src, alt, width, height },
      })
      .pipe(untilDestroy(this))
      .subscribe(({ image }) => {
        if (!image) return

        const data = {
          src: image.src || '',
          alt: image.alt || '',
          width: image.width || '',
          height: image.height || '',
        }

        editor.execCommand('mceUpdateImage', false, data)
      })
  }

  handleDataDict(editor: TinyMceEditor) {
    if (this.dictToken$) {
      this.dictToken$.subscribe((tag) => editor.insertContent(tag))
    } else {
      this.dataDictionaryDialog.open().subscribe((tag) => editor.insertContent(tag))
    }
  }

  handleAttachment(editor: TinyMceEditor) {
    this.attachmentDialog.open().subscribe((attachments: Attachment[]) => {
      const items = this.attachmentsCtrl?.value || []
      this.attachmentsCtrl?.setValue([...items, ...attachments])

      const message = this.contentCtrl.value || ''
      const content = this.getAttachmentLinksHTML(attachments)
      editor.resetContent(`${message} ${content}`)
    })
  }

  onDataDictionary() {
    if (!this.isPlain) {
      this.dataDictionaryDialog.open().subscribe((tag) => this.tinyMceEditor.editor.insertContent(tag))
    } else {
      this.dataDictionaryDialog.open().subscribe((tag) => {
        this.contentCtrl.setValue(this.contentCtrl.value + tag)
      })
    }
  }

  onToggleInputType() {
    this.isPlain = !this.isPlain
  }

  private getAttachmentLinksHTML(attachments: Attachment[]) {
    if (!attachments?.length) {
      return ''
    }

    const content = R.reduce(
      (content: string, file: Attachment) => {
        content += `<p>&#x1f4c2;&nbsp;&nbsp;<a href="${secureFileLink(file.url)}" target="_blank" id="${
          file.id
        }" class="attachment">${file.name}</a></p>`
        return content
      },
      '',
      attachments || []
    )

    return `<div class="attachments">${content}</div>`
  }
}
