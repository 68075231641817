import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { DataEntityType, Destroyable, selectOnce, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Subject } from 'rxjs'
import { debounceTime, filter, switchMapTo } from 'rxjs/operators'
import {
  GetEntityTags,
  isEntityTagsLoaded,
  selectEntityTags,
  selectEntityTypeTags,
  SetEntityTags,
  uniqTags,
} from '../../store'

@Component({
  selector: 'app-entity-form-tags',
  templateUrl: './entity-form-tags.component.html',
  styleUrls: ['./entity-form-tags.component.scss'],
})
export class EntityFormTagsComponent extends Destroyable implements OnInit {
  @Input() ctrl: FormControl = new FormControl([])
  @Input() type: DataEntityType
  @Input() id: string
  @Input() tags: string[] = [] // back compatible, existing tags
  @Input() label = 'Tags'
  @Input() placeholder = 'Type to pick or create tag'
  @Input() required = false

  availableTags: string[] = []

  @Input() autoSave = false
  @Input() showModifiedMsg = false

  @Output() valueChange = new EventEmitter<string[]>()

  save$ = new Subject<string[]>()
  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    if (this.id && this.type) {
      this.store.dispatch(GetEntityTags({ entityType: this.type, entityId: this.id }))
    }
    this.store
      .pipe(
        select(isEntityTagsLoaded),
        filter((isLoaded) => isLoaded),
        switchMapTo(this.store.pipe(select(selectEntityTags(this.type, this.id))))
      )
      .subscribe((tags) => {
        this.ctrl.setValue(uniqTags([...tags, ...(this.tags || [])]))
      })
    this.store.pipe(select(selectEntityTypeTags(this.type)), untilDestroy(this)).subscribe((data) => {
      this.availableTags = data
    })

    this.save$
      .pipe(
        untilDestroy(this),
        debounceTime(1000),
        filter(() => this.autoSave)
      )
      .subscribe((tags) => {
        this.save()
      })
  }

  onChange(tags: string[]) {
    this.save$.next(tags)
    this.valueChange.emit(tags)
  }

  add(tag: string) {
    this.ctrl.setValue(uniqTags([...(this.ctrl.value || []), tag]))
    this.onChange(this.ctrl.value)
  }

  getTags() {
    return this.ctrl.value || []
  }

  save() {
    const tags = this.getTags()

    this.store.dispatch(
      SetEntityTags({ entityId: this.id, entityType: this.type, tags, silent: !this.showModifiedMsg })
    )
  }
}
