import { Injectable } from '@angular/core'
import { CostEstimatorDialogComponent } from './cost-estimator-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CostEstimatorDialogService {
  constructor(private modalService: DialogService) {}

  open() {
    return this.modalService.openVariableDialog(CostEstimatorDialogComponent, {
      width: '800px',
      height: 'auto',
    })
  }
}
