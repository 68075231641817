import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  Channel,
  ChannelAdjustment,
  ConfirmDialogService,
  deleteChannelAdjustment,
  Destroyable,
  selectChannelEntities,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { ChannelAutomationDialogService } from '../automation-dialog/channel-automation-dialog.service'

@Component({
  selector: 'app-channel-automations-grid',
  templateUrl: './channel-automations-grid.component.html',
  styleUrls: ['./channel-automations-grid.component.scss'],
})
export class ChannelAutomationsGridComponent extends Destroyable implements OnInit {
  @Input() items: ChannelAdjustment[] = []

  channelsById: Record<string, Channel> = {}

  constructor(
    private store: Store<any>,
    private confirmDialog: ConfirmDialogService,
    private invoiceAutomationDialog: ChannelAutomationDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectChannelEntities), untilDestroy(this)).subscribe((data) => {
      this.channelsById = data
    })
  }

  onEdit(item: ChannelAdjustment) {
    this.invoiceAutomationDialog.open({ channelAdjustment: item })
  }

  onDelete(item: ChannelAdjustment) {
    this.confirmDialog
      .confirm({
        title: 'Delete this automation?',
        body: 'Are you sure you want to delete this automation?',
      })
      .subscribe(() => {
        this.store.dispatch(deleteChannelAdjustment({ id: item.pkey }))
      })
  }
}
