import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CustomDomainResponse, CustomDomainSSLCertificatePayload, DomainId } from './ssl.model'

@Injectable()
export class SSLService {
  constructor(private http: HttpClient) {}

  getDomains() {
    return this.http.get<CustomDomainResponse[]>('@ssl/domains')
  }

  getDomainById(domain: string) {
    return this.http.get<CustomDomainResponse>(`@ssl/domains/${domain}`)
  }

  createOrGetDomain(domain: DomainId) {
    return this.http.post<CustomDomainResponse>(`@ssl/domains/${domain}`, {})
  }

  deleteDomain(domain: DomainId) {
    return this.http.delete<CustomDomainResponse>(`@ssl/domains/${domain}`)
  }

  requestCertificate(domain: DomainId) {
    return this.http.post<CustomDomainResponse>(`@ssl/certificates/${domain}/request`, {})
  }

  setCustomCertificate(domain: DomainId, data: CustomDomainSSLCertificatePayload) {
    return this.http.put<CustomDomainResponse>(`@ssl/certificates/${domain}/custom`, data)
  }

  deleteCertificate(domain: DomainId) {
    return this.http.delete<CustomDomainResponse>(`@ssl/certificates/${domain}`, {})
  }
}
