import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators'
import { deleteRentalComplete } from '../rental/rental.actions'
import { CleanGroupRateService } from './clean-group-rate.service'
import { ActionSkipped } from '../utility'

@Injectable()
export class GroupRateEffects {
  @Effect()
  cleanGroupRatesOnRentalDeleted$ = this.actions$.pipe(
    ofType(deleteRentalComplete),
    switchMap(({ id }) => this.cleanGroupRateService.cleanOnRentalDeleted(id)),
    map(() => ActionSkipped())
  )

  constructor(private actions$: Actions, private cleanGroupRateService: CleanGroupRateService) {}
}
