import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import { formatDate, User } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'

@Injectable()
export class DownloadUserSettingsDialogService {
  constructor(private dialog: MatDialog) {}

  private getConfig(data: User[]): GenericExportDialogConfig {
    return {
      title: 'Download User Settings to CSV',
      description: 'You are about to download all user settings within your current filter.',
      columns: [
        { name: 'Name', field: 'name' },
        { name: 'Created', field: (t: User) => formatDate(t.created, 'date', false) },
        { name: 'Role', field: 'roleView' },
        { name: 'Email', field: 'email' },
        { name: 'Phone', field: 'phone' },
        { name: 'Country', field: 'countryView' },
        { name: 'Last Login', field: (t: User) => formatDate(t.lastLogin, 'datetime', false) },
      ],
      exportData: data,
      filename: `Users - ${moment().format('MM_DD_YYYY')}`,
      columnCustomizable: true,
    }
  }

  open(users: User[]) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        config: this.getConfig(users),
      },
    }

    return this.dialog.open(GenericExportDialogComponent, defaultConfig)
  }
}
