import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { GatewayTypeEffects } from './gateway-type.effects'
import * as fromPaymentGatewayType from './gateway-type.reducer'
import { GatewayTypeService } from './gateway-type.service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('gateway-types', fromPaymentGatewayType.gatewayTypeReducer),
    EffectsModule.forFeature([GatewayTypeEffects]),
  ],
  declarations: [],
  providers: [GatewayTypeService],
})
export class GatewayTypeModule {}
