import { Inject, Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators'
import { BehaviorSubject, of } from 'rxjs'
import { Store } from '@ngrx/store'
import { AccountService } from './account.service'
import { ActionFailed } from '../utility'
import {
  setAccountTaxes,
  setAccountTaxexComplete,
  loadAccount,
  loadAccountComplete,
  updateAccount,
  updateAccountBillingAddresses,
  updateAccountBillingAddressesComplete,
  updateAccountComplete,
  updateDomain,
  toggleAccountMFA,
  toggleAccountMFAComplete,
} from '../account/account.actions'
import { CURRENT_ACCOUNT_ID } from '../../tokens'

@Injectable()
export class AccountEffects {
  @Effect()
  loadAccount$ = this.actions$.pipe(
    ofType(loadAccount),
    exhaustMap(() =>
      this.account.get().pipe(
        map((account) => loadAccountComplete({ account })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateAccount$ = this.actions$.pipe(
    ofType(updateAccount),
    concatMap(({ account, updateAll }) =>
      this.account.update({ account, updateAll }).pipe(
        map((account) => updateAccountComplete({ update: { id: account.id, changes: account } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateMFA$ = this.actions$.pipe(
    ofType(toggleAccountMFA),
    concatMap(({ enable_2fa }) =>
      this.account.updateMFA(enable_2fa).pipe(
        map((account) => toggleAccountMFAComplete({ update: { id: this.accountId$.value, changes: { enable_2fa } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addAccountTax$ = this.actions$.pipe(
    ofType(setAccountTaxes),
    concatMap(({ taxes }) =>
      this.account.updateTaxes({ taxes }).pipe(
        map((account) => setAccountTaxexComplete({ update: { id: account.id, changes: account } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  setAccountBillingAddresses$ = this.actions$.pipe(
    ofType(updateAccountBillingAddresses),
    concatMap(({ addresses }) =>
      this.account.updateBillingAddress(addresses).pipe(
        map((account) => updateAccountBillingAddressesComplete({ update: { id: account.id, changes: account } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateDomain$ = this.actions$.pipe(
    ofType(updateDomain),
    concatMap(({ domain, noConfirm }) => (noConfirm ? of(domain) : this.account.confirmDomainChange(domain))),
    concatMap((domain) => this.account.checkDomainLocal(domain)),
    concatMap((domain) => this.account.checkDomainRemote(domain)),
    concatMap((domain) =>
      this.account.updateDomain(domain).pipe(
        map((account) => updateAccountComplete({ update: { id: account.id, changes: account } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private account: AccountService,
    @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>
  ) {}
}
