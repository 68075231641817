<ng-container [ngTemplateOutlet]="tile ? box : list"></ng-container>

<ng-template #list>
  <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>

  <ng-container *ngIf="!isLoading">
    <div *ngFor="let item of devices">
      {{ item.name }}:

      <ng-container *ngIf="codesByDeviceId[item.deviceId] as code; else newCode">
        <span
          class="badge badge-secondary-warning text-sm"
          ngxClipboard
          [cbContent]="code.access.code"
          matTooltip="click to copy"
          matTooltipPosition="above"
        >
          {{ code.access.code }}
        </span>
      </ng-container>
      <ng-template #newCode>
        <ng-template [ngTemplateOutlet]="newCodeTmpl" [ngTemplateOutletContext]="{ device: item }"></ng-template>
      </ng-template>
    </div>
    <a href="javascript:;" *ngIf="!devices.length" routerLink="/devices" (click)="link.emit()" class="text-info">
      Link Device</a
    >
  </ng-container>
</ng-template>

<ng-template #box>
  <div class="box-details">
    <app-loading-section *ngIf="isLoading"></app-loading-section>

    <div class="box-dsc-row row" *ngFor="let item of devices">
      <div class="col-8 text-truncate">{{ item.name }}</div>
      <div class="col-4">
        <ng-container *ngIf="codesByDeviceId[item.deviceId] as code; else newCode">
          <span
            class="badge badge-secondary-warning text-sm"
            ngxClipboard
            [cbContent]="code.access.code"
            matTooltip="click to copy"
            matTooltipPosition="above"
          >
            {{ code.access.code }}
          </span>
        </ng-container>
      </div>
      <ng-template #newCode>
        <ng-template [ngTemplateOutlet]="newCodeTmpl" [ngTemplateOutletContext]="{ device: item }"></ng-template>
      </ng-template>
    </div>
    <a
      href="javascript:;"
      *ngIf="!isLoading && !devices.length"
      routerLink="/devices"
      (click)="link.emit()"
      class="text-info"
    >
      <i class="cs-icon cs-icon-link"></i> Link Device</a
    >
  </div>
</ng-template>

<ng-template #newCodeTmpl let-device="device">
  <a href="javascript:;" (click)="onNewCode(device)" class="text-info"> New Code</a>
</ng-template>
