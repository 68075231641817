<div class="main-content">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">{{ website.type }} Images</h6>
      <app-page-help
        articleId="-"
        videoId="-"
        infoTooltip="You may upload additional images to be used on your website. Your rental images are used by default if no images are specified here. If you upload and select a primary website image it will be used as your homepage main image."
      ></app-page-help>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onUpload()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-upload"></span> Upload
    </button>
  </div>
  <!-- image gallery -->
  <div class="container-box details-box overflow-hidden">
    <div class="box-body">
      <h6 class="section-title border-top-0">Manage your website images.</h6>
      <div class="row px-3">
        <div class="col-sm-12 gallery">
          <div ngxDroppable [model]="images" (drop)="onDrop()" class="row">
            <div *ngFor="let image of images; let i = index" class="col-sm-3 pb-3" ngxDraggable [model]="image">
              <div [ngClass]="{ 'primary-item-container': !!image?.primary }">
                <div class="gallery-img gallery-img-container bg-default rounded clickable" (click)="onOpenImage(i)">
                  <img [src]="getImageThumbUrl(image?.secureUrl)" alt="" />
                  <ul class="image-actions" (click)="$event.stopPropagation()">
                    <li *ngIf="image?.primary"><span class="image-type-label">Primary Image</span></li>
                    <li *ngIf="image?.favicon"><span class="image-type-label">Favicon Image</span></li>
                    <li *ngIf="image?.logo"><span class="image-type-label">Logo Image</span></li>
                    <li *ngIf="!image?.primary && !image?.favicon && !image?.logo">
                      <mat-checkbox class="image-action-btn primary-btn" (change)="makeImagePrimary(image)">
                        Mark as Primary
                      </mat-checkbox>
                    </li>
                    <li *ngIf="!image?.primary && !image?.favicon && !image?.logo">
                      <mat-checkbox class="image-action-btn favicon-btn" (change)="makeImageFavicon(image)">
                        Mark as Favicon
                      </mat-checkbox>
                    </li>
                    <li *ngIf="!image?.primary && !image?.favicon && !image?.logo">
                      <mat-checkbox class="image-action-btn logo-btn" (change)="makeImageLogo(image)">
                        Mark as Logo
                      </mat-checkbox>
                    </li>
                    <li>
                      <span class="image-action-btn remove-image-btn" (click)="deleteImage(image)">
                        <i class="cs-icon cs-icon-trash"></i>
                      </span>

                      <span class="image-action-btn image-link-btn" (click)="onShowUrl(image)">
                        <i class="fal fa-link"></i>
                      </span>
                    </li>
                  </ul>
                  <span class="primary-mark" *ngIf="image?.primary"><i class="fal fa-flag"></i></span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="images?.length === 0">
            <p *ngIf="website.type === websiteTypes.RentalWebsite">
              You have not uploaded any images for your website, please click UPLOAD button to add an image for your
              website.
            </p>
            <p *ngIf="website.type === websiteTypes.GuestPortal">
              You have not uploaded any images for your guest portal, please click UPLOAD button to add an image for
              your guest portal.
            </p>
            <p *ngIf="website.type === websiteTypes.BookingEngine">
              You have not uploaded any images for your booking engine, please click UPLOAD button to add an image for
              your booking engine.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="box-body" *ngIf="website.type === websiteTypes.RentalWebsite">
      <h6 class="section-title">Manage your rental images.</h6>
      <div class="row px-3">
        <div class="col-sm-12">
          If you would like to change the images on your website please update your rental images.
          <a href="javascript:;" (click)="onRentalImages()">Click Here</a>
        </div>
      </div>
    </div>
  </div>
</div>
