import { Injectable } from '@angular/core'
import { filter, take } from 'rxjs/operators'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { ConfirmExtraDialogComponent } from './confirm-extra-dialog/confirm-extra-dialog.component'
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component'
import { ConfirmDialogParams, ConfirmDialogStatus } from './confirm-dialog.interfaces'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal'

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  private open<C, R = ConfirmDialogStatus>(component: ComponentType<C>, data?: ConfirmDialogParams) {
    const defaultData = {
      title: 'Delete?',
      body: 'Are you sure you want to delete? This cannot be undone.',
      extra: '',
      confirmText: 'Yes, proceed',
      cancelText: 'No, maybe not',
      cancelBtnClass: data?.isDangerous === true ? 'btn btn-secondary-info' : 'btn btn-secondary-danger',
      confirmBtnClass: data?.isDangerous === true ? 'btn btn-secondary-danger' : 'btn btn-secondary-info',
    }

    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { ...defaultData, ...data },
      disableClose: true,
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open<C, ConfirmDialogParams, R>(component, defaultConfig).afterClosed().pipe(take(1))
  }

  public openRegular(data?: ConfirmDialogParams) {
    return this.open(ConfirmDialogComponent, data)
  }

  public confirm(data?: ConfirmDialogParams) {
    return this.open(ConfirmDialogComponent, data).pipe(filter((status) => status === ConfirmDialogStatus.Confirmed))
  }

  public confirmExtra(data?: ConfirmDialogParams, emitAll = false) {
    return this.open<
      ConfirmExtraDialogComponent,
      {
        status: ConfirmDialogStatus
        isChecked: boolean
      }
    >(ConfirmExtraDialogComponent, data).pipe(
      filter((result) => (emitAll ? true : result && result.status === ConfirmDialogStatus.Confirmed))
    )
  }

  public confirmDelete(data?: ConfirmDialogParams) {
    return this.open<ConfirmDeleteDialogComponent>(ConfirmDeleteDialogComponent, {
      confirmBtnClass: 'btn btn-secondary-danger',
      cancelBtnClass: 'btn btn-secondary-info',
      ...data,
    }).pipe(filter((status) => status === ConfirmDialogStatus.Confirmed))
  }
}
