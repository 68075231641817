import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Pipe({
  name: 'svgIcon',
})
export class SvgIconPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry) {}

  transform(url: string): Observable<SafeHtml> {
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url)
    return this.matIconRegistry
      .getSvgIconFromUrl(safeUrl)
      .pipe(map((svg) => this.domSanitizer.bypassSecurityTrustHtml(svg.outerHTML)))
  }
}
