import { NgModule } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { CommonModule } from '@angular/common'
import { TemplateDialogComponent } from '@tv3/containers/templates/template-dialog/template-dialog.component'
import { SaveTemplateDialogComponent } from '@tv3/containers/templates/save-template-dialog/save-template-dialog.component'
import { EditorEmailComponent } from '@tv3/containers/templates/template-editor/editor-email/editor-email.component'
import { EditorPushNotificationComponent } from '@tv3/containers/templates/template-editor/editor-push-notification/editor-push-notification.component'
import { EditorHttpComponent } from '@tv3/containers/templates/template-editor/editor-http/editor-http.component'
import { TemplateEditorComponent } from './template-editor/template-editor.component'

const components = [
  //
  EditorEmailComponent,
  EditorPushNotificationComponent,
  EditorHttpComponent,
  TemplateEditorComponent,
  TemplateDialogComponent,
  SaveTemplateDialogComponent,
]

@NgModule({
  imports: [SharedModule, CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class TemplatesSharedModule {}
