import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { DiscountCode } from '@tokeet-frontend/discount-codes'
import { select, Store } from '@ngrx/store'
import { selectInquiriesByIds } from '@tv3/store/inquiry/inquiry.selectors'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { NotificationDetailService } from '@tv3/services/notification-detail.service'

@Component({
  selector: 'app-discount-code-bookings',
  templateUrl: './discount-code-bookings.component.html',
  styleUrls: ['./discount-code-bookings.component.scss'],
})
export class DiscountCodeBookingsComponent extends Destroyable implements OnInit {
  @Input() item: Partial<DiscountCode>

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['date', 'action']

  dataSource = new MatTableDataSource<Inquiry>()

  isEmptyTable$ = isEmptyTable(this.dataSource)

  tableType = TableType.DiscountCodeActivities

  constructor(private store: Store<any>, private notificationDetailService: NotificationDetailService) {
    super()
  }

  ngOnInit(): void {
    this.dataSource.data = []
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.store.pipe(select(selectInquiriesByIds(this.item.bookings)), untilDestroy(this)).subscribe((items) => {
      this.dataSource.data = items
    })
  }

  onDetails(item: Inquiry) {
    this.notificationDetailService.tryToOpenInquiryDetail(item.id)
  }
}
