import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import {
  Channel,
  ChannelNameTokens,
  ConfirmDialogService,
  ConnectedChannelAccountResponse,
  DialogService,
  DisconnectChannel,
  DisconnectChannelComplete,
  removeRateMappingForChannel,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { RemoveLocalConnectionsByIds } from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { SelectChannelAccountDialogService } from '@tv3/containers/channels/channel-connect/dialogs/select-channel-account/select-channel-account-dialog.service'
import { selectConnectionsByChannelId } from '@tv3/store/connection/connection.selectors'
import { Connection } from '@tv3/store/connection/connection.model'
import { TiketChannelService } from '@tokeet-frontend/channels'
import { ChannelsOverlayComponent } from '../shared/channels-overlay/channels-overlay.component'

@Injectable({
  providedIn: SharedModule,
})
export class ChannelHelperService {
  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private toaster: Toaster,
    private dialogService: DialogService,
    private confirmDialog: ConfirmDialogService,
    private selectChannelAccountDialog: SelectChannelAccountDialogService,
    private tiketChannel: TiketChannelService
  ) {}

  connectChannel(channelType?: 'api' | 'ical' | 'custom') {
    return this.dialogService.openDrawer(ChannelsOverlayComponent, {
      panelClass: 'drawer-full-70',
      data: { channelType },
    })
  }

  disconnect(channel: Channel) {
    return this.selectChannelAccountDialog.open({ channel }).pipe(
      switchMap(({ account }) => this.disconnectAccount(channel, account)),
      take(1)
    )
  }

  disconnectAccount(channel: Channel, channelAccount) {
    const channelId = channelAccount.channelId || channel.id
    const channelName = channelAccount.name || channel.name
    const propertyId = channelAccount.propertyId

    return this.confirmDialog
      .confirm({
        title: 'Disconnect this Account?',
        body: `This will unlink all rentals connected to this channel and disconnect this account from your AdvanceCM account. <br/>
        Are you sure you want to Disconnect this account?`,
      })
      .pipe(
        switchMap(() => {
          if (channel.name === ChannelNameTokens.Tiket) {
            return this.tiketChannel
              .disconnect(propertyId)
              .pipe(tap(() => this.toaster.success('Account disconnected successfully.')))
          } else {
            channelAccount = channelAccount as ConnectedChannelAccountResponse
            this.store.dispatch(
              DisconnectChannel({
                channelName,
                channelId,
              })
            )
            return this.actions$.pipe(ofType(DisconnectChannelComplete.type))
          }
        }),
        switchMap(() =>
          this.store.pipe(
            select(selectConnectionsByChannelId, { id: channelId }),
            tap((connections: Connection[]) => {
              // remove local connection data
              this.store.dispatch(
                RemoveLocalConnectionsByIds({
                  connectionIds: R.map((c) => c.id, connections),
                })
              )
              this.store.dispatch(removeRateMappingForChannel({ channelId: channelId }))
            })
          )
        ),
        take(1)
      )
  }
}
