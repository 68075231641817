import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { aiSettingsReducer } from './ai-settings.reducer'
import { EffectsModule } from '@ngrx/effects'
import { AISettingsEffects } from './ai-settings.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('aiSettings', aiSettingsReducer),
    EffectsModule.forFeature([AISettingsEffects]),
  ],
  declarations: [],
})
export class AISettingsStoreModule {}
