export interface BDCFreeCancelOption {
  value: number
  days: number
  label: string
}

export interface BDCNonFreeCancelOption {
  value: number
  percent?: number
  fullChargeDays?: number
  label: string
}

export type BDCCancelOption = BDCFreeCancelOption | BDCNonFreeCancelOption
export const maxSelectedCancelPolicies = 6

export const bdcNonFreeFullChargeCancelOption: BDCNonFreeCancelOption = {
  label: 'The guest will be charged the total price if they cancel.',
  value: 1,
}

export const bdcFreeCancelOptions: BDCFreeCancelOption[] = [
  {
    value: 12,
    days: 42,
    label:
      'The guest can cancel free of charge until 42 days before arrival. The guest will be charged the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 14,
    days: 14,
    label:
      'The guest can cancel free of charge until 14 days before arrival. The guest will be charged the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 15,
    days: 7,
    label:
      'The guest can cancel free of charge until 7 days before arrival. The guest will be charged the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 16,
    days: 5,
    label:
      'The guest can cancel free of charge until 5 days before arrival. The guest will be charged the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 29,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 days before arrival. The guest will be charged the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 30,
    days: 14,
    label:
      'The guest can cancel free of charge until 14 days before arrival. The guest will be charged the first night if they cancel in the 14 days before arrival.',
  },
  {
    value: 31,
    days: 3,
    label:
      'The guest can cancel free of charge until 3 days before arrival. The guest will be charged the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 33,
    days: 3,
    label:
      'The guest can cancel free of charge until 3 days before arrival. The guest will be charged the first night if they cancel in the 3 days before arrival.',
  },
  {
    value: 34,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 days before arrival. The guest will be charged the first night if they cancel in the 2 days before arrival.',
  },
  {
    value: 36,
    days: 1,
    label:
      'The guest can cancel free of charge until 1 day before arrival. The guest will be charged the first night if they cancel within 1 day before arrival.',
  },
  {
    value: 37,
    days: 7,
    label:
      'The guest can cancel free of charge until 7 days before arrival. The guest will be charged the first night if they cancel in the 7 days before arrival.',
  },
  {
    value: 38,
    days: 1,
    label:
      'The guest can cancel free of charge until 1 day before arrival. The guest will be charged the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 41,
    days: 6,
    label:
      'The guest can cancel free of charge until 6 pm on the day of arrival. The guest will be charged the total price if they cancel after 6 pm on the day of arrival.',
  },
  {
    value: 42,
    days: 6,
    label:
      'The guest can cancel free of charge until 6 pm on the day of arrival. The guest will be charged the first night if they cancel after 6 pm on the day of arrival.',
  },
  {
    value: 43,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 pm on the day of arrival. The guest will be charged the first night if they cancel after 2 pm on the day of arrival.',
  },
  {
    value: 44,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 pm on the day of arrival. The guest will be charged 30% of the total price if they cancel after 2 pm on the day of arrival.',
  },
  {
    value: 45,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 pm on the day of arrival. The guest will be charged 50% of the total price if they cancel after 2 pm on the day of arrival.',
  },
  {
    value: 46,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 pm on the day of arrival. The guest will be charged 70% of the total price if they cancel after 2 pm on the day of arrival.',
  },
  {
    value: 47,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 pm on the day of arrival. The guest will be charged the total price if they cancel after 2 pm on the day of arrival.',
  },
  {
    value: 48,
    days: 6,
    label:
      'The guest can cancel free of charge until 6 pm on the day of arrival. The guest will be charged 30% of the total price if they cancel after 6 pm on the day of arrival.',
  },
  {
    value: 49,
    days: 6,
    label:
      'The guest can cancel free of charge until 6 pm on the day of arrival. The guest will be charged 50% of the total price if they cancel after 6 pm on the day of arrival.',
  },
  {
    value: 50,
    days: 6,
    label:
      'The guest can cancel free of charge until 6 pm on the day of arrival. The guest will be charged 70% of the total price if they cancel after 6 pm on the day of arrival.',
  },
  {
    value: 51,
    days: 1,
    label:
      'The guest can cancel free of charge until 1 day before arrival. The guest will be charged 30% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 52,
    days: 1,
    label:
      'The guest can cancel free of charge until 1 day before arrival. The guest will be charged 50% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 53,
    days: 1,
    label:
      'The guest can cancel free of charge until 1 day before arrival. The guest will be charged 70% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 54,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 days before arrival. The guest will be charged 30% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 55,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 days before arrival. The guest will be charged 50% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 56,
    days: 2,
    label:
      'The guest can cancel free of charge until 2 days before arrival. The guest will be charged 70% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 57,
    days: 3,
    label:
      'The guest can cancel free of charge until 3 days before arrival. The guest will be charged 30% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 58,
    days: 3,
    label:
      'The guest can cancel free of charge until 3 days before arrival. The guest will be charged 50% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 59,
    days: 3,
    label:
      'The guest can cancel free of charge until 3 days before arrival. The guest will be charged 70% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 60,
    days: 5,
    label:
      'The guest can cancel free of charge until 5 days before arrival. The guest will be charged the first night if they cancel in the 5 days before arrival.',
  },
  {
    value: 61,
    days: 5,
    label:
      'The guest can cancel free of charge until 5 days before arrival. The guest will be charged 30% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 62,
    days: 5,
    label:
      'The guest can cancel free of charge until 5 days before arrival. The guest will be charged 50% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 63,
    days: 5,
    label:
      'The guest can cancel free of charge until 5 days before arrival. The guest will be charged 70% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 64,
    days: 7,
    label:
      'The guest can cancel free of charge until 7 days before arrival. The guest will be charged 30% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 65,
    days: 7,
    label:
      'The guest can cancel free of charge until 7 days before arrival. The guest will be charged 50% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 66,
    days: 7,
    label:
      'The guest can cancel free of charge until 7 days before arrival. The guest will be charged 70% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 67,
    days: 14,
    label:
      'The guest can cancel free of charge until 14 days before arrival. The guest will be charged 30% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 68,
    days: 14,
    label:
      'The guest can cancel free of charge until 14 days before arrival. The guest will be charged 50% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 69,
    days: 14,
    label:
      'The guest can cancel free of charge until 14 days before arrival. The guest will be charged 70% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 70,
    days: 30,
    label:
      'The guest can cancel free of charge until 30 days before arrival. The guest will be charged the first night if they cancel in the 30 days before arrival.',
  },
  {
    value: 71,
    days: 30,
    label:
      'The guest can cancel free of charge until 30 days before arrival. The guest will be charged 30% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 72,
    days: 30,
    label:
      'The guest can cancel free of charge until 30 days before arrival. The guest will be charged 50% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 73,
    days: 30,
    label:
      'The guest can cancel free of charge until 30 days before arrival. The guest will be charged 70% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 74,
    days: 30,
    label:
      'The guest can cancel free of charge until 30 days before arrival. The guest will be charged the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 113,
    days: 42,
    label:
      'The guest can cancel free of charge until 42 days before arrival. The guest will be charged the first night if they cancel in the 42 days before arrival.',
  },
  {
    value: 114,
    days: 42,
    label:
      'The guest can cancel free of charge until 42 days before arrival. The guest will be charged 30% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 115,
    days: 42,
    label:
      'The guest can cancel free of charge until 42 days before arrival. The guest will be charged 50% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 116,
    days: 42,
    label:
      'The guest can cancel free of charge until 42 days before arrival. The guest will be charged 70% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 117,
    days: 60,
    label:
      'The guest can cancel free of charge until 60 days before arrival. The guest will be charged the first night if they cancel in the 60 days before arrival.',
  },
  {
    value: 118,
    days: 60,
    label:
      'The guest can cancel free of charge until 60 days before arrival. The guest will be charged 30% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 119,
    days: 60,
    label:
      'The guest can cancel free of charge until 60 days before arrival. The guest will be charged 50% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 120,
    days: 60,
    label:
      'The guest can cancel free of charge until 60 days before arrival. The guest will be charged 70% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 121,
    days: 60,
    label:
      'The guest can cancel free of charge until 60 days before arrival. The guest will be charged the total price if they cancel in the 60 days before arrival.',
  },
]

export const bdcNonFreeCancelOptions: BDCNonFreeCancelOption[] = [
  {
    value: 2,
    percent: 50,
    fullChargeDays: 42,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 4,
    percent: 50,
    fullChargeDays: 14,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 5,
    percent: 50,
    fullChargeDays: 7,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 6,
    percent: 50,
    fullChargeDays: 5,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 7,
    percent: 30,
    fullChargeDays: 42,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 9,
    percent: 30,
    fullChargeDays: 14,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 10,
    percent: 30,
    fullChargeDays: 7,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 11,
    percent: 30,
    fullChargeDays: 5,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 75,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 76,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 77,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 78,
    percent: 70,
    fullChargeDays: 1,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 79,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 80,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 81,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 82,
    percent: 70,
    fullChargeDays: 2,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 83,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 84,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 85,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 86,
    percent: 70,
    fullChargeDays: 3,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 87,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 88,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 89,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 90,
    percent: 70,
    fullChargeDays: 5,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 5 days before arrival.',
  },
  {
    value: 91,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 92,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 93,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 94,
    percent: 70,
    fullChargeDays: 7,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 7 days before arrival.',
  },
  {
    value: 95,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 96,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 97,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 98,
    percent: 70,
    fullChargeDays: 14,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 14 days before arrival.',
  },
  {
    value: 99,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 100,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 101,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 102,
    percent: 70,
    fullChargeDays: 30,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 122,
    percent: 30,
    fullChargeDays: 1,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 123,
    percent: 50,
    fullChargeDays: 1,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel within 1 day before arrival.',
  },
  {
    value: 124,
    percent: 30,
    fullChargeDays: 2,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 125,
    percent: 50,
    fullChargeDays: 2,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 2 days before arrival.',
  },
  {
    value: 126,
    percent: 30,
    fullChargeDays: 3,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 127,
    percent: 50,
    fullChargeDays: 3,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 3 days before arrival.',
  },
  {
    value: 128,
    percent: 30,
    fullChargeDays: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 129,
    percent: 50,
    fullChargeDays: 30,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 30 days before arrival.',
  },
  {
    value: 130,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 131,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 132,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 133,
    percent: 70,
    fullChargeDays: 42,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 42 days before arrival.',
  },
  {
    value: 134,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 135,
    percent: 30,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and a further 40% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 136,
    percent: 30,
    fullChargeDays: 60,
    label:
      'The guest will be charged 30% of the total price if they cancel after reservation and the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 137,
    percent: 50,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and a further 20% of the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 138,
    percent: 50,
    fullChargeDays: 60,
    label:
      'The guest will be charged 50% of the total price if they cancel after reservation and the total price if they cancel in the 60 days before arrival.',
  },
  {
    value: 139,
    percent: 70,
    fullChargeDays: 60,
    label:
      'The guest will be charged 70% of the total price if they cancel after reservation and the total price if they cancel in the 60 days before arrival.',
  },
]
