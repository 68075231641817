import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { AddTagsToInquiries } from '@tv3/store/inquiry/inquiry-fields.actions'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { AddNewTags, LoadTags, selectTags } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-add-tags-dialog',
  templateUrl: './add-tags-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-tags-dialog.component.scss'],
})
export class AddTagsDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    tags: [[]],
  })

  tags: string[] = []

  constructor(
    public dialogRef: MatDialogRef<AddTagsDialogComponent>,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { inquiries: Inquiry[] },
    private store: Store<fromRoot.State>
  ) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(LoadTags())

    const tags = R.pipe(
      R.map((i: Inquiry) => R.pathOr([], ['attributes', 'tags'], i)),
      R.flatten,
      R.uniq,
      R.map(R.pipe(R.when(R.is(Number), R.toString), R.toLower))
    )(this.data.inquiries || [])

    this.store.pipe(select(selectTags('inquiry')), untilDestroy(this)).subscribe((tags) => {
      this.tags = this.processTags(tags)
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue()

    const tags = this.processTags(formValues.tags)

    this.store.dispatch(AddTagsToInquiries({ inquiries: this.data.inquiries, tags }))

    const newTags = this.processTags(R.filter((tag) => !R.contains(tag, this.tags), tags))

    if (!R.isEmpty(newTags)) {
      this.store.dispatch(AddNewTags({ payload: { tags: newTags, type: 'inquiry' } }))
    }

    this.close()
  }

  addNewTag = (tag: string) => {
    return tag
  }

  private processTags(tags: string[]): string[] {
    return R.map(R.pipe(R.when(R.is(Number), R.toString), R.toLower))(tags || [])
  }
}
