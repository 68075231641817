import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  ConfirmDialogService,
  Rental,
  selectRentalById,
  Toaster,
  RentalService,
  updateRentalTaxesComplete,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { Connection, getRealChannelId } from '@tv3/store/connection/connection.model'
import { selectChannelById } from '@tokeet-frontend/tv3-platform'
import { switchMap, take, map, tap } from 'rxjs/operators'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { AirbnbTaxAmountType } from '@tv3/store/connection/connection.types'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { TaxModalities } from '@tokeet/cost-resolver'
import { TaxV3 } from '@tokeet-frontend/tv3-platform'
import { AirbnbPassThroughOccupancyTax } from '@tokeet-frontend/channels'

@Component({
  selector: 'app-airbnb-tax-settings-dialog',
  templateUrl: './airbnb-tax-settings-dialog.component.html',
  styleUrls: ['./airbnb-tax-settings-dialog.component.scss'],
})
export class AirbnbTaxSettingsDialogComponent extends Destroyable implements OnInit {
  /**
   * TODO - check these two fiels in airbnb documentation
   */
  payload = [
    {
      eligible_for_pass_through_taxes: [true, false],
      pass_through_taxes_collection_type: 'INELIGIBLE',
    },
  ]

  form = this.fb.group({
    taxes: this.fb.array([]),
  })

  rental: Rental

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  currency = 'USD'

  constructor(
    public dialogRef: MatDialogRef<AirbnbTaxSettingsDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<any>,
    private rentalService: RentalService,
    private confirmDialog: ConfirmDialogService,
    private connectionAirbnbService: ConnectionAirbnbService,
    @Inject(MAT_DIALOG_DATA) public data: { connection: Connection }
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectChannelById, { id: getRealChannelId(this.data.connection.channelId) }),
        switchMap((channel) =>
          this.connectionAirbnbService.getPriceSettingsV2(
            this.data.connection.channelId,
            // @ts-ignore
            this.data.connection.propertyId,
            this.data.connection.roomId
          )
        ),
        take(1),
        switchMap(({ pass_through_taxes }) =>
          this.store.pipe(
            select(selectRentalById(this.data.connection.rentalId)),
            take(1),
            map((rental) => ({ pass_through_taxes, rental }))
          )
        )
      )
      .subscribe(({ pass_through_taxes, rental }) => {
        this.rental = rental

        const airbnbTaxes = pass_through_taxes
        const tokeetTaxes = rental.taxes

        // TODO - create new unique tax interface for this dialog

        R.forEach((tax: AirbnbPassThroughOccupancyTax) => {
          if (this.taxExists(tax, tokeetTaxes)) {
            this.addAirbnbTax(tax, true)
          } else {
            this.addAirbnbTax(tax, false)
          }
        }, airbnbTaxes)

        R.forEach((tax: TaxV3) => {
          if (!this.taxExists(tax, airbnbTaxes)) {
            this.addTokeetTax(tax)
          }
        }, tokeetTaxes)
      })
  }

  taxExists(tax: TaxV3 | AirbnbPassThroughOccupancyTax, taxes: any[]) {
    return taxes?.find(
      (t) =>
        t.business_tax_id == tax.business_tax_id &&
        t.tot_registration_id == tax.tot_registration_id &&
        t.amount == tax.amount &&
        t.tax_type === tax.tax_type
    )
  }

  createAirbnbTax(tax: AirbnbPassThroughOccupancyTax, includeTokeet = false) {
    return this.fb.group({
      includeAirbnb: [true],
      includeTokeet: [includeTokeet],
      tax_type: [tax.tax_type, [Validators.required]],
      amount: [tax.amount, [Validators.required]],
      amount_type: [tax.amount_type, [Validators.required]],
      business_tax_id: [tax.business_tax_id, [Validators.required]],
      tot_registration_id: [tax.tot_registration_id, [Validators.required]],
      long_term_stay_exemption: [tax.long_term_stay_exemption],
      attestation: [true],
    })
  }

  createTokeetTax(tax: TaxV3) {
    return this.fb.group({
      includeAirbnb: [false],
      includeTokeet: [true],
      tax_type: [tax.tax_type, [Validators.required]],
      amount: [tax.amount, [Validators.required]],
      amount_type: [this.getAirbnbAmountType(tax), [Validators.required]],
      business_tax_id: [tax.business_tax_id, [Validators.required]],
      tot_registration_id: [tax.tot_registration_id, [Validators.required]],
      long_term_stay_exemption: [tax.long_term_stay_exemption],
      attestation: [true],
    })
  }

  onAddTax() {
    this.addAirbnbTax({} as AirbnbPassThroughOccupancyTax)
  }

  addAirbnbTax(tax: AirbnbPassThroughOccupancyTax, includeTokeet = false) {
    this.taxes.push(this.createAirbnbTax(tax, includeTokeet))
  }

  addTokeetTax(tax: TaxV3) {
    this.taxes.push(this.createTokeetTax(tax))
  }

  removeTax(index: number) {
    this.taxes.removeAt(index)
  }

  getTV3TaxModality(tax: AirbnbPassThroughOccupancyTax): TaxModalities {
    switch (tax.amount_type) {
      case 'percent_per_reservation':
        return TaxModalities.PerStay
      case 'flat_per_guest':
        return TaxModalities.PerPerson
      case 'flat_per_guest_per_night':
        return TaxModalities.PerPersonPerNight
      case 'flat_per_night':
        return TaxModalities.PerNight
    }
  }

  getAirbnbAmountType(tax: TaxV3): AirbnbTaxAmountType {
    switch (tax.modality) {
      case TaxModalities.PerStay:
        return 'percent_per_reservation'
      case TaxModalities.PerPerson:
        return 'flat_per_guest'
      case TaxModalities.PerPersonPerNight:
        return 'flat_per_guest_per_night'
      case TaxModalities.PerNight:
        return 'flat_per_night'
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.confirmDialog
      .confirm({
        title: 'Update taxes?',
        body: 'Are you sure you want to update taxes?',
      })
      .subscribe(() => {
        const { taxes } = form.getRawValue()

        const airbnbTaxes = R.filter((t: any) => t.includeAirbnb, taxes)

        this.connectionAirbnbService
          .updatePriceSettingsV2({
            channelId: this.data.connection.channelId,
            // @ts-ignore
            propertyId: this.data.connection.propertyId,
            // @ts-ignore
            roomid: this.data.connection.roomId,
            // @ts-ignore
            listing_id: this.data.connection.roomId,
            pass_through_taxes: airbnbTaxes,
          })
          .pipe(
            switchMap(() => {
              const tokeetTaxes = R.filter((t: any) => t.includeTokeet, taxes)

              const v3Taxes = tokeetTaxes.map((tax, index) => ({
                amount: tax.amount,
                type: tax.amount_type === 'percent_per_reservation' ? 'percent' : 'flat',
                name: `Airbnb Tax ${index + 1}`,
                modality: this.getTV3TaxModality(tax),
                tax_type: tax.tax_type,
                business_tax_id: tax.business_tax_id,
                tot_registration_id: tax.tot_registration_id,
                long_term_stay_exemption: tax.long_term_stay_exemption,
              }))
              return this.rentalService.updateTaxes(this.rental.id, v3Taxes).pipe(
                tap((data) => {
                  this.store.dispatch(updateRentalTaxesComplete({ update: { id: data.id, changes: data } }))
                })
              )
            })
          )
          .subscribe(
            () => {
              this.toaster.success('Taxes updated successfully.')
              this.dialogRef.close()
            },
            (error) => {
              this.store.dispatch(ActionFailed({ error }))
            }
          )
      })
  }
}
