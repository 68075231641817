<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title">Messages</h6>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div *ngIf="inquiry; else loading" class="modal-body">
      <div class="d-flex gap-3">
        <div class="messages-holder flex-fill container-box">
          <ng-container *ngIf="messages">
            <app-message-list
              [inquiry]="inquiry"
              [messages]="messages"
              [messageId]="messageId"
              [hasHeader]="true"
            ></app-message-list>
          </ng-container>
        </div>

        <div class="messages-sidebar d-flex flex-column gap-3" *ngIf="inquiry" style="width: 300px; min-width: 300px">
          <app-sidebar-expansion-panel header="Occupied Dates" class="bg-white">
            <app-occupied-dates [inquiry]="inquiry"></app-occupied-dates>
          </app-sidebar-expansion-panel>

          <app-sidebar-expansion-panel header="Booking Details" class="bg-white">
            <app-booking-details [inquiry]="inquiry"></app-booking-details>
          </app-sidebar-expansion-panel>

          <app-sidebar-expansion-panel header="Booking Charges" class="bg-white">
            <app-booking-charges [inquiry]="inquiry"></app-booking-charges>
          </app-sidebar-expansion-panel>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="modal-body">
        <app-loading-section></app-loading-section>
      </div>
    </ng-template>
  </div>
</div>
