import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as lodash from 'lodash'
import { SaveForm } from '../../decorators'
import { Destroyable } from '../../rx-operators'
import { Address } from '../../stores'
import { notAllSpacesValidator } from '../../validators'

@Component({
  selector: 'app-edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./edit-address-dialog.component.scss'],
})
export class EditAddressDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    address: ['', [Validators.required, notAllSpacesValidator]],
    city: ['', [Validators.required]],
    country: [],
    country_code: ['', [Validators.required]],
    postal_code: ['', [Validators.required]],
    state: ['', [Validators.required]],
  })

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    public dialogRef: MatDialogRef<EditAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { address: Address; title: string }
  ) {
    super()
  }

  ngOnInit() {
    const address = this.data.address
    if (address) {
      this.form.patchValue(lodash.isFunction(address.serialize) ? address.serialize() : address)
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues)
  }
}
