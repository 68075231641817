import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  ConnectGVRSuccess,
  DisconnectGVRSuccess,
  GetGVRStatus,
  GetGVRListings,
  selectGVRStatus,
} from '@tokeet-frontend/channels'

import {
  Channel,
  DataCheckerService,
  Destroyable,
  LoadChannels,
  untilDestroy,
  UserStorage,
} from '@tokeet-frontend/tv3-platform'
import { WebsiteTemplateGuard } from '@tv3/guards/website-template.guard'
import { WebsiteGuard } from '@tv3/guards/website.guard'
import { LoadConnections } from '@tv3/store/connection/connection.actions'
import { delay, filter, switchMap, take } from 'rxjs/operators'
import { ChannelConnectHelperService } from '../channel-connect-helper.service'

@Component({
  selector: 'app-connect-gvr-wizard',
  templateUrl: './connect-gvr-wizard.component.html',
  styleUrls: ['./connect-gvr-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
  providers: [ChannelConnectHelperService],
})
export class ConnectGVRWizardComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper

  status$ = this.store.pipe(select(selectGVRStatus))

  get channel() {
    return this.data.channel
  }

  constructor(
    public dialogRef: MatDialogRef<ConnectGVRWizardComponent>,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private checker: DataCheckerService,
    private channelConnectHelper: ChannelConnectHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {
    super()
    this.checker.check([WebsiteGuard, WebsiteTemplateGuard])
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(ConnectGVRSuccess), untilDestroy(this)).subscribe(() => {
      this.refresh()
    })

    this.actions.pipe(ofType(DisconnectGVRSuccess), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(GetGVRStatus())
      this.store.dispatch(GetGVRListings())
      this.store.dispatch(LoadChannels())
      this.store.dispatch(LoadConnections({}))
    })
    this.channelConnectHelper.rentalImageCountsGuard().pipe(untilDestroy(this)).subscribe()

    this.refresh()
  }
  ngAfterViewInit(): void {
    this.stepper.animationDone
      .pipe(
        take(1),
        delay(500),
        switchMap(() => this.store.pipe(select(selectGVRStatus))),
        filter((s) => !!s?.connected),
        take(1),
        untilDestroy(this)
      )
      .subscribe((status) => {
        this.stepper.selectedIndex = 1
      })
  }

  close() {
    this.dialogRef.close()
  }

  refresh() {
    this.store.dispatch(GetGVRStatus())
    this.store.dispatch(GetGVRListings())
  }
}
