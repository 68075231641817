import { Destroyable, taxV3ModalityLabels } from '@tokeet-frontend/tv3-platform'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TaxModalities } from '@tokeet/cost-resolver'
import { Observable, of } from 'rxjs'
import { map, startWith, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import { availableTaxeNames } from '../../../store/taxes'

@Component({
  selector: 'app-tax-form',
  templateUrl: './tax-form.component.html',
  styleUrls: ['./tax-form.component.scss'],
})
export class TaxFormComponent extends Destroyable implements OnInit {
  @Input() taxes: FormGroup[]
  @Input() index: number
  @Input() modalityEnabled = true

  @Output() remove = new EventEmitter<number>()

  form: FormGroup

  constructor() {
    super()
  }

  taxName$: Observable<string>
  taxes$ = of(availableTaxeNames)
  filteredTaxes$
  taxV3Modalities = TaxModalities
  taxV3ModalityLabels = taxV3ModalityLabels

  ngOnInit() {
    this.form = this.taxes['controls'][this.index]

    this.taxName$ = this.form.get('name').valueChanges.pipe(startWith(''))
    this.filteredTaxes$ = this.taxName$.pipe(
      withLatestFrom(this.taxes$),
      map(([taxName, taxes]) => this.filterTaxes(taxName, taxes))
    )
  }

  filterTaxes(taxName: string, taxes: any[]) {
    const filterValue = taxName?.toLowerCase() || ''

    if (R.isEmpty(taxName)) {
      return taxes
    }

    return taxes.filter((t) => t.name.toLowerCase().indexOf(filterValue) === 0)
  }
}
