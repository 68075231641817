import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-attachment-dialog',
  host: { class: 'modal-content' },
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss'],
})
export class AttachmentDialogComponent {
  selectFromExisting = false

  constructor(
    public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectFromExisting: boolean }
  ) {
    this.selectFromExisting = this.data.selectFromExisting || false
  }

  onDone(files) {
    this.dialogRef.close(files)
  }

  onClose() {
    this.dialogRef.close()
  }
}
