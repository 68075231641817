import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { Rental, selectUsers, USER_ROLES, sortAscend, SaveForm, restrictUsers } from '@tokeet-frontend/tv3-platform'

export interface UserRestrictionsDialogConfig {
  rental: Rental
  description?: string
}

@Component({
  selector: 'app-user-restrictions-dialog',
  templateUrl: './user-restrictions-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./user-restrictions-dialog.component.scss'],
})
export class UserRestrictionsDialogComponent implements OnInit {
  description =
    'You may specify which users should be restricted from accessing data associated with this rental. This rental will not be displayed in the calendar, inquiry list, invoices, and other data of the specified users when they log into AdvanceCM. You may change user restrictions at anytime.'

  form = this.fb.group({
    users: [''],
  })

  users$ = this.store.pipe(
    select(selectUsers),
    map((users) =>
      R.filter((user) => {
        if (user.roles && user.roles.indexOf(USER_ROLES.admin.value) > -1) {
          // You cannot restrict an admin.
          return false
        } else if (user.roles && user.roles.indexOf(USER_ROLES.owner.value) > -1) {
          // You also cannot use this to restrict owners.
          return false
        }
        return true
      }, users)
    ),
    map((users) => sortAscend('name')(users))
  )

  restrictedUsers: string[] = []

  constructor(
    public dialogRef: MatDialogRef<UserRestrictionsDialogComponent>,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UserRestrictionsDialogConfig,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.restrictedUsers = R.uniq(R.defaultTo([], this.data.rental.restrictedUsers))
    if (this.data.description) {
      this.description = this.data.description
    }
    this.form.patchValue({ users: this.restrictedUsers })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue() as { users: string[] }
    const newRestrictions = R.filter((userId) => R.indexOf(userId, this.restrictedUsers) === -1, formValues.users)
    const removedRestrictions = R.filter((userId) => R.indexOf(userId, formValues.users) === -1, this.restrictedUsers)
    this.store.dispatch(
      restrictUsers({
        rental: this.data.rental,
        addUserIds: newRestrictions,
        removeUserIds: removedRestrictions,
        currentUserIds: formValues.users,
      })
    )
    this.close()
  }
}
