import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { isSomething, SaveForm } from '@tokeet-frontend/tv3-platform'
import { Template } from '@tokeet-frontend/templates'

@Component({
  selector: 'app-save-template-dialog',
  host: { class: 'modal-content' },
  templateUrl: './save-template-dialog.component.html',
  styleUrls: ['./save-template-dialog.component.scss'],
})
export class SaveTemplateDialogComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    description: ['', [Validators.minLength(2), Validators.maxLength(100)]],
  })

  isEdit = false

  constructor(
    public dialogRef: MatDialogRef<SaveTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isEdit: boolean } & Pick<Template, 'name' | 'description'>,
    private fb: FormBuilder
  ) {
    this.isEdit = !!this.data.isEdit
  }

  ngOnInit() {
    this.form.patchValue({
      name: this.data?.name || '',
      description: this.data?.description || '',
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    this.dialogRef.close({ data: form.getRawValue(), saveAsNew: false })
  }

  @SaveForm()
  onSaveAsNew(form: FormGroup) {
    this.dialogRef.close({ data: form.getRawValue(), saveAsNew: true })
  }
}
