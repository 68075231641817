<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Rentals</h6>
      <app-page-help [infoTooltip]="tableTooltip"></app-page-help>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>
  <div class="container-box">
    <div class="filters-box" [formGroup]="form">
      <app-select-search
        matTooltip="Only show rentals that specify the selected tags."
        matTooltipPosition="above"
        [items]="tags$ | async"
        [ctrl]="form.get('tags')"
        placeholder="Tags"
        class="w-auto ml-2"
      ></app-select-search>

      <app-cancel-filters [isFiltering]="form | isFiltering" [filterControl]="form"></app-cancel-filters>
      <div class="flex-fill"></div>
      <app-table-search [ctrl]="form.get('search')"></app-table-search>
    </div>
    <div class="table-holder table-responsive">
      <table class="table table-default table-striped" [hidden]="isEmptyTable$ | async">
        <mat-table #table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span [matTooltip]="element.name" matTooltipPosition="above" class="text-truncate">{{
                element.name
              }}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="descriptionView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description</mat-header-cell>
            <mat-cell *matCellDef="let element" class="px-2">
              <div [matTooltip]="element.descriptionView" matTooltipPosition="above" class="text-truncate">
                {{ element.descriptionView }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef> Phone</mat-header-cell>
            <mat-cell *matCellDef="let element" class=""> {{ element.phone }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="cityView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> City</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.cityView }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="countryView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Country</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.countryView }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onOpenRental(row)"
            class="clickable"
          ></mat-row>
        </mat-table>

        <mat-paginator2
          #paginator
          [length]="dataSource?.data?.length"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator2>
      </table>
      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
    </div>
  </div>
</div>
