<div class="d-flex">
  <ngb-datepicker
    class="border-0 flex-1"
    #datepicker
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="2"
    [dayTemplate]="tmplDate"
    [maxDate]="dateToDateStruct | call: maxDate"
    [minDate]="dateToDateStruct | call: minDate"
    [startDate]="dateToDateStruct | call: startDate"
    outsideDays="hidden"
  >
  </ngb-datepicker>
  <ul class="list-unstyled m-0 py-3 border-left additional-actions" *ngIf="showQuickActions">
    <li><a (click)="onSelectRange('day')">Today</a></li>
    <li><a (click)="onSelectRange('day', -1)">Yesterday</a></li>
    <li><a (click)="onSelectRange('week')">This Week</a></li>
    <li><a (click)="onSelectRange('week', -1)">Last Week</a></li>
    <li><a (click)="onSelectRange('week', -2, -1)">Last Two Weeks</a></li>
    <li><a (click)="onSelectRange('month')">This Month</a></li>
    <li><a (click)="onSelectRange('month', -1)">Last Month</a></li>
    <li class="flex-fill"></li>
    <li class="d-grid">
      <button class="btn btn-sm btn-secondary-light mx-3" (click)="onReset()" type="button">
        <i class="far fa-sync"></i> Reset
      </button>
    </li>
  </ul>
</div>
<ng-template #tmplDate let-date="date" let-focused="focused">
  <span
    *ngIf="date"
    class="drp-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
