<mat-accordion [hideToggle]="true" class="p-3 d-flex flex-column gap-3">
  <mat-expansion-panel
    class="shadow-none border rounded m-0"
    [expanded]="true"
    (opened)="onOpen('basic')"
    [class.bg-active2]="!panel1.expanded"
    #panel1
  >
    <mat-expansion-panel-header [class.border-bottom]="panel1.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Control permissions for Advance Intelligence on all rental related fields."
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel1.expanded ? 'down' : 'right')"></i> Basic Information
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template [ngTemplateOutlet]="itemTable"></ng-template>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-secondary-info ml-auto" [disabled]="isSaving" (click)="onSaveBasic()">
        <i class="fa" [ngClass]="isSaving ? 'fa-spinner fa-spin' : 'fa-save'"></i> Save
      </button>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    class="shadow-none border rounded m-0"
    (opened)="onOpen('details')"
    [class.bg-active2]="!panel2.expanded"
    #panel2
  >
    <mat-expansion-panel-header [class.border-bottom]="panel2.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Set permissions for Rental’s detailed information to filter out from automated AI responses."
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel2.expanded ? 'down' : 'right')"></i> Detailed Information
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template [ngTemplateOutlet]="itemTable"></ng-template>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-secondary-info ml-auto" [disabled]="isSaving" (click)="onSaveDetails()">
        <i class="fa" [ngClass]="isSaving ? 'fa-spinner fa-spin' : 'fa-save'"></i> Save
      </button>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel
    class="shadow-none border rounded m-0"
    (opened)="onOpen('instructions')"
    [class.bg-active2]="!panel3.expanded"
    #panel3
  >
    <mat-expansion-panel-header [class.border-bottom]="panel3.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Set permissions for Rental instructions related information to filter out from automated AI responses."
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel3.expanded ? 'down' : 'right')"></i> Rental Instructions
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template [ngTemplateOutlet]="itemTable"></ng-template>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-secondary-info ml-auto" [disabled]="isSaving" (click)="onSaveInstructions()">
        <i class="fa" [ngClass]="isSaving ? 'fa-spinner fa-spin' : 'fa-save'"></i> Save
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #itemTable>
  <div class="border rounded overflow-hidden my-4 bg-white">
    <mat-table #table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="onMasterChange($event.checked)" [checked]="isMasterChecked()"> </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            (change)="settings[element.key].isEnabled = $event.checked"
            [checked]="settings[element.key].isEnabled"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Rental Data</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.label }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="preBooking">
        <mat-header-cell *matHeaderCellDef> Pre Booking</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            [inline]="true"
            matTooltip="Do you really want to share this information before booking confirmation?"
            [disabled]="!settings[element.key]?.isEnabled"
            [checked]="settings[element.key]?.preBooking"
            (valueChange)="onTogglePreBooking(element, $event)"
          ></app-form-switch>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="postBooking">
        <mat-header-cell *matHeaderCellDef> Post Booking</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            [inline]="true"
            matTooltip="Do you really want to share this information after booking confirmation?"
            [disabled]="!settings[element.key]?.isEnabled"
            [checked]="settings[element.key]?.postBooking"
            (valueChange)="onTogglePostBooking(element, $event)"
          ></app-form-switch>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="postCheckin">
        <mat-header-cell *matHeaderCellDef> Post Checkin</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            [inline]="true"
            matTooltip="Do you really want to share this information after guest checkin?"
            [disabled]="!settings[element.key]?.isEnabled"
            [checked]="settings[element.key]?.postCheckin"
            (valueChange)="onTogglePostCheckin(element, $event)"
          ></app-form-switch>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</ng-template>
