import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap'
import * as moment from 'moment'
import { NgbMomentAdapter } from '@tv3/shared/mini-event-calendar/moment-adapter/moment-adapter'
import { CalendarEvent } from '@tv3/store/calendar/calendar.model'
import * as R from 'ramda'
import { I18n, LocalizedDatepickerI18n } from '@tv3/shared/mini-event-calendar/datepicker-i18n/datepicker-i18n'
import { convertStructToMoment } from '@tv3/utils/functions/convert-struct-to-moment'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-mini-event-calendar',
  templateUrl: './mini-event-calendar.component.html',
  styleUrls: ['./mini-event-calendar.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbMomentAdapter },
    I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: LocalizedDatepickerI18n,
    },
  ],
})
export class MiniEventCalendarComponent implements OnInit, OnChanges {
  @Input() clickable = true
  @Input() events: CalendarEvent[]
  @Input() selectedDate: Date

  @ViewChild('dp', { static: true }) datePicker: NgbDatepicker

  hoveredDate: NgbDate
  fromDate: NgbDate
  toDate: NgbDate

  eventGroups: { [id: string]: any } = {}

  constructor(calendar: NgbCalendar) {
    this.fromDate = calendar.getToday()
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10)
  }

  ngOnInit() {
    this.handleEvents(this.events)
    this.navigateToSelectedMonth(this.selectedDate)
  }

  private navigateToSelectedMonth(date: Date) {
    if (date instanceof Date) {
      const ngbDate = { day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear() }
      this.datePicker.navigateTo(ngbDate)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.events = R.pathOr(this.events, ['events', 'currentValue'], changes)
    this.handleEvents(this.events)
  }

  handleEvents(events: CalendarEvent[]) {
    this.eventGroups = {}
    R.forEach((event: CalendarEvent) => {
      let start = R.is(Number, event.start)
        ? moment(moment.utc(event.start * 1000).format('YYMMDD'), 'YYMMDD')
        : moment(event.start)
      let end = R.is(Number, event.end)
        ? moment(moment.utc(event.end * 1000).format('YYMMDD'), 'YYMMDD')
        : moment(event.end || start.clone().add(1, 'days'))
      let diffDays = end.diff(start, 'days')
      if (event.bookingId) {
        event.type = 4 // booked event as confirmed event
      }
      for (let i = 0; i <= diffDays; i++) {
        let dayProp = start.format('YYYY-MM-DD')
        let group
        if (this.eventGroups[dayProp]) {
          group = this.eventGroups[dayProp]
        } else {
          group = []
          group.isStart = true
          group.isEnd = true
          group.eventClasses = []
        }
        group.push(event)
        group.day = start.clone()
        this.eventGroups[dayProp] = group
        // next day
        start.add(1, 'days')
        if (i != 0) {
          group.isStart = false
        } else {
          group.ci = true // there is one event start on this day
        }
        if (i != diffDays) {
          group.isEnd = false
        } else {
          group.co = true // there is one event end on this day
        }
        group.eventClasses.push('e' + (event.type || 0))
        group.eventClasses = R.uniq(group.eventClasses)
      }
    }, events)

    this.fromDate = this.getNgbDateFromEventGroup(this.eventGroups, R.head)
    this.toDate = this.getNgbDateFromEventGroup(this.eventGroups, R.last)
  }

  getNgbDateFromEventGroup(eventGroups: { [id: string]: any }, firstOrLast): NgbDate {
    if (isSomething(eventGroups)) {
      const toOrFrom = R.pipe(
        R.keys,
        firstOrLast,
        R.split('-'),
        R.map((part) => parseInt(part)),
        R.zipObj(['year', 'month', 'day'])
      )(eventGroups)
      return new NgbDate(toOrFrom.year, toOrFrom.month, toOrFrom.day)
    }
    return null
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date
    } else {
      this.toDate = null
      this.fromDate = date
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    )
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate)
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date)
  }

  isFirst(date: NgbDate) {
    return date.equals(this.fromDate)
  }

  isLast(date: NgbDate) {
    return date.equals(this.toDate)
  }

  isDisabled = (date: NgbDateStruct, current: { month: number }) => {
    if (!this.clickable) {
      return true
    }
    return !this.hasEventOnDay(date)
  }

  private hasEventOnDay(date: NgbDate | NgbDateStruct): boolean {
    return !R.isNil(this.getEventOnDay(date))
  }

  private getEventOnDay(date: NgbDate | NgbDateStruct) {
    const momentDate = convertStructToMoment(date)
    return this.eventGroups[momentDate.format('YYYY-MM-DD')]
  }
}
