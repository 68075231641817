import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { GVRListingViewModel } from '@tokeet-frontend/channels'
import { GVRListingDetailsOverlayComponent } from './gvr-listing-details-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class GVRListingDetailsOverlayService {
  constructor(private dialog: DialogService) {}

  public open(listing: GVRListingViewModel) {
    const defaultConfig: MatDialogConfig = {
      data: { listing },
      width: '750px',
      height: 'auto',
    }

    const dialogRef = this.dialog.openVariableDialog(GVRListingDetailsOverlayComponent, defaultConfig)

    return dialogRef
  }
}
