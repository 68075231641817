import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Destroyable, isSomething } from '@tokeet-frontend/tv3-platform'
import { DataDictionaryDialogService } from '@tv3/shared/data-dictionary-dialog/data-dictionary-dialog.service'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-editor-push-notification',
  templateUrl: './editor-push-notification.component.html',
  styleUrls: ['./editor-push-notification.component.scss'],
})
export class EditorPushNotificationComponent extends Destroyable implements OnInit {
  @Input() contentCtrl: FormControl

  constructor(private dataDictionaryDialog: DataDictionaryDialogService) {
    super()
  }

  ngOnInit() {}

  onDataDictionary() {
    this.dataDictionaryDialog
      .open()
      .pipe(filter(isSomething))
      .subscribe((tag) => {
        this.contentCtrl.setValue(this.contentCtrl.value + tag)
      })
  }
}
