import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Message, MessageType } from '@tv3/store/message/message.model'
import { MessageView } from '@tv3/store/message/message.view'
import * as reducer from '@tv3/store/message/message.reducer'
import * as state from '@tv3/store/message/message.state'
import { selectInquiries } from '@tv3/store/inquiry/inquiry.selectors'
import { selectUserPreferences } from '@tv3/store/user-preferences/user-preferences.selectors'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { keyBy, trim } from 'lodash'

export const messageState = createFeatureSelector<state.State>('message')

export const selectMessagesLoaded = createSelector(messageState, (state) => state.isLoaded)

export const selectAllMessages = createSelector(messageState, reducer.selectAll)

export const selectUnreadMessages = createSelector(selectAllMessages, (messages: Message[]) => {
  return (messages || []).filter((m) => m.type === MessageType.Inbound).filter((m) => !m.read)
})

export const selectAllInboundMessageViews = createSelector(
  selectAllMessages,
  selectInquiries,
  selectAllRentals,
  selectUserPreferences,
  (messages, inquiries, rentals, userPreferences) => {
    const rentalsById = keyBy(rentals, 'id')
    const inquiresById = keyBy(inquiries, 'id')
    return messages
      .filter((m) => m.type === MessageType.Inbound)
      .map((m) => {
        const inquiry = inquiresById[m.inquiryId] || ({} as any)
        const rental = rentalsById[m.rentalId || inquiry.rentalId]
        return {
          ...m,
          guestName: trim(m.guestName || m.name) || 'Unknown',
          rentalName: (rental && rental.name) || '',
          pinned: (userPreferences.pinnedMessages || []).indexOf(m.id) > -1,
          inquiry,
          rental,
        } as MessageView
      })
  }
)

export const selectPinnedMessageViews = createSelector(selectAllInboundMessageViews, (messages) =>
  messages.filter((m) => m.pinned)
)
