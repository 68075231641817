import { FormBuilder } from '@angular/forms'
import * as R from 'ramda'
import { Store } from '@ngrx/store'
import { FilterFormGroup, isSomething } from '@tokeet-frontend/tv3-platform'
import { selectAllDiscountCodeViews } from './discount-code.selectors'
import { DiscountCodeView } from './discount-code.model'
import { IStorage } from '@tokeet-frontend/tv3-platform'

export abstract class DiscountCodeBaseFilter {
  defaultValues = {
    rentals: [],
    type: undefined,
  }

  filters = {
    rentals: (items: DiscountCodeView[], rentalIds: string[]) => {
      if (!isSomething(rentalIds)) {
        return items
      }
      return R.filter((item) => R.contains(item.rental_id, rentalIds), items)
    },
    type: (items: DiscountCodeView[], type: string) => {
      if (!type || type === 'all') {
        return items
      }
      return R.filter((item) => item.type === type, items)
    },
  }

  group: FilterFormGroup<DiscountCodeView>

  protected constructor(
    protected store: Store<any>,
    protected fb: FormBuilder,
    protected storage: IStorage,
    protected filterGroup: string
  ) {
    const storedFilters = this.storage.get(filterGroup, this.defaultValues)

    const group = this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      type: [storedFilters.type],
    })

    this.group = new FilterFormGroup<DiscountCodeView>(
      group.controls,
      this.store,
      this.storage,
      filterGroup,
      selectAllDiscountCodeViews,
      this.filters
    )
  }
}
