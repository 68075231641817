<!-- Header -->
<div *ngIf="hasHeader" class="border-bottom p-3">
  <div class="d-flex align-items-center">
    <div class="text-bold">Previous Messages</div>
    <div class="flex-fill"></div>
    <div class="">
      <app-table-search (search)="searchTerm$.next($event)"> </app-table-search>
    </div>
  </div>
</div>

<app-empty-table *ngIf="!messages?.length" [type]="tableType">
  <p><a (click)="onOpenInquiry()" href="javascript:;">Create an inquiry</a> first to be able to message this guest.</p>
</app-empty-table>

<div
  class="d-flex flex-column gap-4 p-3"
  [ngClass]="{ 'form-visible': isInlineMessageFormVisible }"
  *ngIf="messages?.length"
  style="overflow-x: auto; overflow-y: hidden"
>
  <app-message-list-item
    #msg
    *ngFor="let message of filteredMessages; let i = index"
    [attr.id]="message.messageId"
    [message]="message"
    [collapsed]="!!i"
    [hasMove]="hasMove"
    [inquiry]="inquiry"
    (editDraft)="onEditDraft($event)"
    (aiGenerate)="onAIGenerate($event)"
  ></app-message-list-item>
</div>

<ng-container *ngIf="!(1 | isReadonlyRole) && inquiry">
  <button
    (click)="openMessageForm()"
    *ngIf="getViewRect() as rect"
    matTooltip="Reply"
    matTooltipPosition="left"
    class="btn btn-secondary-info btn-circle text-xxl btn-reply position-fixed"
    type="button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    [ngStyle]="{
      'left.px': rect.left + rect.width - 60,
      bottom: '20px'
    }"
  >
    <i class="fal fa-envelope"></i>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOffsetX]="15"
      [cdkConnectedOverlayOffsetY]="5"
      [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' }]"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isInlineMessageFormVisible"
      [cdkConnectedOverlayDisableClose]="true"
    >
      <ng-template
        [ngTemplateOutlet]="messageFormTmpl"
        [ngTemplateOutletContext]="{ minWidth: rect.width }"
      ></ng-template>
    </ng-template>
  </button>
</ng-container>
<ng-template #messageFormTmpl let-minWidth="minWidth">
  <app-inquiry-message-form
    #editor
    [inquiry]="inquiry"
    [guest]="guest"
    [draftMessage]="draftMessage"
    (close)="closeMessageForm()"
    (popup)="onPopup()"
    cdkDrag
    [cdkDragDisabled]="!isMessageFormPopup"
    [isPopup]="isMessageFormPopup"
    [minEditorHeight]="isMessageFormPopup ? 300 : 150"
    [maxEditorHeight]="isMessageFormPopup ? 800 : 500"
    [style.min-width.px]="minWidth"
  >
  </app-inquiry-message-form>
</ng-template>
