import {
  isSomething,
  epochToViewUTC,
  epochToView,
  DataRecurringRule,
  createdBySystemNameMappings,
  toMoment,
  asUTCEpoch,
  asLocalDate,
} from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'

export const defaultTaskList = 'My Tasks'

export const defaultTaskColors = [
  { color: '#F8D004', bg: '#FFFCF3' },
  { color: '#8FC901', bg: '#F0F9F1' },
  { color: '#09B44A', bg: '#ddefe1' },
  { color: '#00CDCD', bg: '#f0f8ff' },
  { color: '#3470EA', bg: '#DDEFFF' },
  { color: '#7B57D4', bg: '#FBEEFF' },
]

export function getTaskBgColor(color: string) {
  return defaultTaskColors.find((c) => c.color === color)?.bg
}

export enum TaskStatus {
  Pending = 1,
  Completed = 2,
}

export enum TaskStatusView {
  Pending = 'Pending',
  InProgress = 'In Progress',
  DueToday = 'Due Today',
  PastDue = 'Past Due',
  Completed = 'Completed',
}

export interface TaskResponse extends DataRecurringRule {
  pkey: string
  account: number
  name: string

  list?: string
  status: number
  notes: string

  timezone?: string
  start: number
  due: number
  marked?: number
  color?: string
  tags?: string[]
  attachments?: string[]

  user_ids: string[]
  user_id: string
  inquiry_id?: string
  rental_id?: string
  created_by?: string
  trigger_id?: string
  incident_id?: string

  events?: string // Automated Task

  checklist?: TaskChecklistItem[]

  created: number
  archived: number
}

export interface TaskChecklistItem {
  name: string
  description?: string
  status?: number
  date_completed?: number // epoch
  completed_by?: string // user id
}

export type CreateTaskPayload = Omit<TaskResponse, 'pkey' | 'created' | 'account' | 'archived' | 'list' | 'user_id'>
export type UpdateTaskPayload = Partial<CreateTaskPayload>

export interface TaskView extends TaskResponse {
  startView: string
  dueView: string
  createdView: string
  bgColor: string

  isAuto?: boolean

  createdByView?: string
  statusView?: string
  usersView?: string
  inquiryView?: string
  rentalView?: string
  completed?: boolean
  past?: boolean
  pending?: boolean
}

export function taskToView(task: TaskResponse): TaskView {
  const nowEpoch = asUTCEpoch(new Date())
  const color = task.color || defaultTaskColors[0].color
  task.due = +task.due
  task.start = +task.start
  const view: TaskView = {
    ...task,
    color,
    repeat: +task.repeat,
    bgColor: getTaskBgColor(color),
    user_ids: (isSomething(task.user_ids) ? task.user_ids : [task.user_id]).filter((t) => !!t),
    isAuto: !!createdBySystemNameMappings[task.created_by],
    startView: epochToViewUTC(task.start, 'datetime'),
    dueView: epochToViewUTC(task.due, 'datetime'),
    createdView: epochToView(task.created),
    completed: task.status === TaskStatus.Completed,
    past: task.status !== TaskStatus.Completed && nowEpoch > task.due,
    pending: task.status !== TaskStatus.Completed && !(nowEpoch > task.due),
  }

  if (view.status == TaskStatus.Completed) {
    view.statusView = TaskStatusView.Completed
  } else if (moment.utc().isSame(view.due * 1000, 'date')) {
    view.statusView = TaskStatusView.DueToday
  } else if (nowEpoch > view.start && nowEpoch < view.due) {
    view.statusView = TaskStatusView.InProgress
  } else if (view.past) {
    view.statusView = TaskStatusView.PastDue
  } else {
    view.statusView = TaskStatusView.Pending
  }

  return view
}
