import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
/* Store Attachment */
/* Services */
/* Shared Dialogs */
/* Utils */
import {
  addRentalImages,
  Attachment,
  CloudinaryUploadDialogService,
  ConfirmDialogService,
  Destroyable,
  editRentalImage,
  isSomething,
  loadRentalImages,
  makeRentalImagePrimary,
  removeRentalImage,
  removeRentalImages,
  Rental,
  roomCategories,
  TokeetImagePayload,
  untilDestroy,
  updateRentalImageOrder,
} from '@tokeet-frontend/tv3-platform'
import { FormControl } from '@angular/forms'
import * as R from 'ramda'
import { Lightbox } from 'ngx-lightbox'
import { Store } from '@ngrx/store'

import * as fromRoot from '@tv3/store/state'
/* Store Rental */
import { TokeetImage } from '@tv3/models/rental/tokeet-image'
import * as lodash from 'lodash'
import { SelectionModel } from '@angular/cdk/collections'

@Component({
  selector: 'app-rental-details-image-gallery',
  templateUrl: './rental-details-image-gallery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rental-details-image-gallery.component.scss'],
})
export class RentalDetailsImageGalleryComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental
  @Input() highlightedItems: string[] = []

  images: TokeetImage[] = []

  categories = R.sortBy((c: any) => c.name, roomCategories)

  maxImagesCount = 60

  imageOrder: { [key: string]: number } = {}

  viewType = 'grid'

  selection = new SelectionModel<TokeetImage>(true, [])

  constructor(
    private confirmDialogService: ConfirmDialogService,
    private cloudinaryUploadDialog: CloudinaryUploadDialogService,
    private store: Store<fromRoot.State>,
    private lightbox: Lightbox
  ) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(loadRentalImages({ id: this.rental.id }))

    this.setImages(this.rental)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rental = R.pathOr(this.rental, ['rental', 'currentValue'], changes)
    this.setImages(this.rental)
  }

  isHighlighted(id: string) {
    return this.highlightedItems?.includes(id)
  }

  scrollToHighlighted() {
    const element = document.getElementById(this.highlightedItems?.[0])
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  private setImages(rental: Rental) {
    this.images = R.sortBy((item: TokeetImage) => {
      return item.order - (item.primary ? 10000 : 0)
    }, R.defaultTo([], rental.images))
    R.forEach((image) => {
      this.imageOrder[image.id] = image.order
    }, this.images)

    this.selection.clear()
    setTimeout(() => this.scrollToHighlighted(), 300)
  }

  onDrop() {
    const toUpdate: { [key: string]: number } = {}
    this.images.forEach((image, index) => {
      if (!image || index === this.imageOrder[image.id]) {
        return
      }
      toUpdate[image.id] = index
    })
    this.store.dispatch(updateRentalImageOrder({ images: toUpdate, rental: this.rental }))
  }

  onUpload() {
    this.cloudinaryUploadDialog
      .open({
        title: 'Upload Rental Images',
        maxUploads: this.maxImagesCount - this.rental.images.length,
      })
      .subscribe((images: TokeetImagePayload[]) => {
        this.store.dispatch(
          addRentalImages({
            rentalId: this.rental.id,
            data: images,
          })
        )
      })
  }

  changeCategory(image, category) {
    const data = <any>{ imageId: image.id, category: category }
    if (image.metadata.description) {
      data.description = image.metadata.description
    }
    this.store.dispatch(editRentalImage(data))
  }

  changeDescription(image, event) {
    const data = <any>{ imageId: image.id, description: event.target.value }
    if (image.metadata.category) {
      data.category = image.metadata.category
    }
    this.store.dispatch(editRentalImage(data))
  }

  getImageThumbUrl(url: string) {
    // https://cloudinary.com/documentation/image_transformations#img_trans_overview
    const thumbParams = 'w_240,c_limit,q_auto:eco'
    return (url + '').replace('/upload/', `/upload/${thumbParams}/`)
  }

  onOpenImage(index: number) {
    this.lightbox.open(
      R.map((image: TokeetImage) => {
        return {
          thumb: this.getImageThumbUrl(image.secureUrl),
          src: image.secureUrl,
          caption: image.metadata.description,
        }
      }, this.images),
      index
    )
  }

  deleteImage(image?: TokeetImage) {
    if (!isSomething(image)) {
      return
    }
    this.confirmDialogService
      .confirm({
        title: 'Delete this image?',
        body: 'Are you sure you want to delete this image?',
      })
      .subscribe(() => {
        this.store.dispatch(removeRentalImage({ id: image.id }))
      })
  }

  deleteSelectedImages() {
    this.deleteImages(this.selection.selected)
  }

  deleteImages(images: TokeetImage[]) {
    if (!isSomething(images)) {
      return
    }
    this.confirmDialogService
      .confirm({
        title: 'Delete images?',
        body: 'Are you sure you want to delete these images?',
      })
      .subscribe(() => {
        this.store.dispatch(removeRentalImages({ ids: lodash.map(images, (t) => t.id) }))
      })
  }

  onSelectChange(images: TokeetImage[]) {
    this.selection.clear()
    this.selection.select(...images)
  }

  makeImagePrimary(image: TokeetImage) {
    this.store.dispatch(
      makeRentalImagePrimary({ rentalId: image.rentalId, primaryImageId: image.id, images: this.images })
    )
  }
}
