import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms'
import * as lodash from 'lodash'

export function validateForm(formGroup: AbstractControl, refresh = true): boolean {
  if (formGroup instanceof FormControl) {
    formGroup.markAsTouched()
    if (refresh) {
      formGroup.updateValueAndValidity()
    }
    return lodash.isNil(formGroup.errors)
  }
  let controls = []
  if (formGroup instanceof FormGroup) {
    controls = lodash.values(formGroup.controls)
  }
  if (formGroup instanceof FormArray) {
    controls = formGroup.controls
  }

  return lodash.every(
    controls.map((ctrl) => validateForm(ctrl, refresh)),
    (valid) => valid
  )
}

export function SaveForm() {
  return function (target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
    const method = descriptor.value

    descriptor.value = function () {
      const form: AbstractControl = arguments[0]
      const isFormValid = validateForm(form, false)
      if (!isFormValid || form.invalid) {
        return null
      } else {
        return method.apply(this, arguments)
      }
    }
  }
}
