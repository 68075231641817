<div class="modal-header">
  <div class="d-flex align-items-center gap-2 flex-1">
    <h6 class="modal-title"><i class="fal fa-calendar-alt"></i> Share Calendar</h6>
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <!-- Name -->
      <div class="col-sm-12">
        <mat-form-field2
          class="mat-block"
          matTooltip="Give your shared calendar a name. Typically the name of the person you are sharing with or the name of the rental."
          matTooltipPosition="above"
        >
          <input type="text" matInput required placeholder="Share Name" formControlName="name" maxlength="50" />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
            Name must be at most <strong>50</strong> characters long
          </mat-error>
          <mat-hint align="end">{{ form.get('name').value.length || 0 }}/50</mat-hint>
        </mat-form-field2>
      </div>

      <div class="col-sm-12">
        <app-select-search
          bindValue="id"
          bindLabel="name"
          [inline]="false"
          matTooltip="Select the rental whose calendar you wish to share."
          matTooltipPosition="above"
          [multiple]="false"
          [items]="rentals$ | async"
          [ctrl]="form.get('rental')"
          placeholder="Select Rental"
        >
          <ng-container class="error-container">
            <mat-error> Rental is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
      </div>

      <!-- Password -->
      <div class="col-sm-12">
        <mat-form-field2
          class="mat-block"
          matTooltip="Create an optional password which must be used to view the calendar."
          matTooltipPosition="above"
        >
          <input type="password" matInput placeholder="Share Password" formControlName="password" />
        </mat-form-field2>
      </div>

      <!-- Notes -->
      <div class="col-sm-12">
        <mat-form-field2
          class="mat-block"
          matTooltip="Leave a note for those viewing your calendar."
          matTooltipPosition="above"
        >
          <textarea
            placeholder="Notes (visible on shared page):"
            matInput
            mat-autosize="true"
            [cdkAutosizeMaxRows]="5"
            formControlName="notes"
            maxlength="500"
            cdkTextareaAutosize
          ></textarea>
          <mat-hint align="end">{{ form.get('notes').value.length }} / 500</mat-hint>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="create(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="fal fa-share"></i> share
  </button>
</div>
