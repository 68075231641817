import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectUserPreferences } from '@tv3/store/user-preferences/user-preferences.selectors'
import { UpdateUserPreferences } from '@tv3/store/user-preferences/user-preferences.actions'
import { selectSome, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { TooltipPosition } from '@angular/material/tooltip'

@Component({
  selector: 'app-default-route-indicator',
  templateUrl: './default-route-indicator.component.html',
  styleUrls: ['./default-route-indicator.component.scss'],
})
export class DefaultRouteIndicatorComponent extends Destroyable implements OnInit {
  @Input() tooltipText: string = 'Set as default'
  @Input() tooltipPosition: TooltipPosition = 'right'
  @Input() checkIsDefault: (pref: UserPreferences) => boolean
  @Input() getDefaultRoutePayload: (checked: boolean) => any
  @Output() updated = new EventEmitter<boolean>()

  userPreference: UserPreferences
  isDefault = false

  constructor(private store: Store<fromRoot.State>) {
    super()
  }

  ngOnInit() {
    this.store.pipe(selectSome(selectUserPreferences), untilDestroy(this)).subscribe((userPref: UserPreferences) => {
      this.userPreference = userPref
      this.isDefault = this.checkIsDefault(userPref)
    })
  }

  toggleDefault() {
    const payload = {
      id: this.userPreference.id,
      form: this.getDefaultRoutePayload(!this.isDefault),
    }
    this.store.dispatch(UpdateUserPreferences(payload))
    this.updated.emit(!this.isDefault)
  }
}
