import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SignatureService } from './signature.service'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'

@NgModule({
  imports: [CommonModule, Tv3PlatformModule],
  providers: [SignatureService],
})
export class SignatureModule {}
