export const environment = {
  environment: 'production',
  storageKeys: {
    token: 'TOKEN',
    user: 'USER',
  },
  features: {
    symplMigration: true,
    discounts: true,
    agodaContentApi: false,
    bdcContentApi: false,
    spreedlyWhitelist: {
      1549238313.7276: ['mercado_pago'],
      1568144220.3296: ['mercado_pago'],
    },
    airbnb: {
      bookingPreferences: true,
      bookingSettings: true,
      priceSettings: true,
      taxSettings: false,
    },
    losModality: true,
    signatureStandalone: false,
  },
  plans: {
    rategenieStandalone: 'rategenie_v3',
    signatureStandalone: 'signature_v3',
    tokeetStandalone: 'tokeet_v4',
    tokeetStandaloneYearly: 'tokeet_yr_v4',
  },
  prefix: 'tv3',
  cacheInterceptorLog: false,
  production: true,
  topDomain: '.tokeetmail.com',
  apiUrl: 'https://api.tokeet.com',
  signatureApiUrl: 'https://api.usesignature.com',
  webreadyApiUrl: 'https://api.usewebready.com',
  papiApiUrl: 'https://papi.tokeet.com',
  sslAPIUrl: 'https://ssl.tokeet.com',
  checklistUrl: 'https://app.usechecklist.com/',
  rategenieApiUrl: 'https://api.rategenie.io/v1',
  tv2Url: 'https://tv2.tokeet.com/',
  signatureUrl: 'https://app.usesignature.com/',
  automataUrl: 'https://app.useautomata.com/',
  rategenieUrl: 'https://app.rategenie.io/',
  appStoreUrl: 'https://store.tokeet.com/',
  reportsUrl: 'https://app.usemargins.com/',
  symplUrl: 'https://app.sympl.cm/',
  ownerCenterUrl: 'https://app.ownercenter.net/',
  webreadyUrl: 'https://app.usewebready.com/',
  powerReportingUrl: 'https://login.powerreporting.net',
  aiAPIUrl: 'https://api.aireply.io',
  perfume: true,
  config: {
    websiteDomains: ['.shortlettings.net', '.mylettings.me', '.housesquat.com'],
    bookingEngineDomains: ['.booksafely.com', '.securelettings.net', '.trustbookers.com'],
    guestPortalDomains: ['.myrental.guide'],
    sentryDns: 'https://765b52100ae14668b2a4e595da6d043a@sentry.io/1402194',
    apiBaseUrl: 'https://api.tokeet.com/',
    apiMailBaseUrl: 'https://api.tokeet.com/web',
    CMBaseUrl: 'https://cm.tokeet.com',
    invoiceBaseUrl: 'https://invoices.advance.cm/',
    guestPortalUrl: 'https://guest.tokeet.com/',
    registerUrl: 'https://register.tokeet.com/',
    helpUrl: 'http://helpdocs.tokeet.com/',
    oauthUrl: 'https://oauth.tokeet.com',
    calendarShareUrl: 'https://share.tokeet.com/calendar/',
    iCalEndpoint: 'https://calendars.tokeet.com',
    googleMapKey: 'AIzaSyB1Y6gtva7YVwWejE4aEm3a7meDbq1GQlc',

    mailChimpClientId: '836193949496',
    mailChimpRedirectUrl: 'https://api.tokeet.com/oauth/mailchimp',
    mailChimpOAuthUrl: 'https://login.mailchimp.com/oauth2/authorize?response_type=code',

    slackClientId: '25867822561.258713700369',
    stripe_publishable_key: 'pk_live_uTx1fM9ZE6wEjEW6t2Cx9caK',
    slackRedirectUrl: 'https://api.tokeet.com/oauth/slack',
    slackOAuthUrl: 'https://slack.com/oauth/authorize?response_type=code',

    dropboxClientId: 'pmc9hxua63dj1sf',
    dropboxRedirectUrl: 'https://api.tokeet.com/oauth/dropbox',
    dropboxOAuthUrl: 'https://www.dropbox.com/oauth2/authorize?response_type=code',

    stripeClientId: 'ca_7WEyTGm9SP0EKyrk7vihMnGQJDxC063w',
    stripeRedirectUrl: 'https://api.tokeet.com/oauth/stripe',
    stripeOAuthUrl: 'https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write',
    paypalUrl: 'https://www.paypal.com',

    cloudinaryURL: 'https://api.cloudinary.com/v1_1',
    cloudinaryCloudName: 'tokeet',
    cloudinaryUploadPreset: 'qpzoegqz',

    topDomain: '.tokeetmail.com',

    worldpayDeveloperId: '12345678',
    worldpayVersion: '1.0',

    dataFeedURL: 'https://datafeed.tokeet.com',

    airbnbClientId: '1r8sp56opjvvsc23k1vq4z5k6',
    airbnbRedirectUrl: 'https://api.tokeet.com/oauth/abbv2/',
    airbnbOAuthUrl: 'https://www.airbnb.com/oauth2/auth?scope=vr,messages_read,messages_write&',

    intercomAppId: 'ziijopdr',
    wootricToken: 'NPS-a11df413',

    tokeetAIContractId: '43a0e92b-43fd-4894-89b2-8af03f864220',
    tokeetAIContractSignerRole: 'Contact',

    smartDevicesContractId: 'd943cfc2-9181-4f51-b290-39470db9380a',
    smartDevicesContractSignerRole: 'Contact',
  },
}
