import { adapter, initialState } from './discount-code.state'
import { createReducer, on } from '@ngrx/store'
import {
  ActivateDiscountCodeComplete,
  AddDiscountCodeComplete,
  DeactivateDiscountCodeComplete,
  DeleteDiscountCodeComplete,
  DeleteDiscountCodesComplete,
  GetDiscountCodeComplete,
  LoadDiscountCodesComplete,
  UpdateDiscountCodeComplete,
} from './discount-code.actions'
import { upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadDiscountCodesComplete, (state, { items, reset }) => {
    if (reset) state = adapter.removeAll(state)
    return upsertMany(adapter, items, { ...state, isLoaded: true })
  }),
  on(AddDiscountCodeComplete, (state, { item }) => adapter.addOne(item, state)),
  on(GetDiscountCodeComplete, (state, { item }) => adapter.setOne(item, state)),
  on(UpdateDiscountCodeComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(ActivateDiscountCodeComplete, (state, { id }) => adapter.updateOne({ id, changes: { status: 1 } }, state)),
  on(DeactivateDiscountCodeComplete, (state, { id }) => adapter.updateOne({ id, changes: { status: 0 } }, state)),
  on(DeleteDiscountCodeComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteDiscountCodesComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
