import { Component, OnInit, Input } from '@angular/core'
import {
  Channel,
  ChannelService,
  ConnectedChannelAccountResponse,
  ChannelNameTokens,
  DisconnectChannel,
  DisconnectChannelComplete,
  removeRateMappingForChannel,
  ConfirmDialogService,
  Toaster,
  Destroyable,
  untilDestroy,
  InfoDialogService,
  epochToView,
} from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap, map } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import * as R from 'ramda'
import {
  RefreshConnectionsForChannelComplete,
  RemoveLocalConnectionsByIds,
} from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { selectConnectionsByChannelId } from '@tv3/store/connection/connection.selectors'
import { Connection } from '@tv3/store/connection/connection.model'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { CtripChannelService, TiketChannelService } from '@tokeet-frontend/channels'
import { TableType } from '@tv3/shared/empty-table/table-type'
import * as lodash from 'lodash'

@Component({
  selector: 'app-channel-accounts',
  templateUrl: './channel-accounts.component.html',
  styleUrls: ['./channel-accounts.component.scss'],
})
export class ChannelAccountsComponent extends Destroyable implements OnInit {
  @Input() channel: Channel

  tableType = TableType

  isLoading = true
  accounts$: Observable<any[]>

  refresh$ = new BehaviorSubject(true)

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    private infoDialog: InfoDialogService,
    private channelService: ChannelService,
    private tiketChannel: TiketChannelService,
    private ctripChannel: CtripChannelService
  ) {
    super()
  }

  ngOnInit(): void {
    this.accounts$ = this.refresh$.pipe(
      switchMap(() => {
        if (this.channel.name === ChannelNameTokens.Tiket) {
          return this.tiketChannel
            .getStatus()
            .pipe(map(({ hotelIds, connectedAt }) => lodash.map(hotelIds, (id) => ({ propertyId: id, connectedAt }))))
        } else if (this.channel.name === ChannelNameTokens.Trip) {
          return this.ctripChannel
            .getStatus()
            .pipe(map(({ hotelIds, connectedAt }) => lodash.map(hotelIds, (id) => ({ propertyId: id, connectedAt }))))
        } else {
          return this.channelService.getConnectedAccounts(this.channel.id)
        }
      }),
      tap(() => (this.isLoading = false))
    )

    this.actions
      .pipe(
        ofType(DisconnectChannelComplete.type),
        tap(({ channelId }) => {
          this.refresh$.next(true)
          // remove rate mappings for this channel
          this.store.dispatch(removeRateMappingForChannel({ channelId: channelId }))
        }),
        switchMap(({ channelId }) => this.store.pipe(select(selectConnectionsByChannelId, { id: channelId }), take(1))),
        untilDestroy(this)
      )
      .subscribe((connections: Connection[]) => {
        // remove local connection data
        this.store.dispatch(RemoveLocalConnectionsByIds({ connectionIds: R.map((c) => c.id, connections) }))
      })

    this.actions.pipe(ofType(RefreshConnectionsForChannelComplete), untilDestroy(this)).subscribe(() => {
      this.refresh$.next(true)
    })
  }

  onDisconnect(channelAccount: ConnectedChannelAccountResponse) {
    const channelId = channelAccount.channelId || this.channel.id
    const channelName = channelAccount.name || this.channel.name
    const propertyId = channelAccount.propertyId

    return this.confirmDialog
      .confirm({
        title: 'Disconnect this Account?',
        body: `This will unlink all rentals connected to this channel and disconnect this account from your AdvanceCM account. <br/>
        Are you sure you want to Disconnect this account?`,
      })
      .pipe(
        switchMap(() => {
          if (this.channel.name === ChannelNameTokens.Tiket) {
            return this.tiketChannel
              .disconnect(propertyId as string)
              .pipe(tap(() => this.toaster.success('Account disconnected successfully.')))
          } else {
            this.store.dispatch(DisconnectChannel({ channelName, channelId }))
            return of(true)
          }
        })
      )
      .subscribe()
  }

  onDetails(account) {
    const user = account.user_info

    const items = [
      ['Account', account.propertyId],
      ['Connected At', epochToView(account.createdOn || account.connectedAt)],
      // ['Hotel Name', lodash.get(account, 'rooms.0.hotelName', '...')],
    ]
    if (user) {
      items.push(['User', `${user.first_name || ''} ${user.last_name || ''}`.trim()])
    }

    const content = items.map((item) => `<div><b>${item[0]}:</b> ${item[1]}</div>`).join('\n')

    this.infoDialog.open({
      title: `Account #${account.propertyId}`,
      body: content,
    })
  }
}
