import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ConfirmDialogService, Destroyable, SaveForm, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectAllGuests } from '@tv3/store/guest/guest.selectors'
import { SearchGuests, UpdateGuest } from '@tv3/store/guest/guest.actions'
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators'
import * as R from 'ramda'
import { BehaviorSubject, EMPTY } from 'rxjs'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { Guest } from '@tv3/store/guest/guest.model'
import { ChangeInquiryGuest } from '@tv3/store/inquiry/inquiry-fields.actions'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { TruncatePipe } from '@tokeet-frontend/tv3-platform'
import { GuestService } from '@tv3/store/guest/guest.service'
import * as moment from 'moment'

@Component({
  selector: 'app-edit-guest',
  templateUrl: './edit-guest-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./edit-guest-dialog.component.scss'],
})
export class EditGuestDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    guestId: [''],
  })

  guestForm = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    ],
    name: ['', [Validators.required]],
    phone: [''],
    address: this.fb.group({
      address: [''],
      city: [''],
      state: [''],
      country: [],
      country_code: [],
      postal_code: [''],
    }),
  })

  guests$ = this.store.pipe(
    select(selectAllGuests),
    map((guests) =>
      R.map(
        (g) => ({
          ...g,
          label: `${g.name} - ${new TruncatePipe().transform(g.primaryEmail, 20)}`,
        }),
        guests
      )
    )
  )

  guestSearch = new BehaviorSubject<string>('')
  inquiry: Inquiry
  emails = []

  constructor(
    public dialogRef: MatDialogRef<EditGuestDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private guestService: GuestService,
    private dataCheckerService: DataCheckerService,
    private confirmDialog: ConfirmDialogService,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
    this.dataCheckerService.check([ChannelGuard])
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectInquiry, { id: this.data.inquiry.id }),
        switchMap((inquiry) =>
          this.guestService.get(inquiry.guestId).pipe(
            map((guest) => ({ inquiry, guest })),
            catchError(() => {
              this.toaster.error('Unable to open guest details')
              this.close()
              return EMPTY
            })
          )
        ),
        tap(({ inquiry, guest }) => this.setGuestForm(guest)),
        tap(({ inquiry, guest }) => this.setEmails(inquiry.guest)),
        untilDestroy(this)
      )
      .subscribe(({ inquiry, guest }) => {
        this.inquiry = inquiry
      })

    this.form.patchValue({ guestId: this.data.inquiry.guestId })

    this.form
      .get('guestId')
      .valueChanges.pipe(
        distinctUntilChanged(),
        switchMap((guestId) =>
          this.guests$.pipe(
            map((guests) => R.find((g) => g.id === guestId, guests)),
            take(1)
          )
        ),
        map((guest) => new Guest(guest)),
        untilDestroy(this)
      )
      .subscribe((guest) => {
        this.store.dispatch(ChangeInquiryGuest({ inquiry: this.inquiry, guest }))
      })

    this.store.dispatch(SearchGuests({ term: '' }))

    this.guestSearch
      .pipe(
        filter((name) => !R.isEmpty(name)),
        debounceTime(250),
        untilDestroy(this)
      )
      .subscribe((term) => {
        this.store.dispatch(SearchGuests({ term }))
      })
  }

  setEmails(guest: Guest) {
    this.emails = R.pipe(
      R.keys,
      R.map((e) => {
        let email = decodeURIComponent(e)
        return {
          email,
          primary: email === guest.primaryEmail,
        }
      })
    )(guest.email)
  }

  setGuestForm(guest: Guest) {
    if (!guest) {
      return
    }
    const address = guest.address
    this.guestForm.patchValue(
      {
        name: guest.name,
        email: guest.primaryEmail || '',
        birthday: guest.birthday ? moment(guest.birthday, 'MM/DD/YYYY') : '',
        wife: guest.wife ? guest.wife : '',
        phone: guest.phone,

        address: {
          address: address?.address,
          city: address?.city,
          country: address?.country,
          country_code: address?.countryCode,
          state: address?.state,
          postal_code: address?.postalCode,
        },
      },
      { emitEvent: false }
    )
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const { birthday, ...values } = form.getRawValue()
    this.store.dispatch(
      UpdateGuest({
        guestId: this.form.get('guestId').value,
        inquiry: this.data.inquiry,
        form: {
          ...values,
          birthday: moment.isMoment(birthday) ? moment(birthday).format('MM/DD/YYYY') : '',
        },
      })
    )
    this.close()
  }
}
