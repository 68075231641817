import { createAction, props } from '@ngrx/store'
import { CreateCustomChannelPayload, CustomChannelResponse, UpdateCustomChannelPayload } from './model'
import { Update } from '@ngrx/entity'

export const GetCustomChannels = createAction('[Custom Channel] Get all')
export const GetCustomChannelsComplete = createAction(
  '[Custom Channel] Get all complete',
  props<{ items: CustomChannelResponse[] }>()
)

export const CreateCustomChannel = createAction(
  '[Custom Channel] create',
  props<{ payload: CreateCustomChannelPayload }>()
)
export const CreateCustomChannelComplete = createAction(
  '[Custom Channel] create complete',
  props<{ item: CustomChannelResponse }>()
)

export const UpdateCustomChannel = createAction(
  '[Custom Channel] update',
  props<{ id: string; payload: UpdateCustomChannelPayload }>()
)
export const UpdateCustomChannelComplete = createAction(
  '[Custom Channel] update complete',
  props<{ change: Update<CustomChannelResponse> }>()
)

export const DeleteCustomChannel = createAction('[Custom Channel] Delete', props<{ id: string }>())
export const DeleteCustomChannelComplete = createAction('[Custom Channel] Delete complete', props<{ id: string }>())
