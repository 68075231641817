export const airbnbMonthlyPricingRuleDays = 28
export const airbnbWeeklyPricingRuleDays = 7

export enum AirbnbPricingRuleType {
  longTerm = 'STAYED_AT_LEAST_X_DAYS', // Long-term stay adjustment;
  lastMinutes = 'BOOKED_WITHIN_AT_MOST_X_DAYS', // Last-minute discount;
  earlyBird = 'BOOKED_BEYOND_AT_LEAST_X_DAYS', // Booking ahead discount.
}

export interface AirbnbPricingRule {
  rule_type: AirbnbPricingRuleType
  threshold_one: number // 7,
  price_change: number // -12,
  price_change_type: 'PERCENT'
}
