import { createAction, props } from '@ngrx/store'
import { Preference } from './preferences.model'
import { Update } from '@ngrx/entity'
import { BrandingRequest } from '@tv3/interfaces/requests/branding.request'
import { PreferenceRequest } from '@tv3/interfaces/requests/preference.request'

export const LoadPreferences = createAction('[Preferences] Load Start')
export const LoadPreferencesComplete = createAction('[Preferences] Load Complete', props<{ preference: Preference }>())

export const UpdatePreferences = createAction('[Preferences] Update', props<{ id: string; form: PreferenceRequest }>())
export const UpdatePreferencesComplete = createAction(
  '[Preferences] Update Complete',
  props<{ update: Update<Preference> }>()
)

export const UpdateBranding = createAction('[Branding] Update', props<{ request: BrandingRequest }>())
export const UpdateBrandingComplete = createAction(
  '[Branding] Update Complete',
  props<{ update: Update<Preference> }>()
)
