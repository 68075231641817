<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput type="number" formControlName="cleaning" placeholder="Cleaning fee" required />
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput type="number" formControlName="facility" placeholder="Facility usage fee" required />
      </mat-form-field2>
    </div>
  </div>
</form>
