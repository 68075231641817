import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Channel, Rental, Toaster } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { BDCContentProperty, BDCContentPropertyRoom, BDCProduct } from '@tv3/store/channel-content/models/bdc.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FormBuilder } from '@angular/forms'
import { AuthService } from '@tv3/services/auth.service'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import * as lodash from 'lodash'
import { BdcProductDialogService } from '@tv3/containers/channels/content/bdc/dialogs/bdc-product-dialog/bdc-product-dialog.service'
import { take } from 'rxjs/operators'
import { localeCompareSort } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-bdc-content-product-setup',
  templateUrl: './bdc-content-product-setup.component.html',
  styleUrls: ['./bdc-content-product-setup.component.scss'],
})
export class BdcContentProductSetupComponent implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() bdcProperty: BDCContentProperty
  get bdcRoom(): BDCContentPropertyRoom {
    return this.bdcProperty && lodash.get(this.bdcProperty.rooms, 0)
  }

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  products: BDCProduct[] = []
  dataSource = new MatTableDataSource<BDCProduct>([])
  displayedColumns = ['propertyName', 'roomName', 'rateName', 'edit']

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toaster: Toaster,
    private bdcProductDialog: BdcProductDialogService,
    private bdcContentService: BdcContentService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.reload()
  }

  reload() {
    this.bdcContentService.getProducts(this.bdcProperty.propertyId).subscribe((data) => {
      this.dataSource.data = data
      this.products = data
    })
  }

  onRemove(product: BDCProduct) {
    this.bdcContentService.removeProduct(product.propertyId, product.roomId, product.rateId).subscribe(() => {
      this.toaster.success('Product deleted successfully.')
      this.reload()
    })
  }

  onAdd() {
    this.bdcProductDialog
      .open({
        propertyId: this.bdcProperty.propertyId,
        roomId: this.bdcRoom.id,
        selectedRateIds: lodash.map(this.products, 'rateId'),
        availableCancelPolicies: lodash.map(this.bdcProperty.cancelPolicies, 'code'),
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.reload()
      })
  }
}
