<ng-container [formGroup]="form">
  <mat-form-field2 class="dial-code-select">
    <app-select
      [items]="filteredDialCodes"
      formControlName="code"
      bindLabel="codeDisplay"
      bindValue="code"
      placeholder="Code"
      [required]="required"
      [searchable]="true"
      (searchChange)="(onSearch)"
    >
      <ng-template selectItem let-item="item">
        <div class="d-flex align-items-center">
          <span class="flag-icon mr-1" [ngClass]="'flag-icon-' + item.cc" style="min-width: 18px"></span>
          <span class="flex-fill text-truncate">
            {{ item.codeDisplay }}
          </span>
        </div>
      </ng-template>
    </app-select>
    <mat-error *ngIf="codeCtrl.touched && codeCtrl.hasError('required')">Code is required.</mat-error>
    <mat-error *ngIf="codeCtrl.hasError('code')">Code is required.</mat-error>
  </mat-form-field2>
  <mat-form-field2 class="phone-number">
    <input type="text" formControlName="phone" matInput [placeholder]="phoneLabel" [required]="required" />
    <mat-error *ngIf="phoneCtrl.hasError('phone')"> {{ phoneLabel }} is invalid. </mat-error>
    <mat-error *ngIf="phoneCtrl.touched && phoneCtrl.hasError('required')"> {{ phoneLabel }} is required. </mat-error>
  </mat-form-field2>
</ng-container>
