import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-task-conversations',
  templateUrl: './task-conversations.component.html',
  styleUrls: ['./task-conversations.component.scss'],
})
export class TaskConversationsComponent {
  @Input() taskId: string
}
