import { Directive, Output, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core'

export class MoveEvent {
  x: number
  y: number
  offsetX: number
  offsetY: number
}

@Directive({
  selector: '[moveHandler]',
})
export class MoveDirective implements OnInit, OnDestroy {
  private oldRect?: MoveEvent

  @Output()
  public readonly moved = new EventEmitter<MoveEvent>()

  @HostListener('mousedown', ['$event'])
  public onResizeStart(event: MouseEvent) {
    this.oldRect = { x: event.clientX, y: event.clientY, offsetX: 0, offsetY: 0 }
    this.element.nativeElement.classList.add('resizing')
  }

  public constructor(private readonly element: ElementRef) {}

  ngOnInit(): void {
    document.addEventListener('mousemove', this.onResizing.bind(this))
    document.addEventListener('mouseup', this.onResizeEnd.bind(this))
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousemove', this.onResizing.bind(this))
    document.removeEventListener('mouseup', this.onResizeEnd.bind(this))
  }

  public onResizeEnd(event: MouseEvent) {
    if (!this.oldRect) return
    const rect = {
      x: event.clientX,
      y: event.clientY,
      offsetX: event.clientX - this.oldRect.x,
      offsetY: event.clientY - this.oldRect.y,
    }

    this.moved.emit(rect)
    this.oldRect = null
    this.element.nativeElement.classList.remove('resizing')
  }

  public onResizing(event: MouseEvent) {
    if (!this.oldRect) return
    const rect = {
      x: event.clientX,
      y: event.clientY,
      offsetX: event.clientX - this.oldRect.x,
      offsetY: event.clientY - this.oldRect.y,
    }

    this.moved.emit(rect)
    this.oldRect = rect
  }
}
