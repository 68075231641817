import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Channel, InfoDialogService, Rental, SaveForm } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import { AddExportedCalendarConnection } from '@tv3/store/connection/connection.actions'
import { set } from 'lodash'
import { Observable } from 'rxjs'
import { mapTo, switchMap, take } from 'rxjs/operators'
import {
  selectAllAvailableCustomICalChannelName,
  selectAvailableChannelsForNewExportedCalendar,
  selectAvailableRentalForNewExportedCalendar,
} from '@tv3/store/connection/connection.selectors'
import { AddExportedCalendarConnectionPayload } from '@tv3/store/connection/connection.types'

@Component({
  selector: 'app-export-calendar-dialog',
  templateUrl: './export-calendar-dialog.component.html',
  styleUrls: ['./export-calendar-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class ExportCalendarDialogComponent implements OnInit {
  form = this.fb.group({
    channelName: [''],
    rental: [undefined, [Validators.required]],
    channel: [undefined, [Validators.required]],
  })

  isChannelNameRequired = false
  availableChannelNames$ = this.store.pipe(select(selectAllAvailableCustomICalChannelName))

  rentals$: Observable<Rental[]>
  channels$: Observable<Channel[]>

  get channel() {
    return this.data.channel || this.form.get('channel').value
  }

  constructor(
    public dialogRef: MatDialogRef<ExportCalendarDialogComponent>,
    private store: Store<fromRoot.State>,
    private infoDialog: InfoDialogService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { channel?: Channel; rental?: Rental }
  ) {}

  ngOnInit(): void {
    if (this.data.channel) {
      this.rentals$ = this.store.pipe(
        select(selectAvailableRentalForNewExportedCalendar, { name: this.data.channel.name })
      )
    }

    if (this.data.rental) {
      this.channels$ = this.store.pipe(select(selectAvailableChannelsForNewExportedCalendar(this.data.rental.id)))
    }

    this.form.patchValue({ rental: this.data.rental, channel: this.data.channel })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  create(form: FormGroup) {
    const { rental, channel, channelName = '' } = form.getRawValue()

    const create$ = Observable.create((observer) => {
      const refId = Date.now().toString(36).toLowerCase()
      const calendarUrl = `https://calendars.tokeet.com/calendar/rental/${rental.account}/${rental.id}?ref_id=${refId}`
      const payload = {
        rental_id: rental.id,
        id: channel.id,
        name: channel.name,
        agent: channel.agent,
        type: channel.type,
        rental_name: rental.name || '',
        channel_rental_id: `${channel.id}-${rental.id}`,
        ref_id: refId,
        url: calendarUrl,
      } as AddExportedCalendarConnectionPayload

      if (channelName) {
        set(payload, 'attributes.channel_display_name', channelName.trim())
      }

      this.store.dispatch(AddExportedCalendarConnection({ payload }))

      observer.next(calendarUrl)
      observer.complete()
    })

    create$
      .pipe(
        switchMap((url) => {
          return this.infoDialog
            .open({
              title: 'Exported Calendar',
              body: `<p style="word-break: break-all;">Below is your exported calendar url: <br/> <a href="${url}" target="_blank">${url}</a></p>`,
              closeText: 'Done',
            })
            .pipe(mapTo(url))
        }),
        take(1)
      )
      .subscribe(() => {
        this.close()
      })
  }
}
