import { Injectable } from '@angular/core'
import {
  GroupRateService,
  Rate,
  RateService,
  Rental,
  RentalRates,
  selectOnce,
  selectRentalById,
} from '@tokeet-frontend/tv3-platform'
import { dateDiff } from '@tv3/pipes/utility/date-diff.pipe'
import * as moment from 'moment'
import * as R from 'ramda'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'
import { Store } from '@ngrx/store'

export enum BookingDatesLimitType {
  None,
  MinimumStay,
  MaximumStay,
}

@Injectable({
  providedIn: 'root',
})
export class RentalRateService {
  constructor(
    private store: Store<any>,
    private rateService: RateService,
    private groupRateService: GroupRateService
  ) {}

  checkDatesLimit(
    rental: Rental,
    arrive: number,
    depart: number,
    rateCategory?: string
  ): { limit: BookingDatesLimitType; data: any } {
    const baserate: any = rental.baseRate || {}
    const rates: Rate[] = this.getRentalRatesByCategory(rental, rateCategory)
    if (arrive && depart) {
      const nights = dateDiff(depart, arrive, true)
      const start = moment.utc(arrive * 1000)
      const hittedRate = R.find((rate: Rate) => {
        return start.isBetween(rate.start * 1000, rate.end * 1000, 'day', '[]')
      }, rates)

      const minimumStay = (hittedRate ? hittedRate.minimumStay : baserate.minimum) || 0
      const maximumStay = (hittedRate ? hittedRate.maximumStay : baserate.maximum) || Number.MAX_SAFE_INTEGER

      if (nights < minimumStay) {
        return { limit: BookingDatesLimitType.MinimumStay, data: { nights, minimumStay } }
      }

      if (nights > maximumStay) {
        return { limit: BookingDatesLimitType.MaximumStay, data: { nights, maximumStay } }
      }

      return { limit: BookingDatesLimitType.None, data: {} }
    }
  }

  getRateCategories(rentalId: string, noDefault = false): Observable<{ id: string; name: string }[]> {
    return combineLatest([
      this.store.pipe(
        selectOnce(selectRentalById(rentalId)),
        map((r) => r.rates || [])
      ),
      this.groupRateService
        .all()
        .pipe(map((rates) => lodash.filter(rates, (r) => lodash.includes(r.rentalIds, rentalId)))),
    ]).pipe(
      map((data) => lodash.flatten(data)),
      map((rates) => lodash.map(rates, (r) => r.category)),
      map((categories) => (noDefault ? categories : R.prepend('default', categories))),
      map((categories) => categories.sort()),
      map((categories) => R.uniq(categories)),
      map((categories) => R.map((c: string) => ({ id: c, name: lodash.capitalize(c) }), categories))
    )
  }

  getRentalRatesByCategory(rental: Rental, category = 'default'): Rate[] {
    if (R.isEmpty(rental)) {
      return []
    }
    return R.filter((rate) => {
      const rateCategory = rate.category || 'default'
      return rateCategory === category
    }, rental.rates || [])
  }

  // getDefaultRentalCostForDate(rental: Rental, date: moment.Moment): number {
  //   const unixEpoch = date.unix()
  //   const rates = this.getRentalRatesByCategory(rental, 'default')
  //   let targetRate
  //   for (let i = 0, len = rates.length; i < len; i++) {
  //     const rate = rates[i]
  //     if (
  //       (unixEpoch > rate.start && unixEpoch < rate.end) ||
  //       date.isSame(rate.start * 1000, 'day') ||
  //       date.isSame(rate.end * 1000, 'day')
  //     ) {
  //       targetRate = rate
  //       break
  //     }
  //   }
  //   const baseRate = rental.baseRate || { nightly: Number.NaN, weekend: Number.NaN }
  //   const weekendCost = targetRate ? targetRate.weekend : baseRate.weekend
  //   const nightCost = targetRate ? targetRate.nightly : baseRate.nightly
  //
  //   return date.isoWeekday() > 5 && weekendCost ? weekendCost : nightCost
  // }
}
