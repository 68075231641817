import {
  AddUser,
  AddUserComplete,
  LoadUsersComplete,
  RemoveUser,
  RemoveUserComplete,
  RemoveUsersComplete,
  UpdateUser,
  UpdateUserAttributesComplete,
  UpdateUserComplete,
  UpdateUserMFA,
  UpdateUserMFAComplete,
  UpdateUserSettings,
  UpdateUserSettingsComplete,
} from './user.actions'
import { adapter, initialState, State } from './user.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import { ActionFailed, updateOne } from '../utility'

export const reducer = createReducer(
  initialState,
  on(LoadUsersComplete, (state, { users }) => adapter.addAll(users, R.merge(state, { isLoaded: true }))),
  on(AddUserComplete, (state, { user }) => adapter.addOne(user, R.merge(state, { isUpdating: false }))),
  on(RemoveUserComplete, (state, { id }) => adapter.removeOne(id, R.merge(state, { isUpdating: false }))),
  on(RemoveUsersComplete, (state, { ids }) => adapter.removeMany(ids, { ...state, isUpdating: false })),
  on(
    UpdateUserComplete,
    UpdateUserSettingsComplete,
    UpdateUserAttributesComplete,
    UpdateUserMFAComplete,
    (state, { update }) => updateOne(adapter, update, R.merge(state, { isUpdating: false }))
  ),
  on(AddUser, UpdateUser, RemoveUser, UpdateUserSettings, UpdateUserMFA, (state) => ({ ...state, isUpdating: true })),
  on(ActionFailed, (state) => ({ ...state, isUpdating: false }))
)

export function userReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
