import { Component, EventEmitter, NgZone, ViewChild, Directive } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { filter } from 'rxjs/operators'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { TinyMceEditor, TinyMceEditorComponent } from '@tokeet-frontend/tv3-platform'

export interface IDataDictionaryDialogService {
  open(branding?: boolean)
}

@Directive()
export abstract class EmailTemplateAbstractComponent {
  form: FormGroup
  valid = new EventEmitter<boolean>()
  reset = new EventEmitter<boolean>()
  @ViewChild('tinyMceEditor') tinyMceEditor: TinyMceEditorComponent

  plainTextCtrl = new FormControl()

  isPlain = false

  constructor(
    protected fb: FormBuilder,
    protected ngZone: NgZone,
    protected dataDictionaryDialogService: IDataDictionaryDialogService
  ) {}

  onDataDictionary() {
    this.dataDictionaryDialogService
      .open(true)
      .pipe(filter((tag) => isSomething(tag)))
      .subscribe((tag) => {
        if (this.isPlain) {
          const plainTextValue = this.plainTextCtrl.value
          this.plainTextCtrl.patchValue(plainTextValue + tag)
        } else {
          try {
            this.tinyMceEditor.editor.insertContent(tag)
          } catch (e) {}
        }
      })
  }

  onSubjectDataDictionary(control: AbstractControl) {
    this.dataDictionaryDialogService
      .open()
      .pipe(filter((tag) => isSomething(tag)))
      .subscribe((tag) => {
        const value = `${control.value} ${tag}`
        control.setValue(value)
      })
  }

  onToggleInputType() {
    this.isPlain = !this.isPlain
  }

  onEditorSetup(editor: TinyMceEditor) {
    editor.ui.registry.addButton('datadic', {
      icon: 'book',
      tooltip: `You may insert data tokens using data dictionary.`,
      onAction: () =>
        this.ngZone.run(() => {
          this.dataDictionaryDialogService
            .open(true)
            .pipe(filter((tag) => isSomething(tag)))
            .subscribe((tag) => editor.insertContent(tag))
        }),
    })
  }
}
