import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-ru-wizard-complete',
  templateUrl: './connect-ru-wizard-complete.component.html',
  styleUrls: ['./connect-ru-wizard-complete.component.scss'],
})
export class ConnectRUWizardCompleteComponent implements OnInit {
  @Output() done = new EventEmitter()

  ngOnInit(): void {}
}
