import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import { GetGuestBookings } from '@tv3/store/guest/guest.actions'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { EditItemParent } from '@tv3/containers/guests/edit/edit-item.parent'
import { Guest, GuestBooking } from '@tv3/store/guest/guest.model'
import { OpenInquiryOverlay } from '@tv3/store/overlay/overlay.actions'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { Actions, ofType } from '@ngrx/effects'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { AddBookingComplete, DeleteInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import { InquiryIsUpdated } from '@tv3/store/inquiry/inquiry-fields.actions'
import { selectGuestBookingsLoading } from '@tv3/store/guest/guest.selectors'

@Component({
  selector: 'app-guest-booking-list',
  templateUrl: './guest-booking-list.component.html',
  styleUrls: ['./guest-booking-list.component.scss'],
})
export class EditGuestBookingListComponent extends Destroyable implements OnInit, EditItemParent {
  @ViewChild('paginator') set paginator(p: MatPaginator) {
    this.dataSource.paginator = p
  }
  @ViewChild(MatSort) set sort(p: MatSort) {
    this.dataSource.sort = p
  }

  guestItem: Guest
  @Input() set guest(g: Guest) {
    this.guestItem = g
    if (this.guestItem.bookingsInfo) {
      this.refreshTableData()
    } else {
      this.store.dispatch(GetGuestBookings({ guest: this.guestItem }))
    }
  }

  @Input() blockList: string[] = [] // hide booking in this list

  @Input() emptyMessage: string

  isLoading$ = this.store.pipe(select(selectGuestBookingsLoading))

  displayedColumns = [
    'inquirySource',
    'rental',
    // 'touchView',
    'guestArrive',
    'guestDepart',
    'nightsView',
    'personCountView',
    'total',
  ]

  dataSource = new MatTableDataSource<GuestBooking>()

  @Input() tableType = TableType.GuestBookingsList
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private inquirySharedDialogsService: InquirySharedDialogsService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.sortData = localeCompareSort

    this.refreshTableData()
    this.actions$
      .pipe(ofType(InquiryIsUpdated, AddBookingComplete, DeleteInquiryComplete), untilDestroy(this))
      .subscribe(() => {
        this.store.dispatch(GetGuestBookings({ guest: this.guestItem }))
      })
  }

  private refreshTableData() {
    const list = this.guestItem.bookingsInfo ? this.guestItem.bookingsInfo : []
    this.dataSource.data = R.filter((i) => this.blockList.indexOf(i.id) < 0, list)
    this.paginator?.firstPage()
  }

  getId() {
    return 'booking'
  }

  mainAction() {
    this.addItem()
  }

  addItem() {
    this.inquirySharedDialogsService.openAddInquiry({
      title: 'Create Booking',
      isBooking: true,
      start: null,
      end: null,
      defaults: {
        guestId: this.guestItem.id,
      },
    })
  }

  onBookingDetails(guestBooking: GuestBooking) {
    this.store.dispatch(OpenInquiryOverlay({ inquiryId: guestBooking.id }))
  }
}
