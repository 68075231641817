import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { take } from 'rxjs/operators'
import { WebsiteCustomDomainSslDialogComponent } from './website-custom-domain-ssl-dialog.component'
import { CustomDomainResponse } from '@tokeet-frontend/ssl'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class WebsiteCustomDomainSslDialogService {
  constructor(private dialog: DialogService) {}

  public open(domain: CustomDomainResponse) {
    const defaultConfig: MatDialogConfig = {
      width: '800px',
      height: 'auto',
      data: { domain },
    }

    return this.dialog
      .openVariableDialog(WebsiteCustomDomainSslDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(take(1))
  }
}
