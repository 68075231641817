import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { IntegrationService } from '@tokeet-frontend/integrations'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { availableMessageChannels } from '@tokeet-frontend/message'
import { filter, take } from 'rxjs/operators'
import * as lodash from 'lodash'

@Component({
  selector: 'message-sent-with',
  templateUrl: './message-sent-with.component.html',
  styleUrls: ['./message-sent-with.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSentWithComponent implements OnInit {
  @Input() set sentWith(sentWith: string) {
    if (this.isAppId(sentWith)) {
      this.integrationService
        .getAppInfo(sentWith)
        .pipe(filter(isSomething), take(1))
        .subscribe((app) => {
          this.sentWithString = app.name
          this.cd.detectChanges()
        })
    } else {
      this.sentWithString = lodash.head(lodash.split(sentWith, '-'))
    }
  }
  @Input() platform: 'tokeet' | 'sympl'
  @Input() badgeType: 'info' | 'secondary' = 'info'

  sentWithString: string

  constructor(private integrationService: IntegrationService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  isAppId(id: string) {
    return /^app_/.test(id)
  }

  getMessageChannel(ch: string) {
    if (!ch) {
      return null
    }
    return availableMessageChannels.find((t) => lodash.startsWith(t.channel, lodash.toLower(ch)))
  }
}
