import { createAction, props } from '@ngrx/store'
import { EntityTagsResponse } from './model'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'

export const LoadEntityTags = createAction('[EntityTag] Load EntityTags')
export const LoadEntityTagsComplete = createAction(
  '[EntityTag] Load EntityTags Complete',
  props<{ items: EntityTagsResponse[] }>()
)

export const GetEntityTags = createAction(
  '[EntityTag] get tags',
  props<{ entityType: DataEntityType; entityId: string }>()
)
export const GetEntityTagsComplete = createAction(
  '[EntityTag] get tags Complete',
  props<{ item: EntityTagsResponse }>()
)

export const AddEntityTags = createAction(
  '[EntityTag] Add tags',
  props<{ tags: string[]; entityType: DataEntityType; entityId: string; silent?: boolean }>()
)
export const AddEntityTagsComplete = createAction(
  '[EntityTag] Add tags Complete',
  props<{ item: EntityTagsResponse }>()
)

export const UpdateEntityTags = createAction(
  '[EntityTag] update tags',
  props<{ tags: string[]; entityType: DataEntityType; entityId: string; silent?: boolean }>()
)
export const UpdateEntityTagsComplete = createAction(
  '[EntityTag] update tags Complete',
  props<{ item: EntityTagsResponse }>()
)

export const SetEntityTags = createAction(
  '[EntityTag] set tags',
  props<{ tags: string[]; entityType: DataEntityType; entityId: string; silent?: boolean }>()
)
