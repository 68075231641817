import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { CreditInputDialogComponent } from './credit-input-dialog.component'

@Injectable()
export class CreditInputDialogService {
  constructor(private dialog: DialogService) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: '300px',
    }
    return this.dialog.openFixedDialog(CreditInputDialogComponent, defaultConfig)
  }
}
