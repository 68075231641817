import { Channel, ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { environment } from '../../../environments/environment'

export function hasChannelContentApi(channel: Channel) {
  let contentApiChannels = []
  if (environment.features.bdcContentApi) {
    contentApiChannels.push(ChannelNameTokens.BookingAPI)
  }
  if (environment.features.agodaContentApi) {
    contentApiChannels.push(ChannelNameTokens.AgodaAPI)
  }
  return contentApiChannels.indexOf(channel.name) > -1
}
