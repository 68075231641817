import { createAction, props } from '@ngrx/store'
import { Channel, ChannelNameTokens } from './channel.model'
import { Update } from '@ngrx/entity'
import {
  AddAirBnBV1ChannelPayload,
  AddFlipKeyChannelPayload,
  AddPPIDChannelPayload,
  UpdateAirBnBV1ChannelPayload,
} from './channel.types'

export const LoadChannels = createAction('[Channel] Load Channels')
export const LoadChannelsComplete = createAction('[Channel] Load Channels Complete', props<{ channels: Channel[] }>())

export const AddPPIDChannel = createAction(
  '[Channel] Add Channel Property ID Channel',
  props<{ channelId: string; data: AddPPIDChannelPayload; channelName: ChannelNameTokens }>()
)
export const AddPPIDChannelComplete = createAction(
  '[Channel] Add Channel Property ID Channel Complete',
  props<{ update: Update<Channel>; channelName: ChannelNameTokens }>()
)

export const AddFlipKeyChannel = createAction(
  '[Channel] Add FlipKey Channel',
  props<{ channelId: string; data: AddFlipKeyChannelPayload }>()
)
export const AddFlipKeyChannelComplete = createAction(
  '[Channel] Add FlipKey Channel Complete',
  props<{ update: Update<Channel> }>()
)

export const VerifyFlipKeyAccountForChannel = createAction(
  '[Channel] Verify FlipKey For Channel',
  props<{ channelId: string; data: AddFlipKeyChannelPayload }>()
)
export const VerifyFlipKeyAccountForChannelComplete = createAction(
  '[Channel] Verify FlipKey For Channel Complete',
  props<{ update: Update<Channel> }>()
)

export const AddAirBnBV1Channel = createAction(
  '[Channel] Add AirBnB V1 Channel',
  props<{ channelId: string; data: AddAirBnBV1ChannelPayload }>()
)
export const AddAirBnBV1ChannelComplete = createAction(
  '[Channel] Add AirBnB V1 Channel Complete',
  props<{ update: Update<Channel> }>()
)

export const UpdateAirBnBV1Channel = createAction(
  '[Channel] Update AirBnB V1 Channel',
  props<{ channelId: string; data: UpdateAirBnBV1ChannelPayload }>()
)
export const UpdateAirBnBV1ChannelComplete = createAction(
  '[Channel] Update AirBnB V1 Channel Complete',
  props<{ update: Update<Channel> }>()
)

export const DisconnectChannel = createAction(
  '[Channel] Disconnect Channel',
  props<{ channelName: ChannelNameTokens; channelId: string }>()
)
export const DisconnectChannelComplete = createAction(
  '[Channel] Disconnect Channel Complete',
  props<{ channelName: ChannelNameTokens; channelId: string }>()
)
