import { forEach } from 'lodash'

function filter(row: HTMLTableRowElement, term: string) {
  const text = row.textContent.toLowerCase()
  const val = term.toLowerCase()
  row.style.display = text.indexOf(val) === -1 ? 'none' : 'table-row'
}

export function searchTable(table: HTMLTableElement, term: string) {
  forEach(table.tBodies, (tbody: HTMLTableSectionElement) => {
    forEach(tbody.rows, (row) => filter(row, term))
  })
}
