<form [formGroup]="form">
  <div class="row">
    <div formArrayName="amenities" *ngFor="let ctrl of amenityCtrls; let i = index" class="col-sm-3">
      <mat-checkbox [formControlName]="i" [value]="ctrl.id" class="mt-primary">
        {{ amenities[i].name }}
      </mat-checkbox>
    </div>
    <div class="col-sm-12 my-3"><span class="text-danger">*</span> Please select at least one amenity.</div>
  </div>
</form>
