import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, ChannelNameTokens, Destroyable, selectChannelById, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ChannelHelperService } from '@tv3/containers/channels/channel-connect/channel-helper.service'
import { map, switchMap, take } from 'rxjs/operators'
import { selectConnectionViewsByChannelId } from '@tv3/store/connection/connection.selectors'
import { ConnectionStatusTexts, ConnectionView } from '@tv3/store/connection/connection.view'
import { Observable, of } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { CloseAllDialogs } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { AirbnbSyncSettingsDialogService } from '../../channel-connect/dialogs/airbnb-sync-settings/airbnb-sync-settings-dialog.service'
import { ChannelConnectWizardsService } from '../../channel-connect/channel-connect-wizards/index.service'
import { BookingFormulasOverlayService } from '@tv3/containers/formulas/overlay/booking-formulas-overlay.service'
import { isChannelReviewAvailable } from '@tokeet-frontend/reviews'

export enum ChannelDetailsDialogTab {
  MappedRentals,
  Accounts,
  Commissions,
  Reviews,
  ABBOpportunities,
  ABBNotifications,
  Formulas,
  TwoWaySync,
}

@Component({
  selector: 'app-api-channel-dialog',
  templateUrl: './api-channel-dialog.component.html',
  styleUrls: ['./api-channel-dialog.component.scss'],
})
export class ApiChannelDialogComponent extends Destroyable implements OnInit {
  channel: Channel
  connections: ConnectionView[] = []
  tabs = ChannelDetailsDialogTab
  currentTab = ChannelDetailsDialogTab.MappedRentals

  nameTokens = ChannelNameTokens

  isReviewAvailable = false

  hasDisabled$: Observable<boolean>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel },
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private channelConnectWizards: ChannelConnectWizardsService,
    private channelHelperService: ChannelHelperService,
    private bookingFormulasOverlay: BookingFormulasOverlayService,
    public dialogRef: MatDialogRef<ApiChannelDialogComponent>,
    private airbnbSyncSettingsDialog: AirbnbSyncSettingsDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(select(selectChannelById, { id: this.data.channel.id }), untilDestroy(this))
      .subscribe((channel) => {
        this.channel = channel
        this.isReviewAvailable = !!isChannelReviewAvailable(this.channel.name)
      })

    const connections$ = this.store.pipe(select(selectConnectionViewsByChannelId, { id: this.data.channel.id }))

    connections$.pipe(untilDestroy(this)).subscribe((connections) => {
      this.connections = [...connections]
    })

    this.hasDisabled$ = connections$.pipe(
      map((connections) => R.any((c) => c.statusText === ConnectionStatusTexts.Disabled, connections))
    )

    this.actions.pipe(ofType(CloseAllDialogs), untilDestroy(this)).subscribe(() => this.dialogRef.close())
  }

  close() {
    this.dialogRef.close()
  }

  onAddAccount() {
    this.channelConnectWizards.connect(this.channel)
  }

  onConnect() {
    this.channelConnectWizards.connect(this.channel)
  }

  onAirbnbSyncSettings() {
    this.airbnbSyncSettingsDialog.open(this.channel.id)
  }

  onDisconnect() {
    this.channelHelperService.disconnect(this.channel).subscribe()
  }

  onBookingFormula() {
    this.bookingFormulasOverlay.open(this.channel)
  }
}
