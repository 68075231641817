import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialogConfig } from '@angular/material/dialog'
import { AirbnbUpdatePriceSettingsDialogComponent } from './airbnb-update-price-settings-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'

@Injectable({
  providedIn: SharedModule,
})
export class AirbnbUpdatePriceSettingsDialogService {
  constructor(private sidePanel: DialogService) {}

  open(connection: Connection) {
    const defaultConfig: MatDialogConfig = {
      panelClass: ['drawer-full-80'],
      data: { connection },
    }

    return this.sidePanel.openDrawer(AirbnbUpdatePriceSettingsDialogComponent, defaultConfig)
  }
}
