import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { HoliduStatus, HoliduListingModel, HoliduListingViewModel, HoliduUpdateListingModel } from './model'
import { ListingsValidationFieldResult } from '../rental-validation-field'

@Injectable({
  providedIn: 'root',
})
export class HoliduChannelService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  //region Holidu
  getHoliduStatus() {
    const url = `@api/holidu/connection/status`

    return this.http.get<HoliduStatus>(url)
  }

  connectHolidu(providerId: string) {
    const url = `@api/holidu/connection/connect`

    return this.http.post<{ status: HoliduStatus; listings: HoliduListingModel[] }>(url, { providerId })
  }

  getHoliduListings() {
    const url = `@api/holidu/listings`

    return this.http.get<HoliduListingModel[]>(url)
  }

  getConnections() {
    return this.http.get<object[]>(`@api/holidu/connections/tokeet`)
  }

  createHoliduListing(listing: HoliduListingViewModel) {
    const url = `@api/holidu/listings`

    return this.http.post<HoliduListingModel>(url, { listing })
  }

  deleteHoliduListing(propertyId: string) {
    const url = `@api/holidu/listings/${propertyId}`

    return this.http.delete<{ listingId: number }>(url)
  }

  updateHoliduListing(listing: HoliduUpdateListingModel) {
    const url = `@api/holidu/listings`

    return this.http.put<HoliduListingModel>(url, { listing })
  }

  disconnectHolidu(providerId: string) {
    const url = `@api/holidu/connection/disconnect`

    return this.http.post<{ success: boolean }>(url, { providerId })
  }

  getHoliduRentalValidations() {
    const url = `@api/holidu/listings/validate/rentals`

    return this.http.post<ListingsValidationFieldResult>(url, {})
  }
}
