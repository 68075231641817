import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import {
  IntegrationAuthData,
  MAIL_CHIMP_EVENT_HANDLER,
  MailChimpConfig,
  MailChimpListItem,
  MailChimpSyncRequest,
  SlackChannel,
  SlackConfigItem,
  ThirdPartyIntegrationIdentifies,
  PAPIAppModel,
} from './integration.model'
import { deserializeArray, ENVIRONMENT, Toaster } from '@tokeet-frontend/tv3-platform'
import { capitalize, reduce } from 'lodash'
import { Store } from '@ngrx/store'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  private appCache = new Map<string, BehaviorSubject<PAPIAppModel>>()
  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private toaster: Toaster,
    @Inject(ENVIRONMENT) private environment
  ) {}

  request(data) {
    const url = `@api/integration/request`
    return this.http.post(url, data, { responseType: 'arraybuffer' })
  }

  getAppInfo(id: string) {
    if (this.appCache.has(id)) {
      return this.appCache.get(id)
    }
    const sub = new BehaviorSubject<PAPIAppModel>(null)
    this.appCache.set(id, sub)

    return this.http.get<PAPIAppModel>(`@papi/auth/apps/${id}`).pipe(
      switchMap((app) => {
        sub.next(app)
        return sub
      })
    )
  }

  getAuthApps() {
    const url = `@papi/auth/apps`
    return this.http.get<{ total: number; data: PAPIAppModel[] }>(url).pipe(map((res) => res.data))
  }

  disconnectApp(appId: string) {
    const url = `@papi/auth/apps/${appId}`
    return this.http.delete(url)
  }

  getOAuth(integration: ThirdPartyIntegrationIdentifies): Observable<IntegrationAuthData> {
    const url = `@api/${integration}/oauth`
    return this.http.get(url).pipe(map(IntegrationAuthData.deserialize))
  }

  getConfig(integration: ThirdPartyIntegrationIdentifies): Observable<any> {
    const url = `@api/${integration}/config`
    return this.http.get(url)
  }

  unauthorize(integration: ThirdPartyIntegrationIdentifies): Observable<any> {
    const url = `@api/${integration}/oauth`
    return this.http
      .delete(url)
      .pipe(tap(() => this.toaster.success(`${capitalize(integration)} disconnected successfully`)))
  }

  connectWheelhouse(apiKey: string): Observable<any> {
    const url = `@api/wheelhouse/connect`
    return this.http.post(url, { wheelhouseUserApiKey: apiKey })
  }

  getWheelhouseStatus(): Observable<boolean> {
    const url = `@api/wheelhouse/status`
    return this.http.get<{ connected: boolean }>(url).pipe(map((res) => !!res?.connected))
  }

  disconnectWheelhouse(): Observable<any> {
    const url = `@api/wheelhouse/disconnect`
    return this.http.post(url, {})
  }

  /////// MailChimp

  getMailChimpList(): Observable<MailChimpListItem[]> {
    const url = `@api/mailchimp/lists`
    return this.http.get(url).pipe(
      map((res) => res || []),
      deserializeArray<MailChimpListItem>(MailChimpListItem),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getMailChimpConfig(): Observable<{ [id: string]: MailChimpConfig }> {
    const url = `@api/mailchimp/config`
    return this.http.get(url).pipe(
      map((res) =>
        reduce(
          // @ts-ignore
          res.lists || {},
          (acc, item, id) => {
            acc[id] = MailChimpConfig.deserialize(item)
            return acc
          },
          {}
        )
      )
    )
  }

  updateMailChimpSync(payload: MailChimpSyncRequest) {
    const url = `@api/mailchimp/sync`
    return this.http.post(url, payload)
  }

  deleteMailChimpSync(mailchimpListId: string) {
    const url = `@api/mailchimp/unsync`
    return this.http.put(url, { handler: MAIL_CHIMP_EVENT_HANDLER, mailchimplist: mailchimpListId })
  }

  //////// Slack
  getSlackChannels(): Observable<SlackChannel[]> {
    const url = `@api/slack/channels`
    return this.http.get(url).pipe(
      map((res) => res || []),
      deserializeArray<SlackChannel>(SlackChannel)
    )
  }

  getSlackConfig(): Observable<{ [id: string]: SlackConfigItem }> {
    const url = `@api/slack/config`
    return this.http.get(url).pipe(
      map((res) =>
        reduce(
          res,
          (acc, item, id) => {
            acc[id] = SlackConfigItem.deserialize(item)
            return acc
          },
          {}
        )
      )
    )
  }

  addSlackChannelConfig(payload) {
    const url = `@api/slack/add`
    return this.http.post(url, payload)
  }

  removeSlackChannelConfig(id) {
    const url = `@api/slack/remove/${id}`
    return this.http.delete(url)
  }

  getConnectUrl(accountId: number, userId: string, integration: ThirdPartyIntegrationIdentifies): string {
    const state = `${accountId},${userId}`
    let url = ''
    switch (integration) {
      case ThirdPartyIntegrationIdentifies.Slack:
        const { slackOAuthUrl, slackClientId, slackRedirectUrl } = this.environment.config
        url = `${slackOAuthUrl}&scope=chat:write:bot channels:read&client_id=${slackClientId}&state=${state}&redirect_uri=${encodeURIComponent(
          slackRedirectUrl
        )}`
        break
      case ThirdPartyIntegrationIdentifies.Mailchimp:
        const { mailChimpOAuthUrl, mailChimpClientId, mailChimpRedirectUrl } = this.environment.config
        url = `${mailChimpOAuthUrl}&client_id=${mailChimpClientId}&redirect_uri=${encodeURIComponent(
          mailChimpRedirectUrl + '/' + accountId
        )}`
        break
      case ThirdPartyIntegrationIdentifies.Dropbox:
        const { dropboxOAuthUrl, dropboxClientId, dropboxRedirectUrl } = this.environment.config
        url = `${dropboxOAuthUrl}&client_id=${dropboxClientId}&state=${state}&redirect_uri=${encodeURIComponent(
          dropboxRedirectUrl
        )}`
        break
      case ThirdPartyIntegrationIdentifies.Quickbooks:
        url = this.environment.config.oauthUrl + '/auth/quickbooks?account=' + accountId
        break
      default:
        break
    }

    return url
  }
}
