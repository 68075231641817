import { Component, Inject, OnInit } from '@angular/core'
import { getLocalDateString, isSomething, Rental } from '@tokeet-frontend/tv3-platform'
import { from, Observable, of } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import * as moment from 'moment'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import {
  generateRentalParser,
  parseCurrency,
  parseDate,
  parseEmail,
  parseInquirySource,
  parseNumber,
  parseString,
} from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import { Guest } from '@tv3/store/guest/guest.model'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { AddGuestsComplete } from '@tv3/store/guest/guest.actions'
import { concatMap, flatMap, mergeMap, tap, toArray } from 'rxjs/operators'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import { BookingEngineCostRequest } from '@tv3/interfaces/requests/booking-engine-cost.request'
import { AddInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import { set } from 'lodash'
import { GuestService } from '@tv3/store/guest/guest.service'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { NoteService } from '@tv3/store/note/note.service'
import { NoteForm } from '@tv3/interfaces/forms/note.form'
import { AddNote } from '@tv3/store/note/note.actions'
import { ConfirmImportedBooking } from '@tv3/store/inquiry/inquiry-fields.actions'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { BookingCostResolver } from '@tokeet/cost-resolver'

function validBookingDateRange({ inquiry }) {
  const { arriveDate, departDate } = inquiry
  if (!moment(arriveDate).isBefore(departDate, 'day')) {
    return 'Arrive Date should be before Depart Date.'
  }

  return true
}

@Component({
  selector: 'app-import-inquiries-dialog',
  templateUrl: './import-inquiries-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-inquiries-dialog.component.scss'],
})
export class ImportInquiriesDialogComponent extends Destroyable implements OnInit {
  files: ParsedUploadFile<any>[] = []
  rentals: Rental[]

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Guest Name', field: 'guest.name', required: true, parse: parseString },
    { name: 'Guest Phone', field: 'guest.phone', parse: parseString },
    { name: 'Guest Email', field: 'guest.email', required: true, parse: parseEmail },
    {
      name: 'Arrive Date',
      field: 'inquiry.arriveDate',
      required: true,
      parse: parseDate,
      rowValidators: [validBookingDateRange],
    },
    { name: 'Depart Date', field: 'inquiry.departDate', required: true, parse: parseDate },
    { name: 'Adults', field: 'inquiry.adults', required: true, min: 1, parse: parseNumber },
    { name: 'Children', field: 'inquiry.children', default: 0, parse: parseNumber },
    { name: 'Source', field: 'inquiry.source', required: true, parse: parseInquirySource },
    {
      name: 'Rental',
      field: 'inquiry.rentalId',
      required: true,
      parse: (str, colDef) => generateRentalParser(this.rentals)(str, colDef),
    },
    { name: 'Total Cost', field: 'costAmount', parse: parseCurrency },
    {
      name: 'Confirmed',
      field: 'confirmed',
      parse: (str) => ({ value: ['true', 'confirmed', '1'].indexOf(R.toLower(str + '')) > -1 }),
    },
    { name: 'Note', field: 'notes', parse: parseString },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: [
      'Arrive Date, Depart Date, Adults, Guest Name, Guest Email, Source and Rental are required.',
      'Dates format "YYYY-MM-DD", such as "2016-02-14".',
      'Confirmed Inquiry: "confirmed", "1" and "true" on Confirmed column.',
      'You can add some notes for inquiries on Note column.',
    ],
    sample: [
      [
        'Guest Name',
        'Guest Phone',
        'Guest Email',
        'Arrive Date',
        'Depart Date',
        'Adults',
        'Children',
        'Source',
        'Rental',
        'Total Cost',
        'Confirmed',
        'Note',
      ],
      [
        'Iris Ferguson',
        '+1(111)111-11-11',
        'iris.ferguson@gmail.com',
        '2016-02-11',
        '2016-02-14',
        1,
        0,
        'tokeet',
        '1 Tribeca Luxury Two Bedroom close to WTC',
        123,
        1,
        'arrive by car',
      ],
      [
        'Kinley Weiss',
        '',
        'kinley.weiss@gmail.com',
        '2016-02-14',
        '2016-02-17',
        1,
        0,
        'tokeet',
        'Apartment 53',
        124,
        '',
        '',
      ],
    ],
  }

  guests: Guest[] = []

  constructor(
    public dialogRef: MatDialogRef<ImportInquiriesDialogComponent>,
    private guestService: GuestService,
    private inquiryService: InquiryService,
    private noteService: NoteService,
    private inquiryChargeService: InquiryChargeService,
    private store: Store<fromRoot.State>,
    protected toast: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { guests: Guest[] }
  ) {
    super()
  }

  ngOnInit() {
    this.guests = this.data.guests || []

    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  close() {
    this.dialogRef.close()
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  private tryToCreateGuests(newGuests: UpdateGuestRequest[]): Observable<Guest[]> {
    if (R.isEmpty(newGuests)) {
      return of([])
    }
    return this.guestService
      .createMany(newGuests)
      .pipe(tap((guests: Guest[]) => this.store.dispatch(AddGuestsComplete({ guests }))))
  }

  private tryToCreateInquiry({ inquiry, costAmount, notes, confirmed }): Observable<Inquiry> {
    const addInquiryForm: AddInquiryForm = inquiry as AddInquiryForm

    const format = 'YYYY-MM-DD'
    const arriveString = getLocalDateString(inquiry.arriveDate, format)
    const arrive = moment.utc(arriveString, format).unix()
    const departString = getLocalDateString(inquiry.departDate, format)
    const depart = moment.utc(departString, format).unix()

    const costRequest: BookingEngineCostRequest = {
      start: arrive,
      end: depart,
      adults: inquiry.adults,
      child: inquiry.children || 0,
      category: 'default',
      rid: inquiry.rentalId,
      discounts: [{ amount: 0 }],
    }

    if (costAmount) {
      costRequest.usercharge = costAmount
    }

    return this.inquiryChargeService.getEngineCost(costRequest).pipe(
      concatMap((engineCost) => {
        const quotes = BookingCostResolver.convertBookingEngine2Cost(engineCost, costAmount)
        const payload = this.inquiryService.createAddInquiryPayload({ form: addInquiryForm, quotes, cost: engineCost })
        return this.inquiryService.add(payload, true).pipe(
          tap((inquiry) => this.store.dispatch(AddInquiryComplete({ inquiry }))),
          // create notes, booking
          tap((i: Inquiry) => {
            if (notes) {
              this.store.dispatch(AddNote({ inquiry: i, form: { note: notes } as NoteForm, silent: true }))
            }
          }),
          tap((inquiry) => confirmed && this.store.dispatch(ConfirmImportedBooking({ inquiry })))
        )
      })
    )
  }

  create() {
    if (R.any((file) => file.isError, this.files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    //  get parsed csv data
    const csvItems = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )

    // set additional data
    let newGuests = []

    R.forEach(({ inquiry, guest }) => {
      // identity guest by email
      const existingGuest = R.find((existing) => existing.primaryEmail === guest.email, this.guests)

      set(inquiry, 'guestName', (existingGuest && existingGuest.name) || guest.name)
      set(inquiry, 'guestEmail', (existingGuest && existingGuest.primaryEmail) || guest.email)
      set(inquiry, 'guestId', R.path(['id'], existingGuest))
      if (!existingGuest) {
        newGuests.push(guest)
      }
    }, csvItems)

    newGuests = R.uniqBy((g) => g.email, newGuests)

    // try to create guests/inquiries with csv data
    of([])
      .pipe(
        // 1. create new guests
        concatMap(() => {
          return this.tryToCreateGuests(newGuests)
        }),
        tap((newlyCreatedGuests: Guest[]) => {
          // if the guest is new, assign guest id to inquiry
          R.forEach(({ inquiry, guest }) => {
            if (isSomething(inquiry.guestId)) {
              return
            }
            const g = R.pipe(
              R.defaultTo([]),
              R.find((newGuest: Guest) => {
                const newGuestEmail = R.pipe(R.defaultTo(''), R.toLower)(newGuest.primaryEmail)
                const csvGuestEmail = R.pipe(R.defaultTo(''), R.toLower)(guest.email)
                return newGuestEmail === csvGuestEmail
              })
            )(newlyCreatedGuests)
            if (!g) {
              return
            }

            set(inquiry, 'guestName', g.name)
            set(inquiry, 'guestEmail', g.primaryEmail)
            set(inquiry, 'guestId', g.id)
          }, csvItems)
        }),
        // 2. create inquiries
        concatMap(() => {
          // create inquiries one by one
          return of(csvItems).pipe(
            flatMap((item) => from(item)),
            mergeMap((item: any) => this.tryToCreateInquiry(item)),
            toArray(),
            tap(() => this.toast.success('Inquiries imported successfully.'))
          )
        })
      )
      .subscribe(
        () => {
          this.close()
        },
        (error) => {
          this.toast.error('Error when import data. ' + error)
          console.log(error)
        }
      )
  }
}
