import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './reducer'
import * as state from './state'
import { InventoryView } from './model'
import * as R from 'ramda'
import { selectRentalEntities } from '@tokeet-frontend/tv3-platform'

export const inventoryState = createFeatureSelector<state.State>('inventories')

export const selectAllInventories = createSelector(inventoryState, reducer.selectAll)

export const selectInventoriesLoaded = createSelector(inventoryState, (s: state.State) => s.isLoaded)
export const isUpdatingInventories = createSelector(inventoryState, (s: state.State) => s.isUpdating)

export const selectInventories = createSelector(
  selectAllInventories,
  selectRentalEntities,
  (inventories, rentalEntities) => {
    return R.map((inventory: InventoryView) => {
      const rental = inventory.rental_id ? rentalEntities[inventory.rental_id] : null

      return {
        ...inventory,
        rentalView: R.pathOr('', ['name'], rental),
        rental,
      }
    }, inventories) as InventoryView[]
  }
)

export const selectInventoryById = (id: string) =>
  createSelector(selectAllInventories, (items) => {
    return items?.find((item) => item.pkey === id)
  })
