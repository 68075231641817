import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Placement, NgbPopover } from '@ng-bootstrap/ng-bootstrap'

import { inquiryColors } from '@tv3/constants/inquiry-colors'

const Color = require('color')

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Input() activeColor: string

  @Input() size = 25
  @Input() popoverPlacement: Placement = 'auto'

  @Output() change = new EventEmitter<string>()

  @ViewChild('popover', { static: true }) popover: NgbPopover

  colors: string[] = []

  @Input('colors') set setColors(colors: 'inquiry' | string[]) {
    if (colors === 'inquiry') {
      colors = inquiryColors
    }

    this.colors = [null, ...colors]
  }

  get isPopoverOpen() {
    return this.popover.isOpen()
  }

  borderColor(color: string, activeColor: string) {
    if (!color) return '#ccc'
    if (activeColor !== color) return 'transparent'

    return Color(color).darken(0.25)
  }

  backgroundColor(color: string) {
    return color || '#fff'
  }

  onChangeColor(color: string) {
    this.activeColor = color
    this.change.emit(color)
  }
}
