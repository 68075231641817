import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-copy-icon',
  template: `<a
    href="javascript:;"
    class="d-inline-flex"
    ngxClipboard
    [cbContent]="content"
    matTooltip="Copy to clipboard"
  >
    <i class="cs-icon cs-icon-copy"></i>
    <i class="cs-icon cs-icon-copy-solid"></i>
  </a> `,
  styleUrls: ['./CopyIcon.component.scss'],
})
export class CopyIconComponent {
  @Input() content: string
}
