import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ChannelNameTokens, Rental, Toaster } from '@tokeet-frontend/tv3-platform'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { EMPTY, Observable } from 'rxjs'
import {
  AirBnBBookingPrefResponse,
  AirBnBPriceSettingsResponse,
  AirBnBUpdateBookingPrefPayload,
  AirBnBUpdatePriceSettingsPayload,
} from '@tv3/store/connection/connection.types'
import { catchError, map } from 'rxjs/operators'
import * as lodash from 'lodash'
import { HttpGetRequestCacheService } from '@tokeet-frontend/tv3-platform'

export interface AibrnbListingDetails extends Omit<Rental, 'status'> {
  status?: { status_category: string }
  pets_allowed: 0 | 1
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionAirbnbService {
  constructor(private http: HttpClient, private toaster: Toaster, private httpCache: HttpGetRequestCacheService) {}

  getPropertyStatus(propertyId: string | number): Observable<any> {
    const url = `@api/abbv2/publish/status`
    return this.http.post(url, { propertyId })
  }

  getVisibilityStatusV2(
    channelId: string,
    propertyId: string | number,
    roomId: string | number
  ): Observable<{ visible: boolean }> {
    const url = `@api/abbv2/listing/visibility/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.get<{ visible: boolean }>(url).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  setVisibilityStatusV2(
    channelId: string,
    propertyId: string | number,
    roomId: string | number,
    visible: boolean,
    payload?: any
  ) {
    const url = `@api/abbv2/listing/visibility/${channelId}?property=${propertyId}&room=${roomId}&visible=${visible}`
    return this.http.put(url, payload || {}).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  importRoom(channelName: ChannelNameTokens, channelId: string, roomId: string | number, propertyId: string | number) {
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(
      channelName
    )}/save/room/${roomId}/${channelId}?save=1&property=${propertyId}`
    return this.http.get(url).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  getBookingRules(
    channelName: ChannelNameTokens,
    channelId: string,
    propertyId: number,
    roomId: number
  ): Observable<AirBnBBookingPrefResponse> {
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(
      channelName
    )}/booking/rules/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  updateBookingRules(channelName: ChannelNameTokens, payload: AirBnBUpdateBookingPrefPayload) {
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/booking/rules`
    return this.http.put(url, payload).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  getPriceSettingsV2(channelId: string, propertyId: number, roomId: number): Observable<AirBnBPriceSettingsResponse> {
    const url = `@api/abbv2/price/settings/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.get<any>(url).pipe(
      map((res) => (lodash.isArray(res) ? res[0] : res)),
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  updatePriceSettingsV2(payload: AirBnBUpdatePriceSettingsPayload) {
    const url = `@api/abbv2/price/settings`
    return this.http.put(url, payload).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  setReviewStatusV2(channelId: string, propertyId: number, roomId: number, status: string) {
    const url = `@api/abbv2/listing/status/${channelId}?property=${propertyId}&room=${roomId}&status=${status}`
    return this.http.put(url, {}).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  syncImagesV2(channelId: string, propertyId: number, roomId: number) {
    const url = `@api/abbv2/listing/images/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.put(url, {}).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  deleteListingV2(channelId: string, propertyId: number, roomId: number) {
    const url = `@api/abbv2/listing/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.delete(url).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  getListingDetailsV2(propertyId: any, roomId: any) {
    const url = `@api/abbv2/listing/detail?listing=${roomId}&property=${propertyId}`
    return this.httpCache.cacheGetRequest<AibrnbListingDetails>(url)
  }

  updateListingDetailsV2(channelId: string, propertyId: number, roomId: number) {
    const url = `@api/abbv2/listing/details/${channelId}?property=${propertyId}&room=${roomId}`
    return this.http.put(url, {}).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  pushRoomsV2(propertyId: string | number, rentalId: string, listingId: string) {
    const url = `@api/abbv2/listing/room/sync`

    return this.http
      .post(url, {
        property: propertyId,
        listing: listingId,
        rental: rentalId,
      })
      .pipe(
        catchError((error) => {
          this.toaster.error(null, 'Error', error)
          return EMPTY
        })
      )
  }

  uploadTransactions(payload: {
    transactions: any[]
    rentalid: string
    sendto: string
  }): Observable<{ total: number; created: number; updated: number }> {
    const url = `@api/channel/airbnb/upload`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }
}
