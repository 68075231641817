import * as R from 'ramda'
import { startsWith } from 'lodash'

export interface AutomataTriggerSettings {
  hours: number
  template: string
  creator: string
  rental: string
  days: number
  users: string[]
  channel: string
  not_rentals: string[]
}

export interface AutomataTriggerAttributes {
  name?: string
  tags: string[]
  delayed?: number
  workflows: string[]
  bookingTags?: string[]
}

export interface AutomataTrigger {
  settings: AutomataTriggerSettings
  template: string
  status: number
  handler: string
  users: string[]
  archived: number
  pkey: string
  creator: string
  service: 'tv3'
  event: string
  created: number
  subscription: string
  lastupdate: number
  account: number
  attributes: AutomataTriggerAttributes

  name: string
  templateDescription: string
  templateName: string
  channelName: string
  rentalName: string
  recipientsNames: string
  skippedInquiries: string[]
  lastUpdateView: string
  lastUpdateShortView: string
  handlerName: string
  createdView: string
  eventName: string
}

/**
 * Weight and colors are taken from this document https://docs.google.com/spreadsheets/d/1leqGdiIUke9qljRWY7Ke8zoXwukz5JmCxape9CzYSvo/edit#gid=0
 */
export class TriggerEvents {
  static events = [
    {
      name: 'Guest Created',
      value: 'guest.create',
      color: '#0bab2f',
      icon: 'fal fa-users',
      weight: 1.1,
    },
    {
      name: 'Guest Updated',
      value: 'guest.update',
      color: '#969696',
      icon: 'fal fa-user-edit',
      weight: 1.7,
    },
    {
      name: 'Guest Deleted',
      value: 'guest.delete',
      color: '#ee3349',
      icon: 'fal fa-user-minus',
      weight: 2.3,
    },
    {
      name: 'Guest Message',
      value: 'message.create',
      color: '#969696',
      icon: 'fal fa-envelope-open-text',
      weight: 1.6,
    },

    {
      name: 'Inquiry Created',
      value: 'inquiry.create',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 1,
    },
    {
      name: 'Booking Confirmed',
      value: 'inquiry.book',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 3,
    },
    {
      name: 'Booking Cancelled',
      value: 'inquiry.cancel',
      color: '#ee3349',
      icon: 'fal fa-calendar-exclamation',
      weight: 5,
    },

    {
      name: 'Hold Created',
      value: 'event.create',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 2,
    },
    {
      name: 'Hold Deleted',
      value: 'event.delete',
      color: '#ee3349',
      icon: 'fal fa-calendar-exclamation',
      weight: 2.2,
    },

    {
      name: 'Invoice Created',
      value: 'invoice.create',
      color: '#0bab2f',
      icon: 'fal fa-file-invoice-dollar',
      weight: 3.1,
    },
    {
      name: 'Invoice Paid',
      value: 'invoice.paid',
      color: '#0bab2f',
      icon: 'fal fa-box-usd',
      weight: 5.1,
    },
    {
      name: 'Invoice Cancelled',
      value: 'invoice.cancel',
      color: '#ee3349',
      icon: 'fal fa-file-invoice-dollar',
      weight: 3.3,
    },
    {
      name: 'Time-based Event',
      value: 'time',
      color: '#969696',
      icon: 'fal fa-clock',
      weight: 0,
    },
    {
      name: 'iCal Import Error',
      value: 'system.error.ical.refresh',
      icon: 'fal fa-exclamation-triangle',
      color: '#ee3349',
      weight: 11.2,
    },
    {
      name: 'Critical Error',
      value: 'system.error',
      icon: 'fal fa-exclamation-triangle',
      color: '#ee3349',
      weight: 11.3,
    },
    {
      name: 'Channel Disabled',
      value: 'system.error.channel.disabled',
      icon: 'fal fa-exclamation-triangle',
      color: '#ee3349',
      weight: 11.1,
    },
    {
      name: 'Contract Sent',
      value: 'contract.sent',
      icon: 'fal fa-paper-plane',
      color: '#969696',
      weight: 3.5,
    },
    {
      name: 'Contract Viewed',
      value: 'contract.viewed',
      icon: 'far fa-eye',
      color: '#969696',
      weight: 3.53,
    },
    {
      name: 'Contract Signed',
      value: 'contract.signed',
      icon: 'fal fa-user-check',
      color: '#0bab2f',
      weight: 3.52,
    },
    {
      name: 'Contract Rejected',
      value: 'contract.rejected',
      icon: 'fal fa-user-minus',
      color: '#ee3349',
      weight: 3.51,
    },
    {
      name: 'Card Rejected',
      value: 'payment.card.rejected',
      icon: 'fal fa-user-minus',
      color: '#ee3349',
      weight: 3.24,
    },
    {
      name: 'Card Accepted',
      value: 'payment.card.accepted',
      icon: 'fal fa-credit-card',
      color: '#0bab2f',
      weight: 3.21,
    },
    {
      name: 'Card Declined',
      value: 'payment.card.declined',
      icon: 'fal fa-ban',
      color: '#ee3349',
      weight: 3.22,
    },
    {
      name: 'Card Deleted',
      value: 'payment.card.deleted',
      icon: 'cs-icon cs-icon-trash',
      color: '#ee3349',
      weight: 3.25,
    },

    {
      name: 'Hold Updated',
      value: 'event.update',
      icon: 'cs-icon cs-icon-pencil',
      color: '#969696',
      weight: 2.1,
    },
    {
      name: 'Invoice Sent',
      value: 'invoice.sent',
      color: '#969696',
      icon: 'fal fa-paper-plane',
      weight: 3.2,
    },
    {
      name: 'Expense Created',
      value: 'expense.create',
      color: '#0bab2f',
      icon: 'fal fa-file-invoice-dollar',
      weight: 9.1,
    },
    {
      name: 'Expense Updated',
      value: 'expense.update',
      color: '#ffc600',
      icon: 'fal fa-envelope-open-dollar',
      weight: 9.2,
    },
    {
      name: 'Expense Deleted',
      value: 'expense.delete',
      color: '#ee3349',
      icon: 'fal fa-box-usd',
      weight: 9.3,
    },
    {
      name: 'Task Created',
      value: 'task.create',
      color: '#0bab2f',
      icon: 'fal fa-terminal',
      weight: 4.1,
    },
    {
      name: 'Task Updated',
      value: 'task.update',
      color: '#ffc600',
      icon: 'fal fa-terminal',
      weight: 4.3,
    },
    {
      name: 'Task Deleted',
      value: 'task.delete',
      color: '#ee3349',
      icon: 'fal fa-terminal',
      weight: 4.4,
    },
    {
      name: 'Task Completed',
      value: 'task.complete',
      color: '#0bab2f',
      icon: 'fal fa-terminal',
      weight: 4.5,
    },
    {
      name: 'Rate Created',
      value: 'rate.create',
      color: '#0bab2f',
      icon: 'fal fa-money-bill-alt',
      weight: 4.5,
    },
    {
      name: 'Rate Updated',
      value: 'rate.update',
      color: '#ffc600',
      icon: 'fal fa-money-bill-alt',
      weight: 4.5,
    },
    {
      name: 'Rate Deleted',
      value: 'rate.delete',
      color: '#ee3349',
      icon: 'fal fa-money-bill-alt',
      weight: 4.5,
    },
  ]

  static timeevents = [
    {
      name: 'After Inquiry Created',
      value: 'time.after.inquiry.created',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 1.01,
    },
    {
      name: 'After Booking Confirmed',
      value: 'time.after.booking.confirm',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 3.01,
    },
    {
      name: 'After Booking Cancelled',
      value: 'time.after.booking.cancel',
      color: '#ee3349',
      icon: 'fal fa-calendar-exclamation',
      weight: 5.01,
    },
    {
      name: 'Before Check-in',
      value: 'time.before.booking.start',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 8,
    },
    {
      name: 'After Check-in',
      value: 'time.after.booking.start',
      color: '#0bab2f',
      icon: 'fal fa-calendar-star',
      weight: 9,
    },
    {
      name: 'Before Check-out',
      value: 'time.before.booking.end',
      color: '#ee3349',
      icon: 'fal fa-calendar-star',
      weight: 10,
    },
    {
      name: 'After Check-out',
      value: 'time.after.booking.end',
      color: '#ee3349',
      icon: 'fal fa-calendar-star',
      weight: 11,
    },
    {
      name: 'Before Invoice Due',
      value: 'time.before.invoice.due',
      color: '#ffc600',
      icon: 'fal fa-calendar-star',
      weight: 6,
    },
    {
      name: 'After Invoice Due',
      value: 'time.after.invoice.due',
      color: '#ffc600',
      icon: 'fal fa-calendar-exclamation',
      weight: 7,
    },
    {
      name: 'Rental Access Created',
      value: 'rentalaccess.created',
      color: '#ffc600',
      icon: 'fal fa-key',
      weight: 7,
    },
    {
      name: 'Rental Access Updated',
      value: 'rentalaccess.updated',
      color: '#ffc600',
      icon: 'fal fa-key',
      weight: 7,
    },
  ]

  static allWeightedEvents() {
    return R.filter((e: any) => e.weight !== 0, R.concat(TriggerEvents.events, TriggerEvents.timeevents))
  }

  static allSortedEvents() {
    return R.sort((a: any, b: any) => a.weight - b.weight, TriggerEvents.allWeightedEvents())
  }

  static getWeight(trigger: AutomataTrigger): number {
    const weightedEvent = R.find((e: any) => e.value === trigger.event, TriggerEvents.allWeightedEvents())
    if (!R.isNil(weightedEvent)) {
      return weightedEvent.weight
    } else {
      return 0
    }
  }

  static getEventName(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events)
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents)
    }
    return R.isNil(event) ? null : event.name
  }

  static getEventColor(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events)
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents)
    }
    return R.isNil(event) ? null : event.color
  }

  static getEventIcon(value: string): string | null {
    let event = R.find(R.propEq('value', value), TriggerEvents.events)
    if (R.isNil(event)) {
      event = R.find(R.propEq('value', value), TriggerEvents.timeevents)
    }
    return R.isNil(event) ? null : event.icon
  }

  static isInquiry(event: string): boolean {
    return startsWith(event, 'inquiry')
  }

  static allowsRental(event: string): boolean {
    return /^rate|^task|^inquiry|^time|^expense|^event|^invoice/.test(event)
  }

  static allowsChannel(event: string): boolean {
    return this.isInquiry(event) || this.isTime(event) || this.isTask(event)
  }

  static isTime(event: string): boolean {
    return startsWith(event, 'time')
  }

  static isTask(event: string): boolean {
    return startsWith(event, 'task')
  }

  static isInvoice(event: string): boolean {
    return startsWith(event, 'invoice')
  }

  static isExpense(event: string): boolean {
    return startsWith(event, 'expense')
  }

  static isTimeInvoice(event: string): boolean {
    return event === 'time.before.invoice.due' || event === 'time.after.invoice.due'
  }

  static isSkippable(event: string): boolean {
    return this.isTime(event) || this.isInquiry(event) || this.isInvoice(event)
  }

  static isCard(event: string): boolean {
    return startsWith(event, 'payment')
  }

  static isRate(event: string) {
    return startsWith(event, 'rate')
  }
}
