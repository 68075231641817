import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { select, Store } from '@ngrx/store'
import {
  BookingFormulaGuard,
  BookingFormulaResponse,
  BookingFormulaResponseView,
  LoadBookingFormulas,
  RemoveBookingFormula,
  RemoveBookingFormulas,
  selectAllBookingFormulaViews,
  selectChannelsForFormulas,
  ToggleBookingFormulaStatus,
} from '@tokeet-frontend/booking-formula'
import {
  DataCheckerService,
  filterPredicateFactory,
  isEmptyTable,
  localeCompareSort,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { ConfirmDialogService, Rental, selectAllRentals, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { selectLinkedRentalsForChannel } from '@tv3/store/connection/connection.selectors'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { map } from 'rxjs/operators'
import { BookingFormulaDialogService } from '../booking-formula-dialog/booking-formula-dialog.service'

@Component({
  selector: 'app-booking-formulas-table',
  templateUrl: './booking-formulas-table.component.html',
  styleUrls: ['./booking-formulas-table.component.scss'],
})
export class BookingFormulasTableComponent extends SelectableRow<BookingFormulaResponseView> implements OnInit {
  @Input() linkedRentals = true
  @Input() channel: { id: string; name: string }

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['select', 'created', 'name', 'channelView', 'rentalsView', 'months', 'status', 'edit']

  isEmptyTable$ = isEmptyTable(this.dataSource)

  formulas: BookingFormulaResponseView[] = []
  rentals: Rental[] = []
  channels$ = this.store.pipe(select(selectChannelsForFormulas))

  filters = this.fb.group({
    status: [],
    rentals: [[]],
    channels: [[]],
  })

  constructor(
    private bookingFormulaDialog: BookingFormulaDialogService,
    private fb: FormBuilder,
    private store: Store<any>,
    private confirmDialog: ConfirmDialogService,
    protected storage: UserStorage,
    private dataChecker: DataCheckerService,
    private toaster: Toaster
  ) {
    super(storage)
  }

  ngOnInit() {
    this.dataChecker.check([BookingFormulaGuard])

    this.dataSource.data = []
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.dataSource.filterPredicate = filterPredicateFactory(this.displayedColumns)

    this.store
      .pipe(
        select(this.linkedRentals ? selectLinkedRentalsForChannel(this.channel?.id) : selectAllRentals),
        map((items) => lodash.sortBy(items, (t) => lodash.toLower(t.name)))
      )
      .subscribe((items) => {
        this.rentals = items
      })

    this.store.pipe(select(selectAllBookingFormulaViews)).subscribe((items) => {
      this.formulas = items
      this.filterData()
    })

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.filterData()
    })

    if (this.channel) {
      this.filters.patchValue({ channels: [this.channel.id] })
    }
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  filterData() {
    const filters = this.filters.getRawValue()
    let filteredData: BookingFormulaResponseView[] = [...this.formulas]
    if (filters.status) {
      filteredData = lodash.filter(filteredData, (item) => {
        return (filters.status === 'active' && item.status == 1) || (filters.status === 'inactive' && !item.status)
      })
    }

    if (!lodash.isEmpty(filters.rentals)) {
      filteredData = lodash.filter(filteredData, (item) => {
        return lodash.intersection(item.rentals || [], filters.rentals).length > 0
      })
    }

    if (!lodash.isEmpty(filters.channels)) {
      filteredData = lodash.filter(filteredData, (item) => {
        return lodash.includes(filters.channels, item.channel_id)
      })
    }

    this.dataSource.data = filteredData
  }

  onAdd() {
    this.bookingFormulaDialog.open({ channel: this.channel })
  }

  onEdit(item: BookingFormulaResponse) {
    this.bookingFormulaDialog.open({
      channel: this.channel,
      formula: item,
    })
  }

  onToggleStatus(item: BookingFormulaResponse) {
    this.store.dispatch(ToggleBookingFormulaStatus({ id: item.pkey, status: item.status === 1 ? false : true }))
  }

  onDelete(item: BookingFormulaResponse) {
    this.confirmDialog
      .confirm({
        title: 'Delete this booking formula?',
        body: 'Are you sure you want to delete this booking formula? This cannot be undone',
      })
      .subscribe(() => {
        this.store.dispatch(RemoveBookingFormula({ id: item.pkey }))
      })
  }

  onDeleteSelected() {
    const selectedIds = this.selection.selected.map((item) => item.pkey)

    if (selectedIds.length < 1) {
      this.toaster.warning(`Please select at least one formula.`)
      return
    }

    this.confirmDialog
      .confirm({
        title: 'Delete selected booking formulas?',
        body: 'Are you sure you want to delete selected booking formulas?',
      })
      .subscribe(() => {
        this.store.dispatch(RemoveBookingFormulas({ ids: selectedIds }))
      })
  }

  onRefresh() {
    this.store.dispatch(LoadBookingFormulas())
  }
}
