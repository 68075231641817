import { Component, EventEmitter, Input, Output } from '@angular/core'
import { isImportedFromABBV2 } from '@tokeet-frontend/inquiry'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as R from 'ramda'
import { Rental, Toaster } from '@tokeet-frontend/tv3-platform'
import { InquiriesListService } from '@tv3/containers/inquiries/shared/inquiries-list.service'
import { InquiryDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-dialogs.service'
import { DownloadInquiriesDialogService } from '../../dialogs/scoped/download/download-inquiries-dialog.service'

@Component({
  selector: 'app-inquiries-action-btn',
  templateUrl: './inquiries-action-btn.component.html',
  styleUrls: ['./inquiries-action-btn.component.scss'],
})
export class InquiriesActionBtnComponent {
  @Input() inquiries: Inquiry[] = []
  @Input() rentals: Rental[] = []
  @Input() filters

  items: Inquiry[] = []

  @Input() btnLabel = 'Action'
  @Input() btnClass = 'btn btn-secondary-warning'
  @Input() dropdownRight = true

  @Input('selection')
  set selectedInquiries(inquiries: Inquiry[]) {
    this.items = inquiries || []
    this.isAnyUnRead = R.any((i: Inquiry) => !i.read, inquiries) || R.isEmpty(inquiries)
    this.isAnyUnArchived = R.any((i: Inquiry) => !i.archived, inquiries) || R.isEmpty(inquiries)
    this.isAnyNoFollow = R.any((i: Inquiry) => !i.followup, inquiries) || R.isEmpty(inquiries)
    this.isAnyUnpaid = R.any((i: Inquiry) => !i.paid, inquiries) || R.isEmpty(inquiries)
    this.isAnyImportedFromABBV2 = R.any((i: Inquiry) => isImportedFromABBV2(i), inquiries)
  }
  @Output() deselect = new EventEmitter()

  isAnyUnRead = false
  isAnyUnArchived = false
  isAnyNoFollow = false
  isAnyUnpaid = false
  isAnyImportedFromABBV2 = false

  constructor(
    private toaster: Toaster,
    private dialogsService: InquiryDialogsService,
    private inquiriesListService: InquiriesListService,
    private downloadInquiriesDialog: DownloadInquiriesDialogService
  ) {}

  getSelected(field?: string) {
    if (field) {
      return R.map((i: Inquiry) => i[field], this.items)
    } else {
      return this.items
    }
  }

  onLink() {
    const ids = this.getSelected('id')
    this.inquiriesListService.link(ids).subscribe(() => {
      this.deselect.next()
    })
  }

  onImport() {
    const selected = this.getSelected()
    this.inquiriesListService.importFromAirbnb(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onArchive() {
    const selected = this.getSelected()
    this.inquiriesListService.archive(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onUnArchive() {
    const selected = this.getSelected()
    this.inquiriesListService.unArchive(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onFollowUp() {
    const selected = this.getSelected()
    this.inquiriesListService.followUp(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onNoFollowUp() {
    const selected = this.getSelected()
    this.inquiriesListService.noFollowUp(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onRead() {
    const selected = this.getSelected()
    this.inquiriesListService.read(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onUnRead() {
    const selected = this.getSelected()
    this.inquiriesListService.unRead(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onPaid() {
    const selected = this.getSelected()
    this.inquiriesListService.paid(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onUnpaid() {
    const selected = this.getSelected()
    this.inquiriesListService.unpaid(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  onAddTags() {
    const selected = this.getSelected()
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one inquiry.`)
      return
    }
    this.dialogsService.openAddTags(selected)
  }

  onDelete() {
    const selected = this.getSelected()
    this.inquiriesListService.delete(selected).subscribe(() => {
      this.deselect.next()
    })
  }

  getFilteredData() {
    const selected = this.getSelected()
    return selected.length ? selected : this.inquiries
  }

  onDownload() {
    const filteredData = this.getFilteredData()
    this.downloadInquiriesDialog.open(filteredData, this.filters, this.rentals)
  }

  onExportReport() {
    const filteredData = this.getFilteredData()
    this.dialogsService.openExport(filteredData)
  }

  onImportCSV() {
    this.dialogsService.openImport()
  }
}
