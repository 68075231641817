import { deserialize, Serializable, Expose, Type } from '@tokeet-frontend/tv3-platform'

export function getSourceType(object: any) {
  switch (object['_source'].object) {
    case 'guest':
      return GuestSearchResult
    case 'inquiry':
      return InquirySearchResult
    case 'invoice':
      return InvoiceSearchResult
    case 'hold_event':
      return HoldEventSearchResult
    default:
      throw new Error(`Unknown search result type: '${object['_source'].object}'`)
  }
}

export class HoldEventSearchResult extends Serializable<HoldEventSearchResult> {
  @Expose({ name: 'pkey' })
  id: string

  end: number
  title: string
  start: number
  account: number
  expires: number
  created: number
  type: number

  object: 'hold_event'

  static deserialize(data: object): HoldEventSearchResult {
    return deserialize(HoldEventSearchResult, data)
  }
}

export class InvoiceSearchResult extends Serializable<InvoiceSearchResult> {
  @Expose({ name: 'pkey' })
  id: string

  object: 'invoice'

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'inquiry_id' })
  inquiryId: string

  @Expose({ name: 'due_date' })
  dueDate: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  title: string
  status: number
  created: number
  account: number

  static deserialize(data: object): InvoiceSearchResult {
    return deserialize(InvoiceSearchResult, data)
  }
}

export class GuestSearchResult extends Serializable<GuestSearchResult> {
  @Expose({ name: 'pkey' })
  id: string

  object: 'guest'

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'primaryemail' })
  primaryEmail: string

  @Expose({ name: 'lastmessage' })
  lastMessage: number

  title: string
  source: string
  created: number
  account: number

  static deserialize(data: object): GuestSearchResult {
    return deserialize(GuestSearchResult, data)
  }
}

export class InquirySearchResult extends Serializable<InquirySearchResult> {
  @Expose({ name: 'pkey' })
  id: string

  object: 'inquiry'

  @Expose({ name: 'num_child' })
  numChild: number

  @Expose({ name: 'guest_arrive' })
  guestArrive: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'num_adults' })
  numAdults: number

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'guest_depart' })
  guestDepart: number

  @Expose({ name: 'inquiry_source' })
  inquirySource: string

  @Expose({ name: 'received_on' })
  receivedOn: number

  title: string
  booked: number
  account: number
  created: number
  touch: number

  static deserialize(data: object): InquirySearchResult {
    return deserialize(InquirySearchResult, data)
  }
}

export class ESResult extends Serializable<ESResult> {
  @Expose({ name: '_id' })
  id: string

  @Expose({ name: '_type' })
  type: 'tokeet_data'

  @Expose({ name: '_score' })
  score: number

  @Expose({ name: '_index' })
  accountId: string

  @Expose({ name: '_source' })
  @Type((opts) => getSourceType(opts.object))
  source: InvoiceSearchResult | InquirySearchResult | GuestSearchResult

  static deserialize(data: object): ESResult {
    return deserialize(ESResult, data)
  }
}
