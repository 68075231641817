import { EventEmitter, Inject, Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators'
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs'
import { isArray } from 'lodash'
import * as R from 'ramda'
import { LoginCredentials } from '../models/login/login-credentials'
import { LoginResponse } from '../models/login/login-response'
import {
  isSomething,
  LocalStorage,
  Toaster,
  User,
  USER_ROLES as USER_ROLES_DATA,
  UserService,
  CURRENT_USER,
  CURRENT_ACCOUNT_ID,
  AUTH_TOKEN,
  MFADeliveryMethod,
} from '@tokeet-frontend/tv3-platform'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import { roles } from '@tv3/permissions/roles'
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions'
import { IntercomService } from '@tv3/services/intercom.service'
import { WootricService } from '@tv3/services/wootric.service'
import * as Sentry from '@sentry/browser'
import { SubscriptionDto } from '@tv3/store/plan/plan.model'
import { IAuthService, ResetPasswordPayload } from '@tokeet-frontend/auth'
import { GoogleTagManagerService } from './google-tag-manager.service'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import * as moment from 'moment'
import { CookieService } from 'ngx-cookie-service'

export const KEY_TOKEN = 'TOKEN'
export const KEY_USER = 'USER'
export const USER_ROLE = 'USER_ROLE'
export const USER_PERMISSIONS = 'USER_PERMISSIONS'
export const BYPASS_MFA_TOKEN = 'BYPASS_MFA_TOKEN'

export interface UserRole {
  name: string
  value: number
}

export interface Pre2FactorResponse {
  channel: string[]
  to: string
  pkey: string
  success: boolean
  message?: string
}

export interface TwoFactorCodeVerificationPayload {
  pkey: string
  code: string
  channel: string
  keepalive?: boolean
}

interface UserRoles {
  [role: string]: UserRole
}

declare const window: any

export const USER_ROLES = USER_ROLES_DATA as UserRoles

@Injectable({ providedIn: 'root' })
export class AuthService implements IAuthService {
  userEvent$ = new EventEmitter<'login' | 'logout'>()

  constructor(
    private http: HttpClient,
    private storage: LocalStorage,
    private toaster: Toaster,
    private roleService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
    private cookie: CookieService,
    private userService: UserService,
    private intercomService: IntercomService,
    private googleTagManagerService: GoogleTagManagerService,
    private amplitudeService: AmplitudeService,
    private wootricService: WootricService,
    @Inject(CURRENT_USER) private user$: BehaviorSubject<User>,
    @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>,
    @Inject(AUTH_TOKEN) private token$: BehaviorSubject<string>
  ) {
    const token = storage.get(KEY_TOKEN)
    const user = storage.get(KEY_USER)

    if (token) this._token = token
    if (user) this._user = User.deserialize(user)
  }

  private _token: string = null

  get token() {
    return this._token
  }

  set token(token) {
    this._token = token
    this.storage.set(KEY_TOKEN, token)
    this.token$.next(token)
  }

  setApp() {
    const now = moment().unix()
    try {
      localStorage.setItem('app_in_use', `tv3@${now}`)
    } catch (e) {}
  }

  private _user: User = null

  get user() {
    return this._user
  }

  get accountId() {
    return this.user ? this.user.account : null
  }

  set user(user) {
    this._user = user
    this.storage.set(KEY_USER, user.serialize())
    this.user$.next(user)
    this.accountId$.next(user?.account)
  }

  get isAuthenticated() {
    return !!this._token
  }

  isReadOnly() {
    return this.hasRole('readOnly')
  }

  hasRole(role: keyof typeof USER_ROLES_DATA) {
    if (!this.user) return false

    const roleValue = USER_ROLES[role] && USER_ROLES[role].value
    const userRoles = this.user.roles

    if (!roleValue || !isArray(userRoles)) return false

    return userRoles.includes(roleValue)
  }

  subscriptions(loginResponse: LoginResponse): Observable<{ subscriptions: SubscriptionDto[]; card: any }> {
    const url = '@api/subscribe/all'

    return this.http.get<{ subscriptions: SubscriptionDto[]; card: any }>(url, {
      headers: { Authorization: loginResponse.token },
    })
  }

  login(credentials: LoginCredentials, skipIntercomBoot = false) {
    const data = {
      username: credentials.email,
      password: credentials.password,
      keepalive: credentials.keepAlive,
      app: 'tokeet',

      mfa_bypass: undefined,
    }
    const mfa_bypass = this.getBypassMFAToken(credentials.email)
    if (mfa_bypass) {
      data.mfa_bypass = mfa_bypass
    }

    return this.http.post('@api/user/login', data, { observe: 'response' }).pipe(
      switchMap((response) => {
        const resData = response.body as any
        if (response.status === 202) {
          if (!resData.success) {
            return throwError(new Error(resData.message || 'Something wrong, please try it again later'))
          }
          // it's a 2fa response
          return of({ data: resData as Pre2FactorResponse, status: '2fa' })
        } else {
          const res = LoginResponse.deserialize(resData)
          this.saveBypassMFAToken(res.mfa_bypass, res.user.primaryEmail)
          return this.postLogin(res, skipIntercomBoot).pipe(map((data) => ({ data, status: 'success' })))
        }
      }),
      catchError((error) => {
        this.loginError(error)
        return throwError(error)
      })
    )
  }

  loginByToken(token: string) {
    this.token = token
    this.setApp()

    return this.http.get(`@api/user/`).pipe(
      tap((response) => {
        this.user = User.deserialize(response)
        this.setRole(this.user)

        this.intercomService.boot(this.user)
        this.googleTagManagerService.update(this.user)
        this.amplitudeService.setUser(this.user)
        this.intercomService.track('tokeet-user-login')
        this.wootricService.run(this.user)
        this.userEvent$.emit('login')
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.toaster.error(null, 'Error', errorResponse)
        return EMPTY
      })
    )
  }

  verifyCode(payload: TwoFactorCodeVerificationPayload) {
    return this.mfaVerifyCode(payload).pipe(
      tap((data) => {
        this.saveBypassMFAToken(data.mfa_bypass, data.user.primaryEmail)
      }),
      switchMap((loginResponse) => this.postLogin(loginResponse)),
      catchError((error) => {
        this.loginError(error)
        return throwError(error)
      })
    )
  }

  getBypassMFATokenKey(email: string) {
    return `${BYPASS_MFA_TOKEN}.${email}`
  }

  private saveBypassMFAToken(token: string, email: string) {
    if (token) {
      this.cookie.set(this.getBypassMFATokenKey(email), token, moment().add(15, 'days').toDate(), '/')
    }
  }

  isBypassingMFA() {
    return !!this.getBypassMFAToken(this.user.primaryEmail)
  }

  deleteBypassMFAToken() {
    this.cookie.delete(this.getBypassMFATokenKey(this.user.primaryEmail), '/')
  }

  getBypassMFAToken(email: string) {
    return this.cookie.get(this.getBypassMFATokenKey(email))
  }

  postLogin(loginResponse: LoginResponse, skipIntercomBoot = false) {
    return this.subscriptions(loginResponse).pipe(
      map((response) => ({ loginResponse, subscriptions: response.subscriptions })),
      switchMap(({ loginResponse, subscriptions }) => {
        const hasSymplSubscription = R.find((s) => s.product === 'sympl', subscriptions)
        if (isSomething(hasSymplSubscription)) {
          return throwError({ error: `You do not have active AdvanceCM account.` })
        } else {
          loginResponse.user.subscriptions = subscriptions
          return of(loginResponse)
        }
      }),
      tap((result) => {
        this.token = result.token
        this.setApp()
        this.user = result.user
        this.setRole(this.user)

        // do after login
        if (!skipIntercomBoot) {
          this.intercomService.boot(this.user)
          this.googleTagManagerService.update(this.user)
          this.amplitudeService.setUser(this.user)
          this.intercomService.track('tokeet-user-login')
          this.wootricService.run(this.user)
          Sentry.configureScope((scope) => {
            const id = this.user?.account?.toString() || ''
            scope.setUser({ id } as any)
          })
        }
        this.userEvent$.emit('login')
      })
    )
  }

  mfaSendTestCode(channel: MFADeliveryMethod) {
    return this.http.put<Pre2FactorResponse>('@api/user/mfa/test', { channel }).pipe(
      switchMap((data) => {
        if (!data.success) {
          return throwError(new Error(data.message || 'Unable to send MFA Code'))
        }
        return of(data)
      })
    )
  }

  mfaVerifyCode(payload: TwoFactorCodeVerificationPayload) {
    return this.http.post('@api/user/2fa/verify/', { ...payload, app: 'tokeet' }).pipe(map(LoginResponse.deserialize))
  }

  getTotpSetup() {
    return this.http
      .put<{ message?: string; success: boolean; uri: string; status: string }>('@api/user/totp/setup', {})
      .pipe(
        switchMap((data) => {
          if (!data.success) {
            return throwError(new Error(data.message || 'Unable to load TOTP settings'))
          }
          return of(data)
        })
      )
  }

  verifyTotpCode(code: string) {
    return this.http.put('@api/user/totp/verify', { code })
  }

  getTotpSetupStatus() {
    return this.http.get<{ success: true; status: string }>('@api/user/totp/status').pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 404) {
          return of({ success: true, status: 'missing' })
        } else {
          return throwError(error)
        }
      })
    )
  }

  deleteTotpSetup() {
    return this.http.delete('@api/user/totp/delete')
  }

  requestPasswordReset(email: string) {
    return this.http.post('@api/user/password/forgot', { email })
  }

  preResetPassword({ t, k }) {
    return this.http.post('@api/user/password/recover', { t, k }).pipe(map(LoginResponse.deserialize))
  }

  resetPassword(userId: string, token: string, data: ResetPasswordPayload) {
    this.token = token
    return this.http.put(`@api/user/password/${userId}`, data).pipe(finalize(() => (this.token = null)))
  }

  verify() {
    if (!this.isAuthenticated) return EMPTY

    return this.userService.fetchProfile().pipe(
      tap((user) => {
        this.user = user
        this.setRole(this.user)
      })
    )
  }

  logout(redirect = true, redirectUrl?: string) {
    this._token = null
    this._user = null

    this.clearRole()

    Sentry.configureScope((scope) => scope.clear())

    // do after logout
    this.intercomService.track('tokeet-user-logout')
    this.amplitudeService.logOut()
    this.intercomService.shutdown()

    if (redirect) {
      // NOTE: force page refresh after redirecting to /login
      // to ensure session data is removed.
      window.location.href = redirectUrl || '/login'
    }
    this.userEvent$.emit('logout')
  }

  isAdmin() {
    return userRoleToString(this.user.roles) === USER_ROLES.admin.name
  }

  private setRole(user: User) {
    this.clearRole(false)

    const userRole = userRoleToString(user.roles)

    this.storage.set(USER_ROLE, userRole)
    this.storage.set(USER_PERMISSIONS, roles[userRole])
    this.permissionsService.loadPermissions(roles[userRole])
    this.roleService.addRole(userRole, roles[userRole])
  }

  private clearRole(clearStorage = true) {
    if (clearStorage) {
      this.storage.remove(KEY_TOKEN)
      this.storage.remove(KEY_USER)
      this.storage.remove(USER_ROLE)
      this.storage.remove(USER_PERMISSIONS)
    }

    // @todo - could be cause of re-init in some cases
    this.permissionsService.flushPermissions()
    this.roleService.flushRoles()
  }

  private loginError(error) {
    this.toaster.error(null, 'Unable to login', error)
  }
}
