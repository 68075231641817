import {
  AddInventoryComplete,
  DeleteInventoriesComplete,
  DeleteInventoryComplete,
  LoadInventoriesComplete,
  UpdateInventoryComplete,
} from './actions'
import { adapter, initialState, State } from './state'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadInventoriesComplete, (state, { inventories }) =>
    adapter.upsertMany(inventories, { ...state, isLoaded: true })
  ),
  on(AddInventoryComplete, (state, { inventory }) => adapter.addOne(inventory, state)),
  on(UpdateInventoryComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(DeleteInventoryComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteInventoriesComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function inventoryReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
