import { AirbnbPhoto, AirbnbRoom } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.models'

export enum AirbnbTabs {
  Connection,
  Listing,
  Booking,
  Pricing,
  Availability,
  LocalTaxes,
}

export enum AirbnbListingSectionDetails {
  Photos = 'Photos',
  Rooms = 'Rooms',
  Amenities = 'Amenities',
  Accessibility = 'Accessibility',
  TitleAndDescription = 'TitleAndDescription',
  PropertyAndGuests = 'PropertyAndGuests',
  Location = 'Location',
  GuestResources = 'GuestResources',
  Guidebook = 'Guidebook',
}

export interface State {
  tab: AirbnbTabs
  details: AirbnbListingSectionDetails
  rooms: AirbnbRoom[]
  photos: AirbnbPhoto[]
}

export const initialState: State = {
  tab: AirbnbTabs.Connection,
  details: null,
  rooms: [],
  photos: [],
}
