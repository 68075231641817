import { Inject, Injectable } from '@angular/core'
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'
import { HttpErrorResponse } from '@angular/common/http'
import { compact, get } from 'lodash'
import { of } from 'rxjs'
import { delay, take, tap } from 'rxjs/operators'
import { ENVIRONMENT } from '../tokens'

declare const window: any

export interface Toast extends ActiveToast<any> {}

@Injectable()
export class Toaster {
  constructor(private toastr: ToastrService, @Inject(ENVIRONMENT) private environment) {}

  success(message?: string, title = 'Success'): Toast {
    return this.toastr.success(message, title, this.options('success'))
  }

  info(message?: string, title = 'Info'): Toast {
    return this.toastr.info(message, title, this.options('info'))
  }

  warning(message?: string, title = 'Warning'): void {
    of(true)
      .pipe(
        delay(10),
        tap(() => this.toastr.warning(message, title, this.options('warning'))),
        take(1)
      )
      .subscribe()
  }

  error(message?: string, title = 'Error', error?: any): Toast {
    if (error) {
      // console.log('[toaster] error:', error);

      const errorMessage = this.getError(error)

      if (errorMessage && !title) {
        title = message
        message = errorMessage
      } else {
        message = compact([message, this.getError(error)]).join('<br>')
      }
    }

    return this.toastr.error(message, title, this.options('error'))
  }

  clear() {
    return this.toastr.clear()
  }

  private options(type: string): Partial<IndividualConfig> {
    return {
      closeButton: true,
      enableHtml: true,
      onActivateTick: true,
    }
  }

  private getErrorMessage(error: string | object) {
    if (!error) {
      return ''
    }

    if (typeof error === 'object') {
      return this.getErrorMessage(get(error, 'error')) || this.getErrorMessage(get(error, 'message'))
    }

    return error
  }

  private getError(error: any): string {
    if (error instanceof HttpErrorResponse) {
      const response: HttpErrorResponse = error as HttpErrorResponse

      if (!response.status && !window.navigator.onLine) {
        return 'Please check your internet connection.'
      } else if (response.url && response.url.startsWith(this.environment.apiUrl)) {
        return this.getErrorMessage(response)
      }
    }

    return this.getErrorMessage(error)
  }
}
