<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col d-flex justify-content-between align-items-center">
        <div>
          <h5 class="modal-title"><i class="fab fa-airbnb"></i> Guest Review</h5>
        </div>
        <div>
          <button (click)="dialogRef.close()" type="button" class="btn btn-outline-primary mr-3">
            <i class="far fa-times"></i> cancel
          </button>
          <button
            (click)="onSave(form)"
            type="button"
            [disabled]="isLoading || !isRespondable"
            class="btn btn-secondary-info create-btn"
          >
            <i class="cs-icon cs-icon-save"></i> Save
          </button>
        </div>
      </div>
      <a (click)="dialogRef.close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-12"><app-rating [rating]="data.review.overall_rating"></app-rating></div>
          <div class="col-sm-12">
            <mat-form-field2 class="d-block">
              <input matInput type="text" placeholder="Public Review" [value]="data.review.public_review" disabled />
            </mat-form-field2>
          </div>
          <div class="col-sm-12">
            <mat-form-field2 class="d-block">
              <input
                matInput
                type="text"
                placeholder="Private Feedback"
                [value]="data.review.private_feedback"
                disabled
              />
            </mat-form-field2>
          </div>
        </div>
        <div class="row" *ngFor="let item of data.review.category_ratings">
          <div class="col-sm-4">
            <mat-form-field2 class="d-block">
              <input matInput type="text" placeholder="Category" [value]="item.category | titleCase" disabled />
              <app-rating matSuffix [rating]="item.rating"></app-rating>
            </mat-form-field2>
          </div>
          <div class="col-sm-4">
            <mat-form-field2 class="d-block">
              <input matInput type="text" placeholder="Comment" [value]="item.comment || ''" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div>
          <mat-form-field2 class="d-block">
            <textarea matInput placeholder="Response" formControlName="message" required cdkTextareaAutosize></textarea>
          </mat-form-field2>
        </div>
        <div>
          <span *ngIf="data.review.responded_at">Responded: {{ data.review.responded_at | date }}</span
          >&nbsp;
          <span *ngIf="data.review.expires_at">Expired: {{ data.review.expires_at | date }}</span>
        </div>
      </form>
    </div>
  </div>
</div>
