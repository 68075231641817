import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiKey } from './api-keys.model'
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import * as R from 'ramda'
import { Toaster } from '../../services/toaster.service'
import { ApiKeyResponse } from './api-keys.interfaces'

@Injectable()
export class ApiKeysService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  getApiKeys(): Observable<ApiKey[]> {
    return this.http
      .get<ApiKeyResponse[]>(`@api/account/apikey`)
      .pipe(map((response) => R.filter((r) => !R.isNil(r.key), response)))
  }

  generateAPIKey(name: string, internal = false): Promise<ApiKey> {
    return this.http.put<ApiKeyResponse>(`@api/account/apikey`, { name, internal }).toPromise()
  }

  remove(apiKey: ApiKey): Observable<string> {
    return this.http.delete<{ key: string }>(`@api/account/apikey/${apiKey.key}/${apiKey.apikey}`).pipe(
      map((response) => response.key),
      tap(() => this.toast.success('API Key deleted successfully.'))
    )
  }
}
