<div class="container-box details-box">
  <div class="filters-box">
    <h6 class="mb-0">Activity Logs</h6>
    <div class="flex-fill"></div>
    <ng-container [formGroup]="filters">
      <mat-form-field2 [showLabel]="false">
        <mat-select formControlName="status" placeholder="Status">
          <mat-option value=""> All </mat-option>
          <mat-option value="Success">Success </mat-option>
          <mat-option value="Failure">Failure </mat-option>
          <mat-option value="Disabled">Disabled </mat-option>
        </mat-select>
      </mat-form-field2>

      <app-cancel-filters [isFiltering]="filters | isFiltering" [filterControl]="filters"></app-cancel-filters>

      <button type="button" class="btn btn-secondary-light" (click)="onRefreshLog()">
        <i class="far fa-sync"></i>
      </button>
    </ng-container>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.created | epoch: dateFormat }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="expires">
          <mat-header-cell *matHeaderCellDef> Expires</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.expires | epoch: dateFormat }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Message</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-truncate">{{ element.message }}</div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="!dataSource?.filteredData?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>

      <ng-container *ngIf="!dataSource?.filteredData?.length">
        <div class="d-flex justify-content-around">
          <div class="box-alert w-50 m-2">
            <div class="alert alert-secondary-info">
              <div class="content-img"><img alt="" src="/assets/images/icons/info-icon.png" /></div>
              <div class="content">No activity log.</div>
            </div>
          </div>
        </div>
      </ng-container>
    </table>
  </div>
</div>
