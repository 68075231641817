<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-tags"></i> Rental Tags</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="offset-2 col-sm-8">
        <app-form-tags
          matTooltip="Select or create tag(s)"
          matTooltipPosition="above"
          [items]="tags"
          placeholder="Select or create tag(s)"
          formControlName="tags"
        ></app-form-tags>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-sm-8">
        <p>
          This feature allows you to add arbitrary descriptors to this rental which can then be used when filtering on
          various reports and calendars. For example, you can filter by rental tags on the Multi Calendar so that you
          only see a subset of all your rentals.
        </p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
