import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UserPreferences } from './user-preferences.model'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  constructor(private http: HttpClient) {}

  get(): Observable<UserPreferences> {
    const url = '@api/user/preferences/'

    return this.http.get<any>(url).pipe(map(UserPreferences.deserialize))
  }

  update(payload: UserPreferences | Partial<UserPreferences> | object): Observable<UserPreferences> {
    const url = '@api/user/preferences/'

    if (payload instanceof UserPreferences) {
      payload = payload.serialize()
    }

    return this.http.put<any>(url, payload).pipe(map(UserPreferences.deserialize))
  }

  updatePinnedInquiries(inquiryIds: string[]): Observable<UserPreferences> {
    return this.update({
      inquiry_pins: inquiryIds,
    })
  }

  updatePinnedMessages(messageIds: string[]): Observable<UserPreferences> {
    return this.update({
      message_pins: messageIds,
    })
  }
}
