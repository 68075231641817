import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromStealth from './stealth.reducer'
import { StealthEffects } from './stealth.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('stealth', fromStealth.reducer),
    EffectsModule.forFeature([StealthEffects]),
  ],
  declarations: [],
})
export class StealthModule {}
