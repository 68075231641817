import * as lodash from 'lodash'
import { RategeniePlanPrice, RategenieRentalPrice } from '../store/app/app-sub.model'

export function getRategeniePrices(planPrices: RategeniePlanPrice[], discount = 1) {
  const result = lodash.reduce(
    lodash.sortBy(planPrices, (p) => p.upTo || Number.MAX_SAFE_INTEGER),
    ({ prices, last }, price) => {
      const label = !last ? '1' : `${last.upTo + 1} - ${price.upTo}`
      const amount = lodash.toNumber(((price.amount * discount) / 100).toFixed(2))

      const item: RategenieRentalPrice = {
        rangeLabel: price.upTo ? label : `${last.upTo}+`,
        min: !last ? 1 : last.upTo + 1,
        max: price.upTo,
        price: amount,
      }
      prices.push(item)

      return { prices, last: price }
    },
    { prices: [], last: null } as { prices: RategenieRentalPrice[]; last: RategeniePlanPrice }
  )

  return result.prices
}

export function getRGRentalsPrice(count: number, prices: RategenieRentalPrice[]) {
  const { total } = lodash.reduce(
    prices,
    (acc, item) => {
      if (!acc.count) {
        return acc
      }
      let span = 0
      if (item.min && item.max) {
        span = item.max - item.min + 1
        const c = acc.count > span ? span : acc.count
        return { count: acc.count - c, total: acc.total + c * item.price }
      }

      if (item.min) {
        return { count: 0, total: acc.total + acc.count * item.price }
      }

      return acc
    },
    { count, total: 0 }
  )

  return total
}
