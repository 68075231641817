import { Injectable } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { isSubscriptionValid, planTypeLevels, PlanTypes, ProductsForPlan } from '@tv3/store/plan/plan.model'
import { select, Store } from '@ngrx/store'
import {
  selectActivePlansLoaded,
  selectActivePlanStatusView,
  selectActiveProductPlan,
  selectActiveTokeetPlanView,
} from '@tv3/store/plan/plan.selectors'
import { filter, map, switchMap, take } from 'rxjs/operators'
import {
  SubscriptionAddonFeatureAccessLimited,
  SubscriptionFeatureAccessLimited,
  SubscriptionInvalidLimited,
  SubscriptionMissing,
  SubscriptionRentalCountLimited,
  SubscriptionTrialLimited,
  SubscriptionUserCountLimited,
} from '@tv3/store/plan/plan.actions'
import * as fromRoot from '@tv3/store/state'
import { DataCheckerService, selectAllRentals, selectAllUsers, selectOnce } from '@tokeet-frontend/tv3-platform'
import { ActivePlanGuard } from '@tv3/guards/active-plan.guard'
import * as R from 'ramda'
import { PlanSubscriptionView } from '@tv3/store/plan/plan.view'
import { UserGuard } from '@tv3/guards/user.guard'
import { RentalGuard } from '@tv3/guards/rental.guard'

export interface SubscriptionPermissionConfig {
  allowedPlan?: PlanTypes // all plans above this plan are allowed,
  allowedAddonPlan?: ProductsForPlan
  disableTrial?: boolean
  checkAddUserPermission?: boolean
  checkAddRentalPermission?: boolean
  newRentals?: number
  rentals?: number // current rentals count
  users?: number // current users count
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPermissionCheckerService {
  constructor(private store: Store<fromRoot.State>, private dataCheckerService: DataCheckerService) {
    this.dataCheckerService.check([ActivePlanGuard, UserGuard, RentalGuard])
  }

  check(permission?: SubscriptionPermissionConfig): Observable<boolean> {
    permission = { allowedPlan: PlanTypes.Free, disableTrial: false, ...permission }

    return combineLatest([this.store.pipe(selectOnce(selectAllUsers)), this.store.pipe(selectOnce(selectAllRentals))])
      .pipe(
        switchMap(([users, rentals]) => {
          permission.users = users ? users.length : 0
          permission.rentals = rentals ? rentals.length : 0

          return combineLatest([
            this.store.pipe(select(selectActivePlansLoaded)),
            this.store.pipe(select(selectActiveTokeetPlanView)),
            this.store.pipe(select(selectActivePlanStatusView)),
            !permission.allowedAddonPlan
              ? of(true)
              : this.store.pipe(
                  select(selectActiveProductPlan, { product: permission.allowedAddonPlan }),
                  map((addonPlan) => isSubscriptionValid(addonPlan))
                ),
          ])
        })
      )
      .pipe(
        filter(
          ([isActivePlansLoaded, tokeetPlan, planStatus, isAddonActive]) =>
            isActivePlansLoaded && !R.isNil(planStatus) && !R.isNil(isAddonActive)
        ),
        map(([isActivePlansLoaded, tokeetPlan, planStatus, isAddonActive]) => {
          if (!tokeetPlan) {
            this.store.dispatch(SubscriptionMissing())
            return
          }
          if (!planStatus.isActive) {
            this.store.dispatch(SubscriptionInvalidLimited())
            return false
          } else if (planStatus.isTrial && permission.disableTrial) {
            this.store.dispatch(SubscriptionTrialLimited({ currentPlanType: tokeetPlan.type }))
            return false
          } else if (planTypeLevels[permission.allowedPlan] > planTypeLevels[tokeetPlan.type]) {
            this.store.dispatch(
              SubscriptionFeatureAccessLimited({
                currentPlanType: tokeetPlan.type,
                requiredPlanType: permission.allowedPlan,
              })
            )
            return false
          }

          if (!isAddonActive) {
            this.store.dispatch(
              SubscriptionAddonFeatureAccessLimited({
                addonId: permission.allowedAddonPlan,
              })
            )
            return false
          }

          // TV3-2617: no limit for trial user
          if (planStatus.isTrial) {
            return true
          }

          return !(!this.checkUserLimit(permission, tokeetPlan) || !this.checkRentalLimit(permission, tokeetPlan))
        }),
        take(1)
      )
  }

  private isLimited(current: number, limit: number, add = false, newRentals = 1) {
    return add ? current + newRentals > limit : current > limit
  }

  private checkRentalLimit(permission: SubscriptionPermissionConfig, tokeetPlan: PlanSubscriptionView): boolean {
    if (
      this.isLimited(
        permission.rentals,
        tokeetPlan.rentals,
        permission.checkAddRentalPermission,
        permission.newRentals || 1
      )
    ) {
      this.store.dispatch(
        SubscriptionRentalCountLimited({
          currentPlanType: tokeetPlan.type,
          current: permission.rentals,
          limit: tokeetPlan.rentals,
        })
      )
      return false
    }

    return true
  }

  private checkUserLimit(permission: SubscriptionPermissionConfig, tokeetPlan: PlanSubscriptionView) {
    if (this.isLimited(permission.users, tokeetPlan.users, permission.checkAddUserPermission)) {
      this.store.dispatch(
        SubscriptionUserCountLimited({
          currentPlanType: tokeetPlan.type,
          current: permission.users,
          limit: tokeetPlan.users,
        })
      )
      return false
    }

    return true
  }
}
