import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { TaskDataTabs, TaskDialogComponent } from './task-dialog.component'
import { CreateTaskPayload, TaskView } from '@tv3/store/task/task.model'

@Injectable({
  providedIn: 'root',
})
export class TaskDialogService {
  constructor(private dialog: MatDialog) {}

  open(task?: TaskView, defaults: Partial<CreateTaskPayload> = {}, tab?: TaskDataTabs) {
    const defaultConfig: MatDialogConfig = {
      width: '800px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { task, defaults, tab },
    }

    return this.dialog.open(TaskDialogComponent, defaultConfig)
  }
}
