<ng-container *ngIf="rental$ | async as rental; else unknown">
  <app-rental-image [rental]="rental" [size]="imageSize" [circle]="imageCircle" *ngIf="image"></app-rental-image>
  <div
    class="text-truncate"
    [matTooltip]="rental.name"
    matTooltipPosition="above"
    [matTooltipDisabled]="tooltipDisabled"
  >
    {{ rental.name }}
  </div>
</ng-container>
<ng-template #unknown>
  <ng-container *ngIf="rentalId"> &lt;Unknown&gt; </ng-container>
</ng-template>
