import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { HvmiListingViewModel } from '@tokeet-frontend/channels'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { HvmiListingDetailsOverlayComponent } from './hvmi-listing-details-overlay.component'

@Injectable()
export class HvmiListingDetailsOverlayService {
  constructor(private dialog: DialogService) {}

  public open(listing: HvmiListingViewModel) {
    const defaultConfig: MatDialogConfig = {
      data: { listing },
      width: '700px',
      height: 'auto',
    }

    const dialogRef = this.dialog.openVariableDialog(HvmiListingDetailsOverlayComponent, defaultConfig)

    return dialogRef
  }
}
