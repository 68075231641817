import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-airbnb-wizard-step2',
  templateUrl: './connect-airbnb-wizard-step2.component.html',
  styleUrls: ['./connect-airbnb-wizard-step2.component.scss'],
})
export class ConnectAirbnbWizardStep2Component implements OnInit {
  @Output() prev = new EventEmitter()
  @Output() authorize = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
