import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Channel, compareSource, selectAllChannels } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { getChannelFaviconIcon } from '../inquiry/inquiry-source-icon.pipe'

@Pipe({
  name: 'channelIcon',
  pure: false,
})
export class ChannelIconPipe implements PipeTransform {
  channels: Channel[] = []
  constructor(private store: Store<any>) {
    this.store.pipe(select(selectAllChannels)).subscribe((items) => {
      this.channels = items
    })
  }

  transform(channel: Channel | string, icon = true) {
    let nameToken: string
    if (typeof channel === 'string') {
      nameToken = channel
    } else {
      nameToken = channel.name
    }
    if (icon) {
      return getChannelFaviconIcon(nameToken, true)
    }
    const ch = lodash.find(this.channels, (c) => compareSource(c.name, nameToken))
    return ch.logoColor
  }
}
