import { Injectable } from '@angular/core'
import { AiResponderSettingsComponent } from './ai-responder-settings.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AIResponderSettingsService {
  constructor(private dialog: DialogService) {}

  public open() {
    return this.dialog.openDrawer(AiResponderSettingsComponent)
  }
}
