import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EditInvoiceTemplateCostComponent } from './dialogs/edit-cost/edit-invoice-template-cost.component'
import { EditInvoiceTemplateDueDateRuleComponent } from './dialogs/edit-rule/edit-invoice-template-due-date-rule.component'
import { EditTaxDialogComponent } from './dialogs/edit-tax/edit-tax-dialog.component'
import { EditInvoiceTemplateCostService } from './dialogs/edit-cost/edit-invoice-template-cost.service'
import { EditInvoiceTemplateDueDateRuleService } from './dialogs/edit-rule/edit-invoice-template-due-date-rule.service'
import { EditTaxDialogService } from './dialogs/edit-tax/edit-tax-dialog.service'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

const components = [
  //
  EditInvoiceTemplateCostComponent,
  EditInvoiceTemplateDueDateRuleComponent,
  EditTaxDialogComponent,
]

const services = [
  //
  EditInvoiceTemplateCostService,
  EditInvoiceTemplateDueDateRuleService,
  EditTaxDialogService,
]

const modules = [
  ReactiveFormsModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonToggleModule,
  TooltipModule,
  MatTooltipModule,
  Tv3PlatformModule,
]

@NgModule({
  imports: [CommonModule, ...modules],
  providers: [...services],
  declarations: [...components],
  exports: [...modules, ...components],
})
export class InvoiceTemplateComponentLibraryModule {}
