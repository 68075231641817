import { epochToView, plainToClass, Serializable, TaxV3 } from '@tokeet-frontend/tv3-platform'

export interface InvoiceTemplate {
  pkey: string
  account: number
  due_date: InvoiceTemplateDueDateRule
  address_key?: string
  payment_terms: string
  auto_fees_disabled?: boolean
  payment_instructions: string
  notes: string
  type: InvoiceTemplateTypes
  invoice_items: InvoiceTemplateLineItem[]
  name: string
  description: string
  email_template_id: string
  tags: string[]
  created: number
  updated: number
  template?: any
  lastused: number
}

export class InvoiceTemplateView extends Serializable<InvoiceTemplateView> implements InvoiceTemplate {
  account: number
  description: string
  due_date: InvoiceTemplateDueDateRule
  invoice_items: InvoiceTemplateLineItem[]
  name: string
  notes: string
  type: InvoiceTemplateTypes
  payment_instructions: string
  payment_terms: string
  pkey: string
  tags: string[]
  lastused: number
  email_template_id: string
  created: number
  updated: number

  createdView: string
  lastUsedView: string

  static deserialize(data: any): InvoiceTemplateView {
    let template: InvoiceTemplateView = plainToClass<InvoiceTemplateView, InvoiceTemplateView>(
      InvoiceTemplateView,
      data
    )

    template.createdView = epochToView(template.created)
    template.lastUsedView = epochToView(template.lastused)

    return template
  }
}

export interface InvoiceTemplateLineItemTitles {
  item: string
  unit: string
  qty: string
  tax: string
  description: string
}

export enum InvoiceTemplateTypes {
  BookingInvoice = 0,
  GeneralInvoice = 1,
}

export const invoiceTemplateLineItemTitlesByType = {
  [InvoiceTemplateTypes.BookingInvoice]: {
    item: 'Rental',
    unit: 'Nightly Rate',
    qty: 'Nights',
    tax: 'Taxes',
    description: 'Description',
  } as InvoiceTemplateLineItemTitles,
  [InvoiceTemplateTypes.GeneralInvoice]: {
    item: 'Item',
    unit: 'Cost',
    qty: 'Quantity',
    tax: 'Taxes',
    description: 'Description',
  } as InvoiceTemplateLineItemTitles,
}

export interface InvoiceTemplateDueDateRule {
  days: number
  operator: 'before' | 'after'
  event: 'invoice' | 'arrival' | 'departure'
}

export interface InvoiceTemplateCostFormula {
  name: string
  operation: string
  amount: string
  type: string
}

export interface InvoiceTemplateLineItemCost {
  base: string
  cumulativeSubtraction: boolean
  formulas: InvoiceTemplateCostFormula[]
}

export interface InvoiceTemplateLineItem {
  item: string
  unitCost: string | number
  qty: string | number
  description: string
  percent: number
  type: InvoiceTemplateTypes
  taxes: TaxV3[]
  cost: InvoiceTemplateLineItemCost
}

export interface InvoiceTemplateCreatePayload extends Omit<InvoiceTemplate, 'pkey'> {
  subject: string
  body: string
  silent?: boolean
}

export interface InvoiceTemplateUpdatePayload extends InvoiceTemplate {
  subject: string
  body: string
  silent?: boolean
}

export interface InvoiceTemplateCreateRequest extends Omit<InvoiceTemplate, 'pkey'> {}
