import { createAction, props, union } from '@ngrx/store'
import { Itinerary } from './itinerary.model'
import { FlightForm } from '@tv3/interfaces/forms/flight.form'
import { Update } from '@ngrx/entity'
import { Flight } from '@tv3/models/itinerary/flight'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

export const LoadItineraries = createAction('[Itinerary] Load Itineraries', props<{ inquiryId: string }>())
export const LoadItinerariesComplete = createAction(
  '[Itinerary] Load Itineraries Complete',
  props<{ itineraries: Itinerary[] }>()
)

export const AddFlight = createAction(
  '[Itinerary] Add Flight',
  props<{ inquiry: Inquiry; itinerary: Itinerary; form: FlightForm }>()
)
export const AddFlightComplete = createAction('[Itinerary] Add Flight Complete', props<{ update: Update<Itinerary> }>())

export const CreateFlight = createAction('[Itinerary] Create Flight', props<{ inquiry: Inquiry; form: FlightForm }>())
export const CreateFlightComplete = createAction(
  '[Itinerary] Create Flight Complete',
  props<{ itinerary: Itinerary }>()
)

export const EditFlight = createAction('[Itinerary] Edit Flight', props<{ itinerary: Itinerary; form: FlightForm }>())
export const EditFlightComplete = createAction(
  '[Itinerary] Edit Flight Complete',
  props<{ update: Update<Itinerary> }>()
)

export const DeleteFlight = createAction('[Itinerary] Delete Flight', props<{ itinerary: Itinerary; flight: Flight }>())
export const DeleteFlightComplete = createAction(
  '[Itinerary] Delete Flight Complete',
  props<{ update: Update<Itinerary> }>()
)
