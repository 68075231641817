import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { switchMap, take } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { ComponentType } from '@angular/cdk/portal'
import {
  HomeAwayRentalConfigView,
  HomeToGoRentalConfigView,
  LoadChannelConfig,
  LoadRentalConfigs,
  LoadRentalConfigsSuccess,
} from '@tokeet-frontend/channels'
import { HomeAwayConnectionOverlayComponent } from '@tv3/containers/channels/connection-detail/feed/homeaway-connection-overlay/homeaway-connection-overlay.component'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Channel, ChannelNameTokens, DialogService } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import * as R from 'ramda'
import { of } from 'rxjs'

@Injectable({
  providedIn: SharedModule,
})
export class HomeAwayConnectionOverlayService {
  constructor(private dialog: DialogService, private store: Store<any>, private actions$: Actions) {}

  public open<T>(
    config: HomeAwayRentalConfigView,
    connection: ConnectionView,
    component: ComponentType<T>
  ): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { config, connection },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  public openSide(connection: ConnectionView) {
    this.store.dispatch(LoadChannelConfig({ name: ChannelNameTokens.Homeaway }))
    this.store.dispatch(LoadRentalConfigs({ channel: ChannelNameTokens.Homeaway }))

    return this.actions$.pipe(
      ofType(LoadRentalConfigsSuccess),
      take(1),
      switchMap((result) => {
        const config = R.find(
          (c) => c.rental_id === connection.rentalId && c.channel_id === connection.channelId,
          result.configs
        )
        if (!!config) {
          const configView = {
            ...config,
            rental: connection.rental,
          } as HomeToGoRentalConfigView
          return this.open(configView, connection, HomeAwayConnectionOverlayComponent).afterClosed()
        }
        return of(false)
      })
    )
  }
}
