import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { CustomDomainResponse } from './ssl.model'

export interface State extends EntityState<CustomDomainResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<CustomDomainResponse> = createEntityAdapter<CustomDomainResponse>({
  sortComparer: false,
  selectId: (domain: CustomDomainResponse) => domain._id,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
