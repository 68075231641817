<div class="modal-header">
  <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i> Select Files</h5>
  <button (click)="close.emit()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="main-container">
    <div class="left">
      <div class="title">Available files ({{ available.length }})</div>
      <mat-form-field2 class="search mat-block">
        <input [(ngModel)]="queryAvailable" matInput placeholder="Search here..." type="text" />
        <i class="far fa-search"></i>
      </mat-form-field2>
      <div class="items">
        <div *ngFor="let item of available | search: 'attachment.name':queryAvailable" class="item">
          <mat-checkbox [(ngModel)]="item.selected">
            {{ item.attachment.name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="controls">
      <div class="buttons">
        <div class="buttons-block">
          <button class="btn btn-secondary-info" (click)="onMoveAvailable()"><i class="fal fa-angle-right"></i></button>
          <button class="btn btn-secondary-info" (click)="onMoveAllAvailable()">
            <i class="fal fa-angle-double-right"></i>
          </button>
        </div>
        <div class="buttons-block">
          <button class="btn btn-secondary-info" (click)="onMoveSelected()"><i class="fal fa-angle-left"></i></button>
          <button class="btn btn-secondary-info" (click)="onMoveAllSelected()">
            <i class="fal fa-angle-double-left"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title">Selected files ({{ selected.length }})</div>
      <mat-form-field2 class="search mat-block">
        <input [(ngModel)]="querySelected" matInput placeholder="Search here..." type="text" />
        <i class="far fa-search"></i>
      </mat-form-field2>
      <div class="items">
        <div *ngFor="let item of selected | search: 'attachment.name':querySelected" class="item">
          <mat-checkbox [(ngModel)]="item.selected">
            {{ item.attachment.name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="onDone()" [disabled]="!selected.length" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Select
  </button>
</div>
