import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, tap } from 'rxjs/operators'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { SharedModule } from '@tv3/shared/shared.module'

export interface RentalAssociatedCounts {
  bookings: number
  events: number
  images: number
  inquiries: number
  websites: number
}

@Injectable({
  providedIn: SharedModule,
})
export class DeleteRentalService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  getWebreadyRental(rentalId: string) {
    const url = `@webready/private/rentals/${rentalId}`
    return this.http.get(url)
  }

  getTokeetRentalCounts(rentalId: string) {
    const url = `@api/rental/subobjects/count/${rentalId}`
    return this.http.get<{ count: RentalAssociatedCounts }>(url).pipe(map(({ count }) => count))
  }

  getWebreadyRentalAssociated(rentalId: string, data?: { websites?: boolean; images?: boolean }) {
    const url = `@webready/private/rentals/associated_data`
    return this.http
      .post<{ rental: any; websites: any[]; images: { images: any[]; total: number } }>(url, { rentalId, ...data })
      .pipe(map((data) => ({ rental: data.rental, websites: data.websites, images: data.images?.images || [] })))
  }

  deleteWebreadyRental(rentalId: string, deleteImages = false) {
    const url = `@webready/private/rentals/delete`
    return this.http.post(url, { rentalId, deleteImages })
  }

  deleteHold(id: string) {
    const url = `@api/rental/delete/holds/${id}`

    return this.http.delete(url).pipe(tap(() => this.toast.success('Hold events deleted successfully.')))
  }

  deleteBookings(id: string) {
    const url = `@api/rental/delete/inquiries/${id}`

    return this.http.delete(url).pipe(tap(() => this.toast.success('Inquiries and bookings deleted successfully.')))
  }

  deleteWebsites(id: string) {
    const url = `@api/rental/delete/websites/${id}`

    return this.http.delete(url).pipe(tap(() => this.toast.success('Websites deleted successfully.')))
  }

  delete(id: string) {
    const url = `@api/rental/delete/${id}`

    return this.http.delete(url).pipe(tap(() => this.toast.success('Rental deleted successfully.')))
  }
}
