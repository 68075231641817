import { Component, OnInit } from '@angular/core'
import { LoadLatestMessages } from '@tv3/store/message/message.actions'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectUnreadMessages } from '@tv3/store/message/message.selectors'
import { MessageType } from '../../../store/message/message.model'

@Component({
  selector: 'app-messages-icon',
  templateUrl: './messages-icon.component.html',
  styleUrls: ['./messages-icon.component.scss'],
})
export class MessagesIconComponent extends Destroyable implements OnInit {
  unreadCount = 0
  constructor(private store: Store<fromRoot.State>) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(LoadLatestMessages({ messageType: MessageType.Inbound }))
    this.store.pipe(select(selectUnreadMessages), untilDestroy(this)).subscribe((messages) => {
      this.unreadCount = messages.length
    })
  }
}
