import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { selectAllAPIConnectionViews } from '@tv3/store/connection/connection.selectors'
import {
  ActionFailed,
  Channel,
  ChannelService,
  compactObject,
  ConnectedChannelAccountResponse,
  Destroyable,
  DialogService,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { finalize, map, startWith, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'
import { FormBuilder } from '@angular/forms'
import { TableType } from '@tv3/shared/empty-table/table-type'
import {
  ChannelReviewItem,
  ChannelReviewSearchParams,
  getChannelMaxRating,
  getChannelReviewSource,
  ReviewService,
} from '@tokeet-frontend/reviews'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ReviewReplyDialogComponent } from '@tv3/containers/reviews/components/review-reply-dialog/review-reply-dialog.component'

interface ReviewTableItem extends ChannelReviewItem {
  roomName: string
  review: string
  likes: string
  dislikes: string
  rating: number
  roomId: any
  propertyId: any
  submittedDate: number
}

@Component({
  selector: 'app-channel-reviews-table',
  templateUrl: './channel-reviews-table.component.html',
  styleUrls: ['./channel-reviews-table.component.scss'],
})
export class ChannelReviewsTableComponent extends Destroyable implements OnInit {
  @Input() channel: Channel

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  maxRating = 5
  dataSource = new MatTableDataSource<ReviewTableItem>()
  displayedColumns = [
    //
    'select',
    'submittedDate',
    'roomName',
    'propertyId',
    'roomId',
    'review',
    'rating',
    'edit',
  ]
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableType = TableType.AirbnbReviews
  expandedReviewId: string

  filters: ChannelReviewSearchParams = {}
  totalCount = 0
  pagination = {
    pageIndex: 0,
    pageSize: 15,
  }

  filtersForm = this.fb.group({
    property: [],
    room: [],
    period: [null],
  })

  connectedAccounts: ConnectedChannelAccountResponse[] = []
  connections: ConnectionView[] = []
  rooms: {
    name: string
    id: string
    hotelName: string
  }[] = []

  isLoading = false

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private channelService: ChannelService,
    private reviewService: ReviewService,
    private dialog: DialogService
  ) {
    super()
  }

  ngOnInit(): void {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    const source = getChannelReviewSource(this.channel.name)
    this.maxRating = source ? getChannelMaxRating(source) : 5

    this.store.pipe(select(selectAllAPIConnectionViews), untilDestroy(this)).subscribe((connections) => {
      this.connections = connections
    })

    this.filtersForm.valueChanges
      .pipe(
        startWith(this.filtersForm.getRawValue()),
        map(
          (filters): ChannelReviewSearchParams =>
            compactObject({
              properties: filters.property ? [String(filters.property)] : [],
              listings: filters.room ? [String(filters.room)] : [],
              submittedAfter: filters.period?.from,
              submittedBefore: filters.period?.to,
              sources: source ? [source] : [],
              includeStats: false,
              reviewerRole: 'guest',
            })
        )
      )
      .pipe(untilDestroy(this))
      .subscribe((filters) => {
        this.filters = filters
        this.paginator?.firstPage()
        this.onRefresh()
      })

    this.channelService.getConnectedAccounts(this.channel.id).subscribe((accounts) => {
      this.connectedAccounts = accounts
      this.rooms = lodash.head(accounts)?.rooms
    })
  }

  onExpandRow(item: ChannelReviewItem) {
    this.expandedReviewId = this.expandedReviewId === item.reviewId ? null : item.reviewId
  }

  onPageChange(data: PageEvent) {
    this.pagination = data
    this.onRefresh()
  }

  onRefresh() {
    this.isLoading = true
    this.reviewService
      .searchReviews({
        ...this.filters,
        includeStats: false,
        page: this.pagination.pageIndex + 1,
        limit: this.pagination.pageSize,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.totalCount = data?.counts?.total || 0
          this.dataSource.data = lodash.map(data.reviews, (review) => {
            return this.toReviewTableItem(review, this.getConnection(review))
          })
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  getConnection(review: ChannelReviewItem) {
    return lodash.find(
      this.connections,
      // tslint:disable-next-line:triple-equals
      (t) => t.roomId == review.listingId && t.propertyId == review.propertyId
    )
  }

  toReviewTableItem(review: ChannelReviewItem, listing: Connection): ReviewTableItem {
    return {
      ...review,
      submittedDate: review.submittedAt ? moment(review.submittedAt).unix() : 0,
      roomName: listing?.roomName,
      propertyId: review.propertyId || listing?.propertyId,
      roomId: review.listingId || listing?.roomId,
      review: review.text,
      likes: review.positiveText,
      dislikes: review.negativeText,
      rating: review.channelOverallRating,
    }
  }

  canReply(review: ChannelReviewItem) {
    return review.reviewer?.role !== 'host' && !review.response && review.isEligibleForResponse
  }

  onReply(item: ChannelReviewItem) {
    this.dialog
      .openVariableDialog(ReviewReplyDialogComponent, {
        width: '800px',
        data: { review: item },
      })
      .afterClosed()
      .pipe(switchMap(() => this.reviewService.getReview(item._id)))
      .subscribe((review) => {
        const r = this.toReviewTableItem(review, this.getConnection(review))
        this.dataSource.data = this.dataSource.data.map((t) => (t._id === review._id ? r : t))
      })
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = String(term).trim().toLowerCase()
  }
}
