import { createAction, props } from '@ngrx/store'
import {
  MarkSmartDevicePrimaryPayload,
  SmartDevice,
  SmartDeviceSettingsResponse,
  UpdateSmartDevicePayload,
} from './model'
import { Update } from '@ngrx/entity'

export const LoadSmartDevices = createAction('[SmartDevice] Load SmartDevices')
export const LoadSmartDevicesComplete = createAction(
  '[SmartDevice] Load SmartDevices Complete',
  props<{ items: SmartDevice[] }>()
)

export const UpdateSmartDevice = createAction(
  '[SmartDevice] update device',
  props<{ payload: UpdateSmartDevicePayload; msg?: string }>()
)
export const UpdateSmartDeviceComplete = createAction(
  '[SmartDevice] update SmartDevices Complete',
  props<{ change: Update<SmartDevice> }>()
)

export const MarkSmartDevicePrimary = createAction(
  '[SmartDevice] primary device',
  props<{ payload: MarkSmartDevicePrimaryPayload }>()
)
export const MarkSmartDevicePrimaryComplete = createAction(
  '[SmartDevice] primary SmartDevices Complete',
  props<{ change: Update<SmartDevice>[] }>()
)

export const LoadSmartDeviceSettings = createAction('[SmartDevice] Load SmartDevices Settings')
export const LoadSmartDeviceSettingsComplete = createAction(
  '[SmartDevice] Load SmartDevices Settings Complete',
  props<{ settings: SmartDeviceSettingsResponse }>()
)

export const UpdateSmartDeviceSettings = createAction(
  '[SmartDevice] Update SmartDevices Settings',
  props<{ payload: Partial<SmartDeviceSettingsResponse> }>()
)
export const UpdateSmartDeviceSettingsComplete = createAction(
  '[SmartDevice] Update SmartDevices Settings Complete',
  props<{ update: Partial<SmartDeviceSettingsResponse> }>()
)
