import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { Website } from './website.model'
import { WebsiteTemplate } from '@tv3/store/website/website.types'

export interface State extends EntityState<Website> {
  templates: WebsiteTemplate[]
  isLoaded: boolean
  isUpdating: boolean
  isTemplatesLoaded: boolean
}

export const adapter: EntityAdapter<Website> = createEntityAdapter<Website>({
  sortComparer: (a: Website, b: Website) =>
    a.title.toString().localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' }),
})

export const initialState: State = adapter.getInitialState({
  templates: [],
  isLoaded: false,
  isUpdating: false,
  isTemplatesLoaded: false,
})
