<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-file-alt"></i> Add Product</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span><i class="far fa-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12">
        <app-select-search
          [items]="rates"
          [multiple]="false"
          bindLabel="name"
          bindValue="id"
          multiple="false"
          placeholder="Select Rate"
          [ctrl]="form.get('rateId')"
          [inline]="false"
        >
        </app-select-search>
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-9">
            <mat-form-field2
              class="mat-block"
              matTooltip="Set the minimum lead time for a booking with this rate."
              matTooltipPosition="above"
            >
              <input
                type="text"
                formControlName="minAdvancedBookingOffset"
                matInput
                placeholder="Min advance bookings"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="minAdvancedBookingOffsetType" placeholder="Type" required>
                <mat-option *ngFor="let type of advancedBookingOffsetTypes" [value]="type.value">{{
                  type.label
                }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-9">
            <mat-form-field2
              class="mat-block"
              matTooltip="Set how far in advance this rate may be booked."
              matTooltipPosition="above"
            >
              <input
                type="text"
                formControlName="maxAdvancedBookingOffset"
                matInput
                placeholder="Max advance booking"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="maxAdvancedBookingOffsetType" placeholder="Type" required>
                <mat-option *ngFor="let type of advancedBookingOffsetTypes" [value]="type.value">{{
                  type.label
                }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <app-select-search
          [items]="cancelPolicies"
          [multiple]="false"
          bindLabel="label"
          bindValue="value"
          multiple="false"
          [inline]="false"
          placeholder="Cancellation Policy"
          [ctrl]="form.get('cancelPolicyCode')"
        >
        </app-select-search>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onCreate(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="far fa-plus"></i> create
  </button>
</div>
