import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'

import { RentalEffects } from './rental.effects'
import * as fromRental from './rental.reducer'
import { SubdomainGuard } from './subdomain.guard'
import { RentalRatesGuard } from './rental-rates.guard'
import { RentalService } from './rental.service'
import { RateMappingService } from './rental-rate-mapping.service'
import { RentalRateMappingEffects } from './rental-rate-mapping.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('rental', fromRental.rentalReducer),
    EffectsModule.forFeature([
      //
      RentalEffects,
      RentalRateMappingEffects,
    ]),
  ],
  providers: [
    //
    RateMappingService,
    RentalService,
    SubdomainGuard,
    RentalRatesGuard,
  ],
  declarations: [],
})
export class RentalStoreModule {}
