import { FormControl } from '@angular/forms'
import * as R from 'ramda'

export function alphanumericNameValidator(control: FormControl) {
  if (R.isNil(control.value) || !R.is(String, control.value) || control.value.length === 0) {
    return null
  }
  const value = String(control.value)
  const isValid = value.match(/^[\w.\-_]+(?:\s[\w.\-_]+)*$/)
  return isValid ? null : { name: true }
}
