import { Injectable } from '@angular/core'
import { HoldDialogComponent } from './hold-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { HoldDialogInputs } from './hold-dialog.component'

@Injectable()
export class HoldDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: HoldDialogInputs = {}) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data,
    }

    return this.dialog.open(HoldDialogComponent, defaultConfig)
  }
}
