import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  ConnectRentalsUnitedSuccess,
  CreateRentalsUnitedListingSuccess,
  DeleteRentalsUnitedListingSuccess,
  DisconnectRentalsUnitedSuccess,
  GetRentalsUnitedStatus,
  selectRentalsUnitedStatus,
  SetRentalsUnitedListingStatusSuccess,
  UpdateRentalsUnitedListingSuccess,
} from '@tokeet-frontend/channels'
import { Channel, Destroyable, untilDestroy, UserStorage } from '@tokeet-frontend/tv3-platform'
import { LoadConnections } from '@tv3/store/connection/connection.actions'
import { delay, filter, switchMap, take } from 'rxjs/operators'
import { ChannelConnectHelperService } from '../channel-connect-helper.service'

@Component({
  selector: 'app-connect-ru-wizard',
  templateUrl: './connect-ru-wizard.component.html',
  styleUrls: ['./connect-ru-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
  providers: [ChannelConnectHelperService],
})
export class ConnectRUWizardComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper

  status$ = this.store.pipe(select(selectRentalsUnitedStatus))

  get channel() {
    return this.data.channel
  }

  constructor(
    public dialogRef: MatDialogRef<ConnectRUWizardComponent>,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private channelConnectHelper: ChannelConnectHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions
      .pipe(
        ofType(
          ConnectRentalsUnitedSuccess,
          DisconnectRentalsUnitedSuccess,
          CreateRentalsUnitedListingSuccess,
          UpdateRentalsUnitedListingSuccess,
          SetRentalsUnitedListingStatusSuccess,
          DeleteRentalsUnitedListingSuccess
        ),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.store.dispatch(LoadConnections({}))
      })

    this.channelConnectHelper.rentalImageCountsGuard().pipe(untilDestroy(this)).subscribe()

    this.actions.pipe(ofType(DisconnectRentalsUnitedSuccess), untilDestroy(this)).subscribe(() => {
      this.refresh()
    })
    this.refresh()
  }

  ngAfterViewInit(): void {
    this.stepper.animationDone
      .pipe(
        take(1),
        delay(500),
        switchMap(() => this.store.pipe(select(selectRentalsUnitedStatus))),
        filter((s) => !!s?.connected),
        take(1),
        untilDestroy(this)
      )
      .subscribe((status) => {
        this.stepper.selectedIndex = 1
      })
  }

  close() {
    this.dialogRef.close()
  }

  refresh() {
    this.store.dispatch(GetRentalsUnitedStatus())
  }
}
