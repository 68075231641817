import { createAction, props } from '@ngrx/store'
import { CustomDomainResponse, CustomDomainSSLCertificatePayload } from './ssl.model'

export const LoadCustomDomains = createAction('[CustomDomain] Load Custom Domains', props<any>())
export const LoadCustomDomainsComplete = createAction(
  '[DiscountCode] Load Custom Domains Complete',
  props<{ items: CustomDomainResponse[] }>()
)

export const GetCustomDomain = createAction('[CustomDomain] Get Custom Domain', props<{ id: string }>())
export const GetCustomDomainComplete = createAction(
  '[CustomDomain] Get Custom Domain Complete',
  props<{ item: CustomDomainResponse }>()
)

export const GetOrCreateCustomDomain = createAction(
  '[CustomDomain] Get Or Create Custom Domain',
  props<{ id: string }>()
)
export const GetOrCreateCustomDomainComplete = createAction(
  '[CustomDomain] Get Or Create Custom Domain Complete',
  props<{ item: CustomDomainResponse }>()
)

export const DeleteCustomDomain = createAction('[CustomDomain] Delete Custom Domain', props<{ id: string }>())
export const DeleteCustomDomainComplete = createAction(
  '[CustomDomain] Delete Custom Domain Complete',
  props<{ id: string }>()
)

export const RequestCustomDomainCerts = createAction(
  '[CustomDomain] Request Custom Domain SSL',
  props<{ id: string }>()
)
export const RequestCustomDomainCertsComplete = createAction(
  '[CustomDomain] Request Custom Domain SSL Complete',
  props<{ item: CustomDomainResponse }>()
)

export const SetCustomDomainCerts = createAction(
  '[CustomDomain] Set Custom Domain SSL',
  props<{ id: string; payload: CustomDomainSSLCertificatePayload }>()
)
export const SetCustomDomainCertsComplete = createAction(
  '[CustomDomain] Set Custom Domain SSL Complete',
  props<{ item: CustomDomainResponse }>()
)

export const DeleteCustomDomainCerts = createAction('[CustomDomain] Delete Custom Domain SSL', props<{ id: string }>())
export const DeleteCustomDomainCertsComplete = createAction(
  '[CustomDomain] Delete Custom Domain SSL Complete',
  props<{ item: CustomDomainResponse }>()
)
