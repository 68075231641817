export enum ChannelReviewSources {
  Airbnb = 'airbnb',
  BookingCom = 'booking.com',
  Expedia = 'expedia',
  Vrbo = 'vrbo',
}

export const channelReviewSourceOptions = [
  { value: ChannelReviewSources.Airbnb, name: 'Airbnb', color: '#FF9797', reviewable: true },
  { value: ChannelReviewSources.BookingCom, name: 'Booking.com', color: '#006ACB', reviewable: false },
  { value: ChannelReviewSources.Expedia, name: 'Expedia', color: '#79BDFC', reviewable: false },
  { value: ChannelReviewSources.Vrbo, name: 'VRBO', color: '#0e214b', reviewable: false },
]

export function getChannelReviewSource(source: string) {
  if (/^airbnb/i.test(source)) return ChannelReviewSources.Airbnb
  if (/^booking/i.test(source)) return ChannelReviewSources.BookingCom
  if (/^expedia/i.test(source)) return ChannelReviewSources.Expedia
  if (/^vrbo/i.test(source)) return ChannelReviewSources.Vrbo
  return null
}

export function isChannelReviewAvailable(source: string): boolean {
  return !!getChannelReviewSource(source)
}

export function getChannelMaxRating(source: ChannelReviewSources): number {
  switch (source) {
    case ChannelReviewSources.BookingCom:
      return 10
    default:
      return 5
  }
}

export interface ChannelReviewUser {
  id: string
  name: string
  role: string
}

export interface ChannelReviewCategoryRating {
  category: string
  rating: number
}

export interface ChannelReviewItem {
  _id: string
  account: number
  rentalId: string
  inquiryId: string
  guestId: string
  propertyId: string
  listingId: string
  checkIn: string
  checkOut: string
  source: ChannelReviewSources
  reviewId: string
  reservationId: string
  reviewer: ChannelReviewUser
  reviewee: ChannelReviewUser
  title: string
  text: string
  positiveText: string
  negativeText: string
  published: boolean
  overallRating: number // 0
  channelOverallRating: number
  categoryRatings: ChannelReviewCategoryRating[]
  response?: {
    text: string
    respondedAt: string // '2024-08-01T04:25:46.934Z'
    lastChangeAt: string // '2024-08-01T04:25:46.934Z'
  }
  privateFeedback?: string
  isRevieweeRecommended: boolean
  isGenuine: boolean
  submitted: boolean
  submittedAt: string // '2024-08-01T04:25:46.934Z'
  hidden: boolean
  language: string
  reviewCreatedAt: string // '2024-08-01T04:25:46.934Z'
  createdAt: string // '2024-08-01T04:25:46.934Z'
  updatedAt: string // '2024-08-01T04:25:46.934Z'
  isEligibleForResponse: boolean
}

export interface ChannelReviewStatCategoryRatingItem {
  _id: string // category
  reviewsCount: number
  averageRating: number
}
export interface ChannelReviewRespondedCount {
  yes: number
  no: number
}
export interface ChannelRentalAVGRatingItem {
  averageOverallRating: number // 4.875
  source: string // 'airbnb'
  latestReview: {
    text: string // 'The host is very friendly and listen to you very carefully and try to facilitate you as well.\nBut the location is not so good.'
    _id: string // '66d21f6d43f7344f3de4d5f2'
    submittedAt: string // '2024-09-03T07:01:40.748Z'
  }
  rentalId: string // '4e9b0f23-7c26-4fcc-b2bf-f9425e6569d8'
}

export interface ChannelMonthlyRatingDistItem {
  ratings: {
    range: string // '4.8-5'
    count: number // 2
  }[]
  averageOverallRating: number
  month: string // '2024-1'
}

export interface ChannelReviewsResponse {
  reviews: ChannelReviewItem[]
  counts: {
    total: number
    sources: Record<ChannelReviewSources, number>
    responded: ChannelReviewRespondedCount
    byMonth: (Record<ChannelReviewSources, { responded: ChannelReviewRespondedCount }> & { month: string })[] // "month": "2024-07"
  }
  categoryRatings: ChannelReviewStatCategoryRatingItem[]
  averageRating: Record<ChannelReviewSources, { reviewsCount: number; averageRating: number }>
  rentalAverageOverallRating: ChannelRentalAVGRatingItem[]
  monthlyRatingDistribution: ChannelMonthlyRatingDistItem[]
  yearlyFiveStarRating: { year: number; count: number }[]
}

export interface ChannelReviewSearchParams {
  limit?: number
  page?: number
  sources?: ChannelReviewSources[]
  properties?: string[]
  listings?: string[]
  rentals?: string[]
  guests?: string[]
  inquiries?: string[]
  submittedAfter?: number
  submittedBefore?: number
  responded?: boolean
  includeDeletedInquiries?: boolean
  includeStats?: boolean
  reviewerRole?: 'host' | 'guest'
}

export interface ChannelReviewFetchParams {
  limit?: number
  page?: number
  source?: ChannelReviewSources
  propertyId?: string
  listingId?: string
  rentalId?: string
  guestId?: string
  inquiryId?: string
}

export interface ChannelReviewImportPayload {
  connectedProperties?: boolean
  connectedPropertiesChannels?: string[]
  skipPropertiesWithNoConnectedListings?: boolean
  properties?: {
    id: string
    channel: string // 'airbnb'
  }[]
}
