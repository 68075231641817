<div class="modal-body m-0">
  <cdk-virtual-scroll-viewport itemSize="50" class="w-100 h-100" orientation="vertical">
    <ng-container *cdkVirtualFor="let task of tasks">
      <div
        [style.border-left-color]="task.color"
        [style.background-color]="task.bgColor"
        class="task-item clickable"
        (click)="onTask(task)"
        [matTooltip]="task.name"
      >
        <div class="text flex-fill mr-2">
          {{ task?.name | truncate: 20 }}:
          <span class="text-muted text-xs time">
            {{ task?.start | epochUTC: 'DD, MMM: h:mm A' }} ~ {{ task?.due | epochUTC: 'DD, MMM: h:mm A' }}
          </span>
        </div>
        <app-task-status [task]="task"></app-task-status>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
