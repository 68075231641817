import { DataRecurringRule, deserialize, Expose, Serializable, Type } from '@tokeet-frontend/tv3-platform'

export const CalendarEventTypeOther = { id: 0, name: 'Other' }
export const CalendarEventTypePersonal = { id: 1, name: 'Personal' }
export const CalendarEventTypeMaintenance = { id: 2, name: 'Maintenance' }
export const CalendarEventTypePending = { id: 3, name: 'Pending' }
export const CalendarEventTypeConfirmed = { id: 4, name: 'Confirmed' }

export const CalendarEventTypesMapping = {
  [CalendarEventTypeOther.id]: CalendarEventTypeOther,
  [CalendarEventTypePersonal.id]: CalendarEventTypePersonal,
  [CalendarEventTypeMaintenance.id]: CalendarEventTypeMaintenance,
  [CalendarEventTypePending.id]: CalendarEventTypePending,
  [CalendarEventTypeConfirmed.id]: CalendarEventTypeConfirmed,
}

export class CalendarEvent extends Serializable<CalendarEvent> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'booking_id' })
  bookingId: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'ref_id' })
  refId: string

  @Expose({ name: 'created_by' })
  createdBy: string

  @Expose({ name: 'notes_updated' })
  notesUpdated: 0 | 1

  cost: string
  @Expose()
  @Type(() => Number)
  start: number
  @Expose()
  adults: number
  @Expose()
  source: string
  @Expose()
  checkout: number
  @Expose()
  children: number
  @Expose()
  @Type(() => Number)
  end: number
  email: string
  @Expose()
  title: string
  @Expose()
  checkin: number
  @Expose()
  @Type(() => Number)
  type: number
  account: number
  @Type(() => Number)
  expires: number
  @Type(() => Number)
  rolling: number
  @Type(() => Number)
  created: number

  color: string
  num_pets: number
  description: string
  attachments: string[]
  recurrenceRule?: DataRecurringRule['recurrenceRule']
  @Type(() => Number)
  repeat?: DataRecurringRule['repeat']
  group_id?: string

  trigger_id?: string

  static deserialize(data: any): CalendarEvent {
    data.pkey = data.pkey || data.booking_id
    return deserialize(CalendarEvent, data)
  }
}

export interface CreateHoldEventpayload extends DataRecurringRule {
  title: string
  type: number
  start: number
  end: number
  source?: string
  expires: number
  rolling: number
  guest_id?: string
  rental_id: string
  color?: string
  description?: string
  attachments?: string[]
  adults?: number
  children?: number
  num_pets?: number
}

export type UpdateHoldEventPayload = Partial<CreateHoldEventpayload>
