import { adapter, initialState, State } from './state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  LoadSmartDevicesComplete,
  LoadSmartDeviceSettingsComplete,
  MarkSmartDevicePrimaryComplete,
  UpdateSmartDeviceComplete,
  UpdateSmartDeviceSettingsComplete,
} from './actions'

export const reducer = createReducer(
  initialState,
  on(LoadSmartDevicesComplete, (state, { items }) => adapter.setAll(items, R.merge(state, { isLoaded: true }))),
  on(UpdateSmartDeviceComplete, (state, { change }) => adapter.updateOne(change, state)),
  on(MarkSmartDevicePrimaryComplete, (state, { change }) => adapter.updateMany(change, state)),
  on(LoadSmartDeviceSettingsComplete, (state, { settings }) => ({ ...state, settings, isSettingsLoaded: true })),
  on(UpdateSmartDeviceSettingsComplete, (state, { update }) => ({
    ...state,
    settings: { ...state.settings, ...update } as any,
  }))
)

export function deviceReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
