import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  TiketPushRoomAvailabilityPayload,
  TiketPushRoomRatesPayload,
  TiketRoomModel,
  TiketRoomRatePlan,
  TiketStatus,
} from './model'

@Injectable({
  providedIn: 'root',
})
export class TiketChannelService {
  constructor(private http: HttpClient) {}

  getStatus() {
    const url = `@api/tiket/connection/status`

    return this.http.get<TiketStatus>(url)
  }

  connect(hotelId: string) {
    const url = `@api/tiket/connection/connect`

    return this.http.post<TiketStatus>(url, { hotelId })
  }

  disconnect(hotelId: string) {
    const url = `@api/tiket/connection/disconnect`

    return this.http.post(url, { hotelId })
  }

  getRooms() {
    const url = `@api/tiket/rooms/`

    return this.http.get<TiketRoomModel[]>(url)
  }

  getConnections() {
    const url = `@api/tiket/listings/tokeet`

    return this.http.get<any[]>(url)
  }

  getRoomRatePlans(propertyId: any) {
    const url = `@api/tiket/rooms/rate-plans?hotel_id=${propertyId}`

    return this.http.get<TiketRoomRatePlan[]>(url)
  }

  link(roomTypeCode: number, rentalId: string, propertyId: any) {
    const url = `@api/tiket/rooms/link`

    return this.http.post<{ success: true }>(url, { roomTypeCode, rental_id: rentalId, hotel_id: propertyId })
  }

  unlink(roomTypeCode: number, propertyId: any) {
    const url = `@api/tiket/rooms/unlink`

    return this.http.post<{ success: boolean }>(url, { roomTypeCode, hotel_id: propertyId })
  }

  pushRates(roomTypeCode: number, data: TiketPushRoomRatesPayload) {
    const url = `@api/tiket/rooms/rates/push`

    return this.http.post<{ success: boolean }>(url, { ...data, roomTypeCode })
  }

  pusAvailability(roomTypeCode: number, data: TiketPushRoomAvailabilityPayload) {
    const url = `@api/tiket/rooms/availability/push`

    return this.http.post<{ success: boolean }>(url, { ...data, roomTypeCode })
  }
}
