<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-1">
        <div class="d-flex align-items-center gap-2">
          <div class="modal-title">{{ website?.title }} - {{ website?.domain }}</div>
          <app-page-help videoId="-" [articleId]="articleId"></app-page-help>
        </div>
        <div class="flex-fill"></div>
        <button (click)="onDelete()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-danger">
          <span class="cs-icon cs-icon-trash"></span> Delete
        </button>
        <button (click)="onPreview()" type="button" class="btn btn-secondary-secondary">
          <span class="fal fa-share"></span> Preview
        </button>
        <button (click)="onPublish()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-upload"></span> Publish
        </button>
      </div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li [class.active]="tab$.value === tabs.Details">
        <a (click)="tab$.next(tabs.Details)"> Details </a>
      </li>
      <li
        *ngIf="website && website.type !== websiteTypes.BookingEngine"
        [class.active]="tab$.value === tabs.TemplateSettings"
      >
        <a (click)="tab$.next(tabs.TemplateSettings)"> Template Settings </a>
      </li>
      <li
        [matTooltip]="
          website && website.type == websiteTypes.GuestPortal
            ? 'You may create additional pages for your guest portal. Use these pages to promote local destinations which may interest your guests.'
            : 'You may create additional pages for your website.'
        "
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.OtherPages"
        *ngIf="website && website.type !== websiteTypes.BookingEngine"
      >
        <a (click)="tab$.next(tabs.OtherPages)"> Other Pages </a>
      </li>
      <li [class.active]="tab$.value === tabs.Components">
        <a (click)="tab$.next(tabs.Components)"> Components </a>
      </li>
      <li [class.active]="tab$.value === tabs.ContactInfo">
        <a (click)="tab$.next(tabs.ContactInfo)"> Contact Info </a>
      </li>
      <li *ngIf="website && website.type !== websiteTypes.GuestPortal" [class.active]="tab$.value === tabs.Links">
        <a (click)="tab$.next(tabs.Links)"> Links </a>
      </li>
      <li
        matTooltip="You can completely customize the look of your website using custom CSS and JavaScript. You can even overwrite the default CSS of HTML entities. This requires advanced knowledge of HTML and CSS."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Resources"
      >
        <a (click)="tab$.next(tabs.Resources)"> Resources </a>
      </li>
      <li
        matTooltip="You may upload additional images to be used on your website. Your rental images are used by default if no images are specified here. If you upload and select a primary website image it will be used as your homepage main image."
        matTooltipPosition="below"
        [class.active]="tab$.value === tabs.Images"
      >
        <a (click)="tab$.next(tabs.Images)"> Images </a>
      </li>
    </ul>

    <div class="modal-body">
      <div *ngIf="website" class="tab-content">
        <section class="rental-settings-page">
          <app-website-details *ngIf="tab$.value === tabs.Details" [website]="website"></app-website-details>
          <app-website-template-settings
            *ngIf="website && website.type !== websiteTypes.BookingEngine && tab$.value === tabs.TemplateSettings"
            [website]="website"
          >
          </app-website-template-settings>
          <app-website-other-pages
            *ngIf="website && website.type !== websiteTypes.BookingEngine && tab$.value === tabs.OtherPages"
            [website]="website"
          >
          </app-website-other-pages>
          <app-website-components *ngIf="tab$.value === tabs.Components" [website]="website"></app-website-components>
          <app-website-contact *ngIf="tab$.value === tabs.ContactInfo" [website]="website"></app-website-contact>
          <app-website-links
            *ngIf="website && website.type !== websiteTypes.GuestPortal && tab$.value === tabs.Links"
            [website]="website"
          ></app-website-links>
          <app-website-resources *ngIf="tab$.value === tabs.Resources" [website]="website"></app-website-resources>
          <app-website-images *ngIf="tab$.value === tabs.Images" [website]="website"></app-website-images>
        </section>
      </div>
    </div>
  </div>
</div>
