import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectExpediaWizardComponent } from './connect-expedia-wizard.component'
import { ConnectExpediaWizardCompleteComponent } from './complete/connect-expedia-wizard-complete.component'
import { ConnectExpediaWizardStep1Component } from './step1/connect-expedia-wizard-step1.component'
import { ConnectExpediaWizardStep2Component } from './step2/connect-expedia-wizard-step2.component'
import { ConnectExpediaWizardStep3Component } from './step3/connect-expedia-wizard-step3.component'

const components = [
  // Expedia
  ConnectExpediaWizardStep1Component,
  ConnectExpediaWizardStep2Component,
  ConnectExpediaWizardStep3Component,
  ConnectExpediaWizardCompleteComponent,
  ConnectExpediaWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectExpediaWizardModule {}
