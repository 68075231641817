import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './guest.reducer'
import * as state from './guest.state'
import { Guest } from './guest.model'
import * as R from 'ramda'
import Perfume from 'perfume.js'

const perfume = new Perfume({
  logPrefix: 'GuestSelectors:',
})

export const guestState = createFeatureSelector<state.State>('guest')

export const selectAllGuests = createSelector(guestState, reducer.selectAll)

export const selectGuestEntities = createSelector(guestState, reducer.selectEntities)

export const selectGuestById = createSelector(selectGuestEntities, (entities, props) => {
  return entities[props.id]
})

export const selectGuestsLoaded = createSelector(guestState, (state: state.State) => state.isLoaded)
export const selectGuestBookingsLoading = createSelector(
  guestState,
  (state: state.State) => state.isLoadingGuestBookings
)

export const selectGuestsPagination = createSelector(guestState, (state) => state.pagination)

export const selectAllGuestsTags = createSelector(selectAllGuests, (guests) => {
  perfume.start('selectAllGuestsTags')

  const result = R.pipe(
    R.map((g: Guest) => g.tags),
    R.flatten,
    R.reject(R.isNil),
    R.map(R.pipe(R.when(R.is(Number), R.toString))),
    R.uniq,
    R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(guests)

  perfume.end('selectAllGuestsTags')

  return result
})

export const selectAllGuestsSources = createSelector(selectAllGuests, (guests) => {
  perfume.start('selectAllGuestsSources')

  const result = R.pipe(
    R.defaultTo([]),
    R.map((g: Guest) => g.source),
    R.flatten,
    R.reject(R.isNil),
    R.map(R.when(R.is(Number), R.toString)),
    R.map(R.toLower),
    R.uniq,
    R.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })),
    R.map((s) => ({ id: s, name: s }))
  )(guests)

  perfume.end('selectAllGuestsSources')

  return result
})

export const selectAllGuestsCountries = createSelector(selectAllGuests, (guests) => {
  perfume.start('selectAllGuestsCountries')

  const result = R.pipe(
    R.defaultTo([]),
    R.map(({ address }: Guest) => address && { id: address.country, name: address.country }),
    R.reject((i) => !i || !i.id),
    R.uniqBy((i) => i.id),
    R.sortBy((i) => i.name)
  )(guests)

  perfume.end('selectAllGuestsCountries')

  return result
})

export const selectGuestsById = (ids: string[]) =>
  createSelector(selectGuestEntities, (entities) => {
    return R.pipe(
      R.map((id: string) => entities[id]),
      R.reject(R.isNil)
    )(ids) as Guest[]
  })
