import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './itinerary.reducer'
import * as state from './itinerary.state'
import * as R from 'ramda'
import { Itinerary } from '@tv3/store/itinerary/itinerary.model'

export const ItineraryState = createFeatureSelector<state.State>('itinerary')

export const selectAllItineraries = createSelector(ItineraryState, reducer.selectAll)

export const selectItineraryByBookingId = createSelector(selectAllItineraries, (itineraries, props) => {
  return R.find((i: Itinerary) => i.bookingId === props.bookingId)(itineraries)
})
