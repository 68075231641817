<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-money-check-alt"></i> Edit Rental Taxes</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div formArrayName="taxes" *ngFor="let item of taxes.controls; let i = index">
      <div [formGroupName]="i" class="row">
        <div class="col-sm-1">
          <button (click)="removeTax(i)" class="remove-tax tax-btn" type="button">
            <i class="fal fa-minus"></i>
          </button>
        </div>
        <div class="col-sm-3">
          <ng-select
            #tax
            [items]="availableTaxes"
            bindLabel="name"
            [addTag]="newTaxName"
            [addTagText]="'Add Tax'"
            [hideSelected]="true"
            [clearable]="false"
            [multiple]="false"
            placeholder="Name"
            appendTo="body"
            formControlName="name"
            (change)="onAccountTaxSelected($event, item)"
            required
          >
          </ng-select>
          <mat-error
            *ngIf="item['controls'].name.touched && item.hasError('required', ['name'])"
            style="margin-top: -12px"
          >
            Name is <strong>required</strong>
          </mat-error>
        </div>
        <div class="col-sm-3">
          <mat-form-field2 class="mat-block">
            <mat-label
              ><span [innerHtml]="item['controls'].type.value | amountPlaceholder: currencySymbol"></span
            ></mat-label>
            <input type="number" min="0" formControlName="amount" required matInput />
            <mat-error *ngIf="item['controls'].amount.touched && item.hasError('required', ['amount'])">
              Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-2">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="type" placeholder="Type">
              <mat-option value="percent">Percent</mat-option>
              <mat-option value="flat">Flat</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-3">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="modality" placeholder="Modality">
              <mat-option [value]="taxV3Modalities.PerStay">{{
                taxV3ModalityLabels[taxV3Modalities.PerStay]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerNight">{{
                taxV3ModalityLabels[taxV3Modalities.PerNight]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerPerson">{{
                taxV3ModalityLabels[taxV3Modalities.PerPerson]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerPersonPerNight">{{
                taxV3ModalityLabels[taxV3Modalities.PerPersonPerNight]
              }}</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button (click)="addTax()" class="add-tax tax-btn text-tokeet-blue" type="button">
          <i class="far fa-plus"></i> Add Tax
        </button>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
