<div class="toolbar-container bg-white p-3 rounded-top">
  <div class="d-flex align-items-center gap-3 w-100">
    <div class="flex-fill"></div>
    <button class="btn btn-outline-light" (click)="gotoToday()" matTooltip="Go to Today" matTooltipPosition="below">
      Today
    </button>

    <button class="btn btn-secondary-primary" (click)="refresh()" matTooltip="Refresh" matTooltipPosition="below">
      <i class="far fa-sync"></i>
    </button>
    <app-select-nav
      class="field-months"
      [disablePrevious]="!(canNavigate | call : selectedMonth : months : 'prev')"
      [disableNext]="!(canNavigate | call : selectedMonth : months : 'next')"
      (previous)="onChangeMonth('prev')"
      (next)="onChangeMonth('next')"
    >
      <mat-select placeholder="Select month" [(ngModel)]="selectedMonth" (selectionChange)="onChangeMonth()">
        <ng-container *ngFor="let month of months">
          <mat-option *ngIf="isInYear | call : month : selectedYear" [value]="month">{{
            month | epochUTC : 'MMMM'
          }}</mat-option>
        </ng-container>
      </mat-select>
    </app-select-nav>

    <app-select-nav
      class="field-years"
      [disablePrevious]="!(canNavigate | call : selectedYear : years : 'prev')"
      [disableNext]="!(canNavigate | call : selectedYear : years : 'next')"
      (previous)="onChangeYear('prev')"
      (next)="onChangeYear('next')"
    >
      <mat-select placeholder="Select year" [(ngModel)]="selectedYear" (selectionChange)="onChangeYear()">
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </app-select-nav>
  </div>
</div>

<div class="calendar-container bg-white px-3 pb-3 rounded-bottom">
  <ngx-calendar-list
    #calendarList
    *ngIf="!loading"
    [prepareEventsOnViewportChange]="true"
    [class.loading]="fetching"
    weekdayFormat="short"
    [months]="months"
    [events]="events"
    [getEventID]="getEventID"
    [getEventTimeRange]="getEventTimeRange"
    [eventHeight]="45"
    [eventMargin]="40"
    [initialDate]="today"
    (viewportChange)="onViewportChange($event)"
    (selectionChange)="onSelectionChange($event)"
  >
    <app-rental-rates-calendar-cell
      *ngxCalendarListDayCell="let day; let events = events"
      [rental]="rental"
      [day]="day"
      [ratesByCategory]="getMonthDayData | call : rentalRatesByCategory : day"
      [events]="events"
      [booked]="blockedDates && blockedDates[day]"
      [today]="day === today"
      [beforeToday]="isBeforeToday | call : day"
      [startOfMonth]="isStartOfMonth | call : day"
      [showPopover]="!showEvents"
      (activePopover)="activePopover = $event"
    >
    </app-rental-rates-calendar-cell>

    <div
      *ngxCalendarListEventChunk="let event; let type = type; let overlap = overlap"
      class="calendar-event calendar-event-{{ type }}"
      [ngClass]="{ 'calendar-event-overlap': overlap }"
      [ngStyle]="{ 'border-left-color': type === 'full' || type === 'start' ? event.color : null }"
      (click)="showEventDetails(event)"
      [matTooltip]="event.titleView"
      matTooltipPosition="above"
    >
      <img *ngIf="!event.isHoldEvent" draggable="false" class="icon" [src]="event.source | inquirySourceIcon" alt="" />
      <div class="text">{{ event.titleView }}</div>
    </div>
  </ngx-calendar-list>

  <i *ngIf="loading || fetching" class="far fa-spinner fa-spin fa-2x"></i>
</div>
