import { deserialize, Expose, Serializable, Type } from '../../functions/serializer'
import { startCaseHyphen } from '../../functions'
import * as R from 'ramda'

/* AdvanceCM Rate */

export type RateType = 'standard' | 'dynamic' | 'promotion'
export type RateThresholdType = 'weekly' | 'monthy'

export class RateDaySpecific {
  threshold: number
  @Expose({ name: 'thresholdfee' })
  thresholdFee: number
  price: number
  days: number[]
}

// @dynamic
export class Rate extends Serializable<Rate> {
  @Expose({ name: 'key' })
  rentalRateId?: string

  @Expose({ name: 'pkey' })
  groupRateId?: string

  get rateId() {
    return this.rentalRateId || this.groupRateId
  }

  account?: string

  @Expose({ name: 'rental_id' })
  rentalId?: string

  @Expose({ name: 'rentals' })
  rentalIds?: string[]

  type: RateType

  name: string
  category: string

  categories?: string[]

  start: number
  end: number

  additionalFeeAmount?: number
  additionalFeePercent?: number
  additionalFeeThreshold?: number

  @Expose({ name: 'dayspecifics' })
  @Type(() => RateDaySpecific)
  daySpecifics?: RateDaySpecific[]

  @Expose({ name: 'maxguests' })
  maximumGuests?: number

  @Expose({ name: 'maximum' })
  maximumStay?: number
  @Expose({ name: 'minimum' })
  minimumStay?: number

  nightly?: number
  monthly?: number
  weekly?: number
  weekend?: number

  @Expose({ name: 'low_rate' })
  lowRate?: number
  @Expose({ name: 'high_rate' })
  highRate?: number
  @Expose({ name: 'low_threshold' })
  lowThreshold?: number
  @Expose({ name: 'high_threshold' })
  highThreshold?: number
  @Expose({ name: 'threshold_type' })
  thresholdType?: RateThresholdType

  static deserialize(data: object) {
    const rate = deserialize(Rate, data) as Rate

    if (rate.groupRateId) {
      // TV3-1733 - auto capitalize group rate name as back-end is saving it as lower-case
      rate.name = startCaseHyphen(rate.name)
    }

    // when updating a rate, backend returns start & end as strings...
    if (R.is(String, rate.start)) {
      rate.start = parseInt((<any>rate).start, 10)
    }

    if (R.is(String, rate.end)) {
      rate.end = parseInt((<any>rate).end, 10)
    }

    return rate
  }
}

export interface RentalRateItem {
  from: string //  "2024-05-08",
  to: string //  "2024-05-08",
  start: number // 1715126400,
  end: number // 1715212799,
  maxguests: number // 2,
  threshold: number // 0,
  thresholdfee: number // 0,
  price: number // 126,
  currency: string //  "USD"
  maximumstay: number // 30,
  minimumstay: number // 1,
}

/* AdvanceCM Rental Rate from "/rental/rates/list" */
// @dynamic
export class RentalRates extends Serializable<RentalRates> {
  @Expose({ name: 'rental' })
  rentalId: string

  currency: string

  @Expose({ name: 'baserate' })
  baseRate: object

  categories: Record<string, RentalRateItem[]>

  static deserialize(data: object): RentalRates {
    return deserialize(RentalRates, data)
  }
}

export interface RentalRatesV2 {
  rental_id: string
  categories: Record<string, RentalRateItem[]>
}

export interface RentalRatesV2Response {
  items: RentalRatesV2[]
  page: number
  rowsPerPage: number
  total: number
}
