import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'app-bdc-listing-wizard-step3',
  templateUrl: './bdc-listing-wizard-step3.component.html',
  styleUrls: ['./bdc-listing-wizard-step3.component.scss'],
})
export class BDCListingWizardStep3Component implements OnInit {
  @Output() prev = new EventEmitter()
  @Output() done = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
