import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import {
  CreateHoliduListing,
  CreateHoliduListingSuccess,
  DeleteHoliduListing,
  DeleteHoliduListingSuccess,
  getHoliduListings,
  GetHoliduListings,
  HoliduListingViewModel,
} from '@tokeet-frontend/channels'
import * as lodash from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Actions, ofType } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'

@Component({
  selector: 'app-holidu-connection-overlay',
  templateUrl: './holidu-connection-overlay.component.html',
  styleUrls: ['./holidu-connection-overlay.component.scss'],
})
export class HoliduConnectionOverlayComponent extends Destroyable implements OnInit {
  channel: Channel
  connection: ConnectionView
  dateFormat = 'DD-MMM-YYYY | h:mm A'

  listingModel: HoliduListingViewModel

  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    switchMap(() => this.connectionService.getApiConnectionLog(this.channel.name, this.connection.roomId))
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { connection: ConnectionView },
    private connectionService: ConnectionService,
    private store: Store<any>,

    private actions: Actions,
    public dialogRef: MatDialogRef<HoliduConnectionOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.connection = this.data.connection
    this.channel = this.data.connection.channel
    this.store.dispatch(GetHoliduListings())

    getHoliduListings(this.store)
      .pipe(
        map((listings) => lodash.find(listings, (t) => t.providerApartmentId === this.connection.propertyId)),
        untilDestroy(this)
      )
      .subscribe((model) => {
        this.listingModel = model
      })
    this.actions.pipe(ofType(CreateHoliduListingSuccess), untilDestroy(this)).subscribe((statuses) => {
      this.listingModel = { ...this.listingModel, active: true }
    })

    this.actions.pipe(ofType(DeleteHoliduListingSuccess), untilDestroy(this)).subscribe((statuses) => {
      this.listingModel = { ...this.listingModel, active: false }
    })
  }

  close() {
    this.dialogRef.close()
  }

  onToggleStatus() {
    if (this.listingModel?.active) {
      this.store.dispatch(DeleteHoliduListing({ propertyId: this.listingModel.providerApartmentId }))
    } else {
      this.store.dispatch(CreateHoliduListing({ listing: this.listingModel }))
    }
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
