import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { DataDictionaryDialogComponent } from '@tv3/shared/data-dictionary-dialog/data-dictionary-dialog.component'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: SharedModule,
})
export class DataDictionaryDialogService {
  constructor(private dialog: MatDialog) {}

  public open(branding?: boolean) {
    const defaultConfig: MatDialogConfig = {
      width: '950px',
      height: '500px',
      data: {
        branding,
      },
      panelClass: ['variable-modal'],
    }

    return this.dialog
      .open(DataDictionaryDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(filter((t) => !!t))
  }
}
