import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { take } from 'rxjs/operators'
import {
  SelectItemDialogComponent,
  SelectItemDialogConfig,
} from '@tv3/shared/dialogs/select-item-dialog/select-item-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable({
  providedIn: SharedModule,
})
export class SelectItemDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: SelectItemDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { config },
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(SelectItemDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
