<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-address-card"></i> Edit Invoice Logo</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-6">
      <h6>Invoice Logo</h6>
      <div class="mb-3">
        <div class="invoice-logo-placeholder" *ngIf="!logoCtrl.value">No Invoice Logo</div>
        <div *ngIf="logoCtrl.value" class="d-flex">
          <div class="mb-3 invoice-logo-wrapper">
            <img [src]="logoCtrl.value" class="invoice-logo" />
            <i
              class="cs-icon cs-icon-trash text-danger remove-icon-btn"
              matTooltip="Remove Logo"
              (click)="onRemoveLogo()"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <h6>Image Description</h6>
      <br />
      <p>
        The invoice logo appears at the top of the invoices you send to guests and statements you send to owners. By
        default it will be a AdvanceCM logo or blank, however you may select another image file by clicking UPLOAD
        button below.
        <strong>We recommend a maximum image height of 70 pixels and a maximum image width of 500 pixels</strong>
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="upload()" type="button" class="btn btn-secondary-info">
    <i class="fal fa-folder-open"></i> upload
  </button>
  <button (click)="onSave()" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
