import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromIncident from './reducer'
import { IncidentEffects } from './effects'
import { IncidentService } from './service'
import { IncidentsGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('incident', fromIncident.incidentReducer),
    EffectsModule.forFeature([IncidentEffects]),
  ],
  declarations: [],
  providers: [IncidentsGuard, IncidentService],
})
export class IncidentsStoreModule {}
