import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { LosDiscountModule } from './store/los-discount/los-discount.module'
import { PermissionModule } from '@tokeet-frontend/permission'

const dialogs = []

const services = []

const modules = [Tv3PlatformModule, LosDiscountModule, PermissionModule]

@NgModule({
  imports: [CommonModule, ...modules],
  providers: [...services],
  declarations: [...dialogs],
  exports: [...modules],
})
export class LosDiscountLibModule {}
