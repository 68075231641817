import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EntityTagsStoreModule } from './store'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { EntityFormTagsComponent } from './components/entity-form-tags/entity-form-tags.component'
import { EntityFilterTagsComponent } from './components/entity-filter-tags/entity-filter-tags.component'
import { ManageEntityTagsComponent } from './components/manage-entity-tags/manage-entity-tags.component'
import { ManageEntityTagsDialogService } from './components/manage-tags/manage-entity-tags-dialog.service'
import { ManageEntityTagsDialogComponent } from './components/manage-tags/manage-entity-tags-dialog.component'

const components = [
  //
  EntityFormTagsComponent,
  EntityFilterTagsComponent,
  ManageEntityTagsComponent,
  ManageEntityTagsDialogComponent,
]

const modules = [
  //
  EntityTagsStoreModule,
  Tv3PlatformModule,
]

const services = [
  //
  ManageEntityTagsDialogService,
]
@NgModule({
  imports: [CommonModule, ...modules],
  declarations: [...components],
  providers: [...services],
  exports: [...modules, ...components],
})
export class EntityTagsLibraryModule {}
