import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { reducer } from './reducer'
import { CreditChargeEffects } from './effects'
import { CreditChargeService } from './service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('credit-charge', reducer),
    EffectsModule.forFeature([CreditChargeEffects]),
  ],
  declarations: [],
  providers: [CreditChargeService],
  exports: [],
})
export class CreditChargeStoreModule {}
