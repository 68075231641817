<div class="container-box p-3">
  <form
    [formGroup]="form"
    [formEditablePermission]="form"
    class=""
    [class.loading-section]="isSavingDetailInfo$ | async"
  >
    <h6 class="text-uppercase">Rental description</h6>
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="type" placeholder="Rental Type">
                <mat-option *ngFor="let t of types" [value]="t">{{ t }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="checkin_type" placeholder="Checkin Type">
                <mat-option *ngFor="let t of checkinTypes" [value]="t.id">{{ t.name }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="number" matInput min="0" formControlName="bedrooms" placeholder="Bedrooms" />
              <mat-error *ngIf="form.get('bedrooms').hasError('min')"> Min value is 0 </mat-error>
              <mat-error *ngIf="form.get('bedrooms').hasError('integer')"> Value should be integer </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2
              class="mat-block"
              matTooltip="Only values in quarters as 1.25, 1.50, 1.75. 2.0 etc.. are allowed for this field"
              matTooltipPosition="above"
            >
              <input type="number" matInput min="0" step="0.25" formControlName="bathrooms" placeholder="Bathrooms" />
              <mat-error *ngIf="form.get('bathrooms').hasError('min')"> Min value is 0 </mat-error>
              <mat-error *ngIf="form.get('bathrooms').hasError('step')">
                Invalid Value. Only value in quarters as 1.25, 1.50, 1.75. 2.0 etc.. are allowed
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="sizeMetric" placeholder="Size Metric">
                <mat-option value="SQFT">SQFT</mat-option>
                <mat-option value="SQM">SQM</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block" matTooltip="Specify the size of your rental." matTooltipPosition="above">
              <input
                type="text"
                matInput
                formControlName="size"
                placeholder="Size ({{ form.get('sizeMetric').value }}):"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2
              class="mat-block"
              matTooltip="Specify the minimum allowed guest on your rental."
              matTooltipPosition="above"
            >
              <input
                type="number"
                matInput
                min="1"
                step="1"
                formControlName="sleepMin"
                placeholder="Minimum Guest Count"
              />
              <mat-error *ngIf="form.get('sleepMin').hasError('max')">
                Max value is {{ form.get('sleepMax').value }}
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2
              class="mat-block"
              matTooltip="Specify the maximum allowed guest on your rental."
              matTooltipPosition="above"
            >
              <input
                type="number"
                matInput
                min="1"
                step="1"
                formControlName="sleepMax"
                placeholder="Maximum Guest Count"
              />
              <mat-error *ngIf="form.get('sleepMax').hasError('min')">
                Min value is {{ form.get('sleepMin').value }}
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            formControlName="description"
            rows="14"
            placeholder="Enter description of the rental property"
          ></textarea>
        </mat-form-field2>
      </div>
    </div>
    <hr />
    <h6 class="text-uppercase mb-4">Additional Information</h6>
    <div class="row">
      <div class="col-sm-6">
        <div class="row gap-row-3">
          <div class="col-sm-6">
            <mat-checkbox
              matTooltip="Specify if your property is suitable for children"
              matTooltipPosition="above"
              formControlName="children_allowed"
              class="mt-primary"
            >
              Children Allowed
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox
              matTooltip="Specify if a property is suitable for events"
              matTooltipPosition="above"
              formControlName="events_allowed"
              class="mt-primary"
            >
              Events Allowed
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox
              matTooltip="Specify if guests are allowed to smoke at the property"
              matTooltipPosition="above"
              formControlName="smoking_allowed"
              class="mt-primary"
            >
              Smoking Allowed
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox
              matTooltip="Specify if your property is suitable for pets"
              matTooltipPosition="above"
              formControlName="pets_allowed"
              class="mt-primary"
            >
              Pets Allowed
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block minimum_age_input">
              <input
                matTooltip="Specify the minimum age for a guest staying at the property"
                matTooltipPosition="above"
                type="number"
                formControlName="guest_minimum_age"
                matInput
                placeholder="Traveller age"
              />
              <mat-error *ngIf="form.get('guest_minimum_age').touched && form.get('guest_minimum_age').hasError('min')">
                Value must be <strong>at least 0</strong>
              </mat-error>
              <mat-error *ngIf="form.get('guest_minimum_age').touched && form.get('guest_minimum_age').hasError('max')">
                Value must be <strong>at most 200</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="locale" placeholder="Locale">
                <mat-option *ngFor="let locale of locales" [value]="locale.id">{{ locale.name }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h6 class="text-uppercase">Rental Details</h6>
      <div class="selectable-items-container d-flex gap-3">
        <button
          class="selectable-item btn btn-light btn-circle"
          (click)="channelFilterForAmenityCtrl.setValue('')"
          [class.active]="!channelFilterForAmenityCtrl.value"
        >
          ALL
        </button>
        <button
          class="selectable-item btn btn-light btn-circle"
          *ngFor="let channel of apiChannels$ | async"
          (click)="channelFilterForAmenityCtrl.setValue(channel.name)"
          [matTooltip]="channel.friendlyName"
          matTooltipPosition="above"
          [class.active]="channelFilterForAmenityCtrl.value === channel.name"
        >
          <img [src]="channel.name | channelIcon" alt="" style="max-width: 15px; max-height: 15px" />
        </button>
      </div>
    </div>
    <div class="row gap-row-3">
      <ng-container *ngFor="let detail of form['controls']['detail']['controls']; let i = index">
        <div
          formArrayName="detail"
          class="col-sm-3"
          *ngIf="isAmenityAvailable | call : details[i].id : channelFilterForAmenityCtrl.value : channelAmenities"
        >
          <mat-checkbox [formControlName]="i" [value]="detail.id" class="mt-primary">
            {{ details[i].name }}
            <ng-container *ngFor="let item of getIcons | call : details[i].id : channelAmenities : channelsById">
              <img [src]="item.name | channelIcon" class="channel-icon mr-1" height="15" [matTooltip]="item.label" />
            </ng-container>
          </mat-checkbox>
        </div>
      </ng-container>
      <div class="empty-channel-amenities">No available Amenity</div>
    </div>
  </form>
</div>
