import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Destroyable, isSomething, SaveForm } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { InvoiceTemplateCostFormula, InvoiceTemplateLineItemCost } from '../../../../store/templates/template.model'
import * as lodash from 'lodash'

export interface EditInvoiceTemplateCostComponentParams {
  cancelBtnClass?: string
  clearBtnClass?: string
  doneBtnClass?: string
  doneBtnIconClass?: string
}

@Component({
  selector: 'app-edit-invoice-template-cost',
  host: { class: 'modal-content' },
  templateUrl: './edit-invoice-template-cost.component.html',
  styleUrls: ['./edit-invoice-template-cost.component.scss'],
})
export class EditInvoiceTemplateCostComponent extends Destroyable implements OnInit {
  config: EditInvoiceTemplateCostComponentParams = {
    cancelBtnClass: 'btn btn-light',
    clearBtnClass: 'btn  btn-secondary-light',
    doneBtnClass: 'btn btn-secondary-info',
    doneBtnIconClass: 'cs-icon cs-icon-save',
  }

  form = this.fb.group({
    base: ['total'],
    cumulativeSubtraction: [true],
    formulas: this.fb.array([]),
  })

  isEditing = false

  constructor(
    public dialogRef: MatDialogRef<EditInvoiceTemplateCostComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { cost: InvoiceTemplateLineItemCost; config?: EditInvoiceTemplateCostComponentParams },
    private fb: FormBuilder
  ) {
    super()
  }

  get formulas() {
    return this.form.get('formulas') as FormArray
  }

  ngOnInit() {
    this.config = {
      ...this.config,
      ...this.data.config,
    }

    const formula: any = R.pipe(R.pathOr([], ['cost', 'formulas']), R.head)(this.data)
    this.isEditing = isSomething(formula) && isSomething(formula.operation) && isSomething(formula.amount)

    const cost = this.data.cost
    if (isSomething(cost)) {
      this.form.patchValue({
        cumulativeSubtraction: cost.cumulativeSubtraction,
      })
      R.map((item) => {
        const form = this.addFormula()
        form.patchValue(item)
      }, cost.formulas)
    }

    if (!this.formulas.length) {
      this.addFormula()
    }
  }

  addFormula() {
    const form = this.fb.group({
      name: [],
      operation: [],
      amount: [undefined, [Validators.min(0)]],
      type: ['percent'],
    })

    const amountCtrl = form.get('amount')
    form.setValidators((form: FormGroup) => {
      const { type, amount } = form.getRawValue()
      if (type === 'percent' && amount > 100) {
        amountCtrl.setErrors({ max: 100 })
      } else if (amountCtrl.hasError('max')) {
        const newErrors = lodash.omit(amountCtrl.errors, 'max')
        amountCtrl.setErrors(lodash.isEmpty(newErrors) ? null : newErrors)
      }
      return null
    })

    this.formulas.push(form)

    return form
  }

  removeFormula(index: number) {
    this.formulas.removeAt(index)
  }

  operator(index: number) {
    const formula = this.formulas.at(index)
    return formula.value.type === 'percent' ? '%' : '$'
  }

  onClose() {
    this.dialogRef.close()
  }

  onClear() {
    this.form.reset()
    this.form.setControl('formulas', this.fb.array([]))
    this.addFormula()
    this.form.patchValue({ base: 'total', cumulativeSubtraction: true })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    let values = form.getRawValue() as InvoiceTemplateLineItemCost
    values = {
      ...values,
      formulas: R.filter((f: InvoiceTemplateCostFormula) => {
        return isSomething(f.operation) && isSomething(f.amount) && isSomething(f.type)
      }, values.formulas),
    }
    this.dialogRef.close(values)
  }
}
