<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center gap-3 w-100">
    <div class="flex-fill"></div>
  </div>
</div>

<div class="filters-box">
  <app-select-search
    [inline]="true"
    bindValue="id"
    bindLabel="name"
    [items]="rentals$ | async"
    [ctrl]="filters.get('rentals')"
    placeholder="Rentals"
    matTooltip="Filter the below rates by rental."
    matTooltipPosition="above"
  >
  </app-select-search>
  <mat-form-field2
    class="field-categories"
    [showLabel]="false"
    matTooltip="Filter the below rates by category."
    matTooltipPosition="above"
  >
    <mat-select [formControl]="filters.get('category')" placeholder="Category">
      <mat-option value="*">All categories</mat-option>
      <mat-option *ngFor="let category of categories$ | async" [value]="category">{{ category }}</mat-option>
    </mat-select>
  </mat-form-field2>

  <mat-form-field2
    class="field-period"
    [showLabel]="false"
    matTooltip="Filter the below rates by period."
    matTooltipPosition="above"
  >
    <app-mat-daterange-input placeholder="Rate Period" [formControl]="filters.get('period')"></app-mat-daterange-input>
  </mat-form-field2>

  <app-cancel-filters
    [isFiltering]="filters | isFiltering"
    (clear)="clearFilters()"
    [filterControl]="filters"
  ></app-cancel-filters>

  <app-button
    (click)="onRefresh()"
    icon="far fa-sync"
    class="btn btn-secondary-primary"
    [loading]="isRefreshing$ | async"
    matTooltip="Refresh"
    matTooltipPosition="above"
  >
  </app-button>

  <div class="flex-fill"></div>

  <app-table-search
    #search
    (search)="onSearch($event)"
    [ctrl]="filters.get('search')"
    matTooltip="Search by any values visible in the table below"
    matTooltipPosition="above"
  ></app-table-search>

  <div class="btn-group" dropdown [isDisabled]="1 | isReadonlyRole">
    <button type="button" class="btn btn-secondary-warning dropdown-toggle" dropdownToggle>
      <span class="cs-icon cs-icon-bolt"></span> Actions
    </button>
    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <li>
        <button
          type="button"
          (click)="onDeleteSelected()"
          [disabled]="!isAnySelected()"
          matTooltip="Delete selected rates."
          matTooltipPosition="left"
        >
          <i class="cs-icon cs-icon-trash"></i> Delete
        </button>
      </li>
    </ul>
  </div>

  <button type="button" class="btn btn-secondary-info" (click)="onAdd()">
    <span class="far fa-plus"></span> Add Dynamic Rate
  </button>
</div>

<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="period" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            (change)="onMasterToggle($event?.checked)"
            class="master-toggle"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="rental">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rental</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-rental-name [rentalId]="element.rentalId" [image]="true"></app-rental-name>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span
            matTooltip="Rate categories are used to group multiple rates together. Rates in a Rate Category cannot overlap. When updating a channel you push a single rate category. All rates in that category will be pushed to the channel."
            matTooltipPosition="above"
          >
            Category <i class="fas fa-info-circle"></i>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="period">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rate Period</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.start | epochUTC: 'MMM D, YYYY' }} - {{ element.end | epochUTC: 'MMM D, YYYY' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rate</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div
            class="text-truncate"
            [matTooltip]="(element.lowRate | rate: currency:false) + ' - ' + (element.highRate | rate: currency:false)"
          >
            {{ element.lowRate | rate: currency:false }} -
            {{ element.highRate | rate: currency:false }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="minimumStay">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Min Stay</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.minimumStay }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> EDIT</mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i>Edit</button>
              </li>
              <li>
                <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Delete</button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onEdit(row)"
        class="clickable"
        matTooltipPosition="above"
      >
      </mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white"></app-empty-table>
  </table>
</div>
