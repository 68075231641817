import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
  Channel,
  ChannelNameTokens,
  Destroyable,
  loadRentals,
  selectAllRentals,
  selectRentalCities,
  selectRentalTags,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { select, Store } from '@ngrx/store'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { HomeToGoRentalConfigView } from '../../../../store/channels.model'
import {
  DisableAllFeedRentals,
  DisableFeedRentals,
  EnableAllFeedRentals,
  EnableFeedRentals,
  LoadRentalConfigs,
  PauseRentalConfig,
  ResumeHomeToGoRentalConfig,
} from '../../../../store/channels.actions'
import { getRentalConfigs } from '../../../../store/channels.selectors'
import { FormControl } from '@angular/forms'
import * as R from 'ramda'
import { SelectionModel } from '@angular/cdk/collections'
import { asyncScheduler, combineLatest } from 'rxjs'
import { map, observeOn, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-feed-channel-details',
  templateUrl: './feed-channel-details.component.html',
  styleUrls: ['./feed-channel-details.component.scss'],
})
export class FeedChannelDetailsComponent extends Destroyable implements OnInit, AfterViewInit {
  @Input() channel: Channel

  @Output() rentalConfig = new EventEmitter()

  dataSource = new MatTableDataSource<HomeToGoRentalConfigView>()

  @ViewChild('paginator', { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['select', 'rental.name', 'date_enabled', 'lastSync', 'propertyId', 'status']

  isEmptyTable$ = isEmptyTable(this.dataSource)

  rentalConfigs: HomeToGoRentalConfigView[]

  statusCtrl = new FormControl('')
  tagsCtrl = new FormControl([])
  citiesCtrl = new FormControl([])
  renderedData = []
  selection = new SelectionModel(true, [])
  tags$ = this.store.pipe(select(selectRentalTags))
  cities$ = this.store.pipe(select(selectRentalCities))

  rentalsWithoutTaxes$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => R.filter((r) => R.isNil(r.taxes) || R.isEmpty(r.taxes), rentals))
  )

  anySelectedRentalMissingTaxes() {
    return R.any((s) => R.isNil(s?.rental?.taxes) || R.isEmpty(s?.rental?.taxes), this.selection.selected)
  }

  get channelName() {
    switch (this.channel?.name) {
      case ChannelNameTokens.HomeToGo:
        return 'HomeToGo'
      case ChannelNameTokens.Homeaway:
        return 'VRBO'
    }
  }

  get taxesRequired() {
    switch (this.channel?.name) {
      case ChannelNameTokens.HomeToGo:
        return true
      case ChannelNameTokens.Homeaway:
        return false
    }
  }

  constructor(private store: Store<any>, private toaster: Toaster) {
    super()
  }

  ngAfterViewInit() {
    this.store.dispatch(loadRentals({}))

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.store.dispatch(LoadRentalConfigs({ channel: this.channel.name }))

    this.store
      .pipe(getRentalConfigs(this.channel.name), observeOn(asyncScheduler), untilDestroy(this))
      .subscribe((configs) => {
        this.rentalConfigs = configs as HomeToGoRentalConfigView[]
        this.dataSource.data = this.rentalConfigs
      })

    combineLatest([
      this.citiesCtrl.valueChanges.pipe(startWith('')),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.statusCtrl.valueChanges.pipe(startWith([])),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([cities, tags, status]) => {
        let configs = this.rentalConfigs
        switch (status) {
          case 'all':
            configs = this.rentalConfigs
            break
          case 'active':
            configs = R.filter((config) => config.status === 1, this.rentalConfigs)
            break
          case 'inactive':
            configs = R.filter((config) => config.status === 0, this.rentalConfigs)
            break
        }
        if (tags?.length > 0) {
          configs = R.filter(
            (config) =>
              R.any(
                R.equals(true),
                R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], config.rental)), tags)
              ),
            configs
          )
        }
        if (cities?.length > 0) {
          configs = R.filter((config) => R.contains(R.pathOr('', ['address', 'city'], config.rental), cities), configs)
        }
        this.dataSource.data = configs || []
      })

    this.dataSource['_renderData'].subscribe((rows) => {
      this.renderedData = rows
    })
    this.dataSource['_data'].subscribe(() => {
      this.selection = new SelectionModel(true, [])
    })
  }

  ngOnInit() {}

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  onRentalConfigDetails(config: HomeToGoRentalConfigView) {
    this.rentalConfig.emit(config)
  }

  onToggleStatus(config: HomeToGoRentalConfigView) {
    if (config.status === 1) {
      this.store.dispatch(PauseRentalConfig({ config, channel: this.channel.name }))
    } else {
      this.store.dispatch(ResumeHomeToGoRentalConfig({ config, channel: this.channel.name }))
    }
  }

  onEnableAll() {
    this.store.dispatch(EnableAllFeedRentals({ channel: this.channel.name }))
  }

  onDisableAll() {
    this.store.dispatch(DisableAllFeedRentals({ channel: this.channel.name }))
  }

  onEnableSelected() {
    const rentalIds = R.map((s) => s.rental_id, this.selection.selected)
    if (R.isEmpty(rentalIds)) {
      this.toaster.info('Select some rental connections')
      return
    }
    this.store.dispatch(EnableFeedRentals({ channel: this.channel.name, rentalIds }))
  }

  onDisableSelected() {
    const rentalIds = R.map((s) => s.rental_id, this.selection.selected)
    if (R.isEmpty(rentalIds)) {
      this.toaster.info('Select some rental connections')
      return
    }
    this.store.dispatch(DisableFeedRentals({ channel: this.channel.name, rentalIds }))
  }

  masterToggle() {
    if (this.isAllSelected(this.selection, this.renderedData)) {
      this.selection.clear()
    } else {
      this.dataSource.data.forEach((row) => {
        if (
          !R.isNil(
            R.find((rendered) => {
              return rendered['rental_id'] === row['rental_id']
            }, this.renderedData)
          )
        ) {
          this.selection.select(row)
        }
      })
    }
  }

  isAllSelected<T>(selection: SelectionModel<T>, renderedData: T[]) {
    return selection.selected?.length === renderedData?.length
  }
}
