<div class="modal-header">
  <h6 class="modal-title">Copy Taxes From</h6>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-select-taxes-table
    [excludedRentalIds]="[rental.id]"
    selectRentalsLabel="Select Rentals to Copy from"
    (selected)="onSelect($event)"
  ></app-select-taxes-table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="dialogRef.close()"><i class="far fa-times"></i> Cancel</button>
  <button
    type="button"
    class="btn btn-secondary-success"
    [disabled]="!selectedTaxes?.length || isLoading"
    (click)="onOverwrite()"
    matTooltip="It will replace your current settings"
  >
    <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i> Overwrite
  </button>
  <button
    type="button"
    class="btn btn-secondary-info"
    [disabled]="!selectedTaxes?.length || isLoading"
    (click)="onAdd()"
    matTooltip="Selected settings will be added to your rental"
  >
    <i class="far fa-plus" *ngIf="!isLoading"></i>
    <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i> Add
  </button>
</div>
