import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { concatMap, map, toArray } from 'rxjs/operators'
import { Message, MessageType } from '@tv3/store/message/message.model'
import { ChannelMessagePayload, MessageRequest } from '@tv3/store/message/message.request'
import { MessageResponse } from '@tv3/interfaces/responses/message.response'
import { deserializeArray, isUUIDLose } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { isUUID } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: HttpClient) {}

  getMessagesByInquiryId(inquiryId: string): Observable<Message[]> {
    const url = `@api/message/inquiry/${inquiryId}?include_draft=1`
    return this.http.get(url).pipe(deserializeArray<Message>(Message))
  }

  getLatest(type?: MessageType): Observable<Message[]> {
    const url = `@api/message/latest`

    return this.http.get(url, { params: { type: type as any } }).pipe(deserializeArray<Message>(Message))
  }

  create(request: MessageRequest): Observable<Message> {
    const url = `@api/message/`

    return this.http.post<object>(url, request).pipe(
      // when sending message to ABB, no message returned, create one from payload
      map((response) => Message.deserialize(response))
    )
  }

  createChannelMessage(payload: ChannelMessagePayload) {
    const url = '@api/v1/message'
    return this.http.post<any>(url, payload)
  }

  remove(message: Message): Observable<{ messageId: string; inquiryId: string }> {
    const url = `@api/message/delete/${message.id}`

    return this.http.delete<MessageResponse>(url).pipe(map((r) => ({ messageId: r.pkey, inquiryId: r.inquiry_id })))
  }

  removeBatch(messages: Message[]) {
    return of(...messages).pipe(
      concatMap((item) => this.remove(item)),
      toArray()
    )
  }

  removeFromFeed(message: Message) {
    const url = `@api/message/remove/feed/${message.id}`

    return this.http.delete<MessageResponse>(url)
  }

  removeFromFeedBatch(messages: Message[]) {
    return of(...messages).pipe(
      concatMap((item) => this.removeFromFeed(item)),
      toArray()
    )
  }

  markAsRead(messageIds: string[]) {
    const url = `@api/message/read/all`

    messageIds = lodash.filter(messageIds, (id) => isUUIDLose(id))

    return this.http.put(url, { messages: messageIds })
  }

  move(messageId: string, inquiryId: string) {
    const url = `@api/message/move/${messageId}/${inquiryId}`

    return this.http.put(url, {})
  }
}
