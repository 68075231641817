import { Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { difference, map, omit, isEqual, isArray, pick, keyBy, mapValues } from 'lodash'

export function compareListById(items1, items2) {
  items1 = isArray(items1) ? items1 : [items1]
  items2 = isArray(items2) ? items2 : [items2]

  const ids1 = map(items1, 'id')
  const ids2 = map(items2, 'id')
  if (ids1.length !== ids2.length) {
    return false
  }

  return !difference(ids1, ids2).length && !difference(ids2, ids1).length
}

export function distinctUntilChangedByIds(): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) => source.pipe(distinctUntilChanged(compareListById))
}

export function distinctObjectUntilChangedByOmitFields(fields: string[]): (source: Observable<any>) => Observable<any> {
  return (source: Observable<any>) =>
    source.pipe(
      distinctUntilChanged((value1, value2) => {
        const v1 = omit(value1, fields)
        const v2 = omit(value2, fields)
        return isEqual(v1, v2)
      })
    )
}

export function distinctObjectUntilChangedByFields(
  fields: string[],
  id: string = 'id'
): (source: Observable<any>) => Observable<any> {
  const isDataEqual = (value1, value2) => {
    const v1 = pick(value1, fields)
    const v2 = pick(value2, fields)
    return isEqual(v1, v2)
  }
  return (source: Observable<any>) =>
    source.pipe(
      distinctUntilChanged((value1, value2) => {
        if (!isArray(value1) || !isArray(value2)) {
          return isDataEqual(value1, value2)
        }

        const equal = compareListById(value1, value2)
        if (!equal) return false

        const itemsById1 = mapValues(keyBy(value1, id), (v) => pick(v, fields))
        const itemsById2 = mapValues(keyBy(value2, id), (v) => pick(v, fields))

        return isEqual(itemsById1, itemsById2)
      })
    )
}
