import { Component, Input, OnInit } from '@angular/core'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { HomeAwayRentalConfigView } from '@tokeet-frontend/channels'

@Component({
  selector: 'app-feed-connection-status',
  templateUrl: './feed-connection-status.component.html',
  styleUrls: ['./feed-connection-status.component.scss'],
})
export class FeedConnectionStatusComponent extends Destroyable implements OnInit {
  @Input() dateFormat: string
  @Input() config: HomeAwayRentalConfigView

  connectionStatus: {
    lastavailpull: number
    lastratepull: number
  }

  constructor() {
    super()
  }

  ngOnInit() {
    this.connectionStatus = {
      lastavailpull: this.config.attributes?.availabilitypull,
      lastratepull: this.config.attributes?.ratepull,
    }
  }
}
