import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ImportIcalCalendarDialogComponent } from './import-ical-calendar-dialog.component'

@Injectable({
  providedIn: SharedModule,
})
export class ImportIcalCalendarDialogService {
  constructor(private dialog: MatDialog) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(ImportIcalCalendarDialogComponent, defaultConfig)
  }
}
