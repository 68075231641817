export const holiduCancelReasons = [
  { id: 'PROVIDER_NO_PAYMENT', label: 'No Payment' },
  { id: 'PROVIDER_OVERBOOKED', label: 'Overbooked' },
  { id: 'CUSTOMER_WRONG_PRICE', label: 'Wrong Price' },
  { id: 'CUSTOMER_WRONG_FACILITIES', label: 'Wrong Facilities' },
  { id: 'CUSTOMER_UNKNOWN_REASON', label: 'Unknown Reason' },
  { id: 'CUSTOMER_REBOOKING', label: 'Rebooking' },
  { id: 'CUSTOMER_PERSONAL', label: 'Personal' },
  { id: 'CUSTOMER_ALTERNATIVE_HOLIDU_PRICE', label: 'Alternative Holidu Price' },
  { id: 'CUSTOMER_ALTERNATIVE_HOLIDU_LOCATION', label: 'Alternative Holidu Location' },
  { id: 'CUSTOMER_ALTERNATIVE_HOLIDU_OTHER', label: 'Alternative Holidu Other' },
  { id: 'CUSTOMER_ALTERNATIVE_ELSEWHERE_PRICE', label: 'Alternative Elsewhere Price' },
  { id: 'CUSTOMER_ALTERNATIVE_ELSEWHERE_LOCATION', label: 'Alternative Elsewhere Location' },
  { id: 'CUSTOMER_ALTERNATIVE_ELSEWHERE_OTHER', label: 'Alternative Elsewhere Other' },
  { id: 'CUSTOMER_TRAVEL_RESTRICTIONS_VISA', label: 'Travel Restrictions visa' },
  { id: 'CUSTOMER_TRAVEL_RESTRICTIONS_COVID', label: 'Travel Restrictions Covid' },
  { id: 'CUSTOMER_TRAVEL_RESTRICTIONS_OTHER', label: 'Travel Restrictions Other' },
  { id: 'CUSTOMER_PROVIDER_REQUEST_BOOK_ELSEWHERE', label: 'Provider Request Book Elsewhere' },
  { id: 'CUSTOMER_PROVIDER_REQUEST_AVAILABILITY', label: 'Provider Request Availability' },
  { id: 'CUSTOMER_PROVIDER_REQUEST_OTHER', label: 'Provider Request Other' },
  { id: 'CUSTOMER_PROPERTY_QUALITY', label: 'Property Quality' },
  { id: 'CUSTOMER_OTHER', label: 'Other' },
]
