export enum ConfirmDialogStatus {
  Confirmed,
  Canceled,
  Closed,
}

export interface ConfirmDialogParams {
  title?: string
  body?: string
  extra?: string
  extraCheckedRequired?: true
  extraCheckedRequiredMsg?: string
  defaultExtraValue?: boolean
  confirmText?: string
  confirmBtnClass?: string
  confirmBtnTooltip?: string
  cancelText?: string
  cancelBtnClass?: string
  cancelBtnTooltip?: string
  headerInfo?: string
  articleId?: string
  videoId?: string
  isDangerous?: boolean // default is true
}
