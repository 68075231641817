import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BookingFormulaDialogService } from './booking-formula-dialog/booking-formula-dialog.service'
import { BookingFormulaDialogComponent } from './booking-formula-dialog/booking-formula-dialog.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { BookingFormulasOverlayComponent } from './overlay/booking-formulas-overlay.component'
import { BookingFormulaLibraryModule } from '@tokeet-frontend/booking-formula'
import { BookingFormulasTableComponent } from './table/booking-formulas-table.component'
import { BookingFormulasOverlayService } from './overlay/booking-formulas-overlay.service'
import { SharedModule } from '@tv3/shared/shared.module'

const components = [BookingFormulaDialogComponent, BookingFormulasOverlayComponent, BookingFormulasTableComponent]
const services = [BookingFormulaDialogService, BookingFormulasOverlayService]

const modules = [
  //
  SharedModule,
  Tv3PlatformModule,
  BookingFormulaLibraryModule,
]

@NgModule({
  imports: [CommonModule, ...modules],
  declarations: [...components],
  providers: [...services],
  exports: [...components, ...modules],
})
export class BookingFormulaSharedModule {}
