import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { BDCRatePlan } from '@tv3/store/channel-content/models/bdc.model'
import * as lodash from 'lodash'
import {
  BDCCancelOption,
  bdcFreeCancelOptions,
  bdcNonFreeCancelOptions,
  bdcNonFreeFullChargeCancelOption,
} from '@tv3/constants/bdc-cancel-policies'

export interface BDCProductDialogParams {
  propertyId: number
  roomId: number
  selectedRateIds?: number[]
  availableCancelPolicies: number[]
}

@Component({
  selector: 'app-bdc-product-dialog',
  templateUrl: './bdc-product-dialog.component.html',
  styleUrls: ['./bdc-product-dialog.component.scss'],
})
export class BdcProductDialogComponent implements OnInit {
  form = this.fb.group({
    rateId: [],
    cancelPolicyCode: [undefined, [Validators.required]],
    minAdvancedBookingOffset: [undefined, [Validators.required]],
    minAdvancedBookingOffsetType: ['H', [Validators.required]],
    maxAdvancedBookingOffset: [undefined, [Validators.required]],
    maxAdvancedBookingOffsetType: ['H', [Validators.required]],
  })
  advancedBookingOffsetTypes = [
    { label: 'Year', value: 'Y' },
    { label: 'Month', value: 'M' },
    { label: 'Day', value: 'D' },
    { label: 'Hour', value: 'H' },
  ]
  rates: BDCRatePlan[] = []
  allBdcCancelPolicies = [bdcNonFreeFullChargeCancelOption, ...bdcNonFreeCancelOptions, ...bdcFreeCancelOptions]
  cancelPolicies: BDCCancelOption[] = []

  constructor(
    private fb: FormBuilder,
    private bdcContentService: BdcContentService,
    private toaster: Toaster,
    public dialogRef: MatDialogRef<BdcProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BDCProductDialogParams
  ) {}

  ngOnInit() {
    this.bdcContentService.getRates(this.data.propertyId).subscribe((data: BDCRatePlan[]) => {
      this.rates = lodash.filter(data, (rate) => {
        return !lodash.includes(this.data.selectedRateIds, rate.id)
      })
      if (!this.rates.length) {
        this.toaster.warning('There is no available rate for new product. please create a new rate.')
      }
    })

    this.cancelPolicies = lodash.filter(this.allBdcCancelPolicies, ({ value }: BDCCancelOption) => {
      return lodash.includes(this.data.availableCancelPolicies, value)
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onCreate(form: FormGroup) {
    const formData = this.form.getRawValue()
    this.bdcContentService
      .createProduct({
        propertyId: this.data.propertyId,
        roomId: this.data.roomId,
        rateId: formData.rateId,
        cancelPolicyCode: formData.cancelPolicyCode,
        minAdvancedBookingOffset: `P${formData.minAdvancedBookingOffset || 0}${formData.minAdvancedBookingOffsetType}`,
        maxAdvancedBookingOffset: `P${formData.maxAdvancedBookingOffset || 0}${formData.maxAdvancedBookingOffsetType}`,
      })
      .subscribe(({ warnings }) => {
        this.toaster.success('Product added successfully.')
        if (warnings && warnings.length) {
          this.toaster.warning('Product Warnings: ' + warnings.join('\n'))
        }
        this.close()
      })
  }
}
