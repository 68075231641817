import * as lodash from 'lodash'

export function startCaseHyphen(text: string) {
  if (!text) return ''

  let parts = (text + '').split(/\s*-\s*/)

  parts = parts.map((part, index) => {
    const firstChart = part[0] || ''
    part = lodash.startCase(part)

    if (index === 0) return part

    return firstChart + part.slice(1)
  })

  return parts.join(' - ').trim()
}
