import { Component, Input, OnInit, QueryList, TemplateRef } from '@angular/core'
import { FormFieldComponent } from '../form-field/form-field.component'
import { FormFieldPrefixDirective, FormFieldSuffixDirective } from '../form-field/form-field.directive'
import * as lodash from 'lodash'
import { FormFieldAffixComponent } from '../form-field-affix/form-field-affix.component'

type AffixDirective = FormFieldPrefixDirective | FormFieldSuffixDirective

@Component({
  selector: 'app-form-field-render',
  templateUrl: './form-field-render.component.html',
  styleUrls: ['./form-field-render.component.scss'],
})
export class FormFieldRenderComponent implements OnInit {
  @Input() field: FormFieldComponent

  @Input() prefix: TemplateRef<FormFieldAffixComponent>
  @Input() suffix: TemplateRef<FormFieldAffixComponent>

  constructor() {}

  ngOnInit(): void {}

  getAffixes(affixes: QueryList<AffixDirective>, ctrlAffix: TemplateRef<FormFieldAffixComponent>) {
    const templates = lodash.filter([...affixes.map((p) => p.template), ctrlAffix])

    return templates.length > 0 ? templates : null
  }
}
