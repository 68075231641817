<form [formGroup]="form" [formEditablePermission]="form">
  <div class="row">
    <!-- Title -->
    <div class="col-sm-12">
      <mat-form-field2 class="mat-block">
        <input type="text" matInput required placeholder="Title" formControlName="title" tabindex="1" />
        <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
          Title is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('maxlength')">
          Title must be at most <strong>50</strong> characters long
        </mat-error>
        <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('allSpaces')">
          Title cannot be spaces alone.
        </mat-error>
        <mat-hint align="end">{{ form.get('title').value?.length || 0 }}/50</mat-hint>
      </mat-form-field2>
    </div>

    <!-- From -->
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <app-mat-date-input
          placeholder="From"
          formControlName="start"
          [max]="maxStartDate.value"
          required
        ></app-mat-date-input>

        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
          From is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('start').hasError('min')">
          Min value is
          <strong>{{ form.get('start').getError('min').min | epoch : 'date' }}</strong>
        </mat-error>
        <mat-error *ngIf="form.get('start').hasError('max')">
          Max value is
          <strong>{{ form.get('start').getError('max').max | epoch : 'date' }}</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <app-mat-date-input
          placeholder="Until"
          formControlName="end"
          [min]="minEndDate.value"
          required
        ></app-mat-date-input>

        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('end').touched && form.get('end').hasError('required')">
          Until is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('end').hasError('min')">
          Min value is
          <strong>{{ form.get('end').getError('min').min | epoch : 'date' }}</strong>
        </mat-error>
        <mat-error *ngIf="form.get('end').hasError('max')">
          Max value is
          <strong>{{ form.get('end').getError('max').max | epoch : 'date' }}</strong>
        </mat-error>
      </mat-form-field2>
    </div>

    <!-- Type -->
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <mat-select
          matTooltip="Hold Events will be sent to your connected channels and calendars."
          matTooltipPosition="above"
          formControlName="type"
          placeholder="Type"
          [tabIndex]="2"
        >
          <mat-option *ngFor="let type of types" [value]="type.id">{{ type.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('type').touched && form.get('type').hasError('required')">
          Type is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <!-- Expires -->
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <app-mat-date-input
          formControlName="expires"
          placeholder="Expiration"
          [min]="minExpirationDate"
          matTooltip="This Hold Event will be automatically removed from AdvanceCM on the expiration date."
          matTooltipPosition="above"
        ></app-mat-date-input>

        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('expires').hasError('min')">
          Min value is
          <strong>{{ form.get('expires').getError('min').min | epoch : 'date' }}</strong>
        </mat-error>
        <mat-error *ngIf="form.get('expires').hasError('max')">
          Max value is
          <strong>{{ form.get('expires').getError('max').max | epoch : 'date' }}</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-md-12">
      <mat-form-field2 class="mat-block">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          #notes
          maxlength="500"
          rows="3"
          formControlName="description"
          placeholder="Enter description"
          cdkTextareaAutosize
          [cdkAutosizeMaxRows]="5"
        ></textarea>
      </mat-form-field2>
    </div>

    <div class="col-sm-12">
      <app-guest-select [ctrl]="form.get('guest_id')"></app-guest-select>
    </div>

    <!-- Rentals -->
    <div class="col-sm-12" *ngIf="!isEdit">
      <app-select-search
        [inline]="false"
        bindValue="id"
        bindLabel="name"
        [items]="rentals$ | async"
        [ctrl]="form.get('rentalIds')"
        placeholder="Select Rental(s)"
        [tabIndex]="3"
      >
        <ng-container class="error-container">
          <mat-error> Rental(s) is <strong>required</strong> </mat-error>
        </ng-container>
      </app-select-search>
    </div>
    <div class="col-sm-12" *ngIf="isEdit">
      <app-select-search
        bindValue="id"
        bindLabel="name"
        [inline]="false"
        [multiple]="false"
        [items]="rentals$ | async"
        [ctrl]="form.get('rental_id')"
        placeholder="Select Rental"
      >
        <ng-container class="error-container">
          <mat-error> Rental is <strong>required</strong> </mat-error>
        </ng-container>
      </app-select-search>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <mat-checkbox
        matTooltip="Check this box and AdvanceCM will keep shifting your hold event forward by 1 day every 24 hours."
        matTooltipPosition="above"
        [checked]="!!form.get('rolling').value"
        (change)="onRollingChanged($event)"
        >Roll Forward</mat-checkbox
      >
    </div>
    <div class="col-sm-12 mt-3">
      <label>Select Color</label>
      <app-color-boxes [ctrl]="form.get('color')"></app-color-boxes>
    </div>
  </div>
</form>
