<ngb-datepicker
  class="border-0 flex-1"
  [(ngModel)]="selectedDate"
  #datepicker
  (ngModelChange)="onDateChange()"
  [displayMonths]="1"
  [dayTemplate]="tmplDate"
  [maxDate]="dateToDateStruct | call: maxDate"
  [minDate]="dateToDateStruct | call: minDate"
  [startDate]="dateToDateStruct | call: startDate"
  [outsideDays]="showTime ? 'collapsed' : 'hidden'"
  [footerTemplate]="showTime ? timepicker : null"
>
</ngb-datepicker>

<ng-template #timepicker>
  <div class="d-flex justify-content-center align-items-center border-top">
    <ngb-timepicker
      [(ngModel)]="selectedTime"
      (ngModelChange)="onTimeChange()"
      [meridian]="meridian"
      [hourStep]="hourStep"
      [minuteStep]="minuteStep"
      [secondStep]="secondStep"
      [seconds]="seconds"
      [size]="timeSize"
    ></ngb-timepicker>
  </div>
</ng-template>

<ng-template
  #tmplDate
  let-date="date"
  let-focused="focused"
  let-selected="selected"
  let-disabled="disabled"
  let-today="today"
>
  <span
    *ngIf="date"
    class="drp-day"
    [class.focused]="focused"
    [class.selected]="selected"
    [class.disabled]="disabled"
    [class.today]="today"
  >
    {{ date.day }}
  </span>
</ng-template>
