import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { CustomDomainResponse } from '@tokeet-frontend/ssl'
import { Store } from '@ngrx/store'
import { SetCustomDomainCerts } from 'libs/ssl/src/lib/ssl.actions'

@Component({
  selector: 'app-website-custom-domain-ssl-dialog',
  templateUrl: './website-custom-domain-ssl-dialog.component.html',
  styleUrls: ['./website-custom-domain-ssl-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class WebsiteCustomDomainSslDialogComponent implements OnInit {
  get domain() {
    return this.data.domain
  }

  get isEditable() {
    return this.domain?.ssl?.type !== 'letsencrypt'
  }

  form = this.fb.group({
    key: ['', [Validators.required]],
    cert: ['', [Validators.required]],
    ca: [],
  })

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<WebsiteCustomDomainSslDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { domain: CustomDomainResponse }
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      ...this.domain.ssl,
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const data = this.form.getRawValue()
    this.store.dispatch(SetCustomDomainCerts({ id: this.domain._id, payload: data }))
  }
}
