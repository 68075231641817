<div class="modal-header">
  <h5 class="modal-title">
    <ng-container *ngIf="!isEditing">Set Cost Formula </ng-container>
    <ng-container *ngIf="isEditing">Edit Cost Formula </ng-container>
    <small
      ><i
        matTooltip="Set an adjustment to the inquiry’s imported booking charges."
        matTooltipPosition="above"
        class="fal info fa-question-circle"
      ></i
    ></small>
  </h5>
  <button (click)="onClose()" type="button" class="close">
    <span class="far fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <div class="box box-mt-has-pd clearfix">
    <form [formGroup]="form" class="row">
      <div class="col-12">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="base" placeholder="Start the adjustment formula with">
            <mat-option value="total">Booking Total</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <div class="col-12 mb-3">
        <mat-button-toggle-group
          formControlName="cumulativeSubtraction"
          tooltip="This allows you to switch the behavior between cumulative subtraction and aggregate subtraction."
          container="body"
        >
          <mat-button-toggle [value]="false" class="text-sm"> Aggregate </mat-button-toggle>
          <mat-button-toggle [value]="true" class="text-sm"> Cumulative </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div
        formArrayName="formulas"
        *ngFor="let item of formulas.controls; let i = index; let last = last"
        class="col-12"
      >
        <div [formGroupName]="i" class="d-flex gap-3">
          <mat-form-field2 class="mat-block">
            <mat-label> Name: </mat-label>
            <input type="text" formControlName="name" matInput />
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="operation" placeholder="Operation">
              <mat-option value="add">Add (+)</mat-option>
              <mat-option value="subtract">Subtract (-)</mat-option>
            </mat-select>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <mat-label> Amount ({{ operator(i) }}): </mat-label>
            <input type="text" formControlName="amount" matInput />
            <mat-error *ngIf="item.get('amount').hasError('min')"> Minimum value is 0. </mat-error>
            <mat-error *ngIf="item.get('amount').hasError('max')"> Maximum value is 100. </mat-error>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="type" placeholder="Type">
              <mat-option value="percent">Percent</mat-option>
              <mat-option value="flat">Flat</mat-option>
            </mat-select>
          </mat-form-field2>
          <div class="align-input-row-end">
            <button *ngIf="!last" (click)="removeFormula(i)" type="button" class="btn btn-light-danger">
              <i class="fas fa-minus"></i>
            </button>
            <button *ngIf="last" (click)="addFormula()" type="button" class="btn btn-light-info">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button (click)="onClose()" type="button" [ngClass]="config.cancelBtnClass">
    <i class="far fa-times"></i> cancel
  </button>
  <button (click)="onClear()" type="button" [ngClass]="config.clearBtnClass"><i class="fal fa-sync"></i> Reset</button>
  <button (click)="onSave(form)" type="button" [ngClass]="config.doneBtnClass">
    <i [ngClass]="config.doneBtnIconClass"></i> Save
  </button>
</div>
