<div class="modal-header">
  <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i> {{ options.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-upload-box
    [options]="options"
    image="/assets/images/upload-files.svg"
    direction="v"
    (complete)="onUploaded($event)"
    #uploadBox
  ></app-upload-box>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="uploadBox.upload()"
    [disabled]="!uploadBox?.files.length || uploadBox?.isUploading"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i class="cs-icon cs-icon-upload"></i> upload
  </button>
</div>
