import { Pipe, PipeTransform } from '@angular/core'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { ChannelService } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'isAirBnBV2',
})
export class IsAirBnbV2Pipe implements PipeTransform {
  transform(channel: Channel) {
    return ChannelService.isAirBnBV2(channel.name)
  }
}
