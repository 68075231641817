<app-loading-section *ngIf="isLoading; else content"></app-loading-section>
<ng-template #content>
  <div class="p-3">
    <app-history-list
      *ngIf="!!activities?.length; else empty"
      [list]="activities || []"
      contentKey="log"
      userNameKey="guestName"
      timeKey="occuredAt"
      defaultUserName=""
    ></app-history-list>
    <ng-template #empty>
      <app-empty-table class="py-1" type="AccessCodeLogs"></app-empty-table>
    </ng-template>
  </div>
</ng-template>
