<app-avatar
  [backgroundColor]="backgroundColor"
  [image]="url"
  [size]="imageSize"
  [circle]="imageCircle"
  [text]="name"
  *ngIf="image"
></app-avatar>
<div class="text-truncate" [matTooltip]="name" matTooltipPosition="above" [matTooltipDisabled]="tooltipDisabled">
  {{ name }}
</div>
