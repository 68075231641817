import { Injectable } from '@angular/core'
import { MessageDialogComponent } from './message-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class MessageSidePanelService {
  constructor(private sidePanel: DialogService) {}

  open(inquiryId: string, messageId?: string) {
    return this.sidePanel.openDrawer(MessageDialogComponent, {
      data: { inquiryId, messageId },
      panelClass: ['drawer-full-80'],
    })
  }
}
