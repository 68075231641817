import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import * as R from 'ramda'
import { GuestModifyNoteDialogComponent } from '@tv3/containers/guests/edit/note/modify/guest-modify-note-dialog.component'

@Injectable()
export class GuestModifyNoteDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: MatDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: null,
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(GuestModifyNoteDialogComponent, R.merge(defaultConfig, config))
  }
}
