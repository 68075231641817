<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-1">
        <h6 class="modal-title">Attribute Descriptions</h6>
        <div class="flex-fill"></div>
        <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="mx-3 mt-3">
      <div [formGroup]="form" class="row">
        <div class="col-sm-3">
          <mat-form-field2 [showLabel]="false" matTooltip="Entity Type">
            <mat-select formControlName="entity_type" placeholder="Entity Type">
              <mat-option *ngFor="let item of entityTypes" [value]="item">
                {{ item | titleCase }}
              </mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div class="container-box p-3">
        <form [formGroup]="form">
          <div formArrayName="attributes" *ngFor="let item of attributesArrayForm.controls; let i = index">
            <div [formGroupName]="i" class="row">
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <input type="text" formControlName="label" placeholder="Name" matInput required />
                  <mat-error *ngIf="item.get('label').touched && item.get('label').hasError('required')">
                    Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="item.get('label').hasError('duplicated')">
                    Name is <strong>duplicated</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-3">
                <mat-form-field2 class="mat-block">
                  <mat-select formControlName="field_type" placeholder="Data Type" required>
                    <mat-option [value]="item" *ngFor="let item of fieldTypes">{{ item | titleCase }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="item.get('field_type').touched && item.get('field_type').hasError('required')">
                    Data Type is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-5">
                <mat-form-field2 class="mat-block">
                  <input type="text" formControlName="description" placeholder="Description" matInput />
                </mat-form-field2>
              </div>
              <div class="col-sm-1">
                <button
                  (click)="removeAttr(i)"
                  matTooltip="Remove this attribute"
                  class="btn btn-secondary-danger align-input-row-end"
                  type="button"
                >
                  <i class="cs-icon cs-icon-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex align-items-center gap-3 mt-3">
        <button (click)="addAttr()" class="btn btn-secondary-info" type="button">
          <i class="far fa-plus"></i> Add Attribute
        </button>
      </div>
    </div>
  </div>
</div>
