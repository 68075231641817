<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h5 class="modal-title">
          Pricing Settings
          <app-page-help articleId="-" videoId="-"></app-page-help>
        </h5>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="onSave()" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="main-content">
        <form [formGroup]="form" class="d-flex flex-column align-items-stretch gap-4">
          <div class="container-box p-3">
            <div class="row">
              <div class="col-sm-12">
                <p class="subtitle">Pricing</p>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The default daily price for the listing. Must be between 10 and 25,000 {{ currency }}."
                  matTooltipPosition="above"
                >
                  <input
                    type="number"
                    formControlName="default_daily_price"
                    matInput
                    placeholder="Base Price:"
                    min="1"
                  />
                  <span matSuffix>{{ currency }}</span>
                </mat-form-field2>
              </div>
              <div class="col-sm-4">
                <app-select-search
                  [inline]="false"
                  placeholder="Listing Currency"
                  [ctrl]="form.get('listing_currency')"
                  [items]="currencyOptions"
                  [multiple]="false"
                >
                </app-select-search>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <p class="subtitle">Discounts</p>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="If set, multiply the price by this factor for trips between 7 and 28 days. Range from 0 to 100. For example, 30 means 30% off. Must be greater than monthly discount"
                  matTooltipPosition="above"
                >
                  <input
                    type="number"
                    formControlName="weekly_price_factor"
                    matInput
                    placeholder="Weekly Discount (eg. 30)"
                    min="0"
                    max="100"
                  />
                  <span matSuffix>%</span>
                </mat-form-field2>
              </div>

              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="If set, multiply the price by this factor for trips of at least 28 days. Range from 0 to 100. For example, 30 means 30% off."
                  matTooltipPosition="above"
                >
                  <input
                    type="number"
                    formControlName="monthly_price_factor"
                    matInput
                    placeholder="Monthly Discount (eg. 30)"
                    min="0"
                    max="100"
                  />
                  <span matSuffix>%</span>
                </mat-form-field2>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <p class="subtitle">Fees</p>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The default price to apply for the weekend days. Must be between 10 and 25,000 {{
                    currency
                  }}."
                  matTooltipPosition="above"
                >
                  <input type="number" formControlName="weekend_price" matInput placeholder="Weekend Price" min="0" />
                  <span matSuffix>{{ currency }}</span>
                </mat-form-field2>
              </div>
              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Number of guests permitted without any additional fees."
                  matTooltipPosition="above"
                >
                  <input
                    type="number"
                    formControlName="guests_included"
                    matInput
                    placeholder="Guests Included"
                    min="1"
                    max="90"
                    step="1"
                  />
                </mat-form-field2>
              </div>
              <div class="col-sm-4">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Amount added to nightly price for each guest over included number."
                  matTooltipPosition="above"
                >
                  <input
                    type="number"
                    formControlName="price_per_extra_person"
                    matInput
                    placeholder="Extra Guest Fee"
                    min="0"
                  />
                  <span matSuffix>{{ currency }}</span>
                </mat-form-field2>
              </div>
            </div>
          </div>

          <mat-accordion class="d-flex flex-column align-items-stretch gap-4">
            <div class="container-box">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p class="subtitle">Standard Fees</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Cleaning Fee',
                        form: standardFeesForm.get(standardFeeTypes.cleaningFee),
                        amountType: false,
                        tooltip:
                          'The amount added to clean the listing. Maximum cleaning fee is (' +
                          currency +
                          '600 + 25% nightly price).'
                      }"
                    ></ng-template>
                  </div>

                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Security Deposit',
                        form: standardFeesForm.get(standardFeeTypes.securityDeposit),
                        amountType: false,
                        tooltip:
                          'A security deposit held by Airbnb and refunded to the guest unless you make claim within 48 hours after guest checkout. Must be between 100 and 5000 USD.'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Linen Fee',
                        form: standardFeesForm.get(standardFeeTypes.linenFee),
                        amountType: false,
                        tooltip:
                          'The linens fee is added to the cleaning fee for guests when they book, but it will be a separate item in your payout report.'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Resort Fee',
                        form: standardFeesForm.get(standardFeeTypes.resortFee),
                        amountType: true,
                        tooltip:
                          'The resort fee is added to the nightly price for guests when they book, but it will be a separate item in your payout report.'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Management Fee',
                        form: standardFeesForm.get(standardFeeTypes.managementFee),
                        amountType: true,
                        tooltip:
                          'The management fee is added to the nightly price for guests when they book, but it will be a separate item in your payout report.'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Community Fee',
                        form: standardFeesForm.get(standardFeeTypes.communityFee),
                        amountType: true,
                        tooltip:
                          'The community fee is added to the nightly price for guests when they book, but it will be a separate item in your payout report.'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="standardFeeItem"
                      [ngTemplateOutletContext]="{
                        currency: currency,
                        label: 'Pet Fee',
                        form: standardFeesForm.get(standardFeeTypes.petFee),
                        amountType: false,
                        hint:
                          !isPetsFeeAllowed &&
                          'You need to enable Pets fee on Airbnb website before you can edit it here,'
                      }"
                    ></ng-template>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="container-box">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p class="subtitle">Pricing Rules</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="pricingRuleItem"
                      [ngTemplateOutletContext]="{
                        title: 'Early-bird discount',
                        label: 'Discount ends X months before arrival',
                        ruleType: pricingRuleTypes.earlyBird,
                        form: pricingRulesForm.get(pricingRuleTypes.earlyBird),
                        tooltip:
                          'Discount applied to bookings made at least X number of months in advance of the check-in date'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="pricingRuleItem"
                      [ngTemplateOutletContext]="{
                        title: 'Last-minute discount',
                        label: 'Discount starts X days before arrival',
                        ruleType: pricingRuleTypes.lastMinutes,
                        hint: 'Days should be less than 28',
                        form: pricingRulesForm.get(pricingRuleTypes.lastMinutes),
                        tooltip: 'Discount applied to bookings made within X number of days before the check-in date'
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-sm-6">
                    <ng-template
                      [ngTemplateOutlet]="pricingRuleItem"
                      [ngTemplateOutletContext]="{
                        title: 'Long-term discount',
                        label: 'Length of stay',
                        ruleType: pricingRuleTypes.longTerm,
                        form: pricingRulesForm.get(pricingRuleTypes.longTerm),
                        tooltip:
                          'Discount applied to bookings that last over a specified length of stay. This price supersedes weekly/monthly discounts'
                      }"
                    ></ng-template>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="container-box">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p class="subtitle">Occupancy Taxes</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="alert alert-warning">
                  <ul>
                    <li>
                      Local tax collection <br />
                      Taxes apply to your nightly price and fees for bookings, and we include them in your earnings
                      report. Guests are shown tax amounts as part of the booking total.
                    </li>
                    <li>
                      Automatic tax collection <br />
                      Airbnb automatically collects certain taxes from guests for each booking and remits them directly
                      to local tax authorities. (<a href="https://www.airbnb.com/help/article/1036" target="_blank">
                        https://www.airbnb.com/help/article/1036</a
                      >).
                    </li>
                    <li>
                      Review the tax amounts collected by Airbnb for your listing before adding taxes to your listing.
                      (<a href="https://www.airbnb.com/help/topic/1236" target="_blank"
                        >https://www.airbnb.com/help/topic/1236</a
                      >)
                    </li>
                    <li *ngIf="occupancyTaxNotice">
                      {{ occupancyTaxNotice }}
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col-sm-12" *ngFor="let item of occupancyTaxesForm.controls; let index = index">
                    <ng-template
                      [ngTemplateOutlet]="occupancyTaxItem"
                      [ngTemplateOutletContext]="{ form: item, index: index }"
                    ></ng-template>
                  </div>
                </div>
                <div class="mb-3">
                  <button type="button" class="btn btn-secondary-info" (click)="addTax()">
                    <i class="far fa-plus"></i> Add
                  </button>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Standard Fees -->
<ng-template
  #standardFeeItem
  let-form="form"
  let-label="label"
  let-tooltip="tooltip"
  let-hint="hint"
  let-amountType="amountType"
  let-currency="currency"
>
  <div class="form-block">
    <div class="row">
      <ng-container [formGroup]="form">
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block subscript-static" [matTooltip]="tooltip" matTooltipPosition="above">
            <mat-label>{{ label }}</mat-label>
            <input type="number" formControlName="amount" matInput placeholder="Fee per reservation" min="0" />
            <span matSuffix>{{ form.get('amount_type').value === 'percent' ? '%' : currency }}</span>
            <mat-error *ngIf="form.get('amount').hasError('min')">
              Value need to be at least <strong>0</strong>
            </mat-error>
            <mat-error *ngIf="form.get('amount').hasError('max')">
              Value need to be at most <strong>100</strong>
            </mat-error>
            <mat-hint *ngIf="hint">
              {{ hint }}
            </mat-hint>
          </mat-form-field2>
        </div>
        <div class="col-sm-6" *ngIf="amountType">
          <mat-form-field2 class="mat-block">
            <mat-select placeholder="Amount type" formControlName="amount_type" required>
              <mat-option value="flat"> Flat </mat-option>
              <mat-option value="percent"> Percent </mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Pricing Rules -->
<ng-template
  #pricingRuleItem
  let-title="title"
  let-form="form"
  let-ruleType="ruleType"
  let-label="label"
  let-tooltip="tooltip"
  let-hint="hint"
>
  <div class="form-block">
    <div class="mb-3">{{ title }}</div>
    <div class="d-flex flex-column align-items-stretch" [ngClass]="{ 'gap-3': !!hint }">
      <div
        class="d-flex flex-row align-items-start gap-3"
        *ngFor="let item of form.controls; let i = index"
        [formGroup]="item"
      >
        <div class="row flex-fill">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block" [matTooltip]="tooltip" matTooltipPosition="above">
              <mat-label>{{ label }}</mat-label>
              <input type="number" formControlName="threshold_one" matInput />
              <span matSuffix>{{ ruleType === pricingRuleTypes.earlyBird ? 'Months' : 'Days' }} </span>
              <mat-error *ngIf="item.get('threshold_one').hasError('min')">
                Value need to be at least <strong>{{ item.get('threshold_one').errors.min.min }}</strong>
              </mat-error>
              <mat-error *ngIf="item.get('threshold_one').hasError('max')">
                Value need to be at most <strong>{{ item.get('threshold_one').errors.max.max }}</strong>
              </mat-error>
              <mat-error *ngIf="item.get('threshold_one').hasError('multiple')">
                Value need to be multiple of <strong>{{ item.get('threshold_one').errors.multiple }}</strong
                >, and 28 is not allowed.
              </mat-error>
              <mat-hint *ngIf="hint">
                {{ hint }}
              </mat-hint>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block" [matTooltip]="tooltip" matTooltipPosition="above">
              <mat-label>Discount</mat-label>
              <input type="number" formControlName="price_change" matInput />
              <span matSuffix>%</span>
              <mat-error *ngIf="item.get('price_change').hasError('min')">
                Value need to be at least <strong>0</strong>
              </mat-error>
              <mat-error *ngIf="item.get('price_change').hasError('max')">
                Value need to be at most <strong>100</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
        <button type="button" class="btn btn-light-danger btn-remove" (click)="removePricingRule(ruleType, i)">
          <i class="cs-icon cs-icon-trash"></i>
        </button>
      </div>
    </div>
    <div class="mb-3" [ngClass]="{ 'mt-2': !hint, 'mt-4': !!hint }">
      <button type="button" class="btn btn-secondary-info" (click)="addPricingRule(ruleType)">
        <i class="far fa-plus"></i> Add
      </button>
    </div>
  </div>
</ng-template>

<!-- Occupancy Tax -->
<ng-template #occupancyTaxItem let-form="form" let-index="index">
  <div class="form-block">
    <div class="row item-row" [formGroup]="form">
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <mat-select placeholder="Tax type" formControlName="tax_type" required>
            <mat-option [value]="item.id" *ngFor="let item of airbnbOccupancyTaxesTypes"> {{ item.name }} </mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <div class="col-12"></div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <mat-select placeholder="Amount type" formControlName="amount_type" required>
            <mat-option [value]="item.id" *ngFor="let item of airbnbOccupancyTaxAmountTypes">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <input type="number" placeholder="Amount" formControlName="amount" matInput required />
          <mat-error *ngIf="form.get('amount').hasError('required')"> Value is <strong>required</strong> </mat-error>
          <mat-error *ngIf="form.get('amount').hasError('min')">
            Value need to be at least <strong>{{ form.get('amount').errors.min.min }}</strong>
          </mat-error>
          <mat-error *ngIf="form.get('amount').hasError('max')">
            Value need to be at most <strong>{{ form.get('amount').errors.max.max }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <mat-select placeholder="Taxable base" formControlName="taxable_base" multiple>
            <mat-option [value]="item.id" *ngFor="let item of airbnbOccupancyTaxBase"> {{ item.name }} </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('taxable_base').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-12"></div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <input type="number" placeholder="Business tax ID" formControlName="business_tax_id" matInput />
          <mat-error *ngIf="form.get('business_tax_id').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-3 mt-3">
        <mat-checkbox style="margin-top: 1.25rem" formControlName="no_business_tax_id_declaration">
          No Business tax ID
        </mat-checkbox>
      </div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            placeholder="Accommodations tax registration ID"
            formControlName="tot_registration_id"
            matInput
            required
          />
          <mat-error *ngIf="form.get('tot_registration_id').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <!-- <div class="col-sm-3 mt-3">
        <mat-checkbox formControlName="no_tot_registration_id_declaration">
          No accommodations tax registration ID
        </mat-checkbox>
      </div> -->
      <div class="col-12"></div>
      <div class="col-sm-3">
        <mat-form-field2 class="mat-block">
          <input
            type="number"
            placeholder="Long term stay exemption"
            formControlName="long_term_stay_exemption"
            matInput
          />
          <span matSuffix>Nights</span>
          <mat-error *ngIf="form.get('long_term_stay_exemption').hasError('min')">
            Value need to be at least <strong>0</strong>
          </mat-error>
          <mat-error *ngIf="form.get('long_term_stay_exemption').hasError('integer')">
            Value need to be <strong>integer</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <button type="button" class="btn btn-light-danger item-delete-icon" (click)="removeTax(index)">
        <i class="cs-icon cs-icon-trash"></i>
      </button>
    </div>
  </div>
</ng-template>
