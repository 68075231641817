import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LosDiscount } from './los-discount.model'
import { httpRawItemToDataItem } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class LosDiscountService {
  constructor(private http: HttpClient) {}

  load(): Observable<LosDiscount[]> {
    const url = `@api/lengthofstay/all`
    return this.http.get(url).pipe(map((res: any[]) => res.map((item) => httpRawItemToDataItem(item))))
  }

  create(payload: LosDiscount): Observable<LosDiscount> {
    const url = `@api/lengthofstay/`

    return this.http.post<object>(url, payload).pipe(map((res) => httpRawItemToDataItem(res)))
  }

  get(id: string): Observable<LosDiscount> {
    const url = `@api/lengthofstay/${id}`
    return this.http.get(url).pipe(map((res) => httpRawItemToDataItem(res)))
  }

  delete(id: string): Observable<any> {
    const url = `@api/lengthofstay/${id}`
    return this.http.delete(url)
  }
}
