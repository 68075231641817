import { compareStr } from '../../functions'
import { Channel } from './channel.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Channel> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<Channel> = createEntityAdapter<Channel>({
  sortComparer: (a: Channel, b: Channel) => {
    return compareStr(a.friendlyName, b.friendlyName)
  },
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
