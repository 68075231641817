import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed, isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import {
  CreateAccountRateAutomation,
  CreateAccountRateAutomationComplete,
  CreateGapNightAutomation,
  CreateGapNightAutomationComplete,
  CreateLastMinuteAutomation,
  CreateLastMinuteAutomationComplete,
  CreateMinStayAutomation,
  CreateMinStayAutomationComplete,
  CreateRatePushAutomation,
  CreateRatePushAutomationComplete,
  DeleteGapNightAutomation,
  DeleteGapNightAutomationComplete,
  DeleteLastMinuteAutomation,
  DeleteLastMinuteAutomationComplete,
  DeleteMinStayAutomation,
  DeleteMinStayAutomationComplete,
  DeleteRatePushAutomation,
  DeleteRatePushAutomationComplete,
  LoadAccountRateAutomations,
  LoadAccountRateAutomationsComplete,
  UpdateGapNightAutomation,
  UpdateGapNightAutomationComplete,
  UpdateLastMinuteAutomation,
  UpdateLastMinuteAutomationComplete,
  UpdateMinStayAutomation,
  UpdateMinStayAutomationComplete,
  UpdateRatePushAutomation,
  UpdateRatePushAutomationComplete,
} from './actions'
import { RateAutomationService } from './service'
import { AccountRateAutomationType } from './model'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class RateAutomationEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadAccountRateAutomations),
    switchMap(() =>
      this.automationService.load().pipe(
        map((items) => {
          if (isSomething(items)) {
            return LoadAccountRateAutomationsComplete({ items })
          } else {
            return CreateAccountRateAutomation()
          }
        }),
        catchError((error: HttpErrorResponse) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreateAccountRateAutomation),
    switchMap(() =>
      this.automationService.create({}).pipe(
        map((item) => CreateAccountRateAutomationComplete({ item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // #region MinStay

  @Effect()
  addMinStay$ = this.actions$.pipe(
    ofType(CreateMinStayAutomation),
    switchMap(({ payload }) =>
      this.automationService.createAutomation(AccountRateAutomationType.MinStay, payload).pipe(
        map((item) => CreateMinStayAutomationComplete({ item })),
        tap(() => this.toaster.success('Min Stay created successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateMinStay$ = this.actions$.pipe(
    ofType(UpdateMinStayAutomation),
    switchMap(({ id, payload }) =>
      this.automationService.updateAutomation(AccountRateAutomationType.MinStay, id, payload).pipe(
        map((item) => UpdateMinStayAutomationComplete({ item })),
        tap(() => this.toaster.success('Min Stay updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteMinStay$ = this.actions$.pipe(
    ofType(DeleteMinStayAutomation),
    switchMap(({ id }) =>
      this.automationService.deleteAutomation(AccountRateAutomationType.MinStay, id).pipe(
        map((item) => DeleteMinStayAutomationComplete({ item })),
        tap(() => this.toaster.success('Min Stay deleted successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  // #endregion MinStay

  // #region GapNight

  @Effect()
  addGapNight$ = this.actions$.pipe(
    ofType(CreateGapNightAutomation),
    switchMap(({ payload }) =>
      this.automationService.createAutomation(AccountRateAutomationType.GapNights, payload).pipe(
        map((item) => CreateGapNightAutomationComplete({ item })),
        tap(() => this.toaster.success('Gap night automation created successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGapNight$ = this.actions$.pipe(
    ofType(UpdateGapNightAutomation),
    switchMap(({ id, payload }) =>
      this.automationService.updateAutomation(AccountRateAutomationType.GapNights, id, payload).pipe(
        map((item) => UpdateGapNightAutomationComplete({ item })),
        tap(() => this.toaster.success('Gap night automation updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGapNight$ = this.actions$.pipe(
    ofType(DeleteGapNightAutomation),
    switchMap(({ id }) =>
      this.automationService.deleteAutomation(AccountRateAutomationType.GapNights, id).pipe(
        map((item) => DeleteGapNightAutomationComplete({ item })),
        tap(() => this.toaster.success('Gap night automation deleted successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  // #endregion GapNight

  // #region LastMinute

  @Effect()
  addLastMinute$ = this.actions$.pipe(
    ofType(CreateLastMinuteAutomation),
    switchMap(({ payload }) =>
      this.automationService.createAutomation(AccountRateAutomationType.LastMinute, payload).pipe(
        map((item) => CreateLastMinuteAutomationComplete({ item })),
        tap(() => this.toaster.success('Last minute automation created successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateLastMinute$ = this.actions$.pipe(
    ofType(UpdateLastMinuteAutomation),
    switchMap(({ id, payload }) =>
      this.automationService.updateAutomation(AccountRateAutomationType.LastMinute, id, payload).pipe(
        map((item) => UpdateLastMinuteAutomationComplete({ item })),
        tap(() => this.toaster.success('Last minute automation updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteLastMinute$ = this.actions$.pipe(
    ofType(DeleteLastMinuteAutomation),
    switchMap(({ id }) =>
      this.automationService.deleteAutomation(AccountRateAutomationType.LastMinute, id).pipe(
        map((item) => DeleteLastMinuteAutomationComplete({ item })),
        tap(() => this.toaster.success('Last minute automation deleted successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  // #endregion LastMinute

  // #region RatePush

  @Effect()
  addRatePush$ = this.actions$.pipe(
    ofType(CreateRatePushAutomation),
    switchMap(({ payload }) =>
      this.automationService.createAutomation(AccountRateAutomationType.RatePush, payload).pipe(
        map((item) => CreateRatePushAutomationComplete({ item })),
        tap(() => this.toaster.success('Rate push automation created successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRatePush$ = this.actions$.pipe(
    ofType(UpdateRatePushAutomation),
    switchMap(({ id, payload }) =>
      this.automationService.updateAutomation(AccountRateAutomationType.RatePush, id, payload).pipe(
        map((item) => UpdateRatePushAutomationComplete({ item })),
        tap(() => this.toaster.success('Rate push automation updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteRatePush$ = this.actions$.pipe(
    ofType(DeleteRatePushAutomation),
    switchMap(({ id }) =>
      this.automationService.deleteAutomation(AccountRateAutomationType.RatePush, id).pipe(
        map((item) => DeleteRatePushAutomationComplete({ item })),
        tap(() => this.toaster.success('Rate push deleted successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  // #endregion RatePush

  constructor(private actions$: Actions, private toaster: Toaster, private automationService: RateAutomationService) {}
}
