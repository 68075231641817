import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import {
  Channel,
  Destroyable,
  Rental,
  RentalBookedDayTypes,
  TOKEET_CHANNEL_ID,
  selectChannelEntities,
  tokeetDashboardChannel,
  untilDestroy,
  RentalRateItem,
  isSomething,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'

@Component({
  selector: 'app-rental-rates-calendar-cell',
  templateUrl: './rental-rates-calendar-cell.component.html',
  styleUrls: ['./rental-rates-calendar-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RentalRatesCalendarCellComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental
  @Input() day: number
  get defaultRate() {
    return this.ratesByCategory?.['default']
  }
  @Input() ratesByCategory: Record<string, RentalRateItem>

  @Input() events: number
  @Input() booked: RentalBookedDayTypes
  @Input() today: boolean
  @Input() startOfMonth: boolean
  @Input() beforeToday: boolean
  @Input() showPopover: boolean

  @Output() activePopover = new EventEmitter<NgbPopover>()
  showContent = false

  channelsById: Record<string, Channel> = {}

  constructor(private store: Store<any>, private cd: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectChannelEntities), untilDestroy(this)).subscribe((channels) => {
      this.channelsById = channels
      this.channelsById[TOKEET_CHANNEL_ID] = tokeetDashboardChannel
      this.cd.markForCheck()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showContent = !this.beforeToday && (!this.booked || this.booked === 'end') && !!this.ratesByCategory
  }

  getCategoryName = (category: string) => {
    if (this.channelsById && this.channelsById[category]) {
      return this.channelsById[category].friendlyName
    }

    category = category === TOKEET_CHANNEL_ID ? tokeetDashboardChannel.friendlyName : category

    return lodash.startCase(category)
  }

  getRatesForCategories = (ratesByCategory: Record<string, RentalRateItem>, channelsById: Record<string, Channel>) => {
    if (!isSomething(ratesByCategory) || !channelsById) return []

    return lodash
      .chain(ratesByCategory)
      .pickBy((r, key) => !!r)
      .map((r, key) => ({
        name: this.getCategoryName(key),
        price: r.price,
        minimumstay: r.minimumstay,
        maximumstay: r.maximumstay,
        currency: r.currency,
      }))
      .uniqBy((item) => lodash.toLower(item.name))
      .sortBy((item) => (item.name === 'Default' ? '0' : lodash.toLower(item.name)))
      .value()
  }

  openPopover(popover: NgbPopover) {
    if (!this.showPopover || !this.showContent) return

    popover.open()
    this.activePopover.emit(popover)
  }
}
