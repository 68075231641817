export const availableTaxeNames = [
  {
    name: 'City tax',
    description: 'Tax imposed by a governement',
  },
  {
    description:
      "Tax for environmental costs. Usually out of provider's control and sent to us as something about environment/ecology",
    name: 'Eco tax',
  },
  {
    description: 'Tax imposed by a governement',
    name: 'Local tax',
  },
  {
    name: 'Short stay tax',
    description: 'Tax imposed by a governement',
  },
  {
    description: 'Tax imposed by a governement',
    name: 'Tourist tax',
  },
  {
    description: 'VAT/MwSt/Sales tax. Any additional tax when buying/selling something',
    name: 'Vat',
  },
]
