<div class="modal-header">
  <h5 class="modal-title"><i class="far fa-image"></i>&nbsp;&nbsp;Insert / edit image</h5>
  <button (click)="onClose()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<div class="modal-body">
  <ng-container *rerender="resetCounter">
    <div class="upload-box-container">
      <app-upload-box
        [options]="uploadOptions"
        [showFileList]="false"
        image="/assets/images/upload-formate.png"
        (complete)="onUploaded($event)"
      >
      </app-upload-box>

      <div class="image-preview bg-default2" *ngIf="!form.get('src').invalid">
        <img [src]="form.get('src').value" />
      </div>
    </div>
  </ng-container>

  <form [formGroup]="form" class="mt-4">
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field2>
          <input type="text" matInput required placeholder="Image Source" formControlName="src" />
          <mat-error *ngIf="form.get('src').touched && form.get('src').hasError('required')">
            Image source is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('src').touched && form.get('src').hasError('url')">
            Image source is <strong>invalid</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field2>
          <input type="text" matInput placeholder="Image Description" formControlName="alt" maxlength="500" />
        </mat-form-field2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2>
          <input type="number" formControlName="width" matInput placeholder="Width" min="0" />
          <mat-error *ngIf="form.get('width').touched && form.get('width').hasError('min')">
            Value need to be at least <strong>0</strong>
          </mat-error>
          <mat-error *ngIf="form.get('width').touched && form.get('width').hasError('integer')">
            Value need to be <strong>integer</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2>
          <input type="number" formControlName="height" matInput placeholder="Height" min="0" />
          <mat-error *ngIf="form.get('height').touched && form.get('height').hasError('min')">
            Value need to be at least <strong>0</strong>
          </mat-error>
          <mat-error *ngIf="form.get('height').touched && form.get('height').hasError('integer')">
            Value need to be <strong>integer</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <button
          (click)="toggleLock(); $event.currentTarget.blur()"
          class="btn btn-lock {{ isLock ? 'btn-secondary-danger' : 'btn-secondary-info' }}"
          matTooltip="Unlock/Lock Proportions"
          matTooltipPosition="below"
        >
          <i *ngIf="isLock" class="far fa-lock"></i>
          <i *ngIf="!isLock" class="far fa-unlock"></i>
        </button>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button *ngIf="isEdit" (click)="onRemove()" type="button" class="btn btn-secondary-danger">
    <i class="far fa-times"></i> Remove
  </button>

  <div class="flex-1"></div>

  <button (click)="onClose()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>

  <button (click)="onSubmit(form)" type="button" class="btn btn-secondary-info create-btn">
    <span class="cs-icon cs-icon-save"></span> Save
  </button>
</div>
