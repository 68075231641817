import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core'
import { AiResponderSettings } from '../../store/ai-settings.model'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import {
  Destroyable,
  Rental,
  selectRentalEntities,
  untilDestroy,
  selectAllRentals,
} from '@tokeet-frontend/tv3-platform'
import { Store, select } from '@ngrx/store'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Guest } from '@tv3/store/guest/guest.model'
import { GuestService } from '@tv3/store/guest/guest.service'
import * as lodash from 'lodash'
import { FormControl, Validators } from '@angular/forms'
import { debounceTime, observeOn } from 'rxjs/operators'
import { SearchService } from '@tv3/store/search/search.service'
import { BehaviorSubject, asyncScheduler } from 'rxjs'
import { InquirySearchResult } from '@tv3/store/search/search-result.model'
import { SearchGuests } from '@tv3/store/guest/guest.actions'
import { selectAllGuests } from '@tv3/store/guest/guest.selectors'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { UpdateAISettings } from '../../store/ai-settings.actions'

@Component({
  selector: 'app-ai-responder-ignore-list',
  templateUrl: './ai-responder-ignore-list.component.html',
  styleUrls: ['./ai-responder-ignore-list.component.scss'],
})
export class AiResponderIgnoreListComponent extends Destroyable implements OnInit, OnChanges {
  @Input() settings: AiResponderSettings

  @ViewChild('bookingsPaginator') set bookingsPaginator(p: MatPaginator) {
    this.bookingsDataSource.paginator = p
  }
  @ViewChild('bookingsSort') set bookingsSort(s: MatSort) {
    this.bookingsDataSource.sort = s
  }
  bookingsColumns = ['name', 'email', 'guestArrive', 'guestDepart', 'actions']
  bookingsDataSource = new MatTableDataSource<Inquiry>()

  @ViewChild('guestsPaginator') set guestsPaginator(p: MatPaginator) {
    this.guestsDataSource.paginator = p
  }
  @ViewChild('guestsSort') set guestsSort(s: MatSort) {
    this.guestsDataSource.sort = s
  }
  guestsColumns = ['name', 'primaryEmail', 'actions']
  guestsDataSource = new MatTableDataSource<Guest>()

  @ViewChild('regexpsPaginator') set regexpsPaginator(p: MatPaginator) {
    this.regexpsDataSource.paginator = p
  }
  @ViewChild('regexpsSort') set regexpsSort(s: MatSort) {
    this.regexpsDataSource.sort = s
  }
  regexpsColumns = ['content', 'actions']
  regexpsDataSource = new MatTableDataSource<string>()

  @ViewChild('rentalsPaginator') set rentalsPaginator(p: MatPaginator) {
    this.rentalsDataSource.paginator = p
  }
  @ViewChild('rentalsSort') set rentalsSort(s: MatSort) {
    this.rentalsDataSource.sort = s
  }
  rentalsColumns = ['name', 'email', 'actions']
  rentalsDataSource = new MatTableDataSource<Rental>()

  bookingsCtrl = new FormControl([], [Validators.required])
  bookingSearchTerm$ = new BehaviorSubject<string>('')
  inquiries$ = new BehaviorSubject<InquirySearchResult[]>([])

  ignoreGuestsRegexpCtrl = new FormControl('', [Validators.required])

  guestsCtrl = new FormControl([], [Validators.required])
  guestSearchTerm$ = new BehaviorSubject<string>('')
  guests$ = this.store.pipe(select(selectAllGuests))

  rentalsCtrl = new FormControl([], [Validators.required])
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  searchBookingsCtrl = new FormControl()
  searchGuestsCtrl = new FormControl()
  searchGuestRegexpsCtrl = new FormControl()
  searchRentalsCtrl = new FormControl()

  constructor(
    private store: Store<any>,
    private inquiryService: InquiryService,
    private guestService: GuestService,
    private searchService: SearchService
  ) {
    super()
  }

  ngOnInit(): void {
    this.searchService
      .getInquiries(this.bookingSearchTerm$)
      .pipe(untilDestroy(this))
      .subscribe((inquiries) => {
        this.inquiries$.next(inquiries)
      })
    this.guestSearchTerm$.pipe(debounceTime(250), untilDestroy(this)).subscribe((term) => {
      this.store.dispatch(SearchGuests({ term }))
    })
    this.regexpsDataSource.filterPredicate = (item: string, filter: string) => {
      return lodash.toLower(item).includes(lodash.toLower(filter))
    }
    this.regexpsDataSource.sortingDataAccessor = (item: string, property: string) => {
      return lodash.toLower(item)
    }

    this.bookingsDataSource.sortingDataAccessor = (item: Inquiry, property: string) => {
      switch (property) {
        case 'name':
          return lodash.toLower(item.guestDetails?.name || '')
        case 'email':
          return lodash.toLower(item.guestDetails?.email || '')
        default:
          return item[property]
      }
    }

    this.searchBookingsCtrl.valueChanges
      .pipe(untilDestroy(this))
      .subscribe((term) => (this.bookingsDataSource.filter = term))
    this.searchGuestRegexpsCtrl.valueChanges
      .pipe(untilDestroy(this))
      .subscribe((term) => (this.regexpsDataSource.filter = term))
    this.searchGuestsCtrl.valueChanges
      .pipe(untilDestroy(this))
      .subscribe((term) => (this.guestsDataSource.filter = term))
    this.searchRentalsCtrl.valueChanges
      .pipe(untilDestroy(this))
      .subscribe((term) => (this.rentalsDataSource.filter = term))
  }

  refresh() {
    this.bookingsCtrl.reset()
    this.guestsCtrl.reset()
    this.rentalsCtrl.reset()
    this.ignoreGuestsRegexpCtrl.reset()

    this.regexpsDataSource.data = this.settings.ignoreGuestsRegexp || []

    this.inquiryService.get(this.settings.ignoreBookings).subscribe((items) => {
      this.bookingsDataSource.data = items
    })
    this.store.pipe(select(selectRentalEntities), untilDestroy(this)).subscribe((rentalsById) => {
      const ignoreRentals = lodash.compact(lodash.map(this.settings.ignoreRentals, (rId) => rentalsById[rId]))
      this.rentalsDataSource.data = ignoreRentals
    })

    this.guestService.fetch(this.settings.ignoreGuests).subscribe((items) => {
      this.guestsDataSource.data = items
    })
  }

  ngOnChanges() {
    this.refresh()
  }

  onSearchBookings(data: { term: string }) {
    this.bookingSearchTerm$.next(data.term)
  }

  onSearchGuests(data: { term: string }) {
    this.guestSearchTerm$.next(data.term)
  }

  onSelectAll(ctrl: FormControl, items: { id: string }[], checked: boolean) {
    if (checked) {
      ctrl.setValue(items.map((t) => t.id))
    } else {
      ctrl.setValue([])
    }
  }

  isAllSelected(ctrl: FormControl, items: { id: string }[]) {
    return ctrl.value?.length == items.length
  }

  onDeleteBooking(item: Inquiry) {
    const ignoreBookings = lodash.filter(this.settings.ignoreBookings, (id) => id != item.id)
    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreBookings },
        message: 'Booking Conversation deleted successfully from ignore list',
      })
    )
  }

  onSaveBookings() {
    const ids = this.bookingsCtrl.value || []
    const ignoreBookings = lodash.uniq([...this.settings.ignoreBookings, ...ids])
    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreBookings },
        message: 'Booking Conversation added successfully to ignore list',
      })
    )
  }

  onDeleteGuest(item: Guest) {
    const ignoreGuests = lodash.filter(this.settings.ignoreGuests, (id) => id != item.id)
    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreGuests },
        message: 'Guest deleted successfully from ignore list',
      })
    )
  }

  onSaveGuests() {
    const ids = this.guestsCtrl.value || []
    const ignoreGuests = lodash.uniq([...this.settings.ignoreGuests, ...ids])
    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreGuests },
        message: 'Guest added successfully to ignore list',
      })
    )
  }

  onDeleteGuestsRegexp(item: string) {
    const ignoreGuestsRegexp = lodash.filter(this.settings.ignoreGuestsRegexp, (id) => id != item)

    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreGuestsRegexp },
        message: 'Regex pattern delete successfully from ignore list',
      })
    )
  }

  onSaveGuestsRegexp() {
    const regexp = this.ignoreGuestsRegexpCtrl.value

    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreGuestsRegexp: lodash.uniq([...this.settings.ignoreGuestsRegexp, regexp]) },
        message: 'Regex pattern added successfully to ignore list',
      })
    )
  }

  onDeleteRental(item: Rental) {
    const ignoreRentals = lodash.filter(this.settings.ignoreRentals, (id) => id != item.id)

    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreRentals },
        message: 'Rental deleted successfully from ignore list',
      })
    )
  }

  onSaveRentals() {
    const ids = this.rentalsCtrl.value || []
    const ignoreRentals = lodash.uniq([...this.settings.ignoreRentals, ...ids])

    this.store.dispatch(
      UpdateAISettings({
        payload: { ignoreRentals },
        message: 'Rental added successfully to ignore list',
      })
    )
  }
}
