import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { LoadTokeetCreditCharges, LoadTokeetCreditChargesComplete } from './actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { CreditChargeService } from './service'

@Injectable()
export class CreditChargeEffects {
  @Effect()
  loadCharges$ = this.actions$.pipe(
    ofType(LoadTokeetCreditCharges),
    exhaustMap(({ product }) =>
      this.creditChargeService.charges(product).pipe(
        map((items) => LoadTokeetCreditChargesComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private creditChargeService: CreditChargeService) {}
}
