import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class TokeetImage extends Serializable<TokeetImage> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'public_id' })
  publicId: string

  @Expose({ name: 'secure_url' })
  secureUrl: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'website_id' })
  websiteId: string

  primary: number
  height: number
  archived: number
  url: string
  size: number
  order: number
  created: number
  type: string
  width: number
  metadata: { category?: number; description?: string }
  account: number
  favicon: number
  logo: number

  static deserialize(data: object): TokeetImage {
    return deserialize(TokeetImage, data)
  }
}
