<div class="modal-header">
  <h2 class="modal-title">
    {{ title }}
  </h2>

  <button *ngIf="relatedVideos.length > 0" (click)="showRelatedVideos()" class="more-videos" type="button">
    <span>More Videos</span>
    <i class="cs-icon cs-icon-arrow-right-long"></i>
  </button>

  <button (click)="close()" class="close" aria-label="Close" type="button">
    <span class="far fa-times"></span>
  </button>
</div>

<div class="loading-video" *ngIf="!ready">
  <i class="fas fa-spinner fa-spin"></i>
</div>

<div class="modal-body">
  <video #video class="video-js vjs-theme-fantasy vjs-fluid vjs-16-9"></video>
</div>
