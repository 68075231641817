import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { CustomChannelResponse } from '@tokeet-frontend/channels'
import { CustomChannelOverlayComponent } from './custom-channel-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CustomChannelOverlayService {
  constructor(private dialog: DialogService) {}

  public open<T>(channel: CustomChannelResponse) {
    const defaultConfig: MatDialogConfig = {
      data: { channel },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(CustomChannelOverlayComponent, defaultConfig)

    return dialogRef
  }
}
