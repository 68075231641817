import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe, DecimalPipe } from '@angular/common'
import * as lodash from 'lodash'

@Pipe({
  name: 'rate',
})
export class RatePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {}

  transform(value: number, currency?: { code: string } | string, useDefault = true): string {
    // rate has no decimal digits
    value = lodash.round(value)

    if (!useDefault && !currency) {
      return this.decimalPipe.transform(value, '1.0-0')
    }

    let code = 'USD'

    if (currency) {
      if (lodash.isString(currency)) {
        code = currency
      } else if (currency.code) {
        code = currency.code
      }
    }

    return this.currencyPipe.transform(value, code, 'symbol', '1.0-0')
  }
}
