import { Component, HostListener, Input } from '@angular/core'
import { isSomething, Rental, selectRentalById } from '@tokeet-frontend/tv3-platform'
import { RentalService } from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject, Subject, merge } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'

@Component({
  selector: 'app-rental-image',
  template: `
    <app-avatar
      [image]="mainImage$ | async"
      class="rental-avatar"
      [text]="rental?.name"
      [size]="size"
      [circle]="circle"
    >
    </app-avatar>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
})
export class RentalImageComponent {
  rentalId$ = new BehaviorSubject<string>(null)
  rental$ = new BehaviorSubject<Rental>(null)

  @Input() set rental(r: Rental) {
    this.rental$.next(r)
  }
  get rental() {
    return this.rental$.value
  }
  @Input() set rentalId(id: string) {
    this.rentalId$.next(id)
  }
  @Input() size = 35
  @Input() circle = false
  @Input() clickable = false

  mainImage$ = merge(
    this.rentalId$.pipe(switchMap((id) => this.store.pipe(select(selectRentalById(id))))),
    this.rental$
  ).pipe(
    filter(isSomething),

    distinctUntilChanged((a, b) => a?.id === b?.id),
    tap((r) => this.rental$.next(r)),
    map((rental) => this.rentalService.getMainImage(rental))
  )

  constructor(private rentalService: RentalService, private store: Store<any>) {}

  @HostListener('click')
  onClick() {
    if (!this.clickable) return
    this.store.dispatch(OpenRentalOverlay({ rentalId: this.rental.id }))
  }
}
