<div class="container-box shadow-none" style="background: #fbfbff">
  <div class="p-3">
    <div class="card">
      <div class="filters-box">
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected cities."
          matTooltipPosition="above"
          id="rentals-filters-cities"
          [items]="cities$ | async"
          [ctrl]="citiesCtrl"
          placeholder="Rentals by Cities"
        ></app-select-search>
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected tags."
          matTooltipPosition="above"
          id="rentals-filters-tags"
          [items]="tags$ | async"
          [ctrl]="tagsCtrl"
          placeholder="Rentals by Tags"
        ></app-select-search>
        <mat-form-field2 [showLabel]="false" class="w-auto">
          <mat-select [formControl]="statusCtrl" placeholder="Connection Status">
            <mat-option value="all">All</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="inactive">Inactive</mat-option>
          </mat-select>
        </mat-form-field2>

        <app-cancel-filters
          [isFiltering]="isFiltering"
          (clear)="onCancelFilters()"
          [searchCom]="search"
        ></app-cancel-filters>
        <button
          class="btn btn-secondary-primary"
          (click)="onRefresh()"
          matTooltip="Refresh List"
          matTooltipPosition="above"
        >
          <i class="far fa-sync"></i>
        </button>

        <div class="flex-fill"></div>
        <app-table-search (search)="searchCtrl.setValue($event)" #search></app-table-search>
      </div>
      <div class="table-holder table-responsive">
        <table class="table table-default table-striped">
          <mat-table #table matSort [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef> </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div *ngIf="validations[row.providerApartmentId]?.length > 0" class="expandable-control">
                  <i
                    *ngIf="row.providerApartmentId !== expandedElement?.providerApartmentId"
                    (click)="setExpanded(row)"
                    class="far fa-chevron-right"
                  ></i>
                  <i
                    *ngIf="row.providerApartmentId === expandedElement?.providerApartmentId"
                    (click)="setExpanded(null)"
                    class="far fa-chevron-down"
                  ></i>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rental_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="text-truncate mr-2" [matTooltip]="element.rental_name" matTooltipPosition="above">
                  {{ element.rental_name }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="providerId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Provider ID</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.providerId }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="holiduApartmentId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Holidu Apartment ID</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.holiduApartmentId }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="statusView">
              <mat-header-cell *matHeaderCellDef mat-sort-header> status</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.statusView }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> action</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <app-form-switch
                  *ngIf="!element.detailRow"
                  [matTooltip]="element.active ? 'Deactivate listing on Holidu' : 'Activate listing on Holidu'"
                  matTooltipPosition="above"
                  [disabled]="element.detailRow"
                  (click)="$event.stopPropagation()"
                  [id]="element.id"
                  [value]="element.active"
                  [inline]="true"
                  (valueChange)="onToggleStatus(element)"
                ></app-form-switch>

                <button
                  *ngIf="element.detailRow"
                  (click)="
                    setExpanded(expandedElement?.providerApartmentId === element.providerApartmentId ? null : element)
                  "
                  class="btn btn-sm btn-secondary-info"
                  type="button"
                >
                  Fix
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let detail" class="p-0 bg-white">
                <div class="validation-list">
                  <div *ngFor="let error of validations[detail.providerApartmentId]" class="invalid-item">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    <ng-container *ngFor="let e of error.errors">{{ e }}</ng-container>
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRental(detail.rental, error)"
                      type="button"
                      class="btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
                expanded: expandedElement?.providerApartmentId == row.providerApartmentId,
                'row-danger': row.detailRow
              }"
              class="element-row"
            >
            </mat-row>

            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
              [ngClass]="{ 'empty-cell': validations[row?.providerApartmentId]?.length === 0 }"
              [@detailExpand]="
                row?.providerApartmentId == expandedElement?.providerApartmentId ? 'expanded' : 'collapsed'
              "
              style="overflow: hidden"
            >
            </mat-row>
          </mat-table>

          <ng-container *ngIf="isEmptyTable$ | async">
            <div class="empty-box">You haven't created any rentals on your account yet</div>
          </ng-container>

          <mat-paginator2
            #paginator
            [class.hidden]="isEmptyTable$ | async"
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator2>
        </table>
      </div>
    </div>
  </div>
  <div class="border-top p-4 mt-4 d-flex align-items-center gap-3">
    <button class="btn btn-outline-light" (click)="prev.emit()">
      <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
      Previous
    </button>
    <div class="flex-fill"></div>
    <button (click)="onBookingFormula()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-calendar-cog"></span> Set Booking Formula
    </button>

    <button (click)="onComplete()" type="button" class="btn btn-secondary-success">
      Complete <i class="fas fa-check"></i>
    </button>
  </div>
</div>
