<div class="modal-header">
  <h5 class="modal-title"><i class="fas fa-book"></i> Data Dictionary</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <mat-tab-group [color]="'accent'">
    <mat-tab label="Guests">
      <div class="row no-margin">
        <div *ngFor="let guest of dictionary.guest" class="col-sm-4 text-left">
          <button
            [matTooltip]="guest.tooltip"
            matTooltipPosition="above"
            (click)="addTag(guest.value)"
            type="button"
            class="data-item"
          >
            {{ guest.value }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Inquiries">
      <div class="row no-margin">
        <div *ngFor="let inquiry of dictionary.inquiry" class="col-sm-4 text-left">
          <button
            [matTooltip]="inquiry.tooltip"
            matTooltipPosition="above"
            (click)="addTag(inquiry.value)"
            type="button"
            class="data-item"
          >
            {{ inquiry.value }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Rentals">
      <div class="row no-margin">
        <div *ngFor="let rental of dictionary.rental" class="col-sm-4 text-left">
          <button
            [matTooltip]="rental.tooltip"
            matTooltipPosition="above"
            (click)="addTag(rental.value)"
            type="button"
            class="data-item"
          >
            {{ rental.value }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Devices">
      <div class="row no-margin">
        <div *ngFor="let item of dictionary.device" class="col-sm-4 text-left">
          <button
            [matTooltip]="item.tooltip"
            matTooltipPosition="above"
            (click)="addTag(item.value)"
            type="button"
            class="data-item"
          >
            {{ item.value }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="hasBranding" label="Branding">
      <div class="row no-margin">
        <div *ngFor="let code of invoiceTags" class="col-sm-4 text-left">
          <button (click)="addTag(code.key)" type="button" class="data-item">{{ code.key }}</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Custom">
      <div class="row no-margin">
        <div *ngFor="let code of custom$ | async" class="col-sm-4 text-left">
          <button (click)="addTag(code)" type="button" class="data-item">{{ code }}</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Discounts">
      <div class="row no-margin">
        <div *ngFor="let item of dictionary.discount" class="col-sm-4 text-left">
          <button
            [matTooltip]="item.tooltip"
            matTooltipPosition="above"
            (click)="addTag(item.value)"
            type="button"
            class="data-item"
          >
            {{ item.value }}
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
