import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { CreateIncidentPayload, IncidentView, UpdateIncidentPayload } from './model'
import { SearchIncidentParams } from './model'
import { Pagination } from '@tokeet-frontend/tv3-platform'

export const LoadIncidents = createAction(
  '[Incident] Load Incidents',
  props<{ params?: SearchIncidentParams; pagination?: Pagination }>()
)
export const LoadIncidentsComplete = createAction(
  '[Incident] Load Incidents Complete',
  props<{ incidents: IncidentView[] }>()
)

export const LoadIncidentsByIds = createAction('[Incident] Load Incidents By Ids', props<{ ids: string[] }>())
export const LoadIncidentsByIdsComplete = createAction(
  '[Incident] Load Incidents By Ids Complete',
  props<{ incidents: IncidentView[] }>()
)

export const AddIncident = createAction('[Incident] Add Incident', props<{ payload: CreateIncidentPayload }>())
export const AddIncidentComplete = createAction('[Incident] Add Incident Complete', props<{ incident: IncidentView }>())

export const UpdateIncident = createAction(
  '[Incident] Update Incident',
  props<{ payload: UpdateIncidentPayload; incidentId: string }>()
)
export const UpdateIncidentComplete = createAction(
  '[Incident] Update Incident Complete',
  props<{ update: Update<IncidentView> }>()
)

export const DeleteIncident = createAction('[Incident] Delete Incident', props<{ id: string }>())
export const DeleteIncidentComplete = createAction('[Incident] Delete Incident Complete', props<{ id: string }>())

export const DeleteIncidents = createAction('[Incident] Delete Incidents', props<{ ids: string[] }>())
export const DeleteIncidentsComplete = createAction('[Incident] Delete Incidents Complete', props<{ ids: string[] }>())
