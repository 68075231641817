import { Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { ManageEntityTagsComponent, ManageEntityTagsOnSubmit } from '../manage-entity-tags/manage-entity-tags.component'

export interface ManageEntityTagsDialogParams {
  title?: string
  type: DataEntityType
  id: string
  tags?: string[]
  description?: string
  showSuggesttedTags?: boolean
  onSubmit?: ManageEntityTagsOnSubmit
}

@Component({
  selector: 'app-manage-entity-tags-dialog',
  templateUrl: './manage-entity-tags-dialog.component.html',
  styleUrls: ['./manage-entity-tags-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ManageEntityTagsDialogComponent {
  @ViewChild(ManageEntityTagsComponent) com: ManageEntityTagsComponent
  constructor(
    public dialogRef: MatDialogRef<ManageEntityTagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManageEntityTagsDialogParams
  ) {}

  close() {
    this.dialogRef.close()
  }

  onSave() {
    this.com.save()
    this.close()
  }
}
