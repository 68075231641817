import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AddBillingNotificationsLocally, LoadNotifications, LoadNotificationsComplete } from './notification.actions'
import { NotificationService } from './notification.service'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { Notification, NotificationLinkTypes } from '@tv3/store/notification/notification.model'
import { Billing, LoadChargesComplete } from '@tokeet-frontend/billing'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class NotificationEffects {
  @Effect()
  loadNotifications$ = this.actions$.pipe(
    ofType(LoadNotifications),
    switchMap(() =>
      this.notifications.all().pipe(
        map((notifications) => LoadNotificationsComplete({ notifications })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  chargesToNotification$ = this.actions$.pipe(
    ofType(LoadChargesComplete),
    map(({ billings }) => {
      const notifications = R.map((billing: Billing) => {
        return Notification.deserialize({
          pkey: billing.id,
          link_pkey: billing.id,
          link_type: NotificationLinkTypes.Subscription,
          action: billing.status,
          date: billing.created,
          subject: billing.status === 'succeeded' ? billing.description : billing.failure_message,
          title: billing.status === 'succeeded' ? 'Subscription renewed' : 'Subscription renewal failed',
          user: 'AdvanceCM',
        })
      }, billings || [])

      return AddBillingNotificationsLocally({ notifications })
    })
  )

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private notifications: NotificationService
  ) {}
}
