import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, zip } from 'rxjs'
import {
  defaultGuestPortalMessageTemplate,
  defaultInvoiceMessageTemplate,
  defaultQuoteMessageTemplate,
  defaultStandardMessageTemplate,
  defaultTemplateBundle,
  SymplTemplates,
  Template,
  TemplateAddRequest,
  TemplateResponse,
  TemplateType,
  TemplateUpdateRequest,
  TokeetTemplates,
} from './template.model'
import { deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import { map, tap } from 'rxjs/operators'
import * as R from 'ramda'

@Injectable()
export class CustomBrandingService {
  tags = [
    { tag: `<TMPL_VAR NAME='name'/>`, dictionary: `*|BRANDING:name|*` },
    { tag: `<TMPL_VAR NAME='message_html'/>`, dictionary: `*|BRANDING:message_html|*` },
    { tag: `<TMPL_VAR NAME='reference_id'/>`, dictionary: `*|BRANDING:reference_id|*` },
    { tag: `<TMPL_VAR NAME='rental_name'/>`, dictionary: `*|BRANDING:rental_name|*` },
    { tag: `<TMPL_VAR NAME='guest_name'/>`, dictionary: `*|BRANDING:guest_name|*` },
    { tag: `<TMPL_VAR NAME='invoice_link'/>`, dictionary: `*|BRANDING:invoice_link|*` },
    { tag: `<TMPL_VAR NAME='sum'/>`, dictionary: `*|BRANDING:sum|*` },
    { tag: `<TMPL_VAR NAME='tax_fee'/>`, dictionary: `*|BRANDING:tax_fee|*` },
    { tag: `<TMPL_VAR NAME='discounts'/>`, dictionary: `*|BRANDING:discounts|*` },
    { tag: `<TMPL_VAR NAME='charge'/>`, dictionary: `*|BRANDING:charge|*` },
    { tag: `<TMPL_VAR NAME='guest_arrive'/>`, dictionary: `*|BRANDING:guest_arrive|*` },
    { tag: `<TMPL_VAR NAME='guest_depart'/>`, dictionary: `*|BRANDING:guest_depart|*` },
    { tag: `<TMPL_VAR NAME='gp_link'/>`, dictionary: `*|BRANDING:gp_link|*` },
  ]

  constructor(private http: HttpClient, private toaster: Toaster) {}

  add(request: TemplateAddRequest): Observable<Template> {
    const url = '@api/template/'

    request = this.replaceDictionaryWithTemplateVariables(request)

    return this.http.post(url, request).pipe(
      map((response) => Template.deserialize(response)),
      tap(() => this.toaster.success('Template created successfully.')),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  update(id: string, request: TemplateUpdateRequest): Observable<Template> {
    const url = `@api/template/update/${id}`

    request = this.replaceDictionaryWithTemplateVariables(request)

    return this.http.put(url, request).pipe(
      map((response) => Template.deserialize(response)),
      tap(() => this.toaster.success('Template updated successfully.')),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  getCustomBranding(): Observable<Template[]> {
    const url = '@api/template/all/'

    return this.http.get<TemplateResponse[]>(url).pipe(
      deserializeArray<Template>(Template),
      map((templates) => R.filter((t: Template) => t.type === TemplateType.CustomBranding, templates)),
      map((templates) => R.map((t: Template) => this.replaceTemplateVariablesWithDictionary(t), templates))
    )
  }

  getStandardTemplates(product: 'tokeet' | 'sympl' = 'tokeet'): Observable<Template[]> {
    return zip(
      this.getStandardMessageTemplate(product),
      this.getInvoiceMessageTemplate(product),
      this.getQuoteMessageTemplate(product),
      this.getGuestPortalMessageTemplate(product)
    )
  }

  getStandardMessageTemplate(product: 'tokeet' | 'sympl' = 'tokeet'): Observable<Template> {
    let template = defaultTemplateBundle[product]['standard']
    const url = `@api/template/${template.pkey}`
    return this.http.get(url).pipe(
      map((response) =>
        Template.deserialize({
          ...response,
          type: TemplateType.Email,
          name: template.name,
        })
      ),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  getInvoiceMessageTemplate(product: 'tokeet' | 'sympl' = 'tokeet'): Observable<Template> {
    let template = defaultTemplateBundle[product]['invoice']
    const url = `@api/template/${template.pkey}`
    return this.http.get(url).pipe(
      map((response) =>
        Template.deserialize({
          ...response,
          type: TemplateType.Email,
          name: template.name,
        })
      ),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  getQuoteMessageTemplate(product: 'tokeet' | 'sympl' = 'tokeet'): Observable<Template> {
    let template = defaultTemplateBundle[product]['quote']
    const url = `@api/template/${template.pkey}`
    return this.http.get(url).pipe(
      map((response) =>
        Template.deserialize({
          ...response,
          type: TemplateType.Email,
          name: template.name,
        })
      ),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  getGuestPortalMessageTemplate(product: 'tokeet' | 'sympl' = 'tokeet'): Observable<Template> {
    let template = defaultTemplateBundle[product]['portal']
    const url = `@api/template/${template.pkey}`
    return this.http.get(url).pipe(
      map((response) =>
        Template.deserialize({
          ...response,
          type: TemplateType.Email,
          name: template.name,
        })
      ),
      map((template) => this.replaceTemplateVariablesWithDictionary(template))
    )
  }

  replaceTemplateVariablesWithDictionary(template: Template): Template {
    let body = R.clone(template.body)

    R.forEach((t: { tag: string; dictionary: string }) => {
      body = body.split(t.tag).join(t.dictionary)
    }, this.tags)

    let newTemplate = new Template(template)
    newTemplate.body = body
    return newTemplate
  }

  replaceDictionaryWithTemplateVariables(template: Template | TemplateAddRequest) {
    let body = R.clone(template.body)

    R.forEach((t: { tag: string; dictionary: string }) => {
      try {
        body = body.split(t.dictionary).join(t.tag)
      } catch (e) {
        console.log(e)
      }
    }, this.tags)

    return { ...template, body }
  }
}
