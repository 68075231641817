import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { Message, MessageType } from '@tv3/store/message/message.model'
import { MessageForm } from '@tv3/interfaces/forms/message.form'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { MessagingChannelKeys } from '@tokeet-frontend/message'

export const LoadLatestMessages = createAction('[Message] Load Latest Start', props<{ messageType?: MessageType }>())
export const LoadLatestMessagesComplete = createAction(
  '[Message] Load Latest Complete',
  props<{ messages: Message[] }>()
)

export const AddMessage = createAction(
  '[Message] Add Message',
  props<{ inquiry: Inquiry; form: MessageForm; plainMessage?: boolean; channel?: 'email' | MessagingChannelKeys }>()
)
export const AddMessageComplete = createAction('[Message] Add Message Complete', props<{ update: Update<Inquiry> }>())

export const GetBookingMessages = createAction('[Message] Get Booking Messages', props<{ bookingId: string }>())
export const GetBookingMessagesComplete = createAction(
  '[Message] Get booking Messages Complete',
  props<{ update: Update<Inquiry> }>()
)

export const DeleteMessage = createAction(
  '[Message] Delete Message',
  props<{ message: Message; inquiryId: string; silent?: boolean }>()
)
export const DeleteMessageComplete = createAction(
  '[Message] Delete Message Complete',
  props<{ message: Message; inquiryId: string }>()
)

export const RemoveMessageFromFeed = createAction('[Message] Remove Message from Feed', props<{ message: Message }>())
export const RemoveMessageFromFeedComplete = createAction(
  '[Message] Remove Message from Feed Complete',
  props<{ message: Message }>()
)

export const DeleteMessages = createAction(
  '[Message] Delete Messages',
  props<{ items: { message: Message; inquiryId: string }[]; silent?: boolean }>()
)
export const DeleteMessagesComplete = createAction(
  '[Message] Delete Messages Complete',
  props<{ items: { message: Message; inquiryId: string }[] }>()
)

export const RemoveMessagesFromFeed = createAction(
  '[Message] Remove Messages from Feed',
  props<{ messages: Message[] }>()
)
export const RemoveMessagesFromFeedComplete = createAction(
  '[Message] Remove Messages from Feed Complete',
  props<{ messages: Message[] }>()
)

export const MoveMessage = createAction(
  '[Message] Move Message',
  props<{ fromInquiryId: string; toInquiryId: string; message: Message }>()
)
export const MoveMessageComplete = createAction('[Message] Move Message Complete', props<{ update: Update<Inquiry> }>())

export const MarkAsRead = createAction('[Message] Mark As Read', props<{ inquiry: Inquiry; messageIds: string[] }>())
export const MarkAsReadComplete = createAction('[Message] Mark As Read Complete', props<{ update: Update<Inquiry> }>())

export const MarkOneMessageAsRead = createAction('[Message] Mark One Message As Read', props<{ messageId: string }>())
export const MarkOneMessageAsReadComplete = createAction(
  '[Message] Mark One Message As Read Complete',
  props<{ update: Update<Message> }>()
)
