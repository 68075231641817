import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import {
  Account,
  countries,
  Destroyable,
  getCountryByCode,
  selectCurrentAccount,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'

export function createForm(fb: FormBuilder) {
  const accountAddressForm = fb.group({
    address: [undefined, [Validators.required]],
    city: [undefined, [Validators.required]],
    country: [],
    country_code: [undefined, [Validators.required]],
    postal_code: [undefined, [Validators.required]],
    state: [undefined, [Validators.required]],
  })

  const accountForm = fb.group({
    name: [
      undefined,
      [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-zA-z\s]+$/)],
    ],
    phone: [undefined, [Validators.required]],
    email: [undefined, [RxwebValidators.email(), Validators.required]],
    address: accountAddressForm,
  })

  return accountForm
}

@Component({
  selector: 'app-bdc-content-step-account-form',
  templateUrl: './bdc-content-step-account-form.component.html',
  styleUrls: ['./bdc-content-step-account-form.component.scss'],
})
export class BdcContentStepAccountFormComponent extends Destroyable implements OnInit {
  @Input() form: FormGroup
  countryList = countries

  constructor(private fb: FormBuilder, private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.form.get('email').disable()
    const addressForm = this.form.get('address')
    addressForm
      .get('country_code')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((code) => {
        const country = getCountryByCode(code)
        addressForm.patchValue({ country }, { emitEvent: false })
      })

    this.store
      .pipe(
        select(selectCurrentAccount),
        tap((account: Account) => this.form.patchValue(account.serialize())),
        untilDestroy(this)
      )
      .subscribe()
  }
}
