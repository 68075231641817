import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CustomChannelDialogService } from '@tv3/containers/channels/shared/custom-channel-dialog/custom-channel-dialog.service'

@Component({
  selector: 'app-channels-overlay',
  templateUrl: './channels-overlay.component.html',
  styleUrls: ['./channels-overlay.component.scss'],
})
export class ChannelsOverlayComponent implements OnInit {
  get channelType() {
    return this.data.channelType || 'api'
  }
  set channelType(value) {
    this.data.channelType = value
  }

  constructor(
    public dialogRef: MatDialogRef<ChannelsOverlayComponent>,
    private customChannelDialog: CustomChannelDialogService,
    @Inject(MAT_DIALOG_DATA) private data: { channelType?: string }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  onAddCustom() {
    this.customChannelDialog.open()
  }
}
