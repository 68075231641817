import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { forkJoin, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  AddCustomCode,
  AddCustomCodeComplete,
  DeleteCustomCode,
  DeleteCustomCodeComplete,
  GetAllCustomCodes,
  GetAllCustomCodesComplete,
  UpdateCustomCode,
  UpdateCustomCodeComplete,
} from '@tv3/store/custom-codes/custom-code.actions'
import { CustomCodeService } from '@tv3/store/custom-codes/custom-code.service'
import { CustomCode } from '@tv3/store/custom-codes/custom-code.model'
import * as R from 'ramda'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CustomCodeEffects {
  @Effect()
  allCustomCodes$ = this.actions$.pipe(
    ofType(GetAllCustomCodes),
    switchMap(() =>
      this.customCodes.getAll().pipe(
        map((customCodes) => GetAllCustomCodesComplete({ codes: customCodes })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteCustomCodes$ = this.actions$.pipe(
    ofType(DeleteCustomCode),
    map(({ ids }) => {
      ids = R.clone(typeof ids === 'string' ? [ids] : ids)
      const observList = ids.map((id) => {
        const noMessage = ids.indexOf(id) < ids.length - 1
        const message = `Custom Code${ids.length > 1 ? 's' : ''} deleted successfully!`
        return this.customCodes.delete(id, noMessage, message).pipe(catchError((error) => of(ActionFailed({ error }))))
      })
      return observList
    }),
    switchMap((list) =>
      forkJoin(list).pipe(
        switchMap((codes) => {
          return codes.map((code: any) =>
            code instanceof CustomCode ? DeleteCustomCodeComplete({ id: (<CustomCode>code).id }) : code
          )
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addCustomCodes$ = this.actions$.pipe(
    ofType(AddCustomCode),
    switchMap(({ request }) =>
      this.customCodes.create(request).pipe(
        map((code) => AddCustomCodeComplete({ code })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateCustomCodes$ = this.actions$.pipe(
    ofType(UpdateCustomCode),
    switchMap(({ request }) =>
      this.customCodes.edit(request.pkey, request).pipe(
        map((code) => UpdateCustomCodeComplete({ update: { id: code.id, changes: code } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private customCodes: CustomCodeService) {}
}
