import { Component, EventEmitter, Input, Output } from '@angular/core'
import * as lodash from 'lodash'
import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-form-tags',
  templateUrl: './form-tags.component.html',
  styleUrls: ['../form-field-common.scss', './form-tags.component.scss'],
  host: { class: 'form-field-tags' },
})
export class FormTagsComponent extends FormFieldComponent {
  @Input() items: any[]
  @Input() bindLabel: string
  @Input() bindValue: string
  @Input() searchable = true
  @Input() searchPlaceholder = 'Search...'
  @Input() clearable = false
  @Input() addTag = true
  @Input() addTagText = 'Add tag'
  @Input() notFoundText = 'No tag found'

  @Output() search = new EventEmitter<string>()

  addTagFn(term: string) {
    return term
  }

  onSelectDropdownOpen() {
    this.focused = true
  }

  onSelectDropdownClose() {
    this.focused = false
  }

  getItemLabel(item): string {
    return this.bindLabel ? lodash.get(item, this.bindLabel) : lodash.toString(item)
  }
}
