export function formatFileSize(bytes: number = 0, precision: number = 2): string {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

  let bytesFloat = parseFloat(String(bytes))
  if (isNaN(bytesFloat) || !isFinite(bytesFloat)) {
    return '?'
  }

  let unit = 0

  while (bytesFloat >= 1024) {
    bytesFloat /= 1024
    unit++
  }

  unit = unit > units.length - 1 ? units.length - 1 : unit

  return bytesFloat.toFixed(unit === 0 ? 0 : precision) + ' ' + units[unit]
}
