import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY } from 'rxjs'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError } from 'rxjs/operators'
import { HvmiListingModel, HvmiListingViewModel, HvmiStatus, HvmiUpdateListingModel } from './model'
import { ListingsValidationFieldResult } from '../rental-validation-field'

@Injectable({
  providedIn: 'root',
})
export class HVMIChannelService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  //region Hvmi
  getHvmiListingsStatuses() {
    const url = `@api/hvmi/listings/status`

    return this.http.get<any>(url)
  }

  connectHvmi(hmcId: number) {
    const url = `@api/hvmi/connection/connect`

    return this.http.post<{ status: HvmiStatus; listings: HvmiListingModel[] }>(url, { hmcId })
  }
  getHvmiStatus() {
    const url = `@api/hvmi/connection/status`

    return this.http.get<HvmiStatus>(url)
  }

  activateHvmiListing(propertyId: string) {
    const url = `@api/hvmi/listings/activate`

    return this.http.post<HvmiListingModel>(url, { propertyId })
  }

  deactivateHvmiListing(propertyId: string) {
    const url = `@api/hvmi/listings/deactivate`

    return this.http.post<HvmiListingModel>(url, { propertyId })
  }

  deleteHvmiListing(listingId: number) {
    const url = `@api/hvmi/listings/${listingId}`

    return this.http.delete<{ listingId: number }>(url)
  }
  getHvmiListings() {
    const url = `@api/hvmi/listings`

    return this.http.get<HvmiListingModel[]>(url)
  }

  createHvmiListing(listing: HvmiListingViewModel) {
    const url = `@api/hvmi/listings`

    return this.http.post<HvmiListingModel>(url, { listing }).pipe(
      catchError((error) => {
        this.toaster.error(error?.error?.error)
        return EMPTY
      })
    )
  }
  updateHvmiListing(listing: HvmiUpdateListingModel) {
    const url = `@api/hvmi/listings`

    return this.http.put<HvmiListingModel>(url, { listing })
  }

  disconnectHvmi(hmcId: number) {
    const url = `@api/hvmi/connection/disconnect`

    return this.http.post<{ success: boolean }>(url, { hmcId })
  }
  getHvmiRentalValidations() {
    const url = `@api/hvmi/listings/validate/rentals`

    return this.http.post<ListingsValidationFieldResult>(url, {})
  }

  getConnections() {
    return this.http.get<object[]>(`@api/hvmi/connections/tokeet`)
  }
  //endregion
}
