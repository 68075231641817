<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-4">
        <h5 class="modal-title">
          Booking Formulas
          <app-page-help articleId="-" videoId="-"></app-page-help>
        </h5>
      </div>
      <div class="col-sm-8 btn-holder text-right">
        <button
          type="button"
          class="btn btn-secondary-danger ml-3"
          (click)="table?.onDeleteSelected()"
          [disabled]="table?.selection.isEmpty()"
        >
          <i class="cs-icon cs-icon-trash"></i>&nbsp; Delete
        </button>

        <button type="button" class="btn btn-secondary-info ml-3" (click)="table?.onAdd()">
          <i class="far fa-plus"></i>&nbsp; Add
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <app-booking-formulas-table [channel]="data.channel" #table></app-booking-formulas-table>
    </div>
  </div>
</div>
