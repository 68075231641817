import { createAction, props } from '@ngrx/store'
import { ChannelAdjustment, CreateChannelAdjustmentPayload, UpdateChannelAdjustmentPayload } from './model'

export const loadChannelAdjustments = createAction('[ChannelAdjustment] Load ChannelAdjustments')
export const loadChannelAdjustmentsComplete = createAction(
  '[ChannelAdjustment] Load ChannelAdjustments Complete',
  props<{ items: ChannelAdjustment[] }>()
)

export const addChannelAdjustment = createAction(
  '[ChannelAdjustment] Add ChannelAdjustment',
  props<{ payload: CreateChannelAdjustmentPayload }>()
)
export const addChannelAdjustmentComplete = createAction(
  '[ChannelAdjustment] Add ChannelAdjustment complete',
  props<{ item: ChannelAdjustment }>()
)

export const updateChannelAdjustment = createAction(
  '[ChannelAdjustment] Update ChannelAdjustment',
  props<{ id: string; payload: UpdateChannelAdjustmentPayload }>()
)

export const updateChannelAdjustmentComplete = createAction(
  '[ChannelAdjustment] Update ChannelAdjustment complete',
  props<{ item: ChannelAdjustment }>()
)

export const deleteChannelAdjustment = createAction(
  '[ChannelAdjustment] Delete ChannelAdjustment',
  props<{ id: string }>()
)
export const deleteChannelAdjustmentComplete = createAction(
  '[ChannelAdjustment] Delete ChannelAdjustment Complete',
  props<{ id: string }>()
)
