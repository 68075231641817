import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { map, switchMap, tap } from 'rxjs/operators'
import {
  SubscriptionAddonFeatureAccessLimited,
  SubscriptionAddonRentalCountLimited,
  SubscriptionExpired,
  SubscriptionFeatureAccessLimited,
  SubscriptionInvalidLimited,
  SubscriptionMissing,
  SubscriptionRentalCountLimited,
  SubscriptionTrialLimited,
  SubscriptionUserCountLimited,
} from './plan.actions'
import { AlertDialogService, ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { Router } from '@angular/router'
import { tokeetAddonProductNames } from './plan.model'
import { CloseAllDialogs } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { AuthService } from '@tv3/services/auth.service'

@Injectable()
export class SubscriptionEffects {
  @Effect()
  expired$ = this.actions$.pipe(
    ofType(SubscriptionExpired),
    switchMap(() =>
      this.alertDialog.openResult({
        title: 'Subscription Expired',
        body: `Your subscription has expired. Please select a new subscription or enter a new credit card to continue your
              AdvanceCM service.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )
  @Effect()
  missing$ = this.actions$.pipe(
    ofType(SubscriptionMissing),
    switchMap(() =>
      this.alertDialog.openResult({
        title: 'Subscription Missing',
        body: `Your don't subscribe AdvanceCM. Please select a new subscription or enter a new credit card to continue your
              AdvanceCM service.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect()
  trialLimited$ = this.actions$.pipe(
    ofType(SubscriptionTrialLimited),
    switchMap(() =>
      this.alertDialog.openResult({
        title: 'Subscribe to a paid plan',
        body: `You must subscribe to a paid plan before you can access these features.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect()
  invalid$ = this.actions$.pipe(
    ofType(SubscriptionInvalidLimited),
    switchMap(() =>
      this.alertDialog.openResult({
        title: 'Subscribe to a plan',
        body: `You must subscribe to a paid plan before you can access these features.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect()
  accessLimited$ = this.actions$.pipe(
    ofType(SubscriptionFeatureAccessLimited),
    switchMap(({ currentPlanType, requiredPlanType }) =>
      this.alertDialog.openResult({
        title: 'Paid subscription required',
        body: `You must subscribe a paid plan before you can access these features.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect()
  addonAccessLimited$ = this.actions$.pipe(
    ofType(SubscriptionAddonFeatureAccessLimited),
    switchMap(({ addonId }) =>
      this.alertDialog
        .openResult({
          title: 'You Must Subscribe',
          body: `You must subscribe to ${tokeetAddonProductNames[addonId]} before you may access this feature.`,
          extraButton: {
            text: 'Subscribe',
            style: 'btn btn-secondary-primary',
            icon: 'cs-icon cs-icon-store',
          },
        })
        .pipe(
          tap((r) => {
            if (r === 'extra') {
              this.gotoAppStore(addonId)
            }
          })
        )
    ),
    tap(() => {
      this.gotoPlanPage()
    }),
    map(() => CloseAllDialogs())
  )

  @Effect()
  userCountLimited$ = this.actions$.pipe(
    ofType(SubscriptionUserCountLimited),
    switchMap(({ currentPlanType, current, limit }) =>
      this.confirmDialog.confirm({
        title: 'You need to upgrade',
        body: `You must subscribe to a paid plan before you can access these features.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect()
  rentalCountLimited$ = this.actions$.pipe(
    ofType(SubscriptionRentalCountLimited),
    switchMap(({ currentPlanType, current, limit }) =>
      this.confirmDialog.confirm({
        title: 'You need to upgrade',
        body: `You must subscribe to a paid plan before you can access these features.`,
      })
    ),
    tap(() => this.gotoPlanPage()),
    map(() => CloseAllDialogs())
  )

  @Effect({ dispatch: false })
  addonRentalCountLimited$ = this.actions$.pipe(
    ofType(SubscriptionAddonRentalCountLimited),
    switchMap(({ addonPlans, current }) =>
      this.confirmDialog.confirm({
        title: 'You need to upgrade',
        body: `You have exceeded the allowable number of rentals on one or more of your add-on subscriptions. Please upgrade to ensure that the add-on subscriptions are appropriate for the number of rentals on your AdvanceCM account. To do this, toggle the subscription status to Inactive and back to Active. The appropriate plan for your number of rentals is preselected. <br/>
          ${addonPlans.map((p) => `<strong>${p.planName}</strong>: ${p.rentals} rental(s) <br\>`).join('')}`,
      })
    ),
    tap(() => this.gotoPlanPage())
  )

  gotoPlanPage() {
    this.router.navigate(['/billing/info'])
  }

  gotoAppStore(product: string) {
    window.open(`${environment.appStoreUrl}/login?redirect=/details/${product}&token=${this.auth.token}`, '_blank')
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private confirmDialog: ConfirmDialogService,
    private alertDialog: AlertDialogService
  ) {}
}
