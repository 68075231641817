import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditAddressDialogComponent } from './edit-address-dialog.component'
import { Address } from '../../stores'

@Injectable()
export class EditAddressDialogService {
  constructor(private dialog: MatDialog) {}

  open(address?: Address) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { address },
    }

    return this.dialog.open<EditAddressDialogComponent, any, Address>(EditAddressDialogComponent, defaultConfig)
  }
}
