import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Destroyable, Rental, selectAllRentals, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { AccountRatesDialogTab } from '../account-rates-dialog/account-rates-dialog.component'
import { AccountRatesDialogService } from '../account-rates-dialog/account-rates-dialog.service'
import { EditRateDialogInput } from '../edit-rate-dialog/edit-rate-dialog.component'
import { EditRateDialogService } from '../edit-rate-dialog/edit-rate-dialog.service'

@Component({
  selector: 'app-rate-actions',
  templateUrl: './rate-actions.component.html',
  styleUrls: ['./rate-actions.component.scss'],
})
export class RateActionsComponent extends Destroyable implements OnInit {
  @Input() icon: string = 'far fa-plus'
  @Input() label: string = 'Actions'
  @Input() btnClass: string = 'btn btn-secondary-warning'
  @Output() ratesChange = new EventEmitter()

  rentals: Rental[] = []
  showDiscounts = environment.features.discounts

  constructor(
    private store: Store<any>,
    private editRateDialogService: EditRateDialogService,
    private accountRatesDialogService: AccountRatesDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  onAddStandardRate() {
    this.openRateDialog({ type: 'standard' })
  }

  onAddDynamicRate() {
    this.openRateDialog({ type: 'dynamic' })
  }

  openRateDialog(data: EditRateDialogInput) {
    const dialogRef = this.editRateDialogService.open({
      rentals: this.rentals,
      isGroupRate: false,
      ...data,
    })

    dialogRef.beforeClosed().subscribe((result) => {
      if (result && result.rate) this.ratesChange.emit()
    })
  }

  openChannelAdjustments() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.ChannelAutomations })
  }

  openGroupRates() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.GroupRates })
  }

  openLosDiscounts() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.LosDiscounts })
  }

  openDiscountCodes() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.DiscountCodes })
  }
}
