import { InjectionToken, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActionReducerMap, StoreModule } from '@ngrx/store'
import * as fromInquiry from './inquiry.reducer'
import * as inquiryState from './inquiry.state'
import { EffectsModule } from '@ngrx/effects'
import { InquiryEffects } from './inquiry.effects'
import { InquiryFieldsEffects } from '@tv3/store/inquiry/inquiry-fields.effects'
import { BookingFormulaStoreModule } from '@tokeet-frontend/booking-formula'

export const reducerToken = new InjectionToken<ActionReducerMap<inquiryState.State>>('Inquiry Reducers')

export const reducerProvider = [{ provide: reducerToken, useValue: fromInquiry.reducers.reducer }]

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('inquiry', reducerToken),
    EffectsModule.forFeature([InquiryEffects, InquiryFieldsEffects]),
    BookingFormulaStoreModule,
  ],
  providers: [reducerProvider],
  declarations: [],
})
export class InquiryModule {}
