import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditorImageOptions, EditorImageDialogComponent } from './editor-image-dialog.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export { EditorImageOptions }

@Injectable({
  providedIn: SharedModule,
})
export class EditorImageDialogService {
  constructor(private dialog: MatDialog) {}

  public open(options: { image?: EditorImageOptions } = {}) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { image: options.image },
    }

    return this.dialog
      .open(EditorImageDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(map((image: EditorImageOptions) => ({ image })))
  }
}
