<ng-container *ngIf="isLoading$ | async; else table">
  <app-loading-section></app-loading-section>
</ng-container>
<ng-template #table>
  <mat-table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
    <ng-container matColumnDef="inquirySource">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Source</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img
          [src]="element.inquirySource | inquirySourceIcon"
          [alt]="element.inquirySource"
          height="20"
          [matTooltip]="element.inquirySource"
          matTooltipPosition="above"
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rental">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Rental</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-rental-name [rentalId]="element.rentalId" [image]="false"></app-rental-name>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="touchView">
      <mat-header-cell *matHeaderCellDef> Updated</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [matTooltip]="element.touch | epoch" matTooltipPosition="above" [innerHtml]="element.touchView"></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guestArrive">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Arrive</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          [matTooltip]="element.guestArrive | epochUTC"
          matTooltipPosition="above"
          [innerHtml]="element.guestArriveView"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guestDepart">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Depart</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          [matTooltip]="element.guestDepart | epochUTC"
          matTooltipPosition="above"
          [innerHtml]="element.guestDepartView"
        ></span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nightsView">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Nights</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          <i class="cs-icon cs-icon-moon"></i>
          {{ element.nightsView }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="personCountView">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Guests</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          <i class="cs-icon cs-icon-user"></i>
          {{ element.personCountView }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Total</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [innerHtml]="element.total | rentalCurrencySymbol : element.rentalId | async"></span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="clickable"
      (click)="onBookingDetails(row)"
    ></mat-row>
  </mat-table>

  <mat-paginator2
    #paginator
    [length]="dataSource?.data?.length"
    [class.d-none]="isEmptyTable$ | async"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>
  <ng-container *ngIf="isEmptyTable$ | async">
    <app-empty-table [type]="tableType" *ngIf="!emptyMessage"></app-empty-table>
    <div class="text-center p-3" *ngIf="emptyMessage">{{ emptyMessage }}</div>
  </ng-container>
</ng-template>
