import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class Branding extends Serializable<Branding> {
  @Expose({ name: 'standard_template' })
  standardTemplate: string

  @Expose({ name: 'invoice_logo' })
  invoiceLogo: string

  @Expose({ name: 'invoice_template' })
  invoiceTemplate: string

  @Expose({ name: 'quote_template' })
  quoteTemplate: string

  @Expose({ name: 'guest_template' })
  guestTemplate: string

  @Expose({ name: 'contract_template' })
  contractTemplate: string

  static deserialize(data: object): Branding {
    return deserialize(Branding, data)
  }
}
