import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromAttachment from './attachment.reducer'
import { AttachmentEffects } from './attachment.effects'
import { AttachmentsGuard } from './attachment.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('attachment', fromAttachment.attachmentReducer),
    EffectsModule.forFeature([AttachmentEffects]),
  ],
  providers: [AttachmentsGuard],
  declarations: [],
})
export class AttachmentStoreModule {}
