import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import {
  CreateInventoryPayload,
  InventoryResponse,
  InventoryView,
  SearchInventoryParams,
  UpdateInventoryPayload,
} from './model'
import { Pagination } from '@tokeet-frontend/tv3-platform'

export const LoadInventories = createAction(
  '[Inventory] Load Inventories',
  props<{ params?: SearchInventoryParams; pagination?: Pagination }>()
)
export const LoadInventoriesComplete = createAction(
  '[Inventory] Load Inventories Complete',
  props<{ inventories: InventoryView[] }>()
)

export const AddInventory = createAction('[Inventory] Add Inventory', props<{ payload: CreateInventoryPayload }>())
export const AddInventoryComplete = createAction(
  '[Inventory] Add Inventory Complete',
  props<{ inventory: InventoryView }>()
)

export const AddInventories = createAction(
  '[Inventory] Add Inventories',
  props<{ requests: CreateInventoryPayload[] }>()
)
export const AddInventoriesComplete = createAction(
  '[Inventory] Add Inventories Complete',
  props<{ inventories: InventoryResponse[] }>()
)

export const UpdateInventory = createAction(
  '[Inventory] Update Inventory',
  props<{ payload: UpdateInventoryPayload; inventoryId: string }>()
)
export const UpdateInventoryComplete = createAction(
  '[Inventory] Update Inventory Complete',
  props<{ update: Update<InventoryView> }>()
)

export const UpdateInventories = createAction(
  '[Inventory] Update Inventories',
  props<{ items: { payload: UpdateInventoryPayload; inventoryId: string }[] }>()
)
export const UpdateInventoriesComplete = createAction(
  '[Inventory] Update Inventories Complete',
  props<{ updates: Update<InventoryView>[] }>()
)

export const DeleteInventory = createAction('[Inventory] Delete Inventory', props<{ id: string }>())
export const DeleteInventoryComplete = createAction('[Inventory] Delete Inventory Complete', props<{ id: string }>())

export const DeleteInventories = createAction('[Inventory] Delete Inventories', props<{ ids: string[] }>())
export const DeleteInventoriesComplete = createAction(
  '[Inventory] Delete Inventories Complete',
  props<{ ids: string[] }>()
)
