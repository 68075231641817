import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Destroyable, isSomething, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreateRentalsUnitedListing,
  CreateRentalsUnitedListingSuccess,
  RentalsUnitedCancellationPolicy,
  rentalsUnitedDepositTypes,
  RentalsUnitedListingViewModel,
  UpdateRentalsUnitedListing,
  UpdateRentalsUnitedListingSuccess,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'
import * as lodash from 'lodash'

function validateCancellationPolicies(arrayForm: FormArray) {
  const items: { validFrom: number; validTo: number }[] = arrayForm.getRawValue()
  const res = lodash
    .chain(items)
    .map(({ validFrom, validTo }) => {
      if (!lodash.isNumber(validFrom) || !lodash.isNumber(validTo)) {
        return null
      }
      return { validFrom, validTo }
    })
    .compact()
    .sortBy((t) => t.validFrom)
    .reduce(
      (data, item) => {
        if (data.overlap) {
          return data
        }
        const prev = data.prev
        if (!prev) {
          data.prev = item
          return data
        }

        if (
          (prev.validFrom <= item.validFrom && item.validFrom <= prev.validTo) ||
          (prev.validFrom <= item.validTo && item.validTo <= prev.validTo)
        ) {
          data.overlap = true
        }

        data.prev = item

        return data
      },
      { prev: null, overlap: false }
    )
    .value()
  return res.overlap ? { overlap: true } : null
}
@Component({
  selector: 'app-ru-listing-details-overlay',
  templateUrl: './ru-listing-details-overlay.component.html',
  styleUrls: ['./ru-listing-details-overlay.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class RUListingDetailsOverlayComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    rentalId: ['', [Validators.required]],
    landlordName: ['', [Validators.required]],
    landlordEmail: ['', [Validators.required, Validators.email]],
    landlordPhone: ['', [Validators.required]],
    daysBeforeArrival: ['', [Validators.required, Validators.min(1)]],
    depositAmount: ['', [Validators.required, Validators.min(0)]],
    depositTypeId: [undefined, [Validators.required]],
    floor: ['', [Validators.required, Validators.min(0)]],
    checkInOutPlace: ['', [Validators.required, Validators.required]],
    cancellationPolicy: this.fb.array([], [validateCancellationPolicies]),
    cancellationPolicyText: ['', [Validators.maxLength(40000)]],
  })

  rentalsUnitedDepositTypes = rentalsUnitedDepositTypes

  get cancelPolicies() {
    return this.form.get('cancellationPolicy') as FormArray
  }

  isEditing = false

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private actions: Actions,
    @Inject(MAT_DIALOG_DATA) public data: { listing: RentalsUnitedListingViewModel },
    public dialogRef: MatDialogRef<RUListingDetailsOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    const listing = this.data.listing

    this.isEditing = isSomething(listing.propertyId)

    this.form
      .get('depositTypeId')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((value) => {
        const depositAmountCtrl = this.form.get('depositAmount')
        if (value === 1) {
          depositAmountCtrl.setValidators([])
          depositAmountCtrl.setValue(0)
          depositAmountCtrl.disable()
        } else if (value === 2 || value === 3) {
          depositAmountCtrl.setValidators([Validators.min(0), Validators.max(100)])
          depositAmountCtrl.enable()
        } else {
          depositAmountCtrl.setValidators([])
          depositAmountCtrl.enable()
        }
      })

    this.form.patchValue({ ...listing })
    if (!listing?.cancellationPolicy?.length) {
      this.onAddCancelPolicy()
    } else {
      listing.cancellationPolicy.forEach((item) => {
        this.onAddCancelPolicy(item)
      })
    }

    this.actions
      .pipe(ofType(CreateRentalsUnitedListingSuccess, UpdateRentalsUnitedListingSuccess), untilDestroy(this))
      .subscribe(() => {
        this.close()
      })
  }

  onAddCancelPolicy(item?: RentalsUnitedCancellationPolicy) {
    const cancelPolicyForm = this.fb.group({
      validFrom: [item?.validFrom],
      validTo: [item?.validTo],
      totalPricePercent: [item?.totalPricePercent, [Validators.required, Validators.min(0), Validators.max(100)]],
    })

    cancelPolicyForm.get('validFrom').setValidators([
      Validators.required,
      Validators.min(0),
      (ctrl: FormControl) => {
        const value = ctrl.value
        const toValue = cancelPolicyForm?.get('validTo').value
        if (!value || !toValue) {
          return
        }
        if (value >= toValue) {
          return { max: true }
        }
      },
    ])
    cancelPolicyForm.get('validTo').setValidators([
      Validators.required,
      Validators.min(1),
      (ctrl: FormControl) => {
        const value = ctrl.value
        const fromValue = cancelPolicyForm?.get('validFrom').value
        if (!value || !fromValue) {
          return
        }
        if (value <= fromValue) {
          return { min: true }
        }
      },
    ])

    this.cancelPolicies.push(cancelPolicyForm)
  }

  onRemoveCancelPolicy(index: number) {
    this.cancelPolicies.removeAt(index)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const payload = form.getRawValue()

    this.store.dispatch(UpdateRentalsUnitedListing({ listing: payload }))
  }

  @SaveForm()
  onCreate(form: FormGroup) {
    const payload = form.getRawValue()
    this.store.dispatch(CreateRentalsUnitedListing({ listing: payload }))
  }

  close() {
    this.dialogRef.close()
  }
}
