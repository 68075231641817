<div class="">
  <div class="d-flex align-items-center gap-3 mb-3">
    <h6 class="mb-0">Payment Schedule</h6>
    <div class="flex-fill"></div>
    <div class="dropdown" dropdown>
      <button class="btn btn-secondary-primary dropdown-toggle" dropdownToggle type="button">
        <i class="fal fa-copy"></i>
        Copy
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li>
          <button
            (click)="onCopyFrom()"
            matTooltip="Ability to copy schedules from rental/s to your selected rental"
            matTooltipPosition="left"
          >
            <i class="fal fa-copy"></i> Copy from Rental
          </button>
        </li>
        <li>
          <button
            (click)="onCopyTo()"
            matTooltip="Ability to copy schedules to rental from your selected rental/s"
            matTooltipPosition="left"
          >
            <i class="fal fa-paste"></i> Copy to Rental
          </button>
        </li>
      </ul>
    </div>
    <button (click)="onSave()" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span>
      Save
    </button>
  </div>
  <div class="alert alert-secondary-info">
    <i class="fal fa-info-circle alert-icon"></i>
    <div class="alert-content">
      Setting up a payment schedule allows you to specify the payment amount you require from your traveler to confirm a
      booking. You can require 100% at the time of booking or set up multiple payments that can be scheduled for future
      dates.
    </div>
  </div>
  <div class="container-box p-3">
    <form [formGroup]="termsForm">
      <div formArrayName="terms" *ngFor="let item of terms.controls; let i = index">
        <div [formGroupName]="i" class="row term-box">
          <div *ngIf="hasDays(item['controls'].type.value)" class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="This indicates the day count of when the payment should be made w.r.t. payment time."
              matTooltipPosition="above"
            >
              <input type="number" formControlName="days" matInput required placeholder="Days" />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="Choose when the payment should be made"
              matTooltipPosition="above"
            >
              <input type="text" formControlName="typeView" matInput required placeholder="Payment time" />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2
              class="mat-block"
              matTooltip="This indicates the % value of payment to be made."
              matTooltipPosition="above"
            >
              <input type="text" formControlName="percent" matInput required placeholder="Percent" />
            </mat-form-field2>
          </div>
          <div class="col-sm-1">
            <button
              (click)="onRemoveTerm(i)"
              class="btn btn-secondary-danger align-input-row-end"
              type="button"
              matTooltip="Remove"
              matTooltipPosition="above"
            >
              <i class="cs-icon cs-icon-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </form>

    <form
      *ngIf="canAddMoreTypes(terms.controls) && remainingPercentage?.length > 0 && remainingTypes?.length > 0"
      [formGroup]="termForm"
      class="no-bottom no-top"
    >
      <div class="row">
        <div *ngIf="hasDays(termForm.get('type').value)" class="col-sm-3">
          <mat-form-field2
            class="mat-block"
            matTooltip="This indicates the day count of when the payment should be made w.r.t. payment time."
            matTooltipPosition="above"
          >
            <input type="number" formControlName="days" matInput required placeholder="Days" />
            <mat-error *ngIf="termForm.get('days').touched && termForm.get('days').hasError('required')">
              Days is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="termForm.get('days').touched && termForm.get('days').hasError('min')">
              Days must be <strong>positive number</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-3">
          <mat-form-field2>
            <mat-select
              matTooltip="Choose when the payment should be made"
              matTooltipPosition="above"
              formControlName="type"
              placeholder="Payment time"
            >
              <mat-option *ngFor="let t of remainingTypes" [value]="t.value">
                {{ t.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="termForm.get('type').touched && termForm.get('type').hasError('required')">
              Time is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-3">
          <mat-form-field2 matTooltip="This indicates the % value of payment to be made." matTooltipPosition="above">
            <mat-select formControlName="percent" placeholder="Percent">
              <mat-option *ngFor="let t of remainingPercentage" [value]="t"> {{ t }} % </mat-option>
            </mat-select>
            <mat-error *ngIf="termForm.get('percent').touched && termForm.get('percent').hasError('required')">
              Percent is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-1">
          <button
            (click)="onAddTerm(); $event.currentTarget.blur?.()"
            type="button"
            class="btn btn-secondary-info align-input-row-end"
          >
            Add
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
