<div class="container-box">
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort [dataSource]="dataSource" matSortActive="date" matSortDirection="desc">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.date | epoch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef> User</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.user }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef> Message</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.message }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="!dataSource?.filteredData?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>

      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
    </table>
  </div>
</div>
