<div class="d-flex align-items-center gap-3 mb-3">
  <h6 class="mb-0">Expense by Category</h6>
  <div class="flex-fill"></div>
  <div class="form-field-height"></div>
</div>
<div class="row">
  <div class="graph-holder col-lg-7">
    <canvas #ctx></canvas>
  </div>
  <div id="legend-container" class="col-lg-5"></div>
</div>
