import { IdNameObjectItem } from './id-name-item'

export type RoomCategory = IdNameObjectItem<number>

export const roomCategories: RoomCategory[] = [
  { id: 1, name: 'Living' },
  { id: 2, name: 'Dining' },
  { id: 3, name: 'Bedroom' },
  { id: 4, name: 'Bathroom' },
  { id: 5, name: 'Kitchen' },
  { id: 6, name: 'Pool' },
  { id: 7, name: 'Outside' },
  { id: 8, name: 'Garden' },
  { id: 9, name: 'Patio' },
  { id: 10, name: 'Gym' },
  { id: 11, name: 'Sports' },
  { id: 12, name: 'Location' },
  { id: 13, name: 'Utility' },
  { id: 14, name: 'Garage' },
  { id: 15, name: 'Storage' },
  { id: 16, name: 'Laundry' },
  { id: 17, name: 'Other' },
]
