import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Connection, getRealChannelId } from '@tv3/store/connection/connection.model'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectChannelById } from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap } from 'rxjs/operators'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { Toaster, ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { Observable } from 'rxjs'

export function ValidateLeadTime(control: AbstractControl) {
  const value = control.value
  if ((value >= 0 && value <= 24) || [48, 72, 168].indexOf(value) > -1) {
    return null
  }
  return { leadtime: true }
}

@Component({
  selector: 'app-airbnb-update-booking-pref-dialog',
  templateUrl: './airbnb-update-booking-pref-dialog.component.html',
  styleUrls: ['./airbnb-update-booking-pref-dialog.component.scss'],
})
export class AirbnbUpdateBookingPrefDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    leadtime: [undefined, [Validators.required, ValidateLeadTime]],
    turnover: [undefined, [Validators.required]],
    minstay: [undefined, [Validators.required]],
    maxstay: [undefined, [Validators.required]],
    maxleadtime: [undefined, [Validators.required]],
    allow_rtb_above_max_nights: [false],
    day_of_week_min_nights: this.fb.array([]),
    seasonal_min_nights: this.fb.array([]),
  })

  maxNoticeDaysOptions = [
    { name: 'Dates unavailable by default', value: 0 },
    { name: '3 months in advance', value: 90 },
    { name: '6 months in advance', value: 180 },
    { name: '9 months in advance', value: 270 },
    { name: '12 months in advance', value: 365 },
    { name: 'Anytime in the future', value: -1 },
  ]

  weekdays = moment.weekdays()

  get weekMinNights() {
    return this.form?.get('day_of_week_min_nights') as FormArray
  }
  get seasonalMinNights() {
    return this.form?.get('seasonal_min_nights') as FormArray
  }

  channel: Channel

  constructor(
    public dialogRef: MatDialogRef<AirbnbUpdateBookingPrefDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private confirm: ConfirmDialogService,
    private connectionAirbnbService: ConnectionAirbnbService,
    @Inject(MAT_DIALOG_DATA) public data: { connection: Connection }
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectChannelById, { id: getRealChannelId(this.data.connection.channelId) }),
        tap((channel) => (this.channel = channel)),
        switchMap((channel) =>
          this.connectionAirbnbService.getBookingRules(
            channel.name,
            this.data.connection.channelId,
            // @ts-ignore
            this.data.connection.propertyId,
            this.data.connection.roomId
          )
        ),
        untilDestroy(this)
      )
      .subscribe(({ availability_rule }) => {
        this.form.patchValue({
          leadtime: R.pathOr(0, ['booking_lead_time', 'hours'], availability_rule),
          turnover: R.path(['turnover_days', 'days'], availability_rule),
          minstay: R.path(['default_min_nights'], availability_rule),
          maxstay: R.path(['default_max_nights'], availability_rule),
          maxleadtime: R.path(['max_days_notice', 'days'], availability_rule),
          allow_rtb_above_max_nights: R.path(['allow_rtb_above_max_nights'], availability_rule),
        })
        this.weekMinNights.clear()
        const day_of_week_min_nights = lodash.sortBy(
          R.path<any[]>(['day_of_week_min_nights'], availability_rule),
          (t) => t.day_of_week
        )
        lodash.forEach(day_of_week_min_nights, (d) => this.newWeekDayMinNightsGroup(d))
        lodash.forEach(R.path<any[]>(['seasonal_min_nights'], availability_rule), (d) =>
          this.newSeasonalMinNightsGroup(d)
        )
        // if (!this.weekMinNights.length) {
        //   this.newWeekDayMinNightsGroup()
        // }
        // if (!this.seasonalMinNights.length) {
        //   this.newSeasonalMinNightsGroup()
        // }
      })
  }

  isDateRangeOverlap(index: number, ranges: { start_date: moment.Moment; end_date: moment.Moment }[]) {
    const range = ranges[index]
    if (!range || !range.start_date || !range.end_date) return false

    const found = lodash.find(ranges, (r, i) => {
      if (!r.start_date || !r.end_date || i === index) return false

      if (
        (range.start_date?.unix() >= r.start_date?.unix() && range.start_date?.unix() <= r.end_date?.unix()) ||
        (range.end_date?.unix() >= r.start_date?.unix() && range.end_date?.unix() <= r.end_date?.unix()) ||
        (range.start_date?.unix() < r.start_date?.unix() && range.end_date?.unix() > r.end_date?.unix())
      ) {
        return true
      }
    })

    return found
  }

  close() {
    this.dialogRef.close()
  }

  removeWeekDay(index: number) {
    this.weekMinNights.removeAt(index)
  }

  newWeekDayMinNightsGroup(item?: any) {
    const form = this.fb.group({
      day_of_week: [item?.day_of_week, [Validators.required]],
      min_nights: [item?.min_nights, [Validators.required, Validators.min(0)]],
    })
    this.weekMinNights.push(form)
  }

  removeSeasonal(index: number) {
    this.seasonalMinNights.removeAt(index)
  }

  newSeasonalMinNightsGroup(item?: any) {
    const form = this.fb.group({
      start_date: [item?.start_date ? moment(item?.start_date, 'YYYY-MM-DD') : undefined, [Validators.required]],
      end_date: [item?.end_date ? moment(item?.end_date, 'YYYY-MM-DD') : undefined, [Validators.required]],
      min_nights: [item?.min_nights, [Validators.required, Validators.min(1)]],
    })
    this.seasonalMinNights.push(form)

    form.setValidators(() => {
      const dateRanges = this.seasonalMinNights.value
      const index = lodash.findIndex(this.seasonalMinNights.controls, (c) => c === form)
      const startCtrl = form.get('start_date')
      const endCtrl = form.get('end_date')
      if (this.isDateRangeOverlap(index, dateRanges)) {
        startCtrl.setErrors({ overlap: true })
        endCtrl.setErrors({ overlap: true })
      } else {
        let errors = lodash.omit(startCtrl.errors, 'overlap')
        startCtrl.setErrors(lodash.isEmpty(errors) ? null : errors)
        errors = lodash.omit(endCtrl.errors, 'overlap')
        endCtrl.setErrors(lodash.isEmpty(errors) ? null : errors)
      }
      return null
    })
  }

  @SaveForm()
  save(form: FormGroup) {
    const {
      leadtime,
      turnover,
      minstay,
      maxstay,
      maxleadtime,
      allow_rtb_above_max_nights,
      day_of_week_min_nights,
      seasonal_min_nights,
    } = this.form.getRawValue()

    const doUpdate = () =>
      this.connectionAirbnbService
        .updateBookingRules(this.channel.name, {
          channelId: this.data.connection.channelId,
          maxleaddays: maxleadtime,
          maxstay: maxstay,
          minleadtime: leadtime,
          minstay: minstay,
          // @ts-ignore
          propertyId: this.data.connection.propertyId,
          // @ts-ignore
          roomid: this.data.connection.roomId,
          turnover: turnover,
          allow_rtb_above_max_nights,
          day_of_week_min_nights,
          seasonal_min_nights: lodash.map(seasonal_min_nights, (t) => ({
            min_nights: t.min_nights,
            start_date: moment(t.start_date).format('YYYY-MM-DD'),
            end_date: moment(t.end_date).format('YYYY-MM-DD'),
          })),
        })
        .pipe(take(1))

    let ob: Observable<any>
    if (maxleadtime === 0) {
      ob = this.confirm
        .confirm({
          title: 'Dates unavailable by default',
          body: 'You have selected <b>Dates unavailable by default</b> in advance booking settings, It will block the calendar for guests in Airbnb. Please confirm to proceed ',
        })
        .pipe(switchMap(() => doUpdate()))
    } else {
      ob = doUpdate()
    }
    ob.subscribe(
      () => {
        this.toaster.success('Booking rules updated successfully.')
        this.dialogRef.close()
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }
}
