import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'resizeImg' })
export class ResizeImgPipe implements PipeTransform {
  transform(value: string, width: number, height: number): any {
    return resizeImage(value, width, height)
  }
}

export function resizeImage(url: string, width: number, height: number) {
  if (!url) return null
  if (!url.includes('res.cloudinary.com')) return url

  let index = url.indexOf('upload/')
  if (index === -1) return url

  index += 'upload/'.length

  if (height === undefined) {
    height = width
  }

  width = Math.round(width)
  height = Math.round(height)

  const dimensions = 'w_' + width + ',h_' + height + ',q_auto:eco,'

  url = url.slice(0, index) + dimensions + 'c_fill,f_auto/' + url.slice(index, url.length)

  // Force a JPEG format for size savings
  url = url.replace(/\.[a-z0-9]+?$/i, '.jpg')

  return url
}
