import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './channel.reducer'
import * as state from './channel.state'
import * as R from 'ramda'
import { capitalize, toString, find, forEach } from 'lodash'
import { Channel, ChannelNameTokens, tokeetInternalChannels } from './channel.model'
import { sortAscend } from '../../functions'
import { safeLowerCase } from '../../functions'
import { ArrayElement } from '../../types/array'

export const channelState = createFeatureSelector<state.State>('channel')

export const selectChannelsLoaded = createSelector(channelState, (s: state.State) => s.isLoaded)

export const selectChannelEntities = createSelector(channelState, reducer.selectEntities)

export const selectAllChannels = createSelector(channelState, reducer.selectAll)

export const selectAllChannelsWithDefaults = createSelector(selectAllChannels, (items) => {
  return sortAscend<Channel>('friendlyName')([...items, ...tokeetInternalChannels])
})

export const selectAllApiChannelViews = createSelector(selectAllChannels, (channelViews: Channel[]) => {
  return R.filter((channel) => channel.isApi, channelViews)
})

export const selectAllApiChannelViewsWithout = (names: ChannelNameTokens[]) =>
  createSelector(selectAllApiChannelViews, (channelViews: Channel[]) => {
    return R.filter((channel) => !names.includes(channel.name), channelViews)
  })

export const selectApiChannelsWithDefaults = createSelector(selectAllApiChannelViews, (items) => {
  return sortAscend<Channel>('friendlyName')([...items, ...tokeetInternalChannels])
})

export const selectAllICalChannelViews = createSelector(selectAllChannels, (channelViews: Channel[]) => {
  return R.filter((channel) => channel.isICal, channelViews)
})

export const selectChannelById = createSelector(selectChannelEntities, (entities, props) => {
  return entities[props.id] as Channel
})

export const selectIsChannelConnected = (name: ChannelNameTokens) =>
  createSelector(selectAllChannels, (channels) => {
    const channel = R.find((ch) => ch.name === name, channels)
    return channel ? !!channel.isConnected : false
  })

export const selectChannelByName = createSelector(selectAllChannels, (channels: Channel[], props) => {
  return R.find((ch) => {
    if (/expedia/.test(props.name)) {
      return /expedia/.test(R.toLower(ch.name))
    }
    return R.toLower(ch.name) === R.toLower(props.name)
  }, channels)
})

function buildSourcesWithChannels(channels: Channel[]) {
  // TV3-5499: hide GoogleVacationRentals as source
  channels = channels.filter((ch) => ch.name !== ChannelNameTokens.GoogleVacationRentals)
  return R.map((ch: Channel) => {
    if (ch.token === 'booking') {
      return { id: 'booking.com', name: 'booking.com', label: 'Booking.com' }
    } else {
      const name = R.pipe(R.defaultTo(''), R.toLower)(ch.token)
      return { id: name, name, label: ch.friendlyName || name }
    }
  }, channels)
}

export const selectFormChannels = (inquiry: { inquirySource: string }, includeSource = false) =>
  createSelector(selectAllChannels, (channels) => {
    const inquirySource = toString(inquiry.inquirySource)
    let result = buildSourcesWithChannels(channels)
    if (inquirySource?.toLowerCase() === 'tokeet') {
      result = R.append({ id: 'tokeet', name: 'tokeet', label: 'AdvanceCM' }, result)
    }
    if (includeSource && R.isNil(R.find((s) => safeLowerCase(s.id) === safeLowerCase(inquirySource), result))) {
      result = R.append({ id: inquirySource, name: inquirySource, label: capitalize(inquirySource) }, result)
    }
    return sortAscend<ArrayElement<typeof result>>('label')(result)
  })

export const selectSourcesFromChannels = (customSources: string[]) =>
  createSelector(selectAllChannels, (channels) => {
    const result = buildSourcesWithChannels(channels)
    forEach(customSources, (source: string) => {
      if (find(result, (item) => safeLowerCase(item.id) === safeLowerCase(source))) {
        return
      }
      result.push({ name: source, label: capitalize(source), id: source })
    })

    return sortAscend<ArrayElement<typeof result>>('label')(result)
  })
