import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import {
  AlertDialogService,
  Channel,
  ChannelNameTokens,
  ConfirmDialogService,
  ConnectedChannelAccountResponse,
  InfoDialogService,
  isSomething,
  Rental,
  selectOnce,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { concatMap, filter, map, mapTo, switchMap, take, tap, toArray, withLatestFrom } from 'rxjs/operators'
import { AuthService } from '@tv3/services/auth.service'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import {
  AddExportedCalendarConnection,
  CreateConnectionsForChannel,
  CreateConnectionsForChannelComplete,
  DeleteCalendarConnections,
  DeleteExportedCalendarConnection,
  DeleteImportedCalendarConnection,
  ImportBookingsFromConnection,
  ImportBookingsFromConnectionComplete,
  LinkConnectionWithRental,
  ManualRefreshImportedCalendarConnection,
  ManualRefreshImportedCalendarConnections,
  PushAvailabilityToConnection,
  PushAvailabilityToConnectionComplete,
  PushAvailabilityToConnectionForWizard,
  PushRatesToConnection,
  UnlinkConnectionsWithRental,
  UnlinkConnectionWithRental,
} from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { SelectRateToPushDialogService } from '@tv3/containers/channels/channel-connect/dialogs/select-rate-to-push-dialog/select-rate-to-push-dialog.service'
import { SelectRentalDialogService } from '@tv3/shared/dialogs/select-rental-dialog/select-rental-dialog.service'
import { forkJoin, Observable, of } from 'rxjs'
import { SelectToCreateListingDialogService } from '@tv3/containers/channels/channel-connect/dialogs/select-to-create-listing-dialog/select-to-create-listing-dialog.service'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import * as moment from 'moment'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import {
  selectAvailableRentalForNewAPIConnection,
  selectAvailableRentalForNewExportedCalendar,
  selectConnectionById,
} from '@tv3/store/connection/connection.selectors'
import { AddExportedCalendarConnectionPayload } from '@tv3/store/connection/connection.types'
import { RategenieService } from '@tv3/services/rategenie.service'
import { selectAccountPreference } from '@tv3/store/preferences/preferences.selectors'
import { Preference } from '@tv3/store/preferences/preferences.model'
import { UpdatePreferences } from '@tv3/store/preferences/preferences.actions'
import { RentalRateService } from '@tv3/services/rental-rate.service'
import { Connection, getRealChannelId } from '@tv3/store/connection/connection.model'
import {
  PushAvailabilityToCtripListing,
  PushAvailabilityToTiketListing,
  PushRatesToCtripListing,
  PushRatesToTiketListing,
} from '@tokeet-frontend/channels'

@Injectable({
  providedIn: SharedModule,
})
export class ConnectionHelperService {
  constructor(
    private auth: AuthService,
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private toaster: Toaster,
    private alertDialog: AlertDialogService,
    private confirmDialog: ConfirmDialogService,
    private infoDialog: InfoDialogService,
    private selectRentalDialog: SelectRentalDialogService,
    private selectRateToPushDialog: SelectRateToPushDialogService,
    private selectToCreateListingDialog: SelectToCreateListingDialogService,
    private rategenieService: RategenieService,
    private rentalRateService: RentalRateService,
    private connectionService: ConnectionService
  ) {}

  tryToDisplayConnectionWarning(connections: ConnectionView[]) {
    const showWarning = !!R.find((c) => c.warning, connections)
    if (!showWarning) {
      return
    }

    this.store
      .pipe(
        select(selectAccountPreference),
        take(1),
        switchMap((pref: Preference) => {
          if (pref && pref.disableChannelConnectionsWarning) {
            return of(true)
          }
          return this.infoDialog
            .open({
              title: 'Connection Warning',
              body: `Your have either Disabled, Stale, or Pending connections. Please fix these connections as soon as possible.
          AdvanceCM will not sync with your connections while they are in this status.`,
              extra: 'Disable this notification forever.',
            })
            .pipe(
              filter((result) => isSomething(result)),
              tap(({ extra }) => {
                if (extra) {
                  this.store.dispatch(
                    UpdatePreferences({ id: pref.id, form: { disable_channel_connections_warning: 1 } })
                  )
                }
              })
            )
        }),
        take(1)
      )
      .subscribe()
  }

  createListing(channel: Channel) {
    return this.selectToCreateListingDialog.open(channel.id).pipe(
      tap(({ rental, account }: { rental: Rental; account: ConnectedChannelAccountResponse }) => {
        this.store.dispatch(
          CreateConnectionsForChannel({
            channelName: channel.name,
            data: {
              channelId: channel.id,
              propertyId: account.propertyId,
              rental_id: rental.id,
              api: channel.name,
            },
          })
        )
      }),
      switchMap(() => this.actions$.pipe(ofType(CreateConnectionsForChannelComplete))),
      take(1)
    )
  }

  setUpExportCalendar(channel: Channel) {
    return this.store.pipe(
      select(selectAvailableRentalForNewExportedCalendar, { name: channel.name }),
      take(1),
      switchMap((rentals: Rental[]) => {
        return this.selectRentalDialog.open({
          help: `Please select the AdvanceCM rental whose iCal URL you would like to export. You will be given instructions on how to export
      the URL to this channel. You can undo this setting later if you wish.`,
          rentals,
        })
      }),
      switchMap((rental: Rental) => {
        return Observable.create((observer) => {
          const refId = Date.now().toString(36).toLowerCase()
          const baseURL = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`
          const url = `${baseURL}/pages/${channel.infoPage}?accountId=${this.auth.user.account}&rentalId=${rental.id}&ref_id=${refId}`
          // openWindow(url);

          const calendarUrl = `https://calendars.tokeet.com/calendar/rental/${rental.account}/${rental.id}?ref_id=${refId}`
          // @ts-ignore
          // window.successCallback = (channelRentalId, query) => {
          const payload = {
            rental_id: rental.id,
            id: channel.id,
            name: channel.name,
            agent: channel.agent,
            type: channel.type,
            rental_name: rental.name || '',
            channel_rental_id: `${channel.id}-${rental.id}`,
            ref_id: refId,
            url: calendarUrl,
          } as AddExportedCalendarConnectionPayload
          this.store.dispatch(AddExportedCalendarConnection({ payload }))

          observer.next(calendarUrl)
          observer.complete()
          // };
        })
      }),
      switchMap((url) => {
        return this.infoDialog
          .open({
            title: 'Exported Calendar',
            body: `<p style="word-break: break-all;">Below is your exported calendar url: <br/> <a href="${url}" target="_blank">${url}</a></p>`,
            closeText: 'Done',
          })
          .pipe(mapTo(url))
      }),
      take(1)
    )
  }

  importAllBookings(channelName: ChannelNameTokens, connectionViews: ConnectionView[]) {
    connectionViews = R.filter((conn) => !!conn.status && !!conn.rental, connectionViews)
    if (!connectionViews.length) {
      this.alertDialog.open({
        title: 'Warning',
        body: 'No connection(s) to import.',
      })
      return
    }

    this.confirmDialog
      .confirm({
        title: 'Import bookings from channel?',
        body: `This will import your bookings from the selected channel into AdvanceCM. AdvanceCM will only import bookings
            that are not already saved. However, this operation may miss some older bookings.
            <br><br>Are you sure you want to import?`,
        confirmBtnClass: 'btn btn-secondary-success',
        cancelBtnClass: 'btn btn-light',
      })
      .pipe(
        switchMap(() => of(...connectionViews)),
        concatMap((connection) => {
          return this.connectionService.importBookings(channelName, connection.channelId, connection).pipe(
            tap(() =>
              this.store.dispatch(
                ImportBookingsFromConnectionComplete({
                  update: {
                    id: connection.id,
                    changes: { lastavailpull: moment.utc().unix() },
                  },
                })
              )
            )
          )
        }),
        toArray()
      )
      .subscribe(
        () => {
          this.toaster.success(`Bookings imported into AdvanceCM successfully.`)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  importBookings(channelName: ChannelNameTokens, connection: Connection): Observable<any> {
    return this.confirmDialog
      .confirm({
        title: 'Import bookings from channel?',
        body: `This will import your bookings from the selected channel into AdvanceCMAdvanceCM. AdvanceCM will only import bookings
            that are not already saved. However, this operation may miss some older bookings.
            <br><br>Are you sure you want to import?`,
        confirmBtnClass: 'btn btn-secondary-success',
        cancelBtnClass: 'btn btn-light',
      })
      .pipe(
        switchMap(() => this.store.pipe(select(selectConnectionById, { id: connection.id }))),
        tap((connection) => {
          this.store.dispatch(
            ImportBookingsFromConnection({
              connId: connection.id,
              channelId: connection.channelId,
              channelName: channelName,
              data: connection,
            })
          )
        }),
        take(1)
      )
  }

  pushAllAvailability(channelName: ChannelNameTokens, connections: ConnectionView[]) {
    connections = R.filter((conn) => !!conn.status && !!conn.rental, connections)
    if (!connections.length) {
      this.alertDialog.open({
        title: 'Warning',
        body: 'No connection(s) to push.',
      })
      return
    }

    this.confirmDialog
      .confirm({
        title: 'Push availability to channel?',
        body: `This will push your rental(s) availability from AdvanceCM to the selected channel. The channel information will be overwritten
        with your AdvanceCM details. <br>Please ensure your AdvanceCM information is up to date. <br><br> Are you sure you want to push?`,
        confirmBtnClass: 'btn btn-secondary-success',
        cancelBtnClass: 'btn btn-light',
      })
      .pipe(
        switchMap(() => of(...connections)),
        concatMap((connection) => {
          return this.connectionService.pushAvailability(channelName, connection.channelId, connection).pipe(
            tap(() =>
              this.store.dispatch(
                PushAvailabilityToConnectionComplete({
                  update: {
                    id: connection.id,
                    changes: { lastavailpush: moment.utc().unix() },
                  },
                })
              )
            )
          )
        }),
        toArray()
      )
      .subscribe(
        () => {
          this.toaster.success(`Availability pushed to channel successfully.`)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  private _pushAvailability(connection: Connection): Observable<any> {
    if (connection.status === 0) {
      this.alertDialog.open({
        title: 'Connection disabled.',
        body: `This connection has been disabled by AdvanceCM. This is more than likely
            due to invalid credentials or the channel has indicated that the
            connection is no longer valid. Please update your credentials to proceed.`,
      })
      return of(false)
    }

    return this.confirmDialog.confirm({
      title: 'Push availability to channel?',
      body: `This will push your rental availability from AdvanceCM to the selected channel. The channel information will be overwritten with
            your AdvanceCM details. <br>Please ensure your AdvanceCM information is up to date. <br><br>
            Are you sure you want to push?`,
      confirmBtnClass: 'btn btn-secondary-success',
      cancelBtnClass: 'btn btn-light',
    })
  }

  pushAvailability(channelName: ChannelNameTokens, connectionView: ConnectionView): Observable<any> {
    return this._pushAvailability(connectionView).pipe(
      switchMap(() => this.store.pipe(select(selectConnectionById, { id: connectionView.id }))),
      tap((connection) => {
        this.store.dispatch(
          PushAvailabilityToConnection({
            connId: connection.id,
            channelId: connection.channelId,
            channelName: channelName,
            data: connection,
          })
        )
      }),
      take(1)
    )
  }

  pushAvailabilityForTiketLike(connection: Connection, channelName: ChannelNameTokens) {
    return this._pushAvailability(connection).pipe(
      tap(() => {
        const payload = {
          connId: connection.id,
          roomTypeCode: connection.roomId as number,
          data: {
            rental_id: connection.rentalId,
            hotel_id: connection.propertyId as number,
          },
        }

        switch (channelName) {
          case ChannelNameTokens.Tiket:
            this.store.dispatch(PushAvailabilityToTiketListing(payload))
            break
          case ChannelNameTokens.Trip:
            this.store.dispatch(PushAvailabilityToCtripListing(payload))
            break
        }
      })
    )
  }

  pushAvailabilityForWizard(
    channelName: ChannelNameTokens,
    connection: Connection,
    tmpHoldEventId?: string
  ): Observable<any> {
    return this._pushAvailability(connection).pipe(
      switchMap(() => this.store.pipe(select(selectConnectionById, { id: connection.id }))),
      tap((connection) => {
        this.store.dispatch(
          PushAvailabilityToConnectionForWizard({
            connId: connection.id,
            channelId: connection.channelId,
            channelName: channelName,
            data: connection,
            tmpHoldEventId,
          })
        )
      }),
      take(1)
    )
  }

  pushRateForTiketLikeChannel(connection: Connection, channel: Channel, rental: Rental) {
    const doPushRates = (categories) => {
      return this.selectRateToPushDialog
        .open({
          channelId: connection.channelId,
          channelName: channel.name,
          channelFriendlyName: channel.friendlyName || 'channel',
          propertyId: connection.propertyId,
          roomId: connection.roomId,
          categories,
        })
        .pipe(
          tap(({ category, channelRate }: { category: string; channelRate: any }) => {
            const payload = {
              connId: connection.id,
              roomTypeCode: connection.roomId as number,
              data: {
                rental_id: connection.rentalId,
                hotel_id: connection.propertyId as number,
                rate_plan_code: channelRate.id,
                category,
              },
            }

            switch (channel.name) {
              case ChannelNameTokens.Tiket:
                this.store.dispatch(PushRatesToTiketListing(payload))
                break
              case ChannelNameTokens.Trip:
                this.store.dispatch(PushRatesToCtripListing(payload))
                break
            }
          })
        )
    }

    return this.checkBeforePushRates(rental, connection).pipe(switchMap((categories) => doPushRates(categories)))
  }

  checkBeforePushRates(rental: Rental, connection: Connection) {
    return forkJoin([
      this.rategenieService.checkAccountSync(),
      this.rategenieService.checkTokeetSync(),
      this.rategenieService.checkRentalSync(rental.id),
      this.rategenieService.checkChannelConnectionSync(
        rental.id,
        (connection.roomId || connection.propertyId) as string
      ),
    ]).pipe(
      map((syncs: any[]) => R.all((s) => s, syncs)),
      switchMap((allSyncEnabled) =>
        this.rentalRateService
          .getRateCategories(rental.id, true)
          .pipe(map((categories) => [allSyncEnabled, categories]))
      ),
      switchMap(([allSyncEnabled, categories]) => {
        if (allSyncEnabled) {
          return of(categories)
        }

        if (
          !isSomething(categories) ||
          !rental.baseRate ||
          !rental.baseRate.nightly ||
          !rental.currency ||
          !rental.currency.code
        ) {
          return this.alertDialog
            .openResult({
              title: 'Rental rate information incomplete.',
              body: `Please check your rental rate information.<br>
                1. Base rate (nightly) is required.<br>
                2. At least one additional rate is required.<br>
                3. You must specify a currency code.`,
            })
            .pipe(filter(() => false))
        }

        return of(categories)
      })
    )
  }

  pushRates(channel: Channel, rental: Rental, connection: Connection): Observable<any> {
    if (!rental) {
      this.toaster.warning('Cannot find the associated rental.')
      return of(false)
    }

    const doPushRates = (categories) => {
      return this.selectRateToPushDialog
        .open({
          channelId: connection.channelId,
          channelName: channel.name,
          channelFriendlyName: channel.friendlyName || 'channel',
          propertyId: connection.propertyId,
          roomId: connection.roomId,
          categories,
        })
        .pipe(
          tap(({ category, channelRate }: { category: string; channelRate: any }) => {
            this.store.dispatch(
              PushRatesToConnection({
                connId: connection.id,
                channelId: connection.channelId,
                channelName: channel.name,
                data: {
                  category,
                  rateid: channelRate.id,
                  maxguest: channelRate.maxguest,
                  pricemodel: channelRate.model,
                  roomId: connection.roomId,
                  propertyId: connection.propertyId,
                  rental_id: connection.rentalId,
                  maxPersons: channelRate.max_per,
                },
              })
            )
          })
        )
    }

    return this.checkBeforePushRates(rental, connection).pipe(switchMap((categories) => doPushRates(categories)))
  }

  linkRental(channelName: ChannelNameTokens, connection: ConnectionView) {
    return this.store.pipe(
      selectOnce(selectAvailableRentalForNewAPIConnection, {
        id: getRealChannelId(connection.channelId),
        selected: connection.rentalId,
      }),
      switchMap((rentals) =>
        this.selectRentalDialog.open({
          title: 'Select Rental',
          help: `Please select the AdvanceCM rental you would like to link to this property. Linking allows you to synchronize your rental with
        this property. You can unlink this property later if you wish.`,
          selectedId: connection.rentalId,
          rentals,
        })
      ),
      filter((res) => isSomething(res)),
      tap((rental) => {
        this.store.dispatch(
          LinkConnectionWithRental({
            connId: connection.id,
            data: {
              rentalId: rental.id,
              channelId: connection.channelId,
              propertyId: connection.propertyId,
              roomId: connection.roomId,
            },
            channelName: channelName,
          })
        )
      })
    )
  }

  unlinkRental(channelName: ChannelNameTokens, connection: ConnectionView) {
    this.confirmDialog
      .confirm({
        title: 'Unlink this Property?',
        body: 'This will unlink the rental associated with this property. <br/>Are you sure you want to unlink?',
      })
      .subscribe(() => {
        this.store.dispatch(
          UnlinkConnectionWithRental({
            connId: connection.id,
            data: {
              propertyId: connection.propertyId,
              rentalId: connection.rentalId,
              roomId: connection.roomId,
              channelId: connection.channelId,
            },
            channelName: channelName,
          })
        )
      })
  }

  unlinkRentals(connections: ConnectionView[]) {
    this.confirmDialog
      .confirm({
        title: 'Unlink selected Properties?',
        body: 'This will unlink the rental associated with selected properties. <br/>Are you sure you want to unlink?',
      })
      .subscribe(() => {
        this.store.dispatch(
          UnlinkConnectionsWithRental({
            items: connections.map((connection) => ({
              connId: connection.id,
              data: {
                propertyId: connection.propertyId,
                rentalId: connection.rentalId,
                roomId: connection.roomId,
                channelId: connection.channelId,
              },
              channelName: connection.channel.name,
            })),
          })
        )
      })
  }

  refreshImportedCalendar(connection: ConnectionView) {
    this.store.dispatch(
      ManualRefreshImportedCalendarConnection({
        connId: connection.id,
        data: {
          name: connection.name,
          calendar: connection.url,
          rentalid: connection.rentalId,
          sendto: connection.sendto || R.path(['rental', 'email'], connection),
        },
      })
    )
  }

  refreshImportedCalendars(connections: ConnectionView[]) {
    this.store.dispatch(
      ManualRefreshImportedCalendarConnections({
        payloads: R.map(
          (connection) => ({
            connId: connection.id,
            data: {
              name: connection.name,
              calendar: connection.url,
              rentalid: connection.rentalId,
              sendto: connection.sendto || R.path(['rental', 'email'], connection),
            },
          }),
          connections
        ),
      })
    )
  }

  removeImportedCalendar(connection: ConnectionView) {
    this.confirmDialog
      .confirmExtra({
        title: 'Delete this Calendar?',
        body: 'Are you sure you want to delete this calendar?',
        extra: 'Delete all associated events from your calendar?',
      })
      .subscribe(({ isChecked }) => {
        this.store.dispatch(
          DeleteImportedCalendarConnection({
            connId: connection.id,
            rentalId: connection.rentalId,
            removeAllEvents: isChecked,
          })
        )
      })
  }

  removeExportedCalendar(connection: ConnectionView) {
    this.confirmDialog
      .confirm({
        title: 'Delete this iCal connection?',
        body: `Are you sure you want to delete this iCal connection? Deleting the connection does not remove the AdvanceCM iCal URL
      from your channel. If you saved the AdvanceCM URL in your channel please log into your channel dashboard and also delete it there.`,
      })
      .subscribe(() => {
        this.store.dispatch(DeleteExportedCalendarConnection({ id: connection.id }))
      })
  }

  removeCalendars(connections: ConnectionView[]) {
    const imported = connections.filter((t) => t.isImported)
    const exported = connections.filter((t) => t.isExported)

    if (!imported.length) {
      this.confirmDialog
        .confirm({
          title: 'Delete selected calendars?',
          body: `Are you sure you want to delete selected calendars?`,
        })
        .subscribe(() => {
          this.store.dispatch(DeleteCalendarConnections({ exported: exported.map((t) => t.id) }))
        })
    } else {
      this.confirmDialog
        .confirmExtra({
          title: 'Delete selected calendars?',
          body: `Are you sure you want to delete selected calendars?`,
          extra: 'Delete all associated events from your imported calendars?',
        })
        .subscribe(({ isChecked }) => {
          this.store.dispatch(
            DeleteCalendarConnections({
              exported: exported.map((t) => t.id),
              imported: {
                items: imported.map((t) => ({ connId: t.id, rentalId: t.rentalId })),
                removeAllEvents: isChecked,
              },
            })
          )
        })
    }
  }
}
