<div class="d-grid gap-3" style="grid-template-columns: repeat(auto-fit, minmax(260px, 300px))">
  <ng-container [ngSwitch]="channelType">
    <ng-container *ngSwitchCase="'api'">
      <app-channel-card *ngFor="let channel of apiChannels$ | async" [channel]="channel"></app-channel-card>
    </ng-container>
    <ng-container *ngSwitchCase="'ical'">
      <app-channel-card *ngFor="let channel of icalChannels$ | async" [channel]="channel"></app-channel-card>
    </ng-container>
    <ng-container *ngSwitchCase="'custom'">
      <app-channel-card
        *ngFor="let channel of customChannels$ | async"
        [channel]="channel"
        [isCustomChannel]="true"
      ></app-channel-card>
      <div
        class="shadow rounded ng-star-inserted bg-white d-flex align-items-center justify-content-center"
        style="border: 2px dashed var(--info); min-height: 70px"
        (click)="onAddCustom()"
      >
        <a href="javascript:;"> <i class="far fa-plus"></i> Add Custom Channel </a>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-channel-card *ngFor="let channel of apiChannels$ | async" [channel]="channel"></app-channel-card>
      <app-channel-card *ngFor="let channel of icalChannels$ | async" [channel]="channel"></app-channel-card>
      <app-channel-card
        *ngFor="let channel of customChannels$ | async"
        [channel]="channel"
        [isCustomChannel]="true"
      ></app-channel-card>
    </ng-container>
  </ng-container>
</div>
