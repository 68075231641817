import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Rental, RentalService, selectRentalById, selectSomeOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { BookingDatesLimitType, RentalRateService } from './rental-rate.service'

@Injectable({
  providedIn: 'root',
})
export class BookingHelperService {
  constructor(
    private store: Store<any>,
    private rentalService: RentalService,
    private rentalRateService: RentalRateService,
    private toaster: Toaster
  ) {}

  checkBookingDates(rentalId: string, arrive: number, depart: number, validAction$: Observable<any>): Observable<any> {
    return this.rentalService.get(rentalId).pipe(
      switchMap((rental: Rental) => {
        const { limit, data: limitData } = this.rentalRateService.checkDatesLimit(rental, arrive, depart)
        if (limit === BookingDatesLimitType.MinimumStay) {
          this.toaster.warning(`Minimum stay requirement for this Rental is ${limitData.minimumStay} Night (s)`)
        }

        if (limit === BookingDatesLimitType.MaximumStay) {
          this.toaster.warning(`Maximum stay requirement for this Rental is ${limitData.maximumStay} Night (s)`)
        }

        return validAction$
      })
    )
  }
}
