<div
  class="month-day"
  [class.has-data]="ratesByCategory"
  [class.booked]="booked"
  [class.today]="today"
  [class.before-today]="beforeToday"
  [ngClass]="booked"
>
  <div
    class="month-day-inner"
    [ngbPopover]="tmplRateTooltip"
    #popover="ngbPopover"
    container="body"
    placement="auto"
    popoverClass="popover-fill-content"
    triggers="manual"
    [autoClose]="true"
    (click)="openPopover(popover)"
  >
    <div class="day">
      <span *ngIf="startOfMonth">{{ day | epochUTC: 'MMM' }}</span>
      {{ day | epochUTC: 'D' }}
    </div>

    <ng-container *ngIf="showContent">
      <ng-container *ngIf="getRatesForCategories | call: ratesByCategory:channelsById; let rates">
        <table class="rates" *ngIf="rates?.length" [style.margin-top.px]="rates.length >= 4 ? 5 : 10">
          <tr class="rate" *ngFor="let item of rates.slice(0, 4)">
            <td class="label">
              <div [attr.title]="item.name">{{ item.name }}:</div>
            </td>
            <td class="value">
              <div [attr.title]="item.price | rate: item.currency">{{ item.price | rate: item.currency }}</div>
            </td>
          </tr>
        </table>

        <div
          *ngIf="showPopover && rates?.length > 3"
          class="btn-more-channels"
          matTooltip="Click here to view the full list of channel adjustments."
          matTooltipPosition="below"
          (click)="openPopover(popover)"
        >
          <i class="far fa-plus-circle"></i>
        </div>
      </ng-container>
      <div class="nights" *ngIf="defaultRate?.minimumstay">
        <span class="far fa-moon"></span>
        {{ defaultRate?.minimumstay }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #tmplRateTooltip>
  <app-rate-popover-v2 [date]="day" [rate]="defaultRate" [rental]="rental" [ratesByCategory]="ratesByCategory">
  </app-rate-popover-v2>
</ng-template>
