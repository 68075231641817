import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectHvmiWizardComponent } from './connect-hvmi-wizard.component'
import { ConnectHvmiWizardCompleteComponent } from './complete/connect-hvmi-wizard-complete.component'
import { ConnectHvmiWizardStep1Component } from './step1/connect-hvmi-wizard-step1.component'
import { ConnectHvmiWizardStep2Component } from './step2/connect-hvmi-wizard-step2.component'

import { SharedModule } from '@tv3/shared/shared.module'
import { HvmiListingDetailsOverlayService } from './hvmi-listing-details/hvmi-listing-details-overlay.service'
import { HvmiListingDetailsOverlayComponent } from './hvmi-listing-details/hvmi-listing-details-overlay.component'

const components = [
  // Hvmi
  ConnectHvmiWizardStep1Component,
  ConnectHvmiWizardStep2Component,
  ConnectHvmiWizardCompleteComponent,
  ConnectHvmiWizardComponent,
  HvmiListingDetailsOverlayComponent,
]

const services = [
  //
  HvmiListingDetailsOverlayService,
]

const pipes = []

@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components],
  providers: [...services],
  imports: [CommonModule, SharedModule, Tv3PlatformModule],
})
export class ConnectHvmiWizardModule {}
