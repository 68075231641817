import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'app-airbnb-listing-wizard-step4',
  templateUrl: './airbnb-listing-wizard-step4.component.html',
  styleUrls: ['./airbnb-listing-wizard-step4.component.scss'],
})
export class AirbnbListingWizardStep4Component implements OnInit {
  @Output() prev = new EventEmitter()
  @Output() done = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
