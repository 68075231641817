import { AmazingTimePickerModule } from '@oprokidnev/amazing-time-picker'
import { get, padStart } from 'lodash'
import { formatTime, parseTime } from './time-picker-utils'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { APTInputValidatorDirective } from './time-picker.validator'

const AtpCoreService = get(AmazingTimePickerModule, ['ɵinj', 'providers', '1'])

patchTimeFormat(AtpCoreService)

function patchTimeFormat(service: Function) {
  if (typeof service !== 'function') return

  patchFn(service, 'StringToTime', patchStringToTime)
  patchFn(service, 'TimeToString', patchTimeToString)
}

function patchStringToTime(fn: Function): Function {
  return function (time: any) {
    const m = parseTime(time)
    if (!m) return ''

    return fn(m.format('HH:mm'))
  }
}

function patchTimeToString(fn: Function): Function {
  return function (time: any) {
    if (!time) return ''

    const [hour, minute] = fn(time).split(':')

    return formatTime({ hour: Number(hour), min: Number(minute) })
  }
}

function patchFn(obj: Function, name: string, patch: (fn: Function) => Function) {
  const fn = obj.prototype[name]
  if (typeof fn !== 'function') return

  obj.prototype[name] = patch(fn)
}

@NgModule({
  imports: [
    //
    CommonModule,
    AmazingTimePickerModule,
  ],
  exports: [
    //
    AmazingTimePickerModule,
    APTInputValidatorDirective,
  ],
  declarations: [APTInputValidatorDirective],
})
export class TimePickerModule {}
