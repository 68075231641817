import { Component } from '@angular/core'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'

@Component({
  selector: 'app-default-route',
  template: `<app-default-route-indicator
    [checkIsDefault]="isDefault"
    [getDefaultRoutePayload]="getDefaultRoutePayload"
    tooltipPosition="below"
  ></app-default-route-indicator>`,
})
export class DefaultRouteComponent {
  route = window.location.pathname

  isDefault = (pref: UserPreferences) => {
    return pref.defaultRoute === this.route
  }

  getDefaultRoutePayload = (checked: boolean) => {
    return {
      default_route: checked ? this.route : '',
    }
  }
}
