import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { RentalsUnitedListingViewModel } from '@tokeet-frontend/channels'

import { DialogService } from '@tokeet-frontend/tv3-platform'
import { RUListingDetailsOverlayComponent } from './ru-listing-details-overlay.component'

@Injectable()
export class RUListingDetailsOverlayService {
  constructor(private dialog: DialogService) {}

  public open(listing: RentalsUnitedListingViewModel) {
    const defaultConfig: MatDialogConfig = {
      data: { listing },
      width: '700px',
      height: 'auto',
    }

    const dialogRef = this.dialog.openVariableDialog(RUListingDetailsOverlayComponent, defaultConfig)

    return dialogRef
  }
}
