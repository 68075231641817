import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { BDCListingWizardComponent } from './bdc-listing-wizard.component'
import { BDCListingWizardStep1Component } from './step1/bdc-listing-wizard-step1.component'
import { BDCListingWizardStep2Component } from './step2/bdc-listing-wizard-step2.component'
import { BDCListingWizardStep3Component } from './step3/bdc-listing-wizard-step3.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'

const components = [
  //
  BDCListingWizardStep1Component,
  BDCListingWizardStep2Component,
  BDCListingWizardStep3Component,
  BDCListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class BdcListingWizardModule {}
