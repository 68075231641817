import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AddNoteDialogComponent } from '@tv3/containers/inquiries/dialogs/notes/note/add-note-dialog/add-note-dialog.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Injectable()
export class AddNoteDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: any = { inquiry: Inquiry }) {
    let defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data,
    }

    return this.dialog.open(AddNoteDialogComponent, defaultConfig)
  }
}
