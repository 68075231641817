import { ChannelNameTokens, deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'
import { BDCContentPropertySettings } from '@tv3/store/channel-property-setting/settings/bdc-property-settings'
import { AgodaContentPropertySettings } from '@tv3/store/channel-property-setting/settings/agoda-property-settings'
import { ExpediaContentPropertySettings } from '@tv3/store/channel-property-setting/settings/expedia-property-settings'

export class ChannelPropertySetting extends Serializable<ChannelPropertySetting> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'rental_id' })
  rentalId: string // required,  rental id

  channel: ChannelNameTokens // required, channel name
  @Expose({ name: 'property_id' })
  propertyId: any // optional, channel property id

  @Expose({ name: 'listing_id' })
  listingId: any // optional, channel property id

  attributes: BDCContentPropertySettings | ExpediaContentPropertySettings | AgodaContentPropertySettings // optional, channel related data

  static deserialize(data: object): ChannelPropertySetting {
    return deserialize(ChannelPropertySetting, data)
  }
}
export interface ChannelPropertySettingPayload {
  rental_id?: string
  channel?: string
  listing_id?: string
  property_id?: string
  attributes?: any
}
