import { adapter, initialState } from './los-discount.state'
import { createReducer, on } from '@ngrx/store'
import {
  AddLosDiscountComplete,
  DeleteLosDiscountComplete,
  DeleteMultipleLosDiscountComplete,
  GetLosDiscountComplete,
  LoadLosDiscountsComplete,
} from './los-discount.actions'
import { upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadLosDiscountsComplete, (state, { items, reset }) => {
    if (reset) state = adapter.removeAll(state)
    return upsertMany(adapter, items, { ...state, isLoaded: true })
  }),
  on(AddLosDiscountComplete, (state, { item }) => adapter.addOne(item, state)),
  on(GetLosDiscountComplete, (state, { item }) => adapter.setOne(item, state)),
  on(DeleteLosDiscountComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteMultipleLosDiscountComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
