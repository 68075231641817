<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/ru/tokeet-ru.png" alt="" height="45" />
        <div>
          <h6 class="mb-0">Rentals United</h6>
          <div class="text-sm">
            Connect multiple Rentals United accounts and sync bookings, prices, guest messages, and more instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3">
        <div class="text-semi-bold">Getting Started</div>
        <p>Welcome to the Rentals United connection wizard! Please read this carefully before proceeding.</p>

        <div class="text-semi-bold">How do I get started?</div>
        <p>Enter your Rentals United email and password.</p>

        <div class="d-flex gap-3" [formGroup]="form" *ngIf="showCredsForm">
          <mat-form-field2 class="mat-block">
            <input type="email" matInput formControlName="email" placeholder="Email" autocomplete="off" required />
            <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
              Email is required
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('email')"> Email is invalid</mat-error>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <input
              type="password"
              matInput
              formControlName="password"
              placeholder="Password"
              autocomplete="off"
              required
            />
            <mat-error *ngIf="form.get('password').touched && form.get('password').hasError('required')">
              Password is required
            </mat-error>
          </mat-form-field2>
          <button
            class="btn btn-secondary-info align-input-row-end"
            (click)="onConnect(form)"
            [disabled]="isConnecting"
          >
            <i class="cs-icon cs-icon-link" *ngIf="!isConnecting"></i>
            <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
            Connect
          </button>
        </div>
        <div class="mb-3 d-flex gap-3">
          <button
            class="btn btn-secondary-warning"
            (click)="onChangeCreds()"
            *ngIf="status?.connected && !showCredsForm"
          >
            <i class="far fa-key"></i>
            Update Credentials
          </button>
          <button (click)="onDisconnect()" class="btn btn-secondary-danger" type="button" *ngIf="status?.connected">
            <i class="fcs-icon cs-icon-unlink"></i>
            Disconnect
          </button>
        </div>

        <div class="alert alert-secondar-warning mb-3" *ngIf="isCredentialsWrong">
          <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
          <div class="alert-content">
            Your credentials are invald. Please update Rentals United credentials before moving on to the next step.
          </div>
        </div>

        <div class="alert alert-secondary-warning2 align-items-start">
          <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
          <div class="alert-content">
            <div class="text-semi-bold">Important Notes</div>
            You must have a Rentals United account to connect to your AdvanceCM account. For a detailed guide, click
            here for a step-by-step guide.
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
          <button class="btn btn-secondary-info" (click)="next.emit()" [disabled]="!status?.connected || isConnecting">
            Continue
            <i class="cs-icon cs-icon-arrow-right-long text-xs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/ru/step1.png" alt="" class="w-100" />
  </div>
</div>
