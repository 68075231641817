import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { isSomething, selectSome } from '@tokeet-frontend/tv3-platform'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { combineLatest, from, Observable } from 'rxjs'
import * as R from 'ramda'
import { NgxPermissionsService } from 'ngx-permissions'
import { selectActiveTokeetPlanView } from '@tv3/store/plan/plan.selectors'

@Injectable({ providedIn: 'root' })
export class RoutingService {
  constructor(private store: Store<any>, private permissions: NgxPermissionsService) {}

  getDefaultRoute(): Observable<string> {
    return combineLatest([this.isFirstPaid(), this.isTrial()]).pipe(
      map((r) => R.any((i) => i, r)),
      filter((result) => isSomething(result)),
      take(1),
      switchMap((show) => from(this.permissions.hasPermission('canSeeDashboard')).pipe(map((allow) => allow && show))),
      map((show) => (show ? '/dashboard' : '/'))
    )
  }

  isTrial() {
    return this.store.pipe(
      selectSome(selectActiveTokeetPlanView),
      map((sub) => {
        return sub?.isTrial
      })
    )
  }

  isFree() {
    return this.store.pipe(
      selectSome(selectActiveTokeetPlanView),
      map((sub) => {
        return sub?.isFree
      })
    )
  }

  isFirstPaid() {
    return this.store.pipe(
      selectSome(selectActiveTokeetPlanView),
      map((sub) => {
        if (sub?.isTrial || sub?.status !== 'active' || !sub?.trialEnd) {
          return false
        }

        if (sub?.status === 'active') {
          return sub.start < sub.trialEnd + 24 * 30 * 3600
        }

        return false
      })
    )
  }

  isDelinquent() {
    return this.store.pipe(
      select(selectActiveTokeetPlanView),
      map((sub) => sub?.isDelinquent)
    )
  }
}
