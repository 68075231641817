import * as moment from 'moment'
import { EventEmitter, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { GuestResponse } from '@tv3/interfaces/responses/guest.response'
import { Guest, GuestBooking, GuestInteraction, GuestNote } from './guest.model'
import { EMPTY, from, Observable, of } from 'rxjs'
import { deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '../state'
import { Store } from '@ngrx/store'
import { catchError, flatMap, map, mergeMap, tap, toArray } from 'rxjs/operators'
import { statusKey } from '@tv3/constants/guests'
import { GuestNoteRequest } from '@tv3/interfaces/requests/guest-note-request'
import { GuestNoteResponse } from '@tv3/interfaces/responses/guest-note-response'
import { GuestBookingResponse } from '@tv3/interfaces/responses/guest-booking-response'
import { GuestInteractionRequest } from '@tv3/interfaces/requests/guest-interaction-request'
import { GuestFileRequest } from '@tv3/interfaces/requests/guest-file.request'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { Message } from '@tv3/store/message/message.model'
import * as R from 'ramda'
import { Pagination } from '@tv3/interfaces/table/pagination'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  onAddGuest$ = new EventEmitter<Guest>()

  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private toast: Toaster) {}

  get(id: string): Observable<Guest> {
    const url = `@api/guest/${id}`

    return this.http.get<GuestResponse>(url).pipe(
      map((response) => Guest.deserialize(response)),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getAll(pagination: Pagination): Observable<{ guests: Guest[]; pagination: Pagination }> {
    const url = `@api/guest/all?field_sort=lastmessage&sort=-1&limit=${pagination.limit + 1}&skip=${pagination.skip}`

    return this.http.get<GuestResponse[]>(url).pipe(
      map((resp) =>
        resp.map((item) => {
          if (typeof item.lastmessage === 'string') {
            const m = moment(item.lastmessage)
            if (m.isValid()) {
              item.lastmessage = m.unix()
            }
          }
          return item
        })
      ),
      deserializeArray<Guest>(Guest),
      map((guests) => ({
        guests: R.take(1000, guests),
        pagination: {
          ...pagination,
          skip: pagination.skip + pagination.limit,
          hasMore: guests.length > pagination.limit,
        },
      }))
    )
  }

  getGuests(): Observable<Guest[]> {
    const url = `@api/guest/all`

    return this.http.get<GuestResponse[]>(url).pipe(deserializeArray<Guest>(Guest))
  }

  fetch(ids: string[]): Observable<Guest[]> {
    if (R.isEmpty(ids)) {
      return of([])
    }
    const url = `@api/guest/many/`

    return this.http.post<GuestResponse[]>(url, { pkey: ids }).pipe(deserializeArray<Guest>(Guest))
  }

  search(name: string, limit = 20, skip = 0): Observable<Guest[]> {
    const url = `@api/guest/all/?limit=${limit}&skip=${skip}&name=${name}`

    return this.http.get<GuestResponse[]>(url).pipe(deserializeArray<Guest>(Guest))
  }

  create(request: UpdateGuestRequest): Observable<Guest> {
    const url = `@api/guest/`

    return this.http.post<GuestResponse>(url, request).pipe(
      tap(() => this.toast.success('Guest created successfully!')),
      map((response) => Guest.deserialize(response))
    )
  }

  createMany(request: UpdateGuestRequest[], message?: string): Observable<Guest[]> {
    const url = `@api/guests/`

    return this.http.post<GuestResponse[]>(url, request).pipe(
      deserializeArray<Guest>(Guest),
      tap(() => message && this.toast.success(message))
    )
  }

  update(guestId: string, request: UpdateGuestRequest, message?: string, silent = false): Observable<Guest> {
    const url = `@api/guest/update/${guestId}`

    return this.http.put(url, request).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => {
        if (!silent) {
          this.toast.success(message ? message : 'Guest updated successfully!')
        }
      })
    )
  }

  updateFiles(guestId: string, files: GuestFileRequest[]): Observable<Guest> {
    const url = `@api/guest/update/${guestId}`
    const request = { files: files }

    return this.http.put(url, request).pipe(map((response) => Guest.deserialize(response)))
  }

  deleteEmail(guestId: string, request: UpdateGuestRequest): Observable<Guest> {
    const url = `@api/guest/remove/email/${guestId}`

    return this.http.put(url, request).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => this.toast.success('Guest Email deleted successfully!'))
    )
  }

  addEmail(guestId: string, request: UpdateGuestRequest, noMessage?: boolean, message?: string): Observable<Guest> {
    const url = `@api/guest/add/email/${guestId}`

    return this.http.put(url, request).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => !noMessage && this.toast.success(message ? message : 'Guest Email added successfully!'))
    )
  }

  deletePhone(guestId: string, request: UpdateGuestRequest): Observable<Guest> {
    const url = `@api/guest/remove/phone/${guestId}`

    return this.http.put(url, request).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => this.toast.success('Guest Phone deleted successfully!'))
    )
  }

  addPhone(guestId: string, request: UpdateGuestRequest, noMessage?: boolean, message?: string): Observable<Guest> {
    const url = `@api/guest/add/phone/${guestId}`

    return this.http.put(url, request).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => !noMessage && this.toast.success(message ? message : 'Guest Phone added successfully!'))
    )
  }

  delete(guestId: string): Observable<Guest> {
    const url = `@api/guest/delete/${guestId}`

    return this.http.delete(url).pipe(
      map(Guest.deserialize),
      tap(() => this.toast.success('Guest deleted successfully!'))
    )
  }

  removeGuests(guestIds: string[]): Observable<string[]> {
    return of(guestIds).pipe(
      flatMap((guestId) => from(guestId)),
      mergeMap((guestId) => this.http.delete(`@api/guest/delete/${guestId}`).pipe(map(() => guestId))),
      toArray(),
      tap(() => this.toast.success('Guests deleted successfully!'))
    )
  }

  archive(guestId: string): Observable<Guest> {
    const url = `@api/guest/archive/${guestId}`

    return this.http.put<object>(url, {}).pipe(map(Guest.deserialize))
  }

  unarchive(guestId: string): Observable<Guest> {
    const url = `@api/guest/unarchive/${guestId}`

    return this.http.put<object>(url, {}).pipe(map(Guest.deserialize))
  }

  getStatus(guest: Guest): string {
    if (!!guest.archived) {
      // archived
      return statusKey.archived // need to move this to constants
    }
    if (guest.bookings && guest.bookings.length > 0) {
      return statusKey.booked
    }
    if (!guest.bookings || guest.bookings.length === 0) {
      return statusKey.unbooked
    }

    return null
  }

  createNote(guestId: string, note: string): Observable<GuestNote> {
    const url = `@api/guest/note/${guestId}`
    const data: GuestNoteRequest = { note: note }

    return this.http.post<GuestNoteResponse>(url, data).pipe(
      map(GuestNote.deserialize),
      tap(() => this.toast.success('Note created successfully!'))
    )
  }

  updateNote(guestId: string, request: GuestNoteRequest): Observable<GuestNote> {
    const url = `@api/guest/note/update/${guestId}`

    return this.http.put<GuestNoteResponse>(url, request).pipe(
      map(GuestNote.deserialize),
      tap(() => this.toast.success('Note updated successfully!'))
    )
  }

  deleteNote(guestId: string, payload: GuestNoteRequest): Observable<GuestNote> {
    const url = `@api/guest/note/delete/${guestId}`

    const request = {
      note: payload.note,
      key: payload.key,
      timestamp: payload.timestamp,
      user: payload.user,
    } as GuestNoteRequest

    return this.http.put<GuestNoteResponse>(url, request).pipe(
      map(GuestNote.deserialize),
      tap(() => this.toast.success('Note deleted successfully!'))
    )
  }

  getEmails(guestId: string, limit = 100, skip = 0): Observable<Message[]> {
    const url = `@api/guest/emails/${guestId}?limit=${limit}&skip=${skip}&include_draft=1`

    return this.http.get(url).pipe(deserializeArray<Message>(Message))
  }

  getBookings(guestId: string, limit = 100, skip = 0): Observable<GuestBooking[]> {
    const url = `@api/booking/all/guest/${guestId}?limit=${limit}&skip=${skip}&include_draft=1`

    return this.http.get<GuestBookingResponse[]>(url).pipe(deserializeArray<GuestBooking>(GuestBooking))
  }

  createInteraction(guestId: string, request: GuestInteractionRequest): Observable<GuestInteraction> {
    const url = `@api/guest/interaction/${guestId}`

    return this.http.post<GuestInteraction>(url, request).pipe(
      map(GuestInteraction.deserialize),
      tap(() => this.toast.success('Interaction created successfully!'))
    )
  }

  editInteractions(guestId: string, interactions: GuestInteraction[], remove?: boolean): Observable<Guest> {
    const url = `@api/guest/update/${guestId}`

    return this.http.put<Guest>(url, { interaction: interactions }).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => this.toast.success(`Interaction ${remove ? 'deleted' : 'updated'} successfully!`))
    )
  }

  addTagsToGuest(guest: Guest, tags: string[]) {
    const url = `@api/guest/update/${guest.id}`

    return this.http.put<GuestResponse>(url, { tags }).pipe(
      map((response) => Guest.deserialize(response)),
      tap(() => this.toast.success('Guest tags updated successfully'))
    )
  }
}
