import { Pipe, PipeTransform } from '@angular/core'
import { getInvoiceStatus, InvoiceStatus } from '@tokeet-frontend/invoices'

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  transform(invoiceStatus: InvoiceStatus): string {
    return getInvoiceStatus(invoiceStatus)
  }
}
