<mat-form-field2 [showLabel]="!inline">
  <app-select
    [items]="availableTags"
    [multiple]="true"
    [searchable]="true"
    [formControl]="ctrl"
    [placeholder]="placeholder"
    (selectionChange)="onSelectChange($event)"
    (openedChange)="openedChange.emit($event)"
  >
  </app-select>
</mat-form-field2>
