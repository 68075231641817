import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Account, BillingAddress } from './account.models'
import { AccountForm } from './account.interfaces'
import { TaxV3 } from '../rental/rental.models'

export const loadAccount = createAction('[Account] Load Account')
export const loadAccountComplete = createAction('[Account] Load Account Complete', props<{ account: Account }>())

export const updateAccount = createAction(
  '[Account] Update Account',
  props<{ account: AccountForm; updateAll?: boolean }>()
)
export const updateAccountComplete = createAction(
  '[Account] Update Account Complete',
  props<{ update: Update<Account> }>()
)

export const toggleAccountMFA = createAction('[Account] Toggle Account MFA', props<{ enable_2fa: 0 | 1 }>())
export const toggleAccountMFAComplete = createAction(
  '[Account] Toggle Account MFA Complete',
  props<{ update: Update<Account> }>()
)

export const updateAccountBillingAddresses = createAction(
  '[Account] Update Account Billing Addresses',
  props<{ addresses: BillingAddress[] }>()
)
export const updateAccountBillingAddressesComplete = createAction(
  '[Account] Update Account Billing Addresses Complete',
  props<{ update: Update<Account> }>()
)

export const setAccountTaxes = createAction('[Account] Set Taxes', props<{ taxes: TaxV3[] }>())
export const setAccountTaxexComplete = createAction(
  '[Account] Set Taxes Complete',
  props<{ update: Update<Account> }>()
)

export const updateDomain = createAction('[Account] Update Domain', props<{ domain: string; noConfirm?: boolean }>())
