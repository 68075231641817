import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, of } from 'rxjs'
import { concatMap, map, mapTo, startWith, switchMap, tap, toArray } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as R from 'ramda'
import * as fromRoot from '@tv3/store/state'
import {
  ActionFailed,
  DataCheckerService,
  deleteRateMapping,
  deleteRateMappings,
  isEmptyTable,
  isSomething,
  localeCompareSort,
  RateMappingResponse,
  SelectableRow,
  selectAllApiChannelViews,
  selectAllRateMappings,
  selectRentalRateMappings,
  setRateMappingsLastSync,
  toggleRateMappingsSync,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import {
  AlertDialogService,
  ChannelNameTokens,
  ConfirmDialogService,
  InfoDialogService,
  Rental,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { AddRateMappingDialogService } from '../add-rate-mapping-dialog/add-rate-mapping-dialog.service'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { selectAllAPIConnectionViews } from '@tv3/store/connection/connection.selectors'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { FormBuilder } from '@angular/forms'
import { getRealChannelId } from '@tv3/store/connection/connection.model'
import * as moment from 'moment'
import { ConnectionGuard } from '@tv3/guards/connection.guard'

const HIDE_PUSH_RATES_TIP_KEY = 'HIDE_PUSH_RATES_TIP_KEY'

@Component({
  selector: 'app-rental-rate-mappings-table',
  templateUrl: './rental-rate-mappings-table.component.html',
  styleUrls: ['./rental-rate-mappings-table.component.scss'],
})
export class RentalRateMappingsTableComponent extends SelectableRow<RateMappingResponse> implements OnInit, OnChanges {
  @Input() defaultRental?: Rental

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  filters = this.fb.group({
    search: [],
    channels: [[]],
    rentals: [[]],
  })

  channels$ = this.store.pipe(select(selectAllApiChannelViews))

  displayedColumns = [
    'select',
    'channelName',
    'propertyId',
    'roomId',
    'ratename',
    'category',
    'created',
    'autosync',
    'edit',
  ]

  tableType = TableType.RentalRateMappings
  isEmptyTable$ = isEmptyTable(this.dataSource)
  connections: ConnectionView[]

  private refresh$ = new BehaviorSubject(true)

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private toaster: Toaster,
    storage: UserStorage,
    private infoDialog: InfoDialogService,
    private alertDialog: AlertDialogService,
    private confirmDialog: ConfirmDialogService,
    private connectionService: ConnectionService,
    private addRateMappingDialog: AddRateMappingDialogService,
    dataChecker: DataCheckerService
  ) {
    super(storage)
    dataChecker.check([ConnectionGuard])
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.store.pipe(select(selectAllAPIConnectionViews), untilDestroy(this)).subscribe((connections) => {
      this.connections = connections
    })

    this.refresh$
      .pipe(
        switchMap(() =>
          this.store.pipe(
            select(this.defaultRental ? selectRentalRateMappings(this.defaultRental.id) : selectAllRateMappings)
          )
        ),
        switchMap((items) =>
          this.filters.valueChanges.pipe(
            startWith(this.filters.getRawValue()),
            map((filters) => this.filterItems(items, filters))
          )
        ),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.dataSource.data = items
      })
  }

  rateMapHasConnection(map: RateMappingResponse) {
    const found = R.find((c: ConnectionView) => {
      return (
        c.roomId == map.roomId &&
        c.propertyId == map.propertyId &&
        map.rental_id === c.rentalId &&
        map.channel_id === c.channelId
      )
    }, this.connections)

    return !R.isNil(found)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh$.next(true)
  }

  filterItems(items: RateMappingResponse[], filters: { rentals: string[]; channels: string[] }) {
    return lodash
      .chain(items)
      .filter((t) => {
        if (!isSomething(filters.rentals)) return true
        return filters.rentals.includes(t.rental_id)
      })
      .filter((t) => {
        if (!isSomething(filters.channels)) return true
        return filters.channels.includes(getRealChannelId(t.channel_id))
      })
      .value()
  }

  onChangeSync(rateMapping: RateMappingResponse) {
    this.store.dispatch(
      toggleRateMappingsSync({
        items: [
          {
            rentalId: rateMapping.rental_id,
            mappingId: rateMapping.key,
            enabled: !rateMapping.autosync,
          },
        ],
      })
    )
  }

  onPushRatesSelected() {
    const selected = this.getSelected()
    this.pushRates(selected)
  }

  onPushRate(rateMapping: RateMappingResponse) {
    this.pushRates([rateMapping])
  }

  onEdit(item: RateMappingResponse) {
    this.addRateMappingDialog.open({ mapping: item })
  }

  onAdd() {
    this.addRateMappingDialog.open({
      rentalId: this.defaultRental?.id,
    })
  }

  onDelete(rateMapping: RateMappingResponse) {
    this.confirmDialog
      .confirm({
        title: 'Delete  Mapping?',
        body: 'Are you sure you want to delete this mapping?',
      })
      .subscribe(() => {
        this.store.dispatch(deleteRateMapping({ rentalId: rateMapping.rental_id, mappingId: rateMapping.key }))
      })
  }

  onDeleteSelected() {
    const selected = this.getSelected()
    this.confirmDialog
      .confirm({
        title: 'Delete Selected Mappings?',
        body: 'Are you sure you want to delete selected mappings?',
      })
      .subscribe(() => {
        this.store.dispatch(
          deleteRateMappings({ items: selected.map((t) => ({ rentalId: t.rental_id, mappingId: t.key })) })
        )
      })
  }

  private pushRates(rateMappings: RateMappingResponse[]) {
    const baseRate = this.defaultRental.baseRate
    if (!baseRate || !baseRate.nightly) {
      this.alertDialog.open({
        title: 'Warning',
        body: `A Base Rate must be set in order to properly push rates to your connected channels. Please navigate to the Settings Tab
          and enter the required values before returning here to re-attempt your rate push.`,
      })
      return
    }

    of(...rateMappings)
      .pipe(
        concatMap((rateMap) =>
          this.connectionService
            .pushRates(rateMap.api as ChannelNameTokens, rateMap.channel_id, rateMap)
            .pipe(mapTo(rateMap))
        ),
        toArray(),
        switchMap((mappings) => {
          this.store.dispatch(
            setRateMappingsLastSync({
              items: mappings.map((t) => ({ rentalId: t.rental_id, mappingId: t.key, date: moment().unix() })),
            })
          )
          const hideTip = this.storage.get(HIDE_PUSH_RATES_TIP_KEY, false)
          if (hideTip) {
            return of(true)
          }
          return this.infoDialog
            .open({
              title: 'Tips',
              body: `After pushing rates from AdvanceCM, it is recommended that you check the channel rate calendar(s) for accuracy. Errors
          returned by the channel do not appear immediately, so it is a good practice to check that your changes have reflected properly.`,
              extra: 'Do not show this message again.',
            })
            .pipe(tap(({ extra }) => this.storage.set(HIDE_PUSH_RATES_TIP_KEY, !!extra)))
        })
      )
      .subscribe(
        () => {
          this.toaster.info('Rate push scheduled.')
        },
        (error) => this.store.dispatch(ActionFailed({ error }))
      )
  }
}
