import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, ChannelNameTokens, Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import {
  ImportRentalsUnitedBookings,
  ImportRentalsUnitedBookingsSuccess,
  SetRentalsUnitedListingStatus,
  SetRentalsUnitedListingStatusSuccess,
} from '@tokeet-frontend/channels'
import * as lodash from 'lodash'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { Actions, ofType } from '@ngrx/effects'
import { debounce, debounceTime, switchMap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { BehaviorSubject } from 'rxjs'
import { ImportBookingsFromConnectionComplete, LoadConnection } from '@tv3/store/connection/connection.actions'
import { ApiConnectionStatusComponent } from '../../api/status/api-connection-status.component'
import * as moment from 'moment'
import { selectConnectionViewById } from '@tv3/store/connection/connection.selectors'

@Component({
  selector: 'app-ru-connection-overlay',
  templateUrl: './ru-connection-overlay.component.html',
  styleUrls: ['./ru-connection-overlay.component.scss'],
})
export class RentalsUnitedConnectionOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('connStatus') connStatus: ApiConnectionStatusComponent
  channel: Channel
  connection: ConnectionView
  dateFormat = 'DD-MMM-YYYY | h:mm A'

  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    switchMap(() => this.connectionService.getApiConnectionLog(this.channel.name, this.connection.propertyId))
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { connection: ConnectionView },
    private store: Store<any>,
    private connectionService: ConnectionService,
    private actions: Actions,
    public dialogRef: MatDialogRef<RentalsUnitedConnectionOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.connection = this.data.connection
    this.channel = this.data.connection.channel
    this.onRefreshConnection()

    this.store
      .pipe(select(selectConnectionViewById, { id: this.connection.id }), untilDestroy(this))
      .subscribe((conn) => {
        this.connection = conn
      })

    this.actions
      .pipe(ofType(ImportRentalsUnitedBookingsSuccess), debounceTime(1000), untilDestroy(this))
      .subscribe(() => {
        this.onRefreshConnection()
        this.refreshLogs$.next(true)
        this.store.dispatch(
          ImportBookingsFromConnectionComplete({
            update: { id: this.connection.id, changes: { lastavailpull: moment.utc().unix() } },
          })
        )
        setTimeout(() => {
          this.connStatus.refreshStatus()
        }, 100)
      })

    this.actions.pipe(ofType(SetRentalsUnitedListingStatusSuccess), untilDestroy(this)).subscribe(() => {
      this.onRefreshConnection()
    })
  }

  close() {
    this.dialogRef.close()
  }

  onRefreshConnection() {
    this.store.dispatch(
      LoadConnection({
        channelName: ChannelNameTokens.RentalsUnited,
        roomId: this.connection.roomId,
        propertyId: this.connection.propertyId,
      })
    )
  }

  onToggleStatus() {
    this.store.dispatch(
      SetRentalsUnitedListingStatus({
        rentalId: this.connection.rentalId,
        propertyId: this.connection.propertyId as number,
        status: !this.connection.status,
      })
    )
  }

  onImportBookings() {
    this.store.dispatch(
      ImportRentalsUnitedBookings({
        rentalId: this.connection.rentalId,
        propertyId: this.connection.propertyId as number,
      })
    )
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
