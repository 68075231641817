import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './account.reducer'
import * as state from './account.state'
import { Account } from './account.models'

export const accountState = createFeatureSelector<state.State>('account')

export const selectAccountEntities = createSelector(accountState, reducer.selectEntities)
export const selectAllAccounts = createSelector(accountState, reducer.selectAll)

export const selectAccount = (account: number) =>
  createSelector(selectAccountEntities, (entities) => new Account(entities[account]))

export const selectCurrentAccount = createSelector(selectAllAccounts, (accounts) =>
  accounts.length ? new Account(accounts[0]) : null
)

export const selectAccountTaxes = createSelector(selectCurrentAccount, (account) => {
  return account?.attributes?.taxes || []
})

export const selectAccountsLoaded = createSelector(accountState, (state: state.State) => state.isLoaded)

export const selectIsAccountMFAEnabled = createSelector(selectCurrentAccount, (a) => !!a?.enable_2fa)
