import { AbstractControl, Validators } from '@angular/forms'
import * as lodash from 'lodash'

const isPositiveInteger = Validators.pattern(/^([1-9][0-9]*)$/)
const isInteger = Validators.pattern(/^(0|\-?[1-9][0-9]*)$/)

export const integer = () => {
  return (control: AbstractControl) => {
    const value = control.value
    if (lodash.isNil(value)) {
      return
    }
    if (isInteger(control)) {
      return { integer: true }
    }
  }
}

export const numberStepValidator = (step: number) => {
  return (control: AbstractControl) => {
    const value = control.value
    if (lodash.isNil(value)) {
      return
    }
    if (value % step != 0) {
      return { step: true }
    }
  }
}
