<div class="d-flex gap-3">
  <div style="max-width: 500px">
    <div
      matTooltip="Update the logo that displays on your invoices."
      matTooltipPosition="above"
      class="logo-container clickable"
      *ngIf="preference?.branding?.invoiceLogo; else logoUpload"
      (click)="onEditLogo()"
    >
      <img [src]="preference.branding.invoiceLogo" alt="Logo" class="invoice-logo" />
    </div>
    <ng-template #logoUpload>
      <app-upload-box
        [options]="logoUploadBoxOptions"
        image="/assets/images/upload-formate.png"
        [showFileList]="false"
        (complete)="onLogoUploaded($event)"
      ></app-upload-box>
    </ng-template>
  </div>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="d-flex align-items-start gap-3">
      <mat-form-field2 matTooltip="Select invoice date" matTooltipPosition="above">
        <app-mat-date-input
          formControlName="date"
          placeholder="Invoice Date"
          [max]="maxInvoiceDate.value || maxDateDefault"
          [min]="minDateDefault"
          [required]="true"
        ></app-mat-date-input>

        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('date').hasError('max')"> Invoice Date must be before Due Date </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="" matTooltip="Select invoice due date" matTooltipPosition="above">
        <app-mat-date-input
          formControlName="due"
          placeholder="Due Date"
          [showTime]="true"
          [min]="minDueDate.value || minDateDefault"
          [max]="maxDateDefault"
          [required]="true"
        ></app-mat-date-input>

        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('due').hasError('min')"> Due Date must be after Invoice Date </mat-error>
        <mat-error *ngIf="form.get('due').hasError('matDatepickerMin')">
          Min value is <strong>{{ form.get('due').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2
        [matTooltip]="
          form.get('due').value
            ? 'Invoice will expire on this date. If Expires Date is not set invoice will expire after one month of Due Date.'
            : 'Please add Due Date first to enable this field'
        "
        matTooltipPosition="above"
      >
        <app-mat-date-input
          formControlName="expires"
          placeholder="Expires Date"
          [min]="minExpiresDate$.value"
          [max]="maxExpiresDate$.value"
        ></app-mat-date-input>
        <mat-label>Expires Date <i class="fal fa-question-circle"></i></mat-label>
        <i matPrefix class="cs-icon cs-icon-calendar"></i>
        <mat-error *ngIf="form.get('expires').hasError('max')">
          Expiry Date cannot be more than 90 days in future from due date
        </mat-error>
        <mat-error *ngIf="form.get('expires').hasError('min')"> Expiry Date must be after Due Date </mat-error>
        <mat-error *ngIf="form.get('expires').hasError('matDatepickerMin')">
          Min value is
          <strong>{{ form.get('expires').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
        </mat-error>
      </mat-form-field2>
    </div>
  </form>
  <div class="ml-5">
    <h6 class="d-flex align-items-center gap-2">
      <span class="text-info text-lg text-bold">
        {{ guest?.name }}
      </span>
      <span class="badge badge-secondary-info badge-circle badge-md text-xxs cursor-pointer" (click)="onEdit(guest)">
        <i class="cs-icon cs-icon-arrow-top-right"></i>
      </span>
      <a
        matTooltip="View associated guest"
        matTooltipPosition="above"
        *ngIf="!(1 | isReadonlyRole)"
        (click)="onEditGuest()"
        class="text-dark"
      >
        <i class="cs-icon cs-icon-pencil"></i>
      </a>
    </h6>
    <app-guest-invoice-info [guest]="guest" class="text-light"></app-guest-invoice-info>
  </div>
  <div class="ml-5">
    <h6 class="d-flex align-items-center gap-2">
      <span class="text-bold"> Billing Address </span>
      <a
        matTooltip="Edit the address of your AdvanceCM account. This will change the address for all future invoices."
        matTooltipPosition="above"
        (click)="addressInfo.onEditAddress()"
        *ngIf="!(1 | isReadonlyRole)"
        class="text-dark"
      >
        <i class="cs-icon cs-icon-pencil"></i>
      </a>
    </h6>
    <app-account-invoice-info
      [account]="account"
      [invoice]="invoice"
      (address)="onAddressChange($event)"
      #addressInfo
      class="text-light"
    ></app-account-invoice-info>
  </div>
</div>
