import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { RateAutomationEffects } from './effects'
import { RateAutomationService } from './service'
import { rateAutomationReducer } from './reducer'
import { RateAutomationsGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('rate-automation', rateAutomationReducer),
    EffectsModule.forFeature([RateAutomationEffects]),
  ],
  declarations: [],
  providers: [RateAutomationService, RateAutomationsGuard],
  exports: [],
})
export class RateAutomationStoreModule {}
