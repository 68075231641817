import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { FileUploadModule } from 'ng2-file-upload'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button'
import { MatRippleModule, MatPseudoCheckboxModule } from '@angular/material/core'
import { MatStepperModule } from '@angular/material/stepper'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatChipsModule } from '@angular/material/chips'

import {
  NgbDatepickerModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSelectModule } from '@angular/material/select'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { OverlayModule } from '@angular/cdk/overlay'
import { ObserversModule } from '@angular/cdk/observers'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatRadioModule } from '@angular/material/radio'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { ClipboardModule } from 'ngx-clipboard'

import { EditorModule } from '@tinymce/tinymce-angular'

import { storeModules, storeServices } from './stores'
import { services } from './services'
import { components } from './components'
import { pipes } from './pipes'
import { directives } from './directives'
import { dialogServices, dialogs } from './dialogs'
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort'
import { MatPaginatorModule } from '@angular/material/paginator'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { storageServices } from './storage/index'
import { formComponents } from './form/index'
import { tableComponents } from './table'
import { TimePickerModule } from './components/time-picker'

import { PermissionModule } from '@tokeet-frontend/permission'

const tv3Directives = [
  //
  ...directives,
]

const tv3Pipes = [
  //
  ...pipes,
]

const tv3Components = [
  //
  ...components,
  ...dialogs,
  formComponents,
  tableComponents,
]

const tv3Services = [
  //
  ...dialogServices,
  //
  ...storeServices,
  //
  ...services,
  //
  storageServices,
]

const tv3Modules = [
  FormsModule,
  ReactiveFormsModule,
  //
  MatFormFieldModule,
  MatCheckboxModule,
  MatInputModule,
  MatTooltipModule,
  MatButtonModule,
  MatIconModule,
  MatRippleModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatPseudoCheckboxModule,
  MatSelectModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatChipsModule,

  MatExpansionModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatStepperModule,
  MatProgressBarModule,
  // material CDK
  ScrollingModule,
  OverlayModule,
  DragDropModule,
  ObserversModule,

  //
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbPopoverModule,
  NgbProgressbarModule,
  BsDropdownModule,
  PopoverModule,
  TimePickerModule,

  //
  EditorModule,
  NgSelectModule,
  FileUploadModule,
  ClipboardModule,
  //
  ...storeModules,

  //
  PermissionModule,
]

@NgModule({
  imports: [
    //
    CommonModule,
    ...tv3Modules,
  ],
  declarations: [
    //
    ...tv3Pipes,
    ...tv3Directives,
    ...tv3Components,
  ],
  providers: [
    //
    ...tv3Services,
    ...tv3Pipes,
  ],
  exports: [
    //
    ...tv3Modules,
    ...tv3Pipes,
    ...tv3Components,
    ...tv3Directives,
  ],
})
export class Tv3PlatformModule {}
