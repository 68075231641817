import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SignaturePlanPrice } from './signature-pricing'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CustomContractSigner } from './signature.model'

@Injectable()
export class SignatureService {
  constructor(private http: HttpClient) {}

  getSignaturePrices() {
    const url = '@api/plans/price/signature'
    return this.http.get<{ tiers: SignaturePlanPrice[] }>(url)
  }

  getUsage(account: number, stripeId: string): Observable<number> {
    const url = '@api/subscription/signature/current-usage'
    return this.http
      .post<{ usage: number }>(url, { account, stripe_id: stripeId })
      .pipe(map((data) => data?.usage || 0))
  }

  sendContractViaEmail(templateId: string, signers: CustomContractSigner[]) {
    const url = '@api/apisignature/contract/email'
    return this.http
      .post<{ response: { contract_id: string } }>(url, { template_id: templateId, signers })
      .pipe(map((res) => res.response))
  }

  getContractSignLink(contractId: string, role: string) {
    const url = `@api/apisignature/contract/signlink?contract_id=${contractId}`
    return this.http.post<{ response: { link: string } }>(url, { role }).pipe(map((res) => res.response))
  }
}
