import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { AppOverviewDialogComponent } from './app-overview-dialog.component'

@Injectable()
export class AppOverviewDialogService {
  constructor(private dialog: DialogService) {}

  open(product: string) {
    const defaultConfig: MatDialogConfig = {
      width: '850px',
      height: 'auto',
      data: { product },
    }
    return this.dialog.openVariableDialog(AppOverviewDialogComponent, defaultConfig)
  }
}
