import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Pipe({
  name: 'isThreadChecked',
})
export class IsThreadCheckedPipe implements PipeTransform {
  transform(thread: Inquiry, selected: Inquiry[]): boolean {
    return !R.isNil(R.find((i: Inquiry) => i.id === thread.id, selected))
  }
}
