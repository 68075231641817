import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { concatMap, map, switchMap, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import { Invoice } from './invoice.model'
import { deserializeArray, isSomething } from '@tokeet-frontend/tv3-platform'
import { InvoiceService as SingleInvoiceService } from '@tv3/store/invoice/invoice.service'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { PreferenceService } from '@tv3/store/preferences/preference.service'

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private http: HttpClient,
    private invoice: SingleInvoiceService,
    private inquiries: InquiryService,
    private preferenceService: PreferenceService
  ) {}

  add(payload): Observable<Invoice> {
    const url = `@api/invoice`
    return this.http.post(url, payload).pipe(
      map((response) => {
        return Invoice.deserialize({ ...response, notes: payload.notes }) // TV3-2752 - backend doesn't return notes
      })
    )
  }

  get(invoiceId: string): Observable<Invoice> {
    const url = `@api/invoice/${invoiceId}`

    return this.http.get(url).pipe(map((response) => Invoice.deserialize(response)))
  }

  fetch(ids: string[]): Observable<Invoice[]> {
    const url = `@api/invoice/all/`

    if (!isSomething(ids) || !R.is(Array, ids)) {
      return of([])
    }

    return this.http.post(url, { pkeys: ids }).pipe(deserializeArray<Invoice>(Invoice))
  }

  update(invoiceId: string, data): Observable<Invoice> {
    const url = `@api/invoice/update/${invoiceId}`
    return this.http.put(url, data).pipe(map((response) => Invoice.deserialize(response)))
  }

  status(invoiceId: string, status: 'paid' | 'unpaid' | 'cancel') {
    const url = `@api/invoice/${status}/${invoiceId}`
    return this.http.put(url, {}).pipe(map((response) => Invoice.deserialize(response)))
  }

  schedule(invoiceId: string, date: number) {
    const url = `@api/invoice/schedule/${invoiceId}`
    return this.http.put(url, { time: date })
  }

  refund(invoiceId: string) {
    const url = `@api/invoice/refunded/${invoiceId}`

    return this.http.put(url, {}).pipe(map((response) => Invoice.deserialize(response)))
  }

  archive(id: string): Observable<Invoice> {
    const url = `@api/invoice/archive/${id}`

    return this.http.put(url, {}).pipe(map((response) => Invoice.deserialize(response)))
  }
  archiveBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.archive(id)),
      toArray()
    )
  }

  unarchive(id: string): Observable<Invoice> {
    const url = `@api/invoice/unarchive/${id}`

    return this.http.put(url, {}).pipe(map((response) => Invoice.deserialize(response)))
  }
  unarchiveBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.unarchive(id)),
      toArray()
    )
  }

  delete(id: string) {
    const url = `@api/invoice/delete/${id}`

    return this.http.delete(url, {})
  }

  deleteBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }

  disablePayment(invoiceId) {
    const url = `@api/invoice/onlinepayment/disable/${invoiceId}`
    return this.http.put(url, { online_payment: 0 })
  }

  enablePayment(invoiceId) {
    const url = `@api/invoice/onlinepayment/enable/${invoiceId}`
    return this.http.put(url, { online_payment: 1 })
  }

  setGateway(invoiceId, gatewayId) {
    const url = `@api/invoice/gateway/${invoiceId}`
    return this.http.put(url, { gateway_id: gatewayId })
  }

  payWithSavedCard(invoiceId: string, total: number, description: string): Observable<Invoice> {
    const url = `@api/invoice/charge/${invoiceId}`
    return this.http.put(url, { total, description }).pipe(map((response) => Invoice.deserialize(response)))
  }

  newInvoicePayload(invoice: Invoice) {
    const invoiceEditableFields = [
      'currency',
      'due_date',
      'expires_at',
      'guest_id',
      'guest_name',
      'inquiry_id',
      'invoice_date',
      'invoice_items',
      'invoice_num',
      'notes',
      'payment_instructions',
      'payment_terms',
      'rental_id',
      'rental_name',
      'type',
      'address_key',
    ]

    const invoicePayload: any = R.pick(invoiceEditableFields, invoice.serialize())

    return {
      ...invoicePayload,
      invoice_date: invoicePayload.invoice_date,
      due_date: invoicePayload.due_date,
      expires_at: invoicePayload.expires_at,
    }
  }

  editInvoicePayload(invoice: Invoice) {
    const invoiceEditableFields = [
      'currency',
      'due_date',
      'expires_at',
      'guest_id',
      'guest_name',
      'invoice_date',
      'invoice_items',
      'notes',
      'payment_instructions',
      'payment_terms',
      'rental_name',
    ]

    return R.pick(invoiceEditableFields, invoice.serialize())
  }

  getGuestInvoice(invoice: Invoice) {
    const url = `@api/invoice/preview/guest/${invoice.created}/${invoice.invoiceNum}/${invoice.id}/${invoice.publicKey}`

    return this.http.get(url)
  }

  sendBatch(invoices: Invoice[]) {
    return this.preferenceService.get().pipe(
      switchMap((preferences) =>
        of(...invoices).pipe(
          concatMap((invoice) =>
            this.inquiries.load(invoice.inquiryId).pipe(map((inquiry) => ({ inquiry, invoice, preferences })))
          ),
          concatMap(({ inquiry, invoice, preferences }) => this.invoice.send(invoice, inquiry, preferences)),
          toArray()
        )
      )
    )
  }
}
