import { FormBuilder } from '@angular/forms'
import * as R from 'ramda'
import { Store } from '@ngrx/store'
import { FilterFormGroup, isSomething, IStorage } from '@tokeet-frontend/tv3-platform'
import { LosDiscountView } from './los-discount.model'
import { selectAllLosDiscountViews } from './los-discount.selectors'

export abstract class LosDiscountBaseFilter {
  defaultValues = {
    rentals: [],
    channels: [],
    type: undefined,
  }

  filters = {
    rentals: (items: LosDiscountView[], rentalIds: string[]) => {
      if (!isSomething(rentalIds)) {
        return items
      }
      return R.filter((item) => !!R.intersection(item.rentals || [], rentalIds || []).length, items)
    },
    channels: (items: LosDiscountView[], channels: string[]) => {
      if (!isSomething(channels)) {
        return items
      }
      return R.filter((item) => !!R.intersection(item.channels || [], channels || []).length, items)
    },
    type: (items: LosDiscountView[], type: string) => {
      if (!type || type === 'all') {
        return items
      }
      return R.filter((item) => item.type === type, items)
    },
  }

  group: FilterFormGroup<LosDiscountView>

  protected constructor(
    protected store: Store<any>,
    protected fb: FormBuilder,
    protected storage: IStorage,
    protected filterGroup: string
  ) {
    const storedFilters = this.storage.get(filterGroup, this.defaultValues)

    const group = this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      type: [storedFilters.type],
    })

    this.group = new FilterFormGroup<LosDiscountView>(
      group.controls,
      this.store,
      this.storage,
      filterGroup,
      selectAllLosDiscountViews,
      this.filters
    )
  }
}
