<mat-form-field2 class="mat-block" [showLabel]="!inline">
  <app-select
    [required]="required"
    [items]="languages"
    [searchable]="true"
    [formControl]="ctrl"
    bindValue="1"
    bindLabel="name"
    [placeholder]="label"
    [showBlankItem]="showBlankItem"
  >
  </app-select>
</mat-form-field2>
