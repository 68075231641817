import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class InquiryBilling extends Serializable<InquiryBilling> {
  @Expose({ name: 'exp_year' })
  expYear: number

  @Expose({ name: 'exp_month' })
  expMonth: number

  @Expose({ name: 'gateway_id' })
  gatewayId: string

  @Expose({ name: 'card_id' })
  cardId: string

  stripe: string
  gateway: string
  name: string
  cvc: number
  type: string
  currency: string
  zip: number
  last4: number

  static deserialize(data: object): InquiryBilling {
    return deserialize(InquiryBilling, data)
  }
}
