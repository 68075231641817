import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable } from 'rxjs'
import { catchError, map, mapTo, tap } from 'rxjs/operators'
import * as R from 'ramda'
import { ActivePlanStatus, PlanOption, PlanSubscription } from '@tv3/store/plan/plan.model'
import { deserializeArray, isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { environment } from '../../../environments/environment'
import { AuthService } from '@tv3/services/auth.service'
import { Card } from '@tokeet-frontend/billing'
import { TokeetPlanPrice } from '@tokeet-frontend/plans'
import { LoadActivePlans } from '@tv3/store/plan/plan.actions'

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  stripe

  constructor(
    private http: HttpClient,
    private toaster: Toaster,
    private authService: AuthService,
    private store: Store<fromRoot.State>
  ) {}

  handleFailedPayment(res: { clientSecret: string | null }) {
    if (isSomething(res.clientSecret)) {
      this.stripe.handleCardPayment(res.clientSecret).then((result) => {
        const { error } = result
        if (error) {
          if (error.type === 'card_error') {
            this.toaster.error(error.message)
          } else {
            this.toaster.error('Error authenticating card.')
          }
        }
        setTimeout(() => {
          this.store.dispatch(LoadActivePlans({ fresh: true }))
        }, 3000) // Stripe takes some time to update subscription status after invoice is payed
      })
    }
  }

  subscribeTokeetPlan(planId): Observable<PlanSubscription> {
    // @ts-ignore
    this.stripe = Stripe(environment.config.stripe_publishable_key)

    const url = '@api/subscribe'
    return this.http.post<{ subscription: any; clientSecret: string | null }>(url, { plan: planId }).pipe(
      tap((res) => this.handleFailedPayment(res)),
      map((res) => this.parsePlanSubscription(PlanSubscription.deserialize(res.subscription)))
    )
  }

  subscribeProductPlan(planId, product): Observable<PlanSubscription> {
    // @ts-ignore
    this.stripe = Stripe(environment.config.stripe_publishable_key)

    const url = `@api/subscribe?product=${product}`
    return this.http.post<{ subscription: any; clientSecret: string | null }>(url, { plan: planId }).pipe(
      tap((res) => this.handleFailedPayment(res)),
      map((res) => this.parsePlanSubscription(PlanSubscription.deserialize(res.subscription)))
    )
  }

  unsubscribe(id): Observable<PlanSubscription[]> {
    const url = `@api/unsubscribe/${id}`
    return this.http
      .delete<any[]>(url)
      .pipe(map((res) => R.map((s) => this.parsePlanSubscription(PlanSubscription.deserialize(s)), res)))
  }

  retryPayment() {
    const url = `@api/subscriptions/retry-payments`
    return this.http.post(url, {})
  }

  subscriptions(
    fresh = false
  ): Observable<{ subscriptions: PlanSubscription[]; card: Card; ended: PlanSubscription[] }> {
    const url = fresh ? '@api/subscribe/all/fresh' : '@api/subscribe/all'

    return this.http.get<{ subscriptions: any[]; card: any; ended: any[] }>(url).pipe(
      map((response) => {
        const activeSubs = R.map(
          (s) => this.parsePlanSubscription(PlanSubscription.deserialize(s)),
          response.subscriptions
        )

        const endedSubs = R.pipe(
          R.map((s: any) => this.parsePlanSubscription(PlanSubscription.deserialize(s))),
          R.sortBy((s: PlanSubscription) => -s.start),
          R.uniqBy((s: PlanSubscription) => s.product)
        )(response.ended)

        return {
          subscriptions: activeSubs,
          ended: endedSubs,
          card: response.card,
        }
      }),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  status(): Observable<ActivePlanStatus> {
    const url = '@api/subscription/status'

    return this.http.get<object>(url).pipe(map((res) => this.parseActivePlanStatus(ActivePlanStatus.deserialize(res))))
  }

  plansForTokeet(): Observable<PlanOption[]> {
    const url = '@api/subscription/plans'

    return this.http.get<PlanOption[]>(url).pipe(
      map((plans) => R.filter((s) => (s.id + '').indexOf('sympl') === -1, plans)),
      deserializeArray<PlanOption>(PlanOption),
      map((arr) => R.map((item) => this.parsePlanOption(item), arr))
    )
  }

  getTokeetPrices() {
    const url = '@api/plans/price/tokeet'
    return this.http.get<Record<string, TokeetPlanPrice[]>>(url)
  }

  plansForProduct(product: string): Observable<PlanOption[]> {
    const url = `@api/subscription/plans?product=${product}`

    return this.http.get<PlanOption[]>(url).pipe(
      map((plans) => {
        if (product === 'sympl') {
          return plans
        } else {
          return R.filter((p: any) => (p.id + '').indexOf('sympl') === -1, plans)
        }
      }),
      deserializeArray<PlanOption>(PlanOption),
      map((arr) => R.map((item) => this.parsePlanOption(item), arr))
    )
  }

  migrateToSympl() {
    const url = `@api/subscription/migrate/sympl`

    // @ts-ignore
    this.stripe = Stripe(environment.config.stripe_publishable_key)

    this.http
      .post<{ results: { secrets: string[] } }>(url, { plan: 'sympl_v1' })
      .pipe(
        map((res) => res.results),
        catchError((error) => {
          this.store.dispatch(ActionFailed({ error }))
          return EMPTY
        })
      )
      .subscribe(async (res) => {
        if (!R.isEmpty(res.secrets)) {
          for (const secret of res.secrets) {
            try {
              await this.stripe.confirmCardPayment(secret)
            } catch (e) {
              console.log(e)
            }
          }
          this.authService.logout(true, `${environment.symplUrl}login`)
        } else {
          this.authService.logout(true, `${environment.symplUrl}login`)
        }
      })
  }

  parsePlanSubscription(sub: PlanSubscription): PlanSubscription {
    // tslint:disable-next-line:triple-equals
    sub.cancelAtPeriodEnd = !(sub.cancelAtPeriodEnd == false)
    if (sub.trialStart) sub.trialStart = parseInt(sub.trialStart + '', 10)
    if (sub.trialEnd) sub.trialEnd = parseInt(sub.trialEnd + '', 10)
    if (sub.start) sub.start = parseInt(sub.start + '', 10)
    if (sub.until) sub.until = parseInt(sub.until + '', 10)
    if (sub.users) sub.users = parseInt(sub.users + '', 10)
    if (sub.rentals) sub.rentals = parseInt(sub.rentals + '', 10)
    if (sub.timestamp) sub.timestamp = parseInt(sub.timestamp + '', 10)
    if (sub.price) sub.price = parseFloat(sub.price + '')

    return sub
  }

  parsePlanOption(plan: PlanOption): PlanOption {
    if (plan.amount) plan.amount = parseFloat(plan.amount + '')
    if (plan.rentals) plan.rentals = parseInt(plan.rentals + '', 10)
    if (plan.interval_count) plan.interval_count = parseInt(plan.interval_count + '', 10)
    if (plan.users) plan.users = parseInt(plan.users + '', 10)
    return plan
  }

  parseActivePlanStatus(status: ActivePlanStatus): ActivePlanStatus {
    if (status.rentals) status.rentals = parseInt(status.rentals + '', 10)
    if (status.users) status.users = parseInt(status.users + '', 10)
    if (status.price) status.price = parseFloat(status.price + '')
    return status
  }
}
