import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, Validators } from '@angular/forms'
import { MatExpansionPanel } from '@angular/material/expansion'
import { Actions, ofType } from '@ngrx/effects'
import {
  CURRENT_USER,
  Channel,
  SaveForm,
  User,
  untilDestroy,
  Destroyable,
  ActionFailed,
  CURRENT_ACCOUNT_ID,
} from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject } from 'rxjs'
import { ChannelConnectHelperService } from '../../channel-connect-helper.service'

@Component({
  selector: 'app-connect-bdc-wizard-step3',
  templateUrl: './connect-bdc-wizard-step3.component.html',
  styleUrls: ['./connect-bdc-wizard-step3.component.scss'],
  providers: [ChannelConnectHelperService],
})
export class ConnectBDCWizardStep3Component extends Destroyable implements OnInit {
  @ViewChild('settings') startStep: MatExpansionPanel
  @Input() channel: Channel

  @Output() prev = new EventEmitter()
  @Output() connected = new EventEmitter<string>()

  isConnecting = false
  propertyIdCtrl = new FormControl('', [Validators.required])

  constructor(
    private actions: Actions,
    private channelHelperService: ChannelConnectHelperService,
    @Inject(CURRENT_ACCOUNT_ID) public accountId$: BehaviorSubject<number>
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(ActionFailed), untilDestroy(this)).subscribe((action) => {
      this.isConnecting = false
    })
  }

  @SaveForm()
  onConnect(form: AbstractControl) {
    const propertyId = this.propertyIdCtrl.value
    this.isConnecting = true
    this.channelHelperService.connectChannelWithPropertyId(this.channel, propertyId).subscribe(() => {
      this.connected.emit()
    })
  }

  reset() {
    this.propertyIdCtrl.reset()
    this.startStep.open()
  }
}
