import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { isSymplApp } from '@tokeet-frontend/tv3-platform'
import {
  ChannelNameTokens,
  Destroyable,
  selectApiChannelsWithDefaults,
  tokeetWebsitesChannel,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'
import { BehaviorSubject, merge } from 'rxjs'
import { distinctUntilChanged, map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-booking-fee-form',
  templateUrl: './booking-fee-form.component.html',
  styleUrls: ['./booking-fee-form.component.scss'],
})
export class BookingFeeFormComponent extends Destroyable implements OnInit {
  @Input() currencyCode: string
  @Input() form: FormGroup

  @Output() change = new EventEmitter()
  @Output() remove = new EventEmitter()

  minEndDate = new BehaviorSubject<Date>(null)
  maxStartDate = new BehaviorSubject<Date>(null)

  omitChannels = [
    ChannelNameTokens.AirBnBV2API,
    ChannelNameTokens.ExpediaAPI,
    ChannelNameTokens.AgodaAPI,
    ChannelNameTokens.BookingAPI,
  ]
  channels$ = this.store.pipe(
    select(selectApiChannelsWithDefaults),
    map((items) => items.filter((t) => !this.omitChannels.includes(t.name))),
    map((items) => {
      if (isSymplApp()) {
        return items
          .filter((t) => t.name !== tokeetWebsitesChannel.name)
          .map((t) => ({ ...t, friendlyName: t.friendlyName.replace('AdvanceCM', 'Sympl') }))
      } else {
        return items
      }
    })
  )

  showDetails = false

  get startCtrl() {
    return this.form?.get('start') as FormControl
  }
  get endCtrl() {
    return this.form?.get('end') as FormControl
  }

  feeCategories = [
    'Administrative Fee',
    'Booking Fee',
    'Cleaning Fee',
    'Dock Fee',
    'Damage Deposit',
    'Guest Registration',
    'HOA Fee',
    'Hot Tub Fee',
    'Management Fee',
    'Parking Fee',
    'Pool Fee',
    'Pool Heating Fee',
    'Resort Fee',
    'Security Deposit Fee',
    'Service Fee',
    'Utility Fee',
    'Waiver Damage Fee',
    'Miscellaneous Fee',
    'Per Extra Person Fee',
  ]

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit() {
    this.form.valueChanges.pipe(untilDestroy(this)).subscribe((values) => {
      this.change.emit(values)
    })

    this.form
      .get('type')
      .valueChanges.pipe(startWith(this.form.get('type').value), distinctUntilChanged(), untilDestroy(this))
      .subscribe((type) => {
        if (type === 'pct') {
          this.form.get('amount').setValidators([Validators.min(1), Validators.max(100)])
        } else {
          this.form.get('amount').setValidators([Validators.min(1)])
        }
        this.form.get('amount').updateValueAndValidity()
      })

    merge(
      this.startCtrl.valueChanges.pipe(startWith(this.startCtrl.value), distinctUntilChanged()),
      this.endCtrl.valueChanges.pipe(startWith(this.endCtrl.value), distinctUntilChanged())
    )
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.setStartEndMinMax()
      })
  }

  endDateInPast() {
    return moment(this.endCtrl.value).isBefore(moment())
  }

  setStartEndMinMax() {
    if (this.startCtrl.value) {
      this.endCtrl.setValidators([Validators.required])
      const arrive = moment(this.startCtrl.value).add(1, 'days').startOf('day')
      if (`${this.minEndDate.value}` !== arrive.toDate().toString()) {
        // prevent date changed event when set min
        const today = moment()
        if (arrive.isAfter(today)) {
          this.minEndDate.next(arrive.toDate())
        } else {
          this.minEndDate.next(today.toDate())
        }
      }
    }

    if (this.endCtrl.value) {
      this.startCtrl.setValidators([Validators.required])
      const depart = moment(this.endCtrl.value).subtract(1, 'days').endOf('day')
      if (`${this.maxStartDate.value}` !== depart.toDate().toString()) {
        // prevent date changed event when set max
        this.maxStartDate.next(depart.toDate())
      }
    }
  }
}
