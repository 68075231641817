import { adapter, initialState, State } from './datafeed.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  CreateFeed,
  CreateFeedComplete,
  DeleteFeed,
  DeleteFeedComplete,
  DeleteFeedsComplete,
  LoadFeedsComplete,
  PauseFeedComplete,
  ResumeFeedComplete,
} from '@tv3/store/datafeed/datafeed.actions'

export const reducer = createReducer(
  initialState,
  on(LoadFeedsComplete, (state, { feeds }) => adapter.addAll(feeds, R.merge(state, { isLoaded: true }))),
  on(CreateFeedComplete, (state, { feed }) => adapter.addOne(feed, R.merge(state, { isUpdating: false }))),
  on(PauseFeedComplete, ResumeFeedComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(DeleteFeedComplete, (state, { id }) => adapter.removeOne(id, R.merge(state, { isUpdating: false }))),
  on(DeleteFeedsComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(CreateFeed, DeleteFeed, (state) => ({ ...state, isUpdating: true }))
)

export function dataFeedReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
