import {
  AddCustomBrandingTemplateComplete,
  AddTemplate,
  AddTemplateComplete,
  ClearLastAddedTemplate,
  DeleteTemplate,
  DeleteTemplateComplete,
  DeleteTemplatesComplete,
  LoadTemplatesComplete,
  UpdateCustomBrandingTemplateComplete,
  UpdateTemplate,
  UpdateTemplateComplete,
} from './template.actions'
import { adapter, initialState, State } from './template.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadTemplatesComplete, (state, { templates }) => adapter.setAll(templates, R.merge(state, { isLoaded: true }))),
  on(AddTemplateComplete, (state, { template }) =>
    adapter.addOne(template, R.merge(state, { isUpdating: false, lastAddedId: template.id }))
  ),
  on(UpdateTemplateComplete, (state, { update }) =>
    adapter.updateOne(update, R.merge(state, { isUpdating: false, lastEditedId: update.id }))
  ),
  on(AddCustomBrandingTemplateComplete, (state, { template }) =>
    adapter.addOne(template, R.merge(state, { isUpdating: false }))
  ),
  on(UpdateCustomBrandingTemplateComplete, (state, { update }) =>
    adapter.updateOne(update, R.merge(state, { isUpdating: false }))
  ),
  on(DeleteTemplateComplete, (state, { id }) => adapter.removeOne(id, R.merge(state, { isUpdating: false }))),
  on(DeleteTemplatesComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(AddTemplate, UpdateTemplate, DeleteTemplate, (state) => ({ ...state, isUpdating: true })),
  on(ClearLastAddedTemplate, (state) => ({ ...state, lastAddedId: null })),
  on(ActionFailed, (state) => ({ ...state, isUpdating: false }))
)

export function templateReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
