import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectAirbnbWizardComponent } from './connect-airbnb-wizard.component'
import { ConnectAirbnbWizardStep1Component } from './step1/connect-airbnb-wizard-step1.component'
import { ConnectAirbnbWizardStep2Component } from './step2/connect-airbnb-wizard-step2.component'
import { ConnectAirbnbWizardComplete1Component } from './quick-complete/connect-airbnb-wizard-complete1.component'
import { ConnectAirbnbWizardComplete2Component } from './advance-complete/connect-airbnb-wizard-complete2.component'

const components = [
  // Airbnb
  ConnectAirbnbWizardStep1Component,
  ConnectAirbnbWizardStep2Component,
  ConnectAirbnbWizardComplete1Component,
  ConnectAirbnbWizardComplete2Component,
  ConnectAirbnbWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectAirbnbWizardModule {}
