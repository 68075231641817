import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'
import * as R from 'ramda'
import { ActivatedRoute } from '@angular/router'
import { FormBuilder } from '@angular/forms'
import { ConfirmDialogService, AlertDialogService } from '@tokeet-frontend/tv3-platform'
import { CloudinaryUploadDialogService, TokeetImagePayload } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Lightbox } from 'ngx-lightbox'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Attachment } from '@tokeet-frontend/tv3-platform'
import {
  AddWebsiteImages,
  DeleteWebsiteImage,
  LoadWebsiteImages,
  MarkWebsiteImageAsFavicon,
  MarkWebsiteImageAsLogo,
  MarkWebsiteImageAsPrimary,
  UpdateWebsiteImageOrders,
} from '@tv3/store/website-image/website-image.actions'
import { selectAllWebsiteImagesByWebsiteId } from '@tv3/store/website-image/website-image.selectors'
import { TokeetImage } from '@tv3/store/website-image/website-image.model'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'

@Component({
  selector: 'app-website-images',
  templateUrl: './website-images.component.html',
  styleUrls: ['./website-images.component.scss'],
})
export class WebsiteImagesComponent extends Destroyable implements OnInit {
  @Input() website: Website

  websiteTypes = WebsiteTypes
  images: TokeetImage[] = []
  imageOrderByIds: { [key: string]: number } = {}
  rentals: Rental[] = []

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private lightbox: Lightbox,
    private confirmDialogService: ConfirmDialogService,
    private cloudinaryUploadDialog: CloudinaryUploadDialogService,
    private alertDialog: AlertDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(LoadWebsiteImages({ websiteId: this.website.id }))

    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })

    this.store
      .pipe(select(selectAllWebsiteImagesByWebsiteId, { id: this.website.id }), untilDestroy(this))
      .subscribe((images) => {
        this.images = R.sortBy((item: TokeetImage) => {
          return item.order - (item.primary ? 10000 : 0)
        }, R.defaultTo([], images))

        R.forEach((image) => {
          this.imageOrderByIds[image.id] = image.order
        }, this.images)
      })
  }

  onDrop() {
    const orderByIds: { [key: string]: number } = {}
    this.images.forEach((image, index) => {
      if (index === this.imageOrderByIds[image.id]) {
        return
      }
      orderByIds[image.id] = index
    })
    this.store.dispatch(UpdateWebsiteImageOrders({ orderByIds }))
  }

  onUpload() {
    this.cloudinaryUploadDialog
      .open({
        title: 'Upload Website Images',
      })
      .subscribe((images: TokeetImagePayload[]) => {
        this.store.dispatch(AddWebsiteImages({ websiteId: this.website.id, data: images }))
      })
  }

  getImageThumbUrl(url: string) {
    // https://cloudinary.com/documentation/image_transformations#img_trans_overview
    const thumbParams = 'w_240,c_limit,q_auto:eco'
    return (url + '').replace('/upload/', `/upload/${thumbParams}/`)
  }

  onOpenImage(index: number) {
    this.lightbox.open(
      R.map((image: TokeetImage) => {
        return {
          thumb: this.getImageThumbUrl(image.secureUrl),
          src: image.secureUrl,
        }
      }, this.images),
      index
    )
  }

  onShowUrl(image: TokeetImage) {
    this.alertDialog.open({
      title: 'Image URL',
      body: `You may use the URL below to access this image from anywhere.
                This URL will be valid forever and may be accessed without logging into AdvanceCM.
                You may also embed this URL into your website using HTML image tags.<br/><br/>
                <a href="${image.secureUrl}" target="_blank">${image.secureUrl}</a>`,
    })
  }

  deleteImage(image: TokeetImage) {
    this.confirmDialogService
      .confirm({
        title: 'Delete this image?',
        body: 'Are you sure you want to delete this image?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteWebsiteImage({ imageId: image.id }))
      })
  }

  makeImagePrimary(image: TokeetImage) {
    this.store.dispatch(
      MarkWebsiteImageAsPrimary({ websiteId: this.website.id, imageId: image.id, images: this.images })
    )
  }

  makeImageFavicon(image: TokeetImage) {
    this.store.dispatch(
      MarkWebsiteImageAsFavicon({ websiteId: this.website.id, imageId: image.id, images: this.images })
    )
  }

  makeImageLogo(image: TokeetImage) {
    this.store.dispatch(MarkWebsiteImageAsLogo({ websiteId: this.website.id, imageId: image.id, images: this.images }))
  }

  onRentalImages() {
    const activeRentalId = this.website.rentals[0]
    const activeRental = R.find((r) => r.id === activeRentalId, this.rentals)
    if (!activeRental) {
      return
    }
    this.store.dispatch(OpenRentalOverlay({ rental: activeRental, activeTab: RentalDialogTab.Gallery }))
  }
}
