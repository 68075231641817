import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BookingFormulaService } from './service'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { FormulaEffects } from './effects'
import { formulasReducer } from './reducer'
import { BookingFormulaGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('formulas', formulasReducer),
    EffectsModule.forFeature([FormulaEffects]),
  ],
  providers: [BookingFormulaService, BookingFormulaGuard],
})
export class BookingFormulaStoreModule {}
