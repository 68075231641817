<div class="container-box p-3">
  <div [class.loading-section]="isSaving$ | async">
    <div class="map-holder">
      <h6 class="text-uppercase">GPS Location</h6>
      <agm-map [latitude]="this.form.get('gps').value.lat" [longitude]="this.form.get('gps').value.long" [zoom]="14">
        <agm-map-type-control></agm-map-type-control>
        <agm-marker
          [latitude]="this.form.get('gps').value.lat"
          [longitude]="this.form.get('gps').value.long"
          [markerDraggable]="true"
          (dragEnd)="onMarkerChange($event)"
        ></agm-marker>
      </agm-map>
    </div>
  </div>
</div>
