import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Channel, SelectableRow } from '@tokeet-frontend/tv3-platform'

import { UserStorage, isAllSelected } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  addRentalImagesComplete,
  ConfirmDialogService,
  Rental,
  selectRentalCities,
  selectRentalTags,
  untilDestroy,
  updateRentalComplete,
  updateRentalDetailsComplete,
  updateRentalBaseRateComplete,
  removeRentalImages,
  removeRentalImage,
  updateRentalTaxes,
} from '@tokeet-frontend/tv3-platform'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { createSearch, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { select, Store } from '@ngrx/store'
import { SelectionModel } from '@angular/cdk/collections'
import { combineLatest } from 'rxjs'
import { delay, startWith } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import {
  DeleteRentalsUnitedListing,
  GetRentalsUnitedListings,
  GetRentalsUnitedRentalValidations,
  getRentalUnitedListings,
  ListingsValidationFieldResult,
  ListingValidationFieldResultItem,
  RentalsUnitedListingViewModel,
  selectRentalsUnitedRentalValidations,
  selectRentalsUnitedValidationsLoaded,
  SetRentalsUnitedListingStatus,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'
import { DeleteRoomComplete, UpdateRoomComplete, UpsertRoomsComplete } from '@tokeet-frontend/rentals'
import * as lodash from 'lodash'
import { RentalValidationFixHelperService } from '../../rental-validation-fix-helper.service'
import { some } from 'lodash'
import { RUListingDetailsOverlayService } from '../ru-listing-validation/ru-listing-details-overlay.service'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { BookingFormulasOverlayService } from '@tv3/containers/formulas/overlay/booking-formulas-overlay.service'

@Component({
  selector: 'app-connect-ru-wizard-step2',
  templateUrl: './connect-ru-wizard-step2.component.html',
  styleUrls: ['./connect-ru-wizard-step2.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConnectRUWizardStep2Component
  extends SelectableRow<RentalsUnitedListingViewModel>
  implements OnInit, AfterViewInit
{
  @Input() channel: Channel
  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  @ViewChild('paginator', { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['select', 'rental_name', 'propertyId', 'statusText', 'lastSync', 'action']
  expandedElement: any

  isEmptyTable$ = isEmptyTable(this.dataSource)

  listings: RentalsUnitedListingViewModel[]

  statusCtrl = new FormControl('')
  searchCtrl = new FormControl('')
  tagsCtrl = new FormControl([])
  citiesCtrl = new FormControl([])

  tags$ = this.store.pipe(select(selectRentalTags))
  cities$ = this.store.pipe(select(selectRentalCities))

  rentalTabs = RentalDialogTab

  searchFn = createSearch<RentalsUnitedListingViewModel>([
    'rental_name',
    'propertyId',
    'listingId',
    'statusText',
    'rentalId',
    'lastSyncView',
  ])

  validations: ListingsValidationFieldResult

  validationsLoaded$ = this.store.pipe(select(selectRentalsUnitedValidationsLoaded))

  isExpansionDetailRow = (i: number, row: RentalsUnitedListingViewModel & { detailRow?: boolean }) => {
    return row.hasOwnProperty('detailRow')
  }

  constructor(
    private confirmDialog: ConfirmDialogService,
    private store: Store<any>,
    private actions: Actions,

    protected storage: UserStorage,
    private listingDetailsOverlay: RUListingDetailsOverlayService,
    private validationFixHelper: RentalValidationFixHelperService,
    private bookingFormulasOverlay: BookingFormulasOverlayService
  ) {
    super(storage)
  }

  ngOnInit() {
    this.actions
      .pipe(
        ofType(
          updateRentalComplete,
          updateRentalDetailsComplete,
          UpdateRoomComplete,
          UpsertRoomsComplete,
          addRentalImagesComplete,
          DeleteRoomComplete,
          updateRentalBaseRateComplete,
          removeRentalImages,
          removeRentalImage,
          updateRentalTaxes
        ),
        delay(1000),
        startWith(''),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.refresh()
      })

    combineLatest([
      this.store.pipe(getRentalUnitedListings),
      this.store.pipe(select(selectRentalsUnitedRentalValidations)),
      this.searchCtrl.valueChanges.pipe(startWith('')),
      this.citiesCtrl.valueChanges.pipe(startWith([])),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.statusCtrl.valueChanges.pipe(startWith('')),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([listings, validations, term, cities, tags, status]) => {
        this.validations = validations || ({} as any)
        this.listings = listings
        let configs = this.listings
        switch (status) {
          case 'all':
            configs = this.listings
            break
          case 'active':
            configs = R.filter((config) => config.statusText === 'Active', this.listings)
            break
          case 'inactive':
            configs = R.reject((config) => config.statusText === 'Active', this.listings)
            break
        }
        if (tags?.length > 0) {
          configs = R.filter((rental) => {
            return R.any(
              R.equals(true),
              R.map((tag) => R.contains(tag, rental.rental?.tags || []), tags)
            )
          }, configs)
        }
        if (cities?.length > 0) {
          configs = R.filter((rental) => R.includes(rental.rental?.address?.city || '', cities), configs)
        }
        if (term?.length > 0) {
          configs = this.searchFn(configs, term)
        }
        this.dataSource.data = this.withExtraRow(configs || [], this.validations)
      })
  }

  refresh() {
    this.store.dispatch(GetRentalsUnitedRentalValidations())
    this.store.dispatch(GetRentalsUnitedListings())
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
  }

  onCancelFilters() {
    this.statusCtrl.patchValue(null)
    this.tagsCtrl.patchValue(null)
    this.citiesCtrl.patchValue(null)
    this.searchCtrl.patchValue(null)
  }

  get isFiltering() {
    return this.statusCtrl.value || this.tagsCtrl.value || this.citiesCtrl.value || this.searchCtrl.value
  }

  withExtraRow(listings: RentalsUnitedListingViewModel[], validations: ListingsValidationFieldResult) {
    listings = lodash.sortBy(listings, (listing) => validations?.[listing.rentalId]?.length === 0)
    return R.map((listing: RentalsUnitedListingViewModel) => {
      const hasError = some(validations[listing.rentalId], (t) => t.type === 'error')
      return {
        ...listing,
        detailRow: validations?.[listing.rentalId]?.length > 0,
        hasError,
      }
    }, listings)
  }

  allInvalid() {
    return R.all((listing) => listing['detailRow'], this.dataSource.data)
  }

  isAllSelected() {
    return isAllSelected(this.selection, this.listings, this.isRowSelectable)
  }

  isRowSelectable(listing: RentalsUnitedListingViewModel) {
    return !listing['detailRow']
  }

  setExpanded(row) {
    this.expandedElement = row
  }

  onEditListing(listing: RentalsUnitedListingViewModel) {
    this.listingDetailsOverlay.open(listing)
  }

  onDeleteListing(listing: RentalsUnitedListingViewModel) {
    this.confirmDialog
      .confirm({
        title: 'Delete listing?',
        body: 'Are you sure you want to delete this listing?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteRentalsUnitedListing({ rentalId: listing.rentalId, propertyId: listing.propertyId }))
      })
  }

  onSetListingStatus(listing: RentalsUnitedListingViewModel, checked: boolean) {
    this.store.dispatch(
      SetRentalsUnitedListingStatus({
        rentalId: listing.rentalId,
        propertyId: listing.propertyId,
        status: checked,
      })
    )
  }

  onFixRental(rental: Rental, validation: ListingValidationFieldResultItem) {
    this.validationFixHelper.onFixRental(rental, validation, this.validations[rental.id])
  }

  onFixRentalLatLong(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Location }))
  }

  onBookingFormula() {
    this.bookingFormulasOverlay.open(this.channel)
  }
  onComplete() {
    this.next.emit()
  }
}
