import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'

@Component({
  selector: 'app-website-details',
  templateUrl: './website-details.component.html',
  styleUrls: ['./website-details.component.scss'],
})
export class WebsiteDetailsComponent implements OnInit {
  @Input() website: Website

  websiteTypes = WebsiteTypes
  constructor() {}

  ngOnInit() {}
}
