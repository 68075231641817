import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import * as R from 'ramda'

export function dateDiff(toDate, fromDate, nights): number {
  if (toDate && fromDate) {
    toDate = R.is(Number, toDate) ? toDate * 1000 : toDate
    fromDate = R.is(Number, fromDate) ? fromDate * 1000 : fromDate

    let to = moment.utc(toDate)
    let from = moment.utc(fromDate)

    // Set the beginning of each dayas the diff function seems to look at 24 hours as one day.
    to.hour(0).minute(0).second(0)
    from.hour(0).minute(0).second(0)

    let dayDiff = to.diff(from, 'days')

    if (R.is(Number, dayDiff) && nights) {
      return dayDiff
    } else if (R.is(Number, dayDiff)) {
      return dayDiff + 1
    }
  }
  return 0
}

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  transform(toDate, fromDate, nights): number {
    return dateDiff(toDate, fromDate, nights)
  }
}
