import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { GVRChannelService } from './service'
import {
  ActivateGVRListing,
  ActivateGVRListingSuccess,
  ConnectGVR,
  ConnectGVRSuccess,
  CreateGVRListing,
  CreateGVRListingSuccess,
  DeactivateGVRListing,
  DeactivateGVRListingSuccess,
  DeleteGVRListing,
  DeleteGVRListingSuccess,
  DisconnectGVR,
  DisconnectGVRSuccess,
  GetGVRListings,
  GetGVRListingsSuccess,
  GetGVRRentalValidations,
  GetGVRRentalValidationsSuccess,
  GetGVRStatus,
  GetGVRStatusSuccess,
  UpdateGVRListing,
  UpdateGVRListingSuccess,
} from './actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class GVRChannelEffects {
  @Effect()
  getGVRStatus$ = this.actions$.pipe(
    ofType(GetGVRStatus),
    switchMap(() =>
      this.channelsService.getStatus().pipe(
        map((status) => GetGVRStatusSuccess({ status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // @Effect()
  // deleteGVRListing$ = this.actions$.pipe(
  //   ofType(DeleteGVRListing),
  //   switchMap(({ listingId }) =>
  //     this.channelsService.deleteListing(listingId).pipe(
  //       tap(() => this.toaster.success('Listing deleted successfully')),
  //       map(() => DeleteGVRListingSuccess({ listingId })),
  //       catchError((error) => of(ActionFailed({ error })))
  //     )
  //   )
  // )

  @Effect()
  connectGVR$ = this.actions$.pipe(
    ofType(ConnectGVR),
    switchMap(() =>
      this.channelsService.connect().pipe(
        tap(() => this.toaster.success('GVR connected successfully')),
        map(() => ConnectGVRSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  activateGVRListing$ = this.actions$.pipe(
    ofType(ActivateGVRListing),
    switchMap(({ rentalId }) =>
      this.channelsService.activateListing(rentalId).pipe(
        tap(() => this.toaster.success('Listing activated successfully')),
        map(() => ActivateGVRListingSuccess({ rentalId, active: true })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deactivateGVRListing$ = this.actions$.pipe(
    ofType(DeactivateGVRListing),
    switchMap(({ rentalId }) =>
      this.channelsService.deactivateListing(rentalId).pipe(
        tap(() => this.toaster.success('Listing deactivated successfully')),
        map(() => DeactivateGVRListingSuccess({ rentalId, active: false })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getGVRListings$ = this.actions$.pipe(
    ofType(GetGVRListings),
    switchMap(() =>
      this.channelsService.getListings().pipe(
        map((listings) => GetGVRListingsSuccess({ listings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createGVRListing$ = this.actions$.pipe(
    ofType(CreateGVRListing),
    switchMap(({ listing }) =>
      this.channelsService.createListing(listing).pipe(
        tap(() => this.toaster.success('Listing created successfully.')),
        map((response) => CreateGVRListingSuccess({ listing })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getGVRRentalValidations$ = this.actions$.pipe(
    ofType(GetGVRRentalValidations),
    switchMap(() =>
      this.channelsService.getRentalValidations().pipe(
        map((validations) => GetGVRRentalValidationsSuccess({ validations })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGVRListing$ = this.actions$.pipe(
    ofType(UpdateGVRListing),
    switchMap(({ listing }) =>
      this.channelsService.updateListing(listing).pipe(
        tap(() => this.toaster.success('Listing updated successfully.')),
        map((response) => UpdateGVRListingSuccess({ listing })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  disconnectGVR$ = this.actions$.pipe(
    ofType(DisconnectGVR),
    switchMap(() =>
      this.channelsService.disconnect().pipe(
        tap(() => {
          this.toaster.success('GVR disconnected successfully')
        }),
        map(() => DisconnectGVRSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private channelsService: GVRChannelService, private toaster: Toaster) {}
}
