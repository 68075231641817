import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './reducer'
import * as state from './state'
import * as lodash from 'lodash'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { EntityTagsResponse } from './model'

export const entityTagState = createFeatureSelector<state.State>('entityTags')

export const isEntityTagsLoaded = createSelector(entityTagState, (s: state.State) => s.isLoaded)

export const selectAllEntityTags = createSelector(entityTagState, reducer.selectAll)

export const selectEntityTypeTags = (type: DataEntityType) =>
  createSelector(selectAllEntityTags, (tags: EntityTagsResponse[]) => {
    const items = tags.filter((t) => t.entity_type === type)
    return lodash.uniq(lodash.flatten(items.map((t) => t.tags || [])))
  })

export const selectTagsEntity = (type: DataEntityType, id: string) =>
  createSelector(selectAllEntityTags, (tags: EntityTagsResponse[]) =>
    tags.find((t) => t.entity_type === type && t.entity_pkey === id)
  )

export const selectEntityTags = (type: DataEntityType, id: string) =>
  createSelector(selectTagsEntity(type, id), (entity) => entity?.tags || [])

export const selectEntitiesWithTags = (type: DataEntityType, tags: string[]) =>
  createSelector(selectAllEntityTags, (items: EntityTagsResponse[]) => {
    return items.filter((t) => t.entity_type === type && lodash.intersection(t.tags, tags).length > 0)
  })
