import { Store } from '@ngrx/store'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { selectOnce } from '@tokeet-frontend/tv3-platform'
import { selectAllCalendarEventsForRental } from '@tv3/store/calendar/calendar.selectors'
import { map } from 'rxjs/operators'

export function checkBookingOverlapping(
  store: Store<any>,
  calendarService: CalendarService,
  data: { id?: string; rentalId: string; start: number; end: number }
) {
  return store.pipe(
    selectOnce(selectAllCalendarEventsForRental(data.rentalId)),
    map((events) => {
      if (data.id) {
        events = events.filter((event) => (event.id || event.bookingId) !== data.id)
      }
      return calendarService.checkOverlapping({ start: data.start, end: data.end }, events)
    })
  )
}
