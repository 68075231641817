<div class="modal-header">
  <h6 class="modal-title">Edit Listing Settings</h6>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="modal-body">
  <div *ngIf="errors.length > 0" class="info-errors">
    <i class="fal fa-exclamation-triangle"></i>
    <ng-container *ngFor="let error of errors">
      <p *ngFor="let e of error.errors">
        {{ e }}
      </p>
    </ng-container>
  </div>

  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2
          matTooltip="Select HMC ID where you want to create your listing"
          matTooltipPosition="above"
          class="mat-block"
        >
          <mat-select formControlName="hmcId" placeholder="HMC ID" required>
            <mat-option *ngFor="let id of hmcIds$ | async" [value]="id">{{ id }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('hmcId').touched && form.get('hmcId').hasError('required')">
            HMC ID is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2
          matTooltip="Select the numbers of Rooms from dropdown. Prepopulated with data from Rental Bedrooms field"
          matTooltipPosition="above"
          class="mat-block"
        >
          <mat-select formControlName="rooms" placeholder="Rooms" required>
            <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('rooms').touched && form.get('rooms').hasError('required')">
            Rooms is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2
          matTooltip="Only instant bookings are available at the moment"
          matTooltipPosition="above"
          class="mat-block"
        >
          <mat-select formControlName="bookingSetting" placeholder="Booking Setting">
            <mat-option value="instant_booking_only">Instant Booking Only</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('bookingSetting').touched && form.get('bookingSetting').hasError('required')">
            Booking Setting is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 matTooltip="Language of the listing on HVMI" matTooltipPosition="above" class="mat-block">
          <mat-select formControlName="locale" placeholder="Locale">
            <mat-option *ngFor="let locale of locales" [value]="locale.id">{{ locale.name }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <div class="col-sm-12">
        <mat-form-field2 matTooltip="Select your cancellation policy" matTooltipPosition="above" class="mat-block">
          <mat-select formControlName="cancellationPolicyCategory" placeholder="Cancellation Policy">
            <mat-option *ngFor="let policy of cancellationPolicies" [value]="policy.id">{{ policy.name }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2
          matTooltip="Define the number of guests included in the nightly price"
          matTooltipPosition="above"
          class="mat-block"
        >
          <input matInput placeholder="Guests Included" formControlName="guestsIncluded" type="number" required />
          <mat-error *ngIf="form.get('guestsIncluded').touched && form.get('guestsIncluded').hasError('required')">
            Guests Included is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input matInput placeholder="Persons" formControlName="persons" type="number" required />
          <mat-error *ngIf="form.get('persons').touched && form.get('persons').hasError('required')">
            Guests Included is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-12">
        <div class="alert alert-secondary-warning">
          <i class="cs-icon cs-icon-alert-triangle alert-icon"></i>
          <div class="alert-content">
            This Guest Included value will be used on this Rental within Marriott for all bookings been made. Additional
            guest fees will not apply until the guest count on booking is greater than this Guest Included value. Rate
            specific additional guest fees threshold will not be used by Marriott. Marriott will only use this specific
            threshold set here for all rates
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <mat-form-field2
          matTooltip="Copied from the Rental but can be modified here as well"
          matTooltipPosition="above"
          class="mat-block"
        >
          <textarea
            matInput
            maxlength="1000"
            rows="7"
            formControlName="shortDescription"
            placeholder="Short Description"
            required
            cdkTextareaAutosize
          ></textarea>
          <mat-hint align="start">Must be at least 20 and at most 1000 characters</mat-hint>
          <mat-error *ngIf="form.get('shortDescription').touched && form.get('shortDescription').hasError('required')">
            Short Description is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('shortDescription').touched && form.get('shortDescription').hasError('minlength')">
            Short Description must be at least <strong>20 characters long</strong>
          </mat-error>
          <mat-error *ngIf="form.get('shortDescription').touched && form.get('shortDescription').hasError('maxlength')">
            Short Description must be at most <strong>1000 characters long</strong>
          </mat-error>
          <mat-hint align="end">{{ form.get('shortDescription').value?.length || 0 }}/1000</mat-hint>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-outline-light">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <button *ngIf="isEditing" (click)="onSave(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
  <button *ngIf="!isEditing" (click)="onCreate(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Create
  </button>
</div>
