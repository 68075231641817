import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  AddFlight,
  AddFlightComplete,
  CreateFlight,
  CreateFlightComplete,
  DeleteFlight,
  DeleteFlightComplete,
  EditFlight,
  EditFlightComplete,
  LoadItineraries,
  LoadItinerariesComplete,
} from './itinerary.actions'
import { ItineraryService } from './itinerary.service'
import { Itinerary } from '@tv3/store/itinerary/itinerary.model'
import { Flight } from '@tv3/models/itinerary/flight'
import * as moment from 'moment'
import * as R from 'ramda'
import { ActionFailed, TimePickerUtils } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ItineraryEffects {
  @Effect()
  loadItineraries$ = this.actions$.pipe(
    ofType(LoadItineraries),
    switchMap(({ inquiryId }) =>
      this.itineraries.all(inquiryId).pipe(
        map((itineraries) => LoadItinerariesComplete({ itineraries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createFlight$ = this.actions$.pipe(
    ofType(CreateFlight),
    map(({ inquiry, form }) => {
      let flight = new Flight()
      flight.airline = form.airline
      flight.flightNum = form.flightNum
      flight.timezone = form.timezone
      flight.departure = moment
        .utc(moment(form.departureDate).format('YYYY-MM-DD') + ' ' + TimePickerUtils.to24HourTime(form.departureTime))
        .format()
      return {
        flight,
        inquiry,
      }
    }),
    switchMap(({ flight, inquiry }) =>
      this.itineraries.create(inquiry, flight).pipe(
        map((itinerary) => CreateFlightComplete({ itinerary })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addFlight$ = this.actions$.pipe(
    ofType(AddFlight),
    map(({ inquiry, itinerary, form }) => {
      let newItinerary = new Itinerary(itinerary)
      let flight = new Flight()
      flight.airline = form.airline
      flight.flightNum = form.flightNum
      flight.timezone = form.timezone
      flight.departure = moment
        .utc(moment(form.departureDate).format('YYYY-MM-DD') + ' ' + TimePickerUtils.to24HourTime(form.departureTime))
        .format()
      newItinerary.flights = R.append(flight, newItinerary.flights)
      return newItinerary
    }),
    switchMap((itinerary) =>
      this.itineraries.update(itinerary, 'add').pipe(
        map((itinerary) => AddFlightComplete({ update: { id: itinerary.id, changes: itinerary } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  editFlight$ = this.actions$.pipe(
    ofType(EditFlight),
    map(({ itinerary, form }) => {
      let newItinerary = new Itinerary(itinerary)
      let flight = new Flight()
      flight.airline = form.airline
      flight.flightNum = form.flightNum
      flight.timezone = form.timezone
      flight.departure = moment
        .utc(moment(form.departureDate).format('YYYY-MM-DD') + ' ' + TimePickerUtils.to24HourTime(form.departureTime))
        .format()

      newItinerary.flights = R.filter((f: Flight) => f.flightNum !== flight.flightNum, newItinerary.flights)
      newItinerary.flights = R.append(flight, newItinerary.flights)
      return newItinerary
    }),
    switchMap((itinerary) =>
      this.itineraries.update(itinerary, 'edit').pipe(
        map((itinerary) => EditFlightComplete({ update: { id: itinerary.id, changes: itinerary } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteFlight$ = this.actions$.pipe(
    ofType(DeleteFlight),
    map(({ itinerary, flight }) => {
      let newItinerary = new Itinerary(itinerary)
      newItinerary.flights = R.filter((f: Flight) => f.flightNum !== flight.flightNum, newItinerary.flights)
      return newItinerary
    }),
    switchMap((itinerary) =>
      this.itineraries.update(itinerary, 'delete').pipe(
        map((itinerary) => DeleteFlightComplete({ update: { id: itinerary.id, changes: itinerary } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private itineraries: ItineraryService) {}
}
