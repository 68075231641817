import { on } from '@ngrx/store'
import { State } from '../channels.state'
import {
  ConnectRentalsUnitedSuccess,
  CreateRentalsUnitedListingSuccess,
  DeleteRentalsUnitedListingSuccess,
  DisconnectRentalsUnitedSuccess,
  GetRentalsUnitedListingsSuccess,
  GetRentalsUnitedRentalValidationsSuccess,
  GetRentalsUnitedStatusSuccess,
  SetRentalsUnitedListingStatusSuccess,
  UpdateRentalsUnitedListingSuccess,
} from './actions'

export function getRentalsUnitedReducers() {
  return [
    on(GetRentalsUnitedStatusSuccess, (state: State, { status }) => ({
      ...state,
      rentalUnited: {
        ...state.rentalUnited,
        status,
      },
    })),
    on(ConnectRentalsUnitedSuccess, (state: State, { status }) => ({
      ...state,
      rentalUnited: {
        ...state.rentalUnited,
        status,
      },
    })),
    on(GetRentalsUnitedRentalValidationsSuccess, (state: State, { validations }) => ({
      ...state,
      rentalUnited: {
        ...state.rentalUnited,
        validations,
        validationsLoaded: true,
      },
    })),
    on(GetRentalsUnitedListingsSuccess, (state: State, { listings }) => ({
      ...state,
      rentalUnited: {
        ...state.rentalUnited,
        listings,
      },
    })),
    on(DisconnectRentalsUnitedSuccess, (state: State) => {
      return {
        ...state,
        rentalUnited: {
          ...state.rentalUnited,
          status: {
            connected: false,
          },
        },
      }
    }),
    on(
      CreateRentalsUnitedListingSuccess,
      UpdateRentalsUnitedListingSuccess,
      SetRentalsUnitedListingStatusSuccess,
      DeleteRentalsUnitedListingSuccess,
      (state: State, { update }) => {
        return {
          ...state,
          rentalUnited: {
            ...state.rentalUnited,
            listings: state.rentalUnited.listings.map((t) => {
              if (t.rentalId === update.id) {
                return { ...t, ...update.changes }
              } else {
                return t
              }
            }),
          },
        }
      }
    ),
  ]
}
