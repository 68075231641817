import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { InvoiceTemplateDueDateRule } from '../../../../store/templates/template.model'
import {
  EditInvoiceTemplateDueDateRuleComponent,
  EditInvoiceTemplateDueDateRuleComponentParams,
} from './edit-invoice-template-due-date-rule.component'

@Injectable()
export class EditInvoiceTemplateDueDateRuleService {
  constructor(private dialog: MatDialog) {}

  open(rule: InvoiceTemplateDueDateRule, config?: EditInvoiceTemplateDueDateRuleComponentParams) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { rule, config },
    }

    return this.dialog.open(EditInvoiceTemplateDueDateRuleComponent, defaultConfig)
  }
}
