import { Directive, HostBinding, ElementRef, OnInit } from '@angular/core'

const BASE_TAB_INDEX = 999999990

@Directive({
  // tslint:disable directive-selector
  selector: '.modal-body',
})
export class ModalBodyDirective implements OnInit {
  @HostBinding('attr.tabindex') tabIndex = BASE_TAB_INDEX + 1

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const element = this.elementRef.nativeElement as HTMLElement

    if (element.closest) {
      const dialogContainer = element.closest('mat-dialog-container')

      if (dialogContainer) {
        dialogContainer.setAttribute('tabindex', BASE_TAB_INDEX.toString())
      }
    }
  }
}
