<ngb-datepicker
  #dp
  (dateSelect)="onDateSelection($event)"
  [markDisabled]="isDisabled"
  [dayTemplate]="t"
></ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused" let-currentMonth="currentMonth" let-currentYear="currentYear">
  <span
    class="custom-day"
    [class]="date | dayClasses: eventGroups"
    [class.other-month]="date.month !== currentMonth"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
