import { Component, Input } from '@angular/core'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'

import { TooltipPosition } from '@angular/material/tooltip'

@Component({
  selector: 'app-default-calendar',
  template: `<app-default-route-indicator
    [checkIsDefault]="isDefault"
    [getDefaultRoutePayload]="getDefaultRoutePayload"
    tooltipText="Set this calendar as default"
    tooltipPosition="above"
  ></app-default-route-indicator>`,
})
export class DefaultCalendarComponent {
  @Input() calendarName: string

  @Input() tooltipPosition: TooltipPosition = 'right'

  isDefault = (pref: UserPreferences) => {
    return pref.defaultCalendar === this.calendarName
  }

  getDefaultRoutePayload = (checked: boolean) => {
    return { default_calendar: this.calendarName }
  }
}
