import { Component, ContentChild, EventEmitter, HostBinding, Input, Output } from '@angular/core'
import { CustomInputDirective } from './form-input.directive'
import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['../form-field-common.scss', './form-input.component.scss'],
})
export class FormInputComponent extends FormFieldComponent {
  @Input() type: 'text' | 'number' | 'email' | 'password' | 'url' | 'search' = 'text'

  @Input() max: number
  @Input() min: number
  @Input() step: number
  @Input() minlength: number
  @Input() maxlength: number

  @HostBinding('class.multiline')
  @Input()
  multiline: number

  @Output() blur = new EventEmitter<MouseEvent>()
  @Output() focus = new EventEmitter<MouseEvent>()

  @ContentChild(CustomInputDirective) input: CustomInputDirective

  @HostBinding('class.field-search') get isSearchField() {
    return this.type === 'search'
  }

  onBlur($vent: MouseEvent) {
    this.focused = false
    this.blur.emit($vent)
  }

  onFocus($vent: MouseEvent) {
    this.focused = true
    this.focus.emit($vent)
  }
}
