<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-2">
        <h6 class="modal-title">Connection Details</h6>
        <app-airbnb-listing-status [connection]="connection" *ngIf="isAirBnBV2"></app-airbnb-listing-status>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <app-api-connection-detail
        class="d-block mb-3"
        [channel]="channel"
        [rental]="rental"
        [connection]="connection"
        [dateFormat]="dateFormat"
      >
      </app-api-connection-detail>

      <app-api-connection-status
        class="d-block mb-3"
        [channel]="channel"
        [connection]="connection"
        [dateFormat]="dateFormat"
        [availabilityPull]="!isTiketLikeChannel"
        *ngIf="channel && connection"
      ></app-api-connection-status>

      <app-api-connection-log
        class="d-block mb-3"
        [channel]="channel?.name"
        [availabilityPull]="!isTiketLikeChannel"
        [activityLogs]="logs$ | async"
        (refresh)="refreshLogs$.next(true)"
      ></app-api-connection-log>
    </div>
  </div>
</div>
