import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { ChannelReviewItem, ReviewService } from '@tokeet-frontend/reviews'
import { AddTemplate, selectTemplatesByTypes, Template, TemplateType } from '@tokeet-frontend/templates'
import {
  Account,
  ActionFailed,
  Destroyable,
  SaveForm,
  selectCurrentAccount,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { SaveTemplateDialogService } from '@tv3/containers/templates/save-template-dialog/save-template-dialog.service'
import { StripHtmlPipe } from '@tv3/pipes/utility/strip-html.pipe'
import { AuthService } from '@tv3/services/auth.service'
import { DataDictService } from '@tv3/services/data-dict.service'
import { FetchGuests } from '@tv3/store/guest/guest.actions'
import { FetchInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import * as lodash from 'lodash'
import { EMPTY } from 'rxjs'
import { catchError, finalize, mapTo, switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'app-review-reply-dialog',
  templateUrl: './review-reply-dialog.component.html',
  styleUrls: ['./review-reply-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ReviewReplyDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    response: [''],
  })

  get userName() {
    return this.auth.user.name
  }
  get review() {
    return this.data.review
  }
  get reviewer() {
    return this.review.reviewer?.name || this.review.reviewer?.role
  }

  templates$ = this.store.pipe(select(selectTemplatesByTypes([TemplateType.Review])))
  aiResponderSub$ = this.store.pipe(select(selectActiveProductPlanView(ProductsForPlan.TokeetAI)))

  isGeneratingAIresponse = false
  isLoading = false
  inquiry: Inquiry
  account: Account

  constructor(
    private dialogRef: MatDialogRef<ReviewReplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { review: ChannelReviewItem },
    private store: Store<any>,
    private auth: AuthService,
    private reviewService: ReviewService,
    private toaster: Toaster,
    private fb: FormBuilder,
    private dataDictService: DataDictService,
    private saveTemplateDialog: SaveTemplateDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(FetchInquiries({ ids: [this.data.review.inquiryId] }))
    this.store.dispatch(FetchGuests({ ids: [this.data.review.guestId] }))
    this.store.pipe(select(selectInquiry, { id: this.data.review.inquiryId }), untilDestroy(this)).subscribe((item) => {
      this.inquiry = item
    })
    this.store.pipe(select(selectCurrentAccount), untilDestroy(this)).subscribe((a) => {
      this.account = a
    })
  }

  onClose() {
    this.dialogRef.close()
  }

  onTemplateChange([template]: [Template]) {
    if (template) {
      this.dataDictService
        .resolve(template.body, {
          inquiry: this.inquiry,
          rental: this.inquiry.rental,
          guest: this.inquiry.guest,
          account: this.account,
        })
        .subscribe((compiledMessage) => {
          const stripTags = new StripHtmlPipe()
          this.form.patchValue({ response: stripTags.transform(compiledMessage || '') })
        })
    }
  }

  onAIResponse() {
    this.isGeneratingAIresponse = true
    this.reviewService
      .generateReviewResponse(this.review.inquiryId)
      .pipe(finalize(() => (this.isGeneratingAIresponse = false)))
      .subscribe(
        (message) => {
          this.form.patchValue({ response: message })
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  reply(response: string) {
    this.isLoading = true
    return this.reviewService.respondReview(this.review._id, response).pipe(
      finalize(() => (this.isLoading = false)),
      tap(() => this.toaster.success('Review replied successfully')),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const data = form.getRawValue()
    const response = lodash.trim(data.response)
    if (!response) return
    this.reply(response).subscribe(() => {
      this.onClose()
    })
  }

  @SaveForm()
  onSaveTemplateAndReply(form: FormGroup) {
    const data = form.getRawValue()
    const response = lodash.trim(data.response)
    if (!response) return

    this.saveTemplateDialog
      .open()
      .pipe(
        switchMap((data) => {
          return this.reply(response).pipe(mapTo(data))
        })
      )
      .subscribe((data) => {
        this.store.dispatch(AddTemplate({ request: { ...data.data, body: response, type: TemplateType.Review } }))
        this.onClose()
      })
  }
}
