import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { Attachment, CloudinaryUploadDialogService } from '@tokeet-frontend/tv3-platform'
import { Preference } from '@tv3/store/preferences/preferences.model'
import { UpdateBranding } from '@tv3/store/preferences/preferences.actions'
import { BrandingRequest } from '@tv3/interfaces/requests/branding.request'
import { Branding } from '@tv3/models/account/branding'

@Component({
  selector: 'app-edit-branding-logo-dialog',
  templateUrl: './edit-branding-logo-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./edit-branding-logo-dialog.component.scss'],
})
export class EditBrandingLogoDialogComponent {
  logoCtrl = new FormControl('')

  constructor(
    public dialogRef: MatDialogRef<EditBrandingLogoDialogComponent>,
    private uploadFileDialog: CloudinaryUploadDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { preference: Preference },
    private store: Store<fromRoot.State>
  ) {
    this.logoCtrl.patchValue(R.pathOr('', ['branding', 'invoiceLogo'], this.data.preference))
  }

  close() {
    this.dialogRef.close()
  }

  upload() {
    this.uploadFileDialog
      .open({
        title: 'Upload Invoice Logo',
        maxUploads: 1,
        maxSize: 5 * 1024 * 1024,
        guide: `Only image files smaller than 5MB are allowed, We recommend a maximum image height of 70 pixels
        and a maximum image width of 500 pixels.`,
      })
      .subscribe(([logo]: [Attachment]) => {
        this.logoCtrl.patchValue(logo.url)
      })
  }

  onRemoveLogo() {
    this.logoCtrl.setValue('')
    const branding = new Branding(this.data.preference.branding)
    branding.invoiceLogo = ''
    this.store.dispatch(UpdateBranding({ request: branding.serialize() as BrandingRequest }))
  }

  onSave() {
    const imageUrl = this.logoCtrl.value
    imageUrl.replace('http:', 'https:')
    const branding = new Branding(this.data.preference.branding)
    branding.invoiceLogo = imageUrl
    this.store.dispatch(UpdateBranding({ request: branding.serialize() as BrandingRequest }))
    this.close()
  }
}
