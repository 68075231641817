import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CalendarListModule } from '@tokeet/ngx-calendar-list'

import { SharedModule } from '@tv3/shared/shared.module'
import { RentalRatesDialogComponent } from './rental-rates-dialog/rental-rates-dialog.component'
import { RentalRatesCalendarComponent } from './rental-rates-calendar/rental-rates-calendar.component'
import { RentalRatesCalendarCellComponent } from './rental-rates-calendar/rental-rates-calendar-cell/rental-rates-calendar-cell.component'
import { EditRateDialogComponent } from './edit-rate-dialog/edit-rate-dialog.component'
import { RatesTableComponent } from './rates-table/rates-table.component'
import { EditRateDialogService } from './edit-rate-dialog/edit-rate-dialog.service'
import { RentalRateSettingsComponent } from './rental-rate-settings/rental-rate-settings.component'
import { AccountRatesDialogComponent } from './account-rates-dialog/account-rates-dialog.component'
import { AccountRatesDialogService } from './account-rates-dialog/account-rates-dialog.service'
import { RentalRatesDialogService } from './rental-rates-dialog/rental-rates-dialog.service'
import { DiscountsSharedModule } from '@tv3/containers/discounts/discounts-shared.module'
import { RateActionsComponent } from './rate-actions/rate-actions.component'
import { CostEstimatorDialogComponent } from './cost-estimator-dialog/cost-estimator-dialog.component'
import { CostEstimatorDialogService } from './cost-estimator-dialog/cost-estimator-dialog.service'
import { ChannelAutomationsModule } from './channel-automations/channel-automations.module'
import { MinStayAutomationsModule } from './min-stay-automations/min-stay-automations.module'
import { GapNightAutomationsModule } from './gap-night-automations/gap-night-automations.module'
import { LastMinuteAutomationsModule } from './last-minute-automations/last-minute-automations.module'
import { GroupRatesTableComponent } from './group-rates-table/group-rates-table.component'
import { RentalRatesTableComponent } from './rental-rates-table/rental-rates-table.component'
import { DynamicRatesTableComponent } from './dynamic-rates-table/dynamic-rates-table.component'
import { RatePushAutomationsModule } from './rate-push-automations/rate-push-automations.module'

const modules = [
  //
  SharedModule,
  CommonModule,
  CalendarListModule,
  DiscountsSharedModule,
  ChannelAutomationsModule,
  MinStayAutomationsModule,
  GapNightAutomationsModule,
  GapNightAutomationsModule,
  LastMinuteAutomationsModule,
  RatePushAutomationsModule,
]

const components = [
  RentalRatesCalendarComponent,
  RentalRatesCalendarCellComponent,
  GroupRatesTableComponent,
  RatesTableComponent,
  RentalRatesTableComponent,
  RentalRateSettingsComponent,
  RentalRatesDialogComponent,
  DynamicRatesTableComponent,
  EditRateDialogComponent,
  AccountRatesDialogComponent,
  RateActionsComponent,
  CostEstimatorDialogComponent,
]

const services = [
  EditRateDialogService,
  RentalRatesDialogService,
  AccountRatesDialogService,
  CostEstimatorDialogService,
]

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components, ...modules],
  providers: [...services],
})
export class RatesSharedModule {}
