<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">{{ config.title }}</h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <app-booking-select
          [ctrl]="form.get('inquiry_id')"
          [required]="true"
          (selected)="selectedInquiry = $event"
        ></app-booking-select>
        <p [innerHtml]="config.help"></p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
      <i class="fal fa-check"></i> Select
    </button>
  </div>
</form>
