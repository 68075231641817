import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { ChannelPropertySettingService } from './channel-property-setting.service'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  CreateChannelPropertySetting,
  CreateChannelPropertySettingComplete,
  DeleteChannelPropertySetting,
  DeleteChannelPropertySettingComplete,
  GetChannelPropertySetting,
  GetChannelPropertySettingComplete,
  LoadChannelPropertySettings,
  LoadChannelPropertySettingsComplete,
  UpdateChannelPropertySetting,
  UpdateChannelPropertySettingComplete,
} from './channel-property-setting.actions'

@Injectable()
export class ChannelPropertySettingEffects {
  @Effect()
  all$ = this.actions$.pipe(
    ofType(LoadChannelPropertySettings),
    switchMap(() =>
      this.service.all().pipe(
        map((items) => LoadChannelPropertySettingsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreateChannelPropertySetting),
    switchMap(({ payload }) =>
      this.service.create(payload).pipe(
        map((item) => CreateChannelPropertySettingComplete({ item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  get$ = this.actions$.pipe(
    ofType(GetChannelPropertySetting),
    switchMap(({ id }) =>
      this.service.get(id).pipe(
        map((item) => GetChannelPropertySettingComplete({ item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateChannelPropertySetting),
    switchMap(({ id, payload }) =>
      this.service.update(id, payload).pipe(
        map((item) => UpdateChannelPropertySettingComplete({ update: { id, changes: item } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteChannelPropertySetting),
    switchMap(({ id }) =>
      this.service.delete(id).pipe(
        map((res) => DeleteChannelPropertySettingComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private service: ChannelPropertySettingService) {}
}
