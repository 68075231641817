import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { DiscountCode } from './discount-code.model'

export interface State extends EntityState<DiscountCode> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<DiscountCode> = createEntityAdapter<DiscountCode>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
