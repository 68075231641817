<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">{{ website.type }} Components</h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>

  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box overflow-hidden">
      <div class="box-body" *ngIf="website.type === websiteTypes.RentalWebsite">
        <h6 class="section-title border-top-0">Enable or disable sections of the website.</h6>
        <div class="row px-3">
          <div class="col-sm-3" *ngIf="!disabledSettingsForTemplate?.location">
            <mat-checkbox formControlName="location" class="mt-primary"> Location Page </mat-checkbox>
          </div>
          <div class="col-sm-3" *ngIf="!disabledSettingsForTemplate?.footermap">
            <mat-checkbox formControlName="footermap" class="mt-primary"> Mini Footer Map </mat-checkbox>
          </div>
          <div class="col-sm-3" *ngIf="!disabledSettingsForTemplate?.price">
            <mat-checkbox formControlName="price" class="mt-primary"> Price List </mat-checkbox>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-3" *ngIf="!disabledSettingsForTemplate?.calendar">
            <mat-checkbox formControlName="calendar" class="mt-primary"> Availability Calendar </mat-checkbox>
          </div>
          <div class="col-sm-3" *ngIf="!disabledSettingsForTemplate?.gallery">
            <mat-checkbox formControlName="gallery" class="mt-primary"> Photo Gallery Page </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="box-body" *ngIf="website.type === websiteTypes.GuestPortal">
        <h6 class="section-title border-top-0">Enable or disable sections of the website.</h6>
        <div class="row px-3">
          <div class="col-sm-3">
            <mat-checkbox formControlName="directions" class="mt-primary"> Directions </mat-checkbox>
          </div>
          <div class="col-sm-3">
            <mat-checkbox formControlName="gallery" class="mt-primary"> Photos </mat-checkbox>
          </div>
        </div>
        <div class="row px-3">
          <div class="col-sm-3">
            <mat-checkbox formControlName="map" class="mt-primary"> Map </mat-checkbox>
          </div>
          <div class="col-sm-3">
            <mat-checkbox formControlName="weather" class="mt-primary"> Weather </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="box-body">
        <h6 class="section-title border-top-0">Enable analytics and tracking.</h6>
        <div class="row px-3">
          <div class="col-sm-12">
            <mat-form-field2
              class="mat-block"
              matTooltip="Enter your Google Analytics tracking id and your website will track visitor activity into your GA account. Example, UA-100557501-1."
              matTooltipPosition="above"
            >
              <input matInput formControlName="ga" placeholder="Google Analytics Tracking ID:" />
            </mat-form-field2>
          </div>
          <div class="col-sm-12">
            <mat-form-field2
              class="mat-block"
              matTooltip="Enter your project token to start tracking customer interactions using mixpanel."
              matTooltipPosition="above"
            >
              <input matInput formControlName="mixpanel" placeholder="Mixpanel Project Token:" />
            </mat-form-field2>
          </div>
          <div class="col-sm-12">
            <mat-form-field2
              class="mat-block"
              matTooltip="Enter your Facebook pixel ID to start tracking customer interactions using Facebook. We currently only track the pageview event."
              matTooltipPosition="above"
            >
              <input matInput formControlName="fbpixel" placeholder="Facebook Pixel ID:" />
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
