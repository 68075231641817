import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

import { ChannelAutomationDialogComponent, ChannelAutomationDialogInput } from './channel-automation-dialog.component'

@Injectable()
export class ChannelAutomationDialogService {
  constructor(private dialog: MatDialog) {}

  public open(data: ChannelAutomationDialogInput = {}) {
    const defaultConfig: MatDialogConfig<ChannelAutomationDialogInput> = {
      width: '600px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data,
    }

    return this.dialog.open<ChannelAutomationDialogComponent, ChannelAutomationDialogInput>(
      ChannelAutomationDialogComponent,
      defaultConfig
    )
  }
}
