<form [formGroup]="form">
  <div class="my-3 border rounded overflow-hidden">
    <app-tiny-mce-editor
      #tinyMceEditor
      [maxHeight]="300"
      [minHeight]="250"
      [ctrl]="form.get('content')"
    ></app-tiny-mce-editor>
  </div>
  <button
    class="btn btn-secondary-info"
    (click)="onSave(form)"
    [disabled]="isLoading$ | async"
    *ngIf="!form.get('id').value; else save"
  >
    <i class="cs-icon cs-icon-left-down"></i> Add To List
  </button>
  <ng-template #save>
    <button
      class="btn btn-outline btn-default-outline"
      matTooltip="Clear text editor to add a new FAQ"
      (click)="form.reset()"
      [disabled]="isLoading$ | async"
    >
      <i class="far fa-times"></i> Clear
    </button>
    <button
      class="btn btn-secondary-info ml-3"
      matTooltip="Save FAQ changes every time you make any change."
      (click)="onSave(form)"
      [disabled]="isLoading$ | async"
    >
      <i class="far" [ngClass]="(isLoading$ | async) ? 'fa-spin fa-spinner' : 'fa-save'"></i> Save
    </button>
  </ng-template>
</form>
<div class="mt-4 border bg-white rounded overflow-hidden" *ngIf="faqItems.length > 0">
  <mat-table #table matSort multiTemplateDataRows [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Title</mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-black text-bold">
        <div class="mr-1">
          <i *ngIf="element !== expandedItem" class="fas fa-arrow-right"></i>
          <i *ngIf="element === expandedItem" class="fas fa-arrow-down"></i>
        </div>
        FAQ {{ getItemIndex(element.id) + 1 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="preBooking">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Pre Booking</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information before booking confirmation?"
          [checked]="element.preBooking"
          (valueChange)="onTogglePreBooking(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="postBooking">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Post Booking</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information after booking confirmation?"
          [checked]="element.postBooking"
          (valueChange)="onTogglePostBooking(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="postCheckin">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Post Checkin</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information after guest checkin?"
          [checked]="element.postCheckin"
          (valueChange)="onTogglePostCheckin(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <a href="javascript:;" class="" matTooltip="Edit this FAQ" (click)="onEdit(element)"
          ><i class="cs-icon cs-icon-pencil"></i
        ></a>
        <a href="javascript:;" class="text-danger" matTooltip="Delete this FAQ" (click)="onDelete(element.id)"
          ><i class="cs-icon cs-icon-trash"></i
        ></a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
      <mat-cell *matCellDef="let detail" class="py-3">
        <app-show-more [content]="detail.content | html2Text" [maxLength]="500"></app-show-more>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="expandedItem = expandedItem === row ? null : row"
    ></mat-row>
    <mat-row
      *matRowDef="let row; columns: ['content']"
      [@detailExpand]="row?.id == expandedItem?.id ? 'expanded' : 'collapsed'"
      style="overflow: hidden"
    >
    </mat-row>
  </mat-table>
  <app-empty-table type="AIDocuments" *ngIf="!dataSource.data?.length"></app-empty-table>

  <mat-paginator2
    #paginator
    [length]="dataSource?.data?.length"
    [pageSizeOptions]="[5, 10, 20, 50]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>
</div>
