import { Pipe, PipeTransform } from '@angular/core'
import { LastMinuteAutomation } from '@tokeet-frontend/rate-automations'
import * as lodash from 'lodash'

@Pipe({
  name: 'lastMinuteDec',
})
export class LastMinuteDecPipe implements PipeTransform {
  transform(item: LastMinuteAutomation): string {
    return lodash
      .chain(item.windows)
      .map(
        (t) =>
          `${t.days_before} Days before, ${t.min_nights} Nights, Adjustment: ${t.adjust_abs || `${t.adjust_pct}%`}.`
      )
      .join(', ')
      .value()
  }
}
