import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { countries, Destroyable, isSomething, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectGuestById } from '@tv3/store/guest/guest.selectors'
import {
  AddGuestEmail,
  AddGuestPhone,
  DeleteGuestEmail,
  DeleteGuestPhone,
  UpdateGuest,
  UpdateGuestItem,
} from '@tv3/store/guest/guest.actions'
import { Guest } from '@tv3/store/guest/guest.model'
import { MultipleItemsDialogService } from '@tv3/containers/guests/edit/multiple-items/multiple-items-dialog.service'
import * as R from 'ramda'

@Component({
  selector: 'app-edit-guest',
  templateUrl: './edit-guest-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./edit-guest-dialog.component.scss'],
})
export class EditGuestDialogComponent extends Destroyable implements OnInit {
  guestForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', [Validators.required]],
    phone: [],
    address: this.fb.group({
      address: [''],
      city: [''],
      state: [''],
      country: [],
      country_code: [],
      postal_code: [''],
    }),
  })

  guest: Guest

  get addressForm() {
    return this.guestForm.get('address') as FormGroup
  }

  countryList = countries

  constructor(
    public dialogRef: MatDialogRef<EditGuestDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { guestId: string },
    private multipleItemsDialogService: MultipleItemsDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectGuestById, { id: this.data.guestId }), untilDestroy(this)).subscribe((guest) => {
      this.setGuestForm(guest)
      this.guest = guest
    })
  }

  setGuestForm(guest: Guest) {
    if (!guest) {
      return
    }
    const address = guest.address
    this.guestForm.patchValue(
      {
        name: guest.name,
        email: guest.primaryEmail || '',
        phone: guest.phone,
        address: {
          address: address?.address,
          city: address?.city,
          country: address?.country,
          country_code: address?.countryCode,
          state: address?.state,
          postal_code: address?.postalCode,
        },
      },
      { emitEvent: false }
    )
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(
      UpdateGuest({
        guestId: this.data.guestId,
        form: form.getRawValue(),
      })
    )
    this.close()
  }

  editEmail() {
    if (this.guest) {
      const config = {
        height: 'auto',
        data: {
          type: 'email',
          label: 'Edit Guest Emails',
          itemLabel: 'Email',
          newItemLabel: 'Add email',
          primaryItem: () => this.guest.primaryEmailView,
          items: () => Object.keys(this.guest.emailView),
          makePrimary: (item) => {
            this.store.dispatch(UpdateGuestItem({ guestId: this.guest.id, data: { email: item } }))
          },
          makePrimaryLabel: `Make this email the guest's primary email.`,
          deleteItem: (item) => {
            this.store.dispatch(DeleteGuestEmail({ guestId: this.guest.id, data: { email: item } }))
          },
          addItems: (items) => {
            this.store.dispatch(AddGuestEmail({ guestId: this.guest.id, data: items }))
          },
          validators: [Validators.email],
          icon: 'fal fa-user-edit',
        },
      }
      this.multipleItemsDialogService.open(config)
    }
  }

  editPhone() {
    if (this.guest) {
      const config = {
        height: 'auto',
        data: {
          type: 'phone',
          label: 'Edit Guest Phones',
          itemLabel: 'Phone',
          newItemLabel: 'Add phone',
          primaryItem: () => this.guest.phone,
          items: () =>
            R.pipe(
              (arr: string[]) => (this.guest.phone ? [...arr, this.guest.phone] : arr),
              R.map(String),
              R.uniq
            )(this.guest.phones),
          makePrimary: (item) => {
            this.store.dispatch(
              UpdateGuestItem({
                guestId: this.guest.id,
                data: {
                  phone: item,
                  phones: [...this.guest.phones, this.guest.phone].filter((phone) => {
                    return isSomething(phone) && phone !== item
                  }),
                },
              })
            )
          },
          makePrimaryLabel: `Make this phone number this guest's primary phone.`,
          deleteItem: (item) => {
            this.store.dispatch(DeleteGuestPhone({ guestId: this.guest.id, data: { phone: item } }))
          },
          addItems: (items) => {
            this.store.dispatch(AddGuestPhone({ guestId: this.guest.id, data: items }))
          },
          icon: 'fal fa-user-edit',
        },
      }
      this.multipleItemsDialogService.open(config)
    }
  }
}
