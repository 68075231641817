import { Component, HostListener, Input } from '@angular/core'
import { TaskResponse } from '@tv3/store/task/task.model'
import { UpdateTask } from '@tv3/store/task/task.actions'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-task-star-btn',
  templateUrl: './task-star-btn.component.html',
  styleUrls: ['./task-star-btn.component.scss'],
})
export class TaskStarBtnComponent {
  @Input() task: TaskResponse

  @HostListener('click') onToggle() {
    this.store.dispatch(
      UpdateTask({
        id: this.task.pkey,
        form: { marked: !!this.task.marked ? 0 : 1 },
      })
    )
  }

  constructor(private store: Store<any>) {}
}
