import { AddNewTagsComplete, LoadTagsComplete } from './tag.actions'
import { initialState, State } from './tag.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(AddNewTagsComplete, LoadTagsComplete, (state, { id, tags }) =>
    R.merge(state, {
      ...tags,
      id: id,
      isLoaded: true,
    })
  )
)

export function tagsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}
