<div class="modal-dialog modal-lg create-invoice-page" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-fill">
        <div class="modal-title">
          {{ data.isCopy ? 'Copy Invoice' : 'Add Invoice' }}
        </div>
        <div class="flex-fill"></div>

        <button
          type="button"
          class="btn btn-secondary-info"
          (click)="onSaveInvoice(form)"
          [disabled]="(isInvoiceUpdating$ | async) || (1 | isReadonlyRole)"
        >
          <span class="cs-icon cs-icon-save"></span> Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box p-3">
        <div class="mb-3">
          <app-invoice-details
            *ngIf="inquiry"
            [inquiry]="inquiry"
            (update)="onInvoiceDetailChanged($event)"
            [invoice]="invoice"
            [rootForm]="form"
            (close)="close()"
          >
          </app-invoice-details>
        </div>
        <h6>Invoice Details</h6>
        <div *ngIf="rental" class="d-flex flex-column gap-4">
          <app-invoice-item
            *ngFor="let item of invoice.invoiceItems; let idx = index; trackBy: trackByIndex"
            [rootForm]="form"
            [item]="item"
            [guests]="guests"
            [rental]="rental"
            [currency]="invoice?.currency"
            [removable]="idx"
            [invoiceType]="invoiceType"
            (itemChange)="onLineItemChanged(idx, $event)"
            (remove)="onRemoveLineItem(idx, $event)"
          ></app-invoice-item>
        </div>

        <div class="row mt-4">
          <div class="col-sm-8 col-xl-9">
            <button type="button" class="btn btn-secondary-info" (click)="onAddInvoiceItem()">
              <i class="far fa-plus"></i> Add Line Item
            </button>
            <app-invoice-notes
              *ngIf="rental?.id"
              [rootForm]="form"
              [notes]="invoice.notes"
              [paymentTerms]="invoice.paymentTerms"
              [paymentInstructions]="invoice.paymentInstructions"
              (update)="onInvoiceNotesChanged($event)"
              class="d-block mt-4"
            ></app-invoice-notes>
          </div>
          <div class="col-sm-4 col-xl-3 pl-0">
            <div
              class="rounded p-3 d-flex flex-column gap-4"
              *ngIf="invoice"
              style="background: #f0f8ff; border: 1px solid #badeff"
            >
              <div class="row text-light">
                <div class="col-6">Invoice Total</div>
                <div class="col-6 text-right">
                  {{ invoice.invoiceItems | invoiceTotal: true:guests | tokeetCurrency: invoice?.currency }}
                </div>
              </div>
              <div class="row text-light">
                <div class="col-6">Paid to Date</div>
                <div class="col-6 text-right">
                  <ng-container *ngIf="invoice.status !== invoiceStatuses.Paid">
                    {{ 0 | tokeetCurrency: invoice?.currency }}
                  </ng-container>
                  <ng-container *ngIf="invoice.status === invoiceStatuses.Paid">
                    {{ invoice.invoiceItems | invoiceTotal: true:guests | tokeetCurrency: invoice?.currency }}
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <strong>Balance ({{ invoice.currency?.code }})</strong>
                </div>
                <div class="col-6 text-right">
                  <span class="text-danger2">
                    <strong *ngIf="invoice.status !== invoiceStatuses.Paid">
                      {{ invoice.invoiceItems | invoiceTotal: true:guests | tokeetCurrency: invoice?.currency }}
                    </strong>
                    <strong *ngIf="invoice.status === invoiceStatuses.Paid">
                      {{ 0 | tokeetCurrency: invoice?.currency }}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
