import { Injectable } from '@angular/core'
import { isSomething, selectSomeOnce, User } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as R from 'ramda'
import { selectActivePlanStatusView } from '@tv3/store/plan/plan.selectors'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import * as moment from 'moment'
import { isFunction } from 'lodash'

declare const window: any

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(private store: Store<any>) {}

  boot(user: User) {
    if (!isSomething(user)) {
      return
    }
    this.store.pipe(selectSomeOnce(selectActivePlanStatusView)).subscribe((status) => {
      const payload = {
        user_id: user.id,
        account: user.account,
        email: user.primaryEmail,
        name: `${user.firstName} ${user.lastName}`,
        created_at: user.created,
        sub_name: status?.planName,
        sub_id: status?.planId,
        sub_fee: status.price,
        sub_start_at: status.start,
        sub_until_at: status.until,
        sub_update_at: status.timestamp,
        trial_end_at: status.trial_end,
        card_id: '',
        sub_status: status.status === 'inactive' ? (status.delinquent ? 'past_due' : 'inactive') : status.status,
        phone: user.phone,
        rentals: R.pathOr(0, ['rentals'], status),
        users: R.pathOr(0, ['users'], status),
        role: userRoleToString(user.roles),
        products: status.products?.join(','),
        trial: status?.trial_end > moment().unix(),
      }
      if (isFunction(window.Intercom)) {
        window.Intercom('update', payload)
      }
    })
  }

  update(user: any) {
    if (!isSomething(user)) {
      return
    }
    this.store.pipe(selectSomeOnce(selectActivePlanStatusView)).subscribe((status) => {
      const payload = {
        user_id: user.id,
        account: user.account,
        email: user.primaryEmail,
        name: `${user.firstName} ${user.lastName}`,
        created_at: user.created,
        sub_name: status?.planName,
        sub_id: status?.planId,
        sub_fee: status.price,
        sub_start_at: status.start,
        sub_until_at: status.until,
        sub_update_at: status.timestamp,
        trial_end_at: status.trial_end,
        card_id: '',
        sub_status: status.status === 'inactive' ? (status.delinquent ? 'past_due' : 'inactive') : status.status,
        phone: user.phone,
        rentals: R.pathOr(0, ['rentals'], status),
        users: R.pathOr(0, ['users'], status),
        role: userRoleToString(user.roles),
        products: status.products?.join(','),
        trial: status?.trial_end > moment().unix(),
      }
      if (isFunction(window.Intercom)) {
        window.Intercom('update', payload)
      }
    })
  }

  track(event: string) {
    if (isFunction(window.Intercom)) {
      window.Intercom('trackEvent', event)
    }
  }

  shutdown() {
    if (isFunction(window.Intercom)) {
      window.Intercom('shutdown')
    }
  }
}
