<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h6 class="modal-title">Apply Fees</h6>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="onSave()" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="onClose()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="alert alert-secondary-info">
        <i class="fal fa-info-circle alert-icon"></i>
        <div class="alert-content">
          Apply fees to multiple rentals at once. Fees you specify here will be added to existing rental fees.<br />
          Be careful which fees you add, as this form is not used for removing fees, and further removal has to be done
          at individual rental level.
        </div>
      </div>
      <div class="container-box mt-3">
        <div class="filters-box">
          <app-select-search [items]="cities$ | async" [ctrl]="citiesCtrl" placeholder="Cities"></app-select-search>
          <app-select-search [items]="tags$ | async" [ctrl]="tagsCtrl" placeholder="Tags"></app-select-search>
          <div class="flex-fill"></div>
          <mat-form-field2 [showLabel]="false">
            <input matInput type="text" [formControl]="searchCtrl" placeholder="Search..." />
            <i
              matSuffix
              class="fal fa-minus-circle"
              (click)="onCancelFilters()"
              matTooltip="Clear all filters"
              matTooltipPosition="above"
            ></i>
          </mat-form-field2>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-holder table-responsive">
              <table class="table table-default table-striped">
                <mat-table matSort [dataSource]="dataSource">
                  <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected(selection, dataSource.data)"
                        [indeterminate]="selection.hasValue() && !isAllSelected(selection, dataSource.data)"
                      >
                      </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                      >
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.name" matTooltipPosition="above" class="text-truncate">
                        {{ element.name }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.descriptionView" matTooltipPosition="above" class="text-truncate">{{
                        element.descriptionView
                      }}</span>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="cityView">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <span [matTooltip]="element.cityView" matTooltipPosition="above" class="text-truncate">{{
                        element.cityView
                      }}</span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

                <mat-paginator2
                  [pageSize]="10"
                  [length]="dataSource?.data?.length"
                  [class.hidden]="isEmptyTable$ | async"
                  [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
                  [showFirstLastButtons]="true"
                >
                </mat-paginator2>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="container-box">
        <div class="header">
          <h6>
            Fees
            <i
              matTooltip="Define fees you wish to apply to rentals selected above"
              matTooltipPosition="above"
              class="fal fa-info-circle"
            ></i>
          </h6>
        </div>

        <div class="fees">
          <ng-container *ngFor="let fee of fees; let i = index">
            <app-booking-fee-form
              [form]="fee"
              [currencyCode]="currencyCode"
              (remove)="onRemove(i)"
            ></app-booking-fee-form>
          </ng-container>

          <button (click)="onAdd()" class="btn btn-secondary-info" type="button">
            <i class="far fa-plus"></i>
            Add Fee
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
