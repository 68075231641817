<nav class="top-bar-wrapper sticky-top">
  <div class="navbar top-header border-bottom align-items-center">
    <div class="d-flex align-items-center flex-fill">
      <app-search *ngxPermissionsOnly="['canUseSearch']" class="flex-1"></app-search>
    </div>
    <ul class="nav nav-right align-items-center gap-1">
      <li *ngxPermissionsOnly="['canSeeNotifications']" class="notification-holder">
        <app-notifications></app-notifications>
      </li>
      <li *ngxPermissionsOnly="['canSeeInquiries']" class="notification-holder">
        <app-messages-icon></app-messages-icon>
      </li>
      <li class="ml-2" *ngxPermissionsOnly="['canUseGlobalPush']">
        <a
          class="btn btn-secondary-info btn-circle"
          (click)="onPushRates()"
          matTooltip="Push Rates & Availability to all connected API channels."
          matTooltipPosition="below"
        >
          <i class="fal fa-house-signal text-lg"></i>
        </a>
      </li>
      <li class="ml-2">
        <button
          *ngxPermissionsOnly="['canUseSearch']"
          (click)="onOpenInventory()"
          matTooltip="Find available rentals within a date range."
          matTooltipPosition="below"
          type="button"
          class="btn btn-secondary-primary btn-circle"
        >
          <i class="cs-icon cs-icon-building"></i>
        </button>
      </li>
      <li class="ml-2" *ngxPermissionsOnly="['canUseCostEstimator']">
        <button
          type="button"
          class="btn btn-secondary-info2 btn-circle"
          (click)="onCostEstimator()"
          matTooltip="Estimate the cost of your booking."
          matTooltipPosition="below"
          matTooltipClass="fit-tooltip"
        >
          <i class="cs-icon cs-icon-calculator"></i>
        </button>
      </li>
      <li dropdown class="dropdown ml-2" *ngxPermissionsOnly="['canUseGlobalAdd']">
        <button
          type="button"
          class="btn btn-indigo btn-circle"
          dropdownToggle
          matTooltip="Quick option to add new items."
          matTooltipPosition="below"
        >
          <i class="far fa-plus"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-right global-actions-menu" *dropdownMenu>
          <li>
            <button
              matTooltip="Manually create a new booking."
              matTooltipPosition="left"
              (click)="onAddBooking()"
              (mouseenter)="icon1.classList.add('focus')"
              (mouseleave)="icon1.classList.remove('focus')"
            >
              <span class="btn btn-secondary-indigo-reverse text-lg btn-sm" #icon1>
                <i class="cs-icon cs-icon-calendar-plus2"></i>
              </span>
              Add Booking
            </button>
          </li>
          <li>
            <button
              matTooltip="Manually create a new inquiry."
              matTooltipPosition="left"
              (click)="onAddInquiry()"
              (mouseenter)="icon0.classList.add('focus')"
              (mouseleave)="icon0.classList.remove('focus')"
            >
              <span class="btn btn-secondary-info-reverse text-lg btn-sm" #icon0>
                <i class="cs-icon cs-icon-calendar-plus2"></i>
              </span>
              Add Inquiry
            </button>
          </li>
          <li>
            <button
              matTooltip="Block dates on your calendar and make them unavailable for booking."
              matTooltipPosition="left"
              (click)="onAddHoldEvent()"
              (mouseenter)="icon2.classList.add('focus')"
              (mouseleave)="icon2.classList.remove('focus')"
            >
              <span class="btn btn-secondary-danger-reverse text-lg btn-sm" #icon2>
                <i class="cs-icon cs-icon-calendar-ban"></i>
              </span>
              Add Hold
            </button>
          </li>
          <li class="dropdown-divider"></li>
          <li>
            <button
              matTooltip="Manually add a new guest"
              matTooltipPosition="left"
              (click)="onAddGuest()"
              (mouseenter)="icon3.classList.add('focus')"
              (mouseleave)="icon3.classList.remove('focus')"
            >
              <span class="btn btn-secondary-primary-reverse text-lg btn-sm" #icon3>
                <i class="cs-icon cs-icon-user-plus"></i>
              </span>
              Add Guest
            </button>
          </li>
          <li>
            <button
              matTooltip="Manually add a new AdvanceCM rental"
              matTooltipPosition="left"
              (click)="onAddRental()"
              (mouseenter)="icon4.classList.add('focus')"
              (mouseleave)="icon4.classList.remove('focus')"
            >
              <span class="btn btn-secondary-warning-reverse btn-sm" #icon4>
                <i class="cs-icon cs-icon-buildings"></i>
              </span>
              Add Rental
            </button>
          </li>
          <li>
            <button
              matTooltip="Manually create a new expense."
              matTooltipPosition="left"
              (click)="onAddExpense()"
              (mouseenter)="icon5.classList.add('focus')"
              (mouseleave)="icon5.classList.remove('focus')"
            >
              <span class="btn btn-secondary-info-reverse btn-sm" #icon5>
                <i class="cs-icon cs-icon-moneys"></i>
              </span>
              Add Expense
            </button>
          </li>
          <li>
            <button
              matTooltip="Manually create a new task."
              matTooltipPosition="left"
              (click)="onAddTask()"
              (mouseenter)="icon6.classList.add('focus')"
              (mouseleave)="icon6.classList.remove('focus')"
            >
              <span class="btn btn-secondary-success-reverse btn-sm text-lg" #icon6>
                <i class="cs-icon cs-icon-note-check"></i>
              </span>
              Add Task
            </button>
          </li>
          <li class="dropdown-divider"></li>
          <li>
            <button
              matTooltip="Add standard rates which are flat, constant rates & do not change over time."
              matTooltipPosition="left"
              (click)="onAddStandardRate()"
              (mouseenter)="icon7.classList.add('focus')"
              (mouseleave)="icon7.classList.remove('focus')"
            >
              <span class="btn btn-secondary-primary-reverse btn-sm text-lg" #icon7>
                <i class="cs-icon cs-icon-calculator"></i>
              </span>
              Add Standard Rate
            </button>
          </li>
          <li>
            <button
              matTooltip="Add dynamic rates which will adjust gradually and automatically from a low rate to a high rate as your overall rental occupancy level changes. "
              matTooltipPosition="left"
              (click)="onAddDynamicRate()"
              (mouseenter)="icon8.classList.add('focus')"
              (mouseleave)="icon8.classList.remove('focus')"
            >
              <span class="btn btn-secondary-info-reverse btn-sm text-lg" #icon8>
                <i class="cs-icon cs-icon-calculator"></i>
              </span>
              Add Dynamic Rate
            </button>
          </li>
          <li>
            <button
              matTooltip="Add group rates which apply to multiple rentals at once."
              matTooltipPosition="left"
              (click)="openGroupRates()"
              (mouseenter)="icon9.classList.add('focus')"
              (mouseleave)="icon9.classList.remove('focus')"
            >
              <span class="btn btn-secondary-success-reverse btn-sm" #icon9>
                <i class="fas fa-hands-usd fa-fw"></i>
              </span>
              Add Group Rate
            </button>
          </li>
          <li class="dropdown-divider"></li>
          <li>
            <button
              matTooltip="Connect iCal, API & custom channels."
              matTooltipPosition="left"
              (click)="onAddChannel()"
              (mouseenter)="icon10.classList.add('focus')"
              (mouseleave)="icon10.classList.remove('focus')"
            >
              <span class="btn btn-secondary-danger-reverse btn-sm" #icon10>
                <i class="fas fa-plug fa-fw"></i>
              </span>
              Add Channel
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <ul class="nav top-navbar border-bottom">
    <li *ngxPermissionsOnly="['canSeeDashboard']" routerLinkActive="active" class="nav-indigo">
      <a routerLink="/dashboard">
        <i class="cs-icon cs-icon-dashboard"></i>
        <span>Dashboard</span>
      </a>
    </li>
    <!-- <li *ngxPermissionsOnly="['canSeeRates']" routerLinkActive="active" class="nav-green"> -->
    <!--   <a routerLink="/rates"> -->
    <!--     <i class="cs-icon cs-icon-dollar-circle"></i> -->
    <!--     <span>Rates</span> -->
    <!--   </a> -->
    <!-- </li> -->
    <li *ngxPermissionsOnly="['canSeeRentals']" routerLinkActive="active" class="nav-red">
      <a routerLink="/rentals">
        <i class="cs-icon cs-icon-buildings"></i>
        <span>Rentals</span>
      </a>
    </li>
    <li *ngxPermissionsOnly="['canSeeChannels']" routerLinkActive="active" class="nav-blue">
      <a routerLink="/channels">
        <i class="cs-icon cs-icon-channels"></i>
        <span>Channels</span>
      </a>
    </li>
    <li *ngxPermissionsOnly="['canSeeAutomations']" routerLinkActive="active" class="nav-green">
      <a routerLink="/automations">
        <i class="cs-icon cs-icon-automations"></i>
        <span>Automations</span>
      </a>
    </li>
    <ng-container *ngxPermissionsOnly="['canSeeAddons']">
      <li
        dropdown
        container="body"
        placement="bottom left"
        class="dropdown nav-light-indigo"
        [class.active]="
          utility.isRouteActive('integrations') || utility.isRouteActive('websites') || utility.isRouteActive('widgets')
        "
      >
        <a dropdownToggle>
          <i class="cs-icon cs-icon-integrations"></i>
          <span>Integrations</span>
        </a>
        <ul *dropdownMenu class="dropdown-menu wide-dropdown-menu top-navbar-menu">
          <li class="text-uppercase text-light pt-0 pb-3 text-semi-bold border-bottom-0">Build a Website</li>
          <li class="text-uppercase text-light pt-0 pb-3 text-semi-bold border-bottom-0">
            Manage Your Revenue & Operations
          </li>
          <li
            *ngIf="canSee('webready')"
            [matTooltip]="(isWebreadyInactive$ | async) ? 'Go to app store to update subscription for Webready' : ''"
            matTooltipPosition="above"
            [class.inactiveAddon]="isWebreadyInactive$ | async"
          >
            <a [href]="webreadyUrl()" target="_blank">
              <div class="icon-holder">
                <img class="rategenie-icon" src="//cdn.tokeet.com/images/Appstore_logos/webready.svg" alt="" />
              </div>
              <div>
                <h5 class="title">Webready</h5>
                <p>The best website builder for rentals</p>
              </div>
            </a>
          </li>
          <li
            *ngIf="canSee('rategenie')"
            [matTooltip]="(isRateGenieInactive$ | async) ? 'Go to app store to update subscription for Rategenie' : ''"
            matTooltipPosition="above"
            [class.inactiveAddon]="isRateGenieInactive$ | async"
          >
            <a [href]="rategenieUrl()" target="_blank">
              <div class="icon-holder">
                <img class="rategenie-icon" src="//cdn.tokeet.com/icons/rategenie_icon.png" alt="" />
              </div>
              <div>
                <h5 class="title">Rategenie</h5>
                <p>The best rate optimization tool</p>
              </div>
            </a>
          </li>
          <li role="menuitem" *ngxPermissionsOnly="['canSeeWebsites']">
            <a class="" routerLink="/websites">
              <div class="icon-holder text-primary">
                <i class="fal fa-globe"></i>
              </div>
              <div>
                <h5 class="title">Websites</h5>
                <p>Free website builder</p>
              </div>
            </a>
          </li>
          <li
            *ngIf="canSee('checklist')"
            [matTooltip]="(isChecklistInactive$ | async) ? 'Go to app store to update subscription for Checklist' : ''"
            matTooltipPosition="above"
            [class.inactiveAddon]="isChecklistInactive$ | async"
          >
            <a [href]="checklistUrl()" target="_blank">
              <div class="icon-holder">
                <img src="//cdn.tokeet.com/images/Appstore_logos/checklist.svg" alt="" />
              </div>
              <div>
                <h5 class="title">Checklist</h5>
                <p>Manage your property tasks and maintenance</p>
              </div>
            </a>
          </li>
          <li role="menuitem" *ngxPermissionsOnly="['canSeeWidgets']" class="border-bottom-0">
            <a class="" routerLink="/widgets">
              <div class="icon-holder text-primary">
                <i class="fal fa-layer-plus"></i>
              </div>
              <div>
                <h5 class="title">Widgets</h5>
                <p>Setup your AdvanceCM calendar, inquiry, and other widgets.</p>
              </div>
            </a>
          </li>
          <ng-container *ngIf="canSee('ownercenter')">
            <li *ngxPermissionsOnly="['canSeeOwnerCenter']" class="border-bottom-0">
              <a [href]="ownerCenterUrl()" target="_blank">
                <div class="icon-holder">
                  <img src="/assets/images/icons/owners-icon.png" alt="" />
                </div>
                <div>
                  <h5 class="title">Owner Center</h5>
                  <p>Manage invoices, statements, & owner relationship</p>
                </div>
              </a>
            </li>
          </ng-container>
          <li class="text-uppercase text-light py-3 text-semi-bold border-bottom-0">Manage & Automate Workflow</li>
          <li class="text-uppercase text-light py-3 text-semi-bold border-bottom-0">Generated Reports</li>
          <li
            *ngIf="canSee('signature')"
            [matTooltip]="(isSignatureInactive$ | async) ? 'Go to app store to update subscription for Signature' : ''"
            matTooltipPosition="above"
            [class.inactiveAddon]="isSignatureInactive$ | async"
          >
            <a [href]="signatureUrl()" target="_blank">
              <div class="icon-holder">
                <img src="/assets/images/icons/signature-icon.png" alt="" />
              </div>
              <div>
                <h5 class="title">Signature</h5>
                <p>Buid-in eSignature tool</p>
              </div>
            </a>
          </li>
          <li
            *ngIf="canSee('power_reporting')"
            [matTooltip]="
              (isPowerReportingInactive$ | async) ? 'Go to app store to update subscription for Power Reporting' : ''
            "
            matTooltipPosition="above"
            [class.inactiveAddon]="isPowerReportingInactive$ | async"
          >
            <a [href]="powerReportingUrl()" target="_blank">
              <div class="icon-holder">
                <img class="rategenie-icon" src="assets/integrations/rental-ninja-logo.svg" alt="" />
              </div>
              <div>
                <h5 class="title">Power Reporting</h5>
                <p>Build-in reporting tool</p>
              </div>
            </a>
          </li>
          <li
            *ngIf="canSee('automata')"
            [matTooltip]="(isAutomataInactive$ | async) ? 'Go to app store to update subscription for Automata' : ''"
            matTooltipPosition="above"
            [class.inactiveAddon]="isAutomataInactive$ | async"
            class="border-bottom-0"
          >
            <a [href]="automataUrl()" target="_blank">
              <div class="icon-holder">
                <img class="automata-icon" src="//cdn.tokeet.com/icons/automata_icon.png" alt="" />
              </div>
              <div>
                <h5 class="title">Automata</h5>
                <p>Create custom automated workflow</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeReports']" class="border-bottom-0">
            <a [href]="reportsUrl()" target="_blank">
              <div class="icon-holder">
                <img src="/assets/images/icons/margins-icon.png" alt="" />
              </div>
              <div>
                <h5 class="title">Margins</h5>
                <p>The most advanced reporting application</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeIntegrations']" class="full-width border-0 pb-0">
            <div routerLink="/integrations" class="btn btn-secondary-primary w-100">
              <i class="cs-icon cs-icon-right-down"></i> More Integrations
            </div>
          </li>
        </ul>
      </li>
    </ng-container>

    <li *ngxPermissionsOnly="['canSeeAppStore']" routerLinkActive="active" class="nav-teal">
      <a
        [href]="appStoreUrl()"
        (click)="onVisitAppStore()"
        target="_blank"
        matTooltip="Find & Subscribe to a variety of applications that enhance your experience & grow your business."
        matTooltipPosition="below"
      >
        <i class="cs-icon cs-icon-store"></i>
        <span>App Store</span>
      </a>
    </li>

    <ng-container *ngxPermissionsOnly="['canSeeSettings']">
      <li
        dropdown
        container="body"
        placement="bottom right"
        class="dropdown nav-pink"
        [class.active]="
          utility.isRouteActive('settings') ||
          utility.isRouteActive('payments') ||
          utility.isRouteActive('sharing') ||
          utility.isRouteActive('templates') ||
          utility.isRouteActive('stealth') ||
          utility.isRouteActive('feeds')
        "
      >
        <a dropdownToggle>
          <i class="cs-icon cs-icon-cog"></i>
          <span>Settings</span>
        </a>
        <ul *dropdownMenu class="dropdown-menu wide-dropdown-menu dropdown-menu-right top-navbar-menu">
          <ng-container *ngIf="aiResponderSub$ | async as aiResponderSub">
            <li *ngxPermissionsOnly="['canAISettings']">
              <a
                href="javascript:;"
                (click)="onAISettings(aiResponderSub.isValid)"
                [ngStyle]="!aiResponderSub?.isValid ? { filter: 'grayscale(1)', opacity: '0.9' } : {}"
                [matTooltip]="aiResponderSub?.isValid ? '' : 'Please subscribe it before you can use this feature.'"
              >
                <div class="icon-holder text-primary">
                  <svg-icon src="assets/images/icons/ai.svg" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
                </div>
                <div>
                  <h5 class="title">AI Settings</h5>
                  <p>Automatic bot response and feed data to the bot.</p>
                </div>
              </a>
            </li>
          </ng-container>
          <li *ngxPermissionsOnly="['canSeeDataFeeds']">
            <a routerLink="/feeds">
              <div class="icon-holder text-info">
                <svg-icon src="cloud-download" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Data Feeds</h5>
                <p>Extract data from AdvanceCM with a single, secure URL.</p>
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/settings/branding">
              <div class="icon-holder text-indigo">
                <svg-icon src="custom-theme" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Branding Info</h5>
                <p>Customize AdvanceCM to match your company look and feel.</p>
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/settings/files">
              <div class="icon-holder text-primary">
                <svg-icon src="folder" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">File Cabinet</h5>
                <p>Upload and save files that every user may access.</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeePayments']">
            <a routerLink="/payments">
              <div class="icon-holder text-success">
                <svg-icon src="card" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Payments</h5>
                <p>Allow users to pay you online and process credit cards.</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeSharing']">
            <a routerLink="/sharing">
              <div class="icon-holder text-indigo">
                <svg-icon src="calendar-share" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Shared Content</h5>
                <p>Share your calendars and other content via URL.</p>
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/settings/email">
              <div class="icon-holder text-warning">
                <svg-icon src="envelope" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Email Options</h5>
                <p>Configure automated email forwarding.</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeMessagingSettings']">
            <a href="javascript:;" (click)="onMessagingSettings()">
              <div class="icon-holder text-warning">
                <svg-icon src="envelope" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Message Settings</h5>
                <p>Configure sms, email and social media messaging settings.</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeTemplates']">
            <a routerLink="/templates">
              <div class="icon-holder text-primary">
                <svg-icon src="template" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Templates</h5>
                <p>Create message templates to automatically send to users and guests.</p>
              </div>
            </a>
          </li>
          <li *ngxPermissionsOnly="['canSeeStealth']">
            <a routerLink="/stealth">
              <div class="icon-holder text-danger">
                <svg-icon src="stealth" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">Stealth</h5>
                <p>Stealth mode unlists your Airbnb rental during specific hours of the day.</p>
              </div>
            </a>
          </li>
          <li>
            <a routerLink="/settings/user-settings">
              <div class="icon-holder text-info">
                <svg-icon src="user-cog" [svgStyle]="{ 'width.px': 25 }"></svg-icon>
              </div>
              <div>
                <h5 class="title">User Settings</h5>
                <p>Create and manage users, update their passwords, and set their user type.</p>
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</nav>
