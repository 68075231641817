import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Destroyable, epochToViewUTC } from '@tokeet-frontend/tv3-platform'
import { DiscountCode, DiscountCodeChangeValues, DiscountCodeService } from '@tokeet-frontend/discount-codes'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { map } from 'lodash'
import { TableType } from '@tv3/shared/empty-table/table-type'

@Component({
  selector: 'app-discount-code-logs',
  templateUrl: './discount-code-logs.component.html',
  styleUrls: ['./discount-code-logs.component.scss'],
})
export class DiscountCodeLogsComponent extends Destroyable implements OnInit {
  @Input() item: Partial<DiscountCode>

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['date', 'user', 'message']

  dataSource = new MatTableDataSource<{ date: number; message: string }>()

  isEmptyTable$ = isEmptyTable(this.dataSource)

  tableType = TableType.DiscountCodeLogs

  constructor(private discountCodeService: DiscountCodeService) {
    super()
  }

  ngOnInit(): void {
    this.dataSource.data = []
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.discountCodeService.get(this.item.id).subscribe((item) => {
      this.dataSource.data = map(item.changes || [], ({ before, after, update, user }) => {
        return {
          date: update,
          user,
          message: `BEFORE: ` + this.getChangeMessage(before) + '. ' + `AFTER: ` + this.getChangeMessage(after),
        }
      })
    })
  }

  getChangeMessage(change: DiscountCodeChangeValues) {
    if (!change) {
      return ''
    }
    const str = []
    if (change.max_uses) {
      str.push(`Max uses: ` + change.max_uses)
    }
    if (change.start) {
      str.push(`Start:` + epochToViewUTC(change.start))
    }

    if (change.expires) {
      str.push(`Expires:` + epochToViewUTC(change.expires))
    }

    return str.join(', ')
  }
}
