import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import {
  AddTask,
  AddTaskComplete,
  AddTasks,
  AddTasksComplete,
  ChangeTaskStatus,
  ChangeTaskStatusComplete,
  ChangeTasksStatus,
  ChangeTasksStatusComplete,
  DeleteTask,
  DeleteTaskComplete,
  DeleteTasks,
  DeleteTasksComplete,
  LoadTasks,
  LoadTasksComplete,
  UpdateTask,
  UpdateTaskComplete,
  ArchiveTasks,
  ArchiveTasksComplete,
  UnarchiveTasks,
  UnarchiveTasksComplete,
  LoadTasksByIds,
  LoadTasksByIdsComplete,
} from './task.actions'
import { TaskService } from './task.service'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectInquiries } from '@tv3/store/inquiry/inquiry.selectors'
import { TaskView } from './task.model'
import { FetchInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { Toaster, selectOnce } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

@Injectable()
export class TaskEffects {
  @Effect()
  loadTasks$ = this.actions$.pipe(
    ofType(LoadTasks),
    switchMap(() =>
      this.tasks.all().pipe(
        switchMap((tasks) =>
          this.store.pipe(
            selectOnce(selectInquiries),
            map((inquiries) => {
              const taskInquiryIds = lodash.uniq(lodash.map(tasks, (t) => t.inquiry_id).filter((id) => !!id))
              const inquiryIds = lodash.map(inquiries, (t) => t.id)
              const missingInquiryIds = lodash.difference(taskInquiryIds, inquiryIds)
              return {
                missingInquiryIds,
                tasks,
              }
            })
          )
        ),
        switchMap(({ missingInquiryIds, tasks }) => {
          const actions: any[] = [LoadTasksComplete({ tasks })]
          if (missingInquiryIds.length) {
            actions.push(FetchInquiries({ ids: missingInquiryIds }))
          }
          return actions
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  // load tasks by ids
  loadTasksByIds$ = this.actions$.pipe(
    ofType(LoadTasksByIds),
    switchMap(({ ids }) =>
      this.tasks.getTasks(ids).pipe(
        map((tasks) => LoadTasksByIdsComplete({ tasks })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addTask$ = this.actions$.pipe(
    ofType(AddTask),
    concatMap(({ form }) => {
      return this.tasks.add(form).pipe(
        tap(() => this.toast.success('Task created successfully.')),
        map((task) => AddTaskComplete({ task })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  addTasks$ = this.actions$.pipe(
    ofType(AddTasks),
    switchMap(({ requests }) =>
      this.tasks.addTasks(requests).pipe(
        tap(() => this.toast.success('Tasks created successfully.')),
        map((tasks: TaskView[]) => AddTasksComplete({ tasks })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateTask$ = this.actions$.pipe(
    ofType(UpdateTask),
    switchMap(({ id, form }) =>
      this.tasks.update(id, form).pipe(
        tap(() => this.toast.success('Task updated successfully.')),
        map((task) => UpdateTaskComplete({ update: { id: task.pkey, changes: task } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveTasks$ = this.actions$.pipe(
    ofType(ArchiveTasks),
    switchMap(({ ids }) =>
      this.tasks.archiveTasks(ids).pipe(
        tap(() => this.toast.success(ids.length > 1 ? 'Tasks archived successfully.' : 'Task archived successfully.')),
        map((task) => ArchiveTasksComplete({ updates: ids.map((id) => ({ id, changes: { archived: 1 } })) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unarchiveTasks$ = this.actions$.pipe(
    ofType(UnarchiveTasks),
    switchMap(({ ids }) =>
      this.tasks.unarchiveTasks(ids).pipe(
        tap(() =>
          this.toast.success(ids.length > 1 ? 'Tasks unarchived successfully.' : 'Task unarchived successfully.')
        ),
        map((task) => UnarchiveTasksComplete({ updates: ids.map((id) => ({ id, changes: { archived: 0 } })) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteTask$ = this.actions$.pipe(
    ofType(DeleteTask),
    mergeMap(({ id }) =>
      this.tasks.delete(id).pipe(
        map(() => DeleteTaskComplete({ id })),
        tap(() => this.toast.success('Task deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteTasks$ = this.actions$.pipe(
    ofType(DeleteTasks),
    mergeMap(({ ids }) =>
      this.tasks.deleteMultiple(ids).pipe(
        map((task) => DeleteTasksComplete({ ids })),
        tap(() => this.toast.success('Tasks deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  status$ = this.actions$.pipe(
    ofType(ChangeTaskStatus),
    mergeMap(({ status, id }) =>
      this.tasks.changeStatus(status, id).pipe(
        tap(() => this.toast.success('Task updated successfully.')),
        map(() => ChangeTaskStatusComplete({ update: { id, changes: { status } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  statuses$ = this.actions$.pipe(
    ofType(ChangeTasksStatus),
    mergeMap(({ status, ids }) =>
      this.tasks.changeStatuses(status, ids).pipe(
        tap(() => this.toast.success('Tasks updated successfully.')),
        map(() => ChangeTasksStatusComplete({ update: ids.map((id) => ({ id, changes: { status } })) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toast: Toaster,
    private store: Store<fromRoot.State>,
    private tasks: TaskService
  ) {}
}
