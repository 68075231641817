import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { AuthService } from '../services/auth.service'
import { Observable } from 'rxjs'
import { NgxRolesService } from 'ngx-permissions'

@Injectable({ providedIn: 'root' })
export class UnauthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private ngxRolesService: NgxRolesService, private router: Router) {}

  canActivate() {
    if (!this.authService.isAuthenticated) return true

    this.router.navigate(['/calendar/multi'])
    return false
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate()
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
