<div class="modal-header">
  <h5 class="modal-title">{{ (app$ | async)?.name }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<ng-container *ngIf="isAppLoading$ | async; else appContent">
  <app-loading-section></app-loading-section>
</ng-container>

<ng-template #appContent>
  <div class="modal-body p-0 border rounded overflow-hidden">
    <div *ngIf="app$ | async as app">
      <ngb-carousel [interval]="-1">
        <ng-container *ngIf="showingImageType === 'desktop'">
          <ng-container *ngFor="let video of app.videos">
            <ng-template ngbSlide *ngIf="getVideoUrl | call : sanitizer : video as url">
              <div class="picsum-img-wrapper">
                <iframe
                  [src]="url"
                  class="w-100 h-100"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-template
          *ngFor="let image of getImagesByType | call : app.images : showingImageType; let index = index"
          ngbSlide
        >
          <div class="picsum-img-wrapper" [class.mobileWrapper]="showingImageType === 'mobile'">
            <img
              [src]="image.url"
              alt="App Screenshot"
              (click)="openImageViewer(getImagesByType(app.images, showingImageType), index)"
            />
          </div>
        </ng-template>
      </ngb-carousel>
      <div class="p-3 bg-active2">
        <div class="mb-2">
          <app-price
            *ngIf="availability$ | async; let availability"
            [planId]="availability?.plan?.id"
            [app]="app"
            [strategy]="availability?.plan?.strategy"
            [amount]="availability.plan?.amount"
            class="badge badge-secondary-info badge-pill text-md"
          ></app-price>
          <i class="far fa-spin fa-spinner text-xl text-info" *ngIf="isAvailabilityLoading$ | async"></i>
        </div>
        <div [innerHTML]="app.description"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="availability$ | async as availability; else noAvailability">
      <ng-template
        [ngTemplateOutlet]="noAvailability"
        *ngIf="availability.status === statuses.NotAvailable"
      ></ng-template>
      <ng-template [ngTemplateOutlet]="paidOnly" *ngIf="availability.status === statuses.PaidOnly"></ng-template>
      <div class="flex-fill"></div>
      <ng-container *ngIf="availability.status === statuses.Available">
        <button (click)="onAddCard()" class="btn btn-secondary-primary" *ngIf="!isCardAdded">
          <i class="cs-icon cs-icon-card"></i>
          Add Card
        </button>
        <button
          (click)="subscribe(availability.plan)"
          class="btn btn-secondary-info"
          [disabled]="!isCardAdded || subscribing"
          matTooltip="Please add card before you can subscribe it"
          [matTooltipDisabled]="isCardAdded"
        >
          <i *ngIf="!subscribing" class="far fa-plus"></i>
          <i *ngIf="subscribing" class="far fa-spin fa-spinner"></i>
          Subscribe
        </button>
      </ng-container>

      <button
        *ngIf="availability.status === statuses.Added"
        class="btn btn-info"
        disabled
        matTooltip="You have subscribed to this."
      >
        <i class="far fa-check"></i>
        Subscribed
      </button>
    </ng-container>
    <ng-template #noAvailability>
      <div *ngIf="!(isAvailabilityLoading$ | async)" class="alert alert-secondary-danger w-100">
        This app is not available
      </div>
    </ng-template>
    <ng-template #paidOnly>
      <div *ngIf="!(isAvailabilityLoading$ | async)" class="alert alert-secondary-danger w-100">
        This app is available for paid users only.
      </div>
    </ng-template>
  </div>
</ng-template>
