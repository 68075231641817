import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import {
  Destroyable,
  isSomething,
  loadRentals,
  Rental,
  RentalService,
  SaveForm,
  selectAllRentals,
  selectRentalCities,
  selectRentalTags,
  selectSomeOnce,
  TaxV3,
  taxV3ModalityLabels,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Component, OnInit, ViewChild } from '@angular/core'
import { TaxModalities } from '@tokeet/cost-resolver'
import * as R from 'ramda'
import { distinctUntilChanged, flatMap, mergeMap, startWith, toArray } from 'rxjs/operators'
import { isEmptyTable, localeCompareSort, percent, positive, validateMinNumber } from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { select, Store } from '@ngrx/store'
import { combineLatest, from, of } from 'rxjs'
import { SelectionModel } from '@angular/cdk/collections'

@Component({
  selector: 'app-taxes-overlay',
  templateUrl: './taxes-overlay.component.html',
  styleUrls: ['./taxes-overlay.component.scss'],
})
export class TaxesOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  dataSource = new MatTableDataSource<Rental>([])

  displayedColumns = ['select', 'name', 'description', 'cityView']

  isEmptyTable$ = isEmptyTable(this.dataSource)

  form = this.fb.group({
    taxes: this.fb.array([this.createTax()]),
  })

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  currencySymbol: string

  cities$ = this.store.pipe(select(selectRentalCities))
  tags$ = this.store.pipe(select(selectRentalTags))

  citiesCtrl = new FormControl([])
  tagsCtrl = new FormControl([])
  searchCtrl = new FormControl('')

  renderedData = []
  selection = new SelectionModel(true, [])

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TaxesOverlayComponent>,
    private store: Store<any>,
    private toaster: Toaster,
    private rentalService: RentalService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    combineLatest(
      this.store.pipe(selectSomeOnce(selectAllRentals)),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.citiesCtrl.valueChanges.pipe(startWith([])),
      this.searchCtrl.valueChanges.pipe(startWith(''))
    ).subscribe(([rentals, tags, cities, search]) => {
      if (isSomething(tags)) {
        rentals = R.filter(
          (r) =>
            R.any(
              R.equals(true),
              R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], r)), tags)
            ),
          rentals
        )
      }
      if (isSomething(cities)) {
        rentals = R.filter((r) => R.contains(R.pathOr('', ['address', 'city'], r), cities), rentals)
      }
      if (isSomething(search)) {
        rentals = R.filter((r) => {
          const city = R.pathOr('', ['address', 'city'], r)
          const name = r.name
          const description = r.descriptionView
          return [name, description, city].join('').toLowerCase().indexOf(search.toLowerCase()) !== -1
        }, rentals)
      }
      this.dataSource.data = rentals
    })

    this.dataSource['_renderData'].subscribe((rows) => {
      this.renderedData = rows
    })
    this.dataSource['_data'].subscribe(() => {
      this.selection = new SelectionModel(true, [])
    })

    this.form.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => R.equals(a.taxes.sort(), b.taxes.sort())),
        untilDestroy(this)
      )
      .subscribe((values) => {
        values.taxes.forEach((tax, index) => {
          const isPercent = this.taxes.at(index)['controls'].type.value === 'percent'

          if (isPercent) {
            this.taxes.at(index)['controls'].amount.setValidators([Validators.required, percent(true, false)])
          } else {
            this.taxes.at(index)['controls'].amount.setValidators([Validators.required, validateMinNumber(0)])
          }
          this.taxes.at(index)['controls'].amount.updateValueAndValidity()
        })
      })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { taxes } = form.getRawValue()
    if (R.isEmpty(this.selection.selected)) {
      this.toaster.warning('Please select at least one rental.')
      return
    }
    of(this.selection.selected)
      .pipe(
        flatMap((rental) => from(rental)),
        mergeMap((rental) => {
          const rentalTaxes = (rental.taxes || []).concat(taxes)
          return this.rentalService.updateTaxes(rental.id, rentalTaxes, true)
        }),
        toArray()
      )
      .subscribe(() => {
        this.toaster.success('Taxes updated successfully')
        this.store.dispatch(loadRentals({}))
        this.onClose()
      })
  }

  onClose() {
    this.dialogRef.close()
  }

  addTax(tax?: TaxV3) {
    this.taxes.push(this.createTax(tax))
  }

  removeTax(index: number) {
    this.taxes.removeAt(index)
  }

  private createTax(tax?: TaxV3) {
    return this.fb.group({
      amount: [R.pathOr(undefined, ['amount'], tax), [Validators.required]],
      name: [R.pathOr(undefined, ['name'], tax), [Validators.required]],
      type: [R.pathOr('percent', ['type'], tax)],
      modality: [R.pathOr(TaxModalities.PerStay, ['modality'], tax)],
    })
  }

  masterToggle() {
    if (this.isAllSelected(this.selection, this.dataSource.data)) {
      this.selection.clear()
    } else {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row)
      })
    }
  }

  isAllSelected<T>(selection: SelectionModel<T>, data: T[]) {
    return selection.selected?.length === data?.length
  }

  onCancelFilters() {
    this.tagsCtrl.reset()
    this.citiesCtrl.reset()
    this.searchCtrl.reset()
  }
}
