import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ListingValidationFieldResultItem } from '@tokeet-frontend/channels'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { RentalRatesDialogTab } from '@tv3/containers/rates/rental-rates-dialog/rental-rates-dialog.component'
import { RentalRatesDialogService } from '@tv3/containers/rates/rental-rates-dialog/rental-rates-dialog.service'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import * as lodash from 'lodash'

@Injectable()
export class RentalValidationFixHelperService {
  constructor(private store: Store<any>, private rentalRatesDialogService: RentalRatesDialogService) {}

  onFixRental(
    rental: Rental,
    field: ListingValidationFieldResultItem,
    validations: ListingValidationFieldResultItem[],
    fallbackHandle?: () => void
  ) {
    let activeTab = RentalDialogTab.Basic
    switch (field.field) {
      case 'rooms':
        activeTab = RentalDialogTab.Rooms
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab }))
        break
      case 'amenities':
      case 'bathrooms':
      case 'bedrooms':
      case 'sleep_max':
      case 'description':
      case 'size':
      case 'type':
      case 'code':
      case 'persons':
        activeTab = RentalDialogTab.Info
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab }))
        break
      case 'checkinout':
      case 'checkin':
      case 'instructions':
        activeTab = RentalDialogTab.Instructions
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab }))
        break
      case 'nightly':
      case 'baserate':
        this.rentalRatesDialogService.openSide({ rental, tab: RentalRatesDialogTab.Settings })
        break
      case 'address':
      case 'currency':
      case 'city':
      case 'state':
      case 'country_code':
      case 'country':
      case 'zip':
      case 'postal_code':
      case 'display_name':
      case 'shortDescription':
        activeTab = RentalDialogTab.Basic
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab }))
        break
      case 'images':
        activeTab = RentalDialogTab.Gallery
        const imageIds = lodash.filter(validations, (t) => t.field === 'images').map((t) => t.targetId)
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab, highlightedImages: imageIds }))
        break
      case 'taxes':
        activeTab = RentalDialogTab.Taxes
        this.store.dispatch(OpenRentalOverlay({ rental, activeTab }))
        break
      default:
        fallbackHandle?.()
    }
  }
}
