<div class="modal-header">
  <h5 class="modal-title">Property Summary <i class="fal fa-spin fa-spinner" *ngIf="isLoading"></i></h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <tr>
        <td colspan="2"><strong>Warnings: </strong></td>
      </tr>

      <tr>
        <td colspan="2">
          <textarea class="w-100" [rows]="summary?.warnings?.length || 1" disabled>{{ getText('warnings') }}</textarea>
        </td>
      </tr>
      <tr>
        <td colspan="2"><strong>Errors: </strong></td>
      </tr>

      <tr>
        <td colspan="2">
          <textarea class="w-100" [rows]="summary?.errors?.length || 1" disabled>{{ getText('errors') }}</textarea>
        </td>
      </tr>
    </table>
  </div>
</div>
