import { Component, Input, OnInit } from '@angular/core'
import { disabledSettingsForTemplate, Website, WebsiteTypes } from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { FormBuilder, FormGroup } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsiteComponents } from '@tv3/store/website/website.actions'

@Component({
  selector: 'app-website-components',
  templateUrl: './website-components.component.html',
  styleUrls: ['./website-components.component.scss'],
})
export class WebsiteComponentsComponent implements OnInit {
  @Input() website: Website
  disabledSettingsForTemplate: { [key: string]: boolean }

  websiteTypes = WebsiteTypes

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  form = this.fb.group({
    location: [],
    footermap: [],
    calendar: [],
    price: [],
    gallery: [],

    directions: [],
    map: [],
    weather: [],

    ga: [],
    mixpanel: [],
    fbpixel: [],
  })

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    const components = this.website.components || {}
    // 0 | 1 => true | false
    this.form.patchValue({
      ...components,
      location: !!components.location,
      footermap: !!components.footermap,
      calendar: !!components.calendar,
      price: !!components.price,
      gallery: !!components.gallery,
    })
    this.disabledSettingsForTemplate = disabledSettingsForTemplate[this.website.template]
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const formData = fb.getRawValue()
    const payload = {
      ...this.website.serialize(),
      components: {
        ...this.website.components,
        ...formData,
        directions: formData.directions ? 1 : 0,
        map: formData.map ? 1 : 0,
        weather: formData.weather ? 1 : 0,
        location: formData.location ? 1 : 0,
        footermap: formData.footermap ? 1 : 0,
        calendar: formData.calendar ? 1 : 0,
        price: formData.price ? 1 : 0,
        gallery: formData.gallery ? 1 : 0,
      },
    }

    this.store.dispatch(UpdateWebsiteComponents({ id: this.website.id, data: payload }))
  }
}
