import { createAction, props } from '@ngrx/store'
import { IntegrationAuthData, ThirdPartyIntegrationIdentifies } from './integration.model'

export const LoadAuthorizedIntegration = createAction(
  '[Integration] Load Authorized Integration',
  props<{ integration: ThirdPartyIntegrationIdentifies }>()
)
export const LoadAuthorizedIntegrationComplete = createAction(
  '[Integration] Load Authorized Integration Complete',
  props<{ data?: IntegrationAuthData | any; integration: ThirdPartyIntegrationIdentifies }>()
)

export const LoadAuthorizeIntegrationConfig = createAction(
  '[Integration] Load Authorized Integration Config',
  props<{ integration: ThirdPartyIntegrationIdentifies }>()
)
export const LoadAuthorizeIntegrationConfigComplete = createAction(
  '[Integration] Load Authorized Integration Config Complete',
  props<{ data: IntegrationAuthData; integration: ThirdPartyIntegrationIdentifies }>()
)

export const UnauthorizeIntegration = createAction(
  '[Integration] Unauthorized Integration',
  props<{ integration: ThirdPartyIntegrationIdentifies }>()
)
export const UnauthorizeIntegrationComplete = createAction(
  '[Integration] Unauthorized Integration Complete',
  props<{ integration: ThirdPartyIntegrationIdentifies }>()
)

export const ConnectWheelhouse = createAction('[Integration] Connect Wheelhouse', props<{ apiKey: string }>())
export const ConnectWheelhouseComplete = createAction('[Integration] Connect Wheelhouse Complete', props<{}>())

export const DisconnectWheelhouse = createAction('[Integration] Disconnect Wheelhouse', props<{}>())
export const DisconnectWheelhouseComplete = createAction('[Integration] Disconnect Wheelhouse Complete', props<{}>())
