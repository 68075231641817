import { createAction, props } from '@ngrx/store'
import { Forward } from './forward.model'
import { ForwardForm } from '@tv3/interfaces/forms/forward.form'

export const LoadForwards = createAction('[Forward] Load Forwards')
export const LoadForwardsComplete = createAction('[Forward] Load Forwards Complete', props<{ forwards: Forward[] }>())

export const AddForwards = createAction('[Forward] Add Forwards', props<{ form: ForwardForm }>())
export const AddForwardsComplete = createAction('[Forward] Add Forwards Complete', props<{ forwards: Forward[] }>())

export const DeleteForward = createAction('[Forward] Delete Forward', props<{ forward: Forward }>())
export const DeleteForwardComplete = createAction('[Forward] Delete Forward Complete', props<{ id: string }>())

export const DeleteForwards = createAction('[Forward] Delete Forwards', props<{ forwards: Forward[] }>())
export const DeleteForwardsComplete = createAction('[Forward] Delete Forwards Complete', props<{ ids: string[] }>())
