import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'

import { ConnectAgodaWizardModule } from './connect-agoda-wizard/connect-agoda-wizard.module'
import { ConnectBdcWizardModule } from './connect-bdc-wizard/connect-bdc-wizard.module'
import { ConnectExpediaWizardModule } from './connect-expedia-wizard/connect-expedia-wizard.module'
import { ConnectTiketWizardModule } from './connect-tiket-wizard/connect-tiket-wizard.module'
import { ConnectAirbnbWizardModule } from './connect-airbnb-wizard/connect-airbnb-wizard.module'
import { ChannelConnectWizardsService } from './index.service'
import { ChannelConnectHelperService } from './channel-connect-helper.service'
import { ConnectionModule } from '@tv3/store/connection/connection.module'
import { ConnectTripWizardModule } from './connect-trip-wizard/connect-trip-wizard.module'
import { ConnectVrboWizardModule } from './connect-vrbo-wizard/connect-vrbo-wizard.module'
import { ConnectHvmiWizardModule } from './connect-hvmi-wizard/connect-hvmi-wizard.module'
import { ConnectRUWizardModule } from './connect-ru-wizard/connect-ru-wizard.module'
import { ConnectHoliduWizardModule } from './connect-holidu-wizard/connect-holidu-wizard.module'
import { ConnectGVRWizardModule } from './connect-gvr-wizard/connect-gvr-wizard.module'
import { BookingFormulaSharedModule } from '@tv3/containers/formulas/formula-shared.module'
import { RentalValidationFixHelperService } from './rental-validation-fix-helper.service'

const components = []

const services = [
  //
  ChannelConnectWizardsService,
  ChannelConnectHelperService,
  RentalValidationFixHelperService,
]

const modules = [
  Tv3PlatformModule,
  ConnectionModule,
  BookingFormulaSharedModule,

  ConnectAgodaWizardModule,
  ConnectAirbnbWizardModule,
  ConnectBdcWizardModule,
  ConnectExpediaWizardModule,
  ConnectTiketWizardModule,
  ConnectTripWizardModule,
  ConnectVrboWizardModule,
  ConnectHvmiWizardModule,
  ConnectRUWizardModule,
  ConnectHoliduWizardModule,
  ConnectGVRWizardModule,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  providers: [...services],
  imports: [CommonModule, ...modules],
})
export class ChannelConnectWizardsModule {}
