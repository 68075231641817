import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { ConnectionRateMappingsTableComponent } from './connection-rate-mappings-table/connection-rate-mappings-table.component'
import { ChannelRateMappingsTableComponent } from './channel-rate-mappings-table/channel-rate-mappings-table.component'
import { PushRatesOverlayComponent } from './push-rates-overlay/push-rates-overlay.component'
import { RatePushAutomationsComponent } from './automations/rate-push-automations.component'
import { PushRatesOverlayService } from './push-rates-overlay/push-rates-overlay.service'
import { AddRateMappingDialogComponent } from './add-rate-mapping-dialog/add-rate-mapping-dialog.component'
import { RentalRateMappingsTableComponent } from './rental-rate-mappings-table/rental-rate-mappings-table.component'
import { AddRateMappingDialogService } from './add-rate-mapping-dialog/add-rate-mapping-dialog.service'

const modules = [SharedModule, CommonModule]

const components = [
  //
  AddRateMappingDialogComponent,
  RentalRateMappingsTableComponent,
  ConnectionRateMappingsTableComponent,
  ChannelRateMappingsTableComponent,
  PushRatesOverlayComponent,
  RatePushAutomationsComponent,
]

const pipes = []

const services = [
  //
  PushRatesOverlayService,
  AddRateMappingDialogService,
]

@NgModule({
  imports: [...modules],
  declarations: [...components, ...pipes],
  exports: [...components, ...modules, ...pipes],
  providers: [...services, ...pipes],
})
export class RatePushAutomationsModule {}
