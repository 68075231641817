import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'

export enum FileTypes {
  DOC = 'doc',
  IMAGE = 'image',
  XLS = 'xls',
  PPT = 'ppt',
  PDF = 'pdf',
  TXT = 'txt',
  CSV = 'csv',
  VCF = 'vcf',
  FILE = 'file',
  ARCHIVE = 'archive',
}

export const fileType2IconMap = {
  [FileTypes.DOC]: 'fas fa-file-word text-info',
  [FileTypes.XLS]: 'fas fa-file-excel text-indigo',
  [FileTypes.PPT]: 'fas fa-file-powerpoint text-warning',
  [FileTypes.IMAGE]: 'fas fa-file-image text-primary',
  [FileTypes.PDF]: 'fas fa-file-pdf text-danger',
  [FileTypes.TXT]: 'fas fa-file-alt text-info',
  [FileTypes.VCF]: 'fas fa-address-book text-info',
  [FileTypes.CSV]: 'fas fa-file-csv text-info',
  [FileTypes.FILE]: 'fas fa-file text-info',
  [FileTypes.ARCHIVE]: 'fas fa-file-archive text-info',
}

export function getFileType(name: string) {
  const ext = R.last(`${name}`.split('.')).toLowerCase()
  switch (ext) {
    case 'txt':
    case 'json':
      return FileTypes.TXT
    case 'csv':
      return FileTypes.CSV
    case 'png':
    case 'gif':
    case 'jpg':
    case 'jpeg':
      return FileTypes.IMAGE
    case 'doc':
    case 'docx':
      return FileTypes.DOC
    case 'ppt':
    case 'pptx':
      return FileTypes.PPT
    case 'xls':
    case 'xlsx':
      return FileTypes.XLS
    case 'pdf':
      return FileTypes.PDF
    case 'vcf':
      return FileTypes.VCF
    case 'zip':
    case 'rar':
      return FileTypes.ARCHIVE
    default:
      return FileTypes.FILE
  }
}

@Pipe({
  name: 'fileIcon',
})
export class FileIconPipe implements PipeTransform {
  transform(name: string): any {
    const fileType = getFileType(name)
    switch (fileType) {
      case FileTypes.DOC:
      case FileTypes.PPT:
      case FileTypes.XLS:
      case FileTypes.PDF:
      case FileTypes.IMAGE:
      case FileTypes.TXT:
      case FileTypes.CSV:
      case FileTypes.VCF:
        return fileType2IconMap[fileType]
      default:
        return fileType2IconMap.file
    }
  }
}
