import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { ENVIRONMENT, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { noAllWhitespaceValidator } from '@tokeet-frontend/tv3-platform'
import { catchError } from 'rxjs/operators'
import { EMPTY } from 'rxjs'
import { BillingService } from '../store/billing/billing.service'
import { ReplaceCreditCard, AddCreditCard } from '../store/card/card.actions'
import { AddCreditCardRequest } from '../store/card/card.model'

@Component({
  selector: 'app-add-credit-card-dialog',
  templateUrl: './add-credit-card-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-credit-card-dialog.component.scss'],
})
export class AddCreditCardDialogComponent implements OnInit {
  stripe
  elements
  card

  form = this.fb.group({
    firstName: ['', [Validators.required, noAllWhitespaceValidator]],
    lastName: ['', [Validators.required, noAllWhitespaceValidator]],
    terms: [0],
  })

  cardValidationMessage = ''
  isCardValid = false
  processing = false

  constructor(
    public dialogRef: MatDialogRef<AddCreditCardDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private billingService: BillingService,
    private store: Store<any>,
    @Inject(ENVIRONMENT) private environment,
    @Inject(MAT_DIALOG_DATA) public data: { replace: boolean }
  ) {}

  ngOnInit() {
    // @ts-ignore
    this.stripe = Stripe(this.environment.config.stripe_publishable_key)

    this.elements = this.stripe.elements()

    // Only mount the element the first time
    if (!this.card) {
      this.card = this.elements.create('card', {
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            lineHeight: '40px',
            fontWeight: 300,
            fontFamily: '"Poppins", Open Sans, Segoe UI, sans-serif',
            fontSize: '15px',
            '::placeholder': {
              color: '#757575',
            },
          },
        },
      })
      this.card.mount('#card-element')
      this.card.on('change', (event) => {
        this.cardValidationMessage = event.error?.message || ''
        this.isCardValid = event.complete
      })
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.processing = true
    this.billingService
      .setupIntent()
      .pipe(
        catchError(() => {
          this.processing = false
          this.toaster.error('Error creating session with server.')
          return EMPTY
        })
      )
      .subscribe((response) => {
        const formData = form.getRawValue()
        this.stripe
          .handleCardSetup(response.client_secret, this.card, {
            payment_method_data: {
              billing_details: {
                name: `${formData.firstName} ${formData.lastName}`,
              },
            },
          })
          .then((result) => {
            this.processing = false
            const { setupIntent, error } = result
            if (error) {
              if (error.type === 'card_error') {
                this.toaster.error(error.message)
              } else {
                this.toaster.error('Error authenticating card.')
              }
            } else {
              const request = {
                setup_intent: setupIntent,
                replace: this.data.replace,
              } as AddCreditCardRequest
              if (this.data.replace) {
                this.store.dispatch(ReplaceCreditCard({ request }))
              } else {
                this.store.dispatch(AddCreditCard({ request }))
              }
            }
            this.close()
          })
      })
  }
}
