import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Website } from '@tv3/store/website/website.model'
import { FormBuilder, FormGroup } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { UpdateWebsiteResources } from '@tv3/store/website/website.actions'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'

@Component({
  selector: 'app-website-resources',
  templateUrl: './website-resources.component.html',
  styleUrls: ['./website-resources.component.scss'],
})
export class WebsiteResourcesComponent implements OnInit {
  @Input() website: Website

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  form = this.fb.group({
    raw_css: [undefined],
    link_css: [undefined],
    raw_js: [undefined],
    link_js: [undefined],
    header_html: [undefined],
    footer_html: [undefined],
  })

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.form.patchValue(this.website.resources || {})
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const payload = {
      ...this.website.serialize(),
      resources: { ...this.website.resources, ...this.form.getRawValue() },
    }
    this.store.dispatch(UpdateWebsiteResources({ id: this.website.id, data: payload }))
  }
}
