import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { AttachmentService } from '@tokeet-frontend/tv3-platform'

interface SelectableFile {
  selected: boolean
  attachment
}

@Component({
  selector: 'app-attachment-select',
  templateUrl: './attachment-select.component.html',
  styleUrls: ['./attachment-select.component.scss'],
})
export class AttachmentSelectComponent implements OnInit {
  @Output() done = new EventEmitter()
  @Output() close = new EventEmitter()
  available: SelectableFile[] = []
  selected: SelectableFile[] = []
  queryAvailable: string
  querySelected: string

  constructor(private attachment: AttachmentService) {}

  ngOnInit() {
    this.attachment.all().subscribe((attachments) => {
      this.available = attachments.map((attachment) => ({
        selected: false,
        attachment,
      }))
    })
  }

  onMoveAvailable() {
    const checked = this.available.filter((i) => i.selected)
    this.available = this.available.filter((i) => !i.selected)
    checked.forEach((i) => (i.selected = false))
    this.selected = [...checked, ...this.selected]
  }

  onMoveAllAvailable() {
    this.selected = [...this.available, ...this.selected]
    this.selected.forEach((i) => (i.selected = false))
    this.available = []
  }

  onMoveSelected() {
    const checked = this.selected.filter((i) => i.selected)
    this.selected = this.selected.filter((i) => !i.selected)
    checked.forEach((i) => (i.selected = false))
    this.available = [...checked, ...this.available]
  }

  onMoveAllSelected() {
    this.available = [...this.selected, ...this.available]
    this.available.forEach((i) => (i.selected = false))
    this.selected = []
  }

  onDone() {
    const attachments = this.selected.map((i) => i.attachment)
    this.done.emit(attachments)
  }
}
