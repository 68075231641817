export const IconWebfontHash = 'a5288f05a8b'

export type IconName =
  | 'addons'
  | 'adult'
  | 'ai-refresh-solid'
  | 'ai-refresh'
  | 'ai-replied-text'
  | 'ai-solid'
  | 'ai'
  | 'alert-triangle'
  | 'alert-triangle2'
  | 'alert-triangle3'
  | 'angle-down'
  | 'angle-right'
  | 'archive'
  | 'arrow-bottom-right'
  | 'arrow-down-long'
  | 'arrow-first'
  | 'arrow-last'
  | 'arrow-left-long'
  | 'arrow-left-right'
  | 'arrow-right-long'
  | 'arrow-right'
  | 'arrow-top-right-square'
  | 'arrow-top-right'
  | 'arrow-up-top'
  | 'automations'
  | 'ban-dollar'
  | 'ban'
  | 'bath'
  | 'battery'
  | 'bed-times'
  | 'bed'
  | 'bell'
  | 'bill-check'
  | 'bill-dollar'
  | 'bill-percent'
  | 'bill-times'
  | 'bill'
  | 'billing'
  | 'board-check'
  | 'bolt'
  | 'book-open'
  | 'book'
  | 'booking-canceled'
  | 'booking-channel'
  | 'booking-confirmed'
  | 'booking-created'
  | 'booking-engine'
  | 'bookmark'
  | 'bookmark2'
  | 'browser-times-check'
  | 'build'
  | 'building-incident'
  | 'building'
  | 'buildings'
  | 'calculator'
  | 'calendar-alert'
  | 'calendar-alt'
  | 'calendar-alt2'
  | 'calendar-arrow-right'
  | 'calendar-ban'
  | 'calendar-check-sync'
  | 'calendar-check'
  | 'calendar-check2'
  | 'calendar-clock'
  | 'calendar-clock2'
  | 'calendar-cog'
  | 'calendar-day'
  | 'calendar-dollar-circle'
  | 'calendar-link'
  | 'calendar-minus'
  | 'calendar-pause'
  | 'calendar-pause2'
  | 'calendar-pencil'
  | 'calendar-plus'
  | 'calendar-plus2'
  | 'calendar-plus3'
  | 'calendar-schedule'
  | 'calendar-share'
  | 'calendar-sync'
  | 'calendar-time'
  | 'calendar-times'
  | 'calendar'
  | 'calendars'
  | 'calender-pause'
  | 'call'
  | 'card-accepted'
  | 'card-check'
  | 'card-declined'
  | 'card-deleted'
  | 'card-rejected'
  | 'card-times'
  | 'card'
  | 'card2'
  | 'caret-left-right'
  | 'channel-disabled'
  | 'channels'
  | 'chart-bar-line'
  | 'chart-line'
  | 'chat-bolt'
  | 'chat-dollar'
  | 'chat-square'
  | 'chat-star'
  | 'chat-star2'
  | 'chat'
  | 'chat2'
  | 'check-double'
  | 'check-square'
  | 'check-squre'
  | 'check'
  | 'check2'
  | 'checkin-door'
  | 'checkin'
  | 'checkout-door'
  | 'checkout'
  | 'child'
  | 'circle-linked'
  | 'circle-stack'
  | 'clock'
  | 'cloud-download'
  | 'cloud-upload'
  | 'cog-refresh'
  | 'cog'
  | 'coins'
  | 'collapse'
  | 'collapse2'
  | 'connect'
  | 'contract-rejected'
  | 'contract-sent'
  | 'contract-signed'
  | 'contract-viewed'
  | 'copy-solid'
  | 'copy'
  | 'create-task'
  | 'cubes'
  | 'custom-theme'
  | 'dark-light'
  | 'dashboard-grid'
  | 'dashboard'
  | 'dashboard2'
  | 'day'
  | 'device-offline'
  | 'device-online'
  | 'discount-code'
  | 'discount'
  | 'dollar-back'
  | 'dollar-check'
  | 'dollar-circle-check'
  | 'dollar-circle-plus-circle'
  | 'dollar-circle-times'
  | 'dollar-circle'
  | 'dollar-circle2'
  | 'dollar-cycle'
  | 'dollar-light'
  | 'dollar-line-chart'
  | 'dollar-percent'
  | 'dollar-refresh'
  | 'dollar-square'
  | 'dollar-sync'
  | 'dollar'
  | 'dots-circle-v'
  | 'double-arrow-right'
  | 'download'
  | 'download2'
  | 'download3'
  | 'envelope-check'
  | 'envelope-open'
  | 'envelope'
  | 'expand'
  | 'expand2'
  | 'expenses'
  | 'external-square'
  | 'external'
  | 'eye'
  | 'favorite'
  | 'feed-minus'
  | 'female'
  | 'file-alt-check'
  | 'file-alt'
  | 'file-alt2'
  | 'file-arrow-border'
  | 'file-arrow-down'
  | 'file-arrow-up'
  | 'file-dollar'
  | 'file-search'
  | 'file-time'
  | 'filter'
  | 'folder'
  | 'format-clear'
  | 'gender'
  | 'globe'
  | 'globe2'
  | 'gps'
  | 'gps2'
  | 'grid-check'
  | 'grid'
  | 'guest-created'
  | 'guest-deleted'
  | 'guest-portal'
  | 'guest-updated'
  | 'guests'
  | 'gym'
  | 'hand'
  | 'home-percent'
  | 'house-hashtag'
  | 'house-lock'
  | 'http'
  | 'inbox'
  | 'info-circle'
  | 'info'
  | 'inquiries'
  | 'integrations'
  | 'inventory'
  | 'invoice-canceled'
  | 'invoice-created'
  | 'invoice-paid'
  | 'invoices'
  | 'invoices2'
  | 'key'
  | 'key2'
  | 'kitchen'
  | 'left-down'
  | 'light'
  | 'link-plus'
  | 'link'
  | 'link2'
  | 'links-cog'
  | 'list-check-square'
  | 'list-check'
  | 'list-square'
  | 'list'
  | 'logout'
  | 'logout2'
  | 'los-discount'
  | 'male'
  | 'map'
  | 'menu'
  | 'message-received'
  | 'minimize'
  | 'minus-circle'
  | 'money-back'
  | 'money-ban'
  | 'moneys'
  | 'moon'
  | 'note-check'
  | 'note-pad'
  | 'note'
  | 'paid-invoice'
  | 'paperclip'
  | 'pc'
  | 'pen-sign'
  | 'pencil'
  | 'percent'
  | 'person'
  | 'phone-card'
  | 'phone'
  | 'pin'
  | 'plane-arrive'
  | 'plane-depart'
  | 'plane'
  | 'plug'
  | 'plus'
  | 'pm'
  | 'pointer'
  | 'pray'
  | 'printer'
  | 'process'
  | 'property-number'
  | 'property'
  | 'question-circle'
  | 'rate'
  | 'rates'
  | 'recycle'
  | 'redo'
  | 'refresh'
  | 'relax'
  | 'rental'
  | 'rental2'
  | 'rentals'
  | 'replied-text'
  | 'reply-text'
  | 'reply'
  | 'report'
  | 'reports'
  | 'reset'
  | 'review-sent'
  | 'reviews'
  | 'right-down'
  | 'room'
  | 'save'
  | 'search'
  | 'send-email'
  | 'send-notification'
  | 'send-sms'
  | 'send'
  | 'settings'
  | 'share-external'
  | 'share'
  | 'shield-check'
  | 'sign'
  | 'smart-device2'
  | 'smart-devices'
  | 'smart-lock'
  | 'smart-lock2'
  | 'sort'
  | 'source'
  | 'stacked-squares-flow'
  | 'star-half-solid'
  | 'star-outline'
  | 'star'
  | 'stealth'
  | 'store'
  | 'sun'
  | 'tag-plus'
  | 'task-created'
  | 'tasks'
  | 'template'
  | 'theme'
  | 'thumb-stars'
  | 'thumbtack-solid'
  | 'thumbtack'
  | 'time-based'
  | 'time-counter'
  | 'time'
  | 'times-circle'
  | 'times'
  | 'tools'
  | 'top-left-arrow-square'
  | 'trash-alt'
  | 'trash'
  | 'trees'
  | 'undo'
  | 'unlink'
  | 'unlink2'
  | 'unlock'
  | 'unpaid-invoice'
  | 'upload'
  | 'user-card'
  | 'user-check'
  | 'user-cog'
  | 'user-plus-circle'
  | 'user-plus'
  | 'user'
  | 'user2'
  | 'users'
  | 'video'
  | 'wallet'
  | 'website'
  | 'whatsapp'
