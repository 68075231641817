<form [formGroup]="form" class="checklist-form" [class.empty]="isEmpty">
  <mat-form-field2 class="search mat-block inline" [showLabel]="false">
    <input
      matInput
      type="text"
      placeholder="Enter a task and tap enter to add"
      class="example-right-align"
      (keydown.enter)="onEnterPressed()"
      [formControl]="taskCtrl"
    />
    <i matPrefix class="fas fa-edit"></i>
  </mat-form-field2>

  <ul class="list-unstyled pl-3">
    <li
      *ngFor="let formItem of arrItems.controls; let i = index"
      class="d-flex align-items-center justify-content-between"
      [class]="i === arrItems.controls.length - 1 ? '' : 'pb-3'"
    >
      <div>
        {{ formItem.get('name').value }}
      </div>
      <div
        (click)="onRemove(i)"
        class="btn btn-sm btn-light-danger btn-remove"
        matTooltip="Remove the current checklist item"
      >
        <i class="cs-icon cs-icon-trash"></i>
      </div>
    </li>
  </ul>

  <!--  <div class="checklist-item" formArrayName="items" *ngFor="let formItem of arrItems.controls; let i = index">-->
  <!--    <div [formGroup]="formItem" class="d-flex flex-column align-items-stretch gap-2">-->
  <!--      <div class="flex-fill d-flex flex-row align-items-center gap-4">-->
  <!--        <mat-form-field2 class="mat-block flex-fill">-->
  <!--          <mat-label>Name</mat-label>-->
  <!--          <input type="text" matInput formControlName="name" required />-->
  <!--          <mat-error *ngIf="hasError(i, 'name', 'required')"> Name is <strong>required</strong> </mat-error>-->
  <!--        </mat-form-field2>-->

  <!--        <app-form-switch-->
  <!--          matTooltip="Mark checklist item as completed."-->
  <!--          label="Completed"-->
  <!--          [inline]="true"-->
  <!--          labelPosition="after"-->
  <!--          [checked]="isCompleted(i)"-->
  <!--          (valueChange)="toggleCompleted(i)"-->
  <!--          style="margin-top: 0.5rem"-->
  <!--        ></app-form-switch>-->
  <!--        &lt;!&ndash; <mat-checkbox (change)="toggleCompleted(i)" [checked]="isCompleted(i)">Completed</mat-checkbox> &ndash;&gt;-->
  <!--      </div>-->

  <!--      <mat-form-field2 class="mat-block">-->
  <!--        <mat-label>Description</mat-label>-->
  <!--        <textarea-->
  <!--          type="text"-->
  <!--          matInput-->
  <!--          formControlName="description"-->
  <!--          maxlength="1000"-->
  <!--          rows="3"-->
  <!--          cdkTextareaAutosize-->
  <!--          cdkAutosizeMaxRows="5"-->
  <!--        ></textarea>-->
  <!--      </mat-form-field2>-->

  <!--      <div class="d-flex flex-row gap-3" *ngIf="isCompleted(i)">-->
  <!--        <div class="flex-fill d-flex flex-column align-items-stretch">-->
  <!--          <mat-form-field2 class="mat-block">-->
  <!--            <mat-label>Completed at</mat-label>-->
  <!--            <input type="text" disabled matInput [value]="getCompletedAt(i) | epoch : 'DD - MMM - YYYY h:mm A'" />-->
  <!--          </mat-form-field2>-->
  <!--        </div>-->

  <!--        <div class="flex-fill d-flex flex-column align-items-stretch">-->
  <!--          <mat-form-field2 class="mat-block">-->
  <!--            <mat-label>Completed by</mat-label>-->
  <!--            <input type="text" disabled matInput [value]="getCompletedBy(i) | fullname" />-->
  <!--          </mat-form-field2>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="buttons d-flex flex-row align-items-center justify-content-between" *ngIf="!(1 | isReadonlyRole)">-->
  <!--      <div class="btn-group" dropdown [dropup]="true">-->
  <!--        <div (click)="onAdd(i + 1)" class="btn btn-sm btn-outline-info btn-add" matTooltip="Add a new checklist item">-->
  <!--          <i class="far fa-plus"></i> Add Item-->
  <!--        </div>-->
  <!--        <button dropdownToggle type="button" class="btn btn-sm btn-outline-info dropdown-toggle"></button>-->
  <!--        <ul class="dropdown-menu" *dropdownMenu>-->
  <!--          <li class="menu-item-info">-->
  <!--            <button type="button" (click)="onAdd(i)"><i class="cs-icon cs-icon-plus"></i> Add Item Before</button>-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--      </div>-->

  <!--      <div-->
  <!--        (click)="onRemove(i)"-->
  <!--        class="btn btn-sm btn-light-danger btn-remove"-->
  <!--        matTooltip="Remove the current checklist item"-->
  <!--      >-->
  <!--        <i class="cs-icon cs-icon-trash"></i>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <app-empty-table *ngIf="isEmpty" [type]="tableType">
    <!--    <div (click)="onAdd(null)" class="btn btn-secondary-info btn-add mt-3" *ngIf="!(1 | isReadonlyRole)">-->
    <!--      <i class="cs-icon cs-icon-plus"></i> Add Item-->
    <!--    </div>-->
  </app-empty-table>
</form>
