import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { Website, isTokeetWebsiteDomain, WebsiteTypes } from '@tv3/store/website/website.model'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { Actions, ofType } from '@ngrx/effects'
import { WebsiteService } from '@tv3/store/website/website.service'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { ConfigWebsiteDomainDialogService } from '@tv3/containers/websites/dialogs/config-website-domain-dialog/config-website-domain-dialog.service'
import { UpdateWebsite } from '@tv3/store/website/website.actions'
import { SaveForm } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-website-details-portal',
  templateUrl: './website-details-portal.component.html',
  styleUrls: ['./website-details-portal.component.scss'],
})
export class WebsiteDetailsPortalComponent extends Destroyable implements OnInit, OnChanges {
  @Input() website: Website

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))
  isCustomDomain = false

  form = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    domain: [{ value: undefined, disabled: true }],
  })

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private websiteService: WebsiteService,
    private confirmDialog: ConfirmDialogService,
    private configWebsiteDomainDialog: ConfigWebsiteDomainDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.setFormData(this.website)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['website']) {
      this.setFormData(this.website)
    }
  }

  private setFormData(website: Website) {
    this.isCustomDomain = !isTokeetWebsiteDomain(website.domain, WebsiteTypes.GuestPortal)
    this.form.patchValue(
      {
        ...website,
      },
      { emitEvent: false }
    )
  }

  onEditDomain() {
    this.configWebsiteDomainDialog.open(this.website)
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const formData = fb.getRawValue()
    const payload = {
      ...this.website.serialize(),
      title: formData.title,
    }
    this.store.dispatch(UpdateWebsite({ websiteId: this.website.id, data: payload }))
  }
}
