import { Pipe, PipeTransform } from '@angular/core'
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { convertStructToMoment } from '@tv3/utils/functions/convert-struct-to-moment'

@Pipe({
  name: 'dayClasses',
})
export class DayClassesPipe implements PipeTransform {
  transform(date: NgbDate, eventGroups: { [id: string]: any }) {
    const momentDate = convertStructToMoment(date)
    const event = eventGroups[momentDate.format('YYYY-MM-DD')]

    let dayClasses: string[] = ['custom-day']
    if (event) {
      dayClasses.unshift('range')
      event.isStart && dayClasses.unshift('first')
      event.isEnd && dayClasses.unshift('last')
      event.ci && dayClasses.unshift('ci')
      event.co && dayClasses.unshift('co')
      !event.isStart && !event.isEnd && dayClasses.unshift('middle')

      dayClasses = dayClasses.concat(event.eventClasses || [])
    }

    return dayClasses.join(' ')
  }
}
