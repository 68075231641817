import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { Channel, DialogService } from '@tokeet-frontend/tv3-platform'
import { ComponentType } from '@angular/cdk/portal'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { AgodaContentDialogComponent } from '@tv3/containers/channels/content/agoda/agoda-content-dialog.component'

@Injectable({
  providedIn: SharedModule,
})
export class AgodaContentDialogService {
  constructor(private dialog: DialogService) {}

  private open<T>(channel: Channel, connection: ConnectionView, component: ComponentType<T>): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { channel, connection },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  public openOverlay(channel: Channel, connection?: ConnectionView) {
    return this.open(channel, connection, AgodaContentDialogComponent)
  }
}
