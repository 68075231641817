import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import {
  selectRentalSmartDevices,
  SmartDevice,
  SmartDeviceAccessCodeResponse,
  SmartDeviceService,
} from '@tokeet-frontend/smart-devices'
import {
  ActionFailed,
  asLocalDate,
  Destroyable,
  SaveForm,
  TimePickerUtils,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

export enum AccessCodeTabs {
  Details,
  History,
}

@Component({
  selector: 'app-new-accesscode-dialog',
  templateUrl: './new-accesscode-dialog.component.html',
  styleUrls: ['./new-accesscode-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class NewAccesscodeDialogComponent extends Destroyable implements OnInit {
  tabs = AccessCodeTabs
  activeTab = AccessCodeTabs.Details
  devices$: Observable<SmartDevice[]>

  form = this.fb.group({
    inquiryId: [],
    startsAt: [null, [Validators.required]],
    startsAtTime: [null, [Validators.required]],
    endsAt: [null, [Validators.required]],
    endsAtTime: [null, [Validators.required]],
  })
  get inquiryCtrl() {
    return this.form.get('inquiryId') as FormControl
  }

  get isSameDate() {
    const s = this.form.get('startsAt').value
    const e = this.form.get('endsAt').value
    if (!s || !e) return false
    return moment(s).isSame(e, 'date')
  }

  get maxStartTime() {
    if (!this.isSameDate) return ''
    return this.form.get('endsAtTime').value
  }

  get minEndTime() {
    const startTime = this.form.get('startsAtTime').value
    if (!this.isSameDate || !startTime) return ''
    const now = moment()
    if (!moment(this.form.get('endsAt').value).isSame(now, 'date')) return startTime

    return TimePickerUtils.parseTime(startTime).isBefore(now, 'minute') ? TimePickerUtils.formatTime(now) : startTime
  }

  today = moment().startOf('day')

  device: SmartDevice
  inquiry: Inquiry

  isLoading = false
  isEdit = false
  get code() {
    return this.data.code
  }
  filterInquiries = (t: Inquiry) => t.guestDepart > this.today.unix()

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private toaster: Toaster,
    private smartService: SmartDeviceService,
    private inquiryService: InquiryService,
    private dialogRef: MatDialogRef<NewAccesscodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      device?: SmartDevice
      inquiry?: Inquiry
      rentalId?: string
      code?: SmartDeviceAccessCodeResponse
      tab?: AccessCodeTabs
    }
  ) {
    super()
    this.device = this.data.device
    if (data.inquiry) {
      this.inquiryCtrl.setValue(data.inquiry.id)
      this.onSelectInquiry(data.inquiry)
      this.inquiryCtrl.disable()
    }
    this.devices$ = this.store.pipe(select(selectRentalSmartDevices(this.device?.rentalId || this.inquiry?.rentalId)))
    if (data.tab) {
      this.activeTab = data.tab
    }
  }

  ngOnInit(): void {
    if (this.data.code) {
      this.isEdit = true
      this.setForm(this.data.code)
    }
  }

  close() {
    this.dialogRef.close()
  }

  setForm(code: SmartDeviceAccessCodeResponse) {
    this.form.patchValue({
      startsAt: asLocalDate(code.startsAt),
      endsAt: asLocalDate(code.endsAt),
      startsAtTime: TimePickerUtils.formatTime(moment.utc(code.startsAt * 1000)),
      endsAtTime: TimePickerUtils.formatTime(moment.utc(code.endsAt * 1000)),
    })
    this.inquiryCtrl.setValue(code.inquiryId)
    this.inquiryCtrl.disable()
  }

  onSelectInquiry(item: Inquiry) {
    this.inquiry = item
    this.inquiryService.prepareCheckInOut(this.inquiry, false).subscribe((checkInOut) => {
      this.form.patchValue({
        startsAt: asLocalDate(item.guestArrive),
        endsAt: asLocalDate(item.guestDepart),
        startsAtTime: TimePickerUtils.formatTime(checkInOut?.checkIn),
        endsAtTime: TimePickerUtils.formatTime(checkInOut?.checkOut),
      })
    })
  }

  onSelectDevice(items: SmartDevice[]) {
    this.device = lodash.head(items)
  }

  isSavable() {
    if (!this.isEdit) return true
    const { startsAtTime, endsAtTime, ...data } = this.form.getRawValue()

    const startsAt = this._getEpoch(data.startsAt, startsAtTime)
    const endsAt = this._getEpoch(data.endsAt, endsAtTime)

    return this.code.startsAt !== startsAt || this.code.endsAt !== endsAt
  }

  create() {
    const { startsAtTime, endsAtTime, ...data } = this.form.getRawValue()

    const startsAt = this._getEpoch(data.startsAt, startsAtTime)
    const endsAt = this._getEpoch(data.endsAt, endsAtTime)

    this.isLoading = true
    this.smartService
      .creatAccessCode({
        deviceId: this.device.deviceId,
        connectedAccountId: this.device.connectedAccountId,
        inquiryId: this.inquiry?.id,
        guestId: this.inquiry?.guestId,
        startsAt,
        endsAt,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Access Code created successfully.')
          this.dialogRef.close('created')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  update() {
    const { startsAtTime, endsAtTime, ...data } = this.form.getRawValue()

    const startsAt = this._getEpoch(data.startsAt, startsAtTime)
    const endsAt = this._getEpoch(data.endsAt, endsAtTime)

    this.isLoading = true
    this.smartService
      .updateAccessCode({
        deviceId: this.code.deviceId,
        accessCodeId: this.code.accessCodeId,
        startsAt,
        endsAt,
      })
      .pipe(finalize(() => (this.isLoading = false)))

      .subscribe(
        () => {
          this.toaster.success('Access Code updated successfully.')
          this.dialogRef.close('updated')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  private _getEpoch(at: any, atTime: any) {
    const s = [moment(at).format('YYYY-MM-DD'), TimePickerUtils.to24HourTime(atTime)]
    return moment.utc(s.join(' ')).unix()
  }

  @SaveForm()
  onGenerate(form: FormGroup) {
    if (this.isEdit) {
      this.update()
    } else {
      this.create()
    }
  }
}
