<div class="modal-header">
  <h5 class="modal-title"><i class="fas fa-book"></i> Template Dictionary</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <mat-tab-group [color]="'accent'">
    <mat-tab label="Template Tags">
      <div class="row no-margin">
        <div *ngFor="let tag of activeTags" class="col-sm-6 text-left">
          <button
            [matTooltip]="tag.tooltip"
            matTooltipPosition="above"
            (click)="addTag(tag.key)"
            type="button"
            class="data-item"
          >
            {{ tag.key }}
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
