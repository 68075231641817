<app-history-list
  *ngIf="!!guest?.notes?.length; else empty"
  [list]="guest?.notes || []"
  (edit)="onEdit($event)"
  contentKey="note"
  userNameKey="user"
  timeKey="timestamp"
></app-history-list>

<ng-template #empty>
  <app-empty-table *ngIf="guest?.notes?.length === 0" [type]="tableType"></app-empty-table>
</ng-template>
