import { Component, HostBinding, Input } from '@angular/core'

export type FormFieldAffixType = 'icon' | 'text' | 'btn' | 'field'

@Component({
  selector: 'form-field-affix',
  templateUrl: './form-field-affix.component.html',
  styleUrls: ['./form-field-affix.component.scss'],
})
export class FormFieldAffixComponent {
  @HostBinding('class.input-group-affix') isAffix = true
}
