import { Component } from '@angular/core'
import * as R from 'ramda'
import { MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { asUTCEpoch, Toaster } from '@tokeet-frontend/tv3-platform'
import { AddTasks } from '@tv3/store/task/task.actions'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import {
  generateDateParser,
  generateMappingParser,
  parseString,
} from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import * as moment from 'moment'
import { CreateTaskPayload, TaskStatusView } from '@tv3/store/task/task.model'

function validDateRange(item: any) {
  if (!moment(item.start).isBefore(item.due)) {
    return 'Start Date should be before Due Date.'
  }

  return true
}

@Component({
  selector: 'app-import-task-dialog',
  templateUrl: './import-task-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-task-dialog.component.scss'],
})
export class ImportTaskDialogComponent {
  files: ParsedUploadFile<CreateTaskPayload>[] = []

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Name', field: 'name', required: true, parse: parseString },
    { name: 'Start', field: 'start', required: true, parse: generateDateParser('YYYY-MM-DD') },
    {
      name: 'Due',
      field: 'due',
      required: true,
      parse: generateDateParser('YYYY-MM-DD'),

      rowValidators: [validDateRange],
    },
    { name: 'User Id', field: 'user_id', required: true, parse: parseString },
    { name: 'Inquiry Id', field: 'inquiry_id', parse: parseString },
    { name: 'Notes', field: 'notes', parse: parseString },
    {
      name: 'Status',
      field: 'status',
      parse: generateMappingParser(
        {
          finished: 2,
          completed: 2,
          unfinished: 1,
          pending: 1,
          incompleted: 1,
          [TaskStatusView.DueToday.toLowerCase()]: 1,
        },
        'pending'
      ),
      default: 1,
    },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}). You can get the user ID/Inquiry ID on the details view`,
    list: ['Name, Start, Due and User Id are required.', 'Date format "YYYY-MM-DD", such as "2016-02-13".'],
    sample: [
      ['Name', 'Start', 'Due', 'User Id', 'Inquiry Id', 'Notes', 'Status'],
      [
        'Fix air condition',
        '2025-02-13',
        '2025-02-14',
        'a3f19b90-64cb-4506-b269-cb9c7d0c254e',
        '',
        'Note 1',
        'Completed',
      ],
      ['Clean the room', '2025-02-12', '2025-02-14', 'a3f19b90-64cb-4506-b269-cb9c7d0c254e', '', 'Note 2', 'Pending'],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportTaskDialogComponent>,
    private store: Store<fromRoot.State>,
    protected toast: Toaster
  ) {}

  close() {
    this.dialogRef.close()
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  create() {
    if (R.any((file) => file.isError, this.files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    //  get parsed csv data
    let items: any[] = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    items = R.map((item: any) => {
      item.due = asUTCEpoch(item.due)
      item.start = asUTCEpoch(item.start)
      item.user_ids = [item.user_id]
      delete item.user_id
      return item
    }, items)

    this.store.dispatch(AddTasks({ requests: items }))

    this.close()
  }
}
