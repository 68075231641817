<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-money-check-alt"></i> Create a Tax</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row">
    <div class="col-sm-4">
      <mat-form-field2 class="mat-block">
        <input type="text" formControlName="name" matInput required placeholder="Tax Name:" />
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
          Tax name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
          Tax name must be at least <strong>2</strong> characters long
        </mat-error>
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
          Tax name must be at most <strong>50</strong> characters long
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-4">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="form.get('type').value | amountPlaceholder: currencySymbol"></span></mat-label>
        <input type="number" min="0" formControlName="amount" matInput decimal required />
        <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
          Amount is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-4">
      <mat-form-field2 class="mat-block">
        <mat-select formControlName="type" placeholder="Type">
          <mat-option value="percent">Percent</mat-option>
          <mat-option value="flat">Flat</mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="far fa-plus"></i> create
  </button>
</div>
