import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'

import { isAllSelected, UserStorage } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  addRentalImagesComplete,
  Channel,
  ChannelNameTokens,
  Rental,
  selectRentalCities,
  selectRentalTags,
  Toaster,
  untilDestroy,
  updateRentalBaseRateComplete,
  updateRentalComplete,
  updateRentalDetailsComplete,
  updateRentalInstructionsComplete,
} from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { ActionFailed, createSearch, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import {
  CreateHoliduListing,
  CreateHoliduListingFailure,
  CreateHoliduListingSuccess,
  DeleteHoliduListing,
  DeleteHoliduListingFailure,
  getHoliduListings,
  GetHoliduRentalValidations,
  HoliduListingViewModel,
  ListingsValidationFieldResult,
  ListingValidationFieldResultItem,
  selectHoliduRentalValidations,
  selectHoliduValidationsLoaded,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'
import { DeleteRoomComplete, UpdateRoomComplete, UpsertRoomsComplete } from '@tokeet-frontend/rentals'
import { sortBy } from 'lodash'
import { RemoveLocalConnectionsByIds, LoadConnectionsByChannel } from '@tv3/store/connection/connection.actions'
import { getChannelConnectionId } from '@tv3/store/connection/connection.model'
import { RentalValidationFixHelperService } from '../../rental-validation-fix-helper.service'
import { BookingFormulasOverlayService } from '@tv3/containers/formulas/overlay/booking-formulas-overlay.service'

@Component({
  selector: 'app-connect-holidu-wizard-step2',
  templateUrl: './connect-holidu-wizard-step2.component.html',
  styleUrls: ['./connect-holidu-wizard-step2.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConnectHoliduWizardStep2Component
  extends SelectableRow<HoliduListingViewModel>
  implements OnInit, AfterViewInit
{
  @Input() channel: Channel
  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  selectableRowIdPropName = 'providerApartmentId'

  @ViewChild('paginator', { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['select', 'rental_name', 'providerId', 'holiduApartmentId', 'statusView', 'action']

  expandedElement?: HoliduListingViewModel

  isEmptyTable$ = isEmptyTable(this.dataSource)

  renderedData = []

  listings: HoliduListingViewModel[]

  statusCtrl = new FormControl('')
  searchCtrl = new FormControl('')
  tagsCtrl = new FormControl([])
  citiesCtrl = new FormControl([])

  tags$ = this.store.pipe(select(selectRentalTags))
  cities$ = this.store.pipe(select(selectRentalCities))

  rentalTabs = RentalDialogTab

  searchFn = createSearch<HoliduListingViewModel>(['rental_name', 'holiduApartmentId', 'statusView'])

  validations: ListingsValidationFieldResult = {}

  validationsLoaded$ = this.store.pipe(select(selectHoliduValidationsLoaded))

  refreshListings$ = new BehaviorSubject<boolean>(true)

  isExpansionDetailRow = (i: number, row: HoliduListingViewModel & { detailRow?: boolean }) => {
    return row.hasOwnProperty('detailRow')
  }
  constructor(
    private store: Store<any>,
    private toaster: Toaster,
    private actions: Actions,

    protected storage: UserStorage,
    private bookingFormulasOverlay: BookingFormulasOverlayService,
    private validationFixHelper: RentalValidationFixHelperService
  ) {
    super(storage, TableType.VRBORentals)
  }

  ngOnInit() {
    this.onRefresh()

    this.actions
      .pipe(
        ofType(
          updateRentalComplete,
          updateRentalDetailsComplete,
          UpdateRoomComplete,
          UpsertRoomsComplete,
          addRentalImagesComplete,
          DeleteRoomComplete,
          updateRentalInstructionsComplete,
          updateRentalBaseRateComplete
        ),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.onRefresh()
      })

    this.actions.pipe(ofType(CreateHoliduListingSuccess), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(LoadConnectionsByChannel({ channelName: ChannelNameTokens.Holidu, channelId: '' }))
    })

    this.actions
      .pipe(ofType(CreateHoliduListingFailure, DeleteHoliduListingFailure), untilDestroy(this))
      .subscribe(({ err }) => {
        this.store.dispatch(ActionFailed({ error: err as any }))
      })

    combineLatest([this.store.pipe(getHoliduListings), this.store.pipe(select(selectHoliduRentalValidations))])
      .pipe(untilDestroy(this))
      .subscribe(([listings, validations]) => {
        this.validations = validations
        this.listings = listings
        this.refreshListings$.next(true)
      })

    combineLatest([
      this.citiesCtrl.valueChanges.pipe(startWith('')),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.statusCtrl.valueChanges.pipe(startWith([])),
      this.searchCtrl.valueChanges.pipe(startWith([])),
      this.refreshListings$,
    ])
      .pipe(untilDestroy(this))
      .subscribe(([cities, tags, status, searchTerm]) => {
        let configs = this.listings
        switch (status) {
          case 'all':
            configs = this.listings
            break
          case 'active':
            configs = R.filter((listing) => listing.active, this.listings)
            break
          case 'inactive':
            configs = R.filter((listing) => !listing.active, this.listings)
            break
        }
        if (tags?.length > 0) {
          configs = R.filter((listing) => {
            return R.any(
              R.equals(true),
              R.map((tag) => R.contains(tag, listing.rental?.tags || []), tags)
            )
          }, configs)
        }
        if (cities?.length > 0) {
          configs = R.filter((listing) => R.includes(listing.rental?.address?.city || '', cities), configs)
        }
        configs = this.searchFn(configs, searchTerm)

        if (configs?.length !== this.dataSource.data?.length) {
          this.paginator?.firstPage()
        }
        this.dataSource.data = this.withExtraRow(configs || [], this.validations)
      })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
  }

  onCancelFilters() {
    this.statusCtrl.patchValue(null)
    this.tagsCtrl.patchValue(null)
    this.citiesCtrl.patchValue(null)
    this.searchCtrl.patchValue(null)
  }

  get isFiltering() {
    return this.statusCtrl.value || this.tagsCtrl.value || this.citiesCtrl.value || this.searchCtrl.value
  }

  withExtraRow(listings: HoliduListingViewModel[], validations: ListingsValidationFieldResult) {
    listings = sortBy(listings, (listing) => validations[listing.providerApartmentId]?.length === 0)
    return R.map((listing: HoliduListingViewModel) => {
      return {
        ...listing,
        detailRow: validations[listing.providerApartmentId]?.length > 0,
        statusView: listing.active ? 'Active' : 'Inactive',
      }
    }, listings)
  }

  onConnect() {
    if (R.any((config) => config['detailRow'], this.selection.selected as HoliduListingViewModel[])) {
      this.toaster.warning('You have selected rows with invalid rentals')
      return
    }
    R.forEach((config) => {
      // Resume Holidu Rental
    }, this.selection.selected)

    this.onComplete()
  }

  onComplete() {
    this.next.emit(true)
  }

  onRefresh() {
    this.store.dispatch(GetHoliduRentalValidations())
  }

  allInvalid() {
    return R.all((listing) => listing['detailRow'], this.dataSource.data)
  }

  isAllSelected() {
    return isAllSelected(this.selection, this.listings, this.isRowSelectable)
  }

  isRowSelectable(listing: HoliduListingViewModel) {
    return !listing['detailRow']
  }

  setExpanded(row) {
    this.expandedElement = row
  }

  onToggleStatus(listing: HoliduListingViewModel) {
    if (listing.active) {
      this.store.dispatch(DeleteHoliduListing({ propertyId: listing.providerApartmentId }))
      this.store.dispatch(
        RemoveLocalConnectionsByIds({
          connectionIds: [
            getChannelConnectionId({
              name: 'holidu',
              propertyId: listing.providerApartmentId,
              roomId: listing.holiduApartmentId,
            }),
          ],
        })
      )
    } else {
      this.store.dispatch(CreateHoliduListing({ listing }))
    }
  }

  onFixRental(rental: Rental, field: ListingValidationFieldResultItem) {
    this.validationFixHelper.onFixRental(rental, field, this.validations[rental.id])
  }

  onBookingFormula() {
    this.bookingFormulasOverlay.open(this.channel)
  }
}
