import {
  Currency,
  deserialize,
  epochToView,
  epochToViewUTC,
  Exclude,
  Expose,
  plainToClass,
  Rental,
  Serializable,
  SerializableData,
  TaxV2,
  TaxV3,
  Type,
} from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { getInvoiceTotal } from '../../utils/invoice-total'
import { upgradeInvoiceTax } from '../../utils/tv2-invoice-tax'
import * as uuid from 'uuid'

export enum InvoiceTypes {
  BookingInvoice = 0,
  GeneralInvoice = 1,
}

export enum InvoiceStatus {
  Canceled = -1,
  Unsent = 0,
  Unpaid = 1,
  Paid = 2,
  Refunded = 3,
}

export class PaymentDetails extends Serializable<PaymentDetails> {
  date: number
  id: string
  description: string
  service: string
  total: number

  @Expose({ name: 'card_type' })
  cardType: string

  @Expose({ name: 'card_num' })
  cardNum: string

  static deserialize(data: object): PaymentDetails {
    return deserialize(PaymentDetails, data)
  }
}

export class Invoice extends Serializable<Invoice> {
  @Expose({ name: 'paid_date' })
  paidDate: number

  @Expose({ name: 'invoice_items' })
  @Type(() => InvoiceItem)
  invoiceItems: InvoiceItem[]

  @Expose({ name: 'guest_name' })
  guestName: string

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'rental_name' })
  rentalName: string

  @Expose({ name: 'public_key' })
  publicKey: string

  @Expose({ name: 'invoice_date' })
  invoiceDate: number

  @Expose({ name: 'expires_at' })
  expiresAt: number

  @Expose({ name: 'invoice_rule' })
  invoiceRule: any

  @Expose({ name: 'payment_terms' })
  paymentTerms: string

  @Expose({ name: 'payment_instructions' })
  paymentInstructions: string

  @Expose({ name: 'payment_details' })
  @Type(() => PaymentDetails)
  paymentDetails: PaymentDetails

  @Expose({ name: 'inquiry_id' })
  inquiryId: string

  @Expose({ name: 'due_date' })
  dueDate: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'invoice_num' })
  invoiceNum: string

  @Expose({ name: 'online_payment' })
  onlinePayment: number

  @Expose({ name: 'gateway_id' })
  gatewayId: string

  @Expose({ name: 'address_key' })
  addressKey?: string

  currency: Currency

  @Type(() => Rental)
  rental: Rental

  archived: number
  type: InvoiceTypes
  @Type(() => Number)
  status: InvoiceStatus
  account: number
  created: number
  sent: number
  scheduled: number
  notes: string

  createdView: string
  sentView: string
  invoiceDateView: string
  invoiceDueDateView: string
  invoiceTotal: number
  invoiceStatus: string
  currencySymbol: string

  static deserialize(data: any): Invoice {
    let invoice: Invoice = plainToClass<Invoice, Invoice>(Invoice, data)

    invoice = upgradeInvoiceTax(invoice)

    invoice.createdView = epochToView(invoice.created)
    invoice.invoiceDateView = epochToViewUTC(invoice.invoiceDate)
    invoice.invoiceDueDateView = epochToViewUTC(invoice.dueDate)
    invoice.sentView = epochToView(invoice.sent)
    invoice.invoiceTotal = getInvoiceTotal(invoice.invoiceItems || [])
    invoice.invoiceStatus = getInvoiceStatus(invoice.status)
    invoice.currencySymbol = R.pathOr('$', ['symbol'], invoice.currency)

    return invoice
  }
}

export class InvoiceItem extends Serializable<InvoiceItem> {
  @Exclude({ toPlainOnly: true })
  id: string // frontend fix

  item: string
  qty: number
  description: string
  unitCost: number
  discount: number
  percent: number // payment percent

  tax: number // v1 percent
  @Type(() => TaxV2)
  taxEx: TaxV2 // v2 percent and flat
  @Type(() => TaxV3)
  taxes: TaxV3[] // v3 name, percent and flat

  constructor(data?: SerializableData<InvoiceItem>) {
    super(data)
    this.id = uuid.v4()
    this.taxes = []
  }

  static deserialize(data: object): InvoiceItem {
    const item = deserialize<InvoiceItem>(InvoiceItem, data)
    if (!item.id) {
      item.id = uuid.v4()
    }

    return item
  }
}

export function getInvoiceStatus(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.Canceled:
      return 'Canceled'
    case InvoiceStatus.Unsent:
      return 'Unsent'
    case InvoiceStatus.Unpaid:
      return 'Unpaid'
    case InvoiceStatus.Paid:
      return 'Paid'
    case InvoiceStatus.Refunded:
      return 'Refunded'
    default:
      return 'Unsaved'
  }
}

export interface CheckoutSession {
  display_items: CheckoutSessionDisplayItem[]
  object: 'checkout.session'
  subscription: any
  client_reference_id: string // this is set in payments, it should be invoice_num
  success_url: string
  billing_address_collection: any
  locale: any
  livemode: boolean
  mode: string
  setup_intent: any
  customer: any
  payment_intent: string
  id: string
  payment_method_types: string[]
  cancel_url: string
  customer_email: any
  submit_type: any
}

export interface CheckoutSessionDisplayItem {
  type: string
  currency: string
  custom: any
  amount: number
  quantity: number
}

export interface InvoiceLineItemTitles {
  item: string
  unit: string
  qty: string
  discountFees: string
  tax: string
  description: string
}

export const invoiceLineItemTitlesByType: { [type: string]: InvoiceLineItemTitles } = {
  [InvoiceTypes.BookingInvoice]: {
    item: 'Rental',
    unit: 'Nightly Rate',
    qty: 'Nights',
    discountFees: 'Discount/Fees',
    tax: 'Taxes',
    description: 'Description',
  },
  [InvoiceTypes.GeneralInvoice]: {
    item: 'Item',
    unit: 'Cost',
    qty: 'Quantity',
    discountFees: 'Discount/Fees',
    tax: 'Taxes',
    description: 'Description',
  },
}
