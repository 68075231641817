import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { asyncScheduler, BehaviorSubject, combineLatest, EMPTY, merge, Observable, of } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  delay,
  filter,
  map,
  observeOn,
  switchMap,
  take,
  tap,
} from 'rxjs/operators'
import * as R from 'ramda'
import * as moment from 'moment'
import * as fromRoot from '@tv3/store/state'
import {
  ChannelNameTokens,
  ConfirmDialogService,
  DataCheckerService,
  Destroyable,
  isSomething,
  Rental,
  SaveForm,
  selectAllRentals,
  selectOnce,
  selectRentalById,
  selectSomeOnce,
  untilDestroy,
  validateForm,
} from '@tokeet-frontend/tv3-platform'
import { GetGuest, SearchGuests } from '@tv3/store/guest/guest.actions'
import { selectAllGuests, selectGuestById } from '@tv3/store/guest/guest.selectors'
import { Guest } from '@tv3/store/guest/guest.model'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { AddBooking, AddBookingComplete, AddInquiry, AddInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import {
  AddInquiryDialogParamDefaults,
  AddInquiryDialogParams,
} from '@tv3/interfaces/dialogs/add-inquiry-dialog-params'
import { DeleteHoldEvent } from '@tv3/store/calendar/calendar.actions'
import { OpenInquiryOverlay } from '@tv3/store/overlay/overlay.actions'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { DiscountDialogService } from '@tv3/containers/discounts/discount-dialog.service'
import {
  AuditDiscount,
  BookingCostResolver,
  BookingEngineBookingFee,
  BookingEngineTax,
  FeeModalities,
} from '@tokeet/cost-resolver'
import {
  DEFAULT_BOOKING_ARRIVAL_TIME_PADDING,
  DEFAULT_BOOKING_DEPARTURE_TIME_PADDING,
  FeeModalityLabels,
} from '@tokeet-frontend/bookings'
import { BookingFeesService } from '@tokeet-frontend/rentals'
import { AuthService } from '@tv3/services/auth.service'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import * as lodash from 'lodash'
import { selectBookingSourcesWithCustomChannels } from '@tv3/store/channel/selectors'

@Component({
  selector: 'app-add-inquiry-dialog',
  templateUrl: './add-inquiry-dialog.component.html',
  styleUrls: ['./add-inquiry-dialog.component.scss'],
})
export class AddInquiryDialogComponent extends Destroyable implements OnInit {
  title = 'Create Inquiry'

  form = this.fb.group({
    guestId: [],
    guestName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    guestEmail: ['', [Validators.required, Validators.email]],
    guestPhone: [''],
    adults: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    children: ['', [Validators.pattern(/^\d+$/)]],
    rentalId: ['', [Validators.required]],
    arriveDate: ['', [Validators.required]],
    departDate: ['', [Validators.required]],
    charge: ['', [Validators.required]],
    source: ['tokeet', [Validators.required]],
    taxes: this.fb.array([]),
    fees: this.fb.array([]),
    potentialFees: this.fb.array([]),
    discounts: this.fb.array([this.createDiscountField()]),
    color: [],
  })

  feeModalities = FeeModalities
  feeModalityLabels = FeeModalityLabels

  guests$ = this.store.pipe(select(selectAllGuests))
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  guestSearch = new BehaviorSubject<string>('')
  engineCost = new BehaviorSubject<string>(null)
  minDepartDate = new BehaviorSubject<Date>(null)
  maxArriveDate = new BehaviorSubject<Date>(null)

  discountsOrFeesLimit = 2

  rental: Rental
  cost = new BehaviorSubject<BookingEngineCost>(null)
  originCost = new BehaviorSubject<BookingEngineCost>(null)

  isChargeUserDefined = false

  timeout

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  channels$ = this.store.pipe(
    select(selectBookingSourcesWithCustomChannels({ inquirySource: 'tokeet' })),
    map((sources) =>
      lodash
        .filter(sources, (s: any) => s.id !== ChannelNameTokens.AirBnBICal)
        .map((s) =>
          s.id !== ChannelNameTokens.AirBnBV2API
            ? s
            : { ...s, id: ChannelNameTokens.AirBnBICal, name: ChannelNameTokens.AirBnBICal }
        )
    )
  )

  get bookedDays() {
    const formData = this.form.getRawValue()
    const arrive = moment(formData.arriveDate).startOf('day')
    const depart = moment(formData.departDate).endOf('day')
    return depart.diff(arrive, 'days')
  }

  get arriveFromToday() {
    const formData = this.form.getRawValue()
    const arrive = moment(formData.arriveDate).startOf('day')
    const today = moment().startOf('day')
    return arrive.diff(today, 'days')
  }

  underMinGuests$ = this.cost.pipe(
    filter(
      (cost) =>
        isSomething(cost) &&
        (!isSomething(cost.maxguests)
          ? isSomething(cost.sleep_min)
          : isSomething(cost.sleep_min) && cost.sleep_min <= cost.maxguests)
    ),
    map((cost) => {
      const formData = this.form.getRawValue()
      const guests = (formData.adults || 0) + formData.children || 0
      return guests < cost.sleep_min ? cost.sleep_min : null
    })
  )

  overMaxGuests$ = this.cost.pipe(
    filter((cost) => isSomething(cost) && isSomething(cost.maxguests)),
    map((cost) => {
      const formData = this.form.getRawValue()
      const guests = (formData.adults || 0) + formData.children || 0
      return guests > cost.maxguests ? cost.maxguests : null
    })
  )

  underMinStay$ = this.cost.pipe(
    filter((cost) => isSomething(cost)),
    map((cost) => {
      const restrictions = lodash.head(cost.auditdays)?.restrictions
      const minStay = restrictions?.minimumstay_arrival || cost.minimumstay || 0
      if (!minStay) {
        return null
      }
      return minStay > this.bookedDays ? minStay : null
    })
  )

  overMaxStay$ = this.cost.pipe(
    filter((cost) => isSomething(cost)),
    map((cost) => {
      const restrictions = lodash.head(cost.auditdays)?.restrictions
      const maxStay = restrictions?.maximumstay_arrival || cost.maximumstay || 0
      if (!maxStay) {
        return null
      }
      return maxStay < this.bookedDays ? maxStay : null
    })
  )

  isCheckinBlocked$ = this.cost.pipe(
    map((cost) => {
      const date = lodash.head(cost?.auditdays)
      if (!date?.restrictions?.checkin_blocked) return null
      return moment(date.date).format('dddd')
    })
  )
  isCheckoutBlocked$ = this.cost.pipe(
    map((cost) => {
      const date = lodash.last(cost?.auditdays)
      if (!date?.restrictions?.checkout_blocked) return null
      return moment(date.date).format('dddd')
    })
  )

  minAdvanceDays$ = this.cost.pipe(
    map((cost) => {
      const daysStr = lodash.head(cost?.auditdays)?.restrictions?.min_advance_res
      if (!daysStr) return null
      const days = parseInt(daysStr)
      return days > this.arriveFromToday ? days : null
    })
  )

  maxAdvanceDays$ = this.cost.pipe(
    map((cost) => {
      const daysStr = lodash.head(cost?.auditdays)?.restrictions?.max_advance_res
      if (!daysStr) return null
      const days = parseInt(daysStr)
      return days < this.arriveFromToday ? days : null
    })
  )

  disabledBookingFeeIds$ = new BehaviorSubject<string[]>([])

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private fb: FormBuilder,
    private confirmDialog: ConfirmDialogService,
    private dataCheckerService: DataCheckerService,
    private inquiryChargeService: InquiryChargeService,
    private inquiryService: InquiryService,
    private bookingFeesService: BookingFeesService,
    public discountDialog: DiscountDialogService,
    public dialogRef: MatDialogRef<AddInquiryDialogComponent>,
    private authService: AuthService,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data?: AddInquiryDialogParams
  ) {
    super()
    this.dataCheckerService.check([ChannelGuard])
  }

  get discounts() {
    return this.form.get('discounts') as FormArray
  }

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  get fees() {
    return this.form.get('fees') as FormArray
  }

  get potentialFees() {
    return this.form.get('potentialFees') as FormArray
  }

  ngOnInit() {
    this.title = R.pathOr(this.title, ['title'], this.data)

    combineLatest([this.engineCost, this.form.get('rentalId').valueChanges])
      .pipe(
        debounceTime(250),
        map(() => this.getFormData()),
        filter((form: AddInquiryForm) => this.inquiryChargeService.canCallEngineCost(form)),
        concatMap((form: AddInquiryForm) =>
          this.store.pipe(
            selectOnce(selectRentalById(form.rentalId)),
            tap((rental) => (this.rental = rental)),
            map(() => form)
          )
        ),
        switchMap((form) =>
          this.disabledBookingFeeIds$.pipe(
            switchMap((ids) =>
              this.inquiryChargeService.convertFormToBookingEngineRequest(form, this.isChargeUserDefined, ids)
            )
          )
        ),
        switchMap((request) => this.inquiryChargeService.getEngineCost(request)),
        filter((cost) => isSomething(cost)),
        untilDestroy(this)
      )
      .subscribe((cost) => {
        this.cost.next(R.clone(cost))
        this.originCost.next(R.clone(cost))
        this.updateChargeAndTaxes(cost)
        this.updateValueAndValidity()
      })

    this.cost
      .pipe(
        filter(() => isSomething(this.rental?.id)),
        switchMap(() => {
          const { adults, children, arriveDate, departDate } = this.form.getRawValue()
          return this.bookingFeesService.getApplicableFees(
            this.rental.id,
            arriveDate,
            departDate,
            adults,
            children,
            this.authService.user?.account
          )
        })
      )
      .subscribe((fees) => {
        const potentialFees: FormGroup[] = R.map((fee: BookingEngineBookingFee) => this.createFeeField(fee), fees || [])
        this.form.setControl('potentialFees', this.fb.array(potentialFees))
      })

    this.store.dispatch(SearchGuests({ term: '' }))
    this.guestSearch
      .pipe(
        filter((name) => !R.isEmpty(name)),
        debounceTime(250),
        untilDestroy(this)
      )
      .subscribe((term) => {
        this.store.dispatch(SearchGuests({ term }))
      })

    merge(this.form.get('arriveDate').valueChanges, this.form.get('departDate').valueChanges)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.setArriveDepartMinMax()
      })

    this.form
      .get('guestId')
      .valueChanges.pipe(
        filter((id) => !!id),
        switchMap((id) => this.store.pipe(selectSomeOnce(selectGuestById, { id }))),
        untilDestroy(this)
      )
      .subscribe((guest) => {
        this.onSelectGuest([guest])
      })

    this.form.patchValue(this.getDefaults(this.data))

    // close after complete
    this.actions$
      .pipe(ofType(AddBookingComplete, AddInquiryComplete), take(1), untilDestroy(this))
      .subscribe(({ inquiry }) => {
        if (this.data && this.data.convertFromHold) {
          this.store.dispatch(DeleteHoldEvent({ id: this.data.convertFromHold, silent: true }))
          this.store.dispatch(OpenInquiryOverlay({ inquiryId: inquiry.id }))
        }
        this.close()
      })
  }

  updateValueAndValidity() {
    validateForm(this.form.get('adults'))
    validateForm(this.form.get('arriveDate'))
  }

  getDefaults(data: AddInquiryDialogParams) {
    const defaults: AddInquiryDialogParamDefaults = R.pathOr({}, ['defaults'], data)
    const arriveDate = defaults.arriveDate
    let departDate = defaults.departDate

    const isSameDay = moment(moment(arriveDate).format('YYYY-MM-DD')).isSame(
      moment(moment(departDate).format('YYYY-MM-DD'))
    )

    if (isSameDay && arriveDate) {
      departDate = moment(departDate).clone().add(1, 'days').toDate()
    }

    return {
      ...defaults,
      arriveDate,
      departDate,
    }
  }

  onSelectGuest([guest]: [Guest]) {
    this.form.patchValue({
      guestName: guest.name,
      guestEmail: guest.primaryEmail,
      guestPhone: guest.phone,
    })
    this.toggleGuestDetailsFields(false)
  }

  onClearSelectedGuest() {
    this.form.get('guestId').reset()
    this.toggleGuestDetailsFields(true, true)
  }

  toggleGuestDetailsFields(enable: boolean, reset = false) {
    if (reset) {
      this.form.get('guestName').reset()
      this.form.get('guestEmail').reset()
      this.form.get('guestPhone').reset()
    }

    if (enable) {
      this.form.get('guestName').enable()
      this.form.get('guestEmail').enable()
      this.form.get('guestPhone').enable()
    } else {
      this.form.get('guestName').disable()
      this.form.get('guestEmail').disable()
      this.form.get('guestPhone').disable()
    }
  }

  setArriveDepartMinMax() {
    if (this.form.get('arriveDate').value) {
      const arrive = moment(this.form.get('arriveDate').value).add(1, 'days').startOf('day')
      if (`${this.minDepartDate.value}` !== arrive.toDate().toString()) {
        // prevent date changed event when set min
        this.minDepartDate.next(arrive.toDate())
      }
    }

    if (this.form.get('departDate').value) {
      const depart = moment(this.form.get('departDate').value).subtract(1, 'days').endOf('day')
      if (`${this.maxArriveDate.value}` !== depart.toDate().toString()) {
        // prevent date changed event when set max
        this.maxArriveDate.next(depart.toDate())
      }
    }
  }

  onChargeChange(charge: number) {
    this.isChargeUserDefined = charge !== R.pathOr(0, ['base'], this.cost.value)
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      if (!R.isEmpty(charge)) {
        this.engineCost.next('')
      }
    }, 1000)
  }

  updateChargeAndTaxes(cost: BookingEngineCost) {
    this.form.patchValue({ charge: cost.base, extraGuests: cost.extraGuests }, { emitEvent: false })
    // Taxes
    const taxes: FormGroup[] = R.map((tax: BookingEngineTax) => this.createTaxField(tax), cost.taxes || [])
    this.form.setControl('taxes', this.fb.array(taxes))
    // Fees
    const fees: FormGroup[] = R.map((fee: BookingEngineBookingFee) => this.createFeeField(fee), cost.bookingFees || [])
    this.form.setControl('fees', this.fb.array(fees))
  }

  createDiscountField() {
    return this.fb.group({
      amount: [
        '',
        [
          (ctrl: AbstractControl) => {
            const v = ctrl.value
            if (v === 0) {
              return { zero: true }
            }
          },
        ],
      ],
    })
  }

  createTaxField(tax: BookingEngineTax) {
    return this.fb.group({
      taxName: [{ value: R.defaultTo('', tax.name), disabled: true }],
      taxRate: [{ value: R.defaultTo('', tax.rate), disabled: true }],
      taxAmount: [{ value: R.defaultTo('', tax.amount), disabled: true }],
    })
  }

  createFeeField(fee: BookingEngineBookingFee) {
    // @ts-ignore
    const isEnabled = !lodash.includes(this.disabledBookingFeeIds$.value, fee.pkey)
    return this.fb.group({
      selected: [isEnabled],
      type: [{ value: R.defaultTo('', fee.type), disabled: true }],
      name: [{ value: R.defaultTo('', fee.name), disabled: true }],
      rate: [{ value: R.defaultTo('', R.isNil(fee.rate) ? fee.amount : fee.rate), disabled: true }],
      amount: [{ value: R.defaultTo('', fee.amount), disabled: true }],
      modality: [{ value: R.defaultTo(FeeModalities.PerStay, fee.modality), disabled: true }],
    })
  }

  updateBookingFees() {
    const { fees } = this.form.getRawValue()
    const originCost = this.originCost.value
    const disabledBookingFees = originCost?.bookingFees.filter((f, i) => !fees[i].selected)
    // @ts-ignore
    const disabledBookingFeeIds = disabledBookingFees?.map((f) => f.pkey)
    this.disabledBookingFeeIds$.next(disabledBookingFeeIds)
  }

  addDiscountsOrFees() {
    this.discounts.push(this.createDiscountField())
  }

  removeDiscountsOrFees(index: number) {
    this.discounts.removeAt(index)
  }

  onViewDiscount(item: AuditDiscount) {
    this.discountDialog.open(item.disc_type, item.id)
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (!this.cost.value) {
      return
    }

    const { fees, taxes, potentialFees, ...formData } = this.getFormData()

    const guests = (formData.adults || 0) + formData.children || 0
    const maxGuests = this.rental.sleepMax || 0

    const submit = () => {
      const cost = this.cost.value

      const userCharge = this.isChargeUserDefined ? cost.base : null
      const quotes = BookingCostResolver.convertBookingEngine2Cost(cost, userCharge)

      this.amplitudeService.logEvent('add-manual-booking')
      if (this.data && this.data.isBooking) {
        this.store.dispatch(
          AddBooking({
            form: formData,
            quotes,
            cost,
            checkOverlapping: !this.data.convertFromHold,
          })
        )
      } else {
        this.store.dispatch(AddInquiry({ form: formData, quotes, cost }))
      }
    }

    if (maxGuests && maxGuests < guests) {
      this.confirmDialog
        .confirm({
          title: 'Maximum guests exceeded',
          body: `
          This booking exceeds the maximum ${maxGuests} guest(s) set in the rental.
          Do you want to continue creating the booking?
        `,
        })
        .subscribe(submit)
    } else {
      submit()
    }
  }

  private getFormData() {
    const formData = this.form.getRawValue()

    formData.guestEmail = decodeURIComponent(formData.guestEmail)
    formData.arriveDate = this.inquiryService.adjustInquiryDate(
      formData.arriveDate,
      DEFAULT_BOOKING_ARRIVAL_TIME_PADDING
    )
    formData.departDate = this.inquiryService.adjustInquiryDate(
      formData.departDate,
      DEFAULT_BOOKING_DEPARTURE_TIME_PADDING
    )

    return formData
  }
}
