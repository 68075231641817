import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { IncidentService } from './service'
import {
  AddIncident,
  AddIncidentComplete,
  DeleteIncident,
  DeleteIncidentComplete,
  DeleteIncidents,
  DeleteIncidentsComplete,
  LoadIncidents,
  LoadIncidentsByIds,
  LoadIncidentsByIdsComplete,
  LoadIncidentsComplete,
  UpdateIncident,
  UpdateIncidentComplete,
} from './actions'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class IncidentEffects {
  @Effect()
  loadIncidents$ = this.actions$.pipe(
    ofType(LoadIncidents),
    switchMap(({ params, pagination }) =>
      this.incidents.search(params, pagination).pipe(
        map((incidents) => LoadIncidentsComplete({ incidents })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadIncidentsByIds$ = this.actions$.pipe(
    ofType(LoadIncidentsByIds),
    switchMap(({ ids }) =>
      this.incidents.getByIds(ids).pipe(
        map((incidents) => LoadIncidentsByIdsComplete({ incidents })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addIncident$ = this.actions$.pipe(
    ofType(AddIncident),
    switchMap(({ payload }) =>
      this.incidents.create(payload).pipe(
        map((incident) => AddIncidentComplete({ incident })),

        tap(() => this.toast.success('Incident created successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateIncident$ = this.actions$.pipe(
    ofType(UpdateIncident),
    switchMap(({ payload, incidentId }) =>
      this.incidents.update(incidentId, payload).pipe(
        map((incident) => UpdateIncidentComplete({ update: { id: incidentId, changes: incident } })),

        tap(() => this.toast.success('Incident updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteIncident$ = this.actions$.pipe(
    ofType(DeleteIncident),
    switchMap(({ id }) =>
      this.incidents.delete(id).pipe(
        map((incident) => DeleteIncidentComplete({ id: incident.pkey })),
        tap(() => this.toast.success('Incident deleted successfully.')),

        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteIncidents$ = this.actions$.pipe(
    ofType(DeleteIncidents),
    concatMap(({ ids }) =>
      this.incidents.deleteIncidents(ids).pipe(
        map(() => DeleteIncidentsComplete({ ids })),
        tap(() => this.toast.success('Incidents deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private incidents: IncidentService, private toast: Toaster) {}
}
