import { Component, OnInit, Input } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import * as lodash from 'lodash'
import { distinctUntilChanged } from 'rxjs/operators'
import { getCurrenciesWithFlagIcon } from '../../constants'
import { Destroyable, distinctObjectUntilChangedByFields, untilDestroy } from '../../rx-operators'

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
})
export class CurrencySelectComponent extends Destroyable implements OnInit {
  currencies = getCurrenciesWithFlagIcon()

  _ctrl: FormControl
  @Input() set ctrl(c: FormControl) {
    this._ctrl = c
    this.currencyCodeCtrl.setValue(c.value?.code, { emitEvent: false })
  }

  @Input() inline = false

  _required = false
  @Input() set required(bool: boolean) {
    this._required = bool
    this.currencyCodeCtrl.setValidators(bool ? [Validators.required] : [])
  }
  get required() {
    return this._required
  } // false
  @Input() label = 'Currency'

  currencyCodeCtrl = new FormControl()

  ngOnInit(): void {
    this.currencyCodeCtrl.valueChanges.pipe(distinctUntilChanged(), untilDestroy(this)).subscribe((code) => {
      const currency = lodash.find(this.currencies, { code })
      this._ctrl.setValue(currency)
    })
    this._ctrl.valueChanges
      .pipe(distinctObjectUntilChangedByFields(['code']), untilDestroy(this))
      .subscribe((value) => {
        this.currencyCodeCtrl.setValue(value?.code)
      })
  }
}
