import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './website-image.reducer'
import * as state from './website-image.state'
import { TokeetImage } from '@tv3/store/website-image/website-image.model'

import * as R from 'ramda'

export const websiteImageState = createFeatureSelector<state.State>('website-image')

export const isUpdatingWebsiteImage = createSelector(websiteImageState, (state) => state.isUpdating)

export const selectAllWebsiteImages = createSelector(websiteImageState, reducer.selectAll)

export const selectAllWebsiteImagesByWebsiteId = createSelector(
  selectAllWebsiteImages,
  (images: TokeetImage[], props) => {
    return R.filter((i) => i.websiteId === props.id, images)
  }
)

export const selectWebsiteImageById = createSelector(websiteImageState, (s: state.State, props) => {
  return s.entities[props.id]
})
