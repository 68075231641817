import { Injectable } from '@angular/core'
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private router: Router) {}

  public isRouteActive(route: string, exact: boolean = false): boolean {
    return this.router.isActive(route, exact)
  }

  getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(`Control: ${key}; Error: ${keyError}`)
        })
      }
    })
  }

  getDayDiff(start: moment.Moment, end: moment.Moment) {
    const dateFormat = 'YYYY-MM-DD'
    return moment(end.format(dateFormat), dateFormat).diff(moment(start.format(dateFormat), dateFormat), 'days')
  }

  isRequired(control: any) {
    let errors: any = control.validator && (<any>control).validator(new FormControl())
    return errors !== null && errors.required
  }
}
