<mat-form-field2 class="search mat-block navbar-search-box" [showLabel]="false">
  <input
    matInput
    matTooltip="Search Inquiries, Invoices, and Guests from anywhere within the account"
    matTooltipPosition="below"
    [formControl]="searchCtrl"
    autocomplete="off"
    (focus)="onFocus()"
    placeholder="Search inquiries, invoices, and..."
    type="text"
    class="search-dropdown"
  />
  <i matPrefix class="far fa-search"></i>
  <i
    matSuffix
    *ngIf="searchCtrl.value?.length > 0"
    (click)="$event.stopPropagation(); onClearSearch()"
    class="far fa-times"
  ></i>
</mat-form-field2>
<div *ngIf="results.length > 0 && isOpen" class="search-dropdown-menu" (clickOutside)="onClickOutside($event)">
  <ul class="icon-ul">
    <ng-container *ngFor="let result of results">
      <li *ngIf="result.source.object === types.Guest">
        <a (click)="onGuestDetails(result.source.guestId)" href="javascript:;">
          <div class="li-icon-holder">
            <img src="../assets/images/icons/guest-icon.png" alt="" />
          </div>
          <div class="li-content">
            <h6>{{ result.source.title }}</h6>
            <p>
              Guest {{ guestSource(result.source.source) }}since {{ result.source.created | epoch : 'MMM YYYY' }}<br />
              {{ result.source.primaryEmail }}
            </p>
          </div>
        </a>
      </li>
      <li *ngIf="result.source.object === types.Booking">
        <a (click)="onInquiryDetails(result.source.id)" href="javascript:;">
          <div class="li-icon-holder">
            <img src="../assets/images/icons/booking-icon.png" alt="" />
          </div>
          <div class="li-content">
            <h6>{{ result.source.title }}</h6>
            <p>
              Booking for {{ (result.source.numAdults || 0) + (result.source.numChild || 0) }} at
              {{ result.source.rentalId | rentalName | async }}<br />
              Starting {{ result.source.guestArrive | epochUTC : 'DD - MMM - YYYY' }}, ending
              {{ result.source.guestDepart | epochUTC : 'DD - MMM - YYYY' }}
            </p>
          </div>
        </a>
      </li>
      <li *ngIf="result.source.object === types.Invoice">
        <a (click)="onInvoiceDetails(result.source.id)" href="javascript:;">
          <div class="li-icon-holder">
            <img src="../assets/images/icons/invoice-icon.png" alt="" />
          </div>
          <div class="li-content">
            <h6>{{ result.source.title }}</h6>
            <p>
              Invoice for booking {{ result.source.rentalId | rentalName | async }}<br />
              Due {{ result.source.dueDate | epoch : 'DD - MMM - YYYY' }}, {{ result.source.status | invoiceStatus }}
            </p>
          </div>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
