import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface InfoDialogParams {
  title?: string
  body?: string
  closeText?: string
  closeButtonClass?: string
  extra?: string
}

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  isExtraChecked = false
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogParams
  ) {}

  toggleExtra() {
    this.isExtraChecked = !this.isExtraChecked
  }

  close() {
    this.dialogRef.close({ extra: this.isExtraChecked })
  }
}
