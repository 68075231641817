import { Template } from './template.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { pathOr } from 'ramda'

export interface State extends EntityState<Template> {
  isLoaded: boolean
  isUpdating: boolean
  lastAddedId: string
  lastEditedId: string
}

export const adapter: EntityAdapter<Template> = createEntityAdapter<Template>({
  sortComparer: (a: Template, b: Template) =>
    pathOr('', ['name'], a)
      .toString()
      .localeCompare(pathOr('', ['name'], b), undefined, { numeric: true, sensitivity: 'base' }),
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
  lastAddedId: null,
  lastEditedId: null,
})
