import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import {
  Account,
  AccountGuard,
  Destroyable,
  notAllSpacesValidator,
  SaveForm,
  selectAccount,
  selectAllRentals,
  selectSomeOnce,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import {
  domainsByWebsiteType,
  filterWebsiteTemplate,
  tooltipsForTemplate,
  Website,
  WebsiteTemplateNames,
  WebsiteTypes,
} from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { AddWebsitePayload, WebsiteTemplate } from '@tv3/store/website/website.types'
import { AddWebsite } from '@tv3/store/website/website.actions'
import { AuthService } from '@tv3/services/auth.service'
import { Observable, of } from 'rxjs'
import { map, startWith, switchMap } from 'rxjs/operators'
import { selectAllWebsites, selectAllWebsiteTemplates } from '@tv3/store/website/website.selectors'
import { WebsiteTemplateGuard } from '@tv3/guards/website-template.guard'
import * as R from 'ramda'
import { AmplitudeService } from '@tv3/services/amplitude.service'

export class DomainExistsValidator {
  static createValidator(store: Store<any>) {
    return (group: FormGroup) => {
      const domain = (group.controls['subdomain'].value || '').trim() + (group.controls['domain'].value || '').trim()
      store
        .pipe(
          selectSomeOnce(selectAllWebsites),
          map((websites) => R.map((w: Website) => w.domain, websites || [])),
          map((domains) => R.contains(domain, domains))
        )
        .subscribe((match) => {
          if (match) {
            group.controls['subdomain'].setErrors({ domainExists: true })
          } else {
            group.controls['subdomain'].setErrors(null)
          }
        })
    }
  }
}

export class WebsiteHelper {
  static getArticleId(type: WebsiteTypes) {
    switch (type) {
      // NOTE: https://tokeet.atlassian.net/browse/TV4-1576?focusedCommentId=86135
      case WebsiteTypes.BookingEngine:
        return '-'
      case WebsiteTypes.GuestPortal:
        return '-'
      default:
        return '-'
    }
  }
}

@Component({
  selector: 'app-add-website-dialog',
  templateUrl: './add-website-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-website-dialog.component.scss'],
})
export class AddWebsiteDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group(
    {
      title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      description: [undefined],
      domain: [undefined, [Validators.required]],
      subdomain: [
        undefined,
        [Validators.required, Validators.pattern(/^[\w|\d]{1}[a-zA-Z0-9-_]+$/), notAllSpacesValidator],
      ],
      rentals: [[], [Validators.required]],
      template: [undefined, []],
    },
    { validators: [DomainExistsValidator.createValidator(this.store)] }
  )

  tooltipsForTemplate = tooltipsForTemplate

  websiteTypes = WebsiteTypes
  templates$: Observable<WebsiteTemplate[]>
  rentals$ = this.store.pipe(select(selectAllRentals))
  domains: string[] = []
  account: Account
  articleId = ''

  constructor(
    public dialogRef: MatDialogRef<AddWebsiteDialogComponent>,
    private store: Store<fromRoot.State>,
    private dataCheckerService: DataCheckerService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { type: WebsiteTypes; title: string; articleId: string },
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService
  ) {
    super()
    this.dataCheckerService.check([AccountGuard, WebsiteTemplateGuard])
  }

  ngOnInit() {
    this.domains = domainsByWebsiteType[this.data.type]
    this.store.pipe(select(selectAccount(this.auth.user.account)), untilDestroy(this)).subscribe((account: Account) => {
      this.account = account
    })
    if (this.data.type !== WebsiteTypes.GuestPortal) {
      this.form.get('template').setValidators([Validators.required])
    }

    this.templates$ = this.store.pipe(select(selectAllWebsiteTemplates)).pipe(
      switchMap((templates) =>
        this.form.valueChanges.pipe(
          startWith(this.form.getRawValue()),
          map(({ rentals }) => {
            return filterWebsiteTemplate(templates, this.data.type, rentals.length)
          })
        )
      ),
      untilDestroy(this)
    )

    // check template
    this.form
      .get('rentals')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((rentals) => {
        const template = this.form.get('template').value as WebsiteTemplate
        if (rentals && rentals.length > 1 && template && template.name === WebsiteTemplateNames.SandratiTemplate) {
          this.form.patchValue({ template: undefined })
        }
      })

    this.articleId = WebsiteHelper.getArticleId(this.data.type)
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.form.getRawValue()

    const payload: AddWebsitePayload = {
      title: formData.title,
      description: formData.description,
      domain: `${formData.subdomain}${formData.domain}`,
      rentals: formData.rentals,
      template: this.data.type === WebsiteTypes.GuestPortal ? 'default' : formData.template.name,
      template_type: this.data.type === WebsiteTypes.GuestPortal ? 'multiple' : formData.template.properties,
      is_engine: this.data.type === WebsiteTypes.BookingEngine ? 1 : 0,
      is_portal: this.data.type === WebsiteTypes.GuestPortal ? 1 : 0,
      contact: {
        name: this.account.name,
        email: this.account.email,
        phone: this.account.phone,
        domain: this.account.domain,
        address: this.account.address,
      },
    }

    switch (this.data.type) {
      case WebsiteTypes.RentalWebsite:
        this.amplitudeService.logEvent('added-website')
        break
      case WebsiteTypes.BookingEngine:
        this.amplitudeService.logEvent('added-booking-engine')
        break
      case WebsiteTypes.GuestPortal:
        this.amplitudeService.logEvent('added-guest-portal')
        break
    }

    this.store.dispatch(AddWebsite({ data: payload }))

    this.dialogRef.close()
  }
}
