import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'gatewayLogo',
})
export class GatewayLogoPipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'test':
        return 'assets/images/spreedly_logo.svg'
      case 'stripe':
        return 'assets/gateways/stripe.svg'
      case 'authorize_net':
        return 'assets/gateways/authorize_net.svg'
      case 'mercado_pago':
        return 'assets/gateways/mercado_pago.png'
      case 'braintree':
        return 'assets/gateways/braintree.svg'
      case 'optimal_payments':
        return 'assets/gateways/paysafe.svg'
      case 'paymill':
        return 'assets/gateways/paymill.png'
      case 'paypal_commerce_platform':
        return 'assets/gateways/paypal.svg'
      case 'worldpay':
        return 'assets/gateways/worldpay.png'
    }
  }
}
