import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Channel, ChannelNameTokens, Destroyable, Rental } from '@tokeet-frontend/tv3-platform'
import { HomeAwayRentalConfigView, HomeToGoRentalConfigView } from '@tokeet-frontend/channels'

@Component({
  selector: 'app-feed-connection-details',
  templateUrl: './feed-connection-details.component.html',
  styleUrls: ['./feed-connection-details.component.scss'],
})
export class FeedConnectionDetailsComponent extends Destroyable implements OnInit {
  @Input() connection: any
  @Input() channel: Channel
  @Input() config: HomeToGoRentalConfigView | HomeAwayRentalConfigView
  @Output() toggle = new EventEmitter<HomeToGoRentalConfigView | HomeAwayRentalConfigView>()
  @Output() rentalOverlay = new EventEmitter()

  isHomeAway = false
  isHomeToGo = false

  ngOnInit() {
    this.isHomeAway = this.channel.name === ChannelNameTokens.Homeaway
    this.isHomeToGo = this.channel.name === ChannelNameTokens.HomeToGo
  }
}
