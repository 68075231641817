<div class="modal-header">
  <div class="d-flex align-items-center gap-3 flex-1">
    <h6 class="modal-title"><i class="far fa-user"></i> User Detail</h6>
    <div class="flex-fill"></div>
  </div>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="user-contact-banner p-3 d-flex align-items-center gap-3">
  <div style="height: 80px; margin: -1rem 0; bottom: -0.5rem" class="position-relative">
    <ng-container *ngIf="user?.attributes?.gender as gender; else avatar">
      <img *ngIf="gender === 'male'" src="/assets/images/man.png" alt="" class="h-100" />
      <img *ngIf="gender === 'female'" src="/assets/images/woman.png" alt="" class="h-100" />
    </ng-container>
    <ng-template #avatar>
      <app-avatar [text]="user.name" [size]="76" [border]="2" borderColor="white"></app-avatar>
    </ng-template>
  </div>
  <div class="flex-fill">
    <h5>
      {{ user.name }}
      <small class="text-muted text-sm"
        >(User ID: {{ user.id }}) <app-copy-icon [content]="user.id"></app-copy-icon
      ></small>
    </h5>
    <div class="text-sm d-flex align-items-center gap-3">
      <div class="d-flex align-items-center gap-2" matTooltip="Phone">
        <i class="cs-icon cs-icon-phone text-primary"></i>
        <ng-template [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ text: user.phoneView }"></ng-template>
        <app-copy-icon [content]="user.phone" *ngIf="user.phone"></app-copy-icon>
      </div>
      <div class="d-flex align-items-center gap-2" matTooltip="Email">
        <i class="cs-icon cs-icon-envelope text-danger"></i>
        {{ user.primaryEmail }}
        <app-copy-icon [content]="user.primaryEmail" *ngIf="user.primaryEmail"></app-copy-icon>
      </div>
      <div
        class="d-flex align-items-center gap-2 text-capitalize"
        matTooltip="Gender"
        *ngIf="user?.attributes?.gender as gender"
      >
        <i
          class="cs-icon"
          [ngClass]="{ 'cs-icon-male text-info': gender === 'male', 'cs-icon-female text-indigo': gender === 'female' }"
        ></i>
        {{ gender }}
      </div>
    </div>
  </div>
  <button class="btn btn-light btn-circle" (click)="onEdit()" matTooltip="Edit user details">
    <i class="cs-icon cs-icon-pencil"></i>
  </button>
</div>
<div class="modal-body p-0 bg-none">
  <div class="d-flex flex-column p-3 gap-3 container-box">
    <div class="d-flex gap-2">
      <i class="cs-icon cs-icon-gps2 mt-1 text-info"></i>
      <div>
        <div class="text-semi-bold">Address</div>
        <div>
          <ng-template
            [ngTemplateOutlet]="field"
            [ngTemplateOutletContext]="{ text: (user.address | address : false) }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="d-flex gap-2">
        <i class="cs-icon cs-icon-user-card mt-1 text-warning"></i>
        <div>
          <div class="text-semi-bold">User Role</div>
          <div>
            <ng-template [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ text: role }"></ng-template>
          </div>
        </div>
      </div>

      <div class="d-flex gap-2">
        <i class="cs-icon cs-icon-globe2 mt-1 text-indigo"></i>
        <div>
          <div class="text-semi-bold">Language</div>
          <div>
            <ng-template [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ text: language }"></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-box mt-3">
    <div class="d-flex align-items-center">
      <ul class="nav top-navbar pt-2" role="tablist">
        <li
          [class.active]="currentTab$.value === 'settings'"
          matTooltip="Set the apps which user will be restricted from accessing"
        >
          <a (click)="currentTab$.next('settings')"> User Settings </a>
        </li>
        <ng-container *ngIf="!isCurrentUser">
          <li
            [class.active]="currentTab$.value === 'rentals'"
            *ngIf="canRestrictRentals()"
            matTooltip="Select the rentals which this user will be restricted from accessing."
            matTooltipPosition="above"
          >
            <a (click)="currentTab$.next('rentals')"> Restricted Rentals </a>
          </li>
          <li
            [class.active]="currentTab$.value === 'ownerRentals'"
            *ngIf="isOwner(user)"
            matTooltip="Select the rentals which will be visible for this owner."
            matTooltipPosition="above"
          >
            <a (click)="currentTab$.next('ownerRentals')"> Owner Rentals </a>
          </li>
          <li [class.active]="currentTab$.value === 'attributes'">
            <a (click)="currentTab$.next('attributes')"> Custom Attributes </a>
          </li>
        </ng-container>
      </ul>
      <div class="flex-fill"></div>
      <app-table-search
        (search)="rentalsSearchTerm = $event"
        class="mr-3"
        *ngIf="currentTab$.value === 'rentals' || currentTab$.value === 'ownerRentals'"
        style="min-width: 10rem"
      >
      </app-table-search>
    </div>
    <div [ngSwitch]="currentTab$.value">
      <ng-container *ngSwitchCase="'settings'">
        <ng-template [ngTemplateOutlet]="preferences"></ng-template>
      </ng-container>
      <div *ngSwitchCase="'rentals'" class="m-3">
        <app-restricted-rentals [user]="user" [searchTerm]="rentalsSearchTerm"></app-restricted-rentals>
      </div>
      <div *ngSwitchCase="'ownerRentals'" class="m-3">
        <app-owner-rentals [ownerId]="user.id" [searchTerm]="rentalsSearchTerm"></app-owner-rentals>
      </div>
      <div class="bg-white p-3 rounded-bottom" *ngSwitchCase="'attributes'">
        <app-entity-attributes
          #attrs
          entityType="user"
          [entityId]="user.id"
          [showActions]="true"
        ></app-entity-attributes>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    *ngIf="!isOwner(user)"
    (click)="onResetPassword()"
    matTooltip="Send password reset email to this user."
    matTooltipPosition="below"
    type="button"
    class="btn btn-secondary-info"
  >
    <i class="fal fa-key"></i>
    Reset Password
  </button>
  <div class="flex-fill"></div>

  <button
    *ngIf="!isCurrentUser"
    (click)="onDelete()"
    type="button"
    class="btn btn-secondary-danger"
    matTooltip="Delete this user"
    matTooltipPosition="above"
  >
    <span class="cs-icon cs-icon-trash"></span>
    Delete
  </button>
</div>

<ng-template #field let-text="text">
  <ng-container *ngIf="text">{{ text }}</ng-container>
  <span class="text-light text-xs" *ngIf="!text">Not Provided</span>
</ng-template>

<ng-template #preferences>
  <div class="p-4">
    <form [formGroup]="preferenceForm" *ngIf="!isCurrentUser">
      <div class="row gap-row-3">
        <div class="col-sm-4">
          <mat-checkbox
            matTooltip="Disabled users cannot login to AdvanceCM or any of the AdvanceCM add-on applications."
            matTooltipPosition="above"
            formControlName="disabled"
          >
            Disable AdvanceCM Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableAutomata()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Automata."
            matTooltipPosition="above"
            formControlName="automata"
          >
            Enable Automata Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableSignature()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Signature."
            matTooltipPosition="above"
            formControlName="signature"
          >
            Enable Signature Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableOwnerCenter()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Owner Center."
            matTooltipPosition="above"
            formControlName="ownercenter"
          >
            Enable Owner <span *ngIf="isOwner(user)">Portal</span><span *ngIf="!isOwner(user)">Center</span>
            Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableRategenie()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Rategenie."
            matTooltipPosition="above"
            formControlName="rategenie"
          >
            Enable Rategenie Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableWebready()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Webready."
            matTooltipPosition="above"
            formControlName="webready"
          >
            Enable Webready Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableChecklist()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Checklist."
            matTooltipPosition="above"
            formControlName="checklist"
          >
            Enable Checklist Login
          </mat-checkbox>
        </div>
        <div *ngIf="canEnableMargins()" class="col-sm-4">
          <mat-checkbox
            matTooltip="Enable this user to login to Margins."
            matTooltipPosition="above"
            formControlName="margins"
          >
            Enable Margins Login
          </mat-checkbox>
        </div>

        <div *ngIf="isOwner(user)" class="col-sm-4">
          <mat-checkbox
            [matTooltip]="
              !!preferenceForm.get('total').value
                ? 'Uncheck this field to hide booking charges from owner'
                : 'Check this field for showing booking charges to owner'
            "
            matTooltipPosition="above"
            formControlName="total"
          >
            Show Charge Details
          </mat-checkbox>
        </div>
      </div>
      <app-staff-permissions [user]="user" *ngIf="isStaff(user)" class="my-4 d-block"></app-staff-permissions>
    </form>

    <form [formGroup]="mfaForm" *ngIf="isCurrentAdmin || isCurrentUser">
      <hr *ngIf="!isCurrentUser" />
      <h6>Multi-Factor Authentication (MFA) Configuration</h6>

      <div class="alert alert-secondary-warning" *ngIf="isAccountMFAEnabled$ | async">
        <div class="alert-content">
          MFA has been enabled for all users in this account by an administrator. Once account level MFA is enabled your
          user level MFA checkbox is ignored. However, you may still select your delivery method.
        </div>
      </div>

      <app-form-checkbox
        [checkedValue]="1"
        [uncheckedValue]="0"
        formControlName="status"
        class="mb-3 d-inline-block"
        [matTooltip]="
          isCurrentUser
            ? 'Use below Configure MFA Button to edit MFA settings for yourself'
            : 'User can only enable MFA for their own account.'
        "
        >Enable MFA</app-form-checkbox
      >
      <div class="row">
        <div class="col-md-6">
          <mat-form-field2>
            <mat-select formControlName="delivery" placeholder="Delivery Method">
              <mat-option [value]="item.id" *ngFor="let item of mfaDeliveryMethodOptions">{{ item.label }}</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <button
            (click)="onResetTotp()"
            type="button"
            class="btn btn-secondary-danger align-input-row-end"
            matTooltip="You need to remove your account from the Authenticator App as well after deregister your device from here."
            *ngIf="isCurrentUser && isTotpSelected && (isTotpDeviceRegistered$ | async)"
          >
            <i class="cs-icon cs-icon-unlink"></i>
            Deregister auth device
          </button>
        </div>
      </div>
      <button class="btn btn-secondary-primary" (click)="onConfigMFA()" *ngIf="isCurrentUser">
        <i class="cs-icon cs-icon-cog"></i> Configure MFA
      </button>
    </form>

    <div *ngIf="!isCurrentUser && !isCurrentAdmin">
      <div class="current-user-box">
        <i class="fal fa-ban"></i>
        <h5>You cannot update settings for your own user account.</h5>
      </div>
    </div>
  </div>
</ng-template>
