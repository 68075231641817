import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { NgControl } from '@angular/forms'
import { isInteger } from 'lodash'

@Directive({
  selector: 'input[type=number][decimal]',
})
export class DecimalDirective {
  @Input() decimal
  private defaultDecimal = 2
  private numeralDecimalMark = '.'
  constructor(private el: ElementRef, private control: NgControl) {}

  getDecimal(): number {
    return isInteger(parseInt(this.decimal, 10)) ? parseInt(this.decimal, 10) : this.defaultDecimal
  }

  @HostListener('input', ['$event']) onEvent($event) {
    let currentValue = this.el.nativeElement.value
    if (!currentValue) {
      return
    }

    let partInteger = currentValue
    let partDecimal = ''
    if (currentValue.indexOf(this.numeralDecimalMark) >= 0) {
      const parts = currentValue.split(this.numeralDecimalMark)
      partInteger = parts[0]
      partDecimal = this.numeralDecimalMark + parts[1].slice(0, this.getDecimal())

      currentValue = partInteger.toString() + (this.getDecimal() > 0 ? partDecimal.toString() : '')
      this.control.control.setValue(currentValue)
    }
  }
}
