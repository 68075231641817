import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import {
  BDCContentProductPayload,
  BDCContentPropertyPayload,
  BDCContentRatePayload,
  BDCContentRoomPayload,
} from '@tv3/store/channel-content/payload/bdc.request'
import {
  BDCContentPropertyResponse,
  BDCContentRateResponse,
  BDCContentRoomResponse,
} from '@tv3/store/channel-content/payload/bdc.response'
import {
  BDCContentProperty,
  BDCContentPropertySummary,
  BDCLicenseCheckResult,
  BDCProduct,
  BDCRatePlan,
} from '@tv3/store/channel-content/models/bdc.model'
import { get } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class BdcContentService {
  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {}

  get(propertyId: any): Observable<BDCContentProperty> {
    const url = `@api/bdc/content/property/${propertyId}/details`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  summary(propertyId: any): Observable<BDCContentPropertySummary> {
    const url = `@api/bdc/content/property/${propertyId}/summary`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  create(payload: BDCContentPropertyPayload): Observable<BDCContentPropertyResponse> {
    const url = `@api/bdc/content/property`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  update(id: number | string, payload: BDCContentPropertyPayload): Observable<BDCContentPropertyResponse> {
    const url = `@api/bdc/content/property/${id}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  createRoom(propertyId: any, payload: BDCContentRoomPayload): Observable<BDCContentRoomResponse> {
    const url = `@api/bdc/content/property/${propertyId}/room`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  updateRoom(propertyId: any, roomId: any, payload: BDCContentRoomPayload): Observable<BDCContentRoomResponse> {
    const url = `@api/bdc/content/property/${propertyId}/room/${roomId}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getRates(propertyId: any): Observable<BDCRatePlan[]> {
    const url = `@api/bdc/content/property/${propertyId}/rate`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  createRate(propertyId: any, payload: BDCContentRatePayload): Observable<BDCContentRateResponse> {
    const url = `@api/bdc/content/property/${propertyId}/rate`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  updateRate(propertyId: any, rateId: any, payload: BDCContentRatePayload): Observable<BDCContentRateResponse> {
    const url = `@api/bdc/content/property/${propertyId}/rate/${rateId}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getProducts(propertyId: any): Observable<BDCProduct[]> {
    const url = `@api/bdc/content/property/${propertyId}/product`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  createProduct(payload: BDCContentProductPayload): Observable<any> {
    const url = `@api/bdc/content/product`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  removeProduct(propertyId: any, roomId: any, rateId: any): Observable<any> {
    const url = `@api/bdc/content/product/${propertyId}/${roomId}/${rateId}`
    return this.http.delete(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  checkLicense(lat: number, long: number, countryCode2: string): Observable<BDCLicenseCheckResult> {
    const url = `@api/bdc/content/check-license`
    return this.http.post<any>(url, { countryCode2, lat, long }).pipe(
      map(({ data }) => get(data, '0')),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getPropertyAmenities(): Observable<{ [key: string]: number }> {
    const url = `@api/bdc/content/metadata/property-amenities`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getRoomAmenities(): Observable<{ [key: string]: number }> {
    const url = `@api/bdc/content/metadata/room-amenities`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getBedTypes(): Observable<{ [key: string]: number }> {
    const url = `@api/bdc/content/metadata/bed-types`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }
}
