import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, ChannelNameTokens, Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import {
  getRentalConfigs,
  HomeAwayRentalConfigView,
  PauseRentalConfig,
  ResumeHomeAwayRentalConfig,
} from '@tokeet-frontend/channels'
import { BehaviorSubject, Subject } from 'rxjs'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { switchMap, tap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { PushRatesToConnection } from '@tv3/store/connection/connection.actions'

@Component({
  selector: 'app-homeaway-connection-overlay',
  templateUrl: './homeaway-connection-overlay.component.html',
  styleUrls: ['./homeaway-connection-overlay.component.scss'],
})
export class HomeAwayConnectionOverlayComponent extends Destroyable implements OnInit {
  dateFormat = 'DD-MMM-YYYY | h:mm A'
  channel: Channel
  connection: ConnectionView
  config: HomeAwayRentalConfigView

  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    switchMap(() => this.connectionService.getApiConnectionLog(this.channel.name, this.connection.rentalId))
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: HomeAwayRentalConfigView; connection: ConnectionView },
    private connectionService: ConnectionService,
    private connectionActionService: ConnectionHelperService,
    private store: Store<any>,
    public dialogRef: MatDialogRef<HomeAwayConnectionOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.connection = this.data.connection
    this.channel = this.data.connection.channel
    this.config = this.data.config

    this.store.pipe(getRentalConfigs(ChannelNameTokens.Homeaway), untilDestroy(this)).subscribe((configs) => {
      this.config = configs.find((c) => c.rental_id === this.data.config.rental_id)
    })
  }

  close() {
    this.dialogRef.close()
  }

  onPushRates() {
    this.connectionActionService
      .checkBeforePushRates(this.connection.rental, this.connection)
      .pipe(
        tap(() => {
          this.store.dispatch(
            PushRatesToConnection({
              connId: this.connection.id,
              channelId: this.connection.channelId,
              channelName: this.channel.name,
              data: {
                roomId: this.connection.roomId,
                rental_id: this.connection.rentalId,
              },
            })
          )
        })
      )
      .subscribe()
  }

  onPushAvailability() {
    this.connectionActionService.pushAvailability(this.channel.name, this.connection).subscribe()
  }

  onToggleStatus() {
    if (this.config.status === 1) {
      this.store.dispatch(PauseRentalConfig({ config: this.config, channel: this.channel.name }))
    } else {
      this.store.dispatch(ResumeHomeAwayRentalConfig({ config: this.config, channel: this.channel.name }))
    }
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
