import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, concatMap, map, switchMap, take, tap } from 'rxjs/operators'
import { GuestService } from '@tv3/store/guest/guest.service'
import {
  AddGuest,
  AddGuestComplete,
  AddGuestEmail,
  AddGuestEmailComplete,
  AddGuestInteraction,
  AddGuestInteractionComplete,
  AddGuestNote,
  AddGuestNoteComplete,
  AddGuestNotifyComplate,
  AddGuestPhone,
  AddGuestPhoneComplete,
  AddTagsToGuest,
  AddTagsToGuestComplete,
  ArchiveGuest,
  ArchiveGuestComplete,
  DeleteGuest,
  DeleteGuestComplete,
  DeleteGuestEmail,
  DeleteGuestEmailComplete,
  DeleteGuestMessageComplete,
  DeleteGuestNote,
  DeleteGuestNoteComplete,
  DeleteGuestPhone,
  DeleteGuestPhoneComplete,
  DeleteGuests,
  DeleteGuestsComplete,
  FetchGuests,
  FetchGuestsComplete,
  GetGuest,
  GetGuestBookings,
  GetGuestBookingsComplete,
  GetGuestComplete,
  GetGuestEmails,
  GetGuestEmailsComplete,
  LoadGuests,
  LoadGuestsComplete,
  SearchElasticGuests,
  SearchElasticGuestsComplete,
  SearchGuests,
  SearchGuestsComplete,
  UnarchiveGuest,
  UnarchiveGuestComplete,
  UpdateGuest,
  UpdateGuestComplete,
  UpdateGuestFiles,
  UpdateGuestFilesComplete,
  UpdateGuestInteractions,
  UpdateGuestInteractionsComplete,
  UpdateGuestItem,
  UpdateGuestItemComplete,
  UpdateGuestNote,
  UpdateGuestNoteComplete,
  UpdateGuestsComplete,
} from '@tv3/store/guest/guest.actions'
import { forkJoin } from 'rxjs'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { selectOnce, selectSomeOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { UpdateInquiryGuestComplete } from '@tv3/store/inquiry/inquiry-fields.actions'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { SearchService } from '@tv3/store/search/search.service'
import * as fromRoot from '@tv3/store/state'
import { selectAllGuests, selectGuestById } from '@tv3/store/guest/guest.selectors'
import { DeleteInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import { Guest, GuestBooking, GuestNote } from '@tv3/store/guest/guest.model'
import { DeleteMessageComplete } from '@tv3/store/message/message.actions'
import { Message } from '@tv3/store/message/message.model'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Injectable()
export class GuestEffects {
  @Effect()
  allGuests$ = this.actions$.pipe(
    ofType(LoadGuests),
    switchMap(({ pagination }) =>
      this.guests.getAll(pagination).pipe(
        map((res) => LoadGuestsComplete(res)),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  searchGuests$ = this.actions$.pipe(
    ofType(SearchGuests),
    switchMap(({ term }) =>
      this.guests.search(term).pipe(
        map((guests) => SearchGuestsComplete({ guests })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  searchElasticGuests$ = this.actions$.pipe(
    ofType(SearchElasticGuests),
    concatMap(({ term }) =>
      this.searchService.searchGuests(term).pipe(
        concatMap((searched) =>
          this.store.pipe(
            select(selectAllGuests),
            take(1),
            /**
             * guests not present in the store
             * and in need of fetching to replace
             * the incomplete guests originating from elastic search
             */
            map((guests) => R.differenceWith((a, b) => a.id === b.id, searched, guests))
          )
        ),
        map((guests) => SearchElasticGuestsComplete({ guests })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  fetchElasticGuests$ = this.actions$.pipe(
    ofType(SearchElasticGuestsComplete),
    concatMap(({ guests }) => {
      if (R.isEmpty(guests)) {
        return of(ActionSkipped())
      } else {
        return of(FetchGuests({ ids: R.map((i) => i.id, guests) }))
      }
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  @Effect()
  fetchGuests$ = this.actions$.pipe(
    ofType(FetchGuests),
    concatMap(({ ids }) =>
      this.guests.fetch(ids).pipe(
        map((guests) => FetchGuestsComplete({ guests })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getGuest$ = this.actions$.pipe(
    ofType(GetGuest),
    switchMap(({ id }) =>
      this.guests.get(id).pipe(
        map((guest) => GetGuestComplete({ guest })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addGuest$ = this.actions$.pipe(
    ofType(AddGuest),
    map(({ form }) => {
      return {
        name: form.name,
        phone: form.phone,
        email: form.email,
        source: 'AdvanceCM',
        address: {
          city: form.city,
          country: form.country,
          country_code: form.country_code,
        },
      } as UpdateGuestRequest
    }),
    switchMap((payload) =>
      this.guests.create(payload).pipe(
        map((guest) => AddGuestComplete({ guest })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addGuestComplete$ = this.actions$.pipe(
    ofType(AddGuestComplete),
    switchMap(({ guest }) => {
      this.guests.onAddGuest$.emit(guest)
      return of(guest).pipe(
        map((guest) => AddGuestNotifyComplate({ guest })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  addTagsToGuest$ = this.actions$.pipe(
    ofType(AddTagsToGuest),
    switchMap(({ guest, tags, newTags }) =>
      this.guests.addTagsToGuest(guest, tags).pipe(
        map(({ id, tags }) => AddTagsToGuestComplete({ update: { id: guest.id, changes: { tags } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGuest$ = this.actions$.pipe(
    ofType(UpdateGuest),
    switchMap((payload) =>
      this.guests.update(payload.guestId, payload.form).pipe(
        switchMap((guest) => {
          if (payload.inquiry) {
            return this.inquiryService
              .updateInquiry(payload.inquiry.id, { guest_details: { name: guest.name, email: guest.primaryEmail } })
              .pipe(
                switchMap((inquiry) => {
                  return [
                    UpdateGuestComplete({ update: { id: guest.id, changes: guest } }),
                    UpdateInquiryGuestComplete({
                      update: { id: payload.inquiry.id, changes: { guest, guestDetails: inquiry.guestDetails } },
                    }),
                  ]
                })
              )
          }

          return [UpdateGuestComplete({ update: { id: guest.id, changes: guest } })]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGuestFiles$ = this.actions$.pipe(
    ofType(UpdateGuestFiles),
    switchMap(({ guestId, files }) =>
      this.guests.updateFiles(guestId, files).pipe(
        switchMap((guest) => {
          return [
            UpdateGuestComplete({ update: { id: guest.id, changes: guest } }),
            UpdateGuestFilesComplete({ guestId: guest.id, data: guest }),
          ]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGuestItem$ = this.actions$.pipe(
    ofType(UpdateGuestItem),
    switchMap(({ guestId, data, message }) =>
      this.guests.update(guestId, data, message).pipe(
        switchMap((guest) => {
          return [GetGuest({ id: guest.id }), UpdateGuestItemComplete({ guestId: guest.id, data: guest })]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGuestEmail$ = this.actions$.pipe(
    ofType(DeleteGuestEmail),
    switchMap(({ guestId, data }) =>
      this.guests.deleteEmail(guestId, data).pipe(
        switchMap((guest) => {
          return [
            UpdateGuestComplete({ update: { id: guest.id, changes: guest } }),
            DeleteGuestEmailComplete({ guestId: guest.id, data: guest }),
          ]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addGuestEmail$ = this.actions$.pipe(
    ofType(AddGuestEmail),
    map(async ({ guestId, data }) => {
      const emails = typeof data === 'string' ? [data] : data
      const guests = []

      let i = 0
      for (const email of emails) {
        i++
        const noMessage = i < emails.length
        const g = await this.guests
          .addEmail(guestId, { email }, noMessage, 'Guest Emails added successfully!')
          .toPromise()
        guests.push(g)
      }
      return guests
    }),
    switchMap((guests) =>
      from(guests).pipe(
        switchMap((guest) => {
          const lastGuest = guest[guest.length - 1]
          return [
            UpdateGuestComplete({ update: { id: lastGuest.id, changes: lastGuest } }),
            AddGuestEmailComplete({ guestId: lastGuest.id, data: lastGuest }),
          ]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGuestPhone$ = this.actions$.pipe(
    ofType(DeleteGuestPhone),
    switchMap(({ guestId, data }) =>
      this.guests.deletePhone(guestId, data).pipe(
        switchMap((guest) => {
          return [GetGuest({ id: guest.id }), DeleteGuestPhoneComplete({ guestId: guest.id, data: guest })]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addGuestPhone$ = this.actions$.pipe(
    ofType(AddGuestPhone),
    map(async ({ guestId, data }) => {
      const phones = typeof data === 'string' ? [data] : data
      const guests = []

      let i = 0
      for (const phone of phones) {
        i++
        const noMessage = i < phones.length
        const g = await this.guests
          .addPhone(guestId, { phone: phone }, noMessage, 'Guest Phones added successfully!')
          .toPromise()
        guests.push(g)
      }
      return guests
    }),
    switchMap((guests) =>
      from(guests).pipe(
        switchMap((guest) => {
          const lastGuest = guest[guest.length - 1]
          return [
            UpdateGuestComplete({ update: { id: lastGuest.id, changes: lastGuest } }),
            AddGuestPhoneComplete({ guestId: lastGuest.id, data: lastGuest }),
          ]
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGuest$ = this.actions$.pipe(
    ofType(DeleteGuest),
    concatMap(({ id }) =>
      this.guests.delete(id).pipe(
        tap(() => {
          this.amplitudeService.logEvent('delete-guest')
        }),
        map(() => DeleteGuestComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGuests$ = this.actions$.pipe(
    ofType(DeleteGuests),
    concatMap(({ ids }) =>
      this.guests.removeGuests(ids).pipe(
        map((removedIds) => DeleteGuestsComplete({ ids: removedIds })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveGuest$ = this.actions$.pipe(
    ofType(ArchiveGuest),
    map(({ ids }) => {
      ids = R.clone(typeof ids === 'string' ? [ids] : ids)
      return ids.map((id) => this.guests.archive(id))
    }),
    switchMap((list) =>
      forkJoin(list).pipe(
        tap(() => {
          this.amplitudeService.logEvent('archive-guest')
        }),
        tap(() => this.toast.success('Guest(s) archived successfully!')),
        switchMap((guests) => guests.map((g: any) => ArchiveGuestComplete({ update: { id: g.id, changes: g } }))),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unArchiveGuest$ = this.actions$.pipe(
    ofType(UnarchiveGuest),
    map(({ ids }) => {
      ids = R.clone(typeof ids === 'string' ? [ids] : ids)
      return ids.map((id) => this.guests.unarchive(id))
    }),
    switchMap((list) =>
      forkJoin(list).pipe(
        tap(() => this.toast.success('Guest(s) unarchived successfully!')),
        switchMap((guests) => guests.map((g: any) => UnarchiveGuestComplete({ update: { id: g.id, changes: g } }))),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // NOTES
  @Effect()
  addGuestNote$ = this.actions$.pipe(
    ofType(AddGuestNote),
    switchMap(({ guestId, note }) =>
      this.guests.createNote(guestId, note).pipe(
        switchMap((note) => [GetGuest({ id: guestId }), AddGuestNoteComplete({ guestId, data: note })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGuestNote$ = this.actions$.pipe(
    ofType(UpdateGuestNote),
    switchMap(({ guestId, data }) =>
      this.guests.updateNote(guestId, data).pipe(
        switchMap((note) => [GetGuest({ id: guestId }), UpdateGuestNoteComplete({ guestId, data: note })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteGuestNote$ = this.actions$.pipe(
    ofType(DeleteGuestNote),
    switchMap(({ guest, data }) =>
      this.guests.deleteNote(guest.id, data).pipe(
        map((note) => {
          const notes = R.reject((n: GuestNote) => n.key === note.key, guest.notes)
          return {
            guestId: guest.id,
            notes,
          }
        }),
        map((payload) =>
          DeleteGuestNoteComplete({ update: { id: payload.guestId, changes: { notes: payload.notes } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getGuestEmails$ = this.actions$.pipe(
    ofType(GetGuestEmails),
    switchMap(({ guest }) =>
      this.guests.getEmails(guest.id).pipe(
        map((emails) => GetGuestEmailsComplete({ update: { id: guest.id, changes: { emailMessages: emails || [] } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getGuestBookings$ = this.actions$.pipe(
    ofType(GetGuestBookings),
    switchMap(({ guest }) =>
      this.guests.getBookings(guest.id).pipe(
        map((bookings) => GetGuestBookingsComplete({ update: { id: guest.id, changes: { bookingsInfo: bookings } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // @todo - this removes booking from a guest but only locally, it may be a good idea to call update guest to remove booking
  //         although it doesn't affect the app as after refresh, booking won't be associated with it as it doesn't exist
  @Effect()
  removeGuestBookings$ = this.actions$.pipe(
    ofType(DeleteInquiryComplete),
    switchMap(({ id }) =>
      this.store.pipe(
        selectOnce(selectAllGuests),
        map((guests) => {
          let guestsWithBooking = R.filter((g: Guest) => {
            return (
              R.contains(id, g.bookings || []) ||
              !R.isNil(R.find((b: GuestBooking) => b.id === id, g.bookingsInfo || []))
            )
          }, guests || [])
          let guestsWithRemovedBooking = R.map((g: Guest) => {
            return {
              ...g,
              bookings: R.reject((bid) => bid === id, g.bookings || []),
              bookingsInfo: R.reject((b) => b.id === id, g.bookingsInfo || []),
            }
          }, guestsWithBooking || [])
          return R.map((g) => new Guest(g), guestsWithRemovedBooking || [])
        }),
        map((guests) => R.map((g: Guest) => ({ id: g.id, changes: g }), guests)),
        map((updates) => UpdateGuestsComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addGuestInteraction$ = this.actions$.pipe(
    ofType(AddGuestInteraction),
    switchMap(({ guestId, data }) =>
      this.guests.createInteraction(guestId, data).pipe(
        switchMap((interaction) => [
          GetGuest({ id: guestId }),
          AddGuestInteractionComplete({ guestId, data: interaction }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateGuestInteractions$ = this.actions$.pipe(
    ofType(UpdateGuestInteractions),
    switchMap(({ guestId, data, remove }) =>
      this.guests.editInteractions(guestId, data, remove).pipe(
        switchMap((guest) => [
          UpdateGuestComplete({ update: { id: guest.id, changes: guest } }),
          UpdateGuestInteractionsComplete({ guestId: guestId, data: guest }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMessage$ = this.actions$.pipe(
    ofType(DeleteMessageComplete),
    switchMap(({ message, inquiryId }) =>
      of(true).pipe(
        switchMap(() => this.store.pipe(selectSomeOnce(selectGuestById, { id: message.guestId }))),
        map((guest) => {
          const updatedGuest = new Guest(guest)
          updatedGuest.emailMessages = R.filter((m: Message) => message.id !== m.id, updatedGuest.emailMessages || [])

          return { id: message.guestId, changes: { emailMessages: updatedGuest.emailMessages } }
        }),
        map((update) => DeleteGuestMessageComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toast: Toaster,
    private store: Store<fromRoot.State>,
    private searchService: SearchService,
    private inquiryService: InquiryService,
    private guests: GuestService,
    private amplitudeService: AmplitudeService
  ) {}
}
