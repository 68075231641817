import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  ChangeSharedContentPassword,
  LoadSharedContent,
  LoadSharedContentComplete,
  RemoveSharedContent,
  RemoveSharedContentComplete,
  RemoveSharedContents,
  RemoveSharedContentsComplete,
  UpdateSharedContent,
  UpdateSharedContentComplete,
} from '@tv3/store/shared-content/shared-content.actions'
import { SharedContentService } from '@tv3/store/shared-content/shared-content.service'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class SharedContentEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadSharedContent),
    switchMap(() =>
      this.sharedContent.all().pipe(
        map((contents) => LoadSharedContentComplete({ contents })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType(ChangeSharedContentPassword),
    switchMap(({ id, password }) =>
      this.sharedContent.changePassword(id, password).pipe(
        tap(() => this.toast.success('Password updated successfully')),
        map((item) => UpdateSharedContentComplete({ id, item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateSharedContent),
    switchMap(({ id, changes, successMessage }) =>
      this.sharedContent.update(id, changes).pipe(
        tap(() => successMessage && this.toast.success(successMessage)),
        map((item) => UpdateSharedContentComplete({ id, item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  remove$ = this.actions$.pipe(
    ofType(RemoveSharedContent),
    switchMap(({ id }) =>
      this.sharedContent.remove(id).pipe(
        tap(() => this.toast.success('Shared content deleted successfully')),
        map(() => RemoveSharedContentComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMultiple$ = this.actions$.pipe(
    ofType(RemoveSharedContents),
    switchMap(({ ids }) =>
      this.sharedContent.removeMultiple(ids).pipe(
        tap(() => this.toast.success('Shared contents deleted successfully')),
        map(() => RemoveSharedContentsComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private sharedContent: SharedContentService,
    private toast: Toaster
  ) {}
}
