import { Guest } from './guest.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Pagination } from '@tv3/interfaces/table/pagination'

export interface State extends EntityState<Guest> {
  isLoaded: boolean
  pagination: Pagination
  isLoadingGuestBookings: boolean
}

export const adapter: EntityAdapter<Guest> = createEntityAdapter<Guest>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isLoadingGuestBookings: false,
  pagination: {
    limit: 1000,
    skip: 0,
    hasMore: true,
  },
})
