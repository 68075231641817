import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { reducer } from './reducer'
import { MessagingChannelsService } from './service'
import { MessagingChannelEffects } from './efffects'
import { MessagingChannelGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([MessagingChannelEffects]),
    StoreModule.forFeature('messaging-channel', reducer),
  ],
  providers: [MessagingChannelsService, MessagingChannelGuard],
  declarations: [],
})
export class MessagingChannelStoreModule {}
