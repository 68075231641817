import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AddCreditCardRequest, Card } from './card.model'

@Injectable()
export class CardsService {
  constructor(private http: HttpClient) {}

  creditCards(): Observable<Card[]> {
    return this.http.get<Card[]>(`@api/subscribe/cards/`)
  }

  addCCard(payload: AddCreditCardRequest): Observable<Card> {
    return this.http.post<Card>(`@api/subscribe/add/cc`, payload)
  }

  deleteCCard(id: string) {
    return this.http.delete(`@api/subscribe/delete/cc/${id}`)
  }
}
