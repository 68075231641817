import { Component, Input } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import * as moment from 'moment'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-booking-schedule-dates',
  templateUrl: './booking-schedule-dates.component.html',
  styleUrls: ['./booking-schedule-dates.component.scss'],
})
export class BookingScheduleDatesComponent {
  private _inquiry: Inquiry

  isBooked = false
  isCheckedIn = false
  isCheckedOut = false

  checkInout$: Observable<{ checkIn: moment.Moment; checkOut: moment.Moment; timezone: string }>

  @Input() set inquiry(inquiry: Inquiry) {
    this._inquiry = inquiry
    if (!inquiry) {
      return
    }
    this.isBooked = !!inquiry.booked
    this.isCheckedIn = moment.utc().isSameOrAfter(inquiry.guestArrive * 1000)
    this.isCheckedOut = moment.utc().isSameOrAfter(inquiry.guestDepart * 1000)
    this.checkInout$ = this.inquiryService.prepareCheckInOut(inquiry)
  }

  get inquiry() {
    return this._inquiry
  }

  constructor(private inquiryService: InquiryService) {}
}
