import { Injectable } from '@angular/core'
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload'
import { AuthService } from '@tv3/services/auth.service'
import { AppConfig } from '@tv3/app.config'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { forEach, isFunction, debounce } from 'lodash'

export interface UploaderEventFns {
  onAfterAddingAll?: (fileItems: any) => any
  onBuildItemForm?: (fileItem: FileItem, form: any) => any
  onAfterAddingFile?: (fileItem: FileItem) => any
  onWhenAddingFileFailed?: (item: FileLikeObject, filter: any, options: any) => any
  onBeforeUploadItem?: (fileItem: FileItem) => any
  onProgressItem?: (fileItem: FileItem, progress: any) => any
  onProgressAll?: (progress: any) => any
  onSuccessItem?: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any
  onErrorItem?: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any
  onCancelItem?: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any
  onCompleteItem?: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => any
  onCompleteAll?: () => any
}

export interface CustomFileUploaderOptions extends FileUploaderOptions {
  allowedFileExtensions?: string[]
  disallowedFileExtensions?: string[]
}

@Injectable({
  providedIn: 'root',
})
export class FileUploaderFactoryService {
  defaultDisallowedFileExtensions = ['.exe', '.pl', '.sh', '.msi', '.bat', '.cgi']
  private showWarning = debounce((message) => this.toaster.warning(message), 100)

  constructor(private auth: AuthService, private appConfig: AppConfig, private toaster: Toaster) {}

  create(options: CustomFileUploaderOptions, eventFns?: UploaderEventFns): FileUploader {
    options.filters = options.filters || []

    const disallowedFileExtensions = options.disallowedFileExtensions || this.defaultDisallowedFileExtensions
    if (disallowedFileExtensions && disallowedFileExtensions.length) {
      const filterFn = this.getDisallowedFileExtensionsFilter(disallowedFileExtensions)
      options.filters.push({
        name: 'disallowedExtension',
        fn: (...args) => {
          return filterFn(...args)
        },
      })
    }

    const allowedFileExtensions = options.allowedFileExtensions
    if (allowedFileExtensions && allowedFileExtensions.length) {
      const filterFn = this.getAllowedFileExtensionsFilter(allowedFileExtensions)
      options.filters.push({
        name: 'allowedExtension',
        fn: (...args) => {
          return filterFn(...args)
        },
      })
    }

    const uploader = new FileUploader({
      url: '',
      ...options,
    })
    uploader.onWhenAddingFileFailed = (...args) => this.onWhenAddingFileFailed(...args)

    // set event callback functions
    forEach(eventFns, (fn, key) => {
      if (isFunction(fn)) {
        uploader[key] = fn
      }
    })

    return uploader
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    let message = ''
    switch (filter.name) {
      case 'mimeType':
        message = 'Invalid file type'
        break
      case 'queueLimit':
        message = `You can only upload ${options.queueLimit} file(s)`
        break
      case 'fileSize':
        message = 'File size exceeds limit.'
        break
      case 'allowedExtension':
      case 'disallowedExtension':
        message = `This file type is not allowed: ${item.name}`
        break
      default:
        message = 'Invalid file'
    }
    this.showWarning(message)
  }

  getAllowedFileExtensionsFilter(allowedFileExtensions: string[]) {
    return (item: FileLikeObject, options: FileUploaderOptions): boolean => {
      const [, fileExtension] = /(\.[a-z]+)$/i.exec(item.name)
      return allowedFileExtensions.indexOf(fileExtension.toLowerCase()) >= 0
    }
  }

  getDisallowedFileExtensionsFilter(disallowedFileExtensions: string[]) {
    return (item: FileLikeObject, options: FileUploaderOptions): boolean => {
      const [, fileExtension] = /(\.[a-z]+)$/i.exec(item.name)
      return disallowedFileExtensions.indexOf(fileExtension.toLowerCase()) < 0
    }
  }
}
