<div
  dropdown
  placement="bottom right"
  container="body"
  [matTooltipDisabled]="asButton"
  [matTooltip]="sslStatus?.label"
  [isDisabled]="1 | isReadonlyRole"
>
  <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
    <ng-container *ngIf="asButton">
      <button class="btn btn-secondary-info" [disabled]="disabled" type="button">
        <ng-template [ngTemplateOutlet]="sslIcon"></ng-template> Domain SSL
      </button>
    </ng-container>
    <ng-container *ngIf="!asButton">
      <button type="button" class="btn btn-light-info">
        <ng-template [ngTemplateOutlet]="sslIcon"></ng-template>
      </button>
    </ng-container>
  </div>

  <ng-template #sslIcon>
    <i *ngIf="sslStatus?.status === 'active'" class="fas fa-shield-check"></i>
    <i *ngIf="sslStatus?.status !== 'active'" class="far fa-shield-slash"></i>
  </ng-template>

  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <li *ngIf="sslStatus?.status === 'pending'">
      <button (click)="doRefresh(false)" type="button"><i class="far fa-sync"></i>Refresh Status</button>
    </li>
    <li *ngIf="sslStatus?.status === 'off' || sslStatus?.status === 'error'">
      <button (click)="openRequestCertificateConfirm()" type="button">
        <i class="fas fa-shield-check"></i>
        {{ sslStatus?.status === 'error' ? 'Request SSL Certificate Again' : 'Request SSL Certificate' }}
      </button>
    </li>
    <li *ngIf="sslStatus?.status === 'active'">
      <button (click)="openSslCertificateDialog()" type="button"><i class="fal fa-eye"></i>View Certificate</button>
    </li>
    <li *ngIf="sslStatus?.status !== 'pending'">
      <button (click)="openSslCertificateDialog()" type="button">
        <i class="fal fa-user-shield"></i>Custom SSL Certification
      </button>
    </li>
    <li *ngIf="sslStatus?.status === 'active' || sslStatus?.status === 'custom'">
      <button (click)="openDeleteCertificateConfirm()" type="button"><i class="far fa-times"></i>Turn Off SSL</button>
    </li>
  </ul>
</div>
