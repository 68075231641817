import { adapter, initialState, State } from './rule.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddInvoiceRule,
  AddInvoiceRuleComplete,
  AddInvoiceRuleConditionComplete,
  AddInvoiceRulesComplete,
  ArchiveInvoiceRuleComplete,
  DeleteInvoiceRuleComplete,
  DeleteInvoiceRuleConditionComplete,
  DeleteInvoiceRulesComplete,
  LoadInvoiceRulesComplete,
  PauseInvoiceRuleComplete,
  PauseInvoiceRuleReminderComplete,
  ResumeInvoiceRuleComplete,
  ResumeInvoiceRuleReminderComplete,
  SetInvoiceRuleConditionsComplete,
  UnArchiveInvoiceRuleComplete,
  UpdateInvoiceRule,
  UpdateInvoiceRuleComplete,
  UpdateInvoiceRuleConditionComplete,
} from './rule.actions'
import { RuleCondition } from './rule.model'
import * as R from 'ramda'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadInvoiceRulesComplete, (state, { rules }) => adapter.addAll(rules, { ...state, isLoaded: true })),
  on(AddInvoiceRule, (state) => ({ ...state, isAdding: true })),
  on(AddInvoiceRuleComplete, (state, { rule }) => adapter.addOne(rule, { ...state, isAdding: false })),
  on(AddInvoiceRulesComplete, (state, { rules }) => adapter.addMany(rules, state)),
  on(UpdateInvoiceRule, (state) => ({ ...state, isUpdating: true })),
  on(UpdateInvoiceRuleComplete, (state, { updates }) => adapter.updateMany(updates, { ...state, isUpdating: false })),
  on(DeleteInvoiceRulesComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(DeleteInvoiceRuleComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(PauseInvoiceRuleComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(ResumeInvoiceRuleComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(ArchiveInvoiceRuleComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(UnArchiveInvoiceRuleComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(PauseInvoiceRuleReminderComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(ResumeInvoiceRuleReminderComplete, (state, { update }) => adapter.updateMany(update, state)),
  on(SetInvoiceRuleConditionsComplete, (state, { items }) => adapter.addMany(items, state)),
  on(UpdateInvoiceRuleConditionComplete, (state, { response }) => {
    const conditionsWithoutCurrent = R.filter(
      (k) => k.key !== response.condition.key,
      state.entities[response.ruleId].conditions
    )

    return adapter.updateOne(
      {
        id: response.ruleId,
        changes: {
          conditions: <any>R.append(response.condition, <RuleCondition[]>conditionsWithoutCurrent),
        },
      },
      state
    )
  }),
  on(AddInvoiceRuleConditionComplete, (state, { response }) =>
    adapter.updateOne(
      {
        id: response.ruleId,
        changes: {
          conditions: <any>R.append(response.condition, <RuleCondition[]>state.entities[response.ruleId].conditions),
        },
      },
      state
    )
  ),
  on(DeleteInvoiceRuleConditionComplete, (state, { response }) =>
    adapter.updateOne(
      {
        id: response.ruleId,
        changes: {
          conditions: <any>R.filter((k) => k.key !== response.conditionId, state.entities[response.ruleId].conditions),
        },
      },
      state
    )
  ),
  on(ActionFailed, (state) => ({
    ...state,
    isAdding: false,
    isUpdating: false,
  }))
)

export function invoiceRuleReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
