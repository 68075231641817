import { NgModule } from '@angular/core'
import { NavbarComponent } from '@tv3/layout/navbar/navbar.component'
import { SearchComponent } from '@tv3/layout/navbar/search/search.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { NotificationsComponent } from '@tv3/layout/navbar/notifications/notifications.component'
import { MessagesIconComponent } from './navbar/messages-icon/messages-icon.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'
import { AiResponderModule } from '@tv3/containers/ai-responder/ai-responder.module'
import { Sidebar2Component } from './sidebar2/sidebar.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CalendarSharedModule } from '@tv3/containers/calendar/shared/calendar-shared.module'
import { RentalsSharedModule } from '@tv3/containers/rentals/rentals-shared.module'
import { InquiriesSharedModule } from '@tv3/containers/inquiries/shared/inquiries-shared.module'
import { ExpenseSharedModule } from '@tv3/containers/expenses/shared/expense-shared.module'
import { GuestsSharedModule } from '@tv3/containers/guests/guests-shared.module'
import { TasksSharedModule } from '@tv3/containers/tasks/tasks-shared.module'
import { ChannelSharedModule } from '@tv3/containers/channels/shared/channel-shared.module'
import { SettingsSharedModule } from '@tv3/containers/settings/settings-shared.module'
import { BillingLibModule } from '@tokeet-frontend/billing'
import { MessagingSettingsModule } from '@tv3/containers/messaging-settings/messaging-settings.module'

const pipes = []

const dialogs = []

const declarations = [
  ...pipes,
  ...dialogs,
  NavbarComponent,
  SearchComponent,
  NotificationsComponent,
  MessagesIconComponent,
  Sidebar2Component,
]

const modules = [
  SharedModule,
  OverlayModule,
  RatesSharedModule,
  AiResponderModule,
  RentalsSharedModule,
  InquiriesSharedModule,
  CalendarSharedModule,
  ExpenseSharedModule,
  GuestsSharedModule,
  TasksSharedModule,
  ChannelSharedModule,
  SettingsSharedModule,
  BillingLibModule,
  MessagingSettingsModule,
]

@NgModule({
  imports: [...modules],
  declarations,
  exports: [...declarations, ...modules],
  providers: [...pipes],
})
export class LayoutModule {}
