import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { DialogService } from '../../services'
import { HelpVideoDialogComponent } from './help-video-dialog.component'
import { RelatedVideosOverlayComponent } from './related-videos-overlay.component'
import { VideoItem } from './video-player'

@Injectable()
export class HelpVideoDialogService {
  constructor(private dialog: MatDialog, private dialogService: DialogService) {}

  public open(videoId: string) {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: {
        videoId,
        openRelatedVideos: this.openRelatedVideos.bind(this),
      },
    }

    return this.dialog.open(HelpVideoDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }

  public openRelatedVideos(relatedVideos: VideoItem[]) {
    this.dialogService.openDrawer(RelatedVideosOverlayComponent, {
      panelClass: 'drawer-related-videos',
      data: {
        videos: relatedVideos,
        openVideo: this.open.bind(this),
      },
    })
  }
}
