import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { SharedContent } from '@tv3/store/shared-content/shared-content.model'

export interface State extends EntityState<SharedContent> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<SharedContent> = createEntityAdapter<SharedContent>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
