import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AddTagsDialogComponent } from '@tv3/containers/inquiries/dialogs/scoped/add-tags/add-tags-dialog.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { ImportInquiriesDialogComponent } from '@tv3/containers/inquiries/dialogs/scoped/import/import-inquiries-dialog.component'
import { ExportInquiriesReportDialogComponent } from '@tv3/containers/inquiries/dialogs/scoped/export-report/export-inquiries-report-dialog.component'
import * as fromRoot from '@tv3/store/state'
import { Store } from '@ngrx/store'
import { selectAllGuests } from '@tv3/store/guest/guest.selectors'
import { selectSomeOnce } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class InquiryDialogsService {
  constructor(private dialog: MatDialog, private store: Store<fromRoot.State>) {}

  openAddTags(inquiries: Inquiry[]) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { inquiries },
    }

    return this.dialog.open(AddTagsDialogComponent, defaultConfig)
  }

  openImport() {
    this.store.pipe(selectSomeOnce(selectAllGuests)).subscribe((guests) => {
      const defaultConfig: MatDialogConfig = {
        width: '950px',
        height: 'auto',
        maxHeight: '95vh',
        panelClass: ['variable-modal'],
        data: {
          guests,
        },
      }
      this.dialog.open(ImportInquiriesDialogComponent, defaultConfig)
    })
  }

  openExport(inquiries: Inquiry[]) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: {
        inquiries,
      },
    }

    return this.dialog.open(ExportInquiriesReportDialogComponent, defaultConfig)
  }
}
