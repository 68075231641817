import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-booking-formulas-overlay',
  templateUrl: './booking-formulas-overlay.component.html',
  styleUrls: ['./booking-formulas-overlay.component.scss'],
})
export class BookingFormulasOverlayComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { channel: { id: string; name: string } },
    public dialogRef: MatDialogRef<BookingFormulasOverlayComponent>
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }
}
