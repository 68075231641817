import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Channel, ChannelNameTokens, ChannelService } from '@tokeet-frontend/tv3-platform'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { CtripChannelService, TiketChannelService } from '@tokeet-frontend/channels'
import { map, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import * as lodash from 'lodash'

export interface SelectChannelAccountDialogConfig {
  title?: string
  placeholder?: string
  help?: string
  buttonIcon?: string
  buttonText?: string
  channel: Channel
  entityName?: string
}

@Component({
  selector: 'app-select-channel-account-dialog',
  host: { class: 'modal-content' },
  templateUrl: './select-channel-account-dialog.component.html',
  styleUrls: ['./select-channel-account-dialog.component.scss'],
})
export class SelectChannelAccountDialogComponent implements OnInit {
  config = {
    title: 'Select Accommodation ID',
    placeholder: 'Select Accommodation ID',
    help: 'Please select one accommodation ID you want to disconnect from above list.',
    buttonText: 'Select',
    entityName: 'Accommodation ID',
  } as SelectChannelAccountDialogConfig

  form = this.fb.group({
    account: [undefined, [Validators.required]],
  })

  accounts$ = of(this.data.config.channel).pipe(
    switchMap((channel) => {
      if (channel.name === ChannelNameTokens.Tiket) {
        return this.tiket.getStatus().pipe(map(({ hotelIds }) => lodash.map(hotelIds, (id) => ({ propertyId: id }))))
      } else if (channel.name === ChannelNameTokens.Trip) {
        return this.ctrip.getStatus().pipe(map(({ hotelIds }) => lodash.map(hotelIds, (id) => ({ propertyId: id }))))
      } else {
        return this.channelService.getConnectedAccounts(channel.id)
      }
    })
  )

  constructor(
    public dialogRef: MatDialogRef<SelectChannelAccountDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private channelService: ChannelService,
    private tiket: TiketChannelService,
    private ctrip: CtripChannelService,
    @Inject(MAT_DIALOG_DATA) public data: { config: SelectChannelAccountDialogConfig }
  ) {}

  ngOnInit() {
    this.config = { ...this.config, ...this.data.config }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = this.form.getRawValue()
    this.dialogRef.close(formValues)
  }
}
