import { DataEntityType } from '@tokeet-frontend/tv3-platform'

export enum AttributeFieldType {
  Text = 'string',
  Number = 'number',
  PositiveNumber = 'positive_number',
  Date = 'date',
  Boolean = 'boolean',
}

export const attributeFieldTypes = [
  AttributeFieldType.Text,
  AttributeFieldType.Number,
  AttributeFieldType.PositiveNumber,
  AttributeFieldType.Date,
  AttributeFieldType.Boolean,
]

export interface AttributeFieldDef {
  key: string
  touch: 1717084251
  field_type: AttributeFieldType
  description: string //  "date when party is allowed",
  label: string
  attribute: string // "party_allowed"
}

export interface AttributeDescriptionResponse {
  created: number
  attribute_fields: AttributeFieldDef[]
  pkey: string
  entity_type: DataEntityType
  account: number
  archived: number
}

export interface CreateAttributeDescriptionPayload {
  attribute: string // "party_allowed",
  label: string // "Party allowed",
  field_type: AttributeFieldType // "boolean",
  description: string // "Party allowed at the listing"
}
