import * as R from 'ramda'
import * as lodash from 'lodash'
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core'
import {
  ConfirmDialogService,
  Destroyable,
  isUpdatingOwners,
  Rental,
  selectAllRentals,
  selectSome,
  toggleOwnerRentals,
  untilDestroy,
  updateOwners,
} from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { Website } from '@tv3/store/website/website.model'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FormBuilder } from '@angular/forms'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'

class SelectableRental extends Rental {
  selected: boolean
}

@Component({
  selector: 'app-owner-rentals',
  templateUrl: './owner-rentals.component.html',
  styleUrls: ['./owner-rentals.component.scss'],
})
export class OwnerRentalsComponent extends Destroyable implements OnInit, OnChanges {
  @Input() ownerId

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  @Input() website: Website
  @Input() searchTerm: string

  dataSource = new MatTableDataSource<SelectableRental>()
  rentals: SelectableRental[] = []

  displayedColumns = ['select', 'name', 'description']

  tableType = TableType.WebsiteRentals
  isEmptyTable$ = isEmptyTable(this.dataSource)

  isUpdating$ = this.store.pipe(select(isUpdatingOwners))

  get isAllSelected() {
    return this.rentals.length && lodash.every(this.rentals, { selected: true })
  }

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    protected storage: UserStorage,
    private confirmDialogService: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.paginator.firstPage()

    this.store.pipe(selectSome(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = (rentals || []).map(
        (rental) =>
          ({
            ...rental,
            selected: (rental.owners || []).includes(this.ownerId),
          } as SelectableRental)
      )
      this.dataSource.data = R.sortBy((r: SelectableRental) => !r.selected, this.rentals)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const searchTerm: SimpleChange = R.path(['searchTerm'], changes)
    if (searchTerm) {
      this.dataSource.filter = searchTerm.currentValue
      if (searchTerm.currentValue !== searchTerm.previousValue) {
        this.paginator.firstPage()
      }
    }
  }

  onToggle(rental: SelectableRental, selected) {
    rental.selected = selected
    if (selected) {
      this.store.dispatch(
        updateOwners({
          rental: rental,
          addOwnerIds: [this.ownerId],
          removeOwnerIds: [],
          currentOwnerIds: [...(rental.owners || []), this.ownerId],
        })
      )
    } else {
      this.store.dispatch(
        updateOwners({
          rental: rental,
          addOwnerIds: [],
          removeOwnerIds: [this.ownerId],
          currentOwnerIds: (rental.owners || []).filter((ownerId) => ownerId !== this.ownerId),
        })
      )
    }
  }

  onToggleAll(selected) {
    this.confirmDialogService
      .confirm({
        title: selected ? 'Assign all rentals?' : 'Unassign all rentals?',
        body: 'This option changes every rental in your current filter and not just the visible rentals. Would you like to continue?',
      })
      .subscribe(() => {
        this.store.dispatch(
          toggleOwnerRentals({
            ownerId: this.ownerId,
            rentals: this.rentals,
            selected,
          })
        )
      })
  }

  onOpenRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
