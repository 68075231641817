import { createSelector, select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { State } from '../channels.state'
import { combineLatest } from 'rxjs'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { channelsState } from '../channels.selectors'
import { HvmiListingModel, HvmiListingViewModel } from './model'

export const selectHvmiStatus = createSelector(channelsState, (state) => state.hvmi.status)
export const selectHvmiConnected = createSelector(channelsState, (state) => state.hvmi.status?.connected)
export const selectHvmiListings = createSelector(channelsState, (state) => state.hvmi.listings || [])
export const selectHmcIds = createSelector(channelsState, (state) => state.hvmi.status.hmcIds)
export const selectHvmiRentalValidations = createSelector(channelsState, (state) => state.hvmi.validations)
export const selectHvmiValidationsLoaded = createSelector(channelsState, (state) => state.hvmi.validationsLoaded)
export const getHvmiListings = (store: Store<State>) =>
  combineLatest([store.pipe(select(selectHvmiListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      return R.map((listing): HvmiListingModel => {
        const rental = R.find((r: Rental) => r.id === listing.propertyId, rentals)
        return {
          ...listing,
          rental,
          rental_name: rental?.name,
        } as HvmiListingViewModel
      }, listings) as HvmiListingViewModel[]
    })
  )
