import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, concat, EMPTY, forkJoin, from, Observable, of } from 'rxjs'
import { catchError, concatMap, flatMap, last, map, mergeMap, take, tap, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import * as lodash from 'lodash'
import * as momentNs from 'moment-timezone'
import { Account } from '../account/account.models'
import {
  EditRentalImageRequest,
  RentalCustomForm,
  RentalDetailInfoForm,
  RentalDetailsRequest,
  RentalInstructionsForm,
  RentalInstructionsRequest,
  RentalRequest,
  RentalResponse,
} from './rental.interfaces'
import { Toaster } from '../../services/toaster.service'
import {
  BaseRate,
  Rental,
  RentalBookedDayTypes,
  RentalChannelAmenityAvailableItem,
  RentalCheckTime,
  RentalsImageCount,
  RentalStatus,
  TaxV3,
  TokeetImage,
  TokeetImagePayload,
} from './rental.models'
import { itemsInTimeRange, toMoment } from '../../functions'
import { Rate } from '../rate/rate.model'
import { deserializeArray } from '../../functions/deserialize-array'
import { toastError } from '../../rx-operators/toast-error'
import { PaymentScheduleType, RentalPaymentSchedule } from './schedule.model'
import { TimePickerUtils } from '../../components/time-picker'

const moment = momentNs

@Injectable()
export class RentalService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  static getTimeZone({ checkin, checkout, address }: Rental, account?: Account) {
    // TV3-1313: if no rental level checkin/out exists we should use the account level before falling back to the default.

    return (
      (checkin && checkin.timezone) ||
      (checkout && checkout.timezone) ||
      R.path(['attributes', 'checkin', 'timezone'], account) ||
      R.path(['attributes', 'checkout', 'timezone'], account) ||
      (address && address.timezone)
    )
  }

  static getCheckInTime(rental: Rental, checkInTime?: number, account?: Account): momentNs.Moment {
    // TV3-1313: if no rental level checkin/out exists we should use the account level before falling back to the default.

    const addressTimezone = R.pathOr(undefined, ['address', 'timezone'], rental)
    const rentalCheckIn: RentalCheckTime =
      rental.checkin || (account && account.attributes && account.attributes.checkin)

    const checkinTimezone = (rentalCheckIn && rentalCheckIn.timezone) || addressTimezone

    let checkin
    if (checkInTime && checkinTimezone) {
      checkin = moment.tz(checkInTime * 1000, checkinTimezone)
    } else if (rentalCheckIn && !R.isNil(rentalCheckIn.hour)) {
      checkin = moment(rentalCheckIn.hour + ':' + (rentalCheckIn.min || 0), 'H:m')
    } else {
      checkin = moment('10:0', 'H:m')
    }

    return checkin
  }

  static getCheckOutTime(rental: Rental, checkOutTime?: number, account?: Account): momentNs.Moment {
    // TV3-1313: if no rental level checkin/out exists we should use the account level before falling back to the default.

    const addressTimezone = R.pathOr(undefined, ['address', 'timezone'], rental)
    const rentalCheckout: RentalCheckTime =
      rental.checkout || (account && account.attributes && account.attributes.checkout)

    const checkoutTimezone = (rentalCheckout && rentalCheckout.timezone) || addressTimezone

    let checkout
    if (checkOutTime && checkoutTimezone) {
      checkout = moment.tz(checkOutTime * 1000, checkoutTimezone)
    } else if (rentalCheckout && !R.isNil(rentalCheckout.hour)) {
      checkout = moment(rentalCheckout.hour + ':' + (rentalCheckout.min || 0), 'H:m')
    } else {
      checkout = moment('9:0', 'H:m')
    }

    return checkout
  }

  all(less?: boolean): Observable<Rental[]> {
    const url = `@api/rental/all/${less === false ? '' : 'norates/'}?limit=2500`

    return this.http.get<RentalResponse[]>(url).pipe(deserializeArray<Rental>(Rental))
  }

  allNoRates(): Observable<Rental[]> {
    const url = '@api/rental/all/norates/?limit=2500'

    return this.http.get<RentalResponse[]>(url).pipe(deserializeArray<Rental>(Rental))
  }

  getOnlyRates() {
    const url = '@api/rental/all/onlyrates/?limit=2500'
    return this.http.get<RentalResponse[]>(url).pipe(deserializeArray<Rental>(Rental))
  }

  get(id: string): Observable<Rental> {
    const url = `@api/rental/${id}`

    return this.http.get(url).pipe(
      take(1),
      map((response) => Rental.deserialize(response)),
      toastError(this.toast) as any
    )
  }

  getChannelAmenities() {
    const url = `@api/rental/amenities/all`

    return this.http.get<RentalChannelAmenityAvailableItem[]>(url)
  }

  loadRentalsImageCounts(rentals: string[]) {
    const url = `@api/rental/images/count`

    return this.http.post<RentalsImageCount[]>(url, { rentals })
  }

  add(payload: RentalRequest): Observable<Rental> {
    const url = '@api/rental/'

    return this.http.post<RentalResponse>(url, payload).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental created successfully.'))
    )
  }

  update(rentalId: string, payload: RentalRequest): Observable<Rental> {
    const url = `@api/rental/update/${rentalId}`

    return this.http.put<RentalResponse>(url, payload).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental updated successfully.'))
    )
  }

  updateStatus(rentalId: string, status: RentalStatus): Observable<Rental> {
    const url = `@api/rental/update/${rentalId}`

    return this.http.put<RentalResponse>(url, { status }).pipe(map((response) => Rental.deserialize(response)))
  }

  archiveRental(id: string): Observable<Rental> {
    const url = `@api/rental/archive/${id}`

    return this.http.put<Rental>(url, {}).pipe(
      map((response) => ({ ...response, deleted: 1 })),
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental archived successfully.')),
      catchError((error) => {
        this.toast.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  unArchiveRental(id: string): Observable<Rental> {
    const url = `@api/rental/unarchive/${id}`

    return this.http.put<Rental>(url, {}).pipe(
      map((response) => ({ ...response, deleted: 0 })),
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental unarchived successfully.')),
      catchError((error) => {
        this.toast.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  getArchivedRentals(): Observable<Rental[]> {
    const url = `@api/rental/all/deleted`

    return this.http.get<RentalResponse[]>(url).pipe(
      map((rentals) => R.map((r) => ({ ...r, deleted: 1 }), rentals)),
      deserializeArray<Rental>(Rental)
    )
  }

  updateGPS(rentalId: string, payload: { gps: any }): Observable<Rental> {
    const url = `@api/rental/update/${rentalId}`

    return this.http.put<RentalResponse>(url, payload).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental GPS location saved successfully.'))
    )
  }

  updateTaxes(rentalId: string, taxes: TaxV3[], silent = false): Observable<Rental> {
    const url = `@api/rental/update/${rentalId}`

    return this.http.put<RentalResponse>(url, { taxes }).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => {
        if (!silent) {
          this.toast.success('Rental taxes updated successfully.')
        }
      })
    )
  }

  updatePaymentSchedule(payload: RentalPaymentSchedule, rentalId: string, silent = false): Observable<Rental> {
    const url = `@api/rental/update/${rentalId}`

    // TV3-4455
    if (!payload[PaymentScheduleType.AT_BOOKING]) {
      payload[PaymentScheduleType.AT_BOOKING] = {
        percent: 0,
      }
    }

    return this.http.put<RentalResponse>(url, { payment_schedule: payload }).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => {
        if (!silent) {
          this.toast.success('Rental payment schedule updated successfully.')
        }
      })
    )
  }

  images(rentalId: string): Observable<TokeetImage[]> {
    const url = `@api/image/rental/${rentalId}`

    return this.http.get<object[]>(url).pipe(deserializeArray<TokeetImage>(TokeetImage))
  }

  getPrimaryImages(rentalIds?: string[]): Observable<TokeetImage[]> {
    const url = `@api/image/all/primary/`

    return this.http.put<{ [key: string]: object }>(url, rentalIds ? { rental_ids: rentalIds } : {}).pipe(
      map((res) => Object.values(res)),
      deserializeArray<TokeetImage>(TokeetImage)
    )
  }

  updateImagesOrder(images: { [key: string]: number }): Observable<TokeetImage[]> {
    return of(R.keys(images)).pipe(
      flatMap((imageId) => from(imageId)),
      concatMap((imageId) => {
        return this.http
          .put<object[]>(`@api/image/${imageId}`, { order: images[imageId] })
          .pipe(map((i) => TokeetImage.deserialize(i)))
      }),
      toArray(),
      map((images) => R.sortBy((i) => i.order, images))
    )
  }

  addImage(
    payload: TokeetImagePayload,
    withToast = true,
    message = 'Rental image added successfully.'
  ): Observable<TokeetImage> {
    const url = `@api/image`
    return this.http.post<TokeetImage>(url, payload).pipe(
      map((response) => TokeetImage.deserialize(response)),
      tap(() => {
        if (withToast) {
          this.toast.success(message)
        }
      })
    )
  }

  editImage(imageId: string, data: EditRentalImageRequest): Observable<TokeetImage> {
    const url = `@api/image/${imageId}`

    return this.http.put<TokeetImage>(url, data).pipe(
      map((response) => TokeetImage.deserialize(response))
      // tap(() => this.toast.success('Rental image edited successfully.'))
    )
  }

  deleteImage(imageId: string, silent?: boolean): Observable<TokeetImage> {
    const url = `@api/image/delete/${imageId}`

    return this.http.delete<TokeetImage>(url).pipe(
      map((response) => TokeetImage.deserialize(response)),
      tap(() => !silent && this.toast.success('Rental image deleted successfully.'))
    )
  }

  deleteImages(imageIds: string[]): Observable<TokeetImage[]> {
    return of(...lodash.chunk(imageIds, 10)).pipe(
      concatMap((ids) => {
        return combineLatest([...lodash.map(ids, (id) => this.deleteImage(id, true))])
      }),
      toArray(),
      map((data) => lodash.flatten(data)),
      tap(() => this.toast.success('Rental images deleted successfully.'))
    )
  }

  primaryImage(rentalId: string, imageId: string): Observable<{}> {
    const url = `@api/image/primary/${rentalId}/${imageId}`

    return this.http
      .put<{}>(url, {})
      .pipe(tap(() => this.toast.success('Rental image marked as primary successfully.')))
  }

  remove(rentalId: string): Observable<string> {
    const url = `@api/rental/delete/${rentalId}`

    return this.http.delete<RentalResponse>(url).pipe(
      map((r) => r.pkey),
      tap(() => this.toast.success('Rental deleted successfully.'))
    )
  }

  getSubDomain(): Observable<string> {
    const url = '@api/user/subdomain/'

    return this.http.get<{ subdomain: string }>(url).pipe(map((r) => r.subdomain))
  }

  addOwners(rental: Rental, ownerIds: string[]) {
    return of(ownerIds).pipe(
      flatMap((ownerId) => from(ownerId)),
      concatMap((ownerId) => this.http.put(`@api/rental/owner/${rental.id}/${ownerId}`, {})),
      toArray()
    )
  }

  removeOwners(rental: Rental, ownerIds: string[]) {
    return of(ownerIds).pipe(
      flatMap((ownerId) => from(ownerId)),
      concatMap((ownerId) => this.http.delete(`@api/rental/owner/${rental.id}/${ownerId}`)),
      toArray()
    )
  }

  addRentalsToOwner(ownerId: string, rentalIds: string[]) {
    return forkJoin(rentalIds.map((rentalId) => this.http.put(`@api/rental/owner/${rentalId}/${ownerId}`, {})))
  }

  removeRentalsToOwner(ownerId: string, rentalIds: string[]) {
    return forkJoin(rentalIds.map((rentalId) => this.http.delete(`@api/rental/owner/${rentalId}/${ownerId}`)))
  }

  restrictUsers(rental: Rental, userIds: string[]) {
    return of(userIds).pipe(
      flatMap((ownerId) => from(ownerId)),
      concatMap((ownerId) => this.restrictUser(rental, ownerId)),
      toArray()
    )
  }

  unrestrictUsers(rental: Rental, userIds: string[]) {
    return of(userIds).pipe(
      flatMap((ownerId) => from(ownerId)),
      concatMap((ownerId) => this.unrestrictUser(rental, ownerId)),
      toArray()
    )
  }

  restrictUser(rental: Rental, userId: string) {
    return this.http.put(`@api/rental/restrict/${rental.id}/${userId}`, {})
  }

  restrictUserAll(rentals: Rental[], userId: string) {
    return of(rentals).pipe(
      flatMap((rental) => from(rental)),
      concatMap((rental) => this.restrictUser(rental, userId)),
      toArray()
    )
  }

  unrestrictUser(rental: Rental, userId: string) {
    return this.http.delete(`@api/rental/unrestrict/${rental.id}/${userId}`)
  }

  unRestrictUserAll(rentals: Rental[], userId: string) {
    return of(rentals).pipe(
      flatMap((rental) => from(rental)),
      concatMap((rental) => this.unrestrictUser(rental, userId)),
      toArray()
    )
  }

  addTagsToRental(rental: Rental, tags: string[]) {
    const url = `@api/rental/update/${rental.id}`

    return this.http.put<RentalResponse>(url, { tags }).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Tags updated successfully'))
    )
  }

  updateCustom(rental: Rental, custom: RentalCustomForm) {
    const url = `@api/rental/update/${rental.id}`

    return this.http.put<RentalResponse>(url, { ...custom }).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental updated successfully'))
    )
  }

  updateDetails(rental: Rental, form: RentalDetailInfoForm) {
    const url = `@api/rental/update/${rental.id}`
    const request = {
      description: form.description,
      bedrooms: form.bedrooms,
      bathrooms: form.bathrooms,
      locale: form.locale || 'en',
      sleep_min: form.sleepMin,
      sleep_max: form.sleepMax,
      size: form.size,
      size_metric: form.sizeMetric,
      type: form.type,
      detail: form.detail,
      children_allowed: form.children_allowed,
      guest_minimum_age: form.guest_minimum_age,
      events_allowed: form.events_allowed,
      smoking_allowed: form.smoking_allowed,
      pets_allowed: form.pets_allowed,
      checkin_type: form.checkin_type,
      attributes: {
        ...rental.attributes,
        ...form.attributes,
      },
    } as RentalDetailsRequest

    return this.http.put<RentalResponse>(url, request).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental updated successfully'))
    )
  }

  updateInstructions(rental: Rental, form: RentalInstructionsForm) {
    const url = `@api/rental/update/${rental.id}`

    const request: RentalInstructionsRequest = {
      checkin: this.hashTime(form.checkIn, form.timezone),
      checkout: this.hashTime(form.checkOut, form.timezone),
      instructions: {
        checkin: (form.checkInInstructions || '').replace(/(?:\r\n|\r|\n)/g, '<br/>'),
        checkout: (form.checkOutInstructions || '').replace(/(?:\r\n|\r|\n)/g, '<br/>'),
        directions: (form.directionInstructions || '').replace(/(?:\r\n|\r|\n)/g, '<br/>'),
        rules: (form.houseRules || '').replace(/(?:\r\n|\r|\n)/g, '<br/>'),
      },
      specifics: {
        wifi_name: form.wifiName,
        wifi_pass: form.wifiPassword,
        key_pickup: form.keyPickup,
        special_inst: form.specialInstructions,
        sec_code: form.securityCode,
      },
      payment_instructions: form.paymentInstructions,
      payment_terms: form.paymentTerms,
    }

    return this.http.put<RentalResponse>(url, request).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental updated successfully'))
    )
  }

  updateBaseRate(rental: Rental, form: BaseRate, ignoreBDC?: boolean) {
    const url = `@api/rental/update/${rental.id}`

    const request = {
      baserate: form || {},
    }

    if (lodash.isBoolean(ignoreBDC)) {
      request['attributes'] = {
        ...(rental.attributes || {}),
        ignore_bdc_obp: ignoreBDC,
      }
    }

    return this.http.put<RentalResponse>(url, request).pipe(
      map((response) => Rental.deserialize(response)),
      tap(() => this.toast.success('Rental base rate updated successfully'))
    )
  }

  updateAttributes(rental: Rental, attributes: object, message?: string): Observable<Rental> {
    const url = `@api/rental/update/${rental.id}`

    attributes = lodash.assign({}, rental.attributes, attributes)

    return this.http.put<RentalResponse>(url, { attributes }).pipe(map((response) => Rental.deserialize(response)))
  }

  hashTime(time: string, timezone: string): RentalCheckTime {
    if (!timezone || timezone.indexOf('/') < 0) {
      this.toast.error('Please select a timezone default in rental settings.')
      return null
    }

    return this.toCheckTime(time, timezone)
  }

  toCheckTime(time: string, timezone: string): RentalCheckTime {
    if (!time || !timezone) {
      return null
    }

    const m = TimePickerUtils.parseTime(time)
    const t = moment.tz(m, timezone)

    return {
      string: m.toISOString(),
      hour: m.hour(),
      min: m.minute(),
      offset_min: t.utcOffset(),
      timezone: timezone,
    }
  }

  getBlockedDates(rental: Rental) {
    return this.http.get<object[]>(`@api/calendar/rental/blocked/${rental.account}/${rental.id}`).pipe(
      map((items) => {
        const blockedDates: Record<string, RentalBookedDayTypes> = {}

        const dateFormat = 'YYYY-MM-DD'

        if (!lodash.isArray(items)) {
          return blockedDates
        }

        items.forEach((item) => {
          if (item['available'] && !item['closed']) {
            return
          }

          const fromDate = moment.utc(item['from'], dateFormat, true)
          const toDate = moment.utc(item['to'], dateFormat, true)

          if (!fromDate || !toDate || !fromDate.isValid() || !toDate.isValid()) {
            return
          }

          const range = itemsInTimeRange(fromDate, toDate)

          range.forEach((date, index) => {
            const start = index === 0
            const end = index === range.length - 1

            const status = index === 0 ? 'start' : index === range.length - 1 ? 'end' : 'full'

            const blockedDate = blockedDates[date]

            if (!blockedDate) {
              blockedDates[date] = status
              return
            }

            if ((status === 'start' && blockedDate === 'end') || (status === 'end' && blockedDate === 'start')) {
              blockedDates[date] = 'start-end'
            }

            if (status === 'full' && blockedDate !== 'full') {
              blockedDates[date] = 'full'
            }
          })
        })

        return blockedDates
      })
    )
  }

  getUniqueCategories(rates: Rate[]): string[] {
    return R.pipe(
      R.defaultTo([]),
      R.map((r: Rate) => (r.type === 'promotion' ? r.categories : r.category)),
      R.flatten,
      R.reject(R.isNil),
      R.uniq,
      R.map(R.when(R.is(Number), R.toString)),
      R.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
    )(rates)
  }

  getMainImage(rental: Rental) {
    const images = (rental && rental.images) || []
    return images.find((img) => !!img.primary) || images[0] || null
  }

  updateCheckTimeForAllRentals(data: { checkin: RentalCheckTime; checkout: RentalCheckTime }) {
    const url = `@api/rentals/checkinout`
    return this.http.put(url, data)
  }
}
