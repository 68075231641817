<mat-form-field2 [showLabel]="!inline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <app-select
    #select
    [formControl]="ctrl"
    [disabled]="ctrl.disabled"
    [compareWith]="compareObjects"
    [searchable]="true"
    [items]="items"
    [itemHeight]="itemHeight"
    [visibleItems]="visibleItems"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [multiple]="multiple"
    [placeholder]="placeholder"
    searchPlaceholder="Search..."
    textNoMatch="No match"
    [showMasterCheckbox]="showMasterCheckbox"
    [showCloseButton]="showCloseButton"
    [showBlankItem]="hasBlank"
    [blankLabel]="blankLabel"
    [blankValue]="blankValue"
    [required]="isRequired"
    (openedChange)="openedChange.emit($event)"
    (searchChange)="search.emit($event)"
    class="w-100 d-block"
  >
    <div
      *selectItem="let item = item; let label = label"
      class="select-search-item"
      [class.inquiry-template-option]="template === 'inquiry'"
      [matTooltip]="showTooltips ? item.tooltip : ''"
      [matTooltipPosition]="tooltipPosition"
      [title]="label"
    >
      <div class="w-100 text-truncate">
        {{ label }}
        <ng-container *ngIf="template !== 'inquiry'">&nbsp;</ng-container>
        <ng-container *ngIf="template === 'inquiry'">
          at {{ item.rentalId | rentalName | async }}<br />
          starting {{ item.guestArrive | epoch : 'DD - MMM - YYYY' }} ending
          {{ item.guestDepart | epoch : 'DD - MMM - YYYY' }}
          &nbsp;
        </ng-container>
      </div>
      <ng-container *ngIf="hasDelete">
        <span (click)="$event.stopPropagation(); delete.emit(item)" class="delete-btn">
          <i class="fas fa-trash"></i>
        </span>
      </ng-container>
    </div>

    <ng-container *ngIf="hasButton">
      <button
        type="button"
        mat-button
        *selectActionButton
        (click)="buttonAction.emit()"
        [matTooltip]="buttonTooltip"
        matTooltipPosition="left"
      >
        <i class="far fa-plus"></i> {{ buttonText }}
      </button>
    </ng-container>
  </app-select>

  <mat-error>
    <ng-content select=".error-container"></ng-content>
  </mat-error>
</mat-form-field2>
