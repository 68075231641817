<div class="info">
  <i class="fal fa-info-circle"></i>
  <p>Please enter Rental specific configuration below if you do not wish to use the channel configuration.</p>
</div>
<div *ngIf="hasNoFees$ | async" class="warning">
  <i class="fal fa-exclamation-triangle"></i>
  <p>Rental has no associated fees, please click on Add Fee button below and add rental specific fees.</p>
</div>
<app-hometogo-channel-config-form
  *ngIf="channel?.name === channelNames.HomeToGo && !!config"
  [title]="'Rental Config'"
  [channel]="channel"
  [config]="config"
  [taxesFees]="'single'"
  [primaryButtonClass]="primaryButtonClass"
  (save)="onSave($event)"
  (taxesAndFeesUpdate)="this.shouldUpdate.next(true)"
></app-hometogo-channel-config-form>
