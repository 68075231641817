import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { ConfirmDialogService, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import {
  CreateCustomChannelComplete,
  CustomChannelResponse,
  DeleteCustomChannel,
  UpdateCustomChannelComplete,
} from '@tokeet-frontend/channels'
import { CustomChannelFormComponent } from '../../channel-detail/custom/custom-channel-form/custom-channel-form.component'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-custom-channel-dialog',
  templateUrl: './custom-channel-dialog.component.html',
  styleUrls: ['./custom-channel-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class CustomChannelDialogComponent extends Destroyable implements OnInit {
  @ViewChild('form') form: CustomChannelFormComponent

  get isEdit() {
    return !!this.data.channel?.pkey
  }

  constructor(
    public dialogRef: MatDialogRef<CustomChannelDialogComponent>,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private confirm: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { channel?: CustomChannelResponse }
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions
      .pipe(ofType(CreateCustomChannelComplete, UpdateCustomChannelComplete), untilDestroy(this))
      .subscribe(() => {
        this.close()
      })
  }

  close() {
    this.dialogRef.close()
  }

  onDelete() {
    this.confirm.confirm().subscribe(() => {
      this.store.dispatch(DeleteCustomChannel({ id: this.data.channel.pkey }))
      this.close()
    })
  }

  onSave() {
    this.form.save()
  }

  onCreate() {
    this.form.create()
  }
}
