<app-entity-form-tags
  [type]="type"
  [id]="id"
  [tags]="tags"
  [label]="tagsFieldLabel"
  [autoSave]="autoSave"
  [showModifiedMsg]="showModifiedMsg"
  #field
></app-entity-form-tags>

<ng-container *ngIf="showSuggesttedTags">
  <ng-container *ngIf="tags$ | async; let tags">
    <div class="mb-2" *ngIf="tags.length">Suggested Tags</div>
    <mat-chip-list>
      <mat-chip *ngFor="let tag of tags" (click)="field.add(tag)" class="clickable">
        <button class="mat-chip-btn mr-2" type="button"><i class="far fa-plus"></i></button>
        {{ tag }}
      </mat-chip>
    </mat-chip-list>
  </ng-container>
</ng-container>
