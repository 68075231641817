import { createAction, props } from '@ngrx/store'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { Invoice } from '@tokeet-frontend/invoices'

export const OpenInvoiceOverlay = createAction(
  '[Overlay] Open Invoice',
  props<{ invoiceId: string; invoices?: Invoice[] }>()
)

export const OpenInquiryOverlay = createAction('[Overlay] Open Inquiry', props<{ inquiryId: string }>())
export const OpenGuestOverlay = createAction('[Overlay] Open Guest', props<{ guestId: string }>())
export const OpenRentalOverlay = createAction(
  '[Overlay] Open Rental',
  props<{ rental?: Rental; rentalId?: string; activeTab?: RentalDialogTab; highlightedImages?: string[] }>()
)
export const OpenInventoryOverlay = createAction('[Overlay] Open Inventory')
