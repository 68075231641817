<div class="container-box">
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort [dataSource]="dataSource" matSortActive="created" matSortDirection="desc">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.created | epoch }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef> Message</mat-header-cell>
          <mat-cell *matCellDef="let element">
            Click here to view the booking that discount code is applied to.</mat-cell
          >
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onDetails(row)" class="clickable"></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="!dataSource?.filteredData?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>

      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
    </table>
  </div>
</div>
