<div class="details-box">
  <div class="filters-box">
    <app-select-search
      [multiple]="false"
      [items]="propertyIds$ | async"
      [ctrl]="filters.get('propertyId')"
      placeholder="Account"
    ></app-select-search>
    <mat-form-field2 [showLabel]="false">
      <mat-select [formControl]="filters.get('linkage')" placeholder="Linkage">
        <mat-option value="">All</mat-option>
        <mat-option value="linked">Linked</mat-option>
        <mat-option value="unlinked">Unlinked</mat-option>
      </mat-select>
    </mat-form-field2>
    <mat-form-field2 [showLabel]="false" *ngIf="channel | isAirBnBV2">
      <mat-select
        [formControl]="filters.get('status')"
        (selectionChange)="onLinkageFilterChanged()"
        placeholder="Status"
      >
        <mat-option value="">All</mat-option>
        <mat-option value="approved">Approved</mat-option>
        <mat-option value="rejected">Rejected</mat-option>
      </mat-select>
    </mat-form-field2>
    <app-cancel-filters
      [isFiltering]="filters | isFiltering"
      (clear)="onClearFilters()"
      [searchCom]="search"
    ></app-cancel-filters>
    <div class="flex-fill"></div>
    <app-table-search (search)="onSearch($event)" #search></app-table-search>
    <div dropdown class="btn-group" *ngIf="!(1 | isReadonlyRole) && !(isEmptyTable$ | async)">
      <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle">
        <span class="cs-icon cs-icon-bolt"></span> Actions
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li *ngIf="hasChannelContentApi">
          <button
            type="button"
            (click)="onCreateListDetails()"
            matTooltip="Create a property and directly link it with the channel."
            matTooltipPosition="left"
          >
            Create Listing
          </button>
        </li>
        <li
          matTooltip="After adding a new property to an already connected channel selecting Refresh Room List will populate any new listings under mapped rentals."
          matTooltipPosition="left"
        >
          <button (click)="onRefreshRoomList()" type="button">Refresh Room List</button>
        </li>
        <li>
          <button
            *ngIf="(channel | isAirBnBV2) && (isTokeetPaidSub$ | async)"
            (click)="onCreateAListing()"
            matTooltip="Create a listing on this channel from one of your AdvanceCM rentals."
            matTooltipPosition="left"
            type="button"
          >
            Create a Listing
          </button>
        </li>
        <li>
          <button
            type="button"
            matTooltip="This will unlink all selected properties"
            matTooltipPosition="left"
            (click)="onUnlinkRentals()"
          >
            Unlink Properties
          </button>
        </li>
        <li *ngIf="channel | isAirBnBV2">
          <button
            type="button"
            matTooltip="This will import selected unlinked properties into AdvanceCM"
            matTooltipPosition="left"
            (click)="onImportProperties()"
          >
            Import Properties
          </button>
        </li>

        <li matTooltip="Push availability for selected rentals shown under mapped rentals." matTooltipPosition="left">
          <button (click)="onPushAllAvailability()" type="button">Push Availability</button>
        </li>
        <li
          matTooltip="Import bookings for selected rentals shown under mapped rentals."
          matTooltipPosition="left"
          *ngIf="!isTiketLikeChannel"
        >
          <button (click)="onImportAllBookings()" type="button">Import Bookings</button>
        </li>
      </ul>
    </div>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              [checked]="selection.hasValue() && isAllSelected(false)"
              [indeterminate]="selection.hasValue() && !isAllSelected(false)"
              (change)="onMasterToggle($event?.checked)"
              class="master-toggle"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="selection.toggle(element)"
              [checked]="selection.isSelected(element)"
              *ngIf="!element.isFeedApi"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="roomName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property Name</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-truncate" [title]="element.roomName">
            <span [matTooltip]="element.roomName" matTooltipPosition="above">
              {{ element.roomName | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="propertyId">
          <mat-header-cell *matHeaderCellDef> Property ID</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.propertyId }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="roomId">
          <mat-header-cell *matHeaderCellDef> Room ID</mat-header-cell>
          <mat-cell *matCellDef="let element" style="word-break: break-all">
            {{ element.roomId }}
            <app-airbnb-listing-status
              [connection]="element"
              *ngIf="channel | isAirBnBV2"
              class="ml-2"
            ></app-airbnb-listing-status>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="linkDateOrCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date Linked</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.linkDateOrCreatedView }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="rentalName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.rentalName" matTooltipPosition="above">
              {{ element.rentalName || '....' | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
              <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                <a class="btn-ellips">
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li>
                  <button type="button" (click)="onDetail(element, false)">
                    <i class="fal fa-edit"></i>Connection Details
                  </button>
                </li>
                <li *ngIf="element.rental">
                  <button type="button" (click)="onRentalDetail(element)">
                    <i class="fal fa-home"></i>Rental Details
                  </button>
                </li>
                <li *ngIf="!element.rental">
                  <button (click)="onLink(element)" type="button"><i class="fal fa-link"></i>Link</button>
                </li>
                <li *ngIf="element.rental">
                  <button (click)="onUnLink(element)" type="button"><i class="fal fa-unlink"></i>Unlink</button>
                </li>
                <li *ngIf="element.rental">
                  <button type="button" (click)="onPushRates(element)">
                    <i class="fal fa-arrow-up"></i>Push Rates
                  </button>
                </li>
                <li *ngIf="element.rental">
                  <button type="button" (click)="onPushAvailability(element)">
                    <i class="fal fa-arrow-up"></i>Push Availability
                  </button>
                </li>
                <li *ngIf="element.rental && !isTiketLikeChannel">
                  <button type="button" (click)="onImportBookings(element)">
                    <i class="fal fa-arrow-down"></i>Import Bookings
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    (click)="onImportProperty(element)"
                    *ngIf="!element.rentalId && (isAirBnBV1 || isAirBnBV2)"
                  >
                    <i class="fal fa-arrow-down"></i>Import Property
                  </button>
                </li>
                <li *ngIf="element.rental && isAirBnBV2">
                  <button type="button" (click)="onSetVisibility(element)">
                    <i class="fal fa-eye"></i>Set Visibility
                  </button>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onDetail(row)"
          class="clickable"
          [class.row-danger]="row.warning"
        ></mat-row>
      </mat-table>

      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="15"
        [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
  </div>
</div>
