import { Component, Inject } from '@angular/core'
import * as R from 'ramda'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Toaster } from '../../services'
import { TokeetImagePayload } from '../../stores'
import { CloudinaryUploadOptions, CloudinaryUploadDialogConfig } from './cloudinary-upload-dialog.interfaces'

@Component({
  selector: 'app-cloudinary-upload-dialog',
  templateUrl: './cloudinary-upload-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./cloudinary-upload-dialog.component.scss'],
})
export class CloudinaryUploadDialogComponent {
  options: CloudinaryUploadOptions = {
    title: 'Upload Images',
    maxSize: 10 * 1024 * 1024, // 10MB,
    maxUploads: 100,
    cloudinary: true,
    allowedFileExtensions: ['.png', '.jpg', '.jpeg', '.jfif'],
    autoUpload: true,
  }

  constructor(
    public dialogRef: MatDialogRef<CloudinaryUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CloudinaryUploadDialogConfig,
    protected toast: Toaster
  ) {
    this.options = { ...this.options, ...this.data.options }
  }

  onUploaded(uploadedFileResults: any[]) {
    if (uploadedFileResults.length) {
      const attachments = R.map(
        (data) =>
          ({
            public_id: data.public_id,
            secure_url: data.secure_url,
            url: data.secure_url || data.url,
            type: data.format,
            size: data.bytes,
            height: data.height,
            width: data.width,
          } as TokeetImagePayload),
        uploadedFileResults
      )

      this.toast.success('File(s) uploaded successfully.')
      this.dialogRef.close(attachments)
    }
  }

  close() {
    this.dialogRef.close()
  }
}
