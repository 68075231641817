import { Component, OnInit } from '@angular/core'
import {
  EditorRichComponent,
  EditorRichComponentDependencies,
} from '@tv3/containers/templates/template-editor/shared/editor-rich.component.abstract'

@Component({
  selector: 'app-editor-email',
  templateUrl: '../shared/editor-rich.component.abstract.html',
})
export class EditorEmailComponent extends EditorRichComponent implements OnInit {
  isPlain = false

  constructor(dependencies: EditorRichComponentDependencies) {
    super(dependencies)
  }

  ngOnInit() {}
}
