import { adapter, initialState, State } from './website-image.state'
import {
  AddWebsiteImages,
  AddWebsiteImagesComplete,
  DeleteWebsiteImage,
  DeleteWebsiteImageComplete,
  LoadWebsiteImagesComplete,
  MarkWebsiteImageAsFavicon,
  MarkWebsiteImageAsFaviconComplete,
  MarkWebsiteImageAsLogo,
  MarkWebsiteImageAsLogoComplete,
  MarkWebsiteImageAsPrimary,
  MarkWebsiteImageAsPrimaryComplete,
  UpdateWebsiteImage,
  UpdateWebsiteImageComplete,
  UpdateWebsiteImageOrders,
  UpdateWebsiteImageOrdersComplete,
} from '@tv3/store/website-image/website-image.actions'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadWebsiteImagesComplete, (state, { images }) => adapter.upsertMany(images, { ...state })),
  on(AddWebsiteImagesComplete, (state, { images }) => adapter.upsertMany(images, { ...state, isUpdating: false })),
  on(
    AddWebsiteImages,
    UpdateWebsiteImage,
    DeleteWebsiteImage,
    MarkWebsiteImageAsPrimary,
    MarkWebsiteImageAsFavicon,
    MarkWebsiteImageAsLogo,
    UpdateWebsiteImageOrders,
    (state) => ({ ...state, isUpdating: true })
  ),
  on(
    UpdateWebsiteImageComplete,
    MarkWebsiteImageAsPrimaryComplete,
    MarkWebsiteImageAsFaviconComplete,
    MarkWebsiteImageAsLogoComplete,
    (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })
  ),
  on(UpdateWebsiteImageOrdersComplete, (state, { updates }) =>
    adapter.updateMany(updates, { ...state, isUpdating: false })
  ),
  on(DeleteWebsiteImageComplete, (state, { id }) => adapter.removeOne(id, { ...state, isUpdating: false }))
)

export function websiteImageReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
