import { Expose, Type } from 'class-transformer'
import { RentalCheckTime, TaxV3 } from '../rental/rental.models'
import { deserialize, Serializable } from '../../functions/serializer'
import { ApiKey } from '../api-keys/api-keys.model'
import { Currency } from '../../constants'

// @dynamic
export class Address extends Serializable<Address> {
  address: string
  city: string
  country: string
  zip: number
  state: string

  @Expose({ name: 'country_code' })
  countryCode: string

  @Expose({ name: 'CC' })
  cc: string

  @Expose({ name: 'postal_code' })
  postalCode: number

  timezone: string

  static deserialize(data: object): Address {
    return deserialize(Address, data)
  }
}

// @dynamic
export class AccountAttributes extends Serializable<AccountAttributes> {
  taxes: TaxV3[]

  checkin?: RentalCheckTime
  checkout?: RentalCheckTime
  merchant_name: string

  static deserialize(data: object): AccountAttributes {
    return deserialize(AccountAttributes, data)
  }
}

// @dynamic
export class Account extends Serializable<Account> {
  @Expose({ name: 'account' })
  id: number

  account: number
  pkey: string

  @Type(() => Address)
  address: Address

  domain: string
  created: number
  email: string
  name: string
  phone: string
  website: string
  enable_2fa: 0 | 1

  @Expose({ name: 'domain_updated' })
  domainUpdated: number

  @Expose({ name: 'invoice_billing_addresses' })
  invoiceBillingAddresses: BillingAddress[]

  currency: Currency

  @Expose({ name: 'vat_tin' })
  vatTin: number

  apiKeys: ApiKey[]

  @Expose({ name: 'public_key' })
  publicKey: string

  @Expose({ name: 'stripe_id' })
  stripeId: string

  @Type(() => AccountAttributes)
  attributes?: AccountAttributes

  static deserialize(data: object): Account {
    return deserialize(Account, data)
  }
}

export interface BillingAddress extends Address {
  key: string
  name: string
}
