import { Component, Input, OnInit } from '@angular/core'
import { TokeetApp } from '../../store/app/app.model'
import { BehaviorSubject, isObservable } from 'rxjs'
import { AppService } from '../../store/app/app.service'
import { finalize, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-price',
  templateUrl: './app-price.component.html',
  styleUrls: ['./app-price.component.scss'],
})
export class AppPriceComponent implements OnInit {
  @Input() planId: string
  @Input() strategy: string
  @Input() app: TokeetApp
  @Input() amount: number

  isLoading = false
  price$ = new BehaviorSubject<string>('')

  constructor(private appService: AppService, private store: Store<any>) {}

  ngOnInit() {
    const price = this.getPrice()
    if (isObservable(price)) {
      this.isLoading = true
      price.pipe(finalize(() => (this.isLoading = false))).subscribe(
        (p) => {
          this.price$.next(p)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
    } else {
      this.price$.next(price)
    }
  }

  getPrice() {
    switch (this.app.stripe_product_code) {
      case 'tokeet_ai':
        return `$${this.app.starting_price}`
      case 'tokeet_smart_devices':
        return this.appService.getSmartDevicePrice().pipe(map((price) => `$${price} per device per month`))
      case 'rategenie':
        return 'Variable price per rental. No upfront costs.'
      default:
        return null
    }
  }
}
