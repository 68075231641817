import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { MessageEffects } from '@tv3/store/message/message.effects'
import { StoreModule } from '@ngrx/store'
import * as fromMessage from '@tv3/store/message/message.reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('message', fromMessage.messageReducer),
    EffectsModule.forFeature([MessageEffects]),
  ],
  declarations: [],
})
export class MessageModule {}
