import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as R from 'ramda'
import { Destroyable, untilDestroy } from '../../rx-operators'
import { Rental, selectRentalTags, selectAllRentals } from '../../stores'

@Component({
  selector: 'app-select-rentals-table',
  templateUrl: './select-rentals-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRentalsTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  @Input() excludedRentalIds: string[] = []

  @Output() selected = new EventEmitter<Rental[]>()

  displayedColumns: string[] = ['select', 'name']
  dataSource = new MatTableDataSource<Rental>()
  selection = new SelectionModel<Rental>(true, [])

  tags$ = this.store.pipe(select(selectRentalTags))
  tagsCtrl = new FormControl([])
  isLoading = false
  rentals: Rental[] = []

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.store
      .pipe(
        select(selectAllRentals),
        map((rentals) => lodash.filter(rentals, (r) => !this.excludedRentalIds.includes(r.id)))
      )
      .subscribe((rentals) => {
        this.rentals = rentals
        this.refreshTableData()
      })

    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selected.emit(this.selection.selected)
    })
  }

  refreshTableData(tags?: string[]) {
    if (tags?.length > 0) {
      this.dataSource.data = this.filterByTags(this.rentals, tags)
    } else {
      this.dataSource.data = this.rentals
    }
  }

  filterByTags(rentals: Rental[], tags: string[]) {
    return R.filter(
      (r) =>
        R.any(
          R.equals(true),
          R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], r)), tags)
        ),
      rentals
    )
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: Rental, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }
}
