import { epochToView, Expose, plainToClass, Serializable } from '@tokeet-frontend/tv3-platform'
import { LanguageNamePipe } from '@tokeet-frontend/tv3-platform'
import { toUpper, startCase } from 'lodash'

import { v4 as uuid } from 'uuid'

export enum TemplateType {
  None = 0,
  Email = 1,
  Quote = 2,
  Contract = 3,
  // Invoice = 4, // use 8 instead
  PushNotification = 5,
  Payload = 6,
  CustomBranding = 7,
  InvoiceTemplate = 8,
  Review = 9,
}

export enum PayloadMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface TemplateTranslation {
  id: string
  language: string
  languageName: string
  text: string
  subject: string
}

export interface TemplateAttributes {
  tags?: string[]
  autopilot?: string
}

export interface PayloadHttpHeader {
  id: string
  name: string
  value: string
}

export interface TemplateResponse {
  body: string
  description: string
  archived: number
  type: TemplateType
  method: PayloadMethod
  account: number
  created: number
  pkey: string
  name: string
  headers: Partial<PayloadHttpHeader>[]

  subject: string
  modified: number

  lastused: number

  attributes: TemplateAttributes
}

export enum SymplTemplates {
  SymplStandardTemplate = 'sympl-1112223337ST-2',
  SymplQuoteTemplate = 'sympl-1112223336ST-2',
  SymplInvoiceTemplate = 'sympl-1112223338ST-2',
  SymplGuestPortalTemplate = 'sympl-guestportal-1',
}

export enum TokeetTemplates {
  TokeetStandardTemplate = '1112223337ST-2',
  TokeetQuoteTemplate = '1112223336ST-2',
  TokeetInvoiceTemplate = '1112223338ST-2',
  TokeetGuestPortalTemplate = 'guestportal-1',
}

export const defaultStandardMessageTemplate = {
  name: 'AdvanceCM Standard Template',
  pkey: TokeetTemplates.TokeetStandardTemplate,
}
export const defaultQuoteMessageTemplate = {
  name: 'AdvanceCM Quote Template',
  pkey: TokeetTemplates.TokeetQuoteTemplate,
}
export const defaultInvoiceMessageTemplate = {
  name: 'AdvanceCM Invoice Template',
  pkey: TokeetTemplates.TokeetInvoiceTemplate,
}
export const defaultGuestPortalMessageTemplate = {
  name: 'AdvanceCM Guest Portal Template',
  pkey: TokeetTemplates.TokeetGuestPortalTemplate,
}

export const defaultSymplStandardMessageTemplate = {
  name: 'Sympl Standard Template',
  pkey: SymplTemplates.SymplStandardTemplate,
}
export const defaultSymplQuoteMessageTemplate = {
  name: 'Sympl Quote Template',
  pkey: SymplTemplates.SymplQuoteTemplate,
}
export const defaultSymplInvoiceMessageTemplate = {
  name: 'Sympl Invoice Template',
  pkey: SymplTemplates.SymplInvoiceTemplate,
}
export const defaultSymplGuestPortalMessageTemplate = {
  name: 'Sympl Guest Portal Template',
  pkey: SymplTemplates.SymplGuestPortalTemplate,
}

export const defaultTemplates = [
  defaultStandardMessageTemplate,
  defaultInvoiceMessageTemplate,
  defaultQuoteMessageTemplate,
  defaultGuestPortalMessageTemplate,
]

export const defaultSymplTemplates = [
  defaultSymplStandardMessageTemplate,
  defaultSymplInvoiceMessageTemplate,
  defaultSymplQuoteMessageTemplate,
  defaultSymplGuestPortalMessageTemplate,
]

export type DefaultTemplateType = 'standard' | 'quote' | 'invoice' | 'portal'

export const defaultTemplateBundle = {
  tokeet: {
    standard: defaultStandardMessageTemplate,
    quote: defaultQuoteMessageTemplate,
    invoice: defaultInvoiceMessageTemplate,
    portal: defaultGuestPortalMessageTemplate,
  },
  sympl: {
    standard: defaultSymplStandardMessageTemplate,
    quote: defaultSymplQuoteMessageTemplate,
    invoice: defaultSymplInvoiceMessageTemplate,
    portal: defaultSymplGuestPortalMessageTemplate,
  },
}

export class Template extends Serializable<Template> {
  @Expose({ name: 'pkey' })
  id: string

  subject: string
  modified: number
  account: number
  archived: number
  body: string
  created: number
  description: string
  name: string
  friendlyName: string
  type: TemplateType
  triggers: any[]
  lastused: number
  attributes: TemplateAttributes
  method: PayloadMethod
  headers: Partial<PayloadHttpHeader>[]
  languages: Partial<TemplateTranslation>[]

  event: string

  typeView: string
  createdView: string
  modifiedView: string

  static deserialize(data: any): Template {
    let template: Template = plainToClass<Template, Template>(Template, data)

    const friendlyId = template.id ? toUpper(template.id.substring(0, 5)) : ''
    template.friendlyName = template.name ? `${template.name} - ${friendlyId}` : friendlyId

    template.type = parseInt((<any>template).type, 10)

    template.typeView = startCase(TemplateType[template.type])
    template.createdView = epochToView(template.created)
    template.modifiedView = epochToView(template.modified)

    return template
  }
}

export class TemplateTranslation {
  id = ''
  language = ''
  languageName = ''
  text = ''
  subject = ''

  constructor(params?: Partial<TemplateTranslation>) {
    this.id = uuid()
    if (params) {
      if (params.id) {
        this.id = params.id
      }
      this.language = params.language || ''
      this.languageName = new LanguageNamePipe().transform(this.language)
      this.text = params.text || ''
      this.subject = params.subject || ''
    }
  }
}

export interface TemplateAddRequest {
  name: string
  description: string
  subject?: string
  body: string
  type: TemplateType
  event?: string
  attributes?: TemplateAttributes
}

export interface TemplateUpdateRequest {
  name: string
  description: string
  subject?: string
  body: string
  type: TemplateType
  event?: string
  attributes?: TemplateAttributes
}

export interface TemplateEditRequest {
  name: string
  description: string
  subject?: string
  body: string
  type: TemplateType
  method?: PayloadMethod
  headers?: Partial<PayloadHttpHeader>[]
  languages?: Partial<TemplateTranslation>[]
  attributes?: TemplateAttributes
}

export interface TranslateResponse {
  data: TranslateResponseTranslations
}

export interface TranslateResponseTranslations {
  translations: TranslateResponseTranslationModel[]
}

export interface TranslateResponseTranslationModel {
  detectedSourceLanguage: string
  translatedText: string
}

export interface TemplateForm {
  id: string
  name: string
  description: string
  type: TemplateType
  method?: PayloadMethod
  languages?: TemplateTranslation[]
  headers?: Partial<PayloadHttpHeader>[]
  subject: string
  body: string
}
