import { adapter, initialState, State } from './connection.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddExportedCalendarConnection,
  AddExportedCalendarConnectionComplete,
  AddImportedCalendarConnection,
  AddImportedCalendarConnectionComplete,
  DeleteExportedCalendarConnection,
  DeleteExportedCalendarConnectionComplete,
  DeleteImportedCalendarConnection,
  DeleteImportedCalendarConnectionComplete,
  GetConnectionStatuesComplete,
  ImportBookingsFromConnectionComplete,
  LinkConnectionWithRental,
  LinkConnectionWithRentalComplete,
  LoadConnectionsByChannelComplete,
  LoadConnectionsComplete,
  ManualRefreshImportedCalendarConnectionComplete,
  PushAvailabilityToConnectionComplete,
  PushAvailabilityToConnectionForWizardComplete,
  PushRatesToConnectionComplete,
  RemoveLocalConnectionsByIds,
  RemoveLocalConnectionsByChannelId,
  UnlinkConnectionWithRental,
  UnlinkConnectionWithRentalComplete,
  LoadConnectionComplete,
  DeleteCalendarConnectionsComplete,
  LoadConnectionsByChannel,
  GetAirbnbListingStatusComplete,
} from '@tv3/store/connection/connection.actions'
import { deleteRentalComplete } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { updateMany, updateOne, upsertMany } from '@tokeet-frontend/tv3-platform'
import { RefreshConnectionsForChannel } from './connection.actions'

export const reducer = createReducer(
  initialState,
  on(LoadConnectionsComplete, (state, { connections }) =>
    adapter.setAll(connections, {
      ...state,
      isLoaded: true,
    })
  ),
  on(LoadConnectionComplete, (state, { connection }) => adapter.setOne(connection, state)),
  on(LoadConnectionsByChannelComplete, (state, { connections }) =>
    upsertMany(adapter, connections, { ...state, isChannelRefreshing: false })
  ),
  on(AddExportedCalendarConnectionComplete, (state, { connection }) =>
    adapter.addOne(connection, {
      ...state,
      isUpdating: false,
    })
  ),
  on(AddImportedCalendarConnectionComplete, (state, { connection }) =>
    adapter.addOne(connection, {
      ...state,
      isUpdating: false,
    })
  ),
  on(DeleteExportedCalendarConnectionComplete, (state, { id }) =>
    adapter.removeOne(id, {
      ...state,
      isUpdating: false,
    })
  ),
  on(DeleteImportedCalendarConnectionComplete, (state, { id }) =>
    adapter.removeOne(id, {
      ...state,
      isUpdating: false,
    })
  ),
  on(DeleteCalendarConnectionsComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(ImportBookingsFromConnectionComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(PushAvailabilityToConnectionComplete, PushAvailabilityToConnectionForWizardComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(PushRatesToConnectionComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(LinkConnectionWithRentalComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(ManualRefreshImportedCalendarConnectionComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(UnlinkConnectionWithRentalComplete, (state, { update }) =>
    updateOne(adapter, update, {
      ...state,
      isUpdating: false,
    })
  ),
  on(GetConnectionStatuesComplete, (state, { updates }) => updateMany(adapter, updates, state)),
  on(GetAirbnbListingStatusComplete, (state, { updates }) =>
    updateMany(adapter, updates, { ...state, isAirbnbListingStatusLoaded: true })
  ),

  on(
    AddImportedCalendarConnection,
    AddExportedCalendarConnection,
    DeleteImportedCalendarConnection,
    DeleteExportedCalendarConnection,
    LinkConnectionWithRental,
    UnlinkConnectionWithRental,
    (state) => ({ ...state, isUpdating: true })
  ),
  on(LoadConnectionsByChannel, RefreshConnectionsForChannel, (state) => ({ ...state, isChannelRefreshing: true })),
  on(deleteRentalComplete, (state, { id }) => {
    const connections = R.filter((c) => c.rentalId === id, R.values(state.entities))
    const connectionIds = R.map((c) => c.id, connections)
    return adapter.removeMany(connectionIds, state)
  }),
  on(RemoveLocalConnectionsByIds, (state, { connectionIds }) => adapter.removeMany(connectionIds, state)),
  on(RemoveLocalConnectionsByChannelId, (state, { channelId }) => {
    const connections = R.filter((c) => c.channelId === channelId, R.values(state.entities))
    const connectionIds = R.map((c) => c.id, connections)
    return adapter.removeMany(connectionIds, state)
  })
)

export function connectionReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
