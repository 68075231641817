import { Expose, Serializable, plainToClass, epochToView } from '@tokeet-frontend/tv3-platform'

export class Forward extends Serializable<Forward> {
  archived: boolean
  user: string
  created: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  forward: string

  // do not try to Expose pkey as id as id and pkey in this case are different thing
  id: string
  pkey: string

  configuration: any
  account: number

  rentalView = ''
  createdView: string

  static deserialize(data: any): Forward {
    let forward: Forward = plainToClass<Forward, Forward>(Forward, data)

    forward.createdView = epochToView(forward.created)

    return forward
  }
}
