import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { DiscountCodeEffects } from './discount-code.effects'
import { StoreModule } from '@ngrx/store'
import { reducer } from './discount-code.reducer'
import { DiscountCodeService } from './discount-code.service'

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([DiscountCodeEffects]),
    StoreModule.forFeature('discountCodes', reducer),
  ],
  providers: [DiscountCodeService],
  declarations: [],
})
export class DiscountCodeModule {}
