import { plainToClass } from 'class-transformer'
import * as lodash from 'lodash'
import { Expose, Serializable } from '../../functions/serializer'

export enum ChannelTypes {
  ICal = 'ical',
  ICal2 = 'ical2',
  Auto = 'auto',
}

export enum ChannelNameTokens {
  AgodaAPI = 'agoda',
  AirBnBAPI = 'airbnbapi',
  AirBnBV2API = 'airbnbapiv2',
  BookingAPI = 'booking',
  ExpediaAPI = 'expedia',
  FlipKeyAPI = 'flipkeyapi',
  HomeToGo = 'HomeToGo',
  AirBnBICal = 'airbnb',
  ClearTripICal = 'ClearTrip',
  EbabICal = 'ebab',
  FlipKeyICal = 'FlipKey',
  HolidayLettingsICal = 'holidaylettings',
  HouseTripICal = 'HouseTrip',
  TripvillasICal = 'Tripvillas',
  UnknownICal = 'Unknown',
  VrboICal = 'Homeaway',
  VacationRentalsICal = 'vacationrentals.com',
  VacationHomeRentalsICal = 'vacationhomerentals',
  WimduICal = 'Wimdu',
  Homeaway = 'vrboapi',
  Hvmi = 'hvmi',
  Holidu = 'holiduapi',
  RentalsUnited = 'rentalsunited',
  Tiket = 'tiket',
  GoogleVacationRentals = 'google_vacation_rentals',
  Trip = 'Trip.com',
  Webready = 'webready',

  Tokeet = 'tokeet',
  TokeetWebsite = 'tokeetwebsite',
  TokeetWidgets = 'widget',
}

export function compareSource(id1: string, id2: string) {
  if (!id1 || !id2) {
    return false
  }

  id2 = lodash.toLower(id2)
  id1 = lodash.toLower(id1)
  if (id1 === id2) return true

  const sources = [
    //
    [/^booking/],
    [/airbnb/],
    [/expedia/],
    [/holidu/],
    [/marriott/, /hvmi/],
    [/vrbo/, /homeaway/],
    [/widget/],
  ]

  return lodash.some(
    sources,
    (source) => lodash.some(source, (reg) => reg.test(id1)) && lodash.some(source, (reg) => reg.test(id2))
  )
}

export const singleAccountChannels = [
  //
  ChannelNameTokens.Holidu,
  ChannelNameTokens.GoogleVacationRentals,
  ChannelNameTokens.RentalsUnited,
  ChannelNameTokens.Homeaway,
]

export const tooltipsForChannel = {
  [ChannelNameTokens.AirBnBAPI]:
    'Previous version of the AirBnb API. Will be removed once all users have migrated to the official API version.',
  [ChannelNameTokens.AirBnBV2API]: 'Official AirBnb API. Recommended for most users.',
}

export class Channel extends Serializable<Channel> {
  @Expose({ name: 'channelId' })
  id: string

  @Expose({ name: 'logo_color' })
  logoColor: string

  @Expose({ name: 'isconnected' })
  isConnected: number

  @Expose({ name: 'logo_bw' })
  logoBw: string

  logo: string
  depricated: boolean
  name: ChannelNameTokens
  description: string
  channelId: string
  friendlyName: string
  infoPage: string
  channelName: string
  agent: string
  enabled: boolean
  type: ChannelTypes
  handler: string

  isICal: boolean
  isApi: boolean

  token: string

  static deserialize(data: any): Channel {
    let channel: Channel = plainToClass<Channel, Channel>(Channel, data)

    channel.friendlyName = channel.friendlyName || 'Unknown'
    channel.isICal = [ChannelTypes.ICal, ChannelTypes.ICal2].indexOf(channel.type) > -1
    channel.isApi = ChannelTypes.Auto === channel.type

    return channel
  }
}

export const TOKEET_CHANNEL_ID = '00000000-0000-0000-0000-000000000000-tokeetwebsite'
export const TOKEET_CHANNEL_RATE_CATEGORY = TOKEET_CHANNEL_ID

export const tokeetWebsitesChannel = Channel.deserialize({
  channelId: TOKEET_CHANNEL_ID,
  token: ChannelNameTokens.TokeetWebsite,
  friendlyName: 'AdvanceCM Websites',
  name: ChannelNameTokens.TokeetWebsite,
  type: 'auto',
})
export const tokeetDashboardChannel = Channel.deserialize({
  channelId: TOKEET_CHANNEL_ID,
  token: ChannelNameTokens.Tokeet,
  friendlyName: 'AdvanceCM Dashboard',
  name: ChannelNameTokens.Tokeet,
  type: 'auto',
})
export const tokeetWidgetChannel = Channel.deserialize({
  channelId: TOKEET_CHANNEL_ID,
  token: ChannelNameTokens.TokeetWidgets,
  friendlyName: 'AdvanceCM Widget',
  name: ChannelNameTokens.TokeetWidgets,
  type: 'auto',
})

export const tokeetInternalChannels = [
  //
  tokeetDashboardChannel,
  tokeetWebsitesChannel,
  tokeetWidgetChannel,
]
