import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MessageSentWithComponent } from './components/message-sent-with/message-sent-with.component'
import { IntegrationLibModule } from '@tokeet-frontend/integrations'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'

const components = [MessageSentWithComponent]
const dialogs = []

const services = []

const modules = [Tv3PlatformModule, IntegrationLibModule]

@NgModule({
  imports: [CommonModule, ...modules],
  providers: [...services],
  declarations: [...dialogs, ...components],
  exports: [...modules, ...components],
})
export class InquiryLibModule {}
