import {
  AddBookingComplete,
  AddInquiryComplete,
  ChangeAirBnBResponseComplete,
  DeleteInquiriesComplete,
  DeleteInquiryComplete,
  DeleteInquiryMessageComplete,
  FetchInquiriesComplete,
  LoadFilteredInquiriesComplete,
  LoadInquiriesComplete,
  LoadInquiryDetailsComplete,
  RefreshInquiriesComplete,
  SearchElasticInquiriesComplete,
  SearchInquiriesComplete,
  UpdateInquiryCostComplete,
} from './inquiry.actions'
import { adapter, initialState, State } from './inquiry.state'
import * as R from 'ramda'
import {
  AddMessage,
  AddMessageComplete,
  MarkAsReadComplete,
  MoveMessageComplete,
  GetBookingMessagesComplete,
} from '@tv3/store/message/message.actions'
import {
  AddInquiryCardComplete,
  AddTagsToInquiriesComplete,
  ArchiveInquiriesComplete,
  ArchiveInquiryComplete,
  CancelBooking,
  CancelBookingComplete,
  CancelBookingWithReason,
  ChangeBookingEngineCostComplete,
  ChangeCheckInComplete,
  ChangeCheckOutComplete,
  ChangeInquiryAdultsComplete,
  ChangeInquiryArriveComplete,
  ChangeInquiryChannelComplete,
  ChangeInquiryChildrenComplete,
  ChangeInquiryDepartComplete,
  ChangeInquiryGuestComplete,
  ChangeInquiryRentalComplete,
  ConfirmBooking,
  ConfirmBookingComplete,
  DeleteInquiryCardComplete,
  FollowupInquiriesComplete,
  FollowupInquiryComplete,
  InquiryUpdateComplete,
  LinkInquiriesComplete,
  MarkPaidInquiriesComplete,
  MarkPaidInquiryComplete,
  MarkUnpaidInquiriesComplete,
  MarkUnpaidInquiryComplete,
  NoFollowupInquiriesComplete,
  NoFollowupInquiryComplete,
  ReadInquiriesComplete,
  ReadInquiryComplete,
  SetInquiryColorComplete,
  SetGuestPortalAccessibleComplete,
  UnArchiveInquiriesComplete,
  UnArchiveInquiryComplete,
  UnLinkInquiryComplete,
  UnReadInquiriesComplete,
  UnReadInquiryComplete,
  UpdateCheckinStatusComplete,
  UpdateCheckoutStatusComplete,
  UpdateInquiryGuestComplete,
  ChangeBookingDetailsComplete,
  ChangeBookingDetails,
  ChangeInquiryGuest,
  ChangeInquiryDepart,
  ChangeInquiryArrive,
  ChangeBookingEngineCost,
  ChangeInquiryRental,
  ChangeInquiryChildren,
  ChangeInquiryChannel,
  ChangeInquiryAdults,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { createReducer, on } from '@ngrx/store'
import { AddNote, AddNoteComplete, DeleteNoteComplete, UpdateNoteComplete } from '@tv3/store/note/note.actions'
import { ActionFailed, ActionSkipped, updateMany, updateOne, upsertMany } from '@tokeet-frontend/tv3-platform'

export const mainReducer = createReducer(
  initialState,
  on(LoadInquiriesComplete, (state, { inquiries, pagination }) =>
    upsertMany(
      adapter,
      inquiries,
      R.merge(state, {
        isLoaded: true,
        pagination,
      })
    )
  ),
  on(FetchInquiriesComplete, SearchElasticInquiriesComplete, LoadFilteredInquiriesComplete, (state, { inquiries }) =>
    upsertMany(adapter, inquiries, state)
  ),
  on(RefreshInquiriesComplete, (state, { inquiries }) => adapter.addMany(inquiries, state)),
  on(SearchInquiriesComplete, (state, { inquiries }) => upsertMany(adapter, inquiries, state)),
  on(LoadInquiryDetailsComplete, (state, { inquiry }) => adapter.setOne(inquiry, state)),
  on(AddBookingComplete, AddInquiryComplete, (state, { inquiry }) => adapter.addOne(inquiry, state)),
  on(
    ChangeInquiryAdults,
    ChangeInquiryChannel,
    ChangeInquiryChildren,
    ChangeInquiryRental,
    ChangeBookingEngineCost,
    ChangeInquiryArrive,
    ChangeInquiryDepart,
    ChangeInquiryGuest,
    ChangeBookingDetails,
    (state) => ({ ...state, isUpdatingBooking: true })
  ),
  on(ChangeBookingDetailsComplete, (state, { update }) =>
    adapter.updateOne(update, { ...state, isUpdatingBooking: false })
  ),
  on(
    ChangeInquiryAdultsComplete,
    ChangeInquiryChannelComplete,
    ChangeInquiryChildrenComplete,
    ChangeInquiryRentalComplete,
    ChangeBookingEngineCostComplete,
    ChangeInquiryArriveComplete,
    ChangeInquiryDepartComplete,
    ChangeInquiryGuestComplete,
    ConfirmBookingComplete,
    CancelBookingComplete,
    (state, { update }) =>
      adapter.updateOne(
        update,
        R.merge(state, {
          isCancelingBooking: false,
          isConfirmingBooking: false,
          isSavingNote: false,
          isAddingMessage: false,
          isUpdatingBooking: false,
        })
      )
  ),
  on(
    FollowupInquiryComplete,
    NoFollowupInquiryComplete,
    ReadInquiryComplete,
    UnReadInquiryComplete,
    ArchiveInquiryComplete,
    UnArchiveInquiryComplete,
    AddInquiryCardComplete,
    UpdateInquiryGuestComplete,
    UnLinkInquiryComplete,
    DeleteInquiryCardComplete,
    (state, { update }) =>
      adapter.updateOne(
        update,
        R.merge(state, {
          isCancelingBooking: false,
          isConfirmingBooking: false,
          isSavingNote: false,
          isAddingMessage: false,
        })
      )
  ),
  on(
    ChangeCheckInComplete,
    ChangeCheckOutComplete,
    InquiryUpdateComplete,
    MarkPaidInquiryComplete,
    MarkUnpaidInquiryComplete,
    SetInquiryColorComplete,
    UpdateCheckinStatusComplete,
    UpdateCheckoutStatusComplete,
    SetGuestPortalAccessibleComplete,
    (state, { update }) =>
      adapter.updateOne(
        update,
        R.merge(state, {
          isCancelingBooking: false,
          isConfirmingBooking: false,
          isSavingNote: false,
          isAddingMessage: false,
        })
      )
  ),
  on(AddMessageComplete, MarkAsReadComplete, MoveMessageComplete, GetBookingMessagesComplete, (state, { update }) =>
    adapter.updateOne(
      update,
      R.merge(state, {
        isCancelingBooking: false,
        isConfirmingBooking: false,
        isSavingNote: false,
        isAddingMessage: false,
      })
    )
  ),
  on(AddNoteComplete, UpdateNoteComplete, DeleteNoteComplete, ChangeBookingDetailsComplete, (state, { update }) =>
    adapter.updateOne(
      update,
      R.merge(state, {
        isCancelingBooking: false,
        isConfirmingBooking: false,
        isSavingNote: false,
        isAddingMessage: false,
      })
    )
  ),
  on(ChangeAirBnBResponseComplete, DeleteInquiryMessageComplete, (state, { update }) =>
    adapter.updateOne(
      update,
      R.merge(state, {
        isCancelingBooking: false,
        isConfirmingBooking: false,
        isSavingNote: false,
        isAddingMessage: false,
      })
    )
  ),
  on(
    LinkInquiriesComplete,
    AddTagsToInquiriesComplete,
    ArchiveInquiriesComplete,
    UnArchiveInquiriesComplete,
    FollowupInquiriesComplete,
    NoFollowupInquiriesComplete,
    ReadInquiriesComplete,
    UnReadInquiriesComplete,
    MarkPaidInquiriesComplete,
    MarkUnpaidInquiriesComplete,
    (state, { updates }) => updateMany(adapter, updates, state)
  ),
  on(DeleteInquiryComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteInquiriesComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(CancelBooking, (state) => ({ ...state, isCancelingBooking: true })),
  on(CancelBookingWithReason, (state) => ({ ...state, isCancelingBooking: true })),
  on(ConfirmBooking, (state) => ({ ...state, isConfirmingBooking: true })),
  on(AddNote, (state) => ({ ...state, isSavingNote: true })),
  on(AddMessage, (state) => ({ ...state, isAddingMessage: true })),
  on(ActionFailed, ActionSkipped, (state) => ({
    ...state,
    isAddingMessage: false,
    isCancelingBooking: false,
    isConfirmingBooking: false,
    isSavingNote: false,
    isUpdatingBooking: false,
  })),
  on(UpdateInquiryCostComplete, (state, { update }) => updateOne(adapter, update, state))
)

export function getTags(inquiries: Inquiry[]) {
  return R.pipe(
    R.map((i: Inquiry) => R.pathOr([], ['attributes', 'tags'], i)),
    R.flatten,
    R.reject(R.isNil),
    R.map(R.when(R.is(Number), R.toString)),
    R.uniq
  )(inquiries).sort()
}

export function getSources(inquiries: Inquiry[]) {
  return R.pipe(
    R.map((i: Inquiry) => i.inquirySource),
    R.uniq,
    R.reject(R.pipe(R.is(String), R.not)),
    R.map(R.toLower),
    R.uniq,
    R.sortBy((i) => i),
    R.prepend('All Sources'),
    R.map((source) => ({ id: source, name: source }))
  )(inquiries)
}

export function tagsReducer(state = initialState): State {
  return { ...state, tags: getTags(R.values(state.entities)) }
}

export function sourcesReducer(state = initialState): State {
  return { ...state, sources: getSources(R.values(state.entities)) }
}

export const reducer = R.pipe(mainReducer, tagsReducer, sourcesReducer)

export const reducers = {
  reducer,
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
