import * as momentNs from 'moment'
const moment = momentNs
import { isDate, isNumber } from 'lodash'
import * as R from 'ramda'

export function getLocalDateString(date: number | string | Date | momentNs.Moment, format: string) {
  let dateString = ''
  if (moment.isMoment(date)) {
    dateString = date.format(format)
  } else if (isDate(date)) {
    dateString = moment(date).format(format)
  } else if (isNumber(date)) {
    dateString = moment(date * 1000).format(format)
  } else {
    dateString = moment(date).format(format)
  }

  return dateString
}

export function asLocalDate(utcEpoch: number, keepTime = true): Date | undefined {
  const format = keepTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
  if (!utcEpoch) {
    return undefined
  }
  return moment(moment.utc(utcEpoch * 1000).format(format), format).toDate()
}

export function asUTCEpoch(
  date: number | string | Date | momentNs.Moment,
  alignTime?: 'start' | 'end'
): number | undefined {
  const format = 'YYYY-MM-DD HH:mm:ss'
  if (!date) {
    return undefined
  }

  const str = getLocalDateString(date, format)

  const m = moment.utc(str, format)
  if (alignTime === 'start') {
    m.startOf('date')
  } else if (alignTime === 'end') {
    m.endOf('date')
  }

  return m.unix()
}

export type DateFormatType = 'daydate' | 'date' | 'datetime' | 'time' | string
export function formatDate(value: any, format?: DateFormatType, utc = true, defaultValue = 'Never'): string {
  if (!value) return defaultValue

  const datetimeFormat = 'DD - MMM - YYYY | h:mm A'

  switch (format) {
    case 'daydate':
      format = 'ddd MMM DD, YYYY'
      break
    case 'date':
      format = 'DD - MMM - YYYY'
      break
    case 'time':
      format = 'h:mm A'
      break
    case 'datetime':
      format = datetimeFormat
    default:
      format = format || datetimeFormat
      break
  }

  let date: moment.Moment

  if (moment.isMoment(value)) {
    date = value
  }
  if (moment.isDate(value)) {
    date = moment(value)
  }
  if (R.is(Number, value) && value != 0) {
    date = (utc ? moment.utc : moment)(value * 1000)
  }
  if (R.is(String, value)) {
    date = moment(new Date(value))
  }
  if (!date || !date.isValid()) return defaultValue
  return date.format(format)
}
