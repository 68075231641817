import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ActivityTableComponent } from './components/activity-table/activity-table.component'
import { ActivityService } from './store/service'
import { SharedModule } from '@tv3/shared/shared.module'

const components = [ActivityTableComponent]

const services = [ActivityService]

@NgModule({
  imports: [CommonModule, Tv3PlatformModule, SharedModule],
  providers: [...services],
  declarations: [...components],
  exports: [...components],
})
export class ActivityLibraryModule {}
