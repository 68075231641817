<ng-container *ngIf="external || !inquiry?.bookingEngine">
  <div class="box-details">
    <div class="box-dsc-row row">
      <div class="col-8">Charge</div>
      <div class="col-4">
        <span [innerHtml]="charges?.charge || 0 | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Additional Guests Fee</div>
      <div class="col-4">
        <span [innerHtml]="charges?.extraGuests || 0 | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Discount</div>
      <div class="col-4">
        <span [innerHtml]="charges?.discountSum || 0 | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Fees</div>
      <div class="col-4">
        <span [innerHtml]="charges?.feeSum || 0 | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Tax</div>
      <div class="col-4">
        <span [innerHtml]="charges?.taxFee | tokeetCurrency : currencySymbol"></span
        ><span>({{ inquiry?.bookingEngine?.taxPercent | number : '1.2-2' }}%)</span>
      </div>
    </div>
    <ng-container *ngIf="tryToGetFormulaCost | call : inquiryService : inquiry | async as formulaCost">
      <div class="box-dsc-row row">
        <div class="col-8 col-long">Booking Formula</div>
        <div class="col-4">
          <h4 [matTooltip]="currencyCode" matTooltipPosition="left" class="total-price">
            <span [innerHtml]="formulaCost | tokeetCurrency : currencySymbol"></span>
          </h4>
        </div>
      </div>
    </ng-container>
    <hr />
    <div class="box-dsc-row row">
      <div class="col-8">Total</div>
      <div class="col-4">
        <h4 [matTooltip]="currencyCode" matTooltipPosition="left" class="total-price">
          <span [innerHtml]="charges?.sum | tokeetCurrency : currencySymbol"></span>
        </h4>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!external && !!inquiry?.bookingEngine">
  <div class="box-details">
    <div class="box-dsc-row row">
      <div class="col-8">Base Charge</div>
      <div class="col-4">
        <span [innerHtml]="inquiry?.bookingEngine?.extraBedroomsBase | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Additional Guests Fee</div>
      <div class="col-4">
        <span [innerHtml]="inquiry?.bookingEngine?.extraGuests | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row" *ngIf="!!inquiry?.bookingEngine?.extraBedrooms">
      <div class="col-8">Additional Bedrooms Fee</div>
      <div class="col-4">
        <span [innerHtml]="inquiry?.bookingEngine?.extraBedrooms | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Discounts & Fees</div>
      <div class="col-4">
        <span [innerHtml]="inquiry?.bookingEngine?.discountTotal | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div *ngFor="let fee of inquiry?.bookingEngine?.bookingFees" class="box-dsc-row row">
      <div class="col-8">
        {{ fee.name }}
      </div>
      <div class="col-4">
        <span [innerHtml]="fee.amount | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="box-dsc-row row">
      <div class="col-8">Taxes</div>
      <div class="col-4">
        <span [innerHtml]="inquiry?.bookingEngine?.taxTotal | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <ng-container *ngIf="tryToGetFormulaCost | call : inquiryService : inquiry | async as formulaCost">
      <div class="box-dsc-row row">
        <div class="col-8 col-long">Booking Formula</div>
        <div class="col-4">
          <h4 [matTooltip]="currencyCode" matTooltipPosition="left" class="total-price">
            <span [innerHtml]="formulaCost | tokeetCurrency : currencySymbol"></span>
          </h4>
        </div>
      </div>
    </ng-container>
    <hr />
    <div class="box-dsc-row row">
      <div class="col-4">Total</div>
      <div class="col-8">
        <div class="d-flex justify-content-end align-items-center gap-1">
          <app-booking-paid-status [inquiry]="inquiry"></app-booking-paid-status>
          <strong [innerHtml]="inquiry?.bookingEngine?.total | tokeetCurrency : currencySymbol"></strong>
        </div>
      </div>
    </div>
  </div>
</ng-container>
