import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { LoadContracts, LoadContractsComplete } from './contract.actions'
import { ContractService } from '@tv3/store/contract/contract.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ContractEffects {
  @Effect()
  loadAccount$ = this.actions$.pipe(
    ofType(LoadContracts),
    exhaustMap(() =>
      this.contractService.getContracts().pipe(
        map((contracts) => LoadContractsComplete({ contracts })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<any>, private contractService: ContractService) {}
}
