import * as R from 'ramda'
import { adapter, initialState, State } from './message.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  DeleteMessageComplete,
  DeleteMessagesComplete,
  LoadLatestMessagesComplete,
  MarkOneMessageAsReadComplete,
  RemoveMessageFromFeedComplete,
  RemoveMessagesFromFeedComplete,
} from '@tv3/store/message/message.actions'

export const reducer = createReducer(
  initialState,
  on(LoadLatestMessagesComplete, (state, { messages }) => adapter.addAll(messages, R.merge(state, { isLoaded: true }))),
  on(DeleteMessageComplete, (state, { message }) => adapter.removeOne(message.id, state)),
  on(RemoveMessageFromFeedComplete, (state, { message }) => adapter.removeOne(message.id, state)),
  on(MarkOneMessageAsReadComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(DeleteMessagesComplete, (state, { items }) =>
    adapter.removeMany(
      items.map((t) => t.message.id),
      state
    )
  ),
  on(RemoveMessagesFromFeedComplete, (state, { messages }) =>
    adapter.removeMany(
      messages.map((t) => t.id),
      state
    )
  )
)

export function messageReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
