import { Component, Input, OnInit } from '@angular/core'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { Inquiry, isBookingFromAirbnb } from '@tv3/store/inquiry/inquiry.model'
import { AirbnbReservationAlterationDialogService } from '../../airbnb-reservation-alteration-dialog/airbnb-reservation-alteration-dialog.service'
import * as lodash from 'lodash'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { filter, take, map, switchMap } from 'rxjs/operators'
import { LoadInquiryDetailsComplete } from '@tv3/store/inquiry/inquiry.actions'
import { Actions, ofType } from '@ngrx/effects'
import {
  epochToUTCDate,
  isSomething,
  untilDestroy,
  Rental,
  Destroyable,
  DataCheckerService,
  DialogService,
} from '@tokeet-frontend/tv3-platform'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import * as moment from 'moment'
import { SmartDevicesGuard } from '@tokeet-frontend/smart-devices'
import { Store } from '@ngrx/store'

export interface BookingDetails {
  numAdults?: number
  numChild?: number
  rental?: Rental
  guestArrive?: Date
  guestDepart?: Date
  checkIn?: moment.Moment
  checkOut?: moment.Moment
  timezone?: string
}

@Component({
  selector: 'app-overlay-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent extends Destroyable implements OnInit {
  private inquiry$ = new BehaviorSubject<Inquiry>(null)
  @Input() set inquiry(inquiry: Inquiry) {
    this.inquiry$.next(inquiry)
  }
  get inquiry() {
    return this.inquiry$.value
  }
  @Input() readonly = true
  @Input() showTimeZoneSource = true

  details: BookingDetails

  get isAirbnbAlterationInitiated() {
    const alteration = this.inquiry?.alteration_requested
    return this.isAirbnbAPIBooking && !!alteration?.external_id && lodash.toLower(alteration?.status) === 'pending'
  }

  get isAirbnbAPIBooking() {
    return isBookingFromAirbnb(this.inquiry)
  }

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private dialog: DialogService,
    private sharedDialogsService: InquirySharedDialogsService,
    private airbnbReservationAlterationDialog: AirbnbReservationAlterationDialogService,
    private inquiryService: InquiryService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([SmartDevicesGuard])
  }

  ngOnInit() {
    combineLatest([this.actions.pipe(ofType(LoadInquiryDetailsComplete)), this.inquiry$.pipe(filter(isSomething))])
      .pipe(
        take(1),
        filter(() => this.isAirbnbAlterationInitiated),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.showAirbnbGuestAlterationAlert()
      })

    this.inquiry$
      .pipe(
        filter(isSomething),
        switchMap((inquiry) =>
          this.inquiryService.prepareCheckInOut(inquiry).pipe(
            map((checkInOut) => ({
              ...inquiry,
              ...checkInOut,
              guestArrive: epochToUTCDate(inquiry.guestArrive),
              guestDepart: epochToUTCDate(inquiry.guestDepart),
            }))
          )
        ),
        untilDestroy(this)
      )
      .subscribe((details) => {
        this.details = details
      })
  }

  onUpdateDetails() {
    this.sharedDialogsService.openUpdateDetails(this.inquiry)
  }

  showAirbnbGuestAlterationAlert() {
    this.airbnbReservationAlterationDialog.open(this.inquiry)
  }

  onLinkDevice() {
    this.dialog.closeAll()
  }
}
