<div class="modal-header">
  <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i> {{ options.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-upload-box
    [options]="options"
    image="/assets/images/upload-formate.png"
    (complete)="onUploaded($event)"
    #uploadBox
  ></app-upload-box>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" [ngClass]="options?.cancelBtnClass || 'btn btn-secondary-light'">
    <i class="far fa-times"></i> cancel
  </button>
  <button
    (click)="uploadBox.upload()"
    [disabled]="uploadBox.isPendingEmpty"
    type="button"
    class="create-btn"
    [ngClass]="options?.uploadBtnClass || 'btn btn-secondary-info'"
  >
    <i class="cs-icon cs-icon-upload"></i> upload
  </button>
</div>
