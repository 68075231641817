<form [formGroup]="form">
  <div class="card p-3 pl-4 position-relative">
    <button
      type="button"
      class="btn btn-sm btn-circle btn-secondary-danger position-absolute"
      matTooltip="Delete Room"
      matTooltipPosition="above"
      (click)="isEdit() ? onDeleteRoom() : remove.emit(index)"
      style="top: 11.25rem; left: -0.85rem; z-index: 1"
    >
      <i class="cs-icon cs-icon-trash"></i>
    </button>

    <div class="row">
      <div class="col-lg-6">
        <div class="d-grid gap-3" style="grid-template-columns: repeat(3, 1fr)">
          <mat-form-field2 class="mat-block">
            <input matInput type="text" formControlName="name" placeholder="Room Name" required />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Room Name is <strong>required</strong>
            </mat-error>
          </mat-form-field2>

          <mat-form-field2 class="mat-block">
            <mat-select formControlName="type" placeholder="Room Type" required>
              <mat-option value="bedroom">Bedroom</mat-option>
              <mat-option value="bathroom">Bathroom</mat-option>
              <mat-option value="kitchen">Kitchen</mat-option>
              <mat-option value="living">Living</mat-option>
              <mat-option value="general">General</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('type').touched && form.get('type').hasError('required')">
              Room Type is <strong>required</strong>
            </mat-error>
          </mat-form-field2>

          <mat-form-field2 *ngIf="hasSubType()" class="mat-block">
            <mat-select
              formControlName="sub_type"
              placeholder="Room Sub Type"
              [required]="isRequired(form.get('sub_type'))"
            >
              <mat-option *ngFor="let type of roomTypes[getRoomType()]" [value]="type.id">{{ type.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('sub_type').touched && form.get('sub_type').hasError('required')">
              Room Sub Type is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="size_metric" placeholder="Size Metric" required>
              <mat-option value="SQFT">SQFT</mat-option>
              <mat-option value="SQM">SQM</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('size_metric').touched && form.get('size_metric').hasError('required')">
              Size Metric is <strong>required</strong>
            </mat-error>
          </mat-form-field2>

          <mat-form-field2 class="mat-block">
            <input type="text" matInput formControlName="size" placeholder="Size ({{ getSizeMetric() }})" required />
            <mat-error *ngIf="form.get('size').touched && form.get('size').hasError('required')">
              Size is <strong>required</strong>
            </mat-error>
          </mat-form-field2>

          <mat-form-field2 *ngIf="canAttachBathroom()" class="mat-block" [hidePlaceholder]="false">
            <mat-select
              formControlName="bathroom"
              matTooltip='Some channels require at least one bathroom to be set and associated to a room. Please create a room of Room Type "Bathroom" and associate it to some other room by selecting it on "Attached Bathroom" field'
              placeholder="Attached Bathroom"
            >
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let bathroom of bathrooms" [value]="bathroom.pkey">{{ bathroom.name }}</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            maxlength="500"
            rows="4"
            formControlName="description"
            placeholder="Room Description"
            required
          ></textarea>
          <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('required')">
            Room Description is <strong>required</strong>
          </mat-error>
          <mat-hint align="end">{{ form.get('description').value?.length || 0 }}/500</mat-hint>
        </mat-form-field2>
      </div>
      <div class="col-lg-6">
        <div *ngIf="hasBeds()" class="mt-4 bg-default p-3 border rounded">
          <div formArrayName="beds">
            <ng-container *ngFor="let room of bedsArray.controls; let i = index">
              <ng-container [formGroupName]="i">
                <div class="d-flex gap-3">
                  <mat-form-field2 class="mat-block">
                    <mat-label>
                      Bed Type
                      <app-page-help
                        articleId="d0sty819f7"
                        tooltipText="Click here to see bed mapping with VRBO"
                      ></app-page-help>
                    </mat-label>
                    <mat-select formControlName="bed" placeholder="Bed Type" required>
                      <mat-option *ngFor="let type of bedTypes" [value]="type.id">{{ type.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="room.get('bed').touched && room.get('bed').hasError('required')">
                      Bed type is <strong>required</strong>
                    </mat-error>
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <input type="number" matInput placeholder="Qty" required formControlName="count" />
                    <mat-error *ngIf="room.get('count').touched && room.get('count').hasError('required')">
                      Qty is <strong>required</strong>
                    </mat-error>
                  </mat-form-field2>
                  <button (click)="removeBed(i)" class="btn btn-secondary-danger align-input-row-end" type="button">
                    <i class="cs-icon cs-icon-trash"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <div class="d-flex align-items-center mt-2">
              <button (click)="addBed()" class="btn btn-secondary-info" type="button">
                <i class="far fa-plus"></i> Add Line Item
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
