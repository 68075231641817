<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <app-select
          [items]="(rateCategories$ | async) || []"
          bindLabel="name"
          bindValue="id"
          [multiple]="false"
          formControlName="rateCategory"
          placeholder="Rate Category (optional)"
        >
        </app-select>
      </mat-form-field2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Base Charge (' + currencySymbol + '):'"></span></mat-label>
        <input matInput type="number" min="0" formControlName="charge" (keyup)="onChargeChange($event.target.value)" />
      </mat-form-field2>
    </div>
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Extra Guest Fee (' + currencySymbol + '):'"></span></mat-label>
        <input type="number" [value]="cost?.extraGuests || 0" matInput disabled />
      </mat-form-field2>
    </div>
  </div>
  <h6>Discounts & Fees</h6>
  <div class="row" *ngFor="let item of cost?.auditDiscounts">
    <div class="col-md-4">
      <mat-form-field2 class="mat-block">
        <mat-label>
          {{ item.disc_type === 'lengthofstay' ? 'LOS Discount ' : 'Discount Code ' }}
          <span [innerHtml]="'Amount (' + currencySymbol + '):'"></span>
        </mat-label>
        <input type="number" [value]="item.disc_amount" matInput disabled />
        <i matSuffix class="fal fa-eye clickable" (click)="onViewDiscount(item)"></i>
      </mat-form-field2>
    </div>
  </div>
  <div class="row">
    <div
      formArrayName="discounts"
      *ngFor="let item of discounts.controls; let i = index; let last = last"
      class="col-md-4 d-flex gap-2"
    >
      <ng-container [formGroupName]="i">
        <mat-form-field2 class="mat-block">
          <mat-label><span [innerHtml]="'Amount (' + currencySymbol + '):'"></span></mat-label>
          <input type="number" (input)="updateEngineCost()" formControlName="amount" matInput />
        </mat-form-field2>
        <button
          *ngIf="last"
          (click)="removeDiscountsOrFees(i)"
          class="btn btn-secondary-danger align-input-row-end"
          matTooltip="Remove this Discount/Fee"
          type="button"
        >
          <i class="cs-icon cs-icon-trash"></i>
        </button>
      </ng-container>
    </div>
    <div class="col-md-4" *ngIf="discounts.length < discountsOrFeesLimit">
      <button
        (click)="addDiscountsOrFees()"
        class="btn btn-primary mb-3"
        [class.align-input-row-end]="discounts.length > 0"
        type="button"
      >
        <i class="far fa-plus"></i> Add Discount/Fee
      </button>
    </div>
  </div>
  <div *ngIf="fees.controls?.length > 0" class="row">
    <div class="col-md-12">
      <h6>Booking Fees</h6>
      <div formArrayName="fees" *ngFor="let item of fees.controls; let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <input type="text" formControlName="name" matInput placeholder="Fee Name:" />
            </mat-form-field2>
          </div>
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <input type="number" min="0" formControlName="rate" matInput placeholder="Fee Rate:" />
            </mat-form-field2>
          </div>
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="modality" placeholder="Modality">
                <mat-option [value]="feeModalities.PerStay">{{ feeModalityLabels[feeModalities.PerStay] }}</mat-option>
                <mat-option [value]="feeModalities.PerNight">{{
                  feeModalityLabels[feeModalities.PerNight]
                }}</mat-option>
                <mat-option [value]="feeModalities.PerPerson">{{
                  feeModalityLabels[feeModalities.PerPerson]
                }}</mat-option>
                <mat-option [value]="feeModalities.PerPersonPerNight">{{
                  feeModalityLabels[feeModalities.PerPersonPerNight]
                }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <mat-label><span [innerHtml]="'Fee Amount (' + currencySymbol + '):'"></span></mat-label>
              <input type="number" min="0" formControlName="amount" matInput />
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="taxes.controls?.length > 0" class="row">
    <div class="col-md-12">
      <h6>Taxes</h6>
      <div formArrayName="taxes" *ngFor="let item of taxes.controls; let i = index">
        <div class="row" [formGroupName]="i">
          <div class="col-md-6">
            <mat-form-field2 class="mat-block">
              <input type="text" formControlName="taxName" matInput placeholder="Tax Name:" />
            </mat-form-field2>
          </div>
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <input type="number" min="0" formControlName="taxRate" matInput placeholder="Tax Rate:" />
            </mat-form-field2>
          </div>
          <div class="col-md-3">
            <mat-form-field2 class="mat-block">
              <mat-label><span [innerHtml]="'Tax Amount (' + currencySymbol + '):'"></span></mat-label>
              <input type="number" min="0" formControlName="taxAmount" matInput />
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-active2 p-3 rounded d-flex align-items-center justify-content-between">
    <span class="text-light text-uppercase">Total:</span>
    <span
      class="text-bold"
      [innerHtml]="(cost || inquiry.bookingEngine)?.total | tokeetCurrency : inquiry?.rental?.currency"
    ></span>
  </div>
</form>

<div class="footer-control">
  <button [disabled]="loading" (click)="close.emit(true)" type="button" class="btn btn-light">
    <i class="far fa-times"></i> Cancel
  </button>
  <button
    [disabled]="loading"
    (click)="reset()"
    type="button"
    class="btn btn-secondary-light"
    matTooltip="This will update charges according to current rates added in this rental for this booking's date"
  >
    <i class="far fa-sync"></i> reset
  </button>
  <button *ngIf="!loading" (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
  <button
    *ngIf="loading"
    matTooltip="Calculating cost..."
    matTooltipPosition="above"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
