import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { SaveForm, Toaster, TOKEET_CHANNEL_RATE_CATEGORY } from '@tokeet-frontend/tv3-platform'
import { RentalRateService } from '@tv3/services/rental-rate.service'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { concatMap, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { BookingEngineCost, DiscountFee } from '@tv3/models/inquiry/booking-engine-cost'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { ChangeBookingEngineCost, ChangeBookingEngineCostComplete } from '@tv3/store/inquiry/inquiry-fields.actions'
import { BookingEngineCostRequest } from '@tv3/interfaces/requests/booking-engine-cost.request'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { DiscountDialogService } from '@tv3/containers/discounts/discount-dialog.service'
import {
  AuditDiscount,
  BookingCostResolver,
  BookingEngineBookingFee,
  BookingEngineTax,
  FeeModalities,
} from '@tokeet/cost-resolver'
import { FeeModalityLabels } from '@tokeet-frontend/bookings'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-update-quote-internal',
  templateUrl: './update-quote-internal.component.html',
  styleUrls: ['./update-quote-internal.component.scss'],
})
export class UpdateQuoteInternalComponent extends Destroyable implements OnInit {
  @Input() inquiry: Inquiry
  @Output() close = new EventEmitter()

  loading = false

  form = this.fb.group({
    rateCategory: [],
    charge: [],
    taxes: this.fb.array([
      this.fb.group({
        taxName: [{ value: '', disabled: true }],
        taxRate: [{ value: '', disabled: true }],
        taxAmount: [{ value: '', disabled: true }],
      }),
    ]),
    fees: this.fb.array([
      this.fb.group({
        name: [{ value: '', disabled: true }],
        rate: [{ value: '', disabled: true }],
        amount: [{ value: '', disabled: true }],
        modality: [{ value: '', disabled: true }],
      }),
    ]),
    discounts: this.fb.array([]),
  })

  rateCategories$: Observable<{ id: string; name: string }[]>

  fetchCost = new Subject<boolean>() // value:  ignore base charge or not when recalculate cost

  cost: BookingEngineCost

  discountsOrFeesLimit = 2

  feeModalities = FeeModalities
  feeModalityLabels = FeeModalityLabels

  timeout
  timeout2

  isCustomCharge: boolean

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private rentalRateService: RentalRateService,
    private inquiryChargeService: InquiryChargeService,
    private discountDialog: DiscountDialogService,
    private toaster: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
  }

  get discounts() {
    return this.form.get('discounts') as FormArray
  }

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  get fees() {
    return this.form.get('fees') as FormArray
  }

  get currencySymbol() {
    return R.pathOr('$', ['currency', 'symbol'], this.inquiry?.rental)
  }

  ngOnInit() {
    this.actions.pipe(ofType(ChangeBookingEngineCostComplete), untilDestroy(this)).subscribe(() => {
      this.close.emit(true)
    })

    this.rateCategories$ = this.rentalRateService.getRateCategories(this.inquiry.rentalId)

    this.setBookingEngineCost(this.inquiry.bookingEngine)
    this.setDiscounts(this.inquiry.bookingEngine)

    const cost = BookingCostResolver.parseBookingCostString(this.inquiry.cost || this.inquiry.quotes)
    this.isCustomCharge = cost.userCharge > 0

    this.fetchCost
      .pipe(
        debounceTime(1000),
        tap(() => (this.loading = true)),
        switchMap((ignoreCharge) =>
          this.inquiryChargeService.convertInquiryToBookingEngineRequest(
            this.form.getRawValue(),
            this.inquiry,
            ignoreCharge
          )
        ),
        concatMap((request) => this.inquiryChargeService.getEngineCost(request)),
        tap(() => (this.loading = false)),
        filter((cost) => isSomething(cost)),
        untilDestroy(this)
      )
      .subscribe((cost) => {
        this.cost = cost
        this.setBookingEngineCost(cost)
        this.setDiscounts(cost)
      })

    this.form
      .get('rateCategory')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroy(this))
      .subscribe(() => {
        this.fetchCost.next(true) // recalculate charge
      })

    if (!this.inquiry.bookingEngine) {
      this.fetchCost.next(!this.isCustomCharge)
    } else {
      this.cost = this.inquiry.bookingEngine
    }
  }

  onChargeChange(charge: number) {
    this.isCustomCharge = true
    if (!R.isEmpty(charge)) {
      this.fetchCost.next(false)
    }
  }

  updateEngineCost() {
    this.fetchCost.next(!this.isCustomCharge)
  }

  setBookingEngineCost(cost: BookingEngineCost) {
    if (!isSomething(cost)) {
      return
    }
    this.form.patchValue(
      {
        rateCategory: cost.rateCategory === TOKEET_CHANNEL_RATE_CATEGORY ? 'default' : cost.rateCategory,
        charge: cost.base,
      },
      { emitEvent: false }
    )
    const taxes: FormGroup[] = R.map((tax: BookingEngineTax) => this.createTaxField(tax), cost.taxes || [])
    this.form.setControl('taxes', this.fb.array(taxes))

    const fees: FormGroup[] = R.map((fee: BookingEngineBookingFee) => this.createFeeField(fee), cost.bookingFees || [])
    this.form.setControl('fees', this.fb.array(fees))
  }

  setDiscounts(cost: BookingEngineCost) {
    const discountsFormArray = this.form.get('discounts') as FormArray
    if (
      !isSomething(cost) ||
      (!lodash.isEmpty(cost.discountFees) && discountsFormArray.length === cost.discountFees?.length)
    ) {
      return
    }

    let discounts: FormGroup[] = R.map(
      (discount: DiscountFee) => this.createDiscountField(discount.amount),
      cost.discountFees || []
    )
    this.form.setControl('discounts', this.fb.array(discounts))
  }

  createDiscountField(amount?: number) {
    return this.fb.group({ amount: [R.defaultTo('', amount)] })
  }

  createTaxField(tax: BookingEngineTax) {
    return this.fb.group({
      taxName: [{ value: R.defaultTo('', tax.name), disabled: true }],
      taxRate: [{ value: R.defaultTo('', tax.rate), disabled: true }],
      taxAmount: [{ value: R.defaultTo('', tax.amount), disabled: true }],
    })
  }

  createFeeField(fee: BookingEngineBookingFee) {
    return this.fb.group({
      name: [{ value: R.defaultTo('', fee.name), disabled: true }],
      rate: [{ value: R.defaultTo('', fee.rate), disabled: true }],
      amount: [{ value: R.defaultTo('', fee.amount), disabled: true }],
      modality: [{ value: R.defaultTo('', fee.modality), disabled: true }],
    })
  }

  addDiscountsOrFees() {
    this.discounts.push(this.createDiscountField())
  }

  removeDiscountsOrFees(index: number) {
    this.discounts.removeAt(index)
    this.fetchCost.next(!this.isCustomCharge)
  }

  reset() {
    const request = {
      start: this.inquiry.guestArrive,
      end: this.inquiry.guestDepart,
      adults: this.inquiry.numAdults,
      child: this.inquiry.numChild,
      category: 'default',
      rid: this.inquiry.rentalId,
    } as BookingEngineCostRequest
    this.inquiryChargeService.getEngineCost(request).subscribe((result) => {
      this.isCustomCharge = false
      this.form.reset()
      this.setBookingEngineCost(result)
      this.setDiscounts(result)
    })
  }

  onViewDiscount(item: AuditDiscount) {
    this.discountDialog.open(item.disc_type, item.id)
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(
      ChangeBookingEngineCost({
        inquiry: this.inquiry,
        bookingEngineCost: this.cost,
        isUserCharge: this.isCustomCharge,
      })
    )
  }
}
