import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CreateTaxDialogComponent } from '@tv3/shared/dialogs/create-tax-dialog/create-tax-dialog.component'
import { Rental, TaxV3 } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class CreateTaxDialogService {
  constructor(private dialog: MatDialog) {}

  open(rental: Rental, defaults?: TaxV3) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { rental, defaults },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(CreateTaxDialogComponent, defaultConfig)
  }
}
