import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import * as R from 'ramda'
import { MultipleItemsDialogComponent } from '@tv3/containers/guests/edit/multiple-items/multiple-items-dialog.component'

@Injectable()
export class MultipleItemsDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: MatDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: 'auto',
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(MultipleItemsDialogComponent, R.merge(defaultConfig, config))
  }
}
