import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  Website,
  WebsiteTypes,
  disabledSettingsForTemplate,
  WebsiteTemplateNames,
} from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsiteTemplateSettings } from '@tv3/store/website/website.actions'

@Component({
  selector: 'app-website-template-settings',
  templateUrl: './website-template-settings.component.html',
  styleUrls: ['./website-template-settings.component.scss'],
})
export class WebsiteTemplateSettingsComponent implements OnInit {
  @Input() website: Website
  websiteTypes = WebsiteTypes
  templateNames = WebsiteTemplateNames
  disabledSettingsForTemplate: { [key: string]: boolean }

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  form = this.fb.group({
    primaryColor: [],
    fontFamily: [],
    logoTxt: [],
    logoSubTxt: [],
    logoColor: [],
    logoFontFamily: [],
    logoFontSize: [undefined, [Validators.min(1)]],
    tagline1: [],
    tagline2: [],
    tagline3: [],
    footer_desc: [],
  })

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.form.patchValue(this.website.templateSettings || {})
    this.disabledSettingsForTemplate = disabledSettingsForTemplate[this.website.template]
  }

  onColorSelected(key, color) {
    this.form.patchValue({ [key]: color })
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const payload = {
      ...this.website.serialize(),
      template_settings: { ...this.website.templateSettings, ...this.form.getRawValue() },
    }
    this.store.dispatch(UpdateWebsiteTemplateSettings({ id: this.website.id, data: payload }))
  }
}
