import { Pipe, PipeTransform } from '@angular/core'
import { asyncScheduler, Observable } from 'rxjs'
import { observeOn } from 'rxjs/operators'

@Pipe({
  name: 'asyncSchedule',
})
export class AsyncSchedulePipe implements PipeTransform {
  transform<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(observeOn(asyncScheduler))
  }
}
