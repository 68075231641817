import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { FormBuilder } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { finalize, startWith, take } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as fromRoot from '@tv3/store/state'
import { DataCheckerService, isEmptyTable, isSomething, DialogService } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import {
  Channel,
  ConfirmDialogService,
  Destroyable,
  Rental,
  selectAllRentals,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { LastMinuteAutomationDialogComponent } from '../automation-dialog/last-minute-automation-dialog.component'
import {
  DeleteLastMinuteAutomation,
  LastMinuteAutomation,
  LoadAccountRateAutomations,
  LoadAccountRateAutomationsComplete,
  RateAutomationsGuard,
  selectAllLastMinuteAutomations,
} from '@tokeet-frontend/rate-automations'

@Component({
  selector: 'app-last-minute-automations',
  templateUrl: './last-minute-automations.component.html',
  styleUrls: ['./last-minute-automations.component.scss'],
})
export class LastMinuteAutomationsComponent extends Destroyable implements OnInit {
  @Input() viewType: 'list' | 'grid' = 'list'
  tableType = TableType.LastMinuteAutomations

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  lastMinutes$ = new BehaviorSubject<LastMinuteAutomation[]>([])

  channels: Channel[] = []
  rentals: Rental[] = []
  rentalsById: Record<string, Rental> = {}

  filters = this.fb.group({
    rentalIds: [],
    period: [],
  })

  displayedColumns = ['name', 'period', 'rentals', 'adjustment', 'edit']

  dataSource = new MatTableDataSource<LastMinuteAutomation>()
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private fb: FormBuilder,
    private dialog: DialogService,
    private confirmDialog: ConfirmDialogService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([RateAutomationsGuard])
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort

    this.store
      .select(selectAllRentals)
      .pipe(untilDestroy(this))
      .subscribe((rentals) => {
        this.rentalsById = lodash.keyBy(rentals, (rental) => rental.id)
        this.rentals = rentals
      })

    combineLatest([
      this.filters.valueChanges.pipe(startWith(this.filters.getRawValue())),
      this.store.pipe(select(selectAllLastMinuteAutomations)),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([filters, items]: [any, LastMinuteAutomation[]]) => {
        items = items.filter((c) =>
          isSomething(filters.rentalIds) ? lodash.intersection(filters.rentalIds, c.rentals).length > 0 : true
        )

        this.dataSource.data = items || []
      })
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(LoadAccountRateAutomationsComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(LoadAccountRateAutomations())
  }

  getRentalNames = (rentalIds: string[]) => {
    return rentalIds
      .map((rentalId) => this.rentalsById[rentalId] && this.rentalsById[rentalId].name)
      .filter((name) => name)
      .join(', ')
  }

  onAdd() {
    this.dialog.openVariableDialog(LastMinuteAutomationDialogComponent, { width: '800px', height: 'auto' })
  }

  onEdit(lastMinute: LastMinuteAutomation) {
    this.dialog.openVariableDialog(LastMinuteAutomationDialogComponent, {
      width: '800px',
      height: 'auto',
      data: { lastMinute },
    })
  }

  onDelete(lastMinute: LastMinuteAutomation) {
    this.confirmDialog.confirm().subscribe(() => {
      this.store.dispatch(DeleteLastMinuteAutomation({ id: lastMinute.key }))
    })
  }

  clearFilters() {
    this.filters.reset()
  }
}
