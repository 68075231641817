import { Directive, OnInit, Renderer2, Input, ElementRef } from '@angular/core'
import { coerceBooleanProperty } from '@angular/cdk/coercion'

@Directive({
  selector: '[columnResizable]',
})
export class ColumnResizableDirective implements OnInit {
  @Input('columnResizable') resizable: boolean

  columnIndex: number

  private startX: number
  private startWidth: number

  private column: HTMLElement
  private table: HTMLElement

  private pressed: boolean

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.column = this.el.nativeElement
  }

  ngOnInit() {
    const resizable = coerceBooleanProperty(this.resizable)
    if (!resizable) {
      return
    }
    const row: HTMLElement = this.renderer.parentNode(this.column)
    this.columnIndex = Array.from(row.children).indexOf(this.column)
    const thead = this.renderer.parentNode(row)
    this.table = this.renderer.parentNode(thead)

    const resizer = this.renderer.createElement('div')
    this.renderer.addClass(resizer, 'column-resize-handler')
    this.renderer.appendChild(this.column, resizer)
    this.renderer.listen(resizer, 'mousedown', this.onMouseDown)
    this.renderer.listen(this.table, 'mousemove', this.onMouseMove)
    this.renderer.listen('document', 'mouseup', this.onMouseUp)
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true
    this.startX = event.pageX
    this.startWidth = this.column.offsetWidth
  }

  onMouseMove = (event: MouseEvent) => {
    const offset = 35
    if (!this.pressed || !event.buttons) {
      return
    }

    this.renderer.addClass(this.column, 'resizing')

    // Calculate width of column
    let width = this.startWidth + (event.pageX - this.startX - offset)

    const columnCells = Array.from(this.table.querySelectorAll('.mat-row')).map((row: any) =>
      row.querySelectorAll('.mat-cell').item(this.columnIndex)
    )

    // Set table header width
    this.renderer.setStyle(this.column, 'flex', `0 0 ${width}px`)

    // Set table column cells width
    for (const cell of columnCells) {
      this.renderer.setStyle(cell, 'flex', `0 0 ${width}px`)
    }
  }

  onMouseUp = (event: MouseEvent) => {
    if (!this.pressed) {
      return
    }

    this.pressed = false
    this.renderer.removeClass(this.column, 'resizing')
  }
}
