import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { InvoiceEffects } from './invoice.effects'

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([InvoiceEffects])],
  declarations: [],
})
export class InvoiceModule {}
