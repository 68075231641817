import { adapter, initialState, State } from './template.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddInvoiceTemplateComplete,
  DeleteInvoiceTemplateComplete,
  DeleteInvoiceTemplatesComplete,
  LoadInvoiceTemplatesComplete,
  UpdateInvoiceTemplateComplete,
} from './template.actions'
import { updateOne } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadInvoiceTemplatesComplete, (state, { templates }) => adapter.addAll(templates, { ...state, isLoaded: true })),
  on(AddInvoiceTemplateComplete, (state, { template }) =>
    adapter.addOne(template, {
      ...state,
      lastAddedId: template.pkey,
    })
  ),
  on(UpdateInvoiceTemplateComplete, (state, { update }) => updateOne(adapter, update, state)),
  on(DeleteInvoiceTemplateComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteInvoiceTemplatesComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function invoiceTemplateReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
