import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromBilling from './billing.reducer'
import { BillingEffects } from './billing.effects'
import { BillingService } from './billing.service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('billing', fromBilling.billingReducer),
    EffectsModule.forFeature([BillingEffects]),
  ],
  declarations: [],
  providers: [BillingService],
  exports: [],
})
export class BillingStoreModule {}
