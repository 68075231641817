import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Channel, openWindow, ChannelService, CURRENT_ACCOUNT_ID } from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject, Observable } from 'rxjs'
import { ChannelConnectHelperService } from '../channel-connect-helper.service'

const wizardCompleteStepIndex = 2

@Component({
  selector: 'app-connect-airbnb-wizard',
  templateUrl: './connect-airbnb-wizard.component.html',
  styleUrls: ['./connect-airbnb-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class ConnectAirbnbWizardComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper
  isQuickConnect = true
  isAuthorizing = false

  get channel() {
    return this.data.channel
  }

  constructor(
    public dialogRef: MatDialogRef<ConnectAirbnbWizardComponent>,
    private channelHelperService: ChannelConnectHelperService,
    private channelService: ChannelService,
    @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  onQuick() {
    this.isQuickConnect = true
    this.isAuthorizing = true
    this.airbnbAuthorizeAccess().subscribe(() => {
      this.stepper.selectedIndex = wizardCompleteStepIndex
      this.isAuthorizing = false
      this.channelHelperService.doAfterConnected(this.channel)
    })
  }

  onAdvance() {
    this.isQuickConnect = false
    this.stepper.next()
  }

  onChannel() {
    this.dialogRef.close('channel')
  }

  onConnect() {
    this.airbnbAuthorizeAccess().subscribe(() => {
      this.channelHelperService.doAfterConnected(this.channel)
      this.stepper.next()
      this.isAuthorizing = false
    })
  }

  airbnbAuthorizeAccess() {
    return new Observable((observer) => {
      const done = ({ data }) => {
        observer.next(true)
        observer.complete()
        return data === 'success'
      }
      const url = this.channelService.getAirBnBV2AuthorizationUrl(this.accountId$.value)
      openWindow(url, done, 600, 900)
    })
  }
}
