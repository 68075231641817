import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { PaymentRule } from '@tv3/store/payment-rule/payment-rule.model'

export interface State extends EntityState<PaymentRule> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<PaymentRule> = createEntityAdapter<PaymentRule>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
