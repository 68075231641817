import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { getValidationMessage } from '../validators/validation-message'
import {
  FormFieldErrorDirective,
  FormFieldLabelDirective,
  FormFieldPrefixDirective,
  FormFieldSuffixDirective,
} from './form-field.directive'

let nextFormFieldId = 0

@Component({
  selector: 'app-form-field',
  template: '',
  styles: [],
})
export class FormFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('class.form-field')
  isFormField = true

  @Input() label: string
  @Input() labelHint: string
  @Input() placeholder: string
  @Input() hint: string
  @Input() hintRight: boolean

  @Input() set value(v: any) {
    this.ctrl.setValue(v, { emitEvent: false })
  }

  @HostBinding('class.required')
  @Input()
  required: boolean

  @HostBinding('class.inline')
  @Input()
  inline: boolean

  @HostBinding('class.disabled')
  @Input()
  get disabled() {
    return this.ctrl?.disabled
  }
  set disabled(disabled: boolean) {
    if (disabled) {
      this.ctrl.disable({ emitEvent: false })
    } else {
      this.ctrl.enable({ emitEvent: false })
    }
  }

  @HostBinding('class.has-value') get hasValue() {
    return this.ctrl.value !== undefined && this.ctrl.value !== null && this.ctrl.value !== ''
  }

  get invalid() {
    return this.ngControl?.invalid || this.ctrl.invalid
  }

  @HostBinding('class.touched')
  get touched() {
    return this.ngControl ? this.ngControl.touched : this.ctrl.touched
  }

  get isTouchedInvalid(): boolean {
    return this.touched && this.invalid
  }

  @HostBinding('class.focused')
  focused = false

  @Output() valueChange = new EventEmitter<any>()

  @ContentChild(FormFieldLabelDirective) labelTpl: FormFieldLabelDirective
  @ContentChildren(FormFieldPrefixDirective, { descendants: false }) prefixes: QueryList<FormFieldPrefixDirective>
  @ContentChildren(FormFieldSuffixDirective, { descendants: false }) suffixes: QueryList<FormFieldSuffixDirective>
  @ContentChildren(FormFieldErrorDirective, { descendants: false }) errors: QueryList<FormFieldErrorDirective>

  get self() {
    return this
  }

  id = `form-field-${nextFormFieldId++}`
  destroy$ = new Subject()
  ctrl = new FormControl()

  notifyValueChange: (value: any) => void = () => {}
  notifyValueTouched = () => {}

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this
    }
  }

  ngOnInit() {
    this.ctrl.valueChanges
      .pipe(
        map((v) => this.encodeValue(v)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (value: any) => {
          this.notifyValueTouched()
          this.notifyValueChange(value)
          this.valueChange.emit(value)
        },
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  // form value to local value
  decodeValue(v: any) {
    return v
  }

  // local value to form value
  encodeValue(v: any) {
    return v
  }

  writeValue(value: any): void {
    this.ctrl.setValue(this.decodeValue(value), { emitEvent: false })
  }

  registerOnChange(fn: (value: any) => void): void {
    this.notifyValueChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.notifyValueTouched = fn
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
  }

  validationMessage(): string {
    return getValidationMessage(this.ngControl?.control || this.ctrl, this.label || this.placeholder)
  }
}
