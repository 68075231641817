import { Component, Inject, OnInit } from '@angular/core'
import { Inquiry, InquirySourceType, isBookingFromAirbnb } from '@tv3/store/inquiry/inquiry.model'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  asLocalDate,
  asUTCEpoch,
  ConfirmDialogService,
  Destroyable,
  getTimezones,
  RentalService,
  SaveForm,
  selectAllRentals,
  selectSome,
  TimePickerUtils,
  Toaster,
  untilDestroy,
  updateRentalInstructionsComplete,
} from '@tokeet-frontend/tv3-platform'
import { asyncScheduler, BehaviorSubject, merge, Observable } from 'rxjs'
import * as R from 'ramda'
import {
  ChangeBookingDetails,
  ChangeBookingDetailsComplete,
  ChangeBookingDetailsPayload,
  ChangeInquiryRentalComplete,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import * as moment from 'moment'
import { isUpdatingBooking, selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { debounceTime, filter, observeOn, startWith, switchMap } from 'rxjs/operators'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { PreferencesGuard } from '@tv3/guards/preferences.guard'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { Actions, ofType } from '@ngrx/effects'
import { AirbnbChannelService } from '@tokeet-frontend/channels'
import { AirbnbReservationAlterationDialogService } from '@tv3/containers/inquiries/overlays/airbnb-reservation-alteration-dialog/airbnb-reservation-alteration-dialog.service'
import { selectBookingSourcesWithCustomChannels } from '@tv3/store/channel/selectors'
import { selectBookingTaskViews, selectTasksLoaded } from '@tv3/store/task/task.selectors'
import { TaskGuard } from '@tv3/guards/task.guard'

@Component({
  selector: 'app-update-inquiry-details',
  templateUrl: './update-inquiry-details-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./update-inquiry-details-dialog.component.scss'],
})
export class UpdateInquiryDetailsDialogComponent extends Destroyable implements OnInit {
  inquiry: Inquiry

  timezone: string
  timezoneList = getTimezones()

  form = this.fb.group({
    rentalId: [''],
    adults: [''],
    children: [''],
    channel: [''],
    guestArrive: [''],
    guestDepart: [''],
    checkIn: [''],
    checkOut: [''],
    timezone: [R.head(this.timezoneList)],
  })

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  channels$: Observable<any>

  sourceTypes = InquirySourceType
  isAirbnbBooking = false

  minDepartDate = new BehaviorSubject<Date>(null)
  maxArriveDate = new BehaviorSubject<Date>(null)

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  isUpdatingBooking$ = this.store.pipe(observeOn(asyncScheduler), select(isUpdatingBooking))

  adultsOptions = R.range(1, 31)
  childrenOptions = R.range(0, 31)

  constructor(
    public dialogRef: MatDialogRef<UpdateInquiryDetailsDialogComponent>,
    private fb: FormBuilder,
    private actions: Actions,
    private toaster: Toaster,
    private rentalService: RentalService,
    private inquiryService: InquiryService,
    private inquiryChargeService: InquiryChargeService,
    private dataCheckerService: DataCheckerService,
    private airbnbChannelService: AirbnbChannelService,
    private airbnbReservationAlterationDialog: AirbnbReservationAlterationDialogService,
    private confirmDialog: ConfirmDialogService,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
    this.dataCheckerService.check([PreferencesGuard, TaskGuard])
  }

  ngOnInit() {
    this.inquiry = this.data.inquiry
    this.isAirbnbBooking = isBookingFromAirbnb(this.inquiry)

    this.inquiryChargeService.getCharges(this.data.inquiry).subscribe((inquiry) => {
      this.inquiry = inquiry
      this.setForm(this.inquiry)
    })

    this.setForm(this.inquiry)

    this.store.pipe(select(selectInquiry, { id: this.inquiry.id }), untilDestroy(this)).subscribe((inquiry) => {
      this.inquiry = inquiry
    })

    this.actions
      .pipe(
        ofType(ChangeInquiryRentalComplete, updateRentalInstructionsComplete.type),
        debounceTime(500),
        startWith(true),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.refreshCheckTime()
      })

    this.channels$ = this.store.pipe(
      selectSome(selectInquiry, { id: this.data.inquiry.id }),
      switchMap((inquiry) => this.store.pipe(select(selectBookingSourcesWithCustomChannels(inquiry, true))))
    )

    merge(this.form.get('guestArrive').valueChanges, this.form.get('guestDepart').valueChanges)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.setArriveDepartMinMax()
      })

    this.actions
      .pipe(
        ofType(ChangeBookingDetailsComplete),
        switchMap(() => this.store.pipe(select(selectBookingTaskViews(this.inquiry.id)))),
        untilDestroy(this)
      )
      .subscribe((tasks) => {
        if (tasks?.length) {
          this.toaster.warning('There are tasks associated with this booking, please update tasks as needed.')
        }
        this.close()
      })
  }

  refreshCheckTime() {
    this.inquiryService.prepareCheckInOut(this.inquiry, false).subscribe((checkInOut) => {
      this.timezone = checkInOut.timezone
      this.form.patchValue(
        {
          checkIn: TimePickerUtils.formatTime(checkInOut.checkIn),
          checkOut: TimePickerUtils.formatTime(checkInOut.checkOut),
          timezone: checkInOut.timezone,
        },
        { emitEvent: false }
      )

      if (!this.timezone) {
        this.form.get('checkIn').disable({ emitEvent: false })
        this.form.get('checkOut').disable({ emitEvent: false })
      } else {
        this.form.get('checkIn').enable({ emitEvent: false })
        this.form.get('checkOut').enable({ emitEvent: false })
      }
    })
  }

  editRentalTimezone() {
    this.store.dispatch(OpenRentalOverlay({ rental: this.inquiry.rental, activeTab: RentalDialogTab.Instructions }))
  }

  setForm(inquiry: Inquiry) {
    this.form.patchValue({
      rentalId: inquiry.rentalId,
      adults: inquiry.numAdults,
      children: inquiry.numChild,
      channel: (inquiry.inquirySource + '').toLowerCase(),
      guestArrive: asLocalDate(inquiry.guestArrive),
      guestDepart: asLocalDate(inquiry.guestDepart),
    })
    this.setArriveDepartMinMax()
  }

  setArriveDepartMinMax() {
    if (this.form.get('guestArrive').value) {
      const arrive = moment(this.form.get('guestArrive').value) //.add(1, 'days')
      this.minDepartDate.next(arrive.toDate())
    }

    if (this.form.get('guestDepart').value) {
      const depart = moment(this.form.get('guestDepart').value) //.subtract(1, 'days')
      this.maxArriveDate.next(depart.toDate())
    }
  }

  isChargeAffected(payload: ChangeBookingDetailsPayload) {
    return (
      this.inquiry.rentalId !== payload.rental_id ||
      this.inquiry.numAdults !== payload.num_adults ||
      this.inquiry.numChild !== payload.num_child ||
      this.inquiry.inquirySource !== payload.inquiry_source ||
      !moment(this.inquiry.guestArrive * 1000).isSame(payload.guest_arrive * 1000, 'day') ||
      !moment(this.inquiry.guestDepart * 1000).isSame(payload.guest_depart * 1000, 'day')
    )
  }

  updateAirbnbBookingDetails(payload: ChangeBookingDetailsPayload, arrive: Date, depart: Date) {
    return this.airbnbChannelService
      .getAlterationPriceQuote(this.inquiry.inquiryId, {
        start_date: moment(arrive).format('YYYY-MM-DD'),
        end_date: moment(depart).format('YYYY-MM-DD'),
        number_of_adults: payload.num_adults,
        number_of_children: payload.num_child,
        number_of_infants: 0,
        number_of_pets: this.inquiry.num_pets || 0,
      })
      .pipe(
        switchMap((data) => this.airbnbReservationAlterationDialog.open(this.inquiry, data).afterClosed()),
        filter((r) => r === 'initiated')
      )
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const data = this.form.getRawValue()
    const arrive = this.inquiryService.adjustInquiryDate(data.guestArrive)
    const depart = this.inquiryService.adjustInquiryDate(data.guestDepart)

    const getEpoch = (date: Date, time: any) => {
      const s = [moment(date).format('YYYY-MM-DD'), TimePickerUtils.to24HourTime(time)]
      return moment.tz(s.join(' '), this.timezone).unix()
    }

    const payload: ChangeBookingDetailsPayload = {
      rental_id: data.rentalId,
      num_adults: data.adults,
      num_child: data.children,
      inquiry_source: data.channel,
      guest_arrive: asUTCEpoch(arrive),
      guest_depart: asUTCEpoch(depart),
      force_checkin: true,
      check_in: getEpoch(arrive, data.checkIn),
      check_out: getEpoch(depart, data.checkOut),
    }

    const isChargeAffected = this.isChargeAffected(payload)
    this.store.dispatch(ChangeBookingDetails({ inquiry: this.inquiry, payload, isChargeAffected }))
  }

  close() {
    this.dialogRef.close()
  }
}
