import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { MatColumnDef, MatTable } from '@angular/material/table'
import { UserStorage } from '../../storage'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Destroyable } from '../../rx-operators'
import * as lodash from 'lodash'

export interface TableColumnItem {
  key: string
  label: string
  visible: boolean
  restricted?: boolean
}

@Component({
  selector: 'app-table-column-manager',
  templateUrl: './table-column-manager.component.html',
  styleUrls: ['./table-column-manager.component.scss'],
})
export class TableColumnManagerComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef

  @Input() tableId: string
  @Input() leftRestrictedColumns: string[] = ['select']
  @Input() rightRestrictedColumns: string[] = ['edit']
  @Input() displayedColumns: string[]

  @Output() columnsChanged = new EventEmitter<string[]>()
  positions = [
    //
    { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' },
    { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
  ]

  columns: TableColumnItem[] = []
  columnDefs: TableColumnItem[] = []

  isActionsMenuOpen = false

  get columnDefsByName(): Map<string, MatColumnDef> {
    return this.table['_columnDefsByName']
  }

  constructor(
    private table: MatTable<any>,
    private el: ElementRef<HTMLElement>,
    private storage: UserStorage<TableColumnItem[]>
  ) {
    super()
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.columnDefs = this.getColumnDefs()
    this.refreshColumns()
    this.tryEmitChange()
  }

  tryEmitChange() {
    const displayedColumns = this.getDisplayedColumns()
    if (!lodash.isEqual(this.displayedColumns, displayedColumns)) {
      this.columnsChanged.emit(displayedColumns)
    }
  }

  onToggleMenu(open: boolean) {
    this.isActionsMenuOpen = open
    if (open) {
      this.refreshColumns()
    }
  }

  refreshColumns() {
    this.columns = lodash.cloneDeep(this.columnDefs)
    const storedColumns = this.storage.get(this.tableId, [])
    const columns = this.columns.map((c) => {
      const storedColumn = storedColumns.find((sc) => sc.key === c.key)
      return {
        ...c,
        ...storedColumn,
      }
    })
    const storedKeys = storedColumns.map((c) => c.key)
    const left = columns.filter((c) => this.leftRestrictedColumns.includes(c.key))
    const right = columns.filter((c) => this.rightRestrictedColumns.includes(c.key))

    const orderedColumns = lodash.sortBy(
      columns.filter((c) => !this.isRestrictedColumn(c.key)),
      (c, i) => {
        const index = storedKeys.indexOf(c.key)
        return index >= 0 ? index : i
      }
    )

    this.columns = [...left, ...orderedColumns, ...right]
  }

  isRestrictedColumn(key: string) {
    return [...this.leftRestrictedColumns, ...this.rightRestrictedColumns].includes(key)
  }

  onDrop(event: CdkDragDrop<string[]>) {
    // TV4-528: hide restricted columns
    // since we use *ngIf="!item.restricted" to filter out restricted items from "cdkDrag"
    // so we need to adjust the indices from "event: CdkDragDrop"
    // by increasing the index by the size of the left restricted columns (if available)

    const adjust = this.columns.filter((c) => this.leftRestrictedColumns.includes(c.key)).length

    moveItemInArray(this.columns, event.previousIndex + adjust, event.currentIndex + adjust)
  }

  onReset() {
    this.storage.remove(this.tableId)
    this.refreshColumns()
    this.tryEmitChange()
  }

  onApply() {
    this.isActionsMenuOpen = false
    this.storage.set(this.tableId, this.columns)
    this.tryEmitChange()
  }

  getDisplayedColumns() {
    return this.columns.filter((c) => c.visible).map((c) => c.key)
  }

  getColumnDefs() {
    const colDefs = Array.from(this.columnDefsByName.entries()).filter(([key, columnDef]) => !!columnDef.headerCell)

    colDefs.forEach(([columnKey, columnDef]) => {
      if (!columnDef.headerCell) return
      const t = this.vc.createEmbeddedView(columnDef.headerCell.template, {})
      t.detach()
    })

    const keys = colDefs.map(([key]) => key)

    const labels = Array.from(this.el.nativeElement.querySelectorAll<HTMLElement>('.mat-header-cell')).map((elm) => {
      return elm.getAttribute('label') || elm.innerText.trim()
    })

    this.vc.clear()

    return keys.map((key, i) => {
      return {
        key,
        label: labels[i] || `<${lodash.toUpper(key)}>`,
        visible: true,
        restricted: this.isRestrictedColumn(key),
      }
    })
  }
}
