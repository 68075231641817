import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Rental, selectUsers, USER_ROLES, sortAscend, SaveForm, updateOwners } from '@tokeet-frontend/tv3-platform'

export interface OwnerAccessDialogConfig {
  rental: Rental
}

@Component({
  selector: 'app-owner-access-dialog',
  templateUrl: './owner-access-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./owner-access-dialog.component.scss'],
})
export class OwnerAccessDialogComponent implements OnInit {
  form = this.fb.group({
    users: [[]],
  })

  users$ = this.store.pipe(
    select(selectUsers),
    map((users) => R.filter((user) => user.roles && user.roles.indexOf(USER_ROLES.owner.value) > -1, users)),
    map((users) => sortAscend('name')(users))
  )

  constructor(
    public dialogRef: MatDialogRef<OwnerAccessDialogComponent>,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: OwnerAccessDialogConfig,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.form.patchValue({ users: this.data.rental.owners })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue() as { users: string[] }
    let users = R.defaultTo([], formValues.users)
    let owners = R.defaultTo([], this.data.rental.owners)
    const newOwners = R.filter((userId) => R.indexOf(userId, owners) === -1, users)
    const deletedOwners = R.filter((ownerId) => R.indexOf(ownerId, users) === -1, owners)

    this.store.dispatch(
      updateOwners({
        rental: this.data.rental,
        addOwnerIds: newOwners,
        removeOwnerIds: deletedOwners,
        currentOwnerIds: users,
      })
    )

    this.close()
  }
}
