import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, EMPTY, from, Observable, of, throwError } from 'rxjs'
import { Inquiry, InquiryStatus, LoadInquiriesFilter } from './inquiry.model'
import {
  catchError,
  concatMap,
  filter,
  flatMap,
  map,
  mapTo,
  switchMap,
  take,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators'
import {
  asLocalDate,
  asUTCEpoch,
  Channel,
  currencies,
  DataCheckerService,
  deserializeArray,
  isSomething,
  loadAccount,
  Rental,
  RentalService,
  selectAccount,
  selectAccountsLoaded,
  selectAllChannels,
  selectOnce,
  selectRentalById,
  selectSomeOnce,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { InquiryRequest } from '@tv3/interfaces/requests/inquiry-request'
import { InquiryResponse } from '@tv3/interfaces/responses/inquiry-response'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { Guest } from '@tv3/store/guest/guest.model'
import { InquiryBilling } from '@tv3/models/inquiry/inquiry-billing'
import { AirBnbResponseRequest } from '@tv3/interfaces/requests/air-bnb-response.request'
import * as R from 'ramda'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import * as moment from 'moment'
import { MemoizedSelector, select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Pagination } from '@tv3/interfaces/table/pagination'
import { BookingCostResolver, InquiryCost } from '@tokeet/cost-resolver'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { LoadInquiryDetailsComplete } from '@tv3/store/inquiry/inquiry.actions'
import { AuthService } from '@tv3/services/auth.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { Dictionary } from '@ngrx/entity'
import { BookingFormulaService } from '@tokeet-frontend/booking-formula'
import * as lodash from 'lodash'
import { DEFAULT_BOOKING_ARRIVAL_TIME_PADDING, DEFAULT_BOOKING_DEPARTURE_TIME_PADDING } from '@tokeet-frontend/bookings'

import { v4 as uuid } from 'uuid'
import { ChangeBookingDetailsPayload } from '@tv3/store/inquiry/inquiry-fields.actions'

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  formulas = new BehaviorSubject([])

  constructor(
    private http: HttpClient,
    private toaster: Toaster,
    private bookingFormulaService: BookingFormulaService,
    private store: Store<fromRoot.State>,
    private dataChecker: DataCheckerService,
    private auth: AuthService
  ) {}

  all(pagination: Pagination): Observable<{ inquiries: Inquiry[]; pagination: Pagination }> {
    const url = `@api/inquiry/all/?limit=${pagination.limit + 1}&skip=${pagination.skip}`

    return this.http.get<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      map((inquiries) => R.sortBy((i: Inquiry) => -i.touch, inquiries)),
      map((inquiries) => ({
        inquiries: R.take(1000, inquiries),
        pagination: {
          ...pagination,
          skip: pagination.skip + pagination.limit,
          hasMore: inquiries.length > pagination.limit,
        },
      })),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getCostAfterFormulaBatch(ids: string[]): Observable<Dictionary<number>> {
    const url = `@api/inquiry/formula`
    return this.http.post<{ [key: string]: { cost: number; formula_id: string } }>(url, ids).pipe(
      map((res) => {
        return lodash.reduce(
          res,
          (acc, value, key) => {
            if (!value.formula_id) {
              return acc
            }

            acc[key] = value.cost
            return acc
          },
          {} as Dictionary<number>
        )
      })
    )
  }

  getCostAfterFormula(inquiryId: string): Observable<number> {
    return this.getCostAfterFormulaBatch([inquiryId]).pipe(map((res) => res[inquiryId]))
  }

  getBy(filters: LoadInquiriesFilter): Observable<Inquiry[]> {
    const url = `@api/inquiry/all/`

    const paramsPlain: any = {
      rentals: filters.rentals,
    }

    if (filters.source) {
      paramsPlain.source = filters.source
    }

    if (filters.status) {
      paramsPlain[filters.status] = '1'
    }

    if (filters.period) {
      paramsPlain.arrive_from = filters.period.from
      paramsPlain.arrive_to = filters.period.to
    }

    const params = new HttpParams({ fromObject: paramsPlain })

    return this.http.get<object[]>(url, { params }).pipe(
      deserializeArray<Inquiry>(Inquiry),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  refresh(): Observable<Inquiry[]> {
    const url = `@api/inquiry/all/?limit=50&skip=0`

    return this.http.get<object[]>(url).pipe(deserializeArray<Inquiry>(Inquiry))
  }

  get(ids: string[]): Observable<Inquiry[]> {
    if (R.isEmpty(ids)) {
      return of([])
    }

    const url = `@api/inquiries/retrieve/`

    return this.http.post(url, { pkeys: ids }).pipe(
      deserializeArray<Inquiry>(Inquiry),
      map((inquiries) => R.sortBy((i: Inquiry) => -i.touch, inquiries))
    )
  }

  search(name: string): Observable<Inquiry[]> {
    const url = `@api/inquiry/all/?name=${name}&limit=100`

    return this.http.get<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  searchBookings(limit: number, offset: number, booked: number, rentalId: string): Observable<Inquiry[]> {
    const url = `@api/inquiry/all/?limit=${limit}&skip=${offset}&booked=${booked}&rental_id=${rentalId}`
    return this.http.get<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  load(id: string): Observable<Inquiry> {
    const url = `@api/inquiry/${id}?invoice_type=0,1&include_draft=1`

    return this.http.get<any>(url).pipe(
      map((inquiry) => {
        if (isSomething(inquiry.invoices)) {
          return {
            ...inquiry,
            invoices: inquiry.invoices.map((i) => ({
              ...i,
              currency: R.find((c) => c.code === i.currency?.code, currencies) || i.currency,
            })),
          }
        }
        return inquiry
      }),
      map(Inquiry.deserialize)
    )
  }

  loadPreview(id: string): Observable<Inquiry> {
    return this.store.pipe(
      selectOnce(selectInquiry, { id }),
      concatMap((inquiry) => {
        if (!isSomething(inquiry)) {
          const url = `@api/inquiry/preview/${id}`

          return this.http.get<object>(url).pipe(map(Inquiry.deserialize))
        } else {
          return of(inquiry)
        }
      }),
      tap((inquiry) => this.store.dispatch(LoadInquiryDetailsComplete({ inquiry }))),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  markPaidStatus(inquiries: Inquiry[], type: 'paid' | 'unpaid'): Observable<Inquiry[]> {
    return of(inquiries).pipe(
      flatMap((inquiry) => from(inquiry)),
      concatMap((inquiry) => {
        const cost = BookingCostResolver.parseBookingCharges(inquiry as any)
        return this.http.put(`@api/inquiry/${type}/${inquiry.id}?amt=${cost.sum}`, {}).pipe(map(Inquiry.deserialize))
      }),
      toArray(),
      tap(() => this.toaster.success(`Booking(s) marked as ${type} successfully.`))
    )
  }

  markPaid(inquiry: Inquiry): Observable<Inquiry> {
    const cost = BookingCostResolver.parseBookingCharges(inquiry as any)

    const url = `@api/inquiry/paid/${inquiry.id}?amt=${cost.sum}`

    return this.http.put<object>(url, {}).pipe(
      map(Inquiry.deserialize),
      tap(() => this.toaster.success('Booking marked as paid successfully.')),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  markUnpaid(inquiry: Inquiry): Observable<Inquiry> {
    const cost = BookingCostResolver.parseBookingCharges(inquiry as any)

    const url = `@api/inquiry/unpaid/${inquiry.id}?amt=${cost.sum}`

    return this.http.put<object>(url, {}).pipe(
      map(Inquiry.deserialize),
      tap(() => this.toaster.success('Booking marked as unpaid successfully.'))
    )
  }

  addTagsToInquiry(inquiry: Inquiry, tags: string[]): Observable<Inquiry> {
    const attributes = { ...inquiry.attributes, tags }

    return this.updateInquiry(inquiry.id, { attributes }).pipe(
      tap(() => this.toaster.success('Tags added successfully.'))
    )
  }

  addTagsToInquiries(inquiries: Inquiry[], tags: string[]): Observable<Inquiry[]> {
    return of(inquiries).pipe(
      flatMap((inquiry) => from(inquiry)),
      concatMap((inquiry) => {
        const existingTags: string[] = R.pathOr([], ['attributes', 'tags'], inquiry)
        const updatedTags: string[] = R.concat(existingTags, tags)
        const attributes = { ...inquiry.attributes, tags: R.uniq(updatedTags) }
        return this.updateInquiry(inquiry.id, { attributes })
      }),
      toArray(),
      tap(() => this.toaster.success('Tags added successfully.'))
    )
  }

  changeChannel(payload: { inquiry: Inquiry; channel: string; updateEngine: boolean }) {
    const request: any = {
      inquiry_source: payload.channel,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeBookingEngineCost(payload: { inquiry: Inquiry; bookingEngineCost: BookingEngineCost; isUserCharge?: boolean }) {
    const request = {
      booking_engine: payload.bookingEngineCost.serialize(),
    }
    return this.updateInquiryThenQuotes(payload.inquiry.id, request, payload.isUserCharge)
  }

  changeRental(payload: { inquiry: Inquiry; rentalId: string; updateEngine: boolean }) {
    const request: any = {
      rental_id: payload.rentalId,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeGuest(payload: { inquiry: Inquiry; guest: Guest }) {
    const request = {
      guest_id: payload.guest.id,
      guest_details: {
        name: payload.guest.name,
        email: payload.guest.primaryEmail,
      },
    }

    const url = `@api/inquiry/update/guest/${payload.inquiry.id}`

    return this.http.put<InquiryResponse>(url, request).pipe(
      map(Inquiry.deserialize),
      map((inquiry) => {
        inquiry.guest = payload.guest
        return inquiry
      })
    )
  }

  changeArrive(payload: { inquiry: Inquiry; arrive: number; updateEngine: boolean }) {
    const request: any = {
      guest_arrive: payload.arrive,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeDepart(payload: { inquiry: Inquiry; depart: number; updateEngine: boolean }) {
    const request: any = {
      guest_depart: payload.depart,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeAdults(payload: { inquiry: Inquiry; adults: number; updateEngine: boolean }) {
    const request: any = {
      num_adults: payload.adults,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeChildren(payload: { inquiry: Inquiry; children: number; updateEngine: boolean }) {
    const request: any = {
      num_child: payload.children,
    }

    if (payload.updateEngine) {
      request.update_engine = true
      return this.updateInquiryThenQuotes(payload.inquiry.id, request)
    }

    return this.updateInquiry(payload.inquiry.id, request)
  }

  changeBookingDetails(inquiry: Inquiry, payload: ChangeBookingDetailsPayload, updateEngine: boolean) {
    if (updateEngine) {
      return this.updateInquiryThenQuotes(inquiry.id, { ...payload, update_engine: updateEngine })
    }
    return this.updateInquiry(inquiry.id, payload)
  }

  updateInquiry(inquiryId: string, request: any): Observable<Inquiry> {
    const url = `@api/inquiry/update/${inquiryId}`
    return this.http.put<InquiryResponse>(url, request).pipe(map(Inquiry.deserialize))
  }

  updateCheckIn(inquiryId: string, checkIn: any) {
    const url = `@api/inquiry/update/checkin/${inquiryId}`
    return this.http.put<InquiryResponse>(url, checkIn).pipe(map(Inquiry.deserialize))
  }

  updateCheckOut(inquiryId: string, checkOut: any) {
    const url = `@api/inquiry/update/checkout/${inquiryId}`
    return this.http.put<InquiryResponse>(url, checkOut).pipe(map(Inquiry.deserialize))
  }

  updateInquiryThenQuotes(inquiryId: string, request: any, isUserCharge?: boolean) {
    return this.updateInquiry(inquiryId, request).pipe(
      map((inquiry) => ({
        inquiry,
        quotes: BookingCostResolver.convertBookingEngine2Cost(
          inquiry.bookingEngine,
          isUserCharge ? inquiry.bookingEngine.base : null
        ),
      })),
      switchMap(({ inquiry, quotes }) => {
        const quotesRequest: { quotes: string; cost?: string } = { quotes: JSON.stringify(quotes) }
        if (inquiry.cost) {
          quotesRequest.cost = JSON.stringify(quotes)
        }
        return this.updateInquiry(inquiryId, quotesRequest)
      })
    )
  }

  add(request: InquiryRequest, silent = false): Observable<Inquiry> {
    const url = '@api/inquiry/'

    return this.http.post<InquiryResponse>(url, request).pipe(
      tap(() => {
        if (!silent) this.toaster.success('Inquiry created successfully.')
      }),
      map((response) => Inquiry.deserialize(response))
    )
  }

  link(ids: string[]): Observable<string[]> {
    const url = '@api/inquiry/link/'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries linked successfully.')))
  }

  followup(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/followup'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries marked for followup successfully.')))
  }

  nofollowup(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/nofollowup'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries marked for no followup successfully.')))
  }

  followUpInquiry(inquiry: Inquiry) {
    const url = `@api/inquiry/followup/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap((inq) => {
        this.toaster.success(`${name} marked for follow-up successfully.`)
      }),
      catchError(() => {
        this.toaster.error(`Unable to mark ${name} for follow-up.`)
        return EMPTY
      })
    )
  }

  unReadInquiry(inquiry: Inquiry) {
    const url = `@api/inquiry/unread/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap((inq) => {
        this.toaster.success(`${name} marked as unread successfully.`)
      }),
      catchError(() => {
        this.toaster.error(`Unable to mark ${name} as unread.`)
        return EMPTY
      })
    )
  }

  readInquiry(inquiry: Inquiry, silent = false): Observable<Inquiry> {
    const url = `@api/inquiry/read/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap((inq) => {
        if (!silent) {
          this.toaster.success(`${name} marked as read successfully.`)
        }
      }),
      catchError(() => {
        if (!silent) {
          this.toaster.error(`Unable to mark ${name} as read.`)
        }
        return of(null)
      })
    )
  }

  readInquiryNote(inquiryId: string) {
    const url = '@api/inquiry/notes_read'
    return this.http.put(url, { inquiries: [inquiryId] })
  }

  unLinkInquiry(inquiry: Inquiry): Observable<Inquiry> {
    const url = `@api/inquiry/unlink/${inquiry.id}`

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap(() => this.toaster.success('Inquiries unlinked successfully'))
    )
  }

  archiveInquiry(inquiry: Inquiry): Observable<Inquiry> {
    const url = `@api/inquiry/archive/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap(() => this.toaster.success(`${name} archived successfully`)),
      catchError(() => {
        this.toaster.error(`Unable to archive ${name}`)
        return EMPTY
      })
    )
  }

  unArchiveInquiry(inquiry: Inquiry): Observable<Inquiry> {
    const url = `@api/inquiry/unarchive/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap((inq) => {
        this.toaster.success(`${name} unarchived successfully`)
      }),
      catchError(() => {
        this.toaster.error(`Unable to un-archive ${name}`)
        return EMPTY
      })
    )
  }

  noFollowUpInquiry(inquiry: Inquiry): Observable<Inquiry> {
    const url = `@api/inquiry/nofollowup/${inquiry.id}`
    const name = inquiry.booked ? 'Booking' : 'Inquiry'

    return this.http.put<InquiryResponse>(url, { id: inquiry.id }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap((inq) => {
        this.toaster.success(`${name} marked for no follow-up successfully.`)
      }),
      catchError(() => {
        this.toaster.error(`Unable to mark ${name} for no follow-up.`)
        return EMPTY
      })
    )
  }

  archive(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/archive'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries archived successfully.')))
  }

  unarchive(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/unarchive'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries unarchived successfully.')))
  }

  setGuestPortalAccessible(inquiryId: string, disabled: boolean) {
    const url = `@api/inquiry/update/${inquiryId}`
    return this.http
      .put<InquiryResponse>(url, { disable_guest_portal: disabled ? 1 : 0 })
      .pipe(map(Inquiry.deserialize))
  }

  read(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/read'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries read successfully.')))
  }

  unread(ids: string[]): Observable<string[]> {
    const url = '@api/inquiries/unread'

    return this.http
      .put<string[]>(url, { inquiries: ids })
      .pipe(tap(() => this.toaster.success('Inquiries unread successfully.')))
  }

  delete(inquiryIds: string[], guest: boolean): Observable<string[]> {
    const url = `@api/inquiries/delete?guest=${guest ? 1 : 0}`

    return this.http
      .put<string[]>(url, { inquiries: inquiryIds })
      .pipe(tap(() => this.toaster.success('Inquiries deleted successfully.')))
  }

  deleteInquiry(payload: { inquiry: Inquiry; deleteGuest: boolean }): Observable<string> {
    let url = `@api/inquiry/delete/${payload.inquiry.id}`
    if (payload.deleteGuest) {
      url += '?guest=1'
    }

    return this.http.delete<InquiryResponse>(url).pipe(
      map(() => payload.inquiry.id),
      tap(() => this.toaster.success('Inquiry deleted successfully.'))
    )
  }

  addCard(inquiry: Inquiry, request): Observable<InquiryBilling> {
    const url = `@api/inquiry/card/add/${inquiry.id}`

    return this.http.put(url, request).pipe(
      map((response) => InquiryBilling.deserialize(response)),
      tap(() => this.toaster.success('Credit card saved successfully'))
    )
  }

  deleteCard(inquiry: Inquiry) {
    const url = `@api/inquiry/card/delete/${inquiry.id}`

    return this.http
      .put(url, { id: inquiry.id })
      .pipe(tap(() => this.toaster.success('Credit card deleted successfully')))
  }

  getSecurityDoorCode(inquiryId: string) {
    const url = `@api/inquiry/security/doorcode/${inquiryId}`

    return this.http.get<{ access: { door_code: string } }>(url).pipe(map((response) => response?.access))
  }

  changeAirBnBResponse(inquiry: Inquiry, status: InquiryStatus) {
    const url = `@api/abb/inquiry/response`

    const request = {
      inquiry_id: inquiry.inquiryId,
      convo_id: inquiry.convoId,
      ref_id: inquiry.refId,
      rental_id: inquiry.rentalId,
      response: status,
    } as AirBnbResponseRequest

    return this.http.post(url, request)
  }

  getQuotes(inquiry: Inquiry) {
    try {
      return JSON.parse(inquiry.quotes)
    } catch (error) {
      return null
    }
  }

  adjustInquiryDate(date: Date, hour?: number) {
    // TV2: inquiry dates = start of the date + 11 hours
    const m = moment.utc(asUTCEpoch(date) * 1000)
    const epoch = !lodash.isNumber(hour) ? m.unix() : m.startOf('date').add(hour, 'hours').unix()
    return asLocalDate(epoch)
  }

  createAddInquiryPayload({
    form,
    quotes,
    cost,
  }: {
    form: AddInquiryForm
    quotes: InquiryCost
    cost: BookingEngineCost
  }): InquiryRequest {
    const arrive = this.adjustInquiryDate(form.arriveDate, DEFAULT_BOOKING_ARRIVAL_TIME_PADDING)
    const depart = this.adjustInquiryDate(form.departDate, DEFAULT_BOOKING_DEPARTURE_TIME_PADDING)

    return {
      name: form.guestName,
      inquiry_source: form.source || 'tokeet',
      guest_arrive: asUTCEpoch(arrive),
      guest_depart: asUTCEpoch(depart),
      replyto: form.guestEmail,
      rental_id: form.rentalId,
      convo_id: uuid(),
      quotes: JSON.stringify(quotes),
      booking_engine: cost.serialize(),
      num_adults: isSomething(form.adults) ? form.adults : 0,
      num_child: isSomething(form.children) ? form.children : 0,
      guest_details: {
        name: form.guestName,
        email: form.guestEmail,
      },
      received_on: new Date().getTime() / 1000,
      guest_id: form.guestId,
      attributes: {
        color: form.color,
      },
    } as InquiryRequest
  }

  prepareCheckInOut(
    inquiry: Inquiry,
    silent = true
  ): Observable<{
    checkIn: moment.Moment
    checkOut: moment.Moment
    timezone: string
    rental: Rental
  }> {
    if (!isSomething(inquiry.rentalId)) {
      if (!silent) {
        const guestName = R.pathOr('', ['guestDetails', 'name'], inquiry)
        this.toaster.error(`Inquiry by ${guestName} is not associated with any rental.`)
      }
      return EMPTY
    }
    return this.store.pipe(
      selectSomeOnce(selectRentalById(inquiry.rentalId)),
      switchMap((rental) => {
        return this.dataChecker
          .guardDataLoadedBySelector(selectAccountsLoaded as MemoizedSelector<fromRoot.State, boolean>, () =>
            loadAccount()
          )
          .pipe(mapTo(rental))
      }),
      filter(() => isSomething(this.auth.user)),
      withLatestFrom(this.store.select(selectAccount(this.auth.user.account))),
      map(([rental, account]) => ({
        checkIn: RentalService.getCheckInTime(rental, inquiry.checkIn, account),
        checkOut: RentalService.getCheckOutTime(rental, inquiry.checkOut, account),
        timezone: RentalService.getTimeZone(rental, account),
        rental,
      }))
    )
  }
}
