<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header print-hide">
      <div class="col-sm-12 d-flex justify-content-between align-items-center">
        <h6 class="modal-title title">Print Booking Preview</h6>
        <div class="btn-holder">
          <button (click)="onPrint()" type="button" class="btn btn-secondary-secondary ml-2">
            <span class="fal fa-print" aria-hidden="true"></span> Print
          </button>
        </div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body" id="booking-print-preview">
      <div class="mainbody" #preview>
        <table class="content-box" cellpadding="0" cellspacing="0">
          <tr>
            <td class="sidebar">
              <h2 class="title">Guest Details</h2>
              <table cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    Name<br />
                    <strong> {{ inquiry?.guest?.name || '-' }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Telephone<br />
                    <strong> {{ inquiry?.guest?.phone || '-' }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Email<br />
                    <strong> {{ inquiry?.guest?.primaryEmail || '-' }}&nbsp; </strong>
                  </td>
                </tr>
              </table>

              <h2 class="title">Booking Details</h2>
              <table cellpadding="0" cellspacing="0">
                <tr>
                  <td colspan="2">
                    Rentals<br />
                    <strong> {{ inquiry?.rentalView }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Adults:<br />
                    <strong> {{ inquiry?.numAdults }}&nbsp; </strong>
                  </td>
                  <td>
                    Children:<br />
                    <strong> {{ inquiry?.numChild }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    From:<br />
                    <strong> {{ inquiry?.arriveView }}&nbsp; </strong>
                  </td>
                  <td>
                    To:<br />
                    <strong> {{ inquiry?.departView }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Stay<br />
                    <strong> {{ inquiry?.nightsView + 1 }} Days, {{ inquiry?.nightsView }} Nights </strong>
                  </td>
                  <td>
                    Timezone<br />
                    <strong> {{ (checkInOut$ | async).timezone || '-' }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Check-In:<br />
                    <strong> {{ (checkInOut$ | async).checkIn | epoch : 'h:mm A' }}&nbsp; </strong>
                  </td>
                  <td>
                    Check-Out:<br />
                    <strong> {{ (checkInOut$ | async).checkOut | epoch : 'h:mm A' }}&nbsp; </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Source:<br />
                    <strong> {{ inquiry?.inquirySource || '-' }}&nbsp; </strong>
                  </td>
                </tr>
              </table>

              <h2 class="title">Booking Charges</h2>
              <ng-container *ngIf="external || !inquiry?.bookingEngine">
                <table cellpadding="0" cellspacing="0" *ngIf="bookingCharge$ | async; let bookingCharge">
                  <tr>
                    <td>
                      Base Charge:<br />
                      <strong> {{ bookingCharge.charge | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Additional Guests Fee:<br />
                      <strong> {{ bookingCharge?.extraGuests || 0 | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Discounts & Fees:<br />
                      <strong>
                        {{
                          (bookingCharge?.discountSum || 0) + (bookingCharge?.feeSum || 0) | tokeetCurrency : currency
                        }}&nbsp;
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Taxes:<br />
                      <strong> {{ bookingCharge?.taxFee || 0 | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr *ngIf="formulaAdjustedCost | async as formulaCost">
                    <td>
                      Booking Formula:<br />
                      <strong> {{ formulaCost | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total</strong><br />
                      <strong style="color: #d44a48; font-size: 16px">
                        {{ bookingCharge?.sum | tokeetCurrency : currency }}&nbsp;
                      </strong>
                    </td>
                  </tr>
                </table>
              </ng-container>
              <ng-container *ngIf="!external && !!inquiry?.bookingEngine">
                <table cellpadding="0" cellspacing="0" *ngIf="inquiry?.bookingEngine as bookingEngine">
                  <tr>
                    <td>
                      Base Charge:<br />
                      <strong> {{ bookingEngine.extraBedroomsBase | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Additional Guests Fee:<br />
                      <strong> {{ bookingEngine?.extraGuests || 0 | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr *ngIf="!!bookingEngine?.extraBedrooms">
                    <td>
                      Additional Bedrooms Fee:<br />
                      <strong> {{ bookingEngine?.extraBedrooms || 0 | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Discounts & Fees:<br />
                      <strong> {{ bookingEngine?.discountTotal | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr *ngFor="let fee of bookingEngine?.bookingFees">
                    <td>
                      {{ fee.name }}:<br />
                      <strong> {{ fee.amount | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Taxes:<br />
                      <strong> {{ bookingEngine?.taxTotal || 0 | tokeetCurrency : currency }}&nbsp; </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total</strong><br />
                      <strong style="color: #d44a48; font-size: 16px">
                        {{ bookingEngine?.total | tokeetCurrency : currency }}&nbsp;
                      </strong>
                    </td>
                  </tr>
                </table>
              </ng-container>
            </td>
            <td class="content-holder">
              <table class="box-holder" cellpadding="0" cellspacing="0">
                <tr>
                  <td width="100%">
                    <table class="invoice-table-holder" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>
                          <h2 class="title mx-2">Invoices</h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="simple-table table table-striped" cellpadding="0" cellspacing="0">
                            <thead>
                              <tr>
                                <th>Invoice #</th>
                                <th>Invoice Date</th>
                                <th>Due Date</th>
                                <th>Status</th>
                                <th>Amount Due</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let invoice of invoices">
                                <td>
                                  <strong>{{ invoice.invoiceNum }}</strong>
                                </td>
                                <td>{{ invoice.invoiceDate | epoch : 'DD - MMM - YYYY' }}</td>
                                <td>{{ invoice.dueDate | epoch : 'DD - MMM - YYYY' }}</td>
                                <td>{{ invoice.status | invoiceStatus }}</td>
                                <td>
                                  {{ invoice.invoiceItems | invoiceTotal : true : guests | tokeetCurrency : currency }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h2 class="title mx-2">Flights Detail</h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="simple-table table table-striped" cellpadding="0" cellspacing="0">
                            <thead>
                              <tr>
                                <th>Flight No.</th>
                                <th>Airline</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Timezone</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let flight of flights$ | async">
                                <td>
                                  <strong>{{ flight.flightNum }}</strong>
                                </td>
                                <td>{{ flight.airline | airlineCode }}</td>
                                <td>{{ flight.departure | epochUTC : 'DD - MMM - YYYY' }}</td>
                                <td>{{ flight.departureTimeView }}</td>
                                <td>{{ flight.timezone }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h2 class="title mx-2">Notes</h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table class="simple-table table table-striped" cellpadding="0" cellspacing="0">
                            <thead>
                              <tr>
                                <th>Date Added</th>
                                <th>User</th>
                                <th width="60%" style="text-align: left">Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let note of inquiry?.notes">
                                <td>{{ note.timestamp | epoch }}</td>
                                <td>{{ note.user }}</td>
                                <td width="60%" style="text-align: left">{{ note.note }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
