import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import * as R from 'ramda'
import { Account, AccountAttributes, BillingAddress } from './account.models'
import { RentalCheckTime, TaxV3 } from '../rental/rental.models'
import { Toaster } from '../../services/toaster.service'
import { AccountForm, AccountResponse, AccountUpdateRequest } from './account.interfaces'
import { ENVIRONMENT } from '../../tokens'
import { ConfirmDialogService } from '../../dialogs/confirm/confirm-dialog.service'
import { AlertDialogService } from '../../dialogs/alert/alert-dialog.service'

@Injectable()
export class AccountService {
  constructor(
    private http: HttpClient,
    private toast: Toaster,
    private confirmDialog: ConfirmDialogService,
    private alertDialog: AlertDialogService,
    @Inject(ENVIRONMENT) private environment
  ) {}

  get(): Observable<Account> {
    const url = '@api/account/trusted/detail'

    return this.http.get<AccountResponse>(url).pipe(map((response) => Account.deserialize(response)))
  }

  delete() {
    const url = '@api/account/'

    return this.http.delete(url).pipe(
      tap(() => {
        this.toast.success('Account deleted successfully.')
      }),
      catchError((res) => {
        const error = R.pathOr('', ['data', 'error', res])
        this.toast.error(`Unable to delete account - ${error}`)
        return throwError({ error })
      })
    )
  }

  update(payload: { account: AccountForm; updateAll?: boolean }): Observable<Account> {
    const url = '@api/account/detail'

    let request: AccountUpdateRequest = { ...payload.account }

    if (payload.updateAll) {
      request = {
        ...request,
        update_all: 1,
      }
    }

    return this.http.put<AccountResponse>(url, request).pipe(
      map((response) => Account.deserialize(response)),
      tap(() => this.toast.success('Account information updated successfully.'))
    )
  }

  updateBillingAddress(addresses: BillingAddress[]): Observable<Account> {
    const url = '@api/account/detail'
    return this.http.put<AccountResponse>(url, { invoice_billing_addresses: addresses }).pipe(
      map((response) => Account.deserialize(response)),
      tap(() => this.toast.success('Billing address updated successfully.'))
    )
  }

  updateAttributes(attributes: Partial<AccountAttributes>) {
    const url = '@api/account/detail'

    return this.get().pipe(
      switchMap((account) => {
        attributes = { ...(account.attributes || {}), ...attributes }
        return this.http.put<AccountResponse>(url, { attributes })
      }),
      map((response) => Account.deserialize(response))
    )
  }

  updateCheckTime(payload: { checkin: RentalCheckTime; checkout: RentalCheckTime }): Observable<Account> {
    const attributes: Partial<AccountAttributes> = {
      checkin: payload.checkin || null,
      checkout: payload.checkout || null,
    }

    return this.updateAttributes(attributes).pipe(
      tap(() => this.toast.success('Account check-in / check-out times updated successfully.'))
    )
  }

  updateTaxes(payload: { taxes: TaxV3[] }): Observable<Account> {
    const attributes: Partial<AccountAttributes> = {
      taxes: payload.taxes,
    }

    return this.updateAttributes(attributes).pipe(tap(() => this.toast.success('Taxes updated successfully.')))
  }

  confirmDomainChange(domain: string) {
    return this.confirmDialog
      .confirm({
        title: 'Update Domain?',
        body: 'This will change your AdvanceCM domain. All of your rental email addresses will also change to reflect this new domain. You must update the email address in your channels, on your website, and everywhere else you have used your AdvanceCM email addresses. Do you really want to change your domain?',
      })
      .pipe(map(() => domain))
  }

  isDomainAvailable(domain: string) {
    const url = `@api/user/domaincheck/${domain}`

    return this.http.get(url).pipe(
      map(() => true),
      catchError(() => of(false))
    )
  }

  checkDomainRemote(domain: string) {
    const url = `@api/user/domaincheck/${domain}`

    return this.http.get(url).pipe(
      map(() => domain),
      catchError((error) => {
        this.toast.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  updateDomain(domain: string) {
    const url = `@api/account/domain`

    return this.http.put<AccountResponse>(url, { domain }).pipe(
      map((response) => Account.deserialize(response)),
      tap(() => this.toast.success('Domain updated successfully.'))
    )
  }

  updateMFA(enabled: 0 | 1) {
    const url = `@api/account/mfa`

    return this.http.put(url, { enable_2fa: enabled }).pipe(tap(() => this.toast.success('MFA updated successfully.')))
  }

  checkDomainLocal(domain): Observable<string> {
    const topDomain = this.environment.topDomain
    const subDomainLength = domain.length
    const topDomainLength = topDomain.length

    // The domain name should be a-z | A-Z | 0-9
    // The domain name should between 1 and 10 characters long
    // The domain name should end with topDomain
    const validDomain = new RegExp('^[A-Za-z0-9]{2,10}' + topDomain + '$')

    // Require at least a 3 character subdomain and top domain must be at end
    if (domain.substring(0, domain.indexOf('.')).length < 2) {
      this.alertDialog.open({
        title: `Something isn't right`,
        body: 'Your AdvanceCM subdomain must be at least 2 characters long.',
      })
      return EMPTY
    } else if (domain.indexOf(topDomain) < 2 || domain.indexOf(topDomain) < subDomainLength - topDomainLength) {
      this.alertDialog.open({
        title: `Something isn't right`,
        body: `Your AdvanceCM domain must end with <b>${topDomain}</b>.`,
      })
      return EMPTY
    } else if (!validDomain.test(domain)) {
      this.alertDialog.open({
        title: `Some characters are invalid`,
        body: `Your AdvanceCM domain contains invalid characters or is too long. The subdomain should be a maximum of 10 characters, not including the <b>${topDomain}</b> portion. It should also contain alphanumeric characters only.`,
      })
      return EMPTY
    }
    return of(domain)
  }
}
