import { Connection, ConnectionTypes, defaultUndefinedRoomId } from '@tv3/store/connection/connection.model'
import * as moment from 'moment'
import { get, isNumber, max } from 'lodash'
import * as R from 'ramda'
import { Channel, ChannelNameTokens, Rental } from '@tokeet-frontend/tv3-platform'
import { isFeedChannel, isTiketLikeChannel } from '@tokeet-frontend/channels'

export enum ConnectionTypeTexts {
  API = 'API',
  Exported = 'Exported iCal',
  Imported = 'Imported iCal',
}

export enum ConnectionStatusTexts {
  Active = 'Active',
  Disabled = 'Disabled',
  Pending = 'Pending',
  Stale = 'Stale',
}

export class ConnectionView extends Connection {
  isApi: boolean
  isICal: boolean
  isFeedApi: boolean
  isExported: boolean
  isImported: boolean
  isCreatedOnTokeet: boolean
  isIncompleted: boolean
  statusText: ConnectionStatusTexts

  /* View Fields */
  linkDateView: string
  createdView: string
  typeText: string
  accountName: string
  warning = false
  channelFriendlyName: string
  customICalChannelName: string

  linkDateOrCreated: number
  linkDateOrCreatedView: string

  propertyNameView: string

  constructor(public channel: Channel, public rental: Rental, public connection: Connection) {
    super(connection)

    this.name = this.name || this.source
    this.isApi = R.pipe(R.defaultTo(''), R.toLower, R.equals(R.toLower(ConnectionTypes.Api)))(connection.type)
    this.isICal = !this.isApi
    this.isFeedApi = this.isApi && isFeedChannel(this.channel?.name)
    this.isImported = R.pipe(
      R.defaultTo(''),
      R.toLower,
      R.equals(R.toLower(ConnectionTypes.ImportedICal))
    )(connection.type)
    this.isExported = R.pipe(
      R.defaultTo(''),
      R.toLower,
      R.equals(R.toLower(ConnectionTypes.ExportedICal))
    )(connection.type)

    this.typeText =
      (this.isApi && ConnectionTypeTexts.API) ||
      (this.isImported && ConnectionTypeTexts.Imported) ||
      (this.isExported && ConnectionTypeTexts.Exported) ||
      ''

    const format = 'DD - MMM - YYYY'
    this.linkDateView =
      (!this.linkDate && 'Never') || (isNumber(this.linkDate) && moment(this.linkDate * 1000).format(format)) || '...'
    this.createdView =
      (!this.created && 'Never') || (isNumber(this.created) && moment(this.created * 1000).format(format)) || '...'

    this.customICalChannelName = R.pathOr('Unknown', ['attributes', 'channel_display_name'], this)
    if (channel) {
      // this.channelId = channel.id; // warning: the channel id in connection and channel maybe different.
      this.channelFriendlyName =
        channel.name === ChannelNameTokens.UnknownICal ? this.customICalChannelName : channel.friendlyName
    } else {
      this.channelFriendlyName = this.source
    }
    if (rental) {
      this.rentalName = rental.name
      this.lastratepush = max([this.lastratepush, get(rental, 'attributes.last_rateengine_update')])
    }
    this.accountName = this.propertyName || this.channelFriendlyName
    this.propertyNameView = (this.isApi && this.roomName) || (this.isImported && this.name) || 'N/A'

    if (this.isApi) {
      this.isIncompleted = !this.propertyId || !this.roomId || this.roomId === defaultUndefinedRoomId
      this.linkDateOrCreated = this.linkDate
      this.linkDateOrCreatedView = this.linkDateView
    } else {
      this.linkDateOrCreated = this.created
      this.linkDateOrCreatedView = this.createdView
    }

    this.statusText = this.getStatusText()
    this.setWarning()
    this.lastsync = max([this.lastsync, this.lastavailpull, this.lastavailpush, this.lastratepush, this.created])
  }

  setWarning() {
    this.warning = this.statusText !== ConnectionStatusTexts.Active
  }

  private getStatusText(): ConnectionStatusTexts {
    if (this.channel?.name === ChannelNameTokens.Hvmi) {
      return this.status_text as ConnectionStatusTexts
    }
    if (isTiketLikeChannel(this.channel?.name)) {
      this.status = 1
    }

    if (this.isApi) {
      // tslint:disable-next-line:triple-equals
      if (this.status == 0) {
        return ConnectionStatusTexts.Disabled
      } else if (isApiConnectionActive(this, this) || isFeedChannel(this.channel?.name)) {
        return ConnectionStatusTexts.Active
      } else {
        return ConnectionStatusTexts.Pending
      }
    } else {
      if (!this.lastsync) {
        return ConnectionStatusTexts.Pending
      } else if (R.is(Number, this.status) && this.status <= 0) {
        return ConnectionStatusTexts.Disabled
      }

      const now = moment.utc().unix()
      let failed, pending
      if (this.isImported) {
        failed = 24 * 60 * 60
        pending = 6 * 60 * 60
      } else {
        failed = 4 * 24 * 60 * 60 // iCal is stale after 4 days of not being fetched by the channel.
        pending = 24 * 60 * 60
      }

      if (now >= this.lastsync + failed) {
        return ConnectionStatusTexts.Stale
      } else if (now < this.lastsync + pending) {
        return ConnectionStatusTexts.Active
      }

      return ConnectionStatusTexts.Pending
    }
  }
}

export function isApiConnectionActive(
  connection: Connection,
  status: { lastavailpull?: number; lastavailpush?: number; lastratepush?: number }
): boolean {
  if (!connection.rentalId) {
    return false
  }

  if (connection.name === ChannelNameTokens.BookingAPI) {
    return !!(status && status.lastavailpull && status.lastavailpush && status.lastratepush)
  }

  if (isTiketLikeChannel(connection.name)) {
    return !!(status && status.lastavailpush && status.lastratepush)
  }

  return !!(status && (status.lastavailpull || status.lastavailpush || status.lastratepush))
}
