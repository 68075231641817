import { createAction, props } from '@ngrx/store'
import { TokeetImage } from '@tv3/store/website-image/website-image.model'
import { Update } from '@ngrx/entity'
import { Attachment, TokeetImagePayload } from '@tokeet-frontend/tv3-platform'

export const LoadWebsiteImages = createAction('[Website] Load Website Images', props<{ websiteId: string }>())
export const LoadWebsiteImagesComplete = createAction(
  '[Website] Load Website Images Complete',
  props<{ images: TokeetImage[] }>()
)

export const AddWebsiteImages = createAction(
  '[Website] Add Website Image',
  props<{ websiteId: string; data: TokeetImagePayload[] }>()
)
export const AddWebsiteImagesComplete = createAction(
  '[Website] Add Website Image Complete',
  props<{ images: TokeetImage[] }>()
)

export const UpdateWebsiteImage = createAction(
  '[Website] Update Website Image',
  props<{ imageId: string; data: any }>()
)
export const UpdateWebsiteImageComplete = createAction(
  '[Website] Update Website Image Complete',
  props<{ update: Update<TokeetImage> }>()
)

export const DeleteWebsiteImage = createAction('[Website] Delete Website Image', props<{ imageId: string }>())
export const DeleteWebsiteImageComplete = createAction(
  '[Website] Delete Website Image Complete',
  props<{ id: string }>()
)

export const UpdateWebsiteImageOrders = createAction(
  '[Website] Update Website Image Orders',
  props<{ orderByIds: { [id: string]: number } }>()
)
export const UpdateWebsiteImageOrdersComplete = createAction(
  '[Website] Update Website Image Orders Complete',
  props<{ updates: Update<TokeetImage>[] }>()
)

export const MarkWebsiteImageAsPrimary = createAction(
  '[Website] Mark Website Image As Primary',
  props<{ websiteId: string; imageId: string; images: TokeetImage[] }>()
)
export const MarkWebsiteImageAsPrimaryComplete = createAction(
  '[Website] Mark Website Image As Primary Complete',
  props<{ update: Update<TokeetImage> }>()
)

export const MarkWebsiteImageAsFavicon = createAction(
  '[Website] Mark Website Image As Favicon',
  props<{ websiteId: string; imageId: string; images: TokeetImage[] }>()
)
export const MarkWebsiteImageAsFaviconComplete = createAction(
  '[Website] Mark Website Image As Favicon Complete',
  props<{ update: Update<TokeetImage> }>()
)

export const MarkWebsiteImageAsLogo = createAction(
  '[Website] Mark Website Image As Logo',
  props<{ websiteId: string; imageId: string; images: TokeetImage[] }>()
)
export const MarkWebsiteImageAsLogoComplete = createAction(
  '[Website] Mark Website Image As Logo Complete',
  props<{ update: Update<TokeetImage> }>()
)
