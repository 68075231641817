import { Component, Inject, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Website, WebsiteTypes, isTokeetWebsiteDomain } from '@tv3/store/website/website.model'
import { DeleteWebsiteComplete } from '@tv3/store/website/website.actions'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { WebsitesHelperService } from '@tv3/containers/websites/websites-helper.service'
import { selectWebsiteById } from '@tv3/store/website/website.selectors'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { WebsiteTemplateGuard } from '@tv3/guards/website-template.guard'
import { selectCustomDomainById } from 'libs/ssl/src/lib/ssl.selectors'
import { map, switchMap } from 'rxjs/operators'
import { CustomDomainResponse, isCustomDomainSSLActive } from '@tokeet-frontend/ssl'
import { WebsiteHelper } from '../dialogs/add-website-dialog/add-website-dialog.component'

export enum WebsiteDetailTab {
  Details,
  TemplateSettings,
  OtherPages,
  Components,
  ContactInfo,
  Links,
  Resources,
  Images,
}

@Component({
  selector: 'app-website-overlay',
  templateUrl: './website-overlay.component.html',
  styleUrls: ['./website-overlay.component.scss'],
})
export class WebsiteOverlayComponent extends Destroyable implements OnInit {
  tab$ = new BehaviorSubject(WebsiteDetailTab.Details)
  tabs = WebsiteDetailTab

  website: Website
  websiteTypes = WebsiteTypes
  isCustomDomain = false
  customDomainData: CustomDomainResponse
  articleId = ''

  constructor(
    public dialogRef: MatDialogRef<WebsiteOverlayComponent>,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { websiteId: string },
    private actions: Actions,
    private dataCheckerService: DataCheckerService,
    private websitesHelper: WebsitesHelperService
  ) {
    super()
    this.dataCheckerService.check([WebsiteTemplateGuard])
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectWebsiteById, { id: this.data.websiteId }),
        switchMap((web) =>
          this.store.pipe(
            select(selectCustomDomainById(web?.domain)),
            map((ssl) => ({ web, ssl }))
          )
        ),
        untilDestroy(this)
      )
      .subscribe(({ ssl, web }) => {
        this.website = web
        this.customDomainData = ssl
        this.isCustomDomain = !isTokeetWebsiteDomain(web.domain, web.type)
      })

    this.actions.pipe(ofType(DeleteWebsiteComplete), untilDestroy(this)).subscribe(() => {
      this.close()
    })

    this.articleId = WebsiteHelper.getArticleId(this.website.type)
  }

  onPublish() {
    this.websitesHelper.publish(this.website)
  }

  onPreview() {
    let https = true
    if (this.isCustomDomain) {
      https = this.customDomainData ? isCustomDomainSSLActive(this.customDomainData) : false
    }
    this.websitesHelper.preview(this.website, https)
  }

  onDelete() {
    this.websitesHelper.delete(this.website)
  }

  close() {
    this.dialogRef.close()
  }
}
