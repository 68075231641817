import { Action, createReducer, on } from '@ngrx/store'
import { initialState, State } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.state'
import {
  CloseDetails,
  DeletePhotoComplete,
  LoadPhotosComplete,
  LoadRoomsComplete,
  OpenDetails,
  OpenTab,
  UpdateCaptionComplete,
  UpdateOrderComplete,
} from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.actions'
import * as R from 'ramda'

export const reducer = createReducer(
  initialState,
  on(OpenTab, (state, { tab }) => ({ ...state, tab })),
  on(OpenDetails, (state, { details }) => ({ ...state, details })),
  on(CloseDetails, (state) => ({ ...state, details: null })),
  on(LoadRoomsComplete, (state, { rooms }) => ({ ...state, rooms })),
  on(LoadPhotosComplete, (state, { photos }) => ({ ...state, photos })),
  on(UpdateOrderComplete, (state, { orderByIds }) => {
    return {
      ...state,
      photos: R.map((p) => {
        if (R.has(p.id, orderByIds)) {
          return { ...p, sort_order: orderByIds[p.id] }
        } else {
          return p
        }
      }, state.photos),
    }
  }),
  on(UpdateCaptionComplete, (state, { photo }) => {
    return {
      ...state,
      photos: R.update(
        R.findIndex((p) => p.id === photo.id, state.photos),
        {
          ...photo,
          caption: photo.caption,
        },
        state.photos
      ),
    }
  }),
  on(DeletePhotoComplete, (state, { photo }) => ({
    ...state,
    photos: R.reject((p) => p.id === photo.id, state.photos),
  }))
)

export function airBnBApiDialogReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}
