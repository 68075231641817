import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as R from 'ramda'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { take } from 'rxjs/operators'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { InquiryCost, isExternalBookingSource } from '@tokeet/cost-resolver'

@Component({
  selector: 'app-booking-charges',
  templateUrl: './booking-charges.component.html',
  styleUrls: ['./booking-charges.component.scss'],
})
export class BookingChargesComponent implements OnInit, OnChanges {
  @HostBinding('class') classes = 'default-box-holder'

  @Input() title = 'Booking Charges'
  @Input() inquiry: Inquiry
  @Input() header: string

  currencySymbol: string
  currencyCode: string

  charges: InquiryCost

  external = false

  constructor(private inquiryChargeService: InquiryChargeService, public inquiryService: InquiryService) {}

  ngOnInit() {
    this.external =
      !!this.inquiry.booked && isSomething(this.inquiry.cost) && isExternalBookingSource(this.inquiry.inquirySource)
  }

  tryToGetFormulaCost(inquiryService: InquiryService, inquiry: Inquiry) {
    return inquiryService.getCostAfterFormula(inquiry.id)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (R.hasIn('inquiry', changes) && this.inquiry) {
      this.currencySymbol = this.inquiry?.rental?.currency?.symbol
      this.currencyCode = this.inquiry?.rental?.currency?.code

      this.inquiryChargeService
        .getCharges(this.inquiry)
        .pipe(take(1))
        .subscribe((inquiry) => {
          this.charges = inquiry.charges
        })
    }
  }
}
