import { EntityState, EntityAdapter, Update } from '@ngrx/entity'
import * as R from 'ramda'
import { Serializable } from '../../functions'

export function updateOne<T, S extends EntityState<T>>(
  adapter: EntityAdapter<T>,
  update: Update<T>,
  state: S,
  useMerge = false
): S {
  const entity = state.entities[update.id]

  if (!(entity instanceof Serializable)) {
    return adapter.updateOne(update, state)
  }

  const serializable = (entity as Serializable<T>).clone(update.changes, useMerge)

  state = adapter.removeOne(update.id as string, state)
  state = adapter.addOne(serializable, state)

  return state
}

export function updateMany<T, S extends EntityState<T>>(
  adapter: EntityAdapter<T>,
  updates: Update<T>[],
  state: S,
  useMerge = false
): S {
  updates.forEach((update) => {
    state = updateOne(adapter, update, state, useMerge)
  })

  return state
}

export function upsertOne<T, S extends EntityState<T>>(
  adapter: EntityAdapter<T>,
  entity: T,
  state: S,
  replace = false
): S {
  const id = adapter.selectId(entity)
  const existingEntity = state.entities[id]

  if (existingEntity) {
    state = adapter.removeOne(id as string, state)
  }

  if (existingEntity && !replace) {
    if (existingEntity instanceof Serializable) {
      entity = existingEntity.clone(entity)
    } else {
      // @ts-ignore
      entity = R.merge(existingEntity, entity)
    }
  }

  state = adapter.addOne(entity, state)

  return state
}

export function upsertMany<T, S extends EntityState<T>>(
  adapter: EntityAdapter<T>,
  entities: T[],
  state: S,
  replace = false
): S {
  entities.forEach((entity) => {
    state = upsertOne(adapter, entity, state, replace)
  })

  return state
}
