<div [class.active]="isBooked">
  <div class="icon">
    <i class="cs-icon cs-icon-pc"></i>
  </div>
  <div class="date" [matTooltip]="inquiry?.booked | epoch" matTooltipPosition="above">
    {{ inquiry?.booked | epoch : 'D MMM YYYY' }}
  </div>
  <div class="info text-light"><i class="fas fa-check-circle text-success"></i> Reserved</div>
</div>

<div [class.active]="isBooked && isCheckedIn">
  <div class="icon">
    <i class="cs-icon cs-icon-checkin-door"></i>
  </div>
  <div
    class="date"
    matTooltip="{{ inquiry?.guestArrive | epochUTC : 'DD - MMM - YYYY' }} | {{
      (checkInout$ | async)?.checkIn | epoch : 'h:mm A'
    }}"
    matTooltipPosition="above"
  >
    {{ inquiry?.guestArrive | epochUTC : 'D MMM YYYY' }}
  </div>
  <div class="info text-light"><i class="fas fa-check-circle text-success"></i> Check In</div>
</div>

<div [class.active]="isBooked && isCheckedOut">
  <div class="icon">
    <i class="cs-icon cs-icon-checkout-door"></i>
  </div>
  <div
    class="date"
    matTooltip="{{ inquiry?.guestDepart | epochUTC : 'DD - MMM - YYYY' }} | {{
      (checkInout$ | async)?.checkOut | epoch : 'h:mm A'
    }}"
    matTooltipPosition="above"
  >
    {{ inquiry?.guestDepart | epochUTC : 'D MMM YYYY' }}
  </div>
  <div class="info text-light"><i class="fas fa-check-circle text-success"></i> Check Out</div>
</div>
