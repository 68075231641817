import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  selectHvmiStatus,
  ConnectHvmi,
  DisconnectHvmi,
  ConnectHvmiSuccess,
  ConnectHvmiFailure,
} from '@tokeet-frontend/channels'
import { ConfirmDialogService, Destroyable, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

@Component({
  selector: 'app-connect-hvmi-wizard-step1',
  templateUrl: './connect-hvmi-wizard-step1.component.html',
  styleUrls: ['./connect-hvmi-wizard-step1.component.scss'],
})
export class ConnectHvmiWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()

  form = this.fb.group({
    hmcIds: this.fb.array([]),
  })

  newForm = this.fb.group({
    hmcId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
  })
  get hmcIds() {
    return this.form.get('hmcIds') as FormArray
  }
  isConnecting = false
  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private store: Store<any>,
    private confirmDialog: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(ConnectHvmiSuccess), untilDestroy(this)).subscribe(() => {
      this.next.emit()
      this.isConnecting = false
    })

    this.store.pipe(select(selectHvmiStatus), untilDestroy(this)).subscribe((status) => {
      this.hmcIds.clear()
      if (status?.connected) {
        lodash.forEach(status.hmcIds, (hmcId) => {
          this.hmcIds.push(
            this.fb.group({
              hmcId: [{ value: hmcId, disabled: true }],
            })
          )
        })
      }
    })

    this.actions.pipe(ofType(ConnectHvmiFailure), untilDestroy(this)).subscribe(() => {
      this.isConnecting = false
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { hmcId } = form.getRawValue()
    this.store.dispatch(ConnectHvmi({ hmcId: Number(hmcId) }))
    this.isConnecting = true
  }

  canMoveToRentalsStep() {
    const hmcIds = this.hmcIds.value
    return !this.form.invalid && hmcIds.length > 0
  }

  onDisconnect(index: number) {
    const { hmcId } = this.hmcIds.at(index)?.value
    this.confirmDialog
      .confirm({
        title: 'Disconnect?',
        body: 'Are you sure you want to disconnect HVMI?',
      })
      .subscribe(() => {
        this.store.dispatch(DisconnectHvmi({ hmcId }))
      })
  }
}
