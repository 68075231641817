import { createAction, props } from '@ngrx/store'
import { BookingFormulaResponse, CreateBookingFormulaPayload, UpdateBookingFormulaPayload } from './model'
import { Update } from '@ngrx/entity'

export const LoadBookingFormulas = createAction('[BookingFormulas] Load')
export const LoadBookingFormulasComplete = createAction(
  '[BookingFormulas] Load Complete',
  props<{ items: BookingFormulaResponse[] }>()
)

export const CreateBookingFormula = createAction(
  '[BookingFormulas] Create',
  props<{ payload: CreateBookingFormulaPayload }>()
)
export const CreateBookingFormulaComplete = createAction(
  '[BookingFormulas] Create Complete',
  props<{ item: BookingFormulaResponse }>()
)

export const UpdateBookingFormula = createAction(
  '[BookingFormulas] Update',
  props<{ id: string; payload: UpdateBookingFormulaPayload }>()
)
export const UpdateBookingFormulaComplete = createAction(
  '[BookingFormulas] Update Complete',
  props<{ id: string; item: BookingFormulaResponse }>()
)

export const ToggleBookingFormulaStatus = createAction(
  '[BookingFormulas] Toggle Status',
  props<{ id: string; status: boolean }>()
)
export const ToggleBookingFormulaStatusComplete = createAction(
  '[BookingFormulas] Toggle Status Complete',
  props<{ id: string; status: boolean }>()
)

export const ToggleMultipleBookingFormulaStatus = createAction(
  '[BookingFormulas] Toggle Multiple Status',
  props<{ ids: string[]; status: boolean }>()
)
export const ToggleMultipleBookingFormulaStatusComplete = createAction(
  '[BookingFormulas] Toggle Multiple Status Complete',
  props<{ updates: Update<BookingFormulaResponse>[] }>()
)

export const RemoveBookingFormula = createAction('[BookingFormulas] Remove', props<{ id: string }>())
export const RemoveBookingFormulaComplete = createAction('[BookingFormulas] Remove Complete', props<{ id: string }>())

export const RemoveBookingFormulas = createAction('[BookingFormulas] Remove All', props<{ ids: string[] }>())
export const RemoveBookingFormulasComplete = createAction(
  '[BookingFormulas] Remove All Complete',
  props<{ ids: string[] }>()
)
