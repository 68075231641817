import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import {
  ChannelReviewFetchParams,
  ChannelReviewImportPayload,
  ChannelReviewItem,
  ChannelReviewSearchParams,
  ChannelReviewsResponse,
} from './model'

@Injectable()
export class ReviewService {
  constructor(private http: HttpClient) {}

  getReviews(params?: ChannelReviewFetchParams) {
    return this.http
      .get<{ response: ChannelReviewsResponse }>('@api/v1/reviews', { params: params as any })
      .pipe(map((data) => data.response))
  }

  importReviews(payload: ChannelReviewImportPayload) {
    return this.http.post('@api/v1/reviews/import', payload)
  }

  updateReview(id: string, payload: Partial<ChannelReviewItem>) {
    return this.http
      .put<{ response: ChannelReviewItem }>(`@api/v1/reviews/${id}`, payload)
      .pipe(map((data) => data.response))
  }

  searchReviews(params?: ChannelReviewSearchParams) {
    return this.http
      .post<{ response: ChannelReviewsResponse }>('@api/v1/reviews/search', { ...params })
      .pipe(map((data) => data.response))
  }

  getReviewStats(params?: ChannelReviewSearchParams) {
    return this.http
      .post<{ response: ChannelReviewsResponse }>('@api/v1/reviews/stats', params)
      .pipe(map((data) => data.response))
  }

  generateReviewResponse(inquiryId: string) {
    return this.http
      .post<{ message: string }>(`@api/ai/responder/review`, { inquiryId })
      .pipe(map((data) => data.message))
  }
  respondReview(reviewId: string, text: string) {
    return this.http
      .post<{ response: ChannelReviewItem }>(`@api/v1/reviews/${reviewId}/respond`, { response: text })
      .pipe(map((data) => data.response))
  }

  reviewAirbnbGuest(confirmationCode: string, payload: any) {
    const url = `@api/v1/reviews/airbnb/host-to-guest/${confirmationCode}`
    return this.http.post<{ response: ChannelReviewItem }>(url, payload).pipe(map((data) => data.response))
  }

  getReview(revId: string) {
    return this.http.get<{ response: ChannelReviewItem }>(`@api/v1/reviews/${revId}`).pipe(map((data) => data.response))
  }
}
