import { Component, HostBinding, Input } from '@angular/core'
import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['../form-field-common.scss', './form-checkbox.component.scss'],
  host: { class: 'custom-control custom-checkbox' },
})
export class FormCheckboxComponent extends FormFieldComponent {
  @Input() checkRequired: boolean
  @Input() indeterminate: boolean

  @Input() set checked(v: boolean) {
    this.toggleChecked(v)
  }

  @HostBinding('class.checked') get isChecked() {
    return !!this.ctrl.value
  }

  @Input() checkedValue: any = true
  @Input() uncheckedValue: any = false

  get isTouchedInvalid(): boolean {
    return this.ctrl && this.checkRequired && this.touched && !this.ctrl.value
  }

  decodeValue(v: any) {
    return v === this.checkedValue ? true : false
  }

  encodeValue(v: boolean) {
    return v ? this.checkedValue : this.uncheckedValue
  }

  validationMessage(): string {
    if (this.checkRequired && !this.ctrl.value) {
      return `This must be checked.`
    }
  }

  toggleChecked(checked: boolean) {
    this.ctrl.setValue(!!checked, { emitEvent: false })
  }
}
