import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { MatExpansionPanel } from '@angular/material/expansion'

@Component({
  selector: 'app-connect-tiket-wizard-step2',
  templateUrl: './connect-tiket-wizard-step2.component.html',
  styleUrls: ['./connect-tiket-wizard-step2.component.scss'],
})
export class ConnectTiketWizardStep2Component implements OnInit {
  @ViewChild('start') startStep: MatExpansionPanel

  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}

  reset() {
    this.startStep.open()
  }
}
