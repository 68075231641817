import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms'
import { DialCodeItem, Destroyable, untilDestroy, isSomething } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { catchError, debounceTime, filter, finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators'
import { PhoneNumberService } from './phone-number.service'

@Component({
  selector: 'app-national-phone-input2',
  templateUrl: './national-phone-input.component.html',
  styleUrls: ['./national-phone-input.component.scss'],
})
export class NationalPhoneInputComponent extends Destroyable implements OnInit {
  @Input() defaultCode: string
  @Input() ctrl: FormControl
  @Input() codeLabel: string = 'Code'
  @Input() phoneLabel: string = 'Phone Number'

  _required = false
  @Input() set required(value: boolean) {
    this._required = value
    if (value) {
      this.codeCtrl.setValidators([Validators.required])
      this.phoneCtrl.setValidators([Validators.required])
    } else {
      this.codeCtrl.clearValidators()
      this.phoneCtrl.clearValidators()
    }
  }
  get required() {
    return this._required
  }

  form = this.fb.group({
    code: [],
    phone: [],
  })

  get codeCtrl() {
    return this.form.get('code')
  }

  get phoneCtrl() {
    return this.form.get('phone')
  }

  @HostBinding('class.focused')
  isFocused = false

  isValidating = false
  isPhoneValid = true

  isLoading = false
  dialCodes: { country: string; cc: string; code: string; codeDisplay: string; placeholder: string }[] = []
  filteredDialCodes: { country: string; cc: string; code: string; codeDisplay: string; placeholder: string }[] = []

  private ctrlAsyncValidationValue$ = new BehaviorSubject<any>(null)
  constructor(private pnService: PhoneNumberService, private fb: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    const formValueChange$ = this.form.valueChanges.pipe(debounceTime(100))

    formValueChange$.pipe(untilDestroy(this)).subscribe(({ code, phone }) => {
      this.ctrl.setValue(lodash.trim(`${code ? `${code} ${phone || ''}` : ''}`))
    })

    this.ctrl.setAsyncValidators([() => this.ctrlAsyncValidationValue$.pipe(take(1))])

    formValueChange$
      .pipe(
        switchMap(() => this.validatePhoneNumber()),
        untilDestroy(this)
      )
      .subscribe((errors) => {
        this.setCtrlError(this.codeCtrl, 'code', errors?.code)
        this.setCtrlError(this.phoneCtrl, 'phone', errors?.phone)
        this.ctrlAsyncValidationValue$.next(errors)
        this.ctrl.updateValueAndValidity()
      })

    const ctrlTouched = this.ctrl.markAsTouched
    this.ctrl.markAsTouched = (opts?: { onlySelf?: boolean }) => {
      ctrlTouched.call(this.ctrl, opts)
      this.form.markAllAsTouched()
    }

    combineLatest([
      this.ctrl.valueChanges.pipe(
        startWith(this.ctrl.value),
        filter((t) => !!t)
      ),
      this.getDialCodes(),
    ])
      .pipe(take(1), untilDestroy(this))
      .subscribe(() => {
        this.initForm()
      })
  }

  setCtrlError(ctrl: AbstractControl, error: string, invalid: boolean) {
    if (invalid) {
      ctrl.setErrors({ ...ctrl.errors, [error]: true })
    } else {
      const errors = lodash.omit(ctrl.errors, error)
      ctrl.setErrors(isSomething(errors) ? errors : null)
    }
  }

  initForm() {
    const value = this.ctrl.value
    const { code, phone } = this.getNumberParts(value)

    this.form.patchValue({ code: code || this.defaultCode || '', phone }, { emitEvent: false })
  }

  onSearch(term: string, item: any) {
    term = lodash.toLower(lodash.trim(term))
    if (!term) {
      return true
    } else {
      const reg = new RegExp(term, 'ig')
      return reg.test(`${item.codeDisplay} - ${item.country}`)
    }
  }

  setTouched() {
    this.ctrl.markAsTouched()
  }

  getDialCodes() {
    this.isLoading = true
    return this.pnService.getDialCodes().pipe(
      tap((dialCodes) => {
        this.dialCodes = dialCodes.map(([country, cc, dc, n]: DialCodeItem) => ({
          country,
          cc: lodash.toLower(cc),
          code: dc,
          codeDisplay: `+${dc}`,
          placeholder: lodash.trimStart(n, '0'),
        }))
        this.filteredDialCodes = this.dialCodes
      }),
      finalize(() => (this.isLoading = false))
    )
  }

  getPhonePlaceholder(code: string, dialCodes) {
    return lodash.find(dialCodes, (t) => t.code == code)?.placeholder || ''
  }

  isValidCode(code: string) {
    return !!lodash.find(this.dialCodes, (t) => t.code == code)
  }

  getNumberParts(nationalPhoneNumber: string) {
    const [code, ...phonesParts] = lodash
      .chain(lodash.toString(nationalPhoneNumber || ''))
      .trim()
      .replace(/^[\+\@]/, '')
      .split(' ')
      .value()

    const phone = phonesParts.join('')

    return this.isValidCode(code) ? { code, phone } : { code: '', phone: nationalPhoneNumber }
  }

  validatePhoneNumber(): Observable<{ code?: boolean; phone?: boolean; required?: boolean }> {
    const data = this.form.getRawValue()
    if (!data.code && !data.phone) {
      return of(null)
    }

    if (!data.code) {
      return of({ code: true })
    }
    if (this.required && !data.phone) {
      return of({ required: true })
    }

    if (!/^(\d+[-\s]?)+\d+$/.test(data.phone)) {
      return of({ phone: true })
    }

    this.isValidating = true
    const countryCode = lodash.find(this.dialCodes, (t) => t.code === data.code)?.cc

    return this.validate(countryCode, data.phone).pipe(
      map((valid) => {
        return valid ? undefined : { phone: true }
      }),
      finalize(() => (this.isValidating = false))
    )
  }

  validate(countryCode: string, phoneNumber: string) {
    return this.pnService.validatePhoneNumber(countryCode, phoneNumber).pipe(catchError(() => of(true)))
  }
}
