import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GatewayLogoPipe } from './gateway-logo.pipe'
import { GatewayDescriptionPipe } from './gateway-description.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [GatewayLogoPipe, GatewayDescriptionPipe],
  exports: [GatewayLogoPipe, GatewayDescriptionPipe],
})
export class GatewayUtilityModule {}
