import { adapter, initialState, State } from './state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddEntityTagsComplete,
  GetEntityTagsComplete,
  LoadEntityTagsComplete,
  UpdateEntityTagsComplete,
} from './actions'

export const reducer = createReducer(
  initialState,
  on(LoadEntityTagsComplete, (state, { items }) => adapter.setAll(items, { ...state, isLoaded: true })),
  on(AddEntityTagsComplete, UpdateEntityTagsComplete, GetEntityTagsComplete, (state, { item }) =>
    adapter.setOne(item, state)
  )
)

export function tagsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
