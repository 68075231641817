import { Pipe, PipeTransform } from '@angular/core'
import { Store } from '@ngrx/store'
import { User, selectUserEntities } from '../stores'
import { userFullname } from './fullname.pipe'
import { selectSomeOnce } from '../rx-operators'

@Pipe({ name: 'username', pure: false })
export class UsernamePipe implements PipeTransform {
  userEntities: Record<string, User>
  constructor(private store: Store<any>) {
    this.store.pipe(selectSomeOnce(selectUserEntities)).subscribe((users) => {
      this.userEntities = users
    })
  }
  transform(id: string): any {
    return this.userEntities[id]?.name || userFullname(this.userEntities[id])
  }
}
