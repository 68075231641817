import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { EMPTY, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import {
  LoadPaymentGateways,
  LoadPaymentGatewaysComplete,
  RemovePaymentGateway,
  RemovePaymentGatewayComplete,
  RemoveSpreedlyPaymentGateway,
  SetDefaultPaymentGateway,
  SetDefaultPaymentGatewayComplete,
  TogglePaymentGatewayStatus,
  TogglePaymentGatewayStatusComplete,
  UpdatePaymentGateway,
  UpdatePaymentGatewayComplete,
  UpdatePaymentGatewayCredentials,
} from './gateways.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import { GatewaysService } from './gateways.service'

/**
 * TODO - LoadPaymentRules should be handled within TV3 and Sympl effects
 */

@Injectable()
export class GatewaysEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadPaymentGateways),
    switchMap(() =>
      this.gatewaysService.all().pipe(
        map((gateways) => LoadPaymentGatewaysComplete({ gateways })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdatePaymentGateway),
    switchMap(({ id, changes }) => {
      return this.gatewaysService.update(id, changes).pipe(
        tap(() => this.toast.success('Connection updated successfully.')),
        map((paymentGateway) =>
          UpdatePaymentGatewayComplete({
            id,
            paymentGateway,
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateCredentials$ = this.actions$.pipe(
    ofType(UpdatePaymentGatewayCredentials),
    switchMap(({ payload }) =>
      this.gatewaysService.updateCredentials(payload).pipe(
        tap(() => this.toast.success('Connection updated successfully.')),
        map(() => ActionSkipped()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  toggleStatus$ = this.actions$.pipe(
    ofType(TogglePaymentGatewayStatus),
    switchMap(({ id, status }) => {
      return (status ? this.gatewaysService.resume(id) : this.gatewaysService.pause(id)).pipe(
        tap(() => this.toast.success(status ? 'Gateway enabled successfully.' : 'Gateway disabled successfully.')),
        map(() => TogglePaymentGatewayStatusComplete({ id, status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  setDefault$ = this.actions$.pipe(
    ofType(SetDefaultPaymentGateway),
    switchMap(({ id, silent }) =>
      this.gatewaysService.setDefault(id).pipe(
        tap(() => !silent && this.toast.success('Gateway successfully set as default.')),
        map(() => SetDefaultPaymentGatewayComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  remove$ = this.actions$.pipe(
    ofType(RemovePaymentGateway),
    switchMap(({ id }) => {
      return this.gatewaysService.remove(id).pipe(
        tap(() => this.toast.success('Gateway disconnected successfully.')),
        map(() => RemovePaymentGatewayComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  removeSpreedly$ = this.actions$.pipe(
    ofType(RemoveSpreedlyPaymentGateway),
    switchMap(({ id }) => {
      return this.gatewaysService.redact(id).pipe(
        catchError(() => EMPTY),
        switchMap(() => {
          return this.gatewaysService.remove(id).pipe(
            tap(() => this.toast.success('Gateway disconnected successfully.')),
            map(() => RemovePaymentGatewayComplete({ id })),
            catchError((error) => of(ActionFailed({ error })))
          )
        })
      )
    })
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private gatewaysService: GatewaysService,
    private toast: Toaster
  ) {}
}
