import { Injectable } from '@angular/core'
import { SelectBillingAddressDialogComponent } from './select-billing-address-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root',
})
export class SelectBillingAddressDialogService {
  constructor(private dialog: MatDialog) {}

  open(key?: string) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { key },
    }

    return this.dialog.open(SelectBillingAddressDialogComponent, defaultConfig)
  }
}
