import { Component, Inject } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AddLosDiscountComplete, LosDiscountView } from '@tokeet-frontend/los-discount'
import { ConfirmDialogService, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { LosDiscountFormComponent } from '../los-discount-form.component'

@Component({
  selector: 'app-add-los-discount-dialog',
  templateUrl: './add-los-discount-dialog.component.html',
  styleUrls: ['./add-los-discount-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class AddLosDiscountDialogComponent extends LosDiscountFormComponent {
  constructor(
    fb: FormBuilder,
    store: Store<any>,
    private actions: Actions,
    confirmDialog: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { item: Partial<LosDiscountView> },
    public dialogRef: MatDialogRef<AddLosDiscountDialogComponent>
  ) {
    super(fb, store, confirmDialog)
    this.item = this.data.item
  }

  ngOnInit() {
    super.ngOnInit()
    this.actions.pipe(ofType(AddLosDiscountComplete), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }
}
