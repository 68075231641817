<div>
  <app-upload-box
    [options]="uploadBoxOptions"
    image="/assets/images/upload-files.svg"
    [showFileList]="false"
    (afterAddingAll)="onAfterAddingAll($event)"
    #uploadBox
  ></app-upload-box>
  <div class="upload-guide mb-3">
    <div class="my-3" [innerHtml]="guide?.description"></div>
    <label class="text-bold">Required columns</label>
    <ul class="text-lg p-0 d-flex gap-2">
      <li *ngFor="let item of requiredColumns" class="badge text-light bg-default">{{ item.name }}</li>
    </ul>
    <a *ngIf="guide.sample" href="javascript:;" (click)="downloadSample()" class="btn btn-secondary-primary">
      <i class="cs-icon cs-icon-download"></i>
      Download sample CSV
    </a>
  </div>
  <mat-accordion class="d-block container-box">
    <mat-expansion-panel *ngFor="let file of uploadBox?.files; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ file.file.name | truncate : 30 }}
          <small class="ml-1">(<i class="fal fa-hand-point-up"></i> Preview)</small>
        </mat-panel-title>
        <mat-panel-description>
          <mat-checkbox
            matTooltip="Please check this if the first row in this file is header line"
            matTooltipPosition="above"
            [(ngModel)]="file.isHeader"
            (click)="$event.stopPropagation()"
            class="mt-primary"
          >
            Remove Header
          </mat-checkbox>
          <div class="flex-fill"></div>
          <span
            *ngIf="file.isError"
            class="text-danger mr-2"
            [matTooltip]="getFileErrorText(file)"
            matTooltipPosition="above"
          >
            <i class="fal fa-exclamation-triangle"></i>
          </span>

          <button
            class="btn btn-sm btn-light-danger"
            (click)="uploadBox.onRemoveFile(file, i)"
            matTooltip="Remove file"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="table-responsive data-table-wrapper">
        <table class="table table-condensed" aria-hidden="false">
          <thead>
            <tr>
              <th class="icon"></th>
              <th *ngFor="let col of columnDefs" [style.width]="col.width">{{ col.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of file.items; let i = index" [ngClass]="{ hidden: file.isHeader && i == 0 }">
              <td class="icon">
                <span
                  class="icon-container"
                  [matTooltip]="getItemErrorText(item)"
                  matTooltipPosition="above"
                  matTooltipClass="text-left pre-line"
                >
                  <i
                    class="fas"
                    [ngClass]="{
                      'fa-info-circle text-warning': !item.isError && item.isWarning,
                      'fa-exclamation-triangle text-danger': item.isError
                    }"
                  ></i>
                </span>
              </td>
              <ng-container *ngFor="let field of item.originalFields; let j = index">
                <td *ngIf="j < columnDefs.length">
                  {{ field }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
