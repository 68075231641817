import { Component, OnInit } from '@angular/core'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import { parseNumber, parseString } from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, Validators } from '@angular/forms'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { take } from 'rxjs/operators'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-airbnb-upload-transaction-dialog',
  host: { class: 'modal-content' },
  templateUrl: './airbnb-upload-transaction-dialog.component.html',
  styleUrls: ['./airbnb-upload-transaction-dialog.component.scss'],
})
export class AirbnbUploadTransactionDialogComponent extends Destroyable implements OnInit {
  files: ParsedUploadFile<any>[] = []
  rentals: Rental[]

  form = this.fb.group({
    rental: ['', [Validators.required]],
  })

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Date', field: 'date', parse: parseString },
    { name: 'Type', field: 'type', parse: parseString },
    { name: 'Confirmation Code', field: 'confirmationcode', required: true, parse: parseString },
    { name: 'Start Date', field: 'startdate', required: true, parse: parseString },
    { name: 'Nights', field: 'nights', required: true, parse: parseNumber },
    { name: 'Guest', field: 'guest', required: true, parse: parseString },
    { name: 'Listing', field: 'listing', parse: parseString },
    { name: 'Details', field: 'details', parse: parseString },
    { name: 'Reference', field: 'reference', parse: parseString },
    { name: 'Currency', field: 'currency', required: true, parse: parseString },
    { name: 'Amount', field: 'amount', required: true, parse: parseNumber },
    { name: 'Paid Out', field: 'paidout', parse: parseNumber, default: 0 },
    { name: 'Host Fee', field: 'hostfee', parse: parseNumber, default: 0 },
    { name: 'Cleaning Fee', field: 'cleaningfee', parse: parseNumber, default: 0 },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: ['Confirmation Code, Nights, Guest, Currency, Amount and Start Date are required.'],
    sample: [
      [
        'Date',
        'Type',
        'Confirmation Code',
        'Start Date',
        'Nights',
        'Guest',
        'Listing',
        'Details',
        'Reference',
        'Currency',
        'Amount',
        'Paid Out',
        'Host Fee',
        'Cleaning Fee',
      ],
      [
        '2016-02-14',
        'Reservation',
        'HM3XXXXX',
        '2016-02-14',
        1,
        'David',
        'Cozy room in my home on Shalimar',
        '',
        '',
        'AUD',
        36.69,
        '',
        0.0,
        4.81,
      ],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<AirbnbUploadTransactionDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private connectionAirbnbService: ConnectionAirbnbService,
    private toaster: Toaster
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  onCreate() {
    const items = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    const rental = this.form.get('rental').value as Rental
    const data = items.map((item: any) => {
      return {
        ...item,
        rentalid: rental.id,
        sendto: rental.email,
      }
    })

    this.connectionAirbnbService
      .uploadTransactions({
        transactions: data,
        rentalid: rental.id,
        sendto: rental.email,
      })
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.toaster.success(`Total ${res.total}, created ${res.created}, updated ${res.updated}`)
          this.dialogRef.close()
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onClose() {
    this.dialogRef.close()
  }

  hasErrors() {
    return !!this.files.find((file) => file.isError)
  }
}
