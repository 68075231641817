import { Component, OnInit, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import * as moment from 'moment-timezone'
import * as R from 'ramda'

function getTimezones() {
  return R.filter((z: any) => z && z.indexOf('/') > -1 && z.indexOf('Etc/') < 0, moment.tz.names())
}

@Component({
  selector: 'app-timezone-select',
  templateUrl: './timezone-select.component.html',
  styles: [],
})
export class TimezoneSelectComponent implements OnInit {
  timezoneList = getTimezones()

  @Input() ctrl = new FormControl()
  @Input() inline = false
  @Input() required = false
  @Input() label = 'TimeZone'
  constructor() {}

  ngOnInit(): void {}
}
