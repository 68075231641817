import {
  AddExpenseComplete,
  AddExpensesComplete,
  DeleteExpenseComplete,
  DeleteExpensesComplete,
  LoadExpensesByIdsComplete,
  LoadExpensesComplete,
  LoadExpenseSettingsComplete,
  LoadFilteredExpensesComplete,
  UpdateExpenseApprovalStatusComplete,
  UpdateExpenseComplete,
  UpdateExpenseSettingsComplete,
} from './expense.actions'
import { adapter, initialState, State } from './expense.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadExpensesComplete, (state, { expenses }) => adapter.upsertMany(expenses, R.merge(state, { isLoaded: true }))),
  on(LoadFilteredExpensesComplete, (state, { expenses }) => adapter.upsertMany(expenses, state)),
  on(LoadExpensesByIdsComplete, (state, { expenses }) => adapter.addMany(expenses, state)),
  on(AddExpenseComplete, (state, { expense }) => adapter.addOne(expense, state)),
  on(AddExpensesComplete, (state, { expenses }) => adapter.addMany(expenses, state)),
  on(UpdateExpenseComplete, UpdateExpenseApprovalStatusComplete, (state, { update }) =>
    adapter.updateOne(update, state)
  ),
  on(DeleteExpenseComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteExpensesComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(LoadExpenseSettingsComplete, UpdateExpenseSettingsComplete, (state, { settings }) => ({ ...state, settings }))
)

export function expenseReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
