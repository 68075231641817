import * as R from 'ramda'
import { USER_ROLES } from '../constants'

export function userRoleToString(roles: number[]) {
  roles = R.filter((role) => role > 0, roles)
  roles.sort((a, b) => a - b)
  let highestRole
  R.forEachObjIndexed((role) => {
    if (role.value === roles[0]) {
      highestRole = role
    }
  }, USER_ROLES)
  highestRole = highestRole || USER_ROLES.guest
  return highestRole.name
}
