import { on } from '@ngrx/store'
import { State } from '../channels.state'
import { GetCtripStatusSuccess, ConnectCtripSuccess } from './actions'

export function getCtripReducers() {
  return [
    on(GetCtripStatusSuccess, (state: State, { status }) => ({
      ...state,
      ctrip: {
        ...state.ctrip,
        status,
      },
    })),
    on(ConnectCtripSuccess, (state: State, { status }) => ({
      ...state,
      ctrip: {
        ...state.ctrip,
        status,
      },
    })),
  ]
}
