import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDialogParams, ConfirmDialogStatus } from '../confirm-dialog.interfaces'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogParams
  ) {}

  confirm() {
    this.dialogRef.close(ConfirmDialogStatus.Confirmed)
  }

  cancel() {
    this.dialogRef.close(ConfirmDialogStatus.Canceled)
  }

  close() {
    this.dialogRef.close(ConfirmDialogStatus.Closed)
  }
}
