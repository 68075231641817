export type DomainId = string

export interface CustomDomainSSLResponse {
  requested?: number | null

  active?: number | null
  error?: number | null

  errorCode?: 'ratelimit' | 'dns' | null

  type?: 'letsencrypt' | 'custom' | null

  key?: string | null
  cert?: string | null
  ca?: string | null

  letsencrypt?: {
    challengeToken: string
    challengeKey: string
  }
}

export interface CustomDomainResponse {
  _id: DomainId

  created: number
  updated: number

  ssl?: CustomDomainSSLResponse
}

export interface CustomDomainSSLCertificatePayload {
  key: string
  cert: string
  ca?: string
}

export function isCustomDomainSSLActive(domain: CustomDomainResponse): boolean {
  const sslStatus = getCustomSslStatus(domain)
  return sslStatus?.status === 'active' || sslStatus?.status === 'custom'
}

export function getCustomSslStatus(domain: CustomDomainResponse) {
  if (!domain) {
    return null
  }

  if (domain.ssl?.type === 'custom') return { status: 'custom' as const, label: `Custom Certificate` }
  if (domain.ssl?.error) return { status: 'error' as const, label: `SSL Error` }
  if (!domain.ssl || !domain.ssl.requested) return { status: 'off' as const, label: `SSL Off` }
  if (!domain.ssl.active && !domain.ssl.error) return { status: 'pending' as const, label: `SSL Pending` }
  return { status: 'active' as const, label: `SSL Active` }
}
