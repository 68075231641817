import { Component, Input, OnInit } from '@angular/core'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'
import * as lodash from 'lodash'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { inquiriesRouteMapping } from '@tv3/constants/inquiries-route-mapping'

@Component({
  selector: 'app-default-inbox',
  template: `<app-default-route-indicator
    [checkIsDefault]="isDefault"
    [getDefaultRoutePayload]="getDefaultRoutePayload"
    (updated)="setAsDefaultView($event)"
    tooltipPosition="below"
  ></app-default-route-indicator>`,
})
export class DefaultInboxComponent implements OnInit {
  @Input() inboxName: string

  isDefault = (pref: UserPreferences) => {
    return pref.defaultInbox === this.inboxName
  }

  getDefaultRoutePayload = (checked: boolean) => {
    return { default_inbox: this.inboxName }
  }

  constructor(private amplitudeService: AmplitudeService) {}

  ngOnInit() {}

  setAsDefaultView(isDefault: boolean) {
    if (!isDefault) {
      return
    }

    const url =
      lodash.find(lodash.values(inquiriesRouteMapping), (v) => v.name === this.inboxName).url || this.inboxName
    this.amplitudeService.logEvent(`default-inquiries-view-${url}`)
  }
}
