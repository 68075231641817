import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Billing } from './billing.model'

export interface State extends EntityState<Billing> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Billing> = createEntityAdapter<Billing>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
