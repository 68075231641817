import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AttachmentDialogComponent } from './attachment-dialog.component'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'
import { Attachment } from '@tokeet-frontend/tv3-platform'
import { SharedModule } from '@tv3/shared/shared.module'

@Injectable({
  providedIn: SharedModule,
})
export class AttachmentDialogService {
  constructor(private dialog: MatDialog) {}

  public open(selectFromExisting = false) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        selectFromExisting,
      },
    }

    return this.dialog
      .open(AttachmentDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(deserializeArray<Attachment>(Attachment))
  }
}
