import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { filter, take } from 'rxjs/operators'
import {
  SelectRateToPushDialogComponent,
  SelectRateToPushDialogConfig,
} from '@tv3/containers/channels/channel-connect/dialogs/select-rate-to-push-dialog/select-rate-to-push-dialog.component'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class SelectRateToPushDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: SelectRateToPushDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { config },
      panelClass: ['fixed-modal'],
    }

    return this.dialog
      .open(SelectRateToPushDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter((res) => isSomething(res))
      )
  }
}
