import { Component, Input, OnInit } from '@angular/core'
import { ChannelReviewItem, getChannelMaxRating } from '@tokeet-frontend/reviews'

@Component({
  selector: 'app-review-category-tags',
  templateUrl: './review-category-tags.component.html',
  styleUrls: ['./review-category-tags.component.scss'],
})
export class ReviewCategoryTagsComponent implements OnInit {
  @Input() review: ChannelReviewItem

  get categories() {
    return this.review.categoryRatings
  }
  constructor() {}

  ngOnInit(): void {}
  getMaxRating(item: ChannelReviewItem) {
    return getChannelMaxRating(item.source)
  }
}
