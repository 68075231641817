import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  DataCheckerService,
  Destroyable,
  DialogService,
  Rental,
  SaveForm,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import * as R from 'ramda'
import { flatten, omit } from 'lodash'
import { UpsertRooms } from '../../../store/rooms/rooms.actions'
import { selectRooms } from '../../../store/rooms/rooms.selectors'
import { CreateRoomPayload, Room, RoomType, UpdateRoomPayload, isBedsInRoom } from '../../../store/rooms/rooms.model'
import { RoomsGuard } from '../../../store/rooms/rooms.guard'
import { CopyRoomsFromDialogComponent } from '../copy-rooms-from-dialog/copy-rooms-from-dialog.component'
import { CopyRoomsToDialogComponent } from '../copy-rooms-to-dialog/copy-rooms-to-dialog.component'

@Component({
  selector: 'app-rental-rooms',
  templateUrl: './rental-rooms.component.html',
  styleUrls: ['./rental-rooms.component.scss'],
})
export class RentalRoomsComponent extends Destroyable implements OnInit {
  @Input() rental: Rental

  form = this.fb.group({
    rooms: this.fb.array([]),
  })

  get roomsArray() {
    return this.form.get('rooms') as FormArray
  }

  bathrooms: Room[] = []
  rooms: Room[] = []

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private dialogService: DialogService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([RoomsGuard])
  }

  ngOnInit() {
    this.store.pipe(select(selectRooms(this.rental.id)), untilDestroy(this)).subscribe((rooms) => {
      this.rooms = rooms
      this.resetForm()
      if (R.isEmpty(rooms)) {
        this.addEmptyRoom()
      } else {
        this.buildForm(rooms)
      }
      this.bathrooms = rooms.filter((r) => r.type === RoomType.BATHROOM)
    })
  }

  resetForm() {
    do {
      this.roomsArray.removeAt(0)
    } while (this.roomsArray.length > 0)
  }

  buildForm(rooms: Room[]) {
    rooms.forEach((room) => {
      const roomGroup = this.fb.group({
        pkey: [room.pkey],
        name: [room.name, [Validators.required]],
        description: [room.description, [Validators.required]],
        bathroom: [room.bathroom],
        type: [room.type, [Validators.required]],
        sub_type: [room.sub_type, [Validators.required]],
        beds: this.fb.array([]),
        size: [room.size, [Validators.required]],
        size_metric: [room.size_metric || 'SQFT', [Validators.required]],
      })
      const bedsArray = roomGroup.get('beds') as FormArray
      R.forEachObjIndexed(
        (value, key) => {
          bedsArray.push(
            this.fb.group({
              bed: [key],
              count: [((value as string[]) || [])?.length || 1],
            })
          )
        },
        R.groupBy((bed) => bed, room.beds)
      )
      this.roomsArray.push(roomGroup)
    })
  }

  addEmptyRoom() {
    const room = this.fb.group({
      bathroom: [''],
      type: ['', [Validators.required]],
      sub_type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      size: ['', [Validators.required]],
      beds: this.fb.array([]),
      size_metric: ['SQFT', [Validators.required]],
    })
    this.roomsArray.push(room)
  }

  onRemoveRoom(index: number) {
    this.roomsArray.removeAt(index)
  }

  onCopyFrom() {
    this.dialogService.openVariableDialog(CopyRoomsFromDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  onCopyTo() {
    this.dialogService.openVariableDialog(CopyRoomsToDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    let createRooms: CreateRoomPayload[] = []
    let updateRooms: { id: string; payload: UpdateRoomPayload }[] = []
    for (const roomForm of this.roomsArray.controls) {
      const room = roomForm.value
      const beds = flatten(room.beds.map((bed) => R.times(() => bed.bed, bed.count)))
      const payload: UpdateRoomPayload = {
        ...room,
        beds: isBedsInRoom(room.type, room.sub_type) ? beds : [],
      }
      if (room.pkey) {
        updateRooms.push({ id: room.pkey, payload })
      } else {
        createRooms.push({ ...omit(payload, 'pkey'), rental_id: this.rental.id })
      }
    }
    this.store.dispatch(UpsertRooms({ createRooms, updateRooms }))
  }
}
