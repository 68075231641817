import { Injectable } from '@angular/core'
import { Toaster, User } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

@Injectable({ providedIn: 'root' })
export class ChannelContentHelperService {
  constructor(private toaster: Toaster) {}

  validateContact(user: User, silent = false): boolean {
    if (!user) {
      return false
    }
    const requiredFields = {
      firstName: 'First Name',
      lastName: 'Last Name',
      primaryEmail: 'Email',
      phone: 'Phone number',
      'address.address': 'Address',
      'address.city': 'City',
      'address.state': 'State/Province',
      'address.postalCode': 'Postal Code',
      'address.countryCode': 'Country',
    }

    let missingFields = lodash.map(requiredFields, (label, key) => {
      const value = lodash.get(user, key)
      if (lodash.isNil(value) || value === '') {
        return label
      }
      return false
    })
    missingFields = lodash.compact(missingFields)
    if (!missingFields.length) {
      return true
    }
    if (!silent) {
      this.toaster.error(`Please enter these information for user ${user.name}: ${missingFields.join(', ')}`)
    }
    return false
  }
}
