import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, tap, toArray } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { LosDiscountService } from './los-discount.service'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import {
  AddLosDiscount,
  AddLosDiscountComplete,
  DeleteLosDiscount,
  DeleteLosDiscountComplete,
  DeleteMultipleLosDiscount,
  DeleteMultipleLosDiscountComplete,
  LoadLosDiscounts,
  LoadLosDiscountsComplete,
} from './los-discount.actions'

@Injectable()
export class LosDiscountEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadLosDiscounts),
    mergeMap(({ reset }) =>
      this.losDiscountService.load().pipe(
        map((items) => LoadLosDiscountsComplete({ items, reset })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(AddLosDiscount),
    mergeMap(({ payload }) =>
      this.losDiscountService.create(payload).pipe(
        map((res) => AddLosDiscountComplete({ item: res })),
        tap(() => this.toaster.success('LOS discount added successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteLosDiscount),
    concatMap(({ id }) =>
      this.losDiscountService.delete(id).pipe(
        map((res) => DeleteLosDiscountComplete({ id })),
        tap(() => this.toaster.success('LOS discount removed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteMultiple$ = this.actions$.pipe(
    ofType(DeleteMultipleLosDiscount),
    concatMap(({ ids }) =>
      of(...ids).pipe(
        concatMap((id) => this.losDiscountService.delete(id)),
        toArray(),
        map((res) => DeleteMultipleLosDiscountComplete({ ids })),
        tap(() => this.toaster.success('LOS discounts removed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private toaster: Toaster, private losDiscountService: LosDiscountService) {}
}
