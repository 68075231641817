import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  ConnectHoliduSuccess,
  DisconnectHoliduSuccess,
  GetHoliduStatus,
  GetHoliduListings,
  selectHoliduStatus,
} from '@tokeet-frontend/channels'

import { Channel, Destroyable, LoadChannels, untilDestroy, UserStorage } from '@tokeet-frontend/tv3-platform'
import { LoadChannelPropertySettings } from '@tv3/store/channel-property-setting/channel-property-setting.actions'
import { LoadConnections } from '@tv3/store/connection/connection.actions'
import { delay, filter, switchMap, take } from 'rxjs/operators'
import { ChannelConnectHelperService } from '../channel-connect-helper.service'

@Component({
  selector: 'app-connect-holidu-wizard',
  templateUrl: './connect-holidu-wizard.component.html',
  styleUrls: ['./connect-holidu-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
  providers: [ChannelConnectHelperService],
})
export class ConnectHoliduWizardComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper
  status$ = this.store.pipe(select(selectHoliduStatus))

  get channel() {
    return this.data.channel
  }

  constructor(
    public dialogRef: MatDialogRef<ConnectHoliduWizardComponent>,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private channelConnectHelper: ChannelConnectHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(ConnectHoliduSuccess), untilDestroy(this)).subscribe(() => {
      this.refresh()
    })

    this.actions.pipe(ofType(DisconnectHoliduSuccess), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(GetHoliduStatus())
      this.store.dispatch(GetHoliduListings())
      this.store.dispatch(LoadChannels())
      this.store.dispatch(LoadConnections({}))
      this.store.dispatch(LoadChannelPropertySettings())
    })

    this.channelConnectHelper.rentalImageCountsGuard().pipe(untilDestroy(this)).subscribe()

    this.refresh()
  }

  ngAfterViewInit(): void {
    this.stepper.animationDone
      .pipe(
        take(1),
        delay(500),
        switchMap(() => this.store.pipe(select(selectHoliduStatus))),
        filter((s) => !!s?.connected),
        take(1),
        untilDestroy(this)
      )
      .subscribe((status) => {
        this.stepper.selectedIndex = 1
      })
  }

  close() {
    this.dialogRef.close()
  }

  refresh() {
    this.store.dispatch(GetHoliduStatus())
    this.store.dispatch(GetHoliduListings())
  }
}
