import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ChannelContentHelperService } from '@tv3/containers/channels/content/channel-content-helper.service'
import { HasPermission } from 'libs/permission/src/lib/decorators/has-permission.decorator'
import {
  Destroyable,
  selectAllUsers,
  selectUsersByIds,
  untilDestroy,
  UpdateUserComplete,
  User,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { map, observeOn, startWith, switchMap, take, tap } from 'rxjs/operators'
import { UserOverlayService } from '@tv3/containers/settings/user-settings/overlays/user-overlay.service'
import { asyncScheduler, Observable } from 'rxjs'
import { BDCLikePropertyContacts } from '@tv3/store/channel-property-setting/settings/common'
import { NgxPermissionsService } from 'ngx-permissions'
import { Actions, ofType } from '@ngrx/effects'
import * as lodash from 'lodash'

export function createForm(fb: FormBuilder) {
  return fb.group({
    contract_contact: [],
    reservations_contact: [],
    invoices_contact: [],
    availability_contact: [],
    site_content_contact: [],
    parity_contact: [],
    requests_contact: [],
  })
}

@Component({
  selector: 'app-bdc-content-step-contacts-form',
  templateUrl: './bdc-content-step-contacts-form.component.html',
  styleUrls: ['./bdc-content-step-contacts-form.component.scss'],
})
export class BdcContentStepContactsFormComponent extends Destroyable implements OnInit, OnChanges {
  @Input() form: FormGroup
  @Input() contacts: BDCLikePropertyContacts
  isValid = true
  users$: Observable<User[]> = this.store.pipe(observeOn(asyncScheduler), select(selectAllUsers))

  // @ts-ignore
  validateContact = (...args) => this.channelContentHelper.validateContact(...args)

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private permissions: NgxPermissionsService,
    private channelContentHelper: ChannelContentHelperService,
    private userOverlay: UserOverlayService
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(UpdateUserComplete), untilDestroy(this)).subscribe((action) => {
      // @ts-ignore
      this.channelContentHelper.validateContact(action.update.changes)
    })

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        switchMap((contacts) => this.isContactsValid(contacts))
      )
      .subscribe((isValid) => {
        this.isValid = isValid
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contacts'] && changes['contacts'].currentValue) {
      this.form.patchValue(this.contacts)
    }
  }

  @HasPermission('canEditUser')
  onEditUser(formKey: string) {
    const userId = this.form.get(formKey).value
    let selectedUser: User
    this.store
      .pipe(
        select(selectUsersByIds([userId])),
        take(1),
        map(([user]) => user),
        tap((user) => (selectedUser = user)),
        switchMap((user) => this.userOverlay.openSide(user).afterClosed())
      )
      .subscribe(() => {
        this.channelContentHelper.validateContact(selectedUser)
      })
  }

  isContactsValid(contacts: { [key: string]: string }): Observable<boolean> {
    return this.store.pipe(
      select(selectUsersByIds(lodash.values(contacts))),
      take(1),
      map((users) => lodash.map(users, (u) => this.channelContentHelper.validateContact(u, true))),
      map((validations) => lodash.every(validations))
    )
  }
}
