import { createAction, props } from '@ngrx/store'
import { AiResponderSettings } from './ai-settings.model'
import { UpdateAIResponderSettings, AiResponderDoc } from '@tv3/containers/ai-responder/store/ai-settings.model'

export const LoadAISettings = createAction('[AI Settings] Load AI settings')
export const LoadAISettingsComplete = createAction(
  '[AI Settings] Load AI settings Complete',
  props<{ settings: AiResponderSettings }>()
)

export const UpdateAISettings = createAction(
  '[AI Settings] Update AI settings',
  props<{
    payload: Partial<AiResponderSettings>
    message?: string
    silent?: boolean
  }>()
)
export const UpdateAISettingsComplete = createAction(
  '[AI Settings] Update AI settings Complete',
  props<{ changes: Partial<AiResponderSettings> }>()
)

export const ToggleAISettings = createAction(
  '[AI Settings] Update AI Ignored items',
  props<{
    ids: string[]
    entityType: 'rental' | 'guest' | 'booking'
    enabled: boolean
  }>()
)
export const ToggleAISettingsComplete = createAction(
  '[AI Settings] Update AI Ignored items Complete',
  props<{ changes: Partial<AiResponderSettings> }>()
)

export const UploadAIDocumentComplete = createAction(
  '[AI Settings] Upload AI document Complete',
  props<{ doc: AiResponderDoc }>()
)

export const DeleteAIDocument = createAction('[AI Settings] Delete AI Document', props<{ id: string }>())
export const DeleteAIDocumentComplete = createAction(
  '[AI Settings] Delete AI Document Complete',
  props<{ id: string }>()
)
