import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ChannelNameTokens, Toaster } from '@tokeet-frontend/tv3-platform'
import { DeleteInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { ConfirmDialogService, AlertDialogService } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Observable, of } from 'rxjs'
import { concatMap, filter, switchMap, take, tap, toArray } from 'rxjs/operators'
import {
  ArchiveInquiries,
  FollowupInquiries,
  LinkInquiries,
  MarkPaidInquiries,
  MarkUnpaidInquiries,
  NoFollowupInquiries,
  ReadInquiries,
  UnArchiveInquiries,
  UnReadInquiries,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { selectConnectionByLinkedRentalId } from '@tv3/store/connection/connection.selectors'
import { ImportBookingFromABBV2Connection } from '@tv3/store/connection/connection.actions'
import { isImportedFromABBV2 } from '@tokeet-frontend/inquiry'

@Injectable()
export class InquiriesListService {
  constructor(
    private store: Store<fromRoot.State>,
    private toast: Toaster,
    private alertDialog: AlertDialogService,
    private confirmDialog: ConfirmDialogService
  ) {}

  link(ids: string[]): Observable<boolean> {
    if (ids.length <= 1) {
      this.alertDialog.open({
        title: 'No inquiries selected',
        // tslint:disable-next-line:max-line-length
        body: 'Please select two or more inquiries that you would like to link. Once inquiries are linked all messages and invoices from every inquiries will be displayed in each inquiry. It is recommended that you archive all but one of the linked inquiries after linking.',
      })
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    return this.confirmDialog
      .confirm({
        title: 'Link these inquiries?',
        // tslint:disable-next-line:max-line-length
        body: 'Are you sure you want to link these inquiries? Once inquiries are linked all messages and invoices from every linked inquiry in the group will be displayed in each inquiry. It is recommended that you archive all but one of the linked inquiries after linking.<br/><br/>Why link inquiries? Sometimes a guest will correspond from multiple email addresses regarding the same inquiry, or different guests will email about the same inquiry. This may lead to many inquiry entries in AdvanceCM. The best way to resolve this is to link the associated inquiries and archive the ones which you do not plan to use.',
      })
      .pipe(
        tap(() => this.store.dispatch(LinkInquiries({ ids }))),
        switchMap(() =>
          of(true).pipe(
            take(1),
            filter((result) => result)
          )
        )
      )
  }

  importFromAirbnb(selected: Inquiry[]): Observable<any> {
    const inquiries = R.filter((i: Inquiry) => isImportedFromABBV2(i), selected)
    if (inquiries.length < 1) {
      this.toast.warning('Please select at least one booking imported from Airbnb.')
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }

    return of(...inquiries).pipe(
      concatMap((item) =>
        this.store.pipe(
          select(selectConnectionByLinkedRentalId(item.rentalId, ChannelNameTokens.AirBnBV2API)),
          take(1),
          tap((connections) => {
            const conn = R.head(connections)
            if (conn) {
              this.store.dispatch(
                ImportBookingFromABBV2Connection({ inquiryId: item.inquiryId, channelId: conn.channelId, data: conn })
              )
            } else {
              this.toast.warning(
                `The associated rental "${item.rentalView}" is not linked to any airbnb listing`,
                'Unable to import'
              )
            }
          })
        )
      ),
      toArray()
    )
  }

  archive(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !i.archived, selected)
    if (inquiries.length < 1) {
      this.toast.warning('Please select at least one unarchived inquiry.')
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(ArchiveInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  unArchive(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !!i.archived, selected)
    if (inquiries.length < 1) {
      this.toast.warning('Please select at least one archived inquiry.')
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(UnArchiveInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  followUp(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !i.followup, selected)
    if (inquiries.length < 1) {
      this.toast.warning('Please select at least one inquiry to follow up.')
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(FollowupInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  noFollowUp(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !!i.followup, selected)
    if (inquiries.length < 1) {
      this.toast.warning('Please select at least one inquiry to unfollow.')
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(NoFollowupInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  read(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !i.read, selected)
    if (inquiries.length < 1) {
      this.toast.warning(`Please select at least one inquiry that's unread.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(ReadInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  unRead(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !!i.read, selected)
    if (inquiries.length < 1) {
      this.toast.warning(`Please select at least one inquiry that's read.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const ids = R.map((i: Inquiry) => i.id, selected)
    this.store.dispatch(UnReadInquiries({ ids }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  paid(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !i.paid, selected)
    if (inquiries.length < 1) {
      this.toast.warning(`Please select at least one booked inquiry that's unpaid.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const anyNotBooked = R.any((i: Inquiry) => !i.cost, selected)
    if (anyNotBooked) {
      this.toast.warning(`Please de-select inquiries that are not booked.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    this.store.dispatch(MarkPaidInquiries({ inquiries: selected }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  unpaid(selected: Inquiry[]): Observable<boolean> {
    const inquiries = R.filter((i: Inquiry) => !!i.paid, selected)
    if (inquiries.length < 1) {
      this.toast.warning(`Please select at least one booked inquiry that's paid.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    const anyNotBooked = R.any((i: Inquiry) => !i.cost, selected)
    if (anyNotBooked) {
      this.toast.warning(`Please de-select inquiries that are not booked.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    this.store.dispatch(MarkUnpaidInquiries({ inquiries: selected }))
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }

  delete(inquiries: Inquiry[]): Observable<boolean> {
    if (inquiries.length < 1) {
      this.toast.warning(`Please select at least one inquiry.`)
      return of(false).pipe(
        take(1),
        filter((result) => result)
      )
    }
    this.confirmDialog
      .confirmExtra({
        title: 'Delete these inquiries?',
        body: 'Are you sure you want to delete these inquiries? <br>Once these are deleted this action cannot be undone. Please note that deleting inquiries will not result in cancellation of the booking on the originating channel.',
        extra: 'Also delete all guests associated with this inquiry.',
      })
      .subscribe((result) => {
        this.store.dispatch(
          DeleteInquiries({
            inquiryIds: R.map((i) => i.id, inquiries),
            guestIds: R.map((i) => i.guestId, inquiries),
            guest: result.isChecked,
          })
        )
      })
    return of(true).pipe(
      take(1),
      filter((result) => result)
    )
  }
}
