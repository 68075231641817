import { Inject, Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient } from '@angular/common/http'
import { LocalStorage } from '../storage'
import { ENVIRONMENT } from '../tokens'

@Pipe({
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private storage: LocalStorage,
    @Inject(ENVIRONMENT) private environment
  ) {}

  download(resourceUrl: string, type: any): Observable<any> {
    return this.http.get(resourceUrl, {
      responseType: type,
      headers: {
        Authorization: this.storage.get(this.environment.storageKeys.token),
      },
    })
  }

  transform(url: string, blank = true, type: 'blob' | 'arraybuffer' | 'base64' = 'base64') {
    return new Observable<any>((observer) => {
      if (blank) {
        // This is a tiny blank image
        observer.next({
          image: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
          loading: true,
        })
      }

      this.download(url, type === 'arraybuffer' ? 'arraybuffer' : 'blob').subscribe((data) => {
        if (type !== 'base64') {
          observer.next({ image: data, loading: false })
          observer.complete()
          return
        }

        // return new observable which emits a base64 string when blob is converted to base64
        const reader = new FileReader()
        reader.readAsDataURL(data) // convert blob to base64
        reader.onloadend = () => {
          // @ts-ignore
          // emit the base64 string result
          observer.next({ image: this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result), loading: false })
          observer.complete()
        }
      })
    })
  }
}
