<div class="table-holder table-responsive">
  <table class="table table-default table-striped" [hidden]="isEmptyTable$ | async">
    <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Uploaded</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span
            [matTooltip]="element.created | epoch"
            matTooltipPosition="above"
            [innerHtml]="element.createdView"
          ></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef mat-sort-header> User</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-avatar-name [name]="element.user"></app-avatar-name>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <i class="text-xxl fa-fw" [ngClass]="element.name | fileIcon"></i>
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="url">
        <mat-header-cell *matHeaderCellDef class="text-center"> Download</mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-center">
          <a href="javascript:;" (click)="onDownloadFile(element)" class="td-btn text-success">
            <i class="cs-icon cs-icon-cloud-download"></i>
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef class="text-center"> Delete</mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-center">
          <a (click)="onDelete(element)" *ngIf="!(1 | isReadonlyRole)" class="td-btn text-danger">
            <i class="cs-icon cs-icon-trash-alt"></i>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>
  </table>
  <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
</div>
