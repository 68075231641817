import { Pipe, PipeTransform } from '@angular/core'
import { selectRentalById, TokeetCurrencyPipe } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as fromRoot from '@tv3/store/state'
import { Observable } from 'rxjs'
import * as R from 'ramda'

@Pipe({
  name: 'rentalCurrencySymbol',
})
export class GetRentalCurrencySymbolPipe implements PipeTransform {
  constructor(private store: Store<fromRoot.State>, private tokeetCurrencyPipe: TokeetCurrencyPipe) {}

  transform(amount: string, rentalId: string): Observable<string> {
    return this.store.pipe(
      select(selectRentalById(rentalId)),
      map((rental) => {
        const symbol = R.pathOr('$', ['currency', 'symbol'], rental)
        return this.tokeetCurrencyPipe.transform(amount, symbol)
      })
    )
  }
}
