import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { AgodaContentPropertySettings } from '@tv3/store/channel-property-setting/settings/agoda-property-settings'

export function createForm(fb: FormBuilder) {
  return fb.group({
    rules: [undefined, [Validators.required]],
    directions: [undefined, [Validators.required]],
    license: [],
    localRecommendations: [],
  })
}

@Component({
  selector: 'app-agoda-content-step-instruction-form',
  templateUrl: './agoda-content-step-instruction-form.component.html',
  styleUrls: ['./agoda-content-step-instruction-form.component.scss'],
})
export class AgodaContentStepInstructionFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup
  @Input() rental: Rental
  @Input() settings: AgodaContentPropertySettings

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rental'] && changes['rental'].currentValue) {
      this.setForm(this.rental)
    }
    if (changes['settings'] && changes['settings'].currentValue) {
      this.setForm(this.rental)
    }
  }

  setForm(rental: Rental) {
    // Rental Instructions
    if (rental) {
      const instructions = rental.instructions
      const rules = (instructions.rules || '').replace(/<br[^>]*>/gi, '\n')
      const directions = (instructions.directions || '').replace(/<br[^>]*>/gi, '\n')
      this.form.patchValue({
        rules,
        directions,
      })
    }
    if (this.settings) {
      this.form.patchValue({
        license: this.settings.license,
        localRecommendations: this.settings.localRecommendations,
      })
    }
  }
}
