import { Component, Input } from '@angular/core'

declare const window: any

@Component({
  selector: 'app-page-help',
  templateUrl: './page-help.component.html',
  styleUrls: ['./page-help.component.scss'],
})
export class PageHelpComponent {
  @Input() articleId: string
  @Input() categoryId: string
  @Input() tooltipText = 'Click here to see the associated help article.'
  @Input() infoTooltip: string
  @Input() docId: string

  @Input() videoId: string

  onHelpDocs() {
    window.Lighthouse.showButton()
    try {
      if (this.articleId) {
        window.Lighthouse.navigate('article', this.articleId)
      } else if (this.categoryId) {
        window.Lighthouse.navigate('category', this.categoryId)
      }
    } catch (e) {
      console.log('error')
    }
  }
}
