import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './channel-property-setting.reducer'
import * as state from './channel-property-setting.state'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { find } from 'lodash'
import { ChannelPropertySetting } from '@tv3/store/channel-property-setting/channel-property-setting.model'

export const channelPropertySettingState = createFeatureSelector<state.State>('channel-property-setting')

export const selectAllChannelPropertySettings = createSelector(channelPropertySettingState, reducer.selectAll)

export const selectChannelPropertySettingsLoaded = createSelector(
  channelPropertySettingState,
  (state) => state.isLoaded
)

export const selectChannelPropertySetting = (channel: ChannelNameTokens, propertyId: any) =>
  createSelector(selectAllChannelPropertySettings, (settings) => {
    return findChannelPropertySetting(settings, channel, propertyId)
  })

export function findChannelPropertySetting(
  settings: ChannelPropertySetting[],
  channelName: ChannelNameTokens,
  propertyId: any
) {
  // tslint:disable-next-line:triple-equals
  return find(settings, (s) => s.propertyId == propertyId && s.channel === channelName)
}
