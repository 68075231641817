import { Component, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators'
import { asyncScheduler, BehaviorSubject, combineLatest, Subject } from 'rxjs'
import * as moment from 'moment'
import * as lodash from 'lodash'
import { selectLosDiscountViewById } from '@tokeet-frontend/los-discount'
import {
  selectAllRentals,
  Rental,
  selectRentalById,
  taxV3ModalityLabels,
  asUTCEpoch,
  TOKEET_CHANNEL_RATE_CATEGORY,
  selectApiChannelsWithDefaults,
  DataCheckerService,
  RentalRatesGuard,
  RentalService,
} from '@tokeet-frontend/tv3-platform'
import { BookingEngineBookingFee, AuditDiscount } from '@tokeet/cost-resolver'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { AuthService } from '@tv3/services/auth.service'
import { LosDiscountOverlayService } from '@tv3/containers/discounts/los-discount/overlay/los-discount-overlay.service'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { BookingEngineCostRequest } from '@tv3/interfaces/requests/booking-engine-cost.request'
import { MatDialogRef } from '@angular/material/dialog'
import { BookingFeesService } from '@tokeet-frontend/rentals'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'

export interface CostEstimatorFormData {
  rentalId: string
  channelId: string
  arrive: number
  depart: number
  adults: number
  children?: number
}

@Component({
  selector: 'app-cost-estimator-dialog',
  templateUrl: './cost-estimator-dialog.component.html',
  styleUrls: ['./cost-estimator-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class CostEstimatorDialogComponent implements OnInit, OnDestroy {
  rentals$ = this.store.pipe(select(selectAllRentals))
  channels$ = this.store.pipe(select(selectChannelsWithCustomChannels(selectApiChannelsWithDefaults)))

  form = this.fb.group({
    rentalId: [undefined, [Validators.required]],
    channelId: [undefined],
    arrive: [undefined, [Validators.required]],
    depart: [undefined, [Validators.required]],
    adults: [undefined, [Validators.required, Validators.min(1)]],
    children: [undefined, [Validators.min(0)]],
    discount_code: [],
    category: ['default'],
  })

  selectedRental: Rental

  baseChargeCtrl = new FormControl(undefined, [Validators.required, Validators.min(0)])

  modalityLabels = taxV3ModalityLabels
  rateCategories: string[] = []

  minEndDate: Date
  maxStartDate: Date

  estimatedCost = new BehaviorSubject<BookingEngineCost>(null)
  potentialFees: BookingEngineBookingFee[] = []
  destroy$ = new Subject()

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CostEstimatorDialogComponent>,
    private costService: InquiryChargeService,
    private bookingFeesService: BookingFeesService,
    private losDiscountDialog: LosDiscountOverlayService,
    private rentalService: RentalService,
    private authService: AuthService,
    dataChecker: DataCheckerService
  ) {
    dataChecker.check([RentalRatesGuard])
  }

  ngOnInit() {
    this.form
      .get('arrive')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter((v) => !!v),
        takeUntil(this.destroy$)
      )
      .subscribe((date) => {
        const start = moment(date).add(1, 'days').startOf('day')
        this.minEndDate = start.toDate()
      })

    this.form
      .get('depart')
      .valueChanges.pipe(
        distinctUntilChanged(),
        filter((v) => !!v),
        takeUntil(this.destroy$)
      )
      .subscribe((date) => {
        const end = moment(date).subtract(1, 'days').endOf('day')
        this.maxStartDate = end.toDate()
      })

    combineLatest([this.form.valueChanges, this.baseChargeCtrl.valueChanges.pipe(startWith(undefined))])
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        filter(([formData]) => this.isReadyForBookingEngine(formData)),
        map(([formData, userCharge]) => this.convertFormToBookingEnginePayload(formData, userCharge)),
        switchMap((payload) => this.costService.getEngineCost(payload)),
        takeUntil(this.destroy$)
      )
      .subscribe((cost) => {
        this.estimatedCost.next(cost)
        this.baseChargeCtrl.patchValue(cost.base, { emitEvent: false })
      })

    this.estimatedCost
      .pipe(
        switchMap(() => {
          const payload = this.form.getRawValue()
          const currentUser = this.authService.user
          return this.bookingFeesService.getApplicableFees(
            payload.rentalId,
            payload.arrive,
            payload.depart,
            payload.adults,
            payload.children,
            currentUser && currentUser.account
          )
        })
      )
      .subscribe((fees) => {
        this.potentialFees = fees
      })

    this.form
      .get('rentalId')
      .valueChanges.pipe(
        filter((rid) => !!rid),
        switchMap((rid) => this.store.pipe(select(selectRentalById(rid)))),
        takeUntil(this.destroy$)
      )
      .subscribe((rental) => {
        this.selectedRental = rental
        this.rateCategories = this.rentalService.getUniqueCategories(rental.rates)
      })

    //
    this.baseChargeCtrl.disable({ emitEvent: false })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  setStartEndMinMax() {
    if (this.form.get('arrive').value) {
      const start = moment(this.form.get('arrive').value).add(1, 'days').startOf('day')
      this.minEndDate = start.toDate()
    }

    if (this.form.get('depart').value) {
      const end = moment(this.form.get('depart').value).subtract(1, 'days').endOf('day')
      this.maxStartDate = end.toDate()
    }
  }

  isReadyForBookingEngine(formData: CostEstimatorFormData): boolean {
    return !(!formData.rentalId || !formData.arrive || !formData.depart || !formData.adults)
  }

  adjustDate(date: Date): number {
    return moment
      .utc(asUTCEpoch(date) * 1000)
      .startOf('day')
      .add(11, 'hours')
      .unix()
  }

  convertFormToBookingEnginePayload(formData: any, userCharge?: number): BookingEngineCostRequest {
    const request: BookingEngineCostRequest = {
      start: this.adjustDate(formData.arrive),
      end: this.adjustDate(formData.depart),
      adults: formData.adults || 1,
      child: formData.children || 0,
      category: formData.category || TOKEET_CHANNEL_RATE_CATEGORY,
      rid: formData.rentalId,
      channel_id: formData.channelId,
      discounts: lodash.filter(formData.discounts, (d: { amount: number }) => lodash.isNumber(d.amount)),
    }

    if (lodash.isNumber(userCharge)) {
      request.usercharge = userCharge
    }

    if (formData.discount_code) {
      request.discount_code = formData.discount_code
    }

    return request
  }

  onViewDiscount(item: AuditDiscount) {
    if (item.disc_type !== 'lengthofstay') {
      return
    }
    this.store
      .pipe(
        select(selectLosDiscountViewById, { id: item.id }),
        take(1),
        switchMap((discount) => this.losDiscountDialog.open(discount).afterClosed())
      )
      .subscribe()
  }

  close() {
    this.dialogRef.close()
  }
}
