import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Channel, SaveForm } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { FeedConfig, HomeToGoRentalConfigView } from '../../../../store/channels.model'
import { FeesOverlayService, TaxesOverlayService } from '@tokeet-frontend/rentals'
import { HomeToGoChannelConfigFormAbstractComponent } from './hometogo-channel-config-form.abstract.component'

@Component({
  selector: 'app-hometogo-channel-config-form',
  templateUrl: './hometogo-channel-config-form.component.html',
  styleUrls: ['./hometogo-channel-config-form.component.scss'],
})
export class HomeToGoChannelConfigForm extends HomeToGoChannelConfigFormAbstractComponent implements OnInit {
  @Input() channel: Channel
  @Input() config: FeedConfig
  @Input() title: string
  @Input() taxesFees: 'single' | 'bulk'

  @Input() primaryButtonClass = 'btn btn-secondary-info '
  @Input() saveText = 'Save'
  @Output() save = new EventEmitter()
  @Output() taxesAndFeesUpdate = new EventEmitter()

  constructor(
    fb: FormBuilder,
    cd: ChangeDetectorRef,
    store: Store<any>,
    private taxesOverlayService: TaxesOverlayService,
    private feesOverlayService: FeesOverlayService
  ) {
    super(fb, cd, store)
  }

  ngOnInit() {
    this.init()
  }

  addTaxes() {
    if (this.taxesFees === 'bulk') {
      this.taxesOverlayService
        .open()
        .afterClosed()
        .subscribe(() => {
          this.taxesAndFeesUpdate.emit(true)
        })
    } else {
      const config = this.config as HomeToGoRentalConfigView
      // this.editTaxesDialogService
      //   .openEditRentalTaxes(config.rental)
      //   .afterClosed()
      //   .subscribe(() => {
      //     this.taxesAndFeesUpdate.emit(true)
      //   })
    }
  }

  addFees() {
    if (this.taxesFees === 'bulk') {
      this.feesOverlayService
        .open()
        .afterClosed()
        .subscribe(() => {
          this.taxesAndFeesUpdate.emit(true)
        })
    } else {
      const config = this.config as HomeToGoRentalConfigView
      // this.bookingFeesDialogService
      //   .open(config.rental)
      //   .afterClosed()
      //   .subscribe(() => {
      //     this.taxesAndFeesUpdate.emit(true)
      //   })
    }
  }

  @SaveForm()
  onSave(form: FormGroup) {
    this.save.emit({ payload: form.getRawValue(), channel: this.channel })
  }
}
