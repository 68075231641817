import { Component, HostListener, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Destroyable, selectRentalById } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { BehaviorSubject } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-rental-name',
  templateUrl: './rental-name.component.html',
  styleUrls: ['./rental-name.component.scss'],
})
export class RentalNameComponent extends Destroyable implements OnInit {
  rentalId$ = new BehaviorSubject<string>('')

  @Input() set rentalId(rentalId: string) {
    this.rentalId$.next(rentalId)
  }
  get rentalId() {
    return this.rentalId$.value
  }

  @Input() image = false
  @Input() imageSize: number = 25
  @Input() imageCircle = false
  @Input() clickable = false

  @Input() tooltipDisabled = false

  rental$ = this.rentalId$.pipe(
    filter((rentalId) => !!rentalId),
    switchMap((rentalId) => this.store.pipe(select(selectRentalById(rentalId))))
  )

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {}

  @HostListener('click')
  onClick() {
    if (!this.clickable) return
    this.store.dispatch(OpenRentalOverlay({ rentalId: this.rentalId$.value }))
  }
}
