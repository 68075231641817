import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import {
  Destroyable,
  ChannelNameTokens,
  Rental,
  selectRentalById,
  untilDestroy,
  Channel,
} from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap } from 'rxjs/operators'
import { PushRatesToConnectionComplete } from '@tv3/store/connection/connection.actions'
import { Connection } from '@tv3/store/connection/connection.model'
import { ConnectionHelperService } from '../../../connection-helper.service'
import { EditRateDialogService } from '@tv3/containers/rates/edit-rate-dialog/edit-rate-dialog.service'
import { selectConnectionById } from '@tv3/store/connection/connection.selectors'

@Component({
  selector: 'app-bdc-listing-wizard-step2',
  templateUrl: './bdc-listing-wizard-step2.component.html',
  styleUrls: ['./bdc-listing-wizard-step2.component.scss'],
})
export class BDCListingWizardStep2Component extends Destroyable implements OnInit {
  @Input() connection: Connection
  @Input() channel: Channel
  rental: Rental

  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  isRatesPushed = false

  constructor(
    private actions: Actions,
    private store: Store<any>,
    private connectionHelperService: ConnectionHelperService,
    private editRateDialog: EditRateDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectConnectionById, { id: this.connection.id }),
        tap((c) => (this.connection = c)),
        switchMap((c) => this.store.pipe(select(selectRentalById(c.rentalId)))),
        untilDestroy(this)
      )
      .subscribe((rental) => {
        this.rental = rental
      })
  }

  onAddStandardRate() {
    this.addRentalRate('standard')
  }

  onAddDynamicRate() {
    this.addRentalRate('dynamic')
  }

  onPushRates() {
    this.connectionHelperService
      .pushRates(this.channel, this.rental, this.connection)
      .pipe(
        switchMap(() => this.actions.pipe(ofType(PushRatesToConnectionComplete))),
        tap(() => (this.isRatesPushed = true)),
        take(1),
        untilDestroy(this)
      )
      .subscribe()
  }

  addRentalRate(type: 'standard' | 'dynamic') {
    this.editRateDialog.open({
      rentals: [this.rental],
      rental: this.rental,
      type,
    })
  }
}
