<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-user-tag"></i>New Attribute</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-sm-12">
      <p>Please enter new attribute name</p>
    </div>
    <div class="col-sm-12">
      <form [formGroup]="form">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="value" required matInput placeholder="Attribute Name" />
          <mat-error *ngIf="form.get('value').touched && form.get('value').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('value').touched && form.get('value').hasError('allSpaces')">
            Value <strong>must not be empty</strong>
          </mat-error>
          <mat-error *ngIf="form.get('value').touched && form.get('value').hasError('pattern')">
            Value can only contain <strong>letters and spaces</strong>
          </mat-error>
        </mat-form-field2>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
