import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, tap } from 'rxjs/operators'
import { deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import { TokeetImage } from '@tv3/store/website-image/website-image.model'
import { Observable } from 'rxjs'
import { TokeetImagePayload } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class WebsiteImageService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all(websiteId: string): Observable<TokeetImage[]> {
    const url = `@api/image/website/${websiteId}`
    return this.http.get(url).pipe(deserializeArray<TokeetImage>(TokeetImage))
  }

  add(payload: TokeetImagePayload): Observable<TokeetImage> {
    const url = `@api/image`
    return this.http.post<TokeetImage>(url, payload).pipe(map((response) => TokeetImage.deserialize(response)))
  }

  update(imageId: string, payload: any): Observable<TokeetImage> {
    const url = `@api/image/${imageId}`
    return this.http.put(url, payload).pipe(map((res) => TokeetImage.deserialize(res)))
  }

  markAsPrimary(websiteId: string, imageId: string) {
    const url = `@api/image/primary/website/${websiteId}/${imageId}`
    return this.http.put(url, {}).pipe(map((res) => TokeetImage.deserialize(res)))
  }

  markAsFavicon(websiteId: string, imageId: string) {
    const url = `@api/image/favicon/website/${websiteId}/${imageId}`
    return this.http.put(url, {}).pipe(map((res) => TokeetImage.deserialize(res)))
  }

  markAsLogo(websiteId: string, imageId: string) {
    const url = `@api/image/logo/website/${websiteId}/${imageId}`
    return this.http.put(url, {}).pipe(map((res) => TokeetImage.deserialize(res)))
  }

  delete(imageId) {
    const url = `@api/image/delete/${imageId}`
    return this.http.delete(url)
  }
}
