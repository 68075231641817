import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { switchMap, take } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { ComponentType } from '@angular/cdk/portal'
import {
  HomeToGoRentalConfigView,
  LoadChannelConfig,
  LoadRentalConfigs,
  LoadRentalConfigsSuccess,
} from '@tokeet-frontend/channels'
import { HomeToGoConnectionOverlayComponent } from '@tv3/containers/channels/connection-detail/feed/hometogo-connection-overlay/hometogo-connection-overlay.component'
import { Channel, ChannelNameTokens, DialogService, Rental } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import * as R from 'ramda'
import { of } from 'rxjs'
import { ConnectionView } from '@tv3/store/connection/connection.view'

@Injectable({
  providedIn: SharedModule,
})
export class HomeToGoConnectionOverlayService {
  constructor(private dialog: DialogService, private store: Store<any>, private actions$: Actions) {}

  public open<T>(config: HomeToGoRentalConfigView, channel: Channel, component: ComponentType<T>): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { config, channel },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  public openSide(data: {
    rental: Rental
    channel: Channel
    config?: HomeToGoRentalConfigView
    connection?: ConnectionView
  }) {
    if (data.config) {
      return this.open(data.config, data.channel, HomeToGoConnectionOverlayComponent).afterClosed()
    }

    const connection = data.connection
    this.store.dispatch(LoadChannelConfig({ name: ChannelNameTokens.HomeToGo }))
    this.store.dispatch(LoadRentalConfigs({ channel: ChannelNameTokens.HomeToGo }))

    return this.actions$.pipe(
      ofType(LoadRentalConfigsSuccess),
      take(1),
      switchMap((result) => {
        const config = R.find(
          (c) => c.rental_id === connection.rentalId && c.channel_id === connection.channelId,
          result.configs
        )
        if (!!config) {
          const configView = {
            ...config,
            rental: data.rental,
          } as HomeToGoRentalConfigView
          return this.open(configView, data.channel, HomeToGoConnectionOverlayComponent).afterClosed()
        }
        return of(false)
      })
    )
  }
}
