import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Destroyable, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { defaultTinyMCEOptions } from '@tv3/constants/default-tinymce-options'
import { uniqueId, omit } from 'lodash'
import { AiResponderContentSharingItem } from '../../store/ai-settings.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Store, select } from '@ngrx/store'
import { UpdateAISettings, UpdateAISettingsComplete } from '../../store/ai-settings.actions'
import { Actions, ofType } from '@ngrx/effects'
import { selectAISettingsIsSaving } from '@tv3/containers/ai-responder/store/ai-settings.selectors'
interface FAQItem extends AiResponderContentSharingItem {
  id: string
}

@Component({
  selector: 'ai-responder-faq',
  templateUrl: './ai-responder-faq.component.html',
  styleUrls: ['./ai-responder-faq.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AiResponderFaqComponent extends Destroyable implements OnInit {
  editorOptions = defaultTinyMCEOptions

  @ViewChild('paginator') set paginator(p: MatPaginator) {
    this.dataSource.paginator = p
  }
  @ViewChild(MatSort) set sort(s: MatSort) {
    this.dataSource.sort = s
  }
  @Input() set faqs(faqs: AiResponderContentSharingItem[]) {
    this.dataSource.data = faqs?.map((t) => ({ id: uniqueId(), ...t })) || []
  }
  get faqItems(): FAQItem[] {
    return this.dataSource.data
  }

  displayedColumns = ['title', 'preBooking', 'postBooking', 'postCheckin', 'actions']
  dataSource = new MatTableDataSource<FAQItem>()
  expandedItem: FAQItem

  form = this.fb.group({
    id: [],
    content: ['', [Validators.required]],
  })

  isLoading$ = this.store.pipe(select(selectAISettingsIsSaving))

  constructor(private store: Store<any>, private actions: Actions, private fb: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(UpdateAISettingsComplete), untilDestroy(this)).subscribe(() => {
      this.form.reset()
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { content, id } = this.form.getRawValue()
    let faqs: AiResponderContentSharingItem[]
    const item = this.faqItems.find((t) => t.id == id)
    if (item) {
      faqs = this.faqItems.map((t) => (t.id == item.id ? { ...t, content } : t))
    } else {
      faqs = [
        ...this.faqItems,
        { content, preBooking: true, postBooking: true, postCheckin: true } as AiResponderContentSharingItem,
      ]
    }
    this.saveFaqs(faqs)
  }

  onTogglePreBooking(item: FAQItem, checked: boolean) {
    this.onToggleChanged({ ...item, preBooking: checked })
  }

  onTogglePostBooking(item: FAQItem, checked: boolean) {
    this.onToggleChanged({ ...item, postBooking: checked })
  }

  onTogglePostCheckin(item: FAQItem, checked: boolean) {
    this.onToggleChanged({ ...item, postCheckin: checked })
  }

  onToggleChanged(item: FAQItem) {
    const faqs = this.faqItems.map((t) => (t.id == item.id ? item : t))
    this.saveFaqs(faqs)
  }

  onEdit(item: FAQItem) {
    this.form.patchValue(item)
  }

  onDelete(id: string) {
    const faqs = this.faqItems.filter((t) => t.id != id)
    this.saveFaqs(faqs)
  }

  saveFaqs(faqs: AiResponderContentSharingItem[]) {
    this.store.dispatch(
      UpdateAISettings({
        payload: { faqs: faqs.map((t) => omit(t, 'id')) },
        message: `Data saved successfully`,
      })
    )
  }

  getItemIndex(id: string) {
    return this.faqItems.findIndex((t) => t.id == id)
  }
}
