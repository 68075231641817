import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import {
  addRentalImagesComplete,
  AUTH_TOKEN,
  ConfirmDialogService,
  Destroyable,
  isSomething,
  Rental,
  SaveForm,
  selectRentalById,
  untilDestroy,
  updateRentalBaseRateComplete,
  updateRentalComplete,
  updateRentalDetailsComplete,
  updateRentalInstructionsComplete,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  GVRListingViewModel,
  CreateGVRListingSuccess,
  UpdateGVRListingSuccess,
  UpdateGVRListing,
  GVRChannelService,
  ListingValidationFieldResultItem,
  GVRListingPayload,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs'
import * as lodash from 'lodash'
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators'
import { selectAllWebsites } from '@tv3/store/website/website.selectors'
import { WebsiteTypes } from '@tv3/store/website/website.model'
import { RentalValidationFixHelperService } from '../../rental-validation-fix-helper.service'
import { environment } from '@tv3/environments/environment'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { UpdateRoomComplete, UpsertRoomsComplete, DeleteRoomComplete } from '@tokeet-frontend/rentals'
import { WebsiteOverlayService } from '@tv3/containers/websites/overlays/website-overlay.service'
import { PublishWebsiteComplete } from '@tv3/store/website/website.actions'

@Component({
  selector: 'app-gvr-listing-details-overlay',
  templateUrl: './gvr-listing-details-overlay.component.html',
  styleUrls: ['./gvr-listing-details-overlay.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class GVRListingDetailsOverlayComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    cancellationPolicyType: [],
    websiteId: ['', [Validators.required]],
    websiteDomainId: [],
    websiteDisplayName: [],
  })

  get storeLink() {
    let token = this.token$.value
    return `${environment.appStoreUrl}login?token=${token}&redirect=details/webready`
  }

  domains$ = new BehaviorSubject<{ websiteId: string; _id: string }[]>([])
  allWebreadyDomains: any[] = []

  provider: string = 'tokeet'
  errors: ListingValidationFieldResultItem[] = []

  isLoadingWebsites = true
  websites$ = new BehaviorSubject<{ id: string; name: string; provide: string }[]>([])
  isDomainRequired = false
  isDomainDisabled = true
  rental: Rental

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private fb: FormBuilder,
    private actions: Actions,
    private confirm: ConfirmDialogService,
    private gvrService: GVRChannelService,
    private websiteOverlay: WebsiteOverlayService,
    private validationFixHelper: RentalValidationFixHelperService,
    @Inject(AUTH_TOKEN) private token$: BehaviorSubject<string>,
    @Inject(MAT_DIALOG_DATA) public data: { listing: GVRListingViewModel },
    public dialogRef: MatDialogRef<GVRListingDetailsOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.actions.pipe(ofType(CreateGVRListingSuccess, UpdateGVRListingSuccess), untilDestroy(this)).subscribe(() => {
      this.close()
    })

    this.actions
      .pipe(
        ofType(PublishWebsiteComplete),
        switchMap(() => this.validateRental()),
        untilDestroy(this)
      )
      .subscribe()

    this.store.pipe(select(selectRentalById(this.data.listing.rentalId)), untilDestroy(this)).subscribe((rental) => {
      this.rental = rental
    })

    combineLatest([
      this.store.pipe(select(selectAllWebsites)),
      this.http
        .get<any[]>(`@webready/private/websites`)
        .pipe(map((items) => lodash.filter(items, (t) => lodash.includes(t.rentals, this.data.listing.rentalId)))),
      this.http.get<any[]>(`@webready/private/domains`),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([tokeetWebsites, items, domains]) => {
        this.isLoadingWebsites = false
        const webreadyWebsites = items
        this.allWebreadyDomains = domains
        this.websites$.next([
          ...tokeetWebsites
            .filter((t) => t.type === WebsiteTypes.RentalWebsite)
            .filter((t) => lodash.includes(t.rentals, this.data.listing.rentalId))
            .map((t) => ({ id: t.id, name: t.title, provide: 'tokeet' })),
          ...webreadyWebsites.map((t) => ({ id: t._id as string, name: t.name as string, provide: 'webready' })),
        ])
      })

    combineLatest([this.websites$.pipe(filter(isSomething)), this.form.get('websiteId').valueChanges])
      .pipe(untilDestroy(this))
      .subscribe(([websites, websiteId]) => {
        const websiteData = websites.find((t) => t.id === websiteId)
        const domainCtrl = this.form.get('websiteDomainId')

        if (websiteData?.provide === 'webready') {
          const domains = this.allWebreadyDomains.filter((t) => t.websiteId === websiteData.id)
          this.domains$.next(domains)
          domainCtrl.setValidators([Validators.required])
          domainCtrl.enable()
          domainCtrl.setValue(domains.find((t) => t._id === domainCtrl.value)?._id)
          this.isDomainRequired = true
          this.isDomainDisabled = false
        } else {
          domainCtrl.clearValidators()
          domainCtrl.disable()
          domainCtrl.reset()
          this.isDomainRequired = false
          this.isDomainDisabled = true
        }
      })
    const rentalChange$ = this.actions.pipe(
      ofType(
        updateRentalComplete,
        updateRentalDetailsComplete,
        UpdateRoomComplete,
        UpsertRoomsComplete,
        addRentalImagesComplete,
        DeleteRoomComplete,
        updateRentalInstructionsComplete,
        updateRentalBaseRateComplete
      )
    )

    combineLatest([this.form.valueChanges, rentalChange$.pipe(startWith(true))])
      .pipe(
        debounceTime(500),
        switchMap(([v]) => {
          const { websiteId, websiteDomainId } = v
          const websiteData = this.websites$.value.find((t) => t.id === websiteId)
          if (websiteData?.provide === 'tokeet' && websiteId) {
            return this.validateRental()
          } else if (websiteData?.provide === 'webready' && websiteId && websiteDomainId) {
            return this.validateRental()
          }
          return of([])
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.form.patchValue({ ...this.data.listing })
  }

  validateRental() {
    const { websiteId, websiteDomainId } = this.form.getRawValue()
    const websiteData = this.websites$.value.find((t) => t.id === websiteId)

    const payload = {
      websiteId,
      websiteDomainId,
      websiteProvider: websiteData.provide,
      rentalId: this.data.listing.rentalId,
    }

    return this.gvrService.getRentalValidation(payload).pipe(
      tap((errors) => {
        this.errors = errors
        this.provider = payload.websiteProvider
      }),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { cancellationPolicyType, websiteId, websiteDomainId, websiteDisplayName } = form.getRawValue()
    const websiteData = this.websites$.value.find((t) => t.id === websiteId)

    const payload: GVRListingPayload = {
      websiteId,
      websiteDomainId,
      websiteProvider: websiteData.provide,
      websiteDisplayName,
      rentalId: this.data.listing.rentalId,
      cancellationPolicyType,
    }

    this.validateRental().subscribe((errors) => {
      errors = lodash.filter(errors, (t) => t.type === 'error')
      if (errors.length) {
        return
      }

      this.store.dispatch(UpdateGVRListing({ listing: payload }))
    })
  }

  onFixRental(field: ListingValidationFieldResultItem) {
    if (this.provider === 'webready') {
      this.confirm
        .confirm({
          title: 'Fix rental data?',
          body: "Please go to the Webready app to fix this issue, because you're trying to link a Webready website with this listing.",
          confirmText: 'Open Webready',
        })
        .subscribe(() => {
          window.open(`${environment.webreadyUrl}login?token=${this.token$.value}`, '_blank')
        })
    } else {
      this.validationFixHelper.onFixRental(this.rental, field, this.errors, () => {
        if (field.field === 'website') {
          if (field.code === 'website-unpublished') {
            this.onPublishWebsite()
          }
        }
      })
    }
  }

  onCheckWebsiteData() {
    this.validateRental().subscribe({
      error: (error) => {
        this.store.dispatch(ActionFailed({ error }))
      },
    })
  }

  onPublishWebsite() {
    const { websiteId } = this.form.getRawValue()
    const websiteData = this.websites$.value.find((t) => t.id === websiteId)
    if (!websiteData) {
      return
    }

    if (websiteData.provide === 'webready') {
      window.open(`${environment.webreadyUrl}login?token=${this.token$.value}`, '_blank')
    } else {
      this.websiteOverlay.openSide(websiteId)
    }
  }

  close() {
    this.dialogRef.close()
  }
}
