import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { DataCheckerService, Destroyable, selectUsers, untilDestroy, User } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { TaskChecklistItem, TaskResponse } from '@tv3/store/task/task.model'
import * as fromRoot from '@tv3/store/state'
import { keyBy } from 'lodash'
import { AuthService } from '@tv3/services/auth.service'
import { UserGuard } from '@tv3/guards/user.guard'

export type TaskChecklistFormData = {
  items: TaskChecklistItem[]
}

export function createTaskChecklistForm(fb: FormBuilder) {
  const form = fb.group({
    items: fb.array([]),
  })

  return form
}

export function createTaskChecklistItemForm(fb: FormBuilder, item?: TaskChecklistItem) {
  const data: Partial<TaskChecklistItem> = item || {}

  const form = fb.group({
    name: [data.name || '', [Validators.required]],
    description: [data.description || '', []],
    status: [data.status ?? 0, []],
    date_completed: [data.date_completed ?? undefined, []],
    completed_by: [data.completed_by ?? undefined, []],
  })

  return form
}

export function setTaskChecklistFormData(fb: FormBuilder, form: FormGroup, task: Partial<TaskResponse>) {
  const items = form.get('items') as FormArray

  items.clear()

  task.checklist?.forEach((item) => {
    if (item) items.push(createTaskChecklistItemForm(fb, item))
  })
}

export function getTaskChecklistFormData(form: FormGroup) {
  const data = form.getRawValue() as TaskChecklistFormData

  const items: TaskChecklistItem[] = []

  data.items?.forEach((item) => {
    if (!item || !item.name) return

    items.push({
      name: item.name,
      description: item.description || '',
      status: item.status || 0,
      date_completed: item.date_completed || 0,
      completed_by: item.completed_by || '',
    })
  })

  return items
}

@Component({
  selector: 'app-task-checklist',
  templateUrl: './task-checklist.component.html',
  styleUrls: ['./task-checklist.component.scss'],
})
export class TaskChecklistComponent extends Destroyable implements OnInit {
  @Input() form: FormGroup

  tableType = TableType.TaskChecklist

  users: Record<string, User> = {}

  taskCtrl = new FormControl('')

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private auth: AuthService,
    dataChecker: DataCheckerService
  ) {
    super()

    dataChecker.check([UserGuard])
  }

  ngOnInit(): void {
    this.store.pipe(select(selectUsers), untilDestroy(this)).subscribe((users) => {
      this.users = keyBy(users, (user) => user.id)
    })
  }

  get arrItems() {
    return this.form.get('items') as FormArray
  }

  onAdd(index: number | null) {
    const form = createTaskChecklistItemForm(this.fb)

    if (index === null) {
      this.arrItems.push(form)
    } else {
      this.arrItems.insert(index, form)
    }
  }

  onRemove(index: number) {
    this.arrItems.removeAt(index)
  }

  get isEmpty() {
    return !this.arrItems.controls.length
  }

  // isCompleted(index: number) {
  //   return this.arrItem(index, 'status').value === 1
  // }

  // toggleCompleted(index: number) {
  //   const value = this.isCompleted(index) ? 0 : 1
  //   this.arrItem(index, 'status').setValue(value)
  //
  //   const now = moment().unix()
  //   this.arrItem(index, 'date_completed').setValue(now)
  //   this.arrItem(index, 'completed_by').setValue(this.auth.user.id)
  // }
  //
  // getCompletedAt(index: number) {
  //   return this.arrItem(index, 'date_completed').value || null
  // }
  //
  // getCompletedBy(index: number) {
  //   const userId = this.arrItem(index, 'completed_by').value || null
  //
  //   return this.users[userId] || null
  // }

  hasError(index: number, name: string, error: string) {
    const control = this.arrItem(index, name)
    if (!control) return false
    return control.touched && control.hasError(error)
  }

  arrItem(index: number, name: string) {
    return this.arrItems.controls[index].get(name)
  }

  onEnterPressed() {
    if (this.taskCtrl.value) {
      const form = createTaskChecklistItemForm(this.fb, { name: this.taskCtrl.value })
      this.arrItems.push(form)
      this.resetTask()
    }
  }

  resetTask() {
    this.taskCtrl.patchValue('')
  }
}
