import { ChangeDetectorRef } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Channel, Destroyable } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { FeedConfig, HomeToGoFee, HomeToGoTax } from '../../../../store/channels.model'

export abstract class HomeToGoChannelConfigFormAbstractComponent extends Destroyable {
  channel: Channel
  config: FeedConfig

  form = this.fb.group({
    payment_terms: ['', [Validators.required]],
    payment_conditions: [''],
    cancellation_policy: [''],
  })

  filteredTaxOptions = {}
  filteredFeeOptions = {}

  constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef, protected store: Store<any>) {
    super()
  }

  init() {
    if (this.config) {
      this.form.patchValue({
        payment_terms: this.config.payment_terms,
        payment_conditions: this.config.payment_conditions,
        cancellation_policy: this.config.cancellation_policy,
      })
    }
  }

  toFormGroup(tax: Partial<HomeToGoTax> | Partial<HomeToGoFee>) {
    return this.fb.group({
      name: [tax.name, Validators.required],
      type: [tax.type, Validators.required],
      modality: [tax.modality, Validators.required],
      value: [tax.value, Validators.required],
    })
  }
}
