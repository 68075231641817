import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { CustomDomainResponse, getCustomSslStatus } from '@tokeet-frontend/ssl'
import { ConfirmDialogService, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Website } from '@tv3/store/website/website.model'
import { WebsiteCustomDomainSslDialogService } from '@tv3/containers/websites/dialogs/website-custom-domain-ssl-dialog/website-custom-domain-ssl-dialog.service'
import { Store, select } from '@ngrx/store'
import { selectCustomDomainById } from 'libs/ssl/src/lib/ssl.selectors'
import { BehaviorSubject } from 'rxjs'
import {
  DeleteCustomDomainCerts,
  GetOrCreateCustomDomain,
  RequestCustomDomainCerts,
} from 'libs/ssl/src/lib/ssl.actions'

@Component({
  selector: 'app-website-custom-domain-ssl',
  templateUrl: './website-custom-domain-ssl.component.html',
  styleUrls: ['./website-custom-domain-ssl.component.scss'],
})
export class WebsiteCustomDomainSslComponent extends Destroyable implements OnInit, OnChanges {
  @Input() website: Website
  @Input() asButton = false
  @Input() disabled = false

  domain: CustomDomainResponse
  sslStatus: ReturnType<typeof getCustomSslStatus>
  refresh$ = new BehaviorSubject(true)

  constructor(
    private store: Store<any>,
    private confirm: ConfirmDialogService,
    private websiteCustomDomainSslDialog: WebsiteCustomDomainSslDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectCustomDomainById(this.website.domain)), untilDestroy(this)).subscribe((domain) => {
      this.domain = domain
      this.sslStatus = getCustomSslStatus(domain)
    })

    this.refresh$.pipe(untilDestroy(this)).subscribe(() => {
      this.store.dispatch(GetOrCreateCustomDomain({ id: this.website.domain }))
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh$.next(true)
  }

  doRefresh() {
    this.refresh$.next(true)
  }

  openRequestCertificateConfirm() {
    this.confirm
      .confirm({
        title: 'Request SSL Certificate',
        confirmText: 'Request SSL Certificate',
        articleId: 'f8ziq5mi05',
        body: `
        Do you want to enable SSL for this domain? <br/><br/>
        Please make sure you have configured DNS to point your domain to AdvanceCM Website.
        Otherwise we cannot provide SSL Certificate for the domain. <br/><br/>
        The maximum process time is 2 hrs. If the SSL is not approved with in the time frame, kindly contact support.
      `,
      })
      .subscribe(() => {
        this.store.dispatch(RequestCustomDomainCerts({ id: this.website.domain }))
      })
  }

  openSslCertificateDialog() {
    this.websiteCustomDomainSslDialog.open(this.domain)
  }

  openDeleteCertificateConfirm() {
    this.confirm
      .confirm({
        title: 'Turn Off SSL?',
        body: `
        Are you sure you want to turn-off SSL for your domain.
        <i>
        In case you want to request SSL cerfificate again, please notice that <b>we can only issue maximum 5 SSL certificates per domain per week.</b>
        </i>
      `,
      })
      .subscribe(() => {
        this.store.dispatch(DeleteCustomDomainCerts({ id: this.website.domain }))
      })
  }
}
