import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  CtripPushRoomAvailabilityPayload,
  CtripPushRoomRatesPayload,
  CtripRoomModel,
  CtripRoomRatePlan,
  CtripStatus,
} from './model'

@Injectable({
  providedIn: 'root',
})
export class CtripChannelService {
  constructor(private http: HttpClient) {}

  getStatus() {
    const url = `@api/ctrip/connection/status`

    return this.http.get<CtripStatus>(url)
  }

  connect(hotelId: string) {
    const url = `@api/ctrip/connection/connect`

    return this.http.post<CtripStatus>(url, { hotelId })
  }

  disconnect(hotelId: string) {
    const url = `@api/ctrip/connection/disconnect`

    return this.http.post(url, { hotelId })
  }

  getRooms() {
    const url = `@api/ctrip/rooms/`

    return this.http.get<CtripRoomModel[]>(url)
  }

  getConnections() {
    const url = `@api/ctrip/listings/tokeet`

    return this.http.get<any[]>(url)
  }

  getRoomRatePlans(propertyId: any) {
    const url = `@api/ctrip/rooms/rate-plans?hotel_id=${propertyId}`

    return this.http.get<CtripRoomRatePlan[]>(url)
  }

  link(roomTypeCode: number, rentalId: string, propertyId: any) {
    const url = `@api/ctrip/rooms/link`

    return this.http.post<{ success: true }>(url, { roomTypeCode, rental_id: rentalId, hotel_id: propertyId })
  }

  unlink(roomTypeCode: number, propertyId: any) {
    const url = `@api/ctrip/rooms/unlink`

    return this.http.post<{ success: boolean }>(url, { roomTypeCode, hotel_id: propertyId })
  }

  pushRates(roomTypeCode: number, data: CtripPushRoomRatesPayload) {
    const url = `@api/ctrip/rooms/rates/push`

    return this.http.post<{ success: boolean }>(url, { ...data, roomTypeCode })
  }

  pusAvailability(roomTypeCode: number, data: CtripPushRoomAvailabilityPayload) {
    const url = `@api/ctrip/rooms/availability/push`

    return this.http.post<{ success: boolean }>(url, { ...data, roomTypeCode })
  }
}
