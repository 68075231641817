<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex align-items-center gap-3 flex-1">
      <h6 class="modal-title"><i class="far fa-user"></i> {{ isEdit ? 'Edit User' : 'Add User' }}</h6>
      <div class="flex-fill"></div>
    </div>
    <a (click)="close()" class="cstm-close">
      <span class="far fa-times"></span>
    </a>
  </div>
  <div class="modal-body bg-none p-0">
    <form [formGroup]="form">
      <div class="text-light text-uppercase mb-3">User Information</div>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field2 class="mat-block">
            <input
              type="text"
              formControlName="firstname"
              matInput
              required
              placeholder="First Name"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('firstname').touched && form.get('firstname').hasError('required')">
              First Name is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="form.get('firstname').touched && form.get('firstname').hasError('allNumbers')"
              class="text-nowrap"
            >
              Cannot be numbers alone.
            </mat-error>
            <mat-error
              *ngIf="form.get('firstname').touched && form.get('firstname').hasError('minlength')"
              class="text-nowrap"
            >
              At least <strong>2</strong> characters long
            </mat-error>
            <mat-error
              *ngIf="form.get('firstname').touched && form.get('firstname').hasError('maxlength')"
              class="text-nowrap"
            >
              At most <strong>50</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('firstname').hasError('name')" class="text-nowrap">
              Only alphanumeric allowed.
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-3">
          <mat-form-field2 class="mat-block">
            <input
              type="text"
              formControlName="lastname"
              matInput
              required
              placeholder="Last Name"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('lastname').touched && form.get('lastname').hasError('required')">
              Last Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('lastname').hasError('allNumbers')" class="text-nowrap">
              Cannot be numbers alone.
            </mat-error>
            <mat-error *ngIf="form.get('lastname').hasError('minlength')" class="text-nowrap">
              At least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('lastname').hasError('maxlength')" class="text-nowrap">
              At most <strong>50</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('lastname').hasError('name')" class="text-nowrap">
              Only alphanumeric allowed.
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-national-phone-input2 [ctrl]="form.get('phone')"></app-national-phone-input2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="email" formControlName="email" matInput required placeholder="Email" autocomplete="off" />
            <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('email')">
              Email must have valid format
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6" *ngIf="form.get('attributes') as attrsCtrl">
          <label class="form-field-label">Gender</label>
          <div class="d-flex gap-3">
            <button
              class="btn flex-1"
              (click)="attrsCtrl.get('gender').setValue('female')"
              [ngClass]="attrsCtrl.get('gender').value == 'female' ? 'btn-indigo' : 'btn-outline-indigo'"
            >
              <i class="cs-icon cs-icon-female"></i> Female
            </button>
            <button
              class="btn flex-1"
              (click)="attrsCtrl.get('gender').setValue('male')"
              [ngClass]="attrsCtrl.get('gender').value == 'male' ? 'btn-info' : 'btn-outline-info'"
            >
              <i class="cs-icon cs-icon-male"></i> Male
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="role" placeholder="User Role" [required]="true">
              <mat-option
                *ngFor="let role of roles$ | async"
                [value]="role.value"
                [disabled]="isYearlyOC && role.value == roles.owner.value"
                [matTooltip]="role.tooltip"
                matTooltipPosition="right"
                >{{ role.label }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="form.get('role').touched && form.get('role').hasError('required')">
              User Role is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-language-select [ctrl]="form.get('attributes').get('language')"></app-language-select>
        </div>
      </div>
      <div class="row pt-3 border-top" formGroupName="address">
        <div class="col-md-12">
          <div class="text-light text-uppercase mb-3">User Location</div>
        </div>
        <div class="col-md-12">
          <mat-form-field2 *ngIf="isGoogleLoaded" class="mat-block">
            <input
              appGooglePlaces
              (onAddressChange)="onPlaceChange($event)"
              type="text"
              formControlName="address"
              matInput
              placeholder="Address"
            />
            <mat-error *ngIf="addressFrom.get('address').touched && addressFrom.get('address').hasError('minlength')">
              Address must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="addressFrom.get('address').touched && addressFrom.get('address').hasError('maxlength')">
              Address must be at most <strong>140</strong> characters long
            </mat-error>
          </mat-form-field2>
          <mat-form-field2 *ngIf="!isGoogleLoaded" class="mat-block">
            <input type="text" formControlName="address" matInput placeholder="Address" />
            <mat-error *ngIf="addressFrom.get('address').touched && addressFrom.get('address').hasError('minlength')">
              Address must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="addressFrom.get('address').touched && addressFrom.get('address').hasError('maxlength')">
              Address must be at most <strong>140</strong> characters long
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="city" matInput placeholder="City" />
            <mat-error *ngIf="addressFrom.get('city').touched && addressFrom.get('city').hasError('minlength')">
              City must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="addressFrom.get('city').touched && addressFrom.get('city').hasError('maxlength')">
              City must be at most <strong>50</strong> characters long
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="state" matInput placeholder="State / Province / Region" />
            <mat-error *ngIf="addressFrom.get('state').touched && addressFrom.get('state').hasError('minlength')">
              Value must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="addressFrom.get('state').touched && addressFrom.get('state').hasError('maxlength')">
              Value must be at most <strong>50</strong> characters long
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-md-6">
          <app-country-select
            [nameCtrl]="addressFrom.get('country')"
            [codeCtrl]="addressFrom.get('country_code')"
          ></app-country-select>
        </div>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="postal_code" matInput placeholder="Postal Code" />
          </mat-form-field2>
        </div>
      </div>
      <div class="row pt-3 border-top" *ngIf="!isEdit">
        <div class="col-md-12">
          <div class="text-light text-uppercase mb-3">User Credentials</div>
        </div>

        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input
              matTooltip="The password is created by the administrator when creating a user profile.  The user will be able to change their password once they log in."
              matTooltipPosition="above"
              type="password"
              formControlName="password1"
              matInput
              required
              placeholder="Password: (Minimum length of 8 characters)"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('password1').touched && form.get('password1').hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('password1').touched && form.get('password1').hasError('minlength')">
              Password must be at least <strong>8</strong> characters long
            </mat-error>
          </mat-form-field2>
        </div>

        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <input
              type="password"
              formControlName="password2"
              matInput
              required
              placeholder="Repeat Password"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('password2').touched && form.get('password2').hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('password2').touched && form.get('password2').hasError('minlength')">
              Password must be at least <strong>8</strong> characters long
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-top" style="border-top-width: 2px !important">
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Cancel
    </button>
    <button (click)="isEdit ? onUpdate(form) : onCreate(form)" type="button" class="btn btn-secondary-info">
      <i class="cs-icon cs-icon-save"></i>
      Save
    </button>
  </div>
</div>
