import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-agoda-wizard-complete',
  templateUrl: './connect-agoda-wizard-complete.component.html',
  styleUrls: ['./connect-agoda-wizard-complete.component.scss'],
})
export class ConnectAgodaWizardCompleteComponent implements OnInit {
  @Output() reset = new EventEmitter()
  @Output() channel = new EventEmitter()
  @Output() done = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
