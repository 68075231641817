import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ChangePasswordDialogComponent } from '@tv3/shared/change-password/change-password-dialog/change-password-dialog.component'
import { User } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordDialogService {
  constructor(private dialog: MatDialog) {}

  open(user: User) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { user },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(ChangePasswordDialogComponent, defaultConfig)
  }
}
