import { epochToView, Expose, plainToClass, Serializable, Type } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'

export const defaultStealthHandler = 'Handlers::StealthHandler'
export const defaultStealthEvent = 'time.hourly.stealth'

export class StealthTriggerSettings extends Serializable<StealthTriggerSettings> {
  @Expose({ name: 'channel_id' })
  channelId: string
  hour: number // 21,
  stop: string //2019-04-16T12:53:40.205Z,
  @Expose({ name: 'gmt_start' })
  gmtStart: number
  @Expose({ name: 'gmt_stop' })
  gmtStop: number // 21,
  zone: string // Africa/Accra,
  @Expose({ name: 'rental_id' })
  rentalId: string // a70f8790-0676-4456-bf05-893260c65234,
  start: string // 2019-04-16T08:23:40.200Z
}

export class StealthTrigger extends Serializable<StealthTrigger> {
  @Expose({ name: 'pkey' })
  id: string

  service: string // AdvanceCM Stealth,
  archived: number
  event: string // time.hourly.stealth.stop,
  status: number // 1,
  created: number // 1555398754,
  handler: string // Handlers::StealthHandler,
  creator: string // AdvanceCM User,
  mode: number
  account: number
  lastupdate: number
  @Type(() => StealthTriggerSettings)
  settings: StealthTriggerSettings

  // views
  createdView: string
  channelView: string
  RentalView: string
  startView: string
  stopView: string
  timeZoneView: string
  currentlyView: string

  startTime: number
  stopTime: number

  static deserialize(data: any): StealthTrigger {
    const stealth: StealthTrigger = plainToClass<StealthTrigger, StealthTrigger>(StealthTrigger, data)

    stealth.createdView = epochToView(stealth.created)
    stealth.startView = moment(stealth.settings.start).format('hh:mm A')
    stealth.stopView = moment(stealth.settings.stop).format('hh:mm A')

    let start = new Date(stealth.settings.start)
    start.setFullYear(2000)
    start.setMonth(0)
    start.setDate(10)

    stealth.startTime = start.getTime()

    let end = new Date(stealth.settings.start)
    end.setFullYear(2000)
    end.setMonth(0)
    end.setDate(10)

    stealth.stopTime = end.getTime()

    stealth.timeZoneView = stealth.settings.zone
    stealth.currentlyView = stealth.mode === 1 ? 'Hidden' : 'Visible'

    return stealth
  }
}
