import { createAction, props } from '@ngrx/store'
import { User, UserMFAConfig } from './user.model'
import { Update } from '@ngrx/entity'
import { CreateUserPayload, UpdateUserPayload } from './user.interfaces'

export const LoadUsers = createAction('[User] Load Users')
export const LoadUsersComplete = createAction('[User] Load Users Complete', props<{ users: User[] }>())

export const AddUser = createAction('[User] Add User', props<{ payload: CreateUserPayload }>())
export const AddUserComplete = createAction('[User] Add User Complete', props<{ user: User }>())

export const RemoveUser = createAction('[User] Remove User', props<{ id: string }>())
export const RemoveUserComplete = createAction('[User] Remove User Complete', props<{ id: string }>())

export const RemoveUsers = createAction('[User] Remove Users', props<{ ids: string[] }>())
export const RemoveUsersComplete = createAction('[User] Remove Users Complete', props<{ ids: string[] }>())

export const UpdateUser = createAction('[User] Update User', props<{ userId: string; payload: UpdateUserPayload }>())
export const UpdateUserComplete = createAction('[User] Update User Complete', props<{ update: Update<User> }>())

export const UpdateUserMFA = createAction('[User] Update User MFA', props<{ userId: string; payload: UserMFAConfig }>())
export const UpdateUserMFAComplete = createAction('[User] Update User MFA Complete', props<{ update: Update<User> }>())

export const UpdateUserAttributes = createAction(
  '[User] Update User Attributes',
  props<{ userId: string; attributes: { [key: string]: any } }>()
)
export const UpdateUserAttributesComplete = createAction(
  '[User] Update User Attributes Complete',
  props<{ update: Update<User> }>()
)

export const UpdateUserSettings = createAction(
  '[User] Update User Settings',
  props<{
    user: User
    settings: {
      disabled: boolean
      automata: boolean
      ownercenter: boolean
      signature: boolean
      rategenie: boolean
      webready: boolean
      checklist: boolean
      margins: boolean
    }
  }>()
)
export const UpdateUserSettingsComplete = createAction(
  '[User] Update User Settings Complete',
  props<{ update: Update<User> }>()
)
