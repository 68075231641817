import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import * as R from 'ramda'
import { GuestModifyInteractionDialogComponent } from '@tv3/containers/guests/edit/interaction/modify/guest-modify-interaction-dialog.component'

@Injectable()
export class GuestModifyInteractionDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: MatDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      data: null,
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(GuestModifyInteractionDialogComponent, R.merge(defaultConfig, config))
  }
}
