<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Base Charge (' + currencySymbol + '):'"></span></mat-label>
        <input matInput type="number" min="0" formControlName="charge" />
      </mat-form-field2>
    </div>
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Additional Guests Fee (' + currencySymbol + '):'"></span></mat-label>
        <input matInput type="number" min="0" formControlName="extraGuests" />
      </mat-form-field2>
    </div>
  </div>

  <h6>Discounts & Fees</h6>
  <div class="row">
    <div
      formArrayName="discounts"
      *ngFor="let item of discounts.controls; let i = index; let last = last"
      class="col-md-4 d-flex gap-2"
    >
      <ng-container [formGroupName]="i">
        <mat-form-field2 class="mat-block">
          <mat-label><span [innerHtml]="'Amount (' + currencySymbol + '):'"></span></mat-label>
          <input type="number" formControlName="amount" matInput />
        </mat-form-field2>
        <button
          *ngIf="last"
          (click)="removeDiscountsOrFees(i)"
          class="btn btn-secondary-danger align-input-row-end"
          matTooltip="Remove this Discount/Fee"
          type="button"
        >
          <i class="cs-icon cs-icon-trash"></i>
        </button>
      </ng-container>
    </div>
    <div class="col-md-4" *ngIf="discounts.length < discountsOrFeesLimit">
      <button
        (click)="addDiscountsOrFees()"
        class="btn btn-primary mb-3"
        [class.align-input-row-end]="discounts.length > 0"
        type="button"
      >
        <i class="far fa-plus"></i> Add Discount/Fee
      </button>
    </div>
  </div>
  <h6>Taxes</h6>
  <div class="row">
    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Tax (%):'"></span></mat-label>
        <input matInput type="number" min="0" formControlName="taxPercent" />
      </mat-form-field2>
    </div>

    <div class="col-md-6">
      <mat-form-field2 class="mat-block">
        <mat-label><span [innerHtml]="'Tax (' + currencySymbol + '):'"></span></mat-label>
        <input matInput type="number" min="0" formControlName="taxFlat" />
      </mat-form-field2>
    </div>
  </div>
  <div class="bg-active2 p-3 rounded">
    <div *ngIf="getTaxTotal() as taxTotal" class="d-flex align-items-center justify-content-between">
      <span class="text-light text-uppercase">Tax Total:</span>
      <span class="text-bold" [innerHtml]="taxTotal | tokeetCurrency : inquiry?.rental?.currency"></span>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <span class="text-light text-uppercase">Total:</span>
      <span class="text-bold" [innerHtml]="getTotal() | tokeetCurrency : inquiry?.rental?.currency"></span>
    </div>
  </div>
</form>

<div class="footer-control">
  <button [disabled]="loading || calculating" (click)="close.emit(true)" type="button" class="btn btn-light">
    <i class="far fa-times"></i> Cancel
  </button>
  <button
    [disabled]="loading || calculating"
    (click)="reset()"
    type="button"
    class="btn btn-secondary-light"
    matTooltip="This will update charges according to current rates added in this rental for this booking's date"
  >
    <i class="far fa-sync"></i> reset
  </button>
  <button *ngIf="!loading && !calculating" (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
  <button
    *ngIf="loading || calculating"
    matTooltip="Calculating cost..."
    matTooltipPosition="above"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
