import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { BookingFormulaStoreModule } from './store/module'

const components = []
const services = []

@NgModule({
  imports: [CommonModule, Tv3PlatformModule, BookingFormulaStoreModule],
  declarations: [...components],
  providers: [...services],
  exports: [...components],
})
export class BookingFormulaLibraryModule {}
