<div class="main-content">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Resources</h6>
      <app-page-help
        articleId="-"
        videoId="-"
        infoTooltip="You can completely customize the look of your website using custom CSS and JavaScript. You can even overwrite the default CSS of HTML entities. This requires advanced knowledge of HTML and CSS."
      ></app-page-help>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>

  <form
    [formGroup]="form"
    [formEditablePermission]="form"
    class="container-box details-box overflow-hidden"
    [class.loading-section]="isUpdating$ | async"
  >
    <div class="box-body">
      <h6 class="section-title border-top-0">Add CSS resources to your website.</h6>
      <div class="row px-3">
        <div class="col-lg-6">
          <h4 class="title">Raw CSS</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="raw_css"
                  placeholder=".myclass{ color: red; }"
                  rows="4"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h4 class="title">CSS Includes (one URL per line)</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="link_css"
                  placeholder="https://ajax.googleapis.com/ajax/libs/jquerymobile/1.4.5/jquery.mobile.min.css"
                  rows="4"
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-body">
      <h6 class="section-title">Add Javascript resources to your website.</h6>
      <div class="row px-3">
        <div class="col-lg-6">
          <h4 class="title">Raw Javascript</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="raw_js"
                  placeholder="function closePopWin(){  $('#popWrap').fadeOut(); }"
                  rows="4"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h4 class="title">Javascript Includes (one URL per line)</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="link_js"
                  placeholder="https://ajax.googleapis.com/ajax/libs/threejs/r84/three.min.js"
                  rows="4"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-body">
      <h6 class="section-title">Add raw HTML to your website.</h6>
      <div class="row px-3">
        <div class="col-lg-6">
          <h4 class="title">Add HTML to header</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="header_html"
                  placeholder='<meta name="google - site - verification" content="GOOGLE-TOKEN" />'
                  rows="4"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <h4 class="title">Add HTML to footer</h4>
          <div class="row">
            <div class="col-sm-10">
              <mat-form-field2 class="mat-block">
                <textarea
                  matInput
                  formControlName="footer_html"
                  placeholder="<span>Some HTML code</span>"
                  rows="4"
                  cdkTextareaAutosize
                ></textarea>
              </mat-form-field2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
