import { AbstractControl } from '@angular/forms'

export function atLeast(number: number) {
  return (control: AbstractControl) => {
    if (number > (control.value || []).length) {
      return { atLeast: true }
    }
    return null
  }
}
