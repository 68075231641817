export const repeats = [
  { name: 'Weekly', value: 1 },
  { name: 'Monthly', value: 2 },
  { name: 'Yearly', value: 3 },
]

export const statuses = [
  { name: 'Paid', value: 2 },
  { name: 'Unpaid', value: 1 },
]

export const methods = [
  { name: 'Bank Deposit', value: 'bankdeposit' },
  { name: 'Card', value: 'card' },
  { name: 'Cash', value: 'cash' },
  { name: 'Check', value: 'check' },
  { name: 'Credit', value: 'credit' },
  { name: 'Wire Transfer', value: 'wiretransfer' },
  { name: 'Other', value: 'other' },
]

export const categories = [
  { name: 'Food/Beverage', value: 'Food/Beverage' },
  { name: 'Gardening', value: 'Gardening' },
  { name: 'Housekeeping', value: 'Housekeeping' },
  { name: 'Maintenance', value: 'Maintenance' },
  { name: 'Mortgage/Rent', value: 'Mortgage/Rent' },
  { name: 'Pool', value: 'Pool' },
  { name: 'Plumbing', value: 'Plumbing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Utility', value: 'Utility' },
  { name: 'Other', value: 'Other' },
]
