<div class="filters-box">
  <app-select-search
    bindValue="id"
    bindLabel="friendlyName"
    [items]="channels$"
    [ctrl]="filters.get('channels')"
    placeholder="Channels"
    matTooltip="Filter the below rates by channel."
    matTooltipClass="text-center"
    matTooltipPosition="above"
  >
  </app-select-search>

  <app-select-search
    bindValue="id"
    bindLabel="name"
    [items]="rentals$"
    [ctrl]="filters.get('rentals')"
    placeholder="Rentals"
    matTooltip="Filter the below rates by rental."
    matTooltipClass="text-center"
    matTooltipPosition="above"
  >
  </app-select-search>
  <app-entity-filter-tags
    [ctrl]="filters.get('tags')"
    [entityIdsCtrl]="filters.get('taggedIds')"
    [tags]="rentalTags$ | async"
    type="rental"
    placeholder="Tags"
    matTooltip="Filter the below rates by tag."
    matTooltipClass="text-center"
    matTooltipPosition="above"
  ></app-entity-filter-tags>
  <app-cancel-filters
    [isFiltering]="filters | isFiltering"
    [filterControl]="filters"
    [searchCom]="searchCom"
  ></app-cancel-filters>

  <app-button
    (click)="onRefresh()"
    icon="far fa-sync"
    class="btn btn-secondary-primary"
    [loading]="isRefreshing$ | async"
    matTooltip="Refresh"
    matTooltipPosition="above"
  >
  </app-button>

  <div class="flex-fill"></div>
  <app-table-search
    #searchCom
    (search)="onSearch($event)"
    matTooltip="Search by any values visible in the table below"
    matTooltipClass="text-center"
    matTooltipPosition="above"
  ></app-table-search>
  <div class="btn-group dropdown" role="group" dropdown [isDisabled]="1 | isReadonlyRole" *ngIf="showActions">
    <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle">
      <span class="cs-icon cs-icon-bolt"></span> Actions
    </button>
    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <li>
        <button (click)="onPushRate()" type="button" [disabled]="!isAnySelected()">
          <span class="fas fa-arrow-up"></span>
          Pushed Mapped Rates
        </button>
      </li>
      <li>
        <button (click)="onPushAvailability()" type="button" [disabled]="!isAnySelected()">
          <span class="far fa-calendar-alt"></span>
          Push Availability
        </button>
      </li>
      <li>
        <button (click)="onDisableAutoSync()" type="button" [disabled]="!isAnySelected()">
          <span class="far fa-pause"></span>
          Disable Auto Sync
        </button>
      </li>
      <li>
        <button (click)="onEnableAutoSync()" type="button" [disabled]="!isAnySelected()">
          <span class="far fa-play"></span>
          Enable Auto Sync
        </button>
      </li>
      <li>
        <button (click)="onSetFrequency('daily')" type="button" [disabled]="!isAnySelected()">
          <span class="far fa-calendar-day"></span>
          Set Sync Daily
        </button>
      </li>
      <li>
        <button (click)="onSetFrequency('weekly')" type="button" [disabled]="!isAnySelected()">
          <span class="far fa-calendar-week"></span>
          Set Sync Weekly
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table
      #table
      matSort
      matSortActive="channelName"
      matSortDirection="asc"
      (matSortChange)="onSort($event)"
      [dataSource]="dataSource"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div *ngIf="!row.isDoable" class="expandable-control">
            <i
              class="fal fa-exclamation-triangle text-warning"
              [matTooltip]="getItemWarningInfo | call : row"
              matTooltipPosition="right"
            ></i>
          </div>
          <mat-checkbox
            *ngIf="row.isDoable"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rental Name</mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <div *ngIf="element.isLinked; else link" (click)="onRentalClick(element)">
            <app-rental-name [rentalId]="element.rental?.id" [image]="true"></app-rental-name>
          </div>
          <ng-template #link>
            <button (click)="onLink(element)" class="btn btn-secondary-info inline-btn" type="button">
              <i class="fal fa-link"></i>
              Link
            </button>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rentalAddress">
        <mat-header-cell *matHeaderCellDef>country</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.rentalAddress" matTooltipPosition="above" class="text-truncate">{{
            element.rentalAddress
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="channelName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>channel</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="channel_logo">
            <img [src]="element.channel?.logoColor" [alt]="element.channelName" style="height: 16px" />
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="propertyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Property</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.propertyName" matTooltipPosition="above" class="text-truncate">{{
            element.propertyName
          }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="frequencyView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.frequencyView | titleCase }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div [ngSwitch]="element.status" class="status-icons">
            <div *ngSwitchCase="'requesting'"><i class="far fa-spin fa-spinner text-primary"></i></div>
            <div *ngSwitchCase="'scheduled'"><i class="far fa-clock text-warning"></i></div>
            <div *ngSwitchCase="'done'">
              <i class="far fa-check text-success" matTooltip="Done" matTooltipPosition="above"></i>
            </div>
            <div *ngSwitchCase="'error'">
              <i class="far fa-times text-danger" [matTooltip]="element.errorText" matTooltipPosition="above"></i>
            </div>
            <div *ngSwitchDefault>
              <div
                *ngIf="element.isRateMappingImplicit && !element.rateMappings.length"
                matTooltip="AdvanceCM is using a Default rate mapping if no rate mapping created"
                style="font-size: 12px; font-weight: normal"
              >
                Auto Mapped
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandCtrl">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div
            *ngIf="isExpandableRow | call : 0 : row"
            class="expandable-control clickable"
            (click)="onExpandItem(row)"
          >
            <i *ngIf="row.id !== expandedRowItem?.id" class="far fa-chevron-right"></i>
            <i *ngIf="row.id === expandedRowItem?.id" class="far fa-chevron-down"></i>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let detail" [attr.colspan]="displayedColumns.length">
          <div class="border border-top-0 rounded my-3 w-100">
            <app-connection-rate-mappings-table
              [rateMappings]="detail.rateMappings"
              [connection]="detail.connection"
            ></app-connection-rate-mappings-table>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ expanded: expandedRowItem?.id == row.id }"
        class="element-row"
      ></mat-row>

      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']; when: isExpandableRow"
        class="detail-row"
        [class.d-none]="row.id !== expandedRowItem?.id"
      ></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.filteredData?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <ng-container *ngIf="isLoading$ | async; else empty">
      <app-loading-section></app-loading-section>
    </ng-container>
  </table>
</div>

<ng-template #empty>
  <app-empty-table *ngIf="isEmptyTable$ | async" type="PushAllRentals"></app-empty-table>
</ng-template>
