<div class="alert alert-secondary-info">
  <i class="fal fa-info-circle alert-icon"></i>
  <div class="alert-content">
    Channel configuration will be used for all rentals by default, unless you set specific rental configurations which
    override channel configuration.
  </div>
</div>
<app-hometogo-channel-config-form
  *ngIf="channel?.name === channelNames.HomeToGo && !!config"
  [title]="'Channel Config'"
  [channel]="channel"
  [taxesFees]="'bulk'"
  [config]="config"
  [primaryButtonClass]="primaryButtonClass"
  (save)="onSave($event)"
></app-hometogo-channel-config-form>
