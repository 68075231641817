import { Component, OnInit, Inject } from '@angular/core'
import {
  Destroyable,
  Rental,
  ConfirmDialogService,
  ActionFailed,
  Toaster,
  updateRentalPaymentSchedule,
  RentalPaymentSchedule,
  updateRentalPaymentScheduleComplete,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { concatMap, finalize, switchMap, take, toArray } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { of } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { PaymentScheduleItem } from '../select-payment-schedules-table/select-payment-schedules-table.component'
import * as lodash from 'lodash'

@Component({
  selector: 'app-copy-schedules-to-dialog',
  templateUrl: './copy-schedules-to-dialog.component.html',
  styleUrls: ['./copy-schedules-to-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class CopySchedulesToDialogComponent extends Destroyable implements OnInit {
  get rental() {
    return this.data.rental
  }
  selectedItems: PaymentScheduleItem[] = []
  selectedRentals: Rental[] = []

  isLoading = false
  progress = 0

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopySchedulesToDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {}

  onSelectFees(items: PaymentScheduleItem[]) {
    this.selectedItems = items
  }

  onSelectRentals(items: Rental[]) {
    this.selectedRentals = items
  }

  getSelectedRentalPaymentSchedule(): RentalPaymentSchedule {
    return lodash.mapValues(
      lodash.keyBy(this.selectedItems, (item) => item.type),
      (item) => lodash.omit(item, 'type')
    )
  }

  saveSchedule() {
    const doAction = (rentalId: string) => {
      this.store.dispatch(
        updateRentalPaymentSchedule({ payload: this.getSelectedRentalPaymentSchedule(), rentalId, silent: true })
      )
      return this.actions.pipe(ofType(updateRentalPaymentScheduleComplete), take(1))
    }

    return of(...this.selectedRentals).pipe(
      concatMap((rental, i) => {
        this.progress = i + 1
        return doAction(rental.id)
      }),
      toArray()
    )
  }

  onOverwrite() {
    this.isLoading = true
    this.confirmDialog
      .confirm({
        title: 'Copy Schedules?',
        body: 'This action will overwrite schedules in rentals.',
      })
      .pipe(
        switchMap(() => this.saveSchedule()),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.toaster.success('Schedules copied successfully')
          this.dialogRef.close('overwrite')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  close() {
    this.dialogRef.close()
  }
}
