import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './inquiry.reducer'
import * as state from './inquiry.state'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as R from 'ramda'
import { isSomething, selectRentalEntities } from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { selectGuestEntities } from '@tv3/store/guest/guest.selectors'
import { Guest } from '@tv3/store/guest/guest.model'

export const inquiryState = createFeatureSelector<state.State>('inquiry')

export const selectInquiriesLoaded = createSelector(inquiryState, (state: state.State) => state.isLoaded)

export const selectInquiryEntities = createSelector(inquiryState, reducer.selectEntities)

export const selectAllInquiries = createSelector(inquiryState, reducer.selectAll)

// tslint:disable-next-line:max-line-length
export const selectInquiries = createSelector(
  selectAllInquiries,
  selectRentalEntities,
  selectGuestEntities,
  (inquiries, rentalEntities, guestEntities) => {
    return R.map((i: Inquiry) => {
      const rental: Rental = rentalEntities[i.rentalId]
      const guest: Guest = guestEntities[R.path<string>(['guestId'], i)] || R.path(['guest'], i)
      return {
        ...i,
        rental,
        guest,
        rentalView: rental ? rental.name : '',
      } as Inquiry
    }, inquiries)
  }
)

export const selectInquiriesByIds = (ids: string[]) =>
  createSelector(selectInquiryEntities, (itemsById) => {
    return ids.map((id) => itemsById[id]).filter((t) => !!t)
  })

export const selectInquiriesTags = createSelector(inquiryState, (state) => state.tags)

export const selectAllInquiriesViewsByTouched = createSelector(selectInquiries, (inquiries) => {
  return R.sortBy((i: Inquiry) => -i.touch, inquiries)
})

export const selectAllInquiriesByGuestName = createSelector(selectInquiries, (inquiries) => {
  return R.sort(
    (a: Inquiry, b: Inquiry) =>
      R.pathOr('', ['guestDetails', 'name'], a)
        .toString()
        .localeCompare(R.pathOr('', ['guestDetails', 'name'], b), undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    inquiries
  )
})

export const selectInquiriesByGuestId = (guestId: string) =>
  createSelector(selectInquiries, (inquiries) => {
    return R.sortBy(
      (i: Inquiry) => -i.touch,
      R.filter((a) => a.guestId === guestId, inquiries)
    )
  })

export const selectInquirySources = createSelector(inquiryState, (state) => state.sources)

// tslint:disable-next-line:max-line-length
export const selectInquiry = createSelector(
  selectInquiryEntities,
  selectRentalEntities,
  selectGuestEntities,
  (inquiryEntities, rentalEntities, guestEntities, props) => {
    const inquiry: Inquiry = inquiryEntities[props.id]

    if (!isSomething(inquiry)) {
      return null
    }

    const rental: Rental = rentalEntities[R.path<string>(['rentalId'], inquiry)]
    const guest: Guest = guestEntities[R.path<string>(['guestId'], inquiry)] || R.path(['guest'], inquiry)

    return {
      ...inquiry,
      rental,
      guest,
      rentalView: R.pathOr('', ['name'], rental),
    } as Inquiry
  }
)

export const isCancelingBooking = createSelector(inquiryState, (state) => state.isCancelingBooking)

export const isConfirmingBooking = createSelector(inquiryState, (state) => state.isConfirmingBooking)
export const isUpdatingBooking = createSelector(inquiryState, (state) => state.isUpdatingBooking)

export const isSavingNote = createSelector(inquiryState, (state) => state.isSavingNote)

export const isAddingMessage = createSelector(inquiryState, (state) => state.isAddingMessage)

export const selectInquiryPagination = createSelector(inquiryState, (state) => state.pagination)
