import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, from, Observable, of } from 'rxjs'
import { deserializeArray, sortAscend, Toaster } from '@tokeet-frontend/tv3-platform'
import { Contract } from '@tv3/store/contract/contract.model'
import { AuthService } from '@tv3/services/auth.service'
import {
  SignatureTemplate,
  SignatureTemplateResponse,
  SignatureTemplateType,
} from '@tv3/containers/inquiries/overlays/inquiry-overlay/automata-booking-overlay/store/signature-template'
import { catchError, concatMap, flatMap, map, toArray } from 'rxjs/operators'
import * as R from 'ramda'

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private http: HttpClient, private toaster: Toaster, private authService: AuthService) {}

  download(resourceUrl): Observable<Blob> {
    const { account } = this.authService.user
    const url = `@signatureApi/${account}/files?url=${encodeURIComponent(resourceUrl)}`
    return this.http.get(url, { responseType: 'blob' })
  }

  getContractsByInquiryId(inquiryId: string): Observable<Contract[]> {
    return this.http.get(`@signatureApi/contracts/inquiry/${inquiryId}`).pipe(
      deserializeArray<Contract>(Contract),
      catchError((error) => {
        this.toaster.error(null, 'Error', error)
        return EMPTY
      })
    )
  }

  getContracts(): Observable<SignatureTemplate[]> {
    const types: SignatureTemplateType[] = ['contract', 'form', 'document']

    return of(types).pipe(
      flatMap((type) => from(type)),
      concatMap((type) =>
        this.http
          .get<SignatureTemplateResponse>(`@api/template/signature/${type}/all/`)
          .pipe(map((response) => response.items))
      ),
      toArray(),
      map((templates) => sortAscend<SignatureTemplate>('name')(<any>R.flatten(templates))),
      map((contracts) => R.map((c: SignatureTemplate) => ({ ...c, id: c.template_id }), contracts))
    )
  }
}
