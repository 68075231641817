import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'app-agoda-listing-wizard-step3',
  templateUrl: './agoda-listing-wizard-step3.component.html',
  styleUrls: ['./agoda-listing-wizard-step3.component.scss'],
})
export class AgodaListingWizardStep3Component implements OnInit {
  @Output() prev = new EventEmitter()
  @Output() done = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
