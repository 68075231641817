import {
  AddTask,
  AddTaskComplete,
  AddTasks,
  AddTasksComplete,
  ChangeTaskStatus,
  ChangeTaskStatusComplete,
  ChangeTasksStatus,
  ChangeTasksStatusComplete,
  DeleteTask,
  DeleteTaskComplete,
  DeleteTasksComplete,
  LoadTasksComplete,
  UpdateTask,
  UpdateTaskComplete,
  ArchiveTasksComplete,
  UnarchiveTasksComplete,
  LoadTasksByIdsComplete,
} from './task.actions'
import { adapter, initialState, State } from './task.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadTasksComplete, (state, { tasks }) =>
    adapter.setAll(tasks, R.merge(state, { isLoaded: true, isUpdating: false }))
  ),
  on(LoadTasksByIdsComplete, (state, { tasks }) => adapter.addMany(tasks, state)),
  on(AddTaskComplete, (state, { task }) => adapter.addOne(task, { ...state, isUpdating: false })),
  on(DeleteTaskComplete, (state, { id }) => adapter.removeOne(id, { ...state, isUpdating: false })),
  on(DeleteTasksComplete, (state, { ids }) => adapter.removeMany(ids, { ...state, isUpdating: false })),
  on(UpdateTaskComplete, (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })),
  on(ArchiveTasksComplete, UnarchiveTasksComplete, (state, { updates }) => adapter.updateMany(updates, state)),
  on(ChangeTaskStatusComplete, (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })),
  on(ChangeTasksStatusComplete, (state, { update }) => adapter.updateMany(update, { ...state, isUpdating: false })),
  on(AddTasksComplete, (state, { tasks }) => adapter.addMany(tasks, { ...state, isUpdating: false })),
  on(AddTask, AddTasks, UpdateTask, DeleteTask, ChangeTasksStatus, ChangeTaskStatus, (state) => ({
    ...state,
    isUpdating: true,
  }))
)

export function tasksReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
