import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  ActionFailed,
  asUTCEpoch,
  ConfirmDialogService,
  isSomething,
  Rental,
  selectAllRentals,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import * as moment from 'moment'
import { select, Store } from '@ngrx/store'
import { mergeMap, switchMap, toArray, map, finalize, tap } from 'rxjs/operators'
import { EMPTY, combineLatest, of } from 'rxjs'
import { FeeModalities, FeeTypes } from '@tokeet/cost-resolver'
import * as lodash from 'lodash'
import { BookingFee, CreateBookingFeePayload } from '../../../store/fees/booking-fee'
import { BookingFeesService } from '../../../store/fees/booking-fees.service'

@Component({
  selector: 'app-booking-fees',
  templateUrl: './booking-fees.component.html',
  styleUrls: ['./booking-fees.component.scss'],
})
export class BookingFeesComponent implements OnInit {
  @Input() rental: Rental

  isLoading = false

  feeFormItems: FormGroup[] = []

  defaultFee = {
    name: '',
    description: '',
    amount: null,
    type: FeeTypes.Flat,
    modality: FeeModalities.PerStay,
    channel_id: '',
    start: null,
    end: null,
    dow: [],
    refundable: null,
    taxable: null,
    status: true,
    guest_threshold: null,
    length_threshold: null,
    upfront: false,
  }

  allFees: BookingFee[] = []
  rentalFees: BookingFee[] = []

  rentals$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => R.reject((r) => r.id === this.rental?.id, rentals))
  )
  rentalCtrl = new FormControl()

  currencyCode: string

  constructor(
    private bookingFeesService: BookingFeesService,
    private fb: FormBuilder,
    private confirmDialog: ConfirmDialogService,
    private cd: ChangeDetectorRef,
    private store: Store<any>,
    private toaster: Toaster
  ) {}

  ngOnInit() {
    this.defineForm()
    this.refresh()

    this.currencyCode = R.pathOr('USD', ['currency', 'code'], this.rental)
  }

  refresh() {
    this.isLoading = true
    this.bookingFeesService
      .getAllFees(this.rental.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (fees) => {
          this.rentalFees = fees.sort((f) => -f.created)
          this.initFees(this.rentalFees)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  defineForm() {
    return this.fb.group({
      pkey: [''],
      name: ['', [Validators.required]],
      description: [''],
      amount: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      modality: ['ps', [Validators.required]],
      channel_id: [],
      type: ['pct', [Validators.required]],
      dow: [[]],
      start: [null],
      end: [null],
      status: [true],
      refundable: [''],
      taxable: [''],
      guest_threshold: [''],
      length_threshold: [''],
      upfront: [false],
    })
  }

  initFees(fees: BookingFee[]) {
    this.feeFormItems = []

    if (R.isEmpty(fees)) {
      const form = this.defineForm()
      form.patchValue({
        ...this.defaultFee,
      })
      this.feeFormItems.push(form)
    }

    R.forEach((fee) => {
      const form = this.defineForm()
      let start = null
      if (isSomething(fee.scheduling) && isSomething(fee.scheduling.start)) {
        start = moment
          .utc(fee.scheduling.start * 1000)
          .startOf('date')
          .add(12, 'hours')
          .toDate()
      }

      let end = null
      if (isSomething(fee.scheduling) && isSomething(fee.scheduling.end)) {
        end = moment
          .utc(fee.scheduling.end * 1000)
          .startOf('date')
          .add(12, 'hours')
          .toDate()
      }
      form.patchValue({
        ...fee,
        status: fee.status === 1,
        upfront: fee.upfront === 1,
        start,
        end,
        dow: (fee.scheduling?.dow || []).map((d) => d?.toString()) || [],
      })
      this.feeFormItems.push(form)
    }, fees)
  }

  onAdd() {
    const form = this.defineForm()
    form.patchValue({
      ...this.defaultFee,
    })
    this.feeFormItems.push(form)
  }

  onRemove(index: number) {
    this.confirmDialog
      .confirm({
        title: 'Delete this booking fee?',
        body: 'Are you sure you want to delete this booking fee?',
      })
      .pipe(
        switchMap(() => {
          const remove = () => (this.feeFormItems = R.remove(index, 1, this.feeFormItems))

          const pkey = this.feeFormItems[index]?.value?.pkey
          if (!pkey) {
            remove()
            return EMPTY
          }

          return this.bookingFeesService.deleteFee(pkey).pipe(
            tap(() => {
              this.toaster.success('Fee removed successfully')
              remove()
            })
          )
        })
      )
      .subscribe(
        () => {},
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  toPayload(fee: any) {
    const start = moment
      .utc(asUTCEpoch(fee.start) * 1000)
      .startOf('date')
      .add(12, 'hours')
      .unix()
    const end = moment
      .utc(asUTCEpoch(fee.end) * 1000)
      .startOf('date')
      .add(12, 'hours')
      .unix()

    const data: CreateBookingFeePayload = {
      account: this.rental.account,
      name: fee.name,
      description: fee.description,
      rental_id: this.rental.id,
      amount: fee.amount,
      type: fee.type,
      modality: fee.modality,
      refundable: fee.refundable ? 1 : 0,
      taxable: fee.taxable ? 1 : 0,
      channel_id: fee.channel_id,
      guest_threshold: fee.guest_threshold,
      length_threshold: fee.length_threshold,
      status: fee.status ? 1 : 0,
      upfront: fee.upfront ? 1 : 0,
      scheduling: {
        start: isSomething(fee.start) ? start : null,
        end: isSomething(fee.end) ? end : null,
        dow: fee.dow,
      },
    }
    return data
  }

  isAnyInvalid(forms: FormGroup[]) {
    return R.any((f) => f.invalid, forms)
  }

  async save() {
    if (this.isAnyInvalid(this.feeFormItems)) {
      R.forEach((form) => {
        form.markAllAsTouched()
      }, this.feeFormItems)
      return
    }
    this.isLoading = true
    const feeItems = this.feeFormItems.map((f) => {
      const data = f.getRawValue()
      const payload = this.toPayload(data)
      return { payload, pkey: data.pkey }
    })
    const { updateItems, createItems } = lodash.groupBy(feeItems, (item) => (item.pkey ? 'updateItems' : 'createItems'))

    combineLatest([
      of(...(updateItems || [])).pipe(
        mergeMap((item) => this.bookingFeesService.updateFee(item.pkey, item.payload)),
        toArray()
      ),
      this.bookingFeesService.createFees(lodash.map(createItems, (t) => t.payload)),
    ])
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Booking fees saved successfully')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }
}
