import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { HoliduChannelService } from './service'
import {
  ConnectHolidu,
  ConnectHoliduFailure,
  ConnectHoliduSuccess,
  CreateHoliduListing,
  CreateHoliduListingFailure,
  CreateHoliduListingSuccess,
  DeleteHoliduListing,
  DeleteHoliduListingFailure,
  DeleteHoliduListingSuccess,
  DisconnectHolidu,
  DisconnectHoliduFailure,
  DisconnectHoliduSuccess,
  GetHoliduListings,
  GetHoliduListingsFailure,
  GetHoliduListingsSuccess,
  GetHoliduRentalValidations,
  GetHoliduRentalValidationsFailure,
  GetHoliduRentalValidationsSuccess,
  GetHoliduStatus,
  GetHoliduStatusFailure,
  GetHoliduStatusSuccess,
  UpdateHoliduListing,
  UpdateHoliduListingFailure,
  UpdateHoliduListingSuccess,
} from './actions'

@Injectable()
export class HoliduChannelEffects {
  //region Holidu
  @Effect()
  getHoliduStatus$ = this.actions$.pipe(
    ofType(GetHoliduStatus),
    switchMap(() =>
      this.channelsService.getHoliduStatus().pipe(
        map((status) => GetHoliduStatusSuccess({ status })),
        catchError((err) => of(GetHoliduStatusFailure({ err })))
      )
    )
  )

  @Effect()
  connectHolidu$ = this.actions$.pipe(
    ofType(ConnectHolidu),
    concatMap(({ providerId }) =>
      this.channelsService.connectHolidu(providerId).pipe(
        tap(() => this.toaster.success('Holidu connected successfully')),
        map(({ status, listings }) => ConnectHoliduSuccess({ status, listings })),
        catchError((err) => {
          this.toaster.error('Unable to connect', '', err)
          return of(ConnectHoliduFailure({ err }))
        })
      )
    )
  )

  @Effect()
  getHoliduListings$ = this.actions$.pipe(
    ofType(GetHoliduListings),
    switchMap(() =>
      this.channelsService.getHoliduListings().pipe(
        map((listings) => GetHoliduListingsSuccess({ listings })),
        catchError((err) => of(GetHoliduListingsFailure({ err })))
      )
    )
  )

  @Effect()
  createHoliduListing$ = this.actions$.pipe(
    ofType(CreateHoliduListing),
    switchMap(({ listing }) =>
      this.channelsService.createHoliduListing(listing).pipe(
        tap(() => this.toaster.success('Listing enabled successfully.')),
        map((response) => CreateHoliduListingSuccess({ listing: response })),
        catchError((err) => of(CreateHoliduListingFailure({ err })))
      )
    )
  )

  @Effect()
  deleteHoliduListing$ = this.actions$.pipe(
    ofType(DeleteHoliduListing),
    switchMap(({ propertyId }) =>
      this.channelsService.deleteHoliduListing(propertyId).pipe(
        tap(() => this.toaster.success('Listing disabled successfully.')),
        map(() => DeleteHoliduListingSuccess({ propertyId })),
        catchError((err) => of(DeleteHoliduListingFailure({ err })))
      )
    )
  )

  @Effect()
  getHoliduRentalValidations$ = this.actions$.pipe(
    ofType(GetHoliduRentalValidations),
    switchMap(() =>
      this.channelsService.getHoliduRentalValidations().pipe(
        map((validations) => GetHoliduRentalValidationsSuccess({ validations })),
        catchError((err) => of(GetHoliduRentalValidationsFailure({ err })))
      )
    )
  )

  @Effect()
  updateHoliduListing$ = this.actions$.pipe(
    ofType(UpdateHoliduListing),
    concatMap(({ listing }) =>
      this.channelsService.updateHoliduListing(listing).pipe(
        tap(() => this.toaster.success('Listing updated successfully.')),
        map((response) => UpdateHoliduListingSuccess({ listing: response })),
        catchError((err) => of(UpdateHoliduListingFailure({ err })))
      )
    )
  )

  @Effect()
  disconnectHolidu$ = this.actions$.pipe(
    ofType(DisconnectHolidu),
    switchMap(({ providerId }) =>
      this.channelsService.disconnectHolidu(providerId).pipe(
        tap(({ success }) => {
          if (success) {
            this.toaster.success('Holidu disconnected successfully')
          } else {
            this.toaster.error('Error disconnecting Holidu, please contact support.')
          }
        }),
        map(({ success }) => DisconnectHoliduSuccess({ providerId })),
        catchError((err) => of(DisconnectHoliduFailure({ err })))
      )
    )
  )

  //endregion

  constructor(private actions$: Actions, private channelsService: HoliduChannelService, private toaster: Toaster) {}
}
