import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import {
  ConfirmDialogService,
  Destroyable,
  Rental,
  RentalStatus,
  UpdateRentalStatus,
} from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-rental-status-icon',
  templateUrl: './rental-status-icon.component.html',
  styleUrls: ['./rental-status-icon.component.scss'],
  host: {
    '(click)': 'onToggle($event)',
    '(mouseover)': '$event.stopPropagation()',
  },
})
export class RentalStatusIconComponent extends Destroyable implements OnInit {
  @Input() rental: Rental
  @Input() readyIcon = 'fas fa-circle'
  @Input() notReadyIcon = 'fas fa-circle'

  @HostBinding('class') get status() {
    return this.isReady ? 'ready' : 'not-ready'
  }

  get isReady() {
    return !this.rental?.status || this.rental?.status === RentalStatus.READY
  }

  constructor(private store: Store<any>, private confirm: ConfirmDialogService) {
    super()
  }

  ngOnInit(): void {}

  onToggle($event: MouseEvent) {
    $event.stopPropagation()
    $event.preventDefault()

    const status = this.rental.status
    this.confirm
      .confirm({
        title:
          status === RentalStatus.NOT_READY ? 'Update Rental Status to Ready?' : 'Update Rental Status to Not Ready?',
        body: 'Are you sure you want to change this rental status?',
        headerInfo:
          'The Rental Status indicates whether a property is ready or not ready for guests. This can be updated by Service Providers (if using Checklist) or by Administrators and Property Manager users. This does not affect your rental availability.',
      })
      .subscribe(() => {
        this.store.dispatch(
          UpdateRentalStatus({
            rentalId: this.rental.id,
            status: status === RentalStatus.NOT_READY ? RentalStatus.READY : RentalStatus.NOT_READY,
          })
        )
      })
  }
}
