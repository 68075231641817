import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { BookingFormulaResponse } from './model'

export interface FormulaState extends EntityState<BookingFormulaResponse> {
  isLoaded: boolean
}

export const formulaEntityAdapter: EntityAdapter<BookingFormulaResponse> = createEntityAdapter<BookingFormulaResponse>({
  selectId: (item: BookingFormulaResponse) => item.pkey,
  sortComparer: false,
})

export const initialState: FormulaState = formulaEntityAdapter.getInitialState({
  isLoaded: false,
})
