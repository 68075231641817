import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AutomationStoreModule } from './store/automation.module'
import { AutomationTimeFormComponent } from './components/automation-time-form/automation-time-form.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { AutomationsTableActionsComponent } from './components/automations-table-actions/automations-table-actions.component'
import { PermissionModule } from '@tokeet-frontend/permission'

const components = [
  //
  AutomationTimeFormComponent,
  AutomationsTableActionsComponent,
]

@NgModule({
  imports: [CommonModule, AutomationStoreModule, Tv3PlatformModule, PermissionModule],
  declarations: [...components],
  exports: [...components, AutomationStoreModule, Tv3PlatformModule],
})
export class AutomationsLibraryModule {}
