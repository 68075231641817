import { Component, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { NoteForm } from '@tv3/interfaces/forms/note.form'
import { AddGuestNote, DeleteGuestNote, UpdateGuestNote } from '@tv3/store/guest/guest.actions'
import { EditItemParent } from '@tv3/containers/guests/edit/edit-item.parent'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { filter, take } from 'rxjs/operators'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { Guest, GuestNote } from '@tv3/store/guest/guest.model'
import { GuestModifyNoteDialogService } from '@tv3/containers/guests/edit/note/modify/guest-modify-note-dialog.service'

@Component({
  selector: 'app-guest-note-list',
  templateUrl: './guest-note-list.component.html',
  styleUrls: ['./guest-note-list.component.scss'],
})
export class EditGuestNoteListComponent implements EditItemParent {
  @Input() set guest(g: Guest) {
    this.guestItem = g
    if (!this.guestItem.notes) {
      this.guestItem.notes = []
    }
  }

  get guest() {
    return this.guestItem
  }

  guestItem: Guest

  tableType = TableType.GuestNotesList

  constructor(
    private store: Store<fromRoot.State>,
    private guestModifyNoteDialogService: GuestModifyNoteDialogService
  ) {}

  getId() {
    return 'note'
  }

  mainAction() {
    this.addItem()
  }

  addItem() {
    this.guestModifyNoteDialogService
      .open()
      .afterClosed()
      .subscribe((result: { form: NoteForm; data: GuestNote }) => {
        if (result) {
          this.store.dispatch(AddGuestNote({ guestId: this.guestItem.id, note: result.form.note }))
        }
      })
  }

  onEdit(note: GuestNote) {
    this.guestModifyNoteDialogService
      .open({ data: note })
      .afterClosed()
      .pipe(
        filter((result) => isSomething(result)),
        take(1)
      )
      .subscribe((result: { form: NoteForm; data: GuestNote; delete?: boolean }) => {
        const data = GuestNote.deserialize(result.data)
        if (result.delete) {
          this.store.dispatch(DeleteGuestNote({ guest: this.guestItem, data: data }))
        } else {
          data.note = result.form.note
          this.store.dispatch(UpdateGuestNote({ guestId: this.guestItem.id, data: data }))
        }
      })
  }
}
