import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './connection.reducer'
import * as state from './connection.state'
import * as R from 'ramda'
import { Connection, getRealChannelId, isChannelConnection } from '@tv3/store/connection/connection.model'
import {
  Channel,
  ChannelNameTokens,
  isSomething,
  Rental,
  selectAllChannels,
  selectAllICalChannelViews,
  selectAllRentals,
  selectRentalEntities,
  sortAscend,
} from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import {
  findChannelPropertySetting,
  selectAllChannelPropertySettings,
} from '@tv3/store/channel-property-setting/channel-property-setting.selectors'
import * as lodash from 'lodash'
import { findConnectionChannel } from './connection.model'

export const connectionState = createFeatureSelector<state.State>('connection')

export const selectConnectionsLoaded = createSelector(connectionState, (s: state.State) => s.isLoaded)
export const isConnectionUpdating = createSelector(connectionState, (s: state.State) => s.isUpdating)
export const isChannelRefreshing = createSelector(connectionState, (s: state.State) => s.isChannelRefreshing)

export const selectAllConnections = createSelector(connectionState, reducer.selectAll)

export const selectConnectionById = createSelector(connectionState, (s: state.State, props) => {
  return s.entities[props.id]
})

export const selectConnectionsByChannelId = createSelector(selectAllConnections, (connections: Connection[], props) => {
  return R.filter((conn) => {
    return conn.channelId === props.id
  }, connections || [])
})

export const selectConnectionByLinkedRentalId = (rentalId: string, name?: string) =>
  createSelector(selectAllConnections, (connections: Connection[]) => {
    return R.filter((c) => c.rentalId === rentalId && (name ? c.name === name : true), connections)
  })

export const selectConnectionsByRealChannelId = (channelId: string) =>
  createSelector(selectAllConnections, (connections: Connection[]) => {
    return R.filter((conn) => {
      return getRealChannelId(conn.channelId) === channelId
    }, connections || [])
  })

export const selectChannelPropertyIds = (channelId: string) =>
  createSelector(selectConnectionsByRealChannelId(channelId), (items) => {
    return R.uniq(R.map((t) => t.propertyId, items).filter((t) => !!t))
  })

export const selectConnectionsByPropertyId = (propertyId) =>
  createSelector(selectAllConnections, (connections: Connection[]) => {
    return R.filter((conn) => conn.propertyId == propertyId, connections || [])
  })

export const selectAllConnectionViews = createSelector(
  selectAllConnections,
  selectAllChannels,
  selectRentalEntities,
  selectAllChannelPropertySettings,
  (connections: Connection[], channels: Channel[], rentalEntities, settings) => {
    return R.map((conn) => {
      const rental: Rental = rentalEntities[conn.rentalId]
      const channel = findConnectionChannel(conn, channels)
      const view = new ConnectionView(channel, rental, conn)
      if (channel && view.isApi) {
        const setting = findChannelPropertySetting(settings, channel.name, conn.propertyId)
        view.isCreatedOnTokeet = !!setting
      }
      return view
    }, connections)
  }
)

export const selectAllAPIConnectionViews = createSelector(selectAllConnectionViews, (items: ConnectionView[]) => {
  return items.filter((t) => t.isApi)
})

export const selectAPIConnectionsByChannelName = (name: ChannelNameTokens) =>
  createSelector(selectAllAPIConnectionViews, (connections: ConnectionView[]) => {
    if (!name) return connections
    return R.filter((c) => lodash.includes(c.name, name), connections)
  })

export const selectConnectionViewsByChannelId = createSelector(
  selectAllConnectionViews,
  (connections: ConnectionView[], props) => {
    return R.filter((conn) => {
      return getRealChannelId(conn.channelId) === props.id
    }, connections)
  }
)

export function filterImportedCalendarsByChannelName(connections: ConnectionView[], name: string) {
  const channelName = name?.startsWith('vrbo') ? 'Vrbo' : name
  return R.filter((connection: ConnectionView) => {
    return connection.isImported && R.toLower(connection.source + '').indexOf(R.toLower(channelName || '')) > -1
  }, connections)
}

export const selectImportedCalendarsByChannelName = createSelector(
  selectAllConnectionViews,
  (connections: ConnectionView[], props) => {
    return filterImportedCalendarsByChannelName(connections, props.name)
  }
)

export const selectImportedCalendarsByRentalId = (rentalId: string) =>
  createSelector(selectAllConnectionViews, (connections: ConnectionView[]) => {
    return R.filter((connection: ConnectionView) => {
      return connection.isImported && connection.rentalId === rentalId
    }, connections)
  })

export function filterExportedCalendarByChannelName(connections: ConnectionView[], name: string) {
  return R.filter((connection: ConnectionView) => {
    return connection.isExported && R.toLower(connection.name + '').indexOf(R.toLower(name || '')) > -1
  }, connections)
}

export const selectExportedCalendarsByChannelName = createSelector(
  selectAllConnectionViews,
  (connections: ConnectionView[], props) => {
    return filterExportedCalendarByChannelName(connections, props.name)
  }
)

export const selectExportedCalendarsByRentalId = (rentalId: string) =>
  createSelector(selectAllConnectionViews, (connections: ConnectionView[]) => {
    return R.filter((connection: ConnectionView) => {
      return connection.isExported && connection.rentalId === rentalId
    }, connections)
  })

export const selectConnectionViewById = createSelector(
  selectAllConnectionViews,
  (connections: ConnectionView[], props) => {
    return R.find((conn) => conn.id === props.id, connections)
  }
)

export const selectConnectionsChannels = createSelector(selectAllConnectionViews, (connections) => {
  const result = R.pipe(
    R.map((c: ConnectionView) => c.channel),
    R.uniq
  )(connections)
  return sortAscend('friendlyName')(result) as ConnectionView[]
})

export const selectAvailableRentalForNewExportedCalendar = createSelector(
  selectAllRentals,
  selectAllConnectionViews,
  (rentals: Rental[], connections: ConnectionView[], props) => {
    connections = filterExportedCalendarByChannelName(connections, props.name)
    const linkedRentalIds = R.uniq(
      R.filter(
        (i) => !!i,
        R.map((c) => c.rentalId, connections)
      )
    )

    return R.filter((r) => linkedRentalIds.indexOf(r.id) < 0, rentals || [])
  }
)

export const selectAvailableChannelsForNewExportedCalendar = (rentalId: string) =>
  createSelector(
    selectAllICalChannelViews,
    selectExportedCalendarsByRentalId(rentalId),
    (icalChannels: Channel[], exportedItems: ConnectionView[]) => {
      const exportedChannelIds = R.map((i) => i.channelId, exportedItems)

      return R.filter((ch) => !exportedChannelIds.includes(ch.id), icalChannels)
    }
  )

export const selectAvailableChannelsForNewImportedCalendar = (rentalId: string) =>
  createSelector(
    selectAllICalChannelViews,
    selectImportedCalendarsByRentalId(rentalId),
    (icalChannels: Channel[], importedItems: ConnectionView[]) => {
      const importedChannelIds = R.map((i) => i.channelId, importedItems)

      return R.filter((ch) => !importedChannelIds.includes(ch.id), icalChannels)
    }
  )

export const selectAvailableRentalForNewImportedCalendar = createSelector(
  selectAllRentals,
  selectAllConnectionViews,
  (rentals: Rental[], connections: ConnectionView[], props) => {
    connections = filterImportedCalendarsByChannelName(connections, props.name)
    const linkedRentalIds = R.uniq(
      R.filter(
        (i) => !!i,
        R.map((c) => c.rentalId, connections)
      )
    )

    return R.filter((r) => linkedRentalIds.indexOf(r.id) < 0, rentals || [])
  }
)

export const selectAvailableRentalForNewAPIConnection = createSelector(
  selectAllRentals,
  selectAllConnections,
  (rentals: Rental[], connections: Connection[], props) => {
    connections = R.filter((conn) => {
      return getRealChannelId(conn.channelId) === props.id
    }, connections || [])
    let linkedRentalIds = R.uniq(
      R.filter(
        (i) => !!i,
        R.map((c) => c.rentalId, connections)
      )
    )
    if (props.selected) {
      linkedRentalIds = R.difference(linkedRentalIds, [props.selected])
    }

    return R.filter((r) => linkedRentalIds.indexOf(r.id) < 0, rentals || [])
  }
)

export const selectLinkedRentalsForChannel = (channelId: string) =>
  createSelector(selectRentalEntities, selectAllConnections, (rentalsById, connections: Connection[]) => {
    connections = R.filter((conn) => {
      return getRealChannelId(conn.channelId) === channelId
    }, connections || [])
    return R.uniq(
      R.filter(
        (i) => !!i,
        R.map((c) => c.rentalId, connections)
      )
    )
      .map((id: string) => rentalsById[id])
      .filter((t) => !!t)
  })

export const selectNewAvailableChannelIdForNewApiConnection = createSelector(
  selectAllConnections,
  (connections: Connection[], props) => {
    const connectionsForChannel = R.filter((conn) => {
      return getRealChannelId(conn.channelId) === props.id
    }, connections || [])

    const connectionChannelIds = R.uniq(R.map((c) => c.channelId, connectionsForChannel))
    return connectionChannelIds.length <= 1 ? props.id : `${props.id}.${connectionChannelIds.length - 1}`
  }
)

export const selectAllAvailableCustomICalChannelName = createSelector(
  selectAllConnections,
  (connections: Connection[]) => {
    return R.pipe(
      R.map<Connection, string>(R.path(['attributes', 'channel_display_name'])),
      R.concat(['Unknown']),
      R.filter(isSomething),
      R.uniq
    )(connections)
  }
)

export const selectAllAvailableChannelNames = createSelector(selectAllConnectionViews, (conns: ConnectionView[]) => {
  return R.pipe(R.map(R.path(['channelFriendlyName'])), R.filter(isSomething), R.uniq, R.sortBy(R.toLower))(conns)
})

export const selectConnectionViewsByChannel = createSelector(
  selectAllConnectionViews,
  selectAllChannels,
  (conns: ConnectionView[], channels: Channel[]) => {
    const channelById = lodash.keyBy(channels, (c) => c.id)
    return lodash.mapValues(channelById, (ch, id) => {
      return R.filter((c) => isChannelConnection(ch, c), conns)
    })
  }
)
