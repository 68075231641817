import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ApiKeysService } from './api-keys.service'
import { ActionFailed } from '../utility'
import { deleteApiKey, deleteApiKeyComplete, loadApiKeys, loadApiKeysComplete } from '../api-keys/api-keys.actions'

@Injectable()
export class ApiKeyEffects {
  @Effect()
  loadApiKeys$ = this.actions$.pipe(
    ofType(loadApiKeys),
    exhaustMap(() =>
      this.apiKeysService.getApiKeys().pipe(
        map((keys) => loadApiKeysComplete({ keys })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteApiKey$ = this.actions$.pipe(
    ofType(deleteApiKey),
    concatMap(({ key }) =>
      this.apiKeysService.remove(key).pipe(
        map((id) => deleteApiKeyComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private apiKeysService: ApiKeysService) {}
}
