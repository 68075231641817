import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { CreateRateMappingPayload, UpdateRateMappingPayload } from './rental-rate-mapping.model'
import { Rental } from './rental.models'

export const addRateMapping = createAction(
  '[RateMapping] Add Rate Mapping To Rental',
  props<{ rentalId: string; payload: CreateRateMappingPayload }>()
)
export const addRateMappingComplete = createAction(
  '[RateMapping] Add Rate Mapping To Rental Complete',
  props<{ update: Update<Rental> }>()
)

export const updateRateMappings = createAction(
  '[RateMapping] Update RateMaps ',
  props<{ items: { rentalId: string; mappingId: string; payload: UpdateRateMappingPayload }[] }>()
)
export const updateRateMappingsComplete = createAction(
  '[RateMapping] Update RateMaps  Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const toggleRateMappingsSync = createAction(
  '[RateMapping] Toggle RateMap Sync',
  props<{ items: { rentalId: string; mappingId: string; enabled: boolean }[] }>()
)
export const toggleRateMappingsSyncComplete = createAction(
  '[RateMapping] Toggle RateMap Last Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const setRateMappingsLastSync = createAction(
  '[RateMapping] Set RateMaps Last Sync',
  props<{ items: { rentalId: string; mappingId: string; date: number }[] }>()
)
export const setRateMappingsLastSyncComplete = createAction(
  '[RateMapping] Set RateMaps Last Sync Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const deleteRateMapping = createAction(
  '[RateMapping] Delete RateMap',
  props<{ rentalId: string; mappingId: string }>()
)
export const deleteRateMappingComplete = createAction(
  '[RateMapping] Delete RateMap Complete',
  props<{ update: Update<Rental> }>()
)

export const deleteRateMappings = createAction(
  '[RateMapping] Delete RateMaps',
  props<{ items: { rentalId: string; mappingId: string }[] }>()
)
export const deleteRateMappingsComplete = createAction(
  '[RateMapping] Delete RateMaps Complete',
  props<{ updates: Update<Rental>[] }>()
)

export const cleanRateMappings = createAction('[RateMapping] Clean RateMaps', props<{ rentalIds: string[] }>())
