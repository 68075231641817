import { Injectable } from '@angular/core'
import { SubscriptionPermissionCheckerService } from '@tv3/services/subscription-permission-checker.service'
import { switchMap, take } from 'rxjs/operators'
import { of } from 'rxjs'
import { UserDialogComponent } from '@tv3/containers/settings/user-settings/user-dialog/user-dialog.component'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService, User } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class UserDialogService {
  constructor(
    private dialog: DialogService,
    private subscriptionPermissionCheckerService: SubscriptionPermissionCheckerService
  ) {}

  public open(user?: User) {
    if (user) {
      return this.openDialog(user).afterClosed()
    }

    return this.subscriptionPermissionCheckerService
      .check({
        checkAddUserPermission: true,
      })
      .pipe(
        take(1),
        switchMap((allowed) => {
          if (allowed) {
            return this.openDialog().afterClosed()
          } else {
            return of(null)
          }
        })
      )
  }

  private openDialog(user?: User) {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      data: { user },
    }

    return this.dialog.openVariableDialog(UserDialogComponent, defaultConfig)
  }
}
