<div class="">
  <form [formGroup]="form" [formEditablePermission]="form" [class.loading-section]="isSaving$ | async">
    <div class="d-grid gap-3" style="grid-template-columns: auto auto">
      <!-- Check-in / Check-out Times -->
      <div class="card p-3">
        <h6 class="text-uppercase">
          Check-in / Check-out Times
          <app-info-icon
            tooltipText="The rental default check-in and check-out times are used for every new booking received from
                        a connected iCal channel. The check-in/out times of the booking will be set to these defaults automatically.
                        You can change the individual booking times on the booking detail page. These times are especially important
                        when creating time-based triggers. If you create triggers for before/after booking start or end then these
                        times will be used as reference."
            tooltipPosition="right"
          ></app-info-icon>
        </h6>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input
                type="text"
                matInput
                atp-time-picker
                changeToMinutes="true"
                formControlName="checkIn"
                placeholder="Check-in Time"
                required
              />
              <span matSuffix><i class="fal fa-clock"></i></span>
              <mat-error> Check-in time is <strong>required</strong> </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input
                type="text"
                matInput
                atp-time-picker
                changeToMinutes="true"
                formControlName="checkOut"
                placeholder="Check-out Time"
                required
              />
              <span matSuffix><i class="fal fa-clock"></i></span>
              <mat-error> Check-out time is <strong>required</strong> </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block app-select-field">
              <app-select
                required
                [items]="timezoneList"
                [searchable]="true"
                formControlName="timezone"
                placeholder="TimeZone"
              >
                <ng-container class="error-container">
                  <mat-error> Time zone is <strong>required</strong> </mat-error>
                </ng-container>
              </app-select>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <!-- Codes & Passwords -->
      <div class="card p-3">
        <h6 class="text-uppercase">Codes & Passwords</h6>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field2 class="mat-block">
                  <input type="text" matInput formControlName="wifiName" placeholder="WiFi Name" />
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2 class="mat-block">
                  <input type="text" matInput formControlName="wifiPassword" placeholder="WiFi Password" />
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2 class="mat-block">
                  <input type="text" matInput formControlName="securityCode" placeholder="Security Code" />
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2 class="mat-block">
                  <input type="text" matInput formControlName="keyPickup" placeholder="Key Pickup" />
                </mat-form-field2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Check-in Instructions -->
      <div class="card p-3">
        <h6 class="text-uppercase">Check-in Instructions</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="checkInInstructions"
                placeholder="Enter Instructions here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <!-- Check-out Instructions -->
      <div class="card p-3">
        <h6 class="text-uppercase">Check-out Instructions</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="checkOutInstructions"
                placeholder="Enter Instructions here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <!-- Directions -->
      <div class="card p-3">
        <h6 class="text-uppercase">Directions</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="directionInstructions"
                placeholder="Enter Directions here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <!-- House Rules -->
      <div class="card p-3">
        <h6 class="text-uppercase">House Rules</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="houseRules"
                placeholder="Enter House Rules here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <!-- Special Instructions -->
      <div class="card p-3">
        <h6 class="text-uppercase">Special Instructions</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="specialInstructions"
                placeholder="Enter Special Instructions here"
                rows="4"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <!-- Payment Terms -->
      <div class="card p-3">
        <h6 class="text-uppercase">Payment Terms</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="paymentTerms"
                placeholder="Enter Payment Terms here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <!--Payment Instructions-->
      <div class="card p-3">
        <h6 class="text-uppercase">Payment Instructions</h6>
        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="paymentInstructions"
                placeholder="Enter Payment Instructions here"
                rows="4"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="8"
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
