import { Injectable } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  Channel,
  AddPPIDChannel,
  AddPPIDChannelComplete,
  selectSomeOnce,
  selectImageCountsLoaded,
  loadRentalsImageCounts,
  selectAllRentals,
  addRentalImagesComplete,
} from '@tokeet-frontend/tv3-platform'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { LoadConnectionsByChannel, RefreshConnectionsForChannel } from '@tv3/store/connection/connection.actions'
import * as lodash from 'lodash'
import { merge } from 'rxjs'
import { filter, switchMap, take, tap } from 'rxjs/operators'

@Injectable()
export class ChannelConnectHelperService {
  constructor(private store: Store<any>, private actions: Actions, private amplitudeService: AmplitudeService) {}

  connectChannelWithPropertyId(channel: Channel, propertyId: any) {
    this.store.dispatch(
      AddPPIDChannel({
        channelId: channel.id,
        data: {
          property_id: propertyId,
          channel_id: channel.id,
          channel_name: channel.name,
          subscriber: channel.friendlyName,
          handler: channel.handler,
        },
        channelName: channel.name,
      })
    )
    return this.actions.pipe(
      ofType(AddPPIDChannelComplete),
      take(1),
      tap(() => this.doAfterConnected(channel))
    )
  }

  doAfterConnected(channel: Channel) {
    this.amplitudeService.logEvent('add-channel')
    const channelName = channel.name?.toLowerCase()
    this.amplitudeService.logEvent(`add-channel-${channelName}`)
    // refresh listings
    this.store.dispatch(LoadConnectionsByChannel({ channelName: channel.name, channelId: channel.id }))
    this.store.dispatch(
      RefreshConnectionsForChannel({
        channelName: channel.name,
        channelId: channel.id,
        silent: true,
      })
    )
  }

  rentalImageCountsGuard() {
    return merge([
      this.actions.pipe(ofType(addRentalImagesComplete)),
      this.store.pipe(
        select(selectImageCountsLoaded),
        filter((loaded) => !loaded)
      ),
    ]).pipe(
      switchMap(() => this.store.pipe(selectSomeOnce(selectAllRentals))),
      tap((rentals) => this.store.dispatch(loadRentalsImageCounts({ ids: lodash.map(rentals, (r) => r.id) })))
    )
  }
}
