<div (click)="onToggle()" class="box-head d-flex align-items-center gap-3 text-truncate">
  <div>
    <i class="far fa-grip-dots-vertical"></i>
    {{ header }}
  </div>
  <div class="flex-fill"></div>
  <ng-container *ngIf="hasEdit">
    <ng-content select=".header-actions"></ng-content>
    <a
      *ngIf="canEdit"
      (click)="$event.stopPropagation(); edit.emit()"
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      class="d-flex"
    >
      <i class="cs-icon cs-icon-pencil"></i>
    </a>
  </ng-container>
  <a class="d-flex">
    <i *ngIf="isExpanded" class="fas fa-arrow-up"></i>
    <i *ngIf="!isExpanded" class="fas fa-arrow-right"></i>
  </a>
</div>
<div *ngIf="isExpanded" class="box-body mt-3">
  <ng-content></ng-content>
</div>
