import { is, pathOr, sort, toString } from 'ramda'

export function sortAscend<T>(field): (list: T[]) => T[] {
  return sort((a, b) =>
    compareStr(pathOr('', is(Array, field) ? field : [field], a), pathOr('', is(Array, field) ? field : [field], b))
  )
}

export function compareStr(s1: string, s2: string) {
  s1 = toString(s1)
  s2 = toString(s2)
  return s1.localeCompare(s2, undefined, {
    numeric: true,
    sensitivity: 'base',
  })
}
