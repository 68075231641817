import { Inject, Injectable } from '@angular/core'
import { Storage } from './storage'
import { STORAGE_PREFIX } from '../tokens'

@Injectable()
export class LocalStorage extends Storage {
  constructor(@Inject(STORAGE_PREFIX) storagePrefix) {
    super(localStorage, storagePrefix)
  }
}
