import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  ChannelNameTokens,
  ChannelService,
  ConnectedChannelAccountResponse,
  Destroyable,
  selectChannelByName,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { map, observeOn, startWith, switchMap } from 'rxjs/operators'
import { asyncScheduler, Observable, of } from 'rxjs'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'

@Component({
  selector: 'app-select-to-create-listing-dialog',
  host: { class: 'modal-content' },
  templateUrl: './select-to-create-listing-dialog.component.html',
  styleUrls: ['./select-to-create-listing-dialog.component.scss'],
})
export class SelectToCreateListingDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    account: [undefined, [Validators.required]],
    rental: [undefined, [Validators.required]],
  })

  accounts$ = this.channelService.getConnectedAccounts(this.data.channelId)
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  isActiveAccount$: Observable<boolean> = of(true)

  constructor(
    public dialogRef: MatDialogRef<SelectToCreateListingDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private channelService: ChannelService,
    private airbnbService: ConnectionAirbnbService,
    @Inject(MAT_DIALOG_DATA) public data: { channelId: string }
  ) {
    super()
  }

  ngOnInit() {
    this.isActiveAccount$ = this.store.pipe(
      select(selectChannelByName, { name: ChannelNameTokens.AirBnBV2API }),
      switchMap((ch) => {
        if (ch.id === this.data.channelId) {
          return this.form.get('account').valueChanges.pipe(
            startWith(this.form.get('account').value),
            switchMap((account) => {
              if (!account) {
                return of(true)
              }
              return this.airbnbService.getPropertyStatus(account.propertyId).pipe(map((status) => !!status))
            })
          )
        } else {
          return of(true)
        }
      })
    )
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues)
  }
}
