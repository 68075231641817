<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title"><i class="fal fa-calendar-alt"></i> Upload Airbnb transactions from CSV Files</h5>
    <button (click)="onClose()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-csv-file-parser
      [columnDefs]="columnDefs"
      [guide]="guide"
      (parsed)="onCsvFilesParsed($event)"
    ></app-csv-file-parser>
    <div class="row">
      <div class="col-sm-12">
        <app-select-search
          [items]="rentals"
          [ctrl]="form.get('rental')"
          [multiple]="false"
          bindLabel="name"
          placeholder="Rental"
          [inline]="false"
        ></app-select-search>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button
      (click)="onCreate()"
      [disabled]="!files?.length || form.invalid || hasErrors()"
      type="button"
      class="btn btn-secondary-info create-btn"
    >
      <i class="cs-icon cs-icon-upload"></i> upload
    </button>
  </div>
</form>
