import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './airbnb-api.state'

export const airbnbDialogState = createFeatureSelector<state.State>('airbnb-dialog')

export const activeTab = createSelector(airbnbDialogState, (state: state.State) => state.tab)

export const selectAirbnbPhotos = createSelector(airbnbDialogState, (state: state.State) => state.photos)

export const selectDetails = createSelector(airbnbDialogState, (state: state.State) => state.details)
