import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(name: string): string {
    if (name) {
      const initials = (name + '').trim().split(' ')
      if (2 < initials.length) {
        return InitialsPipe._constructInitials(initials.slice(0, 2))
      } else {
        return InitialsPipe._constructInitials(initials)
      }
    }
    return ''
  }

  private static _constructInitials(elements: string[]) {
    if (elements && elements.length > 0) {
      return elements
        .filter((el) => el && el.length > 0)
        .map((el) => el[0].toUpperCase())
        .join('')
    }
    return ''
  }
}
