import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { map, startWith, tap } from 'rxjs/operators'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  addChannelAdjustment,
  ChannelAdjustment,
  Destroyable,
  Rental,
  SaveForm,
  selectAllApiChannelViews,
  selectAllChannelAdjustments,
  selectAllRentals,
  Toaster,
  tokeetDashboardChannel,
  untilDestroy,
  updateChannelAdjustment,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { selectChannelsWithAdditionalChannels } from '@tv3/store/channel/selectors'
import { CustomChannelResponse, selectCustomChannelEntities } from '@tokeet-frontend/channels'

export interface ChannelAutomationDialogInput {
  channelAdjustment?: ChannelAdjustment
}

type AdjustType = 'amount' | 'percent'

@Component({
  selector: 'app-channel-automation-dialog',
  host: { class: 'modal-content' },
  templateUrl: './channel-automation-dialog.component.html',
  styleUrls: ['./channel-automation-dialog.component.scss'],
})
export class ChannelAutomationDialogComponent extends Destroyable implements OnInit, OnDestroy {
  isEdit = false

  form: FormGroup

  channels$ = this.store.select(
    selectChannelsWithAdditionalChannels(selectAllApiChannelViews, 'name', [tokeetDashboardChannel])
  )
  customChannelsById: Record<string, CustomChannelResponse> = {}
  rentals$ = this.store.select(selectAllRentals)
  filteredRentals$: Observable<Rental[]>

  get adjusment() {
    return this.data?.channelAdjustment
  }
  get adjustType(): AdjustType {
    return this.form?.get('adjustType').value
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
    private toaster: Toaster,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<ChannelAutomationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ChannelAutomationDialogInput
  ) {
    super()

    this.isEdit = !!data.channelAdjustment?.pkey

    this.buildForm(data.channelAdjustment)
  }

  ngOnInit() {
    this.store.pipe(select(selectCustomChannelEntities), untilDestroy(this)).subscribe((data) => {
      this.customChannelsById = data
    })
    this.filteredRentals$ = combineLatest([
      this.rentals$,
      this.store.pipe(select(selectAllChannelAdjustments)),
      this.form.get('channel_id').valueChanges.pipe(startWith(null as string)),
    ]).pipe(
      map(([rentals, channelAdjustments, channel_id]) => {
        if (!channel_id) return rentals

        const assignedRentals = {}

        channelAdjustments?.forEach((ca) => {
          if (this.isEdit && ca.pkey === this.data.channelAdjustment.pkey) return
          if (ca.channel_id !== channel_id) return

          ca.rentals.forEach((rentalId) => {
            assignedRentals[rentalId] = true
          })
        })

        rentals = rentals.filter((rental) => !assignedRentals[rental.id])

        return rentals
      }),
      tap((rentals) => {
        const formControl = this.form.get('rentals')

        const rentalsById = lodash.keyBy(rentals, (rental) => rental.id)
        let selectedRentals = formControl.value as string[]

        selectedRentals = selectedRentals.filter((rentalId) => rentalsById[rentalId])

        formControl.setValue(selectedRentals)
      })
    )
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    const { adjustType, ...payload } = form.getRawValue()

    if (payload.adjust_amt) {
      payload.adjust_amt = payload.adjust_amt
      payload.adjust_pct = 0
    } else if (payload.adjust_pct) {
      payload.adjust_pct = payload.adjust_pct
      payload.adjust_amt = 0
    } else {
      this.toaster.error('You must specify either Adjust Amount or Adjust Percent.')
      return
    }

    if (this.isEdit) {
      this.store.dispatch(updateChannelAdjustment({ id: this.adjusment.pkey, payload }))
    } else {
      this.amplitudeService.logEvent(`add-channel-adjustment`)
      this.store.dispatch(addChannelAdjustment({ payload }))
    }
    this.close()
  }

  close() {
    this.dialogRef.close()
  }

  private buildForm(data?: ChannelAdjustment) {
    this.form = this.formBuilder.group({
      channel_id: [data?.channel_id, [Validators.required]],

      rentals: [data?.rentals || [], [Validators.required]],

      adjustType: [!!data?.adjust_pct ? 'percent' : 'amount'],

      adjust_amt: [
        data?.adjust_amt || null,
        [
          (control: AbstractControl) => {
            return this.adjustType ? Validators.required(control) : null
          },
        ],
      ],

      adjust_pct: [
        data?.adjust_pct || null,
        [
          (control: AbstractControl) => {
            return this.adjustType ? Validators.required(control) : null
          },
        ],
      ],
    })

    if (this.isEdit) {
      this.form.get('channel_id').disable()
    }
    this.subscribeForm()
    this.toggleAdjustFields()
  }

  private subscribeForm() {
    this.form
      .get('adjustType')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((value: AdjustType) => {
        this.toggleAdjustFields(value)
      })
  }

  private toggleAdjustFields(adjustType?: AdjustType) {
    if (!adjustType) {
      adjustType = this.form.get('adjustType').value
    }

    const adjust_amt = this.form.get('adjust_amt') as FormControl
    const adjust_pct = this.form.get('adjust_pct') as FormControl

    const control1 = adjustType === 'amount' ? adjust_pct : adjust_amt

    control1.reset(null, { onlySelf: true })
    control1.disable({ onlySelf: true })

    const control2 = adjustType === 'amount' ? adjust_amt : adjust_pct

    control2.enable({ onlySelf: true })
  }
}
