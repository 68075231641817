<form [formGroup]="form">
  <div class="row">
    <!-- Name -->
    <div class="col-sm-12">
      <mat-form-field2 class="mat-block" matTooltip="Enter a unique name for your channel.">
        <input
          type="text"
          matInput
          required
          placeholder="Channel Name"
          formControlName="friendly_name"
          maxlength="50"
        />
        <mat-error *ngIf="form.get('friendly_name').touched && form.get('friendly_name').hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('friendly_name').touched && form.get('friendly_name').hasError('maxlength')">
          Name must be at most <strong>50</strong> characters long
        </mat-error>
        <mat-hint align="end">{{ form.get('friendly_name').value.length || 0 }}/50</mat-hint>
      </mat-form-field2>
    </div>

    <div class="col-sm-12">
      <mat-form-field2 class="mat-block">
        <textarea matInput placeholder="Description" formControlName="description"></textarea>
      </mat-form-field2>
    </div>
    <div class="col-sm-12" *ngIf="!!form.get('logo').value">
      <img [src]="form.get('logo').value" alt="LOGO" style="height: 50px" class="border" />
    </div>
    <div class="col-sm-12 mt-3">
      <button (click)="onUpload()" type="button" class="btn btn-secondary-primary create-btn">
        <i class="far fa-image"></i> Upload Logo
      </button>
    </div>
  </div>
</form>
