import * as R from 'ramda'
import { epochToView } from '@tokeet-frontend/tv3-platform'
import { InvoiceTemplateView } from '../templates/template.model'
import { getCountryName } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'
import { AutomationEventTypes } from '@tokeet-frontend/automations'

declare const require: any

const capitalize = require('lodash/capitalize')

export enum ConditionType {
  UNTIL_CHECKIN = 'until_checkin',
  SINCE_CHECKIN = 'since_checkin',
  UNTIL_CHECKOUT = 'until_checkout',
  SINCE_CHECKOUT = 'since_checkout',
  STAY_LENGTH = 'stay_length',
  GUEST_COUNT = 'guest_count',
  GUEST_COUNTRY = 'guest_country',
  BOOKING_TOTAL = 'booking_total',
  BOOKINGS_STATUS = 'booking_status',
  BOOKING_TAGS = 'booking_tags',
}

export enum ConditionValueFormType {
  DaysHours,
  Nights,
  Number,
  Country,
  None,
  BookingTag,
}

export function resolveFormType(type: ConditionType): ConditionValueFormType {
  switch (type) {
    case ConditionType.UNTIL_CHECKIN:
    case ConditionType.SINCE_CHECKIN:
    case ConditionType.UNTIL_CHECKOUT:
    case ConditionType.SINCE_CHECKOUT:
      return ConditionValueFormType.DaysHours
    case ConditionType.STAY_LENGTH:
      return ConditionValueFormType.Nights
    case ConditionType.GUEST_COUNT:
    case ConditionType.BOOKING_TOTAL:
      return ConditionValueFormType.Number
    case ConditionType.BOOKINGS_STATUS:
      return ConditionValueFormType.None
    case ConditionType.GUEST_COUNTRY:
      return ConditionValueFormType.Country
    case ConditionType.BOOKING_TAGS:
      return ConditionValueFormType.BookingTag
  }
}

export const LESS_THAN = {
  comp: '<',
  label: 'less than',
}

export const MORE_THAN = {
  comp: '>',
  label: 'more than',
}

export const EXACTLY = {
  comp: '=',
  label: 'exactly',
}

export const EQUALS = {
  comp: '=',
  label: 'equals',
}

export const IS = {
  comp: '=',
  label: 'is',
}

export const IS_NOT = {
  comp: '!=',
  label: 'is not',
}

export const UNPAID = {
  value: 0,
  comp: '=',
  label: 'is unpaid',
}

export const PAID = {
  value: 1,
  comp: '=',
  label: 'is paid',
}

export const INCLUDE = {
  comp: '=',
  label: 'include',
}

export const DOES_NOT_INCLUDE = {
  comp: '!=',
  label: 'does not include',
}

export const comparatorMapping = {
  [ConditionType.UNTIL_CHECKIN]: [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.SINCE_CHECKIN]: [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.UNTIL_CHECKOUT]: [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.SINCE_CHECKOUT]: [LESS_THAN, MORE_THAN, EXACTLY],
  [ConditionType.STAY_LENGTH]: [LESS_THAN, MORE_THAN, EQUALS],
  [ConditionType.GUEST_COUNT]: [LESS_THAN, MORE_THAN, EQUALS],
  [ConditionType.GUEST_COUNTRY]: [IS, IS_NOT],
  [ConditionType.BOOKING_TOTAL]: [LESS_THAN, MORE_THAN],
  [ConditionType.BOOKINGS_STATUS]: [PAID, UNPAID],
  [ConditionType.BOOKING_TAGS]: [INCLUDE, DOES_NOT_INCLUDE],
}

export const typeDescriptionMapping = {
  [ConditionType.UNTIL_CHECKIN]: 'Less than 2 days and 1 hour until checkin',
  [ConditionType.SINCE_CHECKIN]: 'More than 5 days and 0 hours since checkin',
  [ConditionType.UNTIL_CHECKOUT]: 'Exactly 1 day and 12 hours until checkout',
  [ConditionType.SINCE_CHECKOUT]: 'More than 14 days and 0 hours since checkout',
  [ConditionType.STAY_LENGTH]: 'Length of stay equals 7 nights',
  [ConditionType.GUEST_COUNT]: 'Number of guests more than 4',
  [ConditionType.GUEST_COUNTRY]: 'Guest country is Australia',
  [ConditionType.BOOKING_TOTAL]: 'Booking total more than 1000',
  [ConditionType.BOOKINGS_STATUS]: 'Booking is paid',
  [ConditionType.BOOKING_TAGS]: `Booking tags include 'SpecialRequest'`,
}

export const typeMapping = {
  [ConditionType.UNTIL_CHECKIN]: {
    value: ConditionType.UNTIL_CHECKIN,
    label: 'until checkin',
  },
  [ConditionType.SINCE_CHECKIN]: {
    value: ConditionType.SINCE_CHECKIN,
    label: 'since checkin',
  },
  [ConditionType.UNTIL_CHECKOUT]: {
    value: ConditionType.UNTIL_CHECKOUT,
    label: 'until checkout',
  },
  [ConditionType.SINCE_CHECKOUT]: {
    value: ConditionType.SINCE_CHECKOUT,
    label: 'since checkout',
  },
  [ConditionType.STAY_LENGTH]: {
    value: ConditionType.STAY_LENGTH,
    label: 'length of stay',
  },
  [ConditionType.GUEST_COUNT]: {
    value: ConditionType.GUEST_COUNT,
    label: 'number of guests',
  },
  [ConditionType.GUEST_COUNTRY]: {
    value: ConditionType.GUEST_COUNTRY,
    label: 'guest country',
  },
  [ConditionType.BOOKING_TOTAL]: {
    value: ConditionType.BOOKING_TOTAL,
    label: 'booking total',
  },
  [ConditionType.BOOKINGS_STATUS]: {
    value: ConditionType.BOOKINGS_STATUS,
    label: 'booking status',
  },
  [ConditionType.BOOKING_TAGS]: {
    value: ConditionType.BOOKING_TAGS,
    label: 'booking tags',
  },
}

export const typeMappingValues = R.sort((a, b) => a.label.toString().localeCompare(b.label), R.values(typeMapping))

export interface InvoiceRule {
  subscription: string
  attributes: {
    name: string
    tags: string[]
    charge: boolean
    send: boolean
    mark: boolean
    failed: boolean
    reminder: {
      when: 'time.before.invoice.due' | 'time.after.invoice.due'
      days: number
    } | null
  }
  users: string[]
  template: string
  pkey: string
  archived: number
  event: AutomationEventTypes
  settings: {
    hours: number
    days: number
    channel: string
    template: string
    creator: string
    not_rentals: string[]
    users: string[]
    type: any
    rental: string
  }
  lastused: number
  conditions: RuleCondition[]
  creator: string
  status: number
  updated: number
  created: number
  service: 'invoicing'
  handler: string
  timeevent: string
  account: number
  group_id: string
}

export interface InvoiceRuleGroup extends Omit<InvoiceRule, 'pkey'> {
  ids: string[]
  rentals: string[]
  channels: string[]
  items: InvoiceRule[]
  //
  templateView: InvoiceTemplateView
}

export interface RuleConditionModel {
  key: string
  type: ConditionType
  comp: string
  value?: number | string
  rule?: string
  days?: number
  hours?: number
}

export interface InvoiceRuleCreateForm {
  name: string
  event: string
  timeevent: {
    event: string
    days: number
    hours: number
  }
  conditions: any[]
  channels: string[]
  rentals: string[]
  not_rentals: string[]
  users: Array<string | number>
  template: string
  active: boolean
  charge: boolean
  send: boolean
  mark: boolean
  tags: string[]
  failedReminder: boolean
  reminder: boolean
  reminderWhen: 'time.before.invoice.due' | 'time.after.invoice.due'
  reminderDays: number
}

export interface InvoiceRuleUpdateForm {
  name: string
  event: string
  timeevent: {
    event: string
    days: number
    hours: number
  }
  conditions: any[]
  channels: string
  rentals: string
  not_rentals: string[]
  users: Array<string | number>
  template: string
  active: boolean
  charge: boolean
  send: boolean
  mark: boolean
  tags: string[]
  failedReminder: boolean
  reminder: boolean
  reminderWhen: 'time.before.invoice.due' | 'time.after.invoice.due'
  reminderDays: number
}

export interface CreateInvoiceRulePayload {
  event: string
  timeevent: string
  days: number
  hours: number
  status: number
  handler: string
  service: 'invoicing'
  conditions?: any[]
  attributes: {
    app?: string
    name: string
    tags?: string[]
    charge: boolean
    send: boolean
    mark: boolean
    failed: boolean
    reminder: {
      when: 'time.before.invoice.due' | 'time.after.invoice.due'
      days: number
    } | null
  }
  rentals: string[]
  channels: string[]
  not_rentals: string[]
  users: Array<string | number>
  template: string
}

export interface UpdateInvoiceRulePayload extends Omit<CreateInvoiceRulePayload, 'rentals' | 'channels'> {
  rental: string
  channel: string
}

export interface InvoiceRuleLog {
  event: string
  actions: any
  account: number
  guest: string
  sent: number
  trigger_name: string
  object: string
  guest_name: string
  service: 'invoicing'
  email_template: string
  event_msgs: string
  channel_name: string
  success: number
  template: string
  channel: string
  trigger: string
  handler: string
  pkey: string
  invoice_id: number
}

export interface InvoiceRuleLogView extends InvoiceRuleLog {
  templateView: string
}

export type CreateInvoiceRuleConditionPayload = Omit<RuleConditionModel, 'key'>

export interface InvoiceRuleConditionSave {
  ruleId: string
  conditionId: string
  condition: RuleConditionModel
}

export interface InvoiceRuleConditionSaveComplete {
  ruleId: string
  condition: RuleConditionModel
}

export interface InvoiceRuleConditionCreate {
  ruleId: string
  condition: RuleConditionModel
}

export interface InvoiceRuleConditionCreateComplete {
  ruleId: string
  condition: RuleConditionModel
}

export interface InvoiceRuleConditionDeleteRequest {
  ruleId: string
  conditionId: string
}

export class RuleCondition implements RuleConditionModel {
  type: ConditionType
  comp: string
  value?: number | string
  rule?: string
  days?: number
  hours?: number

  label: string
  key: string
  updated: number
  updatedView: string

  description: string

  constructor(condition: Partial<RuleCondition>) {
    this.key = condition.key
    this.type = condition.type
    this.comp = condition.comp
    if (!R.isNil(condition.value)) {
      this.value = condition.value
    }
    if (!R.isNil(condition.rule)) {
      this.rule = condition.rule
    }
    if (!R.isNil(condition.days)) {
      this.days = condition.days
    }
    if (!R.isNil(condition.hours)) {
      this.hours = condition.hours
    }
    this.updated = condition.updated
    this.updatedView = epochToView(condition.updated)

    try {
      let desc = ''
      let daysLabel = ''
      let hoursLabel = ''
      let nightsLabel = ''
      let compModel: any
      let join = ''

      switch (this.type) {
        case ConditionType.UNTIL_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.UNTIL_CHECKIN])
          daysLabel = this.days === 1 ? 'day' : 'days'
          hoursLabel = this.hours === 1 ? 'hour' : 'hours'
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${
            this.hours
          } ${hoursLabel} until checkin`
          break
        case ConditionType.SINCE_CHECKIN:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.SINCE_CHECKIN])
          daysLabel = this.days === 1 ? 'day' : 'days'
          hoursLabel = this.hours === 1 ? 'hour' : 'hours'
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${
            this.hours
          } ${hoursLabel} since checkin`
          break
        case ConditionType.UNTIL_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.UNTIL_CHECKOUT])
          daysLabel = this.days === 1 ? 'day' : 'days'
          hoursLabel = this.hours === 1 ? 'hour' : 'hours'
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${
            this.hours
          } ${hoursLabel} until checkout`
          break
        case ConditionType.SINCE_CHECKOUT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.SINCE_CHECKOUT])
          daysLabel = this.days === 1 ? 'day' : 'days'
          hoursLabel = this.hours === 1 ? 'hour' : 'hours'
          desc = `${capitalize(compModel.label)} ${this.days} ${daysLabel} and ${
            this.hours
          } ${hoursLabel} since checkout`
          break
        case ConditionType.STAY_LENGTH:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.STAY_LENGTH])
          nightsLabel = this.value === 1 ? 'night' : 'nights'
          join = compModel.label === 'equals' ? '' : ' is'
          desc = `Length of stay${join} ${compModel.label} ${this.value} ${nightsLabel}`
          break
        case ConditionType.GUEST_COUNT:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_COUNT])
          join = compModel.label === 'equals' ? '' : ' is'
          desc = `Number of guests${join} ${compModel.label} ${this.value}`
          break
        case ConditionType.GUEST_COUNTRY:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.GUEST_COUNTRY])
          desc = `Guest country ${compModel.label} ${getCountryName(<string>this.value)}`
          break
        case ConditionType.BOOKING_TOTAL:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.BOOKING_TOTAL])
          desc = `Booking total is ${compModel.label} ${this.value}`
          break
        case ConditionType.BOOKINGS_STATUS:
          compModel = R.find(
            (comp) => comp.comp === this.comp && comp.value === this.value,
            comparatorMapping[ConditionType.BOOKINGS_STATUS]
          )
          desc = `Booking ${compModel.label}`
          break
        case ConditionType.BOOKING_TAGS:
          compModel = R.find((comp) => comp.comp === this.comp, comparatorMapping[ConditionType.BOOKING_TAGS])
          desc = `Booking tag ${compModel.label} '${this.value}'`
          break
      }

      this.description = desc
    } catch (e) {
      console.log(e)
      this.description = ''
    }
  }
}

export interface RuleNotification {
  pkey: string
  rule: string
  event: string
  guest: string
  guest_name: string
  template: string
  channel: string
  channel_name: string
  event_msgs: string
  handler: string
  success: number
  sent: number
  account: number
  object: any
  service: string // should be 'automata' always
  trigger_name: string

  sentChartFormatted: string
}

export class RuleNotificationTableModel {
  date: number
  dateFormatted: string
  dateFormattedLong: string
  timeFormatted: string
  guest: string
  event: string
  template: string
  channel: string

  handler: string
  guestName: string
  eventName: string
  eventColor: string
  templateName: string
  channelName: string

  ruleId: string
  hasTrigger = true

  eventMessage: string

  constructor(notification: RuleNotification) {
    this.date = notification.sent
    this.dateFormatted = moment.unix(notification.sent).format('DD - MMM - YYYY')
    this.dateFormattedLong = moment.unix(notification.sent).format('DD - MMM - YYYY | h:mm A')
    this.timeFormatted = moment.unix(notification.sent).format('h:mm A')
    this.guest = notification.guest
    this.guestName = notification.guest_name
    this.channel = notification.channel
    this.channelName = notification.channel_name

    this.event = notification.event
    this.handler = notification.handler
    this.template = notification.template
    this.ruleId = notification.rule
    this.eventMessage = notification.event_msgs
  }
}
