import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './template.reducer'
import * as state from './template.state'
import { Template, TemplateType } from './template.model'
import * as R from 'ramda'

export const templateState = createFeatureSelector<state.State>('template-lib')

export const selectAllTemplates = createSelector(templateState, reducer.selectAll)

export const selectTemplateEntities = createSelector(templateState, reducer.selectEntities)

export const selectTemplatesLoaded = createSelector(templateState, (state: state.State) => state.isLoaded)

export const selectTemplateById = (id: string) =>
  createSelector(templateState, (state: state.State) => state.entities[id])

export const selectAllMessageTemplates = createSelector(selectAllTemplates, (templates) => {
  return R.filter((t: Template) =>
    [TemplateType.Email, TemplateType.PushNotification, TemplateType.Payload].includes(t.type)
  )(templates)
})

export const selectAllBrandingTemplates = createSelector(selectAllTemplates, (templates) => {
  return R.filter((t: Template) => TemplateType.CustomBranding == t.type)(templates)
})

export const selectTemplatesByTypes = (types: TemplateType[]) =>
  createSelector(selectAllTemplates, (templates) => {
    return R.filter((t: Template) => types?.includes(t.type))(templates)
  })

export const selectTemplatesForAutomationEvent = (event: string, type?: TemplateType) =>
  createSelector(selectAllTemplates, (items) => {
    return items.filter((t) => t.event === event && (type ? t.type === type : true))
  })

export const isUpdatingTemplates = createSelector(templateState, (state) => state.isUpdating)
