import { Injectable } from '@angular/core'
import { ShareCalendarDialogComponent } from './share-calendar-dialog.component'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ShareCalendarDialogService {
  constructor(private dialog: DialogService) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
    }

    return this.dialog.openFixedDialog(ShareCalendarDialogComponent, defaultConfig)
  }
}
