import { plainToClass } from 'class-transformer'
import { formatFileSize } from '../../functions'
import { epochToView } from '../../functions'
import { Expose, Serializable } from '../../functions/serializer'

export class Attachment extends Serializable<Attachment> {
  @Expose({ name: 'pkey' })
  id: string

  account: number
  archived: number
  basename: string
  created: number
  location: string
  name: string
  size: number
  type: string
  url: string
  user: string
  path: string
  uri: string

  contract: number // is contract or not
  service: string // dropbox, s3

  createdView: string
  sizeView: string

  static deserialize(data: any): Attachment {
    let attachment: Attachment = plainToClass<Attachment, Attachment>(Attachment, data)

    attachment.createdView = epochToView(attachment.created)
    attachment.sizeView = formatFileSize(attachment.size)

    return attachment
  }
}
