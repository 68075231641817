<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h5 class="modal-title">Edit Booking Preferences</h5>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="form"></form>
    </div>
  </div>
</div>
