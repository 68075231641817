import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Website } from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsiteLinks } from '@tv3/store/website/website.actions'
import { RxwebValidators } from '@rxweb/reactive-form-validators'

@Component({
  selector: 'app-website-links',
  templateUrl: './website-links.component.html',
  styleUrls: ['./website-links.component.scss'],
})
export class WebsiteLinksComponent implements OnInit {
  @Input() website: Website
  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  form = this.fb.group({
    facebook: [undefined, [RxwebValidators.url()]],
    twitter: [undefined, [RxwebValidators.url()]],
    instagram: [undefined, [RxwebValidators.url()]],
    googleplus: [undefined, [RxwebValidators.url()]],
    blog: [undefined, [RxwebValidators.url()]],
  })

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    const social = this.website.social || {}
    this.form.patchValue({
      ...social,
    })
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const formData = fb.getRawValue()
    const payload = {
      ...this.website.serialize(),
      social: {
        ...this.website.social,
        ...formData,
      },
    }
    this.store.dispatch(UpdateWebsiteLinks({ id: this.website.id, data: payload }))
  }
}
