import { HttpErrorResponse } from '@angular/common/http'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import {
  AlertDialogService,
  ConfirmDialogParams,
  ConfirmDialogService,
  deleteRentalComplete,
  Destroyable,
  isSomething,
  Rental,
  selectRentalById,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import { environment } from 'apps/tv3/src/environments/environment'
import { EMPTY, Observable } from 'rxjs'
import { catchError, filter, mapTo, switchMap } from 'rxjs/operators'
import { DeleteRentalService } from '../delete/delete-rental.service'

@Component({
  selector: 'app-delete-rental-overlay',
  templateUrl: './delete-rental-overlay.component.html',
  styleUrls: ['./delete-rental-overlay.component.scss'],
})
export class DeleteRentalOverlayComponent extends Destroyable implements OnInit {
  isHoldDeleted = false
  isBookingsDeleted = false
  isWebsitesDeleted = false
  isWebreadyRentalDeleted = false

  tokeetRentalData = {
    loading: true,
    holdEvents: 0,
    bookings: 0,
    websites: 0,
  }
  webreadyRentalData = {
    loading: true,
    rental: false,
    websites: 0,
    images: 0,
  }

  isLoading = false

  get isAllDeleted() {
    if (this.tokeetRentalData.loading || this.webreadyRentalData.loading) {
      return false
    }

    if (this.tokeetRentalData.holdEvents > 0 && !this.isHoldDeleted) {
      return false
    }
    if (this.tokeetRentalData.bookings > 0 && !this.isBookingsDeleted) {
      return false
    }
    if (this.tokeetRentalData.websites > 0 && !this.isWebsitesDeleted) {
      return false
    }
    if (this.webreadyRentalData.rental && !this.isWebreadyRentalDeleted) {
      return false
    }

    return true
  }

  rental: Rental
  constructor(
    public dialogRef: MatDialogRef<DeleteRentalOverlayComponent>,
    private toaster: Toaster,
    private alert: AlertDialogService,
    private confirmDialog: ConfirmDialogService,
    private authService: AuthService,
    private deleteRentalService: DeleteRentalService,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: { rentalId: string }
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(select(selectRentalById(this.data.rentalId)), filter(isSomething), untilDestroy(this))
      .subscribe((rental) => {
        this.rental = rental
      })

    this.deleteRentalService
      .getWebreadyRental(this.data.rentalId)
      .pipe(switchMap(() => this.deleteRentalService.getWebreadyRentalAssociated(this.data.rentalId)))
      .subscribe({
        next: (data) => {
          this.webreadyRentalData = {
            loading: false,
            rental: true,
            websites: data.websites?.length || 0,
            images: data.images?.length || 0,
          }
        },
        error: (res) => {
          if (res?.status === 404) {
            this.webreadyRentalData.loading = false
          }
        },
      })

    this.deleteRentalService.getTokeetRentalCounts(this.data.rentalId).subscribe((counts) => {
      this.tokeetRentalData = {
        loading: false,
        holdEvents: counts.events,
        bookings: counts.inquiries + counts.bookings,
        websites: counts.websites,
      }
    })
  }

  close() {
    this.dialogRef.close()
  }

  onDeleteHold() {
    this.confirmDialog
      .confirm({
        title: 'Delete rental hold events?',
        body: 'This will delete all hold events stored on the selected rental. This action cannot be undone and these items cannot be recovered once this action has been initiated. Are you sure that you want to proceed?',
      })
      .subscribe(() => {
        this.deleteRentalService.deleteHold(this.data.rentalId).subscribe(() => {
          this.tokeetRentalData.holdEvents = 0
          this.isHoldDeleted = true
        })
      })
  }

  onDeleteBookings() {
    this.confirmDialog
      .confirm({
        title: 'Delete rental bookings?',
        body: 'This will delete all inquiries and confirmed bookings stored on the selected rental. All associated items other than the guest record will also be removed as a result. These items include Messages, Invoices, Inquiry Notes, and Booking Charges. This action cannot be undone and all deleted items will not be recoverable. Are you sure that you want to proceed?',
      })
      .subscribe(() => {
        this.deleteRentalService.deleteBookings(this.data.rentalId).subscribe(() => {
          this.tokeetRentalData.bookings = 0
          this.isBookingsDeleted = true
        })
      })
  }

  onDeleteWebsites() {
    this.confirmDialog
      .confirm({
        title: 'Remove rental from websites?',
        body: 'This will remove the selected rental from any AdvanceCM websites associated with it currently. Are you sure that you want to proceed?',
      })
      .subscribe(() => {
        this.deleteRentalService.deleteWebsites(this.data.rentalId).subscribe(() => {
          this.tokeetRentalData.websites = 0
          this.isWebsitesDeleted = true
        })
      })
  }

  onDeleteWebreadyData() {
    const deleteWebsitesWarning = () => {
      this.alert
        .openResult({
          title: 'Warning',
          body: 'You need to manually remove rental from all Webready websites before you can delete it.',
          extraButton: { text: 'Login Webready', icon: 'far fa-globe', style: 'btn btn-secondary-info' },
        })
        .subscribe((res) => {
          if (res === 'extra') {
            this.loginWebready()
          }
        })
    }

    if (this.webreadyRentalData.websites > 0) {
      deleteWebsitesWarning()
      return
    }

    const confirmParams: ConfirmDialogParams = {
      title: 'Remove rental from Webready?',
      body: 'This will remove the selected rental from Webready. Are you sure that you want to proceed?',
    }
    let confirm: Observable<any>

    if (!this.webreadyRentalData.images) {
      confirm = this.confirmDialog.confirm(confirmParams)
    } else {
      confirm = this.confirmDialog.confirmExtra({
        ...confirmParams,
        extra: 'Delete Images',
      })
    }

    confirm
      .pipe(
        switchMap((res) => {
          return this.deleteRentalService
            .deleteWebreadyRental(this.data.rentalId, res?.isChecked || false)
            .pipe(mapTo(res))
        })
      )
      .subscribe(
        (res) => {
          if (res.isChecked) {
            this.webreadyRentalData.images = 0
          }
          this.isWebreadyRentalDeleted = true
        },
        (error: HttpErrorResponse) => {
          if (error.status === 409) {
            deleteWebsitesWarning()
          }
          this.toaster.error('Unable to delete rental from Webready', '', error)
        }
      )
  }

  onDelete() {
    this.confirmDialog
      .confirm({
        title: 'Delete this Rental?',
        body: 'This will remove the rental from your rental list and all AdvanceCM calendars. This action cannot be undone. Are you sure that you want to proceed?',
      })
      .subscribe(() => {
        this.deleteRentalService
          .delete(this.data.rentalId)
          .pipe(
            catchError((error) => {
              this.toaster.error(null, 'Error', error)
              return EMPTY
            })
          )
          .subscribe(() => {
            this.store.dispatch(deleteRentalComplete({ id: this.data.rentalId }))
            this.close()
          })
      })
  }

  loginWebready() {
    const token = this.authService.token
    window.open(`${environment.webreadyUrl}login?token=${token}`, '_blank')
  }
}
