import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { TiketListingWizardComponent } from './tiket-listing-wizard.component'
import { TiketListingWizardStep1Component } from './step1/tiket-listing-wizard-step1.component'
import { TiketListingWizardStep2Component } from './step2/tiket-listing-wizard-step2.component'
import { TiketListingWizardStep3Component } from './step3/tiket-listing-wizard-step3.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'

const components = [
  //
  TiketListingWizardStep1Component,
  TiketListingWizardStep2Component,
  TiketListingWizardStep3Component,
  TiketListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class TiketListingWizardModule {}
