import { FormBuilder, Validators } from '@angular/forms'
import * as moment from 'moment'
import * as lodash from 'lodash'
import { Component, Inject, OnInit } from '@angular/core'
import { downloadCSV } from '@tv3/utils/functions/download'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

declare const require: any
declare const window: any

const Papa = require('papaparse')

export type GetColumnValueFunc<T> = (elm: T) => any

export interface ExportableColumnConfig<T = any> {
  name: string
  field: string | GetColumnValueFunc<T>
  selected?: boolean // default true
  disabled?: boolean // default false
}

export interface GenericExportDialogConfig {
  title: string
  description: string
  filename: string
  columnCustomizable: boolean
  columns: ExportableColumnConfig[]
  extraCheck?: { title: string; checked: boolean; tooltip?: string }
  exportData: any[] | ((config: GenericExportDialogConfig) => any[])
}

@Component({
  selector: 'app-generic-export-dialog',
  templateUrl: './generic-export-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./generic-export-dialog.component.scss'],
})
export class GenericExportDialogComponent implements OnInit {
  config = {
    columns: [],
    columnCustomizable: false,
    exportData: [],
    title: 'Export to CSV',
    description: '',
    filename: `export-${moment().format('MM_DD_YYYY')}`,
  } as GenericExportDialogConfig

  set filename(name: string) {
    this.form.setValue({ name })
  }

  get filename(): string {
    return this.form.get('name').value
  }

  form = this.fb.group({
    name: ['', [Validators.required]],
  })

  isExporting = false

  constructor(
    public dialogRef: MatDialogRef<GenericExportDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { config: GenericExportDialogConfig }
  ) {}

  ngOnInit() {
    this.config = {
      ...this.config,
      ...this.data.config,
      columns: lodash.map(this.data.config.columns, (col) => {
        if (!lodash.has(col, 'selected')) {
          col.selected = true
        }
        return col
      }),
    }
    this.filename = this.config.filename
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  async download(form) {
    this.isExporting = true
    await this.doExport()
    this.isExporting = false
    this.dialogRef.close()
  }

  getExportedColumns() {
    return !this.config.columnCustomizable
      ? this.config.columns
      : lodash.filter(this.config.columns, (c) => c.selected === true)
  }

  getRowData(item: any, columns: ExportableColumnConfig[]) {
    return lodash.map(columns, (column: ExportableColumnConfig) => {
      return lodash.isFunction(column.field) ? column.field.call(item, item) : lodash.get(item, column.field)
    })
  }

  getExportableData(): { fields: string[]; data: any[][] } {
    const exportedColumns = this.getExportedColumns()
    const exportedData = lodash.isFunction(this.config.exportData)
      ? this.config.exportData(this.config)
      : this.config.exportData
    return {
      fields: lodash.map(exportedColumns, 'name'),
      data: lodash.map(exportedData, (item) => {
        return this.getRowData(item, exportedColumns)
      }),
    }
  }

  private async doExport() {
    const csvString = Papa.unparse(this.getExportableData())
    const fileName = `${this.filename}.csv`

    downloadCSV(csvString, fileName)
  }
}
