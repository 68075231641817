import { Observable, combineLatest, of } from 'rxjs'
import { CanActivate, CanLoad, Route } from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import * as fromRoot from '@tv3/store/state'
import { IsDataLoaded, LoadChannels, selectChannelsLoaded } from '@tokeet-frontend/tv3-platform'
import { GetCustomChannels, selectCustomChannelsLoaded } from '@tokeet-frontend/channels'

@Injectable({ providedIn: 'root' })
export class ChannelGuard implements CanActivate, CanLoad, IsDataLoaded {
  constructor(private store: Store<fromRoot.State>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return combineLatest([
      this.store.pipe(
        select(selectChannelsLoaded),
        tap((isLoaded) => {
          if (!isLoaded) {
            this.store.dispatch(LoadChannels())
          }
        })
      ),
      this.store.pipe(
        select(selectCustomChannelsLoaded),
        tap((isLoaded) => {
          if (!isLoaded) {
            this.store.dispatch(GetCustomChannels())
          }
        })
      ),
    ]).pipe(
      map(() => true),
      take(1)
    )
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
