import { Expose, Serializable, Exclude, plainToClass, Type } from '@tokeet-frontend/tv3-platform'
import { WebsitePageSettings, WebsiteTemplate } from '@tv3/store/website/website.types'
import * as R from 'ramda'
import { isString } from 'lodash'
import { epochToView } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { environment } from '@tv3/environments/environment'

export enum WebsiteTypes {
  BookingEngine = 'Booking Engine',
  GuestPortal = 'Guest Portal',
  RentalWebsite = 'Website',
}

export const domainsByWebsiteType = {
  [WebsiteTypes.RentalWebsite]: environment.config.websiteDomains,
  [WebsiteTypes.BookingEngine]: environment.config.bookingEngineDomains,
  [WebsiteTypes.GuestPortal]: environment.config.guestPortalDomains,
}

export function isTokeetWebsiteDomain(domain: string, type: WebsiteTypes) {
  const domains = domainsByWebsiteType[type]
  return !!domains.find((d) => lodash.endsWith(domain, d))
}

export enum WebsiteTemplateNames {
  SandratiTemplate = 'sandrati',
  AlphaTemplate = 'alpha',
  ModernTemplate = 'wprentals',
  FineTemplate = 'onefinestay',
  LuxuryTemplate = 'luxuryretreats',
  DefaultTemplate = 'default',
}

export const disabledSettingsForTemplate = {
  [WebsiteTemplateNames.DefaultTemplate]: {
    primaryColor: true,
  },
  [WebsiteTemplateNames.FineTemplate]: {
    tagline2: true,
    tagline3: true,
  },
  [WebsiteTemplateNames.LuxuryTemplate]: {
    tagline3: true,
  },
  [WebsiteTemplateNames.AlphaTemplate]: {
    footer_desc: true,
  },
  [WebsiteTemplateNames.SandratiTemplate]: {
    logoSubTxt: true,
    tagline1: true,
    tagline2: true,
    tagline3: true,
    footer_desc: true,
    location: true,
  },
  [WebsiteTemplateNames.ModernTemplate]: {
    logoColor: true,
    logoFontSize: true,
  },
}

export const tooltipsForTemplate = {
  [WebsiteTemplateNames.FineTemplate]: 'Single or Multi-rental website.',
  [WebsiteTemplateNames.LuxuryTemplate]: 'Single or Multi-rental website.',
  [WebsiteTemplateNames.AlphaTemplate]: 'Single or Multi-rental website design.',
  [WebsiteTemplateNames.SandratiTemplate]: 'Single Rental website design.',
  [WebsiteTemplateNames.ModernTemplate]: 'Single or Multi-rental website.',
}

export class Website extends Serializable<Website> {
  @Expose({ name: 'pkey' })
  id: string

  company: {
    address: object
    email: string
    name: string
    phone: string
  }

  domain: string

  @Expose({ name: 'engine_domain' })
  engineDomain: string
  @Expose({ name: 'engine_enabled' })
  engineEnabled: number

  @Type(() => Number)
  @Expose({ name: 'lastpublished' })
  lastPublished: number

  @Expose({ name: 'is_portal' })
  isPortal: boolean

  @Expose({ name: 'is_engine' })
  isEngine: boolean

  pages: WebsitePageSettings[]

  rentals: string[]
  rental: string
  rateCategory: string
  resources: object[]
  social: {
    blog: string
    facebook: string
    googleplus: string
    instagram: string
    twitter: string
  }
  @Expose({ name: 'template_settings' })
  templateSettings: any
  components: any

  template: string
  @Expose({ name: 'template_type' })
  templateType: string

  account: number
  title: string
  description: string

  @Type(() => Number)
  lastupdate: number
  archived: number
  @Type(() => Number)
  created: number

  // view fields
  @Exclude({ toPlainOnly: true })
  type: WebsiteTypes
  @Exclude({ toPlainOnly: true })
  lastUpdateView: string
  @Exclude({ toPlainOnly: true })
  isPublishable: boolean
  @Exclude({ toPlainOnly: true })
  rentalsView = ''
  @Exclude({ toPlainOnly: true })
  templateView = ''
  @Exclude({ toPlainOnly: true })
  rentalCountView = ''

  static deserialize(data: Website): Website {
    const website: Website = plainToClass<Website, Website>(Website, data)
    website.pages = R.map((page) => WebsitePageSettings.deserialize(page), website.pages || [])
    website.type = website.isPortal
      ? WebsiteTypes.GuestPortal
      : website.isEngine
      ? WebsiteTypes.BookingEngine
      : WebsiteTypes.RentalWebsite
    website.lastUpdateView = epochToView(website.lastupdate)

    if (!website.rentals && website.rental) {
      website.rentals = [website.rental]
    }
    if (isString(website.rentals)) {
      // @ts-ignore
      website.rentals = [website.rentals]
    }
    website.rentalCountView = `${website.rentals.length}`

    website.isPublishable = !!(website.title && website.domain && website.rentals && website.rentals.length)

    if (!website.isPortal && website.isPublishable) {
      website.isPublishable = !!(website.template && website.rateCategory)
    }

    return website
  }
}

export function filterWebsiteTemplate(templates: WebsiteTemplate[], type: WebsiteTypes, rentalsCount: number) {
  if (type === WebsiteTypes.BookingEngine) {
    return R.filter((template) => {
      return template.engine === 1
    }, templates)
  }

  if (rentalsCount > 1) {
    return R.filter((template) => {
      return template.properties === 'multiple'
    }, templates)
  }
  return templates
}
