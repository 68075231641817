import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BookingFormulaResponse, CreateBookingFormulaPayload, UpdateBookingFormulaPayload } from './model'
import { catchError, concatMap, toArray } from 'rxjs/operators'
import { Observable, of } from 'rxjs'

@Injectable()
export class BookingFormulaService {
  constructor(private http: HttpClient) {}

  getChannelRentalFormula(channelId: string, rentalId: string): Observable<BookingFormulaResponse> {
    const url = '@api/bookingformula/match'

    return this.http
      .post<BookingFormulaResponse>(url, { channel_id: channelId, rental_id: rentalId })
      .pipe(catchError(() => of(null)))
  }

  getFormulas() {
    const url = `@api/bookingformula`
    return this.http.get<BookingFormulaResponse[]>(url)
  }

  getFormula(id: string) {
    const url = `@api/bookingformula/${id}`

    return this.http.get<BookingFormulaResponse>(url).pipe(catchError(() => of(null)))
  }

  getFormulasByChannel(channelId: string) {
    const url = `@api/bookingformula/all/channel`

    return this.http.post<BookingFormulaResponse[]>(url, { channel_id: channelId }).pipe(catchError(() => of([])))
  }

  createFormula(payload: CreateBookingFormulaPayload) {
    const url = `@api/bookingformula`

    return this.http.post<BookingFormulaResponse>(url, payload)
  }

  updateFormula(id: string, payload: UpdateBookingFormulaPayload) {
    const url = `@api/bookingformula/${id}`

    return this.http.put<BookingFormulaResponse>(url, { pkey: id, ...payload })
  }

  activateFormula(id: string) {
    const url = `@api/bookingformula/${id}/unpause`

    return this.http.put<BookingFormulaResponse>(url, {})
  }

  activateMultipleFormulas(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.activateFormula(id)),
      toArray()
    )
  }

  deactivateFormula(id: string) {
    const url = `@api/bookingformula/${id}/pause`

    return this.http.put<BookingFormulaResponse>(url, {})
  }

  deactivateMultipleFormulas(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.deactivateFormula(id)),
      toArray()
    )
  }

  deleteFormula(id: string) {
    const url = `@api/bookingformula/${id}`

    return this.http.delete(url)
  }

  deleteMultipleFormulas(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.deleteFormula(id)),
      toArray()
    )
  }
}
