<div class="dropdown" dropdown [isDisabled]="1 | isReadonlyRole">
  <button type="button" class="dropdown-toggle" [ngClass]="btnClass" dropdownToggle>
    <span [ngClass]="icon"></span> {{ label }}
  </button>
  <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
    <li>
      <button
        type="button"
        (click)="onAddStandardRate()"
        matTooltip="Standard rates are flat, constant rates which do not change over time."
        matTooltipPosition="left"
      >
        <i class="far fa-calculator"></i> Add Standard Rate
      </button>
    </li>
    <li>
      <button
        type="button"
        (click)="onAddDynamicRate()"
        matTooltip="Dynamic rates will gradually and automatically adjust from a low rate to a high rate as your overall rental occupancy level changes. They can also instantly update your channels as the rate changes."
        matTooltipPosition="left"
      >
        <i class="far fa-calculator"></i> Add Dynamic Rate
      </button>
    </li>
    <li class="dropdown-divider"></li>
    <li>
      <button
        type="button"
        (click)="openChannelAdjustments()"
        matTooltip="Automatically adjust the default rate category to push an adjusted rate to the API channels and rentals that you specify. These settings only apply to the default rate category."
        matTooltipPosition="left"
      >
        <i class="far fa-box-up"></i> Channel Rate Automations
      </button>
    </li>
    <li *ngIf="rentals$ | async as rentals">
      <button
        type="button"
        (click)="openGroupRates()"
        [matTooltip]="
          rentals.length < 2
            ? 'You need more than one rental to apply group rates.'
            : 'Add group rates which apply to multiple rentals at once.'
        "
        matTooltipPosition="left"
        [disabled]="rentals.length < 2"
      >
        <i class="fas fa-hands-usd"></i> Group Rates
      </button>
    </li>

    <ng-container *ngIf="showDiscounts">
      <li class="dropdown-divider"></li>
      <li>
        <button type="button" (click)="openLosDiscounts()" matTooltip="" matTooltipPosition="left">
          <i class="cs-icon cs-icon-los-discount fa-w"></i>
          LOS Discounts
        </button>
      </li>
      <li>
        <button type="button" (click)="openDiscountCodes()" matTooltip="" matTooltipPosition="left">
          <i class="cs-icon cs-icon-discount-code fa-fw text-xs"></i>
          Discount Codes
        </button>
      </li>
    </ng-container>
  </ul>
</div>
