import * as momentNs from 'moment'
import { DateFormatType, formatDate } from './utc'
const moment = momentNs

export function epochToView(field: number, format: DateFormatType = 'date') {
  return formatDate(+field, format, false)
}

export function epochToViewUTC(field: number, format: DateFormatType = 'date') {
  return formatDate(+field, format)
}
