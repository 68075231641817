import { Component, OnInit } from '@angular/core'
import { ActionFailed, ExpenseApprovalStatus, ExpensePaidStatus, ExpenseService } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'
import { finalize, map } from 'rxjs/operators'
import * as lodash from 'lodash'

@Component({
  selector: 'app-expense-top-stats',
  templateUrl: './expense-top-stats.component.html',
  styleUrls: ['./expense-top-stats.component.scss'],
})
export class ExpenseTopStatsComponent implements OnInit {
  isLoading = true

  statuses = { paid: 0, unpaid: 0 }
  approval = { pending: 0, approved: 0 }

  constructor(private store: Store<any>, private expenseService: ExpenseService) {}

  ngOnInit() {
    combineLatest([
      this.expenseService
        .getStatusReports({ statuses: [ExpensePaidStatus.Paid, ExpensePaidStatus.Unpaid] })
        .pipe(map((s) => lodash.keyBy(s, (t) => t.status))),
      this.expenseService
        .getApprovalReports({ approval_statuses: [ExpenseApprovalStatus.Approved, ExpenseApprovalStatus.Pending] })
        .pipe(map((s) => lodash.keyBy(s, (t) => t.status))),
    ])
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        ([statusById, approvalById]) => {
          this.statuses = {
            paid: statusById[ExpensePaidStatus.Paid]?.amount || 0,
            unpaid: statusById[ExpensePaidStatus.Unpaid]?.amount || 0,
          }

          this.approval = {
            approved: approvalById[ExpenseApprovalStatus.Approved]?.amount || 0,
            pending: approvalById[ExpenseApprovalStatus.Pending]?.amount || 0,
          }
        },
        (error) => this.store.dispatch(ActionFailed({ error }))
      )
  }
}
