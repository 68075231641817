import {
  AddFlightComplete,
  CreateFlightComplete,
  DeleteFlightComplete,
  EditFlightComplete,
  LoadItinerariesComplete,
} from './itinerary.actions'
import { adapter, initialState, State } from './itinerary.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadItinerariesComplete, (state, { itineraries }) =>
    adapter.addAll(itineraries, R.merge(state, { isLoaded: true }))
  ),
  on(AddFlightComplete, EditFlightComplete, DeleteFlightComplete, (state, { update }) =>
    adapter.updateOne(update, state)
  ),
  on(CreateFlightComplete, (state, { itinerary }) => adapter.addOne(itinerary, state))
)

export function itineraryReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
