import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core'
import { NgbDate, NgbDatepicker, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'

import * as lodash from 'lodash'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input('selection')
  set setSelection(selection: Date) {
    this.prepareDates(selection)
  }

  @Input() local = false
  @Input() maxDate: Date
  @Input() minDate: Date
  @Input() startDate: Date

  @Input() showTime = false
  @Input() meridian = false
  @Input() seconds = false
  @Input() timeSize: 'small' | 'medium' | 'large' = 'medium'
  @Input() minuteStep = 1
  @Input() hourStep = 1
  @Input() secondStep = 1

  @Output() selectionChange = new EventEmitter<Date | null>()

  @ViewChild('datepicker', { static: true }) ngbDatePicker: NgbDatepicker

  selectedDate: NgbDate
  selectedTime: NgbTimeStruct

  ngOnInit() {
    this.navigate(this.selectedDate)
  }

  dateToDateStruct(date?: Date): NgbDateStruct {
    if (!date) {
      return undefined
    }
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    }
  }

  navigate(date: NgbDate) {
    this.ngbDatePicker.navigateTo(date)
  }

  onDateChange() {
    if (this.selectedTime) {
      this.selectionChange.emit(this.toDate(this.selectedDate, this.selectedTime))
    }
  }

  onTimeChange() {
    if (this.selectedDate) {
      this.selectionChange.emit(this.toDate(this.selectedDate, this.selectedTime))
    }
  }

  onReset() {
    this.selectedDate = null
    this.selectedTime = null
    this.selectionChange.emit(null)
  }

  private prepareDates(selection: Date) {
    if (!selection || !lodash.isDate(selection)) {
      const now = new Date()
      this.selectedDate = null
      this.selectedTime = { hour: now.getHours(), minute: now.getMinutes(), second: now.getSeconds() }
    } else {
      const data = this.fromDate(selection)
      this.selectedDate = data.date
      this.selectedTime = data.time
    }
  }

  private fromDate(date: Date): { date: NgbDate; time: NgbTimeStruct } {
    return {
      date: NgbDate.from({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      }),

      time: { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() },
    }
  }

  private toDate(date: NgbDate, time: NgbTimeStruct) {
    return new Date(date.year, date.month - 1, date.day, time?.hour, time?.minute, time?.second, 0)
  }
}
