import { RawEditorSettings, Editor } from 'tinymce'

import 'tinymce'
import 'tinymce/icons/default'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/table'

export type TinyMceEditor = Editor
export type TinyMceEditorOptions = RawEditorSettings

export const defaultTinyMCEOptions: RawEditorSettings = {
  plugins: ['autoresize', 'table', 'link', 'image', 'imagetools'],
  min_height: 150,
  max_height: 300,
  menubar: false,
  browser_spellcheck: true,
  toolbar:
    'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | indent outdent | link | removeformat',
  table_toolbar:
    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage',
  formats: {
    removeformat: [
      {
        selector: 'b,strong,em,i,font,u,strike',
        remove: 'all',
        split: true,
        expand: false,
        block_expand: true,
        deep: true,
      },
      { selector: 'h1,h2,h3,h4,h5,h6', remove: 'all', split: true, expand: false, block_expand: true, deep: true },
      { selector: 'span', attributes: ['style', 'class'], remove: 'empty', split: true, expand: false, deep: true },
      { selector: '*', attributes: ['style', 'class'], split: false, expand: false, deep: true },
    ],
  },
  statusbar: false,
  content_css: [
    '/assets/tinymce/skins/content/default/content.min.css',
    '/assets/tinymce/skins/ui/oxide/content.min.css',
  ],
  skin_url: '/assets/tinymce/skins/ui/oxide',
  theme_url: '/assets/tinymce/themes/silver/theme.js',
}
