<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-user-plus"></i> Add Guest
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span><i class="far fa-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <i class="cs-icon cs-icon-user" matPrefix></i>
          <input type="text" matInput required placeholder="Name" formControlName="name" />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <i class="cs-icon cs-icon-envelope" matPrefix></i>
          <input type="email" matInput required placeholder="Email" formControlName="email" />
          <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('email')">
            Email must have valid format
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <i class="cs-icon cs-icon-phone" matPrefix></i>
          <input type="text" formControlName="phone" matInput placeholder="Phone" />
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="city" matInput placeholder="City" />
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <app-select-search
          [inline]="false"
          [items]="countryList"
          bindLabel="name"
          bindValue="name"
          [ctrl]="form.get('country')"
          placeholder="Country"
          [multiple]="false"
          class="mat-block"
        >
        </app-select-search>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="create(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="far fa-plus"></i> create
  </button>
</div>
