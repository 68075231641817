import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { MessageListComponent } from '@tv3/containers/messages/message-list/message-list.component'
import { MessageListItemComponent } from '@tv3/containers/messages/message-list/message-list-item/message-list-item.component'
import { InquiryMessageFormComponent } from '@tv3/containers/messages/inquiry-message-form/inquiry-message-form.component'
import { EditorModule } from '@tinymce/tinymce-angular'
import { MessageListItemBaseComponent } from '@tv3/containers/messages/message-list/message-list-item-base/message-list-item-base.component'
import { InquiryLibModule } from '@tokeet-frontend/inquiry'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { OverlayModule } from '@angular/cdk/overlay'
import { MessageLibraryModule } from '@tokeet-frontend/message'

const components = [
  MessageListComponent,
  InquiryMessageFormComponent,
  MessageListItemBaseComponent,
  MessageListItemComponent,
]

const dialogs = []

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EditorModule,
    InquiryLibModule,
    DragDropModule,
    OverlayModule,
    MessageLibraryModule,
  ],
  declarations: [...components, ...dialogs],
  exports: [...components, ...dialogs],
})
export class MessagesSharedModule {}
