export interface AgodaCancelPolicy {
  code: number
  start: string
  end: string
}

export const agodaRatePlanTypes = [
  { code: 1, name: 'Net Exclusive' },
  { code: 2, name: 'Net Inclusive' },
  { code: 3, name: 'Sell Exclusive' },
  { code: 4, name: 'Sell Inclusive' },
]

export interface AgodaContact {
  type: string
  givenName: string
  surname: string
  email: string
  phone: string
}

export interface CodeNameType {
  code: string
  name: string
}

export interface AgodaContentProductCancelPolicy {
  code: number
  agodaCode: string
  name: string
  description: string
  tooltip: string
}

export type AgodaRoomAmenity = CodeNameType

export interface AgodaBedroomAmenity {
  code: string
  value: string
}

export interface AgodaContentSubroom {
  type: string
  amenities: AgodaBedroomAmenity[]
}

export interface AgodaContentRoom {
  roomId: number // 1196981747,
  rentalId: string // "1f2a8a66-9d36-4cc4-86ba-5057bca745e7",
  extraBedChildAge: number // null,
  maxExtraBeds: number // 0,
  maxOccupancy: number // 2,
  numOfBedrooms: number // 1,
  name: string // "Double Room",
  ycsExtraBedFlag: string // "false",
  description: string // "",
  amenities: AgodaRoomAmenity[]
  bedrooms: AgodaContentSubroom[]
}

export interface AgodaContentRatePlan {
  name: string
  ratePlanType: number
  tokeetRate: string
  ratePlanId: number
  active: boolean
}

export interface AgodaContentProductRoomSetting {
  min_rate: number //  "1.0",
  num_rooms: number //  "1",
  num_children: number //  "0",
  max_rate: number //  "999999999.0",
  room_id: number //  "1199851643",
  num_persons: number //  "2",
  total_persons: number //  "2",
  num_extrabed: number //  "0",
  num_baby_cots: number //  "0",
  room_name: string //  "Double Room"
}

export interface AgodaContentProductRateSetting {
  offertype_id: number // '22',
  stay_start: string // '2020-05-27',
  cxl_code: string // '1D1N_1N',
  max_los: number // '2',
  min_los: number // '1',
  sell_start: string // '2020-05-27T00:00:00',
  tax_included: number // '1',
  sell_end: string // '2020-05-30T00:00:00',
  rateplan_id: number // '3262372',
  stay_end: string // '2020-05-31',
  max_adv_days: number // '2',
  rate_type: string // 'NET',
  min_adv_days: number // '1',
  master_rate: number // '0',
  offertype_name: string // 'Room Only',
  rateplan_name: string // 'Standard rate'
}

export interface AgodaContentProductSetting {
  room_id: number
  rateplan_id: number
}

export interface AgodaContentPropertyProducts {
  id: string // '9191301',
  name: string // 'Ryokan Bali',
  currency: string // 'AED',
  language: string // 'EN',
  live_status: string // '1',
  occupancy_model: string // 'Full Rate',
  rooms: AgodaContentProductRoomSetting[]
  rateplans: AgodaContentProductRateSetting[]
  products: AgodaContentProductSetting[]
  channels: any[]
}

export interface AgodaContentProperty {
  propertyName: string
  propertyId: number
  id: string // rental id
  currency: string
  chainCode: string
  brandCode: string
  language: string

  hotelCategory: string
  lat: number
  long: number

  services: any[]
  awardRating: string

  cancelPolicies: AgodaCancelPolicy[]
  feePolicies: any[]
  checkInTime: string
  checkOutTime: string
  contacts: AgodaContact[]
  rooms: AgodaContentRoom[]
  rates: AgodaContentRatePlan[]
  address: any
  extra: {
    license: string
    localRecommendations: string
    houseRules: string
    directions: string
  }
}
