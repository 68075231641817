import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CustomChannelDialogComponent } from '@tv3/containers/channels/shared/custom-channel-dialog/custom-channel-dialog.component'
import { CustomChannelResponse } from '@tokeet-frontend/channels'

@Injectable({
  providedIn: SharedModule,
})
export class CustomChannelDialogService {
  constructor(private dialog: MatDialog) {}

  open(channel?: CustomChannelResponse) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { channel },
    }

    return this.dialog.open(CustomChannelDialogComponent, defaultConfig)
  }
}
