<button
  class="btn btn-prev btn-secondary-light border"
  [ngClass]="{ 'btn-disabled': disablePrevious }"
  (click)="previous.emit()"
  [disabled]="disablePrevious"
>
  <i class="far fa-chevron-left"></i>
</button>

<div class="select px-2 border-top border-bottom">
  <ng-content></ng-content>
</div>

<button
  class="btn btn-next btn-secondary-light border"
  [ngClass]="{ 'btn-disabled': disableNext }"
  (click)="next.emit()"
  [disabled]="disableNext"
>
  <i class="far fa-chevron-right"></i>
</button>
