import { Directive, Input, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { NgxPermissionsService } from 'ngx-permissions'

@Directive({
  selector: '[formEditablePermission]',
})
export class FormEditablePermissionDirective implements OnInit {
  @Input() formEditablePermission: AbstractControl

  constructor(private permissions: NgxPermissionsService) {}

  ngOnInit(): void {
    this.permissions.hasPermission('readOnly').then((readOnly) => {
      if (readOnly && this.formEditablePermission.enabled) {
        this.formEditablePermission.disable()
        return
      }
    })
  }
}
