import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  ActionFailed,
  Channel,
  ChannelService,
  ConnectedChannelAccountResponse,
  Destroyable,
  untilDestroy,
  ChannelNameTokens,
  DisconnectChannel,
  ConfirmDialogService,
  userFullname,
} from '@tokeet-frontend/tv3-platform'
import { AirbnbHelperService } from '../../../airbnb-helper.service'
import { Actions, ofType } from '@ngrx/effects'
import {
  LoadConnectionsByChannel,
  RefreshConnectionsForChannelComplete,
} from '@tv3/store/connection/connection.actions'
import { delay, finalize, map, switchMap, tap } from 'rxjs/operators'
import * as lodash from 'lodash'

type AirbnbListing = ConnectedChannelAccountResponse['rooms'][number]

@Component({
  selector: 'app-connect-airbnb-wizard-complete2',
  templateUrl: './connect-airbnb-wizard-complete2.component.html',
  styleUrls: ['./connect-airbnb-wizard-complete2.component.scss'],
})
export class ConnectAirbnbWizardComplete2Component extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  displayedColumns: string[] = ['name', 'select']
  dataSource = new MatTableDataSource<AirbnbListing>()
  selection = new SelectionModel<AirbnbListing>(true, [])

  @Input() channel: Channel
  @Output() done = new EventEmitter()

  isLoading = true

  isImporting = false
  importedListings: string[] = []

  get userName() {
    const user = this.airbnbAccount?.user_info
    return userFullname({ firstName: user?.first_name, lastName: user?.last_name })
  }
  airbnbAccount: ConnectedChannelAccountResponse

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private channelService: ChannelService,
    private confirmDialog: ConfirmDialogService,
    private airbnbHelperService: AirbnbHelperService
  ) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.actions
      .pipe(
        ofType(RefreshConnectionsForChannelComplete),
        delay(5000),
        tap(() =>
          this.store.dispatch(LoadConnectionsByChannel({ channelName: this.channel.name, channelId: this.channel.id }))
        ),
        switchMap(() => this.channelService.getConnectedAccounts(this.channel.id)),
        map((accounts) => lodash.last(lodash.sortBy(accounts, (a) => a.createdOn))),
        untilDestroy(this)
      )
      .subscribe(
        (airbnbAccount) => {
          this.airbnbAccount = airbnbAccount
          this.dataSource.data = airbnbAccount.rooms || []
          this.isLoading = false
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
          this.isLoading = false
        }
      )
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: AirbnbListing, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }

  onImport() {
    this.isImporting = true
    const items = this.selection.selected
    this.airbnbHelperService
      .importRooms(
        items.map(
          (t) =>
            ({
              channelId: this.channel.id,
              roomId: t.id,
              propertyId: this.airbnbAccount.propertyId,
            } as Connection)
        )
      )
      .pipe(finalize(() => (this.isImporting = false)))
      .subscribe(() => {
        this.importedListings.push(...items.map((t) => t.id))
        this.store.dispatch(
          LoadConnectionsByChannel({
            channelName: ChannelNameTokens.AirBnBV2API,
            channelId: this.channel.id,
          })
        )
      })
  }

  isImported(item: AirbnbListing) {
    return this.importedListings.includes(item.id)
  }

  onDisconnect() {
    const channelId = this.airbnbAccount.channelId || this.channel.id
    const channelName = this.airbnbAccount.name || this.channel.name

    return this.confirmDialog
      .confirm({
        title: 'Disconnect this Account?',
        body: `This will disconnect this account from your AdvanceCM account. <br/>
          Are you sure you want to Disconnect this account?`,
      })
      .subscribe(() => {
        this.store.dispatch(DisconnectChannel({ channelName, channelId }))
      })
  }
}
