import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { HttpClient } from '@angular/common/http'
import { User } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
  form = this.fb.group({
    password1: [
      '',
      [Validators.required, Validators.minLength(8), Validators.maxLength(32), Validators.pattern(/^\S*$/)],
    ],
    password2: [
      '',
      [Validators.required, Validators.minLength(8), Validators.maxLength(32), Validators.pattern(/^\S*$/)],
      [this.validatePasswordMatch.bind(this)],
    ],
  })

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private toast: Toaster,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private fb: FormBuilder
  ) {}

  validatePasswordMatch() {
    return of(this.form.controls).pipe(
      map((controls) => ({ password1: controls.password1.value, password2: controls.password2.value })),
      map((form) => form.password1 === form.password2),
      map((match) => (match ? null : { passwordMatch: true }))
    )
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()

    this.changePassword(this.data.user.id, formValues).subscribe(
      () => {
        this.close()
      },
      (error) => {
        this.toast.error(null, 'Error', error)
        this.close()
      }
    )
  }

  close() {
    this.dialogRef.close()
  }

  private changePassword(
    accountPkey: string,
    request: {
      password1: string
      password2: string
    }
  ) {
    return this.http
      .put(`@api/user/password/${accountPkey}`, request)
      .pipe(tap(() => this.toast.success('Password changed successfully.')))
  }
}
