import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed, selectOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import {
  LoadSmartDevices,
  LoadSmartDevicesComplete,
  LoadSmartDeviceSettings,
  LoadSmartDeviceSettingsComplete,
  MarkSmartDevicePrimary,
  MarkSmartDevicePrimaryComplete,
  UpdateSmartDevice,
  UpdateSmartDeviceComplete,
  UpdateSmartDeviceSettings,
  UpdateSmartDeviceSettingsComplete,
} from './actions'
import { SmartDeviceService } from './service'
import { Store } from '@ngrx/store'
import { selectRentalSmartDevices } from './selectors'

@Injectable()
export class SmartDeviceEffects {
  @Effect()
  settings$ = this.actions$.pipe(
    ofType(LoadSmartDeviceSettings),
    switchMap(() =>
      this.smartDeviceService.getSettings().pipe(
        map((settings) => LoadSmartDeviceSettingsComplete({ settings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateSettings$ = this.actions$.pipe(
    ofType(UpdateSmartDeviceSettings),
    switchMap(({ payload }) =>
      this.smartDeviceService.updateSettings(payload).pipe(
        map(() => UpdateSmartDeviceSettingsComplete({ update: payload })),
        tap(() => this.toaster.success('Settings updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadSmartDevices),
    switchMap(() =>
      this.smartDeviceService.devices().pipe(
        map((items) => LoadSmartDevicesComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateSmartDevice),
    switchMap(({ payload, msg }) =>
      this.smartDeviceService.updateDevice(payload).pipe(
        map((item) => UpdateSmartDeviceComplete({ change: { id: payload.deviceId, changes: payload } })),
        tap(() => this.toaster.success(msg || 'Device updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  primary$ = this.actions$.pipe(
    ofType(MarkSmartDevicePrimary),
    switchMap(({ payload }) =>
      this.smartDeviceService.updateDevice(payload).pipe(
        switchMap((item) => {
          return this.store.pipe(
            selectOnce(selectRentalSmartDevices(payload.rentalId)),
            map((items) => items.filter((t) => t.deviceId != payload.deviceId)),
            map((items) =>
              MarkSmartDevicePrimaryComplete({
                change: [
                  { id: payload.deviceId, changes: { isPrimary: true } },
                  ...items.map((t) => ({ id: t.deviceId, changes: { isPrimary: false } })),
                ],
              })
            )
          )
        }),
        tap(() => this.toaster.success('Device marked as primary successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private toaster: Toaster,
    private smartDeviceService: SmartDeviceService
  ) {}
}
