import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Destroyable, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  AirbnbChannelService,
  AirbnbUpdateBookingSettingsPayload,
  airbnbCancellationPolicies,
  airbnbCheckinEndTimeOptions,
  airbnbCheckinStartTimeOptions,
  airbnbCheckoutTimeOptions,
  airbnbDiscountValidator,
  airbnbFixCheckinTime,
  airbnbFromDiscountToPriceFactor,
  airbnbFromPriceFactorToDiscount,
  airbnbInstantBookingAllowedCategories,
  airbnbListingExpectationTypes,
  airbnbLongTermCancellationPolicies,
  turnOffInstantBookingWarning,
} from '@tokeet-frontend/channels'
import * as lodash from 'lodash'
import { AirbnbHelperService } from '../../airbnb-helper.service'
import { combineLatest } from 'rxjs'

@Component({
  selector: 'app-airbnb-booking-settings-dialog',
  templateUrl: './airbnb-booking-settings-dialog.component.html',
  styleUrls: ['./airbnb-booking-settings-dialog.component.scss'],
})
export class AirbnbBookingSettingsDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    cancellation_policy_settings: this.fb.group({
      lts_cancellation_policy_id: [, [Validators.required]], //  "CANCEL_LONG_TERM_FAIR",
      cancellation_policy_category: [, [Validators.required]], //  "super_strict_60",
      non_refundable_price_factor: [, [airbnbDiscountValidator()]], //  (0, 1)
    }),
    guest_controls: this.fb.group({
      allows_smoking_as_host: [],
      allows_pets_as_host: [],
      allows_infants_as_host: [],
      allows_children_as_host: [],
      children_not_allowed_details: [],
      allows_events_as_host: [],
    }),
    check_in_time_start: [], //  "8", "9", "10", ..., "25"or "FLEXIBLE". "NOT_SELECTED"
    check_in_time_end: [], //  "9", "10", "11", ..., "26"or "FLEXIBLE". "NOT_SELECTED"
    check_out_time: [], //  0-23,
    instant_booking_allowed_category: [], // "well_reviewed_guests",
    instant_book_welcome_message: [], // "hello"
    listing_expectations_for_guests: this.fb.array([]), // "hello"
  })
  get cancellationPolicy() {
    return this.form.get('cancellation_policy_settings') as FormGroup
  }
  get guestControls() {
    return this.form.get('guest_controls') as FormGroup
  }
  get expectations() {
    return this.form.get('listing_expectations_for_guests') as FormArray
  }
  cancellationPolicies = airbnbCancellationPolicies
  longTermCancellationPolicies = airbnbLongTermCancellationPolicies
  airbnbCheckinStartTimeOptions = airbnbCheckinStartTimeOptions
  airbnbCheckinEndTimeOptions = airbnbCheckinEndTimeOptions
  airbnbCheckoutTimeOptions = airbnbCheckoutTimeOptions
  airbnbInstantBookingAllowedCategories = airbnbInstantBookingAllowedCategories
  airbnbListingExpectationTypes = airbnbListingExpectationTypes
  turnOffInstantBookingWarning = turnOffInstantBookingWarning

  isFullSync = false

  constructor(
    public dialogRef: MatDialogRef<AirbnbBookingSettingsDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private airbnbChannelService: AirbnbChannelService,
    private airbnbHelperService: AirbnbHelperService,
    @Inject(MAT_DIALOG_DATA) public data: { connection: Connection }
  ) {
    super()
  }

  ngOnInit() {
    const conn = this.data.connection
    combineLatest([
      this.airbnbChannelService.getListingSyncCategory(conn.propertyId, conn.roomId),
      this.airbnbChannelService.getBookingSettingsV2(conn.channelId, conn.propertyId, conn.roomId),
    ]).subscribe(
      ([sync, settings]) => {
        this.isFullSync = sync.synchronization_category == 'sync_all'
        this.form.patchValue({
          ...settings,
          check_in_time_start: airbnbFixCheckinTime(settings.check_in_time_start),
          check_in_time_end: airbnbFixCheckinTime(settings.check_in_time_end),
          cancellation_policy_settings: {
            ...settings.cancellation_policy_settings,
            non_refundable_price_factor: airbnbFromPriceFactorToDiscount(
              settings.cancellation_policy_settings.non_refundable_price_factor
            ),
          },
        })
        if (!!settings.listing_expectations_for_guests?.length) {
          settings.listing_expectations_for_guests.forEach((expectation) => this.onAddExpectation(expectation))
        }
        this.onChildrenChanged({ checked: !!settings.guest_controls.allows_children_as_host })
      },
      (error) => {
        this.toaster.error('Unable to load booking settings', '', error)
      }
    )
  }

  close() {
    this.dialogRef.close()
  }

  onAddExpectation(data?: { type: string; added_details: string }) {
    this.expectations.push(
      this.fb.group({
        type: [data?.type, [Validators.required]],
        added_details: [data?.added_details],
      })
    )
  }

  onRemoveExpectation(index: number) {
    this.expectations.removeAt(index)
  }

  onChildrenChanged(event: { checked: boolean }) {
    const ctrl = this.guestControls.get('children_not_allowed_details')
    if (event.checked) {
      ctrl.clearValidators()
    } else {
      ctrl.setValidators([Validators.required])
    }
    ctrl.updateValueAndValidity()
    ctrl.markAsTouched()
  }

  onUpdateSyncCategoryAirBnBV2() {
    this.airbnbHelperService.setSyncCategoryV2(this.data.connection).subscribe((sync) => {
      this.isFullSync = sync.synchronization_category === 'sync_all'
    })
  }

  onSave(form: FormGroup) {
    this.save(form)
    if (this.guestControls.invalid) {
      this.toaster.error('Unable to save your settings, please check your house rules.')
    }
  }

  @SaveForm()
  save(form: FormGroup) {
    const data = this.form.getRawValue() as AirbnbUpdateBookingSettingsPayload
    data.cancellation_policy_settings.non_refundable_price_factor = airbnbFromDiscountToPriceFactor(
      data.cancellation_policy_settings.non_refundable_price_factor
    )
    this.airbnbChannelService
      .updateBookingSettingsV2({
        ...data,
        // @ts-ignore
        guest_controls: lodash.mapValues(data.guest_controls, (v, k) => (lodash.startsWith(k, 'allows_') ? !!v : v)),
        channelId: this.data.connection.channelId,
        propertyId: this.data.connection.propertyId,
        roomid: this.data.connection.roomId,
      })
      .subscribe(
        () => {
          this.toaster.success('Booking settings saved successfully')
        },
        (error) => {
          this.toaster.error('Unable to save booking settings', '', error)
        }
      )
  }
}
