/* tslint:disable no-shadowed-variable */
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as R from 'ramda'
import { CalendarEvent } from '@tv3/store/calendar/calendar.model'
import {
  AgendaCalendarEventView,
  CalendarEventView,
  HoldEventView,
  MultiCalendarEventView,
  PlanningCalendarEventView,
  StandardCalendarEventView,
} from '@tv3/store/calendar/calendar.view'
import {
  createdBySystemNameMappings,
  isSomething,
  selectRentalEntities,
  selectUserEntities,
} from '@tokeet-frontend/tv3-platform'
import {
  AgendaCalendarFilters,
  CalendarFilters,
  MultiCalendarFilters,
  PlanningCalendarFilters,
  StandardCalendarFilters,
} from '@tv3/interfaces/filters/calendar.filters'
import { Rental } from '@tokeet-frontend/tv3-platform'
import * as reducer from './calendar.reducer'
import * as state from './calendar.state'
import { selectCurrentAccount } from '@tokeet-frontend/tv3-platform'
import { userFullname } from '@tokeet-frontend/tv3-platform'
import { selectAutomationEntities } from '@tokeet-frontend/automations'

export const calendarState = createFeatureSelector<state.State>('calendar')

export const selectAllCalenderLoaded = createSelector(calendarState, (state: state.State) => state.isLoaded)

export const selectAllCalendarEvents = createSelector(calendarState, reducer.selectAll)

export const selectAllHoldEvents = createSelector(selectAllCalendarEvents, (events) => {
  return R.filter((e) => !e.bookingId, events)
})

export const selectAllHoldEventViews = createSelector(
  selectAllHoldEvents,
  selectRentalEntities,
  selectCurrentAccount,
  selectUserEntities,
  selectAutomationEntities,
  (holds, rentalEntities, account, usersById, triggersById) => {
    return R.map((h) => {
      const rental: Rental = rentalEntities[h.rentalId]
      const item = new HoldEventView(h, rental, account)
      const trigger = triggersById[item.trigger_id]
      item.createdByView =
        trigger?.attributes?.name ||
        createdBySystemNameMappings[item.createdBy] ||
        userFullname(usersById[item.createdBy]) ||
        item.createdBy
      return item
    }, holds)
  }
)

export const selectHoldEventsLoaded = createSelector(calendarState, (state: state.State) => state.isHoldLoaded)

export const isCalendarEventUpdating = createSelector(calendarState, (state: state.State) => state.isUpdating)

export const selectFilteredCalendarEvents = (filters: CalendarFilters) =>
  createSelector(selectAllCalendarEvents, (events: CalendarEvent[]) => {
    return R.filter((event: CalendarEvent) => {
      let isTrue = R.isEmpty(filters.rentals || []) || R.contains(event.rentalId, filters.rentals)
      if (!isTrue) {
        return false
      }

      isTrue = R.isEmpty(filters.channels || []) || R.contains(event.source, filters.channels)
      if (!isTrue) {
        return false
      }

      // TODO filter by other filters
      return isTrue
    }, events)
  })

export const selectAllCalendarEventSources = createSelector(selectAllCalendarEvents, (events: CalendarEvent[]) => {
  return R.sortBy(
    (a: any) => R.toLower(a || ''),
    R.filter((s) => !!s, R.uniq(R.map((e) => e.source, events)))
  )
})

function filterCalendarEventViewByTags(
  events: CalendarEventView[] = [],
  tags: string[] = [],
  taggedIds: string[] = []
) {
  if (!isSomething(tags) && !isSomething(taggedIds)) return events
  return R.filter((event: CalendarEventView) => {
    return taggedIds?.includes(event.rentalId) || !!R.intersection(tags || [], event.rental.tags || []).length
  }, events)
}

export const selectFilteredAgendaCalendarEventsViews = (filters: AgendaCalendarFilters) =>
  createSelector(
    selectFilteredCalendarEvents(filters),
    selectRentalEntities,
    selectCurrentAccount,
    (events, rentalEntities, account) => {
      return R.pipe(
        R.filter((e: CalendarEvent) => !!rentalEntities[e.rentalId]),
        R.map((e: CalendarEvent) => new AgendaCalendarEventView(e, new Rental(rentalEntities[e.rentalId]), account))
      )(events)
    }
  )

export const selectFilteredStandardCalendarEventsViews = (filters: StandardCalendarFilters) =>
  createSelector(
    selectFilteredCalendarEvents(filters),
    selectRentalEntities,
    selectCurrentAccount,
    (events, rentalEntities, account) => {
      const eventViews = R.pipe(
        R.filter((e: CalendarEvent) => !!rentalEntities[e.rentalId]),
        R.map((e: CalendarEvent) => new StandardCalendarEventView(e, new Rental(rentalEntities[e.rentalId]), account))
      )(events)

      return filterCalendarEventViewByTags(eventViews, filters.tags, filters.taggedIds)
    }
  )

export const selectFilteredPlanningCalendarEventsViews = (filters: PlanningCalendarFilters) =>
  createSelector(
    selectFilteredCalendarEvents(filters),
    selectRentalEntities,
    selectCurrentAccount,
    (events, rentalEntities, account) => {
      const eventViews = R.pipe(
        R.filter((e: CalendarEvent) => !!rentalEntities[e.rentalId]),
        R.map((e: CalendarEvent) => new PlanningCalendarEventView(e, new Rental(rentalEntities[e.rentalId]), account))
      )(events)

      // filter by tags
      return filterCalendarEventViewByTags(eventViews, filters.tags, filters.taggedIds)
    }
  )

// @todo - CalendarSelectors: selectFilteredMultiCalendarEventsViews 792.19 ms
export const selectFilteredMultiCalendarEventsViews = (filters: MultiCalendarFilters) =>
  createSelector(
    selectFilteredCalendarEvents(filters),
    selectRentalEntities,
    selectCurrentAccount,
    (events, rentalEntities, account) => {
      return R.reduce(
        (acc, event) => {
          if (!acc[event.rentalId]) acc[event.rentalId] = []

          const eventView = new MultiCalendarEventView(event, new Rental(rentalEntities[event.rentalId]), account)

          acc[event.rentalId].push(eventView)
          return acc
        },
        {} as Record<string, MultiCalendarEventView[]>,
        events
      )
    }
  )

// tslint:disable no-shadowed-variable
export const selectCalendarLoading = createSelector(calendarState, (state: state.State) => state.isLoading)

export const selectAllCalendarEventsForRental = (rentalId: string) =>
  createSelector(selectAllCalendarEvents, (events) => {
    return R.filter((e: CalendarEvent) => e.rentalId === rentalId, events)
  })

export const selectMultiCalendarEventsForRental = (rentalId: string) =>
  createSelector(
    selectAllCalendarEventsForRental(rentalId),
    selectRentalEntities,
    selectCurrentAccount,
    (events, rentalEntities, account) => {
      return events.map((event) => new MultiCalendarEventView(event, new Rental(rentalEntities[rentalId]), account))
    }
  )
