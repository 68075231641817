<div class="template-editor-container">
  <div class="comment-composer-box">
    <form [formGroup]="form" [formEditablePermission]="form">
      <div class="p-3">
        <div class="d-flex align-items-center gap-3">
          <mat-form-field2 class="mat-block">
            <input type="text" matInput required placeholder="Template Name" formControlName="name" />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Template Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
              Template Name must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
              Template Name must be at most <strong>50</strong> characters long
            </mat-error>
            <mat-hint align="end">{{ form.get('name').value.length || 0 }}/50</mat-hint>
          </mat-form-field2>
          <div class="flex-fill"></div>
          <mat-form-field2 class="mat-block" [showLabel]="false" style="width: 250px" *ngIf="showType">
            <mat-select
              matTooltip="Change the type of this template."
              matTooltipPosition="above"
              formControlName="type"
              placeholder="Change type"
            >
              <mat-option [value]="types.Email">Email</mat-option>
              <mat-option [value]="types.PushNotification">Push Notification</mat-option>
              <mat-option [value]="types.Payload">Payload</mat-option>
              <mat-option [value]="types.Review">Review</mat-option>
            </mat-select>
          </mat-form-field2>
          <button
            (click)="editor.onDataDictionary()"
            [disabled]="1 | isReadonlyRole"
            matTooltip="You may insert data tokens using data dictionary."
            matTooltipPosition="above"
            type="button"
            class="btn btn-secondary-primary"
          >
            <span class="cs-icon cs-icon-book"></span> data dictionary
          </button>
          <button
            (click)="onSave(form)"
            class="btn btn-secondary-info save-btn"
            type="button"
            *ngIf="showSaveBtn"
            [disabled]="1 | isReadonlyRole"
          >
            <i class="cs-icon cs-icon-save"></i>Save
          </button>
        </div>
      </div>

      <app-editor-email
        *ngIf="templateType === types.Email"
        [type]="templateType"
        [contentCtrl]="form.get('body')"
        [subjectCtrl]="form.get('subject')"
        #editor
      ></app-editor-email>

      <app-editor-push-notification
        *ngIf="templateType === types.PushNotification || templateType === types.Review"
        [type]="templateType"
        [contentCtrl]="form.get('body')"
        #editor
      ></app-editor-push-notification>

      <app-editor-http
        *ngIf="templateType === types.Payload"
        [type]="templateType"
        [contentCtrl]="form.get('body')"
        [subjectCtrl]="form.get('subject')"
        #editor
      ></app-editor-http>
    </form>
  </div>
</div>
