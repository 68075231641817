<div class="modal-header">
  <h6 class="modal-title">Edit Listing Settings</h6>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="modal-body p-0">
  <form [formGroup]="form">
    <div class="p-3 border-bottom">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Landlord Name" formControlName="landlordName" type="text" required />
            <mat-error *ngIf="form.get('landlordName').touched && form.get('landlordName').hasError('required')">
              Landlord Name is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Landlord Email" formControlName="landlordEmail" type="email" required />
            <mat-error *ngIf="form.get('landlordEmail').touched && form.get('landlordEmail').hasError('required')">
              Landlord Email is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Landlord Phone" formControlName="landlordPhone" type="text" required />
            <mat-error *ngIf="form.get('landlordPhone').touched && form.get('landlordPhone').hasError('required')">
              Landlord Phone is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Check-in Location" formControlName="checkInOutPlace" type="text" required />
            <mat-error *ngIf="form.get('checkInOutPlace').touched && form.get('checkInOutPlace').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="p-3 border-bottom">
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field2
            class="mat-block"
            matTooltip="The amount of days before arriving in which the guest should contact the landlord"
          >
            <input
              matInput
              placeholder="Days Before Arrival"
              formControlName="daysBeforeArrival"
              type="number"
              step="1"
              required
            />
            <mat-error
              *ngIf="form.get('daysBeforeArrival').touched && form.get('daysBeforeArrival').hasError('required')"
            >
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-3">
          <mat-form-field2
            class="mat-block"
            matTooltip="If it's a house and providing floor info does not make much sense, you can just put 1"
          >
            <input matInput placeholder="Floor number" formControlName="floor" type="number" required />
            <mat-error *ngIf="form.get('floor').touched && form.get('floor').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="depositTypeId" placeholder="Deposit Type" required>
              <mat-option *ngFor="let item of rentalsUnitedDepositTypes" [value]="item.id">{{ item.label }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('depositTypeId').touched && form.get('depositTypeId').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <textarea
              matInput
              placeholder="Cancellation Policy Text"
              formControlName="cancellationPolicyText"
              rows="3"
            ></textarea>
          </mat-form-field2>
        </div>
        <div class="col-sm-3">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Deposit Amount" formControlName="depositAmount" type="number" required />
            <mat-error *ngIf="form.get('depositAmount').touched && form.get('depositAmount').hasError('required')">
              Value is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('depositAmount').hasError('min')"> Min value is <strong>0</strong> </mat-error>
            <mat-error *ngIf="form.get('depositAmount').hasError('max')"> Max value is <strong>100</strong> </mat-error>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div class="p-3">
      <h6>Cancellation Policy</h6>
      <div formArrayName="cancellationPolicy" *ngFor="let item of cancelPolicies.controls; let i = index">
        <div [formGroupName]="i" class="row">
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input type="number" formControlName="validFrom" matInput required placeholder="From" />
              <mat-hint>days before arrival</mat-hint>
              <mat-error *ngIf="item.get('validFrom').touched && item.get('validFrom').hasError('required')">
                Value is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="item.get('validFrom').hasError('max')"> From should be less than <b>To</b> </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input type="number" formControlName="validTo" matInput required placeholder="To" />
              <mat-hint>days before arrival</mat-hint>
              <mat-error *ngIf="item.get('validTo').touched && item.get('validTo').hasError('required')">
                Value is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="item.get('validTo').hasError('min')"> To should be greater than <b>From</b> </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-4">
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                formControlName="totalPricePercent"
                matInput
                required
                placeholder="Charge (0%-100%)"
              />
              <mat-hint>of total booking amount</mat-hint>
              <mat-error
                *ngIf="item.get('totalPricePercent').touched && item.get('totalPricePercent').hasError('required')"
              >
                Value is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="item.get('totalPricePercent').hasError('min')">
                Value should be greater than <strong>0</strong>
              </mat-error>
              <mat-error *ngIf="item.get('totalPricePercent').hasError('max')">
                Value should be less than <strong>100</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-2">
            <button
              matTooltip="Remove"
              matTooltipPosition="above"
              (click)="onRemoveCancelPolicy(i)"
              type="button"
              class="btn btn-secondary-danger align-input-row-end"
              *ngIf="!!i"
            >
              <i class="cs-icon cs-icon-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="cancelPolicies.hasError('overlap')" class="text-danger mb-3">
        Cancellation policy period(s) should not overlap.
      </div>
      <div class="mt-3">
        <button class="btn btn-secondary-info" (click)="onAddCancelPolicy()">
          <i class="far fa-plus"></i> Add Penalty
        </button>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-outline-light">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <button *ngIf="isEditing" (click)="onSave(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
  <button *ngIf="!isEditing" (click)="onCreate(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Create
  </button>
</div>
