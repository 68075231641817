<mat-form-field2 class="mat-block" [showLabel]="!inline">
  <mat-label>{{ label }}</mat-label>
  <app-select
    [required]="required"
    [items]="currencies"
    [searchable]="true"
    bindLabel="code"
    bindValue="code"
    [formControl]="currencyCodeCtrl"
  >
    <ng-template selectLabel let-items="items">
      <ng-container *ngFor="let item of items">
        <ng-template [ngTemplateOutlet]="itemTpl" [ngTemplateOutletContext]="{ item: item }"></ng-template>
      </ng-container>
    </ng-template>
    <ng-template selectItem let-item="item" let-label="label">
      <ng-template [ngTemplateOutlet]="itemTpl" [ngTemplateOutletContext]="{ item: item }"></ng-template>
    </ng-template>
  </app-select>
</mat-form-field2>
<ng-template let-item="item" #itemTpl>
  <span class="flag-icon-squared mr-1" [class]="item.icon"></span>
  <span>{{ item.code }} (<span [innerHtml]="item.symbol"></span>)</span>
</ng-template>
