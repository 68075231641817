<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon2.png" alt="" height="40" />
      <div>
        <h6 class="mb-0">Request Channel Manager Access</h6>
        <div class="text-sm">
          This section can take between 10 minutes and 2 hrs depending on the volume of requests.
        </div>
      </div>
    </div>
    <div class="border-top p-4 d-flex flex-column" style="min-height: 500px">
      <div class="flex-fill text-sm">
        <p>
          If you have multiple properties to connect, it's recommended to complete these steps for all properties in
          advance. Please enter your property or hotel ID.
        </p>
        <mat-form-field2 class="w-50">
          <input matInput placeholder="Property ID" [formControl]="propertyIdCtrl" required />
          <mat-error *ngIf="propertyIdCtrl.touched && propertyIdCtrl.hasError('required')">
            Property ID is required
          </mat-error>
        </mat-form-field2>
        <p>Once done, click "Next" to proceed to the next step.</p>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button
          class="btn btn-secondary-info"
          matTooltip="Please enter property ID to connect to Booking.com"
          [disabled]="propertyIdCtrl.invalid || isConnecting"
          (click)="onConnect(propertyIdCtrl)"
        >
          Connect
          <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
          <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isConnecting"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.png" alt="" class="w-100" />
  </div>
</div>
