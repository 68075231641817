<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/hvmi/tokeet-hvmi.png" alt="" height="45" />
        <div>
          <h6 class="mb-0">HVMI</h6>
          <div class="text-sm">
            Connect multiple HVMI accounts and sync bookings, prices, guest messages, and more instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3">
        <div class="alert alert-secondary-danger">
          <i class="cs-icon cs-icon-pray alert-icon" style="font-size: 45px"></i>
          <div class="alert-content">
            <div class="text-semi-bold">Thank you for your interest</div>
            in listing your properties with Marriott's Homes & Villas (HVMI) channel. Marriott accepts a limited number
            of property managers. While AdvanceCM can assist with your application, the final decision is up to
            Marriott.
          </div>
        </div>
        <div class="text-semi-bold">Already Signed Up to Homes & Villas by Marriott?</div>
        <p>
          If you signed up but did not receive an HMC ID, contact your account manager at Homes & Villas by Marriott to
          get it.
        </p>

        <div class="text-semi-bold">Not Yet Signed Up?</div>
        <p>Fill out Marriott's interest form. Once you receive your HMC ID, come back here.</p>
        <div class="text-semi-bold">Signed Up via a Channel Manager?</div>
        <p>
          This direct integration requires a new HMC ID. Please contact Homes & Villas by Marriott Bonvoy to obtain it.
        </p>
        <div class="alert alert-secondary-warning2 align-items-start">
          <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
          <div class="alert-content">
            <div class="text-semi-bold">Important Notes</div>
            <ul class="pl-3">
              <li>
                After enabling the AdvanceCM rentals you wish to link and selecting the option to initiate connection,
                you will receive a confirmation email.
              </li>
              <li>Check your email for this confirmation and follow the next steps outlined in the guide.</li>
              <li>An HVMI onboarding team member will contact you to finalize the connections.</li>
            </ul>
          </div>
        </div>

        <form [formGroup]="form" [formEditablePermission]="form" class="step_1_form">
          <div formArrayName="hmcIds" *ngFor="let item of hmcIds.controls; let i = index">
            <div [formGroupName]="i" class="d-flex gap-3">
              <mat-form-field2 class="">
                <mat-label
                  >HMC ID
                  <i
                    matTooltip="HMC ID will be generated by HVMI Supply Onboarding team and shared with connectivity partner as new HMCs are onboarded to push the inventory into HVMI platform"
                    matTooltipPosition="above"
                    class="fal fa-question-circle"
                  ></i>
                </mat-label>
                <input type="text" formControlName="hmcId" matInput disabled />
                <mat-error *ngIf="item['controls'].hmcId.touched && item.hasError('required', ['required'])">
                  HMC ID is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="item['controls'].hmcId.touched && item.hasError('required', ['minlength'])">
                  HMC ID must be <strong>at least 2</strong> characters long
                </mat-error>
                <mat-error *ngIf="item['controls'].hmcId.touched && item.hasError('required', ['maxlength'])">
                  HMC ID must be <strong>at most 30</strong> characters long
                </mat-error>
              </mat-form-field2>
              <button
                (click)="onDisconnect(i)"
                class="btn btn-secondary-danger align-input-row-end"
                type="button"
                [disabled]="1 | isReadonlyRole"
              >
                <span class="cs-icon cs-icon-unlink"></span>
                Disconnect
              </button>
            </div>
          </div>
        </form>

        <form [formGroup]="newForm" [formEditablePermission]="form" class="step_1_form">
          <div class="d-flex gap-3">
            <mat-form-field2 class="">
              <mat-label
                >HMC ID
                <i
                  matTooltip="HMC ID will be generated by HVMI Supply Onboarding team and shared with connectivity partner as new HMCs are onboarded to push the inventory into HVMI platform"
                  matTooltipPosition="above"
                  class="fal fa-question-circle"
                ></i>
              </mat-label>
              <input type="text" formControlName="hmcId" matInput required />
              <mat-error *ngIf="newForm.get('hmcId').touched && newForm.get('hmcId').hasError('required')">
                HMC ID is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="newForm.get('hmcId').touched && newForm.get('hmcId').hasError('pattern')">
                HMC ID must be a number
              </mat-error>
              <mat-error *ngIf="newForm.get('hmcId').touched && newForm.get('hmcId').hasError('minlength')">
                HMC ID must be <strong>at least 2</strong> characters long
              </mat-error>
              <mat-error *ngIf="newForm.get('hmcId').touched && newForm.get('hmcId').hasError('maxlength')">
                HMC ID must be <strong>at most 30</strong> characters long
              </mat-error>
            </mat-form-field2>

            <button
              (click)="onSave(newForm)"
              class="btn btn-secondary-info align-input-row-end"
              type="button"
              [disabled]="(1 | isReadonlyRole) || isConnecting"
            >
              <i class="cs-icon cs-icon-link" *ngIf="!isConnecting"></i>
              <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>

              Connect
            </button>
          </div>
        </form>

        <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
          <button class="btn btn-secondary-info" (click)="next.emit()" [disabled]="!canMoveToRentalsStep()">
            Continue
            <i class="cs-icon cs-icon-arrow-right-long text-xs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/hvmi/step1.png" alt="" class="w-100" />
  </div>
</div>
