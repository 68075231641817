import { Component, Inject, OnInit } from '@angular/core'
import { filter, finalize, map, switchMap } from 'rxjs/operators'

import { Lightbox } from 'ngx-lightbox'
import { DomSanitizer } from '@angular/platform-browser'

import {
  ActionFailed,
  ConfirmDialogService,
  DataCheckerService,
  Destroyable,
  isSomething,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { AppImage, AppVideo, creditsRequiredProducts, ProductPlan, ProductStatus } from '../store/app/app.model'
import { AddCreditCardDialogService } from '../add-credit-card-dialog/add-credit-card-dialog.service'
import { AppService } from '../store/app/app.service'
import { select, Store } from '@ngrx/store'
import { selectAllCards } from '@tokeet-frontend/billing'
import { AppOverviewStore } from './app-overview.store'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { of } from 'rxjs'
import { CardGuard } from '../store/card/guard'
import { CreditInputDialogService } from './credit-input-dialog/credit-input-dialog.service'

@Component({
  selector: 'app-app-overview-dialog',
  templateUrl: './app-overview-dialog.component.html',
  styleUrls: ['./app-overview-dialog.component.scss'],
  providers: [AppOverviewStore],
  host: {
    class: 'modal-content',
  },
})
export class AppOverviewDialogComponent extends Destroyable implements OnInit {
  statuses = ProductStatus
  app$ = this.componentStore.selectApp$
  availability$ = this.componentStore.selectAvailability$

  isAvailabilityLoading$ = this.componentStore.isLoading('availability')
  isAppLoading$ = this.componentStore.isLoading('app')

  showingImageType: 'desktop' | 'mobile' = 'desktop'
  subscribing = false

  isCardAdded = false

  constructor(
    private appsService: AppService,
    private toaster: Toaster,
    private store: Store<any>,
    private addCardDialogService: AddCreditCardDialogService,
    private confirmDialogService: ConfirmDialogService,
    private componentStore: AppOverviewStore,
    private lightbox: Lightbox,
    public sanitizer: DomSanitizer,
    private buyCreditDialog: CreditInputDialogService,
    private dataChecker: DataCheckerService,
    @Inject(MAT_DIALOG_DATA) private data: { product: string },
    private dialogRef: MatDialogRef<AppOverviewDialogComponent>
  ) {
    super()
    this.dataChecker.check([CardGuard])
  }

  ngOnInit() {
    this.componentStore.loadApp(of(this.data.product))
    this.componentStore.loadAvailability(this.app$)
    this.store.pipe(select(selectAllCards), untilDestroy(this)).subscribe((cards) => {
      this.isCardAdded = isSomething(cards)
    })
  }

  getSubscribeDialogBody(plan: ProductPlan) {
    let body = ''
    switch (plan.product) {
      case 'invoicing':
        body = `This will immediately activate your subscription to ${plan.name}.
                Your credit card will be charged.
                You will receive credit for any current subscription which is not fully used.
                <br/><br/>Shall we proceed?`
        break
      case 'webready':
        body = `This will immediately activate your subscription to ${plan.name}.
                Your credit card will be charged for the total amount of the subscription fee if it exists.
                You will receive credit for any current subscription which is not fully used.
                <br/><br/>Shall we proceed?`
        break
      default:
        body = `This will immediately activate your subscription to ${plan.name}.
                Your credit card will be charged for the total amount of the subscription fee.
                You will receive credit for any current subscription which is not fully used.
                <br/><br/>Shall we proceed?`
        break
    }
    return body
  }

  getCreditsAmount() {
    return this.buyCreditDialog
      .open()
      .afterClosed()
      .pipe(
        filter((data) => data?.amount > 0),
        map((data) => data?.amount)
      )
  }

  confirmBeforeSubscribe(plan: ProductPlan) {
    return this.confirmDialogService.confirm({
      title: 'Activate subscription?',
      body: this.getSubscribeDialogBody(plan),
    })
  }

  isCreditsRequired(product: string) {
    return creditsRequiredProducts.includes(product)
  }

  subscribe(plan: ProductPlan) {
    const createSubscription = () => {
      this.subscribing = true
      const subscribe = (credits: number = undefined) => {
        return this.appsService.subscribe(plan.product, plan.id, credits ? { amount: credits } : null)
      }

      if (this.isCreditsRequired(plan.product)) {
        return this.getCreditsAmount().pipe(switchMap((amount) => subscribe(amount)))
      } else {
        return this.confirmBeforeSubscribe(plan).pipe(switchMap(() => subscribe()))
      }
    }

    createSubscription()
      .pipe(
        switchMap(() => this.app$),
        switchMap((app) => this.appsService.getPlanAvailability(app.stripe_product_code)),
        finalize(() => (this.subscribing = false))
      )
      .subscribe(
        (data) => {
          this.componentStore.setAvailability(data)
          this.toaster.success('App subscribed successfully.')
          this.dialogRef.close('success')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onAddCard() {
    this.addCardDialogService.open()
  }

  getVideoUrl(sanitizer: DomSanitizer, video: AppVideo) {
    let url = video.url
    if (url) {
      return sanitizer.bypassSecurityTrustResourceUrl(url)
    }
    switch (video.type) {
      case 'youtube':
        url = `https://www.youtube.com/embed/${video.videoId}`
        break
      default:
        return false
    }

    return sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  getImagesByType(images: AppImage[], type: 'desktop' | 'mobile') {
    return (images || []).filter((m) => m.type === type)
  }

  openImageViewer(images: AppImage[], index: number) {
    this.lightbox.open(images.map((i) => ({ src: i.url, thumb: i.url })) as any, index, {
      showImageNumberLabel: true,
      centerVertically: true,
      wrapAround: true,
    })
  }

  close() {
    this.dialogRef.close()
  }
}
