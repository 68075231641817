<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-user-edit"></i> Edit Additional Guests</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <button
    (click)="onNew()"
    matTooltip="Create new guest and add to this inquiry"
    type="button"
    class="btn btn-secondary-info create-new"
  >
    <span class="far fa-plus"></span> create new
  </button>

  <button
    (click)="onAddExisting()"
    [disabled]="form.get('guestIds').value?.length === 0"
    matTooltip="Add selected guest"
    type="button"
    class="btn btn-secondary-info add-existing"
  >
    <span class="far fa-plus"></span> add
  </button>

  <app-select-search
    bindLabel="name"
    bindValue="id"
    [inline]="false"
    (search)="guestSearch.next($event)"
    [items]="guests$ | async"
    [ctrl]="form.get('guestIds')"
    placeholder="Add Existing Guest"
    class="pull-right"
  ></app-select-search>

  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="departure" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Guest Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.name" matTooltipPosition="above">
              {{ element.name | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="primaryEmail">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.primaryEmail" matTooltipPosition="above">
              {{ element.primaryEmail | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef> Telephone</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.phone" matTooltipPosition="above">
              {{ element.phone | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="countryView">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Country</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.countryView }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div dropdown placement="bottom right" container="body">
              <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                <a class="btn-ellips">
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li>
                  <button (click)="onRemove(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Remove</button>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="5"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-outline-primary">
    <i class="fal fa-check-square"></i> done
  </button>
</div>
