import {
  AddIncidentComplete,
  DeleteIncidentComplete,
  DeleteIncidentsComplete,
  LoadIncidentsByIdsComplete,
  LoadIncidentsComplete,
  UpdateIncidentComplete,
} from './actions'
import { adapter, initialState, State } from './state'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadIncidentsComplete, (state, { incidents }) => adapter.upsertMany(incidents, { ...state, isLoaded: true })),
  on(LoadIncidentsByIdsComplete, (state, { incidents }) => adapter.addMany(incidents, state)),
  on(AddIncidentComplete, (state, { incident }) => adapter.addOne(incident, state)),
  on(UpdateIncidentComplete, (state, { update }) => adapter.updateOne(update, state)),
  on(DeleteIncidentComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteIncidentsComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function incidentReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
