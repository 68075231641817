<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-file-edit"></i> Select An Inquiry</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <app-select-search
          bindValue="id"
          bindLabel="title"
          template="inquiry"
          [multiple]="false"
          [inline]="false"
          [items]="inquiries$ | async"
          (search)="search.next($event)"
          [ctrl]="form.get('inquiry')"
          placeholder="Select Inquiry"
        ></app-select-search>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>Please select an inquiry from the list above. The message will be moved to this inquiry.</p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
