import { adapter, initialState, State } from './ssl.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  DeleteCustomDomainCertsComplete,
  DeleteCustomDomainComplete,
  GetCustomDomainComplete,
  GetOrCreateCustomDomainComplete,
  LoadCustomDomainsComplete,
  RequestCustomDomainCertsComplete,
  SetCustomDomainCertsComplete,
} from './ssl.actions'
import { upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadCustomDomainsComplete, (state, { items }) => upsertMany(adapter, items, { ...state, isLoaded: true })),
  on(
    GetCustomDomainComplete,
    GetOrCreateCustomDomainComplete,
    SetCustomDomainCertsComplete,
    RequestCustomDomainCertsComplete,
    DeleteCustomDomainCertsComplete,
    (state, { item }) => adapter.setOne(item, state)
  ),
  on(DeleteCustomDomainComplete, (state, { id }) => adapter.removeOne(id, state))
)

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
