<ng-container *ngIf="!isCustomChannel">
  <div class="p-4 clickable" (click)="onChannel()">
    <img [src]="channel.logoColor" [alt]="channel.friendlyName" />
    <div class="description mt-3">
      {{ channel.description }}
    </div>
  </div>
  <div class="actions border-top" style="background-color: #f9fbff" *ngIf="!isChannelConnected">
    <div class="text-semi-bold">
      <a href="javascript:;" (click)="onConnect()"> Connect <i class="far fa-link"></i> </a>
    </div>
  </div>
  <div class="actions border-top" *ngIf="isChannelConnected">
    <div class="border-right">
      <div class="text-light text-uppercase text-xs">Accounts</div>
      <div class="text-bold">{{ accounts }}</div>
    </div>
    <div class="border-right">
      <div class="text-light text-uppercase text-xs">Listings</div>
      <div class="text-bold">{{ connections.length }}</div>
    </div>
    <div class="text-semi-bold" *ngIf="!(isSingleAccountChannel | call: channel.name)">
      <a href="javascript:;" (click)="onConnect()"> <i class="far fa-plus"></i> Add </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isCustomChannel">
  <div class="p-4 clickable" (click)="onChannel()">
    <div class="">
      <app-avatar-name
        [url]="channel.logo"
        [name]="channel.friendly_name"
        [imageCircle]="false"
        [imageSize]="40"
        [backgroundColor]="channel.logo ? 'transparent' : null"
        class="text-bold"
      ></app-avatar-name>
    </div>

    <div class="description mt-3">
      {{ channel.description }}
    </div>
  </div>
</ng-container>
