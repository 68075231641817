import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromGatewayLogs from './gateway-log.reducer'
import { GatewayLogEffects } from './gateway-log.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('gateway-logs', fromGatewayLogs.gatewayLogReducer),
    EffectsModule.forFeature([GatewayLogEffects]),
  ],
  declarations: [],
})
export class GatewayLogModule {}
