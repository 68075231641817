import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'

@Pipe({
  name: 'ngSelectEclipsedValue',
})
export class NgSelectEclipsedValuePipe implements PipeTransform {
  transform(items: any[], field: string): string {
    return R.join(
      ', ',
      R.take(
        5,
        R.map((i) => i[field], items)
      )
    )
  }
}
