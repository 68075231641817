import { epochToViewUTC } from '@tokeet-frontend/tv3-platform'

export interface DiscountCodeChangeValues {
  start?: number // 1599143932
  expires?: number // 2592000
  max_uses?: number // 10
}

export interface DiscountCodeChangeItem {
  before: DiscountCodeChangeValues
  after: DiscountCodeChangeValues
  update: number // 1598971132
  user: string
}

export enum DiscountCodeType {
  Percent = 'pct',
  Flat = 'flat',
}

export interface DiscountCode {
  id: string

  code: string // This a 5 character string.
  amount: number // This is the amount of the discount.
  type: DiscountCodeType // This is the type of discount (pct, flat).
  name: string // The name of the code.
  description: string // The description of the code.
  email?: string // The OPTIONAL email address of the guest.
  rental_id?: string // The OPTIONAL tokeet rental id of the specific rental.
  max_uses?: number // The number of uses allowed. (0) indicates infinite.
  start?: number // The OPTIONAL time (epoch) when the code will start being active.
  expires?: number // The OPTIONAL time (epoch) when the code will expire.
  los?: number // The OPTIONAL minimum length of stay required for the discount.
  booking_total_min?: number // TV3-3265

  status: number

  // backend fields
  last_used?: number // This is the last time the code was used.
  bookings?: string[] // An array of bookings associated with this code.

  changes?: DiscountCodeChangeItem[]
}

export interface DiscountCodeView extends DiscountCode {
  startView: string
  expiresView: string
  lastUsedView: string
  rentalView: string
  usedView: number
  leftView: string
}

export function toDiscountCodeView(item: DiscountCode): DiscountCodeView {
  const usedView = (item.bookings && item.bookings.length) || 0
  const leftView = item.max_uses > 0 ? `${item.max_uses - usedView}` : ''
  return {
    ...item,
    startView: epochToViewUTC(item.start),
    expiresView: epochToViewUTC(item.expires),
    usedView,
    leftView,
    lastUsedView: epochToViewUTC(item.last_used),
    rentalView: '',
  }
}
