import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store'
import { filter, map } from 'rxjs/operators'
import { State } from './channels.state'
import { combineLatest } from 'rxjs'
import { FeedChannelStatus, HomeAwayRentalConfigView, HomeToGoRentalConfigView } from './channels.model'
import { ChannelNameTokens, selectFormChannels, selectRentalEntities } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

export const channelsState = createFeatureSelector<State>('channels-lib')

export const selectChannelConfigs = createSelector(channelsState, (state) => state.configs)

export const selectRentalConfigs = createSelector(channelsState, (state) => state.rentalConfigs)

export const selectChannelConfigLoadedState = createSelector(channelsState, (state) => state.configLoaded)

export const getChannelConfigLoaded = (name: ChannelNameTokens) => (store: Store<State>) => {
  return store.pipe(
    select(selectChannelConfigLoadedState),
    map((loaded) => loaded[name])
  )
}

export const getChannelStatus = (channel: ChannelNameTokens) => (store: Store<State>) => {
  return combineLatest([
    store.pipe(select(selectChannelConfigs)),
    store.pipe(select(selectChannelConfigLoadedState)),
  ]).pipe(
    filter(([, loadedConfig]) => loadedConfig[channel]),
    map(([configs]) => (!!configs[channel]?.status ? 'applied' : 'not-applied') as FeedChannelStatus)
  )
}

export const getChannelConfig = (channel: ChannelNameTokens) => (store: Store<State>) => {
  return combineLatest([
    store.pipe(select(selectChannelConfigs)),
    store.pipe(select(selectChannelConfigLoadedState)),
  ]).pipe(
    filter(([, loadedConfig]) => loadedConfig[channel]),
    map(([configs]) => configs[channel])
  )
}

export const getRentalConfigs = (channelName: ChannelNameTokens) => (store: Store<State>) => {
  return combineLatest([store.pipe(select(selectRentalConfigs)), store.pipe(select(selectRentalEntities))]).pipe(
    map(([configs, rentals]) => {
      return R.map((config): HomeToGoRentalConfigView | HomeAwayRentalConfigView => {
        return {
          ...config,
          rental: rentals[config?.rental_id],
          rental_name: rentals[config?.rental_id]?.name,
        }
      }, configs[channelName] || [])
    })
  )
}

export const selectSymplFormChannels = (store: Store<any>) =>
  store.pipe(
    select(selectFormChannels({ inquirySource: 'tokeet' } as Inquiry)),
    map((sources: { id: string; label: string; name: string }[]) =>
      R.map((s) => ({ ...s, label: s.id === 'tokeet' ? 'Sympl Dashboard' : s.label }), sources)
    )
  )
