import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  AirbnbChannelConfig,
  HomeAwayChannelConfig,
  HomeAwayConfigForm,
  HomeAwayRentalConfig,
  HomeToGoChannelConfig,
  HomeToGoConnectionForm,
  HomeToGoRentalConfig,
  UpdateFeedConfig,
} from './channels.model'
import { Observable } from 'rxjs'
import {
  Channel,
  ChannelNameTokens,
  Rental,
  selectAllRentals,
  selectOnce,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { map, switchMap } from 'rxjs/operators'
import * as R from 'ramda'
import { ChannelReviewResponse, FetchChannelReviewsParams, ReviewMonthlyRatingResponse } from './review.model'
import { AirbnbGuest2HostReviewCategories } from './airbnb/review'
import * as lodash from 'lodash'
import { normalizeVrboListingLodgingRate } from './vrbo/model'

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  constructor(private http: HttpClient, private store: Store<any>, private toaster: Toaster) {}

  getChannelPrefix(channelNameToken: ChannelNameTokens) {
    switch (channelNameToken) {
      case ChannelNameTokens.HomeToGo:
        return 'hometogo'
      case ChannelNameTokens.Homeaway:
        return 'homeaway'
    }
  }

  loadChannel(channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/config`

    return this.http.get<HomeToGoChannelConfig>(url)
  }

  loadRentalConfigs(channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rental/config`

    return this.http.get<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>(url)
  }

  disconnectVrbo() {
    const url = `@api/homeaway/pause`
    return this.http.put(url, {})
  }

  reconnectVrbo() {
    const url = `@api/homeaway/unpause`
    return this.http.put(url, {})
  }

  getRentalFeesVrbo(rentalId: string) {
    const url = `@api/homeaway/audit/rates?rental_id=${rentalId}`
    return this.http.get(url).pipe(map((res) => normalizeVrboListingLodgingRate(res)))
  }

  createChannel(config: HomeAwayConfigForm, channel: Channel) {
    const prefix = this.getChannelPrefix(channel.name)
    const url = `@api/${prefix}/config`

    return this.http.post<HomeAwayChannelConfig>(url, config)
  }

  updateChannel(config: UpdateFeedConfig | HomeAwayConfigForm, channel: Channel) {
    const prefix = this.getChannelPrefix(channel.name)
    const url = `@api/${prefix}/config`

    return this.http.put<HomeToGoChannelConfig | HomeAwayChannelConfig>(url, config)
  }

  updateRental(config: UpdateFeedConfig, rental: Rental, channel: Channel) {
    const prefix = this.getChannelPrefix(channel.name)
    const url = `@api/${prefix}/rental/config/${rental.id}`

    return this.http.put<HomeToGoRentalConfig | HomeAwayRentalConfig>(url, config)
  }

  createRental(config: UpdateFeedConfig, rental: Rental, channel: Channel) {
    const prefix = this.getChannelPrefix(channel.name)
    const url = `@api/${prefix}/rental/config/${rental.id}`

    return this.http.post<HomeToGoRentalConfig | HomeAwayRentalConfig>(url, config)
  }

  pauseRentalConfig(config: HomeToGoRentalConfig | HomeAwayRentalConfig, channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rental/${config.rental_id}/pause`

    return this.http.put<HomeToGoRentalConfig | HomeAwayRentalConfig>(url, config)
  }

  resumeRentalConfig(config: HomeToGoRentalConfig | HomeAwayRentalConfig, channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rental/${config.rental_id}/unpause`

    return this.http.put<HomeToGoRentalConfig | HomeAwayRentalConfig>(url, config)
  }

  submitForm(form: HomeToGoConnectionForm): Observable<HomeToGoChannelConfig> {
    const url = `@api/hometogo/register`

    return this.http.post<HomeToGoChannelConfig>(url, form)
  }

  enableAll(channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rentals/unpause`

    return this.store.pipe(
      selectOnce(selectAllRentals),
      switchMap((rentals) =>
        this.http.put<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>(
          url,
          R.map((r) => r.id, rentals)
        )
      )
    )
  }

  disableAll(channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rentals/pause`

    return this.store.pipe(
      selectOnce(selectAllRentals),
      switchMap((rentals) =>
        this.http.put<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>(
          url,
          R.map((r) => r.id, rentals)
        )
      )
    )
  }

  enable(rentalIds: string[], channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rentals/unpause`

    return this.http.put<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>(url, rentalIds)
  }

  disable(rentalIds: string[], channelNameToken: ChannelNameTokens) {
    const prefix = this.getChannelPrefix(channelNameToken)
    const url = `@api/${prefix}/rentals/pause`

    return this.http.put<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>(url, rentalIds)
  }

  reviews(params: FetchChannelReviewsParams) {
    const url = `@api/reviews`
    return this.http.post<ChannelReviewResponse[]>(url, params)
  }

  reviewsCounts(params: FetchChannelReviewsParams, groupBy = 'source') {
    const url = `@api/reviews/count`
    return this.http.post<Record<string, { count: number }>>(url, { ...params, _group: groupBy })
  }

  getReview(id: string) {
    const url = `@api/reviews/${id}`
    return this.http.get<ChannelReviewResponse>(url)
  }

  replyReview(id: string, response: string) {
    const url = `@api/reviews/${id}`
    return this.http.put<ChannelReviewResponse>(url, { reviewee_response: response })
  }

  reviewMonthlyRating(params: FetchChannelReviewsParams) {
    const url = `@api/reviews/rating/recommend`
    return this.http.post<ReviewMonthlyRatingResponse>(url, params)
  }

  airbnbReviewRatingByCategory(params: FetchChannelReviewsParams) {
    const url = `@api/reviews/rating/category`
    return this.http
      .post<Record<AirbnbGuest2HostReviewCategories, { avg_rating: number }>>(url, { ...params, source: 'airbnb' })
      .pipe(map((res) => lodash.mapValues(res, (t) => ({ ...t, avg_rating: parseFloat(t.avg_rating as any) }))))
  }

  reviewResponseCounts(params: FetchChannelReviewsParams) {
    const url = `@api/reviews/rating/responded`
    return this.http.post<Record<'yes' | 'no', { count: number }>>(url, params)
  }

  reviewAvgRating(params: FetchChannelReviewsParams) {
    const url = `@api/reviews/rating/avg_rating`
    return this.http.post<{ count: number; avg: number }>(url, params)
  }

  setAirbnbChannelConfig(channelId: string, payload: AirbnbChannelConfig, params?: { to_service?: '1' }) {
    const url = `@api/abbv2/oauth/${channelId}/config`
    return this.http.post(url, payload, { params })
  }

  getAirbnbChannelConfig(channelId: string, params?: { service_only?: '1' }) {
    const url = `@api/abbv2/oauth/${channelId}/config`
    return this.http.get<AirbnbChannelConfig>(url, { params })
  }
}
