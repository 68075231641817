export enum RoomType {
  BEDROOM = 'bedroom',
  BATHROOM = 'bathroom',
  KITCHEN = 'kitchen',
  LIVING = 'living',
  GENERAL = 'general',
}

export enum RoomSubType {
  MASTER = 'master',
  CHILDREN = 'children',
  GUEST = 'guest',
  FULL = 'full',
  HALF = 'half',
  THREE_QUARTER = 'three_quarter',
  QUARTER = 'quarter',
  KITCHENETTE = 'kitchenette',
  BED_IN_LIVINGROOM = 'bed_in_livingroom',
}

export enum RoomSizeMetric {
  SQFT = 'SQFT',
  SQM = 'SQM',
}

export type BedType =
  | 'TWIN_BED'
  | 'TWIN_XL_BED'
  | 'SINGLE_BED'
  | 'SINGLE_XL_BED'
  | 'FULL_BED'
  | 'FULL_XL_BED'
  | 'DOUBLE_BED'
  | 'QUEEN_NA_BED'
  | 'QUEEN_EU_BED'
  | 'CAL_KING_BED'
  | 'KING_NA_BED'
  | 'KING_EU_BED'
  | 'SUPER_KING_BED'
  | 'GRAND_KING_BED'
  | 'BUNK_BED'
  | 'SOFA_BED'
  | 'MURPHY_BED'
  | 'TODDLER_BED'
  | 'FUTON'
  | 'CRIB'
  | 'AIR_MATTRESS'
  | 'FLOOR_MATTRESS'

export interface Room {
  pkey: string
  account: number
  archived?: number
  created?: number

  rental_id: string
  bathroom?: string
  type?: RoomType
  sub_type?: RoomSubType
  name?: string
  description?: string
  size?: number
  beds?: BedType[]
  size_metric?: RoomSizeMetric
}

export type CreateRoomPayload = Omit<Room, 'pkey' | 'account' | 'created' | 'archived'>
export type UpdateRoomPayload = CreateRoomPayload

export function isBedsInRoom(type: RoomType, subType: RoomSubType) {
  return type === RoomType.BEDROOM || (type === RoomType.LIVING && subType === RoomSubType.BED_IN_LIVINGROOM)
}
