import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Account, Channel, Rental, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { SubmitForm, SubmitFormFailure, SubmitFormSuccess } from '../../store/channels.actions'
import { HomeToGoConnectionForm } from '../../store/channels.model'
import { Actions, ofType } from '@ngrx/effects'
import { HometogoConnectionFormAbstractComponent } from './hometogo-connection-form.abstract.component'

@Component({
  selector: 'app-hometogo-connection-form',
  templateUrl: './hometogo-connection-form.component.html',
  styleUrls: ['./hometogo-connection-form.component.scss'],
})
export class HomeToGoConnectionFormComponent extends HometogoConnectionFormAbstractComponent implements OnInit {
  @Input() channel: Channel
  @Input() account: Account
  @Input() rentals: Rental[]

  @Output() submit = new EventEmitter()

  submitting = false

  steps = {
    form: 1,
    config: 2,
  }

  step = 1
  config = {
    status: 0,
    payment_terms: '',
    payment_conditions: '',
    cancellation_policy: '',
    taxes: [],
    fees: [],
  }

  formPayload: HomeToGoConnectionForm

  constructor(fb: FormBuilder, actions: Actions, store: Store<any>) {
    super(fb, actions, store)
  }

  ngOnInit() {
    this.init()

    this.actions.pipe(ofType(SubmitFormSuccess.type), untilDestroy(this)).subscribe(() => {
      this.submitting = false
      this.submit.emit()
    })

    this.actions.pipe(ofType(SubmitFormFailure.type), untilDestroy(this)).subscribe(() => {
      this.submitting = false
    })
  }

  onConfigSave(payload) {
    this.store.dispatch(SubmitForm({ form: this.formPayload, ...payload }))
  }

  @SaveForm()
  onFinish(form: FormGroup) {
    this.formPayload = this.getFormData(form)
    this.step = this.steps.config
  }
}
