import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, tap, toArray } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { SSLService } from './ssl.service'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import {
  GetOrCreateCustomDomain,
  GetOrCreateCustomDomainComplete,
  GetCustomDomain,
  GetCustomDomainComplete,
  DeleteCustomDomain,
  DeleteCustomDomainComplete,
  LoadCustomDomains,
  LoadCustomDomainsComplete,
  SetCustomDomainCerts,
  SetCustomDomainCertsComplete,
  RequestCustomDomainCerts,
  RequestCustomDomainCertsComplete,
  DeleteCustomDomainCerts,
  DeleteCustomDomainCertsComplete,
} from './ssl.actions'

@Injectable()
export class CustomDomainEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadCustomDomains),
    mergeMap(() =>
      this.sslService.getDomains().pipe(
        map((items) => LoadCustomDomainsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  get$ = this.actions$.pipe(
    ofType(GetCustomDomain),
    mergeMap(({ id }) =>
      this.sslService.getDomainById(id).pipe(
        map((res) => GetCustomDomainComplete({ item: res })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getOrCreate$ = this.actions$.pipe(
    ofType(GetOrCreateCustomDomain),
    mergeMap(({ id }) =>
      this.sslService.createOrGetDomain(id).pipe(
        map((res) => GetOrCreateCustomDomainComplete({ item: res })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteCustomDomain),
    concatMap(({ id }) =>
      this.sslService.deleteDomain(id).pipe(
        map((res) => DeleteCustomDomainComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  requestSSL$ = this.actions$.pipe(
    ofType(RequestCustomDomainCerts),
    concatMap(({ id }) =>
      this.sslService.requestCertificate(id).pipe(
        map((res) => RequestCustomDomainCertsComplete({ item: res })),
        tap(() => this.toaster.success('SSL requested successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  setSSL$ = this.actions$.pipe(
    ofType(SetCustomDomainCerts),
    concatMap(({ id, payload }) =>
      this.sslService.setCustomCertificate(id, payload).pipe(
        map((res) => SetCustomDomainCertsComplete({ item: res })),
        tap(() => this.toaster.success('SSL set successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteSSL$ = this.actions$.pipe(
    ofType(DeleteCustomDomainCerts),
    concatMap(({ id }) =>
      this.sslService.deleteCertificate(id).pipe(
        map((res) => DeleteCustomDomainCertsComplete({ item: res })),
        tap(() => this.toaster.success('SSL removed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private toaster: Toaster, private sslService: SSLService) {}
}
