export enum HoldEventType {
  Other,
  Personal,
  Maintenance,
  Pending,
  Confirmed,
}

export const holdEventTypes = [
  { id: HoldEventType.Other, name: 'Other' },
  { id: HoldEventType.Personal, name: 'Personal' },
  { id: HoldEventType.Maintenance, name: 'Maintenance' },
  { id: HoldEventType.Pending, name: 'Pending' },
  { id: HoldEventType.Confirmed, name: 'Confirmed' },
]
