import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Destroyable, untilDestroy, UpdateUserAttributes, User } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'

@Component({
  selector: 'app-staff-permissions',
  templateUrl: './staff-permissions.component.html',
  styleUrls: ['./staff-permissions.component.scss'],
})
export class StaffPermissionsComponent extends Destroyable implements OnInit {
  @Input() user: User

  form = this.fb.group({
    guest_name: [true],
    guest_email: [false],
    guest_phone: [false],
  })

  constructor(private fb: FormBuilder, private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    const settings = R.pathOr({ guest_name: true }, ['staff_settings'], this.user.attributes)
    this.form.patchValue(settings)

    this.form.valueChanges.pipe(untilDestroy(this)).subscribe((values) => {
      this.store.dispatch(
        UpdateUserAttributes({
          userId: this.user.id,
          attributes: {
            ...this.user.attributes,
            staff_settings: values,
          },
        })
      )
    })
  }
}
