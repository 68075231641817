import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { epochToView } from '@tokeet-frontend/tv3-platform'
import { Billing, BillingDto } from './billing.model'
import { isEmpty } from 'lodash'

@Injectable()
export class BillingService {
  constructor(private http: HttpClient) {}

  setupIntent() {
    const url = `@api/subscribe/setup`

    return this.http.get<{ client_secret: string }>(url)
  }

  charges(): Observable<Billing[]> {
    return this.http.get<BillingDto[]>(`@api/subscribe/charges`).pipe(
      map((response) => response.filter((r) => r.id)),
      map((billings) =>
        billings.map((b) => {
          const name = (b.card.name + '').replace(/\:/g, ' ')
          return {
            ...b,
            createdView: epochToView(b.created),
            amountView: b.refunded ? -1 * (b.amount / 100) : b.amount / 100,
            cardView: isEmpty(b.card)
              ? ''
              : `${name} ending in ${b.card.last4} expiring on ${b.card.exp_month}/${b.card.exp_year}`,
            statusView: `Status (${b.status}), Captured (${b.captured}), Paid (${b.paid}), Refunded (${b.refunded})`,
          } as Billing
        })
      )
    )
  }
}
