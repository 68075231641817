import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Message } from '@tv3/store/message/message.model'
import { MoveMessageComponent } from './move-message.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Injectable({
  providedIn: SharedModule,
})
export class MoveMessageDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: any = { message: Message, inquiry: Inquiry }) {
    let defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data,
    }

    return this.dialog.open(MoveMessageComponent, defaultConfig)
  }
}
