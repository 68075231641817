import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { TaskStatusView, TaskView } from '@tv3/store/task/task.model'

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
})
export class TaskStatusComponent implements OnInit {
  @Input() task: TaskView

  @HostBinding('class') get hostClasses() {
    let classes = ''
    switch (this.task.statusView) {
      case TaskStatusView.Completed:
        classes = 'badge-secondary-success'
        break
      case TaskStatusView.PastDue:
        classes = 'badge-secondary-danger'
        break
      case TaskStatusView.InProgress:
        classes = 'badge-secondary-info'
        break
      case TaskStatusView.DueToday:
        classes = 'badge-secondary-warning'
        break
      case TaskStatusView.Pending:
      default:
        classes = 'badge-secondary-light'
    }

    return `badge ${classes}`
  }

  constructor() {}

  ngOnInit(): void {}
}
