import { Component, EventEmitter, Input, Output } from '@angular/core'
import * as lodash from 'lodash'
import { FormFieldComponent } from '../form-field/form-field.component'
import { FormControl } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { ENTER, COMMA } from '@angular/cdk/keycodes'
import { map, startWith } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-form-tags2',
  templateUrl: './form-tags2.component.html',
  styleUrls: ['../form-field-common.scss', './form-tags2.component.scss'],
  host: { class: 'form-field-tags2' },
})
export class FormTags2Component extends FormFieldComponent {
  @Input() items: string[] = [] // available tags
  @Input() searchable = true
  @Input() searchPlaceholder = 'Search...'
  @Input() clearable = false
  @Input() addTag = true
  @Input() caseSensitive = false
  @Input() placeholder: string = 'Type to pick or create tag'

  separatorKeysCodes: number[] = [ENTER, COMMA]

  tagCtrl = new FormControl([])
  filteredTags$: Observable<string[]>

  @Output() search = new EventEmitter<string>()

  ngOnInit(): void {
    super.ngOnInit()
    this.filteredTags$ = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((term) => this.filterTags(term))
    )
  }

  filterTags(term: string | null) {
    term = lodash.trim(term)
    if (!term) return this.items?.slice()
    const reg = new RegExp(lodash.escapeRegExp(term), 'g' + (this.caseSensitive ? '' : 'i'))
    return this.items?.filter((t) => t.search(reg) > -1)
  }

  add(term: string) {
    term = lodash.trim(term)
    if (!term) {
      return
    }

    this.ctrl.setValue(lodash.uniqBy([...this.ctrl.value, term], (t) => (this.caseSensitive ? t : lodash.toLower(t))))
    this.tagCtrl.reset()
  }

  remove(term: string) {
    this.ctrl.setValue(lodash.filter(this.ctrl.value, (t) => !this.isEqual(t, term)))
  }

  isEqual(a: string, b: string) {
    a = this.caseSensitive ? a : lodash.toLower(a)
    b = this.caseSensitive ? b : lodash.toLower(b)

    return a === b
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    const term = event.option.viewValue
    this.add(term)
  }

  onSelectDropdownOpen() {
    this.focused = true
  }

  onSelectDropdownClose() {
    this.focused = false
  }
}
