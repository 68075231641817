<div class="row" [formGroup]="form">
  <div class="col-md-3">
    <mat-form-field2>
      <input matInput placeholder="Days" formControlName="days" type="number" pattern="^-?(0|[1-9]\d*)?$" />
      <mat-error *ngIf="form.get('days').touched && form.get('days').hasError('required')">
        Days are <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('days').touched && form.get('days').hasError('min')">
        Days need to be at least <strong>0</strong>
      </mat-error>
      <mat-error *ngIf="form.get('days').hasError('pattern')"> Only whole numbers allowed </mat-error>
    </mat-form-field2>
  </div>
  <div class="col-md-3">
    <mat-form-field2>
      <input matInput placeholder="Hours" formControlName="hours" type="number" pattern="^-?(0|[1-9]\d*)?$" />
      <mat-error *ngIf="form.get('hours').hasError('required')"> Hours are <strong>required</strong> </mat-error>
      <mat-error *ngIf="form.get('hours').hasError('min')"> Hours need to be at least <strong>0</strong> </mat-error>
      <mat-error *ngIf="form.get('hours').hasError('max')"> Hours need to be at most <strong>24</strong> </mat-error>
      <mat-error *ngIf="form.get('hours').hasError('pattern')"> Only whole numbers allowed </mat-error>
    </mat-form-field2>
  </div>
  <div class="col-md-6">
    <mat-form-field2 class="mat-block">
      <mat-select placeholder="Time event type" formControlName="event">
        <mat-option *ngFor="let event of timeEvents" [value]="event.event">
          {{ event.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('event').touched && form.get('event').hasError('required')">
        Time event type is <strong>required</strong>
      </mat-error>
    </mat-form-field2>
  </div>
</div>
