<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2
        class="mat-block"
        matTooltip="The ID of the legal entity to which the property belongs."
        matTooltipPosition="above"
      >
        <input matInput placeholder="Legal Entity ID" type="text" formControlName="legalEntityId" />
      </mat-form-field2>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2
        class="mat-block"
        matTooltip="The property licence number, as provided by the local authority."
        matTooltipPosition="above"
      >
        <input
          matInput
          [placeholder]="
            'Property license number' +
            (checkLicenseResult?.format_example ? ': ' + checkLicenseResult?.format_example : '')
          "
          type="text"
          formControlName="licenseNumber"
          [required]="checkLicenseResult?.required && !form.get('noLicenseRequired').value"
        />
        <mat-error *ngIf="form.get('licenseNumber').touched && form.get('licenseNumber').hasError('required')">
          License number is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('licenseNumber').touched && form.get('licenseNumber').hasError('pattern')">
          License number is <strong>invalid</strong>
        </mat-error>
      </mat-form-field2>
    </div>
  </div>
  <div class="row" *ngIf="rental?.address?.countryCode === 'JP'">
    <div class="col-sm-4">
      <mat-form-field2
        class="mat-block"
        matTooltip="The property licence issue date, for properties in Japan."
        matTooltipPosition="above"
      >
        <input
          matInput
          placeholder="License issue date(YYYY-MM-DD: 2019-03-12)"
          type="text"
          formControlName="licenseIssueDate"
        />
      </mat-form-field2>
    </div>
    <div class="col-sm-4">
      <mat-form-field2
        class="mat-block"
        matTooltip="The property licence type, for properties in Japan."
        matTooltipPosition="above"
      >
        <mat-select formControlName="licenseType" placeholder="License type">
          <mat-option value="Minpaku Shinpo License">Minpaku Shinpo License</mat-option>
          <mat-option value="Minpaku Tokku License">Minpaku Tokku License</mat-option>
          <mat-option value="Event Minpaku License">Event Minpaku License</mat-option>
          <mat-option value="Ryokan License">Ryokan License</mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
  </div>
  <div class="row" *ngIf="checkLicenseResult?.can_agree_to_no_license">
    <div class="col-sm-6">
      <mat-checkbox
        formControlName="noLicenseRequired"
        (change)="updateLicenseValidity()"
        matTooltip="If a property is exempt from providing a license number, you should check this"
        matTooltipPosition="above"
        class="mt-primary"
      >
        No License Required
      </mat-checkbox>
    </div>
  </div>
  <div *ngIf="checkLicenseResult?.explanation_url" class="my-3">
    <a [href]="checkLicenseResult.explanation_url" target="_blank">View Explanation</a>
  </div>
</form>
