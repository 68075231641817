<app-form-switch
  *ngIf="view === 'switch'"
  #toggle
  [inline]="true"
  [label]="label"
  (valueChange)="onToggleAI(toggle.checked, toggle)"
  [value]="!isAIActive()"
  [disabled]="isAISubInactive"
  [matTooltip]="!isAISubInactive ? toggleTooltip : 'Go to Appstore to subscribe for this'"
  [matTooltipPosition]="tooltipPosition"
>
  <ng-content></ng-content>
</app-form-switch>

<button
  class="btn btn-outline-ai btn-sm btn-pill px-3"
  *ngIf="view === 'button'"
  (click)="onToggleAI(isAIActive())"
  [class.active]="isAIActive()"
  [disabled]="isAISubInactive"
  [matTooltip]="!isAISubInactive ? toggleTooltip : 'Go to Appstore to subscribe for this'"
  [matTooltipPosition]="tooltipPosition"
>
  <i class="cs-icon cs-icon-ai"></i> {{ label || 'Advance Intelligence' }}
  <ng-content></ng-content>
</button>
