import { InventoryView } from './model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<InventoryView> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<InventoryView> = createEntityAdapter<InventoryView>({
  sortComparer: (a, b) => a.created - b.created,
  selectId: (t: InventoryView) => t.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
