import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Card } from './card.model'

export interface State extends EntityState<Card> {
  loaded: boolean
}

export const adapter: EntityAdapter<Card> = createEntityAdapter<Card>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  loaded: false,
})
