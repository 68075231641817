<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-money-check"></i> Update Quote Details</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-update-quote-external
    *ngIf="external || !data.inquiry?.bookingEngine"
    [inquiry]="data.inquiry"
    (close)="close()"
  ></app-update-quote-external>
  <app-update-quote-internal
    *ngIf="!external && !!data.inquiry?.bookingEngine"
    [inquiry]="data.inquiry"
    (close)="close()"
  ></app-update-quote-internal>
</div>
