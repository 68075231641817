<div class="row">
  <div class="col-sm-12">
    <button (click)="back.emit(true)" class="btn-as-link" type="button">
      <i class="fal fa-angle-left"></i> Back to all photos
    </button>
  </div>
</div>
<form [formGroup]="form" class="clearfix">
  <div class="edit-wrapper">
    <div class="edit-left">
      <div class="photo-box">
        <div class="photo-frame">
          <div [style.background-image]="'url(' + photo.extra_large_url + ')'" class="photo"></div>
        </div>
      </div>
    </div>
    <div class="edit-right">
      <h5>Caption</h5>
      <div style="display: block; width: 100%">
        <mat-form-field2 class="mat-block" appearance="outline">
          <textarea
            matInput
            maxlength="250"
            rows="8"
            formControlName="caption"
            placeholder="Add details about this photo..."
            cdkTextareaAutosize
          ></textarea>
          <mat-hint align="end">{{ form.get('caption').value?.length || 0 }}/250</mat-hint>
        </mat-form-field2>
      </div>
      <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">Save</button>
      <button (click)="onDelete()" type="button" class="btn btn-secondary-danger">Delete</button>
    </div>
  </div>
</form>
