import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'app-loading-section',
  template: `<i class="far fa-spinner fa-spin"></i>`,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
      }
    `,
  ],
})
export class LoadingSectionComponent {
  @HostBinding('style.color')
  @Input()
  color = 'var(--info)'

  @HostBinding('style.font-size')
  @Input()
  size = '50px'
}
