import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators'
import { ConfirmDialogService, DataCheckerService, DataEntityType, SaveForm } from '@tokeet-frontend/tv3-platform'
import {
  AddAttributeDescription,
  AttributeDescriptionGuard,
  AttributeFieldDef,
  AttributeFieldType,
  DeleteAttributeDescription,
  selectAttributeDescriptionByEntityType,
} from '../../store/attr-desc'
import * as lodash from 'lodash'
import {
  AttributeDescriptionResponse,
  CreateAttributeDescriptionPayload,
  attributeFieldTypes,
} from '../../store/attr-desc/model'

@Component({
  selector: 'app-entity-attributes-config-overlay',
  templateUrl: './entity-attributes-config-overlay.component.html',
  styleUrls: ['./entity-attributes-config-overlay.component.scss'],
})
export class EntityAttributesConfigOverlayComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    entity_type: [],
    attributes: this.fb.array([this.createAttr()]),
  })

  get attributesArrayForm() {
    return this.form.get('attributes') as FormArray
  }

  attrDescription: AttributeDescriptionResponse | undefined
  fieldTypes = attributeFieldTypes
  entityTypes = Object.values(DataEntityType)

  destroy$ = new Subject<boolean>()

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private dataCheckerService: DataCheckerService,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<EntityAttributesConfigOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entityType: DataEntityType }
  ) {
    this.dataCheckerService.check([AttributeDescriptionGuard])
  }

  ngOnInit() {
    this.form
      .get('entity_type')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        switchMap((entityType) => this.store.pipe(select(selectAttributeDescriptionByEntityType(entityType)))),
        takeUntil(this.destroy$)
      )
      .subscribe((desc) => {
        this.attrDescription = desc

        const fields = this.attrDescription?.attribute_fields || []

        this.attributesArrayForm.clear()
        if (!lodash.isEmpty(fields)) {
          lodash.forEach(fields, (t: AttributeFieldDef) => this.attributesArrayForm.push(this.createAttr(t)))
        } else {
          this.attributesArrayForm.push(this.createAttr())
        }
      })

    this.form.patchValue({ entity_type: this.data.entityType })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  close() {
    this.dialogRef.close()
  }

  addAttr(attr?: AttributeFieldDef) {
    this.attributesArrayForm.push(this.createAttr(attr))
  }

  removeAttr(index: number) {
    const attr = this.attributesArrayForm.at(index).value
    if (!attr.key || !this.attrDescription) {
      this.attributesArrayForm.removeAt(index)
      return
    }
    this.confirmDialog
      .confirm({
        title: 'Delete attribute',
        body: `Are you sure you want to delete the attribute ${attr.label}?`,
      })
      .subscribe((result) => {
        this.store.dispatch(DeleteAttributeDescription({ entityId: this.attrDescription?.pkey, id: attr.key }))
        this.attributesArrayForm.removeAt(index)
      })
  }

  getExistingAttrIds(ignoreAttrForm: FormGroup) {
    const items = this.attributesArrayForm.controls.filter((f) => f !== ignoreAttrForm)
    return items.map((t) => (t.disabled ? t.value?.attribute : lodash.snakeCase(t.value?.label)))
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { attributes, entity_type } = form.getRawValue()
    const items = lodash.map(
      lodash.filter(attributes, (t) => !t.key),
      (attr) => {
        return {
          ...lodash.omit(attr, 'key'),
          attribute: lodash.snakeCase(attr.label),
        } as CreateAttributeDescriptionPayload
      }
    )
    this.store.dispatch(AddAttributeDescription({ entityType: entity_type, payload: items }))
    this.close()
  }

  private createAttr(attr?: AttributeFieldDef) {
    const form = this.fb.group({
      key: [attr?.key],
      field_type: [attr?.field_type || AttributeFieldType.Text, [Validators.required]],
      description: [attr?.description],
      label: [attr?.label],
      attribute: [attr?.attribute],
    })
    form.get('label')?.setValidators([
      Validators.required,
      (ctrl: FormControl) => {
        const value = ctrl.value
        if (!value) {
          return null
        }
        if (this.getExistingAttrIds(form).includes(lodash.snakeCase(value))) {
          return { duplicated: true }
        }
        return null
      },
    ])
    if (attr?.key) {
      form.disable()
    }

    return form
  }
}
