import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { CalendarEvent } from '@tv3/store/calendar/calendar.model'
import * as R from 'ramda'
import * as moment from 'moment'
import { Actions, ofType } from '@ngrx/effects'
import {
  InquiryIsUpdated,
  CancelBookingComplete,
  ConfirmBookingComplete,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { debounceTime, finalize } from 'rxjs/operators'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-occupied-dates',
  templateUrl: './occupied-dates.component.html',
  styleUrls: ['./occupied-dates.component.scss'],
})
export class OccupiedDatesComponent extends Destroyable implements OnInit, OnChanges {
  @Input() inquiry: Inquiry

  rentalId: string

  isLoading = true
  events: CalendarEvent[] = []
  guestArrive: Date

  constructor(private store: Store<fromRoot.State>, private actions: Actions, private calendar: CalendarService) {
    super()
  }

  ngOnInit() {
    this.actions
      .pipe(
        ofType(InquiryIsUpdated, CancelBookingComplete, ConfirmBookingComplete),
        debounceTime(150),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.refresh()
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.rentalId !== this.inquiry.rentalId) {
      this.rentalId = this.inquiry.rentalId
      this.refresh()
    }
  }

  refresh() {
    this.isLoading = true
    this.calendar
      .byRental(this.rentalId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((events) => {
        this.events = events
        this.setGuestArriveDate(this.inquiry)
      })
  }

  private setGuestArriveDate(inquiry: Inquiry) {
    this.guestArrive = R.is(Number, inquiry.guestArrive)
      ? moment.utc(inquiry.guestArrive * 1000).toDate()
      : moment.utc(inquiry.guestArrive).toDate()
  }
}
