import { Pipe, PipeTransform } from '@angular/core'
import { GapNightAutomation } from '@tokeet-frontend/rate-automations'
import * as lodash from 'lodash'

@Pipe({
  name: 'gapNightDec',
})
export class GapNightDecPipe implements PipeTransform {
  transform(item: GapNightAutomation): string {
    return lodash
      .chain(item.gaps)
      .map(
        (t) =>
          `Gap Nights: ${t.gaps_required} , Min Nights: ${this.getMinNights(t.min_nights)}, Adjustment: ${
            t.adjust_abs || `${t.adjust_pct}%`
          }.`
      )
      .join(', ')
      .value()
  }

  getMinNights(min: any) {
    min = min.toString()
    if (min.startsWith('g')) {
      return 'Length of gap' + (min.length === 1 ? '' : ` - ${min.slice(1)}`)
    } else {
      return min
    }
  }
}
