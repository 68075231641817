<div class="login-page">
  <div class="panel form-panel">
    <ng-content></ng-content>

    <div class="links">
      <a href="//www.tokeet.com/terms.html" target="_blank">Terms of Service</a> |
      <a href="//www.tokeet.com/privacy.html" target="_blank">Privacy Policy</a>
    </div>
  </div>

  <div class="panel intro-panel">
    <img [src]="imageUrl || '/assets/images/misc/login.png'" alt="" style="max-height: 300px" />
    <div class="intro-text text-header">
      Access the AdvanceCM suite of products with a single username and password.
    </div>
    <div class="intro-text">
      AdvanceCM is on a mission to simplify short-term rental management, with the most powerful platform on the market
      and a suite of robust tools for every step of the guest experience. Grow your business with AdvanceCM by doing
      more with less.
    </div>

    <div class="logos">
      <a href="//www.tokeet.com" target="_blank">
        <img alt="AdvanceCM Logo" src="/assets/images/logo/tokeet-light.png" />
      </a>
      <a href="//www.usesignature.com" target="_blank">
        <img alt="Signature Logo" src="/assets/images/logo/signature-light.png" />
      </a>
      <a href="//useautomata.com" target="_blank">
        <img alt="Automata Logo" src="/assets/images/logo/automata-light.png" />
      </a>
      <a href="//rategenie.io" target="_blank">
        <img alt="Rate Genie Logo" src="/assets/images/logo/rategenie-light.png" />
      </a>
      <a href="//www.ownercenter.net" target="_blank">
        <img alt="Owner Center Logo" src="/assets/images/logo/owners-light.png" />
      </a>
      <a href="//www.tokeet.com/reports.html" target="_blank">
        <img alt="Margins Logo" src="/assets/images/logo/margins-light.png" />
      </a>
      <a href="//usechecklist.com" target="_blank">
        <img alt="Checklist Logo" src="/assets/images/logo/checklist-light.png" />
      </a>
      <a href="//sympl.cm" target="_blank">
        <img alt="Sympl Logo" src="/assets/images/logo/sympl-light.png" />
      </a>
      <a href="//usewebready.com" target="_blank">
        <img alt="Webready Logo" src="/assets/images/logo/webready-light.png" />
      </a>
    </div>
  </div>
</div>
