import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Itinerary } from './itinerary.model'
import { Observable, throwError } from 'rxjs'
import { deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '../state'
import { Store } from '@ngrx/store'
import { catchError, map, tap } from 'rxjs/operators'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Flight } from '@tv3/models/itinerary/flight'

export type ItineraryUpdateType = 'add' | 'edit' | 'delete'

@Injectable({
  providedIn: 'root',
})
export class ItineraryService {
  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private toast: Toaster) {}

  all(inquiryId: string): Observable<Itinerary[]> {
    const url = `@api/itinerary/booking/${inquiryId}`

    return this.http.get(url).pipe(deserializeArray<Itinerary>(Itinerary))
  }

  create(inquiry: Inquiry, flight: Flight): Observable<Itinerary> {
    const url = '@api/itinerary/'

    const request = {
      booking_id: inquiry.id,
      flights: [flight.serialize()],
    }

    return this.http.post(url, request).pipe(
      map((response) => Itinerary.deserialize(response)),
      tap(() => this.toast.success('Flight added successfully!'))
    )
  }

  update(itinerary: Itinerary, updateType: ItineraryUpdateType): Observable<Itinerary> {
    const url = `@api/itinerary/update/${itinerary.id}`

    return this.http.put(url, itinerary.serialize()).pipe(
      map((response) => Itinerary.deserialize(response)),
      tap(() => {
        switch (updateType) {
          case 'add':
            this.toast.success('Flight added successfully!')
            break
          case 'edit':
            this.toast.success('Flight updated successfully!')
            break
          case 'delete':
            this.toast.success('Flight deleted successfully!')
            break
        }
      }),
      catchError(() => {
        switch (updateType) {
          case 'add':
            return throwError({ error: 'Unable to add flight' })
          case 'edit':
            return throwError({ error: 'Unable to update flight' })
          case 'delete':
            return throwError({ error: 'Unable to delete flight' })
        }
      })
    )
  }
}
