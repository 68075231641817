import { Account, Channel, countries, Destroyable, Rental } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as R from 'ramda'
import { Store } from '@ngrx/store'
import { HomeToGoConnectionForm } from '../../store/channels.model'
import { Actions } from '@ngrx/effects'
import { validateMinNumber } from '@tokeet-frontend/tv3-platform'

export abstract class HometogoConnectionFormAbstractComponent extends Destroyable {
  channel: Channel
  account: Account
  rentals: Rental[]

  countryList = countries

  form = this.fb.group({
    companyName: ['', [Validators.required]],
    street: ['', [Validators.required]],
    zip: ['', [Validators.required]],
    city: ['', [Validators.required]],
    countryCode: ['', [Validators.required]],
    vat: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    website: [''],
    rentalCount: ['', [Validators.required, validateMinNumber(1)]],
    location: ['', [Validators.required]],
    additional: [''],
    terms: [false, [Validators.required]],
  })

  constructor(protected fb: FormBuilder, protected actions: Actions, protected store: Store<any>) {
    super()
  }

  getFormData(form: FormGroup): HomeToGoConnectionForm {
    return {
      ...R.omit(['terms'], form.getRawValue()),
      country: this.getCountryNameById(this.form.get('countryCode').value),
    } as HomeToGoConnectionForm
  }

  init() {
    this.prepareForm()
  }

  private prepareForm() {
    const rentalsWithCountry = R.reject((r: Rental) => !r.address.country, this.rentals)
    const countryGroups = R.groupBy((r: Rental) => r.address.country, rentalsWithCountry)
    const percentageGroups = []
    R.forEachObjIndexed((value, key) => {
      const percentage = Math.floor((value.length / rentalsWithCountry.length) * 100)
      percentageGroups.push(`${key} ${percentage}%`)
    }, countryGroups)
    const address = this.account.address
    this.form.patchValue({
      companyName: this.account.name,
      street: address && address.address,
      zip: address && address.postalCode,
      city: address && address.city,
      countryCode: address && address.countryCode,
      vat: this.account.vatTin,
      email: this.account.email,
      phone: this.account.phone,
      rentalCount: this.rentals.length || 1,
      location: percentageGroups.join(', '),
    })
  }

  getCountryNameById(id: string) {
    const country = R.find((country: { id: string; name: string }) => country.id === id, this.countryList)
    if (country) {
      return country.name
    }
  }
}
