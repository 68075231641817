import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './reducer'
import * as state from './state'
import * as R from 'ramda'
import { IncidentView } from './model'
import { categories } from './constants'
import {
  createdBySystemNameMappings,
  selectRentalEntities,
  selectUserEntities,
  userFullname,
} from '@tokeet-frontend/tv3-platform'

export const incidentState = createFeatureSelector<state.State>('incident')

export const selectAllIncidents = createSelector(incidentState, reducer.selectAll)

export const selectIncidentsLoaded = createSelector(incidentState, (s: state.State) => s.isLoaded)
export const isUpdatingIncidents = createSelector(incidentState, (s: state.State) => s.isUpdating)

export const selectIncidents = createSelector(
  selectAllIncidents,
  selectRentalEntities,
  selectUserEntities,
  (incidents, rentalEntities, usersById) => {
    return R.map((incident: IncidentView) => {
      const rental = rentalEntities[incident.rental_id]
      const reviewer = usersById[incident.reviewer_id]
      const reporter = usersById[incident.reporter_id]

      return {
        ...incident,
        rentalView: R.pathOr('', ['name'], rental),
        reporterView: reporter ? userFullname(reporter) : createdBySystemNameMappings[incident.reporter_id] || '',
        reviewerView: userFullname(reviewer),
        rental,
      }
    }, incidents) as IncidentView[]
  }
)

export const selectIncidentById = (id: string) =>
  createSelector(selectAllIncidents, (items) => {
    return items?.find((item) => item.pkey === id)
  })

export const selectAllIncidentCategories = createSelector(selectAllIncidents, (incidents) => {
  const result = R.uniq(R.concat(categories, R.flatten(R.map((incident) => incident.categories, incidents))))

  return R.pipe(
    R.reject(R.isNil),
    R.map(R.pipe(R.when(R.is(Number), R.toString))),
    R.uniq,
    R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(result)
})
