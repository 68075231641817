import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'
import { AlertDialogParams } from './alert-dialog.interfaces'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable()
export class AlertDialogService {
  constructor(private dialog: MatDialog) {}

  // call subscribe inside
  public open(data: AlertDialogParams) {
    return this.openResult(data).subscribe()
  }

  // call subscribe outside
  public openResult(data: AlertDialogParams) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: data,
      disableClose: true,
      panelClass: ['fixed-modal'],
    }

    return this.dialog
      .open<AlertDialogComponent, AlertDialogParams, 'extra' | null>(AlertDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(take(1))
  }
}
