import { Preference } from './preferences.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Preference> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Preference> = createEntityAdapter<Preference>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
