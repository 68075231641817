<div class="container-box">
  <div class="filters-box">
    <mat-form-field2 [showLabel]="false" matTooltip="Only show booking formulas with the selected status.">
      <mat-select placeholder="Status" [formControl]="filters.get('status')">
        <mat-option value="">All</mat-option>
        <mat-option value="active">Active</mat-option>
        <mat-option value="inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field2>
    <app-select-search
      bindValue="id"
      bindLabel="name"
      [multiple]="true"
      [items]="rentals"
      [ctrl]="filters.get('rentals')"
      placeholder="Rentals"
      matTooltip="Only show booking formulas with the selected rental(s)."
    >
    </app-select-search>
    <app-select-search
      *ngIf="!channel"
      bindValue="id"
      bindLabel="name"
      [multiple]="true"
      [items]="channels$ | async"
      [ctrl]="filters.get('channels')"
      placeholder="Channels"
      matTooltip="Only show booking formulas with the selected channel(s)."
    >
    </app-select-search>
    <app-cancel-filters
      [isFiltering]="filters | isFiltering: (channel ? ['channels'] : [])"
      [filterControl]="filters"
      [ignoreFields]="channel ? ['channels'] : []"
      [searchCom]="search"
    ></app-cancel-filters>
    <button class="btn btn-secondary-primary" (click)="onRefresh()" matTooltip="Refresh List">
      <i class="far fa-sync"></i>
    </button>
    <div class="flex-fill"></div>
    <app-table-search
      (search)="onSearch($event)"
      matTooltip="Search by name, channel or rental."
      #search
    ></app-table-search>
  </div>
  <mat-table #table matSort [dataSource]="dataSource" matSortActive="created" matSortDirection="desc">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Created</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.created | epoch }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate">{{ element.name }}</div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="channelView">
      <mat-header-cell *matHeaderCellDef> Channel</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate">
          {{ element.channelView }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rentalsView">
      <mat-header-cell *matHeaderCellDef> Rentals</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate">
          {{ element.rentalsView }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="months">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Months</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate">{{ element.months }}</div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <app-form-switch
          [id]="element.pkey"
          [value]="element.status === 1"
          (valueChange)="onToggleStatus(element)"
          [inline]="true"
        ></app-form-switch>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Edit</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
        <div dropdown placement="bottom right" container="body">
          <div dropdownToggle class="btn-group btn-ellips-holder">
            <a class="btn-ellips" matTooltip="You can perform multiple actions using this button">
              <i class="fas fa-ellipsis-v"></i>
            </a>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <li>
              <button
                (click)="onEdit(element)"
                type="button"
                matTooltip="Edit this booking formula."
                matTooltipPosition="left"
              >
                <i class="fal fa-edit"></i>Edit
              </button>
            </li>
            <li>
              <button
                (click)="onDelete(element)"
                type="button"
                matTooltip="Delete this booking formula."
                matTooltipPosition="left"
              >
                <i class="cs-icon cs-icon-trash"></i>Delete
              </button>
            </li>
          </ul>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEdit(row)" class="clickable"></mat-row>
  </mat-table>

  <mat-paginator2
    #paginator
    [length]="dataSource?.data?.length"
    [class.hidden]="!dataSource?.filteredData?.length"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>

  <app-empty-table
    *ngIf="isEmptyTable$ | async"
    [isFiltering]="(filters | isFiltering: (channel ? ['channels'] : [])) || !search.isEmpty"
    type="BookingFormulas"
  ></app-empty-table>
</div>
