import { adapter, initialState, State } from './shared-content.state'
import {
  LoadSharedContentComplete,
  RemoveSharedContentComplete,
  RemoveSharedContentsComplete,
  UpdateSharedContentComplete,
} from '@tv3/store/shared-content/shared-content.actions'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(RemoveSharedContentComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(UpdateSharedContentComplete, (state, { id, item }) =>
    adapter.updateOne(
      {
        id,
        changes: item,
      },
      state
    )
  ),
  on(LoadSharedContentComplete, (state, { contents }) =>
    adapter.addAll(contents, {
      ...state,
      isLoaded: true,
    })
  ),
  on(RemoveSharedContentsComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function sharedContentReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
