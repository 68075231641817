import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { DeleteNote, UpdateNote } from '@tv3/store/note/note.actions'
import { NoteResponse } from '@tv3/store/note/note.model'
import { GenericNoteDialog } from '../note-dialog.abstract'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Component({
  selector: 'app-edit-note-dialog',
  templateUrl: '../note-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['../note-dialog.component.scss'],
})
export class EditNoteDialogComponent extends GenericNoteDialog implements OnInit {
  isEdit = true

  constructor(
    public dialogRef: MatDialogRef<EditNoteDialogComponent>,
    public fb: FormBuilder,
    public store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry; note: NoteResponse }
  ) {
    super(dialogRef, fb)
  }

  ngOnInit() {
    this.form.patchValue({ note: this.data.note.note })
  }

  remove() {
    this.store.dispatch(DeleteNote({ inquiry: this.data.inquiry, note: this.data.note }))
    this.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(UpdateNote({ inquiry: this.data.inquiry, form: form.getRawValue(), note: this.data.note }))
    this.close()
  }
}
