import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { AirbnbListingWizardStep1Component } from './step1/airbnb-listing-wizard-step1.component'
import { AirbnbListingWizardStep2Component } from './step2/airbnb-listing-wizard-step2.component'
import { AirbnbListingWizardStep3Component } from './step3/airbnb-listing-wizard-step3.component'
import { AirbnbListingWizardComponent } from './airbnb-listing-wizard.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'
import { AirbnbListingWizardStep4Component } from './step4/airbnb-listing-wizard-step4.component'

const components = [
  //
  AirbnbListingWizardStep1Component,
  AirbnbListingWizardStep2Component,
  AirbnbListingWizardStep3Component,
  AirbnbListingWizardStep4Component,
  AirbnbListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class AirbnbListingWizardModule {}
