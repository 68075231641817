<mat-form-field2
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
  [class.invalid]="ngControl.control.touched && ngControl.control.invalid"
>
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList class="py-1 w-100" [formControl]="ctrl" [required]="required">
    <mat-chip *ngFor="let tag of ctrl.value" (removed)="remove(tag)">
      {{ tag }}
      <button matChipRemove class="mat-chip-btn">
        <i class="far fa-times"></i>
      </button>
    </mat-chip>
    <input
      *ngIf="addTag"
      [placeholder]="ctrl.value?.length ? '' : placeholder"
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event.value)"
      class="tag-input"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)">
    <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="ctrl.touched && ctrl.hasError('required')"> Tags is required </mat-error>
</mat-form-field2>
