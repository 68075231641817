import { createAction, props } from '@ngrx/store'
import { MessagingChannelKeys, MessagingChannelsConfig } from './model'

export const LoadMessagingChannelConfigs = createAction('[MessagingChannel] Load Configs')
export const LoadMessagingChannelConfigsComplete = createAction(
  '[MessagingChannel] Load Configs Complete',
  props<{ data: MessagingChannelsConfig }>()
)

export const EnableMessagingChannel = createAction(
  '[MessagingChannel] Enable Channel',
  props<{ channel: MessagingChannelKeys }>()
)
export const EnableMessagingChannelComplete = createAction(
  '[MessagingChannel] Enable Channel Complete',
  props<{ channel: MessagingChannelKeys }>()
)

export const DisableMessagingChannel = createAction(
  '[MessagingChannel] Disable Channel',
  props<{ channel: MessagingChannelKeys }>()
)
export const DisableMessagingChannelComplete = createAction(
  '[MessagingChannel] Disable Channel Complete',
  props<{ channel: MessagingChannelKeys }>()
)

export const DisconnectMessagingChannel = createAction(
  '[MessagingChannel] Disconnect Channel',
  props<{ channel: MessagingChannelKeys }>()
)
export const DisconnectMessagingChannelComplete = createAction(
  '[MessagingChannel] Disconnect Channel Complete',
  props<{ channel: MessagingChannelKeys }>()
)
