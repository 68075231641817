import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { flatMap, map, mergeMap, switchMap, toArray } from 'rxjs/operators'
import { from, Observable, of } from 'rxjs'
import * as R from 'ramda'
import { Invoice } from '../invoices/invoice.model'
import { InvoiceTemplate, InvoiceTemplateCreateRequest, InvoiceTemplateUpdatePayload } from './template.model'
import {
  CustomBrandingService,
  Template,
  TemplateResponse,
  TemplateService,
  TemplateType,
} from '@tokeet-frontend/templates'
import { deserializeArray, isSomething, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class InvoiceTemplateService {
  constructor(
    private http: HttpClient,
    private toaster: Toaster,
    private customBrandingService: CustomBrandingService,
    private templateService: TemplateService
  ) {}

  remove(templateId: string) {
    const url = `@api/invoicetemplate/${templateId}`

    return this.http.delete(url)
  }

  deleteBatch(ids: string[]): Observable<string[]> {
    return of(ids).pipe(
      flatMap((id) => from(id)),
      mergeMap((id) => this.http.delete(`@api/invoicetemplate/${id}`).pipe(map(() => id))),
      toArray()
    )
  }

  applyTemplate(bookingId: string, invoiceTemplateId: string) {
    const url = `@api/invoice/create_from_template`

    return this.http.post<{ invoice: any }>(url, { template_id: invoiceTemplateId, inquiry_id: bookingId }).pipe(
      switchMap((response) => {
        const id: string = R.path(['invoice', 'pkey'], response)
        return this.getInvoice(id)
      })
    )
  }

  private getInvoice(invoiceId: string): Observable<Invoice> {
    const url = `@api/invoice/${invoiceId}`

    return this.http.get(url).pipe(map((response) => Invoice.deserialize(response)))
  }

  all() {
    const url = `@api/invoicetemplate/all/`

    return this.http.get<InvoiceTemplate[]>(url).pipe(
      switchMap((invoiceTemplates) =>
        this.templates().pipe(
          map((templates) =>
            R.map(
              (t) => ({
                ...t,
                template: R.find((tmp) => tmp.id === t.email_template_id, templates),
              }),
              invoiceTemplates
            )
          )
        )
      )
    )
  }

  templates(): Observable<Template[]> {
    const url = '@api/template/all/'

    return this.http.get<TemplateResponse[]>(url).pipe(
      deserializeArray<Template>(Template),
      map((templates) => R.filter((t: Template) => t.type === TemplateType.InvoiceTemplate, templates))
    )
  }

  update(payload: InvoiceTemplateUpdatePayload) {
    const url = `@api/invoicetemplate/${payload.pkey}`

    return this.http
      .put<InvoiceTemplate>(url, payload)
      .pipe(
        switchMap((invoiceTemplate) =>
          this.templateService
            .get(invoiceTemplate.email_template_id)
            .pipe(map((template) => ({ ...invoiceTemplate, template: Template.deserialize(template || {}) })))
        )
      )
  }

  create(payload: InvoiceTemplateCreateRequest) {
    const url = `@api/invoicetemplate/`

    return this.http
      .post<InvoiceTemplate>(url, payload)
      .pipe(
        switchMap((invoiceTemplate) =>
          this.templateService
            .get(invoiceTemplate.email_template_id)
            .pipe(map((template) => ({ ...invoiceTemplate, template: Template.deserialize(template || {}) })))
        )
      )
  }

  get(templateId: string) {
    const url = `@api/invoicetemplate/${templateId}`

    return this.http
      .get<InvoiceTemplate>(url)
      .pipe(
        switchMap((invoiceTemplate) =>
          this.templateService
            .get(invoiceTemplate.email_template_id)
            .pipe(map((template) => ({ ...invoiceTemplate, template: Template.deserialize(template || {}) })))
        )
      )
  }

  getTemplate(
    template: InvoiceTemplate,
    product: 'tokeet' | 'sympl' = 'tokeet'
  ): Observable<{ subject: string; body: string }> {
    const mapResponse = () => {
      return map((response: Template) => ({ subject: response.subject, body: response.body }))
    }
    if (isSomething(template) && isSomething(template.email_template_id)) {
      return this.templateService.get(template.email_template_id).pipe(mapResponse())
    } else {
      return this.customBrandingService.getInvoiceMessageTemplate(product).pipe(mapResponse())
    }
  }
}
