import { SelectionModel } from '@angular/cdk/collections'
import * as R from 'ramda'
import { MatTableDataSource } from '@angular/material/table'

export function masterToggle<T>(
  selection: SelectionModel<T>,
  renderedData: T[],
  source: MatTableDataSource<T>,
  isRowSelectable: (row: T) => boolean = () => true,
  idPropName?: string
) {
  if (isAllSelected(selection, renderedData, isRowSelectable)) {
    selection.clear()
  } else {
    source.data.forEach((row) => {
      if (!isRowSelectable(row)) return

      if (
        !R.isNil(
          R.find((rendered) => {
            if (idPropName) {
              return rendered[idPropName] === row[idPropName]
            } else if (R.has('id', rendered)) {
              return rendered['id'] === row['id']
            } else if (R.has('pkey', rendered)) {
              return rendered['pkey'] === row['pkey']
            } else if (R.has('propertyId', rendered)) {
              return rendered['propertyId'] === row['propertyId']
            } else if (!R.isNil(rendered['rateId'])) {
              return rendered['rateId'] === row['rateId']
            }
          }, renderedData)
        )
      ) {
        selection.select(row)
      }
    })
  }
}

export function isAllSelected<T>(
  selection: SelectionModel<T>,
  renderedData: T[],
  isRowSelectable: (row: T) => boolean = () => true
) {
  const selectedItems = R.filter(isRowSelectable, selection.selected)
  const renderedItems = R.filter(isRowSelectable, renderedData)

  return selectedItems.length === renderedItems.length
}
