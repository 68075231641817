import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { ComponentType } from '@angular/cdk/portal'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { GVRConnectionOverlayComponent } from './gvr-connection-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class GVRConnectionOverlayService {
  constructor(private dialog: DialogService) {}

  public open<T>(connection: ConnectionView, component: ComponentType<T>): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { connection },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  public openSide(connection: ConnectionView) {
    return this.open(connection, GVRConnectionOverlayComponent)
  }
}
