<div class="modal-header">
  <h6 class="modal-title">Listing Settings</h6>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="modal-body">
  <div class="webready-banner mb-3">
    <img src="https://cdn.tokeet.com/images/Appstore_logos/webready.svg" alt="Webready Logo" />
    <div>
      Webready is the most advanced website builder in the industry.
      <a [href]="storeLink" target="_blank">Try it now!</a>
    </div>
  </div>
  <div class="mb-3">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <app-select
              [items]="websites$ | async"
              bindLabel="name"
              bindValue="id"
              groupBy="provide"
              formControlName="websiteId"
              placeholder="Website (Landing Page)"
              class="mat-block"
              [required]="true"
            >
            </app-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <app-select
              [items]="domains$ | async"
              bindLabel="_id"
              bindValue="_id"
              formControlName="websiteDomainId"
              placeholder="Webready Website Domain"
              class="mat-block"
              [disabled]="isDomainDisabled"
              [required]="isDomainRequired"
            >
            </app-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input type="text" formControlName="websiteDisplayName" matInput placeholder="Website Display Name" />
            <mat-hint>Rental display name will be used, if this isn't set</mat-hint>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="cancellationPolicyType" placeholder="Cancellation Policy Type">
              <mat-option value="No Refund">No Refund</mat-option>
              <mat-option value="Strict">Strict</mat-option>
              <mat-option value="Firm">Firm</mat-option>
              <mat-option value="Moderate">Moderate</mat-option>
              <mat-option value="Relaxed">Relaxed</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-12 text-right" *ngIf="form.get('websiteId').value">
          <button (click)="onCheckWebsiteData()" type="button" class="btn btn-outline-primary">
            <span class="fal fa-check-circle"></span>
            Check Website Data
          </button>
          <button (click)="onPublishWebsite()" type="button" class="btn btn-secondary-info ml-3">
            <span class="cs-icon cs-icon-upload"></span>
            Publish Website
          </button>
        </div>
      </div>
    </form>
  </div>
  <ng-container *ngIf="errors.length">
    <div class="alert alert-warning">Please publish your website to fix these errors after updating rental data.</div>

    <div class="validation-box">
      <div class="validation-list">
        <div *ngFor="let error of errors" class="invalid-item">
          <i class="fas fa-exclamation-circle" [ngClass]="error.type"></i>
          <ng-container *ngFor="let e of error.errors">{{ e }}</ng-container>
          <a href="javascript:;" (click)="onFixRental(error)" class="fix-btn text-uppercase">
            fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-outline-light">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
</div>
