import {
  addAttachmentsComplete,
  deleteAttachmentComplete,
  deleteAttachmentsComplete,
  getAttachmentsComplete,
  loadAttachmentsComplete,
} from '../attachment/attachment.actions'
import { adapter, initialState, State } from './attachment.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(loadAttachmentsComplete, (state, { attachments }) =>
    adapter.setAll(attachments, R.merge(state, { isLoaded: true }))
  ),
  on(getAttachmentsComplete, (state, { attachments }) => adapter.upsertMany(attachments, state)),
  on(addAttachmentsComplete, (state, { attachments }) => adapter.addMany(attachments, state)),
  on(deleteAttachmentComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(deleteAttachmentsComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function attachmentReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
