import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-credit-input-dialog',
  templateUrl: './credit-input-dialog.component.html',
  styleUrls: ['./credit-input-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class CreditInputDialogComponent implements OnInit {
  form = this.fb.group({
    amount: [undefined, [Validators.required, Validators.min(5), Validators.max(300)]],
  })

  constructor(public dialogRef: MatDialogRef<CreditInputDialogComponent>, private fb: FormBuilder) {}

  ngOnInit() {}

  save(form: FormGroup) {
    if (form.invalid) {
      return
    }
    const data = this.form.getRawValue()
    this.dialogRef.close(data)
  }

  close() {
    this.dialogRef.close()
  }
}
