<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-1">
        <h6 class="modal-title">{{ title }}</h6>
        <div class="flex-fill"></div>
        <ng-template [ngTemplateOutlet]="actions"></ng-template>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box p-3">
        <div *ngIf="data?.isClone">
          <alert type="warning">
            <i class="far fa-exclamation-triangle"></i> Warning: This booking is a clone. Please modify the details,
            before clicking Save to create the booking.
          </alert>
        </div>
        <form [formGroup]="form">
          <div class="">
            <h6 class="section-title">Guest Details</h6>
            <mat-form-field2 class="mat-block mb-3" [showLabel]="false">
              <app-select
                bindLabel="name"
                bindValue="id"
                [searchable]="true"
                (searchChange)="guestSearch.next($event)"
                [multiple]="false"
                [items]="guests$ | async"
                formControlName="guestId"
                placeholder="Search for Guest (optional)"
              ></app-select>
              <i
                matSuffix
                *ngIf="form.get('guestId').value"
                class="far fa-times clickable text-danger"
                (click)="onClearSelectedGuest()"
              ></i>
            </mat-form-field2>
            <div class="d-grid gap-col-3" style="grid-template-columns: 1fr 1fr 1fr">
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="guestName" matInput required placeholder="Guest Name" />
                <mat-error *ngIf="form.get('guestName').touched && form.get('guestName').hasError('required')">
                  Name is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('guestName').touched && form.get('guestName').hasError('minlength')">
                  Name must be at least <strong>2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('guestName').touched && form.get('guestName').hasError('maxlength')">
                  Name must be at most <strong>50</strong> characters long
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="guestEmail" matInput required placeholder="Guest Email" />
                <mat-error *ngIf="form.get('guestEmail').touched && form.get('guestEmail').hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('guestEmail').touched && form.get('guestEmail').hasError('email')">
                  Email must have valid format
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input type="text" formControlName="guestPhone" matInput placeholder="Guest Phone" />
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <mat-select
                  formControlName="source"
                  placeholder="Source:"
                  [required]="true"
                  (selectionChange)="engineCost.next('')"
                >
                  <mat-option *ngFor="let channel of channels$ | async" [value]="channel.id">{{
                    channel.label
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('source').touched && form.get('source').hasError('required')">
                  Channel is <strong>required</strong>
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input
                  type="number"
                  min="1"
                  step="1"
                  (change)="engineCost.next('')"
                  formControlName="adults"
                  matInput
                  required
                  placeholder="Adults"
                />
                <mat-error *ngIf="form.get('adults').touched && form.get('adults').hasError('required')">
                  Adults field is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('adults').hasError('pattern')">
                  Adults field must be a <strong>whole number</strong>
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input
                  type="number"
                  min="0"
                  step="1"
                  (change)="engineCost.next('')"
                  formControlName="children"
                  matInput
                  placeholder="Children"
                />
                <mat-error *ngIf="form.get('children').touched && form.get('children').hasError('pattern')">
                  Children field must be a <strong>whole number</strong>
                </mat-error>
              </mat-form-field2>
            </div>
          </div>
          <div class="cost-error">
            <mat-error *ngIf="overMaxGuests$ | async as maxGuests"> Maximum guests is {{ maxGuests }} </mat-error>
            <mat-error *ngIf="underMinGuests$ | async as minGuests"> Minimum guests is {{ minGuests }} </mat-error>
          </div>

          <div class="">
            <h6 class="section-title">Charges Details</h6>
            <div class="d-grid gap-col-3" style="grid-template-columns: 1fr 1fr 1fr">
              <app-select-search
                bindValue="id"
                bindLabel="name"
                [inline]="false"
                [multiple]="false"
                [items]="rentals$ | async"
                [ctrl]="form.get('rentalId')"
                placeholder="Rental"
              >
                <mat-error
                  class="error-container"
                  *ngIf="form.get('rentalId').touched && form.get('rentalId').hasError('required')"
                >
                  Rental is <strong>required</strong>
                </mat-error>
              </app-select-search>
              <mat-form-field2 class="mat-block">
                <input
                  matInput
                  [matDatepicker]="arriveDate"
                  placeholder="Arrive"
                  [min]="minDateDefault"
                  [max]="maxArriveDate.value || maxDateDefault"
                  [required]="true"
                  (focus)="arriveDate.open()"
                  (dateChange)="engineCost.next('')"
                  (dateInput)="engineCost.next('')"
                  formControlName="arriveDate"
                  readonly
                />
                <i matPrefix (click)="arriveDate.open()" class="cs-icon cs-icon-calendar"></i>
                <mat-datepicker #arriveDate></mat-datepicker>
                <mat-error *ngIf="form.get('arriveDate').touched && form.get('arriveDate').hasError('required')">
                  Arrive is <strong>required</strong>
                </mat-error>
                <mat-hint class="text-danger" *ngIf="underMinStay$ | async"
                  >Minimum stay is {{ cost.value?.minimumstay }}</mat-hint
                >
                <mat-hint class="text-danger" *ngIf="overMaxStay$ | async"
                  >Maximum stay is {{ cost.value?.maximumstay }}</mat-hint
                >
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input
                  matInput
                  [matDatepicker]="departDate"
                  placeholder="Depart"
                  [required]="true"
                  [min]="minDepartDate.value || minDateDefault"
                  [max]="maxDateDefault"
                  (dateChange)="engineCost.next('')"
                  (dateInput)="engineCost.next('')"
                  (focus)="departDate.open()"
                  formControlName="departDate"
                  readonly
                />
                <i matPrefix (click)="departDate.open()" class="cs-icon cs-icon-calendar"></i>
                <mat-datepicker #departDate></mat-datepicker>
                <mat-error *ngIf="form.get('departDate').touched && form.get('departDate').hasError('required')">
                  Depart is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('departDate').hasError('matDatepickerMin')">
                  Min value is
                  <strong>{{ form.get('departDate').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
                </mat-error>
              </mat-form-field2>
              <mat-form-field2 class="mat-block">
                <input
                  type="number"
                  min="0"
                  formControlName="charge"
                  (keyup)="onChargeChange($event.target.value)"
                  matInput
                  placeholder="Base Charge:"
                  [required]="true"
                />
                <mat-error *ngIf="form.get('charge').touched && form.get('charge').hasError('required')">
                  Base Charge is <strong>required</strong>
                </mat-error>
              </mat-form-field2>
              <div
                formArrayName="discounts"
                *ngFor="let item of discounts.controls; let i = index; let last = last; let first = first"
                class="d-flex gap-3"
              >
                <mat-form-field2 class="mat-block" [formGroupName]="i">
                  <input
                    type="number"
                    (change)="engineCost.next('')"
                    formControlName="amount"
                    matInput
                    placeholder="Discounts & Fees:"
                  />
                  <mat-error *ngIf="item.get('amount').hasError('zero')"> Value shouldn't be zero. </mat-error>
                </mat-form-field2>
                <button
                  *ngIf="last && discounts.length < discountsOrFeesLimit"
                  (click)="addDiscountsOrFees()"
                  class="btn btn-primary btn-circle align-input-row-end"
                  matTooltip="Add new Discount or Fee"
                  type="button"
                >
                  <i class="fas fa-plus"></i>
                </button>
                <button
                  *ngIf="last && !first"
                  (click)="removeDiscountsOrFees(i)"
                  class="btn btn-danger btn-circle align-input-row-end"
                  matTooltip="Remove this Discount/Fee"
                  type="button"
                >
                  <i class="fal fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="d-grid gap-col-3" style="grid-template-columns: 1fr 1fr 1fr">
              <mat-form-field2 class="mat-block" *ngIf="!!cost.value?.extraGuests">
                <input
                  type="number"
                  [value]="cost.value?.extraGuests"
                  matInput
                  disabled
                  placeholder="Additional Guests Fee:"
                />
              </mat-form-field2>
              <ng-container *ngFor="let item of cost?.value?.auditDiscounts">
                <mat-form-field2 class="mat-block">
                  <input
                    type="number"
                    [value]="item.disc_amount"
                    matInput
                    disabled
                    [placeholder]="item.disc_type === 'lengthofstay' ? 'LOS Discount' : 'Discount Code'"
                  />
                  <i matSuffix class="fal fa-eye clickable" (click)="onViewDiscount(item)"></i>
                </mat-form-field2>
              </ng-container>
            </div>
            <div [class.hidden]="fees.controls.length === 0" class="mb-3">
              <h6 class="section-title">Booking Fees</h6>
              <div
                formArrayName="fees"
                *ngFor="let item of fees.controls; let i = index"
                class="d-grid align-items-center bg-active2 p-3 rounded gap-col-3 mb-3"
                style="grid-template-columns: min-content repeat(4, 1fr)"
              >
                <ng-container [formGroupName]="i">
                  <mat-checkbox
                    formControlName="selected"
                    #feeSelected
                    (change)="updateBookingFees()"
                    [matTooltip]="feeSelected.checked ? 'Uncheck to ignore this fee' : 'Check to apply this fee'"
                    class="mr-3"
                  ></mat-checkbox>
                  <mat-form-field2 [matTooltip]="item.get('name')?.value" matTooltipPosition="above" class="mat-block">
                    <input type="text" formControlName="name" matInput placeholder="Fee Name:" />
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <mat-label
                      ><span
                        [innerHtml]="
                          'Fee Rate (' + (item.get('type')?.value === 'flat' ? rental?.currency?.symbol : '%') + '):'
                        "
                      ></span
                    ></mat-label>
                    <input type="number" min="0" formControlName="rate" matInput />
                  </mat-form-field2>
                  <mat-form-field2
                    class="mat-block"
                    [matTooltip]="feeModalityLabels[item.get('modality')?.value]"
                    matTooltipPosition="above"
                  >
                    <mat-select formControlName="modality" placeholder="Modality">
                      <mat-option [value]="feeModalities.PerStay">{{
                        feeModalityLabels[feeModalities.PerStay]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerNight">{{
                        feeModalityLabels[feeModalities.PerNight]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerPerson">{{
                        feeModalityLabels[feeModalities.PerPerson]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerPersonPerNight">{{
                        feeModalityLabels[feeModalities.PerPersonPerNight]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerDays">{{
                        feeModalityLabels[feeModalities.PerDays]
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <span
                      matPrefix
                      [innerHtml]="rental?.currency?.symbol"
                      *ngIf="!(item.get('amount').value | isNil)"
                    ></span>
                    <input type="number" min="0" formControlName="amount" matInput placeholder="Fee Amount:" />
                  </mat-form-field2>
                </ng-container>
              </div>
            </div>
            <div [class.hidden]="potentialFees.controls.length === 0" class="mb-3">
              <h6 class="section-title">These booking fees may apply</h6>
              <div
                formArrayName="potentialFees"
                *ngFor="let item of potentialFees.controls; let i = index"
                class="d-grid gap-col-3"
                style="grid-template-columns: 1fr 1fr 1fr"
              >
                <ng-container [formGroupName]="i">
                  <mat-form-field2 [matTooltip]="item.get('name')?.value" matTooltipPosition="above" class="mat-block">
                    <input type="text" formControlName="name" matInput placeholder="Fee Name:" />
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <mat-label
                      ><span
                        [innerHtml]="
                          'Fee Rate (' + (item.get('type')?.value === 'flat' ? rental?.currency?.symbol : '%') + '):'
                        "
                      ></span
                    ></mat-label>
                    <input type="number" min="0" formControlName="rate" matInput />
                  </mat-form-field2>
                  <mat-form-field2
                    class="mat-block"
                    [matTooltip]="feeModalityLabels[item.get('modality')?.value]"
                    matTooltipPosition="above"
                  >
                    <mat-select formControlName="modality" placeholder="Modality">
                      <mat-option [value]="feeModalities.PerStay">{{
                        feeModalityLabels[feeModalities.PerStay]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerNight">{{
                        feeModalityLabels[feeModalities.PerNight]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerPerson">{{
                        feeModalityLabels[feeModalities.PerPerson]
                      }}</mat-option>
                      <mat-option [value]="feeModalities.PerPersonPerNight">{{
                        feeModalityLabels[feeModalities.PerPersonPerNight]
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <span
                      matPrefix
                      [innerHtml]="rental?.currency?.symbol"
                      *ngIf="!(item.get('amount').value | isNil)"
                    ></span>
                    <input type="number" min="0" formControlName="amount" matInput placeholder="Fee Amount:" />
                  </mat-form-field2>
                </ng-container>
              </div>
            </div>
            <div class="cost-error">
              <mat-error *ngIf="isCheckinBlocked$ | async as weekday"> Checkin is blocked on {{ weekday }} </mat-error>
              <mat-error *ngIf="isCheckoutBlocked$ | async as weekday">
                Checkout is blocked on {{ weekday }}
              </mat-error>
              <mat-error *ngIf="underMinStay$ | async as minStay"> Minimum stay is {{ minStay }} </mat-error>
              <mat-error *ngIf="overMaxStay$ | async as maxStay"> Maximum stay is {{ maxStay }} </mat-error>
              <mat-error *ngIf="minAdvanceDays$ | async as minAdvanceDays">
                Minimum Advance Booking is {{ minAdvanceDays }} {{ minAdvanceDays > 1 ? 'days' : 'day' }}
              </mat-error>
              <mat-error *ngIf="maxAdvanceDays$ | async as maxAdvanceDays">
                Maximum Advance Booking is {{ maxAdvanceDays }} days
              </mat-error>
            </div>

            <div [class.hidden]="taxes.controls.length === 0" class="mb-3">
              <h6 class="section-title">Taxes</h6>
              <div
                formArrayName="taxes"
                *ngFor="let item of taxes.controls; let i = index"
                class="d-grid gap-col-3"
                style="grid-template-columns: 1fr 1fr 1fr"
              >
                <ng-container [formGroupName]="i">
                  <mat-form-field2 class="mat-block">
                    <input type="text" formControlName="taxName" matInput placeholder="Tax Name:" />
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <input type="number" min="0" formControlName="taxRate" matInput placeholder="Tax Rate:" />
                  </mat-form-field2>
                  <mat-form-field2 class="mat-block">
                    <input type="number" min="0" formControlName="taxAmount" matInput placeholder="Tax Amount:" />
                  </mat-form-field2>
                </ng-container>
              </div>
            </div>
            <div *ngIf="cost.value" class="bg-active2 rounded p-3 d-flex justify-content-between">
              <span class="text-uppercase text-light">Total: </span>
              <span class="text-bold">{{ cost.value?.total | tokeetCurrency : rental?.currency }}</span>
            </div>
          </div>
        </form>
        <div class="d-flex align-content-center gap-3 mt-3">
          <div class="flex-fill"></div>
          <ng-template [ngTemplateOutlet]="actions"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #actions>
  <button (click)="close()" type="button" class="btn btn-outline-light">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
</ng-template>
