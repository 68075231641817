<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Room Details</div>
      <div class="col-sm-6 btn-holder">
        <span [matTooltip]="isRoomFormValid()" matTooltipPosition="above">
          <button
            type="button"
            class="btn btn-secondary-info ml-2"
            (click)="onSave(roomForm)"
            [disabled]="isRoomFormValid()"
          >
            <i class="cs-icon cs-icon-save"></i> Save
          </button>
        </span>
      </div>
    </div>

    <div class="box-body">
      <div [formGroup]="baseRoomForm">
        <div class="row no-margin">
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input matInput required placeholder="Room Name" type="text" formControlName="name" />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="bedrooms" placeholder="Bedrooms" required>
                <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="bathrooms" placeholder="Bathrooms" required>
                <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Room Amenities</div>
      <div class="col-sm-6 btn-holder"></div>
    </div>

    <div class="box-body">
      <form [formGroup]="rentalDetailsForm">
        <div class="row m-0">
          <div
            formArrayName="detail"
            *ngFor="let detail of rentalDetailsForm['controls']['detail']['controls']; let i = index"
            class="col-sm-3"
          >
            <mat-checkbox [formControlName]="i" [value]="detail.id" class="mt-primary">
              {{ rentalAmenityDetails[i].name }}
            </mat-checkbox>
          </div>
          <div class="col-sm-12 my-3"><span class="text-danger">*</span> Please select at least one amenity.</div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Specify Bedrooms</div>
      <div class="col-sm-6 btn-holder"></div>
    </div>

    <div class="box-body">
      <div *ngFor="let bedroom of bedrooms.controls; let i = index" [formGroup]="bedroom">
        <div class="mr-5 mb-1 bedroom-details">
          <div class="row no-margin">
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="roomType" placeholder="Room Type" required>
                  <mat-option [value]="'BedRoom'">Bedroom</mat-option>
                  <mat-option [value]="'LivingRoom'">Living room</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="bedType" placeholder="Bed Type" required>
                  <mat-option *ngFor="let type of bedTypeOptions" [value]="type.index">{{ type.label }}</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
          </div>
          <a
            href="javascript:;"
            class="remove-btn"
            (click)="removeOneBedroom(i)"
            matTooltip="Remove room"
            matTooltipPosition="left"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </a>
        </div>
      </div>
      <div>
        <button
          type="button"
          *ngIf="bedrooms.length < baseRoomForm.get('bedrooms').value"
          class="btn btn-secondary-info ml-2 mt-2 mb-5 mr-5 float-right"
          (click)="addOneBedroom()"
        >
          <i class="far fa-plus"></i> Add Room
        </button>
      </div>
    </div>
  </div>
</div>
