import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { EntityAttributeResponse } from './model'

@Injectable()
export class EntityAttributesService {
  constructor(private http: HttpClient) {}

  getAttributes(entityType: DataEntityType, entityId: string) {
    const url = `@api/entityattributes/${entityType}/${entityId}/`
    return this.http.get<EntityAttributeResponse>(url)
  }

  create(entityType: DataEntityType, entityId: string, payload: Record<string, any>) {
    const url = `@api/entityattributes/${entityType}/${entityId}/`
    return this.http.post<EntityAttributeResponse>(url, { attributes: payload })
  }
}
