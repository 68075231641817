import { Pipe, PipeTransform } from '@angular/core'
import * as lodash from 'lodash'

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    if (!term) return value
    return (value || []).filter((item) =>
      keys.split(',').some((path) => {
        const val = lodash.get(item, path)
        return val && (val + '').toLowerCase().indexOf(term.toLowerCase()) > -1
      })
    )
  }
}
