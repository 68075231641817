import { find } from 'lodash'

export interface Currency {
  cc: string
  code: string
  symbol: string
  img?: string
  icon?: string
}

const currenciesWithoutIcon: Currency[] = [
  { cc: 'eu', code: 'EUR', symbol: '€' },
  { cc: 'us', code: 'USD', symbol: '$' },
  { cc: 'ca', code: 'CAD', symbol: '$' },
  { cc: 'gb', code: 'GBP', symbol: '£' },
  { cc: 'ae', code: 'AED', symbol: 'د.إ' },
  { cc: 'cw', code: 'ANG', symbol: 'ƒ' },
  { cc: 'ar', code: 'ARS', symbol: '$' },
  { cc: 'au', code: 'AUD', symbol: '$' },
  { cc: 'bg', code: 'BGN', symbol: 'лв' },
  { cc: 'bh', code: 'BHD', symbol: '.د.ب' },
  { cc: 'br', code: 'BRL', symbol: 'R$' },
  { cc: 'bs', code: 'BSD', symbol: '$' },
  { cc: 'ch', code: 'CHF', symbol: 'CHF' },
  { cc: 'cl', code: 'CLP', symbol: '$' },
  { cc: 'cn', code: 'CNY', symbol: '¥' },
  { cc: 'co', code: 'COP', symbol: '$' },
  { cc: 'cz', code: 'CZK', symbol: 'Kč' },
  { cc: 'de', code: 'DEM', symbol: 'DM' },
  { cc: 'de', code: 'DEU', symbol: '₰' },
  { cc: 'dk', code: 'DKK', symbol: 'kr' },
  { cc: 'eg', code: 'EGP', symbol: '£' },
  { cc: 'es', code: 'ESP', symbol: '₧' },
  { cc: 'fj', code: 'FJD', symbol: '$' },
  { cc: 'ge', code: 'GEL', symbol: 'ლ' },
  { cc: 'gh', code: 'GHS', symbol: '¢' },
  { cc: 'gr', code: 'GRD', symbol: '₯' },
  { cc: 'gt', code: 'GTQ', symbol: 'Q' },
  { cc: 'hk', code: 'HKD', symbol: '$' },
  { cc: 'hn', code: 'HNL', symbol: 'L' },
  { cc: 'hr', code: 'HRK', symbol: 'kn' },
  { cc: 'hu', code: 'HUF', symbol: 'Ft' },
  { cc: 'id', code: 'IDR', symbol: 'Rp' },
  { cc: 'il', code: 'ILS', symbol: '₪' },
  { cc: 'in', code: 'INR', symbol: '₹' },
  { cc: 'is', code: 'ISK', symbol: 'kr' },
  { cc: 'jm', code: 'JMD', symbol: 'J$' },
  { cc: 'jp', code: 'JPY', symbol: '¥' },
  { cc: 'ke', code: 'KES', symbol: 'KSh' },
  { cc: 'kr', code: 'KRW', symbol: '₩' },
  { cc: 'la', code: 'LAK', symbol: '₭' },
  { cc: 'lk', code: 'LKR', symbol: '₨' },
  { cc: 'ma', code: 'MAD', symbol: '.د.م' },
  { cc: 'md', code: 'MDL', symbol: 'L' },
  { cc: 'mm', code: 'MMK', symbol: 'K' },
  { cc: 'mn', code: 'MNT', symbol: '₮' },
  { cc: 'mx', code: 'MXN', symbol: '$' },
  { cc: 'my', code: 'MYR', symbol: 'RM' },
  { cc: 'ng', code: 'NGN', symbol: '₦' },
  { cc: 'no', code: 'NOK', symbol: 'kr' },
  { cc: 'nz', code: 'NZD', symbol: '$' },
  { cc: 'om', code: 'OMR', symbol: '﷼' },
  { cc: 'pa', code: 'PAB', symbol: 'B/.' },
  { cc: 'pe', code: 'PEN', symbol: 'S/.' },
  { cc: 'ph', code: 'PHP', symbol: '₱' },
  { cc: 'pk', code: 'PKR', symbol: '₨' },
  { cc: 'pl', code: 'PLN', symbol: 'zł' },
  { cc: 'es', code: 'Pta', symbol: 'Pt' },
  { cc: 'py', code: 'PYG', symbol: 'Gs' },
  { cc: 'ro', code: 'RON', symbol: 'lei' },
  { cc: 'rs', code: 'RSD', symbol: 'Дин.' },
  { cc: 'ru', code: 'RUB', symbol: 'руб' },
  { cc: 'sa', code: 'SAR', symbol: '﷼' },
  { cc: 'se', code: 'SEK', symbol: 'kr' },
  { cc: 'sg', code: 'SGD', symbol: '$' },
  { cc: 'th', code: 'THB', symbol: '฿' },
  { cc: 'tn', code: 'TND', symbol: 'DT' },
  { cc: 'tr', code: 'TRY', symbol: '₤' },
  { cc: 'tt', code: 'TTD', symbol: 'TT$' },
  { cc: 'ua', code: 'UAH', symbol: '₴' },
  { cc: 've', code: 'VEF', symbol: 'Bs' },
  { cc: 'vn', code: 'VND', symbol: '₫' },
  { cc: 'cm', code: 'XAF', symbol: 'FCFA' },
  { cc: 'vc', code: 'XCD', symbol: '$' },
  { cc: 'pf', code: 'XPF', symbol: 'F' },
  { cc: 'za', code: 'ZAR', symbol: 'R' },
  { cc: 'uz', code: 'UZS', symbol: "so'm" },
]

export function getCountryFlagImg(cc: string): string {
  return `flag flag-${cc}`
}

export function getCountryFlagIcon(cc: string): string {
  return `flag-icon flag-icon-${cc}`
}

export function getCurrenciesWithFlagImage(): Required<Omit<Currency, 'icon'>>[] {
  return currenciesWithoutIcon.map(({ cc, code, symbol }) => ({ cc, code, symbol, img: getCountryFlagImg(cc) }))
}

export function getCurrenciesWithFlagIcon(): Required<Omit<Currency, 'img'>>[] {
  return currenciesWithoutIcon.map(({ cc, code, symbol }) => ({ cc, code, symbol, icon: getCountryFlagIcon(cc) }))
}

export function getCurrencies(): Required<Currency>[] {
  return currenciesWithoutIcon.map(({ cc, code, symbol }) => ({
    cc,
    code,
    symbol,
    icon: getCountryFlagIcon(cc),
    img: getCountryFlagImg(cc),
  }))
}

export function getCurrencyByCode(code: string, list: Currency[] = currenciesWithoutIcon): Currency {
  return find(list, (c) => c.code === code)
}

export const currencies = getCurrencies()
