<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/listing-icon3.png" alt="" height="40" />
      <div>
        <h6 class="mb-0">Co-Host Overview (optional)</h6>
        <div class="text-sm">
          The steps outlined in this guide must be completed for co-host listings before you can be confident that
          availability on the AdvanceCM calendar is properly pushed to AirBnb.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create Export URL for Co-host
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Synching availability between AdvanceCM and AirBnb for co-host listings is done via a one way iCal
                connection. You will be creating an export URL during this step and will need to have that URL imported
                into the calendar in AirBnb.
              </p>
              <p>Please hit Create Export below and copy the URL. Then hit Continue to proceed.</p>
              <div class="d-flex align-items-center gap-3">
                <button class="btn btn-light-info" (click)="onExport()">
                  <i class="cs-icon cs-icon-plus"></i> Create Export
                </button>
                <div class="flex-fill"></div>

                <button class="btn btn-light-info" (click)="importIcal.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #importIcal>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Importing to AirBnb for Co-host
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                The export URL created in the previous step will now need to be set in the Imported Calendars section of
                your listing’s calendar.
              </p>

              <p>In Airbnb click into your listing. Then go to the Availability tab shown below.</p>
              <img src="/assets/images/listing-wizards/airbnb/import-ical.png" alt="" />
              <p>
                Toward the bottom of this section you will see the following option to Import Calendar. Choose Import
                Calendar and paste in the URL saved in the previous step. You can choose any name you prefer for the
                calendar name in this step.
              </p>

              <img src="/assets/images/listing-wizards/airbnb/import-ical2.png" alt="" class="mb-3" />

              <div class="d-flex align-items-center gap-3 mt-3">
                <div class="flex-fill"></div>
                <button class="btn btn-light-info" (click)="enableMessaging.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #enableMessaging>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Enabling Messaging for Co-host
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                In order to maintain communication with guests in co-hosted Airbnb accounts, you will need to click on
                the Live Chat button in the bottom-right corner of your AdvanceCM account, and provide us with your
                AdvanceCM Account ID, and your Airbnb property ID.
              </p>
              <p>
                We have provided the message with all the relevant details below. Simply copy this message and paste it
                into your Live Chat question. One of our technicians will input these details into our system so that
                messaging will function via the email address sent with the booking details.
              </p>
              <p>
                For primary listings messaging is sent via the API and does not use the routing emails provided by
                AirBnb. This workaround affects only co-host listings. Also, please note that this step need only be
                completed once for all co-hosted listings on the same AirBnb account.
              </p>
              <em>
                <p>Dear AdvanceCM Support,</p>
                <p>
                  I have a co-hosted Airbnb account, and I need to add my account to your mail service. My information
                  is below.
                  <br />
                  AdvanceCM Account ID: {{ accountId$.value }} <br />
                  Airbnb Co-hosted Property ID: {{ connection.propertyId }} <br />
                </p>
                <p>Thank you.</p>
              </em>
              <p>
                Our team will send a confirmation reply once the update has been made, and your messaging will resume
                through AdvanceCM for any co-hosted listings on that account.
              </p>
              <div class="d-flex align-items-center gap-3 mt-3">
                <div class="flex-fill"></div>
                <button class="btn btn-light-info" (click)="complete.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #complete>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">4</div>
                Co-host process complete
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                You have now completed the workaround steps to ensure that your co-host listing can properly sync
                availability and message your guests from AdvanceCM.
              </p>
              <p>
                Due to this being a co-host listing you will be skipping the Rates walkthrough and moving directly to
                the Review process. As mentioned earlier, rates will need to be set on AirBnb directly for co-host
                listings.
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <div class="flex-fill"></div>
  <button class="btn btn-secondary-info" (click)="next.emit()">
    <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
    Next
  </button>
</div>
