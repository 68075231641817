import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class PaymentRule extends Serializable<PaymentRule> {
  @Expose({ name: 'pkey' })
  id: string
  created: number
  @Expose({ name: 'gateway_id' })
  gatewayId?: string
  channel: string
  @Expose({ name: 'rental_id' })
  rentalId?: string
  status: number
  autobill: boolean

  static deserialize(data: object): PaymentRule {
    return deserialize(PaymentRule, data)
  }
}
