import { LocalStorage } from './local-storage'
import { UserStorage } from './user-storage'
import { SessionStorage } from './session-storage'

export * from './local-storage'
export * from './session-storage'
export * from './user-storage'
export * from './storage'

export const storageServices = [LocalStorage, UserStorage, SessionStorage]
