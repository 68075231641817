import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromPreference from './preferences.reducer'
import { EffectsModule } from '@ngrx/effects'
import { PreferencesEffects } from './preferences.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('preference', fromPreference.preferencesReducer),
    EffectsModule.forFeature([PreferencesEffects]),
  ],
  declarations: [],
})
export class PreferenceModule {}
