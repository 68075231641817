<div class="modal-header">
  <h5 class="modal-title">
    {{ data.title }}
    <app-page-help
      [infoTooltip]="data.headerInfo"
      [articleId]="data.articleId"
      [videoId]="data.videoId"
    ></app-page-help>
  </h5>
</div>
<div class="modal-body">
  <p>{{ data.body }}</p>

  <form [formGroup]="form" class="row">
    <div class="col-sm-8 offset-sm-2">
      <mat-form-field2 class="mat-block">
        <input required matInput formControlName="confirm" placeholder="Enter DELETE to confirm" type="text" />
        <mat-error *ngIf="form.get('confirm').touched && form.get('confirm').hasError('required')">
          Value is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('confirm').touched && form.get('confirm').hasError('pattern')">
          Value must match <strong>DELETE</strong>
        </mat-error>
      </mat-form-field2>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    (click)="cancel()"
    type="button"
    class=""
    [ngClass]="data.cancelBtnClass"
    [matTooltip]="data.cancelBtnTooltip"
    matTooltipPosition="above"
  >
    {{ data.cancelText }}
  </button>
  <button
    (click)="confirm(form)"
    type="button"
    class="create-btn"
    [ngClass]="data.confirmBtnClass"
    [matTooltip]="data.confirmBtnTooltip"
    matTooltipPosition="above"
  >
    {{ data.confirmText }}
  </button>
</div>
