<div class="modal-header">
  <h6 class="modal-title">{{ isEdit ? 'Edit Access Code' : 'Generate Access Code' }}</h6>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <span><i class="far fa-times"></i></span>
  </button>
</div>
<div class="modal-body p-0">
  <ul class="nav top-navbar border-bottom">
    <li [class.active]="activeTab === tabs.Details">
      <a (click)="activeTab = tabs.Details"> Details </a>
    </li>
    <li [class.active]="activeTab === tabs.History" *ngIf="isEdit">
      <a (click)="activeTab = tabs.History"> Logs </a>
    </li>
  </ul>

  <ng-container [ngSwitch]="activeTab">
    <ng-template *ngSwitchCase="tabs.Details" [ngTemplateOutlet]="accessCodeDetails"></ng-template>
    <app-smart-devices-accesscode-history
      *ngSwitchCase="tabs.History"
      [accessCodeId]="code.accessCodeId"
      [deviceId]="code.deviceId"
    ></app-smart-devices-accesscode-history>
  </ng-container>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Close</button>
  <button
    (click)="onGenerate(form)"
    type="button"
    class="btn btn-secondary-info"
    [disabled]="isLoading"
    *ngIf="!isEdit"
  >
    <i class="far fa-plus" *ngIf="!isLoading"></i>
    <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
    Generate
  </button>
  <button
    (click)="onGenerate(form)"
    type="button"
    class="btn btn-secondary-info"
    [disabled]="isLoading || !isSavable()"
    *ngIf="isEdit"
  >
    <i class="cs-icon cs-icon-save" *ngIf="!isLoading"></i>
    <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
    Update
  </button>
</div>

<ng-template #accessCodeDetails>
  <form [formGroup]="form" class="p-3">
    <app-booking-select
      *ngIf="!data?.inquiry || data.code"
      [ctrl]="inquiryCtrl"
      [rentalId]="device?.rentalId"
      [filter]="filterInquiries"
      (selected)="onSelectInquiry($event)"
      [refreshable]="true"
      [onlyBooked]="true"
    ></app-booking-select>

    <mat-form-field2 *ngIf="!data?.device && !data?.code">
      <app-select
        [items]="devices$ | async"
        bindLabel="name"
        placeholder="Device"
        [searchable]="true"
        [multiple]="false"
        (selectedChange)="onSelectDevice($event)"
      ></app-select>
    </mat-form-field2>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field2
          class="mat-block"
          matTooltip="Code will be activated on date set here"
          matTooltipPosition="above"
        >
          <app-mat-date-input
            formControlName="startsAt"
            placeholder="Start Date"
            [min]="today.toDate()"
            [max]="form.get('endsAt').value"
            required
          ></app-mat-date-input>
          <span matPrefix><i class="cs-icon cs-icon-calendar"></i> </span>
          <mat-error *ngIf="form.get('startsAt').touched && form.get('startsAt').hasError('required')">
            Start Date is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('startsAt').hasError('min')">
            Min value is
            <strong>{{ form.get('startsAt').getError('min').min | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
          <mat-error *ngIf="form.get('startsAt').hasError('max')">
            Max value is
            <strong>{{ form.get('startsAt').getError('max').max | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2
          class="mat-block"
          matTooltip="Code will be de-activated on date set here"
          matTooltipPosition="above"
        >
          <app-mat-date-input
            formControlName="endsAt"
            placeholder="End Date"
            [min]="form.get('startsAt').value || today.toDate()"
            required
          ></app-mat-date-input>
          <span matPrefix><i class="cs-icon cs-icon-calendar"></i> </span>
          <mat-error *ngIf="form.get('endsAt').touched && form.get('endsAt').hasError('required')">
            End Date is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('endsAt').hasError('min')">
            Min value is
            <strong>{{ form.get('endsAt').getError('min').min | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
          <mat-error *ngIf="form.get('endsAt').hasError('max')">
            Max value is
            <strong>{{ form.get('endsAt').getError('max').max | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block" matTooltip="Code Activation Start Time" matTooltipPosition="above">
          <input
            type="text"
            matInput
            atp-time-picker
            [max]="maxStartTime"
            changeToMinutes="true"
            formControlName="startsAtTime"
            placeholder="Start Time"
          />
          <span matSuffix><i class="fal fa-clock"></i></span>
          <mat-error *ngIf="form.get('startsAtTime').hasError('max')">
            Max time should be less than
            <strong>{{ form.get('startsAtTime').getError('max').max | epoch : 'hh:mm A' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block" matTooltip="Code Activation End Time" matTooltipPosition="above">
          <input
            type="text"
            matInput
            atp-time-picker
            [min]="minEndTime"
            changeToMinutes="true"
            formControlName="endsAtTime"
            placeholder="End Time"
          />
          <span matSuffix><i class="fal fa-clock"></i></span>
          <mat-error *ngIf="form.get('endsAtTime').hasError('min')">
            Min time should be greater than
            <strong>{{ form.get('endsAtTime').getError('min').min | epoch : 'hh:mm A' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</ng-template>
