import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { combineLatest, Subject } from 'rxjs'
import { startWith, takeUntil } from 'rxjs/operators'
import { NgSelectComponent } from '@ng-select/ng-select'
import { TaxModalities } from '@tokeet/cost-resolver'
import { SaveForm } from '../../decorators'
import { isSomething } from '../../functions'
import { DataCheckerService } from '../../services'
import { LocalStorage } from '../../storage'
import {
  Rental,
  taxV3ModalityLabels,
  TaxV3,
  AccountGuard,
  selectAccount,
  selectAllRentals,
  updateRentalTaxes,
} from '../../stores'
import { ENVIRONMENT } from '../../tokens'

export interface EditTaxesDialogConfig {
  rental: Rental
}

@Component({
  selector: 'app-edit-taxes-dialog',
  // tslint:disable-next-line:use-host-property-decorator
  host: { class: 'modal-content' },
  templateUrl: './edit-taxes-dialog.component.html',
  styleUrls: ['./edit-taxes-dialog.component.scss'],
})
export class EditTaxesDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('tax') ngSelects: QueryList<NgSelectComponent>

  form = this.fb.group({
    taxes: this.fb.array([this.createTax()]),
  })

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  currencySymbol: string
  taxV3Modalities = TaxModalities
  taxV3ModalityLabels = taxV3ModalityLabels

  availableTaxes: TaxV3[] = []
  destroy$ = new Subject<boolean>()

  constructor(
    public dialogRef: MatDialogRef<EditTaxesDialogComponent>,
    private fb: FormBuilder,
    private storage: LocalStorage,
    @Inject(ENVIRONMENT) private environment,
    private dataCheckerService: DataCheckerService,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: EditTaxesDialogConfig
  ) {
    this.dataCheckerService.check([AccountGuard])
  }

  ngOnInit() {
    const taxes = isSomething(this.data.rental.taxes)
      ? this.data.rental.taxes
      : [
          {
            amount: undefined,
            type: 'percent',
            name: undefined,
            modality: TaxModalities.PerStay,
          },
        ]

    this.form = this.fb.group({
      taxes: this.fb.array(R.map((t: TaxV3) => this.createTax(t), taxes)),
    })

    this.currencySymbol = R.pathOr('$', ['currency', 'symbol'], this.data.rental)

    const user = this.storage.get(this.environment.storageKeys.user)

    combineLatest([this.store.pipe(select(selectAccount(user.account))), this.store.pipe(select(selectAllRentals))])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([account, rentals]) => {
        const accountTaxes = R.pathOr([], ['attributes', 'taxes'], account)
        const rentalTaxes = R.flatten(R.map((r) => r.taxes, rentals || []))

        this.availableTaxes = R.uniqBy(
          (t: TaxV3) => t.name,
          R.filter((t) => !!t, [...accountTaxes, ...rentalTaxes])
        )
      })
  }

  ngAfterViewInit() {
    this.ngSelects.changes.pipe(startWith(this.ngSelects)).subscribe((ngSelects) => {
      ngSelects.forEach((ngSelect) => {
        ngSelect['_handleTab'] = function () {
          if (this.isOpen) {
            if (this.itemsList.markedItem) {
              this.toggleItem(this.itemsList.markedItem)
            } else if (this.addTag) {
              this.selectTag()
            }
          }
        }
      })
    })
  }

  newTaxName(name) {
    const tax = new TaxV3()
    tax.name = name
    return tax
  }

  onAccountTaxSelected(tax: TaxV3, ctrl: FormGroup) {
    ctrl.patchValue(tax)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  close() {
    this.dialogRef.close()
  }

  addTax(tax?: TaxV3) {
    this.taxes.push(this.createTax(tax))
  }

  removeTax(index: number) {
    this.taxes.removeAt(index)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (form.invalid) {
      return
    }
    const taxes = form.getRawValue().taxes as TaxV3[]
    this.store.dispatch(updateRentalTaxes({ taxes, rentalId: this.data.rental.id }))
    this.close()
  }

  private createTax(tax?: TaxV3) {
    return this.fb.group({
      amount: [R.pathOr(undefined, ['amount'], tax), [Validators.required]],
      name: [R.pathOr(undefined, ['name'], tax), [Validators.required]],
      type: [R.pathOr('percent', ['type'], tax)],
      modality: [R.pathOr(TaxModalities.PerStay, ['modality'], tax)],
    })
  }
}
