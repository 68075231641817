import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './user.reducer'
import * as state from './user.state'
import { isRole, User } from './user.model'
import * as R from 'ramda'
import { sortAscend } from '../../functions'
import { UserRolePipe } from '../../pipes/user-role.pipe'
import { Role } from './user.interfaces'
import { isSomething } from '../../functions'
import { USER_ROLES } from '../../constants'

export const userState = createFeatureSelector<state.State>('user')

export const selectUsersLoaded = createSelector(userState, (state: state.State) => state.isLoaded)

export const isUpdatingUser = createSelector(userState, (state: state.State) => state.isUpdating)

export const selectRoles = createSelector(userState, (state: state.State) => {
  return sortAscend('label')(state.roles) as Role[]
})

export const selectAllUsers = createSelector(userState, reducer.selectAll)

export const selectUserEntities = createSelector(userState, reducer.selectEntities)

export const selectUsers = createSelector(selectAllUsers, selectRoles, (users: User[], roles: Role[]) => {
  return R.map(
    (user) => ({
      ...user,
      roleView: new UserRolePipe().transform(user.roles, roles),
    }),
    users
  ) as User[]
})

export const selectUsersByRole = (role: number) =>
  createSelector(selectAllUsers, (users: User[]) => {
    return users.filter((u) => isRole(u.roles, role))
  })

export const selectUserById = (userId: string) =>
  createSelector(selectUserEntities, (entities: { [id: string]: User }) => {
    return entities[userId]
  })

export const selectUsersByIds = (userIds: string[]) =>
  createSelector(selectUserEntities, (entities: { [id: string]: User }) => {
    let users: User[] = []

    R.forEach((userId: string | number) => {
      if (userId === '111' || userId === 111) {
        users.push(new User({ id: userId, firstname: 'Guest', lastname: '' } as Partial<User>))
      } else {
        let user = entities[userId]
        if (isSomething(user)) {
          users.push(user)
        }
      }
    }, userIds)

    return users
  })

export const selectOwners = createSelector(selectAllUsers, (users: User[]) => {
  const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop('name')))
  return sortByNameCaseInsensitive(R.filter((user: User) => isRole(user.roles, USER_ROLES.owner.value), users))
})

export const selectAllUserCountries = createSelector(selectAllUsers, (users: User[]) => {
  return R.pipe(R.map(R.pathOr('', ['address', 'country'])), R.uniq, R.reject(R.isEmpty))(users).sort()
})
