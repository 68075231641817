import { Inject, Injectable } from '@angular/core'
import { Storage } from './storage'
import * as R from 'ramda'
import { CURRENT_USER, STORAGE_PREFIX } from '../tokens'
import { User } from '../stores'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class UserStorage<T = any> extends Storage<T> {
  get userId() {
    return R.path(['id'], this.user$.value)
  }
  constructor(@Inject(CURRENT_USER) private user$: BehaviorSubject<User>, @Inject(STORAGE_PREFIX) storagePrefix) {
    super(localStorage, storagePrefix)
  }

  get(key: string, defaultValue: T = null) {
    const userId = this.userId
    if (R.isNil(userId)) {
      return defaultValue
    }
    const userKey = `${key}::${userId}`
    return super.get(userKey, defaultValue)
  }

  has(key: string) {
    const userId = this.userId
    if (R.isNil(userId)) {
      return false
    }
    const userKey = `${key}::${userId}`
    return super.has(userKey)
  }

  set(key: string, value: any = null) {
    const userId = this.userId
    if (!R.isNil(userId)) {
      const userKey = `${key}::${userId}`
      super.set(userKey, value)
    }
  }

  remove(key: string) {
    const userId = this.userId
    const userKey = `${key}::${userId}`
    super.remove(userKey)
  }
}
