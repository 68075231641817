import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Room } from './rooms.model'

export interface State extends EntityState<Room> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Room> = createEntityAdapter<Room>({
  selectId: (room: Room) => room?.pkey,
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
