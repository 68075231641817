import { createAction, props } from '@ngrx/store'
import { LosDiscount } from './los-discount.model'

export const LoadLosDiscounts = createAction('[LosDiscount] Load LOS Discounts', props<{ reset?: boolean }>())

export const LoadLosDiscountsComplete = createAction(
  '[DiscountCode] Load LOS Discounts Complete',
  props<{ items: LosDiscount[]; reset?: boolean }>()
)

export const AddLosDiscount = createAction('[LosDiscount] Add LOS Discount', props<{ payload: LosDiscount }>())
export const AddLosDiscountComplete = createAction(
  '[LosDiscount] Add LOS Discount Complete',
  props<{ item: LosDiscount }>()
)

export const GetLosDiscountComplete = createAction(
  '[LosDiscount] Get LOS Discount Complete',
  props<{ item: LosDiscount }>()
)

export const DeleteMultipleLosDiscount = createAction(
  '[LosDiscount] Delete Multiple LOS Discount',
  props<{ ids: string[] }>()
)
export const DeleteMultipleLosDiscountComplete = createAction(
  '[LosDiscount] Delete Multiple LOS Discount Complete',
  props<{ ids: string[] }>()
)

export const DeleteLosDiscount = createAction('[LosDiscount] Delete LOS Discount', props<{ id: string }>())
export const DeleteLosDiscountComplete = createAction(
  '[LosDiscount] Delete LOS Discount Complete',
  props<{ id: string }>()
)

export const DeleteLosDiscounts = createAction('[LosDiscount] Delete LOS Discounts', props<{ ids: string[] }>())
export const DeleteLosDiscountsComplete = createAction(
  '[LosDiscount] Delete LOS Discounts Complete',
  props<{ ids: string[] }>()
)
