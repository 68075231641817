import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'min' })
export class MinPipe implements PipeTransform {
  transform(v1: number | number[], ...others: number[]): any {
    if (typeof v1 === 'number') {
      return Math.min(v1, ...others)
    } else {
      return Math.min(...v1)
    }
  }
}

@Pipe({ name: 'max' })
export class MaxPipe implements PipeTransform {
  transform(v1: number | number[], ...others: number[]): any {
    if (typeof v1 === 'number') {
      return Math.max(v1, ...others)
    } else {
      return Math.max(...v1)
    }
  }
}
