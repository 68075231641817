import { Component, Input, OnInit } from '@angular/core'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import {
  Destroyable,
  DialogService,
  hasPaymentScheduleDaysOption,
  PaymentScheduleType,
  paymentScheduleTypeItems,
  Rental,
  RentalPaymentSchedule,
  selectRentalById,
  Toaster,
  untilDestroy,
  updateRentalPaymentSchedule,
} from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { distinctUntilChanged } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { CopySchedulesFromDialogComponent } from '../copy-schedules-from-dialog/copy-schedules-from-dialog.component'
import { CopySchedulesToDialogComponent } from '../copy-schedules-to-dialog/copy-schedules-to-dialog.component'

@Component({
  selector: 'app-rental-payment-schedule',
  templateUrl: './rental-payment-schedule.component.html',
  styleUrls: ['./rental-payment-schedule.component.scss'],
})
export class RentalPaymentScheduleComponent extends Destroyable implements OnInit {
  @Input() rental: Rental
  termsForm = this.fb.group({
    terms: this.fb.array([]),
  })

  termForm = this.fb.group({
    type: ['', [Validators.required]],
    days: ['', [Validators.required, Validators.min(0)]],
    percent: ['', [Validators.required]],
  })

  termTypes = paymentScheduleTypeItems

  get terms() {
    return this.termsForm.get('terms') as FormArray
  }

  get remainingPercentage() {
    const values = this.termsForm.getRawValue()
    const total = R.sum(R.map((t) => t.percent, values.terms))
    return R.times((i) => i + 1, 100 - total)
  }

  get remainingTypes() {
    const values = this.termsForm.getRawValue()
    const usedTypes = R.map((t) => t.type, values.terms)

    return R.reject((type) => R.contains(type.value, usedTypes), this.termTypes)
  }

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<any>,
    private dialogService: DialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectRentalById(this.rental.id)), untilDestroy(this)).subscribe((rental) => {
      this.setForm(rental.payment_schedule)
    })

    this.termForm
      .get('type')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroy(this))
      .subscribe((type) => {
        if (this.hasDays(type)) {
          this.termForm.get('days').setValidators([Validators.required, Validators.min(0)])
          this.termForm.get('days').updateValueAndValidity()
        } else {
          this.termForm.get('days').setValidators([])
          this.termForm.get('days').updateValueAndValidity()
        }
      })
  }

  hasDays(type: PaymentScheduleType) {
    return hasPaymentScheduleDaysOption(type)
  }

  setForm(schedule: RentalPaymentSchedule) {
    this.terms.clear()
    R.forEachObjIndexed((item: RentalPaymentSchedule[PaymentScheduleType], type: PaymentScheduleType) => {
      if (type === PaymentScheduleType.AT_BOOKING && item.percent === 0) {
        console.log('skip')
        return
      }
      this.terms.push(this.createTermForm(type, item))
    }, schedule)
  }

  canAddMoreTypes(controls: any[]) {
    const hasAtBooking = !!controls.find((control) => {
      const { type } = control.value
      return type === PaymentScheduleType.AT_BOOKING // && percent === 0
    })
    const max = hasAtBooking ? 5 : 4
    return controls?.length < max
  }

  onAddTerm() {
    if (this.termForm.invalid) {
      this.termForm.markAllAsTouched()
      return
    }
    const values = this.termForm.getRawValue()
    this.terms.push(this.createTermForm(values.type, values))
    this.termForm.reset()
  }

  createTermForm(type: PaymentScheduleType, schedule: RentalPaymentSchedule[PaymentScheduleType]) {
    const typeView = this.termTypes.find((t) => t.value === type)
    return this.fb.group({
      type: [{ value: type, disabled: true }],
      typeView: [{ value: typeView.label, disabled: true }],
      days: [{ value: schedule.days, disabled: true }],
      percent: [{ value: schedule.percent, disabled: true }],
    })
  }

  onRemoveTerm(index: number) {
    this.terms.removeAt(index)
  }

  onCopyFrom() {
    this.dialogService.openVariableDialog(CopySchedulesFromDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  onCopyTo() {
    this.dialogService.openVariableDialog(CopySchedulesToDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  onSave() {
    if (this.termsForm.invalid) {
      this.termsForm.markAllAsTouched()
      return
    }

    if (this.termsForm.get('terms').value?.length === 0) {
      this.toaster.error('You must set at least one payment term.')
      return
    }

    const { terms } = this.termsForm.getRawValue()
    const payment_schedule = {
      ...R.pick(['type'], terms),
    }
    R.forEachObjIndexed((value: { type: PaymentScheduleType; days?: number; percent: number }) => {
      if (hasPaymentScheduleDaysOption(value.type)) {
        payment_schedule[value.type] = { days: value.days, percent: value.percent }
      } else {
        payment_schedule[value.type] = { percent: value.percent }
      }
    }, terms)

    this.store.dispatch(updateRentalPaymentSchedule({ payload: payment_schedule, rentalId: this.rental.id }))
  }
}
