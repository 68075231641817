import { Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, mapTo, switchMap, switchMapTo, take } from 'rxjs/operators'
import * as fromRoot from '@tv3/store/state'
import { selectActivePlansLoaded } from '@tv3/store/plan/plan.selectors'
import { LoadActivePlans, LoadActivePlansComplete } from '@tv3/store/plan/plan.actions'
import { IsDataLoaded } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class ActivePlanGuard implements CanActivate, CanActivateChild, CanLoad, IsDataLoaded {
  constructor(private store: Store<fromRoot.State>, private actions: Actions) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return this.store.pipe(
      select(selectActivePlansLoaded),
      switchMap((isLoaded) => {
        if (!isLoaded) {
          this.store.dispatch(LoadActivePlans({}))
          return this.actions.pipe(ofType(LoadActivePlansComplete), mapTo(true))
        } else {
          return of(true)
        }
      }),
      take(1)
    )
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate()
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
