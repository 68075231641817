import { Injectable, NgZone } from '@angular/core'
import { of, throwError, Observable } from 'rxjs'
import { finalize, map, switchMap, tap, catchError } from 'rxjs/operators'
import * as lodash from 'lodash'
import { RateService, RateView, RentalRateItem, RentalRates, toMoment } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class RatesService {
  loading = true

  private rateCategories: string[] = []

  // rateViews[category][rental_id][date] = rateView
  private rateViews: Record<string, Record<string, Record<string, RateView>>>

  // rentalId => category => date => rate
  private ratesCache: Record<string, Record<string, Record<string, RentalRateItem>>> = {}

  // rateViews[rental_id] = rateView
  private baseRateViews: Record<string, RateView>

  private loadedRentals: Record<string, boolean> = {}

  constructor(private rateService: RateService, private zone: NgZone) {}

  fetchRates(
    rentalIds: string[],
    refresh = false
  ): Observable<{
    rates: RentalRates[]
    rateCategories: string[]
    rateViews: Record<string, Record<string, Record<string, RateView>>>
    baseRateViews: Record<string, RateView>
  }> {
    if (refresh) {
      this.loadedRentals = {}
      this.rateCategories = []
      this.rateViews = {}
      this.baseRateViews = {}
    }

    const shouldFetchRates = rentalIds.filter((rentalId) => !this.loadedRentals[rentalId]).length > 0

    if (!shouldFetchRates) return of(null)

    return of(null).pipe(
      tap(() => {
        this.loading = true

        rentalIds.forEach((rentalId) => {
          this.loadedRentals[rentalId] = true
        })
      }),
      switchMap(() => this.rateService.all(rentalIds)),
      map((rates: any) => {
        if (!rates) return null

        this.rateViews = lodash.merge({}, this.rateViews, this.rateService.getRateViews(rates))
        this.baseRateViews = lodash.merge({}, this.baseRateViews, this.rateService.getBaseRateViews(rates))
        this.rateCategories = lodash.uniq(this.rateCategories.concat(this.rateService.getRateCategories(rates)))

        return {
          rates,
          rateCategories: this.rateCategories,
          rateViews: this.rateViews,
          baseRateViews: this.baseRateViews,
        }
      }),
      catchError((error) => {
        rentalIds.forEach((rentalId) => {
          this.loadedRentals[rentalId] = false
        })

        return throwError(error)
      }),
      finalize(() => {
        this.zone.run(() => {
          this.loading = false
        })
      })
    )
  }

  fetchRatesV2(rentalIds: string[], start: number, end: number, refresh = false) {
    const startDate = toMoment(start).startOf('month')
    const endDate = toMoment(end).endOf('month')
    start = startDate.unix()
    end = endDate.unix()

    if (!refresh) {
      const months = lodash.times(endDate.diff(startDate, 'months') + 1, (i) =>
        startDate.clone().add(i, 'month').unix()
      )
      rentalIds = lodash.filter(rentalIds, (t) => months.some((d) => !this.ratesCache[t]?.['default']?.[d]))

      if (!rentalIds.length) return of(this.ratesCache)
    }

    this.loading = true
    return this.rateService.getRates(rentalIds, start, end, []).pipe(
      map(({ items }) => {
        const ratesView = lodash.mapValues(
          lodash.keyBy(items, (t) => t.rental_id),
          ({ categories }) => {
            return lodash.mapValues(categories, (rates) => lodash.keyBy(rates, (r) => r.start))
          }
        )
        this.ratesCache = lodash.merge({}, this.ratesCache, ratesView)

        return this.ratesCache
      }),
      finalize(() => (this.loading = false))
    )
  }

  getRate(rateCategory: string, rentalId: string, date: number): RateView {
    const rentalRateViews: any = lodash.get(this.rateViews, [rateCategory, rentalId])

    return lodash.get(rentalRateViews, date) || lodash.get(this.baseRateViews, rentalId) || null
  }

  getRates(rentalId: string, date: number) {
    const rates: RateView[] = lodash.map(this.rateViews, (rentals) => {
      return lodash.get(rentals, [rentalId, date])
    })

    return rates
  }

  getRatePrice(rate: RateView, date: number) {
    if (!rate) return null
    return this.rateService.isWeekend(date) && rate.weekend ? rate.weekend : rate.price
  }
}
