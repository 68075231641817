export const airbnbOccupancyTaxTypes = [
  { name: 'Hotel tax', id: 'pass_through_hotel_tax' },
  { name: 'Lodging tax', id: 'pass_through_lodging_tax' },
  { name: 'Room tax', id: 'pass_through_room_tax' },
  { name: 'Tourist tax', id: 'pass_through_tourist_tax' },
  { name: 'Transient occupancy tax', id: 'pass_through_transient_occupancy_tax' },
  { name: 'Sales tax', id: 'pass_through_sales_tax' },
  { name: 'VAT/GST', id: 'pass_through_vat_gst' },
  { name: 'Tourism Assessment/Fee', id: 'pass_through_tourism_assessment_fee' },
]

export const airbnbOccupancyTaxesAttestation = [
  'That the added tax amount is accurate',
  'That they are responsible for remitting the added taxes to the relevant tax authorities',
  'And that Airbnb may disclose tax-related and transaction information to the relevant tax authorities',
]

export const airbnbOccupancyTaxCollectionType = [
  {
    id: 'INELIGIBLE',
    description:
      'Airbnb may or may not collect certain taxes for this listing, hosts are not allowed to set custom taxes.',
  },
  { id: 'NO_AIRBNB_COLLECTED_TAX', description: 'Airbnb doesn’t collect taxes, hosts can add custom taxes.' },
  {
    id: 'OVERRIDE_AIRBNB_COLLECTED_TAX',
    description:
      '	Airbnb automatically collects certain taxes and remits them. Hosts who would like to assume the responsibility of remitting taxes, can opt-out of Airbnb’s automatic tax collection and remittance by adding your own custom taxes instead.',
  },
  {
    id: 'STACKED_AIRBNB_COLLECTED_TAX',
    description:
      '	Airbnb will collect and remit certain taxes directly to tax authorities and will also collect the added taxes set by the host and remit them directly to the host.',
  },
]

export const airbnbOccupancyTaxAmountTypes = [
  { name: 'Percent Per Reservation', id: 'percent_per_reservation' },
  { name: 'Flat Per Guest', id: 'flat_per_guest' },
  { name: 'Flat Per Night', id: 'flat_per_night' },
  { name: 'Flat Per Guest Per Night', id: 'flat_per_guest_per_night' },
]

export const airbnbOccupancyTaxBase = [
  // for percent_per_reservation
  { name: 'Base Price', id: 'base_price' },
  { name: 'Resort Fee', id: 'pass_through_resort_fee' },
  { name: 'Community Fee', id: 'pass_through_community_fee' },
  { name: 'Management Fee', id: 'pass_through_management_fee' },
  { name: 'Linen Fee', id: 'pass_through_linen_fee' },
  { name: 'Cleaning Fee', id: 'pass_through_cleaning_fee' },
  { name: 'Pet Fee', id: 'pass_through_pet_fee' },
]

export interface AirbnbPassThroughOccupancyTax {
  tax_type: string // "pass_through_sales_tax",
  amount_type: string // "percent_per_reservation",
  taxable_base: string[] // ["base_price"],
  amount: number // 90,

  business_tax_id: string // "23df34",
  no_business_tax_id_declaration: boolean // false,

  tot_registration_id: string // "as23",
  no_tot_registration_id_declaration: boolean // false,

  long_term_stay_exemption: number // 23,
  only_first_nights_exemption: number // 23,
  max_cap_per_person_per_night: number // 34

  attestation: true // false,
}
