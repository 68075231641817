import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { GatewayTypeService } from './gateway-type.service'
import { LoadPaymentGatewayTypes, LoadPaymentGatewayTypesComplete } from './gateway-type.actions'

@Injectable()
export class GatewayTypeEffects {
  @Effect()
  loadPaymentTypes$ = this.actions$.pipe(
    ofType(LoadPaymentGatewayTypes),
    switchMap(() =>
      this.paymentGatewayType.all().pipe(
        map((types) => LoadPaymentGatewayTypesComplete({ types })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<any>, private paymentGatewayType: GatewayTypeService) {}
}
