import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { RentalService, Toaster } from '@tokeet-frontend/tv3-platform'
import { GuestService } from '@tv3/store/guest/guest.service'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { InvoiceService } from '@tokeet-frontend/invoices'
import { EMPTY } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { Notification, NotificationLinkTypes } from '../store/notification/notification.model'
import {
  OpenGuestOverlay,
  OpenInquiryOverlay,
  OpenInvoiceOverlay,
  OpenRentalOverlay,
} from '../store/overlay/overlay.actions'
import * as fromRoot from '../store/state'

@Injectable({
  providedIn: 'root',
})
export class NotificationDetailService {
  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private toaster: Toaster,
    private inquiryService: InquiryService,
    private invoiceService: InvoiceService,
    private guestService: GuestService,
    private rentalService: RentalService
  ) {}

  tryToOpenInquiryDetail(id: string) {
    this.inquiryService
      .loadPreview(id)
      .pipe(
        catchError(() => {
          this.toaster.error('Inquiry no longer exists.', 'Not found')
          return EMPTY
        })
      )
      .subscribe(() => {
        this.store.dispatch(OpenInquiryOverlay({ inquiryId: id }))
      })
  }

  tryToOpenInvoiceDetail(id: string) {
    this.invoiceService
      .get(id)
      .pipe(
        catchError(() => {
          this.toaster.error('Invoice no longer exists.', 'Not found')
          return EMPTY
        })
      )
      .subscribe(() => {
        this.store.dispatch(OpenInvoiceOverlay({ invoiceId: id }))
      })
  }

  tryToOpenGuestDetail(id: string) {
    this.guestService
      .get(id)
      .pipe(
        catchError(() => {
          this.toaster.error('Guest no longer exists.', 'Not found')
          return EMPTY
        })
      )
      .subscribe(() => {
        this.store.dispatch(OpenGuestOverlay({ guestId: id }))
      })
  }

  tryToOpenRentalDetail(id: string) {
    this.rentalService
      .get(id)
      .pipe(
        catchError(() => {
          this.toaster.error('Rental no longer exists.', 'Not found')
          return EMPTY
        })
      )
      .subscribe((rental) => {
        if (rental) {
          this.store.dispatch(OpenRentalOverlay({ rental }))
        }
      })
  }

  openDetail(notification: Notification) {
    switch (notification.linkType) {
      case NotificationLinkTypes.Inquiry:
      case NotificationLinkTypes.Booking:
      case NotificationLinkTypes.Contract:
        this.tryToOpenInquiryDetail(notification.linkId)
        break
      case NotificationLinkTypes.Invoice:
        this.tryToOpenInvoiceDetail(notification.linkId)
        break
      case NotificationLinkTypes.Guest:
        this.tryToOpenGuestDetail(notification.guestId)
        break
      case NotificationLinkTypes.Rental:
        this.tryToOpenRentalDetail(notification.rentalId)
        break
      default:
        break
    }
  }
}
