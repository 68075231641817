import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { startWith } from 'rxjs/operators'
import { automationTimeEvents } from '../../store'

export function createAutomationTimeForm(fb: FormBuilder) {
  return fb.group({
    event: [''],
    days: [0],
    hours: [0, [Validators.min(0), Validators.max(24)]],
  })
}

@Component({
  selector: 'app-automation-time-form',
  templateUrl: './automation-time-form.component.html',
  styleUrls: ['./automation-time-form.component.scss'],
})
export class AutomationTimeFormComponent extends Destroyable implements OnInit {
  @Input() eventCtrl: FormControl
  @Input() form: FormGroup
  timeEvents = automationTimeEvents

  ngOnInit(): void {
    const timeEventCtrl = this.form.get('event')
    this.eventCtrl.valueChanges.pipe(startWith(this.eventCtrl.value), untilDestroy(this)).subscribe((event) => {
      if (event === 'time') {
        timeEventCtrl?.setValidators([Validators.required])
      } else {
        timeEventCtrl?.clearValidators()
      }
      timeEventCtrl?.updateValueAndValidity()
    })
  }
}
