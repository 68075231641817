import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import {
  ConfirmDialogService,
  Destroyable,
  genders,
  languages,
  RemoveUser,
  selectAllUsers,
  Toaster,
  untilDestroy,
  UpdateUserSettings,
  User,
  USER_ROLES,
  UserService,
  mfaDeliveryMethodOptions,
  MFADeliveryMethod,
  UpdateUserMFA,
  selectIsAccountMFAEnabled,
  ActionFailed,
  DialogService,
} from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { BehaviorSubject } from 'rxjs'
import { userRoleToString, userRoleToFriendlyString } from '@tv3/utils/functions/user-role-to-string'
import { AuthService } from '@tv3/services/auth.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UserDialogService } from '@tv3/containers/settings/user-settings/user-dialog/user-dialog.service'
import { map, switchMap } from 'rxjs/operators'
import { UserMfaDialogComponent } from '../user-mfa-dialog/user-mfa-dialog.component'

@Component({
  selector: 'app-user-overlay',
  templateUrl: './user-overlay.component.html',
  styleUrls: ['./user-overlay.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class UserOverlayComponent extends Destroyable implements OnInit, OnDestroy {
  preferenceForm = this.fb.group({
    disabled: [false],
    signature: [false],
    automata: [false],
    ownercenter: [false],
    rategenie: [false],
    webready: [false],
    checklist: [false],
    margins: [true],
    total: [false],
  })

  form = this.fb.group({
    preference: this.preferenceForm,
  })

  isAccountMFAEnabled$ = this.store.pipe(select(selectIsAccountMFAEnabled))

  mfaDeliveryMethodOptions = mfaDeliveryMethodOptions
  mfaForm = this.fb.group({
    status: [0],
    delivery: [MFADeliveryMethod.Email],
  })
  get isTotpSelected() {
    return this.mfaForm.get('delivery').value === MFADeliveryMethod.TOTP
  }
  refreshTotpStatus$ = new BehaviorSubject<boolean>(true)
  isTotpDeviceRegistered$ = this.refreshTotpStatus$.pipe(
    switchMap(() => this.auth.getTotpSetupStatus()),
    map((s) => s.status === 'verified')
  )

  users: User[]
  user: User
  get role() {
    return userRoleToFriendlyString(this.user.roles)
  }

  get language() {
    const lang = this.user?.attributes?.language
    if (!lang) return ''
    return languages.find((l) => l['1'] === lang)?.name
  }

  genders = genders

  currentTab$ = new BehaviorSubject<string>('settings')
  rentalsSearchTerm = ''

  constructor(
    public dialogRef: MatDialogRef<UserOverlayComponent>,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private toast: Toaster,
    private auth: AuthService,
    private dialog: DialogService,
    private userService: UserService,
    private userDialog: UserDialogService,
    private confirmDialogService: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User }
  ) {
    super()
    this.mfaForm.disable()
  }

  ngOnInit() {
    this.user = this.data.user

    this.store.pipe(select(selectAllUsers), untilDestroy(this)).subscribe((users) => {
      this.users = users
      this.user = R.find((u) => u.id === this.data.user.id, users)
      this.mfaForm.patchValue(this.user.mfa_config || {})
    })

    this.preferenceForm.patchValue({
      disabled: R.pathOr(false, ['settings', 'disabled'], this.user),
      signature: R.pathOr(false, ['settings', 'signature'], this.user),
      automata: R.pathOr(false, ['settings', 'automata'], this.user),
      ownercenter: R.pathOr(false, ['settings', 'ownercenter'], this.user),
      rategenie: R.pathOr(false, ['settings', 'rategenie'], this.user),
      webready: R.pathOr(false, ['settings', 'webready'], this.user),
      checklist: R.pathOr(false, ['settings', 'checklist'], this.user),
      margins: R.pathOr(true, ['settings', 'margins'], this.user),
      total: R.pathOr(false, ['settings', 'total'], this.user),
    })

    if (!this.canRestrictRentals()) {
      this.currentTab$.next('settings')
    }

    this.preferenceForm.valueChanges.pipe(untilDestroy(this)).subscribe((values) => {
      this.store.dispatch(UpdateUserSettings({ user: this.user, settings: values }))
    })
  }

  get isCurrentUser() {
    return this.auth.user.id === this.user.id
  }

  get isCurrentAdmin() {
    return this.auth.isAdmin()
  }

  close() {
    this.dialogRef.close()
  }

  onResetPassword() {
    this.userService.forgot(R.toLower(this.user.primaryEmail)).subscribe(
      () => this.toast.success('A reset email has been sent to the user.'),
      () => this.toast.error('Something wrong, please check users email address, and try again')
    )
  }

  onResetTotp() {
    this.confirmDialogService
      .confirm({
        title: 'Warning!',
        body: 'Are you sure you want to deregister auth device?',
      })
      .pipe(switchMap(() => this.auth.deleteTotpSetup()))
      .subscribe(
        () => {
          if (this.user?.mfa_config?.delivery === MFADeliveryMethod.TOTP) {
            this.mfaForm.patchValue({ delivery: MFADeliveryMethod.Email })
            this.store.dispatch(
              UpdateUserMFA({
                userId: this.user.id,
                payload: { ...this.user?.mfa_config, delivery: MFADeliveryMethod.Email },
              })
            )
          }
          this.refreshTotpStatus$.next(true)
        },
        (error) => this.store.dispatch(ActionFailed({ error }))
      )
  }

  onDelete() {
    this.confirmDialogService
      .confirm({
        title: 'Delete this user?',
        body: 'Are you sure you want to delete this user?',
      })
      .subscribe(() => {
        this.store.dispatch(RemoveUser({ id: this.user.id }))
        this.close()
      })
  }

  isReadOnly(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.readOnly.name
  }

  isAdmin(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.admin.name
  }

  isOwner(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.owner.name
  }

  isStaff(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.staff.name
  }

  isPropertyManager(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.manager.name
  }

  isPropertyOwner(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.owner.name
  }

  isAccounting(user: User) {
    return userRoleToString(user.roles) === USER_ROLES.accounting.name
  }

  canEnableAutomata() {
    return (
      !this.isReadOnly(this.user) &&
      !this.isAdmin(this.user) &&
      !this.isOwner(this.user) &&
      !this.isStaff(this.user) &&
      !this.isAccounting(this.user)
    )
  }

  canEnableSignature() {
    return (
      !this.isReadOnly(this.user) &&
      !this.isAdmin(this.user) &&
      (this.isPropertyManager(this.user) || this.isPropertyOwner(this.user))
    )
  }

  canEnableOwnerCenter() {
    return !this.isReadOnly(this.user) && !this.isAdmin(this.user) && !this.isStaff(this.user)
  }

  canEnableRategenie() {
    return (
      !this.isReadOnly(this.user) && !this.isAdmin(this.user) && !this.isStaff(this.user) && !this.isOwner(this.user)
    )
  }

  canEnableWebready() {
    return (
      !this.isReadOnly(this.user) && !this.isAdmin(this.user) && !this.isStaff(this.user) && !this.isOwner(this.user)
    )
  }

  canEnableChecklist() {
    return (
      !this.isReadOnly(this.user) && !this.isAdmin(this.user) && !this.isStaff(this.user) && !this.isOwner(this.user)
    )
  }

  canEnableMargins() {
    return !this.isAdmin(this.user)
  }

  canRestrictRentals() {
    return !this.isAdmin(this.user) && !this.isOwner(this.user)
  }

  onEdit() {
    this.userDialog.open(this.user).subscribe()
  }

  onConfigMFA() {
    this.dialog.openFixedDialog(UserMfaDialogComponent, { width: '600px', height: 'auto' })
  }
}
