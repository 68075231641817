import { Component, HostListener, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-channel-logo',
  template: ` <img [src]="logoSrc" alt="Logo" class="logo" /> `,
  styleUrls: ['./channel-logo.component.scss'],
})
export class ChannelLogoComponent implements OnInit {
  @Input() logoColor: string
  @Input() logoGrey: string

  logoSrc: string

  @HostListener('mouseenter') onMouseEnter() {
    this.logoSrc = this.logoColor
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.logoSrc = this.logoGrey
  }

  ngOnInit() {
    this.logoSrc = this.logoGrey
  }
}
