<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-shield-check"></i> SSL Certificate
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h5>
  <button (click)="dialogRef.close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <mat-form-field2 class="mat-block">
          <textarea matInput placeholder="Private Key" formControlName="key" rows="4"></textarea>
        </mat-form-field2>
      </div>
      <div class="col-12">
        <mat-form-field2 class="mat-block">
          <textarea matInput placeholder="Certificate" formControlName="cert" rows="4"></textarea>
        </mat-form-field2>
      </div>
      <div class="col-12">
        <mat-form-field2 class="mat-block">
          <textarea matInput placeholder="CA" formControlName="ca" rows="4"></textarea>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="dialogRef.close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn" [disabled]="!isEditable">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
