import { Pipe, PipeTransform } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Rental, selectAllRentals } from '../stores'

@Pipe({
  name: 'rentalName',
})
export class RentalNamePipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  transform(rentalId): Observable<string> {
    return this.store.pipe(
      select(selectAllRentals),
      map((rentals) => R.find((r: Rental) => r.id === rentalId, rentals)),
      map((rental) => R.pathOr('', ['name'], rental))
    )
  }
}
