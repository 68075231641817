import { Expose, plainToClass, Serializable, Transform, Type } from '@tokeet-frontend/tv3-platform'
import { IBookingEngineCost } from '@tv3/interfaces/responses/booking-engine-cost.response'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { AuditDiscount, BookingEngineBookingFee, BookingEngineTax, TaxModalities } from '@tokeet/cost-resolver'

function normalizeNumber(v) {
  return lodash.toNumber(v) || 0
}

export class BookingEngineCost extends Serializable<BookingEngineCost> {
  @Transform(
    (value) =>
      R.map(
        (t) => ({ ...t, modality: t.modality || TaxModalities.PerStay, amount: normalizeNumber(t.amount) }),
        value || []
      ),
    { toClassOnly: true }
  )
  taxes: BookingEngineTax[]

  @Expose({ name: 'ratecategory' })
  rateCategory: string

  @Expose({ name: 'discounttotal' })
  @Transform((value) => normalizeNumber(value), { toClassOnly: true })
  discountTotal: number

  @Expose({ name: 'taxpercent' })
  taxPercent: number

  @Expose({ name: 'extraguests' })
  @Transform((value) => normalizeNumber(value), { toClassOnly: true })
  extraGuests: number

  extraBedrooms: number

  @Expose({ name: 'taxtotal' })
  @Transform((value) => normalizeNumber(value), { toClassOnly: true })
  taxTotal: number

  minimumstay: number | null
  maximumstay: number | null
  maxguests: number | null
  sleep_min: number | null

  @Expose({ name: 'discountfees' })
  @Type(() => DiscountFee)
  @Transform((value) => (lodash.isNumber(value) ? { amount: value } : value), { toClassOnly: true })
  discountFees: DiscountFee[]

  @Expose({ name: 'auditdiscount' })
  @Transform((v) => lodash.map(v, (t) => ({ ...t, disc_amount: normalizeNumber(t.disc_amount) })))
  auditDiscounts: AuditDiscount[]

  @Transform((value) => normalizeNumber(value), { toClassOnly: true })
  bookfeestotal: number

  @Expose({ name: 'bookingfees' })
  @Transform((v) => lodash.map(v, (t) => ({ ...t, amount: normalizeNumber(t.amount) })))
  bookingFees: BookingEngineBookingFee[]

  auditdays?: BookingEngineAuditDay[]

  @Transform((value, obj) => normalizeNumber(value), { toClassOnly: true })
  base: number

  @Transform((value) => normalizeNumber(value), { toClassOnly: true })
  total: number

  extraBedroomsBase: number

  static deserialize(data: IBookingEngineCost): BookingEngineCost {
    const engineCost: BookingEngineCost = plainToClass(BookingEngineCost, data)
    engineCost.extraBedrooms = getExtraBedroomsFee(engineCost.auditdays)
    engineCost.extraBedroomsBase = engineCost.base - engineCost.extraBedrooms
    return engineCost
  }
}

export function getExtraBedroomsFee(items: BookingEngineAuditDay[]): number {
  return lodash.sumBy(items, (item: BookingEngineAuditDay) => +(item.booking_occupancy?.bedroom_adjustment || 0) || 0)
}

export class DiscountFee {
  amount: number
}

export interface BookingEngineAuditDay {
  date: string // YYYY-MM-DD
  rate: number
  booking_occupancy?: {
    occupancy: number
    additional_bedroom_fee: number
    bedroom_threshold: number
    bedroom_adjustment?: number
  }
  restrictions?: {
    checkout_blocked?: 1
    checkin_blocked?: 1
    maximumstay_arrival?: number // in days,
    minimumstay_arrival?: number
    max_advance_res: string // "6D0H",
    min_advance_res: string // "2D0H",
  }
}
