<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill text-center py-5">
    <div class="w-75 m-auto">
      <img src="/assets/images/channel-wizards/complete-icon.png" alt="" height="50" />
      <h6 class="mt-2">Congratulations!</h6>
      <p>
        Great! Now you're ready to connect each listing to the corresponding AdvanceCM rental(s). You will now be taken
        to the Expedia channel details screen where clicking into any of your rooms will initiate the wizard steps for
        each room.
      </p>
      <div class="alert alert-secondary-warning2 align-items-start text-left">
        <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
        <div class="alert-content">
          <b>Note:</b> If you are unable to see your listings under mapped rentals, please give Agoda.com time to fulfil
          your request and refresh the room list using the Actions button.
        </div>
      </div>
      <div class="d-flex gap-3 align-items-center justify-content-center mt-4">
        <button class="btn btn-secondary-info" (click)="reset.emit()">
          <i class="far fa-link"></i> Connect another account
        </button>
        <button class="btn btn-secondary-primary" (click)="channel.emit()">
          <i class="cs-icon cs-icon-list-square"></i> Channel Details
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4 py-5">
    <img src="/assets/images/channel-wizards/agoda/complete.png" alt="" class="w-100" />
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end">
  <button class="btn btn-secondary-info" (click)="done.emit()">
    <i class="cs-icon cs-icon-tasks"></i>
    Done
  </button>
</div>
