<div class="modal-header">
  <h6 class="modal-title d-flex align-items-center gap-2"><i class="cs-icon cs-icon-save"></i> Save Template</h6>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row no-margin">
    <div class="offset-2 col-md-8">
      <mat-form-field2 class="mat-block">
        <input type="text" matInput required placeholder="Template Name" formControlName="name" />
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
          Template Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
          Template Name must be at least <strong>2</strong> characters long
        </mat-error>
        <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
          Template Name must be at most <strong>50</strong> characters long
        </mat-error>
        <mat-hint align="end">{{ form.get('name').value.length || 0 }}/50</mat-hint>
      </mat-form-field2>
    </div>

    <div class="offset-2 col-md-8">
      <mat-form-field2 class="mat-block">
        <input type="text" matInput placeholder="Description" formControlName="description" />
        <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('minlength')">
          Description must be at least <strong>2</strong> characters long
        </mat-error>
        <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('maxlength')">
          Description must be at most <strong>100</strong> characters long
        </mat-error>
        <mat-hint align="end">{{ form.get('description').value.length || 0 }}/100</mat-hint>
      </mat-form-field2>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button *ngIf="isEdit" (click)="onSaveAsNew(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save As New
  </button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> <span *ngIf="isEdit">Update</span><span *ngIf="!isEdit">Save</span>
  </button>
</div>
