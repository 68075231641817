import { deserialize, Serializable, Type, Expose } from '@tokeet-frontend/tv3-platform'

export enum ContractSignStatus {
  Decline = 'declined',
  Signed = 'signed',
  Opened = 'opened',
  Sent = 'sent',
}

export enum ContractSignerType {
  Custom = 0,
  Guest = 1,
  Staff = 2,
}

export enum ContractStatus {
  Pending = 'pending',
  Completed = 'completed',
  Declined = 'declined',
  Canceled = 'cancelled',
  Voided = 'voided',
}

export enum ContractType {
  Contract = 'contract',
  Form = 'form',
  Document = 'document',
}

export class ContractSigner extends Serializable<ContractSigner> {
  id?: string
  name: string
  email: string
  role: string
  status: ContractSignStatus
  type: ContractSignerType

  static deserialize(data: any): ContractSigner {
    return deserialize(ContractSigner, data)
  }
}

export class Contract extends Serializable<Contract> {
  @Expose({ name: 'contract_id' })
  id: string

  @Expose({ name: 'template_id' })
  templateId: string
  type: ContractType
  @Expose({ name: 'pdf_file_url' })
  pdfFileUrl: string
  name: string
  title: string
  account: string
  @Expose({ name: 'user_id' })
  userId: string // who send this contract
  sender_name: string
  attributes: any
  updated: number
  created: number
  @Type(() => ContractSigner)
  signers: ContractSigner[]
  @Expose({ name: 'status_at' })
  statusAt: number
  status: ContractStatus
  auto: boolean // generated by Automata
  static deserialize(data: any): Contract {
    return deserialize(Contract, data)
  }
}
