<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title d-flex align-items-center gap-2">
        <ng-container *ngIf="rental">
          <app-rental-image [rental]="rental" [size]="35"></app-rental-image>
          {{ rental.name }}
        </ng-container>
      </div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li [class.active]="tab === tabs.Calendar">
        <a
          (click)="tab = tabs.Calendar"
          matTooltip="A Standard Calendar showing rates for all rate categories."
          matTooltipPosition="below"
        >
          Rates Calendar
        </a>
      </li>
      <li [class.active]="tab === tabs.Rates">
        <a
          (click)="tab = tabs.Rates"
          matTooltip="All rates and rate periods shown in tabular format."
          matTooltipPosition="below"
        >
          Rates Table
        </a>
      </li>
      <li [class.active]="tab === tabs.RateMappings">
        <a
          (click)="tab = tabs.RateMappings"
          matTooltip="Rate Maps allow you to streamline the pushing of rates to connected channels. Once you have created your rate maps you may then update all connected channels by clicking the Push Rates button at right. Rate Maps are also used to automatically update channel rates if you use Dynamic Rates. You must, however, enable auto sync for the rates to be automatically updated."
          matTooltipPosition="below"
        >
          Rate Mappings
        </a>
      </li>
      <li *ngIf="showDiscounts" [class.active]="tab === tabs.Discounts">
        <a (click)="tab = tabs.Discounts" matTooltip="All discounts for this rental" matTooltipPosition="below">
          Discounts
        </a>
      </li>
      <li [class.active]="tab === tabs.Settings">
        <a
          (click)="tab = tabs.Settings"
          [matTooltip]="
            hasIncompleteBaseRate
              ? 'Base Rate settings are required to ensure your rate mappings and future rate pushes go through properly. Please make sure that a nightly rate and minimum stay are set on this tab even if you have no plans to use the Base Settings as a default rate.'
              : 'Adjust the Base Rate settings for your rental.'
          "
          matTooltipPosition="below"
        >
          Settings <i *ngIf="hasIncompleteBaseRate" class="fal fa-exclamation-triangle text-danger"></i>
        </a>
      </li>
    </ul>
    <div class="modal-body">
      <div *ngIf="rental" class="tab-content">
        <app-rental-rates-calendar *ngIf="tab === tabs.Calendar" [rental]="rental" [loading]="!initialized">
        </app-rental-rates-calendar>

        <app-rates-table *ngIf="tab === tabs.Rates" [rental]="rental"> </app-rates-table>

        <app-rental-rate-mappings-table *ngIf="tab === tabs.RateMappings" [defaultRental]="rental">
        </app-rental-rate-mappings-table>

        <app-discounts-table *ngIf="tab === tabs.Discounts" [showAddBtn]="true" [rental]="rental">
        </app-discounts-table>

        <app-rental-rate-settings *ngIf="tab === tabs.Settings" [rental]="rental"> </app-rental-rate-settings>
      </div>
    </div>
  </div>
</div>
