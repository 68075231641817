import * as lodash from 'lodash'

export enum GvrIconDisapprovalReason {
  /** Not specified. */
  IMAGE_DISAPPROVAL_REASON_UNSPECIFIED = 'IMAGE_DISAPPROVAL_REASON_UNSPECIFIED',
  /** Icon image does not match the favicon or artwork on the advertiser’s final URL. */
  NOT_LIKE_SITE = 'NOT_LIKE_SITE',
  /** Icon image contains offensive content. */
  OFFENSIVE = 'OFFENSIVE',
  /** Icon image is low quality. */
  LOW_QUALITY = 'LOW_QUALITY',
  /** Icon image is animated. */
  ANIMATED = 'ANIMATED',
  /** Icon image does not have a transparent or non-white background. */
  BAD_BACKGROUND = 'BAD_BACKGROUND',
  /** Icon image contains text that is too small. */
  TEXT_TOO_SMALL = 'TEXT_TOO_SMALL',
}

export interface GvrDisplayNameDisapprovalReason {
  languageCode: string
  disapprovalReason: GvrDisplayNameDisapprovalReasonCode
}

export enum GvrDisplayNameDisapprovalReasonCode {
  /** Not specified. */
  DISAPPROVAL_REASON_UNSPECIFIED = 'DISAPPROVAL_REASON_UNSPECIFIED',
  /** The display name contains punctuation. */
  PUNCTUATION = 'PUNCTUATION',
  /** The display name contains marketing language. */
  MARKETING_LANGUAGE = 'MARKETING_LANGUAGE',
  /** The display name does not match the landing page. */
  LANDING_PAGE_NOT_MATCHED = 'LANDING_PAGE_NOT_MATCHED',
}

export function getListingDisplayNameRejectionReasonText(reason: GvrDisplayNameDisapprovalReasonCode) {
  switch (reason) {
    case GvrDisplayNameDisapprovalReasonCode.PUNCTUATION:
      return `The display name contains punctuation.`
    case GvrDisplayNameDisapprovalReasonCode.MARKETING_LANGUAGE:
      return `The display name contains marketing language.`
    case GvrDisplayNameDisapprovalReasonCode.LANDING_PAGE_NOT_MATCHED:
      return `The display name does not match the landing page.`
    case GvrDisplayNameDisapprovalReasonCode.DISAPPROVAL_REASON_UNSPECIFIED:
      return `Not specified.`
    default:
      return lodash.startCase(lodash.toLower(reason))
  }
}

export function getListingIconRejectionReasonText(reason: GvrIconDisapprovalReason) {
  switch (reason) {
    case GvrIconDisapprovalReason.NOT_LIKE_SITE:
      return `Icon image does not match the favicon or artwork on the advertiser's final URL.`
    case GvrIconDisapprovalReason.OFFENSIVE:
      return `Icon image contains offensive content.`
    case GvrIconDisapprovalReason.LOW_QUALITY:
      return `Icon image is low quality.`
    case GvrIconDisapprovalReason.BAD_BACKGROUND:
      return `Icon image does not have a transparent or non-white background.`
    case GvrIconDisapprovalReason.TEXT_TOO_SMALL:
      return `Icon image contains text that is too small.`
    case GvrIconDisapprovalReason.ANIMATED:
      return `Icon image is animated.`
    case GvrIconDisapprovalReason.IMAGE_DISAPPROVAL_REASON_UNSPECIFIED:
      return `Not specified.`
    default:
      return lodash.startCase(lodash.toLower(reason))
  }
}
