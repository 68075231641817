export function ensureLinkTag(id: string, href: string) {
  if (!document.getElementById(id)) {
    const link = document.createElement('link')

    link.id = id
    link.rel = 'stylesheet'
    link.href = href

    document.head.appendChild(link)
  }
}
