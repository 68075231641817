import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { AttributeDescriptionResponse, CreateAttributeDescriptionPayload } from './model'

@Injectable()
export class EntityAttributeDescService {
  constructor(private http: HttpClient) {}

  all() {
    let url = `@api/attributedescription/all`
    return this.http.get<AttributeDescriptionResponse[]>(url)
  }

  getForType(entityType: DataEntityType) {
    const url = `@api/attributedescription/entity/${entityType}/`
    return this.http.get<AttributeDescriptionResponse[]>(url)
  }

  create(entityType: DataEntityType, payload: CreateAttributeDescriptionPayload[]) {
    const url = `@api/attributedescription/${entityType}/`
    return this.http.post<AttributeDescriptionResponse>(url, { attribute_fields: payload })
  }

  delete(entityId: string, attrId: string) {
    const url = `@api/attributedescription/${entityId}/${attrId}`
    return this.http.delete<AttributeDescriptionResponse>(url)
  }
}
