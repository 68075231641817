<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center gap-2">
    <i class="fal fa-share-alt"></i> Custom Channel
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-custom-channel-form [channel]="data.channel" #form></app-custom-channel-form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <ng-container *ngIf="isEdit; else create">
    <button (click)="onDelete()" type="button" class="btn btn-secondary-danger" [disabled]="form?.isLoading">
      <i class="cs-icon cs-icon-trash"></i> Delete
    </button>
    <button (click)="onSave(form)" type="button" class="btn btn-secondary-info" [disabled]="form?.isLoading">
      <span class="cs-icon cs-icon-save" *ngIf="!form?.isLoading"></span>
      <span class="far fa-spin fa-spinner" *ngIf="form?.isLoading"></span>
      Save
    </button>
  </ng-container>
  <ng-template #create>
    <button (click)="onCreate(form)" type="button" class="btn btn-secondary-info" [disabled]="form?.isLoading">
      <i class="far fa-plus" *ngIf="!form?.isLoading"></i>
      <span class="far fa-spin fa-spinner" *ngIf="form?.isLoading"></span>
      Create
    </button>
  </ng-template>
</div>
