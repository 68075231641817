<div class="d-flex align-items-center gap-3">
  <h6 class="mb-0">Booking Details</h6>
  <div class="flex-fill"></div>
  <ng-container *ngIf="!readonly && !(1 | isReadonlyRole)">
    <ng-container *ngIf="isAirbnbAlterationInitiated; else edit">
      <a
        matTooltip="Review Alteration"
        matTooltipPosition="above"
        (click)="showAirbnbGuestAlterationAlert()"
        class="btn btn-light-danger"
      >
        <i class="fa fa-exclamation-triangle text-danger"></i>
      </a>
    </ng-container>

    <ng-template #edit>
      <a
        *ngxPermissionsOnly="['canEditInquiry']"
        matTooltip="Edit booking details"
        matTooltipPosition="above"
        (click)="onUpdateDetails()"
        class="btn btn-light"
      >
        <i class="cs-icon cs-icon-pencil"></i>
      </a>
    </ng-template>
  </ng-container>
</div>
<div class="info-list mt-3">
  <div class="info-list-item">
    <div class="icon text-indigo">
      <i class="cs-icon cs-icon-calendar-plus text-xl"></i>
    </div>
    <div>
      <div class="label">From</div>
      <div class="value">{{ details?.guestArrive | epochUTC : 'DD - MMM - YYYY' }}</div>
    </div>
  </div>
  <div class="info-list-item">
    <div class="icon text-indigo">
      <i class="cs-icon cs-icon-calendar-plus text-xl"></i>
    </div>
    <div>
      <div class="label">To</div>
      <div class="value">{{ details?.guestDepart | epochUTC : 'DD - MMM - YYYY' }}</div>
    </div>
  </div>
  <div class="info-list-item">
    <div class="icon text-success">
      <i class="cs-icon cs-icon-checkin text-xl"></i>
    </div>
    <div>
      <div class="label">Check-In</div>
      <div class="value">{{ details?.checkIn | epoch : 'h:mm A' }}</div>
    </div>
  </div>
  <div class="info-list-item">
    <div class="icon text-warning">
      <i class="cs-icon cs-icon-checkout text-xl"></i>
    </div>
    <div>
      <div class="label">Check-Out</div>
      <div class="value">{{ details?.checkOut | epoch : 'h:mm A' }}</div>
    </div>
  </div>
  <ng-container *ngIf="showTimeZoneSource">
    <div class="info-list-item" *ngIf="details?.timezone">
      <div class="icon text-danger">
        <i class="cs-icon cs-icon-globe text-xl"></i>
      </div>
      <div>
        <div class="label">Timezone</div>
        <div class="value">{{ details?.timezone }}</div>
      </div>
    </div>
    <div class="info-list-item">
      <div class="icon text-indigo">
        <i class="cs-icon cs-icon-source text-xl"></i>
      </div>
      <div>
        <div class="label">Source</div>
        <div class="value">{{ inquiry.inquirySource | startCase }}</div>
      </div>
    </div>
  </ng-container>
  <div class="info-list-item">
    <div class="icon text-warning">
      <i class="cs-icon cs-icon-adult text-xl"></i>
    </div>
    <div>
      <div class="label">Adults</div>
      <div class="value">{{ details?.numAdults }}</div>
    </div>
  </div>
  <div class="info-list-item">
    <div class="icon text-info">
      <i class="cs-icon cs-icon-child text-xl"></i>
    </div>
    <div>
      <div class="label">Children</div>
      <div class="value">{{ details?.numChild }}</div>
    </div>
  </div>

  <div class="info-list-item">
    <div class="icon text-danger">
      <i class="cs-icon cs-icon-day text-xl"></i>
    </div>
    <div>
      <div class="label">Days</div>
      <div class="value">{{ details?.guestDepart | dateDiff : details?.guestArrive }}</div>
    </div>
  </div>
  <div class="info-list-item">
    <div class="icon text-info">
      <i class="cs-icon cs-icon-moon text-xl"></i>
    </div>
    <div>
      <div class="label">Nights</div>
      <div class="value">{{ details?.guestDepart | dateDiff : details?.guestArrive : 'nights' }}</div>
    </div>
  </div>
  <div class="info-list-item access-codes-item" *ngxPermissionsOnly="['canSeeAccessCodes']">
    <div class="icon text-info">
      <i class="far fa-hashtag-lock text-xl"></i>
    </div>
    <div>
      <div class="label">Access Code</div>
      <div class="value">
        <app-booking-access-code [inquiry]="inquiry" (link)="onLinkDevice()"></app-booking-access-code>
      </div>
    </div>
  </div>
</div>
