import { adapter, initialState, State } from './payment-rule.state'
import {
  CreatePaymentRuleComplete,
  LoadPaymentRulesComplete,
  PausePaymentRulesComplete,
  RemovePaymentRuleComplete,
  TogglePaymentRuleStatusComplete,
} from '@tv3/store/payment-rule/payment-rule.actions'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(PausePaymentRulesComplete, (state, { ids }) => {
    const update = ids.map((id) => ({
      id,
      changes: {
        status: 0,
      },
    }))
    return adapter.updateMany(update, state)
  }),
  on(RemovePaymentRuleComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(TogglePaymentRuleStatusComplete, (state, { id, status }) =>
    adapter.updateOne(
      {
        id,
        changes: {
          status: status ? 1 : 0,
        },
      },
      state
    )
  ),
  on(CreatePaymentRuleComplete, (state, { rules }) => adapter.addMany(rules, state)),
  on(LoadPaymentRulesComplete, (state, { rules }) =>
    adapter.addAll(rules, {
      ...state,
      isLoaded: true,
    })
  )
)

export function paymentRulesReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
