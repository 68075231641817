import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SubscribedAppsComponent } from './lib/subscribed-apps/subscribed-apps.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { AddCreditCardDialogComponent } from './lib/add-credit-card-dialog/add-credit-card-dialog.component'
import { AddCreditCardDialogService } from './lib/add-credit-card-dialog/add-credit-card-dialog.service'
import { AppService } from './lib/store/app/app.service'
import { BillingStoreModule } from './lib/store/billing'
import { CardStoreModule } from './lib/store/card'
import { AppPriceComponent } from './lib/app-overview-dialog/app-price/app-price.component'
import { AppOverviewDialogComponent } from './lib/app-overview-dialog/app-overview-dialog.component'
import { LightboxModule } from 'ngx-lightbox'
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap'
import { AppOverviewDialogService } from './lib/app-overview-dialog/app-overview-dialog.service'
import { CreditChargeStoreModule } from './lib/store/credit-charge'
import { CreditInputDialogComponent } from './lib/app-overview-dialog/credit-input-dialog/credit-input-dialog.component'
import { CreditInputDialogService } from './lib/app-overview-dialog/credit-input-dialog/credit-input-dialog.service'

const modules = [
  //
  Tv3PlatformModule,
  BillingStoreModule,
  CardStoreModule,
  CreditChargeStoreModule,
  LightboxModule,
  NgbCarouselModule,
]

const components = [
  //
  SubscribedAppsComponent,
  AddCreditCardDialogComponent,
  AppPriceComponent,
  CreditInputDialogComponent,
  AppOverviewDialogComponent,
]

const services = [
  //
  AddCreditCardDialogService,
  CreditInputDialogService,
  AppService,
  AppOverviewDialogService,
]

@NgModule({
  imports: [CommonModule, ...modules],
  declarations: [...components],
  exports: [...modules, ...components],
  providers: [...services],
})
export class BillingLibModule {}
