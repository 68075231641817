import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed, ActionSkipped, Toaster } from '@tokeet-frontend/tv3-platform'
import { EntityAttributeDescService } from './service'
import {
  LoadAttributeDescriptions,
  LoadAttributeDescriptionsByEntityType,
  LoadAttributeDescriptionsByEntityTypeComplete,
  LoadAttributeDescriptionsComplete,
  AddAttributeDescription,
  AddAttributeDescriptionComplete,
  DeleteAttributeDescription,
  DeleteAttributeDescriptionComplete,
} from './actions'

@Injectable()
export class AttributeDescriptionEffects {
  all$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAttributeDescriptions),
      switchMap(() =>
        this.service.all().pipe(
          map((items) => LoadAttributeDescriptionsComplete({ items })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAttributeDescriptionsByEntityType),
      switchMap(({ entityType }) =>
        this.service.getForType(entityType).pipe(
          map((items) => LoadAttributeDescriptionsByEntityTypeComplete({ items })),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddAttributeDescription),
      switchMap(({ entityType, payload }) => {
        if (!payload.length) {
          this.toaster.success('Attribute description saved successfully.')
          return [ActionSkipped()]
        }
        return this.service.create(entityType, payload).pipe(
          map((item) => AddAttributeDescriptionComplete({ item })),
          tap(() => this.toaster.success('Attribute description saved successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      })
    )
  )

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAttributeDescription),
      switchMap(({ entityId, id }) =>
        this.service.delete(entityId, id).pipe(
          map((res) => DeleteAttributeDescriptionComplete({ item: res })),
          tap(() => this.toaster.success('Attribute description deleted successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private service: EntityAttributeDescService, private toaster: Toaster) {}
}
