import { ExpenseApprovalSettings, ExpenseView } from './expense.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<ExpenseView> {
  isLoaded: boolean
  settings?: ExpenseApprovalSettings
  isSettingsLoaded: boolean
}

export const adapter: EntityAdapter<ExpenseView> = createEntityAdapter<ExpenseView>({
  sortComparer: false,
  selectId: (expense: ExpenseView) => expense.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isSettingsLoaded: false,
})
