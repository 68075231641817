import { Component } from '@angular/core'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-create-tax-dialog',
  host: { class: 'modal-content' },
  templateUrl: './edit-tax-dialog.component.html',
  styleUrls: ['./edit-tax-dialog.component.scss'],
})
export class EditTaxDialogComponent {
  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    amount: ['', [Validators.required]],
    type: ['percent', [Validators.required]],
  })

  currencySymbol = '$'

  constructor(public dialogRef: MatDialogRef<EditTaxDialogComponent>, private fb: FormBuilder) {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const tax = form.getRawValue()
    this.dialogRef.close(tax)
  }
}
