import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  AddInventories,
  AddInventoriesComplete,
  AddInventory,
  AddInventoryComplete,
  DeleteInventories,
  DeleteInventoriesComplete,
  DeleteInventory,
  DeleteInventoryComplete,
  LoadInventories,
  LoadInventoriesComplete,
  UpdateInventory,
  UpdateInventoryComplete,
  UpdateInventories,
  UpdateInventoriesComplete,
} from './actions'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { InventoryService } from './service'
import { InventoryView } from './model'

@Injectable()
export class InventoryEffects {
  @Effect()
  loadInventories$ = this.actions$.pipe(
    ofType(LoadInventories),
    switchMap(({ params, pagination }) =>
      this.inventories.search(params, pagination).pipe(
        map((inventories) => LoadInventoriesComplete({ inventories })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addInventory$ = this.actions$.pipe(
    ofType(AddInventory),
    switchMap(({ payload }) =>
      this.inventories.create(payload).pipe(
        map((inventory) => AddInventoryComplete({ inventory })),
        tap(() => this.toast.success('Inventory created successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addInventories$ = this.actions$.pipe(
    ofType(AddInventories),
    switchMap(({ requests }) =>
      this.inventories.createInventories(requests).pipe(
        tap(() => this.toast.success('Inventories created successfully.')),
        map((inventories: InventoryView[]) => AddInventoriesComplete({ inventories })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateInventory$ = this.actions$.pipe(
    ofType(UpdateInventory),
    switchMap(({ payload, inventoryId }) =>
      this.inventories.update(inventoryId, payload).pipe(
        map((inventory) => UpdateInventoryComplete({ update: { id: inventoryId, changes: inventory } })),
        tap(() => this.toast.success('Inventory updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateInventories$ = this.actions$.pipe(
    ofType(UpdateInventories),
    concatMap(({ items }) =>
      this.inventories.updateInventories(items).pipe(
        map((inventories) =>
          UpdateInventoriesComplete({
            updates: inventories.map((i) => ({ id: i.pkey, changes: i })),
          })
        ),
        tap(() => this.toast.success('Inventories updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInventory$ = this.actions$.pipe(
    ofType(DeleteInventory),
    switchMap(({ id }) =>
      this.inventories.delete(id).pipe(
        map((inventories) => DeleteInventoryComplete({ id: inventories.pkey })),
        tap(() => this.toast.success('Inventory deleted successfully.')),

        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInventories$ = this.actions$.pipe(
    ofType(DeleteInventories),
    concatMap(({ ids }) =>
      this.inventories.deleteInventories(ids).pipe(
        map(() => DeleteInventoriesComplete({ ids })),
        tap(() => this.toast.success('Inventories deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private inventories: InventoryService, private toast: Toaster) {}
}
