import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { GapNightAutomationsComponent } from './automations/gap-night-automations.component'
import { GapNightAutomationDialogComponent } from './automation-dialog/gap-night-automation-dialog.component'
import { GapNightAutomationsOverlayComponent } from './automations-overlay/gap-night-automations-overlay.component'
import { GapNightAutomationsGridComponent } from './grid/gap-night-automations-grid.component'
import { RateAutomationsLibraryModule } from '@tokeet-frontend/rate-automations'
import { GapNightDecPipe } from './gap-night-dec.pipe'

const modules = [SharedModule, CommonModule, RateAutomationsLibraryModule]

const components = [
  //
  GapNightAutomationsComponent,
  GapNightAutomationDialogComponent,
  GapNightAutomationsOverlayComponent,
  GapNightAutomationsGridComponent,
]

const pipes = [GapNightDecPipe]

const services = []

@NgModule({
  imports: [...modules],
  declarations: [...components, ...pipes],
  exports: [...components, ...modules, ...pipes],
  providers: [...services, ...pipes],
})
export class GapNightAutomationsModule {}
