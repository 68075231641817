import { Component, Input } from '@angular/core'
import { ConnectionView } from '@tv3/store/connection/connection.view'

@Component({
  selector: 'app-ical-connection-status',
  templateUrl: './ical-connection-status.component.html',
  styleUrls: ['./ical-connection-status.component.scss'],
})
export class IcalConnectionStatusComponent {
  @Input() connection: ConnectionView
}
