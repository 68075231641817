import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './attachment.reducer'
import * as state from './attachment.state'
import * as R from 'ramda'
import { Attachment } from './attachment.model'

export const attachmentState = createFeatureSelector<state.State>('attachment')

export const selectAllAttachments = createSelector(attachmentState, reducer.selectAll)

export const selectAttachmentsLoaded = createSelector(attachmentState, (state: state.State) => state.isLoaded)

export const selectAttachmentEntities = createSelector(attachmentState, reducer.selectEntities)

export const selectAttachmentsById = (ids: string[]) =>
  createSelector(selectAttachmentEntities, (attachmentsById) => {
    return ids.map((id) => attachmentsById[id]).filter((a) => !!a)
  })

export const selectAllAttachmentTypes = createSelector(selectAllAttachments, (attachments: Attachment[]) => {
  return R.pipe(
    R.defaultTo([]),
    R.map(({ type }: Attachment) => type && { id: type, name: type }),
    R.reject(R.isNil),
    R.uniqBy((i) => i.id),
    R.sortBy((i) => i.name?.toLowerCase())
  )(attachments)
})

export const selectAllAttachmentUsers = createSelector(selectAllAttachments, (attachments: Attachment[]) => {
  return R.pipe(
    R.defaultTo([]),
    R.map(({ user }: Attachment) => user && { id: user, name: user }),
    R.reject(R.isNil),
    R.uniqBy((i) => i.id),
    R.sortBy((i) => i.name?.toLowerCase())
  )(attachments)
})
