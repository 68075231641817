export enum TableType {
  InquiriesList = 'InquiriesList',
  Automations = 'Automations',
  TaskAutomations = 'TaskAutomations',
  ExpenseAutomations = 'ExpenseAutomations',
  ReviewAutomations = 'ReviewAutomations',
  BookingAutomations = 'BookingAutomations',
  InquiriesInbox = 'InquiriesInbox',
  RentalsList = 'RentalsList',
  PushAllRentals = 'PushAllRentals',
  CustomCodesList = 'CustomCodesList',
  AutomationLogs = 'AutomationLogs',
  GuestsList = 'GuestsList',
  InvoiceRuleLog = 'InvoiceRuleLog',
  AdditionalGuests = 'AdditionalGuests',
  GuestNotesList = 'GuestNotesList',
  GuestInteractionsList = 'GuestInteractionsList',
  GuestFilesList = 'GuestFilesList',
  GuestEmailsList = 'GuestEmailsList',
  GuestBookingsList = 'GuestBookingsList',
  GuestOtherBookingsList = 'GuestOtherBookingsList',
  Invoices = 'Invoices',
  Charges = 'Charges',
  EmailOptions = 'EmailOptions',
  FileCabinet = 'FileCabinet',
  BillingHistory = 'BillingHistory',
  CreditChargeHistory = 'CreditChargeHistory',
  Agenda = 'Agenda',
  Hold = 'Hold',
  StandardCalendar = 'StandardCalendar',
  CalendarSidebarRentals = 'CalendarSidebarRentals',
  CalendarContentRentals = 'CalendarContentRentals',
  Templates = 'Templates',
  Inventories = 'Inventories',
  Incidents = 'Incidents',
  Tasks = 'Tasks',
  TaskChecklist = 'TaskChecklist',
  InvoiceTemplates = 'InvoiceTemplates',
  InvoiceRules = 'InvoiceRules',
  InvoiceAssociatedRules = 'InvoiceAssociatedRules',
  InvoiceRuleActivityLog = 'InvoiceRuleActivityLog',
  UserSettings = 'UserSettings',
  UpcomingArrivals = 'UpcomingArrivals',
  UpcomingDepartures = 'UpcomingDepartures',
  PastDueInvoices = 'PastDueInvoices',
  DataFeed = 'DataFeed',
  ChannelConnections = 'ChannelConnections',
  ChannelAccounts = 'ChannelAccounts',
  Expenses = 'Expenses',
  Extras = 'Extras',
  InquiryDetailsPayments = 'InquiryDetailsPayments',
  InquiryDetailsChecklistNotes = 'InquiryDetailsChecklistNotes',
  InquiryDetailsNotes = 'InquiryDetailsNotes',
  InquiryDetailsFlights = 'InquiryDetailsFlights',
  InquiryDetailsMessages = 'InquiryDetailsMessages',
  InquiryDetailsContracts = 'InquiryDetailsContracts',
  InquiryDetailsTasks = 'InquiryDetailsTasks',
  InquiryDetailsExpenses = 'InquiryDetailsExpenses',
  InquiryDetailsCharges = 'InquiryDetailsCharges',
  InquiryDetailsExtras = 'InquiryDetailsExtras',
  InquiryNotesList = 'InquiryNotesList',
  PaymentGateways = 'PaymentGateways',
  SlackChannelConfig = 'SlackChannelConfig',
  PaymentRules = 'PaymentRules',
  PaymentGatewayLog = 'PaymentGatewayLog',
  SharedContent = 'SharedContent',
  RentalRates = 'RentalRates',
  GroupRates = 'GroupRates',
  ChannelAdjustments = 'ChannelAdjustments',
  MinStayAutomations = 'MinStayAutomations',
  GapNightAutomations = 'GapNightAutomations',
  LastMinuteAutomations = 'LastMinuteAutomations',
  RatePushAutomations = 'RatePushAutomations',
  RentalRateMappings = 'RentalRateMappings',
  MessageFeed = 'MessageFeed',
  MessageTable = 'MessageTable',
  MonthlyOccupancyRateChart = 'MonthlyOccupancyRateChart',
  BookingMtdChart = 'BookingMtdChart',
  RevenueMtdChart = 'RevenueMtdChart',
  ActivityFeed = 'ActivityFeed',
  Websites = 'Websites',
  WebsitePages = 'WebsitePages',
  WebsiteRentals = 'WebsiteRentals',
  VRBORentals = 'VRBORentals',
  AutomataTriggers = 'AutomataTriggers',
  Stealths = 'Stealths',
  RuleConditions = 'RuleConditions',
  DiscountCodes = 'DiscountCodes',
  LosDiscounts = 'LosDiscounts',
  RentalDiscounts = 'RentalDiscounts',
  DiscountCodeActivities = 'DiscountCodeActivities',
  DiscountCodeLogs = 'DiscountCodeLogs',
  BookingFormulas = 'BookingFormulas',
  Integrations = 'Integrations',
  RentalSearch = 'rental-search',
  InquiriesInboxList = 'InquiriesInboxList',
  ExportedCalendars = 'ExportedCalendars',
  ImportedCalendars = 'ImportedCalendars',
  ConnectionActivities = 'ConnectionActivities',
  NoRentals = 'no-rentals',
  NoBookings = 'no-bookings',
  NoRates = 'no-rates',
  InventorySearch = 'inventory-search',

  Commissions = 'Commissions',
  EmailConnection = 'EmailConnection',
  MappedRentals = 'MappedRentals',
  AirbnbReviews = 'AirbnbReviews',
  AirbnbNotifications = 'AirbnbNotifications',
  AirbnbOpportunities = 'AirbnbOpportunities',
  Reviews = 'Reviews',

  SmartDevices = 'SmartDevices',

  AIDocuments = 'AIDocuments',
  AIWebsites = 'AIWebsites',
  AIIgnoreBookings = 'AIIgnoreBookings',
  AIIgnoreGuests = 'AIIgnoreGuests',
  AIIgnoreGuestsRegexp = 'AIIgnoreGuestsRegexp',
  AIIgnoreRentals = 'AIIgnoreRentals',

  CommentsList = 'CommentsList',
  ActivityList = 'ActivityList',
  AccessCodeLogs = 'AccessCodeLogs',
}
