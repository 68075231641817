<div class="container-box shadow-none" style="background: #fbfbff">
  <div class="p-3">
    <div class="card">
      <div class="filters-box">
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected cities."
          matTooltipPosition="above"
          id="rentals-filters-cities"
          [items]="cities$ | async"
          [ctrl]="filters.get('cities')"
          placeholder="Rentals by Cities"
        ></app-select-search>
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected tags."
          matTooltipPosition="above"
          id="rentals-filters-tags"
          [items]="tags$ | async"
          [ctrl]="filters.get('tags')"
          placeholder="Rentals by Tags"
        ></app-select-search>
        <mat-form-field2 [showLabel]="false" class="w-auto">
          <mat-select [formControl]="filters.get('status')" placeholder="Connection Status">
            <mat-option value="all">All</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="inactive">Inactive</mat-option>
          </mat-select>
        </mat-form-field2>
        <app-cancel-filters
          [isFiltering]="filters | isFiltering"
          [filterControl]="filters"
          [searchCom]="search"
        ></app-cancel-filters>
        <div class="flex-fill"></div>
        <app-table-search (search)="onSearch($event)" #search></app-table-search>
      </div>

      <div class="table-holder table-responsive">
        <table class="table table-default table-striped">
          <mat-table #table matSort [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  *ngIf="!allInvalid()"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div *ngIf="row | vrboIsInvalidRental" class="expandable-control">
                  <i
                    *ngIf="row?.rental_id !== expandedElementId"
                    (click)="setExpanded(row)"
                    class="fas fa-chevron-right"
                  ></i>
                  <i
                    *ngIf="row?.rental_id === expandedElementId"
                    (click)="setExpanded(null)"
                    class="fas fa-chevron-down"
                  ></i>
                </div>
                <mat-checkbox
                  *ngIf="!(row | vrboIsInvalidRental)"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                ></mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rental_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-truncate">
                <span [matTooltip]="element.rental_name" matTooltipPosition="above">{{
                  element.rental_name | truncate : 60
                }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date_enabled">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Date Enabled</mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-truncate">
                {{ element.date_enabled | epoch }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="last_sync">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Last Sync</mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-truncate">
                {{ element.last_sync | epoch }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div>{{ element.status == 1 ? 'Active' : 'Inactive' }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> action</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <ng-container *ngIf="element | vrboIsInvalidRental">
                  <button
                    (click)="setExpanded(expandedElementId === element?.rental_id ? null : element)"
                    class="btn btn-sm btn-secondary-info"
                    type="button"
                  >
                    Fix
                  </button>
                </ng-container>
                <ng-container *ngIf="!(element | vrboIsInvalidRental)">
                  <app-form-switch
                    [inline]="true"
                    [disabled]="element | vrboIsInvalidRental"
                    (click)="$event.stopPropagation()"
                    [id]="element.id"
                    [value]="element.status === 1"
                    (valueChange)="onToggleStatus(element)"
                  ></app-form-switch>
                </ng-container>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fees">
              <mat-header-cell *matHeaderCellDef> Fees</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  (click)="onFees(element)"
                  class="btn btn-sm btn-secondary-info"
                  type="button"
                  matTooltip="Click here to view Fees that will be sent to VRBO for this rental"
                  *ngIf="!(element | vrboIsInvalidRental)"
                >
                  Fees
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let detail" class="bg-white text-default text-sm p-0">
                <div class="validation-list">
                  <div [class.invalid-rental]="detail | vrboIsRentalNameInvalid" class="expanded-rental-validation">
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> Display name must include at least 20
                    characters (max of 80)
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalFields(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                  <div
                    [class.invalid-rental]="detail | vrboIsRentalDescriptionInvalid"
                    class="expanded-rental-validation"
                  >
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> Description must include at least 400
                    characters (max of 10,000)
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalFields(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                  <div [class.invalid-rental]="detail | vrboIsRentalFewPhotos" class="expanded-rental-validation">
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> Each listing must have at least 6
                    photos (min resolution 400x300)
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalPhotos(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                  <div
                    [class.invalid-rental]="detail | vrboIsRentalBedroomBathroomInvalid"
                    class="expanded-rental-validation"
                  >
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> Bedroom and bathroom count must be
                    set
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalFields(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                  <div [class.invalid-rental]="detail | vrboIsRentalAddressInvalid" class="expanded-rental-validation">
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> An accurate address is required
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalAddress(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                  <div [class.invalid-rental]="detail | vrboIsRentalLatLongInvalid" class="expanded-rental-validation">
                    <i class="text-lg fas fa-check-circle text-success"></i
                    ><i class="text-lg fas fa-exclamation-circle text-danger"></i> An accurate latitude/longitude is
                    required
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRentalLatLong(detail.rental)"
                      type="button"
                      class="fix-btn btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
                expanded: expandedElementId == row?.rental_id,
                'row-danger': (row | vrboIsInvalidRental)
              }"
              class="element-row"
            >
            </mat-row>

            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
              [@detailExpand]="row?.rental_id == expandedElementId ? 'expanded' : 'collapsed'"
              style="overflow: hidden"
            >
            </mat-row>
          </mat-table>

          <ng-container *ngIf="isEmptyTable$ | async">
            <div class="empty-box">You haven't created any rentals on your account yet</div>
          </ng-container>

          <mat-paginator2
            #paginator
            [class.hidden]="isEmptyTable$ | async"
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator2>
        </table>
      </div>
    </div>
  </div>
  <div class="border-top p-4 mt-4 d-flex align-items-center gap-3">
    <button class="btn btn-outline-light" (click)="prev.emit()">
      <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
      Previous
    </button>
    <div class="flex-fill"></div>
    <button (click)="onBookingFormula()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-calendar-cog"></span> Set Booking Formula
    </button>

    <button
      *ngIf="selection.selected?.length > 0"
      (click)="onConnect()"
      type="button"
      [disabled]="1 | isReadonlyRole"
      class="btn btn-secondary-info"
    >
      Connect <i class="cs-icon cs-icon-bolt"></i>
    </button>
    <button
      *ngIf="selection.selected?.length === 0"
      (click)="onComplete()"
      type="button"
      [disabled]="1 | isReadonlyRole"
      class="btn btn-secondary-success"
    >
      Complete <i class="fas fa-check"></i>
    </button>
  </div>
</div>
