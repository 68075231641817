import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { HelpVideoDialogService } from './help-video-dialog.service'
import { VideoItem } from './video-player'

@Component({
  selector: 'app-related-videos-overlay',
  templateUrl: './related-videos-overlay.component.html',
  styleUrls: ['./related-videos-overlay.component.scss'],
})
export class RelatedVideosOverlayComponent implements OnInit {
  videoItems: VideoItem[] = []

  constructor(
    public dialogRef: MatDialogRef<RelatedVideosOverlayComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      videos: VideoItem[]
      openVideo: HelpVideoDialogService['open']
    }
  ) {
    this.videoItems = data.videos
  }

  ngOnInit(): void {
    console.log(this.videoItems)
  }

  openVideo(videoId: string) {
    this.data.openVideo(videoId)
  }

  close() {
    this.dialogRef.close()
  }
}
