import { createAction, props } from '@ngrx/store'
import { ChannelPropertySetting, ChannelPropertySettingPayload } from './channel-property-setting.model'
import { Update } from '@ngrx/entity'

export const LoadChannelPropertySettings = createAction('[ChannelPropertySetting] Load Start')
export const LoadChannelPropertySettingsComplete = createAction(
  '[ChannelPropertySetting] Load Complete',
  props<{ items: ChannelPropertySetting[] }>()
)

export const CreateChannelPropertySetting = createAction(
  '[ChannelPropertySetting] Create',
  props<{ id: string; payload: ChannelPropertySettingPayload }>()
)
export const CreateChannelPropertySettingComplete = createAction(
  '[ChannelPropertySetting] Create Complete',
  props<{ item: ChannelPropertySetting }>()
)

export const GetChannelPropertySetting = createAction('[ChannelPropertySetting] Get', props<{ id: string }>())
export const GetChannelPropertySettingComplete = createAction(
  '[ChannelPropertySetting] Get Complete',
  props<{ item: ChannelPropertySetting }>()
)

export const UpdateChannelPropertySetting = createAction(
  '[ChannelPropertySetting] Update',
  props<{ id: string; payload: ChannelPropertySettingPayload }>()
)
export const UpdateChannelPropertySettingComplete = createAction(
  '[ChannelPropertySetting] Update Complete',
  props<{ update: Update<ChannelPropertySetting> }>()
)

export const DeleteChannelPropertySetting = createAction('[ChannelPropertySetting] Delete', props<{ id: string }>())
export const DeleteChannelPropertySettingComplete = createAction(
  '[ChannelPropertySetting] Delete Complete',
  props<{ id: string }>()
)
