import { Injectable } from '@angular/core'
import { EditTaxesDialogComponent } from './edit-taxes-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Rental } from '../../stores'

@Injectable()
export class EditTaxesDialogService {
  constructor(private dialog: MatDialog) {}

  open(rental: Rental) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: '400px',
      data: { rental },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(EditTaxesDialogComponent, defaultConfig)
  }
}
