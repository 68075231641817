export enum AccountRateAutomationType {
  MinStay = 'min_nights',
  GapNights = 'gap_nights',
  LastMinute = 'last_minute',
  RatePush = 'rate_push',
}

export interface RateAutomationItemBase {
  key: string
  name: string
  rentals: string[]
  start: number
  stop: number
}

export type CreateRateAutomationPayload = Omit<RateAutomationItemBase, 'key'>

export interface MinStayAutomation extends RateAutomationItemBase {
  days: {
    dow: number
    min_nights: number
  }[]
}

export type CreateMinStayAutomationPayload = Omit<MinStayAutomation, 'key'>

export interface GapNightAutomation extends RateAutomationItemBase {
  gaps: {
    gaps_required: number
    min_nights: number
    adjust_pct: number
    adjust_abs: number
  }[]
}
export type CreateGapNightAutomationPayload = Omit<GapNightAutomation, 'key'>

export interface LastMinuteAutomation extends RateAutomationItemBase {
  windows: {
    days_before: number
    adjust_pct: number
    adjust_abs: number
    min_nights: number
  }[]
}
export type CreateLastMinuteAutomationPayload = Omit<LastMinuteAutomation, 'key'>

export enum RatePushAutomationFrequencyType {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  IntraDay = 'intra_day',
}

export interface RatePushAutomation extends RateAutomationItemBase {
  frequency: RatePushAutomationFrequencyType
  day_hours: number[]
  month_days: number[]
  week_days: number[]
}
export type CreateRatePushAutomationPayload = Omit<RatePushAutomation, 'key'>

export interface AccountRateAutomationResponse {
  pkey: string
  account: number
  start?: number
  end?: number
  min_nights?: MinStayAutomation[]
  gap_nights?: GapNightAutomation[]
  last_minute?: LastMinuteAutomation[]
  rate_push?: RatePushAutomation[]
}

export type CreateAccountRateAutomationPayload = Omit<AccountRateAutomationResponse, 'pkey' | 'account'>
