import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap } from 'rxjs/operators'
import { loadRentalImages } from '@tokeet-frontend/tv3-platform'
import {
  DeletePhoto,
  DeletePhotoComplete,
  LoadPhotos,
  LoadPhotosComplete,
  LoadRooms,
  LoadRoomsComplete,
  OpenTab,
  UpdateCaption,
  UpdateCaptionComplete,
  UpdateOrder,
  UpdateOrderComplete,
} from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.actions'
import { AirbnbTabs } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.state'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { AirbnbApiService } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.service'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AirbnbApiEffects {
  @Effect({ dispatch: false })
  openTab$ = this.actions$.pipe(
    ofType(OpenTab),
    map(({ tab, connection }) => {
      if (tab === AirbnbTabs.Listing) {
        this.store.dispatch(LoadRooms({ connection }))
        this.store.dispatch(LoadPhotos({ connection }))
        this.store.dispatch(loadRentalImages({ id: connection.rentalId }))
      }
    })
  )

  @Effect()
  loadRooms$ = this.actions$.pipe(
    ofType(LoadRooms),
    switchMap(({ connection }) =>
      this.airbnbApiService.getRooms(connection).pipe(
        map((rooms) => LoadRoomsComplete({ rooms })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadPhotos$ = this.actions$.pipe(
    ofType(LoadPhotos),
    switchMap(({ connection }) =>
      this.airbnbApiService.getPhotos(connection).pipe(
        map((photos) => LoadPhotosComplete({ photos })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateOrder$ = this.actions$.pipe(
    ofType(UpdateOrder),
    switchMap(({ connection, photo, newOrder, orderByIds }) =>
      this.airbnbApiService.updateOrder(connection, photo, newOrder).pipe(
        map(() => UpdateOrderComplete({ orderByIds })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateCaption$ = this.actions$.pipe(
    ofType(UpdateCaption),
    switchMap(({ connection, photo }) =>
      this.airbnbApiService.updateCaption(connection, photo).pipe(
        map((photo) => UpdateCaptionComplete({ photo })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deletePhoto$ = this.actions$.pipe(
    ofType(DeletePhoto),
    switchMap(({ connection, photo }) =>
      this.airbnbApiService.deletePhoto(connection, photo).pipe(
        map(() => DeletePhotoComplete({ photo })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private airbnbApiService: AirbnbApiService,
    private store: Store<fromRoot.State>
  ) {}
}
