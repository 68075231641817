import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Rental, SaveForm } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import * as R from 'ramda'

const dataMappings = {
  specialInstructions: 'specifics.special_inst',
  directionInstructions: 'instructions.directions',
  checkInInstructions: 'instructions.checkin',
  checkOutInstructions: 'instructions.checkout',
  houseRules: 'instructions.rules',
  paymentTerms: 'paymentTerms',
  paymentInstructions: 'paymentInstructions',
}

@Component({
  selector: 'app-copy-rental-info-dialog',
  templateUrl: './copy-rental-info-dialog.component.html',
  styleUrls: ['./copy-rental-info-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class CopyRentalInfoDialogComponent implements OnInit {
  form = this.fb.group(
    {
      rental: [undefined, [Validators.required]],
      directionInstructions: [],
      checkInInstructions: [],
      checkOutInstructions: [],
      specialInstructions: [],
      houseRules: [],
      paymentTerms: [],
      paymentInstructions: [],
    },
    {
      validators: [
        (form: FormGroup) => {
          const { rental: rentalId, ...fields } = form.getRawValue()
          const data = lodash.filter(fields, (v) => v)
          return !data.length ? { fields: true } : null
        },
      ],
    }
  )

  constructor(
    public dialogRef: MatDialogRef<CopyRentalInfoDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { rentals: Rental[] }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const { rental: rentalId, ...fields } = this.form.getRawValue()
    const rental = R.find((r: Rental) => r.id === rentalId, this.data.rentals)
    const data = lodash.reduce(
      fields,
      (acc, selected, field) => {
        if (selected) {
          acc[field] = lodash.get(rental, dataMappings[field])
        }
        return acc
      },
      {}
    )
    this.dialogRef.close({ rental, data })
  }
}
