import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromNotification from './notification.reducer'
import { EffectsModule } from '@ngrx/effects'
import { NotificationEffects } from './notification.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('notification', fromNotification.notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),
  ],
  declarations: [],
})
export class NotificationModule {}
