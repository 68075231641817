<div class="modal-header">
  <h5 class="modal-title">
    {{ data.title }}
    <app-page-help
      [infoTooltip]="data.headerInfo"
      [articleId]="data.articleId"
      [videoId]="data.videoId"
    ></app-page-help>
  </h5>
</div>
<div class="modal-body">
  <p [innerHtml]="data.body"></p>
</div>
<div class="modal-footer">
  <button
    (click)="cancel()"
    type="button"
    class=""
    [ngClass]="data.cancelBtnClass"
    [matTooltip]="data.cancelBtnTooltip"
    matTooltipPosition="above"
  >
    {{ data.cancelText }}
  </button>
  <button
    (click)="confirm()"
    type="button"
    class="create-btn"
    [ngClass]="data.confirmBtnClass"
    [matTooltip]="data.confirmBtnTooltip"
    matTooltipPosition="above"
  >
    {{ data.confirmText }}
  </button>
</div>
