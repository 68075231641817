import { Injectable } from '@angular/core'
import * as lodash from 'lodash'
import { Currency, currencies } from '@tokeet-frontend/tv3-platform'

const he = require('he')

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  currencies: Currency[] = currencies

  getCurrencySymbol(currency: Currency | string) {
    let currencyCode

    if (lodash.isString(currency)) {
      currencyCode = currency
    } else if (currency && currency.code) {
      currencyCode = currency.code
    }

    if (!currencyCode) currencyCode = 'USD'

    currency = currencies.find((c) => c['code'] === currencyCode)

    return (currency && currency['symbol'] && he.decode(currency['symbol'])) || '$'
  }
}
