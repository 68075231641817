import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable, of } from 'rxjs'
import { Notification } from '@tv3/store/notification/notification.model'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'
import { catchError } from 'rxjs/operators'

export interface UnreadStats {
  totalcount: number
  newcount: number
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  all(): Observable<Notification[]> {
    const url = '@api/notification/all/'

    return this.http.get(url).pipe(
      deserializeArray<Notification>(Notification),
      catchError(() => of([]))
    )
  }

  getUnreadCount(): Observable<UnreadStats> {
    const url = '@api/notification/count/'

    return this.http.get<UnreadStats>(url).pipe(catchError(() => EMPTY))
  }

  readAll() {
    const url = '@api/notification/count/'

    return this.http.delete(url)
  }
}
