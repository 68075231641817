// https://stackoverflow.com/a/16861050

export function openWindow(url, callback?, w = 500, h = 600) {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop

  const messageHandler = (event) => {
    if (event.origin.indexOf('.tokeet.com') > -1) {
      if (callback && callback(event)) {
        window.removeEventListener('message', messageHandler)
      }
    }
  }
  if (callback) {
    window.addEventListener('message', messageHandler)
  }
  const parsedUrl = new URL(url)
  // Opens a new window
  const newWindow = window.open(
    url,
    parsedUrl.hostname,
    `location=yes,width=${w}, height=${h}, modal=yes, alwaysRaised=yes, top=${top}, left=${left}`
  )
  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
  return newWindow
}
