import { on } from '@ngrx/store'
import { State } from '../channels.state'
import { GetTiketStatusSuccess, ConnectTiketSuccess } from './actions'

export function getTiketReducers() {
  return [
    on(GetTiketStatusSuccess, (state: State, { status }) => ({
      ...state,
      tiket: {
        ...state.tiket,
        status,
      },
    })),
    on(ConnectTiketSuccess, (state: State, { status }) => ({
      ...state,
      tiket: {
        ...state.tiket,
        status,
      },
    })),
  ]
}
