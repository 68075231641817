import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { ListingsValidationFieldResult } from '../rental-validation-field'
import { RentalsUnitedStatus, RentalsUnitedListingModel, RentalsUnitedListingPayload } from './model'

//region Rentals United
export const GetRentalsUnitedRentalValidations = createAction('[RentalUnited] Get Rental Validations')
export const GetRentalsUnitedRentalValidationsSuccess = createAction(
  '[RentalUnited] Get Rental Validations Success',
  props<{
    validations: ListingsValidationFieldResult
  }>()
)

export const GetRentalsUnitedStatus = createAction('[RentalUnited] Get Status')
export const GetRentalsUnitedStatusSuccess = createAction(
  '[RentalUnited] Get Status Success',
  props<{ status: RentalsUnitedStatus }>()
)

export const ConnectRentalsUnited = createAction(
  '[RentalUnited] Connect',
  props<{ email: string; password: string; update?: boolean }>()
)
export const ConnectRentalsUnitedSuccess = createAction(
  '[RentalUnited] Connect Success',
  props<{ status: RentalsUnitedStatus }>()
)

export const DisconnectRentalsUnited = createAction('[RentalUnited] Disconnect')
export const DisconnectRentalsUnitedSuccess = createAction('[RentalUnited] Disconnect Success')

export const GetRentalsUnitedListings = createAction('[RentalUnited] Get Listings')
export const GetRentalsUnitedListingsSuccess = createAction(
  '[RentalUnited] Get Listings Success',
  props<{ listings: RentalsUnitedListingModel[] }>()
)

export const CreateRentalsUnitedListing = createAction(
  '[RentalUnited] Create Listing',
  props<{ listing: RentalsUnitedListingPayload }>()
)

export const CreateRentalsUnitedListingSuccess = createAction(
  '[RentalUnited] Create Listing Success',
  props<{
    update: Update<RentalsUnitedListingModel>
  }>()
)

export const UpdateRentalsUnitedListing = createAction(
  '[RentalUnited] Update Listing',
  props<{ listing: RentalsUnitedListingPayload }>()
)
export const UpdateRentalsUnitedListingSuccess = createAction(
  '[RentalUnited] Update Listing Success',
  props<{
    update: Update<RentalsUnitedListingModel>
  }>()
)

export const ImportRentalsUnitedBookings = createAction(
  '[RentalUnited] Import Listing Bookings',
  props<{ rentalId: string; propertyId: number }>()
)

export const ImportRentalsUnitedBookingsSuccess = createAction('[RentalUnited] Import Listing Bookings Success')

export const SetRentalsUnitedListingStatus = createAction(
  '[RentalUnited] Set Listing Status',
  props<{ rentalId: string; propertyId: number; status: boolean }>()
)
export const SetRentalsUnitedListingStatusSuccess = createAction(
  '[RentalUnited] Set Listing Status Success',
  props<{ update: Update<RentalsUnitedListingModel> }>()
)

export const DeleteRentalsUnitedListing = createAction(
  '[RentalUnited] Delete Listing',
  props<{ rentalId: string; propertyId: number }>()
)
export const DeleteRentalsUnitedListingSuccess = createAction(
  '[RentalUnited] Delete Listing Success',
  props<{ update: Update<RentalsUnitedListingModel> }>()
)
//endregion
