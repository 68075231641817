<div class="alert alert-secondary-info">
  <i class="fal fa-info-circle alert-icon"></i>
  <div class="alert-content">
    Please enable each rental you would like to include in your {{ channelName }} feed. <br />
    Rates for {{ channelName }} use the “Default” category or RateGenie only, further adjustments can be done via
    channel adjustment rules
  </div>
</div>
<div class="title-holder d-flex align-items-center justify-content-between mt-3">
  <div class="title">Rental Connections</div>
  <div class="btn-holder">
    <div dropdown class="btn-group" *ngIf="!(1 | isReadonlyRole)">
      <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle">
        <span class="cs-icon cs-icon-bolt"></span> Actions
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li *ngIf="(rentalsWithoutTaxes$ | async)?.length === 0 || !taxesRequired">
          <button (click)="onEnableAll()" type="button">Enable All</button>
        </li>
        <li *ngIf="(rentalsWithoutTaxes$ | async)?.length > 0 && taxesRequired">
          <button
            matTooltip="Cannot enable all, some rentals are missing taxes. You can go to channel config and use Add Tax to add taxes in bulk, or open individual rental listings on the table below."
            matTooltipPosition="left"
            type="button"
          >
            Enable All
          </button>
        </li>
        <li>
          <button (click)="onDisableAll()" type="button">Disable All</button>
        </li>
        <li *ngIf="!anySelectedRentalMissingTaxes() || !taxesRequired">
          <button (click)="onEnableSelected()" type="button">Enable Selected</button>
        </li>
        <li *ngIf="anySelectedRentalMissingTaxes() && taxesRequired">
          <button
            matTooltip="Cannot enable selected, some rentals are missing taxes. You can go to channel config and use Add Tax to add taxes in bulk, or open individual rental listings on the table below."
            matTooltipPosition="left"
            type="button"
          >
            Enable Selected
          </button>
        </li>
        <li>
          <button (click)="onDisableSelected()" type="button">Disable Selected</button>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="container-box">
  <div class="filters-box">
    <app-select-search
      class="w-auto"
      matTooltip="Only show rentals that specify the selected cities."
      matTooltipPosition="above"
      id="rentals-filters-cities"
      [items]="cities$ | async"
      [ctrl]="citiesCtrl"
      placeholder="Rentals by Cities"
    ></app-select-search>
    <app-select-search
      class="w-auto"
      matTooltip="Only show rentals that specify the selected tags."
      matTooltipPosition="above"
      id="rentals-filters-tags"
      [items]="tags$ | async"
      [ctrl]="tagsCtrl"
      placeholder="Rentals by Tags"
    ></app-select-search>
    <mat-form-field2 [showLabel]="false">
      <mat-select [formControl]="statusCtrl" placeholder="Connection Status">
        <mat-option value="all">All</mat-option>
        <mat-option value="active">Active</mat-option>
        <mat-option value="inactive">Inactive</mat-option>
      </mat-select>
    </mat-form-field2>
  </div>
  <div class="details-box">
    <div class="table-holder table-responsive">
      <table class="table table-default table-striped">
        <mat-table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected(selection, renderedData)"
                [indeterminate]="selection.hasValue() && !isAllSelected(selection, renderedData)"
              >
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="rental.name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-truncate">
              <i
                *ngIf="!element?.rental?.taxes?.length && taxesRequired"
                [matTooltip]="
                  'Rental is missing taxes. ' + channelName + ' connection requires rental taxes to be set.'
                "
                matTooltipPosition="above"
                class="fal fa-exclamation-triangle"
              ></i>
              {{ element.rental?.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date_enabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date Enabled</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-truncate">
              {{ element.date_enabled | epoch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastSync">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Last Sync</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-truncate">
              {{ element.last_sync | epoch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="propertyId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Channel ID</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-truncate">
              {{ element.channel_rental_id }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
            <mat-cell *matCellDef="let element" [class.click-disabled]="1 | isReadonlyRole">
              <ng-container *ngIf="element?.rental?.taxes?.length > 0">
                <app-form-switch
                  (click)="$event.stopPropagation()"
                  [id]="element.id"
                  [value]="element.status === 1"
                  (valueChange)="onToggleStatus(element)"
                  [inline]="true"
                ></app-form-switch>
              </ng-container>
              <ng-container *ngIf="!element?.rental?.taxes?.length">
                <app-form-switch
                  #toggle
                  [value]="false"
                  [inline]="true"
                  (click)="$event.stopPropagation(); onToggleStatus(element); toggle.toggle()"
                ></app-form-switch>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRentalConfigDetails(row)"
            [class.row-danger]="!row?.rental?.taxes?.length && taxesRequired"
            class="clickable"
          ></mat-row>
        </mat-table>

        <ng-container *ngIf="isEmptyTable$ | async">
          <div class="box-alert d-flex justify-content-around">
            <div class="content">You haven't created any rentals on your account yet</div>
          </div>
        </ng-container>

        <mat-paginator2
          [class.hidden]="isEmptyTable$ | async"
          [length]="dataSource?.data?.length"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator2>
      </table>
    </div>
  </div>
</div>
