import { Component, OnInit, Inject } from '@angular/core'
import {
  Destroyable,
  Rental,
  ConfirmDialogService,
  untilDestroy,
  updateRentalPaymentSchedule,
  updateRentalPaymentScheduleComplete,
  RentalPaymentSchedule,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { finalize } from 'rxjs/operators'
import { PaymentScheduleItem } from '../select-payment-schedules-table/select-payment-schedules-table.component'
import * as lodash from 'lodash'

@Component({
  selector: 'app-copy-schedules-from-dialog',
  templateUrl: './copy-schedules-from-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  host: {
    class: 'modal-content',
  },
})
export class CopySchedulesFromDialogComponent extends Destroyable implements OnInit {
  get rental() {
    return this.data.rental
  }
  isLoading = false
  selectedItems: PaymentScheduleItem[] = []

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopySchedulesFromDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(updateRentalPaymentScheduleComplete), untilDestroy(this)).subscribe(() => {
      this.dialogRef.close('done')
    })
  }

  onSelect(items: PaymentScheduleItem[]) {
    this.selectedItems = items
  }

  getSelectedRentalPaymentSchedule(): RentalPaymentSchedule {
    return lodash.mapValues(
      lodash.keyBy(this.selectedItems, (item) => item.type),
      (item) => lodash.omit(item, 'type')
    )
  }

  onOverwrite() {
    this.isLoading = true
    this.confirmDialog
      .confirm({
        title: 'Copy Schedules?',
        body: 'Copying schedules from different rental will overwrite current schedules.',
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.store.dispatch(
          updateRentalPaymentSchedule({ payload: this.getSelectedRentalPaymentSchedule(), rentalId: this.rental.id })
        )
      })
  }

  close() {
    this.dialogRef.close()
  }
}
