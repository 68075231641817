import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, Destroyable, Rental } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import {
  ChannelsService,
  HomeToGoRentalConfigView,
  PauseRentalConfig,
  ResumeHomeToGoRentalConfig,
} from '@tokeet-frontend/channels'
import { BehaviorSubject } from 'rxjs'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'

export enum FeedTabs {
  Connection,
  Config,
}

@Component({
  selector: 'app-hometogo-connection-overlay',
  templateUrl: './hometogo-connection-overlay.component.html',
  styleUrls: ['./hometogo-connection-overlay.component.scss'],
})
export class HomeToGoConnectionOverlayComponent extends Destroyable implements OnInit {
  tabs = FeedTabs
  tab = new BehaviorSubject<FeedTabs>(FeedTabs.Connection)

  channel: Channel
  config: HomeToGoRentalConfigView

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: HomeToGoRentalConfigView; channel: Channel },
    private channelService: ChannelsService,
    private store: Store<any>,
    public dialogRef: MatDialogRef<HomeToGoConnectionOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.channel = this.data.channel
    this.config = this.data.config
  }

  close() {
    this.dialogRef.close()
  }

  onToggleStatus(config: HomeToGoRentalConfigView) {
    if (config.status === 1) {
      this.store.dispatch(PauseRentalConfig({ config, channel: this.channel.name }))
    } else {
      this.store.dispatch(ResumeHomeToGoRentalConfig({ config, channel: this.channel.name }))
    }
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
