import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import * as R from 'ramda'
import { observeOn, takeUntil } from 'rxjs/operators'
import { asyncScheduler, Subject } from 'rxjs'
import { SelectComponent } from '../select/select.component'

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  @Input() items: any[]
  @Input() placeholder: string
  @Input() label: string
  @Input() hasDelete = false
  @Input() multiple = true
  @Input() hasBlank = false
  @Input() blankLabel = 'None'
  @Input() blankValue = null
  @Input() ctrl?: FormControl
  @Input() group?: FormGroup
  @Input() ctrlName?: string
  @Input() template?: 'inquiry'

  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' = 'left'
  @Input() showTooltips = false

  @Input() hasButton = false
  @Input() buttonTooltip = false
  @Input() buttonText = false
  @Output() buttonAction = new EventEmitter()

  @Input() bindLabel?: string
  @Input() bindValue?: string

  @Input() showMasterCheckbox = true
  @Input() showCloseButton = false

  @Input() tabIndex = -1

  @Input()
  @HostBinding('class.inline')
  inline = true

  @ViewChild('select', { static: true }) selectRef: SelectComponent

  @Output() valueChange = new EventEmitter<any>()
  @Output() search = new EventEmitter<string>()

  @Output() openedChange = new EventEmitter<boolean>()

  @Output() delete = new EventEmitter<any>()

  isRequired = false
  destroy$ = new Subject()

  ngOnInit() {
    this.items = this.items || []

    this.checkIfRequired()

    if (this.ctrl) {
    } else if (this.group) {
      this.ctrl = this.group.get(this.ctrlName) as FormControl
    } else {
      this.ctrl = new FormControl()
    }

    this.ctrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.checkIfRequired()

      if (R.isNil(items)) {
        items = []
      } else if (!R.is(Array, items)) {
        items = [items]
      }
      this.valueChange.emit(items)
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  get itemHeight() {
    return this.template === 'inquiry' ? 60 : 40
  }

  get visibleItems() {
    return this.template === 'inquiry' ? 5 : 7
  }

  compareObjects(o1: any, o2: any): boolean {
    return R.equals(o1, o2)
  }

  open() {
    if (this.selectRef) this.selectRef.open()
  }

  checkIfRequired() {
    const errors: any = this.ctrl && this.ctrl.validator && (<any>this.ctrl).validator(new FormControl())
    this.isRequired = !R.isNil(errors) && errors.required
  }
}
