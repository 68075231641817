import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import {
  Destroyable,
  Rental,
  ConfirmDialogService,
  ActionFailed,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Store, select } from '@ngrx/store'
import { concatMap, finalize, switchMap, take, tap, toArray } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { of } from 'rxjs'
import { SelectRoomsTableComponent } from '../select-rooms-table/select-rooms-table.component'
import { Room } from '../../../store/rooms/rooms.model'
import { UpsertRooms, UpsertRoomsComplete } from '../../../store/rooms/rooms.actions'
import { selectAllRooms } from '../../../store/rooms/rooms.selectors'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-copy-rooms-to-dialog',
  templateUrl: './copy-rooms-to-dialog.component.html',
  styleUrls: ['./copy-rooms-to-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class CopyRoomsToDialogComponent extends Destroyable implements OnInit {
  @ViewChild(SelectRoomsTableComponent) feesTable: SelectRoomsTableComponent
  get rental() {
    return this.data.rental
  }
  selectedRooms: Room[] = []
  selectedRentals: Rental[] = []

  isLoading = false
  progress = 0
  rooms: Room[] = []

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopyRoomsToDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectAllRooms), untilDestroy(this)).subscribe((rooms) => {
      this.rooms = rooms
    })
  }

  onSelectRooms(items: Room[]) {
    this.selectedRooms = items
  }

  onSelectRentals(items: Rental[]) {
    this.selectedRentals = items
  }

  onOverwrite() {
    const doAction = (rentalId: string) => {
      const createRooms = this.selectedRooms.map((room) => {
        return {
          ...room,
          rental_id: rentalId,
        }
      })
      const deleteRooms = this.rooms.filter((r) => r.rental_id === rentalId).map((r) => r.pkey)
      this.store.dispatch(UpsertRooms({ createRooms, deleteRooms }))
      return this.actions.pipe(ofType(UpsertRoomsComplete), take(1))
    }

    this.confirmDialog
      .confirm({
        title: 'Overwrite rooms?',
        body: 'This action will overwrite rooms in rentals.',
      })
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() =>
          of(...this.selectedRentals.map((r) => r.id)).pipe(
            concatMap((rentalId, i) => {
              this.progress = i + 1
              return doAction(rentalId)
            }),
            toArray()
          )
        ),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.dialogRef.close('overwrite')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onAdd() {
    const doAction = (rentalId: string) => {
      const createRooms = this.selectedRooms.map((room) => {
        return {
          ...room,
          rental_id: rentalId,
        }
      })
      this.store.dispatch(UpsertRooms({ createRooms }))
      return this.actions.pipe(ofType(UpsertRoomsComplete), take(1))
    }
    this.isLoading = true
    of(...this.selectedRentals.map((r) => r.id))
      .pipe(
        concatMap((rentalId, i) => {
          this.progress = i + 1
          return doAction(rentalId)
        }),
        toArray(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.dialogRef.close('copy')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  close() {
    this.dialogRef.close()
  }
}
