import * as lodash from 'lodash'

export interface AirbnbNotificationItem {
  subtitle: string //  "Required to get paid",
  notification_id: string //  "QUREX0hPU1RfUEFZT1VUX0lORk86YzlkYzc1NWMtMzk2NS0zODUzLTlhN2QtMzMxMDY0OTI0MjEy",
  notification_type: string //  "ADD_HOST_PAYOUT_INFO",
  cta_url: string //  "https://www.airbnb.com/account-settings/payments/payout-methods",
  title: string //  "Add a payout method"
}

export interface AirbnbOpportunityInputFieldNumberValueConstraint {
  value_type: 'PERCENTAGE' | 'INTEGER'
  max_value: number // "99",
  min_value: number //  "1"
}

export interface AirbnbOpportunityInputFieldOptionValueConstraint {
  value_type: 'OPTION'
  options: string[]
}

export interface AirbnbOpportunityInputField {
  value_constraint: AirbnbOpportunityInputFieldNumberValueConstraint | AirbnbOpportunityInputFieldOptionValueConstraint
  key_label: string //"Weekly discount",
  key: string //"weeklyPriceFactorDiscount"
}

export interface AirbnbOpportunityItem {
  title: string // "Offer weekly discounts",
  type: string // "OLYMPUS_WEEKLY_DISCOUNT",
  host_completion_percentage: number // 50,
  description: string // "Attract guests looking to book for 7 nights or more.",
  input_fields: AirbnbOpportunityInputField[]
  id: string // "OLYMPUS_WEEKLY_DISCOUNT",
  activation_modes: string[] //  ["API", "MANUAL"],
  applicable_listing_ids: string[] // ["866943089330075473"],
  activation_url: string // "www.airbnb.com/performance/opportunities/opportunity?type=OLYMPUS_WEEKLY_DISCOUNT&identifier=OLYMPUS_WEEKLY_DISCOUNT"
}

export interface AirbnbOpportunityResponse {
  similar_host_completion_percentage: number // 50,
  airbnb_host_completion_percentage: number // 46,
  current_host_completion_percentage: number // 43
  available_opportunities: []
}

export const airbnbBookingDeclineReasons = [
  { id: 'dates_not_available', label: 'Dates not available' },
  { id: 'not_a_good_fit', label: 'Not a good fit' },
  { id: 'waiting_for_better_reservation', label: 'Waiting for better reservation' },
  { id: 'not_comfortable', label: 'Not comfortable' },
]

export interface AirbnbDeclineBookingPayload {
  decline_reason: string
  decline_message_to_guest: string
  decline_message_to_airbnb: string
}

export const airbnbListingSyncTypes = [
  { name: 'Rates & Availability Only', value: 'sync_rates_and_availability' },
  { name: 'Full Synchronization', value: 'sync_all' },
]

export interface AirbnbUpdateSyncCategoryPayload {
  channelId: string
  propertyId: number | string
  roomid: number | string
  name: string
  category: string
}

export function airbnbFromPriceFactorToDiscount(value: number | null) {
  if (!lodash.isNumber(value)) return null

  return 100 - value * 100
}

export function airbnbFromDiscountToPriceFactor(value: number | null) {
  if (!lodash.isNumber(value)) return null
  return +(1 - value / 100).toFixed(2)
}
