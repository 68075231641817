import {
  LoadActivePlansComplete,
  LoadPlansForProductComplete,
  LoadPlansForAdvanceCMComplete,
  SubscribePlanForProduct,
  SubscribePlanForProductComplete,
  SubscribePlanForAdvanceCM,
  SubscribePlanForAdvanceCMComplete,
  UnsubscribePlan,
  UnsubscribePlanComplete,
} from './plan.actions'
import { adapter, initialState, State } from './plan.state'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(UnsubscribePlan, SubscribePlanForProduct, SubscribePlanForAdvanceCM, (state) => ({
    ...state,
    isUpdatingPlan: true,
  })),
  on(UnsubscribePlanComplete, (state, { product, subscriptions }) => ({
    ...state,
    activePlans: lodash.keyBy(subscriptions, 'product'),
    isUpdatingPlan: false,
  })),
  on(SubscribePlanForAdvanceCMComplete, (state, { planSubscription }) => ({
    ...state,
    activePlans: {
      ...state.activePlans,
      [ProductsForPlan.AdvanceCM]: planSubscription,
    },
    isUpdatingPlan: false,
  })),
  on(SubscribePlanForProductComplete, (state, { planSubscription, product }) => ({
    ...state,
    activePlans: {
      ...state.activePlans,
      [product]: planSubscription,
    },
    isUpdatingPlan: false,
  })),
  on(LoadPlansForAdvanceCMComplete, (state, { plans }) =>
    adapter.addMany(plans, R.mergeDeepRight(state, { isPlansLoaded: { [ProductsForPlan.AdvanceCM]: true } }))
  ),
  on(LoadPlansForProductComplete, (state, { plans, product }) =>
    adapter.addMany(plans, R.mergeDeepRight(state, { isPlansLoaded: { [product]: true } }))
  ),
  on(LoadActivePlansComplete, (state, { card, activePlans, status, endedPlans }) => ({
    ...state,
    ...{ card, activePlans, status, endedPlans },
    isActivePlansLoaded: true,
  }))
)

export function planReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
