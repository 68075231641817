import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { ConnectionActivityLogDetailDialogComponent } from '@tv3/containers/channels/connection-detail/api/log/log-detail-dialog/connection-activity-log-detail-dialog.component'
import { ApiConnectionActivityLog } from '@tv3/store/connection/connection.model'

@Injectable({
  providedIn: SharedModule,
})
export class ConnectionActivityLogDetailDialogService {
  constructor(private dialog: MatDialog) {}

  public open(log: ApiConnectionActivityLog) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: 'auto',
      data: { log },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(ConnectionActivityLogDetailDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
