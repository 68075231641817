import { Component, EventEmitter, Output, Input } from '@angular/core'

@Component({
  selector: 'app-select-nav',
  templateUrl: './select-nav.component.html',
  styleUrls: ['./select-nav.component.scss'],
  host: {
    class: 'btn-group',
  },
})
export class SelectNavComponent {
  @Input() disablePrevious = false
  @Input() disableNext = false

  @Output() previous = new EventEmitter<void>()
  @Output() next = new EventEmitter<void>()
}
