import { Component, EventEmitter, Output } from '@angular/core'
import { FileItem } from 'ng2-file-upload'
import { AuthService } from '@tv3/services/auth.service'
import { addAttachmentsComplete, Attachment, UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss'],
})
export class AttachmentUploadComponent {
  @Output() done = new EventEmitter()
  @Output() switchToRecentFiles = new EventEmitter()
  @Output() close = new EventEmitter()

  files: FileItem[] = []
  results: any[] = []

  options: UploadBoxOptions = {
    maxSize: 10 * 1024 * 1024, // 10MB,
    maxUploads: 1,
    autoUpload: true,
    cloudinary: false,
    token: this.authService.token,
  }

  constructor(private authService: AuthService, private store: Store<any>) {}

  onUploaded(uploadedFileResults: any[]) {
    if (uploadedFileResults.length) {
      const attachments = R.map(Attachment.deserialize, uploadedFileResults)
      if (!this.options.cloudinary) {
        this.store.dispatch(addAttachmentsComplete({ attachments }))
      }
      this.done.emit(attachments)
    }
  }
}
