import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import * as R from 'ramda'
import {
  AddAirBnBV1Channel,
  AddAirBnBV1ChannelComplete,
  AddFlipKeyChannel,
  AddFlipKeyChannelComplete,
  AddPPIDChannel,
  AddPPIDChannelComplete,
  DisconnectChannel,
  DisconnectChannelComplete,
  LoadChannels,
  LoadChannelsComplete,
  UpdateAirBnBV1Channel,
  UpdateAirBnBV1ChannelComplete,
  VerifyFlipKeyAccountForChannel,
  VerifyFlipKeyAccountForChannelComplete,
} from '../channel/channel.actions'
import { ActionFailed } from '../utility'
import { ChannelNameTokens } from './channel.model'
import { Toaster } from '../../services/toaster.service'
import { ChannelService } from './channel.service'
import { PromptDialogService } from '../../dialogs/prompt/prompt-dialog.service'
import { InfoDialogService } from '../../dialogs/info/info-dialog.service'

@Injectable()
export class ChannelEffects {
  @Effect()
  loadChannels$ = this.actions$.pipe(
    ofType(LoadChannels),
    switchMap(() =>
      this.channelService.all().pipe(
        map((channels) => LoadChannelsComplete({ channels })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addPPIDChannel$ = this.actions$.pipe(
    ofType(AddPPIDChannel),
    switchMap(({ channelId, data, channelName }) =>
      this.channelService.addApiChannel(data).pipe(
        map((res) => AddPPIDChannelComplete({ update: { id: channelId, changes: { isConnected: 1 } }, channelName })),
        tap(() => this.toaster.success('Channel connected successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addFlipKey$ = this.actions$.pipe(
    ofType(AddFlipKeyChannel),
    switchMap(({ channelId, data }) =>
      this.channelService.addFlipKeyChannel(data).pipe(
        map((res) => AddFlipKeyChannelComplete({ update: { id: channelId, changes: { isConnected: 1 } } })),
        catchError((error) => {
          if (R.pathEq(['error', 'data', 'status'], 'verify', error || {})) {
            return of(VerifyFlipKeyAccountForChannel({ channelId, data })) // verify account
          } else {
            return of(ActionFailed({ error }))
          }
        })
      )
    )
  )

  @Effect()
  verifyFlipKeyAccount$ = this.actions$.pipe(
    ofType(VerifyFlipKeyAccountForChannel),
    switchMap(({ channelId, data }) => {
      return this.promptDialog
        .open({
          title: 'Verify Your FlipKey Account',
          help: `A verification code has been sent to your cell phone from Tripadvisor/Flipkey.
            Please enter the code above then click the Verify button. If you do not receive a code
            please check your account details on file at Flipkey or contact AdvanceCM support.`,
          placeholder: 'Tripadvisor/FlipKey Verification Code',
          buttonText: 'Verify',
        })
        .pipe(map((code) => ({ channelId, data: { ...data, code } })))
    }),
    switchMap(({ channelId, data }) =>
      this.channelService.verifyFlipKeyChannel(data).pipe(
        map((res) =>
          VerifyFlipKeyAccountForChannelComplete({ update: { id: channelId, changes: { isConnected: 1 } } })
        ),
        catchError((error) => {
          if (R.pathEq(['error', 'data', 'status'], 'verify', error || {})) {
            return of(VerifyFlipKeyAccountForChannel({ channelId, data })) // verify account
          } else {
            return of(ActionFailed({ error }))
          }
        })
      )
    )
  )

  @Effect()
  addAirBnBV1$ = this.actions$.pipe(
    ofType(AddAirBnBV1Channel),
    switchMap(({ channelId, data }) =>
      this.channelService.addAirBnBV1Channel(data).pipe(
        map((res) => AddAirBnBV1ChannelComplete({ update: { id: channelId, changes: { isConnected: 1 } } })),
        catchError((error) => {
          if (R.path(['data', 'airlock'], error || {})) {
            return this.infoDialog
              .open({
                title: 'Please verify your airbnb account',
                body: `Please click the link below to verify your airbnb account.
                    The link will take you to a secure page on the Airbnb website where you must login
                    to confirm your AdvanceCM connection. If you are currently logged into a different Airbnb account you should log out first.
                    <br><br><a target="_blank" href="https://www.airbnb.com/airlock?al_id=${error.data.airlock}">Verify account</a>`,
                closeText: 'Verified',
              })
              .pipe(
                mapTo(
                  AddAirBnBV1Channel({
                    channelId,
                    data: {
                      ...data,
                      retry: 1,
                      airlock_id: error.data.airlock,
                      property: error.data.propertyId,
                    },
                  })
                )
              )
          } else {
            return of(ActionFailed({ error }))
          }
        })
      )
    )
  )

  @Effect()
  updateAirBnBV1$ = this.actions$.pipe(
    ofType(UpdateAirBnBV1Channel),
    switchMap(({ channelId, data }) =>
      this.channelService.updateAirBnBV1Channel(data).pipe(
        map((res) => UpdateAirBnBV1ChannelComplete({ update: { id: channelId, changes: { isConnected: 1 } } })),
        tap(() => this.toaster.success('Account updated successfully.')),
        catchError((error) => {
          if (R.path(['data', 'airlock'], error || {})) {
            return this.infoDialog
              .open({
                title: 'Please verify your airbnb account',
                body: `Please click the link below to verify your airbnb account.
                    The link will take you to a secure page on the Airbnb website where you must login
                    to confirm your AdvanceCM connection. If you are currently logged into a different Airbnb account you should log out first.
                    <br><br><a target="_blank" href="https://www.airbnb.com/airlock?al_id=${error.data.airlock}">Verify account</a>`,
                closeText: 'Verified',
              })
              .pipe(
                mapTo(
                  UpdateAirBnBV1Channel({
                    channelId,
                    data: {
                      ...data,
                      retry: 1,
                      airlock_id: error.data.airlock,
                    },
                  })
                )
              )
          } else {
            return of(ActionFailed({ error }))
          }
        })
      )
    )
  )

  @Effect()
  disconnect$ = this.actions$.pipe(
    ofType(DisconnectChannel),
    switchMap(({ channelName, channelId }) => {
      const doDisconnect = () => {
        switch (channelName) {
          case ChannelNameTokens.AirBnBAPI:
            return this.channelService.disconnectABB(channelId)
          case ChannelNameTokens.AirBnBV2API:
            return this.channelService.disconnectABBV2(channelId)
          default:
            return this.channelService.disconnect(channelId)
        }
      }
      return doDisconnect().pipe(
        map((res) => {
          this.toaster.success('Account disconnected successfully.')
          return DisconnectChannelComplete({ channelName, channelId })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private promptDialog: PromptDialogService,
    private infoDialog: InfoDialogService,
    private channelService: ChannelService
  ) {}
}
