import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { concat, Observable, of } from 'rxjs'
import { map, switchMap, toArray } from 'rxjs/operators'
import * as lodash from 'lodash'

import { GroupRateService } from './group-rate.service'
import { GroupRatesChangedComplete } from './rate.actions'
import { Rate } from './rate.model'

export type CleanGroupRates = Record<'update' | 'delete' | 'skip', Rate[]>

@Injectable()
export class CleanGroupRateService {
  constructor(private groupRateService: GroupRateService, private store: Store<any>) {}

  cleanOnRentalDeleted(rentalId: string, dispatchGroupRatesLoaded = true): Observable<void> {
    return this.groupRateService.all().pipe(
      switchMap((groupRates) => {
        if (!groupRates || !groupRates.length) return of(null)

        const rates = this.cleanGroupRatesByRentalId(groupRates, rentalId)

        return concat(
          ...rates.skip.map((r) => of(r)),
          ...rates.update.map((r) => this.groupRateService.updateRate(r.rateId, r)),
          ...rates.delete.map((r) => this.groupRateService.deleteRate(r.rateId).pipe(map(() => null as Rate)))
        ).pipe(
          toArray(),
          map((updatedRates) => {
            updatedRates = lodash.compact(updatedRates)

            if (dispatchGroupRatesLoaded) {
              this.store.dispatch(GroupRatesChangedComplete({ rates: updatedRates }))
            }

            return updatedRates
          })
        )
      })
    )
  }

  private cleanGroupRatesByRentalId(groupRates: Rate[], rentalId: string) {
    const rates: CleanGroupRates = {
      update: [],
      delete: [],
      skip: [],
    }

    groupRates.forEach((rate) => {
      if (!rate.rentalIds || !rate.rentalIds.includes(rentalId)) {
        return rates.skip.push(rate)
      }

      if (rate.rentalIds.length === 1) {
        rates.delete.push(rate)
      } else {
        rate = rate.clone({ rentalIds: rate.rentalIds.filter((id) => id !== rentalId) })
        rates.update.push(rate)
      }
    })

    return rates
  }
}
