<div class="details-box">
  <div class="filters-box">
    <app-select-search
      bindValue="propertyId"
      bindLabel="propertyId"
      [multiple]="false"
      [items]="connectedAccounts"
      [ctrl]="connectedAccountIdCtrl"
      placeholder="Property"
    ></app-select-search>
    <app-select-search
      bindValue="id"
      bindLabel="name"
      [multiple]="false"
      [items]="rooms"
      [ctrl]="selectedListingIdCtrl"
      placeholder="Listing"
      [hasBlank]="true"
      blankLabel="All Listings"
      blankValue=""
    ></app-select-search>

    <app-cancel-filters
      [isFiltering]="isFiltering"
      (clear)="onClearFilters()"
      [searchCom]="search"
    ></app-cancel-filters>
    <div class="flex-fill"></div>
    <app-table-search (search)="onSearch($event)" #search></app-table-search>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="title" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.description" matTooltipPosition="above">
              {{ element.description }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Edit</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              href="javascript:void(0)"
              matTooltip="Click to add data using AdvanceCM"
              matTooltipPosition="above"
              (click)="onEdit(element)"
            >
              <i class="fas fa-edit"></i>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [class.row-danger]="row.warning"></mat-row>
      </mat-table>

      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="15"
        [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
  </div>
</div>
