import { State, adapter, initialState } from './ai-settings.state'
import { createReducer, on, Action } from '@ngrx/store'
import {
  LoadAISettingsComplete,
  ToggleAISettingsComplete,
  UpdateAISettings,
  UpdateAISettingsComplete,
  UploadAIDocumentComplete,
} from './ai-settings.actions'
import * as lodash from 'lodash'
import { DeleteAIDocumentComplete } from '@tv3/containers/ai-responder/store/ai-settings.actions'
import * as R from 'ramda'

export const reducer = createReducer(
  initialState,
  on(LoadAISettingsComplete, (state, { settings }) => R.merge(state, { isLoaded: true, settings })),
  on(UpdateAISettings, ToggleAISettingsComplete, (state) => R.merge(state, { isSaving: true })),
  on(UpdateAISettingsComplete, (state, { changes }) => {
    const settings = R.merge(state.settings, changes)
    return R.merge(state, {
      isSaving: false,
      settings,
    }) as any
  }),

  on(UploadAIDocumentComplete, (state, { doc }) => {
    return R.merge(state, {
      settings: {
        ...state.settings,
        docs: [...state.settings.docs, doc],
      },
    })
  }),

  on(DeleteAIDocumentComplete, (state, { id }) => {
    const docs = lodash.filter(state.settings.docs, (t) => t.id !== id)
    return R.merge(state, {
      settings: {
        ...state.settings,
        docs,
      },
    })
  })
)

export function aiSettingsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
