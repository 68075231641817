<input
  matInput
  readonly
  type="text"
  [placeholder]="placeholder"
  [ngbPopover]="tmplDateRangePicker"
  #popoverDatePicker="ngbPopover"
  autoClose="outside"
  popoverClass="popover-fill-content dropdown-shadow"
  container="body"
  placement="auto"
  [value]="getInputText | call: value"
  [matTooltip]="getInputTooltip | call: value:showInputTooltip"
  [matTooltipPosition]="inputTooltipPosition"
  matTooltipClass="text-preline"
/>

<ng-template #tmplDateRangePicker>
  <app-date-range-picker
    [selection]="value"
    (selectionChange)="onChangeFilterDateRange($event); popoverDatePicker.close()"
    [local]="local"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (click)="$event.stopPropagation()"
    [showQuickActions]="showQuickActions"
  >
  </app-date-range-picker>
</ng-template>
