import { MessagingChannelsConfig } from './model'

export interface State {
  isLoaded: boolean
  data: MessagingChannelsConfig
}

export const initialState: State = {
  isLoaded: false,
  data: {},
}
