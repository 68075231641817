<mat-form-field2 class="mat-block">
  <app-select
    bindValue="id"
    bindLabel="guestNameView"
    [multiple]="false"
    [items]="inquiries$ | async"
    [searchable]="true"
    (searchChange)="onSearch($event)"
    [formControl]="ctrl"
    [placeholder]="placeholder"
    (selectedChange)="onSelect($event)"
    [required]="required"
    [disabled]="ctrl.disabled"
  >
    <ng-template selectLabel let-items="items">
      <ng-container *ngFor="let item of items">
        <b>{{ item?.guestNameView || '[Unknown]' }}</b> at {{ item?.rentalId | rentalName | async }}
      </ng-container>
    </ng-template>
    <ng-template selectItem let-item="item">
      <div style="line-height: 1.1" class="py-1">
        <div class="text-truncate">
          <b>{{ item.guestNameView || '[Unknown]' }}</b> at {{ item.rentalId | rentalName | async }}
        </div>
        <small class="text-muted">
          starting {{ item.guestArrive | epoch : 'DD - MMM - YYYY' }} ending
          {{ item.guestDepart | epoch : 'DD - MMM - YYYY' }}
        </small>
      </div>
    </ng-template>
  </app-select>
  <a
    matSuffix
    *ngIf="refreshable && !ctrl.disabled"
    (click)="onRefresh()"
    matTooltip="Refresh"
    matTooltipPosition="above"
    ><i class="far fa-sync"></i
  ></a>
  <mat-error *ngIf="ctrl?.touched && ctrl?.hasError('required')"> Inquiry is required </mat-error>
</mat-form-field2>
