import { Component, Input } from '@angular/core'
import { Activity } from '../../store/model'
import { ActivityService } from '../../store/service'
import { ActionFailed, DataEntityType } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss'],
})
export class ActivityTableComponent {
  @Input() entityType: DataEntityType
  @Input() entityId: string

  isLoading = false
  activities: Activity[] = []

  constructor(private store: Store<any>, private activityService: ActivityService) {}

  ngOnInit(): void {
    this.isLoading = true
    this.activityService
      .getActivities(this.entityType, this.entityId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (items) => {
          this.activities = items.logs
        },
        (error) => {
          if (error?.status === 404) {
            return
          }
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }
}
