import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { InvoiceTemplate } from './template.model'

export interface State extends EntityState<InvoiceTemplate> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<InvoiceTemplate> = createEntityAdapter<InvoiceTemplate>({
  sortComparer: false,
  selectId: (i: InvoiceTemplate) => i.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
