import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { RentalDiscountItem } from '@tv3/containers/discounts/discounts-table/discounts-table.component'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import { Injectable } from '@angular/core'
import * as moment from 'moment'

@Injectable()
export class ExportDiscountsDialogService {
  constructor(private dialog: MatDialog) {}

  private getConfig(data: RentalDiscountItem[]): GenericExportDialogConfig {
    return {
      title: 'Download Discounts to CSV',
      description: 'You are about to download all discounts within your current filter.',
      columns: [
        { name: 'Name', field: 'name' },
        { name: 'Discount Type', field: 'discountTypeView' },
        { name: 'Type', field: 'type' },
        { name: 'Amount', field: 'amount' },
        { name: 'Start', field: 'startView' },
        { name: 'End', field: 'endView' },
      ],
      exportData: data,
      filename: `Discounts - ${moment().format('MM_DD_YYYY')}`,
      columnCustomizable: true,
    }
  }

  open(discounts: RentalDiscountItem[]) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        config: this.getConfig(discounts),
      },
    }

    return this.dialog.open(GenericExportDialogComponent, defaultConfig)
  }
}
