import * as lodash from 'lodash'

export interface AirbnbCancellationReasonItem {
  reason: string
  sub_reasons: string[]
}

export interface AirbnbCancellationReasonsResponse {
  penalty_amount_usd_accurate: number
  applicable_reasons: AirbnbCancellationReasonItem[]
}

export interface AirbnbCancelBookingPayload {
  reason: string
  sub_reason?: string
  message_to_guest?: string
}

export const airbnbCancellationReasonTypes = [
  {
    mainId: 'DECLINE_REASON_HOST_DOUBLE',
    id: 'DECLINE_REASON_HOST_EMERGENCY',
    title: 'Emergency',
    message: true,
    description: `The host has an extenuating circumstance`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_DOUBLE',
    id: 'DECLINE_REASON_HOST_HOST_UNAVAILABLE',
    title: 'Host Unavailable',
    message: true,
    description: `The host cannot host on these dates anymore`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_DOUBLE',
    id: 'DECLINE_REASON_HOST_DOUBLE_BOOKED',
    title: 'Double Booked',
    message: true,
    description: `Another guest is already staying there on these dates.`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_CHANGE',
    id: 'DECLINE_REASON_HOST_CHANGE',
    title: 'Change',
    message: true,
    description: `The host wants a different price or trip length`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_CHANGE',
    id: 'DECLINE_REASON_HOST_CHANGE_RESERVATION',
    title: 'Change Reservation',
    message: false,
    description: `The host wants to change the reservation`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_UNAUTHORIZED_PARTY',
    id: 'DECLINE_REASON_HOST_PARTY_REVIEWS',
    title: 'Party Reviews',
    message: false,
    description: `The guest has negative reviews indicating that they have thrown a party in the past`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_UNAUTHORIZED_PARTY',
    id: 'DECLINE_REASON_HOST_PARTY_INDICATION',
    title: 'Party Indication',
    message: false,
    description: `The guest indicated that they are going to throw a party in our message thread`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_BEHAVIOR',
    id: 'DECLINE_REASON_HOST_BEHAVIOR_REVIEWS',
    title: 'Behavior Reviews',
    message: false,
    description: `The guest has negative reviews indicating they have broken house rules in the past`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_BEHAVIOR',
    id: 'DECLINE_REASON_HOST_BEHAVIOR_INDICATION',
    title: 'Behavior Indication',
    message: false,
    description: `The guest indicated that they are going to break my house rules in our message thread`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_BEHAVIOR',
    id: 'DECLINE_REASON_HOST_BEHAVIOR_OTHER',
    title: 'Behavior Other',
    message: false,
    description: `I have other concerns about this guest's behavior`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_BEHAVIOR',
    id: 'DECLINE_REASON_HOST_GUEST_PROFILE',
    title: 'Guest Profile',
    message: false,
    description: `The guest has bad reviews or not enough profile information`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_BAD_FIT',
    id: 'DECLINE_REASON_HOST_BAD_FIT',
    title: 'Bad Fit',
    message: false,
    description: `The listing does not fit the guest's needs`,
  },
  {
    mainId: 'DECLINE_REASON_HOST_OTHER',
    id: 'DECLINE_REASON_HOST_OTHER',
    title: 'Other',
    message: true,
    description: `The host wants to cancel for a reason other than what was listed above`,
  },
]

export interface CancellationByHostReasonsResponse {
  cancellation_by_host: {
    penalty_amount_usd_accurate: number // "46.23",
    applicable_reasons: {
      reason: string
      sub_reasons: string[]
    }[]
  }
}

export function getAirbnbCancellationReasonTypes(res: CancellationByHostReasonsResponse) {
  const reasons = res.cancellation_by_host?.applicable_reasons || []
  const itemsByMainId = lodash.groupBy(airbnbCancellationReasonTypes, (r) => r.mainId)
  const items = lodash.flatten(
    lodash.map(reasons, (r) =>
      lodash.filter(itemsByMainId[r.reason], (sub) => lodash.includes([...r.sub_reasons, r.reason], sub.id))
    )
  )
  return items
}
