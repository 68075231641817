import { Component, Inject, OnInit } from '@angular/core'
import {
  AlertDialogService,
  Channel,
  Destroyable,
  Rental,
  selectRentalById,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs'
import * as lodash from 'lodash'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { Actions, ofType } from '@ngrx/effects'
import {
  BDCPropertyCreateComplete,
  BDCPropertyUpdateComplete,
  BDCRoomCreateComplete,
  BDCRoomUpdateComplete,
} from '@tv3/store/channel-content/conent.actions'
import { BDCContentProperty, BDCContentPropertyRoom } from '@tv3/store/channel-content/models/bdc.model'
import { ChannelPropertySetting } from '@tv3/store/channel-property-setting/channel-property-setting.model'
import { selectChannelPropertySetting } from '@tv3/store/channel-property-setting/channel-property-setting.selectors'

export enum BDCContentDialogTab {
  Property,
  Room,
  Rate,
  Product,
}

@Component({
  selector: 'app-bdc-content-dialog',
  templateUrl: './bdc-content-dialog.component.html',
  styleUrls: ['./bdc-content-dialog.component.scss'],
})
export class BdcContentDialogComponent extends Destroyable implements OnInit {
  tab$ = new BehaviorSubject<BDCContentDialogTab>(BDCContentDialogTab.Property)
  tabs = BDCContentDialogTab

  channel: Channel
  rental: Rental
  connection: ConnectionView

  bdcPropertySettings: ChannelPropertySetting
  bdcProperty: BDCContentProperty

  get bdcRoom(): BDCContentPropertyRoom {
    return this.bdcProperty && lodash.get(this.bdcProperty.rooms, 0)
  }

  constructor(
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private alertDialog: AlertDialogService,
    private bdcContentService: BdcContentService,
    public dialogRef: MatDialogRef<BdcContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { channel: Channel; connection: ConnectionView; activeTab: BDCContentDialogTab }
  ) {
    super()
  }

  ngOnInit() {
    this.tab$.next(this.data.activeTab)
    this.channel = this.data.channel
    this.connection = this.data.connection

    if (this.connection && this.connection.rentalId) {
      this.store
        .pipe(
          select(selectRentalById(this.connection.rentalId)),
          untilDestroy(this),
          tap((rental) => (this.rental = rental))
        )
        .subscribe()
    }

    if (this.connection) {
      // @ts-ignore
      const propertyId = this.connection.propertyId
      this.store
        .pipe(
          select(selectChannelPropertySetting(this.channel.name, propertyId)),
          tap((settings) => (this.bdcPropertySettings = settings)),
          debounceTime(500),
          switchMap(() => this.bdcContentService.get(propertyId))
        )
        .subscribe((property) => {
          this.bdcProperty = property
        })
    }

    this.actions
      .pipe(
        ofType(BDCPropertyCreateComplete, BDCPropertyUpdateComplete, BDCRoomCreateComplete, BDCRoomUpdateComplete),
        debounceTime(500),
        switchMap((data) => this.bdcContentService.get(data.propertyId)),
        tap((property) => (this.bdcProperty = property)),
        untilDestroy(this)
      )
      .subscribe()
  }

  onRentalChange(rental: Rental) {
    this.rental = rental
  }

  close() {
    this.dialogRef.close()
  }
}
