import { createAction, props } from '@ngrx/store'
import { ApiKey } from './api-keys.model'

export const loadApiKeys = createAction('[ApiKey] Load ApiKeys')
export const loadApiKeysComplete = createAction('[ApiKey] Load ApiKeys Complete', props<{ keys: ApiKey[] }>())

export const addApiKey = createAction('[ApiKey] Add ApiKey', props<{ key: ApiKey }>())

export const deleteApiKey = createAction('[ApiKey] Delete ApiKey', props<{ key: ApiKey }>())
export const deleteApiKeyComplete = createAction('[ApiKey] Delete ApiKey Complete', props<{ id: string }>())
