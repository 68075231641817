import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
  Channel,
  ChannelService,
  distinctUntilChangedByIds,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import {
  GetAirbnbListingStatus,
  LoadConnectionsByChannel,
  RefreshConnectionsForChannel,
} from '@tv3/store/connection/connection.actions'
import { selectChannelPropertyIds, selectConnectionViewsByChannelId } from '@tv3/store/connection/connection.selectors'
import { ConnectionDialogService } from '@tv3/containers/channels/connection-detail/connection-dialog.service'
import { AirbnbHelperService } from '@tv3/containers/channels/channel-connect/airbnb-helper.service'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { map } from 'rxjs/operators'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { Observable } from 'rxjs'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { PropertyContentHelperService } from '@tv3/containers/channels/channel-connect/property-content-helper.service'
import { isTiketLikeChannel } from '@tokeet-frontend/channels'
import { hasChannelContentApi } from '@tv3/store/channel-content/content.model'
import { selectActiveTokeetPlanView } from '@tv3/store/plan/plan.selectors'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { FormBuilder } from '@angular/forms'
import { AirbnbSetVisibilityDialogService } from '@tv3/containers/channels/connection-detail/airbnb-set-visibility/airbnb-set-visibility-dialog.service'

@Component({
  selector: 'app-channel-mapped-rentals',
  templateUrl: './channel-mapped-rentals.component.html',
  styleUrls: ['./channel-mapped-rentals.component.scss'],
})
export class ChannelMappedRentalsComponent extends SelectableRow<ConnectionView> implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['select', 'roomName', 'propertyId', 'roomId', 'linkDateOrCreated', 'rentalName', 'edit']

  @Input() channel: Channel
  isAirBnBV1 = false
  isAirBnBV2 = false
  isTiketLikeChannel = false
  hasChannelContentApi = false
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableType = TableType.MappedRentals
  isTokeetPaidSub$ = this.store.pipe(
    select(selectActiveTokeetPlanView),
    map((s) => s?.status === 'active' && s?.isPaid && !s?.isTrial)
  )

  propertyIds$: Observable<any[]>

  connections: ConnectionView[] = []
  filters = this.fb.group({
    linkage: [],
    propertyId: [],
    status: [],
  })

  constructor(
    private store: Store<fromRoot.State>,
    private toaster: Toaster,
    private fb: FormBuilder,
    protected storage: UserStorage,
    private connectionDialog: ConnectionDialogService,
    private airbnbHelperService: AirbnbHelperService,
    private connectionHelperService: ConnectionHelperService,
    private propertyContentHelperService: PropertyContentHelperService,
    private airbnbSetVisibilityDialog: AirbnbSetVisibilityDialogService
  ) {
    super(storage)
  }

  ngOnInit() {
    this.hasChannelContentApi = hasChannelContentApi(this.channel)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.isAirBnBV2 = ChannelService.isAirBnBV2(this.channel.name)
    this.isAirBnBV1 = ChannelService.isAirBnBV1(this.channel.name)
    this.isTiketLikeChannel = isTiketLikeChannel(this.channel.name)

    if (this.channel.isConnected) {
      this.store.dispatch(LoadConnectionsByChannel({ channelName: this.channel.name, channelId: this.channel.id }))
    }

    this.propertyIds$ = this.store.pipe(select(selectChannelPropertyIds(this.channel.id)))
    const connections$ = this.store.pipe(select(selectConnectionViewsByChannelId, { id: this.channel.id }))

    connections$.pipe(untilDestroy(this)).subscribe((connections) => {
      this.dataSource.data = [...connections]
      this.connections = [...connections]
    })

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe((filters) => {
      this.filterData(filters)
    })

    connections$.pipe(distinctUntilChangedByIds(), untilDestroy(this)).subscribe((items) => {
      if (!this.isAirBnBV2) return
      this.store.dispatch(
        GetAirbnbListingStatus({
          items: items.map((t) => ({ id: t.id, propertyId: t.propertyId, listing: t.roomId })),
        })
      )
    })
  }

  onClearFilters() {
    this.filters.reset()
    this.onSearch('')
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  filterData(filters: { linkage: string; propertyId: string; status: string }) {
    this.dataSource.data = R.pipe(
      R.filter((c: ConnectionView) => {
        return (
          !filters.linkage ||
          (c.rentalId && filters.linkage === 'linked') ||
          (!c.rentalId && filters.linkage === 'unlinked')
        )
      }),
      R.filter((c: ConnectionView) => {
        return !filters.propertyId || filters.propertyId == c.propertyId
      }),
      R.filter((c: ConnectionView) => {
        return !filters.status || filters.status === c.airbnbStatus
      })
    )(this.connections)
  }

  onDetail(connection: ConnectionView, wizard = true) {
    this.connectionDialog.openSide(connection, wizard)
  }

  onMasterToggle(checked: boolean) {
    if (checked) {
      this.masterToggle(false)
    } else {
      this.unselectAll()
    }
  }

  onRentalDetail(connection: ConnectionView) {
    this.store.dispatch(OpenRentalOverlay({ rental: connection.rental }))
  }

  onLink(connection: ConnectionView) {
    this.connectionHelperService.linkRental(this.channel.name, connection).subscribe()
  }

  onUnLink(connection: ConnectionView) {
    this.connectionHelperService.unlinkRental(this.channel.name, connection)
  }

  onUnlinkRentals() {
    const selected = this.getSelected().filter((t) => t.isApi && !t.isFeedApi && t.rentalId)
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one linked connection.`)
      return
    }
    this.connectionHelperService.unlinkRentals(selected)
    this.unselectAll()
  }

  onPushRates(connection: ConnectionView) {
    if (this.isTiketLikeChannel) {
      this.connectionHelperService.pushRateForTiketLikeChannel(connection, this.channel, connection.rental).subscribe()
    } else {
      this.connectionHelperService.pushRates(this.channel, connection.rental, connection).subscribe()
    }
  }

  onPushAvailability(connection: ConnectionView) {
    if (this.isTiketLikeChannel) {
      this.connectionHelperService.pushAvailabilityForTiketLike(connection, this.channel.name).subscribe()
    } else {
      this.connectionHelperService.pushAvailability(this.channel.name, connection).subscribe()
    }
  }

  onImportBookings(connection: ConnectionView) {
    this.connectionHelperService.importBookings(this.channel.name, connection).subscribe()
  }

  onImportProperty(connection: ConnectionView) {
    this.airbnbHelperService.importRoom(this.channel.name, connection).subscribe()
  }

  onSetVisibility(connection: ConnectionView) {
    this.airbnbSetVisibilityDialog.open(connection)
  }

  onCreateListDetails() {
    this.propertyContentHelperService.openContentOverlay(this.channel).subscribe()
  }

  onRefreshRoomList(silent = false) {
    this.store.dispatch(
      RefreshConnectionsForChannel({ channelName: this.channel.name, channelId: this.channel.id, silent })
    )
  }

  onCreateAListing() {
    this.connectionHelperService.createListing(this.channel).subscribe(() => {})
  }

  onPushAllAvailability() {
    this.connectionHelperService.pushAllAvailability(this.channel.name, this.getSelected())
  }

  onImportAllBookings() {
    this.connectionHelperService.importAllBookings(this.channel.name, this.getSelected())
  }

  onImportProperties() {
    const connections = this.getSelected().filter((c) => !c.rentalId)
    if (connections.length < 1) {
      this.toaster.warning(`Please select at least one unlinked connection.`)
      return
    }

    this.airbnbHelperService.importRooms(connections).subscribe(() => {
      this.unselectAll()
    })
  }
}
