<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-tags"></i> Add Tags To Inquiries</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="offset-2 col-sm-8">
        <ng-select
          matTooltip="Select or create tag(s)"
          matTooltipPosition="above"
          [items]="tags"
          [addTag]="addNewTag"
          [hideSelected]="true"
          [selectOnTab]="true"
          appendTo="body"
          multiple="true"
          placeholder="Select or create tag(s)"
          formControlName="tags"
        ></ng-select>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-sm-8">
        <p>
          This feature allows you to add arbitrary descriptors to these inquiries which can then be used when filtering
          on various reports and calendars.
        </p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
