import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  Channel,
  countries,
  Destroyable,
  getCountryByCode,
  getTimezones,
  isSomething,
  Rental,
  rentalQuantities,
  RentalService,
  selectRentalById,
  TimePickerUtils,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { asyncScheduler, Observable } from 'rxjs'
import { filter, observeOn, switchMap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import * as moment from 'moment'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { selectAvailableRentalForNewAPIConnection } from '@tv3/store/connection/connection.selectors'

export function createForm(fb: FormBuilder) {
  const rentalAddressForm = fb.group({
    address: [undefined, [Validators.required]],
    city: [undefined, [Validators.required]],
    country: [],
    country_code: [undefined, [Validators.required]],
    zip: [undefined, [Validators.required]],
    state: [undefined, [Validators.required]],
  })
  const rentalForm = fb.group({
    rentalId: [undefined, [Validators.required]],
    phone: [undefined, [Validators.required]],
    address: rentalAddressForm,
    gps: fb.group({
      lat: [undefined, [Validators.required]],
      long: [undefined, [Validators.required]],
    }),
    checkin: [undefined, [Validators.required]],
    checkout: [undefined, [Validators.required]],
    timezone: [undefined, [Validators.required]],
    sleep_max: [undefined, [Validators.required]],
    size: [undefined, [Validators.required]],
    size_metric: [undefined, [Validators.required]],
  })

  return rentalForm
}

@Component({
  selector: 'app-bdc-content-step-rental-form',
  templateUrl: './bdc-content-step-rental-form.component.html',
  styleUrls: ['./bdc-content-step-rental-form.component.scss'],
})
export class BdcContentStepRentalFormComponent extends Destroyable implements OnInit {
  @Input() rentalId: string
  @Input() channel: Channel
  @Input() form: FormGroup
  @Output() rentalChange = new EventEmitter()

  timezoneList = getTimezones()
  countryList = countries
  quantities = rentalQuantities
  rentals$: Observable<Rental[]>

  rental: Rental

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.rentals$ = this.store.pipe(
      observeOn(asyncScheduler),
      select(selectAvailableRentalForNewAPIConnection, { id: this.channel.id, selected: this.rentalId })
    )

    this.form.get('gps').get('lat').disable()
    this.form.get('gps').get('long').disable()
    this.form
      .get('rentalId')
      .valueChanges.pipe(
        switchMap((id) => this.store.pipe(select(selectRentalById(id)))),
        observeOn(asyncScheduler),
        filter(isSomething),
        untilDestroy(this)
      )
      .subscribe((rental) => {
        this.rental = rental
        this.rentalChange.emit(rental)
        this.setRentalForm(rental)
      })

    const addressForm = this.form.get('address')
    addressForm
      .get('country_code')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((code) => {
        const country = getCountryByCode(code)
        addressForm.patchValue({ country }, { emitEvent: false })
      })
  }

  setRentalForm(rental: Rental) {
    const timezone = RentalService.getTimeZone(rental)
    const checkin = TimePickerUtils.formatTime(rental.checkin)
    const checkout = TimePickerUtils.formatTime(rental.checkout)
    const gps = rental.gps || {}

    this.form.patchValue({
      ...rental.serialize(),
      gps,
      timezone,
      checkin,
      checkout,
    })
  }

  onRentalLocation(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Location }))
  }
}
