<app-history-list
  *ngIf="!!list.length; else empty"
  [list]="list"
  (edit)="onEdit($event)"
  [contentKey]="'comment'"
  [userNameKey]="'user'"
  [timeFormat]="'DD-MMM-YYYY'"
  [timeKey]="'date'"
  [iconKey]="'icon'"
  [badgeKey]="'typeView'"
></app-history-list>
<ng-template #empty>
  <app-empty-table *ngIf="list.length === 0" [type]="tableType"></app-empty-table>
</ng-template>
