import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { isExternalBookingSource } from '@tokeet/cost-resolver'
import { Destroyable, isSomething } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Component({
  selector: 'app-update-quote-details',
  templateUrl: './update-quote-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./update-quote-dialog.component.scss'],
})
export class UpdateQuoteDialogComponent extends Destroyable implements OnInit {
  loading = false
  calculating = false

  external: boolean

  constructor(
    public dialogRef: MatDialogRef<UpdateQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
  }

  ngOnInit() {
    this.external =
      !!this.data.inquiry.booked &&
      isSomething(this.data.inquiry.cost) &&
      isExternalBookingSource(this.data.inquiry.inquirySource)
  }

  close() {
    this.dialogRef.close()
  }
}
