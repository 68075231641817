<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center gap-3 w-100">
    <div class="flex-fill"></div>
  </div>
</div>

<div class="filters-box">
  <mat-form-field2
    class="field-categories"
    [showLabel]="false"
    matTooltip="Filter the below rates by category."
    matTooltipPosition="below"
  >
    <mat-select [formControl]="categoryControl" placeholder="Category">
      <mat-option value="*">All categories</mat-option>
      <mat-option *ngFor="let category of categories$ | async" [value]="category">{{ category }}</mat-option>
    </mat-select>
  </mat-form-field2>

  <mat-form-field2
    class="field-types"
    [showLabel]="false"
    matTooltip="Filter the below rates by type."
    matTooltipPosition="below"
  >
    <mat-select [formControl]="typeControl" placeholder="Type">
      <mat-option value="*">All types</mat-option>
      <mat-option *ngFor="let type of rateTypes" [value]="type.value">{{ type.label }}</mat-option>
    </mat-select>
  </mat-form-field2>

  <mat-form-field2
    class="field-period"
    [showLabel]="false"
    matTooltip="Filter the below rates by period."
    matTooltipPosition="below"
  >
    <app-mat-daterange-input placeholder="Rate Period" [formControl]="periodControl"></app-mat-daterange-input>
  </mat-form-field2>

  <app-cancel-filters [isFiltering]="isFiltering" (clear)="clearFilters()"></app-cancel-filters>

  <app-button
    (click)="onRefresh()"
    icon="far fa-sync"
    class="btn btn-secondary-primary"
    [loading]="isRefreshing$ | async"
    matTooltip="Refresh"
    matTooltipPosition="above"
  ></app-button>

  <div class="flex-fill"></div>
  <div class="text-black-50">
    <div *ngIf="isAnySelected()">{{ getSelected().length }} items are selected.</div>
  </div>
  <div class="flex-fill"></div>

  <app-table-search [ctrl]="searchControl" #search matTooltip="Search by name or category"></app-table-search>
  <button
    type="button"
    class="btn btn-secondary-danger"
    [disabled]="!isAnySelected() || isDeleting || !groupRatesLoaded || (1 | isReadonlyRole)"
    (click)="onDelete()"
    [matTooltip]="isAnySelected() ? 'Delete selected rates' : 'Select rates to delete'"
  >
    <i class=" {{ isDeleting ? 'far fa-spinner fa-spin' : 'cs-icon cs-icon-trash' }}"></i>
    <ng-container *ngIf="isAllSelected(false)"> Delete All Rates </ng-container>
    <ng-container *ngIf="!isAllSelected(false)">Delete</ng-container>
  </button>

  <div class="btn-group" dropdown [isDisabled]="1 | isReadonlyRole">
    <button type="button" class="btn btn-secondary-info dropdown-toggle" dropdownToggle>
      <span class="far fa-calculator"></span> Add Rates
    </button>
    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
      <li>
        <button
          type="button"
          (click)="onAddStandardRate()"
          matTooltip="Standard rates are flat, constant rates which do not change over time."
          matTooltipPosition="left"
        >
          <i class="far fa-calculator"></i> Add Standard Rate
        </button>
      </li>
      <li>
        <button
          type="button"
          (click)="onAddDynamicRate()"
          matTooltip="Dynamic rates will gradually and automatically adjust from a low rate to a high rate as your overall rental occupancy level changes. They can also instantly update your channels as the rate changes."
          matTooltipPosition="left"
        >
          <i class="far fa-calculator"></i> Add Dynamic Rate
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="period" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="selection.hasValue() && isAllSelected(isSelectAllRenderedItems)"
            [indeterminate]="selection.hasValue() && !isAllSelected(isSelectAllRenderedItems)"
            [disabled]="!(hasRentalRates | call : dataSource.data)"
            (change)="onMasterToggle($event?.checked)"
            class="master-toggle"
            matTooltip="Select all rates"
          >
          </mat-checkbox>
          <div class="btn-group master-toggle-group" dropdown container="body" [isDisabled]="!dataSource.data?.length">
            <div dropdownToggle>
              <i class="fa fa-caret-down ml-2"></i>
            </div>
            <ul class="dropdown-menu" *dropdownMenu>
              <li>
                <button type="button" (click)="onSelectAll(true)">All</button>
              </li>
              <li>
                <button type="button" (click)="onSelectAll(false)">All Visible</button>
              </li>
              <li>
                <button type="button" (click)="onMasterToggle(false)">None</button>
              </li>
            </ul>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="isGroupRate | call : row"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span
            matTooltip="Rate categories are used to group multiple rates together. Rates in a Rate Category cannot overlap. When updating a channel you push a single rate category. All rates in that category will be pushed to the channel."
            matTooltipPosition="above"
          >
            Category <i class="fas fa-info-circle"></i>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ getRateCategory | call : element }}
          <b *ngIf="element.type === 'dynamic'" matTooltip="Dynamic Rate" matTooltipPosition="right">(D)</b>
          <b *ngIf="element.type === 'promotion'" matTooltip="Promotional Rate" matTooltipPosition="right">(P)</b>
          <b *ngIf="isGroupRate | call : element" matTooltip="Group Rate" matTooltipPosition="right">(G)</b>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="period">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Rate Period</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.start | epochUTC : 'MMM D, YYYY' }} - {{ element.end | epochUTC : 'MMM D, YYYY' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nightly">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Nightly</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div
            class="text-truncate"
            [matTooltip]="
              element.type === 'dynamic'
                ? (element.lowRate | rate : currency : false) + ' - ' + (element.highRate | rate : currency : false)
                : element.nightly
                ? (element.nightly | rate : currency : false)
                : '...'
            "
          >
            <ng-template [ngTemplateOutlet]="element.type === 'dynamic' ? range : nightly"></ng-template>

            <ng-template #range>
              {{ element.lowRate | rate : currency : false }} -
              {{ element.highRate | rate : currency : false }}
            </ng-template>
            <ng-template #nightly>
              {{ element.nightly ? (element.nightly | rate : currency : false) : '...' }}
            </ng-template>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="weekly">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Weekly</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.weekly ? (element.weekly | rate : currency : false) : '...' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="monthly">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Monthly</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.monthly ? (element.monthly | rate : currency : false) : '...' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="minimumStay">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Min Stay</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.minimumStay }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> EDIT</mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div
            dropdown
            placement="bottom right"
            container="body"
            [isDisabled]="1 | isReadonlyRole"
            *ngIf="!(isGroupRate | call : element)"
          >
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button" matTooltip="Edit this rate" matTooltipPosition="left">
                  <i class="fal fa-edit"></i> Edit
                </button>
              </li>
              <li>
                <button
                  (click)="onDelete(element)"
                  type="button"
                  [disabled]="!groupRatesLoaded"
                  matTooltip="Remove this rate"
                  matTooltipPosition="left"
                >
                  <i class="cs-icon cs-icon-trash"></i> Delete
                </button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onEdit(row)"
        class="clickable"
        [matTooltip]="getRowTooltip | call : row"
        matTooltipPosition="above"
      >
      </mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      (page)="onPageChange($event)"
      [pageSize]="savedPageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white"></app-empty-table>
  </table>
</div>
