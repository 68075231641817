import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ApiConnectionActivityLog } from '@tv3/store/connection/connection.model'

@Component({
  selector: 'app-connection-activity-log-detail-dialog',
  templateUrl: './connection-activity-log-detail-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./connection-activity-log-detail-dialog.component.scss'],
})
export class ConnectionActivityLogDetailDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConnectionActivityLogDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { log: ApiConnectionActivityLog }
  ) {}

  close() {
    this.dialogRef.close()
  }
}
