import { isSomething, SelectableRow } from '@tokeet-frontend/tv3-platform'
import { OnInit, Directive } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  AlertDialogService,
  ConfirmDialogService,
  IStorage,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { IInvoiceTemplateOverlayService, IInvoiceTemplateFilters } from './interfaces'
import { PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { isInvoiceTemplateUpdating } from '../../store/templates/template.selectors'
import {
  DeleteInvoiceTemplate,
  DeleteInvoiceTemplates,
  LoadInvoiceTemplates,
} from '../../store/templates/template.actions'
import { InvoiceTemplateView } from '../../store/templates/template.model'
import { selectInvoiceTemplateRules } from '../../store/rules/rule.selectors'
import { InvoiceTypes } from '../../store/invoices/invoice.model'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'

@Directive()
export abstract class InvoiceTemplatesPageAbstractComponent
  extends SelectableRow<InvoiceTemplateView>
  implements OnInit
{
  paginator: any
  sort: MatSort
  displayedColumns = []

  page = new Subject<Partial<PageEvent>>()
  filterGroup
  tableType

  isEmptyTable$ = isEmptyTable(this.dataSource)
  isUpdatingTemplates$ = this.store.pipe(select(isInvoiceTemplateUpdating))
  invoiceTypes = InvoiceTypes
  filters = this.invoiceTemplateFilters.group

  constructor(
    protected invoiceTemplateOverlayService: IInvoiceTemplateOverlayService,
    protected store: Store<any>,
    public invoiceTemplateFilters: IInvoiceTemplateFilters,
    protected storage: IStorage,
    protected toaster: Toaster,
    protected confirmDialog: ConfirmDialogService,
    protected alert: AlertDialogService
  ) {
    super(storage, 'InvoiceTemplates' as any)
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    if (this.savedSort) this.sort.sort(this.savedSort)
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.invoiceTemplateFilters.group.changes.pipe(untilDestroy(this)).subscribe((templates) => {
      this.dataSource.data = templates
    })

    this.invoiceTemplateFilters.group.currentFilters.pipe(untilDestroy(this)).subscribe(() => {
      this.paginator.firstPage()
    })
  }

  onRefresh() {
    this.store.dispatch(LoadInvoiceTemplates({ filters: {} }))
  }

  isAssociatedToRule(template: InvoiceTemplateView) {
    return this.store.pipe(
      select(selectInvoiceTemplateRules(template.pkey)),
      map((rules) => isSomething(rules))
    )
  }

  onAdd(invoiceType: InvoiceTypes) {
    this.invoiceTemplateOverlayService.open(invoiceType)
  }

  onEdit(template: InvoiceTemplateView) {
    this.invoiceTemplateOverlayService.open(null, template)
  }

  onDuplicate(template: InvoiceTemplateView) {
    this.invoiceTemplateOverlayService.open(null, template, true)
  }

  onDeleteSelected() {
    const selected = this.getSelected('pkey')
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one template.`)
      return
    }

    this.confirmDialog
      .confirm({
        title: 'Delete these templates?',
        body: 'Are you sure you want to delete these templates?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteInvoiceTemplates({ ids: selected }))
      })
  }

  onRemove(template: InvoiceTemplateView) {
    this.confirmDialog
      .confirm({
        title: 'Delete this template?',
        body: 'Are you sure you want to delete this template?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteInvoiceTemplate({ id: template.pkey }))
      })
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }
}
