import { Directive, OnInit, ElementRef, OnDestroy, Input, AfterViewInit } from '@angular/core'

const CLASS = 'table-content-size-apply'
const VAR_WIDTH = '--table-content-width'

@Directive({
  selector: '[tableContentSize]',
})
export class TableContentSizeDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input('tableContentSize') varWidth = VAR_WIDTH

  private _currentWidth = 0
  private _cleanup: () => void = null

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const elm = this.el.nativeElement

    elm.classList.add(CLASS)

    const handler = () => {
      this.handleResize()
    }

    window.addEventListener('resize', handler)

    const resizeObserver = window.ResizeObserver && new window.ResizeObserver(handler)
    resizeObserver?.observe(elm)

    this._cleanup = () => {
      window.removeEventListener('resize', handler)
      resizeObserver?.unobserve(elm)
    }
  }

  ngOnDestroy() {
    this._cleanup?.()
    this.handleResize(true)
  }

  ngAfterViewInit() {
    this.handleResize()
  }

  private handleResize(cleanup = false) {
    const elm: HTMLElement = this.el.nativeElement
    if (!elm) return

    const varWidth = this.varWidth || VAR_WIDTH

    if (cleanup) {
      elm.classList.remove(CLASS)
      elm.style.removeProperty(varWidth)
      return
    }

    if (elm.scrollWidth === this._currentWidth) return

    elm.style.removeProperty(varWidth)

    const updateSize = () => {
      const contentWidth = elm.scrollWidth
      const overflow = contentWidth > elm.offsetWidth

      this._currentWidth = contentWidth

      if (!overflow) {
        elm.style.removeProperty(varWidth)
      } else {
        elm.style.setProperty(varWidth, elm.scrollWidth + 'px')
      }
    }

    requestAnimationFrame(updateSize)
  }
}
