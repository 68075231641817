import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './tag.state'
import * as R from 'ramda'

export const tagState = createFeatureSelector<state.State>('tag')

export const selectTags = (type: 'guest' | 'rental' | 'inquiry') =>
  createSelector(tagState, (state: state.State) => {
    return R.sort(
      (a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
      state[type]
    )
  })

export const selectTagsLoaded = createSelector(tagState, (state: state.State) => state.isLoaded)
