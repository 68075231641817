import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { MessagingSettingsComponent } from '@tv3/containers/messaging-settings/settings/messaging-settings.component'
import { MessageLibraryModule } from '@tokeet-frontend/message'
import { MessagingSettingsDialogService } from './settings/messaging-settings.service'
import { SMSAutoCreditRechargeComponent } from './settings/auto-credit-recharge/sms-auto-credit-recharge.component'

const components = [
  //
  MessagingSettingsComponent,
  SMSAutoCreditRechargeComponent,
]

@NgModule({
  imports: [CommonModule, SharedModule, MessageLibraryModule],
  declarations: [...components],
  providers: [MessagingSettingsDialogService],
})
export class MessagingSettingsModule {}
