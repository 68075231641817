import { createAction, props } from '@ngrx/store'
import { Connection } from './connection.model'
import { Update } from '@ngrx/entity'
import {
  AddExportedCalendarConnectionPayload,
  AddImportedCalendarConnectionPayload,
  CreateChannelListingPayload,
  LinkConnectionWithRentalPayload,
  PushRatesToApiConnectionPayload,
  RefreshImportedCalendarConnectionPayload,
  UnlinkConnectionWithRentalPayload,
} from '@tv3/store/connection/connection.types'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { CalendarEvent } from '@tv3/store/calendar/calendar.model'

export const MarkAsCoHostForAirBnbConnection = createAction(
  '[Connection] Mark As Co-Host for AirBnb',
  props<{ connId: string }>()
)
export const MarkAsCoHostForAirBnbConnectionComplete = createAction(
  '[Connection] Mark As Co-Host for AirBnb Complete',
  props<{ update: Update<Connection> }>()
)

export const LoadConnections = createAction('[Connection] Load Connections', props<{ force?: boolean }>())
export const LoadConnectionsComplete = createAction(
  '[Connection] Load Connections Complete',
  props<{ connections: Connection[]; force?: boolean }>()
)

export const LoadConnection = createAction(
  '[Connection] Load Connection',
  props<{ channelName: ChannelNameTokens; roomId: any; propertyId: any }>()
)
export const LoadConnectionComplete = createAction(
  '[Connection] Load Connection Complete',
  props<{ connection: Connection }>()
)

export const LoadConnectionsByChannel = createAction(
  '[Connection] Load Connections By Channel',
  props<{ channelName: ChannelNameTokens; channelId: string }>()
)
export const LoadConnectionsByChannelComplete = createAction(
  '[Connection] Load Connections By Channel Complete',
  props<{ connections: Connection[] }>()
)

export const RefreshConnectionsForChannel = createAction(
  '[Connection] Refresh Connections For Channel',
  props<{ channelName: ChannelNameTokens; channelId: string; silent: boolean }>()
)
export const RefreshConnectionsForChannelComplete = createAction(
  '[Connection] Refresh Connections For Channel Complete'
)

export const CreateConnectionsForChannel = createAction(
  '[Connection] Create Connections For Channel',
  props<{ channelName: ChannelNameTokens; data: CreateChannelListingPayload }>()
)
export const CreateConnectionsForChannelComplete = createAction('[Connection] Create Connections For Channel Complete')

export const LinkConnectionWithRental = createAction(
  '[Connection] Link Connection With Rental',
  props<{ connId: string; data: LinkConnectionWithRentalPayload; channelName: ChannelNameTokens }>()
)
export const LinkConnectionWithRentalComplete = createAction(
  '[Connection] Link Connection With Rental Complete',
  props<{ update: Update<Connection> }>()
)

export const UnlinkConnectionWithRental = createAction(
  '[Connection] Unlink Connection With Rental',
  props<{ connId: string; data: UnlinkConnectionWithRentalPayload; channelName: ChannelNameTokens }>()
)
export const UnlinkConnectionWithRentalComplete = createAction(
  '[Connection] Unlink Connection With Rental Complete',
  props<{ update: Update<Connection> }>()
)

export const UnlinkConnectionsWithRental = createAction(
  '[Connection] Unlink Connections With Rental',
  props<{ items: { connId: string; data: UnlinkConnectionWithRentalPayload; channelName: ChannelNameTokens }[] }>()
)

export const ImportBookingsFromConnection = createAction(
  '[Connection] Import Bookings from Connection',
  props<{ connId: string; channelId: string; channelName: ChannelNameTokens; data: Connection }>()
)
export const ImportBookingsFromConnectionComplete = createAction(
  '[Connection] Import Bookings from Connection Complete',
  props<{ update: Update<Connection> }>()
)

export const ImportBookingFromABBV2Connection = createAction(
  '[Connection] Import Booking from ABBV2 Connection',
  props<{ inquiryId: string; channelId: string; data: Connection }>()
)
export const ImportBookingFromABBV2ConnectionComplete = createAction(
  '[Connection] Import Booking from ABBV2 Connection Complete'
)

export const PushAvailabilityToConnection = createAction(
  '[Connection] Push Availability To Connection',
  props<{ connId: string; channelId: string; channelName: ChannelNameTokens; data: Connection }>()
)
export const PushAvailabilityToConnectionComplete = createAction(
  '[Connection] Push Availability To Connection Complete',
  props<{ update: Update<Connection> }>()
)

export const PushAvailabilityToConnectionForWizard = createAction(
  '[Connection] Push Availability To Connection For Wizard',
  props<{
    connId: string
    channelId: string
    channelName: ChannelNameTokens
    data: Connection
    tmpHoldEventId?: string
  }>()
)
export const PushAvailabilityToConnectionForWizardComplete = createAction(
  '[Connection] Push Availability To Connection For Wizard Complete',
  props<{ update: Update<Connection> }>()
)

export const HoldEventForEmptyCalendarPush = createAction(
  '[Connection] Hold Event For Empty Calendar Push',
  props<{ connId: string; holdEvent: CalendarEvent }>()
)

export const PushRatesToConnection = createAction(
  '[Connection] Push Rates To Connection',
  props<{ connId: string; channelId: string; channelName: ChannelNameTokens; data: PushRatesToApiConnectionPayload }>()
)
export const PushRatesToConnectionComplete = createAction(
  '[Connection] Push Rates To Connection Complete',
  props<{ update: Update<Connection> }>()
)

export const AddImportedCalendarConnection = createAction(
  '[Connection] Add Imported Calendar Connection',
  props<{ payload: AddImportedCalendarConnectionPayload }>()
)
export const AddImportedCalendarConnectionComplete = createAction(
  '[Connection] Add Imported Calendar Connection Complete',
  props<{ connection: Connection }>()
)

export const AddExportedCalendarConnection = createAction(
  '[Connection] Add Exported Calendar Connection',
  props<{ payload: AddExportedCalendarConnectionPayload }>()
)
export const AddExportedCalendarConnectionComplete = createAction(
  '[Connection] Add Exported Calendar Connection Complete',
  props<{ connection: Connection }>()
)

export const ManualRefreshImportedCalendarConnection = createAction(
  '[Connection] Manual Refresh Calendar Connection',
  props<{ connId: string; data: RefreshImportedCalendarConnectionPayload }>()
)
export const ManualRefreshImportedCalendarConnectionComplete = createAction(
  '[Connection] Manual Refresh Calendar Connection Complete',
  props<{ update: Update<Connection> }>()
)

export const ManualRefreshImportedCalendarConnections = createAction(
  '[Connection] Manual Refresh Calendar Connections',
  props<{ payloads: { connId: string; data: RefreshImportedCalendarConnectionPayload }[] }>()
)

export const DeleteExportedCalendarConnection = createAction(
  '[Connection] Delete Exported Calendar Connection',
  props<{ id: string }>()
)
export const DeleteExportedCalendarConnectionComplete = createAction(
  '[Connection] Delete Exported Calendar Connection Complete',
  props<{ id: string }>()
)

export const DeleteImportedCalendarConnection = createAction(
  '[Connection] Delete Imported Calendar Connection',
  props<{
    connId: string
    rentalId: string
    removeAllEvents: boolean // Delete all associated events from your calendar?
  }>()
)
export const DeleteImportedCalendarConnectionComplete = createAction(
  '[Connection] Delete Imported Calendar Connection Complete',
  props<{ id: string }>()
)

export const DeleteCalendarConnections = createAction(
  '[Connection] Delete Calendar Connections',
  props<{
    imported?: {
      items: { connId: string; rentalId: string }[]
      removeAllEvents: boolean
    }
    exported?: string[]
  }>()
)
export const DeleteCalendarConnectionsComplete = createAction(
  '[Connection] Delete Calendar Connections Complete',
  props<{ ids: string[] }>()
)

export const RemoveLocalConnectionsByIds = createAction(
  '[Connection] Remove Local Connections By Ids',
  props<{ connectionIds: string[] }>()
)

export const RemoveLocalConnectionsByChannelId = createAction(
  '[Connection] Remove Local Connections By Channel Id',
  props<{ channelId: string }>()
)

export const GetConnectionStatues = createAction(
  '[Connection] Get Statuses',
  props<{ propertyIds: (string | number)[]; roomIds: (string | number)[] }>()
)
export const GetConnectionStatuesComplete = createAction(
  '[Connection] Get Statuses Complete',
  props<{ updates: Update<Connection>[] }>()
)

export const GetAirbnbListingStatus = createAction(
  '[Connection] Get Airbnb Listings Status',
  props<{ items: { id: string; propertyId: any; listing: any }[] }>()
)
export const GetAirbnbListingStatusComplete = createAction(
  '[Connection] Get Airbnb Listings Status Complete',
  props<{ updates: Update<Connection>[] }>()
)
