import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[rerender]',
})
export class RerenderDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set rerender(_value: any) {
    this.reset()
  }

  reset() {
    this.viewContainer.clear()
    this.viewContainer.createEmbeddedView(this.templateRef)
  }
}
