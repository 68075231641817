import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ChannelAdjustment } from './model'

export interface State extends EntityState<ChannelAdjustment> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<ChannelAdjustment> = createEntityAdapter<ChannelAdjustment>({
  sortComparer: false,
  selectId: (item: ChannelAdjustment) => item.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
