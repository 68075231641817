import { Account } from './account.models'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Account> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Account> = createEntityAdapter<Account>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
