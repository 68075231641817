import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import {
  GetSmartDeviceAccessCodesParams,
  LoadSmartDevicesParams,
  SmartDevice,
  SmartDeviceAccessCodeResponse,
  SmartDeviceAccount,
  SmartDeviceNewAccessCodePayload,
  SmartDeviceNewAccessCodeResponse,
  SmartDeviceSettingsResponse,
  UpdateSmartDevicePayload,
} from './model'
import { CURRENT_ACCOUNT_ID, CURRENT_USER, User } from '@tokeet-frontend/tv3-platform'
import { map } from 'rxjs/operators'
import { SeamAccountConnectData } from './account.model'
import * as lodash from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { SmartDeviceUpdateAccessCodePayload } from './model'
import * as moment from 'moment'

@Injectable()
export class SmartDeviceService {
  constructor(
    private http: HttpClient,
    @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>,
    @Inject(CURRENT_USER) private user$: BehaviorSubject<User>
  ) {}

  preConnect() {
    const url = `@api/v1/smartdevice/account/register/`

    return this.http
      .post<{ response: { data: SeamAccountConnectData } }>(url, { account: this.accountId$.value })
      .pipe(map((d) => d.response?.data))
  }

  disconnect(account: number, providerId: string) {
    const url = `@api/v1/smartdevice/account/delete`
    return this.http.post(url, { account, connectedAccountId: providerId })
  }

  accounts() {
    const url = `@api/v1/smartdevice/account`
    return this.http
      .get<{ response: { data: { account: number; connectedAccounts: SmartDeviceAccount[] }[] } }>(url)
      .pipe(
        map((res) => res.response?.data?.[0]?.connectedAccounts),
        map((items) => items?.map((t) => ({ ...t, username: t.username || t.email })))
      )
  }

  devices(params: LoadSmartDevicesParams = {}) {
    return this.http
      .get<{ response: { data: { account: number; isPrimary?: boolean; rentalId?: string; device: SmartDevice }[] } }>(
        `@api/v1/smartdevice/`,
        {
          params: params as any,
        }
      )
      .pipe(
        map((data) =>
          lodash.map(
            data.response?.data,
            (i): SmartDevice => ({ ...i.device, rentalId: i.rentalId, isPrimary: !!i.isPrimary })
          )
        )
      )
  }

  getAccessCodes(params: GetSmartDeviceAccessCodesParams) {
    const url = `@api/v1/smartdevice/accesscode/`

    return this.http
      .get<{ response: { data: SmartDeviceAccessCodeResponse[] } }>(url, {
        params: { account: this.accountId$.value, ...params },
      })
      .pipe(
        map((res) => res.response.data || []),
        map((items) =>
          items.map(
            (t) =>
              ({
                ...t,
                startsAt: t.startsAt + moment.tz(0, t.timezone).utcOffset() * 60,
                endsAt: t.endsAt + moment.tz(0, t.timezone).utcOffset() * 60,
              } as SmartDeviceAccessCodeResponse)
          )
        )
      )
  }

  creatAccessCode(payload: SmartDeviceNewAccessCodePayload) {
    const url = `@api/v1/smartdevice/accesscode/`
    return this.http
      .post<{ response: { data: SmartDeviceNewAccessCodeResponse } }>(url, {
        account: this.accountId$.value,
        userId: this.user$.value.id,
        ...payload,
      })
      .pipe(map((res) => res.response.data))
  }

  updateAccessCode(payload: SmartDeviceUpdateAccessCodePayload) {
    const url = `@api/v1/smartdevice/accesscode/`
    return this.http.put(url, {
      account: this.accountId$.value,
      ...payload,
    })
  }

  deleteAccessCode(deviceId: string, codeId: string) {
    const url = `@api/v1/smartdevice/accesscode/delete`
    return this.http.post(url, {
      account: this.accountId$.value,
      accessCodeId: codeId,
      deviceId: deviceId,
    })
  }

  updateDevice(payload: UpdateSmartDevicePayload) {
    return this.http.put(`@api/v1/smartdevice/`, payload)
  }

  getSettings() {
    const url = '@api/v1/smartdevice/lock/settings'
    return this.http.get<{ response: { data: SmartDeviceSettingsResponse } }>(url).pipe(map((res) => res.response.data))
  }

  updateSettings(payload: Partial<SmartDeviceSettingsResponse>) {
    const url = '@api/v1/smartdevice/lock/settings'
    return this.http.post(url, {
      account: this.accountId$.value,
      ...payload,
    })
  }
}
