import { StealthTrigger } from './stealth.model'
import { createAction, props, union } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { AddStealthTriggersPayload } from './stealth.types'

export const LoadStealthTriggers = createAction('[Stealth] Load Stealth triggers')
export const LoadStealthTriggersComplete = createAction(
  '[Stealth] Load Stealth triggers Complete',
  props<{ triggers: StealthTrigger[] }>()
)

export const AddStealthTriggers = createAction(
  '[Stealth] Add Stealth triggers',
  props<{ data: AddStealthTriggersPayload }>()
)
export const AddStealthTriggersComplete = createAction(
  '[Stealth] Add Stealth triggers Complete',
  props<{ triggers: StealthTrigger[] }>()
)

export const UpdateStealthTrigger = createAction(
  '[Stealth] Update Stealth trigger',
  props<{ id: string; changes: any }>()
)
export const UpdateStealthTriggerComplete = createAction(
  '[Stealth] Update Stealth trigger Complete',
  props<{ update: Update<StealthTrigger> }>()
)

export const PauseStealthTrigger = createAction('[Stealth] Pause Stealth trigger', props<{ id: string }>())
export const PauseStealthTriggerComplete = createAction(
  '[Stealth] Pause Stealth trigger Complete',
  props<{ update: Update<StealthTrigger> }>()
)

export const ResumeStealthTrigger = createAction('[Stealth] Resume Stealth trigger', props<{ id: string }>())
export const ResumeStealthTriggerComplete = createAction(
  '[Stealth] Resume Stealth trigger Complete',
  props<{ update: Update<StealthTrigger> }>()
)

export const ArchiveStealthTrigger = createAction('[Stealth] Archive Stealth trigger', props<{ id: string }>())
export const ArchiveStealthTriggerComplete = createAction(
  '[Stealth] Archive Stealth trigger Complete',
  props<{ update: Update<StealthTrigger> }>()
)

export const UnarchiveStealthTrigger = createAction('[Stealth] Unarchive Stealth trigger', props<{ id: string }>())
export const UnarchiveStealthTriggerComplete = createAction(
  '[Stealth] Unarchive Stealth trigger Complete',
  props<{ update: Update<StealthTrigger> }>()
)

export const DeleteStealthTrigger = createAction('[Stealth] Delete Stealth trigger', props<{ id: string }>())
export const DeleteStealthTriggerComplete = createAction(
  '[Stealth] Delete Stealth trigger Complete',
  props<{ id: string }>()
)

export const DeleteStealthTriggers = createAction('[Stealth] Delete Stealth Triggers', props<{ ids: string[] }>())
export const DeleteStealthTriggersComplete = createAction(
  '[Stealth] Delete Stealth Triggers Complete',
  props<{ ids: string[] }>()
)

const all = union({
  LoadStealthTriggers,
  LoadStealthTriggersComplete,
  AddStealthTriggers,
  AddStealthTriggersComplete,
  UpdateStealthTrigger,
  UpdateStealthTriggerComplete,
  PauseStealthTrigger,
  PauseStealthTriggerComplete,
  ResumeStealthTrigger,
  ResumeStealthTriggerComplete,
  ArchiveStealthTrigger,
  ArchiveStealthTriggerComplete,
  UnarchiveStealthTrigger,
  UnarchiveStealthTriggerComplete,
  DeleteStealthTrigger,
  DeleteStealthTriggerComplete,
  DeleteStealthTriggers,
  DeleteStealthTriggersComplete,
})

export type StealthActionsUnion = typeof all
