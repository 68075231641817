import { Component, Input, HostBinding } from '@angular/core'
import * as lodash from 'lodash'
import { isNumber, sumBy } from 'lodash'

const COLORS = [
  ['#5cddb0', '#e0faf1'],
  ['#ed44b0', '#fde8f5'],
  ['#7d5bf3', '#e3dff8'],
]

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  host: {
    class: 'avatar',
  },
})
export class AvatarComponent {
  symbol: string
  _text: string
  @Input() set text(text: string) {
    text = lodash.trim(text)
    this._text = text
    this.symbol = text && text.toString()[0].toUpperCase()
    this.color = this.color || this.getDefaultColors()[0]
    this.borderColor = this.borderColor || this.getDefaultColors()[0]
    this.backgroundColor = this.backgroundColor || this.getDefaultColors()[1]
  }

  @HostBinding('class.image')
  @Input()
  image: string

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  @HostBinding('style.min-width.px')
  @HostBinding('style.min-height.px')
  @Input()
  size = 50

  @Input() border: boolean | number = false

  @HostBinding('style.color')
  @Input()
  color: string

  @HostBinding('style.border-color')
  @Input()
  borderColor: string

  @HostBinding('style.border-width.px')
  get borderWidth() {
    if (isNumber(this.border)) return this.border

    return !this.border ? 0 : 1
  }

  @HostBinding('style.background-color')
  @Input()
  backgroundColor: string

  @HostBinding('style.font-size.px')
  get textSize() {
    return Math.round(this.size * 0.5)
  }

  @HostBinding('class.circle')
  @Input()
  circle = true

  @HostBinding('class.image-error')
  isImageError = false

  getDefaultColors() {
    const value = sumBy(this._text, (c) => c.charCodeAt(0))
    return COLORS[value % COLORS.length]
  }

  onImageLoadError() {
    this.isImageError = true
  }
}
