import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ChannelNameTokens, Rental } from '@tokeet-frontend/tv3-platform'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectConnectionViewById } from '@tv3/store/connection/connection.selectors'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectSome, ChannelService } from '@tokeet-frontend/tv3-platform'
import { ChannelDialogService } from '@tv3/containers/channels/channel-detail/channel-dialog.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { isTiketLikeChannel } from '@tokeet-frontend/channels'

@Component({
  selector: 'app-api-connection-dialog',
  templateUrl: './api-connection-dialog.component.html',
  styleUrls: ['./api-connection-dialog.component.scss'],
})
export class ApiConnectionDialogComponent extends Destroyable implements OnInit {
  connection: ConnectionView
  rental: Rental
  channel: Channel
  dateFormat = 'DD-MMM-YYYY | h:mm A'

  isTiketLikeChannel = true
  isAirBnBV2 = false
  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    filter(() => !!this.channel && !!this.connection),
    switchMap(() =>
      this.connectionService.getApiConnectionLog(this.channel.name, this.connection.propertyId, this.connection.roomId)
    )
  )
  constructor(
    private store: Store<fromRoot.State>,
    private channelDialog: ChannelDialogService,
    private connectionService: ConnectionService,
    public dialogRef: MatDialogRef<ApiConnectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connectionId: string }
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(selectSome(selectConnectionViewById, { id: this.data.connectionId }), untilDestroy(this))
      .subscribe((connection: ConnectionView) => {
        this.connection = connection
        this.channel = connection.channel
        this.rental = connection.rental
        this.isTiketLikeChannel = isTiketLikeChannel(this.channel.name)
        this.isAirBnBV2 = ChannelService.isAirBnBV2(this.channel.name)
        this.refreshLogs$.next(true)
      })
  }

  onChannelDetails() {
    this.channelDialog.openSide(this.channel).subscribe()
  }

  close() {
    this.dialogRef.close()
  }
}
