import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  Channel,
  ConfirmDialogService,
  Destroyable,
  DialogService,
  selectChannelEntities,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { GapNightAutomationDialogComponent } from '../automation-dialog/gap-night-automation-dialog.component'
import { DeleteGapNightAutomation, GapNightAutomation } from '@tokeet-frontend/rate-automations'

@Component({
  selector: 'app-gap-night-automations-grid',
  templateUrl: './gap-night-automations-grid.component.html',
  styleUrls: ['./gap-night-automations-grid.component.scss'],
})
export class GapNightAutomationsGridComponent extends Destroyable implements OnInit {
  @Input() items: GapNightAutomation[] = []

  channelsById: Record<string, Channel> = {}

  constructor(private store: Store<any>, private confirmDialog: ConfirmDialogService, private dialog: DialogService) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectChannelEntities), untilDestroy(this)).subscribe((data) => {
      this.channelsById = data
    })
  }

  onEdit(item: GapNightAutomation) {
    this.dialog.openFixedDialog(GapNightAutomationDialogComponent, {
      width: '600px',
      height: 'auto',
      data: { gapNight: item },
    })
  }

  onDelete(item: GapNightAutomation) {
    this.confirmDialog
      .confirm({
        title: 'Delete this automation?',
        body: 'Are you sure you want to delete this automation?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteGapNightAutomation({ id: item.key }))
      })
  }
}
