import { Component, Input, OnInit } from '@angular/core'
import * as lodash from 'lodash'

import {
  Channel,
  Destroyable,
  RateView,
  Rental,
  RentalRateItem,
  TOKEET_CHANNEL_ID,
  isSomething,
  selectChannelEntities,
  tokeetDashboardChannel,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Store, select } from '@ngrx/store'

@Component({
  selector: 'app-rate-popover-v2',
  templateUrl: './rate-popover-v2.component.html',
  styleUrls: ['./rate-popover-v2.component.scss'],
})
export class RatePopoverV2Component extends Destroyable implements OnInit {
  @Input() rental: Rental
  @Input() date: number

  @Input() rate: RentalRateItem
  @Input() ratesByCategory: Record<string, RentalRateItem>

  channelsById: Record<string, Channel> = {}

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectChannelEntities), untilDestroy(this)).subscribe((channels) => {
      this.channelsById = channels
      this.channelsById[TOKEET_CHANNEL_ID] = tokeetDashboardChannel
    })
  }

  getCategoryName = (category: string) => {
    if (this.channelsById && this.channelsById[category]) {
      return this.channelsById[category].friendlyName
    }

    category = category === TOKEET_CHANNEL_ID ? tokeetDashboardChannel.friendlyName : category

    return lodash.startCase(category)
  }

  getChannelRates = (ratesByCategory: Record<string, RentalRateItem>, channelsById: Record<string, Channel>) => {
    if (!isSomething(ratesByCategory) || !channelsById) return []

    return lodash
      .chain(ratesByCategory)
      .pickBy((r, key) => !!r)
      .map((r, key) => ({
        name: this.getCategoryName(key),
        price: r.price,
        minimumstay: r.minimumstay,
        maximumstay: r.maximumstay,
        currency: r.currency,
      }))
      .uniqBy((item) => lodash.toLower(item.name))
      .sortBy((item) => (item.name === 'Default' ? '0' : lodash.toLower(item.name)))
      .value()
  }
}
