<div class="btn-picker"
  [ngStyle]="{
    'width.px': size,
    'height.px': size,
    'background-color': backgroundColor | call:activeColor,
    'border-color': borderColor | call:activeColor:activeColor
  }"
  [ngbPopover]="tmplPopover" #popover="ngbPopover"
  container="body" [placement]="popoverPlacement"
  popoverClass="popover-color-picker popover-fill-content">
</div>

<ng-template #tmplPopover>
  <div class="colors-container">
    <div *ngFor="let color of colors"
      (click)="onChangeColor(color)"
      class="color-item"
      [ngClass]="{ 'active': color === activeColor }"
      [ngStyle]="{
        'background-color': backgroundColor | call:color,
        'border-color': borderColor | call:color:activeColor
      }">
    </div>
  </div>
</ng-template>
