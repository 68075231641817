<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Product</div>
      <div class="col-sm-6 btn-holder">
        <button type="button" class="btn btn-secondary-info ml-2" (click)="onSave(form)">
          <i class="cs-icon cs-icon-save"></i> Save
        </button>
      </div>
    </div>

    <div class="p-3">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Minimum days before the arrival date for which this rate plan may be booked."
                  matTooltipPosition="above"
                >
                  <input
                    type="text"
                    formControlName="minAdvancedBookingOffset"
                    matInput
                    placeholder="Min advance bookings"
                  />
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Maximum days before the arrival date for which this rate plan may be booked."
                  matTooltipPosition="above"
                >
                  <input
                    type="text"
                    formControlName="maxAdvancedBookingOffset"
                    matInput
                    placeholder="Max advance booking"
                  />
                </mat-form-field2>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Minimum number of days that a product can be booked."
                  matTooltipPosition="above"
                >
                  <input type="text" formControlName="minLengthOfStay" matInput placeholder="Min Length Of Stay" />
                </mat-form-field2>
              </div>

              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="Maximum number of days that a product can be booked."
                  matTooltipPosition="above"
                >
                  <input type="text" formControlName="maxLengthOfStay" matInput placeholder="Max Length Of Stay" />
                </mat-form-field2>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The date range for which this rate plan is offered for sell."
                  matTooltipPosition="above"
                >
                  <input
                    matInput
                    [matDatepicker]="sellStartPicker"
                    [min]="minDateDefault"
                    [max]="sellMaxStartDate.value"
                    placeholder="Sell Date Range Start"
                    [required]="true"
                    (focus)="sellStartPicker.open()"
                    formControlName="sellDateRangeStart"
                    tabindex="4"
                    readonly
                  />
                  <i matSuffix (click)="sellStartPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #sellStartPicker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      form.get('sellDateRangeStart').touched && form.get('sellDateRangeStart').hasError('required')
                    "
                  >
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The date range for which this rate plan is offered for sell."
                  matTooltipPosition="above"
                >
                  <input
                    matInput
                    [matDatepicker]="sellEndPicker"
                    [max]="maxDateDefault"
                    [min]="sellMinEndDate.value"
                    placeholder="Sell Date Range End"
                    [required]="true"
                    (focus)="sellEndPicker.open()"
                    formControlName="sellDateRangeEnd"
                    tabindex="4"
                    readonly
                  />
                  <i matSuffix (click)="sellEndPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #sellEndPicker></mat-datepicker>
                  <mat-error
                    *ngIf="form.get('sellDateRangeEnd').touched && form.get('sellDateRangeEnd').hasError('required')"
                  >
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
            </div>
          </div>
          <div class="col-sm-6"></div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The date range for which this rate plan is offered for guest stays."
                  matTooltipPosition="above"
                >
                  <input
                    matInput
                    [matDatepicker]="stayStartPicker"
                    [min]="minDateDefault"
                    [max]="stayMaxStartDate.value"
                    placeholder="Stay Date Range Start"
                    [required]="true"
                    (focus)="stayStartPicker.open()"
                    formControlName="stayDateRangeStart"
                    tabindex="4"
                    readonly
                  />
                  <i matSuffix (click)="stayStartPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #stayStartPicker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      form.get('stayDateRangeStart').touched && form.get('stayDateRangeStart').hasError('required')
                    "
                  >
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-6">
                <mat-form-field2
                  class="mat-block"
                  matTooltip="The date range for which this rate plan is offered for guest stays."
                  matTooltipPosition="above"
                >
                  <input
                    matInput
                    [matDatepicker]="stayEndPicker"
                    [max]="maxDateDefault"
                    [min]="stayMinEndDate.value"
                    placeholder="Stay Date Range End"
                    [required]="true"
                    (focus)="stayEndPicker.open()"
                    formControlName="stayDateRangeEnd"
                    tabindex="4"
                    readonly
                  />
                  <i matSuffix (click)="stayEndPicker.open()" class="far fa-calendar-alt"></i>
                  <mat-datepicker #stayEndPicker></mat-datepicker>
                  <mat-error
                    *ngIf="form.get('stayDateRangeEnd').touched && form.get('stayDateRangeEnd').hasError('required')"
                  >
                    Value is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
            </div>
          </div>
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <app-select-search
              [items]="cancelPolicies"
              [multiple]="false"
              [showTooltips]="true"
              bindLabel="name"
              bindValue="code"
              multiple="false"
              placeholder="Cancel Policy"
              [ctrl]="form.get('cancelPolicyCode')"
            >
            </app-select-search>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
