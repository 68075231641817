export const inquiriesRouteMapping = {
  list: {
    name: 'admin.inquiries', // back compliant for tv2
    url: 'list',
  },
  inbox: {
    name: 'admin.inquiriesv2',
    url: 'inbox',
  },
  messages: {
    name: 'admin.messages',
    url: 'message-feed',
  },
} as { [name: string]: { name: string; url: string; default?: boolean } }

export const defaultLocalInquiriesRouteMapping = inquiriesRouteMapping.inbox
