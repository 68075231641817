import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromChannel from './channel.reducer'
import { ChannelEffects } from './channel.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('channel', fromChannel.channelReducer),
    EffectsModule.forFeature([ChannelEffects]),
  ],
  declarations: [],
})
export class ChannelStoreModule {}
