import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectConnectionViewById } from '@tv3/store/connection/connection.selectors'
import { filter, map } from 'rxjs/operators'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ChannelDialogService } from '@tv3/containers/channels/channel-detail/channel-dialog.service'
import {
  DeleteExportedCalendarConnectionComplete,
  DeleteImportedCalendarConnectionComplete,
} from '@tv3/store/connection/connection.actions'

@Component({
  selector: 'app-ical-connection-dialog',
  templateUrl: './ical-connection-dialog.component.html',
  styleUrls: ['./ical-connection-dialog.component.scss'],
})
export class IcalConnectionDialogComponent extends Destroyable implements OnInit {
  connection: ConnectionView
  rental: Rental
  channel: Channel

  constructor(
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private channelDialog: ChannelDialogService,
    public dialogRef: MatDialogRef<IcalConnectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connectionId: string }
  ) {
    super()
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectConnectionViewById, { id: this.data.connectionId }),
        filter((res) => isSomething(res)),
        untilDestroy(this)
      )
      .subscribe((connection: ConnectionView) => {
        this.connection = connection
        this.channel = connection.channel
        this.rental = connection.rental
      })

    this.actions
      .pipe(
        ofType(DeleteImportedCalendarConnectionComplete, DeleteExportedCalendarConnectionComplete),
        filter(({ id }) => id === this.connection.id),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.close()
      })
  }

  onChannelDetails() {
    this.channelDialog.openSide(this.channel).subscribe()
  }

  close() {
    this.dialogRef.close()
  }
}
