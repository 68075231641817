import { Pipe, PipeTransform } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { CustomChannelResponse, selectAllCustomChannels } from '@tokeet-frontend/channels'
import * as lodash from 'lodash'

const basePath = '/assets/images/channels/simple'
export function getChannelFaviconIcon(channel?: string, noDefault = false) {
  switch (channel?.toLowerCase()) {
    case undefined:
    case null:
    case '':
      if (noDefault) return ''
    case 'all channels':
      return `${basePath}/all-channels.png`
    case 'agoda':
      return `${basePath}/agoda.png`
    case 'airbnb':
    case 'airbnbapiv2':
      return `${basePath}/airbnb.svg`
    case 'booking':
    case 'booking.com':
    case 'bookingcom':
      return `${basePath}/booking.png`
    case 'expedia':
    case 'expedia.com':
      return `${basePath}/expedia.png`
    case 'ebab':
      return `${basePath}/ebab.png`
    case 'flipkey':
      return `${basePath}/flipkey.png`

    case 'cleartrip':
      return `${basePath}/cleartrip.png`
    case 'housetrip':
      return `${basePath}/housetrip.png`
    case 'holidaylettings':
      return `${basePath}/holidaylettings.png`

    case 'rentalsunited':
      return `${basePath}/rentalsunited.png`

    case 'google_vacation_rentals':
      return `${basePath}/google_vacation_rentals.png`

    case 'holiduapi':
    case 'holidu':
      return `${basePath}/holidu.png`

    case 'vrbo':
    case 'homeaway':
      return `${basePath}/homeaway.png`

    case 'tiket':
      return `${basePath}/tiket.png`

    case 'hometogo':
      return `${basePath}/hometogo.png`

    case 'hvmiapi':
    case 'hvmi':
    case 'marriott':
      return `${basePath}/hvmi.png`

    case 'tripvillas':
      return `${basePath}/tripvillas.png`

    case 'vacationhomerentals':
    case 'vacation home rental':
      return `${basePath}/vacation-home-rental.png`

    case 'vacationrentals':
    case 'vacation rentals':
      return `${basePath}/vacation-rentals.png`
    case 'vrboapi':
    case 'vrbo':
      return `${basePath}/vrbo.png`
    case 'wimdu':
      return `${basePath}/wimdu.png`
    case 'trip.com':
      return `${basePath}/trip.png`

    case 'sympl':
      return `${basePath}/sympl.png`
    case 'webready':
      return `${basePath}/webready.png`

    //#region PMS
    case 'fantasticstay':
      return `${basePath}/fantasticstay.png`
    case 'uplisting':
      return `${basePath}/uplisting.png`
    case 'ownerrez':
      return `${basePath}/owner-rez.png`
    case 'hotelavailabilities':
      return `${basePath}/hotelavailabilities.png`
    case 'zeevou':
      return `${basePath}/zeevou.png`
    case 'beds24':
      return `${basePath}/beds24.png`
    case 'hostaway':
      return `${basePath}/hostaway.png`
    case 'hostex':
      return `${basePath}/hostex.png`
    case 'rentalwise':
      return `${basePath}/rental-wise.svg`
    case 'lodgify':
      return `${basePath}/lodgify.png`
    case 'bookingautomation':
    case 'booking-automation':
      return `${basePath}/booking-automation.png`
    case 'apaleo':
      return `${basePath}/apaleo.png`
    case 'cloudbeds':
      return `${basePath}/cloudbeds.png`
    case 'hospitable':
      return `${basePath}/hospitable.png`
    // #endregion

    //#region social
    case 'hotmail':
      return `/assets/images/messaging/hotmail.png`
    case 'gmail':
      return `/assets/images/messaging/gmail.png`
    case 'facebook':
      return `/assets/images/messaging/facebook.png`
    case 'instagram':
      return `/assets/images/messaging/instagram.png`
    case 'whatsapp':
      return `/assets/images/messaging/whatsapp.svg`
    case 'yahoo':
      return `/assets/images/messaging/yahoo.png`
    case 'sms':
      return `/assets/images/messaging/sms.svg`
    //#endregion

    case 'tokeet':
      return `/assets/logo.svg`
    default:
      return noDefault ? '' : `${basePath}/unknown.png`
  }
}

@Pipe({
  name: 'inquirySourceIcon',
  pure: false,
})
export class InquirySourceIconPipe implements PipeTransform {
  customChannels: CustomChannelResponse[] = []
  constructor(private store: Store<any>) {
    this.store.pipe(select(selectAllCustomChannels)).subscribe((customChannels) => {
      this.customChannels = customChannels
    })
  }

  transform(source: string, noDefault = false): string {
    let icon = getChannelFaviconIcon(source, true)
    if (icon) {
      return icon
    }
    const custom = lodash.find(this.customChannels, (c) => c.channel_name === source)
    return custom?.logo || (noDefault ? '' : `${basePath}/unknown.png`)
  }
}
