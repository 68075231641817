import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { NoteEffects } from '@tv3/store/note/note.effects'

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([NoteEffects])],
  declarations: [],
})
export class NoteModule {}
