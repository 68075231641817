import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CommentsResponse } from './model'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CommentService {
  constructor(private http: HttpClient) {}

  getComments(type: DataEntityType, id: string) {
    return this.http.get<CommentsResponse>(`@api/comment/${type}/${id}`)
  }

  addComment(type: DataEntityType, id: string, data: { comment: string; creator_id: string }) {
    return this.http.post<CommentsResponse>(`@api/comment/${type}/${id}/`, {
      comments: [data],
    })
  }

  removeComment(type: DataEntityType, id: string, commentId: string) {
    return this.http.put<CommentsResponse>(`@api/comment/${type}/${id}/`, {
      comments: [commentId],
    })
  }
}
