<div class="modal-header">
  <h5 class="modal-title">Set Visibility</h5>
  <button (click)="close()" type="button" class="close">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="loading" class="d-flex flex-row justify-content-center">
    <mat-spinner matSuffix diameter="16"></mat-spinner>
  </div>

  <div *ngIf="!loading && isVisible" class="d-flex flex-column align-items-stretch gap-3">
    <app-select-search
      [inline]="false"
      [items]="parent?.choices"
      bindValue="value"
      bindLabel="description"
      [ctrl]="form.get('parentId')"
      [multiple]="false"
      [placeholder]="parent?.description"
      [required]="true"
    ></app-select-search>

    <app-select-search
      [inline]="false"
      [items]="selectedParent?.choices"
      bindValue="value"
      bindLabel="description"
      [ctrl]="form.get('childId')"
      [multiple]="false"
      [placeholder]="selectedParent?.description"
      *ngIf="selectedParent?.choices?.length"
      [required]="true"
    ></app-select-search>

    <mat-form-field2 *ngIf="isDetailRequired" class="mat-block">
      <input matInput placeholder="Details" [formControl]="form.get('details')" required />
    </mat-form-field2>
  </div>

  <div *ngIf="!loading && !isVisible">
    This listing is currently <b>not visible</b>. Would you like to make it visible?
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="!submitting" (click)="close()" type="button" class="btn btn-light">
    <i class="far fa-times"></i> Cancel
  </button>

  <mat-spinner *ngIf="submitting" matSuffix diameter="16"></mat-spinner>

  <button
    *ngIf="!loading"
    [disabled]="submitting"
    (click)="setVisibility(form)"
    type="button"
    class="btn btn-secondary-info text-uppercase create-btn"
  >
    <i class="far fa-check"></i>
    {{ isVisible ? 'Hide' : 'Set visible' }}
  </button>
</div>
