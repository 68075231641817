import { Injectable } from '@angular/core'
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions'
import { SharedModule } from '@tv3/shared/shared.module'
import { ActionDialogComponent, ActionDialogInput, ActionDialogItem } from './action-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

export type GetActionParams<T extends string = string> = Record<T, Partial<ActionDialogItem>>
export type GetAddEventActionParams = GetActionParams<'addBooking' | 'addHoldEvent'>
export type GetAddRateActionParams = GetActionParams<'addStandardRate' | 'addDynamicRate'>

@Injectable({
  providedIn: SharedModule,
})
export class ActionDialogService {
  constructor(
    private dialog: MatDialog,
    private ngxPermissionsService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService
  ) {}

  open(actions: ActionDialogItem[]) {
    actions = actions.filter((action) => action.visible !== false)

    if (!actions.length) return

    const defaultConfig: MatDialogConfig<ActionDialogInput> = {
      width: 'auto',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { actions, autoClose: true },
    }

    return this.dialog.open<ActionDialogComponent, ActionDialogInput, ActionDialogItem>(
      ActionDialogComponent,
      defaultConfig
    )
  }

  getAddEventActions(options: GetAddEventActionParams) {
    const actions: ActionDialogItem[] = []

    if (
      options.addBooking &&
      this.ngxPermissionsService.getPermission('canAddInquiry') &&
      !this.ngxRolesService.getRole('OWNER')
    ) {
      actions.push({
        text: 'Add Booking',
        icon: 'cs-icon cs-icon-calendar-plus3 text-success',
        tooltip: 'Manually create a new booking.',
        ...options.addBooking,
      })
    }

    if (options.addHoldEvent && this.ngxPermissionsService.getPermission('canAddHold')) {
      actions.push({
        text: 'Add Hold Event',
        icon: 'cs-icon cs-icon-calendar-pause2 text-info',
        tooltip: 'Block dates on your calendar and make them unavailable for booking.',
        ...options.addHoldEvent,
      })
    }

    return actions
  }

  openAddEventActions(options: GetAddEventActionParams) {
    return this.open(this.getAddEventActions(options))
  }

  getAddRateActions(options: GetAddRateActionParams, isGroupRate = false) {
    const textRate = isGroupRate ? 'Group Rate' : 'Rate'
    const textRates = isGroupRate ? 'group rates' : 'rates'

    const actions: ActionDialogItem[] = []
    if (!this.ngxPermissionsService.getPermission('canAddRates')) {
      return actions
    }
    if (options.addStandardRate) {
      actions.push({
        text: `Add Standard ${textRate}`,
        icon: 'cs-icon cs-icon-dollar text-danger',
        tooltip: `Standard ${textRates} are flat, constant rates which do not change over time.`,
        tooltipPosition: 'left',
        ...options.addStandardRate,
      })
    }
    if (options.addDynamicRate) {
      actions.push({
        text: `Add Dynamic ${textRate}`,
        icon: 'cs-icon cs-icon-dollar-sync text-indigo',
        // tslint:disable-next-line max-line-length
        tooltip: `Dynamic ${textRates} will gradually and automatically adjust from a low rate to a high rate as your overall rental occupancy level changes. They can also instantly update your channels as the rate changes.`,
        tooltipPosition: 'right',
        ...options.addDynamicRate,
      })
    }

    return actions
  }

  openAddRateActions(options: GetAddRateActionParams, isGroupRate = false) {
    return this.open(this.getAddRateActions(options, isGroupRate))
  }

  openCalendarActions(options: GetAddEventActionParams & GetAddRateActionParams, isGroupRate = false) {
    const actions = [...this.getAddEventActions(options), ...this.getAddRateActions(options, isGroupRate)]
    return this.open(actions)
  }

  openRentalCalendarActions(options: GetAddEventActionParams & GetAddRateActionParams) {
    const actions = [...this.getAddEventActions(options), ...this.getAddRateActions(options, false)]

    return this.open(actions)
  }
}
