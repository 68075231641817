<div class="modal-header">
  <div class="d-flex gap-2 align-items-center">
    <h5 class="modal-title"><i class="fal fa-key"></i> Change Password</h5>
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="New password:(minimum length of 8 characters)"
        formControlName="password1"
        type="password"
      />
      <mat-error *ngIf="form.get('password1').hasError('required')"> Password is <strong>required</strong> </mat-error>
      <mat-error *ngIf="form.get('password1').hasError('minNumber')">
        Password must be at least <strong>8</strong> characters long
      </mat-error>
      <mat-error *ngIf="form.get('password1').hasError('maxNumber')">
        Password must be at most 32 characters long
      </mat-error>
      <mat-error *ngIf="form.get('password1').hasError('pattern')"> Password must not contain spaces </mat-error>
    </mat-form-field2>

    <mat-form-field2 class="mat-block">
      <input matInput placeholder="Repeat password" formControlName="password2" type="password" />
      <mat-error *ngIf="form.get('password2').hasError('required')"> Password is <strong>required</strong> </mat-error>
      <mat-error *ngIf="form.get('password2').hasError('minlength')">
        Password must be at least <strong>8</strong> characters long
      </mat-error>
      <mat-error *ngIf="form.get('password2').hasError('maxlength')">
        Password must be at most 32 characters long
      </mat-error>
      <mat-error *ngIf="form.get('password2').hasError('pattern')"> Password must not contain spaces </mat-error>
      <mat-error *ngIf="form.get('password2').errors?.passwordMatch"> Passwords do not match </mat-error>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
