import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './user-preferences.reducer'
import * as state from './user-preferences.state'
import * as R from 'ramda'
import { UserPreferences } from './user-preferences.model'

export const preferenceState = createFeatureSelector<state.State>('user-preference')

export const selectAllUserPreferences = createSelector(preferenceState, reducer.selectAll)

export const selectUserPreferences = createSelector(
  selectAllUserPreferences,
  (preferences) => new UserPreferences(R.head(preferences))
)

export const selectUserPreferenceEntities = createSelector(preferenceState, reducer.selectEntities)

export const selectUserPreferencesLoaded = createSelector(preferenceState, (state: state.State) => {
  return state && state.isLoaded
})
