import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { filter, switchMap, tap } from 'rxjs/operators'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import {
  Destroyable,
  ConfirmDialogStatus,
  ConfirmDialogService,
  untilDestroy,
  DataCheckerService,
} from '@tokeet-frontend/tv3-platform'
import { AiResponderSettings } from '../store/ai-settings.model'
import { MatSlideToggle } from '@angular/material/slide-toggle'
import * as lodash from 'lodash'
import { selectAISettings } from '../store/ai-settings.selectors'
import { UpdateAISettings } from '../store/ai-settings.actions'
import { AISettingsGuard } from '../store/ai-settings.guard'

@Component({
  selector: 'app-ignore-ai-toggle',
  templateUrl: './ignore-ai-toggle.component.html',
  styleUrls: ['./ignore-ai-toggle.component.scss'],
})
export class IgnoreAIToggleComponent extends Destroyable implements OnInit {
  @Input() type: 'guest' | 'booking' | 'rental' | 'domain'
  @Input() item: { id: string }
  @Input() label: string
  @Input() view: 'button' | 'switch' = 'switch'

  tooltip: string
  @Input() set toggleTooltip(tooltip: string) {
    this.tooltip = tooltip
  }
  get toggleTooltip() {
    return this.tooltip || `Exclude this ${this.type} from Advance Intelligence automated messages`
  }
  @Input() tooltipPosition = 'above'

  isAISubInactive = true

  aiSettings: AiResponderSettings = null

  get ignoreListKey() {
    return this.type === 'domain' ? 'ignoreGuestsRegexp' : `ignore${lodash.capitalize(this.type)}s`
  }
  constructor(
    private store: Store<any>,
    private confirmDialog: ConfirmDialogService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([AISettingsGuard])
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectActiveProductPlanView(ProductsForPlan.TokeetAI)),
        filter((res) => res?.isValid),
        switchMap(() => this.store.pipe(select(selectAISettings))),
        untilDestroy(this)
      )
      .subscribe((settings) => {
        this.aiSettings = settings
        this.isAISubInactive = false
      })
  }

  isAIActive() {
    return !this.aiSettings?.[this.ignoreListKey]?.includes(this.item?.id)
  }

  onToggleAI(checked: boolean, toggle?: MatSlideToggle) {
    const confirm = () =>
      this.confirmDialog
        .openRegular({
          title: 'Warning',
          body: `Do you want to exclude this ${this.type} from Advance Intelligence automated responses?`,
        })
        .pipe(
          filter((status) => {
            if (status === ConfirmDialogStatus.Confirmed) {
              return true
            }
            if (toggle) toggle.checked = !checked
            return false
          })
        )

    const doAction = () => {
      let ignoreList = this.aiSettings?.[this.ignoreListKey] || []
      if (checked) {
        ignoreList.push(this.item.id)
      } else {
        ignoreList = ignoreList.filter((id) => id !== this.item.id)
      }
      this.store.dispatch(
        UpdateAISettings({
          payload: { [this.ignoreListKey]: ignoreList },
        })
      )
    }

    if (checked) {
      confirm().subscribe(() => doAction())
    } else {
      doAction()
    }
  }
}
