import { createAction, props } from '@ngrx/store'
import { AutomationResponse, UpdateAutomationPayload, CreateAutomationPayload } from './automation.model'
import { Update } from '@ngrx/entity'

export const LoadAutomations = createAction('[Automation] Load Automations')
export const LoadAutomationsComplete = createAction(
  '[Automation] Load Automations Complete',
  props<{ automations: AutomationResponse[] }>()
)

export const AddAutomation = createAction(
  '[Automation] Add Automation',
  props<{ ids?: string[]; payload: CreateAutomationPayload; silent?: boolean }>()
)
export const AddAutomationComplete = createAction(
  '[Automation] Add AutomationsComplete',
  props<{ automations: AutomationResponse[] }>()
)

export const RecreateAutomation = createAction(
  '[Automation] Recreate Automation',
  props<{ ids: string[]; payload: CreateAutomationPayload }>()
)

export const UpdateAutomations = createAction(
  '[Automation] Update Automations',
  props<{ items: UpdateAutomationPayload[] }>()
)

export const UpdateAutomationsComplete = createAction(
  '[Automation] Update AutomationsComplete',
  props<{ updates: Update<AutomationResponse>[] }>()
)

export const PauseAutomation = createAction(
  '[Automation] Pause Automation',
  props<{ ids: string[]; silent?: boolean }>()
)
export const PauseAutomationComplete = createAction(
  '[Automation] Pause Automation Complete',
  props<{ changes: Update<AutomationResponse>[] }>()
)

export const UnPauseAutomation = createAction(
  '[Automation] UnPause Automation',
  props<{ ids: string[]; silent?: boolean }>()
)
export const UnPauseAutomationComplete = createAction(
  '[Automation] UnPause Automation Complete',
  props<{ changes: Update<AutomationResponse>[] }>()
)

export const ArchiveAutomation = createAction('[Automation] Archive Automation', props<{ ids: string[] }>())
export const ArchiveAutomationComplete = createAction(
  '[Automation] Archive Automation Complete',
  props<{ changes: Update<AutomationResponse>[] }>()
)

export const UnArchiveAutomation = createAction('[Automation] UnArchive Automation', props<{ ids: string[] }>())
export const UnArchiveAutomationComplete = createAction(
  '[Automation] UnArchive Automation Complete',
  props<{ changes: Update<AutomationResponse>[] }>()
)

export const DeleteAutomation = createAction(
  '[Automation] Delete Automation',
  props<{ ids: string[]; silent?: boolean }>()
)
export const DeleteAutomationComplete = createAction(
  '[Automation] Delete Automation Complete',
  props<{ ids: string[] }>()
)
