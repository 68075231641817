import { Injectable } from '@angular/core'
import { Rental, DialogService } from '@tokeet-frontend/tv3-platform'
import { EditBookingPreferencesDialogComponent } from './edit-booking-preferences-dialog.component'

@Injectable()
export class EditBookingPreferencesDialogService {
  constructor(private dialog: DialogService) {}

  open(rental: Rental) {
    const config = {
      data: { rental },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(EditBookingPreferencesDialogComponent, config)

    return dialogRef
  }
}
