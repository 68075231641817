<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-link"></i>&nbsp;Set A Domain
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <h6>Free AdvanceCM Domain</h6>
        <p>Choose a subdomain from one of our free domains or setup a custom domain below.</p>
        <div class="d-flex gap-3">
          <mat-form-field2 class="mat-block">
            <span matPrefix>https://</span>
            <input type="text" matInput placeholder="Specify A Subdomain" formControlName="subdomain" />
            <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('pattern')">
              Subdomain is <strong>invalid</strong>
            </mat-error>
            <mat-error *ngIf="form.get('subdomain').touched && form.get('subdomain').hasError('allSpaces')">
              Subdomain <strong>cannot be spaces alone</strong>
            </mat-error>
          </mat-form-field2>

          <app-select-search
            [items]="domains"
            [ctrl]="form.get('domain')"
            [multiple]="false"
            [inline]="false"
            placeholder="Domain"
            matTooltip="Select one of the free AdvanceCM domains to use with your website."
            matTooltipPosition="above"
          >
          </app-select-search>
        </div>
      </div>
      <div class="col-12">
        <h6>Custom Domain</h6>
        <p>
          You may purchase your own domain and use it with your AdvanceCM website. Simply enter your domain below then
          follow
          <b
            ><a target="_blank" href="https://advance.helpdocs.io/tokeet-websites/custom-domains-with-tokeet-websites"
              >these instructions.</a
            ></b
          >
          Custom domains require DNS configuration before they are active.
          <br />
          <b *ngIf="data.website.isEngine">Booking engines require SSL.</b>
        </p>

        <mat-form-field2 class="mat-block">
          <span matPrefix>https://</span>
          <input type="text" matInput placeholder="Specify A Custom Domain" formControlName="customDomain" />
          <mat-error *ngIf="form.get('customDomain').touched && form.get('customDomain').hasError('url')">
            Domain is <strong>invalid</strong>
          </mat-error>
          <mat-error *ngIf="form.get('customDomain').touched && form.get('customDomain').hasError('allSpaces')">
            Domain <strong>cannot be spaces alone</strong>
          </mat-error>
          <mat-error *ngIf="form.get('customDomain').touched && form.get('customDomain').hasError('allNumbers')">
            Domain <strong>cannot be numbers alone</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <app-website-custom-domain-ssl
    [disabled]="!isCustomDomain"
    *ngIf="isSSLEnabled"
    [matTooltip]="
      !isCustomDomain
        ? 'Please enter and save a custom domain before requesting an SSL'
        : 'Request/Edit SSL for your custom domain'
    "
    matTooltipPosition="above"
    [asButton]="true"
    [website]="website"
  ></app-website-custom-domain-ssl>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn" [disabled]="!isFormValid()">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
