import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

export function createAddressForm(addr?: any): FormGroup {
  return new FormGroup({
    address: new FormControl(addr?.address, [Validators.required]),
    city: new FormControl(addr?.city, [Validators.required]),
    country: new FormControl(addr?.country, [Validators.required]),
    country_code: new FormControl(addr?.countryCode || addr?.country_code, [Validators.required]),
    postal_code: new FormControl(addr?.postalCode || addr?.postal_code, [Validators.required]),
    state: new FormControl(addr?.state, [Validators.required]),
  })
}

@Component({
  selector: 'app-address-form',
  templateUrl: './AddressForm.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class AddressFormComponent implements OnInit {
  @Input() form: FormGroup

  ngOnInit() {}
}
