export enum IncidentStatus {
  Open = 'open',
  InReview = 'in_review',
  Tasked = 'tasked',
  Resolved = 'resolved',
  Escalated = 'escalated',
  OnHold = 'on_hold',
}

export enum IncidentSeverity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical',
}

export interface IncidentResponse {
  pkey: string
  account: number
  incident_id: string // "IQWC4VP4"

  categories: string[]
  description: string
  title: string

  images: string[]

  inquiry_id?: string
  guest_id?: string
  rental_id?: string
  reporter_id: string

  status: IncidentStatus
  severity: IncidentSeverity

  reviewer_id: string

  resolved: string
  comments: string
  task_id?: string

  created: number
  archived: number
}

export interface IncidentView extends IncidentResponse {
  createdView: string
  reporterView: string
  reviewerView: string

  rentalView: string
}

export interface SearchIncidentParams {
  rentals: string[]
  severity: IncidentSeverity
  status: IncidentStatus
  categories: string[]
  period: {
    from: number
    to: number
  }
}

export interface CreateIncidentPayload {
  title: string

  status?: IncidentStatus
  severity?: IncidentSeverity

  inquiry_id?: string
  rental_id?: string
  guest_id?: string
  description: string
  reporter_id: string
  reviewer_id?: string

  categories: string[]
  images?: string[]

  archived?: 0 | 1
}

export type UpdateIncidentPayload = Partial<CreateIncidentPayload>
