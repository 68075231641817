<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h6 class="modal-title">
          <img [src]="channel.logoColor" [alt]="channel.friendlyName" style="height: 38px" />
        </h6>
        <div class="btn-holder d-flex align-items-center">
          <app-select-search
            *ngIf="showChannelNameFilter"
            [multiple]="false"
            [items]="availableChannelNames$ | async"
            [ctrl]="filters.get('channel')"
            [hasBlank]="true"
            blankLabel="All"
            placeholder="Channel Name"
            matTooltip="Filter Calendars by channel name."
            matTooltipPosition="below"
            [inline]="true"
            style="width: 12rem"
          >
          </app-select-search>

          <div dropdown class="btn-group ml-2" [isDisabled]="1 | isReadonlyRole">
            <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle text-nowrap">
              <span class="cs-icon cs-icon-bolt"></span> Actions
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li
                matTooltip="Download a .csv file that shows all stored calendar information for this channel."
                matTooltipPosition="left"
              >
                <button (click)="onExportAll()" type="button">Export All Calendars</button>
              </li>
              <li
                matTooltip="Upload a .csv file with a list of calendar URLs to quickly create all imports."
                matTooltipPosition="left"
              >
                <button (click)="onImportMultiple()" type="button">Import Multiple Calendars</button>
              </li>
              <li matTooltip="Refresh all imported calendars under current channel." matTooltipPosition="left">
                <button (click)="onRefreshAllImports()" type="button">Refresh All Imports</button>
              </li>
            </ul>
          </div>

          <button
            *ngIf="isAirBnB"
            (click)="onUploadAirBnBTransactions()"
            [disabled]="1 | isReadonlyRole"
            type="button"
            class="btn btn-secondary-info ml-2"
          >
            <span class="cs-icon cs-icon-upload" aria-hidden="true"></span> Upload Transactions
          </button>
        </div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box mb-3">
        <app-channel-exported-calendars
          *ngIf="channel"
          [selectedChannelName]="filters.get('channel').value"
          [channel]="channel"
          [dateFormat]="dateFormat"
        >
        </app-channel-exported-calendars>
      </div>
      <div class="container-box">
        <app-channel-imported-calendars
          *ngIf="channel"
          [selectedChannelName]="filters.get('channel').value"
          [channel]="channel"
          [dateFormat]="dateFormat"
        >
        </app-channel-imported-calendars>
      </div>
    </div>
  </div>
</div>
