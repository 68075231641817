<app-form-field-render [field]="self" [prefix]="tagPrefixTmpl">
  <ng-select
    #select
    [items]="items"
    [multiple]="true"
    [addTag]="addTag ? addTagFn : false"
    [addTagText]="addTagText"
    [notFoundText]="notFoundText"
    [closeOnSelect]="true"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [placeholder]="placeholder"
    [virtualScroll]="true"
    [hideSelected]="true"
    appendTo="body"
    [formControl]="ctrl"
    [ngClass]="'form-control'"
    (open)="onSelectDropdownOpen()"
    (close)="onSelectDropdownClose()"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear" let-label="label">
      <div class="selected-tag-item">
        <span class="tag-label">{{ label }}</span>
        <span class="tag-clear-icon" (click)="$event.stopPropagation(); clear(item)"><i class="far fa-times"></i></span>
      </div>
    </ng-template>
  </ng-select>
</app-form-field-render>

<ng-template #tagPrefixTmpl>
  <form-field-affix>
    <i class="far fa-tags"></i>
  </form-field-affix>
</ng-template>
