<div class="container-box">
  <!-- input fields -->
  <div class="input-holder-top filters-box">
    <app-select-search
      matTooltip="Only show channel connections that specify the selected rentals."
      matTooltipPosition="above"
      bindValue="id"
      bindLabel="name"
      [items]="connectionFilters.rentals$ | asyncSchedule | async"
      [ctrl]="filters.get('rentals')"
      placeholder="Rentals"
      *ngIf="!defaultRental"
    ></app-select-search>
    <app-select-search
      matTooltip="Only show channel connections that specify the selected channels."
      matTooltipPosition="above"
      [items]="connectionFilters.channels$ | async"
      [ctrl]="filters.get('channels')"
      placeholder="Channels"
    ></app-select-search>
    <mat-form-field2
      matTooltip="Only show channel connections that specify the selected type."
      matTooltipPosition="above"
      [showLabel]="false"
    >
      <mat-select [formControl]="filters.get('type')" placeholder="Type">
        <mat-option value="all">All Types</mat-option>
        <mat-option [value]="typeTexts.API" [disabled]="channelTypeCtrl.value === 'ical'">
          {{ typeTexts.API }}
        </mat-option>
        <mat-option [value]="typeTexts.Exported" [disabled]="channelTypeCtrl.value === 'api'">
          {{ typeTexts.Exported }}
        </mat-option>
        <mat-option [value]="typeTexts.Imported" [disabled]="channelTypeCtrl.value === 'api'">
          {{ typeTexts.Imported }}
        </mat-option>
      </mat-select>
    </mat-form-field2>
    <mat-form-field2
      matTooltip="Only show channel connections that specify the selected status."
      matTooltipPosition="above"
      [showLabel]="false"
    >
      <mat-select [formControl]="filters.get('status')" placeholder="Status">
        <mat-option value="all">All Status</mat-option>
        <mat-option [value]="statusTexts.Active">{{ statusTexts.Active }}</mat-option>
        <mat-option [value]="statusTexts.Disabled">{{ statusTexts.Disabled }}</mat-option>
        <mat-option [value]="statusTexts.Pending">{{ statusTexts.Pending }}</mat-option>
        <mat-option [value]="statusTexts.Stale">{{ statusTexts.Stale }}</mat-option>
      </mat-select>
    </mat-form-field2>
    <mat-form-field2
      matTooltip="Only show channel connections that specify the selected linkage type."
      matTooltipPosition="above"
      [showLabel]="false"
    >
      <mat-select [formControl]="filters.get('linkage')" placeholder="Linkage">
        <mat-option value="all">All Connections</mat-option>
        <mat-option value="linked">Linked Connections</mat-option>
        <mat-option value="unlinked">Unlinked Connections</mat-option>
      </mat-select>
    </mat-form-field2>
    <app-cancel-filters
      [isFiltering]="filters | isFiltering"
      [filterControl]="filters"
      [ignoreFields]="defaultRental ? ['rentals'] : ['channelType']"
      [searchCom]="searchCom"
    ></app-cancel-filters>
    <button
      (click)="onRefresh()"
      class="btn btn-secondary-primary"
      type="button"
      matTooltip="Refresh"
      matTooltipPosition="above"
    >
      <i class="far fa-sync"></i>
    </button>
    <div class="flex-fill"></div>
    <app-table-search (search)="onSearch($event)" [filterGroup]="filterGroup" #searchCom></app-table-search>
  </div>

  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="warning" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              [checked]="selection.hasValue() && isAllSelected(isSelectAllRenderedItems)"
              [indeterminate]="selection.hasValue() && !isAllSelected(isSelectAllRenderedItems)"
              (change)="onMasterToggle($event?.checked)"
              class="master-toggle"
            >
            </mat-checkbox>
            <div
              class="btn-group master-toggle-group clickable"
              dropdown
              container="body"
              [isDisabled]="!dataSource.data?.length"
            >
              <div dropdownToggle>
                <i class="fa fa-caret-down ml-2"></i>
              </div>
              <ul class="dropdown-menu" *dropdownMenu>
                <li>
                  <button type="button" (click)="onSelectAll(true)">All</button>
                </li>
                <li>
                  <button type="button" (click)="onSelectAll(false)">All Visible</button>
                </li>
                <li>
                  <button type="button" (click)="onMasterToggle(false)">None</button>
                </li>
              </ul>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
              *ngIf="!element.isFeedApi"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="channelFriendlyName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Channel</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.channelFriendlyName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="typeText">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.typeText }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="linkDateOrCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date Linked</mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [matTooltip]="element.linkDateOrCreated | epoch : 'DD - MMM - YYYY h:mm:ss A'"
            matTooltipPosition="above"
          >
            {{ element.linkDateOrCreatedView }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="rentalName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.rentalName" matTooltipPosition="above">
              {{ element.rentalName || '....' | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="propertyNameView">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.propertyNameView" matTooltipPosition="above" class="text-truncate">
              {{ element.propertyNameView }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="statusText">
          <mat-header-cell *matHeaderCellDef mat-sort-header> status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="getStatusChangedTime(element.statusChanged)" matTooltipPosition="above">
              {{ element.statusText }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>
            <app-table-column-manager
              tableId="connections"
              [displayedColumns]="displayedColumns"
              (columnsChanged)="displayedColumns = $event"
            ></app-table-column-manager>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div dropdown placement="bottom right" container="body">
              <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                <a class="btn-ellips">
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li>
                  <button type="button" (click)="onConnectionDetail(element, false)">
                    <i class="cs-icon cs-icon-pencil"></i> Connection Detail
                  </button>
                </li>
                <ng-container *ngIf="!(1 | isReadonlyRole)">
                  <li>
                    <button (click)="onChannelDetails(element.channel)" type="button">
                      <i class="fal fa-share-alt-square"></i>Channel Detail
                    </button>
                  </li>
                  <li *ngIf="!defaultRental && element.rental">
                    <button type="button" (click)="onRentalDetails(element.rental)">
                      <i class="fal fa-home"></i> Rental Detail
                    </button>
                  </li>
                  <li *ngIf="element.isApi && !element.rentalId">
                    <button type="button" (click)="onLinkRental(element)"><i class="fal fa-link"></i> Link</button>
                  </li>
                  <li *ngIf="!defaultRental && hasWizard(element)">
                    <button type="button" (click)="onOpenWizard(element)">
                      <i class="fal fa-life-ring"></i> Connection Wizard
                    </button>
                  </li>
                  <li *ngIf="element.isApi && element.rentalId && !(isFeed | call : element)">
                    <button type="button" (click)="onUnlinkRental(element)">
                      <i class="fal fa-unlink"></i> Unlink
                    </button>
                  </li>
                  <li *ngIf="!element.isApi">
                    <button (click)="onRemove(element)" type="button">
                      <i class="cs-icon cs-icon-trash"></i>Delete
                    </button>
                  </li>
                </ng-container>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onConnectionDetail(row)"
          [class.row-danger]="row.warning"
          class="clickable"
        ></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>

      <app-empty-table
        *ngIf="isEmptyTable$ | async"
        [isFiltering]="(filters | isFiltering) || !searchCom.isEmpty"
        [type]="tableType"
      ></app-empty-table>
    </table>
  </div>
</div>
