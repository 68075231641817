import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EntityTagsLibraryModule } from '@tokeet-frontend/tags'
import { InvoiceBillingAddressComponent } from './components/invoice-billing-address/invoice-billing-address.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { SelectBillingAddressDialogComponent } from './components/select-billing-address-dialog/select-billing-address-dialog.component'
import { BillingAddressDialogComponent } from './components/billing-address-dialog/billing-address-dialog.component'
import { AddressFormComponent } from './components/AddressForm/AddressForm.component'

const components = [
  InvoiceBillingAddressComponent,
  SelectBillingAddressDialogComponent,
  AddressFormComponent,
  BillingAddressDialogComponent,
]

@NgModule({
  imports: [CommonModule, Tv3PlatformModule, EntityTagsLibraryModule],
  declarations: [...components],
  exports: [...components],
})
export class InvoiceLibModule {}
