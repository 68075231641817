import { Directive, ElementRef, Injector, Input, NgZone, ViewContainerRef } from '@angular/core'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { BookingDetailsPopupComponent } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.component'
import { Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { BOOKING_DETAILS_DATA } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.token'
import { filter, map, switchMap } from 'rxjs/operators'
import { LoadInquiryDetails } from '@tv3/store/inquiry/inquiry.actions'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { MultiCalendarEventView } from '@tv3/store/calendar/calendar.view'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'

@Directive({
  selector: '[appBookingDetails]',
  host: {
    '(click)': 'show()',
  },
})
export class BookingDetailsDirective extends Destroyable {
  private _event: MultiCalendarEventView
  private _rental: Rental

  private _overlayRef: OverlayRef | null
  private _portal: ComponentPortal<BookingDetailsPopupComponent>
  private _componentInstance: BookingDetailsPopupComponent | null

  @Input('appBookingDetails')
  get event() {
    return this._event
  }

  set event(value) {
    this._event = value
  }

  @Input('appBookingDetailsRental')
  get rental() {
    return this._rental
  }

  set rental(value) {
    this._rental = value
  }

  constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef<HTMLElement>,
    private _viewContainerRef: ViewContainerRef,
    private inquiryService: InquiryService,
    private _injector: Injector,
    private store: Store<fromRoot.State>,
    private _ngZone: NgZone
  ) {
    super()
  }

  _createOverlay() {
    if (this._overlayRef) {
      return this._overlayRef
    }

    // const topOffset = window.innerHeight < 700 ? '0px' : window.innerHeight > 900 ? '75px' : '25px'
    const strategy = this._overlay.position().global().centerHorizontally().centerVertically() //.top(topOffset)
    // .withTransformOriginOn('.booking-details-popup')
    // .withFlexibleDimensions(false)
    // .withDefaultOffsetX(50)
    // .withViewportMargin(50)
    // .withPositions([{ originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' }])
    // .withPush(true)

    // strategy.positionChanges.pipe(untilDestroy(this)).subscribe((change) => {
    //   if (this._componentInstance) {
    //     if (change.scrollableViewProperties.isOverlayClipped && this._componentInstance.isVisible()) {
    //       this._ngZone.run(() => this.hide())
    //     }
    //   }
    // })

    this._overlayRef = this._overlay.create({
      // height: '472px',
      width: '800px',
      positionStrategy: strategy,
      hasBackdrop: true,
    })

    this._overlayRef
      .detachments()
      .pipe(untilDestroy(this))
      .subscribe(() => this._detach())

    return this._overlayRef
  }

  private _detach() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach()
    }
    this._componentInstance = null
  }

  show() {
    if (this.event.isHoldEvent) {
      return
    }

    const overlayRef = this._createOverlay()

    this._detach()

    this.inquiryService
      .loadPreview(this._getEventID(this.event))
      .pipe(
        switchMap(
          (inquiry) => this.inquiryService.prepareCheckInOut(inquiry, false),
          (inquiry, checkInOut) => ({ inquiry, checkInOut })
        ),
        map(({ inquiry, checkInOut }) => {
          this.store.dispatch(LoadInquiryDetails({ id: inquiry.id }))

          return {
            event: this.event,
            rental: this.rental,
            inquiry,
            checkin: checkInOut.checkIn,
            checkout: checkInOut.checkOut,
          }
        }),
        filter(() => !overlayRef.hasAttached())
      )
      .subscribe((params) => {
        this._portal =
          this._portal ||
          new ComponentPortal(
            BookingDetailsPopupComponent,
            this._viewContainerRef,
            this.createInjector({
              ...params,
            })
          )
        this._componentInstance = overlayRef.attach(this._portal).instance
        this._componentInstance
          .afterHidden()
          .pipe(untilDestroy(this))
          .subscribe(() => this._detach())
        this._componentInstance!.show()
      })
  }

  private _getEventID(event: MultiCalendarEventView) {
    return event.id || event.bookingId
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap()
    injectorTokens.set(BOOKING_DETAILS_DATA, dataToPass)
    return new PortalInjector(this._injector, injectorTokens)
  }

  hide(): void {
    if (this._componentInstance) {
      this._componentInstance.hide()
    }
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose()
      this._componentInstance = null
    }
  }
}
