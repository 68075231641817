import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromInvoiceTemplate from './template.reducer'
import { EffectsModule } from '@ngrx/effects'
import { InvoiceTemplateEffects } from './template.effects'
import { InvoiceTemplateService } from './template.service'
import { InvoiceTemplateGuard } from './template.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('invoice-template-lib', fromInvoiceTemplate.invoiceTemplateReducer),
    EffectsModule.forFeature([InvoiceTemplateEffects]),
  ],
  providers: [InvoiceTemplateService, InvoiceTemplateGuard],
  declarations: [],
})
export class InvoiceTemplateStoreLibraryModule {}
