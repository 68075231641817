import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectVrboWizardComponent } from './connect-vrbo-wizard.component'
import { ConnectVrboWizardCompleteComponent } from './complete/connect-vrbo-wizard-complete.component'
import { ConnectVrboWizardStep1Component } from './step1/connect-vrbo-wizard-step1.component'
import { ConnectVrboWizardStep2Component } from './step2/connect-vrbo-wizard-step2.component'
import { ConnectVrboWizardStep3Component } from './step3/connect-vrbo-wizard-step3.component'
import { VrboFeesDialogComponent } from './vrbo-fees-dialog/vrbo-fees-dialog.component'
import { VrboFeesDialogService } from './vrbo-fees-dialog/vrbo-fees-dialog.service'
import { VrboRentalValidationDialogService } from './vrbo-rental-validation/vrbo-rental-validation-dialog.service'
import { VrboRentalValidationDialogComponent } from './vrbo-rental-validation/vrbo-rental-validation-dialog.component'

import {
  VrboIsInvalidRentalPipe,
  VrboIsRentalAddressInvalidPipe,
  VrboIsRentalBedroomBathroomInvalidPipe,
  VrboIsRentalDescriptionInvalidPipe,
  VrboIsRentalFewPhotosPipe,
  VrboIsRentalLatLongInvalidPipe,
  VrboIsRentalNameInvalidPipe,
} from './is-valid-rental.pipe'
import { SharedModule } from '@tv3/shared/shared.module'

const components = [
  // Vrbo
  ConnectVrboWizardStep1Component,
  ConnectVrboWizardStep2Component,
  ConnectVrboWizardStep3Component,
  ConnectVrboWizardCompleteComponent,
  ConnectVrboWizardComponent,
  VrboFeesDialogComponent,
  VrboRentalValidationDialogComponent,
]

const services = [
  //
  VrboFeesDialogService,
  VrboRentalValidationDialogService,
]

const pipes = [
  VrboIsInvalidRentalPipe,
  VrboIsRentalAddressInvalidPipe,
  VrboIsRentalBedroomBathroomInvalidPipe,
  VrboIsRentalDescriptionInvalidPipe,
  VrboIsRentalFewPhotosPipe,
  VrboIsRentalLatLongInvalidPipe,
  VrboIsRentalNameInvalidPipe,
]

@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components],
  providers: [...services],
  imports: [CommonModule, SharedModule, Tv3PlatformModule],
})
export class ConnectVrboWizardModule {}
