import { epochToView, Serializable, plainToClass, Expose } from '@tokeet-frontend/tv3-platform'

export class DataFeed extends Serializable<DataFeed> {
  @Expose({ name: 'pkey' })
  id: string

  secret: string
  created: number
  type: 'inquiry' | 'guest' | 'expense' | 'invoice' | 'rental' | 'task' | 'user'
  status: number
  lastpull: number
  name: string
  archived: number
  url: string
  account: number

  createdView: string
  createdFullView: string
  lastPullView: string

  static deserialize(data: any): DataFeed {
    let feed: DataFeed = plainToClass<DataFeed, DataFeed>(DataFeed, data)

    feed.createdView = epochToView(feed.created)
    feed.createdFullView = epochToView(feed.created, 'DD - MMM - YYYY | h:mm A')
    feed.lastPullView = epochToView(feed.lastpull)

    return feed
  }
}
