import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'

@Pipe({
  name: 'isNil',
})
export class IsNilPipe implements PipeTransform {
  transform(value: any): boolean {
    return R.isNil(value)
  }
}
