import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import {
  LoadActivePlans,
  LoadActivePlansComplete,
  LoadPlansForProduct,
  LoadPlansForProductComplete,
  LoadPlansForAdvanceCM,
  LoadPlansForAdvanceCMComplete,
  SubscribePlanForProduct,
  SubscribePlanForProductComplete,
  SubscribePlanForAdvanceCM,
  SubscribePlanForAdvanceCMComplete,
  UnsubscribePlan,
  UnsubscribePlanComplete,
} from './plan.actions'
import { PlanService } from './plan.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import { ActivePlanStatus, PlanSubscription } from '@tv3/store/plan/plan.model'
import * as R from 'ramda'
import { selectOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import * as moment from 'moment'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectActiveAddonPlanViews, selectActiveTokeetPlan } from '@tv3/store/plan/plan.selectors'
import { isFunction } from 'lodash'
import { Card } from '@tokeet-frontend/billing'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import { Subscription } from '@tokeet-frontend/subscriptions'

declare const window: any

@Injectable()
export class PlanEffects {
  @Effect()
  loadActivePlan$ = this.actions$.pipe(
    ofType(LoadActivePlans),
    concatMap(({ fresh }) => this.plans.subscriptions(fresh)),
    concatMap((subscriptions) => this.plans.status().pipe(map((status) => ({ ...subscriptions, status })))),
    switchMap((result) =>
      of(result).pipe(
        map(
          (res: {
            card: Card
            status: ActivePlanStatus
            subscriptions: PlanSubscription[]
            ended: PlanSubscription[]
          }) => {
            return {
              card: res.card,
              status: res.status,
              activePlans: R.reduce(
                (acc, p: PlanSubscription) => {
                  acc[p.product] = p
                  return acc
                },
                {},
                res.subscriptions
              ),
              endedPlans: R.reduce(
                (acc, p: PlanSubscription) => {
                  acc[p.product] = p
                  return acc
                },
                {},
                res.ended
              ),
            }
          }
        ),
        map(({ card, status, activePlans, endedPlans }) =>
          LoadActivePlansComplete({ card, activePlans, endedPlans, status })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect({ dispatch: false })
  loadActivePlansComplete$ = this.actions$.pipe(
    ofType(LoadActivePlansComplete),
    tap(({ card, activePlans, status }) => {
      const tokeetSubscription: PlanSubscription = activePlans.tokeet || ({} as PlanSubscription)
      const user = this.authService.user
      let epoch = moment().unix()
      let sub_status
      let re = /free/i
      if (tokeetSubscription.trialEnd && tokeetSubscription.trialEnd > epoch) {
        sub_status = 'trial'
      } else if (tokeetSubscription.until < epoch) {
        sub_status = 'inactive'
      } else if (tokeetSubscription.until >= epoch) {
        sub_status = 'active'
      } else if (re.exec(tokeetSubscription.planId)) {
        sub_status = 'free'
      }
      const now = moment().unix()
      const mainSubscription = R.find((s: Subscription) => s?.product === 'tokeet', user?.subscriptions || [])

      const payload = {
        user_id: user.id,
        account: user.account,
        email: user.primaryEmail,
        name: `${user.firstName} ${user.lastName}`,
        created_at: user.created,
        sub_name: tokeetSubscription.planName,
        sub_id: tokeetSubscription.planId,
        sub_fee: tokeetSubscription.price,
        sub_start_at: tokeetSubscription.start,
        sub_until_at: tokeetSubscription.until,
        sub_update_at: tokeetSubscription.timestamp,
        trial_end_at: tokeetSubscription.trialEnd,
        card_id: card ? card.id : '',
        sub_status,
        phone: user.phone,
        rentals: R.pathOr(0, ['rentals'], status),
        users: R.pathOr(0, ['users'], status),
        role: userRoleToString(user.roles),
        products: R.uniq(R.map((s) => s?.product, user?.subscriptions || [])).join(','),
        trial: mainSubscription?.trial_end > now,
      }
      if (isFunction(window.Intercom)) {
        window.Intercom('update', payload)
      }
    })
  )
  @Effect()
  loadTokeetPlans$ = this.actions$.pipe(
    ofType(LoadPlansForAdvanceCM),
    switchMap(() =>
      this.plans.plansForTokeet().pipe(
        map((plans) => LoadPlansForAdvanceCMComplete({ plans })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadProductPlans$ = this.actions$.pipe(
    ofType(LoadPlansForProduct),
    // maybe multiple plans are required
    mergeMap(({ product }) =>
      this.plans.plansForProduct(product).pipe(
        map((plans) => LoadPlansForProductComplete({ plans, product })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  subscribeTokeetPlan$ = this.actions$.pipe(
    ofType(SubscribePlanForAdvanceCM),
    switchMap(({ planId, planName }) =>
      this.plans.subscribeTokeetPlan(planId).pipe(
        switchMap((planSubscription) =>
          this.store.pipe(
            selectOnce(selectActiveTokeetPlan),
            map((plan) => {
              return planSubscription
            })
          )
        ),
        tap(() => this.toaster.success(`Successfully subscribed to ${planName}`)),
        map((planSubscription) => SubscribePlanForAdvanceCMComplete({ planSubscription })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  subscribeProductPlan$ = this.actions$.pipe(
    ofType(SubscribePlanForProduct),
    concatMap(({ planId, product, planName }) =>
      this.plans.subscribeProductPlan(planId, product).pipe(
        switchMap((planSubscription) =>
          this.store.pipe(
            selectOnce(selectActiveAddonPlanViews),
            map((plans) => {
              const plan = R.find((p) => p.product === product, plans)
              return planSubscription
            })
          )
        ),
        tap(() => this.toaster.success(`Successfully subscribed to ${planName}`)),
        map((planSubscription) => SubscribePlanForProductComplete({ planSubscription, product })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unsubscribePlan$ = this.actions$.pipe(
    ofType(UnsubscribePlan),
    switchMap(({ planId, product, planName }) => {
      return this.plans.unsubscribe(planId).pipe(
        tap(() => this.toaster.success(`Successfully unsubscribed from ${planName}`)),
        map((res) => UnsubscribePlanComplete({ planId, product, subscriptions: res })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private authService: AuthService,
    private plans: PlanService
  ) {}
}
