import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Store, select } from '@ngrx/store'
import { Channel, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'
import { selectConnectionById } from '@tv3/store/connection/connection.selectors'

@Component({
  selector: 'app-tiket-listing-wizard',
  templateUrl: './tiket-listing-wizard.component.html',
  styleUrls: ['./tiket-listing-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class TiketListingWizardComponent extends Destroyable implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper

  get channel() {
    return this.data.channel
  }

  get connection() {
    return this.data.connection
  }

  constructor(
    public dialogRef: MatDialogRef<TiketListingWizardComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel; connection: Connection }
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectConnectionById, { id: this.connection.id }), untilDestroy(this)).subscribe((conn) => {
      this.data.connection = conn
    })
  }

  close() {
    this.dialogRef.close()
  }

  onChannel() {
    this.dialogRef.close('channel')
  }
}
