import * as lodash from 'lodash'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router, UrlTree } from '@angular/router'
import { routes } from '@tv3/app-routing.module'
import { filter, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AppRoutesService {
  publicUrls = routes
    .filter((r) => lodash.get(r, 'data.public'))
    .map((r) => {
      return lodash.startsWith(r.path, '/') ? r.path : `/${r.path}`
    })

  isPublic$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((data: NavigationEnd) => this.isPublic(data.url))
  )
  isProtected$ = this.isPublic$.pipe(map((isPublic) => !isPublic))

  constructor(private router: Router) {}

  public isPublic(url?: string): boolean {
    url = url || this.router.url
    return !!this.publicUrls.find((pubUrl) => lodash.startsWith(url, pubUrl))
  }

  public isProtected(url?: string): boolean {
    return !this.isPublic(url || this.router.url)
  }
}
