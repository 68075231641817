import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Destroyable, Rental, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-edit-booking-preferences',
  templateUrl: './edit-booking-preferences-dialog.component.html',
  styleUrls: ['./edit-booking-preferences-dialog.component.scss'],
})
export class EditBookingPreferencesDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({})

  constructor(
    public dialogRef: MatDialogRef<EditBookingPreferencesDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {}
}
