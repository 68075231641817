import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NgxPermissionsModule } from 'ngx-permissions'
import { IsReadonlyRolePipe } from './pipes/is-readonly.pipe'
import { FormEditablePermissionDirective } from './forms/form-editable-permission.directive'

const exports = [IsReadonlyRolePipe, FormEditablePermissionDirective]

@NgModule({
  imports: [CommonModule, NgxPermissionsModule],
  declarations: [...exports],
  exports: [NgxPermissionsModule, ...exports],
})
export class PermissionModule {}
