import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { BookingChargesComponent } from '@tv3/containers/inquiries/overlays/inquiry-overlay/booking-charges/booking-charges.component'
import { BookingDetailsComponent } from '@tv3/containers/inquiries/overlays/inquiry-overlay/booking-details/booking-details.component'
import { BookBtnComponent } from './book-btn/book-btn.component'
import { SmartDevicesSharedModule } from '@tv3/containers/smart-devices/smart-devices-shared.module'

const components = [
  //
  BookingChargesComponent,
  BookingDetailsComponent,
  BookBtnComponent,
]

const pipes = []

const dialogs = []

const modules = [SharedModule, CommonModule, SmartDevicesSharedModule]

@NgModule({
  imports: [...modules],
  declarations: [...pipes, ...components, ...dialogs],
  exports: [...components, ...dialogs, ...pipes, ...modules],
  providers: [
    //
  ],
})
export class InquirySharedModule {}
