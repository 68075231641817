import { Injectable } from '@angular/core'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { ApiConnectionDialogComponent } from '@tv3/containers/channels/connection-detail/api/api-connection-dialog.component'
import { ComponentType } from '@angular/cdk/portal'
import { IcalConnectionDialogComponent } from '@tv3/containers/channels/connection-detail/ical/ical-connection-dialog.component'
import { ChannelNameTokens, DialogService } from '@tokeet-frontend/tv3-platform'
import { ConnectionStatusTexts, ConnectionView } from '@tv3/store/connection/connection.view'
import { AuthService } from '@tv3/services/auth.service'
import { PropertyContentHelperService } from '../channel-connect/property-content-helper.service'

import { GVRConnectionOverlayService } from './feed/gvr-connection-overlay/gvr-connection-overlay.service'
import { HoliduConnectionOverlayService } from './feed/holidu-connection-overlay/holidu-connection-overlay.service'
import { HomeAwayConnectionOverlayService } from './feed/homeaway-connection-overlay/homeaway-connection-overlay.service'
import { HomeToGoConnectionOverlayService } from './feed/hometogo-connection-overlay/hometogo-connection-overlay.service'
import { HvmiConnectionOverlayService } from './feed/hvmi-connection-overlay/hvmi-connection-overlay.service'
import { RentalsUnitedConnectionOverlayService } from './feed/ru-connection-overlay/ru-connection-overlay.service'
import { ChannelListingWizardsService } from '../channel-connect/listing-connect-wizards/index.service'

@Injectable({
  providedIn: SharedModule,
})
export class ConnectionDialogService {
  constructor(
    private dialog: DialogService,
    private auth: AuthService,

    private channelListingWizards: ChannelListingWizardsService,
    private propertyContentHelperService: PropertyContentHelperService,
    private homeToGoConnectionOverlayService: HomeToGoConnectionOverlayService,
    private homeAwayConnectionOverlayService: HomeAwayConnectionOverlayService,
    private hvmiConnectionOverlayService: HvmiConnectionOverlayService,
    private holiduConnectionOverlayService: HoliduConnectionOverlayService,
    private gvrConnectionOverlay: GVRConnectionOverlayService,
    private rentalsUnitedConnectionOverlay: RentalsUnitedConnectionOverlayService
  ) {}

  private open<T>(connectionId: string, component: ComponentType<T>): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { connectionId },
      panelClass: ['drawer-full-90'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  openSide(connection: ConnectionView, wizard = true) {
    const channel = connection.channel
    if (connection.isICal) {
      return this.open(connection.id, IcalConnectionDialogComponent)
    }

    if (connection.isCreatedOnTokeet && connection.isIncompleted) {
      this.propertyContentHelperService.openContentOverlay(channel, connection).subscribe()
      return
    }

    switch (channel.name) {
      case ChannelNameTokens.Hvmi:
        this.hvmiConnectionOverlayService.openSide(connection)
        return
      case ChannelNameTokens.Holidu:
        this.holiduConnectionOverlayService.openSide(connection)
        return
      case ChannelNameTokens.GoogleVacationRentals:
        this.gvrConnectionOverlay.openSide(connection)
        return
      case ChannelNameTokens.RentalsUnited:
        this.rentalsUnitedConnectionOverlay.openSide(connection)
        return
      case ChannelNameTokens.Homeaway:
        this.homeAwayConnectionOverlayService.openSide(connection).subscribe()
        return
      case ChannelNameTokens.HomeToGo:
        this.homeToGoConnectionOverlayService.openSide({ rental: connection.rental, channel, connection }).subscribe()
        return
      default:
        break
    }

    if (this.auth.isReadOnly()) {
      return this.open(connection.id, ApiConnectionDialogComponent)
    }
    if (connection.statusText === ConnectionStatusTexts.Active || !wizard) {
      return this.open(connection.id, ApiConnectionDialogComponent)
    }

    this.channelListingWizards.openWizard(connection.channel, connection)
  }
}
