<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput formControlName="license" placeholder="Rental License" />
      </mat-form-field2>
    </div>
    <div class="col-sm-6"></div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <textarea
          matInput
          formControlName="directions"
          placeholder="Enter Directions here"
          rows="4"
          required
        ></textarea>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <textarea matInput formControlName="rules" placeholder="Enter House Rules here" rows="4" required> </textarea>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <textarea matInput formControlName="localRecommendations" placeholder="Local Recommendations" rows="4">
        </textarea>
      </mat-form-field2>
    </div>
  </div>
</form>
