import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { switchMap, take } from 'rxjs/operators'
import { ChannelNameTokens, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ApiConnectionActivityLog, ApiConnectionActivityTypes } from '@tv3/store/connection/connection.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { ConnectionActivityLogDetailDialogService } from '@tv3/containers/channels/connection-detail/api/log/log-detail-dialog/connection-activity-log-detail-dialog.service'
import { FormBuilder } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import {
  ImportBookingsFromConnection,
  PushAvailabilityToConnectionComplete,
  PushRatesToConnectionComplete,
} from '@tv3/store/connection/connection.actions'
import { timer } from 'rxjs'
import { isFeedChannel } from '@tokeet-frontend/channels'

@Component({
  selector: 'app-api-connection-log',
  templateUrl: './api-connection-log.component.html',
  styleUrls: ['./api-connection-log.component.scss'],
})
export class ApiConnectionLogComponent extends Destroyable implements OnInit, OnChanges {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<ApiConnectionActivityLog>()
  displayedColumns = ['created', 'status', 'type', 'message']

  @Input() availabilityPull: boolean = true
  @Input() channel: ChannelNameTokens
  @Input() dateFormat: string = 'DD-MMM-YYYY | h:mm A'
  @Input() activityLogs: ApiConnectionActivityLog[] = []
  @Output() refresh = new EventEmitter<any>()

  actionTypes = ApiConnectionActivityTypes
  filters = this.fb.group({
    status: [''],
    action: [''],
  })
  statusFilter = ''

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private activityLogDetailDialog: ConnectionActivityLogDetailDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe((filters) => {
      this.onFiltersChanged(filters)
    })

    this.actions
      .pipe(
        ofType(PushAvailabilityToConnectionComplete, ImportBookingsFromConnection, PushRatesToConnectionComplete),
        switchMap(() => timer(1000, 3000).pipe(take(5))),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.refresh.next()
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activityLogs']) {
      this.onFiltersChanged(this.filters.getRawValue())
    }
  }

  onFiltersChanged(filters) {
    // @ts-ignore
    this.dataSource.data = R.pipe(
      R.filter((log: ApiConnectionActivityLog) => {
        return !filters.status || log.status === filters.status
      }),
      R.filter((log: ApiConnectionActivityLog) => {
        return !filters.action || log.type === filters.action
      })
    )(this.activityLogs || [])
  }

  isFeedChannel() {
    return isFeedChannel(this.channel)
  }

  onShowLogDetail(log: ApiConnectionActivityLog) {
    this.activityLogDetailDialog.open(log)
  }

  onRefreshLog() {
    this.refresh.emit(true)
  }
}
