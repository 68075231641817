import { gatewayTypeEntityAdapter, GatewayTypeState, initialGatewayTypeState } from './gateway-type.state'
import { LoadPaymentGatewayTypesComplete } from './gateway-type.actions'
import { Action, createReducer, on } from '@ngrx/store'

const reducer = createReducer(
  initialGatewayTypeState,
  on(LoadPaymentGatewayTypesComplete, (state, { types }) =>
    gatewayTypeEntityAdapter.addAll(types, {
      ...state,
      isLoaded: true,
    })
  )
)

export function gatewayTypeReducer(state: GatewayTypeState | undefined, action: Action) {
  return reducer(state, action)
}

export const {
  selectIds: selectGatewayTypeIds,
  selectEntities: selectGatewayTypeEntities,
  selectAll: selectGatewayTypeAll,
  selectTotal: selectGatewayTypeTotal,
} = gatewayTypeEntityAdapter.getSelectors()
