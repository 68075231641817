import { Pipe, PipeTransform } from '@angular/core'
import { Channel, ChannelNameTokens } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'canAddChannelAccount',
})
export class CanAddChannelAccountPipe implements PipeTransform {
  transform(channel: Channel) {
    const tokens = [
      ChannelNameTokens.BookingAPI,
      ChannelNameTokens.ExpediaAPI,
      ChannelNameTokens.AirBnBAPI,
      ChannelNameTokens.AirBnBV2API,
      ChannelNameTokens.AgodaAPI,
      ChannelNameTokens.Tiket,
      ChannelNameTokens.Trip,
    ]
    return tokens.indexOf(channel.name) > -1
  }
}
