import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDialogService, Rental, selectRentalById, Toaster } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { AibrnbListingDetails, ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { Connection, getRealChannelId } from '@tv3/store/connection/connection.model'
import { selectChannelById } from '@tokeet-frontend/tv3-platform'
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ActionFailed, integer } from '@tokeet-frontend/tv3-platform'
import {
  AirBnBPriceSettingsResponse,
  AirBnBUpdatePriceSettingsPayload,
  AirbnbPricingStandardFee,
} from '@tv3/store/connection/connection.types'
import { combineLatest } from 'rxjs'
import {
  AirbnbPassThroughOccupancyTax,
  AirbnbPricingRule,
  AirbnbPricingRuleType,
  AirbnbStandardFeeType,
  airbnbCurrencies,
  airbnbMonthlyPricingRuleDays,
  airbnbOccupancyTaxAmountTypes,
  airbnbOccupancyTaxBase,
  airbnbOccupancyTaxCollectionType,
  airbnbOccupancyTaxTypes,
  airbnbOccupancyTaxesAttestation,
  airbnbWeeklyPricingRuleDays,
} from '@tokeet-frontend/channels'
import { Observable } from 'rxjs'

function requiredDep(ctrl: AbstractControl, depCtrl: AbstractControl, isRequired: (value: any) => boolean) {
  depCtrl.valueChanges
    .pipe(
      startWith(depCtrl.value),
      filter((v) => !lodash.isNil(v))
    )
    .subscribe((v) => {
      if (isRequired(v)) {
        ctrl.setValidators([Validators.required])
      } else {
        ctrl.clearValidators()
      }
      ctrl.updateValueAndValidity()
      ctrl.markAsTouched()
    })
}

function percentFlatDep(ctrl: AbstractControl, depCtrl: AbstractControl, isPercent: (value: any) => boolean) {
  depCtrl.valueChanges
    .pipe(
      startWith(depCtrl.value),
      filter((v) => !lodash.isNil(v))
    )
    .subscribe((v) => {
      if (isPercent(v)) {
        ctrl.setValidators([Validators.min(0), Validators.max(100)])
      } else {
        ctrl.setValidators([Validators.min(0)])
      }
      ctrl.updateValueAndValidity()
      ctrl.markAsTouched()
    })
}

// #region Airbnb Standard fee
function createStandardFeesForm(fb: FormBuilder) {
  const feeTypes = Object.values(AirbnbStandardFeeType)
  const feesForm = fb.group({})
  feeTypes.forEach((feeType) => {
    const form = fb.group({
      fee_type: [feeType], // hidden
      amount_type: ['flat'], // hidden
      amount: [],
      charge_type: ['PER_GROUP'], // hidden
    })
    percentFlatDep(form.get('amount'), form.get('amount_type'), (v) => v === 'percent')
    feesForm.addControl(feeType, form)
  })

  return feesForm
}

function setStandardFeesForm(standardFeesForm: FormGroup, standardFees: AirbnbPricingStandardFee[]) {
  lodash.forEach(standardFees, (fee) => {
    const feeForm = standardFeesForm.get(fee.fee_type) as FormGroup
    if (!feeForm) return
    feeForm.patchValue(fromAirbnbStandardFee(fee))
  })
}

function fromAirbnbDiscountFactor(factor: number) {
  return !lodash.isNumber(factor) ? null : 100 - factor * 100
}

function toAirbnbDiscountFactor(percent: number) {
  return !lodash.isNumber(percent) ? null : parseFloat((1 - percent / 100).toFixed(2))
}

function fromAirbnbStandardFee(fee: AirbnbPricingStandardFee): AirbnbPricingStandardFee {
  return {
    ...fee,
    amount: fee.amount_type === 'flat' ? fee.amount / 1000000 : fee.amount,
  }
}

function toAirbnbStandardFee(fee: AirbnbPricingStandardFee): AirbnbPricingStandardFee {
  return {
    ...fee,
    amount: fee.amount_type === 'flat' ? fee.amount * 1000000 : fee.amount,
  }
}
// #endregion

// #region Airbnb Pricing rule

function createPricingRuleForm(fb: FormBuilder, ruleType: AirbnbPricingRuleType, rule?: AirbnbPricingRule) {
  const getThresholdValidators = (ruleType: AirbnbPricingRuleType) => {
    switch (ruleType) {
      case AirbnbPricingRuleType.lastMinutes:
        return [Validators.min(1), Validators.max(28)]
      case AirbnbPricingRuleType.earlyBird:
        return [Validators.min(1), Validators.max(36)]
      default:
        return []
    }
  }

  const form = fb.group({
    rule_type: [ruleType], // hidden
    threshold_one: [undefined, getThresholdValidators(ruleType)],
    price_change: [undefined, [Validators.min(0), Validators.max(100)]],
    price_change_type: ['PERCENT'], // hidden
  })

  if (rule) {
    form.patchValue(fromAirbnbPricingRule(rule))
  }

  return form
}

function createInitPricingRulesForm(fb: FormBuilder) {
  const ruleTypes = lodash.values(AirbnbPricingRuleType)
  const rulesForm = fb.group({})

  lodash.forEach(ruleTypes, (ruleType: AirbnbPricingRuleType) => {
    const array = fb.array([createPricingRuleForm(fb, ruleType)])
    rulesForm.addControl(ruleType, array)
  })

  return rulesForm
}

function setPricingRulesForm(fb: FormBuilder, rulesForm: FormGroup, rules: AirbnbPricingRule[]) {
  // filter out monthly/weekly discount.
  // rules = lodash.filter(
  //   rules,
  //   (r) => {
  //     if (r.rule_type !== AirbnbPricingRuleType.longTerm) return true
  //     return r.threshold_one !== airbnbMonthlyPricingRuleDays && r.threshold_one !== airbnbWeeklyPricingRuleDays
  //   }
  // )

  const rulesByType = lodash.groupBy(rules, (r) => r.rule_type)
  const ruleTypes = lodash.values(AirbnbPricingRuleType)

  lodash.forEach(rulesByType, (items: AirbnbPricingRule[], ruleType: AirbnbPricingRuleType) => {
    if (!ruleTypes.includes(ruleType)) return

    const array = fb.array([])
    items.forEach((item) => array.push(createPricingRuleForm(fb, item.rule_type, item)))
    rulesForm.setControl(ruleType, array)
  })

  return rulesForm
}

function fromAirbnbPricingRule(rule: AirbnbPricingRule): AirbnbPricingRule {
  let threshold_one = rule.threshold_one
  if (rule.rule_type === AirbnbPricingRuleType.earlyBird) {
    threshold_one = Math.round(threshold_one / 30) // month
  }
  return {
    ...rule,
    threshold_one,
    price_change: -rule.price_change,
  }
}

function toAirbnbPricingRule(rule: AirbnbPricingRule): AirbnbPricingRule {
  let threshold_one = rule.threshold_one
  if (rule.rule_type === AirbnbPricingRuleType.earlyBird) {
    threshold_one = threshold_one * 30 // month
  }
  return {
    ...rule,
    threshold_one,
    price_change: -rule.price_change,
  }
}
// #endregion

// #region Airbnb Occupancy Tax
function createAirbnbOccupancyTaxForm(fb: FormBuilder, item?: AirbnbPassThroughOccupancyTax) {
  const form = fb.group({
    tax_type: [item?.tax_type, [Validators.required]],
    amount_type: [item?.amount_type, [Validators.required]],
    amount: [item?.amount, [Validators.required]],
    taxable_base: [item?.taxable_base || []],
    attestation: [true],

    business_tax_id: [item?.business_tax_id, [Validators.required]],
    no_business_tax_id_declaration: [item?.no_business_tax_id_declaration],

    tot_registration_id: [item?.tot_registration_id, [Validators.required]],
    no_tot_registration_id_declaration: [item?.no_tot_registration_id_declaration],

    long_term_stay_exemption: [item?.long_term_stay_exemption, [Validators.min(0), integer()]],
    only_first_nights_exemption: [item?.only_first_nights_exemption, [Validators.min(0), integer()]],
    max_cap_per_person_per_night: [item?.max_cap_per_person_per_night, [Validators.min(0), integer()]],
  })

  percentFlatDep(form.get('amount'), form.get('amount_type'), (v) => v === 'percent_per_reservation')
  requiredDep(form.get('taxable_base'), form.get('amount_type'), (v) => v === 'percent_per_reservation')
  requiredDep(form.get('business_tax_id'), form.get('no_business_tax_id_declaration'), (v) => !v)
  // requiredDep(form.get('tot_registration_id'), form.get('no_tot_registration_id_declaration'), (v) => !v)

  return form
}

function createAirbnbOccupancyTaxesForm(
  fb: FormBuilder,
  taxesForm: FormArray,
  occupancyTaxes: AirbnbPassThroughOccupancyTax[]
) {
  taxesForm.clear()
  lodash
    .map(occupancyTaxes, (item) => createAirbnbOccupancyTaxForm(fb, item))
    .forEach((item) => {
      taxesForm.push(item)
    })
}

function toAirbnbOccupancyTaxes(occupancyTaxes: AirbnbPassThroughOccupancyTax[]): AirbnbPassThroughOccupancyTax[] {
  return lodash.map(occupancyTaxes, (tax) => {
    if (lodash.isEmpty(tax.taxable_base)) {
      tax.taxable_base = ['base_price']
    }
    return tax
  })
}

// #endregion

@Component({
  selector: 'app-airbnb-update-price-settings-dialog',
  templateUrl: './airbnb-update-price-settings-dialog.component.html',
  styleUrls: ['./airbnb-update-price-settings-dialog.component.scss'],
})
export class AirbnbUpdatePriceSettingsDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    default_daily_price: [undefined],
    listing_currency: [undefined, [Validators.required]],
    weekly_price_factor: [undefined],
    monthly_price_factor: [undefined],
    weekend_price: [undefined],
    guests_included: [undefined],
    price_per_extra_person: [undefined],

    standard_fees: createStandardFeesForm(this.fb),
    default_pricing_rules: createInitPricingRulesForm(this.fb),

    pass_through_taxes: this.fb.array([]),
  })

  get standardFeesForm() {
    return this.form?.get('standard_fees') as FormGroup
  }
  get pricingRulesForm() {
    return this.form?.get('default_pricing_rules') as FormGroup
  }

  get occupancyTaxesForm() {
    return this.form?.get('pass_through_taxes') as FormArray
  }

  standardFeeTypes = AirbnbStandardFeeType
  pricingRuleTypes = AirbnbPricingRuleType

  airbnbOccupancyTaxesTypes = airbnbOccupancyTaxTypes
  airbnbOccupancyTaxAmountTypes = airbnbOccupancyTaxAmountTypes
  airbnbOccupancyTaxBase = airbnbOccupancyTaxBase
  airbnbOccupancyTaxesAttestation = airbnbOccupancyTaxesAttestation
  occupancyTaxNotice: string

  rental: Rental

  currencyOptions = airbnbCurrencies
  currency = 'USD'
  isPetsFeeAllowed = false

  constructor(
    public dialogRef: MatDialogRef<AirbnbUpdatePriceSettingsDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private connectionAirbnbService: ConnectionAirbnbService,
    private confirm: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { connection: Connection }
  ) {
    super()
  }

  ngOnInit() {
    const connection = this.data.connection

    this.store.pipe(select(selectRentalById(connection.rentalId)), untilDestroy(this)).subscribe((rental) => {
      this.rental = rental
    })

    combineLatest([
      this.store.pipe(
        select(selectChannelById, { id: getRealChannelId(this.data.connection.channelId) }),
        switchMap((channel) =>
          this.connectionAirbnbService.getPriceSettingsV2(
            this.data.connection.channelId,
            // @ts-ignore
            this.data.connection.propertyId,
            this.data.connection.roomId
          )
        ),
        take(1)
      ),
      this.connectionAirbnbService.getListingDetailsV2(connection.propertyId, connection.roomId),
    ]).subscribe(([pricing_setting, details]: [AirBnBPriceSettingsResponse, AibrnbListingDetails]) => {
      setStandardFeesForm(this.standardFeesForm, pricing_setting.standard_fees)
      setPricingRulesForm(this.fb, this.pricingRulesForm, pricing_setting.default_pricing_rules)
      createAirbnbOccupancyTaxesForm(this.fb, this.occupancyTaxesForm, pricing_setting.pass_through_taxes)
      this.occupancyTaxNotice = lodash.find(
        airbnbOccupancyTaxCollectionType,
        (t) => t.id === pricing_setting.pass_through_taxes_collection_type
      )?.description

      this.isPetsFeeAllowed = !!details.pets_allowed
      if (!this.isPetsFeeAllowed) {
        this.standardFeesForm.get(AirbnbStandardFeeType.petFee).disable()
      }

      this.form.patchValue({
        default_daily_price: pricing_setting.default_daily_price,
        weekend_price: pricing_setting.weekend_price,
        weekly_price_factor: fromAirbnbDiscountFactor(pricing_setting.weekly_price_factor),
        monthly_price_factor: fromAirbnbDiscountFactor(pricing_setting.monthly_price_factor),
        guests_included: pricing_setting.guests_included,
        price_per_extra_person: pricing_setting.price_per_extra_person,
        listing_currency: pricing_setting.listing_currency,
      })
    })

    this.form
      .get('listing_currency')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((c) => {
        this.currency = c
      })
  }

  addPricingRule(ruleType: AirbnbPricingRuleType) {
    const array = this.pricingRulesForm.get(ruleType) as FormArray
    array.push(createPricingRuleForm(this.fb, ruleType))
  }

  removePricingRule(ruleType: AirbnbPricingRuleType, index: number) {
    const array = this.pricingRulesForm.get(ruleType) as FormArray
    array.removeAt(index)
  }

  addTax() {
    this.occupancyTaxesForm.push(createAirbnbOccupancyTaxForm(this.fb))
  }

  removeTax(index: number) {
    this.occupancyTaxesForm.removeAt(index)
  }

  close() {
    this.dialogRef.close()
  }
  onSave() {
    this.save(this.form)
    if (this.form.invalid) {
      this.toaster.warning('Please make sure all fields are filled correctly.', `Unable to save settings`)
    }
  }

  @SaveForm()
  save(form: FormGroup) {
    const {
      default_daily_price,
      weekend_price,
      weekly_price_factor,
      monthly_price_factor,
      // cleaning_fee,
      // security_deposit,
      guests_included,
      price_per_extra_person,
      listing_currency,
      standard_fees: standardFees,
      default_pricing_rules: pricingRules,
      pass_through_taxes: occupancyTaxes,
    } = this.form.getRawValue()

    const standard_fees = lodash
      .values(standardFees)
      .filter((fee: AirbnbPricingStandardFee) =>
        !this.isPetsFeeAllowed ? fee.fee_type !== AirbnbStandardFeeType.petFee : true
      )
      .filter((fee) => lodash.isNumber(fee.amount))
      .map((fee) => ({
        ...toAirbnbStandardFee(fee),
        offline: false,
      }))

    const default_pricing_rules = lodash
      .flatten(lodash.values(pricingRules))
      .filter((rule: AirbnbPricingRule) => lodash.isNumber(rule.price_change) && lodash.isNumber(rule.threshold_one))
      .map((fee) => toAirbnbPricingRule(fee))

    const payload: AirBnBUpdatePriceSettingsPayload = {
      channelId: this.data.connection.channelId,
      // @ts-ignore
      propertyId: this.data.connection.propertyId,
      // @ts-ignore
      roomid: this.data.connection.roomId,
      // @ts-ignore
      listing_id: this.data.connection.roomId,
      default_daily_price,
      guests_included,
      monthly_price_factor: toAirbnbDiscountFactor(monthly_price_factor),
      weekly_price_factor: toAirbnbDiscountFactor(weekly_price_factor),
      price_per_extra_person,
      weekend_price,
      listing_currency,
      standard_fees,
      default_pricing_rules,
      pass_through_taxes: toAirbnbOccupancyTaxes(occupancyTaxes),
    }

    let saveData$: Observable<any>

    if (!lodash.isEmpty(occupancyTaxes)) {
      saveData$ = this.confirm
        .confirmExtra({
          title: 'Terms for adding taxes',
          body: `I confirm this tax amount is correct
        and I will pay this tax once remitted to me by Airbnb.
        I grant Airbnb permission to disclose tax-related and transaction
        information (such as listing address, tax amount, and Tax ID) to
        the relevant tax authorities.`,
          extra: `I Agree to terms for adding taxes`,
          confirmText: 'Save',
          cancelText: 'Cancel',
        })
        .pipe(
          tap(({ isChecked }) => {
            if (!isChecked) {
              this.toaster.warning('Please agree to the terms for adding taxes.')
            }
          }),
          filter(({ isChecked }) => isChecked),
          switchMap(() => this.connectionAirbnbService.updatePriceSettingsV2(payload))
        )
    } else {
      saveData$ = this.connectionAirbnbService.updatePriceSettingsV2(payload)
    }
    saveData$.subscribe(
      () => {
        this.toaster.success('Price settings updated successfully.')
        this.dialogRef.close()
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }
}
