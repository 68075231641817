import { createAction, props } from '@ngrx/store'
import {
  HomeAwayChannelConfig,
  HomeAwayConfigForm,
  HomeAwayRentalConfig,
  HomeAwayRentalConfigView,
  HomeToGoChannelConfig,
  HomeToGoConnectionForm,
  HomeToGoRentalConfig,
  HomeToGoRentalConfigView,
  UpdateFeedConfig,
} from './channels.model'
import { Channel, ChannelNameTokens, Rental } from '@tokeet-frontend/tv3-platform'

//region Submit HomeToGoForm
export const SubmitForm = createAction(
  '[FeedChannels] Submit HomeToGo Form',
  props<{
    form: HomeToGoConnectionForm
    payload: UpdateFeedConfig
    channel: Channel
  }>()
)
export const SubmitFormSuccess = createAction(
  '[FeedChannels] Submit HomeToGo Form Success',
  props<{ config: HomeToGoChannelConfig; name: ChannelNameTokens }>()
)
export const SubmitFormFailure = createAction('[FeedChannels] Submit HomeToGo Form Failure')
//endregion

//region Load Channel Config
export const LoadChannelConfig = createAction(
  '[FeedChannels] Load Channel Config',
  props<{ name: ChannelNameTokens }>()
)
export const LoadChannelConfigSuccess = createAction(
  '[FeedChannels] Load Channel Config Success',
  props<{ config: HomeToGoChannelConfig; name: ChannelNameTokens }>()
)
export const LoadChannelConfigFailure = createAction(
  '[FeedChannels] Load Channel Config Failure',
  props<{ err: Error }>()
)
//endregion

//region Create HomeAway Channel Config
export const CreateHomeAwayChannelConfig = createAction(
  '[FeedChannels] Create Vrbo Channel Config',
  props<{ payload: HomeAwayConfigForm; channel: Channel }>()
)
export const CreateHomeAwayChannelConfigSuccess = createAction(
  '[FeedChannels] Create Vrbo Channel Config Success',
  props<{ config: HomeAwayChannelConfig; name: ChannelNameTokens }>()
)
export const CreateHomeAwayChannelConfigFailure = createAction(
  '[FeedChannels] Create Vrbo Channel Config Failure',
  props<{ err: Error }>()
)

//endregion

//region Update HomeAway Channel Config
export const UpdateHomeAwayChannelConfig = createAction(
  '[FeedChannels] Update Vrbo Channel Config',
  props<{ payload: HomeAwayConfigForm; channel: Channel }>()
)
export const UpdateHomeAwayChannelConfigSuccess = createAction(
  '[FeedChannels] Update Vrbo Channel Config Success',
  props<{ config: HomeAwayChannelConfig; name: ChannelNameTokens }>()
)
export const UpdateHomeAwayChannelConfigFailure = createAction(
  '[FeedChannels] Update Vrbo Channel Config Failure',
  props<{ err: Error }>()
)
//endregion

//region Update Channel Config
export const UpdateChannelConfig = createAction(
  '[FeedChannels] Update Channel Config',
  props<{ payload: UpdateFeedConfig | any; channel: Channel }>() // TODO - add type for homeaway
)
export const UpdateChannelConfigSuccess = createAction(
  '[FeedChannels] Update Channel Config Success',
  props<{ config: HomeToGoChannelConfig; channel: Channel }>()
)
export const UpdateChannelConfigFailure = createAction(
  '[FeedChannels] Update Channel Config Failure',
  props<{ err: Error }>()
)
//endregion

export const PauseHomeAwayChannelConfig = createAction('[FeedChannels] Pause Vrbo Channel Config')
export const PauseHomeAwayChannelConfigSuccess = createAction('[FeedChannels] Pause Vrbo Channel Config Success')

export const ResumeHomeAwayChannelConfig = createAction('[FeedChannels] Resume Vrbo Channel Config')
export const ResumeHomeAwayChannelConfigSuccess = createAction('[FeedChannels] Resume Vrbo Channel Config Success')

//region Update Rental Config
export const UpdateRentalConfig = createAction(
  '[FeedChannels] Update Rental Config',
  props<{ payload: UpdateFeedConfig; channel: Channel; rental: Rental }>()
)
export const UpdateRentalConfigSuccess = createAction(
  '[FeedChannels] Update Rental Config Success',
  props<{ config: HomeToGoRentalConfig | HomeAwayRentalConfig; channel: Channel; rental: Rental }>()
)
export const UpdateRentalConfigFailure = createAction(
  '[FeedChannels] Update Rental Config Failure',
  props<{ err: Error }>()
)
//endregion

//region Create Rental Config
export const CreateRentalConfig = createAction(
  '[FeedChannels] Create Rental Config',
  props<{ payload: UpdateFeedConfig; channel: Channel; rental: Rental }>()
)
export const CreateRentalConfigSuccess = createAction(
  '[FeedChannels] Create Rental Config Success',
  props<{ config: HomeToGoRentalConfig | HomeAwayRentalConfig; channel: Channel; rental: Rental }>()
)
export const CreateRentalConfigFailure = createAction(
  '[FeedChannels] Create Rental Config Failure',
  props<{ err: Error }>()
)
//endregion

//region Load Rental Configs
export const LoadRentalConfigs = createAction(
  '[FeedChannels] Load Rental Configs',
  props<{ channel: ChannelNameTokens }>()
)
export const LoadRentalConfigsSuccess = createAction(
  '[FeedChannels] Load Rental Configs Success',
  props<{ configs: (HomeToGoRentalConfig | HomeAwayRentalConfig)[]; channel: ChannelNameTokens }>()
)
export const LoadRentalConfigsFailure = createAction(
  '[FeedChannels] Load Rental Configs Failure',
  props<{ err: Error }>()
)
//endregion

//region Pause Rental Config
export const PauseRentalConfig = createAction(
  '[FeedChannels] Pause Rental Config',
  props<{ config: HomeToGoRentalConfigView | HomeAwayRentalConfigView; channel: ChannelNameTokens }>()
)
export const PauseRentalConfigSuccess = createAction(
  '[FeedChannels] Pause Rental Config Success',
  props<{ config: HomeToGoRentalConfig | HomeAwayRentalConfig; channel: ChannelNameTokens }>()
)
//endregion

//region Resume HomeToGo|HomeAway Rental Config
export const ResumeHomeToGoRentalConfig = createAction(
  '[FeedChannels] Resume HomeToGo Rental Config',
  props<{ config: HomeToGoRentalConfigView; channel: ChannelNameTokens }>()
)
export const ResumeHomeAwayRentalConfig = createAction(
  '[FeedChannels] Resume HomeAway Rental Config',
  props<{ config: HomeAwayRentalConfigView; channel: ChannelNameTokens }>()
)
export const ResumeRentalConfigSuccess = createAction(
  '[FeedChannels] Resume Rental Config Success',
  props<{ config: HomeToGoRentalConfig | HomeAwayRentalConfig; channel: ChannelNameTokens }>()
)
export const ResumeRentalConfigFailure = createAction(
  '[FeedChannels] Resume Rental Config Failure',
  props<{ err: Error }>()
)
//endregion

export const EnableAllFeedRentals = createAction(
  '[FeedChannels] Enable All Feed Rentals',
  props<{ channel: ChannelNameTokens }>()
)
export const EnableAllFeedRentalsSuccess = createAction(
  '[FeedChannels] Enable All Feed Rentals Success',
  props<{ channel: ChannelNameTokens; configs: (HomeToGoRentalConfig | HomeAwayRentalConfig)[] }>()
)
export const EnableAllFeedRentalsFailure = createAction(
  '[FeedChannels] Enable All Feed Rentals Failure',
  props<{ err: Error }>()
)

export const DisableAllFeedRentals = createAction(
  '[FeedChannels] Disable All Feed Rentals',
  props<{ channel: ChannelNameTokens }>()
)
export const DisableAllFeedRentalsSuccess = createAction(
  '[FeedChannels] Disable All Feed Rentals Success',
  props<{ channel: ChannelNameTokens; configs: (HomeToGoRentalConfig | HomeAwayRentalConfig)[] }>()
)
export const DisableAllFeedRentalsFailure = createAction(
  '[FeedChannels] Disable All Feed Rentals Failure',
  props<{ err: Error }>()
)

export const EnableFeedRentals = createAction(
  '[FeedChannels] Enable Feed Rentals',
  props<{ channel: ChannelNameTokens; rentalIds: string[] }>()
)
export const EnableFeedRentalsSuccess = createAction(
  '[FeedChannels] Enable Feed Rentals Success',
  props<{ channel: ChannelNameTokens; configs: (HomeToGoRentalConfig | HomeAwayRentalConfig)[] }>()
)
export const EnableFeedRentalsFailure = createAction(
  '[FeedChannels] Enable Feed Rentals Failure',
  props<{ err: Error }>()
)

export const DisableFeedRentals = createAction(
  '[FeedChannels] Disable Feed Rentals',
  props<{ channel: ChannelNameTokens; rentalIds: string[] }>()
)
export const DisableFeedRentalsSuccess = createAction(
  '[FeedChannels] Disable Feed Rentals Success',
  props<{ channel: ChannelNameTokens; configs: (HomeToGoRentalConfig | HomeAwayRentalConfig)[] }>()
)
export const DisableFeedRentalsFailure = createAction(
  '[FeedChannels] Disable Feed Rentals Failure',
  props<{ err: Error }>()
)
