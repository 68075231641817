<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-phone"></i> Enter Phone Number</h5>
</div>
<div class="modal-body">
  <div class="mb-3">
    <div class="">Your account info is incomplete. Please enter your phone number(s) to proceed.</div>
  </div>
  <form [formGroup]="form">
    <app-national-phone-input2
      [ctrl]="form.get('accountPhone')"
      phoneLabel="Account Phone Number"
      [required]="true"
    ></app-national-phone-input2>

    <app-national-phone-input2
      [ctrl]="form.get('userPhone')"
      phoneLabel="Your Phone Number"
      [required]="true"
    ></app-national-phone-input2>
  </form>
</div>
<div class="modal-footer">
  <button (click)="save(form)" type="button" [disabled]="isValidating" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
