import { Pipe, PipeTransform } from '@angular/core'
import { truncate } from 'lodash'

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return truncate(value || '', {
      length: args,
    })
  }
}
