<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header btn-holder tab-nav-holder">
      <div class="col-sm-12 nav-tab-holder">
        <ul class="nav nav-tabs">
          <li>
            <a (click)="tab.next(tabs.Connection)" [class.active]="tab.value === tabs.Connection">
              Connection Details
            </a>
          </li>
          <li>
            <a (click)="tab.next(tabs.Config)" [class.active]="tab.value === tabs.Config"> Connection Config </a>
          </li>
        </ul>
        <div class="actions-holder">
          <img [src]="channel.logoColor" [alt]="channel.friendlyName" />
        </div>
      </div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div *ngIf="tab.value === tabs.Connection">
        <app-feed-connection-details
          (rentalOverlay)="onRental($event)"
          [channel]="channel"
          [config]="config"
          (toggle)="onToggleStatus($event)"
        ></app-feed-connection-details>
      </div>
      <div *ngIf="tab.value === tabs.Config">
        <app-feed-connection-config [channel]="channel" [config]="config"></app-feed-connection-config>
      </div>
    </div>
  </div>
</div>
