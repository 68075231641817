export const AIToneOptions = [
  {
    id: 'business' as const,
    label: 'Business',
    example: `E.g. Welcome! Questions about your stay? We're here to help`,
  },
  {
    id: 'casual' as const,
    label: 'Casual',
    example: `E.g. Hey there! Got questions about rooms? Shoot!`,
  },
  {
    id: 'playful' as const,
    label: 'Playful',
    example: `E.g. Hey hey! Curious about our digs? Let's chat!`,
  },
  {
    id: 'thoughtful' as const,
    label: 'Thoughtful',
    example: `E.g. Welcome! Need room info? Just ask, we've got you covered!`,
  },
]

export interface AiResponderDoc {
  id: string
  filename: string
  bytes: number
  preBooking: boolean
  postBooking: boolean
  postCheckin: boolean
}

export const aiResponderRentalDataSharingItems = [
  { key: 'shareRentalName' as const, label: 'Rental Name', group: 'basic' as const },
  { key: 'shareDisplayName' as const, label: 'Display Name', group: 'basic' as const },
  { key: 'shareCurrency' as const, label: 'Currency', group: 'basic' as const },
  { key: 'shareEmail' as const, label: 'Rental Email Address', group: 'basic' as const },
  { key: 'shareAddress' as const, label: 'Address', group: 'basic' as const },
  { key: 'sharePostalCode' as const, label: 'Postal Code', group: 'basic' as const },
  { key: 'sharePhone' as const, label: 'Phone', group: 'basic' as const },
  { key: 'shareRegNumber' as const, label: 'Registration Number', group: 'basic' as const },
  { key: 'shareGps' as const, label: 'GPS Location', group: 'basic' as const },

  { key: 'shareType' as const, label: 'Rental Type', group: 'details' as const },
  { key: 'shareCheckInType' as const, label: 'Checkin Type', group: 'details' as const },
  { key: 'shareBedroomCount' as const, label: 'Bedroom Count', group: 'details' as const },
  { key: 'shareBathroomCount' as const, label: 'Bathroom Count', group: 'details' as const },
  { key: 'shareSize' as const, label: 'Rental Size', group: 'details' as const },
  { key: 'shareMinGuestCount' as const, label: 'Min Guest Count', group: 'details' as const },
  { key: 'shareMaxGuestCount' as const, label: 'Max Guest Count', group: 'details' as const },
  { key: 'shareDescription' as const, label: 'Description of Property', group: 'details' as const },
  { key: 'shareAmenities' as const, label: 'Amenities Details', group: 'details' as const },
  { key: 'shareTravellerAge' as const, label: 'Traveler Age', group: 'details' as const },

  { key: 'shareCheckinTime' as const, label: 'Checkin time', group: 'instructions' as const },
  { key: 'shareCheckoutTime' as const, label: 'Checkout time', group: 'instructions' as const },
  { key: 'shareWifi' as const, label: 'Wifi Password', group: 'instructions' as const },
  { key: 'shareSecurityCode' as const, label: 'Security Code', group: 'instructions' as const },
  { key: 'shareKeyPickup' as const, label: 'Key Pickup', group: 'instructions' as const },
  { key: 'shareCheckinInstructions' as const, label: 'Checkin Instructions', group: 'instructions' as const },
  { key: 'shareCheckoutInstructions' as const, label: 'Checkout Instructions', group: 'instructions' as const },
  { key: 'shareDirections' as const, label: 'Directions', group: 'instructions' as const },
  { key: 'shareHouseRules' as const, label: 'House Rules', group: 'instructions' as const },
  { key: 'shareSpecialInstructions' as const, label: 'Special Instructions', group: 'instructions' as const },
  { key: 'sharePaymentTerms' as const, label: 'Payment Terms', group: 'instructions' as const },
  { key: 'sharePaymentInstructions' as const, label: 'Payment Instructions', group: 'instructions' as const },
  { key: 'shareCustomInformations' as const, label: 'Custom Informations', group: 'instructions' as const },
]

type AiResponderRentalDataSharingKey = typeof aiResponderRentalDataSharingItems[number]['key']

export interface AIResponderRentalDataSharingItem {
  key: AiResponderRentalDataSharingKey
  label: string
  group: 'basic' | 'details' | 'instructions'
}
export interface AiResponderRentalDataSharingItem {
  isEnabled: boolean
  preBooking: boolean
  postBooking: boolean
  postCheckin: boolean
}

export type AiResponderRentalDataSharing = {
  [key in AiResponderRentalDataSharingKey]: AiResponderRentalDataSharingItem
}

export interface AiResponderContentSharingItem {
  content: string
  preBooking: boolean
  postBooking: boolean
  postCheckin: boolean
}

export type AiResponderSettings = AiResponderRentalDataSharing & { [key: string]: any } & {
  isEnabled: boolean
  isAutomateSendReplies: boolean
  isWebsitesEnabled: boolean
  isDocumentsEnabled: boolean
  isFaqEnabled: boolean

  isContractSigned?: boolean
  contractId?: string

  urls: string[]
  docs?: AiResponderDoc[]
  faqs: AiResponderContentSharingItem[]

  channels: string[]
  delayResponse: number
  isAutoRespondToNewBookings: boolean
  isDelayResponse: boolean
  isRespondEvenIfResponded: boolean
  isCreateDrafts: boolean
  tone: string
  name: string

  maxReplies: number // Maximum Replies

  // Ignore AI
  ignoreBookings: string[]
  ignoreGuests: string[]
  ignoreGuestsRegexp: string[]
  ignoreRentals: string[]
}

export type UpdateAIResponderSettings = Partial<AiResponderSettings>

export interface AccountTokeetAiSettingsResponse {
  recharge_balance: number
  recharge_threshold: number
}
