import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core'
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload'
import { ConfirmDialogService, Destroyable, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { CustomFileUploaderOptions, FileUploaderFactoryService } from '@tv3/services/file-uploader-factory.service'
import { environment } from '@tv3/environments/environment'
import { AuthService } from '@tv3/services/auth.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { FormControl } from '@angular/forms'
import { AiResponderDoc } from '@tv3/containers/ai-responder/store/ai-settings.model'
import { AiResponderService } from '../../store/ai-responder.service'
import { localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import {
  DeleteAIDocument,
  UploadAIDocumentComplete,
  UpdateAISettings,
} from '@tv3/containers/ai-responder/store/ai-settings.actions'

@Component({
  selector: 'ai-responder-documents',
  templateUrl: './ai-responder-documents.component.html',
  styleUrls: ['./ai-responder-documents.component.scss'],
})
export class AiResponderDocumentsComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(p: MatPaginator) {
    this.dataSource.paginator = p
  }
  @ViewChild(MatSort) set sort(s: MatSort) {
    this.dataSource.sort = s
  }
  @Input() set documents(docs: AiResponderDoc[]) {
    this.dataSource.data = docs || []
  }

  displayedColumns = ['name', 'bytes', 'preBooking', 'postBooking', 'postCheckin', 'actions']
  dataSource = new MatTableDataSource<AiResponderDoc>()
  searchCtrl = new FormControl()

  uploader: FileUploader
  uploadedLogoFile: FileItem
  acceptFileExtensions = ['.md', '.txt']
  supportedFileExtensionsStr = this.acceptFileExtensions.join(', ')
  sizeLimit = 1024 * 1024 * 20 // 20MB
  maxFiles = 20

  get isMaxFilesReached() {
    return this.dataSource.data?.length >= this.maxFiles
  }

  constructor(
    private store: Store<any>,
    private authService: AuthService,
    private fileUploaderFactory: FileUploaderFactoryService,
    private toaster: Toaster,
    private confirm: ConfirmDialogService,
    private aiResponderService: AiResponderService
  ) {
    super()
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((term) => {
      this.dataSource.filter = term
    })
    this.dataSource.sortData = localeCompareSort

    this.uploader = this.fileUploaderFactory.create(this.getTokeetUploadOptions(), {
      onAfterAddingFile: async (fileItem) => {},
      onErrorItem: (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
        this.toaster.error(
          'File content is not supported by Advance Intelligence, Please update file content.',
          '',
          response
        )
        this.uploader.removeFromQueue(item)
        this.uploader.isUploading = false
      },
      onCompleteItem: (_fileItem, res) => {
        const data = JSON.parse(res)
        this.store.dispatch(UploadAIDocumentComplete({ doc: data.doc }))
        this.toaster.success('File uploaded successfully')
      },
      onCompleteAll: () => {
        this.uploader.clearQueue()
      },
    })
  }

  getTokeetUploadOptions(): CustomFileUploaderOptions {
    return {
      url: `${environment.apiUrl}/ai/responder/files`,
      autoUpload: true,
      maxFileSize: this.sizeLimit,
      queueLimit: 1,
      allowedFileExtensions: this.acceptFileExtensions,
      authToken: this.authService.token,
    }
  }

  onTogglePreBooking(item: AiResponderDoc, checked: boolean) {
    this.onToggleChanged({ ...item, preBooking: checked })
  }

  onTogglePostBooking(item: AiResponderDoc, checked: boolean) {
    this.onToggleChanged({ ...item, postBooking: checked })
  }

  onTogglePostCheckin(item: AiResponderDoc, checked: boolean) {
    this.onToggleChanged({ ...item, postCheckin: checked })
  }

  onToggleChanged(item: AiResponderDoc) {
    const docs = this.dataSource.data?.map((t) => (t.id == item.id ? item : t))
    this.store.dispatch(
      UpdateAISettings({
        payload: { docs },
        message: `Data saved successfully`,
      })
    )
  }

  onDelete(item: AiResponderDoc) {
    this.confirm.confirm().subscribe(() => {
      this.store.dispatch(DeleteAIDocument({ id: item.id }))
    })
  }
}
