import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { UploadFileDialogComponent, UploadFileOptions } from './upload-file-dialog.component'
import { filter, take } from 'rxjs/operators'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable({
  providedIn: SharedModule,
})
export class UploadFileDialogService {
  constructor(private dialog: MatDialog) {}

  open(options: UploadFileOptions = {}) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        options,
      },
    }

    return this.dialog
      .open(UploadFileDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter((images) => isSomething(images))
      )
  }
}
