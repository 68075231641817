import { createAction, props } from '@ngrx/store'
import { Attachment } from './attachment.model'

export const loadAttachments = createAction('[Attachment] Load Attachments')
export const loadAttachmentsComplete = createAction(
  '[Attachment] Load Attachments Complete',
  props<{ attachments: Attachment[] }>()
)

export const addAttachmentsComplete = createAction(
  '[Attachment] Add Attachments Complete',
  props<{ attachments: Attachment[] }>()
)

export const getAttachments = createAction('[Attachment] Get Attachments', props<{ ids: string[] }>())
export const getAttachmentsComplete = createAction(
  '[Attachment] Get Attachments Complete',
  props<{ attachments: Attachment[] }>()
)

export const deleteAttachment = createAction('[Attachment] Delete Attachment', props<{ id: string }>())
export const deleteAttachmentComplete = createAction('[Attachment] Delete Attachment Complete', props<{ id: string }>())

export const deleteAttachments = createAction('[Guest] Delete Attachments', props<{ ids: string[] }>())
export const deleteAttachmentsComplete = createAction('[Guest] Delete Attachments Complete', props<{ ids: string[] }>())
