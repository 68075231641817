import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { Action, select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { Router } from '@angular/router'
import {
  addRental,
  addRentalComplete,
  addRentalImages,
  addRentalImagesComplete,
  addTagsToRental,
  addTagsToRentalComplete,
  archiveRental,
  archiveRentalComplete,
  deleteRental,
  deleteRentalComplete,
  editRentalImage,
  editRentalImageComplete,
  loadArchivedRentals,
  loadArchivedRentalsComplete,
  loadRental,
  loadRentalComplete,
  loadRentalImages,
  loadRentalImagesComplete,
  loadRentals,
  loadRentalsComplete,
  loadSubdomain,
  loadSubdomainComplete,
  makeRentalImagePrimary,
  removeRentalImage,
  removeRentalImageComplete,
  restrictUser,
  restrictUserAll,
  restrictUserAllComplete,
  restrictUserComplete,
  restrictUsers,
  restrictUsersComplete,
  toggleOwnerRentals,
  toggleOwnerRentalsComplete,
  unArchiveRental,
  unArchiveRentalComplete,
  unRestrictUser,
  unRestrictUserAll,
  unRestrictUserAllComplete,
  unRestrictUserComplete,
  updateAllRentalsCheckTimes,
  updateAllRentalsCheckTimesComplete,
  updateOwners,
  updateOwnersComplete,
  updateRental,
  updateRentalBaseRate,
  updateRentalBaseRateComplete,
  updateRentalComplete,
  updateRentalCustomFields,
  updateRentalCustomFieldsComplete,
  updateRentalDetails,
  updateRentalDetailsComplete,
  updateRentalGPS,
  updateRentalGPSComplete,
  updateRentalImageOrder,
  updateRentalImageOrderComplete,
  updateRentalInstructions,
  updateRentalInstructionsComplete,
  updateRentalTaxes,
  updateRentalTaxesComplete,
  updateRentalPaymentSchedule,
  updateRentalPaymentScheduleComplete,
  loadRentalsImageCounts,
  loadRentalsImageCountsComplete,
  UpdateRentalStatus,
  UpdateRentalStatusComplete,
  removeRentalImages,
  removeRentalImagesComplete,
  loadRentalsPrimaryImages,
  loadRentalsPrimaryImagesComplete,
  UpdateRentalsStatus,
  UpdateRentalsStatusComplete,
  loadRentalRates,
  loadRentalRatesComplete,
} from './rental.actions'
import { RentalService } from './rental.service'
import { EditRentalImageRequest, RentalRequest } from './rental.interfaces'
import { Rental } from './rental.models'
import { selectAllRentals } from './rental.selectors'
import { Update } from '@ngrx/entity'
import { isSomething } from '../../functions'
import { Toaster } from '../../services'
import { ActionFailed } from '../utility'
import { currencies } from '../../constants'

@Injectable()
export class RentalEffects {
  @Effect()
  loadRentals$ = this.actions$.pipe(
    ofType(loadRentals),
    switchMap(({ less }) =>
      this.rentals.all(less).pipe(
        map((rentals) => loadRentalsComplete({ rentals })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  loadRentalRates$ = this.actions$.pipe(
    ofType(loadRentalRates),
    switchMap(() =>
      this.rentals.getOnlyRates().pipe(
        map((rentals) =>
          loadRentalRatesComplete({ updates: rentals.map((t) => ({ id: t.id, changes: { rates: t.rates } })) })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadRental$ = this.actions$.pipe(
    ofType(loadRental),
    switchMap(({ id }) =>
      this.rentals.get(id).pipe(
        map((rental) => loadRentalComplete({ rental })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadRentalsImageCounts$ = this.actions$.pipe(
    ofType(loadRentalsImageCounts),
    switchMap(({ ids }) =>
      this.rentals.loadRentalsImageCounts(ids).pipe(
        map((counts) =>
          loadRentalsImageCountsComplete({
            updates: R.map((c) => ({ id: c.rental_id, changes: { imageCount: c.count } }), counts),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveRental$ = this.actions$.pipe(
    ofType(archiveRental),
    switchMap(({ rental }) =>
      this.rentals.archiveRental(rental.id).pipe(
        map((rental) => archiveRentalComplete({ rental })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateStatus$ = this.actions$.pipe(
    ofType(UpdateRentalStatus),
    switchMap(({ rentalId, status }) =>
      this.rentals.updateStatus(rentalId, status).pipe(
        tap(() => this.toast.success('Rental status updated successfully.')),
        map((rental) => UpdateRentalStatusComplete({ update: { id: rental.id, changes: { ...rental } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRentalsStatus$ = this.actions$.pipe(
    ofType(UpdateRentalsStatus),
    switchMap(({ rentalIds, status }) =>
      of(...rentalIds).pipe(
        concatMap((id) => this.rentals.updateStatus(id, status)),
        toArray(),
        map((rentals) => UpdateRentalsStatusComplete({ update: rentals.map((r) => ({ id: r.id, changes: r })) })),
        tap(() => this.toast.success('Rental status updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadArchivedRental$ = this.actions$.pipe(
    ofType(loadArchivedRentals),
    switchMap(() =>
      this.rentals.getArchivedRentals().pipe(
        map((rentals) => loadArchivedRentalsComplete({ rentals })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unArchiveRental$ = this.actions$.pipe(
    ofType(unArchiveRental),
    switchMap(({ rental }) =>
      this.rentals.unArchiveRental(rental.id).pipe(
        map((rental) => unArchiveRentalComplete({ rental })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadImages$ = this.actions$.pipe(
    ofType(loadRentalImages),
    mergeMap(({ id }) =>
      this.rentals.images(id).pipe(
        map((images) => loadRentalImagesComplete({ update: { id, changes: { images } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadPrimaryImages$ = this.actions$.pipe(
    ofType(loadRentalsPrimaryImages),
    mergeMap(({ rentalIds }) =>
      this.rentals.getPrimaryImages(rentalIds).pipe(
        map((images) =>
          loadRentalsPrimaryImagesComplete({
            updates: images.map((image) => ({ id: image.rentalId, changes: { images: [image] } })),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadSubDomain$ = this.actions$.pipe(
    ofType(loadSubdomain),
    switchMap(() =>
      this.rentals.getSubDomain().pipe(
        map((domain) => loadSubdomainComplete({ domain })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addImage$ = this.actions$.pipe(
    ofType(addRentalImages),
    map(({ rentalId, data }) => {
      let i = 0
      const observList = data.map((item) => {
        i++
        const withToast = i >= data.length
        return this.rentals.addImage({ ...item, rental_id: rentalId }, withToast, 'Rental images added successfully.')
      })
      return observList
    }),
    switchMap((list) =>
      forkJoin(list).pipe(
        switchMap((images) => {
          let id = null
          const returnVal = <Action[]>images.map((image) => {
            id = image.rentalId
            return addRentalImagesComplete({ image })
          })
          returnVal.push(loadRentalImages({ id }))
          return returnVal
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeImage$ = this.actions$.pipe(
    ofType(removeRentalImage),
    switchMap(({ id }) =>
      this.rentals.deleteImage(id).pipe(
        switchMap((image) => [loadRentalImages({ id: image.rentalId }), removeRentalImageComplete({ image })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeImages$ = this.actions$.pipe(
    ofType(removeRentalImages),
    switchMap(({ ids }) =>
      this.rentals.deleteImages(ids).pipe(
        switchMap((images) => [loadRentalImages({ id: images[0].rentalId }), removeRentalImagesComplete({ images })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  primaryImage$ = this.actions$.pipe(
    ofType(makeRentalImagePrimary),
    map(({ rentalId, primaryImageId, images }) => {
      let primaryOrder = 0
      images.map((image) => {
        if (image.id === primaryImageId) {
          primaryOrder = image.order
        }
      })

      const data = {
        primary: { rental: rentalId, image: primaryImageId },
        reorders: [{ id: primaryImageId, order: 0 }],
      }

      images.map((image) => {
        if (image.order < primaryOrder) {
          data.reorders.push({ id: image.id, order: image.order + 1 })
        }
      })

      return data
    }),
    switchMap((data) =>
      this.rentals.primaryImage(data.primary.rental, data.primary.image).pipe(
        switchMap(() =>
          forkJoin(data.reorders.map((imageData) => this.rentals.editImage(imageData.id, { order: imageData.order })))
        ),
        switchMap((images) => [loadRentalImages({ id: images[0].rentalId })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  editRentalImage$ = this.actions$.pipe(
    ofType(editRentalImage),
    map(({ imageId, category, description }) => {
      const data: { id: string; data: EditRentalImageRequest } = { id: imageId, data: { metadata: {} } }
      if (category) {
        data.data.metadata.category = category
      }
      if (description) {
        data.data.metadata.description = description
      }
      return data
    }),
    switchMap((request) =>
      this.rentals.editImage(request.id, request.data).pipe(
        switchMap((image) => [loadRentalImages({ id: image.rentalId }), editRentalImageComplete({ image })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addRental$ = this.actions$.pipe(
    ofType(addRental),
    map(({ form, account }) => {
      return {
        address: {
          address: form.address,
          city: form.city,
          state: form.state,
          country: form.country.name,
          country_code: form.country.id,
          zip: form.zip,
        },
        color: form.color,
        name: form.name,
        email: form.email,
        phone: form.phone,
        gps: isSomething(form.gps) ? form.gps : null,
        currency: currencies[0],

        // TV3-1313: when a new rental is created, if no checkin/checkout is specified we should use the account level data.
        checkin: (account.attributes && account.attributes.checkin) || undefined,
        checkout: (account.attributes && account.attributes.checkout) || undefined,
        attributes: {
          ...form.attributes,
          registration: form.registration,
        },
        baserate: form.baserate,
      } as RentalRequest
    }),
    switchMap((request) =>
      this.rentals.add(request).pipe(
        map((rental) => addRentalComplete({ rental })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRental$ = this.actions$.pipe(
    ofType(updateRental),
    map(({ form, rentalId, rental }) => {
      const request: RentalRequest = {
        address: {
          address: form.address || null,
          city: form.city || null,
          state: form.state || null,
          country: form.country || null,
          country_code: form.countryCode || null,
          zip: (form.zip && form.zip.toString()) || null,
        },
        color: form.color,
        name: form.name,
        display_name: form.displayName,
        email: form.email,
        phone: form.phone,
        gps: isSomething(form.gps) ? form.gps : null,
        currency: form.currency,
        baserate: form.baserate,
        attributes: {
          ...rental.attributes,
          ...form.attributes,
          registration: form.registration,
        },
      }

      if (rental) {
        if (rental.address) {
          const rentalAddress: RentalRequest['address'] = {
            address: rental.address.address || null,
            city: rental.address.city || null,
            state: rental.address.state || null,
            country: rental.address.country || null,
            country_code: rental.address.countryCode || null,
            zip: (rental.address.zip && rental.address.zip.toString()) || null,
          }

          if (R.equals(request.address, rentalAddress)) {
            request.address = undefined
          }
        }

        if (
          ((R.isNil(form.gps) || R.isEmpty(form.gps)) && (R.isNil(rental.gps) || R.isEmpty(rental.gps))) ||
          (form.gps && rental.gps && +form.gps.lat === +rental.gps.lat && +form.gps.long === +rental.gps.long)
        ) {
          request.gps = undefined
        }
      }

      return { request, rentalId }
    }),
    switchMap((payload) =>
      this.rentals.update(payload.rentalId, payload.request).pipe(
        map((rental) => updateRentalComplete({ update: { id: rental.id, changes: rental } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  updateRentalGPS$ = this.actions$.pipe(
    ofType(updateRentalGPS),
    map(({ gps, rentalId }) => ({
      request: {
        gps: isSomething(gps) ? gps : null,
      },
      rentalId: rentalId,
    })),
    switchMap((payload) =>
      this.rentals.updateGPS(payload.rentalId, payload.request).pipe(
        map((rental) => updateRentalGPSComplete({ update: { id: rental.id, changes: rental } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRentalTaxes$ = this.actions$.pipe(
    ofType(updateRentalTaxes),
    switchMap(({ taxes, rentalId, silent }) =>
      this.rentals.updateTaxes(rentalId, taxes, silent).pipe(
        map((rental) => updateRentalTaxesComplete({ update: { id: rentalId, changes: rental } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRentalPaymentSchedule$ = this.actions$.pipe(
    ofType(updateRentalPaymentSchedule),
    switchMap(({ payload, rentalId, silent }) =>
      this.rentals.updatePaymentSchedule(payload, rentalId, silent).pipe(
        map((response) => updateRentalPaymentScheduleComplete({ update: { id: rentalId, changes: response } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeRental$ = this.actions$.pipe(
    ofType(deleteRental),
    switchMap(({ rentalId, redirectPath }) =>
      this.rentals.remove(rentalId).pipe(
        map((id) => {
          if (redirectPath) {
            this.router.navigate([redirectPath])
          }
          return deleteRentalComplete({ id })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateOwners$ = this.actions$.pipe(
    ofType(updateOwners),
    concatMap(({ rental, addOwnerIds, removeOwnerIds, currentOwnerIds }) =>
      this.rentals.removeOwners(rental, removeOwnerIds).pipe(
        switchMap(() => this.rentals.addOwners(rental, addOwnerIds)),
        tap(() => this.toast.success('Owner updated successfully')),
        map(() => updateOwnersComplete({ update: { id: rental.id, changes: { owners: currentOwnerIds } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  toggleOwnerRentals = this.actions$.pipe(
    ofType(toggleOwnerRentals),
    switchMap(({ ownerId, rentals, selected }) => {
      const rentalIds = rentals.map((r) => r.id)
      return (
        selected
          ? this.rentals.addRentalsToOwner(ownerId, rentalIds)
          : this.rentals.removeRentalsToOwner(ownerId, rentalIds)
      ).pipe(map(() => ({ ownerId, rentals, selected })))
    }),
    tap(() => this.toast.success('Owner updated successfully')),
    map(({ ownerId, rentals, selected }) =>
      R.map(
        (r) => ({
          id: r.id,
          changes: {
            owners: selected ? [...(r.owners || []), ownerId] : (r.owners || []).filter((id) => id !== ownerId),
          },
        }),
        rentals
      )
    ),
    map((updates) => toggleOwnerRentalsComplete({ updates })),
    catchError((error) => of(ActionFailed({ error })))
  )

  @Effect()
  updateCustom$ = this.actions$.pipe(
    ofType(updateRentalCustomFields),
    switchMap(({ form, rental }) =>
      this.rentals.updateCustom(rental, form).pipe(
        map(() => updateRentalCustomFieldsComplete({ update: { id: rental.id, changes: { ...form } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateDetails$ = this.actions$.pipe(
    ofType(updateRentalDetails),
    switchMap(({ form, rental }) =>
      this.rentals.updateDetails(rental, form).pipe(
        map((rental) => updateRentalDetailsComplete({ update: { id: rental.id, changes: { ...rental } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateInstructions$ = this.actions$.pipe(
    ofType(updateRentalInstructions),
    switchMap(({ form, rental }) =>
      this.rentals.updateInstructions(rental, form).pipe(
        map((rental) => updateRentalInstructionsComplete({ update: { id: rental.id, changes: { ...rental } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateImageOrder$ = this.actions$.pipe(
    ofType(updateRentalImageOrder),
    switchMap(({ images, rental }) =>
      this.rentals.updateImagesOrder(images).pipe(
        map((images) => {
          return R.map((rentalImage) => {
            const updatedImage = R.find((u) => u.id === rentalImage.id, images)
            if (!R.isNil(updatedImage)) {
              return updatedImage
            } else {
              return rentalImage
            }
          }, rental.images)
        }),
        map((images) => ({ id: rental.id, changes: { images } })),
        map((update) => updateRentalImageOrderComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateBaseRate$ = this.actions$.pipe(
    ofType(updateRentalBaseRate),
    switchMap(({ form, rental, ignoreBDC }) =>
      this.rentals.updateBaseRate(rental, form, ignoreBDC).pipe(
        map((rental) => updateRentalBaseRateComplete({ update: { id: rental.id, changes: { ...rental } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRestrictions$ = this.actions$.pipe(
    ofType(restrictUsers),
    switchMap(({ rental, addUserIds, removeUserIds, currentUserIds }) =>
      this.rentals.unrestrictUsers(rental, removeUserIds).pipe(
        switchMap(() => this.rentals.restrictUsers(rental, addUserIds)),
        tap(() => this.toast.success('User restrictions updated successfully')),
        map(() =>
          restrictUsersComplete({
            update: {
              id: rental.id,
              changes: { restrictedUsers: currentUserIds },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addTagsToRental$ = this.actions$.pipe(
    ofType(addTagsToRental),
    switchMap(({ rental, tags, newTags }) =>
      this.rentals.addTagsToRental(rental, tags).pipe(
        map(({ id, tags }) => addTagsToRentalComplete({ update: { id: rental.id, changes: { tags: tags } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  restrictUser$ = this.actions$.pipe(
    ofType(restrictUser),
    switchMap(({ rental, userId }) =>
      this.rentals.restrictUser(rental, userId).pipe(
        tap(() => this.toast.success('Rental restricted successfully.')),
        map(() => R.pipe(R.append(userId), R.uniq)(rental.restrictedUsers)),
        map((restrictedUsers: string[]) =>
          restrictUserComplete({
            update: {
              id: rental.id,
              changes: { restrictedUsers },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  restrictUserAll$ = this.actions$.pipe(
    ofType(restrictUserAll),
    switchMap(({ rentals, userId }) =>
      this.rentals.restrictUserAll(rentals, userId).pipe(
        tap(() => this.toast.success('Rentals restricted successfully.')),
        map(() =>
          R.map(
            (r) => ({
              id: r.id,
              changes: {
                restrictedUsers: <string[]>R.pipe(R.append(userId), R.uniq)(r.restrictedUsers),
              },
            }),
            rentals
          )
        ),
        map((updates) => restrictUserAllComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unRestrictUser$ = this.actions$.pipe(
    ofType(unRestrictUser),
    switchMap(({ rental, userId }) =>
      this.rentals.unrestrictUser(rental, userId).pipe(
        tap(() => this.toast.success('Rental unrestricted successfully.')),
        map(() => R.filter((userId) => userId !== userId)(rental.restrictedUsers)),
        map((restrictedUsers: string[]) =>
          unRestrictUserComplete({
            update: {
              id: rental.id,
              changes: { restrictedUsers },
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unRestrictUserAll$ = this.actions$.pipe(
    ofType(unRestrictUserAll),
    switchMap(({ rentals, userId }) =>
      this.rentals.unRestrictUserAll(rentals, userId).pipe(
        tap(() => this.toast.success('Rentals unrestricted successfully.')),
        map(() =>
          R.map(
            (r) => ({
              id: r.id,
              changes: {
                restrictedUsers: <string[]>R.filter((userId) => userId !== userId)(r.restrictedUsers),
              },
            }),
            rentals
          )
        ),
        map((updates) => unRestrictUserAllComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateAllRentalsCheckTimes$ = this.actions$.pipe(
    ofType(updateAllRentalsCheckTimes),
    switchMap(({ checkin, checkout }) =>
      this.rentals.updateCheckTimeForAllRentals({ checkin, checkout }).pipe(
        tap(() => this.toast.success('Check-in / check-out times updated successfully for all rentals.')),
        switchMap((omitIds: string[]) => {
          return this.store.pipe(select(selectAllRentals), take(1)).pipe(
            map((rentals: Rental[]): Update<Rental>[] => {
              if (omitIds && omitIds.length) {
                rentals = R.filter((r) => omitIds.indexOf(r.id) === -1, rentals || [])
              }
              return R.map((r) => ({ id: r.id, changes: { checkin, checkout } }), rentals)
            })
          )
        }),
        map((updates) => updateAllRentalsCheckTimesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private toast: Toaster,
    private store: Store<any>,
    private rentals: RentalService
  ) {}
}
