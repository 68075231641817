import { createAction, props } from '@ngrx/store'
import { Notification } from './notification.model'

export const LoadNotifications = createAction('[Notification] Load')
export const LoadNotificationsComplete = createAction(
  '[Notification] Load Complete',
  props<{ notifications: Notification[] }>()
)

export const AddBillingNotificationsLocally = createAction(
  '[Notification] Add Billing Notification Complete',
  props<{ notifications: Notification[] }>()
)
