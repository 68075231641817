import { Component, Inject, OnInit } from '@angular/core'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'
import { Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import { WebsiteService } from '@tv3/store/website/website.service'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import * as R from 'ramda'
import {
  portalPageGroups,
  WebsitePageSettings,
  websitePageTypeLabels,
  WebsitePageTypes,
} from '@tv3/store/website/website.types'
import { defaultTinyMCEOptions } from '@tv3/constants/default-tinymce-options'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { AddWebsitePage, UpdateWebsitePage } from '@tv3/store/website/website.actions'

@Component({
  selector: 'app-website-page-dialog',
  templateUrl: './website-page-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./website-page-dialog.component.scss'],
})
export class WebsitePageDialogComponent extends Destroyable implements OnInit {
  editorOptions = defaultTinyMCEOptions
  form = this.fb.group({
    type: [undefined, [Validators.required]],
    group: [undefined, [Validators.required]],
    order: [undefined],
    // content
    title: [''],
    linkTitle: [undefined, [Validators.required]],
    body: [''],
    // external link / internal link
    linkurl: [],
    status: [1],
  })

  isEdit = false

  websiteTypes = WebsiteTypes
  websitePageTypes = WebsitePageTypes

  availableWebsitePageTypes: { id: string; label: string }[] = lodash.map(websitePageTypeLabels, (label, id) => ({
    id,
    label,
  }))
  availableWebsitePageGroups: string[] = []
  availableWebsitePageOrders = R.map(
    (order) => ({ id: order, label: order }),
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  )

  constructor(
    public dialogRef: MatDialogRef<WebsitePageDialogComponent>,
    private store: Store<fromRoot.State>,
    private toaster: Toaster,
    private websiteService: WebsiteService,
    @Inject(MAT_DIALOG_DATA) public data: { website: Website; page: WebsitePageSettings },
    private fb: FormBuilder
  ) {
    super()
    this.availableWebsitePageGroups = this.getPageGroups(this.data.website)

    this.isEdit = !!this.data.page
    if (this.isEdit) {
      this.form.patchValue(this.data.page.serialize())
    }
  }

  ngOnInit() {
    if (this.data.website.type === WebsiteTypes.RentalWebsite) {
      this.form.get('order').setValidators([Validators.required])
    }

    if (this.data.website.type === WebsiteTypes.GuestPortal) {
      this.availableWebsitePageTypes = R.filter(
        (type) => type.id !== WebsitePageTypes.InternalLink,
        this.availableWebsitePageTypes
      )
    }

    this.form
      .get('type')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((type: WebsitePageTypes) => {
        if (type === WebsitePageTypes.Content) {
          this.form.get('linkurl').clearValidators()
          this.form.get('body').setValidators([Validators.required])
          this.form.get('title').setValidators([Validators.required])
        } else {
          this.form.get('linkurl').setValidators([Validators.required])
          this.form.get('body').clearValidators()
          this.form.get('title').clearValidators()
        }

        if (type === WebsitePageTypes.ExternalLink) {
          this.form.get('linkurl').setValidators([Validators.required, <any>RxwebValidators.url()])
        }

        this.form.get('linkurl').reset()
        this.form.get('body').reset()
        this.form.get('title').reset()
      })
  }

  getPageGroups(website: Website) {
    let groups = []
    if (website.type === WebsiteTypes.RentalWebsite) {
      groups = lodash.uniq(lodash.map(website.pages, 'group'))
      groups = groups.sort()
      groups.unshift('default')
      groups = lodash.uniq(groups)
    } else if (website.type === WebsiteTypes.GuestPortal) {
      groups = portalPageGroups
    }

    return groups
  }

  addGroup(group: string) {
    this.availableWebsitePageGroups.push(group)
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.form.getRawValue()
    if (this.isEdit) {
      this.store.dispatch(
        UpdateWebsitePage({
          website: this.data.website,
          pageId: this.data.page.id,
          data: {
            ...formData,
            order: formData.order || 0,
            pkey: this.data.page.id,
            created: this.data.page.created,
          },
        })
      )
    } else {
      this.store.dispatch(AddWebsitePage({ website: this.data.website, data: formData }))
    }
    this.close()
  }
}
