import { Component, Inject } from '@angular/core'
import { AlertDialogParams } from '../alert-dialog.interfaces'
import { ConfirmDialogStatus } from '../../confirm/confirm-dialog.interfaces'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogParams
  ) {}

  close() {
    this.dialogRef.close(ConfirmDialogStatus.Closed)
  }

  onExtra() {
    this.dialogRef.close('extra')
  }
}
