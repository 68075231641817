import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-gvr-wizard-complete',
  templateUrl: './connect-gvr-wizard-complete.component.html',
  styleUrls: ['./connect-gvr-wizard-complete.component.scss'],
})
export class ConnectGVRWizardCompleteComponent implements OnInit {
  @Output() done = new EventEmitter()

  ngOnInit(): void {}
}
