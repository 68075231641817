import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { AiResponderRentalDataSharing } from '../../store/ai-settings.model'
import {
  aiResponderRentalDataSharingItems,
  AIResponderRentalDataSharingItem,
} from '@tv3/containers/ai-responder/store/ai-settings.model'
import * as lodash from 'lodash'
import { MatTableDataSource } from '@angular/material/table'
import { Store } from '@ngrx/store'
import { UpdateAISettings } from '../../store/ai-settings.actions'

@Component({
  selector: 'app-ai-responder-rental-details',
  templateUrl: './ai-responder-rental-details.component.html',
  styleUrls: ['./ai-responder-rental-details.component.scss'],
})
export class AiResponderRentalDetailsComponent extends Destroyable implements OnInit {
  dataSource = new MatTableDataSource<any>()
  displayedColumns = ['select', 'name', 'preBooking', 'postBooking', 'postCheckin']

  @Input() settings: AiResponderRentalDataSharing
  itemsByGroup: Record<string, AIResponderRentalDataSharingItem[]> = lodash.groupBy(
    aiResponderRentalDataSharingItems,
    'group'
  )

  isSaving = false
  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {}

  onMasterChange(checked: boolean) {
    const keys = this.dataSource.data.map((t) => t.key)

    keys.forEach((key) => {
      this.settings[key].isEnabled = checked
    })
  }

  isMasterChecked() {
    const keys = this.dataSource.data.map((t) => t.key)
    return keys.every((key) => this.settings?.[key]?.isEnabled)
  }

  onOpen(key: string) {
    this.dataSource.data = this.itemsByGroup[key]
  }

  onSaveBasic() {
    this.save(this.itemsByGroup['basic'])
  }

  onSaveDetails() {
    this.save(this.itemsByGroup['details'])
  }

  onSaveInstructions() {
    this.save(this.itemsByGroup['instructions'])
  }

  onTogglePreBooking(item: AIResponderRentalDataSharingItem, checked: boolean) {
    this.settings[item.key].preBooking = checked
    this.save([item])
  }

  onTogglePostBooking(item: AIResponderRentalDataSharingItem, checked: boolean) {
    this.settings[item.key].postBooking = checked
    this.save([item])
  }

  onTogglePostCheckin(item: AIResponderRentalDataSharingItem, checked: boolean) {
    this.settings[item.key].postCheckin = checked
    this.save([item])
  }

  save(group: AIResponderRentalDataSharingItem[]) {
    const payload = group.reduce((acc, item, i) => {
      acc[item.key] = this.settings[item.key]
      return acc
    }, {} as AiResponderRentalDataSharing)

    this.store.dispatch(
      UpdateAISettings({
        payload,
        message: 'Settings updated successfully',
      })
    )
  }
}
