<div class="d-flex align-items-center">
  <div class="icon-square icon-square-secondary-light rounded-circle" style="width: 44px; height: 44px">
    <i class="cs-icon cs-icon-circle-stack"></i>
  </div>
  <div class="text-white text-uppercase text-bold text-xxl">Where's My Money</div>
</div>
<div>
  <div class="icon-square icon-square-secondary-success">
    <i class="cs-icon cs-icon-dollar-circle-check"></i>
  </div>
  <div>
    <div class="text-light">
      Paid
      <i class="cs-icon cs-icon-info-circle" matTooltip="Total paid expense in the account"></i>
    </div>
    <div class="text-xxxl text-semi-bold">{{ statuses.paid | currency }}</div>
  </div>
</div>
<div>
  <div class="icon-square icon-square-secondary-danger">
    <i class="cs-icon cs-icon-dollar-circle-times"></i>
  </div>
  <div>
    <div class="text-light">
      Unpaid
      <i class="cs-icon cs-icon-info-circle" matTooltip="Total unpaid expense in the account"></i>
    </div>
    <div class="text-xxxl text-semi-bold">{{ statuses.unpaid | currency }}</div>
  </div>
</div>
<div>
  <div class="icon-square icon-square-secondary-success">
    <i class="cs-icon cs-icon-file-alt-check"></i>
  </div>
  <div>
    <div class="text-light">
      Approved
      <i class="cs-icon cs-icon-info-circle" matTooltip="Total expense in the account that are approved"></i>
    </div>
    <div class="text-xxxl text-semi-bold">{{ approval.approved | currency }}</div>
  </div>
</div>
<div>
  <div class="icon-square icon-square-secondary-danger">
    <i class="cs-icon cs-icon-top-left-arrow-square"></i>
  </div>
  <div>
    <div class="text-light">
      Waiting for Approval
      <i
        class="cs-icon cs-icon-info-circle"
        matTooltip="Total expense in the account that are waiting for approval"
      ></i>
    </div>
    <div class="text-xxxl text-semi-bold">{{ approval.pending | currency }}</div>
  </div>
</div>
