import { Injectable } from '@angular/core'
import { CalendarEvent } from './calendar.model'
import { oneDaySeconds, itemsInTimeRange, toMoment } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class BookedDatesService {
  bookedDatesByRentalId: Record<string, Record<string, number>> = {}
  constructor() {}

  refresh(events: CalendarEvent[]) {
    this.bookedDatesByRentalId = {}
    events.forEach((e: CalendarEvent) => {
      const countByDate = this.bookedDatesByRentalId[e.rentalId] || {}
      let dates = itemsInTimeRange(e.start, e.end, 'day', false)
      if (!dates.length) {
        // one day event
        const m = toMoment(e.start).startOf('day')
        dates = [m.unix()]
      }
      dates.forEach((date) => {
        countByDate[date] = (countByDate[date] || 0) + 1
      })
      this.bookedDatesByRentalId[e.rentalId] = countByDate
    })
  }

  getCount(rentalId: string, date: number) {
    return this.bookedDatesByRentalId?.[rentalId]?.[date] || 0
  }

  isBooked(rentalId: string, date: number) {
    return this.getCount(rentalId, date) > 0
  }

  isStartOfBooked(rentalId: string, date: number) {
    return this.isBooked(rentalId, date) && !this.isBooked(rentalId, date - oneDaySeconds)
  }

  isEndOfBooked(rentalId: string, date: number) {
    return this.isBooked(rentalId, date) && !this.isBooked(rentalId, date + oneDaySeconds)
  }

  mergeDateRanges(ranges: CalendarEvent[]) {
    if (!ranges.length) return []
    ranges = ranges.sort((a, b) => a.start - b.start)
    const mergedRanges = [[ranges[0].start, ranges[0].end]]

    for (let i = 1; i < ranges.length; i++) {
      const currentRange = ranges[i]
      const lastMergedRange = mergedRanges[mergedRanges.length - 1]

      if (currentRange.start <= lastMergedRange[1]) {
        lastMergedRange[0] = Math.max(lastMergedRange[1], currentRange.end)
      } else {
        mergedRanges.push([currentRange.start, currentRange.end])
      }
    }

    return mergedRanges
  }
}
