import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Message } from '@tv3/store/message/message.model'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { MessageDetails } from '@tv3/models/message/message-details'

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./message-details.component.scss'],
})
export class MessageDetailsComponent implements OnInit {
  details: MessageDetails

  constructor(
    public dialogRef: MatDialogRef<MessageDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: Message; inquiry: Inquiry }
  ) {}

  ngOnInit() {
    this.details = new MessageDetails(this.data.message, this.data.inquiry)
  }

  close() {
    this.dialogRef.close()
  }
}
