<div class="details-box">
  <div class="filters-box">
    <app-select-search
      [multiple]="false"
      [items]="propertyIds$ | async"
      [ctrl]="connectedAccountIdCtrl"
      placeholder="Property"
    ></app-select-search>
    <app-cancel-filters
      [isFiltering]="isFiltering"
      (clear)="onClearFilters()"
      [searchCom]="search"
    ></app-cancel-filters>
    <div class="flex-fill"></div>
    <app-table-search (search)="onSearch($event)" #search></app-table-search>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="propertyId">
          <mat-header-cell *matHeaderCellDef> Property ID</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.propertyId }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.title }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="subtitle">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Subtitle </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [matTooltip]="element.subtitle" matTooltipPosition="above">
              {{ element.subtitle || '....' | truncate : 25 }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef mat-sort-header> URL</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              [href]="element.cta_url"
              target="_blank"
              matTooltip="Click to open the link in Airbnb"
              matTooltipPosition="above"
            >
              <i class="fas fa-link"></i>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [class.row-danger]="row.warning"></mat-row>
      </mat-table>

      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="15"
        [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
  </div>
</div>
