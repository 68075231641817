import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-calendar-multi-overlay',
  templateUrl: './calendar-multi-overlay.component.html',
})
export class CalendarMultiOverlayComponent {
  constructor(public dialogRef: MatDialogRef<CalendarMultiOverlayComponent>) {}

  close() {
    this.dialogRef.close()
  }
}
