import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import {
  mfaDeliveryMethodOptions,
  MFADeliveryMethod,
  Destroyable,
  selectUserById,
  untilDestroy,
  SaveForm,
  UpdateUserMFA,
  selectIsAccountMFAEnabled,
  Toaster,
  ActionFailed,
  User,
  ConfirmDialogService,
  DataCheckerService,
  isSomething,
} from '@tokeet-frontend/tv3-platform'
import { UserGuard } from '@tv3/guards/user.guard'
import { AuthService } from '@tv3/services/auth.service'
import { BehaviorSubject } from 'rxjs'
import { filter, finalize, map, switchMap } from 'rxjs/operators'

enum MFAEnableSteps {
  Settings,
  TOTPRegistration,
  CodeVerification,
}

@Component({
  selector: 'app-user-mfa-dialog',
  templateUrl: './user-mfa-dialog.component.html',
  styleUrls: ['./user-mfa-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class UserMfaDialogComponent extends Destroyable implements OnInit {
  steps = MFAEnableSteps
  currentStep = MFAEnableSteps.Settings

  MFADeliveryMethod = MFADeliveryMethod
  mfaDeliveryMethodOptions = mfaDeliveryMethodOptions
  form = this.fb.group({
    status: [0],
    delivery: [MFADeliveryMethod.Email],
  })
  get selectedDeliveryMethod() {
    return this.form.get('delivery').value
  }

  codeCtrl = new FormControl(null, [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(6),
    RxwebValidators.digit(),
  ])

  currentUser: User

  get isTotpSelected() {
    return this.form.get('delivery').value === MFADeliveryMethod.TOTP
  }
  refreshTotpStatus$ = new BehaviorSubject<boolean>(true)
  isTotpDeviceRegistered$ = this.refreshTotpStatus$.pipe(
    switchMap(() => this.auth.getTotpSetupStatus()),
    map((s) => s.status === 'verified')
  )

  isAccountMFAEnabled$ = this.store.pipe(select(selectIsAccountMFAEnabled))

  get isBypassMFA() {
    return !!this.auth.isBypassingMFA()
  }

  isLoading = false
  totpRegisterURL: string
  recipientAddress: string

  constructor(
    private dialogRef: MatDialogRef<UserMfaDialogComponent>,
    private store: Store<any>,
    private toaster: Toaster,
    private auth: AuthService,
    private fb: FormBuilder,
    private confirm: ConfirmDialogService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([UserGuard])
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectUserById(this.auth.user.id)), untilDestroy(this), filter(isSomething))
      .subscribe((user) => {
        this.currentUser = user
        this.form.patchValue(user.mfa_config || {})
      })
  }

  close() {
    this.dialogRef.close()
  }

  onVerifyStep() {
    this.isLoading = true
    this.auth
      .mfaSendTestCode(this.selectedDeliveryMethod)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.recipientAddress = data.to
          this.currentStep = MFAEnableSteps.CodeVerification
          this.toaster.success('MFA code is sent successfully.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error, skip401: true }))
        }
      )
  }

  onResendCode() {
    this.onVerifyStep()
  }

  @SaveForm()
  onVerifyCode(ctrl: FormControl) {
    this.isLoading = true
    this.auth
      .mfaVerifyCode({ code: ctrl.value, pkey: this.currentUser.id, channel: this.selectedDeliveryMethod })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.saveSettings()
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error, skip401: true }))
        }
      )
  }

  onTOTPStep() {
    this.isLoading = true
    this.auth
      .getTotpSetup()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        if (data.uri) {
          this.currentStep = MFAEnableSteps.TOTPRegistration
          this.totpRegisterURL = data.uri
        } else {
          this.saveSettings()
        }
      })
  }

  @SaveForm()
  onVerifyTotpCode(ctrl: FormControl) {
    this.isLoading = true
    this.auth
      .verifyTotpCode(ctrl.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.saveSettings()
          this.toaster.success('TOTP Enabled successfully.')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onResetTotp() {
    this.confirm
      .confirm({
        title: 'Warning!',
        body: 'Are you sure you want to deregister auth device?',
      })
      .pipe(switchMap(() => this.auth.deleteTotpSetup()))
      .subscribe(
        () => {
          if (this.currentUser?.mfa_config?.delivery === MFADeliveryMethod.TOTP) {
            this.form.patchValue({ delivery: MFADeliveryMethod.Email })
            this.saveSettings(false)
          }
          this.refreshTotpStatus$.next(true)
        },
        (error) => this.store.dispatch(ActionFailed({ error }))
      )
  }

  saveSettings(close = true) {
    const payload = this.form.getRawValue()
    this.store.dispatch(UpdateUserMFA({ userId: this.auth.user.id, payload }))
    if (close) {
      this.close()
    }
  }

  isNotChanged() {
    const data = this.form.getRawValue()
    const mfaConfig = this.currentUser.mfa_config
    return data.delivery === mfaConfig?.delivery && !!data.status === !!mfaConfig.status
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (this.isNotChanged()) {
      this.close()
      return
    }

    const { delivery } = this.form.getRawValue()
    switch (delivery) {
      case MFADeliveryMethod.Email:
      case MFADeliveryMethod.SMS:
      case MFADeliveryMethod.WhatsApp:
        this.onVerifyStep()
        break

      case MFADeliveryMethod.TOTP:
        this.onTOTPStep()
      default:
        break
    }
  }

  onClearBypassMFA() {
    this.auth.deleteBypassMFAToken()
    this.toaster.success('MFA is required now when you login.')
  }
}
