import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditBrandingLogoDialogComponent } from '@tv3/containers/invoices/shared/dialogs/edit-branding-logo-dialog/edit-branding-logo-dialog.component'
import { Preference } from '@tv3/store/preferences/preferences.model'

@Injectable()
export class EditBrandingLogoDialogService {
  constructor(private dialog: MatDialog) {}

  open(preference: Preference) {
    // @todo - This dialog can only be opened if Agent plan or above, show an alert dialog

    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { preference },
    }

    return this.dialog.open(EditBrandingLogoDialogComponent, defaultConfig)
  }
}
