import { createReducer, on } from '@ngrx/store'
import * as lodash from 'lodash'
import {
  DisableMessagingChannelComplete,
  DisconnectMessagingChannelComplete,
  EnableMessagingChannelComplete,
  LoadMessagingChannelConfigsComplete,
} from './actions'
import { initialState } from './state'

export const reducer = createReducer(
  initialState,
  on(LoadMessagingChannelConfigsComplete, (state, { data }) => ({ isLoaded: true, data })),
  on(EnableMessagingChannelComplete, (state, { channel }) => ({
    ...state,
    data: lodash.merge({}, state.data, { [channel]: { enabled: true } }),
  })),
  on(DisableMessagingChannelComplete, (state, { channel }) => ({
    ...state,
    data: lodash.merge({}, state.data, { [channel]: { enabled: false } }),
  })),
  on(DisconnectMessagingChannelComplete, (state, { channel }) => ({
    ...state,
    data: lodash.merge({}, state.data, { [channel]: { enabled: false } }),
  }))
)
