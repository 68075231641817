import { Injectable } from '@angular/core'
import { RentalsSharedModule } from '../rentals-shared.module'
import { AddRentalDialogComponent } from '@tv3/containers/rentals/add/add-rental-dialog.component'
import { SubscriptionPermissionCheckerService } from '@tv3/services/subscription-permission-checker.service'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: RentalsSharedModule,
})
export class AddRentalDialogService {
  constructor(
    private sidePanel: DialogService,
    private subscriptionPermissionCheckerService: SubscriptionPermissionCheckerService
  ) {}

  public open() {
    return this.subscriptionPermissionCheckerService
      .check({
        checkAddRentalPermission: true,
      })
      .pipe(
        switchMap((allowed) => {
          if (allowed) {
            return of(this.sidePanel.openDrawer(AddRentalDialogComponent))
          } else {
            return of(null)
          }
        })
      )
  }
}
