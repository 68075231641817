import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { Invoice, InvoiceBillingAddressComponent, UpdateInvoiceAddress } from '@tokeet-frontend/invoices'
import { Account } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-account-invoice-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent {
  @ViewChild('billingAddress') billingAddress: InvoiceBillingAddressComponent
  @Input() account: Account
  @Input() invoice: Invoice

  @Output() address = new EventEmitter<string>()

  constructor(private store: Store<any>) {}

  onEditAddress() {
    this.billingAddress.changeAddress().subscribe((key) => {
      this.address.emit(key)
      if (this.invoice.id) {
        this.store.dispatch(UpdateInvoiceAddress({ id: this.invoice.id, addressKey: key }))
      }
    })
  }
}
