import { Action, createReducer, on } from '@ngrx/store'
import { adapter, initialState, State } from './card.state'
import {
  AddCreditCardComplete,
  DeleteCreditCardComplete,
  LoadCardsComplete,
  ReplaceCreditCardComplete,
} from './card.actions'

export const reducer = createReducer(
  initialState,
  on(LoadCardsComplete, (state, { cards }) => adapter.addAll(cards, { ...state, loaded: true })),
  on(AddCreditCardComplete, (state, { card }) => adapter.addOne(card, { ...state, loaded: true })),
  on(ReplaceCreditCardComplete, (state, { card }) => adapter.setAll([card], { ...state, loaded: true })),
  on(DeleteCreditCardComplete, (state, { id }) => adapter.removeOne(id, { ...state, loaded: true }))
)

export function cardReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
