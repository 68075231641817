import { GatewayLogState, initialGatewayLogState } from './gateway-log.state'
import { Action, createReducer, on } from '@ngrx/store'
import { LoadPaymentGatewayLogComplete } from './gateway-log.actions'

const reducer = createReducer(
  initialGatewayLogState,
  on(LoadPaymentGatewayLogComplete, (state, { id, log }) => ({
    ...state,
    [id]: log,
  }))
)

export function gatewayLogReducer(state: GatewayLogState | undefined, action: Action) {
  return reducer(state, action)
}
