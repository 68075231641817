<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Contract Contact"
          formControlName="contract_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('contract_contact')"
          *ngIf="form.get('contract_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Reservations Contact"
          formControlName="reservations_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('reservations_contact')"
          *ngIf="form.get('reservations_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Invoices Contact"
          formControlName="invoices_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('invoices_contact')"
          *ngIf="form.get('invoices_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Availability Contact"
          formControlName="availability_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('availability_contact')"
          *ngIf="form.get('availability_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Site Content Contact"
          formControlName="site_content_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('site_content_contact')"
          *ngIf="form.get('site_content_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Party Contact"
          formControlName="parity_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('parity_contact')"
          *ngIf="form.get('parity_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="d-flex align-items-center">
        <ng-select
          [items]="users$ | async"
          [clearable]="true"
          appendTo="body"
          class="mat-block"
          bindLabel="name"
          bindValue="id"
          (change)="validateContact($event)"
          placeholder="Request Contact"
          formControlName="requests_contact"
        >
        </ng-select>
        <a
          href="javascript:;"
          (click)="onEditUser('requests_contact')"
          *ngIf="form.get('requests_contact').value"
          class="ml-2"
        >
          <i class="fal fa-edit"></i>
        </a>
      </div>
    </div>
  </div>
</form>
