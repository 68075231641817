<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-2">
        <h6 class="modal-title">Connection Details</h6>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <app-ical-connection-detail class="d-block mb-3" [rental]="rental" [channel]="channel" [connection]="connection">
      </app-ical-connection-detail>

      <app-ical-connection-status class="d-block mb-3" [connection]="connection"></app-ical-connection-status>

      <app-imported-connection-log
        *ngIf="connection.isImported"
        [channel]="channel"
        [connection]="connection"
      ></app-imported-connection-log>
    </div>
  </div>
</div>
