import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'getImageThumb',
})
export class GetImageThumbPipe implements PipeTransform {
  transform(url: string): string {
    // https://cloudinary.com/documentation/image_transformations#img_trans_overview
    const thumbParams = 'w_210,c_limit,q_auto:eco'
    return (url + '').replace('/upload/', `/upload/${thumbParams}/`)
  }
}
