export interface CalendarRouteMapping {
  name: string
  url: string
  default?: boolean
}

export const calendarRouteMapping = {
  standardCalendar: {
    name: 'admin.calendar.standard', // back compliant for tv2
    url: 'standard',
  },
  agendaCalendar: {
    name: 'admin.calendar.agenda',
    url: 'agenda',
  },
  planningCalendar: {
    name: 'admin.calendar.planning_summary',
    url: 'planning',
  },
  multiCalendar: {
    name: 'admin.calendar.scheduler',
    url: 'multi',
    default: true,
  },
  holdList: {
    name: 'admin.calendar.hold_events',
    url: 'hold',
  },
}

export const defaultLocalCalendarMapping = calendarRouteMapping.multiCalendar
