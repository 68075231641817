import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as fromRoot from '@tv3/store/state'
import { Guest } from '@tv3/store/guest/guest.model'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject } from 'rxjs'
import * as R from 'ramda'
import { selectAllGuests, selectGuestsById } from '@tv3/store/guest/guest.selectors'
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { AddGuestComplete, FetchGuests, SearchGuests } from '@tv3/store/guest/guest.actions'
import { AddGuestDialogService } from '../../../../guests/dialogs/add/add-guest-dialog.service'
import { SetAdditionalGuests } from '@tv3/store/inquiry/inquiry-fields.actions'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { FormBuilder } from '@angular/forms'
import { Destroyable, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-edit-additional-guests',
  host: { class: 'modal-content' },
  templateUrl: './edit-additional-guests.component.html',
  styleUrls: ['./edit-additional-guests.component.scss'],
})
export class EditAdditionalGuestsComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['name', 'primaryEmail', 'phone', 'countryView', 'edit']

  dataSource = new MatTableDataSource<Guest>()

  tableType = TableType.AdditionalGuests
  isEmptyTable$ = isEmptyTable(this.dataSource)

  mainGuest: Guest
  guests: Guest[] = []

  form = this.fb.group({
    guestIds: [[]],
  })

  guests$ = this.store.pipe(
    select(selectAllGuests),
    map((guests) => {
      const filterOutGuestIds = [...R.map((g: Guest) => g.id, this.guests), this.mainGuest && this.mainGuest.id]
      return R.filter((guest: Guest) => !R.contains(guest.id, filterOutGuestIds), guests)
    }),
    map((guests) =>
      R.map((g: Guest) => {
        let isNameEmpty = R.pipe(R.toString, R.trim, R.isEmpty)(g.name)
        return {
          id: g.id,
          name: isNameEmpty ? g.primaryEmail : g.name,
        }
      }, guests)
    )
  )
  guestSearch = new BehaviorSubject<string>('')

  constructor(
    public dialogRef: MatDialogRef<EditAdditionalGuestsComponent>,
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private fb: FormBuilder,
    private toast: Toaster,
    private addGuestDialogService: AddGuestDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
  }

  ngOnInit() {
    const guestIds = R.pathOr([], ['attributes', 'additionalGuests'], this.data.inquiry)
    this.store.dispatch(FetchGuests({ ids: guestIds }))
    this.store.dispatch(SearchGuests({ term: '' }))

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.store
      .pipe(
        select(selectInquiry, { id: this.data.inquiry.id }),
        tap((inquiry) => (this.mainGuest = inquiry.guest)),
        map((inquiry) => R.pathOr([], ['attributes', 'additionalGuests'], inquiry)),
        switchMap((ids) => this.store.pipe(select(selectGuestsById(ids)))),
        untilDestroy(this)
      )
      .subscribe((guests) => {
        this.dataSource.data = guests
        this.guests = guests
      })

    this.actions$.pipe(ofType(AddGuestComplete.type), untilDestroy(this)).subscribe(({ guest }: any) => {
      const currentGuests = R.append(guest, this.guests)
      this.store.dispatch(
        SetAdditionalGuests({
          guestIds: R.map((g: Guest) => g.id, currentGuests),
          inquiry: this.data.inquiry,
        })
      )
    })

    this.guestSearch
      .pipe(
        filter((name) => !R.isEmpty(name)),
        debounceTime(250),
        distinctUntilChanged(),
        untilDestroy(this)
      )
      .subscribe((term) => {
        this.store.dispatch(SearchGuests({ term }))
      })
  }

  onAddExisting() {
    const guestIds: string[] = this.form.get('guestIds').value
    this.form.patchValue({ guestIds: [] })
    const currentGuestIds = R.concat(
      guestIds,
      R.map((g: Guest) => g.id, this.guests)
    )
    this.store.dispatch(SetAdditionalGuests({ guestIds: R.uniq(currentGuestIds), inquiry: this.data.inquiry }))
    this.toast.success('Guest(s) added successfully.')
  }

  onRemove(guest: Guest) {
    const currentGuests = R.filter((g: Guest) => g.id !== guest.id, this.guests)
    this.store.dispatch(
      SetAdditionalGuests({
        guestIds: R.map((g: Guest) => g.id, currentGuests),
        inquiry: this.data.inquiry,
      })
    )
    this.toast.success('Guest removed successfully.')
  }

  onNew() {
    this.addGuestDialogService.open()
  }

  close() {
    this.dialogRef.close()
  }
}
