import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-tiket-wizard-step1',
  templateUrl: './connect-tiket-wizard-step1.component.html',
  styleUrls: ['./connect-tiket-wizard-step1.component.scss'],
})
export class ConnectTiketWizardStep1Component implements OnInit {
  @Output() next = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
}
