import { CsvDataParseResult, CsvFileColumnDef, CsvImportedDateFormat } from '@tv3/interfaces/files/csv-file-parser.file'
import * as moment from 'moment'
import { isEmail } from '@tv3/validators/string-validator/isEmail'
import { isNumeric } from '@tv3/validators/string-validator/isNumeric'
import { isURL } from '@tv3/validators/string-validator/isURL'
import { toFloat } from '@tv3/validators/string-validator/toFloat'
import { isUndefined } from 'lodash'
import * as R from 'ramda'
import { holdEventTypes } from '@tv3/constants/hold-event-types'
import { countries } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export function parseString(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<string> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }

  return { value: str, warning }
}

export function parseInquirySource(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<string> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required` }
  }

  if (!/^([a-zA-Z0-9]|\.)+$/.test(str)) {
    return { error: `Special character in ${colDef.name}` }
  }

  if (str.length > 15) {
    return { error: `${colDef.name} must be at most 15 characters long` }
  }

  return { value: str }
}

export function parseURL(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<string> {
  const { value, warning, error } = parseString(str, colDef)
  if (!isURL(value)) {
    return { value, warning, error: `${str} is not a valid url.` }
  }

  return { value, warning, error }
}

export function parseDate(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<Date> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }

  if (!str && !colDef.default) {
    return { value: undefined }
  }

  const date = moment.utc(str, CsvImportedDateFormat, true) // use strict mode
  if (!date.isValid()) {
    return { error: `${colDef.name} should be in 'YYYY-MM-DD'(2016-02-14) format.` }
  }
  return { value: date.toDate(), warning }
}

export function generateDateParser(format: string = CsvImportedDateFormat) {
  return function (str: string, colDef: CsvFileColumnDef): CsvDataParseResult<Date> {
    if (!str && colDef.required && isUndefined(colDef.default)) {
      return { error: `${colDef.name} is required.` }
    }

    let warning = ''
    if (!str && !isUndefined(colDef.default)) {
      str = colDef.default
      warning = `${colDef.name}: use default: ${str}`
    }

    if (!str) {
      return { value: undefined }
    }

    const date = moment(str, format, true) // use strict mode
    if (!date.isValid()) {
      return { error: `${colDef.name} should be in '${format}'(${moment().format(format)}) format.` }
    }
    return { value: date.toDate(), warning }
  }
}

export function parseDateEpoch(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<number> {
  const { value, warning, error } = parseDate(str, colDef)
  if (moment.isDate(value)) {
    return { value: moment(value).unix(), warning, error }
  }
  return { value, warning, error }
}

export function parseEmail(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<string> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }

  if (!isEmail(str)) {
    return { error: `${str} is not a valid email address` }
  }
  return { value: str, warning }
}

export function parseCountry(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<string> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }

  str = (str + '').trim()

  if (str && R.isNil(R.find((c) => c.name === str, countries))) {
    return { error: `${str} is not a valid country name` }
  }
  return { value: str, warning }
}

export function parseNumber(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<number> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }
  str += '' // convert to string

  if (!isNumeric(str)) {
    return { error: `${str} is not a valid number` }
  }

  const value = toFloat(str)

  if (!isUndefined(colDef.max) && value > colDef.max) {
    return { error: `${colDef.name} should be less than ${colDef.max}: ${value}` }
  }
  if (!isUndefined(colDef.min) && value < colDef.min) {
    return { error: `${colDef.name} should be greater than ${colDef.min}: ${value}` }
  }

  return { value, warning }
}

export function parseCurrency(str: string, colDef: CsvFileColumnDef): CsvDataParseResult<number> {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  let warning = ''
  if (!str && !isUndefined(colDef.default)) {
    str = colDef.default
    warning = `${colDef.name}: use default: ${str}`
  }
  str += '' // convert to string

  const value = toFloat(str.replace(/[^0-9.-]+/g, ''))

  if (!isNumeric(value + '')) {
    return { error: `${str} is not a valid number` }
  }

  if (!isUndefined(colDef.max) && value > colDef.max) {
    return { error: `${colDef.name} should be less than ${colDef.max}: ${value}` }
  }
  if (!isUndefined(colDef.min) && value < colDef.min) {
    return { error: `${colDef.name} should be greater than ${colDef.min}: ${value}` }
  }

  return { value, warning }
}

export function generateValuesParser(values: any[]) {
  return function (str: string, colDef: CsvFileColumnDef): CsvDataParseResult<any> {
    if (!str && colDef.required && isUndefined(colDef.default)) {
      return { error: `${colDef.name} is required.` }
    }

    if (!str && !isUndefined(colDef.default)) {
      return { value: colDef.default, warning: `${colDef.name}: use default` }
    }

    if (str && values.indexOf(str) < 0) {
      return { error: `${str} is not a valid value under column ${colDef.name}.` }
    }

    return { value: str }
  }
}

export function generateMappingParser(mapping: { [key: string]: any }, unknownMappedKey?: string) {
  return function (str: string, colDef: CsvFileColumnDef): CsvDataParseResult<any> {
    if (!str && colDef.required && isUndefined(colDef.default)) {
      return { error: `${colDef.name} is required.` }
    }

    if (!str && !isUndefined(colDef.default)) {
      return { value: colDef.default, warning: `${colDef.name}: use default` }
    }

    str = lodash.toLower(str)

    if (str && !R.hasIn(R.toLower(str + ''), mapping) && unknownMappedKey) {
      return { value: mapping[unknownMappedKey], warning: `${colDef.name}: use default - ${unknownMappedKey}` }
    }

    if (str && !R.hasIn(R.toLower(str + ''), mapping)) {
      return { error: `${str} is not a valid value under column ${colDef.name}.` }
    }

    return { value: mapping[R.toLower(str + '')] }
  }
}

export function generateRentalParser(rentals) {
  return function (str: string, colDef: CsvFileColumnDef) {
    if (!str && colDef.required) {
      return { error: `${colDef.name} is required.` }
    }
    if (!str && !colDef.required) {
      return { value: '' }
    }
    const rentalId = R.prop(
      'id',
      rentals.find((r) => r.name === str)
    )

    if (!rentalId) {
      const msg = `Can not find rental with name ${str}`
      if (colDef.required) {
        return { error: msg }
      } else {
        return { warning: msg }
      }
    }

    return { value: rentalId }
  }
}

export function parseHoldType(str: string, colDef: CsvFileColumnDef) {
  if (!str && colDef.required) {
    return { error: `${colDef.name} is required.` }
  }

  const typeId = R.prop(
    'id',
    holdEventTypes.find((r) => r.name === str)
  )

  if (R.isNil(typeId)) {
    return { error: `Can not find hold event type with name ${str}` }
  }

  return { value: typeId }
}
