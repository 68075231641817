<div class="modal-header">
  <h5 class="modal-title">{{ data.title }}</h5>
</div>
<div class="modal-body">
  <p [innerHtml]="data.body"></p>

  <p *ngIf="data.extra" [innerHTML]="data.extra"></p>
</div>
<div class="modal-footer">
  <button *ngIf="data.extraButton" (click)="onExtra()" [class]="data.extraButton.style">
    <i [class]="data.extraButton.icon"></i>{{ data.extraButton.text }}
  </button>
  <button (click)="close()" type="button" class="btn btn-secondary-info create-btn">OK</button>
</div>
