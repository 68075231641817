import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as lodash from 'lodash'
import { SmartDeviceProvider } from './account.model'
import * as reducer from './reducer'
import * as state from './state'

export const deviceState = createFeatureSelector<state.State>('device')

export const isSmartDevicesLoaded = createSelector(deviceState, (s: state.State) => s.isLoaded)
export const isSmartDeviceSettingsLoaded = createSelector(deviceState, (s: state.State) => s.isSettingsLoaded)
export const selectSmartDeviceSettings = createSelector(deviceState, (s: state.State) => s.settings)

export const selectAllSmartDevices = createSelector(deviceState, reducer.selectAll)

export const selectSmartDeviceEntities = createSelector(deviceState, reducer.selectEntities)

export const selectSmartDeviceById = (deviceId: string) =>
  createSelector(reducer.selectEntities, (dataById) => dataById[deviceId])

export const selectLinkedSmartDevices = createSelector(selectAllSmartDevices, (items) =>
  items.filter((t) => !!t.rentalId)
)
export const selectUnlinkedSmartDevices = createSelector(selectAllSmartDevices, (items) =>
  items.filter((t) => !t.rentalId)
)

export const selectRentalSmartDevices = (rentalId: string, onlyAccessCodeSupported = false) =>
  createSelector(selectAllSmartDevices, (items) => {
    return items
      .filter((i) => i.rentalId === rentalId)
      .filter((d) => (onlyAccessCodeSupported ? d.capabilitiesSupported.includes('access_code') : true))
  })

export const selectLinkedAccountSmartDevices = (account: string) =>
  createSelector(selectAllSmartDevices, (items) => {
    return items.filter((i) => i.connectedAccountId === account)
  })

export const selectConnectedProviders = createSelector(selectAllSmartDevices, (items) => {
  return lodash.map(
    lodash.uniqBy(items, (t) => t.brandName),
    (t) =>
      ({
        brandName: t.brandName,
        brandDisplayName: t.brandDisplayName,
        brandImageUrl: t.brandImageUrl,
      } as SmartDeviceProvider)
  )
})
