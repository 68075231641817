<div class="d-flex gap-3 container-box shadow-none">
  <div class="flex-fill text-center py-5">
    <div class="w-50 m-auto">
      <img src="/assets/images/channel-wizards/complete-icon.png" alt="" height="50" />
      <h6 class="mt-2">Congratulations!</h6>
      <p>
        Great! Now you're ready to connect each listing to the corresponding AdvanceCM rental(s). You will now be taken
        to the Booking.com channel details screen where clicking into any of your rooms will initiate the wizard steps
        for each room.
      </p>
      <div class="d-flex gap-3 align-items-center justify-content-center mt-4">
        <button class="btn btn-secondary-info" (click)="reset.emit()">
          <i class="far fa-link"></i> Connect another account
        </button>
        <button class="btn btn-secondary-primary" (click)="channel.emit()">
          <i class="cs-icon cs-icon-list-square"></i> Channel Details
        </button>
      </div>
    </div>
  </div>
  <div class="px-3 py-4 m-4 text-center" style="background: #f2f2f2; width: 0; min-width: 350px">
    <img src="/assets/images/channel-wizards/hands-clap.png" alt="" class="mb-4" />
    <h5>Connection request completed</h5>
    <p>Your connection with AdvanceCM was activated</p>
    <ul class="list-group my-4 text-left text-sm rounded-0">
      <li class="list-group-item">
        <div class="text-semi-bold">Summary of your request</div>
      </li>
      <li class="list-group-item">AdvanceCM is the connectivity provider handling your connection.</li>
      <li class="list-group-item">
        The requested type of connection: Reservations, Rates & Availability, Guest Reviews, Guest Messages, Performace
        data and insights, Reporting
      </li>
      <li class="list-group-item">The Connection request was auto-approved on 27 May, 08:54</li>
    </ul>

    <a href="#" class="btn text-white" style="background: #0171c2">Go to the Booking.com Extranet</a>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end">
  <button class="btn btn-secondary-info" (click)="done.emit()">
    <i class="cs-icon cs-icon-tasks"></i>
    Done
  </button>
</div>
