import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { LosDiscountEffects } from './los-discount.effects'
import { StoreModule } from '@ngrx/store'
import { reducer } from './los-discount.reducer'
import { LosDiscountService } from './los-discount.service'

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([LosDiscountEffects]),
    StoreModule.forFeature('losDiscounts', reducer),
  ],
  providers: [LosDiscountService],
  declarations: [],
})
export class LosDiscountModule {}
