<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-fill">
        <h6 class="modal-title">Training Videos</h6>
        <div class="flex-fill"></div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>

    <div class="modal-body">
      <div class="video-items">
        <div *ngFor="let item of videoItems" class="video-item">
          <div class="video-poster" (click)="openVideo(item.videoId)">
            <img loading="lazy" src="https://img.youtube.com/vi/{{ item.videoId }}/maxresdefault.jpg" />

            <button class="play-button">
              <i class="fas fa-play fa-fw"></i>
            </button>
          </div>

          <div class="video-title">
            <div class="text-title text-truncate text-dark">{{ item.title }}</div>
            <div class="text-duration text-nowrap text-light text-md">{{ item.duration }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
