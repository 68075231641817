import { Component, HostBinding, Input } from '@angular/core'
import { TableType } from '@tv3/shared/empty-table/table-type'

@Component({
  selector: 'app-empty-table',
  templateUrl: './empty-table.component.html',
  styleUrls: ['./empty-table.component.scss'],
})
export class EmptyTableComponent {
  @Input() type?: TableType

  @HostBinding('style.--image-size')
  @Input()
  imageSize?: string

  @HostBinding('class.searching')
  @Input()
  isFiltering = false

  types = TableType
}
