import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { AddTemplate, Template, TemplateType, TemplateUpdateRequest, UpdateTemplate } from '@tokeet-frontend/templates'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveTemplateDialogService } from '@tv3/containers/templates/save-template-dialog/save-template-dialog.service'
import * as fromRoot from '@tv3/store/state'
import { Store } from '@ngrx/store'
import { Destroyable, SaveForm } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent extends Destroyable implements OnInit {
  private _template: Template
  @Input() set template(t: Template) {
    this._template = t
    this.setTemplate(t)
  }

  get template() {
    return this._template
  }

  _type: TemplateType
  @Input() set type(t: TemplateType) {
    this._type = t
    this.form.get('type').setValue(t)
  }

  @Input() showType = true
  @Input() showSaveBtn = false

  @ViewChild('editor') editor: any
  types = TemplateType

  form = this.fb.group({
    body: ['', [Validators.required]],
    type: [TemplateType.Email],
    subject: [''],
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    template: [''],
  })

  get templateType() {
    return this.form.get('type').value
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private saveTemplateDialogService: SaveTemplateDialogService,
    private amplitudeService: AmplitudeService
  ) {
    super()
  }

  ngOnInit() {}

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const payload = form.getRawValue()
    if (this.template?.id) {
      this.store.dispatch(UpdateTemplate({ id: this.template.id, request: payload }))
    } else {
      this.store.dispatch(AddTemplate({ request: payload }))
      this.amplitudeService.logEvent('add-templates')
    }
  }

  private setTemplate(template: Template) {
    this.form.patchValue({
      type: template?.type || this._type,
      body: R.defaultTo('', template?.body),
      subject: R.defaultTo('', template?.subject),
      name: template?.name || '',
      template,
    })
  }
}
