<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-comments"></i> {{ data ? 'Edit' : 'Add' }} Interaction</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body p-0 bg-none">
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="type" placeholder="Interaction Type" required>
            <mat-option *ngFor="let type of types" [value]="type.value">{{ type.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('type').touched && form.get('type').hasError('required')">
            Type is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block">
          <input
            matInput
            required
            [min]="minDateDefault"
            [max]="maxDateDefault"
            [matDatepicker]="date"
            placeholder="Date:"
            (focus)="date.open()"
            formControlName="date"
            readonly
          />
          <i matSuffix (click)="date.open()" class="far fa-calendar-alt"></i>
          <mat-datepicker #date></mat-datepicker>
          <mat-error *ngIf="form.get('date').touched && form.get('date').hasError('required')">
            Date is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-12">
        <mat-form-field2 class="mat-block mat-txt-area" appearance="outline">
          <textarea
            matInput
            #comment
            maxlength="500"
            rows="14"
            formControlName="comment"
            required
            placeholder="Type your comment here.."
            cdkTextareaAutosize
          ></textarea>
          <mat-hint align="end">{{ form.get('comment').value?.length || 0 }}/500</mat-hint>
          <mat-error *ngIf="form.get('comment').touched && form.get('comment').hasError('required')">
            Comment is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    *ngIf="data"
    (click)="delete()"
    [disabled]="1 | isReadonlyRole"
    type="button"
    class="btn btn-secondary-danger"
  >
    <i class="cs-icon cs-icon-trash"></i> delete
  </button>
  <div class="flex-fill"></div>
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
