import { ChannelPropertySetting } from './channel-property-setting.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<ChannelPropertySetting> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<ChannelPropertySetting> = createEntityAdapter<ChannelPropertySetting>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
