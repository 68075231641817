<div class="calendar-holder">
  <app-mini-event-calendar
    *ngIf="!isLoading; else loading"
    [selectedDate]="guestArrive"
    [events]="events"
    [clickable]="false"
  ></app-mini-event-calendar>

  <ng-template #loading>
    <app-loading-section></app-loading-section>
  </ng-template>
</div>
