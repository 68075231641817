<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Rates List</div>
      <div class="col-sm-6 btn-holder">
        <button type="button" class="btn btn-secondary-info ml-2" (click)="onAdd()">
          <i class="far fa-plus"></i> Add Rate
        </button>
      </div>
    </div>

    <div class="box-body">
      <div class="table-holder table-responsive">
        <table class="table table-default">
          <mat-table #table matSort [dataSource]="dataSource">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.active | yesno }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="propertyId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Property Id </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.propertyId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="propertyName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Property Name </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.propertyName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
              <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div dropdown placement="bottom right" container="body">
                  <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                    <a class="btn-ellips">
                      <i class="fas fa-ellipsis-v"></i>
                    </a>
                  </div>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <li>
                      <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i>Edit</button>
                    </li>
                  </ul>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator2
            #paginator
            [length]="dataSource?.data?.length"
            [class.hidden]="!dataSource?.data?.length"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator2>
        </table>
      </div>
    </div>
  </div>
</div>
