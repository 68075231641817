import { Component } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeFormComponent } from '../../discount-code-form.component'

@Component({
  selector: 'app-discount-code-detail',
  templateUrl: './discount-code-detail.component.html',
  styleUrls: ['./discount-code-detail.component.scss'],
})
export class DiscountCodeDetailComponent extends DiscountCodeFormComponent {
  isEdit = true

  constructor(fb: FormBuilder, store: Store<any>, confirmDialog: ConfirmDialogService) {
    super(fb, store, confirmDialog)
  }
}
