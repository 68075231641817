import { Component } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AddDiscountCodeComplete } from '@tokeet-frontend/discount-codes'
import { ConfirmDialogService, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeFormComponent } from '../discount-code-form.component'

@Component({
  selector: 'app-add-discount-code-dialog',
  templateUrl: './add-discount-code-dialog.component.html',
  styleUrls: ['./add-discount-code-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class AddDiscountCodeDialogComponent extends DiscountCodeFormComponent {
  isEdit = false

  constructor(
    fb: FormBuilder,
    private actions: Actions,
    store: Store<any>,
    confirmDialog: ConfirmDialogService,
    private dialogRef: MatDialogRef<AddDiscountCodeDialogComponent>
  ) {
    super(fb, store, confirmDialog)
  }

  ngOnInit() {
    super.ngOnInit()
    this.actions.pipe(ofType(AddDiscountCodeComplete), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }
}
