import { Component, Input } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent {
  @Input() inquiry: Inquiry
}
