import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  isSmartDeviceSettingsLoaded,
  selectSmartDeviceSettings,
  SmartDeviceService,
  SmartDeviceSettingsResponse,
} from '@tokeet-frontend/smart-devices'
import { Destroyable, openWindow, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { SmartDevicesSubscribeService } from '../smart-devices-subscribe.service'

@Component({
  selector: 'app-connect-device-btn',
  templateUrl: './connect-device-btn.component.html',
  styleUrls: ['./connect-device-btn.component.scss'],
})
export class ConnectDeviceBtnComponent extends Destroyable implements OnInit {
  @Input() label = 'Add Provider'
  @Input() btnClass = 'btn btn-secondary-info'

  @Output() connected = new EventEmitter()

  settings: SmartDeviceSettingsResponse
  isSettingsLoaded$ = this.store.pipe(select(isSmartDeviceSettingsLoaded))
  isDeviceSubscribed = false
  constructor(
    private store: Store<any>,
    private smartService: SmartDeviceService,
    private subscribeDeviceService: SmartDevicesSubscribeService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectSmartDeviceSettings), untilDestroy(this)).subscribe((s) => {
      this.settings = s
    })
    this.store
      .pipe(select(selectActiveProductPlanView(ProductsForPlan.SmartDevice)), untilDestroy(this))
      .subscribe((sub) => {
        this.isDeviceSubscribed = sub?.isValid
      })

    this.subscribeDeviceService.connected.subscribe(() => {
      this.connected.emit()
    })
  }

  onAddProvider() {
    if (!this.subscribeDeviceService.checkSubscription()) {
      return
    }

    this.smartService
      .preConnect()
      .pipe()
      .subscribe((data) => {
        openWindow(data.connectWebview.url)
      })
  }
}
