import { MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, Validators } from '@angular/forms'

export abstract class GenericNoteDialog {
  maxLength = 500

  isEdit = false

  form = this.fb.group({
    note: ['', [Validators.required]],
  })

  constructor(public dialogRef: MatDialogRef<GenericNoteDialog>, public fb: FormBuilder) {}

  close() {
    this.dialogRef.close()
  }
}
