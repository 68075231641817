<div class="modal-header">
  <h6 class="modal-title">Copy Schedules From</h6>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-select-payment-schedules-table
    [excludedRentalIds]="[rental.id]"
    (selected)="onSelect($event)"
  ></app-select-payment-schedules-table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="dialogRef.close()"><i class="far fa-times"></i> Cancel</button>
  <button
    type="button"
    class="btn btn-secondary-success"
    [disabled]="!selectedItems?.length || isLoading"
    (click)="onOverwrite()"
    matTooltip="It will replace your current settings"
  >
    <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i> Overwrite
  </button>
</div>
