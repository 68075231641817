<mat-form-field2 class="mat-block">
  <app-select
    bindValue="id"
    bindLabel="name"
    [multiple]="false"
    [items]="guests$ | async"
    [searchable]="true"
    (searchChange)="onSearch($event)"
    [formControl]="ctrl"
    [placeholder]="placeholder"
    (selectedChange)="onSelect($event)"
    [required]="required"
  >
    <ng-template selectItem let-item="item">
      <div style="line-height: 1.1" class="py-1">
        <div class="text-truncate text-semi-bold">
          {{ item.name }}
        </div>
        <small class="text-muted">
          {{ item.primaryEmail }}
        </small>
      </div>
    </ng-template>
  </app-select>
  <mat-error *ngIf="ctrl?.touched && ctrl?.hasError('required')"> Guest is required </mat-error>
</mat-form-field2>
