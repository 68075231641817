import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { FormBuilder, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import {
  Rental,
  Destroyable,
  LoadTags,
  selectTags,
  untilDestroy,
  SaveForm,
  addTagsToRental,
  AddNewTags,
} from '@tokeet-frontend/tv3-platform'

export interface ManageTagsDialogConfig {
  rental: Rental
}

@Component({
  selector: 'app-manage-rental-tags-dialog',
  templateUrl: './manage-rental-tags-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./manage-rental-tags-dialog.component.scss'],
})
export class ManageRentalTagsDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    tags: [[]],
  })

  tags: string[]

  constructor(
    public dialogRef: MatDialogRef<ManageRentalTagsDialogComponent>,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ManageTagsDialogConfig,
    private store: Store<any>
  ) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(LoadTags())

    this.form.patchValue({ tags: this.data.rental.tags })

    this.store.pipe(select(selectTags('rental')), untilDestroy(this)).subscribe((tags) => {
      this.tags = this.processTags(tags)
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue()
    const tags = this.processTags(formValues.tags)
    const newTags = this.processTags(R.filter((tag: string) => (this.tags || []).indexOf(tag) < 0, tags))
    this.store.dispatch(addTagsToRental({ rental: this.data.rental, tags, newTags }))
    if (!R.isEmpty(newTags)) {
      this.store.dispatch(AddNewTags({ payload: { tags: newTags, type: 'rental' } }))
    }
    this.close()
  }

  private processTags(tags: string[]) {
    return R.map(R.pipe(R.when(R.is(Number), R.toString), R.toLower))(tags || [])
  }
}
