import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './expense.reducer'
import * as state from './expense.state'
import * as R from 'ramda'
import { categories } from './expense.constants'

export const expenseState = createFeatureSelector<state.State>('expense')

export const selectAllExpenses = createSelector(expenseState, reducer.selectAll)

export const selectExpensesLoaded = createSelector(expenseState, (state: state.State) => state.isLoaded)
export const selectExpenseSettingsLoaded = createSelector(expenseState, (state: state.State) => state.isSettingsLoaded)

export const selectExpenseById = (id: string) => createSelector(reducer.selectEntities, (data) => data[id])

export const selectExpenseSettings = createSelector(expenseState, (s) => s.settings)
export const selectExpenseUserApproveItem = (userId: string) =>
  createSelector(selectExpenseSettings, (settings) => {
    return R.find((t) => t.user_id === userId, settings?.approvers || [])
  })

export const selectAllExpenseCategories = createSelector(selectAllExpenses, (expenses) => {
  const result = R.uniq(
    R.concat(
      R.map((c) => c.value, categories),
      R.map((expense) => expense.category, expenses)
    )
  )
  return R.pipe(
    R.reject(R.isNil),
    R.map(R.pipe(R.when(R.is(Number), R.toString))),
    R.uniq,
    R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(result)
})
