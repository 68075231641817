import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite, selectWebsiteById } from '@tv3/store/website/website.selectors'
import * as fromRoot from '@tv3/store/state'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { WebsitePageSettings } from '@tv3/store/website/website.types'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { ConfirmDialogService, Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { WebsiteService } from '@tv3/store/website/website.service'
import { filter } from 'rxjs/operators'
import { DeleteWebsitePage, UpdateWebsitePageStatus } from '@tv3/store/website/website.actions'
import { WebsitePageDialogService } from '@tv3/containers/websites/dialogs/website-page-dialog/website-page-dialog.service'
import * as R from 'ramda'

@Component({
  selector: 'app-website-other-pages',
  templateUrl: './website-other-pages.component.html',
  styleUrls: ['./website-other-pages.component.scss'],
})
export class WebsiteOtherPagesComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<WebsitePageSettings>()
  displayedColumns = [
    'titleView',
    'linkTitle',
    'group',
    'statusView',
    'order',
    'typeView',
    'createdView',
    'updatedView',
    'edit',
  ]
  tableType = TableType.WebsitePages
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableTooltip = ''

  @Input() website: Website

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  constructor(
    private store: Store<fromRoot.State>,
    private confirmDialog: ConfirmDialogService,
    private websiteService: WebsiteService,
    private websitePageDialog: WebsitePageDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.paginator.firstPage()
    // remove order for portal
    if (this.website.type === WebsiteTypes.GuestPortal) {
      this.displayedColumns = R.filter((col) => col !== 'order', this.displayedColumns)
    }

    this.store
      .pipe(
        select(selectWebsiteById, { id: this.website.id }),
        filter((r) => isSomething(r)),
        untilDestroy(this)
      )
      .subscribe((web) => {
        this.website = web
        this.dataSource.data = web.pages || []
      })
    this.tableTooltip = this.getTableTooltip()
  }

  private getTableTooltip() {
    switch (this.website.type) {
      case WebsiteTypes.GuestPortal:
        return 'You may create additional pages for your guest portal. Use these pages to promote local destinations which may interest your guests.'
      case WebsiteTypes.RentalWebsite:
        return 'You may create additional pages for your website.'
      default:
        return ''
    }
  }

  onAddPage() {
    this.websitePageDialog.open(null, this.website)
  }

  onEdit(page: WebsitePageSettings) {
    this.websitePageDialog.open(page, this.website)
  }

  onTogglePage(page: WebsitePageSettings, status: 0 | 1) {
    this.store.dispatch(UpdateWebsitePageStatus({ website: this.website, pageId: page.id, status }))
  }

  onDelete(page: WebsitePageSettings) {
    this.confirmDialog
      .confirm({
        title: 'Delete Page',
        body: 'Are you sure you want to delete this page?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteWebsitePage({ website: this.website, pageId: page.id }))
      })
  }
}
