import { Injectable } from '@angular/core'
import { RentalsSharedModule } from '../rentals-shared.module'
import { DeleteRentalOverlayComponent } from './delete-rental-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: RentalsSharedModule,
})
export class DeleteRentalOverlayService {
  constructor(private sidePanel: DialogService) {}

  public open(rentalId: string) {
    this.sidePanel.openDrawer(DeleteRentalOverlayComponent, { data: { rentalId } })
  }
}
