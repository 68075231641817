import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs'
import { catchError, finalize, map, startWith, switchMap, take, tap, toArray } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as fromRoot from '@tv3/store/state'
import {
  DateRangePickerSelection,
  createLocaleCompareSort,
  isEmptyTable,
  isSomething,
  ActionFailed,
  Rental,
  selectRentalEntities,
  untilDestroy,
  loadRentalRatesComplete,
  loadRentalRates,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'

import {
  ConfirmDialogService,
  Rate,
  RateService,
  RentalService,
  selectAllRentals,
  Toaster,
  toMoment,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { EditRateDialogService } from '../edit-rate-dialog/edit-rate-dialog.service'

@Component({
  selector: 'app-dynamic-rates-table',
  templateUrl: './dynamic-rates-table.component.html',
  styleUrls: ['./dynamic-rates-table.component.scss'],
})
export class DynamicRatesTableComponent extends SelectableRow<Rate> implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  isDeleting = false

  tableType: TableType = TableType.RentalRates

  filters = this.fb.group({
    search: [],
    category: [],
    period: [],
    rentals: [[]],
  })

  categories$: Observable<string[]>
  rentals$ = this.store.pipe(select(selectAllRentals))
  rentalsById: Record<string, Rental> = {}

  displayedColumns = ['select', 'name', 'rental', 'category', 'period', 'rate', 'minimumStay', 'edit']
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private toaster: Toaster,
    private rentalService: RentalService,
    private rateService: RateService,
    private confirmDialog: ConfirmDialogService,
    private editRateDialog: EditRateDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort(this.sortDataAccessor)

    const dynamicRates$ = this.rentals$.pipe(
      map((rentals) => lodash.flatten(lodash.map(rentals, (r) => r.rates || []))),
      map((rates) => lodash.filter(rates, (r) => r.type === 'dynamic'))
    )

    combineLatest([dynamicRates$, this.filters.valueChanges.pipe(startWith(this.filters.getRawValue()))])
      .pipe(map(([rates, filters]) => this.filterRates(rates)))
      .subscribe((rates) => {
        this.dataSource.data = rates
      })

    this.categories$ = dynamicRates$.pipe(map((rates) => this.rentalService.getUniqueCategories(rates)))
    this.store.pipe(select(selectRentalEntities), untilDestroy(this)).subscribe((data) => (this.rentalsById = data))
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(loadRentalRatesComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(loadRentalRates())
  }

  sortDataAccessor = (rate: Rate, property: string) => {
    switch (property) {
      case 'period':
        return rate.start
      case 'rate':
        return rate.lowRate
      case 'rental':
        return this.rentalsById[rate.rentalId]?.name
      default:
        return rate[property]
    }
  }

  onSearch(term: string) {
    this.dataSource.paginator?.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  clearFilters() {
    this.filters.reset()
  }

  filterRates(rates: Rate[]) {
    const filters = this.filters.getRawValue()

    const category = lodash.toString(filters.category)
    const period = filters.period as DateRangePickerSelection
    const rentals = filters.rentals || []

    return lodash
      .chain(rates)
      .filter((rate) => {
        if (!isSomething(rentals)) return true
        return rentals.includes(rate.rentalId)
      })
      .filter((rate) => {
        if (!category || category === '*') return true
        return lodash.toString(rate.category) === category
      })
      .filter((rate) => {
        if (!period) return true
        const start = toMoment(rate.start).startOf('date').unix()
        const end = toMoment(rate.end).startOf('date').unix()

        return (
          (start >= period.from && start <= period.to) ||
          (end >= period.from && end <= period.to) ||
          (start < period.from && end > period.to)
        )
      })
      .value()
  }

  onAdd() {
    this.editRateDialog.open({
      type: 'dynamic',
      rentals: Object.values(this.rentalsById),
    })
  }

  onEdit(rate: Rate) {
    this.editRateDialog.open({ rate })
  }

  onDelete(rate?: Rate) {
    this.confirmDialog
      .confirm({ title: 'Delete Rate?', body: `Are you sure you want to delete this rate?` })
      .pipe(switchMap(() => this.deleteRates([rate])))
      .subscribe(() => {
        this.toaster.success('Rate deleted successfully.')
      })
  }

  onDeleteSelected() {
    const rates = this.getSelected()

    this.confirmDialog
      .confirm({ title: 'Delete Rates?', body: `Are you sure you want to delete selected rates?` })
      .pipe(switchMap(() => this.deleteRates(rates)))
      .subscribe(() => {
        this.toaster.success('Rates deleted successfully.')
      })
  }

  private deleteRates(rates: Rate[]) {
    const ratesByRentalId = lodash.groupBy(rates, (r) => r.rentalId)
    this.isDeleting = true
    return of(...lodash.toPairs(ratesByRentalId)).pipe(
      switchMap(([id, items]) =>
        this.rateService.deleteRates(id, items).pipe(
          tap((data) => {
            this.store.dispatch(updateRentalComplete({ update: { id, changes: data } }))
          }),
          catchError((error) => {
            this.store.dispatch(ActionFailed({ error }))
            return throwError(error)
          })
        )
      ),
      toArray(),
      finalize(() => (this.isDeleting = false))
    )
  }
}
