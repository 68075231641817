import { createAction, props } from '@ngrx/store'
import { CtripPushRoomAvailabilityPayload, CtripPushRoomRatesPayload, CtripRoomModel, CtripStatus } from './model'

export const GetCtripStatus = createAction('[Ctrip] Get Status')
export const GetCtripStatusSuccess = createAction('[Ctrip] Get Status Success', props<{ status: CtripStatus }>())

export const ConnectCtrip = createAction('[Ctrip] Connect', props<{ hotelId: string }>())
export const ConnectCtripSuccess = createAction('[Ctrip] Connect Success', props<{ status: CtripStatus }>())

export const DisconnectCtrip = createAction('[Ctrip] Disconnect', props<{ hotelId: string }>())
export const DisconnectCtripSuccess = createAction('[Ctrip] Disconnect Success', props<{ hotelId: string }>())

export const PushRatesToCtripListing = createAction(
  '[Ctrip] Push Rates to Listing',
  props<{ connId: string; roomTypeCode: number; data: CtripPushRoomRatesPayload }>()
)

export const PushAvailabilityToCtripListing = createAction(
  '[Ctrip] Push Availability to Listing',
  props<{ connId: string; roomTypeCode: number; data: CtripPushRoomAvailabilityPayload }>()
)
