import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Invoice } from '@tokeet-frontend/invoices'

export const defaultInvoicePaginationLimit = 50

export interface State extends EntityState<Invoice> {
  isLoaded: boolean
  isUpdating: boolean
  pagination: any
}

export const adapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
  pagination: {
    limit: defaultInvoicePaginationLimit,
    skip: 0,
    hasMore: true,
  },
})
