import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { languages } from '../constants'

@Pipe({
  name: 'languageName',
})
export class LanguageNamePipe implements PipeTransform {
  transform(langCode: any): string {
    const language = R.find((c) => c['1'] === langCode, languages)
    if (!R.isNil(language)) {
      return language.name
    } else {
      return 'Unknown'
    }
  }
}
