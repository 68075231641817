<mat-form-field2 class="mat-block" [showLabel]="!inline">
  <app-select
    [required]="required"
    [items]="countries"
    [searchable]="true"
    [formControl]="codeCtrl"
    bindValue="id"
    bindLabel="name"
    [placeholder]="label"
    [showBlankItem]="showBlankItem"
  >
  </app-select>
</mat-form-field2>
