import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { BDCContentDialogService } from '@tv3/containers/channels/content/bdc/bdc-content-dialog.service'
import { Channel, ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Observable, of } from 'rxjs'
import { Store } from '@ngrx/store'
import { LoadConnections, RefreshConnectionsForChannel } from '@tv3/store/connection/connection.actions'
import { delay, tap } from 'rxjs/operators'
import { AgodaContentDialogService } from '@tv3/containers/channels/content/agoda/agoda-content-dialog.service'

@Injectable({
  providedIn: SharedModule,
})
export class PropertyContentHelperService {
  constructor(
    private store: Store<any>,
    private bdcContentDialog: BDCContentDialogService,
    private agodaContentDialog: AgodaContentDialogService
  ) {}

  openContentOverlay(channel: Channel, connection?: ConnectionView): Observable<any> {
    let action

    switch (channel.name) {
      case ChannelNameTokens.BookingAPI:
        action = this.bdcContentDialog.openOverlay(channel, connection).afterClosed()
        break
      case ChannelNameTokens.AgodaAPI:
        action = this.agodaContentDialog.openOverlay(channel, connection).afterClosed()
        break
      default:
        action = of(null)
    }

    return action.pipe(
      tap(() =>
        this.store.dispatch(
          RefreshConnectionsForChannel({ channelName: channel.name, channelId: channel.id, silent: true })
        )
      ),
      delay(500), // wait for refreshing
      tap(() => this.store.dispatch(LoadConnections({})))
    )
  }
}
