import { Directive, TemplateRef } from '@angular/core'

@Directive({ selector: '[fieldLabel]' })
export class FormFieldLabelDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[fieldPrefix]' })
export class FormFieldPrefixDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({ selector: '[fieldSuffix]' })
export class FormFieldSuffixDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({ selector: '[fieldError]' })
export class FormFieldErrorDirective {
  constructor(public template: TemplateRef<any>) {}
}
