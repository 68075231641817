import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromRooms from './rooms.reducer'
import { RoomsEffects } from './rooms.effects'
import { RoomsGuard } from './rooms.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('rooms-lib', fromRooms.roomsReducer),
    EffectsModule.forFeature([RoomsEffects]),
  ],
  declarations: [],
  providers: [RoomsGuard],
  exports: [],
})
export class RoomsStoreModule {}
