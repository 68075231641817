import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DataCheckerService, Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject, Subject } from 'rxjs'
import { SearchService } from '@tv3/store/search/search.service'
import { FormControl } from '@angular/forms'
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { FetchInquiries, LoadInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { selectInquiries, selectInquiriesByIds } from '@tv3/store/inquiry/inquiry.selectors'
import * as lodash from 'lodash'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { InquiryGuard } from '@tv3/guards/inquiry.guard'

@Component({
  selector: 'app-booking-select',
  templateUrl: './booking-select.component.html',
  styleUrls: ['./booking-select.component.scss'],
})
export class BookingSelectComponent extends Destroyable implements OnInit {
  @Input() ctrl = new FormControl()
  @Input() placeholder = 'Inquiry'
  _rentalId: string
  @Input() set rentalId(id: string) {
    this._rentalId = id
    this.resetItems$.next(true)
  }
  get rentalId() {
    return this._rentalId
  }
  @Input() required = false
  @Input() onlyBooked = false
  @Input() refreshable = false
  @Input() filter: (item: Inquiry) => boolean

  @Output() selected = new EventEmitter<Inquiry>()

  inquiries$ = new BehaviorSubject<Inquiry[]>([])
  search$ = new Subject<string>()
  resetItems$ = new BehaviorSubject(true)

  constructor(
    private store: Store<any>,
    private searchService: SearchService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([InquiryGuard])
  }

  ngOnInit(): void {
    this.ctrl.valueChanges
      .pipe(
        filter(isSomething),
        tap((id) => this.store.dispatch(FetchInquiries({ ids: [id] }))),
        switchMap((id) => this.store.pipe(select(selectInquiriesByIds([id]))))
      )
      .subscribe((bookings) => {
        this.addItems(bookings)
      })
    this.resetItems$
      .pipe(
        switchMap(() => this.store.pipe(select(selectInquiries))),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.addItems(items)
      })

    this.search$
      .pipe(
        distinctUntilChanged(),
        switchMap((term) => this.searchService.searchInquiries(term, this.getSearchFilters())),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.addItems(items)
      })
  }

  getSearchFilters() {
    const filters: any = {}
    if (this.onlyBooked) {
      filters.booked = 1
    }
    if (this.rentalId) {
      filters.rental_ids = [this.rentalId]
    }
    return filters
  }

  addItems(items: Inquiry[]) {
    items = lodash.uniqBy([...items, ...this.inquiries$.value], 'id')
    items = this.rentalId ? items.filter((i) => i.rentalId === this.rentalId) : items
    items = this.onlyBooked ? items.filter((i) => !!i.booked) : items
    items = lodash.isFunction(this.filter) ? lodash.filter(items, (t) => this.filter(t)) : items
    this.inquiries$.next(items)
  }

  onSearch(term: string) {
    this.search$.next(lodash.trim(term))
  }

  onSelect(items: Inquiry[]) {
    this.selected.emit(lodash.head(items))
  }

  onRefresh() {
    this.store.dispatch(LoadInquiries({ pagination: { limit: 1000, skip: 0, hasMore: false } }))
  }
}
