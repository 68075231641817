<div class="container-box details-box">
  <div class="box-body">
    <div class="row no-margin">
      <div class="col-md-6 box-content border-right">
        <h6 class="title">Channel Information</h6>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Channel" [value]="channel?.friendlyName" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Type" value="API" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div *ngIf="isHomeAway" class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Property Name" [value]="connection?.propertyNameView" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Date Linked" [value]="config?.date_enabled | epoch" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block has-icon">
              <input type="text" matInput placeholder="Rental" [value]="config?.rental?.name" disabled />
              <a href="javascript:;" (click)="rentalOverlay.emit(config?.rental)" class="rental-link">
                <i class="fal fa-home"></i>
              </a>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-md-6 box-content">
        <h6 class="title">Connection Information</h6>
        <div *ngIf="!isHomeAway" class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Channel ID" [value]="config?.channel_rental_id || ''" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div *ngIf="isHomeToGo" class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input
                type="text"
                matInput
                placeholder="Connection Approval Status"
                [value]="config?.channel_live ? 'Live' : 'Pending'"
                disabled
              />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Channel URL" [value]="config?.channel_url || ''" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-11">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput placeholder="Last Sync" [value]="config?.last_sync | epoch" disabled />
            </mat-form-field2>
          </div>
        </div>
        <div *ngIf="isHomeToGo" class="row">
          <div class="col-sm-11">
            <ng-container *ngIf="config?.rental?.taxes?.length > 0">
              <app-form-switch
                (click)="$event.stopPropagation()"
                [id]="config?.pkey"
                [value]="config?.status === 1"
                (valueChange)="toggle.emit(config)"
                >Status: {{ config?.status === 1 ? 'Active' : 'Inactive' }}</app-form-switch
              >
            </ng-container>
            <ng-container *ngIf="config?.rental?.taxes?.length === 0">
              <app-form-switch
                #toggleEl
                [value]="false"
                (click)="$event.stopPropagation(); toggle.emit(config); toggleEl.toggle()"
                >Status: {{ config?.status === 1 ? 'Active' : 'Inactive' }}</app-form-switch
              >
            </ng-container>
          </div>
        </div>
        <div *ngIf="isHomeAway" class="row">
          <div class="col-sm-11">
            <app-form-switch
              (click)="$event.stopPropagation()"
              [id]="config?.pkey"
              [value]="config?.status === 1"
              (valueChange)="toggle.emit(config)"
            >
              Status: {{ config?.status === 1 ? 'Active' : 'Inactive' }}
            </app-form-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
