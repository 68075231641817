<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-address-card"></i>
    {{ data.title ? data.title : 'Edit Address' }}
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="form">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input matInput placeholder="Address" type="text" formControlName="address" required />
          <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
            Address cannot be spaces alone.
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input matInput placeholder="City" type="text" formControlName="city" required />
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input matInput placeholder="State / Province / Region" type="text" formControlName="state" required />
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <mat-form-field2 class="mat-block">
          <input matInput placeholder="Postal Code" type="text" formControlName="postal_code" required />
        </mat-form-field2>
      </div>
      <div class="col-sm-6">
        <app-country-select
          [codeCtrl]="form.get('country_code')"
          [nameCtrl]="form.get('country')"
          [required]="true"
        ></app-country-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
