import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, map, mergeMap, concatMap, switchMap, tap } from 'rxjs/operators'
import { EMPTY, of } from 'rxjs'
import {
  CreateRentalConfig,
  CreateRentalConfigFailure,
  CreateRentalConfigSuccess,
  DisableAllFeedRentals,
  DisableAllFeedRentalsFailure,
  DisableAllFeedRentalsSuccess,
  DisableFeedRentals,
  EnableAllFeedRentals,
  EnableAllFeedRentalsFailure,
  EnableAllFeedRentalsSuccess,
  EnableFeedRentals,
  EnableFeedRentalsSuccess,
  LoadChannelConfig,
  LoadChannelConfigFailure,
  LoadChannelConfigSuccess,
  LoadRentalConfigs,
  LoadRentalConfigsFailure,
  LoadRentalConfigsSuccess,
  PauseRentalConfig,
  PauseRentalConfigSuccess,
  ResumeHomeToGoRentalConfig,
  ResumeRentalConfigFailure,
  ResumeRentalConfigSuccess,
  SubmitForm,
  SubmitFormFailure,
  SubmitFormSuccess,
  UpdateChannelConfig,
  UpdateChannelConfigFailure,
  UpdateChannelConfigSuccess,
  UpdateRentalConfig,
  UpdateRentalConfigFailure,
  UpdateRentalConfigSuccess,
  DisableFeedRentalsSuccess,
  DisableFeedRentalsFailure,
  EnableFeedRentalsFailure,
  CreateHomeAwayChannelConfig,
  CreateHomeAwayChannelConfigFailure,
  CreateHomeAwayChannelConfigSuccess,
  ResumeHomeAwayRentalConfig,
  UpdateHomeAwayChannelConfig,
  UpdateHomeAwayChannelConfigSuccess,
  UpdateHomeAwayChannelConfigFailure,
  PauseHomeAwayChannelConfig,
  PauseHomeAwayChannelConfigSuccess,
  ResumeHomeAwayChannelConfig,
  ResumeHomeAwayChannelConfigSuccess,
} from './channels.actions'
import { ChannelsService } from './channels.service'
import { LoadConnections } from '@tv3/store/connection/connection.actions'
import { HomeAwayChannelConfig, HomeToGoChannelConfig } from '@tokeet-frontend/channels'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ChannelsEffects {
  @Effect()
  loadConfig$ = this.actions$.pipe(
    ofType(LoadChannelConfig),
    concatMap(({ name }) =>
      this.channelsService.loadChannel(name).pipe(
        map((config) => LoadChannelConfigSuccess({ config, name })),
        catchError((err) => {
          if (err.status === 404) {
            return of(LoadChannelConfigSuccess({ config: null, name }))
          } else {
            return of(LoadChannelConfigFailure({ err }))
          }
        })
      )
    )
  )

  @Effect()
  loadRentalConfigs$ = this.actions$.pipe(
    ofType(LoadRentalConfigs),
    concatMap(({ channel }) =>
      this.channelsService.loadRentalConfigs(channel).pipe(
        map((configs) => LoadRentalConfigsSuccess({ configs, channel })),
        catchError((err) => of(LoadRentalConfigsFailure({ err })))
      )
    )
  )

  @Effect()
  createConfig$ = this.actions$.pipe(
    ofType(CreateHomeAwayChannelConfig),
    switchMap(({ payload, channel }) =>
      this.channelsService.createChannel(payload, channel).pipe(
        tap(() => this.toaster.success('Config created successfully')),
        map((config) => CreateHomeAwayChannelConfigSuccess({ config, name: channel.name })),
        catchError((err) => of(CreateHomeAwayChannelConfigFailure({ err })))
      )
    )
  )

  @Effect()
  updateHomeAwayConfig$ = this.actions$.pipe(
    ofType(UpdateHomeAwayChannelConfig),
    switchMap(({ payload, channel }) =>
      this.channelsService.updateChannel(payload, channel).pipe(
        tap(() => this.toaster.success('Config updated successfully')),
        map((config) =>
          UpdateHomeAwayChannelConfigSuccess({ config: config as HomeAwayChannelConfig, name: channel.name })
        ),
        catchError((err) => of(UpdateHomeAwayChannelConfigFailure({ err })))
      )
    )
  )
  @Effect()
  pauseHomeAwayConfig$ = this.actions$.pipe(
    ofType(PauseHomeAwayChannelConfig),
    switchMap(() =>
      this.channelsService.disconnectVrbo().pipe(
        tap(() => this.toaster.success('Channel disconnected successfully')),
        map(() => PauseHomeAwayChannelConfigSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  resumeHomeAwayConfig$ = this.actions$.pipe(
    ofType(ResumeHomeAwayChannelConfig),
    switchMap(() =>
      this.channelsService.reconnectVrbo().pipe(
        tap(() => this.toaster.success('Channel connected successfully')),
        map(() => ResumeHomeAwayChannelConfigSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateConfig$ = this.actions$.pipe(
    ofType(UpdateChannelConfig),
    switchMap(({ payload, channel }) =>
      this.channelsService.updateChannel(payload, channel).pipe(
        tap(() => this.toaster.success('Config updated successfully')),
        map((config) => UpdateChannelConfigSuccess({ config: config as HomeToGoChannelConfig, channel })),
        catchError((err) => of(UpdateChannelConfigFailure({ err })))
      )
    )
  )

  @Effect()
  updateRentalConfig$ = this.actions$.pipe(
    ofType(UpdateRentalConfig),
    switchMap(({ payload, channel, rental }) =>
      this.channelsService.updateRental(payload, rental, channel).pipe(
        tap(() => this.toaster.success('Config updated successfully')),
        map((config) => UpdateRentalConfigSuccess({ config, channel, rental })),
        catchError((err) => of(UpdateRentalConfigFailure({ err })))
      )
    )
  )

  @Effect()
  createRentalConfig$ = this.actions$.pipe(
    ofType(CreateRentalConfig),
    switchMap(({ payload, channel, rental }) =>
      this.channelsService.createRental(payload, rental, channel).pipe(
        tap(() => this.toaster.success('Config updated successfully')),
        map((config) => CreateRentalConfigSuccess({ config, channel, rental })),
        catchError((err) => of(CreateRentalConfigFailure({ err })))
      )
    )
  )

  @Effect()
  enableAllFeedRentals$ = this.actions$.pipe(
    ofType(EnableAllFeedRentals),
    switchMap(({ channel }) =>
      this.channelsService.enableAll(channel).pipe(
        tap(() => this.toaster.success('All rentals enabled successfully')),
        map((configs) => EnableAllFeedRentalsSuccess({ channel, configs })),
        catchError((err) => of(EnableAllFeedRentalsFailure({ err })))
      )
    )
  )

  @Effect()
  disableAllFeedRentals$ = this.actions$.pipe(
    ofType(DisableAllFeedRentals),
    switchMap(({ channel }) =>
      this.channelsService.disableAll(channel).pipe(
        tap(() => this.toaster.success('All rentals disabled successfully')),
        map((configs) => DisableAllFeedRentalsSuccess({ channel, configs })),
        catchError((err) => of(DisableAllFeedRentalsFailure({ err })))
      )
    )
  )

  @Effect()
  enableFeedRentals$ = this.actions$.pipe(
    ofType(EnableFeedRentals),
    switchMap(({ channel, rentalIds }) =>
      this.channelsService.enable(rentalIds, channel).pipe(
        tap(() => this.toaster.success('Rentals enabled successfully')),
        map((configs) => EnableFeedRentalsSuccess({ channel, configs })),
        catchError((err) => of(EnableFeedRentalsFailure({ err })))
      )
    )
  )

  @Effect()
  disableFeedRentals$ = this.actions$.pipe(
    ofType(DisableFeedRentals),
    switchMap(({ channel, rentalIds }) =>
      this.channelsService.disable(rentalIds, channel).pipe(
        tap(() => this.toaster.success('Rentals disabled successfully')),
        map((configs) => DisableFeedRentalsSuccess({ channel, configs })),
        catchError((err) => of(DisableFeedRentalsFailure({ err })))
      )
    )
  )

  @Effect()
  pauseRentalConfig$ = this.actions$.pipe(
    ofType(PauseRentalConfig),
    mergeMap(({ config, channel }) =>
      this.channelsService.pauseRentalConfig(config, channel).pipe(
        tap(() => this.toaster.success('Rental paused successfully')),
        map((config) => PauseRentalConfigSuccess({ config, channel })),
        catchError((err) => {
          this.toaster.error(null, null, err)
          return EMPTY
        })
      )
    )
  )

  @Effect()
  resumeHomeToGoRentalConfig$ = this.actions$.pipe(
    ofType(ResumeHomeToGoRentalConfig),
    mergeMap(({ config, channel }) => {
      if (isSomething(config.rental.taxes)) {
        return this.channelsService.resumeRentalConfig(config, channel).pipe(
          tap(() => this.toaster.success('Rental resumed successfully')),
          map((result) => ResumeRentalConfigSuccess({ config: { ...config, ...result }, channel })),
          catchError((err) => {
            this.toaster.error(null, null, err)
            return EMPTY
          })
        )
      } else {
        this.toaster.warning(`You must set taxes on this rental before enabling it.`)
        return EMPTY
      }
    })
  )

  @Effect()
  resumeHomeAwayRentalConfig$ = this.actions$.pipe(
    ofType(ResumeHomeAwayRentalConfig),
    mergeMap(({ config, channel }) => {
      return this.channelsService.resumeRentalConfig(config, channel).pipe(
        tap(() => this.toaster.success('Rental enabled successfully')),
        map((result) => ResumeRentalConfigSuccess({ config: { ...config, ...result }, channel })),
        catchError((err) => {
          this.toaster.error(null, null, err)
          return EMPTY
        })
      )
    })
  )

  @Effect()
  submitForm$ = this.actions$.pipe(
    ofType(SubmitForm),
    switchMap(({ form, payload, channel }) =>
      this.channelsService.submitForm(form).pipe(
        switchMap((config) => {
          this.toaster.success('Applied to HomeToGo.')
          return [SubmitFormSuccess({ config, name: channel.name }), UpdateChannelConfig({ payload, channel })]
        }),
        catchError(() => {
          this.toaster.error('Unable to submit form, please contact support.')
          return of(SubmitFormFailure())
        })
      )
    )
  )

  @Effect()
  statusChange$ = this.actions$.pipe(
    ofType(
      PauseRentalConfigSuccess,
      ResumeRentalConfigSuccess,
      EnableAllFeedRentalsSuccess,
      DisableAllFeedRentalsSuccess,
      EnableFeedRentalsSuccess,
      DisableFeedRentalsSuccess
    ),
    switchMap(() => of(LoadConnections({ force: true })))
  )

  constructor(private actions$: Actions, private channelsService: ChannelsService, private toaster: Toaster) {}
}
