<div class="container-box details-box overflow-hidden">
  <div class="py-4 px-3">
    <h6 class="mb-0">Connection Status</h6>
  </div>
  <!-- Connection Status table -->
  <div class="table-holder table-responsive">
    <table class="table mat-table mb-0">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell">Status</th>
          <th class="mat-header-cell" *ngIf="connection?.isExported">Exported</th>
          <th class="mat-header-cell" *ngIf="connection?.isImported">Imported</th>
          <th class="mat-header-cell">Skipped</th>
        </tr>
      </thead>
      <tbody>
        <tr class="mat-row">
          <td class="mat-cell">{{ connection?.statusText }}</td>
          <td class="mat-cell" *ngIf="connection?.isExported">{{ connection?.exported || 0 }} Calendar Item(s)</td>
          <td class="mat-cell" *ngIf="connection?.isImported">{{ connection?.imported || 0 }} Calendar Item(s)</td>
          <td class="mat-cell">{{ connection?.skipped || 0 }} Calendar Item(s)</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
