import { Injectable } from '@angular/core'
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import * as moment from 'moment'
import { convertStructToMoment } from '@tv3/utils/functions/convert-struct-to-moment'

@Injectable()
export class NgbMomentAdapter extends NgbDateAdapter<moment.Moment> {
  fromModel(date: moment.Moment): NgbDateStruct {
    if (!date || !moment.isMoment(date)) {
      return null
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    }
  }

  toModel(date: NgbDateStruct): moment.Moment {
    return convertStructToMoment(date)
  }
}
