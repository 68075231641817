import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { PaymentGatewayType, SpreedlySupportedGateway } from './gateway-type.model'

@Injectable()
export class GatewayTypeService {
  constructor(private http: HttpClient) {}

  all() {
    const url = '@api/payments/gateways/'

    return this.http.get<PaymentGatewayType[]>(url).pipe(
      map((gateways) => gateways.filter((g) => g.id === 'stripe' || g.id === 'paypal')),
      map((gateways) => R.sortBy((i: PaymentGatewayType) => -(i.notes || []).length, gateways)),
      map((gateways) => {
        return R.map((gateway: PaymentGatewayType) => {
          gateway.description = `${gateway.description || ''}`.replace(/[t|T]okeet/gi, 'Sympl')
          gateway.notes = R.map((note) => `${note || ''}`.replace(/[t|T]okeet/gi, 'Sympl'), gateway.notes)
          return gateway
        }, gateways)
      })
    )
  }

  getSupportedGateways() {
    const url = `@api/payments/gateways/supported`

    return this.http.get<SpreedlySupportedGateway[]>(url)
  }
}
