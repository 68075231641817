import { Notification } from './notification.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Notification> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
