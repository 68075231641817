import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { Connection } from '@tv3/store/connection/connection.model'
import { selectChannelPropertyIds, selectConnectionsByRealChannelId } from '@tv3/store/connection/connection.selectors'
import { AirbnbChannelService, AirbnbNotificationItem } from '@tokeet-frontend/channels'
import { ChannelService, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { Channel, Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { filter, tap, map, switchMap } from 'rxjs/operators'
import { combineLatest, Observable } from 'rxjs'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { FormControl } from '@angular/forms'

interface AirbnbNotificationTableItem extends AirbnbNotificationItem {
  propertyId: string
}

@Component({
  selector: 'app-abb-notifications',
  templateUrl: './abb-notifications.component.html',
  styleUrls: ['./abb-notifications.component.scss'],
})
export class AbbNotificationsComponent extends Destroyable implements OnInit {
  @Input() channel: Channel

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  items: AirbnbNotificationTableItem[] = []
  dataSource = new MatTableDataSource<AirbnbNotificationTableItem>()
  displayedColumns = ['propertyId', 'title', 'subtitle', 'link']
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableType = TableType.AirbnbNotifications

  connections: Connection[] = []
  connectedAccountIdCtrl = new FormControl('')

  propertyIds$: Observable<any[]>

  constructor(private store: Store<any>, private airbnbChannelService: AirbnbChannelService) {
    super()
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.propertyIds$ = this.store.pipe(select(selectChannelPropertyIds(this.channel.id)))

    this.store
      .pipe(
        select(selectConnectionsByRealChannelId(this.channel.id)),
        filter(isSomething),
        tap((items) => (this.connections = items)),
        map((items: Connection[]) => lodash.uniq(lodash.map(items, (t) => t.propertyId))),
        switchMap((propertyIds: string[]) =>
          combineLatest(
            propertyIds.map((propertyId) => {
              return this.airbnbChannelService
                .getHostNotifications(propertyId)
                .pipe(
                  map((items) =>
                    lodash.map(items, (item: AirbnbNotificationItem) => this.toTableItem(item, propertyId))
                  )
                )
            })
          )
        ),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.items = lodash.flatten(items)
        this.dataSource.data = this.items
      })

    this.connectedAccountIdCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((propertyId) => {
      this.dataSource.data = !propertyId ? this.items : this.items.filter((t) => t.propertyId === propertyId)
    })
  }

  toTableItem(item: AirbnbNotificationItem, propertyId: string): AirbnbNotificationTableItem {
    return {
      ...item,
      propertyId: propertyId,
    }
  }

  onClearFilters() {
    this.connectedAccountIdCtrl.reset()
  }

  get isFiltering() {
    return this.connectedAccountIdCtrl.value
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }
}
