import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { AttributeDescriptionStoreModule } from './store/attr-desc/module'
import { EntityAttributesConfigOverlayComponent } from './components/entity-attributes-config-overlay/entity-attributes-config-overlay.component'
import { EntityAttributesOverlayComponent } from './components/entity-attributes-overlay/entity-attributes-overlay.component'
import { EntityAttributesConfigOverlayService } from './components/entity-attributes-config-overlay/entity-attributes-config-overlay.service'
import { EntityAttributesComponent } from './components/entity-attributes/entity-attributes.component'
import { EntityAttributesService } from './store/attrs'

const components = [EntityAttributesComponent, EntityAttributesConfigOverlayComponent, EntityAttributesOverlayComponent]

const services = [
  //
  EntityAttributesConfigOverlayService,
  EntityAttributesService,
]

@NgModule({
  imports: [CommonModule, Tv3PlatformModule, AttributeDescriptionStoreModule],
  exports: [Tv3PlatformModule, AttributeDescriptionStoreModule, components],
  declarations: [
    //
    ...components,
  ],

  providers: [
    //
    ...services,
  ],
})
export class CustomAttrsLibraryModule {}
