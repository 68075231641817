<ng-container *ngIf="!editable">
  <i *ngFor="let item of stars; let i = index" [ngClass]="getStarClass | call : item"></i>
</ng-container>

<ng-container *ngIf="editable">
  <i
    *ngFor="let item of starOptions; let i = index"
    (click)="onVote(item)"
    (mouseenter)="onHover(item)"
    [ngClass]="{ 'cs-icon-star': hoverIndex > i, 'cs-icon-star-outline': hoverIndex <= i }"
    class="rating-star cs-icon"
  ></i>
</ng-container>
