import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { ExportIcalCalendarDialogService } from '@tv3/containers/channels/channel-connect/dialogs/export-ical-calendar-dialog/export-ical-calendar-dialog.service'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  selectAllAvailableCustomICalChannelName,
  selectExportedCalendarsByChannelName,
  selectImportedCalendarsByChannelName,
} from '@tv3/store/connection/connection.selectors'
import { asyncScheduler, zip } from 'rxjs'
import { observeOn, take, tap } from 'rxjs/operators'
import * as moment from 'moment'
import { AirbnbUploadTransactionDialogService } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-upload-transaction-dialog/airbnb-upload-transaction-dialog.service'
import { ImportIcalCalendarDialogService } from '@tv3/containers/channels/channel-connect/dialogs/import-ical-calendar-dialog/import-ical-calendar-dialog.service'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { FormBuilder } from '@angular/forms'

@Component({
  selector: 'app-ical-channel-dialog',
  templateUrl: './ical-channel-dialog.component.html',
  styleUrls: ['./ical-channel-dialog.component.scss'],
})
export class IcalChannelDialogComponent implements OnInit {
  channel: Channel
  dateFormat = 'DD-MMM-YYYY | h:mm A'
  isAirBnB = false
  showChannelNameFilter = false
  availableChannelNames$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllAvailableCustomICalChannelName))
  filters = this.fb.group({
    channel: [],
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel },
    public dialogRef: MatDialogRef<IcalChannelDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private exportIcalCalendarDialog: ExportIcalCalendarDialogService,
    private airbnbUploadTransactionDialog: AirbnbUploadTransactionDialogService,
    private importIcalCalendarDialog: ImportIcalCalendarDialogService,
    private connectionHelperService: ConnectionHelperService
  ) {}

  ngOnInit() {
    this.channel = this.data.channel
    this.isAirBnB = R.toLower(this.channel.name || this.channel.friendlyName || '').indexOf('airbnb') > -1
    this.showChannelNameFilter = this.channel.name === ChannelNameTokens.UnknownICal
  }

  close() {
    this.dialogRef.close()
  }

  onExportAll() {
    zip(
      this.store.pipe(select(selectImportedCalendarsByChannelName, { name: this.channel.name })),
      this.store.pipe(select(selectExportedCalendarsByChannelName, { name: this.channel.name }))
    )
      .pipe(take(1))
      .subscribe(([importedCalendars, exportedCalendars]) => {
        this.exportIcalCalendarDialog.open(
          [...importedCalendars, ...exportedCalendars],
          `${this.channel.friendlyName}-calendar-${moment().format('MM_DD_YYYY')}`
        )
      })
  }

  onImportMultiple() {
    this.importIcalCalendarDialog.open()
  }

  onRefreshAllImports() {
    this.store
      .pipe(
        select(selectImportedCalendarsByChannelName, { name: this.channel.name }),
        take(1),
        tap((connections) => this.connectionHelperService.refreshImportedCalendars(connections))
      )
      .subscribe()
  }

  onUploadAirBnBTransactions() {
    this.airbnbUploadTransactionDialog.open()
  }
}
