import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { countries, getCountryCode } from '@tokeet-frontend/tv3-platform'
import { AddGuest } from '@tv3/store/guest/guest.actions'
import { GuestForm } from '@tv3/interfaces/forms/guest.form'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Component({
  selector: 'app-add-guest-dialog',
  templateUrl: './add-guest-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-guest-dialog.component.scss'],
})
export class AddGuestDialogComponent extends Destroyable implements OnInit {
  countryList = countries

  form = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    city: [''],
    country: [],
  })

  constructor(
    public dialogRef: MatDialogRef<AddGuestDialogComponent>,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService
  ) {
    super()
  }

  ngOnInit() {
    this.form
      .get('name')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((val) => {
        if (val.trim().length === 0 && val.trim() !== val) {
          this.form.patchValue({ name: val.trim() })
        }
      })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  create(form: FormGroup) {
    const values = form.getRawValue() as GuestForm
    values.country_code = getCountryCode(values.country)
    this.amplitudeService.logEvent('add-guest')
    this.store.dispatch(AddGuest({ form: values }))
    this.close()
  }
}
