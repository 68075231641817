import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { AirbnbChannelService, AirbnbOpportunityItem } from '@tokeet-frontend/channels'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import {
  Channel,
  ChannelService,
  ConnectedChannelAccountResponse,
  Destroyable,
  isSomething,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { filter, switchMap, startWith, tap, debounceTime, map } from 'rxjs/operators'
import { AbbOpportunityDialogService } from '../abb-opportunity-dialog/abb-opportunity-dialog.service'
import { FormControl } from '@angular/forms'
import { combineLatest, Observable } from 'rxjs'
import { TableType } from '@tv3/shared/empty-table/table-type'

@Component({
  selector: 'app-abb-opportunities',
  templateUrl: './abb-opportunities.component.html',
  styleUrls: ['./abb-opportunities.component.scss'],
})
export class AbbOpportunitiesComponent extends Destroyable implements OnInit {
  @Input() channel: Channel

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  dataSource = new MatTableDataSource<AirbnbOpportunityItem>()
  displayedColumns = ['title', 'description', 'edit']
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableType = TableType.AirbnbOpportunities

  selectedListingIdCtrl = new FormControl('')
  connectedAccountIdCtrl = new FormControl('')

  connectedAccounts: ConnectedChannelAccountResponse[] = []
  rooms: {
    name: string
    id: string
    hotelName: string
  }[] = []

  constructor(
    private airbnbChannelService: AirbnbChannelService,
    private abbOpportunityDialog: AbbOpportunityDialogService,
    private channelService: ChannelService
  ) {
    super()
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.channelService
      .getConnectedAccounts(this.channel.id)

      .subscribe((accounts) => {
        this.connectedAccounts = accounts
        this.connectedAccountIdCtrl.setValue('')
      })

    combineLatest([
      this.connectedAccountIdCtrl.valueChanges.pipe(
        tap((propertyId) => {
          this.rooms = lodash.find(this.connectedAccounts, (t) => t.propertyId === propertyId)?.rooms
          this.selectedListingIdCtrl.reset()
        })
      ),
      this.selectedListingIdCtrl.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        debounceTime(500),
        switchMap(([propertyId, listingId]) => this.loadItems(propertyId, listingId)),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.dataSource.data = items
      })
  }

  loadItems(propertyId?, listingId?): Observable<AirbnbOpportunityItem[]> {
    if (propertyId) {
      return this.airbnbChannelService
        .getOpportunities(propertyId, listingId)
        .pipe(map((d) => d.available_opportunities))
    } else {
      return combineLatest(this.connectedAccounts.map((a) => this.loadItems(a.propertyId))).pipe(
        map((data) => lodash.flatten(data))
      )
    }
  }

  openAirbnbActivateUrl(item: AirbnbOpportunityItem) {
    window.open(item.activation_url, '_blank')
  }

  onEdit(item: AirbnbOpportunityItem) {
    this.abbOpportunityDialog
      .open(item, this.channel.id, this.connectedAccountIdCtrl.value)
      .pipe(filter(isSomething))
      .subscribe((opportunityId: string) => {
        this.dataSource.data = lodash.filter(this.dataSource.data, (t) => t.id !== opportunityId)
      })
  }

  onClearFilters() {
    this.selectedListingIdCtrl.reset()
    this.connectedAccountIdCtrl.reset()
  }

  get isFiltering() {
    return this.selectedListingIdCtrl.value || this.connectedAccountIdCtrl.value
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }
}
