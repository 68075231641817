import * as R from 'ramda'
import * as moment from 'moment'
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

export function convertStructToMoment(date: NgbDateStruct | NgbDate) {
  if (!date || !R.is(Number, date.day) || !R.is(Number, date.day) || !R.is(Number, date.day)) {
    return null
  }

  return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD')
}
