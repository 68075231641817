<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-sticky-note"></i>&nbsp;&nbsp;
    <ng-container *ngIf="isEdit"> Edit Comment</ng-container>
    <ng-container *ngIf="!isEdit"> Add Comment</ng-container>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [formEditablePermission]="form">
    <mat-form-field2 [showLabel]="false">
      <textarea
        matInput
        #notes
        [maxlength]="maxLength"
        rows="8"
        formControlName="note"
        placeholder="Type your comment here.."
        style="outline: none"
      ></textarea>
      <mat-error *ngIf="form.get('note').touched && form.get('note').hasError('required')">
        Comment is <strong>required</strong>
      </mat-error>
      <mat-hint align="end">{{ notes.value.length }} / {{ maxLength }}</mat-hint>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button
    *ngIf="isEdit"
    (click)="remove()"
    [disabled]="1 | isReadonlyRole"
    type="button"
    class="btn btn-secondary-danger"
  >
    <i class="cs-icon cs-icon-trash"></i> delete
  </button>
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
