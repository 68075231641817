import { createAction, props } from '@ngrx/store'
import { Inquiry, InquiryStatus, LoadInquiriesFilter } from './inquiry.model'
import { Update } from '@ngrx/entity'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { Pagination } from '@tv3/interfaces/table/pagination'
import { InquiryCost } from '@tokeet/cost-resolver'

export const LoadInquiries = createAction('[Inquiry] Load Inquiries', props<{ pagination: Pagination }>())
export const LoadInquiriesComplete = createAction(
  '[Inquiry] Load Inquiries Complete',
  props<{ inquiries: Inquiry[]; pagination: Pagination }>()
)

export const LoadFilteredInquiries = createAction(
  '[Inquiry] Load Filtered Inquiries',
  props<{ filter: LoadInquiriesFilter }>()
)
export const LoadFilteredInquiriesComplete = createAction(
  '[Inquiry] Load Filtered Inquiries Complete',
  props<{ inquiries: Inquiry[] }>()
)

export const RefreshInquiries = createAction('[Inquiry] Refresh Inquiries')
export const RefreshInquiriesComplete = createAction(
  '[Inquiry] Refresh Inquiries Complete',
  props<{ inquiries: Inquiry[] }>()
)

export const SearchInquiries = createAction('[Inquiry] Search Inquiries', props<{ term: string }>())
export const SearchInquiriesComplete = createAction(
  '[Inquiry] Search Inquiries Complete',
  props<{ inquiries: Inquiry[] }>()
)

export const SearchElasticInquiries = createAction('[Inquiry] Search Elastic Inquiries', props<{ term: string }>())
export const SearchElasticInquiriesComplete = createAction(
  '[Inquiry] Search Elastic Inquiries Complete',
  props<{ inquiries: Inquiry[] }>()
)

export const SendGuestLogin = createAction('[Inquiry] Send Guest Login', props<{ inquiry: Inquiry }>())

export const LoadInquiryDetails = createAction('[Inquiry] Load Inquiry Details', props<{ id: string }>())
export const LoadInquiryDetailsComplete = createAction(
  '[Inquiry] Load Inquiry Details Complete',
  props<{ inquiry: Inquiry }>()
)

export const AddInquiry = createAction(
  '[Inquiry] Add Inquiry',
  props<{ form: AddInquiryForm; quotes: InquiryCost; cost: BookingEngineCost }>()
)
export const AddInquiryComplete = createAction('[Inquiry] Add Inquiry Complete', props<{ inquiry: Inquiry }>())

export const AddBooking = createAction(
  '[Inquiry] Add Booking',
  props<{
    form: AddInquiryForm
    quotes: InquiryCost
    cost: BookingEngineCost
    checkOverlapping: boolean
  }>()
)
export const AddBookingComplete = createAction('[Inquiry] Add Booking Complete', props<{ inquiry: Inquiry }>())

export const FetchInquiries = createAction('[Inquiry] Fetch Inquiries', props<{ ids: string[] }>())
export const FetchInquiriesComplete = createAction(
  '[Inquiry] Fetch Inquiries Complete',
  props<{ inquiries: Inquiry[] }>()
)

export const DeleteInquiry = createAction(
  '[Inquiry] Delete Inquiry',
  props<{ inquiry: Inquiry; deleteGuest: boolean }>()
)
export const DeleteInquiryComplete = createAction('[Inquiry] Delete Inquiry Complete', props<{ id: string }>())

export const ChangeAirBnBResponse = createAction(
  '[Inquiry] Change AirBnB Response',
  props<{ inquiry: Inquiry; status: InquiryStatus }>()
)
export const ChangeAirBnBResponseComplete = createAction(
  '[Inquiry] Change AirBnB Response Complete',
  props<{ update: Update<Inquiry> }>()
)

export const BookingAlterationChanged = createAction('[Inquiry] Booking Alteration Changed', props<{ id: string }>())

export const DeleteInquiries = createAction(
  '[Inquiry] Delete Inquiries',
  props<{ inquiryIds: string[]; guestIds: string[]; guest: boolean }>()
)
export const DeleteInquiriesComplete = createAction('[Inquiry] Delete Inquiries Complete', props<{ ids: string[] }>())

export const DeleteInquiryMessageComplete = createAction(
  '[Inquiry] Delete Message Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UpdateInquiryCost = createAction('[Inquiry] Update Inquiry Cost', props<{ id: string; cost: string }>())
export const UpdateInquiryCostComplete = createAction(
  '[Inquiry] Update Inquiry Cost Complete',
  props<{ update: Update<Inquiry> }>()
)
