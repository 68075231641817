import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialogConfig } from '@angular/material/dialog'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { AirbnbTaxSettingsDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-tax-settings-dialog/airbnb-tax-settings-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class AirbnbTaxSettingsDialogService {
  constructor(private sidePanel: DialogService) {}

  open(connection: ConnectionView) {
    const defaultConfig: MatDialogConfig = {
      panelClass: ['drawer-full-80'],
      data: { connection },
    }

    return this.sidePanel.openDrawer(AirbnbTaxSettingsDialogComponent, defaultConfig)
  }
}
