import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export function isFeedChannel(channelName: string) {
  return (
    channelName === ChannelNameTokens.HomeToGo ||
    channelName === ChannelNameTokens.Homeaway ||
    channelName === ChannelNameTokens.Hvmi ||
    channelName === ChannelNameTokens.Holidu ||
    channelName === ChannelNameTokens.GoogleVacationRentals ||
    channelName === ChannelNameTokens.RentalsUnited
  )
}

export function isTiketLikeChannel(channelName: string) {
  channelName = lodash.toLower(channelName)
  return (
    channelName === lodash.toLower(ChannelNameTokens.Tiket) || channelName === lodash.toLower(ChannelNameTokens.Trip)
  )
}
