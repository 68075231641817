import {
  CalendarEvent,
  CalendarEventTypeConfirmed,
  CalendarEventTypeOther,
  CalendarEventTypesMapping,
} from './calendar.model'
import * as R from 'ramda'
import * as lodash from 'lodash'
import * as moment from 'moment'
import {
  Account,
  createdBySystemNameMappings,
  epochToView,
  epochToViewUTC,
  Rental,
  RentalService,
} from '@tokeet-frontend/tv3-platform'
import { DefaultInquiryColor } from '@tv3/store/inquiry/inquiry.model'
import { AgendaEventType } from '@tv3/containers/calendar/agenda/enums/agenda-calendar-event-type.enum'

export const DefaultHoldEventColor = '#676767'

export class CalendarEventView extends CalendarEvent {
  // data fields
  startDate: moment.Moment
  endDate: moment.Moment
  isAllDay: boolean
  isHoldEvent: boolean
  color: string

  // view fields
  titleView = ''
  guestsView = ''
  rentalView = ''
  checkinView = ''
  checkoutView = ''
  typeView = ''

  constructor(rawEvent: CalendarEvent, public rental: Rental, account: Account) {
    super(rawEvent)

    this.startDate = R.is(Number, rawEvent.start) ? moment.utc(rawEvent.start * 1000) : moment.utc(rawEvent.start)
    this.endDate = R.is(Number, rawEvent.end) ? moment.utc(rawEvent.end * 1000) : moment.utc(rawEvent.end)

    this.isHoldEvent = !rawEvent.bookingId
    this.isAllDay = this.isHoldEvent
    if (R.isNil(this.type)) {
      this.type = rawEvent.bookingId ? CalendarEventTypeConfirmed.id : CalendarEventTypeOther.id
    }
    this.color = this.isHoldEvent ? rawEvent.color || DefaultHoldEventColor : rawEvent.color || DefaultInquiryColor

    this.titleView = this.title
    this.typeView = CalendarEventTypesMapping[this.type].name
    if (rental) {
      this.checkinView = RentalService.getCheckInTime(rental, this.checkin, account).format('h:mm A')
      this.checkoutView = RentalService.getCheckOutTime(rental, this.checkout, account).format('h:mm A')
      this.rentalView = rental.name
    }

    this.guestsView = rawEvent.bookingId ? `Adults ${rawEvent.adults}, Children ${rawEvent.children}` : 'Hold event'
  }
}

export class AgendaCalendarEventView extends CalendarEventView {
  // data fields
  checkEventType: string
  date: moment.Moment

  // view fields
  dateView = ''
  checkTimeView = ''

  constructor(event: CalendarEvent, rental: Rental, account: Account) {
    super(event, rental, account)
  }

  setCheckEventType(type: AgendaEventType) {
    this.checkEventType = type
    this.date = type === AgendaEventType.CheckOut ? this.endDate : this.startDate
    this.dateView = this.date.format('DD - MMM - YYYY')
    this.checkTimeView = type === AgendaEventType.CheckOut ? this.checkoutView : this.checkinView
  }
}

export class StandardCalendarEventView extends CalendarEventView {
  constructor(event: CalendarEvent, rental: Rental, account: Account) {
    super(event, rental, account)

    const source = !this.isHoldEvent && this.source ? `(${lodash.startCase(this.source)}) ` : ''
    this.titleView = `${event.title} ${source}(${this.typeView}) ${!this.rentalView ? '' : ' - ' + this.rentalView}`
  }
}

export class PlanningCalendarEventView extends CalendarEventView {
  constructor(event: CalendarEvent, rental: Rental, account: Account) {
    super(event, rental, account)
    this.titleView = `${event.title}`
  }
}

export class MultiCalendarEventView extends CalendarEventView {
  constructor(event: CalendarEvent, rental: Rental, account: Account) {
    super(event, rental, account)

    const source = !this.isHoldEvent && this.source ? `(${lodash.startCase(this.source)}) ` : ''
    this.titleView = `${event.title} ${source}(${this.typeView}) ${!this.rentalView ? '' : ' - ' + this.rentalView}`
  }
}

export class HoldEventView extends CalendarEventView {
  createdView: string
  startView: string
  endView: string
  expiresView: string
  dateViewFormat = 'DD - MMM - YYYY'
  createdByView: string
  isAuto: boolean

  constructor(event: CalendarEvent, rental: Rental, account: Account) {
    super(event, rental, account)
    this.isAuto = !!createdBySystemNameMappings[event.createdBy]
    this.createdView = epochToView(this.created, this.dateViewFormat)
    this.startView = epochToViewUTC(this.start)
    this.endView = epochToViewUTC(this.end)
    this.expiresView = epochToViewUTC(this.expires)
  }
}
