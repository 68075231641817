import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'

@Pipe({
  name: 'fieldOrPath',
})
export class FieldOrPathPipe implements PipeTransform {
  transform(value: any, fieldOrPath, defaultValue = ''): any {
    return R.pathOr(defaultValue, R.split('.', R.defaultTo('', fieldOrPath)), value)
  }
}
