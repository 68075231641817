import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { RentalsUnitedChannelService } from './service'
import {
  ConnectRentalsUnited,
  ConnectRentalsUnitedSuccess,
  CreateRentalsUnitedListing,
  CreateRentalsUnitedListingSuccess,
  DeleteRentalsUnitedListing,
  DeleteRentalsUnitedListingSuccess,
  DisconnectRentalsUnited,
  DisconnectRentalsUnitedSuccess,
  GetRentalsUnitedListings,
  GetRentalsUnitedListingsSuccess,
  GetRentalsUnitedRentalValidations,
  GetRentalsUnitedRentalValidationsSuccess,
  GetRentalsUnitedStatus,
  GetRentalsUnitedStatusSuccess,
  ImportRentalsUnitedBookings,
  ImportRentalsUnitedBookingsSuccess,
  SetRentalsUnitedListingStatus,
  SetRentalsUnitedListingStatusSuccess,
  UpdateRentalsUnitedListing,
} from './actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class RentalsUnitedChannelEffects {
  @Effect()
  getStatus$ = this.actions$.pipe(
    ofType(GetRentalsUnitedStatus),
    switchMap(() =>
      this.channelsService.getStatus().pipe(
        map((status) => GetRentalsUnitedStatusSuccess({ status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  connect$ = this.actions$.pipe(
    ofType(ConnectRentalsUnited),
    switchMap(({ email, password, update }) =>
      this.channelsService.connect(email, password, update).pipe(
        tap(() => {
          if (update) {
            this.toaster.success('Rentals United credentials updated successfully')
          } else {
            this.toaster.success('Rentals United connected successfully')
          }
        }),
        map((status) => ConnectRentalsUnitedSuccess({ status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getListings$ = this.actions$.pipe(
    ofType(GetRentalsUnitedListings),
    switchMap(() =>
      this.channelsService.getListings().pipe(
        map((listings) => GetRentalsUnitedListingsSuccess({ listings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createListing$ = this.actions$.pipe(
    ofType(CreateRentalsUnitedListing, UpdateRentalsUnitedListing),
    switchMap(({ listing }) =>
      this.channelsService.createListing(listing).pipe(
        tap(() => this.toaster.success('Listing updated successfully.')),
        map((response) => CreateRentalsUnitedListingSuccess({ update: { id: listing.rentalId, changes: response } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteListing$ = this.actions$.pipe(
    ofType(DeleteRentalsUnitedListing),
    switchMap(({ propertyId, rentalId }) =>
      this.channelsService.deleteListing(propertyId).pipe(
        tap(() => this.toaster.success('Listing deleted successfully.')),
        map((response) =>
          DeleteRentalsUnitedListingSuccess({ update: { id: rentalId, changes: { propertyId: null, lastSync: null } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  setListingStatus$ = this.actions$.pipe(
    ofType(SetRentalsUnitedListingStatus),
    switchMap(({ propertyId, rentalId, status }) =>
      this.channelsService.setListingStatus(propertyId, status).pipe(
        tap(() => this.toaster.success(status ? 'Listing enabled successfully.' : 'Listing disabled successfully.')),
        map((response) =>
          SetRentalsUnitedListingStatusSuccess({ update: { id: rentalId, changes: { active: status } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getRentalValidations$ = this.actions$.pipe(
    ofType(GetRentalsUnitedRentalValidations),
    switchMap(() =>
      this.channelsService.getRentalsValidations().pipe(
        map((validations) => GetRentalsUnitedRentalValidationsSuccess({ validations })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  disconnect$ = this.actions$.pipe(
    ofType(DisconnectRentalsUnited),
    switchMap(() =>
      this.channelsService.disconnect().pipe(
        tap(() => this.toaster.success('Rentals United disconnected successfully')),
        map(() => DisconnectRentalsUnitedSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  importBookings$ = this.actions$.pipe(
    ofType(ImportRentalsUnitedBookings),
    switchMap((data) =>
      this.channelsService.importBookings(data.rentalId, data.propertyId).pipe(
        tap(() => this.toaster.success('Rentals United bookings imported successfully')),
        map(() => ImportRentalsUnitedBookingsSuccess()),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private channelsService: RentalsUnitedChannelService,
    private toaster: Toaster
  ) {}
}
