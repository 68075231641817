<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Address" type="text" formControlName="address" required />
        <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
          Address cannot be spaces alone.
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="City" type="text" formControlName="city" required />
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="State / Province / Region" type="text" formControlName="state" required />
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Postal Code" type="text" formControlName="postal_code" required />
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <app-country-select
        [codeCtrl]="form.get('country_code')"
        [nameCtrl]="form.get('country')"
        [required]="true"
      ></app-country-select>
    </div>
  </div>
</div>
