import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'
import { Message, MessageType } from '@tv3/store/message/message.model'
import * as R from 'ramda'
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs'
import { AddMessageComplete, DeleteMessage, MarkAsRead } from '@tv3/store/message/message.actions'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as jQuery from 'jquery'
import { Attachment, unpackSafeHtml, isSomething } from '@tokeet-frontend/tv3-platform'
import { DomSanitizer } from '@angular/platform-browser'
import { Guest } from '@tv3/store/guest/guest.model'
import { filter, map } from 'rxjs/operators'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { MessageListItemComponent } from '@tv3/containers/messages/message-list/message-list-item/message-list-item.component'
import { AddInquiryDialogParamDefaults } from '@tv3/interfaces/dialogs/add-inquiry-dialog-params'
import { toString } from 'lodash'
import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { InquiryMessageFormComponent } from '../inquiry-message-form/inquiry-message-form.component'
import { PreferencesGuard } from '@tv3/guards/preferences.guard'
import { Actions, ofType } from '@ngrx/effects'

declare const document: any

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent extends Destroyable implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('messageFormTmpl') messageFormTmpl: TemplateRef<unknown>
  @ViewChild('editor') messageEditor: InquiryMessageFormComponent
  messageFormOverlayRef: OverlayRef = this.overlay.create({ hasBackdrop: true, width: '80%' })

  _messages: Message[] = []
  @Input() set messages(msgs: Message[]) {
    this._messages = this.processMessages(R.filter((m) => m.type !== MessageType.Draft, msgs || []))
    this.draftMessage = R.find((m) => m.type === MessageType.Draft, msgs || [])
  }
  get messages(): Message[] {
    return this._messages || []
  }

  @Input() inquiry: Inquiry
  @Input() guest?: Guest
  @Input() messageId?: string
  @Input() hasHeader = false
  @Input() hasMove = true

  @Input() set emptyTableType(type: TableType) {
    if (type) {
      this.tableType = type
    }
  }

  @ViewChildren('msg') messageComponents: QueryList<MessageListItemComponent>

  searchTerm$ = new BehaviorSubject('')

  filteredMessages: Message[]

  isInlineMessageFormVisible = false
  isMessageFormPopup = false

  draftMessage: Message

  tableType = TableType.GuestEmailsList

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _elementRef: ElementRef,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private dataChecker: DataCheckerService,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    private sanitized: DomSanitizer,
    private overlay: Overlay
  ) {
    super()
    this.dataChecker.check([PreferencesGuard])
  }

  ngOnInit() {
    this.markAllAsRead(this.messages)

    // combineLatest([
    //   interval(15 * 1000).pipe(startWith(0))
    // ]).pipe(untilDestroy(this)).subscribe(() => {
    //   this.store.dispatch(GetBookingMessages({ bookingId: this.inquiry?.id }))
    // })

    this.actions.pipe(ofType(AddMessageComplete), untilDestroy(this)).subscribe((action) => {
      if (this.draftMessage) {
        this.store.dispatch(DeleteMessage({ message: this.draftMessage, inquiryId: this.inquiry.id, silent: true }))
      }
    })

    this.searchTerm$
      .pipe(
        filter((term) => R.is(String, term)),
        map((term) => term.toLowerCase()),
        untilDestroy(this)
      )
      .subscribe((term) => {
        if (R.isEmpty(term)) {
          this.filteredMessages = this.messages
        } else {
          this.filteredMessages = R.filter((m: Message) => {
            let html = (unpackSafeHtml(m.messageHtml) + '').toLowerCase()
            const guestName = (m.guestName + '').toLowerCase()
            return html.indexOf(term) !== -1 || guestName.indexOf(term) !== -1
          }, <any>this.messages)
        }
      })
  }

  ngOnChanges() {
    this.searchTerm$.next(this.searchTerm$.value)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let element = document.getElementById(this.messageId)
      if (element) {
        element.scrollIntoView()
      }
    }, 250)
  }

  onOpenInquiry() {
    let defaults = {} as AddInquiryDialogParamDefaults
    const guestId: string = R.path(['id'], this.guest)
    if (guestId) {
      defaults = {
        guestId,
      }
    }
    this.inquirySharedDialogsService.openAddInquiry({ defaults })
  }

  markAllAsRead(messages: Message[]) {
    const unreadMessageIds = R.pipe(
      R.filter((m: Message) => !m.read),
      R.map((m: Message) => m.id)
    )(messages)

    if (unreadMessageIds && unreadMessageIds.length > 0 && this.inquiry) {
      this.store.dispatch(MarkAsRead({ inquiry: this.inquiry, messageIds: unreadMessageIds }))
    }
  }

  toggleCollapsing(collapsed) {
    this.messageComponents.forEach((m) => m.toggleCollapsing(collapsed))
  }

  private processMessages(messages: Message[]): Message[] {
    const process = R.map((m: Message) => {
      if (m.isProcessed) {
        return m
      }

      const processedMessage = new Message(m)

      let messageTxt = toString(processedMessage.messageTxt).trim()
      messageTxt = messageTxt.replace(/\n/gi, '<br/>')

      let messageHtml = toString(processedMessage.messageHtml || '') || messageTxt
      messageHtml = `<div>${messageHtml.replace(/<!--(.*?)-->/gi, '')}</div>` // remove comments

      const $messageHtml = jQuery('style,link,script', messageHtml).remove().end() // remove style, link and script tag

      processedMessage.innerAttachments = jQuery('.attachments', messageHtml).length > 0
      processedMessage.sanitizedMsgHtml = <string>(
        this.sanitized.bypassSecurityTrustHtml(`<div>${$messageHtml.html()}</div>`)
      )

      // Format the Text message
      processedMessage.messageTxt = messageTxt

      try {
        processedMessage.attachments = <Attachment[]>JSON.parse(<any>processedMessage.attachments || null) || []
      } catch (e) {}

      processedMessage.isProcessed = true

      return processedMessage
    })

    const filteredMessages = R.filter((m: Message) => isSomething(m.messageTxt) || isSomething(m.attachments))

    const sort = R.sortBy((m: Message) => -m.receivedOn)

    return <any>R.pipe(filteredMessages, process, filteredMessages, sort)(<any>messages || [])
  }

  getViewRect() {
    return this._elementRef.nativeElement.getBoundingClientRect()
  }

  onPopup() {
    this.isInlineMessageFormVisible = false
    this.isMessageFormPopup = true
    const portal = new TemplatePortal(this.messageFormTmpl, this._viewContainerRef)
    const elm: HTMLElement = this._viewContainerRef.element.nativeElement
    // const rect = elm.getBoundingClientRect()

    const position = new GlobalPositionStrategy()
    position.centerHorizontally().centerVertically()
    this.messageFormOverlayRef.updatePositionStrategy(position)
    this.messageFormOverlayRef.attach(portal)

    // TV4-1948: we need to subscribe to "keydownEvents"
    // so "Overlay" doesn't trigger close on parent overlays
    this.messageFormOverlayRef.keydownEvents().subscribe()
  }

  openMessageForm() {
    this.isInlineMessageFormVisible = true
    this.isMessageFormPopup = false
  }

  closeMessageForm() {
    this.isInlineMessageFormVisible = false
    if (this.isMessageFormPopup) {
      this.messageFormOverlayRef.detach()
    }
    this.isMessageFormPopup = false
  }

  onEditDraft(msg: Message) {
    this.draftMessage = msg
    this.openMessageForm()
  }

  onAIGenerate() {
    this.openMessageForm()
    setTimeout(() => {
      this.messageEditor?.onAiResponder()
    }, 100)
  }
}
