import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { InquiryResponse } from '@tv3/interfaces/responses/inquiry-response'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { AlertDialogService, deserializeArray, isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { GuestService } from '@tv3/store/guest/guest.service'
import { Guest } from '@tv3/store/guest/guest.model'
import { EMPTY, Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import * as moment from 'moment-timezone'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { ActionFailed, HttpGetRequestCacheService } from '@tokeet-frontend/tv3-platform'
import { BookingCostResolver } from '@tokeet/cost-resolver'

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private guestService: GuestService,
    private inquiryChargeService: InquiryChargeService,
    private inquiryService: InquiryService,
    private alertDialog: AlertDialogService,
    private toaster: Toaster,
    private httpCache: HttpGetRequestCacheService
  ) {}

  upcomingArrivals(from: number, to: number) {
    const url = `@api/inquiry/all/?booked=1&arrive_from=${from}&arrive_to=${to}`

    return this.httpCache.cacheGetRequest<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      map((inquiries) => R.filter((i: Inquiry) => i.guestArrive > from && i.guestArrive < to, inquiries)),
      map((inquiries) => R.sortBy((i: Partial<Inquiry>) => i.guestArrive, inquiries)),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  upcomingDepartures(from: number, to: number) {
    const url = `@api/inquiry/all/?booked=1&arrive_to=${from}&depart_from=${from}&depart_to=${to}`

    return this.httpCache.cacheGetRequest<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      map((inquiries) =>
        R.filter((i: Inquiry) => i.guestArrive < from && i.guestDepart > from && i.guestDepart < to, inquiries)
      ),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  confirm(inquiry: Inquiry): Observable<{ inquiry: Inquiry; guest: Guest }> {
    const url = `@api/booking/${inquiry.id}`

    return this.inquiryChargeService.getCharges(inquiry).pipe(
      map((inquiry) => inquiry.charges),
      switchMap((charges) =>
        this.http.post<InquiryResponse>(url, { cost: JSON.stringify(charges) }).pipe(
          map((response) => Inquiry.deserialize(response)),
          tap(() => this.toaster.success('Booking created successfully.')),
          concatMap((response) => {
            const lastvisit = moment.utc(inquiry.guestArrive * 1000).format('MM/DD/YYYY')
            return this.guestService.update(inquiry.guestId, { lastvisit }).pipe(
              map((guest) => ({
                inquiry: response,
                guest,
              }))
            )
          })
        )
      )
    )
  }

  confirmImported(inquiry: Inquiry): Observable<Inquiry> {
    const url = `@api/booking/${inquiry.id}`

    const cost = isSomething(inquiry.bookingEngine)
      ? JSON.stringify(BookingCostResolver.convertBookingEngine2Cost(inquiry.bookingEngine))
      : inquiry.quotes

    return this.http.post<InquiryResponse>(url, { cost }).pipe(map((response) => Inquiry.deserialize(response)))
  }

  cancel(inquiry: Inquiry, byGuest?: boolean) {
    let url = `@api/booking/cancel/${inquiry.id}`

    let payload = {}
    if (byGuest) {
      payload = {
        canceled_by: 'guest',
      }
    }

    return this.http.put<InquiryResponse>(url, payload).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap(() => this.toaster.success('Booking canceled successfully.')),
      tap((inquiry) => {
        const alertSources = ['booking', 'expedia']
        const source = R.pipe(R.defaultTo(''), R.toLower)(inquiry.inquirySource)
        if (
          R.any(
            R.equals(true),
            R.map((s) => source.indexOf(s) !== -1, alertSources)
          )
        ) {
          this.alertDialog.open({
            title: 'Cancel Booking',
            body: 'You must login to your channel to cancel the reservation with the guest. Canceling in AdvanceCM will only free your availability calendar, it does not cancel the reservation on your channel.',
          })
        }
      })
    )
  }

  cancelWithReason(inquiry: Inquiry, reason: string) {
    let url = `@api/booking/cancel/${inquiry.id}`

    return this.http.put<InquiryResponse>(url, { canceled_by: reason }).pipe(
      map((response) => Inquiry.deserialize(response)),
      tap(() => this.toaster.success('Booking canceled successfully.'))
    )
  }

  invalidInquiry(inquiry: Inquiry) {
    return !inquiry.guestArrive || !inquiry.guestDepart || !inquiry.numAdults || !inquiry.rentalId
  }
}
