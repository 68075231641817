import { Component, Input, OnInit } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { NotesSidePanelService } from '@tv3/containers/inquiries/dialogs/notes/notes/notes-side-panel.service'
import { HasPermission } from 'libs/permission/src/lib/decorators/has-permission.decorator'
import { NoteResponse } from '@tv3/store/note/note.model'
import { NgxPermissionsService } from 'ngx-permissions'
import { EditNoteDialogService } from '@tv3/containers/inquiries/dialogs/notes/note/edit-note-dialog/edit-note-dialog.service'

@Component({
  selector: 'app-sidebar-notes',
  templateUrl: './sidebar-notes.component.html',
  styleUrls: ['./sidebar-notes.component.scss'],
})
export class SidebarNotesComponent implements OnInit {
  @Input() inquiry: Inquiry

  canEditNotes = this.permissions.getPermission('canEditNotes')

  constructor(
    private notesSidePanelService: NotesSidePanelService,
    private editNoteDialog: EditNoteDialogService,
    private permissions: NgxPermissionsService
  ) {}

  ngOnInit() {}

  @HasPermission('canEditNotes')
  onEdit(note: NoteResponse) {
    this.editNoteDialog.open({ inquiry: this.inquiry, note })
  }

  onAddNote() {
    this.notesSidePanelService.open(this.inquiry as Inquiry)
  }
}
