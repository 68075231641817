import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'

import { catchError, concatMap, map, toArray } from 'rxjs/operators'
import * as R from 'ramda'

import { isSomething, epochToViewUTC, Pagination } from '@tokeet-frontend/tv3-platform'
import {
  SearchIncidentParams,
  IncidentView,
  IncidentResponse,
  CreateIncidentPayload,
  UpdateIncidentPayload,
} from './model'
import * as lodash from 'lodash'

@Injectable()
export class IncidentService {
  constructor(private http: HttpClient) {}

  all() {
    const url = `@api/incident/all`
    return this.http.get<IncidentResponse[]>(url).pipe(map((responses) => this.mapIncidents(responses)))
  }

  search(searchParams?: SearchIncidentParams, pagination?: Pagination): Observable<IncidentView[]> {
    const url = `@api/incident/retrieve/`

    pagination = pagination || { skip: 0, limit: 1000 }

    const filters: {
      severity?: string
      status?: string | number
      rentals?: string | string[]
    } = {}

    if (searchParams && isSomething(searchParams)) {
      if (searchParams?.severity) {
        filters.severity = searchParams?.severity
      }
      if (searchParams?.status) {
        filters.status = searchParams?.status
      }
      if (isSomething(searchParams?.rentals)) {
        filters.rentals = searchParams?.rentals
      }
    }

    // sor by incident date
    const params = new HttpParams({
      fromObject: { skip: pagination.skip + '', limit: pagination.limit + '', sort: 'date', direction: '-1' },
    })

    return this.http
      .post<IncidentResponse[]>(url, { ...filters }, { params })
      .pipe(map((responses) => this.mapIncidents(responses)))
  }

  get(id: string): Observable<IncidentView> {
    const url = `@api/incident/${id}`

    return this.http.get<IncidentResponse>(url).pipe(map((response) => this.buildIncident(response)))
  }

  getByIds(ids: string[]): Observable<IncidentView[]> {
    return of(...ids).pipe(
      concatMap((id) => this.get(id).pipe(catchError(() => of(null)))),
      toArray(),
      map((items) => items.filter((t) => !lodash.isNil(t)) as IncidentView[])
    )
  }

  create(request: CreateIncidentPayload): Observable<IncidentView> {
    const url = '@api/incident/'

    return this.http.post<IncidentResponse>(url, request).pipe(map((response) => this.buildIncident(response)))
  }

  update(incidentId: string, payload: UpdateIncidentPayload): Observable<IncidentView> {
    const url = `@api/incident/${incidentId}`

    return this.http.put<IncidentResponse>(url, payload).pipe(map((response) => this.buildIncident(response)))
  }

  delete(incidentId: string) {
    const url = `@api/incident/delete/${incidentId}`

    return this.http.delete<IncidentResponse>(url)
  }

  deleteIncidents(incidentIds: string[]) {
    return of(...incidentIds).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }

  private mapIncidents(incidents: IncidentResponse[]): IncidentView[] {
    return R.map((r: IncidentResponse) => this.buildIncident(r), incidents)
  }

  private buildIncident(incident: IncidentResponse): IncidentView {
    return {
      ...incident,
      createdView: epochToViewUTC(incident.created),
    } as IncidentView
  }
}
