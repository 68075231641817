import { createSelector, Selector } from '@ngrx/store'
import { selectCustomChannelAsSources } from '@tokeet-frontend/channels'
import {
  Channel,
  ChannelNameTokens,
  selectAllApiChannelViewsWithout,
  selectAllICalChannelViews,
  selectFormChannels,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { selectConnectionViewsByChannel } from '../connection/connection.selectors'
import { State } from '../state'

export const selectBookingSourcesWithCustomChannels = (custom: { inquirySource: string }, includeSource?: boolean) =>
  createSelector(
    selectFormChannels(custom, includeSource),
    selectCustomChannelAsSources,
    (channels: { id: string; name: string; label: string }[], customChannels) => {
      return lodash.sortBy([...channels, ...customChannels], (t) => lodash.toLower(t.label))
    }
  )

export const selectChannelsWithCustomChannels = (
  selector: Selector<State, Channel[]>,
  field: 'name' | 'token' = 'name',
  additionalChannels: Channel[] = []
) =>
  createSelector(
    selector,
    selectCustomChannelAsSources,
    (channels, customChannels): { id: string; name: string; label: string; channelId: string; custom: boolean }[] => {
      const items = [...channels, ...additionalChannels].map((c) => ({
        id: lodash.get(c, field),
        name: lodash.get(c, field),
        label: c.friendlyName,
        channelId: c.id,
        custom: false,
      }))
      return lodash.sortBy([...items, ...customChannels], (t) => lodash.toLower(t.label))
    }
  )

export const selectChannelsWithAdditionalChannels = (
  selector: Selector<State, Channel[]>,
  field: 'name' | 'token' = 'name',
  additionalChannels: Channel[] = []
) =>
  createSelector(
    selector,
    (channels): { id: string; name: string; label: string; channelId: string; custom: boolean }[] => {
      const items = [...channels, ...additionalChannels].map((c) => ({
        id: lodash.get(c, field),
        name: lodash.get(c, field),
        label: c.friendlyName,
        channelId: c.id,
        custom: false,
      }))
      return lodash.sortBy(items, (t) => lodash.toLower(t.label))
    }
  )

export const selectConnectedAPIChannels = (connected: boolean | null | undefined) =>
  createSelector(
    selectAllApiChannelViewsWithout([ChannelNameTokens.Webready]),
    selectConnectionViewsByChannel,
    (channels, itemsByChannelId) => {
      return channels.filter((c) => {
        if (!lodash.isBoolean(connected)) {
          return true
        }
        const items = itemsByChannelId[c.id] || []
        return connected ? items.length > 0 : !items.length
      })
    }
  )

export const selectConnectedICALChannels = (connected: boolean | null | undefined) =>
  createSelector(selectAllICalChannelViews, selectConnectionViewsByChannel, (channels, itemsByChannelId) => {
    return channels.filter((c) => {
      if (!lodash.isBoolean(connected)) {
        return true
      }
      const items = itemsByChannelId[c.id] || []
      return connected ? items.length > 0 : !items.length
    })
  })
