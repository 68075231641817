import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  ApiConnectionActivityLog,
  Connection,
  ImportedConnectionActivityLog,
} from '@tv3/store/connection/connection.model'
import { forkJoin, Observable, of } from 'rxjs'
import { ChannelNameTokens, deserializeArray, isSomething } from '@tokeet-frontend/tv3-platform'
import { filter, map } from 'rxjs/operators'
import * as R from 'ramda'
import {
  AddExportedCalendarConnectionPayload,
  AddImportedCalendarConnectionPayload,
  ApiConnectionStatus,
  ChannelRoomRateResponse,
  CreateChannelListingPayload,
  GetChannelRoomRatesPayload,
  LinkConnectionWithRentalPayload,
  PushRatesToApiConnectionPayload,
  RefreshImportedCalendarConnectionPayload,
  UnlinkConnectionWithRentalPayload,
} from '@tv3/store/connection/connection.types'
import * as lodash from 'lodash'
import {
  HoliduChannelService,
  HVMIChannelService,
  RentalsUnitedChannelService,
  TiketChannelService,
  GVRChannelService,
  CtripChannelService,
} from '@tokeet-frontend/channels'

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(
    private http: HttpClient,
    private gvrChannelService: GVRChannelService,
    private holidu: HoliduChannelService,
    private hvmi: HVMIChannelService,
    private rentalsUnited: RentalsUnitedChannelService,
    private tiketService: TiketChannelService,
    private ctripService: CtripChannelService
  ) {}

  static getBackendURLIdentityForChannel(channelName: ChannelNameTokens, includeNew = false) {
    let url = ''
    switch (channelName) {
      case ChannelNameTokens.ExpediaAPI:
        url = 'expedia'
        break
      case ChannelNameTokens.BookingAPI:
        url = 'bdc'
        break
      case ChannelNameTokens.AirBnBV2API:
        url = 'abbv2'
        break
      case ChannelNameTokens.AirBnBAPI:
        url = 'abb'
        break
      case ChannelNameTokens.AgodaAPI:
        url = 'agoda'
        break
      case ChannelNameTokens.HomeToGo:
        url = 'hometogo'
        break
      case ChannelNameTokens.Homeaway:
        url = 'homeaway'
        break
    }

    if (includeNew) {
      switch (channelName) {
        case ChannelNameTokens.Holidu:
          url = 'holidu'
          break
        case ChannelNameTokens.Hvmi:
          url = 'hvmi'
          break
        case ChannelNameTokens.RentalsUnited:
          url = 'rentalsunited'
          break
        case ChannelNameTokens.Tiket:
          url = 'tiket'
          break
        case ChannelNameTokens.Trip:
          url = 'ctrip'
          break
        case ChannelNameTokens.GoogleVacationRentals:
          url = 'gvr'
          break
      }
    }

    return url
  }

  getCM2Connections() {
    return this.http.get<{ listings: object[]; errors: any[] }>(`@api/channel/cm2/listings`).pipe(
      map((res) => res.listings),
      deserializeArray<Connection>(Connection)
    )
  }

  getCM1Connections() {
    return this.http.get<object[]>('@api/channel/all/').pipe(
      map((channels) =>
        R.reject(
          (c: any) =>
            R.isNil(c.channel_id) &&
            R.isNil(c.propertyName) &&
            R.isNil(c.propertyId) &&
            R.isNil(c.rental_id) &&
            R.isNil(c.linkDate) &&
            R.isNil(c.name),
          channels
        )
      ),
      map((response) =>
        R.map((r: any) => {
          r.legacyId = r.id
          delete r.id
          return r
        }, response)
      ),
      deserializeArray<Connection>(Connection)
    )
  }

  all(): Observable<Connection[]> {
    return forkJoin([this.getCM1Connections(), this.getCM2Connections()]).pipe(map((data) => R.flatten(data)))
  }

  get(channelName: ChannelNameTokens, roomId: any, propertyId: any) {
    let observer = of(null)
    if (channelName === ChannelNameTokens.RentalsUnited) {
      observer = this.rentalsUnited.getConnection(propertyId)
    }

    return observer.pipe(
      filter(isSomething),
      map((data) => Connection.deserialize(data))
    )
  }

  allByChannel(channelName: ChannelNameTokens, channelId: string): Observable<Connection[]> {
    if (channelName === ChannelNameTokens.Holidu) {
      return this.holidu.getConnections().pipe(deserializeArray<Connection>(Connection))
    } else if (channelName === ChannelNameTokens.Hvmi) {
      return this.hvmi.getConnections().pipe(deserializeArray<Connection>(Connection))
    } else if (channelName === ChannelNameTokens.RentalsUnited) {
      return this.rentalsUnited.getConnections().pipe(deserializeArray<Connection>(Connection))
    } else if (channelName === ChannelNameTokens.GoogleVacationRentals) {
      return this.gvrChannelService.getConnections().pipe(deserializeArray<Connection>(Connection))
    }

    let url = ''
    const channelPath = ConnectionService.getBackendURLIdentityForChannel(channelName)

    if (channelName === ChannelNameTokens.Tiket) {
      return this.tiketService.getConnections().pipe(deserializeArray<Connection>(Connection))
    } else if (channelName === ChannelNameTokens.Trip) {
      return this.ctripService.getConnections().pipe(deserializeArray<Connection>(Connection))
    } else if (channelPath) {
      url = `@api/channel/all?channel_id=${channelId}`
      return this.http.get(url).pipe(deserializeArray<Connection>(Connection))
    }

    return of([])
  }

  allByRental(rentalId: string): Observable<Connection[]> {
    const url = `@api/channel/rental/api/${rentalId}`

    return this.http.get(url).pipe(deserializeArray<Connection>(Connection))
  }

  refreshForChannel(channelName: ChannelNameTokens, channelId: string) {
    if (channelName === ChannelNameTokens.Tiket) {
      return this.tiketService.getConnections()
    } else if (channelName === ChannelNameTokens.Trip) {
      return this.ctripService.getConnections()
    }

    const channelPath = ConnectionService.getBackendURLIdentityForChannel(channelName)
    if (!channelPath) {
      return of([])
    }

    const url = `@api/${channelPath}/refresh/rooms/${channelId}`
    return this.http.get(url)
  }

  getExportedCalendarsByChannelName(channelName: string): Observable<Connection[]> {
    const url = `@api/channel/exported/all/`
    return this.http.get<object[]>(url).pipe(
      map((res) =>
        R.filter((item: any) => {
          const name = (item.name + '').toLowerCase()
          return name.indexOf((channelName + '').toLowerCase()) > -1
        }, res)
      ),
      deserializeArray<Connection>(Connection)
    )
  }

  addImportedCalendarConnection(payload: AddImportedCalendarConnectionPayload): Observable<Connection> {
    const url = `@api/calendar/import`

    return this.http.put(url, payload).pipe(map((res) => Connection.deserialize(res)))
  }

  addExportedCalendarConnection(payload: AddExportedCalendarConnectionPayload): Observable<Connection> {
    const url = `@api/channel/`

    return this.http.post(url, payload).pipe(map((res) => Connection.deserialize(res)))
  }

  createListing(channelName: ChannelNameTokens, payload: CreateChannelListingPayload) {
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/publish/create/`
    return this.http.post(url, payload)
  }

  link(payload: LinkConnectionWithRentalPayload) {
    const url = '@api/channel/rental/link'
    return this.http.put(url, payload)
  }

  linkABBV1(payload: LinkConnectionWithRentalPayload) {
    const url = '@api/abb/rental/link'
    return this.http.put(url, payload)
  }

  linkABBV2(payload: LinkConnectionWithRentalPayload) {
    const url = '@api/abbv2/rental/link'
    return this.http.put(url, payload)
  }

  unlink(payload: UnlinkConnectionWithRentalPayload) {
    const url = '@api/channel/rental/unlink'
    return this.http.put(url, payload)
  }

  unlinkABB(payload: UnlinkConnectionWithRentalPayload) {
    const url = '@api/abb/rental/unlink'
    return this.http.put(url, payload)
  }

  unlinkABBV2(payload: UnlinkConnectionWithRentalPayload) {
    const url = '@api/abbv2/rental/unlink'
    return this.http.put(url, payload)
  }

  importBookingByInquiryIdABBV2(channelId: string, connection: Connection, inquiryId: string) {
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(
      ChannelNameTokens.AirBnBV2API
    )}/import/old/bookings/${channelId}`
    connection = lodash.merge(new Connection(), connection) // ensure we can call serialize
    return this.http.post(url, { ...connection.serialize(), conf_code: inquiryId })
  }

  importBookings(channelName: ChannelNameTokens, channelId: string, connection: Connection) {
    let url = ''
    switch (channelName) {
      case ChannelNameTokens.ExpediaAPI:
        url = `@api/expedia/import/bookings/${channelId}`
        break
      default:
        url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/import/old/bookings/${channelId}`
        break
    }
    connection = lodash.merge(new Connection(), connection) // ensure we can call serialize
    return this.http.post(url, connection.serialize())
  }

  pushAvailability(channelName: ChannelNameTokens, channelId: string, connection: Connection) {
    if (channelName === ChannelNameTokens.GoogleVacationRentals) {
      return this.gvrChannelService.pushAvailability(connection.roomId as string)
    }
    if (channelName === ChannelNameTokens.Tiket) {
      return this.tiketService.pusAvailability(connection.roomId as number, {
        hotel_id: connection.propertyId as number,
        rental_id: connection.rentalId,
      })
    } else if (channelName === ChannelNameTokens.Trip) {
      return this.ctripService.pusAvailability(connection.roomId as number, {
        hotel_id: connection.propertyId as number,
        rental_id: connection.rentalId,
      })
    }
    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/push/availability/${channelId}`
    connection = lodash.merge(new Connection(), connection) // ensure we can call serialize
    return this.http.post(url, connection.serialize())
  }

  pushRates(channelName: ChannelNameTokens, channelId: string, payload: PushRatesToApiConnectionPayload) {
    if (channelName === ChannelNameTokens.GoogleVacationRentals) {
      return this.gvrChannelService.pushRates(payload.roomId as string)
    }
    if (channelName === ChannelNameTokens.Tiket) {
      return this.tiketService.pushRates(payload.roomId as number, {
        rental_id: payload.rental_id,
        hotel_id: payload.propertyId as number,
        rate_plan_code: lodash.toNumber(payload.rateid),
        category: payload.category,
      })
    } else if (channelName === ChannelNameTokens.Trip) {
      return this.ctripService.pushRates(payload.roomId as number, {
        rental_id: payload.rental_id,
        hotel_id: payload.propertyId as number,
        rate_plan_code: lodash.toNumber(payload.rateid),
        category: payload.category,
      })
    }

    const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/push/rates/${channelId}`
    return this.http.post(url, payload)
  }

  getRoomRatesFromConnection(
    channelName: ChannelNameTokens,
    channelId: string,
    payload: GetChannelRoomRatesPayload
  ): Observable<ChannelRoomRateResponse[]> {
    if (channelName === ChannelNameTokens.Tiket) {
      return this.tiketService.getRoomRatePlans(payload.propertyId).pipe(
        map((items) =>
          items.map(
            (item) =>
              ({
                id: lodash.toString(item.code),
                name: item.name,
              } as ChannelRoomRateResponse)
          )
        )
      )
    } else if (channelName === ChannelNameTokens.Trip) {
      return this.ctripService.getRoomRatePlans(payload.propertyId).pipe(
        map((items) =>
          items.map(
            (item) =>
              ({
                id: lodash.toString(item.code),
                name: item.name,
              } as ChannelRoomRateResponse)
          )
        )
      )
    } else {
      const url = `@api/${ConnectionService.getBackendURLIdentityForChannel(channelName)}/roomrates/${channelId}`
      return this.http.post<ChannelRoomRateResponse[]>(url, payload)
    }
  }

  getApiConnectionLog(
    channelName: ChannelNameTokens,
    propertyId: string | number,
    roomId?: string | number
  ): Observable<ApiConnectionActivityLog[]> {
    const path = ConnectionService.getBackendURLIdentityForChannel(channelName, true)
    if (!path) {
      return of([])
    }
    const url = `@api/${path}/activity/log/${propertyId}?${!!roomId ? `roomid=${roomId}` : ''}`
    return this.http.get(url).pipe(deserializeArray<ApiConnectionActivityLog>(ApiConnectionActivityLog))
  }

  getApiConnectionStatus(
    channelName: ChannelNameTokens,
    propertyId: string | number,
    roomId: string | number
  ): Observable<ApiConnectionStatus> {
    const path = ConnectionService.getBackendURLIdentityForChannel(channelName)
    if (!path) {
      return of({})
    }
    const url = `@api/${path}/update/log/${propertyId}?roomid=${roomId}`
    return this.http.get<ApiConnectionStatus>(url)
  }

  // This method deletes a connected iCal rental from the AdvanceCM API, not the Channel Manager.
  deleteExportedCalendar(id: string) {
    const url = `@api/channel/delete/${id}`
    return this.http.delete(url)
  }

  getImportedConnectionLog(connectionId: string): Observable<ImportedConnectionActivityLog[]> {
    const url = `@api/calendar/import/logs/${connectionId}`
    return this.http.get(url).pipe(deserializeArray<ImportedConnectionActivityLog>(ImportedConnectionActivityLog))
  }

  refreshImportedCalendar(payload: RefreshImportedCalendarConnectionPayload): Observable<Connection> {
    const url = `@api/calendar/import`
    return this.http.put(url, payload).pipe(map((res) => Connection.deserialize(res)))
  }

  deleteImportedCalendar(calendarId: string, rentalId: string, removeAllEvents: boolean) {
    const url = `@api/calendar/import/delete/${rentalId}/${calendarId}?all=${removeAllEvents ? 1 : ''}`
    return this.http.delete(url)
  }

  getConnectionsStatuses(propertyIds: (string | number)[], roomIds: (string | number)[]) {
    const url = '@api/channel/connection/status/'
    return this.http.post(url, {
      properties: propertyIds,
      rooms: roomIds,
    })
  }
}
