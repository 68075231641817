<div class="modal-header">
  <h5 class="modal-title">
    <i *ngIf="data.icon" [class]="data.icon"></i>
    {{ data.label }}
  </h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body pb-0">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12">
        <div class="primary-item mb-3">
          Primary {{ data.itemLabel }}: <strong>{{ data.primaryItem() }}</strong>
        </div>
      </div>

      <table class="table mb-0">
        <thead>
          <tr>
            <th scope="col">{{ data.itemLabel }}</th>
            <th scope="col" class="fit">Mark as Primary</th>
            <th scope="col" class="fit">Remove</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of data.items(); let i = index">
            <tr>
              <td>
                <mat-form-field2 class="mat-block" [showLabel]="false">
                  <input type="text" matInput value="{{ item }}" placeholder="{{ data.itemLabel }}" readonly />
                </mat-form-field2>
              </td>
              <td class="text-center">
                <button
                  (click)="makePrimaryOldItem(i)"
                  [matTooltip]="item == data.primaryItem() ? '' : data.makePrimaryLabel"
                  matTooltipPosition="above"
                  type="button"
                  class="btn btn-light position-relative"
                  [disabled]="item == data.primaryItem()"
                >
                  <i [class]="item == data.primaryItem() ? 'fas text-primary' : 'far'" class="fa-thumbtack"></i>
                  <span *ngIf="item == data.primaryItem()" class="suffix-primary text-xs"> Primary </span>
                </button>
              </td>
              <td class="text-center">
                <button (click)="removeOldItem(i)" class="btn btn-light-danger" type="button">
                  <i class="far fa-minus-circle"></i>
                </button>
              </td>
            </tr>
          </ng-container>
          <ng-container formArrayName="newItems">
            <tr *ngFor="let item of newItems.controls; let i = index">
              <td [formGroupName]="i">
                <mat-form-field2
                  class="mat-block"
                  [class]="item.get('formItem').touched && item.get('formItem').hasError('email') ? 'mb-2' : ''"
                  [showLabel]="false"
                >
                  <input type="text" matInput formControlName="formItem" placeholder="{{ data.itemLabel }}" />
                  <mat-error *ngIf="item.get('formItem').touched && item.get('formItem').hasError('email')">
                    Email must have valid format
                  </mat-error>
                </mat-form-field2>
              </td>
              <td class="text-center">
                <button
                  [matTooltip]="
                    item.get('formItem').invalid || !item.get('formItem').value
                      ? 'Enter valid value'
                      : data.makePrimaryLabel
                  "
                  [disabled]="item.get('formItem').invalid || !item.get('formItem').value"
                  (click)="makePrimaryNewItem(i)"
                  matTooltipPosition="above"
                  type="button"
                  class="btn btn-light position-relative"
                >
                  <i class="far fa-thumbtack"></i>
                </button>
              </td>
              <td class="text-center">
                <button (click)="removeNewItem(i)" class="btn btn-light-danger" type="button">
                  <i class="far fa-minus-circle"></i>
                </button>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="3">
              <button (click)="addNewItem()" class="btn btn-light-primary" type="button">
                <i class="far fa-plus"></i>
                {{ data.newItemLabel }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
