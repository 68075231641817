import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ChannelPropertySetting, ChannelPropertySettingPayload } from './channel-property-setting.model'

import * as lodash from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class ChannelPropertySettingService {
  constructor(private http: HttpClient) {}

  all(): Observable<ChannelPropertySetting[]> {
    const url = '@api/channelproperties/all'
    return this.http
      .get<any[]>(url)
      .pipe(map((items) => lodash.map(items, (item) => ChannelPropertySetting.deserialize(item))))
  }

  create(payload: ChannelPropertySettingPayload): Observable<ChannelPropertySetting> {
    const url = '@api/channelproperties'

    return this.http.post(url, payload).pipe(map((response) => ChannelPropertySetting.deserialize(response)))
  }

  get(id: string): Observable<ChannelPropertySetting> {
    const url = `@api/channelproperties/${id}`

    return this.http.get<object>(url).pipe(map((response) => ChannelPropertySetting.deserialize(response)))
  }

  update(id: string, payload: ChannelPropertySettingPayload): Observable<ChannelPropertySetting> {
    const url = `@api/channelproperties/${id}`

    return this.http.put(url, payload).pipe(map((response) => ChannelPropertySetting.deserialize(response)))
  }

  delete(id: string): Observable<any> {
    const url = `@api/channelproperties/${id}`
    return this.http.delete(url)
  }
}
