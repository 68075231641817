import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { AuthService } from '@tv3/services/auth.service'
import { addAttachmentsComplete, Attachment } from '@tokeet-frontend/tv3-platform'
import { UploadBoxOptions } from '@tokeet-frontend/tv3-platform'

export interface UploadFileOptions extends UploadBoxOptions {
  title?: string
}

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent {
  options: UploadFileOptions = {
    title: 'Upload files',
    maxSize: 10 * 1024 * 1024, // 10MB,
    maxUploads: 100,
    cloudinary: false,
    token: this.authService.token,
  }

  constructor(
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private store: Store<fromRoot.State>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { options: UploadFileOptions },
    protected toast: Toaster
  ) {}

  close() {
    this.dialogRef.close()
  }

  onUploaded(uploadedFileResults: any[]) {
    if (uploadedFileResults.length) {
      const attachments = R.map(Attachment.deserialize, uploadedFileResults)
      if (!this.options.cloudinary) {
        this.store.dispatch(addAttachmentsComplete({ attachments }))
      }
      this.toast.success('File(s) uploaded successfully.')
      this.dialogRef.close(attachments)
    }
  }
}
