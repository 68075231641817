<div class="container-box details-box overflow-hidden">
  <div class="py-4 px-3">
    <h6 class="mb-0">Connection Status</h6>
  </div>
  <!-- Connection Status table -->
  <div class="table-holder table-responsive">
    <table class="table mat-table mb-0">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell">Status</th>
          <th class="mat-header-cell" *ngIf="availabilityPull">Last Availability Pull</th>
          <th class="mat-header-cell" *ngIf="availabilityPush">Last Availability Push</th>
          <th class="mat-header-cell" *ngIf="ratesPush">Last Rate Push</th>
        </tr>
      </thead>
      <tbody>
        <tr class="mat-row">
          <td class="mat-cell">{{ connectionStatus?.status }}</td>
          <td class="mat-cell" *ngIf="availabilityPull">{{ connectionStatus?.lastavailpull | epoch: dateFormat }}</td>
          <td class="mat-cell" *ngIf="availabilityPush">{{ connectionStatus?.lastavailpush | epoch: dateFormat }}</td>
          <td class="mat-cell" *ngIf="ratesPush">{{ connectionStatus?.lastratepush | epoch: dateFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
