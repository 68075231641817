import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-push-rates-overlay',
  templateUrl: './push-rates-overlay.component.html',
  styleUrls: ['./push-rates-overlay.component.scss'],
})
export class PushRatesOverlayComponent {
  constructor(private dialogRef: MatDialogRef<PushRatesOverlayComponent>) {}

  close() {
    this.dialogRef.close()
  }
}
