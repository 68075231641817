import { on } from '@ngrx/store'
import { State } from '../channels.state'
import {
  GetHoliduStatusSuccess,
  ConnectHoliduSuccess,
  GetHoliduRentalValidationsSuccess,
  CreateHoliduListingSuccess,
  UpdateHoliduListingSuccess,
  DeleteHoliduListingSuccess,
  GetHoliduListingsSuccess,
  DisconnectHoliduSuccess,
} from './actions'

export function getHoliduReducers() {
  return [
    on(GetHoliduStatusSuccess, (state: State, { status }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        status,
      },
    })),
    on(ConnectHoliduSuccess, (state: State, { status, listings }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        status,
        listings: listings || state.holidu.listings || [],
      },
    })),
    on(GetHoliduRentalValidationsSuccess, (state: State, { validations }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        validations,
        validationsLoaded: true,
      },
    })),
    on(CreateHoliduListingSuccess, UpdateHoliduListingSuccess, (state: State, { listing }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        listings: state.holidu.listings.map((l) =>
          l.providerApartmentId === listing.providerApartmentId ? listing : l
        ),
      },
    })),
    on(DeleteHoliduListingSuccess, (state: State, { propertyId }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        listings: state.holidu.listings.map((l) =>
          l.providerApartmentId === propertyId ? { ...l, active: false, holiduApartmentId: null } : l
        ),
      },
    })),
    on(GetHoliduListingsSuccess, (state: State, { listings }) => ({
      ...state,
      holidu: {
        ...state.holidu,
        listings,
      },
    })),
    on(DisconnectHoliduSuccess, (state: State) => {
      return {
        ...state,
        holidu: {
          ...state.holidu,
          status: {
            ...state.holidu.status,
            providerId: null,
            connected: false,
          },
        },
      }
    }),
  ]
}
