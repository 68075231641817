import { AccountStoreModule, AccountService } from './account'
import { ApiKeyStoreModule, ApiKeysService } from './api-keys'
import { AttachmentStoreModule, AttachmentService } from './attachment'
import { ChannelStoreModule, ChannelService } from './channel'
import { ChannelAdjustmentStoreModule } from './channel-adjustment'
import { ExpenseStoreModule } from './expense'
import {
  CleanGroupRateService,
  GroupRateService,
  PromotionalRateService,
  RateStoreModule,
  RateService,
  RateSplitterService,
} from './rate'
import { RentalStoreModule } from './rental'
import { TagStoreModule, TagService } from './tag'
import { UserStoreModule, UserService } from './user'
import { UtilityStoreModule } from './utility'

export * from './account'
export * from './api-keys'
export * from './attachment'
export * from './channel'
export * from './expense'
export * from './rate'
export * from './rental'
export * from './tag'
export * from './user'
export * from './trigger'
export * from './utility'
export * from './channel-adjustment'

export const storeServices = [
  //
  AccountService,
  ApiKeysService,
  AttachmentService,
  ChannelService,
  CleanGroupRateService,
  GroupRateService,
  PromotionalRateService,
  RateSplitterService,
  RateService,
  TagService,
  UserService,
]

export const storeModules = [
  AccountStoreModule,
  ApiKeyStoreModule,
  AttachmentStoreModule,
  ChannelStoreModule,
  ExpenseStoreModule,
  RateStoreModule,
  RentalStoreModule,
  TagStoreModule,
  UserStoreModule,
  ChannelAdjustmentStoreModule,
  UtilityStoreModule,
]
