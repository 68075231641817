export function compactObject(obj) {
  const propNames = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i]
    if (typeof obj[propName] === 'object' && obj[propName] !== null) {
      compactObject(obj[propName])
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName]
      }
    } else if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}
