import { Injectable } from '@angular/core'
import { concat, of, Observable } from 'rxjs'
import { map, switchMap, toArray } from 'rxjs/operators'
import * as lodash from 'lodash'
import { GroupRateService } from './group-rate.service'
import { RateService } from './rate.service'
import { Rate } from './rate.model'
import { ConfirmDialogService } from '../../dialogs/confirm/confirm-dialog.service'
import { RentalService } from '../rental/rental.service'
import { toMoment } from '../../functions'
import { ConfirmDialogStatus } from '../../dialogs/confirm/confirm-dialog.interfaces'

export type PreprocessRate = (r1: Rate, r2: Rate) => { rate: Rate; add: Rate }
export type CheckSkipRate = (r1: Rate, r2: Rate) => boolean

export interface SplitRateOptions {
  skip?: CheckSkipRate
  preprocess?: PreprocessRate
}

export type SplitRates = Record<'add' | 'update' | 'delete' | 'skip', Rate[]>
export type RateOperation = (rate: Rate) => Observable<Rate>

@Injectable()
export class RateSplitterService {
  constructor(
    private confirmDialog: ConfirmDialogService,
    private rentalService: RentalService,
    private rateService: RateService,
    private groupRateService: GroupRateService
  ) {}

  saveGroupRate(rate: Rate) {
    return this.groupRateService.all().pipe(
      map((rates: any) => {
        return lodash.map(rates, (r) => {
          r.start = toMoment(r.start).startOf('date').unix()
          r.end = toMoment(r.end).endOf('date').unix()

          return r
        })
      }),
      switchMap((rates) => {
        const { splitRates, overlap } = this.splitRates(rate, rates, {
          skip: this.checkSkipGroupRate,
          preprocess: this.preprocessGroupRate,
        })

        if (!overlap) return of(splitRates)

        return this.confirmDialog
          .openRegular({
            title: 'Overlapping with existing group rates',
            body: `
            Your new rate overlaps with existing group rates assigned to one or more rentals.
            If you wish to proceed, AdvanceCM will automatically update your existing rates so they do not overlap your new rates.
            In other words, your new rate will take precedence.
          `,
          })
          .pipe(map((status) => (status === ConfirmDialogStatus.Confirmed ? splitRates : null)))
      }),
      switchMap((splitRates) => {
        if (!splitRates) return of(null)

        return this.saveRates(rate, splitRates, {
          add: (r) => this.groupRateService.addRate(r),
          update: (r) => this.groupRateService.updateRate(r.rateId, r),
          delete: (r) => this.groupRateService.deleteRate(r.rateId).pipe(map(() => null as Rate)),
        }).pipe(map((result) => ({ groupRate: result.rate, groupRates: result.rates })))
      })
    )
  }

  saveRentalRate(rate: Rate, rentalId: string = null) {
    rentalId = rentalId || rate.rentalId

    return this.rentalService.get(rentalId).pipe(
      map((rental) => {
        return lodash.map(rental.rates, (r) => {
          r.start = toMoment(r.start).startOf('date').unix()
          r.end = toMoment(r.end).endOf('date').unix()

          return r
        })
      }),
      switchMap((rates) => {
        const { splitRates } = this.splitRates(rate, rates)

        return this.saveRates(rate, splitRates, {
          add: (r) => this.rateService.addRate(rentalId, r),
          update: (r) => this.rateService.updateRate(rentalId, r.rateId, r),
          delete: (r) => this.rateService.deleteRate(rentalId, r.rateId).pipe(map(() => null as Rate)),
        })
      })
    )
  }

  private saveRates(
    rate: Rate,
    splitRates: SplitRates,
    operations: Record<'add' | 'update' | 'delete', RateOperation>
  ) {
    return concat(
      ...splitRates.skip.map((r) => of(r)),
      ...splitRates.update.map((r) => operations.update(r)),
      ...splitRates.delete.map((r) => operations.delete(r)),
      ...splitRates.add.map((r) => operations.add(r)),
      rate.rateId ? operations.update(rate) : operations.add(rate)
    ).pipe(
      toArray(),
      map((rates) => {
        return { rate: lodash.last(rates), rates: lodash.compact(rates) }
      })
    )
  }

  private checkSkipGroupRate(rate: Rate, compareRate: Rate) {
    const commonRentals = lodash.intersection(rate.rentalIds, compareRate.rentalIds)
    return commonRentals.length === 0
  }

  private preprocessGroupRate(rate: Rate, compareRate: Rate) {
    const start1 = toMoment(rate.start)
    const end1 = toMoment(rate.end)
    const start2 = toMoment(compareRate.start)
    const end2 = toMoment(compareRate.end)

    const overlap =
      (start1.isSameOrAfter(start2, 'date') && start1.isSameOrBefore(end2, 'date')) ||
      (end1.isSameOrAfter(start2, 'date') && end1.isSameOrBefore(end2, 'date')) ||
      (start1.isSameOrBefore(start2, 'date') && end1.isSameOrAfter(end2, 'date'))

    if (!overlap) return null

    const commonRentals = lodash.intersection(rate.rentalIds, compareRate.rentalIds)
    if (!commonRentals.length) return null

    const excludeRentals = lodash.difference(rate.rentalIds, commonRentals)
    if (!excludeRentals.length) return null

    const newRate = rate.clone({
      groupRateId: null,
      rentalIds: excludeRentals,
      name: rate.name + ' 1',
    })

    rate = rate.clone({
      rentalIds: commonRentals,
      name: rate.name + ' 2',
    })

    return { rate, add: newRate }
  }

  private splitRates(rate: Rate, rates: Rate[], options: SplitRateOptions = {}) {
    const splitRates: SplitRates = {
      add: [],
      update: [],
      delete: [],
      skip: [],
    }

    if (rate.rateId) {
      rates = rates.filter((r) => r.rateId !== rate.rateId)
    }

    rates.forEach((r) => {
      if (lodash.toLower(r.category) !== lodash.toLower(rate.category)) {
        splitRates.skip.push(r)
        return
      }

      if (options.skip && options.skip(r, rate)) {
        splitRates.skip.push(r)
        return
      }

      let newRate: Rate = null

      if (options.preprocess) {
        const result = options.preprocess(r, rate)

        if (result && result.add) {
          newRate = result.add || null
        }

        if (result && result.rate) {
          r = result.rate
        }
      }

      if (r.start >= rate.start && r.end <= rate.end) {
        splitRates.delete.push(r)
      } else if (r.start < rate.start && r.end >= rate.start && r.end <= rate.end) {
        splitRates.update.push(
          r.clone({
            end: toMoment(rate.start).subtract(1, 'day').endOf('day').unix(),
          })
        )
      } else if (r.start >= rate.start && r.start <= rate.end && r.end > rate.end) {
        splitRates.update.push(
          r.clone({
            start: toMoment(rate.end).add(1, 'day').startOf('day').unix(),
          })
        )
      } else if (r.start < rate.start && r.end > rate.end) {
        splitRates.update.push(
          r.clone({
            name: `${r.name} - split 1`,
            end: toMoment(rate.start).subtract(1, 'day').endOf('day').unix(),
          })
        )

        splitRates.add.push(
          r.clone({
            rentalRateId: null,
            groupRateId: null,

            name: `${r.name} - split 2`,
            start: toMoment(rate.end).add(1, 'day').startOf('day').unix(),
          })
        )
      } else {
        splitRates.skip.push(r)
        newRate = null
      }

      if (newRate) {
        splitRates.add.push(newRate)
      }
    })

    const overlap = splitRates.skip.length !== rates.length

    return { splitRates, overlap }
  }
}
