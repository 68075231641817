<form [formGroup]="form" [formEditablePermission]="form">
  <h6 class="">Notes</h6>

  <div class="card p-3">
    <mat-form-field2 class="mat-block">
      <textarea
        matInput
        rows="5"
        placeholder="Payment Terms(visible to guest on invoice)"
        formControlName="paymentTerms"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field2>
  </div>
  <div class="d-flex gap-3 mt-3">
    <div class="card p-3 flex-1">
      <mat-form-field2 class="mat-block">
        <textarea
          matInput
          rows="5"
          placeholder="Payment Instructions(visible to guest on invoice)"
          formControlName="paymentInstructions"
          cdkTextareaAutosize
        ></textarea>
      </mat-form-field2>
    </div>

    <div class="card p-3 flex-1">
      <mat-form-field2 class="mat-block">
        <textarea
          matInput
          rows="5"
          placeholder="Notes(visible to guest on invoice)"
          formControlName="notes"
          cdkTextareaAutosize
        ></textarea>
      </mat-form-field2>
    </div>
  </div>
</form>
