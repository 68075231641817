import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { MinStayAutomationsComponent } from './automations/min-stay-automations.component'
import { MinStayAutomationDialogComponent } from './automation-dialog/min-stay-automation-dialog.component'
import { MinStayAutomationsOverlayComponent } from './automations-overlay/min-stay-automations-overlay.component'
import { MinStayAutomationsGridComponent } from './grid/min-stay-automations-grid.component'
import { RateAutomationsLibraryModule } from '@tokeet-frontend/rate-automations'
import { MinStayDecPipe } from './min-stay-dec.pipe'

const modules = [SharedModule, CommonModule, RateAutomationsLibraryModule]

const components = [
  //
  MinStayAutomationsComponent,
  MinStayAutomationDialogComponent,
  MinStayAutomationsOverlayComponent,
  MinStayAutomationsGridComponent,
]

const pipes = [MinStayDecPipe]

const services = []

@NgModule({
  imports: [...modules],
  declarations: [...components, ...pipes],
  exports: [...components, ...modules, ...pipes],
  providers: [...services, ...pipes],
})
export class MinStayAutomationsModule {}
