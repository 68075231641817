import { createAction, props } from '@ngrx/store'
import { TiketPushRoomAvailabilityPayload, TiketPushRoomRatesPayload, TiketRoomModel, TiketStatus } from './model'

export const GetTiketStatus = createAction('[Tiket] Get Status')
export const GetTiketStatusSuccess = createAction('[Tiket] Get Status Success', props<{ status: TiketStatus }>())

export const ConnectTiket = createAction('[Tiket] Connect', props<{ hotelId: string }>())
export const ConnectTiketSuccess = createAction('[Tiket] Connect Success', props<{ status: TiketStatus }>())

export const DisconnectTiket = createAction('[Tiket] Disconnect', props<{ hotelId: string }>())
export const DisconnectTiketSuccess = createAction('[Tiket] Disconnect Success', props<{ hotelId: string }>())

export const PushRatesToTiketListing = createAction(
  '[Tiket] Push Rates to Listing',
  props<{ connId: string; roomTypeCode: number; data: TiketPushRoomRatesPayload }>()
)

export const PushAvailabilityToTiketListing = createAction(
  '[Tiket] Push Availability to Listing',
  props<{ connId: string; roomTypeCode: number; data: TiketPushRoomAvailabilityPayload }>()
)
