import { Component, Inject, OnInit } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { LoadInquiryDetails } from '@tv3/store/inquiry/inquiry.actions'
import { Destroyable, isSomething, selectSome, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { Observable } from 'rxjs'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { map, switchMap } from 'rxjs/operators'
import * as R from 'ramda'
import { selectItineraryByBookingId } from '@tv3/store/itinerary/itinerary.selectors'
import { Flight } from '@tv3/models/itinerary/flight'
import * as moment from 'moment'
import { LoadItineraries } from '@tv3/store/itinerary/itinerary.actions'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Invoice } from '@tokeet-frontend/invoices'
import { isExternalBookingSource } from '@tokeet/cost-resolver'

@Component({
  selector: 'app-print-booking',
  templateUrl: './print-booking-preview-dialog.component.html',
  styleUrls: ['./print-booking-preview-dialog.component.scss'],
})
export class PrintBookingPreviewDialogComponent extends Destroyable implements OnInit {
  inquiry: Inquiry
  currency = '$'
  checkInOut$: Observable<any>
  bookingCharge$: Observable<any>
  flights$ = this.store.pipe(
    selectSome(selectItineraryByBookingId, { bookingId: this.data.bookingId }),
    map((itinerary) => {
      return R.map((flight) => {
        flight = new Flight(flight)
        if (flight.departure) {
          flight.departureTimeView = moment.utc(flight.departure).format('hh:mm A')
        }
        return flight
      }, itinerary.flights)
    })
  )

  get guests() {
    return (this.inquiry && (this.inquiry.numAdults || 0) + (this.inquiry.numChild || 0)) || 1
  }

  inquiry$: Observable<Inquiry>

  invoices: Invoice[] = []

  formulaAdjustedCost: Observable<number>
  external = false

  constructor(
    public dialogRef: MatDialogRef<PrintBookingPreviewDialogComponent>,
    private store: Store<fromRoot.State>,
    private inquiryService: InquiryService,
    private inquiryChargeService: InquiryChargeService,
    @Inject(MAT_DIALOG_DATA) public data: { bookingId: string }
  ) {
    super()
  }

  ngOnInit() {
    document.body.classList.add('print-modal')
    this.store.dispatch(LoadInquiryDetails({ id: this.data.bookingId }))
    this.store.dispatch(LoadItineraries({ inquiryId: this.data.bookingId }))

    this.inquiry$ = this.store.pipe(selectSome(selectInquiry, { id: this.data.bookingId }), untilDestroy(this))

    this.inquiry$.subscribe((inquiry) => {
      this.inquiry = inquiry
      this.external = isExternalBookingSource(this.inquiry.inquirySource)
      this.invoices = inquiry.invoices || []
      this.currency = R.pathOr('$', ['rental', 'currency', 'symbol'], inquiry)
    })

    this.formulaAdjustedCost = this.inquiry$.pipe(
      switchMap((inquiry) => this.inquiryService.getCostAfterFormula(inquiry.id))
    )

    this.checkInOut$ = this.inquiry$.pipe(switchMap((inquiry) => this.inquiryService.prepareCheckInOut(inquiry)))

    this.bookingCharge$ = this.inquiry$.pipe(
      switchMap((inquiry) => this.inquiryChargeService.getCharges(inquiry).pipe(map((i) => i.charges)))
    )
  }

  ngOnDestroy(): void {
    document.body.classList.remove('print-modal')
    super.ngOnDestroy()
  }

  onPrint() {
    window.print()
  }

  close() {
    this.dialogRef.close('close')
  }
}
