import { Expose, Serializable, plainToClass } from '@tokeet-frontend/tv3-platform'
import { epochToView } from '@tokeet-frontend/tv3-platform'

export class WebsiteTemplate extends Serializable<WebsiteTemplate> {
  engine?: 1
  mode: string
  name: string
  pkey: string
  properties: 'single' | 'multiple'
  title: string
  type: string

  static deserialize(data: WebsiteTemplate): WebsiteTemplate {
    return plainToClass<WebsiteTemplate, WebsiteTemplate>(WebsiteTemplate, data)
  }
}

export enum WebsitePageTypes {
  InternalLink = 'internallink',
  ExternalLink = 'externallink',
  Content = 'content',
}

export const websitePageTypeLabels = {
  [WebsitePageTypes.InternalLink]: ' Internal Link',
  [WebsitePageTypes.ExternalLink]: 'External Link',
  [WebsitePageTypes.Content]: 'Content Page',
}

export const portalPageGroups = [
  'attractions',
  'dancing',
  'drinking',
  'eating',
  'entertainment',
  'shopping',
  'sightseeing',
  'other',
]

export class WebsitePageSettings extends Serializable<WebsitePageSettings> {
  @Expose({ name: 'pkey' })
  id: string

  body: any
  group: string
  title?: string
  linkTitle: string
  linkurl?: string
  order: number

  status: number
  type: WebsitePageTypes

  created: number
  updated: number

  // view
  titleView: string
  createdView: string
  updatedView: string
  statusView: string
  typeView: string

  static deserialize(data: WebsitePageSettings): WebsitePageSettings {
    const settings: WebsitePageSettings = plainToClass<WebsitePageSettings, WebsitePageSettings>(
      WebsitePageSettings,
      data
    )
    settings.createdView = epochToView(settings.created)
    settings.updatedView = epochToView(settings.updated)
    settings.statusView = settings.status ? 'Visible' : 'Hidden'
    settings.titleView = settings.title || settings.linkTitle
    settings.typeView =
      settings.type === WebsitePageTypes.Content
        ? 'Content'
        : WebsitePageTypes.ExternalLink
        ? 'External Link'
        : 'Internal Link'
    return settings
  }
}

export interface AddWebsitePayload {
  title: string
  domain: string
  rentals: string
  template: string
  template_type: string
  description: string
  is_engine: 0 | 1
  is_portal: 0 | 1
  contact: {
    name: string
    email: string
    phone: string
    domain: string
    address: any // address object
  }
}
