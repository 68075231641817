import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import {
  AirbnbChannelService,
  AirbnbReservationAlterationBase,
  AirbnbReservationAlterationPriceQuoteResponse,
} from '@tokeet-frontend/channels'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { LoadInquiryDetails, BookingAlterationChanged } from '@tv3/store/inquiry/inquiry.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { EMPTY } from 'rxjs'
import { catchError, delay, finalize } from 'rxjs/operators'

@Component({
  selector: 'app-airbnb-reservation-alteration-dialog',
  templateUrl: './airbnb-reservation-alteration-dialog.component.html',
  styleUrls: ['./airbnb-reservation-alteration-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AirbnbReservationAlterationDialogComponent implements OnInit {
  get alterationId() {
    return this.inquiry?.alteration_requested?.external_id
  }

  get inquiry() {
    return this.data.inquiry
  }

  get quote() {
    return this.data.quote
  }

  get alteration(): AirbnbReservationAlterationBase {
    return this.isQuote ? this.quote : this.inquiry.alteration_requested
  }

  get total() {
    return this.isQuote ? this.quote?.expected_payout_amount_accurate : this.inquiry.alteration_requested?.total
  }

  get isQuote() {
    return !!this.quote
  }

  get isHostAlteration() {
    return this.alteration?.initiated_by === 'host'
  }
  get isGuestAlteration() {
    return this.alteration?.initiated_by === 'guest'
  }

  isLoading = false
  constructor(
    private store: Store<any>,
    public dialogRef: MatDialogRef<AirbnbReservationAlterationDialogComponent>,
    private toaster: Toaster,
    private airbnbChannelService: AirbnbChannelService,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry; quote?: AirbnbReservationAlterationPriceQuoteResponse }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  doAction(action: 'ignore' | 'decline' | 'accept' | 'cancel') {
    this.isLoading = true
    return this.airbnbChannelService.updateReservationAlterationById(this.alterationId, action).pipe(
      delay(5000),
      finalize(() => (this.isLoading = false)),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  onInitialAlteration() {
    const quote = this.data.quote
    this.isLoading = true
    this.airbnbChannelService
      .initAlteration(this.inquiry.inquiryId, {
        start_date: quote.start_date,
        end_date: quote.end_date,
        number_of_adults: quote.guest_details.number_of_adults,
        number_of_children: quote.guest_details.number_of_children,
        number_of_infants: quote.guest_details.number_of_infants,
        number_of_pets: quote.guest_details.number_of_pets,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Reservation alteration initiated Successfully')
          this.store.dispatch(LoadInquiryDetails({ id: this.inquiry.id }))
          this.store.dispatch(BookingAlterationChanged({ id: this.inquiry.id }))
          this.dialogRef.close('initiated')
        },
        (error) => {
          this.toaster.error('Unable to initiate reservation alteration', '', error)
        }
      )
  }

  onIgnore() {
    this.doAction('ignore').subscribe(() => {
      this.store.dispatch(LoadInquiryDetails({ id: this.inquiry.id }))
      this.close()
    })
  }

  onDecline() {
    this.doAction('decline').subscribe(() => {
      this.store.dispatch(LoadInquiryDetails({ id: this.inquiry.id }))
      this.store.dispatch(BookingAlterationChanged({ id: this.inquiry.id }))
      this.close()
    })
  }

  onAccept() {
    this.doAction('accept').subscribe(() => {
      this.store.dispatch(LoadInquiryDetails({ id: this.inquiry.id }))
      this.store.dispatch(BookingAlterationChanged({ id: this.inquiry.id }))
      this.close()
    })
  }

  onCancel() {
    this.doAction('cancel').subscribe(() => {
      this.store.dispatch(LoadInquiryDetails({ id: this.inquiry.id }))
      this.close()
    })
  }
}
