<input
  type="checkbox"
  class="custom-control-input"
  [formControl]="ctrl"
  [indeterminate]="indeterminate"
  id="{{ id }}"
  [class.is-invalid]="isTouchedInvalid"
/>
<label class="custom-control-label form-label" for="{{ id }}">{{ label }} <ng-content></ng-content> </label>
<div class="invalid-feedback form-error" *ngIf="isTouchedInvalid">
  {{ validationMessage() }}
</div>
