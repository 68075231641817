import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  AddExpense,
  AttachmentService,
  ConfirmDialogService,
  ExpenseView,
  getAttachments,
  selectAttachmentsById,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ExpenseDialog, getExpensePayloadFromForm } from '../../shared/dialogs/expense-dialog.abstract'
import { FetchInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { AuthService } from '@tv3/services/auth.service'

@Component({
  selector: 'app-duplicate-expense-dialog',
  templateUrl: '../../shared/dialogs/expense-dialog.component.html',
  styleUrls: ['../../shared/dialogs/expense-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class DuplicateExpenseDialogComponent extends ExpenseDialog implements OnInit {
  isDuplicate = true
  isEdit = true

  title = 'Duplicate Expense'

  get expense() {
    return this.data.expense
  }

  constructor(
    public dialogRef: MatDialogRef<DuplicateExpenseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { expense: ExpenseView },
    protected fb: FormBuilder,
    protected authService: AuthService,
    protected attachmentService: AttachmentService,
    protected confirm: ConfirmDialogService,

    protected store: Store<fromRoot.State>
  ) {
    super(fb, authService, attachmentService, store, confirm)
  }

  ngOnInit() {
    super.ngOnInit()
    this.store.dispatch(FetchInquiries({ ids: [this.expense.inquiry_id] }))
    this.store.dispatch(getAttachments({ ids: this.expense.attachments || [] }))
    this.store
      .pipe(select(selectAttachmentsById(this.expense.attachments || [])))
      .subscribe((attachments) => (this.attachments = attachments || []))

    this.setFormData(this.expense)
  }

  close() {
    this.dialogRef.close()
  }

  onSave(form: FormGroup) {
    this.store.dispatch(
      AddExpense({ payload: { ...getExpensePayloadFromForm(form), attachments: this.attachments.map((t) => t.id) } })
    )
    this.close()
  }
}
