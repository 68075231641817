export interface SmartDeviceAccount {
  connectedAccountId: string //  '430a8366-9ae6-4425-b0d6-71a666f19ef4'
  providerImageUrl: string //  'https://connect.getseam.com/_next/image?url=https://connect.getseam.com/assets/images/logos/august_logo_square.png&q=75&w=128'
  email: string //  'jane@example.com'
  username: string
  connected: boolean //  false
}

export interface SmartDevice {
  connectedAccountId: string // '34f2eb3c-c258-4361-90e6-299a0d043f19'
  deviceId: string // 'adf47c9e-f057-4cad-a2c0-b160ee4133db'
  name: string // ''
  capabilitiesSupported: string // ['access_code', 'lock']
  createdAt: number // 1711957824
  brandName: string // 'august'
  brandDisplayName: string // 'August'
  brandImageUrl: string // 'https://connect.getseam.com/_next/image?url=https://connect.getseam.com/assets/images/logos/august_logo_square.png&q=75&w=128'
  batteryStatus: string // 'full'
  batteryLevel: number //  0.9999532347993827
  online: boolean // false
  rentalId?: string
  isPrimary: boolean
}

export interface SmartDeviceAccessCodeResponse {
  code: string //  "102132",
  userId: string //  "b8881db4-4a6a-491a-a3d9-351d514ebfa0",
  inquiryId: string //  "01929f57-3e0b-713b-8dfe-0cf7e128cffa-tk2",
  endsAt: number //  1729677600,
  deviceId: string //  "c5e37c5e-f75d-435f-aba8-9842ea036d09",
  account: number //  1596621740.8045,
  status: string //  "unset",
  accessCodeId: string //  "1e2b3e06-7311-439e-a7b7-f3adf7d3cf98",
  guestId: string //  "01927554-3718-7e80-9124-cd8c75403e57-tk2",
  startsAt: number //  1729519200
  timezone: string
}

export interface SmartDeviceNewAccessCodePayload {
  deviceId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  connectedAccountId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  inquiryId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  guestId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  startsAt: number // 100000
  endsAt: number // 1200312
}

export interface SmartDeviceUpdateAccessCodePayload {
  deviceId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  accessCodeId: string // '2b2cfc39-4c53-4f6f-b377-2a4ecc65e03a'
  startsAt: number // 100000
  endsAt: number // 1200312
}
export interface SmartDeviceNewAccessCodeResponse {
  accessCodeId: string // '4cf27f6d-397e-4a10-9fb0-9af52d678d99'
  accessCode: string // '2266'
  status: string // 'setting'
}

export interface UpdateSmartDevicePayload {
  account: number // 123456
  connectedAccountId: string // '34f2eb3c-c258-4361-90e6-299a0d043f19'
  deviceId: string // 'adf47c9e-f057-4cad-a2c0-b160ee4133db'
  rentalId?: string //
  isPrimary?: boolean
}

export interface MarkSmartDevicePrimaryPayload {
  account: number // 123456
  connectedAccountId: string // '34f2eb3c-c258-4361-90e6-299a0d043f19'
  deviceId: string // 'adf47c9e-f057-4cad-a2c0-b160ee4133db'
  isPrimary: boolean
  rentalId: string
}

export enum SmartDeviceCodeStyles {
  PhoneNumber = 'PHONE_NUMBER',
  RandomNumber = 'RANDOM_NUMBER',
}

export interface SmartDeviceSettingsResponse {
  account: number
  isContractSigned: boolean
  contractId?: string
  codeFormat: number
  codeStyle: SmartDeviceCodeStyles // 'PHONE_NUMBER'
  startActivationInMinute: number
  endActivationInMinute: number
  isAutomaticallyCreateCode: boolean //  false
}

export interface LoadSmartDevicesParams {
  rentalId?: string
}

export interface GetSmartDeviceAccessCodesParams {
  deviceId?: string
  connectedAccountId?: string
  inquiryId?: string
}
