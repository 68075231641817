import { assertString } from './util/assertString'

const ipv4Maybe = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/
const ipv6Block = /^[0-9A-F]{1,4}$/i

export function isIP(str, version: any = '') {
  assertString(str)
  version = String(version)
  if (!version) {
    return isIP(str, 4) || isIP(str, 6)
  } else if (version === '4') {
    if (!ipv4Maybe.test(str)) {
      return false
    }
    const parts = str.split('.').sort((a, b) => a - b)
    return parts[3] <= 255
  } else if (version === '6') {
    const blocks = str.split(':')
    let foundOmissionBlock = false // marker to indicate ::

    // At least some OS accept the last 32 bits of an IPv6 address
    // (i.e. 2 of the blocks) in IPv4 notation, and RFC 3493 says
    // that '::ffff:a.b.c.d' is valid for IPv4-mapped IPv6 addresses,
    // and '::a.b.c.d' is deprecated, but also valid.
    const foundIPv4TransitionBlock = isIP(blocks[blocks.length - 1], 4)
    const expectedNumberOfBlocks = foundIPv4TransitionBlock ? 7 : 8

    if (blocks.length > expectedNumberOfBlocks) {
      return false
    }
    // initial or final ::
    if (str === '::') {
      return true
    } else if (str.substr(0, 2) === '::') {
      blocks.shift()
      blocks.shift()
      foundOmissionBlock = true
    } else if (str.substr(str.length - 2) === '::') {
      blocks.pop()
      blocks.pop()
      foundOmissionBlock = true
    }

    for (let i = 0; i < blocks.length; ++i) {
      // test for a :: which can not be at the string start/end
      // since those cases have been handled above
      if (blocks[i] === '' && i > 0 && i < blocks.length - 1) {
        if (foundOmissionBlock) {
          return false // multiple :: in address
        }
        foundOmissionBlock = true
      } else if (foundIPv4TransitionBlock && i === blocks.length - 1) {
        // it has been checked before that the last
        // block is a valid IPv4 address
      } else if (!ipv6Block.test(blocks[i])) {
        return false
      }
    }
    if (foundOmissionBlock) {
      return blocks.length >= 1
    }
    return blocks.length === expectedNumberOfBlocks
  }
  return false
}
