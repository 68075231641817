import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  Destroyable,
  selectAllRentals,
  untilDestroy,
  ActionFailed,
  TaxV3,
  Rental,
  taxV3ModalityLabels,
  isSomething,
} from '@tokeet-frontend/tv3-platform'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'
import { TaxTypes } from '@tokeet/cost-resolver'

@Component({
  selector: 'app-select-taxes-table',
  templateUrl: './select-taxes-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTaxesTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  @Input() excludedRentalIds: string[] = []
  @Input() set selectedRentalIds(rentalIds: string[]) {
    this.rentalsCtrl.setValue(rentalIds)
  }
  @Input() selectRentalsLabel = 'Select Rentals'

  @Output() selected = new EventEmitter<TaxV3[]>()
  modalityLabels = taxV3ModalityLabels
  taxTypes = TaxTypes

  displayedColumns: string[] = ['select', 'name']

  dataSource = new MatTableDataSource<TaxV3>()
  selection = new SelectionModel<TaxV3>(true, [])

  rentals$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => lodash.filter(rentals, (r) => !this.excludedRentalIds.includes(r.id)))
  )
  rentals: Rental[] = []
  isLoading = false
  rentalsCtrl = new FormControl([])

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.rentals$.pipe(untilDestroy(this)).subscribe(
      (rentals) => {
        this.rentals = rentals
        this.refreshTableData(this.rentalsCtrl.value || [])
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
    this.rentalsCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((rentalIds) => {
      this.refreshTableData(rentalIds)
    })
    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selected.emit(this.selection.selected)
    })
  }

  getTaxes(rentalIds: string[] = []) {
    return lodash.flatten(
      lodash.map(
        lodash.filter(this.rentals, (r) => (rentalIds?.length ? rentalIds?.includes(r.id) : true)),
        (r) => r.taxes || []
      )
    )
  }

  refreshTableData(rentalIds?: string[]) {
    this.dataSource.data = this.getTaxes(rentalIds)
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: TaxV3, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }
}
