import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  CustomChannelResponse,
  selectGVRStatus,
  selectHoliduStatus,
  selectHvmiStatus,
  selectRentalsUnitedStatus,
} from '@tokeet-frontend/channels'
import {
  Channel,
  Destroyable,
  Toaster,
  isSomething,
  untilDestroy,
  ChannelTypes,
  singleAccountChannels,
  ChannelNameTokens,
} from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'
import { selectConnectionViewsByChannel } from '@tv3/store/connection/connection.selectors'
import * as lodash from 'lodash'
import { ChannelConnectWizardsService } from '../../channel-connect/channel-connect-wizards/index.service'
import { filter, map, switchMap } from 'rxjs/operators'
import { ChannelDialogService } from '../../channel-detail/channel-dialog.service'
import { combineLatest, of } from 'rxjs'
import { AuthService } from '@tv3/services/auth.service'
import { CustomChannelOverlayService } from '@tv3/containers/channels/channel-detail/custom/custom-channel-overlay.service'

@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss'],
})
export class ChannelCardComponent extends Destroyable implements OnInit {
  @Input() channel: Channel | CustomChannelResponse

  @Input() isCustomChannel: boolean = false

  feedChannelConnectedAccounts: number = 0

  singleAccountChannels = singleAccountChannels

  connections: Connection[] = []

  get isChannelConnected() {
    return this.feedChannelConnectedAccounts > 0 || this.connections?.length > 0
  }

  get accounts() {
    if (this.feedChannelConnectedAccounts) return this.feedChannelConnectedAccounts
    return lodash.keys(lodash.groupBy(this.connections, (c) => c.propertyId)).length
  }

  constructor(
    private store: Store<any>,
    private auth: AuthService,
    private toaster: Toaster,
    private connectWizards: ChannelConnectWizardsService,
    private customChannelDialog: CustomChannelOverlayService,
    private channelDialogService: ChannelDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.isCustomChannel) {
      const ch = this.channel as Channel
      this.store
        .pipe(
          select(selectConnectionViewsByChannel),
          map((data) => data[ch.id]),
          untilDestroy(this)
        )
        .subscribe((c) => {
          this.connections = c
        })
    }

    combineLatest([
      this.store.pipe(
        select(selectHvmiStatus),
        map((c) => c?.hmcIds?.length)
      ),
      this.store.pipe(
        select(selectHoliduStatus),
        map((c) => c?.connected)
      ),
      this.store.pipe(
        select(selectGVRStatus),
        map((c) => c?.connected)
      ),
      this.store.pipe(
        select(selectRentalsUnitedStatus),
        map((c) => c?.connected)
      ),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([accounts, isHoliduConnected, isGVRConnected, isRUConnected]) => {
        if (!('name' in this.channel)) return
        switch (this.channel.name) {
          case ChannelNameTokens.Hvmi:
            this.feedChannelConnectedAccounts = accounts
            break
          case ChannelNameTokens.Holidu:
            this.feedChannelConnectedAccounts = isHoliduConnected ? 1 : 0
            break
          case ChannelNameTokens.GoogleVacationRentals:
            this.feedChannelConnectedAccounts = isGVRConnected ? 1 : 0
            break
          case ChannelNameTokens.RentalsUnited:
            this.feedChannelConnectedAccounts = isRUConnected ? 1 : 0
            break
        }
      })
  }

  isSingleAccountChannel(name: ChannelNameTokens) {
    return singleAccountChannels.includes(name)
  }

  onConnect() {
    const ch = this.channel as Channel

    if (ch.type !== ChannelTypes.Auto) {
      this.onChannel()
      return
    }

    this.connectWizards
      .connect(ch)
      .pipe(
        filter(isSomething),
        switchMap((res) => {
          if (res === 'channel') {
            return this.channelDialogService.openSide(ch)
          }
          return of(res)
        })
      )
      .subscribe()
  }

  onChannel() {
    if (this.auth.isReadOnly()) {
      this.toaster.warning("You don't have access to this.")
      return
    }

    if (this.isCustomChannel) {
      this.customChannelDialog.open(this.channel as CustomChannelResponse)
      return
    }
    const channel = this.channel as Channel
    this.channelDialogService.openSide(channel).subscribe()
  }
}
