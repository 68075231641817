import { Expose, Serializable, Type } from '../../functions/serializer'
import { plainToClass, Transform } from 'class-transformer'
import * as R from 'ramda'
import { Address } from '../account/account.models'
import { epochToView } from '../../functions'
import * as lodash from 'lodash'
import { MFADeliveryMethod } from '../../constants'

export interface UserMFAConfig {
  status: 1 | 0
  delivery: MFADeliveryMethod
}

// @dynamic
export class User extends Serializable<User> {
  @Expose({ name: 'pkey' })
  id: string

  account: number

  @Expose({ name: 'firstname' })
  firstName: string

  @Expose({ name: 'lastname' })
  lastName: string

  @Expose({ name: 'primaryemail' })
  primaryEmail: string

  email: string[]

  phone: string

  @Type(() => Address)
  address: Address

  @Transform((value) => R.map((t) => +t, value || []), { toClassOnly: true })
  roles: number[]
  created: number
  name: string
  @Expose({ name: 'lastlogin' })
  lastLogin: number

  phoneView: string
  countryView: string
  createdView: string
  lastLoginView: string
  roleView: string
  attributes: any

  subscriptions: any[]

  mfa_config?: UserMFAConfig
  settings: {
    total: number
    payouts: number
    payments: number
    invoices: number
    statements: number
    reports: number
    disabled: number
    automata: number
    ownercenter: number
    signature: number
    margins: number
  }

  static deserialize(data: any): User {
    let user: User = plainToClass<User, User>(User, data)
    user.name = `${data.firstname} ${data.lastname}`
    user.name = user.name.trim()
    user.phoneView = user.phone ? (/^\+/.test(user.phone) ? user.phone : `+${user.phone}`) : ''
    user.countryView = R.pathOr('', ['address', 'country'], user)
    user.createdView = epochToView(user.created)
    user.lastLoginView = epochToView(user.lastLogin)
    return user
  }
}

export function isRole(roles: number[], role: number) {
  return lodash.head(lodash.sortBy(roles)) === role
}
