import { Itinerary } from './itinerary.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Itinerary> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Itinerary> = createEntityAdapter<Itinerary>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
