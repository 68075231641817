import { Action, ActionReducer, MetaReducer } from '@ngrx/store'
import { storeLogger } from 'ngrx-store-logger'
import * as moment from 'moment'

let logger: Function

export const metaReducers: (environment: any) => MetaReducer<any>[] = (environment) => {
  return !environment.production ? [<MetaReducer<any>>storeFreeze, <MetaReducer<any>>togglableLogger] : []
}

/* storeLogger - modified to allow toggle showing logs */

function togglableLogger(reducer) {
  if (!localStorage.getItem('Store:disableLogger')) {
    logger = createLogger()(reducer)
  }

  return function (state, action) {
    if (logger) {
      return logger(state, action)
    }

    return reducer(state, action)
  }
}

function createLogger(): MetaReducer<any> {
  return storeLogger({
    collapsed: true,
    filter: {
      blacklist: ['@ngrx/store/update-reducers', '@ngrx/effects/init', '@ngrx/effects/update-effects'],
    },
    colors: {
      title: (action: any) => {
        if (action.type.startsWith('[Account]')) {
          return '#f44336'
        } else if (action.type.startsWith('[Billing]')) {
          return '#E91E63'
        } else if (action.type.startsWith('[Channel]')) {
          return '#9C27B0'
        } else if (action.type.startsWith('[ChannelProperty]')) {
          return '#9C27B0'
        } else if (action.type.startsWith('[Expense]')) {
          return '#673AB7'
        } else if (action.type.startsWith('[Inquiry]')) {
          return '#03A9F4'
        } else if (action.type.startsWith('[Invoice]')) {
          return '#007bff'
        } else if (action.type.startsWith('[Rental]')) {
          return '#ff9908'
        } else if (action.type.startsWith('[Attachment]')) {
          return '#449D44'
        } else if (action.type.startsWith('[Preferences]')) {
          return '#879d12'
        } else if (action.type.startsWith('[Guest]')) {
          return '#4b9d93'
        } else if (action.type.startsWith('[Plan]')) {
          return '#009d81'
        } else if (action.type.startsWith('[Subscriptions]')) {
          return '#1f44ff'
        } else if (
          action.type.startsWith('[Payment Gateway]') ||
          action.type.startsWith('[Payment Gateway Type]') ||
          action.type.startsWith('[Payment Gateway Log]') ||
          action.type.startsWith('[Payment Rule]')
        ) {
          return '#52cf33'
        }
      },
      prevState: (prevState: Object) => '#000000',
      action: (action: Object) => '#000000',
      nextState: (nextState: Object) => '#000000',
      error: (error: any, prevState: Object) => '#000000',
    },
  })
}

/* storeFreeze - modified to skip freezing Moment object */

function storeFreeze<T, V extends Action = Action>(reducer: ActionReducer<T, V>): ActionReducer<T, V>
function storeFreeze<T, V extends Action = Action>(reducer: ActionReducer<T, V>): ActionReducer<any, any> {
  return function freeze(state, action): any {
    state = state || {}

    deepFreeze(state)

    // guard against trying to freeze null or undefined types
    if (action.payload) {
      deepFreeze(action.payload)
    }

    const nextState = reducer(state, action)

    deepFreeze(nextState)

    return nextState
  }
}

function deepFreeze(o) {
  if (moment.isMoment(o)) return o

  Object.freeze(o)

  const oIsFunction = typeof o === 'function'
  const hasOwnProp = Object.prototype.hasOwnProperty

  Object.getOwnPropertyNames(o).forEach(function (prop) {
    const shouldFreezeProp =
      hasOwnProp.call(o, prop) &&
      (oIsFunction ? prop !== 'caller' && prop !== 'callee' && prop !== 'arguments' : true) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])

    if (shouldFreezeProp && !moment.isMoment(o[prop])) {
      deepFreeze(o[prop])
    }
  })

  return o
}
