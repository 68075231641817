import { Injectable } from '@angular/core'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { MessagingSettingsComponent } from './messaging-settings.component'

@Injectable()
export class MessagingSettingsDialogService {
  constructor(private dialog: DialogService) {}

  public open() {
    return this.dialog.openDrawer(MessagingSettingsComponent)
  }
}
