<div class="modal-header">
  <h5 class="modal-title d-flex align-items-center"><i class="cs-icon cs-icon-download mr-1"></i>{{ config.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="">
    <mat-form-field2 class="mat-block">
      <input matInput placeholder="Name" formControlName="name" type="text" />
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field2>
  </form>
  <div class="mb-3" *ngIf="config.columnCustomizable">
    <p class="text-bold">Select the columns you want to download</p>
    <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr 1fr 1fr">
      <ng-container *ngFor="let column of config.columns">
        <mat-checkbox
          [class.opaque]="column.disabled"
          [disabled]="column.disabled"
          [checked]="column.selected"
          [(ngModel)]="column.selected"
          >{{ column.name }}</mat-checkbox
        >
      </ng-container>
    </div>
  </div>
  <div class="">
    <p [innerHTML]="config.description"></p>
  </div>
  <div class="" *ngIf="config.extraCheck">
    <mat-checkbox
      [checked]="config.extraCheck.checked"
      [(ngModel)]="config.extraCheck.checked"
      [matTooltip]="config.extraCheck.tooltip"
      matTooltipPosition="above"
    >
      {{ config.extraCheck.title }}
    </mat-checkbox>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="download(form)" type="button" class="btn btn-secondary-info create-btn" [disabled]="isExporting">
    <i class="cs-icon cs-icon-download"></i> download
  </button>
</div>
