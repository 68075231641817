import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { isSomething, selectOnce, selectSomeOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { MessageRequest } from '@tv3/store/message/message.request'
import { BookingHelperService } from '@tv3/services/booking-helper.service'
import { DeleteCalendarEventsComplete } from '@tv3/store/calendar/calendar.actions'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { DeleteGuestsComplete, GetGuestComplete, GetGuestEmailsComplete } from '@tv3/store/guest/guest.actions'
import { Guest } from '@tv3/store/guest/guest.model'
import { selectGuestById } from '@tv3/store/guest/guest.selectors'
import { GuestService } from '@tv3/store/guest/guest.service'
import { BookingService } from '@tv3/store/inquiry/booking.service'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { selectInquiries, selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { AddMessageComplete, DeleteMessageComplete } from '@tv3/store/message/message.actions'
import { Message } from '@tv3/store/message/message.model'
import { SearchService } from '@tv3/store/search/search.service'
import { WebsiteService } from '@tv3/store/website/website.service'
import * as R from 'ramda'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators'

import { MessageService } from '../message/message.service'
import * as fromRoot from '../state'
import {
  AddBooking,
  AddBookingComplete,
  AddInquiry,
  AddInquiryComplete,
  ChangeAirBnBResponse,
  ChangeAirBnBResponseComplete,
  DeleteInquiries,
  DeleteInquiriesComplete,
  DeleteInquiry,
  DeleteInquiryComplete,
  DeleteInquiryMessageComplete,
  FetchInquiries,
  FetchInquiriesComplete,
  LoadFilteredInquiries,
  LoadFilteredInquiriesComplete,
  LoadInquiries,
  LoadInquiriesComplete,
  LoadInquiryDetails,
  LoadInquiryDetailsComplete,
  RefreshInquiries,
  RefreshInquiriesComplete,
  SearchElasticInquiries,
  SearchElasticInquiriesComplete,
  SearchInquiries,
  SearchInquiriesComplete,
  SendGuestLogin,
  UpdateInquiryCost,
  UpdateInquiryCostComplete,
} from './inquiry.actions'
import { InquiryService } from './inquiry.service'
import { PreferenceService } from '@tv3/store/preferences/preference.service'
import { Preference } from '@tv3/store/preferences/preferences.model'
import { TokeetTemplates } from '@tokeet-frontend/templates'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import { DeleteLocalInvoicesByIds, selectInvoicesForBookings } from '@tokeet-frontend/invoices'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import { InquiryCost } from '@tokeet/cost-resolver'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { checkBookingOverlapping } from '@tv3/store/inquiry/check-overlapping'

@Injectable()
export class InquiryEffects {
  @Effect()
  loadInquiries$ = this.actions$.pipe(
    ofType(LoadInquiries),
    exhaustMap(({ pagination }) =>
      this.inquiries.all(pagination).pipe(
        map((result) => LoadInquiriesComplete(result)),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadFilteredInquiries$ = this.actions$.pipe(
    ofType(LoadFilteredInquiries),
    exhaustMap(({ filter }) =>
      this.inquiries.getBy(filter).pipe(
        map((inquiries) => LoadFilteredInquiriesComplete({ inquiries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  refreshInquiries$ = this.actions$.pipe(
    ofType(RefreshInquiries),
    exhaustMap(() =>
      this.inquiries.refresh().pipe(
        map((inquiries) => RefreshInquiriesComplete({ inquiries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  searchInquiries$ = this.actions$.pipe(
    ofType(SearchInquiries),
    concatMap(({ term }) =>
      this.inquiries.search(term).pipe(
        map((inquiries) => SearchInquiriesComplete({ inquiries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  searchElasticInquiries$ = this.actions$.pipe(
    ofType(SearchElasticInquiries),
    concatMap(({ term }) =>
      this.searchService.searchInquiries(term).pipe(
        concatMap((searched) =>
          this.store.pipe(
            select(selectInquiries),
            take(1),
            /**
             * inquiries not present in the store
             * and in need of fetching to replace
             * the incomplete inquiries originating from elastic search
             */
            map((inquiries) => R.differenceWith((a, b) => a.id === b.id, searched, inquiries))
          )
        ),
        map((inquiries) => SearchElasticInquiriesComplete({ inquiries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  fetchSearchElasticInquiries$ = this.actions$.pipe(
    ofType(SearchElasticInquiriesComplete),
    concatMap(({ inquiries }) => {
      if (R.isEmpty(inquiries)) {
        return of(ActionSkipped())
      } else {
        return of(FetchInquiries({ ids: R.map((i) => i.id, inquiries) }))
      }
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  @Effect()
  fetchInquiries$ = this.actions$.pipe(
    ofType(FetchInquiries),
    concatMap(({ ids }) =>
      this.inquiries.get(ids).pipe(
        map((inquiries) => FetchInquiriesComplete({ inquiries })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadInquiryDetails$ = this.actions$.pipe(
    ofType(LoadInquiryDetails),
    switchMap(({ id }) =>
      this.inquiries.load(id).pipe(
        map((inquiry) => LoadInquiryDetailsComplete({ inquiry })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  private addInquiry(form: AddInquiryForm, quotes: InquiryCost, cost: BookingEngineCost, silent = false) {
    const getGuest = (): Observable<Guest> => {
      if (!!form.guestId) return of(null)
      return this.guests.create({
        name: form.guestName,
        email: form.guestEmail,
        phone: form.guestPhone,
        source: form.source,
      })
    }

    const payload = this.inquiries.createAddInquiryPayload({ form, quotes, cost })
    return getGuest().pipe(
      switchMap((guest) => {
        payload.guest_id = payload.guest_id || guest?.id
        return this.inquiries.add(payload, silent).pipe(map((inquiry) => ({ guest, inquiry })))
      })
    )
  }

  @Effect()
  addInquiry$ = this.actions$.pipe(
    ofType(AddInquiry),
    switchMap(({ form, quotes, cost }) => this.addInquiry(form, quotes, cost)),
    switchMap(({ guest, inquiry }) => {
      const actions: any[] = [AddInquiryComplete({ inquiry })]
      if (guest) {
        actions.push(GetGuestComplete({ guest }))
      }
      return actions
    }),
    catchError((error, caught) => {
      this.store.dispatch(ActionFailed({ error }))
      return caught
    })
  )

  @Effect()
  addBooking$ = this.actions$.pipe(
    ofType(AddBooking),
    switchMap(({ form, cost, quotes, checkOverlapping }) => {
      const addBooking = () => {
        return this.addInquiry(form, quotes, cost, true).pipe(
          switchMap(({ inquiry }) => {
            return this.bookings.confirm(inquiry).pipe(
              switchMap(({ inquiry, guest }) => {
                const actions: any[] = [AddBookingComplete({ inquiry })]
                if (guest) {
                  actions.push(GetGuestComplete({ guest }))
                }
                return actions
              })
            )
          })
        )
      }

      const payload = this.inquiries.createAddInquiryPayload({ form, cost, quotes })
      if (checkOverlapping) {
        return checkBookingOverlapping(this.store, this.calendarService, {
          rentalId: payload.rental_id,
          start: payload.guest_arrive,
          end: payload.guest_depart,
        }).pipe(
          switchMap((event) => {
            if (!!event) {
              this.toaster.error('Overlapping with other events.')
              return [ActionSkipped()]
            } else {
              return addBooking()
            }
          })
        )
      } else {
        return addBooking()
      }
    }),
    catchError((error, caught) => {
      this.store.dispatch(ActionFailed({ error }))
      return caught
    })
  )

  @Effect()
  deleteInquiries$ = this.actions$.pipe(
    ofType(DeleteInquiries),
    switchMap(({ inquiryIds, guestIds, guest }) =>
      this.inquiries.delete(inquiryIds, guest).pipe(
        switchMap((ids) => {
          let resultActions = [DeleteInquiriesComplete({ ids }), DeleteCalendarEventsComplete({ ids })]

          if (guest) {
            resultActions.push(<any>DeleteGuestsComplete({ ids: guestIds }))
          }

          return this.store.pipe(
            selectOnce(selectInvoicesForBookings(ids)),
            map((invoices) => invoices.map((i) => i.id)),
            switchMap((invoiceIds) => [DeleteLocalInvoicesByIds({ ids: invoiceIds }), ...resultActions])
          )
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changeAirBnBResponse$ = this.actions$.pipe(
    ofType(ChangeAirBnBResponse),
    switchMap(({ inquiry, status }) =>
      this.inquiries.changeAirBnBResponse(inquiry, status).pipe(
        map((inq) => ({ id: inquiry.id, changes: { status } })),
        map((update) => ChangeAirBnBResponseComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInquiry$ = this.actions$.pipe(
    ofType(DeleteInquiry),
    switchMap(({ inquiry, deleteGuest }) =>
      this.inquiries.deleteInquiry({ inquiry, deleteGuest }).pipe(
        switchMap(() =>
          this.store.pipe(
            selectOnce(selectInvoicesForBookings([inquiry.id])),
            map((invoices) => invoices.map((i) => i.id))
          )
        ),
        switchMap((invoiceIds) => [
          DeleteInquiryComplete({ id: inquiry.id }),
          DeleteCalendarEventsComplete({ ids: [inquiry.id] }),
          DeleteLocalInvoicesByIds({ ids: invoiceIds }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect({ dispatch: false })
  sendGuestLogin$ = this.actions$.pipe(
    ofType(SendGuestLogin),
    switchMap(({ inquiry }) =>
      this.websiteService.getGuestPortalForRental(inquiry.rentalId).pipe(
        map((response) => ({ domain: response.domain, inquiry })),
        catchError((error) => {
          this.toaster.error('Unable to send link. ', null, error)
          return EMPTY
        })
      )
    ),
    concatMap((payload) => this.preferenceService.get().pipe(map((preferences) => ({ ...payload, preferences })))),
    concatMap((payload: { domain: string; inquiry: Inquiry; preferences: Preference }) => {
      let branding = payload.preferences.branding || <any>{}
      const request = {
        inquiry_id: payload.inquiry.id,
        convo_id: payload.inquiry.convoId,
        guest_id: payload.inquiry.guestId,
        rental_id: payload.inquiry.rentalId,
        message_txt: 'Here is your login.', // This isn't used, but shouldn't be blank.
        message_html: 'Here is your login.', // This isn't used, but shouldn't be blank.
        rental_name: payload.inquiry.rental.name,
        guest_name: payload.inquiry.guest.name,
        subject: 'Login to view your booking at ' + payload.inquiry.rental.name,
        from: payload.inquiry.rental.email,
        api: R.pathOr('', ['attributes', 'api'], payload.inquiry),
        template: branding.guestTemplate || TokeetTemplates.TokeetGuestPortalTemplate,
        gp_link:
          'https://' +
          payload.domain +
          '/authorize/' +
          payload.inquiry.account +
          '/' +
          payload.inquiry.id +
          '/' +
          payload.inquiry.guestId +
          '/' +
          payload.inquiry.created,
      } as MessageRequest

      return of({
        inquiry: payload.inquiry,
        request,
      })
    }),
    concatMap((payload) =>
      this.messageService.create(payload.request).pipe(
        switchMap((message) => this.getGuest(message.guestId).pipe(map((guest) => ({ message, guest })))),
        map(({ message, guest }) => {
          return {
            inquiryUpdate: {
              id: message.inquiryId,
              changes: { messages: R.append(message, payload.inquiry.messages) },
            },
            guestUpdate: { id: guest.id, changes: { emailMessages: R.append(message, guest.emailMessages) } },
          }
        }),
        switchMap((update) => [
          AddMessageComplete({ update: update.inquiryUpdate }),
          GetGuestEmailsComplete({ update: update.guestUpdate }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMessage$ = this.actions$.pipe(
    ofType(DeleteMessageComplete),
    switchMap(({ message, inquiryId }) =>
      of(true).pipe(
        switchMap(() => this.store.pipe(selectSomeOnce(selectInquiry, { id: inquiryId }))),
        map((inquiry) => {
          const messages = R.filter((m: Message) => message.id !== m.id, inquiry.messages || [])
          return { id: inquiryId, changes: { messages } }
        }),
        map((update) => DeleteInquiryMessageComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateInquiryCost$ = this.actions$.pipe(
    ofType(UpdateInquiryCost),
    switchMap(({ id, cost }) => {
      return this.inquiries.updateInquiry(id, { cost, quotes: cost }).pipe(
        tap(() => this.toaster.success('Inquiry cost updated successfully.')),
        map((inq) => ({ id, changes: { cost } })),
        map((update) => UpdateInquiryCostComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private guestService: GuestService,
    private messageService: MessageService,
    private websiteService: WebsiteService,
    private preferenceService: PreferenceService,
    private calendarService: CalendarService,
    private bookingHelperService: BookingHelperService,
    private store: Store<fromRoot.State>,
    private inquiries: InquiryService,
    private searchService: SearchService,
    private bookings: BookingService,
    private guests: GuestService
  ) {}

  private getGuest(guestId: string): Observable<Guest> {
    return this.store.pipe(select(selectGuestById, { id: guestId }), take(1)).pipe(
      switchMap((guest) => {
        if (isSomething(guest)) {
          return of(guest)
        } else {
          return this.guestService.get(guestId)
        }
      })
    )
  }
}
