import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FilterGroup } from '@tv3/services/utils/filters.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { combineLatest, Subject } from 'rxjs'
import { formatDate, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { map } from 'rxjs/operators'
import {
  Channel,
  ChannelNameTokens,
  isSomething,
  LoadChannels,
  Rental,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { ConnectionStatusTexts, ConnectionTypeTexts, ConnectionView } from '@tv3/store/connection/connection.view'
import { ChannelDialogService } from '@tv3/containers/channels/channel-detail/channel-dialog.service'
import { ConnectionDialogService } from '@tv3/containers/channels/connection-detail/connection-dialog.service'
import { ConnectionFilters } from '@tv3/store/connection/connection.filters'
import { DeleteCalendarConnectionsComplete } from '@tv3/store/connection/connection.actions'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { LoadConnections } from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { isFeedChannel, LoadChannelConfig } from '@tokeet-frontend/channels'
import { LoadChannelPropertySettings } from '@tv3/store/channel-property-setting/channel-property-setting.actions'
import { AuthService } from '@tv3/services/auth.service'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { selectConnectionsLoaded } from '@tv3/store/connection/connection.selectors'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  ChannelListingWizardsService,
  hasListingWizard,
} from '../channel-connect/listing-connect-wizards/index.service'

@Component({
  selector: 'app-connection-list',
  templateUrl: './connection-list.component.html',
  styleUrls: ['./connection-list.component.scss'],
})
export class ConnectionListComponent extends SelectableRow<ConnectionView> implements OnInit {
  defaultRental: Rental
  @Input() set rental(value: Rental) {
    this.defaultRental = value
    this.filters?.patchValue({ rentals: [value.id], channelType: '' })
  }

  selectableRowIdPropName = 'id'
  isSelectAllRenderedItems = true
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = [
    'select',
    'channelFriendlyName',
    'typeText',
    'linkDateOrCreated',
    'rentalName',
    'propertyNameView',
    'statusText',
    'edit',
  ]

  viewType = 'list'

  // filters
  filters = this.connectionFilters.group
  channelTypeCtrl = this.filters.get('channelType')
  statusTexts = ConnectionStatusTexts
  typeTexts = ConnectionTypeTexts

  // empty table
  isEmptyTable$ = isEmptyTable(this.dataSource)
  filterGroup = FilterGroup.Connection
  tableType = TableType.ChannelConnections
  isLoading$ = this.store.pipe(
    select(selectConnectionsLoaded),
    map((loaded) => !loaded)
  )
  isEmpty$ = this.connectionFilters.group.origin.pipe(map((items) => !items.length))

  refreshStatus$ = new Subject<Connection[]>()

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private auth: AuthService,
    private toaster: Toaster,

    protected storage: UserStorage,
    public connectionFilters: ConnectionFilters,

    private connectionHelperService: ConnectionHelperService,
    private channelDialog: ChannelDialogService,
    private connectionDialog: ConnectionDialogService,

    private channelListingWizards: ChannelListingWizardsService
  ) {
    super(storage, TableType.ChannelConnections)
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.connectionFilters.group.currentFilters.pipe(untilDestroy(this)).subscribe(() => {
      this.paginator.firstPage()
    })

    this.actions$.pipe(ofType(DeleteCalendarConnectionsComplete)).subscribe(() => {
      this.selection.clear()
    })

    combineLatest([this.connectionFilters.group.changes, this.connectionFilters.rentals$])
      .pipe(
        map(([connections, rentals]) => {
          if (this.auth.isAdmin()) return connections
          // const userId = this.auth.user.id;
          // const restrictedRentalId = lodash.filter(rentals, r => lodash.includes(r.restrictedUsers, userId)).map(r=>r.id)
          const rentalIds = lodash.map(rentals, (r) => r.id)

          return lodash.filter(connections, (c) => rentalIds.includes(c.rentalId))
        }),
        untilDestroy(this)
      )
      .subscribe((connections: ConnectionView[]) => {
        this.dataSource.data = connections
      })
  }

  isRowSelectable(row: ConnectionView) {
    return row.isICal || (row.isApi && !row.isFeedApi)
  }

  onMasterToggle(checked: boolean) {
    if (checked) {
      this.isSelectAllRenderedItems = true
      this.masterToggle(true)
    } else {
      this.unselectAll()
    }
  }

  onSelectAll(all = false) {
    this.unselectAll()
    if (all) {
      this.masterToggle(false)
      this.isSelectAllRenderedItems = false
    } else {
      this.masterToggle(true)
      this.isSelectAllRenderedItems = true
    }
  }

  onRefresh() {
    this.store.dispatch(LoadChannels())
    this.store.dispatch(LoadConnections({}))
    this.store.dispatch(LoadChannelPropertySettings())
    this.store.dispatch(LoadChannelConfig({ name: ChannelNameTokens.HomeToGo }))
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  isFeed(connection: ConnectionView) {
    return isFeedChannel(connection.channel?.name)
  }

  onConnectionDetail(connection: ConnectionView, wizard = true) {
    this.connectionDialog.openSide(connection, wizard)
  }

  hasWizard(connection: ConnectionView) {
    return hasListingWizard(connection.channel?.name)
  }

  onOpenWizard(connection: ConnectionView) {
    this.channelListingWizards.openWizard(connection.channel, connection)
  }

  onChannelDetails(channel: Channel) {
    this.channelDialog.openSide(channel).subscribe()
  }

  onRentalDetails(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }

  onLinkRental(connection: ConnectionView) {
    this.connectionHelperService.linkRental(connection.channel.name, connection).subscribe()
  }

  onUnlinkRental(connection: ConnectionView) {
    this.connectionHelperService.unlinkRental(connection.channel.name, connection)
  }

  onUnlinkRentals() {
    const selected = this.getSelected().filter((t) => t.isApi && !t.isFeedApi && t.rentalId)
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one API connection.`)
      return
    }
    this.connectionHelperService.unlinkRentals(selected)
  }

  onRemove(connection: ConnectionView) {
    if (connection.isImported) {
      this.connectionHelperService.removeImportedCalendar(connection)
    } else {
      this.connectionHelperService.removeExportedCalendar(connection)
    }
  }

  onDeleteSelected() {
    const selected = this.getSelected().filter((t) => t.isICal)
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one iCal connection.`)
      return
    }

    this.connectionHelperService.removeCalendars(selected)
  }

  getStatusChangedTime(time: number) {
    if (isSomething(time)) {
      return `Last Change: ${formatDate(time, 'datetime')}`
    } else {
      return ''
    }
  }
}
