import { Injectable } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ChannelTypes, UserStorage } from '@tokeet-frontend/tv3-platform'
import { FilterGroup } from '@tv3/services/utils/filters.service'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import {
  selectAllAvailableChannelNames,
  selectAllConnectionViews,
  selectConnectionsLoaded,
} from '@tv3/store/connection/connection.selectors'
import { filter, map, observeOn, switchMapTo } from 'rxjs/operators'
import { asyncScheduler } from 'rxjs'
import {
  FilterFormGroup,
  isSomething,
  Rental,
  selectAllRentals,
  selectRentalsLoaded,
} from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class ConnectionFilters {
  defaultValues = {
    rentals: [],
    channels: [],
    channelType: 'api',
    connected: undefined,
    type: '',
    status: '',
    linkage: '',
  }

  filters = {
    rentals: (connections: ConnectionView[], rentals: string[]) => {
      if (!isSomething(rentals)) {
        return connections
      }
      return R.filter((c: ConnectionView) => R.contains(c.rentalId, rentals), connections)
    },
    channels: (connections: ConnectionView[], channels: string[]) => {
      if (!isSomething(channels)) {
        return connections
      }
      return R.filter((connection: ConnectionView) => {
        return channels.indexOf(connection.channelFriendlyName) > -1
      }, connections)
    },
    channelType: (connections: ConnectionView[], type: 'api' | 'ical' | 'custom') => {
      if (!isSomething(type)) {
        return connections
      }
      if (type === 'custom') {
        return [] // no custom connections
      }
      return R.filter((connection: ConnectionView) => {
        return connection.isApi === (type === 'api') || connection.isICal === (type === 'ical')
      }, connections)
    },
    type: (connections: ConnectionView[], type: string) => {
      if (!isSomething(type) || type === 'all') {
        return connections
      }
      return R.filter((c: ConnectionView) => c.typeText === type, connections)
    },
    status: (connections: ConnectionView[], status: string) => {
      if (!isSomething(status) || status === 'all') {
        return connections
      }
      return R.filter((c: ConnectionView) => c.statusText === status, connections)
    },
    linkage: (connections: ConnectionView[], linkage: string) => {
      if (!isSomething(linkage) || linkage === 'all') {
        return connections
      }
      return R.filter(
        (c: ConnectionView) => (c.rentalId && linkage === 'linked') || (!c.rentalId && linkage === 'unlinked'),
        connections
      )
    },
  }

  group: FilterFormGroup<ConnectionView>

  constructor(private store: Store<fromRoot.State>, private fb: FormBuilder, private storage: UserStorage) {
    const storedFilters = this.storage.get(FilterGroup.Connection, this.defaultValues)

    const group = this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      type: [storedFilters.type],
      channelType: [storedFilters.channelType ?? this.defaultValues.channelType],
      status: [storedFilters.status],
      linkage: [storedFilters.linkage],
      connected: [storedFilters.connected],
    })

    this.group = new FilterFormGroup<ConnectionView>(
      group.controls,
      this.store,
      this.storage,
      FilterGroup.Connection,
      selectAllConnectionViews,
      this.filters,
      null,
      true
    )
  }

  rentals$ = this.store.pipe(
    select(selectRentalsLoaded),
    filter((isLoaded) => isLoaded),
    switchMapTo(this.store.pipe(select(selectAllRentals))),
    map((rentals) => {
      const chosenRentals = this.group.get('rentals').value
      const existingRentals = R.filter(
        (item) =>
          R.contains(
            item,
            R.map((r: Rental) => r.id, rentals)
          ),
        chosenRentals || []
      )
      this.group.patchValue({ rentals: existingRentals })

      return rentals
    })
  )

  channels$ = this.store.pipe(
    select(selectConnectionsLoaded),
    filter((isLoaded) => isLoaded),
    switchMapTo(this.store.pipe(select(selectAllAvailableChannelNames))),
    map((items) => {
      const chosenItems = this.group.get('channels').value
      const existingItems = R.filter((item) => R.contains(item, items), chosenItems || [])
      this.group.patchValue({ channels: existingItems })

      return items
    }),
    observeOn(asyncScheduler)
  )
}
