import { adapter, initialState, State } from './channel.state'
import * as R from 'ramda'
import {
  AddAirBnBV1Channel,
  AddAirBnBV1ChannelComplete,
  AddFlipKeyChannel,
  AddFlipKeyChannelComplete,
  AddPPIDChannel,
  AddPPIDChannelComplete,
  LoadChannelsComplete,
  VerifyFlipKeyAccountForChannel,
  VerifyFlipKeyAccountForChannelComplete,
} from '../channel/channel.actions'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadChannelsComplete, (state, { channels }) => adapter.addAll(channels, R.merge(state, { isLoaded: true }))),
  on(AddAirBnBV1Channel, AddFlipKeyChannel, AddPPIDChannel, VerifyFlipKeyAccountForChannel, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(
    AddAirBnBV1ChannelComplete,
    AddFlipKeyChannelComplete,
    VerifyFlipKeyAccountForChannelComplete,
    (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false })
  ),
  on(AddPPIDChannelComplete, (state, { update }) => adapter.updateOne(update, { ...state, isUpdating: false }))
)

export function channelReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
