import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import {
  filterWebsiteTemplate,
  tooltipsForTemplate,
  Website,
  isTokeetWebsiteDomain,
  WebsiteTypes,
} from '@tv3/store/website/website.model'
import {
  ConfirmDialogService,
  Destroyable,
  Rental,
  RentalService,
  SaveForm,
  selectAllRentals,
  untilDestroy,
  GroupRateService,
  Rate,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite, selectAllWebsiteTemplates } from '@tv3/store/website/website.selectors'
import { WebsiteTemplate } from '@tv3/store/website/website.types'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { Actions, ofType } from '@ngrx/effects'
import { WebsiteService } from '@tv3/store/website/website.service'
import { ConfigWebsiteDomainDialogService } from '@tv3/containers/websites/dialogs/config-website-domain-dialog/config-website-domain-dialog.service'
import { combineLatest } from 'rxjs'
import { delay, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import { startCase } from 'lodash'
import { DownloadEngineLinksDialogService } from '@tv3/containers/websites/overlays/details/website-details-engine/download-engine-links-dialog.service'
import { UpdateWebsite, UpdateWebsiteRentalsComplete } from '@tv3/store/website/website.actions'

@Component({
  selector: 'app-website-details-engine',
  templateUrl: './website-details-engine.component.html',
  styleUrls: ['./website-details-engine.component.scss'],
})
export class WebsiteDetailsEngineComponent extends Destroyable implements OnInit, OnChanges {
  @Input() website: Website

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))
  isCustomDomain = false

  tooltipsForTemplate = tooltipsForTemplate
  templates: WebsiteTemplate[] = []
  rentals: Rental[] = []
  rateCategories: { value: string; label: string }[] = []

  form = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    domain: [{ value: undefined, disabled: true }],
    rateCategory: [undefined, [Validators.required]],
    template: [undefined, [Validators.required]],
  })

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private websiteService: WebsiteService,
    private groupRateService: GroupRateService,
    private rentalService: RentalService,
    private confirmDialog: ConfirmDialogService,
    private configWebsiteDomainDialog: ConfigWebsiteDomainDialogService,
    private downloadEngineLinksDialog: DownloadEngineLinksDialogService
  ) {
    super()
  }

  ngOnInit() {
    combineLatest(
      this.form.valueChanges.pipe(
        startWith(this.form.getRawValue()),
        switchMap(() => this.store.pipe(select(selectAllWebsiteTemplates))),
        map((templates) => filterWebsiteTemplate(templates, this.website.type, this.website.rentals.length)),
        tap((templates) => (this.templates = templates)),
        tap((templates) => {
          const template = R.find((t) => this.form.get('template').value === t.name, templates)
          if (!template) {
            this.form.patchValue({ template: undefined }, { emitEvent: false })
          }
        })
      ),
      this.store.pipe(
        select(selectAllRentals),
        tap((rentals) => (this.rentals = rentals))
      ),
      this.groupRateService.all()
    )
      .pipe(untilDestroy(this))
      .subscribe((data) => {
        this.rateCategories = this.getRentalRateCategories(this.website, this.rentals, data[2])
      })

    this.actions.pipe(ofType(UpdateWebsiteRentalsComplete), delay(500), untilDestroy(this)).subscribe(() => {
      this.form.patchValue({ rateCategory: 'default' })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['website']) {
      this.setFormData(this.website)
    }
  }

  private getRentalRateCategories(website: Website, rentals: Rental[], groupRates: Rate[]) {
    const activeRentalId = website.rentals[0]
    const activeRental = R.find((r) => r.id === activeRentalId, rentals)
    const activeRentalRates = (activeRental && activeRental.rates) || []

    const rentalIds = R.groupBy((rentalId) => rentalId, website.rentals)
    const activeGroupRates = (groupRates || []).filter((groupRate) => {
      return R.filter((rentalId) => !!rentalIds[rentalId], groupRate.rentalIds).length === website.rentals.length
    })

    const rateCategories = [
      ...this.rentalService.getUniqueCategories(activeRentalRates),
      ...this.rentalService.getUniqueCategories(activeGroupRates),
    ]

    if (!rateCategories.includes('default')) rateCategories.push('default') // always add 'default'

    return R.map((c) => ({ value: c, label: startCase(c) }), R.uniq(rateCategories))
  }

  private setFormData(website: Website) {
    this.isCustomDomain = !isTokeetWebsiteDomain(website.domain, WebsiteTypes.BookingEngine)
    this.form.patchValue(
      {
        ...website,
        rateCategory: website.rateCategory,
        engine_enabled: !!website.engineEnabled, // 0|1=>true|false
        template: website.template,
      },
      { emitEvent: false }
    )
  }

  onEditDomain() {
    this.configWebsiteDomainDialog.open(this.website)
  }

  onDownloadEngineLinks() {
    this.downloadEngineLinksDialog.open(this.website, this.rentals)
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const formData = fb.getRawValue()
    const template = R.find((t) => formData.template === t.name, this.templates)
    const payload = {
      ...this.website.serialize(),
      title: formData.title,
      rateCategory: formData.rateCategory,
      template: template.name,
      template_type: template.properties,
    }
    this.store.dispatch(UpdateWebsite({ websiteId: this.website.id, data: payload }))
  }
}
