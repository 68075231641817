import * as R from 'ramda'
import { USER_ROLES } from '@tokeet-frontend/tv3-platform'
import { RoleKeys } from '@tv3/permissions/roles'

export function userRoleToString(roles: number[]) {
  roles = R.filter((role) => role > 0, roles || [])
  roles.sort((a, b) => a - b)
  let highestRole
  R.forEachObjIndexed((role) => {
    if (role.value === roles[0]) {
      highestRole = role
    }
  }, USER_ROLES)
  highestRole = highestRole || USER_ROLES.guest
  return highestRole.name
}

export function userRoleToFriendlyString(roles: number[]) {
  const role = userRoleToString(roles)
  switch (role) {
    case RoleKeys.ADMIN:
      return 'Administrator'
    case RoleKeys.MANAGER:
      return 'Property Manager'
    case RoleKeys.ACCOUNTING:
      return 'Accountant'
    case RoleKeys.OWNER:
      return 'Property Owner'
    case RoleKeys.STAFF:
      return 'House Staff'
    case RoleKeys.GUEST:
      return 'Guest'
    case RoleKeys.READ_ONLY:
      return 'Read-only'
  }
}
