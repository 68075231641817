import { Component, Input, OnInit } from '@angular/core'
import { AibrnbListingDetails, ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { Connection } from '@tv3/store/connection/connection.model'

@Component({
  selector: 'app-airbnb-listing-status',
  templateUrl: './airbnb-listing-status.component.html',
  styleUrls: ['./airbnb-listing-status.component.scss'],
})
export class AirbnbListingStatusComponent implements OnInit {
  @Input() connection: Connection
  // status: AibrnbListingDetails['status']
  constructor(private connectionAirbnbService: ConnectionAirbnbService) {}

  ngOnInit(): void {
    // this.connectionAirbnbService.getListingDetailsV2(this.connection.propertyId, this.connection.roomId).subscribe(
    //   (data) => {
    //     this.status = data?.status
    //   },
    //   (err) => {}
    // )
  }
}
