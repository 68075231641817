import { createAction, props } from '@ngrx/store'
import { AttributeDescriptionResponse, CreateAttributeDescriptionPayload } from './model'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'

export const LoadAttributeDescriptions = createAction('[AttributeDescription] Load all')
export const LoadAttributeDescriptionsComplete = createAction(
  '[AttributeDescription] Load all Complete',
  props<{ items: AttributeDescriptionResponse[] }>()
)

export const LoadAttributeDescriptionsByEntityType = createAction(
  '[AttributeDescription] Load all for type',
  props<{ entityType: DataEntityType }>()
)
export const LoadAttributeDescriptionsByEntityTypeComplete = createAction(
  '[AttributeDescription] Load all for type Complete',
  props<{ items: AttributeDescriptionResponse[] }>()
)

export const AddAttributeDescription = createAction(
  '[AttributeDescription] Add',
  props<{
    entityType: DataEntityType
    payload: CreateAttributeDescriptionPayload[]
  }>()
)
export const AddAttributeDescriptionComplete = createAction(
  '[AttributeDescription] Add Complete',
  props<{ item: AttributeDescriptionResponse }>()
)

export const DeleteAttributeDescription = createAction(
  '[AttributeDescription] Delete',
  props<{ entityId: string; id: string }>()
)
export const DeleteAttributeDescriptionComplete = createAction(
  '[AttributeDescription] Delete Complete',
  props<{ item: AttributeDescriptionResponse }>()
)
