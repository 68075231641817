<div class="d-flex align-items-center gap-3 mb-3">
  <div class="d-flex align-items-center gap-2">
    <h6 class="mb-0">2-Way Sync</h6>
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </div>
  <div class="flex-fill"></div>
</div>

<div class="container-box p-5">
  <div class="select-block-container">
    <div class="select-block" [class.active]="form.get('booking_settings').value">
      <a (click)="onToggle('booking_settings')">
        <i class="cs-icon cs-icon-calendar-sync text-success"></i>
        <div>Sync Booking Settings</div>
      </a>
    </div>

    <div class="select-block" [class.active]="form.get('pricing_settings').value">
      <a (click)="onToggle('pricing_settings')">
        <i class="cs-icon cs-icon-dollar-sync text-info"></i>
        <div>Sync Pricing Settings</div>
      </a>
    </div>

    <div class="select-block" [class.active]="form.get('availability_rules').value">
      <a (click)="onToggle('availability_rules')">
        <i class="cs-icon cs-icon-calendar-check-sync text-indigo"></i>
        <div>Sync Availability Rules</div>
      </a>
    </div>
  </div>
</div>
