import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { AddGuestInteraction, UpdateGuestInteractions } from '@tv3/store/guest/guest.actions'
import { GuestAddInteractionForm } from '@tv3/interfaces/forms/guest-interaction.form'
import { EditItemParent } from '@tv3/containers/guests/edit/edit-item.parent'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { EditGuestInteractionList } from '@tv3/containers/guests/edit/interaction/list/guest-interaction-list.model'
import * as R from 'ramda'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { filter, take } from 'rxjs/operators'
import { Guest, GuestInteraction } from '@tv3/store/guest/guest.model'
import { GuestModifyInteractionDialogService } from '@tv3/containers/guests/edit/interaction/modify/guest-modify-interaction-dialog.service'

@Component({
  selector: 'app-guest-interaction-list',
  templateUrl: './guest-interaction-list.component.html',
  styleUrls: ['./guest-interaction-list.component.scss'],
})
export class EditGuestInteractionListComponent implements OnInit, EditItemParent {
  @Input() set guest(g: Guest) {
    this.guestItem = g
    this.formList(this.guestItem.interaction ? this.guestItem.interaction : [])
  }

  guestItem: Guest

  list: EditGuestInteractionList[]

  tableType = TableType.GuestInteractionsList

  constructor(
    private store: Store<fromRoot.State>,
    private guestModifyInteractionDialogService: GuestModifyInteractionDialogService
  ) {}

  formList(list: GuestInteraction[]) {
    if (!list) {
      this.list = []
    } else {
      this.list = R.map((item) => {
        const listItem: EditGuestInteractionList = <EditGuestInteractionList>{ ...item }

        switch (listItem.type) {
          case 'meeting':
            listItem.icon = 'fal tab-icon fa-users'
            listItem.boxClass = 'meeting'
            break
          case 'phone call':
            listItem.icon = 'fal tab-icon fa-phone-volume'
            listItem.boxClass = 'phone-call'
            break
          case 'comment':
            listItem.icon = 'fal tab-icon fa-comments'
            listItem.boxClass = 'comment'
            break
        }

        return listItem
      }, list)
    }
  }

  ngOnInit() {}

  getId() {
    return 'interaction'
  }

  mainAction() {
    this.addItem()
  }

  addItem() {
    this.guestModifyInteractionDialogService
      .open()
      .afterClosed()
      .pipe(
        filter((result) => isSomething(result)),
        take(1)
      )
      .subscribe((result: { form: GuestAddInteractionForm }) => {
        if (result) {
          this.store.dispatch(AddGuestInteraction({ guestId: this.guestItem.id, data: result.form }))
        }
      })
  }

  onEdit(interaction: GuestInteraction) {
    this.guestModifyInteractionDialogService
      .open({ data: interaction })
      .afterClosed()
      .pipe(
        filter((result) => isSomething(result)),
        take(1)
      )
      .subscribe((result: { form: GuestAddInteractionForm; data: GuestInteraction; delete?: boolean }) => {
        let list = this.guestItem.interaction.slice()
        if (result.delete) {
          list = list.filter((item) => item.id !== result.data.id)
        }
        const updateList = list.map((listItem) => {
          const item = { ...listItem }
          if (item.id === result.data.id) {
            item.type = result.form.type
            item.date = result.form.date
            item.comment = result.form.comment
          }
          const desObj = GuestInteraction.deserialize(item)
          desObj.key = item.id
          return desObj
        })
        this.store.dispatch(
          UpdateGuestInteractions({ guestId: this.guestItem.id, data: updateList, remove: result.delete })
        )
      })
  }
}
