import { FormGroup } from '@angular/forms'
import { IStorage } from '../storage/storage'
import { debounceTime } from 'rxjs/operators'

export class PersistFormGroupUtils {
  constructor(protected storage: IStorage, protected storeKey: string) {}

  connect(form: FormGroup, defaults = {}) {
    const values = this.storage.get(this.storeKey, defaults)
    if (values) {
      form.patchValue(values)
    }

    form.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
      this.storage.set(this.storeKey, form.getRawValue())
    })
  }
}
