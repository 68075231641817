export const USER_ROLES = {
  admin: {
    name: 'ADMIN',
    value: 1,
  },
  readOnly: {
    name: 'READ-ONLY',
    value: 0.1,
  },
  manager: {
    name: 'MANAGER',
    value: 1.1,
  },
  accounting: {
    name: 'ACCOUNTING',
    value: 1.2,
  },
  staff: {
    name: 'STAFF',
    value: 1.5,
  },
  owner: {
    name: 'OWNER',
    value: 1.6,
  },
  guest: {
    name: 'GUEST',
    value: 2,
  },
}
