import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { BehaviorSubject, forkJoin, merge, Observable, of, throwError } from 'rxjs'
import { catchError, concatMap, finalize, map, take, tap, withLatestFrom } from 'rxjs/operators'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  ActionFailed,
  Currency,
  DateRangePickerSelection,
  cleanRateMappings,
  createLocaleCompareSort,
  isEmptyTable,
  LoadGroupRatesComplete,
  loadRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'

import {
  ConfirmDialogService,
  GroupRateService,
  GroupRatesChangedComplete,
  Rate,
  RateMappingResponse,
  RateType,
  Rental,
  RentalService,
  selectAllRentals,
  Toaster,
  toMoment,
  untilDestroy,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { EditRateDialogService } from '../edit-rate-dialog/edit-rate-dialog.service'
import { EditRateDialogInput } from '../edit-rate-dialog/edit-rate-dialog.component'
import * as R from 'ramda'

@Component({
  selector: 'app-group-rates-table',
  templateUrl: './group-rates-table.component.html',
  styleUrls: ['./group-rates-table.component.scss'],
})
export class GroupRatesTableComponent extends SelectableRow<Rate> implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  isDeleting = false
  groupRatesLoaded = false

  tableType: TableType = TableType.GroupRates

  searchControl = new FormControl()
  categoryControl = new FormControl()
  typeControl = new FormControl()
  periodControl = new FormControl()
  rentalsControl = new FormControl()

  rentalRates$ = new BehaviorSubject<Rate[]>([])
  groupRates$ = new BehaviorSubject<Rate[]>([])

  rates$ = new BehaviorSubject<Rate[]>([])

  filteredRentals$ = new BehaviorSubject<Rental[]>([])
  categories$: Observable<string[]>

  rentals: Rental[]

  currency: string | Currency

  displayedColumns = [
    'select',
    'name',
    'category',
    'period',
    'rentals',
    'nightly',
    'weekly',
    'monthly',
    'minimumStay',
    'edit',
  ]

  isEmptyTable$ = isEmptyTable(this.dataSource)

  isSelectAllRenderedItems = true

  rateTypes: { value: RateType; label: string }[] = [
    { value: 'standard', label: 'Standard Rate' },
    { value: 'dynamic', label: 'Dynamic Rate' },
  ]

  constructor(
    protected storage: UserStorage,
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private toaster: Toaster,
    private rentalService: RentalService,
    private groupRateService: GroupRateService,
    private confirmDialog: ConfirmDialogService,
    private editRateDialog: EditRateDialogService
  ) {
    super(storage)
  }

  ngOnInit() {
    this.loadSavedTableState()

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort(this.sortDataAccessor)

    this.categories$ = this.rates$.pipe(
      map((rates) => this.rentalService.getUniqueCategories(rates)),
      tap((items) => {
        const chosenItem = this.categoryControl.value

        if (R.isNil(R.find((i) => i === chosenItem, items))) {
          this.categoryControl.patchValue(null)
        }
      })
    )

    this.prepareRentals()

    this.prepareRates()

    merge(
      this.rates$,
      this.searchControl.valueChanges,
      this.categoryControl.valueChanges,
      this.typeControl.valueChanges,
      this.periodControl.valueChanges,
      this.rentalsControl.valueChanges
    )
      .pipe(
        tap(() => {
          this.filterRates()
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.rates$.next(this.rates$.value)
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.fetchGroupRates()
      .pipe(
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  onMasterToggle(checked: boolean) {
    if (checked) {
      this.isSelectAllRenderedItems = true
      this.masterToggle(true)
    } else {
      this.unselectAll()
    }
  }

  onSelectAll(all = false) {
    this.unselectAll()
    if (all) {
      this.masterToggle(false)
      this.isSelectAllRenderedItems = false
    } else {
      this.masterToggle(true)
      this.isSelectAllRenderedItems = true
    }
  }

  searchRate(rate: Rate) {
    this.searchControl.setValue(rate.name)
  }

  sortDataAccessor = (rate: Rate, property: string) => {
    switch (property) {
      case 'period':
        return rate.start
      case 'category':
        return this.getRateCategory(rate)
      case 'rentals':
        return rate.rentalIds ? rate.rentalIds.length : 0
      default:
        return rate[property]
    }
  }

  clearFilters() {
    this.searchControl.reset()
    this.categoryControl.reset()
    this.typeControl.reset()
    this.periodControl.reset()
    this.rentalsControl.reset()
  }

  get isFiltering() {
    return (
      this.searchControl.value || this.categoryControl.value || this.periodControl.value || this.rentalsControl.value
    )
  }

  filterRates() {
    const search = lodash.trim(this.searchControl.value).toLowerCase()
    const category = lodash.toString(this.categoryControl.value)
    const type = lodash.toString(this.typeControl.value)
    const period = this.periodControl.value as DateRangePickerSelection

    let rates = this.rates$.value

    if (search) {
      const dateFormat = 'MMM D, YYYY'

      rates = rates.filter((rate) => {
        if (lodash.toString(rate.name).toLowerCase().includes(search)) return true
        if (lodash.toString(rate.category).toLowerCase().includes(search)) return true

        const textCategories = rate.categories && lodash.toString(rate.categories.join('\n'))
        if (textCategories && textCategories.toLowerCase().includes(search)) return true

        const textPeriod = `${toMoment(rate.start).format(dateFormat)} - ${toMoment(rate.end).format(dateFormat)}`
        if (textPeriod && textPeriod.toLowerCase().includes(search)) return true

        return false
      })
    }

    if (type && type !== '*') {
      rates = rates.filter((rate) => rate.type === type)
    }

    if (category && category !== '*') {
      rates = rates.filter((rate) => {
        return lodash.toString(rate.category) === category || (rate.categories && rate.categories.includes(category))
      })
    }

    if (period) {
      rates = rates.filter((rate) => {
        const start = toMoment(rate.start).startOf('date').unix()
        const end = toMoment(rate.end).startOf('date').unix()

        return (
          (start >= period.from && start <= period.to) ||
          (end >= period.from && end <= period.to) ||
          (start < period.from && end > period.to)
        )
      })
    }

    const rentalIds = lodash.keyBy(this.rentalsControl.value as string[])

    if (!lodash.isEmpty(rentalIds)) {
      rates = rates.filter((rate) => {
        return lodash.find(rate.rentalIds, (rentalId) => rentalIds[rentalId])
      })
    }

    this.dataSource.data = rates
  }

  getRateCategory(rate: Rate) {
    if (rate.type === 'promotion') {
      return (rate.categories || []).join(', ') || 'promotion'
    } else {
      return rate.category || ''
    }
  }

  getRentalsTooltip = (rentalIds: string) => {
    const rentals = lodash.keyBy(this.rentals, (rental) => rental.id)

    const rentalNames = lodash.map(rentalIds, (rentalId) => {
      const rental = rentals[rentalId]

      return rental && rental.name
    })

    return lodash.compact(rentalNames).join(', ')
  }

  onAddStandardRate() {
    this.openEditRateDialog(null, 'standard')
  }

  onAddDynamicRate() {
    this.openEditRateDialog(null, 'dynamic')
  }

  onEdit(rate: Rate) {
    this.openEditRateDialog(rate)
  }

  onDelete(rate?: Rate) {
    const rates: Rate[] = rate ? [rate] : this.getSelected()

    this.onDeleteGroupRates(rates)
  }

  private prepareRates() {
    this.prepareGroupRates()

    this.groupRates$
      .pipe(
        tap((rates) => {
          this.rates$.next(rates)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private fetchGroupRates() {
    return this.groupRateService.all().pipe(
      tap((rates) => {
        this.groupRates$.next(rates)
        this.groupRatesLoaded = true
      })
    )
  }

  private prepareGroupRates() {
    this.fetchGroupRates().subscribe()

    this.actions$
      .pipe(
        ofType(GroupRatesChangedComplete, LoadGroupRatesComplete),
        tap(({ rates }) => {
          this.groupRates$.next(rates)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private prepareRentals() {
    this.rates$
      .pipe(
        withLatestFrom(this.store.select(selectAllRentals)),
        map(([rates, rentals]) => {
          this.rentals = rentals

          this.filteredRentals$.next(rentals)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private onDeleteGroupRates(rates: Rate[]) {
    const textRates = rates.length > 1 ? 'these group rates' : 'this group rate'

    let text = `Are you sure you want to delete ${textRates}?`

    this.confirmDialog
      .confirm({
        title: `Delete ${textRates}?`,
        body: `This action may also delete rate mappings in rentals. ${text}`,
      })
      .subscribe(() => {
        this.deleteGroupRates(rates)
      })
  }

  private deleteGroupRates(rates: Rate[]) {
    this.isDeleting = true

    this.groupRateService
      .deleteRates(rates)
      .pipe(
        tap((updatedRates) => this.store.dispatch(GroupRatesChangedComplete({ rates: updatedRates }))),
        finalize(() => (this.isDeleting = false))
      )
      .subscribe(
        () => {
          this.toaster.success(`${rates.length > 1 ? 'Group rates' : 'Group rate'} deleted successfully.`)

          this.store.dispatch(
            cleanRateMappings({ rentalIds: lodash.uniq(lodash.flatten(lodash.map(rates, (r) => r.rentalIds || []))) })
          )
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  private openEditRateDialog(rate: Rate = null, type: RateType = null) {
    const params: EditRateDialogInput = {
      isGroupRate: true,
      rate,
      type,
      groupRates: this.groupRates$.value,
      rentals: this.rentals,
    }

    this.editRateDialog
      .open(params)
      .afterClosed()
      .subscribe((result) => {
        if (result && result.groupRates) {
          this.groupRates$.next(result.groupRates)
        }
      })
  }
}
