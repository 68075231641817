<form [formGroup]="form" *ngIf="!isFieldDefsEmpty; else empty">
  <div class="row">
    <div formArrayName="attrs" *ngFor="let item of attrsArrayForm.controls; let i = index" class="col-sm-3">
      <ng-container [formGroupName]="i" *ngIf="item.value as data">
        <ng-container [ngSwitch]="data.type">
          <ng-container *ngSwitchCase="dataTypes.Number">
            <ng-template [ngTemplateOutlet]="numberInput"></ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="dataTypes.PositiveNumber">
            <ng-template [ngTemplateOutlet]="numberInput"></ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="dataTypes.Date">
            <mat-form-field2 class="mat-block" [matTooltip]="data.description" matTooltipPosition="above">
              <input
                matInput
                [matDatepicker]="datePicker"
                [placeholder]="data.label"
                (focus)="datePicker.open()"
                formControlName="value"
                readonly
              />
              <i matPrefix (click)="datePicker.open()" class="cs-icon cs-icon-calendar"></i>
              <mat-datepicker #datePicker></mat-datepicker>
            </mat-form-field2>
          </ng-container>
          <ng-container *ngSwitchCase="dataTypes.Boolean">
            <mat-checkbox
              formControlName="value"
              [matTooltip]="data.description"
              matTooltipPosition="above"
              class="align-input-row-end"
              >{{ data.label }}</mat-checkbox
            >
          </ng-container>
          <ng-container *ngSwitchCase="dataTypes.Text">
            <mat-form-field2 class="mat-block" [matTooltip]="data.description" matTooltipPosition="above">
              <input type="text" formControlName="value" [placeholder]="data.label" matInput />
            </mat-form-field2>
          </ng-container>
        </ng-container>
        <ng-template #numberInput>
          <mat-form-field2 class="mat-block" [matTooltip]="data.description" matTooltipPosition="above">
            <input type="number" formControlName="value" [placeholder]="data.label" matInput />
            <mat-error *ngIf="item.get('value').hasError('positive')"> Value must be positive </mat-error>
          </mat-form-field2>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="showActions"
    class="d-flex gap-3 align-items-center mt-3"
    [ngClass]="'justify-content-' + actionsPosition"
  >
    <ng-template [ngTemplateOutlet]="configBtn"></ng-template>
    <button (click)="onSave(form)" class="btn btn-secondary-info" [disabled]="isLoading || isSaving" type="button">
      <i class="cs-icon cs-icon-save" *ngIf="!isSaving"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isSaving"></i>
      Save
    </button>
  </div>
</form>

<ng-template #configBtn>
  <button (click)="onConfig()" class="btn btn-secondary-primary" [disabled]="isLoading || isSaving" type="button">
    <i class="cs-icon cs-icon-cog"></i>
    Attributes Configuration
  </button>
</ng-template>

<ng-template #empty>
  <app-loading-section *ngIf="isLoading"></app-loading-section>
  <div class="text-center p-5 text-light" *ngIf="!isLoading">
    <p>No attributes defined</p>
    <ng-template [ngTemplateOutlet]="configBtn"></ng-template>
  </div>
</ng-template>
