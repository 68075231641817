import { Branding } from '@tv3/models/account/branding'
import { Expose, Serializable, Type, deserialize } from '@tokeet-frontend/tv3-platform'

export class Preference extends Serializable<Preference> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'date_format' })
  dateFormat: string

  @Expose({ name: 'cal_click' })
  calClick: string

  @Type(() => Number)
  @Expose({ name: 'double_booking' })
  doubleBooking: number

  @Expose({ name: 'invoice_prefix' })
  invoicePrefix: string

  created: number

  @Type(() => Number)
  @Expose({ name: 'first_login' })
  firstLogin: number

  // @Expose({name: 'invoice_no_next'})
  // invoiceNumber: number; // this number is incremented when an invoice is created

  @Type(() => Number)
  invoice_uses_booking_taxes: number

  archived: number

  account: number

  @Type(() => Branding)
  branding: Branding

  @Expose({ name: 'disable_channel_connections_warning' })
  disableChannelConnectionsWarning: number

  user: string
  logins: number

  static deserialize(data: object): Preference {
    return deserialize(Preference, data)
  }
}
