import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { Channel, ChannelTypes, DialogService } from '@tokeet-frontend/tv3-platform'
import { ApiChannelDialogComponent } from '@tv3/containers/channels/channel-detail/api/api-channel-dialog.component'
import { ComponentType } from '@angular/cdk/portal'
import { IcalChannelDialogComponent } from '@tv3/containers/channels/channel-detail/ical/ical-channel-dialog.component'
import { isFeedChannel } from '@tokeet-frontend/channels'
import { ChannelConnectWizardsService } from '../channel-connect/channel-connect-wizards/index.service'
@Injectable({
  providedIn: SharedModule,
})
export class ChannelDialogService {
  constructor(private dialog: DialogService, private channelConnectWizards: ChannelConnectWizardsService) {}

  private open<T>(channel: Channel, component: ComponentType<T>) {
    const defaultConfig: MatDialogConfig = {
      data: { channel },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef.afterClosed()
  }

  public openSide(channel: Channel) {
    if (isFeedChannel(channel.name)) {
      return this.channelConnectWizards.openFeedChannel(channel)
    } else if (channel?.type !== ChannelTypes.Auto) {
      return this.open(channel, IcalChannelDialogComponent)
    } else {
      return this.open(channel, ApiChannelDialogComponent)
    }
  }
}
