import { DataFeed } from './datafeed.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<DataFeed> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<DataFeed> = createEntityAdapter<DataFeed>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
