<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Contact Information</h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box">
      <div class="box-body">
        <div class="d-flex rounded-top overflow-hidden">
          <h6 class="section-title border-right flex-1 border-top-0">Company Information</h6>
          <h6 class="section-title flex-1 border-top-0">Company Location</h6>
        </div>
        <div class="row px-3">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="name" placeholder="Name" required />
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                    Name is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="phone" placeholder="Phone" required />
                  <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('required')">
                    Phone is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="email" placeholder="Email" required />
                  <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('email')">
                    Email is <strong>invalid</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div formGroupName="address" class="col-sm-12">
                <app-country-select
                  [codeCtrl]="form.get('address').get('country_code')"
                  [nameCtrl]="form.get('address').get('country')"
                ></app-country-select>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div formGroupName="address" class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="street" placeholder="Address" />
                </mat-form-field2>
              </div>
              <div formGroupName="address" class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="city" placeholder="City" />
                </mat-form-field2>
              </div>
              <div formGroupName="address" class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="state" placeholder="State/Province/Region" />
                </mat-form-field2>
              </div>
              <div formGroupName="address" class="col-sm-12">
                <mat-form-field2 class="mat-block">
                  <input matInput formControlName="postal_code" placeholder="Postal code" />
                </mat-form-field2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
