import { FocusMonitor } from '@angular/cdk/a11y'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { Subject } from 'rxjs'
import * as lodash from 'lodash'

import { TooltipPosition } from '@angular/material/tooltip'
import { MatFormFieldControl } from '@angular/material/form-field'
import * as moment from 'moment'
import { NativeDateAdapter } from '@angular/material/core'

@Component({
  selector: 'app-mat-date-input',
  templateUrl: './mat-date-input.component.html',
  styleUrls: ['mat-date-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MatDateInputComponent }],
})
export class MatDateInputComponent implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy, DoCheck {
  static nextId = 0

  controlType = 'mat-date-input'

  @HostBinding() id = `${this.controlType}-${MatDateInputComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''

  stateChanges = new Subject<void>()
  errorState = false

  focused = false
  touched = false

  _value: Date = null
  _placeholder: string
  _required = false
  _disabled = false

  @Input() dateFormat: string
  @Input() showInputTooltip: boolean | TooltipPosition = false
  @Input() showTime = false
  maxDate: Date
  @Input() set max(value: Date | null) {
    const validValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(value))

    if (
      !this.dateAdapter.sameDate(validValue, this.maxDate) &&
      (!this.minDate || this.dateAdapter.compareDate(this.minDate, validValue) <= 0)
    ) {
      this.maxDate = validValue
    }
  }

  minDate: Date
  @Input() set min(value: Date | null) {
    const validValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(value))

    if (
      !this.dateAdapter.sameDate(validValue, this.minDate) &&
      (!this.maxDate || this.dateAdapter.compareDate(validValue, this.maxDate) <= 0)
    ) {
      this.minDate = validValue
    }
  }

  @Input() local = false

  @Output() valueChange = new EventEmitter<Date>()

  isPickerOpen = false
  private dateAdapter = new NativeDateAdapter('en-GB')

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public elementRef: ElementRef,
    public focusMonitor: FocusMonitor
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      setTimeout(() => {
        let focused = !!origin

        this.focused = focused
        this.stateChanges.next()
      })
    })
  }

  ngOnDestroy() {
    this.stateChanges.complete()
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement)
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched
      this.stateChanges.next()
    }
  }

  onChange = (value: Date) => {}

  onTouched = () => {
    this.touched = true
  }

  writeValue(value: Date): void {
    this.value = value
  }

  registerOnChange(fn): void {
    this.onChange = fn
  }

  registerOnTouched(fn): void {
    this.onTouched = fn
  }

  @Input()
  get value() {
    return this._value
  }

  set value(value: Date) {
    this._value = value

    if (this.onChange) this.onChange(value)
    this.stateChanges.next()
  }

  @Input()
  get placeholder() {
    return this._placeholder
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder
    this.stateChanges.next()
  }

  @Input()
  get required() {
    return this._required
  }

  set required(required) {
    this._required = coerceBooleanProperty(required)
    this.stateChanges.next()
  }

  @Input()
  get disabled() {
    return this._disabled
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis)
    this.stateChanges.next()
  }

  get empty() {
    return !this._value
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ')
  }

  onDateSelect(value: Date) {
    setTimeout(() => {
      this.onTouched()

      this.value = value
      this.valueChange.emit(value)
      if (!this.showTime) {
        this.isPickerOpen = false
      }
    })
  }

  onContainerClick() {
    this.isPickerOpen = true
  }

  get inputTooltipPosition() {
    return lodash.isString(this.showInputTooltip) ? this.showInputTooltip : 'below'
  }

  getInputText = (value: Date) => {
    if (!value) return ''
    return moment(value).format(
      this.dateFormat ? this.dateFormat : this.showTime ? 'DD - MM - YYYY HH:mm' : 'DD - MM - YYYY'
    )
  }

  getInputTooltip = (value: Date, showInputTooltip: boolean | TooltipPosition) => {
    if (!showInputTooltip) return null
    return this.getInputText(value) || null
  }
}
