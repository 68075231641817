import { AbstractControl } from '@angular/forms'
import * as R from 'ramda'

export const positive = () => {
  return (control: AbstractControl) => {
    const value = control.value
    if (R.isNil(value) || value === '') {
      return
    }
    if (value <= 0) {
      return { positive: true }
    }
  }
}
