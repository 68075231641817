import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { Channel, Rental, ChannelNameTokens, Destroyable, selectOnce } from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap, finalize } from 'rxjs/operators'
import { selectAvailableRentalForNewAPIConnection } from '@tv3/store/connection/connection.selectors'
import { Observable } from 'rxjs'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  LinkConnectionWithRental,
  LinkConnectionWithRentalComplete,
  PushAvailabilityToConnectionComplete,
} from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { ConnectionHelperService } from '../../../connection-helper.service'
import { FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-trip-listing-wizard-step1',
  templateUrl: './trip-listing-wizard-step1.component.html',
  styleUrls: ['./trip-listing-wizard-step1.component.scss'],
})
export class TripListingWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()

  @Input() connection: Connection
  @Input() channel: Channel

  rentalIdCtrl = new FormControl('', [Validators.required])

  rentals$: Observable<Rental[]>

  isProcessing = false
  isLinked = false
  isAvailabilityPushed = false
  constructor(
    private store: Store<any>,
    private connectionHelperService: ConnectionHelperService,
    private actions: Actions
  ) {
    super()
  }

  ngOnInit(): void {
    this.rentals$ = this.store.pipe(
      selectOnce(selectAvailableRentalForNewAPIConnection, {
        id: this.channel.id,
        selected: this.connection.rentalId,
      })
    )

    this.rentalIdCtrl.setValue(this.connection.rentalId)
    if (this.connection.rentalId) {
      this.rentalIdCtrl.disable()
      this.isLinked = true
    }
  }

  onPushAvailability() {
    this.isProcessing = true
    this.connectionHelperService
      .pushAvailabilityForTiketLike(this.connection, ChannelNameTokens.Trip)
      .pipe(
        switchMap(() => this.actions.pipe(ofType(PushAvailabilityToConnectionComplete))),
        take(1),
        tap(() => (this.isAvailabilityPushed = true)),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe()
  }

  onLink() {
    this.isProcessing = true
    this.store.dispatch(
      LinkConnectionWithRental({
        connId: this.connection.id,
        channelName: ChannelNameTokens.Trip,
        data: {
          rentalId: this.rentalIdCtrl.value,
          channelId: this.connection.channelId,
          propertyId: this.connection.propertyId,
          roomId: this.connection.roomId,
        },
      })
    )
    this.actions
      .pipe(
        ofType(LinkConnectionWithRentalComplete),
        take(1),
        tap(() => (this.isLinked = true)),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe()
  }
}
