<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-tags"></i>&nbsp;&nbsp;
    {{ data.title || 'Manage Tags' }}
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-manage-entity-tags
    [type]="data.type"
    [id]="data.id"
    [tags]="data.tags || []"
    [showSuggesttedTags]="data.showSuggesttedTags || false"
    [autoSave]="false"
    [onSubmit]="data.onSubmit"
    #field
  ></app-manage-entity-tags>

  <div *ngIf="data.description" class="alert alert-secondary-info mt-4 mb-0">
    <i class="fal fa-info-circle alert-icon"></i>
    <div class="alert-content" [innerHTML]="data.description"></div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave()" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
