import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Message, plainTextMessage } from '@tv3/store/message/message.model'
import * as R from 'ramda'
import { AddMessage, AddMessageComplete, GetBookingMessages } from '@tv3/store/message/message.actions'
import {
  Account,
  AccountGuard,
  AlertDialogService,
  Attachment,
  ChannelService,
  Destroyable,
  DialogService,
  epochToView,
  isSomething,
  selectAccount,
  selectCurrentAccount,
  selectUsers,
  SessionStorage,
  sortAscend,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as jQuery from 'jquery'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import * as fromRoot from '@tv3/store/state'
import { Branding } from '@tv3/models/account/branding'
import { AttachmentDialogService } from '@tv3/shared/attachment/attachment-dialog.service'
import { selectTemplatesByTypes, Template, TemplateType } from '@tokeet-frontend/templates'
import { isAddingMessage, selectInquiries, selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { DataDictService } from '@tv3/services/data-dict.service'
import { Website } from '@tv3/store/website/website.model'
import { selectWebsitesForRental } from '@tv3/store/website/website.selectors'
import { Actions, ofType } from '@ngrx/effects'
import { debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { TemplateGuard } from '@tv3/guards/template.guard'
import { UserGuard } from '@tv3/guards/user.guard'
import { PreferencesGuard } from '@tv3/guards/preferences.guard'
import { Guest } from '@tv3/store/guest/guest.model'
import { SearchInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { InquiryGuard } from '@tv3/guards/inquiry.guard'
import { AuthService } from '@tv3/services/auth.service'
import { DataDictionaryDialogService } from '@tv3/shared/data-dictionary-dialog/data-dictionary-dialog.service'
import { WebsiteGuard } from '@tv3/guards/website.guard'
import { ActionFailed, secureFileLink } from '@tokeet-frontend/tv3-platform'
import { AiResponderService } from '@tv3/containers/ai-responder/store/ai-responder.service'
import { environment } from '@tv3/environments/environment'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { TinyMceEditorComponent, TinyMceEditor } from '@tokeet-frontend/tv3-platform'
import {
  availableMessageChannels,
  isFacebookEmail,
  isInstagramEmail,
  MessagingChannelGuard,
  MessagingChannelsConfig,
  MessagingChannelsService,
  MessagingChannelType,
  selectMessagingChannels,
  WhatsappMessageTemplate,
} from '@tokeet-frontend/message'
import * as lodash from 'lodash'
import { AppOverviewDialogService } from '@tokeet-frontend/billing'
import { LoadActivePlans } from '@tv3/store/plan/plan.actions'
import { MessagingSettingsDialogService } from '@tv3/containers/messaging-settings/settings/messaging-settings.service'
import { SmartDeviceAccess, SmartDeviceAccessService } from '@tokeet-frontend/smart-devices'

export function getLocalBookingMessageDraftKey(inquiryId: string) {
  return `${inquiryId}-message-draft`
}

@Component({
  selector: 'app-inquiry-message-form',
  templateUrl: './inquiry-message-form.component.html',
  host: { class: 'd-flex flex-column message-editor comment-composer-bottom-fixed bg-default2' },
  styleUrls: ['./inquiry-message-form.component.scss'],
})
export class InquiryMessageFormComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild('tinyMceEditor') tinyMceEditor: TinyMceEditorComponent

  @Input() inquiry: Inquiry
  @Input() guest?: Guest
  @Input() minEditorHeight = 250
  @Input() maxEditorHeight = 300
  @Input() draftMessage?: Message

  @Output() close = new EventEmitter()
  @Output() popup = new EventEmitter()

  @Input()
  @HostBinding('class.popup')
  isPopup = false

  get localBookingMessageDraftKey() {
    return getLocalBookingMessageDraftKey(this.inquiry.id)
  }

  form = this.fb.group({
    subject: [''],
    cc: [[]],
    bcc: [[]],
    messageHtml: ['', [Validators.required]],
    messageText: [''],
    addCost: [false],
    template: [''],
    attachments: [],
    delivery: ['email'],
    whatsappTemplateId: [],
    whatsappTemplateVariables: [],
  })

  messageDeliveryMethodOptions: {
    id: MessagingChannelType | 'email'
    logo: string
    label: string
    channel: string
    availableMessageFeatures: string[]
    isSubscriptionRequired?: boolean
  }[] = [
    {
      logo: '/assets/images/messaging/email.svg',
      channel: 'email',
      availableMessageFeatures: ['quote', 'template', 'attachment'],
      label: 'Email',
      id: 'email',
    },
    ...(availableMessageChannels as any),
  ]
  messagingChannels: MessagingChannelsConfig = {}

  inquiryCtrl = new FormControl('')
  ccUsers: { name: string; email: string; tag?: boolean }[] = []

  restrictedEmail: boolean

  branding: Branding
  channel: string | boolean
  rentalWebsites: Website[] = []
  account: Account
  deviceAccesses: SmartDeviceAccess[] = []

  hasCC = false
  hasBCC = false
  showEditor = false
  showHeader = false

  isAIGenerating = false
  isAISubscriptionValid = false

  users$ = this.store.pipe(select(selectUsers))
  inquiries$: Observable<{ id: string; name: string }[]>
  templates$ = this.store.pipe(select(selectTemplatesByTypes([TemplateType.Email])))
  isAddingMessage$ = this.store.pipe(select(isAddingMessage))

  MessagingChannelType = MessagingChannelType
  isWhatsappTemplatesOpen = false
  whatsappTemplates: WhatsappMessageTemplate[] = []
  isUsingWhatsappTemplate = false
  isMessagingSubscribed = false

  inquirySearch = new BehaviorSubject<string>('')

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    private actions$: Actions,
    private storage: SessionStorage,
    private dataChecker: DataCheckerService,
    private attachmentDialog: AttachmentDialogService,
    private channelService: ChannelService,
    private alertDialog: AlertDialogService,
    private dataDictService: DataDictService,
    private deviceAccessService: SmartDeviceAccessService,
    private auth: AuthService,
    private dataDictionaryDialogService: DataDictionaryDialogService,
    private store: Store<fromRoot.State>,
    private aiResponder: AiResponderService,
    private appOverviewDialog: AppOverviewDialogService,
    private messagingSettings: MessagingSettingsDialogService,
    private messagingChannelsService: MessagingChannelsService,
    private ngZone: NgZone
  ) {
    super()
    this.dataChecker.check([
      TemplateGuard,
      UserGuard,
      PreferencesGuard,
      InquiryGuard,
      AccountGuard,
      WebsiteGuard,
      MessagingChannelGuard,
    ])
  }

  ngOnInit() {
    this.inquirySearch
      .pipe(
        filter((name) => !R.isEmpty(name)),
        debounceTime(375),
        untilDestroy(this)
      )
      .subscribe((term) => {
        this.store.dispatch(SearchInquiries({ term }))
      })

    this.store
      .pipe(select(selectActiveProductPlanView(ProductsForPlan.TokeetAI)), untilDestroy(this))
      .subscribe((res) => {
        this.isAISubscriptionValid = !!res?.isValid
      })

    this.store.pipe(select(selectInquiry, { id: this.inquiry.id }), untilDestroy(this)).subscribe((inquiry) => {
      this.inquiry = inquiry
    })
    this.store.pipe(select(selectMessagingChannels), untilDestroy(this)).subscribe((d) => {
      this.messagingChannels = d
    })

    this.deviceAccessService.getBookingDeviceAccesses(this.inquiry.id).subscribe(
      (items) => {
        this.deviceAccesses = items
      },
      () => {}
    )

    this.messagingChannelsService.getWhatsappTemplates().subscribe(
      (templates) => {
        this.whatsappTemplates = templates
      },
      () => {}
    )

    this.store
      .pipe(select(selectWebsitesForRental, { id: this.inquiry.rentalId }), untilDestroy(this))
      .subscribe((webs) => {
        this.rentalWebsites = webs
      })

    this.store.pipe(select(selectCurrentAccount), untilDestroy(this)).subscribe((account) => {
      this.account = account
    })

    this.store
      .pipe(select(selectActiveProductPlanView(ProductsForPlan.Messaging)), untilDestroy(this))
      .subscribe((sub) => {
        this.isMessagingSubscribed = !!sub?.isValid
      })

    this.actions$.pipe(ofType(AddMessageComplete), untilDestroy(this)).subscribe(() => {
      this.storage.remove(this.localBookingMessageDraftKey)
      this.close.emit(true)
    })

    this.initForm(this.inquiry)
    this.checkIsChannel()
    this.form
      .get('messageHtml')
      .valueChanges.pipe(debounceTime(500), untilDestroy(this))
      .subscribe((msg) => {
        this.storage.set(this.localBookingMessageDraftKey, msg)
      })

    if (this.guest) {
      this.inquiryCtrl.patchValue(this.inquiry.id)
      this.inquiries$ = this.store.pipe(
        select(selectInquiries),
        map((inquiries) => R.filter((i: Inquiry) => i.guestId === this.guest.id, inquiries)),
        map((inquiries) =>
          R.map((i: Inquiry) => {
            const createdView = epochToView(i.created)
            return {
              id: i.id,
              name: `${R.pathOr('', ['guestDetails', 'name'], i)} / ${R.pathOr(
                '',
                ['rental', 'name'],
                i
              )} / ${createdView}`,
            }
          }, inquiries)
        )
      )
      this.inquiryCtrl.valueChanges
        .pipe(
          switchMap((id) => this.store.pipe(select(selectInquiry, { id }))),
          untilDestroy(this)
        )
        .subscribe((inquiry) => {
          this.initForm(inquiry)
        })
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showEditor = true
    }, 0)
  }

  isFeatureEnabled(feature: 'quote' | 'template' | 'attachment' | 'ai') {
    if (this.isUsingWhatsappTemplate) return false
    if (feature === 'ai') return true

    const channel = this.form.get('delivery').value
    const cfg = this.messageDeliveryMethodOptions.find((t) => t.channel === channel)
    if (!cfg) return true
    return cfg.availableMessageFeatures.includes(feature)
  }

  compileDataTokens(content: string) {
    return this.dataDictService.resolve(content, {
      inquiry: this.inquiry,
      rental: this.inquiry.rental,
      guest: this.inquiry.guest,
      websites: this.rentalWebsites,
      account: this.account,
      accesses: this.deviceAccesses,
    })
  }

  compileWhatsappTemplate(template: WhatsappMessageTemplate) {
    if (!isSomething(template.variables)) return of({ content: template.content, variables: {} })

    const content = template.content

    return combineLatest(
      lodash.map(template.variables || [], (tag) => {
        return this.compileDataTokens(tag)
      })
    ).pipe(
      // Render the template with the variables
      map((variables) => ({
        content: content.replace(/{{(\d+)}}/g, (_, key) => variables[key - 1]),
        variables: lodash.mapKeys(
          lodash.mapValues(variables, (t) => t),
          (v, i) => +i + 1
        ),
      }))
    )
  }

  onChangeWhatsappTemplate(delivery: string, template: WhatsappMessageTemplate) {
    this.isUsingWhatsappTemplate = true
    this.compileWhatsappTemplate(template).subscribe(({ content, variables }) => {
      this.form.patchValue({
        whatsappTemplateId: template.sid,
        whatsappTemplateVariables: JSON.stringify(variables),
        messageHtml: content,
        delivery,
      })
    })
  }

  onSubscribeMessaging() {
    this.appOverviewDialog
      .open(ProductsForPlan.Messaging)
      .afterClosed()
      .subscribe((res) => {
        this.store.dispatch(LoadActivePlans({}))
      })
  }

  getSelectedDelivery() {
    const delivery = this.form.get('delivery').value
    return this.messageDeliveryMethodOptions.find((t) => t.channel === delivery)
  }

  onChangeDelivery(delivery: string) {
    this.isUsingWhatsappTemplate = false
    this.form.patchValue({
      whatsappTemplateId: '',
      whatsappTemplateVariables: '',
    })
    const config = availableMessageChannels.find((t) => t.channel === delivery)
    if (!config || (!config.isSubscriptionRequired && !config.oauth)) {
      this.form.patchValue({ delivery })
      return
    }

    const isChannelEnabled = this.messagingChannels?.[delivery]?.enabled
    if (config.isSubscriptionRequired && !this.isMessagingSubscribed) {
      this.toaster.warning(`Please subscribe Messaging feature before you can use it.`)
      this.onSubscribeMessaging()
    } else if (config.oauth && !isChannelEnabled) {
      this.toaster.warning(`Please connect to ${config.label} before you can use it.`)
      this.messagingSettings.open()
    } else {
      this.form.patchValue({ delivery })
    }
  }

  enableQuote(inquiry: Inquiry) {
    const source = inquiry.inquirySource
    return !/airbnb|booking/gi.test(source)
  }

  isMsgChannelEnabled(msgChannel: MessagingChannelType | 'email') {
    const guestEmail = this.guest?.primaryEmail || this.inquiry.guestDetails?.email
    switch (msgChannel) {
      case MessagingChannelType.Facebook:
        return isFacebookEmail(guestEmail)
      case MessagingChannelType.Instagram:
        return isInstagramEmail(guestEmail)
      default:
        return true
    }
  }

  getDeliveryMethodFromSource(source: string) {
    const delivery = this.messageDeliveryMethodOptions.find((t) => t.channel === source)
    return delivery && this.isMsgChannelEnabled(delivery.id) ? delivery.channel : 'email'
  }

  initForm(inquiry: Inquiry) {
    const localMsg = this.storage.get(this.localBookingMessageDraftKey, '')
    this.form.patchValue({
      subject: this.getSubject(inquiry),
      messageHtml: this.isPopup ? localMsg : this.draftMessage?.messageHtml || '',
      delivery: this.getDeliveryMethodFromSource(inquiry.inquirySource),
    })
    this.ccUsers = this.getCCUsers(inquiry, this.ccUsers)
  }

  openDataDictionary() {
    return this.dataDictionaryDialogService.open().pipe(switchMap((tag) => this.compileDataTokens(tag)))
  }

  onPopup() {
    this.popup.emit(true)
    document.body.classList.add('message-form-popup')
  }

  onClose() {
    this.close.emit(true)
    document.body.classList.remove('message-form-popup')
  }

  onAiResponder() {
    if (!this.isAISubscriptionValid) {
      window.open(`${environment.appStoreUrl}/login?redirect=/details/tokeet_ai&token=${this.auth.token}`, '_blank')
      return
    }

    this.isAIGenerating = true
    this.aiResponder
      .generateReply({ account: this.inquiry.account, inquiryId: this.inquiry.id, convert: 'html', create: 'draft' })
      .pipe(finalize(() => (this.isAIGenerating = false)))
      .subscribe(
        ({ message }) => {
          this.tinyMceEditor.editor.resetContent(message)
          this.store.dispatch(GetBookingMessages({ bookingId: this.inquiry.id }))
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onTemplateChange([template]: [Template]) {
    if (template) {
      this.compileDataTokens(template.body).subscribe((compiledMessage) => {
        this.form.patchValue({ template, subject: template.subject, messageHtml: compiledMessage || '' })
      })
    }
  }

  onSend(form: FormGroup) {
    const msgCtrl = this.form.get('messageHtml')
    if (msgCtrl.invalid) {
      this.toaster.warning('Message content is required')
      return
    }
    const messageHtml = msgCtrl.value
    if (this.isMessageValid(this.inquiry, messageHtml)) {
      this.form.patchValue({
        attachments: this.getPresentAttachments(messageHtml),
        messageText: this.tinyMceEditor.editor.getContent({ format: 'text' }),
      })
      const { delivery, ...data } = form.getRawValue()
      this.store.dispatch(
        AddMessage({
          inquiry: this.inquiry,
          form: data,
          plainMessage: !!this.channel,
          channel: delivery,
        })
      )
    }
  }

  private checkIsChannel() {
    this.channel = this.channelService.channelFromEmail(this.inquiry.guest.primaryEmail)

    if (!!this.channel) {
      this.restrictedEmail = true
      this.alertDialog.open({
        title: 'Limited messaging ability',
        body: `Your message will be routed through ${this.channel}.<br>As a result you are restricted in what you may include<br>in your message. Links, attachments, and email addresses<br>are not allowed. ${this.channel} will block any message containing<br>restricted content.`,
      })
    }
  }

  private getMessageText(messageHtml: string): string {
    return plainTextMessage(String(this.getMessageHtmlWithoutAttachments(messageHtml)))
  }

  private getMessageHtmlWithoutAttachments(messageHtml: string): string {
    return jQuery(`<div>${messageHtml}</div>`).find('.attachment').remove().end().html()
  }

  private isMessageValid(inquiry: Inquiry, messageHtml: string) {
    const messageHtmlRaw = this.getMessageHtmlWithoutAttachments(messageHtml)
    const messageText = this.getMessageText(messageHtml)

    if (!inquiry.id || !inquiry.convoId || !inquiry.guestId) {
      this.alertDialog.open({
        title: 'Error',
        body: 'There is an error in your inquiry.',
      })
      return false
    } else if (!messageText || !messageHtmlRaw || messageHtmlRaw.length < 3 || messageText.length < 3) {
      this.alertDialog.open({
        title: 'Error',
        body: 'Your message must be over 2 characters.',
      })
      return false
    }

    return true
  }

  onEditorSetup(editor: TinyMceEditor) {
    editor.ui.registry.addButton('datadic', {
      icon: 'book',
      tooltip: `You may insert data tokens using data dictionary.`,
      onAction: () =>
        this.ngZone.run(() => {
          this.openDataDictionary().subscribe((tag) => editor.insertContent(tag))
        }),
    })

    editor.ui.registry.addButton('subject', {
      icon: 'settings',
      tooltip: `Toggle headers`,
      onAction: () =>
        this.ngZone.run(() => {
          this.showHeader = !this.showHeader
        }),
    })
  }

  onAttachment() {
    this.attachmentDialog
      .open()
      .pipe(
        tap((attachments: Attachment[]) => {
          const items = this.form.get('attachments').value || []
          this.form.patchValue({ attachments: [...items, ...attachments] })
        }),
        tap((attachments: Attachment[]) => {
          const message = this.form.get('messageHtml').value || ''
          const content = this.getAttachmentLinksHTML(attachments)
          this.form.patchValue({ messageHtml: `${message} ${content}` })
        })
      )
      .subscribe()
  }

  private getAttachmentLinksHTML(attachments: Attachment[]) {
    if (!attachments?.length) {
      return ''
    }

    const content = R.reduce(
      (content: string, file: Attachment) => {
        content += `<p>&#x1f4c2;&nbsp;&nbsp;<a href="${secureFileLink(
          file.url,
          this.inquiry?.guestId || this.guest?.id
        )}" target="_blank" id="${file.id}" class="attachment">${file.name}</a></p>`
        return content
      },
      '',
      attachments || []
    )

    return `<div class="attachments">${content}</div>`
  }

  addNewCC = (email: string) => {
    email = email.toLowerCase()
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    if (EMAIL_REGEXP.test(email)) {
      const newCC = { name: email, email: email, tag: true }
      this.ccUsers = R.append(newCC, <any>this.ccUsers)
      return newCC
    } else {
      this.alertDialog.open({
        title: 'Cannot send to that email.',
        body: 'The email you entered is invalid. AdvanceCM cannot send messsages to invalid email addresses. Please enter a valid email address to add to the CC of this message.',
      })
    }
  }

  private getCCUsers(inquiry: Inquiry, ccUsers: { name: string; email: string }[]) {
    const newCcUsers = R.clone(ccUsers)
    R.forEach((m: Message) => {
      if (m.type === 1) {
        const user = { name: m.name, email: m.replyTo }
        if (
          R.isNil(R.find((u) => u.name === user.name && u.email === user.email, newCcUsers)) &&
          inquiry.guest.primaryEmail !== m.replyTo
        ) {
          newCcUsers.push(user)
        }
      }
    }, R.defaultTo([], inquiry.messages))
    return <any>sortAscend('name')(newCcUsers)
  }

  private getSubject(inquiry: Inquiry): string {
    const rentalView = R.pathOr('', ['rentalView'], inquiry)
    const rentalName = R.pathOr(rentalView, ['rental', 'name'], inquiry)
    if (inquiry) {
      return `Re: Your inquiry about ${rentalName} (${inquiry.guestNameView}) - ${inquiry.id.substr(
        inquiry.id.length - 5
      )}`
    } else {
      return 'Re: Your inquiry'
    }
  }

  private getPresentAttachments(messageHTML: any): Attachment[] {
    const attachedFiles = []
    const attachments = this.form.get('attachments').value
    if (attachments && attachments.length > 0) {
      R.forEach((file: Attachment) => {
        if (jQuery('#' + file.id, messageHTML).length > 0) {
          attachedFiles.push(file)
        }
      }, attachments)
    }
    return attachedFiles
  }

  protected readonly Guest = Guest
}
