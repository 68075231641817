import { Observable, of } from 'rxjs'
import { CanActivate, CanLoad, Route } from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import { selectIncidentsLoaded } from './selectors'
import { LoadIncidents } from './actions'
import { IsDataLoaded } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class IncidentsGuard implements CanActivate, CanLoad, IsDataLoaded {
  constructor(private store: Store<any>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return this.store.pipe(
      select(selectIncidentsLoaded),
      tap((isLoaded) => {
        if (!isLoaded) {
          this.store.dispatch(LoadIncidents({}))
        }
      }),
      map(() => true),
      take(1)
    )
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
