import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators'
import * as R from 'ramda'
import * as fromRoot from '@tv3/store/state'
import { isSomething, Rental, selectRentalById, loadRental } from '@tokeet-frontend/tv3-platform'
import { environment } from '../../../../environments/environment'

export enum RentalRatesDialogTab {
  Calendar,
  Rates,
  RateMappings,
  Discounts,
  Settings,
}

export interface RentalRatesDialogInput {
  rental: Rental
  tab?: RentalRatesDialogTab
}

export interface RentalRatesDialogResult {
  ratesChanged: boolean
}

@Component({
  selector: 'app-rental-rates-dialog',
  templateUrl: './rental-rates-dialog.component.html',
  styleUrls: ['./rental-rates-dialog.component.scss'],
})
export class RentalRatesDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  tab = RentalRatesDialogTab.Calendar
  tabs = RentalRatesDialogTab

  initialized = false

  rental: Rental

  hasIncompleteBaseRate = true

  showDiscounts = environment.features.discounts

  private unsubscribe$ = new Subject<void>()

  constructor(
    private store: Store<fromRoot.State>,
    private dialogRef: MatDialogRef<RentalRatesDialogComponent, RentalRatesDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: RentalRatesDialogInput
  ) {}

  ngOnInit() {
    this.store.dispatch(loadRental({ id: this.data.rental.id }))

    this.rental = this.data.rental
    this.tab = this.data.tab || this.tab

    this.store
      .select(selectRentalById(this.rental.id))
      .pipe(
        distinctUntilChanged(),
        tap((rental) => {
          this.rental = rental
          const baseRate: any = R.pathOr({}, ['baseRate'], this.rental)
          this.hasIncompleteBaseRate = !isSomething(baseRate.nightly) || !isSomething(baseRate.minimum)
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngAfterViewInit() {
    this.dialogRef.afterOpened().subscribe(() => {
      setTimeout(() => (this.initialized = true), 250)
    })
  }

  close() {
    this.dialogRef.close()
  }
}
