import {
  ClearPaymentGateways,
  LoadPaymentGatewaysComplete,
  RemovePaymentGatewayComplete,
  SetDefaultPaymentGatewayComplete,
  TogglePaymentGatewayStatusComplete,
  UpdatePaymentGatewayComplete,
} from './gateways.actions'
import { gatewayEntityAdapter, GatewaysState, initialState } from './gateways.state'
import { Action, createReducer, on } from '@ngrx/store'

const reducer = createReducer(
  initialState,
  on(RemovePaymentGatewayComplete, (state, { id }) => gatewayEntityAdapter.removeOne(id, state)),
  on(SetDefaultPaymentGatewayComplete, (state, { id }) => {
    const update = (state.ids as string[]).map((stateId) => ({
      id: stateId,
      changes: {
        default: stateId === id ? 1 : 0,
      },
    }))
    return gatewayEntityAdapter.updateMany(update, state)
  }),
  on(TogglePaymentGatewayStatusComplete, (state, { id, status }) =>
    gatewayEntityAdapter.updateOne(
      {
        id,
        changes: {
          status: status ? 1 : 0,
        },
      },
      state
    )
  ),
  on(UpdatePaymentGatewayComplete, (state, { id, paymentGateway }) =>
    gatewayEntityAdapter.updateOne(
      {
        id,
        changes: paymentGateway,
      },
      state
    )
  ),
  on(ClearPaymentGateways, (state) =>
    gatewayEntityAdapter.removeAll({
      ...state,
      isLoaded: false,
    })
  ),
  on(LoadPaymentGatewaysComplete, (state, { gateways }) =>
    gatewayEntityAdapter.addAll(gateways, {
      ...state,
      isLoaded: true,
    })
  )
)

export function gatewaysReducer(state: GatewaysState | undefined, action: Action) {
  return reducer(state, action)
}

export const {
  selectIds: selectGatewaysIds,
  selectEntities: selectGatewaysEntities,
  selectAll: selectGatewaysAll,
  selectTotal: selectGatewaysTotal,
} = gatewayEntityAdapter.getSelectors()
