<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-fill">
        <h6 class="modal-title"><span *ngIf="!isEdit">New</span><span *ngIf="isEdit">Edit</span> Template</h6>
        <div class="flex-fill"></div>
        <mat-form-field2 [showLabel]="false" style="width: 200px" *ngIf="templates?.length > 0 && !data.hideTemplates">
          <app-select
            matTooltip="Switch to existing template."
            matTooltipPosition="above"
            bindLabel="name"
            bindValue="id"
            [multiple]="false"
            [items]="templates"
            [formControl]="templateCtrl"
            (selectedChange)="onSelect($event)"
            placeholder="Change template"
          ></app-select>
        </mat-form-field2>
        <button
          *ngIf="isEdit"
          (click)="onDelete()"
          [disabled]="1 | isReadonlyRole"
          type="button"
          class="btn btn-secondary-danger"
        >
          <span class="cs-icon cs-icon-trash"></span>
          Delete
        </button>
        <app-button
          (click)="editor?.save()"
          [disabled]="1 | isReadonlyRole"
          icon="cs-icon cs-icon-save"
          [class]="'success' | buttonClass"
        >
          Save
        </app-button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <app-template-editor
        [type]="data.type"
        [showType]="!data.hideType"
        [template]="template"
        #editor
      ></app-template-editor>
    </div>
  </div>
</div>
