import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import {
  AirbnbCancelBookingPayload,
  AirbnbChannelService,
  airbnbCancellationReasonTypes,
  getAirbnbCancellationReasonTypes,
} from '@tokeet-frontend/channels'
import {
  ConfirmDialogService,
  Destroyable,
  Rental,
  SaveForm,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { CancelBookingComplete, InquiryIsUpdated, CancelBooking } from '@tv3/store/inquiry/inquiry-fields.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-cancel-airbnb-booking-dialog',
  templateUrl: './cancel-airbnb-booking-dialog.component.html',
  styleUrls: ['./cancel-airbnb-booking-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class CancelAirbnbBookingDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    reason: [undefined, [Validators.required]],
    message_to_guest: ['', []],
  })
  reasons: typeof airbnbCancellationReasonTypes[0][] = []
  penaltyAmountUsdAccurate = 0
  isLoading = false
  noAirbnbCancelReason = false
  constructor(
    public dialogRef: MatDialogRef<CancelAirbnbBookingDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private confirm: ConfirmDialogService,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental; inquiry: Inquiry },
    private airbnbChannelService: AirbnbChannelService
  ) {
    super()
  }

  ngOnInit(): void {
    this.form
      .get('reason')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((reason) => {
        const reasonData = this.reasons?.find((t) => t.id === reason)
        const defaultValidators = [Validators.minLength(30), Validators.maxLength(240)]
        const messageCtrl = this.form.get('message_to_guest')
        if (reasonData?.message) {
          messageCtrl.setValidators([Validators.required, ...defaultValidators])
        } else {
          messageCtrl.setValidators(defaultValidators)
        }
        messageCtrl.updateValueAndValidity()
      })
    this.airbnbChannelService.getCancellationReasons(this.data.inquiry.inquiryId).subscribe(
      (res) => {
        this.reasons = getAirbnbCancellationReasonTypes(res)
        this.noAirbnbCancelReason = !this.reasons.length
        this.penaltyAmountUsdAccurate = res.cancellation_by_host?.penalty_amount_usd_accurate || 0
        if (this.noAirbnbCancelReason) {
          this.form.disable()
        }
      },
      (error) => {
        this.toaster.error('Unable to load data', '', error)
      }
    )
  }

  @SaveForm()
  onDecline(form: FormGroup) {
    const { reason, message_to_guest } = this.form.getRawValue()
    const reasonData = this.reasons?.find((t) => t.id === reason)
    let payload: AirbnbCancelBookingPayload
    if (reasonData.id === reasonData.mainId) {
      payload = {
        reason,
        message_to_guest,
      }
    } else {
      payload = {
        reason: reasonData.mainId,
        sub_reason: reason,
        message_to_guest,
      }
    }

    this.isLoading = true
    this.airbnbChannelService
      .cancelBooking(this.data.inquiry.inquiryId, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Booking canceled successfully')
          this.store.dispatch(CancelBookingComplete({ update: { id: this.data.inquiry.id, changes: { booked: 0 } } }))
          this.store.dispatch(InquiryIsUpdated({ update: { id: this.data.inquiry.id, changes: { booked: 0 } } }))
          this.dialogRef.close()
        },
        (error) => {
          this.toaster.error('Unable to canceled booking', '', error)
        }
      )
  }

  onCancel() {
    this.confirm
      .confirm({
        title: 'Cancel Booking',
        body: 'Are you sure you want to cancel the booking?',
      })
      .subscribe(() => {
        this.store.dispatch(CancelBooking({ inquiry: this.data.inquiry }))
        this.dialogRef.close()
      })
  }
}
