import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { Destroyable, Rental, ConfirmDialogService, ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { concatMap, finalize, switchMap, tap, toArray } from 'rxjs/operators'
import * as lodash from 'lodash'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CopyFeesFromDialogComponent } from '../copy-fees-from-dialog/copy-fees-from-dailog.component'
import { SelectFeesTableComponent } from '../select-fees-table/select-fees-table.component'
import { BookingFee, CreateBookingFeePayload } from '../../../store/fees/booking-fee'
import { BookingFeesService } from '../../../store/fees/booking-fees.service'

import { of } from 'rxjs'

@Component({
  selector: 'app-copy-fees-to-dialog',
  templateUrl: './copy-fees-to-dialog.component.html',
  styleUrls: ['./copy-fees-to-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class CopyFeesToDialogComponent extends Destroyable implements OnInit {
  @ViewChild(SelectFeesTableComponent) feesTable: SelectFeesTableComponent
  get fees() {
    return this.feesTable?.fees
  }
  get rental() {
    return this.data.rental
  }
  selectedFees: BookingFee[] = []
  selectedRentals: Rental[] = []

  isLoading = false
  progress = 0

  constructor(
    private store: Store<any>,
    private toaster: Toaster,
    private bookingFeesService: BookingFeesService,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopyFeesFromDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {}

  onSelectFees(items: BookingFee[]) {
    this.selectedFees = items
  }

  onSelectRentals(items: Rental[]) {
    this.selectedRentals = items
  }

  getRentalFees(rentalId: string) {
    return this.fees.filter((f) => f.rental_id === rentalId)
  }

  getSelectedFeesPayload(rentalId: string): CreateBookingFeePayload[] {
    return this.selectedFees.map((f) => ({
      ...lodash.omit(f, ['pkey']),
      rental_id: rentalId,
    }))
  }

  onOverwrite() {
    const doAction = (rentalId: string) => {
      return of(true).pipe(
        switchMap(() => this.bookingFeesService.deleteFees(this.getRentalFees(rentalId).map((f) => f.pkey))),
        switchMap(() => this.bookingFeesService.createFees(this.getSelectedFeesPayload(rentalId)))
      )
    }

    this.confirmDialog
      .confirm({
        title: 'Overwrite fees?',
        body: 'This action will overwrite fees in rentals.',
      })
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() =>
          of(...this.selectedRentals.map((r) => r.id)).pipe(
            concatMap((rentalId, i) => {
              this.progress = i + 1
              return doAction(rentalId)
            }),
            toArray()
          )
        ),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.toaster.success('Fees copied successfully')
          this.dialogRef.close('overwrite')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onAdd() {
    const doAction = (rentalId: string) => {
      return this.bookingFeesService.createFees(this.getSelectedFeesPayload(rentalId))
    }
    this.isLoading = true
    of(...this.selectedRentals.map((r) => r.id))
      .pipe(
        concatMap((rentalId, i) => {
          this.progress = i + 1
          return doAction(rentalId)
        }),
        toArray(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.toaster.success('Fees copied successfully')
          this.dialogRef.close('copy')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  close() {
    this.dialogRef.close()
  }
}
