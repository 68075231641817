import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditGuestDialogComponent } from './edit-guest-dialog.component'

@Injectable()
export class EditGuestDialogService {
  constructor(private dialog: MatDialog) {}

  open(guestId: string) {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { guestId },
    }

    return this.dialog.open(EditGuestDialogComponent, defaultConfig)
  }
}
