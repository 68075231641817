import { Component, Input, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { selectUsers } from '@tokeet-frontend/tv3-platform'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit {
  @Input() ctrl: FormControl
  @Input() placeholder = 'Select Users'
  @Input() label = 'Select Users'
  @Input() multiple = true
  @Input() showLabel = true
  @Input() showPlaceholder = true

  users$ = this.store.pipe(select(selectUsers))
  constructor(private store: Store<any>) {}

  ngOnInit(): void {}
}
