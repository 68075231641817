import { Injectable } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import { DiscountCodeBaseFilter } from '@tokeet-frontend/discount-codes'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { FilterGroup } from '@tv3/services/utils/filters.service'

@Injectable({ providedIn: 'root' })
export class DiscountCodeFilter extends DiscountCodeBaseFilter {
  constructor(protected store: Store<any>, protected fb: FormBuilder, protected storage: UserStorage) {
    super(store, fb, storage, FilterGroup.DiscountCodes)
  }
}
