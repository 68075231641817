import { createAction, props } from '@ngrx/store'
import { ListingsValidationFieldResult } from '../rental-validation-field'
import { HoliduStatus, HoliduListingModel, HoliduListingViewModel, HoliduUpdateListingModel } from './model'

//region Holidu
export const GetHoliduRentalValidations = createAction('[Holidu] Get Rental Validations')
export const GetHoliduRentalValidationsSuccess = createAction(
  '[Holidu] Get Rental Validations Success',
  props<{
    validations: ListingsValidationFieldResult
  }>()
)
export const GetHoliduRentalValidationsFailure = createAction(
  '[Holidu] Get Rental Validations Failure',
  props<{ err: Error }>()
)

export const GetHoliduStatus = createAction('[Holidu] Get Status')
export const GetHoliduStatusSuccess = createAction('[Holidu] Get Status Success', props<{ status: HoliduStatus }>())
export const GetHoliduStatusFailure = createAction('[Holidu] Get Status Failure', props<{ err: Error }>())

export const ConnectHolidu = createAction('[Holidu] Connect', props<{ providerId: string }>())
export const ConnectHoliduSuccess = createAction(
  '[Holidu] Connect Success',
  props<{ status: HoliduStatus; listings: HoliduListingModel[] }>()
)
export const ConnectHoliduFailure = createAction('[Holidu] Connect Failure', props<{ err: Error }>())

export const DisconnectHolidu = createAction('[Holidu] Disconnect', props<{ providerId: string }>())
export const DisconnectHoliduSuccess = createAction('[Holidu] Disconnect Success', props<{ providerId: string }>())
export const DisconnectHoliduFailure = createAction('[Holidu] Disconnect Failure', props<{ err: Error }>())

export const GetHoliduListings = createAction('[Holidu] Get Listings')
export const GetHoliduListingsSuccess = createAction(
  '[Holidu] Get Listings Success',
  props<{ listings: HoliduListingModel[] }>()
)
export const GetHoliduListingsFailure = createAction('[Holidu] Get Listings Failure', props<{ err: Error }>())

export const CreateHoliduListing = createAction('[Holidu] Create Listing', props<{ listing: HoliduListingViewModel }>())
export const CreateHoliduListingSuccess = createAction(
  '[Holidu] Create Listing Success',
  props<{ listing: HoliduListingModel }>()
)
export const CreateHoliduListingFailure = createAction(
  '[Holidu] Create Listing Failure',
  props<{ err: { error: { error: string[]; field: string }[] } }>()
)

export const UpdateHoliduListing = createAction(
  '[Holidu] Update Listing',
  props<{ listing: HoliduUpdateListingModel }>()
)
export const UpdateHoliduListingSuccess = createAction(
  '[Holidu] Update Listing Success',
  props<{ listing: HoliduListingModel }>()
)
export const UpdateHoliduListingFailure = createAction('[Holidu] Update Listing Failure', props<{ err: Error }>())

export const GetHoliduAmenities = createAction('[Holidu] Get Amenities')
export const GetHoliduAmenitiesSuccess = createAction(
  '[Holidu] Get Amenities Success',
  props<{ amenities: { id: string; name: string }[] }>()
)
export const GetHoliduAmenitiesFailure = createAction('[Holidu] Get Amenities Failure', props<{ err: Error }>())

export const DeleteHoliduListing = createAction('[Holidu] Delete Listing', props<{ propertyId: string }>())
export const DeleteHoliduListingSuccess = createAction(
  '[Holidu] Delete Listing Success',
  props<{ propertyId: string }>()
)
export const DeleteHoliduListingFailure = createAction('[Holidu] Delete Listing Failure', props<{ err: Error }>())
//endregion
