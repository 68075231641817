import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core'
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router'
import { AuthService } from './services/auth.service'
import { select, Store } from '@ngrx/store'
import {
  LocalStorage,
  selectAccountsLoaded,
  selectSomeOnce,
  Toaster,
  selectCurrentAccount,
  isSomething,
  User,
  Account,
  SessionStorage,
  DataCheckerService,
  AccountGuard,
  RentalRatesGuard,
} from '@tokeet-frontend/tv3-platform'
import { filter, share, switchMap, switchMapTo, take, tap, delay, map } from 'rxjs/operators'
import * as fromRoot from '@tv3/store/state'
import { IntercomService } from '@tv3/services/intercom.service'
import { AppRoutesService } from '@tv3/services/utils/routes.service'
import { WootricService } from '@tv3/services/wootric.service'
import { VisitTV3 } from './store/user-preferences/user-preferences.actions'
import {
  selectUserPreferences,
  selectUserPreferencesLoaded,
} from '@tv3/store/user-preferences/user-preferences.selectors'
import * as Sentry from '@sentry/browser'
import { SwUpdate } from '@angular/service-worker'
import { combineLatest, interval, of } from 'rxjs'
import { RefreshInquiries } from '@tv3/store/inquiry/inquiry.actions'
import { selectActiveTokeetPlanView } from '@tv3/store/plan/plan.selectors'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { GoogleTagManagerService } from './services/google-tag-manager.service'
import { RoutingService } from '@tv3/services/routing.service'
import { PhoneNumberRequestDialogService } from './shared/phone-number-request-dialog/phone-number-request-dialog.service'
import { PlanSubscriptionView } from './store/plan/plan.view'
import { ADMIN_MASQUERADE_FLAG_KEY } from './services/utils/storage-prefix'

import 'sortable-tablesort/sortable.min.js'
import * as lodash from 'lodash'
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard'
import { RentalGuard } from './guards/rental.guard'
import { UserPreferencesGuard } from './guards/user-preference.guard'
import { ActivePlanGuard } from './guards/active-plan.guard'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { PreferencesGuard } from './guards/preferences.guard'
import { EntityTagsGuard } from '@tokeet-frontend/tags'

declare const window: any
export const CHUNK_ERROR_KEY = 'CHUNK_ERROR_KEY'

@Component({
  selector: 'app-root',
  template: `
    <ng-progress></ng-progress>
    <ng-container *ngIf="authenticatedPageLoaded$ | async">
      <app-sidebar2></app-sidebar2>
      <app-navbar></app-navbar>
    </ng-container>
    <section #mainSection [ngClass]="appMainClass" dropdownScrollContainer>
      <ng-container *ngIf="authenticatedPageLoaded$ | async">
        <alert type="warning" class="d-block " *ngIf="isReadonly">
          You don’t have access to change anything on this account
        </alert>
        <alert type="warning" class="d-block mx-3" *ngIf="delinquent$ | async">
          <strong>Payment Failed:</strong> Your account is currently delinquent. Please update your card to ensure the
          account is running smoothly.
        </alert>
      </ng-container>
      <router-outlet></router-outlet>
    </section>
    <ng-container *ngIf="authenticatedPageLoaded$ | async">
      <app-phone-widget *ngIf="isFirstPaid$ | async"></app-phone-widget>
      <app-help-widget></app-help-widget>
      <app-automation-init-setup></app-automation-init-setup>
    </ng-container>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('mainSection', { static: true }) mainSection: ElementRef

  @HostBinding('class') get rootClass() {
    return lodash.snakeCase(location.pathname)
  }

  pageNavigationEnd$ = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
  pageNavigationStart$ = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationStart))

  authenticatedPageLoading$ = this.pageNavigationStart$.pipe(
    filter((data: NavigationStart) => this.appRoutesService.isProtected(data.url)),
    filter(() => this.authService.isAuthenticated),
    share()
  )
  authenticatedPageLoaded$ = this.pageNavigationEnd$.pipe(
    filter((data: NavigationEnd) => this.appRoutesService.isProtected(data.url)),
    filter(() => this.authService.isAuthenticated),
    share()
  )

  authenticatedPageActive$ = this.pageNavigationEnd$.pipe(
    map((data: NavigationEnd) => this.appRoutesService.isProtected(data.url) && this.authService.isAuthenticated),
    share()
  )

  isFirstPaid$ = this.routingService.isFirstPaid()
  delinquent$ = this.routingService.isDelinquent()

  appMainClass = ''

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    public appRoutesService: AppRoutesService,
    private authService: AuthService,
    private intercomService: IntercomService,
    private googleTagManagerService: GoogleTagManagerService,
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    private storage: LocalStorage,
    private routingService: RoutingService,
    private toaster: Toaster,
    private clipboard: ClipboardService,
    private wootricService: WootricService,
    private amplitudeService: AmplitudeService,
    private dataGuard: DataCheckerService,
    private sessionStorage: SessionStorage,
    private phoneNumberRequestDialog: PhoneNumberRequestDialogService
  ) {}

  get isMainPageScrollable() {
    const routes: [string, boolean][] = [['rates', true]]

    return !routes.find((route) => this.router.isActive(route[0], route[1]))
  }

  get isReadonly() {
    return this.authService.isReadOnly()
  }

  ngOnInit() {
    const oldHandler = this.router.errorHandler
    // Replace route error handler
    this.router.errorHandler = (err: any) => {
      // Check if there is an error loading the chunk
      if (err.stack && err.stack.indexOf('Error: Loading chunk') >= 0) {
        // Check if is the first time the error happened
        if (this.storage.get(CHUNK_ERROR_KEY) !== err.stack) {
          // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
          this.storage.set(CHUNK_ERROR_KEY, err.stack)
          this.toaster.info('New version of the app detected, reloading...')
          setTimeout(() => {
            window.location.reload(true)
          }, 2500)
        } else {
          // The chunk really does not exist after reload
          console.error(`We cannot find the chunk...`)
        }
      }
      // Run original handler
      oldHandler(err)
    }

    this.swUpdate.versionUpdates.pipe(filter((e) => e.type === 'VERSION_DETECTED')).subscribe(() => {
      const snack = this.snackbar.open('Update Available', 'Reload')
      snack.onAction().subscribe(() => {
        window.location.reload()
      })
    })

    if (this.authService.isAuthenticated) {
      this.intercomService.boot(this.authService.user)
      this.googleTagManagerService.update(this.authService.user)
      this.amplitudeService.setUser(this.authService.user)
      this.wootricService.run(this.authService.user)

      Sentry.configureScope((scope) => {
        const id = this.authService.user?.account?.toString() || ''
        scope.setUser({ id })
      })
    }

    this.authService.verify().subscribe()

    this.pageNavigationEnd$
      .pipe(
        tap(() => (this.mainSection.nativeElement.scrollTop = 0)),
        switchMap(() => this.routingService.isDelinquent())
      )
      .subscribe((isDelinquent) => {
        const classes = []
        if (this.appRoutesService.isProtected()) {
          classes.push('main-body')
        }

        if (!this.isMainPageScrollable) {
          classes.push('no-scroll')
        }

        if (this.isReadonly) {
          classes.push('global-alert')
        }
        if (isDelinquent) {
          classes.push('delinquent')
        }
        this.appMainClass = classes.join(' ')
        this.amplitudeService.logPageView()
      })

    this.authenticatedPageLoading$.pipe(take(1)).subscribe(() => {
      this.dataGuard.check([
        RentalGuard,
        RentalRatesGuard,
        ChannelGuard,
        AccountGuard,
        PreferencesGuard,
        UserPreferencesGuard,
        EntityTagsGuard,
      ])
    })

    this.authenticatedPageLoaded$
      .pipe(
        switchMap(() =>
          this.store.pipe(
            select(selectUserPreferencesLoaded),
            filter((isLoaded) => isLoaded && !this.authService.hasRole('readOnly')),
            take(1)
          )
        ),
        switchMap(() => this.store.pipe(selectSomeOnce(selectUserPreferences)))
      )
      .subscribe((preferences) => {
        if (!preferences.tv3Login) {
          // this.videoDialogService.open()
          this.store.dispatch(VisitTV3())
        }
      })

    this.authenticatedPageLoaded$.pipe(delay(2000), take(1)).subscribe(() => this.dataGuard.check([ActivePlanGuard]))

    this.authenticatedPageLoaded$
      .pipe(
        switchMapTo(this.store.pipe(select(selectAccountsLoaded))),
        filter((loaded) => !!loaded),
        switchMapTo(this.store.pipe(select(selectCurrentAccount))),
        take(1)
      )
      .subscribe((account) => {
        const user = this.authService.user
        const phone = isSomething(user.phone) ? user.phone : account.phone
        this.intercomService.update({ ...user, phone })
        this.googleTagManagerService.update({ ...user, phone } as User)
        this.amplitudeService.setUser({ ...user, phone } as User)
      })

    this.authenticatedPageLoaded$
      .pipe(
        take(1),
        switchMap(() => interval(90 * 1000))
      )
      .subscribe(() => {
        this.store.dispatch(RefreshInquiries())
      })

    this.authenticatedPageActive$.subscribe((active) => {
      document.body.classList.toggle('app-loaded', active)
    })

    combineLatest([
      this.store.pipe(select(selectCurrentAccount), filter(isSomething)),
      this.store.pipe(select(selectActiveTokeetPlanView), filter(isSomething)),
    ])
      .pipe(
        take(1),
        filter(([_, sub]: [Account, PlanSubscriptionView]) => sub.isPaid && sub.status === 'active'),
        switchMap(([account]) => {
          const user = this.authService.user
          const masquerade: boolean = this.sessionStorage.get(ADMIN_MASQUERADE_FLAG_KEY)
          if (!masquerade && this.authService.isAdmin() && (!account.phone || !user.phone)) {
            return this.phoneNumberRequestDialog.open().afterClosed()
          } else {
            return of(true)
          }
        })
      )
      .subscribe()

    this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.toaster.success('Copied to clipboard', 'Success')
      }
    })
  }
}
