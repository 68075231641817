import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { isSomething, selectSomeOnce, User } from '@tokeet-frontend/tv3-platform'
import { selectActivePlanStatusView } from '@tv3/store/plan/plan.selectors'
import * as R from 'ramda'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import * as moment from 'moment'
import { Store } from '@ngrx/store'

declare const window: any

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(private store: Store<any>) {}

  update(user: User) {
    if (environment.environment !== 'production' || !isSomething(user)) {
      return
    }
    this.store.pipe(selectSomeOnce(selectActivePlanStatusView)).subscribe((status) => {
      const payload = {
        event: 'user',
        user_id: user.id,
        user_email: user.primaryEmail,
        user_name: `${user.firstName} ${user.lastName}`,
        user_phone: user.phone,
        user_role: userRoleToString(user.roles),
        user_created_at: user.created,
        account: user.account,
        subscription: status?.planName,
        subscription_status:
          status.status === 'inactive' ? (status.delinquent ? 'past_due' : 'inactive') : status.status,
        subscription_cycle_start_at: status.start,
        subscription_cycle_end_at: status.until,
        subscription_updated_at: status.timestamp,
        trial: status?.trial_end > moment().unix(),
        trial_end_at: status.trial_end,
        rental_count: R.pathOr(0, ['rentals'], status),
        user_count: R.pathOr(0, ['users'], status),
      }
      window.dataLayer?.push({ ...payload })
    })
  }
}
