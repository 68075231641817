import { createSelector, select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { State } from '../channels.state'
import { combineLatest } from 'rxjs'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { channelsState } from '../channels.selectors'
import { TiketRoomModel, TiketRoomViewModel } from './model'

export const selectTiketState = createSelector(channelsState, (state) => state.tiket)

export const selectTiketStatus = createSelector(selectTiketState, (state) => state.status)

export const selectTiketConnected = createSelector(selectTiketState, (state) => state.status?.connected)
export const selectTiketHotelIds = createSelector(selectTiketState, (state) => state.status?.hotelIds || [])

export const selectTiketListings = createSelector(selectTiketState, (state) => state.listings || [])

export const getTiketListings = (store: Store<State>) =>
  combineLatest([store.pipe(select(selectTiketListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      return R.map((listing): TiketRoomModel => {
        const rental = R.find((r: Rental) => r.id === listing.rental_id, rentals)
        return {
          ...listing,
          rental,
          rental_name: rental?.name,
        } as TiketRoomViewModel
      }, listings) as TiketRoomViewModel[]
    })
  )
