import { Inquiry } from './inquiry.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Pagination } from '@tv3/interfaces/table/pagination'

export interface State extends EntityState<Inquiry> {
  isLoaded: boolean
  isCancelingBooking: boolean
  isConfirmingBooking: boolean
  isSavingNote: boolean
  isAddingMessage: boolean
  isUpdatingBooking: boolean
  pagination: Pagination
  tags: string[]
  sources: { id: string; name: string }[]
}

export const adapter: EntityAdapter<Inquiry> = createEntityAdapter<Inquiry>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isCancelingBooking: false,
  isConfirmingBooking: false,
  isSavingNote: false,
  isAddingMessage: false,
  isUpdatingBooking: false,
  pagination: {
    limit: 1000,
    skip: 0,
    hasMore: true,
  },
  tags: [],
  sources: [],
})
