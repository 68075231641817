import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, from, Observable, of } from 'rxjs'
import { catchError, concatMap, flatMap, map, mergeMap, tap, toArray } from 'rxjs/operators'
import { Toaster } from '../../services/toaster.service'
import { Attachment } from './attachment.model'
import { downloadBlob } from '../../functions'
import { ENVIRONMENT } from '../../tokens'
import { LocalStorage } from '../../storage/local-storage'
import { deserializeArray } from '../../functions/deserialize-array'
import * as lodash from 'lodash'

@Injectable()
export class AttachmentService {
  constructor(
    private http: HttpClient,
    private storage: LocalStorage,
    @Inject(ENVIRONMENT) private environment,
    private toast: Toaster
  ) {}

  all(): Observable<Attachment[]> {
    const url = '@api/attachments/all/'

    return this.http.get<object[]>(url).pipe(deserializeArray<Attachment>(Attachment))
  }

  delete(attachmentId: string): Observable<Attachment> {
    const url = `@api/attachment/delete/${attachmentId}`

    return this.http.delete(url).pipe(
      map(Attachment.deserialize),
      tap(() => this.toast.success('File deleted successfully.'))
    )
  }

  getById(id: string): Observable<Attachment> {
    const url = `@api/attachment/${id}`
    return this.http.get<object>(url).pipe(map((data) => Attachment.deserialize(data)))
  }

  getByIds(ids: string[]): Observable<Attachment[]> {
    if (!ids.length) return of([])

    return of(...lodash.chunk(ids, 10)).pipe(
      concatMap((ids) => combineLatest(lodash.map(ids, (id) => this.getById(id)))),
      toArray(),
      map((data) => lodash.flatten(data))
    )
  }

  get(url: string, type: string): Observable<Blob> {
    return this.http.get(url, this.getAttachmentDownloadHttpOptions()).pipe(
      map((content) => {
        return new Blob([content], { type: type })
      })
    )
  }

  deleteFiles(attachmentIds: string[]): Observable<Attachment[]> {
    return of(attachmentIds).pipe(
      flatMap((id) => from(id)),
      mergeMap((id) => this.http.delete(`@api/attachment/delete/${id}`).pipe(map(Attachment.deserialize))),
      toArray(),
      tap(() => this.toast.success('Files deleted successfully!'))
    )
  }

  getAttachmentDownloadHttpOptions(): any {
    return {
      responseType: 'arraybuffer',
      headers: {
        Authorization: this.storage.get(this.environment.storageKeys.token),
      },
    }
  }

  getAttachmentDownloadUrl(attachment: Attachment) {
    const apiUrl = this.environment.apiUrl
    if (attachment.uri) {
      return `${apiUrl}${attachment.uri}`
    } else if (attachment.location) {
      return `${apiUrl}/cabinet${attachment.location}`
    } else if (attachment.url) {
      const url = new URL(attachment.url)
      if (url.host.search('cabinet.tokeet.com') > -1) {
        return `${apiUrl}/cabinet${url.pathname}`
      } else {
        return attachment.url
      }
    }
    return ''
  }

  downloadFile(attachment: Attachment) {
    const uri = attachment.uri
    const name = attachment.name
    const path = attachment.path
    const type = attachment['content-type']

    const downloadUrl = this.getAttachmentDownloadUrl(attachment)
    const httpOptions = this.getAttachmentDownloadHttpOptions()

    let downloader: Observable<any>
    if (uri) {
      downloader = this.http.put(downloadUrl, { name: name, path: path }, httpOptions)
    } else {
      downloader = this.http.get(downloadUrl, httpOptions)
    }

    return downloader.pipe(
      map((response) => {
        const blob = new Blob([response], { type: type })
        downloadBlob(blob, name)
      }),
      catchError(() => {
        this.toast.error('Could not retrieve the attachment. File is missing.')
        return of(null)
      })
    )
  }

  downloadContractFile(file: Attachment) {
    const url = `@api/attachment/contract/${file.service}`
    return this.http.put(url, { path: file.location }, this.getAttachmentDownloadHttpOptions()).pipe(
      map((response) => {
        const type = file.type || 'application/pdf'
        const blob = new Blob([response], { type })
        downloadBlob(blob, file.name)
      }),
      catchError(() => {
        this.toast.error('Could not retrieve the attachment. File is missing.')
        return of(null)
      })
    )
  }

  downloadFiles(files: Attachment[]) {
    return of(...files).pipe(
      concatMap((file) => {
        if (file.contract) {
          return this.downloadContractFile(file)
        }
        return this.downloadFile(file)
      }),
      toArray()
    )
  }
}
