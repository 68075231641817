import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FormBuilder } from '@angular/forms'
import { CustomChannelResponse, DeleteCustomChannel, UpdateCustomChannelComplete } from '@tokeet-frontend/channels'
import { CustomChannelFormComponent } from './custom-channel-form/custom-channel-form.component'
import { ConfirmDialogService, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'

export enum CustomChannelOverlayTab {
  Details,
  Formulas,
}

@Component({
  selector: 'app-custom-channel-overlay',
  templateUrl: './custom-channel-overlay.component.html',
  styleUrls: ['./custom-channel-overlay.component.scss'],
})
export class CustomChannelOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('form', { static: false }) form: CustomChannelFormComponent

  channel: CustomChannelResponse
  tabs = CustomChannelOverlayTab
  currentTab = CustomChannelOverlayTab.Details
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { channel: CustomChannelResponse },
    public dialogRef: MatDialogRef<CustomChannelOverlayComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private confirm: ConfirmDialogService
  ) {
    super()
    this.channel = this.data.channel
  }

  ngOnInit() {
    this.actions.pipe(ofType(UpdateCustomChannelComplete), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }

  onDelete() {
    this.confirm.confirm().subscribe(() => {
      this.store.dispatch(DeleteCustomChannel({ id: this.channel.pkey }))
      this.close()
    })
  }

  onSave() {
    this.form.save()
  }
}
