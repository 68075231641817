import { Component, Inject } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { LosDiscount } from '@tokeet-frontend/los-discount'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'

import { LosDiscountFormComponent } from '../los-discount-form.component'

@Component({
  selector: 'app-los-discount-overlay',
  templateUrl: './los-discount-overlay.component.html',
  styleUrls: ['./los-discount-overlay.component.scss'],
})
export class LosDiscountOverlayComponent extends LosDiscountFormComponent {
  isEdit = true

  constructor(
    fb: FormBuilder,
    store: Store<any>,
    confirmDialog: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { item: LosDiscount },
    public dialogRef: MatDialogRef<LosDiscountOverlayComponent>
  ) {
    super(fb, store, confirmDialog)
    this.item = this.data.item
  }

  close() {
    this.dialogRef.close()
  }
}
