import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromCards from './card.reducer'
import { CardEffect } from './card.effect'
import { CardsService } from './card.service'
import { CardGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('cards', fromCards.cardReducer),
    EffectsModule.forFeature([CardEffect]),
  ],
  declarations: [],
  providers: [CardsService, CardGuard],
  exports: [],
})
export class CardStoreModule {}
