import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { MultipleItemsInputModel } from '@tv3/containers/guests/edit/multiple-items/multiple-items-input.model'

@Component({
  selector: 'app-multiple-items-dialog',
  templateUrl: './multiple-items-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./multiple-items-dialog.component.scss'],
})
export class MultipleItemsDialogComponent {
  form = this.fb.group({
    newItems: this.fb.array([]),
  })

  constructor(
    public dialogRef: MatDialogRef<MultipleItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MultipleItemsInputModel,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder
  ) {}

  get newItems() {
    return this.form.get('newItems') as FormArray
  }

  close() {
    this.dialogRef.close()
  }

  removeOldItem(index: number) {
    this.data.deleteItem(this.data.items()[index])
  }

  removeNewItem(index: number) {
    this.newItems.removeAt(index)
  }

  addNewItem() {
    this.newItems.push(this.getNewFormItem())
  }

  getNewFormItem() {
    return this.data.validators
      ? this.fb.group({ formItem: ['', this.data.validators] })
      : this.fb.group({ formItem: [''] })
  }

  makePrimaryOldItem(index: number) {
    this.data.makePrimary(this.data.items()[index])
    // this.removeOldItem(index);
  }

  makePrimaryNewItem(index: number) {
    if (this.newItems.getRawValue()[index].formItem) {
      this.data.makePrimary(this.newItems.getRawValue()[index].formItem)

      if (this.newItems.getRawValue().length > 1) {
        this.newItems.removeAt(index)
      } else {
        this.newItems.controls[index].get('formItem').setValue('')
      }
    }
  }

  @SaveForm()
  save(form: FormGroup) {
    const values = this.newItems.getRawValue().filter((item) => item.formItem)
    if (values.length > 0) {
      this.data.addItems(values.map((item) => item.formItem))
    }
    this.close()
  }
}
