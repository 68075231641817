import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'amountPlaceholder',
})
export class AmountPlaceholderPipe implements PipeTransform {
  transform(type: 'percent' | 'flat', symbol: string): string {
    if (type === 'percent') {
      return `Amount (%):`
    } else {
      return `Amount (${symbol}):`
    }
  }
}
