import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Destroyable, selectAllRentals, untilDestroy, ActionFailed } from '@tokeet-frontend/tv3-platform'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'

import { Room } from '../../../store/rooms/rooms.model'
import { selectAllRooms } from '../../../store/rooms/rooms.selectors'

@Component({
  selector: 'app-select-rooms-table',
  templateUrl: './select-rooms-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRoomsTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  @Input() excludedRentalIds: string[] = []
  @Input() set selectedRentalIds(rentalIds: string[]) {
    this.rentalsCtrl.setValue(rentalIds)
  }

  @Output() selected = new EventEmitter<Room[]>()

  displayedColumns: string[] = ['select', 'name']

  dataSource = new MatTableDataSource<Room>()
  selection = new SelectionModel<Room>(true, [])

  rentals$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => lodash.filter(rentals, (r) => !this.excludedRentalIds.includes(r.id)))
  )
  isLoading = false
  rentalsCtrl = new FormControl([])
  rooms: Room[] = []

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.store.pipe(select(selectAllRooms)).subscribe(
      (rooms) => {
        this.rooms = rooms
        this.refreshTableData(this.rentalsCtrl.value || [])
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
    this.rentalsCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((rentalIds) => {
      this.refreshTableData(rentalIds)
    })
    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selected.emit(this.selection.selected)
    })
  }

  refreshTableData(rentalIds?: string[]) {
    if (rentalIds?.length > 0) {
      this.dataSource.data = this.rooms.filter((f) => rentalIds.includes(f.rental_id))
    } else {
      this.dataSource.data = this.rooms.filter((f) => !this.excludedRentalIds.includes(f.rental_id))
    }
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: Room, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }
}
