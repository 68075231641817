import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import {
  ActivateHvmiListing,
  ActivateHvmiListingSuccess,
  DeactivateHvmiListing,
  DeactivateHvmiListingSuccess,
  HVMIChannelService,
  HvmiListingStatus,
} from '@tokeet-frontend/channels'
import { BehaviorSubject } from 'rxjs'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { Actions, ofType } from '@ngrx/effects'
import { switchMap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'

export enum FeedTabs {
  Connection,
  Config,
}

@Component({
  selector: 'app-hvmi-connection-overlay',
  templateUrl: './hvmi-connection-overlay.component.html',
  styleUrls: ['./hvmi-connection-overlay.component.scss'],
})
export class HvmiConnectionOverlayComponent extends Destroyable implements OnInit {
  tabs = FeedTabs
  tab = new BehaviorSubject<FeedTabs>(FeedTabs.Connection)

  channel: Channel
  connection: ConnectionView
  dateFormat = 'DD-MMM-YYYY | h:mm A'

  status: HvmiListingStatus

  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    switchMap(() => this.connectionService.getApiConnectionLog(this.channel.name, this.connection.propertyId))
  )

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { connection: ConnectionView },
    private channelService: HVMIChannelService,
    private connectionService: ConnectionService,
    private store: Store<any>,

    private actions: Actions,
    public dialogRef: MatDialogRef<HvmiConnectionOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.connection = this.data.connection
    this.channel = this.data.connection.channel

    this.channelService.getHvmiListingsStatuses().subscribe((statuses) => {
      this.status = statuses.find((s) => s.listingId === this.data.connection.roomId)
    })

    this.actions.pipe(ofType(ActivateHvmiListingSuccess), untilDestroy(this)).subscribe((statuses) => {
      this.status = { ...this.status, listingStatus: 'active' }
    })

    this.actions.pipe(ofType(DeactivateHvmiListingSuccess), untilDestroy(this)).subscribe((statuses) => {
      this.status = { ...this.status, listingStatus: 'inactive' }
    })
  }

  close() {
    this.dialogRef.close()
  }

  onToggleStatus() {
    if (this.status.listingStatus === 'active') {
      this.store.dispatch(DeactivateHvmiListing({ propertyId: this.connection.rentalId }))
    } else {
      this.store.dispatch(ActivateHvmiListing({ propertyId: this.connection.rentalId }))
    }
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
