<div class="modal-header">
  <h5 class="modal-title">
    <ng-container *ngIf="!isEditing">Add</ng-container>
    <ng-container *ngIf="isEditing">Edit</ng-container> Date Rule
    <small
      ><i
        matTooltip="Add conditions to automatically set a due date in relation to Arrival Date, Departure Date, or Invoice Date."
        matTooltipPosition="above"
        class="fal info fa-question-circle"
      ></i
    ></small>
  </h5>
  <button (click)="onClose()" type="button" class="close">
    <span class="far fa-times"></span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="box box-mt-has-pd clearfix">
    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input matInput type="number" placeholder="Days" formControlName="days" required />
          <mat-error *ngIf="form.get('operator').touched && form.get('operator').hasError('required')">
            Value is <strong>require</strong>
          </mat-error>
          <mat-error *ngIf="form.get('days').touched && form.get('days').hasError('minNumber')">
            Days need to be at least <strong>1</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="operator" placeholder="Before / After" required>
            <mat-option value="before">Before</mat-option>
            <mat-option value="after">After</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('operator').touched && form.get('operator').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('operator').touched && form.get('operator').hasError('afterInvoice')">
            Due Date should not before <strong>Invoice Date</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="event" placeholder="Event" required>
            <mat-option value="arrival">Arrival Date</mat-option>
            <mat-option value="departure">Departure Date</mat-option>
            <mat-option value="invoice">Invoice Date</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('event').touched && form.get('event').hasError('required')">
            Value is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <div class="alert alert-secondary-info">
      <i class="fas fa-info-circle alert-icon"></i>
      <div class="alert-content">
        If after triggering this rule, due date is calculated to be before invoice date, it will set to one day after
        invoice date.
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="onClose()" type="button" [ngClass]="config.cancelBtnClass">
    <i class="far fa-times"></i> cancel
  </button>
  <button *ngIf="isEditing" (click)="onClear()" type="button" [ngClass]="config.clearBtnClass">
    <i class="far fa-sync"></i> Reset
  </button>
  <button (click)="onSave(form)" type="button" [ngClass]="config.doneBtnClass">
    <i [ngClass]="config.doneBtnIconClass"></i> Save
  </button>
</div>
