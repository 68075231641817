import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AIResponderBookingReplyPayload } from './model'
import { AccountTokeetAiSettingsResponse, AiResponderSettings, UpdateAIResponderSettings } from './ai-settings.model'
import { map } from 'rxjs/operators'

@Injectable()
export class AiResponderService {
  constructor(private http: HttpClient) {}

  generateReply(payload: AIResponderBookingReplyPayload) {
    return this.http.post<{ message: string }>(`@api/ai/responder/reply`, payload)
  }

  getSettings() {
    return this.http
      .get<{ settings: AiResponderSettings }>(`@api/ai/responder/settings`)
      .pipe(map((res) => res.settings))
  }

  updateSettings(payload: UpdateAIResponderSettings) {
    return this.http.post(`@api/ai/responder/settings`, payload)
  }

  deleteFile(id: string) {
    return this.http.delete(`@api/ai/responder/files/${id}`)
  }

  getAccountAISettings() {
    return this.http.get<AccountTokeetAiSettingsResponse>(`@api/account/tokeet-ai-settings/`)
  }

  setAccountAISettings(data: AccountTokeetAiSettingsResponse) {
    return this.http.put(`@api/account/tokeet-ai-settings/`, { tokeet_ai_recharge_settings: data })
  }

  recharge(amount: number) {
    return this.http.post(`@api/subscriptions/tokeet-ai/recharge`, { product: 'tokeet_ai', amount })
  }
}
