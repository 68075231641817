import * as R from 'ramda'
import { TaxV3 } from '@tokeet-frontend/tv3-platform'
import { BookingEngineTax, TaxModalities } from '@tokeet/cost-resolver'

export function convertBookingEngineTax(taxes: BookingEngineTax[]) {
  return R.map((tax: BookingEngineTax) => {
    const t = new TaxV3()
    t.name = tax.name
    t.amount = (R.isNil(tax.rate) ? tax.amount : tax.rate) || 0
    t.type = tax.type
    t.modality = tax.modality || TaxModalities.PerStay
    return t
  }, taxes || [])
}
