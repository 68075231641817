import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { AgodaListingWizardComponent } from './agoda-listing-wizard.component'
import { AgodaListingWizardStep1Component } from './step1/agoda-listing-wizard-step1.component'
import { AgodaListingWizardStep2Component } from './step2/agoda-listing-wizard-step2.component'
import { AgodaListingWizardStep3Component } from './step3/agoda-listing-wizard-step3.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'

const components = [
  //
  AgodaListingWizardStep1Component,
  AgodaListingWizardStep2Component,
  AgodaListingWizardStep3Component,
  AgodaListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class AgodaListingWizardModule {}
