import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { automationsReducer } from './automation.reducer'
import { AutomationEffects } from './automation.effects'
import { AutomationEventNamePipe } from './automation-event.pipe'
import { AutomationEventHandlerPipe } from './automation-handler.pipe'
import { AutomationGuard } from './automation.guard'

const pipes = [AutomationEventNamePipe, AutomationEventHandlerPipe]

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('automation', automationsReducer),
    EffectsModule.forFeature([AutomationEffects]),
  ],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes, AutomationGuard],
})
export class AutomationStoreModule {}
