import { UserPreferences } from './user-preferences.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<UserPreferences> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<UserPreferences> = createEntityAdapter<UserPreferences>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
