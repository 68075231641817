import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CreateCustomChannelPayload, CustomChannelResponse, UpdateCustomChannelPayload } from './model'

@Injectable({
  providedIn: 'root',
})
export class CustomChannelService {
  constructor(private http: HttpClient) {}

  all() {
    const url = `@api/channel/custom/all`
    return this.http.get<CustomChannelResponse[]>(url)
  }

  create(payload: CreateCustomChannelPayload) {
    const url = `@api/channel/custom`
    return this.http.post<CustomChannelResponse>(url, payload)
  }

  update(id: string, payload: UpdateCustomChannelPayload) {
    const url = `@api/channel/custom/${id}`
    return this.http.put<CustomChannelResponse>(url, payload)
  }

  delete(id: string) {
    const url = `@api/channel/custom/delete/${id}`
    return this.http.delete(url)
  }
}
