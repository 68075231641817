import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { Observable, of, Subject, throwError } from 'rxjs'
import { catchError, distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators'
import * as R from 'ramda'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  asLocalDate,
  asUTCEpoch,
  epochToViewUTC,
  LoadGroupRatesComplete,
  SaveForm,
} from '@tokeet-frontend/tv3-platform'
import { Rate, RateDaySpecific, RateType, updateRentalComplete } from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { CurrencyService } from '@tv3/services/utils/currency.service'
import { RateSplitterService } from '@tokeet-frontend/tv3-platform'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { RentalRateService } from '@tv3/services/rental-rate.service'
import { GroupRateService } from '@tokeet-frontend/tv3-platform'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { PromotionalRateService } from '@tokeet-frontend/tv3-platform'
import { GroupRatesChangedComplete } from '@tokeet-frontend/tv3-platform'
import { atLeast } from '@tv3/validators/at-least.validator'
import * as moment from 'moment'
import { AmplitudeService } from '@tv3/services/amplitude.service'

export interface EditRateDialogInput {
  rental?: Rental
  rentals?: Rental[]

  type?: RateType
  rate?: Rate

  start?: number
  end?: number
  defaultRentals?: Rental[]

  isGroupRate?: boolean
  groupRates?: Rate[]
}

export interface EditRateDialogResult {
  rental?: Rental
  rate?: Rate

  rates?: Rate[]
  promotions?: Rate[]

  groupRate?: Rate
  groupRates?: Rate[]
}

@Component({
  selector: 'app-edit-rate-dialog',
  // tslint:disable-next-line use-host-property-decorator
  host: { class: 'modal-content' },
  templateUrl: './edit-rate-dialog.component.html',
  styleUrls: ['./edit-rate-dialog.component.scss'],
})
export class EditRateDialogComponent extends Destroyable implements OnInit {
  isEdit = false
  rateType: RateType
  dialogTitle: string

  currencySymbol = ''

  groupCollapsed = {
    additionalGuest: true,
    daySpecifics: true,
  }

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  specificDays = [
    { name: 'Monday', id: 0 },
    { name: 'Tuesday', id: 1 },
    { name: 'Wednesday', id: 2 },
    { name: 'Thursday', id: 3 },
    { name: 'Friday', id: 4 },
    { name: 'Saturday', id: 5 },
    { name: 'Sunday', id: 6 },
  ]

  specificDaysLimit = 7

  categories: string[] = []

  filteredCategories$: Observable<string[]>

  form: FormGroup

  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<HTMLElement>

  private unsubscribe$ = new Subject<void>()

  constructor(
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditRateDialogComponent, EditRateDialogResult>,
    private currencyService: CurrencyService,
    private toaster: Toaster,
    private rentalRateService: RentalRateService,
    private groupRateService: GroupRateService,
    private rateSplitterService: RateSplitterService,
    private promotionalRateService: PromotionalRateService,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: EditRateDialogInput
  ) {
    super()
  }

  ngOnInit() {
    const rate = this.data.rate || null
    const rental = this.data.rental || null

    this.isEdit = !!(rate && rate.rateId)
    this.rateType = this.getRateType()
    this.dialogTitle = this.getDialogTitle()

    if (this.isGroupRate) {
      this.prepareGroupRateData()
    } else if (rental) {
      this.prepareRentalRateData(rental)
    }

    this.form = this.buildForm(rate)

    this.roundFormValues()
    this.subscribeForm()

    if (rate) {
      this.groupCollapsed.additionalGuest = !(rate.additionalFeeAmount || rate.additionalFeeThreshold)
      this.groupCollapsed.daySpecifics = !(rate.daySpecifics && rate.daySpecifics.length)
    }

    if (!this.isPromotionalRate) {
      this.filteredCategories$ = this.form.get('category').valueChanges.pipe(
        startWith(''),
        map((name) => (name ? this.filterCategories(name) : this.categories.slice())),
        map((categories) => (R.isEmpty(categories) ? ['default'] : categories))
      )
    }
  }

  private filterCategories(category: string) {
    const filterValue = lodash.toString(category).toLowerCase()

    if (R.isEmpty(category)) {
      return this.categories
    }

    return this.categories.filter((c) => lodash.toString(c).toLowerCase().includes(filterValue))
  }

  get isPromotionalRate() {
    return this.rateType === 'promotion'
  }

  get isGroupRate() {
    return this.data.isGroupRate
  }

  get textRate() {
    return this.isGroupRate ? 'Group Rate' : 'Rate'
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    if (form.invalid) return

    const data = form.getRawValue()

    data.start = asUTCEpoch(data.start, 'start')
    data.end = asUTCEpoch(data.end, 'end')

    if (this.isPromotionalRate) {
      data.category = 'promotion'
    } else {
      lodash.unset(data, 'categories')
    }

    const rate = new Rate(data)

    if (this.data.rate) {
      if (this.isGroupRate) {
        rate.groupRateId = this.data.rate.rateId
      } else {
        rate.rentalRateId = this.data.rate.rateId
      }

      rate.account = this.data.rate.account
    }

    rate.type = this.rateType

    if (!this.validate(rate)) return

    // filter empty day specific item after validating
    if (rate.daySpecifics) {
      rate.daySpecifics = rate.daySpecifics.filter((item) => item.price && !lodash.isEmpty(item.days))
    }

    form.disable()

    let task

    if (this.isGroupRate) {
      task = this.saveGroupRate(rate)
    } else if (this.isPromotionalRate) {
      task = this.savePromotionalRate(rate)
    } else {
      task = this.saveRate(rate)
    }

    task
      .pipe(
        tap((output: EditRateDialogResult) => {
          if (!output) {
            form.enable()
            return
          }

          if (this.isGroupRate) {
            this.amplitudeService.logEvent('add-group-rate')
          } else {
            this.amplitudeService.logEvent(`add-${this.rateType}-rate`)
          }
          this.toaster.success(`${this.isGroupRate ? 'Group' : 'Rental'} rate saved successfully.`)
          this.close(output)
        }),
        catchError((error) => {
          this.toaster.error(`Unable to save ${this.isGroupRate ? 'group' : 'rental'} rate.`)
          form.enable()

          return throwError(error)
        })
      )
      .subscribe()
  }

  private saveGroupRate(rate: Rate) {
    return this.rateSplitterService.saveGroupRate(rate).pipe(
      map((result) => {
        if (!result) return null

        this.store.dispatch(GroupRatesChangedComplete({ rates: result.groupRates }))

        return { ...result }
      })
    )
  }

  private saveRate(rate: Rate) {
    const rentalId: string = R.path(['rental', 'id'], this.data)
    return this.rateSplitterService.saveRentalRate(rate, rentalId).pipe(
      map((result) => {
        const rental = this.updateRentalRates(rate, { rates: result.rates })

        return { ...result, rental: rental || null }
      })
    )
  }

  private savePromotionalRate(rate) {
    return this.promotionalRateService.saveRate(rate).pipe(
      map((result) => {
        if (result.overlap) {
          this.toaster.error(`Overlapping with rate ${result.overlap.name}`)
          return null
        }

        const rental = this.updateRentalRates(rate, { promotions: result.promotions })

        return { ...result, rental: rental || null }
      })
    )
  }

  onChangeDaySpecificItem(index: number) {
    if (index === 0) {
      this.daySpecificsControl.push(this.buildDaySpecific())

      setTimeout(() => {
        // scroll to bottom so added item can be seen.
        const elm = this.modalBody.nativeElement
        elm.scrollTop = elm.scrollHeight
      })
    } else {
      this.daySpecificsControl.removeAt(index)
    }
  }

  isDaySelected(index: number, day: number) {
    let selected = false

    lodash.forEach(this.daySpecificsControl.controls, (control, i) => {
      if (index === i) return

      const days = control.get('days').value as number[]

      if (days && days.includes(day)) {
        selected = true
        return false
      }
    })

    return selected
  }

  close(result: EditRateDialogResult = null) {
    this.dialogRef.close(result)
  }

  get daySpecificsControl() {
    return this.form.get('daySpecifics') as FormArray
  }

  private updateRentalRates(rate: Rate, data: { rates?: Rate[]; promotions?: Rate[] }) {
    let rental = this.data.rental

    if (!rental && this.data.rentals) {
      rental = lodash.find(this.data.rentals, (r) => r.id === rate.rentalId)
    }

    if (!rental) return

    const changes: Partial<Rental> = {}

    if (data.rates) changes.rates = data.rates
    if (data.promotions) changes.promotions = data.promotions

    this.store.dispatch(
      updateRentalComplete({
        update: {
          id: rental.id,
          changes,
        },
      })
    )

    return rental
  }

  private validate(rate: Rate) {
    let error

    if (this.rateType === 'standard') {
      error = this.validateStandardRate(rate)
    } else if (this.rateType === 'dynamic') {
      error = this.validateDynamicRate(rate)
    } else if (this.rateType === 'promotion') {
      error = this.validatePromotionalRate(rate)
    }

    if (!error && rate.minimumStay > rate.maximumStay && rate.maximumStay) {
      error = 'Maximum Stay should not be less than Minimum Stay.'
    }

    if (!error && !this.isValidSpecificDays(rate.daySpecifics)) {
      error = 'Day Specific Pricing missing nightly price or days.'
    }

    if (!error && !this.isPromotionalRate && !this.isValidCategory(rate.category)) {
      error = 'Invalid category name, please only enter characters in a-z, A-Z, 0-9, _ . and -.'
    }

    if (error) {
      this.toaster.error(error)
    }

    return !error
  }

  private validateStandardRate(rate: Rate) {
    if (!rate.nightly && !rate.weekend && !rate.weekly && !rate.monthly) {
      return 'You must specify at least one rate type.'
    }
  }

  private validatePromotionalRate(rate: Rate) {
    return this.validateStandardRate(rate)
  }

  private validateDynamicRate(rate: Rate) {
    if (rate.type !== 'dynamic') return false

    if (!rate.lowRate || !rate.highRate || !rate.lowThreshold || !rate.highThreshold) {
      return 'Rate and occupancy fields are required.'
    }

    if (rate.lowRate > rate.highRate) {
      return 'Low Rate cannot be higher than High Rate.'
    }

    if (rate.lowThreshold > rate.highThreshold) {
      return 'Low Occ cannot be higher than High Occ.'
    }

    if (!rate.thresholdType) {
      return 'Rate Interval must be specified.'
    }
  }

  private isValidCategory(category: string) {
    return category && /^[a-zA-Z0-9._-]*$/.test(category)
  }

  private isValidSpecificDays(daySpecifics: RateDaySpecific[]) {
    let valid = true

    lodash.forEach(daySpecifics, (item) => {
      if ((item.price && lodash.isEmpty(item.days)) || (!item.price && !lodash.isEmpty(item.days))) {
        valid = false
        return false
      }
    })

    return valid
  }

  private buildForm(rate: Rate) {
    let typeSpecificFields: Record<string, any> = {}

    if (this.rateType === 'standard' || this.rateType === 'promotion') {
      typeSpecificFields = {
        nightly: [null],
        monthly: [null],
        weekly: [null],
      }
    } else if (this.rateType === 'dynamic') {
      typeSpecificFields = {
        lowRate: [null, Validators.required],
        highRate: [null, Validators.required],
        lowThreshold: [null, Validators.required],
        highThreshold: [null, Validators.required],
        thresholdType: [null, Validators.required],
      }
    }

    if (this.isPromotionalRate) {
      typeSpecificFields.categories = [null, [Validators.required]]
    } else {
      typeSpecificFields.category = [null, [Validators.required]]
    }

    if (this.isGroupRate) {
      typeSpecificFields.rentalIds = [[], [Validators.required, atLeast(2)]]
    } else {
      typeSpecificFields.rentalId = [null, [Validators.required]]
    }

    const daySpecifics = this.formBuilder.array([])

    const form = this.formBuilder.group({
      name: [null, [Validators.required]],

      start: [null, [Validators.required]],
      end: [null, [Validators.required]],

      minimumStay: [null, [RxwebValidators.digit(), Validators.min(1)]],
      maximumStay: [null, [RxwebValidators.digit(), Validators.min(1)]],
      maximumGuests: [null, [RxwebValidators.digit(), Validators.min(1)]],

      additionalFeeAmount: [null],
      additionalFeePercent: [null],
      additionalFeeThreshold: [null, [RxwebValidators.digit(), Validators.min(1)]],

      daySpecifics,

      ...typeSpecificFields,
    })

    if (rate && this.rateType === 'dynamic') {
      rate = rate.clone({
        lowThreshold: this.adjustDynamicThreshold(rate.lowThreshold),
        highThreshold: this.adjustDynamicThreshold(rate.highThreshold),
      })
    }

    if (rate) {
      form.patchValue(lodash.omit(rate, ['daySpecifics', 'start', 'end']))

      form.get('start').setValue(asLocalDate(rate.start))
      form.get('end').setValue(asLocalDate(rate.end))

      lodash.forEach(rate.daySpecifics, (item) => {
        daySpecifics.push(this.buildDaySpecific(item))
      })
    } else {
      if (this.data.start) form.get('start').setValue(asLocalDate(this.data.start))
      if (this.data.end) form.get('end').setValue(asLocalDate(this.data.end))
    }

    if (!this.isGroupRate && this.data.rental) {
      form.get('rentalId').setValue(this.data.rental.id)
    }

    if (this.isGroupRate && this.data.defaultRentals) {
      form.get('rentalIds').setValue(this.data.defaultRentals.map((r) => r.id))
    }

    if (!daySpecifics.controls.length) {
      daySpecifics.push(this.buildDaySpecific())
    }

    if (!form.get('maximumGuests').value) {
      form.get('maximumGuests').setValue(2)
    }

    if (!form.get('category').value) {
      form.get('category').setValue('default')
    }

    if (!form.get('name').value && this.data.start && this.data.end) {
      form.get('name').setValue(`From (${epochToViewUTC(this.data.start)}) Until (${epochToViewUTC(this.data.end)})`)
    }

    return form
  }

  private buildDaySpecific(item: RateDaySpecific = null) {
    return this.formBuilder.group({
      price: [(item && item.price) || null],
      threshold: [(item && item.threshold) || null, [RxwebValidators.digit(), Validators.min(1)]],
      thresholdFee: [(item && item.thresholdFee) || null],
      days: [(item && item.days) || null],
    })
  }

  private adjustDynamicThreshold(value: number) {
    if (!value) return null

    return value < 1 ? Math.floor(value * 100) : value
  }

  private subscribeForm() {
    const fields = {
      additionalFeeAmount: 'additionalFeePercent',
      additionalFeePercent: 'additionalFeeAmount',
    }

    lodash.forEach(fields, (target, source) => {
      this.form
        .get(source)
        .valueChanges.pipe(
          distinctUntilChanged(),
          takeUntil(this.unsubscribe$),
          tap((value) => {
            const control = this.form.get(target)

            if (value || value === 0) {
              control.disable({ onlySelf: true })
            } else {
              control.enable({ onlySelf: true })
            }
          })
        )
        .subscribe()
    })

    if (!this.isGroupRate) {
      this.subscribeRentalRateForm()
    }
  }

  private subscribeRentalRateForm() {
    this.form
      .get('rentalId')
      .valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        tap((rentalId: string) => {
          const rental = lodash.find(this.data.rentals, (r) => r.id === rentalId)

          if (rental) this.prepareRentalRateData(rental)
        })
      )
      .subscribe()
  }

  private roundFormValues() {
    const integerFields = ['minimumStay', 'maximumStay', 'maximumGuests', 'additionalFeeThreshold']

    const decimalFields = [
      'nightly',
      'monthly',
      'weekly',
      'lowRate',
      'highRate',
      'lowThreshold',
      'highThreshold',
      'additionalFeeAmount',
      'additionalFeePercent',
    ]

    const daySpecificIntegerFields = ['threshold']
    const daySpecificDecimalFields = ['price', 'thresholdFee']

    lodash.forEach(integerFields, (field) => this.roundInteger(field))
    lodash.forEach(decimalFields, (field) => this.roundDecimal(field))

    for (let i = 0; i <= this.daySpecificsControl.length; i++) {
      lodash.forEach(daySpecificIntegerFields, (field) => this.roundInteger(field, i))
      lodash.forEach(daySpecificDecimalFields, (field) => this.roundDecimal(field, i))
    }
  }

  roundInteger(field: string, index?: number) {
    this.roundFieldValue((value) => lodash.round(value), field, index)
  }

  roundDecimal(field: string, index?: number) {
    this.roundFieldValue((value) => lodash.round(value, 2), field, index)
  }

  private roundFieldValue(round: (value: number) => number, field: string, index?: number) {
    const formControl = this.getFormControl(field, index)
    if (!formControl) return

    let value = formControl.value
    if (lodash.isNil(value)) return
    if (lodash.isString(value)) value = +value
    if (lodash.isNaN(value)) return

    const roundedValue = round(value)
    if (value.toString() === roundedValue.toString()) return

    formControl.setValue(roundedValue, { onlySelf: true })
  }

  private getFormControl(field: string, index?: number) {
    if (lodash.isNil(index)) return this.form.get(field) as FormControl

    const formGroup = this.daySpecificsControl.at(index)
    return formGroup && (formGroup.get(field) as FormControl)
  }

  private prepareRentalRateData(rental: Rental) {
    const currencySymbol = this.currencyService.getCurrencySymbol(rental.currency)

    this.currencySymbol = currencySymbol ? `(${currencySymbol})` : ''

    this.rentalRateService.getRateCategories(rental.id).subscribe((categories) => {
      this.categories = categories.map((item) => item.id)
    })
  }

  private prepareGroupRateData() {
    this.currencySymbol = ''
    ;(this.data.groupRates
      ? of(this.data.groupRates)
      : this.groupRateService.all().pipe(
          tap((groupRates) => {
            this.store.dispatch(LoadGroupRatesComplete({ rates: groupRates }))
          })
        )
    )
      .pipe(
        tap((groupRates) => {
          const rental = { rates: groupRates || [] } as Rental

          this.rentalRateService.getRateCategories(rental.id).subscribe((categories) => {
            this.categories = categories.map((item) => item.id)
          })
        })
      )
      .subscribe()
  }

  private getRateType() {
    return this.data.type || (this.data.rate && this.data.rate.type) || 'standard'
  }

  private getDialogTitle() {
    return `${this.isEdit ? 'Edit' : 'Add'} ${lodash.capitalize(this.rateType)} ${this.textRate}`
  }
}
