export const inquiryColors = [
  '#7bd148',
  '#5484ed',
  '#a4bdfc',
  '#46d6db',
  '#7ae7bf',
  '#51b749',
  '#fbd75b',
  '#ffb878',
  '#ff887c',
  '#dc2127',
  '#dbadff',
]
