import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BDCContentPropertySettings } from '@tv3/store/channel-property-setting/settings/bdc-property-settings'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

export function createForm(fb: FormBuilder) {
  return fb.group({
    pets_policy: [undefined, [Validators.required]],
    pets_fee: [undefined],
    // children: [undefined],
  })
}

@Component({
  selector: 'app-bdc-content-step-pet-form',
  templateUrl: './bdc-content-step-pet-form.component.html',
  styleUrls: ['./bdc-content-step-pet-form.component.scss'],
})
export class BdcContentStepPetFormComponent extends Destroyable implements OnInit, OnChanges {
  @Input() form: FormGroup
  @Input() settings: BDCContentPropertySettings

  petsNotAllowedPolicy = 'Pets Not Allowed'
  petPolicies = ['Pets Allowed', this.petsNotAllowedPolicy, 'Pets By Arrangements']
  petFeeOptions = [
    { value: 'free', label: 'Free' },
    { value: 'charges_may_apply', label: 'Charges May Apply' },
  ]
  isPetsFeeRequired = false

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.form
      .get('pets_policy')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((petsPolicy) => {
        this.setCtrlRequirement(this.form.get('pets_fee'), petsPolicy !== this.petsNotAllowedPolicy)
        this.isPetsFeeRequired = petsPolicy !== this.petsNotAllowedPolicy
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['settings'] && changes['settings'].currentValue) {
      this.form.patchValue({
        pets_policy: this.settings.pets_policy,
        pets_fee: this.settings.pets_fee,
      })
    }
  }

  setCtrlRequirement(ctrl: AbstractControl, isRequired: boolean) {
    if (isRequired) {
      ctrl.setValidators([Validators.required])
    } else {
      ctrl.clearValidators()
      ctrl.updateValueAndValidity()
    }
    ctrl.markAsUntouched()
  }
}
