import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'

import { isSomething, selectOnce, selectRentalById, Toaster, userFullname } from '@tokeet-frontend/tv3-platform'
import { MessageService } from '@tv3/store/message/message.service'
import {
  AddMessage,
  AddMessageComplete,
  DeleteMessage,
  DeleteMessageComplete,
  DeleteMessages,
  DeleteMessagesComplete,
  GetBookingMessages,
  GetBookingMessagesComplete,
  LoadLatestMessages,
  LoadLatestMessagesComplete,
  MarkAsRead,
  MarkAsReadComplete,
  MarkOneMessageAsRead,
  MarkOneMessageAsReadComplete,
  MoveMessage,
  MoveMessageComplete,
  RemoveMessageFromFeed,
  RemoveMessageFromFeedComplete,
  RemoveMessagesFromFeed,
  RemoveMessagesFromFeedComplete,
} from '@tv3/store/message/message.actions'
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { combineLatest, of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { ChannelMessagePayload, MessageRequest } from '@tv3/store/message/message.request'
import * as R from 'ramda'
import { TemplateType } from '@tokeet-frontend/templates'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Message, MessageType } from '@tv3/store/message/message.model'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { GetGuestEmailsComplete } from '@tv3/store/guest/guest.actions'
import { selectGuestById } from '@tv3/store/guest/guest.selectors'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { selectBrandingPreference } from '@tv3/store/preferences/preferences.selectors'
import { MessageForm } from '@tv3/interfaces/forms/message.form'
import { MessagingChannelKeys, MessagingChannelType } from '@tokeet-frontend/message'
import * as moment from 'moment'
import { AuthService } from '@tv3/services/auth.service'

@Injectable()
export class MessageEffects {
  @Effect()
  loadLatest$ = this.actions$.pipe(
    ofType(LoadLatestMessages),
    switchMap(({ messageType }) =>
      this.messageService.getLatest(messageType).pipe(
        map((messages) => LoadLatestMessagesComplete({ messages })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  private getNewMessagePayload(inquiry: Inquiry, data: MessageForm, plainMessage = false) {
    return combineLatest([
      this.store.pipe(select(selectGuestById, { id: inquiry.guestId })),
      this.store.pipe(select(selectBrandingPreference)),
      this.store.pipe(select(selectRentalById(inquiry.rentalId))),
      data.addCost ? this.inquiryChargeService.getCharges(inquiry) : of(null),
    ]).pipe(
      take(1),
      map(([guest, branding, rental, charges]) => {
        const request: MessageRequest = {
          inquiry_id: inquiry.id,
          convo_id: inquiry.convoId,
          guest_id: inquiry.guestId,
          rental_id: inquiry.rentalId,
          ref_id: inquiry.refId,
          api: R.pathOr('', ['attributes', 'api'], inquiry),
          source: inquiry.inquirySource,
          message_txt: data.messageText,
          message_html: data.messageHtml,
          rental_name: rental?.name,
          guest_name: guest?.name || R.pathOr('', ['guestDetails', 'name'], inquiry),
          guest_email: guest?.primaryEmail || R.pathOr('', ['guestDetails', 'email'], inquiry),
          guest_arrive: inquiry.guestArrive,
          guest_depart: inquiry.guestDepart,
          cc: R.map((i) => i.email, data.cc),
          bcc: data.bcc,
          subject: data.subject || `Re: Your inquiry about ${rental?.name || ''}`,
          from: rental?.email,
          attachments: data.attachments,
        }

        // @todo - this shouldn't be done here but on the backend
        if (charges && data.addCost) {
          request.charge = (charges.charge || 0).toFixed(2)
          request.discounts = charges.discountSum + charges.feeSum
          request.tax_fee = charges.taxFee
          request.sum = charges.sum
          request.quote = 1
          request.template = branding?.quoteTemplate || '1112223336ST-2'
          request.currency = rental?.currency?.code || ''
        } else if (data.template && data.template.type === TemplateType.Contract) {
          request.template = branding?.contractTemplate || 'contract-template'
          request.contractName = data.template.name
          request.contractTemplate = data.template.id
        } else {
          request.template = branding?.standardTemplate || '1112223337ST-2'
        }
        if (plainMessage) {
          request.template = ''
          request.message_html = plainMessage ? data.messageText : data.messageHtml
        }
        return request
      })
    )
  }

  private getNewChannelMessagePayload(
    channel: MessagingChannelKeys,
    data: MessageRequest,
    form: MessageForm,
    accountId: number
  ): ChannelMessagePayload {
    const payload: ChannelMessagePayload = {
      name: data.rental_name,
      guest_id: data.guest_id,
      inquiry_id: data.inquiry_id,
      convo_id: data.convo_id,
      rental_id: data.rental_id,
      channel,
      sender: {
        account: String(accountId),
      },
      receiver: {
        email: data.guest_email,
      },
      'message-data': {
        content: data.message_txt,
      },
    }

    if (channel.startsWith(MessagingChannelType.Whatsapp) && form.whatsappTemplateId) {
      payload['message-data'] = {
        content_id: form.whatsappTemplateId,
        content_variables: form.whatsappTemplateVariables,
      }
    }

    return payload
  }

  @Effect()
  addMessage$ = this.actions$.pipe(
    ofType(AddMessage),
    switchMap(({ inquiry, form, channel, plainMessage }) =>
      combineLatest([
        this.store.pipe(selectOnce(selectGuestById, { id: inquiry.guestId })),
        this.getNewMessagePayload(inquiry, form, plainMessage).pipe(),
      ]).pipe(map(([guest, request]) => ({ request, inquiry, guest, channel, form })))
    ),
    switchMap(({ request, inquiry, guest, channel, form }) =>
      (channel === 'email'
        ? this.messageService.create(request)
        : this.messageService.createChannelMessage(
            this.getNewChannelMessagePayload(channel, request, form, inquiry.account)
          )
      ).pipe(
        map((response) =>
          response.id
            ? response
            : Message.deserialize({
                ...request,
                type: MessageType.Outbound,
                name: userFullname(this.auth.user),
                received_on: moment().unix(),
                created: moment().unix(),
                pkey: btoa(new Date().toISOString()),
                sent_with: channel === 'email' ? 'tokeet' : channel,
              })
        ),
        tap(() => this.toast.success('Message sent successfully!')),
        switchMap((message) => {
          const actions: any[] = [
            AddMessageComplete({
              update: {
                id: inquiry.id,
                changes: { messages: R.append(message, inquiry.messages) },
              },
            }),
          ]
          if (guest) {
            actions.push(
              GetGuestEmailsComplete({
                update: { id: guest.id, changes: { emailMessages: R.append(message, guest.emailMessages) } },
              })
            )
          }
          return actions
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getBookingMessages$ = this.actions$.pipe(
    ofType(GetBookingMessages),
    concatMap(({ bookingId }) =>
      this.messageService.getMessagesByInquiryId(bookingId).pipe(
        map((messages) => GetBookingMessagesComplete({ update: { id: bookingId, changes: { messages } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markAsRead$ = this.actions$.pipe(
    ofType(MarkAsRead),
    concatMap(({ inquiry, messageIds }) =>
      this.messageService.markAsRead(messageIds).pipe(
        map(() => {
          const messages = R.map((m: Message) => {
            let message = new Message(m)
            message.read = 1
            return message
          }, inquiry.messages)
          return { id: inquiry.id, changes: { messages } }
        }),
        map((update) => MarkAsReadComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markOneAsRead$ = this.actions$.pipe(
    ofType(MarkOneMessageAsRead),
    concatMap(({ messageId }) =>
      this.messageService.markAsRead([messageId]).pipe(
        map((res) => MarkOneMessageAsReadComplete({ update: { id: messageId, changes: { read: 1 } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMessage$ = this.actions$.pipe(
    ofType(DeleteMessage),
    switchMap(({ message, inquiryId, silent }) =>
      this.messageService.remove(message).pipe(
        map(() => DeleteMessageComplete({ message, inquiryId })),
        tap(() => !silent && this.toast.success('Message deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMessages$ = this.actions$.pipe(
    ofType(DeleteMessages),
    switchMap(({ items, silent }) =>
      this.messageService.removeBatch(items.map((t) => t.message)).pipe(
        map(() => DeleteMessagesComplete({ items })),
        tap(() => !silent && this.toast.success('Messages deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMessageFromFeed$ = this.actions$.pipe(
    ofType(RemoveMessageFromFeed),
    switchMap(({ message }) =>
      this.messageService.removeFromFeed(message).pipe(
        map(() => RemoveMessageFromFeedComplete({ message })),
        tap(() => this.toast.success('Message removed successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  removeMessageFromFeeds$ = this.actions$.pipe(
    ofType(RemoveMessagesFromFeed),
    switchMap(({ messages }) =>
      this.messageService.removeFromFeedBatch(messages).pipe(
        map(() => RemoveMessagesFromFeedComplete({ messages })),
        tap(() => this.toast.success('Messages removed successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  moveMessage$ = this.actions$.pipe(
    ofType(MoveMessage),
    switchMap(({ fromInquiryId, toInquiryId, message }) =>
      this.messageService.move(message.id, toInquiryId).pipe(
        switchMap((r) =>
          this.store.pipe(
            select(selectInquiry, { id: fromInquiryId }),
            filter((inquiry) => isSomething(inquiry)),
            take(1),
            map((inquiry) => {
              const updated = new Inquiry(inquiry)
              updated.messages = R.filter((m: Message) => message.id !== m.id, R.defaultTo([], inquiry.messages))
              return updated
            })
          )
        ),
        tap(() => this.toast.success('Message moved successfully!')),
        map((inquiry) => ({ id: inquiry.id, changes: { messages: inquiry.messages } })),
        map((update) => MoveMessageComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private inquiryChargeService: InquiryChargeService,
    private messageService: MessageService,
    private toast: Toaster,
    private auth: AuthService
  ) {}
}
