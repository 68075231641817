import { epochToViewUTC } from '@tokeet-frontend/tv3-platform'

export enum LosDiscountType {
  Percent = 'pct',
  Flat = 'flat',
}

export enum LosDiscountModality {
  PerStay = 'ps',
  PerNight = 'pn',
}

export const availableLosDiscountModalities = [
  {
    value: LosDiscountModality.PerStay,
    label: 'Per Stay (PS)',
  },
  {
    value: LosDiscountModality.PerNight,
    label: 'Per Night (PN)',
  },
]

export interface LosDiscount {
  id: string

  type: LosDiscountType // This is the type of discount (pct, flat).
  name: string // The name of the LOS.
  description: string // The description of the LOS.
  modality: LosDiscountModality

  amount: number
  length: number // The minimum length of stay required for the discount.
  guests: number // The minimum number of guests required for the discount.

  rentals?: string[] // The OPTIONAL tokeet rental ids of the specific rental.
  channels?: string[] // The OPTIONAL channel tokens this LOS is associated with.
  start?: number // The OPTIONAL time (epoch) when the LOS will start being active.
  end?: number // The OPTIONAL time (epoch) when the LOS will end.

  created?: number
}

export interface LosDiscountView extends LosDiscount {
  rentalsView: string
  channelsView: string
  startView: string
  endView: string
  createdView: string
}

export function toLosDiscountView(item: LosDiscount): LosDiscountView {
  return {
    ...item,
    startView: epochToViewUTC(item.start),
    endView: epochToViewUTC(item.end),
    createdView: epochToViewUTC(item.created),
    rentalsView: '',
    channelsView: '',
  }
}
