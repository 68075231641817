<form *ngIf="step === steps.form" [formGroup]="form" [formEditablePermission]="form">
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="companyName" placeholder="Legal Company Name:" />
        <mat-hint>e.g.: HomeToGo GmbH</mat-hint>
        <mat-error *ngIf="form.get('companyName').touched && form.get('companyName').hasError('required')">
          Legal Company Name is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="street" placeholder="Street Name and Number:" />
        <mat-error *ngIf="form.get('street').touched && form.get('street').hasError('required')">
          Street Name and Number is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="zip" placeholder="ZIP/Postal Code:" />
        <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('required')">
          ZIP/Postal Code is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="city" placeholder="City:" />
        <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('required')">
          City is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <app-select-search
        [items]="countryList"
        [ctrl]="form.get('countryCode')"
        [multiple]="false"
        bindLabel="name"
        bindValue="id"
        placeholder="Country:"
      ></app-select-search>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="vat" placeholder="Company VAT number:" />
        <mat-hint
          >Applicable for companies registered in the European Union. Country Code and VAT Number e.g. DE123456789
        </mat-hint>
        <mat-error *ngIf="form.get('vat').touched && form.get('vat').hasError('required')">
          VAT is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="email" placeholder="Email:" />
        <mat-hint>Email address for all company correspondence</mat-hint>
        <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input required matInput type="text" formControlName="phone" placeholder="Phone:" />
        <mat-hint>Phone number for all company correspondence</mat-hint>
        <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('required')">
          Phone is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input matInput type="text" formControlName="website" placeholder="Company website URL:" />
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input
          required
          matInput
          type="text"
          formControlName="rentalCount"
          placeholder="Total amount of properties you can provide:"
        />
        <mat-hint
          >What is the average available inventory you can provide through the AdvanceCM feed to us at any one
          time?</mat-hint
        >
        <mat-error *ngIf="form.get('rentalCount').touched && form.get('rentalCount').hasError('required')">
          Total properties is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('rentalCount').touched && form.get('rentalCount').hasError('minNumber')">
          Total properties <strong>must be at least 1</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input
          required
          matInput
          type="text"
          formControlName="location"
          placeholder="Location & distribution of your properties:"
        />
        <mat-hint>e.g. 20% UK, 10% France, 50% USA, 30% China</mat-hint>
        <mat-error *ngIf="form.get('location').touched && form.get('location').hasError('required')">
          Location is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
      <mat-form-field2 class="mat-block">
        <input matInput type="text" formControlName="additional" placeholder="Additional information" />
      </mat-form-field2>

      <div class="terms-block">Terms & Conditions</div>
      <mat-checkbox class="mt-primary mat-block" formControlName="terms" required>
        By checking this box, I am accepting HomeToGo's Partner Integration
        <a target="_blank" href="https://www.hometogo.com/partner-integration/">Terms &amp; Conditions</a><br />
        on behalf of the entity stated under "Legal Company Name"<br />and I agree to the Partner Integration Terms &
        Conditions on behalf of the entity<br />that I represent.
      </mat-checkbox>
      <mat-error style="position: absolute" *ngIf="form.get('terms').touched && form.get('terms').hasError('required')">
        You have to accept terms and conditions
      </mat-error>
    </div>
    <div class="col-sm-12 submit-block">
      <button
        [disabled]="submitting || (1 | isReadonlyRole)"
        (click)="onFinish(form)"
        type="button"
        class="btn btn-secondary-info ml-2"
      >
        <i *ngIf="!submitting" class="fal fa-chevron-right"></i>
        <i *ngIf="submitting" class="far fa-spinner fa-spin"></i> Next
      </button>
    </div>
  </div>
</form>

<ng-container *ngIf="step === steps.config">
  <app-hometogo-channel-config-form
    [saveText]="'Connect'"
    [title]="'Channel Config'"
    [taxesFees]="'bulk'"
    [channel]="channel"
    [config]="config"
    (save)="onConfigSave($event)"
  ></app-hometogo-channel-config-form>
</ng-container>
