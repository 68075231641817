export enum AutomationTypes {
  Booking = 'booking',
  Charge = 'charge',
  CreditCard = 'card',
  Expense = 'expense',
  Extra = 'extra',
  Incident = 'incident',
  Invoice = 'invoice',
  Device = 'device',
  Message = 'message',
  Review = 'review',
  Rate = 'rate',
  Task = 'task',
  Rental = 'rental',
}

export enum AutomationServiceTypes {
  TV3 = 'tv3',
  Advance = 'advance',
  Signature = 'signature',
  Webready = 'webready',
  Automata = 'automata',
  Task = 'task',
  Expense = 'expense',
}

export const enabledAutomationServices = [
  AutomationServiceTypes.TV3,
  AutomationServiceTypes.Advance,
  //
  // AutomationTypes.Booking,
  // AutomationTypes.Charge,
  // AutomationTypes.CreditCard,
  // AutomationTypes.Device,
  // AutomationServiceTypes.Expense,
  // AutomationTypes.Extra,
  // AutomationTypes.Incident,
  // AutomationTypes.Invoice,
  // AutomationTypes.Message,
  // AutomationTypes.Review,
  // AutomationServiceTypes.Task,
]

export interface AutomationTypesConfig {
  type: AutomationTypes
  icon: string
  color: string
  label: string
  tooltip: string
}

export const automationTypesConfig: AutomationTypesConfig[] = [
  {
    type: AutomationTypes.Booking,
    icon: 'cs-icon cs-icon-calendar-clock',
    color: 'text-info',
    label: 'Bookings',
    tooltip: 'It allows you to setup Automation for Bookings related different statuses & conditions',
  },
  {
    type: AutomationTypes.Charge,
    icon: 'cs-icon cs-icon-dollar-square',
    color: 'text-success',
    label: 'Charges',
    tooltip: 'It allows you to setup Automation for Charges related different statuses & conditions',
  },
  {
    type: AutomationTypes.CreditCard,
    icon: 'cs-icon cs-icon-card2',
    color: 'text-info',
    label: 'Credit Card',
    tooltip: 'It allows you to setup Automation for Credit Card related different statuses & conditions',
  },
  {
    type: AutomationTypes.Expense,
    icon: 'cs-icon cs-icon-wallet',
    color: 'text-primary',
    label: 'Expenses',
    tooltip: 'It allows you to setup Automation for Expenses related different statuses & conditions',
  },
  {
    type: AutomationTypes.Extra,
    icon: 'cs-icon cs-icon-dollar-circle-plus-circle',
    color: 'text-danger',
    label: 'Extras',
    tooltip: 'It allows you to setup Automation for Extras related different statuses & conditions',
  },
  {
    type: AutomationTypes.Incident,
    icon: 'cs-icon cs-icon-building-incident',
    color: 'text-primary',
    label: 'Incidents',
    tooltip: 'It allows you to setup Automation for Incidents related different statuses & conditions',
  },
  {
    type: AutomationTypes.Invoice,
    icon: 'cs-icon cs-icon-file-dollar',
    color: 'text-indigo',
    label: 'Invoices',
    tooltip: 'It allows you to setup Automation for Invoices related different statuses & conditions',
  },
  {
    type: AutomationTypes.Device,
    icon: 'cs-icon cs-icon-smart-device2',
    color: 'text-secondary',
    label: 'Locks',
    tooltip: 'It allows you to setup Automation for Smart Locks related different statuses & conditions',
  },
  {
    type: AutomationTypes.Message,
    icon: 'cs-icon cs-icon-chat2',
    color: 'text-danger',
    label: 'Messages',
    tooltip: 'It allows you to setup Automation for Messages related different statuses & conditions',
  },
  {
    type: AutomationTypes.Review,
    icon: 'cs-icon cs-icon-chat-star',
    color: 'text-warning',
    label: 'Reviews',
    tooltip: 'It allows you to setup Automation for Reviews related different statuses & conditions',
  },
  {
    type: AutomationTypes.Rate,
    icon: 'cs-icon cs-icon-dollar-cycle',
    color: 'text-success',
    label: 'Rates',
    tooltip: 'It allows you to setup Automation for Rates related different statuses & conditions',
  },
  {
    type: AutomationTypes.Task,
    icon: 'cs-icon cs-icon-board-check',
    color: 'text-indigo',
    label: 'Tasks',
    tooltip: 'It allows you to setup Automation for Tasks related different statuses & conditions',
  },
]
