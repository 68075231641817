import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  AgodaAdditionalFee,
  AgodaAdditionalFeeCodes,
} from '@tv3/store/channel-property-setting/settings/agoda-property-settings'
import * as R from 'ramda'

export function createForm(fb: FormBuilder) {
  return fb.group({
    cleaning: [0, [Validators.required, Validators.min(0)]],
    facility: [0, [Validators.required, Validators.min(0)]],
  })
}

@Component({
  selector: 'app-agoda-content-step-additional-fees-form',
  templateUrl: './agoda-content-step-additional-fees-form.component.html',
  styleUrls: ['./agoda-content-step-additional-fees-form.component.scss'],
})
export class AgodaContentStepAdditionalFeesFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup
  @Input() fees: AgodaAdditionalFee[]

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fees'] && changes['fees'].currentValue) {
      this.setForm(this.fees)
    }
  }

  setForm(fees: AgodaAdditionalFee[]) {
    const cleaning = R.find((fee) => fee.code === AgodaAdditionalFeeCodes.Cleaning, fees || [])
    const facility = R.find((fee) => fee.code === AgodaAdditionalFeeCodes.Facility, fees || [])
    if (this.fees) {
      this.form.patchValue({
        cleaning: (cleaning && cleaning.amount) || 0,
        facility: (facility && facility.amount) || 0,
      })
    }
  }
}
