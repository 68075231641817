import { Observable, of } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import * as R from 'ramda'
import { IsDataLoaded } from '@tokeet-frontend/tv3-platform'
import { ThirdPartyIntegrationIdentifies } from '../store/integration/integration.model'
import { selectAuthorizeIntegrationsLoaded } from '../store/integration/integration.selectors'
import { LoadAuthorizedIntegration } from '../store/integration/integration.actions'

@Injectable({ providedIn: 'root' })
export class IntegrationGuard implements CanActivate, CanActivateChild, CanLoad, IsDataLoaded {
  constructor(private store: Store<any>) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const plans = route.data['integrations']
    return this.isDataLoaded(plans).pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(
    integrations: ThirdPartyIntegrationIdentifies[] = [
      ThirdPartyIntegrationIdentifies.Dropbox,
      ThirdPartyIntegrationIdentifies.Mailchimp,
      ThirdPartyIntegrationIdentifies.Slack,
      ThirdPartyIntegrationIdentifies.Wheelhouse,
    ]
  ): Observable<boolean> {
    if (!integrations || !integrations.length) {
      return of(true)
    }

    return this.store.pipe(
      select(selectAuthorizeIntegrationsLoaded),
      tap((isAuthorizedIntegrationLoaded) => {
        R.forEach((integration: ThirdPartyIntegrationIdentifies) => {
          if (!isAuthorizedIntegrationLoaded[integration]) {
            this.store.dispatch(LoadAuthorizedIntegration({ integration }))
          }
        }, integrations)
      }),
      map(() => true),
      take(1)
    )
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const plans = childRoute.data['integrations']
    return this.isDataLoaded(plans)
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const plans = route.data['integrations']
    return this.isDataLoaded(plans)
  }
}
