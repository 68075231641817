import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { tap } from 'rxjs/operators'

import {
  DataCheckerService,
  Destroyable,
  Rate,
  RentalRatesGuard,
  selectAllRentals,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { environment } from '@tv3/environments/environment'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { AddDiscountCodeComplete } from '@tokeet-frontend/discount-codes'
import { AddLosDiscountComplete } from '@tokeet-frontend/los-discount'

export enum AccountRatesDialogTab {
  ChannelAutomations,
  GroupRates,
  LosDiscounts,
  DiscountCodes,
  MinStayAutomations,
  GapNightAutomations,
  DynamicRatesAutomations,
  RatePushAutomations,
  LastMinuteAutomations,
}

export interface AccountRatesDialogInput {
  activeTab?: AccountRatesDialogTab
  focusGroupRate?: Rate
}

@Component({
  selector: 'app-account-rates-dialog',
  templateUrl: './account-rates-dialog.component.html',
  styleUrls: ['./account-rates-dialog.component.scss'],
})
export class AccountRatesDialogComponent extends Destroyable implements OnInit, AfterViewInit {
  tab = AccountRatesDialogTab.ChannelAutomations
  tabs = AccountRatesDialogTab

  rentalsCount: number = null

  @ViewChild('ratesTable', { static: false }) ratesTable: any

  showDiscounts = environment.features.discounts

  constructor(
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private amplitudeService: AmplitudeService,
    private dialogRef: MatDialogRef<AccountRatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccountRatesDialogInput,
    private dataChecker: DataCheckerService
  ) {
    super()
    if (data.activeTab) this.tab = data.activeTab
    this.dataChecker.check([RentalRatesGuard])
  }

  ngOnInit() {
    this.store
      .select(selectAllRentals)
      .pipe(
        tap((rentals) => {
          this.rentalsCount = rentals.length
        }),
        untilDestroy(this)
      )
      .subscribe()
    this.actions.pipe(ofType(AddDiscountCodeComplete), untilDestroy(this)).subscribe(() => {
      this.amplitudeService.logEvent('add-discount-code')
    })
    this.actions.pipe(ofType(AddLosDiscountComplete), untilDestroy(this)).subscribe(() => {
      this.amplitudeService.logEvent('add-los-discount')
    })
  }

  ngAfterViewInit() {
    setTimeout(() => this.setupTab())
  }

  close() {
    this.dialogRef.close()
  }

  get showGroupRates() {
    return this.rentalsCount !== null && this.rentalsCount >= 2
  }

  private setupTab() {
    const { focusGroupRate } = this.data

    if (this.tab === AccountRatesDialogTab.GroupRates && this.ratesTable && focusGroupRate) {
      this.ratesTable.searchRate(focusGroupRate)
    }
  }
}
