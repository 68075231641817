import { Pipe, PipeTransform } from '@angular/core'
import { AbstractControl, Validators } from '@angular/forms'

@Pipe({
  name: 'required',
})
export class RequiredPipe implements PipeTransform {
  transform(ctrl: AbstractControl) {
    return ctrl.hasValidator(Validators.required)
  }
}
