import { createFeatureSelector, createSelector } from '@ngrx/store'
import { State } from './state'
import * as reducer from './reducer'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
export const attributeDescriptionState = createFeatureSelector<State>('attr-desc')

export const selectAttributeDescriptionsLoaded = createSelector(
  attributeDescriptionState,
  (state: State) => state.isLoaded
)

export const selectAttributeDescriptionEntities = createSelector(attributeDescriptionState, reducer.selectEntities)

export const selectAllAttributeDescriptions = createSelector(attributeDescriptionState, reducer.selectAll)

export const selectAttributeDescriptionByEntityType = (entityType: DataEntityType) =>
  createSelector(selectAllAttributeDescriptions, (items) => {
    return lodash.head(items.filter((item) => item.entity_type === entityType))
  })
