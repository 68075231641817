import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators'

import { IntegrationService } from './integration.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import {
  ConnectWheelhouse,
  ConnectWheelhouseComplete,
  DisconnectWheelhouse,
  DisconnectWheelhouseComplete,
  LoadAuthorizedIntegration,
  LoadAuthorizedIntegrationComplete,
  LoadAuthorizeIntegrationConfig,
  LoadAuthorizeIntegrationConfigComplete,
  UnauthorizeIntegration,
  UnauthorizeIntegrationComplete,
} from './integration.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ThirdPartyIntegrationIdentifies } from './integration.model'

@Injectable()
export class IntegrationEffects {
  // maybe multiple are required
  @Effect()
  loadAuthData$ = this.actions$.pipe(
    ofType(LoadAuthorizedIntegration),
    concatMap(({ integration }) => {
      if (integration === ThirdPartyIntegrationIdentifies.Wheelhouse) {
        return this.integration.getWheelhouseStatus().pipe(
          filter((status) => status),
          map((connected) => LoadAuthorizedIntegrationComplete({ data: { connected: true }, integration }))
        )
      }
      return this.integration.getOAuth(integration).pipe(
        map((data) => LoadAuthorizedIntegrationComplete({ data, integration })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  getConfig$ = this.actions$.pipe(
    ofType(LoadAuthorizeIntegrationConfig),
    switchMap(({ integration }) =>
      this.integration.getConfig(integration).pipe(
        map((data) => LoadAuthorizeIntegrationConfigComplete({ data, integration })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unauthorize$ = this.actions$.pipe(
    ofType(UnauthorizeIntegration),
    switchMap(({ integration }) =>
      this.integration.unauthorize(integration).pipe(
        map(() => UnauthorizeIntegrationComplete({ integration })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  connectWheelhouse$ = this.actions$.pipe(
    ofType(ConnectWheelhouse),
    switchMap(({ apiKey }) =>
      this.integration.connectWheelhouse(apiKey).pipe(
        tap(() => this.toaster.success('Wheelhouse connected successfully.')),
        map(() => ConnectWheelhouseComplete({ integration: ThirdPartyIntegrationIdentifies.Wheelhouse })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  disconnectWheelhouse$ = this.actions$.pipe(
    ofType(DisconnectWheelhouse),
    switchMap(() =>
      this.integration.disconnectWheelhouse().pipe(
        tap(() => this.toaster.success('Wheelhouse disconnected successfully.')),
        switchMap(() => [
          DisconnectWheelhouseComplete({ integration: ThirdPartyIntegrationIdentifies.Wheelhouse }),
          UnauthorizeIntegrationComplete({ integration: ThirdPartyIntegrationIdentifies.Wheelhouse }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private integration: IntegrationService, private toaster: Toaster) {}
}
