import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DataCheckerService, Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject, Subject } from 'rxjs'
import { SearchService } from '@tv3/store/search/search.service'
import { FormControl } from '@angular/forms'
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'
import { Guest } from '@tv3/store/guest/guest.model'
import { GuestGuard } from '@tv3/guards/guest.guard'
import { FetchGuests } from '@tv3/store/guest/guest.actions'
import { selectAllGuests, selectGuestsById } from '@tv3/store/guest/guest.selectors'

@Component({
  selector: 'app-guest-select',
  templateUrl: './guest-select.component.html',
  styleUrls: ['./guest-select.component.scss'],
})
export class GuestSelectComponent extends Destroyable implements OnInit {
  @Input() ctrl = new FormControl()
  @Input() placeholder = 'Guest'
  @Input() required = false

  @Output() selected = new EventEmitter<Guest>()

  guests$ = new BehaviorSubject<Guest[]>([])
  search$ = new Subject<string>()

  constructor(
    private store: Store<any>,
    private searchService: SearchService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([GuestGuard])
  }

  ngOnInit(): void {
    this.ctrl.valueChanges
      .pipe(
        filter(isSomething),
        tap((id) => this.store.dispatch(FetchGuests({ ids: [id] }))),
        switchMap((id) => this.store.pipe(select(selectGuestsById([id]))))
      )
      .subscribe((items) => {
        this.addItems(items)
      })
    this.store.pipe(select(selectAllGuests), untilDestroy(this)).subscribe((items) => {
      this.addItems(items)
    })

    this.search$
      .pipe(
        distinctUntilChanged(),
        switchMap((term) => this.searchService.searchGuests(term)),
        untilDestroy(this)
      )
      .subscribe((items) => {
        this.addItems(items)
      })
  }

  addItems(items: Guest[]) {
    this.guests$.next(lodash.uniqBy([...items, ...this.guests$.value], 'id'))
  }

  onSearch(term: string) {
    this.search$.next(lodash.trim(term))
  }

  onSelect(items: Guest[]) {
    this.selected.emit(lodash.head(items))
  }
}
