import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Store } from '@ngrx/store'
import { ChannelNameTokens, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import {
  Channel,
  ChannelService,
  ConfirmDialogService,
  ConnectedChannelAccountResponse,
  Toaster,
  isSomething,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { concatMap, filter, map, startWith, switchMap, tap, toArray } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import { combineLatest, of } from 'rxjs'
import {
  ChannelCommissionResponse,
  ChannelCommissionService,
  CtripChannelService,
  TiketChannelService,
} from '@tokeet-frontend/channels'
import { ChannelCommissionDialogService } from '@tv3/containers/channels/channel-detail/api/channel-commission-dialog/channel-commission-dialog.service'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { SelectItemDialogService } from '@tv3/shared/dialogs/select-item-dialog/select-item-dialog.service'
import { TableType } from '@tv3/shared/empty-table/table-type'

@Component({
  selector: 'app-channel-commissions',
  templateUrl: './channel-commissions.component.html',
  styleUrls: ['./channel-commissions.component.scss'],
})
export class ChannelCommissionsComponent extends SelectableRow<ChannelCommissionResponse> implements OnInit {
  @Input() channel: Channel

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = ['select', 'channel_account', 'name', 'amount', 'edit']
  isEmptyTable$ = isEmptyTable(this.dataSource)
  tableType = TableType.Commissions

  selectedAccountIdCtrl = new FormControl('')

  commissions: ChannelCommissionResponse[] = []
  connectedAccounts: (string | number)[] = []

  constructor(
    private store: Store<any>,
    private confirm: ConfirmDialogService,
    private toaster: Toaster,
    protected storage: UserStorage,
    private tiketChannel: TiketChannelService,
    private ctripChannel: CtripChannelService,
    private channelService: ChannelService,
    private selectItemDialog: SelectItemDialogService,
    private channelCommissionService: ChannelCommissionService,
    private channelCommissionDialog: ChannelCommissionDialogService
  ) {
    super(storage)
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    combineLatest([this.getConnectedAccountIds(), this.refresh()]).subscribe(([accounts]) => {
      this.connectedAccounts = accounts
      this.filterData()
    })

    this.selectedAccountIdCtrl.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.filterData()
    })
  }

  getConnectedAccountIds() {
    if (this.channel.name === ChannelNameTokens.Tiket) {
      return this.tiketChannel.getStatus().pipe(map(({ hotelIds }) => hotelIds))
    } else if (this.channel.name === ChannelNameTokens.Trip) {
      return this.ctripChannel.getStatus().pipe(map(({ hotelIds }) => hotelIds))
    } else {
      return this.channelService
        .getConnectedAccounts(this.channel.id)
        .pipe(map((items) => items.map((t) => t.propertyId)))
    }
  }

  refresh() {
    return this.channelCommissionService.all(this.channel.id).pipe(
      tap((items) => {
        this.commissions = items
      })
    )
  }

  filterData() {
    if (!this.selectedAccountIdCtrl.value) {
      this.dataSource.data = this.commissions
    } else {
      this.dataSource.data = lodash.filter(
        this.commissions,
        (t) => t.channel_account == this.selectedAccountIdCtrl.value
      )
    }
  }

  onAdd() {
    this.channelCommissionDialog
      .open(this.channel, this.connectedAccounts)
      .pipe(
        filter(isSomething),
        switchMap(() => this.refresh())
      )
      .subscribe(() => {
        this.filterData()
      })
  }

  onEdit(item: ChannelCommissionResponse) {
    this.channelCommissionDialog
      .open(this.channel, this.connectedAccounts, item)
      .pipe(
        filter(isSomething),
        switchMap(() => this.refresh())
      )
      .subscribe(() => {
        this.filterData()
      })
  }

  onDelete(item: ChannelCommissionResponse) {
    this.confirm
      .confirm()
      .pipe(
        switchMap(() => this.channelCommissionService.delete(item.pkey)),
        switchMap(() => this.refresh())
      )
      .subscribe(
        () => {
          this.toaster.success('Commission deleted successfully')
          this.filterData()
        },
        (error) => {
          this.toaster.error('Unable to delete Commission', null, error)
        }
      )
  }

  onMasterToggle(checked: boolean) {
    if (checked) {
      this.masterToggle(false)
    } else {
      this.unselectAll()
    }
  }

  onCopy() {
    const selected = this.getSelected()
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one item.`)
      return
    }
    const selectedAccountIds = lodash.map(selected, (t) => t.channel_account)
    this.selectItemDialog
      .open({
        title: 'Copy Commissions',
        help: 'Please select one account to copy to.',
        placeholder: 'Account',
        list: lodash.filter(this.connectedAccounts, (t) => !selectedAccountIds.includes(t as any)),
      })
      .pipe(
        filter(isSomething),
        switchMap((accountId) =>
          of(...selected).pipe(
            switchMap((item) =>
              this.channelCommissionService.create({
                ...lodash.pick(item, ['account', 'channel_account', 'channel_id', 'name', 'amount']),
                channel_account: accountId,
              })
            ),
            toArray()
          )
        ),
        switchMap(() => this.refresh())
      )
      .subscribe(
        () => {
          this.toaster.success('Commissions copied successfully')
          this.filterData()
          this.unselectAll()
        },
        (error) => {
          this.toaster.error('Unable to copy Commissions', null, error)
        }
      )
  }

  onDeleteSelected() {
    const selected = this.getSelected().map((t) => t.pkey)
    if (selected.length < 1) {
      this.toaster.warning(`Please select at least one item.`)
      return
    }

    this.confirm
      .confirm()
      .pipe(
        switchMap(() =>
          of(...selected).pipe(
            concatMap((id) => this.channelCommissionService.delete(id)),
            toArray()
          )
        ),
        switchMap(() => this.refresh())
      )
      .subscribe(() => {
        this.filterData()
        this.unselectAll()
      })
  }

  onClearFilters() {
    this.selectedAccountIdCtrl.setValue(undefined)
    this.onSearch('')
  }

  get isFiltering() {
    return this.selectedAccountIdCtrl.value
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }
}
