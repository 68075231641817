import { Component, Inject, OnInit } from '@angular/core'
import * as moment from 'moment'
import * as R from 'ramda'
import { range } from 'lodash'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { epochToViewUTC, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { CurrencyPipe } from '@angular/common'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { epochToView } from '@tokeet-frontend/tv3-platform'
import { observeOn } from 'rxjs/operators'
import { asyncScheduler } from 'rxjs'
import { downloadCSV } from '@tv3/utils/functions/download'
import { BookingCostResolver } from '@tokeet/cost-resolver'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'

declare const require: any
declare const window: any

const Papa = require('papaparse')

@Component({
  selector: 'app-export-inquiries-report-dialog',
  templateUrl: './export-inquiries-report-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./export-inquiries-report-dialog.component.scss'],
})
export class ExportInquiriesReportDialogComponent implements OnInit {
  isExporting = false

  years: string[]
  months = moment.months()

  form = this.fb.group({
    year: ['', [Validators.required]],
    month: ['', [Validators.required]],
    rentals: ['', [Validators.required]],
    onlyBooked: [false],
  })

  exportableData: any[]

  columns = [
    'Villa Name',
    'Guest Name',
    'Arrive',
    'Depart',
    'Guests',
    'Nights',
    'Initial Price',
    'Taxes',
    'Discounts & Fee',
    'Final Price',
    'Formula Cost',
    'Booking Source',
    'Booking Date',
  ]

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  constructor(
    private store: Store<fromRoot.State>,
    public dialogRef: MatDialogRef<ExportInquiriesReportDialogComponent>,
    public fb: FormBuilder,
    private inquiryService: InquiryService,
    @Inject(MAT_DIALOG_DATA) public data: { inquiries: Inquiry[] }
  ) {}

  ngOnInit() {
    this.years = R.map((i) => moment().subtract(i, 'year').format('YYYY'), range(3, -3, -1))
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  async download(form: FormGroup) {
    this.isExporting = true
    await this.doExport()
    this.isExporting = false
    this.dialogRef.close()
  }

  private async doExport() {
    const filteredData = R.pipe(
      R.filter((i: Inquiry) => {
        const rentalIds = this.form.get('rentals').value
        if (isSomething(rentalIds)) {
          return R.contains(i.rentalId, rentalIds)
        }
        return true
      }),
      R.filter((i: Inquiry) => {
        const filterYear = this.form.get('year').value
        if (filterYear) {
          const inquiryYear = moment.utc(i.guestArrive * 1000).format('YYYY')
          return inquiryYear === filterYear
        }
        return true
      }),
      R.filter((i: Inquiry) => {
        const filterMonth = this.form.get('month').value
        if (filterMonth) {
          const inquiryMonth = moment.utc(i.guestArrive * 1000).format('MMMM')
          return inquiryMonth === filterMonth
        }
        return true
      }),
      R.filter((i: Inquiry) => {
        const onlyBooked = this.form.get('onlyBooked').value
        if (onlyBooked) {
          return !!(i.booked || i.bookingId)
        }
        return true
      })
    )(<any>this.data.inquiries)

    const ids = R.map((t) => t.id, filteredData).filter((t) => !!t)

    const formulaCostsByIds = await this.inquiryService.getCostAfterFormulaBatch(ids).toPromise()

    this.exportableData = R.map((e: Inquiry) => {
      const charges = BookingCostResolver.parseBookingCharges(e as any)

      const currencyCode = R.pathOr('USD', ['rental', 'currency', 'code'], e)
      const charge = R.pathOr(0, ['charge'], charges)
      const taxFee = R.pathOr(0, ['taxFee'], charges)
      const feeSum = R.pathOr(0, ['feeSum'], charges)
      const discountSum = R.pathOr(0, ['discountSum'], charges)
      const sum = R.pathOr(0, ['sum'], charges)
      const formulaCost = formulaCostsByIds[e.id]

      return {
        'Villa Name': R.pathOr('', ['rental', 'name'], e),
        'Guest Name': e.guestNameView,
        Arrive: epochToViewUTC(e.guestArrive, 'MM/DD/YYYY'),
        Depart: epochToViewUTC(e.guestDepart, 'MM/DD/YYYY'),
        Guests: e.guestsView,
        Nights: e.nightsView,
        'Initial Price': new CurrencyPipe('en-US').transform(charge, currencyCode),
        Taxes: new CurrencyPipe('en-US').transform(taxFee, currencyCode),
        'Discounts & Fee': new CurrencyPipe('en-US').transform(feeSum + discountSum, currencyCode),
        'Final Price': new CurrencyPipe('en-US').transform(sum, currencyCode),
        'Formula Cost': formulaCost >= 0 ? new CurrencyPipe('en-US').transform(formulaCost, currencyCode) : '',
        'Booking Source': R.pathOr('', ['inquirySource'], e),
        'Booking Date': epochToView(e.booked, 'MM/DD/YYYY'),
      }
    }, filteredData)

    const csvString = Papa.unparse({
      fields: this.columns,
      data: JSON.stringify(this.exportableData),
    })
    const fileName = `inquiries_report_${this.form.get('year').value}_${this.form.get('month').value}_${
      this.form.get('onlyBooked').value ? 'booked' : ''
    }.csv`
    downloadCSV(csvString, fileName)
  }
}
