import { Observable, of } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import { LoadInvoiceTemplates } from './template.actions'
import { selectInvoiceTemplatesLoaded } from './template.selectors'
import { IsDataLoaded } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class InvoiceTemplateGuard implements CanActivate, CanActivateChild, CanLoad, IsDataLoaded {
  constructor(private store: Store<any>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return this.store.pipe(
      select(selectInvoiceTemplatesLoaded),
      tap((isLoaded) => {
        if (!isLoaded) {
          const filters = {} as {
            limit?: number
            skip?: number
          }
          this.store.dispatch(LoadInvoiceTemplates({ filters }))
        }
      }),
      map(() => true),
      take(1)
    )
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate()
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
