import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  setAccountTaxes,
  Currency,
  Rental,
  selectAccountTaxes,
  TaxV3,
  TokeetCurrencyPipe,
} from '@tokeet-frontend/tv3-platform'
import { TaxTypes } from '@tokeet/cost-resolver'
import * as R from 'ramda'
import { CreateTaxDialogService } from '@tv3/shared/dialogs/create-tax-dialog/create-tax-dialog.service'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { AuthService } from '@tv3/services/auth.service'
import { map, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { compact } from 'lodash'
import { validateMinNumber } from '@tokeet-frontend/tv3-platform'
import {
  InvoiceItem,
  InvoiceLineItemTitles,
  invoiceLineItemTitlesByType,
  InvoiceTypes,
  setInvoiceItemTaxV1,
} from '@tokeet-frontend/invoices'

@Component({
  selector: 'app-invoice-item',
  templateUrl: './invoice-item.component.html',
  styleUrls: ['./invoice-item.component.scss'],
})
export class InvoiceItemComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input() editable = true
  @Input() removable = false
  @Input() invoiceType: InvoiceTypes
  @Input() item: InvoiceItem
  @Input() guests = 1
  @Input() rental: Rental
  @Input() currency: Currency
  @Output() itemChange = new EventEmitter<InvoiceItem>()
  @Output() remove = new EventEmitter<InvoiceItem>()

  titles: InvoiceLineItemTitles

  partialPaymentOpts = [0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.75, 0.8, 0.9, 1.0]

  @Input() rootForm: FormGroup
  itemFormCtrlName = `item-${Date.now()}`
  form = this.fb.group({
    id: [],
    item: [undefined, [Validators.required]],
    description: [undefined],
    discount: [undefined],
    qty: [undefined, [Validators.required, validateMinNumber(1)]],
    unitCost: [undefined, [Validators.required]],
    percent: [1.0],
    taxes: this.fb.array([]),
  })

  taxes: { name: string; formName: string; amount: number; type: string }[] = []
  searchTaxTerm = ''

  get taxesForm() {
    return this.form.get('taxes') as FormArray
  }

  destroy$ = new Subject<boolean>()

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private store: Store<fromRoot.State>,
    private tokeetCurrencyPipe: TokeetCurrencyPipe,
    private createTaxDialogService: CreateTaxDialogService
  ) {}

  ngOnInit() {
    this.buildTaxesFormGroup(this.item.taxes || [])
    this.store
      .pipe(
        select(selectAccountTaxes),
        takeUntil(this.destroy$),
        map((taxes) => ({ accountTaxes: taxes, rentalTaxes: R.pathOr([], ['taxes'], this.rental) as TaxV3[] })),
        map(({ accountTaxes, rentalTaxes }) => R.uniq(<any>R.concat(accountTaxes, rentalTaxes))),
        map((taxes) => R.map((tax: TaxV3) => ({ ...tax, formName: this.getTaxFormName(tax) }), taxes))
      )
      .subscribe((taxes) => {
        this.taxes = taxes
        // in order to show taxes for version 2, we need to add invoice taxes of v2 to the list
        const itemTaxes: TaxV3[] = this.item.taxes || []
        R.forEach((tax) => {
          const taxFormName = this.getTaxFormName(tax)
          if (!R.find((t) => t.formName === taxFormName, this.taxes)) {
            this.taxes.push({ ...tax, formName: taxFormName })
          }
        }, itemTaxes)
      })

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const item = InvoiceItem.deserialize({ ...this.form.getRawValue(), taxes: this.getTaxesValues(this.taxesForm) })
      if (item.taxes && item.taxes.length) {
        // back compliance
        setInvoiceItemTaxV1(item)
        item.taxEx = undefined // remove tax v2
      }
      this.itemChange.emit(item)
    })

    this.rootForm.addControl(this.itemFormCtrlName, this.form)

    this.titles = invoiceLineItemTitlesByType[this.removable ? InvoiceTypes.GeneralInvoice : this.invoiceType]
  }

  ngOnDestroy(): void {
    this.rootForm.removeControl(this.itemFormCtrlName)
  }

  private getTaxFormName(tax: TaxV3) {
    if (tax.type === TaxTypes.Percent) {
      return `${tax.amount}%`
    } else {
      return `${this.tokeetCurrencyPipe.transform(tax.amount, this.currency.symbol, '1.0-2')}`
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const editableChange = changes['editable']
    if (editableChange) {
      editableChange.currentValue ? this.form.enable() : this.form.disable()
    }
  }

  ngAfterContentInit(): void {
    this.form.patchValue(
      {
        id: this.item.id,
        item: this.item.item,
        description: this.item.description,
        discount: this.item.discount,
        qty: this.item.qty,
        unitCost: this.item.unitCost,
        percent: this.item.percent,
      },
      { emitEvent: false }
    )
  }

  private buildTaxesFormGroup(taxes: TaxV3[] = []) {
    taxes = compact(taxes)
    R.forEach((tax: TaxV3) => {
      this.taxesForm.push(this.createTaxField(this.getTaxFormName(tax)))
    }, taxes)
  }

  private getTaxesValues(taxesForm: FormArray) {
    const value = taxesForm.getRawValue()
    return R.filter(
      (i) => !!i,
      R.map(({ taxFormName }) => {
        const tax = R.find(R.pathEq(['formName'], taxFormName), this.taxes || [])
        if (!tax) {
          return null
        }
        const t3 = new TaxV3()
        t3.amount = tax.amount
        t3.type = tax.type
        t3.name = tax.name
        return t3
      }, value)
    )
  }

  onNewTax() {
    const tax = new TaxV3()
    if (this.searchTaxTerm) {
      tax.name = this.searchTaxTerm
    }
    this.createTaxDialogService.open(this.rental, tax)
  }

  createTaxField(taxFormName?): FormGroup {
    return this.fb.group({
      taxFormName: [taxFormName],
    })
  }

  onAddTax() {
    this.taxesForm.push(this.createTaxField())
  }

  onDeleteTax(tax: TaxV3) {
    this.taxes = R.reject(
      (t: TaxV3) => t.amount === tax.amount && t.name === tax.name && t.type === tax.type,
      this.taxes
    ) as any[]
    this.store.dispatch(setAccountTaxes({ taxes: this.taxes }))
  }

  onRemoveTax(index: number) {
    this.taxesForm.removeAt(index)
  }

  onRemoveItem() {
    this.remove.emit(this.item)
  }
}
