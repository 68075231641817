import { Component, Inject, Input, OnInit } from '@angular/core'
import { Comment, CommentsResponse } from '../../store/model'
import { CommentService } from '../../store/service'
import {
  ActionFailed,
  CURRENT_USER,
  Destroyable,
  SaveForm,
  Toaster,
  User,
  DataEntityType,
} from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { finalize } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
})
export class CommentsListComponent extends Destroyable implements OnInit {
  @Input() entityType: DataEntityType
  @Input() entityId: string

  isLoading = false
  isSending = false
  comments: CommentsResponse | undefined

  form = this.fb.group({
    comment: ['', [Validators.required]],
  })

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    private store: Store<any>,
    private commentService: CommentService,
    @Inject(CURRENT_USER) private user$: BehaviorSubject<User>
  ) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.commentService
      .getComments(this.entityType, this.entityId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (items) => {
          this.comments = items
        },
        (error) => {
          if (error?.status === 404) {
            return
          }
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  @SaveForm()
  onAddComment(form: FormGroup) {
    const { comment } = form.getRawValue()
    this.isSending = true
    this.commentService
      .addComment(this.entityType, this.entityId, { comment, creator_id: this.user$.value.id })
      .pipe(finalize(() => (this.isSending = false)))
      .subscribe(
        (items) => {
          this.comments = items
          this.toaster.success('Comment added successfully')
          this.form.reset()
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onRemove(item: Comment) {
    this.commentService.removeComment(this.entityType, this.entityId, item.key).subscribe(
      (items) => {
        this.comments = items
        this.toaster.success('Comment removed successfully')
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }
}
