<div class="title">
  {{ date | epochUTC : 'MMM DD, YYYY' }}
</div>

<div class="rate-details">
  <table class="table table-sm">
    <tr>
      <th scope="col">Channel</th>
      <th scope="col">Price</th>
      <th scope="col">Min Stay</th>
      <th scope="col">Max Stay</th>
    </tr>
    <tr *ngFor="let item of getChannelRates | call : ratesByCategory : channelsById">
      <th scope="row">{{ item.name }}</th>
      <td>{{ item.price | rate : item.currency }}</td>
      <td>{{ item.minimumstay || 'N/A' }}</td>
      <td>{{ item.maximumstay || 'N/A' }}</td>
    </tr>
  </table>
</div>
