import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { select, Store } from '@ngrx/store'
import { getChannelConfig, HomeAwayChannelConfig } from '@tokeet-frontend/channels'
import { PaymentGatewayServiceIdentities, selectPaymentGatewayByService } from '@tokeet-frontend/gateways'
import { Channel, ChannelNameTokens, Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { combineLatest } from 'rxjs'
import { delay, filter, map, switchMap, take } from 'rxjs/operators'

@Component({
  selector: 'app-connect-vrbo-wizard',
  templateUrl: './connect-vrbo-wizard.component.html',
  styleUrls: ['./connect-vrbo-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class ConnectVrboWizardComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild(MatStepper) stepper: MatStepper

  get channel() {
    return this.data.channel
  }

  isStripeConnected$ = this.store.pipe(
    select(selectPaymentGatewayByService(PaymentGatewayServiceIdentities.Stripe)),
    map((t) => !!t?.length)
  )
  config$ = this.store.pipe(getChannelConfig(ChannelNameTokens.Homeaway))

  constructor(
    public dialogRef: MatDialogRef<ConnectVrboWizardComponent>,
    private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {
    super()
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.stepper.animationDone
      .pipe(
        take(1),
        delay(500),
        switchMap(() => combineLatest([this.config$, this.isStripeConnected$])),
        filter(([config, isStripeConnected]) => isSomething(config) && isStripeConnected),
        take(1),
        untilDestroy(this)
      )
      .subscribe(([config, isStripeConnected]: [HomeAwayChannelConfig, boolean]) => {
        const terms = Object.values(config.payment_terms || {}).filter((v) => !!v)
        if (!config.status || !terms.length) {
          this.stepper.selectedIndex = 1
        } else {
          this.stepper.selectedIndex = 2
        }
      })
  }

  close() {
    this.dialogRef.close()
  }
}
