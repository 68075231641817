import { SafeHtml } from '@angular/platform-browser'
import { Type, deserialize, Expose, Serializable, plainToClass } from '@tokeet-frontend/tv3-platform'
import { Attachment } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export enum MessageType {
  Draft = -1,
  Inbound = 1,
  Outbound = 2,
  System = 3,
  Guest = 4,
  Owner = 5,
}

export class Message extends Serializable<Message> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'reference_id' })
  referenceId: number

  @Expose({ name: 'guest_name' })
  guestName: string

  @Expose({ name: 'message_id' })
  messageId: string

  @Expose({ name: 'message_html' })
  messageHtml: string

  @Expose({ name: 'processed_date' })
  processedDate: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'rental_name' })
  rentalName: string

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'message_txt' })
  messageTxt: string

  @Expose({ name: 'convo_id' })
  convoId: string

  @Expose({ name: 'received_on' })
  receivedOn: number

  @Expose({ name: 'guest_email' })
  guestEmail: string

  @Expose({ name: 'inquiry_id' })
  inquiryId: string

  @Expose({ name: 'replyto' })
  replyTo: string

  @Expose({ name: 'sentto' })
  sentTo: string

  @Expose({ name: 'sent_with' })
  sentWith: 'automata' | 'tokeet'
  written_by: string

  cc: string[]
  bcc: string[]

  name: string
  type: MessageType
  archived: number
  from: string
  account: number
  read: number
  created: number

  @Type(() => Attachment)
  attachments: Attachment[]

  innerAttachments: boolean

  touch: number
  subject: string
  template: string

  @Expose({ name: 'tax_fee' })
  taxFee: number

  @Expose({ name: 'guest_arrive' })
  guestArrive: string

  @Expose({ name: 'guest_depart' })
  guestDepart: string

  discounts: number
  currency: string
  sum: number
  charge: number

  sanitizedMsgHtml: SafeHtml

  isProcessed = false

  static deserialize(data: any): Message {
    const message: Message = plainToClass<Message, Message>(Message, data)
    // @ts-ignore
    message.type = message.type * 1 // convert to number
    message.messageTxt = lodash.toString(message.messageTxt || '')
    message.messageHtml = message.messageHtml || message.messageTxt.replace(/\n/g, '<br>')
    message.receivedOn = parseInt((<any>message).receivedOn, 10)

    return message
  }
}

export function isBotMessage(msg: Message) {
  const bot = /aiResponder|TokeetAI/i
  return bot.test(msg?.sentWith) || bot.test(msg?.written_by)
}

export function plainTextMessage(html: string) {
  return lodash
    .toString(html)
    .replace(/<br\s*[\/]?>/gi, '\r\n')
    .replace(/<br[^>]*>/gi, '\r\n')
    .replace(/<\/p><p[^>]*>/gi, '\r\n')
    .replace(/<[^>]+>/gm, '')
    .replace(/\&nbsp\;/gm, ' ')
}
