<div class="charge-items" [ngClass]="direction">
  <ng-container *ngIf="isExternalBooking || !inquiry?.bookingEngine">
    <div class="charge-item">
      <div class="label">Base Charge</div>
      <div class="value">
        <span [innerHtml]="charges?.charge | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Additional Guests Fee</div>
      <div class="value">
        <span [innerHtml]="charges?.extraGuests | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Discounts & Fees</div>
      <div class="value">
        <span
          [innerHtml]="(charges?.discountSum || 0) + (charges?.feeSum || 0) | tokeetCurrency : currencySymbol"
        ></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Taxes</div>
      <div class="value">
        <span [innerHtml]="charges?.taxFee || 0 | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div *ngIf="!!formulaAdjustedCost" class="charge-item">
      <div class="label">
        <span
          [matTooltip]="
            'Booking costs were adjusted via the booking formula set on the \'' +
            inquiry?.inquirySource +
            '\' Channel Details settings'
          "
          matTooltipPosition="above"
        >
          Booking Formula
        </span>
      </div>
      <div class="value">
        <span class="formula-cost" [innerHtml]="formulaAdjustedCost | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div *ngIf="status" class="charge-item">
      <div class="label">
        <span
          [matTooltip]="status.remaining ? 'Remaining: ' + (status.remaining | tokeetCurrency : currencySymbol) : ''"
          matTooltipPosition="above"
          >Total (<span>{{ status.status }} <i class="fal fa-money-bill-alt"></i></span>)</span
        >
      </div>
      <div class="value">
        <span
          class="total-price"
          [matTooltip]="currencyCode"
          matTooltipPosition="right"
          [ngClass]="{ 'text-success': status.status === 'Paid', 'text-danger': status.status !== 'Paid' }"
          [innerHtml]="charges?.sum | tokeetCurrency : currencySymbol"
        ></span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isExternalBooking && !!inquiry?.bookingEngine">
    <div class="charge-item">
      <div class="label">Base Charge</div>
      <div class="value">
        <span [innerHtml]="inquiry?.bookingEngine?.extraBedroomsBase | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Additional Guests Fee</div>
      <div class="value">
        <span [innerHtml]="inquiry?.bookingEngine?.extraGuests | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item" *ngIf="!!inquiry?.bookingEngine?.extraBedrooms">
      <div class="label">Additional Bedrooms Fee</div>
      <div class="value">
        <span [innerHtml]="inquiry?.bookingEngine?.extraBedrooms | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Discounts & Fees</div>
      <div class="value">
        <span [innerHtml]="inquiry?.bookingEngine?.discountTotal | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div *ngFor="let fee of inquiry?.bookingEngine?.bookingFees" class="charge-item">
      <div class="label">
        {{ fee.name }}
      </div>
      <div class="value">
        <span [innerHtml]="fee.amount | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div class="charge-item">
      <div class="label">Taxes</div>
      <div class="value">
        <span [innerHtml]="inquiry?.bookingEngine?.taxTotal | tokeetCurrency : currencySymbol"></span>
      </div>
    </div>
    <div *ngIf="status" class="charge-item total">
      <div class="label">
        <span
          [matTooltip]="status.remaining ? 'Remaining: ' + (status.remaining | tokeetCurrency : currencySymbol) : ''"
          matTooltipPosition="above"
          >Total (<span>{{ status.status }} <i class="fal fa-money-bill-alt"></i></span>)</span
        >
      </div>
      <div class="value">
        <span
          class="total-price"
          [matTooltip]="currencyCode"
          matTooltipPosition="right"
          [ngClass]="{ 'text-success': status.status === 'Paid', 'text-danger': status.status !== 'Paid' }"
          [innerHtml]="inquiry?.bookingEngine?.total | tokeetCurrency : currencySymbol"
        ></span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!(1 | isReadonlyRole)" class="d-flex flex-column gap-3">
    <!-- Edit -->
    <ng-container *ngIf="!readonly">
      <a
        matTooltip="Edit booking charges"
        matTooltipPosition="above"
        (click)="onUpdateQuote()"
        class="btn btn-secondary-light"
      >
        <i class="cs-icon cs-icon-pencil"></i>
      </a>
    </ng-container>
    <!-- ABB Breakdown -->
    <a
      *ngIf="inquiry.abbPrice && inquiry.abbPrice.base"
      matTooltip="View Airbnb charge breakdown"
      matTooltipPosition="above"
      (click)="abbBreakdown.emit()"
      class="btn btn-secondary-light btn-charges-breakdown"
    >
      <i class="fal fa-server"></i>
    </a>
  </div>
</div>
<ng-container *ngIf="applicableFees && applicableFees$ | async as fees">
  <div class="w-100 text-light mt-3" *ngIf="fees.length" [class.text-right]="direction === 'h'">
    These booking fees may apply:
  </div>
  <div class="charge-items" *ngIf="fees.length" [ngClass]="direction">
    <div class="charge-item" *ngFor="let fee of fees">
      <div class="label">{{ fee.name }}</div>
      <div class="value">
        <span
          [innerHtml]="
            fee.type === feeTypes.Percent
              ? fee.amount + '%'
              : (fee.amount | tokeetCurrency : currencySymbol) + ' / ' + feeModalityLabels[fee.modality]
          "
        ></span>
      </div>
    </div>
  </div>
</ng-container>
