<ng-container *ngIf="isLoading; else list">
  <app-loading-section></app-loading-section>
</ng-container>

<ng-template #list>
  <ng-content *ngIf="!comments?.comments?.length"></ng-content>

  <app-history-list
    [list]="comments?.comments || []"
    contentKey="comment"
    userId="creator_id"
    timeKey="created"
    timeFormat="DD - MMM - YYYY hh:mm A"
    (remove)="onRemove($event)"
  ></app-history-list>

  <form [formGroup]="form" class="mt-3">
    <mat-form-field2 [showLabel]="false">
      <textarea
        matInput
        formControlName="comment"
        placeholder="Write your comment here..."
        cdkTextareaAutosize
      ></textarea>
      <button
        matSuffix
        type="button"
        class="btn btn-light-info"
        [disabled]="form.invalid"
        (click)="onAddComment(form)"
        style="margin-right: -0.75rem"
      >
        <i class="cs-icon cs-icon-send"></i>
      </button>
    </mat-form-field2>
  </form>
</ng-template>
