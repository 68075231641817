import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ChannelCommissionResponse, ChannelCommissionService } from '@tokeet-frontend/channels'
import { Channel, ConnectedChannelAccountResponse, Destroyable, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import * as lodash from 'lodash'
import { combineLatest } from 'rxjs'

@Component({
  selector: 'app-channel-commission-dialog',
  templateUrl: './channel-commission-dialog.component.html',
  styleUrls: ['./channel-commission-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ChannelCommissionDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    accounts: [[]],
    name: [undefined, [Validators.required]],
    amount: [undefined, [Validators.required, Validators.min(0), Validators.max(100)]],
  })
  isEdit = false
  isLoading = false
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    public dialogRef: MatDialogRef<ChannelCommissionDialogComponent>,
    private toaster: Toaster,
    private commissionService: ChannelCommissionService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      commission?: ChannelCommissionResponse
      accounts: (string | number)[]
      channel: Channel
    }
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.data.commission) {
      this.isEdit = true
      this.form.patchValue(this.data.commission)
    } else {
      this.form.get('accounts').setValidators([Validators.required])
    }
  }

  close() {
    this.dialogRef.close()
  }

  create() {
    const { accounts, ...others } = this.form.getRawValue()
    const items = lodash.map(accounts, (channel_account) => ({
      channel_account,
      channel_id: this.data.channel.id,
      ...others,
      account: this.auth.user.account,
    }))
    combineLatest(lodash.map(items, (data) => this.commissionService.create(data))).subscribe(
      (res) => {
        this.toaster.success('Commission(s) saved successfully')
        this.dialogRef.close(res)
      },
      (error) => {
        this.toaster.error('Unable to save Commission(s)', '', error)
      }
    )
  }

  update() {
    const { accounts, ...others } = this.form.getRawValue()
    this.commissionService.update(this.data.commission.pkey, others).subscribe(
      (res) => {
        this.toaster.success('Commission saved successfully')
        this.dialogRef.close(res)
      },
      (error) => {
        this.toaster.error('Unable to save Commission', '', error)
      }
    )
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (this.isEdit) {
      this.update()
    } else {
      this.create()
    }
  }
}
