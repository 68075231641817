import { createAction, props } from '@ngrx/store'
import { PaymentRule } from '@tv3/store/payment-rule/payment-rule.model'
import { CreatePaymentRuleRequest } from '@tv3/interfaces/requests/create-payment-rule.request'

export const LoadPaymentRules = createAction('[Payment Rule] Load')
export const LoadPaymentRulesComplete = createAction('[Payment Rule] Load Complete', props<{ rules: PaymentRule[] }>())

export const CreatePaymentRule = createAction('[Payment Rule] Create', props<{ request: CreatePaymentRuleRequest }>())
export const CreatePaymentRuleComplete = createAction(
  '[Payment Rule] Create Complete',
  props<{ rules: PaymentRule[] }>()
)

export const TogglePaymentRuleStatus = createAction(
  '[Payment Rule] Toggle Status',
  props<{ id: string; status: boolean }>()
)
export const TogglePaymentRuleStatusComplete = createAction(
  '[Payment Rule] Toggle Status Complete',
  props<{ id: string; status: boolean }>()
)

export const RemovePaymentRule = createAction('[Payment Rule] Remove', props<{ id: string }>())
export const RemovePaymentRuleComplete = createAction('[Payment Rule] Remove Complete', props<{ id: string }>())

export const PausePaymentRules = createAction('[Payment Rule] Pause Rules', props<{ paymentGatewayId: string }>())
export const PausePaymentRulesComplete = createAction('[Payment Rule] Pause Rules Complete', props<{ ids: string[] }>())
