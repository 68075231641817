<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-edit"></i> Edit Invalid Rental Fields</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <input type="text" matInput formControlName="display_name" placeholder="Display Name" required />
      <mat-hint align="start">Must be at least 20 and at most 80 characters</mat-hint>
      <mat-error *ngIf="form.get('display_name').touched && form.get('display_name').hasError('required')">
        Display name is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('display_name').touched && form.get('display_name').hasError('minlength')">
        Display name must be at least <strong>20 characters long</strong>
      </mat-error>
      <mat-error *ngIf="form.get('display_name').touched && form.get('display_name').hasError('maxlength')">
        Display name must be at most <strong>80 characters long</strong>
      </mat-error>
      <mat-hint align="end">{{ form.get('display_name').value?.length || 0 }}/80</mat-hint>
    </mat-form-field2>
    <mat-form-field2 class="mat-block">
      <textarea
        matInput
        maxlength="10000"
        rows="4"
        formControlName="description"
        placeholder="Description"
        required
        cdkTextareaAutosize
      ></textarea>
      <mat-hint align="start">Must be at least 400 and at most 10,000 characters</mat-hint>
      <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('required')">
        Description is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('minlength')">
        Description must be at least <strong>400 characters long</strong>
      </mat-error>
      <mat-error *ngIf="form.get('description').touched && form.get('description').hasError('maxlength')">
        Description must be at most <strong>10,000 characters long</strong>
      </mat-error>
      <mat-hint align="end">{{ form.get('description').value?.length || 0 }}/10,000</mat-hint>
    </mat-form-field2>

    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="bedrooms" placeholder="Bedrooms">
            <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('bedrooms').touched && form.get('bedrooms').hasError('required')">
            Bedrooms is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="bathrooms" placeholder="Bathrooms">
            <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('bathrooms').touched && form.get('bathrooms').hasError('required')">
            Bathrooms is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="far fa-plus"></i> create
  </button>
</div>
