<div class="d-flex align-items-center gap-3">
  <h6 class="mb-0"></h6>
  <div class="flex-fill"></div>
  <app-cancel-filters (clear)="onResetFilters()"></app-cancel-filters>
  <app-select-search
    [items]="rentals$ | async"
    bindLabel="name"
    bindValue="id"
    [multiple]="true"
    placeholder="Rentals"
    [ctrl]="filters.get('rentals')"
    matTooltip="Only show expenses that specify the selected rentals."
    style="width: 12rem"
  >
  </app-select-search>
  <app-select-search
    [items]="months"
    bindLabel="name"
    bindValue="id"
    [multiple]="false"
    [ctrl]="filters.get('month')"
    matTooltip="Only show expenses that specify within the selected month."
  >
  </app-select-search>
  <app-select-search
    [items]="years"
    bindLabel="name"
    bindValue="id"
    [multiple]="false"
    [ctrl]="filters.get('year')"
    matTooltip="Only show expenses that specify within the selected year."
  >
  </app-select-search>
</div>
