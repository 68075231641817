import { Component, OnDestroy, OnInit } from '@angular/core'
import * as R from 'ramda'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import {
  AddExpenses,
  asUTCEpoch,
  categories,
  CreateExpensePayload,
  methods,
  selectAllRentals,
  statuses,
} from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import {
  generateDateParser,
  generateMappingParser,
  generateRentalParser,
  parseNumber,
  parseString,
} from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-import-expense-dialog',
  templateUrl: './import-expense-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-expense-dialog.component.scss'],
})
export class ImportExpenseDialogComponent extends Destroyable implements OnInit {
  files: ParsedUploadFile<any>[] = []
  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Expense Date', field: 'expenseDate', required: true, parse: generateDateParser('YYYY-MM-DD') },
    { name: 'Due Date', field: 'dueDate', parse: generateDateParser('YYYY-MM-DD') },
    {
      name: 'Category',
      field: 'category',
      required: true,
      default: 'Other',
      parse: generateMappingParser(
        R.reduce(
          (acc, s) => {
            acc[R.toLower(s.name)] = s.value
            return acc
          },
          {},
          categories
        ),
        'other'
      ),
    },
    { name: 'Amount', field: 'amount', required: true, parse: parseNumber },
    {
      name: 'Method',
      field: 'method',
      default: 'other',
      parse: generateMappingParser(
        R.reduce(
          (acc, s) => {
            acc[R.toLower(s.name)] = s.value
            return acc
          },
          {},
          methods
        ),
        'other'
      ),
    },
    {
      name: 'Status',
      field: 'status',
      default: 1,
      parse: generateMappingParser(
        R.reduce(
          (acc, s) => {
            acc[R.toLower(s.name)] = s.value
            return acc
          },
          {},
          statuses
        )
      ),
    },
    {
      name: 'Rental Name',
      field: 'rentalId',
      required: false,
      parse: (str, colDef) => generateRentalParser(this.rentals)(str, colDef),
    },
    { name: 'Description', field: 'description', parse: parseString },
  ]

  rentals: Rental[]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: [
      'Expense Date, Category and Amount are required.',
      'Dates format "YYYY-MM-DD", such as "2016-02-14".',
      'Unknown category will be set with "Other".',
      'Unknown method will be set with "Other".',
      'Default expense status will be Unpaid.',
    ],
    sample: [
      ['Expense Date', 'Due Date', 'Category', 'Amount', 'Method', 'Status', 'Rental Name', 'Description'],
      ['2016-02-14', '2016-02-14', 'Gardening', 500, 'Check', 'Paid', 'Sadewa Villa', ''],
      ['2016-02-14', '', 'Housekeeping', 200, 'Other', 'Paid', 'Apartment 53', ''],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportExpenseDialogComponent>,
    private store: Store<fromRoot.State>,
    protected toast: Toaster
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  close() {
    this.dialogRef.close()
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
    this.validate()
  }

  create() {
    if (R.any((file) => file.isError, this.files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    //  get parsed csv data
    const items: any = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )

    // round amount
    R.forEach((item: any) => {
      item.amount = item.amount.toFixed(2) * 1
    }, items)

    this.store.dispatch(AddExpenses({ items: R.map(this.toPayload, items) }))

    this.close()
  }

  toPayload(item): CreateExpensePayload {
    return {
      rental_id: item.rentalId,
      date: asUTCEpoch(item.expenseDate),
      category: item.category,
      amount: item.amount,
      status: item.status,
      method: item.method,
      description: item.description,
      due: asUTCEpoch(item.dueDate),
    }
  }

  validate() {
    this.files.forEach((file) => {
      file.items.forEach((item) => {
        const { dueDate, expenseDate } = item.item
        if (dueDate && expenseDate && expenseDate > dueDate) {
          item.errors.push('Expense Date can not be greater then Due Date')
          file.isError = true
          item.isError = true
        }
      })
    })
  }
}
