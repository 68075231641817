import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { switchMap, tap } from 'rxjs/operators'
import { LazyOverlayService } from '@tv3/shared/lazy-overlay/lazy-overlay.service'
import {
  OpenGuestOverlay,
  OpenInquiryOverlay,
  OpenInventoryOverlay,
  OpenInvoiceOverlay,
  OpenRentalOverlay,
} from '@tv3/store/overlay/overlay.actions'
import { GuestService } from '@tv3/store/guest/guest.service'
import { GetGuestComplete } from '@tv3/store/guest/guest.actions'
import { Guest } from '@tv3/store/guest/guest.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Rental, selectRental } from '@tokeet-frontend/tv3-platform'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { Invoice } from '@tokeet-frontend/invoices'

@Injectable()
export class OverlayEffects {
  @Effect({ dispatch: false })
  openInvoiceOverlay$ = this.actions$.pipe(
    ofType(OpenInvoiceOverlay),
    tap(({ invoiceId, invoices }) => this.openInvoice(invoiceId, invoices))
  )

  @Effect({ dispatch: false })
  openInquiryOverlay$ = this.actions$.pipe(
    ofType(OpenInquiryOverlay),
    tap(({ inquiryId }) => this.openInquiry(inquiryId))
  )

  @Effect({ dispatch: false })
  openGuestOverlay$ = this.actions$.pipe(
    ofType(OpenGuestOverlay),
    tap(({ guestId }) => this.openGuest(guestId))
  )

  @Effect({ dispatch: false })
  openRentalOverlay$ = this.actions$.pipe(
    ofType(OpenRentalOverlay),
    tap(({ rental, rentalId, activeTab, highlightedImages }) =>
      this.openRental(rentalId || rental.id, activeTab, highlightedImages)
    )
  )

  @Effect({ dispatch: false })
  openInventoryOverlay$ = this.actions$.pipe(
    ofType(OpenInventoryOverlay),
    tap(() => this.openInventory())
  )

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private guestService: GuestService,
    private lazyOverlayService: LazyOverlayService
  ) {}

  private openInvoice<T>(invoiceId: string, invoices: Invoice[]) {
    this.lazyOverlayService
      .openLazyOverlay(
        this.loadInvoiceOverlay(),
        { invoiceId, invoices },
        {
          panelClass: ['drawer-full-90'],
        }
      )
      .pipe(switchMap((dialogRef) => dialogRef.afterClosed()))
      .subscribe()
  }

  private async loadInvoiceOverlay() {
    const { InvoiceOverlayModule } = await import(
      '../../containers/invoices/overlays/invoice-overlay/invoice-overlay.module'
    )
    const { InvoiceOverlayComponent } = await import(
      '../../containers/invoices/overlays/invoice-overlay/invoice-overlay.component'
    )
    return this.lazyOverlayService.wrapLazyComponentModule(InvoiceOverlayComponent, InvoiceOverlayModule)
  }

  private openInquiry<T>(inquiryId: string) {
    this.lazyOverlayService
      .openLazyOverlay(
        this.loadInquiryOverlay(),
        { inquiryId },
        {
          panelClass: ['drawer-full-90'],
        }
      )
      .pipe(switchMap((dialogRef) => dialogRef.afterClosed()))
      .subscribe()
  }

  private async loadInquiryOverlay() {
    const { InquiryOverlayModule } = await import(
      '../../containers/inquiries/overlays/inquiry-overlay/inquiry-overlay.module'
    )
    const { InquiryOverlayComponent } = await import(
      '../../containers/inquiries/overlays/inquiry-overlay/inquiry-overlay.component'
    )
    return this.lazyOverlayService.wrapLazyComponentModule(InquiryOverlayComponent, InquiryOverlayModule)
  }

  private openGuest<T>(guestId: string) {
    this.guestService
      .get(guestId)
      .pipe(
        switchMap((guest) => {
          this.store.dispatch(GetGuestComplete({ guest }))
          return this.lazyOverlayService
            .openLazyOverlay(
              this.loadGuestOverlay(),
              { guest: new Guest(guest) },
              {
                panelClass: ['drawer-full-70'],
              }
            )
            .pipe(switchMap((dialogRef) => dialogRef.afterClosed()))
        })
      )
      .subscribe()
  }

  private async loadGuestOverlay() {
    const { GuestOverlayModule } = await import('../../containers/guests/overlays/guest-overlay/guest-overlay.module')
    const { GuestOverlayComponent } = await import(
      '../../containers/guests/overlays/guest-overlay/guest-overlay.component'
    )
    return this.lazyOverlayService.wrapLazyComponentModule(GuestOverlayComponent, GuestOverlayModule)
  }

  private async openRental(
    rentalId: string,
    activeTab: RentalDialogTab = RentalDialogTab.Basic,
    highlightedImages: string[] = []
  ) {
    this.store.dispatch(selectRental({ id: rentalId }))

    this.lazyOverlayService
      .openLazyOverlay(
        this.loadRentalOverlay(),
        { rentalId, activeTab, highlightedImages },
        {
          panelClass: ['drawer-full-80'],
        }
      )
      .pipe(switchMap((dialogRef) => dialogRef.afterClosed()))
      .subscribe()
  }

  private async loadRentalOverlay() {
    const { RentalOverlayModule } = await import(
      '../../containers/rentals/overlays/rental-overlay/rental-overlay.module'
    )
    const { RentalOverlayComponent } = await import(
      '../../containers/rentals/overlays/rental-overlay/rental-overlay.component'
    )
    return this.lazyOverlayService.wrapLazyComponentModule(RentalOverlayComponent, RentalOverlayModule)
  }

  private openInventory() {
    this.lazyOverlayService
      .openLazyOverlay(
        this.loadInventoryOverlay(),
        {},
        {
          panelClass: ['drawer-full-60'],
        }
      )
      .pipe(switchMap((dialogRef) => dialogRef.afterClosed()))
      .subscribe()
  }

  private async loadInventoryOverlay() {
    const { InventoryOverlayModule } = await import(
      '@tv3/containers/inventories/overlays/inventory-overlay/inventory-overlay.module'
    )
    const { InventoryOverlayComponent } = await import(
      '@tv3/containers/inventories/overlays/inventory-overlay/inventory-overlay.component'
    )
    return this.lazyOverlayService.wrapLazyComponentModule(InventoryOverlayComponent, InventoryOverlayModule)
  }
}
