import { Component, EventEmitter, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { toUpper, toLower, snakeCase, sortBy, startCase } from 'lodash'
import { UpdateGuestItem } from '@tv3/store/guest/guest.actions'
import { EditItemParent } from '@tv3/containers/guests/edit/edit-item.parent'
import { debounceTime } from 'rxjs/operators'
import { Guest } from '@tv3/store/guest/guest.model'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { AddAttributeDialogService } from '@tv3/containers/guests/dialogs/add-attribute/add-attribute-dialog.service'
import { PromptDialogService } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

@Component({
  selector: 'app-guest-attribute-list',
  templateUrl: './guest-attribute-list.component.html',
  styleUrls: ['./guest-attribute-list.component.scss'],
})
export class GuestAttributeListComponent implements EditItemParent {
  @Input() set guest(g: Guest) {
    this.guestItem = g
    this.prevGuestItem = new Guest(g)
    this.attributes = { ...this.guestItem.attributes }

    this.itemKeys = lodash.compact(
      lodash.map(this.attributes, (value, key) => {
        if (lodash.isObject(value)) {
          return
        }
        return {
          key: key,
          caption: startCase(toLower(key)),
        }
      })
    )
    this.itemKeys = sortBy(this.itemKeys, 'key')
  }

  onFocusChange$ = new EventEmitter<boolean>()
  focus = true
  debTime = 300

  attributes: { [id: string]: string }
  guestItem: Guest
  prevGuestItem: Guest
  itemKeys: { key: string; caption: string }[]

  constructor(
    private store: Store<fromRoot.State>,
    private promptDialog: PromptDialogService,
    private addAttributeDialogService: AddAttributeDialogService,
    private toaster: Toaster
  ) {
    this.onFocusChange$.pipe(debounceTime(this.debTime)).subscribe((item) => {
      if (!this.focus) {
        this.saveItem()
      }
    })
  }

  getId() {
    return 'attribute'
  }

  mainAction() {
    this.addAttributeDialogService.open().subscribe((name: string) => {
      const item = {
        key: toUpper(snakeCase(name)),
        caption: startCase(name),
      }

      this.itemKeys.push(item)
      this.itemKeys = sortBy(this.itemKeys, 'key')
      // save new added attribute
      this.attributes[item.key] = '' // init value
      this.saveItem()
    })
  }

  saveItem() {
    // Compare current and previous values
    if (JSON.stringify(this.guestItem) !== JSON.stringify(this.prevGuestItem)) {
      this.guestItem.attributes = this.attributes
      const data = {
        birthday: R.pathOr('', ['birthday'], this.guestItem),
        wife: R.pathOr('', ['wife'], this.guestItem),
        drink: R.pathOr('', ['drink'], this.guestItem),
        hobby: R.pathOr('', ['hobby'], this.guestItem),
        sport: R.pathOr('', ['sport'], this.guestItem),
        kids: R.pathOr('', ['kids'], this.guestItem),
        attributes: this.attributes,
      }
      this.store.dispatch(
        UpdateGuestItem({ guestId: this.guestItem.id, data: data, message: 'Guest Attributes updated successfully!' })
      )
    }
  }

  onBlur() {
    this.guestItem.attributes = this.attributes
    this.focus = false
    this.onFocusChange$.emit(this.focus)
  }

  onFocus() {
    this.guestItem.attributes = this.attributes
    this.focus = true
    this.onFocusChange$.emit(this.focus)
  }
}
