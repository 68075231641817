// https://github.com/w11k/ngx-componentdestroyed/blob/master/src/index.ts

import { Component, Directive, OnDestroy } from '@angular/core'
import { Observable, ReplaySubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const ON_DESTROY_SUBJECT_KEY = Symbol('ON_DESTROY_SUBJECT_KEY')

@Directive()
export class Destroyable implements OnDestroy {
  constructor() {
    ;(this as any)[ON_DESTROY_SUBJECT_KEY] = new ReplaySubject()
  }

  observeOnDestroy() {
    return (this as any)[ON_DESTROY_SUBJECT_KEY]
  }

  ngOnDestroy() {
    ;(this.observeOnDestroy() as Subject<void>).next()
  }
}

export function componentDestroyed(target: any): Observable<void> {
  const onDestroySubject = (target as any)[ON_DESTROY_SUBJECT_KEY]
  if (onDestroySubject === undefined) {
    const proto = Object.getPrototypeOf(target)
    const compInfo =
      proto !== undefined && ((proto.constructor !== undefined) !== proto.constructor.name) !== undefined
        ? ` (component: ${proto.constructor.name})`
        : ''

    throw new Error(`You are almost there! Please extends the base class 'Destroyable'${compInfo}.`)
  }

  return onDestroySubject
}

export function untilDestroy<T>(component: any): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => source.pipe(takeUntil(componentDestroyed(component)))
}
