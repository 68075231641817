import { createAction, props } from '@ngrx/store'
import { CreateRoomPayload, Room, UpdateRoomPayload } from './rooms.model'
import { Update } from '@ngrx/entity'

export const LoadRooms = createAction('[Rooms] Load all rooms')
export const LoadRoomsComplete = createAction('[Rooms] Load all rooms Complete', props<{ rooms: Room[] }>())

export const GetRentalRooms = createAction('[Rooms] Get Rental Rooms', props<{ rentalId: string }>())
export const GetRentalRoomsComplete = createAction('[Rooms] Get Rental Rooms Complete', props<{ rooms: Room[] }>())

export const UpdateRoom = createAction(
  '[Rooms] Update',
  props<{ id: string; room: UpdateRoomPayload; silent?: boolean }>()
)
export const UpdateRoomComplete = createAction('[Rooms] Update Complete', props<{ update: Update<Room> }>())

export const UpsertRooms = createAction(
  '[Rooms] Upsert Rooms',
  props<{
    createRooms?: CreateRoomPayload[]
    updateRooms?: { id: string; payload: UpdateRoomPayload }[]
    deleteRooms?: string[]
  }>()
)
export const UpsertRoomsComplete = createAction('[Rooms] Upsert Rooms Complete', props<{ rooms: Room[] }>())

export const DeleteRoom = createAction('[Rooms] Delete', props<{ id: string }>())
export const DeleteRoomComplete = createAction('[Rooms] Delete Complete', props<{ id: string }>())

export const DeleteRooms = createAction('[Rooms] Delete rooms', props<{ ids: string[] }>())
export const DeleteRoomsComplete = createAction('[Rooms] Delete rooms Complete', props<{ ids: string[] }>())
