import { Injectable } from '@angular/core'
import { DiscountCodeOverlayService } from './discount-code/overlay/discount-code-overlay.service'
import {
  GetLosDiscountComplete,
  LosDiscountService,
  LosDiscountView,
  selectLosDiscountViewById,
} from '@tokeet-frontend/los-discount'
import { select, Store } from '@ngrx/store'
import { catchError, switchMap, take, tap } from 'rxjs/operators'
import {
  DiscountCodeService,
  DiscountCodeView,
  GetDiscountCodeComplete,
  selectDiscountCodeViewById,
} from '@tokeet-frontend/discount-codes'
import { EMPTY, of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { LosDiscountOverlayService } from './los-discount/overlay/los-discount-overlay.service'

@Injectable()
export class DiscountDialogService {
  constructor(
    private discountCodeDialog: DiscountCodeOverlayService,
    private losDiscountDialog: LosDiscountOverlayService,
    private discountCodeService: DiscountCodeService,
    private losDiscountService: LosDiscountService,
    private store: Store<any>
  ) {}

  open(type: 'lengthofstay' | 'discount_code', id: string) {
    if (type === 'lengthofstay') {
      this.store
        .pipe(
          select(selectLosDiscountViewById, { id }),
          switchMap((item) =>
            item
              ? of(item)
              : this.losDiscountService.get(id).pipe(
                  tap((d) => this.store.dispatch(GetLosDiscountComplete({ item: d }))),
                  catchError((error) => {
                    this.store.dispatch(ActionFailed({ error }))
                    return EMPTY
                  })
                )
          ),
          take(1),
          switchMap((item: LosDiscountView) => this.losDiscountDialog.open(item).afterClosed())
        )
        .subscribe()
    } else {
      this.store
        .pipe(
          select(selectDiscountCodeViewById, { id }),
          switchMap((item) =>
            item
              ? of(item)
              : this.discountCodeService.get(id).pipe(
                  tap((d) => this.store.dispatch(GetDiscountCodeComplete({ item: d }))),
                  catchError((error) => {
                    this.store.dispatch(ActionFailed({ error }))
                    return EMPTY
                  })
                )
          ),
          take(1),
          switchMap((item: DiscountCodeView) => this.discountCodeDialog.open(item).afterClosed())
        )
        .subscribe()
    }
  }
}
