import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '../utility'
import { AttachmentService } from './attachment.service'
import {
  deleteAttachment,
  deleteAttachmentComplete,
  deleteAttachments,
  deleteAttachmentsComplete,
  getAttachmentsComplete,
  loadAttachments,
  loadAttachmentsComplete,
} from './attachment.actions'
import { getAttachments } from './attachment.actions'

@Injectable()
export class AttachmentEffects {
  @Effect()
  loadAttachments$ = this.actions$.pipe(
    ofType(loadAttachments),
    exhaustMap(() =>
      this.attachments.all().pipe(
        map((attachments) => loadAttachmentsComplete({ attachments })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  getAttachments$ = this.actions$.pipe(
    ofType(getAttachments),
    exhaustMap(({ ids }) =>
      this.attachments.getByIds(ids).pipe(
        map((attachments) => getAttachmentsComplete({ attachments })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteAttachment$ = this.actions$.pipe(
    ofType(deleteAttachment),
    concatMap(({ id }) =>
      this.attachments.delete(id).pipe(
        map(() => deleteAttachmentComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteAttachments$ = this.actions$.pipe(
    ofType(deleteAttachments),
    concatMap(({ ids }) =>
      this.attachments.deleteFiles(ids).pipe(
        map(() => deleteAttachmentsComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private attachments: AttachmentService) {}
}
