import { deserialize, Expose, Serializable, Type } from '@tokeet-frontend/tv3-platform'

export enum ThirdPartyIntegrationIdentifies {
  Slack = 'slack',
  Dropbox = 'dropbox',
  Mailchimp = 'mailchimp',
  Quickbooks = 'quickbooks',
  Paypal = 'paypal',
  Stripe = 'stripe',
  Xero = 'xero',
  Guestflow = 'guestflow',
  YourWelcome = 'your-welcome',
  Pricelabs = 'pricelabs',
  Duve = 'duve',
  Twilio = 'twilio',
  ChargeAutomation = 'charge-automation',
  GoogleAnalytics = 'google-analytics',
  Operto = 'operto',
  Superhog = 'superhog',
  Airdna = 'airdna',
  Mailgun = 'mailgun',
  Wheelhouse = 'wheelhouse',
  Insuraguest = 'insuraguest',
  TurnoverBnB = 'turnoverbnb',
  Boostly = 'boostly',
  OpertoTeams = 'operto-teams',
  DOINN = 'doinn',
  Minut = 'minut',
  Cleanster = 'cleanster',
  JervisSystems = 'jervis-systems',
  Alertify = 'alertify',
  Beyond = 'beyond',
  AIPEXTechnologies = 'aipex-technologies',
  TurboSuite = 'turbo-suite',
  StayFi = 'stay-fi',
  KeyNinja = 'key-ninja',
  MovingLake = 'moving-lake',
  Paraspot = 'paraspot',
  LulaCleaning = 'lula-cleaning',
  Opiniion = 'opiniion',
  Safely = 'safely',
  HKeeper = 'h-keeper',
  CheckinScan = 'checkin-scan',
  GuestGuru = 'guest-guru',
  PAPI = 'papi',
}

export class IntegrationAuthData extends Serializable<IntegrationAuthData> {
  @Expose({ name: 'pkey' })
  id: string
  @Expose({ name: 'owner_id' })
  ownerId: string
  account: number
  service: ThirdPartyIntegrationIdentifies
  status: number

  default: number
  configuration: object
  credentials: object
  created: number
  archived: number

  static deserialize(data: object): IntegrationAuthData {
    return deserialize(IntegrationAuthData, data)
  }
}

export interface AuthorizedIntegrationsById {
  [id: string]: IntegrationAuthData | any
}

export class MailChimpListStat extends Serializable<MailChimpListStat> {
  @Expose({ name: 'avg_sub_rate' })
  avgSubRate: number
  @Expose({ name: 'avg_unsub_rate' })
  avgUnsubRate: number
  @Expose({ name: 'campaign_count' })
  campaignCount: number
  @Expose({ name: 'cleaned_count' })
  cleanedCount: number
  @Expose({ name: 'cleaned_count_since_send' })
  cleanedCountSinceSend: number
  @Expose({ name: 'click_rate' })
  clickRate: number
  @Expose({ name: 'group_count' })
  groupCount: number
  @Expose({ name: 'grouping_count' })
  groupingCount: number
  @Expose({ name: 'member_count' })
  memberCount: number
  @Expose({ name: 'member_count_since_send' })
  memberCountSinceSend: number
  @Expose({ name: 'merge_var_count' })
  mergeVarCount: number
  @Expose({ name: 'open_rate' })
  openRate: number
  @Expose({ name: 'target_sub_rate' })
  targetSubRate: number
  @Expose({ name: 'unsubscribe_count' })
  unsubscribeCount: number
  @Expose({ name: 'unsubscribe_count_since_send' })
  unsubscribeCountSinceSend: number

  static deserialize(data: object): MailChimpListStat {
    return deserialize(MailChimpListStat, data)
  }
}

export class MailChimpListItem extends Serializable<MailChimpListItem> {
  id: string
  name: string
  @Type(() => MailChimpListStat)
  stats: MailChimpListStat
  visibility: string // "pub"
  @Expose({ name: 'date_created' })
  createdDate: string

  static deserialize(data: object): MailChimpListItem {
    return deserialize(MailChimpListItem, data)
  }
}

export const MAIL_CHIMP_EVENT_HANDLER = 'Events::MailChimpHandler'

export class MailChimpConfig extends Serializable<MailChimpConfig> {
  events: string[]
  handler: string
  @Expose({ name: 'mailchimplist' })
  mailChimpList: string
  uuid: string

  static deserialize(data: object): MailChimpConfig {
    return deserialize(MailChimpConfig, data)
  }
}

export interface MailChimpSyncRequest {
  events: string[]
  handler: string
  mailchimplist: string
  uuid: string
}

export class SlackConfigItem extends Serializable<SlackConfigItem> {
  @Expose({ name: 'channel' })
  slackChannelId: string
  @Expose({ name: 'channel_name' })
  slackChannelName: string
  event: string
  @Expose({ name: 'event_name' })
  eventName: string
  id: string
  @Expose({ name: 'template' })
  templateId: string
  @Expose({ name: 'template_name' })
  templateName: string
  @Expose({ name: 'user' })
  userName: string // "AdvanceCM User"
  created: number

  static deserialize(data: object): SlackConfigItem {
    return deserialize(SlackConfigItem, data)
  }
}

export class SlackChannel extends Serializable<SlackChannel> {
  created: number
  id: string
  @Expose({ name: 'is_archived' })
  isArchived: boolean
  @Expose({ name: 'is_channel' })
  isChannel: boolean
  @Expose({ name: 'is_general' })
  isGeneral: boolean
  @Expose({ name: 'is_member' })
  isMember: boolean
  @Expose({ name: 'is_mpim' })
  isMpim: boolean
  @Expose({ name: 'is_org_shared' })
  isOrgShared: boolean
  @Expose({ name: 'is_private' })
  isPrivate: boolean
  @Expose({ name: 'is_shared' })
  isShared: boolean
  members: string[]
  name: string
  @Expose({ name: 'num_members' })
  numMembers: number

  static deserialize(data: object): SlackChannel {
    return deserialize(SlackChannel, data)
  }
}

export interface PAPIAppModel {
  id: string // "app_c999c0f93e514877b19b4f23c43f5d93",
  name: string // "Duve",
  environment: string // "prod",
  connectedAt: number
  scopes: { [key: string]: boolean }
  deletion: any
}
