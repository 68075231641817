import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import * as R from 'ramda'

export function timeAgo(value: any): string {
  let date: moment.Moment
  const now = moment()

  if (moment.isMoment(value)) {
    date = value
  }
  if (moment.isDate(value)) {
    date = moment(value)
  }
  if (R.is(Number, value)) {
    date = value === 0 ? null : moment.unix(value)
  }
  if (R.is(String, value)) {
    date = moment(new Date(value))
  }

  if (!date) {
    return 'Never'
  }

  if (date.isValid() && date.isBefore(now)) {
    return date.fromNow()
  } else {
    return 'a few seconds ago' // fix time is not sync on backend and local
  }
}

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): string {
    return timeAgo(value)
  }
}
