<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-info-circle"></i> Message Details</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <ul *ngIf="details">
    <li *ngIf="details.from"><b>From:</b> {{ details.from }}</li>
    <li *ngIf="details.to"><b>To:</b> {{ details.to }}</li>
    <li *ngIf="details.cc"><b>CC:</b> {{ details.cc }}</li>
    <li *ngIf="details.bcc"><b>BCC:</b> {{ details.bcc }}</li>
    <li *ngIf="details.receivedOn"><b>Date:</b> {{ details.receivedOn | epoch }}</li>
    <li *ngIf="details.subject"><b>Subject:</b> {{ details.subject }}</li>
    <li *ngIf="details.id"><b>Id:</b> {{ details.id }}</li>
    <li *ngIf="details.status"><b>Delivery Status:</b> {{ details.status }}</li>
    <li>
      <b>Spam Score:</b> {{ details.spamScore }} ({{ details.spamFlag == 'Yes' ? 'Spam Found' : 'Spam Not Found' }})
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Close</button>
</div>
