import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { SignatureTemplate } from '@tv3/containers/inquiries/overlays/inquiry-overlay/automata-booking-overlay/store/signature-template'

export interface State extends EntityState<SignatureTemplate> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<SignatureTemplate> = createEntityAdapter<SignatureTemplate>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
