<div class="mb-3">
  <h6 class="mb-0">Subscribed Applications</h6>
</div>
<div class="container-box addons-table-holder" data-intercom-subscribed-apps>
  <div class="p-3 d-flex">
    <app-table-search (search)="onSearch($event)"></app-table-search>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped sortable" #table>
      <thead>
        <tr>
          <th>Application name</th>
          <th>when subscription started</th>
          <th>Next Payment date</th>
          <th class="no-sort">payment total</th>
          <th class="no-sort">Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="subscriptionsLoading$ | async">
          <tr>
            <td></td>
            <td></td>
            <td>
              <app-loading-section></app-loading-section>
            </td>
            <td></td>
            <td></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!(subscriptionsLoading$ | async)">
          <tr *ngFor="let subscription of subscriptions$ | async">
            <td>
              <img [src]="(apps$ | async)[subscription.product]?.logo_url" alt="App Logo" class="addons-img" />
              {{ (apps$ | async)[subscription.product]?.name }}
            </td>
            <td [attr.data-sort]="subscription.created">
              <fa-icon [icon]="['fal', 'alarm-clock']" class="icon-danger"></fa-icon>
              {{ subscription.created | epoch }}
              <span *ngIf="isOverdue | call : subscription.until" class="due-date">1 Day Overdue</span>
            </td>
            <td [class.has-trial]="subscription.status === 'trialing'" [attr.data-sort]="subscription.until">
              <ng-container *ngIf="subscription.status === 'trialing'">
                <span>Trial until: </span>{{ subscription.trial_end | epoch }}
              </ng-container>
              <ng-container *ngIf="subscription.status !== 'trialing'">
                {{ subscription.until | epoch }}
              </ng-container>
            </td>
            <td>
              <ng-container [ngSwitch]="subscription.product">
                <ng-container *ngSwitchCase="'tokeet_ai'"> $0.08 per generated response </ng-container>
                <ng-container *ngSwitchCase="'power_reporting'">
                  ${{ roundFloat | call : subscription.price * subscription.quantity }}
                </ng-container>
                <ng-container *ngSwitchCase="'tokeet_smart_devices'">
                  ${{ roundFloat | call : subscription.price * subscription.quantity }} for
                  {{ subscription.quantity }} device(s) per month
                </ng-container>
                <ng-container *ngSwitchCase="'rategenie'">
                  <ng-container *ngIf="!subscription.discount"> {{ rgPrice$ | async | currency }} </ng-container>
                  <ng-container *ngIf="!!subscription.discount">
                    {{ (rgPrice$ | async) - (rgPrice$ | async) * (subscription.discount.percent_off / 100) | currency }}
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault> ${{ roundFloat | call : subscription.price }} </ng-container>
              </ng-container>
              {{ getSubscriptionPriceUnit | call : subscription.metadata?.pricing : (isYearly | call : subscription) }}
            </td>
            <td
              *ngIf="
                subscription.metadata?.product !== 'webready' &&
                subscription.metadata?.product !== 'tokeet' &&
                subscription.metadata?.product !== 'website_management'
              "
              (click)="$event.stopPropagation()"
              class="status-column"
            >
              <div class="d-flex align-items-center">
                <app-form-switch
                  [inline]="true"
                  [value]="isActive | call : subscription"
                  (valueChange)="onStatusChange($event, subscription)"
                  matTooltip="Cancel subscription for this app"
                  matTooltipPosition="left"
                ></app-form-switch>
                <div class="status">
                  {{ getStatus | call : subscription }}
                </div>
              </div>
              <div *ngIf="subscription.cancel_at_period_end" class="valid-until">
                Valid until: {{ subscription.until | epoch }}
              </div>
            </td>
            <td
              *ngIf="
                subscription.metadata?.product === 'tokeet' || subscription.metadata?.product === 'website_management'
              "
              (mouseenter)="subscription.$hideToggle = true"
              (mouseleave)="subscription.$hideToggle = false"
              class="status-column"
            >
              <div class="d-flex align-items-center">
                <ng-container *ngIf="!subscription?.$hideToggle">
                  <app-form-switch [inline]="true" [value]="true"></app-form-switch>
                  <span class="status">Active</span>
                </ng-container>
                <ng-container *ngIf="subscription?.$hideToggle">
                  <span class="webready-status">Please contact support to cancel</span>
                </ng-container>
              </div>
            </td>
          </tr>
          <ng-container *ngIf="webreadySubscription$ | async as subscription">
            <ng-container *ngIf="subscription.tier === 'unlimited'">
              <tr>
                <td>
                  <img
                    [src]="geWebreadyLogo(apps$, 'single_rental_website') | async"
                    alt="App Logo"
                    class="addons-img"
                  />
                  Webready Unlimited
                </td>
                <td [attr.data-sort]="subscription.created">
                  <fa-icon [icon]="['fal', 'alarm-clock']" class="icon-danger"></fa-icon>
                  {{ subscription.created | epoch }}
                  <span *ngIf="isOverdue | call : subscription.current_period_end" class="due-date">1 Day Overdue</span>
                </td>
                <td [attr.data-sort]="subscription.current_period_end">
                  {{ subscription.current_period_end | epoch }}
                </td>
                <td>$499.00 per month</td>
                <td class="status-column">
                  <span class="webready-status">Please reach out to support for updates</span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="subscription.tier !== 'unlimited'">
              <tr *ngFor="let sub of subscription.features | keyvalue">
                <td>
                  <img [src]="geWebreadyLogo(apps$, sub.key) | async" alt="App Logo" class="addons-img" />
                  {{ sub.value.name }}
                </td>
                <td [attr.data-sort]="subscription.created">
                  <fa-icon [icon]="['fal', 'alarm-clock']" class="icon-danger"></fa-icon>
                  {{ subscription.created | epoch }}
                  <span *ngIf="isOverdue | call : subscription.current_period_end" class="due-date">1 Day Overdue</span>
                </td>
                <td [attr.data-sort]="subscription.current_period_end">
                  {{ subscription.current_period_end | epoch }}
                </td>
                <td>
                  {{ getCharge | call : sub.key : sub.value }}
                </td>
                <td
                  *ngIf="!isWebreadyAddon(sub.key)"
                  (mouseenter)="sub.$hideToggle = true"
                  (mouseleave)="sub.$hideToggle = false"
                  class="status-column"
                >
                  <ng-container *ngIf="!sub.$hideToggle">
                    <div class="d-flex align-items-center">
                      <app-form-switch [inline]="true" [value]="isActive | call : subscription"></app-form-switch>
                      <div class="status">
                        {{ getWebreadyStatus | call : subscription }}
                      </div>
                    </div>
                    <div *ngIf="subscription.cancel_at_period_end" class="valid-until">
                      Valid until: {{ subscription.current_period_end | epoch }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="sub.$hideToggle">
                    <span class="webready-status">Can only be updated on Webready app</span>
                  </ng-container>
                </td>
                <td *ngIf="isWebreadyAddon(sub.key)" (click)="$event.stopPropagation()" class="status-column">
                  <div class="d-flex align-items-center">
                    <app-form-switch
                      [value]="isActiveWebreadyAddon | call : subscription : sub.key"
                      (valueChange)="onWebreadyAddonStatusChange($event, sub.key)"
                      [inline]="true"
                    ></app-form-switch>
                    <div *ngIf="unsubscribingWebreadyAddons === sub.key" class="unsubscribing">
                      <fa-icon [icon]="['fal', 'spinner']" [spin]="true" [size]="'2x'"></fa-icon>
                    </div>
                    <div *ngIf="unsubscribingWebreadyAddons !== sub.key" class="status">
                      {{ getStatusWebreadyAddon | call : subscription : sub.key }}
                    </div>
                  </div>
                  <div
                    *ngIf="
                      subscription.cancel_at_period_end ||
                      (!!subscription.next_billing_cycle &&
                        !(subscription.next_billing_cycle?.features)[sub.key]?.active)
                    "
                    class="valid-until"
                  >
                    Valid until: {{ subscription.current_period_end | epoch }}
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
