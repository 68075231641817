import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { OverlayEffects } from '@tv3/store/overlay/overlay.effects'

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([OverlayEffects])],
  declarations: [],
})
export class OverlayModule {}
