import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { concat, Observable, of } from 'rxjs'
import { last, map, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { RentalService } from '../rental/rental.service'
import { Rate, RentalRates, RentalRatesV2Response } from './rate.model'
import { RateView } from './rate.interfaces'
import { toMoment } from '../../functions'
import * as momentNs from 'moment'
import { Toaster } from '../../services/toaster.service'
import { toastError } from '../../rx-operators/toast-error'

const moment = momentNs

@Injectable()
export class RateService {
  constructor(private http: HttpClient, private toaster: Toaster, private rentalService: RentalService) {}

  all(rentalIds: string[]) {
    const url = '@api/rental/rates/list'

    const data = { rentals: rentalIds }

    return this.http.put<object[]>(url, data).pipe(
      map((items) => items.map(RentalRates.deserialize)),
      toastError(this.toaster)
    )
  }

  getRates(rentalIds: string[], start: number, end: number, categories = ['default']) {
    const s = toMoment(start)
    const e = toMoment(end)
    const url = `@api/v1/rates?&start_date=${s.format('YYYY-MM-DD')}&end_date=${e.format('YYYY-MM-DD')}&rows=${
      e.diff(s, 'days') + 1
    }`
    return this.http
      .post<{ response: RentalRatesV2Response }>(url, { rentals: rentalIds, categories })
      .pipe(map((res) => res?.response))
  }

  addRate(rentalId: string, rate: Rate): Observable<Rate> {
    const url = `@api/rental/rate/${rentalId}`

    return this.http.put<object>(url, rate.serialize()).pipe(
      map((result) => Rate.deserialize(result)),
      toastError(this.toaster) as any
    )
  }

  updateRate(rentalId: string, rateId: string, rate: Rate): Observable<Rate> {
    const url = `@api/rental/rate/${rentalId}/${rateId}`

    return this.http.put<object>(url, rate.serialize()).pipe(
      map((result) => Rate.deserialize(result)),
      toastError(this.toaster) as any
    )
  }

  deleteRate(rentalId: string, rateId: string) {
    const url = `@api/rental/rate/${rentalId}/${rateId}`

    return this.http.delete<object[]>(url).pipe(
      map((rates) => (lodash.isArray(rates) ? rates.map(Rate.deserialize) : [])),
      toastError(this.toaster)
    )
  }

  deletePromotionalRate(rentalId: string, rateId: string) {
    const url = `@api/rental/promotion/rate/${rentalId}/${rateId}`

    return this.http.delete<object[]>(url).pipe(
      map((rates) => (lodash.isArray(rates) ? rates.map(Rate.deserialize) : [])),
      toastError(this.toaster)
    )
  }

  deleteRates(rentalId: string, rates: Rate[]): Observable<{ rates: Rate[]; promotions: Rate[] }> {
    const url = `@api/rental/rates/${rentalId}`
    const request = (payload: { ratekeys?: string[]; promo?: 1 }) => this.http.request('delete', url, { body: payload })
    const promoKeys = rates.filter((rate) => rate.type === 'promotion').map((rate) => rate.rateId)
    const rateKeys = rates.filter((rate) => rate.type !== 'promotion').map((rate) => rate.rateId)

    return concat(
      !!rateKeys.length ? request({ ratekeys: rateKeys }) : of(true),
      !!promoKeys.length ? request({ promo: 1, ratekeys: promoKeys }) : of(true)
    ).pipe(
      last(),
      switchMap(() => this.rentalService.get(rentalId)),
      map((rental) => rental)
    )
  }

  getBaseRateViews(rates: RentalRates[]) {
    const rateViews = lodash.mapValues(
      lodash.keyBy(rates, (rate) => rate.rentalId),
      (rentalRate) => {
        return this.getRentalBaseRateView(rentalRate)
      }
    )

    return rateViews
  }

  getRateCategories(rates: RentalRates[]) {
    return lodash
      .chain(rates)
      .map((rate) => lodash.keys(rate.categories))
      .flatten()
      .uniq()
      .sortBy((category) => category?.toLowerCase())
      .value()
  }

  getRateViews(rates: RentalRates[]) {
    // convert "from" and "to" date string to epoch

    rates = rates.map((rate) => {
      rate = new RentalRates(rate)

      const baseRate = rate.baseRate || {}

      rate.baseRate = {
        ...baseRate,
        from: baseRate['from'] ? this.toDateEpoch(baseRate['from']) : null,
        to: baseRate['to'] ? this.toDateEpoch(baseRate['to']) : null,
      }

      rate.categories = lodash.mapValues(rate.categories, (subrates) =>
        subrates.map(
          (subrate) =>
            ({
              ...subrate,
              from: this.toDateEpoch(subrate['from']),
              to: this.toDateEpoch(subrate['to']),
            } as any)
        )
      )

      return rate
    })

    const categories = this.getRateCategories(rates)

    const rateViews: Record<string, Record<string, Record<string, RateView>>> = {}

    categories.forEach((category) => {
      rateViews[category] = this.getCategoryRateViews(rates, category)
    })

    return rateViews
  }

  isWeekend(date: number) {
    const weekday = toMoment(date).isoWeekday()

    return weekday === 6 || weekday === 7
  }

  private getRentalBaseRateView(rentalRate: RentalRates) {
    const baseRate = rentalRate.baseRate || {}

    const price = baseRate['price'] || null
    const weekend = baseRate['weekend'] || null

    if (!price) {
      return null
    }

    const rateView: RateView = {
      price: +price,
      weekend: weekend ? +weekend : null,

      currency: baseRate['currency'] || null,

      maxGuest: baseRate['maxguest'] || null,
      minimumStay: baseRate['minimumstay'] || null,
      maximumStay: baseRate['maximumstay'] || null,

      category: 'Base Rate',

      start: this.toDateEpoch(baseRate['from']),
      end: this.toDateEpoch(baseRate['to']),

      baseRate: true,
    }

    return rateView
  }

  private getCategoryRateViews(rates: RentalRates[], category: string) {
    const rateViews: Record<string, Record<string, RateView>> = {}

    lodash.forEach(rates, (rentalRate) => {
      const rentalRateViews = this.getRentalRateViews(rentalRate, category)

      if (!lodash.isEmpty(rentalRateViews)) {
        rateViews[rentalRate.rentalId] = rentalRateViews
      }
    })

    return rateViews
  }

  private getRentalRateViews(rentalRate: RentalRates, category: string) {
    const rates: Record<string, RateView> = {}

    const baseRate = rentalRate.baseRate || {}

    const categoryRates = rentalRate.categories[category] || []

    for (let i = 0, l1 = categoryRates.length; i < l1; i++) {
      const categoryRate = categoryRates[i]
      const daySpecifics = this.getDaySpecificPrices(categoryRate['dayspecifics'])

      if (!categoryRate['price']) {
        continue
      }

      const start = toMoment(categoryRate['from'] as any)
      const end = toMoment(categoryRate['to'] as any)

      while (start <= end) {
        const day = start.unix()
        const weekDay = start.isoWeekday() - 1

        const weekendPrice = categoryRate['weekend'] ? categoryRate['weekend'] : null
        const price: number =
          daySpecifics[weekDay] !== null ? daySpecifics[weekDay] : weekendPrice || categoryRate['price']

        if (!price) continue

        const rateView: RateView = {
          date: day,

          price: +price,
          weekend: weekendPrice ? +weekendPrice : null,
          currency: categoryRate['currency'] || baseRate['currency'] || rentalRate.currency || null,

          maxGuest: categoryRate['maxguest'] || baseRate['maxguest'] || null,
          minimumStay: lodash.get(categoryRate, 'minimumstay', lodash.get(baseRate, 'minimumstay', null)),
          maximumStay: categoryRate['maximumstay'] || baseRate['maximumstay'] || null,

          category,

          start: categoryRate['from'] || baseRate['from'],
          end: categoryRate['to'] || baseRate['from'],
        }

        rates[day] = rateView

        start.add(1, 'day')
      }
    }

    return rates
  }

  private getDaySpecificPrices(daySpecifics: object[]) {
    const prices: Record<string, number> = {}

    for (let weekDay = 0; weekDay < 7; weekDay++) {
      const specific = lodash.find(daySpecifics, (item: object) => {
        return lodash.includes(item['days'], weekDay)
      })

      const price = specific && specific['price']

      prices[weekDay] = price || null
    }

    return prices
  }

  private toDateEpoch(date: string) {
    return moment.utc(date, 'YYYY-MM-DD', true).startOf('date').unix()
  }
}
