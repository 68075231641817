<ng-container [formGroup]="form">
  <div class="d-flex flex-column gap-2 px-3 pt-3" *ngIf="showHeader">
    <table class="table">
      <tbody>
        <tr>
          <td>To:</td>
          <td class="w-100">
            <div class="d-flex align-items-center gap-2">
              <mat-form-field2 class="mat-block" [showLabel]="false">
                <input matInput required placeholder="To" type="text" [value]="inquiry.guestDetails?.name" disabled />
              </mat-form-field2>
              <div class="d-flex align-items-center gap-2">
                <a (click)="hasCC = !hasCC" [class.active]="hasCC">CC</a>
                <a (click)="hasBCC = !hasBCC" [class.active]="hasBCC">BCC</a>
                <a
                  class="text-secondary text-lg position-relative"
                  matTooltip="Close"
                  (click)="onClose()"
                  style="top: -1.25rem; right: -0.25rem"
                >
                  <i class="far fa-times"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="hasCC">
          <td>CC:</td>
          <td>
            <app-select-search
              [items]="ccUsers"
              [multiple]="true"
              bindLabel="name"
              placeholder="Nothing Selected"
              [ctrl]="form.get('cc')"
              class="mat-block"
              matTooltip="Please select an email from the list or type an email and hit enter key."
              matTooltipPosition="above"
            ></app-select-search>
          </td>
        </tr>
        <tr *ngIf="hasBCC">
          <td>BCC:</td>
          <td>
            <app-select-search
              [items]="users$ | async"
              [ctrl]="form.get('bcc')"
              [inline]="true"
              bindLabel="name"
              bindValue="primaryEmail"
              placeholder="Nothing Selected"
              class="mat-block"
            ></app-select-search>
          </td>
        </tr>
        <tr *ngIf="guest">
          <td>Inquiry:</td>
          <td>
            <app-select-search
              [items]="inquiries$ | async"
              [ctrl]="inquiryCtrl"
              (search)="inquirySearch.next($event)"
              [multiple]="false"
              [inline]="true"
              bindLabel="name"
              bindValue="id"
              placeholder="Nothing Selected"
              class="mat-block"
            ></app-select-search>
          </td>
        </tr>
        <tr>
          <td>Subject:</td>
          <td>
            <mat-form-field2 class="mat-block" [showLabel]="false">
              <input matInput required placeholder="To" type="text" formControlName="subject" />
            </mat-form-field2>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- editor -->
  <div class="comment-composer flex-fill px-3 pt-3">
    <ng-container *ngIf="showEditor">
      <div class="additional-actions position-absolute">
        <a
          class="btn btn-secondary-light2"
          matTooltip="Expand the text editor."
          matTooltipPosition="above"
          *ngIf="!isPopup && !isUsingWhatsappTemplate"
          (click)="onPopup()"
          ><i class="cs-icon cs-icon-expand"></i
        ></a>
        <a
          class="btn btn-secondary-light2 text-xxl"
          matTooltip="Close"
          matTooltipPosition="above"
          (click)="onClose()"
          *ngIf="!showHeader"
        >
          <i class="far fa-times"></i>
        </a>
      </div>
      <app-tiny-mce-editor
        #tinyMceEditor
        additionalToolbar="| datadic | subject"
        [options]="form.get('delivery').value === 'email' ? null : { toolbar: 'undo redo | datadic | subject' }"
        [minHeight]="minEditorHeight"
        [maxHeight]="maxEditorHeight"
        (setup)="onEditorSetup($event)"
        [ctrl]="form.get('messageHtml')"
        [disabled]="isUsingWhatsappTemplate"
      ></app-tiny-mce-editor>
    </ng-container>
    <div
      *ngIf="isAIGenerating"
      class="position-absolute d-flex flex-column align-items-center justify-content-center bg-white text-center"
      style="top: 0; left: 0; right: 0; bottom: 0; opacity: 0.95; z-index: 2"
    >
      <app-loading-section class="ai-generating"></app-loading-section>
      <div>
        Our AI is putting on its thinking cap, pondering your question. <br />
        Hang in there!
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div class="d-flex align-items-center p-3">
    <div dropdown [dropup]="true" class="btn-group">
      <a dropdownToggle type="button" class="btn btn-link text-secondary dropdown-toggle">
        <span class="text-muted text-sm">Via</span>
        <img [src]="t.logo" alt="" height="15" *ngIf="getSelectedDelivery(); let t" />
      </a>

      <ul *dropdownMenu class="dropdown-menu">
        <li *ngFor="let t of messageDeliveryMethodOptions">
          <button
            (click)="onChangeDelivery(t.channel)"
            type="button"
            (mouseenter)="btn.classList.add('focus'); isWhatsappTemplatesOpen = false"
            (mouseleave)="btn.classList.remove('focus')"
            *ngIf="t.id !== MessagingChannelType.Whatsapp"
            [disabled]="!isMsgChannelEnabled(t.id)"
            [matTooltipDisabled]="isMsgChannelEnabled(t.id)"
            [matTooltip]="'Guest doesn’t have ' + t.label + ' email'"
            matTooltipPosition="right"
            #btn
            [ngStyle]="btn.classList.contains('focus') ? { color: t.color } : {}"
          >
            <img
              [src]="t.logo"
              height="15"
              [style.filter]="
                btn.classList.contains('focus') || form.get('delivery').value == t.channel ? 'none' : 'grayscale(1)'
              "
            />
            {{ t.label }}
          </button>

          <ng-container *ngIf="t.id === MessagingChannelType.Whatsapp">
            <button
              (click)="onChangeDelivery(t.channel)"
              type="button"
              (mouseenter)="btn.classList.add('focus'); isWhatsappTemplatesOpen = !isWhatsappTemplatesOpen"
              (mouseleave)="btn.classList.remove('focus')"
              cdkOverlayOrigin
              #whatsappTemplatesTrigger="cdkOverlayOrigin"
              #btn
              [ngStyle]="btn.classList.contains('focus') ? { color: t.color } : {}"
            >
              <img
                [src]="t.logo"
                height="15"
                [style.filter]="
                  btn.classList.contains('focus') || form.get('delivery').value == t.channel ? 'none' : 'grayscale(1)'
                "
              />
              {{ t.label }}
            </button>
            <ng-template
              cdkConnectedOverlay
              (overlayOutsideClick)="isWhatsappTemplatesOpen = false"
              [cdkConnectedOverlayOffsetX]="15"
              [cdkConnectedOverlayPositions]="[
                { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' }
              ]"
              [cdkConnectedOverlayOrigin]="whatsappTemplatesTrigger"
              [cdkConnectedOverlayOpen]="isMessagingSubscribed ? isWhatsappTemplatesOpen : false"
            >
              <div>
                <ul
                  class="dropdown-menu show position-relative"
                  style="top: 0"
                  (click)="isWhatsappTemplatesOpen = false"
                >
                  <li *ngFor="let tmp of whatsappTemplates">
                    <button type="button" (click)="onChangeWhatsappTemplate(t.channel, tmp)">
                      {{ tmp.name | titleCase }}
                    </button>
                  </li>
                </ul>
              </div>
            </ng-template>
          </ng-container>
        </li>
      </ul>
    </div>
    <div class="flex-fill"></div>
    <ul class="list-reset d-flex align-items-center gap-3">
      <ng-container>
        <li *ngIf="(enableQuote | call : inquiry) && isFeatureEnabled('quote')">
          <mat-checkbox
            matTooltip="Insert quote into email"
            matTooltipPosition="above"
            formControlName="addCost"
            class="mt-primary addcost"
          >
            Add Quote
          </mat-checkbox>
        </li>
        <li *ngIf="isFeatureEnabled('template')">
          <app-select-search
            [items]="templates$ | async"
            (valueChange)="onTemplateChange($event)"
            [hasBlank]="true"
            [multiple]="false"
            bindLabel="name"
            placeholder="Select Template"
            style="width: 10rem"
          ></app-select-search>
        </li>
        <li *ngIf="isFeatureEnabled('attachment')">
          <a
            (click)="onAttachment()"
            matTooltip="Attachments"
            matTooltipPosition="above"
            class="btn btn-secondary-light text-xl"
          >
            <i class="cs-icon cs-icon-paperclip"></i>
          </a>
        </li>
        <li *ngIf="isFeatureEnabled('ai')">
          <a
            (click)="onAiResponder()"
            [matTooltip]="
              isAISubscriptionValid ? 'Generate a reply for the above message.' : 'Go to Appstore to subscribe for this'
            "
            [style.filter]="isAISubscriptionValid ? 'none' : 'grayscale(1)'"
            matTooltipPosition="above"
            class="btn btn-secondary-primary text-xl"
          >
            <i class="cs-icon cs-icon-ai"></i>
          </a>
        </li>
      </ng-container>
      <li>
        <app-button
          (click)="onSend(form)"
          icon="cs-icon cs-icon-send"
          [class]="'success' | buttonClass"
          [disabled]="(isAddingMessage$ | async) || isAIGenerating"
          [loading]="isAddingMessage$ | async"
        >
          Send
        </app-button>
      </li>
    </ul>
  </div>
</ng-container>
