import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { DiscountFee } from '@tv3/models/inquiry/booking-engine-cost'

@Pipe({
  name: 'feesSum',
})
export class FeesSumPipe implements PipeTransform {
  transform(fees: DiscountFee[]): number {
    return R.pipe(
      R.map((f: DiscountFee) => (R.is(Number, f.amount) ? f.amount : 0)),
      R.sum
    )(fees || [])
  }
}
