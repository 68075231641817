<form>
  <div class="modal-header">
    <h5 class="modal-title" matTooltip="Expand icon → Preview to ensure there is no error in the uploaded file.">
      <i class="cs-icon cs-icon-upload"></i>&nbsp;&nbsp; Import Guests from CSV Files
    </h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-csv-file-parser
      [columnDefs]="columnDefs"
      [guide]="guide"
      [maxUploads]="100"
      (parsed)="onCsvFilesParsed($event)"
    ></app-csv-file-parser>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button (click)="create()" [disabled]="!files?.length" type="button" class="btn btn-secondary-info create-btn">
      <i class="cs-icon cs-icon-upload"></i> upload
    </button>
  </div>
</form>
