import { Component, Input, OnInit } from '@angular/core'
import { isFunction } from 'lodash'

declare const window: any

@Component({
  selector: 'app-help-widget',
  templateUrl: './help-widget.component.html',
  styleUrls: ['./help-widget.component.scss'],
})
export class HelpWidgetComponent implements OnInit {
  public open = false
  public spin = true
  @Input() direction = 'right'
  @Input() animationMode = 'scale'

  constructor() {}

  ngOnInit() {}

  goToScheduleTraining() {
    window.open('https://calendly.com/tokeet')
  }

  goToHelp() {
    window.open('https://advance.helpdocs.io/')
  }

  goToCommunity() {
    window.open('https://community.tokeet.com/')
  }

  goToTokeetStatus() {
    window.open('https://status.tokeet.com/')
  }

  openChat() {
    if (isFunction(window.Intercom)) {
      window.Intercom('show')
    }
    this.open = false
  }
}
