<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-ban"></i> Cancel Airbnb Booking</h5>
  <button (click)="dialogRef.close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="alert alert-danger" *ngIf="!!penaltyAmountUsdAccurate">
      <i class="fas fa-exclamation-triangle"></i>
      You will be charged with penalty of ${{ penaltyAmountUsdAccurate }} amount if you cancel the booking.
    </div>
    <div class="alert alert-danger" *ngIf="noAirbnbCancelReason">
      <i class="fas fa-exclamation-triangle"></i>
      Airbnb is not allowing this to be canceled, but you can still cancel this booking in AdvanceCM only.
    </div>
    <mat-form-field2 class="mat-block">
      <mat-select formControlName="reason" placeholder="Cancel Reason" required>
        <mat-option
          *ngFor="let item of reasons"
          [matTooltip]="item.description"
          matTooltipPosition="right"
          [value]="item.id"
        >
          {{ item.title }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="form.get('reason').touched && form.get('reason').hasError('required')">
        Reason is required.
      </mat-error>
    </mat-form-field2>
    <mat-form-field2 class="mat-block">
      <textarea
        matInput
        placeholder="Message to guest"
        rows="3"
        formControlName="message_to_guest"
        cdkTextareaAutosize
      ></textarea>
      <mat-error *ngIf="form.get('message_to_guest').touched && form.get('message_to_guest').hasError('required')">
        Message to guest is required.
      </mat-error>
      <mat-error *ngIf="form.get('message_to_guest').hasError('minlength')">
        min message length is <strong>30</strong>
      </mat-error>
      <mat-error *ngIf="form.get('message_to_guest').hasError('maxlength')">
        max message length is <strong>240</strong>
      </mat-error>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button (click)="dialogRef.close()" type="button" class="btn btn-light" matTooltip="Close overlay with any action">
    <i class="far fa-times"></i> Close
  </button>

  <button
    (click)="onCancel()"
    matTooltip="Cancel this booking in AdvanceCM only."
    type="button"
    [disabled]="isLoading"
    class="btn btn-secondary-info create-btn"
  >
    <i class="fal fa-ban"></i> Cancel in AdvanceCM
  </button>

  <button
    (click)="onDecline(form)"
    type="button"
    [disabled]="isLoading || noAirbnbCancelReason"
    class="btn btn-secondary-danger create-btn"
    matTooltip="This will Cancel the booking in Airbnb as well. Charges may apply"
  >
    <i class="fal fa-ban"></i> Decline
  </button>
</div>
