import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators'
import {
  asLocalDate,
  asUTCEpoch,
  compactObject,
  DataRecurringCondition,
  Destroyable,
  isSomething,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged, startWith } from 'rxjs/operators'

export function createRecurringForm(fb: FormBuilder, isUnlimited = true) {
  const positiveInteger = [
    RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false }),
    RxwebValidators.minNumber({ value: 1 }),
  ]
  const form = fb.group({
    interval: [1, positiveInteger],
    frequency: ['MONTHLY'],
    count: [undefined, positiveInteger],
    weekDay: [[moment().isoWeekday()]],
    until: [],
    end: [isUnlimited ? undefined : 'on'],
  })

  return form
}

export function setRecurringFormData(form: FormGroup, data: DataRecurringCondition, isUnlimited = true) {
  form.patchValue({
    ...data,
    until: asLocalDate(data?.until),
    weekDay: data?.weekDay || [moment().isoWeekday()],
  })

  const endCtrl = form.get('end')
  if (data?.count) {
    endCtrl.setValue('after')
  } else if (data?.until) {
    endCtrl.setValue('on')
  } else if (isUnlimited) {
    endCtrl.setValue('')
  }
}

export function getRecurringFormData(form: FormGroup): DataRecurringCondition {
  const { end, weekDay, frequency, until, ...others } = form.getRawValue()
  return compactObject({
    weekDay: frequency === 'WEEKLY' ? weekDay : [],
    until: asUTCEpoch(until),
    frequency,
    ...others,
  })
}

@Component({
  selector: 'app-recurring-form',
  templateUrl: './recurring-form.component.html',
  styleUrls: ['./recurring-form.component.scss'],
})
export class RecurringFormComponent extends Destroyable implements OnInit {
  repeatIntervalOptions = ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']
  weekDayOptions = ['m', 't', 'w', 't', 'f', 's', 's']
  occurrencesOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  ]

  @Input() form: FormGroup
  @Input() repeatableCtrl: FormControl
  @Input() isUnlimitedEnabled = true
  constructor() {
    super()
  }

  ngOnInit(): void {
    combineLatest([
      this.repeatableCtrl.valueChanges.pipe(startWith(this.repeatableCtrl.value)),
      this.form.get('end').valueChanges.pipe(startWith(this.form.get('end').value)),
    ])
      .pipe(
        distinctUntilChanged((a, b) => lodash.isEqual(a, b)),
        untilDestroy(this)
      )
      .subscribe(([repeatable, endOn]: [boolean, string]) => {
        if (repeatable) {
          this.form.enable()
        } else {
          this.form.disable()
        }
        const untilCtrl = this.form.get('until')
        const countCtrl = this.form.get('count')
        if (repeatable) {
          if (endOn !== 'on') {
            untilCtrl.reset()
            untilCtrl.clearValidators()
          } else {
            untilCtrl.setValidators([Validators.required])
          }
          if (endOn !== 'after') {
            countCtrl.reset()
            countCtrl.clearValidators()
          } else {
            countCtrl.setValidators([Validators.required])
          }
        } else {
          untilCtrl.reset()
          countCtrl.reset()
          untilCtrl.clearValidators()
          countCtrl.clearValidators()
        }
        untilCtrl.updateValueAndValidity()
        countCtrl.updateValueAndValidity()
      })
  }

  isWeekdaySelected(day: number) {
    const ctrl = this.form.get('weekDay')
    const selected = ctrl.value || []
    return lodash.includes(selected, day)
  }

  onWeekdayClick(day: number) {
    const ctrl = this.form.get('weekDay')
    const selected = ctrl.value || []
    if (selected.includes(day)) {
      ctrl.setValue(selected.filter((d) => d != day))
    } else {
      ctrl.setValue([...selected, day].sort())
    }
    if (!isSomething(ctrl.value)) {
      ctrl.setValue([moment().isoWeekday()])
    }
  }
}
