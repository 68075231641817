<ng-container *ngIf="!isReadOnlyBooking && inquiry">
  <ng-template [ngTemplateOutlet]="confirmBtn" *ngIf="!isBooked && !isAirbnbAPIInquiry"></ng-template>
  <ng-template [ngTemplateOutlet]="cancelBtn" *ngIf="isBooked"></ng-template>
</ng-container>

<ng-template #cancelBtn>
  <app-button
    *ngIf="!isVrbo && !isHolidu"
    icon="fal fa-ban"
    class="btn btn-secondary-danger"
    [class.btn-sm]="btnSm"
    (click)="onCancelBooking()"
    [loading]="isCanceling$ | async"
    >Cancel Booking</app-button
  >

  <div *ngIf="isVrbo" dropdown class="btn-group">
    <app-button
      dropdownToggle
      icon="fal fa-ban"
      class="btn btn-secondary-danger"
      [class.btn-sm]="btnSm"
      (click)="onCancelBooking()"
      [loading]="isCanceling$ | async"
      matTooltip="Cancel booking"
      >Cancel</app-button
    >
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li>
        <button (click)="onCancelBooking(true)" type="button">Cancelled by Guest</button>
      </li>
      <li>
        <button (click)="onCancelBooking()" type="button">Cancelled by Property Manager</button>
      </li>
    </ul>
  </div>

  <div *ngIf="isHolidu" dropdown class="btn-group">
    <app-button
      dropdownToggle
      icon="fal fa-ban"
      class="btn btn-secondary-danger"
      [class.btn-sm]="btnSm"
      [loading]="isCanceling$ | async"
      matTooltip="Cancel booking"
    >
      Cancel
    </app-button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" style="max-height: 300px; overflow: auto">
      <li *ngFor="let item of holiduCancelReasons">
        <button (click)="onCancelBookingWithReason(item.id)" type="button">
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #confirmBtn>
  <ng-container *ngIf="isAirbnbAPIBooking && bookingStatus === 'pending'; else confirmBooking">
    <app-button
      (click)="onAcceptAirbnbBooking(inquiry)"
      icon="fal fa-check-square"
      [loading]="isLoading"
      class="btn btn-secondary-info"
      [class.btn-sm]="btnSm"
      >Accept</app-button
    >
    <app-button
      (click)="onDeclineAirbnbBooking(inquiry)"
      icon="fal fa-ban"
      [loading]="isLoading"
      class="ml-3 btn btn-secondary-danger"
      [class.btn-sm]="btnSm"
      >Decline</app-button
    >
  </ng-container>

  <ng-template #confirmBooking>
    <app-button
      *ngIf="bookingStatus !== 'deny'"
      [matTooltip]="
        isAirbnbAPIBooking && isCanceled ? 'You cannot confirm a cancelled Airbnb booking' : 'Confirm booking'
      "
      matTooltipPosition="below"
      icon="fal fa-check-square"
      class="btn btn-secondary-info"
      [class.btn-sm]="btnSm"
      (click)="onConfirmBooking()"
      [disabled]="isAirbnbAPIBooking && isCanceled"
      [loading]="isConfirmingBooking$ | async"
      >Confirm Booking</app-button
    >
  </ng-template>
</ng-template>
