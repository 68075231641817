import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromPlan from './plan.reducer'
import { EffectsModule } from '@ngrx/effects'
import { PlanEffects } from './plan.effects'
import { SubscriptionEffects } from '@tv3/store/plan/subscription.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('plan', fromPlan.planReducer),
    EffectsModule.forFeature([PlanEffects, SubscriptionEffects]),
  ],
  declarations: [],
})
export class PlanModule {}
