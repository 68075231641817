<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-2">
        <h6 class="modal-title">Connection Details</h6>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box mb-3">
        <div class="px-3 py-2 border-bottom">
          <div class="d-flex align-items-center gap-3">
            <div class="title">
              <img [src]="channel?.logoColor" [alt]="channel?.friendlyName" />
            </div>
            <div class="flex-fill"></div>
            <app-form-switch [value]="!!connection?.status" (valueChange)="onToggleStatus()" [inline]="true">
              {{ !!connection?.status ? 'Active' : 'Inactive' }}
            </app-form-switch>
            <div class="dropdown" dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
              <button type="button" class="btn btn-secondary-warning dropdown-toggle" dropdownToggle>
                <span class="cs-icon cs-icon-bolt"></span> Actions
              </button>
              <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                <li>
                  <button type="button" (click)="onImportBookings()">
                    <i class="fal fa-arrow-down"></i> Import Bookings
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="col-md-6">
              <div class="text-uppercase text-light mb-3">Channel Information</div>
              <div class="d-flex">
                <mat-form-field2 class="mat-block">
                  <input type="text" matInput placeholder="Rental" [value]="connection.rental?.name" disabled />
                </mat-form-field2>

                <a
                  href="javascript:;"
                  (click)="onRentalDetails(connection.rental)"
                  *ngIf="connection.rental"
                  class="ml-2 btn btn-light-primary align-input-row-end"
                >
                  <i class="cs-icon cs-icon-rental2"></i>
                </a>
              </div>
              <div class="d-flex align-items-center gap-5">
                <div class="d-flex gap-2">
                  <i class="cs-icon cs-icon-channels text-info text-xxl mt-1"></i>
                  <div>
                    <div class="text-light">Channel</div>
                    <div>{{ channel?.friendlyName }}</div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <i class="cs-icon cs-icon-grid-check text-warning text-xxl mt-1"></i>
                  <div>
                    <div class="text-light">Type</div>
                    <div>API</div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <i class="cs-icon cs-icon-calendar-plus text-indigo text-xxl mt-1"></i>
                  <div>
                    <div class="text-light">Date Linked</div>
                    <div>{{ connection?.created | epoch : 'DD-MMM-YYYY • hh:mm A' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 border-left">
              <div class="text-uppercase text-light mb-3">Room Information</div>
              <div class="d-grid gap-3" style="grid-template-columns: 1fr 1fr">
                <div class="d-flex gap-2">
                  <i class="cs-icon cs-icon-property text-primary text-xxl fa-fw mt-1"></i>
                  <div>
                    <div class="text-light">Property Name</div>
                    <div>{{ connection.propertyNameView }}</div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <i class="far fa-check-circle text-primary text-xxl fa-fw mt-1"></i>
                  <div>
                    <div class="text-light">Connection Status</div>
                    <div>{{ connection?.status ? 'Active' : 'Inactive' }}</div>
                  </div>
                </div>

                <div class="d-flex gap-2">
                  <i class="far fa-sync text-indigo text-xl fa-fw mt-1"></i>
                  <div>
                    <div class="text-light">Last Sync</div>
                    <div>{{ connection.lastsync | epoch }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-api-connection-status
        #connStatus
        class="d-block mb-3"
        [dateFormat]="dateFormat"
        [channel]="channel"
        [connection]="connection"
      ></app-api-connection-status>

      <app-api-connection-log
        class="d-block mb-3"
        [channel]="channel.name"
        [activityLogs]="logs$ | async"
        (refresh)="refreshLogs$.next(true)"
      ></app-api-connection-log>
    </div>
  </div>
</div>
