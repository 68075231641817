import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
import { isSomething } from '../functions/is-something'

export function selectSome<T, Props, K>(
  mapFn: (state: T, props: Props) => K,
  props?: Props
): (source$: Observable<T>) => Observable<K>

export function selectSome<T, Props, K>(
  pathOrMapFn: ((state: T, props?: Props) => any) | string,
  propsOrPath: Props | string,
  ...paths: string[]
) {
  return function selectOperator(source$: Observable<T>): Observable<K> {
    let mapped$: Observable<any>

    if (typeof pathOrMapFn === 'string') {
      const pathSlices = [<string>propsOrPath, ...paths].filter(Boolean)
      mapped$ = source$.pipe(pluck(pathOrMapFn, ...pathSlices))
    } else if (typeof pathOrMapFn === 'function') {
      mapped$ = source$.pipe(map((source) => pathOrMapFn(source, <Props>propsOrPath)))
    } else {
      throw new TypeError(
        `Unexpected type '${typeof pathOrMapFn}' in select operator,` + ` expected 'string' or 'function'`
      )
    }

    return mapped$.pipe(
      distinctUntilChanged(),
      filter((item) => isSomething(item))
    )
  }
}
