import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { PushRatesOverlayComponent } from '@tv3/containers/rates/rate-push-automations/push-rates-overlay/push-rates-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class PushRatesOverlayService {
  constructor(private dialogService: DialogService) {}

  public openSide(): MatDialogRef<PushRatesOverlayComponent, void> {
    return this.dialogService.openDrawer(PushRatesOverlayComponent)
  }
}
