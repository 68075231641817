export enum MessagingChannelType {
  Gmail = 'gmail',
  Hotmail = 'hotmail',
  Instagram = 'instagram',
  Yahoo = 'yahoo',
  Facebook = 'facebook',
  Slack = 'slack',
  Whatsapp = 'whatsapp',
  SMS = 'sms',
}

export const availableMessageChannels = [
  {
    id: MessagingChannelType.Facebook,
    label: 'Facebook',
    logo: '/assets/images/messaging/facebook.png',
    channel: 'facebook' as const,
    availableMessageFeatures: ['quote', 'template'],
    oauth: true,
    description:
      'Connect your Facebook Business account to manage guest inquiries with ease. This two-way sync integration brings every message from prospective and current guests directly into your inbox, helping property managers track conversations, respond quickly, and never miss an inquiry—ensuring a smooth guest experience across platforms.',
  },
  {
    id: MessagingChannelType.Instagram,
    label: 'Instagram',
    logo: '/assets/images/messaging/instagram.png',
    channel: 'instagram' as const,
    availableMessageFeatures: ['quote', 'template'],
    oauth: true,
    description:
      'Sync your Instagram Business account to handle rental inquiries from your inbox. Perfect for property managers showcasing listings on Instagram, this integration captures all guest messages centrally, allowing you to address questions, discuss bookings, and maintain a professional touch in every interaction.',
  },
  // {
  //   id: MessagingChannelType.Gmail,
  //   label: 'Gmail',
  //   logo: '/assets/images/messaging/gmail.png',
  //   channel: 'gmail' as const,
  //   oauth: true,
  //   description:
  //     'Integrate your Gmail account to streamline all guest communications in one place. This integration pulls Gmail messages into your inbox, simplifying booking management and ensuring a complete record of interactions, allowing you to stay organized and provide timely responses.',
  // },
  // {
  //   id: MessagingChannelType.Hotmail,
  //   label: 'Hotmail',
  //   logo: '/assets/images/messaging/hotmail.png',
  //   channel: 'hotmail' as const,
  //   oauth: true,
  //   description:
  //     'Connect your Hotmail account to centralize guest emails and manage bookings efficiently. With two-way sync, this integration ensures all communications are easily accessible in your inbox, enabling property managers to respond quickly and keep organized records—ideal for handling multiple properties.',
  // },
  // {
  //   id: MessagingChannelType.Slack,
  //   label: 'Slack',
  //   logo: '/assets/images/messaging/slack.png',
  //   channel: 'slack' as const,
  //   oauth: true,
  //   description:
  //     'Connect your Slack account for streamlined communication and enhanced security. Use Slack for easy authentication and team collaboration.',
  // },
  // {
  //   id: MessagingChannelType.Yahoo,
  //   label: 'Yahoo',
  //   logo: '/assets/images/messaging/yahoo.png',
  //   channel: 'yahoo' as const,
  //   oauth: true,
  //   description:
  //     'Link your Yahoo account to consolidate guest communication in one unified inbox. This two-way sync integration brings all guest emails together, making it simple to manage inquiries, track bookings, and provide a smooth, professional experience from first contact to final checkout.',
  // },
  {
    id: MessagingChannelType.Whatsapp,
    label: 'Whatsapp',
    logo: '/assets/images/messaging/whatsapp.svg',
    channel: 'whatsapp-twilio' as const,
    availableMessageFeatures: ['quote', 'template'],
    isSubscriptionRequired: true,
    description:
      'Integrate your WhatsApp account via our third party provider to facilitate fast, reliable guest communication. Ideal for real-time inquiries and booking requests, WhatsApp messages sync seamlessly with your inbox, letting property managers deliver responsive service without juggling platforms—essential for a professional rental operation.',
  },
  {
    id: MessagingChannelType.SMS,
    label: 'SMS',
    logo: '/assets/images/messaging/sms.svg',
    channel: 'sms' as const,
    availableMessageFeatures: ['quote', 'template'],
    isSubscriptionRequired: true,
    description:
      'Enable SMS integration for direct, time-sensitive updates to guests. With SMS messages in your inbox, property managers can send booking confirmations, check-in instructions, and reminders, ensuring guests stay informed and enhancing your reliability as a rental provider.',
  },
]

export type MessagingChannelKeys = (typeof availableMessageChannels)[number]['channel']

export type MessagingChannelsConfig = {
  [key in MessagingChannelKeys]?: { enabled: boolean }
}

export interface WhatsappMessageTemplate {
  name: string //  'checkout_reminder',
  variables: string[] // ["*|GUEST:NAME|*", "*|RENTAL:NAME|*"]
  language: string //  'en',
  content: string //  'Good morning {{1}}, we hope you had a wonderful stay at {{2}}! \n\nThis is a quick reminder that checkout is at {{3}} on {{4}}. Let us know if you need any assistance with checkout procedures.',
  sid: string //  'HXe5908d285233a2df7a146ebf72eb5a44'
}

export function isInstagramEmail(email: string) {
  return email.endsWith('@i.g')
}

export function isFacebookEmail(email: string) {
  return email.endsWith('@f.b')
}
