import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { selectAllRentals, Channel, isSomething, Rental } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { AddImportedCalendarConnection } from '@tv3/store/connection/connection.actions'
import { set } from 'lodash'
import { Observable, asyncScheduler } from 'rxjs'
import { observeOn } from 'rxjs/operators'
import { selectAllAvailableCustomICalChannelName } from '@tv3/store/connection/connection.selectors'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Component({
  selector: 'app-import-calendar-dialog',
  templateUrl: './import-calendar-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-calendar-dialog.component.scss'],
})
export class ImportCalendarDialogComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required]],
    channel: [''],
    rental: [undefined, [Validators.required]],
    calendar: ['', [Validators.required, Validators.pattern(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/)]],
  })

  availableChannelNames$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllAvailableCustomICalChannelName))
  rentals$: Observable<Rental[]>

  constructor(
    public dialogRef: MatDialogRef<ImportCalendarDialogComponent>,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService,
    @Inject(MAT_DIALOG_DATA) public data: { isChannelNameRequired?: boolean; channel?: Channel; rental?: Rental }
  ) {}

  ngOnInit(): void {
    if (!this.data?.rental) {
      this.rentals$ = this.store.pipe(select(selectAllRentals))
    } else {
      this.form.patchValue({ rental: this.data.rental })
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  create(form: FormGroup) {
    const { rental, name, calendar, channel = '' } = form.getRawValue()
    const payload = {
      rentalid: rental.id,
      sendto: rental.email,
      name,
      calendar,
      book: true,
    }
    if (channel) {
      set(payload, 'attributes.channel_display_name', channel.trim())
    }

    if (isSomething(this.data?.channel)) {
      const channelName = this.data.channel.name?.toLowerCase()
      this.amplitudeService.logEvent(`add-ical-${channelName}`)
    }

    this.store.dispatch(AddImportedCalendarConnection({ payload }))
    this.close()
  }
}
