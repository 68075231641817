import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './integration.state'
import { ThirdPartyIntegrationIdentifies } from './integration.model'
import { isSomething } from '@tokeet-frontend/tv3-platform'

export const integrationState = createFeatureSelector<state.State>(state.STORE_ID)

export const selectAuthorizeIntegrationsLoaded = createSelector(
  integrationState,
  (s: state.State) => s.isAuthorizedIntegrationLoaded
)

export const selectAuthorizedIntegrationLoaded = (integration: ThirdPartyIntegrationIdentifies) => {
  return createSelector(
    selectAuthorizeIntegrationsLoaded,
    (isAuthorizedIntegrationLoaded) => isAuthorizedIntegrationLoaded[integration]
  )
}

export const isIntegrationAuthorized = (integration: ThirdPartyIntegrationIdentifies) => {
  return createSelector(
    integrationState,
    selectAuthorizedIntegrationLoaded(integration),
    (s: state.State, isLoaded: boolean) => {
      return isLoaded && !!isSomething(s.authorizedIntegrations[integration])
    }
  )
}

export const selectAllAuthorizedIntegrations = createSelector(
  integrationState,
  (s: state.State) => s.authorizedIntegrations
)

export const selectAuthorizedIntegration = (integration: ThirdPartyIntegrationIdentifies) => {
  return createSelector(integrationState, (s: state.State) => s.authorizedIntegrations[integration])
}
