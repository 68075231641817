import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { EntityTagsResponse } from './model'

export interface State extends EntityState<EntityTagsResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<EntityTagsResponse> = createEntityAdapter<EntityTagsResponse>({
  selectId: (item) => item.pkey,
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
