import { get } from 'lodash'

export function filterPredicateFactory(searchableKeys?: string[]) {
  searchableKeys = searchableKeys || []
  return (data, term) => {
    const keys = searchableKeys.length ? [...searchableKeys] : Object.keys(data)
    let dataStr = keys.reduce((acc, key) => {
      let value = data[key]
      if (key && key.indexOf('.') !== -1) {
        value = get(data, key)
      }
      return `${acc}◬${value || ''}`
    }, '')

    term = normalizeString(term).toLowerCase()
    dataStr = normalizeString(dataStr).toLowerCase()

    return dataStr.indexOf(term) !== -1
  }
}

function normalizeString(value: string) {
  return (value || '')
    .toString()
    .replace(/\s{2,}/g, ' ')
    .trim()
}
