import * as R from 'ramda'

export function getExistingIds(items: { id: string }[], ids: string[]) {
  const itemIds = R.pipe(R.defaultTo([]), R.reject(R.isNil), R.pluck('id'))(items)

  return R.pipe(
    R.defaultTo([]),
    R.filter((id) => R.contains(id, itemIds))
  )(ids)
}
