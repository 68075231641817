import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  AlertDialogService,
  asUTCEpoch,
  ConfirmDialogService,
  ConfirmDialogStatus,
  isSomething,
  Rental,
  selectOnce,
  selectRentalById,
  TaxV3,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { BookingHelperService } from '@tv3/services/booking-helper.service'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { LoadCalendarEventsByRental } from '@tv3/store/calendar/calendar.actions'
import { selectAllCalendarEventsForRental } from '@tv3/store/calendar/calendar.selectors'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { GetGuestComplete } from '@tv3/store/guest/guest.actions'
import { BookingService } from '@tv3/store/inquiry/booking.service'
import {
  AddInquiryCard,
  AddInquiryCardComplete,
  AddTagsToInquiries,
  AddTagsToInquiriesComplete,
  AddTagsToInquiry,
  ArchiveInquiries,
  ArchiveInquiriesComplete,
  ArchiveInquiry,
  ArchiveInquiryComplete,
  CancelBooking,
  CancelBookingComplete,
  CancelBookingWithReason,
  ChangeBookingEngineCost,
  ChangeBookingEngineCostComplete,
  ChangeCheckIn,
  ChangeCheckInComplete,
  ChangeCheckOut,
  ChangeCheckOutComplete,
  ChangeInquiryAdults,
  ChangeInquiryAdultsComplete,
  ChangeInquiryArrive,
  ChangeInquiryArriveComplete,
  ChangeInquiryChannel,
  ChangeInquiryChannelComplete,
  ChangeInquiryChildren,
  ChangeInquiryChildrenComplete,
  ChangeInquiryDepart,
  ChangeInquiryDepartComplete,
  ChangeInquiryGuest,
  ChangeInquiryGuestComplete,
  ChangeInquiryRental,
  ChangeInquiryRentalComplete,
  ConfirmBooking,
  ConfirmBookingComplete,
  ConfirmImportedBooking,
  DeleteInquiryCard,
  DeleteInquiryCardComplete,
  FollowupInquiries,
  FollowupInquiriesComplete,
  FollowupInquiry,
  FollowupInquiryComplete,
  InquiryIsUpdated,
  InquiryUpdateComplete,
  LinkInquiries,
  LinkInquiriesComplete,
  MarkPaidInquiries,
  MarkPaidInquiriesComplete,
  MarkPaidInquiry,
  MarkPaidInquiryComplete,
  MarkUnpaidInquiries,
  MarkUnpaidInquiriesComplete,
  MarkUnpaidInquiry,
  MarkUnpaidInquiryComplete,
  NoFollowupInquiries,
  NoFollowupInquiriesComplete,
  NoFollowupInquiry,
  NoFollowupInquiryComplete,
  ReadInquiries,
  ReadInquiriesComplete,
  ReadInquiry,
  ReadInquiryComplete,
  SetAdditionalGuests,
  SetInquiryColor,
  SetInquiryColorComplete,
  SetGuestPortalAccessible,
  SetGuestPortalAccessibleComplete,
  UnArchiveInquiries,
  UnArchiveInquiriesComplete,
  UnArchiveInquiry,
  UnArchiveInquiryComplete,
  UnLinkInquiry,
  UnLinkInquiryComplete,
  UnReadInquiries,
  UnReadInquiriesComplete,
  UnReadInquiry,
  UnReadInquiryComplete,
  UpdateCheckinStatus,
  UpdateCheckinStatusComplete,
  UpdateCheckoutStatus,
  UpdateCheckoutStatusComplete,
  ChangeBookingDetails,
  ChangeBookingDetailsPayload,
  ChangeBookingDetailsComplete,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { WebsiteService } from '@tv3/store/website/website.service'

import * as fromRoot from '@tv3/store/state'
import { ActionFailed, ActionSkipped } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { of } from 'rxjs'
import { catchError, concatMap, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { DEFAULT_BOOKING_ARRIVAL_TIME_PADDING, DEFAULT_BOOKING_DEPARTURE_TIME_PADDING } from '@tokeet-frontend/bookings'

@Injectable()
export class InquiryFieldsEffects {
  @Effect()
  setAdditionalGuests$ = this.actions$.pipe(
    ofType(SetAdditionalGuests),
    switchMap(({ guestIds, inquiry }) => {
      const attributes = R.merge(inquiry.attributes, { additionalGuests: guestIds })

      return this.inquiries.updateInquiry(inquiry.id, { attributes }).pipe(
        map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
        switchMap((update) => [InquiryUpdateComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  addTagsToInquiry$ = this.actions$.pipe(
    ofType(AddTagsToInquiry),
    switchMap(({ inquiry, tags }) =>
      this.inquiries.addTagsToInquiry(inquiry, tags).pipe(
        switchMap((inquiry) => [
          InquiryUpdateComplete({ update: { id: inquiry.id, changes: inquiry } }),
          InquiryIsUpdated({ update: { id: inquiry.id, changes: inquiry } }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markPaidInquiries$ = this.actions$.pipe(
    ofType(MarkPaidInquiries),
    switchMap(({ inquiries }) =>
      this.inquiries.markPaidStatus(inquiries, 'paid').pipe(
        map((inquiries) => R.map((i: Inquiry) => ({ id: i.id, changes: i }), inquiries)),
        map((updates) => MarkPaidInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markUnpaidInquiries$ = this.actions$.pipe(
    ofType(MarkUnpaidInquiries),
    switchMap(({ inquiries }) =>
      this.inquiries.markPaidStatus(inquiries, 'unpaid').pipe(
        map((inquiries) => R.map((i: Inquiry) => ({ id: i.id, changes: i }), inquiries)),
        map((updates) => MarkUnpaidInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markPaidInquiry$ = this.actions$.pipe(
    ofType(MarkPaidInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.markPaid(inquiry).pipe(
        switchMap((inquiry) => [
          MarkPaidInquiryComplete({ update: { id: inquiry.id, changes: inquiry } }),
          InquiryIsUpdated({ update: { id: inquiry.id, changes: inquiry } }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markUnpaidInquiry$ = this.actions$.pipe(
    ofType(MarkUnpaidInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.markUnpaid(inquiry).pipe(
        switchMap((inquiry) => [
          MarkUnpaidInquiryComplete({ update: { id: inquiry.id, changes: inquiry } }),
          InquiryIsUpdated({ update: { id: inquiry.id, changes: inquiry } }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addTagsToInquiries$ = this.actions$.pipe(
    ofType(AddTagsToInquiries),
    switchMap(({ inquiries, tags }) =>
      this.inquiries.addTagsToInquiries(inquiries, tags).pipe(
        map((inquiries) => R.map((i: Inquiry) => ({ id: i.id, changes: i }), inquiries || [])),
        map((updates) => AddTagsToInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  confirmBooking$ = this.actions$.pipe(
    ofType(ConfirmBooking),
    concatMap(({ inquiry }) =>
      this.store.pipe(
        selectOnce(selectAllCalendarEventsForRental(inquiry.rentalId)),
        map((events) => ({ inquiry, events }))
      )
    ),
    concatMap((payload) => {
      if (this.bookings.invalidInquiry(payload.inquiry)) {
        this.alertDialog.open({
          title: 'Something missing',
          body: 'Please check your inquiry details. Rental, adult count and booking dates are required',
        })
        return of(null)
      }
      if (
        this.calendarService.checkOverlapping(
          { start: payload.inquiry.guestArrive, end: payload.inquiry.guestDepart },
          payload.events
        )
      ) {
        this.toaster.error('Overlapping with other events.')
        return of(null)
      }
      return of(payload.inquiry)
    }),
    concatMap((inquiry) => {
      if (inquiry) {
        return this.bookings.confirm(inquiry).pipe(
          concatMap((response) => {
            return [
              ConfirmBookingComplete({ update: { id: response.inquiry.id, changes: response.inquiry } }),
              GetGuestComplete({ guest: response.guest }),
              InquiryIsUpdated({ update: { id: response.inquiry.id, changes: response.inquiry } }),
            ]
          }),
          catchError((error) => of(ActionFailed({ error })))
        )
      } else {
        return of(ActionSkipped())
      }
    })
  )

  @Effect()
  confirmImportedBooking$ = this.actions$.pipe(
    ofType(ConfirmImportedBooking),
    mergeMap(({ inquiry }) =>
      this.store.pipe(
        selectOnce(selectAllCalendarEventsForRental(inquiry.rentalId)),
        map((events) => ({ inquiry, events }))
      )
    ),
    mergeMap((payload) => {
      if (this.bookings.invalidInquiry(payload.inquiry)) {
        this.alertDialog.open({
          title: 'Something missing',
          body: 'Please check your inquiry details. Rental, adult count and booking dates are required',
        })
        return of(null)
      }
      if (
        this.calendarService.checkOverlapping(
          { start: payload.inquiry.guestArrive, end: payload.inquiry.guestDepart },
          payload.events
        )
      ) {
        this.toaster.error('Overlapping with other events.')
        return of(null)
      }
      return of(payload.inquiry)
    }),
    mergeMap((inquiry) => {
      if (inquiry) {
        return this.bookings.confirmImported(inquiry).pipe(
          mergeMap((inquiry) => [
            ConfirmBookingComplete({ update: { id: inquiry.id, changes: inquiry } }),
            InquiryIsUpdated({ update: { id: inquiry.id, changes: inquiry } }),
          ]),
          catchError((error) => of(ActionFailed({ error })))
        )
      } else {
        return of(ActionSkipped())
      }
    })
  )

  @Effect()
  cancelBooking$ = this.actions$.pipe(
    ofType(CancelBooking),
    concatMap(({ inquiry, byGuest }) =>
      this.bookings.cancel(inquiry, byGuest).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [CancelBookingComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  cancelBookingWithReason$ = this.actions$.pipe(
    ofType(CancelBookingWithReason),
    concatMap(({ inquiry, reason }) =>
      this.bookings.cancelWithReason(inquiry, reason).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [CancelBookingComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  linkInquiries$ = this.actions$.pipe(
    ofType(LinkInquiries),
    switchMap(({ ids }) =>
      this.inquiries.link(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { links: ids } }), ids)),
        map((updates) => LinkInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  followupInquiries$ = this.actions$.pipe(
    ofType(FollowupInquiries),
    switchMap(({ ids }) =>
      this.inquiries.followup(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { followup: 1 } }), ids)),
        map((updates) => FollowupInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  followupInquiry$ = this.actions$.pipe(
    ofType(FollowupInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.followUpInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [FollowupInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changeCheckIn$ = this.actions$.pipe(
    ofType(ChangeCheckIn),
    switchMap(({ inquiry, checkIn }) =>
      this.inquiries.updateCheckIn(inquiry.id, checkIn).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [ChangeCheckInComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changeCheckOut$ = this.actions$.pipe(
    ofType(ChangeCheckOut),
    switchMap(({ inquiry, checkOut }) =>
      this.inquiries.updateCheckOut(inquiry.id, checkOut).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [ChangeCheckOutComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  readInquiry$ = this.actions$.pipe(
    ofType(ReadInquiry),
    switchMap(({ inquiry, silent }) =>
      this.inquiries.readInquiry(inquiry, !!silent).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [ReadInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInquiryCard$ = this.actions$.pipe(
    ofType(DeleteInquiryCard),
    switchMap(({ inquiry }) =>
      this.inquiries.deleteCard(inquiry).pipe(
        map(() => ({ id: inquiry.id, changes: { billing: null } })),
        switchMap((update) => [DeleteInquiryCardComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unLinkInquiry$ = this.actions$.pipe(
    ofType(UnLinkInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.unLinkInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: { links: [] } })),
        switchMap((update) => [UnLinkInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveInquiry$ = this.actions$.pipe(
    ofType(ArchiveInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.archiveInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [ArchiveInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unArchiveInquiry$ = this.actions$.pipe(
    ofType(UnArchiveInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.unArchiveInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [UnArchiveInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unReadInquiry$ = this.actions$.pipe(
    ofType(UnReadInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.unReadInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [UnReadInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  noFollowupInquiry$ = this.actions$.pipe(
    ofType(NoFollowupInquiry),
    switchMap(({ inquiry }) =>
      this.inquiries.noFollowUpInquiry(inquiry).pipe(
        map((inq) => ({ id: inq.id, changes: inq })),
        switchMap((update) => [NoFollowupInquiryComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  readInquiries$ = this.actions$.pipe(
    ofType(ReadInquiries),
    switchMap(({ ids }) =>
      this.inquiries.read(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { read: 1 } }), ids)),
        map((updates) => ReadInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unreadInquiries$ = this.actions$.pipe(
    ofType(UnReadInquiries),
    switchMap(({ ids }) =>
      this.inquiries.unread(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { read: 0 } }), ids)),
        map((updates) => UnReadInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  nofollowupInquiries$ = this.actions$.pipe(
    ofType(NoFollowupInquiries),
    switchMap(({ ids }) =>
      this.inquiries.nofollowup(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { followup: 0 } }), ids)),
        map((updates) => NoFollowupInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveInquiries$ = this.actions$.pipe(
    ofType(ArchiveInquiries),
    switchMap(({ ids }) =>
      this.inquiries.archive(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { archived: 1 } }), ids)),
        map((updates) => ArchiveInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unarchiveInquiries$ = this.actions$.pipe(
    ofType(UnArchiveInquiries),
    switchMap(({ ids }) =>
      this.inquiries.unarchive(ids).pipe(
        map((ids) => R.map((id) => ({ id, changes: { archived: 0 } }), ids)),
        map((updates) => UnArchiveInquiriesComplete({ updates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changeAdults$ = this.actions$.pipe(
    ofType(ChangeInquiryAdults),
    concatMap(({ inquiry, adults }) => {
      return this.confirmBookingChargeChange(inquiry).pipe(
        concatMap((status) => {
          const updatedInquiry: Inquiry = <Inquiry>{ ...inquiry, numAdults: adults }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
          } else {
            return of({ inquiry: inquiry, updateEngine: false })
          }
        }),
        concatMap(({ inquiry, updateEngine }) =>
          this.inquiries.changeAdults({ inquiry, adults, updateEngine }).pipe(
            map((updatedInquiry) => ({ id: updatedInquiry.id, changes: updatedInquiry })),
            switchMap((update) => [ChangeInquiryAdultsComplete({ update }), InquiryIsUpdated({ update })]),
            catchError((error) => of(ActionFailed({ error })))
          )
        )
      )
    })
  )

  @Effect()
  changeChildren$ = this.actions$.pipe(
    ofType(ChangeInquiryChildren),
    concatMap(({ inquiry, children }) => {
      return this.confirmBookingChargeChange(inquiry).pipe(
        concatMap((status) => {
          const updatedInquiry: Inquiry = <Inquiry>{ ...inquiry, numChild: children }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
          } else {
            return of({ inquiry, updateEngine: false })
          }
        }),
        concatMap(({ inquiry, updateEngine }) =>
          this.inquiries.changeChildren({ inquiry, children, updateEngine }).pipe(
            map((updatedInquiry) => ({ id: inquiry.id, changes: updatedInquiry })),
            switchMap((update) => [ChangeInquiryChildrenComplete({ update }), InquiryIsUpdated({ update })]),
            catchError((error) => of(ActionFailed({ error })))
          )
        )
      )
    })
  )

  @Effect()
  changeChannel$ = this.actions$.pipe(
    ofType(ChangeInquiryChannel),
    switchMap(({ inquiry, channel }) => {
      return this.confirmBookingChargeChange(inquiry).pipe(
        concatMap((status) => {
          const updatedInquiry: Inquiry = <Inquiry>{ ...inquiry, inquirySource: channel }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
          } else {
            return of({ inquiry, updateEngine: false })
          }
        }),
        switchMap(({ inquiry, updateEngine }) =>
          this.inquiries.changeChannel({ inquiry, channel, updateEngine }).pipe(
            map((res: Inquiry) => ({ id: res.id, changes: res })),
            switchMap((update) => [ChangeInquiryChannelComplete({ update }), InquiryIsUpdated({ update })]),
            catchError((error) => of(ActionFailed({ error })))
          )
        )
      )
    })
  )

  @Effect()
  changeRental$ = this.actions$.pipe(
    ofType(ChangeInquiryRental),
    switchMap(({ inquiry, rentalId }) => {
      // check overlapping for bookings
      if (!inquiry.booked) {
        // is booking
        return of({ inquiry, rentalId })
      }

      return this.store.pipe(
        selectOnce(selectAllCalendarEventsForRental(rentalId)),
        map((events) => {
          if (
            this.calendarService.checkOverlapping(
              {
                start: inquiry.guestArrive,
                end: inquiry.guestDepart,
              },
              events
            )
          ) {
            this.toaster.error('Overlapping with other events.')
            return null
          }
          return { inquiry, rentalId }
        })
      )
    }),
    concatMap((payload) => {
      if (!payload) {
        return of(ActionSkipped())
      }

      return this.confirmBookingChargeChange(payload.inquiry).pipe(
        concatMap((status) =>
          this.store.pipe(
            select(selectRentalById(payload.rentalId)),
            take(1),
            map((rental) => ({ rental, status }))
          )
        ),
        concatMap(({ rental, status }: { rental: Rental; status: ConfirmDialogStatus }) => {
          const taxEx = {
            percent: R.pipe(
              R.filter((t: TaxV3) => t.type === 'percent'),
              <any>R.map((t: TaxV3) => (t.amount > 0 ? t.amount : 0)),
              R.sum
            )(<any>R.defaultTo([], rental.taxes)),
            flat: R.pipe(
              R.filter((t: TaxV3) => t.type === 'flat'),
              <any>R.map((t: TaxV3) => (t.amount > 0 ? t.amount : 0)),
              R.sum
            )(<any>R.defaultTo([], rental.taxes)),
          }

          const updatedInquiry: Inquiry = <Inquiry>{
            ...payload.inquiry,
            rentalId: rental.id,
            charges: { ...payload.inquiry.charges, taxEx: taxEx },
          }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ rental, inquiry, updateEngine: true })))
          } else {
            return of({ rental, inquiry: payload.inquiry, updateEngine: false })
          }
        }),
        concatMap((request) =>
          this.inquiries
            .changeRental({
              inquiry: request.inquiry,
              rentalId: request.rental.id,
              updateEngine: request.updateEngine,
            })
            .pipe(
              map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
              switchMap((update) => [ChangeInquiryRentalComplete({ update }), InquiryIsUpdated({ update })]),
              catchError((error) => of(ActionFailed({ error })))
            )
        )
      )
    })
  )

  @Effect()
  changeGuest$ = this.actions$.pipe(
    ofType(ChangeInquiryGuest),
    switchMap(({ inquiry, guest }) =>
      this.inquiries.changeGuest({ inquiry, guest }).pipe(
        map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
        switchMap((update) => [ChangeInquiryGuestComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  changeArrive$ = this.actions$.pipe(
    ofType(ChangeInquiryArrive),
    map(({ inquiry, arrive }) => ({
      inquiry,
      arrive: asUTCEpoch(this.inquiries.adjustInquiryDate(arrive, DEFAULT_BOOKING_ARRIVAL_TIME_PADDING)),
    })),
    concatMap((payload) =>
      this.checkBookingDateOverlap(payload.inquiry, payload.inquiry.rentalId, { arrive: payload.arrive }, payload)
    ),
    concatMap((payload) => {
      if (!payload) return of(ActionSkipped())
      return this.confirmBookingChargeChange(payload.inquiry).pipe(
        concatMap((status) => {
          const updatedInquiry: Inquiry = <Inquiry>{ ...payload.inquiry, guestArrive: payload.arrive }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
          } else {
            return of({ inquiry: payload.inquiry, updateEngine: false })
          }
        }),
        concatMap(({ inquiry, updateEngine }) => {
          const change$ = this.inquiries
            .changeArrive({
              inquiry: inquiry,
              arrive: payload.arrive,
              updateEngine: updateEngine,
            })
            .pipe(
              map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
              switchMap((update) => [ChangeInquiryArriveComplete({ update }), InquiryIsUpdated({ update })]),
              catchError((error) => of(ActionFailed({ error })))
            )
          return this.bookingHelperService
            .checkBookingDates(inquiry.rentalId, payload.arrive, inquiry.guestDepart, change$)
            .pipe(catchError((error) => of(ActionFailed({ error }))))
        })
      )
    })
  )

  @Effect()
  changeDepart$ = this.actions$.pipe(
    ofType(ChangeInquiryDepart),
    map(({ inquiry, depart }) => ({
      inquiry,
      depart: asUTCEpoch(this.inquiries.adjustInquiryDate(depart, DEFAULT_BOOKING_DEPARTURE_TIME_PADDING)),
    })),
    concatMap((payload) =>
      this.checkBookingDateOverlap(payload.inquiry, payload.inquiry.rentalId, { depart: payload.depart }, payload)
    ),
    concatMap((payload) => {
      if (!payload) return of(ActionSkipped())
      return this.confirmBookingChargeChange(payload.inquiry).pipe(
        concatMap((status) => {
          const updatedInquiry: Inquiry = <Inquiry>{ ...payload.inquiry, guestDepart: payload.depart }

          if (status === ConfirmDialogStatus.Confirmed) {
            return this.inquiryChargeService
              .calculateRentalCharge(updatedInquiry, true)
              .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
          } else {
            return of({ inquiry: payload.inquiry, updateEngine: false })
          }
        }),
        concatMap(({ inquiry, updateEngine }) => {
          const change$ = this.inquiries
            .changeDepart({
              inquiry: inquiry,
              depart: payload.depart,
              updateEngine: updateEngine,
            })
            .pipe(
              map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
              switchMap((update) => [ChangeInquiryDepartComplete({ update }), InquiryIsUpdated({ update })]),
              catchError((error) => of(ActionFailed({ error })))
            )

          return this.bookingHelperService
            .checkBookingDates(inquiry.rentalId, inquiry.guestArrive, payload.depart, change$)
            .pipe(catchError((error) => of(ActionFailed({ error }))))
        })
      )
    })
  )

  @Effect()
  changeBookingDetails$ = this.actions$.pipe(
    ofType(ChangeBookingDetails),
    concatMap(({ inquiry, payload, isChargeAffected }) =>
      this.checkBookingDateOverlap(
        inquiry,
        payload.rental_id || inquiry.rentalId,
        { depart: payload.guest_depart, arrive: payload.guest_arrive },
        { inquiry, payload, isChargeAffected }
      )
    ),
    concatMap((data) => {
      if (!data) return of(ActionSkipped())
      const { inquiry, payload, isChargeAffected } = data
      const confirmChangeCharge = () =>
        this.confirmBookingChargeChange(inquiry).pipe(
          switchMap((status) => {
            const updatedInquiry: Inquiry = <Inquiry>{ ...inquiry, guestDepart: payload.guest_depart }

            if (status === ConfirmDialogStatus.Confirmed) {
              return this.inquiryChargeService
                .calculateRentalCharge(updatedInquiry, true)
                .pipe(map((inquiry) => ({ inquiry, updateEngine: true })))
            } else {
              return of({ inquiry: inquiry, updateEngine: false })
            }
          })
        )

      return (isChargeAffected ? confirmChangeCharge() : of({ inquiry, updateEngine: false })).pipe(
        switchMap(({ inquiry, updateEngine }) => {
          const change$ = this.inquiries.changeBookingDetails(inquiry, payload, updateEngine).pipe(
            map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
            switchMap((update) => [ChangeBookingDetailsComplete({ update }), InquiryIsUpdated({ update })]),
            catchError((error) => of(ActionFailed({ error })))
          )

          return this.bookingHelperService
            .checkBookingDates(payload.rental_id, payload.guest_arrive, payload.guest_depart, change$)
            .pipe(catchError((error) => of(ActionFailed({ error }))))
        })
      )
    })
  )

  @Effect()
  changeBookingEngine$ = this.actions$.pipe(
    ofType(ChangeBookingEngineCost),
    switchMap(({ inquiry, bookingEngineCost, isUserCharge }) =>
      this.inquiries.changeBookingEngineCost({ inquiry, bookingEngineCost, isUserCharge }).pipe(
        map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
        tap(() => this.toaster.success('Charges updated successfully.')),
        switchMap((update) => [ChangeBookingEngineCostComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addCard$ = this.actions$.pipe(
    ofType(AddInquiryCard),
    map(({ inquiry, form }) => {
      return {
        request: {
          first_name: form.firstName,
          last_name: form.lastName,
          full_number: form.cardNumber,
          expiration_month: form.month,
          expiration_year: form.year,
          zip: form.zip,
          cvc: form.cvc,
          terms: form.terms,
          currency: R.pathOr('USD', ['currency', 'code'], inquiry.rental),
        },
        inquiry,
      }
    }),
    switchMap((payload) =>
      this.inquiries.addCard(payload.inquiry, payload.request).pipe(
        map((billing) => ({ id: payload.inquiry.id, changes: { billing } })),
        switchMap((update) => [AddInquiryCardComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  /**
   *
   * Once features are created, update flights, payments and other stuff on inquiry details
   *
   */
  @Effect()
  refreshCalendarByRental$ = this.actions$.pipe(
    ofType(
      ChangeBookingEngineCostComplete,
      ChangeInquiryRentalComplete,
      ChangeInquiryChannelComplete,
      ChangeInquiryAdultsComplete,
      ChangeInquiryChildrenComplete
    ),
    map(({ update }) => {
      const rentalId: string = R.path(['changes', 'rentalId'], update)
      if (isSomething(rentalId)) {
        return LoadCalendarEventsByRental({ id: rentalId })
      }
    })
  )

  @Effect()
  setInquiryColor$ = this.actions$.pipe(
    ofType(SetInquiryColor),
    switchMap(({ inquiry, color }) => {
      const attributes = R.merge(inquiry.attributes, { color })

      return this.inquiries.updateInquiry(inquiry.id, { attributes }).pipe(
        map((inquiry) => ({ id: inquiry.id, changes: inquiry })),
        switchMap((update) => [SetInquiryColorComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateCheckinStatus$ = this.actions$.pipe(
    ofType(UpdateCheckinStatus),
    switchMap(({ inquiry, status }) => {
      const attributes = R.merge(inquiry.attributes || {}, { is_checked_in: status })

      return this.inquiries.updateInquiry(inquiry.id, { attributes }).pipe(
        map((res) => ({ id: inquiry.id, changes: res })),
        tap(() => this.toaster.success('Marked as checked in successfully.')),
        switchMap((update) => [UpdateCheckinStatusComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateCheckoutStatus$ = this.actions$.pipe(
    ofType(UpdateCheckoutStatus),
    switchMap(({ inquiry, status }) => {
      const attributes = R.merge(inquiry.attributes || {}, { is_checked_out: status })

      return this.inquiries.updateInquiry(inquiry.id, { attributes }).pipe(
        map((res) => ({ id: inquiry.id, changes: res })),
        tap(() => this.toaster.success('Marked as checked out successfully.')),
        switchMap((update) => [UpdateCheckoutStatusComplete({ update }), InquiryIsUpdated({ update })]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  guestPortalAccess$ = this.actions$.pipe(
    ofType(SetGuestPortalAccessible),
    switchMap(({ rentalId, ...others }) => this.websiteService.getGuestPortalForRental(rentalId).pipe(mapTo(others))),
    switchMap(({ inquiryId, disabled }) => {
      return this.inquiries.setGuestPortalAccessible(inquiryId, disabled).pipe(
        map((res) => ({ id: inquiryId, changes: res })),
        tap(() =>
          this.toaster.success(disabled ? 'Guest portal disabled successfully.' : 'Guest portal enabled successfully.')
        ),
        switchMap((update) => [SetGuestPortalAccessibleComplete({ update }), InquiryIsUpdated({ update })])
      )
    }),
    catchError((error) => of(ActionFailed({ error })))
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private alertDialog: AlertDialogService,
    private calendarService: CalendarService,
    private confirmDialog: ConfirmDialogService,
    private inquiryChargeService: InquiryChargeService,
    private bookingHelperService: BookingHelperService,
    private store: Store<fromRoot.State>,
    private inquiries: InquiryService,
    private bookings: BookingService,
    private websiteService: WebsiteService
  ) {}

  private confirmBookingChargeChange(inquiry: Inquiry) {
    if (!!inquiry.booked) {
      return this.confirmDialog.openRegular({
        title: 'Update the booking charge?',
        body:
          // tslint:disable-next-line: max-line-length
          'Your change may cause the cost of this booking to be updated. <br>Do you want to update the booking cost when this change is applied?',
      })
    } else {
      return of(ConfirmDialogStatus.Confirmed)
    }
  }

  private checkBookingDateOverlap<T>(
    inquiry: Inquiry,
    rentalId: string,
    dates: { arrive?: number; depart?: number },
    payload: T
  ) {
    return this.store.pipe(
      selectOnce(selectAllCalendarEventsForRental(rentalId)),
      map((events) => {
        events = events.filter((event) => (event.id || event.bookingId) !== inquiry.id)
        const start = dates.arrive || inquiry.guestArrive
        const end = dates.depart || inquiry.guestDepart

        if (this.calendarService.checkOverlapping({ start, end }, events)) {
          this.toaster.error('Overlapping with other events.')
          return null
        }
        return payload
      })
    )
  }
}
