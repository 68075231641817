import { createAction, props } from '@ngrx/store'
import { Template, TemplateAddRequest, TemplateUpdateRequest } from './template.model'
import { Update } from '@ngrx/entity'

export const LoadTemplates = createAction('[Template] Load Templates')
export const LoadTemplatesComplete = createAction(
  '[Template] Load Templates Complete',
  props<{ templates: Template[] }>()
)

export const AddTemplate = createAction('[Template] Add Template', props<{ request: TemplateAddRequest }>())
export const AddTemplateComplete = createAction('[Template] Add Template Complete', props<{ template: Template }>())

export const UpdateTemplate = createAction(
  '[Template] Update Template',
  props<{ id: string; request: TemplateUpdateRequest }>()
)
export const UpdateTemplateComplete = createAction(
  '[Template] Update Template Complete',
  props<{ update: Update<Template> }>()
)

export const AddCustomBrandingTemplateComplete = createAction(
  '[Template] Add Custom Branding Template Complete',
  props<{ template: Template }>()
)

export const UpdateCustomBrandingTemplate = createAction(
  '[Template] Update Custom Branding Template',
  props<{ id: string; request: any }>()
)
export const UpdateCustomBrandingTemplateComplete = createAction(
  '[Template] Update Custom Branding Template Complete',
  props<{ update: Update<Template> }>()
)

export const DeleteTemplate = createAction('[Template] Delete Template', props<{ id: string }>())
export const DeleteTemplateComplete = createAction('[Template] Delete Template Complete', props<{ id: string }>())

export const DeleteTemplates = createAction('[Template] Delete Templates', props<{ ids: string[] }>())
export const DeleteTemplatesComplete = createAction('[Template] Delete Templates Complete', props<{ ids: string[] }>())

export const ClearLastAddedTemplate = createAction('[Templates] Clear Last Added Template')
