import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromGuest from './guest.reducer'
import { EffectsModule } from '@ngrx/effects'
import { GuestEffects } from './guest.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('guest', fromGuest.guestReducer),
    EffectsModule.forFeature([GuestEffects]),
  ],
  declarations: [],
})
export class GuestModule {}
