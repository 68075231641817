import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'

@Component({
  selector: 'mat-paginator2',
  templateUrl: './MatPaginator2.component.html',
  styleUrls: ['./MatPaginator2.component.scss'],
  inputs: ['disabled'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatPaginator2 extends MatPaginator {
  @Input() pageBtns: number = 5
  get getPageItems(): number[] {
    const totalPages = this.getNumberOfPages()

    if (totalPages <= this.pageBtns) {
      return Array.from({ length: totalPages }, (_, i) => i)
    } else {
      let startIndex = Math.max(0, this.pageIndex - Math.floor(this.pageBtns / 2))
      const endIndex = Math.min(totalPages - 1, startIndex + this.pageBtns - 1)
      startIndex = endIndex - this.pageBtns + 1

      return Array.from({ length: this.pageBtns }, (_, i) => i + startIndex)
    }
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 out of ${length}`
    }

    length = Math.max(length, 0)

    const startIndex = page * pageSize

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize

    return `${endIndex - startIndex} out of ${length}`
  }

  gotoPage(index: number): void {
    if (this.pageIndex === index) {
      return
    }

    const previousPageIndex = this.pageIndex
    this.pageIndex = index
    // @ts-ignore
    this._emitPageEvent(previousPageIndex)
  }
}
