import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { BDCContentPropertySummary } from '@tv3/store/channel-content/models/bdc.model'

@Component({
  selector: 'app-bdc-property-summary-dialog',
  templateUrl: './bdc-property-summary-dialog.component.html',
  styleUrls: ['./bdc-property-summary-dialog.component.scss'],
})
export class BdcPropertySummaryDialogComponent implements OnInit {
  summary: BDCContentPropertySummary
  isLoading = true
  constructor(
    public dialogRef: MatDialogRef<BdcPropertySummaryDialogComponent>,
    private bdcContentService: BdcContentService,
    @Inject(MAT_DIALOG_DATA) public data: { propertyId: number }
  ) {}

  ngOnInit(): void {
    this.bdcContentService.summary(this.data.propertyId).subscribe((sum) => {
      this.isLoading = false
      this.summary = sum
    })
  }

  getText(key: string) {
    if (!this.summary || !this.summary[key] || !this.summary[key].length) {
      return 'None'
    }
    const items = this.summary[key] || []
    return items
      .map((item, index) => {
        return `${index + 1}. ${item}`
      })
      .join('\n')
  }

  close() {
    this.dialogRef.close()
  }
}
