import { createAction, props } from '@ngrx/store'
import { ListingsValidationFieldResult } from '../rental-validation-field'
import { GVRStatus, GVRListingModel, GVRListingPayload } from './model'

export const GetGVRRentalValidations = createAction('[GVR] Get Rental Validations')
export const GetGVRRentalValidationsSuccess = createAction(
  '[GVR] Get Rental Validations Success',
  props<{
    validations: ListingsValidationFieldResult
  }>()
)

export const GetGVRStatus = createAction('[GVR] Get Status')
export const GetGVRStatusSuccess = createAction('[GVR] Get Status Success', props<{ status: GVRStatus }>())

export const ConnectGVR = createAction('[GVR] Connect')
export const ConnectGVRSuccess = createAction('[GVR] Connect Success')

export const DisconnectGVR = createAction('[GVR] Disconnect')
export const DisconnectGVRSuccess = createAction('[GVR] Disconnect Success')

export const GetGVRListings = createAction('[GVR] Get Listings')
export const GetGVRListingsSuccess = createAction(
  '[GVR] Get Listings Success',
  props<{ listings: GVRListingModel[] }>()
)

export const CreateGVRListing = createAction('[GVR] Create Listing', props<{ listing: GVRListingPayload }>())
export const CreateGVRListingSuccess = createAction(
  '[GVR] Create Listing Success',
  props<{ listing: GVRListingPayload }>()
)

export const UpdateGVRListing = createAction('[GVR] Update Listing', props<{ listing: GVRListingPayload }>())
export const UpdateGVRListingSuccess = createAction(
  '[GVR] Update Listing Success',
  props<{ listing: GVRListingPayload }>()
)

export const ActivateGVRListing = createAction('[GVR] Activate Listing', props<{ rentalId: string }>())
export const ActivateGVRListingSuccess = createAction(
  '[GVR] Activate Listing Success',
  props<{ rentalId: string; active: boolean }>()
)

export const DeactivateGVRListing = createAction('[GVR] Deactivate Listing', props<{ rentalId: string }>())
export const DeactivateGVRListingSuccess = createAction(
  '[GVR] Deactivate Listing Success',
  props<{ rentalId: string; active: boolean }>()
)

export const DeleteGVRListing = createAction('[GVR] Delete Listing', props<{ rentalId: string }>())
export const DeleteGVRListingSuccess = createAction('[GVR] Delete Listing Success', props<{ rentalId: string }>())
