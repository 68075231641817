import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import { AccountRatesDialogComponent, AccountRatesDialogInput } from './account-rates-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AccountRatesDialogService {
  constructor(private dialogService: DialogService) {}

  public openSide(data: AccountRatesDialogInput = {}): MatDialogRef<AccountRatesDialogComponent, void> {
    return this.dialogService.openDrawer(AccountRatesDialogComponent, {
      panelClass: ['drawer-full-80'],
      data: data,
    })
  }
}
