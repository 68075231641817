import { createAction, props } from '@ngrx/store'
import { ListingsValidationFieldResult } from '../rental-validation-field'
import { HvmiStatus, HvmiListingModel, HvmiListingViewModel, HvmiUpdateListingModel } from './model'

//region HVMI
export const GetHvmiRentalValidations = createAction('[HVMI] Get Rental Validations')
export const GetHvmiRentalValidationsSuccess = createAction(
  '[HVMI] Get Rental Validations Success',
  props<{
    validations: ListingsValidationFieldResult
  }>()
)
export const GetHvmiRentalValidationsFailure = createAction(
  '[HVMI] Get Rental Validations Failure',
  props<{ err: Error }>()
)

export const GetHvmiStatus = createAction('[HVMI] Get Status')
export const GetHvmiStatusSuccess = createAction('[HVMI] Get Status Success', props<{ status: HvmiStatus }>())
export const GetHvmiStatusFailure = createAction('[HVMI] Get Status Failure', props<{ err: Error }>())

export const ConnectHvmi = createAction('[HVMI] Connect', props<{ hmcId: number }>())
export const ConnectHvmiSuccess = createAction(
  '[HVMI] Connect Success',
  props<{ status: HvmiStatus; listings: HvmiListingModel[] }>()
)
export const ConnectHvmiFailure = createAction('[HVMI] Connect Failure', props<{ err: Error }>())

export const DisconnectHvmi = createAction('[HVMI] Disconnect', props<{ hmcId: number }>())
export const DisconnectHvmiSuccess = createAction('[HVMI] Disconnect Success', props<{ hmcId: number }>())
export const DisconnectHvmiFailure = createAction('[HVMI] Disconnect Failure', props<{ err: Error }>())

export const GetHvmiListings = createAction('[HVMI] Get Listings')
export const GetHvmiListingsSuccess = createAction(
  '[HVMI] Get Listings Success',
  props<{ listings: HvmiListingModel[] }>()
)
export const GetHvmiListingsFailure = createAction('[HVMI] Get Listings Failure', props<{ err: Error }>())

export const CreateHvmiListing = createAction('[HVMI] Create Listing', props<{ listing: HvmiListingViewModel }>())
export const CreateHvmiListingSuccess = createAction(
  '[HVMI] Create Listing Success',
  props<{ listing: HvmiListingModel }>()
)
export const CreateHvmiListingFailure = createAction(
  '[HVMI] Create Listing Failure',
  props<{ err: { error: { error: string[]; field: string }[] } }>()
)

export const ActivateHvmiListing = createAction('[HVMI] Activate Listing', props<{ propertyId: string }>())
export const ActivateHvmiListingSuccess = createAction(
  '[HVMI] Activate Listing Success',
  props<{ listing: HvmiListingModel }>()
)
export const ActivateHvmiListingFailure = createAction(
  '[HVMI] Activate Listing Failure',
  props<{ err: { error: { error: string[]; field: string }[] } }>()
)

export const DeactivateHvmiListing = createAction('[HVMI] Deactivate Listing', props<{ propertyId: string }>())
export const DeactivateHvmiListingSuccess = createAction(
  '[HVMI] Deactivate Listing Success',
  props<{ listing: HvmiListingModel }>()
)
export const DeactivateHvmiListingFailure = createAction(
  '[HVMI] Deactivate Listing Failure',
  props<{ err: { error: { error: string[]; field: string }[] } }>()
)

export const UpdateHvmiListing = createAction('[HVMI] Update Listing', props<{ listing: HvmiUpdateListingModel }>())
export const UpdateHvmiListingSuccess = createAction(
  '[HVMI] Update Listing Success',
  props<{ listing: HvmiListingModel }>()
)
export const UpdateHvmiListingFailure = createAction('[HVMI] Update Listing Failure', props<{ err: Error }>())

export const DeleteHvmiListing = createAction('[HVMI] Delete Listing', props<{ listingId: number }>())
export const DeleteHvmiListingSuccess = createAction('[HVMI] Delete Listing Success', props<{ listingId: number }>())
export const DeleteHvmiListingFailure = createAction('[HVMI] Delete Listing Failure', props<{ err: Error }>())
//endregion
