import { FeeModalities } from '@tokeet-frontend/rentals'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
export interface VrboListingFee {
  pkey: string
  modality: FeeModalities
  type: string
  amount: number
  name: string
}

function getFeeModality(fee: any): FeeModalities {
  if (fee.appliesPerGuestPerNight) {
    return FeeModalities.PerPersonPerNight
  } else if (fee.appliesPerNight) {
    return FeeModalities.PerNight
  } else if (fee.appliesPerGuestPerStay) {
    return FeeModalities.PerPerson
  }
  return FeeModalities.PerStay
}

export function normalizeVrboListingLodgingRate(data: any): VrboListingFee[] {
  const { lodgingRate } = data
  const { fees, taxRules, paymentSchedule } = lodgingRate
  const feeList: VrboListingFee[] = []
  if (!isSomething(fees)) {
    return feeList
  }

  const parseFee = (fee: any, title: string = 'Fee', isPercent = false) => {
    if (!isSomething(fee)) return []

    const fees = Array.isArray(fee) ? fee : [fee]
    return fees.map((item: any) => ({
      pkey: item.externalId,
      modality: getFeeModality(item),
      type: isPercent ? 'pct' : 'flat',
      amount: isPercent ? item.percent : item.amount,
      name: item.name || title,
    }))
  }

  Object.keys(fees).forEach((key) => {
    feeList.push(...parseFee(fees[key].fee, lodash.startCase(key), /percent/i.test(key)))
  })
  return feeList
}
