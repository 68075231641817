<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div *ngIf="isDuplicate" class="mx-3 mt-3">
  <alert type="warning">
    <strong>Warning:</strong> This expense is a duplicate. Please modify the expense details, before clicking
    <strong>Save</strong> to create the expense.
  </alert>
</div>

<div class="modal-body">
  <ul class="nav top-navbar border-bottom mb-3">
    <li [class.active]="activeTab === tabs.Expense">
      <a (click)="activeTab = tabs.Expense"> Details </a>
    </li>
    <li [class.active]="activeTab === tabs.Recurring">
      <a (click)="activeTab = tabs.Recurring"> Recurring </a>
    </li>
    <li [class.active]="activeTab === tabs.Attachments">
      <a (click)="activeTab = tabs.Attachments"> Attachments </a>
    </li>
    <ng-container *ngIf="isEdit">
      <li [class.active]="activeTab === tabs.Attributes">
        <a (click)="activeTab = tabs.Attributes"> Custom Attributes </a>
      </li>
      <li [class.active]="activeTab === tabs.Comments">
        <a (click)="activeTab = tabs.Comments"> Comments </a>
      </li>
      <li [class.active]="activeTab === tabs.Activity">
        <a (click)="activeTab = tabs.Activity"> Logs </a>
      </li>
    </ng-container>
  </ul>
  <ng-container [ngSwitch]="activeTab">
    <ng-template *ngSwitchCase="tabs.Expense" [ngTemplateOutlet]="detailsForm"></ng-template>
    <ng-template *ngSwitchCase="tabs.Recurring" [ngTemplateOutlet]="recurringForm"></ng-template>
    <ng-template *ngSwitchCase="tabs.Attachments" [ngTemplateOutlet]="attachmentsTab"></ng-template>
    <ng-template *ngSwitchCase="tabs.Comments" [ngTemplateOutlet]="comments"></ng-template>
    <ng-template *ngSwitchCase="tabs.Activity" [ngTemplateOutlet]="activity"></ng-template>
    <div *ngSwitchCase="tabs.Attributes">
      <app-entity-attributes
        #attrs
        entityType="expense"
        [entityId]="expenseId"
        [showActions]="false"
      ></app-entity-attributes>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button *ngIf="isEdit" (click)="onRemove(expense)" type="button" class="btn btn-secondary-danger">
    <i class="cs-icon cs-icon-trash"></i> Delete
  </button>
  <button (click)="close()" type="button" class="btn btn-light ml-auto">
    <span class="far fa-times"></span>
    Cancel
  </button>
  <ng-container *ngIf="activeTab === tabs.Attributes && attrs">
    <button (click)="attrs.onConfig()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-primary">
      <span class="cs-icon cs-icon-cog"></span>
      Attributes Configuration
    </button>
    <button
      (click)="onSaveAttrs()"
      [disabled]="(1 | isReadonlyRole) || attrs.isSaving"
      type="button"
      class="btn btn-secondary-info"
    >
      <i class="cs-icon cs-icon-save" *ngIf="!attrs.isSaving"></i>
      <i class="far fa-spin fa-spinner" *ngIf="attrs.isSaving"></i>
      Save
    </button>
  </ng-container>
  <button
    *ngIf="activeTab !== tabs.Attributes"
    (click)="save()"
    [disabled]="1 | isReadonlyRole"
    type="button"
    class="btn btn-secondary-info"
  >
    <span class="cs-icon cs-icon-save"></span>
    Save
  </button>
</div>

<ng-template #detailsForm>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="row">
      <div class="col-md-6">
        <app-select-search
          bindValue="id"
          bindLabel="name"
          [multiple]="false"
          [inline]="false"
          [matTooltip]="
            isEdit ? 'Change the rental associated with this expense.' : 'Choose a Rental to link this expense to.'
          "
          matTooltipPosition="left"
          [items]="rentals$ | async"
          [ctrl]="form.get('rental_id')"
          (valueChange)="form.get('inquiry_id').reset()"
          placeholder="Rental"
        >
        </app-select-search>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            [matTooltip]="
              isEdit
                ? 'Change the category or create a new one by deleting the existing data and typing into the field below.'
                : 'Choose a category or create a new one by typing into the field below.'
            "
            matTooltipPosition="left"
            placeholder="Category"
            matInput
            [formControl]="form.get('category')"
            [matAutocomplete]="auto"
            required
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let category of filteredCategories$ | async" [value]="category">
              {{ category }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('category').touched && form.get('category').hasError('required')">
            Category is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <app-booking-select
          [matTooltip]="
            isEdit
              ? 'Change the inquiry this expense is associated with.'
              : 'Associate this expense with an inquiry. This step is not required.'
          "
          matTooltipPosition="left"
          [ctrl]="form.get('inquiry_id')"
          (selected)="onSelectInquiry($event)"
          [rentalId]="form.get('rental_id').value"
        ></app-booking-select>
      </div>
      <div class="col-md-3">
        <mat-form-field2 class="mat-block" matTooltip="Select the expense date">
          <input
            matInput
            [matDatepicker]="expenseDate"
            [min]="minDateDefault"
            [max]="maxDateDefault"
            placeholder="Expense Date"
            [required]="true"
            (focus)="expenseDate.open()"
            formControlName="date"
            readonly
          />
          <i matSuffix (click)="expenseDate.open()" class="cs-icon cs-icon-calendar"></i>
          <mat-datepicker #expenseDate></mat-datepicker>
          <mat-error *ngIf="form.get('date').touched && form.get('date').hasError('required')">
            Expense Date is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-3">
        <mat-form-field2 class="mat-block" matTooltip="Select the due date for this expense">
          <input
            matInput
            [matDatepicker]="dueDatePicker"
            [max]="maxDateDefault"
            [min]="form.get('date').value || minDateDefault"
            placeholder="Due Date"
            (focus)="dueDatePicker.open()"
            formControlName="due"
            readonly
          />
          <i matSuffix (click)="dueDatePicker.open()" class="cs-icon cs-icon-calendar"></i>
          <mat-datepicker #dueDatePicker></mat-datepicker>
          <mat-error *ngIf="form.get('due').hasError('matDatepickerMin')">
            Min value is <strong>{{ form.get('due').getError('matDatepickerMin').min | epoch : 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block" matTooltip="Enter the amount of expense">
          <input matInput decimal required placeholder="Amount" formControlName="amount" type="number" />
          <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
            Amount is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-6">
        <mat-form-field2 class="mat-block" matTooltip="Select the payment method for this expense">
          <mat-select formControlName="method" placeholder="Payment Method">
            <mat-option *ngFor="let method of methodOptions" [value]="method.value">{{ method.name }}</mat-option>
          </mat-select>
        </mat-form-field2>
      </div>
      <ng-container *ngIf="isEdit">
        <ng-template *ngIf="isAdmin" [ngTemplateOutlet]="approveField"></ng-template>
        <ng-template
          *ngIf="!isAdmin && (approvable$ | async)?.amount >= form.get('amount').value"
          [ngTemplateOutlet]="approveField"
        ></ng-template>
      </ng-container>
      <ng-template #approveField>
        <div class="col-md-6">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="approval_status" placeholder="Approval Status">
              <mat-option *ngFor="let item of approvalStatuses" [value]="item.id">{{ item.label }}</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </ng-template>
      <div class="col-md-6 my-3">
        <mat-checkbox formControlName="status" class="mt-3" matTooltip="Mark the status of expense">
          Paid
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-form-field2 class="mat-block mat-txt-area" appearance="outline">
          <textarea
            matInput
            formControlName="description"
            #notes
            maxlength="500"
            rows="3"
            placeholder="Description"
            cdkTextareaAutosize
          ></textarea>
          <mat-hint align="end">{{ notes.value?.length || 0 }}/500</mat-hint>
        </mat-form-field2>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #recurringForm>
  <app-recurring-form [form]="repeatOptionsForm" [repeatableCtrl]="form.get('repeat')"></app-recurring-form>
</ng-template>

<ng-template #comments>
  <app-comments-list entityType="expense" [entityId]="expenseId">
    <app-empty-table type="CommentsList"></app-empty-table>
  </app-comments-list>
</ng-template>

<ng-template #activity>
  <app-activity-table entityType="expense" [entityId]="expenseId">
    <app-empty-table type="ActivityList"></app-empty-table>
  </app-activity-table>
</ng-template>

<ng-template #attachmentsTab>
  <app-upload-box
    [options]="uploadBoxOptions"
    [showFileList]="false"
    (complete)="onFileUploaded($event)"
    *ngIf="!(1 | isReadonlyRole)"
    image="/assets/images/upload-formate2.png"
  ></app-upload-box>

  <!-- <div class="d-flex align-items-center gap-3" *ngIf="form.get('photo').value as photo">
    <div class="text-primary text-xxl">
      <i class="" [ngClass]="photo | fileIcon"></i>
    </div>
    <div class="mr-4 text-truncate" style="direction: rtl">{{ photo }}</div>
    <div class="flex-fill text-light text-right text-sm"></div>
    <div class="d-flex">
      <a href="javascript:;" class="btn btn-light-info btn-sm" (click)="downloadPhoto(photo)"
        ><i class="cs-icon cs-icon-download"></i>
      </a>
      <a href="javascript:;" class="btn btn-light-danger btn-sm" (click)="onResetPhoto()" *ngIf="!(1 | isReadonlyRole)"
        ><i class="cs-icon cs-icon-trash"></i>
      </a>
    </div>
  </div> -->

  <table *ngIf="attachments.length" class="mt-4 table table-condensed" aria-hidden="false">
    <thead>
      <tr>
        <th class="text-sm text-semi-bold border-top-0"></th>
        <th class="text-sm text-semi-bold border-top-0">Name</th>
        <th class="text-sm text-semi-bold border-top-0">Details</th>
        <th class="text-sm text-semi-bold border-top-0">Download</th>
        <th class="text-sm text-semi-bold border-top-0">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of attachments; let i = index">
        <td class="text-primary text-xxl">
          <i [ngClass]="item.name | fileIcon"></i>
        </td>
        <td>{{ item.name }}</td>
        <td>
          <span class="text-nowrap">
            {{ item.created | epoch : 'DD-MMM-YYYY' }} at {{ item.created | epoch : 'hh:mm A' }}
          </span>
          <br />
          <span class="text-light text-sm"> {{ item.user }} • {{ item.size | fileSize }} </span>
        </td>
        <td class="text-center align-middle">
          <button type="button" class="btn btn-light-info btn-sm" (click)="onDownloadAttachment(item)">
            <i class="cs-icon cs-icon-download"></i>
          </button>
        </td>
        <td class="text-center align-middle">
          <button
            type="button"
            class="btn btn-light-danger btn-sm"
            (click)="onDeleteAttachment(item)"
            *ngIf="!(1 | isReadonlyRole)"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
