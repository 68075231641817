import { Pipe, PipeTransform } from '@angular/core'
import { airlines } from '@tv3/constants/airlines'
import * as R from 'ramda'

@Pipe({
  name: 'airlineCode',
})
export class AirlineCodePipe implements PipeTransform {
  transform(code: string): string {
    if (code) {
      let line = R.find((a) => a.code === code, airlines)
      if (line) {
        return line.name
      }
    }
    return code
  }
}
