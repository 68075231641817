import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { AbbOpportunityDialogComponent } from './abb-opportunity-dialog.component'
import { AirbnbOpportunityItem } from '@tokeet-frontend/channels'

@Injectable({
  providedIn: SharedModule,
})
export class AbbOpportunityDialogService {
  constructor(private dialog: MatDialog) {}

  public open(opportunity: AirbnbOpportunityItem, channelId: string, propertyId: string) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: 'auto',
      data: { opportunity, channelId, propertyId },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(AbbOpportunityDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
