import { AbstractControl } from '@angular/forms'

export function validateMaxNumber(number) {
  return (control: AbstractControl) => {
    if (control.value > number) {
      return { maxNumber: true }
    }
    return null
  }
}
