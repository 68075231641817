import { AlertDialogComponent } from './alert/alert-dialog/alert-dialog.component'
import { ConfirmDeleteDialogComponent } from './confirm/confirm-delete-dialog/confirm-delete-dialog.component'
import { ConfirmDialogComponent } from './confirm/confirm-dialog/confirm-dialog.component'
import { ConfirmExtraDialogComponent } from './confirm/confirm-extra-dialog/confirm-extra-dialog.component'
import { PromptDialogComponent } from './prompt/prompt-dialog.component'
import { InfoDialogComponent } from './info/info-dialog.component'
import { CloudinaryUploadDialogComponent } from './cloudinary-upload/cloudinary-upload-dialog.component'
import { EditTaxesDialogComponent } from './edit-taxes/edit-taxes-dialog.component'
import { HelpVideoDialogComponent } from './help-video-dialog/help-video-dialog.component'
import { HelpVideoDialogService } from './help-video-dialog/help-video-dialog.service'
import { AlertDialogService } from './alert/alert-dialog.service'
import { CloudinaryUploadDialogService } from './cloudinary-upload/cloudinary-upload-dialog.service'
import { ConfirmDialogService } from './confirm/confirm-dialog.service'
import { EditTaxesDialogService } from './edit-taxes/edit-taxes-dialog.service'
import { InfoDialogService } from './info/info-dialog.service'
import { PromptDialogService } from './prompt/prompt-dialog.service'
import { EditAddressDialogService } from './edit-address-dialog/edit-address-dialog.service'
import { EditAddressDialogComponent } from './edit-address-dialog/edit-address-dialog.component'
import { RelatedVideosOverlayComponent } from './help-video-dialog/related-videos-overlay.component'

export * from './cloudinary-upload/cloudinary-upload-dialog.interfaces'
export * from './help-video-dialog/help-video-dialog.service'
export * from './alert/alert-dialog.interfaces'
export * from './alert/alert-dialog.service'
export * from './cloudinary-upload/cloudinary-upload-dialog.service'
export * from './confirm/confirm-dialog.service'
export * from './confirm/confirm-dialog.interfaces'
export * from './edit-taxes/edit-taxes-dialog.service'
export * from './info/info-dialog.service'
export * from './prompt/prompt-dialog.interfaces'
export * from './prompt/prompt-dialog.service'
export * from './edit-address-dialog/edit-address-dialog.service'

export * from './alert/alert-dialog/alert-dialog.component'
export * from './confirm/confirm-delete-dialog/confirm-delete-dialog.component'
export * from './confirm/confirm-dialog/confirm-dialog.component'
export * from './confirm/confirm-extra-dialog/confirm-extra-dialog.component'
export * from './prompt/prompt-dialog.component'
export * from './info/info-dialog.component'
export * from './cloudinary-upload/cloudinary-upload-dialog.component'
export * from './edit-taxes/edit-taxes-dialog.component'
export * from './help-video-dialog/help-video-dialog.component'

export const dialogs = [
  AlertDialogComponent,
  ConfirmDeleteDialogComponent,
  ConfirmDialogComponent,
  ConfirmExtraDialogComponent,
  PromptDialogComponent,
  InfoDialogComponent,
  CloudinaryUploadDialogComponent,
  EditTaxesDialogComponent,
  HelpVideoDialogComponent,
  RelatedVideosOverlayComponent,
  EditAddressDialogComponent,
]

export const dialogServices = [
  HelpVideoDialogService,
  AlertDialogService,
  InfoDialogService,
  ConfirmDialogService,
  PromptDialogService,
  CloudinaryUploadDialogService,
  EditTaxesDialogService,
  EditAddressDialogService,
  EditAddressDialogService,
]
