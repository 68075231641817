import {
  AutomationDateRuleTypes,
  AutomationEventTypes,
  AutomationHandler,
  AutomationServiceTypes,
  AutomationTypes,
} from '../store'
import {
  AutomationEventTemplateSample,
  bookingCanceledNotifyEmailTemplateSample,
  bookingConfirmedNotifyEmailTemplateSample,
  deviceBatteryLowTemplateSample,
  deviceCodeFirstUsedTemplateSample,
  deviceIssuesRequireAttentionTemplateSample,
} from './templates'
import * as moment from 'moment/moment'

export interface PredefinedAutomationItem {
  name: string
  event: AutomationEventTypes
  handler?: AutomationHandler
  template?: AutomationEventTemplateSample
  service: AutomationServiceTypes
  type: AutomationTypes
  data?: any
}

export const deviceCodeFirstUsedAutomationSample: PredefinedAutomationItem = {
  name: 'Code First Used',
  event: AutomationEventTypes.DeviceCodeFirstUsed,
  handler: AutomationHandler.SendEmailMessage,
  template: deviceCodeFirstUsedTemplateSample,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Device,
}

export const deviceBatteryLowAutomationSample: PredefinedAutomationItem = {
  name: 'Battery Low',
  event: AutomationEventTypes.DeviceLowBattery,
  handler: AutomationHandler.SendEmailMessage,
  template: deviceBatteryLowTemplateSample,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Device,
}

export const deviceIssuesRequireAttentionAutomationSample: PredefinedAutomationItem = {
  name: 'Issues Require Attention',
  event: AutomationEventTypes.DeviceIssuesRequireAttention,
  handler: AutomationHandler.SendEmailMessage,
  template: deviceIssuesRequireAttentionTemplateSample,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Device,
}

export const bookingConfirmedEmailAutomationSample: PredefinedAutomationItem = {
  name: 'Booking Confirmation Message to Guest',
  event: AutomationEventTypes.BookingConfirmed,
  handler: AutomationHandler.SendEmailMessage,
  template: bookingConfirmedNotifyEmailTemplateSample,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Message,
}

export const bookingCanceledEmailAutomationSample: PredefinedAutomationItem = {
  name: 'Booking Cancellation Message to Guest',
  event: AutomationEventTypes.BookingCanceled,
  handler: AutomationHandler.SendEmailMessage,
  template: bookingCanceledNotifyEmailTemplateSample,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Message,
}

export const InvoiceCanceledFollowupAutomationSample: PredefinedAutomationItem = {
  name: 'Mark Booking as Followup when Invoice canceled',
  event: AutomationEventTypes.InvoiceCanceled,
  handler: AutomationHandler.BookingMarkFollowUp,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Booking,
}

export const CreateExpenseWhenBookingConfirmedAutomationSample: PredefinedAutomationItem = {
  name: 'Create Expense when Booking Confirmed',
  event: AutomationEventTypes.BookingConfirmed,
  service: AutomationServiceTypes.Advance,
  type: AutomationTypes.Expense,
  handler: AutomationHandler.ExpenseCreateHandler,
  data: {
    category: 'Maintenance',
    amount: 100,
    date: {
      base: AutomationDateRuleTypes.AfterBookingConfirmed,
      days: 0,
      hours: 0,
    },
    due: {
      base: AutomationDateRuleTypes.AfterBookingConfirmed,
      days: 1,
      hours: 0,
    },
    method: 'card',
    attachments: [],
    approval_status: 'approved',
  },
}

export const CreateTaskWhenCardDeclinedAutomationSample: PredefinedAutomationItem = {
  name: 'Failed Payment- Follow Up',
  event: AutomationEventTypes.PaymentCardDeclined,
  type: AutomationTypes.Task,
  service: AutomationServiceTypes.Advance,
  handler: AutomationHandler.TaskCreateHandler,
  data: {
    user_ids: [],
    start: {
      days: 0,
      hours: 0,
      base: 'after.booking-confirmed',
    },
    due: {
      base: 'after.booking-confirmed',
      days: 0,
      hours: 1,
    },
    name: 'Failed Payment- Follow Up',
    timezone: moment.tz.guess(),
    attachments: [],
    notes: 'Follow up with the client. ',
  },
}
