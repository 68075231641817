import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Destroyable, selectAllRentals, untilDestroy, ActionFailed } from '@tokeet-frontend/tv3-platform'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { finalize, map } from 'rxjs/operators'
import * as lodash from 'lodash'
import { BookingFee, FeeModalityLabels } from '../../../store/fees/booking-fee'
import { BookingFeesService } from '../../../store/fees/booking-fees.service'

import { FeeTypes } from '@tokeet/cost-resolver'

@Component({
  selector: 'app-select-fees-table',
  templateUrl: './select-fees-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFeesTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  @Input() excludedRentalIds: string[] = []
  @Input() set selectedRentalIds(rentalIds: string[]) {
    this.rentalsCtrl.setValue(rentalIds)
  }

  @Output() selected = new EventEmitter<BookingFee[]>()

  displayedColumns: string[] = ['select', 'name']
  feeTypes = FeeTypes
  modalityLabels = FeeModalityLabels

  dataSource = new MatTableDataSource<BookingFee>()
  selection = new SelectionModel<BookingFee>(true, [])

  rentals$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => lodash.filter(rentals, (r) => !this.excludedRentalIds.includes(r.id)))
  )
  isLoading = false
  rentalsCtrl = new FormControl([])
  fees: BookingFee[] = []

  constructor(private store: Store<any>, private bookingFeesService: BookingFeesService) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.bookingFeesService
      .getAllFees()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (fees) => {
          this.fees = fees
          this.refreshTableData(this.rentalsCtrl.value || [])
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
    this.rentalsCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((rentalIds) => {
      this.refreshTableData(rentalIds)
    })
    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selected.emit(this.selection.selected)
    })
  }

  refreshTableData(rentalIds?: string[]) {
    if (rentalIds?.length > 0) {
      this.dataSource.data = this.fees.filter((f) => rentalIds.includes(f.rental_id))
    } else {
      this.dataSource.data = this.fees.filter((f) => !this.excludedRentalIds.includes(f.rental_id))
    }
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: BookingFee, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }
}
