import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromExpense from './expense.reducer'
import { ExpenseEffects } from './expense.effects'
import { ExpenseService } from './expense.service'
import { ExpenseSettingsGuard } from './settings.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('expense', fromExpense.expenseReducer),
    EffectsModule.forFeature([ExpenseEffects]),
  ],
  declarations: [],
  providers: [ExpenseSettingsGuard, ExpenseService],
})
export class ExpenseStoreModule {}
