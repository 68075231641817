<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Rates List</div>
      <div class="col-sm-6 btn-holder">
        <button type="button" class="btn btn-secondary-info ml-2" (click)="onSave(form)">
          <i class="cs-icon cs-icon-save"></i> Save
        </button>
      </div>
    </div>

    <div class="p-3">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input matInput required placeholder="Rate Name" type="text" formControlName="name" />
            </mat-form-field2>
          </div>

          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="ratePlanType" placeholder="Rate Plan Type" required>
                <mat-option *ngFor="let type of agodaRatePlanTypes" [value]="type.code">{{ type.name }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>

          <ng-container *ngIf="agodaRatePlan?.ratePlanId">
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <input matInput required placeholder="Rate Plan ID" type="text" formControlName="ratePlanId" />
              </mat-form-field2>
            </div>

            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <input matInput required placeholder="Active" type="text" formControlName="active" />
              </mat-form-field2>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>
</div>
