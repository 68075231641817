import { Injectable } from '@angular/core'
import { OwnerAccessDialogComponent } from './owner-access-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class OwnerAccessDialogService {
  constructor(private dialog: MatDialog) {}

  open(rental: Rental) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { rental },
    }

    return this.dialog.open(OwnerAccessDialogComponent, defaultConfig)
  }
}
