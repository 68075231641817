import { Component, Input } from '@angular/core'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'

import { TooltipPosition } from '@angular/material/tooltip'

@Component({
  selector: 'app-default-task-view',
  template: `<app-default-route-indicator
    [checkIsDefault]="isDefault"
    [getDefaultRoutePayload]="getDefaultRoutePayload"
    tooltipText="Set this view as default"
    tooltipPosition="above"
  ></app-default-route-indicator>`,
})
export class DefaultTaskViewComponent {
  @Input() viewName: string

  @Input() tooltipPosition: TooltipPosition = 'right'

  isDefault = (pref: UserPreferences) => {
    return pref.defaultTaskView === this.viewName
  }

  getDefaultRoutePayload = (checked: boolean) => {
    return { default_task_view: this.viewName }
  }
}
