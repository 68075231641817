import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { NoteResponse } from '@tv3/store/note/note.model'
import { NoteForm } from '@tv3/interfaces/forms/note.form'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

export const AddNote = createAction('[Note] Add Note', props<{ inquiry: Inquiry; form: NoteForm; silent?: boolean }>())
export const AddNoteComplete = createAction('[Note] Add Note Complete', props<{ update: Update<Inquiry> }>())

export const UpdateNote = createAction(
  '[Note] Update Note',
  props<{ inquiry: Inquiry; form: NoteForm; note: NoteResponse }>()
)
export const UpdateNoteComplete = createAction('[Note] Update NotesComplete', props<{ update: Update<Inquiry> }>())

export const DeleteNote = createAction('[Note] Delete Note', props<{ inquiry: Inquiry; note: NoteResponse }>())
export const DeleteNoteComplete = createAction('[Note] Delete Note Complete', props<{ update: Update<Inquiry> }>())
