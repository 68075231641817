import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromChannels from './store/channels.reducer'
import { ChannelsEffects } from './store/channels.effects'
import { HomeToGoConnectionFormComponent } from './components/hometogo-connection-form/hometogo-connection-form.component'
import { FeedChannelConfigComponent } from './components/feed/channel/feed-channel-config/feed-channel-config.component'
import { FeedChannelDetailsComponent } from './components/feed/channel/feed-channel-details/feed-channel-details.component'
import { HomeToGoChannelGuard } from './guards/hometogo-channel.guard'
import { FeedConnectionDetailsComponent } from './components/feed/connection/feed-connection-details/feed-connection-details.component'
import { FeedConnectionConfigComponent } from './components/feed/connection/feed-connection-config/feed-connection-config.component'
import { HomeToGoChannelConfigForm } from './components/feed/config/feed-config/hometogo-channel-config-form.component'
import { PermissionModule } from '@tokeet-frontend/permission'
import { TiketChannelEffects } from './store/tiket/effects'
import { GVRChannelEffects } from './store/gvr/effects'
import { RentalsUnitedChannelEffects } from './store/rentals-united/effects'
import { HoliduChannelEffects } from './store/holidu/effects'
import { HVMIChannelEffects } from './store/hvmi/effects'
import { CtripChannelEffects } from './store/ctrip/effects'
import { RatingComponent } from './components/rating/rating.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { RentalsLibraryModule } from '@tokeet-frontend/rentals'

const components = [
  HomeToGoConnectionFormComponent,
  FeedChannelConfigComponent,
  FeedChannelDetailsComponent,
  FeedConnectionDetailsComponent,
  FeedConnectionConfigComponent,
  HomeToGoChannelConfigForm,
  RatingComponent,
]

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('channels-lib', fromChannels.channelsReducer),
    EffectsModule.forFeature([
      ChannelsEffects,
      GVRChannelEffects,
      RentalsUnitedChannelEffects,
      HVMIChannelEffects,
      HoliduChannelEffects,
      TiketChannelEffects,
      CtripChannelEffects,
    ]),
    Tv3PlatformModule,
    RentalsLibraryModule,
    PermissionModule,
  ],
  providers: [HomeToGoChannelGuard],
  declarations: [...components],
  exports: [...components],
})
export class ChannelsLibraryModule {}
