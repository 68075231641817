<div class="table-holder table-responsive">
  <table
    class="table table-default table-striped"
    [hidden]="isEmptyTable$ | async"
    [class.loading-section]="isUpdating$ | async"
  >
    <mat-table #table matSort [dataSource]="dataSource" class="border rounded">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="onToggleAll($event.checked)" [checked]="isAllSelected"> </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onToggle(element, $event.checked)"
            [checked]="element.selected"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.name" matTooltipPosition="above">{{ element.name | truncate : 45 }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="">
            <i class="fas fa-bed-front text-info"></i>
            {{ element.bedrooms || 0 }}
          </span>
          <span class="ml-3">
            <i class="fas fa-bath text-indigo"></i>
            {{ element.bathrooms || 0 }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Phone</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.phone" matTooltipPosition="above">{{
            element.phone | truncate : 16
          }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="cityView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.cityView" matTooltipPosition="above">{{ element.cityView | truncate : 16 }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="countryView">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Country</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.countryView" matTooltipPosition="above">{{
            element.countryView | truncate : 16
          }}</span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onOpenRental(row)" class="clickable"></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [pageBtns]="2"
      [length]="dataSource?.data?.length"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>
  </table>
  <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
</div>
