import { adapter, initialState, State } from './state'
import * as R from 'ramda'
import {
  addChannelAdjustmentComplete,
  deleteChannelAdjustmentComplete,
  loadChannelAdjustmentsComplete,
  updateChannelAdjustmentComplete,
} from './actions'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(loadChannelAdjustmentsComplete, (state, { items }) => adapter.setAll(items, R.merge(state, { isLoaded: true }))),
  on(addChannelAdjustmentComplete, updateChannelAdjustmentComplete, (state, { item }) => adapter.setOne(item, state)),
  on(deleteChannelAdjustmentComplete, (state, { id }) => adapter.removeOne(id, state))
)

export function adjustmentReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
