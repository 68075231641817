import { createSelector, select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { State } from '../channels.state'
import { combineLatest } from 'rxjs'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { channelsState } from '../channels.selectors'
import { HoliduListingModel, HoliduListingViewModel } from './model'

export const selectHoliduStatus = createSelector(channelsState, (state) => state.holidu.status)

export const selectHoliduConnected = createSelector(channelsState, (state) => state.holidu.status?.connected)

export const selectHoliduListings = createSelector(channelsState, (state) => state.holidu.listings || [])

export const selectProviderId = createSelector(channelsState, (state) => state.holidu.status.providerId)

export const selectHoliduRentalValidations = createSelector(channelsState, (state) => state.holidu.validations)

export const selectHoliduValidationsLoaded = createSelector(channelsState, (state) => state.holidu.validationsLoaded)

export const getHoliduListings = (store: Store<State>) =>
  combineLatest([store.pipe(select(selectHoliduListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      return R.map((listing): HoliduListingModel => {
        const rental = R.find((r: Rental) => r.id === listing.providerApartmentId, rentals)
        return {
          ...listing,
          rental,
          rental_name: rental?.name,
        } as HoliduListingViewModel
      }, listings) as HoliduListingViewModel[]
    })
  )
