import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { LosDiscount } from './los-discount.model'

export interface State extends EntityState<LosDiscount> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<LosDiscount> = createEntityAdapter<LosDiscount>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
