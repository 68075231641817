import { Serializable, deserialize, Expose } from '@tokeet-frontend/tv3-platform'

export class Address extends Serializable<Address> {
  address: string
  city: string
  country: string
  zip: number
  state: string

  @Expose({ name: 'country_code' })
  countryCode: string

  @Expose({ name: 'CC' })
  cc: string

  @Expose({ name: 'postal_code' })
  postalCode: number

  timezone: string

  static deserialize(data: object): Address {
    return deserialize(Address, data)
  }
}
