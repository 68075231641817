import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY } from 'rxjs'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError } from 'rxjs/operators'
import {
  RentalsUnitedListingModel,
  RentalsUnitedListingPayload,
  RentalsUnitedStatus,
  RentalsUnitedTokens,
  RentalUnitedCompanyDetails,
} from './model'
import { ListingsValidationFieldResult } from '../rental-validation-field'

@Injectable({
  providedIn: 'root',
})
export class RentalsUnitedChannelService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  connect(email: string, password: string, update: boolean = false) {
    const url = `@api/rentalsunited/connection/connect`

    return this.http.post<RentalsUnitedStatus>(url, { email, password, update })
  }

  importBookings(rentalId: string, propertyId: number) {
    const url = `@api/rentalsunited/import/property/bookings`
    return this.http.post(url, { propertyId })
  }

  getStatus() {
    const url = `@api/rentalsunited/connection/status`

    return this.http.get<RentalsUnitedStatus>(url)
  }

  validateCompanyDetails(data: RentalUnitedCompanyDetails) {
    const url = `@api/rentalsunited/connection/company-details/validate`

    return this.http.post(url, {})
  }

  updateCompanyDetails(data: RentalUnitedCompanyDetails) {
    const url = `@api/rentalsunited/connection/company-details`

    return this.http.post<{ success: boolean }>(url, data)
  }

  getRentalsUnitedCompanyDetails() {
    const url = `@api/rentalsunited/connection/company-details`

    return this.http.get<RentalUnitedCompanyDetails>(url)
  }

  getTokens() {
    const url = `@api/rentalsunited/connection/ru-tokens/`
    return this.http.get<RentalsUnitedTokens>(url)
  }

  disconnect() {
    const url = `@api/rentalsunited/connection/disconnect`

    return this.http.post(url, {})
  }

  setListingStatus(propertyId: number, status: boolean) {
    const url = `@api/rentalsunited/listings/property/${propertyId}/status`
    return this.http.put(url, { active: status })
  }

  deleteListing(propertyId: number) {
    const url = `@api/rentalsunited/listings/property/${propertyId}`
    return this.http.delete(url)
  }

  getListings() {
    const url = `@api/rentalsunited/listings`

    return this.http.get<RentalsUnitedListingModel[]>(url)
  }

  getConnections() {
    const url = `@api/rentalsunited/listings/tokeet`
    return this.http.get<any[]>(url)
  }

  getConnection(propertyId: string) {
    const url = `@api/rentalsunited/listings/property/${propertyId}`
    return this.http.get<any>(url)
  }

  createListing(listing: RentalsUnitedListingPayload) {
    const url = `@api/rentalsunited/listings`

    return this.http.post<{ propertyId: number }>(url, { listing }).pipe(
      catchError((error) => {
        this.toaster.error(error?.error?.error)
        return EMPTY
      })
    )
  }

  getRentalsValidations() {
    const url = `@api/rentalsunited/listings/validate/rentals`

    return this.http.post<ListingsValidationFieldResult>(url, {})
  }
}
