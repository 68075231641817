import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { PreferenceService } from './preference.service'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  LoadPreferences,
  LoadPreferencesComplete,
  UpdateBranding,
  UpdateBrandingComplete,
  UpdatePreferences,
  UpdatePreferencesComplete,
} from './preferences.actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class PreferencesEffects {
  @Effect()
  loadPreferences$ = this.actions$.pipe(
    ofType(LoadPreferences),
    switchMap(() =>
      this.preferences.get().pipe(
        map((preference) => LoadPreferencesComplete({ preference })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updatePreferences$ = this.actions$.pipe(
    ofType(UpdatePreferences),
    switchMap(({ id, form }) =>
      this.preferences.update(form).pipe(
        map((preference) => UpdatePreferencesComplete({ update: { id, changes: preference } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateBranding$ = this.actions$.pipe(
    ofType(UpdateBranding),
    switchMap(({ request }) =>
      this.preferences.updateBranding(request).pipe(
        map((preference) => UpdateBrandingComplete({ update: { id: preference.id, changes: preference } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private preferences: PreferenceService) {}
}
