import { Injectable } from '@angular/core'
import { InfoDialogComponent, InfoDialogParams } from './info-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable()
export class InfoDialogService {
  constructor(private dialog: MatDialog) {}

  public open(data?: InfoDialogParams, dialogType = 'fixed-modal') {
    const defaultData = {
      title: 'Info',
      body: '',
      closeText: 'Close',
    }

    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { ...defaultData, ...data },
      panelClass: [dialogType],
    }

    return this.dialog.open(InfoDialogComponent, defaultConfig).afterClosed()
  }
}
