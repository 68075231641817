<input
  matInput
  readonly
  type="text"
  [placeholder]="placeholder"
  [value]="getInputText | call : value"
  [matTooltip]="getInputTooltip | call : value : showInputTooltip"
  [matTooltipPosition]="inputTooltipPosition"
  matTooltipClass="text-preline"
  (click)="isPickerOpen = !isPickerOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
/>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="isPickerOpen = false"
  [cdkConnectedOverlayOffsetY]="8"
  [cdkConnectedOverlayOffsetX]="-32"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' }
  ]"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isPickerOpen"
>
  <app-date-picker
    [selection]="value"
    (selectionChange)="onDateSelect($event)"
    [local]="local"
    [showTime]="showTime"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [meridian]="true"
    timeSize="small"
    class="bg-white rounded dropdown-shadow"
  >
  </app-date-picker>
</ng-template>
