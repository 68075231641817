import { createAction, props } from '@ngrx/store'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Update } from '@ngrx/entity'
import { Guest } from '@tv3/store/guest/guest.model'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { AddBillingDetailsForm } from '@tv3/interfaces/forms/add-billing-details.form'

export const SetAdditionalGuests = createAction(
  '[Inquiry] Set Additional Guests',
  props<{ guestIds: string[]; inquiry: Inquiry }>()
)

export const AddTagsToInquiry = createAction(
  '[Inquiry] Add Inquiry Tags',
  props<{ inquiry: Inquiry; tags: string[]; newTags: string[] }>()
)

export const AddTagsToInquiries = createAction(
  '[Inquiry] Add Tags To Inquiries',
  props<{ inquiries: Inquiry[]; tags: string[] }>()
)
export const AddTagsToInquiriesComplete = createAction(
  '[Inquiry] Add Tags To Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const ConfirmBooking = createAction('[Inquiry] Confirm Booking', props<{ inquiry: Inquiry }>())
export const ConfirmBookingComplete = createAction(
  '[Inquiry] Confirm Booking Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ConfirmImportedBooking = createAction('[Inquiry] Confirm Imported Booking', props<{ inquiry: Inquiry }>())

export const CancelBookingWithReason = createAction(
  '[Inquiry] Cancel Booking With Reason',
  props<{ inquiry: Inquiry; reason: string }>()
)

export const CancelBooking = createAction('[Inquiry] Cancel Booking', props<{ inquiry: Inquiry; byGuest?: boolean }>())
export const CancelBookingComplete = createAction(
  '[Inquiry] Cancel Booking Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryRental = createAction(
  '[Inquiry] Change Rental',
  props<{ inquiry: Inquiry; rentalId: string }>()
)
export const ChangeInquiryRentalComplete = createAction(
  '[Inquiry] Change Rental Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeCheckIn = createAction('[Inquiry] Change Check In', props<{ inquiry: Inquiry; checkIn: any }>())
export const ChangeCheckInComplete = createAction(
  '[Inquiry] Change Check In Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeCheckOut = createAction('[Inquiry] Change Check Out', props<{ inquiry: Inquiry; checkOut: any }>())
export const ChangeCheckOutComplete = createAction(
  '[Inquiry] Change Check Out Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryGuest = createAction('[Inquiry] Change Guest', props<{ inquiry: Inquiry; guest: Guest }>())
export const ChangeInquiryGuestComplete = createAction(
  '[Inquiry] Change Guest Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UpdateInquiryGuestComplete = createAction(
  '[Inquiry] Update Guest Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryArrive = createAction('[Inquiry] Change Arrive', props<{ inquiry: Inquiry; arrive: Date }>())
export const ChangeInquiryArriveComplete = createAction(
  '[Inquiry] Change Arrive Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryDepart = createAction('[Inquiry] Change Depart', props<{ inquiry: Inquiry; depart: Date }>())
export const ChangeInquiryDepartComplete = createAction(
  '[Inquiry] Change Depart Complete',
  props<{ update: Update<Inquiry> }>()
)

export interface ChangeBookingDetailsPayload {
  rental_id: string
  inquiry_source: string
  num_child: number
  num_adults: number
  guest_depart: number
  guest_arrive: number

  force_checkin?: true
  check_in?: number
  check_out?: number
}

export const ChangeBookingDetails = createAction(
  '[Inquiry] Change Booking Details',
  props<{
    inquiry: Inquiry
    payload: ChangeBookingDetailsPayload
    isChargeAffected?: boolean
  }>()
)
export const ChangeBookingDetailsComplete = createAction(
  '[Inquiry] Change Booking Details Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeBookingEngineCost = createAction(
  '[Inquiry] Change Booking Engine Cost',
  props<{ inquiry: Inquiry; bookingEngineCost: BookingEngineCost; isUserCharge?: boolean }>()
)
export const ChangeBookingEngineCostComplete = createAction(
  '[Inquiry] Change Booking Engine Cost Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryAdults = createAction(
  '[Inquiry] Change Adults',
  props<{ inquiry: Inquiry; adults: number }>()
)
export const ChangeInquiryAdultsComplete = createAction(
  '[Inquiry] Change Adults Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryChannel = createAction(
  '[Inquiry] Change Channel',
  props<{ inquiry: Inquiry; channel: string }>()
)
export const ChangeInquiryChannelComplete = createAction(
  '[Inquiry] Change Channel Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ChangeInquiryChildren = createAction(
  '[Inquiry] Change Children',
  props<{ inquiry: Inquiry; children: number }>()
)
export const ChangeInquiryChildrenComplete = createAction(
  '[Inquiry] Change Children Complete',
  props<{ update: Update<Inquiry> }>()
)

export const LinkInquiries = createAction('[Inquiry] Link Inquiries', props<{ ids: string[] }>())
export const LinkInquiriesComplete = createAction(
  '[Inquiry] Link Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const ArchiveInquiries = createAction('[Inquiry] Archive Inquiries', props<{ ids: string[] }>())
export const ArchiveInquiriesComplete = createAction(
  '[Inquiry] Archive Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const UnArchiveInquiries = createAction('[Inquiry] UnArchive Inquiries', props<{ ids: string[] }>())
export const UnArchiveInquiriesComplete = createAction(
  '[Inquiry] UnArchive Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const ArchiveInquiry = createAction('[Inquiry] Archive Inquiry', props<{ inquiry: Inquiry }>())
export const ArchiveInquiryComplete = createAction(
  '[Inquiry] Archive Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UnArchiveInquiry = createAction('[Inquiry] UnArchive Inquiry', props<{ inquiry: Inquiry }>())
export const UnArchiveInquiryComplete = createAction(
  '[Inquiry] UnArchive Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const FollowupInquiries = createAction('[Inquiry] Followup Inquiries', props<{ ids: string[] }>())
export const FollowupInquiriesComplete = createAction(
  '[Inquiry] Followup Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const FollowupInquiry = createAction('[Inquiry] Followup Inquiry', props<{ inquiry: Inquiry }>())
export const FollowupInquiryComplete = createAction(
  '[Inquiry] Followup Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UnReadInquiry = createAction('[Inquiry] UnRead Inquiry', props<{ inquiry: Inquiry }>())
export const UnReadInquiryComplete = createAction(
  '[Inquiry] UnRead Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ReadInquiry = createAction('[Inquiry] Read Inquiry', props<{ inquiry: Inquiry; silent?: boolean }>())
export const ReadInquiryComplete = createAction('[Inquiry] Read Inquiry Complete', props<{ update: Update<Inquiry> }>())

export const UnLinkInquiry = createAction('[Inquiry] UnLink Inquiry', props<{ inquiry: Inquiry }>())
export const UnLinkInquiryComplete = createAction(
  '[Inquiry] UnLink Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const NoFollowupInquiries = createAction('[Inquiry] NoFollowup Inquiries', props<{ ids: string[] }>())
export const NoFollowupInquiriesComplete = createAction(
  '[Inquiry] NoFollowup Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const NoFollowupInquiry = createAction('[Inquiry] NoFollowup Inquiry', props<{ inquiry: Inquiry }>())
export const NoFollowupInquiryComplete = createAction(
  '[Inquiry] NoFollowup Inquiry Complete',
  props<{ update: Update<Inquiry> }>()
)

export const ReadInquiries = createAction('[Inquiry] Read Inquiries', props<{ ids: string[] }>())
export const ReadInquiriesComplete = createAction(
  '[Inquiry] Read Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const UnReadInquiries = createAction('[Inquiry] UnRead Inquiries', props<{ ids: string[] }>())
export const UnReadInquiriesComplete = createAction(
  '[Inquiry] UnRead Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const MarkPaidInquiry = createAction('[Inquiry] Mark Paid', props<{ inquiry: Inquiry }>())
export const MarkPaidInquiryComplete = createAction(
  '[Inquiry] Mark Paid Complete',
  props<{ update: Update<Inquiry> }>()
)

export const MarkUnpaidInquiry = createAction('[Inquiry] Mark Unpaid', props<{ inquiry: Inquiry }>())
export const MarkUnpaidInquiryComplete = createAction(
  '[Inquiry] Mark Unpaid Complete',
  props<{ update: Update<Inquiry> }>()
)

export const MarkPaidInquiries = createAction('[Inquiry] Mark Paid Inquiries', props<{ inquiries: Inquiry[] }>())
export const MarkPaidInquiriesComplete = createAction(
  '[Inquiry] Mark Paid Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const MarkUnpaidInquiries = createAction('[Inquiry] Mark Unpaid Inquiries', props<{ inquiries: Inquiry[] }>())
export const MarkUnpaidInquiriesComplete = createAction(
  '[Inquiry] Mark Unpaid Inquiries Complete',
  props<{ updates: Update<Inquiry>[] }>()
)

export const AddInquiryCard = createAction(
  '[Inquiry] Add Inquiry Card',
  props<{ inquiry: Inquiry; form: AddBillingDetailsForm }>()
)
export const AddInquiryCardComplete = createAction(
  '[Inquiry] Add Inquiry Card Complete',
  props<{ update: Update<Inquiry> }>()
)

export const SetInquiryColor = createAction('[Inquiry] Set Color', props<{ inquiry: Inquiry; color: string }>())
export const SetInquiryColorComplete = createAction(
  '[Inquiry] Set Color Complete',
  props<{ update: Update<Inquiry> }>()
)

export const DeleteInquiryCard = createAction('[Inquiry] Delete Inquiry Card', props<{ inquiry: Inquiry }>())
export const DeleteInquiryCardComplete = createAction(
  '[Inquiry] Delete Inquiry Billing Card Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UpdateCheckinStatus = createAction(
  '[Inquiry] Update Checkin Status',
  props<{ inquiry: Inquiry; status: 0 | 1 }>()
)
export const UpdateCheckinStatusComplete = createAction(
  '[Inquiry] Update Checkin Status Complete',
  props<{ update: Update<Inquiry> }>()
)

export const UpdateCheckoutStatus = createAction(
  '[Inquiry] Update Checkout Status',
  props<{ inquiry: Inquiry; status: 0 | 1 }>()
)
export const UpdateCheckoutStatusComplete = createAction(
  '[Inquiry] Update Checkout Status Complete',
  props<{ update: Update<Inquiry> }>()
)

export const SetGuestPortalAccessible = createAction(
  '[Inquiry] Set Guest Portal Accessible',
  props<{ inquiryId: string; rentalId: string; disabled: boolean }>()
)
export const SetGuestPortalAccessibleComplete = createAction(
  '[Inquiry] Set Guest Portal Accessible Complete',
  props<{ update: Update<Inquiry> }>()
)

export const InquiryIsUpdated = createAction('[Inquiry] Inquiry Is Updated', props<{ update: Update<Inquiry> }>())

export const InquiryUpdateComplete = createAction('[Inquiry] Update Complete', props<{ update: Update<Inquiry> }>())
