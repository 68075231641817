<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill">
    <div class="d-flex gap-3 align-items-center p-3">
      <img src="/assets/images/channel-wizards/step-icon1.png" alt="" height="40" />
      <div>
        <h6 class="mb-0">Preparation</h6>
        <div class="text-sm">
          This section will prepare you for connecting Booking.com with AdvanceCM. <br />
          Please read through these instructions carefully before proceeding.
        </div>
      </div>
    </div>
    <div class="border-top p-4">
      <div>
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" #start>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">1</div>
                Create or Import Rentals
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Linking an existing AdvanceCM rental to your Booking.com property will be required in the connection
              wizard.
            </p>
            <p>
              If you have not yet manually created or imported a rental from Booking.com please do so before proceeding.
              The following guide will explain how to do this.
              <a href="https://help.tokeet.com/rental-settings/how-do-i-add-and-update-rentals" target="_blank"
                >https://help.tokeet.com/rental-settings/how-do-i-add-and-update-rentals</a
              >
            </p>
            <p>
              Please note that one AdvanceCM rental can be connected with multiple channels. The combined availability
              of the bookings for all connected channels will be pushed out to any channels connected to this rental.
            </p>
            <div class="text-right mt-3">
              <button class="btn btn-light-info" (click)="blocked.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #blocked>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">2</div>
                Blocked Dates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                After connecting AdvanceCM with Booking.com your availability will be determined solely by what shows on
                your AdvanceCM rental calendar.
              </p>

              <p>
                Any previously blocked or closed dates (not including confirmed bookings which will show as blocked) on
                the booking.com calendar will be re-opened as a result of making this connection.
              </p>

              <p>
                In order to keep these dates blocked you will want to either prepare your AdvanceCM rental by adding the
                appropriate hold events to the calendar before pushing availability, or add these hold events to the
                calendar after you have completed the remaining steps in this connection wizard.
              </p>

              <p>
                This guide will explain how to do this.
                <a href="https://help.tokeet.com/calendars/what-is-a-hold-event" target="_blank"
                  >https://help.tokeet.com/calendars/what-is-a-hold-event</a
                >
              </p>
              <div class="text-right mt-3">
                <button class="btn btn-light-info" (click)="prepare.open()">
                  Next <i class="cs-icon cs-icon-arrow-down-long"></i>
                </button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #prepare>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="step-number mr-2">3</div>
                Preparing Rates
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <p>
                Once a connection is established between AdvanceCM and Booking.com your rates and availability will be
                disabled until you have completed all of the steps in the wizard.
              </p>

              <p>
                Going forward, you must push rates from AdvanceCM to Booking.com and will not be able to edit them
                directly on the Booking.com rates calendar.
              </p>

              <p>Please take note of the following important considerations:</p>
              <ul>
                <li>
                  You will not be able to review the rates that were set previously in Booking.com once you establish
                  the connection with AdvanceCM. For this reason, it is very important that you review that information
                  on the Booking.com rates calendar before proceeding.
                </li>
                <li>
                  Pushing rates to booking.com will be a required step in the connection wizard. If you have not yet
                  prepared rates for this purpose, please use the following guide to do so before beginning.
                  <a
                    href="https://help.tokeet.com/channel-manager/booking-com/pushing-rates-to-bookingcom"
                    target="_blank"
                    >https://help.tokeet.com/channel-manager/booking-com/pushing-rates-to-bookingcom</a
                  >
                </li>
                <li>
                  You will have an opportunity to create a rate within the connection wizard and can make changes after
                  the connection is complete, but it is recommended that you prepare your rates before proceeding if you
                  would like to have your full rate plan in place before completing the connection. If you have a very
                  straightforward plan (example: One Standard Rate that doesn’t change dependent on the date range)
                  adding this rate from within the connection wizard is recommended.
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mt-5 d-flex align-items-center justify-content-between">
        <button class="btn btn-outline-light" (click)="prev.emit()">
          <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
          Previous
        </button>
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Next
          <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/step2.png" alt="" class="w-100" />
  </div>
</div>
