import { Pipe, PipeTransform } from '@angular/core'
import { ButtonType } from '@tv3/shared/button/button.component'

@Pipe({
  name: 'buttonClass',
})
export class ButtonClassPipe implements PipeTransform {
  transform(type: ButtonType): string {
    switch (type) {
      case 'success':
        return 'btn btn-secondary-info'
      case 'warning':
        return 'btn btn-secondary-warning'
      case 'info':
        return 'btn btn-secondary-info'
      case 'danger':
        return 'btn btn-secondary-danger'
    }
  }
}
