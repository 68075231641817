import { NgModule } from '@angular/core'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { CommonModule } from '@angular/common'
import { CommentService } from './store'
import { CommentsListComponent } from './components/comments-list/comments-list.component'

const components = [CommentsListComponent]

const services = [CommentService]

@NgModule({
  imports: [CommonModule, Tv3PlatformModule],
  providers: [
    //
    ...services,
  ],
  exports: [
    //
    ...components,
  ],
  declarations: [
    //
    ...components,
  ],
})
export class CommentsLibraryModule {}
