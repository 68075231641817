<div class="mb-3">
  <div class="container">
    <div class="row">
      <div class="col-8 pl-0 pr-2">
        <mat-form-field2 [showLabel]="false" class="mat-block">
          <app-select
            [items]="rentals$ | async"
            bindLabel="name"
            bindValue="id"
            [multiple]="true"
            [searchable]="true"
            [formControl]="rentalsCtrl"
            placeholder="Select Rentals to Copy from"
          >
          </app-select>
        </mat-form-field2>
      </div>
      <div class="col-4 pr-0 pl-2">
        <app-table-search class="max-w-full" (search)="onSearch($event)"></app-table-search>
      </div>
    </div>
  </div>
</div>
<mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="onMasterChange($event.checked)" [checked]="isMasterChecked()"> </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-checkbox (change)="onSelect(element, $event.checked)" [checked]="selection.isSelected(element)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Booking Fee</mat-header-cell>
    <mat-cell *matCellDef="let element" class="d-flex align-items-center gap-2">
      {{ element.name }}
      <div class="flex-fill"></div>
      <span class="badge badge-secondary-info">
        {{ modalityLabels[element.modality] }}
      </span>
      <span class="badge badge-secondary-indigo text-capitalize">
        {{ element.type }}
      </span>
      <span class="badge badge-secondary-warning">
        {{ element.type === feeTypes.Percent ? element.amount + '%' : (element.amount | number : '1.2-2') }}
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<div *ngIf="!dataSource.data?.length" class="p-5 text-light text-center">There is no data</div>
<mat-paginator2
  #paginator
  [length]="dataSource?.data?.length"
  [pageSizeOptions]="[5, 10, 20, 50]"
  [showFirstLastButtons]="true"
>
</mat-paginator2>
