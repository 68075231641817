import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { UserService } from './user.service'

import {
  AddUser,
  AddUserComplete,
  LoadUsers,
  LoadUsersComplete,
  RemoveUser,
  RemoveUserComplete,
  RemoveUsers,
  RemoveUsersComplete,
  UpdateUser,
  UpdateUserAttributes,
  UpdateUserAttributesComplete,
  UpdateUserComplete,
  UpdateUserMFA,
  UpdateUserMFAComplete,
  UpdateUserSettings,
  UpdateUserSettingsComplete,
} from './user.actions'
import { ActionFailed } from '../utility'
import { Toaster } from '../../services'

@Injectable()
export class UserEffects {
  @Effect()
  loadUsers$ = this.actions$.pipe(
    ofType(LoadUsers),
    switchMap(() =>
      this.users.fetchUsers().pipe(
        map((users) => LoadUsersComplete({ users })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addUser$ = this.actions$.pipe(
    ofType(AddUser),
    concatMap(({ payload }) =>
      this.users.add(payload).pipe(
        map((user) => AddUserComplete({ user })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateUser$ = this.actions$.pipe(
    ofType(UpdateUser),
    concatMap(({ payload, userId }) =>
      this.users.update(userId, payload).pipe(
        map((user) => UpdateUserComplete({ update: { id: user.id, changes: user } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateUserMFA$ = this.actions$.pipe(
    ofType(UpdateUserMFA),
    concatMap(({ payload, userId }) =>
      this.users.updateMFA(userId, payload).pipe(
        map((user) => UpdateUserMFAComplete({ update: { id: userId, changes: { mfa_config: payload } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateUserAttrs$ = this.actions$.pipe(
    ofType(UpdateUserAttributes),
    // @ts-ignore
    concatMap(({ userId, attributes }) =>
      this.users.update(userId, { attributes }).pipe(
        // @ts-ignore
        map((user) => UpdateUserAttributesComplete({ update: { id: user.id, changes: user } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateUserSettings$ = this.actions$.pipe(
    ofType(UpdateUserSettings),
    map((payload) => {
      return {
        request: {
          settings: {
            ...payload.settings,
            disabled: payload.settings.disabled ? 1 : 0,
            signature: payload.settings.signature ? 1 : 0,
            automata: payload.settings.automata ? 1 : 0,
            ownercenter: payload.settings.ownercenter ? 1 : 0,
            rategenie: payload.settings.rategenie ? 1 : 0,
            webready: payload.settings.webready ? 1 : 0,
            checklist: payload.settings.checklist ? 1 : 0,
            margins: payload.settings.margins ? 1 : 0,
          },
        },
        userId: payload.user.id,
      }
    }),
    concatMap((payload) =>
      this.users.updateSettings(payload.userId, payload.request).pipe(
        map((user) => UpdateUserSettingsComplete({ update: { id: user.id, changes: user } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeUser$ = this.actions$.pipe(
    ofType(RemoveUser),
    switchMap(({ id }) =>
      this.users.remove(id).pipe(
        map(() => RemoveUserComplete({ id })),
        tap(() => this.toast.success('User deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeUsers$ = this.actions$.pipe(
    ofType(RemoveUsers),
    switchMap(({ ids }) =>
      this.users.removeMultiple(ids).pipe(
        map(() => RemoveUsersComplete({ ids })),
        tap(() => this.toast.success('Users deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private users: UserService, private toast: Toaster) {}
}
