import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { FormBuilder } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { finalize, startWith, take } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as fromRoot from '@tv3/store/state'
import {
  ChannelAdjustmentsGuard,
  createLocaleCompareSort,
  DataCheckerService,
  deleteChannelAdjustment,
  isEmptyTable,
  isSomething,
  loadChannelAdjustments,
  loadChannelAdjustmentsComplete,
  selectAllChannelAdjustments,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import {
  ChannelAdjustment,
  ConfirmDialogService,
  Destroyable,
  Rental,
  selectAllApiChannelViews,
  selectAllRentals,
  tokeetDashboardChannel,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'
import { ChannelAutomationDialogService } from '../automation-dialog/channel-automation-dialog.service'

@Component({
  selector: 'app-channel-automations',
  templateUrl: './channel-automations.component.html',
  styleUrls: ['./channel-automations.component.scss'],
})
export class ChannelAutomationsComponent extends Destroyable implements OnInit {
  @Input() viewType: 'list' | 'grid' = 'list'
  tableType = TableType.ChannelAdjustments

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  channelAdjustments$ = new BehaviorSubject<ChannelAdjustment[]>([])

  channels: { channelId: string; label: string }[] = []
  rentals: Rental[] = []
  rentalsById: Record<string, Rental> = {}

  filters = this.fb.group({
    channelIds: [],
    rentalIds: [],
  })

  displayedColumns = ['channelName', 'adjust_amt', 'adjust_pct', 'rentals', 'edit']

  dataSource = new MatTableDataSource<ChannelAdjustment>()
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private fb: FormBuilder,
    private confirmDialog: ConfirmDialogService,
    private channelAutomationDialog: ChannelAutomationDialogService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([ChannelAdjustmentsGuard])
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort(this.sortDataAccessor)

    this.store
      .select(selectChannelsWithCustomChannels(selectAllApiChannelViews, 'name', [tokeetDashboardChannel]))
      .pipe(untilDestroy(this))
      .subscribe((channels) => {
        this.channels = channels
      })

    this.store
      .select(selectAllRentals)
      .pipe(untilDestroy(this))
      .subscribe((rentals) => {
        this.rentalsById = lodash.keyBy(rentals, (rental) => rental.id)
        this.rentals = rentals
      })

    combineLatest([
      this.filters.valueChanges.pipe(startWith(this.filters.getRawValue())),
      this.store.pipe(select(selectAllChannelAdjustments)),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([filters, items]: [any, ChannelAdjustment[]]) => {
        items = items
          ?.filter((ca) => (isSomething(filters.channelIds) ? filters.channelIds.includes(ca.channel_id) : true))
          .filter((c) =>
            isSomething(filters.rentalIds) ? lodash.intersection(filters.rentalIds, c.rentals).length > 0 : true
          )

        this.dataSource.data = items || []
      })
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(loadChannelAdjustmentsComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(loadChannelAdjustments())
  }

  sortDataAccessor = (adjustment: ChannelAdjustment, property: string) => {
    switch (property) {
      case 'channelName':
        return this.getChannelName(adjustment.channel_id)
      case 'rentals':
        return adjustment.rentals ? adjustment.rentals.length : 0
      default:
        return adjustment[property]
    }
  }

  getRentalNames = (rentalIds: string[]) => {
    return rentalIds
      .map((rentalId) => this.rentalsById[rentalId] && this.rentalsById[rentalId].name)
      .filter((name) => name)
      .join(', ')
  }

  getChannelName = (channelId: string) => {
    const channel = this.channels.find((c) => c.channelId === channelId)

    return channel ? channel.label : channelId === 'tokeet' ? 'AdvanceCM Dashboard' : 'Unknown'
  }

  getRentalsTooltip = (rentalIds: string) => {
    const rentalNames = lodash.map(rentalIds, (rentalId) => {
      const rental = this.rentalsById[rentalId]

      return rental && rental.name
    })

    return lodash.compact(rentalNames).join(', ')
  }

  onAdd() {
    this.channelAutomationDialog.open()
  }

  onEdit(channelAdjustment: ChannelAdjustment) {
    this.channelAutomationDialog.open({ channelAdjustment })
  }

  onDelete(channelAdjustment: ChannelAdjustment) {
    this.confirmDialog.confirm().subscribe(() => {
      this.store.dispatch(deleteChannelAdjustment({ id: channelAdjustment.pkey }))
    })
  }

  clearFilters() {
    this.filters.reset()
  }
}
