import { AbstractControl } from '@angular/forms'

export function getValidationMessage(ctrl: AbstractControl, label: string): string | undefined {
  if (!ctrl.invalid) {
    return
  }

  if (ctrl.hasError('required')) {
    return `${label} is required`
  }

  if (ctrl.hasError('email')) {
    return `${label} is invalid`
  }

  if (ctrl.hasError('phone')) {
    return `${label} is invalid`
  }

  if (ctrl.hasError('number')) {
    return 'Only number values are allowed.'
  }

  if (ctrl.hasError('integer')) {
    return 'Only integer values are allowed.'
  }

  if (ctrl.hasError('minlength')) {
    const minLength = ctrl.errors.minlength.requiredLength
    return `Minimum length is ${minLength} characters.`
  }

  if (ctrl.hasError('maxlength')) {
    const maxLength = ctrl.errors.maxlength.requiredLength
    return `Maximum length is ${maxLength} characters.`
  }

  if (ctrl.hasError('min')) {
    const min = ctrl.errors.min.min ?? ctrl.errors.min
    return `Minimum value is ${min}.`
  }

  if (ctrl.hasError('max')) {
    const max = ctrl.errors.max.max ?? ctrl.errors.max
    return `Maximum value is ${max}.`
  }

  if (ctrl.hasError('url')) {
    return 'Invalid url format.'
  }

  if (ctrl.hasError('percent')) {
    return 'Value should be greater than 0 and less than 100'
  }

  if (ctrl.hasError('positive')) {
    return 'Value should be greater than 0'
  }
}
