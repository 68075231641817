import { Component, OnInit } from '@angular/core'
import {
  EditorRichComponent,
  EditorRichComponentDependencies,
} from '@tv3/containers/templates/template-editor/shared/editor-rich.component.abstract'
import { isHTML } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-editor-http',
  templateUrl: '../shared/editor-rich.component.abstract.html',
})
export class EditorHttpComponent extends EditorRichComponent implements OnInit {
  isPlain = true

  constructor(dependencies: EditorRichComponentDependencies) {
    super(dependencies)
  }

  ngOnInit() {
    this.isPlain = !isHTML(this.contentCtrl.value)
  }
}
