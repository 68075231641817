import { Component, OnInit } from '@angular/core'

declare const window: any

@Component({
  selector: 'app-phone-widget',
  templateUrl: './phone-widget.component.html',
  styleUrls: ['./phone-widget.component.scss'],
})
export class PhoneWidgetComponent implements OnInit {
  public open = false
  public spin = true
  public direction = 'up'
  public animationMode = 'fling'

  constructor() {}

  ngOnInit() {}

  goToOnboarding() {
    window.open('https://calendly.com/tokeet-success/onboarding-call')
  }

  goToDemo() {
    window.open('https://calendly.com/tokeet-success/initial-training')
  }
}
