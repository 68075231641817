import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Observable } from 'rxjs'
import { AirbnbPhoto, AirbnbRoom } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.models'
import { map, tap } from 'rxjs/operators'
import { Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class AirbnbApiService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  //region Photos
  getPhotos(connection: ConnectionView): Observable<AirbnbPhoto[]> {
    const url = `@api/abbv2/listing/photos/${connection.channelId}/${connection.propertyId}/${connection.roomId}`

    return this.http.get<AirbnbPhoto[]>(url)
  }

  updateOrder(connection: ConnectionView, photo: AirbnbPhoto, newOrder: number): Observable<AirbnbPhoto> {
    const url = `@api/abbv2/listing/photos/${connection.channelId}/${connection.propertyId}/${photo.id}`

    return this.http
      .put<{ listing_photo: AirbnbPhoto }>(url, { sort_order: newOrder })
      .pipe(map((response) => response.listing_photo))
  }

  updateCaption(connection: ConnectionView, photo: AirbnbPhoto): Observable<AirbnbPhoto> {
    const url = `@api/abbv2/listing/photos/${connection.channelId}/${connection.propertyId}/${photo.id}`

    return this.http.put<{ listing_photo: AirbnbPhoto }>(url, { caption: photo.caption }).pipe(
      tap(() => this.toaster.success('Caption updated successfully.')),
      map((response) => response.listing_photo)
    )
  }

  deletePhoto(connection: ConnectionView, photo: AirbnbPhoto): Observable<AirbnbPhoto> {
    const url = `@api/abbv2/listing/photos/${connection.channelId}/${connection.propertyId}/${photo.id}`

    return this.http.delete<{ listing_photo: AirbnbPhoto }>(url).pipe(
      tap(() => this.toaster.success('Photo deleted successfully.')),
      map((response) => response.listing_photo)
    )
  }
  //endregion

  //region Rooms
  getRooms(connection: ConnectionView): Observable<AirbnbRoom[]> {
    const url = `@api/abbv2/listing/rooms/${connection.channelId}/${connection.propertyId}/${connection.roomId}`

    return this.http.get<AirbnbRoom[]>(url)
  }
  //endregion
}
