<div class="box-details">
  <div class="box-dsc-row row">
    <div class="col-4">Rental</div>
    <div class="col-8">
      <span [matTooltip]="inquiry.rental?.name" matTooltipPosition="above">
        {{ inquiry.rental?.name }}
      </span>
    </div>
  </div>
  <div class="box-dsc-row row">
    <div class="col-4">Guests</div>
    <div class="col-8">
      {{ (inquiry.numAdults || 0) + (inquiry.numChild || 0) }}
    </div>
  </div>
  <div class="box-dsc-row row">
    <div class="col-4">Arrive</div>
    <div class="col-8">
      {{ inquiry.guestArrive | epochUTC : 'DD - MMM - YYYY' }}
    </div>
  </div>
  <div class="box-dsc-row row">
    <div class="col-4">Depart</div>
    <div class="col-8">
      {{ inquiry.guestDepart | epochUTC : 'DD - MMM - YYYY' }}
    </div>
  </div>
  <div class="box-dsc-row row">
    <div class="col-4">Days</div>
    <div class="col-8">
      {{ inquiry.guestDepart | dateDiff : inquiry.guestArrive }} Days,
      {{ inquiry.guestDepart | dateDiff : inquiry.guestArrive : 'nights' }} Nights
    </div>
  </div>
</div>
