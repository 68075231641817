import { adapter, initialState, State } from './forward.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddForwardsComplete,
  DeleteForwardComplete,
  DeleteForwardsComplete,
  LoadForwardsComplete,
} from '@tv3/store/forward/forward.actions'

export const reducer = createReducer(
  initialState,
  on(AddForwardsComplete, (state, { forwards }) => adapter.upsertMany(forwards, state)),
  on(DeleteForwardComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteForwardsComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(LoadForwardsComplete, (state, { forwards }) => adapter.addAll(forwards, R.merge(state, { isLoaded: true })))
)

export function forwardReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
