import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Rental } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'

export function createForm(fb: FormBuilder) {
  return fb.group({
    amenities: fb.array([]),
  })
}

@Component({
  selector: 'app-content-step-amenities-form',
  templateUrl: './content-step-amenities-form.component.html',
  styleUrls: ['./content-step-amenities-form.component.scss'],
})
export class ContentStepAmenitiesFormComponent implements OnInit, OnChanges {
  @Input() amenities: { id: string; name: string }[] = []
  @Input() form: FormGroup
  @Input() rental: Rental

  get amenityCtrls() {
    return this.form.controls['amenities']['controls']
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rental'] && changes['rental'].currentValue) {
      const controls = R.map((d) => this.fb.control(R.indexOf(d.id, R.keys(this.rental.detail)) !== -1), this.amenities)
      this.form.setControl('amenities', this.fb.array(controls))
    }
  }
}
