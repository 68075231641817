import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { ActionFailed, Toaster, isSomething } from '@tokeet-frontend/tv3-platform'
import { RoomsService } from './rooms.service'
import {
  DeleteRoom,
  DeleteRoomComplete,
  DeleteRooms,
  DeleteRoomsComplete,
  GetRentalRooms,
  GetRentalRoomsComplete,
  LoadRooms,
  LoadRoomsComplete,
  UpdateRoom,
  UpdateRoomComplete,
  UpsertRooms,
  UpsertRoomsComplete,
} from './rooms.actions'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'

@Injectable()
export class RoomsEffects {
  @Effect()
  loadRooms$ = this.actions$.pipe(
    ofType(LoadRooms),
    switchMap(() =>
      this.roomsService.getAllRooms().pipe(
        map((rooms) => LoadRoomsComplete({ rooms })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getRooms$ = this.actions$.pipe(
    ofType(GetRentalRooms),
    switchMap(({ rentalId }) =>
      this.roomsService.getRentalRooms(rentalId).pipe(
        map((rooms) => GetRentalRoomsComplete({ rooms })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  upsertRooms$ = this.actions$.pipe(
    ofType(UpsertRooms),
    switchMap(({ createRooms, updateRooms, deleteRooms }) =>
      this.roomsService.upsertRooms(createRooms || [], updateRooms || [], deleteRooms || []).pipe(
        tap(() => this.toaster.success('Rooms updated successfully')),
        switchMap((rooms) => {
          const actions = []
          if (isSomething(rooms)) {
            actions.push(UpsertRoomsComplete({ rooms }))
          }
          if (isSomething(deleteRooms)) {
            actions.push(DeleteRoomsComplete({ ids: deleteRooms }))
          }
          return actions
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateRoom$ = this.actions$.pipe(
    ofType(UpdateRoom),
    switchMap(({ id, room, silent }) =>
      this.roomsService.updateRoom(id, room).pipe(
        tap(() => {
          if (!silent) {
            this.toaster.success('Room updated successfully')
          }
        }),
        map((response) => UpdateRoomComplete({ update: { id: response.pkey, changes: response } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteRoom$ = this.actions$.pipe(
    ofType(DeleteRoom),
    switchMap(({ id }) =>
      this.roomsService.deleteRoom(id).pipe(
        tap(() => this.toaster.success('Room deleted successfully')),
        map(() => DeleteRoomComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteRooms$ = this.actions$.pipe(
    ofType(DeleteRooms),
    switchMap(({ ids }) =>
      this.roomsService.deleteRooms(ids).pipe(
        tap(() => this.toaster.success('Room deleted successfully')),
        map(() => DeleteRoomsComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private roomsService: RoomsService, private toaster: Toaster) {}
}
