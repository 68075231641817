import { createAction, props } from '@ngrx/store'
import { UserPreferences } from './user-preferences.model'
import { Update } from '@ngrx/entity'

export const LoadUserPreferences = createAction('[UserPreferences] Load Start')
export const LoadUserPreferencesComplete = createAction(
  '[UserPreferences] Load Complete',
  props<{ preferences: UserPreferences }>()
)

export const UpdateUserPreferences = createAction(
  '[UserPreferences] Update',
  props<{ id: string; form: any; successMessage?: string }>()
)
export const UpdateUserPreferencesComplete = createAction(
  '[UserPreferences] Update Complete',
  props<{ update: Update<UserPreferences> }>()
)

export const VisitTV3 = createAction('[UserPreferences] Visit TV3')
