<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-user-edit"></i> Edit Guest</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <h6>Change the guest on this booking:</h6>
      </div>

      <div class="col-md-12">
        <app-select-search
          bindLabel="label"
          bindValue="id"
          [inline]="false"
          (search)="guestSearch.next($event)"
          [multiple]="false"
          [items]="guests$ | async"
          [ctrl]="form.get('guestId')"
          placeholder="Change Guest"
        ></app-select-search>
      </div>
    </div>
  </form>
  <hr />
  <form [formGroup]="guestForm">
    <div class="row">
      <div class="col-md-12">
        <h6>Edit the current guest information:</h6>
      </div>

      <div class="col-md-12">
        <mat-form-field2 class="mat-block">
          <input type="text" placeholder="Guest Email" matInput formControlName="email" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let email of emails" [value]="email.email">
              {{ email.email }} <span *ngIf="email.primary">(Primary)</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="guestForm.get('email').touched && guestForm.get('email').hasError('required')">
            Guest email is <strong>required</strong>
          </mat-error>
          <mat-error
            *ngIf="
              guestForm.get('email').touched &&
              (guestForm.get('email').hasError('pattern') || guestForm.get('email').hasError('email'))
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-md-6">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="name" matInput required placeholder="Name" />
          <mat-error *ngIf="guestForm.get('name').touched && guestForm.get('name').hasError('required')">
            Guest name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="col-md-6">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="phone" matInput placeholder="Phone" />
        </mat-form-field2>
      </div>

      <div class="col-md-6" formGroupName="address">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="city" matInput placeholder="City" />
        </mat-form-field2>
      </div>

      <div class="col-md-6">
        <app-country-select
          [nameCtrl]="guestForm.get('address').get('country')"
          [codeCtrl]="guestForm.get('address').get('country_code')"
        ></app-country-select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(guestForm)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
