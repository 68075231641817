<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Room Details</div>
      <div class="col-sm-6 btn-holder">
        <span
          [matTooltip]="!(selectedAmenitiesCount$ | asyncSchedule | async) ? 'Please select at least one amenity.' : ''"
          matTooltipPosition="above"
        >
          <button
            type="button"
            class="btn btn-secondary-info ml-2"
            (click)="onSave(roomForm)"
            [disabled]="!(selectedAmenitiesCount$ | asyncSchedule | async)"
          >
            <i class="cs-icon cs-icon-save"></i> Save
          </button>
        </span>
      </div>
    </div>

    <div class="box-body">
      <div [formGroup]="baseRoomForm">
        <div class="row no-margin">
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input matInput required placeholder="Room Name" type="text" formControlName="name" />
            </mat-form-field2>
          </div>

          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="type" placeholder="Room Type" required>
                <mat-option *ngFor="let t of types" [value]="t">{{ t }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>

          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input matInput placeholder="License Number" type="text" formControlName="license" />
            </mat-form-field2>
          </div>
        </div>

        <div class="row no-margin">
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="sizeMeasurementUnit" placeholder="Size Metric" required>
                <mat-option value="sqft">SQFT</mat-option>
                <mat-option value="sqm">SQM</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <input
                type="text"
                matInput
                formControlName="sizeMeasurement"
                placeholder="Size ({{ baseRoomForm.get('sizeMeasurementUnit').value }}):"
                required
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="maxOccupancy" placeholder="Sleep Max" required>
                <mat-option *ngFor="let q of quantities; let i = index" [value]="i">{{ q }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="col-sm-3">
            <mat-form-field2 class="mat-block">
              <mat-select formControlName="nonSmoking" placeholder="Smoking">
                <mat-option [value]="0">Smoking Allowed</mat-option>
                <mat-option [value]="1">Smoking Not Allowed</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Room Amenities</div>
      <div class="col-sm-6 btn-holder"></div>
    </div>

    <div class="box-body">
      <div class="mx-3">
        <app-content-step-amenities-form
          [rental]="rental"
          [amenities]="rentalAmenityDetails"
          [form]="rentalAmenitiesForm"
        >
        </app-content-step-amenities-form>
      </div>
    </div>
  </div>
</div>

<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Specify Bedrooms</div>
      <div class="col-sm-6 btn-holder"></div>
    </div>

    <div class="box-body">
      <div *ngFor="let bedroom of bedrooms.controls; let i = index" [formGroup]="bedroom">
        <div class="mr-5 mb-1 bedroom-details">
          <div class="row no-margin">
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="roomType" placeholder="Room Type" required>
                  <mat-option [value]="'Bedroom'">Bedroom</mat-option>
                  <mat-option [value]="'Living Room'">Living room</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="privateBathroom" placeholder="Private Bathroom" required>
                  <mat-option [value]="1">Yes</mat-option>
                  <mat-option [value]="0">No</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="bedType" placeholder="Bed Type" required>
                  <mat-option *ngFor="let type of bedTypeOptions" [value]="type.index">{{ type.label }}</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
            <div class="col-sm-3">
              <mat-form-field2 class="mat-block">
                <mat-select formControlName="maxGuests" placeholder="Sleep Max" required>
                  <mat-option *ngFor="let q of quantities; let i = index" [value]="i + 1">{{ q }}</mat-option>
                </mat-select>
              </mat-form-field2>
            </div>
          </div>
          <a
            href="javascript:;"
            class="remove-btn"
            (click)="removeOneBedroom(i)"
            matTooltip="Remove room"
            matTooltipPosition="left"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </a>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-secondary-info ml-2 mt-2 mb-5 mr-5 float-right" (click)="addOneBedroom()">
          <i class="far fa-plus"></i> Add Room
        </button>
      </div>
    </div>
  </div>
</div>
