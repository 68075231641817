import { Component, Inject } from '@angular/core'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { AddNote } from '@tv3/store/note/note.actions'
import { GenericNoteDialog } from '../note-dialog.abstract'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: '../note-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['../note-dialog.component.scss'],
})
export class AddNoteDialogComponent extends GenericNoteDialog {
  isEdit = false

  constructor(
    public dialogRef: MatDialogRef<GenericNoteDialog>,
    public fb: FormBuilder,
    public store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super(dialogRef, fb)
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(AddNote({ inquiry: this.data.inquiry, form: form.getRawValue() }))
    this.close()
  }
}
