import { createAction, props } from '@ngrx/store'
import {
  ActivePlansByProduct,
  ActivePlanStatus,
  PlanOption,
  PlanSubscription,
  PlanTypes,
} from '@tv3/store/plan/plan.model'
import { Card } from '@tokeet-frontend/billing'

export const LoadActivePlans = createAction('[Plan] Load Account Subscription', props<{ fresh?: boolean }>())
export const LoadActivePlansComplete = createAction(
  '[Plan] Load Account Subscription Complete',
  props<{ card: Card; activePlans: ActivePlansByProduct; status: ActivePlanStatus; endedPlans: ActivePlansByProduct }>()
)
export const LoadPlansForAdvanceCM = createAction('[Plan] Load All Plans AdvanceCM')
export const LoadPlansForAdvanceCMComplete = createAction(
  '[Plan] Load All Plans AdvanceCM Complete',
  props<{ plans: PlanOption[] }>()
)

export const LoadPlansForProduct = createAction('[Plan] Load All Plans For Product', props<{ product: string }>())
export const LoadPlansForProductComplete = createAction(
  '[Plan] Load All Plans For Product Complete',
  props<{ plans: PlanOption[]; product: string }>()
)

export const SubscribePlanForAdvanceCM = createAction(
  '[Plan] Subscribe Plan For AdvanceCM',
  props<{ planId: string; planName: string }>()
)
export const SubscribePlanForAdvanceCMComplete = createAction(
  '[Plan] Subscribe Plan For AdvanceCM Complete',
  props<{ planSubscription: PlanSubscription }>()
)

export const SubscribePlanForProduct = createAction(
  '[Plan] Subscribe Plan For Product',
  props<{ planId: string; product: string; planName: string }>()
)
export const SubscribePlanForProductComplete = createAction(
  '[Plan] Subscribe Plan For Product Complete',
  props<{ planSubscription: PlanSubscription; product: string }>()
)

export const UnsubscribePlan = createAction(
  '[Plan] Unsubscribe Plan',
  props<{ planId: string; product: string; planName: string }>()
)
export const UnsubscribePlanComplete = createAction(
  '[Plan] Unsubscribe Plan Complete',
  props<{ planId: string; product: string; subscriptions: PlanSubscription[] }>()
)

export const SubscriptionMissing = createAction('[Subscription] Subscription Missing')
export const SubscriptionExpired = createAction(
  '[Subscription] Subscription Expired',
  props<{ currentPlanType: PlanTypes; util: number }>()
)
export const SubscriptionTrialLimited = createAction(
  '[Subscription] Subscription Trial Limited',
  props<{ currentPlanType: PlanTypes }>()
)
export const SubscriptionInvalidLimited = createAction('[Subscription] Subscription invalid Limited')
export const SubscriptionUserCountLimited = createAction(
  '[Subscription] Subscription User Count Limited',
  props<{ currentPlanType: PlanTypes; current: number; limit: number }>()
)
export const SubscriptionRentalCountLimited = createAction(
  '[Subscription] Subscription Rental Count Limited',
  props<{ currentPlanType: PlanTypes; current: number; limit: number }>()
)
export const SubscriptionAddonRentalCountLimited = createAction(
  '[Subscription] Subscription Addon Rental Count Limited',
  props<{ addonPlans: PlanSubscription[]; current: number }>()
)
export const SubscriptionFeatureAccessLimited = createAction(
  '[Subscription] Subscription Feature Access Limited',
  props<{ currentPlanType: PlanTypes; requiredPlanType: PlanTypes }>()
)
export const SubscriptionAddonFeatureAccessLimited = createAction(
  '[Subscription] Subscription Addon Feature Access Limited',
  props<{ addonId: string }>()
)
