import { on } from '@ngrx/store'
import * as R from 'ramda'
import { State } from '../channels.state'
import {
  GetHvmiStatusSuccess,
  ConnectHvmiSuccess,
  GetHvmiRentalValidationsSuccess,
  CreateHvmiListingSuccess,
  UpdateHvmiListingSuccess,
  ActivateHvmiListingSuccess,
  DeactivateHvmiListingSuccess,
  DeleteHvmiListingSuccess,
  GetHvmiListingsSuccess,
  DisconnectHvmiSuccess,
} from './actions'
import { HvmiListingModel } from './model'

export function getHVMIReducers() {
  return [
    on(GetHvmiStatusSuccess, (state: State, { status }) => ({
      ...state,
      hvmi: {
        ...state.hvmi,
        status,
      },
    })),
    on(ConnectHvmiSuccess, (state: State, { status, listings }) => ({
      ...state,
      hvmi: {
        ...state.hvmi,
        status,
        listings: listings || state.hvmi.listings || [],
      },
    })),
    on(GetHvmiRentalValidationsSuccess, (state: State, { validations }) => ({
      ...state,
      hvmi: {
        ...state.hvmi,
        validations,
        validationsLoaded: true,
      },
    })),
    on(
      CreateHvmiListingSuccess,
      UpdateHvmiListingSuccess,
      ActivateHvmiListingSuccess,
      DeactivateHvmiListingSuccess,
      (state: State, { listing }) => ({
        ...state,
        hvmi: {
          ...state.hvmi,
          listings: state.hvmi.listings.map((l) => (l.propertyId === listing.propertyId ? listing : l)),
        },
      })
    ),
    on(DeleteHvmiListingSuccess, (state: State, { listingId }) => ({
      ...state,
      hvmi: {
        ...state.hvmi,
        listings: state.hvmi.listings.map((l) =>
          l.listingId === listingId
            ? ({
                ...l,
                listingStatus: 'draft',
                listingId: null,
                lastSync: null,
                createdAt: null,
                hvmiStatus: null,
                hmcId: null,
              } as HvmiListingModel)
            : l
        ),
      },
    })),
    on(GetHvmiListingsSuccess, (state: State, { listings }) => ({
      ...state,
      hvmi: {
        ...state.hvmi,
        listings,
      },
    })),
    on(DisconnectHvmiSuccess, (state: State, { hmcId }) => {
      const hmcIds = state.hvmi.status.hmcIds?.filter((id) => id !== hmcId)
      return {
        ...state,
        hvmi: {
          ...state.hvmi,
          status: {
            ...state.hvmi.status,
            hmcIds,
            connected: !R.isEmpty(hmcIds),
          },
        },
      }
    }),
  ]
}
