<div class="modal-header">
  <h5 class="modal-title">Copy Rooms To</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <mat-stepper [linear]="true" #stepper>
    <mat-step label="Select Rooms to Copy to" [completed]="!!selectedRooms.length">
      <app-select-rooms-table
        [selectedRentalIds]="[rental.id]"
        (selected)="onSelectRooms($event)"
      ></app-select-rooms-table>
    </mat-step>
    <mat-step label="Select Rentals to Copy to" [completed]="!!selectedRentals.length">
      <app-select-rentals-table
        [excludedRentalIds]="[rental.id]"
        (selected)="onSelectRentals($event)"
      ></app-select-rentals-table>
    </mat-step>
    <ng-template matStepperIcon="edit">
      <i class="cs-icon cs-icon-check"></i>
    </ng-template>
    <ng-template matStepperIcon="done">
      <i class="cs-icon cs-icon-check"></i>
    </ng-template>
  </mat-stepper>
  <div *ngIf="isLoading">
    <div class="text-right">
      <i class="far fa-spin fa-spinner mr-1 text-primary"></i>
      {{ progress }}/{{ selectedRentals.length }}
    </div>
    <mat-progress-bar mode="determinate" [value]="(progress / selectedRentals.length) * 100"></mat-progress-bar>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-light" (click)="stepper.previous()" *ngIf="stepper.selectedIndex > 0">
    <i class="far fa-arrow-left"></i> Back
  </button>
  <button class="btn btn-outline-light" (click)="stepper.next()" *ngIf="stepper.selectedIndex < 1">
    Next <i class="far fa-arrow-right"></i>
  </button>

  <ng-container *ngIf="stepper.selectedIndex === 1">
    <button
      class="btn btn-secondary-success"
      [disabled]="!selectedRooms.length || !selectedRentals.length || isLoading"
      (click)="onOverwrite()"
      matTooltip="It will replace your current settings"
    >
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Overwrite
    </button>
    <button
      class="btn btn-secondary-info"
      [disabled]="!selectedRooms.length || !selectedRentals.length || isLoading"
      (click)="onAdd()"
      matTooltip="Selected settings will be added to your rental"
    >
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Copy
    </button>
  </ng-container>
</div>
