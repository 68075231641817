import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'

export interface SelectItemDialogConfig {
  title: string
  help: string
  buttonIcon?: string
  buttonText?: string
  iconRight?: boolean
  list: any[]
  placeholder?: string
  bindValue?: string
  bindLabel?: string
  multiple?: boolean
  value?: string | string[]
}

@Component({
  selector: 'app-select-item-dialog',
  templateUrl: './select-item-dialog.component.html',
  styleUrls: ['./select-item-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class SelectItemDialogComponent implements OnInit {
  config = {
    title: 'Select Item',
    help: 'Please select one item from the list above.',
    buttonText: 'Select',
    buttonIcon: 'cs-icon cs-icon-save',
    list: [],
    multiple: false,
    placeholder: 'Select',
  } as SelectItemDialogConfig

  form = this.fb.group({
    selected: [undefined, [Validators.required]],
  })

  constructor(
    public dialogRef: MatDialogRef<SelectItemDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { config: SelectItemDialogConfig }
  ) {}

  ngOnInit() {
    this.config = { ...this.config, ...this.data.config }
    this.form.get('selected').setValue(this.config.value)
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues.selected)
  }
}
