import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { of } from 'rxjs'
import { LoadCharges, LoadChargesComplete } from './billing.actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { BillingService } from './billing.service'

@Injectable()
export class BillingEffects {
  @Effect()
  loadCharges$ = this.actions$.pipe(
    ofType(LoadCharges),
    exhaustMap(() =>
      this.billingService.charges().pipe(
        map((billings) => LoadChargesComplete({ billings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private billingService: BillingService) {}
}
