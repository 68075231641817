<div class="tab-pane" role="tabpanel">
  <div class="box-holder">
    <ng-container *ngFor="let item of itemList; let i = index">
      <div class="box bg-default2 p-3 d-flex gap-2" *ngIf="i < showIndex">
        <app-avatar
          *ngIf="item[userNameKey] || (item[userId] | username) || defaultUserName || item.uiUserImage"
          [text]="item[userNameKey] || (item[userId] | username) || defaultUserName"
          [image]="item.uiUserImage"
          [size]="35"
        ></app-avatar>
        <div class="box-content flex-fill">
          <div class="text-bold d-flex align-items-center gap-2 position-relative">
            {{ item[userNameKey] || (item[userId] | username) || defaultUserName }}
            <span class="text-light text-sm text-normal-bold">{{ item[timeKey] | epoch : timeFormat }}</span>
            <span class="badge" *ngIf="badgeKey">{{ item[badgeKey] }}</span>
            <i [ngClass]="item.uiMainIcon"></i>
            <div class="flex-fill"></div>
            <div class="actions position-absolute" style="right: 0">
              <button
                class="btn btn-light-danger bg-default2"
                *ngIf="remove.observers.length > 0"
                (click)="remove.emit(item)"
              >
                <i class="cs-icon cs-icon-trash"></i>
              </button>
              <button
                class="btn btn-secondary-light bg-default2"
                *ngIf="edit.observers.length > 0"
                (click)="edit.emit(item)"
              >
                <i class="cs-icon cs-icon-pencil"></i>
              </button>
            </div>
          </div>
          <div class="my-3" style="word-break: break-all">{{ item[contentKey] }}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="" *ngIf="isMoreAvailable()">
    <button type="button" class="btn btn-outline-success mt-3" (click)="showMore()">Show More</button>
  </div>
</div>
