import { AddBillingNotificationsLocally, LoadNotificationsComplete } from './notification.actions'
import { adapter, initialState, State } from './notification.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadNotificationsComplete, (state, { notifications }) =>
    adapter.addMany(notifications, R.merge(state, { isLoaded: true }))
  ),
  on(AddBillingNotificationsLocally, (state, { notifications }) => adapter.addMany(notifications, state))
)

export function notificationReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
