import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BDCLicenseCheckResult } from '@tv3/store/channel-content/models/bdc.model'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { BDCContentPropertySettings } from '@tv3/store/channel-property-setting/settings/bdc-property-settings'
import { debounce } from 'lodash'

export function createForm(fb: FormBuilder) {
  return fb.group({
    legalEntityId: [],
    noLicenseRequired: [],
    licenseNumber: [],
    licenseIssueDate: [],
    licenseType: [],
  })
}

@Component({
  selector: 'app-bdc-content-step-license-form',
  templateUrl: './bdc-content-step-license-form.component.html',
  styleUrls: ['./bdc-content-step-license-form.component.scss'],
})
export class BdcContentStepLicenseFormComponent implements OnInit, OnChanges {
  constructor(private bdcContentService: BdcContentService) {}
  @Input() form: FormGroup
  @Input() rental: Rental
  @Input() settings: BDCContentPropertySettings

  checkLicenseResult: BDCLicenseCheckResult

  checkLicence = debounce(() => {
    this.bdcContentService
      .checkLicense(
        this.rental.gps.lat,
        this.rental.gps.long,
        this.rental.address.cc || this.rental.address.countryCode
      )
      .subscribe((res) => {
        this.checkLicenseResult = res
        this.updateLicenseValidity()
      })
  }, 1000)

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['settings'] && changes['settings'].currentValue) {
      this.form.patchValue({
        ...this.settings,
      })
    }

    if (changes['rental'] && changes['rental'].currentValue) {
      this.checkLicence()
    }
  }

  updateLicenseValidity() {
    if (!this.checkLicenseResult) {
      return
    }
    let validators = []
    if (this.form.get('noLicenseRequired').value) {
      validators = [Validators.pattern(this.checkLicenseResult.regex_pattern)]
    } else if (this.checkLicenseResult.required) {
      validators = [Validators.pattern(this.checkLicenseResult.regex_pattern), Validators.required]
    }
    this.form.get('licenseNumber').setValidators(validators)
    this.form.updateValueAndValidity()
  }
}
