<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput required placeholder="Email" type="email" formControlName="email" />
            <mat-hint>Your property will be created under this email.</mat-hint>
          </mat-form-field2>
        </div>
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <input matInput required placeholder="Name" type="text" formControlName="name" />
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
              Name must be at least <strong>2</strong> characters long
            </mat-error>
            <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('pattern')">
              Name must be alpha characters
            </mat-error>
            <mat-hint align="end">{{ form.get('name').value?.length || 0 }}/50</mat-hint>
          </mat-form-field2>
        </div>
      </div>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput required placeholder="Address" type="text" formControlName="address" />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="City" type="text" formControlName="city" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="State / Province / Region" type="text" formControlName="state" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Postal Code" type="text" formControlName="postal_code" required />
      </mat-form-field2>
    </div>
    <div formGroupName="address" class="col-sm-6">
      <ng-select
        [items]="countryList"
        [selectOnTab]="true"
        [clearable]="false"
        bindLabel="name"
        bindValue="id"
        formControlName="country_code"
        placeholder="Country"
        appendTo="body"
        class="mat-block"
        required
      >
      </ng-select>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput required placeholder="Phone" type="text" formControlName="phone" />
      </mat-form-field2>
    </div>
  </div>
</form>
