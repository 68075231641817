import { User } from './user.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { pathOr } from 'ramda'
import { Role } from './user.interfaces'

export interface State extends EntityState<User> {
  isLoaded: boolean
  isRolesLoaded: boolean
  isUpdating: boolean
  roles: Role[]
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: (a: User, b: User) =>
    pathOr('', ['name'], a)
      .toString()
      .localeCompare(pathOr('', ['name'], b), undefined, { numeric: true, sensitivity: 'base' }),
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isRolesLoaded: false,
  isUpdating: false,
  roles: [
    {
      label: 'Read-Only',
      value: '0.1',
      tooltip: 'The Read-Only cannot make any changes on AdvanceCM rental management interface.',
    },
    {
      label: 'Administrators',
      value: '1',
      tooltip:
        'The Administrator has complete control and visibility over the entire AdvanceCM rental management interface.',
    },
    {
      value: '1.1',
      label: 'Property Managers',
      tooltip:
        'Property Manager users get full access to all menus and modules for the rentals assigned by the Administrator.',
    },
    {
      value: '1.2',
      label: 'Accounting',
      tooltip: 'Accounting users only have access to the Reports module.',
    },
    {
      label: 'House Staff',
      value: '1.5',
      tooltip:
        'House Staff users have access to the Calendar module only.  House Staff cannot see price details, cannot modify Inquiries, and cannot create Booking or Hold Events.',
    },
    {
      label: 'Property Owner',
      value: '1.6',
      tooltip:
        'Property Owner users can access the AdvanceCM Calendar and Reports modules with varying degrees of detail, based on their role and level of access.',
    },
  ],
})
