import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import { concatMap, map, tap, toArray } from 'rxjs/operators'
import { Template, TemplateAddRequest, TemplateResponse, TemplateUpdateRequest } from './template.model'

@Injectable()
export class TemplateService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all(): Observable<Template[]> {
    const url = '@api/template/all/'

    return this.http.get<TemplateResponse[]>(url).pipe(deserializeArray<Template>(Template))
  }

  get(templateId: string): Observable<Template> {
    const url = `@api/template/${templateId}`
    return this.http.get(url).pipe(map((response) => Template.deserialize(response)))
  }

  add(request: TemplateAddRequest, options = { silent: false }): Observable<Template> {
    const url = '@api/template/'

    return this.http.post(url, request).pipe(
      map((response) => Template.deserialize(response)),
      tap(() => {
        if (!options.silent) {
          this.toast.success('Template created successfully.')
        }
      })
    )
  }

  update(id: string, request: TemplateUpdateRequest, options = { silent: false }): Observable<Template> {
    const url = `@api/template/update/${id}`

    return this.http.put(url, request).pipe(
      map((response) => Template.deserialize(response)),
      tap(() => {
        if (!options.silent) {
          this.toast.success('Template updated successfully.')
        }
      })
    )
  }

  delete(id: string): Observable<string> {
    const url = `@api/template/delete/${id}`

    return this.http.delete<string>(url).pipe(map(() => id))
  }

  deleteMultiple(ids: string[]): Observable<string[]> {
    return of(...ids).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }
}
