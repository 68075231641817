import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ChannelListingWizardsService } from './index.service'
import { AirbnbListingWizardModule } from './airbnb-listing-wizard/airbnb-listing-wizard.module'
import { BdcListingWizardModule } from './bdc-listing-wizard/bdc-listing-wizard.module'
import { AgodaListingWizardModule } from './agoda-listing-wizard/agoda-listing-wizard.module'
import { ExpediaListingWizardModule } from './expedia-listing-wizard/expedia-listing-wizard.module'
import { TiketListingWizardModule } from './tiket-listing-wizard/tiket-listing-wizard.module'
import { TripListingWizardModule } from './trip-listing-wizard/trip-listing-wizard.module'

const components = []

const modules = [
  AirbnbListingWizardModule,
  BdcListingWizardModule,
  AgodaListingWizardModule,
  ExpediaListingWizardModule,
  TiketListingWizardModule,
  TripListingWizardModule,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  providers: [ChannelListingWizardsService],
  imports: [CommonModule, ...modules],
})
export class ChannelListingWizardsModule {}
