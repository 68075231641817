import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { SelectInvoiceTemplateDialogComponent } from '@tv3/containers/invoices/templates/select-invoice-template/select-invoice-template.component'

@Injectable()
export class SelectInvoiceTemplateDialogService {
  constructor(private dialog: MatDialog) {}

  open(selectBooking = false) {
    let defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { selectBooking },
    }

    return this.dialog.open(SelectInvoiceTemplateDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
