import { adapter, initialState, State } from './invoice.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddInvoiceComplete,
  ArchiveInvoice,
  ArchiveInvoiceComplete,
  DeleteInvoiceComplete,
  DeleteLocalInvoicesByIds,
  DisableInvoiceOnlinePayment,
  DisableInvoiceOnlinePaymentComplete,
  EnableInvoiceOnlinePayment,
  EnableInvoiceOnlinePaymentComplete,
  FetchInvoicesComplete,
  LoadInvoiceComplete,
  LoadInvoicesComplete,
  LoadPastDueInvoicesComplete,
  PayInvoiceWithSavedCard,
  PayInvoiceWithSavedCardComplete,
  RefundInvoice,
  RefundInvoiceComplete,
  ScheduleInvoice,
  ScheduleInvoiceComplete,
  SearchElasticInvoicesComplete,
  SendInvoice,
  SendInvoiceComplete,
  SetInvoicePaymentGateway,
  SetInvoicePaymentGatewayComplete,
  UnArchiveInvoice,
  UnArchiveInvoiceComplete,
  UpdateInvoice,
  UpdateInvoiceAddress,
  UpdateInvoiceAddressComplete,
  UpdateInvoiceComplete,
  UpdateInvoiceStatus,
  UpdateInvoiceStatusComplete,
  AddInvoice,
  DeleteInvoicesComplete,
  SendInvoicesComplete,
} from './invoice.actions'
import { updateMany, updateOne, upsertMany } from '@tokeet-frontend/tv3-platform'
import { ApplyInvoiceTemplateComplete } from '../../store/templates/template.actions'
import { ArchiveInvoicesComplete, UnArchiveInvoicesComplete } from '@tokeet-frontend/invoices'

export const reducer = createReducer(
  initialState,
  on(LoadInvoicesComplete, (state, { invoices }) => upsertMany(adapter, invoices, R.merge(state, { isLoaded: true }))),
  on(LoadPastDueInvoicesComplete, SearchElasticInvoicesComplete, FetchInvoicesComplete, (state, { invoices }) =>
    upsertMany(adapter, invoices, state)
  ),
  on(AddInvoiceComplete, LoadInvoiceComplete, (state, { invoice }) =>
    adapter.addOne(invoice, { ...state, isUpdating: false })
  ),
  on(ArchiveInvoicesComplete, UnArchiveInvoicesComplete, SendInvoicesComplete, (state, { update }) =>
    updateMany(adapter, update, state)
  ),
  on(
    PayInvoiceWithSavedCardComplete,
    SetInvoicePaymentGatewayComplete,
    EnableInvoiceOnlinePaymentComplete,
    DisableInvoiceOnlinePaymentComplete,
    UpdateInvoiceComplete,
    UpdateInvoiceStatusComplete,
    UpdateInvoiceAddressComplete,
    ScheduleInvoiceComplete,
    (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(
    ArchiveInvoiceComplete,
    UnArchiveInvoiceComplete,
    SendInvoiceComplete,
    RefundInvoiceComplete,
    (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(
    PayInvoiceWithSavedCard,
    SetInvoicePaymentGateway,
    EnableInvoiceOnlinePayment,
    DisableInvoiceOnlinePayment,
    AddInvoice,
    UpdateInvoice,
    UpdateInvoiceStatus,
    UpdateInvoiceAddress,
    (state) => ({ ...state, isUpdating: true })
  ),
  on(ScheduleInvoice, RefundInvoice, ArchiveInvoice, UnArchiveInvoice, SendInvoice, (state) => ({
    ...state,
    isUpdating: true,
  })),
  on(DeleteInvoiceComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteLocalInvoicesByIds, DeleteInvoicesComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(ApplyInvoiceTemplateComplete, (state, { invoice }) => adapter.addOne(invoice, state))
)

export function invoiceReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
