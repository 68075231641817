import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { GVRListingModel, GVRStatus, GVRListingPayload } from './model'
import { ListingsValidationFieldResult, ListingValidationFieldResultItem } from '../rental-validation-field'

@Injectable({
  providedIn: 'root',
})
export class GVRChannelService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  // getListingsStatuses() {
  //   const url = `@api/gvr/listings/status`

  //   return this.http.get<any>(url)
  // }

  connect() {
    const url = `@api/gvr/connection/connect`

    return this.http.post(url, {})
  }
  getStatus() {
    const url = `@api/gvr/connection/status`

    return this.http.get<GVRStatus>(url)
  }

  activateListing(listingId: string) {
    const url = `@api/gvr/listing/status`

    return this.http.put(url, { listingId, active: true })
  }

  deactivateListing(listingId: string) {
    const url = `@api/gvr/listing/status`

    return this.http.put(url, { listingId, active: false })
  }

  // deleteListing(listingId: number) {
  //   const url = `@api/gvr/listings/${listingId}`

  //   return this.http.delete<{ listingId: number }>(url)
  // }

  getConnections() {
    const url = `@api/gvr/listing/tokeet`

    return this.http.get<GVRListingModel[]>(url)
  }

  getListings() {
    const url = `@api/gvr/listing/all`

    return this.http.get<GVRListingModel[]>(url)
  }

  createListing(listing: GVRListingPayload) {
    const url = `@api/gvr/listing`

    return this.http.post<GVRListingModel>(url, listing)
  }

  updateListing(listing: GVRListingPayload) {
    const url = `@api/gvr/listing`

    return this.http.post<GVRListingModel>(url, listing)
  }

  disconnect() {
    const url = `@api/gvr/connection/disconnect`

    return this.http.post(url, {})
  }

  pushRates(listingId: string) {
    const url = `@api/gvr/listings/${listingId}/rates/push`
    return this.http.post(url, {})
  }

  pushAvailability(listingId: string) {
    const url = `@api/gvr/listings/${listingId}/availability/push`
    return this.http.post(url, {})
  }

  getRentalValidations() {
    const url = `@api/gvr/listing/validate/rentals`

    return this.http.post<ListingsValidationFieldResult>(url, {})
  }

  getRentalValidation(payload: Omit<GVRListingPayload, 'cancellationPolicyType'>) {
    const url = `@api/gvr/listing/validate/rental`
    return this.http.post<ListingValidationFieldResultItem[]>(url, payload)
  }
}
