import { Injectable } from '@angular/core'
import * as lodash from 'lodash'
import { isFunction } from 'lodash'
import * as R from 'ramda'
import { Guest } from '@tv3/store/guest/guest.model'
import { DateDiffPipe } from '@tv3/pipes/utility/date-diff.pipe'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import {
  Account,
  DataCheckerService,
  formatDate,
  isSomething,
  Rental,
  RentalService,
  toMoment,
} from '@tokeet-frontend/tv3-platform'
import { Website } from '@tv3/store/website/website.model'
import { CurrencyPipe } from '@angular/common'
import { isObservable, Observable, of } from 'rxjs'
import { catchError, concatMap, map, mapTo, switchMap, take, tap, toArray } from 'rxjs/operators'
import { CustomCode } from '@tv3/store/custom-codes/custom-code.model'
import { DiscountCode, DiscountCodeService, DiscountCodeType } from '@tokeet-frontend/discount-codes'
import { select, Store } from '@ngrx/store'
import { selectAllCustomCodes } from '@tv3/store/custom-codes/custom-code.selectors'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { BookingCostResolver } from '@tokeet/cost-resolver'
import { CustomCodeGuard } from '@tv3/guards/custom-code.guard'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { SmartDeviceAccess } from '@tokeet-frontend/smart-devices'

export interface DataDictItemDef {
  type: string
  value: string | Function
  tooltip?: string
}

interface DataDictResolveParams {
  account?: Account
  rental?: Rental
  inquiry?: Inquiry
  websites?: Website[]
  guest?: Guest
  accesses?: SmartDeviceAccess[]
}

const handleLineBreaks = (text) => (text || '').replace(/\r?\n/g, '<br />')

@Injectable({
  providedIn: 'root',
})
export class DataDictService {
  interpolate = /(\*\|[\s\S]+?\|\*)/gi

  dictDefs: { [key: string]: DataDictItemDef } = {
    '*|CONTRACT:SIGNATURE|*': {
      type: 'contract',
      value: '[sign]',
    },
    '*|CONTRACT:COUNTERSIGN|*': {
      type: 'contract',
      value: '[countersign]',
    },
    '*|CONTRACT:DATE|*': {
      type: 'contract',
      value: '[date]',
    },

    '*|GUEST:NAME|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ guest }: DataDictResolveParams) => guest.name || '',
    },
    '*|GUEST:FNAME|*': {
      type: 'guest',
      tooltip: `Will show only guest's first name in the message.`,
      value: ({ guest }: DataDictResolveParams) => R.head((guest.name || '').split(' ')),
    },
    '*|GUEST:EMAIL|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ guest }: DataDictResolveParams) => guest.primaryEmail || '',
    },
    '*|GUEST:PHONE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry (if provided) and is stored in the guest record.',
      value: ({ guest }: DataDictResolveParams) => guest.phone || '',
    },
    '*|GUEST:guest_id|*': {
      type: 'guest',
      tooltip: `The AdvanceCM internal id of the guest`,
      value: ({ guest }: DataDictResolveParams) => guest.id,
    },
    '*|GUEST:ADDRESS|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => (guest.address && guest.address.address) || '',
    },
    '*|GUEST:CITY|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => (guest.address && guest.address.city) || '',
    },
    '*|GUEST:COUNTRY|*': {
      type: 'guest',
      tooltip: 'This is stored in the guest record (if provided).',
      value: ({ guest }: DataDictResolveParams) => (guest.address && guest.address.country) || '',
    },
    '*|GUEST:SOURCE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the associated inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.inquirySource || '',
    },
    '*|GUEST:BIRTHDAY|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.birthday || '',
    },
    '*|GUEST:SPOUSE|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.wife || '',
    },
    '*|GUEST:HOBBY|*': {
      type: 'guest',
      tooltip: 'Will be pulled from the guest record which you can edit to include these details.',
      value: ({ guest }: DataDictResolveParams) => guest.hobby || '',
    },
    '*|GUEST:RAND4|*': {
      type: 'guest',
      tooltip: 'Include a random 4 digit code which can be used to program smart locks.',
      value: () => lodash.random(1000, 9999),
    },
    '*|GUEST:RAND6|*': {
      type: 'guest',
      tooltip: 'Include a random 6 digit code which can be used to program smart locks.',
      value: () => lodash.random(100000, 999999),
    },

    // Inquiry data

    '*|INQUIRY:INQUIRY_ID|*': {
      type: 'inquiry',
      tooltip: 'This is imported from the listing channel and can be found in the notes section of the inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.id || inquiry.bookingId,
    },
    '*|INQUIRY:CHANNEL|*': {
      type: 'inquiry',
      tooltip: 'The channel where we imported the associated inquiry from.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.inquirySource || null,
    },
    '*|INQUIRY:ARRIVE|*': {
      type: 'inquiry',
      tooltip: 'The date that the guest is checking in.',
      value: ({ inquiry }: DataDictResolveParams) => formatDate(inquiry.guestArrive, 'date', true),
    },
    '*|INQUIRY:DEPART|*': {
      type: 'inquiry',
      tooltip: 'The date the guest is due to check-out.',
      value: ({ inquiry }: DataDictResolveParams) => formatDate(inquiry.guestDepart, 'date', true),
    },
    '*|INQUIRY:CHECK_IN|*': {
      type: 'inquiry',
      tooltip: 'The check-in time set for this rental under your rental Instructions tab in the rental details.',
      value: ({ inquiry, rental, account }: DataDictResolveParams) => {
        const checkInTime = RentalService.getCheckInTime(rental, inquiry.checkIn, account)
        return checkInTime.format('H:mm')
      },
    },
    '*|INQUIRY:CHECK_OUT|*': {
      type: 'inquiry',
      tooltip: 'The check-out time set for this rental under your rental instructions tab in the rental details.',
      value: ({ inquiry, rental, account }: DataDictResolveParams) => {
        const checkOutTime = RentalService.getCheckOutTime(rental, inquiry.checkOut, account)
        return checkOutTime.format('H:mm')
      },
    },
    '*|INQUIRY:COST|*': {
      type: 'inquiry',
      tooltip: 'The booking total which is shown on the associated inquiry.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        const code = R.pathOr('USD', ['currency', 'code'], rental)
        let total
        if (inquiry.bookingEngine) {
          total = inquiry.bookingEngine.total
        } else {
          const cost = BookingCostResolver.parseBookingCharges(inquiry as any)
          total = cost?.sum
        }
        return new CurrencyPipe('en').transform(total, code, 'symbol')
      },
    },
    '*|INQUIRY:BOOKING_FORMULA|*': {
      type: 'inquiry',
      tooltip: 'Booking costs were adjusted via the booking formula set on the channel.',
      value: ({ inquiry, rental }: DataDictResolveParams) => {
        const code = R.pathOr('USD', ['currency', 'code'], rental)
        return this.inquiryService
          .getCostAfterFormula(inquiry.id)
          .pipe(map((total) => new CurrencyPipe('en').transform(total, code, 'symbol')))
      },
    },
    '*|INQUIRY:NIGHTS|*': {
      type: 'inquiry',
      tooltip: 'The number of nights the guest is staying.',
      value: ({ inquiry }: DataDictResolveParams) =>
        this.dateDiffPipe.transform(inquiry.guestDepart, inquiry.guestArrive, true),
    },
    '*|INQUIRY:ADULTS|*': {
      type: 'inquiry',
      tooltip: 'The number of adults which is stored in the inquiry.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.numAdults || 0,
    },
    '*|INQUIRY:CHILDREN|*': {
      type: 'inquiry',
      tooltip: 'The number of Children set in the inquiry details.',
      value: ({ inquiry }: DataDictResolveParams) => inquiry.numChild || 0,
    },
    '*|INQUIRY:BOOK_DATE|*': {
      type: 'inquiry',
      tooltip: 'This is the date the booking was imported by AdvanceCM.',
      value: ({ inquiry }: DataDictResolveParams) => (inquiry.booked ? formatDate(inquiry.booked) : ''),
    },
    '*|INQUIRY:GUEST_PORTAL|*': {
      type: 'inquiry',
      tooltip: 'Include a URL directing the guest to your guest portal.',
      value: ({ inquiry, websites }: DataDictResolveParams) => {
        if (!isSomething(websites)) {
          return ''
        }
        const portal = R.last(R.filter((w) => !!w.isPortal, websites))
        if (isSomething(portal)) {
          return `<a href="https://${portal.domain}/authorize/${inquiry.account}/${inquiry.id}/${inquiry.guestId}/${inquiry.created}">Click here</a>`
        } else {
          return ''
        }
      },
    },
    '*|INQUIRY:SECURITY_DOOR_CODE|*': {
      type: 'inquiry',
      tooltip: 'Door code for smart lock.',
      value: ({ inquiry }: DataDictResolveParams) =>
        this.inquiryService.getSecurityDoorCode(inquiry.id).pipe(map((t) => t?.door_code || '')),
    },
    '*|INQUIRY:SECURITY_SMART_DEVICES|*': {
      type: 'inquiry',
      tooltip: 'Codes for linked smart devices.',
      value: ({ accesses }: DataDictResolveParams) => {
        return lodash
          .map(accesses, (t) => {
            const start = formatDate(t.access.start, 'MMM DD • hh:mm A')
            const end = formatDate(t.access.end, 'MMM DD • hh:mm A')
            return `${t.device_name}: ${t.access.code} (${start} ~ ${end})`
          })
          .join(', ')
      },
    },

    // Rental data

    '*|RENTAL:NAME|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental name.',
      value: ({ rental }: DataDictResolveParams) => rental.name || '',
    },
    '*|RENTAL:DISPLAY_NAME|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental display name',
      value: ({ rental }: DataDictResolveParams) => rental.displayName || '',
    },
    '*|RENTAL:PHONE|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental phone number. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.phone || '',
    },
    '*|RENTAL:EMAIL|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental email. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.email || '',
    },
    '*|RENTAL:ZIP|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental zip code. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) =>
        (rental.address && (rental.address.postalCode || rental.address.zip)) || '',
    },
    '*|RENTAL:ADDRESS|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental address. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.address) || '',
    },
    '*|RENTAL:CITY|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental city. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.city) || '',
    },
    '*|RENTAL:STATE|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental state. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.state) || '',
    },
    '*|RENTAL:COUNTRY|*': {
      type: 'rental',
      tooltip: 'The AdvanceCM rental country. Pulled from the Basic Info section of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.address && rental.address.country) || '',
    },
    '*|RENTAL:DESCRIPTION|*': {
      type: 'rental',
      tooltip: 'Pulled from the description field on the Detail Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => rental.description || '',
    },
    '*|RENTAL:TERMS|*': {
      type: 'rental',
      tooltip: '',
      value: ({ rental }: DataDictResolveParams) => rental.paymentTerms || '',
    },
    '*|RENTAL:CUSTOM1|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 1 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom1),
    },
    '*|RENTAL:CUSTOM2|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 2 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom2),
    },
    '*|RENTAL:CUSTOM3|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 3 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom3),
    },
    '*|RENTAL:CUSTOM4|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 4 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom4),
    },
    '*|RENTAL:CUSTOM5|*': {
      type: 'rental',
      tooltip: 'Pulled from the Custom Information 5 field on the Custom Information tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => handleLineBreaks(rental.custom5),
    },
    '*|RENTAL:CHECKIN_INSTRUCTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Check-in Instructions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.checkin) || '',
    },
    '*|RENTAL:CHECKOUT_INSTRUCTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Checkout Instructions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.checkout) || '',
    },
    '*|RENTAL:HOUSE_RULES|*': {
      type: 'rental',
      tooltip: 'Pulled from the House Rules field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.rules) || '',
    },
    '*|RENTAL:DIRECTIONS|*': {
      type: 'rental',
      tooltip: 'Pulled from the Directions field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.instructions && rental.instructions.directions) || '',
    },
    '*|RENTAL:PAYMENT_INSTRUCTIONS|*': {
      type: 'rental',
      value: ({ rental }: DataDictResolveParams) => (rental.paymentInstructions || '').replace(/\n/gi, '<br/>'),
    },
    '*|RENTAL:WIFI_NAME|*': {
      type: 'rental',
      tooltip: 'Pulled from the WiFi name field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.wifi_name) || '',
    },
    '*|RENTAL:WIFI|*': {
      type: 'rental',
      tooltip: 'Pulled from the WiFi password field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.wifi_pass) || '',
    },
    '*|RENTAL:KEYPICKUP|*': {
      type: 'rental',
      tooltip: 'Pulled from the Key Pickup field on the Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.key_pickup) || '',
    },
    '*|RENTAL:SECURITY|*': {
      type: 'rental',
      tooltip: 'Pulled from the Codes and Passwords section on your Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.sec_code) || '',
    },
    '*|RENTAL:SPECIAL|*': {
      type: 'rental',
      tooltip: 'Pulled from the Special Instructions field on Rental Instructions tab of Rental Details.',
      value: ({ rental }: DataDictResolveParams) => (rental.specifics && rental.specifics.special_inst) || '',
    },
    '*|RENTAL:WEBSITES|*': {
      type: 'rental',
      tooltip: 'Will include the URL for the tokeet website this rental is associated with.',
      value: ({ websites }: DataDictResolveParams) => {
        return R.reduce(
          (acc, web) => {
            acc += '<a href="https://' + web.domain + '" target="_blank">' + web.title + '</a>&nbsp;'
            return acc
          },
          '',
          websites
        )
      },
    },
    // Device
    '*|DEVICE:NAME|*': {
      type: 'device',
      value: ({ accesses }: DataDictResolveParams) => this.getPrimaryDeviceAccess(accesses)?.device_name || '',
    },
    '*|DEVICE:RENTAL|*': {
      type: 'device',
      value: ({ rental }: DataDictResolveParams) => rental.name || '',
    },
    '*|DEVICE:CODE|*': {
      type: 'device',
      value: ({ accesses }: DataDictResolveParams) => this.getPrimaryDeviceAccess(accesses)?.access?.code || '',
    },
    '*|DEVICE:CODE_STARTDATE|*': {
      type: 'device',
      value: ({ accesses }: DataDictResolveParams) =>
        formatDate(this.getPrimaryDeviceAccess(accesses)?.access?.start, 'datetime', true, ''),
    },
    '*|DEVICE:CODE_ENDDATE|*': {
      type: 'device',
      value: ({ accesses }: DataDictResolveParams) =>
        formatDate(this.getPrimaryDeviceAccess(accesses)?.access?.end, 'datetime', true, ''),
    },

    // Discount
    '*|DISCOUNT:5|*': {
      type: 'discount',
      value: () => '',
    },
    '*|DISCOUNT:10|*': {
      type: 'discount',
      value: () => '',
    },
    '*|DISCOUNT:15|*': {
      type: 'discount',
      value: () => '',
    },
    '*|DISCOUNT:20|*': {
      type: 'discount',
      value: () => '',
    },
  }

  constructor(
    private store: Store<any>,
    private dateDiffPipe: DateDiffPipe,
    private dataCheckerService: DataCheckerService,
    private discountCodeService: DiscountCodeService,
    private inquiryService: InquiryService
  ) {
    this.dataCheckerService.check([CustomCodeGuard])
  }

  private getPrimaryDeviceAccess(items: SmartDeviceAccess[]) {
    const device = lodash.find(items, (t) => !!t.is_primary)
    return device ? device : lodash.head(items)
  }

  getAvailableKeys(): { [type: string]: { value: string; tooltip?: string }[] } {
    return lodash.groupBy(
      lodash.map(this.dictDefs, (def: DataDictItemDef, key: string) => {
        return {
          type: def.type,
          value: key,
          tooltip: def.tooltip,
        }
      }),
      'type'
    )
  }

  getDiscountToken(token: string): { amount: number; type: DiscountCodeType } {
    const match = /^\*\|DISCOUNT:(-?[0-9]+)(%?)\|\*$/.exec(token.trim())
    if (!match) {
      return null
    }
    const [t, amount, type] = match
    return { amount: parseFloat(amount), type: type === '%' ? DiscountCodeType.Percent : DiscountCodeType.Flat }
  }

  resolveDiscount({ amount, type }, { guest, rental }: DataDictResolveParams): Observable<string> {
    return (
      this.discountCodeService
        // @ts-ignore
        .create({
          amount,
          type,
          max_uses: 1,
          email: guest.primaryEmail,
          name: `Discount for ${guest.name}`,
          description: `Created automatically in message.`,
          start: toMoment().startOf('day').unix(),
          expires: toMoment().add(1, 'year').unix(),
          rental_id: rental.id,
        })
        .pipe(
          map((discount: DiscountCode) => {
            return `${discount.code}`
          }),
          catchError((error) => {
            this.store.dispatch(ActionFailed({ error }))
            return of('')
          })
        )
    )
  }

  getAllDataTokensInText(text): string[] {
    let m
    const tokens = []
    do {
      m = this.interpolate.exec(text)
      if (m) {
        tokens.push(m[1])
      }
    } while (m)

    return tokens
  }

  resolveTokens(tokens: string[], params: DataDictResolveParams): Observable<{ [key: string]: any }> {
    return this.store.pipe(
      select(selectAllCustomCodes),
      take(1),
      switchMap((codes) => {
        const dataTokenResolver = this.resolver(params, codes)
        const tokenValuePairs = {}
        return of(...tokens).pipe(
          concatMap((token) => {
            const discountCodeToken = this.getDiscountToken(token)
            let ob: Observable<any>
            if (discountCodeToken) {
              ob = this.resolveDiscount(discountCodeToken, params)
            } else {
              const resolver = dataTokenResolver(token)
              ob = isObservable(resolver) ? resolver : of(resolver)
            }
            return ob.pipe(
              catchError(() => of('')),
              tap((v) => (tokenValuePairs[token] = v))
            )
          }),
          toArray(),
          mapTo(tokenValuePairs)
        )
      })
    )
  }

  resolve(text, params: DataDictResolveParams): Observable<string> {
    text = text || ''
    if (!text) return of('')

    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
    }

    const tokens = this.getAllDataTokensInText(text)

    return this.resolveTokens(tokens, params).pipe(
      map((pairs) => {
        return lodash.reduce(
          tokens,
          (t, token) => {
            return t.replace(new RegExp(escapeRegExp(token), 'g'), lodash.isNil(pairs[token]) ? '' : pairs[token])
          },
          text
        )
      })
    )
  }

  private resolver(params: DataDictResolveParams, codes: CustomCode[]) {
    return (key) => {
      const item = this.dictDefs[key]
      if (item) {
        if (isFunction(item.value)) {
          return item.value(params)
        }
        return item.value
      } else {
        const customCode = R.find(
          (c) => c.nameFormatted === key && (R.isEmpty(c.rentals) || R.contains(params.rental.id, c.rentals)),
          codes
        )
        if (R.isNil(customCode)) {
          return
        }
        return customCode.body
      }
    }
  }
}
