<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-truncate" [matTooltip]="rental.name" matTooltipPosition="below">
        Delete Rental - {{ rental.name }}
      </h5>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box border p-4">
        <div>
          You are attempting to delete a rental. In order to delete a rental from AdvanceCM you must first delete any
          hold events, inquiries, bookings and website associations before doing so. Please use the options below to
          complete these tasks. Please note that all associated hold events, bookings, invoices and messages will be
          purged and will not be recoverable. This will also impact data shown in your reports as these items will no
          longer be accessible for calculation by the reporting module. Please acknowledge that you are fully aware of
          the consequences by selecting checkboxes and then use the buttons below to initiate the deletion of these
          items.
        </div>

        <div class="mt-3">
          <ol class="notes-list">
            <li>
              This rental will be disconnected from all API channels. If the channel connection is no longer valid it
              should be removed from AdvanceCM.
            </li>
            <li>All rental images and other associated data will be removed.</li>
            <li>All imported and exported iCal calendars will be deleted.</li>
            <li>This rental will no longer be displayed on distribution channels like Marriott and HomeToGo.</li>
            <li>You must remove all bookings, hold events, and website associations related to this rental.</li>
            <li>This rental will be deactivated in all connected Tokeet and partner applications.</li>
          </ol>
        </div>

        <div class="mt-5">
          <div class="actions-list-title">Some of these services can be deleted from your account individually</div>
          <div class="actions-list row">
            <div class="col-sm-6">
              <div class="action-item" [class.disabled]="!tokeetRentalData.holdEvents || isHoldDeleted">
                <i class="far fa-calendar-times action-icon"></i>
                <div class="action-info-wrapper">
                  <div class="action-title">Hold Events</div>
                  <div class="action-description">{{ tokeetRentalData.holdEvents }} Hold Events Created</div>
                </div>
                <div>
                  <i class="fas fa-trash action-btn" (click)="onDeleteHold()"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="action-item" [class.disabled]="!tokeetRentalData.bookings || isBookingsDeleted">
                <i class="far fa-calendar-check action-icon"></i>
                <div class="action-info-wrapper">
                  <div class="action-title">Inquiries & Bookings</div>
                  <div class="action-description">{{ tokeetRentalData.bookings }} Inquiries & Bookings Created</div>
                </div>
                <div>
                  <i class="fas fa-trash action-btn" (click)="onDeleteBookings()"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="action-item" [class.disabled]="!tokeetRentalData.websites || isWebsitesDeleted">
                <i class="far fa-browser action-icon"></i>
                <div class="action-info-wrapper">
                  <div class="action-title">Websites Associations</div>
                  <div class="action-description">{{ tokeetRentalData.websites }} Website Associations</div>
                </div>
                <div>
                  <i class="fas fa-trash action-btn" (click)="onDeleteWebsites()"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="action-item" [class.disabled]="!webreadyRentalData.rental || isWebreadyRentalDeleted">
                <i class="far fa-globe action-icon"></i>
                <div class="action-info-wrapper">
                  <div class="action-title">Rental in Webready</div>
                  <div class="action-description">
                    Used in {{ webreadyRentalData.websites }} Websites, contains {{ webreadyRentalData.images }} Images
                  </div>
                </div>
                <div>
                  <i class="fas fa-trash action-btn" (click)="onDeleteWebreadyData()"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mt-5">
              <div class="row">
                <div class="offset-sm-3"></div>
                <div class="col-sm-6">
                  <div class="action-item delete-rental-item" [class.disabled]="!isAllDeleted">
                    <i class="far fa-building action-icon"></i>
                    <div class="action-info-wrapper">
                      <div class="action-title">Delete Whole Rental</div>
                      <div class="action-description">All Service Associated with this rental will be deleted</div>
                    </div>
                    <div>
                      <i class="fas fa-trash action-btn" (click)="onDelete()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
