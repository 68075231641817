<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">{{ config.title }}</h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <app-select-search
          bindLabel="propertyId"
          [items]="accounts$ | async"
          [ctrl]="form.get('account')"
          [multiple]="false"
          [inline]="false"
          [placeholder]="config.placeholder"
        >
          <ng-container class="error-container">
            <mat-error> {{ config.entityName }} is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
        <p [innerHtml]="config.help"></p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
      <i class="cs-icon cs-icon-save"></i> {{ config.buttonText }}
    </button>
  </div>
</form>
