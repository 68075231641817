<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/step-icon2.png" alt="" height="40" />
    <div>
      <h6 class="mb-0">Request Channel Manager Access</h6>
      <div class="text-sm">This section can take between 10 minutes and 2 hrs depending on the volume of requests.</div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #settings>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              BDC Channel Manager Settings
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Click on the Account button at the top right, then click on the Channel Manager link in the drop-down
              menu.
            </p>

            <p>
              If you don't see the Channel Manager link then please contact your Booking.com support team to request the
              commercial approval and make sure the sign up tool in your extranet is activated.
            </p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="startConnection.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #startConnection>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Start Connection Process
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>On the next screen, click on the Start button to search for AdvanceCM in the provider list.</p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="selectType.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #selectType>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Select Connection Type
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>In the Search for providers field, type AdvanceCM and then select the AdvanceCM option which appears.</p>
            <p>
              You will be making a full, two-way connection with AdvanceCM. Which means that AdvanceCM will handle
              availability, rates, and reservation synchronization.
            </p>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="enterAccountId.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #enterAccountId>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">4</div>
              Enter Account ID
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Enter your AdvanceCM account id. Your AdvanceCM account id is shown below. It can also be found by
              navigating to account info on your AdvanceCM account.
            </p>

            <p>Please copy this and paste it into the field shown below.</p>

            <div class="bg-white px-3 py-2 rounded w-50 text-bold">
              AdvanceCM ID: {{ accountId$.value }}
              <app-copy-icon [content]="accountId$.value" class="text-lg"></app-copy-icon>
            </div>
            <div class="text-right">
              <button class="btn btn-light-info" (click)="acceptTerms.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #acceptTerms>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">5</div>
              Accept Terms
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Accept the terms of the agreement between you and Booking.com regarding this XML connection.</p>
          <div class="text-right">
            <button class="btn btn-light-info" (click)="confirmConnection.open()">
              Next <i class="cs-icon cs-icon-arrow-down-long"></i>
            </button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #confirmConnection>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">6</div>
              Confirm the Connection
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              This step may take some time based on Booking.com's request volume. AdvanceCM will receive an email from
              Booking.com to confirm your connection request. Once confirmed, your admin portal will update to step 4.
            </p>

            <p>
              You'll get an email from Booking.com for confirmation. Refresh your screen to proceed. Confirm the
              connection by clicking the blue button. When it shows step 5 (Active), continue with the remaining steps.
            </p>

            <div class="alert alert-secondary-warning2 align-items-start">
              <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
              <div class="alert-content">
                <b>Note:</b> Connections outside peak hours (12 am to 4 am GMT) may require a pause until approved.
                Contact us via live chat with your Booking.com property ID if you encounter any issues.
              </div>
            </div>
            <div class="text-right mt-3">
              <button class="btn btn-light-info" (click)="enterPropertyId.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #enterPropertyId>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">7</div>
              Enter Property ID
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mb-3">
            <mat-form-field2 [showLabel]="false" class="w-50">
              <input matInput placeholder="Enter Property ID" [formControl]="propertyIdCtrl" required />
              <mat-error *ngIf="propertyIdCtrl.touched && propertyIdCtrl.hasError('required')">
                Property ID is required
              </mat-error>
            </mat-form-field2>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div style="max-width: 350px; min-width: 350px">
      <img src="/assets/images/channel-wizards/bdc/bdc-settings.png" alt="" class="w-100" *ngIf="settings.expanded" />
      <img
        src="/assets/images/channel-wizards/bdc/start-connection.jpeg"
        alt=""
        class="w-100"
        *ngIf="startConnection.expanded"
      />
      <img src="/assets/images/channel-wizards/bdc/select-type.jpeg" alt="" class="w-100" *ngIf="selectType.expanded" />
      <img
        src="/assets/images/channel-wizards/bdc/enter-account.jpeg"
        alt=""
        class="w-100"
        *ngIf="enterAccountId.expanded"
      />
      <img
        src="/assets/images/channel-wizards/bdc/accept-term.jpeg"
        alt=""
        class="w-100"
        *ngIf="acceptTerms.expanded"
      />
      <img
        src="/assets/images/channel-wizards/bdc/confirm-connection.png"
        alt=""
        class="w-100"
        *ngIf="confirmConnection.expanded"
      />
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button
    class="btn btn-secondary-info"
    matTooltip="Please enter property ID to connect to Booking.com"
    [disabled]="propertyIdCtrl.invalid || isConnecting"
    (click)="onConnect(propertyIdCtrl)"
  >
    Connect
    <i class="far fa-spin fa-spinner" *ngIf="isConnecting"></i>
    <i class="cs-icon cs-icon-arrow-right-long text-xxs" *ngIf="!isConnecting"></i>
  </button>
</div>
