import { Component, Input, OnInit } from '@angular/core'
import { Website } from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsiteContact } from '@tv3/store/website/website.actions'
import { RxwebValidators } from '@rxweb/reactive-form-validators'

@Component({
  selector: 'app-website-contact',
  templateUrl: './website-contact.component.html',
  styleUrls: ['./website-contact.component.scss'],
})
export class WebsiteContactComponent implements OnInit {
  @Input() website: Website
  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))

  form = this.fb.group({
    name: [undefined, [Validators.required]],
    phone: [undefined, [Validators.required]],
    email: [undefined, [RxwebValidators.email(), Validators.required]],
    address: this.fb.group({
      street: [''],
      city: [''],
      country_code: [''],
      country: [],
      postal_code: [''],
      state: [''],
    }),
  })

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    const company = this.website.company || {}
    this.form.patchValue({
      ...company,
    })
  }

  @SaveForm()
  onSave(fb: FormGroup) {
    const formData = fb.getRawValue()
    const payload = {
      ...this.website.serialize(),
      company: {
        ...this.website.company,
        ...formData,
      },
    }
    this.store.dispatch(UpdateWebsiteContact({ id: this.website.id, data: payload }))
  }
}
