import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { Destroyable, SaveForm, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Inquiry, isBookingFromAirbnb } from '@tv3/store/inquiry/inquiry.model'
import { BookingCostResolver, InquiryCost } from '@tokeet/cost-resolver'
import { UpdateInquiryCost, UpdateInquiryCostComplete } from '@tv3/store/inquiry/inquiry.actions'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-update-quote-external',
  templateUrl: './update-quote-external.component.html',
  styleUrls: ['./update-quote-external.component.scss'],
})
export class UpdateQuoteExternalComponent extends Destroyable implements OnInit {
  @Input() inquiry: Inquiry

  @Output() close = new EventEmitter()

  calculating = false
  loading = false

  form = this.fb.group({
    charge: [],
    extraGuests: [],
    discounts: this.fb.array([]),
    taxPercent: [],
    taxFlat: [],
  })

  discountsOrFeesLimit = 2

  constructor(private fb: FormBuilder, private store: Store<any>, private actions: Actions, private toaster: Toaster) {
    super()
  }

  get discounts() {
    return this.form.get('discounts') as FormArray
  }

  get currencySymbol() {
    return R.pathOr('$', ['currency', 'symbol'], this.inquiry?.rental)
  }

  ngOnInit() {
    const cost: InquiryCost = BookingCostResolver.parseBookingCharges(this.inquiry as any)

    this.actions.pipe(ofType(UpdateInquiryCostComplete), untilDestroy(this)).subscribe(() => {
      this.close.emit(true)
    })
    this.setCostForm(cost)
  }

  setCostForm(cost: InquiryCost) {
    const tax = cost.taxEx
    this.form.patchValue(
      {
        charge: cost.charge,
        extraGuests: cost.extraGuests,
        taxPercent: tax?.percent,
        taxFlat: tax?.flat,
      },
      { emitEvent: false }
    )
    this.setDiscounts(cost)
  }

  setDiscounts(cost: InquiryCost) {
    let discounts: FormGroup[] = R.map(
      (discount: number) => this.createDiscountField(discount),
      [...cost.discounts, ...cost.fees]
    )
    this.form.setControl('discounts', this.fb.array(discounts))
  }

  createDiscountField(amount?: number) {
    return this.fb.group({ amount: [R.defaultTo('', amount)] })
  }

  addDiscountsOrFees() {
    this.discounts.push(this.createDiscountField())
  }

  removeDiscountsOrFees(index: number) {
    this.discounts.removeAt(index)
  }

  reset() {
    const cost: InquiryCost = BookingCostResolver.parseBookingCharges(this.inquiry as any)
    this.form.reset()
    this.setCostForm(cost)
  }

  getTaxTotal() {
    const cost = this.getBookingCost()
    return cost.taxFee
  }

  getTotal() {
    const cost = this.getBookingCost()
    return cost.sum
  }

  getBookingCost() {
    const { charge, discounts, extraGuests, taxPercent, taxFlat } = this.form.getRawValue()
    const discountsArray: number[] = discounts?.map((d) => d.amount).filter((amount) => R.is(Number, amount))
    const discountItems = discountsArray.filter((t) => t < 0)
    const feeItems = discountsArray.filter((t) => t > 0)

    return BookingCostResolver.sumBookingCost({
      charge,
      discounts: discountItems,
      fees: feeItems,
      extraGuests,
      taxEx: { flat: taxFlat, percent: taxPercent },
    } as any)
  }

  @SaveForm()
  save(form: FormGroup) {
    const cost: InquiryCost = this.getBookingCost()

    const isAirbnbBooking = isBookingFromAirbnb(this.inquiry)
    if (isAirbnbBooking) {
    }

    this.store.dispatch(UpdateInquiryCost({ id: this.inquiry.id, cost: JSON.stringify(cost) }))

    this.close.emit(true)
  }
}
