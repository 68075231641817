import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-paid-status-badge',
  templateUrl: './paid-status-badge.component.html',
  styleUrls: ['./paid-status-badge.component.scss'],
})
export class PaidStatusBadgeComponent implements OnInit {
  @Input() paid: boolean
  constructor() {}

  ngOnInit(): void {}
}
