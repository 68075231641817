<div class="container-box shadow-none" style="background: #fbfbff">
  <div class="p-3">
    <div class="row">
      <div class="col-md-8">
        <form [formGroup]="form" [formEditablePermission]="form" class="step_1_form">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field2 class="mat-block company-name-block">
                <mat-label
                  >Company Name
                  <i
                    matTooltip="Company Name, this will update the Company Name on the Vrbo info page."
                    matTooltipPosition="above"
                    class="fal fa-question-circle"
                  ></i
                ></mat-label>
                <input type="text" formControlName="companyName" matInput required />
                <mat-error *ngIf="form.get('companyName').touched && form.get('companyName').hasError('required')">
                  Company Name is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('companyName').touched && form.get('companyName').hasError('minlength')">
                  Company Name must be <strong>at least 2</strong> characters long
                </mat-error>
                <mat-error *ngIf="form.get('companyName').touched && form.get('companyName').hasError('maxlength')">
                  Company Name must be <strong>at most 30</strong> characters long
                </mat-error>
              </mat-form-field2>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <mat-form-field2 class="mat-block" *ngIf="currentCurrency as item">
                <mat-label>
                  Currency
                  <i
                    matTooltip="We use currency set on your account for Vrbo listings"
                    matTooltipPosition="above"
                    class="fal fa-question-circle"
                  ></i>
                </mat-label>
                <input type="text" matInput [value]="item.code" disabled readonly />
                <span matSuffix [innerHtml]="item.symbol"></span>
              </mat-form-field2>
            </div>

            <div class="col-sm-4">
              <mat-form-field2 class="mat-block">
                <mat-label
                  >Primary Guest Minimum Age
                  <i
                    matTooltip="Set the minimum age requirement of the primary renter for your Vrbo listings"
                    matTooltipPosition="above"
                    class="fal fa-question-circle"
                  ></i
                ></mat-label>

                <input type="number" formControlName="primaryGuestMinAge" matInput />
                <mat-error
                  *ngIf="form.get('primaryGuestMinAge').touched && form.get('primaryGuestMinAge').hasError('required')"
                >
                  Age is <strong>required</strong>
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-4">
              <label class="mb-3">
                Booking Settings
                <i
                  matTooltip="Instant Booking is an online booking type that allows travelers to instantly book your property. It also confirms booking requests automatically"
                  matTooltipPosition="above"
                  class="fal fa-question-circle"
                ></i>
              </label>

              <app-form-switch [formControl]="form.get('instantBooking')" class="booking-settings-toggle">
                {{ form.get('instantBooking').value ? 'Instant' : 'Request to book' }}
                <i
                  *ngIf="form.get('instantBooking').disabled"
                  matTooltip="Only instant bookings are supported at the moment"
                  matTooltipPosition="above"
                  class="fal fa-question-circle"
                ></i>
              </app-form-switch>
            </div>
          </div>
        </form>
        <div class="text-danger" *ngIf="!stripeCheckbox.checked">
          You must have a Stripe payment gateway connected in order to connect VRBO
        </div>

        <form [formGroup]="termsForm" [formEditablePermission]="form">
          <div class="mb-2 d-flex align-items-center justify-content-between">
            <div class="text-semi-bold">
              Payment Terms
              <i
                matTooltip="Setting up a payment schedule allows you to specify the payment amount you require from your traveler to confirm a booking. You are able to require 100% at the time of booking or set up multiple payments that can be scheduled for future dates."
                matTooltipPosition="above"
                class="fal fa-question-circle"
              ></i>
            </div>
            <app-form-switch #stripeCheckbox [value]="isStripeConnected$ | async" [disabled]="true" [inline]="true">
              Stripe {{ stripeCheckbox.checked ? 'Connected' : 'Not Connected' }}
            </app-form-switch>
          </div>

          <table class="table bg-white rounded shadow" formArrayName="terms">
            <thead class="bg-default2">
              <tr>
                <th class="text-semi-bold">Days</th>
                <th class="text-semi-bold">Payment Time</th>
                <th class="text-semi-bold">Percent (%)</th>
                <th class="text-center text-semi-bold">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of terms.controls; let i = index" [formGroup]="terms.controls[i]">
                <td>
                  <ng-container *ngIf="hasDays(item['controls'].type.value)">
                    <mat-form-field2 class="mat-block" [showLabel]="false">
                      <input type="number" formControlName="days" matInput required placeholder="Days" />
                    </mat-form-field2>
                  </ng-container>
                </td>
                <td>
                  <mat-form-field2 class="mat-block" [showLabel]="false">
                    <input
                      type="text"
                      [value]="getTypeLabel(item['controls'].type.value)"
                      matInput
                      disabled
                      required
                      placeholder="Payment time"
                    />
                  </mat-form-field2>
                </td>
                <td>
                  <mat-form-field2 class="mat-block" [showLabel]="false">
                    <input type="text" formControlName="percent" matInput required placeholder="Percent" />
                  </mat-form-field2>
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-light-danger"
                    (click)="onRemoveTerm(i)"
                    [disabled]="1 | isReadonlyRole"
                    type="button"
                  >
                    <i class="cs-icon cs-icon-trash"></i>
                  </button>
                </td>
              </tr>
              <tr
                [formGroup]="termForm"
                *ngIf="
                  canAddMoreTypes(terms.controls) &&
                  remainingPercentage?.length > 0 &&
                  remainingTypes?.length > 0 &&
                  (isStripeConnected$ | async)
                "
              >
                <td>
                  <div *ngIf="hasDays(termForm.get('type').value)">
                    <mat-form-field2 class="mat-block" [showLabel]="false">
                      <input type="number" formControlName="days" matInput required placeholder="Days" />
                      <mat-error *ngIf="termForm.get('days').touched && termForm.get('days').hasError('required')">
                        Days is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="termForm.get('days').touched && termForm.get('days').hasError('min')">
                        Days must be <strong>positive number</strong>
                      </mat-error>
                    </mat-form-field2>
                  </div>
                </td>
                <td>
                  <mat-form-field2 class="mat-block" [showLabel]="false">
                    <mat-select
                      matTooltip="Choose when the payment should be made"
                      matTooltipPosition="above"
                      formControlName="type"
                      placeholder="Payment time"
                    >
                      <mat-option *ngFor="let t of remainingTypes" [value]="t.value">
                        {{ t.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="termForm.get('type').touched && termForm.get('type').hasError('required')">
                      Type is <strong>required</strong>
                    </mat-error>
                  </mat-form-field2>
                </td>
                <td>
                  <mat-form-field2 class="mat-block" [showLabel]="false">
                    <mat-select formControlName="percent" placeholder="Percent">
                      <mat-option *ngFor="let t of remainingPercentage" [value]="t"> {{ t }} %</mat-option>
                    </mat-select>
                    <mat-error *ngIf="termForm.get('percent').touched && termForm.get('percent').hasError('required')">
                      Percent is <strong>required</strong>
                    </mat-error>
                  </mat-form-field2>
                </td>
                <td class="text-center pb-4">
                  <button
                    (click)="onAddTerm()"
                    [disabled]="1 | isReadonlyRole"
                    type="button"
                    class="btn btn-secondary-info"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="text-semi-bold mb-3">
              Cancellation Policy
              <i
                matTooltip="When setting your listing’s cancellation policy, you may choose from one of five options."
                matTooltipPosition="above"
                class="fal fa-question-circle"
              ></i>
            </div>

            <mat-radio-group [formControl]="form.get('cancellationPolicy')" class="cancellation-group">
              <ul class="list-group">
                <li class="list-group-item">
                  <mat-radio-button value="STRICT">
                    <div class="d-flex gap-2 align-items-center">
                      <div class="badge badge-circle badge-secondary-warning" style="--size: 2rem">
                        <i class="cs-icon text-md cs-icon-bed-times"></i>
                      </div>
                      <div>
                        <div class="radio-title text-semi-bold">Strict</div>
                        <div class="radio-desc text-light text-wrap text-sm">
                          Cancel your booking at least 60 days before the stay to get a full refund.
                        </div>
                      </div>
                    </div>
                  </mat-radio-button>
                </li>
                <li class="list-group-item">
                  <mat-radio-button value="FIRM">
                    <div class="d-flex gap-2 align-items-center">
                      <div class="badge badge-circle badge-secondary-primary" style="--size: 2rem">
                        <i class="cs-icon text-md cs-icon-money-back"></i>
                      </div>
                      <div>
                        <div class="radio-title text-semi-bold">Firm</div>
                        <div class="radio-desc text-light text-wrap text-sm">
                          Cancel at least 60 days before the stay for a full refund. Cancel at least 30 days before for
                          a 50% refund.
                        </div>
                      </div>
                    </div>
                  </mat-radio-button>
                </li>
                <li class="list-group-item">
                  <mat-radio-button value="MODERATE">
                    <div class="d-flex gap-2 align-items-center">
                      <div class="badge badge-circle badge-secondary-indigo" style="--size: 2rem">
                        <i class="cs-icon text-md cs-icon-dollar-back"></i>
                      </div>
                      <div>
                        <div class="radio-title text-semi-bold">Moderate</div>
                        <div class="radio-desc text-light text-wrap text-sm">
                          <span class="text-success">Recommended</span>
                          - Cancel at least 30 days before the stay for a full refund. Cancel at least 14 days before
                          for a 50% refund.
                        </div>
                      </div>
                    </div>
                  </mat-radio-button>
                </li>
                <li class="list-group-item">
                  <mat-radio-button value="RELAXED">
                    <div class="d-flex gap-2 align-items-center">
                      <div class="badge badge-circle badge-secondary-success" style="--size: 2rem">
                        <i class="cs-icon text-md cs-icon-relax"></i>
                      </div>
                      <div>
                        <div class="radio-title text-semi-bold">Relaxed</div>
                        <div class="radio-desc text-light text-wrap text-sm">
                          Cancel at least 14 days before the stay for a full refund. Cancel at least 7 days before for a
                          50% refund.
                        </div>
                      </div>
                    </div>
                  </mat-radio-button>
                </li>
                <li class="list-group-item">
                  <mat-radio-button value="NO_REFUND">
                    <div class="d-flex gap-2 align-items-center">
                      <div class="badge badge-circle badge-secondary-danger" style="--size: 2rem">
                        <i class="cs-icon text-md cs-icon-money-ban"></i>
                      </div>
                      <div>
                        <div class="radio-title text-semi-bold">No Refund</div>
                        <div class="radio-desc text-light text-wrap text-sm">
                          No refunds will be offered, regardless of the reason or timeframe.
                        </div>
                      </div>
                    </div>
                  </mat-radio-button>
                </li>
              </ul>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-top p-4 mt-5 d-flex align-items-center gap-3">
    <button class="btn btn-outline-light" (click)="prev.emit()">
      <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
      Previous
    </button>
    <div class="flex-fill"></div>
    <button (click)="onDisconnect()" type="button" class="btn btn-secondary-danger" *ngIf="!!config?.status">
      <i class="fal fa-ban"></i> Disconnect
    </button>
    <button
      (click)="onReconnect()"
      [disabled]="!(isStripeConnected$ | async)"
      type="button"
      class="btn btn-secondary-info"
      *ngIf="!!config && !config?.status"
    >
      <i class="fal fa-link"></i> Connect
    </button>
    <button
      (click)="onContinue()"
      [disabled]="!(isStripeConnected$ | async)"
      type="button"
      *ngIf="!config || !!config?.status"
      class="btn btn-secondary-info"
    >
      Next
      <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
    </button>
  </div>
</div>
