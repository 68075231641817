import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { ActionFailed, isSomething, Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  AddAutomation,
  AddAutomationComplete,
  ArchiveAutomation,
  ArchiveAutomationComplete,
  DeleteAutomation,
  DeleteAutomationComplete,
  LoadAutomations,
  LoadAutomationsComplete,
  PauseAutomation,
  PauseAutomationComplete,
  RecreateAutomation,
  UnArchiveAutomation,
  UnArchiveAutomationComplete,
  UnPauseAutomation,
  UnPauseAutomationComplete,
  UpdateAutomations,
  UpdateAutomationsComplete,
} from './automation.actions'
import { AutomationResponse, CreateAutomationPayload } from './automation.model'
import { AutomationService } from './automation.service'

@Injectable()
export class AutomationEffects {
  @Effect()
  loadAutomations$ = this.actions$.pipe(
    ofType(LoadAutomations),
    switchMap(() =>
      this.automationService.all().pipe(
        map((automations) => LoadAutomationsComplete({ automations })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteAutomations$ = this.actions$.pipe(
    ofType(DeleteAutomation),
    switchMap(({ ids, silent }) =>
      this.automationService.deleteBatch(ids).pipe(
        tap(() => !silent && this.toast.success('Automation deleted successfully.')),
        map(() => DeleteAutomationComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pauseAutomation$ = this.actions$.pipe(
    ofType(PauseAutomation),
    concatMap(({ ids, silent }) =>
      this.automationService.pause(ids).pipe(
        tap(() => !silent && this.toast.success('Automation updated successfully. ')),
        map(() => PauseAutomationComplete({ changes: R.map((id) => ({ id, changes: { status: 0 } }), ids) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  resumeAutomation$ = this.actions$.pipe(
    ofType(UnPauseAutomation),
    concatMap(({ ids, silent }) =>
      this.automationService.resume(ids).pipe(
        tap(() => !silent && this.toast.success('Automation updated successfully. ')),
        map(() => UnPauseAutomationComplete({ changes: R.map((id) => ({ id, changes: { status: 1 } }), ids) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveAutomation$ = this.actions$.pipe(
    ofType(ArchiveAutomation),
    switchMap(({ ids }) =>
      this.automationService.archive(ids).pipe(
        tap(() => this.toast.success('Automation archived successfully.')),
        map(() => ArchiveAutomationComplete({ changes: R.map((id) => ({ id, changes: { archived: 1 } }), ids) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unArchiveAutomation$ = this.actions$.pipe(
    ofType(UnArchiveAutomation),
    switchMap(({ ids }) =>
      this.automationService.unArchive(ids).pipe(
        tap(() => this.toast.success('Automation unarchived successfully.')),
        map(() => UnArchiveAutomationComplete({ changes: R.map((id) => ({ id, changes: { archived: 0 } }), ids) })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addAutomation$ = this.actions$.pipe(
    ofType(AddAutomation),
    switchMap(({ ids, payload, silent }) => {
      const ob$ = isSomething(ids) ? this.automationService.deleteBatch(ids || []) : of([])
      return ob$.pipe(
        switchMap(() =>
          this.createAutomation(payload).pipe(
            tap(() => !silent && this.toast.success('Automation created successfully.')),
            switchMap((automations) => {
              let actions: any[] = [AddAutomationComplete({ automations })]
              if (isSomething(ids)) {
                actions = R.append(DeleteAutomationComplete({ ids: ids || [] }), actions)
              }
              return actions
            }),
            catchError((error) => of(ActionFailed({ error })))
          )
        )
      )
    })
  )

  @Effect()
  recreateAutomation$ = this.actions$.pipe(
    ofType(RecreateAutomation),
    switchMap(({ ids, payload }) => {
      return combineLatest([this.createAutomation(payload), this.automationService.deleteBatch(ids || [])]).pipe(
        tap(() => this.toast.success('Automation updated successfully.')),
        switchMap(([automations]) => [
          AddAutomationComplete({ automations }),
          DeleteAutomationComplete({ ids: ids || [] }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateAutomation$ = this.actions$.pipe(
    ofType(UpdateAutomations),
    concatMap(({ items }) => {
      return this.automationService.updateMany(items).pipe(
        tap(() => this.toast.success('Automation updated successfully. ')),
        map((automations) =>
          UpdateAutomationsComplete({ updates: automations.map((t) => ({ id: t.pkey, changes: t })) })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(private actions$: Actions, private automationService: AutomationService, private toast: Toaster) {}

  private createAutomation(payload: CreateAutomationPayload): Observable<AutomationResponse[]> {
    const batch = isSomething(payload.channels) || isSomething(payload.rentals)
    if (batch) {
      return this.automationService.createMany(payload)
    } else {
      return this.automationService.create(payload).pipe(map((item) => [item]))
    }
  }
}
