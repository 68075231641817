import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Subscription } from '@tokeet-frontend/subscriptions'
import { Toaster, searchTable } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'
import { EMPTY, Observable } from 'rxjs'
import { catchError, map, switchMapTo } from 'rxjs/operators'
import { WebreadySubscription } from '../store/app/app-sub.model'
import { TokeetApp } from '../store/app/app.model'
import { AppService } from '../store/app/app.service'
import { SubscribedAppsStore } from './subscribed-apps.store'

@Component({
  selector: 'app-subscribed-apps',
  templateUrl: './subscribed-apps.component.html',
  styleUrls: ['./subscribed-apps.component.scss'],
  providers: [SubscribedAppsStore],
})
export class SubscribedAppsComponent implements OnInit {
  @ViewChild('table') appTable: ElementRef<HTMLTableElement>

  subscriptions$ = this.store.selectSubscriptions$
  subscriptionsLoading$ = this.store.selectSubscriptionsLoading$
  apps$ = this.store.selectApps$
  webreadySubscription$ = this.store.selectWebreadySubscription$
  rgPrice$ = this.store.selectRGPrice$

  unsubscribingWebreadyAddons: string

  constructor(private store: SubscribedAppsStore, private appsService: AppService, private toaster: Toaster) {}

  ngOnInit() {
    this.store.loadAccount(this.appsService.getSubscriptions())
    this.store.loadApps(this.appsService.getApps())
    this.store.loadRGPrices(this.appsService.getRategeniePrices())
    this.store.loadWebreadySubscription(this.appsService.getWebreadySubscription())
  }

  geWebreadyLogo(apps$: Observable<{ [index: string]: TokeetApp }>, feature: string) {
    return apps$.pipe(
      map((apps: any) => {
        if (feature === 'multi_rental_website' || feature === 'single_rental_website') {
          return apps.webready?.logo_url
        } else {
          return apps[feature]?.logo_url || apps.webready?.logo_url
        }
      })
    )
  }

  isWebreadyAddon(product: any) {
    return this.appsService.isWebreadyAddon(product)
  }

  getCardName(name: string) {
    return (name || '').toString().replace(/\:/g, ' ').replace(/\s+/, ' ')
  }

  isOverdue(end: number) {
    const now = moment.utc().unix()
    return now > end
  }

  roundFloat(price: number) {
    return parseFloat((Math.round(price * 100) / 100).toFixed(2))
  }

  isActive(subscription: Subscription) {
    return (
      (subscription.status === 'active' || subscription.status === 'trialing') && !subscription.cancel_at_period_end
    )
  }

  isActiveWebreadyAddon(subscription: WebreadySubscription, product: string) {
    return (
      !subscription.cancel_at_period_end &&
      subscription.features[product]?.active &&
      (!subscription.next_billing_cycle || subscription.next_billing_cycle.features[product]?.active)
    )
  }

  getStatusWebreadyAddon(subscription: WebreadySubscription, product: string) {
    if (
      !subscription.cancel_at_period_end &&
      subscription.features[product]?.active &&
      (!subscription.next_billing_cycle || subscription.next_billing_cycle.features[product]?.active)
    ) {
      return 'Active'
    } else if (
      subscription.cancel_at_period_end ||
      (subscription.features[product]?.active &&
        !!subscription.next_billing_cycle &&
        !subscription.next_billing_cycle.features[product]?.active)
    ) {
      return 'Cancelled'
    } else {
      return 'Inactive'
    }
  }

  getStatus(subscription: Subscription) {
    if (subscription.cancel_at_period_end) {
      return 'Cancelled'
    } else if (subscription.status === 'active') {
      return 'Active'
    } else if (subscription.status === 'trialing') {
      return 'Trial'
    } else if (subscription.status === 'past_due') {
      return 'Past Due'
    } else {
      return 'Inactive'
    }
  }

  getCharge(featureKey: string, featureValue: any) {
    const round = (price) => parseFloat((Math.round(price) / 100).toFixed(2))

    switch (featureKey) {
      case 'webready_edocuments':
        return `$${round(featureValue.total)} for ${featureValue.quantity} document(s)`
      case 'webready_invoicing':
        return 'Fee of 1% per invoice'
      case 'booking_engine':
      case 'webready_messaging':
      case 'webready_automations':
      case 'webready_airbnb_connector':
        return `$${round(featureValue.price)} per month`
      case 'webready_translator':
      case 'single_rental_website':
      case 'multi_rental_website':
        if (featureValue.quantity > 0) {
          return `$${round(featureValue.price * featureValue.quantity)} for ${featureValue.quantity} website(s)`
        } else {
          return `$0 for no website(s)`
        }
      case 'tokeet_smart_devices':
        return `$${round(featureValue.price * featureValue.quantity)} for ${featureValue.quantity} device(s)`
    }
  }

  getSubscriptionPriceUnit(type: string, isYearly = false) {
    switch (type) {
      case 'flat':
      case 'per-owner':
      case 'per-rental':
        return isYearly ? 'per year' : 'per month'
      case 'rental-brackets':
        return isYearly ? 'per year' : 'per month'
      case 'per-job':
        return 'Variable price per job'
      case 'per-website':
        return 'Variable price per website'
      case 'per-document':
        return 'Variable price per document'
      case 'percent':
        return 'Variable percentage of sales'
    }
  }

  getWebreadyStatus(subscription: WebreadySubscription) {
    if (subscription.cancel_at_period_end) {
      return 'Cancelled'
    } else if (subscription.status === 'active') {
      return 'Active'
    } else if (subscription.delinquent) {
      return 'Past Due'
    } else {
      return 'Inactive'
    }
  }

  onWebreadyAddonStatusChange(checked: boolean, product: string) {
    if (checked) {
      return
    }
    this.unsubscribingWebreadyAddons = product
    this.appsService
      .unsubscribeWebreadyAddon(product)
      .pipe(
        switchMapTo(this.appsService.getWebreadySubscription()),
        catchError(() => {
          this.toaster.error('Unable to unsubscribe addon.')
          this.unsubscribingWebreadyAddons = null
          return EMPTY
        })
      )
      .subscribe((subscription) => {
        this.toaster.success('Addon unsubscribed successfully')
        this.store.loadWebreadySubscription(subscription)
        this.unsubscribingWebreadyAddons = null
      })
  }

  onStatusChange(checked: boolean, subscription: Subscription) {
    if (checked) {
      this.appsService.subscribe(subscription.product, subscription.plan_id).subscribe((result) => {
        this.toaster.success('Subscribed to addon successfully')
        this.store.updateSubscription(result.subscription)
      })
    } else {
      this.appsService.unsubscribe(subscription.plan_id).subscribe((subscriptions) => {
        this.toaster.success('Unsubscribed from addon successfully')
        this.store.setSubscriptions(subscriptions)
      })
    }
  }

  isMonthly(subscription: Subscription) {
    return !(subscription.plan_id + '').includes('_yr')
  }

  isYearly(subscription: Subscription) {
    return (subscription.plan_id + '').includes('_yr') || subscription.metadata?.product === 'website_management'
  }

  onSearch(term: string) {
    searchTable(this.appTable.nativeElement, term)
  }
}
