import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { Observable } from 'rxjs'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Component({
  selector: 'app-task-inquiry-details',
  templateUrl: './task-inquiry-details.component.html',
  styleUrls: ['./task-inquiry-details.component.scss'],
})
export class TaskInquiryDetailsComponent implements OnInit {
  @Input() inquiryId: string
  inquiry$: Observable<Inquiry>
  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    this.inquiry$ = this.store.pipe(select(selectInquiry, { id: this.inquiryId }))
  }
}
