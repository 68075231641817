import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  asLocalDate,
  Destroyable,
  getISOWeekdays,
  integer,
  isSomething,
  notAllNumbersValidator,
  positive,
  SaveForm,
  selectAllChannelsWithDefaults,
  selectAllRentals,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreateMinStayAutomation,
  CreateMinStayAutomationPayload,
  MinStayAutomation,
  selectAllMinStayAutomations,
  UpdateMinStayAutomation,
} from '@tokeet-frontend/rate-automations'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'
import { combineLatest, Observable } from 'rxjs'
import { debounceTime, filter, map, startWith } from 'rxjs/operators'

export interface MinStayAutomationDialogInput {
  minStay?: MinStayAutomation
}

@Component({
  selector: 'app-min-stay-automation-dialog',
  host: { class: 'modal-content' },
  templateUrl: './min-stay-automation-dialog.component.html',
  styleUrls: ['./min-stay-automation-dialog.component.scss'],
})
export class MinStayAutomationDialogComponent extends Destroyable implements OnInit, OnDestroy {
  isEdit = false

  daysForm = this.fb.array(
    getISOWeekdays().map(() => this.fb.control(undefined, [positive(), integer(), Validators.required]))
  )
  form = this.fb.group({
    name: ['', [Validators.required, notAllNumbersValidator]],
    rentals: [[], [Validators.required]],
    range: [null, [Validators.required]],
    days: this.daysForm,
  })

  minDate: Date
  rentals$ = this.store.select(selectAllRentals)
  channels$ = this.store.select(selectChannelsWithCustomChannels(selectAllChannelsWithDefaults, 'name'))
  weekdayOptions = getISOWeekdays()

  usedRange$: Observable<{ start: number; end: number }[]>

  get minStay() {
    return this.data?.minStay
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private dialogRef: MatDialogRef<MinStayAutomationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MinStayAutomationDialogInput
  ) {
    super()

    this.isEdit = !!data.minStay?.key
    if (!this.isEdit) {
      this.minDate = new Date()
    } else {
      this.minDate = asLocalDate(Math.min(Date.now() / 1000, this.data.minStay.start))
    }

    this.form.patchValue({
      ...(data.minStay || {}),
      range: this.isEdit ? { from: data.minStay.start, to: data.minStay.stop } : null,
    })
    const minNightsByDay = lodash.keyBy(this.minStay?.days, (t) => t.dow)
    const minNights = this.weekdayOptions.map((t, i) => minNightsByDay[i + 1]?.min_nights)
    this.daysForm.patchValue(minNights)
  }

  ngOnInit() {
    this.usedRange$ = combineLatest([
      this.store.pipe(select(selectAllMinStayAutomations)),
      this.form.get('rentals').valueChanges.pipe(startWith(this.form.get('rentals').value), filter(isSomething)),
    ]).pipe(
      map(([items, rentals]) => {
        items = items.filter((t) => lodash.intersection(rentals, t.rentals).length > 0)
        if (this.isEdit) {
          items = items.filter((t) => t.key !== this.minStay?.key)
        }
        return lodash.compact(lodash.map(items, (t) => ({ start: t.start, end: t.stop })))
      })
    )
    this.daysForm.valueChanges
      .pipe(startWith(this.daysForm.value), debounceTime(500), untilDestroy(this))
      .subscribe((items) => {
        const isAnyValid = items.some((t) => !lodash.isNil(t))
        if (!isAnyValid) {
          this.daysForm.controls.forEach((c) => {
            c.setValidators([Validators.required, positive(), integer()])
            c.updateValueAndValidity({ emitEvent: false })
          })
        } else {
          this.daysForm.controls.forEach((c) => {
            c.setValidators([positive(), integer()])
            c.updateValueAndValidity({ emitEvent: false })
          })
        }
      })
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    const { range, days, ...data } = form.getRawValue()
    const payload: CreateMinStayAutomationPayload = {
      ...data,
      start: range.from,
      stop: range.to,
      days: lodash
        .map(days, (min_nights, i) => ({ dow: i + 1, min_nights }))
        .filter((t) => !lodash.isNil(t.min_nights)),
    }

    if (this.isEdit) {
      this.store.dispatch(UpdateMinStayAutomation({ id: this.minStay.key, payload }))
    } else {
      this.store.dispatch(CreateMinStayAutomation({ payload }))
    }
    this.close()
  }

  close() {
    this.dialogRef.close()
  }
}
