import { adapter, initialState, State } from './stealth.state'
import * as StealthActions from './stealth.actions'

export function reducer(state = initialState, action: StealthActions.StealthActionsUnion): State {
  switch (action.type) {
    case StealthActions.LoadStealthTriggersComplete.type:
      return adapter.addAll(action.triggers, { ...state, isLoaded: true })

    case StealthActions.AddStealthTriggersComplete.type:
      return adapter.addMany(action.triggers, state)

    case StealthActions.UpdateStealthTriggerComplete.type:
    case StealthActions.PauseStealthTriggerComplete.type:
    case StealthActions.ResumeStealthTriggerComplete.type:
    case StealthActions.ArchiveStealthTriggerComplete.type:
    case StealthActions.UnarchiveStealthTriggerComplete.type:
      return adapter.updateOne(action.update, state)

    case StealthActions.DeleteStealthTriggerComplete.type:
      return adapter.removeOne(action.id, state)
    case StealthActions.DeleteStealthTriggersComplete.type:
      return adapter.removeMany(action.ids, state)

    default:
      return state
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
