<div class="col-sm-12 main-content">
  <div class="container-box details-box">
    <div class="row header">
      <div class="col-sm-6 title">Specify Rental & Channel Account</div>
      <div class="col-sm-6 btn-holder"></div>
    </div>

    <div class="box-body">
      <div class="row">
        <div class="col">
          <h6 class="section-title">
            <small>
              The new booking.com listing will use information currently in your AdvanceCM account. The most important
              information is listing below for verification. Please update this information as needed prior to creating
              the listing.
            </small>
          </h6>
        </div>
      </div>

      <div>
        <mat-vertical-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="rentalForm">
            <ng-template matStepLabel>Review rental details</ng-template>
            <app-bdc-content-step-rental-form
              [form]="rentalForm"
              [channel]="channel"
              [rentalId]="rentalId"
              (rentalChange)="onRentalChange($event)"
            >
            </app-bdc-content-step-rental-form>
            <div>
              <button type="button" mat-button (click)="onEditRental(rental)" *ngIf="rental">
                <i class="fal fa-edit"></i> Edit Rental
              </button>
              <button type="button" mat-button matStepperNext>Next <i class="fal fa-angle-right"></i></button>
            </div>
          </mat-step>

          <mat-step [stepControl]="licenseForm">
            <ng-template matStepLabel>Review License Details</ng-template>
            <app-bdc-content-step-license-form
              [form]="licenseForm"
              [settings]="bdcPropertySetting?.attributes"
              [rental]="rental"
            >
            </app-bdc-content-step-license-form>
            <div>
              <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button type="button" mat-button matStepperNext>Next <i class="fal fa-angle-right"></i></button>
            </div>
          </mat-step>

          <mat-step [stepControl]="rentalAmenitiesForm">
            <ng-template matStepLabel>Review rental Amenities</ng-template>
            <app-content-step-amenities-form
              [rental]="rental"
              [amenities]="rentalAmenityDetails"
              [form]="rentalAmenitiesForm"
            >
            </app-content-step-amenities-form>
            <div>
              <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button
                type="button"
                mat-button
                matStepperNext
                [disabled]="!selectedPropertyAmenitiesCount(rentalAmenitiesForm)"
              >
                Next <i class="fal fa-angle-right"></i>
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="petsForm">
            <ng-template matStepLabel>Review Pets Policy</ng-template>
            <app-bdc-content-step-pet-form
              [form]="petsForm"
              [settings]="bdcPropertySetting?.attributes"
            ></app-bdc-content-step-pet-form>
            <div>
              <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button type="button" mat-button matStepperNext>Next <i class="fal fa-angle-right"></i></button>
            </div>
          </mat-step>
          <mat-step [stepControl]="accountForm">
            <ng-template matStepLabel>Review account details</ng-template>
            <app-bdc-content-step-account-form [form]="accountForm"></app-bdc-content-step-account-form>
            <div>
              <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button type="button" mat-button matStepperNext>Next <i class="fal fa-angle-right"></i></button>
            </div>
          </mat-step>
          <mat-step [stepControl]="contactsForm">
            <ng-template matStepLabel>Select Contacts</ng-template>
            <app-bdc-content-step-contacts-form
              #contactFormCom
              [form]="contactsForm"
              [contacts]="bdcPropertySetting?.attributes?.contacts"
            >
            </app-bdc-content-step-contacts-form>
            <div>
              <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button type="button" mat-button matStepperNext [disabled]="!contactFormCom.isValid">
                Next <i class="fal fa-angle-right"></i>
              </button>
            </div>
          </mat-step>
          <mat-step [stepControl]="cancelPoliciesForm">
            <form [formGroup]="cancelPoliciesForm">
              <ng-template matStepLabel>Cancellation Policy</ng-template>
              <app-bdc-content-step-policies-form
                [form]="cancelPoliciesForm"
                (change)="onCancelPolicyChange($event)"
                [policies]="bdcPropertySetting?.attributes?.policies"
              ></app-bdc-content-step-policies-form>
              <div>
                <button type="button" mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
                <button type="button" mat-button matStepperNext>Next <i class="fal fa-angle-right"></i></button>
              </div>
            </form>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div>
              <button mat-button matStepperPrevious><i class="fal fa-angle-left"></i> Back</button>
              <button mat-button (click)="onSave()"><i class="cs-icon cs-icon-save"></i> Save</button>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </div>
    </div>
  </div>
</div>
