import * as moment from 'moment'
import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import {
  generateRentalParser,
  parseDate,
  parseHoldType,
  parseString,
} from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import * as R from 'ramda'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { Destroyable, Rental, selectAllRentals, selectOnce, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { CalendarEvent, CreateHoldEventpayload } from '@tv3/store/calendar/calendar.model'
import { AddHoldEvent } from '@tv3/store/calendar/calendar.actions'
import { selectAllCalendarEventsForRental } from '@tv3/store/calendar/calendar.selectors'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-import-holds-dialog',
  templateUrl: './import-holds-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-holds-dialog.component.scss'],
})
export class ImportHoldsDialogComponent extends Destroyable implements OnInit {
  files: ParsedUploadFile<any>[] = []
  rentals: Rental[]

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Title', field: 'title', required: true, parse: parseString },
    {
      name: 'Rental',
      field: 'rentalId',
      required: true,
      parse: (str, colDef) => generateRentalParser(this.rentals)(str, colDef),
    },
    { name: 'Start', field: 'start', required: true, parse: parseDate },
    { name: 'End', field: 'end', required: true, parse: parseDate },
    { name: 'Type', field: 'type', required: true, parse: parseHoldType },
    { name: 'Expires', field: 'expires', parse: parseDate },
  ]

  guide: CsvFileParserGuide = {
    // tslint:disable-next-line:max-line-length
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: ['Title, Rental, Start, End, Type are required.', 'Dates format "YYYY-MM-DD", such as "2016-02-14".'],
    sample: [
      ['Title', 'Rental', 'Start', 'End', 'Type', 'Expires'],
      ['Hold1', 'Apartment 53', '2016-02-14', '2016-02-17', 'Personal', '2016-02-14'],
      ['Hold2', 'BaiMarco', '2016-02-15', '2016-02-19', 'Confirmed', '2016-02-14'],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportHoldsDialogComponent>,
    private store: Store<fromRoot.State>,
    private calendarService: CalendarService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
    files.forEach((file) => {
      if (!file.isError) {
        this.checkOverlapWithExistingEvents(file)
      }
    })
    this.checkOverlapBetweenImportedEvents(this.files)
  }

  onCreate() {
    const items: any = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    const payloads = items.map((item: any): CreateHoldEventpayload => {
      return {
        ...item,
        start: moment(item.start).unix(),
        end: moment(item.end).unix(),
        expires: moment(item.expires).unix(),
        rental_id: item.rentalId,
      }
    })
    this.store.dispatch(AddHoldEvent({ payloads }))

    this.dialogRef.close()
  }

  onClose() {
    this.dialogRef.close()
  }

  hasErrors() {
    return !!this.files.find((file) => file.isError)
  }

  checkOverlapWithExistingEvents(file) {
    file.items.forEach((entry) => {
      const item = entry.item
      const rentalId = item.rentalId
      if (!rentalId) {
        return
      }
      this.store.pipe(selectOnce(selectAllCalendarEventsForRental(rentalId))).subscribe((events) => {
        const overlapsEvent = this.calendarService.checkOverlapping({ start: item.start, end: item.end }, events, false)
        if (!overlapsEvent) {
          return
        }
        this.setError(file, entry, overlapsEvent)
      })
    })
  }

  checkOverlapBetweenImportedEvents(files) {
    const items = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    files.forEach((file) => {
      file.items.forEach((entry) => {
        const item = entry.item
        const events = items.filter((i) => i !== item) as CalendarEvent[]
        const overlapsEvent = this.calendarService.checkOverlapping({ start: item.start, end: item.end }, events, false)
        if (!overlapsEvent) {
          return
        }
        this.setError(file, entry, {
          ...overlapsEvent,
          start: moment(overlapsEvent.start).unix(),
          end: moment(overlapsEvent.end).unix(),
        } as CalendarEvent)
      })
    })
  }

  setError(file, entry, event: CalendarEvent) {
    file.isError = true
    file.errors = ['File contains errors, please check details in a rows.']
    entry.isError = true
    const startFormatted = moment.unix(event.start).format('DD-MMM-YYYY')
    const endFormatted = moment.unix(event.end).format('DD-MMM-YYYY')
    entry.errors = [...entry.errors, `Overlapping with event ${event.title} ${startFormatted} - ${endFormatted}`]
  }
}
