<div class="box-details">
  <div *ngFor="let invoice of invoices" class="box-dsc-row row invoice-item" (click)="onEditInvoice(invoice)">
    <div [matTooltip]="invoice.dueDate | epoch" matTooltipPosition="above" class="col-5">
      Due: {{ invoice.dueDate | epoch : 'DD - MMM - YYYY' }}
    </div>
    <div class="col-3 text-center">
      <span class="invoice-status">{{ invoice.status | invoiceStatus }}</span>
    </div>
    <div class="col-4 text-right">
      <span
        [innerHtml]="invoice.invoiceItems | invoiceTotal : true : guests | tokeetCurrency : invoice?.currency"
      ></span>
    </div>
  </div>
</div>
<div class="box-details" *ngIf="addable">
  <div class="box-dsc-row text-center">
    <div class="btn-group dropdown" role="group" dropdown container="body" [isDisabled]="1 | isReadonlyRole">
      <button
        type="button"
        class="btn btn-secondary-info add-invoice-btn"
        [disabled]="1 | isReadonlyRole"
        (click)="onNewInvoice(invoiceTypes.BookingInvoice)"
      >
        <span class="fal fa-usd-circle"></span> Add Invoice
      </button>
      <button dropdownToggle type="button" class="btn btn-secondary-info dropdown-toggle ml-0 add-invoice-btn"></button>
      <ul class="dropdown-menu" *dropdownMenu>
        <li>
          <button type="button" class="dropdown-item" (click)="onNewInvoice(invoiceTypes.GeneralInvoice)">
            General Invoice
          </button>
        </li>
        <li>
          <button type="button" class="dropdown-item" (click)="onApplyInvoiceTemplate()">Apply Invoice Template</button>
        </li>
      </ul>
    </div>
  </div>
</div>
