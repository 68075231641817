import { Directive, forwardRef, Input } from '@angular/core'
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { NativeDateAdapter } from '@angular/material/core'
import * as lodash from 'lodash'
import { DateRangePickerSelection } from '../date-range-picker/date-range-picker.component'
import { isSomething } from '../../functions'

@Directive({
  selector: 'app-mat-daterange-input[ranges]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DaterangeInputOverlapValidatorDirective), multi: true },
  ],
})
export class DaterangeInputOverlapValidatorDirective implements Validator {
  usedRanges: { start: number; end: number }[] = []
  @Input() set ranges(items: { start: number; end: number }[] | null) {
    if (!lodash.isEqual(this.usedRanges, items)) {
      this.usedRanges = items
      this._validatorOnChange()
    }
  }

  _validatorOnChange = () => {}

  private isInRange(d: number, r: { start: number; end: number }) {
    const rs = new Date(r.start * 1000)
    const re = new Date(r.end * 1000)
    const date = new Date(d * 1000)

    return this.dateAdapter.compareDate(date, rs) >= 0 && this.dateAdapter.compareDate(date, re) <= 0
  }

  private isOverlap = (r1: { start: number; end: number }, r2: { start: number; end: number }) => {
    return (
      this.isInRange(r1.start, r2) ||
      this.isInRange(r1.end, r2) ||
      this.isInRange(r2.start, r1) ||
      this.isInRange(r2.end, r1)
    )
  }

  private overlapValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const range = control.value as DateRangePickerSelection
    if (!range || !isSomething(range)) {
      return null
    }
    const overlap = lodash.find(this.usedRanges, (r) => this.isOverlap(r, { start: range.from, end: range.to }))

    return overlap ? { overlap: { range: overlap, actual: range } } : null
  }

  protected _getValidators(): ValidatorFn[] {
    return [this.overlapValidator]
  }

  /** The combined form control validator for this input. */
  protected _validator: ValidatorFn | null
  private dateAdapter = new NativeDateAdapter('en-GB')

  constructor() {
    this._validator = Validators.compose(this._getValidators())
  }

  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator ? this._validator(c) : null
  }
}
