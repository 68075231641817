import * as moment from 'moment'

export interface DateRange {
  start: moment.Moment
  end: moment.Moment
}

export interface CalendarFilters {
  rentals: string[]
  channels: string[]
  tags: string[]
  taggedIds: string[]

  start: any
  end: any
}

export interface AgendaCalendarFilters extends CalendarFilters {
  range: string
  type: string
}

export interface StandardCalendarFilters extends CalendarFilters {
  view: string
}

export interface PlanningCalendarFilters extends CalendarFilters {
  months: number[]
  tags: string[]
}

export enum MultiCalendarAvailabilityTypes {
  booked = 'booked',
  available = 'available',
}

export interface MultiCalendarFilters extends Exclude<CalendarFilters, 'start' | 'end'> {
  availabilityType: MultiCalendarAvailabilityTypes
  availabilityRange: DateRange
  search: string

  initialDate: number
  pageIndex: number
  pageSize: number
}
