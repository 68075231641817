import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-avatar-name',
  templateUrl: './avatar-name.component.html',
  styleUrls: ['./avatar-name.component.scss'],
})
export class AvatarNameComponent {
  @Input() name: string
  @Input() url: string
  @Input() image = true
  @Input() imageSize: number = 25
  @Input() imageCircle = true
  @Input() backgroundColor: string

  @Input() tooltipDisabled = false
}
