import { SelectionModel } from '@angular/cdk/collections'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { createLocaleCompareSort, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { Destroyable, roomCategories, TokeetImage, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'tokeet-frontend-image-table',
  templateUrl: './image-table.component.html',
  styleUrls: ['./image-table.component.scss'],
})
export class ImageTableComponent extends Destroyable implements OnInit, OnChanges {
  @Input() images: TokeetImage[] = []

  @Output() delete = new EventEmitter<TokeetImage>()
  @Output() selectChange = new EventEmitter<TokeetImage[]>()
  @Output() primary = new EventEmitter<TokeetImage>()

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  dataSource = new MatTableDataSource<TokeetImage>([])

  displayedColumns = ['select', 'image', 'resolution', 'created', 'size', 'category', 'description', 'edit']

  isEmptyTable$ = isEmptyTable(this.dataSource)
  selection = new SelectionModel<TokeetImage>(true, [])

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort(this.sortDataAccessor)

    this.dataSource['_data'].pipe(untilDestroy(this)).subscribe(() => {
      this.selection?.clear()
    })

    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selectChange.emit(this.selection.selected)
    })
  }

  sortDataAccessor = (item: TokeetImage, property: string) => {
    switch (property) {
      case 'category':
        return this.getCategory(item.metadata?.category)
      default:
        return item[property]
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images']) {
      this.dataSource.data = this.images
    }
  }

  getImageThumbUrl(url: string) {
    // https://cloudinary.com/documentation/image_transformations#img_trans_overview
    const thumbParams = 'w_50,c_limit,q_auto:eco'
    return (url + '').replace('/upload/', `/upload/${thumbParams}/`)
  }

  getCategory(id: number): string {
    return roomCategories.find((t) => t.id == id)?.name
  }

  onPrimary(image: TokeetImage) {
    this.primary.next(image)
  }

  onDelete(image: TokeetImage) {
    this.delete.next(image)
  }

  onOpenImage(image: TokeetImage) {
    window.open(image.secureUrl || image.url, '_blank')
  }

  masterToggle() {
    if (this.isAllSelected(this.selection, this.dataSource.data)) {
      this.selection.clear()
    } else {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row)
      })
    }
  }

  isAllSelected<T>(selection: SelectionModel<T>, data: T[]) {
    return selection.selected?.length === data?.length
  }
}
