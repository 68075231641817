<div class="modal-header">
  <h6 class="modal-title">Select Billing Address</h6>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2>
      <app-select
        [items]="items"
        bindLabel="address"
        bindValue="key"
        formControlName="address"
        placeholder="Billing Address"
        class="mat-block"
        required
      >
      </app-select>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light">
    <i class="far fa-times"></i>
    Cancel
  </button>
  <button (click)="select(form)" class="btn btn-secondary-info" type="button">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
