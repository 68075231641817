/* tslint:disable no-input-rename member-ordering */
import {
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms'
import { FocusMonitor } from '@angular/cdk/a11y'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import {
  DOWN_ARROW,
  END,
  ENTER,
  ESCAPE,
  hasModifierKey,
  HOME,
  LEFT_ARROW,
  RIGHT_ARROW,
  SPACE,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { delay, distinctUntilChanged, tap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { SelectActionButtonDirective, SelectItemDirective, SelectLabelDirective } from './select.directives'
import { MatSelect } from '@angular/material/select'
import { MatFormFieldControl } from '@angular/material/form-field'
import { ErrorStateMatcher, MatOption } from '@angular/material/core'

export type Selection<T> = T | T[]

// TODO:
// - improve panel max height, as mat-select set a fixed panel max height to SELECT_PANEL_MAX_HEIGHT = 256,
//   that causes mismatch panel postion in some cases as SelectComponent has different max height.
// - improve calculating max width, currently it addes a dummay item of the longest label to top
//   to leverage mat-select auto sizing, however this won't work well with custom item content.

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectComponent }],
})
export class SelectComponent<T = any, V = T>
  implements OnInit, OnDestroy, DoCheck, OnChanges, ControlValueAccessor, MatFormFieldControl<Selection<V>>
{
  /* MatFormFieldControl */

  static nextId = 0

  controlType = 'tokeet-select'

  @HostBinding() id = `${this.controlType}-${SelectComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''

  @HostBinding('class') className = 'mat-primary'

  stateChanges = new Subject<void>()
  errorState = false

  focused = false
  touched = false

  _value: Selection<V> = null
  _refinedValue: Selection<V> = null

  _placeholder: string
  _required = false
  _disabled = false

  valueMap = new Map<V, T>()

  /* MatSelect */

  // @Input('aria-label') ariaLabel: string;
  // @Input('aria-labelledby') ariaLabelledby: string;
  @Input() disableOptionCentering: boolean
  @Input() disableRipple: boolean
  @Input() errorStateMatcher: ErrorStateMatcher
  @Input() multiple: boolean
  @Input() panelClass: string | string[] | Set<string> | { [key: string]: any }

  @Input() compareWith = (o1: any, o2: any) => o1 === o2
  @Input() sortComparator: (a: MatOption, b: MatOption, options: MatOption[]) => number

  /* select component */

  @Input() itemHeight = 40
  @Input() visibleItems = 7

  _items: T[] = []
  @Input() set items(value: T[]) {
    this._items = value || []
  }
  get items(): T[] {
    return this._items
  }

  @Input() bindLabel: string | string[] | ((item: T) => string)
  @Input() bindValue: string | string[] | ((item: T) => V)

  @Input() searchable = false
  @Input() searchCompare: (item: T, search: string) => boolean

  @Input() searchPlaceholder = 'Search...'
  @Input() textNoItems = 'No data available'
  @Input() textNoMatch = 'No match'

  @Input() showMasterCheckbox = true
  @Input() showCloseButton = false

  @Input() showBlankItem = false
  @Input() blankLabel = 'None'
  @Input() blankValue = null

  @Output() openedChange = new EventEmitter<boolean>()
  @Output() searchChange = new EventEmitter<string>()
  @Output() selectionChange = new EventEmitter<Selection<V>>()
  @Output() selectedChange = new EventEmitter<Selection<T>>()

  _matSelect: MatSelect
  get matSelect(): MatSelect {
    return this._matSelect
  }
  @ViewChild('matSelect', { static: true }) set matSelect(value: MatSelect) {
    this._matSelect = value
    this._matSelect._offsetY = 15
    this._matSelect._positions = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'bottom',
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom',
      },
    ]
  }
  @ViewChild('scroller') scroller: CdkVirtualScrollViewport
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>

  @ContentChild(SelectItemDirective) selectItemDirective: SelectItemDirective<T>
  @ContentChild(SelectLabelDirective) selectLabelDirective: SelectLabelDirective<T, V>
  @ContentChild(SelectActionButtonDirective) selectActionButtonDirective: SelectActionButtonDirective

  filteredItems$ = new BehaviorSubject<T[]>([])
  focusedItemIndex$ = new BehaviorSubject<number>(-1)

  widestItem$ = new BehaviorSubject<T>(null)

  searchControl = new FormControl()

  isOpen = false

  searchSub: Subscription

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public elementRef: ElementRef,
    public focusMonitor: FocusMonitor
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit() {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe((origin) => this.handleFocus(!!origin))

    this.toggleSearchSubscribe(this.searchable)
  }

  ngOnDestroy() {
    this.stateChanges.complete()
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement)

    this.toggleSearchSubscribe(false)
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched
      this.stateChanges.next()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchable) {
      this.toggleSearchSubscribe(changes.searchable.currentValue)
    }

    if (changes.items) {
      setTimeout(() => {
        this.updateWidestItems()
        this.updateRefinedValue()
        this.updateValueMap(this.value)
        this.filterItems()

        this.stateChanges.next()
      })
    }
  }

  private _onChange = (value: Selection<V>) => {}
  private _onTouched = () => {
    this.touched = true
  }

  writeValue(value: Selection<V>): void {
    this.value = value
  }

  registerOnChange(fn): void {
    this._onChange = fn
  }

  registerOnTouched(fn): void {
    this._onTouched = fn
  }

  @Input()
  get value() {
    return this._refinedValue
  }
  set value(value: Selection<V>) {
    this._value = value
    this.updateValueMap(value)
    this.updateRefinedValue()

    if (this._onChange) this._onChange(value)
    this.stateChanges.next()
  }

  @Input()
  get placeholder() {
    return this._placeholder
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder
    this.stateChanges.next()
  }

  @Input()
  get required() {
    return this._required
  }
  set required(required) {
    this._required = coerceBooleanProperty(required)
    this.stateChanges.next()
  }

  @Input()
  get disabled() {
    return this._disabled
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis)
    this.stateChanges.next()
  }

  get empty() {
    return this.isEmpty(this.value)
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ')
  }

  handleFocus = (focused: boolean) => {
    this.focused = focused

    setTimeout(() => {
      this.stateChanges.next()
    })
  }

  isEmpty(val): boolean {
    return (
      (lodash.isObject(val) && lodash.isEmpty(val)) ||
      lodash.isUndefined(val) ||
      lodash.isNull(val) ||
      (val as any) === ''
    )
  }

  onContainerClick() {
    if (!this.focused) {
      this.matSelect.open()
    }
  }

  /* select component */

  getScrollHeight = (items: number) => {
    items = items || 0
    items = Math.min(items, this.visibleItems)

    const viewportHeight = document.documentElement.clientHeight

    let height = items * this.itemHeight

    height = Math.min(height, viewportHeight * 0.7) // max 70% viewport height
    height = Math.max(height, this.itemHeight) // min height for an item showing no match text

    return height
  }

  getPanelClass = (panelClass: string | string[] | Set<string> | { [key: string]: any }) => {
    let additionalClass = this.controlType + '-panel'

    if (this.multiple && this.showMasterCheckbox !== false) {
      additionalClass += ' ' + this.controlType + '-panel-master-checkbox'
    }

    if (lodash.isString(panelClass)) {
      panelClass = `${additionalClass} ${panelClass}`
    } else if (lodash.isArray(panelClass)) {
      panelClass = [additionalClass, ...panelClass]
    } else if (lodash.isSet(panelClass)) {
      panelClass = new Set(panelClass)
      panelClass.add(additionalClass, true)
    } else if (lodash.isPlainObject(panelClass)) {
      panelClass = { [additionalClass]: true, ...panelClass }
    } else {
      panelClass = additionalClass
    }

    return panelClass
  }

  onOpenedChange(opened: boolean) {
    this.openedChange.emit(opened)

    this.isOpen = opened

    if (opened) {
      let activeIndex = this.focusedItemIndex$.value

      if (activeIndex === -1 && !this.multiple) {
        activeIndex = this.getSelectedItemIndex()
      }

      if (activeIndex === -1) activeIndex = 0

      this.focusedItemIndex$.next(activeIndex)
      this.ensureItemVisible(activeIndex)
    } else {
      this.focusedItemIndex$.next(-1)
    }

    if (opened) {
      if (this.scroller) {
        this.scroller.checkViewportSize()
        setTimeout(() => this.scroller.checkViewportSize(), 500)
      }
      setTimeout(() => {
        if (this.searchable && this.searchInput && this.searchInput.nativeElement) {
          this.searchInput.nativeElement.focus()
        }
      })
    } else {
      this.clearSearch()
    }
  }

  onChangeSelection(value: Selection<V>) {
    setTimeout(() => {
      this._onTouched()
      if (this._onChange) this._onChange(value)
      this.stateChanges.next()

      this._value = value
      this.updateRefinedValue()
      this.selectionChange.emit(value)
      this.selectedChange.emit(Array.from(this.valueMap.values()))
    })
  }

  getItemIndexByValue(value: V) {
    return this.items.findIndex((item) => this.getValue(item) === value)
  }

  getSelectedItemIndex() {
    if (this.multiple || this.empty) return -1

    return this.getItemIndexByValue(this.value as V)
  }

  clearSearch() {
    this.searchControl.setValue('')
  }

  getLabel = (item: T): string => {
    const bindLabel = this.bindLabel

    if (lodash.isFunction(bindLabel)) return bindLabel(item)
    if (lodash.isString(bindLabel) || lodash.isArray(bindLabel)) return lodash.get(item, bindLabel)

    return lodash.toString(item)
  }

  getValue = (item: T): V => {
    const bindValue = this.bindValue

    if (lodash.isFunction(bindValue)) return bindValue(item)
    if (lodash.isString(bindValue) || lodash.isArray(bindValue)) return lodash.get(item, bindValue)

    return item as any
  }

  getTriggerContext = (valueMap: Map<V, T>) => {
    const values = Array.from(valueMap.keys())
    const items = Array.from(valueMap.values())

    return { items, values, removeItem: (item: T) => this.onItemClick(item) }
  }

  getTriggerValue = (items: T[]) => {
    const labels = items.map((item) => this.getLabel(item))
    return lodash.compact(labels).join(', ')
  }

  getItemChecked = (item: T, valueMap: Map<V, T>) => {
    return valueMap.get(this.getValue(item)) ? 'checked' : 'unchecked'
  }

  getMasterChecked = (items: T[], valueMap: Map<V, T>) => {
    if (!items || !items.length || !valueMap) return 'unchecked'

    const size = valueMap.size

    if (!size) return 'unchecked'
    if (size === items.length) return 'checked'

    return 'indeterminate'
  }

  toggleMasterCheckbox = () => {
    const state = this.getMasterChecked(this.items, this.valueMap)

    this.valueMap = new Map()
    const values: V[] = []

    if (state !== 'checked') {
      this.items.forEach((item) => {
        const itemValue = this.getValue(item)

        this.valueMap.set(itemValue, item)
        values.push(itemValue)
      })
    }

    this.onChangeSelection(values)
  }

  onItemClick(item: T) {
    const itemValue = this.getValue(item)

    if (this.multiple) {
      const selected = this.valueMap.get(itemValue)
      this.valueMap = new Map(this.valueMap)

      if (selected) {
        this.valueMap.delete(itemValue)
      } else {
        this.valueMap.set(itemValue, item)
      }

      const values = Array.from(this.valueMap.keys())
      this.onChangeSelection(values)

      this.focusedItemIndex$.next(this.getItemIndexByValue(itemValue))
    } else {
      this.valueMap = new Map()
      this.valueMap.set(itemValue, item)

      this.onChangeSelection(itemValue)
      this.close()
    }
  }

  open() {
    if (this.matSelect) this.matSelect.open()
  }

  close() {
    if (!this.matSelect) return
    this.matSelect.close()
    this.matSelect.focus()
  }

  toggle() {
    if (this.matSelect) this.matSelect.toggle()
  }

  get panelOpen() {
    return this.matSelect ? this.matSelect.panelOpen : false
  }

  private updateWidestItems() {
    let widestItem: T = null
    let maxLabelLength = -1

    for (let i = 0; i < this.items?.length; i++) {
      const item = this.items[i]
      const label = this.getLabel(item)

      if (label && label?.length > maxLabelLength) {
        widestItem = item
        maxLabelLength = label?.length
      }
    }

    this.widestItem$.next(widestItem)
  }

  private updateValueMap(val) {
    let values: V[] = []

    if (this.multiple) {
      if (lodash.isArray(val)) values = val
    } else if (!this.isEmpty(val)) {
      values = [val as V]
    }

    this.valueMap = new Map()

    if (!values.length) return this.valueMap

    values.forEach((value) => {
      this.valueMap.set(value, null)
    })

    this.items.forEach((item) => {
      const itemValue = this.getValue(item)
      if (this.valueMap.has(itemValue)) this.valueMap.set(itemValue, item)
    })
  }

  private toggleSearchSubscribe(enable = true) {
    if (this.searchSub) this.searchSub.unsubscribe()

    if (!enable) return

    this.searchSub = this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        delay(250),
        tap((search: string) => {
          this.searchChange.emit(lodash.trim(search).toLowerCase())
          this.filterItems()
        })
      )
      .subscribe()
  }

  private defaultSearchCompare = (item: T, search: string) => {
    const label = this.getLabel(item)

    return label && lodash.toString(label).toLowerCase().includes(search)
  }

  private filterItems = () => {
    let items = this.items || []

    const search = lodash.trim(this.searchControl.value).toLowerCase()
    const searchCompare = this.searchCompare || this.defaultSearchCompare

    if (search) {
      items = items.filter((item) => {
        return searchCompare(item, search)
      })
    } else {
      items = [...items]
    }

    if (this.showBlankItem) {
      items.unshift(this.blankValue)
    }

    this.filteredItems$.next(items)
  }

  handleSelectKeydown(event: KeyboardEvent) {
    if (this.isOpen) {
      this.handleOpenedKeydown(event)
    } else {
      this.handleClosedKeydown(event)
    }
  }

  handleInputKeydown(event: KeyboardEvent) {
    event.stopPropagation()
    const { keyCode } = this.getKeyboardEventInfo(event)

    if (keyCode !== SPACE) {
      this.handleOpenedKeydown(event)
    }
  }

  handleItemKeydown(event: KeyboardEvent) {
    event.stopPropagation()
    event.preventDefault()
    this.handleOpenedKeydown(event)
  }

  handleClosedKeydown(event: KeyboardEvent) {
    const { keyCode, isArrowKey, isOpenKey } = this.getKeyboardEventInfo(event)

    if ((isOpenKey && !hasModifierKey(event)) || ((this.multiple || event.altKey) && isArrowKey)) {
      event.preventDefault()
      this.open()
    } else if (!this.multiple) {
      if (keyCode === HOME) {
        this.setItemSelected('first')
      } else if (keyCode === END) {
        this.setItemSelected('last')
      } else if (keyCode === UP_ARROW) {
        this.setItemSelected('prev')
      } else if (keyCode === DOWN_ARROW) {
        this.setItemSelected('next')
      }
    }
  }

  handleOpenedKeydown(event: KeyboardEvent) {
    const { keyCode, isSelectKey } = this.getKeyboardEventInfo(event)

    if (keyCode === HOME) {
      this.setItemFocused('first')
    } else if (keyCode === END) {
      this.setItemFocused('last')
    } else if (keyCode === UP_ARROW) {
      this.setItemFocused('prev')
    } else if (keyCode === DOWN_ARROW) {
      this.setItemFocused('next')
    } else if (keyCode === TAB || keyCode === ESCAPE) {
      this.close()
    } else if (isSelectKey) {
      const item = this.filteredItems$.value[this.focusedItemIndex$.value]
      this.onItemClick(item)
    }
  }

  private setItemSelected(type: 'first' | 'last' | 'prev' | 'next') {
    if (!this.items.length) return

    const index = this.getNextItemIndex(type, this.getSelectedItemIndex())

    this.onItemClick(this.items[index])
  }

  private setItemFocused(type: 'first' | 'last' | 'next' | 'prev') {
    if (!this.items.length) return

    const index = this.getNextItemIndex(type, this.focusedItemIndex$.value)

    this.focusedItemIndex$.next(index)
    this.ensureItemVisible(index)
  }

  private ensureItemVisible(index: number) {
    if (!this.scroller || !this.scroller.elementRef) return

    const itemHeight = this.itemHeight
    const scrollElement = this.scroller.elementRef.nativeElement
    const clientHeight = scrollElement.clientHeight
    const startOffset = scrollElement.scrollTop
    const endOffset = startOffset + clientHeight

    const startIndex = Math.ceil(startOffset / itemHeight)
    const endIndex = Math.max(0, Math.floor(endOffset / itemHeight) - 1)

    let offset = -1

    if (index < startIndex) {
      offset = index * itemHeight
    } else if (index > endIndex) {
      offset = (index + 1) * itemHeight - clientHeight
    }

    if (offset >= 0) {
      this.scroller.scrollToOffset(offset, 'auto')
    }
  }

  private getNextItemIndex(type: 'first' | 'last' | 'next' | 'prev', activeIndex: number) {
    let index: number

    if (type === 'first') {
      index = 0
    } else if (type === 'last') {
      index = this.items.length - 1
    } else {
      index = activeIndex + (type === 'prev' ? -1 : 1)
    }

    index = Math.min(Math.max(index, 0), this.items.length - 1)

    return index
  }

  private updateRefinedValue() {
    this._refinedValue = this.getRefinedValue(this._value, this.items)
  }

  private getRefinedValue(value: Selection<V>, items: T[] = []) {
    const map = new Map<V, boolean>()

    for (let i = 0; i < items.length; i++) {
      map.set(this.getValue(items[i]), true)
    }

    if (!lodash.isArray(value)) {
      return map.get(value) ? value : null
    } else {
      return lodash.filter(value, (v) => map.get(v))
    }
  }

  private getKeyboardEventInfo(event: KeyboardEvent) {
    const keyCode = event.keyCode
    const isArrowKey =
      keyCode === DOWN_ARROW || keyCode === UP_ARROW || keyCode === LEFT_ARROW || keyCode === RIGHT_ARROW
    const isEnterOrSpace = keyCode === ENTER || keyCode === SPACE

    return {
      keyCode,
      isArrowKey,
      isOpenKey: isEnterOrSpace,
      isSelectKey: isEnterOrSpace,
    }
  }
}
