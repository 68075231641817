<form [formGroup]="form">
  <ng-template matStepLabel>Review Pets Policy</ng-template>
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field2 class="mat-block">
            <mat-label>Pets Policy</mat-label>
            <mat-select formControlName="pets_policy" required>
              <mat-option *ngFor="let item of petPolicies" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-6" *ngIf="isPetsFeeRequired">
          <mat-form-field2 class="mat-block">
            <mat-label>Pets Fee</mat-label>
            <mat-select formControlName="pets_fee" required>
              <mat-option *ngFor="let item of petFeeOptions" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </div>
    </div>
  </div>
</form>
