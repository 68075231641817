import { FormControl } from '@angular/forms'
import * as R from 'ramda'

export function noAllWhitespaceValidator(control: FormControl) {
  if (R.isNil(control.value) || !R.is(String, control.value) || control.value.length === 0) {
    return null
  }
  const isWhitespace = (control.value + '').trim().length === 0
  const isValid = !isWhitespace
  return isValid ? null : { allWhitespace: true }
}
