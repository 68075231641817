import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs'
import * as R from 'ramda'

import * as fromRoot from '@tv3/store/state'
import { MultiCalendarEventView } from '@tv3/store/calendar/calendar.view'
import { Destroyable, Rental } from '@tokeet-frontend/tv3-platform'
import { MessageSidePanelService } from '@tv3/containers/inquiries/dialogs/message/message-side-panel.service'
import { NotesSidePanelService } from '@tv3/containers/inquiries/dialogs/notes/notes/notes-side-panel.service'
import { Inquiry, InquiryPaymentStatus } from '@tv3/store/inquiry/inquiry.model'
import { BOOKING_DETAILS_DATA } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.token'
import { untilDestroy } from '@tokeet-frontend/tv3-platform'
import { bookingDetailsPopupAnimation } from '@tv3/animations/booking-details-popup.animation'
import { OpenGuestOverlay, OpenInquiryOverlay, OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { selectSome } from '@tokeet-frontend/tv3-platform'
import { MarkPaidInquiry, MarkUnpaidInquiry, SetInquiryColor } from '@tv3/store/inquiry/inquiry-fields.actions'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { AuthService } from '@tv3/services/auth.service'
import { NgxPermissionsService } from 'ngx-permissions'
import { HasPermission } from '@tokeet-frontend/permission'

@Component({
  selector: 'app-booking-details-popup',
  templateUrl: './booking-details-popup.component.html',
  animations: [bookingDetailsPopupAnimation.popupState],
  host: { class: 'multi-calendar-page popover-event-details booking-details-popup' },
  styleUrls: ['./booking-details-popup.component.scss'],
})
export class BookingDetailsPopupComponent extends Destroyable implements OnInit {
  event: MultiCalendarEventView
  rental: Rental
  inquiry: Inquiry

  _visibility: 'initial' | 'visible' | 'hidden' = 'initial'

  private readonly _onHide: Subject<any> = new Subject()

  isInitializing = true

  isDialog = false

  get currentUser() {
    return this.auth.user
  }
  get isOwner() {
    return this.auth.hasRole('owner')
  }

  canSeeCharges$ = new BehaviorSubject(false)

  hasPermission$(permissions: NgxPermissionsService, per: string | string[]) {
    return from(permissions.hasPermission(per))
  }

  constructor(
    @Inject(BOOKING_DETAILS_DATA) @Optional() public data: any,
    @Inject(MAT_DIALOG_DATA) @Optional() public dialogData: any,
    @Optional() public dialogRef: MatDialogRef<BookingDetailsPopupComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private messageSidePanel: MessageSidePanelService,
    private noteSidePanel: NotesSidePanelService,
    private store: Store<fromRoot.State>,
    public inquiryService: InquiryService,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    public permissions: NgxPermissionsService,
    private auth: AuthService
  ) {
    super()
  }

  ngOnInit() {
    const data = this.data ? this.data : this.dialogData

    const canSeeCharges$ = () => {
      if (this.isOwner) {
        return of(!!this.currentUser?.settings?.total)
      } else {
        return this.hasPermission$(this.permissions, 'canEditInquiry')
      }
    }
    canSeeCharges$().subscribe((canSeeCharges) => {
      this.canSeeCharges$.next(canSeeCharges)
    })

    this.rental = data.rental
    this.inquiry = data.inquiry

    if (this.dialogData) {
      this.show()
      this.isDialog = true
    }

    setTimeout(() => {
      this.isInitializing = false
    }, 500)

    this.store.pipe(selectSome(selectInquiry, { id: this.inquiry.id }), untilDestroy(this)).subscribe((inquiry) => {
      this.inquiry = inquiry
    })
  }

  onOpenMessages() {
    this.onClose()
    this.messageSidePanel.open(this.inquiry.id)
  }

  onOpenNotes() {
    this.onClose()
    this.noteSidePanel.open(this.inquiry)
  }

  onOpenInquiry() {
    this.onClose()
    this.store.dispatch(OpenInquiryOverlay({ inquiryId: this.inquiry.id }))
  }

  onOpenRental() {
    this.onClose()
    this.store.dispatch(OpenRentalOverlay({ rental: this.rental }))
  }

  onEditGuest() {
    this.onClose()
    this.store.dispatch(OpenGuestOverlay({ guestId: this.inquiry.guestId }))
  }

  onClone() {
    this.onClose()
    this.inquirySharedDialogsService.cloneInquiry(this.inquiry)
  }

  @HasPermission('canEditInquiry')
  onChangePaymentStatus(currentStatus: InquiryPaymentStatus) {
    if (currentStatus === InquiryPaymentStatus.Paid) {
      this.onUnPaid()
    } else {
      this.onPaid()
    }
  }

  onPaid() {
    this.store.dispatch(MarkPaidInquiry({ inquiry: this.inquiry }))
  }

  onUnPaid() {
    this.store.dispatch(MarkUnpaidInquiry({ inquiry: this.inquiry }))
  }

  onClose() {
    if (this.isDialog && this.dialogRef) {
      this.dialogRef.close()
    } else {
      this.hide()
    }
  }

  onChangeInquiryColor(color) {
    this.store.dispatch(SetInquiryColor({ inquiry: this.inquiry, color }))
  }

  isVisible(): boolean {
    return this._visibility === 'visible'
  }

  show() {
    this._visibility = 'visible'
    this._markForCheck()
  }

  hide() {
    this._visibility = 'hidden'
    this._onHide.next(this._visibility)
    this._onHide.complete()
    this._markForCheck()
  }

  afterHidden(): Observable<void> {
    return this._onHide.asObservable()
  }

  _markForCheck(): void {
    this._changeDetectorRef.markForCheck()
  }

  _animationDone(event): void {
    const toState = event.toState as 'initial' | 'visible' | 'hidden'

    if (toState === 'hidden' && !this.isVisible()) {
      this._onHide.next()
    }
  }

  ngOnDestroy() {
    this._onHide.next(true)
    this._onHide.complete()
  }
}
