<div
  [formGroup]="form"
  class="d-grid gap-3"
  [style.gridTemplateColumns]="modalityEnabled ? '1fr 1fr 1fr 1fr 40px' : '1fr 1fr 1fr 40px'"
>
  <div>
    <mat-form-field2 class="mat-block" matAutocompleteOrigin #origin="matAutocompleteOrigin">
      <input
        type="text"
        formControlName="name"
        matInput
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
        required
        placeholder="Tax Name:"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let tax of filteredTaxes$ | async" [value]="tax.name">
          {{ tax.name }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
        Tax name is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
        Tax name must be at least <strong>2</strong> characters long
      </mat-error>
      <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
        Tax name must be at most <strong>50</strong> characters long
      </mat-error>
    </mat-form-field2>
  </div>
  <div>
    <mat-form-field2 class="mat-block">
      <mat-label>
        <span [innerHtml]="form.get('type').value | amountPlaceholder : '$'"></span>
      </mat-label>
      <input type="number" min="0" formControlName="amount" required matInput decimal />
      <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
        Amount is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('percent')">
        Amount should be at least <b>0</b> and less than <b>100</b>
      </mat-error>
      <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('minNumber')">
        Amount must be at least <strong>0</strong>
      </mat-error>
    </mat-form-field2>
  </div>
  <div>
    <mat-form-field2 class="mat-block">
      <mat-select formControlName="type" placeholder="Type">
        <mat-option value="percent">Percent</mat-option>
        <mat-option value="flat">Flat</mat-option>
      </mat-select>
    </mat-form-field2>
  </div>
  <div *ngIf="modalityEnabled">
    <mat-form-field2 class="mat-block">
      <mat-select formControlName="modality" placeholder="Modality">
        <mat-option [value]="taxV3Modalities.PerStay">{{ taxV3ModalityLabels[taxV3Modalities.PerStay] }}</mat-option>
        <mat-option [value]="taxV3Modalities.PerNight">{{ taxV3ModalityLabels[taxV3Modalities.PerNight] }}</mat-option>
        <mat-option [value]="taxV3Modalities.PerPerson">{{
          taxV3ModalityLabels[taxV3Modalities.PerPerson]
        }}</mat-option>
        <mat-option [value]="taxV3Modalities.PerPersonPerNight">{{
          taxV3ModalityLabels[taxV3Modalities.PerPersonPerNight]
        }}</mat-option>
      </mat-select>
    </mat-form-field2>
  </div>
  <button (click)="remove.emit(index)" class="btn btn-secondary-danger align-input-row-end" type="button">
    <i class="cs-icon cs-icon-trash"></i>
  </button>
</div>
