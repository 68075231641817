import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromCustomCode from './custom-code.reducer'
import { EffectsModule } from '@ngrx/effects'
import { CustomCodeEffects } from './custom-code.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('custom-codes', fromCustomCode.customCodeReducer),
    EffectsModule.forFeature([CustomCodeEffects]),
  ],
  declarations: [],
})
export class CustomCodeModule {}
