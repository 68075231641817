import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GatewaysService } from './gateways.service'
import { StoreModule } from '@ngrx/store'
import * as fromGateways from './gateways.reducer'
import { EffectsModule } from '@ngrx/effects'
import { GatewaysEffects } from './gateways.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('gateways', fromGateways.gatewaysReducer),
    EffectsModule.forFeature([GatewaysEffects]),
  ],
  declarations: [],
  providers: [GatewaysService],
  exports: [],
})
export class GatewaysModule {}
