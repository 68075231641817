import { Pipe, PipeTransform } from '@angular/core'
import { BookingCostResolver } from '@tokeet/cost-resolver'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Pipe({
  name: 'bookingTotal',
})
export class BookingTotalPipe implements PipeTransform {
  transform(inquiry: Inquiry): number {
    const cost = BookingCostResolver.parseBookingCharges(inquiry)
    return cost?.sum || 0
  }
}
