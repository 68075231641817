import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators'
import { SearchResponse } from '@tv3/interfaces/responses/search.response'
import {
  ESResult,
  GuestSearchResult,
  InquirySearchResult,
  InvoiceSearchResult,
} from '@tv3/store/search/search-result.model'
import * as R from 'ramda'
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectInquiries } from '@tv3/store/inquiry/inquiry.selectors'
import { Guest } from '@tv3/store/guest/guest.model'
import {
  deserializeArray,
  epochToView,
  epochToViewUTC,
  getNightsBetweenArrivalDepart,
  selectOnce,
  sortAscend,
} from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { Invoice } from '@tokeet-frontend/invoices'

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {}

  search<T>(term: string, filter?: 'invoice' | 'inquiry' | 'guest' | 'hold_event') {
    const url = `@api/search/?q=${term.trim()}`

    if (R.isEmpty(term)) {
      return of([])
    }

    return this.http.get<SearchResponse>(url).pipe(
      map((response) => response.results),
      map((results) => R.take(20, results)),
      deserializeArray<ESResult>(ESResult),
      map((results) => R.sortBy((r: ESResult) => -r.score, results)),
      map((results) => {
        if (filter) {
          return R.pipe(
            R.filter((r: ESResult) => r.source.object === filter),
            R.map((r: ESResult) => r.source)
          )(results)
        }
        return results
      })
    )
  }

  searchInquiries(
    term: string,
    filters?: { rental_ids?: string[]; guest_ids?: string[]; booked?: 0 | 1 }
  ): Observable<Inquiry[]> {
    const url = `@api/search/inquiry/`

    return this.http.get<SearchResponse>(url, { params: { q: term, ...filters } }).pipe(
      map((response) => response.results),
      deserializeArray<ESResult>(ESResult),
      map((results) => R.map((r: ESResult) => r.source as InquirySearchResult, results)),
      map((inquiries) => this.mapSearchResultsToInquiry(inquiries))
    )
  }

  searchGuests(term: string): Observable<Guest[]> {
    const url = `@api/search/guest/?q=${term}`

    return this.http.get<SearchResponse>(url).pipe(
      map((response) => response.results),
      deserializeArray<ESResult>(ESResult),
      map((results) => R.map((r: ESResult) => r.source as GuestSearchResult, results)),
      map((guests) => this.mapSearchResultsToGuest(guests))
    )
  }

  searchInvoices(term: string): Observable<Invoice[]> {
    const url = `@api/search/invoice/?q=${term}`

    return this.http.get<SearchResponse>(url).pipe(
      map((response) => response.results),
      deserializeArray<ESResult>(ESResult),
      map((results) => R.map((r: ESResult) => r.source as InvoiceSearchResult, results)),
      map((invoices) => this.mapSearchResultsToInvoice(invoices))
    )
  }

  getInquiries(search: BehaviorSubject<string>) {
    let temp: InquirySearchResult[] = []

    return search.pipe(
      startWith(''),
      debounceTime(375),
      distinctUntilChanged(),
      switchMap((term) =>
        this.search<InquirySearchResult>(term, 'inquiry').pipe(
          map((inquiries: InquirySearchResult[]) => ({
            inquiries,
            term,
          }))
        )
      ),
      switchMap(({ inquiries, term }) => {
        if (R.isEmpty(term) && R.isEmpty(temp)) {
          return this.getRecent()
        }
        return of(inquiries)
      }),
      map((inquiries) => {
        temp = R.pipe(
          R.concat(temp),
          R.uniqBy((r) => r.id),
          sortAscend('title')
        )(inquiries)
        return temp
      })
    )
  }

  getRecent(): Observable<InquirySearchResult[]> {
    const url = `@api/inquiry/all/?limit=10&skip=0`

    const recentInquiries: Observable<InquirySearchResult[]> = this.http.get<object[]>(url).pipe(
      deserializeArray<Inquiry>(Inquiry),
      map(this.mapInquiryToSearchResults),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )

    return this.store.pipe(
      selectOnce(selectInquiries),
      switchMap((inquiries) => {
        if (R.isEmpty(inquiries)) {
          return recentInquiries
        } else {
          const searchResults = this.mapInquiryToSearchResults(inquiries)
          return of(searchResults)
        }
      })
    )
  }

  mapInquiryToSearchResults(inquiries: Inquiry[]) {
    return R.map((i: Inquiry) => {
      const searchResult = new InquirySearchResult()

      searchResult.id = i.id
      searchResult.object = 'inquiry'
      searchResult.numChild = i.numChild
      searchResult.guestArrive = i.guestArrive
      searchResult.rentalId = i.rentalId
      searchResult.numAdults = i.numAdults
      searchResult.guestId = i.guestId
      searchResult.guestDepart = i.guestDepart
      searchResult.inquirySource = i.inquirySource
      searchResult.title =
        R.pathOr('', ['guestDetails', 'name'], i).toString().trim() ||
        R.pathOr('', ['guestDetails', 'email'], i).toString().trim()
      searchResult.booked = i.booked
      searchResult.account = i.account
      searchResult.created = i.created

      return searchResult
    }, inquiries)
  }

  mapSearchResultsToInquiry(inquiries: InquirySearchResult[]) {
    return R.map((i: InquirySearchResult) => {
      let inquiry = new Inquiry()
      inquiry = R.merge(inquiry, {
        id: i.id,
        created: i.created,
        createdView: epochToView(i.created),
        inquirySource: i.inquirySource,
        booked: i.booked,
        touch: i.touch,
        touchView: epochToView(i.touch),
        receivedOn: i.receivedOn,
        receivedOnView: epochToView(i.receivedOn),
        guestNameView: i.title,
        guestArrive: i.guestArrive,
        arriveView: epochToViewUTC(i.guestArrive),
        guestDepart: i.guestDepart,
        departView: epochToViewUTC(i.guestDepart),
        nightsView: getNightsBetweenArrivalDepart(i.guestArrive, i.guestDepart),
        guestsView: `${R.defaultTo(0, i.numAdults) + R.defaultTo(0, i.numChild) || '...'}`,
        numChild: i.numChild,
        rentalId: i.rentalId,
        numAdults: i.numAdults,
        guestId: i.guestId,
        account: i.account,
        read: 1,
      } as Inquiry)
      return inquiry
    }, inquiries)
  }

  private mapSearchResultsToGuest(guests: GuestSearchResult[]) {
    return R.map((g: GuestSearchResult) => {
      let guest = new Guest()
      const primaryEmail = R.pipe(R.when(R.is(Number), R.toString), R.defaultTo(''))(g.primaryEmail)
      guest = R.merge(guest, {
        id: g.id,
        guestId: g.id,
        lastMessage: g.lastMessage,
        account: g.account,
        created: g.created,
        lastMessageView: epochToView(g.lastMessage),
        primaryEmail,
        primaryEmailView: primaryEmail.replace(/\%2E/g, '.'),
        source: g.source,
        name: g.title,
        username: g.title,
        address: <any>{},
      } as Partial<Guest>)

      return guest
    }, guests)
  }

  private mapSearchResultsToInvoice(invoices: InvoiceSearchResult[]) {
    return R.map((i: InvoiceSearchResult) => {
      let invoice = new Invoice()
      invoice = R.merge(invoice, {} as Partial<Invoice>)

      return invoice
    }, invoices)
  }
}
