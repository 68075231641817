import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export interface EntityTagsResponse {
  entity_type: DataEntityType
  pkey: string // '1f5bba6d-81d0-44bf-a2ab-568e6c09455a'
  tags: string[] // ['alpah', 'beta']
  account: number // 1531831211.5715
  entity_pkey: string // '3b2234f3-1ac6-46da-9dab-57cab0097e82'
}

export function uniqTags(tags: string[], caseSenstive = false) {
  return lodash.uniqBy(tags, (t) => (caseSenstive ? t : lodash.toLower(t)))
}
