export const rentalTypes = [
  'Apartment',
  'Barn',
  'Boat',
  'B&B',
  'Building',
  'Bungalow',
  'Cabin',
  'Campground',
  'Caravan',
  'Castle',
  'Chacara',
  'Chalet',
  'Chateau',
  'Condo',
  'Cottage',
  'Estate',
  'Farmhouse',
  'Guesthouse',
  'Hostel',
  'Hotel',
  'House',
  'Lodge',
  'Mas',
  'Mill',
  'Mobile Home',
  'Other',
  'Private House',
  'Private Room',
  'RV',
  'Resort',
  'Riad',
  'Studio',
  'Tower',
  'Townhouse',
  'Trullo',
  'Villa',
  'Yacht',
]
