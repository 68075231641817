import { Component, ContentChild, ContentChildren, Input, OnInit, QueryList } from '@angular/core'
import {
  MatLabel,
  MatPlaceholder,
  MatError,
  MatHint,
  MatPrefix,
  MatSuffix,
  MatFormFieldControl,
} from '@angular/material/form-field'

@Component({
  selector: 'mat-form-field2',
  templateUrl: './mat-form-field2.component.html',
  styleUrls: ['./mat-form-field2.component.scss'],
  host: {
    class: 'mat-form-field2',
    '[class.is-invalid]': '_control?.errorState',
    '[class.is-disabled]': '_control?.disabled',
    '[class.is-autofilled]': '_control?.autofilled',
    '[class.is-focused]': '_control?.focused',
    '[class.is-filled]': '!_control?.empty',
    '[class.inline]': '!showLabel',
  },
})
export class MatFormField2Component implements OnInit {
  @Input() showLabel = true
  @Input() hidePlaceholder = false

  @ContentChild(MatFormFieldControl) _controlNonStatic: MatFormFieldControl<any>
  @ContentChild(MatFormFieldControl, { static: true }) _controlStatic: MatFormFieldControl<any>
  get _control() {
    // TODO(crisbeto): we need this workaround in order to support both Ivy and ViewEngine.
    //  We should clean this up once Ivy is the default renderer.
    return this._explicitFormFieldControl || this._controlNonStatic || this._controlStatic
  }
  set _control(value) {
    this._explicitFormFieldControl = value
  }
  private _explicitFormFieldControl: MatFormFieldControl<any>

  @ContentChild(MatPlaceholder) _placeholderChild: MatPlaceholder
  @ContentChild(MatLabel) _labelChildNonStatic: MatLabel
  @ContentChild(MatLabel, { static: true }) _labelChildStatic: MatLabel
  get _labelChild() {
    return this._labelChildNonStatic || this._labelChildStatic
  }
  @ContentChildren(MatError, { descendants: true }) _errorChildren: QueryList<MatError>
  @ContentChildren(MatHint, { descendants: true }) _hintChildren: QueryList<MatHint>
  @ContentChildren(MatPrefix, { descendants: true }) _prefixChildren: QueryList<MatPrefix>
  @ContentChildren(MatSuffix, { descendants: true }) _suffixChildren: QueryList<MatSuffix>
  constructor() {}

  ngOnInit(): void {}

  _hasLabel() {
    return !!this._labelChild
  }

  _hasPlaceholder() {
    return !!((this._control && this._control?.placeholder) || this._placeholderChild)
  }

  /** Determines whether to display hints or errors. */
  _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren && this._errorChildren.length > 0 && this._control?.errorState ? 'error' : 'hint'
  }
}
