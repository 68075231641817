<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-file-alt"></i>&nbsp;<span *ngIf="!isEdit">Add New Page</span>
    <span *ngIf="isEdit">Update Page</span>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="row">
      <div class="col-4">
        <app-select-search
          [items]="availableWebsitePageTypes"
          [ctrl]="form.get('type')"
          [multiple]="false"
          bindValue="id"
          bindLabel="label"
          placeholder="Page Type"
          [inline]="false"
        >
          <ng-container class="error-container">
            <mat-error> Page Type is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
      </div>
      <div class="col-4">
        <app-select-search
          [items]="availableWebsitePageGroups"
          [hasButton]="data.website.type === websiteTypes.RentalWebsite"
          buttonText="Add Group"
          (buttonAction)="addGroup($event)"
          [multiple]="false"
          [inline]="false"
          [placeholder]="'Select group'"
          [ctrl]="form.get('group')"
          required
          matTooltip="Page groups allow you to create dropdown menus by placing multiple pages into the same group. The group name will become the menu title. The default group places each page individually on the toolbar."
          matTooltipPosition="above"
        >
          <ng-container class="error-container">
            <mat-error> Group is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
      </div>
      <div class="col-4" *ngIf="data.website.type === websiteTypes.RentalWebsite">
        <mat-form-field2 class="mat-block">
          <app-select
            [items]="availableWebsitePageOrders"
            formControlName="order"
            bindLabel="label"
            bindValue="id"
            [multiple]="false"
            placeholder="Toolbar Link Order"
            matTooltip="Link order allows you to change the order of your pages on the toolbar or in a group. The lowest link order in a group is the overall order of that group."
            matTooltipPosition="above"
            required
          >
          </app-select>
          <mat-error *ngIf="form.get('order').touched && form.get('order').hasError('required')">
            Toolbar Link Order is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>

    <!-- Content Page -->
    <div class="row" *ngIf="form.get('type').value === websitePageTypes.Content">
      <div class="col-8">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required placeholder="Page Title" formControlName="title" />
          <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
            Page Title is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-4">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required placeholder="Link Title" formControlName="linkTitle" />
          <mat-error *ngIf="form.get('linkTitle').touched && form.get('linkTitle').hasError('required')">
            Link Title is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-12">
        <editor [init]="editorOptions" [formControl]="form.get('body')" [initialValue]="form.get('body').value">
        </editor>
        <mat-error *ngIf="form.get('body').touched && form.get('body').hasError('required')" class="ml5">
          Page Content is <strong>required</strong>
        </mat-error>
      </div>
    </div>
    <!-- External Link-->
    <div class="row" *ngIf="form.get('type').value === websitePageTypes.ExternalLink">
      <div class="col-8">
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            matInput
            required
            placeholder="Absolute Link URL: (e.g. https://www.tokeet.com)"
            formControlName="linkurl"
          />
          <mat-error *ngIf="form.get('linkurl').touched && form.get('linkurl').hasError('required')">
            URL is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('linkurl').touched && form.get('linkurl').hasError('url')">
            URL is <strong>invalid</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-4">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required placeholder="Link Title" formControlName="linkTitle" />
          <mat-error *ngIf="form.get('linkTitle').touched && form.get('linkTitle').hasError('required')">
            Link Title is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <!-- Internal Link -->
    <div class="row" *ngIf="form.get('type').value === websitePageTypes.InternalLink">
      <div class="col-8">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required placeholder="Internal Page Link Title" formControlName="linkurl" />
          <mat-error *ngIf="form.get('linkurl').touched && form.get('linkurl').hasError('required')">
            Internal Page Link Title is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-4">
        <mat-form-field2 class="mat-block">
          <input type="text" matInput required placeholder="Link Title" formControlName="linkTitle" />
          <mat-error *ngIf="form.get('linkTitle').touched && form.get('linkTitle').hasError('required')">
            Link Title is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="onSave(form)"
    [disabled]="1 | isReadonlyRole"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
