<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-key"></i> Restrict Access
    <a
      matTooltip="Click here to see the associated help article"
      href="https://help.tokeet.com/settings-and-configurations/restricting-user-access"
      target="_blank"
      ><i class="fal fa-question-circle"></i
    ></a>
  </h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="offset-2 col-sm-8">
        <app-select-search
          [inline]="false"
          [items]="users$ | async"
          [ctrl]="form.get('users')"
          bindLabel="name"
          bindValue="id"
          placeholder="Select user(s)"
        ></app-select-search>
      </div>
    </div>
    <div class="row">
      <div class="offset-2 col-sm-8">
        <p [innerHTML]="description"></p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
