import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

import { EditRateDialogComponent, EditRateDialogInput, EditRateDialogResult } from './edit-rate-dialog.component'

@Injectable()
export class EditRateDialogService {
  constructor(private dialog: MatDialog) {}

  public open(data: EditRateDialogInput) {
    const defaultConfig: MatDialogConfig<EditRateDialogInput> = {
      width: '750px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data,
    }

    return this.dialog.open<EditRateDialogComponent, EditRateDialogInput, EditRateDialogResult>(
      EditRateDialogComponent,
      defaultConfig
    )
  }
}
