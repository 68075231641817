<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h5 class="modal-title">Pricing Settings</h5>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="save(form)" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="main-content">
        <div class="container-box">
          <div class="row">
            <div class="col-sm-6">
              <p class="subtitle">Taxes</p>
            </div>
          </div>

          <form [formGroup]="form">
            <div formArrayName="taxes" *ngFor="let item of taxes.controls; let i = index" class="row form-block">
              <!--                *ngIf="item['controls'].airbnb?.value"-->
              <!--                *ngIf="item['controls'].tokeet?.value"-->
              <button
                (click)="removeTax(i)"
                matTooltip="Remove tax"
                matTooltipPosition="above"
                class="btn btn-secondary-danger remove-tax-btn"
              >
                <i class="fal fa-minus"></i>
              </button>
              <div [formGroupName]="i" class="col-sm-12">
                <div class="tax-block">
                  <div class="row">
                    <div class="col-sm-2">
                      <mat-form-field2 class="mat-block">
                        <mat-select formControlName="tax_type" placeholder="Tax Type">
                          <mat-option value="pass_through_hotel_tax">Hotel Tax</mat-option>
                          <mat-option value="pass_through_lodging_tax">Lodging Tax</mat-option>
                          <mat-option value="pass_through_room_tax">Room Tax</mat-option>
                          <mat-option value="pass_through_tourist_tax">Tourist Tax</mat-option>
                          <mat-option value="pass_through_transient_occupancy_tax">Transient Occupancy Tax</mat-option>
                          <mat-option value="pass_through_sales_tax">Sales Tax</mat-option>
                          <mat-option value="pass_through_vat_gst">VAT / GST</mat-option>
                          <mat-option value="pass_through_tourism_assessment_fee">Tourism Assessment Fee</mat-option>
                        </mat-select>
                        <mat-error *ngIf="item['controls'].tax_type.touched && item.hasError('required', ['tax_type'])">
                          Value is <strong>required</strong>
                        </mat-error>
                      </mat-form-field2>
                    </div>
                    <div class="col-sm-2">
                      <mat-form-field2 class="mat-block">
                        <mat-select formControlName="amount_type" placeholder="Amount Type">
                          <mat-option value="percent_per_reservation">Percent per reservation</mat-option>
                          <mat-option value="flat_per_guest">Flat per guest</mat-option>
                          <mat-option value="flat_per_night">Flat per night</mat-option>
                          <mat-option value="flat_per_guest_per_night">Flat per guest per night</mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="item['controls'].amount_type.touched && item.hasError('required', ['amount_type'])"
                        >
                          Value is <strong>required</strong>
                        </mat-error>
                      </mat-form-field2>
                    </div>
                    <div class="col-sm-2">
                      <mat-form-field2 class="mat-block">
                        <input type="text" formControlName="amount" matInput placeholder="Amount" />
                        <span matSuffix>{{
                          item.value?.amount_type === 'percent_per_reservation' ? '%' : rental?.currency?.code
                        }}</span>
                        <mat-error *ngIf="item['controls'].amount.touched && item.hasError('required', ['amount'])">
                          Value is <strong>required</strong>
                        </mat-error>
                      </mat-form-field2>
                    </div>
                    <div class="col-sm-6">
                      <div class="tax-type">
                        <mat-checkbox formControlName="includeAirbnb">
                          <i class="fab fa-airbnb tax-airbnb"></i> Include on Airbnb
                        </mat-checkbox>
                        <mat-checkbox formControlName="includeTokeet">
                          <img [src]="'../assets/migration-sympl/tokeet-icon.png'" class="tax-tokeet" alt="" /> Include
                          on AdvanceCM
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <mat-form-field2 class="mat-block">
                        <input type="text" formControlName="business_tax_id" matInput placeholder="Business Tax Id" />
                        <mat-error
                          *ngIf="
                            item['controls'].business_tax_id.touched && item.hasError('required', ['business_tax_id'])
                          "
                        >
                          Value is <strong>required</strong>
                        </mat-error>
                      </mat-form-field2>
                    </div>
                    <div class="col-sm-4">
                      <mat-form-field2 class="mat-block">
                        <input
                          type="text"
                          formControlName="tot_registration_id"
                          matInput
                          placeholder="Accommodations tax registration number"
                        />
                        <mat-error
                          *ngIf="
                            item['controls'].tot_registration_id.touched &&
                            item.hasError('required', ['tot_registration_id'])
                          "
                        >
                          Value is <strong>required</strong>
                        </mat-error>
                      </mat-form-field2>
                    </div>
                    <div class="col-sm-3">
                      <mat-form-field2
                        matTooltip="Does your region provide exemptions for long-term stay? Tax may not apply to bookings longer than a certain number of days."
                        matTooltipPosition="above"
                        class="mat-block"
                      >
                        <input
                          type="number"
                          formControlName="long_term_stay_exemption"
                          matInput
                          step="1"
                          placeholder="Long term stay exemption"
                        />
                      </mat-form-field2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-sm-3">
              <div class="btn-group">
                <button (click)="onAddTax()" type="button" class="btn btn-secondary-info">
                  <span class="far fa-plus"></span>
                  Add Tax
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
