import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectAgodaWizardComponent } from './connect-agoda-wizard.component'
import { ConnectAgodaWizardCompleteComponent } from './complete/connect-agoda-wizard-complete.component'
import { ConnectAgodaWizardStep1Component } from './step1/connect-agoda-wizard-step1.component'
import { ConnectAgodaWizardStep2Component } from './step2/connect-agoda-wizard-step2.component'
import { ConnectAgodaWizardStep3Component } from './step3/connect-agoda-wizard-step3.component'

const components = [
  // Agoda
  ConnectAgodaWizardStep1Component,
  ConnectAgodaWizardStep2Component,
  ConnectAgodaWizardStep3Component,
  ConnectAgodaWizardCompleteComponent,
  ConnectAgodaWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectAgodaWizardModule {}
