import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { combineLatest, Observable, of } from 'rxjs'
import * as R from 'ramda'
import { concatMap, map, toArray } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import {
  InvoiceRule,
  CreateInvoiceRulePayload,
  InvoiceRuleLog,
  InvoiceRuleLogView,
  UpdateInvoiceRulePayload,
  RuleConditionModel,
  CreateInvoiceRuleConditionPayload,
} from './rule.model'

@Injectable()
export class InvoiceRuleService {
  constructor(private http: HttpClient, private store: Store<any>) {}

  logs() {
    const url = `@api/notification/invoicing/?limit=10000&skip=0`
    return this.http.get<InvoiceRuleLog[]>(url)
  }

  all() {
    const url = `@api/invoicerule/all/`

    return this.http
      .get<InvoiceRule[]>(url)
      .pipe(map((rules) => R.map((rule: InvoiceRule) => this.normalize(rule), rules)))
  }

  pauseRuleReminder(id: string) {
    const url = `@api/invoicerule/reminder/pause/${id}`

    return this.http.put<InvoiceRule>(url, {}).pipe(map((rule) => this.normalize(rule)))
  }

  pauseRuleReminderBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.pauseRuleReminder(id)),
      toArray()
    )
  }

  resumeRuleReminder(id: string, when: 'time.before.invoice.due' | 'time.after.invoice.due', days: number) {
    const url = `@api/invoicerule/reminder/resume/${id}`

    return this.http.put<InvoiceRule>(url, { when, days }).pipe(map((rule) => this.normalize(rule)))
  }

  resumeRuleReminderBatch(ids: string[], when: 'time.before.invoice.due' | 'time.after.invoice.due', days: number) {
    return of(...ids).pipe(
      concatMap((id) => this.resumeRuleReminder(id, when, days)),
      toArray()
    )
  }

  createMany(payload: CreateInvoiceRulePayload): Observable<InvoiceRule[]> {
    payload = this.normalizeCreatePayload(payload)

    return this.http
      .post<InvoiceRule[]>(`@api/invoicerules/`, payload)
      .pipe(map((rules) => R.map((rule: InvoiceRule) => this.normalize(rule), rules)))
  }

  update(id: string, payload: UpdateInvoiceRulePayload): Observable<InvoiceRule> {
    return this.http.put<InvoiceRule>(`@api/invoicerule/${id}`, payload).pipe(map((rule) => this.normalize(rule)))
  }

  get(id: string): Observable<InvoiceRule> {
    return this.http.get<InvoiceRule>(`@api/invoicerule/${id}`).pipe(map((rule) => this.normalize(rule)))
  }

  pause(id: string): Observable<InvoiceRule> {
    return this.http.put<InvoiceRule>(`@api/invoicerule/pause/${id}`, {}).pipe(map((rule) => this.normalize(rule)))
  }

  pauseBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.pause(id)),
      toArray()
    )
  }

  resume(id: string): Observable<InvoiceRule> {
    return this.http.put<InvoiceRule>(`@api/invoicerule/resume/${id}`, {}).pipe(map((rule) => this.normalize(rule)))
  }

  resumeBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.resume(id)),
      toArray()
    )
  }

  archive(id: string): Observable<InvoiceRule> {
    return this.http.put<InvoiceRule>(`@api/invoicerule/archive/${id}`, {}).pipe(map((rule) => this.normalize(rule)))
  }

  archiveBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.archive(id)),
      toArray()
    )
  }

  unarchive(id: string): Observable<InvoiceRule> {
    return this.http.put<InvoiceRule>(`@api/invoicerule/unarchive/${id}`, {}).pipe(map((rule) => this.normalize(rule)))
  }

  unarchiveBatch(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.unarchive(id)),
      toArray()
    )
  }

  delete(id: string) {
    return this.http.delete(`@api/invoicerule/${id}`)
  }

  deleteBatch(ids: string[]): Observable<string[]> {
    if (R.isEmpty(ids)) {
      return of([])
    }
    return this.http.put<string[]>(`@api/invoicerule/delete/batch`, { ids: ids })
  }

  setConditions(ruleId: string, items: CreateInvoiceRuleConditionPayload[]) {
    return this.http
      .put<InvoiceRule>(`@api/invoicerule/condition/set/${ruleId}`, { conditions: items })
      .pipe(map((rule) => this.normalize(rule)))
  }

  saveCondition(request) {
    return this.http.put<RuleConditionModel>(`@api/invoicerule/condition/${request.ruleId}`, {
      ...request.condition,
      key: request.conditionId,
    })
  }

  createCondition(request) {
    return this.http.post<RuleConditionModel>(`@api/invoicerule/condition/${request.ruleId}`, request.condition)
  }

  deleteCondition(request) {
    return this.http.put(`@api/invoicerule/condition/remove/${request.ruleId}`, { key: request.conditionId })
  }

  private normalizeCreatePayload(payload: CreateInvoiceRulePayload): CreateInvoiceRulePayload {
    return {
      ...payload,
      event: payload.event,
      status: 1,
      handler: 'Handlers::InvoiceRuleHandler',
      service: 'invoicing',
      conditions: payload.conditions,
      channels: R.isEmpty(payload.channels) ? [''] : payload.channels,
      rentals: R.isEmpty(payload.rentals) ? [''] : payload.rentals,
      users: R.append(111, payload.users),
      template: payload.template,
    }
  }

  private normalize(rule: InvoiceRule): InvoiceRule {
    const channel = R.path(['settings', 'channel'], rule)
    const group_id = rule.group_id || rule.pkey
    if (channel === 'airbnb') {
      return { ...rule, group_id, settings: { ...rule.settings, channel: 'airbnbapiv2' } }
    } else {
      return { ...rule, group_id }
    }
  }
}
