<div class="flex-fill w-100">
  <div class="d-flex mb-2 align-items-center" *ngIf="!isDraft">
    <div
      class="d-flex align-items-center gap-2"
      (click)="collapse.emit(!collapsed)"
      [matTooltip]="toggleCollapsingTooltip"
      matTooltipPosition="below"
    >
      <div
        *ngIf="message.type === MessageType.Inbound || message.type === MessageType.Guest"
        class="d-flex align-items-center gap-2 text-bold"
      >
        <app-avatar [text]="guestName" [size]="24"></app-avatar>
        <span>
          {{ guestName | truncate : 25 }}
        </span>
        <span>to</span>
        <span> AdvanceCM </span>
      </div>
      <div
        *ngIf="message.type === MessageType.Outbound || message.type === MessageType.Draft"
        class="d-flex align-items-center gap-2 text-bold"
      >
        <app-avatar [image]="isBot ? '/assets/images/icons/ai-avatar.svg' : 'assets/logo.svg'" [size]="24"></app-avatar>
        <span>
          {{ message.name || 'Unknown sender' | truncate : 25 }}
        </span>
        <span>to</span>
        <span>
          {{ guestName | truncate : 25 }}
        </span>
      </div>
      <div class="text-sm text-light" *ngIf="!isDraft">
        {{ message.receivedOn | epoch : 'D-MMM-YYYY / H:mm A' }}
      </div>
      <message-sent-with
        [sentWith]="message.sentWith"
        platform="tokeet"
        class="d-inline-block ml-2"
      ></message-sent-with>
    </div>
    <div class="flex-fill"></div>
    <ng-content select="[actions]"></ng-content>
  </div>

  <div *ngIf="collapsed" class="collapsed-content" (click)="collapse.emit(!collapsed)">
    <div [innerHtml]="message.messageTxt || message.messageHtml || 'Message collapsed.'"></div>
    <i class="fal fa-chevron-double-down"></i>
  </div>

  <ng-container *ngIf="!collapsed">
    <div
      *ngIf="(message.messageTxt && !message.messageHtml) || viewPlainText"
      class="bg-default2 p-3 rounded"
      [ngClass]="{ clickable: clickable }"
      (click)="bodyClick.emit()"
    >
      <div [innerHtml]="message.messageTxt"></div>
      <ng-container *ngTemplateOutlet="quote"></ng-container>
    </div>

    <div
      *ngIf="message.messageHtml && !viewPlainText"
      class="bg-default2 p-3 rounded"
      [ngClass]="{ clickable: clickable }"
      (click)="bodyClick.emit()"
    >
      <div class="message-content">
        <div [innerHtml]="message.sanitizedMsgHtml"></div>
        <ng-container *ngTemplateOutlet="quote"></ng-container>
        <ng-container *ngTemplateOutlet="attachments"></ng-container>
      </div>
      <div *ngIf="isDraft" class="draft-actions mt-2">
        <div>Not happy? <a href="javascript:;" (click)="onAIGenerate()">Generate another response</a></div>
        <a class="draft-action" matTooltip="Edit this message draft" (click)="onEditDraft(message)"
          ><i class="cs-icon cs-icon-pencil"></i
        ></a>
        <a class="draft-action" matTooltip="Send this message to guest" (click)="onSendDraft(message)"
          ><i class="fa fa-paper-plane"></i
        ></a>
        <a class="draft-action danger" matTooltip="Remove this message draft" (click)="onRemoveDraft(message)"
          ><i class="cs-icon cs-icon-trash"></i
        ></a>
      </div>
    </div>
  </ng-container>

  <ng-template #quote>
    <div *ngIf="message.template === tokeetTemplates.TokeetQuoteTemplate">
      <h4>Your Quote</h4>
      <div></div>
      <table *ngIf="!inquiry?.bookingEngine">
        <tr>
          <th>Base Rate:</th>
          <td><span [innerHtml]="message.charge | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr>
          <th>Discounts & Fees:&nbsp;&nbsp;</th>
          <td><span [innerHtml]="message.discounts | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr>
          <th>Tax:</th>
          <td><span [innerHtml]="message.taxFee | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr class="quote-total">
          <th>Total Amount:</th>
          <td><span [innerHtml]="message.sum | tokeetCurrency : currencySymbol"></span></td>
        </tr>
      </table>
      <table *ngIf="inquiry?.bookingEngine">
        <tr>
          <th>Base Charge:&nbsp;&nbsp;</th>
          <td>
            <span [innerHtml]="inquiry?.bookingEngine?.extraBedroomsBase | tokeetCurrency : currencySymbol"></span>
          </td>
        </tr>
        <tr>
          <th>Additional Guests Fee:&nbsp;&nbsp;</th>
          <td><span [innerHtml]="inquiry?.bookingEngine?.extraGuests | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr *ngIf="!!inquiry?.bookingEngine?.extraBedrooms">
          <th>Additional Bedrooms Fee:&nbsp;&nbsp;</th>
          <td><span [innerHtml]="inquiry?.bookingEngine?.extraBedrooms | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr>
          <th>Discounts & Fees:&nbsp;&nbsp;</th>
          <td><span [innerHtml]="inquiry?.bookingEngine?.discountTotal | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr *ngFor="let fee of inquiry?.bookingEngine?.bookingFees">
          <th>{{ fee.name }}</th>
          <td><span [innerHtml]="fee.amount | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr>
          <th>Taxes:</th>
          <td><span [innerHtml]="inquiry?.bookingEngine?.taxTotal | tokeetCurrency : currencySymbol"></span></td>
        </tr>
        <tr class="quote-total">
          <th>Total:</th>
          <td><span [innerHtml]="inquiry?.bookingEngine?.total | tokeetCurrency : currencySymbol"></span></td>
        </tr>
      </table>
    </div>
  </ng-template>

  <ng-template #attachments>
    <ng-container *ngIf="!message.innerAttachments">
      <div *ngFor="let attachment of message.attachments">
        <a *ngIf="!attachment.path && !!attachment.url" [href]="attachment.url" target="_blank"
          >{{ attachment.name }}&nbsp;&nbsp;<i class="fal fa-paperclip"></i
        ></a>
        <a *ngIf="!!attachment.path && !attachment.url" (click)="onDownloadAttachment(attachment)"
          >{{ attachment.name }}&nbsp;&nbsp;<i class="fal fa-paperclip"></i
        ></a>
      </div>
    </ng-container>
  </ng-template>
</div>
