import { Component, Inject, OnInit } from '@angular/core'
import { CustomBrandingType } from '@tv3/types/custom-branding-type'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-template-dictionary-dialog',
  host: { class: 'modal-content' },
  templateUrl: './template-dictionary-dialog.component.html',
  styleUrls: ['./template-dictionary-dialog.component.scss'],
})
export class TemplateDictionaryDialogComponent implements OnInit {
  tags = {
    [CustomBrandingType.StandardMessage]: [
      { key: `*|BRANDING:name|*`, tooltip: 'The name of the user who is sending the message.' },
      { key: `*|BRANDING:message_html|*`, tooltip: 'The html version of the message sent.' },
      { key: `*|BRANDING:reference_id|*`, tooltip: 'A unique AdvanceCM id for this specific message.' },
      { key: `*|BRANDING:rental_name|*`, tooltip: 'The name of the rental associated with this message.' },
      { key: `*|BRANDING:guest_name|*`, tooltip: 'The full name of the guest the message is sent to.' },
    ],
    [CustomBrandingType.InvoiceMessage]: [
      { key: `*|BRANDING:name|*`, tooltip: 'The name of the user who is sending the message.' },
      { key: `*|BRANDING:reference_id|*`, tooltip: 'A unique AdvanceCM id for this specific message.' },
      { key: `*|BRANDING:rental_name|*`, tooltip: 'The name of the rental associated with this message.' },
      { key: `*|BRANDING:guest_name|*`, tooltip: 'The full name of the guest the message is sent to.' },
      { key: `*|BRANDING:invoice_link|*`, tooltip: 'The URL to your invoice.' },
    ],
    [CustomBrandingType.QuoteMessage]: [
      { key: `*|BRANDING:name|*`, tooltip: 'The name of the user who is sending the message.' },
      { key: `*|BRANDING:message_html|*`, tooltip: 'The html version of the message sent.' },
      { key: `*|BRANDING:reference_id|*`, tooltip: 'A unique AdvanceCM id for this specific message.' },
      { key: `*|BRANDING:rental_name|*`, tooltip: 'The name of the rental associated with this message.' },
      { key: `*|BRANDING:guest_name|*`, tooltip: 'The full name of the guest the message is sent to.' },
      { key: `*|BRANDING:sum|*`, tooltip: 'The numeric sum of the quote.' },
      { key: `*|BRANDING:tax_fee|*`, tooltip: 'The total tax of the quote.' },
      { key: `*|BRANDING:discounts|*`, tooltip: 'The total discounts of the quote.' },
      { key: `*|BRANDING:charge|*`, tooltip: 'The base charge of the quote.' },
      { key: `*|BRANDING:guest_arrive|*`, tooltip: 'The guest arrival date.' },
      { key: `*|BRANDING:guest_depart|*`, tooltip: 'The guest departure date.' },
    ],
    [CustomBrandingType.GuestPortalMessage]: [
      { key: `*|BRANDING:name|*`, tooltip: 'The name of the user who is sending the message.' },
      { key: `*|BRANDING:rental_name|*`, tooltip: 'The name of the rental associated with this message.' },
      { key: `*|BRANDING:guest_name|*`, tooltip: 'The full name of the guest the message is sent to.' },
      { key: `*|BRANDING:gp_link|*`, tooltip: 'A link to the guest portal with credentials.' },
    ],
  }

  activeTags

  constructor(
    public dialogRef: MatDialogRef<TemplateDictionaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: CustomBrandingType }
  ) {}

  ngOnInit() {
    this.activeTags = this.tags[this.data.type]
  }

  addTag(tag: string) {
    this.dialogRef.close(tag)
  }

  close() {
    this.dialogRef.close()
  }
}
