import { createAction, props } from '@ngrx/store'

export const BDCPropertyCreateComplete = createAction(
  '[channel content] BDC Property Create Complete',
  props<{ propertyId: number }>()
)
export const BDCPropertyUpdateComplete = createAction(
  '[channel content] BDC Property Update Complete',
  props<{ propertyId: number }>()
)

export const BDCRoomCreateComplete = createAction(
  '[channel content] BDC Room Create Complete',
  props<{ propertyId: number; roomId: number }>()
)
export const BDCRoomUpdateComplete = createAction(
  '[channel content] BDC Room Update Complete',
  props<{ propertyId: number; roomId: number }>()
)

export const AgodaPropertyCreateComplete = createAction(
  '[channel content] Agoda Property Create Complete',
  props<{ propertyId: number }>()
)
export const AgodaPropertyUpdateComplete = createAction(
  '[channel content] Agoda Property Update Complete',
  props<{ propertyId: number }>()
)
export const AgodaPropertyClosedComplete = createAction(
  '[channel content] Agoda Property Closed Complete',
  props<{ propertyId: number }>()
)

export const AgodaRoomCreateComplete = createAction(
  '[channel content] Agoda Room Create Complete',
  props<{ propertyId: number; roomId: number }>()
)
export const AgodaRoomUpdateComplete = createAction(
  '[channel content] Agoda Room Update Complete',
  props<{ propertyId: number; roomId: number }>()
)

export const AgodaRateCreateComplete = createAction(
  '[channel content] Agoda Rate Create Complete',
  props<{ propertyId: number; rateId: number }>()
)
export const AgodaRateUpdateComplete = createAction(
  '[channel content] Agoda Rate Update Complete',
  props<{ propertyId: number; rateId: number }>()
)
