export const categories = [
  'Maintenance',
  'Supplies',
  'Safety',
  'Housekeeping',
  'Facilities',
  'Guest',
  'Utilities',
  'Equipment',
]
