import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import {
  CustomChannelResponse,
  CreateCustomChannel,
  UpdateCustomChannel,
  selectAllCustomChannels,
  CreateCustomChannelComplete,
  UpdateCustomChannelComplete,
} from '@tokeet-frontend/channels'
import {
  CloudinaryUploadDialogService,
  Destroyable,
  SaveForm,
  Toaster,
  TokeetImagePayload,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-custom-channel-form',
  templateUrl: './custom-channel-form.component.html',
  styleUrls: ['./custom-channel-form.component.scss'],
})
export class CustomChannelFormComponent extends Destroyable implements OnInit {
  @Input() channel: CustomChannelResponse

  form = this.fb.group({
    friendly_name: ['', [Validators.required]],
    description: [],
    channel_name: [],
    logo: [],
  })
  isLoading = false

  customChannels: CustomChannelResponse[] = []
  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private store: Store<any>,
    private toaster: Toaster,
    private cloudinaryUploadDialog: CloudinaryUploadDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.channel) {
      this.form.patchValue(this.channel)
    }

    this.store.pipe(select(selectAllCustomChannels), untilDestroy(this)).subscribe((items) => {
      this.customChannels = items
    })

    this.actions
      .pipe(ofType(CreateCustomChannelComplete, UpdateCustomChannelComplete), untilDestroy(this))
      .subscribe(() => {
        this.isLoading = false
      })
  }

  onUpload() {
    this.cloudinaryUploadDialog
      .open({
        title: 'Upload Channel Logo',
        maxUploads: 1,
      })
      .subscribe(([logo]: TokeetImagePayload[]) => {
        this.form.get('logo').setValue(logo.secure_url || logo.url)
      })
  }

  save() {
    this.onSave(this.form)
  }

  isNameConflict(name: string) {
    name = lodash.kebabCase(name)
    return !!lodash.find(this.customChannels, (c) => c.pkey !== this.channel?.pkey && c.channel_name === name)
  }

  @SaveForm()
  private onSave(form: FormGroup) {
    const { friendly_name, ...data } = this.form.getRawValue()
    if (this.isNameConflict(friendly_name)) {
      this.toaster.warning('The channel is existing, please create a channel with different name.')
      return
    }
    this.isLoading = true

    this.store.dispatch(
      UpdateCustomChannel({
        id: this.channel.pkey,
        payload: {
          ...data,
          channel_name: lodash.kebabCase(friendly_name),
          friendly_name,
        },
      })
    )
  }

  create() {
    this.onCreate(this.form)
  }

  @SaveForm()
  private onCreate(form: FormGroup) {
    const { friendly_name, ...data } = this.form.getRawValue()
    if (this.isNameConflict(friendly_name)) {
      this.toaster.warning('The channel is existing, please create a channel with different name.')
      return
    }
    this.isLoading = true

    this.store.dispatch(
      CreateCustomChannel({
        payload: {
          ...data,
          channel_name: lodash.kebabCase(friendly_name),
          friendly_name,
        },
      })
    )
  }
}
