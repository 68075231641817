import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { ManageEntityTagsDialogParams, ManageEntityTagsDialogComponent } from './manage-entity-tags-dialog.component'

@Injectable()
export class ManageEntityTagsDialogService {
  constructor(private dialog: DialogService) {}

  open(data: ManageEntityTagsDialogParams) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      data,
    }

    return this.dialog.openVariableDialog(ManageEntityTagsDialogComponent, defaultConfig)
  }
}
