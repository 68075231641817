import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Rental } from '@tokeet-frontend/tv3-platform'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import * as moment from 'moment'
import * as R from 'ramda'
import { Website } from '@tv3/store/website/website.model'

@Injectable({
  providedIn: SharedModule,
})
export class DownloadEngineLinksDialogService {
  constructor(private dialog: MatDialog) {}

  private getConfig(website: Website, rentals: Rental[], filename: string): GenericExportDialogConfig {
    return {
      title: 'Download Engine Links to CSV',
      description: 'You are about to download all engine links for this website',
      columns: [
        { name: 'Rental', field: 'name' },
        { name: 'Rental ID', field: 'id' },
        {
          name: 'Engine Link',
          field: (r) =>
            `https://${website.domain}/checkout?rental_id=${r.id}&start=[booking_start_date]&end=[booking_end_date]`,
        },
      ],
      exportData: rentals,
      filename: filename,
      columnCustomizable: false,
    }
  }

  open(website: Website, rentals: Rental[]) {
    const filename = `Engine_Links(${moment().format('MM_DD_YYYY')})`
    const rentalIds = website.rentals || []
    const selectRentals = R.filter((r) => rentalIds.indexOf(r.id) > -1, rentals)
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        config: this.getConfig(website, selectRentals, filename),
      },
    }

    return this.dialog.open(GenericExportDialogComponent, defaultConfig)
  }
}
