import { Component, Input, OnChanges, OnInit } from '@angular/core'
import * as lodash from 'lodash'

const MAX_LENGTH = 160

@Component({
  selector: 'app-show-more',
  template: `
    <ng-container *ngIf="!isCollapsed">
      <span [innerHtml]="content"></span>
      <ul *ngIf="!!notes?.length">
        <li *ngFor="let note of notes">
          <span><i class="far fa-check"></i> {{ note }}</span>
        </li>
      </ul>
      <a (click)="isCollapsed = true" *ngIf="isTrimmed" class="btn-more ml-1" href="javascript:;">Show less </a>
    </ng-container>
    <ng-container *ngIf="isCollapsed">
      <span [innerHtml]="trimmedContent"></span> ...
      <a (click)="isCollapsed = false" class="btn-more" href="javascript:;">Show more </a>
    </ng-container>
  `,
  styles: [
    `
      .fa-check {
        color: var(--info);
        position: absolute;
        left: -20px;
        top: 4px;
      }
      ul {
        list-style: none;
        margin-top: 10px;
      }
      ul li {
        position: relative;
      }
    `,
  ],
})
export class ShowMoreComponent implements OnInit, OnChanges {
  @Input() content: string
  @Input() notes: string[]
  @Input() maxLength = MAX_LENGTH

  trimmedContent: string
  isTrimmed: boolean
  isCollapsed: boolean = false

  ngOnInit() {}

  ngOnChanges() {
    this.refresh()
  }

  trim(str, num) {
    str = lodash.toString(str).trim()
    const trimmedString = str.substr(0, num)
    if (trimmedString.length < num) return trimmedString
    this.isTrimmed = true
    const firstSpaceIndex = trimmedString.lastIndexOf(' ')
    return trimmedString.substr(
      0,
      Math.min(trimmedString.length, firstSpaceIndex < 0 ? trimmedString.length : firstSpaceIndex)
    )
  }

  refresh() {
    this.trimmedContent = this.trim(this.content, this.maxLength)
    this.isCollapsed = this.trimmedContent?.length < this.content?.length
  }
}
