import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { selectUserPreferences } from '@tv3/store/user-preferences/user-preferences.selectors'
import { filter, map, take } from 'rxjs/operators'
import { UserPreferences } from '@tv3/store/user-preferences/user-preferences.model'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import { Observable } from 'rxjs'
import { defaultLocalCalendarMapping } from '@tv3/constants/calendar-route-mapping'

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(selectUserPreferences),
      map((pref: UserPreferences) => {
        if (pref?.defaultRoute) {
          this.router.navigate([pref.defaultRoute])
        } else {
          this.router.navigate([`/calendar/${defaultLocalCalendarMapping.url}`])
        }
        return true
      }),
      take(1)
    )
  }
}
