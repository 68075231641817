import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { RateService } from './rate.service'
import { Rate } from './rate.model'
import { RentalService } from '../rental/rental.service'
import { Toaster } from '../../services/toaster.service'
import { toMoment } from '../../functions'

@Injectable()
export class PromotionalRateService {
  constructor(private toaster: Toaster, private rentalService: RentalService, private rateService: RateService) {}

  saveRate(rate: Rate) {
    return this.rentalService.get(rate.rentalId).pipe(
      map((rental) => {
        const promotions = lodash.map(rental.promotions, (r) => {
          r.start = toMoment(r.start).startOf('date').unix()
          r.end = toMoment(r.end).startOf('date').unix()

          return r
        })

        return promotions
      }),
      switchMap((promotions) => {
        const overlapRate = this.checkOverlap(rate, promotions)

        if (overlapRate) {
          return of({ overlap: overlapRate, rate, promotions })
        }

        const task = rate.rateId
          ? this.rateService.updateRate(rate.rentalId, rate.rateId, rate)
          : this.rateService.addRate(rate.rentalId, rate)

        return task.pipe(
          map((updatedRate) => {
            if (rate.rateId) {
              promotions = promotions.map((r) => (r.rateId === updatedRate.rateId ? updatedRate : r))
            } else {
              promotions = promotions.concat(updatedRate)
            }

            return { overlap: null, rate: updatedRate, promotions }
          })
        )
      })
    )
  }

  checkOverlap(rate: Rate, promotions: Rate[]) {
    let found: Rate = null

    const start = toMoment(rate.start).startOf('date').unix()
    const end = toMoment(rate.end).startOf('date').unix()

    lodash.forEach(promotions, (r) => {
      if (rate.rateId && rate.rateId === r.rateId) return
      if (!this.checkCategoryOverlap(rate, r)) return

      if (
        (start >= r.start && start <= r.end) ||
        (end >= r.start && end <= r.end) ||
        (start < r.start && end > r.end)
      ) {
        found = r
        return false
      }
    })

    return found
  }

  checkCategoryOverlap(r1: Rate, r2: Rate) {
    let found = false

    const categories = r2.categories || []

    lodash.forEach(r1.categories, (category) => {
      if (categories.includes(category)) {
        found = true
        return false
      }
    })

    return found
  }
}
