import { Directive, forwardRef, Input } from '@angular/core'
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { NativeDateAdapter } from '@angular/material/core'

@Directive({
  selector: 'app-mat-date-input[min],app-mat-date-input[max]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => DateInputValidatorDirective), multi: true }],
})
export class DateInputValidatorDirective implements Validator {
  @Input() showTime = false
  maxDate: Date
  @Input() set max(value: Date | null) {
    const validValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(value))

    if (
      !this.isSameDatetime(validValue, this.maxDate) &&
      (!this.minDate || this.compareDatetime(this.minDate, validValue) <= 0)
    ) {
      this.maxDate = validValue
      this._validatorOnChange()
    }
  }

  minDate: Date
  @Input() set min(value: Date | null) {
    const validValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(value))

    if (
      !this.isSameDatetime(validValue, this.minDate) &&
      (!this.maxDate || this.compareDatetime(validValue, this.maxDate) <= 0)
    ) {
      this.minDate = validValue
      this._validatorOnChange()
    }
  }

  private isSameDatetime(d1: Date, d2: Date) {
    if (!this.dateAdapter.sameDate(d1, d2)) return false
    if (!d1 && !d2) return true
    if (!this.showTime) return true
    return d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes()
  }

  private compareDatetime(d1: Date, d2: Date) {
    if (!this.showTime) return this.dateAdapter.compareDate(d1, d2)
    const res = this.dateAdapter.compareDate(d1, d2)
    if (res !== 0) return res
    return d1.getHours() * 60 + d1.getMinutes() - (d2.getHours() * 60 + d2.getMinutes())
  }

  _validatorOnChange = () => {}

  private _minValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const controlValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(control.value))
    const min = this.minDate
    return !min || !controlValue || this.compareDatetime(min, controlValue) <= 0
      ? null
      : { min: { min: min, actual: controlValue } }
  }

  private _maxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const controlValue = this.dateAdapter.getValidDateOrNull(this.dateAdapter.deserialize(control.value))
    const max = this.maxDate
    return !max || !controlValue || this.compareDatetime(max, controlValue) >= 0
      ? null
      : { max: { max: max, actual: controlValue } }
  }

  protected _getValidators(): ValidatorFn[] {
    return [this._minValidator, this._maxValidator]
  }

  /** The combined form control validator for this input. */
  protected _validator: ValidatorFn | null
  private dateAdapter = new NativeDateAdapter('en-GB')

  constructor() {
    this._validator = Validators.compose(this._getValidators())
  }

  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this._validator ? this._validator(c) : null
  }
}
