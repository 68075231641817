import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CommentsLibraryModule } from '@tokeet-frontend/comments'
import { ActivityLibraryModule } from '@tokeet-frontend/activity'
import { SharedModule } from '@tv3/shared/shared.module'
import { AddExpenseDialogComponent } from '../dialogs/add/add-expense-dialog.component'
import { DuplicateExpenseDialogComponent } from '../dialogs/duplicate/duplicate-expense-dialog.component'
import { EditExpenseDialogComponent } from '../dialogs/edit/edit-expense-dialog.component'
import { ImportExpenseDialogComponent } from '../dialogs/import/import-expense-dialog.component'
import { ExpenseCategoryChartComponent } from './category-chart/expense-category-chart.component'
import { ExpenseMonthlyChartComponent } from './monthly-chart/expense-monthly-chart.component'
import { ExpenseTopStatsComponent } from './top-stats/expense-top-stats.component'
import { ExpenseReportFiltersComponent } from './report-filters/expense-report-filters.component'

const components = [
  //
  ExpenseCategoryChartComponent,
  ExpenseMonthlyChartComponent,
  ExpenseTopStatsComponent,
  ExpenseReportFiltersComponent,
]

const dialogs = [
  AddExpenseDialogComponent,
  EditExpenseDialogComponent,
  ImportExpenseDialogComponent,
  DuplicateExpenseDialogComponent,
]

@NgModule({
  imports: [SharedModule, CommonModule, CommentsLibraryModule, ActivityLibraryModule],
  declarations: [...components, ...dialogs],
  exports: [...components, ...dialogs],
})
export class ExpenseSharedModule {}
