import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { environment } from '../../../environments/environment'
import { AuthService } from '@tv3/services/auth.service'
import * as R from 'ramda'
import { currencies, deserializeArray, Toaster } from '@tokeet-frontend/tv3-platform'
import { Preference } from '@tv3/store/preferences/preferences.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { CheckoutSession, Invoice, LoadInvoicesFilters } from '@tokeet-frontend/invoices'
import * as moment from 'moment'
import { PaymentGateway } from '@tokeet-frontend/gateways'
import { CookieService } from 'ngx-cookie-service'

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private http: HttpClient,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private auth: AuthService,
    private cookie: CookieService
  ) {}

  all(
    filters: LoadInvoicesFilters
  ): Observable<{ invoices: Invoice[]; filters: LoadInvoicesFilters; hasMore: boolean }> {
    const url = `@api/invoice/all/`
    filters = { ...filters, limit: filters.limit || 1000, skip: filters.skip || 0 }
    // @ts-ignore
    const params = new HttpParams({ fromObject: { ...filters, limit: `${filters.limit + 1}` } })

    return this.http.get<object[]>(url, { params }).pipe(
      map((invoices: Invoice[]) =>
        R.map(
          (i) => ({ ...i, currency: R.find((c) => c.code === i.currency?.code, currencies) || i.currency }),
          invoices
        )
      ),
      deserializeArray<Invoice>(Invoice),
      map((invoices) => ({
        invoices: R.take(filters.limit, invoices),
        filters,
        hasMore: invoices.length > filters.limit,
      }))
    )
  }

  getPastDueInvoices() {
    const now = moment().unix()
    const url = `@api/invoice/all/?due_to=${now}&sort=due_date&status=1`

    return this.http.get(url).pipe(deserializeArray<Invoice>(Invoice))
  }

  send(invoice: Invoice, inquiry: Inquiry, preferences: Preference) {
    const url = `@api/invoice/send/${invoice.id}`

    let branding = preferences.branding || <any>{}

    const request = {
      inquiry_id: inquiry.id,
      convo_id: inquiry.convoId,
      guest_id: inquiry.guestId,
      rental_id: inquiry.rentalId,
      message_txt: 'Here is your invoice.', // This isn't used, but shouldn't be blank.
      message_html: 'Here is your invoice.', // This isn't used, but shouldn't be blank.
      rental_name: inquiry.rental.name,
      guest_name: inquiry.guest.name,
      guest_arrive: inquiry.guestArrive,
      guest_depart: inquiry.guestDepart,
      subject: `Your invoice from ${R.pathOr('', ['rental', 'name'], inquiry)}`,
      from: inquiry.rental.email,
      template: branding.invoiceTemplate || '1112223338ST-2',
      invoice_num: invoice.invoiceNum,
      invoice_link: this.getPrintUrl(invoice),
    }

    return this.http.put(url, request)
  }

  getPrintUrl(invoice: Invoice, print: 0 | 1 = 0) {
    return `${environment.config.invoiceBaseUrl}invoice/guest/${invoice.created}/${invoice.invoiceNum}/${invoice.id}/${invoice.publicKey}/${this.auth.user.account}?print=${print}`
  }

  private openInvoiceWindow(url) {
    this.cookie.set('token', this.auth.token, moment().add(5, 'minutes').toDate(), '/invoice/guest', '.tokeet.com')
    window.open(url, '_blank', 'width=1100, height=900')
  }

  previewInvoice(invoice: Invoice) {
    const url = this.getPrintUrl(invoice, 0)
    console.log(url)
    this.openInvoiceWindow(url)
  }

  printInvoice(invoice: Invoice) {
    const url = this.getPrintUrl(invoice, 1)
    this.openInvoiceWindow(url)
  }

  getCheckoutSession(invoice: Invoice, gateway: PaymentGateway) {
    const url = `@api/invoice/checkout/session`

    return this.getGuestInvoice(invoice).pipe(
      switchMap((guestInvoice) => {
        return this.http
          .post<CheckoutSession>(url, {
            invoice: {
              ...guestInvoice,
              pkey: invoice.id,
              account: invoice.account,
            },
            gateway: {
              stripe_user_id: gateway.credentials.stripe_user_id,
            },
          })
          .pipe(
            catchError((error) => {
              this.store.dispatch(ActionFailed({ error }))
              return throwError(error)
            })
          )
      })
    )
  }

  getGuestInvoice(invoice: Invoice) {
    const url = `@api/invoice/preview/guest/${invoice.created}/${invoice.invoiceNum}/${invoice.id}/${invoice.publicKey}`

    return this.http.get(url)
  }
}
