<div class="modal-header">
  <h5 class="modal-title">
    <i class="fab fa-airbnb"></i>
    Booking Alteration
    <app-page-help articleId="juz2cws6sb"></app-page-help>
  </h5>
  <button (click)="dialogRef.close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <table class="table table-striped">
    <tr>
      <th></th>
      <th>Before</th>
      <th>After</th>
    </tr>
    <tr>
      <td>Arrival Date</td>
      <td>{{ inquiry.guestArrive | epochUTC : 'YYYY-MM-DD' }}</td>
      <td>{{ alteration.start_date }}</td>
    </tr>
    <tr>
      <td>Departure Date</td>
      <td>{{ inquiry.guestDepart | epochUTC : 'YYYY-MM-DD' }}</td>
      <td>{{ alteration.end_date }}</td>
    </tr>
    <tr>
      <td>Adult</td>
      <td>{{ inquiry.numAdults }}</td>
      <td>{{ alteration.guest_details.number_of_adults }}</td>
    </tr>
    <tr>
      <td>Children</td>
      <td>{{ inquiry.numChild }}</td>
      <td>{{ alteration.guest_details.number_of_children }}</td>
    </tr>
    <tr>
      <td>Infants</td>
      <td>{{ 0 }}</td>
      <td>{{ alteration.guest_details.number_of_infants }}</td>
    </tr>
    <tr>
      <td>Pets</td>
      <td>{{ 0 }}</td>
      <td>{{ alteration.guest_details.number_of_pets }}</td>
    </tr>
    <tr class="font-weight-bold">
      <td>Total Charge</td>
      <td>{{ inquiry.abbPrice?.payout || 0 }}</td>
      <td>{{ total | currency : '' }}</td>
    </tr>
  </table>
</div>
<div class="modal-footer">
  <ng-container *ngIf="isQuote">
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Cancel
    </button>
    <button
      (click)="onInitialAlteration()"
      type="button"
      [disabled]="isLoading"
      class="btn btn-secondary-info create-btn"
    >
      <i class="fal fa-check"></i> Initiate
    </button>
  </ng-container>
  <ng-container *ngIf="isHostAlteration">
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Close
    </button>
    <button (click)="onCancel()" type="button" [disabled]="isLoading" class="btn btn-outline-danger create-btn">
      <i class="fal fa-ban"></i> Cancel
    </button>
  </ng-container>
  <ng-container *ngIf="isGuestAlteration">
    <button (click)="onIgnore()" type="button" [disabled]="isLoading" class="btn btn-secondary-info create-btn">
      <i class="far fa-times"></i> Ignore
    </button>
    <button (click)="onDecline()" type="button" [disabled]="isLoading" class="btn btn-secondary-danger create-btn">
      <i class="fal fa-ban"></i> Decline
    </button>
    <button (click)="onAccept()" type="button" [disabled]="isLoading" class="btn btn-secondary-success create-btn">
      <i class="fal fa-check"></i> Accept
    </button>
  </ng-container>
</div>
