import * as lodash from 'lodash'

export enum BookingFormulaBaseType {
  BookingTotal = 'total',
  GuestPayout = 'guest_payout',
}

export interface BookingFormulaResponse {
  base: BookingFormulaBaseType
  name: string
  formula: BookingFormulaItem[]
  cumulative: number
  rental_id?: string
  status: 0 | 1
  rentals?: string[]
  scheduling?: {
    start_month?: number
    end_month?: number
  }

  lastupdated: number
  creator: string
  account: number
  channel_id: string
  archived: 0 | 1
  deleted: 0 | 1
  pkey: string
  created: number
}

export interface BookingFormulaResponseView extends BookingFormulaResponse {
  channelView: string
  rentalsView: string
  months: string
}

export type CreateBookingFormulaPayload = Omit<
  BookingFormulaResponse,
  'pkey' | 'created' | 'lastupdated' | 'deleted' | 'archived' | 'created' | 'account'
>
export type UpdateBookingFormulaPayload = Partial<CreateBookingFormulaPayload>

export interface BookingFormulaItem {
  amount: number
  type: 'flat' | 'percent' // | 'variable'
  operation: 'add' | 'subtract'
  name: string
}

export function getFormulaScheduling(formula: BookingFormulaResponse) {
  return {
    start_month: +formula.scheduling?.start_month || 0,
    end_month: lodash.isNil(formula.scheduling?.end_month) ? 11 : +formula.scheduling?.end_month,
  }
}

export const formulaAppliedMonths = (start: number, end: number, includeDefault = false) => {
  if (!lodash.isNumber(start) || !lodash.isNumber(end)) {
    return includeDefault ? lodash.range(12) : []
  }

  if (start <= end) {
    return lodash.range(start, end + 1)
  }

  return lodash.range(start, end + 12 + 1).map((t) => t % 12)
}

export function isFormulasMonthOverlap(
  appliedItems: { rentals: string[]; months: number[] }[],
  rentals: string[],
  months: number[]
): boolean {
  const appliedMonths = lodash.uniq(
    lodash.flatten(
      appliedItems
        .filter((t) => {
          if (!rentals.length) {
            return !t.rentals?.length
          }
          return lodash.intersection(t.rentals, rentals).length > 0
        })
        .map((t) => t.months)
    )
  )

  return lodash.intersection(months, appliedMonths).length > 0
}
