import { Pipe, PipeTransform } from '@angular/core'
import * as lodash from 'lodash'

@Pipe({ name: 'fullname' })
export class FullnamePipe implements PipeTransform {
  transform(user: { firstName: string; lastName: string }): string {
    return userFullname(user)
  }
}

export function userFullname(user?: { firstName: string; lastName: string }): string {
  if (!user) {
    return ''
  }
  return lodash.trim(`${user.firstName || ''} ${user.lastName || ''}`)
}
