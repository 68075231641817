import { Component, OnInit, Input } from '@angular/core'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'

export abstract class AutomationsTable<T> extends SelectableRow<T> {
  abstract onPauseSelected(): void
  abstract onResumeSelected(): void
  abstract onArchiveSelected(): void
  abstract onUnarchiveSelected(): void
  abstract onDeleteSelected(): void
}

@Component({
  selector: 'app-automations-table-actions',
  templateUrl: './automations-table-actions.component.html',
  styleUrls: ['./automations-table-actions.component.scss'],
})
export class AutomationsTableActionsComponent implements OnInit {
  @Input() table: AutomationsTable<any>

  get isAnySelected() {
    return this.table.isAnySelected()
  }

  constructor() {}

  ngOnInit(): void {}
}
