import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import * as R from 'ramda'
import { monthsList } from '@tv3/constants/months'
import * as moment from 'moment'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class DownloadInquiriesDialogService {
  constructor(private dialog: MatDialog) {}

  private getConfig(data: Inquiry[], filename: string): GenericExportDialogConfig {
    return {
      title: 'Download Inquiries to CSV',
      description: 'You are about to download all inquiries within your current filter.',
      columns: [
        { name: 'Source', field: 'inquirySource' },
        { name: 'Rental', field: 'rentalView' },
        { name: 'Updated', field: 'touchView' },
        { name: 'Guest Name', field: 'guestNameView' },
        { name: 'Arrive', field: 'arriveView' },
        { name: 'Depart', field: 'departView' },
        { name: 'Nights', field: 'nightsView' },
        { name: 'Guests', field: 'guestsView' },
      ],
      exportData: data,
      filename: filename,
      columnCustomizable: true,
    }
  }

  open(inquiries: Inquiry[], filters: any, rentals: Rental[]) {
    const month = R.find((m) => m.id === <number>filters.month + 1, monthsList) || { name: 'All' }
    const source = filters.source || 'All'
    const status = filters.status || 'All'
    const rentalNames =
      R.pipe(
        R.filter((r: Rental) => R.contains(r.id, filters.rentals || [])),
        <any>R.map((r: Rental) => r.name),
        R.join(',')
      )(<any>rentals) || 'All'

    const filename = `Inquiries_Month(${
      month.name
    })_Source(${source})_Status(${status})_Rentals(${rentalNames})_Date(${moment().format('MM_DD_YYYY')})`

    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        config: this.getConfig(inquiries, filename),
      },
    }

    return this.dialog.open(GenericExportDialogComponent, defaultConfig)
  }
}
