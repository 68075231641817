import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import {
  AddWebsite,
  AddWebsiteComplete,
  DeleteWebsite,
  DeleteWebsiteComplete,
  LoadWebsite,
  LoadWebsiteComplete,
  LoadWebsites,
  LoadWebsitesComplete,
  LoadWebsiteTemplates,
  LoadWebsiteTemplatesComplete,
  PublishWebsite,
  PublishWebsiteComplete,
  UpdateWebsite,
  UpdateWebsiteComplete,
} from './website.actions'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { WebsiteService } from './website.service'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Router } from '@angular/router'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class WebsiteEffects {
  @Effect()
  loadWebsiteTemplates$ = this.actions$.pipe(
    ofType(LoadWebsiteTemplates),
    switchMap(() =>
      this.websiteService.templates().pipe(
        map((templates) => LoadWebsiteTemplatesComplete({ templates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadWebsites$ = this.actions$.pipe(
    ofType(LoadWebsites),
    switchMap(() =>
      this.websiteService.all().pipe(
        map((websites) => LoadWebsitesComplete({ websites })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadWebsite$ = this.actions$.pipe(
    ofType(LoadWebsite),
    switchMap(({ id }) =>
      this.websiteService.get(id).pipe(
        map((website) => LoadWebsiteComplete({ website })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addWebsite$ = this.actions$.pipe(
    ofType(AddWebsite),
    switchMap(({ data }) =>
      this.websiteService.add(data).pipe(
        map((website) => AddWebsiteComplete({ website })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateWebsite$ = this.actions$.pipe(
    ofType(UpdateWebsite),
    switchMap(({ websiteId, data }) =>
      this.websiteService.update(websiteId, data).pipe(
        tap(() => this.toaster.success('Website updated successfully.')),
        map((website) => UpdateWebsiteComplete({ update: { id: websiteId, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  publishWebsite$ = this.actions$.pipe(
    ofType(PublishWebsite),
    switchMap(({ id, domain }) =>
      this.websiteService.publish(domain).pipe(
        tap(() => this.toaster.success('Website published successfully.')),
        map(({ lastpublished }) =>
          PublishWebsiteComplete({ update: { id, changes: { lastPublished: lastpublished } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteWebsite$ = this.actions$.pipe(
    ofType(DeleteWebsite),
    switchMap(({ id }) =>
      this.websiteService.delete(id).pipe(
        tap(() => this.toaster.success('Website deleted successfully.')),
        map((res) => DeleteWebsiteComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private websiteService: WebsiteService
  ) {}
}
