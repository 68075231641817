import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { UpdateCustomBrandingTemplateComplete } from '@tokeet-frontend/templates'
import {
  Account,
  Address,
  BillingAddress,
  Destroyable,
  isSomething,
  selectCurrentAccount,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { Subject } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { SelectBillingAddressDialogService } from '../select-billing-address-dialog/select-billing-address-dialog.service'

@Component({
  selector: 'invoice-billing-address',
  templateUrl: './invoice-billing-address.component.html',
  styleUrls: ['./invoice-billing-address.component.scss'],
})
export class InvoiceBillingAddressComponent extends Destroyable implements OnInit, OnChanges {
  @Input() addressKey?: string
  @Input() defaultName?: string
  billingAddress: BillingAddress

  refresh$ = new Subject()

  constructor(private store: Store<any>, private selectBillingAddressDialog: SelectBillingAddressDialogService) {
    super()
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => this.store.pipe(select(selectCurrentAccount))),
        filter(isSomething),
        untilDestroy(this)
      )
      .subscribe((account: Account) => {
        let address = lodash.find(account.invoiceBillingAddresses, (t) => t.key === this.addressKey)
        if (!address) {
          address = account.address as BillingAddress
        }
        this.billingAddress = address
      })
    this.refresh$.next()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh$.next()
  }

  changeAddress() {
    return this.selectBillingAddressDialog
      .open(this.addressKey)
      .afterClosed()
      .pipe(
        filter((t) => lodash.isObject(t)),
        map((t) => lodash.get(t, 'key', ''))
      )
  }
}
