<form [formGroup]="form">
  <div class="row">
    <div class="col-sm-4">
      <mat-form-field2 class="mat-block">
        <mat-label>Cancellation Policy</mat-label>
        <mat-select formControlName="isFree">
          <mat-option [value]="true">Guest may cancel free</mat-option>
          <mat-option [value]="false">Mandatory cancellation fee applies</mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
    <div class="col-sm-8"></div>
    <div class="col-sm-12" *ngIf="form.get('isFree').value">
      Guest must cancel
      <mat-form-field2 class="days-select mx-1" floatLabel="never">
        <mat-select formControlName="xDaysInAdvance" required>
          <mat-option *ngFor="let opt of freeCancelDaysOptions" [value]="opt">{{ opt }}</mat-option>
        </mat-select>
      </mat-form-field2>
      days in advance.
    </div>

    <div class="col-sm-12 d-flex align-items-center" *ngIf="form.get('isFree').value === false">
      Guest will be charged
      <mat-form-field2 class="days-select mx-1" floatLabel="never">
        <mat-select formControlName="chargeXPercentFee" required>
          <mat-option *ngFor="let opt of nonfreeCancelPercentOptions" [value]="opt">{{ opt }}</mat-option>
        </mat-select>
      </mat-form-field2>
      % if they cancel right after booking. Guest will be charged 100% if they cancel less than
      <mat-form-field2 class="days-select mx-1" floatLabel="never">
        <mat-select formControlName="fullChargeDays">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let opt of nonfreeCancelFullChargeDaysOptions" [value]="opt">{{ opt }}</mat-option>
        </mat-select>
      </mat-form-field2>
      days before arrival.
    </div>

    <div class="col-sm-12" *ngIf="form.get('isFree').value && form.get('xDaysInAdvance').value; let xDaysInAdvance">
      <ng-container *ngFor="let opt of freeCancelOptionsByDays[xDaysInAdvance]">
        <mat-checkbox
          *ngIf="!isCancelPolicyChecked(opt.value)"
          (change)="onCancelPolicyChanged(opt, freeCancelCheckbox)"
          #freeCancelCheckbox
        >
          {{ opt.label }}
        </mat-checkbox>
      </ng-container>
    </div>
    <div
      class="col-sm-12"
      *ngIf="form.get('isFree').value === false && form.get('chargeXPercentFee').value; let chargeXPercentFee"
    >
      <mat-checkbox
        *ngIf="!isCancelPolicyChecked(bdcNonFreeFullChargeCancelOption.value)"
        (change)="onCancelPolicyChanged(bdcNonFreeFullChargeCancelOption, fullFeeCheckbox)"
        #fullFeeCheckbox
      >
        {{ bdcNonFreeFullChargeCancelOption.label }}
      </mat-checkbox>
      <ng-container
        *ngFor="
          let opt of nonfreeCancelOptionsByPercent[chargeXPercentFee + '|' + (form.get('fullChargeDays').value || '')]
        "
      >
        <mat-checkbox
          *ngIf="!isCancelPolicyChecked(opt.value)"
          (change)="onCancelPolicyChanged(opt, feeCheckbox)"
          #feeCheckbox
        >
          {{ opt.label }}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-sm-12 font-weight-bold mb-2">{{ maxSelectedCancelPoliciesWarningTxt }}</div>
    <div class="col-sm-12">
      <mat-checkbox
        [checked]="true"
        (change)="onCancelPolicyChanged(opt, selectedCheckbox)"
        *ngFor="let opt of activeCancelPolicies"
        #selectedCheckbox
      >
        {{ opt.label }}
      </mat-checkbox>
    </div>
  </div>
</form>
