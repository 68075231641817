import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { concatMap, map, toArray } from 'rxjs/operators'
import { DiscountCode } from './discount-code.model'
import { httpRawItemToDataItem } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class DiscountCodeService {
  constructor(private http: HttpClient) {}

  load(): Observable<DiscountCode[]> {
    const url = `@api/discountcode/all`
    return this.http.get(url).pipe(map((res: any[]) => res.map((item) => httpRawItemToDataItem(item))))
  }

  create(payload: DiscountCode): Observable<DiscountCode> {
    const url = `@api/discountcode/`

    return this.http.post<object>(url, payload).pipe(map((res) => httpRawItemToDataItem(res)))
  }

  get(id: string): Observable<DiscountCode> {
    const url = `@api/discountcode/${id}`

    return this.http.get<object>(url).pipe(map((res) => httpRawItemToDataItem(res)))
  }

  update(id: string, payload: DiscountCode): Observable<DiscountCode> {
    const url = `@api/discountcode/${id}`

    return this.http.put<object>(url, payload).pipe(map((res) => httpRawItemToDataItem(res)))
  }

  deactivate(id: string): Observable<any> {
    const url = `@api/discountcode/deactivate/${id}`
    return this.http.put(url, {})
  }

  activate(id: string): Observable<any> {
    const url = `@api/discountcode/activate/${id}`
    return this.http.put(url, {})
  }

  delete(id: string): Observable<any> {
    const url = `@api/discountcode/${id}`
    return this.http.delete(url)
  }

  deleteMultiple(ids: string[]): Observable<string[]> {
    return of(...ids).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }
}
