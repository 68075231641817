import { Pipe, PipeTransform } from '@angular/core'
import { getInvoiceLineTaxTotal, getInvoiceLineTotal, getInvoiceTotal, InvoiceItem } from '@tokeet-frontend/invoices'
import { TaxV3 } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'invoiceLineTaxTotal',
})
export class InvoiceLineTaxTotalPipe implements PipeTransform {
  transform(item: InvoiceItem, taxes?: TaxV3[], guests = 1): number {
    return getInvoiceLineTaxTotal(item, taxes, guests)
  }
}

@Pipe({
  name: 'invoiceLineTotal',
})
export class InvoiceLineTotalPipe implements PipeTransform {
  transform(item: InvoiceItem, partial = true, guests = 1): number {
    return getInvoiceLineTotal(item, partial, guests)
  }
}

@Pipe({
  name: 'invoiceTotal',
})
export class InvoiceTotalPipe implements PipeTransform {
  transform(invoiceItems: InvoiceItem[], partial = true, guests = 1): number {
    return getInvoiceTotal(invoiceItems, partial, guests)
  }
}
