import { User, Serializable } from '@tokeet-frontend/tv3-platform'

export class LoginResponse extends Serializable<LoginResponse> {
  token: string
  user: User
  mfa_bypass?: string

  static deserialize(data: object): LoginResponse {
    const loginResponse = new LoginResponse()

    loginResponse.token = data['token']
    loginResponse.mfa_bypass = data['mfa_bypass']
    loginResponse.user = User.deserialize(data)

    return loginResponse
  }
}
