import { Component, ViewChild, Input, AfterViewInit, OnInit, ElementRef } from '@angular/core'
import { Chart, ChartConfiguration } from 'chart.js'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { Destroyable, ExpenseCategoryReportItem, getDataColorByIndex } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { DecimalPipe } from '@angular/common'
import { ExpenseReportFilters } from '../report-filters/expense-report-filters.component'

@Component({
  selector: 'app-expense-monthly-chart',
  templateUrl: './expense-monthly-chart.component.html',
  styleUrls: ['./expense-monthly-chart.component.scss'],
})
export class ExpenseMonthlyChartComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild('ctx') ctx: ElementRef<HTMLCanvasElement>

  @Input() isLoading = false
  @Input() set data(d: Record<string, ExpenseCategoryReportItem[]>) {
    this.drawChart(d)
  }
  @Input() filters: ExpenseReportFilters

  private chart: Chart
  tableType = TableType.BookingMtdChart

  constructor(private elRef: ElementRef) {
    super()
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.chart = new Chart(this.ctx.nativeElement, getChartOptions())
  }

  drawChart(itemsByCategory: Record<string, ExpenseCategoryReportItem[]>) {
    if (!this.chart || !this.filters) return
    const year = this.filters?.year
    const month = this.filters?.month
    const days = lodash.range(1, moment().year(year).month(month).daysInMonth() + 1)
    const dates = days.map((d) => moment().year(year).month(month).date(d).format('YYYY-MM-DD'))
    const itemsByCategoryByDate = lodash.mapValues(itemsByCategory, (t) => lodash.keyBy(t, 'date'))

    const categories = Object.keys(itemsByCategory)

    const items = categories.map((cat) => {
      return {
        name: cat,
        data: dates.map((d) => itemsByCategoryByDate[cat]?.[d]?.amount || 0),
      }
    })
    this.chart.data.labels = days
    this.chart.data.datasets = items.map((item, i) => {
      const color = getDataColorByIndex(i)
      return {
        stack: 'stack0',
        label: item.name,
        backgroundColor: color,
        data: item.data,
      }
    })
    this.chart.update()
    setChartLegends(this.chart, this.elRef.nativeElement)
  }
}

function getChartOptions(): ChartConfiguration {
  const numberPipe = new DecimalPipe('en-US')
  return {
    type: 'bar',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'bottom',
      },
      legendCallback,
      scales: {
        xAxes: [
          {
            display: true,
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (tickValue: number) {
                return numberPipe.transform(tickValue, '1.0-2')
              },
            },
          },
        ],
      },
      tooltips: {
        mode: 'index',
        position: 'nearest',
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex]
            return `${dataset.label}: ${numberPipe.transform(dataset.data[tooltipItem.index] as number, '1.0-2')}`
          },
        },
      },
    },
  }
}

function legendCallback(chart: Chart) {
  const { data } = chart
  const total = lodash.sum(data.datasets.map((dataset) => lodash.sum(dataset.data)))
  const renderLabels = () => {
    return data.datasets
      .map((dataset, i) => {
        const subtotal = lodash.sum(dataset.data)
        return `<li class="text-nowrap legend-item" id="legend-${i}-item">
            <i class="fas fa-circle" style="color: ${dataset.backgroundColor}"></i>
            <span class="legend-label">
              <span class="text-light">${dataset.label}: </span> ${((subtotal / total) * 100).toFixed(2)}%
            </span>
          </li>
        `
      })
      .join('')
  }
  return `
    <ul class="d-flex align-items-center justify-content-center flex-wrap gap-2 list-unstyled m-2 text-sm">
      ${renderLabels()}
    </ul>`
}

const setChartLegends = (myChart: Chart, hostElement: HTMLElement) => {
  const containerElement = hostElement.querySelector('#legend-container')
  containerElement.innerHTML = myChart.generateLegend() as string

  const legendItemSelector = '.legend-item'
  const labelSelector = '.legend-label'

  const legendItems = containerElement.querySelectorAll(legendItemSelector)
  legendItems.forEach((item, i) => {
    item.addEventListener('click', (e: MouseEvent) => updateDataset(e.currentTarget as HTMLElement, i))
  })

  const updateDataset = (currentEl: HTMLElement, index: number) => {
    const meta = myChart.getDatasetMeta(index)
    const labelEl: HTMLElement = currentEl.querySelector(labelSelector)
    meta.hidden = !meta.hidden
    labelEl.style.textDecoration = meta.hidden ? 'line-through' : 'none'
    myChart.update()
  }
}
