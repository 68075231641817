<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header btn-holder tab-nav-holder">
      <div class="col-sm-12 nav-tab-holder">
        <ul class="nav nav-tabs" role="tablist">
          <li>
            <a (click)="tab$.next(tabs.Property)" [class.active]="tab$.value === tabs.Property"> Property </a>
          </li>
          <li *ngIf="bdcProperty">
            <a (click)="tab$.next(tabs.Room)" [class.active]="tab$.value === tabs.Room"> Room </a>
          </li>
          <li *ngIf="bdcProperty">
            <a (click)="tab$.next(tabs.Rate)" [class.active]="tab$.value === tabs.Rate"> Rate Plan </a>
          </li>
          <li *ngIf="bdcProperty && this.bdcRoom">
            <a (click)="tab$.next(tabs.Product)" [class.active]="tab$.value === tabs.Product"> Product </a>
          </li>
        </ul>
      </div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>

    <div class="modal-body">
      <div class="tab-content">
        <section [ngSwitch]="tab$.value" class="rental-settings-page">
          <app-bdc-content-property-setup
            *ngSwitchCase="tabs.Property"
            [channel]="channel"
            [connection]="connection"
            [rental]="rental"
            [bdcProperty]="bdcProperty"
            [bdcPropertySetting]="bdcPropertySettings"
            (rentalChange)="onRentalChange($event)"
          >
          </app-bdc-content-property-setup>
          <app-bdc-content-room-setup
            *ngSwitchCase="tabs.Room"
            [channel]="channel"
            [connection]="connection"
            [bdcProperty]="bdcProperty"
            [rental]="rental"
          >
          </app-bdc-content-room-setup>
          <app-bdc-content-rate-setup
            *ngSwitchCase="tabs.Rate"
            [channel]="channel"
            [connection]="connection"
            [bdcProperty]="bdcProperty"
            [rental]="rental"
          >
          </app-bdc-content-rate-setup>
          <app-bdc-content-product-setup
            *ngSwitchCase="tabs.Product"
            [channel]="channel"
            [connection]="connection"
            [bdcProperty]="bdcProperty"
            [rental]="rental"
          >
          </app-bdc-content-product-setup>
        </section>
      </div>
    </div>
  </div>
</div>
