import * as R from 'ramda'
import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import * as reducer from './payment-rule.reducer'
import * as state from './payment-rule.state'
import {
  selectAllChannels,
  selectAllRentals,
  tokeetDashboardChannel,
  tokeetWebsitesChannel,
} from '@tokeet-frontend/tv3-platform'
import { PaymentRuleView } from '@tv3/store/payment-rule/payment-rule.view'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { PaymentGateway, selectAllPaymentGateways } from '@tokeet-frontend/gateways'
import * as lodash from 'lodash'
import { selectChannelsWithCustomChannels } from '../channel/selectors'

export const paymentRuleState = createFeatureSelector<state.State>('payment-rule')

export const selectAllPaymentRules = createSelector(paymentRuleState, reducer.selectAll)

export const selectPaymentRulesLoaded = createSelector(paymentRuleState, (s: state.State) => s.isLoaded)

export const selectPaymentRulesByGateway = (gatewayId) =>
  createSelector(selectAllPaymentRules, (paymentRules) => paymentRules.filter((rule) => rule.gatewayId === gatewayId))

export const selectAllPaymentRuleViews = createSelector(
  selectAllPaymentRules,
  selectAllPaymentGateways,
  selectAllRentals,
  (paymentRules, paymentGateways, rentals) => {
    return paymentRules.map((rule) => {
      const gateway = rule.gatewayId && R.find((g: PaymentGateway) => g.pkey === rule.gatewayId, paymentGateways)
      const gatewayName = gateway && (gateway.name || gateway.pkey.split('-').slice(-1)[0])

      const rentalName = rule.rentalId && <any>R.pipe(
          R.find((rental: Rental) => rental.id === rule.rentalId),
          R.prop('name')
        )(rentals)

      const priority =
        R.pipe(
          R.map((i: any) => i(rule)),
          R.find((i) => i)
        )([(r) => r.rentalId && r.channel && 1, (r) => r.rentalId && 2, (r) => r.channel && 3]) || 0

      return {
        ...rule,
        gatewayName,
        rentalName,
        priority,
      } as PaymentRuleView
    })
  }
)

export const selectChannelsForPaymentRule = createSelector(
  selectChannelsWithCustomChannels(selectAllChannels, 'token', [tokeetDashboardChannel, tokeetWebsitesChannel]),
  (items) => {
    return items
  }
)
