import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'

import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { TableType } from '@tv3/shared/empty-table/table-type'
import {
  isSomething,
  Rental,
  selectAllRentals,
  selectRentalTags,
  selectSome,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { isUpdatingWebsite } from '@tv3/store/website/website.selectors'
import { localeCompareSort, isEmptyTable } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsiteRentals } from '@tv3/store/website/website.actions'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { FormBuilder } from '@angular/forms'
import { map, startWith, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-website-details-rentals',
  templateUrl: './website-details-rentals.component.html',
  styleUrls: ['./website-details-rentals.component.scss'],
})
export class WebsiteDetailsRentalsComponent extends SelectableRow<Rental> implements OnInit, OnChanges {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  @Input() website: Website

  displayedColumns = ['select', 'name', 'descriptionView', 'phone', 'cityView', 'countryView']
  tableTooltip = ''

  tableType = TableType.WebsiteRentals
  isEmptyTable$ = isEmptyTable(this.dataSource)

  isUpdating$ = this.store.pipe(select(isUpdatingWebsite))
  rentals: Rental[] = []
  tags$ = this.store.pipe(select(selectRentalTags))
  form = this.fb.group({
    search: [''],
    tags: [[]],
  })

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,

    protected storage: UserStorage,
    private toaster: Toaster
  ) {
    super(storage, TableType.WebsiteRentals)
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.paginator.firstPage()

    this.store
      .pipe(
        selectSome(selectAllRentals),
        switchMap((rentals: Rental[]) =>
          this.form.valueChanges.pipe(
            startWith(this.form.getRawValue()),
            map(({ tags }) => {
              if (!isSomething(tags)) return rentals
              return R.filter(
                (r) =>
                  R.any(
                    R.equals(true),
                    R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], r)), tags)
                  ),
                rentals
              )
            })
          )
        ),
        untilDestroy(this)
      )
      .subscribe((rentals) => {
        this.rentals = rentals
        this.dataSource.data = rentals || []
        this.refreshWebsiteRentals()
      })

    this.tableTooltip = this.getTableTooltip()

    this.form
      .get('search')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((term) => {
        this.paginator.firstPage()
        this.dataSource.filter = (term || '').trim().toLowerCase()
      })
  }

  private getTableTooltip() {
    switch (this.website.type) {
      case WebsiteTypes.GuestPortal:
        return 'Select the rentals which you want to include in this guest portal.'
      case WebsiteTypes.BookingEngine:
        return 'Select the rentals which you want to include in this booking engine.'
      case WebsiteTypes.RentalWebsite:
        return 'Select the rentals which you want to include in this website. Switching between a single rental and multiple rentals will automatically change the template used.'
      default:
        return ''
    }
  }

  refreshWebsiteRentals() {
    const websiteRentalIds = this.website.rentals || []
    const selectedRentals = R.filter((rental: Rental) => websiteRentalIds.indexOf(rental.id) > -1, this.rentals)
    this.selection.select(...selectedRentals)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshWebsiteRentals()
  }

  onOpenRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }

  onSave() {
    const selected = this.getSelected() as Rental[]
    if (!selected.length) {
      this.toaster.warning('At least one rental is required.')
      return
    }

    const payload = {
      ...this.website.serialize(),
      rentals: R.map((r) => r.id, selected),
    }
    this.store.dispatch(UpdateWebsiteRentals({ id: this.website.id, data: payload }))
  }
}
