import { ActivePlansByProduct, ActivePlanStatus, PlanOption } from '@tv3/store/plan/plan.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Card } from '@tokeet-frontend/billing'

export interface State extends EntityState<PlanOption> {
  isPlansLoaded: {
    [product: string]: Boolean
  }
  isActivePlansLoaded: boolean
  activePlans: ActivePlansByProduct
  endedPlans: ActivePlansByProduct
  status: ActivePlanStatus
  card: Card
  isUpdatingPlan: boolean
}

export const adapter: EntityAdapter<PlanOption> = createEntityAdapter<PlanOption>({
  sortComparer: (a, b): number => {
    return a.rentals - b.rentals
  },
})

export const initialState: State = adapter.getInitialState({
  isPlansLoaded: {},
  isActivePlansLoaded: false,
  activePlans: {},
  endedPlans: {},
  status: null,
  card: null,
  isUpdatingPlan: false,
})
