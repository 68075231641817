<div class="modal-header">
  <div class="modal-title d-flex gap-2">
    <div>
      Property ID ({{ connection.propertyId }}) - {{ connection.roomName || connection.propertyName }} ({{
        connection.roomId
      }})
    </div>
    <app-page-help [articleId]="'-'" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<mat-stepper [linear]="true" class="m-3" #stepper>
  <mat-step label="Connecting" [state]="stepper.selectedIndex > 0 ? 'done' : 'number'">
    <app-agoda-listing-wizard-step1
      (next)="stepper.next()"
      [channel]="channel"
      [connection]="connection"
    ></app-agoda-listing-wizard-step1>
  </mat-step>
  <mat-step label="Rates" [state]="stepper.selectedIndex > 1 ? 'done' : 'number'">
    <app-agoda-listing-wizard-step2
      (prev)="stepper.previous()"
      (next)="stepper.next()"
      [connection]="connection"
      [channel]="channel"
      #step2
    ></app-agoda-listing-wizard-step2>
  </mat-step>
  <mat-step label="Review" [state]="stepper.selectedIndex > 2 ? 'done' : 'number'">
    <app-agoda-listing-wizard-step3
      (prev)="stepper.previous()"
      (done)="close()"
      #step3
    ></app-agoda-listing-wizard-step3>
  </mat-step>
  <ng-template matStepperIcon="done">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
</mat-stepper>
