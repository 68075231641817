import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { reducer } from './reducer'
import { EntityAttributeDescService } from './service'
import { AttributeDescriptionEffects } from './effects'

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AttributeDescriptionEffects]),
    StoreModule.forFeature('attr-desc', reducer),
  ],
  providers: [EntityAttributeDescService],
  declarations: [],
})
export class AttributeDescriptionStoreModule {}
