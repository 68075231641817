export const airlines: { code: string; name: string }[] = [
  { code: '02Q', name: 'Titan Airways' },
  { code: 'TO', name: 'Transavia France' },
  { code: 'EW', name: 'Eurowings' },
  { code: 'U2', name: 'easyJet' },
  { code: 'A5', name: 'HOP' },
  { code: '04Q', name: 'Tradewind Aviation' },
  { code: '05Q', name: 'Comlux Aviation, AG' },
  { code: '06Q', name: 'Master Top Linhas Aereas Ltd.' },
  { code: '07Q', name: 'Flair Airlines Ltd.' },
  { code: '09Q', name: 'Swift Air, LLC' },
  { code: '0BQ', name: 'DCA' },
  { code: '0CQ', name: 'ACM AIR CHARTER GmbH' },
  { code: '0GQ', name: 'Inter Island Airways, d/b/a Inter Island Air' },
  { code: '0HQ', name: 'Polar Airlines de Mexico d/b/a Nova Air' },
  { code: '0J', name: 'JetClub AG' },
  { code: '0JQ', name: 'Vision Airlines' },
  { code: '0LQ', name: 'Metropix UK, LLP.' },
  { code: '0MQ', name: 'Multi-Aero, Inc. d/b/a Air Choice One' },
  { code: '0OQ', name: 'Open Skies' },
  { code: '0Q', name: 'Flying Service N.V.' },
  { code: '0QQ', name: 'TAG Aviation (UK) Ltd.' },
  { code: '0RQ', name: 'TAG Aviation Espana S.L.' },
  { code: '0TQ', name: 'Corporatejets, XXI' },
  { code: '0UQ', name: 'Comlux Malta, Ltd.' },
  { code: '0VQ', name: 'Ocean Sky (UK) Limited' },
  { code: '0WQ', name: 'Avjet Corporation' },
  { code: '0YQ', name: 'Comlux Malta Ltd.' },
  { code: '10Q', name: 'Swiss Air Ambulance' },
  { code: '12Q', name: 'Unijet' },
  { code: '13Q', name: 'Chartright Air Inc.' },
  { code: '14Q', name: 'London Air Services Limited' },
  { code: '15Q', name: 'Air Alsie A/S' },
  { code: '16', name: 'PSA Airlines Inc.' },
  { code: '17', name: 'Piedmont Airlines' },
  { code: '17Q', name: 'Albinati Aeronautics SA' },
  { code: '1AQ', name: 'Charter Air Transport, Inc.' },
  { code: '1BQ', name: 'Dynamic Airways, LLC' },
  { code: '1DQ', name: 'Island Airlines LLC' },
  { code: '1EQ', name: 'KaiserAir, Inc.' },
  { code: '1FQ', name: 'Alsek Air' },
  { code: '1HQ', name: 'International Jet Management GmbH' },
  { code: '1I', name: 'Sky Trek International Airlines' },
  { code: '1IQ', name: 'Jet link AG' },
  { code: '1JQ', name: 'Exec Direct Aviation Services Limited' },
  { code: '1LQ', name: 'Bertelsmann Aviation GmbH' },
  { code: '1MQ', name: 'Twin Cities Air Service LLC' },
  { code: '1NQ', name: 'JSC The 224th Flight Unit State Airlines' },
  { code: '1PQ', name: 'Makani Kai Air Charters' },
  { code: '1QQ', name: 'City Wings Inc dba Seaflight' },
  { code: '1RQ', name: 'Sun Air Express LLC dba Sun Air International' },
  { code: '1SQ', name: 'Star Marianas Air Inc.' },
  { code: '1TQ', name: 'Rhoades Aviation dba Transair' },
  { code: '1UQ', name: 'Gainjet Aviation SA' },
  { code: '1VQ', name: 'Scott Air LLC dba Island Air Express' },
  { code: '1WQ', name: 'Ultimate JetCharters LLC dba Ultimate Air Shuttle' },
  { code: '1XQ', name: 'Dassault Falcon Service' },
  { code: '2E', name: 'Smokey Bay Air Inc.' },
  { code: '2F', name: 'Frontier Flying Service' },
  { code: '2K', name: 'Aerolineas Galapagos S A Aerogal' },
  { code: '2M', name: 'Midway Express Airlines' },
  { code: '2O', name: 'Island Air Service' },
  { code: '2R', name: 'Regal Air' },
  { code: '2T', name: 'Canada 3000 Airlines Ltd.' },
  { code: '2U', name: 'Valley Air Express Inc.' },
  { code: '37', name: 'Zeal 320' },
  { code: '3C', name: 'Regions Air, Inc.' },
  { code: '3F', name: 'Pacific Airways, Inc.' },
  { code: '3M', name: 'Silver Airways' },
  { code: '3S', name: 'AeroLogic GmbH' },
  { code: '3U', name: 'Sichuan Airlines Co Ltd.' },
  { code: '4B', name: 'Boutique Air' },
  { code: '4B (1)', name: 'Olson Air Service' },
  { code: '4C', name: 'Aerovias de Intergracian Regional' },
  { code: '4E', name: 'Tanana Air Service' },
  { code: '4E (1)', name: 'British Airtours Limited' },
  { code: '4H', name: 'Belize Trans Air' },
  { code: '4M', name: 'LAN Argentina' },
  { code: '4M (1)', name: 'Lan Dominica' },
  { code: '4N', name: 'Air North' },
  { code: '4O', name: 'ABC Aerolineas SA de CV dba Interjet' },
  { code: '4R', name: 'Regent Air Corporation' },
  { code: '4S', name: 'Sol Air (Aero Hunduras)' },
  { code: '4S (1)', name: 'Conner Air Lines Inc.' },
  { code: '4T', name: 'Belair Airlines Ltd.' },
  { code: '4W', name: 'Warbelow' },
  { code: '4Y', name: 'Yute Air Aka Flight Alaska' },
  { code: '5B', name: 'Bellair Inc.' },
  { code: '5C', name: 'C.A.L Cargo Airlines' },
  { code: '5D', name: 'Aerolitoral' },
  { code: '5F', name: 'Arctic Circle Air Service' },
  { code: '5G', name: 'Skyservice Airlines, Inc.' },
  { code: '5G (1)', name: 'Queen Air' },
  { code: '5J', name: 'Private Jet Expeditions' },
  { code: '5L', name: 'Aerosur' },
  { code: '5V', name: 'Tatonduk Outfitters Limited d/b/a Everts Air Alaska and Everts Air Cargo' },
  { code: '5X', name: 'United Parcel Service' },
  { code: '5Y', name: 'Atlas Air Inc.' },
  { code: '6A', name: 'Aviacsa Airlines' },
  { code: '6B', name: 'TUIfly Nordic A.B.' },
  { code: '6C', name: 'Cape Smythe Air Service' },
  { code: '6F', name: 'Laker Airways Inc.' },
  { code: '6H', name: 'Israir Airlines' },
  { code: '6P', name: 'Pacific East Air Inc.' },
  { code: '6R', name: 'Aerounion Aerotransporte de Carga Union SA de CV' },
  { code: '6U', name: 'Air Ukraine' },
  { code: '6Y', name: 'Nicaraguense De Aviacion Sa' },
  { code: '7B', name: 'Krasair' },
  { code: '7C', name: 'Jeju Air Co Ltd.' },
  { code: '7F', name: 'First Air' },
  { code: '7G', name: 'MK Airlines Ltd.' },
  { code: '7G (1)', name: 'Bellair Inc. (1)' },
  { code: '7H', name: 'Era Aviation' },
  { code: '7I', name: 'Insel Air International B V' },
  { code: '7M', name: 'Aeromonterrey S.A.' },
  { code: '7N', name: 'Inland Aviation Services' },
  { code: '7P', name: 'Apa International Air S.A.' },
  { code: '7Q', name: 'Pan American World Airways Dominicana' },
  { code: '7S', name: 'Arctic Transportation' },
  { code: '7S (1)', name: 'Skystar International Inc.' },
  { code: '7Z', name: 'Lb Limited' },
  { code: '8C', name: 'Air Transport International' },
  { code: '8D', name: 'Servant Air Inc.' },
  { code: '8E', name: 'Bering Air Inc.' },
  { code: '8F', name: 'Wilburs Inc.' },
  { code: '8J', name: 'Jetall Airways' },
  { code: '8L', name: 'Servicio Aereo Leo Lopez' },
  { code: '8N', name: 'Flagship Airlines Inc.' },
  { code: '8Q', name: 'Baker Aviation Inc.' },
  { code: '8R', name: 'Edelweiss Air Ag' },
  { code: '8V', name: 'Wright Air Service' },
  { code: '9E', name: 'Endeavor Air Inc.' },
  { code: '9J', name: 'Pacific Island Aviation' },
  { code: '9K', name: 'Cape Air' },
  { code: '9L', name: 'Colgan Air' },
  { code: '9M', name: 'Central Mountain Air' },
  { code: '9N', name: 'ANA & JP Express Co. Ltd.' },
  { code: '9R', name: 'Flagship Express Services' },
  { code: '9S', name: 'Southern Air Inc.' },
  { code: '9T', name: 'TravelspanGT, Inc. S.A.' },
  { code: '9V', name: 'Avior Airlines, C.A.' },
  { code: '9W', name: 'Jet Airways (India) Limited' },
  { code: 'A0', name: "Elysair d/b/a L'Avion" },
  { code: 'A2', name: 'Cielos De Peru' },
  { code: 'A4', name: 'Southern Wind Airlines' },
  { code: 'A7', name: 'Air Comet S.A.' },
  { code: 'A7 (1)', name: 'Air 21' },
  { code: 'AA', name: 'American Airlines Inc.' },
  { code: 'AAA', name: 'Associated Aviation Act.' },
  { code: 'AAB', name: 'Antilles Air Boats Inc.' },
  { code: 'AAE', name: 'AAA Airlines' },
  { code: 'AAI', name: 'Audi Air Inc.' },
  { code: 'AAL', name: 'Argosy Air Lines Inc.' },
  { code: 'AAP', name: 'Armstrong Air Service Inc.' },
  { code: 'AAQ', name: 'Advance Air Charters' },
  { code: 'AAR', name: 'Altair Airlines Inc.' },
  { code: 'AAT', name: 'Air Sunshine Inc.' },
  { code: 'AAV', name: 'Alpine Air' },
  { code: 'AAZ', name: 'Alaska Aeronautical Indust.' },
  { code: 'AB', name: 'Air Berlin PLC and CO' },
  { code: 'ABH', name: 'Air Bahia' },
  { code: 'ABI', name: 'Airbama Incorporated' },
  { code: 'ABO', name: 'Aeronaves Boringuena Inc.' },
  { code: 'ABX', name: 'ABX Air, Inc.' },
  { code: 'AC', name: 'Air Canada' },
  { code: 'ACA', name: 'Alaska Central Airways Inc.' },
  { code: 'ACD', name: 'Academy Airlines' },
  { code: 'ACG', name: 'Air Cargo Express Inc.' },
  { code: 'ACH', name: 'Island Airlines Hawaii Inc.' },
  { code: 'ACI', name: 'AAA-Action Air Carrier Inc.' },
  { code: 'ACJ', name: 'Atlantic Coast Jet Inc.' },
  { code: 'ACK', name: 'Air Central Inc.' },
  { code: 'ACL', name: 'Check-Air Inc.' },
  { code: 'ACN', name: 'Alliance Airlines' },
  { code: 'ACO', name: 'Air Cargo Enterprises Inc.' },
  { code: 'ACP', name: 'Air Chaparral Inc.' },
  { code: 'ACQ', name: 'Aeronautica De Cancun' },
  { code: 'ACS', name: 'Alamo Commuter Airlines' },
  { code: 'ACT', name: 'Air Central Inc. (1)' },
  { code: 'ACW', name: 'Transwest Air Express' },
  { code: 'AD', name: 'Avia Leasing Company' },
  { code: 'ADB', name: 'Antonov Company' },
  { code: 'ADQ', name: 'Aeronaves Del Peru' },
  { code: 'ADR', name: 'Adirondack Airlines' },
  { code: 'ADV', name: 'Advantage Airlines' },
  { code: 'AE', name: 'Air Europe Limited' },
  { code: 'AEC', name: 'Astec Air East Inc.' },
  { code: 'AED', name: 'Air East Of Delaware Inc.' },
  { code: 'AEF', name: 'Aero Freight' },
  { code: 'AEO', name: 'Aero Coach' },
  { code: 'AEQ', name: 'Air Caravane Inc.' },
  { code: 'AEX', name: 'Air Excursions LLC (1)' },
  { code: 'AF', name: 'Compagnie Natl Air France' },
  { code: 'AFA', name: 'American Flag Airlines Inc.' },
  { code: 'AFE', name: 'Air Florida Express' },
  { code: 'AFG', name: 'American Flight Group' },
  { code: 'AFQ', name: 'Aero B Venezuela C.A.' },
  { code: 'AFS', name: 'Arcata Flying Service' },
  { code: 'AGA', name: 'Air Cargo America Inc.' },
  { code: 'AGQ', name: 'Argo S. A.' },
  { code: 'AHA', name: 'Air Alpha' },
  { code: 'AHC', name: 'Air Hawaii' },
  { code: 'AHQ', name: 'Air National' },
  { code: 'AI', name: 'National Aviation Company of India Limited d/b/a Air India' },
  { code: 'AIA', name: 'All Island Air' },
  { code: 'AIC', name: 'Air Caribbean' },
  { code: 'AIE', name: 'Air Express Inc.' },
  { code: 'AIG', name: 'Airgo Inc.' },
  { code: 'AIK', name: 'Air Ketchum Idaho Inc.' },
  { code: 'AIM', name: 'Air Mont. Inc.' },
  { code: 'AIN', name: 'Air Indiana' },
  { code: 'AIQ', name: 'Norontair' },
  { code: 'AIT', name: 'Airways International' },
  { code: 'AIY', name: 'Alliance Air' },
  { code: 'AJ', name: 'Air Micronesia Inc.' },
  { code: 'AJQ', name: 'Aerodynamics Inc.' },
  { code: 'AKQ', name: 'Airmark Airlines Inc.' },
  { code: 'AL', name: 'Skyway Airlines' },
  { code: 'ALD', name: 'Allied Airlines' },
  { code: 'ALE', name: 'Air-Lift Associates Inc.' },
  { code: 'ALI', name: 'Brandt Air' },
  { code: 'ALK', name: 'Air Link Airways' },
  { code: 'ALL', name: 'All Seasons Air Pacific' },
  { code: 'ALQ', name: 'Alberta Northern Airlines' },
  { code: 'ALS', name: 'Air L.A.' },
  { code: 'ALT', name: 'Altus Airlines' },
  { code: 'ALU', name: 'Aleutian Air' },
  { code: 'ALX', name: 'Atlanta Express' },
  { code: 'AM', name: 'Aeromexico' },
  { code: 'AMA', name: 'North American Airlines Inc.' },
  { code: 'AME', name: 'Americair Inc.' },
  { code: 'AMI', name: 'American Inter-Island Inc.' },
  { code: 'AMQ', name: 'Ameristar Air Cargo' },
  { code: 'AMR', name: 'AvAir' },
  { code: 'AMT', name: 'American Air Transport' },
  { code: 'ANB', name: 'Air Nebraska Inc.' },
  { code: 'ANC', name: 'Atlantic Air' },
  { code: 'AND', name: 'Southcentral Air Inc.' },
  { code: 'ANI', name: 'Dash Air Corporation' },
  { code: 'ANM', name: 'Airways Of New Mexico Inc.' },
  { code: 'ANO', name: 'Metro Air Northeast Vermont' },
  { code: 'ANT', name: 'Air North/Nenana' },
  { code: 'AO', name: 'Aviacion Y Comercio S.A.' },
  { code: 'AOI', name: 'Trans New York' },
  { code: 'AON', name: 'Action Airlines' },
  { code: 'AOQ', name: 'Aerovias S.A.' },
  { code: 'AOR', name: 'Air New Orleans' },
  { code: 'APE', name: 'Air Cape' },
  { code: 'APH', name: 'Alpha Air' },
  { code: 'API', name: 'Arizona Pacific Inc.' },
  { code: 'APL', name: 'Appalachian Flying Service' },
  { code: 'APN', name: 'Aspen Airways Inc.' },
  { code: 'APQ', name: 'Alas De Transporte International' },
  { code: 'APS', name: 'Air Spirit Inc.' },
  { code: 'APY', name: 'Air Polynesia Inc.' },
  { code: 'AQQ', name: 'Air Charter (Safa)' },
  { code: 'AR', name: 'Aerolineas Argentinas' },
  { code: 'ARA', name: 'Air Resorts Airlines' },
  { code: 'ARB', name: 'Air Caribe International' },
  { code: 'ARD', name: 'Airlift International' },
  { code: 'ARI', name: 'Arizona Aero Corp.' },
  { code: 'ARM', name: 'Airmarc Airlines Inc.' },
  { code: 'ARN', name: 'Arnold Aviation' },
  { code: 'ARO', name: 'Air Oregon' },
  { code: 'ARP', name: 'Airspur Helicopters Inc.' },
  { code: 'ARQ', name: 'Aerovias Colombianas Arca' },
  { code: 'ARR', name: 'Arrowhead Airways Inc.' },
  { code: 'ARS', name: 'Air Sedona' },
  { code: 'ARZ', name: 'Arizona Airways' },
  { code: 'AS', name: 'Alaska Airlines Inc.' },
  { code: 'ASC', name: 'Air South Inc.' },
  { code: 'ASD', name: 'Amistad Airlines' },
  { code: 'ASH', name: 'Air South' },
  { code: 'ASI', name: 'Alpine Air Services Inc.' },
  { code: 'ASN', name: 'Air Casino' },
  { code: 'ASP', name: 'Asap Air Inc.' },
  { code: 'ASQ', name: 'Aerosur (1)' },
  { code: 'ASR', name: 'Astor Air' },
  { code: 'AST', name: 'Astro Airways' },
  { code: 'ASU', name: 'Air South (1)' },
  { code: 'ASW', name: 'Sunrise Airlines Inc.' },
  { code: 'AT', name: 'Royal Air Maroc' },
  { code: 'ATH', name: 'Air Southeast' },
  { code: 'ATN', name: 'Air Atlantic Airlines Inc.' },
  { code: 'ATO', name: 'Pacific National Airways' },
  { code: 'ATQ', name: 'Aero Tranportes Panamenos' },
  { code: 'ATS', name: 'Atlantis Airlines Inc.' },
  { code: 'ATT', name: 'Aerotransit Inc.' },
  { code: 'ATX', name: 'Air Carolina' },
  { code: 'AUX', name: 'Austin Express Inc.' },
  { code: 'AV', name: 'Aerovias Nacl De Colombia' },
  { code: 'AVA', name: 'Air Vectors Airways' },
  { code: 'AVC', name: 'Av Center Inc.' },
  { code: 'AVG', name: 'Airvantage' },
  { code: 'AVI', name: 'Aero Virgin Islands Corp.' },
  { code: 'AVL', name: 'Aviation Sales' },
  { code: 'AVQ', name: 'Aero Laguna S.A. De C.V.' },
  { code: 'AVR', name: 'Air Vermont Inc.' },
  { code: 'AVS', name: 'Aviation Services' },
  { code: 'AVZ', name: 'Southern Jersey Airways' },
  { code: 'AWA', name: 'Air West Airlines' },
  { code: 'AWQ', name: 'Aermediterranea S.P.A.' },
  { code: 'AX', name: 'Trans States Airlines' },
  { code: 'AXQ', name: 'Aeron International Airlines Inc.' },
  { code: 'AY', name: 'Finnair Oy' },
  { code: 'AYQ', name: 'Aerodan S.A. De C.V.' },
  { code: 'AZ', name: 'Compagnia Aerea Italiana' },
  { code: 'AZP', name: 'Arizona Pacific Airways' },
  { code: 'AZQ', name: 'Anglo Airlines Ltd.' },
  { code: 'B6', name: 'JetBlue Airways' },
  { code: 'BA', name: 'British Airways Plc' },
  { code: 'BAA', name: 'Aero International Airlines' },
  { code: 'BAC', name: 'Business Aircraft Corp.' },
  { code: 'BAH', name: 'Brennan & Hargreaves Inc.' },
  { code: 'BAK', name: 'Bankair Inc.' },
  { code: 'BAN', name: 'Bangor International Air' },
  { code: 'BAQ', name: 'Aero Rentas De Coahuila S.A. De C.V.' },
  { code: 'BAR', name: 'Bard-Air Corp.' },
  { code: 'BAT', name: 'West Penn Commuter' },
  { code: 'BAY', name: 'Burlington Aeroplane Co.' },
  { code: 'BBQ', name: 'Balair Ag' },
  { code: 'BC', name: 'Skymark Airlines Inc.' },
  { code: 'BCA', name: 'Baja Cortez Airlines' },
  { code: 'BCQ', name: 'Bradley Air Services Ltd.' },
  { code: 'BD', name: 'British Midland Airways Ltd.' },
  { code: 'BDE', name: 'Jet Express' },
  { code: 'BDQ', name: 'Aerotaxis De Aguascalientes S.A. De C.V.' },
  { code: 'BE', name: 'Braniff International Airlines Inc.' },
  { code: 'BEA', name: 'Business Express Airlines' },
  { code: 'BEN', name: 'Bennington Aviation Inc.' },
  { code: 'BEQ', name: 'Air Belgium' },
  { code: 'BES', name: 'Aeroservices Executive' },
  { code: 'BEY', name: 'Beyer Aviation' },
  { code: 'BF', name: 'Markair Inc.' },
  { code: 'BFG', name: 'Million Air' },
  { code: 'BFQ', name: 'Buffalo Airways Inc.' },
  { code: 'BG', name: 'Biman Bangladesh Airlines' },
  { code: 'BGQ', name: "Airbus Transport Internat'l" },
  { code: 'BHK', name: 'Blackhawk Airways Inc.' },
  { code: 'BHO', name: 'Bighorn Airways Inc.' },
  { code: 'BHQ', name: 'Turks Air Ltd. (1)' },
  { code: 'BIA', name: 'Big Island Air' },
  { code: 'BIQ', name: 'Servicios Aeronauticos Z S.A. De C.V.' },
  { code: 'BIR', name: 'Birchwood Air Service' },
  { code: 'BJQ', name: 'Taxirey S.A. De C.V.' },
  { code: 'BLQ', name: 'Air Bvi Limited' },
  { code: 'BM', name: 'Belize Air International Ltd.' },
  { code: 'BMT', name: 'Bremerton Aviation Inc.' },
  { code: 'BN', name: 'Britannia Airways GmbH' },
  { code: 'BN (1)', name: 'Braniff Inc.' },
  { code: 'BNQ', name: 'NetJets Large Aircraft Company L.L.C.' },
  { code: 'BOA', name: 'Boise Air Service' },
  { code: 'BOI', name: 'Bo-S-Aire Airlines Inc.' },
  { code: 'BOQ', name: 'Transp. Aereos Bolivianos' },
  { code: 'BQ', name: 'Aeromar C. Por A.' },
  { code: 'BR', name: 'Eva Airways Corporation' },
  { code: 'BR (1)', name: 'Cal Air International Ltd.' },
  { code: 'BRK', name: 'Barken International' },
  { code: 'BRO', name: 'Brower Airways Inc.' },
  { code: 'BRW', name: 'Barrow Air' },
  { code: 'BTQ', name: 'Brasair Transportes Aereos' },
  { code: 'BUH', name: 'Business Helicopters' },
  { code: 'BUQ', name: 'Wrangler Aviation Inc.' },
  { code: 'BUQ (1)', name: 'Blue Bell Inc.' },
  { code: 'BUR', name: 'Burlington Airways Inc.' },
  { code: 'BW', name: 'Caribbean Airlines Limited' },
  { code: 'BXQ', name: 'Spantax S. A.' },
  { code: 'BY', name: 'Britannia Airways Ltd.' },
  { code: 'BYA', name: 'Bay Air' },
  { code: 'BZQ', name: 'Abaco Air, Ltd.' },
  { code: 'C5', name: 'Commutair Aka Champlain Enterprises, Inc.' },
  { code: 'C6', name: 'Canjet Airlines' },
  { code: 'C8', name: 'Cargolux Italia SpA' },
  { code: 'C8 (1)', name: 'Chicago Express Airlines' },
  { code: 'CA', name: 'Air China' },
  { code: 'CAC', name: 'Coleman Air Transport' },
  { code: 'CAF', name: 'California Seaboard' },
  { code: 'CAI', name: 'Crawford Aviation Inc.' },
  { code: 'CAM', name: 'C & M Airlines' },
  { code: 'CAP', name: 'Metro Air Northeast N Y' },
  { code: 'CAQ', name: 'Challenge Air Luftverkehrs GmbH' },
  { code: 'CAT', name: 'Catalina Airlines Inc.' },
  { code: 'CAV', name: 'Air Virginia' },
  { code: 'CAZ', name: 'CAT Aviation' },
  { code: 'CB', name: 'Air One Inc.' },
  { code: 'CBA', name: 'Carriba Air Inc.' },
  { code: 'CC', name: 'Air Atlanta Icelandic' },
  { code: 'CC (1)', name: 'Air Atlanta Inc.' },
  { code: 'CCA', name: 'Cityhop' },
  { code: 'CCO', name: 'Chester County Aviation' },
  { code: 'CDQ', name: 'Kitty Hawk International' },
  { code: 'CEN', name: 'Cen-Tex Airlines Inc.' },
  { code: 'CEQ', name: 'All Canada Express' },
  { code: 'CET', name: 'Century Airlines (Cal)' },
  { code: 'CF', name: 'Compan. De Aviacion Faucett' },
  { code: 'CGA', name: 'Chicago Airlines' },
  { code: 'CGL', name: 'Viking International Airlines Inc.' },
  { code: 'CGO', name: 'Chicago Air Taxi' },
  { code: 'CH', name: 'Bemidji Airlines' },
  { code: 'CHD', name: 'Chandler Flyer' },
  { code: 'CHE', name: 'Charter Airlines' },
  { code: 'CHF', name: 'Hammonds Commuter Air Serv.' },
  { code: 'CHI', name: 'Chitina Air Service' },
  { code: 'CHL', name: 'Channel Flying Inc.' },
  { code: 'CHO', name: 'Choi Aviation Inc.' },
  { code: 'CHS', name: 'Christman Air System' },
  { code: 'CHT', name: 'Charter One' },
  { code: 'CI', name: 'China Airlines Ltd.' },
  { code: 'CIQ', name: 'A/S Conair' },
  { code: 'CIS', name: 'Cal Sierra Airlines' },
  { code: 'CJ', name: 'Colgan Airways Inc.' },
  { code: 'CK', name: 'China Cargo Airline' },
  { code: 'CKI', name: 'Inlet Airlines' },
  { code: 'CL', name: 'Capitol International Inc.' },
  { code: 'CLB', name: 'Columbia Pacific Airlines' },
  { code: 'CLD', name: 'Hawaii Pacific Helicopters' },
  { code: 'CLF', name: 'California Air Shuttle' },
  { code: 'CLQ', name: 'Aero Transcolombiana' },
  { code: 'CM', name: 'Compania Panamena (Copa)' },
  { code: 'CMB', name: 'Combs Airways Inc.' },
  { code: 'CMD', name: 'Command Airways Inc.' },
  { code: 'CMQ', name: 'Servicio De Carga Aerea Sa' },
  { code: 'CMR', name: 'Commuter Airlines (Ca)' },
  { code: 'CNN', name: 'Centennial Airlines' },
  { code: 'CO', name: 'Continental Air Lines Inc.' },
  { code: 'COB', name: 'Cotton Belt Aviation Inc.' },
  { code: 'COC', name: 'Cochise Airlines Inc.' },
  { code: 'COK', name: 'Coker Aviation Inc.' },
  { code: 'COL', name: 'Presidential Express' },
  { code: 'COM', name: 'Commutair' },
  { code: 'COR', name: 'Coral Air Inc.' },
  { code: 'COS', name: 'Cosmopolitan Comm. Airline' },
  { code: 'COT', name: 'Southeastern Commuter' },
  { code: 'COU', name: 'Courtesy Air Service Inc.' },
  { code: 'CP', name: 'Compass Airlines' },
  { code: 'CP (1)', name: 'Canadian Airlines International Ltd.' },
  { code: 'CPA', name: 'Capitol Air Lines' },
  { code: 'CPC', name: 'Coastal Plains Commuter Inc.' },
  { code: 'CPQ', name: 'Capitol Air Express Inc.' },
  { code: 'CPT', name: 'Capitol Air' },
  { code: 'CQI', name: 'Conquest Airline Corp.' },
  { code: 'CRA', name: 'Commuter Airlines' },
  { code: 'CRB', name: 'Caribair' },
  { code: 'CRE', name: 'Caribbean Express Inc.' },
  { code: 'CRH', name: 'Cresent Medivac' },
  { code: 'CRN', name: 'Crown Aviaiton Inc.' },
  { code: 'CRO', name: 'Crown Airways Inc.' },
  { code: 'CRP', name: 'Corporate Air' },
  { code: 'CRT', name: 'Crested Butte Air Service' },
  { code: 'CRV', name: 'Acropolis Aviation Ltd.' },
  { code: 'CS', name: 'Continental Micronesia' },
  { code: 'CSA', name: 'Casino Airlines' },
  { code: 'CSK', name: 'Catskill Airways Inc.' },
  { code: 'CSL', name: 'Central States Air Lines' },
  { code: 'CSM', name: 'Chisum Flying Service' },
  { code: 'CSN', name: 'Casino Airlines (1)' },
  { code: 'CSQ', name: 'Celtic Tech Jet Ltd.' },
  { code: 'CTA', name: 'Century Airlines' },
  { code: 'CTH', name: 'Cothron Aviation' },
  { code: 'CTL', name: 'Coastal Air Transport' },
  { code: 'CTQ', name: 'Custom Air Transport' },
  { code: 'CTR', name: 'Chartair' },
  { code: 'CUQ', name: 'Caicos Caribbean Airways' },
  { code: 'CUS', name: 'Custom Air Service' },
  { code: 'CV', name: 'Cargolux Airlines International S.A' },
  { code: 'CVA', name: 'Catalina-Vegas Airlines' },
  { code: 'CVQ', name: 'Caraven S.A.' },
  { code: 'CW', name: 'Air Marshall Islands Inc.' },
  { code: 'CWQ', name: 'Canadian Aviation Express' },
  { code: 'CX', name: 'Cathay Pacific Airways Ltd.' },
  { code: 'CYQ', name: 'Corse Air International' },
  { code: 'CZ', name: 'China Southern Airlines' },
  { code: 'CZ (1)', name: 'Cascade Airways Inc.' },
  { code: 'CZQ', name: 'Aviation Concepts' },
  { code: 'DA', name: 'Dan Air Services' },
  { code: 'DAN', name: 'Danbury Airlines' },
  { code: 'DAU', name: 'Dauphin Island Airways' },
  { code: 'DC', name: 'Caribbean Air Cargo Co Ltd.' },
  { code: 'DE', name: 'Condor Flugdienst' },
  { code: 'DEA', name: 'Downeast Airlines Inc.' },
  { code: 'DEC', name: 'Decatur Aviation' },
  { code: 'DFS', name: 'Downeast Flying Service' },
  { code: 'DH', name: 'Independence Air' },
  { code: 'DH (1)', name: 'Discovery Airways Inc.' },
  { code: 'DHL', name: 'DHL Airlines Inc.' },
  { code: 'DHQ', name: 'DHL Aero Expresso' },
  { code: 'DIA', name: 'Drummond Island Air' },
  { code: 'DIC', name: 'Dickman Aviation Services' },
  { code: 'DIR', name: 'Northern Star' },
  { code: 'DL', name: 'Delta Air Lines Inc.' },
  { code: 'DM', name: 'Maersk Air I/S' },
  { code: 'DO', name: 'Compania Dominicana De Avia' },
  { code: 'DOL', name: 'Dolphin Airways Inc.' },
  { code: 'DOQ', name: 'Aerolineas Dominicanas S A' },
  { code: 'DPA', name: 'Desert Pacific Airways' },
  { code: 'DPI', name: 'Desert Pacific Airlines Inc.' },
  { code: 'DQQ', name: 'Caribwest Airways Ltd.' },
  { code: 'DSA', name: 'Desert Sun Airlines' },
  { code: 'DST', name: 'Desert Airlines' },
  { code: 'DUL', name: 'Dulles Express' },
  { code: 'DUZ', name: 'Summit Airlines Inc.' },
  { code: 'DVO', name: 'Devoe Airlines' },
  { code: 'DWN', name: 'Dawn Air' },
  { code: 'DXA', name: 'Dolphin Express' },
  { code: 'DY', name: 'Norwegian Air Shuttle ASA' },
  { code: 'E0', name: 'EOS Airlines, Inc.' },
  { code: 'E7', name: 'Estafeta Carga Aereas' },
  { code: 'E72', name: 'European Aviation Air Charter' },
  { code: 'E8', name: 'USAfrica Airways Inc.' },
  { code: 'E9', name: 'Boston-Maine Airways' },
  { code: 'EA', name: 'Eastern Air Lines Inc.' },
  { code: 'EAI', name: 'Executive Aviation Inc.' },
  { code: 'EAQ', name: 'Executive Airlines, SL' },
  { code: 'EAS', name: 'Eastern Caribbean Airways' },
  { code: 'EAT', name: 'Eastman Airways Inc.' },
  { code: 'EBQ', name: 'Execaire Aviation Ltd.' },
  { code: 'ECA', name: 'Eagle Airlines (1)' },
  { code: 'ECI', name: 'Eastern Carolina Aviation' },
  { code: 'ECL', name: 'Eclipse Airlines' },
  { code: 'ECQ', name: 'Trans American Charter' },
  { code: 'ECR', name: 'East Coast Airways' },
  { code: 'ED', name: 'Andes' },
  { code: 'EDE', name: 'Ede Aire' },
  { code: 'EET', name: 'Exec Express II' },
  { code: 'EEX', name: 'Exec Express' },
  { code: 'EEZ', name: 'Eagle Aviation Inc.' },
  { code: 'EFA', name: 'Executive Four Winds' },
  { code: 'EFQ', name: 'Trans Europ. Airways France' },
  { code: 'EGA', name: 'Eagle Airline' },
  { code: 'EGJ', name: 'Eagle Jet Charter' },
  { code: 'EGL', name: 'Eagle Commuter Airlines Inc.' },
  { code: 'EH', name: 'Saeta Airlines' },
  { code: 'EHA', name: 'East Hampton Aire Inc.' },
  { code: 'EI', name: 'Aer Lingus Plc' },
  { code: 'EIA', name: 'East. Indiana Aviation Sale' },
  { code: 'EJQ', name: 'Aeroejecutivos C.A.' },
  { code: 'EK', name: 'Emirates' },
  { code: 'EKA', name: 'Air Pacific Inc.' },
  { code: 'ELL', name: 'Ellis Air Taxi Inc.' },
  { code: 'EM', name: 'Empire Airlines Inc.' },
  { code: 'EMA', name: 'Emerald Airlines' },
  { code: 'EME', name: 'Metro Express' },
  { code: 'EMP', name: 'Empire Airlines Inc. (2)' },
  { code: 'ENT', name: 'Enterprise Airlines Inc.' },
  { code: 'EOQ', name: 'Aeroservicios Ecuatorianos' },
  { code: 'EP', name: 'All Star Airlines Inc.' },
  { code: 'EQ', name: 'Tame Linea Aerea del Ecuador Tme EP' },
  { code: 'ER', name: 'Astar USA, LLC' },
  { code: 'ERI', name: 'Erie Airways Inc.' },
  { code: 'ERO', name: 'Empire Aero Service' },
  { code: 'ERQ', name: 'Aero Trades (Western) Ltd.' },
  { code: 'ESQ', name: 'Seagreen Air Transport' },
  { code: 'ET', name: 'Ethiopian Airlines' },
  { code: 'EU', name: 'Ecuatoriana De Aviacion' },
  { code: 'EUQ', name: 'Air Atlanta Europe' },
  { code: 'EV', name: 'ExpressJet Airlines Inc.' },
  { code: 'EVA', name: 'Evanston Aviation' },
  { code: 'EWA', name: 'Rio Grande Air' },
  { code: 'EXA', name: 'Columbia Pacific Airlines (1)' },
  { code: 'EXC', name: 'Hapag-Lloyd Executive GmbH' },
  { code: 'EXC (1)', name: 'Executive Charter' },
  { code: 'EXP', name: 'Express Airlines II' },
  { code: 'EXQ', name: 'Export Air Del Peru S.A.' },
  { code: 'EXR', name: 'Executive Transport' },
  { code: 'EY', name: 'Etihad Airways' },
  { code: 'EZ', name: 'Evergreen International Inc.' },
  { code: 'F2', name: 'Omega Air Holdings d/b/a Focus Air' },
  { code: 'F8', name: 'Freedom Airlines d/b/a HP Expr' },
  { code: 'F9', name: 'Frontier Airlines Inc.' },
  { code: 'FAA', name: 'Falwell Aviation Inc.' },
  { code: 'FAC', name: 'Falcon Airways Inc.' },
  { code: 'FAQ', name: 'Fast Air Carrier Ltd. (1)' },
  { code: 'FAR', name: 'Federal Armored Service Inc.' },
  { code: 'FB', name: 'Translift Airways Limited' },
  { code: 'FBI', name: 'Young Flying Service' },
  { code: 'FCA', name: 'Southern Airlines Inc.' },
  { code: 'FCQ', name: 'Falcon Air Express' },
  { code: 'FD', name: 'Air Puerto Rico Airlines' },
  { code: 'FDA', name: 'Florida Airlines Inc.' },
  { code: 'FDM', name: 'Freedom Airlines Inc.' },
  { code: 'FDQ', name: 'Great American Airways' },
  { code: 'FE', name: 'Primaris Airlines Inc.' },
  { code: 'FF', name: 'Tower Air Inc.' },
  { code: 'FFQ', name: 'Five Star Airlines' },
  { code: 'FH', name: 'Frontier Horizon Inc.' },
  { code: 'FI', name: 'Icelandair' },
  { code: 'FJ', name: 'Air Pacific Ltd.' },
  { code: 'FJ (1)', name: 'Air Pacific Ltd. (1)' },
  { code: 'FL', name: 'AirTran Airways Corporation' },
  { code: 'FL (1)', name: 'Frontier Airlines Inc. (1)' },
  { code: 'FLA', name: 'Florida Air' },
  { code: 'FLM', name: 'Flamenco Airways Inc.' },
  { code: 'FLR', name: 'Flirite Inc.' },
  { code: 'FNQ', name: 'Fine Airlines Inc.' },
  { code: 'FOR', name: 'Ford-Aire Inc.' },
  { code: 'FOS', name: 'Foster Aviation' },
  { code: 'FP', name: 'TAG Aviation S.A.' },
  { code: 'FQ', name: 'Air Aruba' },
  { code: 'FRA', name: 'Freedom Air' },
  { code: 'FRS', name: 'Four Star Aviation' },
  { code: 'FRT', name: 'Frontier Commuter' },
  { code: 'FRW', name: 'Farwest Airlines' },
  { code: 'FS', name: 'Serv De Trans Aereos Fuegui' },
  { code: 'FSA', name: 'F.S. Air Service' },
  { code: 'FT', name: 'Flying Tiger Line Inc.' },
  { code: 'FTI', name: 'Southern Airways' },
  { code: 'FVA', name: 'Skycraft Inc.' },
  { code: 'FW', name: 'Skybus Inc.' },
  { code: 'FWA', name: 'Far West Airlines' },
  { code: 'FX', name: 'Federal Express Corporation' },
  { code: 'FYB', name: 'Flying Boat' },
  { code: 'G3', name: 'VRG Linhas Aereas S A' },
  { code: 'G3 (1)', name: 'Aerochago S.A.' },
  { code: 'G4', name: 'Allegiant Air' },
  { code: 'G7', name: 'GoJet Airlines, LLC d/b/a United Express' },
  { code: 'GA', name: 'P.T. Garuda Indonesian Arwy' },
  { code: 'GAL', name: 'Galena Air Service' },
  { code: 'GAQ', name: 'Galaxy Airlines Inc.' },
  { code: 'GAS', name: 'General Aviation Inc.' },
  { code: 'GAT', name: 'Gulf Air Taxi' },
  { code: 'GAV', name: 'Gromer Aviation Inc.' },
  { code: 'GBA', name: 'Central States Airline' },
  { code: 'GBQ', name: 'G5 Executive Ag' },
  { code: 'GCA', name: 'Golden Carriage Aire' },
  { code: 'GCH', name: 'Grand Canyon Helicopters' },
  { code: 'GCQ', name: 'General Air Cargo' },
  { code: 'GCS', name: 'Midway Commuter' },
  { code: 'GD', name: 'Transp. Aereos Ejecutivos' },
  { code: 'GDW', name: 'Golden West Airlines Inc.' },
  { code: 'GE', name: 'Lufthansa Cargo Airlines' },
  { code: 'GF', name: 'Gulf Air Company' },
  { code: 'GF (1)', name: 'Gulf Air Transport Inc.' },
  { code: 'GFC', name: 'Gulf Flite Center' },
  { code: 'GFQ', name: 'Gulf And Caribbean Cargo' },
  { code: 'GG', name: 'Cargo 360, Inc.' },
  { code: 'GG (1)', name: 'Tropical Airways Inc.' },
  { code: 'GG (2)', name: 'Grenada Airways Ltd.' },
  { code: 'GH', name: 'Ghana Airways Corporation' },
  { code: 'GHA', name: 'Green Hills Aviation Ltd.' },
  { code: 'GIQ', name: 'Global International Airways Corp.' },
  { code: 'GJ', name: 'Eurofly S.p.A' },
  { code: 'GJ (1)', name: 'Mexicargo' },
  { code: 'GKQ', name: 'Laker Airways' },
  { code: 'GL', name: 'Miami Air International' },
  { code: 'GLW', name: 'Golden West Airlines Co.' },
  { code: 'GM', name: 'Air America' },
  { code: 'GMA', name: 'Green Mountain Airlines Ltd.' },
  { code: 'GOF', name: 'Copper State Airlines' },
  { code: 'GPA', name: 'GP Express Airlines' },
  { code: 'GQ', name: 'Big Sky Airlines Inc.' },
  { code: 'GR', name: 'Gemini Air Cargo Airways' },
  { code: 'GRA', name: 'Gorda Aero Service' },
  { code: 'GRD', name: 'Grand Canyon Airlines' },
  { code: 'GRO', name: 'Isle Royale Seaplane Serv.' },
  { code: 'GS', name: 'Air Foyle Limited' },
  { code: 'GSA', name: 'Golden Gate Airlines Inc.' },
  { code: 'GSC', name: 'GCS Air Service' },
  { code: 'GSL', name: 'Golden South Airlines Inc.' },
  { code: 'GSQ', name: 'Global Supply System' },
  { code: 'GST', name: 'Golden State Airlines' },
  { code: 'GU', name: 'Aviateca' },
  { code: 'GUL', name: 'Gull Air Inc.' },
  { code: 'GUN', name: 'Gunnel Aviation Inc.' },
  { code: 'GV', name: 'Grant Aviation' },
  { code: 'GW', name: 'Central American Airlines' },
  { code: 'GWA', name: 'Great Western Aviation' },
  { code: 'GWE', name: 'Ross Aviation Inc.' },
  { code: 'GX', name: 'Air Ontario Limited' },
  { code: 'GY', name: 'Guyana Airways Corporation' },
  { code: 'H2', name: 'City Bird' },
  { code: 'H5', name: 'Magadan Airlines' },
  { code: 'H6', name: 'Hageland Aviation Service' },
  { code: 'HA', name: 'Hawaiian Airlines Inc.' },
  { code: 'HAQ', name: 'Hapag Lloyd Flug.' },
  { code: 'HAR', name: 'Friendship Air Alaska' },
  { code: 'HB', name: 'Homer Air' },
  { code: 'HB (1)', name: 'Air Club International' },
  { code: 'HBQ', name: 'Harris Air Services' },
  { code: 'HCQ', name: 'Av Atlantic' },
  { code: 'HEL', name: 'Helitrans' },
  { code: 'HEP', name: 'Hub Express' },
  { code: 'HER', name: 'Markair Express' },
  { code: 'HET', name: 'Helitac Aviation' },
  { code: 'HEU', name: 'Heussler Air Service Corp' },
  { code: 'HFQ', name: 'Haiti Air Freight Intl' },
  { code: 'HFS', name: 'Hensley Flying Service Inc.' },
  { code: 'HIQ', name: 'Business Flights Ltd.' },
  { code: 'HJ', name: 'Air Haiti S. A.' },
  { code: 'HK', name: 'Time Air Sweden Ab' },
  { code: 'HLQ', name: 'Heavylift Cargo Airlines Lt' },
  { code: 'HMZ', name: 'Long Island Airlines' },
  { code: 'HNS', name: 'Haines Airways' },
  { code: 'HOL', name: 'Holiday Airlines Inc.' },
  { code: 'HOQ', name: 'Holidair Airways' },
  { code: 'HOR', name: 'Iowa Airlines' },
  { code: 'HP', name: 'America West Airlines Inc. (Merged with US Airways 9/05. Stopped reporting 10/07.)' },
  { code: 'HPZ', name: 'Pacific Coast Airlines' },
  { code: 'HQ', name: 'Harmony Airways' },
  { code: 'HQ (1)', name: 'Business Express' },
  { code: 'HQQ', name: 'Harlequin Air Corporation' },
  { code: 'HRA', name: 'Trans-National Airlines Haw' },
  { code: 'HRG', name: 'Heritage Airlines' },
  { code: 'HRQ', name: 'Tar Airlines' },
  { code: 'HRZ', name: 'Allegheny Airlines' },
  { code: 'HSV', name: 'Harbor Air Service' },
  { code: 'HSZ', name: 'Scenic Airlines Inc.' },
  { code: 'HU', name: 'Hainan Airlines Company Limited' },
  { code: 'HUB', name: 'Skystream Airlines' },
  { code: 'HVQ', name: 'Transavia Holland B. V.' },
  { code: 'HWK', name: 'Hawking Corporation' },
  { code: 'HX', name: 'Guy-America Airways Inc.' },
  { code: 'HY', name: 'Uzbekistan Airways' },
  { code: 'HYA', name: 'Hyannis Aviation' },
  { code: 'IA', name: 'Iraqi Airways' },
  { code: 'IB', name: 'Iberia Air Lines Of Spain' },
  { code: 'ICF', name: 'Iowa City Flying Service' },
  { code: 'ICH', name: 'Island Air Charters' },
  { code: 'ICQ', name: 'Iscargo Hf' },
  { code: 'IDQ', name: 'Intl Air Service Co. Ltd.' },
  { code: 'IEX', name: 'Island Air Express' },
  { code: 'IG', name: 'Meridiana S.p.A' },
  { code: 'IGA', name: 'Global Jet Austria, GmbH' },
  { code: 'III', name: 'Idee Industries Inc.' },
  { code: 'IJ', name: 'Great Wall Airlines Company Limited' },
  { code: 'IKQ', name: 'Tradewinds Airways Ltd.' },
  { code: 'IMP', name: 'Imperial Airlines Inc.' },
  { code: 'IN', name: 'Aerlinte Eireann Teoranta' },
  { code: 'INA', name: 'Inland Empire Airlines Inc.' },
  { code: 'IND', name: 'Indiana Airways Inc. (Pa)' },
  { code: 'INQ', name: 'Independent Air Inc.' },
  { code: 'IOW', name: 'Iowa Airways' },
  { code: 'IPI', name: 'Indo-Pacific International' },
  { code: 'IPQ', name: 'Aruba Cargo' },
  { code: 'IPS', name: 'Island Express' },
  { code: 'IR', name: 'Iran National Airlines Corp' },
  { code: 'IRQ', name: 'Interstate Airlines Inc.' },
  { code: 'ISL', name: 'Island Airlines' },
  { code: 'ITQ', name: 'Interamericana De Aviacion' },
  { code: 'IW', name: 'Air Liberte Aka Aom Minerve' },
  { code: 'IW (1)', name: 'Best Airlines Inc.' },
  { code: 'IXQ', name: 'Inair' },
  { code: 'J5', name: 'Alaska Seaplane Service' },
  { code: 'J6', name: 'Larrys Flying Service' },
  { code: 'J7', name: 'Valujet Airlines Inc.' },
  { code: 'JAG', name: 'JetAlliance Flugbetriebs d/b/a JAF Airservice' },
  { code: 'JAM', name: 'Sunbelt Airlines Inc.' },
  { code: 'JAQ', name: 'Jamaica Air Freighters' },
  { code: 'JB', name: 'Helijet International Inc.' },
  { code: 'JCM', name: 'Air Chico' },
  { code: 'JCQ', name: 'Jet Connection Businessflight Ag' },
  { code: 'JCZ', name: 'Rocky Mountain Airways Inc.' },
  { code: 'JD', name: 'Japan Air System Co. Ltd.' },
  { code: 'JER', name: 'Jer-Don Air' },
  { code: 'JF', name: 'L.A.B. Flying Service Inc.' },
  { code: 'JI', name: 'Jade Cargo International' },
  { code: 'JI (1)', name: 'Midway Airlines Inc.' },
  { code: 'JIQ', name: 'Jet Fleet Intl. Airlines' },
  { code: 'JJ', name: 'Transportes Aeros Meridiona' },
  { code: 'JK', name: 'Spanair S.A.' },
  { code: 'JK (1)', name: 'Sunworld International Airways Inc.' },
  { code: 'JKQ', name: 'Express One International Inc.' },
  { code: 'JL', name: 'Japan Air Lines Co. Ltd.' },
  { code: 'JM', name: 'Air Jamaica Limited' },
  { code: 'JMA', name: 'Jim Air Inc.' },
  { code: 'JN', name: 'Excel Airways' },
  { code: 'JO', name: 'Jalways Co. Ltd.' },
  { code: 'JQ', name: 'Jetstar Airways Pty Limited' },
  { code: 'JR', name: 'Aero California' },
  { code: 'JTA', name: 'Jetaire Airlines Inc.' },
  { code: 'JU', name: 'Yugoslav Airlines' },
  { code: 'JW', name: 'Arrow Air Inc.' },
  { code: 'JX', name: 'Southeast Airlines' },
  { code: 'JX (1)', name: 'Jes Air Ltd.' },
  { code: 'JXQ', name: 'Allarco Developments Ltd.' },
  { code: 'JZ', name: 'Japan Air Charter Co. Ltd.' },
  { code: 'K3', name: 'Venture Travel LLC d/b/a Taquan Air Service' },
  { code: 'K5', name: 'SeaPort Airlines, Inc. d/b/a Wings of Alaska' },
  { code: 'K7', name: 'Arizona Express' },
  { code: 'K8', name: 'Caribbean Sun Airlines, Inc. d/b/a World Atlantic Airlines' },
  { code: 'K8 (1)', name: 'Dutch Caribbean Airlines' },
  { code: 'KA', name: 'Hong Kong Dragonair' },
  { code: 'KAH', name: 'Kenmore Air Harbor' },
  { code: 'KAI', name: 'Knight Airlines Inc.' },
  { code: 'KAQ', name: 'Kalitta Air LLC' },
  { code: 'KAT', name: 'Katmai Air' },
  { code: 'KB', name: 'Haiti Air' },
  { code: 'KE', name: 'Korean Air Lines Co. Ltd.' },
  { code: 'KEA', name: 'Keene Airways' },
  { code: 'KEE', name: 'Key Airlines' },
  { code: 'KET', name: 'Ketchikan Air Service' },
  { code: 'KFS', name: 'King Flying Service' },
  { code: 'KH', name: 'Aloha Air Cargo' },
  { code: 'KI', name: 'Time Air Ltd.' },
  { code: 'KIN', name: 'Golden Pacific Airlines' },
  { code: 'KKQ', name: 'Transmeridian Air Cargo Ltd.' },
  { code: 'KL', name: 'Klm Royal Dutch Airlines' },
  { code: 'KLQ', name: 'Kalitta Charters II' },
  { code: 'KM', name: 'Air Malta' },
  { code: 'KN', name: 'Morris Air Corporation' },
  { code: 'KO', name: 'Alaska Central Express' },
  { code: 'KOD', name: 'Kodiak Airways Inc.' },
  { code: 'KP', name: 'Kiwi International' },
  { code: 'KR', name: 'Kitty Hawk Aircargo' },
  { code: 'KR (1)', name: 'Kar-Air Oy' },
  { code: 'KS', name: 'Peninsula Airways Inc.' },
  { code: 'KTQ', name: 'Turks Air Ltd.' },
  { code: 'KU', name: 'Kuwait Airways Corp.' },
  { code: 'KW', name: 'Carnival Air Lines Inc.' },
  { code: 'KWQ', name: 'Kelowna Flightcraft Air Cha' },
  { code: 'KWZ', name: 'Crown Air' },
  { code: 'KX', name: 'Cayman Airways Limited' },
  { code: 'KXZ', name: 'Chaparral Airlines Inc.' },
  { code: 'KZ', name: 'Nippon Cargo Airlines' },
  { code: 'L2', name: 'Lynden Air Cargo Airlines' },
  { code: 'L3', name: 'Lynx Aviation d/b/a Frontier Airlines' },
  { code: 'L7', name: 'Lan Colombia' },
  { code: 'LA', name: 'Lan-Chile Airlines' },
  { code: 'LAA', name: 'La Skycab Airline' },
  { code: 'LAH', name: 'La Helicopter' },
  { code: 'LAN', name: 'Lancer Aviation Inc.' },
  { code: 'LAP', name: 'Lapsa' },
  { code: 'LAR', name: 'Laredo Air' },
  { code: 'LAS', name: 'Lake Union Air Service' },
  { code: 'LAW', name: 'Lawrence Aviation' },
  { code: 'LB', name: 'Lloyd Aereo Boliviano S. A.' },
  { code: 'LBQ', name: 'Millon Air Inc.' },
  { code: 'LC', name: 'Varig Logistica S/A' },
  { code: 'LC (1)', name: 'Lineas Aereas Del Caribe' },
  { code: 'LC (2)', name: 'Legend Airlines' },
  { code: 'LCQ', name: 'Latcr' },
  { code: 'LCT', name: 'Lake Coastal' },
  { code: 'LEQ', name: 'Le Point Air' },
  { code: 'LEX', name: "L'Express" },
  { code: 'LF', name: 'Jettrain Airlines' },
  { code: 'LG', name: 'Luxair' },
  { code: 'LGQ', name: 'Lineas Aereas Allegro' },
  { code: 'LH', name: 'Lufthansa German Airlines' },
  { code: 'LHA', name: 'Havasu Airlines' },
  { code: 'LIQ', name: 'Liat (1974) Limited' },
  { code: 'LJ', name: 'Jin Air Co Ltd.' },
  { code: 'LKN', name: 'Loken Aviation' },
  { code: 'LLA', name: 'Lakeland Aviation' },
  { code: 'LNQ', name: 'Aerolineas Latinas' },
  { code: 'LO', name: 'Polskie Linie Lotnicze' },
  { code: 'LOQ', name: 'Lorair Ltd.' },
  { code: 'LOS', name: 'Aero Commuter' },
  { code: 'LP', name: 'Lan Peru Airlines' },
  { code: 'LP (1)', name: 'The Hawaii Express Inc.' },
  { code: 'LR', name: 'Lacsa' },
  { code: 'LRQ', name: 'Air Liberte' },
  { code: 'LSQ', name: 'Lineas Aereas Suramerican' },
  { code: 'LSZ', name: 'Alaska Island Air Inc.' },
  { code: 'LT', name: 'Luftransport-Unternehmen' },
  { code: 'LU', name: 'Air Atlantic Dominicana' },
  { code: 'LUQ', name: 'Lineas Aereas La-Tur Sa' },
  { code: 'LVA', name: 'Las Vegas Airlines' },
  { code: 'LW', name: 'Pacific Wings Airlines' },
  { code: 'LX', name: 'Swiss International Airlines' },
  { code: 'LXQ', name: 'Translux Intl Airlines' },
  { code: 'LY', name: 'El Al Israel Airlines Ltd.' },
  { code: 'LZ', name: 'Balkan Bulgarian Airlines' },
  { code: 'LZQ', name: 'Leisure International Airways Ltd.' },
  { code: 'M3', name: 'ABSA-Aerolinhas Brasileiras' },
  { code: 'M6', name: 'Amerijet International' },
  { code: 'M7', name: 'Aerotransportes Mas De Crga' },
  { code: 'MA', name: 'Malev Hungarian Airlines' },
  { code: 'MAA', name: 'Maui Airlines' },
  { code: 'MAC', name: 'Mackey International Inc.' },
  { code: 'MAE', name: 'Mid-Atlantic Exp. Airlines' },
  { code: 'MAG', name: 'Magum Airlines' },
  { code: 'MAI', name: 'The Maui Commuter' },
  { code: 'MAQ', name: 'Millardair Limited' },
  { code: 'MAR', name: "Marshall's Air Inc." },
  { code: 'MAV', name: 'Maverick Airways' },
  { code: 'MAX', name: 'Maxair Inc.' },
  { code: 'MB', name: 'Samoa Airlines Inc.' },
  { code: 'MC', name: 'Transtar Airlines Corp.' },
  { code: 'MCO', name: 'Mid Continental Airlines In' },
  { code: 'MDC', name: 'Midcontinent Airlines' },
  { code: 'MDU', name: 'Metro Airlines' },
  { code: 'ME', name: 'Middle East Airlines S.A.L.' },
  { code: 'MEA', name: 'Manhattan Express' },
  { code: 'MET', name: 'Metroplex Airlines' },
  { code: 'MEX', name: 'Metro Express 11' },
  { code: 'MG', name: 'Champion Air' },
  { code: 'MGQ', name: 'Montana Austria Flugetrieb' },
  { code: 'MH', name: 'Malaysian Airline System' },
  { code: 'MHK', name: 'Mohawk Airlines' },
  { code: 'MHO', name: 'Mahalo Air' },
  { code: 'MIN', name: 'Minuteman Aviation Inc.' },
  { code: 'MIS', name: 'Midstate Airlines Inc.' },
  { code: 'MIY', name: 'Midway Aviation Inc.' },
  { code: 'MJ', name: 'Lapa-Lineas Aereas Privadas' },
  { code: 'MKQ', name: 'Airmark Corporation' },
  { code: 'ML', name: 'Aero Costa Rica' },
  { code: 'ML (1)', name: 'Midway Airlines Inc. (1)' },
  { code: 'MLL', name: 'Miller Air Transportersinc' },
  { code: 'MLX', name: 'Merlin Express' },
  { code: 'MM', name: 'Euro Atlantic Airways' },
  { code: 'MM (1)', name: 'Sociedad Aeronautica De Med' },
  { code: 'MMH', name: 'Ocean Airways Inc.' },
  { code: 'MNA', name: 'M & N Aviation' },
  { code: 'MNZ', name: 'Cumberland Airlines' },
  { code: 'MOQ', name: 'Morningstar Air Express' },
  { code: 'MP', name: 'Martinair Holland N.V.' },
  { code: 'MPA', name: 'Mid Pacific Airlines Inc.' },
  { code: 'MPC', name: 'Mid Pacific Air Corp.' },
  { code: 'MQ', name: 'Envoy Air' },
  { code: 'MRC', name: 'Marco Island Airways Inc.' },
  { code: 'MRQ', name: 'National Air Cargo Group, Inc. d/b/a Murray Air' },
  { code: 'MRR', name: 'Merrimack Aviation Inc.' },
  { code: 'MS', name: 'Egyptair' },
  { code: 'MST', name: 'Midwest Aviation' },
  { code: 'MT', name: 'Thomas Cook Airlines Uk Ltd.' },
  { code: 'MT (1)', name: 'Flying Colours Airlines Ltd.' },
  { code: 'MTA', name: 'Arkansas Traveler Airline' },
  { code: 'MTR', name: 'Metroflight Airlines' },
  { code: 'MTV', name: 'Mst Aviation' },
  { code: 'MTX', name: 'Mountain Air Express' },
  { code: 'MU', name: 'China Eastern Airlines' },
  { code: 'MU (1)', name: 'Mcclain Airlines Inc.' },
  { code: 'MUA', name: "Manu'a Air Transport" },
  { code: 'MUQ', name: 'Aerolineas Mundo' },
  { code: 'MVA', name: 'Mississippi Valley Airlines' },
  { code: 'MW', name: 'Mokulele Flight Services, Inc.' },
  { code: 'MWA', name: 'Mountain West Airlines' },
  { code: 'MX', name: 'Compania Mexicana De Aviaci' },
  { code: 'MY', name: 'MAXjet' },
  { code: 'MYQ', name: 'Lineas Aereas Mayas (Lamsa)' },
  { code: 'MZ', name: 'P.N. Merpati Nusantara Air.' },
  { code: 'N5', name: 'Skagway Air Service' },
  { code: 'N5 (1)', name: 'Nations Air Express Inc.' },
  { code: 'N6', name: 'Aero Continente' },
  { code: 'N7', name: 'National Airlines' },
  { code: 'NA', name: 'North American Airlines' },
  { code: 'NAC', name: 'National Air Commuter Inc.' },
  { code: 'NAP', name: 'Npa Inc.' },
  { code: 'NAQ', name: 'Nationair' },
  { code: 'NAS', name: 'Nasville Eagle' },
  { code: 'NAT', name: 'National Air' },
  { code: 'NB', name: 'Sterling Cargo' },
  { code: 'NBQ', name: 'Sterling Airways A/S' },
  { code: 'NC', name: 'Northern Air Cargo Inc.' },
  { code: 'NCA', name: 'National Commuter Airlines' },
  { code: 'NCE', name: 'Northcoast Executive' },
  { code: 'ND', name: 'Intair' },
  { code: 'NEB', name: 'New Born Wings Inc.' },
  { code: 'NEC', name: 'Nor East Commuter Airlines' },
  { code: 'NEQ', name: 'North East Cargo Airlines' },
  { code: 'NER', name: 'Northeast Express Regional (1)' },
  { code: 'NET', name: 'Nacnet' },
  { code: 'NEV', name: 'Nevada Airlines' },
  { code: 'NEW', name: 'New England Airlines Inc.' },
  { code: 'NEX', name: 'Northeast Express Regional' },
  { code: 'NFA', name: 'National Florida Airlines' },
  { code: 'NFQ', name: 'North Cariboo Flying Serv.' },
  { code: 'NG', name: 'Lauda Air Luftfahrt Ag' },
  { code: 'NGL', name: 'Northern Eagle Airways Inc.' },
  { code: 'NH', name: 'All Nippon Airways Co.' },
  { code: 'NHA', name: 'Newair Flight Inc.' },
  { code: 'NI', name: 'Lanica' },
  { code: 'NI (1)', name: 'American International Airways Inc.' },
  { code: 'NJ', name: 'Vanguard Airlines Inc.' },
  { code: 'NJA', name: 'N. J. Merrimack Airlines' },
  { code: 'NJE', name: 'NetJets Transportes Aereos, S.A.' },
  { code: 'NK', name: 'Spirit Air Lines' },
  { code: 'NKQ', name: 'Norcanair' },
  { code: 'NLQ', name: 'Nolinor Aviation' },
  { code: 'NN', name: 'Northward Airlines Ltd.' },
  { code: 'NOQ', name: 'Novair Airlines Ab' },
  { code: 'NPA', name: 'North Pacific Airlines Inc.' },
  { code: 'NPT', name: 'E. J. A. Newport Inc.' },
  { code: 'NQ', name: 'Air Japan Co' },
  { code: 'NRN', name: 'Northern Airlines Inc.' },
  { code: 'NSQ', name: 'North Coast Air Services' },
  { code: 'NTH', name: 'Northaire' },
  { code: 'NTQ', name: 'National Aviation Consult.' },
  { code: 'NUM', name: 'Lake State Airways' },
  { code: 'NUQ', name: 'Air Niugini' },
  { code: 'NVQ', name: 'Novair International Airways Ltd.' },
  { code: 'NVX', name: 'Nevis Express' },
  { code: 'NW', name: 'Northwest Airlines Inc.' },
  { code: 'NWQ', name: 'N. W. Territorial Airways' },
  { code: 'NWS', name: 'Northwest Seaplanes' },
  { code: 'NXQ', name: 'North Vancouver Air' },
  { code: 'NY', name: 'New York Air Inc.' },
  { code: 'NYH', name: 'New York Helicopter Corp.' },
  { code: 'NZ', name: 'Air New Zealand' },
  { code: 'OA', name: 'Olympic Airways' },
  { code: 'OAK', name: 'Harbor Airlines Inc.' },
  { code: 'OB', name: 'Oasis International Airlines S.A.' },
  { code: 'OC', name: 'Air California Inc.' },
  { code: 'OCR', name: 'Ocean Reef Airways Inc.' },
  { code: 'OD', name: 'Emerald Airlines Inc.' },
  { code: 'OD (1)', name: 'Aerocondor' },
  { code: 'OE', name: 'Westair Airlines Inc.' },
  { code: 'OF', name: 'Air Finland Ltd.' },
  { code: 'OFF', name: 'Offshore Logistics' },
  { code: 'OH', name: 'Comair Inc.' },
  { code: 'OHA', name: 'Omaha Aviation' },
  { code: 'OHZ', name: 'Caribbean Air Services Inc.' },
  { code: 'OI', name: 'Prestige Airways' },
  { code: 'OIZ', name: 'Corporate Air Inc.' },
  { code: 'OJ', name: 'Fly Jamaica Airways Limited' },
  { code: 'OK', name: 'Czech Airlines' },
  { code: 'OMH', name: 'Omniflight Helicopters' },
  { code: 'OMI', name: 'Omniflight Airways' },
  { code: 'OMK', name: 'Omak Aviation Airlines' },
  { code: 'OMN', name: 'Omni Pacific Airline' },
  { code: 'OMQ', name: 'Quassar De Mexico' },
  { code: 'ON', name: 'Air Nauru' },
  { code: 'ONQ', name: 'Nunasi-Central Airlines Ltd.' },
  { code: 'OO', name: 'SkyWest Airlines Inc.' },
  { code: 'OOZ', name: 'Sun Aire Lines' },
  { code: 'OP', name: 'Flying Boat Inc.' },
  { code: 'OP (1)', name: 'Air Panama Internacional' },
  { code: 'OPQ', name: 'Orion Air' },
  { code: 'OR', name: 'TUI Airlines Nederland B.V.' },
  { code: 'ORI', name: 'Orion Air Inc.' },
  { code: 'OS', name: 'Austrian Airlines' },
  { code: 'OTT', name: 'Otter Air' },
  { code: 'OV', name: 'Aeromaritime' },
  { code: 'OW', name: 'Executive Airlines' },
  { code: 'OWQ', name: 'Ontario Worldair Limited' },
  { code: 'OWS', name: 'Air Kentucky Air Lines' },
  { code: 'OZ', name: 'Asiana Airlines Inc.' },
  { code: 'OZ (1)', name: 'Ozark Air Lines Inc. (1)' },
  { code: 'OZA', name: 'Ozark Air Lines Inc.' },
  { code: 'P9', name: 'Pro Air Inc.' },
  { code: 'PA', name: 'Florida Coastal Airlines' },
  { code: 'PA (1)', name: 'Pan American World Airways (1)' },
  { code: 'PA (2)', name: 'Pan American World Airways' },
  { code: 'PAI', name: 'Permian Airways Inc.' },
  { code: 'PAQ', name: 'Patriot Airlines Inc.' },
  { code: 'PAT', name: 'Panorama Air Tours' },
  { code: 'PBA', name: 'Provincetown Boston' },
  { code: 'PBQ', name: 'Privatair' },
  { code: 'PCA', name: 'Piasa Commuter Airlines' },
  { code: 'PCL', name: 'Pacific Cal Air' },
  { code: 'PCQ', name: 'Pace Airlines' },
  { code: 'PCR', name: 'Pacair' },
  { code: 'PD', name: 'Porter Airlines, Inc.' },
  { code: 'PD (1)', name: 'Pem-Air Limited' },
  { code: 'PDA', name: 'Trans Southern Airways' },
  { code: 'PDQ', name: 'Antigua Paradise Airways' },
  { code: 'PE', name: 'Air Europe S.P.A.' },
  { code: 'PE (1)', name: 'People Express Airlines Inc.' },
  { code: 'PEA', name: 'Pearson Aircraft' },
  { code: 'PEQ', name: 'SkyLink Express Inc.' },
  { code: 'PER', name: 'Air Pennsylvania' },
  { code: 'PET', name: 'Petro Aviation Corp.' },
  { code: 'PFA', name: 'Pacificair' },
  { code: 'PFQ', name: 'Asia Pacific' },
  { code: 'PH', name: 'Polynesian Airlines Ltd.' },
  { code: 'PHL', name: 'Phillips Airlines' },
  { code: 'PHR', name: 'Pinehurst Airlines Inc.' },
  { code: 'PHX', name: 'Phoenix Airlines' },
  { code: 'PI', name: 'Piedmont Aviation Inc.' },
  { code: 'PIA', name: 'Pacific Island Airways' },
  { code: 'PIQ', name: 'Pacific International Airlines' },
  { code: 'PK', name: 'Pakistan International Airlines' },
  { code: 'PKQ', name: 'Skyworld Airlines Inc.' },
  { code: 'PL', name: 'Aero Peru' },
  { code: 'PLA', name: 'Planes Inc.' },
  { code: 'PLQ', name: 'Planet Airways' },
  { code: 'PLZ', name: 'Polar Airways Inc.' },
  { code: 'PM', name: 'Prescott Support Company, Inc.' },
  { code: 'PMA', name: 'Pacific Missionary Aviation' },
  { code: 'PMT', name: 'Pilgrim Aviation & Airlines' },
  { code: 'PN', name: 'Pan American Airways Corp.' },
  { code: 'PNM', name: 'Panama Aviation' },
  { code: 'PNQ', name: 'Panagra Airways' },
  { code: 'PNR', name: 'Pioneer Airways' },
  { code: 'PNS', name: 'Pine State Airlines' },
  { code: 'PO', name: 'Polar Air Cargo Airways' },
  { code: 'POA', name: 'Potomac Air' },
  { code: 'POC', name: 'Pocono Airlines Inc.' },
  { code: 'POL', name: 'Polar International' },
  { code: 'POM', name: 'Pompano Airways' },
  { code: 'PON', name: 'Ponderosa Aviation Inc.' },
  { code: 'POQ', name: 'Polyot Airlines' },
  { code: 'PP', name: 'Jet Aviation Business Jets AG' },
  { code: 'PPQ', name: 'Propair' },
  { code: 'PR', name: 'Philippine Airlines Inc.' },
  { code: 'PRA', name: 'Princeton Aviation Corp' },
  { code: 'PRD', name: 'Paradise Island Airlines' },
  { code: 'PRM', name: 'Prime Air Inc.' },
  { code: 'PRN', name: 'Puerto Rico Intl Airlines' },
  { code: 'PRO', name: 'Sundance Airlines' },
  { code: 'PRP', name: 'Propheter Aviation' },
  { code: 'PRQ', name: 'Florida West Airlines Inc.' },
  { code: 'PRR', name: 'Pro Air Services' },
  { code: 'PS', name: 'Ukraine International Airlines' },
  { code: 'PS (1)', name: 'Pacific Southwest Airlines' },
  { code: 'PSA', name: 'P.S. Air Freight Inc.' },
  { code: 'PT', name: 'Capital Cargo International' },
  { code: 'PTQ', name: 'Flight International' },
  { code: 'PU', name: 'Primeras Lineas Uruguays For International' },
  { code: 'PUQ', name: 'Aeropuma' },
  { code: 'PV', name: 'Eastern Provincial Airways' },
  { code: 'PVA', name: 'Precision Valley Aviation' },
  { code: 'PW', name: 'Pacific Western Airlines' },
  { code: 'PY', name: 'Surinam Airways Limited' },
  { code: 'PZ', name: 'Lineas Aereas Paraguayas' },
  { code: 'Q2', name: 'Zambia Airways Corp.' },
  { code: 'Q4', name: 'Master Top Linhas Aereas, Ltda.' },
  { code: 'Q5', name: '40-Mile Air' },
  { code: 'Q7', name: 'Sobelair' },
  { code: 'QB', name: 'Inter-Quebec (Intair)' },
  { code: 'QD', name: 'Grand Airways Inc.' },
  { code: 'QF', name: 'Qantas Airways Ltd.' },
  { code: 'QG', name: 'Sky West Aviation Inc.' },
  { code: 'QH', name: 'Air Florida Inc.' },
  { code: 'QK', name: 'Jazz Aviation LP' },
  { code: 'QN', name: 'Royal Air' },
  { code: 'QO', name: 'Aeromexpress' },
  { code: 'QOZ', name: 'Bar Harbor Airways' },
  { code: 'QQ', name: 'Reno Air Inc.' },
  { code: 'QR', name: 'Qatar Airways (Q.C.S.C)' },
  { code: 'QS', name: 'Travel Service, a.s.' },
  { code: 'QS (1)', name: 'Northeastern International Airways' },
  { code: 'QT', name: 'Transportes Aereos Mercantiles Panamericanos S.A' },
  { code: 'QTQ', name: 'Aer Turas Teoranta' },
  { code: 'QWE', name: 'Qwest Commuter' },
  { code: 'QX', name: 'Horizon Air' },
  { code: 'R3', name: 'Air Company Yakutia' },
  { code: 'RAL', name: 'Royal-Air Ltd.' },
  { code: 'RAN', name: 'Pan Am Express' },
  { code: 'RAQ', name: 'Arista International Airlines Inc.' },
  { code: 'RAY', name: 'Royale Airlines Inc.' },
  { code: 'RB', name: 'Syrian Arab Airlines' },
  { code: 'RC', name: 'Republic Airlines Inc.' },
  { code: 'RCA', name: 'Mid-South Aviation Inc. (1)' },
  { code: 'RD', name: 'Ryan International Airlines' },
  { code: 'RD (1)', name: 'Airlift International Inc.' },
  { code: 'RDR', name: 'Rader Aviation' },
  { code: 'RDW', name: 'Redwing Airways' },
  { code: 'REA', name: 'Realwest Airlines' },
  { code: 'RED', name: 'Aerosun International Airlines Inc.' },
  { code: 'REQ', name: 'Renown Aviation' },
  { code: 'RES', name: 'Resort Airlines Inc.' },
  { code: 'RG', name: 'Varig S. A.' },
  { code: 'RGQ', name: 'Regency Air' },
  { code: 'RHA', name: 'Royal Hawaiian Airways Inc.' },
  { code: 'RIA', name: 'Trump Air' },
  { code: 'RIC', name: 'Richardson Aviation' },
  { code: 'RID', name: 'Indiana Airways Inc. (Reid' },
  { code: 'RIO', name: 'Rio Airways Inc.' },
  { code: 'RIQ', name: 'Rich International Airways' },
  { code: 'RIV', name: 'Riverside Air Service' },
  { code: 'RJ', name: 'Alia-(The) Royal Jordanian' },
  { code: 'RJS', name: 'Michigan Airways Inc.' },
  { code: 'RK', name: 'Air Afrique' },
  { code: 'RL', name: 'UltrAir' },
  { code: 'RL (1)', name: 'Aerolineas Nicaraguenses' },
  { code: 'RLQ', name: 'Reliant Airlines' },
  { code: 'RMH', name: 'Rocky Mountain Helicopters' },
  { code: 'RMV', name: 'Ram Aviation' },
  { code: 'RNQ', name: 'Mytravel Airways' },
  { code: 'RO', name: 'Tarom Romanian Air Transpor' },
  { code: 'ROA', name: 'Royal West Airways Inc.' },
  { code: 'ROE', name: 'Roederer Aviation Inc.' },
  { code: 'ROQ', name: 'Aero Uruguay (1)' },
  { code: 'ROS', name: 'Great Western Airlines Inc.' },
  { code: 'ROY', name: 'Royal American Airways Inc.' },
  { code: 'RP', name: 'Chautauqua Airlines Inc.' },
  { code: 'RRT', name: 'Air Freight Express' },
  { code: 'RS', name: 'Sky Regional Airlines Inc.' },
  { code: 'RSC', name: 'Resort Commuter' },
  { code: 'RSQ', name: 'Aerotransportes Entre Rios' },
  { code: 'RSV', name: 'Air-Serve' },
  { code: 'RTQ', name: 'Aerotour Dominicano Cxa' },
  { code: 'RU', name: 'AirBridgeCargo Airlines Limited' },
  { code: 'RV', name: 'Air Canada rouge LP' },
  { code: 'RV (1)', name: 'Reeve Aleutian Airways Inc.' },
  { code: 'RVA', name: 'Reeves Air' },
  { code: 'RVQ', name: 'Reeve Air Alaska, LLC' },
  { code: 'RWG', name: 'Air Rovers Inc.' },
  { code: 'RY', name: 'Redcoat Air Cargo Limited' },
  { code: 'RZZ', name: 'Aeromech Inc.' },
  { code: 'S3', name: 'Santa Barbara Airlines, C.A' },
  { code: 'S4', name: 'Sata Internacional' },
  { code: 'S5', name: 'Shuttle America Corp.' },
  { code: 'S6', name: 'Star Air A/S' },
  { code: 'S6 (1)', name: 'Mountain Bird/Salmon Air' },
  { code: 'SA', name: 'South African Airways' },
  { code: 'SAC', name: 'Sundorph Aeronautical Corp.' },
  { code: 'SAH', name: 'Sea Air Shuttle' },
  { code: 'SAI', name: 'Seair Alaska Airlines Inc.' },
  { code: 'SAJ', name: 'Sajen Air Inc.' },
  { code: 'SAL', name: "Sallee's Aviation" },
  { code: 'SAM', name: 'Samoan Airlines Inc.' },
  { code: 'SAQ', name: 'Southern Air Transport Inc.' },
  { code: 'SAS', name: 'Air Cortez International' },
  { code: 'SAX', name: 'Air Americana' },
  { code: 'SBA', name: 'Saber Aviation Inc.' },
  { code: 'SBN', name: 'Suburban Airlines' },
  { code: 'SBQ', name: 'Skyjet S.A.' },
  { code: 'SCN', name: 'Scott Aviation' },
  { code: 'SCO', name: 'Seaco Airlines Inc.' },
  { code: 'SCT', name: 'Skyline Airlines' },
  { code: 'SCY', name: 'South Coast Airways' },
  { code: 'SDQ', name: 'St. Lucia Airways Limited' },
  { code: 'SE', name: 'XL Airways France' },
  { code: 'SE (1)', name: 'Das Air Cargo' },
  { code: 'SEB', name: 'Seaborne Aviation' },
  { code: 'SEG', name: 'Seagull Air Service' },
  { code: 'SEM', name: 'Semo Aviation Inc.' },
  { code: 'SEP', name: 'Southern Express Airways' },
  { code: 'SEQ', name: 'Sky Service F.B.O.' },
  { code: 'SER', name: 'Service Aviation Inc.' },
  { code: 'SFI', name: 'Stahmannfarms Inc.' },
  { code: 'SFO', name: 'Sfo Helicopter Airlines Inc.' },
  { code: 'SFQ', name: 'Pacific International Airlines Inc.' },
  { code: 'SFQ (1)', name: 'Southern Frontier Air Trans' },
  { code: 'SFS', name: 'Sierra Flite Service Inc.' },
  { code: 'SG', name: 'Jetsgo' },
  { code: 'SH', name: 'Servicio Aereo De Honduras' },
  { code: 'SHS', name: 'Shasta Air Inc.' },
  { code: 'SHV', name: 'Shavano Air Inc.' },
  { code: 'SI', name: 'Sierra Pacific Airlines' },
  { code: 'SI (1)', name: 'Jet America Airlines Inc.' },
  { code: 'SIA', name: 'Silver State Airlines' },
  { code: 'SIQ', name: 'Premiair' },
  { code: 'SIQ (1)', name: 'Scanair' },
  { code: 'SJA', name: 'San Juan Airlines Inc.' },
  { code: 'SJQ', name: 'Skycharter (Malton) Ltd.' },
  { code: 'SK', name: 'Scandinavian Airlines Sys.' },
  { code: 'SKI', name: 'Sunbird Inc.' },
  { code: 'SKJ', name: 'Sky-Jet' },
  { code: 'SKL', name: 'Bas Airlines' },
  { code: 'SKM', name: 'Scheduled Skyways' },
  { code: 'SKS', name: 'Silver Kris Services Ltd.' },
  { code: 'SKT', name: 'Skytrain' },
  { code: 'SLO', name: 'Slocum Air Inc.' },
  { code: 'SLQ', name: 'Sky King Inc.' },
  { code: 'SLR', name: 'Salair' },
  { code: 'SLZ', name: 'Southeast Airlines Inc.' },
  { code: 'SM', name: 'Sunworld International Airlines' },
  { code: 'SMC', name: 'Stopher Mechanical Inc.' },
  { code: 'SMO', name: 'Samoa Aviation Inc.' },
  { code: 'SMQ', name: 'Serv. Aerolineas Mexicanas' },
  { code: 'SN', name: 'Brussels Airlines N.V.' },
  { code: 'SN (1)', name: 'Sabena Belgian World Air.' },
  { code: 'SNA', name: 'Aviation Associates' },
  { code: 'SNB', name: 'Ccair' },
  { code: 'SNC', name: 'Gelco Courier Services Inc.' },
  { code: 'SNE', name: 'Shawnee Airlines' },
  { code: 'SNK', name: 'Spernak Airways Inc.' },
  { code: 'SNV', name: 'Sierra Nevada Airways' },
  { code: 'SO', name: 'Swiss World Airways' },
  { code: 'SOA', name: 'Mall Airways Inc.' },
  { code: 'SOE', name: 'Sky One Express' },
  { code: 'SOL', name: 'Soonair Lines Inc.' },
  { code: 'SOP', name: 'South Pacific Island Airway' },
  { code: 'SPF', name: 'Springfield Air Service' },
  { code: 'SPG', name: 'Springdale Air' },
  { code: 'SPH', name: 'Spirit Helicopter' },
  { code: 'SPQ', name: 'Sun Pacific International' },
  { code: 'SPR', name: 'Sportsman Flying Service' },
  { code: 'SQ', name: 'Singapore Airlines Ltd.' },
  { code: 'SR', name: 'Swissair Transport Co. Ltd.' },
  { code: 'SRA', name: 'Star Aviation' },
  { code: 'SRL', name: 'Sterling Air Service' },
  { code: 'SS', name: 'Corsair International' },
  { code: 'SSA', name: 'S. S. Airways Inc.' },
  { code: 'SSP', name: 'Southern Seaplane' },
  { code: 'SSQ', name: 'Superior Airways Limited' },
  { code: 'SSS', name: 'Sierra Express Inc.' },
  { code: 'SST', name: 'Seaplane Shuttle Transport' },
  { code: 'ST', name: 'Belize Airways Limited' },
  { code: 'STA', name: 'State Airlines Inc.' },
  { code: 'STF', name: 'Trans East International' },
  { code: 'STG', name: 'Sedalia Marshall Boonvl Stg' },
  { code: 'STQ', name: 'Skycraft Air Transport Inc.' },
  { code: 'STR', name: 'Star Aviation Corporation' },
  { code: 'STW', name: 'Stateswest Airlines' },
  { code: 'STZ', name: 'Air America Inc.' },
  { code: 'SU', name: 'Aeroflot Russian Airlines' },
  { code: 'SUA', name: 'Sunshine Airlines' },
  { code: 'SUI', name: 'Sun International Airways' },
  { code: 'SUT', name: 'Sunbelt' },
  { code: 'SUX', name: 'Sunair Express LLC' },
  { code: 'SV', name: 'Saudi Arabian Airlines Corp' },
  { code: 'SVL', name: 'Sun Valley Airlines' },
  { code: 'SVW', name: 'Global Jet Luxembourg, Sa' },
  { code: 'SWA', name: 'Sunwest Airlines' },
  { code: 'SWT', name: 'Swift Aire Lines Inc.' },
  { code: 'SX', name: 'Skybus Airlines, Inc.' },
  { code: 'SX (1)', name: 'Aeroejecutivo S.A.' },
  { code: 'SXP', name: 'Sierra Expressway' },
  { code: 'SY', name: 'Sun Country Airlines d/b/a MN Airlines' },
  { code: 'SYB', name: 'Skybus Express Airlines' },
  { code: 'SYM', name: 'Skymaster' },
  { code: 'SZQ', name: 'Aerolineas El Salvador S A' },
  { code: 'T3', name: 'Tristar Airlines Inc.' },
  { code: 'T9', name: 'TransMeridian Airlines' },
  { code: 'TA', name: 'Taca International Airlines' },
  { code: 'TAC', name: 'Air Colorado' },
  { code: 'TAF', name: 'Tri Air Freight' },
  { code: 'TAL', name: 'Tejas Airlines Inc.' },
  { code: 'TAQ', name: 'Taquan Air Service' },
  { code: 'TAS', name: 'Yosemite Airlines Inc.' },
  { code: 'TAT', name: 'Talarik Creek Air Taxi' },
  { code: 'TB', name: 'TUI Airlines Belgium N.V. d/b/a Jetairfly' },
  { code: 'TB (1)', name: 'USAir Shuttle' },
  { code: 'TBQ', name: 'Triair Bermuda Flt Operations' },
  { code: 'TCA', name: 'Trans Catalina Airlines' },
  { code: 'TCE', name: 'Oceanair Line' },
  { code: 'TCI', name: 'Trans California Airlines' },
  { code: 'TCL', name: 'Trans-Central Airlines Inc.' },
  { code: 'TCO', name: 'Trans-Colorado Airlines' },
  { code: 'TCQ', name: 'Express.Net Airlines' },
  { code: 'TDQ', name: 'Venexcargo' },
  { code: 'TDQ (1)', name: 'Transcarga' },
  { code: 'TEN', name: 'Tennessee Airways Inc.' },
  { code: 'TER', name: 'Terre Haute Air Commuter' },
  { code: 'TFQ', name: 'Trans International Airlines Inc.' },
  { code: 'TFZ', name: 'Tri-State Airlines Inc.' },
  { code: 'TG', name: 'Thai Airways International Ltd.' },
  { code: 'TGO', name: 'Trans Air Cargo Inc.' },
  { code: 'TGQ', name: 'Tranps Aereos Guatemaltecos' },
  { code: 'TH', name: 'Transmile Air Service' },
  { code: 'THA', name: 'Tropical Helicopter Airways' },
  { code: 'THO', name: 'Thorson Aviation' },
  { code: 'THQ', name: 'T-Bird Air Inc.' },
  { code: 'TIA', name: 'Trans Air Inc.' },
  { code: 'TIE', name: 'Trans International Express' },
  { code: 'TIQ', name: 'Tyrolean Jet Service' },
  { code: 'TIS', name: 'Skyfreight' },
  { code: 'TJ', name: 'Jet Charter Service Inc.' },
  { code: 'TK', name: 'Turk Hava Yollari A.O.' },
  { code: 'TKQ', name: 'Trans-Air-Link Corporation' },
  { code: 'TLQ', name: 'Trans-Mediterranean Airways' },
  { code: 'TMA', name: 'Trans Mo Airlines' },
  { code: 'TN', name: 'Air Tahiti Nui' },
  { code: 'TNQ', name: 'Emery Worldwide Airlines' },
  { code: 'TNS', name: 'Transtate Aviation' },
  { code: 'TP', name: 'Tap-Portuguese Airlines' },
  { code: 'TPQ', name: 'Aerial Transit Company' },
  { code: 'TQQ', name: 'Taca Peru' },
  { code: 'TR', name: 'Transbrasil S.A.' },
  { code: 'TRA', name: 'Aeromexico Travel' },
  { code: 'TRI', name: 'Great Plains Airlines Inc.' },
  { code: 'TRO', name: 'Air Molakai' },
  { code: 'TRP', name: 'Trans Pennsylvania Airlines' },
  { code: 'TRQ', name: 'Blue Scandinavia Ab' },
  { code: 'TRR', name: 'Territorial Airlines' },
  { code: 'TS', name: 'Air Transat' },
  { code: 'TSF', name: 'American Central Airlines' },
  { code: 'TSI', name: 'Trans Sierra Airlines Inc.' },
  { code: 'TSQ', name: 'TPI International Airways' },
  { code: 'TTM', name: 'The Time Machine' },
  { code: 'TUL', name: 'Tulsair Beechcraft Inc.' },
  { code: 'TUR', name: 'Turner Aire' },
  { code: 'TV', name: 'Haiti Trans Air' },
  { code: 'TV (1)', name: 'Transamerica Airlines Inc.' },
  { code: 'TVL', name: 'Travelair' },
  { code: 'TW', name: 'Trans World Airways LLC' },
  { code: 'TWA', name: 'Trans Western Airlines Utah' },
  { code: 'TWE', name: 'Trans World Express' },
  { code: 'TX', name: 'Soc.Nouvelle Air Guadeloupe' },
  { code: 'TX (1)', name: 'Transportes Aereos Nacional' },
  { code: 'TXA', name: 'Texas National Airlines' },
  { code: 'TXE', name: 'Trans Air (Hawaii)' },
  { code: 'TXQ', name: 'British Caribbean Airways' },
  { code: 'TXY', name: 'Texas Airways' },
  { code: 'TYE', name: 'Temsco Airlines' },
  { code: 'TZ', name: 'ATA Airlines d/b/a ATA' },
  { code: 'TZQ', name: 'First Choice Airways' },
  { code: 'U2', name: 'UFS Inc.' },
  { code: 'U5', name: 'USA 3000 Airlines' },
  { code: 'U7', name: 'USA Jet Airlines Inc.' },
  { code: 'UA', name: 'United Air Lines Inc.' },
  { code: 'UAI', name: 'Universal Airways Inc.' },
  { code: 'UC', name: 'Ladeco' },
  { code: 'UD', name: 'Fast Air Carrier Ltd.' },
  { code: 'UH', name: 'US Helicopter Corporation' },
  { code: 'UMQ', name: 'Air Manila Inc.' },
  { code: 'UN', name: 'Transaero Airlines' },
  { code: 'UNQ', name: 'Overseas National Airways' },
  { code: 'UP', name: 'Bahamasair Holding Limited' },
  { code: 'UR', name: 'Empire Airlines Inc. (1)' },
  { code: 'URQ', name: 'Aero Uruguay' },
  { code: 'US', name: 'US Airways Inc.' },
  { code: 'USA', name: 'Air U.S.' },
  { code: 'LATAM', name: 'LATAM' },
  { code: 'Avianca', name: 'Avianca' },
  { code: 'UT', name: 'Union De Transports Aeriens' },
  { code: 'UTQ', name: 'Universal Airlines Inc.' },
  { code: 'UX', name: 'Air Europa' },
  { code: 'UX (1)', name: 'Air Illinois Inc.' },
  { code: 'UYQ', name: 'Aerolineas Uruguayas S.A.' },
  { code: 'V4', name: 'Vensecar International' },
  { code: 'V5', name: 'J.D.Valenciana De Aviation' },
  { code: 'V8', name: 'Iliamna Air Taxi' },
  { code: 'V9', name: 'Eagle Airlines' },
  { code: 'VA', name: 'Virgin Blue International Airlines t/a V Australia' },
  { code: 'VA (1)', name: 'Venezuelan International Airways' },
  { code: 'VAG', name: 'Vagabond Aviation Inc.' },
  { code: 'VAQ', name: 'Vacationair' },
  { code: 'VAS', name: 'Valley Air Services' },
  { code: 'VB', name: 'Aeroenlaces Nacionales, S.A. de C.V. d/b/a VivaAerobus' },
  { code: 'VB (1)', name: 'Pacific Express' },
  { code: 'VC', name: 'Servicios Avensa' },
  { code: 'VCQ', name: 'Victory Air Transport Inc.' },
  { code: 'VE', name: 'Aerovias Venezolanas-Avensa' },
  { code: 'VER', name: 'Britt Airlines Inc.' },
  { code: 'VEZ', name: 'Valdez Airlines' },
  { code: 'VH', name: 'Aeropostal Alas De Venezuel' },
  { code: 'VI', name: 'Vieques Air Link Inc.' },
  { code: 'VIG', name: 'Viking Express' },
  { code: 'VIN', name: 'Vale International Airlines' },
  { code: 'VIQ', name: 'Volga-Dnepr Airlines' },
  { code: 'VIQ (1)', name: 'Viscount Air Service Inc.' },
  { code: 'VIS', name: 'Virgin Islands Seaplane' },
  { code: 'VJT', name: 'VistaJet Limited' },
  { code: 'VK', name: 'Air Tungaru Corporation' },
  { code: 'VL', name: 'Mid-South Aviation Inc.' },
  { code: 'VLA', name: 'Village Aviation' },
  { code: 'VLY', name: 'Valley Airlines' },
  { code: 'VOQ', name: 'Avalon Aviation Ltd.' },
  { code: 'VP', name: 'Viacao Aerea Sao Paulo' },
  { code: 'VQ', name: 'Vintage Props & Jets Inc.' },
  { code: 'VR', name: 'Transportes Aereos De Cabo' },
  { code: 'VS', name: 'Virgin Atlantic Airways' },
  { code: 'VSA', name: 'VistaJet Luftfahrtunternehmen GmbH' },
  { code: 'VV', name: 'Aerosvit Ukranian Airlines' },
  { code: 'VV (1)', name: 'Challenge Air Transport Inc.' },
  { code: 'VV (2)', name: 'Challenge Air International Inc.' },
  { code: 'VW', name: 'Transportes Aeromar S.A de C.V' },
  { code: 'VX', name: 'Virgin America' },
  { code: 'VX (1)', name: 'Aces Airlines' },
  { code: 'VY', name: 'Highland Express Airways' },
  { code: 'W3', name: 'Arik Air International' },
  { code: 'W4', name: 'Mn Aviation, Inc.' },
  { code: 'W7', name: 'Western Pacific Airlines' },
  { code: 'W8', name: 'Cargojet Airways Ltd.' },
  { code: 'W9', name: 'Eastwind Airlines Inc.' },
  { code: 'WA', name: 'Worldwide Airlines Services' },
  { code: 'WA (1)', name: 'Western Air Lines Inc.' },
  { code: 'WAC', name: 'Air East Airlines' },
  { code: 'WAI', name: 'Wise Airlines Inc.' },
  { code: 'WAP', name: 'Arrow Panama' },
  { code: 'WAQ', name: 'Air Charter Systems (Acs)' },
  { code: 'WAR', name: 'Waring Air' },
  { code: 'WAW', name: 'Westward Airways Inc.' },
  { code: 'WC', name: 'Wien Air Alaska Inc.' },
  { code: 'WCA', name: "Walker's International" },
  { code: 'WCI', name: 'Mountainwest Airlines' },
  { code: 'WD', name: 'Halisa Air' },
  { code: 'WDQ', name: 'Wardair Canada Inc.' },
  { code: 'WE', name: 'Centurion Cargo Inc.' },
  { code: 'WEB', name: 'Southeast Alaska Airlines' },
  { code: 'WEQ', name: 'Aero-Services-Corporate' },
  { code: 'WES', name: 'Western Star Airlines Inc.' },
  { code: 'WET', name: 'Western Charter Inc.' },
  { code: 'WFS', name: 'Wheeler Airlines' },
  { code: 'WFT', name: 'Westflight Aviation' },
  { code: 'WG', name: 'Sunwing Airlines Inc.' },
  { code: 'WGL', name: 'Wrangell Air Service' },
  { code: 'WGQ', name: 'Gateway Aviation Limited' },
  { code: 'WI', name: 'Tradewinds Airlines' },
  { code: 'WIA', name: 'Wisc Air' },
  { code: 'WIL', name: "Will's Air" },
  { code: 'WIR', name: 'Williams Air Inc.' },
  { code: 'WL', name: 'World Air Network' },
  { code: 'WLH', name: 'Welch Aviation' },
  { code: 'WLQ', name: 'Worldwide Airlines Inc.' },
  { code: 'WM', name: 'Windward Islands Airways' },
  { code: 'WN', name: 'Southwest Airlines Co.' },
  { code: 'WNG', name: 'Wings Airways' },
  { code: 'WNQ', name: 'Winair Inc.' },
  { code: 'WO', name: 'World Airways Inc.' },
  { code: 'WP', name: 'Island Air Hawaii' },
  { code: 'WRA', name: 'Wra Inc.' },
  { code: 'WRD', name: 'Ward Air' },
  { code: 'WRT', name: 'Wright Airlines Inc.' },
  { code: 'WS', name: 'Westjet' },
  { code: 'WS (1)', name: 'Suncoast Airlines Inc.' },
  { code: 'WSK', name: 'AAR Western Skyways' },
  { code: 'WST', name: 'West Isle Air' },
  { code: 'WSU', name: 'Mission Air' },
  { code: 'WT', name: 'Nigeria Airways Ltd.' },
  { code: 'WTA', name: 'Westates Airlines' },
  { code: 'WV', name: 'Air Greco, Inc., d/b/a Wings Air' },
  { code: 'WV (1)', name: 'Air South Inc. (1)' },
  { code: 'WW', name: 'WOW Air ehf' },
  { code: 'WWI', name: 'Wings West Airlines' },
  { code: 'WWQ', name: 'Worldways Canada Ltd.' },
  { code: 'X4', name: 'Air Excursions LLC' },
  { code: 'X9', name: 'Omni Air Express' },
  { code: 'XAA', name: 'Aero Union Corp' },
  { code: 'XAB', name: 'Air Polynesia d/b/a DHL Cargo' },
  { code: 'XAC', name: 'Air Express International Airlines' },
  { code: 'XAE', name: 'Amerford Airways Inc.' },
  { code: 'XAF', name: 'Astro-Wing Airlines Inc.' },
  { code: 'XAG', name: 'Ball Brothers Inc.' },
  { code: 'XAH', name: 'Hawaii Pacific Air' },
  { code: 'XAI', name: 'Burlington Northern Inc.' },
  { code: 'XAJ', name: 'Central American International' },
  { code: 'XAK', name: 'Century Airlines Inc.' },
  { code: 'XAM', name: 'Cohlmia Aviation' },
  { code: 'XAO', name: 'Dallas Flight Center' },
  { code: 'XAQ', name: 'Cam Air International Inc.' },
  { code: 'XAR', name: 'Jet Way Inc.' },
  { code: 'XAS', name: 'Klondike Air Inc.' },
  { code: 'XAT', name: 'Michigan Peninsula Airways' },
  { code: 'XAU', name: 'Flight Line Inc.' },
  { code: 'XAY', name: 'Pat Air' },
  { code: 'XAZ', name: 'Purolator Sky Courier Inc.' },
  { code: 'XBC', name: 'Jet East Inc.' },
  { code: 'XBF', name: 'WTC Airlines' },
  { code: 'XBH', name: 'Profit Airlines Inc.' },
  { code: 'XBI', name: 'CF Air Freight' },
  { code: 'XBJ', name: 'International Air Service' },
  { code: 'XBM', name: 'Phh Air Inc.' },
  { code: 'XBP', name: 'Circle Airfreight Corp.' },
  { code: 'XBQ', name: 'Aeronaves De Puerto Rico' },
  { code: 'XBR', name: 'Allstates Air Cargo Inc.' },
  { code: 'XBT', name: 'Pacific Alaska Airlines Inc.' },
  { code: 'XBV', name: 'Nal Inc.' },
  { code: 'XBX', name: 'Icb International Airlines' },
  { code: 'XBZ', name: 'Air Natl Aircraft Sal & Ser' },
  { code: 'XC', name: 'Air Caribbean (1)' },
  { code: 'XCA', name: 'Hawkins And Powers Aviation' },
  { code: 'XDA', name: 'Pacific Air Express Inc.' },
  { code: 'XDC', name: 'Hemisphere Air Charter' },
  { code: 'XDD', name: 'International Parcel Express Inc.' },
  { code: 'XDE', name: 'Air Train Inc.' },
  { code: 'XDF', name: 'Salair Inc.' },
  { code: 'XDH', name: 'Kitty Hawk Air Cargo' },
  { code: 'XE', name: 'ExpressJet Airlines Inc. (1)' },
  { code: 'XF', name: 'Vladivostok Air' },
  { code: 'XJ', name: 'Mesaba Airlines' },
  { code: 'XK', name: 'Aerotal' },
  { code: 'XL', name: 'Lan Ecuador' },
  { code: 'XP', name: 'Casino Express' },
  { code: 'XPA', name: 'Express Air d/b/a Sunrise' },
  { code: 'XPE', name: 'Amira Air GmbH' },
  { code: 'XPQ', name: 'International Cargo Xpress' },
  { code: 'XV', name: 'Presidential Airways Inc.' },
  { code: 'XY', name: 'Munz Northern Airlines Inc.' },
  { code: 'XYZ', name: 'Airpac Inc.' },
  { code: 'Y2', name: 'Globespan Airways Limited d/b/a Flyglobespan' },
  { code: 'Y4', name: 'Concesionaria Vuela Compania De Aviacion SA de CV (Volaris)' },
  { code: 'Y7', name: 'Flyjet Limited d/b/a Silverjet' },
  { code: 'Y8', name: 'Yangtze River Express Airlines Company' },
  { code: 'YB', name: 'Trans Continental Airlines' },
  { code: 'YBQ', name: 'Yellow Bird Air' },
  { code: 'YM', name: 'Royal West Airlines' },
  { code: 'YR', name: 'Grand Canyon Airlines, Inc. d/b/a Grand Canyon Airlines d/b/a Scenic Airlines' },
  { code: 'YV', name: 'Mesa Airlines Inc.' },
  { code: 'YX', name: 'Republic Airlines' },
  { code: 'YX (1)', name: 'Midwest Airline, Inc.' },
  { code: 'Z3', name: 'PM Air, LLC' },
  { code: 'Z3 (1)', name: 'Promech' },
  { code: 'ZA', name: 'Accessair Holdings' },
  { code: 'ZAQ', name: 'Zas Airline Of Egypt' },
  { code: 'ZB', name: 'Monarch Airlines' },
  { code: 'ZE', name: 'Lineas Aereas Azteca' },
  { code: 'ZFZ', name: 'Mcphillips Flying Service' },
  { code: 'ZIA', name: 'Air New Mexico' },
  { code: 'ZK', name: 'Great Lakes Airlines' },
  { code: 'ZKQ', name: 'Zantop International' },
  { code: 'ZL', name: 'Affretair' },
  { code: 'ZMZ', name: 'Winnipesaukee Aviation Inc.' },
  { code: 'ZN', name: 'Key Airlines Inc.' },
  { code: 'ZO', name: 'Florida Express Inc.' },
  { code: 'ZP', name: 'Air St. Thomas' },
  { code: 'ZS', name: 'Hispaniola Airways' },
  { code: 'ZUQ', name: 'Zuliana De Aviacion' },
  { code: 'ZV', name: 'Air Midwest Inc.' },
  { code: 'ZW', name: 'Air Wisconsin Airlines Corp' },
  { code: 'ZX', name: 'Air Georgian' },
  { code: 'ZX (1)', name: 'Airbc Ltd.' },
  { code: 'ZY', name: 'Atlantic Gulf Airlines' },
  { code: 'SVGA', name: 'SVG Air' },
  { code: 'GNDA', name: 'Grenadine Airways' },
  { code: 'MUSA', name: 'Mustique Airways' },
  { code: 'ZYZ', name: 'Skyway Aviation Inc.' },
  { code: 'AXM', name: 'AirAsia' },
]
