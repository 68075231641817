import { createAction, props } from '@ngrx/store'
import { DataFeed } from './datafeed.model'
import { Update } from '@ngrx/entity'

export const LoadFeeds = createAction('[Feed] Load Feeds')
export const LoadFeedsComplete = createAction('[Feed] Load Feeds Complete', props<{ feeds: DataFeed[] }>())

export const CreateFeed = createAction('[Feed] Create Feed', props<{ payload: { name: string; type: string } }>())
export const CreateFeedComplete = createAction('[Feed] Create Feed Complete', props<{ feed: DataFeed }>())

export const ResumeFeed = createAction('[Feed] Resume Feed', props<{ id: string }>())
export const ResumeFeedComplete = createAction('[Feed] Resume Feed Complete', props<{ update: Update<DataFeed> }>())

export const PauseFeed = createAction('[Feed] Pause Feed', props<{ id: string }>())
export const PauseFeedComplete = createAction('[Feed] Pause Feed Complete', props<{ update: Update<DataFeed> }>())

export const DeleteFeed = createAction('[Feed] Delete Feed', props<{ id: string }>())
export const DeleteFeedComplete = createAction('[Feed] Delete Feed Complete', props<{ id: string }>())

export const DeleteFeeds = createAction('[Feed] Delete Feeds', props<{ ids: string[] }>())
export const DeleteFeedsComplete = createAction('[Feed] Delete Feeds Complete', props<{ ids: string[] }>())
