import * as lodash from 'lodash'
import * as moment from 'moment'
import { datetime, RRule } from 'rrule'

export interface DataRecurringCondition {
  // Recurring Task
  frequency: string // #ENUM(DAILY, WEEKLY, MONTHLY, YEARLY) ex: DAILY
  interval: number // #ex: 1
  weekDay?: number[] // #ENUM(1,2,3,4,5,6,7) ex: 1
  count?: number // #ex: 1
  until?: number // #UNIX timestamp ex: 1718686961
}

export interface DataRecurringRule {
  recurrenceRule?: DataRecurringCondition
  repeat?: number
}

export function isRecurringConditionChanged(d1: DataRecurringCondition, d2: DataRecurringCondition) {
  if (d1.frequency !== d2.frequency) return true
  if (d1.interval != d2.interval) return true
  if (d1.count != d2.count) return true
  if (d1.until != d2.until) return true

  return !lodash.isEqual(d1.weekDay || [], d2.weekDay || [])
}

export function getRecurringDates(utcEpoch: number, condition: DataRecurringCondition): number[] {
  const weekdays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU]
  const start = moment.utc(utcEpoch * 1000)
  const until = condition.until ? moment.utc(condition.until * 1000) : null

  const options = {
    freq: RRule[condition.frequency],
    interval: +condition.interval,
    byweekday: condition.frequency === 'WEEKLY' ? lodash.map(condition.weekDay, (t) => weekdays[t - 1]) : null,
    dtstart: datetime(start.year(), start.month() + 1, start.date()),
    until: until ? datetime(until.year(), until.month() + 1, until.date()) : undefined,
    count: +condition.count,
    wkst: RRule.MO,
  }

  const rule = new RRule(options)

  const items = rule.all()
  return items.map((d) => moment(d).unix())
}
