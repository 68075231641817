import { Expose, Serializable, Type, plainToClass, Rental } from '@tokeet-frontend/tv3-platform'
import { Guest } from '@tv3/store/guest/guest.model'

export type NotificationType =
  | 'inquiry'
  | 'paid'
  | 'booked'
  | 'cancelled'
  | 'signed'
  | 'rejected'
  | 'succeeded'
  | 'failed'

export enum NotificationLinkTypes {
  Message = 'message',
  Inquiry = 'inquiry',
  Booking = 'booking',
  Invoice = 'invoice',
  Guest = 'guest',
  Rental = 'rental',
  Subscription = 'subscription',
  Contract = 'contract',
  HoldEvent = 'event',
}

export class Notification extends Serializable<Notification> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'link_type' })
  linkType: NotificationLinkTypes

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'link_pkey' })
  linkId: string

  action: string
  date: number
  subject: string
  title: string
  user?: string

  @Type(() => Rental)
  rental: Rental

  @Type(() => Guest)
  guest: Guest

  type: NotificationType

  static deserialize(data: any): Notification {
    const notification: Notification = plainToClass<Notification, Notification>(Notification, {
      ...data,
      pkey: `${data.pkey}-${data.date}-${data.action}`,
    })

    notification.date = parseInt(<any>notification.date, 10)

    /**
     * Special Case where we change Inquiry Cancelled into Booking Cancelled
     * Special Case where we change Inquiry Booked into Booking Confirmed
     * because that's how trigger event is called
     */
    if (notification.linkType === NotificationLinkTypes.Inquiry && notification.action === 'cancelled') {
      notification.linkType = NotificationLinkTypes.Booking
    }
    if (notification.linkType === NotificationLinkTypes.Inquiry && notification.action === 'booked') {
      notification.linkType = NotificationLinkTypes.Booking
      notification.action = 'confirmed'
    }

    notification.type = this.getType(notification)
    return notification
  }

  private static getType(notification: Notification): NotificationType {
    return (
      [
        { id: 'inquiry', condition: (a) => NotificationLinkTypes.Inquiry === a.linkType && 'created' === a.action },
        { id: 'paid', condition: (a) => NotificationLinkTypes.Invoice === a.linkType && 'paid' === a.action },
        { id: 'booked', condition: (a) => NotificationLinkTypes.Booking === a.linkType && 'confirmed' === a.action },
        { id: 'cancelled', condition: (a) => NotificationLinkTypes.Booking === a.linkType && 'cancelled' === a.action },
        { id: 'signed', condition: (a) => NotificationLinkTypes.Contract === a.linkType && 'signed' === a.action },
        { id: 'rejected', condition: (a) => NotificationLinkTypes.Contract === a.linkType && 'rejected' === a.action },
        {
          id: 'succeeded',
          condition: (a) => NotificationLinkTypes.Subscription === a.linkType && 'succeeded' === a.action,
        },
        { id: 'failed', condition: (a) => NotificationLinkTypes.Subscription === a.linkType && 'failed' === a.action },
        { id: 'event', condition: (a) => NotificationLinkTypes.HoldEvent === a.linkType },
      ].find((i) => i.condition(notification)) || { id: null }
    ).id as NotificationType
  }
}
