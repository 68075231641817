import { TemplateType } from '@tokeet-frontend/templates'

export interface AutomationEventTemplateSample {
  name: string
  subject: string
  description: string
  body: string
  type: TemplateType.Email | TemplateType.Payload | TemplateType.PushNotification
}

export const deviceBatteryLowTemplateSample: AutomationEventTemplateSample = {
  name: 'Battery is low',
  subject: 'Battery is low for *|DEVICE:NAME|*',
  description: '',
  body: `Hi,<br><br>
    Smart Device *|DEVICE:NAME|* battery is low. Please replace batteries.
    `,
  type: TemplateType.Email,
}
export const deviceCodeFirstUsedTemplateSample: AutomationEventTemplateSample = {
  name: 'Device code first used',
  subject: 'Device code first used for *|DEVICE:NAME|*',
  description: '',
  body: `Hi,<br><br>
    Guest *|GUEST:NAME|* has used this code first time for Rental *|RENTAL:NAME|* for this booking *|INQUIRY:ARRIVE|* to *|INQUIRY:DEPART|*.
    `,
  type: TemplateType.Email,
}

export const deviceIssuesRequireAttentionTemplateSample: AutomationEventTemplateSample = {
  name: 'Device issues require attention',
  subject: 'Device issues for *|DEVICE:NAME|*',
  description: '',
  body: `Hi,<br><br>
    Something is wrong with device *|DEVICE:NAME|* for *|RENTAL:NAME|*. Please check the device configuration.
    `,
  type: TemplateType.Email,
}

export const bookingConfirmedNotifyEmailTemplateSample: AutomationEventTemplateSample = {
  name: 'Booking Confirmation Message to Guest',
  subject: 'Thanks for booking *|RENTAL:NAME|*',
  description: '',
  body: `Hi *|GUEST:NAME|*,<br>
      <br>
      We are happy that you have decided to book our place. We look forward to having you as our guest, and will do whatever we can to make your stay enjoyable! We will be sending you some pre-arrival information including check in and wifi details a few days before your scheduled arrival. If you have any questions before then, just let us know. Your booking details are below for your reference.<br>
      <br>
      Property: *|RENTAL:NAME|*<br>
      Adults: *|INQUIRY:ADULTS|*<br>
      Children: *|INQUIRY:CHILDREN|*<br>
      Arrival: *|INQUIRY:ARRIVE|*<br>
      Departure: *|INQUIRY:DEPART|*<br>
      Total: *|INQUIRY:COST|*`,
  type: TemplateType.Email,
}

export const bookingCanceledNotifyEmailTemplateSample: AutomationEventTemplateSample = {
  name: 'Booking Cancellation Message to Guest',
  subject: 'We are sorry to see you cancel your stay at *|RENTAL:NAME|*',
  description: '',
  body: `Hi *|GUEST:NAME|*,<br>
     <br>
      We are disappointed to see that you have cancelled your stay with us at *|RENTAL:NAME|* from *|INQUIRY:ARRIVE|* to *|INQUIRY:DEPART|*. If there is anything we can do to assist you with getting rebooked, please let us know. We would love to have you as a guest, and we wish you the best of luck in your journey.<br>
      <br>
      Thank you for your interest.<br>
      `,
  type: TemplateType.Email,
}
