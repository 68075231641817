import * as StealthActions from './stealth.actions'
import {
  AddStealthTriggersComplete,
  ArchiveStealthTriggerComplete,
  DeleteStealthTriggerComplete,
  DeleteStealthTriggers,
  DeleteStealthTriggersComplete,
  LoadStealthTriggersComplete,
  PauseStealthTriggerComplete,
  ResumeStealthTriggerComplete,
  UnarchiveStealthTriggerComplete,
  UpdateStealthTriggerComplete,
} from './stealth.actions'
import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { StealthTriggerService } from './stealth.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class StealthEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(StealthActions.LoadStealthTriggers.type),
    switchMap(() =>
      this.stealthTriggerService.all().pipe(
        map((triggers) => LoadStealthTriggersComplete({ triggers })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(StealthActions.AddStealthTriggers.type),
    switchMap(({ data }) =>
      this.stealthTriggerService.add(data).pipe(
        tap(() => this.toast.success('Stealth created successfully')),
        map((triggers) => AddStealthTriggersComplete({ triggers })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(StealthActions.UpdateStealthTrigger.type),
    switchMap(({ id, changes }) =>
      this.stealthTriggerService.update(<string>id, changes).pipe(
        tap(() => this.toast.success('Stealth updated successfully')),
        map((changes) => UpdateStealthTriggerComplete({ update: { id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pause$ = this.actions$.pipe(
    ofType(StealthActions.PauseStealthTrigger.type),
    switchMap(({ id }) =>
      this.stealthTriggerService.pause(id).pipe(
        tap(() => this.toast.success('Stealth mode paused successfully')),
        map((changes) => PauseStealthTriggerComplete({ update: { id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  resume$ = this.actions$.pipe(
    ofType(StealthActions.ResumeStealthTrigger.type),
    switchMap(({ id }) =>
      this.stealthTriggerService.resume(id).pipe(
        tap(() => this.toast.success('Stealth mode resumed successfully')),
        map((changes) => ResumeStealthTriggerComplete({ update: { id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(StealthActions.ArchiveStealthTrigger.type),
    switchMap(({ id }) =>
      this.stealthTriggerService.archive(id).pipe(
        tap(() => this.toast.success('Stealth mode archived successfully')),
        map((changes) => ArchiveStealthTriggerComplete({ update: { id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  unarchive$ = this.actions$.pipe(
    ofType(StealthActions.UnarchiveStealthTrigger.type),
    switchMap(({ id }) =>
      this.stealthTriggerService.unarchive(id).pipe(
        tap(() => this.toast.success('Stealth mode unarchived successfully')),
        map((changes) => UnarchiveStealthTriggerComplete({ update: { id, changes } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  remove$ = this.actions$.pipe(
    ofType(StealthActions.DeleteStealthTrigger.type),
    switchMap(({ id }) =>
      this.stealthTriggerService.remove(id).pipe(
        tap(() => this.toast.success('Stealth mode deleted successfully')),
        map(() => DeleteStealthTriggerComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeMultiple$ = this.actions$.pipe(
    ofType(DeleteStealthTriggers),
    switchMap(({ ids }) =>
      this.stealthTriggerService.removeMultiple(ids).pipe(
        tap(() => this.toast.success('Stealth modes deleted successfully')),
        map(() => DeleteStealthTriggersComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions<StealthActions.StealthActionsUnion>,
    private store: Store<fromRoot.State>,
    private stealthTriggerService: StealthTriggerService,
    private toast: Toaster
  ) {}
}
