import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { ChannelsLibraryModule } from '@tokeet-frontend/channels'
import { ReviewsLibraryModule } from '@tokeet-frontend/reviews'
import { ReviewReplyDialogComponent } from './components/review-reply-dialog/review-reply-dialog.component'
import { ReviewCategoryTagsComponent } from './components/review-category-tags/review-category-tags.component'
import { ChannelReviewsTableComponent } from './components/channel-reviews-table/channel-reviews-table.component'
import { TemplatesSharedModule } from '../templates/templates-shared.module'

const components = [
  //
  ReviewReplyDialogComponent,
  ReviewCategoryTagsComponent,
  ChannelReviewsTableComponent,
]

const modules = [
  //
  SharedModule,
  ChannelsLibraryModule,
  ReviewsLibraryModule,
  TemplatesSharedModule,
]

@NgModule({
  declarations: [
    //
    ...components,
  ],
  imports: [
    //
    CommonModule,
    ...modules,
  ],
  exports: [
    //
    ...modules,
    ...components,
  ],
})
export class ReviewsSharedModule {}
