import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  AddUser,
  Destroyable,
  isEmailTaken,
  notAllNumbersValidator,
  ParsedGooglePlace,
  SaveForm,
  selectRoles,
  selectUsers,
  Toaster,
  untilDestroy,
  User,
  USER_ROLES,
  isSomething,
  UpdateUser,
  unRestrictUserAll,
  selectAllRentals,
  Rental,
  getCountryByCode,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { MapsAPILoader } from '@agm/core'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'
import { alphanumericNameValidator } from '@tokeet-frontend/tv3-platform'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import { userRoleToNumber } from '@tv3/utils/functions/user-role-to-number'

@Component({
  selector: 'user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent extends Destroyable implements OnInit {
  users: User[]
  rentals: Rental[]
  admins: User[]
  emails: string[]

  isEdit = false
  get user() {
    return this.data.user
  }

  roles = USER_ROLES

  isYearlyOC = false

  roles$ = this.store.pipe(select(selectRoles))

  form = this.fb.group({
    firstname: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        notAllNumbersValidator,
        alphanumericNameValidator,
      ],
    ],
    lastname: ['', [Validators.required, alphanumericNameValidator]],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    role: ['', [Validators.required]],
    address: this.fb.group({
      address: ['', [Validators.minLength(2), Validators.maxLength(140)]],
      city: ['', [Validators.minLength(2), Validators.maxLength(50)]],
      state: ['', [Validators.minLength(2), Validators.maxLength(50)]],
      country: [''],
      country_code: [''],
      postal_code: [''],
    }),
    attributes: this.fb.group({
      gender: [],
      language: [],
    }),
  })

  get addressFrom() {
    return this.form.get('address') as FormGroup
  }
  isGoogleLoaded = false

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    public fb: FormBuilder,
    private mapsApiLoader: MapsAPILoader,
    public store: Store<fromRoot.State>,
    private toast: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { user?: User }
  ) {
    super()
  }

  ngOnInit() {
    const user = this.data.user
    if (user) {
      this.isEdit = true
      this.form.patchValue({
        ...user,
        firstname: user.firstName,
        lastname: user.lastName,
        phone: user.phone,
        email: this.user.primaryEmail,
        role: R.toString(userRoleToNumber(user.roles)),
        address: {
          ...user.address,
          country_code: user.address?.countryCode || '',
          postal_code: user.address?.postalCode || '',
        },
      })
    } else {
      this.form.addControl('password1', this.fb.control('', [Validators.required, Validators.minLength(8)]))
      this.form.addControl('password2', this.fb.control('', [Validators.required, Validators.minLength(8)]))
    }

    this.mapsApiLoader.load().then(() => {
      this.isGoogleLoaded = true
    })

    this.store.pipe(select(selectUsers), untilDestroy(this)).subscribe((users) => {
      this.users = users
      this.admins = R.filter((u: User) => u.roles && u.roles.indexOf(USER_ROLES.admin.value) > -1, users)
      this.emails = <string[]>R.pipe(
        R.filter((u: User) => u.id !== this.user.id),
        <any>R.map((u: User) => u.primaryEmail)
      )(<any>users)
    })

    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })

    this.store
      .pipe(select(selectActiveProductPlanView(ProductsForPlan.OwnerCenter)), untilDestroy(this))
      .subscribe((sub) => {
        this.isYearlyOC = sub?.isYearly
      })
  }

  close() {
    this.dialogRef.close()
  }

  onPlaceChange(parsedPlace: ParsedGooglePlace) {
    const country = getCountryByCode(parsedPlace.countryShort)
    this.addressFrom.patchValue({
      address: (parsedPlace.streetNumber ? parsedPlace.streetNumber + ' ' : '') + (parsedPlace.street || ''),
      city: parsedPlace.city,
      state: parsedPlace.state,
      country: country,
      country_code: parsedPlace.countryShort,
    })
  }

  @SaveForm()
  onCreate(form: FormGroup) {
    const formValues = form.getRawValue()
    const payload = {
      ...formValues,
      roles: [formValues.role],
    }
    if (isEmailTaken(formValues.email, this.users)) {
      this.toast.error('Email is already taken!')
      return
    }
    if (!R.equals(formValues.password1, formValues.password2)) {
      this.toast.error('Passwords do not match.')
      return
    }
    this.store.dispatch(AddUser({ payload }))
    this.close()
  }

  @SaveForm()
  onUpdate(form: FormGroup) {
    const formValues = form.getRawValue()
    const payload = {
      ...formValues,
      roles: [formValues.role],
      attributes: {
        ...this.user.attributes,
        ...formValues.attributes,
      },
    }
    if (
      this.admins.length <= 1 &&
      userRoleToString(R.map((r) => parseFloat(r), [formValues.role])) !== USER_ROLES.admin.name &&
      this.admins[0].id === this.user.id
    ) {
      this.toast.error('This is the last administrator, You cannot remove it')
      return
    }

    if (this.emails.indexOf(formValues.email) > -1) {
      this.toast.error('User with this email already exist.')
      return
    }

    if (!isSomething(formValues.role)) {
      this.toast.error('You must have to specify at least one role.')
      return
    }

    this.store.dispatch(UpdateUser({ userId: this.user.id, payload }))
    this.tryToUnrestrictOwner(formValues)
    this.close()
  }

  private tryToUnrestrictOwner(form: any) {
    // tslint:disable-next-line: triple-equals
    if (
      userRoleToNumber(this.user.roles) !== USER_ROLES.owner.value &&
      parseFloat(form.role) == USER_ROLES.owner.value
    ) {
      const rentals = R.filter((rental) => {
        return R.contains(this.user.id, R.defaultTo([], rental.restrictedUsers))
      }, this.rentals)
      if (rentals.length > 0) {
        this.store.dispatch(unRestrictUserAll({ rentals, userId: this.user.id }))
      }
    }
  }
}
