import { createAction, props } from '@ngrx/store'
import { TaskResponse, UpdateTaskPayload } from './task.model'
import { Update } from '@ngrx/entity'
import { CreateTaskPayload } from '@tv3/store/task/task.model'

export const LoadTasks = createAction('[Task] Load Tasks')
export const LoadTasksComplete = createAction('[Task] Load Tasks Complete', props<{ tasks: TaskResponse[] }>())

// load by ids
export const LoadTasksByIds = createAction('[Task] Load Tasks by ids', props<{ ids: string[] }>())
export const LoadTasksByIdsComplete = createAction(
  '[Task] Load Tasks by ids Complete',
  props<{ tasks: TaskResponse[] }>()
)

export const AddTask = createAction('[Task] Add Task', props<{ form: CreateTaskPayload }>())
export const AddTaskComplete = createAction('[Task] Add Task Complete', props<{ task: TaskResponse }>())

export const AddTasks = createAction('[Task] Add Tasks', props<{ requests: CreateTaskPayload[] }>())
export const AddTasksComplete = createAction('[Task] Add Tasks Complete', props<{ tasks: TaskResponse[] }>())

export const DeleteTask = createAction('[Task] Delete Task', props<{ id: string }>())
export const DeleteTaskComplete = createAction('[Task] Delete Task Complete', props<{ id: string }>())

export const DeleteTasks = createAction('[Task] Delete Tasks', props<{ ids: string[] }>())
export const DeleteTasksComplete = createAction('[Task] Delete Tasks Complete', props<{ ids: string[] }>())

export const UpdateTask = createAction('[Task] Update Task', props<{ form: UpdateTaskPayload; id: string }>())
export const UpdateTaskComplete = createAction('[Task] Update Task Complete', props<{ update: Update<TaskResponse> }>())

export const ChangeTaskStatus = createAction('[Task] Change Status', props<{ status: number; id: string }>())
export const ChangeTaskStatusComplete = createAction(
  '[Task] Change Status Complete',
  props<{ update: Update<TaskResponse> }>()
)

export const ArchiveTasks = createAction('[Task] Archive Tasks', props<{ ids: string[] }>())
export const ArchiveTasksComplete = createAction(
  '[Task] Archive Tasks Complete',
  props<{ updates: Update<TaskResponse>[] }>()
)

export const UnarchiveTasks = createAction('[Task] UnArchive Tasks', props<{ ids: string[] }>())
export const UnarchiveTasksComplete = createAction(
  '[Task] UnArchive Tasks Complete',
  props<{ updates: Update<TaskResponse>[] }>()
)

export const ChangeTasksStatus = createAction('[Task] Change Tasks Status', props<{ status: number; ids: string[] }>())
export const ChangeTasksStatusComplete = createAction(
  '[Task] Change Tasks Status Complete',
  props<{ update: Update<TaskResponse>[] }>()
)
