import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActionFailed, Channel, ConfirmDialogService, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'

import { ConnectGVR, ConnectGVRSuccess, DisconnectGVR, GVRStatus, selectGVRStatus } from '@tokeet-frontend/channels'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, Validators } from '@angular/forms'
import { ChannelConnectHelperService } from '../../channel-connect-helper.service'
@Component({
  selector: 'app-connect-gvr-wizard-step1',
  templateUrl: './connect-gvr-wizard-step1.component.html',
  styleUrls: ['./connect-gvr-wizard-step1.component.scss'],
  providers: [ChannelConnectHelperService],
})
export class ConnectGVRWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()
  @Input() channel: Channel

  form = this.fb.group({
    providerId: ['', [Validators.required]],
  })

  isConnecting = false
  status?: GVRStatus

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private confirm: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectGVRStatus), untilDestroy(this)).subscribe((status) => {
      this.status = status
    })
    this.actions.pipe(ofType(ConnectGVRSuccess), untilDestroy(this)).subscribe(() => {
      this.next.emit()
      this.isConnecting = false
    })
    this.actions.pipe(ofType(ActionFailed), untilDestroy(this)).subscribe(() => {
      this.isConnecting = false
    })
  }

  onConnect() {
    this.store.dispatch(ConnectGVR())
    this.isConnecting = true
  }

  onDisconnect() {
    this.confirm
      .confirm({
        title: 'Disconnect?',
        body: 'Are you sure you want to disconnect Google Vacation Rentals?',
      })
      .subscribe(() => {
        this.store.dispatch(DisconnectGVR())
      })
  }
}
