import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { SaveTemplateDialogComponent } from '@tv3/containers/templates/save-template-dialog/save-template-dialog.component'
import { filter, take } from 'rxjs/operators'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { Template } from '@tokeet-frontend/templates'

@Injectable({
  providedIn: SharedModule,
})
export class SaveTemplateDialogService {
  constructor(private dialog: MatDialog) {}

  public open(isEdit?: boolean, data?: Pick<Template, 'name' | 'description'>) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      data: { ...data, isEdit },
      panelClass: ['variable-modal'],
    }

    return this.dialog
      .open<SaveTemplateDialogComponent, any, { data: Pick<Template, 'name' | 'description'>; saveAsNew: boolean }>(
        SaveTemplateDialogComponent,
        defaultConfig
      )
      .afterClosed()
      .pipe(
        take(1),
        filter((request) => isSomething(request))
      )
  }
}
