import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core'
import * as R from 'ramda'
import { toNumber } from 'lodash'

declare const google: any

export class ComponentRestrictions {
  public country: string

  constructor(obj?: Partial<ComponentRestrictions>) {
    if (!obj) {
      return
    }

    Object.assign(this, obj)
  }
}

export interface LatLng {
  /** Comparison function. */
  equals(other: LatLng): boolean

  /** Returns the latitude in degrees. */
  lat(): number

  /** Returns the longitude in degrees. */
  lng(): number

  /** Converts to string representation. */
  toString(): string

  /** Returns a string of the form "lat,lng". We round the lat/lng values to 6 decimal places by default. */
  toUrlValue(precision?: number): string

  /** Converts to JSON representation. This function is intended to be used via JSON.stringify. */
  toJSON(): LatLngLiteral
}

export type LatLngLiteral = { lat: number; lng: number }
export type LatLngBoundsLiteral = { east: number; north: number; south: number; west: number }

export interface LatLngBounds {
  /** Returns true if the given lat/lng is in this bounds. */
  contains(latLng: LatLng | LatLngLiteral): boolean

  /** Returns true if this bounds approximately equals the given bounds. */
  equals(other: LatLngBounds | LatLngBoundsLiteral): boolean

  /** Extends this bounds to contain the given point. */
  extend(point: LatLng | LatLngLiteral): LatLngBounds

  /** Computes the center of this LatLngBounds */
  getCenter(): LatLng

  /** Returns the north-east corner of this bounds. */
  getNorthEast(): LatLng

  /** Returns the south-west corner of this bounds. */
  getSouthWest(): LatLng

  /** Returns true if this bounds shares any points with the other bounds. */
  intersects(other: LatLngBounds | LatLngBoundsLiteral): boolean

  /** Returns if the bounds are empty. */
  isEmpty(): boolean

  /** Converts to JSON representation. This function is intended to be used via JSON.stringify. */
  toJSON(): LatLngBoundsLiteral

  /** Converts the given map bounds to a lat/lng span. */
  toSpan(): LatLng

  /** Converts to string. */
  toString(): string

  /**
   * Returns a string of the form "lat_lo,lng_lo,lat_hi,lng_hi" for this bounds, where "lo" corresponds to the
   * southwest corner of the bounding box, while "hi" corresponds to the northeast corner of that box.
   */
  toUrlValue(precision?: number): string

  /** Extends this bounds to contain the union of this and the given bounds. */
  union(other: LatLngBounds | LatLngBoundsLiteral): LatLngBounds
}

export interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

export interface Geometry {
  location: LatLng
  viewport: LatLngBounds
}

export interface Photo {
  height: number
  html_attributions: string[]
  width: number
}

export interface OpeningHours {
  open_now: boolean
  periods: OpeningPeriod[]
  weekday_text: string[]
}

export interface OpeningPeriod {
  open: OpeningHoursTime
  close?: OpeningHoursTime
}

export interface OpeningHoursTime {
  day: number
  hours: number
  minutes: number
  nextDate: number
  time: string
}

export class Place {
  address_components: AddressComponent[]
  adr_address: string
  formatted_address: string
  formatted_phone_number: string
  geometry: Geometry
  html_attributions: string[]
  icon: string
  id: string
  international_phone_number: string
  name: string
  opening_hours: OpeningHours
  permanently_closed: boolean
  photos: Photo[]
  place_id: string
  price_level: number
  rating: number
  reviews: PlaceReview[]
  types: string[]
  url: string
  utc_offset: number
  vicinity: string
  website: string
}

export class Options {
  public bounds: LatLngBounds
  public componentRestrictions: ComponentRestrictions
  public types: string[]

  public constructor(opt?: Partial<Options>) {
    if (!opt) {
      return
    }

    Object.assign(this, opt)
  }
}

export interface PlaceReview {
  aspects: PlaceAspectRating[]
  author_name: string
  author_url: string
  language: string
  text: string
}

export interface PlaceAspectRating {
  rating: number
  type: string
}

export interface ParsedGooglePlace {
  placeId: string
  streetNumber: string
  street: string
  city: string
  state: string
  countryShort: string
  country: string
  gps: {
    lat: number
    long: number
  }
  postCode: string
  district: string
}

@Directive({
  selector: '[appGooglePlaces]',
  exportAs: 'app-google-places',
})
export class GooglePlacesDirective implements AfterViewInit {
  @Input('options') options: Options
  @Output() onAddressChange: EventEmitter<any> = new EventEmitter()
  private autocomplete: any
  private eventListener: any
  public place: Place

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    if (!this.options) {
      this.options = new Options()
    }

    this.initialize()
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places)
  }

  private initialize(): void {
    if (this.autocomplete != null) {
      google.maps.event.trigger(this.autocomplete, 'remove')
      this.autocomplete = null
      this.eventListener = null
    }

    if (!this.isGoogleLibExists()) {
      throw new Error('Google maps library can not be found')
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options)

    if (!this.autocomplete) {
      throw new Error('Autocomplete is not initialized')
    }

    if (!this.autocomplete.addListener != null) {
      // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent()
      })
    }
  }

  public reset(): void {
    this.initialize()
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace()

      if (this.place && this.place.place_id) {
        this.onAddressChange.emit(this.parseGooglePlace(this.place))
      }
    })
  }

  isGooglePlace(place: Place) {
    if (!place) {
      return false
    }
    return !!place.place_id
  }

  getAddressComponent(place: Place, componentTemplate) {
    let result
    if (!this.isGooglePlace(place)) {
      return
    }
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0]
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]]
        return result
      }
    }
    return
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getPlaceId(place: Place) {
    if (!this.isGooglePlace(place)) {
      return
    }
    return place.place_id
  }

  getCity(place: Place) {
    return (
      this.getAddressComponent(place, { locality: 'long_name' }) ||
      this.getAddressComponent(place, { sublocality: 'long_name' }) ||
      this.getAddressComponent(place, { sublocality_level_1: 'long_name' }) ||
      this.getAddressComponent(place, { neighborhood: 'long_name' }) ||
      this.getAddressComponent(place, { administrative_area_level_3: 'long_name' }) ||
      this.getAddressComponent(place, { administrative_area_level_2: 'long_name' })
    )
  }

  getState(place: Place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getDistrict(place: Place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getCountryShort(place: Place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getCountry(place: Place) {
    const COMPONENT_TEMPLATE = { country: 'long_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  getPostCode(place: Place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' }
    return this.getAddressComponent(place, COMPONENT_TEMPLATE)
  }

  isGeometryExist(place: Place): boolean {
    return R.is(Object, place) && R.is(Object, place.geometry)
  }

  getLatitude(place: Place): number {
    if (!this.isGeometryExist(place)) {
      return
    }
    return toNumber(place.geometry.location.lat())
  }

  getLongitude(place: Place): number {
    if (!this.isGeometryExist(place)) {
      return
    }
    return toNumber(place.geometry.location.lng())
  }

  parseGooglePlace(place: Place): ParsedGooglePlace {
    return {
      placeId: this.getPlaceId(place),
      streetNumber: this.getStreetNumber(place),
      street: this.getStreet(place),
      city: this.getCity(place),
      state: this.getState(place),
      countryShort: this.getCountryShort(place),
      country: this.getCountry(place),
      gps: {
        lat: this.getLatitude(place),
        long: this.getLongitude(place),
      },
      postCode: this.getPostCode(place),
      district: this.getDistrict(place),
    } as ParsedGooglePlace
  }
}
