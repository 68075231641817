import { Component, OnInit, Inject } from '@angular/core'
import { Destroyable, Rental, ConfirmDialogService, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Store, select } from '@ngrx/store'
import { finalize } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Room } from '../../../store/rooms/rooms.model'
import { UpsertRooms, UpsertRoomsComplete } from '../../../store/rooms/rooms.actions'
import { selectRooms } from '../../../store/rooms/rooms.selectors'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-copy-rooms-from-dialog',
  templateUrl: './copy-rooms-from-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  host: {
    class: 'modal-content',
  },
})
export class CopyRoomsFromDialogComponent extends Destroyable implements OnInit {
  get rental() {
    return this.data.rental
  }
  isLoading = false
  selectedRooms: Room[] = []

  rentalRooms: Room[] = []
  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopyRoomsFromDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectRooms(this.data.rental.id)), untilDestroy(this)).subscribe((rooms) => {
      this.rentalRooms = rooms
    })

    this.actions.pipe(ofType(UpsertRoomsComplete), untilDestroy(this)).subscribe(() => {
      this.dialogRef.close('done')
    })
  }

  onSelect(items: Room[]) {
    this.selectedRooms = items
  }

  onOverwrite() {
    this.isLoading = true
    this.confirmDialog
      .confirm({
        title: 'Overwrite current rooms?',
        body: 'Copying rooms from different rental will overwrite current rooms.',
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        const createRooms = this.selectedRooms.map((room) => {
          return {
            ...room,
            rental_id: this.rental.id,
          }
        })
        this.store.dispatch(UpsertRooms({ createRooms, deleteRooms: this.rentalRooms.map((room) => room.pkey) }))
      })
  }

  onAdd() {
    const createRooms = this.selectedRooms.map((room) => {
      return {
        ...room,
        rental_id: this.rental.id,
      }
    })

    this.store.dispatch(UpsertRooms({ createRooms }))
  }

  close() {
    this.dialogRef.close()
  }
}
