<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-credit-card"></i> Add Credit Card</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <!--

  @todo - style this form better

  https://stripe.dev/elements-examples/
  https://github.com/stripe/elements-examples/#example-2

  -->

  <form [formGroup]="form" class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="First Name:" formControlName="firstName" [required]="true" />
        <mat-error *ngIf="form.get('firstName').touched && form.get('firstName').hasError('required')">
          First name is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="
            form.get('firstName').touched &&
            form.get('firstName').hasError('allWhitespace') &&
            !form.get('firstName').hasError('required')
          "
        >
          First name cannot be all whitespaces
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Last Name:" formControlName="lastName" [required]="true" />
        <mat-error *ngIf="form.get('lastName').touched && form.get('lastName').hasError('required')">
          Last name is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="
            form.get('lastName').touched &&
            form.get('lastName').hasError('allWhitespace') &&
            !form.get('lastName').hasError('required')
          "
        >
          Last name cannot be all whitespaces
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-12 cc-box">
      <div id="card-element" class="field border-bottom"></div>
      <small *ngIf="cardValidationMessage" class="text-danger">{{ cardValidationMessage }}</small>
    </div>
    <div class="col-sm-12 terms-box">
      <mat-checkbox class="mt-primary mat-block" formControlName="terms" required>
        I agree to the <a href="https://www.tokeet.com/terms.html" target="_blank">terms & conditions</a
        ><sup class="text-danger">*</sup>
      </mat-checkbox>
      <mat-error *ngIf="form.get('terms').touched && form.get('terms').hasError('required')">
        You have to accept terms and conditions
      </mat-error>
    </div>
    <div class="col-sm-12">
      <div class="terms">
        <p>
          I authorise AdvanceCM to send instructions to the financial institution that issued my card to take payments
          from my card account in accordance with the terms of my agreement with you.
        </p>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="save(form)"
    [disabled]="processing || !isCardValid"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i *ngIf="!processing" class="cs-icon cs-icon-save"></i>
    <i *ngIf="processing" class="far fa-spinner fa-spin"></i> save
  </button>
</div>
