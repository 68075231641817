import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as lodash from 'lodash'
import { of } from 'rxjs'
import { tap } from 'rxjs/operators'

type HTTPGetOptions = Parameters<HttpClient['get']>[1]

@Injectable()
export class HttpGetRequestCacheService {
  private cache = new Map<string, any>()
  constructor(private http: HttpClient) {}

  cacheGetRequest<T = any>(url: string, options?: boolean | (HTTPGetOptions & { refresh?: boolean })) {
    const refresh = lodash.isBoolean(options) ? options : options?.refresh || false
    if (refresh) {
      this.cache.delete(url)
    }

    if (this.cache.has(url)) {
      return of(this.cache.get(url) as T)
    }

    options = lodash.isBoolean(options) ? {} : options
    return this.http.get<T>(url, options).pipe(
      tap((data) => {
        this.cache.set(url, data)
      })
    )
  }
}
