import { ComponentStore } from '@ngrx/component-store'
import { Injectable } from '@angular/core'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { EMPTY, Observable } from 'rxjs'
import { Params } from '@angular/router'
import { isNil } from 'ramda'
import { ProductAvailability, TokeetApp } from '../store/app/app.model'
import { AppService } from '../store/app/app.service'
import { Subscription } from '@tokeet-frontend/subscriptions'
import { Card } from '../store/card/card.model'
import { AppOverviewState } from './app-overview.state'

const DEFAULT_STATE: AppOverviewState = {
  app: {
    value: null,
    $loading: false,
  },
  availability: {
    value: null,
    $loading: false,
  },
  subscriptions: {
    value: [],
    $loading: false,
  },
  cards: {
    value: [],
    $loading: false,
  },
}

@Injectable()
export class AppOverviewStore extends ComponentStore<AppOverviewState> {
  constructor(private appsService: AppService) {
    super(DEFAULT_STATE)
  }

  readonly setLoading = this.updater(
    (state: AppOverviewState, params: { key: 'app' | 'availability' | 'subscriptions' | 'cards'; value: boolean }) => ({
      ...state,
      [params.key]: {
        ...state[params.key],
        $loading: params.value,
      },
    })
  )

  readonly setSubscriptions = this.updater(
    (
      state: AppOverviewState,
      {
        subscriptions,
      }: {
        subscriptions: Subscription[]
      }
    ) => ({
      ...state,
      subscriptions: {
        ...state.subscriptions,
        value: subscriptions,
      },
    })
  )

  readonly loadSubscriptions = this.effect((origin$: Observable<{ subscriptions: Subscription[] }>) =>
    origin$.pipe(
      tap((account: { subscriptions: Subscription[] }) => {
        this.setSubscriptions(account)
        this.setLoading({ key: 'subscriptions', value: false })
      })
    )
  )

  readonly setCards = this.updater((state: AppOverviewState, cards: Card[]) => ({
    ...state,
    cards: {
      ...state.cards,
      value: cards,
    },
  }))

  readonly loadCards = this.effect((origin$: Observable<Card[]>) =>
    origin$.pipe(
      tap((cards: Card[]) => {
        this.setCards(cards)
        this.setLoading({ key: 'cards', value: false })
      })
    )
  )

  readonly loadApp = this.effect((origin$: Observable<string>) =>
    origin$.pipe(
      tap(() => this.setLoading({ key: 'app', value: true })),
      switchMap((product) => this.appsService.getAppDetails(product)),
      tap((app: TokeetApp) => {
        this.setApp(app)
        this.setLoading({ key: 'app', value: false })
      })
    )
  )

  readonly loadAvailability = this.effect((origin$: Observable<TokeetApp>) =>
    origin$.pipe(
      filter((app) => !isNil(app)),
      tap(() => this.setLoading({ key: 'availability', value: true })),
      switchMap((app) =>
        this.appsService.getPlanAvailability(app.stripe_product_code).pipe(
          catchError(() => {
            this.setLoading({ key: 'availability', value: false })
            return EMPTY
          })
        )
      ),
      tap((availability: ProductAvailability) => {
        this.setAvailability(availability)
        this.setLoading({ key: 'availability', value: false })
      })
    )
  )

  isLoading = (key: 'app' | 'availability' | 'subscriptions' | 'cards') => this.select((state) => state[key].$loading)

  selectApp$ = this.select((state) => state.app.value)
  selectCards$ = this.select((state) => state.cards.value)
  selectSubscriptions$ = this.select((state) => state.subscriptions.value)
  selectAvailability$ = this.select((state) => state.availability.value)

  readonly setApp = this.updater((state: AppOverviewState, app: TokeetApp) => ({
    ...state,
    app: {
      ...state.app,
      value: app,
    },
  }))

  readonly setAvailability = this.updater((state: AppOverviewState, availability: ProductAvailability) => ({
    ...state,
    availability: {
      ...state.availability,
      value: availability,
    },
  }))
}
