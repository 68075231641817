import { createAction, props } from '@ngrx/store'
import { SharedContent } from '@tv3/store/shared-content/shared-content.model'

export const LoadSharedContent = createAction('[Shared Content] Load')
export const LoadSharedContentComplete = createAction(
  '[Shared Content] Load Complete',
  props<{ contents: SharedContent[] }>()
)

export const ChangeSharedContentPassword = createAction(
  '[Shared Content] Change Password',
  props<{ id: string; password: string }>()
)

export const UpdateSharedContent = createAction(
  '[Shared Content] Update',
  props<{ id: string; changes: any; successMessage?: string }>()
)
export const UpdateSharedContentComplete = createAction(
  '[Shared Content] Update Complete',
  props<{ id: string; item: SharedContent }>()
)

export const RemoveSharedContent = createAction('[Shared Content] Remove', props<{ id: string }>())
export const RemoveSharedContentComplete = createAction('[Shared Content] Remove Complete', props<{ id: string }>())

export const RemoveSharedContents = createAction('[Shared Content] Remove Multiple', props<{ ids: string[] }>())
export const RemoveSharedContentsComplete = createAction(
  '[Shared Content] Remove Multiple Complete',
  props<{ ids: string[] }>()
)
