<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-sticky-note"></i> {{ data ? 'Edit' : 'New' }} Comment</h5>
  <button (click)="close()" type="button" class="close" aria-label="Close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body p-0 bg-none m-0">
  <form [formGroup]="form" [formEditablePermission]="form">
    <mat-form-field2 [showLabel]="false">
      <textarea
        matInput
        #notes
        [maxlength]="maxLength"
        rows="8"
        formControlName="note"
        placeholder="Type your comments here.."
        cdkTextareaAutosize
      ></textarea>
      <mat-error *ngIf="form.get('note').touched && form.get('note').hasError('required')" class="pl-3">
        Comment is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="form.get('note').touched && form.get('note').hasError('maxLength')" class="pl-3">
        Comment must be <strong>at most 500</strong> characters long
      </mat-error>
      <mat-hint align="end" class="pr-3">{{ notes.value.length }} / {{ maxLength }}</mat-hint>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button *ngIf="data" (click)="delete()" type="button" class="btn btn-secondary-danger">
    <i class="cs-icon cs-icon-trash"></i> delete
  </button>
  <div class="flex-fill"></div>
  <button (click)="close()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-light">
    <i class="far fa-times"></i> Cancel
  </button>
  <button (click)="save(form)" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
