import * as lodash from 'lodash'
import { isUUID } from '@tokeet-frontend/tv3-platform'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'

export function isImportedFromABBV2(item: { inquiryId: string; attributes: any }) {
  return item?.attributes?.api === ChannelNameTokens.AirBnBV2API && !isUUID(item?.inquiryId)
}

export function isChannelCancelable(source: string) {
  const names = [ChannelNameTokens.RentalsUnited, ChannelNameTokens.Holidu]
  source = lodash.toLower(source)
  return !!names.find((n: string) => n.includes(source) || source.includes(n))
}
