import { Injectable } from '@angular/core'
import {
  BdcProductDialogComponent,
  BDCProductDialogParams,
} from '@tv3/containers/channels/content/bdc/dialogs/bdc-product-dialog/bdc-product-dialog.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable({
  providedIn: SharedModule,
})
export class BdcProductDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: BDCProductDialogParams) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { ...data },
    }

    return this.dialog.open(BdcProductDialogComponent, defaultConfig)
  }
}
