import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import * as moment from 'moment'
import * as R from 'ramda'

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
})
export class HistoryListComponent implements OnInit, OnChanges {
  @Output() edit = new EventEmitter()
  @Output() remove = new EventEmitter()

  @Input() set showCount(c: number) {
    this.showIndex = c ? c : 5
  }

  @Input() editable = true
  @Input() showMoreCount = 5
  @Input() userNameKey: string
  @Input() userId: string
  @Input() userImageKey: string
  @Input() defaultUserImage: string
  @Input() defaultUserName = 'Unknown'
  @Input() timeKey: string
  @Input() timeFormat = 'DD - MMM - YYYY hh:mm A'
  @Input() monthKey: string
  @Input() yearKey: string
  @Input() contentKey: string

  @Input() badgeKey: string

  @Input() iconKey: string
  @Input() defaultIcon: string

  @Input() set list(l: any[]) {
    this.itemList = l ? l.slice() : []

    if (this.timeKey) {
      this.defineTimeCaptions()
    }
  }

  itemList: any[]

  showIndex = 5

  constructor() {}

  ngOnInit() {
    // this.defineTimeCaptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.defineTimeCaptions()
  }

  showMore() {
    this.showIndex += this.showIndex
  }

  isMoreAvailable() {
    return this.itemList.length > this.showIndex
  }

  defineTimeCaptions() {
    const todayMonth = moment().format('MMMM')
    const todayYear = moment().format('YYYY')
    const lastMonth = moment().subtract(1, 'month').format('MMMM')
    const lastYear = moment().subtract(1, 'year').format('YYYY')

    let currentMonth = todayMonth
    let currentYear = todayYear
    let lastYearCaption = false
    let i = 0

    const toMoment = (item) =>
      R.is(Number, item[this.timeKey]) ? moment(item[this.timeKey] * 1000) : moment(item[this.timeKey])

    this.itemList = R.sortBy((item) => -toMoment(item), this.itemList)
    this.itemList = this.itemList.map((listItem) => {
      const item = { ...listItem }
      const timeDate = toMoment(item)
      const timestampMonth = this.monthKey ? item[this.monthKey] : timeDate.format('MMMM')
      const timestampYear = this.yearKey ? item[this.yearKey] : timeDate.format('YYYY')
      if (currentMonth !== timestampMonth || currentYear !== timestampYear) {
        if (currentMonth !== timestampMonth || currentYear === timestampYear) {
          item.uiTimeCaption = (timestampMonth === lastMonth ? 'Last Month - ' : '') + timestampMonth
        } else {
          item.uiTimeCaption =
            (lastYearCaption && timestampYear === lastYear ? 'Last Year - ' : '') +
            `${timestampMonth} - ${timestampYear}`
          lastYearCaption = true
        }
      }

      if (this.userImageKey) {
        item.uiUserImage = item[this.userImageKey]
      } else if (this.defaultUserImage) {
        item.uiUserImage = this.defaultUserImage
      }

      if (this.iconKey) {
        item.uiMainIcon = item[this.iconKey]
      } else if (this.defaultIcon) {
        item.uiMainIcon = this.defaultIcon
      }

      currentMonth = timestampMonth
      currentYear = timestampYear
      i++

      return item
    })
  }
}
