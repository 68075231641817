import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromAccount from './account.reducer'
import { EffectsModule } from '@ngrx/effects'
import { AccountEffects } from './account.effects'
import { AccountGuard } from './account.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('account', fromAccount.accountReducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
  providers: [AccountGuard],
  declarations: [],
})
export class AccountStoreModule {}
