import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromSharedContent from './shared-content.reducer'
import { SharedContentEffects } from '@tv3/store/shared-content/shared-content.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('shared-content', fromSharedContent.sharedContentReducer),
    EffectsModule.forFeature([SharedContentEffects]),
  ],
  declarations: [],
})
export class SharedContentModule {}
