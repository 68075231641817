import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { BookingFormulaResponse } from '@tokeet-frontend/booking-formula'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { BookingFormulaDialogComponent } from './booking-formula-dialog.component'

@Injectable()
export class BookingFormulaDialogService {
  constructor(private dialog: DialogService) {}

  open(data: { channel?: { id: string; name: string }; formula?: BookingFormulaResponse }) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: '550px',
      data,
    }

    return this.dialog.openVariableDialog(BookingFormulaDialogComponent, defaultConfig)
  }
}
