import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { PaymentRuleEffects } from '@tv3/store/payment-rule/payment-rule.effects'
import * as fromPaymentRule from './payment-rule.reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('payment-rule', fromPaymentRule.paymentRulesReducer),
    EffectsModule.forFeature([PaymentRuleEffects]),
  ],
  declarations: [],
})
export class PaymentRuleModule {}
