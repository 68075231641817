<div class="modal-header">
  <div class="d-flex align-items-center gap-2">
    <div class="modal-title">Connect To Rentals United</div>
    <app-page-help [articleId]="'-'" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<mat-stepper [linear]="true" #stepper class="m-3" [class.flexiable]="(status$ | async)?.connected">
  <mat-step
    label="Getting Started"
    [editable]="stepper.selectedIndex < 2"
    [state]="stepper.selectedIndex > 0 ? 'done' : 'number'"
  >
    <app-connect-ru-wizard-step1 (next)="stepper.selectedIndex = 1"></app-connect-ru-wizard-step1>
  </mat-step>
  <mat-step
    label="Select Rentals"
    [editable]="stepper.selectedIndex < 2"
    [state]="stepper.selectedIndex > 1 ? 'done' : 'number'"
  >
    <ng-template matStepContent>
      <app-connect-ru-wizard-step2
        (prev)="stepper.previous()"
        (next)="stepper.next()"
        [channel]="channel"
        #step2
      ></app-connect-ru-wizard-step2>
    </ng-template>
  </mat-step>
  <mat-step
    label="Complete"
    [editable]="stepper.selectedIndex < 2"
    [state]="stepper.selectedIndex > 2 ? 'done' : 'number'"
  >
    <app-connect-ru-wizard-complete (done)="close()"></app-connect-ru-wizard-complete>
  </mat-step>
  <ng-template matStepperIcon="done">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
</mat-stepper>
