<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/vrbo/tokeet-vrbo.png" alt="" height="45" />
        <div>
          <h6 class="mb-0">VRBO</h6>
          <div class="text-sm">
            Connect multiple VRBO accounts and sync bookings, prices, guest messages, and more instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3">
        Connection between AdvanceCM and VRBO, offering numerous advantages, including unparalleled reliability.
        Bookings, inquiries, requests, and guest conversations sync instantly. Connect as many VRBO accounts as needed,
        and AdvanceCM will automatically import your listings in seconds.
        <div class="d-flex gap-2 align-items-center mt-3">
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Listing Creation</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Rates</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Bookings</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Availabilities</span>
        </div>
      </div>
    </div>

    <div class="container-box shadow-none p-3">
      <h6>Read Carefully</h6>
      <ul class="pl-3 lh-lg">
        <li>Follow the step-by-step process to connect AdvanceCM with VRBO.</li>
        <li>Read any tooltips for guidance.</li>
      </ul>
      <h6>Proceed</h6>
      <ul class="pl-3 lh-lg">
        <li>After completing a step, click <b>CONNECT</b> to move to the next section.</li>
      </ul>

      <div class="alert alert-secondary-warning2 align-items-start">
        <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
        <div class="alert-content">
          <div class="text-semi-bold">Important Notes</div>
          <div>
            After enabling AdvanceCM rentals and selecting "initiate connection," you'll receive an email confirmation.
            Check your email for confirmation.
          </div>
          <div>
            Follow the next steps outlined in the guide provided. VRBO's onboarding team will contact you to finalize
            connections.
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Connect
          <i class="cs-icon cs-icon-link"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/vrbo/step1.png" alt="" class="w-100" />
  </div>
</div>
