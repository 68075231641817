import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { EMPTY, Observable, of } from 'rxjs'

import { concatMap, map, toArray } from 'rxjs/operators'
import * as R from 'ramda'

import { isSomething, epochToViewUTC, Pagination, Toaster } from '@tokeet-frontend/tv3-platform'
import {
  CreateInventoryPayload,
  InventoryResponse,
  InventoryStatus,
  InventoryView,
  SearchInventoryParams,
  UpdateInventoryPayload,
} from './model'

@Injectable()
export class InventoryService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all() {
    const url = `@api/inventory/all/`
    return this.http.get<InventoryResponse[]>(url).pipe(map((responses) => this.mapInventories(responses)))
  }

  search(searchParams?: SearchInventoryParams, pagination?: Pagination): Observable<InventoryView[]> {
    const url = `@api/inventory/retrieve/`

    pagination = pagination || { skip: 0, limit: 1000 }

    const filters: {
      status?: string | number
      rentals?: string | string[]
      categories?: string | string[]
      conditions?: string | string[]
    } = {}

    if (searchParams && isSomething(searchParams)) {
      if (searchParams?.status) {
        filters.status = searchParams?.status
      }
      if (isSomething(searchParams?.rentals)) {
        filters.rentals = searchParams?.rentals
      }
      if (isSomething(searchParams?.categories)) {
        filters.categories = searchParams?.categories
      }
      if (isSomething(searchParams?.conditions)) {
        filters.conditions = searchParams?.conditions
      }
    }

    // sort by inventory date
    const params = new HttpParams({
      fromObject: { skip: pagination.skip + '', limit: pagination.limit + '', sort: 'date', direction: '-1' },
    })

    return this.http
      .post<InventoryResponse[]>(url, { ...filters }, { params })
      .pipe(map((responses) => this.mapInventories(responses)))
  }

  get(id: string): Observable<InventoryView> {
    const url = `@api/incident/${id}`

    return this.http.get<InventoryResponse>(url).pipe(map((response) => this.buildInventory(response)))
  }

  create(request: CreateInventoryPayload): Observable<InventoryView> {
    const url = '@api/inventory/'

    return this.http.post<InventoryResponse>(url, request).pipe(map((response) => this.buildInventory(response)))
  }

  createInventories(payload: CreateInventoryPayload[]) {
    return of(...payload).pipe(
      concatMap((payload) => this.create(payload)),
      toArray()
    )
  }

  update(inventoryId: string, payload: UpdateInventoryPayload): Observable<InventoryView> {
    const url = `@api/inventory/${inventoryId}`

    return this.http.put<InventoryResponse>(url, payload).pipe(map((response) => this.buildInventory(response)))
  }

  updateInventories(items: { payload: UpdateInventoryPayload; inventoryId: string }[]) {
    if (items.length < 1) {
      this.toast.warning('Please select at least one inventory.')
      return EMPTY
    }
    return of(...items).pipe(
      concatMap(({ inventoryId, payload }) => this.update(inventoryId, payload)),
      toArray()
    )
  }

  delete(inventoryId: string) {
    const url = `@api/inventory/delete/${inventoryId}`

    return this.http.delete<InventoryResponse>(url)
  }

  deleteInventories(inventoryIds: string[]) {
    return of(...inventoryIds).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }

  private mapInventories(inventories: InventoryResponse[]): InventoryView[] {
    return R.map((r: InventoryResponse) => this.buildInventory(r), inventories)
  }

  private buildInventory(inventory: InventoryResponse): InventoryView {
    return {
      ...inventory,
      createdView: epochToViewUTC(inventory.created),
      statusView: Object.keys(InventoryStatus).find(
        (key) => String(InventoryStatus[key as keyof typeof InventoryStatus]) === String(inventory.status)
      ),
    } as InventoryView
  }
}
