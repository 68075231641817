import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { take } from 'rxjs/operators'
import { Channel, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { FormBuilder } from '@angular/forms'
import { ApiConnectionActivityTypes, ImportedConnectionActivityLog } from '@tv3/store/connection/connection.model'
import { Actions, ofType } from '@ngrx/effects'
import { ManualRefreshImportedCalendarConnectionComplete } from '@tv3/store/connection/connection.actions'

@Component({
  selector: 'app-imported-connection-log',
  templateUrl: './imported-connection-log.component.html',
  styleUrls: ['./imported-connection-log.component.scss'],
})
export class ImportedConnectionLogComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<ImportedConnectionActivityLog>()
  displayedColumns = ['created', 'status', 'expires', 'message']

  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() dateFormat = 'DD-MMM-YYYY | h:mm A'

  filters = this.fb.group({
    status: [''],
  })
  activityLogs: ImportedConnectionActivityLog[] = []

  constructor(private fb: FormBuilder, private actions: Actions, private connectionService: ConnectionService) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe((filters) => {
      this.onFiltersChanged(filters)
    })

    this.actions
      .pipe(ofType(ManualRefreshImportedCalendarConnectionComplete), untilDestroy(this))
      .subscribe(() => this.reloadData())

    this.reloadData()
  }

  onFiltersChanged(filters) {
    // @ts-ignore
    this.dataSource.data = R.pipe(
      R.filter((log: ImportedConnectionActivityLog) => {
        return !filters.status || log.status === filters.status
      })
    )(<any>this.activityLogs)
  }

  reloadData() {
    this.connectionService
      .getImportedConnectionLog(this.connection.id)
      .pipe(take(1))
      .subscribe((res) => {
        this.activityLogs = [...res]
        this.onFiltersChanged(this.filters.getRawValue())
      })
  }

  onRefreshLog() {
    this.reloadData()
  }

  protected readonly actionTypes = ApiConnectionActivityTypes
}
