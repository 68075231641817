import * as R from 'ramda'
import * as momentNs from 'moment'
const moment = momentNs

export function getNightsBetweenArrivalDepart(arrival, depart) {
  if (depart && arrival) {
    let toDate = R.is(Number, depart) ? depart * 1000 : depart
    let fromDate = R.is(Number, arrival) ? arrival * 1000 : arrival

    let to = moment.utc(toDate)
    let from = moment.utc(fromDate)

    // Set the beginning of each days the diff function seems to look at 24 hours as one day.
    to.hour(0).minute(0).second(0)
    from.hour(0).minute(0).second(0)

    let dayDiff = to.diff(from, 'days')

    if (R.is(Number, dayDiff)) {
      return dayDiff
    } else if (R.is(Number, dayDiff)) {
      return dayDiff + 1
    }
  } else {
    return 0
  }
}
