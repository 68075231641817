import { FormGroup } from '@angular/forms'
import { AutomationTypes } from './automation-services'

export enum AutomationDateRuleTypes {
  AfterBookingCreated = 'after.booking-created', // created
  AfterBookingConfirmed = 'after.booking-confirmed', // booked
  AfterBookingCanceled = 'after.booking-canceled', // canceled
  BeforeCheckIn = 'before.booking-start', // guest_arrive
  AfterCheckIn = 'after.booking-start', // guest_arrive
  BeforeCheckOut = 'before.booking-end', // guest_depart
  AfterCheckOut = 'after.booking-end', // guest_depart
}

export const automationDateRules: {
  type: AutomationDateRuleTypes
  label: string
  types: AutomationTypes[]
}[] = [
  {
    type: AutomationDateRuleTypes.AfterBookingCreated,
    label: 'After Inquiry Created',
    types: [AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    type: AutomationDateRuleTypes.AfterBookingConfirmed,
    label: 'After Booking Confirmed',
    types: [AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    type: AutomationDateRuleTypes.AfterBookingCanceled,
    label: 'After Booking Cancelled',
    types: [AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    type: AutomationDateRuleTypes.BeforeCheckIn,
    label: 'Before Booking Start',
    types: [AutomationTypes.Task, AutomationTypes.Expense, AutomationTypes.Booking],
  },
  {
    type: AutomationDateRuleTypes.AfterCheckIn,
    label: 'After Booking Start',
    types: [AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    type: AutomationDateRuleTypes.BeforeCheckOut,
    label: 'Before Booking End',
    types: [AutomationTypes.Task, AutomationTypes.Expense],
  },
  {
    type: AutomationDateRuleTypes.AfterCheckOut,
    label: 'After Booking End',
    types: [AutomationTypes.Task, AutomationTypes.Expense, AutomationTypes.Booking],
  },
]

export function getAutomationtDateRuleByType(type?: AutomationTypes) {
  if (!type) return automationDateRules
  return automationDateRules.filter((a) => a.types.includes(type))
}

export interface AutomationDate {
  base: string
  days: number
  hours: number
}

export function isValidAutomationDatePair(startDate: AutomationDate, endDate: AutomationDate) {
  const startBase = startDate.base
  const endBase = endDate.base

  if (!startBase || !endBase) return true
  const level1 = automationDateRules.findIndex((t) => t.type === startBase)
  const level2 = automationDateRules.findIndex((t) => t.type === endBase)
  if (level1 < level2) return true
  if (level1 > level2) return false

  const isBefore = startBase.includes('before')

  const startHours = startDate.days * 24 + (startDate.hours || 0)
  const endHours = endDate.days * 24 + (endDate.hours || 0)
  if (Number.isNaN(startHours) || Number.isNaN(endHours)) return true

  if (isBefore) {
    return startHours >= endHours
  } else {
    return startHours <= endHours
  }
}

export function validateAutomationDate(type: 'start' | 'end', pairDateForm: FormGroup, msg: string) {
  return (form: FormGroup) => {
    const date1 = form.getRawValue()
    const date2 = pairDateForm.getRawValue()
    const startDate = type === 'start' ? date1 : date2
    const endDate = type === 'end' ? date1 : date2
    if (!isValidAutomationDatePair(startDate, endDate)) {
      return { date: msg }
    }
    return null
  }
}
