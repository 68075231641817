<div class="modal-header">
  <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i> Import Inventories from CSV Files</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-csv-file-parser
    [columnDefs]="columnDefs"
    [guide]="guide"
    (parsed)="onCsvFilesParsed($event)"
  ></app-csv-file-parser>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="create()" [disabled]="!files?.length" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-upload"></i> upload
  </button>
</div>
