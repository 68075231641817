import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MessagingChannelKeys, MessagingChannelsConfig, WhatsappMessageTemplate } from './model'
import { map } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { CURRENT_ACCOUNT_ID, isSomething } from '@tokeet-frontend/tv3-platform'

export interface MessagingResponse<T> {
  http_code: string
  response: T
}

@Injectable()
export class MessagingChannelsService {
  constructor(private http: HttpClient, @Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>) {}

  enableChannel(type: MessagingChannelKeys) {
    const url = `@api/v1/message/register-channels?account=${this.accountId$.value}`

    return this.http.post<MessagingResponse<{ success: true }>>(url, {
      account: String(this.accountId$.value),
      [type]: {
        enabled: true,
      },
    })
  }

  unsubscribe(channel: MessagingChannelKeys) {
    const url = `@api/v1/message/${channel}/unsubscribe`

    return this.http.delete(url)
  }

  loginChannel(type: MessagingChannelKeys, pageType?: 'business' | 'personal') {
    let url = `@api/v1/message/${type}/login?account=${this.accountId$.value}`
    if (isSomething(pageType)) {
      url = `${url}&type=${pageType}`
    }

    return this.http.get<MessagingResponse<string>>(url).pipe(map((t) => t.response))
  }

  fetchChannels() {
    const url = `@api/v1/message/channels`

    return this.http.get<MessagingResponse<MessagingChannelsConfig>>(url).pipe(map((r) => r.response))
  }

  getWhatsappTemplates() {
    const url = `@api/v1/message/templates?channel=whatsapp-twilio`

    return this.http
      .get<MessagingResponse<{ templates: WhatsappMessageTemplate[] }>>(url)
      .pipe(map((r) => r.response?.templates || []))
  }
}
