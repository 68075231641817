import { GuestDetails } from '@tv3/models/guest/guest-details'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import {
  asUTCEpoch,
  ChannelNameTokens,
  epochToView,
  epochToViewUTC,
  Expose,
  getNightsBetweenArrivalDepart,
  isSomething,
  plainToClass,
  Rental,
  Serializable,
  Type,
} from '@tokeet-frontend/tv3-platform'
import { Guest } from '@tv3/store/guest/guest.model'
import { InquiryBilling } from '@tv3/models/inquiry/inquiry-billing'
import { NoteResponse } from '@tv3/store/note/note.model'
import { Message } from '@tv3/store/message/message.model'
import * as moment from 'moment'
import * as R from 'ramda'
import { timeAgo } from '@tv3/pipes/utility/time-ago.pipe'
import { Invoice, upgradeInvoiceTax } from '@tokeet-frontend/invoices'
import { InquiryCost } from '@tokeet/cost-resolver'

export const DefaultInquiryColor = '#ddd'

import { v4 as uuid } from 'uuid'
import { AirbnbReservationAlterationResponse } from '@tokeet-frontend/channels'

import { isUUID } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

export enum CheckInStatus {
  CheckedIn = 'checked-in',
  CheckedOut = 'checked-out',
  Arriving = 'pending',
}

export enum InquiryPaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  PartialPaid = 'Partial Paid',
}

export enum InquirySourceType {
  Agoda,
  Airbnb,
  Booking,
  Expedia,
  FlipKey,
  HolidayLettings,
  Tokeet,
  TokeetWebsite,
  TripVillas,
  Unknown,
  VacationHomeRentals,
  VRBO,
  Wimdu,
  Vrbo,
  HomeToGo,
  Marriott,
  GoogleVacationRentals,
}

export type InquirySourceView =
  | 'Agoda'
  | 'Airbnb'
  | 'Booking.com'
  | 'Expedia'
  | 'FlipKey'
  | 'Holiday Lettings'
  | 'AdvanceCM'
  | 'AdvanceCM Website'
  | 'Trip Villas'
  | 'Unknown'
  | 'Vacation Home Rentals'
  | 'VRBO'
  | 'Wimdu'
  | 'Vrbo'
  | 'HomeToGo'
  | 'Marriott'
  | 'Google Vacation Rentals'

export function parseInquirySource(inquiry: Inquiry): { type: InquirySourceType; view: InquirySourceView } {
  if (!isSomething(inquiry) || !isSomething(inquiry.inquirySource)) {
    return {
      view: null,
      type: null,
    }
  }

  const channels: { condition: RegExp; type: InquirySourceType; view: InquirySourceView }[] = [
    { condition: new RegExp('agoda', 'i'), type: InquirySourceType.Agoda, view: 'Agoda' },
    { condition: new RegExp('airbnb', 'i'), type: InquirySourceType.Airbnb, view: 'Airbnb' },
    { condition: new RegExp('booking', 'i'), type: InquirySourceType.Booking, view: 'Booking.com' },
    { condition: new RegExp('expedia', 'i'), type: InquirySourceType.Expedia, view: 'Expedia' },
    { condition: new RegExp('flipkey', 'i'), type: InquirySourceType.FlipKey, view: 'FlipKey' },
    { condition: new RegExp('holiday', 'i'), type: InquirySourceType.HolidayLettings, view: 'Holiday Lettings' },
    { condition: new RegExp('tokeetwebsite', 'i'), type: InquirySourceType.TokeetWebsite, view: 'AdvanceCM Website' },
    { condition: new RegExp('tokeet', 'i'), type: InquirySourceType.Tokeet, view: 'AdvanceCM' },
    { condition: new RegExp('trip', 'i'), type: InquirySourceType.TripVillas, view: 'Trip Villas' },
    { condition: new RegExp('unknown', 'i'), type: InquirySourceType.Unknown, view: 'Unknown' },
    {
      condition: new RegExp('google', 'i'),
      type: InquirySourceType.GoogleVacationRentals,
      view: 'Google Vacation Rentals',
    },
    {
      condition: new RegExp('vacation', 'i'),
      type: InquirySourceType.VacationHomeRentals,
      view: 'Vacation Home Rentals',
    },
    { condition: new RegExp('vrbo', 'i'), type: InquirySourceType.VRBO, view: 'Vrbo' },
    { condition: new RegExp('wimdu', 'i'), type: InquirySourceType.Wimdu, view: 'Wimdu' },
    { condition: new RegExp('vrbo', 'i'), type: InquirySourceType.Vrbo, view: 'Vrbo' },
    { condition: new RegExp('hometogo', 'i'), type: InquirySourceType.HomeToGo, view: 'HomeToGo' },
    { condition: new RegExp('marriott', 'i'), type: InquirySourceType.Marriott, view: 'Marriott' },
    { condition: <any>{ test: () => true }, type: InquirySourceType.Unknown, view: <any>inquiry.inquirySource },
  ]

  for (let i = 0; i < channels.length; i++) {
    if (channels[i].condition.test(inquiry.inquirySource)) {
      return {
        type: channels[i].type,
        view: channels[i].view,
      }
    }
  }
}

export class Inquiry extends Serializable<Inquiry> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'guest_depart' })
  guestDepart: number

  @Expose({ name: 'ref_id' })
  refId: string

  @Expose({ name: 'inquiry_id' })
  inquiryId: string

  @Expose({ name: 'guest_details' })
  @Type(() => GuestDetails)
  guestDetails: GuestDetails

  @Expose({ name: 'booking_engine' })
  @Type(() => BookingEngineCost)
  bookingEngine: BookingEngineCost

  @Expose({ name: 'check_out' })
  checkOut: number

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Type(() => Rental)
  rental: Rental

  @Expose({ name: 'booking_id' })
  bookingId: string

  @Expose({ name: 'num_child' })
  numChild: number

  @Expose({ name: 'num_adults' })
  numAdults: number

  num_pets: number

  @Expose({ name: 'inquiry_source' })
  inquirySource: string

  sourceType: InquirySourceType
  sourceView: InquirySourceView

  @Expose({ name: 'abb_price' })
  abbPrice: any

  @Expose({ name: 'convo_id' })
  convoId: string

  @Expose({ name: 'received_on' })
  receivedOn: number

  @Expose({ name: 'guest_arrive' })
  guestArrive: number

  @Expose({ name: 'guest_id' })
  guestId: string

  @Expose({ name: 'check_in' })
  checkIn: number

  @Expose({ name: 'processed_date' })
  processedDate: number

  @Expose({ name: 'automata_logs' })
  automataLogs: object[]

  @Expose({ name: 'message_txt' })
  messageTxt: string

  @Expose({ name: 'message_id' })
  messageId: string

  @Expose({ name: 'property_id' })
  propertyId: number

  @Expose({ name: 'inquiry_logs' })
  inquiryLogs: object[]

  @Expose({ name: 'last_message_at' })
  lastMessageAt: number

  @Expose({ name: 'reference_id' })
  referenceId: number

  @Expose({ name: 'guest_name' })
  guestName: string

  @Expose({ name: 'disable_guest_portal' })
  disableGuestPortal: 0 | 1

  @Type(() => Guest)
  guest: Guest

  replyto: string
  quotes: string
  logs: object[]
  read: number
  archived: number
  closed: number
  touch: number
  created: number
  canceled: number
  attributes: any
  booked: number
  account: number
  cost: string

  formulaCost?: number

  skip: boolean //@todo - remove this

  notes: NoteResponse[]

  paid: number

  sentto: string
  followup: number

  @Type(() => Invoice)
  invoices: Invoice[]

  @Type(() => Message)
  messages: Message[]

  @Type(() => InquiryBilling)
  billing: InquiryBilling

  status: InquiryStatus
  links: string[]
  payout: number | string

  charges: InquiryCost

  discountFees: any

  /* View Helpers */
  rentalView: string
  touchView: string
  receivedOnView: string
  guestNameView: string
  arriveView: string
  departView: string
  nightsView: number
  guestsView: string
  bookedView: string
  createdView: string
  channelBookingIdView: string

  /* Thread View Helpers */
  lastMessageAgo = ''
  checkInStatus: CheckInStatus = CheckInStatus.Arriving
  guestCount: number

  guestArriveTimeView?: string
  guestDepartTimeView?: string

  alteration_requested?: AirbnbReservationAlterationResponse

  static deserialize(data: any): Inquiry {
    if (R.is(String, data.touch)) {
      data.touch = asUTCEpoch(data.touch)
    }
    const inquiry: Inquiry = plainToClass<Inquiry, Inquiry>(Inquiry, data)
    if (inquiry.bookingEngine) {
      inquiry.bookingEngine = BookingEngineCost.deserialize(data.booking_engine)
    }

    inquiry.checkIn = parseInt((<any>inquiry).checkIn, 10)
    inquiry.checkOut = parseInt((<any>inquiry).checkOut, 10)
    inquiry.numAdults = parseInt((<any>inquiry).numAdults, 10)
    inquiry.numChild = parseInt((<any>inquiry).numChild, 10)

    inquiry.guestArrive = parseInt((<any>inquiry).guestArrive, 10)
    inquiry.guestDepart = parseInt((<any>inquiry).guestDepart, 10)

    if (isSomething(inquiry.invoices)) {
      inquiry.invoices = R.map((i) => upgradeInvoiceTax(i), inquiry.invoices || [])
    }

    const parsedSource = parseInquirySource(inquiry)

    inquiry.sourceType = parsedSource.type
    inquiry.sourceView = parsedSource.view

    if (inquiry.lastMessageAt) {
      inquiry.lastMessageAgo = timeAgo(inquiry.lastMessageAt)
    }

    inquiry.checkInStatus = getCheckInStatus(inquiry)

    inquiry.inquirySource = (inquiry.inquirySource + '').toLowerCase()

    inquiry.guestCount = (inquiry.numAdults || 0) + (inquiry.numChild || 0)

    inquiry.guestNameView = lodash.trim(R.pathOr('', ['name'], inquiry.guestDetails))

    inquiry.touchView = epochToView(inquiry.touch)
    inquiry.receivedOnView = epochToView(inquiry.receivedOn)
    inquiry.arriveView = epochToViewUTC(inquiry.guestArrive)
    inquiry.departView = epochToViewUTC(inquiry.guestDepart)
    inquiry.bookedView = epochToView(inquiry.booked)
    inquiry.createdView = epochToView(inquiry.created)
    inquiry.channelBookingIdView = inquiry.id !== inquiry.inquiryId ? inquiry.inquiryId : ''

    inquiry.nightsView = getNightsBetweenArrivalDepart(inquiry.guestArrive, inquiry.guestDepart)
    inquiry.guestsView = `${R.defaultTo(0, inquiry.numAdults) + R.defaultTo(0, inquiry.numChild)}`

    if (R.isEmpty(inquiry.notes) && inquiry.inquiryId !== inquiry.id) {
      const note = {} as NoteResponse
      note.key = uuid()
      note.note = `The ${inquiry.inquirySource} booking id is ${inquiry.inquiryId}`
      note.timestamp = inquiry.processedDate
      note.user = inquiry.inquirySource
      inquiry.notes = [note]
    }

    return inquiry
  }
}

export function isBookingFromAirbnb(inquiry: Inquiry) {
  if (!inquiry) return false
  return inquiry.id !== inquiry.inquiryId && /airbnb/gi.test(inquiry.inquirySource)
}

export function isReadOnlyBooking(inquiry: Inquiry) {
  return inquiry?.inquirySource === 'marriott'
}

export function isInquiryFromAirbnb(inquiry: Inquiry) {
  if (!inquiry) return false
  return (
    inquiry.id === inquiry.inquiryId &&
    /airbnb/gi.test(inquiry.inquirySource) &&
    !isUUID(inquiry.convoId) &&
    lodash.isNumber(inquiry.convoId)
  )
}

export interface LoadInquiriesFilter {
  rentals?: string[]
  source?: string
  status?: string
  period?: {
    from: number
    to: number
  }
}

export enum InquiryStatusEnum {
  Booked = 'booked',
  Canceled = 'canceled',
  Inquiry = 'inquiry',
}

export type InquiryStatus = 'preapproved' | 'inquiry' | 'denied' | 'deny' | 'accept' | 'pending' | 'cancelled_by_guest'

export function getCheckInStatus(inquiry: Inquiry) {
  const isCheckedIn = R.path(['attributes', 'is_checked_in'], inquiry)
  const isCheckedOut = R.path(['attributes', 'is_checked_out'], inquiry)

  if (isCheckedOut) {
    return CheckInStatus.CheckedOut
  }
  if (isCheckedIn) {
    return CheckInStatus.CheckedIn
  }

  let checkIn, checkOut
  if (isSomething(inquiry.checkIn) && isSomething(inquiry.checkOut)) {
    checkIn = inquiry.checkIn
    checkOut = inquiry.checkOut
  } else if (isSomething(inquiry.guestArrive) && isSomething(inquiry.guestDepart)) {
    checkIn = inquiry.guestArrive
    checkOut = inquiry.guestDepart
  }

  const currentTime = moment().unix()

  if (!checkIn || !checkOut || currentTime < checkIn) {
    return CheckInStatus.Arriving
  } else if (currentTime >= checkOut) {
    return CheckInStatus.CheckedOut
  } else if (currentTime < checkOut && currentTime >= checkIn) {
    return CheckInStatus.CheckedIn
  }
}
