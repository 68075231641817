import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { SmartDevice, SmartDeviceSettingsResponse } from './model'

export interface State extends EntityState<SmartDevice> {
  isLoaded: boolean
  settings?: SmartDeviceSettingsResponse
  isSettingsLoaded: boolean
}

export const adapter: EntityAdapter<SmartDevice> = createEntityAdapter<SmartDevice>({
  selectId: (item) => item.deviceId,
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isSettingsLoaded: false,
})
