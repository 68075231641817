import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ExportCalendarDialogComponent } from './export-calendar-dialog.component'
import { Channel, Rental } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class ExportCalendarDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: { channel?: Channel; rental?: Rental }) {
    const defaultConfig: MatDialogConfig = {
      width: '450px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: {
        ...data,
      },
    }

    return this.dialog.open(ExportCalendarDialogComponent, defaultConfig)
  }
}
