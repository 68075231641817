export interface State {
  isLoaded: boolean
  id: string
  website: string[]
  rental: string[]
  guest: string[]
  invoice: string[]
  inquiry: string[]
}

export const initialState: State = {
  isLoaded: false,
  id: null,
  website: [],
  rental: [],
  guest: [],
  invoice: [],
  inquiry: [],
}
