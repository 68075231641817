import {
  AddGuestComplete,
  AddGuestsComplete,
  AddTagsToGuestComplete,
  ArchiveGuestComplete,
  DeleteGuestComplete,
  DeleteGuestMessageComplete,
  DeleteGuestNoteComplete,
  DeleteGuestsComplete,
  FetchGuestsComplete,
  GetGuestBookingsComplete,
  GetGuestComplete,
  GetGuestEmailsComplete,
  LoadGuestsComplete,
  SearchElasticGuestsComplete,
  SearchGuestsComplete,
  UnarchiveGuestComplete,
  UpdateGuestComplete,
  UpdateGuestsComplete,
} from './guest.actions'
import { adapter, initialState, State } from './guest.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import { upsertMany } from '@tokeet-frontend/tv3-platform'
import { GetGuestBookings } from '@tv3/store/guest/guest.actions'

export const reducer = createReducer(
  initialState,
  on(LoadGuestsComplete, (state, { guests, pagination }) =>
    upsertMany(adapter, guests, R.merge(state, { isLoaded: true, pagination }))
  ),
  on(SearchGuestsComplete, SearchElasticGuestsComplete, FetchGuestsComplete, (state, { guests }) =>
    adapter.upsertMany(guests, state)
  ),
  on(GetGuestComplete, (state, { guest }) => adapter.setOne(guest, state)),
  on(AddGuestComplete, (state, { guest }) => adapter.addOne(guest, state)),
  on(AddGuestsComplete, (state, { guests }) => adapter.addMany(guests, state)),
  on(UpdateGuestsComplete, (state, { updates }) => adapter.updateMany(updates, state)),
  on(GetGuestBookings, (state) => ({ ...state, isLoadingGuestBookings: true })),
  on(GetGuestBookingsComplete, (state, { update }) =>
    adapter.updateOne(update, { ...state, isLoadingGuestBookings: false })
  ),
  on(
    UpdateGuestComplete,
    ArchiveGuestComplete,
    UnarchiveGuestComplete,
    GetGuestEmailsComplete,
    DeleteGuestMessageComplete,
    AddTagsToGuestComplete,
    DeleteGuestNoteComplete,
    (state, { update }) => adapter.updateOne(update, state)
  ),
  on(DeleteGuestComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteGuestsComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function guestReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
