<div class="d-flex gap-3">
  <div class="flex-fill d-flex flex-column gap-2">
    <div class="container-box shadow-none">
      <div class="p-3 d-flex align-items-center gap-3">
        <img src="/assets/images/channel-wizards/trip/tokeet-trip.png" alt="" height="45" />
        <div>
          <h6 class="mb-0">Trip.com</h6>
          <div class="text-sm">
            Connect multiple Trip.com accounts and sync bookings, prices, guest messages, and more instantly.
          </div>
        </div>
      </div>
      <div class="border-top p-3">
        Connection between AdvanceCM and Trip.com, offering numerous advantages, including unparalleled reliability.
        Bookings, inquiries, requests, and guest conversations sync instantly. Connect as many Trip.com accounts as
        needed, and AdvanceCM will automatically import your listings in seconds.
        <div class="d-flex gap-2 align-items-center mt-3">
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Listing Creation</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Rates</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Bookings</span>
          <span class="bg-active2 badge-pill text-sm px-3 py-1">Availabilities</span>
        </div>
      </div>
    </div>

    <div class="container-box shadow-none p-3">
      <h6>How to connect to Trip.com</h6>
      <div>
        Trip.com is one of Tokeet’s preferred partners. This means we import your listings from Trip.com and allow you
        to connect them to other platforms like Booking.com, Vrbo (HomeAway), and more.
      </div>
    </div>

    <div class="container-box shadow-none">
      <div class="p-3">
        <h6>Connecting to Trip.com with AdvanceCM is easy and efficient</h6>
        <div>
          Use the 'Connect' option for a detailed process walkthrough. <br />
          For a detailed guide, <a href="">click here for a step-by-step guide</a>.
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-3 border-top p-3">
        <button class="btn btn-secondary-info" (click)="next.emit()">
          Connect
          <i class="cs-icon cs-icon-link"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4">
    <img src="/assets/images/channel-wizards/trip/step1.png" alt="" class="w-100" />
  </div>
</div>
