<form [formGroup]="form" class="preference-form">
  <div class="row">
    <!-- <div class="col-sm-4">
      <mat-checkbox
        matTooltip="Allow this House Staff to see guest name"
        matTooltipPosition="above"
        formControlName="guest_name"
      >
        Show Guest Name
      </mat-checkbox>
    </div> -->
    <div class="col-sm-4">
      <mat-checkbox
        matTooltip="Allow this House Staff to see guest email"
        matTooltipPosition="above"
        formControlName="guest_email"
      >
        Show Guest Email
      </mat-checkbox>
    </div>
    <div class="col-sm-4">
      <mat-checkbox
        matTooltip="Allow this House Staff to see guest phone"
        matTooltipPosition="above"
        formControlName="guest_phone"
      >
        Show Guest Phone
      </mat-checkbox>
    </div>
  </div>
</form>
