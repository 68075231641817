import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Channel, Destroyable, Rental, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { ActivateGVRListing, DeactivateGVRListing } from '@tokeet-frontend/channels'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { BehaviorSubject } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { PushRatesToConnection } from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { FormSwitchComponent } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-gvr-connection-overlay',
  templateUrl: './gvr-connection-overlay.component.html',
  styleUrls: ['./gvr-connection-overlay.component.scss'],
})
export class GVRConnectionOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('toggle') toggle: FormSwitchComponent
  channel: Channel
  connection: ConnectionView
  dateFormat = 'DD-MMM-YYYY | h:mm A'

  refreshLogs$ = new BehaviorSubject<boolean>(true)
  logs$ = this.refreshLogs$.pipe(
    switchMap(() => this.connectionService.getApiConnectionLog(this.channel.name, this.connection.roomId))
  )

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private connectionService: ConnectionService,
    private connectionActionService: ConnectionHelperService,
    public dialogRef: MatDialogRef<GVRConnectionOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { connection: ConnectionView }
  ) {
    super()
  }

  ngOnInit() {
    this.connection = this.data.connection
    this.channel = this.data.connection.channel
    this.actions.pipe(ofType(ActionFailed), untilDestroy(this)).subscribe(() => {
      this.toggle.value = !!this.connection.status
    })
  }

  close() {
    this.dialogRef.close()
  }

  onPushRates() {
    this.connectionActionService
      .checkBeforePushRates(this.connection.rental, this.connection)
      .pipe(
        tap(() => {
          this.store.dispatch(
            PushRatesToConnection({
              connId: this.connection.id,
              channelId: this.connection.channelId,
              channelName: this.channel.name,
              data: {
                roomId: this.connection.roomId,
              },
            })
          )
        })
      )
      .subscribe()
  }

  onPushAvailability() {
    this.connectionActionService.pushAvailability(this.channel.name, this.connection).subscribe()
  }

  onToggleStatus() {
    if (this.connection?.status) {
      this.store.dispatch(DeactivateGVRListing({ rentalId: this.connection.rentalId }))
    } else {
      this.store.dispatch(ActivateGVRListing({ rentalId: this.connection.rentalId }))
    }
  }

  onRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
