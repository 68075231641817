import {
  HomeAwayChannelConfig,
  HomeAwayRentalConfig,
  HomeToGoChannelConfig,
  HomeToGoRentalConfig,
} from './channels.model'
import { Dictionary } from '@ngrx/entity'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { GVRListingModel, GVRStatus } from './gvr/model'
import { RentalsUnitedStatus, RentalsUnitedListingModel } from './rentals-united/model'
import { HoliduStatus, HoliduListingModel } from './holidu'
import { HvmiListingModel, HvmiStatus } from './hvmi'
import { ListingsValidationFieldResult } from './rental-validation-field'
import { CtripState } from './ctrip/state'
import { TicketState } from './tiket/state'

export interface State {
  configLoaded: Dictionary<boolean>
  rentalConfigs: Dictionary<(HomeToGoRentalConfig | HomeAwayRentalConfig)[]>
  configs: Dictionary<HomeToGoChannelConfig | HomeAwayChannelConfig>
  hvmi: {
    status: HvmiStatus
    listings: HvmiListingModel[]
    validations: ListingsValidationFieldResult
    validationsLoaded: boolean
  }
  rentalUnited: {
    status: RentalsUnitedStatus
    listings: RentalsUnitedListingModel[]
    validations: ListingsValidationFieldResult
    validationsLoaded: boolean
  }
  holidu: {
    status: HoliduStatus
    listings: HoliduListingModel[]
    validations: ListingsValidationFieldResult
    validationsLoaded: boolean
  }
  gvr: {
    status: GVRStatus
    listings: GVRListingModel[]
    validations: ListingsValidationFieldResult
    validationsLoaded: boolean
  }
  ctrip: CtripState
  tiket: TicketState
}

export const initialState: State = {
  configLoaded: {
    [ChannelNameTokens.HomeToGo]: false,
    [ChannelNameTokens.Homeaway]: false,
  },
  configs: {},
  rentalConfigs: {},
  hvmi: {
    status: null,
    listings: [],
    validations: {},
    validationsLoaded: false,
  },
  rentalUnited: {
    status: null,
    listings: [],
    validations: null,
    validationsLoaded: false,
  },
  holidu: {
    status: null,
    listings: [],
    validations: {},
    validationsLoaded: false,
  },
  gvr: {
    status: null,
    listings: [],
    validations: {},
    validationsLoaded: false,
  },
  tiket: {
    status: null,
    listings: [],
  },
  ctrip: {
    status: null,
    listings: [],
  },
}
