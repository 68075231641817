import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  AirbnbDeclineBookingPayload,
  AirbnbNotificationItem,
  AirbnbOpportunityResponse,
  AirbnbUpdateSyncCategoryPayload,
} from './model'
import { HttpGetRequestCacheService } from '@tokeet-frontend/tv3-platform'
import { AirbnbHost2GuestReviewPayload, AirbnbHost2GuestReviewResponse, AirbnbReviewSubmittedResponse } from './review'
import { Observable } from 'rxjs'
import { AirbnbBookingSettingsResponse, AirbnbUpdateBookingSettingsPayload } from './booking_settings'
import { AirbnbCancelBookingPayload, CancellationByHostReasonsResponse } from './cancellations'
import { map } from 'rxjs/operators'
import { AirbnbCheckinType } from './checkin_type'
import {
  AirbnbReservationAlterationResponse,
  AirbnbReservationAlterationPriceQuotePayload,
  AirbnbReservationAlterationPriceQuoteResponse,
} from './alteration'
import { AirbnbDeactivationReasonFlowResponse } from './deactivation'

@Injectable({
  providedIn: 'root',
})
export class AirbnbChannelService {
  constructor(private http: HttpClient, private httpCache: HttpGetRequestCacheService) {}

  getListingReviews(listingId: string | number, refresh = false) {
    // room id
    const url = `@api/abbv2/listing/reviews/${listingId}`
    return this.httpCache.cacheGetRequest<AirbnbReviewSubmittedResponse[]>(url, refresh)
  }

  getHostNotifications(hostId: string | number, refresh = false) {
    // property Id
    const url = `@api/abbv2/host/notifications/${hostId}`
    return this.httpCache.cacheGetRequest<AirbnbNotificationItem[]>(url, refresh)
  }

  getListingOpportunities(listingId: string | number, refresh = false) {
    // room Id
    const url = `@api/abbv2/host/opportunities/${listingId}`
    return this.httpCache.cacheGetRequest<AirbnbOpportunityResponse>(url, refresh)
  }

  getOpportunities(propertyId: any, listingId?: string | number | null, refresh = false) {
    let url = `@api/abbv2/host/opportunities?property_id=${propertyId}`
    if (listingId) {
      url += `&listing_id=${listingId}`
    }

    return this.httpCache
      .cacheGetRequest<{ data: AirbnbOpportunityResponse; next_cursor: string | null }>(url, refresh)
      .pipe(map(({ data }) => data))
  }

  setListingOpportunity(listingId: string | number, opportunityType: string, payload: any) {
    const url = `@api/abbv2/host/opportunities/${listingId}?type=${opportunityType}`
    return this.http.put<AirbnbOpportunityResponse>(url, payload)
  }

  setOpportunity(opportunityType: string, propertyId: string, payload: any) {
    const url = `@api/abbv2/host/opportunities?type=${opportunityType}&property_id=${propertyId}`
    return this.http.put<AirbnbOpportunityResponse>(url, payload)
  }

  acceptBooking(code: string) {
    const url = `@api/abbv2/reservation/${code}/accept`
    return this.http.put<any>(url, {})
  }

  declineBooking(code: string, payload: AirbnbDeclineBookingPayload) {
    const url = `@api/abbv2/reservation/${code}/deny`
    return this.http.put<any>(url, payload)
  }

  getBookingReview(confirmCode: string) {
    const url = `@api/abbv2/host/review/${confirmCode}`
    return this.http.get<AirbnbHost2GuestReviewResponse>(url)
  }

  getGuestReview(confirmCode: string) {
    const url = `@api/abbv2/guest/review/${confirmCode}`
    return this.http.get<AirbnbHost2GuestReviewResponse>(url)
  }

  setGuestReview(confirmCode: string, payload: AirbnbHost2GuestReviewPayload) {
    const url = `@api/abbv2/guest/review/${confirmCode}`
    return this.http.put<AirbnbReviewSubmittedResponse>(url, payload)
  }

  respondReview(listingId: string, reviewId: string, message: string) {
    const url = `@api/abbv2/listing/${listingId}/review/${reviewId}/respond`
    return this.http.put<any>(url, { reviewee_response: message })
  }

  getBookingSettingsV2(channelId: string, propertyId: any, roomId: any): Observable<AirbnbBookingSettingsResponse> {
    const url = `@api/abbv2/booking/settings/${propertyId}/${roomId}/${channelId}`
    return this.http.get<any>(url)
  }

  updateBookingSettingsV2(payload: AirbnbUpdateBookingSettingsPayload) {
    const url = `@api/abbv2/booking/settings`
    return this.http.put(url, payload)
  }

  getListingSyncCategory(propertyId: any, roomId: any) {
    const url = `@api/abbv2/listing/detail?listing=${roomId}&property=${propertyId}&original_data=1&attributes=synchronization_category`
    return this.http.get<{ synchronization_category: string }>(url)
  }

  updateListingSyncCategory(payload: AirbnbUpdateSyncCategoryPayload) {
    const url = `@api/abbv2/sync/category`
    return this.http.put<{ synchronization_category: string }>(url, payload)
  }

  cancelBooking(code: string, payload: AirbnbCancelBookingPayload) {
    const url = `@api/abbv2/reservation/${code}/cancel`
    return this.http.put<any>(url, payload)
  }

  getCancellationReasons(code: string, cancelBy = 'cancellation_by_host') {
    const url = `@api/abbv2/reservation/${code}?attributes=${cancelBy}`
    return this.http.get<CancellationByHostReasonsResponse>(url)
  }

  getCheckinTypes() {
    const url = `@api/rental/checkin_types/all`
    return this.httpCache.cacheGetRequest<AirbnbCheckinType[]>(url)
  }

  getReservationAlterationById(alterationId: string) {
    const url = `@api/abbv2/reservation/alteration/${alterationId}`
    return this.http.get<AirbnbReservationAlterationResponse>(url)
  }

  updateReservationAlterationById(alterationId: string, action: 'accept' | 'decline' | 'ignore' | 'cancel') {
    const url = `@api/abbv2/reservation/alteration/${alterationId}/${action}`
    return this.http.put(url, {})
  }

  getAlterationPriceQuote(code: string, payload: AirbnbReservationAlterationPriceQuotePayload) {
    const url = `@api/abbv2/price/quote/reservation/${code}`
    return this.http
      .put<{ price_quote: AirbnbReservationAlterationPriceQuoteResponse }>(url, payload)
      .pipe(map(({ price_quote }) => price_quote))
  }

  initAlteration(code: string, payload: AirbnbReservationAlterationPriceQuotePayload) {
    const url = `@api/inquiry/${code}/alteration`
    const { start_date, end_date, ...guest_details } = payload
    return this.http.post(url, {
      start_date,
      end_date,
      guest_details,
    })
  }

  getPropertyDeactivationReasons(propertyId: any) {
    const url = `@api/abbv2/flow/${propertyId}/listing_deactivation`
    return this.http.get<{ flows: AirbnbDeactivationReasonFlowResponse[] }>(url).pipe(map((data) => data.flows[0]))
  }
}
