import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { fromPromise } from 'rxjs/internal-compatibility'
import { Dictionary } from 'ramda'
import { Store } from '@ngrx/store'
import { ActionFailed, ENVIRONMENT } from '@tokeet-frontend/tv3-platform'
import { TokeetApp, ProductAvailability } from './app.model'
import * as R from 'ramda'
import { Card } from '../card/card.model'
import { WebreadySubscription, RategeniePlanPrice } from './app-sub.model'
import { Subscription } from '@tokeet-frontend/subscriptions'

@Injectable()
export class AppService {
  stripe

  constructor(
    private readonly http: HttpClient,
    private store: Store<any>,
    @Inject(ENVIRONMENT) private environment: any
  ) {}
  getApps(): Observable<TokeetApp[]> {
    return this.http.get<TokeetApp[]>('@api/app/all')
  }

  getWebreadySubscription() {
    return this.http.get<WebreadySubscription>(`@api/webready/subscriptions/current`)
  }

  parseWebreadySubscription(subscriptions: Subscription[]) {
    const webreadySubscriptions = R.filter((s) => s.product === 'webready', subscriptions)
    const subscriptionsWithoutWebready = R.reject((s) => s.product === 'webready', subscriptions)
    if (!!R.find((s) => s.plan_id === 'webready_v4', webreadySubscriptions)) return subscriptions

    if (!R.isEmpty(webreadySubscriptions)) {
      const single = R.find((s) => s.metadata?.type === 'single_rental_website', webreadySubscriptions)
      const multi = R.find((s) => s.metadata?.type === 'multi_rental_website', webreadySubscriptions)
      const templates = R.find((s) => s.metadata?.type === 'premium_templates', webreadySubscriptions)
      const unlimited = R.find((s) => s.metadata?.type === 'unlimited', webreadySubscriptions)
      let price = 0
      if (unlimited) {
        price = unlimited.price
      } else {
        if (single) {
          price += single.price * single.quantity
        }
        if (multi) {
          price += multi.price * multi.quantity
        }
        if (templates) {
          price += templates.price
        }
      }
      const created = !!unlimited ? unlimited.created : single.created
      const start = !!unlimited ? unlimited.start : single.start
      const timestamp = !!unlimited ? unlimited.timestamp : single.timestamp
      const trialEnd = !!unlimited ? unlimited.trial_end : single.trial_end
      const trialStart = !!unlimited ? unlimited.trial_start : single.trial_start
      const until = !!unlimited ? unlimited.until : single.until

      const webreadySubscription = {
        gateway: 'stripe',
        plan: 'webready_v1',
        plan_id: 'webready_v1',
        plan_name: 'Webready',
        price,
        created,
        product: 'webready',
        rentals: 0,
        start,
        timestamp,
        trial_end: trialEnd,
        trial_start: trialStart,
        txn: null,
        until,
        users: 1,
        status: 'active',
        cancel_at_period_end: R.any((s) => s.cancel_at_period_end, webreadySubscriptions),
        metadata: {
          users: 1,
          pricing: 'rental-brackets',
          product: 'webready',
          value: 1,
          apps: 'tokeet,sympl',
          rentals: 1,
        },
      } as unknown as Subscription
      return [...subscriptionsWithoutWebready, webreadySubscription]
    }
    return subscriptions
  }

  migrateToSympl(planId: string) {
    const url = `@api/subscription/migrate/sympl`

    return this.http.post(url, { plan: planId })
  }

  subscribeWebready() {
    const url = `@api/webready/subscriptions`
    return this.http
      .post(url, {
        tier: 'free',
        quantity: {
          single_rental_website: 0,
          multi_rental_website: 0,
        },
      })
      .pipe(
        catchError((error) => {
          this.store.dispatch(ActionFailed({ error }))
          return EMPTY
        })
      )
  }

  getSmartDevicePrice() {
    const url = `@api/plans/price/smart_devices`
    return this.http.get<{ amount: number }>(url).pipe(map((response) => response.amount))
  }

  getRategeniePrices(): Observable<RategeniePlanPrice[]> {
    const url = `@api/plans/price/rategenie`

    return this.http.get<{ tiers: RategeniePlanPrice[] }>(url).pipe(map((response) => response.tiers))
  }

  isWebreadyAddon(product: string): boolean {
    return (
      product === 'webready_messaging' ||
      product === 'webready_automations' ||
      product === 'webready_translator' ||
      product === 'webready_invoicing' ||
      product === 'booking_engine' ||
      product === 'webready_airbnb_connector' ||
      product === 'webready_edocuments'
    )
  }

  subscribeWebreadyAddon(product: string, plan: string) {
    const url = `@api/webready/subscriptions/addons`

    return this.http
      .post(url, {
        product,
        plan,
      })
      .pipe(
        catchError((error) => {
          this.store.dispatch(ActionFailed({ error }))
          return EMPTY
        })
      )
  }

  unsubscribeWebreadyAddon(product: string) {
    const url = `@api/webready/subscriptions/addons/${product}`

    return this.http.delete(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getSubscriptions() {
    const url = '@api/subscribe/all/fresh'

    return this.http
      .get<{
        card: Card
        subscriptions: Subscription[]
      }>(url)
      .pipe(
        map((result) => ({
          ...result,
          subscriptions: this.parseWebreadySubscription(result.subscriptions),
        }))
      )
  }

  setupIntent() {
    const url = `@api/subscribe/setup`

    return this.http.get<{ client_secret: string }>(url)
  }

  unsubscribe(id): Observable<Subscription[]> {
    const url = `@api/unsubscribe/${id}`
    return this.http
      .delete<Subscription[]>(url)
      .pipe(map((subscriptions) => this.parseWebreadySubscription(subscriptions)))
  }

  getAppDetails(product: string) {
    return this.http.get<TokeetApp>(`@api/app/details/${product}`)
  }

  getPlanAvailability(product: string) {
    return this.http
      .get<Dictionary<ProductAvailability>>(`@api/plans/${product}/available`)
      .pipe(map((res) => res[product]))
  }

  subscribe(product: string, planId: string, additionalData?: any): Observable<any> {
    // @ts-ignore
    this.stripe = Stripe(this.environment.config.stripe_publishable_key)

    let observable$: Observable<{ subscription: any; clientSecret: string | null }>
    if (['tokeet_ai', 'tokeet_messaging', 'tokeet_smart_devices'].includes(product)) {
      const url = `@api/subscribe/${product}`
      observable$ = this.http.post<any>(url, { ...additionalData })
    } else {
      const url = `@api/subscribe?product=${product}`
      observable$ = this.http.post<any>(url, { plan: planId, ...additionalData })
    }
    return observable$.pipe(switchMap((res) => fromPromise(this.handleFailedPayment(res))))
  }

  handleFailedPayment(res: { clientSecret: string | null }) {
    if (!!res.clientSecret) {
      return this.stripe.handleCardPayment(res.clientSecret).then((result) => {
        const { error } = result
        if (error) {
          if (error.type === 'card_error') {
            this.store.dispatch(ActionFailed({ error }))
          } else {
            this.store.dispatch(ActionFailed({ error: new Error('Error authenticating card.') }))
          }
          throw error
        }
      })
    } else {
      return Promise.resolve(res)
    }
  }
}
