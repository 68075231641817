import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { Destroyable, SaveForm, untilDestroy, integer, UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'

export type EditorImageOptions = {
  src: string | null
  alt: string | null
  width: number | null
  height: number | null
}

@Component({
  selector: 'app-editor-image-dialog',
  host: { class: 'modal-content' },
  templateUrl: './editor-image-dialog.component.html',
  styleUrls: ['./editor-image-dialog.component.scss'],
})
export class EditorImageDialogComponent extends Destroyable implements OnInit {
  uploadOptions: UploadBoxOptions = {
    maxSize: 10 * 1024 * 1024, // 10MB,
    maxUploads: 1,
    autoUpload: true,
    cloudinary: true,
    allowedFileExtensions: ['.png', '.jpg', '.jpeg'],
    token: this.authService.token,
  }

  form: FormGroup

  isEdit = false
  resetCounter = 0

  constructor(
    private dialogRef: MatDialogRef<EditorImageDialogComponent>,
    private authService: AuthService,
    fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { image: EditorImageOptions }
  ) {
    super()

    const image: Partial<EditorImageOptions> = data.image || {}

    const src = image.src || ''
    const alt = image.alt || ''
    const width = this.isValidSize(image.width) ? image.width : undefined
    const height = this.isValidSize(image.height) ? image.height : undefined

    const aspectRatio = this.isValidSize(width) && this.isValidSize(height) ? width / height : undefined

    this.isEdit = !!src

    this.form = fb.group({
      src: [src, [RxwebValidators.url()]],
      alt: [alt],
      width: [width, [Validators.min(0), integer()]],
      height: [height, [Validators.min(0), integer()]],
      lock: [true],
      aspectRatio: [aspectRatio],
    })
  }

  ngOnInit() {
    this.form
      .get('src')
      .valueChanges.pipe(
        untilDestroy(this),
        tap(() => this.updateImageSize())
      )
      .subscribe()

    this.form
      .get('width')
      .valueChanges.pipe(
        untilDestroy(this),
        tap(() => this.updateSize('height'))
      )
      .subscribe()

    this.form
      .get('height')
      .valueChanges.pipe(
        untilDestroy(this),
        tap(() => this.updateSize('width'))
      )
      .subscribe()
  }

  private updateImageSize() {
    const src = this.getSrc()
    if (!src) return

    const img = new Image()

    img.onload = () => {
      if (this.getSrc() !== src) return

      const width = img.naturalWidth || null
      const height = img.naturalHeight || null
      const aspectRatio = this.isValidSize(width) && this.isValidSize(height) ? width / height : null

      this.form.patchValue({ width, height, aspectRatio }, { emitEvent: false })
    }

    img.src = src
  }

  private updateSize(type: 'width' | 'height') {
    const width = this.form.get('width').value
    const height = this.form.get('height').value
    const aspectRatio = this.form.get('aspectRatio').value
    const lock = this.form.get('lock').value

    if (!lock || !this.isValidSize(aspectRatio)) return

    let value: number

    if (type === 'width' && this.isValidSize(height)) {
      value = height * aspectRatio
    } else if (type === 'height' && this.isValidSize(width)) {
      value = width / aspectRatio
    } else {
      return
    }

    this.form.patchValue({ [type]: Math.round(value) }, { emitEvent: false })
  }

  get isLock() {
    return this.form.get('lock').value === true
  }

  toggleLock() {
    return this.form.get('lock').setValue(!this.isLock)
  }

  private getSrc() {
    return this.form.get('src').value || ''
  }

  private isValidSize(value: any): value is number {
    return typeof value === 'number' && isFinite(value) && value > 0
  }

  onUploaded(uploadedFileResults: any[]) {
    // file is Cloudinary upload response:
    // https://api.cloudinary.com/v1_1/<folder>/upload

    const file = uploadedFileResults[0]
    if (!file) return

    const url = file['secure_url'] || file['url']
    if (typeof url !== 'string') return

    this.form.patchValue({ src: url })

    this.resetCounter += 1
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    const data = form.getRawValue()

    let image = {
      src: data.src,
      alt: String(data.alt || '').trim(),
      width: this.isValidSize(data.width) ? data.width : null,
      height: this.isValidSize(data.height) ? data.height : null,
    }

    if (!image.src) image = null

    this.dialogRef.close(image)
  }

  onRemove() {
    this.dialogRef.close({ src: '' })
  }

  onClose() {
    this.dialogRef.close(null)
  }
}
