<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">
          <img [src]="channel.logo" [alt]="channel.friendly_name" *ngIf="channel.logo" />
          <h5 class="modal-title ml-2 d-inline-block">{{ channel.friendly_name }}</h5>
        </div>

        <div class="btn-holder d-flex align-items-center"></div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar border-bottom bg-white" role="tablist">
      <li [class.active]="currentTab === tabs.Details">
        <a (click)="currentTab = tabs.Details"> Details </a>
      </li>
      <li [class.active]="currentTab === tabs.Formulas">
        <a (click)="currentTab = tabs.Formulas"> Formulas </a>
      </li>
    </ul>
    <div class="modal-body">
      <ng-container *ngIf="currentTab === tabs.Details">
        <div class="title-holder">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Details</h6>
            <div class="btn-holder">
              <button
                (click)="onDelete()"
                [disabled]="(1 | isReadonlyRole) || form?.isLoading"
                type="button"
                class="btn btn-secondary-danger"
              >
                <span class="cs-icon cs-icon-trash" aria-hidden="true"></span> Delete
              </button>

              <button
                (click)="onSave()"
                [disabled]="1 | isReadonlyRole"
                [disabled]="form?.isLoading"
                type="button"
                class="btn btn-secondary-info"
              >
                <span class="cs-icon cs-icon-save" *ngIf="!form?.isLoading"></span>
                <span class="far fa-spin fa-spinner" *ngIf="form?.isLoading"></span>
                Save
              </button>
            </div>
          </div>
        </div>
        <div class="container-box p-3">
          <app-custom-channel-form [channel]="channel" #form></app-custom-channel-form>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTab === tabs.Formulas">
        <div class="title-holder">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Formulas</h6>
            <div class="btn-holder">
              <button
                type="button"
                class="btn btn-secondary-danger"
                (click)="table?.onDeleteSelected()"
                [disabled]="table?.selection.isEmpty()"
              >
                <i class="cs-icon cs-icon-trash"></i>&nbsp; Delete
              </button>

              <button type="button" class="btn btn-secondary-info" (click)="table?.onAdd()">
                <i class="far fa-plus"></i>&nbsp; Add
              </button>
            </div>
          </div>
        </div>
        <app-booking-formulas-table
          [linkedRentals]="false"
          [channel]="{ id: channel.pkey, name: channel.channel_name }"
          #table
        ></app-booking-formulas-table>
      </ng-container>
    </div>
  </div>
</div>
