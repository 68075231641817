import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store'
import * as reducer from './card.reducer'
import * as state from './card.state'
import { combineLatest } from 'rxjs'
import { filter, map } from 'rxjs/operators'

export const cardState = createFeatureSelector<state.State>('cards')

export const selectCardsLoaded = createSelector(cardState, (s: state.State) => s.loaded)

export const selectAllCards = createSelector(cardState, reducer.selectAll)

export const getAllCards = (store: Store<any>) =>
  combineLatest([store.pipe(select(selectAllCards)), store.pipe(select(selectCardsLoaded))]).pipe(
    filter(([, ready]) => ready),
    map(([cards]) => cards)
  )
