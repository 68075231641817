import { Injectable } from '@angular/core'
import {
  AddInvoiceDialogComponent,
  CreateInvoiceParams,
} from '@tv3/containers/invoices/invoices/add-invoice/add-invoice-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { MatDialogConfig } from '@angular/material/dialog'

@Injectable()
export class AddInvoiceDialogService {
  constructor(private dialog: DialogService) {}

  public open(data: CreateInvoiceParams) {
    const defaultConfig: MatDialogConfig = {
      data: { ...data },
      panelClass: ['drawer-full-90'],
    }

    const dialogRef = this.dialog.openDrawer(AddInvoiceDialogComponent, defaultConfig)

    return dialogRef
  }
}
