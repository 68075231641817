import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as lodash from 'lodash'
import * as reducer from './reducer'
import * as state from './state'

export const rateAutomationState = createFeatureSelector<state.State>('rate-automation')

export const isRateAutomationsLoaded = createSelector(rateAutomationState, (s: state.State) => s.isLoaded)

export const selectAllAccountRateAutomations = createSelector(rateAutomationState, reducer.selectAll)

export const selectAccountRateAutomation = createSelector(selectAllAccountRateAutomations, (items) => {
  return lodash.head(items)
})

export const selectAllMinStayAutomations = createSelector(selectAccountRateAutomation, (t) => t?.min_nights || [])

export const selectAllGapNightAutomations = createSelector(selectAccountRateAutomation, (t) => t?.gap_nights || [])

export const selectAllLastMinuteAutomations = createSelector(selectAccountRateAutomation, (t) => t?.last_minute || [])

export const selectAllRatePushAutomations = createSelector(selectAccountRateAutomation, (t) => t?.rate_push || [])
