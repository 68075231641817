import * as R from 'ramda'
import { AutomationHandler } from './automation-handlers'
import { AutomationResponse } from './automation.model'
import { AutomationEventTypes } from './automation-events'

export function allowsChannel(event: AutomationEventTypes): boolean {
  return /^inquiry/.test(event)
}

export function allowsRental(event: AutomationEventTypes): boolean {
  return /^rate|^task|^inquiry|^time|^expense|^event|^invoice/.test(event)
}

function getUsers(automations: AutomationResponse[]) {
  return R.pipe(R.pluck('users'), R.flatten, R.reject(R.isNil), R.uniq)(automations)
}

export function withoutGuest(automations: AutomationResponse[]) {
  const users = getUsers(automations)
  return R.filter((u) => u !== '111' && u !== 111, users)
}

export function sendToGuest(automations: AutomationResponse[]) {
  const users = getUsers(automations)
  return R.contains('111', users) || R.contains(111, users)
}

export function getPostUrl(automations: AutomationResponse[]) {
  const users = getUsers(automations)
  return R.defaultTo('', R.head(users))
}

export function allowsBookingTags(handler: string): boolean {
  return handler === AutomationHandler.BookingAddTagHandler || handler === AutomationHandler.BookingRemoveTagHandler
}

export function isTimeEvent(event: AutomationEventTypes): boolean {
  return event.startsWith('time')
}

export function getAutomationEvent(event: AutomationEventTypes) {
  return isTimeEvent(event) ? 'time' : event
}
