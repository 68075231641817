import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-setup-layer',
  templateUrl: './setup-layer.component.html',
  styleUrls: ['./setup-layer.component.scss'],
})
export class SetupLayerComponent implements OnInit {
  @Input() loading = true
  @Input() isEmpty = true
  ngOnInit(): void {}
}
