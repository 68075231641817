import { Component, Inject, OnInit } from '@angular/core'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import * as R from 'ramda'
import { round, sumBy } from 'lodash'
import * as fromRoot from '@tv3/store/state'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import {
  AccountGuard,
  AttachmentsGuard,
  DataCheckerService,
  Destroyable,
  getNightsBetweenArrivalDepart,
  Rental,
  SaveForm,
  selectRentalById,
  selectSomeOnce,
  Toaster,
  epochToViewUTC,
  TaxV2,
  isSomething,
} from '@tokeet-frontend/tv3-platform'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { InvoiceNotesChangeEvent } from '@tv3/containers/invoices/invoices/add-invoice/invoice-notes/invoice-notes.component'
import { InvoiceDetailsChangeEvent } from '@tv3/containers/invoices/invoices/add-invoice/invoice-details/invoice-details.component'
import {
  AddInvoice,
  AddInvoiceComplete,
  Invoice,
  InvoiceItem,
  InvoiceLineItemTitles,
  invoiceLineItemTitlesByType,
  InvoiceStatus,
  InvoiceTypes,
  setInvoiceItemTaxV3,
  isInvoiceUpdating,
} from '@tokeet-frontend/invoices'
import { Actions, ofType } from '@ngrx/effects'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormGroup } from '@angular/forms'
import { InvoiceTotalPipe } from '@tv3/pipes/invoice/invoice-total.pipe'
import { convertBookingEngineTax } from '@tokeet-frontend/bookings'
import {
  AuditDiscount,
  BookingEngineBookingFee,
  BookingEngineTax,
  isExternalBookingSource,
  TaxTypes,
} from '@tokeet/cost-resolver'

export interface CreateInvoiceParams {
  type: InvoiceTypes
  inquiryId: string
  isCopy?: boolean
  invoice?: Invoice
}

@Component({
  selector: 'app-add-invoice-dialog',
  templateUrl: './add-invoice-dialog.component.html',
  styleUrls: ['./add-invoice-dialog.component.scss'],
})
export class AddInvoiceDialogComponent extends Destroyable implements OnInit {
  lineItemTitles: InvoiceLineItemTitles
  invoiceStatuses = InvoiceStatus

  invoiceType: number = InvoiceTypes.BookingInvoice

  isInvoiceUpdating$ = this.store.pipe(select(isInvoiceUpdating))

  inquiry: Inquiry
  rental: Rental

  form = new FormGroup({})
  invoice = new Invoice()
  get guests() {
    return (this.inquiry && (this.inquiry.numAdults || 0) + (this.inquiry.numChild || 0)) || 1
  }

  constructor(
    public dialogRef: MatDialogRef<AddInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateInvoiceParams,
    private inquiryChargeService: InquiryChargeService,
    private inquiryService: InquiryService,
    private toaster: Toaster,
    private dataCheckerService: DataCheckerService,
    private store: Store<fromRoot.State>,
    private actions$: Actions
  ) {
    super()
    this.dataCheckerService.check([AccountGuard, AttachmentsGuard])
    this.invoiceType = this.data.type
    this.invoice.invoiceItems = []

    if (this.data.isCopy) {
      this.invoice = this.data.invoice
    }
  }

  ngOnInit() {
    this.inquiryService
      .load(this.data.inquiryId)
      .pipe(
        switchMap((inquiry) => this.inquiryChargeService.getCharges(inquiry)),
        tap((inquiry) => (this.inquiry = inquiry)),
        switchMap((inquiry) =>
          this.store.pipe(
            selectSomeOnce(selectRentalById(inquiry.rental.id)),
            map((rental) => ({ inquiry, rental }))
          )
        ),
        tap(({ inquiry, rental }) => {
          if (!this.data.isCopy) {
            this.invoice.invoiceItems = [
              this.convertInquiryToInvoiceItem(inquiry),
              ...this.convertBookingFeesToInvoiceItems(inquiry),
              ...this.convertDiscountsToInvoiceItems(inquiry),
            ]
          }
          this.initializeInvoice(inquiry, rental)
        })
      )
      .subscribe(({ inquiry, rental }) => {
        this.rental = rental
        this.lineItemTitles = invoiceLineItemTitlesByType[this.invoiceType]
      })
  }

  initializeInvoice(inquiry: Inquiry, rental: Rental) {
    this.invoice.guestId = inquiry.guestId
    this.invoice.guestName = inquiry.guestDetails.name
    this.invoice.rentalId = inquiry.rentalId
    this.invoice.inquiryId = inquiry.id
    this.invoice.rentalName = rental.name
    this.invoice.paymentTerms = rental.paymentTerms
    this.invoice.paymentInstructions = rental.paymentInstructions
    this.invoice.currency = rental.currency
    this.invoice.type = this.invoiceType
  }

  close() {
    this.dialogRef.close()
  }

  onInvoiceDetailChanged({ due, date, expires, invoiceNum, addressKey }: InvoiceDetailsChangeEvent) {
    this.invoice.dueDate = due
    this.invoice.invoiceDate = date
    this.invoice.expiresAt = expires
    this.invoice.invoiceNum = invoiceNum
    this.invoice.addressKey = addressKey
  }

  onInvoiceNotesChanged({ notes, paymentTerms, paymentInstructions }: InvoiceNotesChangeEvent) {
    this.invoice.notes = notes
    this.invoice.paymentTerms = paymentTerms
    this.invoice.paymentInstructions = paymentInstructions
  }

  onLineItemChanged(index: number, invoiceItem: InvoiceItem) {
    const items = this.invoice.invoiceItems
    items[index] = invoiceItem
    this.invoice.invoiceItems = [...items]
  }

  onRemoveLineItem(index: number) {
    const items = this.invoice.invoiceItems
    items.splice(index, 1)
    this.invoice.invoiceItems = [...items]
  }

  trackByIndex(index: any, item: InvoiceItem) {
    return item.id || index
  }

  onAddInvoiceItem() {
    const item = new InvoiceItem()
    item.percent = 1.0
    this.invoice.invoiceItems.push(item)
  }

  private convertInquiryToInvoiceItem(inquiry: Inquiry): InvoiceItem {
    const item = new InvoiceItem()
    item.percent = 1.0 // 100%

    if (this.invoiceType === InvoiceTypes.GeneralInvoice) {
      return item
    }
    // only for booking invoice
    const rental = inquiry.rental
    item.item = rental.name
    item.qty = getNightsBetweenArrivalDepart(inquiry.guestArrive, inquiry.guestDepart)

    const bookingEngine = inquiry.bookingEngine

    if (!isExternalBookingSource(inquiry.inquirySource)) {
      const additionalGuestsFee = bookingEngine.extraGuests || 0
      const baseCharge = bookingEngine.base + additionalGuestsFee
      // item unit price
      item.unitCost = round(baseCharge / item.qty, 4)
      // item taxes
      item.taxes = convertBookingEngineTax(bookingEngine.taxes || [])

      // item discount
      const discountFeeTotal = sumBy(bookingEngine.discountFees, (t) => t.amount || 0)
      item.discount = round(discountFeeTotal, 2)
    } else {
      // inquiry taxes
      const charges = inquiry.charges
      const additionalGuestsFee = charges.extraGuests || 0
      const baseCharge = R.pathOr(0, ['charge'], charges) + additionalGuestsFee

      item.unitCost = round(baseCharge / item.qty, 4)
      const feeSum = charges.feeSum || 0
      const discountSum = charges.discountSum || 0
      item.discount = round(feeSum + discountSum, 2)
      const taxEx = new TaxV2()
      if (isSomething(charges.taxEx)) {
        taxEx.flat = charges.taxEx?.flat || 0
        taxEx.percent = charges.taxEx?.percent || 0
      } else {
        taxEx.flat = charges.taxFee || 0
        taxEx.percent = 0
      }
      item.taxEx = taxEx
      setInvoiceItemTaxV3(item, true)
    }

    const numOfPerson = (inquiry.numAdults || 0) + (inquiry.numChild || 0)
    item.description = `${item.qty} night(s) for ${numOfPerson} guest(s) at ${rental.name} starting ${epochToViewUTC(
      inquiry.guestArrive,
      'DD - MMM - YYYY'
    )} and ending ${epochToViewUTC(inquiry.guestDepart, 'DD - MMM - YYYY')}`

    return item
  }

  private convertDiscountsToInvoiceItems(inquiry: Inquiry): InvoiceItem[] {
    if (isExternalBookingSource(inquiry.inquirySource)) {
      return []
    }
    const bookingEngine = inquiry.bookingEngine

    const items = R.pathOr([], ['auditDiscounts'], bookingEngine)
    const taxes = R.pathOr([], ['taxes'], bookingEngine).filter((i: BookingEngineTax) => i.type === TaxTypes.Percent)
    return R.map((data: AuditDiscount) => {
      const item = new InvoiceItem()
      item.qty = 1
      item.unitCost = data.disc_amount
      item.item = data.disc_type === 'lengthofstay' ? 'LOS Discount' : 'Discount Code'
      item.taxes = convertBookingEngineTax(taxes)
      return item
    }, items || [])
  }

  private convertBookingFeesToInvoiceItems(inquiry: Inquiry): InvoiceItem[] {
    if (isExternalBookingSource(inquiry.inquirySource)) {
      return []
    }
    const bookingEngine = inquiry.bookingEngine
    const items = R.pathOr([], ['bookingFees'], bookingEngine)
    const taxes = R.pathOr([], ['taxes'], bookingEngine).filter((i: BookingEngineTax) => i.type === TaxTypes.Percent)
    return R.map((data: BookingEngineBookingFee) => {
      const item = new InvoiceItem()

      item.qty = 1
      item.unitCost = data.amount
      item.item = data.name
      item.description = data.description
      item.taxes = data.taxable ? convertBookingEngineTax(taxes) : []

      return item
    }, items || [])
  }

  @SaveForm()
  onSaveInvoice(form: FormGroup) {
    const total = new InvoiceTotalPipe().transform(this.invoice.invoiceItems)
    if (total <= 0) {
      this.toaster.error('Invoice total must be positive number.')
      return
    }
    this.store.dispatch(AddInvoice({ invoice: this.invoice }))
    this.actions$.pipe(ofType(AddInvoiceComplete), take(1)).subscribe(({ invoice }) => {
      this.dialogRef.close(invoice)
    })
  }
}
