import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Toaster, SaveForm, ActionFailed, positive } from '@tokeet-frontend/tv3-platform'
import { AiResponderService } from '@tv3/containers/ai-responder/store/ai-responder.service'
import { CreditChargeService } from '@tokeet-frontend/billing'

@Component({
  selector: 'app-ai-auto-credit-recharge',
  templateUrl: './ai-auto-credit-recharge.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class AiAutoCreditRechargeComponent implements OnInit {
  settingsForm = this.fb.group({
    recharge_balance: [undefined, [positive(), Validators.required]],
    recharge_threshold: [undefined, [positive(), Validators.required]],
  })
  rechargeForm = this.fb.group({
    recharge_amount: [50, [Validators.min(5), Validators.required]],
  })
  isRecharging = false

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private toaster: Toaster,
    private aiService: AiResponderService,
    private creditService: CreditChargeService
  ) {}

  ngOnInit(): void {
    this.aiService.getAccountAISettings().subscribe((data) => {
      const defaultData = {
        recharge_balance: 50,
        recharge_threshold: 10,
      }
      this.settingsForm.patchValue(data || defaultData)
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const payload = this.settingsForm.getRawValue()
    this.aiService.setAccountAISettings(payload).subscribe(
      () => {
        this.toaster.success('Settings updated successfully!')
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }

  @SaveForm()
  onCharge(form: FormGroup) {
    this.isRecharging = true
    const payload = this.rechargeForm.getRawValue()
    this.creditService.aiRecharge(payload.recharge_amount).subscribe(
      () => {
        this.isRecharging = false
        this.toaster.success('Recharged successfully!')
      },
      (error) => {
        this.isRecharging = false
        this.store.dispatch(ActionFailed({ error }))
      }
    )
  }
}
