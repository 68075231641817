<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/listing-icon2.png" alt="" height="40" />
    <div>
      <h6 class="mb-0">Rates</h6>
      <div class="text-sm">
        As mentioned previously in this connection wizard, rates will now need to be pushed from AdvanceCM to
        Booking.com.
        <br />
        Until this is done, your availability will remain blocked and the connection will not be complete.
      </div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" #settings>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Add Rates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              As mentioned previously in this connection wizard, rates will now need to be pushed from AdvanceCM to
              Booking.com. Until this is done, your availability will remain blocked and the connection will not be
              complete.
            </p>

            <p>
              If you have already prepared rates which you want to push to booking.com please hit Continue to proceed.
            </p>
            <div class="d-flex align-items-center gap-3">
              <button class="btn btn-light-info" (click)="onAddStandardRate()">
                <i class="far fa-plus"></i> Add Standard Rate
              </button>
              <button class="btn btn-light-info" (click)="onAddDynamicRate()">
                <i class="far fa-plus"></i> Add Dynamic Rate
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="pushRates.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #pushRates>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Push Rates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              This section will push the rates prepared by you previously to booking.com. After completing this step you
              should see both the availability and rates match with your booking.com rates calendar.
            </p>

            <p>
              After clicking the Push Rates button you will be asked to specify the AdvanceCM Rate Category and the
              Channel Rate Category.
            </p>

            <p>Here is a quick explanation of both values.</p>

            <div class="text-semi-bold">AdvanceCM Rate Category:</div>
            <p>
              This corresponds with the rate category you have created or selected when creating your rates in
              AdvanceCM.
            </p>

            <div class="text-semi-bold">Channel Rate Category:</div>
            <p>
              This corresponds with the rate name in Booking.com. By default the name of this rate will be Standard, but
              it is possible that you have renamed the standard rate in Booking.com and you will instead see that rate
              plan name as an option.
            </p>
            <div class="d-flex align-items-center gap-3">
              <button class="btn btn-light-info" (click)="onPushRates()">
                <i class="cs-icon cs-icon-upload"></i> Push Rates
              </button>
              <div class="flex-fill"></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button class="btn btn-secondary-info" (click)="next.emit()">
    Next
    <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
  </button>
</div>
