import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'

import { CanAddChannelAccountPipe } from '@tv3/containers/channels/pipes/can-add-channel-account.pipe'
import { IsAirBnbV1Pipe } from '@tv3/containers/channels/pipes/is-air-bnb-v1.pipe'
import { IsAirBnbV2Pipe } from '@tv3/containers/channels/pipes/is-air-bnb-v2.pipe'
import { ConnectionActivityTypePipe } from '@tv3/containers/channels/pipes/connection-activity-type.pipe'
import { ChannelMappedRentalsComponent } from '@tv3/containers/channels/channel-detail/api/mapped-rentals/channel-mapped-rentals.component'
import { ChannelExportedCalendarsComponent } from '@tv3/containers/channels/channel-detail/ical/exported-calendars/channel-exported-calendars.component'
import { ChannelImportedCalendarsComponent } from '@tv3/containers/channels/channel-detail/ical/imported-calendars/channel-imported-calendars.component'
import { ApiConnectionDetailComponent } from '@tv3/containers/channels/connection-detail/api/detail/api-connection-detail.component'
import { ApiConnectionStatusComponent } from '@tv3/containers/channels/connection-detail/api/status/api-connection-status.component'
import { ApiConnectionLogComponent } from '@tv3/containers/channels/connection-detail/api/log/api-connection-log.component'
import { IcalConnectionDetailComponent } from '@tv3/containers/channels/connection-detail/ical/detail/ical-connection-detail.component'
import { IcalConnectionStatusComponent } from '@tv3/containers/channels/connection-detail/ical/status/ical-connection-status.component'
import { ApiChannelDialogComponent } from '@tv3/containers/channels/channel-detail/api/api-channel-dialog.component'
import { IcalChannelDialogComponent } from '@tv3/containers/channels/channel-detail/ical/ical-channel-dialog.component'
import { ApiConnectionDialogComponent } from '@tv3/containers/channels/connection-detail/api/api-connection-dialog.component'
import { IcalConnectionDialogComponent } from '@tv3/containers/channels/connection-detail/ical/ical-connection-dialog.component'
import { ChannelConfigPpidDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/ppid/channel-config-ppid-dialog.component'
import { ChannelConfigCredentialsDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/credentials/channel-config-credentials-dialog.component'
import { ConnectionActivityLogDetailDialogComponent } from '@tv3/containers/channels/connection-detail/api/log/log-detail-dialog/connection-activity-log-detail-dialog.component'
import { SelectRateToPushDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/select-rate-to-push-dialog/select-rate-to-push-dialog.component'
import { SelectChannelAccountDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/select-channel-account/select-channel-account-dialog.component'
import { SelectToCreateListingDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/select-to-create-listing-dialog/select-to-create-listing-dialog.component'
import { ImportIcalCalendarDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/import-ical-calendar-dialog/import-ical-calendar-dialog.component'
import { AirbnbUploadTransactionDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-upload-transaction-dialog/airbnb-upload-transaction-dialog.component'
import { AirbnbUpdateBookingPrefDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-update-booking-pref-dialog/airbnb-update-booking-pref-dialog.component'
import { AirbnbUpdatePriceSettingsDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-update-price-settings-dialog/airbnb-update-price-settings-dialog.component'

import { ImportedConnectionLogComponent } from '@tv3/containers/channels/connection-detail/ical/log/imported-connection-log.component'
import { ExportCalendarDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/export-calendar-dialog/export-calendar-dialog.component'
import { AirbnbApiConnectionDialogComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/airbnb-api-connection-dialog.component'
import { StoreModule } from '@ngrx/store'
import * as fromAirBnBApi from '../connection-detail/airbnb-api/store/airbnb-api.reducer'
import { AirBnBPricingComponent } from '../connection-detail/airbnb-api/pricing/pricing.component'
import { AirBnBListingDetailsIndexComponent } from '../connection-detail/airbnb-api/listing-details/listing-details-index.component'
import { AirBnBBookingSettingsComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/booking-settings/booking-settings.component'
import { AirBnBAvailabilityComponent } from '../connection-detail/airbnb-api/availability/availability.component'
import { AirBnBLocalTaxesComponent } from '../connection-detail/airbnb-api/local-taxes/local-taxes.component'
import { EffectsModule } from '@ngrx/effects'
import { AirbnbApiEffects } from '../connection-detail/airbnb-api/store/airbnb-api.effects'
import { AirBnBListingPhotosComponent } from '../connection-detail/airbnb-api/listing-details/photos/photos.component'
import { AirbnbListingRoomsComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/rooms/rooms.component'
import { AirBnBListingAmenitiesComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/amenities/amenities.component'
import { AirBnBListingPhotosDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/photos/photos-detail/photos-detail.component'
import { AirBnBListingDetailsSectionComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/shared/details-section/details-section.component'
import { AirBnBListingRoomsDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/rooms/rooms-detail/rooms-detail.component'
import { AirBnBListingTitleSectionComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/shared/title-section/title-section.component'
import { AirBnBListingAmenitiesDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/amenities/amenities-detail/amenities-detail.component'
import { AirBnBListingAccessibilityComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/accessibility/accessibility.component'
import { AirBnBListingAccessibilityDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/accessibility/accessibility-detail/accessibility-detail.component'
import { AirBnBListingTitleAndDescriptionComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/title-and-description/title-and-description.component'
import { AirBnBListingTitleAndDescriptionDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/title-and-description/title-and-description-detail/title-and-description-detail.component'
import { AirBnBListingPropertyAndGuestsComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/property-and-guests/property-and-guests.component'
import { AirBnBListingPropertyAndGuestsDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/property-and-guests/property-and-guests-detail/property-and-guests-detail.component'
import { AirBnBListingLocationComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/location/location.component'
import { AirBnBListingLocationDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/location/location-detail/location-detail.component'
import { AirbnbListingGuestResourcesComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/guest-resources/guest-resources.component'
import { AirBnBListingGuestResourcesDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/guest-resources/guest-resources-detail/guest-resources-detail.component'
import { AirBnBListingGuidebookComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/guidebook/guidebook.component'
import { AirBnBListingGuidebookDetailComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/guidebook/guidebook-detail/guidebook-detail.component'
import { AirbnbListingPhotoEditComponent } from '@tv3/containers/channels/connection-detail/airbnb-api/listing-details/photos/photos-detail/photo-edit/photo-edit.component'
import { SettingsSharedModule } from '@tv3/containers/settings/settings-shared.module'
import { ChannelsLibraryModule, CustomChannelModule } from '@tokeet-frontend/channels'
import { FeedChannelOverlayComponent } from '@tv3/containers/channels/channel-detail/feed/feed-channel-overlay/feed-channel-overlay.component'
import { HomeToGoConnectionOverlayComponent } from '@tv3/containers/channels/connection-detail/feed/hometogo-connection-overlay/hometogo-connection-overlay.component'
import { RentalsLibraryModule } from '@tokeet-frontend/rentals'
import { AirbnbTaxSettingsDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-tax-settings-dialog/airbnb-tax-settings-dialog.component'
import { AirbnbBookingSettingsDialogComponent } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-booking-settings-dialog/airbnb-booking-settings-dialog.component'

import { HomeAwayConnectionOverlayComponent } from '@tv3/containers/channels/connection-detail/feed/homeaway-connection-overlay/homeaway-connection-overlay.component'

import { HvmiConnectionOverlayComponent } from '@tv3/containers/channels/connection-detail/feed/hvmi-connection-overlay/hvmi-connection-overlay.component'
import { FeedConnectionStatusComponent } from '../connection-detail/feed/status/feed-connection-status.component'
import { AirbnbListingStatusComponent } from '../components/airbnb-listing-status/airbnb-listing-status.component'

import { RentalsUnitedConnectionOverlayComponent } from '../connection-detail/feed/ru-connection-overlay/ru-connection-overlay.component'
import { RentalsUnitedConnectionOverlayService } from '../connection-detail/feed/ru-connection-overlay/ru-connection-overlay.service'

import { HoliduConnectionOverlayComponent } from '../connection-detail/feed/holidu-connection-overlay/holidu-connection-overlay.component'

import { GVRConnectionOverlayComponent } from '../connection-detail/feed/gvr-connection-overlay/gvr-connection-overlay.component'
import { WebsitesSharedModule } from '@tv3/containers/websites/websites-shared.module'
import { AbbNotificationsComponent } from '../channel-detail/api/abb-notifications/abb-notifications.component'
import { AbbOpportunitiesComponent } from '../channel-detail/api/abb-opportunities/abb-opportunities.component'
import { AbbReviewsComponent } from '../channel-detail/api/abb-reviews/abb-reviews.component'
import { AbbOpportunityDialogComponent } from '../channel-detail/api/abb-opportunity-dialog/abb-opportunity-dialog.component'
import { AbbReplyGuestReviewDialogComponent } from '../channel-detail/api/reply-guest-review-dialog/abb-reply-guest-review-dialog.component'
import { AirbnbSyncSettingsDialogComponent } from '../channel-connect/dialogs/airbnb-sync-settings/airbnb-sync-settings-dialog.component'
import { ChannelCommissionsComponent } from '@tv3/containers/channels/channel-detail/api/channel-commissions/channel-commissions.component'
import { ChannelCommissionDialogComponent } from '@tv3/containers/channels/channel-detail/api/channel-commission-dialog/channel-commission-dialog.component'
import { CustomChannelDialogComponent } from '@tv3/containers/channels/shared/custom-channel-dialog/custom-channel-dialog.component'
import { CustomChannelOverlayComponent } from '@tv3/containers/channels/channel-detail/custom/custom-channel-overlay.component'
import { CustomChannelOverlayService } from '@tv3/containers/channels/channel-detail/custom/custom-channel-overlay.service'
import { CustomChannelFormComponent } from '@tv3/containers/channels/channel-detail/custom/custom-channel-form/custom-channel-form.component'
import { AirbnbSetVisibilityDialogComponent } from '@tv3/containers/channels/connection-detail/airbnb-set-visibility/airbnb-set-visibility-dialog.component'

import { ChannelCardComponent } from '../channel-grid/channel-card/channel-card.component'
import { ChannelGridComponent } from '../channel-grid/channel-grid.component'
import { ChannelsOverlayComponent } from './channels-overlay/channels-overlay.component'
import { ChannelConnectWizardsModule } from '../channel-connect/channel-connect-wizards/index.module'
import { ChannelListingWizardsModule } from '../channel-connect/listing-connect-wizards/index.module'
import { AbbSyncSettingsComponent } from '../channel-detail/api/abb-sync-settings/abb-sync-settings.component'
import { ChannelAccountsComponent } from '../channel-detail/api/channel-accounts/channel-accounts.component'
import { ConnectionListComponent } from '../connection-list/connection-list.component'
import { BookingFormulaSharedModule } from '@tv3/containers/formulas/formula-shared.module'
import { ReviewsSharedModule } from '@tv3/containers/reviews/reviews-shared.module'

const components = [
  CanAddChannelAccountPipe,
  IsAirBnbV1Pipe,
  IsAirBnbV2Pipe,
  ConnectionActivityTypePipe,

  ConnectionListComponent,

  ChannelMappedRentalsComponent,
  ChannelExportedCalendarsComponent,
  ChannelImportedCalendarsComponent,
  ApiConnectionDetailComponent,
  ApiConnectionStatusComponent,
  ApiConnectionLogComponent,
  IcalConnectionDetailComponent,
  IcalConnectionStatusComponent,
  ImportedConnectionLogComponent,
  AirBnBPricingComponent,
  AirBnBListingDetailsIndexComponent,
  AirBnBBookingSettingsComponent,
  AirBnBAvailabilityComponent,
  AirBnBLocalTaxesComponent,
  AirBnBListingPhotosComponent,
  AirbnbListingRoomsComponent,
  AirBnBListingAmenitiesComponent,
  AirBnBListingPhotosDetailComponent,
  AirBnBListingDetailsSectionComponent,
  AirBnBListingRoomsDetailComponent,
  AirBnBListingTitleSectionComponent,
  AirBnBListingAmenitiesDetailComponent,
  AirBnBListingAccessibilityComponent,
  AirBnBListingAccessibilityDetailComponent,
  AirBnBListingTitleAndDescriptionComponent,
  AirBnBListingTitleAndDescriptionDetailComponent,
  AirBnBListingPropertyAndGuestsComponent,
  AirBnBListingPropertyAndGuestsDetailComponent,
  AirBnBListingLocationComponent,
  AirBnBListingLocationDetailComponent,
  AirbnbListingGuestResourcesComponent,
  AirBnBListingGuestResourcesDetailComponent,
  AirBnBListingGuidebookComponent,
  AirBnBListingGuidebookDetailComponent,
  AirbnbListingPhotoEditComponent,

  AirbnbListingStatusComponent,
  AirbnbSyncSettingsDialogComponent,

  AbbOpportunitiesComponent,
  AbbReviewsComponent,
  AbbNotificationsComponent,
  AbbOpportunityDialogComponent,
  AbbReplyGuestReviewDialogComponent,
  AirbnbSetVisibilityDialogComponent,
  AbbSyncSettingsComponent,

  ChannelAccountsComponent,

  ApiChannelDialogComponent,
  FeedChannelOverlayComponent,
  IcalChannelDialogComponent,
  ApiConnectionDialogComponent,
  AirbnbApiConnectionDialogComponent,
  IcalConnectionDialogComponent,
  ChannelConfigPpidDialogComponent,
  ChannelConfigCredentialsDialogComponent,
  ConnectionActivityLogDetailDialogComponent,
  SelectRateToPushDialogComponent,
  ExportCalendarDialogComponent,
  SelectChannelAccountDialogComponent,
  SelectToCreateListingDialogComponent,
  ImportIcalCalendarDialogComponent,
  AirbnbUploadTransactionDialogComponent,
  AirbnbUpdateBookingPrefDialogComponent,
  AirbnbUpdatePriceSettingsDialogComponent,
  AirbnbTaxSettingsDialogComponent,
  AirbnbBookingSettingsDialogComponent,
  HomeToGoConnectionOverlayComponent,
  HomeAwayConnectionOverlayComponent,
  HvmiConnectionOverlayComponent,
  RentalsUnitedConnectionOverlayComponent,
  FeedConnectionStatusComponent,
  HoliduConnectionOverlayComponent,

  GVRConnectionOverlayComponent,

  ChannelCommissionsComponent,
  ChannelCommissionDialogComponent,

  //
  CustomChannelDialogComponent,
  CustomChannelFormComponent,
  CustomChannelOverlayComponent,

  //
  ChannelGridComponent,
  ChannelCardComponent,
  ChannelsOverlayComponent,
]

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    SettingsSharedModule,

    ChannelsLibraryModule,
    CustomChannelModule,
    BookingFormulaSharedModule,
    StoreModule.forFeature('airbnb-dialog', fromAirBnBApi.airBnBApiDialogReducer),
    EffectsModule.forFeature([AirbnbApiEffects]),
    RentalsLibraryModule,
    WebsitesSharedModule,
    ChannelConnectWizardsModule,
    ChannelListingWizardsModule,
    ReviewsSharedModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [RentalsUnitedConnectionOverlayService, CustomChannelOverlayService],
})
export class ChannelSharedModule {}
