import { FeeTypes } from '@tokeet/cost-resolver'

export enum FeeModalities {
  PerPerson = 'pp',
  PerNight = 'pn',
  PerPersonPerNight = 'pppn',
  PerStay = 'ps',
}

export const FeeModalityLabels: { [key: string]: string } = {
  [FeeModalities.PerPerson]: `Per Person`,
  [FeeModalities.PerNight]: `Per Night`,
  [FeeModalities.PerPersonPerNight]: `Per Person/Per Night (PP/PN)`,
  [FeeModalities.PerStay]: `Per Stay`,
}

export type BookingFeeId = string

export interface BookingFeeScheduling {
  dow?: number[]
  start?: number
  end?: number
}

export interface BookingFee {
  pkey: BookingFeeId //     This is the primary key of the Fee.
  account: number //  This is the account to which the fee belongs.
  rental_id: string //
  name: string //     This is the name of the fee.
  description?: string // This is a description of the fee.
  amount: number // ($/%) This is the numerical amount of the fee. It can be a percentage or flat amount, which is indicated by the Type attribute, but it must be > 0.
  type: FeeTypes // (Flat/Percent) This is the type of the fee. Fees can be a percentage of the booking cost or a flat amount.
  modality: FeeModalities // This determines how the fee is applied to the booking. The fee may increase based on the parameters of the booking and the modality explains how. Possible values are: Per Stay, Per Night, Per Person, PP/PN. [per_stay,per_person,per_person_per_night,per_days]
  scheduling?: BookingFeeScheduling // This determines on which days or dates the fee is applied. These are two separate values which may also be null. This is a sub-object. Day of Week, Date Range
  refundable: number //  This indicates if the fee is refundable.
  taxable: number //     This indicates if the fee is taxable.
  channel_id?: string
  guest_threshold?: number // This specifies if the fee applies over or below is set guest count.
  length_threshold?: number // This indicates if the fee is applied for stays over or under a specific length. Use positive values to indicate over and negative to indicate under.
  status: number // This indicates if the fee is active or paused.
  created: number
  upfront: number
}

export type CreateBookingFeePayload = Omit<BookingFee, 'pkey' | 'created'>

export type UpdateBookingFeePayload = CreateBookingFeePayload
