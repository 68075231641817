import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as lodash from 'lodash'
import {
  BDCCancelOption,
  BDCFreeCancelOption,
  bdcFreeCancelOptions,
  bdcNonFreeCancelOptions,
  bdcNonFreeFullChargeCancelOption,
  maxSelectedCancelPolicies,
} from '@tv3/constants/bdc-cancel-policies'
import { Destroyable, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MatCheckbox } from '@angular/material/checkbox'

export function createForm(fb: FormBuilder) {
  return fb.group({
    isFree: [undefined],
    xDaysInAdvance: [undefined],
    chargeXPercentFee: [undefined],
    fullChargeDays: [undefined],
  })
}

@Component({
  selector: 'app-bdc-content-step-policies-form',
  templateUrl: './bdc-content-step-policies-form.component.html',
  styleUrls: ['./bdc-content-step-policies-form.component.scss'],
})
export class BdcContentStepPoliciesFormComponent extends Destroyable implements OnInit, OnChanges {
  @Input() form: FormGroup
  @Input() policies: number[]
  @Output() change = new EventEmitter<BDCCancelOption[]>()

  freeCancelOptionsByDays: lodash.Dictionary<BDCFreeCancelOption[]> = lodash.groupBy(bdcFreeCancelOptions, 'days')
  // @ts-ignore
  freeCancelDaysOptions = lodash.sortBy(lodash.keys(this.freeCancelOptionsByDays), (day) => day * 1)

  bdcNonFreeFullChargeCancelOption = bdcNonFreeFullChargeCancelOption
  nonfreeCancelOptionsByPercent = lodash.groupBy(
    bdcNonFreeCancelOptions,
    (item) => `${item.percent}|${item.fullChargeDays || ''}`
  )
  nonfreeCancelPercentOptions = lodash.uniq(lodash.map(bdcNonFreeCancelOptions, 'percent')).sort()
  nonfreeCancelFullChargeDaysOptions = lodash.sortBy(
    lodash.uniq(lodash.filter(lodash.map(bdcNonFreeCancelOptions, 'fullChargeDays')))
  )
  maxSelectedCancelPoliciesWarningTxt = `Please choose up to ${maxSelectedCancelPolicies} cancellation policies you want available on your listing.`
  activeCancelPolicies: BDCCancelOption[] = []

  constructor(private toaster: Toaster) {
    super()
  }

  ngOnInit(): void {
    // cancel policies
    this.form
      .get('isFree')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((isFree) => {
        this.setCtrlRequirement(this.form.get('xDaysInAdvance'), isFree)
        this.setCtrlRequirement(this.form.get('chargeXPercentFee'), !isFree)
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['policies'] && changes['policies'].currentValue) {
      this.setPolicies(this.policies)
    }
  }

  setCtrlRequirement(ctrl: AbstractControl, isRequired: boolean) {
    if (isRequired) {
      ctrl.setValidators([Validators.required])
    } else {
      ctrl.clearValidators()
      ctrl.updateValueAndValidity()
    }
    ctrl.markAsUntouched()
  }

  setPolicies(policies: number[]) {
    this.activeCancelPolicies = lodash.filter(
      [bdcNonFreeFullChargeCancelOption, ...bdcFreeCancelOptions, ...bdcNonFreeCancelOptions],
      ({ value }) => lodash.includes(policies, value)
    ) as BDCCancelOption[]
    this.change.emit([...this.activeCancelPolicies])
  }

  isCancelPolicyChecked(policy: number) {
    return !!lodash.find(this.activeCancelPolicies, (a) => a.value === policy)
  }

  onCancelPolicyChanged(item: BDCCancelOption, checkbox: MatCheckbox) {
    if (checkbox.checked) {
      if (this.activeCancelPolicies.length >= maxSelectedCancelPolicies) {
        this.toaster.warning(this.maxSelectedCancelPoliciesWarningTxt)
        checkbox.writeValue(false)
        return
      }
      this.activeCancelPolicies.push(item)
    } else {
      lodash.remove(this.activeCancelPolicies, (a) => a.value === item.value)
    }
    this.change.emit([...this.activeCancelPolicies])
  }
}
