import { AbstractControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import { isSomething, convertToTokeetEmail } from '../functions'
import { selectOnce } from '../rx-operators'
import { selectAllRentals, selectSubdomain, selectedRentalId, Rental } from '../stores'

// @dynamic
export class RentalEmailNotTakenValidator {
  static createValidator(store: Store<any>) {
    return (control: AbstractControl) => {
      return store.pipe(
        select(selectAllRentals),
        filter((rentals) => isSomething(rentals)),
        take(1),
        withLatestFrom(store.pipe(select(selectSubdomain), take(1))),
        switchMap(([rentals, subDomain]) =>
          store.pipe(
            selectOnce(selectedRentalId),
            map((id) => ({ id, rentals, subDomain }))
          )
        ),
        map(({ id, rentals, subDomain }) => {
          if (id) {
            const withoutRental = R.filter((r: Rental) => r.id !== id, rentals)
            return isRentalEmailTaken(control.value, subDomain, withoutRental)
          }
          return isRentalEmailTaken(control.value, subDomain, rentals)
        })
      )
    }
  }
}

export function isRentalEmailTaken(email: string, subDomain: string, rentals: Rental[]) {
  return R.any((r: Rental) => r.email === convertToTokeetEmail(email, subDomain))(rentals) ? { emailTaken: true } : null
}
