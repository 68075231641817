import { createSelector, select, Store } from '@ngrx/store'
import { EpochPipe, Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { channelsState } from '../channels.selectors'
import { RentalsUnitedListingModel, RentalsUnitedListingViewModel } from './model'

export const selectRentalsUnitedStatus = createSelector(channelsState, (state) => state.rentalUnited.status)
export const selectRentalsUnitedConnected = createSelector(
  channelsState,
  (state) => state.rentalUnited.status?.connected
)
export const selectRentalsUnitedListings = createSelector(channelsState, (state) => state.rentalUnited.listings || [])
export const selectRentalsUnitedRentalValidations = createSelector(
  channelsState,
  (state) => state.rentalUnited.validations
)
export const selectRentalsUnitedValidationsLoaded = createSelector(
  channelsState,
  (state) => state.rentalUnited.validationsLoaded
)

export const getRentalUnitedListings = (store: Store<any>) =>
  combineLatest([store.pipe(select(selectRentalsUnitedListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      const epoch = new EpochPipe()
      return R.map((listing): RentalsUnitedListingModel => {
        const rental = R.find((r: Rental) => r.id === listing.rentalId, rentals)
        const lastsync = lodash.max([
          listing.lastSync,
          listing.lastRatePush,
          listing.lastAvailabilityPush,
          listing.createdAt,
        ])
        return {
          ...listing,
          statusText: listing.propertyId ? (listing.active ? 'Active' : 'Inactive') : 'Draft',
          rental,
          rental_name: rental?.name,
          lastSync: lastsync,
          lastSyncView: epoch.transform(lastsync),
        } as RentalsUnitedListingViewModel
      }, listings) as RentalsUnitedListingViewModel[]
    })
  )
