import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Attachment } from './attachment.model'

export interface State extends EntityState<Attachment> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
