import { Pipe, PipeTransform } from '@angular/core'
import { Connection } from '@tv3/store/connection/connection.model'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'connectionType',
})
export class ConnectionTypePipe implements PipeTransform {
  transform(connection: Connection): string {
    let type
    if (isSomething(connection.imported)) {
      type = 'Imported'
    } else if (isSomething(connection.propertyId)) {
      type = 'API'
    } else {
      type = 'Exported'
    }
    return type
  }
}
