import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  Account,
  BillingAddress,
  Destroyable,
  notAllSpacesValidator,
  SaveForm,
  selectCurrentAccount,
  untilDestroy,
  updateAccountBillingAddresses,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as lodash from 'lodash'
import { createAddressForm } from '../AddressForm/AddressForm.component'

@Component({
  selector: 'app-billing-address-dialog',
  templateUrl: './billing-address-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./billing-address-dialog.component.scss'],
})
export class BillingAddressDialogComponent extends Destroyable implements OnInit {
  form = createAddressForm()
  account: Account

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    public dialogRef: MatDialogRef<BillingAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { address: BillingAddress }
  ) {
    super()
  }

  ngOnInit() {
    this.form.addControl('key', this.fb.control(''))

    if (this.data.address) {
      this.form.patchValue(this.data.address)
    }

    this.store.pipe(select(selectCurrentAccount), untilDestroy(this)).subscribe((data) => {
      this.account = data
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue()
    let payload: BillingAddress[] = []
    if (formValues.key) {
      payload = lodash.map(this.account.invoiceBillingAddresses, (a) => {
        return a.key === formValues.key ? formValues : a
      })
    } else {
      payload = [...(this.account.invoiceBillingAddresses || []), formValues]
    }

    this.store.dispatch(updateAccountBillingAddresses({ addresses: payload }))
    this.close()
  }
}
