import { Component, Input, OnInit } from '@angular/core'
import { ConnectionView, isApiConnectionActive } from '@tv3/store/connection/connection.view'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { ApiConnectionStatus } from '@tv3/store/connection/connection.types'
import { catchError, filter, take } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import {
  ImportBookingsFromConnectionComplete,
  PushAvailabilityToConnectionComplete,
  PushRatesToConnectionComplete,
} from '@tv3/store/connection/connection.actions'
import { get, max } from 'lodash'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'

@Component({
  selector: 'app-api-connection-status',
  templateUrl: './api-connection-status.component.html',
  styleUrls: ['./api-connection-status.component.scss'],
})
export class ApiConnectionStatusComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() dateFormat: string

  @Input() availabilityPull: boolean = true
  @Input() availabilityPush: boolean = true
  @Input() ratesPush: boolean = true

  connectionStatus: ApiConnectionStatus

  constructor(private actions: Actions, private store: Store<any>, private connectionService: ConnectionService) {
    super()
  }

  ngOnInit() {
    this.refreshStatus()
    this.listenDataChange()
  }

  listenDataChange() {
    this.actions
      .pipe(
        ofType(ImportBookingsFromConnectionComplete),
        filter(({ update }) => update.id === this.connection.id),
        untilDestroy(this)
      )
      .subscribe(({ update }) => {
        this.connectionStatus.lastavailpull = update.changes.lastavailpull
        this.setStatusText(this.connectionStatus)
      })

    this.actions
      .pipe(
        ofType(PushAvailabilityToConnectionComplete),
        filter(({ update }) => update.id === this.connection.id),
        untilDestroy(this)
      )
      .subscribe(({ update }) => {
        this.connectionStatus.lastavailpush = update.changes.lastavailpush
        this.setStatusText(this.connectionStatus)
      })

    this.actions
      .pipe(
        ofType(PushRatesToConnectionComplete),
        filter(({ update }) => update.id === this.connection.id),
        untilDestroy(this)
      )
      .subscribe(({ update }) => {
        this.connectionStatus.lastratepush = update.changes.lastratepush
        this.setStatusText(this.connectionStatus)
      })
  }

  refreshStatus() {
    this.connectionService
      .getApiConnectionStatus(this.channel.name, this.connection.propertyId, this.connection.roomId)
      .pipe(
        take(1),
        catchError(() => of(null))
      )
      .subscribe((res) => {
        this.connectionStatus = res || {}

        this.connectionStatus.lastavailpull = max([res?.lastavailpull, this.connection.lastavailpull])
        this.connectionStatus.lastavailpush = max([res?.lastavailpush, this.connection.lastavailpush])
        this.connectionStatus.lastratepush = max([res?.lastratepush, this.connection.lastratepush])

        this.connectionStatus.lastratepush = max([
          this.connectionStatus.lastratepush,
          get(this.connection.rental, 'attributes.last_rateengine_update'),
        ])
        this.setStatusText(this.connectionStatus)
      })
  }

  private setStatusText(statusObj: ApiConnectionStatus) {
    // tslint:disable-next-line:triple-equals
    if (this.connection.status == 0) {
      statusObj.status = 'Disabled'
    } else if (statusObj.loginfail) {
      statusObj.status = 'Login Failed'
    } else if (isApiConnectionActive(this.connection, statusObj)) {
      statusObj.status = 'Active'
    } else {
      statusObj.status = 'Pending'
    }
  }
}
