import * as R from 'ramda'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
  ConfirmDialogService,
  Destroyable,
  Rental,
  selectAllRentals,
  untilDestroy,
  restrictUser,
  restrictUserAll,
  unRestrictUser,
  unRestrictUserAll,
  User,
  isRestrictingUsers,
} from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { Website } from '@tv3/store/website/website.model'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { IsRestrictedPipe } from '@tv3/containers/settings/user-settings/overlays/is-restricted.pipe'

@Component({
  selector: 'app-restricted-rentals',
  templateUrl: './restricted-rentals.component.html',
  styleUrls: ['./restricted-rentals.component.scss'],
})
export class RestrictedRentalsComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  @Input() user: User
  @Input() website: Website
  @Input() set searchTerm(s: string) {
    this.dataSource.filter = s
    this.paginator.firstPage()
  }

  dataSource = new MatTableDataSource<Rental>()
  rentals: Rental[] = []

  displayedColumns = ['select', 'name', 'description']

  tableType = TableType.RentalsList
  isEmptyTable$ = isEmptyTable(this.dataSource)

  isRestrictingUsers$ = this.store.pipe(select(isRestrictingUsers))

  constructor(
    private store: Store<fromRoot.State>,
    protected storage: UserStorage,
    private confirmDialogService: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
      const isRestricted = new IsRestrictedPipe()
      this.dataSource.data = R.sortBy((r) => !isRestricted.transform(r, this.user), rentals)
      this.paginator.firstPage()
    })
    this.paginator.firstPage()
  }

  onToggleAll(event: MatCheckboxChange) {
    this.confirmDialogService
      .confirm({
        title: event.checked ? 'Restrict all rentals?' : 'Unrestrict all rentals?',
        body: 'This option changes every rental in your current filter and not just the visible rentals. Would you like to continue?',
      })
      .subscribe(() => {
        if (event.checked) {
          this.store.dispatch(restrictUserAll({ rentals: this.rentals, userId: this.user.id }))
        } else {
          this.store.dispatch(unRestrictUserAll({ rentals: this.rentals, userId: this.user.id }))
        }
      })
  }

  onToggle(event: MatCheckboxChange, rental: Rental) {
    if (event.checked) {
      this.store.dispatch(restrictUser({ rental, userId: this.user.id }))
    } else {
      this.store.dispatch(unRestrictUser({ rental, userId: this.user.id }))
    }
  }

  onOpenRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }
}
