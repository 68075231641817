import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { PaymentRule } from '@tv3/store/payment-rule/payment-rule.model'
import { CreatePaymentRuleRequest } from '@tv3/interfaces/requests/create-payment-rule.request'
import { deserializeArray, isSomething } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class PaymentRuleService {
  constructor(private http: HttpClient) {}

  all(): Observable<PaymentRule[]> {
    const url = '@api/payments/rules/all'

    return this.http.get(url).pipe(deserializeArray<PaymentRule>(PaymentRule))
  }

  create(request: CreatePaymentRuleRequest): Observable<PaymentRule[]> {
    const url = '@api/payments/rule/'

    request = {
      ...request,
      rentals: isSomething(request.rentals) ? request.rentals : null,
    }

    return this.http.post(url, request).pipe(deserializeArray<PaymentRule>(PaymentRule))
  }

  pause(id) {
    const url = `@api/payments/rule/pause/${id}`

    return this.http.put(url, {})
  }

  resume(id) {
    const url = `@api/payments/rule/resume/${id}`

    return this.http.put(url, {})
  }

  remove(id) {
    const url = `@api/payments/rule/${id}`

    return this.http.delete(url)
  }
}
