<div class="mat-form-field2-wrapper">
  <div class="mat-form-field2-label-wrapper" *ngIf="showLabel" [ngSwitch]="_hasLabel()">
    <label class="mat-form-field2-label">
      <ng-content select="mat-label" *ngSwitchCase="true"></ng-content>
      <ng-container *ngSwitchCase="false">
        <ng-content select="mat-placeholder"></ng-content>
        {{ _control?.placeholder }}
      </ng-container>
      <!-- @breaking-change 8.0.0 remove `mat-placeholder-required` class -->
      <span
        class="mat-placeholder-required mat-form-field-required-marker"
        aria-hidden="true"
        *ngIf="_control?.required && !_control?.disabled"
        >&#32;*</span
      >
    </label>
  </div>
  <div class="mat-form-field-flex" #connectionContainer>
    <div class="mat-form-field-prefix mr-2 d-flex align-items-center" *ngIf="_prefixChildren.length">
      <ng-content select="[matPrefix]"></ng-content>
    </div>

    <div class="mat-form-field2-infix flex-fill d-flex h-100 w-100 align-items-center" #inputContainer>
      <label class="mat-form-field-placeholder" [class.mat-form-field-empty]="_control?.empty" *ngIf="!hidePlaceholder">
        <ng-content select="mat-placeholder"></ng-content>
        <span>{{ _control?.placeholder }}</span>
      </label>
      <ng-content></ng-content>
    </div>

    <div class="mat-form-field-suffix ml-2 d-flex align-items-center" *ngIf="_suffixChildren.length">
      <ng-content select="[matSuffix]"></ng-content>
    </div>
  </div>

  <div class="mat-form-field2-subscript-wrapper" [ngSwitch]="_getDisplayedMessages()">
    <div *ngSwitchCase="'error'" class="mat-form-field2-errors">
      <ng-content select="mat-error"></ng-content>
    </div>

    <div class="mat-form-field-hint-wrapper d-flex align-items-center text-sm" *ngSwitchCase="'hint'">
      <ng-content select="mat-hint:not([align='end'])"></ng-content>
      <div class="mat-form-field-hint-spacer flex-fill">&nbsp;</div>
      <ng-content select="mat-hint[align='end']"></ng-content>
    </div>
  </div>
</div>
