import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BookingFeeId, BookingFee, CreateBookingFeePayload, UpdateBookingFeePayload } from './booking-fee'
import { catchError, map, mergeMap, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import { BookingEngineBookingFee, Inquiry } from '@tokeet/cost-resolver'
import { EMPTY, of } from 'rxjs'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class BookingFeesService {
  constructor(private http: HttpClient) {}

  getApplicableFeesForInquiry(inquiry: Inquiry, account: number, upfront: boolean) {
    return this.http.post<BookingFee[]>(`@api/bookingfees/applicable`, {
      rid: inquiry.rentalId,
      start: inquiry.guestArrive,
      end: inquiry.guestDepart,
      adults: inquiry.numAdults,
      child: inquiry.numChild,
      upfront: upfront ? 1 : 0,
      account,
    })
  }

  getApplicableFees(
    rentalId: string,
    arrive: moment.Moment | Date,
    depart: moment.Moment | Date,
    adults: number,
    child: number,
    account: number,
    upfront = false
  ) {
    return this.http
      .post<BookingEngineBookingFee[]>(`@api/bookingfees/applicable`, {
        rid: rentalId,
        start: moment(arrive).unix(),
        end: moment(depart).unix(),
        adults,
        child,
        upfront: upfront ? 1 : 0,
        account,
      })
      .pipe(catchError(() => EMPTY))
  }

  getAllFees(rentalId?: string) {
    let url = `@api/bookingfees/all`

    if (rentalId) {
      url += `?rental_id=${rentalId}`
    }

    return this.http.get<BookingFee[]>(url).pipe(map((fees) => R.map((f) => this.normalizeBookingFee(f), fees)))
  }

  getFee(feeId: BookingFeeId) {
    const url = `@api/bookingfees/${feeId}`

    return this.http.get<BookingFee>(url)
  }

  createFee(data: CreateBookingFeePayload) {
    const url = `@api/bookingfees`

    return this.http.post<BookingFee>(url, data).pipe(map((fee) => this.normalizeBookingFee(fee)))
  }

  createFees(data: CreateBookingFeePayload[]) {
    return of(...data).pipe(
      mergeMap((fee) => this.createFee(fee)),
      toArray()
    )
  }

  updateFee(feeId: BookingFeeId, data: UpdateBookingFeePayload) {
    const url = `@api/bookingfees/${feeId}`

    return this.http.put<BookingFee>(url, data).pipe(map((fee) => this.normalizeBookingFee(fee)))
  }

  deleteFee(feeId: BookingFeeId) {
    const url = `@api/bookingfees/${feeId}`

    return this.http.delete<BookingFee>(url)
  }

  deleteFees(feeIds: BookingFeeId[]) {
    return of(...feeIds).pipe(
      mergeMap((feeId) => this.deleteFee(feeId)),
      toArray()
    )
  }

  normalizeBookingFee(fee: BookingFee): BookingFee {
    return {
      ...fee,
      account: +fee.account || 0,
      amount: +fee.amount || 0,
      status: +fee.status || 0,
      refundable: +fee.refundable || 0,
      taxable: +fee.taxable || 0,
    }
  }
}
