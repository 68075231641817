import { Component } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { AddExpense, AttachmentService, ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { ExpenseDialog, getExpensePayloadFromForm } from '../../shared/dialogs/expense-dialog.abstract'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { AuthService } from '@tv3/services/auth.service'

@Component({
  selector: 'app-add-expense-dialog',
  templateUrl: '../../shared/dialogs/expense-dialog.component.html',
  styleUrls: ['../../shared/dialogs/expense-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AddExpenseDialogComponent extends ExpenseDialog {
  title = 'Add Expense'

  constructor(
    public dialogRef: MatDialogRef<AddExpenseDialogComponent>,
    protected fb: FormBuilder,
    protected authService: AuthService,
    protected attachmentService: AttachmentService,
    protected store: Store<any>,
    protected confirm: ConfirmDialogService,
    private amplitudeService: AmplitudeService
  ) {
    super(fb, authService, attachmentService, store, confirm)
  }

  close() {
    this.dialogRef.close()
  }

  onSave(form: FormGroup) {
    this.store.dispatch(
      AddExpense({
        payload: {
          ...getExpensePayloadFromForm(form),
          attachments: this.attachments.map((t) => t.id),
        },
      })
    )
    this.amplitudeService.logEvent('add-expenses')
    this.close()
  }
}
