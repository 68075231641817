import { adapter, initialState, State } from './account.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  setAccountTaxexComplete,
  loadAccountComplete,
  updateAccountBillingAddressesComplete,
  updateAccountComplete,
  toggleAccountMFAComplete,
} from './account.actions'
import { updateOne } from '../utility'

export const reducer = createReducer(
  initialState,
  on(loadAccountComplete, (state, { account }) => adapter.addOne(account, R.merge(state, { isLoaded: true }))),
  on(
    updateAccountComplete,
    setAccountTaxexComplete,
    updateAccountBillingAddressesComplete,
    toggleAccountMFAComplete,
    (state, { update }) => updateOne(adapter, update, state)
  )
)

export function accountReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
