<div class="filters-box">
  <div class="flex-fill"></div>

  <div dropdown [isDisabled]="1 | isReadonlyRole" class="btn-group">
    <button
      dropdownToggle
      type="button"
      class="btn btn-secondary-warning dropdown-toggle"
      matTooltip="You can perform multiple actions using this button."
    >
      <span class="cs-icon cs-icon-bolt"></span> Actions
    </button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li>
        <button (click)="onPushRatesSelected()" type="button" [disabled]="!isAnySelected()">
          <span class="fas fa-arrow-up"></span> Push Rates
        </button>
      </li>
      <li>
        <button (click)="onDeleteSelected()" type="button" [disabled]="!isAnySelected()">
          <i class="cs-icon cs-icon-trash"></i> Delete
        </button>
      </li>
    </ul>
  </div>
  <button type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info" (click)="onAdd()">
    <span class="far fa-plus"></span> Add Mapping
  </button>
</div>
<div class="table-holder table-responsive">
  <table class="table table-default table-striped">
    <mat-table #table matSort matSortActive="channelName" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
          <mat-checkbox (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
          <i
            *ngIf="!rateMapHasConnection(row)"
            matTooltip="Rental is not linked to a channel, rate push will fail."
            matTooltipPosition="above"
            class="fal fa-exclamation-triangle ml-3"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="channelName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Channel Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.channelName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="propertyId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Property ID</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.propertyId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="roomId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Room ID</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.roomId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ratename">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Channel Category</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.ratename }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header>AdvanceCM Category</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Mapped</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [matTooltip]="element.created | epoch" matTooltipPosition="above">
            {{ element.created | epoch : 'DD - MMM - YYYY' }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="autosync">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          <span
            matTooltip="Automatic synchronization applies to Dynamic Rates only. When enabled AdvanceCM will automatically update your channel rates as your availability changes."
            matTooltipPosition="below"
          >
            Auto Sync <i class="fas fa-info-circle"></i>
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <app-form-switch
            #slideToggle
            [disabled]="1 | isReadonlyRole"
            [checked]="!!element.autosync"
            [inline]="true"
            (toggleChange)="onChangeSync(element)"
            [matTooltip]="
              element.autosync
                ? 'Synchronization is enabled for this rate mapping.'
                : 'Synchronization is disabled for rate mapping.'
            "
          >
          </app-form-switch>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>

        <mat-cell *matCellDef="let element">
          <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
            <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
              <a class="btn-ellips">
                <i class="fas fa-ellipsis-v"></i>
              </a>
            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
              <li>
                <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i> Edit</button>
              </li>
              <li>
                <button (click)="onPushRate(element)" type="button"><i class="fas fa-arrow-up"></i> Push Rate</button>
              </li>
              <li>
                <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Delete</button>
              </li>
            </ul>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.row-danger]="!rateMapHasConnection(row)"
        (click)="onEdit(row)"
      ></mat-row>
    </mat-table>

    <mat-paginator2
      #paginator
      [length]="dataSource?.data?.length"
      [class.hidden]="isEmptyTable$ | async"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator2>

    <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType" class="bg-white">
      <p>
        Need advanced rate management? Try <a href="https://www.rategenie.io/" target="_blank">Rategenie</a>,
        AdvanceCM's best-in-class dynamic pricing application.
      </p>
    </app-empty-table>
  </table>
</div>
