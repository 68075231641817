import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  AddInvoiceTemplate,
  AddInvoiceTemplateComplete,
  ApplyInvoiceTemplate,
  ApplyInvoiceTemplateComplete,
  DeleteInvoiceTemplate,
  DeleteInvoiceTemplateComplete,
  DeleteInvoiceTemplates,
  DeleteInvoiceTemplatesComplete,
  DuplicateInvoiceTemplate,
  LoadInvoiceTemplates,
  LoadInvoiceTemplatesComplete,
  UpdateInvoiceTemplate,
  UpdateInvoiceTemplateComplete,
} from './template.actions'
import { DataEntityType, Toaster } from '@tokeet-frontend/tv3-platform'
import { InvoiceTemplateService } from './template.service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { TemplateService, TemplateType } from '@tokeet-frontend/templates'
import { InvoiceTemplateCreatePayload, InvoiceTemplateCreateRequest } from '@tokeet-frontend/invoices'
import { AddEntityTagsComplete, EntityTagsService } from '@tokeet-frontend/tags'
import * as R from 'ramda'

@Injectable()
export class InvoiceTemplateEffects {
  @Effect()
  loadInvoiceTemplates$ = this.actions$.pipe(
    ofType(LoadInvoiceTemplates),
    concatMap(() =>
      this.invoiceTemplateService.all().pipe(
        map((templates) => LoadInvoiceTemplatesComplete({ templates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addInvoiceTemplate$ = this.actions$.pipe(
    ofType(AddInvoiceTemplate),
    concatMap(({ template, tags }) => {
      const createInvoiceTemplate = (template) => {
        return this.invoiceTemplateService.create(template).pipe(
          switchMap((template) => {
            if (!tags || !tags.length) return of({ template, tags: [] })

            return this.tagsService
              .addTags(DataEntityType.InvoiceTemplate, template.pkey, tags)
              .pipe(map((tag) => ({ template, tags: [tag] })))
          }),
          switchMap((response) => {
            const actions = [
              AddInvoiceTemplateComplete({ template: response.template }),
              ...response.tags.map((item) => AddEntityTagsComplete({ item })),
            ]

            return of(...actions)
          }),
          tap(() => this.toaster.success('Invoice template created successfully.')),
          catchError((error) => of(ActionFailed({ error })))
        )
      }
      const createTemplate = (subject, body) => {
        const payload = {
          name: 'InvoiceTemplate',
          description: '',
          subject,
          body,
          type: TemplateType.InvoiceTemplate,
        }
        return this.templateService.add(payload, { silent: true })
      }
      const convertPayloadToRequest = (payload: InvoiceTemplateCreatePayload) => {
        return R.pick(
          [
            'account',
            'due_date',
            'payment_terms',
            'payment_instructions',
            'notes',
            'invoice_items',
            'name',
            'description',
            'email_template_id',
            'auto_fees_disabled',
            'tags',
            'type',
          ],
          payload
        ) as InvoiceTemplateCreateRequest
      }
      if (template.email_template_id) {
        return createInvoiceTemplate(convertPayloadToRequest(template))
      } else {
        return createTemplate(template.subject, template.body).pipe(
          switchMap((response) =>
            createInvoiceTemplate(
              convertPayloadToRequest({
                ...template,
                email_template_id: response.id,
              })
            )
          )
        )
      }
    })
  )

  @Effect()
  updateInvoiceTemplate$ = this.actions$.pipe(
    ofType(UpdateInvoiceTemplate),
    concatMap(({ template, silent }) =>
      this.templateService
        .update(template.email_template_id, {
          body: template.body,
          subject: template.subject,
          description: '',
          name: 'InvoiceTemplate',
          type: TemplateType.InvoiceTemplate,
        })
        .pipe(
          switchMap(() =>
            this.invoiceTemplateService.update(template).pipe(
              map((template) =>
                UpdateInvoiceTemplateComplete({ update: { id: template.pkey, changes: template }, silent })
              ),
              catchError((error) => of(ActionFailed({ error })))
            )
          )
        )
    )
  )

  @Effect()
  duplicateInvoiceTemplate$ = this.actions$.pipe(
    ofType(DuplicateInvoiceTemplate),
    concatMap(({ template }) => {
      delete template.pkey
      return this.invoiceTemplateService.create(template).pipe(
        map((created) => AddInvoiceTemplateComplete({ template: created })),
        tap(() => this.toaster.success('Invoice template duplicated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  deleteInvoiceTemplate$ = this.actions$.pipe(
    ofType(DeleteInvoiceTemplate),
    concatMap(({ id }) =>
      this.invoiceTemplateService.remove(id).pipe(
        map(() => DeleteInvoiceTemplateComplete({ id })),
        tap(() => this.toaster.success('Invoice template deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInvoiceTemplates$ = this.actions$.pipe(
    ofType(DeleteInvoiceTemplates),
    concatMap(({ ids }) =>
      this.invoiceTemplateService.deleteBatch(ids).pipe(
        map(() => DeleteInvoiceTemplatesComplete({ ids })),
        tap(() => this.toaster.success('Invoice templates deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  applyInvoiceTemplate$ = this.actions$.pipe(
    ofType(ApplyInvoiceTemplate),
    concatMap(({ bookingId, invoiceTemplateId }) =>
      this.invoiceTemplateService.applyTemplate(bookingId, invoiceTemplateId).pipe(
        map((invoice) => ApplyInvoiceTemplateComplete({ invoice })),
        tap(() => this.toaster.success('Invoice template applied successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private invoiceTemplateService: InvoiceTemplateService,
    private templateService: TemplateService,
    private tagsService: EntityTagsService
  ) {}
}
