<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex justify-content-between align-items-center flex-1 gap-3">
        <div class="d-flex align-items-center gap-2">
          <h6 class="modal-title title mb-0">
            <img [src]="channel.logoColor" [alt]="channel.friendlyName" />
          </h6>
          <app-page-help videoId="-" articleId="-" *ngIf="channel?.name === nameTokens.AirBnBV2API"></app-page-help>
          <app-page-help videoId="-" articleId="-" *ngIf="channel?.name === nameTokens.AgodaAPI"></app-page-help>
          <app-page-help videoId="-" articleId="-" *ngIf="channel?.name === nameTokens.BookingAPI"></app-page-help>
          <app-page-help videoId="-" articleId="-" *ngIf="channel?.name === nameTokens.Trip"></app-page-help>
        </div>
        <div class="flex-fill"></div>
        <button
          *ngIf="connections.length && channel | canAddChannelAccount"
          (click)="onAddAccount()"
          [disabled]="1 | isReadonlyRole"
          type="button"
          matTooltip="Connect another account.  All listings found on connected accounts will appear together under mapped rentals."
          matTooltipPosition="below"
          class="btn btn-secondary-info"
        >
          <span class="far fa-plus"></span> Add Account
        </button>

        <button
          *ngIf="connections.length"
          (click)="onDisconnect()"
          [disabled]="1 | isReadonlyRole"
          type="button"
          class="btn btn-secondary-danger"
        >
          <span class="fal fa-ban" aria-hidden="true"></span> Disconnect
        </button>

        <button
          *ngIf="!connections.length"
          (click)="onConnect()"
          [disabled]="1 | isReadonlyRole"
          type="button"
          class="btn btn-secondary-info"
        >
          <span class="fal fa-plug" aria-hidden="true"></span> Connect
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar border-bottom bg-white" role="tablist">
      <li [class.active]="currentTab === tabs.MappedRentals">
        <a (click)="currentTab = tabs.MappedRentals"> Mapped Rentals </a>
      </li>
      <li [class.active]="currentTab === tabs.Accounts">
        <a (click)="currentTab = tabs.Accounts"> Accounts </a>
      </li>
    </ul>
    <ul class="nav drawer-right-navbar">
      <li [class.active]="currentTab === tabs.Commissions" matTooltip="Commissions" matTooltipPosition="left">
        <a (click)="currentTab = tabs.Commissions">
          <i class="cs-icon cs-icon-dollar-percent text-xxl"></i>
        </a>
      </li>
      <li
        [class.active]="currentTab === tabs.Reviews"
        matTooltip="Reviews"
        matTooltipPosition="left"
        *ngIf="isReviewAvailable"
      >
        <a (click)="currentTab = tabs.Reviews">
          <i class="cs-icon cs-icon-thumb-stars"></i>
        </a>
      </li>
      <ng-container *ngIf="channel | isAirBnBV2">
        <li [class.active]="currentTab === tabs.ABBOpportunities" matTooltip="Opportunities" matTooltipPosition="left">
          <a (click)="currentTab = tabs.ABBOpportunities">
            <i class="cs-icon cs-icon-dollar-light"></i>
          </a>
        </li>
        <li [class.active]="currentTab === tabs.ABBNotifications" matTooltip="Notifications" matTooltipPosition="left">
          <a (click)="currentTab = tabs.ABBNotifications">
            <i class="cs-icon cs-icon-bell"></i>
          </a>
        </li>
      </ng-container>
      <li [class.active]="currentTab === tabs.Formulas" matTooltip="Formulas" matTooltipPosition="left">
        <a (click)="currentTab = tabs.Formulas">
          <i class="cs-icon cs-icon-calendar-cog"></i>
        </a>
      </li>
      <li
        [class.active]="currentTab === tabs.TwoWaySync"
        *ngIf="channel | isAirBnBV2"
        matTooltip="2-Way Sync"
        matTooltipPosition="left"
      >
        <a (click)="currentTab = tabs.TwoWaySync">
          <i class="cs-icon cs-icon-cog-refresh"></i>
        </a>
      </li>
    </ul>
    <div class="modal-body">
      <ng-container [ngSwitch]="currentTab">
        <ng-container *ngSwitchCase="tabs.MappedRentals">
          <div *ngIf="(hasDisabled$ | asyncSchedule | async) && (channel | isAirBnBV2)" class="warning-box">
            We notice you have bad Airbnb connections. Simply click on the Add Account button to reconnect these
            accounts then click on Actions → Refresh Room List. All other settings and rental mappings will remain
            unchanged
          </div>
          <app-channel-mapped-rentals class="container-box d-block" *ngIf="channel" [channel]="channel">
          </app-channel-mapped-rentals>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.Commissions">
          <app-channel-commissions class="d-block" [channel]="channel"></app-channel-commissions>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.ABBOpportunities">
          <div class="d-flex align-items-center gap-3 mb-3">
            <div class="d-flex align-items-center gap-2">
              <h6 class="mb-0">Opportunities</h6>
              <app-page-help articleId="-" videoId="-"></app-page-help>
            </div>
            <div class="flex-fill"></div>
          </div>
          <app-abb-opportunities class="container-box d-block" [channel]="channel"></app-abb-opportunities>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.Reviews">
          <div class="d-flex align-items-center gap-3 mb-3">
            <div class="d-flex align-items-center gap-2">
              <h6 class="mb-0">Reviews</h6>
              <app-page-help articleId="-" videoId="-"></app-page-help>
            </div>
            <div class="flex-fill"></div>
          </div>
          <app-channel-reviews-table class="container-box d-block" [channel]="channel"></app-channel-reviews-table>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.ABBNotifications">
          <div class="d-flex align-items-center gap-3 mb-3">
            <div class="d-flex align-items-center gap-2">
              <h6 class="mb-0">Notifications</h6>
              <app-page-help articleId="-" videoId="-"></app-page-help>
            </div>
            <div class="flex-fill"></div>
          </div>
          <app-abb-notifications class="container-box d-block" [channel]="channel"></app-abb-notifications>
        </ng-container>

        <ng-container *ngSwitchCase="tabs.Accounts">
          <app-channel-accounts [channel]="channel"></app-channel-accounts>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.Formulas">
          <ng-template [ngTemplateOutlet]="formulas"></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="tabs.TwoWaySync">
          <app-abb-sync-settings [channel]="channel"></app-abb-sync-settings>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #formulas>
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Booking Formulas</h6>
      <app-page-help articleId="-" videoId="-"></app-page-help>
    </div>
    <div class="flex-fill"></div>
    <button
      type="button"
      class="btn btn-secondary-danger"
      (click)="table?.onDeleteSelected()"
      [disabled]="table?.selection.isEmpty()"
    >
      <i class="cs-icon cs-icon-trash"></i>&nbsp; Delete
    </button>

    <button type="button" class="btn btn-secondary-info" (click)="table?.onAdd()">
      <i class="far fa-plus"></i>&nbsp; Add
    </button>
  </div>
  <app-booking-formulas-table [channel]="channel" #table></app-booking-formulas-table>
</ng-template>
