import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ChannelCommissionPayload, ChannelCommissionResponse } from './model'

@Injectable({
  providedIn: 'root',
})
export class ChannelCommissionService {
  constructor(private http: HttpClient) {}

  all(channelId?: string) {
    let url = `@api/channel/commission/all/`
    if (channelId) {
      url = `${url}?channel_id=${channelId}`
    }
    return this.http.get<ChannelCommissionResponse[]>(url)
  }

  create(payload: ChannelCommissionPayload) {
    const url = `@api/channel/commission`
    return this.http.post<ChannelCommissionResponse>(url, payload)
  }

  update(id: string, payload: ChannelCommissionPayload) {
    const url = `@api/channel/commission/${id}`
    return this.http.put<ChannelCommissionResponse>(url, payload)
  }

  delete(id: string) {
    const url = `@api/channel/commission/delete/${id}`
    return this.http.delete(url)
  }
}
