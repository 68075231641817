import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import { deserializeArray, sortAscend, Toaster } from '@tokeet-frontend/tv3-platform'
import { Website } from '@tv3/store/website/website.model'
import { WebsiteEngineResponse } from '@tv3/interfaces/responses/engine.response'
import { AddWebsitePayload, WebsitePageSettings, WebsiteTemplate } from '@tv3/store/website/website.types'

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all(): Observable<Website[]> {
    const url = '@api/websites/'

    return this.http.get(url).pipe(deserializeArray<Website>(Website))
  }

  allWebsites(): Observable<Website[]> {
    const url = '@api/websites/listings/'
    return this.http.get(url).pipe(deserializeArray<Website>(Website))
  }

  allEngines(): Observable<WebsiteEngineResponse[]> {
    const url = '@api/websites/engines/'
    return this.http
      .get<WebsiteEngineResponse[]>(url)
      .pipe(map((engines) => sortAscend<WebsiteEngineResponse>('title')(engines)))
  }

  allWebsitesForRental(rentalId: string): Observable<Website[]> {
    const url = `@api/websites/rental/${rentalId}`
    return this.http.get(url).pipe(deserializeArray<Website>(Website))
  }

  getGuestPortalForRental(rentalId: string): Observable<Website> {
    const url = `@api/website/portal/rental/${rentalId}`
    return this.http.get<Website>(url).pipe(map((res) => Website.deserialize(res)))
  }

  get(id: string): Observable<Website> {
    const url = `@api/website/${id}`

    return this.http.get<Website>(url).pipe(
      take(1),
      map((response) => Website.deserialize(response)),
      catchError(() => {
        this.toast.error('Unable to load website')
        return throwError({ error: 'Unable to load website' })
      })
    )
  }

  add(data: AddWebsitePayload): Observable<Website> {
    const template_type = this.getWebsiteTemplateType(data)

    if (template_type) {
      data = { ...data, template_type }
    }

    const url = `@api/website/`
    return this.http.post(url, data).pipe(map((res) => Website.deserialize(res as Website)))
  }

  update(id: string, data): Observable<Website> {
    const template_type = this.getWebsiteTemplateType(data)

    if (template_type) {
      data = { ...data, template_type }
    }

    const url = `@api/website/${id}`
    return this.http.put<Website>(url, data).pipe(map((res) => Website.deserialize(res)))
  }

  enableOnlineBookings(websiteId: string) {
    const url = `@api/website/enable/booking/${websiteId}`
    return this.http.put(url, {})
  }

  disableOnlineBookings(websiteId: string) {
    const url = `@api/website/disable/booking/${websiteId}`
    return this.http.put(url, {})
  }

  publish(domain: string): Observable<{ lastpublished: number }> {
    const url = `@api/website/publish/?domain=${domain}`

    return this.http.post<{ lastpublished: number }>(url, {})
  }

  isValidDomain(domain: string): boolean {
    // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
    const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i
    return regex.test(domain)
  }

  isDomainAvailable(domain: string) {
    const url = `@api/website/domain/available/`
    return this.http.put(url, { domain })
  }

  delete(id: string) {
    const url = `@api/website/${id}`
    return this.http.delete(url)
  }

  templates(): Observable<WebsiteTemplate[]> {
    const url = `@api/website/templates`
    return this.http.get<WebsiteTemplate[]>(url)
  }

  // Pages
  addPage(websiteId: string, data: any): Observable<WebsitePageSettings> {
    const url = `@api/website/page/${websiteId}`
    return this.http.post(url, data).pipe(map(WebsitePageSettings.deserialize))
  }

  updatePage(websiteId: string, data: any): Observable<WebsitePageSettings> {
    const url = `@api/website/page/${websiteId}`
    return this.http.put(url, data).pipe(map(WebsitePageSettings.deserialize))
  }

  updatePageStatus(websiteId: string, pageId: string, status: 0 | 1) {
    const url = `@api/website/page/status/${websiteId}`
    return this.http.put(url, { pkey: pageId, status })
  }

  deletePage(websiteId, pageId) {
    const url = `@api/website/page/${websiteId}/${pageId}`
    return this.http.delete(url)
  }

  private getWebsiteTemplateType(data: any) {
    // TV3-2517 - if website has only 1 rental, template type should be set to "single"

    const rentals: string[] = data && data.rentals

    if (!rentals || !rentals.length) return null

    return rentals.length > 1 ? 'multiple' : 'single'
  }
}
