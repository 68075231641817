import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { AuthorizedIntegrationsById, IntegrationAuthData } from './integration.model'

export const STORE_ID = 'integration'

export interface State extends EntityState<IntegrationAuthData> {
  isAuthorizedIntegrationLoaded: {
    [key: string]: Boolean
  }
  authorizedIntegrations: AuthorizedIntegrationsById
}

export const adapter: EntityAdapter<IntegrationAuthData> = createEntityAdapter<IntegrationAuthData>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isAuthorizedIntegrationLoaded: {},
  authorizedIntegrations: {},
})
