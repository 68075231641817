import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { NgProgressModule } from 'ngx-progressbar'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { AppRoutingModule } from './app-routing.module'
import { RootStoreModule } from './store/store.module'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { SharedModule } from './shared/shared.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ReactiveFormsModule } from '@angular/forms'
import { ToastrModule } from 'ngx-toastr'
import { ApiInterceptor } from './interceptors/api.interceptor'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions'
import { AuthService, USER_PERMISSIONS, USER_ROLE } from '@tv3/services/auth.service'
import { AgmCoreModule, LAZY_MAPS_API_CONFIG, LazyMapsAPILoaderConfigLiteral } from '@agm/core'
import { NgProgressRouterModule } from 'ngx-progressbar/router'
import { LayoutModule } from '@tv3/layout/layout.module'
import { captureException, init } from '@sentry/browser'
import { environment } from '../environments/environment'
import { PerfumeModule } from 'perfume.js/angular'
import {
  AUTH_TOKEN,
  CURRENT_ACCOUNT_ID,
  ENVIRONMENT,
  LocalStorage,
  Tv3PlatformModule,
  User,
} from '@tokeet-frontend/tv3-platform'
import { ServiceWorkerModule } from '@angular/service-worker'
import { metaReducers, STORAGE_PREFIX, CURRENT_USER } from '@tokeet-frontend/tv3-platform'
import { VERSION } from '../environments/version'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip'

if (!!environment.config.sentryDns) {
  init({
    dsn: environment.config.sentryDns,
    release: `tv3@${VERSION.hash}`,
    environment: environment.environment,
    ignoreErrors: ['ResizeObserver loop limit exceeded', new RegExp(/ChunkLoadError/g)],
    beforeSend(event) {
      if (['ProgressEvent', 'XMLHttpRequestProgressEvent'].includes((event?.extra?.__serialized__ as any)?.error)) {
        return null
      }
      return event
    },
  })
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {
    captureException(error.originalError || error)
    throw error
  }
}

export function setPermissions(storage: LocalStorage, ps: NgxPermissionsService) {
  return function () {
    return ps.loadPermissions(storage.get(USER_PERMISSIONS, []))
  }
}

export function setRole(storage: LocalStorage, ps: NgxRolesService) {
  return function () {
    return ps.addRole(storage.get(USER_ROLE, ''), storage.get(USER_PERMISSIONS, []))
  }
}

@Injectable()
export class CustomSvgHttpLoader extends SvgLoader {
  constructor(private http: HttpClient) {
    super()
  }

  getSvg(icon: string): Observable<string> {
    if (icon.endsWith('.svg')) {
      return this.http.get(icon, { responseType: 'text' })
    }
    return this.http.get(`/assets/icons/svgs/${icon}.svg`, { responseType: 'text' })
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    StoreModule.forRoot(<any>{}, { metaReducers: metaReducers(environment) }),
    EffectsModule.forRoot([]),
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    SharedModule,
    LayoutModule,
    RootStoreModule,
    AppRoutingModule,
    HttpClientXsrfModule.disable(),
    Tv3PlatformModule,
    PerfumeModule.forRoot({
      firstContentfulPaint: true,
      firstInputDelay: true,
      logPrefix: 'Benchmark:',
      logging: environment.perfume,
    }),
    NgxPermissionsModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.config.googleMapKey,
    }),
    AngularSvgIconModule.forRoot({
      loader: { provide: SvgLoader, useClass: CustomSvgHttpLoader },
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.environment === 'production' }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setPermissions,
      deps: [LocalStorage, NgxPermissionsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setRole,
      deps: [LocalStorage, NgxRolesService],
      multi: true,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useFactory: (): MatTooltipDefaultOptions => ({
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1500,
        position: 'above',
      }),
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: STORAGE_PREFIX,
      useValue: 'tv3',
    },
    {
      provide: CURRENT_USER,
      useValue: new BehaviorSubject<User>(null),
    },
    {
      provide: CURRENT_ACCOUNT_ID,
      useValue: new BehaviorSubject<number>(null),
    },
    {
      provide: AUTH_TOKEN,
      useValue: new BehaviorSubject<string>(''),
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: LAZY_MAPS_API_CONFIG,
      useValue: <LazyMapsAPILoaderConfigLiteral>{
        apiKey: environment.config.googleMapKey,
        libraries: ['places'],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
