import { createAction, props } from '@ngrx/store'
import { CalendarEvent, CreateHoldEventpayload, UpdateHoldEventPayload } from './calendar.model'
import { Update } from '@ngrx/entity'
import { ShareCalendarRequest } from '@tv3/interfaces/requests/calendar.request'

export const LoadHoldEvents = createAction('[Calendar] Load Holds Event', props<{ rentalId?: string }>())
export const LoadHoldEventsComplete = createAction(
  '[Calendar] Load Hold Events Complete',
  props<{ events: CalendarEvent[] }>()
)

export const AddHoldEvent = createAction(
  '[Calendar] Add Hold Event',
  props<{ payloads: CreateHoldEventpayload[]; holdId?: string; recurring?: boolean }>()
)
export const AddHoldEventComplete = createAction(
  '[Calendar] Add Hold Event Complete',
  props<{ events: CalendarEvent[] }>()
)

export const ReceateRecurringHoldEvents = createAction(
  '[Calendar] Recreate Recurring Hold Event',
  props<{ payloads: CreateHoldEventpayload[]; group_id: string }>()
)

export const UpdateHoldEvent = createAction(
  '[Calendar] Update Hold Event',
  props<{ payload: UpdateHoldEventPayload; holdId: string; group_id?: string }>()
)
export const UpdateHoldEventComplete = createAction(
  '[Calendar] Update Hold Event Complete',
  props<{ updates: Update<CalendarEvent>[] }>()
)

export const ClearHoldEvents = createAction('[Calendar] Clear Hold Events', props<{ ids: string[] }>())

export const DeleteHoldEvent = createAction(
  '[Calendar] Delete Hold Event',
  props<{ id: string; silent?: boolean; group_id?: string }>()
)
export const DeleteHoldEventComplete = createAction('[Calendar] Delete Hold Event Complete', props<{ id: string }>())

export const DeleteHoldEvents = createAction('[Calendar] Delete Holds Event', props<{ ids: string[] }>())
export const DeleteHoldEventsComplete = createAction(
  '[Calendar] Delete Hold Events Complete',
  props<{ ids: string[] }>()
)

export const LoadCalendarEventsByRental = createAction('[Calendar] Load Events By Rental', props<{ id: string }>())
export const LoadCalendarEventsByRentalComplete = createAction(
  '[Calendar] Load Events By Rental Complete',
  props<{ events: CalendarEvent[] }>()
)

export const LoadCalendarRange = createAction(
  '[Calendar] Load Range',
  props<{ start: number; end: number; uid?: any; reset?: boolean }>()
)
export const LoadCalendarRangeComplete = createAction(
  '[Calendar] Load Range Complete',
  props<{ events: CalendarEvent[]; uid?: any; reset?: boolean }>()
)

export const ShareCalendar = createAction(
  '[Calendar] Share Calendar',
  props<{ request: ShareCalendarRequest; protect?: boolean }>()
)

export const DeleteCalendarEventsComplete = createAction(
  '[Calendar] Delete Calendar Events Calendar',
  props<{ ids: string[] }>()
)

export const SetInquiryNotesReadStatus = createAction(
  '[Calendar] Inquiry Notes Read Status',
  props<{ id: string; status: 0 | 1 }>()
)
