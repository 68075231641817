<app-form-field-render [field]="self" [prefix]="type === 'search' && searchPrefixTmpl">
  <ng-container *ngIf="multiline; else singleLine">
    <ng-template [ngTemplateOutlet]="textareaTmpl"></ng-template>
  </ng-container>

  <ng-template #singleLine>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'number'">
        <ng-template [ngTemplateOutlet]="numberInputTmpl"></ng-template>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="input?.templateRef || inputTmpl"></ng-template>
      </ng-container>
    </ng-container>
  </ng-template>
</app-form-field-render>

<ng-template #inputTmpl>
  <input
    [type]="type === 'search' ? 'text' : type"
    class="form-control"
    [formControl]="ctrl"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [class.is-invalid]="isTouchedInvalid"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    [placeholder]="placeholder || ''"
    [value]="value"
  />
</ng-template>

<ng-template #numberInputTmpl>
  <input
    type="number"
    class="form-control"
    [formControl]="ctrl"
    [max]="max"
    [min]="min"
    [step]="step"
    [class.is-invalid]="isTouchedInvalid"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    [placeholder]="placeholder || ''"
    [value]="value"
  />
</ng-template>

<ng-template #textareaTmpl>
  <div class="form-control multiline">
    <div class="multiline-control-wrapper" [attr.data-value]="ctrl?.value">
      <textarea
        [formControl]="ctrl"
        [rows]="multiline"
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        [class.is-invalid]="isTouchedInvalid"
        [placeholder]="placeholder || ''"
      ></textarea>
    </div>
  </div>
</ng-template>

<ng-template #searchPrefixTmpl>
  <form-field-affix>
    <i class="far fa-search"></i>
  </form-field-affix>
</ng-template>
