import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
  Channel,
  Rental,
  rentalDetails,
  rentalQuantities,
  RentalService,
  SaveForm,
  Toaster,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { rentalTypes } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as lodash from 'lodash'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import { AgodaRoomCreateComplete, AgodaRoomUpdateComplete } from '@tv3/store/channel-content/conent.actions'
import { delay, mapTo, switchMap, take, tap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { Actions, ofType } from '@ngrx/effects'
import { selectNewAvailableChannelIdForNewApiConnection } from '@tv3/store/connection/connection.selectors'
import {
  RefreshConnectionsForChannel,
  RefreshConnectionsForChannelComplete,
} from '@tv3/store/connection/connection.actions'
import { AgodaContentProperty, AgodaContentRoom } from '@tv3/store/channel-content/models/agoda.model'
import { AgodaContentService } from '@tv3/store/channel-content/agoda-content.service'
import {
  agodaBedTypeOptions,
  availableRoomAmenitiesTokeetIds,
  isAgodaBedTypeAmenityCode,
} from '@tv3/store/channel-property-setting/settings/agoda-property-settings'
import { AgodaContentRoomResponse } from '@tv3/store/channel-content/payload/agoda.response'

@Component({
  selector: 'app-agoda-content-room-setup',
  templateUrl: './agoda-content-room-setup.component.html',
  styleUrls: ['./agoda-content-room-setup.component.scss'],
})
export class AgodaContentRoomSetupComponent implements OnInit, OnChanges {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() agodaProperty: AgodaContentProperty

  get agodaRoom(): AgodaContentRoom {
    return this.agodaProperty && lodash.get(this.agodaProperty.rooms, 0)
  }

  quantities = rentalQuantities
  types = rentalTypes
  bedTypeOptions = agodaBedTypeOptions
  rentalAmenityDetails = rentalDetails.filter((item) => availableRoomAmenitiesTokeetIds.indexOf(item.id) > -1)

  baseRoomForm = this.fb.group({
    name: [undefined, [Validators.required]],
    bedrooms: [undefined, [Validators.required]],
    bathrooms: [undefined, [Validators.required]],
  })

  bedrooms = this.fb.array([])
  roomForm = this.fb.group({
    base: this.baseRoomForm,
    bedrooms: this.bedrooms,
  })

  rentalDetailsForm = this.fb.group({
    detail: this.fb.array([]),
  })

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    private actions: Actions,
    private store: Store<any>,
    private rentalService: RentalService,
    private connectionService: ConnectionService,
    private agodaContentService: AgodaContentService
  ) {}

  ngOnInit() {
    const controls = R.map(
      (d) => this.fb.control(R.indexOf(d.id, R.keys(this.rental.detail)) !== -1),
      this.rentalAmenityDetails
    )
    this.rentalDetailsForm.setControl('detail', this.fb.array(controls))
  }

  ngOnChanges(changes: SimpleChanges): void {
    const agodaPropertyChange = changes['agodaProperty']
    if (agodaPropertyChange && agodaPropertyChange.currentValue) {
      this.setBaseRoomForm(agodaPropertyChange.currentValue)
      this.setBedroomsForm(agodaPropertyChange.currentValue)
    }
  }

  setBaseRoomFormByRental(rental: Rental) {
    this.baseRoomForm.patchValue({
      bedrooms: rental.bedrooms,
      bathrooms: rental.bathrooms,
    })
  }

  setBaseRoomForm(property: AgodaContentProperty) {
    if (this.agodaRoom) {
      this.baseRoomForm.patchValue({ name: this.agodaRoom.name })
    }
    this.setBaseRoomFormByRental(this.rental)
  }

  setBedroomsForm(property: AgodaContentProperty) {
    let bedrooms = (this.agodaRoom && this.agodaRoom.bedrooms) || []
    if (!this.agodaRoom || !bedrooms || !bedrooms.length) {
      // @ts-ignore
      bedrooms = [{}]
    }
    this.bedrooms.clear()
    lodash.forEach(bedrooms, (bedroom) => {
      const form = this.getNewBedroomForm()
      form.patchValue({
        roomType: bedroom.type,
        bedType: lodash.get(
          lodash.filter(bedroom.amenities, ({ code }) => isAgodaBedTypeAmenityCode(code)),
          '0.code'
        ),
      })
      this.bedrooms.push(form)
    })
  }

  getNewBedroomForm() {
    return this.fb.group({
      roomType: [undefined, [Validators.required]],
      bedType: [undefined, [Validators.required]],
    })
  }

  addOneBedroom() {
    this.bedrooms.push(this.getNewBedroomForm())
  }

  removeOneBedroom(idx: number) {
    this.bedrooms.removeAt(idx)
  }

  linkWithRental(roomData: AgodaContentRoomResponse) {
    return this.store.pipe(select(selectNewAvailableChannelIdForNewApiConnection, { id: this.channel.id })).pipe(
      take(1),
      switchMap((channelId) =>
        this.connectionService.link({
          channelId,
          propertyId: roomData.propertyId,
          roomId: roomData.roomId,
          rentalId: this.rental.id,
        })
      )
    )
  }

  getSelectedAmenities() {
    const { detail: rentalDetailCheckData } = this.rentalDetailsForm.getRawValue()
    const details = {
      ...this.rental.detail,
    }
    rentalDetailCheckData.forEach((value, i) => (details[this.rentalAmenityDetails[i].id] = !!value))
    return lodash.reduce(
      details,
      (acc, value, key) => {
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
  }

  saveRentalData() {
    const details = this.getSelectedAmenities()
    const { bedrooms, bathrooms } = this.baseRoomForm.getRawValue()
    const payload = {
      bedrooms,
      bathrooms,
      detail: details,
    }
    // @ts-ignore
    return this.rentalService.update(this.rental.id, payload).pipe(
      tap((rental: Rental) =>
        this.store.dispatch(
          updateRentalComplete({
            update: {
              id: rental.id,
              changes: rental,
            },
          })
        )
      )
    )
  }

  createRoom({ base, bedrooms }) {
    this.saveRentalData()
      .pipe(
        switchMap(() =>
          this.agodaContentService.createRoom(this.agodaProperty.propertyId, {
            name: base.name,
            bedrooms,
            rentalId: this.rental.id,
          })
        ),
        delay(500), // wait for backend refresh
        tap(() =>
          this.store.dispatch(
            RefreshConnectionsForChannel({
              channelName: this.channel.name,
              channelId: this.channel.id,
              silent: true,
            })
          )
        ),
        switchMap((data) => {
          return this.actions.pipe(ofType(RefreshConnectionsForChannelComplete), mapTo(data))
        }),
        switchMap((roomData: AgodaContentRoomResponse) => this.linkWithRental(roomData).pipe(mapTo(roomData)))
      )
      .subscribe(({ propertyId, roomId, warnings }) => {
        this.store.dispatch(AgodaRoomCreateComplete({ propertyId, roomId }))
        this.toaster.success('Room created successfully.')
        if (warnings && warnings.length) {
          this.toaster.warning('Room Warnings: ' + warnings.join('\n'))
        }
      })
  }

  updateRoom({ base, bedrooms }) {
    this.saveRentalData()
      .pipe(
        switchMap(() =>
          this.agodaContentService.updateRoom(this.agodaProperty.propertyId, this.agodaRoom.roomId, {
            name: base.name,
            bedrooms,
            rentalId: this.rental.id,
          })
        )
      )
      .subscribe(({ propertyId, roomId, warnings }) => {
        this.store.dispatch(AgodaRoomUpdateComplete({ propertyId, roomId }))
        this.toaster.success('Room updated successfully.')
        if (warnings && warnings.length) {
          this.toaster.warning('Room Warnings: ' + warnings.join('\n'))
        }
      })
  }

  isRoomFormValid(): string {
    const bedroomsCount = this.baseRoomForm.get('bedrooms').value
    if (this.bedrooms.length !== bedroomsCount) {
      return `You have to specify ${bedroomsCount} bedroom(s).`
    }

    const { detail } = this.rentalDetailsForm.getRawValue()
    if (!detail.filter((d) => d).length) {
      return `Please select at least one amenity.`
    }

    return ''
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.roomForm.getRawValue()
    if (this.agodaRoom && this.agodaRoom.roomId) {
      this.updateRoom(formData)
    } else {
      this.createRoom(formData)
    }
  }
}
