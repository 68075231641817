import { createAction, props } from '@ngrx/store'
import { PaymentGateway } from './gateways.model'

export const LoadPaymentGateways = createAction('[Gateways] Load')
export const LoadPaymentGatewaysComplete = createAction(
  '[Gateways] Load Complete',
  props<{ gateways: PaymentGateway[] }>()
)

export const ClearPaymentGateways = createAction('[Gateways] Clear')

export const UpdatePaymentGateway = createAction('[Gateways] Update', props<{ id: string; changes: any }>())
export const UpdatePaymentGatewayComplete = createAction(
  '[Gateways] Update Complete',
  props<{ id: string; paymentGateway: PaymentGateway }>()
)

export const UpdatePaymentGatewayCredentials = createAction('[Gateways] Update Credentials', props<{ payload: any }>())

export const TogglePaymentGatewayStatus = createAction(
  '[Gateways] Toggle Status',
  props<{ id: string; status: boolean }>()
)
export const TogglePaymentGatewayStatusComplete = createAction(
  '[Gateways] Toggle Status Complete',
  props<{ id: string; status: boolean }>()
)

export const SetDefaultPaymentGateway = createAction(
  '[Gateways] Set Default',
  props<{ id: string; silent?: boolean }>()
)
export const SetDefaultPaymentGatewayComplete = createAction('[Gateways] Set Default Complete', props<{ id: string }>())

export const RemovePaymentGateway = createAction('[Gateways] Remove', props<{ id: string }>())
export const RemovePaymentGatewayComplete = createAction('[Gateways] Remove Complete', props<{ id: string }>())

export const RemoveSpreedlyPaymentGateway = createAction('[Gateways] Remove Spreedly', props<{ id: string }>())
export const RemoveSpreedlyPaymentGatewayComplete = createAction(
  '[Gateways] Remove Spreedly Complete',
  props<{ id: string }>()
)
