import { Component, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions'
import { NgPerfume } from 'perfume.js/angular'
import { debounceTime, distinctUntilChanged, filter, take, tap, map } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as moment from 'moment'
import {
  CalendarComponent,
  CalendarConfig,
  CalendarEventTimeRange,
  CalendarSelectionInfo,
  CalendarViewPortInfo,
} from '@tokeet/ngx-multi-event-calendar'

import { FilterGroup, FiltersService } from '@tv3/services/utils/filters.service'
import * as fromRoot from '@tv3/store/state'
import {
  AddHoldEventComplete,
  DeleteHoldEventComplete,
  LoadCalendarRange,
  LoadCalendarRangeComplete,
  UpdateHoldEventComplete,
} from '@tv3/store/calendar/calendar.actions'
import { AuthService } from '@tv3/services/auth.service'
import { selectCalendarLoading, selectFilteredMultiCalendarEventsViews } from '@tv3/store/calendar/calendar.selectors'
import { MultiCalendarEventView } from '@tv3/store/calendar/calendar.view'
import {
  DateRangePickerSelection,
  DefaultRentalColor,
  Destroyable,
  isSomething,
  localTodayToUTC,
  Rental,
  selectAllRentals,
  selectRentalsLoaded,
  itemsInTimeRange,
  toMoment,
  untilDestroy,
  RentalRateItem,
  updateRentalComplete,
  updateRentalBaseRateComplete,
} from '@tokeet-frontend/tv3-platform'
import { MultiCalendarAvailabilityTypes } from '@tv3/interfaces/filters/calendar.filters'
import { ActionDialogService } from '@tv3/shared/dialogs/action-dialog/action-dialog.service'
import {
  CancelBookingComplete,
  ChangeBookingDetailsComplete,
  ChangeInquiryArriveComplete,
  ChangeInquiryDepartComplete,
  ChangeInquiryGuestComplete,
  ConfirmBookingComplete,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { ImportCalendarDialogService } from '@tv3/containers/calendar/shared/import-calendar-dialog/import-calendar-dialog.service'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { ShareCalendarDialogService } from '../shared/share-calendar-dialog/share-calendar-dialog.service'
import * as R from 'ramda'
import { AddBookingComplete, DeleteInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import { AddImportedCalendarConnectionComplete } from '@tv3/store/connection/connection.actions'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { distinctObjectUntilChangedByFields } from '@tokeet-frontend/tv3-platform'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { RatesService } from '@tv3/containers/rates/rate-services/rates.service'
import { BookedDatesService } from '@tv3/store/calendar/booked-dates.service'
import { EditRateDialogInput } from '@tv3/containers/rates/edit-rate-dialog/edit-rate-dialog.component'
import { EditRateDialogService } from '@tv3/containers/rates/edit-rate-dialog/edit-rate-dialog.service'
import { AccountRatesDialogTab } from '@tv3/containers/rates/account-rates-dialog/account-rates-dialog.component'
import { AccountRatesDialogService } from '@tv3/containers/rates/account-rates-dialog/account-rates-dialog.service'
import { environment } from '@tv3/environments/environment'
import { HoldDialogService } from '../hold/hold-dialog/hold-dialog.service'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { AddRentalDialogService } from '@tv3/containers/rentals/add/add-rental-dialog.service'
import * as jQuery from 'jquery'
import * as bootstrap from 'bootstrap'

@Component({
  selector: 'app-calendar-multi',
  templateUrl: './calendar-multi.component.html',
  styleUrls: ['../shared/calendar-component-common.scss', './calendar-multi.component.scss'],
  providers: [BookedDatesService],
})
export class CalendarMultiComponent extends Destroyable implements OnInit {
  calendarUrl = ''

  // # region toolbar

  years: number[]
  months: number[]

  selectedYear: number
  selectedMonth: number

  lastNavigatingDate: number = null

  filters = this.filtersService.getMultiCalendarFilters()
  isFilteringIgnoreFields = ['start', 'end', 'availabilityType', 'initialDate', 'pageIndex', 'pageSize']

  filterAvailabilityDateRange: DateRangePickerSelection
  filterAvailabilityType: MultiCalendarAvailabilityTypes

  availabilityTypes = MultiCalendarAvailabilityTypes

  autoCloseDropdownFilters = true

  filtersCount = 0
  showDiscounts = environment.features.discounts

  // #endregion toolbar

  // # region calendar

  config: CalendarConfig

  tableTypes = TableType

  rentals: Rental[] = []
  events: Record<string, MultiCalendarEventView[]> = {}

  filteredRentals: Rental[] = []
  filteredEvents: Record<string, MultiCalendarEventView[]> = {}

  filterTags: string[]
  filterChannels: string[]

  activePage = this.filters.get('pageIndex').value
  pageSize = this.filters.get('pageSize').value

  pageSizes = [5, 10, 20, 50, 100, 200, 500]
  pageRange = { from: 0, to: 0 }

  activeMonth: number
  loading = false

  @ViewChild('calendar', { static: false })
  calendar: CalendarComponent<Rental, MultiCalendarEventView>

  @ViewChild('paginator', { static: true })
  paginator: MatPaginator

  today = localTodayToUTC().unix()

  defaultRentalColor = DefaultRentalColor

  // # endregion calendar

  // # region tooltip

  currencySymbol: string

  // # endregion tooltip

  private uidFilterRentals: string = null

  isAutomatedRateMenuOpen = false

  protected readonly automatedRatesTabs = AccountRatesDialogTab

  constructor(
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private perfume: NgPerfume,
    private ngxRolesService: NgxRolesService,
    private filtersService: FiltersService,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    private holdDialog: HoldDialogService,
    private shareCalendarDialog: ShareCalendarDialogService,
    private importCalendarDialog: ImportCalendarDialogService,
    private ngxPermissionsService: NgxPermissionsService,
    private actionDialogService: ActionDialogService,
    private calendarService: CalendarService,
    private ratesService: RatesService,
    private amplitudeService: AmplitudeService,
    private bookedDatesService: BookedDatesService,
    private editRateDialogService: EditRateDialogService,
    private accountRatesDialogService: AccountRatesDialogService,
    private addRentalDialog: AddRentalDialogService
  ) {
    super()
    perfume.start('MultiCalendar')
  }

  ngOnInit() {
    this.amplitudeService.logEvent('calendar-view-multi')

    this.config = {
      sidebarWidth: 235,
      headerHeight: 60,
      cellWidth: 70,
      cellHeight: 60,
      pageIndex: this.activePage,
      pageSize: this.pageSize,
      showStrippedColumns: false,
      overlapMode: 'day',
      overlapThreshold: 1,
      trimEventHours: true,
      eventSpanAlign: 'end',
      selectionMode: 'multiple-row',
    }

    this.subscribeStore()
    this.subscribeFilters()

    this.prepareYears()

    this.filterRentals()
  }

  prepareCalendarTooltips() {
    requestAnimationFrame(() => {
      jQuery('.calendar-container [title]').each(function () {
        const titleText = jQuery(this).attr('title')
        if (titleText) {
          jQuery(this).removeAttr('title')
          new bootstrap.Tooltip(this, {
            title: titleText,
            placement: 'top',
            html: true,
          })
        }
      })
    })
  }
  renderEventTooltip(event, overlaps) {
    return `
      <div>
        <div>${event?.titleView || ''}</div>
        ${overlaps.map((item) => `<div>${item.titleView}</div>`).join('')}
      </div>
    `
  }

  // #region store

  private subscribeStore() {
    // this.prepareCalendarUrl();

    this.store
      .pipe(
        select(selectCalendarLoading),
        distinctUntilChanged(),
        tap((loading) => {
          this.loading = loading
          if (!loading) {
            this.prepareCalendarTooltips()
          }
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store
      .pipe(
        select(selectAllRentals),
        distinctObjectUntilChangedByFields(['name', 'status', 'images', 'bedrooms', 'bathrooms'], 'id'),
        tap((rentals) => {
          this.updateRentals(rentals)
          this.filterRentals()
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store
      .pipe(
        select(selectFilteredMultiCalendarEventsViews(this.filters.getRawValue())),
        distinctUntilChanged(),
        tap((events) => {
          this.bookedDatesService.refresh(lodash.flatten(lodash.values(events)))
          this.updateEvents(events)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.actions$
      .pipe(
        ofType(LoadCalendarRangeComplete),
        tap((action) => {
          if (this.uidFilterRentals && action.uid === this.uidFilterRentals) {
            this.uidFilterRentals = null
            this.filterRentals()
          }
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.actions$
      .pipe(
        ofType(
          AddHoldEventComplete,
          UpdateHoldEventComplete,
          DeleteHoldEventComplete,
          AddImportedCalendarConnectionComplete,
          AddBookingComplete,
          DeleteInquiryComplete,
          ChangeInquiryArriveComplete,
          ChangeInquiryDepartComplete,
          ChangeInquiryGuestComplete,
          ConfirmBookingComplete,
          CancelBookingComplete,
          ChangeBookingDetailsComplete
        ),
        tap(() => {
          this.onViewportChange(this.calendar.activeViewportInfo)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.actions$
      .pipe(
        ofType(updateRentalComplete, updateRentalBaseRateComplete),
        map((a) => a.update.id as string),
        untilDestroy(this)
      )
      .subscribe((rentalId) => {
        const viewportInfo = this.calendar.activeViewportInfo
        this.refreshRates([rentalId], viewportInfo.startDate, viewportInfo.endDate, true)
      })
  }

  private fetchEvents(from: number, to: number, uid: any = null) {
    this.store.dispatch(
      LoadCalendarRange({
        start: from,
        end: to,
        uid,
      })
    )
  }

  // #endregion store

  // #region toolbar

  onRefresh() {
    const viewportInfo = this.calendar.activeViewportInfo
    this.fetchEvents(viewportInfo.startDate, viewportInfo.endDate)
    this.fetchRates(viewportInfo.visibleItems, viewportInfo.startDate, viewportInfo.endDate, true)
  }

  navigate(type: 'prev' | 'next') {
    if (!isSomething(this.calendar)) {
      return
    }

    const viewportInfo = this.calendar.activeViewportInfo
    const days = toMoment(viewportInfo.endDate).diff(toMoment(viewportInfo.startDate), 'days')
    const date = toMoment(this.lastNavigatingDate || viewportInfo.startDate)

    if (type === 'next') {
      date.add(days, 'days')
    } else if (type === 'prev') {
      date.subtract(days, 'days')
    }

    this.lastNavigatingDate = date.startOf('date').unix()

    this.calendar.navigate(this.lastNavigatingDate)
  }

  onChangeYear = (type: 'prev' | 'next' = null) => {
    const index = this.years.indexOf(this.selectedYear)

    if (type === 'prev') {
      if (index === 0) return
      this.selectedYear = this.years[index - 1]
    } else if (type === 'next') {
      if (index === this.years.length - 1) return
      this.selectedYear = this.years[index + 1]
    }

    this.prepareMonths()
    this.onChangeMonth()
  }

  onChangeMonth = (type: 'prev' | 'next' = null) => {
    if (!this.calendar) return

    const yearIndex = this.years.indexOf(this.selectedYear)
    const index = this.months.indexOf(this.selectedMonth)

    if (type === 'prev') {
      if (index === 0) {
        if (yearIndex === 0) return

        this.selectedYear = this.years[yearIndex - 1]
        this.prepareMonths()

        this.selectedMonth = lodash.last(this.months)
      } else {
        this.selectedMonth = this.months[index - 1]
      }
    } else if (type === 'next') {
      if (index === this.months.length - 1) {
        if (yearIndex === this.years.length - 1) return

        this.selectedYear = this.years[yearIndex + 1]
        this.prepareMonths()

        this.selectedMonth = lodash.first(this.months)
      } else {
        this.selectedMonth = this.months[index + 1]
      }
    }

    this.calendar.navigate(this.selectedMonth)
  }

  onChangePage(page: PageEvent) {
    if (this.calendar && page.pageIndex !== page.previousPageIndex) {
      this.calendar.page(page.pageIndex)
    } else {
      this.config = {
        ...this.config,
        pageIndex: 0,
        pageSize: page.pageSize,
      }
    }
  }

  onChangeFilterDateRange(range: DateRangePickerSelection) {
    this.filterAvailabilityDateRange = range

    this.filters.get('availabilityRange').setValue(
      range && {
        start: range.from,
        end: range.to,
      }
    )

    if (!range) {
      this.filterRentals()
      return
    }

    this.uidFilterRentals = lodash.uniqueId()
    this.fetchEvents(range.from, range.to, this.uidFilterRentals)

    this.calendar?.navigate(range.from)
  }

  onChangeFilterAvailabilityType(type: MultiCalendarAvailabilityTypes) {
    this.filterAvailabilityType = type

    this.filters.get('availabilityType').setValue(type)

    this.filterRentals()
  }

  onAddBooking(start?: number, end?: number, rentalId?: string) {
    this.inquirySharedDialogsService.openCreateBooking(start, end, rentalId)
  }

  onAddHoldEvent(start?: number, end?: number, rentalId?: string) {
    this.holdDialog.open({ defaults: { start, end, rentalIds: rentalId ? [rentalId] : [] } })
  }

  onImportCalendar() {
    this.importCalendarDialog.open()
  }

  onShareCalendar() {
    this.shareCalendarDialog.open()
  }

  gotoToday() {
    this.calendar.navigate(this.today)
  }

  onAddStandardRate(rentals: Rental[] = [], start: number = null, end: number = null) {
    this.openRateDialog({ type: 'standard', start, end }, rentals)
  }

  onAddDynamicRate(rentals: Rental[] = [], start: number = null, end: number = null) {
    this.openRateDialog({ type: 'dynamic', start, end }, rentals)
  }

  openRateDialog(data: EditRateDialogInput, rentals?: Rental[]) {
    const isGroupRate = R.defaultTo([], rentals).length > 1

    const dialogRef = this.editRateDialogService.open({
      rentals: this.rentals,
      rental: isGroupRate ? null : lodash.first(rentals) || null,
      defaultRentals: isGroupRate ? rentals : null,
      isGroupRate,
      ...data,
    })

    dialogRef.beforeClosed().subscribe((result) => {
      if (result && result.rate) this.onRefresh()
    })
  }

  openChannelAdjustments() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.ChannelAutomations })
  }

  onAutomatedRates() {
    this.accountRatesDialogService.openSide()
  }

  openGroupRates() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.GroupRates })
  }

  openLosDiscounts() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.LosDiscounts })
  }

  openDiscountCodes() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.DiscountCodes })
  }

  private prepareYears() {
    const year = toMoment().year()

    const range = 2

    const years = lodash.range(year - range, year + range + 1)

    this.years = years
    this.selectedYear = years[range]

    this.prepareMonths(true)

    const m = toMoment()

    const minDate = m.year(lodash.first(years)).startOf('year').unix()
    const maxDate = m.year(lodash.last(years)).endOf('year').unix()

    let initialDate = this.filters.get('initialDate').value

    if (!initialDate || initialDate < minDate || initialDate > maxDate) {
      initialDate = this.today
    }

    this.config = {
      ...this.config,
      minDate,
      maxDate,
      initialDate,
    }
  }

  private prepareMonths(init = false) {
    const today = toMoment(this.today)

    const year = moment.utc({ year: this.selectedYear })

    let selectedMonth: number

    const start = year.clone().startOf('year').unix()
    const end = year.clone().endOf('year').unix()

    const months = itemsInTimeRange(start, end, 'month')

    if (init && year.isSame(today, 'year')) {
      selectedMonth = today.startOf('month').unix()
    }

    this.months = months

    if (!selectedMonth) {
      selectedMonth = toMoment(this.selectedMonth || this.months[0])
        .year(this.selectedYear)
        .startOf('month')
        .unix()
    }

    this.selectedMonth = selectedMonth
  }

  // #endregion toolbar

  get isOwner() {
    return this.ngxRolesService.getRole('OWNER')
  }

  get search(): string {
    return this.filters.get('search').value || ''
  }

  //#region calendar

  getItemID(item: Rental) {
    return item.id
  }

  getEventID = (event: MultiCalendarEventView) => {
    return this.calendarService.getEventID(event)
  }

  getEventTimeRange = (event: MultiCalendarEventView): CalendarEventTimeRange => {
    return this.calendarService.getEventTimeRange(event)
  }

  onViewportChange(viewportInfo: CalendarViewPortInfo<Rental>) {
    this.activePage = viewportInfo.activePage
    this.pageSize = viewportInfo.pageSize

    this.filters.get('pageIndex').setValue(this.activePage)
    this.filters.get('pageSize').setValue(this.pageSize)

    const year = toMoment(viewportInfo.activeMonth).year()
    if (year !== this.selectedYear) {
      this.selectedYear = year
      this.prepareMonths()
    }

    this.selectedMonth = viewportInfo.activeMonth
    this.lastNavigatingDate = null

    this.pageRange.from = this.activePage * this.pageSize + 1
    this.pageRange.to = Math.min((this.activePage + 1) * this.pageSize, this.filteredRentals.length)
    if (this.pageRange.to === 0) this.pageRange.from = 0

    this.fetchEvents(viewportInfo.startDate, viewportInfo.endDate)
    this.fetchRates(viewportInfo.visibleItems, viewportInfo.startDate, viewportInfo.endDate)

    this.filters.get('initialDate').setValue(viewportInfo.startDate)
  }

  onSelectionChange(selectionInfo: CalendarSelectionInfo<Rental>) {
    const { items } = selectionInfo

    if (!items.length) {
      return
    }

    const start = selectionInfo.start
    const end = selectionInfo.end

    this.showSelectionDialog(items, start, end)
  }

  private showSelectionDialog(rentals: Rental[], start: number, end: number) {
    this.actionDialogService
      .openCalendarActions({
        addBooking: {
          disabled: rentals.length > 1,
          onClick: () => {
            this.onAddBooking(start, end, rentals[0]?.id)
          },
        },
        addHoldEvent: {
          onClick: () => {
            this.holdDialog.open({ defaults: { start, end, rentalIds: rentals.map((r) => r.id) } })
          },
        },
        addDynamicRate: {
          onClick: () => this.onAddDynamicRate(rentals, start, end),
        },
        addStandardRate: {
          onClick: () => this.onAddStandardRate(rentals, start, end),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.calendar.clearSelections()
      })
  }

  filterRentals() {
    const search = this.search.trim().toLowerCase()

    let rentals = [...this.rentals]

    if (search) {
      rentals = rentals.filter((rental) => {
        return (rental.name + '').toLowerCase().includes(search)
      })
    }

    const availabilityType = this.filterAvailabilityType
    const availabilityDateRange = this.filterAvailabilityDateRange

    const availabilityStart = availabilityDateRange && toMoment(availabilityDateRange.from).endOf('date').unix()
    const availabilityEnd = availabilityDateRange && toMoment(availabilityDateRange.to).startOf('date').unix()

    if (availabilityDateRange && availabilityType === MultiCalendarAvailabilityTypes.available) {
      rentals = rentals.filter((rental) => {
        const events = this.filteredEvents[rental.id]
        let available = true

        lodash.forEach(events, (event) => {
          if (event.end < availabilityStart || event.start > availabilityEnd) return
          available = false
          return false
        })

        return available
      })
    }

    if (availabilityDateRange && availabilityType === MultiCalendarAvailabilityTypes.booked) {
      rentals = rentals.filter((rental) => {
        const events = this.filteredEvents[rental.id]
        let booked = false

        lodash.forEach(events, (event) => {
          if (event.end < availabilityStart || event.start > availabilityEnd) return

          booked = true
          return false
        })

        return booked
      })
    }

    const { filterRentals, filterTags } = this.updateFilters()

    rentals = rentals.filter((rental) => {
      if (filterRentals.length && !filterRentals.includes(rental.id)) return false

      if (filterTags.length) {
        if (lodash.isEmpty(rental.tags)) return false
        if (!lodash.intersection(filterTags, rental.tags).length) return false
      }

      return true
    })

    this.filteredRentals = rentals
  }

  private updateRentals(rentals: Rental[]) {
    this.rentals = rentals

    this.filterTags = R.pipe(
      R.map((r: Rental) => R.defaultTo([], r.tags)),
      R.flatten,
      R.uniq,
      R.reject(R.isNil),
      R.map(R.pipe(R.when(R.is(Number), R.toString), R.toLower)),
      R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
    )(this.rentals)

    // clear not existing tags
    const chosenItems = this.filters.get('tags').value
    const existingItems = R.filter((item) => R.contains(item, this.filterTags), chosenItems || [])
    this.filters.patchValue({ tags: existingItems })
  }

  private filterEvents() {
    const { filterChannels } = this.updateFilters()

    if (filterChannels.length) {
      this.filteredEvents = lodash.mapValues(this.events, (events) => {
        return events.filter((event) => filterChannels.includes(event.source))
      })
    } else {
      this.filteredEvents = this.events
    }
    if (!lodash.isEmpty(this.filteredEvents)) {
      this.perfume.end('MultiCalendar')
    }

    this.filterRentals()
  }

  private updateEvents(events: Record<string, MultiCalendarEventView[]>) {
    const rentalIds = lodash.keyBy(this.rentals, (rental) => rental.id)

    events = lodash.reduce(
      events,
      (accum, rentalEvents, rentalId) => {
        if (rentalIds[rentalId]) accum[rentalId] = rentalEvents
        return accum
      },
      {}
    )

    this.events = events

    this.filterChannels = lodash
      .chain(events)
      .map((itemEvents) => itemEvents.map((event) => event.source))
      .flatten()
      .compact()
      .uniq()
      .sortBy((source) => source.toLowerCase())
      .value()

    // clear not existing channels
    const chosenChannels = this.filters.get('channels').value
    const existingChannels = R.filter((item) => R.contains(item, this.filterChannels), chosenChannels || [])
    if (chosenChannels.length !== existingChannels.length) {
      this.filters.patchValue({ channels: existingChannels })
    }

    this.store
      .pipe(
        select(selectRentalsLoaded),
        filter((loaded) => loaded),
        take(1)
      )
      .subscribe(() => {
        // clear not existing rentals
        const chosenRentals = this.filters.get('rentals').value
        const existingRentals = R.filter(
          (item) =>
            R.contains(
              item,
              R.map((r: Rental) => r.id, this.rentals)
            ),
          chosenRentals || []
        )
        if (chosenRentals.length !== existingRentals.length) {
          this.filters.patchValue({ rentals: existingRentals })
        }
      })

    this.filterEvents()
  }

  private updateFilters() {
    const filterRentals = (this.filters.get('rentals').value || []) as string[]
    const filterTags = (this.filters.get('tags').value || []) as string[]
    const filterChannels = (this.filters.get('channels').value || []) as string[]

    this.filtersCount = filterRentals.length + filterChannels.length + filterTags.length

    return { filterRentals, filterTags, filterChannels }
  }

  clearFilters() {
    const defaultFilters = this.filtersService.defaultFilters[FilterGroup.MultiCalendar]

    this.filters.setValue(defaultFilters)
    this.populateFilters()

    this.gotoToday()
  }

  private subscribeFilters() {
    this.populateFilters()

    const filterRentalsProps = ['rentals', 'tags', 'search']
    const filterEventsProps = ['channels']

    const resetPageProps = [...filterRentalsProps, 'availabilityType', 'availabilityRange']

    resetPageProps.forEach((filterProp) => {
      this.filters
        .get(filterProp)
        .valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.paginator.firstPage()),
          untilDestroy(this)
        )
        .subscribe()
    })

    filterRentalsProps.forEach((filterProp) => {
      this.filters
        .get(filterProp)
        .valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.filterRentals()),
          untilDestroy(this)
        )
        .subscribe()
    })

    filterEventsProps.forEach((filterProp) => {
      this.filters
        .get(filterProp)
        .valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.filterEvents()),
          untilDestroy(this)
        )
        .subscribe()
    })

    this.filters.valueChanges
      .pipe(
        debounceTime(1000),
        tap((values) => {
          this.filtersService.storeMultiCalendar(values)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private populateFilters() {
    // TV3-1201: availability type should be "available" by default
    this.filterAvailabilityType = MultiCalendarAvailabilityTypes.available

    const availabilityRange = this.filters.get('availabilityRange').value

    this.onChangeFilterDateRange(
      availabilityRange && {
        from: availabilityRange.start,
        to: availabilityRange.end,
      }
    )
  }

  isBooked(rentalId: string, date: number) {
    return this.bookedDatesService.isBooked(rentalId, date)
  }

  //#endregion calendar

  //#region rates

  rentalRatesByCategory: Record<string, Record<string, Record<string, RentalRateItem>>> = {}
  getDefaultRate(rentalId: string, date: number) {
    return this.rentalRatesByCategory?.[rentalId]?.['default']?.[date]
  }

  getRentalCategoryRates(rentalId: string, date: number) {
    const data = this.rentalRatesByCategory?.[rentalId]
    return lodash.mapValues(data, (t) => t[date])
  }

  private fetchRates(rentals: Rental[], start: number, end: number, refresh = false) {
    const rentalIds = rentals.map((rental) => rental.id)
    this.refreshRates(rentalIds, start, end, refresh)
  }

  private refreshRates(rentalIds: string[], start: number, end: number, refresh = false) {
    this.ratesService.fetchRatesV2(rentalIds, start, end, refresh).subscribe((data) => {
      this.rentalRatesByCategory = data
    })
  }

  // #endregion rates

  showEventDetails(event: MultiCalendarEventView) {
    if (event.isHoldEvent) {
      this.onEditHold(event)
    }
  }

  onEditHold(event: MultiCalendarEventView) {
    if (this.ngxPermissionsService.getPermission('canEditHold') && event.isHoldEvent) {
      this.holdDialog.open({ hold: event, convertable: true, deletable: true })
    }
  }

  showRentalPopover(sidebarCell: HTMLElement, popover: NgbPopover) {
    const span = sidebarCell.querySelector('span')
    const isOverflow = span && span.scrollWidth > span.clientWidth

    if (isOverflow) popover.open()
  }

  onOpenRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Calendar }))
  }

  onAddPrice(tab: AccountRatesDialogTab = AccountRatesDialogTab.ChannelAutomations) {
    this.accountRatesDialogService.openSide({ activeTab: tab })
  }

  onAddRental() {
    this.addRentalDialog.open().pipe(take(1)).subscribe()
  }
}
