<div class="modal-header">
  <h6 class="modal-title d-flex align-items-center gap-2">
    <i class="cs-icon cs-icon-calendar-pause"></i> {{ isEdit ? 'Edit Hold Event' : 'Add Hold Event' }}
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h6>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<ul class="nav top-navbar border-bottom bg-white">
  <li [class.active]="activeTab === tabs.Details">
    <a (click)="activeTab = tabs.Details"> Details </a>
  </li>
  <li [class.active]="activeTab === tabs.Attachments">
    <a (click)="activeTab = tabs.Attachments"> Attachments </a>
  </li>
  <li [class.active]="activeTab === tabs.Recurring">
    <a (click)="activeTab = tabs.Recurring"> Recurring </a>
  </li>
  <ng-container *ngIf="isEdit">
    <li [class.active]="activeTab === tabs.Notes">
      <a (click)="activeTab = tabs.Notes"> Comments </a>
    </li>

    <li [class.active]="activeTab === tabs.Logs">
      <a (click)="activeTab = tabs.Logs"> Logs </a>
    </li>
  </ng-container>
</ul>
<div class="modal-body">
  <ng-container [ngSwitch]="activeTab">
    <app-hold-event-form *ngSwitchCase="tabs.Details" [form]="form" [isEdit]="isEdit"></app-hold-event-form>
    <ng-template [ngTemplateOutlet]="attachmentsTpl" *ngSwitchCase="tabs.Attachments"></ng-template>
    <ng-template *ngSwitchCase="tabs.Recurring" [ngTemplateOutlet]="recurringFormTpl"></ng-template>

    <app-comments-list *ngSwitchCase="tabs.Notes" entityType="hold_event" [entityId]="hold?.id">
      <app-empty-table type="CommentsList"></app-empty-table>
    </app-comments-list>

    <app-activity-table *ngSwitchCase="tabs.Logs" entityType="hold_event" [entityId]="hold?.id">
      <app-empty-table type="ActivityList"></app-empty-table>
    </app-activity-table>
  </ng-container>
</div>
<div class="modal-footer">
  <button
    (click)="delete()"
    type="button"
    class="btn btn-secondary-danger"
    [disabled]="1 | isReadonlyRole"
    *ngIf="isEdit && data.deletable"
  >
    <i class="cs-icon cs-icon-trash"></i> delete
  </button>
  <div class="flex-fill"></div>
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    *ngIf="isEdit && data.convertable"
    matTooltip="Convert to booking"
    matTooltipPosition="above"
    [disabled]="1 | isReadonlyRole"
    (click)="convert()"
    type="button"
    class="btn btn-secondary-info"
  >
    <i class="fal fa-bed"></i> convert
  </button>
  <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>

<ng-template #attachmentsTpl>
  <app-upload-box
    [options]="uploadBoxOptions"
    [showFileList]="false"
    (complete)="onUploadComplete($event)"
  ></app-upload-box>
  <div *ngFor="let item of attachments; let i = index" class="d-flex align-items-center gap-3">
    <div class="text-primary text-xxl">
      <i class="" [ngClass]="item.name | fileIcon"></i>
    </div>
    <div class="mr-4">{{ item.name }}</div>
    <div class="flex-fill text-light text-right text-sm">
      {{ item.created | epoch : 'DD-MMM-YYYY' }} at {{ item.created | epoch : 'HH:mm' }} • {{ item.user }} •
      {{ item.size | fileSize }}
    </div>
    <div>
      <a href="javascript:;" class="btn btn-light-info btn-sm" (click)="onDownloadAttachment(item)"
        ><i class="cs-icon cs-icon-download"></i>
      </a>
      <a href="javascript:;" class="btn btn-light-danger btn-sm" (click)="onDeleteAttachment(item)"
        ><i class="cs-icon cs-icon-trash"></i>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #recurringFormTpl>
  <app-recurring-form
    [form]="form.get('recurrenceRule')"
    [repeatableCtrl]="form.get('repeat')"
    [isUnlimitedEnabled]="false"
    class="d-block p-3"
  ></app-recurring-form>
</ng-template>
