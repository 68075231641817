import { PaymentGatewayServiceIdentities } from '../gateways/gateways.model'

export interface PaymentGatewayType {
  id: PaymentGatewayServiceIdentities
  name: string
  logo: string
  description: string
  type: 'secret' | 'oauth'
  website: string
  notes: string[]
}

export type SpreedlyGatewayType =
  | 'authorize_net'
  | 'braintree'
  | 'optimal_payments'
  | 'paymill'
  | 'paypal_commerce_platform'
  | 'worldpay'
  | 'mercado_pago'

export const spreedlyGatewayTypes = [
  'authorize_net',
  'braintree',
  'optimal_payments',
  'paymill',
  'paypal_commerce_platform',
  'worldpay',
  'mercado_pago',
  'test',
]

export interface SpreedlySupportedGateway {
  auth_modes?: {
    auth_mode_type: string
    credentials: {
      label: string
      safe: boolean
      name: string
    }[]
    name: string
  }[]
  company_name: string
  gateway_type: SpreedlyGatewayType
  homepage: string
  name: string
  supported_countries: string[]
}
