import { createAction, props } from '@ngrx/store'
import {
  AccountRateAutomationResponse,
  CreateGapNightAutomationPayload,
  CreateLastMinuteAutomationPayload,
  CreateMinStayAutomationPayload,
  CreateRatePushAutomationPayload,
} from './model'

export const LoadAccountRateAutomations = createAction('[RateAutomation] Load Rate Automations')
export const LoadAccountRateAutomationsComplete = createAction(
  '[RateAutomation] Load Rate Automations Complete',
  props<{ items: AccountRateAutomationResponse[] }>()
)

export const CreateAccountRateAutomation = createAction('[RateAutomation] Create Rate Automation')
export const CreateAccountRateAutomationComplete = createAction(
  '[RateAutomation] Create Rate Automation Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const CreateMinStayAutomation = createAction(
  '[MinStay] create Min stay',
  props<{ payload: CreateMinStayAutomationPayload }>()
)
export const CreateMinStayAutomationComplete = createAction(
  '[MinStay] create MinStay Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const UpdateMinStayAutomation = createAction(
  '[MinStay] update Min stay',
  props<{ id: string; payload: CreateMinStayAutomationPayload }>()
)
export const UpdateMinStayAutomationComplete = createAction(
  '[MinStay] update MinStay Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const DeleteMinStayAutomation = createAction('[MinStay] Delete Min stay', props<{ id: string }>())
export const DeleteMinStayAutomationComplete = createAction(
  '[MinStay] Delete MinStay Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const CreateGapNightAutomation = createAction(
  '[GapNight] create gap night',
  props<{ payload: CreateGapNightAutomationPayload }>()
)
export const CreateGapNightAutomationComplete = createAction(
  '[GapNight] create GapNight Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const UpdateGapNightAutomation = createAction(
  '[GapNight] update gap night',
  props<{ id: string; payload: CreateGapNightAutomationPayload }>()
)
export const UpdateGapNightAutomationComplete = createAction(
  '[GapNight] update GapNight Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const DeleteGapNightAutomation = createAction('[GapNight] Delete Min stay', props<{ id: string }>())
export const DeleteGapNightAutomationComplete = createAction(
  '[GapNight] Delete GapNight Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const CreateLastMinuteAutomation = createAction(
  '[LastMinute] create Last Minute',
  props<{ payload: CreateLastMinuteAutomationPayload }>()
)
export const CreateLastMinuteAutomationComplete = createAction(
  '[LastMinute] create LastMinute Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const UpdateLastMinuteAutomation = createAction(
  '[LastMinute] update Last minute',
  props<{ id: string; payload: CreateLastMinuteAutomationPayload }>()
)
export const UpdateLastMinuteAutomationComplete = createAction(
  '[LastMinute] update LastMinute Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const DeleteLastMinuteAutomation = createAction('[LastMinute] Delete Min stay', props<{ id: string }>())
export const DeleteLastMinuteAutomationComplete = createAction(
  '[LastMinute] Delete LastMinute Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const CreateRatePushAutomation = createAction(
  '[RatePush] create rate push',
  props<{ payload: CreateRatePushAutomationPayload }>()
)
export const CreateRatePushAutomationComplete = createAction(
  '[RatePush] create RatePush Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const UpdateRatePushAutomation = createAction(
  '[RatePush] update rate push',
  props<{ id: string; payload: CreateRatePushAutomationPayload }>()
)
export const UpdateRatePushAutomationComplete = createAction(
  '[RatePush] update RatePush Complete',
  props<{ item: AccountRateAutomationResponse }>()
)

export const DeleteRatePushAutomation = createAction('[RatePush] Delete Min stay', props<{ id: string }>())
export const DeleteRatePushAutomationComplete = createAction(
  '[RatePush] Delete RatePush Complete',
  props<{ item: AccountRateAutomationResponse }>()
)
