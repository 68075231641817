import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ManualRefreshImportedCalendarConnection } from '@tv3/store/connection/connection.actions'
import { isConnectionUpdating } from '@tv3/store/connection/connection.selectors'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'

@Component({
  selector: 'app-ical-connection-detail',
  templateUrl: './ical-connection-detail.component.html',
  styleUrls: ['./ical-connection-detail.component.scss'],
})
export class IcalConnectionDetailComponent {
  @Input() rental: Rental
  @Input() channel: Channel
  @Input() connection: ConnectionView

  isUpdating$ = this.store.pipe(select(isConnectionUpdating))

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private connectionHelperService: ConnectionHelperService
  ) {}

  onRefresh() {
    this.store.dispatch(
      ManualRefreshImportedCalendarConnection({
        connId: this.connection.id,
        data: {
          name: this.connection.name,
          calendar: this.connection.url,
          rentalid: this.connection.rentalId,
          sendto: this.connection.sendto || this.rental.email,
        },
      })
    )
  }

  onDelete() {
    if (this.connection.isImported) {
      this.connectionHelperService.removeImportedCalendar(this.connection)
    } else {
      this.connectionHelperService.removeExportedCalendar(this.connection)
    }
  }
}
