import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { Store, select } from '@ngrx/store'
import { NgxPermissionsService } from 'ngx-permissions'
import { fromEvent, Subscription } from 'rxjs'
import { distinctUntilChanged, finalize, tap } from 'rxjs/operators'
import * as lodash from 'lodash'
import {
  CalendarEventTimeRange,
  CalendarListComponent,
  CalendarListSelectionInfo,
  CalendarListViewPortInfo,
} from '@tokeet/ngx-calendar-list'

import * as fromRoot from '@tv3/store/state'
import {
  Destroyable,
  localTodayToUTC,
  Rental,
  RentalBookedDayTypes,
  RentalService,
  itemsInTimeRange,
  toMoment,
  untilDestroy,
  RentalRateItem,
  TOKEET_CHANNEL_ID,
  selectChannelEntities,
  tokeetDashboardChannel,
  Channel,
} from '@tokeet-frontend/tv3-platform'
import { CalendarService } from '@tv3/store/calendar/calendar.service'
import { selectMultiCalendarEventsForRental } from '@tv3/store/calendar/calendar.selectors'
import { MultiCalendarEventView } from '@tv3/store/calendar/calendar.view'
import { LoadCalendarEventsByRental } from '@tv3/store/calendar/calendar.actions'
import { BookingDetailsPopupService } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.service'
import {
  ActionDialogService,
  GetAddEventActionParams,
  GetAddRateActionParams,
} from '@tv3/shared/dialogs/action-dialog/action-dialog.service'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { EditRateDialogInput } from '../edit-rate-dialog/edit-rate-dialog.component'
import { EditRateDialogService } from '../edit-rate-dialog/edit-rate-dialog.service'
import { ExpectPermission } from '@tokeet-frontend/permission'
import { RatesService } from '../rate-services/rates.service'
import { HoldDialogService } from '@tv3/containers/calendar/hold/hold-dialog/hold-dialog.service'

@Component({
  selector: 'app-rental-rates-calendar',
  templateUrl: './rental-rates-calendar.component.html',
  styleUrls: ['./rental-rates-calendar.component.scss'],
})
export class RentalRatesCalendarComponent extends Destroyable implements OnInit {
  @Input() rental: Rental
  @Input() showEvents = false

  @Input() loading = false

  years: number[]
  months: number[]

  selectedMonth: number
  selectedYear: number

  fetchingBlockedDates = true
  blockedDates: Record<string, RentalBookedDayTypes> = {}

  events: MultiCalendarEventView[] = null

  channelsById: Record<string, Channel> = {}
  rentalRatesByCategory: Record<string, Record<string, RentalRateItem>> = {}

  today = localTodayToUTC().unix()

  activePopover: NgbPopover

  calendarList: CalendarListComponent<MultiCalendarEventView>
  scrollSub: Subscription

  constructor(
    private store: Store<fromRoot.State>,
    private permissions: NgxPermissionsService,
    private rentalService: RentalService,
    private ratesService: RatesService,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    private bookingDetailsPopupService: BookingDetailsPopupService,
    private calendarService: CalendarService,
    private actionDialog: ActionDialogService,
    private holdDialog: HoldDialogService,
    private editRateDialog: EditRateDialogService
  ) {
    super()
    this.onViewportChange = lodash.debounce(this.onViewportChange, 250)
  }

  ngOnInit() {
    this.buildMonths()
    this.fetchBlockedDates()

    this.store.pipe(select(selectChannelEntities), untilDestroy(this)).subscribe((channels) => {
      this.channelsById = channels
      this.channelsById[TOKEET_CHANNEL_ID] = tokeetDashboardChannel
    })

    if (this.showEvents) {
      this.store
        .select(selectMultiCalendarEventsForRental(this.rental.id))
        .pipe(
          distinctUntilChanged(),
          tap((events) => {
            this.events = events
          }),
          untilDestroy(this)
        )
        .subscribe()

      this.fetchEvents()
    }
  }

  @ViewChild('calendarList', { static: false })
  set setCalendarList(calendarList: CalendarListComponent) {
    this.calendarList = calendarList

    if (this.scrollSub) this.scrollSub.unsubscribe()
    if (!calendarList) return

    this.scrollSub = fromEvent(calendarList.contentScroller.elementRef.nativeElement, 'scroll')
      .pipe(
        tap(() => {
          if (this.activePopover && this.activePopover.isOpen) {
            this.activePopover.close()
          }
        })
      )
      .subscribe()
  }

  get fetching() {
    return this.ratesService.loading || this.fetchingBlockedDates
  }

  getEventID = (event: MultiCalendarEventView) => {
    return this.calendarService.getEventID(event)
  }

  getEventTimeRange = (event: MultiCalendarEventView): CalendarEventTimeRange => {
    return this.calendarService.getEventTimeRange(event)
  }

  isStartOfMonth(date: number) {
    return toMoment(date).date() === 1
  }

  isBeforeToday(date: number) {
    return toMoment(date).isBefore(toMoment(), 'date')
  }

  isInYear(month: number, year: number) {
    return toMoment(month).year() === year
  }

  canNavigate(active: number, list: number[], type: 'prev' | 'next') {
    if (type === 'prev') return active > lodash.first(list)
    if (type === 'next') return active < lodash.last(list)
    return false
  }

  getMonthDayData(rentalRatesByCategory: Record<string, Record<string, RentalRateItem>>, date: number) {
    const data = rentalRatesByCategory
    return lodash.mapValues(data, (t) => t[date])
  }

  refresh(
    options: { rates?: boolean; events?: boolean; blockedDates?: boolean } = {
      rates: true,
      events: true,
      blockedDates: true,
    }
  ) {
    if (options.rates) {
      const viewportInfo = this.calendarList.currentViewportInfo
      const month = toMoment(viewportInfo.activeMonth)
      const start = month.clone().subtract(1, 'months').startOf('month').unix()
      const end = month.clone().add(1, 'months').endOf('month').unix()
      this.fetchRates(start, end, true)
    }
    if (options.events) this.fetchEvents()
    if (options.blockedDates) this.fetchBlockedDates()
  }

  fetchBlockedDates() {
    this.fetchingBlockedDates = true

    this.rentalService
      .getBlockedDates(this.rental)
      .pipe(
        tap((blockedDates) => {
          this.blockedDates = blockedDates
        }),
        finalize(() => {
          this.fetchingBlockedDates = false
        })
      )
      .subscribe()
  }

  private fetchRates(start: number, end: number, refresh = false) {
    this.ratesService.fetchRatesV2([this.rental.id], start, end, refresh).subscribe((data) => {
      this.rentalRatesByCategory = data[this.rental.id]
    })
  }

  fetchEvents() {
    if (!this.showEvents) return
    this.store.dispatch(LoadCalendarEventsByRental({ id: this.rental.id }))
  }

  showEventDetails(event: MultiCalendarEventView) {
    if (event.isHoldEvent) {
      this.onEditHold(event)
    } else {
      this.bookingDetailsPopupService.open(event)
    }
  }

  onEditHold(event: MultiCalendarEventView) {
    if (this.permissions.getPermission('canEditHold') && event.isHoldEvent) {
      this.holdDialog.open({ hold: event, convertable: true, deletable: true })
    }
  }

  onChangeMonth(type: 'next' | 'prev' = null, scroll = true) {
    const firstMonth = toMoment(lodash.first(this.months))
    const lastMonth = toMoment(lodash.last(this.months))

    let month = toMoment(this.selectedMonth)

    if (type === 'next') {
      month.add(1, 'month')
    } else if (type === 'prev') {
      month.subtract(1, 'month')
    }

    if (month.isBefore(firstMonth, 'month')) {
      month = firstMonth
    } else if (month.isAfter(lastMonth, 'month')) {
      month = lastMonth
    }

    this.selectedMonth = month.startOf('month').unix()
    this.selectedYear = month.year()

    if (scroll) this.calendarList.scrollToDate(this.selectedMonth)
  }

  onChangeYear(type: 'next' | 'prev' = null, scroll = true) {
    if (type === 'next') {
      if (this.selectedYear === lodash.last(this.years)) return
      this.selectedYear += 1
    } else if (type === 'prev') {
      if (this.selectedYear === lodash.first(this.years)) return
      this.selectedYear -= 1
    }

    this.selectedMonth = toMoment()
      .year(this.selectedYear)
      .month(toMoment(this.selectedMonth).month())
      .startOf('month')
      .unix()

    this.onChangeMonth(null, scroll)
  }

  onViewportChange(viewportInfo: CalendarListViewPortInfo) {
    const month = toMoment(viewportInfo.activeMonth)

    this.selectedMonth = month.startOf('month').unix()
    this.selectedYear = month.year()

    const start = month.clone().subtract(1, 'months').startOf('month').unix()
    const end = month.clone().add(1, 'months').endOf('month').unix()
    this.fetchRates(start, end)
  }

  @ExpectPermission('readOnly')
  onSelectionChange(selectionInfo: CalendarListSelectionInfo) {
    if (selectionInfo.selectionType === 'click') {
      // TODO: maybe show tooltip
      console.log('onSelectionChange.click', selectionInfo)
      return
    }

    if (selectionInfo.selectionType === 'drag') {
      const dates = selectionInfo.selections

      if (!dates.length) return

      const start = lodash.minBy(dates)
      const end = lodash.maxBy(dates)

      this.showSelectionDialog(start, end)
      return
    }
  }

  private showSelectionDialog(start: number, end: number) {
    const eventActions: GetAddEventActionParams = {
      addBooking: {
        onClick: () => {
          this.inquirySharedDialogsService.openCreateBooking(start, end, this.rental.id)
        },
      },
      addHoldEvent: {
        onClick: () => {
          this.holdDialog.open({ defaults: { start, end, rentalIds: [this.rental.id] } })
        },
      },
    }

    const rateActions: GetAddRateActionParams = {
      addStandardRate: {
        onClick: () => this.openRateDialog({ type: 'standard', start, end }),
      },
      addDynamicRate: {
        onClick: () => this.openRateDialog({ type: 'dynamic', start, end }),
      },
    }

    const dialogRef = this.showEvents
      ? this.actionDialog.openRentalCalendarActions({ ...eventActions, ...rateActions })
      : this.actionDialog.openAddRateActions(rateActions)

    dialogRef.beforeClosed().subscribe(() => {
      this.calendarList.clearSelections()
    })
  }

  openRateDialog(data: EditRateDialogInput) {
    const dialogRef = this.editRateDialog.open({
      rentals: [this.rental],
      rental: this.rental,
      ...data,
    })

    dialogRef.beforeClosed().subscribe((result) => {
      this.calendarList.clearSelections()

      if (result && result.rate) this.refresh({ rates: true })
    })
  }

  gotoToday() {
    this.calendarList.scrollToDate(this.today)
  }

  private buildMonths() {
    const start = toMoment().startOf('month')
    const end = start.clone().add(24, 'month')

    this.months = itemsInTimeRange(start, end, 'month')

    this.years = itemsInTimeRange(start, end, 'year').map((time) => toMoment(time).year())
    this.selectedYear = lodash.first(this.years)

    this.onChangeYear(null, false)
  }
}
