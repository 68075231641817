import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Destroyable, untilDestroy, rentalColors } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-color-boxes',
  templateUrl: './color-boxes.component.html',
  styleUrls: ['./color-boxes.component.scss'],
})
export class ColorBoxesComponent extends Destroyable implements OnInit {
  @Input() colors: string[] = rentalColors
  @HostBinding('style.--color-box-size.px')
  @Input()
  boxSize = 24

  @Input() set color(color: string) {
    this.ctrl.setValue(color, { emitEvent: false })
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.ctrl.disable({ emitEvent: false })
    } else {
      this.ctrl.enable({ emitEvent: false })
    }
  }
  @Input() label: string
  @Input() ctrl = new FormControl()

  @Output() change = new EventEmitter<string>()

  ngOnInit(): void {
    this.ctrl.valueChanges.pipe(untilDestroy(this)).subscribe((color) => {
      this.change.emit(color)
    })
  }
}
