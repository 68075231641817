import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  asLocalDate,
  compactObject,
  Destroyable,
  isSomething,
  notAllNumbersValidator,
  SaveForm,
  selectAllChannelsWithDefaults,
  selectAllRentals,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreateGapNightAutomation,
  CreateGapNightAutomationPayload,
  GapNightAutomation,
  selectAllGapNightAutomations,
  UpdateGapNightAutomation,
} from '@tokeet-frontend/rate-automations'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import { combineLatest, Observable } from 'rxjs'

export interface GapNightAutomationDialogInput {
  gapNight?: GapNightAutomation
}

@Component({
  selector: 'app-gap-night-automation-dialog',
  host: { class: 'modal-content' },
  templateUrl: './gap-night-automation-dialog.component.html',
  styleUrls: ['./gap-night-automation-dialog.component.scss'],
})
export class GapNightAutomationDialogComponent extends Destroyable implements OnInit, OnDestroy {
  isEdit = false

  form = this.fb.group({
    name: ['', [Validators.required, notAllNumbersValidator]],
    rentals: [[], [Validators.required]],
    range: [null, [Validators.required]],
    gaps: this.fb.array([]),
  })

  minNightsOptions = [
    { value: null, name: 'No minimum nights' },
    { value: '1', name: '1 night' },
    { value: '2', name: '2 nights' },
    { value: '3', name: '3 nights' },
    { value: '4', name: '4 nights' },
    { value: '5', name: '5 nights' },
    { value: '6', name: '6 nights' },
    { value: '7', name: '7 nights' },
    { value: 'g', name: 'Length of gap' },
    { value: 'g1', name: 'Length of gap - 1' },
    { value: 'g2', name: 'Length of gap - 2' },
  ]

  rentals$ = this.store.select(selectAllRentals)
  channels$ = this.store.select(selectChannelsWithCustomChannels(selectAllChannelsWithDefaults, 'name'))
  usedRange$: Observable<{ start: number; end: number }[]>

  get gapNight() {
    return this.data?.gapNight
  }

  get itemsForm() {
    return this.form.get('gaps') as FormArray
  }
  minDate: Date

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private dialogRef: MatDialogRef<GapNightAutomationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: GapNightAutomationDialogInput
  ) {
    super()

    this.isEdit = !!data.gapNight?.key
    if (!this.isEdit) {
      this.minDate = new Date()
    } else {
      this.minDate = asLocalDate(Math.min(Date.now() / 1000, this.data.gapNight.start))
    }

    this.form.patchValue({
      ...(data.gapNight || {}),
      range: this.isEdit ? { from: data.gapNight.start, to: data.gapNight.stop } : null,
    })
    lodash.forEach(data.gapNight?.gaps, (t) => this.itemsForm.push(this.createItemForm(t)))
    if (!this.itemsForm.length) {
      this.onAddItem()
    }
  }

  ngOnInit() {
    this.usedRange$ = combineLatest([
      this.store.pipe(select(selectAllGapNightAutomations)),
      this.form.get('rentals').valueChanges.pipe(startWith(this.form.get('rentals').value), filter(isSomething)),
    ]).pipe(
      map(([items, rentals]) => {
        items = items.filter((t) => lodash.intersection(rentals, t.rentals).length > 0)
        if (this.isEdit) {
          items = items.filter((t) => t.key !== this.gapNight?.key)
        }
        return lodash.compact(lodash.map(items, (t) => ({ start: t.start, end: t.stop })))
      })
    )
  }

  createItemForm(day?: GapNightAutomation['gaps'][number]) {
    const form = this.fb.group({
      gaps_required: [day?.gaps_required, [Validators.required]],
      min_nights: [day?.min_nights?.toString()],
      adjust_pct: [day?.adjust_pct, [Validators.required]],
      adjust_abs: [day?.adjust_abs, [Validators.required]],
    })
    form.valueChanges
      .pipe(
        startWith(form.getRawValue()),
        distinctUntilChanged((a, b) => lodash.isEqual(a, b))
      )
      .subscribe((value) => {
        const adjust_abs = value?.adjust_abs
        const adjust_pct = value?.adjust_pct
        if (lodash.isNumber(adjust_abs)) {
          form.get('adjust_abs').setValidators([Validators.required])
          form.get('adjust_pct').clearValidators()
          form.get('adjust_pct').disable()
          form.updateValueAndValidity()
        } else if (lodash.isNumber(adjust_pct)) {
          form.get('adjust_pct').setValidators([Validators.required])
          form.get('adjust_abs').clearValidators()
          form.get('adjust_abs').disable()
          form.updateValueAndValidity()
        } else {
          form.get('adjust_abs').enable({ emitEvent: false })
          form.get('adjust_pct').enable({ emitEvent: false })
        }
      })
    return form
  }

  onAddItem() {
    this.itemsForm.push(this.createItemForm())
  }

  onRemoveItem(index: number) {
    this.itemsForm.removeAt(index)
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    const { range, gaps, ...data } = form.getRawValue()
    const payload: CreateGapNightAutomationPayload = {
      ...data,
      start: range.from,
      stop: range.to,
      gaps: lodash.map(gaps, (t) => compactObject(t)),
    }

    if (this.isEdit) {
      this.store.dispatch(UpdateGapNightAutomation({ id: this.gapNight.key, payload }))
    } else {
      this.store.dispatch(CreateGapNightAutomation({ payload }))
    }
    this.close()
  }

  close() {
    this.dialogRef.close()
  }
}
