<i *ngIf="infoTooltip" class="fal fa-info-circle" [matTooltip]="infoTooltip" matTooltipPosition="below"></i>

<i
  *ngIf="articleId || categoryId"
  (click)="onHelpDocs()"
  class="cs-icon cs-icon-question-circle"
  [matTooltip]="tooltipText"
  matTooltipPosition="below"
></i>

<app-help-video-icon [videoId]="videoId" *ngIf="videoId"></app-help-video-icon>

<i *ngIf="docId" class="cs-icon cs-icon-file-alt2"></i>
