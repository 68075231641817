export interface AnimationOption {
  keyframes: Keyframe[]
  options: KeyframeAnimationOptions
}

export interface DrawerAnimationOptions {
  incoming: { keyframes: Keyframe[]; options: KeyframeAnimationOptions }
  outgoing: { keyframes: Keyframe[]; options: KeyframeAnimationOptions }
}

export const drawerAnimation: DrawerAnimationOptions = {
  incoming: {
    keyframes: [{ transform: 'translateX(100%)' }, { transform: 'translateX(0)' }],
    options: { easing: 'ease-in', duration: 350 },
  },
  outgoing: {
    keyframes: [{ transform: 'translateX(0)' }, { transform: 'translateX(100%)' }],
    options: { easing: 'ease-out', duration: 250 },
  },
}

export const drawerBackdropAnimation: DrawerAnimationOptions = {
  incoming: {
    keyframes: [{ opacity: 0 }, { opacity: 1 }],
    options: drawerAnimation.incoming.options,
  },
  outgoing: {
    keyframes: [{ opacity: 1 }, { opacity: 0 }],
    options: drawerAnimation.outgoing.options,
  },
}
