/* tslint:disable directive-selector */
import { Directive, TemplateRef } from '@angular/core'

export interface SelectItemDirectiveContext<T> {
  item: T
  index: number
  first: boolean
  last: boolean
  even: boolean
  odd: boolean
}

@Directive({ selector: '[selectItem]' })
export class SelectItemDirective<T> {
  constructor(public template: TemplateRef<SelectItemDirectiveContext<T>>) {}
}

export interface SelectLabelDirectiveContext<T, V> {
  items: T
  values: V
}

@Directive({ selector: '[selectLabel]' })
export class SelectLabelDirective<T, V> {
  constructor(public template: TemplateRef<SelectLabelDirectiveContext<T, V>>) {}
}

@Directive({ selector: '[selectActionButton]' })
export class SelectActionButtonDirective {
  constructor(public template: TemplateRef<any>) {}
}
