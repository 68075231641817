<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-ban"></i> Decline Booking</h5>
  <button (click)="dialogRef.close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <mat-form-field2 class="mat-block">
      <mat-select formControlName="decline_reason" placeholder="Decline Reason" required>
        <mat-option *ngFor="let item of reasons" [value]="item.id"> {{ item.label }}</mat-option>
      </mat-select>
    </mat-form-field2>
    <mat-form-field2 class="mat-block">
      <textarea
        matInput
        placeholder="Message to guest"
        [required]="true"
        rows="3"
        formControlName="decline_message_to_guest"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field2>
    <mat-form-field2 class="mat-block">
      <textarea
        matInput
        placeholder="Message to Airbnb"
        rows="3"
        formControlName="decline_message_to_airbnb"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field2>
  </form>
</div>
<div class="modal-footer">
  <button (click)="dialogRef.close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onDecline(form)" type="button" [disabled]="isLoading" class="btn btn-secondary-info create-btn">
    <i class="fal fa-ban"></i> Decline
  </button>
</div>
