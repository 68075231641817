import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { concat, Observable } from 'rxjs'
import { last, map, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'

import { Rate } from './rate.model'
import { toastError } from '../../rx-operators/toast-error'
import { Toaster } from '../../services/toaster.service'

@Injectable()
export class GroupRateService {
  constructor(private http: HttpClient, private toaster: Toaster) {}

  all(): Observable<Rate[]> {
    const url = '@api/rate/group'

    return this.http.get<object[]>(url).pipe(
      map((items) => items.map(Rate.deserialize)),
      toastError(this.toaster) as any
    )
  }

  get(rateId: string): Observable<Rate> {
    const url = `@api/rate/group/${rateId}`

    return this.http.get<object>(url).pipe(map(Rate.deserialize), toastError(this.toaster) as any)
  }

  addRate(rate: Rate): Observable<Rate> {
    const url = '@api/rate/group'

    return this.http.post<any>(url, rate.serialize()).pipe(
      map((result) => Rate.deserialize(result)),
      toastError(this.toaster) as any
    )
  }

  updateRate(rateId: string, rate: Rate): Observable<Rate> {
    const url = `@api/rate/group/${rateId}`

    let payload = rate.serialize() as any

    payload = {
      ...payload,
      additionalFeeAmount: payload.additionalFeeAmount || '',
      additionalFeeThreshold: payload.additionalFeeThreshold || '',
    }

    return this.http.put<object>(url, payload).pipe(
      map((result) => {
        // TODO: remove this once the API is fixed to return "pkey" in response.
        if (!result['pkey']) result['pkey'] = rateId
        return result
      }),
      map((result) => Rate.deserialize(result)),
      toastError(this.toaster) as any
    )
  }

  deleteRate(rateId: string) {
    const url = `@api/rate/group/${rateId}`

    return this.http.delete<object[]>(url).pipe(
      map((rates) => (lodash.isArray(rates) ? rates.map(Rate.deserialize) : [])),
      toastError(this.toaster)
    )
  }

  deleteRates(rates: Rate[]) {
    return concat(...rates.map((rate) => this.deleteRate(rate.rateId))).pipe(
      last(),
      switchMap(() => this.all())
    )
  }
}
