import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Room, CreateRoomPayload, UpdateRoomPayload } from './rooms.model'
import { Observable, of } from 'rxjs'
import { map, mergeMap, switchMap, toArray } from 'rxjs/operators'
import { isEmpty } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  constructor(private http: HttpClient) {}

  getAllRooms() {
    const url = `@api/room/all`

    return this.http.get<Room[]>(url)
  }

  getRentalRooms(rentalId: string) {
    const url = `@api/room/retrieve`

    return this.http.post<Room[]>(url, { rentals: [rentalId] })
  }

  createRoom(room: CreateRoomPayload) {
    const url = `@api/room/`

    return this.http.post<Room>(url, room)
  }

  createRooms(rooms: CreateRoomPayload[]): Observable<Room[]> {
    return of(...rooms).pipe(
      mergeMap((room) => this.createRoom(room)),
      toArray()
    )
  }

  upsertRooms(
    createRooms: CreateRoomPayload[],
    updateRooms: { id: string; payload: UpdateRoomPayload }[],
    deleteRooms: string[]
  ): Observable<Room[]> {
    const createRooms$ = isEmpty(createRooms) ? of([]) : this.createRooms(createRooms)
    const updateRooms$ = isEmpty(updateRooms) ? of([]) : this.updateRooms(updateRooms)
    const deleteRooms$ = isEmpty(deleteRooms) ? of([]) : this.deleteRooms(deleteRooms)
    return deleteRooms$.pipe(
      switchMap(() => createRooms$),
      switchMap((createdRooms) => updateRooms$.pipe(map((updatedRooms) => [...createdRooms, ...updatedRooms])))
    )
  }

  updateRoom(id: string, room: UpdateRoomPayload) {
    const url = `@api/room/${id}`

    return this.http.put<Room>(url, room)
  }

  updateRooms(rooms: { id: string; payload: UpdateRoomPayload }[]) {
    return of(...rooms).pipe(
      mergeMap((data) => this.updateRoom(data.id, data.payload)),
      toArray()
    )
  }

  deleteRoom(id: string) {
    const url = `@api/room/${id}`

    return this.http.delete(url, {})
  }

  deleteRooms(ids: string[]) {
    return of(...ids).pipe(
      mergeMap((id) => this.deleteRoom(id)),
      toArray()
    )
  }
}
