import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { deserializeArray, selectOnce, toMoment } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '../state'
import { Store } from '@ngrx/store'
import { CalendarResponse } from '@tv3/interfaces/responses/calendar.response'
import { CalendarEvent, CreateHoldEventpayload, UpdateHoldEventPayload } from '@tv3/store/calendar/calendar.model'
import { AuthService } from '@tv3/services/auth.service'
import { selectIsDoubleBookingDisabled } from '@tv3/store/preferences/preferences.selectors'
import { concatMap, map, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import * as moment from 'moment'
import { CalendarEventView } from './calendar.view'

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient, private auth: AuthService, private store: Store<fromRoot.State>) {}

  allHolds(rentalId?: string): Observable<CalendarEvent[]> {
    const url = `@api/calendar/holds/all/${rentalId ? `rental/${rentalId}` : ''}`

    return this.http.get<object[]>(url).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  create(requests: CreateHoldEventpayload[]): Observable<CalendarEvent[]> {
    const url = '@api/calendar/'

    return of(...requests).pipe(
      concatMap((request) =>
        this.http
          .post<object>(url, { ...request, source: request.source || 'tokeet' })
          .pipe(map((hold) => CalendarEvent.deserialize(hold)))
      ),
      toArray()
    )
  }

  createRecurring(requests: CreateHoldEventpayload[]) {
    const url = '@api/calendars'
    return this.http.post<any[]>(url, requests).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  updateRecurring(groupId: string, payload: UpdateHoldEventPayload) {
    const url = `@api/calendars/update/${groupId}`
    return this.http.put<any[]>(url, payload).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  deleteRecurring(groupId: string) {
    const url = `@api/calendars/delete/${groupId}`
    return this.http.delete(url).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  update(holdId: string, payload: UpdateHoldEventPayload): Observable<CalendarEvent> {
    const url = `@api/calendar/update/${holdId}`

    return this.http.put(url, payload).pipe(map((hold) => CalendarEvent.deserialize(hold)))
  }

  delete(id: string) {
    const url = `@api/calendar/delete/${id}`

    return this.http.delete(url)
  }

  deleteBatch(ids: string[]): Observable<string[]> {
    const url = '@api/calendar/delete/batch'

    return this.http.put<string[]>(url, { ids })
  }

  byRental(rentalId: string): Observable<CalendarEvent[]> {
    const url = `@api/calendar/all/${rentalId}`

    return this.http.get<CalendarResponse[]>(url).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  byRange(request: { start: number; end: number; past?: number }): Observable<CalendarEvent[]> {
    let past = request.past

    if (R.isNil(past)) {
      const now = moment.utc().endOf('date').unix()

      // if start is less than 1 year ago, so increase past
      if (now - request.start > 31104000) {
        past = now - request.start
      }
    }

    const params: Record<string, string> = {
      start: request.start.toString(),
      end: request.end.toString(),
    }

    if (!R.isNil(past)) {
      params.past = past.toString()
    }

    const url = '@api/calendar/all/'

    return this.http.get<CalendarResponse[]>(url, { params }).pipe(deserializeArray<CalendarEvent>(CalendarEvent))
  }

  checkOverlapping(range: { start; end }, events: CalendarEvent[], isBooking = true) {
    const result = new BehaviorSubject<CalendarEvent>(null)

    this.store
      .pipe(
        selectOnce(selectIsDoubleBookingDisabled),
        map((doubleBookingDisabled) => {
          const eventStart = R.is(Number, range.start) ? moment.utc(range.start * 1000) : moment.utc(range.start)
          const eventEnd = R.is(Number, range.end) ? moment.utc(range.end * 1000) : moment.utc(range.end)
          if (!eventStart.isSame(eventEnd, 'day')) {
            eventEnd.subtract(1, 'days')
          }

          let overlappingEvent: CalendarEvent = null

          for (let i = 0, len = events.length; i < len; i++) {
            const event = events[i]
            if (isBooking && !!event.bookingId && !doubleBookingDisabled) {
              // two bookings can overlap
              continue
            }
            const start = R.is(Number, event.start) ? moment.utc(event.start * 1000) : moment.utc(event.start)
            const end = R.is(Number, event.start) ? moment.utc(event.end * 1000) : moment.utc(event.end)
            if (!start.isSame(end, 'day')) {
              end.subtract(1, 'days')
            }

            if (
              eventStart.isBetween(start, end, 'day') ||
              eventEnd.isBetween(start, end, 'day') ||
              start.isBetween(eventStart, eventEnd) ||
              end.isBetween(eventStart, eventEnd) ||
              start.isSame(eventStart, 'day') ||
              start.isSame(eventEnd, 'day') ||
              end.isSame(eventStart, 'day') ||
              end.isSame(eventEnd, 'day')
            ) {
              overlappingEvent = event
              break
            }
          }
          return overlappingEvent
        })
      )
      .subscribe((overlappingEvent) => result.next(overlappingEvent))

    return result.value
  }

  getEventID(event: CalendarEvent) {
    return event.id || event.bookingId
  }

  getEventTimeRange(event: CalendarEventView) {
    if (!event.isHoldEvent) return { start: event.start, end: event.end, event }

    const start = toMoment(event.start)
    const end = toMoment(event.end)

    // TV3-1124:
    // if a hold event is a single day. the "start" and "date" fields will have the same value.
    // in this case, we add some hours to "end" field to make sure "start" and "end" time are different.
    // so the event will be visible in the calendar.

    if (start.isSame(end, 'date')) {
      end.endOf('date')
    }

    if (start.isSame(end, 'hour')) {
      end.add(6, 'hour')
    }

    return { start: start.unix(), end: end.unix(), event }
  }
}
