import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { DefaultPaymentGateway, PaymentGateway } from './gateways.model'
import { PaymentGatewayLog } from '../logs/gateway-log.model'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class GatewaysService {
  constructor(private http: HttpClient) {}

  all() {
    const url = '@api/payments/gateway/all/'

    return this.http.get<PaymentGateway[]>(url)
  }

  update(id, changes) {
    const url = `@api/payments/gateway/${id}`

    return this.http.put<PaymentGateway>(url, changes)
  }

  pause(id) {
    const url = `@api/payments/gateway/pause/${id}`

    return this.http.put(url, {})
  }

  resume(id) {
    const url = `@api/payments/gateway/resume/${id}`

    return this.http.put(url, {})
  }

  setDefault(id) {
    const url = `@api/payments/gateway/default/${id}`

    return this.http.put(url, {})
  }

  getDefault() {
    const url = `@api/payments/gateway/default`
    return this.http.get<DefaultPaymentGateway>(url)
  }

  remove(id) {
    const url = `@api/payments/gateway/${id}`

    return this.http.delete(url)
  }

  redact(id: string) {
    const url = `@api/payments/gateways/redact/${id}`

    return this.http.post(url, {})
  }

  updateCredentials(data) {
    const url = `@api/payments/gateways/credentials/`

    return this.http.post(url, data)
  }

  getLog(id) {
    const url = `@api/payments/gateway/logs/${id}`

    return this.http.get<PaymentGatewayLog[]>(url)
  }

  getAssignedGateway({
    gatewayId,
    rentalId,
    inquirySource,
    vault,
  }: {
    gatewayId?: string
    rentalId?: string
    inquirySource?: string
    vault?: number
  }) {
    const url = `@api/payments/gateway/assigned`

    // we default channel to webready because invoice can be for an Upsell
    const request = {
      gateway_id: gatewayId,
      rental_id: rentalId,
      channel: inquirySource || 'webready',
      vault: vault,
    }

    return this.http.post<PaymentGateway>(url, request)
  }

  createGateway(type: string, description: string, authType: string, credentials?: any) {
    const url = `@api/payments/gateways`

    return this.http
      .post(url, {
        type,
        description,
        mode: isSomething(authType) ? authType : type === 'test' ? 'default' : authType,
        credentials: credentials || {},
      })
      .pipe(switchMap((response: any) => this.resume(response.pkey).pipe(map(() => ({ ...response, status: 1 })))))
  }
}
