<eco-fab-speed-dial [(open)]="open" [direction]="direction" [animationMode]="animationMode">
  <eco-fab-speed-dial-trigger [spin]="spin">
    <button matTooltip="Get Help" matTooltipPosition="above" mat-fab class="trigger shadow-none" [class.active]="open">
      <i class="icon far fa-question text-xxxl"></i>
    </button>
  </eco-fab-speed-dial-trigger>

  <eco-fab-speed-dial-actions>
    <button
      matTooltip="Schedule Training"
      matTooltipPosition="below"
      mat-mini-fab
      (click)="goToScheduleTraining()"
      class="action"
    >
      <i class="icon far fa-calendar-alt"></i>
    </button>
    <button matTooltip="Help Center" matTooltipPosition="below" mat-mini-fab (click)="goToHelp()" class="action">
      <i class="icon far fa-book"></i>
    </button>
    <button matTooltip="Email Support" matTooltipPosition="below" mat-mini-fab class="action">
      <a href="mailto:support@tokeet.com">
        <i class="icon far fa-envelope"></i>
      </a>
    </button>
    <button matTooltip="Live Chat" matTooltipPosition="below" mat-mini-fab (click)="openChat()" class="action">
      <i class="icon far fa-comments"></i>
    </button>
    <button matTooltip="Community" matTooltipPosition="below" mat-mini-fab (click)="goToCommunity()" class="action">
      <i class="icon far fa-users"></i>
    </button>
    <button
      matTooltip="System Status"
      matTooltipPosition="below"
      mat-mini-fab
      (click)="goToTokeetStatus()"
      class="action"
    >
      <i class="icon far fa-server"></i>
    </button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
