import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import * as lodash from 'lodash'

declare const require: any
const JSONbig = require('json-bigint')({ storeAsString: true, protoAction: 'ignore', constructorAction: 'ignore' })

import { environment } from '../../environments/environment'
import { AuthService } from '../services/auth.service'

const API_MAPPINGS = {
  '@api/': environment.apiUrl,
  '@cmApi/': environment.config.CMBaseUrl,
  '@signatureApi/': environment.signatureApiUrl,
  '@rategenieApi/': environment.rategenieApiUrl,
  '@webready/': environment.webreadyApiUrl,
  '@papi/': environment.papiApiUrl,
  '@ssl/': `${environment.sslAPIUrl}/private`,
  '@ai/': `${environment.aiAPIUrl}/v1`,
}

const SESSION_EXPIRED_STATUS_CODES = [401, 403, 407]
const SESSION_EXPIRED_EXCLUDING_URLS = [
  'https://www.googleapis.com',
  '/user/password',
  '/user/login',
  '/user/2fa/verify/',
]

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRedirecting = false

  constructor(private authService: AuthService) {}

  addTokenType(url: string) {
    if (url.search(environment.papiApiUrl) === 0) {
      return `Bearer`
    } else {
      return ''
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mappedUrl = this.findMappedUrl(request.url)

    if (mappedUrl) {
      const token = this.authService.token

      const setHeaders = token
        ? {
            Authorization: `${this.addTokenType(mappedUrl)} ${token}`.trim(),
          }
        : {}

      request = request.clone({ url: mappedUrl, setHeaders })
    }
    let observable
    if (request.responseType !== 'json') {
      observable = next.handle(request)
    } else {
      observable = this.handleJsonResponse(request, next)
    }

    return observable.pipe(
      tap((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    )
  }

  private handleJsonResponse(httpRequest: HttpRequest<any>, next: HttpHandler) {
    httpRequest = httpRequest.clone({ responseType: 'text' })
    return next.handle(httpRequest).pipe(
      map((event) => {
        if (event instanceof HttpResponse && typeof event.body === 'string' && !!event.body?.length) {
          const type = event.headers.get('content-type')
          if (/application\/json/gi.test(type)) {
            return event.clone({ body: JSONbig.parse(event.body) })
          }
        }
        return event
      }),
      catchError((res) => {
        if (res instanceof HttpErrorResponse && typeof res.error === 'string' && !!res.error?.length) {
          const err = new HttpErrorResponse({
            error: JSON.parse(res.error),
            headers: res.headers,
            status: res.status,
            statusText: res.statusText,
            url: res.url,
          })
          return throwError(err)
        } else {
          return throwError(res)
        }
      })
    )
  }

  private findMappedUrl(url) {
    const prefix = lodash.keys(API_MAPPINGS).find((p) => url.startsWith(p))

    if (!prefix) return null

    return API_MAPPINGS[prefix] + url.slice(prefix.length - 1)
  }

  private handleResponse(response: any): Observable<HttpEvent<any>> {
    this.handleAuthError(response)

    return <Observable<HttpEvent<any>>>of({})
  }

  private handleError(error: any): Observable<HttpEvent<any>> {
    const isAuthError = this.handleAuthError(error)

    return !isAuthError ? throwError(error) : <Observable<HttpEvent<any>>>of({})
  }

  private handleAuthError(response: any) {
    // TODO: improve handling auth errors
    const url: string = response.url || ''

    const sessionExpired =
      SESSION_EXPIRED_STATUS_CODES.includes(response.status) &&
      !SESSION_EXPIRED_EXCLUDING_URLS.find((u) => url.includes(u)) &&
      !this.authService.isReadOnly()

    if (!sessionExpired) return false
    if (this.isRedirecting) return true

    this.isRedirecting = true
    this.authService.logout()

    return true
  }
}
