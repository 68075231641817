import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './state'
import * as reducer from './reducer'

export const customChannelState = createFeatureSelector<state.State>('custom-channels')

export const selectCustomChannelsLoaded = createSelector(customChannelState, (state: state.State) => state.isLoaded)

export const selectCustomChannelEntities = createSelector(customChannelState, reducer.selectEntities)

export const selectCustomChannelById = (id: string) =>
  createSelector(selectCustomChannelEntities, (itemsById) => {
    return itemsById[id]
  })

export const selectAllCustomChannels = createSelector(customChannelState, reducer.selectAll)

export const selectCustomChannelAsSources = createSelector(selectAllCustomChannels, (items) => {
  return items.map((item) => ({
    channelId: item.pkey,
    id: item.channel_name,
    name: item.channel_name,
    label: item.friendly_name,
    custom: true,
  }))
})
