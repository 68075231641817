import { Injectable } from '@angular/core'
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

const I18N_VALUES = {
  en: {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
}

@Injectable()
export class I18n {
  language = 'en'
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class LocalizedDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super()
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1]
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1]
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month)
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`
  }
}
