import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectUniqueCodeNames } from '@tv3/store/custom-codes/custom-code.selectors'
import { GetAllCustomCodes } from '@tv3/store/custom-codes/custom-code.actions'
import { DataDictService } from '@tv3/services/data-dict.service'
import * as R from 'ramda'

@Component({
  selector: 'app-data-dictionary-dialog',
  host: { class: 'modal-content' },
  templateUrl: './data-dictionary-dialog.component.html',
  styleUrls: ['./data-dictionary-dialog.component.scss'],
})
export class DataDictionaryDialogComponent implements OnInit {
  dictionary = {
    ...this.dataDictService.getAvailableKeys(),
  }

  invoiceTags = [
    { key: `*|BRANDING:name|*`, tooltip: 'The name of the user who is sending the message.' },
    { key: `*|BRANDING:reference_id|*`, tooltip: 'A unique AdvanceCM id for this specific message.' },
    { key: `*|BRANDING:invoice_link|*`, tooltip: 'The URL to your invoice.' },
  ]

  hasBranding = false

  custom$ = this.store.pipe(select(selectUniqueCodeNames))

  constructor(
    public dialogRef: MatDialogRef<DataDictionaryDialogComponent>,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { branding: boolean },
    private dataDictService: DataDictService
  ) {}

  ngOnInit() {
    this.hasBranding = R.pathOr(false, ['branding'], this.data)
    this.store.dispatch(GetAllCustomCodes())
  }

  addTag(tag: string) {
    this.dialogRef.close(tag)
  }

  close() {
    this.dialogRef.close()
  }
}
