import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { SearchService } from '@tv3/store/search/search.service'
import { Subject } from 'rxjs'
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators'
import { ESResult } from '@tv3/store/search/search-result.model'
import * as R from 'ramda'
import { SearchResultType } from '@tv3/layout/navbar/search/search-result-type'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FormControl } from '@angular/forms'
import { OpenGuestOverlay, OpenInquiryOverlay, OpenInvoiceOverlay } from '@tv3/store/overlay/overlay.actions'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  host: { class: 'search-holder' },
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: false }) searchInput

  results: ESResult[] = []

  types = SearchResultType

  isOpen = false

  searchCtrl = new FormControl('')

  private destroy$ = new Subject()

  constructor(private searchService: SearchService, private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(
        filter((term) => {
          const hasTerm = isSomething(term)
          if (!hasTerm) {
            this.results = []
          }
          return hasTerm
        }),
        debounceTime(500),
        switchMap((term) => this.searchService.search<ESResult>(term)),
        takeUntil(this.destroy$)
      )
      .subscribe((results) => {
        this.isOpen = true
        this.results = results
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  guestSource(source: string) {
    if (isSomething(source)) {
      return `from ${source} `
    } else {
      return 'from AdvanceCM '
    }
  }

  onClearSearch() {
    this.searchCtrl.patchValue('')
  }

  onFocus() {
    if (!R.isEmpty(this.results)) {
      this.isOpen = true
    }
  }

  onClickOutside(event: Event) {
    if (R.is(Function, (<any>event.target).closest)) {
      const closest = (<any>event.target).closest('.navbar-search-box')
      if (R.isNil(closest)) {
        this.isOpen = false
      }
    } else {
      const classNames = (<any>event.target).className || ''
      if (classNames.indexOf('search-dropdown') === -1) {
        this.isOpen = false
      }
    }
  }

  onInquiryDetails(inquiryId: string) {
    this.store.dispatch(OpenInquiryOverlay({ inquiryId }))
  }

  onGuestDetails(guestId: string) {
    this.store.dispatch(OpenGuestOverlay({ guestId }))
  }

  onInvoiceDetails(invoiceId: string) {
    this.store.dispatch(OpenInvoiceOverlay({ invoiceId }))
  }
}
