import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  Destroyable,
  isSomething,
  Rental,
  SaveForm,
  selectChannelById,
  selectRentalEntities,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import {
  BookingFormulaItem,
  BookingFormulaBaseType,
  BookingFormulaResponse,
  formulaAppliedMonths,
  isFormulasMonthOverlap,
  ToggleBookingFormulaStatus,
  selectAllBookingFormulas,
  UpdateBookingFormula,
  CreateBookingFormula,
  getFormulaScheduling,
  selectChannelsForFormulas,
} from '@tokeet-frontend/booking-formula'
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as R from 'ramda'
import { switchMap, map } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import { percent, positive } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'
import * as moment from 'moment'
import { selectLinkedRentalsForChannel } from '@tv3/store/connection/connection.selectors'

@Component({
  selector: 'app-booking-formula-dialog',
  host: { class: 'modal-content' },
  templateUrl: './booking-formula-dialog.component.html',
  styleUrls: ['./booking-formula-dialog.component.scss'],
})
export class BookingFormulaDialogComponent extends Destroyable implements OnInit {
  baseTypes = [
    { label: 'Gross Booking Total', value: BookingFormulaBaseType.BookingTotal },
    { label: 'Guest Payout', value: BookingFormulaBaseType.GuestPayout },
  ]

  isBaseTypeCustomizable = false

  form = this.fb.group(
    {
      base: [BookingFormulaBaseType.BookingTotal, [Validators.required]],
      name: ['', [Validators.required]],
      formula: this.fb.array([]),
      rentals: [[]],
      channel_id: ['', [Validators.required]],
      start_month: [
        undefined,
        [
          Validators.required,
          (ctrl: AbstractControl) => {
            const v = ctrl.value
            const end = this.form?.get('end_month').value
            if (!lodash.isNumber(v) || !lodash.isNumber(end)) {
              return
            }
            if (v > end) {
              return { max: true }
            }
          },
        ],
      ],
      end_month: [
        undefined,
        [
          Validators.required,
          (ctrl: AbstractControl) => {
            const v = ctrl.value
            const start = this.form?.get('start_month').value
            if (!lodash.isNumber(v) || !lodash.isNumber(start)) {
              return
            }
            if (v < start) {
              return { min: true }
            }
          },
        ],
      ],
      status: [true],
      cumulative: [true],
    },
    {
      validators: [
        (form: FormGroup) => {
          const { start_month, end_month, rentals } = form.getRawValue()
          if (!lodash.isNumber(start_month) || !lodash.isNumber(end_month)) {
            return
          }
          const currentAppliedMonths = formulaAppliedMonths(start_month, end_month)
          const overlap = isFormulasMonthOverlap(this.appliedMonths, rentals, currentAppliedMonths)

          const startCtrl = form.get('start_month')
          if (overlap) {
            startCtrl.setErrors({ overlap: true })
            return { overlap: true }
          } else {
            const errors = lodash.omit({ ...startCtrl.errors }, 'overlap')
            startCtrl.setErrors(lodash.isEmpty(errors) ? null : errors)
          }
        },
      ],
    }
  )

  get formulaItems() {
    return this.form.get('formula') as FormArray
  }

  get rentalIds() {
    return this.form.get('rentals').value || []
  }

  get formula() {
    return this.data.formula
  }

  rentals: Rental[] = []
  rentalsById: { [key: string]: Rental } = {}
  months = moment.months().map((m, index) => ({ label: m, value: index }))

  appliedMonths: { rentals: string[]; months: number[] }[] = []

  channels: { id: string; name: string }[] = []

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<BookingFormulaDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      channel?: { id: string; name: string }
      formula?: BookingFormulaResponse
    }
  ) {
    super()
  }

  ngOnInit() {
    const formula = this.data.formula

    combineLatest([
      this.store
        .pipe(select(selectAllBookingFormulas))
        .pipe(map((items) => (!this.data.channel ? items : items.filter((t) => t.channel_id == this.data.channel.id)))),
      this.form
        .get('channel_id')
        .valueChanges.pipe(switchMap((id) => this.store.pipe(select(selectChannelById, { id })))),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([formulas, channel]) => {
        this.isBaseTypeCustomizable = /airbnb/.test(channel.name)
        // if (!this.isBaseTypeCustomizable && !this.formulaItems.length) {
        //   this.formulaItems.push(this.createFormula())
        // }
        this.appliedMonths = this.getAppliedMonths(formulas.filter((f) => f.pkey !== formula?.pkey))
      })

    this.form
      .get('channel_id')
      .valueChanges.pipe(
        switchMap((channelId) =>
          this.store.pipe(
            select(selectLinkedRentalsForChannel(channelId)),
            map((items) => lodash.sortBy(items, (t) => lodash.toLower(t.name)))
          )
        ),
        untilDestroy(this)
      )
      .subscribe((rentals) => {
        this.rentals = rentals
      })

    this.formulaItems.clear()
    if (isSomething(formula)) {
      const range = getFormulaScheduling(formula)
      this.form.patchValue({
        base: formula.base || BookingFormulaBaseType.BookingTotal,
        name: formula.name,
        status: !!formula.status,
        cumulative: !!formula.cumulative,
        rentals: formula.rentals || [],
        start_month: range.start_month,
        end_month: range.end_month,
        channel_id: formula.channel_id,
      })
      R.map((t: BookingFormulaItem) => this.formulaItems.push(this.createFormula(t)), formula.formula)
    } else if (this.data.channel) {
      this.form.patchValue({ channel_id: this.data.channel.id })
    }

    this.store.pipe(select(selectRentalEntities), untilDestroy(this)).subscribe((res) => {
      this.rentalsById = res
    })

    this.store
      .pipe(select(selectChannelsForFormulas))
      .pipe(untilDestroy(this))
      .subscribe((channels) => {
        this.channels = channels
      })
  }

  getRentalsView(rentalIds: string[]) {
    return R.values(R.pick(rentalIds, this.rentalsById))
      .map((r) => r.name)
      .join(', ')
  }

  createFormula(formula?: BookingFormulaItem) {
    const form = this.fb.group({
      amount: [R.pathOr(undefined, ['amount'], formula), [Validators.required]],
      name: [R.pathOr(undefined, ['name'], formula), [Validators.required]],
      type: [R.pathOr('percent', ['type'], formula)],
      operation: [R.pathOr('add', ['operation'], formula)],
    })

    const amountCtrl = form.get('amount')
    form.get('type').valueChanges.subscribe((type) => {
      amountCtrl.setValidators([Validators.required, type === 'percent' ? percent() : positive()])
      amountCtrl.updateValueAndValidity()
    })

    return form
  }

  addFormula(formula?: BookingFormulaItem) {
    this.formulaItems.push(this.createFormula(formula))
  }

  removeFormula(index: number) {
    this.formulaItems.removeAt(index)
  }

  close() {
    this.dialogRef.close()
  }

  onToggleStatus(status: boolean) {
    if (this.formula.pkey) {
      this.store.dispatch(ToggleBookingFormulaStatus({ id: this.formula.pkey, status }))
    }
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { start_month, end_month, ...others } = form.getRawValue()
    const payload = {
      ...others,
      status: others.status ? 1 : 0,
      cumulative: others.cumulative ? 1 : 0,
      scheduling: { start_month, end_month },
    }

    if (this.formula?.pkey) {
      this.store.dispatch(UpdateBookingFormula({ id: this.formula.pkey, payload }))
    } else {
      this.store.dispatch(CreateBookingFormula({ payload }))
      this.close()
    }
  }

  getAppliedMonths(items: BookingFormulaResponse[]): { rentals: string[]; months: number[] }[] {
    return lodash.map(items, (t) => ({
      rentals: t.rentals,
      months: formulaAppliedMonths(t.scheduling?.start_month, t.scheduling?.end_month),
    }))
  }

  // updateAmplitude(editing: boolean) {
  //   if (isSomething(this.data.channel)) {
  //     const channelName = this.data.channel.name?.toLowerCase()
  //     this.amplitudeService.logEvent(`${editing ? 'set' : 'add'}-booking-formula-${channelName}`)
  //   }
  // }
}
