import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { ImportCalendarDialogComponent } from './import-calendar-dialog.component'
import { Channel, DialogService, Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ImportCalendarDialogService {
  constructor(private dialog: DialogService) {}

  open(data?: { isChannelNameRequired?: boolean; channel?: Channel; rental?: Rental }) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      data: { ...data },
    }

    return this.dialog.openFixedDialog(ImportCalendarDialogComponent, defaultConfig)
  }
}
