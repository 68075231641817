import { Pipe, PipeTransform } from '@angular/core'
import { getNightsBetweenArrivalDepart } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'nights',
})
export class NightsPipe implements PipeTransform {
  transform(start, end) {
    return getNightsBetweenArrivalDepart(start, end)
  }
}
