<div *ngIf="label" class="colors-label">{{ label }}</div>
<div class="colors-wrapper">
  <div
    *ngFor="let color of colors"
    [style.background-color]="color"
    (click)="ctrl.setValue(color)"
    [class.disabled]="ctrl.disabled"
    [class.active]="ctrl.value === color"
  ></div>
</div>
