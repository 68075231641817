import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { Action, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Router } from '@angular/router'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  AddWebsiteImages,
  AddWebsiteImagesComplete,
  DeleteWebsiteImage,
  DeleteWebsiteImageComplete,
  LoadWebsiteImages,
  LoadWebsiteImagesComplete,
  MarkWebsiteImageAsFavicon,
  MarkWebsiteImageAsFaviconComplete,
  MarkWebsiteImageAsLogo,
  MarkWebsiteImageAsLogoComplete,
  MarkWebsiteImageAsPrimary,
  MarkWebsiteImageAsPrimaryComplete,
  UpdateWebsiteImage,
  UpdateWebsiteImageComplete,
  UpdateWebsiteImageOrders,
  UpdateWebsiteImageOrdersComplete,
} from '@tv3/store/website-image/website-image.actions'
import { WebsiteImageService } from '@tv3/store/website-image/website-image.service'
import { TokeetImage } from '@tv3/models/rental/tokeet-image'
import { Update } from '@ngrx/entity'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class WebsiteImageEffects {
  @Effect()
  loadAll$ = this.actions$.pipe(
    ofType(LoadWebsiteImages),
    switchMap(({ websiteId }) =>
      this.websiteImageService.all(websiteId).pipe(
        map((images) => LoadWebsiteImagesComplete({ images })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(AddWebsiteImages),
    switchMap(({ websiteId, data }) =>
      forkJoin(data.map((attachment) => this.websiteImageService.add({ ...attachment, website_id: websiteId }))).pipe(
        map((images) => AddWebsiteImagesComplete({ images })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateWebsiteImage),
    switchMap(({ imageId, data }) =>
      this.websiteImageService.update(imageId, data).pipe(
        tap(() => this.toaster.success('Website image updated successfully.')),
        map((image) => UpdateWebsiteImageComplete({ update: { id: imageId, changes: image } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateOrders$ = this.actions$.pipe(
    ofType(UpdateWebsiteImageOrders),
    switchMap(({ orderByIds }) => {
      const observers = R.keys(orderByIds).map((id: string) => {
        return this.websiteImageService.update(id, { order: orderByIds[id] })
      })
      return forkJoin(observers).pipe(
        map(() =>
          UpdateWebsiteImageOrdersComplete({
            updates: R.keys(orderByIds).map((id) => {
              return { id, changes: { order: orderByIds[id] } } as Update<TokeetImage>
            }),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteWebsiteImage),
    switchMap(({ imageId }) =>
      this.websiteImageService.delete(imageId).pipe(
        tap(() => this.toaster.success('Website image deleted successfully.')),
        map((res) => DeleteWebsiteImageComplete({ id: imageId })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  markAsPrimary$ = this.actions$.pipe(
    ofType(MarkWebsiteImageAsPrimary),
    switchMap(({ websiteId, imageId, images }) => {
      const primaryImage = R.find((i) => i.id === imageId, images)
      // mark as primary
      const observers = [this.websiteImageService.markAsPrimary(websiteId, imageId)]
      // reorder images
      observers.push(this.websiteImageService.update(imageId, { order: 0 }))
      R.forEach((image) => {
        if (image.order < primaryImage.order) {
          observers.push(this.websiteImageService.update(image.id, { order: image.order + 1 }))
        }
      }, images)
      // do actions
      return forkJoin(observers).pipe(
        switchMap((res) => [
          MarkWebsiteImageAsPrimaryComplete({ update: { id: imageId, changes: { primary: 1 } } }),
          LoadWebsiteImages({ websiteId }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  markAsFavicon$ = this.actions$.pipe(
    ofType(MarkWebsiteImageAsFavicon),
    switchMap(({ websiteId, imageId, images }) => {
      const oldFavicon: any = R.find((i) => !!i.favicon, images)
      return this.websiteImageService.markAsFavicon(websiteId, imageId).pipe(
        switchMap(() => {
          const actions: Action[] = [
            MarkWebsiteImageAsFaviconComplete({ update: { id: imageId, changes: { favicon: 1 } } }),
          ]
          if (oldFavicon) {
            actions.push(UpdateWebsiteImageComplete({ update: { id: oldFavicon.id, changes: { favicon: 0 } } }))
          }
          return actions
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  markAsLogo$ = this.actions$.pipe(
    ofType(MarkWebsiteImageAsLogo),
    switchMap(({ websiteId, imageId, images }) => {
      const oldLogo: any = R.find((i) => !!i.logo, images)
      return this.websiteImageService.markAsLogo(websiteId, imageId).pipe(
        switchMap(() => {
          const actions: Action[] = [MarkWebsiteImageAsLogoComplete({ update: { id: imageId, changes: { logo: 1 } } })]
          if (oldLogo) {
            actions.push(UpdateWebsiteImageComplete({ update: { id: oldLogo.id, changes: { logo: 0 } } }))
          }
          return actions
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private toaster: Toaster,
    private store: Store<fromRoot.State>,
    private websiteImageService: WebsiteImageService
  ) {}
}
