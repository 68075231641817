<div class="fee border bg-white rounded p-3 pl-4 position-relative">
  <button
    type="button"
    class="btn btn-sm btn-circle btn-secondary-danger position-absolute"
    matTooltip="Remove Fee"
    matTooltipPosition="above"
    (click)="remove.emit(true)"
    style="top: 7rem; margin-top: -2rem; left: -0.85rem; z-index: 1"
  >
    <i class="cs-icon cs-icon-trash"></i>
  </button>

  <form [formGroup]="form">
    <div class="fee_row main_row">
      <div class="col-50">
        <div class="fee_row">
          <div class="col-50 field-wrapper-mr">
            <mat-form-field2 class="name-field">
              <input matInput placeholder="Name" formControlName="name" [matAutocomplete]="auto" type="text" required />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let category of feeCategories"
                  [value]="category"
                  [matTooltip]="category"
                  matTooltipPosition="right"
                  class="category-option"
                  >{{ category }}</mat-option
                >
              </mat-autocomplete>
              <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-50 field-wrapper-ml field-wrapper-mr">
            <mat-form-field2>
              <input
                matInput
                [placeholder]="form.get('type').value === 'pct' ? 'Amount (%)' : 'Amount (' + currencyCode + ')'"
                formControlName="amount"
                type="number"
                required
              />
              <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
                Amount is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('min')">
                Amount must be <strong>at least 1</strong>
              </mat-error>
              <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('max')">
                Amount must be <strong>at most 100</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
        <div class="fee_row">
          <div class="col-50 field-wrapper-mr">
            <mat-form-field2>
              <mat-select placeholder="Type" formControlName="type" required>
                <mat-option value="pct">Percent</mat-option>
                <mat-option value="flat">Flat</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('type').touched && form.get('type').hasError('required')">
                Type is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-50 field-wrapper-ml field-wrapper-mr">
            <mat-form-field2>
              <mat-select
                matTooltip="Select the modality. This determines how the fee is applied to the booking"
                matTooltipPosition="above"
                placeholder="Modality"
                formControlName="modality"
                required
              >
                <mat-option value="ps">Per Stay</mat-option>
                <mat-option value="pn">Per Night</mat-option>
                <mat-option value="pp">Per Person</mat-option>
                <mat-option value="pppn">Per Person/Per Night (PP/PN)</mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('modality').touched && form.get('modality').hasError('required')">
                Modality is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
      </div>
      <div class="col-50 field-wrapper-ml">
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            maxlength="500"
            rows="6"
            formControlName="description"
            placeholder="Description"
            class="description-textarea"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field2>
      </div>
    </div>

    <!-- <button
      class="btn btn-sm btn-light-danger position-absolute"
      matTooltip="Remove Fee"
      matTooltipPosition="above"
      (click)="remove.emit(true)"
      style="top: -0.5rem; right: 0"
    >
      <i class="cs-icon cs-icon-trash"></i>
    </button> -->

    <div class="d-flex align-items-center gap-3 mb-3">
      <app-form-switch formControlName="status" [inline]="true">{{
        form.get('status').value ? 'Active' : 'Inactive'
      }}</app-form-switch>
      <mat-checkbox
        matTooltip="This indicates if the fee is charged upfront"
        matTooltipPosition="above"
        formControlName="upfront"
        class="upfront-control"
        >Upfront</mat-checkbox
      >
      <div class="flex-fill"></div>
      <div class="show-more">
        <a *ngIf="!showDetails" (click)="showDetails = true" href="javascript:;">
          <i class="fal fa-chevron-down"></i> More Options
        </a>
        <a *ngIf="showDetails" (click)="showDetails = false" href="javascript:;">
          <i class="fal fa-chevron-up"></i> Less Options
        </a>
        <mat-error *ngIf="!showDetails && form.get('end').touched && form.get('end').invalid && endDateInPast()">
          Scheduling End date shouldn't be in the past
        </mat-error>
        <mat-error
          *ngIf="
            !showDetails &&
            ((startCtrl.touched && startCtrl.hasError('required')) || (endCtrl.touched && endCtrl.hasError('required')))
          "
        >
          Both scheduling start and end dates are required
        </mat-error>
      </div>
    </div>

    <div [class.hidden]="!showDetails">
      <div class="form_row">
        <mat-form-field2>
          <input
            matTooltip="This indicates if the fee applies over or under the set guest count. Use positive values to indicate over and negative to indicate under"
            matTooltipPosition="above"
            matInput
            placeholder="Guest Threshold"
            formControlName="guest_threshold"
            type="number"
          />
        </mat-form-field2>

        <mat-form-field2>
          <input
            matTooltip="This indicates if the fee applies over or under a specific length. Use positive values to indicate over and negative to indicate under"
            matTooltipPosition="above"
            matInput
            placeholder="Length Threshold"
            formControlName="length_threshold"
            type="number"
          />
        </mat-form-field2>

        <mat-form-field2 class="mat-calendar">
          <input
            matInput
            [matDatepicker]="startPicker"
            [max]="maxStartDate.value"
            placeholder="Scheduling Start"
            matTooltip="This determines on which dates the fee is applied."
            matTooltipPosition="above"
            (focus)="startPicker.open()"
            formControlName="start"
            tabindex="6"
            [required]="!!endCtrl.value"
          />
          <i matSuffix (click)="startPicker.open()" class="far fa-calendar-alt"></i>
          <mat-datepicker #startPicker></mat-datepicker>
          <mat-error *ngIf="form.get('start').touched && form.get('start').hasError('required')">
            Scheduling Start date is required
          </mat-error>
        </mat-form-field2>

        <mat-form-field2 class="mat-calendar">
          <input
            matInput
            [matDatepicker]="endPicker"
            [min]="minEndDate.value"
            placeholder="Scheduling End"
            matTooltip="This determines on which dates the fee is applied."
            matTooltipPosition="above"
            (focus)="endPicker.open()"
            formControlName="end"
            [required]="!!startCtrl.value"
            tabindex="6"
          />
          <i matSuffix (click)="endPicker.open()" class="far fa-calendar-alt"></i>
          <mat-datepicker #endPicker></mat-datepicker>
          <mat-error *ngIf="form.get('end').touched && form.get('end').invalid && endDateInPast()">
            Scheduling End date shouldn't be in the past
          </mat-error>
          <mat-error *ngIf="form.get('end').touched && form.get('end').hasError('required')">
            Scheduling End date is required
          </mat-error>
          <mat-error *ngIf="form.get('end').hasError('matDatepickerMin')">
            Min value is <strong>{{ form.get('end').getError('matDatepickerMin').min | epoch: 'DD-MMM-YYYY' }}</strong>
          </mat-error>
        </mat-form-field2>
      </div>

      <div class="form_row">
        <app-select-search
          bindLabel="friendlyName"
          bindValue="id"
          [inline]="false"
          [multiple]="false"
          [items]="channels$ | async"
          [ctrl]="form.get('channel_id')"
          placeholder="Channel"
        ></app-select-search>

        <mat-form-field2>
          <mat-select
            matTooltip="This determines on which days the fee is applied"
            matTooltipPosition="above"
            placeholder="Day of Week"
            formControlName="dow"
            multiple
          >
            <mat-option value="1">Monday</mat-option>
            <mat-option value="2">Tuesday</mat-option>
            <mat-option value="3">Wednesday</mat-option>
            <mat-option value="4">Thursday</mat-option>
            <mat-option value="5">Friday</mat-option>
            <mat-option value="6">Saturday</mat-option>
            <mat-option value="7">Sunday</mat-option>
          </mat-select>
        </mat-form-field2>

        <mat-checkbox
          matTooltip="This indicates if the fee is refundable"
          matTooltipPosition="above"
          formControlName="refundable"
          >Refundable</mat-checkbox
        >

        <mat-checkbox
          matTooltip="This indicates if the fee is taxable"
          matTooltipPosition="above"
          formControlName="taxable"
          >Taxable</mat-checkbox
        >
      </div>
    </div>
  </form>
</div>
