import { Injectable } from '@angular/core'
import { NotesDialogComponent } from './notes-dialog.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class NotesSidePanelService {
  constructor(private sidePanel: DialogService) {}

  open(inquiry: Inquiry) {
    return this.sidePanel.openDrawer(NotesDialogComponent, {
      data: { inquiry },
    })
  }
}
