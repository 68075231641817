<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex justify-content-between align-items-center col-sm-12">
        <h5 class="modal-title">Channels</h5>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar border-bottom bg-white" role="tablist">
      <li [class.active]="channelType === 'api'">
        <a (click)="channelType = 'api'"> API </a>
      </li>
      <li [class.active]="channelType === 'ical'">
        <a (click)="channelType = 'ical'"> iCal </a>
      </li>
      <li [class.active]="channelType === 'custom'">
        <a (click)="channelType = 'custom'"> Custom </a>
      </li>
    </ul>
    <div class="modal-body">
      <div class="d-flex justify-content-end mb-3" *ngIf="channelType === 'custom'">
        <button (click)="onAddCustom()" class="btn btn-secondary-info">Add Custom Channel</button>
      </div>
      <app-channel-grid [channelType]="channelType"></app-channel-grid>
    </div>
  </div>
</div>
