import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { DataCheckerService } from '@tokeet-frontend/tv3-platform'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { InvoiceTemplateGuard, selectAllInvoiceTemplatesByName } from '@tokeet-frontend/invoices'

@Component({
  selector: 'app-select-invoice-template-dialog',
  host: { class: 'modal-content' },
  templateUrl: './select-invoice-template.component.html',
  styleUrls: ['./select-invoice-template.component.scss'],
})
export class SelectInvoiceTemplateDialogComponent {
  form = this.fb.group({
    template: ['', [Validators.required]],
    bookingId: [],
  })

  templates$ = this.store.pipe(select(selectAllInvoiceTemplatesByName))

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    dataCheckerService: DataCheckerService,
    public dialogRef: MatDialogRef<SelectInvoiceTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectBooking: boolean }
  ) {
    dataCheckerService.check([InvoiceTemplateGuard])
    if (this.data.selectBooking) {
      this.form.get('bookingId').setValidators([Validators.required])
    }
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const values = form.getRawValue()
    this.dialogRef.close(values)
  }

  onClose() {
    this.dialogRef.close()
  }
}
