import { PaymentGatewayType, SpreedlySupportedGateway } from '../types/gateway-type.model'

export interface PaymentGateway {
  pkey: string
  gateway: string
  service: PaymentGatewayServiceIdentities
  default: number
  handler: string
  credentials: any
  archived: number
  status: number
  name: string
  account: number
  created: number
  owner_id: string
}

export class DefaultPaymentGateway {
  gateway_id: string
  livemode: boolean
  service: PaymentGatewayServiceIdentities
}

export interface PaymentGatewayView extends PaymentGateway {
  type: PaymentGatewayType
  spreedly: SpreedlySupportedGateway
  gatewayName: string
  identity: string
  ownerName?: string
}

export enum PaymentGatewayServiceIdentities {
  PayPal = 'paypal',
  Stripe = 'stripe',
  Worldpay = 'worldpay',
  Braintree = 'braintree',
  Paymill = 'paymill',
  PayPalCommercePlatform = 'paypal_commerce_platform',
  Paysafe = 'optimal_payments',
  AuthorizeNET = 'authorize_net',
}

export const enabledPaymentGatewayTypes = [
  PaymentGatewayServiceIdentities.PayPal,
  PaymentGatewayServiceIdentities.Stripe,
  PaymentGatewayServiceIdentities.Worldpay,
  PaymentGatewayServiceIdentities.Braintree,
  PaymentGatewayServiceIdentities.Paymill,
  PaymentGatewayServiceIdentities.PayPalCommercePlatform,
  PaymentGatewayServiceIdentities.Paysafe,
  PaymentGatewayServiceIdentities.AuthorizeNET,
]
