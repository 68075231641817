import { Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { EventEmitter, OnInit, Directive } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { InvoiceTemplate } from '../../../store/templates/template.model'
import { InvoiceRuleGroup } from '../../../store/rules/rule.model'
import { selectInvoiceTemplateRules } from '../../../store/rules/rule.selectors'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { asyncScheduler, of, Subject } from 'rxjs'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { filter, observeOn, switchMap } from 'rxjs/operators'

@Directive()
export abstract class InvoicingRulesAbstractComponent extends Destroyable implements OnInit {
  form: FormGroup
  template: InvoiceTemplate
  valid = new EventEmitter<boolean>()
  paginator: MatPaginator
  sort: MatSort

  displayedColumns = []

  dataSource = new MatTableDataSource<InvoiceRuleGroup>()

  page = new Subject<Partial<PageEvent>>()

  tableType
  isEmptyTable$ = isEmptyTable(this.dataSource)

  constructor(protected store: Store<any>) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    of(this.template)
      .pipe(
        filter((template) => isSomething(template)),
        switchMap((template) => this.store.pipe(select(selectInvoiceTemplateRules(template.pkey)))),
        observeOn(asyncScheduler),
        untilDestroy(this)
      )
      .subscribe((rules) => {
        this.dataSource.data = rules
      })

    this.valid.emit(true)
  }
}
