import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { filter, take } from 'rxjs/operators'
import { AddAttributeDialogComponent } from '@tv3/containers/guests/dialogs/add-attribute/add-attribute-dialog.component'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AddAttributeDialogService {
  constructor(private dialog: MatDialog) {}

  public open() {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      panelClass: ['fixed-modal'],
    }

    return this.dialog
      .open(AddAttributeDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter((res) => isSomething(res))
      )
  }
}
