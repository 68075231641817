import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

  transform(bytes: number = 0, precision: number = 2): string {
    bytes = parseFloat(String(bytes))
    if (isNaN(bytes) || !isFinite(bytes)) {
      return '?'
    }

    let unit = 0

    while (bytes >= 1024) {
      bytes /= 1024
      unit++
    }

    unit = unit > this.units.length - 1 ? this.units.length - 1 : unit

    return bytes.toFixed(unit === 0 ? 0 : precision) + ' ' + this.units[unit]
  }
}
