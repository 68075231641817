import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import { distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  Destroyable,
  SaveForm,
  untilDestroy,
  CreateRateMappingPayload,
  addRateMapping,
  isSomething,
  selectRentalRateMappings,
  ActionFailed,
  DataCheckerService,
  RentalRatesGuard,
  updateRateMappings,
} from '@tokeet-frontend/tv3-platform'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { RateMappingResponse } from '@tokeet-frontend/tv3-platform'
import { RentalRateService } from '@tv3/services/rental-rate.service'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { ChannelRoomRateResponse, GetChannelRoomRatesPayload } from '@tv3/store/connection/connection.types'
import { selectAllAPIConnectionViews } from '@tv3/store/connection/connection.selectors'
import { rateMappingDisabledChannels } from '@tokeet-frontend/channels'
import { combineLatest } from 'rxjs'

export interface AddRateMappingDialogInput {
  rentalId?: string
  connection?: ConnectionView
  mapping?: RateMappingResponse
}

@Component({
  selector: 'app-add-rate-mapping-dialog',
  templateUrl: './add-rate-mapping-dialog.component.html',
  // tslint:disable use-host-property-decorator
  host: { class: 'modal-content' },
  styleUrls: ['./add-rate-mapping-dialog.component.scss'],
})
export class AddRateMappingDialogComponent extends Destroyable implements OnInit {
  form: FormGroup = this.formBuilder.group({
    rentalId: [null, [Validators.required]],
    category: [null, [Validators.required]],
    propertyId: [null, [Validators.required]],
    rateid: [null, [Validators.required]],
    frequency: ['daily', [Validators.required]],
  })

  frequencyOptions = [
    { id: 'daily', label: 'Daily' },
    { id: 'weekly', label: 'Weekly' },
    { id: 'disabled', label: 'Disabled' },
  ]

  selectedConnection: ConnectionView
  get selectedChannelId() {
    const connection = this.selectedConnection
    return (connection && connection.channelId) || null
  }

  rentals$ = this.store.pipe(
    select(selectAllAPIConnectionViews),
    map((items) =>
      lodash
        .chain(items)
        .map((c) => c.rental)
        .compact()
        .uniqBy((t) => t.id)
        .value()
    )
  )

  isEdit = false
  get mapping() {
    return this.data.mapping
  }

  connections: ConnectionView[]
  categories: { id: string; name: string }[]
  rateMappings: RateMappingResponse[] = []

  roomRates: Record<string, ChannelRoomRateResponse[]> = {}
  loadingRoomRates: Record<string, boolean> = {}

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
    private connectionService: ConnectionService,
    private rentalRateService: RentalRateService,
    public dialogRef: MatDialogRef<AddRateMappingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddRateMappingDialogInput,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([RentalRatesGuard])
    this.selectedConnection = this.data.connection
    this.isEdit = !!this.data.mapping
  }

  ngOnInit() {
    const { connection } = this.data

    const rentalId$ = this.form.get('rentalId').valueChanges.pipe(distinctUntilChanged())

    const availableConnections$ = rentalId$.pipe(
      filter(isSomething),
      switchMap((rentalId) =>
        this.store.pipe(
          select(selectAllAPIConnectionViews),
          map((items) => items.filter((t) => t.rentalId === rentalId))
        )
      ),
      map((items) => items.filter((t) => !rateMappingDisabledChannels.includes(t.channel.name)))
    )
    availableConnections$.pipe(untilDestroy(this)).subscribe((connections) => {
      this.connections = lodash.sortBy(connections, (c) => lodash.toLower(c.channelFriendlyName))
    })

    rentalId$
      .pipe(
        switchMap((rentalId) => this.store.pipe(select(selectRentalRateMappings(rentalId)))),
        untilDestroy(this)
      )
      .subscribe((rateMappings) => {
        this.rateMappings = rateMappings
      })

    rentalId$
      .pipe(
        switchMap((rentalId) => this.rentalRateService.getRateCategories(rentalId)),
        untilDestroy(this)
      )
      .subscribe((cats) => {
        this.categories = cats
      })

    combineLatest([availableConnections$, this.form.get('propertyId').valueChanges])
      .pipe(untilDestroy(this))
      .subscribe(([connections, propertyId]) => {
        const connection = lodash.find(connections, (item) => item.propertyId == propertyId)
        this.selectedConnection = connection
        this.fetchRoomRates(connection)
      })

    if (this.data.rentalId) {
      this.form.patchValue({ rentalId: this.data.rentalId })
      this.form.get('rentalId').disable()
    }
    if (connection) {
      this.form.patchValue({ rentalId: connection.rentalId, propertyId: connection.propertyId })
      this.form.get('rentalId').disable()
      this.form.get('propertyId').disable()
      this.fetchRoomRates(connection)
    }
    if (this.mapping) {
      this.form.patchValue({
        rentalId: this.mapping.rental_id,
        category: this.mapping.category,
        propertyId: this.mapping.propertyId,
        rateid: this.mapping.rateid,
        frequency: this.mapping.frequency,
      })
      this.form.disable()
      this.form.get('frequency').enable()
    }

    rentalId$.pipe(untilDestroy(this)).subscribe(() => {
      this.form.get('propertyId').reset()
      this.form.get('rateid').reset()
      this.form.get('category').reset()
    })
  }

  onChannelSelected() {
    this.form.get('rateid').reset()
  }

  getRoomRateOptions() {
    return this.roomRates[this.selectedChannelId] || []
  }

  private getAvailableRoomRates(connection: ConnectionView, roomRates: ChannelRoomRateResponse[]) {
    if (!connection) return []

    const rateMappings = lodash.filter(this.rateMappings, (rateMapping) => {
      return rateMapping.propertyId == connection.propertyId && rateMapping.roomId == connection.roomId
    })

    const roomNames = {}

    rateMappings.forEach((rateMapping) => {
      roomNames[rateMapping.ratename] = this.mapping?.key === rateMapping.key ? false : true
    })

    roomRates = lodash.filter(roomRates, (roomRate: ChannelRoomRateResponse) => {
      return !roomNames[roomRate.name]
    })

    return roomRates
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    if (form.invalid) return

    const { rentalId, ...data } = form.getRawValue()

    const category = data.category as string
    const connection = this.selectedConnection
    const roomRate = lodash.find(this.roomRates[this.selectedChannelId], (t) => t.id == data.rateid)

    const payload: CreateRateMappingPayload = {
      category: category,
      rateid: data.rateid,
      maxguest: roomRate?.maxguest,
      ratename: roomRate?.name,
      pricemodel: roomRate?.model,
      maxPersons: roomRate?.max_per,
      roomId: connection.roomId as number,
      channel_id: connection.channelId,
      channelName: connection.channel.friendlyName,
      api: connection.channel.name,
      propertyId: data.propertyId,
      frequency: data.frequency,
    }

    if (this.isEdit) {
      this.store.dispatch(updateRateMappings({ items: [{ rentalId, mappingId: this.mapping.key, payload }] }))
    } else {
      this.store.dispatch(addRateMapping({ rentalId, payload }))
    }
    this.close()
  }

  close() {
    this.dialogRef.close()
  }

  fetchRoomRates(connection: ConnectionView) {
    if (!connection) return

    const channel = connection.channel
    const channelId = connection.channelId

    if (this.loadingRoomRates[channelId] || this.roomRates[channelId]) return

    this.loadingRoomRates[channelId] = true

    const payload: GetChannelRoomRatesPayload = {
      channel_id: channelId,
      api: channel.name,
      id: connection.roomId,
      propertyId: connection.propertyId,
    }

    this.connectionService
      .getRoomRatesFromConnection(channel.name, channelId, payload)
      .pipe(finalize(() => (this.loadingRoomRates[channelId] = false)))
      .subscribe(
        (roomRates: ChannelRoomRateResponse[]) => {
          roomRates = this.getAvailableRoomRates(connection, roomRates)
          this.roomRates[channelId] = roomRates
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }
}
