import { ChannelNameTokens, Rental } from '@tokeet-frontend/tv3-platform'

export const rateMappingDisabledChannels = [
  ChannelNameTokens.Holidu,
  ChannelNameTokens.RentalsUnited,
  ChannelNameTokens.GoogleVacationRentals,
]

export type FeedChannelStatus = 'applied' | 'not-applied'

export interface HomeToGoConnectionForm {
  companyName: string
  street: string
  zip: string
  country: string
  countryCode: string
  vat: string
  email: string
  phone: string
  website: string
  rentalCount: string | number
  location: string
  additional: string
}

export interface HomeToGoTax {
  name: string
  type: 'flat' | 'pct'
  modality:
    | 'per_person_stay'
    | 'per_person_night'
    | 'per_stay'
    | 'per_night'
    | 'per_hour'
    | 'per_minute'
    | 'per_week'
    | 'per_unit'
    | 'per_child'
  value: number
  included: string | number
  code: string
  decimals: number
  attributes?: any
}

export interface HomeToGoFee {
  name: string
  type: 'flat' | 'pct'
  modality:
    | 'per_person_stay'
    | 'per_person_night'
    | 'per_stay'
    | 'per_night'
    | 'per_hour'
    | 'per_minute'
    | 'per_week'
    | 'per_unit'
    | 'per_child'
  value: number
  included: string | number
  code: string
  attributes?: any
}

export interface UpdateFeedConfig {
  pkey: string
  status: number
  payment_terms: string
  payment_conditions: string
  cancellation_policy: string
  taxes: HomeToGoTax[]
  fees: HomeToGoFee[]
}

export interface HomeAwayConfigForm {
  cancellation_policy: string
  booking_policy: string
  guest_minimum_age: number
  payment_terms: { [key: string]: { days?: number; percent: number } }
  tc_accepted: number
}

export interface UpdateHomeAwayConfig {
  cancellation_policy: string
  booking_policy: string
  guest_minimum_age: number
  payment_terms: { [key: string]: { days?: number; percent: number } }
  tc_accepted: number
}

export interface FeedConfig {
  status: number
  payment_terms: any
  payment_conditions: string
  cancellation_policy: string
  taxes: HomeToGoTax[]
  fees: HomeToGoFee[]
}

export interface HomeAwayRentalConfig {
  attributes?: {
    availabilitypull?: number
    ratepull?: number
  }
  archived: number
  account: number
  status: number
  date_enabled: number
  created: number
  channel_id: string
  pkey: string
  last_sync: number
  rental_id: string
  channel_url: string
  channel_live: number
}

export interface HomeAwayChannelConfig extends FeedConfig {
  pkey: string
  created: number
  archived: number
  deleted: number
  account: number
  last_sync: number
  date_enabled: number
  tc_accepted: number
  guest_minimum_age: number
  payment_terms: { [type: string]: { percent: number; days?: number } }
}

export interface HomeToGoChannelConfig extends FeedConfig {
  pkey: string
  created: number
  archived: number
  deleted: number
  account: number
  channel_id: string
  channel_account: string
  channel_rooms: string[]
  property_id: string
  last_sync: number
  date_enabled: number
  tc_accepted: number
}

export interface HomeToGoRentalConfig extends FeedConfig {
  pkey: string
  created: number
  archived: number
  deleted: number
  account: number
  channel_id: string
  channel_name: string
  channel_account: string
  rental_id: string
  last_sync: number
  date_enabled: number
  channel_url: string
  channel_rental_id: string
  channel_live: number
}

export interface HomeToGoRentalConfigView extends HomeToGoRentalConfig {
  rental: Rental
  rental_name: string
}

export interface HomeAwayRentalConfigView extends HomeAwayRentalConfig {
  rental: Rental
  rental_name: string
}

export interface AirbnbSyncSettings {
  booking_settings: boolean
  pricing_settings: boolean
  availability_rules: boolean
}

export interface AirbnbChannelConfig {
  ignoreHoldEvents?: boolean
  syncSettings?: AirbnbSyncSettings
}
