import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { InvoiceService } from '@tv3/store/invoice/invoice.service'
import {
  getInvoiceStatus,
  Invoice,
  InvoiceStatus,
  LoadInvoices,
  LoadInvoicesComplete,
  LoadPastDueInvoices,
  LoadPastDueInvoicesComplete,
  SearchElasticInvoices,
  SearchElasticInvoicesComplete,
  selectAllInvoices,
  SendInvoice,
  SendInvoiceComplete,
} from '@tokeet-frontend/invoices'
import { catchError, concatMap, concatMapTo, map, take, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import * as moment from 'moment'
import { selectOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectInquiries } from '@tv3/store/inquiry/inquiry.selectors'
import * as R from 'ramda'
import { FetchInquiriesComplete } from '@tv3/store/inquiry/inquiry.actions'
import { SearchService } from '@tv3/store/search/search.service'
import { PreferenceService } from '@tv3/store/preferences/preference.service'

@Injectable()
export class InvoiceEffects {
  @Effect()
  loadInvoices$ = this.actions$.pipe(
    ofType(LoadInvoices),
    concatMap(({ filters }) =>
      this.invoices.all(filters).pipe(
        map((result) => LoadInvoicesComplete(result)),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  searchElasticInvoices$ = this.actions$.pipe(
    ofType(SearchElasticInvoices),
    concatMap(({ term }) =>
      this.searchService.searchInvoices(term).pipe(
        concatMap((searched) =>
          this.store.pipe(
            select(selectAllInvoices),
            take(1),
            /**
             * invoices not present in the store
             * and in need of fetching to replace
             * the incomplete invoices originating from elastic search
             */
            map((invoices) => R.differenceWith((a, b) => a.id === b.id, searched, invoices))
          )
        ),
        map((invoices) => SearchElasticInvoicesComplete({ invoices })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  loadPastDueInvoices$ = this.actions$.pipe(
    ofType(LoadPastDueInvoices),
    concatMapTo(
      this.invoices.getPastDueInvoices().pipe(
        concatMap((invoices) =>
          this.store.pipe(
            selectOnce(selectInquiries),
            map((inquiries) => {
              /**
               * we check whether we already have some inquiries in the store
               * the ones we don't have we request from the API
               */
              const inquiryIds = R.map(R.prop('id'), inquiries)
              const missingInquiryIds = R.pipe(
                R.reduce((acc: string[], invoice: Invoice) => {
                  if (!R.contains(invoice.inquiryId, inquiryIds)) {
                    return R.append(invoice.inquiryId, acc)
                  }
                  return acc
                }, []),
                R.reject(R.isEmpty),
                R.uniq
              )(invoices)

              return {
                missingInquiryIds,
                invoices,
              }
            })
          )
        ),
        concatMap(({ missingInquiryIds, invoices }) =>
          this.inquiries.get(missingInquiryIds).pipe(map((inquiries) => ({ inquiries, invoices })))
        ),
        concatMap(({ inquiries, invoices }) => [
          LoadPastDueInvoicesComplete({ invoices }),
          FetchInquiriesComplete({ inquiries }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  sendInvoice$ = this.actions$.pipe(
    ofType(SendInvoice),
    concatMap(({ invoice }) => this.inquiries.load(invoice.inquiryId).pipe(map((inquiry) => ({ inquiry, invoice })))),
    concatMap(({ inquiry, invoice }) =>
      this.preferenceService.get().pipe(
        map((preferences) => ({
          inquiry,
          invoice,
          preferences,
        }))
      )
    ),
    concatMap(({ inquiry, invoice, preferences }) =>
      this.invoices.send(invoice, inquiry, preferences).pipe(
        tap(() => this.toaster.success('Invoice sent successfully.')),
        map(() => {
          const status = invoice.status !== InvoiceStatus.Paid ? InvoiceStatus.Unpaid : invoice.status
          return SendInvoiceComplete({
            update: {
              id: invoice.id,
              changes: {
                status,
                invoiceStatus: getInvoiceStatus(status),
                sent: moment().unix(),
              },
            },
          })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private inquiries: InquiryService,
    private invoices: InvoiceService,
    private searchService: SearchService,
    private preferenceService: PreferenceService,
    private store: Store<fromRoot.State>,
    private toaster: Toaster
  ) {}
}
