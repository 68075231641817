import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { LastMinuteAutomationsComponent } from './automations/last-minute-automations.component'
import { LastMinuteAutomationDialogComponent } from './automation-dialog/last-minute-automation-dialog.component'
import { LastMinuteAutomationsOverlayComponent } from './automations-overlay/last-minute-automations-overlay.component'
import { LastMinuteAutomationsGridComponent } from './grid/last-minute-automations-grid.component'
import { RateAutomationsLibraryModule } from '@tokeet-frontend/rate-automations'
import { LastMinuteDecPipe } from './last-minute-dec.pipe'

const modules = [SharedModule, CommonModule, RateAutomationsLibraryModule]

const components = [
  //
  LastMinuteAutomationsComponent,
  LastMinuteAutomationDialogComponent,
  LastMinuteAutomationsOverlayComponent,
  LastMinuteAutomationsGridComponent,
]

const pipes = [LastMinuteDecPipe]

const services = []

@NgModule({
  imports: [...modules],
  declarations: [...components, ...pipes],
  exports: [...components, ...modules, ...pipes],
  providers: [...services, ...pipes],
})
export class LastMinuteAutomationsModule {}
