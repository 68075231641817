import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { SaveForm, selectCurrentAccount, Account, Destroyable, AddressPipe } from '@tokeet-frontend/tv3-platform'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import * as lodash from 'lodash'
import { map } from 'rxjs/operators'

const defaultAccountAddressKey = 'account'

@Component({
  selector: 'app-select-billing-address-dialog',
  templateUrl: './select-billing-address-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./select-billing-address-dialog.component.scss'],
})
export class SelectBillingAddressDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    address: ['', [Validators.required]],
  })

  items: { key: string; address: string; data: any }[] = []

  addressPipe = new AddressPipe()
  constructor(
    public dialogRef: MatDialogRef<SelectBillingAddressDialogComponent>,
    private store: Store<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { key?: string }
  ) {
    super()
  }

  ngOnInit(): void {
    this.form.patchValue({
      address: this.data.key || defaultAccountAddressKey,
    })

    this.store
      .pipe(
        select(selectCurrentAccount),
        map((account) => this.getAddresses(account))
      )
      .subscribe((items) => {
        this.items = items
      })
  }

  getAddresses(account: Account) {
    const items = lodash.map(account.invoiceBillingAddresses, (a) => ({
      key: a.key || '',
      address: this.addressPipe.transform(a),
      data: a,
    }))

    return [
      {
        key: defaultAccountAddressKey,
        address: this.addressPipe.transform(account.address),
        data: account.address,
      },
      ...items,
    ]
  }

  @SaveForm()
  select(form: FormGroup) {
    const { address: key } = this.form.getRawValue()
    const addressData = lodash.find(this.items, (a) => a.key === key)
    this.dialogRef.close(addressData.data)
  }

  close() {
    this.dialogRef.close()
  }
}
