import { Injectable } from '@angular/core'
import { UserRestrictionsDialogComponent } from './user-restrictions-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class UserRestrictionsDialogService {
  constructor(private dialog: MatDialog) {}

  open(rental: Rental, description?: string) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { rental, description },
    }

    return this.dialog.open(UserRestrictionsDialogComponent, defaultConfig)
  }
}
