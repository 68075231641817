import { Component, Input, ViewChild } from '@angular/core'
import { DialogService, Rental } from '@tokeet-frontend/tv3-platform'
import { CopyFeesFromDialogComponent } from '../copy-fees-from-dialog/copy-fees-from-dailog.component'
import { BookingFeesComponent } from '../booking-fees/booking-fees.component'
import { CopyFeesToDialogComponent } from '../copy-fees-to-dialog/copy-fees-to-dialog.component'

@Component({
  selector: 'app-rental-fees',
  templateUrl: './rental-fees.component.html',
  styleUrls: ['./rental-fees.component.scss'],
})
export class RentalFeesComponent {
  @ViewChild('fees') fees: BookingFeesComponent

  @Input() rental: Rental

  constructor(private dialogService: DialogService) {}

  onCopyFrom() {
    this.dialogService
      .openVariableDialog(CopyFeesFromDialogComponent, { data: { rental: this.rental }, width: '900px' })
      .afterClosed()
      .subscribe((result) => {
        if (!!result) {
          this.fees.refresh()
        }
      })
  }

  onCopyTo() {
    this.dialogService
      .openVariableDialog(CopyFeesToDialogComponent, { data: { rental: this.rental }, width: '900px' })
      .afterClosed()
      .subscribe((result) => {
        if (!!result) {
          this.fees.refresh()
        }
      })
  }
}
