import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './reducer'
import * as state from './state'

export const creditChargeState = createFeatureSelector<state.State>('credit-charge')

export const selectAllCreditCharges = createSelector(creditChargeState, reducer.selectAll)

export const selectProductCreditCharges = (product: string) =>
  createSelector(selectAllCreditCharges, (items) => {
    return items?.filter((item) => item.product === product)
  })
