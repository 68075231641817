import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { Toaster, UploadBoxComponent, UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { isFunction, set, isString } from 'lodash'
import { ParsedItem } from '@tv3/interfaces/files/parsed-item.file'
import { parse } from 'papaparse'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import { forkJoin, Observable, of } from 'rxjs'
import { downloadCSV } from '@tv3/utils/functions/download'
import { FileItem } from 'ng2-file-upload'

const Papa = require('papaparse')

@Component({
  selector: 'app-csv-file-parser',
  templateUrl: './csv-file-parser.component.html',
  styleUrls: ['./csv-file-parser.component.scss'],
})
export class CsvFileParserComponent implements OnInit, OnChanges {
  @ViewChild('uploadBox') uploadbox: UploadBoxComponent
  @Input() columnDefs: CsvFileColumnDef[]
  @Input() isHeader: Function
  @Input() guide: CsvFileParserGuide
  @Input() maxUploads: number
  @Output() parsed = new EventEmitter<ParsedUploadFile<any>[]>()

  uploadBoxOptions: UploadBoxOptions = {
    allowedFileExtensions: ['.csv'],
    maxSize: 10 * 1024 * 1024, // 10MB
    autoUpload: false,
    maxUploads: 10,
  }

  get requiredColumns() {
    return R.filter((col) => col.required, this.columnDefs)
  }

  constructor(protected toast: Toaster) {}

  ngOnChanges(): void {
    this.uploadBoxOptions = {
      ...this.uploadBoxOptions,
      maxUploads: this.maxUploads,
    }
  }

  ngOnInit() {
    if (!this.isHeader || !isFunction(this.isHeader)) {
      const columnNames = R.map((i) => i.name, this.columnDefs)
      this.isHeader = (firstRow: any[]) => {
        return R.toLower(columnNames.join()).indexOf(R.toLower(firstRow[0])) > -1
      }
    }
  }

  onAfterAddingAll(fileItems: FileItem[]) {
    forkJoin(
      fileItems.map((fileItem) => {
        return this.parseFile(fileItem)
      })
    ).subscribe(() => {
      this.parsed.emit(this.uploadbox.files)
    })
  }

  getFileErrorText(file: ParsedUploadFile<any>): string {
    if (!file.isError) {
      return ''
    }
    return R.join('\n')(R.map((error) => `• ${error}\n`, file.errors))
  }

  getItemErrorText(item: ParsedItem<any>): string {
    if (!item.isError && !item.isWarning) {
      return ''
    }

    let result = R.join('\n')(R.map((error) => `• ${error}\n`, item.errors))
    if (item.isError && item.isWarning) {
      result += '--------------------\n'
    }

    result += R.join('\n')(R.map((warning) => `• ${warning}\n`, item.warnings))

    return result
  }

  downloadSample() {
    const csvString = Papa.unparse({
      data: JSON.stringify(this.guide.sample),
    })
    const fileName = `sample.csv`
    downloadCSV(csvString, fileName)
  }

  private parseCSVFile(fileItem: ParsedUploadFile<any>): Observable<boolean> {
    return Observable.create((observer) => {
      parse(fileItem._file, {
        skipEmptyLines: true,
        complete: ({ data: csvRows = [] }: any) => {
          while (
            csvRows.length > 0 &&
            csvRows[csvRows.length - 1].length === 1 &&
            csvRows[csvRows.length - 1][0] === ''
          ) {
            csvRows.pop()
          }
          let lastRequiredIndex = 0
          this.columnDefs.forEach((colDef, index) => {
            if (colDef.required) {
              lastRequiredIndex = index
            }
          })

          const firstRow = csvRows[0] || []
          if (!csvRows.length || firstRow.length < lastRequiredIndex + 1) {
            // firstRow.length < this.columnDefs.length
            fileItem.isError = true
            fileItem.errors = ['File does not contain all necessary fields.']
            observer.next(true)
            observer.complete()
            return
          }
          fileItem.isHeader = this.isHeader(firstRow)

          // parse csv
          csvRows.forEach((csvRow: any[], index) => {
            const parsedItem: ParsedItem<any> = {
              originalFields: csvRow,
              errors: [],
              warnings: [],
              item: {},
            }

            fileItem.items.push(parsedItem)

            if (fileItem.isError || (index === 0 && fileItem.isHeader)) {
              return
            }

            // parse columns
            csvRow.forEach((colValue, i) => {
              const colDef = this.columnDefs[i]
              const { value, error, warning } = colDef.parse(colValue, colDef)
              if (error) {
                parsedItem.isError = true
                parsedItem.errors.push(error)
              } else {
                set(parsedItem.item, colDef.field || colDef.name, value)
              }

              if (warning) {
                parsedItem.isWarning = true
                parsedItem.warnings.push(warning)
              }
            })

            // run validators on row data
            this.columnDefs.forEach((colDef: CsvFileColumnDef) => {
              R.forEach((validator) => {
                const res = validator(parsedItem.item)
                if (isString(res)) {
                  parsedItem.isError = true
                  parsedItem.errors.push(res)
                }
              }, colDef.rowValidators || [])
            })
          })

          if (R.find((item) => item.isError, fileItem.items)) {
            fileItem.isError = true
            fileItem.errors.push('File contains errors, please check details in a rows.')
          }

          observer.next(true)
          observer.complete()
        },
      })
    })
  }

  private parseFile(fileItem: ParsedUploadFile<any>): Observable<any> {
    if (fileItem.file.size === 0) {
      fileItem.isError = true
      fileItem.errors = ["Please don't upload empty csv file."]
      return of(false)
    }

    fileItem.isHeader = false
    fileItem.errors = []
    fileItem.items = []

    return this.parseCSVFile(fileItem)
  }
}
