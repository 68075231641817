import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MessagingChannelStoreModule } from './store/channel'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { MessagingSettingsStoreModule } from './store/settings/module'

const modules = [
  //
  Tv3PlatformModule,
  MessagingChannelStoreModule,
  MessagingSettingsStoreModule,
]

@NgModule({
  imports: [CommonModule, ...modules],
  exports: [...modules],
})
export class MessageLibraryModule {}
