<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="price$ | async as price; else strategyPrice"> {{ price }}</ng-container>

  <ng-template #strategyPrice>
    <ng-container *ngIf="strategy === 'flat'">$ {{ amount }} per month</ng-container>
    <ng-container *ngIf="strategy === 'per-rental'">$ {{ amount }} per rental per month </ng-container>
    <ng-container *ngIf="strategy === 'rental-brackets'">$ {{ amount }} per month</ng-container>
    <ng-container *ngIf="strategy === 'per-job'">Variable price per job. No upfront costs.</ng-container>
    <ng-container *ngIf="strategy === 'per-website'">Variable price per website. No upfront costs.</ng-container>
    <ng-container *ngIf="strategy === 'per-document'">Variable price per document. No upfront costs.</ng-container>
    <ng-container *ngIf="strategy === 'percent'">Variable percentage of sales</ng-container>
    <ng-container *ngIf="strategy === 'per-owner'">$19.99 first owner, $9.99 for each next owner</ng-container>
  </ng-template>
</ng-container>

<i class="far fa-spin fa-spinner text-xl text-info" *ngIf="isLoading"></i>
