<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-12">
        <h6 class="modal-title">Comments</h6>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="container-box p-3">
        <mat-form-field2>
          <mat-label>Add Comments</mat-label>
          <textarea
            matInput
            [formControl]="form.get('note')"
            rows="3"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="5"
            placeholder="Enter your comments here.."
            required
            [maxlength]="maxLength"
            #noteInput
          ></textarea>
          <mat-hint align="end">{{ noteInput.value.length }} / {{ maxLength }}</mat-hint>
        </mat-form-field2>
        <div class="text-right mb-3">
          <button class="btn btn-secondary-info" (click)="save(form)" [disabled]="isSavingNote$ | async">
            <i class="far fa-spin fa-spinner" *ngIf="isSavingNote$ | async; else icon"></i>
            <ng-template #icon>
              <i class="cs-icon cs-icon-save"></i>
            </ng-template>
            Save
          </button>
        </div>
        <app-history-list
          *ngIf="!!notes?.length; else empty"
          [list]="notes || []"
          (edit)="onEdit($event)"
          (remove)="onRemove($event)"
          [contentKey]="'note'"
          [userNameKey]="'user'"
          [timeKey]="'timestamp'"
        ></app-history-list>

        <ng-template #empty>
          <app-empty-table type="InquiryNotesList"></app-empty-table>
        </ng-template>
      </div>
    </div>
  </div>
</div>
