import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { channelPropertySettingReducer } from './channel-property-setting.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ChannelPropertySettingEffects } from './channel-property-setting.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('channel-property-setting', channelPropertySettingReducer),
    EffectsModule.forFeature([ChannelPropertySettingEffects]),
  ],
  declarations: [],
})
export class ChannelPropertySettingModule {}
