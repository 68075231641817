import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'

import { SmartDevicesLibraryModule } from '@tokeet-frontend/smart-devices'

import { NewAccesscodeDialogComponent } from './new-accesscode-dialog/new-accesscode-dialog.component'
import { BookingAccessCodeComponent } from './booking-access-code/booking-access-code.component'
import { AutomationsLibraryModule } from '@tokeet-frontend/automations'
import { ConnectDeviceBtnComponent } from './connect-device-btn/connect-device-btn.component'
import { SignatureModule } from '@tokeet-frontend/signature'
import { SmartDevicesSubscribeService } from './smart-devices-subscribe.service'
import { SmartDevicesAccesscodeHistoryComponent } from '@tv3/containers/smart-devices/accesscode-dialog/history/smart-devices-accesscode-history.component'

const components = [
  //
  SmartDevicesAccesscodeHistoryComponent,
  NewAccesscodeDialogComponent,
  BookingAccessCodeComponent,
  ConnectDeviceBtnComponent,
]

const modules = [
  //
  SharedModule,
  SmartDevicesLibraryModule,
  AutomationsLibraryModule,
  SignatureModule,
]

@NgModule({
  declarations: [...components],
  imports: [CommonModule, ...modules],
  exports: [
    //
    ...components,
    ...modules,
  ],
  providers: [SmartDevicesSubscribeService],
})
export class SmartDevicesSharedModule {}
