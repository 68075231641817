import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import {
  RentalRatesDialogComponent,
  RentalRatesDialogInput,
  RentalRatesDialogResult,
} from './rental-rates-dialog.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class RentalRatesDialogService {
  constructor(private dialogService: DialogService) {}

  public openSide(data: RentalRatesDialogInput): MatDialogRef<RentalRatesDialogComponent, RentalRatesDialogResult> {
    return this.dialogService.openDrawer(RentalRatesDialogComponent, {
      panelClass: ['drawer-full-90'],
      data,
    })
  }
}
