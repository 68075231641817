import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { LoadPaymentGatewayLog, LoadPaymentGatewayLogComplete } from './gateway-log.actions'
import { GatewaysService } from '../gateways/gateways.service'

@Injectable()
export class GatewayLogEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadPaymentGatewayLog),
    switchMap(({ id }) =>
      this.gatewaysService.getLog(id).pipe(
        map((log) =>
          LoadPaymentGatewayLogComplete({
            id,
            log,
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<any>, private gatewaysService: GatewaysService) {}
}
