export const rentalDetails: { id: string; name: string }[] = [
  { id: '24hr-checkin', name: '24 Hour Check-in' },
  { id: 'activities_older_children', name: 'Activities Older Children' },
  { id: 'activities_young_children', name: 'Activities Young Children' },
  { id: 'adjoining_rooms', name: 'Adjoining Rooms' },
  { id: 'all_inclusive', name: 'All Inclusive' },
  { id: 'bathtub', name: 'Bathtub' },
  { id: 'bbq', name: 'BBQ/Picnic area' },
  { id: 'beach', name: 'Beach' },
  { id: 'beach_view', name: 'Beach View' },
  { id: 'beachfront', name: 'Beach Front' },
  { id: 'bed_and_breakfast', name: 'Bed And Breakfast' },
  { id: 'bicycle', name: 'Bicycle' },
  { id: 'breakfast', name: 'Breakfast' },
  { id: 'business_services', name: 'Business Services' },
  { id: 'cable', name: 'Cable Television' },
  { id: 'car_rental', name: 'Car Rental' },
  { id: 'central_air_conditioning', name: 'Air Conditioning' },
  { id: 'concierge', name: 'Concierge' },
  { id: 'credit_cards_accepted', name: 'Credit Cards Accepted' },
  { id: 'desk', name: 'Desk' },
  { id: 'dishwasher', name: 'Dishwasher' },
  { id: 'doorman', name: 'Doorman' },
  { id: 'dry_cleaning', name: 'Dry Cleaning' },
  { id: 'dryer', name: 'Dryer' },
  { id: 'early_arrival', name: 'Early Arrival' },
  { id: 'elder_access', name: 'Elder Access' },
  { id: 'fireplace', name: 'Indoor Fireplace' },
  { id: 'fitness_center', name: 'Fitness Center' },
  { id: 'free_breakfast', name: 'Free Breakfast' },
  { id: 'free_cancelation', name: 'Free Cancelation' },
  { id: 'free_housekeeping', name: 'Free Housekeeping' },
  { id: 'free_internet', name: 'Free Internet' },
  { id: 'free_local_calls', name: 'Free Local Calls' },
  { id: 'free_parking', name: 'Free Parking' },
  { id: 'free_wifi', name: 'Free Wifi' },
  { id: 'freezer', name: 'Freezer' },
  { id: 'game_room', name: 'Game Room' },
  { id: 'games', name: 'Games' },
  { id: 'garden', name: 'Garden' },
  { id: 'gym', name: 'Gym' },
  { id: 'hair-dryer', name: 'Hair Dryer' },
  { id: 'hangers', name: 'Hangers' },
  { id: 'heating', name: 'Heating' },
  { id: 'hot_tub', name: 'Hot Tub' },
  { id: 'housekeeping', name: 'Housekeeping' },
  { id: 'indoor_pool', name: 'Indoor pool' },
  { id: 'inperson_checkin', name: 'In-Person check-in' },
  { id: 'iron', name: 'Clothes Iron' },
  { id: 'iron_board', name: 'Ironing Board' },
  { id: 'kids_activities', name: 'Kids Activities' },
  { id: 'late_arrival', name: 'Late Arrival' },
  { id: 'late_check_out', name: 'Late Check Out' },
  { id: 'lockers_storage', name: 'Lockers Storage' },
  { id: 'loyalty_rewards_available', name: 'Loyalty Rewards Available' },
  { id: 'massage', name: 'Massage' },
  { id: 'meals_included', name: 'Meals Included' },
  { id: 'meeting_room', name: 'Meeting Room' },
  { id: 'non_smoking', name: 'Non Smoking' },
  { id: 'paid_parking', name: 'Paid Parking' },
  { id: 'parking_available', name: 'Parking Available' },
  { id: 'patio_furniture', name: 'Outdoor furniture' },
  { id: 'pets_allowed', name: 'Pets Allowed' },
  { id: 'private_beach', name: 'Private beach area' },
  { id: 'room_service', name: 'Room Service' },
  { id: 'room_with_a_view', name: 'Room With A View' },
  { id: 'shampoo', name: 'Shampoo' },
  { id: 'shared_dryer', name: 'Shared Dryer' },
  { id: 'shared_washers', name: 'Shared Washers' },
  { id: 'shuttle', name: 'Shuttle' },
  { id: 'smart_tv', name: 'Smart TV' },
  { id: 'smartlock', name: 'Smartlock' },
  { id: 'stairs_elevator', name: 'Stairs Elevator' },
  { id: 'stroller_parking', name: 'Stroller Parking' },
  { id: 'swimming_pool', name: 'Swimming Pool' },
  { id: 'television', name: 'Television' },
  { id: 'tennis_court', name: 'Tennis Court' },
  { id: 'terrace', name: 'Terrace' },
  { id: 'valet_parking', name: 'Valet Parking' },
  { id: 'washer', name: 'Washer' },
  { id: 'water_sports', name: 'Water Sports' },
  { id: 'water_view', name: 'Water View' },
  { id: 'waterfront', name: 'Waterfront' },
  { id: 'wheelchair_access', name: 'Wheelchair Access' },
  { id: 'fridge', name: 'Fridge' },
  { id: 'bath', name: 'Bath' },
  { id: 'shower', name: 'Shower' },
  { id: 'washing_machine', name: 'Washing Machine' },
  { id: 'coffee_maker', name: 'Coffee Maker' },
  { id: 'baby_cot', name: 'Baby Cot' },
  { id: 'high_chair', name: 'High Chair' },
  { id: 'smoke_alarms', name: 'Smoke Alarms' },
  { id: 'dedicated_workspace', name: 'Dedicated Workspace' },
  { id: 'microwave', name: 'Microwave' },
  { id: 'outdoor_dining_area', name: 'Outdoor Dining Area' },
  { id: 'lift', name: 'Lift' },
  { id: 'downtown', name: 'Downtown' },
  { id: 'golf_course_front', name: 'Golf Course Front' },
  { id: 'golf_course_view', name: 'Golf Course View' },
  { id: 'lake', name: 'Lake' },
  { id: 'lake_front', name: 'Lake Front' },
  { id: 'lake_view', name: 'Lake View' },
  { id: 'near_ocean', name: 'Near Ocean' },
  { id: 'ocean_front', name: 'Ocean Front' },
  { id: 'ocean_view', name: 'Ocean View' },
  { id: 'resort', name: 'Resort' },
  { id: 'river', name: 'River' },
  { id: 'rural', name: 'Rural' },
  { id: 'ski_in', name: 'Ski In' },
  { id: 'ski_in_out', name: 'Ski In Out' },
  { id: 'ski_out', name: 'Ski Out' },
  { id: 'town', name: 'Town' },
  { id: 'village', name: 'Village' },
  { id: 'mountain', name: 'Mountain' },
  { id: 'mountain_view', name: 'Mountain view' },
].sort((a, b) => a.name.localeCompare(b.name))

export const rentalLocales: { id: string; name: string }[] = [
  { id: 'aa', name: 'Afar' },
  { id: 'ab', name: 'Abkhazian' },
  { id: 'ae', name: 'Avestan' },
  { id: 'af', name: 'Afrikaans' },
  { id: 'ak', name: 'Akan' },
  { id: 'am', name: 'Amharic' },
  { id: 'an', name: 'Aragonese' },
  { id: 'ar', name: 'Arabic' },
  { id: 'as', name: 'Assamese' },
  { id: 'av', name: 'Avaric' },
  { id: 'ay', name: 'Aymara' },
  { id: 'az', name: 'Azerbaijani' },
  { id: 'ba', name: 'Bashkir' },
  { id: 'be', name: 'Belarusian' },
  { id: 'bg', name: 'Bulgarian' },
  { id: 'bh', name: 'Bihari languages' },
  { id: 'bi', name: 'Bislama' },
  { id: 'bm', name: 'Bambara' },
  { id: 'bn', name: 'Bengali' },
  { id: 'bo', name: 'Tibetan' },
  { id: 'br', name: 'Breton' },
  { id: 'bs', name: 'Bosnian' },
  { id: 'ca', name: 'Catalan; Valencian' },
  { id: 'ce', name: 'Chechen' },
  { id: 'ch', name: 'Chamorro' },
  { id: 'co', name: 'Corsican' },
  { id: 'cr', name: 'Cree' },
  { id: 'cs', name: 'Czech' },
  { id: 'cu', name: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic' },
  { id: 'cv', name: 'Chuvash' },
  { id: 'cy', name: 'Welsh' },
  { id: 'da', name: 'Danish' },
  { id: 'de', name: 'German' },
  { id: 'dv', name: 'Divehi; Dhivehi; Maldivian' },
  { id: 'dz', name: 'Dzongkha' },
  { id: 'ee', name: 'Ewe' },
  { id: 'el', name: 'Greek, Modern (1453-)' },
  { id: 'en', name: 'English' },
  { id: 'eo', name: 'Esperanto' },
  { id: 'es', name: 'Spanish; Castilian' },
  { id: 'et', name: 'Estonian' },
  { id: 'eu', name: 'Basque' },
  { id: 'fa', name: 'Persian' },
  { id: 'ff', name: 'Fulah' },
  { id: 'fi', name: 'Finnish' },
  { id: 'fj', name: 'Fijian' },
  { id: 'fo', name: 'Faroese' },
  { id: 'fr', name: 'French' },
  { id: 'fy', name: 'Western Frisian' },
  { id: 'ga', name: 'Irish' },
  { id: 'gd', name: 'Gaelic; Scottish Gaelic' },
  { id: 'gl', name: 'Galician' },
  { id: 'gn', name: 'Guarani' },
  { id: 'gu', name: 'Gujarati' },
  { id: 'gv', name: 'Manx' },
  { id: 'ha', name: 'Hausa' },
  { id: 'he', name: 'Hebrew' },
  { id: 'hi', name: 'Hindi' },
  { id: 'ho', name: 'Hiri Motu' },
  { id: 'hr', name: 'Croatian' },
  { id: 'ht', name: 'Haitian; Haitian Creole' },
  { id: 'hu', name: 'Hungarian' },
  { id: 'hy', name: 'Armenian' },
  { id: 'hz', name: 'Herero' },
  { id: 'ia', name: 'Interlingua (International Auxiliary Language Association)' },
  { id: 'id', name: 'Indonesian' },
  { id: 'ie', name: 'Interlingue; Occidental' },
  { id: 'ig', name: 'Igbo' },
  { id: 'ii', name: 'Sichuan Yi; Nuosu' },
  { id: 'ik', name: 'Inupiaq' },
  { id: 'io', name: 'Ido' },
  { id: 'is', name: 'Icelandic' },
  { id: 'it', name: 'Italian' },
  { id: 'iu', name: 'Inuktitut' },
  { id: 'ja', name: 'Japanese' },
  { id: 'jv', name: 'Javanese' },
  { id: 'ka', name: 'Georgian' },
  { id: 'kg', name: 'Kongo' },
  { id: 'ki', name: 'Kikuyu; Gikuyu' },
  { id: 'kj', name: 'Kuanyama; Kwanyama' },
  { id: 'kk', name: 'Kazakh' },
  { id: 'kl', name: 'Kalaallisut; Greenlandic' },
  { id: 'km', name: 'Central Khmer' },
  { id: 'kn', name: 'Kannada' },
  { id: 'ko', name: 'Korean' },
  { id: 'kr', name: 'Kanuri' },
  { id: 'ks', name: 'Kashmiri' },
  { id: 'ku', name: 'Kurdish' },
  { id: 'kv', name: 'Komi' },
  { id: 'kw', name: 'Cornish' },
  { id: 'ky', name: 'Kirghiz; Kyrgyz' },
  { id: 'la', name: 'Latin' },
  { id: 'lb', name: 'Luxembourgish; Letzeburgesch' },
  { id: 'lg', name: 'Ganda' },
  { id: 'li', name: 'Limburgan; Limburger; Limburgish' },
  { id: 'ln', name: 'Lingala' },
  { id: 'lo', name: 'Lao' },
  { id: 'lt', name: 'Lithuanian' },
  { id: 'lu', name: 'Luba-Katanga' },
  { id: 'lv', name: 'Latvian' },
  { id: 'mg', name: 'Malagasy' },
  { id: 'mh', name: 'Marshallese' },
  { id: 'mi', name: 'Maori' },
  { id: 'mk', name: 'Macedonian' },
  { id: 'ml', name: 'Malayalam' },
  { id: 'mn', name: 'Mongolian' },
  { id: 'mr', name: 'Marathi' },
  { id: 'ms', name: 'Malay' },
  { id: 'mt', name: 'Maltese' },
  { id: 'my', name: 'Burmese' },
  { id: 'na', name: 'Nauru' },
  { id: 'nb', name: 'Bokmål, Norwegian; Norwegian Bokmål' },
  { id: 'nd', name: 'Ndebele, North; North Ndebele' },
  { id: 'ne', name: 'Nepali' },
  { id: 'ng', name: 'Ndonga' },
  { id: 'nl', name: 'Dutch; Flemish' },
  { id: 'nn', name: 'Norwegian Nynorsk; Nynorsk, Norwegian' },
  { id: 'no', name: 'Norwegian' },
  { id: 'nr', name: 'Ndebele, South; South Ndebele' },
  { id: 'nv', name: 'Navajo; Navaho' },
  { id: 'ny', name: 'Chichewa; Chewa; Nyanja' },
  { id: 'oc', name: 'Occitan (post 1500); Provençal' },
  { id: 'oj', name: 'Ojibwa' },
  { id: 'om', name: 'Oromo' },
  { id: 'or', name: 'Oriya' },
  { id: 'os', name: 'Ossetian; Ossetic' },
  { id: 'pa', name: 'Panjabi; Punjabi' },
  { id: 'pi', name: 'Pali' },
  { id: 'pl', name: 'Polish' },
  { id: 'ps', name: 'Pushto; Pashto' },
  { id: 'pt', name: 'Portuguese' },
  { id: 'qu', name: 'Quechua' },
  { id: 'rm', name: 'Romansh' },
  { id: 'rn', name: 'Rundi' },
  { id: 'ro', name: 'Romanian; Moldavian; Moldovan' },
  { id: 'ru', name: 'Russian' },
  { id: 'rw', name: 'Kinyarwanda' },
  { id: 'sa', name: 'Sanskrit' },
  { id: 'sc', name: 'Sardinian' },
  { id: 'sd', name: 'Sindhi' },
  { id: 'se', name: 'Northern Sami' },
  { id: 'sg', name: 'Sango' },
  { id: 'si', name: 'Sinhala; Sinhalese' },
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  { id: 'sm', name: 'Samoan' },
  { id: 'sn', name: 'Shona' },
  { id: 'so', name: 'Somali' },
  { id: 'sq', name: 'Albanian' },
  { id: 'sr', name: 'Serbian' },
  { id: 'ss', name: 'Swati' },
  { id: 'st', name: 'Sotho, Southern' },
  { id: 'su', name: 'Sundanese' },
  { id: 'sv', name: 'Swedish' },
  { id: 'sw', name: 'Swahili' },
  { id: 'ta', name: 'Tamil' },
  { id: 'te', name: 'Telugu' },
  { id: 'tg', name: 'Tajik' },
  { id: 'th', name: 'Thai' },
  { id: 'ti', name: 'Tigrinya' },
  { id: 'tk', name: 'Turkmen' },
  { id: 'tl', name: 'Tagalog' },
  { id: 'tn', name: 'Tswana' },
  { id: 'to', name: 'Tonga (Tonga Islands)' },
  { id: 'tr', name: 'Turkish' },
  { id: 'ts', name: 'Tsonga' },
  { id: 'tt', name: 'Tatar' },
  { id: 'tw', name: 'Twi' },
  { id: 'ty', name: 'Tahitian' },
  { id: 'ug', name: 'Uighur; Uyghur' },
  { id: 'uk', name: 'Ukrainian' },
  { id: 'ur', name: 'Urdu' },
  { id: 'uz', name: 'Uzbek' },
  { id: 've', name: 'Venda' },
  { id: 'vi', name: 'Vietnamese' },
  { id: 'vo', name: 'Volapük' },
  { id: 'wa', name: 'Walloon' },
  { id: 'wo', name: 'Wolof' },
  { id: 'xh', name: 'Xhosa' },
  { id: 'yi', name: 'Yiddish' },
  { id: 'yo', name: 'Yoruba' },
  { id: 'za', name: 'Zhuang; Chuang' },
  { id: 'zh', name: 'Chinese' },
  { id: 'zu', name: 'Zulu' },
]
