import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { AccountRateAutomationResponse } from './model'

export interface State extends EntityState<AccountRateAutomationResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<AccountRateAutomationResponse> = createEntityAdapter<AccountRateAutomationResponse>(
  {
    selectId: (item) => item.pkey,
    sortComparer: false,
  }
)

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
