import { Component, Input, OnInit } from '@angular/core'
import { Channel, Destroyable, Rental, SaveForm, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '@tv3/services/auth.service'
import * as lodash from 'lodash'
import {
  AgodaContentProductCancelPolicy,
  AgodaContentProductRateSetting,
  AgodaContentProductRoomSetting,
  AgodaContentProductSetting,
  AgodaContentProperty,
  AgodaContentRatePlan,
  AgodaContentRoom,
} from '@tv3/store/channel-content/models/agoda.model'
import { AgodaContentService } from '@tv3/store/channel-content/agoda-content.service'
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, merge } from 'rxjs'
import { AgodaContentProductPayload } from '@tv3/store/channel-content/payload/agoda.request'

@Component({
  selector: 'app-agoda-content-product-setup',
  templateUrl: './agoda-content-product-setup.component.html',
  styleUrls: ['./agoda-content-product-setup.component.scss'],
})
export class AgodaContentProductSetupComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() agodaProperty: AgodaContentProperty

  get agodaRoom(): AgodaContentRoom {
    return this.agodaProperty && lodash.get(this.agodaProperty.rooms, 0)
  }

  get agodaRate(): AgodaContentRatePlan {
    return this.agodaProperty && lodash.get(this.agodaProperty.rates, 0)
  }

  product: { ratePlan: AgodaContentProductRateSetting; room: AgodaContentProductRoomSetting; [key: string]: any }

  form = this.fb.group({
    cancelPolicyCode: [undefined, [Validators.required]],
    minAdvancedBookingOffset: [undefined, [Validators.required]],
    maxAdvancedBookingOffset: [undefined, [Validators.required]],
    sellDateRangeStart: [undefined, [Validators.required]],
    sellDateRangeEnd: [undefined, [Validators.required]],
    stayDateRangeStart: [undefined, [Validators.required]],
    stayDateRangeEnd: [undefined, [Validators.required]],
    maxLengthOfStay: [undefined, [Validators.required]],
    minLengthOfStay: [undefined, [Validators.required]],
  })

  minDateDefault = moment().add(1, 'days').startOf('day').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  sellMinEndDate = new BehaviorSubject<Date>(this.minDateDefault)
  sellMaxStartDate = new BehaviorSubject<Date>(this.maxDateDefault)

  stayMinEndDate = new BehaviorSubject<Date>(this.minDateDefault)
  stayMaxStartDate = new BehaviorSubject<Date>(this.maxDateDefault)

  cancelPolicies: AgodaContentProductCancelPolicy[] = []

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toaster: Toaster,
    private agodaContentService: AgodaContentService
  ) {
    super()
  }

  ngOnInit() {
    merge(this.form.get('sellDateRangeStart').valueChanges, this.form.get('sellDateRangeEnd').valueChanges)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.setSellDateRangeMinMax()
      })

    merge(this.form.get('stayDateRangeStart').valueChanges, this.form.get('stayDateRangeEnd').valueChanges)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.setStayDateRangeMinMax()
      })

    combineLatest([
      this.agodaContentService.getCancelPolicies(),
      this.agodaContentService.getProducts(this.agodaProperty.propertyId),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([cancelPolicies, productsData]) => {
        this.cancelPolicies = cancelPolicies

        if (!productsData) {
          return
        }
        const { rateplans, rooms } = productsData
        const products = lodash.map(productsData.products, (product: AgodaContentProductSetting) => {
          // tslint:disable-next-line:triple-equals
          const rate = lodash.find(rateplans, (r) => r.rateplan_id == product.rateplan_id)
          // tslint:disable-next-line:triple-equals
          const room = lodash.find(rooms, (r) => r.room_id == product.room_id)
          return {
            ...lodash.omit(productsData, ['products', 'rateplans', 'rooms']),
            ratePlan: rate,
            room,
          }
        })
        this.product = lodash.head(products)
        this.initForm()
      })
  }

  setSellDateRangeMinMax() {
    if (this.form.get('sellDateRangeStart').value) {
      const start = moment(this.form.get('sellDateRangeStart').value).add(1, 'days').startOf('day')
      this.sellMinEndDate.next(start.toDate())
    }

    if (this.form.get('sellDateRangeEnd').value) {
      const end = moment(this.form.get('sellDateRangeEnd').value).subtract(1, 'days').endOf('day')
      this.sellMaxStartDate.next(end.toDate())
    }
  }

  setStayDateRangeMinMax() {
    if (this.form.get('stayDateRangeStart').value) {
      const start = moment(this.form.get('stayDateRangeStart').value).add(1, 'days').startOf('day')
      this.stayMinEndDate.next(start.toDate())
    }

    if (this.form.get('stayDateRangeEnd').value) {
      const end = moment(this.form.get('stayDateRangeEnd').value).subtract(1, 'days').endOf('day')
      this.stayMaxStartDate.next(end.toDate())
    }
  }

  initForm() {
    if (!this.product) {
      return
    }

    const toDate = (dateString: string) => {
      let date
      if (dateString) {
        date = moment(dateString)
      }
      return date && date.isValid() ? date.toDate() : undefined
    }
    const { ratePlan } = this.product
    const cancelPolicy: AgodaContentProductCancelPolicy = lodash.find(this.cancelPolicies, {
      agodaCode: ratePlan.cxl_code,
    })

    this.form.patchValue({
      cancelPolicyCode: cancelPolicy && cancelPolicy.code,
      minAdvancedBookingOffset: ratePlan.min_adv_days,
      maxAdvancedBookingOffset: ratePlan.max_adv_days,
      sellDateRangeStart: toDate(ratePlan.sell_start),
      sellDateRangeEnd: toDate(ratePlan.sell_end),
      stayDateRangeStart: toDate(ratePlan.stay_start),
      stayDateRangeEnd: toDate(ratePlan.stay_end),
      minLengthOfStay: ratePlan.min_los,
      maxLengthOfStay: ratePlan.max_los,
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.form.getRawValue()
    const payload = {
      propertyId: this.agodaProperty.propertyId,
      roomId: this.agodaRoom.roomId,
      rateId: this.agodaRate.ratePlanId,
      cancelPolicyCode: formData.cancelPolicyCode,
      minAdvancedBookingOffset: `P${formData.minAdvancedBookingOffset || 0}D`, // P3D
      maxAdvancedBookingOffset: `P${formData.maxAdvancedBookingOffset || 0}D`,
      sellDateRangeEnd: moment(formData.sellDateRangeEnd).format('YYYY-MM-DD'),
      sellDateRangeStart: moment(formData.sellDateRangeStart).format('YYYY-MM-DD'),
      stayDateRangeEnd: moment(formData.stayDateRangeEnd).format('YYYY-MM-DD'),
      stayDateRangeStart: moment(formData.stayDateRangeStart).format('YYYY-MM-DD'),
      maxLengthOfStay: `P${formData.maxLengthOfStay || 0}D`,
      minLengthOfStay: `P${formData.minLengthOfStay || 0}D`,
    } as AgodaContentProductPayload

    this.agodaContentService.setProduct(payload).subscribe(({ warnings }) => {
      this.toaster.success('Product saved successfully.')
      if (warnings && warnings.length) {
        this.toaster.warning('Product Warnings: ' + warnings.join('\n'))
      }
    })
  }
}
