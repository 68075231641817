import { DeleteInquiryComplete } from '@tv3/store/inquiry/inquiry.actions'
import { ManualRefreshImportedCalendarConnectionComplete } from '@tv3/store/connection/connection.actions'
import { adapter, initialState, State } from './calendar.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  AddHoldEvent,
  AddHoldEventComplete,
  ClearHoldEvents,
  DeleteHoldEvent,
  DeleteHoldEventComplete,
  DeleteHoldEvents,
  DeleteHoldEventsComplete,
  DeleteCalendarEventsComplete,
  LoadCalendarEventsByRentalComplete,
  LoadCalendarRange,
  LoadCalendarRangeComplete,
  LoadHoldEventsComplete,
  SetInquiryNotesReadStatus,
  UpdateHoldEvent,
  UpdateHoldEventComplete,
} from '@tv3/store/calendar/calendar.actions'
import { CancelBookingComplete, SetInquiryColorComplete } from '@tv3/store/inquiry/inquiry-fields.actions'
import { ActionFailed, ActionSkipped, updateMany, updateOne, upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadCalendarEventsByRentalComplete, (state, { events }) => upsertMany(adapter, events, state)),
  on(LoadCalendarRange, (state) => ({ ...state, isLoading: true })),
  on(LoadCalendarRangeComplete, (state, { events, reset }) =>
    (reset ? adapter.setAll : adapter.upsertMany)(events, {
      ...state,
      isLoading: false,
      isLoaded: true,
    })
  ),
  on(SetInquiryNotesReadStatus, (state, { id, status }) =>
    adapter.updateOne({ id, changes: { notesUpdated: status } }, state)
  ),
  on(DeleteCalendarEventsComplete, (state, { ids }) => adapter.removeMany(ids, state)),
  on(AddHoldEventComplete, (state, { events }) => adapter.addMany(events, { ...state, isUpdating: false })),
  on(UpdateHoldEventComplete, (state, { updates }) => updateMany(adapter, updates, { ...state, isUpdating: false })),
  on(DeleteHoldEventComplete, (state, { id }) => adapter.removeOne(id, { ...state, isUpdating: false })),
  on(DeleteHoldEventsComplete, (state, { ids }) =>
    adapter.removeMany(ids, {
      ...state,
      isUpdating: false,
    })
  ),
  on(LoadHoldEventsComplete, (state, { events }) =>
    upsertMany(adapter, events, {
      ...state,
      isHoldLoaded: true,
    })
  ),
  on(ClearHoldEvents, (state, { ids }) => adapter.removeMany(ids, { ...state, isHoldLoaded: false })),
  on(AddHoldEvent, DeleteHoldEvent, DeleteHoldEvents, UpdateHoldEvent, (state) => ({ ...state, isUpdating: true })),

  on(DeleteInquiryComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(CancelBookingComplete, (state, { update }) => adapter.removeOne(update.id as string, state)),
  on(SetInquiryColorComplete, (state, { update }) => {
    const inquiry = update.changes
    const event = state.entities[inquiry.id || inquiry.bookingId]

    return updateOne(
      adapter,
      {
        id: adapter.selectId(event) as string,
        changes: {
          color: update.changes.attributes.color,
        },
      },
      state
    )
  }),

  on(ManualRefreshImportedCalendarConnectionComplete, (state) => {
    // TV3-2153: when refresh imported calendars,
    // exitsing duplicated iCal events are deleted and new events are created on back-end, causing event's "pkey" changed
    // hence we will get duplicate iCal events on the next time the calendar re-fetches events.
    // so remove all events here in CalendarReducer so we can get rid of incorrect overlaps the issue on calendars.
    return adapter.removeAll(state)
  }),

  on(ActionFailed, ActionSkipped, (state) => ({ ...state, isUpdating: false }))
)

export function calendarReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
