import { Component, Input, OnInit } from '@angular/core'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { AccessCodeLogView, SmartDeviceAccessService } from '@tokeet-frontend/smart-devices'
import { Store } from '@ngrx/store'
import { finalize, map } from 'rxjs/operators'
import { forkJoin } from 'rxjs'
import * as lodash from 'lodash'
import { GuestService } from '@tv3/store/guest/guest.service'

@Component({
  selector: 'app-smart-devices-accesscode-history',
  templateUrl: './smart-devices-accesscode-history.component.html',
  styleUrls: ['./smart-devices-accesscode-history.component.scss'],
})
export class SmartDevicesAccesscodeHistoryComponent extends Destroyable implements OnInit {
  @Input() accessCodeId: string
  @Input() deviceId: string

  isLoading = false
  activities: AccessCodeLogView[] = []

  constructor(
    private store: Store<any>,
    private deviceAccessService: SmartDeviceAccessService,
    private guestService: GuestService
  ) {
    super()
  }

  ngOnInit() {
    this.isLoading = true

    forkJoin({
      accessLogs: this.deviceAccessService.getAccessCodeLog(this.accessCodeId, this.deviceId),
      guests: this.guestService.getGuests(),
    })
      .pipe(
        map(({ accessLogs, guests }) => {
          const guestIds = lodash.keyBy(guests, (guest) => guest.id)

          return accessLogs.map((log) => ({
            ...log,
            guestName: guestIds[log.guestId]?.name || null,
          }))
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((augmentedLogs) => {
        this.activities = augmentedLogs
      })
  }
}
