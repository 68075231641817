import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromWebsiteImage from './website-image.reducer'
import { EffectsModule } from '@ngrx/effects'
import { WebsiteImageEffects } from './website-image.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('website-image', fromWebsiteImage.websiteImageReducer),
    EffectsModule.forFeature([WebsiteImageEffects]),
  ],
  declarations: [],
})
export class WebsiteImageModule {}
