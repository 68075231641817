import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { Channel } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-connect-agoda-wizard',
  templateUrl: './connect-agoda-wizard.component.html',
  styleUrls: ['./connect-agoda-wizard.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class ConnectAgodaWizardComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper

  get channel() {
    return this.data.channel
  }

  constructor(
    public dialogRef: MatDialogRef<ConnectAgodaWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel }
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  onChannel() {
    this.dialogRef.close('channel')
  }
}
