import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromApiKey from './api-keys.reducer'
import { ApiKeyEffects } from './api-keys.effects'
import { ApiKeysGuard } from './api-keys.guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('apiKey', fromApiKey.apiKeysReducer),
    EffectsModule.forFeature([ApiKeyEffects]),
  ],
  providers: [ApiKeysGuard],
  declarations: [],
})
export class ApiKeyStoreModule {}
