import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './custom-code.reducer'
import * as state from './custom-code.state'
import { CustomCode } from './custom-code.model'
import * as R from 'ramda'
import {
  isSomething,
  selectAllRentals,
  selectRentalEntities,
  selectUsers,
  uniqueProperties,
} from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { User } from '@tokeet-frontend/tv3-platform'

export const customCodeState = createFeatureSelector<state.State>('custom-codes')

export const selectAllCustomCodes = createSelector(customCodeState, reducer.selectAll)

export const isCustomCodeLoaded = createSelector(customCodeState, (state) => state.isLoaded)

export const selectUniqueCodeNames = createSelector(selectAllCustomCodes, (codes) => {
  return uniqueProperties('nameFormatted')(codes)
})

export const selectAllCustomCodesWithCreatorAndRentals = createSelector(
  selectAllCustomCodes,
  selectUsers,
  selectRentalEntities,
  (codes, users, rentalEntities) => {
    return <CustomCode[]>R.map((c: CustomCode) => {
      let code = new CustomCode(c)
      const creator = R.find((u: User) => u.id === code.creator, users)
      if (!R.isNil(creator)) {
        code.creatorView = creator.name
      }

      code.rentalNames = null
      if (code.rentals) {
        R.forEach((rentalId: string) => {
          const rental: Rental = rentalEntities[rentalId]
          if (!R.isNil(rental)) {
            if (!isSomething(code.rentalNames)) {
              code.rentalNames = rental.name
            } else {
              code.rentalNames += `, ${rental.name}`
            }
          }
        }, code.rentals)
      }

      return code
    }, codes)
  }
)

export const selectRentalsForCode = (code: CustomCode) =>
  createSelector(
    selectAllCustomCodesWithCreatorAndRentals,
    selectRentalEntities,
    selectAllRentals,
    (codes, entities, rentals) => {
      if (R.isNil(code)) {
        return rentals
      }
      const allRentalsForCodeName = R.pipe(
        R.filter(R.propEq('name', code.name)),
        R.map(R.view(R.lensProp('rentals'))),
        R.flatten,
        R.uniq
      )(codes)
      const result = R.concat(
        code.rentals,
        R.without(
          allRentalsForCodeName,
          R.map((r: Rental) => r.id, rentals)
        )
      )
      return R.reject(R.isNil)(R.map((id: string) => entities[id], result))
    }
  )
