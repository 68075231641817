import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core'
import { NgxPermissionsService } from 'ngx-permissions'
import * as lodash from 'lodash'

@Pipe({
  name: 'isReadonlyRole',
  pure: false,
})
export class IsReadonlyRolePipe implements PipeTransform {
  value: boolean
  constructor(private permissions: NgxPermissionsService, private _ref: ChangeDetectorRef) {}
  transform(): boolean {
    if (!lodash.isUndefined(this.value)) {
      return this.value
    }

    this.permissions.hasPermission('readOnly').then((v) => {
      this.value = v
      this._ref.markForCheck()
    })

    return true
  }
}
