import { adapter, initialState } from './state'
import { createReducer, on } from '@ngrx/store'
import {
  LoadAttributeDescriptionsComplete,
  DeleteAttributeDescriptionComplete,
  AddAttributeDescriptionComplete,
  LoadAttributeDescriptionsByEntityTypeComplete,
} from './actions'

export const reducer = createReducer(
  initialState,
  on(LoadAttributeDescriptionsComplete, (state, { items }) => adapter.setAll(items, { ...state, isLoaded: true })),
  on(AddAttributeDescriptionComplete, (state, { item }) => adapter.setOne(item, state)),
  on(LoadAttributeDescriptionsByEntityTypeComplete, (state, { items }) => adapter.upsertMany(items, state)),
  on(DeleteAttributeDescriptionComplete, (state, { item }) => adapter.setOne(item, state))
)

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
