import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { UpdateGuestFiles } from '@tv3/store/guest/guest.actions'
import { GuestFileRequest } from '@tv3/interfaces/requests/guest-file.request'
import { EditItemParent } from '@tv3/containers/guests/edit/edit-item.parent'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { Attachment, AttachmentService, ConfirmDialogService, deleteAttachment } from '@tokeet-frontend/tv3-platform'
import { Guest } from '@tv3/store/guest/guest.model'
import { UploadFileDialogService } from '@tv3/shared/dialogs/upload-file-dialog/upload-file-dialog.service'

@Component({
  selector: 'app-guest-file-list',
  templateUrl: './guest-file-list.component.html',
  styleUrls: ['./guest-file-list.component.scss'],
})
export class EditGuestFileListComponent implements OnInit, EditItemParent {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  @Input() set guest(g: Guest) {
    this.guestItem = g
    this.paginator.firstPage()
    this.dataSource.data = this.guestItem.files ? this.guestItem.files : []
  }

  guestItem: Guest

  displayedColumns = ['created', 'user', 'name', 'url', 'delete']

  dataSource = new MatTableDataSource<Attachment>()

  tableType = TableType.GuestFilesList
  isEmptyTable$ = isEmptyTable(this.dataSource, true)

  constructor(
    private store: Store<fromRoot.State>,
    private confirmDialog: ConfirmDialogService,
    private uploadFileDialog: UploadFileDialogService,
    private attachmentService: AttachmentService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    this.paginator.firstPage()
    this.dataSource.data = this.guestItem.files ? this.guestItem.files : []
  }

  getId() {
    return 'file'
  }

  mainAction() {
    this.addItem()
  }

  addItem() {
    this.uploadFileDialog.open({ maxUploads: 100 }).subscribe((result) => {
      if (this.guestItem.files) {
        this.guestItem.files = result.concat(this.guestItem.files)
      } else {
        this.guestItem.files = result
      }
      this.store.dispatch(UpdateGuestFiles({ guestId: this.guestItem.id, files: this.getFilesRequestData() }))
    })
  }

  onDelete(item: Attachment) {
    this.confirmDialog.confirm().subscribe(() => {
      this.store.dispatch(deleteAttachment({ id: item.id }))

      this.guestItem.files = R.filter((file) => {
        return file.id !== item.id
      }, this.guestItem.files)
      this.store.dispatch(UpdateGuestFiles({ guestId: this.guestItem.id, files: this.getFilesRequestData() }))
    })
  }

  onDownloadFile(item: Attachment) {
    this.attachmentService.downloadFile(item).subscribe()
  }

  private getFilesRequestData() {
    return R.map((file) => {
      const fileG = <any>{ ...file }
      fileG.pkey = file.id
      delete fileG.id
      return <GuestFileRequest>fileG
    }, this.guestItem.files)
  }
}
