import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import {
  Destroyable,
  isSavingRentalBaseRate,
  RatesChangedComplete,
  Rental,
  SaveForm,
  Toaster,
  untilDestroy,
  updateRentalBaseRate,
  updateRentalBaseRateComplete,
} from '@tokeet-frontend/tv3-platform'
import { BaseRate } from '@tv3/models/rental/base-rate'
import { RategenieService } from '@tv3/services/rategenie.service'
import { CurrencyService } from '@tv3/services/utils/currency.service'
import { isSubscriptionValid, ProductsForPlan } from '@tv3/store/plan/plan.model'
import { selectActiveProductPlan } from '@tv3/store/plan/plan.selectors'
import * as fromRoot from '@tv3/store/state'
import * as lodash from 'lodash'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import * as R from 'ramda'
import { Observable, zip } from 'rxjs'
import { map, share, tap } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-rental-rate-settings',
  templateUrl: './rental-rate-settings.component.html',
  styleUrls: ['./rental-rate-settings.component.scss'],
})
export class RentalRateSettingsComponent extends Destroyable implements OnInit, OnChanges, OnDestroy {
  @Input() rental: Rental

  form: FormGroup

  currencySymbol = '$'

  isSaving$ = this.store.pipe(select(isSavingRentalBaseRate), share())

  isRategenieSync$: Observable<boolean>
  isNightlyEditable = true
  isStayEditable = true
  isClearable = true

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private currencyService: CurrencyService,
    private toaster: Toaster,
    private rategenieService: RategenieService
  ) {
    super()
  }

  ngOnInit() {
    this.currencySymbol = this.currencyService.getCurrencySymbol(this.rental.currency)
    this.isRategenieSync$ = zip(
      this.store.pipe(
        select(selectActiveProductPlan, { product: ProductsForPlan.Rategenie }),
        map((plan) => isSubscriptionValid(plan))
      ),
      this.rategenieService.checkAccountSync(),
      this.rategenieService.checkTokeetSync(),
      this.rategenieService.checkRentalSync(this.rental.id)
    ).pipe(
      map((syncs: boolean[]) => R.all((s) => s, syncs)),
      tap((sync) => {
        if (sync) {
          this.isNightlyEditable = false
          this.isStayEditable = false
          this.isClearable = false
          this.buildForm()
        } else {
          this.isClearable = true
        }
      })
    )

    this.actions.pipe(ofType(updateRentalBaseRateComplete), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(RatesChangedComplete())
    })

    this.buildForm()
    this.roundFormValues()
  }

  ngOnChanges(changes: SimpleChanges) {
    const rental: Rental = changes.rental && changes.rental.currentValue

    if (rental) {
      this.rental = rental
      this.buildForm()
    }
  }

  clear() {
    const baseRate = this.form.getRawValue() as BaseRate
    const payload: any = {}
    if (!this.isStayEditable) {
      payload.minimum = baseRate.minimum
      payload.maximum = baseRate.maximum
    }
    if (!this.isNightlyEditable) {
      payload.nightly = baseRate.nightly
    }

    this.store.dispatch(
      updateRentalBaseRate({
        form: payload,
        rental: this.rental,
      })
    )
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    if (form.invalid) return

    const baseRate = form.getRawValue() as BaseRate

    if (!this.validate(baseRate)) return

    const ignoreBDC = form.get('ignoreBDC').value as boolean

    this.store.dispatch(
      updateRentalBaseRate({
        form: baseRate,
        rental: this.rental,
        ignoreBDC,
      })
    )
  }

  private validate(baseRate: BaseRate) {
    let error

    if (!baseRate.nightly && !baseRate.weekly) {
      error = 'You must specify either a nightly or weekly rate.'
    }

    if (!error && baseRate.minimum > baseRate.maximum && baseRate.maximum) {
      error = 'Maximum Stay should not be less than Minimum Stay.'
    }

    if (error) {
      this.toaster.error(error)
    }

    return !error
  }

  private buildForm() {
    const form = (this.form = this.formBuilder.group({
      name: [null],

      nightly: [null],
      monthly: [null],
      weekly: [null],
      weekend: [null],

      minimum: [null, [RxwebValidators.digit(), Validators.min(1)]],
      maximum: [null, [RxwebValidators.digit(), Validators.min(1)]],

      additionalFeeAmount: [null],
      additionalFeeThreshold: [null],
      ignoreBDC: [false],
    }))

    const baseRate = this.rental.baseRate

    if (baseRate) {
      form.patchValue(baseRate)
    }
    if (!this.isNightlyEditable) {
      this.form.get('nightly').disable()
    }

    if (!this.isStayEditable) {
      this.form.get('minimum').disable()
      this.form.get('maximum').disable()
    }

    if (this.rental.attributes && this.rental.attributes['ignore_bdc_obp']) {
      form.patchValue({ ignoreBDC: true })
    }

    form.patchValue({ name: 'Base Rate' })
  }

  private roundFormValues() {
    const integerFields = ['minimum', 'maximum']
    const decimalFields = ['nightly', 'weekend', 'weekly', 'monthly', 'additionalFeeAmount']

    lodash.forEach(integerFields, (field) => this.roundInteger(field))
    lodash.forEach(decimalFields, (field) => this.roundDecimal(field))
  }

  roundInteger(field: string) {
    this.roundFieldValue((value) => lodash.round(value), field)
  }

  roundDecimal(field: string) {
    this.roundFieldValue((value) => lodash.round(value, 2), field)
  }

  private roundFieldValue(round: (value: number) => number, field: string) {
    const formControl = this.form.get(field)
    if (!formControl) return

    let value = formControl.value
    if (lodash.isNil(value)) return
    if (lodash.isString(value)) value = +value
    if (lodash.isNaN(value)) return

    const roundedValue = round(value)
    if (value.toString() === roundedValue.toString()) return

    formControl.setValue(roundedValue, { onlySelf: true })
  }
}
