import { Component, Inject, OnInit } from '@angular/core'
import { setAccountTaxes, Rental, SaveForm, selectAccount, selectOnce, TaxV3 } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { AuthService } from '@tv3/services/auth.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-create-tax-dialog',
  host: { class: 'modal-content' },
  templateUrl: './create-tax-dialog.component.html',
  styleUrls: ['./create-tax-dialog.component.scss'],
})
export class CreateTaxDialogComponent implements OnInit {
  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
    amount: ['', [Validators.required]],
    type: ['percent', [Validators.required]],
  })

  currencySymbol: string

  constructor(
    public dialogRef: MatDialogRef<CreateTaxDialogComponent>,
    private fb: FormBuilder,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental; defaults?: TaxV3 },
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.currencySymbol = R.pathOr('$', ['currency', 'symbol'], this.data.rental)
    this.form.patchValue(this.data.defaults || {})
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    this.store.pipe(selectOnce(selectAccount(this.auth.user.account))).subscribe((account) => {
      const tax = form.getRawValue()
      const taxes = R.concat([tax], R.pathOr([], ['attributes', 'taxes'], account))
      this.store.dispatch(setAccountTaxes({ taxes }))
      this.close()
    })
  }
}
