import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  GetCtripStatus,
  GetGVRStatus,
  GetHoliduStatus,
  GetHvmiStatus,
  GetRentalsUnitedStatus,
  GetTiketStatus,
  selectAllCustomChannels,
} from '@tokeet-frontend/channels'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { selectConnectedAPIChannels, selectConnectedICALChannels } from '@tv3/store/channel/selectors'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { CustomChannelDialogService } from '../shared/custom-channel-dialog/custom-channel-dialog.service'

@Component({
  selector: 'app-channel-grid',
  templateUrl: './channel-grid.component.html',
  styleUrls: ['./channel-grid.component.scss'],
})
export class ChannelGridComponent extends Destroyable implements OnInit {
  connected$ = new BehaviorSubject<boolean>(null)
  apiChannels$ = this.connected$.pipe(
    switchMap((connected) => this.store.pipe(select(selectConnectedAPIChannels(connected))))
  )
  icalChannels$ = this.connected$.pipe(
    switchMap((connected) => this.store.pipe(select(selectConnectedICALChannels(connected))))
  )

  customChannels$ = this.store.pipe(select(selectAllCustomChannels))

  @Input() channelType = 'api'

  @Input() set channelConnected(c: boolean) {
    this.connected$.next(c)
  }
  constructor(private store: Store<any>, private customChannelDialog: CustomChannelDialogService) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(GetHvmiStatus())
    this.store.dispatch(GetGVRStatus())
    this.store.dispatch(GetRentalsUnitedStatus())
    this.store.dispatch(GetHoliduStatus())
    this.store.dispatch(GetCtripStatus())
    this.store.dispatch(GetTiketStatus())
  }

  onAddCustom() {
    this.customChannelDialog.open()
  }
}
