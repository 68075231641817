import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectTripWizardComponent } from './connect-trip-wizard.component'
import { ConnectTripWizardCompleteComponent } from './complete/connect-trip-wizard-complete.component'
import { ConnectTripWizardStep1Component } from './step1/connect-trip-wizard-step1.component'
import { ConnectTripWizardStep2Component } from './step2/connect-trip-wizard-step2.component'
import { ConnectTripWizardStep3Component } from './step3/connect-trip-wizard-step3.component'

const components = [
  ConnectTripWizardStep1Component,
  ConnectTripWizardStep2Component,
  ConnectTripWizardStep3Component,
  ConnectTripWizardCompleteComponent,
  ConnectTripWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectTripWizardModule {}
