import { Injectable } from '@angular/core'
import { AppConfig } from '@tv3/app.config'
import { isSomething, User } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { environment } from '../../environments/environment'

declare const window: any

@Injectable({
  providedIn: 'root',
})
export class WootricService {
  constructor(private appConfig: AppConfig) {}

  run(user: User) {
    if (environment.environment !== 'production') {
      console.log('wootric disabled on staging')
      return
    }
    if (!isSomething(window.wootric) || !isSomething(user)) {
      return
    }
    const subscription = R.find((s) => s?.product === 'tokeet', user?.subscriptions || [])
    if (subscription?.metadata?.value >= 0 && subscription?.price > 0 && subscription?.status === 'active') {
      window.wootricSettings = {
        external_id: user.id,
        product_name: 'AdvanceCM',
        email: user.primaryEmail,
        created_at: user.created,
        account_token: this.appConfig.config.wootricToken,
        properties: {
          pricing_plan: subscription.plan_name,
          rentals: subscription.quantity,
          planId: subscription.plan_id,
          sub_status: subscription.status,
        },
      }
      window.wootric('run')
    }
  }
}
