import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { UtilityEffects } from './utility.effects'

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([UtilityEffects])],
  declarations: [],
})
export class UtilityStoreModule {}
