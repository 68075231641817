import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { Channel, DialogService } from '@tokeet-frontend/tv3-platform'
import { ComponentType } from '@angular/cdk/portal'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import {
  BdcContentDialogComponent,
  BDCContentDialogTab,
} from '@tv3/containers/channels/content/bdc/bdc-content-dialog.component'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'

@Injectable({
  providedIn: SharedModule,
})
export class BDCContentDialogService {
  constructor(private dialog: DialogService) {}

  private open<T>(
    channel: Channel,
    connection: ConnectionView,
    activeTab: BDCContentDialogTab,
    component: ComponentType<T>
  ): MatDialogRef<T> {
    const defaultConfig: MatDialogConfig = {
      data: { channel, connection, activeTab },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(component, defaultConfig)

    return dialogRef
  }

  public openOverlay(channel: Channel, connection?: ConnectionView, activeTab = BDCContentDialogTab.Property) {
    return this.open(channel, connection, activeTab, BdcContentDialogComponent)
  }
}
