import { Observable, of } from 'rxjs'
import { concatMap, map, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'
import { StealthTrigger } from './stealth.model'
import { AddStealthTriggersPayload } from './stealth.types'

@Injectable({
  providedIn: 'root',
})
export class StealthTriggerService {
  constructor(private http: HttpClient) {}

  all(): Observable<StealthTrigger[]> {
    const url = '@api/stealth/all/'
    return this.http.get(url).pipe(deserializeArray<StealthTrigger>(StealthTrigger))
  }

  add(payload: AddStealthTriggersPayload): Observable<StealthTrigger[]> {
    const url = `@api/stealth/`
    return this.http.post(url, payload).pipe(deserializeArray<StealthTrigger>(StealthTrigger))
  }

  get(id: string): Observable<StealthTrigger> {
    const url = `@api/stealth/${id}`
    return this.http.get(url).pipe(map((response) => StealthTrigger.deserialize(response)))
  }

  update(id: string, changes): Observable<StealthTrigger> {
    const url = `@api/stealth/${id}`
    return this.http.put(url, changes).pipe(map((response) => StealthTrigger.deserialize(response)))
  }

  remove(id: string) {
    const url = `@api/stealth/${id}`

    return this.http.delete<StealthTrigger>(url)
  }

  removeMultiple(ids: string[]): Observable<StealthTrigger[]> {
    return of(...ids).pipe(
      concatMap((id) => this.remove(id)),
      toArray()
    )
  }

  pause(id: string) {
    const url = `@api/stealth/pause/${id}`
    return this.http.put(url, {}).pipe(map((response) => StealthTrigger.deserialize(response)))
  }

  resume(id: string) {
    const url = `@api/stealth/resume/${id}`
    return this.http.put(url, {}).pipe(map((response) => StealthTrigger.deserialize(response)))
  }

  archive(id: string) {
    const url = `@api/stealth/archive/${id}`
    return this.http.put(url, {}).pipe(map((response) => StealthTrigger.deserialize(response)))
  }

  unarchive(id: string) {
    const url = `@api/stealth/unarchive/${id}`
    return this.http.put(url, {}).pipe(map((response) => StealthTrigger.deserialize(response)))
  }
}
