import { deserialize, Expose, Serializable, Transform } from '@tokeet-frontend/tv3-platform'

enum SharedContentType {
  CALENDAR = 'calendar',
}

export class SharedContent extends Serializable<SharedContent> {
  @Expose({ name: 'pkey' })
  id: string
  name: string
  type: SharedContentType
  @Transform((value) => value === 1)
  enabled: boolean
  views: number
  created: number
  notes: string
  url: string

  static deserialize(data: object): SharedContent {
    return deserialize(SharedContent, data)
  }
}
