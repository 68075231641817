import { TaxTypes } from '@tokeet/cost-resolver'
import { BDCLikePropertyContacts } from '@tv3/store/channel-property-setting/settings/common'

export type FeeType = TaxTypes

export interface RentalFee {
  type: FeeType
  amount: number
  code: number
  frequency: number
  inclusive: number
}

export type PetsAllowedCode = 'Pets Allowed' | 'Pets Not Allowed' | 'Pets By Arrangements'
export type PetsFeeType = 'free' | 'charges_may_apply'

export interface BDCContentPropertySettings {
  contacts: BDCLikePropertyContacts
  fees: RentalFee[]
  policies: number[]
  pets_policy: PetsAllowedCode
  pets_fee: PetsFeeType
  legalEntityId?: string
  noLicenseRequired?: string
  licenseNumber?: string
  licenseIssueDate?: string
  licenseType?: string
}

export const availablePropertyAmenitiesTokeetIds = [
  'meeting_room',
  'tennis_court',
  'free_breakfast',
  'dry_cleaning',
  'swimming_pool',
  'stairs_elevator',
  'business_services',
  'lockers_storage',
  'paid_parking',
  'fireplace',
  'housekeeping',
  'beachfront',
  'parking_available',
  'gym',
  'non_smoking',
  'heating',
  'free_parking',
  'beach_view',
  'free_housekeeping',
  'game_room',
  'central_air_conditioning',
  'fitness_center',
  'car_rental',
  'valet_parking',
  'shuttle',
  'room_service',
  'hot_tub',
  'bbq',
  'patio_furniture',
  'garden',
  'beach',
  'private_beach',
  'terrace',
  'bicycle',
  'indoor_pool',
  'massage',
]

export const availableRoomAmenitiesTokeetIds = [
  'television',
  'free_wifi',
  'elder_access',
  'washer',
  'shampoo',
  'smart_tv',
  'fireplace',
  'games',
  'dryer',
  'room_with_a_view',
  'heating',
  'iron_board',
  'iron',
  'beach_view',
  'hair-dryer',
  'wheelchair_access',
  'central_air_conditioning',
  'cable',
  'hot_tub',
  'patio_furniture',
  'bathtub',
  'desk',
  'swimming_pool',
]

export const bdcMaxGuestsCount = 20
