<ng-container *ngIf="accounts$ | async; let accounts; else: loading">
  <ng-template *ngIf="!accounts.length" [ngTemplateOutlet]="empty"></ng-template>

  <div class="d-grid gap-3" style="grid-template-columns: repeat(auto-fit, minmax(300px, 300px))">
    <ng-container *ngFor="let item of accounts">
      <div class="d-flex align-items-center gap-2 p-3 bg-white rounded clickable" (click)="onDetails(item)">
        <ng-template
          *ngIf="item.user_info"
          [ngTemplateOutlet]="user"
          [ngTemplateOutletContext]="{ user: item.user_info, account: item }"
        ></ng-template>

        <ng-template
          *ngIf="!item.user_info"
          [ngTemplateOutlet]="account"
          [ngTemplateOutletContext]="{ account: item }"
        ></ng-template>

        <div class="flex-fill"></div>
        <button class="btn btn-secondary-danger" (click)="$event.stopPropagation(); onDisconnect(item)">
          <i class="cs-icon cs-icon-unlink"></i>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template let-user="user" let-account="account" #user>
  <app-avatar [text]="user.first_name + ' ' + user.last_name" [image]="user.picture_url" [size]="40"></app-avatar>
  <div class="text-truncate">
    <div class="text-semi-bold">{{ user.first_name + ' ' + user.last_name }}</div>
    <div class="text-light text-truncate" [matTooltip]="account.rooms?.[0]?.hotelName" matTooltipPosition="above">
      #{{ user.id }} - {{account.rooms?.[0]?.hotelName || 'no Listing'}}
    </div>
  </div>
</ng-template>

<ng-template let-account="account" #account>
  <app-avatar [text]="account.propertyId" [size]="40"></app-avatar>
  <div class="text-truncate">
    <div class="text-semi-bold text-truncate" [matTooltip]="account.rooms?.[0]?.hotelName" matTooltipPosition="above">
      {{account.rooms?.[0]?.hotelName || account.propertyId}}
    </div>
    <div class="text-light" *ngIf="account.rooms">#{{ account.propertyId }}</div>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading-section></app-loading-section>
</ng-template>

<ng-template #empty>
  <app-empty-table class="container-box" [type]="tableType.ChannelAccounts"></app-empty-table>
</ng-template>
