import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core'

@Component({
  selector: 'app-sidebar-expansion-panel',
  templateUrl: './sidebar-expansion-panel.component.html',
  styleUrls: ['./sidebar-expansion-panel.component.scss'],
  host: {
    class: 'default-box-holder',
  },
})
export class SidebarExpansionPanelComponent {
  @Input() header: string
  @Input() hasEdit = false
  @Input() canEdit = true

  @Output() edit = new EventEmitter()

  @HostBinding('class.expanded')
  isExpanded = true

  onToggle() {
    this.isExpanded = !this.isExpanded
  }

  get tooltipText() {
    if (this.hasEdit) {
      return `Edit ${(this.header + '').toLowerCase()}`
    } else {
      return ''
    }
  }
}
