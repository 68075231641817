import { LoadChargesComplete } from './billing.actions'
import { adapter, initialState, State } from './billing.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadChargesComplete, (state, { billings }) => adapter.addAll(billings, R.merge(state, { isLoaded: true })))
)

export function billingReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
