export interface IStorage<T = any> {
  get: (key: string, defaultValue?: T) => T
  has: (key: string) => boolean
  set: (key: string, value?: T) => void
  remove: (key: string) => void
}
export class Storage<T = any> implements IStorage<T> {
  constructor(private storage: any, private prefix: string = null) {}

  get(key: string, defaultValue: any = null): T {
    key = this.toKey(key)
    try {
      const value = this.storage.getItem(key)

      if (value === null) return defaultValue

      return JSON.parse(value)
    } catch (error) {
      return defaultValue
    }
  }

  has(key: string) {
    key = this.toKey(key)
    return !!this.get(key)
  }

  set(key: string, value: T = null) {
    key = this.toKey(key)
    this.storage.setItem(key, JSON.stringify(value))
  }

  remove(key: string) {
    key = this.toKey(key)
    this.storage.removeItem(key)
  }

  private toKey(key): string {
    return this.prefix ? `${this.prefix}:${key}` : key
  }
}
