<div>
  <div class="border-top border-bottom">
    <textarea [formControl]="contentCtrl" class="plain-editor m-0 border-0 w-100 p-2" cdkTextareaAutosize></textarea>
  </div>
  <div class="p-3">
    <div [class.tooLong]="contentCtrl.value.length > 160" class="templateLength">
      {{ contentCtrl.value.length }} <span>Characters</span><span *ngIf="contentCtrl.value.length === 1">s</span>
    </div>
  </div>
</div>
