import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import {
  ActivateDiscountCode,
  ActivateDiscountCodeComplete,
  AddDiscountCode,
  AddDiscountCodeComplete,
  DeactivateDiscountCode,
  DeactivateDiscountCodeComplete,
  DeleteDiscountCode,
  DeleteDiscountCodeComplete,
  DeleteDiscountCodes,
  DeleteDiscountCodesComplete,
  LoadDiscountCodes,
  LoadDiscountCodesComplete,
  UpdateDiscountCode,
  UpdateDiscountCodeComplete,
} from './discount-code.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeService } from './discount-code.service'

@Injectable()
export class DiscountCodeEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadDiscountCodes),
    mergeMap(({ reset }) =>
      this.discountCodeService.load().pipe(
        map((items) => LoadDiscountCodesComplete({ items, reset })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(AddDiscountCode),
    mergeMap(({ payload }) =>
      this.discountCodeService.create(payload).pipe(
        map((res) => AddDiscountCodeComplete({ item: res })),
        tap(() => this.toaster.success('Discount code added successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateDiscountCode),
    concatMap(({ id, payload }) =>
      this.discountCodeService.update(id, payload).pipe(
        map((res) => UpdateDiscountCodeComplete({ update: { id, changes: res } })),
        tap(() => this.toaster.success('Discount code updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  activate$ = this.actions$.pipe(
    ofType(ActivateDiscountCode),
    concatMap(({ id }) =>
      this.discountCodeService.activate(id).pipe(
        map((res) => ActivateDiscountCodeComplete({ id })),
        tap(() => this.toaster.success('Discount code activated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deactivate$ = this.actions$.pipe(
    ofType(DeactivateDiscountCode),
    concatMap(({ id }) =>
      this.discountCodeService.deactivate(id).pipe(
        map((res) => DeactivateDiscountCodeComplete({ id })),
        tap(() => this.toaster.success('Discount code deactivated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteDiscountCode),
    concatMap(({ id }) =>
      this.discountCodeService.delete(id).pipe(
        map((res) => DeleteDiscountCodeComplete({ id })),
        tap(() => this.toaster.success('Discount code removed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteMultiple$ = this.actions$.pipe(
    ofType(DeleteDiscountCodes),
    concatMap(({ ids }) =>
      this.discountCodeService.deleteMultiple(ids).pipe(
        map((res) => DeleteDiscountCodesComplete({ ids })),
        tap(() => this.toaster.success('Discount codes removed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private toaster: Toaster, private discountCodeService: DiscountCodeService) {}
}
