import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import {
  AccountRateAutomationResponse,
  AccountRateAutomationType,
  CreateAccountRateAutomationPayload,
  CreateRateAutomationPayload,
} from './model'
import { select, Store } from '@ngrx/store'
import { selectAccountRateAutomation } from './selectors'

@Injectable()
export class RateAutomationService {
  accountRateAutoamtion: AccountRateAutomationResponse | undefined = undefined
  constructor(private http: HttpClient, private store: Store<any>) {
    this.store.pipe(select(selectAccountRateAutomation)).subscribe((t) => (this.accountRateAutoamtion = t))
  }

  load() {
    const url = `@api/rateautomation/all/`
    return this.http.get<AccountRateAutomationResponse[]>(url)
  }

  create(payload: CreateAccountRateAutomationPayload) {
    const url = `@api/rateautomation/`

    return this.http.post<AccountRateAutomationResponse>(url, payload)
  }

  get(id: string) {
    const url = `@api/rateautomation/${id}`
    return this.http.get<AccountRateAutomationResponse>(url)
  }

  delete(id: string) {
    const url = `@api/rateautomation/${id}`
    return this.http.delete(url)
  }

  createAutomation(type: AccountRateAutomationType, payload: CreateRateAutomationPayload) {
    const pkey = this.accountRateAutoamtion?.pkey
    const url = `@api/rateautomation/${pkey}/${type}/`

    return this.http.post<AccountRateAutomationResponse>(url, payload)
  }

  updateAutomation(type: AccountRateAutomationType, key: string, payload: CreateRateAutomationPayload) {
    const pkey = this.accountRateAutoamtion?.pkey

    const url = `@api/rateautomation/${pkey}/${type}/${key}`

    return this.http.put<AccountRateAutomationResponse>(url, payload)
  }

  deleteAutomation(type: AccountRateAutomationType, key: string) {
    const pkey = this.accountRateAutoamtion?.pkey

    const url = `@api/rateautomation/${pkey}/${type}/${key}`

    return this.http.delete<AccountRateAutomationResponse>(url)
  }
}
