import { Directive } from '@angular/core'
import { MatSortable } from '@angular/material/sort'
import { IStorage } from '../storage'
import { Destroyable } from '../rx-operators'

@Directive()
export abstract class ManagedTable extends Destroyable {
  savedSort: MatSortable
  savedPageSize: number

  constructor(protected storage?: IStorage, protected tableType?: string) {
    super()

    if (tableType) {
      this.loadSavedTableState()
    }
  }

  protected loadSavedTableState() {
    let savedSort = this.storage?.get(this.getSortKey(), null)

    this.savedSort = savedSort || null
    this.savedPageSize = parseInt(this.storage.get(this.getPageSizeKey(), 10), 10)
  }

  onSort(event) {
    this.storage?.set(this.getSortKey(), { id: event.active, start: event.direction })
  }

  onPageChange(event) {
    this.storage?.set(this.getPageSizeKey(), event.pageSize)
  }

  getSortKey() {
    return `TABLE_SORT:${this.tableType}`
  }

  getPageSizeKey() {
    return `TABLE_PAGE_SIZE:${this.tableType}`
  }
}
