import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ImportGuestsDialogComponent } from '@tv3/containers/guests/dialogs/import/import-guests-dialog.component'
import { Guest } from '@tv3/store/guest/guest.model'

@Injectable()
export class ImportGuestsDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: { guests: Guest[] }) {
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

    const defaultConfig: MatDialogConfig = {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      panelClass: ['variable-modal'],
      data,
    }

    return this.dialog.open(ImportGuestsDialogComponent, defaultConfig)
  }
}
