import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import {
  ConfirmDialogService,
  ConfirmDialogStatus,
  Destroyable,
  SaveForm,
  Toaster,
  selectAllRentals,
  untilDestroy,
  ChannelNameTokens,
  DataCheckerService,
  isSomething,
  openWindow,
  AlertDialogService,
} from '@tokeet-frontend/tv3-platform'
import { Store, select } from '@ngrx/store'
import { AiResponderService } from '../store/ai-responder.service'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { filter, finalize, switchMap, tap, observeOn, map, takeWhile, delay } from 'rxjs/operators'
import { AuthService } from '@tv3/services/auth.service'
import { asyncScheduler, interval } from 'rxjs'
import { AIToneOptions, AiResponderSettings } from '../store/ai-settings.model'
import { integer } from '@tokeet-frontend/tv3-platform'
import { selectBookingSourcesWithCustomChannels } from '@tv3/store/channel/selectors'
import * as lodash from 'lodash'
import { environment } from '@tv3/environments/environment'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { RentalGuard } from '@tv3/guards/rental.guard'
import { AISettingsGuard } from '@tv3/containers/ai-responder/store/ai-settings.guard'
import { selectAISettings, selectAISettingsIsSaving } from '../store/ai-settings.selectors'
import { LoadAISettings, UpdateAISettings, LoadAISettingsComplete } from '../store/ai-settings.actions'
import { CustomContractSigner, SignatureService } from '@tokeet-frontend/signature'

@Component({
  templateUrl: './ai-responder-settings.component.html',
  styleUrls: ['./ai-responder-settings.component.scss'],
  host: { class: 'template-overlay' },
})
export class AiResponderSettingsComponent extends Destroyable implements OnInit {
  activeTab = 0
  minuteOptions = [5, 10, 15, 30, 60]
  channels$ = this.store.pipe(
    select(selectBookingSourcesWithCustomChannels({ inquirySource: 'tokeet' })),
    map((sources) =>
      lodash
        .filter(sources, (s: any) => s.id !== ChannelNameTokens.AirBnBICal)
        .map((s) =>
          s.id !== ChannelNameTokens.AirBnBV2API
            ? s
            : { ...s, id: ChannelNameTokens.AirBnBICal, name: ChannelNameTokens.AirBnBICal }
        )
    )
  )
  toneOptions = AIToneOptions

  settingsForm = this.fb.group({
    channels: [[]],
    notification: ['none'],
    delayResponse: [15, [Validators.min(1), integer()]],
    isAutoRespondToNewBookings: [false],
    isDelayResponse: [false],
    isRespondEvenIfResponded: [false],
    isCreateDrafts: [false],
    name: ['Advance Intelligence', [Validators.required]],
    tone: ['business', [Validators.required]],
    maxReplies: [1, [Validators.required, Validators.min(1), integer()]],
  })

  testForm = this.fb.group({
    rentalId: [undefined, [Validators.required]],
    inquiryId: [undefined, [Validators.required]],
    tone: ['business', [Validators.required]],
    message: ['', [Validators.required]],
    answer: [],
  })

  isGeneratingResponse = false
  isLoadingBookings = false
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  bookings$ = this.testForm.get('rentalId').valueChanges.pipe(
    filter((id) => !!id),
    tap(() => (this.isLoadingBookings = true)),
    switchMap((rentalId) => this.inquiryService.getBy({ rentals: [rentalId] })),
    tap(() => (this.isLoadingBookings = false))
  )

  togglesForm = this.fb.group({
    isEnabled: [],
    isAutomateSendReplies: [false],
    isWebsitesEnabled: [],
    isDocumentsEnabled: [],
    isFaqEnabled: [],
  })

  isSaving$ = this.store.pipe(select(selectAISettingsIsSaving))
  isTestFormChanged = false
  isTestPopoverOpen = false
  aiSettings: AiResponderSettings

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private toaster: Toaster,
    private confirm: ConfirmDialogService,
    private alert: AlertDialogService,
    private dataChecker: DataCheckerService,
    public dialogRef: MatDialogRef<AiResponderSettingsComponent>,
    private aiResponderService: AiResponderService,
    private inquiryService: InquiryService,
    private auth: AuthService,
    private signature: SignatureService
  ) {
    super()
    this.dataChecker.check([ChannelGuard, RentalGuard, AISettingsGuard])
  }

  ngOnInit(): void {
    this.store.pipe(select(selectAISettings), filter(isSomething), untilDestroy(this)).subscribe((settings) => {
      this.aiSettings = lodash.cloneDeep(settings)
      this.togglesForm.patchValue(settings, { emitEvent: false })
      this.settingsForm.patchValue(settings, { emitEvent: false })
      if (!settings.isEnabled) {
        this.togglesForm.disable({ emitEvent: false })
        this.settingsForm.disable({ emitEvent: false })
        this.togglesForm.get('isEnabled').enable({ emitEvent: false })
      } else {
        this.togglesForm.enable({ emitEvent: false })
        this.settingsForm.enable({ emitEvent: false })
      }

      this.testForm.get('tone').setValue(settings.tone || 'business', { emitEvent: false })
    })

    this.testForm.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.isTestFormChanged = true
    })

    const watchToggle = (form: FormGroup, key: string, cb: (checked: boolean) => void) => {
      form.get(key).valueChanges.pipe(untilDestroy(this)).subscribe(cb)
    }
    watchToggle(this.togglesForm, 'isEnabled', (checked) => this.onToggleAI(checked))
    watchToggle(this.togglesForm, 'isAutomateSendReplies', (checked) => this.onToggleAutoReply(checked))
    watchToggle(this.togglesForm, 'isWebsitesEnabled', (checked) => this.onToggleWebsites(checked))
    watchToggle(this.togglesForm, 'isDocumentsEnabled', (checked) => this.onToggleDocuments(checked))
    watchToggle(this.togglesForm, 'isFaqEnabled', (checked) => this.onToggleFAQs(checked))
    // settings
    watchToggle(this.settingsForm, 'isAutoRespondToNewBookings', (checked) =>
      this.onToggleSettings('isAutoRespondToNewBookings', checked)
    )
    watchToggle(this.settingsForm, 'isDelayResponse', (checked) => this.onToggleSettings('isDelayResponse', checked))
    watchToggle(this.settingsForm, 'isRespondEvenIfResponded', (checked) =>
      this.onToggleSettings('isRespondEvenIfResponded', checked)
    )
    watchToggle(this.settingsForm, 'notification', (checked) => this.onToggleSettings('notification', checked))

    watchToggle(this.settingsForm, 'isCreateDrafts', (checked) => {
      if (checked) {
        this.confirm
          .openRegular({
            title: 'Warning!',
            body: `Your Advance Intelligence balance will be used for each generated draft. Do you want to enable it?`,
          })
          .subscribe((status) => {
            if (status === ConfirmDialogStatus.Confirmed) {
              this.onToggleSettings('isCreateDrafts', checked)
            } else {
              this.settingsForm.get('isCreateDrafts').setValue(false, { emitEvent: false })
            }
          })
      } else {
        this.onToggleSettings('isCreateDrafts', checked)
      }
    })
  }

  close() {
    this.dialogRef.close()
  }

  onRefreshSettings() {
    this.store.dispatch(LoadAISettings())
  }

  onToggleAI(checked: boolean) {
    if (checked) {
      this.aiResponderService
        .getSettings()
        .pipe(
          tap((settings) => this.store.dispatch(LoadAISettingsComplete({ settings }))),
          delay(100)
        )
        .subscribe(() => {
          this.onEnableAI()
        })
    } else {
      this.onDisableAI()
    }
  }

  onDisableAI() {
    this.confirm
      .confirm({
        title: 'Disable Advance Intelligence?',
        body: 'Are you sure you want to disable Advance Intelligence?',
        confirmText: 'Disable',
      })
      .subscribe(() => {
        this.store.dispatch(
          UpdateAISettings({
            payload: {
              isEnabled: false,
              isAutomateSendReplies: false,
              // isWebsitesEnabled: false,
              isDocumentsEnabled: false,
              isFaqEnabled: false,
            },
            message: 'Advance Intelligence feature has been disabled successfully',
          })
        )
      })
  }
  onEnableAI() {
    if (this.aiSettings?.isContractSigned && this.aiSettings?.contractId) {
      this.store.dispatch(
        UpdateAISettings({
          payload: {
            isEnabled: true,
          },
          message: 'Advance Intelligence feature has been enabled successfully',
        })
      )

      return
    }

    if (this.aiSettings?.contractId) {
      this.alert
        .openResult({
          title: 'Advance Intelligence Agreement Not Signed',
          body: 'Please sign Advance Intelligence agreement to enable Advance Intelligence feature',
        })
        .pipe(switchMap(() => this.openContract(this.aiSettings.contractId)))
        .subscribe()
      return
    }

    const terms =
      environment.environment === 'production'
        ? 'https://www.tokeet.com/ai_terms_conditions.html'
        : 'https://stage.home.tokeet.com/ai_terms_conditions.html'
    this.confirm
      .confirmExtra(
        {
          title: 'Enable Advance Intelligence?',
          body: 'Welcome to Advance Intelligence! Please take a moment to review & sign Advance Intelligence agreement to proceed & Accept our Terms and Conditions to ensure a smooth and secure experience.',
          extra: `I agree to the <a href="${terms}" target="_blank">Terms & Conditions</a>`,
          extraCheckedRequired: true,
          extraCheckedRequiredMsg: 'Please select checkbox to proceed',
          confirmText: 'Sign',
          confirmBtnTooltip: 'Sign Advance Intelligence agreement to enable Advance Intelligence feature',
        },
        true
      )
      .pipe(
        filter((res) => {
          if (res.status != ConfirmDialogStatus.Confirmed || !res.isChecked) {
            this.togglesForm.get('isEnabled').setValue(false, { emitEvent: false })
            return false
          }
          return true
        }),
        switchMap(() => {
          const signer: CustomContractSigner = {
            email: this.auth.user.primaryEmail,
            name: this.auth.user.name,
            role: environment.config.tokeetAIContractSignerRole,
          }
          return this.signature.sendContractViaEmail(environment.config.tokeetAIContractId, [signer])
        }),
        switchMap((contract) => {
          this.store.dispatch(
            UpdateAISettings({
              payload: { contractId: contract.contract_id, isContractSigned: false },
              silent: true,
            })
          )
          // keep it disabled before sign the contract
          this.togglesForm.get('isEnabled').setValue(false, { emitEvent: false })
          return this.openContract(contract.contract_id)
        })
      )
      .subscribe()
  }

  onToggleAutoReply(checked: boolean) {
    const update = () => {
      this.store.dispatch(
        UpdateAISettings({
          payload: { isAutomateSendReplies: checked },
          message: 'Automatically sending Advance Intelligence message option is ' + (checked ? 'enabled' : 'disabled'),
        })
      )
    }
    if (!checked) {
      update()
      return
    }
    this.confirm
      .confirm({
        title: 'Enable Automatically Send AI Replies?',
        body: `Do you want to automatically send AI based messages to guest as reply?`,
      })
      .subscribe(() => {
        update()
      })
  }

  onSignAIContract() {
    const contractId = this.aiSettings.contractId
    if (!contractId) {
      return
    }
    this.openContract(contractId).subscribe()
  }

  openContract(contractId: string) {
    return this.signature.getContractSignLink(contractId, environment.config.tokeetAIContractSignerRole).pipe(
      map(({ link }) => openWindow(link, null, 1200, 800)),
      switchMap((win: Window) => interval(2000).pipe(takeWhile(() => !win.closed))),
      untilDestroy(this),
      finalize(() => this.checkContractSigned())
    )
  }

  checkContractSigned() {
    interval(2000)
      .pipe(
        takeWhile(() => !this.aiSettings?.isContractSigned),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.onRefreshSettings()
      })
  }

  onToggleWebsites(checked: boolean) {
    this.store.dispatch(
      UpdateAISettings({
        payload: { isWebsitesEnabled: checked },
        message: 'Websites are now ' + (checked ? 'enabled' : 'disabled'),
      })
    )
  }

  onToggleDocuments(checked: boolean) {
    this.store.dispatch(
      UpdateAISettings({
        payload: { isDocumentsEnabled: checked },
        message: 'Documents are now ' + (checked ? 'enabled' : 'disabled'),
      })
    )
  }

  onToggleFAQs(checked: boolean) {
    this.store.dispatch(
      UpdateAISettings({
        payload: { isFaqEnabled: checked },
        message: 'FAQs are now ' + (checked ? 'enabled' : 'disabled'),
      })
    )
  }

  onToggleSettings(key: string, checked: boolean) {
    this.store.dispatch(
      UpdateAISettings({
        payload: { [key]: checked },
        message: 'This feature is now ' + (checked ? 'enabled' : 'disabled'),
      })
    )
  }

  @SaveForm()
  onSaveSettings(form: FormGroup) {
    const payload = this.settingsForm.getRawValue()
    this.store.dispatch(
      UpdateAISettings({
        payload,
      })
    )
  }

  @SaveForm()
  onTest(form: FormGroup) {
    const { answer, rentalId, ...payload } = this.testForm.getRawValue()
    this.isGeneratingResponse = true
    this.aiResponderService
      .generateReply({
        account: this.auth.accountId,
        convert: 'html',
        ...payload,
      })
      .pipe(finalize(() => (this.isGeneratingResponse = false)))
      .subscribe(
        (res) => {
          this.testForm.patchValue({ answer: res.message })
          this.isTestFormChanged = false
        },
        (error) => {
          this.toaster.error('Unable to generate response', '', error)
        }
      )
  }
}
