import { Component, Inject, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { Observable } from 'rxjs'
import { ChannelRoomRateResponse, GetChannelRoomRatesPayload } from '@tv3/store/connection/connection.types'

export interface SelectRateToPushDialogConfig {
  title?: string
  help?: string
  buttonIcon?: string
  buttonText?: string
  channelId: string
  channelName: ChannelNameTokens
  channelFriendlyName: string
  propertyId: string | number
  roomId: string | number
  categories: { id: string; name: string }[]
}

@Component({
  selector: 'app-select-rate-to-push-dialog',
  host: { class: 'modal-content' },
  templateUrl: './select-rate-to-push-dialog.component.html',
  styleUrls: ['./select-rate-to-push-dialog.component.scss'],
})
export class SelectRateToPushDialogComponent implements OnInit {
  config = {
    title: 'Select Rate Category',
    help: 'This will push your rental rates from AdvanceCM to the selected channel. The channel rates will be overwritten with your AdvanceCM rates so be certain that your AdvanceCM rates are correct. Please select a AdvanceCM rate category to push from, then a channel rate category to push to.',
    buttonText: 'PUSH RATE',
  } as SelectRateToPushDialogConfig

  form = this.fb.group({
    category: [undefined, [Validators.required]],
    channelRate: [undefined, [Validators.required]],
  })

  channelRoomRates$: Observable<ChannelRoomRateResponse[]>

  constructor(
    public dialogRef: MatDialogRef<SelectRateToPushDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private connectionService: ConnectionService,
    @Inject(MAT_DIALOG_DATA) public data: { config: SelectRateToPushDialogConfig }
  ) {
    const config = this.data.config
    const payload: GetChannelRoomRatesPayload = {
      api: config.channelName,
      channel_id: config.channelId,
      id: config.roomId,
      propertyId: config.propertyId,
    }

    this.channelRoomRates$ = this.connectionService.getRoomRatesFromConnection(
      this.data.config.channelName,
      this.data.config.channelId,
      payload
    )
  }

  isDefaultCategory() {
    const category = this.form.get('category').value
    return !category || category === 'default'
  }

  ngOnInit() {
    this.config = { ...this.config, ...this.data.config }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = this.form.getRawValue()
    this.dialogRef.close(formValues)
  }
}
