import { IncidentView } from './model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<IncidentView> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<IncidentView> = createEntityAdapter<IncidentView>({
  sortComparer: (a, b) => a.created - b.created,
  selectId: (t: IncidentView) => t.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
