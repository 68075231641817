import { Directive, Input, OnInit } from '@angular/core'
import {
  asLocalDate,
  asUTCEpoch,
  ConfirmDialogService,
  ConfirmDialogStatus,
  Destroyable,
  isSomething,
  SaveForm,
  selectAllRentals,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { filter, observeOn, startWith } from 'rxjs/operators'
import { asyncScheduler, BehaviorSubject, merge } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { noAllWhitespaceValidator, omitViewFields, percent, positive } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'

import { FormSwitchComponent } from '@tokeet-frontend/tv3-platform'
import {
  DiscountCode,
  ActivateDiscountCode,
  DeactivateDiscountCode,
  UpdateDiscountCode,
  AddDiscountCode,
} from '@tokeet-frontend/discount-codes'

@Directive()
export class DiscountCodeFormComponent extends Destroyable implements OnInit {
  @Input() item: Partial<DiscountCode>

  form = this.fb.group({
    type: ['pct', [Validators.required]],
    amount: [undefined, [Validators.required, Validators.min(0)]],
    booking_total_min: [undefined, [positive()]],
    name: ['', [Validators.required, Validators.maxLength(50), noAllWhitespaceValidator]],
    description: ['', [Validators.required, Validators.maxLength(150)]],
    email: ['', [Validators.email]],
    rental_id: [],
    max_uses: [undefined, [Validators.required, Validators.min(1)]],
    start: [undefined],
    expires: [undefined],
    los: [undefined, [Validators.min(1)]],
  })

  get typeCtrl() {
    return this.form.get('type')
  }

  get amountCtrl() {
    return this.form.get('amount')
  }

  statusCtrl = new FormControl()

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  now = new Date()

  minEndDate = new BehaviorSubject<Date>(this.now)
  maxStartDate = new BehaviorSubject<Date>(null)

  isEdit = false

  constructor(private fb: FormBuilder, private store: Store<any>, private confirmDialog: ConfirmDialogService) {
    super()
  }

  ngOnInit() {
    this.setForm(this.item)
    merge(this.form.get('start').valueChanges, this.form.get('expires').valueChanges)
      .pipe(
        startWith(null),
        filter(() => !this.item || !this.item.id),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.setStartEndMinMax()
      })

    this.typeCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((value) => {
      if (value === 'pct') {
        this.amountCtrl.setValidators([Validators.required, percent()])
      } else {
        this.amountCtrl.setValidators([Validators.required, positive()])
      }
      this.amountCtrl.updateValueAndValidity()
    })
  }

  setStartEndMinMax() {
    if (this.form.get('start').value) {
      const start = moment(this.form.get('start').value).add(1, 'days').startOf('day').toDate()
      const currentEnd = (this.item && asLocalDate(this.item.expires)) || this.now
      let minEndDate = currentEnd < start ? start : currentEnd
      minEndDate = minEndDate < this.now ? this.now : minEndDate
      this.minEndDate.next(minEndDate)
    }

    if (this.form.get('expires').value) {
      const end = moment(this.form.get('expires').value).subtract(1, 'days').endOf('day').toDate()
      const currentStart = this.item && asLocalDate(this.item.start)
      this.maxStartDate.next(currentStart && currentStart < end ? currentStart : end)
    }
  }

  onStatusChange(checked: boolean, toggle: FormSwitchComponent) {
    if (checked) {
      this.onActivate(this.item)
    } else {
      this.onDeactivate(this.item, toggle)
    }
  }

  onActivate(item: Partial<DiscountCode>) {
    this.store.dispatch(ActivateDiscountCode({ id: item.id }))
  }

  onDeactivate(item: Partial<DiscountCode>, toggle: FormSwitchComponent) {
    this.confirmDialog
      .openRegular({
        title: 'Deactivate discount?',
        body: 'Are you sure you want to deactivate this discount?',
      })
      .subscribe((status: ConfirmDialogStatus) => {
        if (status === ConfirmDialogStatus.Confirmed) {
          this.store.dispatch(DeactivateDiscountCode({ id: item.id }))
        } else {
          toggle.value = true
        }
      })
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = form.getRawValue()
    const payload = {
      ...formData,
      start: asUTCEpoch(formData.start),
      expires: asUTCEpoch(formData.expires),
    } as DiscountCode

    if (this.isEdit) {
      this.store.dispatch(UpdateDiscountCode({ id: this.item.id, payload }))
    } else {
      this.store.dispatch(AddDiscountCode({ payload }))
    }
  }

  updateEditFormState(item: Partial<DiscountCode>) {
    this.form.disable()
    if (item.start) {
      this.form.get('start').enable()
    }
    if (item.expires) {
      this.form.get('expires').enable()
    }
    this.form.get('max_uses').enable()
    this.form
      .get('max_uses')
      .setValidators([Validators.required, Validators.min((item.bookings && item.bookings.length) || 1)])
    this.form.updateValueAndValidity()
  }

  private setForm(item?: Partial<DiscountCode>) {
    if (isSomething(item)) {
      this.form.patchValue({
        ...omitViewFields(item),
        start: item.start && asLocalDate(item.start),
        expires: item.expires && asLocalDate(item.expires),
      })
      this.statusCtrl.patchValue(!!item.status)
      this.isEdit = !!item.id
    }
    if (this.isEdit) {
      this.updateEditFormState(item)
    }
  }
}
