<mat-accordion [hideToggle]="true" class="p-3 d-flex flex-column gap-3">
  <mat-expansion-panel
    class="shadow-none border rounded m-0"
    [expanded]="true"
    [class.bg-active2]="!panel1.expanded"
    #panel1
  >
    <mat-expansion-panel-header [class.border-bottom]="panel1.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Add bookings to exclude them from Advance Intelligence automated responses"
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel1.expanded ? 'down' : 'right')"></i> Ignore Bookings
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row mt-3">
      <div class="col-md-6">
        <mat-form-field2>
          <mat-label>Select bookings for Ignore list</mat-label>
          <app-select
            bindValue="id"
            bindLabel="title"
            [multiple]="true"
            [searchable]="true"
            [itemHeight]="60"
            [items]="inquiries$ | async"
            (searchChange)="onSearchBookings($event)"
            [formControl]="bookingsCtrl"
            #bookings
          >
            <ng-template selectItem let-item="item">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <div style="line-height: 1.5" class="py-2">
                  <div class="text-truncate">
                    <b>{{ item.title }}</b> at {{ item.rentalId | rentalName | async }}
                  </div>
                  <small class="text-muted">
                    starting {{ item.guestArrive | epoch: 'DD - MMM - YYYY' }} ending
                    {{ item.guestDepart | epoch: 'DD - MMM - YYYY' }}
                  </small>
                </div>
              </div>
            </ng-template>
          </app-select>
        </mat-form-field2>
        <button class="btn btn-secondary-info text-nowrap" (click)="onSaveBookings()" [disabled]="bookingsCtrl.invalid">
          <i class="cs-icon cs-icon-left-down"></i> Add To List
        </button>
      </div>
    </div>
    <div class="border rounded overflow-hidden my-4 bg-white">
      <div class="p-3 border-bottom d-flex">
        <app-table-search
          inputTooltip="Enter name you want to search for"
          [ctrl]="searchBookingsCtrl"
        ></app-table-search>
        <app-cancel-filters
          [isFiltering]="searchBookingsCtrl.value"
          (clear)="searchBookingsCtrl.reset()"
          class="ml-auto"
        ></app-cancel-filters>
      </div>
      <mat-table #table #bookingsSort="matSort" matSort [dataSource]="bookingsDataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Guest Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.guestDetails?.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Guest Email</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.guestDetails?.email }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="guestArrive">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Arrival</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.guestArrive | epoch: 'DD - MMM - YYYY' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="guestDepart">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Departure</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.guestDepart | epoch: 'DD - MMM - YYYY' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              href="javascript:void(0)"
              class="text-danger"
              matTooltip="Remove it from ignore list"
              (click)="onDeleteBooking(element)"
              ><i class="cs-icon cs-icon-trash"></i
            ></a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="bookingsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: bookingsColumns"></mat-row>
      </mat-table>
      <app-empty-table type="AIIgnoreBookings" *ngIf="!bookingsDataSource.data?.length"></app-empty-table>

      <mat-paginator2
        #bookingsPaginator
        [length]="bookingsDataSource?.data?.length"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="shadow-none border rounded m-0" [class.bg-active2]="!panel2.expanded" #panel2>
    <mat-expansion-panel-header [class.border-bottom]="panel2.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Add guests to exclude them from Advance Intelligence automated responses"
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel2.expanded ? 'down' : 'right')"></i> Ignore Guests
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row mt-3">
      <div class="col-md-6">
        <mat-form-field2>
          <mat-label>Select guests for Ignore List</mat-label>
          <app-select
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [searchable]="true"
            [items]="guests$ | async"
            (searchChange)="onSearchGuests($event)"
            [formControl]="guestsCtrl"
            #guests
          >
            <ng-template selectItem let-item="item">
              <div class="text-truncate">
                {{ item.name }} <span class="text-light">{{ item.primaryEmail }}</span>
              </div>
            </ng-template>
          </app-select>
        </mat-form-field2>
        <button class="btn btn-secondary-info text-nowrap" (click)="onSaveGuests()" [disabled]="guestsCtrl.invalid">
          <i class="cs-icon cs-icon-left-down"></i> Add To List
        </button>
      </div>
    </div>
    <div class="border rounded overflow-hidden my-4 bg-white">
      <div class="p-3 border-bottom d-flex">
        <app-table-search inputTooltip="Enter name you want to search for" [ctrl]="searchGuestsCtrl"></app-table-search>
        <app-cancel-filters
          [isFiltering]="searchGuestsCtrl.value"
          (clear)="searchGuestsCtrl.reset()"
          class="ml-auto"
        ></app-cancel-filters>
      </div>
      <mat-table #table #guestsSort="matSort" matSort [dataSource]="guestsDataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Guest Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="primaryEmail">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Guest Email</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.primaryEmail }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              href="javascript:void(0)"
              class="text-danger"
              matTooltip="Remove it from ignore list"
              (click)="onDeleteGuest(element)"
              ><i class="cs-icon cs-icon-trash"></i
            ></a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="guestsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: guestsColumns"></mat-row>
      </mat-table>
      <app-empty-table type="AIIgnoreGuests" *ngIf="!guestsDataSource.data?.length"></app-empty-table>

      <mat-paginator2
        #guestsPaginator
        [length]="guestsDataSource?.data?.length"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="shadow-none border rounded m-0" [class.bg-active2]="!panel3.expanded" #panel3>
    <mat-expansion-panel-header [class.border-bottom]="panel3.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Add regex to exclude the guests from Advance Intelligence automated responses when emails are matched"
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel3.expanded ? 'down' : 'right')"></i> Ignore Regex Patterns
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="mt-3">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field2>
            <mat-label>Add Regex Pattern to Ignore List</mat-label>
            <input type="text" matInput placeholder="Example: gmail.com" [formControl]="ignoreGuestsRegexpCtrl" />
          </mat-form-field2>
          <button
            class="btn btn-secondary-info text-nowrap"
            (click)="onSaveGuestsRegexp()"
            [disabled]="ignoreGuestsRegexpCtrl.invalid"
          >
            <i class="cs-icon cs-icon-left-down"></i> Add To List
          </button>
        </div>
      </div>
      <div class="border rounded overflow-hidden my-4 bg-white">
        <div class="p-3 border-bottom d-flex">
          <app-table-search
            inputTooltip="Enter regexp you want to search for"
            [ctrl]="searchGuestRegexpsCtrl"
          ></app-table-search>
          <app-cancel-filters
            [isFiltering]="searchGuestRegexpsCtrl.value"
            (clear)="searchGuestRegexpsCtrl.reset()"
            class="ml-auto"
          ></app-cancel-filters>
        </div>
        <mat-table #table #regexpsSort="matSort" matSort [dataSource]="regexpsDataSource">
          <ng-container matColumnDef="content">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Regex</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a
                href="javascript:void(0)"
                class="text-danger"
                matTooltip="Remove it from ignore list"
                (click)="onDeleteGuestsRegexp(element)"
                ><i class="cs-icon cs-icon-trash"></i
              ></a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="regexpsColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: regexpsColumns"></mat-row>
        </mat-table>
        <app-empty-table type="AIIgnoreGuestsRegexp" *ngIf="!regexpsDataSource.data?.length"></app-empty-table>

        <mat-paginator2
          #regexpsPaginator
          [length]="regexpsDataSource?.data?.length"
          [pageSizeOptions]="[5, 10, 20, 50]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator2>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="shadow-none border rounded m-0" [class.bg-active2]="!panel4.expanded" #panel4>
    <mat-expansion-panel-header [class.border-bottom]="panel4.expanded">
      <mat-panel-title
        class="d-flex align-items-center"
        matTooltip="Add rentals to exclude them from Advance Intelligence automated responses"
      >
        <i class="fas mr-1" [ngClass]="'fa-arrow-' + (panel4.expanded ? 'down' : 'right')"></i> Ignore Rentals
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row mt-3">
      <div class="col-md-6">
        <mat-form-field2>
          <mat-label>Select rentals for Ignore list</mat-label>
          <app-select
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [searchable]="true"
            [items]="rentals$ | async"
            [formControl]="rentalsCtrl"
            #rentals
          >
            <ng-template selectItem let-item="item">
              {{ item.name }}
            </ng-template>
          </app-select>
        </mat-form-field2>
        <button class="btn btn-secondary-info text-nowrap" (click)="onSaveRentals()" [disabled]="rentalsCtrl.invalid">
          <i class="cs-icon cs-icon-left-down"></i> Add To List
        </button>
      </div>
    </div>
    <div class="border rounded overflow-hidden my-4 bg-white">
      <div class="p-3 border-bottom d-flex">
        <app-table-search
          inputTooltip="Enter name you want to search for"
          [ctrl]="searchRentalsCtrl"
        ></app-table-search>
        <app-cancel-filters
          [isFiltering]="searchRentalsCtrl.value"
          (clear)="searchRentalsCtrl.reset()"
        ></app-cancel-filters>
      </div>
      <mat-table #table #rentalsSort="matSort" matSort [dataSource]="rentalsDataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Rental Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Rental Email</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a
              href="javascript:void(0)"
              class="text-danger"
              matTooltip="Remove it from ignore list"
              (click)="onDeleteRental(element)"
              ><i class="cs-icon cs-icon-trash"></i
            ></a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="rentalsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: rentalsColumns"></mat-row>
      </mat-table>
      <app-empty-table type="AIIgnoreRentals" *ngIf="!rentalsDataSource.data?.length"></app-empty-table>

      <mat-paginator2
        #rentalsPaginator
        [length]="rentalsDataSource?.data?.length"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </div>
  </mat-expansion-panel>
</mat-accordion>
