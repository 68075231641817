import { Pipe, PipeTransform } from '@angular/core'
import { startCase, toLower } from 'lodash'

@Pipe({
  name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    return startCase(toLower(value || ''))
  }
}
