import { Injectable } from '@angular/core'
import { AddCreditCardDialogComponent } from './add-credit-card-dialog.component'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class AddCreditCardDialogService {
  constructor(private dialog: DialogService) {}

  open(replace = false) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { replace },
    }
    return this.dialog.openVariableDialog(AddCreditCardDialogComponent, defaultConfig)
  }
}
