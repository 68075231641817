import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'

import { isAllSelected, UserStorage } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  getRentalConfigs,
  HomeAwayRentalConfigView,
  PauseRentalConfig,
  ResumeHomeAwayRentalConfig,
} from '@tokeet-frontend/channels'
import {
  Channel,
  ChannelNameTokens,
  InfoDialogService,
  Rental,
  selectChannelByName,
  selectRentalCities,
  selectRentalTags,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { createSearch, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { select, Store } from '@ngrx/store'
import { SelectionModel } from '@angular/cdk/collections'
import { combineLatest } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { FormBuilder } from '@angular/forms'
import { sortBy } from 'lodash'
import { VrboFeesDialogService } from '../vrbo-fees-dialog/vrbo-fees-dialog.service'
import { VrboRentalValidationDialogService } from '../vrbo-rental-validation/vrbo-rental-validation-dialog.service'
import { VrboIsInvalidRentalPipe } from '../is-valid-rental.pipe'
import { BookingFormulasOverlayService } from '@tv3/containers/formulas/overlay/booking-formulas-overlay.service'

@Component({
  selector: 'app-connect-vrbo-wizard-step3',
  templateUrl: './connect-vrbo-wizard-step3.component.html',
  styleUrls: ['./connect-vrbo-wizard-step3.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConnectVrboWizardStep3Component
  extends SelectableRow<HomeAwayRentalConfigView>
  implements OnInit, AfterViewInit
{
  @Input() channel: Channel

  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  @ViewChild('paginator', { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['select', 'rental_name', 'date_enabled', 'last_sync', 'status', 'action', 'fees']

  isExpansionDetailRow = (i: number, row: HomeAwayRentalConfigView & { detailRow?: boolean }) => {
    return row.hasOwnProperty('detailRow') && new VrboIsInvalidRentalPipe().transform(row)
  }
  expandedElementId: string

  isEmptyTable$ = isEmptyTable(this.dataSource)

  rentalConfigs: HomeAwayRentalConfigView[]

  filters = this.fb.group({
    status: [''],
    tags: [[]],
    cities: [[]],
  })

  tags$ = this.store.pipe(select(selectRentalTags))
  cities$ = this.store.pipe(select(selectRentalCities))

  searchFn = createSearch<HomeAwayRentalConfigView>(['rental_name', 'propertyId'])

  constructor(
    private fb: FormBuilder,
    private infoDialog: InfoDialogService,
    private store: Store<any>,
    private toaster: Toaster,

    protected storage: UserStorage,
    private vrboFeesDialog: VrboFeesDialogService,
    private bookingFormulasOverlay: BookingFormulasOverlayService,
    private vrboRentalValidationDialog: VrboRentalValidationDialogService
  ) {
    super(storage, TableType.VRBORentals)
  }

  ngOnInit() {
    this.store.pipe(getRentalConfigs(ChannelNameTokens.Homeaway), untilDestroy(this)).subscribe((configs) => {
      this.rentalConfigs = configs as HomeAwayRentalConfigView[]
      this.dataSource.data = this.withExtraRow(configs)
    })
    this.store
      .pipe(select(selectChannelByName, { name: ChannelNameTokens.Homeaway }), untilDestroy(this))
      .subscribe((channel) => {
        this.channel = channel
      })

    combineLatest([
      this.store.pipe(getRentalConfigs(ChannelNameTokens.Homeaway)),
      this.filters.valueChanges.pipe(startWith(this.filters.getRawValue())),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([rentalConfigs, { status, tags, cities }]) => {
        let configs = rentalConfigs
        switch (status) {
          case 'all':
            configs = rentalConfigs
            break
          case 'active':
            configs = R.filter((config) => config.status === 1, rentalConfigs)
            break
          case 'inactive':
            configs = R.filter((config) => config.status === 0, rentalConfigs)
            break
        }
        if (tags?.length > 0) {
          configs = R.filter(
            (config) =>
              R.any(
                R.equals(true),
                R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], config.rental)), tags)
              ),
            configs
          )
        }
        if (cities?.length > 0) {
          configs = R.filter((config) => R.contains(R.pathOr('', ['address', 'city'], config.rental), cities), configs)
        }
        this.dataSource.data = this.withExtraRow(configs || [])
      })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    const configs = this.searchFn(this.rentalConfigs, term)
    this.dataSource.data = this.withExtraRow(configs)
  }

  withExtraRow(configs: HomeAwayRentalConfigView[]) {
    configs = sortBy(configs, (config) => !new VrboIsInvalidRentalPipe().transform(config))
    return R.map((config: HomeAwayRentalConfigView) => {
      return {
        ...config,
        detailRow: new VrboIsInvalidRentalPipe().transform(config),
      }
    }, configs)
  }

  onConnect() {
    if (
      R.any((config) => {
        return new VrboIsInvalidRentalPipe().transform(config)
      }, this.selection.selected as HomeAwayRentalConfigView[])
    ) {
      this.toaster.warning('You have selected rows with invalid rentals')
      return
    }
    R.forEach((config) => {
      this.store.dispatch(ResumeHomeAwayRentalConfig({ config, channel: ChannelNameTokens.Homeaway }))
    }, this.selection.selected)

    this.onComplete()
  }

  onComplete() {
    this.next.emit()
  }

  allInvalid() {
    return R.all((config) => new VrboIsInvalidRentalPipe().transform(config), this.dataSource.data)
  }

  isAllSelected() {
    return isAllSelected(this.selection, this.rentalConfigs, this.isRowSelectable)
  }

  isRowSelectable(row: HomeAwayRentalConfigView) {
    const invalid = new VrboIsInvalidRentalPipe().transform(row)
    if (row.rental_id === '4ae9c84e-5d78-4283-af8e-81f4e6efcf29') {
      console.log(`id=${row.rental_id} invalid=${invalid}`)
    }
    return !invalid
  }

  setExpanded(row) {
    this.expandedElementId = row?.rental_id
  }

  onFees(config: HomeAwayRentalConfigView) {
    this.vrboFeesDialog.open(config.rental_id)
  }

  onToggleStatus(config: HomeAwayRentalConfigView) {
    if (config.status === 1) {
      this.store.dispatch(PauseRentalConfig({ config, channel: ChannelNameTokens.Homeaway }))
    } else {
      this.store.dispatch(ResumeHomeAwayRentalConfig({ config, channel: ChannelNameTokens.Homeaway }))
    }
  }

  onFixRentalFields(rental: Rental) {
    this.vrboRentalValidationDialog.open(rental)
  }

  onFixRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Basic }))
  }

  onFixRentalPhotos(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Gallery }))
  }

  onFixRentalAddress(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Basic }))
  }

  onFixRentalLatLong(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Location }))
  }

  onBookingFormula() {
    this.bookingFormulasOverlay.open(this.channel)
  }
}
