import { createAction, props } from '@ngrx/store'
import { Website } from './website.model'
import { Update } from '@ngrx/entity'
import { AddWebsitePayload, WebsiteTemplate } from '@tv3/store/website/website.types'

export const LoadWebsiteTemplates = createAction('[Website] Load Website Templates')
export const LoadWebsiteTemplatesComplete = createAction(
  '[Website] Load Website Templates Complete',
  props<{ templates: WebsiteTemplate[] }>()
)

export const LoadWebsites = createAction('[Website] Load Websites')
export const LoadWebsitesComplete = createAction('[Website] Load Websites Complete', props<{ websites: Website[] }>())

export const LoadWebsite = createAction('[Website] Load Website', props<{ id: string }>())
export const LoadWebsiteComplete = createAction('[Website] Load Website Complete', props<{ website: Website }>())

export const AddWebsite = createAction('[Website] Add Website', props<{ data: AddWebsitePayload }>())
export const AddWebsiteComplete = createAction('[Website] Add Website Complete', props<{ website: Website }>())

export const UpdateWebsite = createAction('[Website] Update Website', props<{ websiteId: string; data: any }>())
export const UpdateWebsiteComplete = createAction(
  '[Website] Update Website Complete',
  props<{ update: Update<Website> }>()
)

export const PublishWebsite = createAction('[Website] Publish Website', props<{ id: string; domain: string }>())
export const PublishWebsiteComplete = createAction(
  '[Website] Publish Website Complete',
  props<{ update: Update<Website> }>()
)

export const ToggleWebsiteOnlineBookings = createAction(
  '[Website] Toggle Website Online Bookings',
  props<{ websiteId: string; status: boolean }>()
)
export const ToggleWebsiteOnlineBookingsComplete = createAction(
  '[Website] Toggle Website Online Bookings Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteComponents = createAction(
  '[Website] Update Website Components',
  props<{ id: string; data: any }>()
)
export const UpdateWebsiteComponentsComplete = createAction(
  '[Website] Update Website Components Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteContact = createAction('[Website] Update Website Contact', props<{ id: string; data: any }>())
export const UpdateWebsiteContactComplete = createAction(
  '[Website] Update Website Contact Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteLinks = createAction('[Website] Update Website Links', props<{ id: string; data: any }>())
export const UpdateWebsiteLinksComplete = createAction(
  '[Website] Update Website Links Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteTemplateSettings = createAction(
  '[Website] Update Website Template Settings',
  props<{ id: string; data: any }>()
)
export const UpdateWebsiteTemplateSettingsComplete = createAction(
  '[Website] Update Website Template Settings Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteResources = createAction(
  '[Website] Update Website Resource',
  props<{ id: string; data: any }>()
)
export const UpdateWebsiteResourcesComplete = createAction(
  '[Website] Update Website Resource Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsiteRentals = createAction('[Website] Update Website Rentals', props<{ id: string; data: any }>())
export const UpdateWebsiteRentalsComplete = createAction(
  '[Website] Update Website Rentals Complete',
  props<{ update: Update<Website> }>()
)

export const DeleteWebsite = createAction('[Website] Delete Website', props<{ id: string }>())
export const DeleteWebsiteComplete = createAction('[Website] Delete Website Complete', props<{ id: string }>())

export const AddWebsitePage = createAction('[Website] Add Website Page', props<{ website: Website; data: any }>())
export const AddWebsitePageComplete = createAction(
  '[Website] Add Website Page Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsitePage = createAction(
  '[Website] Update Website Page',
  props<{ website: Website; pageId: string; data: any }>()
)
export const UpdateWebsitePageComplete = createAction(
  '[Website] Update Website Page Complete',
  props<{ update: Update<Website> }>()
)

export const UpdateWebsitePageStatus = createAction(
  '[Website] Update Website Page Status',
  props<{ website: Website; pageId: string; status: 0 | 1 }>()
)
export const UpdateWebsitePageStatusComplete = createAction(
  '[Website] Update Website Page Status Complete',
  props<{ update: Update<Website> }>()
)

export const DeleteWebsitePage = createAction(
  '[Website] Delete Website Page',
  props<{ website: Website; pageId: string }>()
)
export const DeleteWebsitePageComplete = createAction(
  '[Website] Delete Website Page Complete',
  props<{ update: Update<Website> }>()
)
