<div class="d-flex align-items-center gap-3 mb-3">
  <div class="d-flex align-items-center gap-2">
    <h6 class="mb-0">Commissions</h6>
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </div>
  <div class="flex-fill"></div>
  <div dropdown class="btn-group" [isDisabled]="(1 | isReadonlyRole) || (isEmptyTable$ | async)">
    <button dropdownToggle type="button" class="btn btn-secondary-warning dropdown-toggle">
      <span class="cs-icon cs-icon-bolt"></span> Actions
    </button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li>
        <button type="button" (click)="onCopy()"><i class="fal fa-copy"></i> Copy</button>
      </li>
      <li>
        <button (click)="onDeleteSelected()" type="button"><i class="cs-icon cs-icon-trash"></i> Delete</button>
      </li>
    </ul>
  </div>
  <button type="button" class="btn btn-secondary-info" [disabled]="1 | isReadonlyRole" (click)="onAdd()">
    <span class="fas fa-plus"></span> Add Commission
  </button>
</div>

<div class="container-box d-block">
  <div class="title-holder">
    <div class="">
      <div class="filters-box">
        <app-select-search
          [multiple]="false"
          [items]="connectedAccounts"
          [ctrl]="selectedAccountIdCtrl"
          placeholder="Account"
          [hasBlank]="true"
          blankLabel="All Accounts"
          blankValue=""
        ></app-select-search>
        <app-cancel-filters
          [isFiltering]="isFiltering"
          (clear)="onClearFilters()"
          [searchCom]="search"
        ></app-cancel-filters>
        <div class="flex-fill"></div>
        <app-table-search (search)="onSearch($event)" #search></app-table-search>
      </div>
      <div class="table-holder table-responsive">
        <table class="table table-default table-striped">
          <mat-table #table matSort matSortActive="name" matSortDirection="desc" [dataSource]="dataSource">
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  [checked]="selection.hasValue() && isAllSelected(false)"
                  [indeterminate]="selection.hasValue() && !isAllSelected(false)"
                  (change)="onMasterToggle($event?.checked)"
                  class="master-toggle"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="selection.toggle(element)"
                  [checked]="selection.isSelected(element)"
                  *ngIf="!element.isFeedApi"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="channel_account">
              <mat-header-cell *matHeaderCellDef> Account</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.channel_account }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.name }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Value </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.amount }}% </mat-cell>
            </ng-container>
            <ng-container matColumnDef="edit">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Edit</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
                  <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                    <a class="btn-ellips">
                      <i class="fas fa-ellipsis-v"></i>
                    </a>
                  </div>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <li>
                      <button type="button" (click)="onEdit(element)"><i class="fal fa-edit"></i> Edit</button>
                    </li>
                    <li>
                      <button type="button" (click)="onDelete(element)">
                        <i class="cs-icon cs-icon-trash"></i> Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
          <mat-paginator2
            #paginator
            [length]="dataSource?.data?.length"
            [class.hidden]="isEmptyTable$ | async"
            [pageSize]="15"
            [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator2>
        </table>
      </div>
    </div>
  </div>
</div>
