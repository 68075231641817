import { Pipe } from '@angular/core'
import { BookingFee, FeeModalityLabels } from '../store/fees/booking-fee'
import { TokeetCurrencyPipe } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { FeeTypes } from '@tokeet/cost-resolver'

@Pipe({
  name: 'feeString',
})
export class FeeStringPipe {
  constructor(private tokeetCurrencyPipe: TokeetCurrencyPipe) {}
  transform(fee: BookingFee, currency: string) {
    const amount =
      fee.type === FeeTypes.Percent ? `${fee.amount}%` : this.tokeetCurrencyPipe.transform(fee.amount, currency)
    return `${fee.name}, ${lodash.capitalize(fee.type)}, ${amount}, ${FeeModalityLabels[fee.modality]}`
  }
}
