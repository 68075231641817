import {
  LoadBookingFormulasComplete,
  RemoveBookingFormulaComplete,
  ToggleBookingFormulaStatusComplete,
  UpdateBookingFormulaComplete,
  CreateBookingFormulaComplete,
  RemoveBookingFormulasComplete,
  ToggleMultipleBookingFormulaStatusComplete,
} from './actions'
import { formulaEntityAdapter, FormulaState, initialState } from './state'
import { Action, createReducer, on } from '@ngrx/store'

const reducer = createReducer(
  initialState,
  on(RemoveBookingFormulaComplete, (state, { id }) => formulaEntityAdapter.removeOne(id, state)),
  on(RemoveBookingFormulasComplete, (state, { ids }) => formulaEntityAdapter.removeMany(ids, state)),
  on(ToggleBookingFormulaStatusComplete, (state, { id, status }) =>
    formulaEntityAdapter.updateOne(
      {
        id,
        changes: {
          status: status ? 1 : 0,
        },
      },
      state
    )
  ),
  on(ToggleMultipleBookingFormulaStatusComplete, (state, { updates }) =>
    formulaEntityAdapter.updateMany(updates, { ...state, isUpdating: false })
  ),
  on(CreateBookingFormulaComplete, (state, { item }) => formulaEntityAdapter.setOne(item, state)),
  on(UpdateBookingFormulaComplete, (state, { id, item }) =>
    formulaEntityAdapter.updateOne(
      {
        id,
        changes: item,
      },
      state
    )
  ),
  on(LoadBookingFormulasComplete, (state, { items }) =>
    formulaEntityAdapter.setAll(items, {
      ...state,
      isLoaded: true,
    })
  )
)

export function formulasReducer(state: FormulaState | undefined, action: Action) {
  return reducer(state, action)
}

export const {
  selectIds: selectFormulasIds,
  selectEntities: selectFormulasEntities,
  selectAll: selectFormulasAll,
  selectTotal: selectFormulasTotal,
} = formulaEntityAdapter.getSelectors()
