import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss'],
})
export class TaskHistoryComponent {
  @Input() taskId: string
}
