import { BDCLikePropertyContacts } from '@tv3/store/channel-property-setting/settings/common'

export interface AgodaAdditionalFee {
  code: number
  amount: number
}

export interface AgodaContentPropertySettings {
  contacts: BDCLikePropertyContacts
  license?: string
  localRecommendations?: string
  fees: AgodaAdditionalFee[]
}

export const agodaBedTypeOptions = [
  // http://content-push-sandbox.agoda.com/cm/codes/bed-types
  { index: 1, label: 'Single Bed' },
  { index: 2, label: 'Semi Double-Bed' },
  { index: 3, label: 'Double Bed' },
  { index: 4, label: 'Queen Bed' },
  { index: 5, label: 'King Bed' },
  { index: 6, label: 'Super King Bed' },
  { index: 7, label: 'Bunk Bed' },
  { index: 8, label: 'Sofa Bed' },
  { index: 9, label: 'Japanese Futon' },
  { index: 10, label: 'Male Capsule' },
  { index: 11, label: 'Female Capsule' },
  { index: 12, label: 'Baby cot' },
]

export enum AgodaAdditionalFeeCodes {
  Cleaning = 116,
  Facility = 342,
}

export function isAgodaBedTypeAmenityCode(code: any) {
  return code <= 12
}

export const availablePropertyAmenitiesTokeetIds = [
  '24hr-checkin',
  'beach_view',
  'beachfront',
  'free_breakfast',
  'car_rental',
  'doorman',
  'dry_cleaning',
  'fireplace',
  'fitness_center',
  'non_smoking',
  'room_service',
  'shuttle',
  'swimming_pool',
  'tennis_court',
  'valet_parking',
  'wheelchair_access',
  'garden',
  'beach',
  'private_beach',
  'indoor_pool',
  'massage',
]
export const availableRoomAmenitiesTokeetIds = [
  'central_air_conditioning',
  'hair-dryer',
  'game_room',
  'heating',
  'iron_board',
  'non_smoking',
  'pets_allowed',
  'shampoo',
  'swimming_pool',
  'television',
  'wheelchair_access',
  'terrace',
  'indoor_pool',
  'bathtub',
  'desk',
  'dishwasher',
]
