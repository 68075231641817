<div class="modal-header">
  <h5 class="modal-title">
    <i class="fal fa-file-alt"></i> Select Invoice Template
    <i
      matTooltip="Select invoice template you want to apply to a booking."
      matTooltipPosition="above"
      class="fal fa-question-circle"
    ></i>
  </h5>
  <button (click)="onClose()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" [formEditablePermission]="form">
    <app-booking-select
      [ctrl]="form.get('bookingId')"
      [required]="true"
      *ngIf="data?.selectBooking"
    ></app-booking-select>
    <app-select-search
      [items]="templates$ | async"
      bindValue="pkey"
      bindLabel="name"
      [inline]="false"
      [ctrl]="form.get('template')"
      [multiple]="false"
      [hasBlank]="false"
      matTooltip="Click here to select the invoice template that will be applied to a booking."
      matTooltipPosition="above"
      placeholder="Invoice Template"
    >
      <ng-container class="error-container">
        <mat-error> Invoice Template is <strong>required</strong> </mat-error>
      </ng-container>
    </app-select-search>
  </form>
</div>
<div class="modal-footer">
  <button (click)="onClose()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-bolt"></i> apply
  </button>
</div>
