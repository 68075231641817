import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  Destroyable,
  selectAllRentals,
  untilDestroy,
  ActionFailed,
  Rental,
  RentalPaymentSchedule,
  PaymentScheduleType,
  paymentScheduleTypeItems,
  hasPaymentScheduleDaysOption,
} from '@tokeet-frontend/tv3-platform'
import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'

export type PaymentScheduleItem = RentalPaymentSchedule[PaymentScheduleType] & { type: PaymentScheduleType }

@Component({
  selector: 'app-select-payment-schedules-table',
  templateUrl: './select-payment-schedules-table.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class SelectPaymentSchedulesTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator
  }
  @Input() excludedRentalIds: string[] = []
  @Input() set selectedRentalId(rentalId: string) {
    this.rentalCtrl.setValue(rentalId)
  }

  @Output() selected = new EventEmitter<PaymentScheduleItem[]>()
  scheduleLabels = lodash.keyBy(paymentScheduleTypeItems, (t) => t.value)
  displayedColumns: string[] = ['select', 'name']

  dataSource = new MatTableDataSource<PaymentScheduleItem>()
  selection = new SelectionModel<PaymentScheduleItem>(true, [])

  rentals$ = this.store.pipe(
    select(selectAllRentals),
    map((rentals) => lodash.filter(rentals, (r) => !this.excludedRentalIds.includes(r.id)))
  )
  rentals: Rental[] = []
  isLoading = false
  rentalCtrl = new FormControl()

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    this.isLoading = true
    this.rentals$.pipe(untilDestroy(this)).subscribe(
      (rentals) => {
        this.rentals = rentals
        this.refreshTableData(this.rentalCtrl.value)
      },
      (error) => {
        this.store.dispatch(ActionFailed({ error }))
      }
    )
    this.rentalCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((rentalId) => {
      this.refreshTableData(rentalId)
    })
    this.selection.changed.pipe(untilDestroy(this)).subscribe(() => {
      this.selected.emit(this.selection.selected)
    })
  }

  hasDaysOption(type: PaymentScheduleType) {
    return hasPaymentScheduleDaysOption(type)
  }

  scheduleToItems(schedule: RentalPaymentSchedule) {
    return lodash.map(schedule || {}, (value, key: PaymentScheduleType) => ({ type: key, ...value }))
  }

  getSchedules(rentalId: string) {
    return lodash.flatten(
      lodash.map(
        lodash.filter(this.rentals, (r) => rentalId == r.id),
        (r) => this.scheduleToItems(r.payment_schedule)
      )
    )
  }

  refreshTableData(rentalId?: string) {
    this.dataSource.data = this.getSchedules(rentalId)
  }

  onMasterChange(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource.data)
    } else {
      this.selection.clear()
    }
  }

  isMasterChecked() {
    this.selection.selected.length === this.dataSource.data.length
  }

  onSearch(terms: string) {
    this.dataSource.filter = terms
  }

  onSelect(item: PaymentScheduleItem, checked: boolean) {
    if (checked) {
      this.selection.select(item)
    } else {
      this.selection.deselect(item)
    }
  }
}
