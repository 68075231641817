import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AirbnbChannelService, AirbnbReviewSubmittedResponse } from '@tokeet-frontend/channels'
import { SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-abb-reply-guest-review-dialog',
  templateUrl: './abb-reply-guest-review-dialog.component.html',
  styleUrls: ['./abb-reply-guest-review-dialog.component.scss'],
})
export class AbbReplyGuestReviewDialogComponent implements OnInit {
  form = this.fb.group({
    message: ['', [Validators.required]],
  })

  isRespondable = false
  isLoading = false
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AbbReplyGuestReviewDialogComponent>,
    private airbnbChannelService: AirbnbChannelService,
    private toaster: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { review: AirbnbReviewSubmittedResponse; propertyId: string; roomId: string }
  ) {}

  ngOnInit(): void {
    const review = this.data.review
    this.form.patchValue({ message: review.reviewee_response })
    this.isRespondable = !review.is_expired && !review.submitted && !review.is_responded
    if (!this.isRespondable) {
      this.form.disable()
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { message } = form.getRawValue()

    this.isLoading = true
    this.airbnbChannelService
      .respondReview(this.data.roomId, this.data.review.id, message)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Response saved successfully')
          this.dialogRef.close()
        },
        (error) => {
          this.toaster.error('Unable to save response to Airbnb', null, error)
        }
      )
  }
}
