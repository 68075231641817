<form>
  <div class="modal-header">
    <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i> Import Guests from vCard Files</h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <app-upload-box
      [options]="uploadBoxOptions"
      image="/assets/images/upload-files.svg"
      [showFileList]="false"
      (afterAddingFile)="onAfterAddingFile($event)"
      #uploadBox
    ></app-upload-box>
    <div class="upload-guide mb-3">
      <div class="my-3" [innerHtml]="guide?.description"></div>
      <label class="text-bold">Required columns</label>
      <ul class="text-lg p-0 d-flex gap-2">
        <li *ngFor="let item of requiredColumns" class="badge text-light bg-default">{{ item.label }}</li>
      </ul>
      <a href="javascript:;" (click)="downloadSample()" class="btn btn-secondary-primary">
        <i class="cs-icon cs-icon-download"></i>
        Download sample vCard
      </a>
    </div>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let file of uploadBox?.files; let i = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ file.file.name | truncate: 30 }}
            <small>(<i class="fal fa-hand-point-up"></i> Preview)</small>
          </mat-panel-title>
          <mat-panel-description>
            <div class="flex-fill"></div>
            <span
              *ngIf="file.isError"
              class="text-danger mr-2"
              [matTooltip]="getFileErrorText(file)"
              matTooltipPosition="above"
            >
              <i class="fal fa-exclamation-triangle"></i>
            </span>
            <button
              class="btn btn-sm btn-light-danger"
              (click)="uploadBox?.onRemoveFile(file, i)"
              matTooltip="Remove file"
              matTooltipPosition="above"
            >
              <i class="cs-icon cs-icon-trash"></i>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="table-responsive" *ngIf="file.items">
          <table class="table table-condensed" aria-hidden="false">
            <thead>
              <tr>
                <th class="icon"></th>
                <th *ngFor="let col of columnDefs" [style.width]="col.width">{{ col.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of file.items">
                <td class="icon">
                  <span
                    class="icon-container"
                    [matTooltip]="getItemErrorText(item)"
                    matTooltipPosition="above"
                    matTooltipClass="text-left pre-line"
                  >
                    <i
                      class="fal fa-exclamation-triangle"
                      [ngClass]="{
                        'fa-info-circle text-warning': !item.isError && item.isWarning,
                        'fa-exclamation-triangle text-danger': item.isError
                      }"
                    ></i>
                  </span>
                </td>
                <td *ngFor="let col of columnDefs; let i = index">
                  {{ item.item | fieldOrPath: col.field }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button
      (click)="onCreate(uploadBox?.files)"
      [disabled]="!uploadBox?.files?.length"
      type="button"
      class="btn btn-secondary-info create-btn"
    >
      <i class="cs-icon cs-icon-upload"></i> upload
    </button>
  </div>
</form>
