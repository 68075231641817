import { Message } from './message.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Message> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
