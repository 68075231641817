import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SSLService } from './ssl.service'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { CustomDomainEffects } from './ssl.effects'
import { reducer } from './ssl.reducer'

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([CustomDomainEffects]),
    StoreModule.forFeature('CustomDomains', reducer),
  ],
  declarations: [],
  providers: [SSLService],
  exports: [],
})
export class SSLModule {}
