declare const window: any

export function downloadBlob(blob, fileName) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const elem = window.document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = fileName
    elem.target = '_blank'
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }
}

export function downloadCSV(csvString, fileName = 'download.csv') {
  const blob = new Blob(
    [
      new Uint8Array([0xef, 0xbb, 0xbf]), // TV3-744, https://stackoverflow.com/a/41363077
      csvString,
    ],
    {
      type: 'text/csv;charset=utf-8;',
    }
  )

  downloadBlob(blob, fileName)
}
