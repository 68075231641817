import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { TokeetImage } from '@tv3/store/website-image/website-image.model'

export interface State extends EntityState<TokeetImage> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<TokeetImage> = createEntityAdapter<TokeetImage>()

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
