import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import {
  AddWebsitePage,
  AddWebsitePageComplete,
  DeleteWebsitePage,
  DeleteWebsitePageComplete,
  ToggleWebsiteOnlineBookings,
  ToggleWebsiteOnlineBookingsComplete,
  UpdateWebsiteComponents,
  UpdateWebsiteComponentsComplete,
  UpdateWebsiteContact,
  UpdateWebsiteContactComplete,
  UpdateWebsiteLinks,
  UpdateWebsiteLinksComplete,
  UpdateWebsitePage,
  UpdateWebsitePageComplete,
  UpdateWebsitePageStatus,
  UpdateWebsitePageStatusComplete,
  UpdateWebsiteRentals,
  UpdateWebsiteRentalsComplete,
  UpdateWebsiteResources,
  UpdateWebsiteResourcesComplete,
  UpdateWebsiteTemplateSettings,
  UpdateWebsiteTemplateSettingsComplete,
} from './website.actions'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { WebsiteService } from './website.service'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Router } from '@angular/router'
import { AlertDialogService, Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { WebsitePageSettings } from '@tv3/store/website/website.types'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { DefaultPaymentGateway, GatewaysService, PaymentGatewayServiceIdentities } from '@tokeet-frontend/gateways'

@Injectable()
export class WebsiteFieldEffects {
  @Effect()
  toggleOnlineBookings$ = this.actions$.pipe(
    ofType(ToggleWebsiteOnlineBookings),
    switchMap(({ websiteId, status }) => {
      const enableOnlineBooking = () => {
        return this.gatewaysService.getDefault().pipe(
          switchMap((defaultPaymentGateway: DefaultPaymentGateway) => {
            // If the default is a stripe gateway, then allow it.
            if (defaultPaymentGateway.service === PaymentGatewayServiceIdentities.Stripe) {
              return this.websiteService.enableOnlineBookings(websiteId).pipe(
                tap(() => this.toaster.success('Bookings enabled successfully.')),
                map((res) =>
                  ToggleWebsiteOnlineBookingsComplete({ update: { id: websiteId, changes: { engineEnabled: 1 } } })
                )
              )
            } else {
              // Otherwise don't allow it.
              return this.alertDialog
                .openResult({
                  title: 'You need a Stripe gateway',
                  body: `You must connect a Stripe gateway and set it as your default
                      gateway before you can enable online bookings. Online bookings
                      currently only work with Stripe.`,
                })
                .pipe(
                  map((res) =>
                    ToggleWebsiteOnlineBookingsComplete({ update: { id: websiteId, changes: { engineEnabled: 0 } } })
                  )
                )
            }
          })
        )
      }
      const disableOnlineBooking = () => {
        return this.websiteService.disableOnlineBookings(websiteId).pipe(
          tap(() => this.toaster.success('Bookings disable successfully.')),
          map((res) =>
            ToggleWebsiteOnlineBookingsComplete({ update: { id: websiteId, changes: { engineEnabled: 0 } } })
          )
        )
      }
      return (status ? enableOnlineBooking() : disableOnlineBooking()).pipe(
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateRentals$ = this.actions$.pipe(
    ofType(UpdateWebsiteRentals),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Website rentals updated successfully.')),
        map((website) => UpdateWebsiteRentalsComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateResources$ = this.actions$.pipe(
    ofType(UpdateWebsiteResources),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Website resources updated successfully.')),
        map((website) => UpdateWebsiteResourcesComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateContact$ = this.actions$.pipe(
    ofType(UpdateWebsiteContact),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Contact information updated successfully.')),
        map((website) => UpdateWebsiteContactComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  updateComponents$ = this.actions$.pipe(
    ofType(UpdateWebsiteComponents),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Website components updated successfully.')),
        map((website) => UpdateWebsiteComponentsComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateLinks$ = this.actions$.pipe(
    ofType(UpdateWebsiteLinks),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Website links updated successfully.')),
        map((website) => UpdateWebsiteLinksComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateTemplateSettings$ = this.actions$.pipe(
    ofType(UpdateWebsiteTemplateSettings),
    switchMap(({ id, data }) =>
      this.websiteService.update(id, data).pipe(
        tap(() => this.toaster.success('Website settings updated successfully.')),
        map((website) => UpdateWebsiteTemplateSettingsComplete({ update: { id, changes: website } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addWebsitePage$ = this.actions$.pipe(
    ofType(AddWebsitePage),
    switchMap(({ website, data }) =>
      this.websiteService.addPage(website.id, data).pipe(
        tap(() => this.toaster.success('New page added successfully.')),
        map((page) => {
          return AddWebsitePageComplete({ update: { id: website.id, changes: { pages: [page, ...website.pages] } } })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateWebsitePage$ = this.actions$.pipe(
    ofType(UpdateWebsitePage),
    switchMap(({ website, pageId, data }) =>
      this.websiteService.updatePage(website.id, data).pipe(
        tap(() => this.toaster.success('Page updated successfully.')),
        map((page: WebsitePageSettings) =>
          R.pipe(
            R.filter((p: WebsitePageSettings) => p.id !== page.id),
            R.append(page)
          )(website.pages)
        ),
        map((pages) => UpdateWebsitePageComplete({ update: { id: website.id, changes: { pages } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateWebsitePageStatus$ = this.actions$.pipe(
    ofType(UpdateWebsitePageStatus),
    switchMap(({ website, pageId, status }) =>
      this.websiteService.updatePageStatus(website.id, pageId, status).pipe(
        tap(() => this.toaster.success('Page updated successfully.')),
        map((res) => {
          let pages = website.pages || []
          const pageIndex = R.findIndex((p: WebsitePageSettings) => p.id === pageId, website.pages || [])
          if (pageIndex > -1) {
            let page = pages[pageIndex]
            page = page.clone({ status })
            pages = [...pages.slice(0, pageIndex), page, ...pages.slice(pageIndex + 1)]
          }
          return UpdateWebsitePageStatusComplete({ update: { id: website.id, changes: { pages } } })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteWebsitePage$ = this.actions$.pipe(
    ofType(DeleteWebsitePage),
    switchMap(({ website, pageId }) =>
      this.websiteService.deletePage(website.id, pageId).pipe(
        tap(() => this.toaster.success('Page deleted successfully.')),
        map((res) => {
          const newPages = R.filter((p: WebsitePageSettings) => p.id !== pageId, website.pages || [])
          return DeleteWebsitePageComplete({ update: { id: website.id, changes: { pages: newPages } } })
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private toaster: Toaster,
    private alertDialog: AlertDialogService,
    private store: Store<fromRoot.State>,
    private websiteService: WebsiteService,
    private gatewaysService: GatewaysService
  ) {}
}
