import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { CURRENT_ACCOUNT_ID } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-connect-vrbo-wizard-complete',
  templateUrl: './connect-vrbo-wizard-complete.component.html',
  styleUrls: ['./connect-vrbo-wizard-complete.component.scss'],
})
export class ConnectVrboWizardCompleteComponent implements OnInit {
  @Output() reset = new EventEmitter()
  @Output() channel = new EventEmitter()
  @Output() done = new EventEmitter()

  get prefillUrl() {
    const prefillUrl = `https://www.vrbo.com/p/onboard?prefill=${btoa(
      `softwareId=TOKEET&advertiserAssignedId=${lodash.toString(this.accountId$.value).replace('.', '-')}`
    )}`
    return prefillUrl
  }

  constructor(@Inject(CURRENT_ACCOUNT_ID) private accountId$: BehaviorSubject<number>) {}

  ngOnInit(): void {}
}
