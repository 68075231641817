import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './rental.reducer'
import * as state from './rental.state'
import * as R from 'ramda'
import { Rental } from './rental.models'

export const rentalState = createFeatureSelector<state.State>('rental')

export const selectRentalsLoaded = createSelector(rentalState, (s: state.State) => s.isLoaded)

export const selectRentalImagesLoaded = createSelector(rentalState, (s: state.State) => s.isImageLoaded)

export const selectIsPrimaryImagesLoaded = createSelector(
  rentalState,
  (state: state.State) => state.isPrimaryImagesLoaded
)

export const selectRentalsSubDomainLoaded = createSelector(rentalState, (s: state.State) => !R.isNil(s.subdomain))

export const selectRentalEntities = createSelector(rentalState, reducer.selectEntities)

export const selectAllRentals = createSelector(rentalState, reducer.selectAll)

export const selectUnarchivedRentals = createSelector(selectAllRentals, (rentals: Rental[]) =>
  R.filter((r) => !r.archived, rentals)
)

export const selectImageCountsLoaded = createSelector(rentalState, (state: state.State) => state.imageCountsLoaded)
export const selectRentalRatesLoaded = createSelector(rentalState, (state: state.State) => state.isRatesLoaded)

export const selectAllRentalsWithArchived = createSelector(rentalState, (state) =>
  R.concat(R.values(state.entities), state.archivedRentals)
)

export const selectRentalById = (id: string) =>
  createSelector(selectRentalEntities, (entities) => new Rental(entities[id]))

export const selectAllRentalTags = createSelector(selectAllRentals, (rentals) => {
  return R.uniq(R.flatten(R.map((rental) => rental.tags || [], rentals))).sort()
})

export const selectSubdomain = createSelector(rentalState, (state) => state.subdomain)

export const isSavingRental = createSelector(rentalState, (state) => state.isSavingRental)

export const isUpdatingOwners = createSelector(rentalState, (state) => state.isUpdatingOwners)

export const isRestrictingUsers = createSelector(rentalState, (state) => state.isRestrictingUsers)

export const isSavingTags = createSelector(rentalState, (state) => state.isSavingTags)

export const isSavingCustom = createSelector(rentalState, (state) => state.isSavingCustom)

export const isSavingDetailInfo = createSelector(rentalState, (state) => state.isSavingDetailInfo)

export const isSavingRentalInstructions = createSelector(rentalState, (state) => state.isSavingRentalInstructions)

export const isSavingRentalBaseRate = createSelector(rentalState, (state) => state.isSavingBaseRate)

export const selectedRentalId = createSelector(rentalState, (state) => state.selectedId)

export const selectRentalCities = createSelector(selectAllRentals, (rentals) => {
  return R.pipe(
    R.map((r: Rental) => R.pathOr('', ['address', 'city'], r)),
    R.reject(R.isNil),
    R.reject(R.isEmpty),
    R.uniq,
    R.sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(rentals)
})

export const selectRentalTags = createSelector(selectAllRentals, (rentals): string[] => {
  return R.pipe(
    R.map((r: Rental) => R.pathOr([], ['tags'], r)),
    R.flatten,
    R.reject(R.isNil),
    R.reject(R.isEmpty),
    R.uniq,
    R.sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(rentals)
})

export const selectRentalCitiesWithArchived = createSelector(rentalState, (state) => {
  return R.pipe(
    R.map((r: Rental) => R.pathOr('', ['address', 'city'], r)),
    R.reject(R.isNil),
    R.reject(R.isEmpty),
    R.uniq,
    R.sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(R.concat(R.values(state.entities), state.archivedRentals))
})

export const selectRentalTagsWithArchived = createSelector(rentalState, (state) => {
  return R.pipe(
    R.map((r: Rental) => R.pathOr([], ['tags'], r)),
    R.flatten,
    R.reject(R.isNil),
    R.reject(R.isEmpty),
    R.uniq,
    R.sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(R.concat(R.values(state.entities), state.archivedRentals))
})
