import { Flight } from '@tv3/models/itinerary/flight'
import { Serializable, Expose, deserialize, Type } from '@tokeet-frontend/tv3-platform'

export class Itinerary extends Serializable<Itinerary> {
  @Expose({ name: 'booking_id' })
  bookingId: string

  @Expose({ name: 'pkey' })
  id: string

  account: number
  created: number
  archived: number

  @Type(() => Flight)
  flights: Flight[]

  static deserialize(data: any): Itinerary {
    return deserialize(Itinerary, data)
  }
}
