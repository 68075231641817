import { Injectable } from '@angular/core'

import { environment } from '../environments/environment'

export interface IAppConfig {
  apiUrl: string
  signatureApiUrl: string
  config: { [id: string]: string }
}

@Injectable({ providedIn: 'root' })
export class AppConfig implements IAppConfig {
  environment: string
  production: boolean
  apiUrl: string
  signatureApiUrl: string
  config: { [id: string]: string }

  constructor() {
    Object.assign(this, environment)
  }
}
