import { Observable, of } from 'rxjs'
import { CanActivate, CanLoad, Route } from '@angular/router'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators'
import * as fromRoot from '@tv3/store/state'
import { LoadGuests } from '@tv3/store/guest/guest.actions'
import { selectGuestsLoaded, selectGuestsPagination } from '@tv3/store/guest/guest.selectors'
import { IsDataLoaded } from '@tokeet-frontend/tv3-platform'

@Injectable({ providedIn: 'root' })
export class GuestGuard implements CanActivate, CanLoad, IsDataLoaded {
  constructor(private store: Store<fromRoot.State>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return this.store.pipe(
      select(selectGuestsLoaded),
      withLatestFrom(this.store.pipe(select(selectGuestsPagination))),
      tap(([isLoaded, pagination]) => {
        if (!isLoaded && pagination.hasMore) {
          this.store.dispatch(LoadGuests({ pagination }))
        }
      }),
      map(() => true),
      take(1)
    )
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
