import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromItinerary from './itinerary.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ItineraryEffects } from './itinerary.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('itinerary', fromItinerary.itineraryReducer),
    EffectsModule.forFeature([ItineraryEffects]),
  ],
  declarations: [],
})
export class ItineraryModule {}
