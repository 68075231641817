import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
  Channel,
  Destroyable,
  Rental,
  rentalDetails,
  rentalQuantities,
  RentalService,
  SaveForm,
  Toaster,
  untilDestroy,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { rentalTypes } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as lodash from 'lodash'
import * as R from 'ramda'
import { BDCContentProperty, BDCContentPropertyRoom } from '@tv3/store/channel-content/models/bdc.model'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { select, Store } from '@ngrx/store'
import { BDCRoomCreateComplete, BDCRoomUpdateComplete } from '@tv3/store/channel-content/conent.actions'
import { delay, mapTo, switchMap, take, tap } from 'rxjs/operators'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { Actions, ofType } from '@ngrx/effects'
import {
  availableRoomAmenitiesTokeetIds,
  bdcMaxGuestsCount,
} from '@tv3/store/channel-property-setting/settings/bdc-property-settings'
import { selectNewAvailableChannelIdForNewApiConnection } from '@tv3/store/connection/connection.selectors'
import { BDCContentRoomResponse } from '@tv3/store/channel-content/payload/bdc.response'
import {
  RefreshConnectionsForChannel,
  RefreshConnectionsForChannelComplete,
} from '@tv3/store/connection/connection.actions'
import { createForm as createAmenitiesForm } from '@tv3/containers/channels/content/bdc/property/step-forms/step-amenities/content-step-amenities-form.component'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-bdc-content-room-setup',
  templateUrl: './bdc-content-room-setup.component.html',
  styleUrls: ['./bdc-content-room-setup.component.scss'],
})
export class BdcContentRoomSetupComponent extends Destroyable implements OnInit, OnChanges {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() bdcProperty: BDCContentProperty

  get bdcRoom(): BDCContentPropertyRoom {
    return this.bdcProperty && lodash.get(this.bdcProperty.rooms, 0)
  }

  quantities = rentalQuantities.slice(0, bdcMaxGuestsCount)
  types = rentalTypes
  bedTypeOptions = [
    { label: 'Double Bed', index: 33 },
    { label: 'Extra large Double Bed', index: 58 },
    { label: 'Large Double Bed', index: 86 },
    { label: 'Sofa Bed', index: 102 },
    { label: 'Twin Bed', index: 113 },
    { label: 'Futon Mattress', index: 200 },
    { label: 'Single Bed', index: 203 },
    { label: 'Bunk Bed', index: 4001 },
  ]
  rentalAmenityDetails = rentalDetails.filter((item) => availableRoomAmenitiesTokeetIds.indexOf(item.id) > -1)

  baseRoomForm = this.fb.group({
    type: [undefined, [Validators.required]],
    name: [undefined, [Validators.required]],
    maxOccupancy: [undefined, [Validators.required]],
    sizeMeasurement: [undefined, [Validators.required]],
    sizeMeasurementUnit: [undefined, [Validators.required]],
    nonSmoking: [],
    license: [],
  })

  bedrooms = this.fb.array([])
  roomForm = this.fb.group({
    base: this.baseRoomForm,
    bedrooms: this.bedrooms,
  })

  rentalAmenitiesForm = createAmenitiesForm(this.fb)
  selectedAmenitiesCount$ = new BehaviorSubject(0)

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    private actions: Actions,
    private store: Store<any>,
    private rentalService: RentalService,
    private connectionService: ConnectionService,
    private bdcContentService: BdcContentService
  ) {
    super()
  }

  ngOnInit() {
    this.rentalAmenitiesForm.valueChanges
      .pipe(
        tap(({ amenities }) => {
          this.selectedAmenitiesCount$.next(amenities.filter((d) => d).length)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const bdcPropertyChange = changes['bdcProperty']
    if (bdcPropertyChange && bdcPropertyChange.currentValue) {
      this.setBaseRoomForm(bdcPropertyChange.currentValue)
      this.setBedroomsForm(bdcPropertyChange.currentValue)
    }
  }

  setBaseRoomFormByRental(rental: Rental) {
    this.baseRoomForm.reset()
    this.baseRoomForm.patchValue({
      type: rental.type,
      maxOccupancy: rental.sleepMax,
      sizeMeasurement: rental.size,
      sizeMeasurementUnit: rental.sizeMetric && lodash.toLower(rental.sizeMetric),
    })
  }

  setBaseRoomForm(property: BDCContentProperty) {
    if (!this.bdcRoom) {
      this.setBaseRoomFormByRental(this.rental)
      return
    }
    this.baseRoomForm.reset()
    this.baseRoomForm.patchValue(this.bdcRoom)
  }

  setBedroomsForm(property: BDCContentProperty) {
    let bedrooms = (this.bdcRoom && this.bdcRoom.bedrooms) || []
    if (!this.bdcRoom || !bedrooms || !bedrooms.length) {
      // @ts-ignore
      bedrooms = [{}]
    }
    this.bedrooms.clear()
    lodash.forEach(bedrooms, (bedroom) => {
      const form = this.getNewBedroomForm()
      form.patchValue(bedroom)
      this.bedrooms.push(form)
    })
  }

  getNewBedroomForm() {
    return this.fb.group({
      roomType: [undefined, [Validators.required]],
      privateBathroom: [undefined, [Validators.required]],
      bedType: [undefined, [Validators.required]],
      maxGuests: [undefined, [Validators.required]],
    })
  }

  addOneBedroom() {
    this.bedrooms.push(this.getNewBedroomForm())
  }

  removeOneBedroom(idx: number) {
    this.bedrooms.removeAt(idx)
  }

  linkWithRental(roomData: BDCContentRoomResponse) {
    return this.store.pipe(select(selectNewAvailableChannelIdForNewApiConnection, { id: this.channel.id })).pipe(
      take(1),
      switchMap((channelId) =>
        this.connectionService.link({
          channelId,
          propertyId: roomData.propertyId,
          roomId: roomData.roomId,
          rentalId: this.rental.id,
        })
      )
    )
  }

  getSelectedAmenities() {
    const { amenities: rentalDetailCheckData } = this.rentalAmenitiesForm.getRawValue()
    const details = {
      ...this.rental.detail,
    }
    rentalDetailCheckData.forEach((value, i) => (details[this.rentalAmenityDetails[i].id] = !!value))
    return lodash.reduce(
      details,
      (acc, value, key) => {
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
  }

  saveRentalData() {
    const details = this.getSelectedAmenities()
    // @ts-ignore
    return this.rentalService.update(this.rental.id, { detail: details }).pipe(
      tap((rental: Rental) =>
        this.store.dispatch(
          updateRentalComplete({
            update: {
              id: rental.id,
              changes: rental,
            },
          })
        )
      )
    )
  }

  createRoom({ base, bedrooms }) {
    this.saveRentalData()
      .pipe(
        switchMap(() =>
          this.bdcContentService.createRoom(this.bdcProperty.propertyId, {
            ...base,
            bedrooms,
            propertyId: this.bdcProperty.propertyId,
            rentalId: this.rental.id,
          })
        ),
        delay(500), // wait for backend refresh
        tap(() =>
          this.store.dispatch(
            RefreshConnectionsForChannel({
              channelName: this.channel.name,
              channelId: this.channel.id,
              silent: true,
            })
          )
        ),
        switchMap((data) => {
          return this.actions.pipe(ofType(RefreshConnectionsForChannelComplete), mapTo(data))
        }),
        switchMap((roomData: BDCContentRoomResponse) => this.linkWithRental(roomData).pipe(mapTo(roomData)))
      )
      .subscribe(({ propertyId, roomId, warnings }) => {
        this.store.dispatch(BDCRoomCreateComplete({ propertyId, roomId }))
        this.toaster.success('Room created successfully.')
        if (warnings && warnings.length) {
          this.toaster.warning('Room Warnings: ' + warnings.join('\n'))
        }
      })
  }

  updateRoom({ base, bedrooms }) {
    this.saveRentalData()
      .pipe(
        switchMap(() =>
          this.bdcContentService.updateRoom(this.bdcProperty.propertyId, this.bdcRoom.id, {
            ...base,
            bedrooms,
            propertyId: this.bdcProperty.propertyId,
            roomId: this.bdcRoom.id,
            rentalId: this.rental.id,
          })
        )
      )
      .subscribe(({ propertyId, roomId, warnings }) => {
        this.store.dispatch(BDCRoomUpdateComplete({ propertyId, roomId }))
        this.toaster.success('Room updated successfully.')
        if (warnings && warnings.length) {
          this.toaster.warning('Room Warnings: ' + warnings.join('\n'))
        }
      })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.roomForm.getRawValue()
    if (this.bdcRoom && this.bdcRoom.id) {
      this.updateRoom(formData)
    } else {
      this.createRoom(formData)
    }
  }
}
