export function fixDropdownClose() {
  const handler = (event: MouseEvent) => {
    const dropdown = getTargetElement(event.target, (elm) => elm.hasAttribute('dropdowntoggle'))
    if (!dropdown) return

    const elm = getTargetElement(dropdown, (elm) => elm.tagName === 'MAT-CELL')
    if (!elm) return

    closeDropdown(dropdown)
  }

  document.addEventListener('click', handler, true)

  return () => {
    document.removeEventListener('click', handler, true)
  }
}

function closeDropdown(currentDropdown: Element) {
  const elms = document.querySelectorAll('.open[dropdown] [dropdowntoggle]')

  elms.forEach((elm) => {
    if (elm === currentDropdown) return
    ;(elm as HTMLElement).click?.()
  })
}

function getTargetElement(target: EventTarget | null, check: (elm: Element) => boolean): Element | null {
  const node = target as Node

  if (node && node.nodeType === Node.ELEMENT_NODE) {
    const element = node as Element
    if (check(element)) return element
  }

  if (!node.parentNode) return null
  return getTargetElement(node.parentNode, check)
}
