import { AbstractControl } from '@angular/forms'
import * as lodash from 'lodash'

export function airbnbDiscountValidator(min = 0, max = 100, equalMin = false, equalMax = false) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value
    if (!lodash.isNumber(value)) return null
    if (value < min || (!equalMin && value === min)) {
      return { min }
    }
    if (value >= max || (!equalMax && value === max)) {
      return { max }
    }
  }
}
