import { CalendarEvent } from './calendar.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import * as R from 'ramda'

export interface State extends EntityState<CalendarEvent> {
  isLoading: boolean
  isLoaded: boolean
  isHoldLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<CalendarEvent> = createEntityAdapter<CalendarEvent>({
  selectId: (event: CalendarEvent) => R.path<string>(['id'], event) || R.path<string>(['bookingId'], event), // there is no id for booking event, we use booking id as entity id.
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  isHoldLoaded: false,
  isUpdating: false,
})
