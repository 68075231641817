<ng-container [ngSwitch]="sentWithString">
  <ng-container *ngSwitchCase="'automata'">
    <span matTooltip="Message sent via Automata" matTooltipPosition="above" class="via"
      >/ via <img src="//cdn.tokeet.com/icons/automata_icon.png" alt=""
    /></span>
  </ng-container>
  <ng-container *ngSwitchCase="'tokeet'"></ng-container>
  <ng-container *ngSwitchCase="'sympl'"></ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="getMessageChannel | call: sentWithString; let channel; else: unknown">
      <span [matTooltip]="'Message sent via ' + channel.label" class="via"
        >/ via <img [src]="channel.logo" alt=""
      /></span>
    </ng-container>

    <ng-template #unknown>
      <span class="via" *ngIf="!!sentWithString"
        >/ via <span [ngClass]="'badge-' + badgeType" class="badge text-capitalize">{{ sentWithString }}</span></span
      >
    </ng-template>
  </ng-container>
</ng-container>
