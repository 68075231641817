import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { AirbnbChannelService, AirbnbOpportunityInputField, AirbnbOpportunityItem } from '@tokeet-frontend/channels'
import { Destroyable, SaveForm, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Connection } from '@tv3/store/connection/connection.model'
import { selectConnectionsByRealChannelId } from '@tv3/store/connection/connection.selectors'
import * as lodash from 'lodash'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-abb-opportunity-dialog',
  templateUrl: './abb-opportunity-dialog.component.html',
  styleUrls: ['./abb-opportunity-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AbbOpportunityDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    listing_ids: [[], [Validators.required]],
  })
  isLoading = false
  connections: Connection[] = []
  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    public dialogRef: MatDialogRef<AbbOpportunityDialogComponent>,
    private airbnbChannelService: AirbnbChannelService,
    private toaster: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { opportunity: AirbnbOpportunityItem; channelId: string; propertyId: string }
  ) {
    super()
  }

  ngOnInit(): void {
    lodash.forEach(this.data.opportunity.input_fields, (field) => {
      const ctrl = new FormControl(undefined, this.getValidators(field))
      this.form.addControl(lodash.snakeCase(field.key), ctrl)
    })
    this.store
      .pipe(select(selectConnectionsByRealChannelId(this.data.channelId)), untilDestroy(this))
      .subscribe((items) => {
        this.connections = lodash.filter(items, (t) =>
          lodash.includes(this.data.opportunity.applicable_listing_ids, t.roomId)
        )
      })
  }

  getValidators(field: AirbnbOpportunityInputField) {
    const constraints = field.value_constraint
    if (lodash.isEmpty(constraints)) return []
    const validators = [Validators.required]
    switch (constraints.value_type) {
      case 'PERCENTAGE':
      case 'INTEGER':
        if (constraints.max_value) {
          validators.push(Validators.max(lodash.toNumber(constraints.max_value)))
        }
        if (constraints.min_value) {
          validators.push(Validators.min(lodash.toNumber(constraints.min_value)))
        }
        break
      case 'OPTION':
        break
    }
    return validators
  }

  getUrl() {
    const item = this.data.opportunity
    return lodash.startsWith(item.activation_url, 'http') ? item.activation_url : `https://${item.activation_url}`
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const data = form.getRawValue()
    const opportunity = this.data.opportunity
    const input_fields = lodash.reduce(
      opportunity.input_fields,
      (acc, field) => {
        acc.push({
          key: field.key,
          value: data[lodash.snakeCase(field.key)],
        })
        return acc
      },
      []
    )
    const payload = {
      action: 'APPLY',
      opportunity_id: opportunity.id,
      input_fields,
      listing_ids: data.listing_ids,
    }

    this.isLoading = true
    this.airbnbChannelService
      .setOpportunity(opportunity.type, this.data.propertyId, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('data saved successfully')
          this.dialogRef.close(opportunity.id)
        },
        (error) => {
          this.toaster.error('Unable to save data to Airbnb', null, error)
        }
      )
  }
}
