<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-calendar-alt"></i> Import Calendars from CSV Files</h5>
  <button (click)="onClose()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-csv-file-parser
    [columnDefs]="columnDefs"
    [guide]="guide"
    (parsed)="onCsvFilesParsed($event)"
  ></app-csv-file-parser>
</div>
<div class="modal-footer">
  <button (click)="onClose()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button
    (click)="onCreate()"
    [disabled]="!files?.length || hasErrors()"
    type="button"
    class="btn btn-secondary-info create-btn"
  >
    <i class="cs-icon cs-icon-upload"></i> upload
  </button>
</div>
