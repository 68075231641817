import { DataRecurringRule } from '../../types'
import { Rental } from '../rental'

export enum ExpenseApprovalStatus {
  Pending = 'pending',
  Denied = 'denied',
  Approved = 'approved',
  OnHold = 'hold',
}

export const expenseApprovalStatusOptions = [
  { label: 'Pending', id: ExpenseApprovalStatus.Pending },
  { label: 'Denied', id: ExpenseApprovalStatus.Denied },
  { label: 'Approved', id: ExpenseApprovalStatus.Approved },
  { label: 'On Hold', id: ExpenseApprovalStatus.OnHold },
]

export enum ExpensePaidStatus {
  Unpaid = 1,
  Paid = 2,
}

export interface ExpenseResponse extends DataRecurringRule {
  pkey: string
  rental_id: string
  inquiry_id: string
  payment_account: string
  date: number

  // @depreated
  photo: string
  attachments: string[]

  description: string
  category: string
  account: number
  archived: number
  due: number
  method: string
  created: number
  status: ExpensePaidStatus
  amount: number
  created_by: string
  trigger_id?: string
  approval_status?: ExpenseApprovalStatus

  events?: string // Automated Expense
}
export interface ExpenseView extends ExpenseResponse {
  isAuto: boolean
  rental: Rental
  createdView: string
  dateView: string
  dueView: string
  methodView: string
  statusView: string

  createdByView: string

  rentalView: string
  amountCodeView: string
}

export interface IExpenseFilters {
  rentals: string[]
  method: string
  status: number | string
  categories: string[]
  period: {
    from: number
    to: number
  }
}

export interface CreateExpensePayload {
  date: number
  due: number
  category: string
  amount: number
  status: number
  method: string

  inquiry_id?: string
  repeat?: number
  rental_id?: string
  description: string
  photo?: string
  attachments?: string[]
  approval_status?: ExpenseApprovalStatus
}

export type UpdateExpensePayload = Partial<CreateExpensePayload>

// Approval
export interface ExpenseApproverItem {
  user_id: string
  amount: number
  currency?: string
}

export interface ExpenseApprovalSettings {
  pkey: string
  account: number
  auto_approve_amount: number
  approvers: ExpenseApproverItem[]
}

export type UpdateExpenseSettingsPayload = Partial<Omit<ExpenseApprovalSettings, 'pkey' | 'account'>>

export interface FetchExpenseStatsReportParams {
  start?: number
  end?: number
  rentals?: string[]
  statuses?: number[]
  approval_statuses?: ExpenseApprovalStatus[]
  categories?: string[]
}

export interface ExpenseCategoryReportItem {
  category: string
  amount: number
  date: string
}
