import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '@tv3/shared/shared.module'
import { ChannelAutomationsComponent } from './automations/channel-automations.component'
import { ChannelAutomationDialogComponent } from './automation-dialog/channel-automation-dialog.component'
import { ChannelAutomationDialogService } from './automation-dialog/channel-automation-dialog.service'
import { ChannelAutomationsOverlayComponent } from './automations-overlay/channel-automations-overlay.component'
import { ChannelAutomationsGridComponent } from './grid/channel-automations-grid.component'

const modules = [SharedModule, CommonModule]

const components = [
  //
  ChannelAutomationsComponent,
  ChannelAutomationDialogComponent,
  ChannelAutomationsOverlayComponent,
  ChannelAutomationsGridComponent,
]

const services = [ChannelAutomationDialogService]

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components, ...modules],
  providers: [...services],
})
export class ChannelAutomationsModule {}
