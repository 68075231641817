import { ComponentStore } from '@ngrx/component-store'
import { SubscribedAppsState } from './subscribed-apps.state'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { map, reduce, reject, sort } from 'ramda'
import * as R from 'ramda'
import { Subscription } from '@tokeet-frontend/subscriptions'
import { TokeetApp } from '../store/app/app.model'
import { getRGRentalsPrice, getRategeniePrices } from './subscribed-apps.helper'
import { Injectable } from '@angular/core'
import { RategeniePlanPrice, WebreadySubscription } from '../store/app/app-sub.model'

const DEFAULT_STATE = {
  rgPrices: {
    tiers: [],
  },
  subscriptions: {
    value: [],
    $loading: true,
  },
  apps: {
    value: {},
    $loading: true,
  },
  webreadySubscription: {
    value: null,
    $loading: true,
  },
}

@Injectable()
export class SubscribedAppsStore extends ComponentStore<SubscribedAppsState> {
  constructor() {
    super(DEFAULT_STATE)
  }

  readonly loadAccount = this.effect((origin$: Observable<{ subscriptions: Subscription[] }>) =>
    origin$.pipe(
      tap((account: { subscriptions: Subscription[] }) => {
        this.setAccount(account)
        this.setLoading({ key: 'subscriptions', value: false })
      })
    )
  )

  readonly loadApps = this.effect((origin$: Observable<TokeetApp[]>) =>
    origin$.pipe(
      tap((apps: TokeetApp[]) => {
        this.setApps(apps)
        this.setLoading({ key: 'apps', value: false })
      })
    )
  )

  readonly loadRGPrices = this.effect((origin$: Observable<RategeniePlanPrice[]>) =>
    origin$.pipe(
      tap((tiers: RategeniePlanPrice[]) => {
        this.setRGPrices(tiers)
      })
    )
  )

  readonly loadWebreadySubscription = this.effect((origin$: Observable<WebreadySubscription>) =>
    origin$.pipe(
      tap((subscription: WebreadySubscription) => {
        this.setWebreadySubscription(subscription)
        // this.setLoading({ key: 'subscriptions', value: false })
      })
    )
  )

  readonly setAccount = this.updater(
    (
      state: SubscribedAppsState,
      {
        subscriptions,
      }: {
        subscriptions: Subscription[]
      }
    ) => ({
      ...state,
      subscriptions: {
        ...state.subscriptions,
        value: this.sortSubscriptions(subscriptions),
      },
    })
  )

  readonly setWebreadySubscription = this.updater((state: SubscribedAppsState, subscription: WebreadySubscription) => ({
    ...state,
    webreadySubscription: {
      ...state.webreadySubscription,
      value: subscription,
    },
  }))

  readonly setRGPrices = this.updater((state: SubscribedAppsState, tiers: RategeniePlanPrice[]) => ({
    ...state,
    rgPrices: {
      tiers,
    },
  }))

  readonly setApps = this.updater((state: SubscribedAppsState, apps: TokeetApp[]) => ({
    ...state,
    apps: {
      ...state.apps,
      value: reduce(
        (acc, elem) => {
          acc[elem.stripe_product_code] = elem

          acc['website_management'] = {
            logo_url: 'https://cdn.tokeet.com/images/Appstore_logos/webready.svg',
            name: 'Website Management',
          }

          return acc
        },
        {},
        apps
      ),
    },
  }))

  readonly setLoading = this.updater(
    (state: SubscribedAppsState, params: { key: 'cards' | 'subscriptions' | 'apps'; value: boolean }) => ({
      ...state,
      [params.key]: {
        ...state[params.key],
        $loading: params.value,
      },
    })
  )

  readonly setSubscriptions = this.updater((state: SubscribedAppsState, subscriptions: Subscription[]) => ({
    ...state,
    subscriptions: {
      ...state.subscriptions,
      value: this.sortSubscriptions(subscriptions),
    },
  }))

  readonly updateSubscription = this.updater((state: SubscribedAppsState, subscription: Subscription) => ({
    ...state,
    subscriptions: {
      ...state.subscriptions,
      value: this.sortSubscriptions(
        map((s) => (s.product === subscription.product ? { ...subscription } : s), state.subscriptions.value)
      ),
    },
  }))

  readonly selectSubscriptions$ = this.select((state: SubscribedAppsState) => {
    return reject(
      (s) =>
        s.plan_id === 'free_trial_v3' || s.product.startsWith('webready') || s.product.startsWith('booking_engine'),
      state.subscriptions.value
    )
  })

  readonly selectWebreadySubscription$ = this.select((state: SubscribedAppsState) => {
    const webreadySubscription = state.webreadySubscription.value
    const signatureSubscription = R.find((s) => s.product === 'signature', state.subscriptions.value || [])
    if (!webreadySubscription) {
      return
    }
    for (const feature of Object.keys(webreadySubscription.features)) {
      if (!webreadySubscription.features[feature].active) {
        delete webreadySubscription.features[feature]
      }
      if (!R.isNil(signatureSubscription) && feature === 'webready_edocuments') {
        delete webreadySubscription.features[feature]
      }
      if (feature === 'website_management') {
        delete webreadySubscription.features[feature]
      }
    }

    return webreadySubscription
  })

  readonly selectSubscriptionsLoading$ = this.select((state: SubscribedAppsState) => state.subscriptions.$loading)

  readonly selectWebreadySubscriptionLoading$ = this.select(
    (state: SubscribedAppsState) => state.webreadySubscription.$loading
  )

  readonly selectAppsLoading$ = this.select((state: SubscribedAppsState) => state.apps.$loading)

  readonly selectApps$ = this.select((state: SubscribedAppsState) => state.apps.value)

  readonly selectRGPrice$ = this.select((state: SubscribedAppsState) => {
    const subscription = state.subscriptions.value.find((s) => s.plan_id === 'rategenie_v3')
    if (subscription) {
      return getRGRentalsPrice(subscription?.quantity, getRategeniePrices(state.rgPrices.tiers))
    }
  })

  sortSubscriptions(items: Subscription[]) {
    return sort(
      (a: Subscription, b: Subscription) =>
        a.product.localeCompare(b.product, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      items
    )
  }
}
