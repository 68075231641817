import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { AddGuestDialogComponent } from '@tv3/containers/guests/dialogs/add/add-guest-dialog.component'
import { EditGuestNoteListComponent } from '@tv3/containers/guests/edit/note/list/guest-note-list.component'
import { EditGuestEmailListComponent } from '@tv3/containers/guests/edit/email/list/guest-email-list.component'
import { EditGuestBookingListComponent } from '@tv3/containers/guests/edit/booking/list/guest-booking-list.component'
import { EditGuestInteractionListComponent } from '@tv3/containers/guests/edit/interaction/list/guest-interaction-list.component'
import { EditGuestFileListComponent } from '@tv3/containers/guests/edit/file/list/guest-file-list.component'
import { GetRentalCurrencySymbolPipe } from '@tv3/containers/guests/pipes/get-rental-currency-symbol.pipe'
import { ImportGuestsDialogComponent } from '@tv3/containers/guests/dialogs/import/import-guests-dialog.component'
import { GuestAttributeListComponent } from '@tv3/containers/guests/edit/attribute/list/guest-attribute-list.component'
import { MessagesSharedModule } from '@tv3/containers/messages/messages-shared.module'
import { ImportVcardGuestsDialogComponent } from '@tv3/containers/guests/dialogs/import-vcard/import-vcard-guests-dialog.component'
import { GuestModifyNoteDialogComponent } from '@tv3/containers/guests/edit/note/modify/guest-modify-note-dialog.component'
import { GuestModifyInteractionDialogComponent } from '@tv3/containers/guests/edit/interaction/modify/guest-modify-interaction-dialog.component'
import { MultipleItemsDialogComponent } from '@tv3/containers/guests/edit/multiple-items/multiple-items-dialog.component'
import { AddGuestDialogService } from '@tv3/containers/guests/dialogs/add/add-guest-dialog.service'
import { DownloadGuestsDialogService } from '@tv3/containers/guests/dialogs/download/download-guests-dialog.service'
import { ImportGuestsDialogService } from '@tv3/containers/guests/dialogs/import/import-guests-dialog.service'
import { ImportVcardGuestsDialogService } from '@tv3/containers/guests/dialogs/import-vcard/import-vcard-guests-dialog.service'
import { MultipleItemsDialogService } from '@tv3/containers/guests/edit/multiple-items/multiple-items-dialog.service'
import { GuestModifyNoteDialogService } from '@tv3/containers/guests/edit/note/modify/guest-modify-note-dialog.service'
import { GuestModifyInteractionDialogService } from '@tv3/containers/guests/edit/interaction/modify/guest-modify-interaction-dialog.service'
import { AddAttributeDialogComponent } from '@tv3/containers/guests/dialogs/add-attribute/add-attribute-dialog.component'
import { AddAttributeDialogService } from '@tv3/containers/guests/dialogs/add-attribute/add-attribute-dialog.service'
import { EditGuestDialogComponent } from './dialogs/edit-guest/edit-guest-dialog.component'
import { EditGuestDialogService } from './dialogs/edit-guest/edit-guest-dialog.service'

const modules = [CommonModule, SharedModule, MessagesSharedModule]

const components = [
  EditGuestNoteListComponent,
  EditGuestEmailListComponent,
  EditGuestBookingListComponent,
  EditGuestInteractionListComponent,
  EditGuestFileListComponent,
  GuestAttributeListComponent,
]

const pipes = [GetRentalCurrencySymbolPipe]

const dialogs = [
  AddGuestDialogComponent,
  EditGuestDialogComponent,
  ImportGuestsDialogComponent,
  ImportVcardGuestsDialogComponent,
  GuestModifyNoteDialogComponent,
  GuestModifyInteractionDialogComponent,
  MultipleItemsDialogComponent,
  AddAttributeDialogComponent,
]

const services = [
  AddGuestDialogService,
  EditGuestDialogService,
  DownloadGuestsDialogService,
  ImportGuestsDialogService,
  ImportVcardGuestsDialogService,
  MultipleItemsDialogService,
  GuestModifyNoteDialogService,
  GuestModifyInteractionDialogService,
  AddAttributeDialogService,
]

@NgModule({
  imports: [...modules],
  declarations: [...components, ...dialogs, ...pipes],
  providers: [...services],
  exports: [...components, ...dialogs, ...pipes, ...modules],
})
export class GuestsSharedModule {}
