import { Injectable } from '@angular/core'
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { SettingsSharedModule } from '../../settings-shared.module'
import { UserOverlayComponent } from '@tv3/containers/settings/user-settings/overlays/user-overlay.component'
import { DialogService, User } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SettingsSharedModule,
})
export class UserOverlayService {
  constructor(private dialog: DialogService) {}

  public openSide(user: User): MatDialogRef<UserOverlayComponent> {
    const defaultConfig: MatDialogConfig = {
      data: { user },
      width: '750px',
      height: 'auto',
    }

    const dialogRef = this.dialog.openVariableDialog(UserOverlayComponent, defaultConfig)

    return dialogRef
  }
}
