<div class="">
  <form [formGroup]="form" [formEditablePermission]="form" [class.loading-section]="isSavingCustom$ | async">
    <div class="d-grid gap-3" style="grid-template-columns: auto">
      <div class="card p-3">
        <h6 class="text-uppercase">Custom Information 1</h6>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            formControlName="custom1"
            placeholder="Enter Custom Information"
            rows="4"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="8"
          ></textarea>
        </mat-form-field2>
      </div>
      <div class="card p-3">
        <h6 class="text-uppercase">Custom Information 2</h6>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            formControlName="custom2"
            placeholder="Enter Custom Information"
            rows="4"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="8"
          ></textarea>
        </mat-form-field2>
      </div>
      <div class="card p-3">
        <h6 class="text-uppercase">Custom Information 3</h6>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            formControlName="custom3"
            placeholder="Enter Custom Information"
            rows="4"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="8"
          ></textarea>
        </mat-form-field2>
      </div>

      <div class="card p-3">
        <h6 class="text-uppercase">Custom Information 4</h6>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            formControlName="custom4"
            placeholder="Enter Custom Information"
            rows="4"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="8"
          ></textarea>
        </mat-form-field2>
      </div>

      <div class="card p-3">
        <h6 class="text-uppercase">Custom Information 5</h6>
        <mat-form-field2 class="mat-block">
          <textarea
            matInput
            formControlName="custom5"
            placeholder="Enter Custom Information"
            rows="4"
            cdkTextareaAutosize
            cdkAutosizeMaxRows="8"
          ></textarea>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
