import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { AirbnbChannelConfig, ChannelsService } from '@tokeet-frontend/channels'
import { Channel, Destroyable, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-abb-sync-settings',
  templateUrl: './abb-sync-settings.component.html',
  styleUrls: ['./abb-sync-settings.component.scss'],
})
export class AbbSyncSettingsComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  form = this.fb.group({
    pricing_settings: [false],
    booking_settings: [false],
    availability_rules: [false],
  })

  isLoading = true
  config: AirbnbChannelConfig

  constructor(private fb: FormBuilder, private toaster: Toaster, private channelService: ChannelsService) {
    super()
  }

  ngOnInit(): void {
    this.channelService
      .getAirbnbChannelConfig(this.channel.id, { service_only: '1' })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((config) => {
        this.form.patchValue(config?.syncSettings || {}, { emitEvent: false })
        this.config = config
      })
    this.form.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.save()
    })
  }

  onToggle(key: string) {
    this.form.get(key).setValue(!this.form.get(key).value)
  }

  save() {
    const formValues = this.form.getRawValue()
    this.isLoading = true
    this.channelService
      .setAirbnbChannelConfig(
        this.channel.id,
        {
          ...this.config,
          syncSettings: formValues,
        },
        { to_service: '1' }
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Airbnb Sync Settings Saved Successfully')
        },
        (error) => this.toaster.error('Unable to save Airbnb Sync Settings', null, error)
      )
  }
}
