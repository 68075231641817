import { catchError } from 'rxjs/operators'
import { EMPTY } from 'rxjs'
import { Toaster } from '../services/toaster.service'

export function toastError<T>(toaster: Toaster) {
  return catchError((error) => {
    toaster.error(null, 'Error', error)
    return EMPTY
  })
}
