<div class="modal-header">
  <div class="col-sm-6">
    <h5 class="modal-title">
      <i class="far fa-sync"></i> 2-Way Sync <app-page-help articleId="-" videoId="-"></app-page-help>
    </h5>
  </div>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div>
      <mat-checkbox formControlName="booking_settings"> Sync Booking Settings </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="pricing_settings"> Sync Pricing Settings </mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="availability_rules"> Sync Availability Rules </mat-checkbox>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button class="btn btn-secondary-info create-btn" (click)="save(form)" type="button" [disabled]="isLoading">
    <i class="fal" [ngClass]="isLoading ? 'fa-spin fa-spinner' : 'fa-save'"></i> Save
  </button>
</div>
