import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import * as lodash from 'lodash'
import { TableSearchComponent } from '../../table'
import { Destroyable } from '../../rx-operators'
import { isSomething } from '../../functions'

@Component({
  selector: 'app-cancel-filters',
  templateUrl: './cancel-filters.component.html',
  styleUrls: ['./cancel-filters.component.scss'],
})
export class CancelFiltersComponent extends Destroyable {
  @Input() filterControl: FormGroup
  @Input() searchCom: TableSearchComponent
  @Input() ignoreFields: string[]
  @Input() isFiltering = false
  @Input() defaultFilters: any = {}

  @Output() clear = new EventEmitter()

  onClearFilters() {
    if (this.filterControl) {
      if (isSomething(this.ignoreFields)) {
        const ignoreValues = lodash.pick(this.filterControl.value, this.ignoreFields)
        this.filterControl.reset(ignoreValues || {})
      } else {
        this.filterControl.reset(this.defaultFilters)
      }
    }
    if (this.searchCom) {
      this.searchCom.onClearSearch()
    }
    this.clear.emit(true)
  }
}
