<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Website Details</h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box">
      <div class="box-body">
        <div class="d-flex rounded-top overflow-hidden">
          <h6 class="section-title border-right flex-1 border-top-0">Engine Information</h6>
          <h6 class="section-title flex-1 border-top-0">Link Instructions</h6>
        </div>
        <div class="row px-3">
          <div class="col-6">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput required placeholder="Website Title" formControlName="title" />
              <mat-hint align="end">{{ form.get('title').value.length || 0 }}/50</mat-hint>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
                Website Title is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('minlength')">
                Website Title must be at least <strong>2</strong> characters long
              </mat-error>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('maxlength')">
                Website Title must be at most <strong>50</strong> characters long
              </mat-error>
            </mat-form-field2>
            <div class="d-flex gap-1">
              <mat-form-field2 class="mat-block">
                <input type="text" matInput required placeholder="Domain" formControlName="domain" />
              </mat-form-field2>
              <a href="javascript:;" (click)="onEditDomain()" class="btn btn-light-info align-input-row-end">
                <i class="fal fa-edit"></i>
              </a>
              <app-website-custom-domain-ssl
                #ssl
                *ngIf="isCustomDomain"
                [website]="website"
                class="align-input-row-end"
              ></app-website-custom-domain-ssl>
            </div>
            <mat-form-field2 class="mat-block">
              <app-select
                [items]="templates"
                formControlName="template"
                required
                bindLabel="title"
                bindValue="name"
                placeholder="Select Template"
                matTooltip="Select the template to use with your website. These templates support {{
                  website.rentals.length <= 1 ? 'a single rental' : 'multiple rentals'
                }}. This is based on the number of selected rentals."
                matTooltipPosition="above"
              >
                <ng-template selectItem let-item="item">
                  <div [matTooltip]="tooltipsForTemplate[item.name]" matTooltipPosition="right">
                    {{ item.title }}
                  </div>
                </ng-template>
              </app-select>
              <mat-error *ngIf="form.get('template').touched && form.get('template').hasError('required')">
                Template is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
            <app-select-search
              [items]="rateCategories"
              [ctrl]="form.get('rateCategory')"
              [multiple]="false"
              bindValue="value"
              bindLabel="label"
              [inline]="false"
              placeholder="Rate Category"
              matTooltip="Select the rate category you want displayed on your website."
              matTooltipPosition="above"
            >
              <ng-container class="error-container">
                <mat-error> Rate Category is <strong>required</strong> </mat-error>
              </ng-container>
            </app-select-search>
          </div>
          <div class="col-6">
            <p>
              You may link from your other (non-AdvanceCM) websites directly to this booking engine using special links.
              These links will contain information regarding the rental the guest would like to book and the booking
              dates. The booking engine will then take the guest through the booking process for that rental.
            </p>
            <p>
              Download the rental links by clicking the button below. You should dynamically change the
              [date_placeholders] in each link with the dates the guest choose to book (DD-MM-YYYY). Consult your web
              developer for help doing this. Please note that
              <b>if you change your domain you must update all links to this booking engine</b>.
            </p>
            <br />
            <button class="btn btn-secondary-warning" (click)="onDownloadEngineLinks()">
              <i class="fal fa-download"></i> Download Links
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
