<div class="modal-header">
  <div class="d-flex align-items-center gap-2">
    <div class="modal-title">Connect To Airbnb</div>
    <app-page-help [articleId]="'-'" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<mat-stepper [linear]="true" #stepper class="m-3">
  <mat-step
    label="Getting Started"
    [editable]="stepper.selectedIndex < 2"
    [state]="stepper.selectedIndex > 0 ? 'done' : 'number'"
  >
    <app-connect-airbnb-wizard-step1
      (next)="stepper.next()"
      [isAuthorizing]="isAuthorizing"
      (advance)="onAdvance()"
      (quick)="onQuick()"
    ></app-connect-airbnb-wizard-step1>
  </mat-step>
  <mat-step
    label="Preparation"
    [optional]="true"
    [editable]="false"
    [state]="stepper.selectedIndex > 1 ? 'done' : 'number'"
  >
    <app-connect-airbnb-wizard-step2
      (prev)="stepper.previous()"
      (authorize)="onConnect()"
    ></app-connect-airbnb-wizard-step2>
  </mat-step>
  <mat-step
    label="Account Connection Complete"
    [editable]="false"
    [state]="stepper.selectedIndex > 2 ? 'done' : 'number'"
  >
    <app-connect-airbnb-wizard-complete1
      *ngIf="isQuickConnect"
      (reset)="stepper.reset()"
      (channel)="onChannel()"
    ></app-connect-airbnb-wizard-complete1>
    <app-connect-airbnb-wizard-complete2
      *ngIf="!isQuickConnect"
      [channel]="data.channel"
      (done)="close()"
    ></app-connect-airbnb-wizard-complete2>
  </mat-step>
  <ng-template matStepperIcon="done">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
</mat-stepper>
