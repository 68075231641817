import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  AddTemplateComplete,
  DeleteTemplate,
  selectAllMessageTemplates,
  Template,
  TemplateType,
  UpdateTemplateComplete,
} from '@tokeet-frontend/templates'
import { FormControl } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { select, Store } from '@ngrx/store'
import { ConfirmDialogService, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'
import * as lodash from 'lodash'

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
})
export class TemplateDialogComponent extends Destroyable implements OnInit {
  isEdit = false

  template: Template
  templates: Template[] = []
  templateCtrl = new FormControl()

  constructor(
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private confirmDialog: ConfirmDialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: { type?: TemplateType; template?: Template; hideType?: boolean; hideTemplates?: boolean },
    public dialogRef: MatDialogRef<TemplateDialogComponent>
  ) {
    super()
    this.isEdit = !!data?.template?.id
    this.templateCtrl.setValue(data?.template?.id)
    this.template = data.template
  }

  ngOnInit() {
    this.store.pipe(select(selectAllMessageTemplates), untilDestroy(this)).subscribe((templates) => {
      this.templates = templates
    })

    this.actions.pipe(ofType(AddTemplateComplete, UpdateTemplateComplete), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }

  onSelect(templates: Template[]) {
    this.template = lodash.head(templates)
  }

  onDelete() {
    this.confirmDialog
      .confirm({
        title: 'Delete template?',
        body: 'Are you sure you want to delete this template?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteTemplate({ id: this.data.template.id }))
        this.close()
      })
  }
}
