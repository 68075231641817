import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectEntities, selectAll } from './template.reducer'
import * as R from 'ramda'
import { State } from './template.state'
import { InvoiceTemplate, InvoiceTemplateView } from './template.model'

const invoiceState = createFeatureSelector<State>('invoice-template-lib')

export const selectAllInvoiceTemplates = createSelector(invoiceState, selectAll)

export const selectAllInvoiceTemplatesByName = createSelector(selectAllInvoiceTemplates, (templates) => {
  return R.sort(
    (a: InvoiceTemplate, b: InvoiceTemplate) =>
      R.pathOr('', ['name'], a)
        .toString()
        .localeCompare(R.pathOr('', ['name'], b), undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
    templates
  )
})

export const selectInvoiceTemplateEntities = createSelector(invoiceState, selectEntities)

export const selectInvoiceTemplatesLoaded = createSelector(invoiceState, (state) => state.isLoaded)

export const isInvoiceTemplateUpdating = createSelector(invoiceState, (state) => state.isUpdating)

export const selectInvoiceTemplate = createSelector(selectInvoiceTemplateEntities, (entities, props) => {
  return entities[props.id]
})

export const selectAllInvoiceTemplateViews = createSelector(selectAllInvoiceTemplates, (templates) => {
  return R.map((t: InvoiceTemplate) => InvoiceTemplateView.deserialize(t), templates)
})

export const selectAllInvoiceTemplateTags = createSelector(selectAllInvoiceTemplates, (templates) => {
  return R.pipe(
    R.map((g: InvoiceTemplate) => g.tags),
    R.flatten,
    R.reject(R.isNil),
    R.map(R.pipe(R.when(R.is(Number), R.toString))),
    R.uniq,
    R.sort((a: string, b: string) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(templates)
})
