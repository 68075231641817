import { Pipe, PipeTransform } from '@angular/core'
import { userRoleToString } from '@tokeet-frontend/tv3-platform'
import { USER_ROLES, User } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'

@Pipe({
  name: 'staffPermissions',
})
export class StaffPermissionsPipe implements PipeTransform {
  constructor() {}

  transform(user: User, canSee: 'email' | 'name' | 'phone') {
    if (userRoleToString(user?.roles || []) != USER_ROLES.staff.name) {
      return true
    }
    let settings = R.pathOr({}, ['staff_settings'], user?.attributes)
    settings = { ...settings, guest_name: true } // always show name
    return R.pathOr(false, [`guest_${canSee}`], settings)
  }
}
