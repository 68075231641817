import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromTemplate from './template.reducer'
import { EffectsModule } from '@ngrx/effects'
import { TemplateEffects } from './template.effects'
import { CustomBrandingService } from './custom-branding.service'
import { TemplateService } from './template.service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('template-lib', fromTemplate.templateReducer),
    EffectsModule.forFeature([TemplateEffects]),
  ],
  providers: [CustomBrandingService, TemplateService],
  declarations: [],
})
export class TemplatesLibraryModule {}
