import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { filter, take } from 'rxjs/operators'
import {
  SelectRentalDialogComponent,
  SelectRentalDialogConfig,
} from '@tv3/shared/dialogs/select-rental-dialog/select-rental-dialog.component'
import { isSomething } from '@tokeet-frontend/tv3-platform'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable({
  providedIn: SharedModule,
})
export class SelectRentalDialogService {
  constructor(private dialog: MatDialog) {}

  public open(config?: SelectRentalDialogConfig) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { config },
      panelClass: ['fixed-modal'],
    }

    return this.dialog
      .open(SelectRentalDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        filter((res) => isSomething(res)),
        take(1)
      )
  }
}
