import { createSelector, select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { State } from '../channels.state'
import { combineLatest } from 'rxjs'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { channelsState } from '../channels.selectors'
import { GVRListingModel, GVRListingViewModel } from './model'

export const selectGVRState = createSelector(channelsState, (state) => state.gvr)
export const selectGVRStatus = createSelector(selectGVRState, (state) => state.status)
export const selectGVRConnected = createSelector(selectGVRState, (state) => state.status?.connected)
export const selectGVRListings = createSelector(selectGVRState, (state) => state.listings || [])
export const selectGVRRentalValidations = createSelector(selectGVRState, (state) => state.validations)
export const selectGVRValidationsLoaded = createSelector(selectGVRState, (state) => state.validationsLoaded)
export const getGVRListings = (store: Store<State>) =>
  combineLatest([store.pipe(select(selectGVRListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      return R.map((listing): GVRListingModel => {
        const rental = R.find((r: Rental) => r.id === listing.rentalId, rentals)
        return {
          ...listing,
          rental,
          rental_name: rental?.name,
        } as GVRListingViewModel
      }, listings) as GVRListingViewModel[]
    })
  )
