<div class="row">
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <i class="cs-icon cs-icon-calendar" matPrefix (click)="birthdayInput.focus()"></i>
      <input
        matInput
        type="text"
        name="birthday"
        placeholder="Birthday"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.birthday"
        [matDatepicker]="picker"
        (blur)="onBlur()"
        (focus)="onFocus(); picker.open()"
        #birthdayInput
      />
      <i
        class="far fa-times text-danger"
        matSuffix
        *ngIf="guestItem.birthday"
        (click)="guestItem.birthday = null; onBlur()"
      ></i>
    </mat-form-field2>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="Spouse"
        name="wife"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.wife"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="Kids Names"
        name="kids"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.kids"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="Drink Preference"
        name="drink"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.drink"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="Hobby"
        name="hobby"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.hobby"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>
  <div class="col-md-3">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="Favorite Sport"
        name="sport"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="guestItem.sport"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>

  <div class="col-md-3" *ngFor="let item of itemKeys">
    <mat-form-field2 class="mat-block">
      <input
        matInput
        placeholder="{{ item.caption }}"
        [disabled]="1 | isReadonlyRole"
        [(ngModel)]="attributes[item.key]"
        type="text"
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
    </mat-form-field2>
  </div>
</div>
