import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { AuthService, KEY_TOKEN } from '@tv3/services/auth.service'
import * as fromRoot from '@tv3/store/state'
import { Destroyable, DialogService, isSomething, LocalStorage } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { ChangePasswordDialogService } from '@tv3/shared/change-password/change-password-dialog/change-password-dialog.service'
import { userRoleToFriendlyString } from '@tv3/utils/functions/user-role-to-string'
import { NgProgress } from 'ngx-progressbar'
import { Observable } from 'rxjs'
import { UserMfaDialogComponent } from '@tv3/containers/settings/user-settings/user-mfa-dialog/user-mfa-dialog.component'
import { selectActiveProductPlanView } from '@tv3/store/plan/plan.selectors'
import { ProductsForPlan } from '@tv3/store/plan/plan.model'

@Component({
  selector: 'app-sidebar2',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: { class: 'dashboard_sidebar' },
})
export class Sidebar2Component extends Destroyable implements OnInit {
  isLoading$: Observable<boolean>

  get username() {
    if (this.auth.user) {
      const firstName = this.auth.user.firstName
      const lastName = this.auth.user.lastName
      return `${firstName} ${lastName}`
    } else {
      return ''
    }
  }

  get user() {
    return this.auth.user
  }

  get role() {
    if (this.auth.user) {
      return userRoleToFriendlyString(this.auth.user.roles)
    } else {
      return ''
    }
  }

  isUserActionsMenuOpen = false
  isPaymentsMenuOpen = false
  isTasksMenuOpen = false

  smartDeviceSub$ = this.store.pipe(
    select(selectActiveProductPlanView(ProductsForPlan.SmartDevice)),
    map((res) => res || {})
  )

  constructor(
    private auth: AuthService,
    private storage: LocalStorage,
    private store: Store<fromRoot.State>,
    private progress: NgProgress,
    private dialog: DialogService,
    private changePasswordDialogService: ChangePasswordDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.isLoading$ = this.progress.ref().state.pipe(map((state) => state.active))
  }

  reportsUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.reportsUrl}login?token=${token}`
  }

  getDeviceStoreLink() {
    return `${environment.appStoreUrl}/login?redirect=/details/${ProductsForPlan.SmartDevice}&token=${this.auth.token}`
  }

  goToChangelog() {
    window.open('https://changes.tokeet.com/')
  }

  onMFA() {
    this.dialog.openFixedDialog(UserMfaDialogComponent, { width: '600px', height: 'auto' })
  }

  logout() {
    this.auth.logout()
  }

  onChangePassword() {
    this.changePasswordDialogService.open(this.auth.user)
  }
}
