import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { HVMIChannelService } from './service'
import {
  ActivateHvmiListing,
  ActivateHvmiListingFailure,
  ActivateHvmiListingSuccess,
  ConnectHvmi,
  ConnectHvmiFailure,
  ConnectHvmiSuccess,
  CreateHvmiListing,
  CreateHvmiListingFailure,
  CreateHvmiListingSuccess,
  DeactivateHvmiListing,
  DeactivateHvmiListingFailure,
  DeactivateHvmiListingSuccess,
  DeleteHvmiListing,
  DeleteHvmiListingFailure,
  DeleteHvmiListingSuccess,
  DisconnectHvmi,
  DisconnectHvmiFailure,
  DisconnectHvmiSuccess,
  GetHvmiListings,
  GetHvmiListingsFailure,
  GetHvmiListingsSuccess,
  GetHvmiRentalValidations,
  GetHvmiRentalValidationsFailure,
  GetHvmiRentalValidationsSuccess,
  GetHvmiStatus,
  GetHvmiStatusFailure,
  GetHvmiStatusSuccess,
  UpdateHvmiListing,
  UpdateHvmiListingFailure,
  UpdateHvmiListingSuccess,
} from './actions'

@Injectable()
export class HVMIChannelEffects {
  //region Hvmi
  @Effect()
  getHvmiStatus$ = this.actions$.pipe(
    ofType(GetHvmiStatus),
    switchMap(() =>
      this.channelsService.getHvmiStatus().pipe(
        map((status) => GetHvmiStatusSuccess({ status })),
        catchError((err) => of(GetHvmiStatusFailure({ err })))
      )
    )
  )

  @Effect()
  deleteHvmiListing$ = this.actions$.pipe(
    ofType(DeleteHvmiListing),
    switchMap(({ listingId }) =>
      this.channelsService.deleteHvmiListing(listingId).pipe(
        tap(() => this.toaster.success('Listing deleted successfully')),
        map(() => DeleteHvmiListingSuccess({ listingId })),
        catchError((err) => of(DeleteHvmiListingFailure({ err })))
      )
    )
  )

  @Effect()
  connectHvmi$ = this.actions$.pipe(
    ofType(ConnectHvmi),
    switchMap(({ hmcId }) =>
      this.channelsService.connectHvmi(hmcId).pipe(
        tap(() => this.toaster.success('HVMI connected successfully')),
        map(({ status, listings }) => ConnectHvmiSuccess({ status, listings })),
        catchError((err) => {
          this.toaster.error(`Unable to connect`, '', err)
          return of(ConnectHvmiFailure({ err }))
        })
      )
    )
  )

  @Effect()
  activateHvmiListing$ = this.actions$.pipe(
    ofType(ActivateHvmiListing),
    switchMap(({ propertyId }) =>
      this.channelsService.activateHvmiListing(propertyId).pipe(
        tap(() => this.toaster.success('Listing activated successfully')),
        map((listing) => ActivateHvmiListingSuccess({ listing })),
        catchError((err) => of(ActivateHvmiListingFailure({ err })))
      )
    )
  )

  @Effect()
  deactivateHvmiListing$ = this.actions$.pipe(
    ofType(DeactivateHvmiListing),
    switchMap(({ propertyId }) =>
      this.channelsService.deactivateHvmiListing(propertyId).pipe(
        tap(() => this.toaster.success('Listing deactivated successfully')),
        map((listing) => DeactivateHvmiListingSuccess({ listing })),
        catchError((err) => of(DeactivateHvmiListingFailure({ err })))
      )
    )
  )

  @Effect()
  getHvmiListings$ = this.actions$.pipe(
    ofType(GetHvmiListings),
    switchMap(() =>
      this.channelsService.getHvmiListings().pipe(
        map((listings) => GetHvmiListingsSuccess({ listings })),
        catchError((err) => of(GetHvmiListingsFailure({ err })))
      )
    )
  )

  @Effect()
  createHvmiListing$ = this.actions$.pipe(
    ofType(CreateHvmiListing),
    switchMap(({ listing }) =>
      this.channelsService.createHvmiListing(listing).pipe(
        tap(() => this.toaster.success('Listing created successfully.')),
        map((response) => CreateHvmiListingSuccess({ listing: response })),
        catchError((err) => of(CreateHvmiListingFailure({ err })))
      )
    )
  )

  @Effect()
  getHvmiRentalValidations$ = this.actions$.pipe(
    ofType(GetHvmiRentalValidations),
    switchMap(() =>
      this.channelsService.getHvmiRentalValidations().pipe(
        map((validations) => GetHvmiRentalValidationsSuccess({ validations })),
        catchError((err) => of(GetHvmiRentalValidationsFailure({ err })))
      )
    )
  )

  @Effect()
  updateHvmiListing$ = this.actions$.pipe(
    ofType(UpdateHvmiListing),
    switchMap(({ listing }) =>
      this.channelsService.updateHvmiListing(listing).pipe(
        tap(() => this.toaster.success('Listing updated successfully.')),
        map((response) => UpdateHvmiListingSuccess({ listing: response })),
        catchError((err) => of(UpdateHvmiListingFailure({ err })))
      )
    )
  )

  @Effect()
  disconnectHvmi$ = this.actions$.pipe(
    ofType(DisconnectHvmi),
    switchMap(({ hmcId }) =>
      this.channelsService.disconnectHvmi(hmcId).pipe(
        tap(({ success }) => {
          if (success) {
            this.toaster.success('HVMI disconnected successfully')
          } else {
            this.toaster.error('Error disconnecting HVMI, please contact support.')
          }
        }),
        map(({ success }) => DisconnectHvmiSuccess({ hmcId })),
        catchError((err) => of(DisconnectHvmiFailure({ err })))
      )
    )
  )
  //endregion

  constructor(private actions$: Actions, private channelsService: HVMIChannelService, private toaster: Toaster) {}
}
