import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  Channel,
  ChannelNameTokens,
  CURRENT_ACCOUNT_ID,
  isSomething,
  selectChannelByName,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { filter, switchMap, take, tap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-airbnb-listing-wizard-step2',
  templateUrl: './airbnb-listing-wizard-step2.component.html',
  styleUrls: ['./airbnb-listing-wizard-step2.component.scss'],
})
export class AirbnbListingWizardStep2Component implements OnInit {
  @Input() connection: Connection
  @Input() channel: Channel

  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  constructor(
    private store: Store<any>,
    @Inject(CURRENT_ACCOUNT_ID) public accountId$: BehaviorSubject<number>,
    private connectionHelperService: ConnectionHelperService
  ) {}

  ngOnInit(): void {}

  onExport() {
    this.store
      .pipe(
        select(selectChannelByName, { name: ChannelNameTokens.UnknownICal }),
        filter((c) => isSomething(c)),
        take(1),
        switchMap((channel) => this.connectionHelperService.setUpExportCalendar(channel))
      )
      .subscribe()
  }
}
