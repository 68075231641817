import { Injectable, Injector } from '@angular/core'
import { Store, MemoizedSelector, Action } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { Observable } from 'rxjs'
import { take, tap, filter } from 'rxjs/operators'

// the Guards which need to be used in component to load data should implement this interface
export interface IsDataLoaded {
  isDataLoaded(params?: any): Observable<boolean>
}

@Injectable()
export class DataCheckerService {
  constructor(private moduleInjector: Injector, private store: Store<any>, private actions$: Actions) {}

  check(guards: any[] = []) {
    guards.map((guard: any) => {
      const checker = this.moduleInjector.get(guard)
      if (checker.isDataLoaded) {
        checker.isDataLoaded().pipe(take(1)).subscribe()
      }
    })
  }

  guardDataLoadedBySelector(selector: MemoizedSelector<any, boolean>, action: () => Action) {
    return this.store.select(selector).pipe(
      tap((loaded) => {
        if (!loaded) this.store.dispatch(action())
      }),
      filter((loaded) => loaded),
      take(1)
    )
  }

  guardDataLoadedByAction(waitForAction: Action, action: () => Action) {
    this.store.dispatch(action())

    return this.actions$.pipe(ofType(waitForAction.type), take(1))
  }
}
