<div>
  <a
    class="btn btn-light btn-circle"
    matTooltip="View a chronological list of events that updates in real time."
    matTooltipPosition="below"
    (click)="onShown()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <i class="fal fa-bell text-lg"></i>
    <sup [hidden]="!unreadCount" class="badge">{{ unreadCount }}</sup>
  </a>
</div>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="isOpen = false"
  [cdkConnectedOverlayOffsetY]="18"
  [cdkConnectedOverlayOffsetX]="72"
  [cdkConnectedOverlayPositions]="[{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="bg-white dropdown-shadow rounded">
    <div class="d-flex align-items-center p-3">
      <h6 class="mb-0">Notifications</h6>
      <div class="flex-fill"></div>
      <a class="text-sm text-link" (click)="onReadAll()">
        <i class="cs-icon cs-icon-check-double text-xs"></i> Mark all as read</a
      >
    </div>
    <ul class="nav top-navbar border-bottom text-sm">
      <li *ngFor="let tab of tabs" [class.active]="activeTab === tab" (click)="onTabClick(tab)">
        <a class=""> {{ tab.title }} {{ tab.amount }} </a>
      </li>
    </ul>
    <ul class="list-unstyled m-0 p-0 text-sm">
      <ng-container *ngFor="let item of notifications">
        <li class="border-bottom notification-item" *ngIf="theme[item.type] as typeTheme" [ngClass]="typeTheme.color">
          <a
            (click)="onOpen(item)"
            class="p-2 d-flex align-items-center gap-3 text-default"
            [class.disabled]="!typeTheme.clickable"
          >
            <div class="badge badge-circle" [ngClass]="'badge-secondary-' + typeTheme.color" style="--size: 2rem">
              <i [ngClass]="typeTheme.icon" class="text-md"></i>
            </div>
            <div class="flex-fill">
              <div class="text-truncate" [innerHTML]="getTitleHtml(item.title, typeTheme.color)"></div>
              <div class="text-truncate">{{ item.subject }}</div>
            </div>
            <div class="text-xs" style="color: #9aa7b7">
              {{ item.date | timeAgo }}
            </div>
          </a>
        </li>
      </ng-container>
      <li class="p-3 text-light text-center border-bottom" *ngIf="notifications.length === 0">
        No Notification available
      </li>
    </ul>
    <div class="p-3">
      <a routerLink="/dashboard" class="text-sm" (click)="isOpen = false">
        View all notification <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
      </a>
    </div>
  </div>
</ng-template>
