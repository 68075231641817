import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { VideoItem } from './video-player'
import { DialogService } from '../../services'
import { HelpVideoDialogService } from './help-video-dialog.service'

// @dynamic
@Component({
  selector: 'app-help-video-dialog',
  templateUrl: './help-video-dialog.component.html',
  styleUrls: ['./help-video-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class HelpVideoDialogComponent implements AfterViewInit {
  title = 'Walkthrough Video'

  ready = false
  relatedVideos: VideoItem[] = []

  @ViewChild('video', { static: false }) elementVideo: ElementRef<HTMLVideoElement>

  constructor(
    private dialog: DialogService,
    public dialogRef: MatDialogRef<HelpVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      videoId: string
      openRelatedVideos: HelpVideoDialogService['openRelatedVideos']
    }
  ) {}

  async ngAfterViewInit() {
    const videoId = this.data.videoId

    const { initVideoPlayer, findRelatedVideos } = await import('./video-player')

    this.ready = true

    initVideoPlayer(this.elementVideo.nativeElement, videoId, {
      // onReady: () => {
      //   this.ready = true
      // }
    })

    this.relatedVideos = findRelatedVideos(videoId)
  }

  showRelatedVideos() {
    this.close()
    this.data.openRelatedVideos(this.relatedVideos)
  }

  close() {
    this.dialogRef.close()
  }
}
