import { HttpErrorResponse } from '@angular/common/http'
import { createAction, props, union } from '@ngrx/store'

export const ActionFailed = createAction(
  '[Utility] Action Failed',
  props<{ error: HttpErrorResponse | Error | string; skip401?: boolean }>()
)
export const ActionSkipped = createAction('[Utility] Action Skipped')
export const CloseAllDialogs = createAction('[Utility] Close All Dialogs')

// @todo - passing custom error message to ActionFailed can be done like this
//         catchError(() => throwError({error: 'Unable to mark for no follow-up.'}))
export const all = union({
  ActionFailed,
  ActionSkipped,
})

export type UtilityActionsUnion = typeof all
