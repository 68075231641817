<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Template Settings</h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>
  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box">
      <div class="box-body">
        <h6 class="section-title rounded-top overflow-hidden">Adjust your website colors, fonts, or favicon.</h6>
        <div class="row px-3">
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.primaryColor">
            <mat-form-field2 class="mat-block">
              <input
                matInput
                [value]="form.get('primaryColor').value"
                placeholder="Primary Color"
                [colorPicker]="form.get('primaryColor').value"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                (colorPickerChange)="onColorSelected('primaryColor', $event)"
              />
              <mat-icon
                matSuffix
                fontSet="fas"
                fontIcon="fa-circle"
                class="color-picker-value-indicator"
                [style.color]="form.get('primaryColor').value"
              ></mat-icon>
            </mat-form-field2>
          </div>
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoFontFamily">
            <mat-form-field2 class="mat-block">
              <input
                matInput
                formControlName="fontFamily"
                placeholder="Font Family"
                [style.font-family]="form.get('logoFontFamily').value"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoTxt">
            <mat-form-field2 class="mat-block">
              <input matInput formControlName="logoTxt" placeholder="Logo Text" />
            </mat-form-field2>
          </div>
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoSubTxt">
            <mat-form-field2 class="mat-block">
              <input matInput formControlName="logoSubTxt" placeholder="Subtitle Text" />
            </mat-form-field2>
          </div>

          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoColor">
            <mat-form-field2 class="mat-block">
              <input
                matInput
                [value]="form.get('logoColor').value"
                placeholder="Logo Color"
                [colorPicker]="form.get('logoColor').value"
                [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                (colorPickerChange)="onColorSelected('logoColor', $event)"
              />
              <mat-icon
                matSuffix
                fontSet="fas"
                fontIcon="fa-circle"
                class="color-picker-value-indicator"
                [style.color]="form.get('logoColor').value"
              ></mat-icon>
            </mat-form-field2>
          </div>
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoFontFamily">
            <mat-form-field2 class="mat-block">
              <input
                matInput
                formControlName="logoFontFamily"
                placeholder="Logo Font Family"
                [style.font-family]="form.get('logoFontFamily').value"
              />
            </mat-form-field2>
          </div>
          <div class="col-sm-4" *ngIf="!disabledSettingsForTemplate?.logoFontSize">
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                min="1"
                matInput
                formControlName="logoFontSize"
                placeholder="Logo Font Size"
                [style.font-size.px]="form.get('logoFontSize').value"
              />
              <mat-error *ngIf="form.get('logoFontSize').touched && form.get('logoFontSize').hasError('min')">
                Font size is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <div
        class="box-body"
        *ngIf="website.type === websiteTypes.RentalWebsite && website.template !== templateNames.SandratiTemplate"
      >
        <h6 class="section-title">Change your website subtitles, taglines,and descriptions</h6>
        <div class="row px-3">
          <div class="col-sm-12" *ngIf="!disabledSettingsForTemplate?.tagline1">
            <mat-form-field2 class="mat-block">
              <input matInput formControlName="tagline1" placeholder="Tagline 1" />
            </mat-form-field2>
          </div>
          <div class="col-sm-12" *ngIf="!disabledSettingsForTemplate?.tagline2">
            <mat-form-field2 class="mat-block">
              <input matInput formControlName="tagline2" placeholder="Tagline 2" />
            </mat-form-field2>
          </div>
          <div class="col-sm-12" *ngIf="!disabledSettingsForTemplate?.tagline3">
            <mat-form-field2 class="mat-block">
              <input matInput formControlName="tagline3" placeholder="Tagline 3" />
            </mat-form-field2>
          </div>
          <div class="col-sm-12" *ngIf="!disabledSettingsForTemplate?.footer_desc">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                formControlName="footer_desc"
                placeholder="Footer description"
                cdkTextareaAutosize
              ></textarea>
              <mat-hint>Add a short description to be placed in the website footer.</mat-hint>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
