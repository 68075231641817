import * as R from 'ramda'
import {
  addRentalComplete,
  addTagsToRental,
  addTagsToRentalComplete,
  archiveRentalComplete,
  deleteRentalComplete,
  loadArchivedRentalsComplete,
  loadRentalComplete,
  loadRentalImages,
  loadRentalImagesComplete,
  loadRentalRatesComplete,
  loadRentalsComplete,
  loadRentalsImageCountsComplete,
  loadRentalsPrimaryImages,
  loadRentalsPrimaryImagesComplete,
  loadSubdomainComplete,
  restrictUser,
  restrictUserAll,
  restrictUserAllComplete,
  restrictUserComplete,
  restrictUsers,
  restrictUsersComplete,
  selectRental,
  toggleOwnerRentals,
  toggleOwnerRentalsComplete,
  unArchiveRentalComplete,
  unRestrictUser,
  unRestrictUserAll,
  unRestrictUserAllComplete,
  unRestrictUserComplete,
  updateAllRentalsCheckTimesComplete,
  updateMultipleRentalsComplete,
  updateOwners,
  updateOwnersComplete,
  updateRental,
  updateRentalBaseRate,
  updateRentalBaseRateComplete,
  updateRentalComplete,
  updateRentalCustomFields,
  updateRentalCustomFieldsComplete,
  updateRentalDetails,
  updateRentalDetailsComplete,
  updateRentalGPSComplete,
  updateRentalImageOrderComplete,
  updateRentalInstructions,
  updateRentalInstructionsComplete,
  updateRentalPaymentScheduleComplete,
  UpdateRentalsStatusComplete,
  UpdateRentalStatusComplete,
  updateRentalTaxesComplete,
} from './rental.actions'
import { adapter, initialState, State } from './rental.state'
import { Action, createReducer, on } from '@ngrx/store'
import { Rental } from './rental.models'
import * as lodash from 'lodash'
import { upsertOne, updateMany, updateOne } from '../utility'
import {
  addRateMappingComplete,
  deleteRateMappingComplete,
  deleteRateMappingsComplete,
  setRateMappingsLastSyncComplete,
  toggleRateMappingsSyncComplete,
  updateRateMappingsComplete,
} from './rental-rate-mapping.actions'

export const reducer = createReducer(
  initialState,
  on(loadRentalsComplete, (state, { rentals }) => adapter.addAll(rentals, lodash.merge(state, { isLoaded: true }))),
  on(selectRental, (state, { id }) => ({ ...state, selectedId: id })),
  on(addRentalComplete, (state, { rental }) => adapter.addOne(rental, state)),
  on(loadRentalComplete, (state, { rental }) => upsertOne(adapter, rental, state)),
  on(deleteRentalComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(loadSubdomainComplete, (state, { domain }) => ({ ...state, subdomain: domain })),
  on(loadRentalsImageCountsComplete, (state, { updates }) =>
    updateMany(
      adapter,
      updates,
      lodash.merge(state, {
        imageCountsLoaded: true,
      })
    )
  ),
  on(
    updateRentalTaxesComplete,
    updateRentalPaymentScheduleComplete,
    updateRentalImageOrderComplete,
    updateRentalGPSComplete,
    (state, { update }) => updateOne(adapter, update, state)
  ),
  on(updateRentalComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingRental: false,
      })
    )
  ),
  on(UpdateRentalStatusComplete, (state, { update }) => updateOne(adapter, update, state)),
  on(UpdateRentalsStatusComplete, (state, { update }) => updateMany(adapter, update, state)),
  on(loadRentalRatesComplete, updateAllRentalsCheckTimesComplete, updateMultipleRentalsComplete, (state, { updates }) =>
    updateMany(adapter, updates, { ...state, isRatesLoaded: true })
  ),
  on(updateOwnersComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isUpdatingOwners: false,
      })
    )
  ),
  on(archiveRentalComplete, (state, { rental }) =>
    adapter.removeOne(
      rental.id,
      lodash.merge(state, {
        archivedRentals: R.append(rental, state.archivedRentals),
      })
    )
  ),
  on(unArchiveRentalComplete, (state, { rental }) =>
    adapter.upsertOne(
      rental,
      lodash.merge(state, {
        archivedRentals: R.reject((r: Rental) => r.id === rental.id, state.archivedRentals),
      })
    )
  ),
  on(loadArchivedRentalsComplete, (state, { rentals }) => ({ ...state, archivedRentals: rentals })),
  on(toggleOwnerRentalsComplete, (state, { updates }) =>
    updateMany(
      adapter,
      updates,
      lodash.merge(state, {
        isUpdatingOwners: false,
      })
    )
  ),
  on(restrictUsersComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isRestrictingUsers: false,
      })
    )
  ),
  on(addTagsToRentalComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingTags: false,
      })
    )
  ),
  on(updateRentalCustomFieldsComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingCustom: false,
      })
    )
  ),
  on(updateRentalDetailsComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingDetailInfo: false,
      })
    )
  ),
  on(updateRentalInstructionsComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingRentalInstructions: false,
      })
    )
  ),
  on(updateRentalBaseRateComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isSavingBaseRate: null,
      })
    )
  ),
  on(restrictUserComplete, unRestrictUserComplete, (state, { update }) =>
    updateOne(
      adapter,
      update,
      lodash.merge(state, {
        isRestrictingUsers: false,
      })
    )
  ),
  on(restrictUserAllComplete, unRestrictUserAllComplete, (state, { updates }) =>
    updateMany(
      adapter,
      updates,
      lodash.merge(state, {
        isRestrictingUsers: false,
      })
    )
  ),
  on(loadRentalImages, (state) => ({ ...state, isImageLoaded: false })),
  on(loadRentalImagesComplete, (state, { update }) => {
    const images = R.sortBy((img) => img.order || Infinity, update.changes.images)

    return updateOne(
      adapter,
      {
        id: update.id as string,
        changes: { images },
      },
      { ...state, isImageLoaded: true }
    )
  }),
  on(loadRentalsPrimaryImages, (state) => ({ ...state, isPrimaryImagesLoaded: false })),
  on(loadRentalsPrimaryImagesComplete, (state, { updates }) =>
    updateMany(adapter, updates, { ...state, isPrimaryImagesLoaded: true })
  ),
  on(updateOwners, toggleOwnerRentals, (state) => ({ ...state, isUpdatingOwners: true })),
  on(restrictUser, restrictUserAll, unRestrictUser, unRestrictUserAll, restrictUsers, (state) => ({
    ...state,
    isRestrictingUsers: true,
  })),
  on(updateRental, (state) => ({ ...state, isSavingRental: true })),
  on(addTagsToRental, (state) => ({ ...state, isSavingTags: true })),
  on(updateRentalCustomFields, (state) => ({ ...state, isSavingCustom: true })),
  on(updateRentalDetails, (state) => ({ ...state, isSavingDetailInfo: true })),
  on(updateRentalInstructions, (state) => ({ ...state, isSavingRentalInstructions: true })),
  on(updateRentalBaseRate, (state, { form }) => ({ ...state, isSavingBaseRate: form ? 'save' : 'clear' })),
  // rate mappings
  on(
    //
    addRateMappingComplete,
    deleteRateMappingComplete,
    (state, { update }) => updateOne(adapter, update, state)
  ),
  on(
    //
    toggleRateMappingsSyncComplete,
    deleteRateMappingsComplete,
    setRateMappingsLastSyncComplete,
    updateRateMappingsComplete,
    (state, { updates }) => updateMany(adapter, updates, state)
  )
)

export function rentalReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
