<div class="modal-header">
  <h5 class="modal-title">{{ config.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-select-search
    bindValue="id"
    bindLabel="name"
    [inline]="false"
    [items]="rentals"
    [ctrl]="form.get('rental')"
    [multiple]="false"
    placeholder="Select Rental"
  >
    <ng-container class="error-container">
      <mat-error> Rental is <strong>required</strong> </mat-error>
    </ng-container>
  </app-select-search>

  <p>{{ config.help }}</p>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> {{ config.buttonText }}
  </button>
</div>
