import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import * as reducer from './reducer'
import { FormulaState } from './state'
import {
  selectRentalEntities,
  selectAllApiChannelViews,
  untilDestroy,
  selectChannelEntities,
} from '@tokeet-frontend/tv3-platform'
import { BookingFormulaResponseView, getFormulaScheduling } from './model'
import * as moment from 'moment'
import * as lodash from 'lodash'
import { selectAllCustomChannels, selectCustomChannelEntities } from '@tokeet-frontend/channels'

export const bookingFormulaState = createFeatureSelector<FormulaState>('formulas')

export const selectAllBookingFormulas = createSelector(bookingFormulaState, reducer.selectFormulasAll)

export const selectBookingFormulasLoaded = createSelector(bookingFormulaState, (s: FormulaState) => s.isLoaded)

export const selectAllEnabledBookingFormulas = createSelector(selectAllBookingFormulas, (items) =>
  items?.filter((t) => !!t.status)
)

export const selectAllBookingFormulaViews = createSelector(
  selectRentalEntities,
  selectAllBookingFormulas,
  selectChannelEntities,
  selectCustomChannelEntities,
  (rentalsById, items, channelsById, customChannelsById) => {
    const months = moment.months()
    return items.map((item): BookingFormulaResponseView => {
      const rentals = item.rentals?.map((rentalId) => rentalsById[rentalId])
      const range = getFormulaScheduling(item)
      return {
        ...item,
        channelView: channelsById[item.channel_id]?.friendlyName || customChannelsById[item.channel_id]?.friendly_name,
        rentalsView: !!rentals?.length ? rentals.map((r) => r.name).join(', ') : 'All Rentals',
        months: `${months[range.start_month]} - ${months[range.end_month]}`,
      }
    })
  }
)

export const selectChannelBookingFormulas = (channelId: string) =>
  createSelector(selectAllBookingFormulas, (items) => items?.filter((t) => t.channel_id === channelId))

export const selectBookingFormulaById = (id: string) =>
  createSelector(reducer.selectFormulasEntities, (itemsById) => itemsById[id])

export const selectChannelsForFormulas = createSelector(
  selectAllApiChannelViews,
  selectAllCustomChannels,
  (channelViews, customChannels) => {
    return lodash.sortBy(
      [
        ...channelViews.map((c) => ({ id: c.id, name: c.friendlyName })),
        ...customChannels.map((c) => ({ id: c.pkey, name: c.friendly_name })),
      ],
      (t) => lodash.toLower(t.name)
    )
  }
)
