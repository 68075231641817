import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { PreferenceRequest } from '@tv3/interfaces/requests/preference.request'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { BrandingRequest } from '@tv3/interfaces/requests/branding.request'
import { PreferenceResponse } from '@tv3/interfaces/responses/preference.response'
import { Preference } from './preferences.model'

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  get(): Observable<Preference> {
    const url = '@api/account/preferences/'

    return this.http.get<object>(url).pipe(map((response) => Preference.deserialize(response)))
  }

  update(payload: PreferenceRequest): Observable<Preference> {
    const url = '@api/account/preferences/'

    return this.http.put<PreferenceResponse>(url, payload).pipe(
      map((response) => Preference.deserialize(response)),
      tap(() => this.toast.success('Preferences updated successfully!'))
    )
  }

  updateBranding(request: BrandingRequest) {
    const url = '@api/account/preferences/'

    return this.http.put<PreferenceResponse>(url, { branding: request }).pipe(
      map((response) => Preference.deserialize(response)),
      tap(() => this.toast.success('Branding updated successfully!'))
    )
  }
}
