import { Injectable } from '@angular/core'
import {
  Channel,
  ChannelNameTokens,
  DialogService,
  selectAllRentals,
  selectOnce,
  Toaster,
} from '@tokeet-frontend/tv3-platform'
import { ConnectAirbnbWizardComponent } from './connect-airbnb-wizard/connect-airbnb-wizard.component'
import { ConnectBDCWizardComponent } from './connect-bdc-wizard/connect-bdc-wizard.component'
import { ConnectAgodaWizardComponent } from './connect-agoda-wizard/connect-agoda-wizard.component'
import { ComponentType } from '@angular/cdk/portal'
import { ConnectExpediaWizardComponent } from './connect-expedia-wizard/connect-expedia-wizard.component'
import { ConnectTiketWizardComponent } from './connect-tiket-wizard/connect-tiket-wizard.component'
import { tap, switchMap, switchMapTo, map, filter } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { selectActiveTokeetPlan } from '@tv3/store/plan/plan.selectors'
import { MatDialogConfig } from '@angular/material/dialog'
import { ConnectTripWizardComponent } from './connect-trip-wizard/connect-trip-wizard.component'
import { ConnectVrboWizardComponent } from './connect-vrbo-wizard/connect-vrbo-wizard.component'
import { ConnectHvmiWizardComponent } from './connect-hvmi-wizard/connect-hvmi-wizard.component'
import { ConnectRUWizardComponent } from './connect-ru-wizard/connect-ru-wizard.component'
import { ConnectHoliduWizardComponent } from './connect-holidu-wizard/connect-holidu-wizard.component'
import { ConnectGVRWizardComponent } from './connect-gvr-wizard/connect-gvr-wizard.component'

@Injectable()
export class ChannelConnectWizardsService {
  constructor(private dialogService: DialogService, private toaster: Toaster, private store: Store<any>) {}

  connect(channel: Channel) {
    switch (channel.name) {
      case ChannelNameTokens.AirBnBV2API:
        return this.openDialog(ConnectAirbnbWizardComponent, channel)
      case ChannelNameTokens.BookingAPI:
        return this.openDialog(ConnectBDCWizardComponent, channel)
      case ChannelNameTokens.AgodaAPI:
        return this.openDialog(ConnectAgodaWizardComponent, channel)
      case ChannelNameTokens.ExpediaAPI:
        return this.openDialog(ConnectExpediaWizardComponent, channel)
      case ChannelNameTokens.Tiket:
        return this.paidSubRequired(channel).pipe(switchMapTo(this.openDialog(ConnectTiketWizardComponent, channel)))
      case ChannelNameTokens.Trip:
        return this.paidSubRequired(channel).pipe(switchMapTo(this.openDialog(ConnectTripWizardComponent, channel)))
      case ChannelNameTokens.Homeaway:
      case ChannelNameTokens.Hvmi:
      case ChannelNameTokens.RentalsUnited:
        return this.paidSubRequired(channel).pipe(switchMapTo(this.openFeedChannel(channel)))
      case ChannelNameTokens.Holidu:
        return this.connectToHolidu(channel)
      case ChannelNameTokens.GoogleVacationRentals:
        return this.connectToGVR(channel)
    }
  }

  openFeedChannel(channel: Channel) {
    switch (channel.name) {
      case ChannelNameTokens.Homeaway:
        return this.openDialog(ConnectVrboWizardComponent, channel)
      case ChannelNameTokens.Hvmi:
        return this.openDialog(ConnectHvmiWizardComponent, channel)
      case ChannelNameTokens.Holidu:
        return this.connectToHolidu(channel)
      case ChannelNameTokens.GoogleVacationRentals:
        return this.connectToGVR(channel)
      case ChannelNameTokens.RentalsUnited:
        return this.openDialog(ConnectRUWizardComponent, channel)
    }
  }

  connectToHolidu(channel: Channel) {
    const msg =
      'Only paying users with a minimum of 15 rentals can connect to Holidu. Please update your subscription to continue.'
    return this.paidSubRequired(channel, msg).pipe(
      switchMapTo(this.store.pipe(selectOnce(selectAllRentals))),
      filter((rentals) => {
        if (rentals.length < 5) {
          this.toaster.info('You must have at least 15 rentals to connect to Holidu.')
          return false
        }
        return true
      }),
      switchMap(() => this.openDialog(ConnectHoliduWizardComponent, channel))
    )
  }

  connectToGVR(channel: Channel) {
    const msg = 'Only paying users can connect to Google Vacation Rentals. Please update your subscription to continue.'
    return this.paidSubRequired(channel, msg).pipe(switchMap(() => this.openDialog(ConnectGVRWizardComponent, channel)))
  }

  private paidSubRequired(channel: Channel, unpaidSubsMessage?: string) {
    unpaidSubsMessage =
      unpaidSubsMessage ||
      `Only paying users can connect to ${channel.friendlyName}. Please update your subscription to continue.`
    return this.isPaidSub().pipe(
      tap((isPaid) => {
        if (!isPaid) this.toaster.info(unpaidSubsMessage)
      }),
      filter((isPaid) => isPaid)
    )
  }

  private isPaidSub() {
    return this.store.pipe(
      selectOnce(selectActiveTokeetPlan),
      map((plan) => plan.status === 'active')
    )
  }

  private openDialog<T>(component: ComponentType<T>, channel: Channel) {
    return this.dialogService
      .openVariableDialog(component, {
        width: '75%',
        height: 'auto',
        disableClose: true,
        data: { channel },
      })
      .afterClosed()
  }

  private openDrawer<T>(component: ComponentType<T>, channel: Channel) {
    const defaultConfig: MatDialogConfig = {
      data: { channel },
      panelClass: ['drawer-full-90'],
    }

    return this.dialogService.openDrawer(component, defaultConfig).afterClosed()
  }
}
