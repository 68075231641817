export interface CsvDataParseResult<T> {
  warning?: string
  error?: string
  value?: T
}

export interface CsvFileColumnDef {
  name: string
  field: string // parsed value will be saved in this field, `name` will be used if not set
  default: any // if no value, this will be used
  required: boolean
  parse: (string, CsvFileColumnDef) => CsvDataParseResult<any>
  type: string
  max?: number // for number
  min?: number // for number
  width: string // example: 150px,
  rowValidators?: ((row: any) => true | string)[]
}

export interface CsvFileParserGuide {
  description: string
  list: string[]
  sample?: (string | number)[][]
}

export const CsvImportedDateFormat = 'YYYY-MM-DD'
