import { on } from '@ngrx/store'
import * as R from 'ramda'
import { State } from '../channels.state'
import {
  GetGVRStatusSuccess,
  ConnectGVRSuccess,
  GetGVRRentalValidationsSuccess,
  CreateGVRListingSuccess,
  UpdateGVRListingSuccess,
  ActivateGVRListingSuccess,
  DeactivateGVRListingSuccess,
  DeleteGVRListingSuccess,
  GetGVRListingsSuccess,
  DisconnectGVRSuccess,
} from './actions'
import { GVRListingModel } from './model'

export function getGVRReducers() {
  return [
    on(GetGVRStatusSuccess, (state: State, { status }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        status,
      },
    })),
    on(ConnectGVRSuccess, (state: State) => ({
      ...state,
      gvr: {
        ...state.gvr,
        status: { connected: true },
      },
    })),
    on(GetGVRRentalValidationsSuccess, (state: State, { validations }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        validations,
        validationsLoaded: true,
      },
    })),
    on(CreateGVRListingSuccess, UpdateGVRListingSuccess, (state: State, { listing }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        listings: state.gvr.listings.map((l) =>
          l.rentalId === listing.rentalId ? { ...l, ...listing, active: true } : l
        ),
      },
    })),
    on(ActivateGVRListingSuccess, DeactivateGVRListingSuccess, (state: State, { rentalId, active }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        listings: state.gvr.listings.map((l) => (l.rentalId === rentalId ? { ...l, active: active } : l)),
      },
    })),
    on(DeleteGVRListingSuccess, (state: State, { rentalId }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        listings: state.gvr.listings.map((l) =>
          l.rentalId === rentalId
            ? ({
                ...l,
                active: false,
                lastSync: null,
              } as GVRListingModel)
            : l
        ),
      },
    })),
    on(GetGVRListingsSuccess, (state: State, { listings }) => ({
      ...state,
      gvr: {
        ...state.gvr,
        listings,
      },
    })),
    on(DisconnectGVRSuccess, (state: State) => {
      return {
        ...state,
        gvr: {
          ...state.gvr,
          status: { connected: true },
        },
      }
    }),
  ]
}
