import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Inquiry, InquiryPaymentStatus } from '@tv3/store/inquiry/inquiry.model'
import { FeeTypes, InquiryCost, isExternalBookingSource } from '@tokeet/cost-resolver'
import {
  Destroyable,
  Rental,
  isSomething,
  selectRentalById,
  untilDestroy,
  distinctUntilChangedByIds,
} from '@tokeet-frontend/tv3-platform'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { Store, select } from '@ngrx/store'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { BookingFee, BookingFeesService, FeeModalityLabels } from '@tokeet-frontend/rentals'

@Component({
  selector: 'app-overlay-booking-charges',
  templateUrl: './booking-charges.component.html',
  styleUrls: ['./booking-charges.component.scss'],
})
export class BookingChargesComponent extends Destroyable implements OnInit {
  private inquiry$ = new BehaviorSubject<Inquiry>(null)
  @Input() set inquiry(inquiry: Inquiry) {
    this.inquiry$.next(inquiry)
  }
  get inquiry() {
    return this.inquiry$.value
  }

  @Input() direction = 'h'

  @Input() applicableFees = true
  applicableFees$: Observable<BookingFee[]>
  feeTypes = FeeTypes
  feeModalityLabels = FeeModalityLabels

  @Input() readonly: boolean
  @Output() abbBreakdown = new EventEmitter()

  rental: Rental

  charges: InquiryCost
  formulaAdjustedCost: number

  get currencySymbol() {
    return this.rental?.currency?.symbol
  }
  get currencyCode() {
    return this.rental?.currency?.code
  }

  status: { paid: boolean; remaining: number; status: InquiryPaymentStatus }

  get isExternalBooking() {
    return (
      !!this.inquiry?.booked && isSomething(this.inquiry?.cost) && isExternalBookingSource(this.inquiry?.inquirySource)
    )
  }

  constructor(
    private store: Store<any>,
    private inquiryChargeService: InquiryChargeService,
    private inquiryService: InquiryService,
    private bookingFeesService: BookingFeesService,
    private sharedDialogsService: InquirySharedDialogsService
  ) {
    super()
  }

  ngOnInit() {
    this.inquiry$
      .pipe(
        filter(isSomething),
        untilDestroy(this),
        switchMap((inquiry) =>
          combineLatest([
            this.inquiryChargeService.getCharges(inquiry).pipe(map((i) => i?.charges)),
            this.inquiryService.getCostAfterFormula(inquiry.id),
            this.store.pipe(select(selectRentalById(inquiry.rentalId))),
            this.inquiryChargeService.getBookingPaymentStatusForInquiry(inquiry),
          ])
        )
      )
      .subscribe(([charges, cost, rental, status]) => {
        this.charges = charges
        this.formulaAdjustedCost = cost
        this.rental = rental
        this.status = status
      })

    this.applicableFees$ = this.inquiry$.pipe(
      filter(isSomething),
      distinctUntilChangedByIds(),
      switchMap((inquiry) => this.bookingFeesService.getApplicableFeesForInquiry(inquiry, inquiry.account, false))
    )
  }

  onUpdateQuote() {
    this.sharedDialogsService.openUpdateQuote(this.inquiry)
  }
}
