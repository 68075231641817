import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  ActionFailed,
  Channel,
  ConfirmDialogService,
  Destroyable,
  SaveForm,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'

import {
  ConnectHolidu,
  ConnectHoliduFailure,
  ConnectHoliduSuccess,
  DisconnectHolidu,
  HoliduStatus,
  selectHoliduStatus,
} from '@tokeet-frontend/channels'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ChannelConnectHelperService } from '../../channel-connect-helper.service'
@Component({
  selector: 'app-connect-holidu-wizard-step1',
  templateUrl: './connect-holidu-wizard-step1.component.html',
  styleUrls: ['./connect-holidu-wizard-step1.component.scss'],
  providers: [ChannelConnectHelperService],
})
export class ConnectHoliduWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()
  @Input() channel: Channel

  form = this.fb.group({
    providerId: ['', [Validators.required]],
  })

  isConnecting = false
  status?: HoliduStatus

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private confirm: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectHoliduStatus), untilDestroy(this)).subscribe((status) => {
      this.status = status
      if (status?.connected) {
        this.form.patchValue({
          providerId: status.providerId,
        })
        this.form.get('providerId').disable()
      } else {
        this.form.patchValue({
          providerId: null,
        })
        this.form.get('providerId').enable()
      }
    })

    this.actions.pipe(ofType(ConnectHoliduSuccess), untilDestroy(this)).subscribe(() => {
      this.next.emit()
      this.isConnecting = false
    })
    this.actions.pipe(ofType(ConnectHoliduFailure), untilDestroy(this)).subscribe(() => {
      this.isConnecting = false
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { providerId } = form.getRawValue()
    this.store.dispatch(ConnectHolidu({ providerId }))
    this.isConnecting = true
  }

  onDisconnect() {
    const { providerId } = this.form.getRawValue()
    this.confirm
      .confirm({
        title: 'Disconnect?',
        body: 'Are you sure you want to disconnect Holidu?',
      })
      .subscribe(() => {
        this.store.dispatch(DisconnectHolidu({ providerId }))
      })
  }
}
