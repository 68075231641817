import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
  ActionFailed,
  compactObject,
  Destroyable,
  ExpenseCategoryReportItem,
  ExpenseService,
  Rental,
  selectAllRentals,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment/moment'
import * as lodash from 'lodash'
import { FormBuilder } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators'

export interface ExpenseReportFilters {
  year: number
  month: number
  rentals: string[]
}

@Component({
  selector: 'app-expense-report-filters',
  templateUrl: './expense-report-filters.component.html',
  styleUrls: ['./expense-report-filters.component.scss'],
})
export class ExpenseReportFiltersComponent extends Destroyable implements OnInit {
  @Output() data = new EventEmitter<Record<string, ExpenseCategoryReportItem[]>>()

  rentals$ = this.store.pipe(select(selectAllRentals))
  months = moment.months().map((m, i) => ({ id: i, name: m }))
  years = lodash.range(-2, 2).map((y) => ({ id: moment().year() + y, name: moment().year() + y }))
  filters = this.fb.group({
    year: [moment().year()],
    month: [moment().month()],
    rentals: [[]],
  })

  isLoading = false
  get filtersData(): ExpenseReportFilters {
    return this.filters.getRawValue()
  }

  constructor(private fb: FormBuilder, private store: Store<any>, private expenseService: ExpenseService) {
    super()
  }

  ngOnInit() {
    this.filters.valueChanges
      .pipe(
        startWith(this.filters.getRawValue()),
        debounceTime(500),
        tap(() => (this.isLoading = true)),
        switchMap((filters) =>
          this.expenseService.getCategoryReports(
            compactObject({
              rentals: filters.rentals,
              ...this.getYearRange(filters.year, filters.month),
            })
          )
        ),
        tap(() => (this.isLoading = false)),
        untilDestroy(this)
      )
      .subscribe(
        (itemsByCategory) => {
          this.data.emit(itemsByCategory)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
          this.isLoading = false
        }
      )
  }

  getYearRange(year: number, month: number) {
    const start = moment.utc().year(year).month(month).startOf('month')
    const end = moment.utc().year(year).month(month).endOf('month')
    return { start: start.unix(), end: end.unix() }
  }

  onResetFilters() {
    this.filters.patchValue({
      rentals: [],
      month: moment().month(),
      year: moment().year(),
    })
  }
}
