import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ExpenseService } from './expense.service'
import {
  AddExpense,
  AddExpenseComplete,
  AddExpenses,
  AddExpensesComplete,
  DeleteExpense,
  DeleteExpenseComplete,
  DeleteExpenses,
  DeleteExpensesComplete,
  LoadExpenses,
  LoadExpensesByIds,
  LoadExpensesByIdsComplete,
  LoadExpensesComplete,
  LoadExpenseSettings,
  LoadExpenseSettingsComplete,
  LoadFilteredExpenses,
  LoadFilteredExpensesComplete,
  UpdateExpense,
  UpdateExpenseApprovalStatus,
  UpdateExpenseApprovalStatusComplete,
  UpdateExpenseComplete,
  UpdateExpenseSettings,
  UpdateExpenseSettingsComplete,
} from './expense.actions'
import { ActionFailed } from '../utility'
import { Toaster } from '../../services/toaster.service'

@Injectable()
export class ExpenseEffects {
  @Effect()
  loadExpenses$ = this.actions$.pipe(
    ofType(LoadExpenses),
    switchMap(({ filters, pagination }) =>
      this.expenses.all(filters, pagination).pipe(
        map((expenses) => LoadExpensesComplete({ expenses })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // load by ids
  @Effect()
  loadExpensesByIds$ = this.actions$.pipe(
    ofType(LoadExpensesByIds),
    switchMap(({ ids }) =>
      this.expenses.getByIds(ids).pipe(
        map((expenses) => LoadExpensesByIdsComplete({ expenses })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  // todo: remove. left just for compatibility
  @Effect()
  loadFilteredExpenses$ = this.actions$.pipe(
    ofType(LoadFilteredExpenses),
    switchMap(({ filters }) =>
      this.expenses.all(filters).pipe(
        map((expenses) => LoadFilteredExpensesComplete({ expenses })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  // -----

  @Effect()
  addExpense$ = this.actions$.pipe(
    ofType(AddExpense),
    switchMap(({ payload }) =>
      this.expenses.create(payload).pipe(
        map((expense) => AddExpenseComplete({ expense })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addExpenses$ = this.actions$.pipe(
    ofType(AddExpenses),
    switchMap(({ items }) =>
      this.expenses.createBatch(items).pipe(
        tap(() => this.toast.success(`Expense items added successfully.`)),
        map((expenses) => AddExpensesComplete({ expenses })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateExpense$ = this.actions$.pipe(
    ofType(UpdateExpense),
    switchMap(({ payload, expenseId }) =>
      this.expenses.update(expenseId, payload).pipe(
        map((expense) => UpdateExpenseComplete({ update: { id: expenseId, changes: expense } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updatestatus$ = this.actions$.pipe(
    ofType(UpdateExpenseApprovalStatus),
    switchMap(({ status, expenseId }) =>
      this.expenses.update(expenseId, { approval_status: status }).pipe(
        map((expense) => UpdateExpenseApprovalStatusComplete({ update: { id: expenseId, changes: expense } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteExpense$ = this.actions$.pipe(
    ofType(DeleteExpense),
    switchMap(({ id }) =>
      this.expenses.delete(id).pipe(
        map((expense) => DeleteExpenseComplete({ id: expense.pkey })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteExpenses$ = this.actions$.pipe(
    ofType(DeleteExpenses),
    concatMap(({ ids }) =>
      this.expenses.deleteExpenses(ids).pipe(
        map((removedIds) => DeleteExpensesComplete({ ids: removedIds })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  getSettings$ = this.actions$.pipe(
    ofType(LoadExpenseSettings),
    concatMap(() =>
      this.expenses.getSettings().pipe(
        map((settings) => LoadExpenseSettingsComplete({ settings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateSettings$ = this.actions$.pipe(
    ofType(UpdateExpenseSettings),
    concatMap(({ payload }) =>
      this.expenses.updateSettings(payload).pipe(
        map((settings) => UpdateExpenseSettingsComplete({ settings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private expenses: ExpenseService, private toast: Toaster) {}
}
