import { Component, Input, OnInit } from '@angular/core'
import { Channel, ChannelNameTokens, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { HomeToGoRentalConfigView } from '../../../../store/channels.model'
import { Store } from '@ngrx/store'
import { CreateRentalConfig, UpdateRentalConfig } from '../../../../store/channels.actions'
import { BookingFeesService } from '@tokeet-frontend/rentals'
import { map, switchMap } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs'
import * as R from 'ramda'

@Component({
  selector: 'app-feed-connection-config',
  templateUrl: './feed-connection-config.component.html',
  styleUrls: ['./feed-connection-config.component.scss'],
})
export class FeedConnectionConfigComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() config: HomeToGoRentalConfigView
  @Input() primaryButtonClass = 'btn btn-secondary-info '

  channelNames = ChannelNameTokens
  hasNoFees$: Observable<boolean>

  shouldUpdate = new BehaviorSubject(true)

  constructor(private store: Store<any>, private bookingFeesService: BookingFeesService) {
    super()
  }

  ngOnInit() {
    this.hasNoFees$ = this.shouldUpdate.pipe(
      untilDestroy(this),
      switchMap(() =>
        this.bookingFeesService.getAllFees().pipe(
          map((fees) => fees.filter((f) => f.rental_id === this.config?.rental.id)),
          map((fees) => R.isEmpty(fees))
        )
      )
    )
  }

  onSave(payload) {
    // TODO - make sure this.config is updated after reducer updates the object
    if (this.config.pkey) {
      this.store.dispatch(UpdateRentalConfig({ ...payload, rental: this.config.rental }))
    } else {
      this.store.dispatch(CreateRentalConfig({ ...payload, rental: this.config.rental }))
    }
  }
}
