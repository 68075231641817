<div class="mx-4 mt-4">
  <div class="select-block-container" style="grid-template-columns: repeat(2, 1fr)">
    <div
      class="select-block"
      *ngFor="let action of data.actions"
      [class.disabled]="action.disabled"
      [matTooltip]="action.tooltip"
      [matTooltipPosition]="action.tooltipPosition || 'above'"
      [matTooltipClass]="action.tooltipClass"
    >
      <a (click)="onClickAction(action)">
        <i [ngClass]="action.icon"></i>
        <div class="text-nowrap">{{ action.text }}</div>
      </a>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
</div>
