import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { InvoiceTemplateLineItemCost } from '../../../../store/templates/template.model'
import {
  EditInvoiceTemplateCostComponent,
  EditInvoiceTemplateCostComponentParams,
} from './edit-invoice-template-cost.component'

@Injectable()
export class EditInvoiceTemplateCostService {
  constructor(private dialog: MatDialog) {}

  open(cost: InvoiceTemplateLineItemCost, config?: EditInvoiceTemplateCostComponentParams) {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      panelClass: ['variable-modal', 'modal-edit-invoice-template-cost'],
      data: { cost, config },
    }

    return this.dialog.open(EditInvoiceTemplateCostComponent, defaultConfig)
  }
}
