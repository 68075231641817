import { Observable, of } from 'rxjs'
import { concatMap, map, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { SharedContent } from '@tv3/store/shared-content/shared-content.model'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class SharedContentService {
  constructor(private http: HttpClient) {}

  all(): Observable<SharedContent[]> {
    const url = '@api/share/all/'

    return this.http.get(url).pipe(deserializeArray<SharedContent>(SharedContent))
  }

  share(payload: any): Observable<SharedContent> {
    const url = `@api/share/`

    return this.http.post(url, payload).pipe(map((response) => SharedContent.deserialize(response)))
  }

  changePassword(id: string, password: string): Observable<SharedContent> {
    const url = `@api/share/password/${id}`

    return this.http.put(url, { password }).pipe(map((response) => SharedContent.deserialize(response)))
  }

  update(id, changes): Observable<SharedContent> {
    const url = `@api/share/${id}`

    return this.http.put(url, changes).pipe(map((response) => SharedContent.deserialize(response)))
  }

  remove(id) {
    const url = `@api/share/delete/${id}`

    return this.http.delete<SharedContent>(url)
  }

  removeMultiple(ids: string[]): Observable<SharedContent[]> {
    return of(...ids).pipe(
      concatMap((id) => this.remove(id)),
      toArray()
    )
  }
}
