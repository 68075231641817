import { Pipe, PipeTransform } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { isSomething } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'isFiltering',
  pure: false,
})
export class IsFilteringPipe implements PipeTransform {
  transform(form: FormGroup, ignoreFields: string[] = [], defaultEmptyValues = ['all']): boolean {
    const data = form.value
    return Object.keys(data).some(
      (key) => !ignoreFields.includes(key) && !defaultEmptyValues.includes(data[key]) && isSomething(data[key])
    )
  }
}
