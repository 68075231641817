import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import * as R from 'ramda'
import { Preference } from '@tv3/store/preferences/preferences.model'
import { filter, take, tap } from 'rxjs/operators'
import {
  Account,
  AlertDialogService,
  asLocalDate,
  asUTCEpoch,
  ChannelService,
  Destroyable,
  isSomething,
  selectAccount,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import { EditBrandingLogoDialogService } from '@tv3/containers/invoices/shared/dialogs/edit-branding-logo-dialog/edit-branding-logo-dialog.service'
import { InvoiceService } from '@tv3/store/invoice/invoice.service'
import { Invoice, InvoiceStatus, isInvoiceUpdating, UpdateInvoice } from '@tokeet-frontend/invoices'
import * as moment from 'moment'
import { selectAccountPreference } from '@tv3/store/preferences/preferences.selectors'
import { LoadPreferences, UpdateBranding } from '@tv3/store/preferences/preferences.actions'
import { Guest } from '@tv3/store/guest/guest.model'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { BehaviorSubject, merge } from 'rxjs'
import { UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { Branding } from '@tv3/models/account/branding'
import { BrandingRequest } from '@tv3/interfaces/requests/branding.request'
import { OpenGuestOverlay } from '@tv3/store/overlay/overlay.actions'

export interface InvoiceDetailsChangeEvent {
  invoiceNum: string
  due: number
  date: number
  expires: number
  addressKey: string
}

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
})
export class InvoiceDetailsComponent extends Destroyable implements OnInit, OnChanges {
  @Input() editable = true
  @Input() guest: Guest
  @Input() inquiry?: Inquiry
  @Input() invoice: Invoice
  @Output() update = new EventEmitter<InvoiceDetailsChangeEvent>()
  @Output() close = new EventEmitter()

  @Input() rootForm: FormGroup

  logoUploadBoxOptions: UploadBoxOptions = {
    maxSize: 5 * 1024 * 1024, // 10MB,
    maxUploads: 1,
    autoUpload: true,
    cloudinary: true,
    guide: `You can only upload one file that is 5 MBand 70 PX *500 PX dimension.`,
  }

  form = this.fb.group({
    invoiceNum: [],
    date: [null, [Validators.required]],
    due: [null, [Validators.required]],
    expires: [],
    addressKey: [],
  })

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  minScheduleDate = moment().add(1, 'day').startOf('day').toDate()

  statuses: typeof InvoiceStatus = InvoiceStatus

  preference: Preference
  account: Account

  minDueDate = new BehaviorSubject<Date>(null)
  maxInvoiceDate = new BehaviorSubject<Date>(null)
  minExpiresDate$ = new BehaviorSubject<Date>(moment().startOf('day').toDate())
  maxExpiresDate$ = new BehaviorSubject<Date>(null)

  invoiceNum: string

  restrictedEmail = false

  isInvoiceUpdating$ = this.store.pipe(select(isInvoiceUpdating))

  constructor(
    private store: Store<fromRoot.State>,
    private sharedDialogsService: InquirySharedDialogsService,
    private editBrandingLogoDialogService: EditBrandingLogoDialogService,
    private auth: AuthService,
    private fb: FormBuilder,
    private alertDialog: AlertDialogService,
    private channelService: ChannelService
  ) {
    super()
  }

  ngOnInit() {
    const dueCtrl = this.form.get('due')
    merge(this.form.get('date').valueChanges, dueCtrl.valueChanges)
      .pipe(untilDestroy(this))
      .subscribe(() => {
        this.updateDateLimit()
      })

    const expireCtrl = this.form.get('expires')
    dueCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((due) => {
      if (due && !expireCtrl.value && dueCtrl.enabled) {
        expireCtrl.enable({ emitEvent: false })
        expireCtrl.setValue(moment(due).add(14, 'days').toDate())
      } else if (!due) {
        expireCtrl.disable()
      }
    })

    this.form.patchValue({
      due: asLocalDate(this.invoice.dueDate) || new Date(),
      date: asLocalDate(this.invoice.invoiceDate) || new Date(),
      expires: asLocalDate(this.invoice.expiresAt) || moment().add(14, 'days').toDate(),
      invoiceNum: this.invoice.invoiceNum,
      addressKey: this.invoice.addressKey,
    })

    this.form.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      const { date, due, expires, invoiceNum, addressKey } = this.form.getRawValue()
      this.update.emit({
        date: asUTCEpoch(date),
        due: asUTCEpoch(due),
        expires: asUTCEpoch(expires),
        invoiceNum,
        addressKey,
      } as InvoiceDetailsChangeEvent)
    })

    // always get fresh preference
    this.store.dispatch(LoadPreferences())

    this.store
      .pipe(
        select(selectAccountPreference),
        untilDestroy(this),
        filter((preference) => isSomething(preference)),
        tap((preference) => !this.invoice.id && this.setInvoiceNum(preference)) // only set invoice num for new invoice
      )
      .subscribe((preference) => {
        this.preference = preference
      })

    this.store
      .pipe(
        select(selectAccount(this.auth.user.account)),
        untilDestroy(this),
        filter((account) => isSomething(account))
      )
      .subscribe((account) => {
        this.account = account
      })
    this.rootForm.addControl('details', this.form)
    this.checkRecipientEmail()
  }

  updateDateLimit() {
    if (this.form.get('date').value) {
      const start = this.form.get('date').value
      this.minDueDate.next(start)
    }

    if (this.form.get('due').value) {
      const end = this.form.get('due').value
      this.maxInvoiceDate.next(end)
      this.minExpiresDate$.next(moment(end).add(1, 'days').startOf('day').toDate())
      this.maxExpiresDate$.next(moment(end).add(90, 'days').startOf('day').toDate())
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const editableChange = changes['editable']
    if (editableChange) {
      editableChange.currentValue ? this.form.enable() : this.form.disable()
    }
    const inquiryChange = changes['inquiry']
    if (inquiryChange && inquiryChange.currentValue) {
      this.guest = inquiryChange.currentValue.guest
      const oldGuest = R.path(['previousValue', 'guest'], inquiryChange) as Guest
      if (oldGuest && this.guest && oldGuest.id !== this.guest.id && this.invoice.id) {
        this.invoice.guestId = this.guest.id
        this.invoice.guestName = this.guest.name
        this.store.dispatch(UpdateInvoice({ invoice: this.invoice }))
      }
    }
  }

  onEditGuest() {
    this.sharedDialogsService.openEditGuest(this.inquiry)
  }

  onAddressChange(key: string) {
    this.form.patchValue({ addressKey: key || '' })
  }

  onLogoUploaded([item]) {
    const imageUrl = item.url
    imageUrl.replace('http:', 'https:')
    const branding = new Branding(this.preference.branding)
    branding.invoiceLogo = imageUrl
    this.store.dispatch(UpdateBranding({ request: branding.serialize() as BrandingRequest }))
  }

  onEditLogo() {
    const result = this.editBrandingLogoDialogService.open(this.preference).afterClosed()
    result.pipe(take(1)).subscribe((res) => {
      if (res === 'upload') {
        this.close.emit()
      }
    })
  }

  private setInvoiceNum(preference: Preference) {
    let invoiceNum = `${Date.now()}`
    if (preference.invoicePrefix) {
      invoiceNum = `${moment.utc().unix()}`
      invoiceNum = preference.invoicePrefix + invoiceNum.slice(-6)
    }

    this.form.patchValue({ invoiceNum })
  }

  private checkRecipientEmail() {
    let guestEmail = R.pathOr('', ['guest', 'primaryEmail'], this.inquiry)
    if (R.isEmpty(guestEmail)) {
      guestEmail = R.pathOr('', ['guestDetails', 'email'], this.inquiry)
    }
    const channel = this.channelService.channelFromEmail(guestEmail)
    if (!channel) {
      return
    }
    this.restrictedEmail = true

    this.alertDialog.open({
      title: 'Cannot send invoice',
      body: `Messages to this guest will be routed through ${channel}. As a result you are restricted in what you may include in your message. Invoices cannot be sent to this guest using their current email. ${channel} will block any message containing restricted content.`,
    })
  }

  onEdit(guest: Guest) {
    this.store.dispatch(OpenGuestOverlay({ guestId: guest.id }))
  }
}
