import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { SmartDeviceEffects } from './effects'
import { SmartDeviceService } from './service'
import { deviceReducer } from './reducer'
import { SmartDevicesGuard } from './guard'
import { SmartDeviceSettingsGuard } from './settings-guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('device', deviceReducer),
    EffectsModule.forFeature([SmartDeviceEffects]),
  ],
  declarations: [],
  providers: [SmartDeviceService, SmartDevicesGuard, SmartDeviceSettingsGuard],
  exports: [],
})
export class SmartDeviceStoreModule {}
