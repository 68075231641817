import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Toaster } from '../../services'
import { CreateRateMappingPayload, RateMappingResponse, UpdateRateMappingPayload } from './rental-rate-mapping.model'

@Injectable()
export class RateMappingService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  setRateMappingAutoSync(rentalId: string, mappingId: string, autoSync: boolean) {
    const url = `@api/rental/ratemap/autosync/${rentalId}/${mappingId}?autosync=${autoSync ? 1 : 0}`

    return this.http.put<RateMappingResponse[]>(url, {})
  }

  addRateMapping(rentalId: string, payload: CreateRateMappingPayload) {
    const url = `@api/rental/ratemap/${rentalId}`

    return this.http.put<RateMappingResponse>(url, payload)
  }

  deleteRateMapping(rentalId: string, rateMappingId: string) {
    const url = `@api/rental/ratemap/${rentalId}/${rateMappingId}`

    return this.http.delete<RateMappingResponse[]>(url)
  }

  updateRateMapping(rentalId: string, rateMappingId: string, payload: UpdateRateMappingPayload) {
    const url = `@api/rental/ratemap/${rentalId}/${rateMappingId}`

    return this.http.put<RateMappingResponse[]>(url, payload)
  }
}
