<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">
        {{ website.type }} Pages
        <app-page-help [infoTooltip]="tableTooltip"></app-page-help>
      </h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onAddPage()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="far fa-plus"></span> Add Page
    </button>
  </div>

  <div class="container-box details-box">
    <div class="table-holder table-responsive">
      <table class="table table-default table-striped" [hidden]="isEmptyTable$ | async">
        <mat-table #table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="titleView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Title</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.titleView }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="linkTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Link</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.linkTitle }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Group</mat-header-cell>
            <mat-cell *matCellDef="let element" class="text-capitalize"> {{ element.group }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="statusView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.statusView }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="order">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Order</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.order }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="typeView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.typeView }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Created</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span
                [matTooltip]="element.created | epoch"
                matTooltipPosition="above"
                [innerHtml]="element.createdView"
              ></span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="updatedView">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Updated</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span
                [matTooltip]="element.updated | epoch"
                matTooltipPosition="above"
                [innerHtml]="element.updatedView"
              ></span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
                <div dropdownToggle (click)="$event.stopPropagation()" class="btn-group btn-ellips-holder">
                  <a class="btn-ellips">
                    <i class="fas fa-ellipsis-v"></i>
                  </a>
                </div>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                  <li>
                    <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i>Edit</button>
                  </li>
                  <li *ngIf="element.status">
                    <button (click)="onTogglePage(element, 0)" type="button">
                      <i class="fal fa-eye-slash"></i>Hide
                    </button>
                  </li>
                  <li *ngIf="!element.status">
                    <button (click)="onTogglePage(element, 1)" type="button"><i class="fal fa-eye"></i>Show</button>
                  </li>
                  <li>
                    <button (click)="onDelete(element)" type="button">
                      <i class="cs-icon cs-icon-trash"></i>Delete
                    </button>
                  </li>
                </ul>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" (click)="onEdit(row)"></mat-row>
        </mat-table>

        <mat-paginator2
          #paginator
          [length]="dataSource?.data?.length"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator2>
      </table>
      <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
    </div>
  </div>
</div>
