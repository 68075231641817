import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { PaymentGateway } from './gateways.model'

export interface GatewaysState extends EntityState<PaymentGateway> {
  isLoaded: boolean
}

export const gatewayEntityAdapter: EntityAdapter<PaymentGateway> = createEntityAdapter<PaymentGateway>({
  selectId: (gateway: PaymentGateway) => gateway.pkey,
  sortComparer: false,
})

export const initialState: GatewaysState = gatewayEntityAdapter.getInitialState({
  isLoaded: false,
})
