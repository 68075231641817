import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Destroyable, isSomething, Rental } from '@tokeet-frontend/tv3-platform'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { Actions } from '@ngrx/effects'
import { DeleteRoom, UpdateRoom } from '../../../store/rooms/rooms.actions'
import { Room, RoomType, RoomSubType, isBedsInRoom } from '../../../store/rooms/rooms.model'
import { startWith } from 'rxjs/operators'

@Component({
  selector: 'app-room-form',
  templateUrl: './room-form.component.html',
  styleUrls: ['./room-form.component.scss'],
})
export class RoomFormComponent extends Destroyable implements OnInit {
  @Input() form: FormGroup
  @Input() rental: Rental
  @Input() index: number
  @Input() rooms: Room[]
  @Input() bathrooms: Room[]

  @Output() remove = new EventEmitter()

  roomTypes = {
    bedroom: [
      { id: 'master', name: 'Master' },
      { id: 'children', name: 'Children' },
      { id: 'guest', name: 'Guest' },
    ],
    bathroom: [
      { id: 'full', name: 'Full' },
      { id: 'half', name: 'Half' },
      { id: 'three_quarter', name: 'Three Quarter' },
      { id: 'quarter', name: 'Quarter' },
    ],
    kitchen: [
      { id: 'full', name: 'Full' },
      { id: 'kitchenette', name: 'Kitchenette' },
    ],
    living: [
      { id: 'bed_in_livingroom', name: 'Bed in Living room' },
      { id: '', name: 'Living Room' },
    ],
    general: [],
  }

  bedTypes = [
    { id: 'TWIN_BED', name: 'Twin bed (NA)' },
    { id: 'TWIN_XL_BED', name: 'XL Twin bed (NA)' },
    { id: 'SINGLE_BED', name: 'Twin/Single bed (EU)' },
    { id: 'SINGLE_XL_BED', name: 'XL Single bed (EU)' },
    { id: 'FULL_BED', name: 'Full bed (NA)' },
    { id: 'FULL_XL_BED', name: 'XL Full bed (NA)' },
    { id: 'DOUBLE_BED', name: 'Double bed (EU)' },
    { id: 'QUEEN_NA_BED', name: 'Queen bed (NA)' },
    { id: 'QUEEN_EU_BED', name: 'Queen bed (EU)' },
    { id: 'CAL_KING_BED', name: 'Cal king bed (NA)' },
    { id: 'KING_NA_BED', name: 'King bed (NA)' },
    { id: 'KING_EU_BED', name: 'King bed (EU)' },
    { id: 'SUPER_KING_BED', name: 'Super king bed (EU)' },
    { id: 'GRAND_KING_BED', name: 'Grand kind bed (EU)' },
    { id: 'BUNK_BED', name: 'Bunk bed' },
    { id: 'SOFA_BED', name: 'Sofa bed' },
    { id: 'MURPHY_BED', name: 'Murphy bed' },
    { id: 'TODDLER_BED', name: 'Toddler bed' },
    { id: 'FUTON', name: 'Futon bed' },
    { id: 'CRIB', name: 'Crib' },
    { id: 'AIR_MATTRESS', name: 'Air mattress' },
    { id: 'FLOOR_MATTRESS', name: 'Floor mattress' },
  ]

  get bedsArray() {
    return this.form.get('beds') as FormArray
  }

  constructor(private fb: FormBuilder, private store: Store<any>, private actions: Actions) {
    super()
  }

  ngOnInit() {
    this.form
      .get('type')
      .valueChanges.pipe(startWith(this.form.get('type').value))
      .subscribe((type) => {
        if (type === RoomType.GENERAL || type === RoomType.LIVING) {
          this.form.get('sub_type').setValidators([])
          this.form.get('sub_type').updateValueAndValidity()
        } else {
          this.form.get('sub_type').setValidators([Validators.required])
          this.form.get('sub_type').updateValueAndValidity()
        }
      })
  }

  hasSubType() {
    const type = this.form.get('type').value
    return type !== RoomType.GENERAL
  }

  hasBeds() {
    const type: RoomType = this.form.get('type').value
    const subType: RoomSubType = this.form.get('sub_type').value
    return isBedsInRoom(type, subType)
  }

  isRequired(control: any) {
    let errors: any = control.validator && (<any>control).validator(new FormControl())
    return errors !== null && errors.required
  }

  isEdit() {
    return isSomething(this.form.get('pkey')?.value)
  }

  getRoomType() {
    return this.form.get('type').value
  }

  getSizeMetric() {
    return this.form.get('size_metric').value
  }

  isBathroom() {
    return this.form.get('type').value === 'bathroom'
  }

  canAttachBathroom() {
    return this.form.get('type').value !== 'bathroom' && this.form.get('type').value !== 'kitchen'
  }

  onDeleteRoom() {
    const { pkey } = this.form.value

    const room = this.rooms.find((r) => r.pkey === pkey)

    if (room.type === RoomType.BATHROOM) {
      const roomsThisBathroomIsAttachedTo = this.rooms.filter((r) => r.bathroom === pkey)

      for (let associatedRoom of roomsThisBathroomIsAttachedTo) {
        this.store.dispatch(
          UpdateRoom({ id: associatedRoom.pkey, room: { ...associatedRoom, bathroom: null }, silent: true })
        )
      }
    }

    this.store.dispatch(DeleteRoom({ id: room.pkey }))
    this.remove.emit(this.index)
  }

  addBed() {
    this.bedsArray.push(this.fb.group({ bed: [''], count: [1] }))
  }

  removeBed(index: number) {
    this.bedsArray.removeAt(index)
  }

  increment(index: number) {
    const count = this.bedsArray.at(index).get('count')
    count.setValue(count.value + 1)
  }

  decrement(index: number) {
    const count = this.bedsArray.at(index).get('count')
    if (count.value === 1) {
      this.bedsArray.removeAt(index)
    } else {
      count.setValue(count.value - 1)
    }
  }
}
