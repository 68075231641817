import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import * as moment from 'moment'
import * as R from 'ramda'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

import { isPostalCode } from '@tv3/validators/string-validator/isPostalCode'
import { AddInquiryCard } from '@tv3/store/inquiry/inquiry-fields.actions'

function isZipCode(ctrl: AbstractControl): ValidationErrors | null {
  const value = ctrl.value
  if (isPostalCode(value, 'any')) {
    return null
  }
  return { zipCode: true }
}

@Component({
  selector: 'app-add-billing-details',
  templateUrl: './add-billing-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-billing-dialog.component.scss'],
})
export class AddBillingDialogComponent implements OnInit {
  form = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    cardNumber: [
      '',
      [
        Validators.required,
        Validators.pattern(
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
        ),
      ],
    ],
    zip: ['', [Validators.required, isZipCode]],
    cvc: ['', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]],
    month: ['', [Validators.required]],
    year: ['', [Validators.required]],
  })

  expYears: string[] = []

  currentYear = moment().year()

  constructor(
    public dialogRef: MatDialogRef<AddBillingDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {}

  ngOnInit() {
    this.form.setValidators(this.cardDateInFuture())
    R.times((i) => {
      this.expYears = R.append(this.currentYear + i + '', this.expYears)
    }, 51)
  }

  cardDateInFuture(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const month = parseInt(this.form.get('month').value, 10)
      const year = parseInt(this.form.get('year').value, 10)
      const currentMonth = moment().month() + 1
      const currentYear = moment().year()
      if (Number.isNaN(month)) {
        this.form.get('month').setErrors({ required: true })
        return null
      }
      if (Number.isNaN(year)) {
        if (Number.isNaN(month)) {
          this.form.get('month').setErrors({ required: true })
          return null
        } else {
          this.form.get('month').setErrors(null)
          return null
        }
      }
      if (year > currentYear) {
        this.form.get('month').setErrors(null)
        return null
      }
      if (currentYear > year) {
        this.form.get('month').setErrors({ cardInPast: true })
        return null
      }
      if (month < currentMonth) {
        this.form.get('month').setErrors({ cardInPast: true })
        return null
      }
      this.form.get('month').setErrors(null)
      return null
    }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(AddInquiryCard({ inquiry: this.data.inquiry, form: form.getRawValue() }))
    this.close()
  }
}
