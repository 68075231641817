import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { Destroyable, Rental, ConfirmDialogService, ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { finalize, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SelectFeesTableComponent } from '../select-fees-table/select-fees-table.component'
import { BookingFee, CreateBookingFeePayload } from '../../../store/fees/booking-fee'
import { BookingFeesService } from '../../../store/fees/booking-fees.service'

@Component({
  selector: 'app-copy-fees-from-dialog',
  templateUrl: './copy-fees-from-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  host: {
    class: 'modal-content',
  },
})
export class CopyFeesFromDialogComponent extends Destroyable implements OnInit {
  @ViewChild(SelectFeesTableComponent) feesTable: SelectFeesTableComponent
  get fees() {
    return this.feesTable?.fees
  }
  get rental() {
    return this.data.rental
  }
  isLoading = false
  selectedFees: BookingFee[] = []

  constructor(
    private store: Store<any>,
    private toaster: Toaster,
    private bookingFeesService: BookingFeesService,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopyFeesFromDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {}

  onSelect(items: BookingFee[]) {
    this.selectedFees = items
  }

  getRentalFees() {
    return this.fees.filter((f) => f.rental_id === this.rental?.id)
  }

  getSelectedFeesPayload(): CreateBookingFeePayload[] {
    return this.selectedFees.map((f) => ({
      ...lodash.omit(f, ['pkey']),
      rental_id: this.rental.id,
    }))
  }

  onOverwrite() {
    this.isLoading = true
    this.confirmDialog
      .confirm({
        title: 'Overwrite current fees?',
        body: 'Copying fees from different rental will overwrite current fees.',
      })
      .pipe(
        switchMap(() => this.bookingFeesService.deleteFees(this.getRentalFees().map((f) => f.pkey))),
        switchMap(() => this.bookingFeesService.createFees(this.getSelectedFeesPayload())),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.toaster.success('Fees copied successfully')
          this.dialogRef.close('overwrite')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onAdd() {
    this.isLoading = true
    this.bookingFeesService
      .createFees(this.getSelectedFeesPayload())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Fees copied successfully')
          this.dialogRef.close('copy')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  close() {
    this.dialogRef.close()
  }
}
