import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import { Guest } from '@tv3/store/guest/guest.model'
import * as lodash from 'lodash'
import * as R from 'ramda'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Injectable()
export class DownloadGuestsDialogService {
  constructor(private dialog: MatDialog, private amplitudeService: AmplitudeService) {}

  private getConfig(data: Guest[], filename: string): GenericExportDialogConfig {
    return {
      title: 'Download Guests to CSV',
      description: 'You are about to download all guests within your current filter.',
      columns: [
        { name: 'Name', field: 'name' },
        { name: 'Email', field: 'primaryEmail' },
        {
          name: 'Secondary Email',
          field: (guest) =>
            R.map((e) => decodeURIComponent(String(e)), R.keys(guest.email || {}))
              .filter((e) => e !== guest.primaryEmail)
              .join(','),
        },
        { name: 'Phone', field: 'phone' },
        { name: 'City', field: 'address.city' },
        { name: 'Country', field: 'countryView' },
        { name: 'Notes', field: (e) => lodash.map(e.notes || [], 'note').join(' ') },
      ],
      exportData: data,
      filename: filename,
      columnCustomizable: true,
    }
  }

  open(list: Guest[], filters: any) {
    // change any to type
    const nameObj = filters.nameOptions.find((item) => item.condition === filters.name)
    const name = nameObj ? nameObj.name : 'All'
    const country = filters.country && filters.country.length > 0 ? filters.country.length : 'All'
    const tag = filters.tags && filters.tags.length > 0 ? filters.tags.length : 'All'
    const source = filters.source && filters.source.length > 0 ? filters.source.length : 'All'
    const status = filters.status && filters.status.length > 0 ? filters.status.length : 'All'
    const fileName = `Guest_Name(${name})_Country(${country})_Tag(${tag})_Source(${source})_Status(${status})`

    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        config: this.getConfig(list, fileName),
      },
    }

    return this.dialog
      .open(GenericExportDialogComponent, defaultConfig)
      .afterClosed()
      .subscribe(() => {
        this.amplitudeService.logEvent('download-guest-list')
      })
  }
}
