<div
  class="overflow_scroll overflow_vertical"
  [class.overflow_scrollable]="isScrollable"
  [class.overflow_has_prev]="hasPrev"
  [class.overflow_has_next]="hasNext"
  [ngStyle]="{ pointerEvents: isHandling ? 'none' : 'auto' }"
  (mousedown)="onMouseDown($event)"
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onEnd()"
  (touchcancel)="onEnd()"
>
  <div
    #container
    class="overflow_content"
    (scroll)="onScroll($event)"
    (wheel)="onWheel($event)"
    (window:resize)="onResize()"
    (cdkObserveContent)="onResize()"
  >
    <ng-content></ng-content>
  </div>

  <button #prev class="overflow_nav overflow_prev" (click)="nav(false)">
    <i class="far fa-chevron-up icon"></i>
  </button>
  <button #next class="overflow_nav overflow_next" (click)="nav(true)">
    <i class="far fa-chevron-down icon"></i>
  </button>
</div>
