<div
  *ngFor="let note of inquiry.notes"
  (click)="onEdit(note)"
  [matTooltip]="canEditNotes ? 'Click to edit or delete comment' : ''"
  matTooltipPosition="left"
  [class.canEditNote]="canEditNotes"
  class="note-list"
>
  <div class="avater">
    <span class="avater-cst-magenta">{{ note.user | initials }}</span>
  </div>
  <div class="content">
    <h4>
      {{ note.user }}
      <small>{{ note.timestamp | timeAgo }}</small>
    </h4>
    <p>
      {{ note.note }}
    </p>
  </div>
</div>

<ng-container *ngIf="!inquiry.notes || inquiry.notes.length === 0">
  <h6 class="text-center">No comments available</h6>
</ng-container>

<div class="box-details">
  <div class="box-dsc-row row">
    <div class="col-12 text-center has-btn">
      <button (click)="onAddNote()" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
        <span class="fal fa-clipboard-list"></span>
        Add Comments
      </button>
    </div>
  </div>
</div>
