import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import {
  AddTemplate,
  AddTemplateComplete,
  DeleteTemplate,
  DeleteTemplateComplete,
  DeleteTemplates,
  DeleteTemplatesComplete,
  LoadTemplates,
  LoadTemplatesComplete,
  UpdateCustomBrandingTemplate,
  UpdateCustomBrandingTemplateComplete,
  UpdateTemplate,
  UpdateTemplateComplete,
} from './template.actions'
import { TemplateService } from './template.service'
import { Store } from '@ngrx/store'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { CustomBrandingService } from './custom-branding.service'

@Injectable()
export class TemplateEffects {
  @Effect()
  loadTemplates$ = this.actions$.pipe(
    ofType(LoadTemplates),
    switchMap(() =>
      this.templates.all().pipe(
        map((templates) => LoadTemplatesComplete({ templates })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addTemplate$ = this.actions$.pipe(
    ofType(AddTemplate),
    switchMap(({ request }) =>
      this.templates.add(request).pipe(
        map((template) => AddTemplateComplete({ template })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateTemplate$ = this.actions$.pipe(
    ofType(UpdateTemplate),
    switchMap(({ id, request }) =>
      this.templates.update(id, request).pipe(
        map((template) => UpdateTemplateComplete({ update: { id: template.id, changes: template } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateCustomBrandingTemplate$ = this.actions$.pipe(
    ofType(UpdateCustomBrandingTemplate),
    switchMap(({ id, request }) =>
      this.customBrandingService.update(id, request).pipe(
        map((template) => UpdateCustomBrandingTemplateComplete({ update: { id: template.id, changes: template } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteTemplate$ = this.actions$.pipe(
    ofType(DeleteTemplate),
    switchMap(({ id }) =>
      this.templates.delete(id).pipe(
        map((id) => DeleteTemplateComplete({ id })),
        tap(() => this.toast.success('Template deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteTemplates$ = this.actions$.pipe(
    ofType(DeleteTemplates),
    switchMap(({ ids }) =>
      this.templates.deleteMultiple(ids).pipe(
        map((ids) => DeleteTemplatesComplete({ ids })),
        tap(() => this.toast.success('Templates deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private customBrandingService: CustomBrandingService,
    private templates: TemplateService,
    private toast: Toaster
  ) {}
}
