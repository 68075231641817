<div
  class="uploader-box border rounded bg-white d-flex align-items-center p-4 mt-3 position-relative"
  ng2FileDrop
  [uploader]="uploader"
  *ngIf="!isMaxFilesReached"
>
  <img src="/assets/images/upload-file.png" alt="" class="m-3" />
  <input
    type="file"
    [accept]="acceptFileExtensions"
    #fileInput
    ng2FileSelect
    [uploader]="uploader"
    multiple
    class="d-none"
  />
  <div class="flex-fill">
    <h6>Drag files here or <a href="javascript:;" (click)="fileInput.click()">Browse</a> to upload</h6>
    <p class="text-muted" style="font-size: 0.8rem">
      You can upload only 1 file at a time that is 20 MB or smaller. <br />
      Maximum {{ maxFiles }} files are allowed to be uploaded in total. <br />
      only {{ supportedFileExtensionsStr }} files are allowed
    </p>
  </div>
  <div
    *ngIf="uploader?.isUploading"
    class="position-absolute d-flex align-items-center justify-content-center rounded bg-secondary"
    style="opacity: 0.9; top: 0; bottom: 0; left: 0; right: 0"
  >
    <ngb-progressbar type="warning" [value]="uploader?.progress" [striped]="true" [animated]="true" style="width: 90%"
      ><i>Uploading</i></ngb-progressbar
    >
  </div>
</div>
<div class="border bg-white rounded overflow-hidden mt-3">
  <div class="p-3 border-bottom d-flex">
    <app-table-search inputTooltip="Enter document name you want to search for" [ctrl]="searchCtrl"></app-table-search>
    <app-cancel-filters
      [isFiltering]="searchCtrl.value"
      (clear)="searchCtrl.reset()"
      class="ml-auto"
    ></app-cancel-filters>
  </div>
  <mat-table #table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Title</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bytes">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Size</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.bytes | fileSize }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="preBooking">
      <mat-header-cell *matHeaderCellDef> Pre Booking</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information before booking confirmation?"
          [checked]="element.preBooking"
          (valueChange)="onTogglePreBooking(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="postBooking">
      <mat-header-cell *matHeaderCellDef> Post Booking</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information after booking confirmation?"
          [checked]="element.postBooking"
          (valueChange)="onTogglePostBooking(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="postCheckin">
      <mat-header-cell *matHeaderCellDef> Post Checkin</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-form-switch
          [inline]="true"
          matTooltip="Do you really want to share this information after guest checkin?"
          [checked]="element.postCheckin"
          (valueChange)="onTogglePostCheckin(element, $event)"
        ></app-form-switch>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a href="javascript:;" (click)="onDelete(element)" matTooltip="Delete this file" class="text-danger"
          ><i class="cs-icon cs-icon-trash"></i
        ></a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <app-empty-table type="AIDocuments" *ngIf="!dataSource.data?.length"></app-empty-table>

  <mat-paginator2
    #paginator
    [length]="dataSource?.data?.length"
    [pageSizeOptions]="[5, 10, 20, 50]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>
</div>
