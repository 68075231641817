import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  selectRentalSmartDevices,
  SmartDevice,
  SmartDeviceAccess,
  SmartDeviceAccessService,
  SmartDevicesGuard,
  SmartDeviceSettingsGuard,
} from '@tokeet-frontend/smart-devices'
import { DataCheckerService, Destroyable, DialogService, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as lodash from 'lodash'
import { finalize } from 'rxjs/operators'

import { NewAccesscodeDialogComponent } from '../new-accesscode-dialog/new-accesscode-dialog.component'
import { SmartDevicesSubscribeService } from '../smart-devices-subscribe.service'

@Component({
  selector: 'app-booking-access-code',
  templateUrl: './booking-access-code.component.html',
  styleUrls: ['./booking-access-code.component.scss'],
})
export class BookingAccessCodeComponent extends Destroyable implements OnInit {
  @Input() inquiry: Inquiry
  @Input() tile = false

  @Output() link = new EventEmitter()

  isLoading = true
  codesByDeviceId: Record<string, SmartDeviceAccess> = {}
  devices: SmartDevice[] = []
  constructor(
    private store: Store<any>,
    private accessService: SmartDeviceAccessService,
    private dialog: DialogService,
    private subscribeDeviceService: SmartDevicesSubscribeService,
    private dataChecker: DataCheckerService
  ) {
    super()
    this.dataChecker.check([SmartDevicesGuard, SmartDeviceSettingsGuard])
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectRentalSmartDevices(this.inquiry.rentalId, true)), untilDestroy(this))
      .subscribe((items) => {
        this.devices = items
      })

    this.refresh()
  }

  refresh() {
    this.isLoading = true

    this.accessService
      .getBookingDeviceAccesses(this.inquiry.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.codesByDeviceId = lodash.mapValues(
            lodash.groupBy(data, (c) => c.device_id),
            (codes) => lodash.head(codes)
          )
        },
        (error) => {
          // this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onNewCode(device: SmartDevice) {
    if (!this.subscribeDeviceService.checkSubscription()) {
      return
    }

    this.dialog
      .openFixedDialog(NewAccesscodeDialogComponent, {
        width: '600px',
        data: { inquiry: this.inquiry, device },
      })
      .afterClosed()
      .subscribe(() => {
        this.refresh()
      })
  }
}
