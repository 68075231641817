import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { notAllSpacesValidator } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-add-attribute-dialog',
  templateUrl: './add-attribute-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./add-attribute-dialog.component.scss'],
})
export class AddAttributeDialogComponent {
  form = this.fb.group({
    value: ['', [Validators.required, notAllSpacesValidator, Validators.pattern('^[a-zA-Z\\s]*$')]],
  })

  constructor(public dialogRef: MatDialogRef<AddAttributeDialogComponent>, private fb: FormBuilder) {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues.value)
  }
}
