import { createSelector, select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { State } from '../channels.state'
import { combineLatest } from 'rxjs'
import { Rental, selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { channelsState } from '../channels.selectors'
import { CtripRoomModel, CtripRoomViewModel } from './model'

export const selectCtripState = createSelector(channelsState, (state) => state.ctrip)

export const selectCtripStatus = createSelector(selectCtripState, (state) => state.status)

export const selectCtripConnected = createSelector(selectCtripState, (state) => state.status?.connected)
export const selectCtripHotelIds = createSelector(selectCtripState, (state) => state.status?.hotelIds || [])

export const selectCtripListings = createSelector(selectCtripState, (state) => state.listings || [])

export const getCtripListings = (store: Store<State>) =>
  combineLatest([store.pipe(select(selectCtripListings)), store.pipe(select(selectAllRentals))]).pipe(
    map(([listings, rentals]) => {
      return R.map((listing): CtripRoomModel => {
        const rental = R.find((r: Rental) => r.id === listing.rental_id, rentals)
        return {
          ...listing,
          rental,
          rental_name: rental?.name,
        } as CtripRoomViewModel
      }, listings) as CtripRoomViewModel[]
    })
  )
