/* tslint:disable max-line-length */
import { Injectable } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import * as moment from 'moment'
import * as R from 'ramda'

import {
  AgendaCalendarFilters,
  MultiCalendarAvailabilityTypes,
  MultiCalendarFilters,
  PlanningCalendarFilters,
  StandardCalendarFilters,
} from '@tv3/interfaces/filters/calendar.filters'
import { StandardCalendarViewTypes } from '@tv3/containers/calendar/calendar-standard/enums/standard-calendar-view-type.enum'
import { AgendaRangeTypes } from '@tv3/containers/calendar/agenda/enums/agenda-range-types.enum'
import { RateFilters } from '@tv3/interfaces/filters/rate.filters'
import { HoldEventView } from '@tv3/store/calendar/calendar.view'
import { CustomCodeFilters } from '@tv3/interfaces/filters/custom-code.filters'
import { UserStorage } from '@tokeet-frontend/tv3-platform'

export enum FilterGroup {
  Hold = 'HOLDS_FILTERS',
  Invoices = 'INVOICES_FILTERS',
  InvoiceTemplates = 'INVOICE_TEMPLATES',
  InvoiceRules = 'INVOICE_RULES',
  Charges = 'CHARGE_FILTERS',
  Expense = 'EXPENSE_FILTERS',
  Extra = 'EXTRA_FILTERS',
  Connection = 'CONNECTIONS_FILTERS',
  User = 'ACCOUNT_USERS',
  Inquiry = 'INQUIRY_FILTERS_V2',
  Guest = 'GUEST_FILTERS',
  CustomCode = 'CUSTOM_CODE_FILTERS',
  DataFeed = 'DATA_FEED_FILTERS',
  MultiCalendar = 'MULTI_CALENDAR_FILTERS',
  Agenda = 'AGENDA_FILTERS',
  StandardCalendar = 'STANDARD_CALENDAR_FILTERS',
  PlanningCalendar = 'PLANNING_CALENDAR_FILTERS',
  Rentals = 'RENTALS_FILTERS',
  Automations = 'AUTOMATION_FILTERS',
  Stealths = 'STEALTHS_FILTERS',
  FileCabinet = 'FILE_CABINET_FILTERS',
  Inventories = 'INVENTORIES_FILTERS',
  Incidents = 'INCIDENTS_FILTERS',
  Tasks = 'TASKS_FILTERS',
  TasksStatus = 'TASKS_STATUS_FILTERS',
  TasksCalendar = 'TASKS_CALENDAR_FILTERS',
  Rate = 'RATES_FILTERS',
  Templates = 'TEMPLATES_FILTERS',
  PaymentGateways = 'PAYMENT_GATEWAYS',
  PaymentGatewayLog = 'PAYMENT_GATEWAY_LOG',
  PaymentRules = 'PAYMENT_RULES',
  SharedContent = 'SHARED_CONTENT',
  MessageFeed = 'MESSAGE_FEED',
  Websites = 'WEBSITES_FILTERS',
  DashboardTopFilters = 'DASHBOARD_TOP_FILTERS',
  DashboardActivityFeed = 'DASHBOARD_ACTIVITY_FEED',
  DashboardMonthlyRevenue = 'DASHBOARD_MONTHLY_REVENUE',
  DashboardUpcomingArrivals = 'DASHBOARD_UPCOMING_ARRIVALS',
  DashboardUpcomingDepartures = 'DASHBOARD_UPCOMING_DEPARTURES',
  DashboardPastDueInvoices = 'DASHBOARD_PAST_DUE_INVOICES',

  DiscountCodes = 'DiscountCodes',
  LosDiscounts = 'LosDiscounts',
  SmartDevices = 'SmartDevices',
}

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  defaultFilters = {
    [FilterGroup.CustomCode]: {
      rentals: [],
      tags: [],
      taggedIds: [],
    },
    [FilterGroup.InvoiceRules]: {
      rentals: [],
      channels: [],
      templates: [],
      events: [],
      tags: [],
      taggedIds: [],
    },
    [FilterGroup.InvoiceTemplates]: {
      tags: [],
      taggedIds: [],
    },
    [FilterGroup.MultiCalendar]: {
      rentals: [],
      channels: [],
      tags: [],
      taggedIds: [],
      search: '',
      availabilityType: MultiCalendarAvailabilityTypes.available,
      availabilityRange: null,
      initialDate: null,
      pageIndex: 0,
      pageSize: 10,
    },
    [FilterGroup.Agenda]: {
      rentals: [],
      range: AgendaRangeTypes.Week,
      start: moment.utc().startOf('isoWeek'),
      end: moment.utc().endOf('isoWeek'),
      type: undefined,
    },
    [FilterGroup.StandardCalendar]: {
      rentals: [],
      tags: [],
      taggedIds: [],
      channels: [],
      view: StandardCalendarViewTypes.Monthly,
      start: moment.utc().startOf(StandardCalendarViewTypes.Monthly),
      end: moment.utc().endOf(StandardCalendarViewTypes.Monthly),
    },
    [FilterGroup.PlanningCalendar]: {
      rentals: [],
      tags: [],
      taggedIds: [],
      channels: [],
      months: [],
      start: moment.utc().startOf('year'),
      end: moment.utc().endOf('year'),
    },
    [FilterGroup.Rate]: {
      rentals: [],
      tags: [],
      taggedIds: [],
      channelId: null,
      search: null,
      initialDate: null,
    },
    [FilterGroup.DashboardTopFilters]: {
      year: moment().year(),
      rentals: [],
    },
    [FilterGroup.DashboardActivityFeed]: {
      type: 'all',
    },
    [FilterGroup.DashboardPastDueInvoices]: {
      pageSize: 5,
      search: '',
    },
    [FilterGroup.DashboardUpcomingArrivals]: {
      pageSize: 5,
      search: '',
    },
    [FilterGroup.DashboardUpcomingDepartures]: {
      pageSize: 5,
      search: '',
    },
  }

  constructor(private storage: UserStorage, private fb: FormBuilder) {}

  filterHoldByMonth(holds: HoldEventView[], month: string) {
    if (month === 'all') {
      return holds
    }
    return R.filter((h: HoldEventView) => {
      const months = this.getMonthsBetween(h.start, h.end)
      return months.indexOf(month) > -1
    }, holds)
  }

  getMonthsBetween(start, end) {
    const startDate = R.is(Number, start) ? moment(start * 1000) : moment(start)
    const endDate = R.is(Number, end) ? moment(end * 1000) : moment(end)
    const months = []
    for (const date = startDate.clone(); date <= endDate; date.add(1, 'month')) {
      months.push(date.month() + 1)
    }
    return months
  }

  storeCustomCode(filters: CustomCodeFilters): void {
    this.storage.set(FilterGroup.CustomCode, { ...filters })
  }

  storeMultiCalendar(filters: MultiCalendarFilters): void {
    this.storage.set(FilterGroup.MultiCalendar, filters)
  }

  storeAgenda(filters: AgendaCalendarFilters): void {
    this.storage.set(FilterGroup.Agenda, {
      ...filters,
      start: moment.utc(filters.start).toISOString(),
      end: moment.utc(filters.end).toISOString(),
    })
  }

  storeStandardCalendarFilters(filters: StandardCalendarFilters) {
    this.storage.set(FilterGroup.StandardCalendar, {
      ...filters,
      start: moment.utc(filters.start).toISOString(),
      end: moment.utc(filters.end).toISOString(),
    })
  }

  storePlanningCalendarFilters(filters: PlanningCalendarFilters) {
    this.storage.set(FilterGroup.PlanningCalendar, {
      ...filters,
      start: moment.utc(filters.start).toISOString(),
      end: moment.utc(filters.end).toISOString(),
    })
  }

  storeRateFilters(filters: RateFilters): void {
    this.storage.set(FilterGroup.Rate, filters)
  }

  storeDashboardTopFilters(filters: any) {
    this.storage.set(FilterGroup.DashboardTopFilters, filters)
  }

  getDashboardTopFilters() {
    const storedFilters: any = this.storage.get(
      FilterGroup.DashboardTopFilters,
      this.defaultFilters[FilterGroup.DashboardTopFilters]
    )
    return this.fb.group({
      year: [storedFilters.year],
      rentals: [storedFilters.rentals],
    })
  }

  storeDashboardActivityFeedFilters(filters: any) {
    this.storage.set(FilterGroup.DashboardActivityFeed, filters)
  }

  getDashboardActivityFeedFilters() {
    const storedFilters: any = this.storage.get(
      FilterGroup.DashboardActivityFeed,
      this.defaultFilters[FilterGroup.DashboardActivityFeed]
    )
    return this.fb.group({
      type: [storedFilters.type],
    })
  }

  storeDashboardPastDueInvoicesFilters(filters: any) {
    this.storage.set(FilterGroup.DashboardPastDueInvoices, filters)
  }

  getDashboardPastDueInvoicesFilters() {
    const storedFilters: any = this.storage.get(
      FilterGroup.DashboardPastDueInvoices,
      this.defaultFilters[FilterGroup.DashboardPastDueInvoices]
    )
    return this.fb.group({
      pageSize: [storedFilters.pageSize],
      search: [''],
    })
  }

  storeDashboardUpcomingArrivalsFilters(filters: any) {
    this.storage.set(FilterGroup.DashboardUpcomingArrivals, filters)
  }

  getDashboardUpcomingArrivalsFilters() {
    const storedFilters: any = this.storage.get(
      FilterGroup.DashboardUpcomingArrivals,
      this.defaultFilters[FilterGroup.DashboardUpcomingArrivals]
    )
    return this.fb.group({
      pageSize: [storedFilters.pageSize],
      search: [''],
    })
  }

  storeDashboardUpcomingDeparturesFilters(filters: any) {
    this.storage.set(FilterGroup.DashboardUpcomingDepartures, filters)
  }

  getDashboardUpcomingDeparturesFilters() {
    const storedFilters: any = this.storage.get(
      FilterGroup.DashboardUpcomingDepartures,
      this.defaultFilters[FilterGroup.DashboardUpcomingDepartures]
    )
    return this.fb.group({
      pageSize: [storedFilters.pageSize],
      search: [''],
    })
  }

  getMultiCalendarFilters() {
    let storedFilters: MultiCalendarFilters = this.storage.get(
      FilterGroup.MultiCalendar,
      this.defaultFilters[FilterGroup.MultiCalendar] as MultiCalendarFilters
    )

    storedFilters = R.merge(this.defaultFilters[FilterGroup.MultiCalendar], storedFilters)

    return this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      tags: [storedFilters.tags],
      taggedIds: [storedFilters.taggedIds],
      availabilityType: [storedFilters.availabilityType],
      availabilityRange: [storedFilters.availabilityRange],
      search: [storedFilters.search],

      initialDate: [storedFilters.initialDate],
      pageIndex: [storedFilters.pageIndex],
      pageSize: [storedFilters.pageSize],
    })
  }

  getAgendaFilters() {
    const defaultRange = AgendaRangeTypes.Week
    const defaultStart = moment.utc().startOf('isoWeek')
    const defaultEnd = defaultStart.clone().endOf('isoWeek')

    const storedFilters: AgendaCalendarFilters = this.storage.get(
      FilterGroup.Agenda,
      this.defaultFilters[FilterGroup.Agenda] as AgendaCalendarFilters
    )

    // const start = moment.utc(storedFilters.start).isValid() ? moment.utc(storedFilters.start) : defaultStart;
    // const end = moment.utc(storedFilters.end).isValid() ? moment.utc(storedFilters.end) : defaultEnd;

    return this.fb.group({
      rentals: [storedFilters.rentals],
      range: [defaultRange],
      start: [defaultStart],
      end: [defaultEnd],
      type: [],
    })
  }

  getStandardCalendarFilters() {
    const defaultView = StandardCalendarViewTypes.Monthly
    const defaultStart = moment.utc().startOf(defaultView)
    const defaultEnd = defaultStart.clone().endOf(defaultView)

    const storedFilters: StandardCalendarFilters = this.storage.get(
      FilterGroup.StandardCalendar,
      this.defaultFilters[FilterGroup.StandardCalendar] as StandardCalendarFilters
    )

    // const start = moment.utc(storedFilters.start).isValid() ? moment.utc(storedFilters.start) : defaultStart;
    // const end = moment.utc(storedFilters.end).isValid() ? moment.utc(storedFilters.end) : defaultEnd;

    return this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      tags: [storedFilters.tags],
      taggedIds: [storedFilters.taggedIds],
      view: [defaultView],
      start: [defaultStart],
      end: [defaultEnd],
    })
  }

  getPlanningCalendarDefaultFilters() {
    const defaultStart = moment.utc().startOf('year')
    const defaultEnd = defaultStart.clone().endOf('year')
    const currentMonthIndex = moment.utc().month() + 1
    const defaultMonthCount = 3
    const defaultMonths = R.range(
      currentMonthIndex,
      currentMonthIndex + defaultMonthCount > 13 ? 13 : currentMonthIndex + defaultMonthCount
    )

    return {
      ...this.defaultFilters[FilterGroup.PlanningCalendar],
      start: defaultStart,
      end: defaultEnd,
      months: defaultMonths,
    }
  }

  getPlanningCalendarFilters() {
    const defaultFilters = this.getPlanningCalendarDefaultFilters()

    const storedFilters: PlanningCalendarFilters = this.storage.get(FilterGroup.PlanningCalendar, defaultFilters)

    const start = moment.utc(storedFilters.start).isValid() ? moment.utc(storedFilters.start) : defaultFilters.start
    const end = moment.utc(storedFilters.end).isValid() ? moment.utc(storedFilters.end) : defaultFilters.end
    const months =
      R.isEmpty(storedFilters.months) || R.isNil(storedFilters.months) ? defaultFilters.months : storedFilters.months

    return this.fb.group({
      rentals: [storedFilters.rentals],
      channels: [storedFilters.channels],
      tags: [storedFilters.tags],
      taggedIds: [storedFilters.taggedIds],
      months: [months],
      start: [start],
      end: [end],
    })
  }

  getCustomCodeFilters() {
    const storedFilters: CustomCodeFilters = this.storage.get(
      FilterGroup.CustomCode,
      this.defaultFilters[FilterGroup.CustomCode] as CustomCodeFilters
    )

    return this.fb.group({
      rentals: [storedFilters.rentals],
      tags: [storedFilters.tags],
      taggedIds: [storedFilters.taggedIds],
    })
  }

  getRateFilters() {
    const storedFilters: RateFilters = this.storage.get(
      FilterGroup.Rate,
      this.defaultFilters[FilterGroup.Rate] as RateFilters
    )

    return this.fb.group({
      rentals: [storedFilters.rentals],
      tags: [storedFilters.tags],
      taggedIds: [storedFilters.taggedIds],
      channelId: [storedFilters.channelId],
      search: [storedFilters.search],
      initialDate: [storedFilters.initialDate],
    })
  }
}
