import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'
import { combineLatest, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  AccountGuard,
  ConfirmDialogService,
  DataCheckerService,
  DialogService,
  Rental,
  SaveForm,
  selectAccountTaxes,
  selectAllRentals,
  TaxV3,
  taxV3ModalityLabels,
  updateRentalTaxes,
} from '@tokeet-frontend/tv3-platform'
import { TaxModalities } from '@tokeet/cost-resolver'
import { CopyTaxesFromDialogComponent } from '../copy-taxes-from-dialog/copy-taxes-from-dailog.component'
import { CopyTaxesToDialogComponent } from '../copy-taxes-to-dialog/copy-taxes-to-dialog.component'

@Component({
  selector: 'app-edit-rental-taxes',
  templateUrl: './edit-rental-taxes.component.html',
  styleUrls: ['./edit-rental-taxes.component.scss'],
})
export class EditRentalTaxesComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    taxes: this.fb.array([this.createTax()]),
  })

  get taxes() {
    return this.form.get('taxes') as FormArray
  }

  @Input() rental: Rental

  currencySymbol: string
  taxV3Modalities = TaxModalities
  taxV3ModalityLabels = taxV3ModalityLabels

  availableTaxes: TaxV3[] = []
  destroy$ = new Subject<boolean>()

  constructor(
    public dialogRef: MatDialogRef<EditRentalTaxesComponent>,
    private fb: FormBuilder,
    private confirm: ConfirmDialogService,
    private dataCheckerService: DataCheckerService,
    private dialogService: DialogService,
    private store: Store<any>
  ) {
    this.dataCheckerService.check([AccountGuard])
  }

  ngOnInit() {
    this.currencySymbol = R.pathOr('$', ['currency', 'symbol'], this.rental)

    const taxes = this.rental.taxes || []
    if (!R.isEmpty(taxes)) {
      this.form = this.fb.group({
        taxes: this.fb.array(R.map((t: TaxV3) => this.createTax(t), taxes)),
      })
    } else {
      this.form = this.fb.group({
        taxes: this.fb.array([this.createTax()]),
      })
    }

    combineLatest([this.store.pipe(select(selectAccountTaxes)), this.store.pipe(select(selectAllRentals))])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([accountTaxes, rentals]) => {
        const rentalTaxes = R.flatten(R.map((r) => r.taxes, rentals || []))

        this.availableTaxes = R.uniqBy(
          (t: TaxV3) => t.name,
          R.filter((t) => !!t, [...accountTaxes, ...rentalTaxes])
        )
      })
  }

  newTaxName(name) {
    const tax = new TaxV3()
    tax.name = name
    return tax
  }

  onAccountTaxSelected(tax: TaxV3, ctrl: FormGroup) {
    ctrl.patchValue(tax)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  addTax(tax?: TaxV3) {
    this.taxes.push(this.createTax(tax))
  }

  removeTax(index: number) {
    this.confirm
      .confirm({
        title: 'Remove tax',
        body: 'Are you sure you want to remove this tax?',
      })
      .subscribe(() => {
        this.taxes.removeAt(index)
      })
  }

  onCopyFrom() {
    this.dialogService.openVariableDialog(CopyTaxesFromDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  onCopyTo() {
    this.dialogService.openVariableDialog(CopyTaxesToDialogComponent, {
      data: { rental: this.rental },
      width: '900px',
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (form.invalid) {
      return
    }
    const taxes = form.getRawValue().taxes as TaxV3[]
    this.store.dispatch(updateRentalTaxes({ taxes, rentalId: this.rental.id }))
  }

  private createTax(tax?: TaxV3) {
    return this.fb.group({
      amount: [tax?.amount, [Validators.required]],
      name: [tax?.name, [Validators.required]],
      type: [tax?.type || 'percent'],
      tax_type: [tax?.tax_type, [Validators.required]],
      modality: [tax?.modality || TaxModalities.PerStay],
      // business_tax_id: [tax?.business_tax_id],
      // tot_registration_id: [tax?.tot_registration_id],
      // long_term_stay_exemption: [tax?.long_term_stay_exemption],
    })
  }
}
