import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './los-discount.state'
import * as reducer from './los-discount.reducer'
import * as lodash from 'lodash'
import {
  Channel,
  selectAllApiChannelViews,
  selectRentalEntities,
  tokeetDashboardChannel,
} from '@tokeet-frontend/tv3-platform'
import { LosDiscountView, toLosDiscountView } from './los-discount.model'

export const losDiscountState = createFeatureSelector<state.State>('losDiscounts')

export const selectLosDiscountsLoaded = createSelector(losDiscountState, (state: state.State) => state.isLoaded)

export const selectLosDiscountEntities = createSelector(losDiscountState, reducer.selectEntities)

export const selectLosDiscountById = createSelector(selectLosDiscountEntities, (itemsById, props) => {
  return itemsById[props.id]
})

export const selectAllLosDiscounts = createSelector(losDiscountState, reducer.selectAll)

export const losTokeetChannel = Channel.deserialize({
  channelId: '00000000-0000-0000-0000-000000000000-tokeetwebsite',
  token: 'tokeet',
  friendlyName: 'AdvanceCM/Sympl',
  name: 'tokeet',
  type: 'auto',
})

export const selectAPIChannelsForLOS = createSelector(selectAllApiChannelViews, (channels) => {
  return lodash.sortBy([...channels, losTokeetChannel], 'friendlyName')
})

export const selectAllLosDiscountViews = createSelector(
  selectAllLosDiscounts,
  selectRentalEntities,
  selectAPIChannelsForLOS,
  (discounts, rentalsById, apiChannels) => {
    const channelsById = lodash.keyBy(apiChannels, 'id')
    return lodash.map(discounts, (item) => {
      const rentals = lodash.map(item.rentals, (id) => rentalsById[id]).filter((i) => !!i)
      const channels = lodash.map(item.channels, (id) => channelsById[id]).filter((i) => !!i)
      const newItem = toLosDiscountView(item)
      return {
        ...newItem,
        rentalsView: rentals.map((r) => r.name).join(', '),
        // @ts-ignore
        channelsView: channels.map((c) => c.friendlyName).join(', '),
      }
    })
  }
)

export const selectLosDiscountViewById = createSelector(selectAllLosDiscountViews, (items, props) => {
  return lodash.find(items, { id: props.id })
})

export const selectLosDiscountViewsByRentalId = createSelector(
  selectAllLosDiscountViews,
  (items: LosDiscountView[], props) => {
    return lodash.filter(items, (item) => lodash.isEmpty(item.rentals) || lodash.includes(item.rentals, props.id))
  }
)
