import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core'
import { Message, MessageType, isBotMessage, plainTextMessage } from '@tv3/store/message/message.model'
import { Attachment, AttachmentService, ChannelService, ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { TokeetTemplates } from '@tokeet-frontend/templates'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as lodash from 'lodash'
import { AddMessage, DeleteMessage } from '@tv3/store/message/message.actions'
import { Store } from '@ngrx/store'
import * as R from 'ramda'

@Component({
  selector: 'app-message-list-item-base',
  templateUrl: './message-list-item-base.component.html',
  styleUrls: ['./message-list-item-base.component.scss'],
})
export class MessageListItemBaseComponent implements OnInit {
  @HostBinding('class.box') box = true
  @HostBinding('class.receiver') receiver = true
  @HostBinding('class.sender') sender = false
  @HostBinding('class.bot') get isBot() {
    return isBotMessage(this.message) && !this.isDraft
  }
  @HostBinding('class.draft') get isDraft() {
    return this.message.type === MessageType.Draft
  }

  @Input() message: Message
  @Input() inquiry?: Inquiry
  @Input() clickable: boolean
  @Input() viewPlainText = false
  @Output() editDraft = new EventEmitter<Message>()
  @Output() aiGenerate = new EventEmitter<Message>()
  @Output() bodyClick = new EventEmitter()

  get guestName() {
    return lodash.trim(this.message.guestName) || this.inquiry?.guestDetails?.name || 'Unknown'
  }

  MessageType = MessageType

  tokeetTemplates = TokeetTemplates

  @Input() collapsed = true
  @Output() collapse = new EventEmitter<boolean>()

  @Input() currencySymbol = '$'

  constructor(
    private store: Store<any>,
    private channelService: ChannelService,
    private attachmentService: AttachmentService,
    private confirmDialog: ConfirmDialogService
  ) {}

  ngOnInit() {}

  get toggleCollapsingTooltip() {
    return this.collapsed ? 'Expand message' : 'Collapse message'
  }

  onDownloadAttachment(attachment: Attachment) {
    this.attachmentService.downloadFile(attachment).subscribe()
  }

  onEditDraft(msg: Message) {
    this.editDraft.emit(msg)
  }

  onAIGenerate() {
    this.aiGenerate.emit()
  }

  onRemoveDraft(msg: Message) {
    this.confirmDialog
      .confirm({
        title: 'Delete Message Draft?',
        body: 'Are you sure you want to delete this message draft?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteMessage({ message: msg, inquiryId: this.inquiry.id }))
      })
  }

  onSendDraft(msg: Message) {
    this.confirmDialog
      .confirm({
        title: 'Send Message?',
        body: 'Are you sure you want to send this message to the guest?',
      })
      .subscribe(() => {
        this.store.dispatch(
          AddMessage({
            inquiry: this.inquiry,
            form: {
              subject: msg.subject || `Re: Your inquiry about ${R.pathOr('', ['rental', 'name'], this.inquiry)}`,
              cc: [],
              bcc: [],
              messageHtml: msg.messageHtml,
              messageText: plainTextMessage(msg.messageTxt),
            } as any,
            plainMessage: !!this.channelService.channelFromEmail(this.inquiry.guest.primaryEmail),
          })
        )
        this.store.dispatch(DeleteMessage({ message: msg, inquiryId: this.inquiry.id, silent: true }))
      })
  }
}
