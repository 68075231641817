import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EditTaxDialogComponent } from './edit-tax-dialog.component'

@Injectable()
export class EditTaxDialogService {
  constructor(private dialog: MatDialog) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(EditTaxDialogComponent, defaultConfig)
  }
}
