<div dropdown [isDisabled]="1 | isReadonlyRole" class="btn-group">
  <button
    dropdownToggle
    type="button"
    class="btn btn-secondary-warning dropdown-toggle"
    matTooltip="You can perform multiple actions using this button."
  >
    <span class="cs-icon cs-icon-bolt"></span> Actions
  </button>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <li matTooltip="Pause selected automations." matTooltipPosition="left">
      <button (click)="table?.onPauseSelected()" [disabled]="!isAnySelected" type="button">
        <i class="fal fa-pause fa-fw"></i>Pause
      </button>
    </li>
    <li matTooltip="Resume selected automations." matTooltipPosition="left">
      <button (click)="table?.onResumeSelected()" [disabled]="!isAnySelected" type="button">
        <i class="fal fa-play fa-fw"></i>Resume
      </button>
    </li>
    <li matTooltip="Archive selected automations." matTooltipPosition="left">
      <button (click)="table?.onArchiveSelected()" type="button" [disabled]="!isAnySelected">
        <i class="far fa-fw fa-archive"></i> Archive
      </button>
    </li>
    <li matTooltip="Unarchive selected automations." matTooltipPosition="left">
      <button (click)="table?.onUnarchiveSelected()" type="button" [disabled]="!isAnySelected">
        <i class="far fa-fw fa-archive"></i> Unarchive
      </button>
    </li>

    <li matTooltip="Delete selected automations." matTooltipPosition="left">
      <button (click)="table?.onDeleteSelected()" [disabled]="!isAnySelected" type="button">
        <i class="cs-icon cs-icon-trash"></i> Delete
      </button>
    </li>
  </ul>
</div>
