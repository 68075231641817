import { createAction, props } from '@ngrx/store'
import { Guest, GuestInteraction, GuestNote } from './guest.model'
import { GuestForm } from '@tv3/interfaces/forms/guest.form'
import { Update } from '@ngrx/entity'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { GuestInteractionRequest } from '@tv3/interfaces/requests/guest-interaction-request'
import { GuestFileRequest } from '@tv3/interfaces/requests/guest-file.request'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { Pagination } from '@tv3/interfaces/table/pagination'

export const LoadGuests = createAction('[Guest] Load Guests', props<{ pagination: Pagination }>())
export const LoadGuestsComplete = createAction(
  '[Guest] Load Guests Complete',
  props<{ guests: Guest[]; pagination: Pagination }>()
)

export const SearchGuests = createAction('[Guest] Search Guests', props<{ term: string }>())
export const SearchGuestsComplete = createAction('[Guest] Search Guests Complete', props<{ guests: Guest[] }>())

export const SearchElasticGuests = createAction('[Guest] Search Elastic Guests', props<{ term: string }>())
export const SearchElasticGuestsComplete = createAction(
  '[Guest] Search Elastic Guests Complete',
  props<{ guests: Guest[] }>()
)

export const GetGuest = createAction('[Guest] Get Guest', props<{ id: string }>())
export const GetGuestComplete = createAction('[Guest] Get Guest Complete', props<{ guest: Guest }>())

export const AddGuest = createAction('[Guest] Add Guest', props<{ form: GuestForm }>())
export const AddGuestComplete = createAction('[Guest] Add Guest Complete', props<{ guest: Guest }>())

export const AddGuestNotifyComplate = createAction('[Guest] Add Guest Notify Complete', props<{ guest: Guest }>())

export const AddTagsToGuest = createAction(
  '[Guest] Add Tags To Guest',
  props<{ guest: Guest; tags: string[]; newTags: string[] }>()
)
export const AddTagsToGuestComplete = createAction(
  '[Guest] Add Tags To GuestComplete',
  props<{ update: Update<Guest> }>()
)

export const AddGuests = createAction('[Guest] Add Guests', props<{ forms: GuestForm[] }>())
export const AddGuestsComplete = createAction('[Guest] Add Guests Complete', props<{ guests: Guest[] }>())

export const UpdateGuest = createAction(
  '[Guest] Update Guest',
  props<{ guestId: string; form: UpdateGuestRequest; inquiry?: Inquiry }>()
)
export const UpdateGuestComplete = createAction('[Guest] Update Guest Complete', props<{ update: Update<Guest> }>())

export const DeleteGuest = createAction('[Guest] Delete Guest', props<{ id: string }>())
export const DeleteGuestComplete = createAction('[Guest] Delete Guest Complete', props<{ id: string }>())

export const DeleteGuests = createAction('[Guest] Delete Guests', props<{ ids: string[] }>())
export const DeleteGuestsComplete = createAction('[Guest] Delete Guests Complete', props<{ ids: string[] }>())

export const ArchiveGuest = createAction('[Guest] Archive Guest', props<{ ids: string | string[] }>())
export const ArchiveGuestComplete = createAction('[Guest] Archive Guest Complete', props<{ update: Update<Guest> }>())

export const UnarchiveGuest = createAction('[Guest] Unarchive Guest', props<{ ids: string | string[] }>())
export const UnarchiveGuestComplete = createAction(
  '[Guest] Unarchive Guest Complete',
  props<{ update: Update<Guest> }>()
)

export const AddGuestNote = createAction('[Guest] Add Guest Note', props<{ guestId: string; note: string }>())
export const AddGuestNoteComplete = createAction(
  '[Guest] Add Guest Note Complete',
  props<{ guestId: string; data: GuestNote }>()
)

export const UpdateGuestNote = createAction('[Guest] Update Guest Note', props<{ guestId: string; data: GuestNote }>())
export const UpdateGuestNoteComplete = createAction(
  '[Guest] Update Guest Note Complete',
  props<{ guestId: string; data: GuestNote }>()
)

export const DeleteGuestNote = createAction('[Guest] Delete Guest Note', props<{ guest: Guest; data: GuestNote }>())
export const DeleteGuestNoteComplete = createAction(
  '[Guest] Delete Guest Note Complete',
  props<{ update: Update<Guest> }>()
)

export const GetGuestEmails = createAction('[Guest] Get Guest Emails', props<{ guest: Guest }>())
export const GetGuestEmailsComplete = createAction(
  '[Guest] Get Guest Emails Complete',
  props<{ update: Update<Guest> }>()
)

export const GetGuestBookings = createAction('[Guest] Get Guest Bookings', props<{ guest: Guest }>())
export const GetGuestBookingsComplete = createAction(
  '[Guest] Get Guest Bookings Complete',
  props<{ update: Update<Guest> }>()
)

export const AddGuestInteraction = createAction(
  '[Guest] Add Guest Interaction',
  props<{ guestId: string; data: GuestInteractionRequest }>()
)
export const AddGuestInteractionComplete = createAction(
  '[Guest] Add Guest Interaction Complete',
  props<{ guestId: string; data: GuestInteraction }>()
)

export const UpdateGuestInteractions = createAction(
  '[Guest] Update Guest Interactions',
  props<{ guestId: string; data: GuestInteraction[]; remove?: boolean }>()
)
export const UpdateGuestInteractionsComplete = createAction(
  '[Guest] Update Guest Interactions Complete',
  props<{ guestId: string; data: Guest }>()
)

export const UpdateGuestFiles = createAction(
  '[Guest] Update Guest Files',
  props<{ guestId: string; files: GuestFileRequest[] }>()
)
export const UpdateGuestFilesComplete = createAction(
  '[Guest] Update Guest Files Complete',
  props<{ guestId: string; data: Guest }>()
)

export const UpdateGuestItem = createAction(
  '[Guest] Update Guest Item',
  props<{ guestId: string; data: UpdateGuestRequest; message?: string }>()
)
export const UpdateGuestItemComplete = createAction(
  '[Guest] Update Guest Item Complete',
  props<{ guestId: string; data: Guest }>()
)

export const DeleteGuestEmail = createAction(
  '[Guest] Delete Guest Email',
  props<{ guestId: string; data: UpdateGuestRequest }>()
)
export const DeleteGuestEmailComplete = createAction(
  '[Guest] Delete Guest Email Complete',
  props<{ guestId: string; data: Guest }>()
)

export const AddGuestEmail = createAction(
  '[Guest] Add Guest Email',
  props<{ guestId: string; data: string | string[] }>()
)
export const AddGuestEmailComplete = createAction(
  '[Guest] Add Guest Email Complete',
  props<{ guestId: string; data: Guest }>()
)

export const DeleteGuestPhone = createAction(
  '[Guest] Delete Guest Phone',
  props<{ guestId: string; data: UpdateGuestRequest }>()
)
export const DeleteGuestPhoneComplete = createAction(
  '[Guest] Delete Guest Phone Complete',
  props<{ guestId: string; data: Guest }>()
)

export const AddGuestPhone = createAction(
  '[Guest] Add Guest Phone',
  props<{ guestId: string; data: string | string[] }>()
)
export const AddGuestPhoneComplete = createAction(
  '[Guest] Add Guest Phone Complete',
  props<{ guestId: string; data: Guest }>()
)

export const FetchGuests = createAction('[Guest] Fetch Guests', props<{ ids: string[] }>())
export const FetchGuestsComplete = createAction('[Guest] Fetch Guests Complete', props<{ guests: Guest[] }>())

export const DeleteGuestMessageComplete = createAction(
  '[Guest] Delete Message Complete',
  props<{ update: Update<Guest> }>()
)

export const UpdateGuestsComplete = createAction(
  '[Guest] Update Guests Complete',
  props<{ updates: Update<Guest>[] }>()
)
