import { Component, OnInit } from '@angular/core'
import { Validators, FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import {
  SaveForm,
  selectCurrentAccount,
  Destroyable,
  untilDestroy,
  UpdateUser,
  updateAccount,
} from '@tokeet-frontend/tv3-platform'
import { AuthService } from '@tv3/services/auth.service'
import * as lodash from 'lodash'
import { combineLatest } from 'rxjs'

@Component({
  selector: 'app-phone-number-request-dialog',
  templateUrl: './phone-number-request-dialog.component.html',
  styleUrls: ['./phone-number-request-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class PhoneNumberRequestDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    accountPhone: ['', [Validators.required]],
    userPhone: ['', [Validators.required]],
  })

  countryCode: string
  isLoading = true
  isValidating = false

  constructor(
    public dialogRef: MatDialogRef<PhoneNumberRequestDialogComponent>,
    private store: Store<any>,
    private fb: FormBuilder,
    private auth: AuthService
  ) {
    super()
  }

  ngOnInit(): void {
    combineLatest([this.store.pipe(select(selectCurrentAccount))])
      .pipe(untilDestroy(this))
      .subscribe(([account]) => {
        this.form.patchValue({
          accountPhone: account.phone,
          userPhone: this.auth.user?.phone,
        })
      })
  }

  @SaveForm()
  save(form: FormGroup) {
    const { accountPhone, userPhone } = form.getRawValue()
    const userData = this.auth.user
    const userAddress = userData.address
    this.store.dispatch(
      UpdateUser({
        userId: userData.id,
        payload: {
          phone: userPhone,
          firstname: userData.firstName,
          lastname: userData.lastName,
          email: userData.primaryEmail,
          roles: userData.roles,
          address: {
            address: userAddress?.address,
            city: userAddress?.city,
            country: userAddress?.country,
            country_code: userAddress?.cc || userAddress?.countryCode,
            state: userAddress?.state,
            postal_code: lodash.toString(userAddress?.postalCode || ''),
          },
          attributes: userData.attributes,
        },
      })
    )

    this.store.dispatch(updateAccount({ account: { phone: accountPhone } as any }))

    this.close()
  }

  close() {
    this.dialogRef.close()
  }
}
