<ul class="internal-module-switcher">
  <li
    class="module-item"
    routerLinkActive="active"
    matTooltip="Multi Calendar"
    *ngxPermissionsOnly="['canSeeMultiCalendar']"
  >
    <a routerLink="/calendar/multi"><i class="cs-icon cs-icon-calendar-alt"></i></a>
  </li>
  <li
    class="module-item"
    routerLinkActive="active"
    matTooltip="Standard Calendar"
    *ngxPermissionsOnly="['canSeeStandardCalendar']"
  >
    <a routerLink="/calendar/standard"><i class="cs-icon cs-icon-calendar-day"></i></a>
  </li>
  <li
    class="module-item"
    routerLinkActive="active"
    matTooltip="Planning Calendar"
    *ngxPermissionsOnly="['canSeePlanningCalendar']"
  >
    <a routerLink="/calendar/planning"><i class="cs-icon cs-icon-calendar-check"></i></a>
  </li>
  <li class="module-item" routerLinkActive="active" matTooltip="Agenda Calendar" *ngxPermissionsOnly="['canSeeAgenda']">
    <a routerLink="/calendar/agenda"><i class="cs-icon cs-icon-calendar-schedule"></i></a>
  </li>
  <li class="module-item" routerLinkActive="active" matTooltip="Hold Events" *ngxPermissionsOnly="['canSeeHold']">
    <a routerLink="/calendar/hold"><i class="cs-icon cs-icon-calendar-pause"></i></a>
  </li>
</ul>
