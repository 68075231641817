import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { InquiryBilling } from '@tv3/models/inquiry/inquiry-billing'
import { DeleteInquiryCard } from '@tv3/store/inquiry/inquiry-fields.actions'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { PaymentGateway } from '@tokeet-frontend/gateways'

@Component({
  selector: 'app-view-billing-details',
  templateUrl: './view-billing-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./view-billing-dialog.component.scss'],
})
export class ViewBillingDialogComponent implements OnInit {
  form = this.fb.group({})

  cardLogo: string

  constructor(
    public dialogRef: MatDialogRef<ViewBillingDialogComponent>,
    private fb: FormBuilder,
    private confirmDialog: ConfirmDialogService,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry; payment: PaymentGateway }
  ) {}

  ngOnInit() {
    this.setCardLogo(this.data.inquiry.billing)
  }

  setCardLogo(billing: InquiryBilling) {
    const type = billing.type && billing.type.toLowerCase()
    switch (type) {
      case 'mastercard':
      case 'mc':
        this.cardLogo = 'fa-5x fab fa-cc-mastercard'
        break
      case 'visa':
      case 'vi':
        this.cardLogo = 'fa-5x fab fa-cc-visa'
        break
      case 'discover':
      case 'ds':
        this.cardLogo = 'fa-5x fab fa-cc-discover'
        break
      case 'americanexpress':
      case 'ax':
        this.cardLogo = 'fa-5x fab fa-cc-amex'
        break
      default:
        this.cardLogo = 'fa-5x fal fa-credit-card'
        break
    }
  }

  deleteCard() {
    this.confirmDialog
      .confirm({
        title: 'Delete the card details?',
        body: 'This will permanently delete the card details from your payment gateway and AdvanceCM. This cannot be undone. <br><br>Are you sure?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteInquiryCard({ inquiry: this.data.inquiry }))
        this.close()
      })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.close()
  }
}
