import { Component, ViewChild } from '@angular/core'
import { AuthService, KEY_TOKEN, KEY_USER, USER_PERMISSIONS, USER_ROLE } from '@tv3/services/auth.service'
import { UtilityService } from '@tv3/services/utils/utility.service'
import { environment } from '@tv3/environments/environment'
import { Destroyable, isSomething, LocalStorage, untilDestroy, DialogService } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import { OpenInventoryOverlay } from '@tv3/store/overlay/overlay.actions'
import {
  selectActiveProductPlan,
  selectActiveProductPlanView,
  selectActiveTokeetPlanView,
} from '@tv3/store/plan/plan.selectors'
import { isSubscriptionValid, ProductsForPlan, PlanTypes } from '@tv3/store/plan/plan.model'
import { map, take, filter, switchMap } from 'rxjs/operators'
import { userRoleToFriendlyString } from '@tv3/utils/functions/user-role-to-string'
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown'
import { AmplitudeService } from '@tv3/services/amplitude.service'
import { AIResponderSettingsService } from '@tv3/containers/ai-responder/settings/ai-responser-settings.service'
import { PushRatesOverlayService } from '@tv3/containers/rates/rate-push-automations/push-rates-overlay/push-rates-overlay.service'
import { AddRentalDialogService } from '@tv3/containers/rentals/add/add-rental-dialog.service'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { AddGuestDialogService } from '@tv3/containers/guests/dialogs/add/add-guest-dialog.service'
import { AddExpenseDialogComponent } from '@tv3/containers/expenses/dialogs/add/add-expense-dialog.component'
import { LoadActivePlans, SubscriptionFeatureAccessLimited } from '@tv3/store/plan/plan.actions'
import { ChannelHelperService } from '@tv3/containers/channels/channel-connect/channel-helper.service'
import { of } from 'rxjs'
import { EditRateDialogService } from '@tv3/containers/rates/edit-rate-dialog/edit-rate-dialog.service'
import { AccountRatesDialogTab } from '@tv3/containers/rates/account-rates-dialog/account-rates-dialog.component'
import { AccountRatesDialogService } from '@tv3/containers/rates/account-rates-dialog/account-rates-dialog.service'
import { TaskDialogService } from '@tv3/containers/tasks/shared/task-dialog/task-dialog.service'
import { CostEstimatorDialogService } from '@tv3/containers/rates/cost-estimator-dialog/cost-estimator-dialog.service'
import { HoldDialogService } from '@tv3/containers/calendar/hold/hold-dialog/hold-dialog.service'
import { MessagingSettingsDialogService } from '@tv3/containers/messaging-settings/settings/messaging-settings.service'
import { AppOverviewDialogService } from '@tokeet-frontend/billing'

declare const window: any

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends Destroyable {
  @ViewChild('websiteWidgetMenu', { static: false }) websiteWidgetMenu: BsDropdownDirective

  isNavBarCollapsed = false

  isWebreadyInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.Webready }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  isPowerReportingInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.PowerReporting }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  isRateGenieInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.Rategenie }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  isAutomataInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.Automata }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  isSignatureInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.Signature }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  isChecklistInactive$ = this.store.pipe(
    select(selectActiveProductPlan, { product: ProductsForPlan.Checklist }),
    map((plan) => !isSubscriptionValid(plan)),
    untilDestroy(this)
  )

  aiResponderSub$ = this.store.pipe(
    select(selectActiveProductPlanView(ProductsForPlan.TokeetAI)),
    map((res) => res || {})
  )

  constructor(
    public utility: UtilityService,
    private storage: LocalStorage,
    private store: Store<any>,
    private auth: AuthService,
    private amplitudeService: AmplitudeService,
    private sidePanel: DialogService,
    private pushRatesOverlayService: PushRatesOverlayService,
    private aiSettings: AIResponderSettingsService,
    private messaginSettings: MessagingSettingsDialogService,
    private appOverviewDialog: AppOverviewDialogService,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    private addRentalDialog: AddRentalDialogService,
    private addHoldDialog: HoldDialogService,
    private addGuestDialog: AddGuestDialogService,
    private taskDialog: TaskDialogService,
    private channelHelperService: ChannelHelperService,
    private editRateDialogService: EditRateDialogService,
    private accountRatesDialogService: AccountRatesDialogService,
    private costEstimator: CostEstimatorDialogService
  ) {
    super()
  }

  get name() {
    if (this.auth.user) {
      const firstName = this.auth.user.firstName
      const lastName = this.auth.user.lastName
      return `${firstName} ${lastName}`
    } else {
      return ''
    }
  }

  get role() {
    if (this.auth.user) {
      return userRoleToFriendlyString(this.auth.user.roles)
    } else {
      return ''
    }
  }

  onOpenInventory() {
    this.store.dispatch(OpenInventoryOverlay())
  }

  canSee(
    product: 'power_reporting' | 'ownercenter' | 'rategenie' | 'automata' | 'signature' | 'checklist' | 'webready'
  ) {
    if (this.auth.hasRole('admin')) {
      return true
    }

    if (product === 'power_reporting' && (this.auth.hasRole('manager') || this.auth.hasRole('accounting'))) {
      return true
    }

    return !!R.pathOr(0, ['settings', product], this.auth.user)
  }

  switchToTV2() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }

    if (!isSomething(token)) {
      return
    }

    this.storage.remove(KEY_TOKEN)
    this.storage.remove(KEY_USER)
    this.storage.remove(USER_ROLE)
    this.storage.remove(USER_PERMISSIONS)

    window.location.href = `${environment.tv2Url}?token=${token}`
  }

  appStoreUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.appStoreUrl}login?token=${token}`
  }

  rategenieUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.rategenieUrl}login?token=${token}`
  }

  webreadyUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.webreadyUrl}login?token=${token}`
  }

  powerReportingUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.powerReportingUrl}/login?token=${token}`
  }

  automataUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.automataUrl}login?token=${token}`
  }

  signatureUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.signatureUrl}?token=${token}`
  }

  checklistUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.checklistUrl}login#token=${token}`
  }

  reportsUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.reportsUrl}login?token=${token}`
  }

  ownerCenterUrl() {
    let token = this.auth.token
    if (!isSomething(token)) {
      token = this.storage.get(KEY_TOKEN)
    }
    return `${environment.ownerCenterUrl}login?token=${token}`
  }

  onVisitAppStore() {
    this.amplitudeService.logEvent('visited-app-store')
  }

  onPushRates() {
    this.pushRatesOverlayService.openSide()
  }

  onAISettings(isValid: boolean) {
    if (isValid) {
      this.aiSettings.open()
    } else {
      this.onSubscribeApp(ProductsForPlan.TokeetAI)
    }
  }

  onMessagingSettings() {
    this.messaginSettings.open()
  }

  onSubscribeApp(product: string) {
    this.appOverviewDialog
      .open(product)
      .afterClosed()
      .subscribe((res) => {
        this.store.dispatch(LoadActivePlans({}))
      })
  }

  onCostEstimator() {
    this.costEstimator.open()
  }

  onAddRental() {
    this.addRentalDialog.open().pipe(take(1)).subscribe()
  }

  onAddBooking() {
    this.inquirySharedDialogsService.openAddInquiry({
      title: 'Create Booking',
      isBooking: true,
    })
  }

  onAddInquiry() {
    this.inquirySharedDialogsService.openAddInquiry({})
  }

  onAddHoldEvent() {
    this.addHoldDialog.open()
  }

  onAddGuest() {
    this.addGuestDialog.open()
  }

  onAddExpense() {
    this.sidePanel.openVariableDialog(AddExpenseDialogComponent, { width: '800px' })
  }

  onAddTask() {
    this.taskDialog.open()
  }

  onAddStandardRate() {
    this.editRateDialogService.open({ type: 'standard' })
  }

  onAddDynamicRate() {
    this.editRateDialogService.open({ type: 'dynamic' })
  }

  openGroupRates() {
    this.accountRatesDialogService.openSide({ activeTab: AccountRatesDialogTab.GroupRates })
  }

  onAddChannel() {
    this.store
      .pipe(
        select(selectActiveTokeetPlanView),
        filter((plan) => isSomething(plan)),
        take(1),
        switchMap((plan) => {
          if (plan.isPaid) {
            return this.channelHelperService.connectChannel().afterClosed()
          } else {
            this.store.dispatch(
              SubscriptionFeatureAccessLimited({
                currentPlanType: plan.type,
                requiredPlanType: PlanTypes.Entry,
              })
            )
            return of(null)
          }
        })
      )
      .subscribe()
  }
}
