import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { TokeetCreditChargeResponse } from './model'

export interface State extends EntityState<TokeetCreditChargeResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<TokeetCreditChargeResponse> = createEntityAdapter<TokeetCreditChargeResponse>({
  sortComparer: false,
  selectId: (t) => t.pkey,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
