import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { TripListingWizardComponent } from './trip-listing-wizard.component'
import { TripListingWizardStep1Component } from './step1/trip-listing-wizard-step1.component'
import { TripListingWizardStep2Component } from './step2/trip-listing-wizard-step2.component'
import { TripListingWizardStep3Component } from './step3/trip-listing-wizard-step3.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'

const components = [
  //
  TripListingWizardStep1Component,
  TripListingWizardStep2Component,
  TripListingWizardStep3Component,
  TripListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class TripListingWizardModule {}
