import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'
import { has, isObject, isString } from 'lodash'
import { Currency } from '../constants'

declare const require: any
const he = require('he')

@Pipe({
  name: 'tokeetCurrency',
})
export class TokeetCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, currency?: string | Currency, digitsInfo?: string, locale?: string): string | null {
    let symbol = '$'

    if (isString(currency)) {
      symbol = currency
    } else if (isObject(currency) && has(currency, 'symbol')) {
      symbol = (currency as Currency).symbol
    }

    symbol = he.decode(symbol)

    return this.currencyPipe.transform(value, symbol, 'code', digitsInfo, locale)
  }
}
