import { Injectable } from '@angular/core'
import { BookingDetailsPopupComponent } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.component'
import { map, switchMap } from 'rxjs/operators'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { CalendarEventView } from '@tv3/store/calendar/calendar.view'
import * as R from 'ramda'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

@Injectable()
export class BookingDetailsPopupService {
  constructor(private dialog: MatDialog, private inquiryService: InquiryService) {}

  open(event: CalendarEventView) {
    if (R.isNil(event.bookingId)) {
      return
    }

    this.inquiryService
      .loadPreview(event.bookingId)
      .pipe(
        switchMap(
          (inquiry) => this.inquiryService.prepareCheckInOut(inquiry, false),
          (inquiry, checkInOut) => ({ inquiry, checkInOut })
        ),
        map(({ inquiry, checkInOut }) => {
          return {
            event,
            rental: event.rental,
            inquiry,
            checkin: checkInOut.checkIn,
            checkout: checkInOut.checkOut,
          }
        })
      )
      .subscribe((params) => {
        const defaultConfig: MatDialogConfig = {
          width: '800px',
          height: 'auto',
          panelClass: ['fixed-modal'],
          data: { ...params },
        }

        this.dialog.open(BookingDetailsPopupComponent, defaultConfig)
      })
  }
}
