import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class Flight extends Serializable<Flight> {
  @Expose({ name: 'flight_num' })
  flightNum: string

  airline: string
  timezone: string
  departure: string

  departureTimeView = ''

  static deserialize(data: object): Flight {
    return deserialize(Flight, data)
  }
}
