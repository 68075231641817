import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  Account,
  AccountService,
  ActionFailed,
  Channel,
  ChannelService,
  Destroyable,
  Rental,
  rentalDetails,
  RentalService,
  Toaster,
  updateAccountComplete,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as lodash from 'lodash'
import { Observable } from 'rxjs'
import { mapTo, switchMap, take, tap } from 'rxjs/operators'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { AuthService } from '@tv3/services/auth.service'
import {
  AgodaPropertyClosedComplete,
  AgodaPropertyCreateComplete,
  AgodaPropertyUpdateComplete,
} from '@tv3/store/channel-content/conent.actions'
import {
  ChannelPropertySetting,
  ChannelPropertySettingPayload,
} from '@tv3/store/channel-property-setting/channel-property-setting.model'
import { ChannelPropertySettingService } from '@tv3/store/channel-property-setting/channel-property-setting.service'
import {
  CreateChannelPropertySettingComplete,
  UpdateChannelPropertySettingComplete,
} from '@tv3/store/channel-property-setting/channel-property-setting.actions'
import { AgodaContentProperty } from '@tv3/store/channel-content/models/agoda.model'
import {
  AgodaAdditionalFeeCodes,
  availablePropertyAmenitiesTokeetIds,
} from '@tv3/store/channel-property-setting/settings/agoda-property-settings'
import { AgodaContentPropertyPayload } from '@tv3/store/channel-content/payload/agoda.request'
import { AgodaContentService } from '@tv3/store/channel-content/agoda-content.service'
import { createForm as createRentalForm } from '@tv3/containers/channels/content/bdc/property/step-forms/step-rental/bdc-content-step-rental-form.component'
import { createForm as createAmenitiesForm } from '@tv3/containers/channels/content/bdc/property/step-forms/step-amenities/content-step-amenities-form.component'
import { createForm as createAccountForm } from '@tv3/containers/channels/content/bdc/property/step-forms/step-account/bdc-content-step-account-form.component'
import { createForm as createContactsForm } from '@tv3/containers/channels/content/bdc/property/step-forms/step-contacts/bdc-content-step-contacts-form.component'
import { createForm as createInstructionForm } from '@tv3/containers/channels/content/agoda/property/step-forms/step-instructions/agoda-content-step-instruction-form.component'
import { createForm as createAdditionalFeesForm } from '@tv3/containers/channels/content/agoda/property/step-forms/step-additional-fees/agoda-content-step-additional-fees-form.component'
import { ChannelConnectHelperService } from '@tv3/containers/channels/channel-connect/channel-connect-wizards/channel-connect-helper.service'

@Component({
  selector: 'app-agoda-content-property-setup',
  templateUrl: './agoda-content-property-setup.component.html',
  styleUrls: ['./agoda-content-property-setup.component.scss'],
})
export class AgodaContentPropertySetupComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Output() rentalChange = new EventEmitter<Rental>()
  @Input() agodaProperty: AgodaContentProperty
  @Input() agodaPropertySetting: ChannelPropertySetting

  get propertyId() {
    return (
      (this.agodaProperty && this.agodaProperty.propertyId) ||
      (this.connection && this.connection.propertyId) ||
      (this.agodaPropertySetting && this.agodaPropertySetting.propertyId)
    )
  }

  get rentalId() {
    return (this.rental && this.rental.id) || (this.connection && this.connection.rentalId)
  }

  rentalAmenityDetails = rentalDetails.filter((item) => availablePropertyAmenitiesTokeetIds.indexOf(item.id) > -1)

  rentalInstructionsForm = createInstructionForm(this.fb)
  rentalForm = createRentalForm(this.fb)
  rentalAmenitiesForm = createAmenitiesForm(this.fb)
  accountForm = createAccountForm(this.fb)
  contactsForm = createContactsForm(this.fb)
  additionalFeesForm = createAdditionalFeesForm(this.fb)

  constructor(
    private channelService: ChannelService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private auth: AuthService,
    private channelConnectHelperService: ChannelConnectHelperService,
    private rentalService: RentalService,
    private accountService: AccountService,
    private agodaContentService: AgodaContentService,
    private channelPropertySettingService: ChannelPropertySettingService
  ) {
    super()
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.connection && this.connection.rentalId) {
        this.rentalForm.get('rentalId').disable()
      }
      if (this.rentalId) {
        this.rentalForm.patchValue({ rentalId: this.rentalId })
      }

      if (this.agodaPropertySetting) {
        this.rentalForm.patchValue({ rentalId: this.agodaPropertySetting.rentalId })
      }
    }, 250)
  }

  onRentalChange(rental: Rental) {
    this.rental = rental
    this.rentalChange.emit(rental)
  }

  savePropertyContentSetting(propertyId, settings: ChannelPropertySettingPayload): Observable<ChannelPropertySetting> {
    const payload = {
      ...settings,
      property_id: propertyId,
      listing_id: propertyId,
    } as ChannelPropertySettingPayload
    if (this.agodaPropertySetting) {
      return this.channelPropertySettingService.update(this.agodaPropertySetting.id, payload).pipe(
        tap((data) =>
          this.store.dispatch(
            UpdateChannelPropertySettingComplete({
              update: { id: this.agodaPropertySetting.id, changes: data },
            })
          )
        )
      )
    } else {
      return this.channelPropertySettingService.create(payload).pipe(
        tap((data) => {
          this.store.dispatch(CreateChannelPropertySettingComplete({ item: data }))
        })
      )
    }
  }

  saveRentalData() {
    const rawData = this.rentalForm.getRawValue()
    const rentalData = lodash.omit(rawData, ['rentalId', 'timezone'])
    // format checkin time/checkout time
    rentalData.checkin = this.rentalService.toCheckTime(rawData.checkin, rawData.timezone)
    rentalData.checkout = this.rentalService.toCheckTime(rawData.checkout, rawData.timezone)
    rentalData.detail = this.getSelectedAmenities()

    // Instruction data
    const instructionData = this.rentalInstructionsForm.getRawValue()
    const directions = (instructionData.directions || '').replace(/(?:\r\n|\r|\n)/g, '<br/>')
    const rules = (instructionData.rules || '').replace(/(?:\r\n|\r|\n)/g, '<br/>')
    rentalData.instructions = {
      ...this.rental.instructions,
      directions,
      rules,
    }

    // @ts-ignore
    return this.rentalService.update(this.rental.id, rentalData).pipe(
      tap((rental: Rental) =>
        this.store.dispatch(
          updateRentalComplete({
            update: {
              id: rental.id,
              changes: rental,
            },
          })
        )
      )
    )
  }

  saveAccountData() {
    const accountData = this.accountForm.getRawValue()
    return this.accountService.update({ account: accountData }).pipe(
      tap((account: Account) =>
        this.store.dispatch(
          updateAccountComplete({
            update: {
              id: account.id,
              changes: account,
            },
          })
        )
      )
    )
  }

  createOrUpdateChannelProperty(settings: ChannelPropertySettingPayload) {
    const payload = {
      account: this.auth.user.account,
      rentalId: this.rental.id,
      settings: settings.attributes,
    } as AgodaContentPropertyPayload

    const isCreate = !this.propertyId

    const sub = !isCreate
      ? this.agodaContentService.update(this.propertyId, payload)
      : this.agodaContentService.create(payload).pipe(
          switchMap((data) =>
            this.channelConnectHelperService
              .connectChannelWithPropertyId(this.channel, data.propertyId)
              .pipe(mapTo(data))
          ),
          tap(({ propertyId, warnings }) => {
            this.store.dispatch(
              isCreate ? AgodaPropertyCreateComplete({ propertyId }) : AgodaPropertyUpdateComplete({ propertyId })
            )
            this.toaster.success(`Property ${isCreate ? 'Created' : 'Updated'} successfully.`)
            if (warnings && warnings.length) {
              this.toaster.warning(`Property Warnings: ${warnings.join('\n')}`)
            }
          })
        )
    return sub
  }

  onSave() {
    const { license, localRecommendations } = this.rentalInstructionsForm.getRawValue()
    const { cleaning, facility } = this.additionalFeesForm.getRawValue()
    const settingsPayload = {
      rental_id: this.rental.id,
      property_id: this.propertyId,
      channel: this.channel.name,
      attributes: {
        contacts: this.contactsForm.getRawValue(),
        license,
        localRecommendations,
        fees: [
          { code: AgodaAdditionalFeeCodes.Cleaning, amount: cleaning || 0 },
          { code: AgodaAdditionalFeeCodes.Facility, amount: facility || 0 },
        ],
      },
    } as ChannelPropertySettingPayload

    this.saveRentalData()
      .pipe(
        switchMap(() => this.saveAccountData()),
        switchMap(() => this.createOrUpdateChannelProperty(settingsPayload)),
        switchMap(({ propertyId }) => this.savePropertyContentSetting(propertyId, settingsPayload)),
        take(1)
      )
      .subscribe()
  }

  onCloseProperty() {
    this.agodaContentService.close(this.propertyId).subscribe(
      () => {
        this.toaster.success('Property Closed successfully')
        this.store.dispatch(AgodaPropertyClosedComplete({ propertyId: this.propertyId }))
      },
      (error) => {
        this.store.dispatch(ActionFailed(error))
      }
    )
  }

  onEditRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }

  getSelectedAmenities() {
    const { amenities: rentalDetailCheckData } = this.rentalAmenitiesForm.getRawValue()
    const details = {
      ...this.rental.detail,
    }
    rentalDetailCheckData.forEach((value, i) => (details[this.rentalAmenityDetails[i].id] = !!value))
    return lodash.reduce(
      details,
      (acc, value, key) => {
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
  }

  selectedPropertyAmenitiesCount(form: FormGroup) {
    const { amenities } = form.getRawValue()
    return amenities.filter((d) => d).length
  }
}
