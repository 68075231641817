import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TokeetMessagingSettingsResponse, UpdateTokeetMessagingSettingsPayload } from './model'

@Injectable()
export class MessagingSettingsService {
  constructor(private http: HttpClient) {}

  getSettings() {
    const url = `@api/account/tokeet-messaging-settings`

    return this.http.get<TokeetMessagingSettingsResponse>(url)
  }

  updateSettings(payload: UpdateTokeetMessagingSettingsPayload) {
    const url = `@api/account/tokeet-messaging-settings`

    return this.http.put<TokeetMessagingSettingsResponse>(url, { tokeet_messaging_recharge_settings: payload })
  }

  recharge(amount: number) {
    return this.http.post(`@api/subscriptions/tokeet-messaging/recharge`, { product: 'tokeet_messaging', amount })
  }
}
