import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import * as lodash from 'lodash'
interface ScrollRect {
  width: number
  height: number
  scrollWidth: number
  scrollHeight: number
  scrollLeft: number
  scrollTop: number
}

interface Point {
  x: number
  y: number
}

@Component({
  selector: 'app-overflow-scroll',
  templateUrl: './overflow-scroll.component.html',
  styleUrls: ['./overflow-scroll.component.scss'],
})
export class OverflowScrollComponent {
  @Input() threshold = 10

  @ViewChild('container') container: ElementRef<HTMLElement>
  @ViewChild('prev') prevBtn: ElementRef<HTMLElement>
  @ViewChild('next') nextBtn: ElementRef<HTMLElement>

  hasPrev = false
  hasNext = false

  isHandling = false
  startPoint: Point

  isScrollable = false

  scrollRect: ScrollRect

  constructor() {}

  start(p: Point) {
    this.startPoint = p
    this.updateRect()
  }

  end() {
    this.startPoint = null
    this.isHandling = false
  }

  move(p: Point) {
    let distance = Math.round(p.y - this.startPoint.y)
    if (!this.isHandling && Math.abs(distance) >= this.threshold) {
      this.isHandling = true
    }

    if (!this.isHandling) {
      return
    }

    const max = this.scrollRect.scrollHeight - this.scrollRect.height
    const offset = this.guardRange(this.scrollRect.scrollTop - distance, 0, max)
    this.scrollTo(offset)

    this.updateNav(offset, max)
  }

  onResize() {
    this.end()
    this.updateRect()
  }

  onEnd() {
    this.end()
  }

  onMouseDown($event: MouseEvent) {
    this.start({ x: $event.clientX, y: $event.clientY })

    const onMouseMove = (event: MouseEvent) => {
      this.move({ x: event.clientX, y: event.clientY })
    }

    const onMouseUp = () => {
      this.end()

      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
    }

    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mousemove', onMouseMove)
  }

  onTouchStart(event: TouchEvent) {
    this.start({ x: event.touches[0].clientX, y: event.touches[0].clientY })
  }

  onTouchMove(event: TouchEvent) {
    this.move({ x: event.touches[0].clientX, y: event.touches[0].clientY })
  }

  onScroll($event: MouseEvent) {
    if (this.startPoint) return
    this.updateRect()
  }

  onWheel($event: WheelEvent) {
    let offset = this.guardRange(Math.abs($event.deltaY) * 0.1, 5, 50)
    if ($event.deltaY < 0) offset = -offset

    this.scrollTo(this.container.nativeElement.scrollTop + offset)
  }

  nav(forward: boolean) {
    const rect = this.scrollRect
    let elm = this.container.nativeElement

    let childNodes: NodeListOf<ChildNode> = elm.childNodes

    let contentContainer = elm.querySelector('[data-overflow-scroll-content-container]')

    if (contentContainer) {
      childNodes = contentContainer.childNodes
    }

    if (!elm || !rect) return

    let offset = 0

    const top = (this.hasNext && this.prevBtn.nativeElement.offsetHeight) || 0
    const bottom = this.nextBtn.nativeElement.offsetHeight || 0

    const threshold = rect.scrollTop + (forward ? rect.height - bottom : top)

    lodash.forEach(childNodes, (node) => {
      if (node.nodeType !== Node.ELEMENT_NODE) return

      const elm = node as HTMLElement

      const newOffset = elm.offsetTop + elm.offsetHeight

      if (forward && newOffset > threshold) return false

      offset = newOffset

      if (offset > threshold) return false
    })

    offset = forward
      ? Math.min(offset - top, rect.scrollHeight - rect.height)
      : Math.max(offset - rect.height + bottom, 0)

    this.scrollTo(offset, true)
  }

  scrollTo(top: number, smooth = false) {
    const elm = this.container.nativeElement
    if (elm.scrollTo && smooth) {
      elm.scrollTo({ top, behavior: 'smooth' })
    } else {
      elm.scrollTop = top
    }
  }

  updateNav(offset: number, max: number) {
    const edge = 5

    const prev = offset >= edge
    const next = offset <= max - edge

    if (this.hasPrev !== prev) this.hasPrev = prev
    if (this.hasNext !== next) this.hasNext = next
  }

  updateRect() {
    const elm = this.container.nativeElement

    const rect: ScrollRect = {
      width: elm.clientWidth,
      height: elm.clientHeight,
      scrollWidth: elm.scrollWidth,
      scrollHeight: elm.scrollHeight,
      scrollLeft: elm.scrollLeft,
      scrollTop: elm.scrollTop,
    }

    const offset = rect.scrollTop
    const max = rect.scrollHeight - rect.height

    this.isScrollable = max > 0

    this.updateNav(offset, max)

    this.scrollRect = rect
  }

  guardRange(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min)
  }
}
