import { Exclude, plainToClass, Type, Expose, Serializable } from '@tokeet-frontend/tv3-platform'
import { Address } from '@tv3/models/account/address'
import { Attachment } from '@tokeet-frontend/tv3-platform'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { Message } from '@tv3/store/message/message.model'
import * as moment from 'moment'
import * as R from 'ramda'
import { interactionType } from '@tv3/constants/guests'
import { epochToView } from '@tokeet-frontend/tv3-platform'
import { BookingCostResolver, isExternalBookingSource } from '@tokeet/cost-resolver'

@Exclude()
export class GuestInteraction extends Serializable<GuestInteraction> {
  @Expose({ name: 'key' })
  id: string

  @Expose({ name: 'key' })
  key: string

  @Expose({ name: 'comment' })
  comment: string

  @Expose({ name: 'date' })
  date: number

  @Expose({ name: 'timestamp' })
  timestamp: number

  @Expose({ name: 'type' })
  type: 'meeting' | 'phone call' | 'comment'

  @Expose({ name: 'user' })
  user: string

  dateView: string
  typeView: string

  static deserialize(data: any): GuestInteraction {
    let interaction: GuestInteraction = plainToClass<GuestInteraction, GuestInteraction>(GuestInteraction, data)

    // @todo - figure out why date can be both number and a string
    const view = R.is(Number, interaction.date) ? moment(interaction.date * 1000) : moment(interaction.date)
    interaction.dateView = view.format('DD - MMM - YYYY')

    const tView = R.find((r: { name: string; value: string }) => r.value === interaction.type, interactionType)
    interaction.typeView = tView ? tView.name : ''

    return interaction
  }
}

export class GuestBooking extends Serializable<GuestBooking> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'inquiry_source' })
  inquirySource: string

  touch: number

  @Expose({ name: 'guest_depart' })
  guestDepart: number

  @Expose({ name: 'guest_arrive' })
  guestArrive: number

  read: number

  @Expose({ name: 'num_adults' })
  numAdults: number

  @Expose({ name: 'num_child' })
  numChild: number

  @Expose({ name: 'booking_engine' })
  @Type(() => BookingEngineCost)
  bookingEngine: BookingEngineCost

  cost: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  touchView: string
  guestArriveView: string
  guestDepartView: string
  nightsView: number
  personCountView: number
  total: number

  static deserialize(data: any): GuestBooking {
    let booking: GuestBooking = plainToClass<GuestBooking, GuestBooking>(GuestBooking, data)

    let view = R.is(Number, booking.touch) ? moment(booking.touch * 1000) : moment(booking.touch)
    booking.touchView = view.format('DD - MMM - YYYY')

    booking.total = BookingCostResolver.parseBookingCharges(booking as any).sum

    view = R.is(Number, booking.guestArrive) ? moment.utc(booking.guestArrive * 1000) : moment(booking.guestArrive)
    booking.guestArriveView = view.format('DD - MMM - YYYY')

    view = R.is(Number, booking.guestDepart) ? moment.utc(booking.guestDepart * 1000) : moment(booking.guestDepart)
    booking.guestDepartView = view.format('DD - MMM - YYYY')

    if (booking.guestArrive && booking.guestDepart) {
      booking.nightsView = Math.round((booking.guestDepart - booking.guestArrive) / (60 * 60 * 24))
    }

    if (booking.numAdults || booking.numChild) {
      booking.personCountView = (booking.numAdults ? booking.numAdults : 0) + (booking.numChild ? booking.numChild : 0)
    }

    return booking
  }
}

@Exclude()
export class GuestNote extends Serializable<GuestNote> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'key' })
  key: string

  @Expose({ name: 'note' })
  note: string

  @Expose({ name: 'user' })
  user: string

  @Expose({ name: 'timestamp' })
  timestamp: number

  timestampView: string
  timestampMonth: string
  timestampYear: string

  static deserialize(data: any): GuestNote {
    let note: GuestNote = plainToClass<GuestNote, GuestNote>(GuestNote, data)

    const view = R.is(Number, note.timestamp) ? moment(note.timestamp * 1000) : moment(note.timestamp)
    note.timestampView = view.format('DD - MMM - YYYY')
    note.timestampMonth = view.format('MMMM')
    note.timestampYear = view.format('YYYY')

    return note
  }
}

export class Guest extends Serializable<Guest> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'primaryemail' })
  primaryEmail: string

  @Type(() => Address)
  address: Address

  @Expose({ name: 'lastvisit' })
  lastvisit: number | string

  @Expose({ name: 'lastmessage' })
  lastMessage: number

  @Expose({ name: 'ref_id' })
  refId: number | string

  @Expose({ name: 'lastlogin' })
  lastLogin: number

  @Expose({ name: 'sms_number' })
  smsNumber: number

  @Expose({ name: 'resettoken' })
  resetToken: string

  @Expose({ name: 'pic_url' })
  picUrl: string

  @Type(() => GuestInteraction)
  interaction: GuestInteraction[]

  @Type(() => GuestNote)
  notes: GuestNote[]

  email: { [id: string]: number }

  username: string
  account: number
  phone: string
  name: string
  source: string
  created: number
  archived: number
  bookings: string[]
  tags: string[]

  phones: string[]
  attributes: { [id: string]: string }
  drink: string
  birthday: number | string
  sport: string
  token: string
  hobby?: string
  wife?: string
  kids?: string

  @Expose({ name: 'email_messages' })
  @Type(() => Message)
  emailMessages?: Message[]

  @Expose({ name: 'bookings_info' })
  @Type(() => GuestBooking)
  bookingsInfo?: GuestBooking[]

  @Type(() => Attachment)
  files?: Attachment[]

  /* View Fields */
  countryView: string
  lastMessageView: string
  initials: string
  emailView: { [id: string]: number }
  primaryEmailView: string
  createdView: string

  static deserialize(data: any): Guest {
    let guest: Guest = plainToClass<Guest, Guest>(Guest, data)

    const messageView = R.is(Number, guest.lastMessage) ? moment(guest.lastMessage * 1000) : moment(guest.lastMessage)
    guest.lastMessageView = epochToView(guest.lastMessage)
    guest.countryView = R.pathOr('', ['address', 'country'], guest)

    const guestName = R.pathOr('', ['name'], guest)
    const initialsList = String(guestName).match(/\b\w/g) || []
    guest.initials = ((initialsList.shift() || '') + (initialsList.pop() || '')).toUpperCase()

    const primaryEmail = R.pipe(R.when(R.is(Number), R.toString), R.defaultTo(''))(guest.primaryEmail)

    guest.primaryEmailView = primaryEmail.replace(/\%2E/g, '.')
    guest.emailView = {}
    if (guest.email) {
      for (const i in guest.email) {
        guest.emailView[i.replace(/\%2E/g, '.')] = guest.email[i]
      }
    }

    guest.createdView = epochToView(guest.created)

    return guest
  }
}
