import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './automation.reducer'
import * as state from './automation.state'
import * as R from 'ramda'
import { selectApiChannelsWithDefaults } from '@tokeet-frontend/tv3-platform'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'
import {
  automationDisabledChannels,
  AutomationViewGroup,
  AutomationResponse,
  getAutomationGroupId,
} from './automation.model'
import { AutomationServiceTypes, AutomationTypes } from './automation-services'
import { AutomationEventTypes, getAvailableAutomationEvents } from './automation-events'

export const automationState = createFeatureSelector<state.State>('automation')

export const selectAllAutomations = createSelector(automationState, reducer.selectAll)

export const selectAutomationsLoaded = createSelector(automationState, (s: state.State) => s.isLoaded)
export const selectAutomationEntities = createSelector(automationState, reducer.selectEntities)

export const selectAutomationsByType = (type: AutomationTypes, onlyVisibleEvents = true) =>
  createSelector(selectAllAutomations, (automations) => {
    automations = automations.filter((a) => a.type === type)
    if (onlyVisibleEvents) {
      const events = getAvailableAutomationEvents(type)
      return automations.filter((a) => events.find((e) => e.event === a.event))
    } else {
      return automations
    }
  })

export const selectAvailableAutomationChannels = createSelector(
  selectChannelsWithCustomChannels(selectApiChannelsWithDefaults, 'token'),
  (channels) => {
    return R.pipe(
      R.filter((c: any) => !automationDisabledChannels.includes(c.id)),
      R.filter((c: any) => c.id !== 'airbnb'),
      R.map((c: any) => {
        if (c.id === 'airbnbapiv2') {
          return {
            id: 'airbnb',
            name: 'airbnb',
            label: 'Airbnb API',
          }
        } else {
          return c
        }
      })
    )(channels)
  }
)

export const selectAllAutomationGroups = createSelector(selectAllAutomations, (items) => {
  return R.pipe(
    R.groupBy((a: AutomationResponse) => getAutomationGroupId(a)),
    R.values,
    R.reduce((acc, automations) => {
      automations = R.sortBy((a) => -a.lastupdate, automations)
      const ids = R.map((a) => a.pkey, automations)

      const group = {
        ...R.head(automations),
        ids,
        rentals: R.uniq(R.map((a) => a.settings.rental, automations)),
        channels: R.uniq(R.map((a) => a.settings.channel, automations)),
        items: automations,
      } as AutomationViewGroup
      return R.append(group, acc)
    }, [] as AutomationViewGroup[])
  )(items)
})

export const selectAutomationGroupByTriggerId = (triggerId: string) =>
  createSelector(selectAllAutomationGroups, (items) => {
    return items?.find((t) => t.ids?.includes(triggerId))
  })

export const selectAutomationGroupsByType = (type: AutomationTypes, onlyVisibleEvents = true) => {
  return createSelector(selectAllAutomationGroups, (items) => {
    items = items.filter((t) => t.type === type)
    if (onlyVisibleEvents) {
      const events = getAvailableAutomationEvents(type)
      return items.filter((t) =>
        events.find((e) => {
          if (e.event === 'time') {
            return t.event.startsWith('time')
          }
          return e.event === t.event
        })
      )
    } else {
      return items
    }
  })
}

export const selectAutomationGroupsByEvent = (event: AutomationEventTypes, service = AutomationServiceTypes.Advance) =>
  createSelector(selectAllAutomationGroups, (items) => {
    return items.filter((t) => t.event === event && t.service === service)
  })

export const selectIsAdding = createSelector(automationState, (s: state.State) => s.isAdding)
export const selectIsUpdating = createSelector(automationState, (s: state.State) => s.isUpdating)
