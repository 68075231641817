import { ChangeDetectionStrategy, Component } from '@angular/core'

@Component({
  selector: 'app-calendar-nav-group',
  templateUrl: './calendar-nav-group.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarNavGroupComponent {}
