import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SharedModule } from '@tv3/shared/shared.module'
import { take } from 'rxjs/operators'
import { ConfigWebsiteDomainDialogComponent } from './config-website-domain-dialog.component'
import { Website } from '@tv3/store/website/website.model'

@Injectable({
  providedIn: SharedModule,
})
export class ConfigWebsiteDomainDialogService {
  constructor(private dialog: MatDialog) {}

  public open(website: Website) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      data: { website },
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(ConfigWebsiteDomainDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
