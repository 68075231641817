import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import * as R from 'ramda'

@Pipe({
  name: 'addSubtractDay',
})
export class AddSubtractDayPipe implements PipeTransform {
  transform(value: any, add = true): Date {
    if (moment.isDate(value)) {
      if (add) {
        return moment(value).add(1, 'days').toDate()
      } else {
        return moment(value).subtract(1, 'days').toDate()
      }
    }
    if (moment.isMoment(value)) {
      if (add) {
        return value.add(1, 'days').toDate()
      } else {
        return value.subtract(1, 'days').toDate()
      }
    }

    if (R.is(Number, value)) {
      if (add) {
        return moment
          .utc(value * 1000)
          .add(1, 'days')
          .toDate()
      } else {
        return moment
          .utc(value * 1000)
          .subtract(1, 'days')
          .toDate()
      }
    }
  }
}
