import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TokeetCreditChargeLogResponse, TokeetCreditChargeResponse } from './model'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'
import { of } from 'rxjs'

@Injectable()
export class CreditChargeService {
  constructor(private http: HttpClient) {}

  charges(product: string) {
    return this.http.get<TokeetCreditChargeResponse[]>(`@api/subscription_prepaid/all?product=${product}`)
  }

  getActiveCredits(product: string) {
    return this.http
      .get<TokeetCreditChargeResponse[]>(`@api/subscription_prepaid/all?product=${product}&status=active`)
      .pipe(map((items) => lodash.head(items)))
  }

  logs(subId: string) {
    return this.http.get<TokeetCreditChargeLogResponse[]>(`@api/subscription_prepaid/logs/all?subscription_id=${subId}`)
  }

  recharge(product: string, amount: number) {
    if (product === 'tokeet_ai') {
      return this.aiRecharge(amount)
    } else if (product === 'tokeet_messaging') {
      return this.messagingRecharge(amount)
    } else {
      return of(null)
    }
  }

  aiRecharge(amount: number) {
    return this.http.post(`@api/subscriptions/tokeet-ai/recharge`, { product: 'tokeet_ai', amount })
  }

  messagingRecharge(amount: number) {
    return this.http.post(`@api/subscriptions/tokeet-messaging/recharge`, { product: 'tokeet_messaging', amount })
  }
}
