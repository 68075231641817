<div class="container-box details-box">
  <div class="filters-box">
    <h6 class="mb-0">Activity Logs</h6>
    <div class="flex-fill"></div>
    <ng-container [formGroup]="filters">
      <mat-form-field2 [showLabel]="false">
        <mat-select formControlName="action" placeholder="Type">
          <mat-option value=""> All </mat-option>
          <mat-option [value]="actionTypes.AvailabilityPush">
            {{ actionTypes.AvailabilityPush | connectionActivityType }}
          </mat-option>
          <mat-option [value]="actionTypes.AvailabilityPull" *ngIf="availabilityPull">
            {{ actionTypes.AvailabilityPull | connectionActivityType }}
          </mat-option>
          <mat-option [value]="actionTypes.RatesPush">
            {{ actionTypes.RatesPush | connectionActivityType }}
          </mat-option>
          <ng-container *ngIf="isFeedChannel()">
            <mat-option [value]="actionTypes.ListingPush">
              {{ actionTypes.ListingPush | connectionActivityType }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field2>
      <mat-form-field2 [showLabel]="false">
        <mat-select formControlName="status" placeholder="Status">
          <mat-option value="">All</mat-option>
          <mat-option value="success">Success</mat-option>
          <mat-option value="error">Error</mat-option>
        </mat-select>
      </mat-form-field2>

      <app-cancel-filters [isFiltering]="filters | isFiltering" [filterControl]="filters"></app-cancel-filters>

      <button type="button" class="btn btn-secondary-light" (click)="onRefreshLog()">
        <i class="far fa-sync"></i>
      </button>
    </ng-container>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Date/Time</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.created | epoch : dateFormat }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.type | connectionActivityType }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Message</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-truncate">
              <ng-container *ngIf="element.message">
                {{ element.message | truncate : 100 }}
              </ng-container>
              <ng-container *ngIf="!element.message"> OK </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onShowLogDetail(row)"
          class="clickable"
          [ngClass]="{ 'row-danger': row.past && row.status !== 2 }"
        ></mat-row>
      </mat-table>
      <app-empty-table *ngIf="!dataSource?.filteredData?.length" type="ConnectionActivities"></app-empty-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="!dataSource?.filteredData?.length"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
  </div>
</div>
