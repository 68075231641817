import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AirbnbChannelConfig, ChannelsService } from '@tokeet-frontend/channels'
import { SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-airbnb-sync-settings-dialog',
  templateUrl: './airbnb-sync-settings-dialog.component.html',
  styleUrls: ['./airbnb-sync-settings-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AirbnbSyncSettingsDialogComponent implements OnInit {
  form = this.fb.group({
    pricing_settings: [false],
    booking_settings: [false],
    availability_rules: [false],
  })

  isLoading = true
  config: AirbnbChannelConfig

  constructor(
    public dialogRef: MatDialogRef<AirbnbSyncSettingsDialogComponent>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private channelService: ChannelsService,
    @Inject(MAT_DIALOG_DATA) public data: { channelId: string }
  ) {}

  ngOnInit(): void {
    this.channelService
      .getAirbnbChannelConfig(this.data.channelId, { service_only: '1' })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((config) => {
        this.form.patchValue(config?.syncSettings || {})
        this.config = config
      })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = this.form.getRawValue()
    this.isLoading = true
    this.channelService
      .setAirbnbChannelConfig(
        this.data.channelId,
        {
          ...this.config,
          syncSettings: formValues,
        },
        { to_service: '1' }
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Airbnb Sync Settings Saved Successfully')
          this.dialogRef.close()
        },
        (error) => this.toaster.error('Unable to save Airbnb Sync Settings', null, error)
      )
  }
}
