import { Pipe, PipeTransform } from '@angular/core'
import { ApiConnectionActivityTypes } from '@tv3/store/connection/connection.model'

@Pipe({
  name: 'connectionActivityType',
})
export class ConnectionActivityTypePipe implements PipeTransform {
  transform(type: ApiConnectionActivityTypes) {
    let typeText = `${type}`
    switch (type) {
      case ApiConnectionActivityTypes.AvailabilityPull:
        typeText = 'Availability Pull'
        break
      case ApiConnectionActivityTypes.AvailabilityPush:
        typeText = 'Availability Push'
        break
      case ApiConnectionActivityTypes.RatesPush:
        typeText = 'Rate Push'
        break
      case ApiConnectionActivityTypes.ListingPush:
        typeText = 'Listing Push'
        break
    }
    return typeText
  }
}
