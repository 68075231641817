import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { ActionFailed } from './utility.actions'
import { tap } from 'rxjs/operators'
import { Toaster } from '../../services'

declare const window: any

@Injectable()
export class UtilityEffects {
  @Effect({ dispatch: false })
  actionFailed$ = this.actions$.pipe(
    ofType(ActionFailed),
    tap((action) => {
      // @ts-ignore
      const error = action.error || action.payload
      const role = localStorage.getItem('tv3:USER_ROLE')
      if (!action.skip401 && /READ-ONLY/.test(role) && error?.status == 401) {
        return
      }
      if (window.navigator.onLine) {
        this.toast.error('Something went wrong', null, error)
      } else {
        this.toast.error('Error', null, 'Please check your internet connection.')
      }
    })
  )

  constructor(private actions$: Actions, private toast: Toaster) {}
}
