<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-calendar-alt"></i> Export Calendar</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body" style="outline: none">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm-12" *ngIf="isChannelNameRequired">
        <mat-form-field2 class="mat-block">
          <input
            type="text"
            matInput
            placeholder="Channel Name"
            formControlName="channelName"
            maxlength="50"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let name of availableChannelNames$ | async" [value]="name">
              {{ name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('channelName').touched && form.get('channelName').hasError('required')">
            Channel name is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('channelName').touched && form.get('channelName').hasError('maxlength')">
            Channel name must be at most <strong>50</strong> characters long
          </mat-error>
          <mat-hint align="end">{{ form.get('channelName').value.length || 0 }}/50</mat-hint>
        </mat-form-field2>
      </div>

      <div class="col-sm-12" *ngIf="channels$">
        <app-select-search
          bindLabel="friendlyName"
          [inline]="false"
          [multiple]="false"
          [items]="channels$ | async"
          [ctrl]="form.get('channel')"
          placeholder="Channel"
        >
          <ng-container class="error-container">
            <mat-error> Channel is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
      </div>

      <div class="col-sm-12" *ngIf="rentals$">
        <app-select-search
          bindLabel="name"
          [inline]="false"
          [multiple]="false"
          [items]="rentals$ | async"
          [ctrl]="form.get('rental')"
          placeholder="Rental"
        >
          <ng-container class="error-container">
            <mat-error> Rental is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
      </div>
      <div class="col-sm-12">
        <div class="alert alert-secondary-info">
          <i class="fal fa-info-circle alert-icon"></i>
          <div class="alert-content">
            Please select the AdvanceCM rental whose iCal URL you would like to export. You can undo this setting later
            if you wish.
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="create(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="fal fa-check"></i> Export
  </button>
</div>
