import { Rental } from '@tokeet-frontend/tv3-platform'
import {
  GvrDisplayNameDisapprovalReason,
  GvrIconDisapprovalReason,
  getListingDisplayNameRejectionReasonText,
  getListingIconRejectionReasonText,
} from './reject'
import * as lodash from 'lodash'

export type GVRStatus = { connected: boolean }

export enum GVRListingStatus {
  LIVE_ON_GOOGLE = 'LIVE_ON_GOOGLE',
  INACTIVE = 'INACTIVE',
  IN_QUEUE = 'IN_QUEUE',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED',
}

export interface GVRListingModel {
  rentalId: string
  cancellationPolicyType: 'No Refund' | 'Strict' | 'Firm' | 'Moderate' | 'Relaxed'
  websiteId: string
  websiteDomainId: string
  websiteProvider: string
  displayNameState?: GVRListingState
  displayNameDisapprovalReason: GvrDisplayNameDisapprovalReason[]
  iconState?: GVRListingState
  iconDisapprovalReasons?: GvrIconDisapprovalReason[]
  updatedAt: number
  publishedAt: number
  createdAt: number
  status: GVRListingStatus // https://tokeet.atlassian.net/browse/TV3-5917

  active: boolean
}

export interface GVRListingViewModel extends GVRListingModel {
  rental: Rental
  rental_name: string
  displayNameRejectionReasons: string
  iconRejectionReasons: string
  displayNameStateText: string
  iconStateText: string
}

export interface GVRListingPayload {
  rentalId: string
  cancellationPolicyType: 'No Refund' | 'Strict' | 'Firm' | 'Moderate' | 'Relaxed'
  websiteId: string
  websiteProvider: string
  websiteDomainId?: string
  websiteDisplayName?: string
}

export enum GVRListingState {
  REVIEW_STATE_UNSPECIFIED = 'REVIEW_STATE_UNSPECIFIED',
  REVIEW_STATE_NEW = 'REVIEW_STATE_NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export function getGVRListingState(state: GVRListingState) {
  if (state === GVRListingState.REVIEW_STATE_NEW) {
    return 'In Review'
  }
  if (state === GVRListingState.APPROVED) {
    return 'Approved'
  }
  if (state === GVRListingState.REJECTED) {
    return 'Rejected'
  }

  return ''
}

export function getGVRListingStatusTooltip(status: GVRListingStatus) {
  switch (status) {
    case GVRListingStatus.LIVE_ON_GOOGLE:
      return 'The listing is Live on Google and the property link is available'
    case GVRListingStatus.INACTIVE:
      return 'The listing is disabled'
    case GVRListingStatus.IN_QUEUE:
      return 'The listing was recently created and not pulled by Google yet'
    case GVRListingStatus.IN_REVIEW:
      return 'The listing is pulled by Google and is being reviewed'
    case GVRListingStatus.REJECTED:
      return 'The listing is rejected due to data issues that need to be fixed'
  }
}

export function getGVRListingIconRejectionReasons(state: GVRListingState, reasons: GvrIconDisapprovalReason[]) {
  if (state !== GVRListingState.REJECTED) return null

  const items = lodash.compact(
    lodash.map(reasons, (reason) => {
      if (!reason) return null
      return getListingIconRejectionReasonText(reason)
    })
  )

  return items.length ? items : null
}

export function getGVRListingDisplayNameRejectionReasons(
  state: GVRListingState,
  reasons: GvrDisplayNameDisapprovalReason[]
) {
  if (state !== GVRListingState.REJECTED) return null

  const items = lodash.compact(
    lodash.map(reasons, (reason) => {
      if (!reason || !reason.disapprovalReason) return null
      return getListingDisplayNameRejectionReasonText(reason.disapprovalReason)
    })
  )

  return items.length ? items : null
}
