<mat-select
  #matSelect
  [disabled]="disabled"
  [placeholder]="placeholder"
  [required]="required"
  [compareWith]="compareWith"
  [sortComparator]="sortComparator"
  [disableOptionCentering]="disableOptionCentering"
  [disableRipple]="disableRipple"
  [errorStateMatcher]="errorStateMatcher"
  [panelClass]="getPanelClass | call: panelClass"
  (openedChange)="onOpenedChange($event)"
  (keydown)="handleSelectKeydown($event)"
  value="dummy"
>
  <mat-select-trigger>
    <ng-container
      *ngTemplateOutlet="
        selectLabelDirective?.template || tmplDefaultLabel;
        context: getTriggerContext | call: valueMap
      "
    ></ng-container>
  </mat-select-trigger>

  <!-- mat-select only shows dropdown in at least 1 mat-option, so add a dummy one here -->
  <mat-option value="dummy" style="display: none"></mat-option>

  <div class="{{ controlType }}-search-box" *ngIf="searchable">
    <mat-pseudo-checkbox
      ngClass="{{ controlType }}-master-checkbox {{ controlType }}-item-checkbox"
      *ngIf="multiple"
      [state]="getMasterChecked | call: items:valueMap"
      (click)="toggleMasterCheckbox()"
    >
    </mat-pseudo-checkbox>

    <input
      #searchInput
      class="{{ controlType }}-input"
      [formControl]="searchControl"
      [placeholder]="searchPlaceholder"
      (keydown)="handleInputKeydown($event)"
    />
    <div class="{{ controlType }}-buttons">
      <button
        ngClass="{{ controlType }}-button {{ controlType }}-button-clear"
        *ngIf="searchControl.value"
        (click)="clearSearch(); searchInput.focus()"
      >
        <i class="far fa-times"></i>
      </button>

      <button
        ngClass="{{ controlType }}-button {{ controlType }}-button-close"
        *ngIf="showCloseButton"
        (click)="matSelect.close()"
      >
        Close
      </button>

      <ng-content select="[select-top-buttons]"></ng-content>
    </div>
  </div>

  <input readonly class="{{ controlType }}-focus-trap" #focusTrap (keydown)="handleItemKeydown($event)" />

  <ng-content select="[select-top-content]"></ng-content>

  <div
    *ngIf="
      {
        filteredItems: filteredItems$ | async,
        focusedItemIndex: focusedItemIndex$ | async
      };
      let data
    "
    class="{{ controlType }}-items"
    [ngStyle]="{ 'height.px': (getScrollHeight | call: data.filteredItems.length) }"
  >
    <div
      *ngIf="widestItem$ | async; let widestItem"
      class="{{ controlType }}-item mat-option {{ controlType }}-widest-item-placeholder"
      [ngClass]="{ 'mat-option-multiple': multiple }"
    >
      <mat-pseudo-checkbox ngClass="{{ controlType }}-item-checkbox" *ngIf="multiple"></mat-pseudo-checkbox>

      <div class="{{ controlType }}-item-content">{{ getLabel | call: widestItem }}</div>
    </div>

    <div *ngIf="!data.filteredItems.length" class="mat-option mat-option-disabled">
      {{ items?.length ? textNoMatch : textNoItems }}
    </div>

    <cdk-virtual-scroll-viewport
      #scroller
      *ngIf="data.filteredItems.length"
      class="{{ controlType }}-scroller"
      [itemSize]="itemHeight"
      orientation="vertical"
    >
      <div
        #elmItem
        class="{{ controlType }}-item mat-option"
        [ngClass]="{
          'mat-option-multiple': multiple,
          'mat-selected': (getItemChecked | call: item:valueMap) === 'checked',
          'mat-active': index === data.focusedItemIndex
        }"
        [ngStyle]="{ 'height.px': itemHeight }"
        *cdkVirtualFor="
          let item of data.filteredItems;
          let index = index;
          let first = first;
          let last = last;
          let even = even;
          let odd = odd
        "
        (click)="$event.stopPropagation(); focusTrap.focus(); onItemClick(item)"
      >
        <mat-pseudo-checkbox
          ngClass="{{ controlType }}-item-checkbox"
          *ngIf="multiple"
          [state]="getItemChecked | call: item:valueMap"
        >
        </mat-pseudo-checkbox>

        <div class="{{ controlType }}-item-content" *ngIf="item === blankValue && !multiple">
          <div class="{{ controlType }}-item-content-inner">
            {{ blankLabel }}
          </div>
        </div>

        <div class="{{ controlType }}-item-content" *ngIf="item">
          <div class="{{ controlType }}-item-content-inner">
            <ng-container
              *ngTemplateOutlet="
                selectItemDirective?.template || tmplDefaultItem;
                context: {
                  $implicit: item,
                  item: item,
                  label: (getLabel | call: item),
                  index: index,
                  first: first,
                  last: last,
                  even: even,
                  odd: odd
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <ng-content select="[select-bottom-content]"></ng-content>

  <div
    ngClass="{{ controlType }}-action-button"
    *ngIf="selectActionButtonDirective?.template"
    (click)="matSelect.close()"
  >
    <ng-container *ngTemplateOutlet="selectActionButtonDirective?.template"></ng-container>
  </div>
</mat-select>

<ng-template #tmplDefaultItem let-label="label">
  <div ngClass="{{ controlType }}-item-default-label" [title]="label">{{ label }}&nbsp;</div>
</ng-template>

<ng-template #tmplDefaultLabel let-items="items">
  {{ getTriggerValue | call: items }}
</ng-template>
