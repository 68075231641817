<mat-form-field2 class="search mat-block inline" [showLabel]="false">
  <input
    matInput
    type="text"
    [formControl]="searchCtrl"
    [matTooltip]="inputTooltip"
    matTooltipPosition="above"
    [placeholder]="inputPlaceholder"
    class="example-right-align"
  />

  <i matPrefix class="far fa-search"></i>
  <i
    matSuffix
    *ngIf="searchCtrl.value?.length > 0"
    (click)="$event.stopPropagation(); onClearSearch()"
    [matTooltip]="clearTooltip"
    matTooltipPosition="above"
    class="far fa-times text-danger clickable"
  ></i>
</mat-form-field2>
