<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 w-100">
        <div class="modal-title d-flex gap-2">
          Discount Code <app-page-help articleId="-" videoId="-"></app-page-help>
        </div>
        <div class="flex-fill"></div>
        <button type="button" class="btn btn-secondary-info" [disabled]="1 | isReadonlyRole" (click)="onSave()">
          <span class="cs-icon cs-icon-save" aria-hidden="true"></span> Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close" data-dismiss="modal" aria-label="Close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li [class.active]="activeTab === tabs.DiscountCode">
        <a (click)="activeTab = tabs.DiscountCode"> Details </a>
      </li>
      <li *ngIf="data.item?.id" [class.active]="activeTab === tabs.Activities">
        <a (click)="activeTab = tabs.Activities"> Activity </a>
      </li>
      <li *ngIf="data.item?.id" [class.active]="activeTab === tabs.Logs">
        <a (click)="activeTab = tabs.Logs"> Logs </a>
      </li>
    </ul>
    <div class="modal-body p-3 bg-none">
      <app-discount-code-detail
        #details
        [item]="data.item"
        *ngIf="activeTab === tabs.DiscountCode"
      ></app-discount-code-detail>
      <app-discount-code-bookings [item]="data.item" *ngIf="activeTab === tabs.Activities"></app-discount-code-bookings>

      <app-discount-code-logs [item]="data.item" *ngIf="activeTab === tabs.Logs"></app-discount-code-logs>
    </div>
  </div>
</div>
