import { deserialize, Expose, Serializable, ChannelTypes, Channel } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'

export enum ConnectionTypes {
  Api = 'api',
  ImportedICal = 'imported',
  ExportedICal = 'exported',
}

export type HomeToGoStatus = 'authorized' | 'pending' | 'not-authorized'

export const defaultUndefinedRoomId = '....'

export class Connection extends Serializable<Connection> {
  @Expose({ name: 'id' })
  _id: string
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'channel_rental_id' })
  channelRentalId: string

  @Expose({ name: 'ref_id' })
  refId: string

  @Expose({ name: 'rental_name' })
  rentalName: string

  @Expose({ name: 'channel_id' })
  channelId: string

  @Expose({ name: 'con_type' })
  conType: string

  @Expose({ name: 'status_changed' })
  statusChanged: number

  status_text: string
  sendto: string
  skipped: number
  archived: number
  type: ConnectionTypes
  legacyId: string
  created: number
  lastsync: number
  lastavailpull: number
  lastavailpush: number
  lastratepush: number
  exported: number
  agent: string
  name: string
  source: string
  account: number
  url: string
  imported: number
  propertyId: string | number
  propertyLink?: string
  roomId: string | number
  status: number
  roomName: string
  propertyName: string
  linkDate: number
  attributes: any

  airbnbStatus?: string

  static deserialize(data: any): Connection {
    data.type =
      (R.hasIn('imported', data) && ConnectionTypes.ImportedICal) ||
      (R.hasIn('propertyId', data) && ConnectionTypes.Api) ||
      ConnectionTypes.ExportedICal
    data.pkey = getChannelConnectionId(data) // for api connections
    const item = deserialize<Connection>(Connection, data)
    item.channelId = item.channelId || item.channelRentalId?.replace(`-${item.rentalId}`, '')
    return item
  }
}

export function getRealChannelId(connectionChannelId: string) {
  if (!connectionChannelId) return undefined
  return (connectionChannelId + '').split('.')[0]
}

export function getChannelConnectionId(conn: any): string {
  return conn.pkey
    ? conn.pkey
    : `${conn.propertyId || conn.property || conn.channel_id}-${conn.roomId || conn.room || conn.rental_id}`
}

export enum ApiConnectionActivityTypes {
  AvailabilityPull = 'availpull',
  AvailabilityPush = 'availpush',
  RatesPush = 'ratespush',
  ListingPush = 'listingpush',
}

export class ApiConnectionActivityLog extends Serializable<ApiConnectionActivityLog> {
  account: number
  channel: string
  created: number
  message: string
  @Expose({ name: 'pkey' })
  id: string
  property: number | string
  request: string
  response: string
  room: number | string
  status: string
  ttl: number
  type: ApiConnectionActivityTypes

  static deserialize(data: any): ApiConnectionActivityLog {
    const item = deserialize<ApiConnectionActivityLog>(ApiConnectionActivityLog, data)
    item.type = normalizeLogType(item.type)
    item.status = item.status === 'failure' ? 'error' : item.status
    return item
  }
}

function normalizeLogType(type: string): ApiConnectionActivityTypes {
  switch (type) {
    case ApiConnectionActivityTypes.AvailabilityPull:
      return type
    case ApiConnectionActivityTypes.RatesPush:
    case 'ratepull':
    case 'ratepush':
      return ApiConnectionActivityTypes.RatesPush
    case ApiConnectionActivityTypes.AvailabilityPush:
    case 'availabilitypull':
      return ApiConnectionActivityTypes.AvailabilityPush
    case 'listingpull':
    case 'update-listing':
    case 'update-amenities':
    case 'update-policies':
    case 'update-fees-and-taxes':
    case 'update-media':
    case 'update-rooms':
      return ApiConnectionActivityTypes.ListingPush
    default:
      return type as ApiConnectionActivityTypes
  }
}

export function getChannelType(connectionType: ConnectionTypes) {
  switch (connectionType) {
    case ConnectionTypes.ExportedICal:
    case ConnectionTypes.ImportedICal:
      return ChannelTypes.ICal
    case ConnectionTypes.Api:
      return ChannelTypes.Auto
    default:
      return ''
  }
}

export function isChannelConnection(channel: Channel, conn: Connection) {
  const channelName = R.toLower(channel.name + '')
  const channelFriendlyName = R.toLower(channel.friendlyName + '')
  const connChannelType = getChannelType(conn.type)

  if (channel.id === getRealChannelId(conn.channelId)) return true
  if (!channel.type?.includes(connChannelType)) return false
  if (channelName === R.toLower(conn.source + '')) return true
  if (channelName === R.toLower(conn.name + '')) return true
  if (channelFriendlyName.includes(R.toLower(conn.source + ''))) return true

  return false
}

export function findConnectionChannel(conn: Connection, channels: Channel[]) {
  return R.find((c: Channel) => isChannelConnection(c, conn))(channels)
}

export class ImportedConnectionActivityLog extends Serializable<ImportedConnectionActivityLog> {
  @Expose({ name: 'pkey' })
  id: string

  account: number
  created: number
  message: string
  status: string
  ttl: number
  url: string

  expires: number

  static deserialize(data: any): ImportedConnectionActivityLog {
    data.expires = data.created + 7 * 24 * 3600 //  7 days after the created timestamp
    return deserialize(ImportedConnectionActivityLog, data)
  }
}
