import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EntityTagsResponse } from './model'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { map } from 'rxjs/operators'

@Injectable()
export class EntityTagsService {
  constructor(private http: HttpClient) {}

  getAllTags() {
    return this.http
      .get<EntityTagsResponse[]>(`@api/tag/all/`)
      .pipe(map((items) => items.filter((t) => !!t.entity_type)))
  }

  addTags(type: DataEntityType, id: string, tags: string[]) {
    return this.http.post<EntityTagsResponse>(`@api/tag/${type}/${id}/`, { tags })
  }

  updateTags(type: DataEntityType, id: string, tags: string[]) {
    return this.http.put<EntityTagsResponse>(`@api/tag/${type}/${id}/`, { tags })
  }

  getTags(type: DataEntityType, id: string) {
    return this.http.get<EntityTagsResponse>(`@api/tag/${type}/${id}/`)
  }
}
