<div
  ng2FileDrop
  [uploader]="uploader"
  class="d-flex align-items-center gap-3 bg-default2 p-3 upload-box position-relative"
>
  <img [src]="image ? image : '/assets/images/upload-files.svg'" alt="" [style.height]="imageHeight" />
  <div>
    <h5 class="text-lg text-dark">
      Drop files here or
      <a href="javascript:;" (click)="fileInput.click()"> Browse </a>
      to upload
      <input
        type="file"
        [accept]="acceptFileExtensions"
        #fileInput
        ng2FileSelect
        [uploader]="uploader"
        multiple
        class="d-none"
      />
    </h5>
    <div [innerHTML]="getGuideText()" class="text-sm text-light"></div>
  </div>
  <div
    *ngIf="uploader?.isUploading"
    class="position-absolute d-flex align-items-center justify-content-center rounded bg-secondary"
    style="opacity: 0.9; top: 0; bottom: 0; left: 0; right: 0"
  >
    <ngb-progressbar type="warning" [value]="uploader?.progress" [striped]="true" [animated]="true" style="width: 90%"
      ><i>Uploading</i></ngb-progressbar
    >
  </div>
</div>

<div class="table-responsive" *ngIf="files.length && showFileList">
  <table class="table table-condensed" aria-hidden="false">
    <thead>
      <tr>
        <th class="text-sm text-semi-bold border-top-0">Name</th>
        <th class="text-sm text-semi-bold border-top-0">Size</th>
        <th class="text-sm text-semi-bold border-top-0">Progress</th>
        <th class="text-sm text-semi-bold border-top-0">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of files; let i = index">
        <td>{{ file.file.name }}</td>
        <td>{{ file.file.size | fileSize }}</td>
        <td class="align-middle">
          <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
        </td>
        <td>
          <a
            (click)="!file.isUploading && onRemoveFile(file, i)"
            class="td-btn text-danger"
            [ngClass]="{ disabled: file.isUploading }"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
