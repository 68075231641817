import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { ForwardService } from '@tv3/store/forward/forward.service'
import {
  AddForwards,
  AddForwardsComplete,
  DeleteForward,
  DeleteForwardComplete,
  DeleteForwards,
  DeleteForwardsComplete,
  LoadForwards,
  LoadForwardsComplete,
} from './forward.actions'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import * as R from 'ramda'
import { ForwardRequest } from '@tv3/interfaces/requests/forward.request'

@Injectable()
export class ForwardEffects {
  @Effect()
  loadForwards$ = this.actions$.pipe(
    ofType(LoadForwards),
    switchMap(() =>
      this.forwards.all().pipe(
        map((forwards) => LoadForwardsComplete({ forwards })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addForward$ = this.actions$.pipe(
    ofType(AddForwards),
    map(({ form }) =>
      R.map((rentalId) => {
        return {
          forward: form.forward,
          rental_id: rentalId,
        } as ForwardRequest
      }, form.rentals)
    ),
    switchMap((requests) =>
      this.forwards.create(requests).pipe(
        map((forwards) => AddForwardsComplete({ forwards })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteForward$ = this.actions$.pipe(
    ofType(DeleteForward),
    concatMap(({ forward }) =>
      this.forwards.remove(forward).pipe(
        map(() => DeleteForwardComplete({ id: forward.id })),
        tap(() => this.toast.success('Email forwarding deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteForwards$ = this.actions$.pipe(
    ofType(DeleteForwards),
    concatMap(({ forwards }) =>
      this.forwards.removeMultiple(forwards).pipe(
        map(() => DeleteForwardsComplete({ ids: forwards.map((f) => f.id) })),
        tap(() => this.toast.success('Selected email forwarding(s) deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private forwards: ForwardService, private toast: Toaster) {}
}
