import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import {
  AgodaContentProductCancelPolicy,
  AgodaContentProperty,
  AgodaContentPropertyProducts,
  CodeNameType,
} from '@tv3/store/channel-content/models/agoda.model'
import {
  AgodaContentProductPayload,
  AgodaContentPropertyPayload,
  AgodaContentRatePayload,
  AgodaContentRoomPayload,
} from '@tv3/store/channel-content/payload/agoda.request'
import {
  AgodaContentPropertyResponse,
  AgodaContentRateResponse,
  AgodaContentRoomResponse,
} from '@tv3/store/channel-content/payload/agoda.response'

@Injectable({
  providedIn: 'root',
})
export class AgodaContentService {
  metadataCache: { [key: string]: any } = {}

  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {}

  get(propertyId: any): Observable<AgodaContentProperty> {
    const url = `@api/agoda/content/property/${propertyId}/details`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  create(payload: AgodaContentPropertyPayload): Observable<AgodaContentPropertyResponse> {
    const url = `@api/agoda/content/property`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  update(id: number | string, payload: AgodaContentPropertyPayload): Observable<AgodaContentPropertyResponse> {
    const url = `@api/agoda/content/property/${id}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  createRoom(propertyId: any, payload: AgodaContentRoomPayload): Observable<AgodaContentRoomResponse> {
    const url = `@api/agoda/content/property/${propertyId}/room`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  updateRoom(propertyId: any, roomId: any, payload: AgodaContentRoomPayload): Observable<AgodaContentRoomResponse> {
    const url = `@api/agoda/content/property/${propertyId}/room/${roomId}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  createRate(propertyId: any, payload: AgodaContentRatePayload): Observable<AgodaContentRateResponse> {
    const url = `@api/agoda/content/property/${propertyId}/rate`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  updateRate(propertyId: any, rateId: any, payload: AgodaContentRatePayload): Observable<AgodaContentRateResponse> {
    const url = `@api/agoda/content/property/${propertyId}/rate/${rateId}`
    return this.http.put<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  setProduct(payload: AgodaContentProductPayload): Observable<any> {
    const url = `@api/agoda/content/product`
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  getProducts(propertyId: any): Observable<AgodaContentPropertyProducts> {
    const url = `@api/agoda/content/property/${propertyId}/products`
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  close(propertyId: any): Observable<any> {
    const url = `@api/agoda/content/property/${propertyId}/status`
    return this.http.put<any>(url, { status: 'Closed' })
  }

  getBedTypes(): Observable<CodeNameType[]> {
    const cacheKey = 'BedTypes'
    if (this.metadataCache[cacheKey]) {
      return of(this.metadataCache[cacheKey])
    }
    const url = `@api/agoda/content/metadata/bed-types`
    return this.http.get<any>(url).pipe(tap((data) => (this.metadataCache[cacheKey] = data)))
  }

  getRoomAmenities(): Observable<CodeNameType[]> {
    const cacheKey = 'RoomAmenities'
    if (this.metadataCache[cacheKey]) {
      return of(this.metadataCache[cacheKey])
    }
    const url = `@api/agoda/content/metadata/room-amenities`
    return this.http.get<any>(url).pipe(tap((data) => (this.metadataCache[cacheKey] = data)))
  }

  getCancelPolicies(): Observable<AgodaContentProductCancelPolicy[]> {
    const cacheKey = 'cancelPolicies'
    if (this.metadataCache[cacheKey]) {
      return of(this.metadataCache[cacheKey])
    }
    const url = `@api/agoda/content/metadata/cancel-policies`
    return this.http.get<any>(url).pipe(
      map((data) => data.map((item) => ({ ...item, tooltip: item.description }))),
      tap((data) => (this.metadataCache[cacheKey] = data))
    )
  }
}
