<div [@state]="_visibility" (@state.done)="_animationDone($event)" class="popover-container popover-booking">
  <div class="popover-header d-flex align-items-center">
    <a
      href="javascript:;"
      role="button"
      class="btn-close"
      (click)="onClose()"
      matTooltip="Close overlay"
      matTooltipPosition="below"
    >
      <i class="far fa-times"></i>
    </a>

    <div class="w-50 flex-fill">
      <div>
        <ng-template #guestName>
          <div style="font-size: 22px; font-weight: 600" class="d-flex align-items-center gap-2 text-info">
            <app-avatar [text]="inquiry.guestNameView" [size]="24"></app-avatar>
            <div class="text-truncate">
              <div class="text-truncate">
                {{ inquiry.guestNameView }}
                <i
                  class="cs-icon cs-icon-arrow-top-right external-circle-icon"
                  matTooltip="View guest details"
                  matTooltipPosition="below"
                ></i>
              </div>
            </div>
            <img
              [src]="inquiry.inquirySource | inquirySourceIcon"
              alt=""
              style="height: 20px"
              [matTooltip]="inquiry.sourceView"
              matTooltipPosition="below"
            />
            <app-booking-paid-status
              [inquiry]="inquiry"
              [remaining]="false"
              class="text-sm"
              (click)="$event.stopPropagation(); onChangePaymentStatus(paidStatus.status.status)"
              #paidStatus
            ></app-booking-paid-status>
          </div>
        </ng-template>
        <a href="javascript:;" (click)="onEditGuest()" *ngxPermissionsOnly="['canEditGuest']">
          <ng-template [ngTemplateOutlet]="guestName"></ng-template>
        </a>
        <ng-template [ngTemplateOutlet]="guestName" *ngxPermissionsExcept="['canEditGuest']"></ng-template>
      </div>
      <div>
        <ng-template #rentalName>
          <div style="font-size: 1rem; font-weight: 500" class="d-flex align-items-center gap-2 text-info">
            <app-rental-image [rental]="rental" [size]="16" class="d-flex mx-1"></app-rental-image>
            <div class="text-truncate">
              <div class="text-truncate">
                {{ rental?.name }}
                <i
                  class="cs-icon cs-icon-arrow-top-right external-circle-icon"
                  matTooltip="View rental details"
                  matTooltipPosition="below"
                ></i>
              </div>
            </div>
          </div>
        </ng-template>
        <a href="javascript:;" (click)="onOpenRental()" *ngxPermissionsOnly="['canEditRental']">
          <ng-template [ngTemplateOutlet]="rentalName"></ng-template>
        </a>
        <ng-template [ngTemplateOutlet]="rentalName" *ngxPermissionsExcept="['canEditRental']"></ng-template>
      </div>
    </div>
    <div class="flex-fill"></div>
    <div class="d-flex align-items-center gap-3">
      <button
        *ngxPermissionsOnly="['canEditInquiry']"
        type="button"
        class="btn btn-secondary-primary"
        (click)="onOpenMessages()"
        matTooltip="Messages"
        matTooltipPosition="above"
      >
        <i class="cs-icon cs-icon-chat-square"></i>
      </button>

      <button
        *ngxPermissionsOnly="['canEditNotes']"
        type="button"
        class="btn btn-secondary-primary"
        (click)="onOpenNotes()"
        matTooltip="Notes"
        matTooltipPosition="above"
      >
        <i class="cs-icon cs-icon-note"></i>
      </button>
      <button
        *ngxPermissionsOnly="['canAddInquiry']"
        type="button"
        class="btn btn-secondary-primary"
        (click)="onClone()"
        matTooltip="Click here to clone this booking"
        matTooltipPosition="above"
      >
        <i class="cs-icon cs-icon-copy"></i>
      </button>

      <button
        *ngxPermissionsOnly="['canEditInquiry']"
        type="button"
        class="btn btn-secondary-primary"
        (click)="onOpenInquiry()"
        matTooltip="Edit"
        matTooltipPosition="above"
      >
        <i class="cs-icon cs-icon-pencil"></i>
      </button>
    </div>
  </div>
  <div class="popover-body">
    <div class="d-flex gap-3">
      <div class="flex-fill w-50">
        <app-overlay-booking-details
          [readonly]="true"
          [showTimeZoneSource]="false"
          [inquiry]="inquiry"
        ></app-overlay-booking-details>
      </div>
      <div class="flex-fill w-50">
        <ng-container *ngIf="canSeeCharges$ | async">
          <h6 class="text-uppercase">Booking Charges</h6>
          <app-overlay-booking-charges
            direction="v"
            [readonly]="true"
            [inquiry]="inquiry"
            class="bg-default p-3 rounded"
          ></app-overlay-booking-charges>
        </ng-container>
      </div>
    </div>
    <div class="d-flex mt-3 gap-3">
      <div class="flex-fill w-50">
        <div *ngxPermissionsOnly="['canSeeGuestContacts']">
          <h6 class="text-uppercase">Guest Details</h6>
        </div>
      </div>
      <div class="flex-fill w-50">
        <div class="colors-container" *ngxPermissionsOnly="['canEditInquiry']">
          <h6 class="text-uppercase">Select Booking Color</h6>
        </div>
      </div>
    </div>
    <div class="d-flex gap-3">
      <div class="flex-fill w-50" *ngxPermissionsOnly="['canSeeGuestContacts']">
        <ul class="bg-default p-3 rounded list-reset d-flex flex-column gap-2 h-100">
          <li *ngIf="inquiry.guest?.name" class="d-flex align-items-center text-sm gap-2">
            <i class="cs-icon cs-icon-user text-info text-md"></i>
            {{ inquiry.guest?.name }}
            <app-copy-icon [content]="inquiry.guest?.name" class="text-light text-lg"></app-copy-icon>
          </li>

          <li
            *ngIf="inquiry.guest?.phone && (currentUser | staffPermissions : 'phone')"
            class="d-flex align-items-center text-sm gap-2"
          >
            <i class="cs-icon cs-icon-call text-success text-xl"></i>
            {{ inquiry.guest?.phone }}
            <app-copy-icon [content]="inquiry.guest?.phone" class="text-light text-lg"></app-copy-icon>
          </li>
          <li
            *ngIf="inquiry.guest?.primaryEmail && (currentUser | staffPermissions : 'email')"
            class="d-flex align-items-center text-sm gap-2"
          >
            <i class="cs-icon cs-icon-envelope text-danger"></i>
            {{ inquiry.guest?.primaryEmail }}
            <app-copy-icon [content]="inquiry.guest?.primaryEmail" class="text-light text-lg"></app-copy-icon>
          </li>
        </ul>
      </div>
      <div class="flex-fill w-50" *ngxPermissionsOnly="['canEditInquiry']">
        <div class="bg-default p-3 rounded h-100">
          <app-color-boxes
            [color]="inquiry.attributes?.color"
            (change)="onChangeInquiryColor($event)"
            matTooltip="Change inquiry color"
            matTooltipPosition="above"
          ></app-color-boxes>
        </div>
      </div>
    </div>
  </div>
</div>
