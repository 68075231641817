import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { ChannelCommissionResponse } from '@tokeet-frontend/channels'
import { ChannelCommissionDialogComponent } from '@tv3/containers/channels/channel-detail/api/channel-commission-dialog/channel-commission-dialog.component'
import { ConnectedChannelAccountResponse, Channel } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class ChannelCommissionDialogService {
  constructor(private dialog: MatDialog) {}

  public open(channel: Channel, accounts: (string | number)[], commission?: ChannelCommissionResponse) {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: { commission, accounts, channel },
      panelClass: ['modal', 'variable-modal'],
    }

    return this.dialog.open(ChannelCommissionDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
