import {
  ConfirmDialogService,
  DataEntityType,
  Destroyable,
  isSomething,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { BehaviorSubject, Observable } from 'rxjs'
import {
  AddInvoiceTemplate,
  AddInvoiceTemplateComplete,
  DeleteInvoiceTemplate,
  DeleteInvoiceTemplateComplete,
  UpdateInvoiceTemplate,
  UpdateInvoiceTemplateComplete,
} from '../../store/templates/template.actions'
import {
  InvoiceTemplate,
  InvoiceTemplateCreatePayload,
  InvoiceTemplateLineItem,
  InvoiceTemplateTypes,
  InvoiceTemplateUpdatePayload,
} from '../../store/templates/template.model'
import { selectInvoiceTemplateRules } from '../../store/rules/rule.selectors'
import { InvoiceTemplateService } from '../../store/templates/template.service'
import { ChangeDetectorRef, OnInit, Directive } from '@angular/core'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { emojiDisabledValidator, validateMinNumber } from '@tokeet-frontend/tv3-platform'
import { MatDialogRef } from '@angular/material/dialog'
import { CustomBrandingService } from '@tokeet-frontend/templates'
import { TemplateSaveDialogService } from './interfaces'
import { IAuthService } from '@tokeet-frontend/auth'
import { ManageEntityTagsDialogParams, ManageEntityTagsDialogService } from '@tokeet-frontend/tags'

export enum InvoiceTemplateTab {
  EditTemplate,
  InvoicingRules,
  EmailTemplate,
}

@Directive()
export abstract class InvoiceTemplateOverlayAbstractComponent extends Destroyable implements OnInit {
  steps: {
    icon: string
    title: string
    next: InvoiceTemplateTab
    prev: InvoiceTemplateTab
    current: InvoiceTemplateTab
    tooltip?: string
    ready: boolean
    hidden: boolean
  }[] = []

  tabs = InvoiceTemplateTab

  step = new BehaviorSubject<InvoiceTemplateTab>(InvoiceTemplateTab.EditTemplate)

  isEditing = false
  isDuplicate = false

  form = this.fb.group({
    due_date: [null, [Validators.required]],
    payment_terms: ['', [emojiDisabledValidator]],
    payment_instructions: ['', [emojiDisabledValidator]],
    notes: ['', [emojiDisabledValidator]],
    invoice_items: this.fb.array([]),
    tags: [[]],
    email_template_id: [''],
    subject: ['', [Validators.maxLength(75)]],
    body: [''],
    address_key: [''],
    auto_fees_disabled: [false],
  })

  get invoice_items() {
    return this.form.get('invoice_items') as FormArray
  }

  isAssignedToRules$: Observable<boolean>
  product: 'tokeet' | 'sympl' = 'tokeet'

  constructor(
    protected fb: FormBuilder,
    public data: { type?: InvoiceTemplateTypes; template?: InvoiceTemplate; duplicate?: boolean },
    protected store: Store<any>,
    protected invoiceTemplateService: InvoiceTemplateService,
    protected actions: Actions,
    protected dialogRef: MatDialogRef<any>,
    protected ref: ChangeDetectorRef,
    protected customBrandingService: CustomBrandingService,
    protected invoiceManageTagsDialogService: ManageEntityTagsDialogService,
    protected invoiceTemplateSaveDialogService: TemplateSaveDialogService,
    protected auth: IAuthService,
    protected confirmDialogService: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.isDuplicate = !!this.data.duplicate
    this.isEditing = !!this.data.template
    if (this.isEditing) {
      this.form.patchValue(this.data.template)

      R.times((index) => {
        this.addInvoiceItem()
        const invoiceItem = this.data.template.invoice_items[index]
        this.invoice_items.at(index).patchValue(invoiceItem)
        const taxes = this.invoice_items.at(index).get('taxes') as FormArray
        R.times((i) => {
          taxes.push(
            this.fb.group({
              amount: [''],
              type: [''],
              name: [''],
            })
          )
          taxes.at(i).patchValue(invoiceItem.taxes[i])
        }, invoiceItem.taxes.length)
        this.setValidators(invoiceItem, index)
      }, this.data.template.invoice_items.length)
      this.isAssignedToRules$ = this.store.pipe(
        select(selectInvoiceTemplateRules(this.data.template.pkey)),
        map((rules) => isSomething(rules))
      )
    } else {
      this.addInvoiceItem()
    }

    this.invoiceTemplateService.getTemplate(this.data.template, this.product).subscribe(({ subject, body }) => {
      this.form.patchValue({ subject, body })
    })

    this.steps = [
      {
        icon: 'far fa-edit',
        title: 'Invoice Template',
        next: this.isEditing ? InvoiceTemplateTab.InvoicingRules : InvoiceTemplateTab.EmailTemplate,
        prev: null,
        current: InvoiceTemplateTab.EditTemplate,
        ready: false,
        hidden: false,
      },
      {
        icon: 'far fa-tools',
        title: 'Invoicing Automation',
        next: InvoiceTemplateTab.EmailTemplate,
        prev: InvoiceTemplateTab.EditTemplate,
        current: InvoiceTemplateTab.InvoicingRules,
        ready: false,
        hidden: !this.isEditing,
      },
      {
        icon: 'far fa-comment-alt-lines',
        title: 'Email Template',
        tooltip: 'Edit the email message sent with this invoice template.',
        next: null,
        prev: this.isEditing ? InvoiceTemplateTab.InvoicingRules : InvoiceTemplateTab.EditTemplate,
        current: InvoiceTemplateTab.EmailTemplate,
        ready: false,
        hidden: false,
      },
    ]

    this.actions
      .pipe(
        ofType(AddInvoiceTemplateComplete.type, UpdateInvoiceTemplateComplete.type, DeleteInvoiceTemplateComplete.type),
        untilDestroy(this)
      )
      .subscribe(({ silent }) => {
        if (!silent) {
          this.dialogRef.close()
        }
      })
  }

  addInvoiceItem() {
    this.invoice_items.push(
      this.fb.group({
        item: ['*|RENTAL:NAME|*', [Validators.required]],
        unitCost: [{ value: '', disabled: true }],
        qty: [{ value: '', disabled: true }],
        description: [''],
        percent: [1.0],
        type: [isSomething(this.data.type) ? this.data.type : InvoiceTemplateTypes.BookingInvoice],
        taxes: this.fb.array([]),
        cost: [{}],
      })
    )
    this.ref.detectChanges()
  }

  setValidators(invoiceItem: InvoiceTemplateLineItem, index: number) {
    const unitCostControl = this.invoice_items.at(index).get('unitCost')
    const qtyControl = this.invoice_items.at(index).get('qty')
    if (invoiceItem.type === InvoiceTemplateTypes.GeneralInvoice) {
      unitCostControl.setValidators([Validators.required, validateMinNumber(1)])
      qtyControl.setValidators([Validators.required, validateMinNumber(1)])
      unitCostControl.enable()
      qtyControl.enable()
    } else {
      unitCostControl.setValidators([])
      qtyControl.setValidators([])
      unitCostControl.disable()
      qtyControl.disable()
    }
  }

  onTemplateReset() {
    this.customBrandingService.getInvoiceMessageTemplate().subscribe(({ subject, body }) => {
      this.form.patchValue({ subject, body })
    })
  }

  onStepReady(isValid: boolean, tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    if (step) {
      step.ready = isValid
      if (isValid) {
        console.log(`Step ${step.current} is ready.`)
      }
    }
  }

  onOpenStep(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    if (step) {
      this.step.next(step.current)
    }
  }

  isStepReady(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    return step && step.ready
  }

  isLastStep(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    return step && step.current === InvoiceTemplateTab.EmailTemplate
  }

  hasPrevious(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    return step && !R.isNil(step.prev)
  }

  hasNext(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    return step && !R.isNil(step.next)
  }

  onPrevStep(tab: InvoiceTemplateTab) {
    const step = R.find((s) => s.current === tab, this.steps)
    if (step) {
      this.step.next(step.prev)
    }
  }

  onNextStep(tab: InvoiceTemplateTab) {
    if (this.isStepReady(tab)) {
      const step = R.find((s) => s.current === tab, this.steps)
      if (step) {
        this.step.next(step.next)
      }
    } else {
      this.form.markAllAsTouched()
    }
  }

  onTags() {
    const fieldTags = this.form.get('tags')
    const existingTags = fieldTags.value

    const title = 'Add Invoice Template Tags'
    const description =
      'This feature allows you to add arbitrary descriptors to this template which can then be used when filtering on various tables and reports. For example, you can filter by tags on the templates table so that you only see a subset of all your templates'

    const params: ManageEntityTagsDialogParams = {
      tags: existingTags,
      title,
      description,
      id: this.isEditing ? this.data.template.pkey : null,
      type: DataEntityType.InvoiceTemplate,
    }

    if (!this.isEditing) {
      params.onSubmit = (tags) => {
        fieldTags.setValue(tags)
      }
    }

    this.invoiceManageTagsDialogService.open(params)
  }

  onSave(options: { silent?: boolean } = {}) {
    if (!this.form.valid) {
      this.form.markAllAsTouched()
      return
    }
    const name = R.pathOr('', ['template', 'name'], this.data)
    const description = R.pathOr('', ['template', 'description'], this.data)
    if (options.silent) {
      const { tags, ...values } = this.form.getRawValue()
      let payload = this.convertFormToPayload(values, name, description)
      if (this.isEditing && !this.isDuplicate) {
        this.store.dispatch(
          UpdateInvoiceTemplate({
            template: payload as InvoiceTemplateUpdatePayload,
            silent: options.silent,
          })
        )
      } else {
        this.store.dispatch(AddInvoiceTemplate({ template: payload as InvoiceTemplateCreatePayload, tags }))
      }
    } else {
      this.invoiceTemplateSaveDialogService
        .open(name, description)
        .afterClosed()
        .subscribe((form: { name: string; description: string }) => {
          if (isSomething(form)) {
            const { name, description } = form
            const { tags, ...values } = this.form.getRawValue()

            let payload = this.convertFormToPayload(values, name, description)
            if (this.isEditing && !this.isDuplicate) {
              this.store.dispatch(
                UpdateInvoiceTemplate({
                  template: payload as InvoiceTemplateUpdatePayload,
                  silent: options.silent,
                })
              )
            } else {
              this.store.dispatch(AddInvoiceTemplate({ template: payload as InvoiceTemplateCreatePayload, tags }))
            }
          }
        })
    }
  }

  onDelete() {
    this.confirmDialogService
      .confirm({
        title: 'Delete this template?',
        body: 'Are you sure you want to delete this template?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteInvoiceTemplate({ id: this.data.template.pkey }))
      })
  }

  onClose() {
    this.dialogRef.close()
  }

  onTouched() {
    this.form.markAllAsTouched()
  }

  private convertFormToPayload(form, name, description) {
    const isBookingType = R.any(
      (i: InvoiceTemplateLineItem) => i.type === InvoiceTemplateTypes.BookingInvoice,
      form.invoice_items as InvoiceTemplateLineItem[]
    )
    let payload = {
      ...form,
      name,
      description,
      type: isBookingType ? InvoiceTemplateTypes.BookingInvoice : InvoiceTemplateTypes.GeneralInvoice,
    }
    if (this.isEditing) {
      payload = {
        ...payload,
        pkey: this.data.template.pkey,
      }
    }
    payload = {
      ...payload,
      account: this.auth.user.account,
    }
    return payload
  }
}
