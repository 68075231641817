import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed, ActionSkipped, selectOnce, Toaster } from '@tokeet-frontend/tv3-platform'
import { EntityTagsService } from './service'
import * as lodash from 'lodash'
import {
  AddEntityTags,
  AddEntityTagsComplete,
  GetEntityTags,
  GetEntityTagsComplete,
  LoadEntityTags,
  LoadEntityTagsComplete,
  SetEntityTags,
  UpdateEntityTags,
  UpdateEntityTagsComplete,
} from './actions'
import { Store } from '@ngrx/store'
import { selectTagsEntity } from './selectors'
import { EntityTagsResponse } from './model'

@Injectable()
export class EntityTagsEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadEntityTags),
    switchMap(() =>
      this.tagsService.getAllTags().pipe(
        map((items) => LoadEntityTagsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  get$ = this.actions$.pipe(
    ofType(GetEntityTags),
    switchMap(({ entityType, entityId }) =>
      this.tagsService.getTags(entityType, entityId).pipe(
        map((item) => GetEntityTagsComplete({ item })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(AddEntityTags),
    switchMap(({ entityType, entityId, tags, silent }) =>
      this.tagsService.addTags(entityType, entityId, tags).pipe(
        map((item) => AddEntityTagsComplete({ item })),
        tap(() => !silent && this.toaster.success('Tags updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateEntityTags),
    switchMap(({ entityType, entityId, tags, silent }) =>
      this.tagsService.updateTags(entityType, entityId, tags).pipe(
        map((item) => UpdateEntityTagsComplete({ item })),
        tap(() => !silent && this.toaster.success('Tags updated successfully')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  set$ = this.actions$.pipe(
    ofType(SetEntityTags),
    switchMap((data) =>
      this.store.pipe(
        selectOnce(selectTagsEntity(data.entityType, data.entityId)),
        map((e: EntityTagsResponse) => {
          if (
            !lodash.difference(e?.tags || [], data.tags).length &&
            !lodash.difference(data.tags, e?.tags || []).length
          ) {
            return ActionSkipped()
          }

          return e?.pkey ? UpdateEntityTags(data) : AddEntityTags(data)
        })
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private toaster: Toaster,
    private tagsService: EntityTagsService
  ) {}
}
