import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { NoteService } from '@tv3/store/note/note.service'
import {
  AddNote,
  AddNoteComplete,
  DeleteNote,
  DeleteNoteComplete,
  UpdateNote,
  UpdateNoteComplete,
} from '@tv3/store/note/note.actions'
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { NoteResponse } from '@tv3/store/note/note.model'

@Injectable()
export class NoteEffects {
  @Effect()
  addNote$ = this.actions$.pipe(
    ofType(AddNote),
    mergeMap(({ inquiry, form, silent }) =>
      this.noteService.create(inquiry.id, form, silent).pipe(
        map((note) => ({ id: inquiry.id, changes: { notes: R.append(note, inquiry.notes) } })),
        map((update) => AddNoteComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateNote$ = this.actions$.pipe(
    ofType(UpdateNote),
    concatMap(({ inquiry, form, note }) =>
      this.noteService.update(inquiry.id, note, form).pipe(
        map((note) => {
          let notes = R.filter((n: NoteResponse) => n.key !== note.key, inquiry.notes)

          return {
            id: inquiry.id,
            changes: {
              notes: R.append(note, notes),
            },
          }
        }),
        map((update) => UpdateNoteComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteNote$ = this.actions$.pipe(
    ofType(DeleteNote),
    concatMap(({ inquiry, note }) =>
      this.noteService.delete(inquiry.id, note).pipe(
        map((note) => {
          let notes = R.filter((n: NoteResponse) => n.key !== note.key, inquiry.notes)

          return {
            id: inquiry.id,
            changes: {
              notes: notes,
            },
          }
        }),
        map((update) => DeleteNoteComplete({ update })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private noteService: NoteService) {}
}
