import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './gateway-type.reducer'
import { GatewayTypeState } from './gateway-type.state'

export const paymentGatewayTypeState = createFeatureSelector<GatewayTypeState>('gateway-types')

export const selectAllPaymentGatewayTypes = createSelector(paymentGatewayTypeState, reducer.selectGatewayTypeAll)

export const selectPaymentGatewayTypesLoaded = createSelector(
  paymentGatewayTypeState,
  (s: GatewayTypeState) => s.isLoaded
)
