<div class="modal-header">
  <h5 class="modal-title"><i class="cs-icon cs-icon-upload"></i>&nbsp;&nbsp;Upload Files</h5>
  <button (click)="close.emit()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <app-upload-box
    [options]="options"
    image="/assets/images/upload-files.svg"
    (complete)="onUploaded($event)"
    #uploadBox
  ></app-upload-box>
</div>
<div class="modal-footer">
  <button (click)="switchToRecentFiles.emit()" type="button" class="btn btn-secondary-info create-btn">
    <i class="fal fa-list-ul"></i> Select
  </button>
</div>
