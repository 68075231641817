import { Pipe, PipeTransform } from '@angular/core'
import { User } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'isAllRestricted',
})
export class IsAllRestrictedPipe implements PipeTransform {
  transform(rentals: Rental[], user: User): boolean {
    return R.filter((r: Rental) => !R.contains(user.id, r.restrictedUsers || []), rentals || []).length === 0
  }
}
