<div class="modal-header">
  <h5 class="modal-title">Channel Commission</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12" *ngIf="!isEdit">
        <app-select-search [items]="data.accounts" [ctrl]="form.get('accounts')" placeholder="Account" [inline]="false">
        </app-select-search>
      </div>
      <div class="col-md-12">
        <mat-form-field2 class="d-block">
          <input matInput type="text" placeholder="Name" formControlName="name" required />
          <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div class="col-md-12">
        <mat-form-field2 class="d-block">
          <input matInput type="number" placeholder="Value %" formControlName="amount" required />
          <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
            value is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('max')">
            Max value is <strong>100</strong>
          </mat-error>
          <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('min')">
            Min value is <strong>0</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" [disabled]="isLoading" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Save
  </button>
</div>
