<div class="container-box">
  <div class="px-3 py-2 border-bottom">
    <div class="d-flex align-items-center gap-3">
      <div class="title">
        <img [src]="channel?.logoColor" [alt]="channel?.friendlyName" />
      </div>
      <div class="flex-fill"></div>
      <button
        type="button"
        class="btn btn-secondary-primary"
        *ngIf="connection?.isImported"
        (click)="onRefresh()"
        [disabled]="(isUpdating$ | async) || (1 | isReadonlyRole)"
      >
        <span class="far fa-sync" aria-hidden="true"></span>
      </button>

      <button
        type="button"
        class="btn btn-secondary-danger"
        (click)="onDelete()"
        [disabled]="(isUpdating$ | async) || (1 | isReadonlyRole)"
      >
        <span class="cs-icon cs-icon-trash" aria-hidden="true"></span>
        Delete
      </button>
    </div>
  </div>
  <div class="p-3">
    <div class="row">
      <div class="col-md-6">
        <div class="text-uppercase text-light mb-3">Channel Information</div>
        <div class="d-flex gap-2 mb-3">
          <i class="cs-icon cs-icon-rental2 text-success text-xxl mt-1"></i>
          <div>
            <div class="text-light">Linked Rental</div>
            <div>{{ rental?.name }}</div>
          </div>
        </div>
        <div class="d-flex align-items-center gap-5">
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-channels text-info text-xxl mt-1"></i>
            <div>
              <div class="text-light">Channel</div>
              <div>{{ channel?.friendlyName || connection?.source || 'Unknown' }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-grid-check text-warning text-xxl mt-1"></i>
            <div>
              <div class="text-light">Type</div>
              <div>{{ connection?.typeText }}</div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-calendar-plus text-indigo text-xxl mt-1"></i>
            <div>
              <div class="text-light">Date Linked</div>
              <div>{{ connection?.linkDateOrCreated | epoch : 'DD-MMM-YYYY • hh:mm A' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 border-left">
        <div class="text-uppercase text-light mb-3">Calendar Information</div>
        <div class="d-flex flex-column gap-3">
          <div class="d-flex gap-2">
            <i class="cs-icon cs-icon-link2 text-primary text-xxl fa-fw mt-1"></i>
            <div>
              <div class="text-light">URL</div>
              <div>
                {{ connection?.url }}
                <app-copy-icon [content]="connection?.url" class="text-xl ml-2"></app-copy-icon>
              </div>
            </div>
          </div>
          <div class="d-flex gap-2">
            <i class="far fa-sync text-primary text-xxl fa-fw mt-1"></i>
            <div>
              <div class="text-light">Last Activity</div>
              <div>{{ connection?.lastsync | epoch : 'datetime' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
