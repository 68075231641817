<eco-fab-speed-dial [(open)]="open" [direction]="direction" [animationMode]="animationMode">
  <eco-fab-speed-dial-trigger [spin]="spin">
    <button [matTooltip]="'Schedule Meeting'" matTooltipPosition="left" mat-fab class="trigger">
      <mat-icon>
        <i class="icon far fa-phone"></i>
      </mat-icon>
    </button>
  </eco-fab-speed-dial-trigger>

  <eco-fab-speed-dial-actions [ngClass]="{ moveBtns: !open }">
    <button
      [matTooltip]="'Schedule onboarding'"
      matTooltipPosition="left"
      mat-mini-fab
      (click)="goToOnboarding()"
      class="action"
    >
      <mat-icon>
        <i class="icon far fa-calendar-alt"></i>
      </mat-icon>
    </button>
    <button [matTooltip]="'Book a Demo'" matTooltipPosition="left" mat-mini-fab (click)="goToDemo()" class="action">
      <mat-icon>
        <i class="icon fal fa-id-card-alt"></i>
      </mat-icon>
    </button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
