import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import {
  CreateInvoiceRuleConditionPayload,
  InvoiceRule,
  InvoiceRuleConditionCreate,
  InvoiceRuleConditionCreateComplete,
  InvoiceRuleConditionDeleteRequest,
  InvoiceRuleConditionSave,
  InvoiceRuleConditionSaveComplete,
  UpdateInvoiceRulePayload,
} from '@tokeet-frontend/invoices'
import { CreateInvoiceRulePayload } from './rule.model'

export const LoadInvoiceRules = createAction('[InvoiceRules] Load Rules')
export const LoadInvoiceRulesComplete = createAction(
  '[InvoiceRules] Load Rules Complete',
  props<{ rules: InvoiceRule[] }>()
)

export const ArchiveInvoiceRule = createAction('[InvoiceRules] Archive', props<{ ids: string[] }>())
export const ArchiveInvoiceRuleComplete = createAction(
  '[InvoiceRules] Archive Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const UnArchiveInvoiceRule = createAction('[InvoiceRules] UnArchive', props<{ ids: string[] }>())
export const UnArchiveInvoiceRuleComplete = createAction(
  '[InvoiceRules] UnArchive Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const DeleteInvoiceRule = createAction('[InvoiceRules] Delete Rule', props<{ ids: string[] }>())
export const DeleteInvoiceRuleComplete = createAction('[InvoiceRules] Delete Rule Complete', props<{ ids: string[] }>())

export const DuplicateInvoiceRule = createAction(
  '[InvoiceRules] Duplicate Rule',
  props<{ payload: CreateInvoiceRulePayload }>()
)

export const AddInvoiceRule = createAction(
  '[InvoiceRules] Add Rule',
  props<{ payload: CreateInvoiceRulePayload; tags?: string[]; silent?: boolean }>()
)
export const AddInvoiceRuleComplete = createAction('[InvoiceRules] Add Rule Complete', props<{ rule: InvoiceRule }>())
export const AddInvoiceRulesComplete = createAction(
  '[InvoiceRules] Add Rules Complete',
  props<{ rules: InvoiceRule[] }>()
)

export const RecreateInvoiceRule = createAction(
  '[InvoiceRules] Recreate Rule',
  props<{ payload: CreateInvoiceRulePayload; ids: string[] }>()
)
export const UpdateInvoiceRule = createAction(
  '[InvoiceRules] Update Rule',
  props<{ items: { payload: UpdateInvoiceRulePayload; id: string }[] }>()
)
export const UpdateInvoiceRuleComplete = createAction(
  '[InvoiceRules] Update Rule Complete',
  props<{ updates: Update<InvoiceRule>[] }>()
)

export const DeleteInvoiceRules = createAction(
  '[InvoiceRules] Delete Rules',
  props<{ ids: string[]; silent?: boolean }>()
)
export const DeleteInvoiceRulesComplete = createAction(
  '[InvoiceRules] Delete Rules Complete',
  props<{ ids: string[] }>()
)

export const PauseInvoiceRule = createAction('[InvoiceRule] Pause', props<{ ids: string[] }>())
export const PauseInvoiceRuleComplete = createAction(
  '[InvoiceRule] Pause Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const ResumeInvoiceRule = createAction('[InvoiceRule] Resume', props<{ ids: string[] }>())
export const ResumeInvoiceRuleComplete = createAction(
  '[InvoiceRule] Resume Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const PauseInvoiceRuleReminder = createAction('[InvoiceRule] Pause Reminder', props<{ ids: string[] }>())
export const PauseInvoiceRuleReminderComplete = createAction(
  '[InvoiceRule] Pause Reminder Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const ResumeInvoiceRuleReminder = createAction(
  '[InvoiceRule] Resume Reminder',
  props<{ ids: string[]; when: 'time.before.invoice.due' | 'time.after.invoice.due'; days: number }>()
)
export const ResumeInvoiceRuleReminderComplete = createAction(
  '[InvoiceRule] Resume Reminder Complete',
  props<{ update: Update<InvoiceRule>[] }>()
)

export const SetInvoiceRuleConditions = createAction(
  '[InvoiceRule] Set Conditions Start',
  props<{ ids: string[]; items: CreateInvoiceRuleConditionPayload[] }>()
)
export const SetInvoiceRuleConditionsComplete = createAction(
  '[InvoiceRule] Set Conditions Complete',
  props<{ items: InvoiceRule[] }>()
)

export const UpdateInvoiceRuleCondition = createAction(
  '[InvoiceRule] Update Condition Start',
  props<{ request: InvoiceRuleConditionSave }>()
)
export const UpdateInvoiceRuleConditionComplete = createAction(
  '[InvoiceRule] Update Condition Complete',
  props<{ response: InvoiceRuleConditionSaveComplete }>()
)

export const AddInvoiceRuleCondition = createAction(
  '[InvoiceRule] Add Condition Start',
  props<{ request: InvoiceRuleConditionCreate }>()
)
export const AddInvoiceRuleConditionComplete = createAction(
  '[InvoiceRule] Add Condition Complete',
  props<{ response: InvoiceRuleConditionCreateComplete }>()
)

export const DeleteInvoiceRuleCondition = createAction(
  '[InvoiceRule] Delete Condition Start',
  props<{ request: InvoiceRuleConditionDeleteRequest }>()
)
export const DeleteInvoiceRuleConditionComplete = createAction(
  '[InvoiceRule] Delete Condition Complete',
  props<{ response: InvoiceRuleConditionDeleteRequest }>()
)
