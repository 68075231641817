<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-credit-card"></i> Buy Credit to Subscribe</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row">
    <div class="col-sm-12">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Credit Amount" formControlName="amount" required />
        <mat-error *ngIf="form.get('amount').touched && form.get('amount').hasError('required')">
          Amount is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('amount').hasError('min')">
          Amount need to be at least <strong>5</strong>
        </mat-error>
        <mat-error *ngIf="form.get('amount').hasError('max')">
          Amount need to be at most <strong>300</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-12">
      You can choose one option and click on Submit button which will make your subscription successful.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> Submit
  </button>
</div>
