import { Component, Inject, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import { CustomChannelDialogComponent } from '@tv3/containers/channels/shared/custom-channel-dialog/custom-channel-dialog.component'
import { ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { Connection } from '@tv3/store/connection/connection.model'
import { combineLatest } from 'rxjs'
import {
  AirbnbChannelService,
  AirbnbDeactivationReasonFlowResponse,
  detailRequiredReasonIds,
} from '@tokeet-frontend/channels'
import { finalize } from 'rxjs/operators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActionFailed, Destroyable, SaveForm, Toaster, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-airbnb-set-visibility-dialog',
  templateUrl: './airbnb-set-visibility-dialog.component.html',
  styleUrls: ['./airbnb-set-visibility-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class AirbnbSetVisibilityDialogComponent extends Destroyable implements OnInit {
  get conn() {
    return this.data.connection
  }

  loading = true
  submitting = false

  isVisible = false
  deactivationReasons: AirbnbDeactivationReasonFlowResponse

  get parent() {
    return this.deactivationReasons?.questions?.[0]
  }

  get selectedParent() {
    const parentId = this.form.get('parentId').value
    if (!parentId) {
      return null
    }
    return this.parent?.choices.find((i) => i.value === parentId)
  }

  get isDetailRequired() {
    const { parentId, childId } = this.form.getRawValue()
    return detailRequiredReasonIds.includes(parentId) || detailRequiredReasonIds.includes(childId)
  }

  form = this.fb.group({
    parentId: [],
    childId: [],
    details: [],
  })

  constructor(
    private fb: FormBuilder,
    private toaster: Toaster,
    public dialogRef: MatDialogRef<CustomChannelDialogComponent>,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { connection: Connection },
    private connectionAirbnbService: ConnectionAirbnbService,
    private airbnbChannelService: AirbnbChannelService
  ) {
    super()
  }

  ngOnInit(): void {
    combineLatest([
      this.connectionAirbnbService.getVisibilityStatusV2(this.conn.channelId, this.conn.propertyId, this.conn.roomId),
      this.airbnbChannelService.getPropertyDeactivationReasons(this.conn.propertyId),
    ])
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(([v, d]) => {
        this.isVisible = v.visible
        this.deactivationReasons = d
        if (this.isVisible) {
          this.form.get('parentId').setValidators([Validators.required])
        }
      })

    const setCtrlValidator = (key: string, required = false) => {
      if (required) {
        this.form.get(key).setValidators([Validators.required])
      } else {
        this.form.get(key).clearValidators()
      }
      this.form.get(key).updateValueAndValidity()
    }

    this.form
      .get('parentId')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((id) => {
        setCtrlValidator('details', this.isDetailRequired)
        setCtrlValidator('childId', !!this.selectedParent.choices?.length)
      })

    this.form
      .get('childId')
      .valueChanges.pipe(untilDestroy(this))
      .subscribe((id) => {
        setCtrlValidator('details', this.isDetailRequired)
      })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  setVisibility(form: FormGroup) {
    this.submitting = true

    const { parentId, childId, details } = form.value
    this.connectionAirbnbService
      .setVisibilityStatusV2(this.conn.channelId, this.conn.propertyId, this.conn.roomId, !this.isVisible, {
        deactivation_reason: childId || parentId,
        deactivation_details: details,
      })
      .subscribe(
        () => {
          this.toaster.success('Property visibility updated successfully.')
          this.dialogRef.close(!this.isVisible)
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
          this.submitting = false
        }
      )
  }
}
