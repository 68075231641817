import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromTask from './task.reducer'
import { EffectsModule } from '@ngrx/effects'
import { TaskEffects } from './task.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('task', fromTask.tasksReducer),
    EffectsModule.forFeature([TaskEffects]),
  ],
  declarations: [],
})
export class TaskStoreModule {}
