<div class="" [class.container-box]="viewType === 'list'">
  <div class="filters-box" [class.container-box]="viewType === 'grid'">
    <app-select-search
      bindValue="id"
      bindLabel="name"
      [items]="rentals"
      [ctrl]="filters.get('rentalIds')"
      placeholder="Rentals"
      matTooltip="Filter the below automations by rental."
      matTooltipPosition="above"
      matTooltipClass="text-center"
    >
    </app-select-search>
    <mat-form-field2
      [showLabel]="false"
      matTooltip="Filter the below automations by period."
      matTooltipPosition="above"
      matTooltipClass="text-center"
    >
      <app-mat-daterange-input
        placeholder="Rate Period"
        [formControl]="filters.get('period')"
      ></app-mat-daterange-input>
    </mat-form-field2>

    <app-cancel-filters [isFiltering]="filters | isFiltering" (clear)="clearFilters()"></app-cancel-filters>

    <app-button
      (click)="onRefresh()"
      icon="far fa-sync"
      class="btn btn-secondary-primary"
      [loading]="isRefreshing$ | async"
      matTooltip="Refresh"
      matTooltipPosition="above"
    >
    </app-button>

    <div class="flex-fill"></div>

    <button type="button" class="btn btn-secondary-info" (click)="onAdd()" [disabled]="1 | isReadonlyRole">
      <i class="far fa-plus"></i> Add Automation
    </button>
  </div>

  <ng-template [ngTemplateOutlet]="table" *ngIf="viewType === 'list'"></ng-template>
  <ng-template [ngTemplateOutlet]="grid" *ngIf="viewType === 'grid'"></ng-template>
  <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
</div>

<ng-template #grid>
  <app-last-minute-automations-grid
    [items]="dataSource.filteredData"
    class="d-block my-3"
  ></app-last-minute-automations-grid>
</ng-template>

<ng-template #table>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <mat-table #table matSort matSortActive="created" matSortDirection="desc" [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-truncate">
              {{ element.name }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rentals">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Rentals</mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            [matTooltip]="getRentalNames | call: element.rentals"
            matTooltipPosition="above"
          >
            {{ element.rentals?.length || 'All Rentals' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="period">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Period</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="text-truncate">
              {{ element.start | epochUTC: 'MMM D, YYYY' }} - {{ element.stop | epochUTC: 'MMM D, YYYY' }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustment">
          <mat-header-cell *matHeaderCellDef>Adjustment</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element | lastMinuteDec }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>

          <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
            <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
              <div dropdownToggle class="btn-group btn-ellips-holder">
                <a class="btn-ellips">
                  <i class="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <li>
                  <button (click)="onEdit(element)" type="button"><i class="fal fa-edit"></i>Edit</button>
                </li>
                <li>
                  <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i>Delete</button>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onEdit(row)" class="clickable"></mat-row>
      </mat-table>

      <mat-paginator2
        #paginator
        [length]="dataSource?.data?.length"
        [class.hidden]="isEmptyTable$ | async"
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator2>
    </table>
  </div>
</ng-template>
