import { Directive, Input } from '@angular/core'
import { PopoverDirective } from 'ngx-bootstrap/popover'

@Directive({
  selector: '[appTooltip]',
  exportAs: 'tooltip',
})
export class TooltipDirective extends PopoverDirective {
  @Input() set appTooltip(data: any) {
    this.popover = data
  }
  @Input() set tooltipPosition(placement: string) {
    this.placement = placement as any
  }

  @Input() set tooltipDelay(delay: number) {
    this.delay = delay
  }

  @Input() set tooltipContext(context: any) {
    this.popoverContext = context
  }

  @Input() set tooltipContainer(c) {
    this.container = c
  }

  @Input() tooltipDisabled = false

  placement = 'auto' as any
  containerClass = 'custom-tooltip'
  triggers = 'hover'
  container = 'body'
  adaptivePosition = true

  show() {
    if (this.tooltipDisabled) {
      return
    }
    super.show()
  }
}
