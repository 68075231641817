import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { PaymentGatewayLog, PaymentGatewayLogView } from './gateway-log.model'
import { GatewayLogState } from './gateway-log.state'
import { selectAllInquiries } from '@tv3/store/inquiry/inquiry.selectors'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

export const paymentGatewayLogState = createFeatureSelector<GatewayLogState>('gateway-logs')

export const selectLog = (paymentGatewayId) =>
  createSelector(paymentGatewayLogState, (s: GatewayLogState) => {
    return (s && s[paymentGatewayId]) || []
  })

export const selectLogView = (paymentGatewayId) =>
  createSelector(
    selectLog(paymentGatewayId),
    selectAllRentals,
    selectAllInquiries,
    (log: PaymentGatewayLog[], rentals: Rental[], inquiries: Inquiry[]) => {
      return log.map((item) => {
        const rental = rentals.find((r) => r.id === item.rental_id)
        const inquiry = inquiries.find((i) => i.id === item.inquiry_id)
        return {
          ...item,
          guestName: inquiry?.guestNameView,
          rentalName: rental?.name,
          currency: rental?.currency,
        } as PaymentGatewayLogView
      })
    }
  )
