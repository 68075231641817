import * as lodash from 'lodash'

export enum PaymentScheduleType {
  AFTER_CHECKIN = 'AFTER_CHECKIN',
  BEFORE_CHECKIN = 'BEFORE_CHECKIN',
  AT_CHECKIN = 'AT_CHECKIN',
  AT_CHECKOUT = 'AT_CHECKOUT',
  AFTER_BOOKING = 'AFTER_BOOKING',
  AFTER_CHECKOUT = 'AFTER_CHECKOUT',
  AT_BOOKING = 'AT_BOOKING',
}

export function hasPaymentScheduleDaysOption(type: PaymentScheduleType) {
  return !lodash.startsWith(type, 'AT_')
}

export const paymentScheduleTypeItems = [
  { value: PaymentScheduleType.BEFORE_CHECKIN, label: 'Before Check-in' },
  { value: PaymentScheduleType.AT_CHECKIN, label: 'At Check-in' },
  { value: PaymentScheduleType.AFTER_CHECKIN, label: 'After Check-in' },
  { value: PaymentScheduleType.AT_CHECKOUT, label: 'At Check-out' },
  { value: PaymentScheduleType.AFTER_CHECKOUT, label: 'After Check-out' },
  { value: PaymentScheduleType.AT_BOOKING, label: 'At booking' },
  { value: PaymentScheduleType.AFTER_BOOKING, label: 'After booking' },
]

export type RentalPaymentSchedule = Partial<Record<PaymentScheduleType, { percent: number; days?: number }>>
