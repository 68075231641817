import { Component, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core'
import { NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import * as lodash from 'lodash'
import * as moment from 'moment'

export interface DateRangePickerSelection {
  from: number
  to: number
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @Input('selection')
  set setSelection(selection: DateRangePickerSelection) {
    this.prepareDates(selection)
  }

  @Input() local = false
  @Input() allowSingleDay = false
  @Input() showQuickActions = true
  @Input() maxDate: Date
  @Input() minDate: Date
  @Input() startDate: Date

  @Output() selectionChange = new EventEmitter<DateRangePickerSelection | null>()

  @ViewChild('datepicker', { static: true }) ngbDatePicker: NgbDatepicker

  hoveredDate: NgbDate

  fromDate: NgbDate
  toDate: NgbDate

  ngOnInit() {
    this.navigate(this.fromDate)
  }

  dateToDateStruct(date?: Date): NgbDateStruct {
    if (!date) {
      return undefined
    }
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
  }

  navigate(date: NgbDate) {
    this.ngbDatePicker.navigateTo(date)
  }

  onDateSelection(date: NgbDate) {
    const checkDate = (date1: NgbDate, date2: NgbDate) => {
      if (date1.equals(date2)) return this.allowSingleDay
      return date.after(this.fromDate)
    }

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date
    } else if (this.fromDate && !this.toDate && checkDate(date, this.fromDate)) {
      this.toDate = date
    } else {
      this.toDate = null
      this.fromDate = date
    }

    if (this.fromDate && this.toDate) {
      this.selectionChange.emit({
        from: this.toTimestamp(this.fromDate),
        to: this.toTimestamp(this.toDate),
      })
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    )
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate)
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date)
  }

  onSelectRange(unit: 'day' | 'week' | 'month', num?: number, endNum?: number) {
    const date = moment().add(num || 0, unit)
    const start = date.startOf(unit == 'week' ? 'isoWeek' : unit)
    const end = (!lodash.isNumber(endNum) ? date.clone() : moment().add(endNum, unit)).endOf(
      unit == 'week' ? 'isoWeek' : unit
    )
    this.fromDate = new NgbDate(start.year(), start.month() + 1, start.date())
    this.toDate = new NgbDate(end.year(), end.month() + 1, end.date())

    this.selectionChange.emit({ from: this.toTimestamp(this.fromDate), to: this.toTimestamp(this.toDate) })
  }

  onReset() {
    this.fromDate = null
    this.toDate = null
    this.selectionChange.emit(null)
  }

  private prepareDates(selection: DateRangePickerSelection) {
    this.fromDate = !selection || !selection.from ? null : this.fromTimestamp(selection.from)
    this.toDate = !selection || !selection.to ? null : this.fromTimestamp(selection.to)
  }

  private fromTimestamp(timestamp: number) {
    const m = (this.local ? moment : moment.utc)(timestamp * 1000)

    return NgbDate.from({
      day: m.date(),
      month: m.month() + 1,
      year: m.year(),
    })
  }

  private toTimestamp(date: NgbDate) {
    return (this.local ? moment : moment.utc)({
      year: date.year,
      month: date.month - 1,
      date: date.day,
    })
      .startOf('date')
      .unix()
  }
}
