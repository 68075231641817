/* tslint:disable max-line-length */

export enum RoleKeys {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  ACCOUNTING = 'ACCOUNTING',
  OWNER = 'OWNER',
  STAFF = 'STAFF',
  GUEST = 'GUEST',
  READ_ONLY = 'READ-ONLY',
}

export const readOnlyPermission = 'readOnly'

export const roles = {
  [RoleKeys.ADMIN]: [
    'canSeeAppStore',
    'canMigrateToSympl',
    'canSeeOwnerCenter',
    'canSeeYearlyPlanAnnouncement',
    'canSeeAirbnbVersion1Announcement',
    'canSeeAirbnbICalAnnouncement',
    'canSeePricingAnnouncement',
    'canChangePassword',
    'canSeeAutomations',
    'canSeeStealth',
    'canSeeChangelog',
    'canUseSearch',
    'canSeeNotifications',
    'canDeleteAccount',
    'canSeeAccount',
    'canSeeReports',
    'canSeeExpenses',
    'canSeeCalendar',
    'canSeeMultiCalendar',
    'canSeeStandardCalendar',
    'canSeePlanningCalendar',
    'canSeeAgenda',
    'canSeeHold',
    'canSeeInventories',
    'canSeeIncidents',
    'canSeeTasks',
    'canSeeSettings',
    'canSeeInquiries',
    'canSeeRentals',
    'canSeeInvoices',
    'canSeeCharges',
    'canSeeSmartDevices',
    'canSeeDiscounts',
    'canSeeBilling',
    'canSeeGuests',
    'canSeeRates',
    'canSeeDataFeeds',
    'canSeeChannels',
    'canSeeWidgets',
    'canSeeWebsites',
    'canEditUser',
    'canSeeIntegrations',
    'canSeeMessagingSettings',
    'canSeePayments',
    'canSeeSharing',
    'canSeeTemplates',
    'canSeeAddons',
    'canAddInquiry',
    'canEditInquiry',
    'canAddHold',
    'canEditHold',
    'canEditRental',
    'canEditGuest',
    'canEditNotes',
    'canAddRates',
    'canSeeContracts',
    'canSeeAllRentals',
    'canSeeCalendarActions',
    'canSeeDashboard',
    'canAddTasks',
    'canEditTasks',
    'canDeleteTasks',
    'canSeeGuestContacts',
    'canSeeReviews',
    'canAISettings',
    'canUseGlobalPush',
    'canUseCostEstimator',
    'canUseGlobalAdd',
    'canSeeExpenseSettings',
    'canSeeAccessCodes',
  ],
  [RoleKeys.MANAGER]: [
    'canSeeOwnerCenter',
    'canSeeYearlyPlanAnnouncement',
    'canSeeAirbnbVersion1Announcement',
    'canSeeAirbnbICalAnnouncement',
    'canSeePricingAnnouncement',
    'canChangePassword',
    'canSeeAutomations',
    'canSeeStealth',
    'canSeeChangelog',
    'canUseSearch',
    'canSeeNotifications',
    'canSeeAccount',
    'canSeeReports',
    'canSeeExpenses',
    'canSeeCalendar',
    'canSeeMultiCalendar',
    'canSeeStandardCalendar',
    'canSeePlanningCalendar',
    'canSeeAgenda',
    'canSeeHold',
    'canSeeInventories',
    'canSeeIncidents',
    'canSeeTasks',
    'canSeeSettings',
    'canSeeInquiries',
    'canSeeRentals',
    'canSeeInvoices',
    'canSeeCharges',
    'canSeeSmartDevices',
    'canSeeDiscounts',
    'canSeeGuests',
    'canSeeRates',
    'canSeeDataFeeds',
    'canSeeChannels',
    'canSeeWidgets',
    'canSeeWebsites',
    'canSeeIntegrations',
    'canSeeMessagingSettings',
    'canSeePayments',
    'canSeeSharing',
    'canSeeTemplates',
    'canSeeAddons',
    'canAddInquiry',
    'canEditInquiry',
    'canAddHold',
    'canEditHold',
    'canEditRental',
    'canEditGuest',
    'canEditNotes',
    'canAddRates',
    'canSeeContracts',
    'canSeeAllRentals',
    'canSeeCalendarActions',
    'canSeeDashboard',
    'canAddTasks',
    'canEditTasks',
    'canDeleteTasks',
    'canSeeGuestContacts',
    'canSeeReviews',
    'canAISettings',
    'canUseGlobalPush',
    'canUseCostEstimator',
    'canUseGlobalAdd',
    'canSeeAccessCodes',
  ],
  [RoleKeys.READ_ONLY]: [
    readOnlyPermission,
    'canSeeOwnerCenter',
    'canSeeYearlyPlanAnnouncement',
    'canSeeAirbnbVersion1Announcement',
    'canSeeAirbnbICalAnnouncement',
    'canSeePricingAnnouncement',
    'canChangePassword',
    'canSeeAutomations',
    'canSeeStealth',
    'canSeeChangelog',
    'canUseSearch',
    'canSeeNotifications',
    'canSeeAccount',
    'canSeeReports',
    'canSeeExpenses',
    'canSeeCalendar',
    'canSeeMultiCalendar',
    'canSeeStandardCalendar',
    'canSeePlanningCalendar',
    'canSeeAgenda',
    'canSeeHold',
    'canSeeInventories',
    'canSeeIncidents',
    'canSeeTasks',
    'canSeeSettings',
    'canSeeInquiries',
    'canSeeRentals',
    'canSeeInvoices',
    'canSeeCharges',
    'canSeeSmartDevices',
    'canSeeDiscounts',
    'canSeeGuests',
    'canSeeRates',
    'canSeeChannels',
    'canSeeWidgets',
    'canSeeWebsites',
    'canSeeIntegrations',
    'canSeeMessagingSettings',
    'canSeePayments',
    'canSeeSharing',
    'canSeeTemplates',
    'canSeeAddons',
    'canSeeContracts',
    'canSeeAllRentals',
    'canSeeDashboard',
    'canSeeGuestContacts',
    'canSeeReviews',
    //
    // 'canEditInquiry',
    // 'canAddHold',
    // 'canEditHold',
    // 'canEditRental',
    // 'canEditGuest',
    // 'canEditNotes',
    // 'canDeleteTasks',
  ],
  [RoleKeys.ACCOUNTING]: ['canSeeReports'],
  [RoleKeys.OWNER]: [
    //
    'canSeeCalendar',
    'canSeeMultiCalendar',
    'canSeeStandardCalendar',
    'canEditHold',
    'canAddHold',
  ],
  [RoleKeys.STAFF]: [
    'canSeeCalendar',
    'canSeeMultiCalendar',
    'canSeeStandardCalendar',
    'canSeePlanningCalendar',
    'canSeeAgenda',
    'canSeeHold',
    'canSeeAllRentals',
    'canSeeGuestContacts',
  ],
  [RoleKeys.GUEST]: [],
}
