import { CustomChannelResponse } from './model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import * as lodash from 'lodash'

export interface State extends EntityState<CustomChannelResponse> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<CustomChannelResponse> = createEntityAdapter<CustomChannelResponse>({
  selectId: (item) => item.pkey,
  sortComparer: (a, b) =>
    lodash
      .toString(a.friendly_name)
      .localeCompare(lodash.toString(b.friendly_name), undefined, { numeric: true, sensitivity: 'base' }),
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
