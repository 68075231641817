import { Injectable } from '@angular/core'
import { Effect, ofType, Actions } from '@ngrx/effects'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import * as lodash from 'lodash'
import { ActionFailed, selectSomeOnce } from '@tokeet-frontend/tv3-platform'
import { AiResponderService } from './ai-responder.service'
import {
  DeleteAIDocument,
  DeleteAIDocumentComplete,
  LoadAISettings,
  LoadAISettingsComplete,
  ToggleAISettings,
  ToggleAISettingsComplete,
  UpdateAISettings,
  UpdateAISettingsComplete,
} from './ai-settings.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { selectAISettings } from './ai-settings.selectors'

@Injectable()
export class AISettingsEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadAISettings),
    switchMap(() =>
      this.aiService.getSettings().pipe(
        map((settings) => LoadAISettingsComplete({ settings })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateAISettings),
    switchMap(({ payload, message, silent }) =>
      this.aiService.updateSettings(payload).pipe(
        map(() => UpdateAISettingsComplete({ changes: payload })),
        tap(() => !silent && this.toaster.success(message || 'Settings updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  @Effect()
  toggle = this.actions$.pipe(
    ofType(ToggleAISettings),
    switchMap(({ ids, entityType, enabled }) =>
      this.store.pipe(
        selectSomeOnce(selectAISettings),
        switchMap((settings) => {
          const key = `ignore${lodash.capitalize(entityType)}s`
          let ignoreList = settings[key] || []
          if (enabled) {
            ignoreList = lodash.difference(ignoreList, ids)
          } else {
            ignoreList = lodash.uniq([...ignoreList, ...ids])
          }
          const payload = { [key]: ignoreList }
          return this.aiService.updateSettings(payload).pipe(
            map(() => ToggleAISettingsComplete({ changes: payload })),
            tap(() => this.toaster.success('Settings updated successfully.')),
            catchError((error) => of(ActionFailed({ error })))
          )
        })
      )
    )
  )

  @Effect()
  deleteDoc$ = this.actions$.pipe(
    ofType(DeleteAIDocument),
    switchMap(({ id }) =>
      this.aiService.deleteFile(id).pipe(
        map(() => DeleteAIDocumentComplete({ id })),
        tap(() => this.toaster.success('Document deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private toaster: Toaster,
    private aiService: AiResponderService
  ) {}
}
