import { Component, Input, OnInit, ViewChild } from '@angular/core'
import {
  ConfirmDialogService,
  DataCheckerService,
  Rental,
  filterPredicateFactory,
  isEmptyTable,
  selectAllRentals,
  untilDestroy,
  DialogService,
  SelectableRow,
  UserStorage,
} from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import {
  DeleteDiscountCode,
  DiscountCodeGuard,
  DiscountCodeView,
  LoadDiscountCodes,
  selectAllDiscountCodeViews,
  selectDiscountCodesLoaded,
} from '@tokeet-frontend/discount-codes'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { select, Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'
import { FormBuilder } from '@angular/forms'
import { createLocaleCompareSort } from '@tokeet-frontend/tv3-platform'
import {
  DeleteLosDiscount,
  LoadLosDiscounts,
  LosDiscountGuard,
  LosDiscountView,
  selectAllLosDiscountViews,
} from '@tokeet-frontend/los-discount'
import * as lodash from 'lodash'
import { DiscountCodeOverlayService } from '../discount-code/overlay/discount-code-overlay.service'
import { LosDiscountOverlayService } from '../los-discount/overlay/los-discount-overlay.service'
import { AddDiscountCodeDialogComponent } from '../discount-code/add/add-discount-code-dialog.component'
import { AddLosDiscountDialogComponent } from '../los-discount/add/add-los-discount-dialog.component'

export interface RentalDiscountItem {
  name: string
  discountTypeView: string
  discountType: 'code' | 'los'
  type: string
  amount: number
  start: number
  end: number
  startView: string
  endView: string
  isUsed?: boolean
  rentals?: string[]
  item: LosDiscountView | DiscountCodeView
}

@Component({
  selector: 'app-discounts-table',
  templateUrl: './discounts-table.component.html',
  styleUrls: ['./discounts-table.component.scss'],
})
export class DiscountsTableComponent extends SelectableRow<RentalDiscountItem> implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  @Input() rental: Rental
  @Input() showAddBtn = false

  displayedColumns = ['select', 'name', 'discountTypeView', 'type', 'amount', 'startView', 'endView', 'edit']

  tableType = TableType.RentalDiscounts
  isEmptyTable$ = isEmptyTable(this.dataSource)

  items: RentalDiscountItem[] = []
  isLoaded$ = this.store.pipe(select(selectDiscountCodesLoaded))

  rentals$ = this.store.pipe(select(selectAllRentals))

  filters = this.fb.group({
    discountType: [],
    type: [],
    rentals: [],
  })

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private confirmDialog: ConfirmDialogService,
    private dialog: DialogService,
    private dataCheckerService: DataCheckerService,
    private discountCodeDialog: DiscountCodeOverlayService,
    private losDiscountDialog: LosDiscountOverlayService,
    protected storage: UserStorage
  ) {
    super(storage, TableType.RentalDiscounts)
    this.dataCheckerService.check([DiscountCodeGuard, LosDiscountGuard])
  }

  ngOnInit() {
    this.dataSource.filterPredicate = filterPredicateFactory(this.displayedColumns)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort()

    combineLatest([
      this.store.pipe(select(selectAllDiscountCodeViews)),
      this.store.pipe(select(selectAllLosDiscountViews)),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([discountCodes, losDiscounts]) => {
        this.items = [...this.processDiscountCodes(discountCodes), ...this.processLosDiscounts(losDiscounts)]
        this.filterData()
      })

    this.filters.valueChanges.pipe(untilDestroy(this)).subscribe(() => {
      this.paginator.firstPage()
      this.filterData()
    })

    this.filters.patchValue({ rentals: this.rental ? [this.rental.id] : [] })
  }

  filterData() {
    const { discountType, type, rentals } = this.filters.getRawValue()
    this.dataSource.data = lodash
      .filter(this.items, (item) => (discountType ? item.discountType === discountType : true))
      .filter((item) => (type ? item.type === type : true))
      .filter((item) => (rentals?.length ? lodash.intersection(item.rentals, rentals).length > 0 : true))
  }

  processDiscountCodes(items: DiscountCodeView[]): RentalDiscountItem[] {
    return lodash.map(items, (item: DiscountCodeView) => {
      return {
        name: item.name,
        discountTypeView: 'Discount Code',
        discountType: 'code',
        type: item.type,
        amount: item.amount,
        start: item.start,
        end: item.expires,
        startView: item.startView,
        endView: item.expiresView,
        isUsed: !!item.last_used,
        rentals: item.rental_id ? [item.rental_id] : [],
        item,
      }
    })
  }

  processLosDiscounts(items: LosDiscountView[]): RentalDiscountItem[] {
    return lodash.map(items, (item: LosDiscountView) => {
      return {
        name: item.name,
        discountTypeView: 'LOS Discount',
        discountType: 'los',
        type: item.type,
        amount: item.amount,
        start: item.start,
        end: item.end,
        startView: item.startView,
        endView: item.endView,
        rentals: item.rentals || [],
        item,
      }
    })
  }

  onSearch(term: string) {
    this.dataSource.filter = (term + '').trim().toLowerCase()
    this.paginator.firstPage()
  }

  onRefresh() {
    this.store.dispatch(LoadDiscountCodes({}))
    this.store.dispatch(LoadLosDiscounts({}))
  }

  onEdit(item: RentalDiscountItem) {
    if (item.discountType === 'code') {
      this.onEditDiscountCode(item.item as DiscountCodeView)
    } else {
      this.onEditLosDiscount(item.item as LosDiscountView)
    }
  }

  onEditDiscountCode(item: DiscountCodeView) {
    this.discountCodeDialog.open(item)
  }

  onEditLosDiscount(item: LosDiscountView) {
    this.losDiscountDialog.open(item)
  }

  onAddDiscountCode() {
    this.dialog.openVariableDialog(AddDiscountCodeDialogComponent, {
      width: '700px',
      height: 'auto',
      data: {
        item: this.rental ? { rental_id: this.rental.id } : {},
      },
    })
  }

  onAddLosDiscount() {
    this.dialog.openVariableDialog(AddLosDiscountDialogComponent, {
      width: '700px',
      height: 'auto',
      data: {
        item: this.rental ? { rentals: [this.rental.id] } : {},
      },
    })
  }

  onDelete(item: RentalDiscountItem) {
    if (item.discountType === 'code') {
      this.onDeleteDiscountCode(item.item as DiscountCodeView)
    } else {
      this.onDeleteLosDiscount(item.item as LosDiscountView)
    }
  }

  onDeleteDiscountCode(item: DiscountCodeView) {
    this.confirmDialog
      .confirm({
        title: 'Delete discount?',
        body: 'Are you sure you want to delete this discount?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteDiscountCode({ id: item.id }))
      })
  }

  onDeleteLosDiscount(item: LosDiscountView) {
    this.confirmDialog
      .confirm({
        title: 'Delete discount?',
        body: 'Are you sure you want to delete this discount?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteLosDiscount({ id: item.id }))
      })
  }
}
