import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './ssl.state'
import * as reducer from './ssl.reducer'

export const CustomDomainState = createFeatureSelector<state.State>('CustomDomains')

export const selectCustomDomainsLoaded = createSelector(CustomDomainState, (state: state.State) => state.isLoaded)

export const selectCustomDomainEntities = createSelector(CustomDomainState, reducer.selectEntities)

export const selectCustomDomainById = (domain: string) =>
  createSelector(selectCustomDomainEntities, (itemsById) => {
    return itemsById[domain]
  })

export const selectAllCustomDomains = createSelector(CustomDomainState, reducer.selectAll)
