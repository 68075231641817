import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AccessCodeLog, SmartDeviceAccess } from './model'
import * as moment from 'moment'
import { map } from 'rxjs/operators'
import * as lodash from 'lodash'

@Injectable()
export class SmartDeviceAccessService {
  constructor(private http: HttpClient) {}

  getBookingDeviceAccesses(inquiryId: string) {
    const url = `@api/smartdeviceaccess/inquiry/${inquiryId}`
    return this.http
      .get<SmartDeviceAccess[]>(url)
      .pipe(map((items) => lodash.orderBy(items, [(t) => t.created], 'desc').map((t) => this.addOffset(t))))
  }

  getDeviceAccesses(deviceId: string) {
    const url = `@api/smartdeviceaccess/all/?device_id=${deviceId}`
    return this.http
      .get<SmartDeviceAccess[]>(url)
      .pipe(map((items) => lodash.orderBy(items, [(t) => t.created], 'desc').map((t) => this.addOffset(t))))
  }

  getGuestDeviceAccesses(deviceId: string) {
    const url = `@api/smartdeviceaccess/all/?device_id=${deviceId}`
    return this.http
      .get<SmartDeviceAccess[]>(url)
      .pipe(map((items) => lodash.orderBy(items, [(t) => t.created], 'desc').map((t) => this.addOffset(t))))
  }

  getRentalDeviceAccesses(rentalId: string) {
    const url = `@api/smartdeviceaccess/all/?rental_id=${rentalId}`
    return this.http
      .get<SmartDeviceAccess[]>(url)
      .pipe(map((items) => lodash.orderBy(items, [(t) => t.created], 'desc').map((t) => this.addOffset(t))))
  }

  getAccessCodeLog(accessCodeId: string, deviceId: string) {
    const url = `@api/v1/smartdevice/log/?accessCodeId=${accessCodeId}&deviceId=${deviceId}`
    return this.http.get<{ response: { data: AccessCodeLog[] } }>(url).pipe(map((res) => res.response.data || []))
  }

  private addOffset(item: SmartDeviceAccess): SmartDeviceAccess {
    return {
      ...item,
      access: {
        ...item.access,
        code: lodash.trim(item.access.code, '@'),
        start: item.access.start + moment.tz(0, item.timezone).utcOffset() * 60,
        end: item.access.end + moment.tz(0, item.timezone).utcOffset() * 60,
      },
    }
  }
}
