import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core'
import * as lodash from 'lodash'

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  @Input() maxRating = 5
  // 0-5
  stars: number[] = []
  @Input() set rating(v: number) {
    this.rateIndex = v
    this.hoverIndex = v
    const full = Math.floor(v)
    const half = v - full > 0
    const empty = this.maxRating - Math.ceil(v)
    this.stars = [...lodash.times(full).map(() => 1), half ? 0.5 : 0, ...lodash.times(empty).map(() => 0)].slice(
      0,
      this.maxRating
    )
  }
  @HostBinding('class.editable')
  @Input()
  editable = false

  @Input() showLevelColor = false
  @HostBinding('class')
  get ratingLevel() {
    return this.showLevelColor ? 'r' + Math.ceil((this.rateIndex / this.maxRating) * 5) : ''
  }

  @Output() rate = new EventEmitter<number>()

  rateIndex = 0
  hoverIndex = 0
  @HostListener('mouseleave') onPresenting() {
    this.hoverIndex = this.rateIndex
  }

  get starOptions() {
    return lodash.range(1, this.maxRating + 1)
  }

  constructor() {}

  ngOnInit(): void {}

  getStarClass(v: number): string {
    if (v === 0) {
      return 'cs-icon cs-icon-star-outline'
    } else if (v === 1) {
      return 'cs-icon cs-icon-star'
    } else {
      return 'cs-icon cs-icon-star-half-solid'
    }
  }

  onHover(v: number) {
    this.hoverIndex = v
  }

  onVote(v: number) {
    this.rateIndex = v
    this.rate.emit(v)
  }
}
