import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './plan.state'
import * as R from 'ramda'
import * as reducer from '@tv3/store/plan/plan.reducer'
import { ActivePlansByProduct, PlanOption, ProductsForPlan } from '@tv3/store/plan/plan.model'
import { ActivePlanStatusView, PlanOptionView, PlanSubscriptionView } from '@tv3/store/plan/plan.view'
import { selectAllUsers } from '@tokeet-frontend/tv3-platform'

export const planState = createFeatureSelector<state.State>('plan')

export const selectAllPlans = createSelector(planState, reducer.selectAll)

export const selectAllPlanViews = createSelector(selectAllPlans, (plans: PlanOption[]) => {
  return R.map((plan) => {
    return new PlanOptionView(plan)
  }, plans)
})

export const selectAllPlanViewsForVersion = createSelector(selectAllPlanViews, (plans: PlanOptionView[], props) => {
  return R.filter((plan) => {
    return plan.version === props[plan.product]
  }, plans)
})

export const selectAllProductPlanViews = (product: string) => {
  return createSelector(selectAllPlanViews, (planViews) => {
    return R.filter(R.propEq('product', product), planViews)
  })
}

export const selectAllProductPlanViewsByVersions = (product: string, versions: string[]) => {
  return createSelector(selectAllPlanViews, (planViews) => {
    return planViews.filter((p) => p.product === product && versions.includes(p.version))
  })
}

export const selectProductPlanViewById = createSelector(selectAllPlanViews, (plans: PlanOptionView[], props) => {
  return R.find((p) => p.id === props.id, plans || [])
})

export const selectProductsPlansLoaded = createSelector(planState, (state: state.State) => state.isPlansLoaded)

export const selectTokeetPlansLoaded = createSelector(
  planState,
  (state: state.State) => state.isPlansLoaded[ProductsForPlan.AdvanceCM]
)

export const selectTokeetPlans = createSelector(selectAllPlans, (plans: PlanOption[]) => {
  return R.filter((plan: PlanOption) => plan.product === 'tokeet', plans)
})

export const selectIsUpdatingPlan = createSelector(planState, (state: state.State) => state.isUpdatingPlan)

export const selectActivePlansLoaded = createSelector(planState, (state: state.State) => state.isActivePlansLoaded)
export const selectActivePlans = createSelector(planState, (state) => state.activePlans)
export const selectEndedPlans = createSelector(planState, (state) => state.endedPlans)

export const selectActivePlanStatusView = createSelector(planState, (state) => {
  if (state.status) {
    return new ActivePlanStatusView(state.status)
  }
  return null
})

export const selectActiveProductPlan = createSelector(
  selectActivePlans,
  (activeProductPlans: ActivePlansByProduct, props) => {
    return activeProductPlans && activeProductPlans[props.product]
  }
)

export const selectActiveProductPlanView = (product: ProductsForPlan) =>
  createSelector(selectActivePlans, (activeProductPlans: ActivePlansByProduct) => {
    const sub = activeProductPlans?.[product]
    if (sub) {
      return new PlanSubscriptionView(sub)
    }
    return null
  })

export const selectEndedProductPlanView = (product: ProductsForPlan) =>
  createSelector(selectEndedPlans, (endedProductPlans: ActivePlansByProduct) => {
    const sub = endedProductPlans?.[product]
    if (sub) {
      return new PlanSubscriptionView(sub)
    }
    return null
  })

export const selectActiveTokeetPlan = createSelector(planState, (state) => state.activePlans[ProductsForPlan.AdvanceCM])
export const selectActiveTokeetPlanView = createSelector(planState, (state) => {
  const sub = state.activePlans[ProductsForPlan.AdvanceCM]
  if (sub) {
    return new PlanSubscriptionView(sub)
  }
  return null
})

export const selectActiveAddonPlanViews = createSelector(planState, (state) => {
  return R.map((product) => {
    const sub = state.activePlans[product]
    if (sub) {
      return new PlanSubscriptionView(sub)
    }
    return null
  }, Object.values(ProductsForPlan)).filter((p) => !!p)
})

export const selectSavedCreditCard = createSelector(planState, (s) => s.card)

export const isPlanActive = createSelector(selectActiveTokeetPlan, (subscription) => {
  return R.pathOr('inactive', ['status'], subscription) !== 'inactive'
})

export const canAddUser = createSelector(
  isPlanActive,
  selectActiveTokeetPlan,
  selectAllUsers,
  (isActive, subscription, users) => {
    return isActive && subscription.users > users.length
  }
)
