import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { ChannelAdjustmentEffects } from './effects'
import { ChannelAdjustmentsGuard } from './guard'
import { adjustmentReducer } from './reducer'
import { ChannelAdjustmentService } from './service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('channelAdjustment', adjustmentReducer),
    EffectsModule.forFeature([ChannelAdjustmentEffects]),
  ],
  providers: [ChannelAdjustmentsGuard, ChannelAdjustmentService],
  declarations: [],
})
export class ChannelAdjustmentStoreModule {}
