<div class="">
  <div class="d-flex align-items-center gap-3 mb-3">
    <h6 class="mb-0">Taxes</h6>
    <div class="flex-fill"></div>
    <button (click)="addTax()" class="btn btn-secondary-info" type="button"><i class="far fa-plus"></i> Add Tax</button>
    <div class="dropdown" dropdown>
      <button class="btn btn-secondary-primary dropdown-toggle" dropdownToggle type="button">
        <i class="fal fa-copy"></i>
        Copy
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li>
          <button
            (click)="onCopyFrom()"
            matTooltip="Ability to copy taxes from rental(s) to your selected rental."
            matTooltipPosition="left"
          >
            <i class="fal fa-copy"></i> Copy from Rental
          </button>
        </li>
        <li>
          <button
            (click)="onCopyTo()"
            matTooltip="Ability to copy taxes to rental(s) from your selected rental."
            matTooltipPosition="left"
          >
            <i class="fal fa-paste"></i> Copy to Rental
          </button>
        </li>
      </ul>
    </div>
    <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span>
      Save
    </button>
  </div>
  <div class="alert alert-secondary-info">
    <i class="fal fa-info-circle alert-icon"></i>
    <div class="alert-content">
      Change taxes associated with this rental. These adjustments will apply to any booking not imported from channels
      where these taxes are charged to the guest up front.
    </div>
  </div>
  <form [formGroup]="form" class="d-flex flex-column gap-3">
    <div formArrayName="taxes" *ngFor="let item of taxes.controls; let i = index" class="container-box p-3">
      <div [formGroupName]="i" class="row">
        <div class="col-sm-3">
          <mat-form-field2 class="mat-block">
            <input
              type="text"
              formControlName="name"
              placeholder="Name"
              matInput
              required
              [matAutocomplete]="autoName"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoName="matAutocomplete"
              (optionSelected)="onAccountTaxSelected($event.option.value, item)"
            >
              <mat-option *ngFor="let option of availableTaxes" [value]="option">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field2>
        </div>
        <div class="col-sm-2">
          <mat-form-field2 class="mat-block">
            <mat-label
              ><span [innerHtml]="item['controls'].type.value | amountPlaceholder: currencySymbol"></span
            ></mat-label>
            <input type="number" min="0" formControlName="amount" required matInput />
            <mat-error *ngIf="item['controls'].amount.touched && item.hasError('required', ['amount'])">
              Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-2">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="type" placeholder="Amount Type">
              <mat-option value="percent">Percent</mat-option>
              <mat-option value="flat">Flat</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-sm-2">
          <mat-form-field2 class="mat-block">
            <mat-select formControlName="tax_type" placeholder="Tax Type" required>
              <mat-option value="pass_through_hotel_tax">Hotel Tax</mat-option>
              <mat-option value="pass_through_lodging_tax">Lodging Tax</mat-option>
              <mat-option value="pass_through_room_tax">Room Tax</mat-option>
              <mat-option value="pass_through_tourist_tax">Tourist Tax</mat-option>
              <mat-option value="pass_through_transient_occupancy_tax">Transient Occupancy Tax</mat-option>
              <mat-option value="pass_through_sales_tax">Sales Tax</mat-option>
              <mat-option value="pass_through_vat_gst">VAT / GST</mat-option>
              <mat-option value="pass_through_tourism_assessment_fee">Tourism Assessment Fee</mat-option>
            </mat-select>
            <mat-error *ngIf="item['controls'].tax_type.touched && item.hasError('required', ['tax_type'])">
              Tax Type is <strong>required</strong>
            </mat-error>
          </mat-form-field2>
        </div>
        <div class="col-sm-2">
          <mat-form-field2 class="mat-block">
            <mat-select
              formControlName="modality"
              placeholder="Modality"
              matTooltip="Modality determines how the fees is applied to the booking."
              matTooltipPosition="above"
            >
              <mat-option [value]="taxV3Modalities.PerStay">{{
                taxV3ModalityLabels[taxV3Modalities.PerStay]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerNight">{{
                taxV3ModalityLabels[taxV3Modalities.PerNight]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerPerson">{{
                taxV3ModalityLabels[taxV3Modalities.PerPerson]
              }}</mat-option>
              <mat-option [value]="taxV3Modalities.PerPersonPerNight">{{
                taxV3ModalityLabels[taxV3Modalities.PerPersonPerNight]
              }}</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>

        <!--            <div class="col-sm-1"></div>-->
        <!--            <div class="col-sm-4">-->
        <!--              <mat-form-field2 class="mat-block">-->
        <!--                <span matPrefix><i matTooltip="Airbnb exclusive feature" class="fab fa-airbnb"></i></span>-->
        <!--                <input type="text" formControlName="business_tax_id" matInput placeholder="Business Tax Id" />-->
        <!--              </mat-form-field2>-->
        <!--            </div>-->
        <!--            <div class="col-sm-4">-->
        <!--              <mat-form-field2 class="mat-block">-->
        <!--                <span matPrefix><i matTooltip="Airbnb exclusive feature" class="fab fa-airbnb"></i></span>-->
        <!--                <input-->
        <!--                  type="text"-->
        <!--                  formControlName="tot_registration_id"-->
        <!--                  matInput-->
        <!--                  placeholder="Accommodations tax registration number"-->
        <!--                />-->
        <!--              </mat-form-field2>-->
        <!--            </div>-->
        <!--            <div class="col-sm-3">-->
        <!--              <mat-form-field2 class="mat-block">-->
        <!--                <span matPrefix><i matTooltip="Airbnb exclusive feature" class="fab fa-airbnb"></i></span>-->
        <!--                <input-->
        <!--                  type="number"-->
        <!--                  formControlName="long_term_stay_exemption"-->
        <!--                  matInput-->
        <!--                  step="1"-->
        <!--                  placeholder="Long term stay exemption"-->
        <!--                />-->
        <!--              </mat-form-field2>-->
        <!--            </div>-->
        <div class="col-sm-1">
          <button
            (click)="removeTax(i)"
            class="btn btn-secondary-danger align-input-row-end"
            type="button"
            matTooltip="Remove Tax"
          >
            <i class="cs-icon cs-icon-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
