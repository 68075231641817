import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectRUWizardComponent } from './connect-ru-wizard.component'
import { ConnectRUWizardCompleteComponent } from './complete/connect-ru-wizard-complete.component'
import { ConnectRUWizardStep1Component } from './step1/connect-ru-wizard-step1.component'
import { ConnectRUWizardStep2Component } from './step2/connect-ru-wizard-step2.component'

import { SharedModule } from '@tv3/shared/shared.module'
import { RUListingDetailsOverlayComponent } from './ru-listing-validation/ru-listing-details-overlay.component'
import { RUListingDetailsOverlayService } from './ru-listing-validation/ru-listing-details-overlay.service'

const components = [
  // RU
  ConnectRUWizardStep1Component,
  ConnectRUWizardStep2Component,
  ConnectRUWizardCompleteComponent,
  ConnectRUWizardComponent,
  RUListingDetailsOverlayComponent,
]

const services = [
  //
  RUListingDetailsOverlayService,
]

const pipes = []

@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components],
  providers: [...services],
  imports: [CommonModule, SharedModule, Tv3PlatformModule],
})
export class ConnectRUWizardModule {}
