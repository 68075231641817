import { Component, Input } from '@angular/core'
import { Toaster } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-copy-to-clipboard',
  template: `
    <i
      class="fal fa-copy"
      [ngxClipboard]="input"
      (cbOnSuccess)="onCopied()"
      matTooltip="Copy"
      matTooltipPosition="above"
    >
    </i>
  `,
  styles: [
    `
      :host {
        margin-left: 5px;
        cursor: pointer;
      }
    `,
  ],
})
export class CopyToClipboardComponent {
  @Input() input
  @Input() successMessage

  constructor(private toast: Toaster) {}

  onCopied() {
    if (this.successMessage) {
      this.toast.success(this.successMessage)
    }
  }
}
