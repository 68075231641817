import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { DiscountCode } from '@tokeet-frontend/discount-codes'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeOverlayComponent } from './discount-code-overlay.component'

@Injectable()
export class DiscountCodeOverlayService {
  constructor(private dialog: DialogService) {}

  open(item: DiscountCode) {
    const defaultConfig: MatDialogConfig = {
      data: { item },
      panelClass: ['drawer-full-70'],
    }

    const dialogRef = this.dialog.openDrawer(DiscountCodeOverlayComponent, defaultConfig)

    return dialogRef
  }
}
