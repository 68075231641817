<div class="modal-header">
  <h5 class="modal-title">Log Details</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <tr>
        <td><strong>Channel: </strong></td>
        <td>{{ data.log?.channel }}</td>
      </tr>
      <tr>
        <td><strong>Property: </strong></td>
        <td>{{ data.log?.property }}</td>
      </tr>
      <tr>
        <td><strong>Type: </strong></td>
        <td>{{ data.log?.type | connectionActivityType }}</td>
      </tr>
      <tr>
        <td><strong>Date/Time: </strong></td>
        <td>{{ data.log?.created | epoch: 'DD-MMM-YYYY | h:mm A' }}</td>
      </tr>
      <tr>
        <td colspan="2"><strong>Message: </strong></td>
      </tr>

      <tr>
        <td colspan="2">
          <textarea class="w-100" [rows]="data.log?.message ? 5 : 1" disabled>{{ data.log?.message || 'OK' }}</textarea>
        </td>
      </tr>
      <tr>
        <td colspan="2"><strong>Request: </strong></td>
      </tr>

      <tr>
        <td colspan="2">
          <textarea class="w-100" [rows]="data.log?.request ? 5 : 1" disabled>{{
            data.log?.request || 'No Content'
          }}</textarea>
        </td>
      </tr>
      <tr>
        <td colspan="2"><strong>Response: </strong></td>
      </tr>

      <tr>
        <td colspan="2">
          <textarea class="w-100" [rows]="data.log?.response ? 5 : 1" disabled>{{
            data.log?.response || 'No Content'
          }}</textarea>
        </td>
      </tr>
    </table>
  </div>
</div>
