import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { GetGuestBookings, GetGuestEmails } from '@tv3/store/guest/guest.actions'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { MessageSidePanelService } from '@tv3/containers/inquiries/dialogs/message/message-side-panel.service'
import { Message } from '@tv3/store/message/message.model'
import { Guest } from '@tv3/store/guest/guest.model'
import { selectInquiriesByGuestId } from '@tv3/store/inquiry/inquiry.selectors'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as lodash from 'lodash'

@Component({
  selector: 'app-guest-email-list',
  templateUrl: './guest-email-list.component.html',
  host: { class: 'messages' },
  styleUrls: ['./guest-email-list.component.scss'],
})
export class EditGuestEmailListComponent extends Destroyable implements OnInit {
  @Input() set guest(g: Guest) {
    this.guestItem = g

    if (this.guestItem.emailMessages && !lodash.isEqual(this.guestItem.emailMessages, this.emailMessages)) {
      this.emailMessages = this.guestItem.emailMessages
    }

    if (!this.guestItem.emailMessages) {
      this.store.dispatch(GetGuestEmails({ guest: this.guestItem }))
    }

    if (!this.guestItem.bookingsInfo) {
      this.store.dispatch(GetGuestBookings({ guest: this.guestItem }))
    }
  }

  guestItem: Guest
  inquiry: Inquiry
  emailMessages: Message[]

  tableType = TableType.GuestEmailsList

  constructor(private store: Store<fromRoot.State>, private messageSidePanelService: MessageSidePanelService) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectInquiriesByGuestId(this.guestItem.id)), untilDestroy(this)).subscribe((d) => {
      this.inquiry = lodash.head(d)
    })
  }

  onEdit(guest: Message) {
    this.messageSidePanelService.open(guest.inquiryId, guest.messageId)
  }
}
