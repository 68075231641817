import { Observable, of } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { catchError, map, switchMapTo, take, tap } from 'rxjs/operators'
import { ChannelNameTokens, IsDataLoaded } from '@tokeet-frontend/tv3-platform'
import { State } from '../store/channels.state'
import { LoadChannelConfig } from '../store/channels.actions'
import { getChannelConfigLoaded } from '../store/channels.selectors'

@Injectable()
export class HomeToGoChannelGuard implements CanActivate, CanActivateChild, CanLoad, IsDataLoaded {
  constructor(private store: Store<State>) {}

  public canActivate(): Observable<boolean> {
    return this.isDataLoaded().pipe(
      switchMapTo(of(true)),
      catchError(() => of(false))
    )
  }

  isDataLoaded(): Observable<boolean> {
    return this.store.pipe(
      getChannelConfigLoaded(ChannelNameTokens.HomeToGo),
      tap((isLoaded) => {
        if (!isLoaded) {
          this.store.dispatch(LoadChannelConfig({ name: ChannelNameTokens.HomeToGo }))
        }
      }),
      map(() => true),
      take(1)
    )
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate()
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate()
  }
}
