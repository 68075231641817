<div class="modal-header">
  <h5 class="modal-title" [innerHTML]="data?.title"></h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <p [innerHtml]="data.body"></p>
  <mat-checkbox (change)="toggleExtra()" [checked]="isExtraChecked" *ngIf="data.extra">
    <span [innerHtml]="data.extra"></span>
  </mat-checkbox>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="create-btn" [ngClass]="data.closeButtonClass || 'btn btn-light'">
    <i class="far fa-times"></i> {{ data.closeText }}
  </button>
</div>
