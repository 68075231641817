import * as R from 'ramda'
import { plainToClass } from 'class-transformer'
import { isNumber, isObject, toNumber } from 'lodash'

import * as costResover from '@tokeet/cost-resolver'

import { deserialize, Expose, Serializable, Type } from '../../functions/serializer'
import { Address } from '../account/account.models'
import { Rate } from '../rate/rate.model'
import { isSomething } from '../../functions'
import { Currency } from '../../constants'
import { RentalPaymentSchedule } from './schedule.model'
import { RateMappingResponse } from './rental-rate-mapping.model'

export const DefaultRentalColor = '#ddd'

export type RentalBookedDayTypes = 'start' | 'end' | 'full' | 'start-end'

export interface RentalCheckTime {
  string: string
  hour: number
  min: number
  offset_min: number
  timezone: string
}

export enum RentalStatus {
  NOT_READY = 'not-ready',
  IN_PROGRESS = 'in-progress',
  READY = 'ready',
}

export class TokeetImage extends Serializable<TokeetImage> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'public_id' })
  publicId: string

  @Expose({ name: 'secure_url' })
  secureUrl: string

  @Expose({ name: 'rental_id' })
  rentalId: string

  @Expose({ name: 'website_id' })
  websiteId: string

  primary: number
  height: number
  archived: number
  url: string
  size: number
  order: number
  created: number
  type: string
  width: number
  metadata: { category?: number; description?: string }
  account: number
  favicon: number
  logo: number

  static deserialize(data: object): TokeetImage {
    return deserialize(TokeetImage, data)
  }
}

export interface TokeetImagePayload {
  rental_id: string
  website_id?: string
  public_id: string
  secure_url: string
  url: string
  type: string
  size: number
  height?: number
  width?: number
  primary?: number
  order?: number
  favicon?: number
  logo?: number
  metadata?: { category?: number; description?: string }
}

export class BaseRate {
  name: string

  nightly?: number
  weekly?: number
  monthly?: number
  weekend?: number

  minimum: number
  maximum?: number

  additionalFeeAmount?: number
  additionalFeeThreshold?: number
}

export const taxV3ModalityLabels: { [key: string]: string } = {
  [costResover.TaxModalities.PerPerson]: 'Per Person (PP)',
  [costResover.TaxModalities.PerNight]: 'Per Night (PN)',
  [costResover.TaxModalities.PerPersonPerNight]: 'Per Person/ Per Night (PP/PN)',
  [costResover.TaxModalities.PerStay]: 'Per Stay (PS)',
}

export type TaxV1 = number

export class TaxV2 {
  percent: number
  flat: number
}

export class TaxV3 {
  amount: number
  type: string
  name: string
  modality?: costResover.TaxModalities = costResover.TaxModalities.PerStay
  tax_type?:
    | 'pass_through_hotel_tax'
    | 'pass_through_lodging_tax'
    | 'pass_through_room_tax'
    | 'pass_through_tourist_tax'
    | 'pass_through_transient_occupancy_tax'
    | 'pass_through_sales_tax'
    | 'pass_through_vat_gst'
    | 'pass_through_tourism_assessment_fee'

  business_tax_id?: string
  tot_registration_id?: string
  long_term_stay_exemption?: number
}

export function toTaxV2(tax: TaxV1 | TaxV3) {
  const v2 = new TaxV2()
  if (isNumber(tax)) {
    v2.percent = tax
    v2.flat = 0
  }

  if (isObject(tax)) {
    // @ts-ignore
    v2.flat = tax.type === costResover.TaxTypes.Flat ? tax.amount : 0
    // @ts-ignore
    v2.percent = tax.type === costResover.TaxTypes.Percent ? tax.amount : 0
  }

  return v2
}

export class Gps {
  long: number
  lat: number
}

export interface RentalsImageCount {
  rental_id: string
  count: number
}

export interface RentalChannelAmenityAvailableItem {
  id: string
  name: string
  ru?: string
  expedia?: string
  agoda?: string
  bdc?: string
  abb?: string
  hvmi?: string
  holidu?: string
  gvr?: string
  [key: string]: string
}

// @dynamic
export class Rental extends Serializable<Rental> {
  @Expose({ name: 'pkey' })
  id: string

  abb_listing_id?: string

  @Expose({ name: 'payment_instructions' })
  paymentInstructions: string

  @Expose({ name: 'size_metric' })
  sizeMetric: string

  @Expose({ name: 'sleep_min' })
  sleepMin: number

  @Expose({ name: 'sleep_max' })
  sleepMax: number

  @Expose({ name: 'display_name' })
  displayName: string

  @Expose({ name: 'payment_terms' })
  paymentTerms: string

  @Expose({ name: 'restricted_users' })
  restrictedUsers: string[]

  owners: string[]

  @Type(() => Address)
  address: Address

  @Type(() => Gps)
  gps: Gps

  @Expose({ name: 'baserate' })
  baseRate: BaseRate

  payment_schedule?: RentalPaymentSchedule

  locale: string

  attributes?: object

  status?: RentalStatus

  detail: { [id: string]: boolean }

  instructions: {
    checkin: string
    directions: string
    checkout: string
    rules: string
  }

  specifics: {
    sec_code: number | string
    special_inst: string
    key_pickup: number | string
    wifi_pass: number | string
    wifi_name: string
  }

  @Type(() => TaxV3)
  taxes: TaxV3[]

  lastupdate: string
  tags: string[]
  created: number
  account: number
  size: number
  description: string
  archived: number
  bathrooms: number
  phone: number
  name: string
  email: string
  type: string
  bedrooms: number
  checkin_type?: string

  custom1: string
  custom2: string
  custom3: string
  custom4: string
  custom5: string

  deleted: 0 | 1

  imageCount?: number

  @Type(() => Rate)
  rates: Rate[]

  @Type(() => Rate)
  promotions: Rate[]

  @Expose({ name: 'ratemap' })
  rateMap: RateMappingResponse[]

  currency: Currency
  checkin: RentalCheckTime
  checkout: RentalCheckTime
  color: string

  @Type(() => TokeetImage)
  images: TokeetImage[]

  descriptionView: string
  cityView: string
  countryView: string

  children_allowed: number
  guest_minimum_age: number
  events_allowed: number
  smoking_allowed: number
  pets_allowed: number

  static deserialize(data: any): Rental {
    const rental: Rental = plainToClass<Rental, Rental>(Rental, data)

    rental.descriptionView = `${rental.bedrooms || 0} Bedrooms, ${rental.bathrooms || 0} Bathrooms`
    rental.cityView = R.pathOr('', ['address', 'city'], rental)
    rental.countryView = R.pathOr('', ['address', 'country'], rental)
    rental.bedrooms = parseFloat(`${rental.bedrooms}`)
    rental.bathrooms = parseFloat(`${rental.bathrooms}`)
    rental.sleepMin = parseInt(`${rental.sleepMin}`, 10)
    rental.sleepMax = parseInt(`${rental.sleepMax}`, 10)

    rental.custom1 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom1)
    rental.custom2 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom2)
    rental.custom3 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom3)
    rental.custom4 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom4)
    rental.custom5 = R.pipe(R.when(R.is(Number), R.toString))(rental.custom5)

    rental.instructions = rental.instructions || <any>{}

    rental.instructions.checkin = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.checkin)
    rental.instructions.directions = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.directions)
    rental.instructions.checkout = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.checkout)
    rental.instructions.rules = R.pipe(R.when(R.is(Number), R.toString))(rental.instructions.rules)

    rental.specifics = rental.specifics || <any>{}

    rental.specifics.sec_code = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.sec_code)
    rental.specifics.special_inst = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.special_inst)
    rental.specifics.key_pickup = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.key_pickup)
    rental.specifics.wifi_pass = R.pipe(R.when(R.is(Number), R.toString))(rental.specifics.wifi_pass)

    rental.description = R.pipe(R.when(R.is(Number), R.toString))(rental.description)

    rental.gps = !isSomething(rental.gps)
      ? rental.gps
      : {
          lat: toNumber(R.path(['gps', 'lat'], rental)),
          long: toNumber(R.path(['gps', 'long'], rental)),
        }

    rental.status = R.pipe((status: string | undefined): RentalStatus => {
      // Check if the status is a valid RentalStatus
      if (Object.values(RentalStatus).includes(status as RentalStatus)) {
        return status as RentalStatus
      }
      // Provide a default value if the status is not valid
      return RentalStatus.NOT_READY
    })(rental.status)

    return rental
  }
}
