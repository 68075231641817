import { Expose, Serializable, plainToClass } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { epochToView } from '@tokeet-frontend/tv3-platform'

export class CustomCode extends Serializable<CustomCode> {
  @Expose({ name: 'pkey' })
  id: string

  account: number
  name: string
  description: string
  body: string
  archived: number
  created: number
  creator: string

  attributes: { tags: string[] }
  rentals: string[]

  nameFormatted: string

  createdView: string
  tagView: string
  rentalNames: string
  creatorView: string

  static deserialize(data: any): CustomCode {
    let code: CustomCode = plainToClass<CustomCode, CustomCode>(CustomCode, data)

    let name = code.name || ''

    name = name.toString()

    code.nameFormatted = `*|CUSTOM:${name.toUpperCase()}|*`

    code.createdView = epochToView(code.created)

    code.tagView = lodash.get(code, 'attributes.tags', []).join(', ')

    return code
  }
}
