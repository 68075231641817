import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ChannelNameTokens, Rental } from '@tokeet-frontend/tv3-platform'
import { Channel } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { select, Store } from '@ngrx/store'
import {
  isConnectionUpdating,
  selectAvailableRentalForNewAPIConnection,
} from '@tv3/store/connection/connection.selectors'
import { Router } from '@angular/router'
import * as fromRoot from '@tv3/store/state'
import { asyncScheduler, Observable } from 'rxjs'
import { FormBuilder, Validators } from '@angular/forms'
import { AlertDialogService } from '@tokeet-frontend/tv3-platform'
import { isBoolean } from 'lodash'
import { LinkConnectionWithRental, UnlinkConnectionWithRentalComplete } from '@tv3/store/connection/connection.actions'
import { AirbnbHelperService } from '@tv3/containers/channels/channel-connect/airbnb-helper.service'
import { AibrnbListingDetails, ConnectionAirbnbService } from '@tv3/store/connection/connection-airbnb.service'
import { ChannelService } from '@tokeet-frontend/tv3-platform'
import { map, observeOn } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { BdcPropertySummaryDialogService } from '@tv3/containers/channels/content/bdc/dialogs/bdc-property-summary-dialog/bdc-property-summary-dialog.service'
import { PropertyContentHelperService } from '@tv3/containers/channels/channel-connect/property-content-helper.service'
import { hasChannelContentApi } from '@tv3/store/channel-content/content.model'
import { environment } from '../../../../../../environments/environment'
import { selectActiveTokeetPlanView } from '@tv3/store/plan/plan.selectors'
import { AirbnbBookingSettingsDialogService } from '@tv3/containers/channels/channel-connect/dialogs/airbnb-booking-settings-dialog/airbnb-booking-settings-dialog.service'
import { isTiketLikeChannel } from '@tokeet-frontend/channels'
import { AirbnbSetVisibilityDialogService } from '../../airbnb-set-visibility/airbnb-set-visibility-dialog.service'

@Component({
  selector: 'app-api-connection-detail',
  templateUrl: './api-connection-detail.component.html',
  styleUrls: ['./api-connection-detail.component.scss'],
})
export class ApiConnectionDetailComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() dateFormat: string

  isAirBnBV1 = false
  isAirBnBV2 = false
  isBDC = false
  isTiketLikeChannel = false
  hasChannelContentApi = false
  isAirBnBV2PropertyVisible: boolean
  isUpdating$ = this.store.pipe(select(isConnectionUpdating))
  rentals$: Observable<Rental[]>
  isTokeetPaidSub$ = this.store.pipe(
    select(selectActiveTokeetPlanView),
    map((s) => s?.status === 'active' && s?.isPaid && !s?.isTrial)
  )

  form = this.fb.group({
    rental: [undefined, [Validators.required]],
  })

  hasBookingPreferences = environment.features.airbnb.bookingPreferences
  hasBookingSettings = environment.features.airbnb.bookingSettings
  hasPriceSettings = environment.features.airbnb.priceSettings
  hasTaxSettings = environment.features.airbnb.taxSettings

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private actions: Actions,
    private store: Store<fromRoot.State>,
    private alertDialog: AlertDialogService,
    private channelService: ChannelService,
    private connectionAirbnbService: ConnectionAirbnbService,
    private connectionActionService: ConnectionHelperService,
    private airbnbHelperService: AirbnbHelperService,
    private airbnbBookingSettingsDialog: AirbnbBookingSettingsDialogService,
    private propertyContentHelperService: PropertyContentHelperService,
    private bdcPropertySummaryDialog: BdcPropertySummaryDialogService,
    private airbnbSetVisibilityDialog: AirbnbSetVisibilityDialogService
  ) {
    super()
  }

  isBoolean(value) {
    return isBoolean(value)
  }

  ngOnInit() {
    this.hasChannelContentApi = hasChannelContentApi(this.channel)
    if (this.rental) {
      this.form.patchValue({ rental: this.rental.id }, { emitEvent: false })
    }
    this.isAirBnBV2 = ChannelService.isAirBnBV2(this.channel.name)
    this.isAirBnBV1 = ChannelService.isAirBnBV1(this.channel.name)
    this.isBDC = ChannelService.isBookingAPI(this.channel.name)
    this.isTiketLikeChannel = isTiketLikeChannel(this.channel.name)

    if (this.isAirBnBV2) {
      this.connectionAirbnbService
        .getVisibilityStatusV2(this.connection.channelId, this.connection.propertyId, this.connection.roomId)
        .pipe(untilDestroy(this))
        .subscribe(({ visible }) => {
          this.isAirBnBV2PropertyVisible = visible
        })
    }

    this.actions.pipe(ofType(UnlinkConnectionWithRentalComplete), untilDestroy(this)).subscribe(({ update }) => {
      if (update.id === this.connection.id) {
        this.form.patchValue({ rental: undefined })
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rental) {
      this.rentals$ = this.store.pipe(
        observeOn(asyncScheduler),
        select(selectAvailableRentalForNewAPIConnection, { id: this.channel.id, selected: this.connection.rentalId })
      )
      this.form.patchValue({ rental: this.rental && this.rental.id })
    }
  }

  onRentalDetails(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }

  onToggleAirBnBVisible() {
    this.airbnbSetVisibilityDialog
      .open(this.connection)
      .afterClosed()
      .subscribe((visible) => {
        if (isBoolean(visible)) {
          this.isAirBnBV2PropertyVisible = visible
        }
      })
  }

  onLink() {
    this.connectionActionService.linkRental(this.channel.name, this.connection).subscribe()
  }

  onUnlink() {
    this.connectionActionService.unlinkRental(this.channel.name, this.connection)
  }

  onPushRates() {
    if (this.isTiketLikeChannel) {
      this.connectionActionService.pushRateForTiketLikeChannel(this.connection, this.channel, this.rental).subscribe()
    } else {
      this.connectionActionService.pushRates(this.channel, this.rental, this.connection).subscribe()
    }
  }

  onPushAvailability() {
    if (this.isTiketLikeChannel) {
      this.connectionActionService.pushAvailabilityForTiketLike(this.connection, this.channel.name).subscribe()
    } else {
      this.connectionActionService.pushAvailability(this.channel.name, this.connection).subscribe()
    }
  }

  onImportBookings() {
    this.connectionActionService.importBookings(this.channel.name, this.connection).subscribe()
  }

  onUpdateBookingPrefAirBnB() {
    this.airbnbHelperService.updateBookingPref(this.connection)
  }

  onUpdatePriceSettingsAirBnBV2() {
    this.airbnbHelperService.updatePriceSettingsV2(this.connection)
  }

  onUpdateTaxSettingsAirBnBV2() {
    this.airbnbHelperService.updateTaxSettings(this.connection)
  }

  onUpdateBookingSettingsAirBnBV2() {
    this.airbnbBookingSettingsDialog.open(this.connection)
  }

  onUpdateSyncCategoryAirBnBV2() {
    this.airbnbHelperService.setSyncCategoryV2(this.connection).subscribe()
  }

  onSyncImageAirBnBV2() {
    this.airbnbHelperService.syncImageV2(this.connection)
  }

  onUpdateListingDetailsAirBnBV2() {
    this.airbnbHelperService.updateListingDetailsV2(this.connection)
  }

  onSetReviewStatusAirBnBV2() {
    this.airbnbHelperService.setReviewStatusV2(this.connection)
  }

  onPushRoomsAirBnBV2() {
    this.airbnbHelperService.pushRoomsV2(this.connection, this.rental)
  }

  onDeleteListingAirBnBV2() {
    this.airbnbHelperService.deleteListingV2(this.connection)
  }

  onEditListDetails() {
    this.propertyContentHelperService.openContentOverlay(this.channel, this.connection).subscribe()
  }

  onBDCViewSummary() {
    // @ts-ignore
    this.bdcPropertySummaryDialog.open(this.connection.propertyId)
  }

  onSave() {
    if (!this.connection.roomId) {
      this.alertDialog.open({
        title: 'Property details missing.',
        body: `It seems the room id for this connection is missing. This could
          be due to an incomplete connection, or you may need to refresh the data.
          Please return to the main Channel Manager page and refresh the list.`,
      })
      return
    }
    const rentalId = this.form.get('rental').value

    this.store.dispatch(
      LinkConnectionWithRental({
        connId: this.connection.id,
        data: {
          channelId: this.connection.channelId,
          propertyId: this.connection.propertyId,
          roomId: this.connection.roomId,
          rentalId: rentalId,
        },
        channelName: this.channel.name,
      })
    )
  }
}
