import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store, select } from '@ngrx/store'
import {
  Channel,
  Rental,
  ChannelNameTokens,
  untilDestroy,
  Destroyable,
  selectOnce,
} from '@tokeet-frontend/tv3-platform'
import { switchMap, take, tap, finalize } from 'rxjs/operators'
import { selectAvailableRentalForNewAPIConnection } from '@tv3/store/connection/connection.selectors'
import { Observable } from 'rxjs'
import { Connection } from '@tv3/store/connection/connection.model'
import {
  PushAvailabilityToConnectionForWizardComplete,
  HoldEventForEmptyCalendarPush,
  LinkConnectionWithRental,
  LinkConnectionWithRentalComplete,
} from '@tv3/store/connection/connection.actions'
import { Actions, ofType } from '@ngrx/effects'
import { ConnectionHelperService } from '../../../connection-helper.service'
import { FormControl, Validators } from '@angular/forms'
import { AirbnbHelperService } from '@tv3/containers/channels/channel-connect/airbnb-helper.service'

@Component({
  selector: 'app-airbnb-listing-wizard-step1',
  templateUrl: './airbnb-listing-wizard-step1.component.html',
  styleUrls: ['./airbnb-listing-wizard-step1.component.scss'],
})
export class AirbnbListingWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()
  @Output() coHost = new EventEmitter()

  @Input() connection: Connection
  @Input() channel: Channel

  rentalIdCtrl = new FormControl('', [Validators.required])

  rentals$: Observable<Rental[]>

  private tmpHoldEventId: string

  isProcessing = false
  isLinked = false
  isBookingImported = false
  isAvailabilityPushed = false
  constructor(
    private store: Store<any>,
    private actions: Actions,
    private connectionHelperService: ConnectionHelperService,
    private airbnbHelperService: AirbnbHelperService
  ) {
    super()
  }

  ngOnInit(): void {
    this.rentals$ = this.store.pipe(
      selectOnce(selectAvailableRentalForNewAPIConnection, {
        id: this.channel.id,
        selected: this.connection.rentalId,
      })
    )

    this.rentalIdCtrl.setValue(this.connection.rentalId)
    if (this.connection.rentalId) {
      this.rentalIdCtrl.disable()
      this.isLinked = true
    }

    // save temporary hold event id
    this.actions
      .pipe(
        ofType(HoldEventForEmptyCalendarPush),
        tap(({ holdEvent }) => (this.tmpHoldEventId = holdEvent.id)),
        untilDestroy(this)
      )
      .subscribe()
  }

  onImportListing() {
    this.airbnbHelperService.importRoom(ChannelNameTokens.AirBnBV2API, this.connection).subscribe()
  }

  onChangeSyncType() {
    this.airbnbHelperService.setSyncCategoryV2(this.connection).subscribe()
  }

  onImport() {
    this.isProcessing = true
    this.connectionHelperService
      .importBookings(ChannelNameTokens.AirBnBV2API, this.connection)
      .pipe(
        take(1),
        tap(() => (this.isBookingImported = true)),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe()
  }

  onPushAvailability() {
    this.isProcessing = true
    this.connectionHelperService
      .pushAvailabilityForWizard(ChannelNameTokens.AirBnBV2API, this.connection, this.tmpHoldEventId)
      .pipe(
        switchMap(() => this.actions.pipe(ofType(PushAvailabilityToConnectionForWizardComplete))),
        take(1),
        tap(() => (this.isAvailabilityPushed = true)),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe()
  }

  onLink() {
    this.isProcessing = true
    this.store.dispatch(
      LinkConnectionWithRental({
        connId: this.connection.id,
        channelName: ChannelNameTokens.AirBnBV2API,
        data: {
          rentalId: this.rentalIdCtrl.value,
          channelId: this.connection.channelId,
          propertyId: this.connection.propertyId,
          roomId: this.connection.roomId,
        },
      })
    )
    this.actions
      .pipe(
        ofType(LinkConnectionWithRentalComplete),
        take(1),
        tap(() => (this.isLinked = true)),
        finalize(() => (this.isProcessing = false))
      )
      .subscribe()
  }
}
