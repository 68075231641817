export interface TokeetApp {
  pkey: string
  stripe_product_code: string
  name: string
  tag_line: string
  description: string
  starting_price: number | string
  logo_url: string
  overview: string
  images: any[]
  videos: any[]
  help_center_url: string
  categories: any[]
  score: number
  votes: number
  required_apps: any[]
  website_url: string
}

export interface AppImage {
  url: string
  type: 'mobile' | 'desktop'
}

export interface AppVideo {
  url?: string // null,
  primary: boolean // false,
  videoId: string // "AkNTkT6Ah8Y",
  type: 'youtube'
}

export type ProductPricingStrategy =
  | 'flat'
  | 'per-rental'
  | 'rental-brackets'
  | 'per-job'
  | 'percent'
  | 'per-website'
  | 'per-document'

export interface ProductPlan {
  amount: number
  name: string
  interval_count: number
  interval: string
  trial: string
  id: string
  product: string
  strategy: ProductPricingStrategy
  rentals: string
  value: string
}

export enum ProductStatus {
  Available,
  Added,
  Included,
  Migration,
  NotAvailable,
  PaidOnly,
}

export interface ProductAvailability {
  status: ProductStatus
  plan: ProductPlan
  message?: string
}

export const creditsRequiredProducts = ['tokeet_ai', 'tokeet_messaging']
