import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { concatMap, map, tap, toArray } from 'rxjs/operators'
import { Serializable } from '../../functions/serializer'
import { Toaster } from '../../services/toaster.service'
import { Role, CreateUserPayload, UpdateUserPayload } from './user.interfaces'
import { User, UserMFAConfig } from './user.model'

export class ResetPasswordData extends Serializable<ResetPasswordData> {
  password1: string
  password2: string
}

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  add(request: CreateUserPayload): Observable<User> {
    const url = '@api/user/'

    return this.http.post<object>(url, request).pipe(
      map(User.deserialize),
      tap(() =>
        this.toast.success(
          'User added successfully! Please note that to log in, users must verify their email first. If they are unable to locate the email, please inform them to reset their password.'
        )
      )
    )
  }

  update(userId: string, request: UpdateUserPayload): Observable<User> {
    const url = `@api/user/update/${userId}`

    return this.http.put<object>(url, request).pipe(
      map(User.deserialize),
      tap(() => this.toast.success('User updated successfully!'))
    )
  }

  updateMFA(userId: string, request: UserMFAConfig) {
    const url = `@api/user/mfa/${userId}`

    return this.http
      .put<UserMFAConfig>(url, request)
      .pipe(tap(() => this.toast.success('User MFA updated successfully!')))
  }

  updateSettings(userId: string, request: any): Observable<User> {
    const url = `@api/user/update/${userId}`

    return this.http.put<object>(url, request).pipe(
      map(User.deserialize),
      tap(() => this.toast.success('User updated successfully!'))
    )
  }

  remove(userId: string): Observable<User> {
    const url = `@api/user/delete/${userId}`

    return this.http.delete<User>(url)
  }

  removeMultiple(userIds: string[]): Observable<User[]> {
    return of(...userIds).pipe(
      concatMap((id) => this.remove(id)),
      toArray()
    )
  }

  fetchProfile(): Observable<User> {
    const url = '@api/user/'

    return this.http.get<object>(url).pipe(map(User.deserialize))
  }

  getRoles(): Observable<Role[]> {
    const url = '@api/roles/'

    return this.http.get<Role[]>(url)
  }

  fetchUsers(): Observable<User[]> {
    const url = '@api/user/all/'

    return this.http.get<any[]>(url).pipe(map((items) => items.map(User.deserialize)))
  }

  resetPassword(userId: string, payload: ResetPasswordData): Observable<User> {
    const url = `@api/user/password/${userId}`

    return this.http.put<any>(url, payload.serialize()).pipe(map(User.deserialize))
  }

  forgot(email: string) {
    const url = `@api/user/password/forgot`

    return this.http.post(url, { email })
  }
}
