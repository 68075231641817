import { Component, Input, OnInit } from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
})
export class InfoIconComponent implements OnInit {
  @Input() tooltipText = ''
  @Input() tooltipClass = 'text-center'
  @Input() tooltipPosition: TooltipPosition = 'above'

  constructor() {}

  ngOnInit(): void {}
}
