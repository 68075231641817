<div class="modal-header">
  <h6 class="modal-title d-flex align-items-center gap-2">
    Reply to Review
    <app-page-help articleId="-" videoId="-"></app-page-help>
  </h6>
  <button (click)="onClose()" type="button" class="close" aria-label="Close">
    <span><i class="far fa-times"></i></span>
  </button>
</div>
<div class="modal-body bg-none p-0">
  <div class="container-box mb-3 p-3 d-flex gap-2">
    <app-rental-image [rentalId]="review.rentalId" [size]="40" [circle]="true"></app-rental-image>
    <div>
      <app-rental-name [rentalId]="review.rentalId" class="text-bold"></app-rental-name>
      <div class="d-flex align-items-center gap-2 text-muted">
        <app-rating [rating]="review.overallRating"></app-rating>
        •
        <span>
          {{ review.submittedAt | date: 'dd - MMM - yyyy' }}
        </span>
        •
        <div>from {{ reviewer | titleCase }}</div>
      </div>
      <div class="mt-3">
        <app-review-category-tags [review]="review" class="mb-2"></app-review-category-tags>

        <div>
          {{ review.text }}
        </div>
      </div>
    </div>
  </div>

  <div class="container-box p-3 d-flex gap-2">
    <app-avatar [text]="userName" [size]="40"></app-avatar>
    <form [formGroup]="form" class="flex-1 position-relative">
      <mat-form-field2 class="mat-block">
        <mat-label>Your Response</mat-label>
        <textarea
          formControlName="response"
          rows="10"
          required
          matInput
          placeholder="Write your response here or choose to automatically generate one."
        ></textarea>
        <mat-error *ngIf="form.get('response').touched && form.get('response').hasError('required')">
          Response is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </form>
  </div>
</div>
<div class="modal-footer">
  <app-select-search
    [items]="templates$ | async"
    (valueChange)="onTemplateChange($event)"
    [hasBlank]="true"
    [multiple]="false"
    bindLabel="name"
    placeholder="Saved Templates"
    style="width: 12rem"
  ></app-select-search>

  <button
    class="btn btn-secondary-primary"
    (click)="onAIResponse()"
    [disabled]="isLoading || isGeneratingAIresponse || !review.inquiryId"
    *ngIf="(aiResponderSub$ | async)?.isValid"
  >
    <i class="far fa-spin fa-spinner" *ngIf="isGeneratingAIresponse"></i>
    <i class="cs-icon cs-icon-ai" *ngIf="!isGeneratingAIresponse"></i> Generate with AI
  </button>

  <div class="flex-fill"></div>
  <button (click)="onClose()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>

  <div class="dropdown btn-group" dropdown [isDisabled]="isGeneratingAIresponse || isLoading" [dropup]="true">
    <button
      (click)="onSave(form)"
      type="button"
      class="btn btn-secondary-info"
      [disabled]="isLoading || isGeneratingAIresponse"
    >
      <i class="cs-icon cs-icon-reply" *ngIf="!isLoading"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Post Reply
    </button>
    <button dropdownToggle class="btn btn-secondary-info dropdown-toggle"></button>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li>
        <button (click)="onSaveTemplateAndReply(form)">Reply & Save as Template</button>
      </li>
    </ul>
  </div>
</div>
