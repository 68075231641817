import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core'
import { Message } from '@tv3/store/message/message.model'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { DeleteMessage } from '@tv3/store/message/message.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as R from 'ramda'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { MessageDetailsDialogService } from '../../../inquiries/dialogs/message/message-details/message-details.dialog.service'
import { MoveMessageDialogService } from '../move-message/move-message-dialog.service'
import { Printd } from 'printd'
import { MessageDetails } from '@tv3/models/message/message-details'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { MessageListItemBaseComponent } from '@tv3/containers/messages/message-list/message-list-item-base/message-list-item-base.component'
import * as lodash from 'lodash'

@Component({
  selector: 'app-message-list-item',
  templateUrl: './message-list-item.component.html',
  styleUrls: ['./message-list-item.component.scss'],
})
export class MessageListItemComponent extends Destroyable implements OnInit, OnChanges {
  @HostBinding('class.box') box = true
  @HostBinding('class.receiver') receiver = true
  @HostBinding('class.sender') sender = false

  @Input() message: Message
  @Input() inquiry?: Inquiry
  @Input() hasMove = true
  @Input() collapsed = true

  @Output() editDraft = new EventEmitter<Message>()
  @Output() aiGenerate = new EventEmitter<Message>()

  messageDetails: MessageDetails

  get isSpam() {
    return lodash.get(this.message, 'spam_score.X-Mailgun-Sflag', 'No') == 'Yes'
  }

  @ViewChild('msg', { static: true }) messageListItemBaseComponent: MessageListItemBaseComponent
  @ViewChild('printableMessageContent', { static: true }) printableMessageContent: ElementRef

  viewPlainText = false

  currencySymbol = '$'

  constructor(
    private store: Store<fromRoot.State>,
    private messageDetailsDialog: MessageDetailsDialogService,
    private moveMessageDialog: MoveMessageDialogService,
    private confirmDialog: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.setCurrencySymbol(this.inquiry)
    this.messageDetails = new MessageDetails(this.message, this.inquiry)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.inquiry = R.pathOr(this.inquiry, ['inquiry', 'currentValue'], changes)
    this.setCurrencySymbol(this.inquiry)
    this.messageDetails = new MessageDetails(this.message, this.inquiry)
  }

  onDetails(message: Message, inquiry: Inquiry) {
    this.messageDetailsDialog.open({ message, inquiry })
  }

  setCurrencySymbol(inquiry: Inquiry) {
    this.currencySymbol = R.pathOr(this.currencySymbol, ['rental', 'currency', 'symbol'], inquiry)
  }

  onRemove() {
    this.confirmDialog.confirm().subscribe(() => {
      this.store.dispatch(DeleteMessage({ message: this.message, inquiryId: this.inquiry.id }))
    })
  }

  onMove() {
    this.moveMessageDialog.open({ message: this.message, inquiry: this.inquiry })
  }

  onPrint() {
    const printer = new Printd()
    printer.print(this.printableMessageContent.nativeElement)
  }

  toggleCollapsing(collapsed) {
    this.collapsed = collapsed
  }
}
