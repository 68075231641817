import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { Destroyable, isSomething, rentalQuantities, SaveForm, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreateHvmiListing,
  CreateHvmiListingFailure,
  CreateHvmiListingSuccess,
  HvmiListingViewModel,
  locales,
  selectHmcIds,
  UpdateHvmiListing,
  UpdateHvmiListingSuccess,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-hvmi-listing-details-overlay',
  templateUrl: './hvmi-listing-details-overlay.component.html',
  styleUrls: ['./hvmi-listing-details-overlay.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class HvmiListingDetailsOverlayComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    hmcId: ['', [Validators.required]],
    shortDescription: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(1000)]],
    rooms: ['', [Validators.required]],
    locale: [''],
    persons: [{ value: '', disabled: true }],
    guestsIncluded: [1],
    cancellationPolicyCategory: [''],
    bookingSetting: [{ value: 'instant_booking_only', disabled: true }],
  })

  quantities = rentalQuantities

  locales = locales

  cancellationPolicies = [
    { id: 'cancellation_14_days_with_grace_period', name: 'Cancellation 14 days with grace period' },
    { id: 'cancellation_30_days_with_grace_period', name: 'Cancellation 30 days with grace period' },
    { id: 'cancellation_60_days_with_grace_period', name: 'Cancellation 60 days with grace period' },
    { id: 'cancellation_90_days_with_grace_period', name: 'Cancellation 90 days with grace period' },
  ]

  isEditing = false

  errors: { error: string[]; field: string }[] = []

  hmcIds$ = this.store.pipe(select(selectHmcIds))

  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private actions: Actions,
    @Inject(MAT_DIALOG_DATA) public data: { listing: HvmiListingViewModel },
    public dialogRef: MatDialogRef<HvmiListingDetailsOverlayComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.isEditing = isSomething(this.data.listing.listingId)

    this.form.patchValue({
      hmcId: this.data.listing.hmcId,
      rooms: this.data.listing.rooms,
      shortDescription: this.data.listing.shortDescription,
      persons: this.data.listing.persons,
      locale: this.data.listing.locale,
      guestsIncluded: this.data.listing.guestsIncluded,
      cancellationPolicyCategory: this.data.listing.cancellationPolicyCategory,
    })

    if (this.isEditing) {
      this.form.get('hmcId').disable()
    }

    this.actions.pipe(ofType(CreateHvmiListingFailure), untilDestroy(this)).subscribe(({ err }) => {
      this.errors = err.error
    })

    this.actions.pipe(ofType(CreateHvmiListingSuccess, UpdateHvmiListingSuccess), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const payload = form.getRawValue()

    this.store.dispatch(
      UpdateHvmiListing({
        listing: {
          ...payload,
          propertyId: this.data.listing.propertyId,
        },
      })
    )
  }

  @SaveForm()
  onCreate(form: FormGroup) {
    const payload = form.getRawValue()

    this.store.dispatch(
      CreateHvmiListing({
        listing: {
          ...payload,
          propertyId: this.data.listing.propertyId,
        },
      })
    )
  }

  close() {
    this.dialogRef.close()
  }
}
