import { AbstractControl } from '@angular/forms'

const regex = /\p{Extended_Pictographic}/gu

export function emojiDisabledValidator(control: AbstractControl) {
  const value = control.value
  if (!value) {
    return null
  }

  if (regex.test(value)) {
    return { emoji: true }
  }

  return null
}
