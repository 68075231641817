import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as state from './discount-code.state'
import * as reducer from './discount-code.reducer'
import { selectRentalEntities } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { DiscountCodeView, toDiscountCodeView } from './discount-code.model'

export const discountCodeState = createFeatureSelector<state.State>('discountCodes')

export const selectDiscountCodesLoaded = createSelector(discountCodeState, (state: state.State) => state.isLoaded)

export const selectDiscountCodeEntities = createSelector(discountCodeState, reducer.selectEntities)

export const selectDiscountCodeById = createSelector(selectDiscountCodeEntities, (itemsById, props) => {
  return itemsById[props.id]
})

export const selectAllDiscountCodes = createSelector(discountCodeState, reducer.selectAll)

export const selectAllDiscountCodeViews = createSelector(
  selectAllDiscountCodes,
  selectRentalEntities,
  (discounts, rentalsById) => {
    return lodash.map(discounts, (item) => {
      const rental = rentalsById[item.rental_id]

      const newItem = toDiscountCodeView(item)
      newItem.rentalView = (rental && rental.name) || ''

      return newItem
    })
  }
)

export const selectDiscountCodeViewById = createSelector(selectAllDiscountCodeViews, (items, props) => {
  return lodash.find(items, { id: props.id })
})

export const selectDiscountCodeViewsByRentalId = createSelector(
  selectAllDiscountCodeViews,
  (items: DiscountCodeView[], props) => {
    return lodash.filter(items, (item) => item.rental_id === props.id || !item.rental_id)
  }
)
