import { Injectable } from '@angular/core'
import { concatMap, flatMap, map, tap, toArray } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { from, Observable, of } from 'rxjs'
import * as R from 'ramda'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'
import { ForwardResponse } from '@tv3/interfaces/responses/forward.response'
import { ForwardRequest } from '@tv3/interfaces/requests/forward.request'
import { Forward } from '@tv3/store/forward/forward.model'

@Injectable({
  providedIn: 'root',
})
export class ForwardService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all(): Observable<Forward[]> {
    return this.http.get<ForwardResponse[]>(`@api/email/forward/all`).pipe(
      map((response) => R.filter((r: ForwardResponse) => !R.isNil(r.id), response)),
      deserializeArray<Forward>(Forward)
    )
  }

  create(requests: ForwardRequest[]): Observable<Forward[]> {
    return of(requests).pipe(
      flatMap((request) => from(request)),
      concatMap((request) => this.createForward(request)),
      toArray(),
      tap(() => this.toast.success('Email forwarding created successfully.'))
    )
  }

  remove(emailForward: Forward): Observable<string> {
    return this.http
      .delete<{ key: string }>(`@api/email/forward/${emailForward.pkey}`)
      .pipe(map((response) => response.key))
  }

  removeMultiple(emailForwards: Forward[]): Observable<string[]> {
    return of(...emailForwards).pipe(
      concatMap((forward) => this.remove(forward)),
      toArray()
    )
  }

  private createForward(request: ForwardRequest) {
    return this.http
      .post<ForwardResponse>(`@api/email/forward`, request)
      .pipe(map((response) => Forward.deserialize(response)))
  }
}
