import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { domainsByWebsiteType, Website, WebsiteTypes, isTokeetWebsiteDomain } from '@tv3/store/website/website.model'
import { Store, select } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as fromRoot from '@tv3/store/state'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { WebsiteService } from '@tv3/store/website/website.service'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { UpdateWebsite } from '@tv3/store/website/website.actions'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { notAllNumbersValidator } from '@tokeet-frontend/tv3-platform'
import { notAllSpacesValidator } from '@tokeet-frontend/tv3-platform'
import { selectWebsiteById } from '@tv3/store/website/website.selectors'

@Component({
  selector: 'app-config-website-domain-dialog',
  templateUrl: './config-website-domain-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./config-website-domain-dialog.component.scss'],
})
export class ConfigWebsiteDomainDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    domain: [undefined],
    subdomain: [undefined, [Validators.pattern(/^[A-Za-z0-9]{1,25}$/), notAllSpacesValidator]],
    customDomain: [undefined, [notAllNumbersValidator, notAllSpacesValidator]],
  })

  websiteTypes = WebsiteTypes
  domains: string[] = []
  website: Website
  isCustomDomain = false
  isSSLEnabled = false

  constructor(
    public dialogRef: MatDialogRef<ConfigWebsiteDomainDialogComponent>,
    private store: Store<fromRoot.State>,
    private websiteService: WebsiteService,
    private toaster: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { website: Website },
    private fb: FormBuilder
  ) {
    super()
  }

  ngOnInit() {
    this.domains = domainsByWebsiteType[this.data.website.type]
    this.setFormData(this.data.website.domain)
    this.isSSLEnabled = this.data.website.type !== WebsiteTypes.GuestPortal
    this.website = this.data.website
    this.store.pipe(select(selectWebsiteById, { id: this.data.website.id }), untilDestroy(this)).subscribe((web) => {
      this.website = web
      this.isCustomDomain = !isTokeetWebsiteDomain(web.domain, web.type)
    })
  }

  private isTokeetDomain(str) {
    return this.domains.indexOf(str) > -1
  }

  isFormValid() {
    const formData = this.form.getRawValue()
    return (formData.subdomain && formData.domain) || formData.customDomain
  }

  private setFormData(websiteDomain: string = '') {
    const [sub, ...main] = websiteDomain.split('.')
    const tokeetDomain = websiteDomain.substring(sub.length)
    if (this.isTokeetDomain(tokeetDomain)) {
      this.form.patchValue(
        {
          subdomain: sub,
          domain: tokeetDomain,
        },
        { emitEvent: false }
      )
    } else {
      this.form.patchValue(
        {
          customDomain: websiteDomain,
        },
        { emitEvent: false }
      )
    }
  }

  formatDomain(domain: string = '') {
    domain = domain.toLowerCase()
    domain = domain.replace(/^http:\/*/, '')
    domain = domain.replace(/^https:\/*/, '')
    domain = domain.replace(/^www\./, '')
    return domain
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = this.form.getRawValue()
    if (formData.customDomain && !this.websiteService.isValidDomain(this.formatDomain(formData.customDomain))) {
      this.toaster.warning('Your domain is invalid.')
      return
    } else if (
      !formData.customDomain &&
      !this.websiteService.isValidDomain(`${formData.subdomain}${formData.domain}`)
    ) {
      this.toaster.warning('Your subdomain is invalid.')
      return
    }
    const websiteDomain = formData.customDomain
      ? this.formatDomain(formData.customDomain)
      : `${formData.subdomain}${formData.domain}`

    this.websiteService.isDomainAvailable(websiteDomain).subscribe(
      () => {
        this.store.dispatch(
          UpdateWebsite({
            websiteId: this.data.website.id,
            data: {
              ...this.data.website.serialize(),
              domain: websiteDomain,
            },
          })
        )
      },
      () => {
        this.toaster.warning('Your requested domain is already taken.')
      }
    )
  }
}
