import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import {
  LoadBookingFormulas,
  LoadBookingFormulasComplete,
  RemoveBookingFormula,
  RemoveBookingFormulaComplete,
  RemoveBookingFormulas,
  RemoveBookingFormulasComplete,
  ToggleBookingFormulaStatus,
  ToggleBookingFormulaStatusComplete,
  UpdateBookingFormula,
  UpdateBookingFormulaComplete,
  CreateBookingFormula,
  CreateBookingFormulaComplete,
  ToggleMultipleBookingFormulaStatus,
  ToggleMultipleBookingFormulaStatusComplete,
} from './actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { BookingFormulaService } from './service'

@Injectable()
export class FormulaEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadBookingFormulas),
    switchMap(() =>
      this.formulaService.getFormulas().pipe(
        map((items) => LoadBookingFormulasComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreateBookingFormula),
    switchMap(({ payload }) => {
      return this.formulaService.createFormula(payload).pipe(
        tap(() => this.toaster.success('Booking formula created successfully')),
        map((bookingFormula) => CreateBookingFormulaComplete({ item: bookingFormula })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateBookingFormula),
    switchMap(({ id, payload }) => {
      return this.formulaService.updateFormula(id, payload).pipe(
        tap(() => this.toaster.success('Booking formula updated successfully')),
        map((bookingFormula) =>
          UpdateBookingFormulaComplete({
            id,
            item: bookingFormula,
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  toggleStatus$ = this.actions$.pipe(
    ofType(ToggleBookingFormulaStatus),
    switchMap(({ id, status }) => {
      return (status ? this.formulaService.activateFormula(id) : this.formulaService.deactivateFormula(id)).pipe(
        tap(() => this.toaster.success(status ? 'Formula enabled successfully.' : 'Formula disabled successfully.')),
        map(() => ToggleBookingFormulaStatusComplete({ id, status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  toggleMultipleStatus$ = this.actions$.pipe(
    ofType(ToggleMultipleBookingFormulaStatus),
    switchMap(({ ids, status }) => {
      const message = status ? 'Formulas enabled successfully.' : 'Formulas disabled successfully.'
      return (
        status ? this.formulaService.activateMultipleFormulas(ids) : this.formulaService.deactivateMultipleFormulas(ids)
      ).pipe(
        tap(() => this.toaster.success(message)),
        switchMap((formulas) =>
          formulas.map((each) =>
            ToggleMultipleBookingFormulaStatusComplete({
              updates: ids.map((id) => ({
                id,
                changes: { status: status ? 1 : 0 },
              })),
            })
          )
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  remove$ = this.actions$.pipe(
    ofType(RemoveBookingFormula),
    switchMap(({ id }) => {
      return this.formulaService.deleteFormula(id).pipe(
        tap(() => this.toaster.success('Formula deleted successfully.')),
        map(() => RemoveBookingFormulaComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  removes$ = this.actions$.pipe(
    ofType(RemoveBookingFormulas),
    switchMap(({ ids }) => {
      return this.formulaService.deleteMultipleFormulas(ids).pipe(
        tap(() => this.toaster.success('Formulas deleted successfully.')),
        map(() => RemoveBookingFormulasComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(private actions$: Actions, private formulaService: BookingFormulaService, private toaster: Toaster) {}
}
