import { Message, MessageType } from '@tv3/store/message/message.model'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { isUUID } from '@tokeet-frontend/tv3-platform'

export class MessageDetails {
  id: string
  from: string
  to: string
  cc: string
  bcc: string
  receivedOn: number
  subject: string

  status: string
  spamScore: number
  spamFlag: string

  constructor(message: Message, inquiry: Inquiry) {
    this.id = message.id
    this.from = message.from
    if (message.type === MessageType.Outbound) {
      this.to = R.pathOr('', ['guest', 'primaryEmail'], inquiry)
    } else {
      this.from = message.replyTo
      this.to = message.sentTo
    }

    this.cc = lodash.isString(message.cc) ? message.cc : (message.cc || []).join(', ')
    this.bcc = lodash.isString(message.bcc) ? message.bcc : (message.bcc || []).join(', ')
    this.receivedOn = message.receivedOn
    this.subject = message.subject

    this.status = isUUID(message.id) ? 'Sent' : 'Sending'
    this.spamScore = lodash.get(message, 'spam_score.X-Mailgun-Sscore', 0)
    this.spamFlag = lodash.get(message, 'spam_score.X-Mailgun-Sflag', 'No')
  }
}
