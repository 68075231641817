import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { isSomething, selectSomeOnce, User } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { selectActivePlanStatusView } from '@tv3/store/plan/plan.selectors'
import { userRoleToString } from '@tv3/utils/functions/user-role-to-string'
import * as moment from 'moment'
import * as R from 'ramda'

declare const window: any

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor(private store: Store<any>) {}

  logEvent(event: string) {
    if (environment.environment === 'production') {
      window.amplitude?.getInstance()?.logEvent(event)
    } else {
      console.log(event)
    }
  }

  logPageView() {
    if (environment.environment === 'production') {
      window.amplitude?.getInstance()?.logEvent('pageview', { url: window.location.pathname })
    }
  }

  setUser(user: User) {
    if (environment.environment !== 'production' || !isSomething(user)) {
      return
    }

    window.amplitude?.getInstance()?.setUserId(user.id)

    this.store.pipe(selectSomeOnce(selectActivePlanStatusView)).subscribe((status) => {
      const payload = {
        user_id: user.id,
        user_email: user.primaryEmail,
        user_name: `${user.firstName} ${user.lastName}`,
        user_phone: user.phone,
        user_role: userRoleToString(user.roles),
        user_created_at: user.created,
        account: user.account,
        subscription: status?.planName,
        subscription_status:
          status.status === 'inactive' ? (status.delinquent ? 'past_due' : 'inactive') : status.status,
        subscription_cycle_start_at: status.start,
        subscription_cycle_end_at: status.until,
        subscription_updated_at: status.timestamp,
        trial: status?.trial_end > moment().unix(),
        trial_end_at: status.trial_end,
        rental_count: R.pathOr(0, ['rentals'], status),
        user_count: R.pathOr(0, ['users'], status),
      }

      try {
        const identify = new window.amplitude.Identify()

        R.forEach((key) => {
          identify.set(key, payload[key])
        }, R.keys(payload))

        window.amplitude?.getInstance()?.identify(identify)
      } catch (e) {
        console.log('error logging to amplitude')
      }
    })
  }

  logOut() {
    if (environment.environment !== 'production') {
      return
    }

    window.amplitude?.getInstance()?.setUserId(null)
  }
}
