import {
  DeleteRoomComplete,
  GetRentalRoomsComplete,
  UpdateRoomComplete,
  UpsertRoomsComplete,
  LoadRoomsComplete,
  DeleteRoomsComplete,
} from './rooms.actions'
import { adapter, initialState, State } from './rooms.state'
import { Action, createReducer, on } from '@ngrx/store'
import { updateOne } from '@tokeet-frontend/tv3-platform'
import { upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadRoomsComplete, (state, { rooms }) => adapter.setAll(rooms, { ...state, isLoaded: true })),
  on(GetRentalRoomsComplete, (state, { rooms }) => upsertMany(adapter, rooms, state)),
  on(UpdateRoomComplete, (state, { update }) => updateOne(adapter, update, state)),
  on(UpsertRoomsComplete, (state, { rooms }) => upsertMany(adapter, rooms, state)),
  on(DeleteRoomComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(DeleteRoomsComplete, (state, { ids }) => adapter.removeMany(ids, state))
)

export function roomsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
