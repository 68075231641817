import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as R from 'ramda'

import * as fromRoot from '@tv3/store/state'

/* Store Rental */
import { Rental, updateRentalCustomFields } from '@tokeet-frontend/tv3-platform'
import { isSavingCustom } from '@tokeet-frontend/tv3-platform'

/* Decorators */
import { SaveForm } from '@tokeet-frontend/tv3-platform'

/* Utils */
import { Destroyable } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-rental-details-custom-info',
  templateUrl: './rental-details-custom-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rental-details-custom-info.component.scss'],
})
export class RentalDetailsCustomInfoComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental

  form = this.fb.group({
    custom1: ['', []],
    custom2: ['', []],
    custom3: ['', []],
    custom4: ['', []],
    custom5: ['', []],
  })

  isFormInitialized = false

  isSavingCustom$ = this.store.pipe(select(isSavingCustom))

  constructor(private fb: FormBuilder, private store: Store<fromRoot.State>) {
    super()
  }

  ngOnInit() {
    this.setForm(this.rental)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rental = R.pathOr(this.rental, ['rental', 'currentValue'], changes)
    this.setForm(this.rental)
  }

  save() {
    this.onSave(this.form)
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const formValues = form.getRawValue()
    this.store.dispatch(updateRentalCustomFields({ rental: this.rental, form: formValues }))
  }

  private setForm(rental: Rental) {
    if (this.isFormInitialized) {
      return
    }
    this.isFormInitialized = true
    this.form.patchValue({
      custom1: rental.custom1,
      custom2: rental.custom2,
      custom3: rental.custom3,
      custom4: rental.custom4,
      custom5: rental.custom5,
    })
  }
}
