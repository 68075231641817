import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { countries, getCountryByCode, getCountryCode } from '../../constants'

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit, OnDestroy {
  countries = countries

  @Input() codeCtrl = new FormControl()
  @Input() nameCtrl = new FormControl()
  @Input() inline = false
  @Input() required = false
  @Input() label = 'Country'
  @Input() showBlankItem = true

  destroy$ = new Subject<void>()
  constructor() {}

  ngOnInit(): void {
    this.codeCtrl?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((code) => {
      this.nameCtrl?.setValue(getCountryByCode(code), { emitEvent: false })
    })
    this.nameCtrl?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((name) => {
      this.codeCtrl?.setValue(getCountryCode(name), { emitEvent: false })
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
