import { Pipe, PipeTransform } from '@angular/core'
import { isNumber } from 'lodash'
import * as moment from 'moment'

@Pipe({ name: 'isTimeStartOf' })
export class IsTimeStartOfPipe implements PipeTransform {
  transform(value: any, startOfUnit: moment.unitOfTime.StartOf, compareUnit: moment.unitOfTime.StartOf = 'date'): any {
    return transform(value, startOfUnit, compareUnit, false)
  }
}

@Pipe({ name: 'isUTCTimeStartOf' })
export class IsUTCTimeStartOfPipe implements PipeTransform {
  transform(value: any, startOfUnit: moment.unitOfTime.StartOf, compareUnit: moment.unitOfTime.StartOf = 'date'): any {
    return transform(value, startOfUnit, compareUnit, true)
  }
}

function transform(
  value: any,
  startOfUnit: moment.unitOfTime.StartOf,
  compareUnit: moment.unitOfTime.StartOf = 'date',
  utc = false
) {
  let m: moment.Moment

  const toMoment = utc ? moment.utc : moment

  if (isNumber(value)) {
    m = toMoment(value * 1000)
  } else if (moment.isMoment(value)) {
    m = value
  } else {
    m = toMoment(value)
  }

  return !m || !m.isValid() ? false : m.clone().startOf(startOfUnit).isSame(m, compareUnit)
}
