import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import {
  ExpenseView,
  CreateExpensePayload,
  ExpenseResponse,
  IExpenseFilters,
  UpdateExpensePayload,
  UpdateExpenseSettingsPayload,
  ExpenseApprovalSettings,
  FetchExpenseStatsReportParams,
  ExpenseCategoryReportItem,
  ExpensePaidStatus,
} from './expense.model'
import { catchError, concatMap, map, mergeMap, tap, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import * as lodash from 'lodash'
import { Toaster } from '../../services/toaster.service'
import { epochToViewUTC, isSomething } from '../../functions'
import { methods } from './expense.constants'
import { Pagination } from '../../types/pagination'
import { createdBySystemNameMappings } from '../../constants'

@Injectable()
export class ExpenseService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all(request?: IExpenseFilters, pagination?: Pagination): Observable<ExpenseView[]> {
    const url = `@api/expense/all/`

    pagination = pagination || { skip: 0, limit: 1000 }

    const filters: {
      due_from?: number
      due_to?: number
      method?: string
      status?: string | number
      rentals?: string | string[]
    } = {}

    if (request) {
      if (isSomething(request.period) && request.period.from > 0 && request.period.to > 0) {
        filters.due_from = request.period.from - 24 * 60 * 60
        filters.due_to = request.period.to + 24 * 60 * 60
      }
      if (isSomething(request.method) && request.method !== 'all') {
        filters.method = request.method
      }
      if (isSomething(request.status) && request.status !== 'all') {
        filters.status = request.status
      }
      if (isSomething(request.rentals)) {
        filters.rentals = request.rentals
      }
    }

    // sor by expense date
    const params = new HttpParams({
      fromObject: { skip: pagination.skip + '', limit: pagination.limit + '', sort: 'date', direction: '-1' },
    })

    return this.http
      .post<ExpenseResponse[]>(url, { ...filters }, { params })
      .pipe(map((responses) => this.mapExpenses(responses)))
  }

  get(id: string): Observable<ExpenseView> {
    const url = `@api/expense/${id}`

    return this.http.get<ExpenseResponse>(url).pipe(map((response) => this.buildExpense(response)))
  }

  getByIds(ids: string[]): Observable<ExpenseView[]> {
    return of(...ids).pipe(
      concatMap((id) => this.get(id).pipe(catchError(() => of(null)))),
      toArray(),
      map((items) => items.filter((t) => !lodash.isNil(t)) as ExpenseView[])
    )
  }

  create(request: CreateExpensePayload): Observable<ExpenseView> {
    const url = '@api/expense/'

    return this.http.post<ExpenseResponse>(url, request).pipe(
      map((response) => this.buildExpense(response)),
      tap(() => this.toast.success('Expense created successfully.'))
    )
  }

  createBatch(request: CreateExpensePayload[]): Observable<ExpenseView[]> {
    const url = '@api/expenses/'

    return this.http.post<ExpenseResponse[]>(url, request).pipe(map((responses) => this.mapExpenses(responses)))
  }

  update(expenseId: string, payload: UpdateExpensePayload): Observable<ExpenseView> {
    const url = `@api/expense/${expenseId}`

    return this.http.put<ExpenseResponse>(url, payload).pipe(
      map((response) => this.buildExpense(response)),
      tap(() => this.toast.success('Expense updated successfully.'))
    )
  }

  delete(expenseId: string): Observable<ExpenseView> {
    const url = `@api/expense/delete/${expenseId}`

    return this.http.delete<ExpenseResponse>(url).pipe(
      map((response) => this.buildExpense(response)),
      tap(() => this.toast.success('Expense deleted successfully.'))
    )
  }

  deleteExpenses(expenseIds: string[]): Observable<string[]> {
    return of(...expenseIds).pipe(
      mergeMap((id) => this.http.delete(`@api/expense/delete/${id}`).pipe(map(() => id))),
      toArray(),
      tap(() => this.toast.success('Expenses deleted successfully!'))
    )
  }

  private mapExpenses(expenses: ExpenseResponse[]): ExpenseView[] {
    return R.map((r: ExpenseResponse) => this.buildExpense(r), expenses)
  }

  private buildExpense(expense: ExpenseResponse): ExpenseView {
    return {
      ...expense,
      photo: expense.photo && (expense.photo + '').replace('http:', ''),
      isAuto: !!createdBySystemNameMappings[expense.created_by],
      createdView: epochToViewUTC(expense.created),
      dateView: epochToViewUTC(expense.date),
      dueView: epochToViewUTC(expense.due),
      methodView: lodash.capitalize(this.getExpenseMethod(expense)),
      statusView: this.getExpenseStatus(expense.status),
    } as ExpenseView
  }

  getExpenseStatus(status: ExpensePaidStatus): string {
    let statusText = '...'

    switch (status) {
      case 1:
        statusText = 'Unpaid'
        break
      case 2:
        statusText = 'Paid'
        break
      default:
        statusText = 'Unpaid'
    }

    return statusText
  }

  private getExpenseMethod(expense: ExpenseResponse): string {
    return R.pipe(
      R.find((m: { name: string; value: string }) => m.value === R.prop('method', expense)),
      R.prop('name'),
      R.defaultTo('Unknown')
    )(methods)
  }

  getCategoryReports(
    params: Omit<FetchExpenseStatsReportParams, 'statuses' | 'approval_statuses'>
  ): Observable<Record<string, ExpenseCategoryReportItem[]>> {
    return this.http
      .post<
        {
          _id: { date: string; category: string }
          amount: number
        }[]
      >('@api/reports/expenses/dates', params)
      .pipe(
        map((items) => items.map(({ _id, amount }) => ({ category: _id.category, date: _id.date, amount }))),
        map((items) => lodash.groupBy(items, 'category'))
      )
  }

  getStatusReports(params: Omit<FetchExpenseStatsReportParams, 'approval_statuses' | 'categories'>) {
    return this.http
      .post<{ _id: number; amount: number }[]>('@api/reports/expenses/dates', params)
      .pipe(map((items) => items.map((t) => ({ status: t._id, amount: t.amount }))))
  }

  getApprovalReports(params: Omit<FetchExpenseStatsReportParams, 'statuses' | 'categories'>) {
    return this.http
      .post<{ _id: string; amount: number }[]>('@api/reports/expenses/dates', params)
      .pipe(map((items) => items.map((t) => ({ status: t._id, amount: t.amount }))))
  }

  getSettings() {
    const url = `@api/expensesettings`

    return this.http.get<ExpenseApprovalSettings>(url)
  }

  updateSettings(payload: UpdateExpenseSettingsPayload) {
    const url = `@api/expensesettings`

    return this.http.put<ExpenseApprovalSettings>(url, payload)
  }
}
