<form [formGroup]="form" [formEditablePermission]="form" class="d-flex flex-column gap-4">
  <div class="d-flex">
    <app-form-switch
      [formControl]="repeatableCtrl"
      labelPosition="before"
      [inline]="true"
      [checkedValue]="1"
      [uncheckedValue]="0"
      ><span class="text-bold text-uppercase">Enable Recurring</span></app-form-switch
    >
  </div>
  <div class="">
    <div class="text-uppercase text-bold mb-3">Repeat Every</div>
    <div class="d-flex align-items-center gap-3">
      <mat-form-field2 [showLabel]="false">
        <input type="number" matInput formControlName="interval" placeholder="Interval" required />
      </mat-form-field2>
      <mat-form-field2 [showLabel]="false">
        <mat-select formControlName="frequency">
          <mat-option [value]="item" *ngFor="let item of repeatIntervalOptions" class="text-capitalize">
            {{ item | titleCase }}
          </mat-option>
        </mat-select>
      </mat-form-field2>
    </div>
  </div>
  <div class="" *ngIf="form.get('frequency').value === 'WEEKLY'">
    <div class="text-uppercase text-bold mb-3">Repeat On</div>
    <ng-container *ngFor="let item of weekDayOptions; let index = index">
      <button
        class="btn btn-circle mr-3"
        type="button"
        [disabled]="form.disabled"
        [ngClass]="isWeekdaySelected(index + 1) ? 'btn-primary' : 'btn-outline-light'"
        (click)="onWeekdayClick(index + 1)"
        matTooltip="Click here to select/deselect specific day"
        matTooltipPosition="above"
        [matTooltipDisabled]="form.disabled"
      >
        {{ item }}
      </button>
    </ng-container>
  </div>
  <div class="">
    <div class="text-uppercase text-bold mb-3">Ends</div>
    <mat-radio-group formControlName="end" class="d-flex flex-column align-items-start gap-3">
      <mat-radio-button value="" *ngIf="isUnlimitedEnabled">Never</mat-radio-button>
      <mat-radio-button value="on">
        <div class="d-grid align-items-center" style="grid-template-columns: 60px 1fr">
          <div class="">On</div>
          <div class="">
            <mat-form-field2 [showLabel]="false" style="width: 14rem">
              <input matInput [matDatepicker]="endDatePicker" formControlName="until" placeholder="Date" readonly />
              <i matSuffix (click)="endDatePicker.open()" class="cs-icon cs-icon-calendar"></i>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="form.get('until').touched && form.get('until').hasError('required')"
                >Date is required</mat-error
              >
            </mat-form-field2>
          </div>
        </div>
      </mat-radio-button>
      <mat-radio-button value="after">
        <div class="d-grid align-items-center" style="grid-template-columns: 60px 1fr">
          <div class="">After</div>
          <div class="">
            <mat-form-field2 [showLabel]="false" [hidePlaceholder]="true" style="width: 14rem">
              <input type="number" matInput formControlName="count" placeholder="Occurrence" />
              <span matSuffix>Occurrences</span>
              <mat-error *ngIf="form.get('count').touched && form.get('count').hasError('required')"
                >Occurrence is required</mat-error
              >
            </mat-form-field2>
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</form>
