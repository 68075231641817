import { from, Observable, OperatorFunction } from 'rxjs'
import { filter, flatMap, map, toArray } from 'rxjs/operators'
import { Serializable } from './serializer'
import { isSomething } from './is-something'

export function deserializeArray<T extends Serializable<T>>(type: {
  deserialize: (data: object) => T
}): OperatorFunction<object[], T[]> {
  return function (source$: Observable<object[]>): Observable<T[]> {
    return source$.pipe(
      filter((response) => isSomething(response)),
      flatMap((response: object[]) => from(response)),
      map((response) => type.deserialize(response)),
      toArray()
    )
  }
}
