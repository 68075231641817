<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center flex-fill gap-3">
        <div class="d-flex ali-items-center gap-2">
          <h6 class="modal-title mb-0">Messaging Settings</h6>
          <app-page-help articleId="-" videoId="-"></app-page-help>
        </div>
        <div class="flex-fill"></div>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="fas fa-times"></span>
      </a>
    </div>
    <ul class="nav top-navbar bg-white border-bottom" role="tablist">
      <li [class.active]="activeTab === tabs.Channels">
        <a href="javascript:;" (click)="activeTab = tabs.Channels"> Channels </a>
      </li>
      <li [class.active]="activeTab === tabs.Charges" *ngIf="isMessagingSubscribed">
        <a href="javascript:;" (click)="activeTab = tabs.Charges"> Credit Recharge </a>
      </li>
    </ul>
    <div class="modal-body">
      <ng-container [ngSwitch]="activeTab">
        <ng-template [ngTemplateOutlet]="channels" *ngSwitchCase="tabs.Channels"></ng-template>
        <ng-template [ngTemplateOutlet]="charges" *ngSwitchCase="tabs.Charges"></ng-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #channels>
  <div class="d-grid gap-3" style="grid-template-columns: repeat(auto-fit, minmax(350px, 1fr))">
    <div *ngFor="let item of items" class="container-box p-3">
      <div class="d-flex align-items-center mb-2">
        <div class="text-bold d-flex align-items-center gap-2">
          <img [src]="item.logo" alt="" height="25" />
          {{ item.label }}
        </div>
        <div class="flex-fill"></div>
        <ng-container *ngIf="item.isSubscriptionRequired && !isMessagingSubscribed">
          <button (click)="onSubscribe()" type="button" class="btn btn-secondary-info" [disabled]="1 | isReadonlyRole">
            Subscribe
          </button>
        </ng-container>
        <ng-container *ngIf="item.isSubscriptionRequired && isMessagingSubscribed">
          <button type="button" class="btn btn-primary">Subscribed</button>
        </ng-container>

        <ng-container *ngIf="item.oauth">
          <button
            (click)="onConnect(item.channel)"
            type="button"
            class="btn btn-secondary-info"
            [disabled]="1 | isReadonlyRole"
            *ngIf="!channelConfig?.[item.channel]?.enabled"
          >
            Connect
          </button>
          <button
            (click)="onDisconnect(item.channel)"
            type="button"
            class="btn btn-secondary-danger"
            [disabled]="1 | isReadonlyRole"
            *ngIf="channelConfig?.[item.channel]?.enabled"
          >
            Disconnect
          </button>
        </ng-container>
      </div>
      <div>{{ item.description }}</div>
    </div>
  </div>
</ng-template>

<ng-template #charges>
  <app-sms-auto-credit-recharge class="container-box p-3"></app-sms-auto-credit-recharge>
</ng-template>
