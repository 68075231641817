import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromConnection from './connection.reducer'
import { EffectsModule } from '@ngrx/effects'
import { ConnectionEffects } from './connection.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('connection', fromConnection.connectionReducer),
    EffectsModule.forFeature([ConnectionEffects]),
  ],
  declarations: [],
})
export class ConnectionModule {}
