import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'

import { CalendarModule as NgxMultiEventCalendarModule } from '@tokeet/ngx-multi-event-calendar'

import { DateRangeSelectComponent } from './date-range-select/date-range-select.component'
import { ShareCalendarDialogComponent } from './share-calendar-dialog/share-calendar-dialog.component'
import { DefaultCalendarComponent } from './default-calendar/default-calendar.component'
import { BookingDetailsPopupComponent } from './booking-details-popup/booking-details-popup.component'
import { BookingDetailsDirective } from './booking-details-popup/booking-details.directive'
import { BookingDetailsPopupService } from '@tv3/containers/calendar/shared/booking-details-popup/booking-details-popup.service'
import { UsersLibModule } from '@tokeet-frontend/users'
import { CalendarNavGroupComponent } from './calendar-nav-group/calendar-nav-group.component'
import { InquirySharedModule } from '@tv3/containers/inquiries/overlays/inquiry-overlay/inquiry-shared.module'
import { RentalsLibraryModule } from '@tokeet-frontend/rentals'
import { CalendarMultiComponent } from '../calendar-multi/calendar-multi.component'
import { RentalsSharedModule } from '@tv3/containers/rentals/rentals-shared.module'
import { NgxPermissionsModule } from 'ngx-permissions'
import { CalendarMultiOverlayComponent } from './calendar-multi-overlay/calendar-multi-overlay.component'
import { CalendarMultiOverlayService } from './calendar-multi-overlay/calendar-multi-overlay.service'
import { ImportCalendarDialogService } from './import-calendar-dialog/import-calendar-dialog.service'
import { ShareCalendarDialogService } from './share-calendar-dialog/share-calendar-dialog.service'
import { ImportCalendarDialogComponent } from './import-calendar-dialog/import-calendar-dialog.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'
import { HoldDialogComponent } from '../hold/hold-dialog/hold-dialog.component'
import { HoldDialogService } from '../hold/hold-dialog/hold-dialog.service'
import { HoldEventFormComponent } from '../hold/hold-event-form/hold-event-form.component'
import { ImportHoldsDialogComponent } from '../hold/import-holds-dialog/import-holds-dialog.component'
import { ActivityLibraryModule } from '@tokeet-frontend/activity'
import { CommentsLibraryModule } from '@tokeet-frontend/comments'

const components = [
  DateRangeSelectComponent,
  DefaultCalendarComponent,
  BookingDetailsDirective,
  CalendarNavGroupComponent,

  CalendarMultiComponent,
  CalendarMultiOverlayComponent,

  //
  ShareCalendarDialogComponent,
  ImportCalendarDialogComponent,
  HoldEventFormComponent,
  HoldDialogComponent,
  ImportHoldsDialogComponent,
  BookingDetailsPopupComponent,
]

const providers = [
  //

  HoldDialogService,

  BookingDetailsPopupService,
  CalendarMultiOverlayService,
  ShareCalendarDialogService,
  ImportCalendarDialogService,
]

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RentalsLibraryModule,
    UsersLibModule,
    InquirySharedModule,
    NgxMultiEventCalendarModule,
    RentalsSharedModule,
    RatesSharedModule,
    CommentsLibraryModule,
    ActivityLibraryModule,
    NgxPermissionsModule.forChild(),
  ],
  declarations: [...components],
  exports: [...components],
  providers: [...providers],
})
export class CalendarSharedModule {}
