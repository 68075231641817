import { Injectable } from '@angular/core'
import { CalendarMultiOverlayComponent } from './calendar-multi-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CalendarMultiOverlayService {
  constructor(private dialog: DialogService) {}

  open() {
    return this.dialog.openDrawer(CalendarMultiOverlayComponent, {
      panelClass: ['drawer-full-90'],
    })
  }
}
