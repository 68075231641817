import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { StealthTrigger } from './stealth.model'

export interface State extends EntityState<StealthTrigger> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<StealthTrigger> = createEntityAdapter<StealthTrigger>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
