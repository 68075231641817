import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { PhoneNumberRequestDialogComponent } from './phone-number-request-dialog.component'

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberRequestDialogService {
  constructor(private dialog: MatDialog) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      width: '500px',
      height: 'auto',
      data: {},
      panelClass: ['variable-modal'],
      disableClose: true,
    }

    return this.dialog.open(PhoneNumberRequestDialogComponent, defaultConfig)
  }
}
