<div class="table-holder table-responsive">
  <mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected(selection, dataSource.data)"
          [indeterminate]="selection.hasValue() && !isAllSelected(selection, dataSource.data)"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef> Image</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img [src]="getImageThumbUrl(element.secureUrl)" alt="" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="resolution">
      <mat-header-cell *matHeaderCellDef mat-sort-header="width"> Resolution</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="!!element.width"> {{ element.width }}x{{ element.height }} </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.created | epoch }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Size</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.size ? (element.size | fileSize) : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ getCategory(element.metadata?.category) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="text-truncate" [matTooltip]="element.metadata?.description">
          {{ element.metadata?.description }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef> Edit</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div dropdown placement="bottom right" container="body" [isDisabled]="1 | isReadonlyRole">
          <div dropdownToggle class="btn-group btn-ellips-holder">
            <a class="btn-ellips">
              <i class="fas fa-ellipsis-v"></i>
            </a>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <li *ngIf="!element?.primary">
              <button (click)="onPrimary(element)" type="button"><i class="fal fa-square"></i>Mark as Primary</button>
            </li>
            <li *ngIf="element?.primary">
              <button type="button"><i class="fal fa-check-square"></i> Primary Image</button>
            </li>
            <li>
              <button (click)="onOpenImage(element)" type="button"><i class="fal fa-link"></i> View Full Image</button>
            </li>
            <li>
              <button (click)="onDelete(element)" type="button"><i class="cs-icon cs-icon-trash"></i> Delete</button>
            </li>
          </ul>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator2
    #paginator
    [pageSize]="10"
    [length]="dataSource?.data?.length"
    [class.hidden]="isEmptyTable$ | async"
    [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>

  <ng-container *ngIf="isEmptyTable$ | async">
    <ng-content></ng-content>
  </ng-container>
</div>
