export enum MFADeliveryMethod {
  Email = 'email',
  SMS = 'sms',
  WhatsApp = 'whatsapp',
  TOTP = 'totp',
}

export const mfaDeliveryMethodOptions = [
  { id: MFADeliveryMethod.Email, label: 'Email' },
  { id: MFADeliveryMethod.SMS, label: 'SMS' },
  { id: MFADeliveryMethod.WhatsApp, label: 'WhatsApp' },
  { id: MFADeliveryMethod.TOTP, label: 'TOTP' },
]
