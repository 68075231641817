import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'

export class UserPreferences extends Serializable<UserPreferences> {
  @Expose({ name: 'pkey' })
  id: string

  @Expose({ name: 'user' })
  userId: string

  archived: number
  account: number
  created: number

  @Expose({ name: 'first_login' })
  firstLogin: number

  @Expose({ name: 'tv3_login' })
  tv3Login: boolean

  @Expose({ name: 'default_calendar' })
  defaultCalendar: string

  @Expose({ name: 'default_inbox' })
  defaultInbox: string

  @Expose({ name: 'default_task_view' })
  defaultTaskView: string

  @Expose({ name: 'default_route' })
  defaultRoute: string

  logins: number

  @Expose({ name: 'multi_calendar_row' })
  multiCalendarRow: number

  @Expose({ name: 'message_pins' })
  pinnedMessages: string[]

  @Expose({ name: 'inquiry_pins' })
  pinnedInquiries: string[]

  static deserialize(data: object): UserPreferences {
    return deserialize(UserPreferences, data)
  }
}
