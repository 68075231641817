<div class="details-box">
  <div class="filters-box">
    <app-select-search
      bindValue="propertyId"
      bindLabel="propertyId"
      [multiple]="false"
      [items]="connectedAccounts"
      [ctrl]="filtersForm.get('property')"
      placeholder="Property"
    ></app-select-search>
    <app-select-search
      bindValue="id"
      bindLabel="name"
      [multiple]="false"
      [items]="rooms"
      [ctrl]="filtersForm.get('room')"
      placeholder="Listing"
      [hasBlank]="true"
      blankLabel="All Listings"
      blankValue=""
    ></app-select-search>
    <mat-form-field2
      [showLabel]="false"
      matTooltip="Only show reviews submitted within selected dates."
      matTooltipPosition="above"
    >
      <app-mat-daterange-input
        placeholder="Date Range"
        [formControl]="filtersForm.get('period')"
        [local]="true"
      ></app-mat-daterange-input>
    </mat-form-field2>
    <app-cancel-filters
      [isFiltering]="filtersForm | isFiltering"
      [filterControl]="filtersForm"
      [searchCom]="search"
    ></app-cancel-filters>
    <div class="flex-fill"></div>
    <app-table-search (search)="onSearch($event)" #search></app-table-search>
  </div>
  <div class="table-holder table-responsive">
    <table class="table table-default table-striped">
      <app-loading-section *ngIf="isLoading; else table"></app-loading-section>
    </table>
  </div>
  <mat-paginator2
    #paginator
    [length]="totalCount"
    [class.hidden]="isEmptyTable$ | async"
    (page)="onPageChange($event)"
    [pageSize]="15"
    [pageSizeOptions]="[5, 10, 15, 20, 50, 75, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator2>
</div>

<ng-template #table>
  <mat-table
    matSort
    matSortActive="submittedDate"
    matSortDirection="desc"
    [dataSource]="dataSource"
    multiTemplateDataRows
    [ngClass]="{ 'rating-wide': maxRating > 5 }"
  >
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <div (click)="onExpandRow(row)" class="p-3" *ngIf="row.response?.text">
          <i class="far" [ngClass]="row.reviewId === expandedReviewId ? 'fa-angle-down' : 'fa-angle-right'"></i>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="submittedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.submittedDate | epoch }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="roomName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Property Name</mat-header-cell>
      <mat-cell *matCellDef="let element" class="text-truncate" [title]="element.roomName">
        <div [matTooltip]="element.roomName" class="text-truncate">
          {{ element.roomName }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="propertyId">
      <mat-header-cell *matHeaderCellDef> Property ID</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.propertyId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="roomId">
      <mat-header-cell *matHeaderCellDef> Room ID</mat-header-cell>
      <mat-cell *matCellDef="let element" style="word-break: break-all">
        <div [matTooltip]="element.roomId" class="text-truncate">
          {{ element.roomId }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="review">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Review</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div [matTooltip]="element.review" class="text-truncate">
          {{ element.review | truncate : 100 }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="likes">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Likes</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div [matTooltip]="element.likes" class="text-truncate">
          {{ element.likes }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dislikes">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Dislikes</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div [matTooltip]="element.dislikes" class="text-truncate">
          {{ element.dislikes }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rating">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Rating</mat-header-cell>
      <mat-cell *matCellDef="let element" [matTooltip]="element.rating + '/' + maxRating">
        <app-rating [maxRating]="maxRating" [rating]="element.rating"></app-rating>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell *matCellDef="let element" class="clickable py-3">
        <div
          class="text-info d-flex"
          *ngIf="canReply | call : element"
          (click)="onReply(element)"
          style="font-size: 35px"
        >
          <i class="cs-icon cs-icon-reply-text"></i>
        </div>
        <div
          class="text-primary d-flex"
          (click)="onExpandRow(element)"
          *ngIf="element.response"
          style="font-size: 40px"
        >
          <i class="cs-icon cs-icon-replied-text"></i>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="response">
      <mat-cell *matCellDef="let review" [attr.colspan]="displayedColumns.length" class="px-0">
        <div class="border m-3 ml-5 w-100 bg-white p-3 rounded" *ngIf="expandedReviewId === review.reviewId">
          <app-review-category-tags [review]="review" class="mb-2"></app-review-category-tags>
          <div class="border-bottom pb-3 mb-3">
            <div>{{ review.text }}</div>
            <div *ngIf="review.positiveText" class="text-success">Likes: {{ review.positiveText }}</div>
            <div *ngIf="review.negativeText" class="text-warning">Dislikes: {{ review.negativeText }}</div>
          </div>
          <div class="d-flex align-items-center gap-2 mb-3">
            <app-avatar-name
              *ngIf="review.reviewee?.name || review.reviewee?.role; let name"
              [name]="name | titleCase"
            ></app-avatar-name>
            <div
              class="text-muted"
              *ngIf="review.response?.respondedAt || review.response?.lastChangeAt; let respondedAt"
            >
              {{ respondedAt | date : 'medium' }}
            </div>
          </div>
          <div>
            {{ review.response?.text }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    <mat-row *matRowDef="let row; columns: ['response']" [class.d-none]="row.reviewId !== expandedReviewId"></mat-row>
  </mat-table>
  <app-empty-table *ngIf="isEmptyTable$ | async" [type]="tableType"></app-empty-table>
</ng-template>
