import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { VrboFeesDialogComponent } from './vrbo-fees-dialog.component'

@Injectable()
export class VrboFeesDialogService {
  constructor(private dialog: DialogService) {}

  open(rentalId: string) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      data: { rentalId },
    }

    return this.dialog.openFixedDialog(VrboFeesDialogComponent, defaultConfig)
  }
}
