<div class="modal-header">
  <div class="d-flex align-items-center gap-2">
    <div class="modal-title">Connect To Agoda</div>
    <app-page-help [articleId]="'-'" videoId="-"></app-page-help>
  </div>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>

<mat-stepper [linear]="true" #stepper class="m-3">
  <mat-step
    label="Getting Started"
    [editable]="stepper.selectedIndex < 3"
    [state]="stepper.selectedIndex > 0 ? 'done' : 'number'"
  >
    <app-connect-agoda-wizard-step1 (next)="stepper.next()"></app-connect-agoda-wizard-step1>
  </mat-step>
  <mat-step
    label="Preparation"
    [optional]="true"
    [editable]="stepper.selectedIndex < 3"
    [state]="stepper.selectedIndex > 1 ? 'done' : 'number'"
  >
    <app-connect-agoda-wizard-step2
      (prev)="stepper.previous()"
      (next)="stepper.next()"
      #step2
    ></app-connect-agoda-wizard-step2>
  </mat-step>
  <mat-step
    label="Request Channel Manager Access"
    [optional]="true"
    [editable]="stepper.selectedIndex < 3"
    [state]="stepper.selectedIndex > 2 ? 'done' : 'number'"
  >
    <app-connect-agoda-wizard-step3
      (prev)="stepper.previous()"
      [channel]="channel"
      (connected)="stepper.next()"
      #step3
    ></app-connect-agoda-wizard-step3>
  </mat-step>
  <mat-step
    label="Complete"
    [editable]="stepper.selectedIndex < 3"
    [state]="stepper.selectedIndex > 3 ? 'done' : 'number'"
  >
    <app-connect-agoda-wizard-complete
      (reset)="stepper.reset(); step2.reset(); step3.reset()"
      (channel)="onChannel()"
      (done)="close()"
    ></app-connect-agoda-wizard-complete>
  </mat-step>
  <ng-template matStepperIcon="done">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <i class="cs-icon cs-icon-check2 text-xs"></i>
  </ng-template>
</mat-stepper>
