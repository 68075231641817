import { adapter, initialState, State } from './channel-property-setting.state'
import { updateOne, upsertMany } from '@tokeet-frontend/tv3-platform'
import { Action, createReducer, on } from '@ngrx/store'
import {
  CreateChannelPropertySettingComplete,
  DeleteChannelPropertySettingComplete,
  LoadChannelPropertySettingsComplete,
  UpdateChannelPropertySettingComplete,
} from './channel-property-setting.actions'

export const reducer = createReducer(
  initialState,
  on(LoadChannelPropertySettingsComplete, (state, { items }) =>
    upsertMany(adapter, items, {
      ...state,
      isLoaded: true,
    })
  ),
  on(CreateChannelPropertySettingComplete, (state, { item }) => adapter.addOne(item, state)),
  on(UpdateChannelPropertySettingComplete, (state, { update }) => updateOne(adapter, update, state)),
  on(DeleteChannelPropertySettingComplete, (state, { id }) => adapter.removeOne(id, state))
)

export function channelPropertySettingReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
