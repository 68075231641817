export enum AirbnbHost2GuestReviewCategories {
  Communication = 'communication',
  Cleanliness = 'cleanliness',
  RespectHouseRules = 'respect_house_rules',
}

export enum AirbnbGuest2HostReviewCategories {
  Accuracy = 'accuracy',
  Cleanliness = 'cleanliness',
  Checkin = 'checkin',
  Communication = 'communication',
  Location = 'location',
  Value = 'value',
}

export const airbnbHost2GuestReviewCategoryTags = [
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_NEAT_AND_TIDY',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Neat & tidy',
  },
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_KEPT_IN_GOOD_CONDITION',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Kept in good condition',
  },
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_TOOK_CARE_OF_GARBAGE',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Took care of garbage',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_IGNORED_CHECKOUT_DIRECTIONS',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Ignored check-out directions',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_GARBAGE',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Excessive garbage',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_MESSY_KITCHEN',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Messy kitchen',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_DAMAGE',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Damaged property',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_RUINED_BED_LINENS',
    category: AirbnbHost2GuestReviewCategories.Cleanliness,
    label: 'Ruined bed linens',
  },
  //
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_ARRIVED_EARLY',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Arrived too early',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_STAYED_PAST_CHECKOUT',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Stayed past checkout',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_GUESTS',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Unapproved guests',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_PET',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Unapproved pet',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_DID_NOT_RESPECT_QUIET_HOURS',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Didn’t respect quiet hours',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_FILMING',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Unapproved filming or photography',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNAPPROVED_EVENT',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Unapproved event',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_SMOKING',
    category: AirbnbHost2GuestReviewCategories.RespectHouseRules,
    label: 'Smoking',
  },
  //
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_HELPFUL_MESSAGES',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Helpful messages',
  },
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_RESPECTFUL',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Respectful',
  },
  {
    id: 'HOST_REVIEW_GUEST_POSITIVE_ALWAYS_RESPONDED',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Always responded',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNHELPFUL_MESSAGES',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Unhelpful responses',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_DISRESPECTFUL',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Disrespectful',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_UNREACHABLE',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Unreachable',
  },
  {
    id: 'HOST_REVIEW_GUEST_NEGATIVE_SLOW_RESPONSES',
    category: AirbnbHost2GuestReviewCategories.Communication,
    label: 'Slow responses',
  },
]

export interface AirbnbHost2GuestReviewNotSubmittedResponse {
  submitted: false
  id: string
  is_expired: 0 | 1
  is_responded: 0 | 1
  expires_at: string // "2023-06-13T01:11:00.626Z"
  hidden: boolean
}

export interface AirbnbCategoryRatingItem {
  rating: number // 1-5
  comment: string
  review_category_tags: string[]
  category: AirbnbHost2GuestReviewCategories | AirbnbGuest2HostReviewCategories
}

export interface AirbnbReviewSubmittedResponse {
  submitted: true
  id: string //"827230969726879084"
  is_expired: 0 | 1
  is_responded: 0 | 1
  category_ratings: AirbnbCategoryRatingItem[]
  public_review: string //"большой молодец",
  is_reviewee_recommended: boolean
  private_feedback: string //"красавелло",
  overall_rating: number
  reviewee_response: string
  responded_at: string // "2023-06-13T01:11:00.626Z"
  expires_at: string // "2023-06-13T01:11:00.626Z"
  submitted_at: string // "2023-06-13T01:11:00.626Z"
}

export type AirbnbHost2GuestReviewPayload = Omit<AirbnbReviewSubmittedResponse, 'submitted'>

export type AirbnbHost2GuestReviewResponse = AirbnbHost2GuestReviewNotSubmittedResponse | AirbnbReviewSubmittedResponse
