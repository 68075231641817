import { Rental } from '@tokeet-frontend/tv3-platform'

export type RentalsUnitedStatus = {
  connected: boolean
  deactivated?: {
    reason: 'wrong-credentials' //
    date: string
  }
}

export interface RentalUnitedCompanyDetails {
  contactInfo: {
    firstName: string
    lastName: string
    email: string
    phone: string
    birthDate: string
    city: string
    countryCode: string
    address: string
    zipCode: string
  }
  companyInfo: {
    companyName: string
    websiteAddress: string
    companyCity: string
    merchantName: string
    numberOfProperties?: number
    locationsCoordinates: {
      latitude: number
      longitude: number
    }[]
  }
}

export interface RentalsUnitedCancellationPolicy {
  validTo: number
  totalPricePercent: number
  validFrom: number
}

export class RentalsUnitedListingPayload {
  rentalId: string

  landlordName: string

  landlordEmail: string

  landlordPhone: string

  // @Min(1)
  daysBeforeArrival: number

  // @Min(0)
  depositAmount: number

  // @IsIn([1, 2, 3, 4, 5, 6])
  depositTypeId: number

  // @Min(1)
  standardGuests: number

  // @Min(1)
  floor: number

  checkInOutPlace: string
}

export interface RentalsUnitedListingModel extends RentalsUnitedListingPayload {
  rentalId: string
  propertyId: number
  lastSync: number
  lastRatePush?: number
  lastAvailabilityPush?: number
  lastAvailabilityPull?: number
  createdAt: number
  active: boolean
  cancellationPolicy: RentalsUnitedCancellationPolicy[]
}

export interface RentalsUnitedListingViewModel extends RentalsUnitedListingModel {
  rental: Rental
  rental_name: string
  statusText: string
  lastSyncView: string
}

export const rentalsUnitedDepositTypes = [
  { id: 1, label: 'No deposit' },
  { id: 2, label: 'Percentage of total price (without cleaning)' },
  { id: 3, label: 'Percentage of total price' },
  { id: 4, label: 'Fixed amount per day' },
  { id: 5, label: 'Flat amount per stay' },
  // { id: 6, label: 'Custom' },
]

export interface RentalsUnitedTokens {
  access_token: string
  refresh_token: string
}
