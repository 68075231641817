import { Pipe, PipeTransform } from '@angular/core'
import { Rental, isSomething } from '@tokeet-frontend/tv3-platform'
import { isNumber, isNaN } from 'lodash'
import { HomeAwayRentalConfigView } from '@tokeet-frontend/channels'

function isNameCorrectLength(rental?: Rental) {
  return rental?.displayName?.length >= 20 && rental?.displayName?.length <= 80
}

function isDescriptionCorrectLength(rental?: Rental) {
  return rental?.description?.length >= 400 && rental?.description?.length <= 10000
}

function hasEnoughPhotos(rental?: Rental) {
  return rental?.imageCount >= 6
}

function hasBedroomAndBathroom(rental?: Rental) {
  return (
    isNumber(rental?.bathrooms) && !isNaN(rental?.bathrooms) && isNumber(rental?.bedrooms) && !isNaN(rental?.bedrooms)
  )
}

function hasAddress(rental?: Rental) {
  return isSomething(rental?.address?.address)
}

function hasLatLong(rental?: Rental) {
  return isSomething(rental?.gps) && isSomething(rental?.gps.lat) && isSomething(rental?.gps.long)
}

@Pipe({
  name: 'vrboIsInvalidRental',
})
export class VrboIsInvalidRentalPipe implements PipeTransform {
  transform(config: HomeAwayRentalConfigView): boolean {
    return (
      !isNameCorrectLength(config.rental) ||
      !isDescriptionCorrectLength(config.rental) ||
      !hasEnoughPhotos(config.rental) ||
      !hasBedroomAndBathroom(config.rental) ||
      !hasAddress(config.rental) ||
      !hasLatLong(config.rental)
    )
  }
}

@Pipe({
  name: 'vrboIsRentalNameInvalid',
})
export class VrboIsRentalNameInvalidPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !isNameCorrectLength(rental.rental)
  }
}

@Pipe({
  name: 'vrboIsRentalDescriptionInvalid',
})
export class VrboIsRentalDescriptionInvalidPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !isDescriptionCorrectLength(rental.rental)
  }
}

@Pipe({
  name: 'vrboIsRentalFewPhotos',
})
export class VrboIsRentalFewPhotosPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !hasEnoughPhotos(rental.rental)
  }
}

@Pipe({
  name: 'vrboIsRentalBedroomBathroomInvalid',
})
export class VrboIsRentalBedroomBathroomInvalidPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !hasBedroomAndBathroom(rental.rental)
  }
}

@Pipe({
  name: 'vrboIsRentalAddressInvalid',
})
export class VrboIsRentalAddressInvalidPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !hasAddress(rental.rental)
  }
}

@Pipe({
  name: 'vrboIsRentalLatLongInvalid',
})
export class VrboIsRentalLatLongInvalidPipe implements PipeTransform {
  transform(rental: HomeAwayRentalConfigView): boolean {
    return !hasLatLong(rental.rental)
  }
}
