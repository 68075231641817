import { Directive, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import {
  asLocalDate,
  asUTCEpoch,
  ConfirmDialogService,
  Destroyable,
  isSomething,
  noAllWhitespaceValidator,
  SaveForm,
  selectAllRentals,
  selectChannelByName,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { observeOn, startWith } from 'rxjs/operators'
import { asyncScheduler, BehaviorSubject, combineLatest, merge } from 'rxjs'
import * as moment from 'moment'
import { omitViewFields, percent, positive } from '@tokeet-frontend/tv3-platform'
import {
  losTokeetChannel,
  availableLosDiscountModalities,
  AddLosDiscount,
  LosDiscount,
  DeleteLosDiscount,
  LosDiscountModality,
  LosDiscountType,
  LosDiscountView,
} from '@tokeet-frontend/los-discount'
import { environment } from '@tv3/environments/environment'

@Directive()
export abstract class LosDiscountFormComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    type: [LosDiscountType.Percent, [Validators.required]],
    modality: [LosDiscountModality.PerStay, [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(50), noAllWhitespaceValidator]],
    description: ['', [Validators.required, Validators.maxLength(150)]],
    amount: [undefined, [Validators.required]],
    length: [undefined, [Validators.required, Validators.min(1)]],
    guests: [undefined, [Validators.required, Validators.min(1)]],
    rentals: [[]],
    channels: [[]],
    start: [],
    end: [],
  })

  get typeCtrl() {
    return this.form.get('type')
  }

  get amountCtrl() {
    return this.form.get('amount')
  }

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  channels = [losTokeetChannel]
  now = new Date()
  hasModality = environment.features.losModality

  modalities = LosDiscountModality
  modalityOptions = availableLosDiscountModalities
  minEndDate = new BehaviorSubject<Date>(this.now)
  maxStartDate = new BehaviorSubject<Date>(null)

  isEdit = false

  item: Partial<LosDiscountView>

  constructor(private fb: FormBuilder, private store: Store<any>, private confirmDialog: ConfirmDialogService) {
    super()
  }

  ngOnInit() {
    merge(this.form.get('start').valueChanges, this.form.get('end').valueChanges)
      .pipe(startWith(null), untilDestroy(this))
      .subscribe(() => {
        this.setStartEndMinMax()
      })

    this.typeCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((value) => {
      if (value === 'pct') {
        this.amountCtrl.setValidators([Validators.required, percent()])
      } else {
        this.amountCtrl.setValidators([Validators.required, positive()])
      }
      this.amountCtrl.updateValueAndValidity()
    })

    combineLatest([this.store.pipe(select(selectChannelByName, { name: 'webready' }))])
      .pipe(untilDestroy(this))
      .subscribe(([webready]) => {
        this.channels = [losTokeetChannel, webready]
      })

    this.setForm(this.item)
  }

  setStartEndMinMax() {
    if (this.form.get('start').value) {
      const start = moment(this.form.get('start').value).add(1, 'days').startOf('day')
      this.minEndDate.next(start.toDate() < this.now ? this.now : start.toDate())
    }

    if (this.form.get('end').value) {
      const end = moment(this.form.get('end').value).subtract(1, 'days').endOf('day')
      this.maxStartDate.next(end.toDate())
    }
  }

  abstract close(): void

  @SaveForm()
  onSave(form: FormGroup) {
    const formData = form.getRawValue()
    if (this.isEdit) {
    } else {
      this.store.dispatch(
        AddLosDiscount({
          payload: {
            ...formData,
            start: asUTCEpoch(formData.start),
            end: asUTCEpoch(formData.end),
          } as LosDiscount,
        })
      )
    }
    this.close()
  }

  onDelete() {
    this.confirmDialog
      .confirm({
        title: 'Delete discount?',
        body: 'Are you sure you want to delete this discount?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteLosDiscount({ id: this.item.id }))
        this.close()
      })
  }

  private setForm(item?: Partial<LosDiscount>) {
    if (isSomething(item)) {
      this.form.patchValue({
        ...omitViewFields(item),
        modality: item.modality || LosDiscountModality.PerStay,
        start: item.start && asLocalDate(item.start),
        end: item.end && asLocalDate(item.end),
      })
      this.isEdit = !!item.id
    }
    if (this.isEdit) {
      this.form.disable()
    }
  }
}
