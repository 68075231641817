import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Channel, isSomething, PromptDialogService, Rental, Toaster } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder } from '@angular/forms'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { filter, switchMap, take } from 'rxjs/operators'
import { AuthService } from '@tv3/services/auth.service'
import { BDCContentProperty, BDCRatePlan } from '@tv3/store/channel-content/models/bdc.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { localeCompareSort } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-bdc-content-rate-setup',
  templateUrl: './bdc-content-rate-setup.component.html',
  styleUrls: ['./bdc-content-rate-setup.component.scss'],
})
export class BdcContentRateSetupComponent implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() bdcProperty: BDCContentProperty

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  dataSource = new MatTableDataSource<BDCRatePlan>([])
  displayedColumns = ['name', 'id', 'active', 'propertyId', 'propertyName', 'edit']

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toaster: Toaster,
    private bdcContentService: BdcContentService,
    private promptDialog: PromptDialogService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    this.reload()
  }

  reload() {
    this.bdcContentService.getRates(this.bdcProperty.propertyId).subscribe((data) => {
      this.dataSource.data = data
    })
  }

  onEdit(rate: BDCRatePlan) {
    this.promptDialog
      .open({
        title: 'Enter Rate Name',
        buttonIcon: 'cs-icon cs-icon-save',
        buttonText: 'Update',
        defaultValue: rate.name,
        placeholder: 'Rate Name',
      })
      .pipe(
        take(1),
        filter(isSomething),
        switchMap((name) =>
          this.bdcContentService.updateRate(rate.propertyId, rate.id, {
            account: this.auth.user.account,
            rentalId: this.rental.id,
            channelId: this.channel.id,
            name,
          })
        )
      )
      .subscribe((res) => {
        this.toaster.success('Rate Name updated successfully.')
        this.reload()
      })
  }

  onAdd() {
    this.promptDialog
      .open({
        title: 'Enter Rate Name',
        buttonIcon: 'cs-icon cs-icon-save',
        buttonText: 'Add',
        placeholder: 'Rate Name',
      })
      .pipe(
        take(1),
        filter(isSomething),
        switchMap((name) =>
          this.bdcContentService.createRate(this.bdcProperty.propertyId, {
            account: this.auth.user.account,
            rentalId: this.rental.id,
            channelId: this.channel.id,
            name,
          })
        )
      )
      .subscribe((res) => {
        this.toaster.success('Rate added successfully.')
        this.reload()
      })
  }
}
