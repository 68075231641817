import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromTag from './tag.reducer'
import { EffectsModule } from '@ngrx/effects'
import { TagEffects } from './tag.effects'

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('tag', fromTag.tagsReducer), EffectsModule.forFeature([TagEffects])],
  declarations: [],
})
export class TagStoreModule {}
