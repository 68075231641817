import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { DialogService, Rental } from '@tokeet-frontend/tv3-platform'
import { VrboRentalValidationDialogComponent } from './vrbo-rental-validation-dialog.component'

@Injectable()
export class VrboRentalValidationDialogService {
  constructor(private dialog: DialogService) {}

  open(rental: Rental) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: '500px',
      data: { rental },
    }

    return this.dialog.openFixedDialog(VrboRentalValidationDialogComponent, defaultConfig)
  }
}
