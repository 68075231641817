export enum InventoryStatus {
  Inactive = 0,
  Active = 1,
}

export enum InventoryCondition {
  New = 'new',
  Good = 'good',
  Fair = 'fair',
  Excellent = 'excellent',
  Damaged = 'damaged',
  Worn = 'worn',
  Inoperable = 'inoperable',
  Old = 'old',
}

export enum InventoryCategory {
  Furniture = 'furniture',
  Appliance = 'appliance',
  Electronics = 'electronics',
  Kitchenware = 'kitchenware',
  Bedding = 'bedding',
  Supplies = 'supplies',
  Consumables = 'consumables',
  Plants = 'plants',
  Decor = 'decor',
  Outdoor = 'outdoor',
  Pool = 'pool',
  Other = 'other',
}

export interface InventoryResponse {
  pkey: string
  account: number
  title: string
  description?: string
  category: InventoryCategory
  sub_category?: string
  condition: InventoryCondition

  images?: string[]
  files?: string[]
  rental_id?: string
  status?: InventoryStatus
  quantity?: number
  comments?: string
  replenish_count?: number
  replenished_at?: number
  supplier?: string
  replace_by?: string
  updated_at?: number
  purchase_price?: number
  purchased_at?: number
  attributes?: string[]

  created: number
  archived: number
}

export interface InventoryView extends InventoryResponse {
  createdView: string
  rentalView: string
  statusView: string
}

export interface SearchInventoryParams {
  rentals: string[]
  status: InventoryStatus
  categories: string[]
  conditions: InventoryCondition
  period: {
    from: number
    to: number
  }
}

export interface CreateInventoryPayload {
  account: number
  title: string
  description?: string
  category: InventoryCategory
  sub_category?: string
  condition: InventoryCondition

  images?: string[]
  files?: string[]
  rental_id?: string
  status?: InventoryStatus
  quantity?: number
  replenish_count?: number
  purchase_price?: number
  purchased_at?: number

  archived?: 0 | 1
}

export type UpdateInventoryPayload = Partial<CreateInventoryPayload>
