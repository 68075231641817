import { Component, Input, OnInit, ViewChild } from '@angular/core'
import * as lodash from 'lodash'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'
import { GetEntityTags, selectEntityTags, selectEntityTypeTags } from '@tokeet-frontend/tags'
import { select, Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { combineLatest, Observable } from 'rxjs'
import { EntityFormTagsComponent } from '../entity-form-tags/entity-form-tags.component'

export type ManageEntityTagsOnSubmit = (tags: string[]) => unknown

@Component({
  selector: 'app-manage-entity-tags',
  templateUrl: './manage-entity-tags.component.html',
  styleUrls: ['./manage-entity-tags.component.scss'],
})
export class ManageEntityTagsComponent implements OnInit {
  @ViewChild(EntityFormTagsComponent) tagsField?: EntityFormTagsComponent

  @Input() type: DataEntityType
  @Input() id: string
  @Input() showSuggesttedTags = true
  @Input() tags: string[] = []
  @Input() tagsFieldLabel = 'Tags'
  @Input() autoSave = true
  @Input() showModifiedMsg = true
  @Input() onSubmit: ManageEntityTagsOnSubmit | null = null

  tags$: Observable<string[]>

  constructor(private store: Store<any>) {}

  ngOnInit() {
    if (this.id && this.type) {
      this.store.dispatch(GetEntityTags({ entityType: this.type, entityId: this.id }))
    }
    this.tags$ = combineLatest([
      this.store.pipe(select(selectEntityTags(this.type, this.id))),
      this.store.pipe(select(selectEntityTypeTags(this.type))),
    ]).pipe(map(([entityTags, tags]) => lodash.difference(tags, entityTags)))
  }

  save() {
    if (this.onSubmit && this.tagsField) {
      const result = this.onSubmit(this.tagsField.getTags())
      if (result !== true) return
    }

    this.tagsField?.save()
  }
}
