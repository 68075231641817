import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Destroyable, isSavingRental, Rental, SaveForm, updateRentalGPS } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FormBuilder, FormGroup } from '@angular/forms'

@Component({
  selector: 'app-rental-location',
  templateUrl: './rental-location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rental-location.component.scss'],
})
export class RentalLocationComponent extends Destroyable implements OnInit, OnChanges {
  @Input() rental: Rental

  isSaving$ = this.store.pipe(select(isSavingRental))

  form = this.fb.group({
    gps: [{}],
  })

  constructor(private store: Store<fromRoot.State>, private fb: FormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.setForm(this.rental)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rental = R.pathOr(this.rental, ['rental', 'currentValue'], changes)
    this.setForm(this.rental)
  }

  onMarkerChange(event: { coords: { lat: number; lng: number } }) {
    const lat = event.coords.lat
    const long = event.coords.lng
    this.form.patchValue({ gps: { lat, long } })
  }

  save() {
    this.onSaveGPS(this.form)
  }

  @SaveForm()
  onSaveGPS(form: FormGroup) {
    const values = form.getRawValue()

    this.store.dispatch(updateRentalGPS({ gps: values.gps, rentalId: this.rental.id }))
  }

  private setForm(rental: Rental) {
    this.form.patchValue({
      gps: rental.gps || {},
    })
  }
}
