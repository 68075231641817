import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { MatDialogRef } from '@angular/material/dialog'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

export interface SelectItemDialogConfig {
  title: string
  help: string
}

@Component({
  selector: 'app-select-item-dialog',
  templateUrl: './select-inquiry-dialog.component.html',
  styleUrls: ['./select-inquiry-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class SelectInquiryDialogComponent implements OnInit {
  config = {
    title: 'Select Inquiry',
    help: 'Please select one inquiry from the list above for the new invoice.',
  } as SelectItemDialogConfig

  form = this.fb.group({
    inquiry_id: [undefined, [Validators.required]],
  })

  selectedInquiry: Inquiry

  constructor(public dialogRef: MatDialogRef<SelectInquiryDialogComponent>, private fb: FormBuilder) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.dialogRef.close(this.selectedInquiry)
  }
}
