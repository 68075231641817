import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { AutomationResponse } from './automation.model'

export interface State extends EntityState<AutomationResponse> {
  isLoaded: boolean
  isAdding: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<AutomationResponse> = createEntityAdapter<AutomationResponse>({
  selectId: (a: AutomationResponse) => a.pkey,
  sortComparer: (a, b) => b.created - a.created,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isAdding: false,
  isUpdating: false,
})
