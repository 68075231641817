import * as lodash from 'lodash'

export enum DataEntityType {
  Rental = 'rental',
  Guest = 'guest',
  Expense = 'expense',
  Extra = 'extras',
  Task = 'task',
  Inquiry = 'inquiry',
  User = 'user',
  HoldEvent = 'hold_event',
  Invoice = 'invoice',
  InvoiceTemplate = 'invoice_template',
  InvoiceRule = 'invoice_rule',
  CustomCode = 'custom_code',
  Incident = 'incident',
  Inventory = 'inventory',
}

export const dataEntityTypes: DataEntityType[] = lodash.values(DataEntityType)
