<div dropdown class="dropdown" [isDisabled]="1 | isReadonlyRole">
  <button dropdownToggle type="button" class="dropdown-toggle" [class.caret-none]="!btnLabel" [ngClass]="btnClass">
    <span class="cs-icon cs-icon-bolt"></span> {{ btnLabel }}
  </button>
  <ul *dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="dropdownRight">
    <li *ngIf="isAnyImportedFromABBV2" matTooltip="Re-import selected bookings from Airbnb." matTooltipPosition="left">
      <button (click)="onImport()" type="button"><i class="cs-icon fa-fw cs-icon-download"></i> Re-import</button>
    </li>
    <!-- Link -->
    <li
      matTooltip="Link multiple related inquiries together. This allows them to share the same data."
      matTooltipPosition="left"
    >
      <button (click)="onLink()" type="button"><i class="far fa-fw fa-link"></i> Link</button>
    </li>

    <li class="dropdown-divider"></li>

    <!-- Paid -->
    <li *ngIf="isAnyUnpaid" matTooltip="Mark selected bookings as paid." matTooltipPosition="left">
      <button (click)="onPaid()" type="button"><i class="far fa-fw fa-money-check-alt"></i> Mark as Paid</button>
    </li>

    <!-- Unpaid -->
    <li *ngIf="!isAnyUnpaid" matTooltip="Mark selected bookings as unpaid." matTooltipPosition="left">
      <button (click)="onUnpaid()" type="button"><i class="far fa-fw fa-money-check-alt"></i> Mark as Unpaid</button>
    </li>

    <!-- Mark for followup -->
    <li *ngIf="isAnyNoFollow" matTooltip="Flag selected inquiries for follow-up later." matTooltipPosition="left">
      <button (click)="onFollowUp()" type="button"><i class="far fa-fw fa-star"></i> Mark for Follow Up</button>
    </li>

    <!-- Mark for no followup -->
    <li *ngIf="!isAnyNoFollow" matTooltip="Remove follow-up flag from selected inquiries." matTooltipPosition="left">
      <button (click)="onNoFollowUp()" type="button"><i class="far fa-fw fa-star"></i> Mark for No Follow Up</button>
    </li>

    <!-- Mark as read -->
    <li *ngIf="isAnyUnRead" matTooltip="Mark selected inquiries as read." matTooltipPosition="left">
      <button (click)="onRead()" type="button"><i class="far fa-fw fa-envelope-open"></i> Mark as Read</button>
    </li>

    <!-- Mark as unread -->
    <li *ngIf="!isAnyUnRead" matTooltip="Mark selected inquiries as unread." matTooltipPosition="left">
      <button (click)="onUnRead()" type="button"><i class="far fa-fw fa-envelope"></i> Mark as Unread</button>
    </li>

    <li class="dropdown-divider"></li>

    <!-- Add tags -->
    <li matTooltip="Add tags to selected inquiries" matTooltipPosition="left">
      <button (click)="onAddTags()" type="button"><i class="far fa-fw fa-tags"></i> Add Tags</button>
    </li>

    <li class="dropdown-divider"></li>

    <!-- Download -->
    <li *ngIf="dataSource?.filteredData?.length === 0">
      <button
        matTooltip="There are no inquiries under current filter"
        matTooltipPosition="left"
        type="button"
        class="tone-down"
      >
        <i class="cs-icon cs-icon-download"></i> Download
      </button>
    </li>
    <li *ngIf="dataSource?.filteredData?.length !== 0">
      <button
        (click)="onDownload()"
        type="button"
        matTooltip="Download your inquiries to csv"
        matTooltipPosition="right"
      >
        <i class="cs-icon cs-icon-download"></i> Download
      </button>
    </li>

    <!-- Export Report -->
    <li>
      <button
        (click)="onExportReport()"
        type="button"
        matTooltip="Export the inquiries for selected year and rental(s)"
        matTooltipPosition="right"
      >
        <i class="fal fa-download"></i> Export Report
      </button>
    </li>

    <!-- Import from CSV -->
    <li>
      <button
        (click)="onImportCSV()"
        type="button"
        [disabled]="1 | isReadonlyRole"
        matTooltip="Import multiple inquiries at a time"
        matTooltipPosition="right"
      >
        <i class="cs-icon cs-icon-upload"></i> Import from CSV
      </button>
    </li>

    <li class="dropdown-divider"></li>

    <!-- Archive -->
    <li
      *ngIf="isAnyUnArchived"
      matTooltip="Archive inquiries and hide them from the default view."
      matTooltipPosition="left"
    >
      <button (click)="onArchive()" type="button"><i class="far fa-fw fa-archive"></i> Archive</button>
    </li>

    <!-- Unarchive -->
    <li *ngIf="!isAnyUnArchived" matTooltip="Unarchive previously archived inquiries." matTooltipPosition="left">
      <button (click)="onUnArchive()" type="button"><i class="far fa-fw fa-file-archive"></i> Unarchive</button>
    </li>

    <!-- Delete -->
    <li matTooltip="Delete selected inquiries and bookings." matTooltipPosition="left">
      <button (click)="onDelete()" type="button"><i class="cs-icon fa-fw cs-icon-trash"></i> Delete</button>
    </li>
  </ul>
</div>
