import { Injectable } from '@angular/core'
import { DialogService, DataEntityType } from '@tokeet-frontend/tv3-platform'
import { EntityAttributesConfigOverlayComponent } from './entity-attributes-config-overlay.component'

@Injectable()
export class EntityAttributesConfigOverlayService {
  constructor(private dialog: DialogService) {}

  open(entityType: DataEntityType) {
    const config = {
      data: { entityType },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(EntityAttributesConfigOverlayComponent, config)

    return dialogRef
  }
}
