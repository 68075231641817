import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface InvoiceNotesChangeEvent {
  notes: string
  paymentTerms: string
  paymentInstructions: string
}

@Component({
  selector: 'app-invoice-notes',
  templateUrl: './invoice-notes.component.html',
  styleUrls: ['./invoice-notes.component.scss'],
})
export class InvoiceNotesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() editable = true
  @Input() paymentTerms: string
  @Input() paymentInstructions: string
  @Input() notes: string

  @Input() rootForm: FormGroup
  form = this.fb.group({
    notes: [undefined],
    paymentTerms: [undefined],
    paymentInstructions: [undefined],
  })

  @Output() update = new EventEmitter<InvoiceNotesChangeEvent>()

  destroy$ = new Subject<boolean>()

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form.patchValue({
      notes: this.notes,
      paymentTerms: this.paymentTerms,
      paymentInstructions: this.paymentInstructions,
    })

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.update.emit(this.form.getRawValue() as InvoiceNotesChangeEvent)
    })
    this.rootForm.addControl('notes', this.form)
  }

  ngOnChanges(changes: SimpleChanges): void {
    const editableChange = changes['editable']
    if (editableChange) {
      editableChange.currentValue ? this.form.enable() : this.form.disable()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
