import { TaskResponse } from './task.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { pathOr } from 'ramda'

export interface State extends EntityState<TaskResponse> {
  isLoaded: boolean
  isUpdating: boolean
}

export const adapter: EntityAdapter<TaskResponse> = createEntityAdapter<TaskResponse>({
  selectId: (task: TaskResponse) => task.pkey,
  sortComparer: (a: TaskResponse, b: TaskResponse) =>
    pathOr('', ['name'], a)
      .toString()
      .localeCompare(pathOr('', ['name'], b), undefined, { numeric: true, sensitivity: 'base' }),
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
})
