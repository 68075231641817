<div>
  <div *ngIf="type === types.Email" class="d-flex align-items-center border-top px-3">
    <div class="mr-3 text-bold">
      <span>Subject:</span>
    </div>
    <input tabindex="-1" type="text" class="form-control border-0 shadow-none" [formControl]="subjectCtrl" />
  </div>
  <div *ngIf="!isPlain" class="border-top border-bottom">
    <app-tiny-mce-editor
      #tinyMceEditor
      [options]="editorOptions"
      [maxHeight]="700"
      [minHeight]="500"
      (setup)="onEditorSetup($event)"
      [ctrl]="contentCtrl"
    ></app-tiny-mce-editor>
    <mat-error *ngIf="contentCtrl.touched && contentCtrl.hasError('required')">
      Content is <strong>required</strong>
    </mat-error>
  </div>

  <div *ngIf="isPlain" class="border-top border-bottom">
    <textarea [formControl]="contentCtrl" class="plain-editor m-0 border-0 w-100 p-2" cdkTextareaAutosize></textarea>
  </div>

  <div class="d-flex align-items-center gap-3 p-3">
    <button
      *ngIf="!isPlain"
      [disabled]="1 | isReadonlyRole"
      (click)="onToggleInputType()"
      matTooltip="Click here to edit the template as plain text. This will allow you to modify the HTML code of your template, and create JSON files for use with webhooks."
      matTooltipPosition="above"
      class="btn btn-secondary-primary"
    >
      <i class="far fa-code"></i> Use plain text
    </button>
    <button
      *ngIf="isPlain"
      [disabled]="1 | isReadonlyRole"
      (click)="onToggleInputType()"
      matTooltip="Click here to edit the template as rich text. This will allow you to use all the text editing features of the message template editor."
      matTooltipPosition="above"
      class="btn btn-secondary-primary"
    >
      <i class="cs-icon cs-icon-file-alt"></i> Use rich text
    </button>
    <div class="flex-fill"></div>
    <ng-content select=".bottom-right-additional-actions"></ng-content>
  </div>
</div>
