import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  Account,
  AccountService,
  Channel,
  ChannelService,
  Destroyable,
  Rental,
  rentalDetails,
  RentalService,
  Toaster,
  updateAccountComplete,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup } from '@angular/forms'
import * as lodash from 'lodash'
import { Observable } from 'rxjs'
import { mapTo, switchMap, take, tap } from 'rxjs/operators'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { BDCCancelOption } from '@tv3/constants/bdc-cancel-policies'
import { BdcContentService } from '@tv3/store/channel-content/bdc-content.service'
import { BDCContentPropertyPayload } from '@tv3/store/channel-content/payload/bdc.request'
import { AuthService } from '@tv3/services/auth.service'
import { BDCPropertyCreateComplete, BDCPropertyUpdateComplete } from '@tv3/store/channel-content/conent.actions'
import { BDCContentProperty } from '@tv3/store/channel-content/models/bdc.model'
import { ChannelHelperService } from '@tv3/containers/channels/channel-connect/channel-helper.service'
import { availablePropertyAmenitiesTokeetIds } from '@tv3/store/channel-property-setting/settings/bdc-property-settings'
import {
  ChannelPropertySetting,
  ChannelPropertySettingPayload,
} from '@tv3/store/channel-property-setting/channel-property-setting.model'
import { ChannelPropertySettingService } from '@tv3/store/channel-property-setting/channel-property-setting.service'
import {
  CreateChannelPropertySettingComplete,
  UpdateChannelPropertySettingComplete,
} from '@tv3/store/channel-property-setting/channel-property-setting.actions'
import { createForm as createAccountForm } from './step-forms/step-account/bdc-content-step-account-form.component'
import { createForm as createContactForm } from './step-forms/step-contacts/bdc-content-step-contacts-form.component'
import { createForm as createPetsForm } from './step-forms/step-pet/bdc-content-step-pet-form.component'
import { createForm as createRentalForm } from './step-forms/step-rental/bdc-content-step-rental-form.component'
import { createForm as createLicenseForm } from './step-forms/step-license/bdc-content-step-license-form.component'
import { createForm as createAmenitiesForm } from './step-forms/step-amenities/content-step-amenities-form.component'
import { createForm as createPolicyForm } from './step-forms/step-policies/bdc-content-step-policies-form.component'
import { ChannelConnectWizardsService } from '@tv3/containers/channels/channel-connect/channel-connect-wizards/index.service'
import { ChannelConnectHelperService } from '../../../channel-connect/channel-connect-wizards/channel-connect-helper.service'

@Component({
  selector: 'app-bdc-content-property-setup',
  templateUrl: './bdc-content-property-setup.component.html',
  styleUrls: ['./bdc-content-property-setup.component.scss'],
})
export class BdcContentPropertySetupComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() bdcProperty: BDCContentProperty
  @Input() bdcPropertySetting: ChannelPropertySetting
  @Output() rentalChange = new EventEmitter<Rental>()

  get propertyId() {
    return (
      (this.bdcProperty && this.bdcProperty.propertyId) ||
      (this.connection && this.connection.propertyId) ||
      (this.bdcPropertySetting && this.bdcPropertySetting.propertyId)
    )
  }

  get rentalId() {
    return (this.rental && this.rental.id) || (this.connection && this.connection.rentalId)
  }

  rentalAmenityDetails = rentalDetails.filter((item) => availablePropertyAmenitiesTokeetIds.indexOf(item.id) > -1)

  rentalAmenitiesForm = createAmenitiesForm(this.fb)
  rentalForm = createRentalForm(this.fb)
  accountForm = createAccountForm(this.fb)
  contactsForm = createContactForm(this.fb)
  petsForm = createPetsForm(this.fb)
  licenseForm = createLicenseForm(this.fb)
  cancelPoliciesForm = createPolicyForm(this.fb)
  activeCancelPolicies: BDCCancelOption[] = []

  constructor(
    private channelService: ChannelService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private auth: AuthService,
    private channelHelperService: ChannelHelperService,
    private rentalService: RentalService,
    private accountService: AccountService,
    private bdcContentService: BdcContentService,
    private channelPropertySettingService: ChannelPropertySettingService,
    private channelConnectHelperService: ChannelConnectHelperService
  ) {
    super()
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.rentalId) {
        this.rentalForm.patchValue({ rentalId: this.rentalId })
      }
      if (this.connection && this.connection.rentalId) {
        this.rentalForm.get('rentalId').disable()
      }
      if (this.bdcPropertySetting) {
        this.rentalForm.patchValue({ rentalId: this.bdcPropertySetting.rentalId })
      }
    }, 250)
  }

  onRentalChange(rental: Rental) {
    this.rental = rental
    this.rentalChange.emit(rental)
  }

  savePropertyContentSetting(propertyId, settings: ChannelPropertySettingPayload): Observable<ChannelPropertySetting> {
    const payload = {
      ...settings,
      property_id: propertyId,
      listing_id: propertyId,
    } as ChannelPropertySettingPayload
    if (this.bdcPropertySetting) {
      return this.channelPropertySettingService.update(this.bdcPropertySetting.id, payload).pipe(
        tap((data) =>
          this.store.dispatch(
            UpdateChannelPropertySettingComplete({
              update: { id: this.bdcPropertySetting.id, changes: data },
            })
          )
        )
      )
    } else {
      return this.channelPropertySettingService.create(payload).pipe(
        tap((data) => {
          this.store.dispatch(CreateChannelPropertySettingComplete({ item: data }))
        })
      )
    }
  }

  saveRentalData() {
    const rawData = this.rentalForm.getRawValue()
    const rentalData = lodash.omit(rawData, ['rentalId', 'timezone'])
    // format checkin time/checkout time
    rentalData.checkin = this.rentalService.toCheckTime(rawData.checkin, rawData.timezone)
    rentalData.checkout = this.rentalService.toCheckTime(rawData.checkout, rawData.timezone)
    rentalData.detail = this.getSelectedAmenities()
    // @ts-ignore
    return this.rentalService.update(this.rental.id, rentalData).pipe(
      tap((rental: Rental) =>
        this.store.dispatch(
          updateRentalComplete({
            update: {
              id: rental.id,
              changes: rental,
            },
          })
        )
      )
    )
  }

  saveAccountData() {
    const accountData = this.accountForm.getRawValue()
    return this.accountService.update({ account: accountData }).pipe(
      tap((account: Account) =>
        this.store.dispatch(
          updateAccountComplete({
            update: {
              id: account.id,
              changes: account,
            },
          })
        )
      )
    )
  }

  createOrUpdateChannelProperty(settings: ChannelPropertySettingPayload) {
    const payload = {
      account: this.auth.user.account,
      rentalId: this.rental.id,
      channelId: this.channel.id,
      settings: settings.attributes,
    } as BDCContentPropertyPayload

    const isCreate = !this.propertyId

    const sub = !isCreate
      ? this.bdcContentService.update(this.propertyId, payload)
      : this.bdcContentService.create(payload).pipe(
          switchMap((data) =>
            this.channelConnectHelperService
              .connectChannelWithPropertyId(this.channel, data.propertyId)
              .pipe(mapTo(data))
          ),
          tap(({ propertyId, warnings }) => {
            this.store.dispatch(
              isCreate ? BDCPropertyCreateComplete({ propertyId }) : BDCPropertyUpdateComplete({ propertyId })
            )
            this.toaster.success(`Property ${isCreate ? 'Created' : 'Updated'} successfully.`)
            if (warnings && warnings.length) {
              this.toaster.warning(`Property Warnings: ${warnings.join('\n')}`)
            }
          })
        )
    return sub
  }

  onSave() {
    const settingsPayload = {
      rental_id: this.rental.id,
      property_id: this.propertyId,
      channel: this.channel.name,
      attributes: {
        policies: lodash.map(this.activeCancelPolicies, 'value'),
        contacts: this.contactsForm.getRawValue(),
        ...this.petsForm.getRawValue(),
        ...this.licenseForm.getRawValue(),
      },
    } as ChannelPropertySettingPayload

    this.saveRentalData()
      .pipe(
        switchMap(() => this.saveAccountData()),
        switchMap(() => this.createOrUpdateChannelProperty(settingsPayload)),
        switchMap(({ propertyId }) => this.savePropertyContentSetting(propertyId, settingsPayload)),
        take(1)
      )
      .subscribe()
  }

  onEditRental(rental: Rental) {
    this.store.dispatch(OpenRentalOverlay({ rental }))
  }

  onCancelPolicyChange(policies: BDCCancelOption[]) {
    this.activeCancelPolicies = policies || []
  }

  getSelectedAmenities() {
    const { amenities: rentalDetailCheckData } = this.rentalAmenitiesForm.getRawValue()
    const details = {
      ...this.rental.detail,
    }
    rentalDetailCheckData.forEach((value, i) => (details[this.rentalAmenityDetails[i].id] = !!value))
    return lodash.reduce(
      details,
      (acc, value, key) => {
        if (value) {
          acc[key] = value
        }
        return acc
      },
      {}
    )
  }

  selectedPropertyAmenitiesCount(form: FormGroup) {
    const { amenities } = form.getRawValue()
    return amenities.filter((d) => d).length
  }
}
