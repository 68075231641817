import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { DeleteWebsite, PublishWebsite } from '@tv3/store/website/website.actions'
import { Website } from '@tv3/store/website/website.model'
import { AlertDialogService, ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'

@Injectable({
  providedIn: SharedModule,
})
export class WebsitesHelperService {
  constructor(
    private store: Store<fromRoot.State>,
    private alertDialog: AlertDialogService,
    private confirmDialog: ConfirmDialogService,
    private inquiryService: InquiryService
  ) {}

  publish(website: Website) {
    if (!website.isPublishable) {
      this.alertDialog.open({
        title: 'Website is incomplete.',
        body: 'Your website settings is missing required fields. Please update the missing fields and try to publish again.',
      })
      return
    }
    this.store.dispatch(PublishWebsite({ id: website.id, domain: website.domain }))
  }

  preview(website: Website, https = true) {
    const rentalIds = website.rentals || []
    const activeRentalId = rentalIds[0]
    const protocol = https ? 'https' : 'http'
    if (website.isEngine && activeRentalId) {
      const query = `template=${website.template}&path=%2Fcheckout%3Frental_id%3D${activeRentalId}`
      window.open(`${protocol}://${website.domain}/preview?${query}`)
    } else if (website.isPortal && activeRentalId) {
      this.inquiryService.searchBookings(1, 0, 1, activeRentalId).subscribe(([inquiry]) => {
        if (!inquiry) {
          return
        }
        window.open(
          `${protocol}://${website.domain}/authorize/${inquiry.account}/${inquiry.id}/${inquiry.guestId}/${inquiry.created}?preview=1`
        )
      })
    } else {
      window.open(`${protocol}://${website.domain}/preview`)
    }
  }

  delete(website: Website) {
    this.confirmDialog
      .confirm({
        title: 'Delete website?',
        body: 'Are you sure you want to delete this website?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteWebsite({ id: website.id }))
      })
  }
}
