import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ActivitiesResponse } from './model'
import { DataEntityType } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient) {}

  getActivities(type: DataEntityType, id: string) {
    return this.http.get<ActivitiesResponse>(`@api/entitylog/${type}/${id}`)
  }

  addActivity(type: DataEntityType, id: string, data: { log: string; creator_id: string }) {
    return this.http.put<ActivitiesResponse>(`@api/entitylog/${type}/${id}/`, {
      logs: [data],
    })
  }
}
