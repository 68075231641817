import { Component, Inject, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Message } from '@tv3/store/message/message.model'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import * as R from 'ramda'
import { LoadInquiryDetailsComplete } from '@tv3/store/inquiry/inquiry.actions'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectSome } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent extends Destroyable implements OnInit {
  inquiry: Inquiry

  messages: Message[] = []

  messageId?: string

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private inquiryService: InquiryService,
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiryId: string; messageId?: string }
  ) {
    super()
  }

  ngOnInit() {
    this.messageId = this.data.messageId

    this.inquiryService.load(this.data.inquiryId).subscribe((inquiry) => {
      this.inquiry = inquiry
      this.store.dispatch(LoadInquiryDetailsComplete({ inquiry }))
    })

    this.store.pipe(selectSome(selectInquiry, { id: this.data.inquiryId }), untilDestroy(this)).subscribe((inquiry) => {
      this.messages = R.defaultTo([], inquiry.messages)
    })
  }

  close() {
    this.dialogRef.close()
  }
}
