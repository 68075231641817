import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AirbnbPhoto } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.models'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConfirmDialogService, SaveForm } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-photo-edit',
  templateUrl: './photo-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./photo-edit.component.scss'],
})
export class AirbnbListingPhotoEditComponent implements OnInit {
  @Input() photo: AirbnbPhoto

  @Output() back = new EventEmitter()
  @Output() save = new EventEmitter()
  @Output() delete = new EventEmitter()

  form = this.fb.group({
    caption: ['', [Validators.maxLength(250)]],
  })

  constructor(private fb: FormBuilder, private confirmDialog: ConfirmDialogService) {}

  ngOnInit() {
    console.log(this.photo)
    this.form.patchValue({
      caption: this.photo.caption,
    })
  }

  @SaveForm()
  onSave(form: FormGroup) {
    this.save.emit({ ...this.photo, caption: form.get('caption').value })
    this.back.emit(true)
  }

  onDelete() {
    this.confirmDialog
      .confirm({
        title: 'Delete this photo?',
        body: 'Are you sure you want to delete this photo?',
      })
      .subscribe(() => {
        this.delete.emit({ ...this.photo })
        this.back.emit(true)
      })
  }
}
