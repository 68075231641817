import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromInvoiceRule from './rule.reducer'
import { EffectsModule } from '@ngrx/effects'
import { InvoiceRuleEffects } from './rule.effects'
import { InvoiceRuleService } from './rule.service'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('invoice-rule-lib', fromInvoiceRule.invoiceRuleReducer),
    EffectsModule.forFeature([InvoiceRuleEffects]),
  ],
  providers: [InvoiceRuleService],
  declarations: [],
})
export class InvoiceRuleLibraryModule {}
