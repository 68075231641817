import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as R from 'ramda'
import * as reducer from './website.reducer'
import * as state from './website.state'
import { Website, WebsiteTypes } from '@tv3/store/website/website.model'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { WebsiteTemplate } from '@tv3/store/website/website.types'

export const websiteState = createFeatureSelector<state.State>('website')

export const selectWebsitesLoaded = createSelector(websiteState, (s: state.State) => s.isLoaded)
export const isUpdatingWebsite = createSelector(websiteState, (state) => state.isUpdating)

export const selectAllWebsites = createSelector(websiteState, reducer.selectAll)

export const selectAllWebsiteTemplates = createSelector(websiteState, (s: state.State) => s.templates)
export const isWebsiteTemplatesLoaded = createSelector(websiteState, (s: state.State) => s.isTemplatesLoaded)

export const selectAllWebsiteViews = createSelector(
  selectAllWebsites,
  selectAllRentals,
  selectAllWebsiteTemplates,
  (websites: Website[], rentals: Rental[], templates: WebsiteTemplate[]) => {
    return R.map((website: Website) => {
      const web = Website.deserialize(website.serialize() as Website)
      const rentalIds = web.rentals || []
      const selectedRentals = R.filter((r: Rental) => rentalIds.indexOf(r.id) > -1, rentals || [])
      web.rentalsView = R.map((r) => r.name, selectedRentals).join(', ')

      const selectTemplate = R.find((t: WebsiteTemplate) => t.name === web.template, templates || [])
      web.templateView = selectTemplate ? selectTemplate.title : 'Default Template'
      return web
    }, websites || [])
  }
)

export const selectWebsitesForRental = createSelector(selectAllWebsites, (websites: Website[], props) => {
  return R.filter((web: Website) => {
    const rentalIds = web.rentals || []
    return rentalIds.indexOf(props.id) !== -1
  })(websites)
})

export const selectWebsitesByType = createSelector(selectAllWebsites, (websites: Website[], props) => {
  return R.filter((web) => {
    return web.type === props.type
  }, websites)
})

export const selectWebsiteById = createSelector(websiteState, (s: state.State, props) => {
  return s.entities[props.id]
})
