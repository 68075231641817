import { classToClass, classToPlain, plainToClass } from 'class-transformer'
import { assign, merge } from 'lodash'

export { Expose, Type, Exclude, Transform, classToClass, classToPlain, plainToClass } from 'class-transformer'

export function deserialize<T>(Class: any, data: object, options?: object): T {
  return plainToClass<T, object>(Class, data, options)
}

export function serialize(data: object, options?: object): object {
  return classToPlain(data, options)
}

export function clone<T>(data: T): T {
  return classToClass<T>(data, { ignoreDecorators: true })
}

/* Serializable class */

export type SerializableData<T> = T | Partial<T>

export class Serializable<T> {
  constructor(data?: SerializableData<T>) {
    if (data) this.merge(data)
  }

  serialize() {
    return serialize(this)
  }

  merge(data: SerializableData<T>, useMerge = true) {
    if (useMerge) {
      merge(this, data)
    } else {
      assign(this, data)
    }
  }

  clone(data?: SerializableData<T>, useMerge = false): T {
    const cloned = clone(this)

    if (data) {
      cloned.merge(data, useMerge)
    }

    return cloned as any
  }
}
