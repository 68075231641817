import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { GuestNote } from '@tv3/store/guest/guest.model'
import { NoteForm } from '@tv3/interfaces/forms/note.form'
import * as R from 'ramda'

@Component({
  selector: 'app-guest-modify-note',
  templateUrl: './guest-modify-note-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./guest-modify-note-dialog.component.scss'],
})
export class GuestModifyNoteDialogComponent {
  maxLength = 500

  form = this.fb.group({
    note: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
  })

  constructor(
    public dialogRef: MatDialogRef<GuestModifyNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GuestNote,
    private fb: FormBuilder
  ) {
    this.form.patchValue({
      note: R.pathOr('', ['note'], data),
    })
  }

  close() {
    this.dialogRef.close()
  }

  delete() {
    this.dialogRef.close({ form: null, data: this.data, delete: true })
  }

  @SaveForm()
  save(form: FormGroup) {
    this.dialogRef.close({ form: form.getRawValue() as NoteForm, data: this.data })
  }
}
