import * as moment from 'moment'

export function getISOWeekdays() {
  const [sun, ...others] = moment.weekdays()
  return [...others, sun]
}

export function getISOWeekdaysShort() {
  const [sun, ...others] = moment.weekdaysShort()
  return [...others, sun]
}

export function getISOWeekdaysMin() {
  const [sun, ...others] = moment.weekdaysMin()
  return [...others, sun]
}
