import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  deleteRentalComplete,
  Destroyable,
  isSomething,
  loadRental,
  Rental,
  selectRentalById,
  selectSome,
  untilDestroy,
  isRestrictingUsers,
} from '@tokeet-frontend/tv3-platform'
import { tap } from 'rxjs/operators'
import { RentalDetailsBasicInfoComponent } from './components/basic-info/rental-details-basic-info.component'
import { RentalDetailsDetailInfoComponent } from './components/detail-info/rental-details-detail-info.component'
import { RentalDetailsImageGalleryComponent } from './components/image-gallery/rental-details-image-gallery.component'
import { RentalDetailsInstructionsComponent } from './components/instructions/rental-details-instructions.component'
import { RentalDetailsCustomInfoComponent } from './components/custom-info/rental-details-custom-info.component'
import { RentalLocationComponent } from './components/location/rental-location.component'
import * as R from 'ramda'

export enum RentalDialogTab {
  Calendar,
  Basic,
  Location,
  Custom,
  Info,
  Gallery,
  Instructions,
  Attributes,
  //
  RatesTable,
  RateMappings,
  Discounts,
  Settings,
  //
  Taxes,
  Fees,
  PaymentSchedule,
  Rooms,
  UserRestrictions,
  OwnerAccess,
  Tags,
  Connections,
  Calendars,
  //
}

@Component({
  selector: 'app-rental-overlay',
  templateUrl: './rental-overlay.component.html',
  styleUrls: ['./rental-overlay.component.scss'],
})
export class RentalOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('basic') basic: RentalDetailsBasicInfoComponent
  @ViewChild('detail') detail: RentalDetailsDetailInfoComponent
  @ViewChild('gallery') gallery: RentalDetailsImageGalleryComponent
  @ViewChild('instructions') instructions: RentalDetailsInstructionsComponent
  @ViewChild('custom') custom: RentalDetailsCustomInfoComponent
  @ViewChild('location') location: RentalLocationComponent

  tab$ = new BehaviorSubject(RentalDialogTab.Basic)
  tabs = RentalDialogTab

  rental: Rental

  hasIncompleteBaseRate = true
  incompleteBaseRateMessage =
    'Base Rate settings are required to ensure your rate mappings and future rate pushes go through properly. Please make sure that a nightly rate and minimum stay are set on this tab even if you have no plans to use the Base Settings as a default rate.'

  hasIncompleteCheckInOut = false

  isRestrictingUsers$ = this.store.pipe(select(isRestrictingUsers))
  get rentalId() {
    return this.data.rentalId
  }

  constructor(
    private actions: Actions,
    public dialogRef: MatDialogRef<RentalOverlayComponent>,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { rentalId: string; activeTab: RentalDialogTab; highlightedImages?: string[] }
  ) {
    super()
  }

  ngOnInit() {
    this.tab$.next(this.data.activeTab)

    this.store.dispatch(loadRental({ id: this.rentalId }))

    this.store
      .pipe(
        selectSome(selectRentalById(this.rentalId)),
        tap((rental) => {
          this.hasIncompleteCheckInOut = !isSomething(rental.checkin) || !isSomething(rental.checkout)

          const baseRate: any = R.pathOr({}, ['baseRate'], rental)
          this.hasIncompleteBaseRate = !isSomething(baseRate.nightly) || !isSomething(baseRate.minimum)
        }),
        untilDestroy(this)
      )
      .subscribe((rental) => {
        this.rental = rental
      })

    this.actions.pipe(ofType(deleteRentalComplete.type), untilDestroy(this)).subscribe(() => {
      this.close()
    })
  }

  close() {
    this.dialogRef.close()
  }
}
