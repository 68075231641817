import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'

import { AddRateMappingDialogComponent, AddRateMappingDialogInput } from './add-rate-mapping-dialog.component'

@Injectable()
export class AddRateMappingDialogService {
  constructor(private dialog: MatDialog) {}

  public open(data: AddRateMappingDialogInput) {
    const defaultConfig: MatDialogConfig<AddRateMappingDialogInput> = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data,
    }

    return this.dialog.open<AddRateMappingDialogComponent, AddRateMappingDialogInput>(
      AddRateMappingDialogComponent,
      defaultConfig
    )
  }
}
