import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { interactionType } from '@tv3/constants/guests'
import { GuestAddInteractionForm } from '@tv3/interfaces/forms/guest-interaction.form'
import { GuestInteraction } from '@tv3/store/guest/guest.model'
import * as R from 'ramda'
import * as moment from 'moment'

@Component({
  selector: 'app-guest-modify-interaction',
  templateUrl: './guest-modify-interaction-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./guest-modify-interaction-dialog.component.scss'],
})
export class GuestModifyInteractionDialogComponent implements OnInit {
  types = interactionType

  form = this.fb.group({
    type: ['', [Validators.required]],
    date: ['', [Validators.required]],
    comment: ['', [Validators.required, Validators.maxLength(500)]],
  })

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  constructor(
    public dialogRef: MatDialogRef<GuestModifyInteractionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GuestInteraction,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form.patchValue({
      type: R.pathOr('', ['type'], this.data),
      date: moment(R.pathOr('', ['date'], this.data)).toDate(),
      comment: R.pathOr('', ['comment'], this.data),
    })
  }

  close() {
    this.dialogRef.close()
  }

  delete() {
    this.dialogRef.close({ form: null, data: this.data, delete: true })
  }

  @SaveForm()
  save(form: FormGroup) {
    const values = form.getRawValue()
    this.dialogRef.close({ form: { ...values, date: values.date } as GuestAddInteractionForm, data: this.data })
  }
}
