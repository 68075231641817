<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-credit-card"></i> Billing Details</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <i [class]="cardLogo"></i>
  <div>
    <table border="0">
      <tr>
        <td align="right">Name:</td>
        <td>
          &nbsp;&nbsp;<b> {{ data.inquiry.billing.name }} </b>
        </td>
      </tr>
      <tr>
        <td align="right">Card number:</td>
        <td>
          &nbsp;&nbsp;<b> **** - **** - **** -{{ data.inquiry.billing.last4 }} </b>
        </td>
      </tr>
      <tr>
        <td align="right">Expires:</td>
        <td>
          &nbsp;&nbsp;<b> {{ data.inquiry.billing.expMonth }} / {{ data.inquiry.billing.expYear }} </b>
        </td>
      </tr>
      <tr>
        <td align="right">Postal code:</td>
        <td>
          &nbsp;&nbsp;<b> {{ data.inquiry.billing.zip }} </b>
        </td>
      </tr>
      <tr>
        <td align="right">CVC code:</td>
        <td>
          &nbsp;&nbsp;<b> {{ data.inquiry.billing.cvc }} </b>
        </td>
      </tr>
    </table>
    <br />
    This card is saved in your {{ data.inquiry.billing.gateway }} payment gateway
    {{ data.payment.name || data.payment.pkey }}.
  </div>
</div>
<div class="modal-footer">
  <button (click)="deleteCard()" type="button" class="btn btn-secondary-danger">
    <i class="cs-icon cs-icon-trash"></i> delete
  </button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="fal fa-check-square"></i> close
  </button>
</div>
