import { Component, Inject } from '@angular/core'
import { TooltipPosition } from '@angular/material/tooltip'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface ActionDialogItem {
  text: string
  icon?: string
  visible?: boolean
  disabled?: boolean
  tooltip?: string
  tooltipPosition?: TooltipPosition
  tooltipClass?: any
  onClick?: Function
}

export interface ActionDialogInput {
  actions: ActionDialogItem[]
  autoClose?: boolean
}

@Component({
  selector: 'app-action-dialog',
  host: { class: 'modal-content' },
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent {
  actions: ActionDialogItem[]

  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionDialogInput
  ) {}

  onClickAction(action: ActionDialogItem) {
    if (action.onClick) action.onClick()

    if (this.data.autoClose) this.close(action)
  }

  close(action: ActionDialogItem = null) {
    this.dialogRef.close(action)
  }
}
