import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import {
  Destroyable,
  Rental,
  rentalQuantities,
  RentalResponse,
  RentalService,
  SaveForm,
  Toaster,
  updateRentalDetailsComplete,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-vrbo-validation-dialog',
  templateUrl: './vrbo-rental-validation-dialog.component.html',
  styleUrls: ['./vrbo-rental-validation-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class VrboRentalValidationDialogComponent extends Destroyable implements OnInit {
  form = this.fb.group({
    display_name: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(80)]],
    description: ['', [Validators.required, Validators.minLength(400), Validators.maxLength(10000)]],
    bedrooms: ['', [Validators.required]],
    bathrooms: ['', [Validators.required]],
  })

  quantities = rentalQuantities

  constructor(
    public dialogRef: MatDialogRef<VrboRentalValidationDialogComponent>,
    private store: Store<any>,
    private fb: FormBuilder,
    private toaster: Toaster,
    private http: HttpClient,
    private rentalService: RentalService,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit() {
    this.form.patchValue({
      display_name: this.data.rental.displayName,
      description: this.data.rental.description,
      bedrooms: this.data.rental.bedrooms,
      bathrooms: this.data.rental.bathrooms,
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const payload = form.getRawValue()

    this.updateRental(this.data.rental.id, payload)
    this.close()
  }

  private updateRental(
    rentalId: string,
    form: { displayName: string; description: string; bedrooms: number; bathrooms: number }
  ) {
    this.rentalService.update(rentalId, form as any).subscribe((rental) => {
      this.store.dispatch(updateRentalDetailsComplete({ update: { id: rentalId, changes: { ...rental } } }))
      this.close()
    })
  }
}
