import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { PaymentGatewayType } from './gateway-type.model'

export interface GatewayTypeState extends EntityState<PaymentGatewayType> {
  isLoaded: boolean
}

export const gatewayTypeEntityAdapter: EntityAdapter<PaymentGatewayType> = createEntityAdapter<PaymentGatewayType>({
  sortComparer: false,
})

export const initialGatewayTypeState: GatewayTypeState = gatewayTypeEntityAdapter.getInitialState({
  isLoaded: false,
})
