import { FocusMonitor } from '@angular/cdk/a11y'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { Subject } from 'rxjs'
import * as lodash from 'lodash'

import { DateRangePickerSelection } from '../date-range-picker/date-range-picker.component'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { TooltipPosition } from '@angular/material/tooltip'
import { MatFormFieldControl } from '@angular/material/form-field'
import { toMoment } from '../../functions'

@Component({
  selector: 'app-mat-daterange-input',
  templateUrl: './mat-daterange-input.component.html',
  styleUrls: ['mat-daterange-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MatDateRangeInputComponent }],
})
export class MatDateRangeInputComponent
  implements ControlValueAccessor, MatFormFieldControl<DateRangePickerSelection>, OnDestroy, DoCheck
{
  static nextId = 0

  controlType = 'mat-daterange-input'

  @HostBinding() id = `${this.controlType}-${MatDateRangeInputComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''

  stateChanges = new Subject<void>()
  errorState = false

  focused = false
  touched = false

  _value: DateRangePickerSelection = null
  _placeholder: string
  _required = false
  _disabled = false

  @Input() dateFormat = 'DD/MM/YYYY'
  @Input() showInputTooltip: boolean | TooltipPosition = false
  @Input() maxDate: Date
  @Input() minDate: Date

  @Input() local = false
  @Input() showQuickActions = true

  @Output() change = new EventEmitter<DateRangePickerSelection>()

  @ViewChild('popoverDatePicker', { static: true }) popover: NgbPopover

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public elementRef: ElementRef,
    public focusMonitor: FocusMonitor
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      setTimeout(() => {
        let focused = !!origin

        if (!focused) {
          focused = this.popover && this.popover.isOpen()
        }

        this.focused = focused
        this.stateChanges.next()
      })
    })
  }

  ngOnDestroy() {
    this.stateChanges.complete()
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement)
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched
      this.stateChanges.next()
    }
  }

  onChange = (value: DateRangePickerSelection) => {}

  onTouched = () => {
    this.touched = true
  }

  writeValue(value: DateRangePickerSelection): void {
    this.value = value
  }

  registerOnChange(fn): void {
    this.onChange = fn
  }

  registerOnTouched(fn): void {
    this.onTouched = fn
  }

  @Input()
  get value() {
    return this._value
  }

  set value(value: DateRangePickerSelection) {
    this._value = value

    if (this.onChange) this.onChange(value)
    this.stateChanges.next()
  }

  @Input()
  get placeholder() {
    return this._placeholder
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder
    this.stateChanges.next()
  }

  @Input()
  get required() {
    return this._required
  }

  set required(required) {
    this._required = coerceBooleanProperty(required)
    this.stateChanges.next()
  }

  @Input()
  get disabled() {
    return this._disabled
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis)
    this.stateChanges.next()
  }

  get empty() {
    return !this._value || !this.value.from || !this.value.from
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ')
  }

  onChangeFilterDateRange(value: DateRangePickerSelection) {
    setTimeout(() => {
      this.onTouched()

      this.value = value
      this.change.emit(value)
    })
  }

  onContainerClick() {
    if (this.popover) this.popover.open()
  }

  get inputTooltipPosition() {
    return lodash.isString(this.showInputTooltip) ? this.showInputTooltip : 'below'
  }

  getInputText = (value: DateRangePickerSelection) => {
    if (!value) return ''
    return toMoment(value.from).format(this.dateFormat) + ' - ' + toMoment(value.to).format(this.dateFormat)
  }

  getInputTooltip = (value: DateRangePickerSelection, showInputTooltip: boolean | TooltipPosition) => {
    if (!showInputTooltip) return null
    return this.getInputText(value) || null
  }
}
