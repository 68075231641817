import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TaxesOverlayComponent } from './taxes-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class TaxesOverlayService {
  constructor(private dialog: DialogService) {}

  public open(): MatDialogRef<TaxesOverlayComponent> {
    const config = {
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(TaxesOverlayComponent, config)

    return dialogRef
  }
}
