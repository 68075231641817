import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { DiscountCode } from './discount-code.model'

export const LoadDiscountCodes = createAction('[DiscountCode] Load Discount Codes', props<{ reset?: boolean }>())

export const LoadDiscountCodesComplete = createAction(
  '[DiscountCode] Load Discount Codes Complete',
  props<{ items: DiscountCode[]; reset?: boolean }>()
)

export const AddDiscountCode = createAction('[DiscountCode] Add Discount Code', props<{ payload: DiscountCode }>())
export const AddDiscountCodeComplete = createAction(
  '[DiscountCode] Add Discount Code Complete',
  props<{ item: DiscountCode }>()
)

export const GetDiscountCodeComplete = createAction(
  '[DiscountCode] Get Discount Code Complete',
  props<{ item: DiscountCode }>()
)

export const UpdateDiscountCode = createAction(
  '[DiscountCode] Update Discount Code',
  props<{ id: string; payload: DiscountCode }>()
)
export const UpdateDiscountCodeComplete = createAction(
  '[DiscountCode] Update Discount Code Complete',
  props<{ update: Update<DiscountCode> }>()
)

export const DeleteDiscountCode = createAction('[DiscountCode] Delete Discount Code', props<{ id: string }>())
export const DeleteDiscountCodeComplete = createAction(
  '[DiscountCode] Delete Discount Code Complete',
  props<{ id: string }>()
)

export const DeleteDiscountCodes = createAction('[DiscountCode] Delete Discount Codes', props<{ ids: string[] }>())
export const DeleteDiscountCodesComplete = createAction(
  '[DiscountCode] Delete Discount Codes Complete',
  props<{ ids: string[] }>()
)

export const ActivateDiscountCode = createAction('[DiscountCode] Activate Discount Code', props<{ id: string }>())
export const ActivateDiscountCodeComplete = createAction(
  '[DiscountCode] Activate Discount Code Complete',
  props<{ id: string }>()
)

export const DeactivateDiscountCode = createAction('[DiscountCode] Deactivate Discount Code', props<{ id: string }>())
export const DeactivateDiscountCodeComplete = createAction(
  '[DiscountCode] Deactivate Discount Code Complete',
  props<{ id: string }>()
)
