import { createAction, props } from '@ngrx/store'
import { TagsType } from './tag.interfaces'

export const LoadTags = createAction('[Tag] Load Tags')
export const LoadTagsComplete = createAction(
  '[Tag] Load Tags Complete',
  props<{
    id: string
    tags: {
      inquiry: string[]
      invoice: string[]
      guest: string[]
      rental: string[]
      website: string[]
    }
  }>()
)

export const AddNewTags = createAction('[Tag] Add New Tags', props<{ payload: { tags: string[]; type: TagsType } }>())
export const AddNewTagsComplete = createAction(
  '[Tag] Add New Tags Complete',
  props<{
    id: string
    tags: {
      inquiry: string[]
      invoice: string[]
      guest: string[]
      rental: string[]
      website: string[]
    }
  }>()
)
