<div class="p-3">
  <div class="row">
    <div class="col-sm-9">
      <form [formGroup]="form" (submit)="onSubmit(form)" [formEditablePermission]="form">
        <h6>Rental Base Rate Details</h6>

        <div class="row">
          <div
            class="col-sm-6"
            [matTooltip]="(isRategenieSync$ | async) ? 'These can be changed only in Rategenie.' : ''"
            matTooltipPosition="above"
          >
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                min="0"
                matInput
                placeholder="Nightly ({{ currencySymbol }})"
                formControlName="nightly"
                tabindex="11"
                (change)="roundDecimal('nightly')"
              />
            </mat-form-field2>
          </div>

          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                min="0"
                matInput
                placeholder="Weekend Night ({{ currencySymbol }})"
                formControlName="weekend"
                tabindex="12"
                (change)="roundDecimal('weekend')"
              />
            </mat-form-field2>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input
                matTooltip="The weekly value entered here will apply only to bookings created manually in AdvanceCM, or on a AdvanceCM Website or Booking Engine."
                matTooltipPosition="above"
                type="number"
                min="0"
                matInput
                placeholder="Weekly ({{ currencySymbol }})"
                formControlName="weekly"
                tabindex="13"
                (change)="roundDecimal('weekly')"
              />
            </mat-form-field2>
          </div>

          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input
                matTooltip="The monthly value entered here will apply only to bookings created manually in AdvanceCM, or on a AdvanceCM Website or Booking Engine."
                matTooltipPosition="above"
                type="number"
                min="0"
                matInput
                placeholder="Monthly ({{ currencySymbol }})"
                formControlName="monthly"
                tabindex="14"
                (change)="roundDecimal('monthly')"
              />
            </mat-form-field2>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <mat-form-field2
              class="mat-block"
              [matTooltip]="(isRategenieSync$ | async) ? 'These can be changed only in Rategenie.' : ''"
              matTooltipPosition="above"
            >
              <input
                required
                type="number"
                min="1"
                step="1"
                matInput
                placeholder="Minimum Stay (nights)"
                formControlName="minimum"
                tabindex="2"
                (change)="roundInteger('minimum')"
              />
              <mat-error *ngIf="form.get('minimum').touched && form.get('minimum').hasError('required')">
                Minimum Stay is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="form.get('minimum').touched && form.get('minimum').hasError('min')">
                Value must be <strong>at least 1</strong>
              </mat-error>
              <mat-error *ngIf="form.get('minimum').touched && form.get('minimum').hasError('digits')">
                Value is invalid
              </mat-error>
            </mat-form-field2>
          </div>

          <div class="col-sm-6">
            <mat-form-field2
              class="mat-block"
              [matTooltip]="(isRategenieSync$ | async) ? 'These can be changed only in Rategenie.' : ''"
              matTooltipPosition="above"
            >
              <input
                type="number"
                min="0"
                step="1"
                matInput
                placeholder="Maximum Stay (nights)"
                formControlName="maximum"
                tabindex="3"
                (change)="roundInteger('maximum')"
              />
              <mat-error *ngIf="form.get('maximum').touched && form.get('maximum').hasError('digits')">
                Value is invalid
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
        <h6>Additional Guest Fee</h6>

        <div class="row">
          <div class="col-sm-4">
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                matInput
                placeholder="({{ currencySymbol }})"
                formControlName="additionalFeeAmount"
                min="0"
                tabindex="21"
                (change)="roundDecimal('additionalFeeAmount')"
              />
              <mat-hint>per person, per night</mat-hint>
            </mat-form-field2>
          </div>
          <div class="col-sm-4">
            <mat-form-field2 class="mat-block">
              <input
                type="number"
                matInput
                placeholder="Guest Count"
                formControlName="additionalFeeThreshold"
                min="0"
                step="1"
                tabindex="22"
                (change)="roundInteger('additionalFeeThreshold')"
              />
              <mat-hint>above this many guests</mat-hint>
            </mat-form-field2>
          </div>
          <div class="col-sm-4">
            <mat-checkbox
              formControlName="ignoreBDC"
              class="field-ignore-bdc"
              matTooltip="Ignoring Booking.com's additional guest fee is only required if it is already being added to your Extranet calendar.  You may contact Booking.com to have these values set in your Extranet calendar"
            >
              Ignore for booking.com
            </mat-checkbox>
          </div>
        </div>
      </form>
      <div class="d-flex align-items-center gap-3">
        <div class="flex-fill"></div>
        <button
          (click)="clear()"
          [disabled]="(isSaving$ | async) === 'clear' || !isClearable || (1 | isReadonlyRole)"
          type="button"
          class="btn btn-outline-danger create-btn"
          tabindex="52"
          matTooltip="It will be enabled only if any value is added in any of the fee field(s), and it will remove all the added fees."
        >
          <i class=" {{ (isSaving$ | async) === 'clear' ? 'far fa-spinner fa-spin' : 'cs-icon cs-icon-trash' }}"></i>
          Clear
        </button>

        <button
          (click)="onSubmit(form)"
          [disabled]="(isSaving$ | async) === 'save' || (1 | isReadonlyRole)"
          type="button"
          class="btn btn-secondary-info create-btn"
          tabindex="51"
        >
          <i class="fal {{ (isSaving$ | async) === 'save' ? 'fa-spinner fa-spin' : 'fa-save' }}"></i>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
