import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { LosDiscountOverlayComponent } from './los-discount-overlay.component'
import { DialogService } from '@tokeet-frontend/tv3-platform'
import { LosDiscountView } from '@tokeet-frontend/los-discount'

@Injectable()
export class LosDiscountOverlayService {
  constructor(private dialog: DialogService) {}

  open(item: LosDiscountView) {
    const defaultConfig: MatDialogConfig = {
      data: { item },
      panelClass: ['drawer-full-50'],
    }

    const dialogRef = this.dialog.openDrawer(LosDiscountOverlayComponent, defaultConfig)

    return dialogRef
  }
}
