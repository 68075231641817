import { createFeatureSelector, createSelector } from '@ngrx/store'
import { selectAll, selectEntities } from './invoice.reducer'
import { State } from './invoice.state'
import * as R from 'ramda'
import * as moment from 'moment'
import { Invoice } from './invoice.model'
import { currencies } from '@tokeet-frontend/tv3-platform'

export const invoiceState = createFeatureSelector<State>('invoice-lib')

export const selectAllInvoices = createSelector(invoiceState, selectAll)

export const selectInvoiceEntities = createSelector(invoiceState, selectEntities)

export const selectInvoicePagination = createSelector(invoiceState, (state) => state.pagination)

export const selectInvoicesLoaded = createSelector(invoiceState, (state) => state.isLoaded)

export const isInvoiceUpdating = createSelector(invoiceState, (state) => state.isUpdating)

export const selectInvoicesForBookings = (bookingIds: string[]) =>
  createSelector(selectAllInvoices, (items) => items.filter((item) => bookingIds.includes(item.inquiryId)))

export const selectInvoice = createSelector(selectInvoiceEntities, (entities, props) => {
  const invoice = entities[props.id]
  if (invoice) {
    return { ...invoice, currency: R.find((c) => c.code === invoice.currency?.code, currencies) || invoice.currency }
  }
  return invoice
})

export const selectInvoiceInLast30Days = createSelector(selectAllInvoices, (invoices: Invoice[]) => {
  const d30DaysAgo = moment.utc().unix() - 30 * 24 * 3600
  return R.filter((item: Invoice) => {
    return !item.archived && item.invoiceDate > d30DaysAgo
  }, invoices || [])
})
