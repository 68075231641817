import { Injectable, NgZone, TemplateRef } from '@angular/core'
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal'
import { AnimationOption, drawerAnimation, drawerBackdropAnimation } from '../animations'
import * as lodash from 'lodash'

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog, private ngZone: NgZone) {}
  openDrawer<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig
  ): MatDialogRef<T, R> {
    const panelClass = lodash.isArray(config?.panelClass)
      ? config?.panelClass
      : config?.panelClass
      ? [config.panelClass]
      : []

    const ref = this.dialog.open(componentOrTemplateRef, {
      width: '100%',
      height: '100%',
      maxWidth: 'auto',
      position: {
        right: '0px',
        bottom: '0px',
      },
      data: {},
      ...config,
      panelClass: ['modal', 'drawer-right', 'right', ...panelClass],
    })

    return this.animateDialog(ref)
  }

  openFixedDialog<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig
  ): MatDialogRef<T, R> {
    const panelClass = lodash.isArray(config?.panelClass)
      ? config?.panelClass
      : config?.panelClass
      ? [config.panelClass]
      : []

    return this.dialog.open(componentOrTemplateRef, {
      data: {},
      ...config,
      panelClass: ['fixed-modal', ...panelClass],
    })
  }

  openVariableDialog<T, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig
  ): MatDialogRef<T, R> {
    const panelClass = lodash.isArray(config?.panelClass)
      ? config?.panelClass
      : config?.panelClass
      ? [config.panelClass]
      : []

    return this.dialog.open(componentOrTemplateRef, {
      data: {},
      ...config,
      panelClass: ['variable-modal', ...panelClass],
    })
  }

  animateDialog<T, R = any>(
    ref: MatDialogRef<T, R>,
    animation = drawerAnimation,
    backdropAnimation = drawerBackdropAnimation
  ): MatDialogRef<T, R> {
    if (!animation) return ref

    const wrapper: HTMLElement = ref['_overlayRef']['_host']
    const backdrop: HTMLElement = ref['_overlayRef']['_backdropElement']

    const animate = (drawerAnimation: AnimationOption, backdropAnimation: AnimationOption) => {
      backdrop.animate(backdropAnimation.keyframes, backdropAnimation.options)
      return wrapper.animate(drawerAnimation.keyframes, drawerAnimation.options)
    }

    animate(animation.incoming, backdropAnimation.incoming)

    const closeFunction = ref.close

    const closeHandler = (dialogResult?: R) => {
      ref.close = closeFunction // recover the original function

      const animationAction = animate(animation.outgoing, backdropAnimation.outgoing)
      animationAction.onfinish = () => {
        wrapper.style.display = 'none'
        backdrop.style.display = 'none'
        this.ngZone.run(() => ref.close(dialogResult))
      }
    }
    ref.close = (dialogResult?: R) => closeHandler(dialogResult)

    return ref
  }

  closeAll() {
    this.dialog.closeAll()
  }
}
