import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '@tv3/store/state'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { ShareCalendar } from '@tv3/store/calendar/calendar.actions'
import { environment } from '@tv3/environments/environment'
import { observeOn } from 'rxjs/operators'
import { asyncScheduler } from 'rxjs'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-share-calendar-dialog',
  templateUrl: './share-calendar-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./share-calendar-dialog.component.scss'],
})
export class ShareCalendarDialogComponent {
  form = this.fb.group({
    name: ['', [Validators.required]],
    password: ['', []],
    rental: [undefined, [Validators.required]],
    notes: ['', []],
  })

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))

  constructor(
    public dialogRef: MatDialogRef<ShareCalendarDialogComponent>,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder
  ) {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  create(form: FormGroup) {
    const { rental, password, name, notes } = form.getRawValue()
    const payload = {
      shared_id: rental,
      type: 'calendar',
      password: password || this.makePassword(),
      name,
      notes,
      url: environment.config.calendarShareUrl,
    }

    this.store.dispatch(ShareCalendar({ request: payload, protect: !!password }))
    this.close()
  }

  private makePassword() {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  }
}
