<div class="">
  <div class="d-flex align-items-center gap-3 mb-3">
    <h6 class="mb-0">Rental Rooms</h6>
    <div class="flex-fill"></div>
    <button (click)="addEmptyRoom()" class="btn btn-secondary-info" type="button">
      <i class="far fa-plus"></i> Add Room
    </button>
    <div class="dropdown" dropdown>
      <button class="btn btn-secondary-primary dropdown-toggle" dropdownToggle type="button">
        <i class="fal fa-copy"></i>
        Copy
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li>
          <button
            (click)="onCopyFrom()"
            matTooltip="Ability to copy rooms from rental/s to your selected rental"
            matTooltipPosition="left"
          >
            <i class="fal fa-copy"></i> Copy from Rental
          </button>
        </li>
        <li>
          <button
            (click)="onCopyTo()"
            matTooltip="Ability to copy rooms to rental from your selected rental/s"
            matTooltipPosition="left"
          >
            <i class="fal fa-paste"></i> Copy to Rental
          </button>
        </li>
      </ul>
    </div>
    <button (click)="onSave(form)" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span>
      Save
    </button>
  </div>
  <form [formGroup]="form" class="">
    <div class="alert alert-secondary-info">
      <i class="fal fa-info-circle alert-icon"></i>
      <div class="alert-content">
        Some channels require at least one bathroom to be set and associated to a room.<br />
        Please create a room of Room Type "Bathroom" and associate it to some other room by selecting it on "Attached
        Bathroom" field.
      </div>
    </div>

    <div formArrayName="rooms" class="d-flex flex-column gap-3">
      <ng-container *ngFor="let room of roomsArray.controls; let i = index">
        <ng-container [formGroupName]="i">
          <app-room-form
            [form]="room"
            [rental]="rental"
            [rooms]="rooms"
            [bathrooms]="bathrooms"
            [index]="i"
            (remove)="onRemoveRoom($event)"
          ></app-room-form>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
