import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { DataEntityType, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { selectEntitiesWithTags, selectEntityTypeTags, uniqTags } from '../../store'
import { FormControl } from '@angular/forms'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { distinctUntilChanged, switchMap } from 'rxjs/operators'
import * as lodash from 'lodash'

@Component({
  selector: 'app-entity-filter-tags',
  templateUrl: './entity-filter-tags.component.html',
  styleUrls: ['./entity-filter-tags.component.scss'],
})
export class EntityFilterTagsComponent extends Destroyable implements OnInit {
  @Input() ctrl = new FormControl([])
  @Input() entityIdsCtrl = new FormControl([])
  @Input() type: DataEntityType
  @Input() placeholder = 'Tags'
  @Input() inline = true

  tags$ = new BehaviorSubject<string[]>([])
  @Input() set tags(t: string[]) {
    // back compatible, existing tags
    this.tags$.next(t || [])
  }

  @Output() openedChange = new EventEmitter<boolean>()

  availableTags: string[] = []
  tagsChange = new Subject<string[]>()

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit(): void {
    combineLatest([
      this.tags$.pipe(distinctUntilChanged((a, b) => lodash.isEqual(a, b))),
      this.store.pipe(select(selectEntityTypeTags(this.type))),
    ])
      .pipe(untilDestroy(this))
      .subscribe(([tags1, tags2]) => {
        this.availableTags = uniqTags([...tags1, ...tags2])
      })
    this.tagsChange
      .pipe(
        switchMap((tags) => this.store.pipe(select(selectEntitiesWithTags(this.type, tags)))),
        untilDestroy(this)
      )
      .subscribe((data) => {
        this.entityIdsCtrl.setValue(data.map((t) => t.entity_pkey))
      })
  }

  onSelectChange(tags: string[]) {
    this.tagsChange.next(tags)
  }
}
