import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { TagService } from './tag.service'
import { AddNewTags, AddNewTagsComplete, LoadTags, LoadTagsComplete } from './tag.actions'
import { selectSomeOnce } from '../../rx-operators/select-something-once'
import { tagState } from './tag.selectors'
import { ActionFailed } from '../utility'

@Injectable()
export class TagEffects {
  @Effect()
  loadTags$ = this.actions$.pipe(
    ofType(LoadTags),
    switchMap(() =>
      this.tags.all().pipe(
        map(({ id, tags }) => LoadTagsComplete({ id, tags })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addNewTags$ = this.actions$.pipe(
    ofType(AddNewTags),
    switchMap(({ payload }) =>
      this.store.pipe(
        selectSomeOnce(tagState),
        map((state) => ({ payload, state }))
      )
    ),
    switchMap(({ payload, state }) =>
      this.tags.addTags(payload.type, state, payload.tags).pipe(
        map((result) => AddNewTagsComplete(result)),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<any>, private tags: TagService) {}
}
