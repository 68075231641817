<mat-form-field2 class="mat-block" [showLabel]="!inline">
  <app-select
    [required]="required"
    [items]="timezoneList"
    [searchable]="true"
    [formControl]="ctrl"
    [placeholder]="label"
  >
  </app-select>
</mat-form-field2>
