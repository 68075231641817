import * as moment from 'moment'

export const TIME_FORMAT = 'h:mm A'

type FormatTimeOptions = { hour: number; min: number } | moment.Moment

export function formatTime(options: FormatTimeOptions | null | undefined) {
  if (!options) return ''
  if (moment.isMoment(options)) return options.format(TIME_FORMAT)
  return moment(options.hour + ':' + options.min, 'H:m').format(TIME_FORMAT)
}

export function parseTime(time: string) {
  time = String(time || '').trim()
  return time ? moment(time, TIME_FORMAT) : null
}

export function to24HourTime(time: FormatTimeOptions | string | null | undefined) {
  const s = typeof time === 'string' ? time : formatTime(time)
  const m = parseTime(s)
  if (!m) return ''

  return m.format('HH:mm')
}
