import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromIntegration from './integration.reducer'
import { EffectsModule } from '@ngrx/effects'
import { IntegrationEffects } from './integration.effects'
import { STORE_ID } from './integration.state'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(STORE_ID, fromIntegration.integrationReducer),
    EffectsModule.forFeature([IntegrationEffects]),
  ],
  declarations: [],
})
export class IntegrationModule {}
