<app-message-list-item-base
  #msg
  [message]="message"
  [inquiry]="inquiry"
  [collapsed]="collapsed"
  (collapse)="toggleCollapsing($event)"
  [currencySymbol]="currencySymbol"
  [viewPlainText]="viewPlainText"
  (editDraft)="editDraft.emit($event)"
  (aiGenerate)="aiGenerate.emit($event)"
  class=""
>
  <ng-container actions>
    <div class="d-flex ali-items-center gap-2">
      <a
        (click)="toggleCollapsing(!collapsed)"
        [matTooltip]="collapsed ? 'Expand message' : 'Collapse message'"
        matTooltipPosition="above"
        class="text-light d-inline-flex"
      >
        <i class="cs-icon text-md" [ngClass]="collapsed ? 'cs-icon-expand2' : 'cs-icon-collapse2'"></i>
      </a>

      <i class="fal fa-exclamation-triangle text-danger" *ngIf="isSpam" matTooltip="Spam"></i>
      <a
        matTooltip="Message details"
        matTooltipPosition="above"
        (click)="onDetails(message, inquiry)"
        class="text-light d-inline-flex"
      >
        <i class="fal fa-info-circle"></i>
      </a>
      <a matTooltip="Print Message" matTooltipPosition="above" (click)="onPrint()" class="text-light d-inline-flex">
        <i class="fal fa-print"></i>
      </a>
      <a
        *ngIf="hasMove && !(1 | isReadonlyRole)"
        matTooltip="Move to other inquiry"
        matTooltipPosition="above"
        (click)="onMove()"
        class="text-light d-inline-flex"
      >
        <i class="fal fa-share"></i>
      </a>
      <a
        matTooltip="Delete"
        *ngIf="!(1 | isReadonlyRole)"
        matTooltipPosition="above"
        (click)="onRemove()"
        class="text-light d-inline-flex"
      >
        <i class="cs-icon cs-icon-trash"></i>
      </a>
      <a
        (click)="viewPlainText = !viewPlainText"
        [class.active]="viewPlainText"
        [matTooltip]="viewPlainText ? 'View styled text' : 'View plain text'"
        matTooltipPosition="above"
        class="text-light d-inline-flex"
      >
        <i class="fal fa-file-alt"></i>
      </a>
    </div>
  </ng-container>
</app-message-list-item-base>

<!-- For Print -->
<div #printableMessageContent class="d-none">
  <ul style="list-style: none; padding: 0">
    <li *ngIf="messageDetails?.from"><b>From:</b> {{ messageDetails?.from }}</li>
    <li *ngIf="messageDetails?.to"><b>To:</b> {{ messageDetails?.to }}</li>
    <li *ngIf="messageDetails?.cc"><b>CC:</b> {{ messageDetails?.cc }}</li>
    <li *ngIf="messageDetails?.bcc"><b>BCC:</b> {{ messageDetails?.bcc }}</li>
    <li *ngIf="messageDetails?.receivedOn"><b>Date:</b> {{ messageDetails?.receivedOn | epoch }}</li>
    <li *ngIf="messageDetails?.subject"><b>Subject:</b> {{ messageDetails?.subject }}</li>
    <li *ngIf="messageDetails?.id"><b>Id:</b> {{ messageDetails?.id }}</li>
  </ul>
  <div [innerHtml]="message.sanitizedMsgHtml"></div>
</div>
