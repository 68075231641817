import { Pipe, PipeTransform } from '@angular/core'
import { DateFormatType, formatDate } from '../functions'

@Pipe({
  name: 'epoch',
})
export class EpochPipe implements PipeTransform {
  transform(value: any, format?: DateFormatType, defaultValue = 'Never'): string {
    return formatDate(value, format, false, defaultValue)
  }
}
