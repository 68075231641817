import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Destroyable, Rental, selectRentalById, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { Inquiry, InquiryPaymentStatus, InquirySourceType } from '@tv3/store/inquiry/inquiry.model'
import { BehaviorSubject } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-booking-paid-status',
  template: `
    <span
      [matTooltip]="
        remaining && status?.remaining
          ? 'Remaining: ' + (status?.remaining | tokeetCurrency : rental?.currency)
          : (getPaymentStatusTooltip | call : inquiry)
      "
    >
      {{ status?.status }}
    </span>
  `,
  styles: [
    `
      :host {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingPaidStatusComponent extends Destroyable implements OnInit {
  @Input() pill = true
  @Input() remaining = true

  private _inquiry$ = new BehaviorSubject<Inquiry>(null)
  @Input() set inquiry(inquiry: Inquiry) {
    this._inquiry$.next(inquiry)
  }
  get inquiry() {
    return this._inquiry$.value
  }

  @HostBinding('class') get hostClass() {
    if (!this.pill) return ''
    return `badge badge-pill ${this.status?.status === InquiryPaymentStatus.Paid ? 'badge-success' : 'badge-danger'}`
  }

  rental: Rental

  @HostBinding('class.d-inline-block')
  status: { paid: boolean; remaining: number; status: InquiryPaymentStatus }

  constructor(
    private store: Store<any>,
    private inquiryChargeService: InquiryChargeService,
    private cd: ChangeDetectorRef
  ) {
    super()
  }

  ngOnInit(): void {
    const booking$ = this._inquiry$.pipe(filter((inquiry) => !!inquiry))
    booking$
      .pipe(
        switchMap((b) => this.store.pipe(select(selectRentalById(b.rentalId)))),
        untilDestroy(this)
      )
      .subscribe((rental) => {
        this.rental = rental
        this.cd.markForCheck()
      })

    booking$
      .pipe(
        switchMap((inquiry) => this.inquiryChargeService.getBookingPaymentStatusForInquiry(inquiry)),
        untilDestroy(this)
      )
      .subscribe((status) => {
        this.status = status
        this.cd.markForCheck()
      })
  }

  getPaymentStatusTooltip(inquiry: Inquiry) {
    switch (inquiry?.sourceType) {
      case InquirySourceType.Airbnb:
      case InquirySourceType.Vrbo:
      case InquirySourceType.FlipKey:
        // tslint:disable-next-line:max-line-length
        return 'Payment status for this channel is taken directly from the originating channel.  When a booking is paid on the channel website, this status will update accordingly.'
      case InquirySourceType.Booking:
      case InquirySourceType.Expedia:
      case InquirySourceType.Agoda:
        // tslint:disable-next-line:max-line-length
        return 'The payment status reflected here does not account for any payments made outside of AdvanceCM invoicing.  Once the total of paid AdvanceCM invoices equals the booking charges shown on the booking, the status will automatically change to paid.  If you do not want to invoice through AdvanceCM, you can manually mark the booking as paid by using the Actions button on the booking details overlay.'
      default:
        // tslint:disable-next-line:max-line-length
        return 'The payment status reflected here does not account for any payments made on the channel listing site.  You can either create an invoice that equals the booking charges shown or manually mark the booking as paid by using the Actions button on the booking details overlay.'
    }
  }
}
