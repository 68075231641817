import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MessagingSettingsService } from './service'

@NgModule({
  imports: [CommonModule],
  providers: [MessagingSettingsService],
  declarations: [],
})
export class MessagingSettingsStoreModule {}
