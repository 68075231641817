import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { AddWebsiteDialogComponent } from '@tv3/containers/websites/dialogs/add-website-dialog/add-website-dialog.component'
import { WebsiteOverlayComponent } from '@tv3/containers/websites/overlays/website-overlay.component'
import { RentalsSharedModule } from '@tv3/containers/rentals/rentals-shared.module'
import { WebsiteComponentsComponent } from '@tv3/containers/websites/overlays/details/website-components/website-components.component'
import { WebsiteContactComponent } from '@tv3/containers/websites/overlays/details/website-contact/website-contact.component'
import { WebsiteLinksComponent } from '@tv3/containers/websites/overlays/details/website-links/website-links.component'
import { WebsiteResourcesComponent } from '@tv3/containers/websites/overlays/details/website-resources/website-resources.component'
import { WebsiteImagesComponent } from '@tv3/containers/websites/overlays/details/website-images/website-images.component'
import { WebsiteDetailsComponent } from '@tv3/containers/websites/overlays/details/website-details/website-details.component'
import { WebsiteDetailsEngineComponent } from '@tv3/containers/websites/overlays/details/website-details-engine/website-details-engine.component'
import { WebsiteDetailsPortalComponent } from '@tv3/containers/websites/overlays/details/website-details-portal/website-details-portal.component'
import { WebsiteDetailsWebComponent } from '@tv3/containers/websites/overlays/details/website-details-web/website-details-web.component'
import { WebsiteDetailsRentalsComponent } from '@tv3/containers/websites/overlays/details/website-details-rentals/website-details-rentals.component'
import { WebsiteOtherPagesComponent } from '@tv3/containers/websites/overlays/details/website-other-pages/website-other-pages.component'
import { WebsiteTemplateSettingsComponent } from '@tv3/containers/websites/overlays/details/website-template-settings/website-template-settings.component'
import { EditorModule } from '@tinymce/tinymce-angular'
import { WebsitePageDialogComponent } from '@tv3/containers/websites/dialogs/website-page-dialog/website-page-dialog.component'
import { ConfigWebsiteDomainDialogComponent } from '@tv3/containers/websites/dialogs/config-website-domain-dialog/config-website-domain-dialog.component'
import { WebsiteCustomDomainSslDialogComponent } from '@tv3/containers/websites/dialogs/website-custom-domain-ssl-dialog/website-custom-domain-ssl-dialog.component'
import { WebsiteCustomDomainSslComponent } from '@tv3/containers/websites/overlays/details/website-custom-domain-ssl/website-custom-domain-ssl.component'
import { SSLModule } from '@tokeet-frontend/ssl'

const components = [
  WebsiteDetailsComponent,
  WebsiteDetailsEngineComponent,
  WebsiteDetailsPortalComponent,
  WebsiteDetailsWebComponent,
  WebsiteDetailsRentalsComponent,
  WebsiteComponentsComponent,
  WebsiteOtherPagesComponent,
  WebsiteTemplateSettingsComponent,
  WebsiteContactComponent,
  WebsiteLinksComponent,
  WebsiteResourcesComponent,
  WebsiteImagesComponent,
  WebsiteCustomDomainSslComponent,
]
const dialogs = [
  AddWebsiteDialogComponent,
  WebsitePageDialogComponent,
  WebsiteOverlayComponent,
  ConfigWebsiteDomainDialogComponent,
  WebsiteCustomDomainSslDialogComponent,
]

@NgModule({
  imports: [CommonModule, SharedModule, RentalsSharedModule, EditorModule, SSLModule],
  declarations: [...components, ...dialogs],
  exports: [...components, ...dialogs],
})
export class WebsitesSharedModule {}
