import { Pipe, PipeTransform } from '@angular/core'
import { DateFormatType, formatDate } from '../functions'

@Pipe({
  name: 'epochUTC',
})
export class EpochUTCPipe implements PipeTransform {
  transform(value: any, format: DateFormatType = 'daydate', defaultValue = 'Never'): string {
    return formatDate(value, format, true, defaultValue)
  }
}
