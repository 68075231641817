<div class="modal-header">
  <h5 class="modal-title">{{ data.opportunity.title }}</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <p>
    {{ data.opportunity.description }}
  </p>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <app-select-search
          [items]="connections"
          [ctrl]="form.get('listing_ids')"
          bindValue="roomId"
          bindLabel="roomName"
          placeholder="Apply to listings"
          [inline]="false"
        >
        </app-select-search>
      </div>
      <ng-container *ngFor="let field of data.opportunity.input_fields; let i = index">
        <div [ngSwitch]="field.value_constraint.value_type" class="col-md-6">
          <ng-container *ngSwitchCase="'INTEGER'">
            <mat-form-field2 class="d-block">
              <input
                matInput
                type="number"
                [placeholder]="field.key_label || data.opportunity.title"
                [formControlName]="field.key | snakeCase"
                required
              />
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('required')"
              >
                value is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('max')"
              >
                Max value is <strong>{{ field.value_constraint.max_value }}</strong>
              </mat-error>
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('min')"
              >
                Min value is <strong>{{ field.value_constraint.min_value }}</strong>
              </mat-error>
            </mat-form-field2>
          </ng-container>
          <ng-container *ngSwitchCase="'PERCENTAGE'">
            <mat-form-field2 class="d-block">
              <input
                matInput
                type="number"
                [placeholder]="field.key_label || data.opportunity.title"
                [formControlName]="field.key | snakeCase"
                required
              />
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('required')"
              >
                value is <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('max')"
              >
                Max value is <strong>{{ field.value_constraint.max_value }}</strong>
              </mat-error>
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('min')"
              >
                Min value is <strong>{{ field.value_constraint.min_value }}</strong>
              </mat-error>
            </mat-form-field2>
          </ng-container>
          <ng-container *ngSwitchCase="'OPTION'">
            <mat-form-field2 class="d-block">
              <mat-select
                [formControlName]="field.key | snakeCase"
                [placeholder]="field.key_label || data.opportunity.title"
                required
              >
                <mat-option *ngFor="let option of field.value_constraint.options" [value]="option">
                  {{ option | capitalize }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.get(field.key | snakeCase).touched && form.get(field.key | snakeCase).hasError('required')"
              >
                value is <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="onSave(form)" [disabled]="isLoading" type="button" class="btn btn-secondary-info create-btn">
    <ng-container *ngIf="!data.opportunity?.input_fields?.length"> <i class="fal fa-check"></i> activate </ng-container>
    <ng-container *ngIf="!!data.opportunity?.input_fields?.length">
      <i class="cs-icon cs-icon-save"></i> save
    </ng-container>
  </button>
</div>
