import { initialState, State } from './channels.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  CreateHomeAwayChannelConfigSuccess,
  CreateRentalConfigSuccess,
  DisableAllFeedRentalsSuccess,
  DisableFeedRentalsSuccess,
  EnableAllFeedRentalsSuccess,
  EnableFeedRentalsSuccess,
  LoadChannelConfigSuccess,
  LoadRentalConfigsSuccess,
  PauseHomeAwayChannelConfigSuccess,
  PauseRentalConfigSuccess,
  ResumeHomeAwayChannelConfigSuccess,
  ResumeRentalConfigSuccess,
  SubmitFormSuccess,
  UpdateChannelConfigSuccess,
  UpdateHomeAwayChannelConfigSuccess,
  UpdateRentalConfigSuccess,
} from './channels.actions'
import * as R from 'ramda'
import { getTiketReducers } from './tiket/reducer'
import { getGVRReducers } from './gvr/reducer'
import { getRentalsUnitedReducers } from './rentals-united/reducer'
import { getHoliduReducers } from './holidu/reducer'
import { getHVMIReducers } from './hvmi/reducer'
import { ChannelNameTokens } from '@tokeet-frontend/tv3-platform'
import { getCtripReducers } from './ctrip/reducer'

export const reducer = createReducer(
  initialState,
  on(
    LoadChannelConfigSuccess,
    SubmitFormSuccess,
    CreateHomeAwayChannelConfigSuccess,
    UpdateHomeAwayChannelConfigSuccess,
    (state, { config, name }) => ({
      ...state,
      configs: {
        ...state.configs,
        [name]: config,
      },
      configLoaded: {
        ...state.configLoaded,
        [name]: true,
      },
    })
  ),
  on(PauseHomeAwayChannelConfigSuccess, (state) => {
    const configs = state.configs
    return {
      ...state,
      configs: {
        ...state.configs,
        [ChannelNameTokens.Homeaway]: {
          ...configs[ChannelNameTokens.Homeaway],
          status: 0,
        },
      },
    }
  }),
  on(ResumeHomeAwayChannelConfigSuccess, (state) => {
    const configs = state.configs
    return {
      ...state,
      configs: {
        ...state.configs,
        [ChannelNameTokens.Homeaway]: {
          ...configs[ChannelNameTokens.Homeaway],
          status: 1,
        },
      },
    }
  }),
  on(UpdateChannelConfigSuccess, (state, { config, channel }) => {
    return {
      ...state,
      configs: {
        ...state.configs,
        [channel.name]: config,
      },
    }
  }),
  on(LoadRentalConfigsSuccess, (state, { configs, channel }) => {
    return {
      ...state,
      rentalConfigs: {
        ...state.rentalConfigs,
        [channel]: configs,
      },
    }
  }),
  on(PauseRentalConfigSuccess, ResumeRentalConfigSuccess, (state, { config, channel }) => {
    return {
      ...state,
      rentalConfigs: {
        [channel]: R.map((c) => (c.rental_id === config.rental_id ? config : c), state.rentalConfigs[channel]),
      },
    }
  }),
  on(EnableAllFeedRentalsSuccess, (state, { channel, configs }) => {
    return {
      ...state,
      rentalConfigs: {
        [channel]: configs,
      },
    }
  }),
  on(DisableAllFeedRentalsSuccess, (state, { channel, configs }) => {
    return {
      ...state,
      rentalConfigs: {
        [channel]: configs,
      },
    }
  }),
  on(EnableFeedRentalsSuccess, DisableFeedRentalsSuccess, (state, { channel, configs }) => {
    return {
      ...state,
      rentalConfigs: {
        [channel]: R.reduce(
          (result, config) => {
            const updatedConfig = R.find((c) => c.rental_id === config.rental_id, configs)
            if (!R.isNil(updatedConfig)) {
              result = R.append(updatedConfig, result)
            } else {
              result = R.append(config, result)
            }
            return result
          },
          [],
          state.rentalConfigs[channel]
        ),
      },
    }
  }),
  on(UpdateRentalConfigSuccess, CreateRentalConfigSuccess, (state, { config, channel }) => {
    return {
      ...state,
      rentalConfigs: {
        [channel.name]: R.map(() => config, state.rentalConfigs[channel.name]),
      },
    }
  }),
  ...getTiketReducers(),
  ...getCtripReducers(),
  ...getGVRReducers(),
  ...getHVMIReducers(),
  ...getHoliduReducers(),
  ...getRentalsUnitedReducers()
)

export function channelsReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}
