import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '@tv3/shared/shared.module'
import { QRCodeModule } from 'angularx-qrcode'

import { UserDialogComponent } from '@tv3/containers/settings/user-settings/user-dialog/user-dialog.component'
import { UserOverlayComponent } from '@tv3/containers/settings/user-settings/overlays/user-overlay.component'
import { IsRestrictedPipe } from '@tv3/containers/settings/user-settings/overlays/is-restricted.pipe'
import { IsAllRestrictedPipe } from '@tv3/containers/settings/user-settings/overlays/is-all-restricted.pipe'
import { OwnerRentalsComponent } from '@tv3/containers/settings/owner-rentals/owner-rentals.component'
import { UsersLibModule } from '@tokeet-frontend/users'
import { RestrictedRentalsComponent } from './restricted-rentals/restricted-rentals.component'
import { UserMfaDialogComponent } from './user-settings/user-mfa-dialog/user-mfa-dialog.component'
import { DownloadUserSettingsDialogService } from '@tv3/containers/settings/user-settings/dialogs/download/download-user-settings-dialog.service'

const pipes = [IsRestrictedPipe, IsAllRestrictedPipe]

const components = [
  //
  OwnerRentalsComponent,
  RestrictedRentalsComponent,
  UserMfaDialogComponent,
]

const dialogs = [
  //
  UserDialogComponent,
  UserOverlayComponent,
]

const services = [
  //
  DownloadUserSettingsDialogService,
]

@NgModule({
  imports: [
    //
    SharedModule,
    CommonModule,
    UsersLibModule,
    QRCodeModule,
  ],
  declarations: [...components, ...dialogs, ...pipes],
  exports: [...components, ...dialogs, ...pipes],
  providers: [...services],
})
export class SettingsSharedModule {}
