import { Component, Inject, OnInit } from '@angular/core'
import { Rental, SaveForm } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as R from 'ramda'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface SelectRentalDialogConfig {
  rentals?: Rental[]
  selectedId?: string
  title?: string
  help?: string
  buttonIcon?: string
  buttonText?: string
}

@Component({
  selector: 'app-select-rental-dialog',
  templateUrl: './select-rental-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./select-rental-dialog.component.scss'],
})
export class SelectRentalDialogComponent extends Destroyable implements OnInit {
  config = {
    title: 'Select Rental',
    help: 'Please select a rental from the list above.',
    buttonText: 'Select',
  } as SelectRentalDialogConfig

  form = this.fb.group({
    rental: [undefined, [Validators.required]],
  })

  rentals: Rental[] = []

  constructor(
    public dialogRef: MatDialogRef<SelectRentalDialogComponent>,
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { config: SelectRentalDialogConfig }
  ) {
    super()
  }

  ngOnInit() {
    if (this.data.config?.rentals) {
      this.rentals = this.data.config.rentals
    } else {
      this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
        this.rentals = rentals
      })
    }

    this.form.patchValue({ rental: this.data.config?.selectedId })

    this.config = { ...this.config, ...this.data.config }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = this.form.getRawValue()
    const rental = R.find((r: Rental) => r.id === formValues.rental, this.rentals)
    this.dialogRef.close(rental)
  }
}
