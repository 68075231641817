import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectTiketWizardComponent } from './connect-tiket-wizard.component'
import { ConnectTiketWizardCompleteComponent } from './complete/connect-tiket-wizard-complete.component'
import { ConnectTiketWizardStep1Component } from './step1/connect-tiket-wizard-step1.component'
import { ConnectTiketWizardStep2Component } from './step2/connect-tiket-wizard-step2.component'
import { ConnectTiketWizardStep3Component } from './step3/connect-tiket-wizard-step3.component'

const components = [
  // Tiket
  ConnectTiketWizardStep1Component,
  ConnectTiketWizardStep2Component,
  ConnectTiketWizardStep3Component,
  ConnectTiketWizardCompleteComponent,
  ConnectTiketWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectTiketWizardModule {}
