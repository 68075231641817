import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import { NoteResponse } from '@tv3/store/note/note.model'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { NoteForm } from '@tv3/interfaces/forms/note.form'

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  create(inquiryId: string, form: NoteForm, silent = false): Observable<NoteResponse> {
    const url = `@api/inquiry/note/${inquiryId}`

    return this.http.put<NoteResponse>(url, form).pipe(
      tap(() => {
        if (!silent) {
          this.toast.success('Comment added successfully!')
        }
      })
    )
  }

  update(inquiryId: string, note: NoteResponse, form: NoteForm): Observable<NoteResponse> {
    const url = `@api/inquiry/note/update/${inquiryId}`

    const request = { ...note, note: form.note }

    return this.http.put<NoteResponse>(url, request).pipe(
      tap(() => this.toast.success('Comment updated successfully!')),
      catchError(() => throwError({ error: 'Unable to update comment' }))
    )
  }

  delete(inquiryId: string, note: NoteResponse): Observable<NoteResponse> {
    const url = `@api/inquiry/note/delete/${inquiryId}`

    return this.http.put<NoteResponse>(url, note).pipe(
      tap(() => this.toast.success('Comment deleted successfully!')),
      catchError(() => throwError({ error: 'Unable to delete comment' }))
    )
  }
}
