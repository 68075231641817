<div class="container-box shadow-none" style="background: #fbfbff">
  <div class="p-3">
    <div class="card">
      <div class="filters-box">
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected cities."
          matTooltipPosition="above"
          id="rentals-filters-cities"
          [items]="cities$ | async"
          [ctrl]="citiesCtrl"
          placeholder="Rentals by Cities"
        ></app-select-search>
        <app-select-search
          class="w-auto"
          matTooltip="Only show rentals that specify the selected tags."
          matTooltipPosition="above"
          id="rentals-filters-tags"
          [items]="tags$ | async"
          [ctrl]="tagsCtrl"
          placeholder="Rentals by Tags"
        ></app-select-search>
        <mat-form-field2 [showLabel]="false" class="w-auto">
          <mat-select [formControl]="statusCtrl" placeholder="Connection Status">
            <mat-option value="all">All</mat-option>
            <mat-option value="active">Active</mat-option>
            <mat-option value="inactive">Inactive</mat-option>
          </mat-select>
        </mat-form-field2>
        <app-cancel-filters
          [isFiltering]="isFiltering"
          (clear)="onCancelFilters()"
          [searchCom]="search"
        ></app-cancel-filters>
        <button
          (click)="refresh()"
          matTooltip="Refresh the list"
          matTooltipPosition="above"
          class="btn btn-secondary-primary"
        >
          <i class="far fa-sync"></i>
        </button>
        <div class="flex-fill"></div>
        <app-table-search (search)="searchCtrl.setValue($event)" #search></app-table-search>
      </div>

      <div class="table-holder table-responsive">
        <table class="table table-default table-striped">
          <mat-table #table matSort [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  *ngIf="!allInvalid()"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div *ngIf="validations[row.propertyId]?.length > 0" class="expandable-control">
                  <i *ngIf="row !== expandedElement" (click)="setExpanded(row)" class="far fa-chevron-right"></i>
                  <i *ngIf="row === expandedElement" (click)="setExpanded(null)" class="far fa-chevron-down"></i>
                </div>
                <mat-checkbox
                  *ngIf="!(validations[row.propertyId]?.length > 0)"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                ></mat-checkbox>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rental_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Linked Rental</mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-truncate">
                {{ element.rental_name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastSync">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Last Sync</mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-truncate">
                {{ element.lastSync || element.createdAt | epoch }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hmcId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> HMC ID</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.hmcId }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="listingId">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Listing ID</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.listingId }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hvmiStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Hvmi Status</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.hvmiStatus | titleCase }}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="listingStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Listing Status</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span class="status-line">{{ element.listingStatus | titleCase }}</span>
                <app-form-switch
                  [matTooltip]="
                    element.listingStatus === 'active' ? 'Deactivate listing on HVMI' : 'Activate listing on HVMI'
                  "
                  matTooltipPosition="above"
                  *ngIf="element.listingStatus !== 'draft' && element.hvmiStatus === 'approved'"
                  [disabled]="element.detailRow"
                  (click)="$event.stopPropagation()"
                  [id]="element.id"
                  [value]="element.listingStatus === 'active'"
                  (valueChange)="onToggleStatus(element)"
                ></app-form-switch>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> action</mat-header-cell>
              <mat-cell *matCellDef="let element" class="d-flex gap-2">
                <ng-container *ngIf="!(1 | isReadonlyRole)">
                  <button
                    *ngIf="element.listingStatus !== 'draft'"
                    (click)="onEditListing(element)"
                    class="btn btn-secondary-info btn-sm"
                    type="button"
                    style="min-width: auto"
                  >
                    <i class="cs-icon cs-icon-pencil"></i>
                  </button>

                  <button
                    *ngIf="!element.detailRow && (validationsLoaded$ | async) && element.listingStatus === 'draft'"
                    (click)="onEditListing(element)"
                    class="btn btn-secondary-info btn-sm"
                    type="button"
                  >
                    Create
                  </button>

                  <button
                    *ngIf="element.listingStatus !== 'draft'"
                    (click)="onDeleteListing(element)"
                    class="btn btn-secondary-danger btn-sm"
                    type="button"
                    style="min-width: auto"
                  >
                    <i class="cs-icon cs-icon-trash"></i>
                  </button>

                  <button
                    *ngIf="element.detailRow"
                    (click)="setExpanded(expandedElement === element ? null : element)"
                    class="btn btn-sm btn-secondary-info"
                    type="button"
                  >
                    Fix
                  </button>
                </ng-container>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <mat-cell *matCellDef="let detail" class="p-0 bg-white">
                <div class="validation-list">
                  <div *ngFor="let error of validations[detail.propertyId]" class="invalid-item">
                    <i class="text-lg fas fa-exclamation-circle text-danger"></i>
                    <ng-container *ngFor="let e of error.errors">{{ e }}</ng-container>
                    <div class="flex-fill"></div>
                    <button
                      (click)="onFixRental(detail.rental, error)"
                      type="button"
                      class="btn btn-sm btn-secondary-info"
                    >
                      fix it <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
                    </button>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ expanded: expandedElement == row, 'row-danger': row.detailRow }"
              class="element-row"
            >
            </mat-row>

            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
              [ngClass]="{ 'empty-cell': validations[row?.rental?.id]?.length === 0 }"
              [@detailExpand]="row?.rental?.id == expandedElement?.rental?.id ? 'expanded' : 'collapsed'"
              style="overflow: hidden"
            >
            </mat-row>
          </mat-table>

          <ng-container *ngIf="isEmptyTable$ | async">
            <div class="empty-box">You haven't created any rentals on your account yet</div>
          </ng-container>

          <mat-paginator2
            #paginator
            [class.hidden]="isEmptyTable$ | async"
            [length]="dataSource?.data?.length"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 75, 100]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator2>
        </table>
      </div>
    </div>
  </div>
  <div class="border-top p-4 mt-4 d-flex align-items-center gap-3">
    <button class="btn btn-outline-light" (click)="prev.emit()">
      <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
      Previous
    </button>
    <div class="flex-fill"></div>
    <button (click)="onBookingFormula()" [disabled]="1 | isReadonlyRole" type="button" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-calendar-cog"></span> Set Booking Formula
    </button>

    <button (click)="onComplete()" type="button" class="btn btn-secondary-success">
      Complete <i class="fas fa-check"></i>
    </button>
  </div>
</div>
