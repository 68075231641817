import { Component, OnInit, ViewChild } from '@angular/core'
import {
  ConfirmDialogService,
  ConfirmDialogStatus,
  DataCheckerService,
  Destroyable,
  DialogService,
  filterPredicateFactory,
  isEmptyTable,
  selectAllRentals,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FilterGroup } from '@tv3/services/utils/filters.service'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { finalize, observeOn, take } from 'rxjs/operators'
import { asyncScheduler, BehaviorSubject } from 'rxjs'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { createLocaleCompareSort } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeFilter } from '@tv3/store/discount-code/discount-code.filter'
import {
  ActivateDiscountCode,
  DeactivateDiscountCode,
  DeleteDiscountCode,
  DiscountCode,
  DiscountCodeGuard,
  DiscountCodeView,
  LoadDiscountCodes,
  LoadDiscountCodesComplete,
  selectDiscountCodesLoaded,
} from '@tokeet-frontend/discount-codes'
import { MatPaginator } from '@angular/material/paginator'
import { FormSwitchComponent } from '@tokeet-frontend/tv3-platform'
import { DiscountCodeOverlayService } from './overlay/discount-code-overlay.service'
import { AddDiscountCodeDialogComponent } from './add/add-discount-code-dialog.component'

@Component({
  selector: 'app-discount-codes-table',
  templateUrl: './discount-codes-table.component.html',
  styleUrls: ['./discount-codes-table.component.scss'],
})
export class DiscountCodesTableComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = [
    'name',
    'amount',
    'code',
    'rentalView',
    'startView',
    'expiresView',
    'usedView',
    'leftView',
    'status',
    'edit',
  ]

  dataSource = new MatTableDataSource<DiscountCodeView>()

  filterGroup = FilterGroup.DiscountCodes
  tableType = TableType.DiscountCodes
  isEmptyTable$ = isEmptyTable(this.dataSource)

  isLoaded$ = this.store.pipe(select(selectDiscountCodesLoaded))

  items: DiscountCodeView[] = []
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  filters = this.discountCodeFilter.group
  searchTerm: string

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    public inquiryChargeService: InquiryChargeService,
    protected storage: UserStorage,
    private confirmDialog: ConfirmDialogService,
    private dialog: DialogService,
    private discountCodeFilter: DiscountCodeFilter,
    private dataCheckerService: DataCheckerService,
    private discountCodeDialog: DiscountCodeOverlayService
  ) {
    super()
    this.dataCheckerService.check([DiscountCodeGuard])
  }

  ngOnInit() {
    this.dataSource.filterPredicate = filterPredicateFactory(this.displayedColumns)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort()

    this.discountCodeFilter.group.changes.pipe(untilDestroy(this)).subscribe((items) => {
      this.items = this.dataSource.data = items
    })

    this.discountCodeFilter.group.currentFilters.pipe(untilDestroy(this)).subscribe(() => {
      this.paginator.firstPage()
    })
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(LoadDiscountCodesComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(LoadDiscountCodes({ reset: true }))
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  onStatusChange(item: DiscountCodeView, checked: boolean, toggle: FormSwitchComponent) {
    if (checked) {
      this.onActivate(item)
    } else {
      this.onDeactivate(item, toggle)
    }
  }

  onActivate(item: DiscountCodeView) {
    this.store.dispatch(ActivateDiscountCode({ id: item.id }))
  }

  onDeactivate(item: DiscountCode, toggle: FormSwitchComponent) {
    this.confirmDialog
      .openRegular({
        title: 'Deactivate discount?',
        body: 'Are you sure you want to deactivate this discount?',
      })
      .subscribe((status: ConfirmDialogStatus) => {
        if (status === ConfirmDialogStatus.Confirmed) {
          this.store.dispatch(DeactivateDiscountCode({ id: item.id }))
        } else {
          toggle.value = true
        }
      })
  }

  onEdit(item: DiscountCodeView) {
    this.discountCodeDialog.open(item)
  }

  onAdd() {
    this.dialog.openVariableDialog(AddDiscountCodeDialogComponent, {
      width: '700px',
      height: 'auto',
      data: {},
    })
  }

  onDelete(item: DiscountCodeView) {
    this.confirmDialog
      .confirm({
        title: 'Delete discount?',
        body: 'Are you sure you want to delete this discount?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteDiscountCode({ id: item.id }))
      })
  }

  trackById(index, item: DiscountCodeView) {
    return item.id
  }
}
