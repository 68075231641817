import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import {
  Account,
  AccountForm,
  Channel,
  ChannelNameTokens,
  ConfirmDialogService,
  currencies,
  Destroyable,
  isSomething,
  Rental,
  selectAccount,
  selectAllRentals,
  Toaster,
  untilDestroy,
  updateAccount,
} from '@tokeet-frontend/tv3-platform'
import { FormArray, FormBuilder, Validators } from '@angular/forms'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { distinctUntilChanged, map } from 'rxjs/operators'
import * as R from 'ramda'
import {
  CreateHomeAwayChannelConfig,
  getChannelConfig,
  HomeAwayChannelConfig,
  HomeAwayConfigForm,
  LoadChannelConfig,
  LoadRentalConfigs,
  PauseHomeAwayChannelConfig,
  PauseHomeAwayChannelConfigSuccess,
  ResumeHomeAwayChannelConfig,
  UpdateHomeAwayChannelConfig,
} from '@tokeet-frontend/channels'

import { UserStorage, CURRENT_ACCOUNT_ID } from '@tokeet-frontend/tv3-platform'
import { RemoveLocalConnectionsByChannelId } from '@tv3/store/connection/connection.actions'
import { PaymentGatewayServiceIdentities, selectPaymentGatewayByService } from '@tokeet-frontend/gateways'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { ChannelConnectHelperService } from '../../channel-connect-helper.service'

@Component({
  selector: 'app-connect-vrbo-wizard-step2',
  templateUrl: './connect-vrbo-wizard-step2.component.html',
  styleUrls: ['./connect-vrbo-wizard-step2.component.scss'],
})
export class ConnectVrboWizardStep2Component extends Destroyable implements OnInit {
  @Input() channel: Channel

  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  form = this.fb.group({
    companyName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
    accountCurrency: [{ value: '', disabled: true }],
    primaryGuestMinAge: [18, [Validators.required]],
    instantBooking: [{ value: true, disabled: true }],
    cancellationPolicy: ['MODERATE', [Validators.required]],
  })

  termsForm = this.fb.group({
    terms: this.fb.array([]),
  })

  termForm = this.fb.group({
    type: ['', [Validators.required]],
    days: ['', [Validators.required, Validators.min(1)]],
    percent: ['', [Validators.required]],
  })

  currencyList = currencies

  termTypes = [
    { value: 'AT_CHECKIN', label: 'At Check-in' },
    { value: 'AT_CHECKOUT', label: 'At Check-out' },
    { value: 'AFTER_BOOKING', label: 'After booking' },
    { value: 'AFTER_CHECKOUT', label: 'After Check-out' },
    { value: 'AT_BOOKING', label: 'At booking' },
    { value: 'BEFORE_CHECKIN', label: 'Before Check-in' },
    { value: 'AFTER_CHECKIN', label: 'After Check-in' },
  ]

  rentals: Rental[]

  config: HomeAwayChannelConfig
  account: Account

  isEditing = false
  loadedImages = []

  isStripeConnected$ = this.store.pipe(
    select(selectPaymentGatewayByService(PaymentGatewayServiceIdentities.Stripe)),
    map((t) => !!t?.length)
  )

  get terms() {
    return this.termsForm.get('terms') as FormArray
  }

  get currentCurrency() {
    const currentCurrencyCode = this.form.get('accountCurrency').value as string
    return this.currencyList.find((c) => c.code === currentCurrencyCode)
  }

  get remainingPercentage() {
    const values = this.termsForm.getRawValue()
    const total = R.sum(R.map((t) => t.percent, values.terms))
    return R.times((i) => i + 1, 100 - total)
  }

  get remainingTypes() {
    const values = this.termsForm.getRawValue()
    const usedTypes = R.map((t) => t.type, values.terms)

    return R.reject((type) => R.contains(type.value, usedTypes), this.termTypes)
  }

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private toaster: Toaster,
    private confirm: ConfirmDialogService,
    private channelConnectHelper: ChannelConnectHelperService,
    protected storage: UserStorage,
    private store: Store<any>,
    @Inject(CURRENT_ACCOUNT_ID) public accountId$: BehaviorSubject<number>
  ) {
    super()
  }

  ngOnInit() {
    this.store.dispatch(LoadChannelConfig({ name: ChannelNameTokens.Homeaway }))
    this.store.dispatch(LoadRentalConfigs({ channel: ChannelNameTokens.Homeaway }))

    this.store.pipe(select(selectAccount(this.accountId$.value)), untilDestroy(this)).subscribe((account) => {
      this.account = account
      this.setCompanyName(account)
    })

    combineLatest([this.store.pipe(getChannelConfig(ChannelNameTokens.Homeaway)), this.isStripeConnected$])
      .pipe(untilDestroy(this))
      .subscribe(([config, isStripeConnected]: [HomeAwayChannelConfig, boolean]) => {
        this.config = config
        this.isEditing = isSomething(config)
        if (!this.isEditing) {
          return
        }
        this.setPaymentTermsForm(config)
      })

    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })

    this.channelConnectHelper.rentalImageCountsGuard().pipe(untilDestroy(this)).subscribe()

    this.actions.pipe(ofType(PauseHomeAwayChannelConfigSuccess), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(RemoveLocalConnectionsByChannelId({ channelId: this.channel.id }))
    })

    this.actions.pipe(ofType(UpdateHomeAwayChannelConfig)).subscribe((a) => console.log(a))

    this.setCompanyName(this.account)

    this.form.patchValue({
      accountCurrency: this.account?.currency?.code || 'USD',
    })

    this.termForm
      .get('type')
      .valueChanges.pipe(distinctUntilChanged(), untilDestroy(this))
      .subscribe((type) => {
        if (this.hasDays(type)) {
          this.termForm.get('days').setValidators([Validators.required, Validators.min(1)])
          this.termForm.get('days').updateValueAndValidity()
        } else {
          this.termForm.get('days').setValidators([])
          this.termForm.get('days').updateValueAndValidity()
        }
      })
  }

  setCompanyName(account: Account) {
    if (isSomething(account.name)) {
      this.form.patchValue({
        companyName: account.name,
      })
      this.form.get('companyName').disable()
    }
  }

  hasDays(type: string) {
    return (
      type === 'AFTER_CHECKOUT' || type === 'BEFORE_CHECKIN' || type === 'AFTER_CHECKIN' || type === 'AFTER_BOOKING'
    )
  }

  getTypeLabel(type: string) {
    return this.termTypes.find((t) => t.value === type)?.label
  }

  canAddMoreTypes(controls: any[]) {
    const hasAtBooking = !!controls.find((control) => {
      const { percent, type } = control.value
      return type === 'AT_BOOKING' // && percent === 0
    })
    const max = hasAtBooking ? 5 : 4
    return controls?.length < max
  }

  setPaymentTermsForm(config: HomeAwayChannelConfig) {
    this.form.patchValue({
      primaryGuestMinAge: config.guest_minimum_age,
      cancellationPolicy: config.cancellation_policy,
    })

    this.terms.clear()
    R.forEachObjIndexed((value: any, key: any) => {
      if (key === 'AT_BOOKING' && value.percent === 0) {
        return
      }
      if (this.hasDays(key)) {
        this.terms.push(
          this.fb.group({
            type: [{ value: key, disabled: true }, [Validators.required]],
            days: [{ value: value.days, disabled: true }, [Validators.required]],
            percent: [{ value: value.percent, disabled: true }, [Validators.required]],
          })
        )
      } else {
        this.terms.push(
          this.fb.group({
            type: [{ value: key, disabled: true }, [Validators.required]],
            percent: [{ value: value.percent, disabled: true }, [Validators.required]],
          })
        )
      }
    }, config.payment_terms)
  }

  onAddTerm() {
    if (this.termForm.invalid) {
      this.termForm.markAllAsTouched()
      return
    }
    const values = this.termForm.getRawValue()

    this.terms.push(
      this.fb.group({
        type: [{ value: values.type, disabled: true }, [Validators.required]],
        days: [{ value: values.days, disabled: true }, [Validators.required]],
        percent: [{ value: values.percent, disabled: true }, [Validators.required]],
      })
    )

    this.termForm.reset()

    this.tryToSavePaymentTerms()
  }

  onRemoveTerm(index: number) {
    if (this.isEditing) {
      this.confirm
        .confirm({
          title: 'Warning',
          body: 'Are you sure you want to remove this payment term?',
        })
        .subscribe(() => {
          this.terms.removeAt(index)
          this.tryToSavePaymentTerms()
        })
    } else {
      this.terms.removeAt(index)
    }
  }

  tryToSavePaymentTerms() {
    if (!this.isEditing) {
      return
    }
    this.store.dispatch(
      UpdateHomeAwayChannelConfig({
        payload: {
          payment_terms: this.getPaymentTermsValue(),
        } as any,
        channel: this.channel,
      })
    )
  }

  getPaymentTermsValue() {
    const { terms } = this.termsForm.getRawValue()
    const payment_terms = {
      ...R.pick(['type'], terms),
    }
    R.forEachObjIndexed((value, key) => {
      if (this.hasDays(value.type)) {
        payment_terms[value.type] = { days: value.days, percent: value.percent }
      } else {
        payment_terms[value.type] = { percent: value.percent }
      }
    }, terms)

    const hasAtBooking = !!payment_terms['AT_BOOKING']
    if (!hasAtBooking) {
      payment_terms['AT_BOOKING'] = {
        percent: 0,
      }
    }

    return payment_terms
  }

  onContinue() {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }
    if (!isSomething(this.account.name)) {
      this.store.dispatch(
        updateAccount({
          account: {
            name: this.form.get('companyName').value,
            currency: { ...(this.account.currency || {}), code: this.form.get('accountCurrency').value },
          } as AccountForm,
        })
      )
    }

    if (this.termsForm.invalid) {
      this.termsForm.markAllAsTouched()
      return
    }
    if (this.termsForm.get('terms').value?.length === 0) {
      this.toaster.error('You must set at least one payment term.')
      return
    }
    const formValues = this.form.getRawValue()

    const payload = {
      cancellation_policy: formValues.cancellationPolicy,
      booking_policy: formValues.instantBooking ? 'INSTANT' : 'QUOTEHOLD',
      guest_minimum_age: formValues.primaryGuestMinAge,
      payment_terms: this.getPaymentTermsValue(),
      tc_accepted: 1,
    } as HomeAwayConfigForm

    if (this.isEditing) {
      this.store.dispatch(UpdateHomeAwayChannelConfig({ payload, channel: this.channel }))
    } else {
      this.store.dispatch(CreateHomeAwayChannelConfig({ payload, channel: this.channel }))
    }
    this.next.emit()
  }

  onReconnect() {
    this.store.dispatch(ResumeHomeAwayChannelConfig())
  }

  onDisconnect() {
    this.confirm
      .confirm({
        title: 'Disconnect Vrbo',
        body: 'Are you sure you want to disconnect Vrbo?',
      })
      .subscribe(() => {
        this.store.dispatch(PauseHomeAwayChannelConfig())
      })
  }
}
