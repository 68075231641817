import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { languages } from '../../constants'

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
  languages = languages

  @Input() ctrl = new FormControl()
  @Input() inline = false
  @Input() required = false
  @Input() label = 'Language'
  @Input() showBlankItem = true

  destroy$ = new Subject<void>()
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
