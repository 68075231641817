import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'

import * as fromRoot from '@tv3/store/state'
import {
  asLocalDate,
  compactObject,
  Destroyable,
  integer,
  isSomething,
  notAllNumbersValidator,
  positive,
  SaveForm,
  selectAllChannelsWithDefaults,
  selectAllRentals,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  CreateLastMinuteAutomation,
  CreateLastMinuteAutomationPayload,
  LastMinuteAutomation,
  selectAllLastMinuteAutomations,
  UpdateLastMinuteAutomation,
} from '@tokeet-frontend/rate-automations'
import { selectChannelsWithCustomChannels } from '@tv3/store/channel/selectors'
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import { combineLatest, Observable } from 'rxjs'

export interface LastMinuteAutomationDialogInput {
  lastMinute?: LastMinuteAutomation
}

@Component({
  selector: 'app-last-minute-automation-dialog',
  host: { class: 'modal-content' },
  templateUrl: './last-minute-automation-dialog.component.html',
  styleUrls: ['./last-minute-automation-dialog.component.scss'],
})
export class LastMinuteAutomationDialogComponent extends Destroyable implements OnInit, OnDestroy {
  isEdit = false

  form = this.fb.group({
    name: ['', [Validators.required, notAllNumbersValidator]],
    rentals: [[], [Validators.required]],
    range: [null, [Validators.required]],
    windows: this.fb.array([]),
  })

  rentals$ = this.store.select(selectAllRentals)
  channels$ = this.store.select(selectChannelsWithCustomChannels(selectAllChannelsWithDefaults, 'name'))
  usedRange$: Observable<{ start: number; end: number }[]>

  get lastMinute() {
    return this.data?.lastMinute
  }

  get itemsForm() {
    return this.form.get('windows') as FormArray
  }
  minDate: Date

  constructor(
    private fb: FormBuilder,
    private store: Store<fromRoot.State>,
    private dialogRef: MatDialogRef<LastMinuteAutomationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: LastMinuteAutomationDialogInput
  ) {
    super()

    this.isEdit = !!data.lastMinute?.key
    if (!this.isEdit) {
      this.minDate = new Date()
    } else {
      this.minDate = asLocalDate(Math.min(Date.now() / 1000, this.data.lastMinute.start))
    }

    this.form.patchValue({
      ...(data.lastMinute || {}),
      range: this.isEdit ? { from: data.lastMinute.start, to: data.lastMinute.stop } : null,
    })
    lodash.forEach(data.lastMinute?.windows, (t) => this.itemsForm.push(this.createItemForm(t)))
    if (!this.itemsForm.length) {
      this.onAddItem()
    }
  }

  ngOnInit() {
    this.usedRange$ = combineLatest([
      this.store.pipe(select(selectAllLastMinuteAutomations)),
      this.form.get('rentals').valueChanges.pipe(startWith(this.form.get('rentals').value), filter(isSomething)),
    ]).pipe(
      map(([items, rentals]) => {
        items = items.filter((t) => lodash.intersection(rentals, t.rentals).length > 0)
        if (this.isEdit) {
          items = items.filter((t) => t.key !== this.lastMinute?.key)
        }
        return lodash.compact(lodash.map(items, (t) => ({ start: t.start, end: t.stop })))
      })
    )
  }

  createItemForm(day?: LastMinuteAutomation['windows'][number]) {
    const form = this.fb.group({
      days_before: [day?.days_before, [Validators.required]],
      min_nights: [day?.min_nights, [positive(), integer()]],
      adjust_pct: [day?.adjust_pct, [Validators.required]],
      adjust_abs: [day?.adjust_abs, [Validators.required]],
    })
    form.valueChanges
      .pipe(
        startWith(form.getRawValue()),
        distinctUntilChanged((a, b) => lodash.isEqual(a, b))
      )
      .subscribe((value) => {
        const adjust_abs = value?.adjust_abs
        const adjust_pct = value?.adjust_pct
        if (lodash.isNumber(adjust_abs)) {
          form.get('adjust_abs').setValidators([Validators.required])
          form.get('adjust_pct').clearValidators()
          form.get('adjust_pct').disable()
          form.updateValueAndValidity()
        } else if (lodash.isNumber(adjust_pct)) {
          form.get('adjust_pct').setValidators([Validators.required])
          form.get('adjust_abs').clearValidators()
          form.get('adjust_abs').disable()
          form.updateValueAndValidity()
        } else {
          form.get('adjust_abs').enable({ emitEvent: false })
          form.get('adjust_pct').enable({ emitEvent: false })
        }
      })
    return form
  }

  onAddItem() {
    this.itemsForm.push(this.createItemForm())
  }

  onRemoveItem(index: number) {
    this.itemsForm.removeAt(index)
  }

  @SaveForm()
  onSubmit(form: FormGroup) {
    const { range, windows, ...data } = form.getRawValue()
    const payload: CreateLastMinuteAutomationPayload = {
      ...data,
      start: range.from,
      stop: range.to,
      windows: lodash.map(windows, (t) => compactObject(t)),
    }

    if (this.isEdit) {
      this.store.dispatch(UpdateLastMinuteAutomation({ id: this.lastMinute.key, payload }))
    } else {
      this.store.dispatch(CreateLastMinuteAutomation({ payload }))
    }
    this.close()
  }

  close() {
    this.dialogRef.close()
  }
}
