import { Pipe, PipeTransform } from '@angular/core'
import { User } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Pipe({
  name: 'isRestricted',
})
export class IsRestrictedPipe implements PipeTransform {
  transform(rental: Rental, user: User): boolean {
    return R.contains(user.id, R.defaultTo([], rental.restrictedUsers))
  }
}
