import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { AiResponderSettings } from './ai-settings.model'

export interface State extends EntityState<any> {
  isLoaded: boolean
  isSaving: boolean
  settings: AiResponderSettings
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isSaving: false,
  settings: null,
})
