import { AbstractControl, FormGroup } from '@angular/forms'
import { MemoizedSelector, select, Store } from '@ngrx/store'
import { distinctUntilChanged, map, observeOn, shareReplay, startWith, switchMap, tap } from 'rxjs/operators'
import * as R from 'ramda'
import { asyncScheduler, BehaviorSubject, Observable } from 'rxjs'
import { IStorage } from '../storage/storage'
import { isSomething } from '../functions/is-something'

export class FilterFormGroup<T> extends FormGroup {
  changes: Observable<T[]>
  origin: Observable<T[]>
  currentFilters = new BehaviorSubject([])

  constructor(
    public controls: { [key: string]: AbstractControl },
    private store: Store<any>,
    private storage: IStorage,
    private filterGroup: string,
    private selector: MemoizedSelector<object, T[]>,
    private filters: { [key: string]: (list: T[], predicate: any, filters?: any) => T[] },
    private storeFn?: Function,
    private callAlways?
  ) {
    super(controls)

    this.changes = this.valueChanges.pipe(
      startWith(this.getRawValue()),
      distinctUntilChanged((x, y) => R.equals(x, y)),
      tap((filters) => this.currentFilters.next(filters)),
      switchMap((filters) =>
        this.store.pipe(
          select(this.selector),
          map((entities: T[]) => ({ entities, filters }))
        )
      ),
      tap(({ entities, filters }) => {
        if (this.storage) {
          this.storage.set(this.filterGroup, R.is(Function, storeFn) ? storeFn(filters) : { ...filters })
        }
      }),
      map(({ entities, filters }) => {
        R.forEach((filterKey: string) => {
          if (isSomething(filters[filterKey]) || this.callAlways) {
            const func = this.filters[filterKey]
            entities = R.is(Function, func) ? func(entities, filters[filterKey], filters) : entities
          }
        }, R.keys(filters))

        return entities
      }),
      observeOn(asyncScheduler),
      shareReplay(1)
    )

    this.origin = this.store.pipe(select(this.selector))
  }
}
