import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectBDCWizardComponent } from './connect-bdc-wizard.component'
import { ConnectBDCWizardCompleteComponent } from './quick-complete/connect-bdc-wizard-complete.component'
import { ConnectBDCWizardStep1Component } from './step1/connect-bdc-wizard-step1.component'
import { ConnectBDCWizardStep2Component } from './step2/connect-bdc-wizard-step2.component'
import { ConnectBDCWizardStep3Component } from './step3/connect-bdc-wizard-step3.component'

const components = [
  // Booking.com
  ConnectBDCWizardStep1Component,
  ConnectBDCWizardStep2Component,
  ConnectBDCWizardStep3Component,
  ConnectBDCWizardCompleteComponent,
  ConnectBDCWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule],
})
export class ConnectBdcWizardModule {}
