import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { AirbnbReservationAlterationDialogComponent } from './airbnb-reservation-alteration-dialog.component'
import { AirbnbReservationAlterationPriceQuoteResponse } from '@tokeet-frontend/channels'

@Injectable()
export class AirbnbReservationAlterationDialogService {
  constructor(private dialog: MatDialog) {}

  open(inquiry: Inquiry, quote?: AirbnbReservationAlterationPriceQuoteResponse) {
    let defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { inquiry, quote },
    }

    return this.dialog.open(AirbnbReservationAlterationDialogComponent, defaultConfig)
  }
}
