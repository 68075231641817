import { Component, Inject } from '@angular/core'
import { Observable, of } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import { parseCountry, parseEmail, parseString } from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import { Guest } from '@tv3/store/guest/guest.model'
import { AddGuestsComplete } from '@tv3/store/guest/guest.actions'
import { concatMap, tap } from 'rxjs/operators'
import { GuestService } from '@tv3/store/guest/guest.service'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Component({
  selector: 'app-import-guests-dialog',
  templateUrl: './import-guests-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-guests-dialog.component.scss'],
})
export class ImportGuestsDialogComponent {
  files: ParsedUploadFile<any>[] = []

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Name', field: 'guest.name', required: true, parse: parseString },
    { name: 'Email', field: 'guest.email', required: true, parse: parseEmail },
    { name: 'Phone', field: 'guest.phone', parse: parseString },
    { name: 'City', field: 'guest.address.city', parse: parseString },
    { name: 'Country', field: 'guest.address.country', parse: parseCountry },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: ['Guest Name, Guest Email are required.'],
    sample: [
      ['Name', 'Email', 'Phone', 'City', 'Country'],
      ['Iris Ferguson', 'iris.ferguson@gmail.com', '1111111111', 'Munich', 'Germany'],
      ['Kinley Weiss', 'kinley.weiss@gmail.com', '1111111111', 'Dublin', 'Ireland'],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportGuestsDialogComponent>,
    private guestService: GuestService,
    private store: Store<fromRoot.State>,
    protected toast: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { guests: Guest[] },
    private amplitudeService: AmplitudeService
  ) {}

  close() {
    this.dialogRef.close()
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  private tryToCreateGuests(newGuests: UpdateGuestRequest[]): Observable<Guest[]> {
    if (R.isEmpty(newGuests)) {
      this.toast.success(`There are no new guests in CSV file${this.files.length > 1 ? 's' : ''}!`)
      return of([])
    }
    return this.guestService
      .createMany(newGuests, `CSV file${this.files.length > 1 ? 's' : ''} uploaded successfully!`)
      .pipe(tap((guests: Guest[]) => this.store.dispatch(AddGuestsComplete({ guests }))))
  }

  create() {
    if (R.any((file) => file.isError, this.files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    this.amplitudeService.logEvent('upload-guest-list-csv')

    const items = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    const uniqueItems = R.uniqBy(({ guest }) => guest.email, items)
    if (items.length !== uniqueItems.length) {
      this.toast.error('Please check your file(s), duplicated email address exists')
      return
    }

    let newGuests = []
    R.forEach(({ guest }) => {
      const g = R.pipe(
        R.pathOr([], ['guests']),
        R.find((i: Guest) => i.primaryEmail === guest.email)
      )(this.data)
      if (!g) {
        newGuests.push(guest)
      }
    }, items)
    newGuests = R.uniqBy((g) => g.email, newGuests)

    of([])
      .pipe(
        concatMap(() => {
          return this.tryToCreateGuests(newGuests)
        })
      )
      .subscribe(
        () => {
          this.close()
        },
        (error) => {
          this.toast.error('Error when import data. ' + error)
          console.log(error)
        }
      )
  }
}
