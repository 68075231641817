import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'
import { Address } from '../stores'
import { getCountryByCode } from '../constants'

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(addr: Address, ui = false): string {
    if (!addr) {
      return ''
    }
    let { address, city, state, country, countryCode, cc, postalCode, zip } = addr
    postalCode = postalCode || zip
    country = country || getCountryByCode(countryCode || cc)

    if (!ui) {
      return R.filter((i) => !!i, [address, city, state, postalCode, country]).join(', ')
    }

    const statePostalCode = `${state || ''} ${postalCode || ''}`.trim()
    const cityStatePostalCode = city && statePostalCode ? `${city}, ${statePostalCode}` : city || statePostalCode || ''

    return R.filter((i) => !!i, [address, `${cityStatePostalCode}`, country]).join('<br/>')
  }
}
