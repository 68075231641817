import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ConnectGVRWizardComponent } from './connect-gvr-wizard.component'
import { ConnectGVRWizardCompleteComponent } from './complete/connect-gvr-wizard-complete.component'
import { ConnectGVRWizardStep1Component } from './step1/connect-gvr-wizard-step1.component'
import { ConnectGVRWizardStep2Component } from './step2/connect-gvr-wizard-step2.component'

import { SharedModule } from '@tv3/shared/shared.module'
import { GVRListingDetailsOverlayService } from './gvr-listing-details/gvr-listing-details-overlay.service'
import { GVRListingDetailsOverlayComponent } from './gvr-listing-details/gvr-listing-details-overlay.component'

const components = [
  // GVR
  ConnectGVRWizardStep1Component,
  ConnectGVRWizardStep2Component,
  ConnectGVRWizardCompleteComponent,
  ConnectGVRWizardComponent,
  GVRListingDetailsOverlayComponent,
]

const services = [
  //
  GVRListingDetailsOverlayService,
]

const pipes = []

@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components],
  providers: [...services],
  imports: [CommonModule, SharedModule, Tv3PlatformModule],
})
export class ConnectGVRWizardModule {}
