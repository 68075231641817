import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { Invoice } from './invoice.model'
import { LoadInvoicesFilters } from './invoice.types'

export const LoadInvoice = createAction('[Invoice] Load Invoice', props<{ invoiceId: string }>())
export const LoadInvoiceComplete = createAction('[Invoice] Load Invoice Complete', props<{ invoice: Invoice }>())

export const FetchInvoices = createAction('[Invoice] Fetch Invoices', props<{ ids: string[] }>())
export const FetchInvoicesComplete = createAction('[Invoice] Fetch Invoices Complete', props<{ invoices: Invoice[] }>())

export const LoadInvoices = createAction(
  '[Invoice] Load Invoices',
  props<{ filters: LoadInvoicesFilters; pagination?: any }>()
)
export const LoadInvoicesComplete = createAction(
  '[Invoice] Load Invoices Complete',
  props<{ invoices: Invoice[]; filters?: LoadInvoicesFilters; hasMore?: boolean; pagination?: any }>()
)

export const AddInvoice = createAction('[Invoice] Add Invoice', props<{ invoice: Invoice }>())
export const AddInvoiceComplete = createAction('[Invoice] Add Invoice Complete', props<{ invoice: Invoice }>())

export const UpdateInvoice = createAction('[Invoice] Update Invoice', props<{ invoice: Invoice }>())
export const UpdateInvoiceComplete = createAction(
  '[Invoice] Update Invoice Complete',
  props<{ update: Update<Invoice> }>()
)

export const UpdateInvoiceAddress = createAction(
  '[Invoice] Update Invoice Address',
  props<{ id: string; addressKey: string }>()
)
export const UpdateInvoiceAddressComplete = createAction(
  '[Invoice] Update Invoice Address Complete',
  props<{ update: Update<Invoice> }>()
)

export const UpdateInvoiceStatus = createAction(
  '[Invoice] Update Invoice Status',
  props<{ invoiceId: string; status: 'paid' | 'unpaid' | 'cancel' }>()
)
export const UpdateInvoiceStatusComplete = createAction(
  '[Invoice] Update Invoice Status Complete',
  props<{ update: Update<Invoice> }>()
)

export const SetInvoicePaymentGateway = createAction(
  '[Invoice] Set Invoice Payment Gateway',
  props<{ invoiceId: string; gatewayId: string }>()
)
export const SetInvoicePaymentGatewayComplete = createAction(
  '[Invoice] Set Invoice Payment Gateway Complete',
  props<{ update: Update<Invoice> }>()
)

export const EnableInvoiceOnlinePayment = createAction(
  '[Invoice] Enable Invoice Online Payment',
  props<{ invoiceId: string }>()
)
export const EnableInvoiceOnlinePaymentComplete = createAction(
  '[Invoice] Enable Invoice Online Payment Complete',
  props<{ update: Update<Invoice> }>()
)

export const DisableInvoiceOnlinePayment = createAction(
  '[Invoice] Disable Invoice Online Payment',
  props<{ invoiceId: string }>()
)
export const DisableInvoiceOnlinePaymentComplete = createAction(
  '[Invoice] Disable Invoice Online Payment Complete',
  props<{ update: Update<Invoice> }>()
)

export const ScheduleInvoice = createAction('[Invoice] Schedule Invoice', props<{ invoiceId: string; date: number }>())
export const ScheduleInvoiceComplete = createAction(
  '[Invoice] Schedule Invoice Complete',
  props<{ update: Update<Invoice> }>()
)

export const RefundInvoice = createAction('[Invoice] Refund Invoice', props<{ invoiceId: string }>())
export const RefundInvoiceComplete = createAction(
  '[Invoice] Refund Invoice Complete',
  props<{ update: Update<Invoice> }>()
)

export const UnArchiveInvoice = createAction('[Invoice] UnArchive Invoice', props<{ id: string }>())
export const UnArchiveInvoiceComplete = createAction(
  '[Invoice] UnArchive Invoice Complete',
  props<{ update: Update<Invoice> }>()
)

export const ArchiveInvoice = createAction('[Invoice] Archive Invoice', props<{ id: string }>())
export const ArchiveInvoiceComplete = createAction(
  '[Invoice] Archive Invoice Complete',
  props<{ update: Update<Invoice> }>()
)

export const DeleteInvoice = createAction('[Invoice] Delete Invoice', props<{ id: string }>())
export const DeleteInvoiceComplete = createAction('[Invoice] Delete Invoice Complete', props<{ id: string }>())

export const UnArchiveInvoices = createAction('[Invoices] UnArchive Invoices', props<{ ids: string[] }>())
export const UnArchiveInvoicesComplete = createAction(
  '[Invoices] UnArchive Invoices Complete',
  props<{ update: Update<Invoice>[] }>()
)

export const ArchiveInvoices = createAction('[Invoices] Archive Invoices', props<{ ids: string[] }>())
export const ArchiveInvoicesComplete = createAction(
  '[Invoices] Archive Invoices Complete',
  props<{ update: Update<Invoice>[] }>()
)

export const SendInvoices = createAction('[Invoices] Send Invoices', props<{ invoices: Invoice[] }>())
export const SendInvoicesComplete = createAction(
  '[Invoices] Send Invoices Complete',
  props<{ update: Update<Invoice>[] }>()
)

export const DeleteInvoices = createAction('[Invoices] Delete Invoices', props<{ ids: string[] }>())
export const DeleteInvoicesComplete = createAction('[Invoices] Delete Invoices Complete', props<{ ids: string[] }>())

export const DeleteLocalInvoicesByIds = createAction(
  '[Invoice] Delete local invoices for inquiry',
  props<{ ids: string[] }>()
)

export const PayInvoiceWithSavedCard = createAction(
  '[Invoice] Pay Invoice With Saved Card',
  props<{ invoiceId: string; total: number; description: string }>()
)
export const PayInvoiceWithSavedCardComplete = createAction(
  '[Invoice] Pay Invoice With Saved Card Complete',
  props<{ update: Update<Invoice> }>()
)

export const SearchElasticInvoices = createAction('[Invoice] Search Elastic Invoices', props<{ term: string }>())
export const SearchElasticInvoicesComplete = createAction(
  '[Invoice] Search Elastic Invoices Complete',
  props<{ invoices: Invoice[] }>()
)

export const LoadPastDueInvoices = createAction('[Invoice] Load Past Due Invoices')
export const LoadPastDueInvoicesComplete = createAction(
  '[Invoice] Load Past Due Invoices Complete',
  props<{ invoices: Invoice[] }>()
)

export const SendInvoice = createAction('[Invoice] Send Invoice', props<{ invoice: Invoice }>())
export const SendInvoiceComplete = createAction('[Invoice] Send Invoice Complete', props<{ update: Update<Invoice> }>())
