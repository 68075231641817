import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromPreference from './user-preferences.reducer'
import { EffectsModule } from '@ngrx/effects'
import { UserPreferencesEffects } from './user-preferences.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('user-preference', fromPreference.userPreferencesReducer),
    EffectsModule.forFeature([UserPreferencesEffects]),
  ],
  declarations: [],
})
export class UserPreferenceModule {}
