<form [formGroup]="form" [formEditablePermission]="form">
  <div class="container-box">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="btn-holder">
        <button
          (click)="addTaxes()"
          class="btn btn-secondary-info"
          type="button"
          [ngClass]="primaryButtonClass"
          [disabled]="1 | isReadonlyRole"
        >
          <i class="far fa-plus"></i> add tax
        </button>
        <button
          (click)="addFees()"
          class="btn btn-secondary-info"
          type="button"
          [ngClass]="primaryButtonClass"
          [disabled]="1 | isReadonlyRole"
        >
          <i class="far fa-plus"></i> add fee
        </button>
        <button
          (click)="onSave(form)"
          class="btn btn-secondary-info"
          type="button"
          [ngClass]="primaryButtonClass"
          [disabled]="1 | isReadonlyRole"
        >
          <span class="cs-icon cs-icon-save"></span>
          {{ saveText }}
        </button>
      </div>
    </div>

    <div class="details-box">
      <div class="box-body">
        <h6>Payment Settings</h6>

        <div class="row">
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                rows="4"
                formControlName="payment_terms"
                placeholder="Payment Terms:"
                required
                cdkTextareaAutosize
              ></textarea>
              <mat-error *ngIf="form.get('payment_terms').touched && form.get('payment_terms').hasError('required')">
                Payment terms are <strong>required</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-12">
            <mat-form-field2 class="mat-block">
              <textarea
                matInput
                rows="4"
                formControlName="payment_conditions"
                placeholder="Payment Conditions:"
                cdkTextareaAutosize
              ></textarea>
            </mat-form-field2>
          </div>
        </div>

        <h6>Cancellation Policy</h6>

        <div class="radio-box">
          <mat-radio-group formControlName="cancellation_policy">
            <mat-radio-button value="Strict">
              Bookings canceled at least 60 days before the start of stay will receive 100% refund streamline
            </mat-radio-button>
            <mat-radio-button value="Firm">
              Bookings canceled at least 60 days before the start of the stay will receive 100% refund<br />
              Bookings canceled at least 30 days before the start of the stay will receive 50% refund
            </mat-radio-button>
            <mat-radio-button value="Moderate">
              Bookings canceled at least 30 days before the start of the stay will receive 100% refund<br />
              Bookings canceled at least 14 days before the start of the stay will receive 50% refund streamline
            </mat-radio-button>
            <mat-radio-button value="Relaxed">
              Bookings canceled at least 14 days before the start of the stay will receive 100% refund<br />
              Bookings canceled at least 7 days before the start of the stay will receive 50% refund
            </mat-radio-button>
            <mat-radio-button value="No Refund"> No Refund </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</form>
