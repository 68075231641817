<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-credit-card"></i> Add Credit Card</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="row">
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="First Name:" formControlName="firstName" [required]="true" />
        <mat-error *ngIf="form.get('firstName').touched && form.get('firstName').hasError('required')">
          First name is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Last Name:" formControlName="lastName" [required]="true" />
        <mat-error *ngIf="form.get('lastName').touched && form.get('lastName').hasError('required')">
          Last name is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input
          matInput
          placeholder="Card Number:"
          formControlName="cardNumber"
          [required]="true"
          maxlength="16"
          autocomplete="cc-number"
        />
        <mat-error *ngIf="form.get('cardNumber').touched && form.get('cardNumber').hasError('required')">
          Card number is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('cardNumber').touched && form.get('cardNumber').hasError('pattern')">
          Invalid card number
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <mat-select formControlName="month" required placeholder="Month:">
          <mat-option value="01">01</mat-option>
          <mat-option value="02">02</mat-option>
          <mat-option value="03">03</mat-option>
          <mat-option value="04">04</mat-option>
          <mat-option value="05">05</mat-option>
          <mat-option value="06">06</mat-option>
          <mat-option value="07">07</mat-option>
          <mat-option value="08">08</mat-option>
          <mat-option value="09">09</mat-option>
          <mat-option value="10">10</mat-option>
          <mat-option value="11">11</mat-option>
          <mat-option value="12">12</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('month').touched && form.get('month').hasError('required')">
          Month is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('month').touched && form.get('month').hasError('cardInPast')">
          Card date can't be in past
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-3">
      <mat-form-field2 class="mat-block">
        <mat-select formControlName="year" required placeholder="Year:">
          <mat-option *ngFor="let year of expYears" [value]="year">{{ year }}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('year').touched && form.get('year').hasError('required')">
          Year is <strong>required</strong>
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="Zip/Postal Code:" required formControlName="zip" [required]="true" />
        <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('required')">
          Postal Code is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="
            form.get('zip').touched && form.get('zip').hasError('zipCode') && !form.get('zip').hasError('required')
          "
        >
          Invalid Postal Code
        </mat-error>
      </mat-form-field2>
    </div>
    <div class="col-sm-6">
      <mat-form-field2 class="mat-block">
        <input matInput placeholder="CVC Code:" required formControlName="cvc" />
        <mat-error *ngIf="form.get('cvc').touched && form.get('cvc').hasError('required')">
          CVC Code is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.get('cvc').touched && form.get('cvc').hasError('pattern')"> Invalid CVC Code </mat-error>
      </mat-form-field2>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
