<!-- Label -->
<label *ngIf="field.label || field.labelTpl" class="form-label">
  <ng-template [ngTemplateOutlet]="field.labelTpl?.template || defaultLabel"></ng-template>
</label>

<ng-template #defaultLabel>
  {{ field.label }} <span *ngIf="field.labelHint">({{ field.labelHint }})</span>
  <span *ngIf="field.required" class="required">*</span>
</ng-template>

<!-- Content -->
<div class="form-field-inner" [class.is-invalid]="field.isTouchedInvalid">
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="getAffixes | call: field.prefixes:prefix as prefixes">
      <ng-container *ngFor="let prefix of prefixes">
        <ng-template [ngTemplateOutlet]="prefix"></ng-template>
      </ng-container>
    </div>

    <ng-content></ng-content>

    <div class="input-group-append" *ngIf="getAffixes | call: field.suffixes:suffix as suffixes">
      <ng-container *ngFor="let suffix of suffixes">
        <ng-template [ngTemplateOutlet]="suffix"></ng-template>
      </ng-container>
    </div>
  </div>
</div>
<!-- Validation message -->
<ng-container *ngIf="field?.touched && !!field?.errors?.length; else innerError">
  <div class="invalid-feedback form-error">
    <ng-container *ngFor="let error of field?.errors">
      <ng-template [ngTemplateOutlet]="error?.template"></ng-template>
    </ng-container>
  </div>
</ng-container>
<ng-template #innerError>
  <div class="invalid-feedback form-error" *ngIf="field.isTouchedInvalid">
    {{ field.validationMessage() }}
  </div>
</ng-template>

<!-- Hint -->
<small
  class="form-text text-muted form-hint"
  [class.text-right]="field.hintRight"
  *ngIf="field.hint && !field.isTouchedInvalid"
>
  {{ field.hint }}
</small>
