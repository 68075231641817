import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { Channel, ChannelNameTokens, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { isConnectionUpdating, selectExportedCalendarsByChannelName } from '@tv3/store/connection/connection.selectors'
import { ConnectionDialogService } from '@tv3/containers/channels/connection-detail/connection-dialog.service'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { ConnectionHelperService } from '@tv3/containers/channels/channel-connect/connection-helper.service'
import { ExportCalendarDialogService } from '@tv3/containers/channels/channel-connect/dialogs/export-calendar-dialog/export-calendar-dialog.service'
import * as R from 'ramda'

@Component({
  selector: 'app-channel-exported-calendars',
  templateUrl: './channel-exported-calendars.component.html',
  styleUrls: ['./channel-exported-calendars.component.scss'],
})
export class ChannelExportedCalendarsComponent extends Destroyable implements OnInit, OnChanges {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<ConnectionView>()
  isEmptyTable$ = isEmptyTable(this.dataSource)

  displayedColumns = ['created', 'rentalName', 'lastsync', 'edit']

  @Input() selectedChannelName: string
  @Input() channel: Channel
  @Input() dateFormat: string

  connections: ConnectionView[] = []
  isUpdating$ = this.store.pipe(select(isConnectionUpdating))

  constructor(
    private connectionHelperService: ConnectionHelperService,
    private connectionDialog: ConnectionDialogService,
    private exportCalendarDialogService: ExportCalendarDialogService,
    private store: Store<fromRoot.State>
  ) {
    super()
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
    if (this.channel.name === ChannelNameTokens.UnknownICal) {
      this.displayedColumns = ['customICalChannelName', ...this.displayedColumns]
    }

    this.store
      .pipe(select(selectExportedCalendarsByChannelName, { name: this.channel.name }))
      .pipe(untilDestroy(this))
      .subscribe((res: ConnectionView[]) => {
        this.connections = res
        this.filterData()
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedChannelName']) {
      this.filterData()
    }
  }

  filterData() {
    let list = this.connections || []
    //  filter by channel name
    if (this.selectedChannelName) {
      list = R.filter((item: ConnectionView) => item.customICalChannelName === this.selectedChannelName)(list)
    }

    this.dataSource.data = list
  }

  onAdd() {
    this.exportCalendarDialogService.open({ channel: this.channel })
  }

  onDetails(connection: ConnectionView) {
    this.connectionDialog.openSide(connection)
  }

  onRentalDetail(connection: ConnectionView) {
    this.store.dispatch(OpenRentalOverlay({ rental: connection.rental }))
  }

  onDelete(connection: ConnectionView) {
    this.connectionHelperService.removeExportedCalendar(connection)
  }
}
