import { Component, HostBinding, Input } from '@angular/core'

import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['../form-field-common.scss', './form-switch.component.scss'],
  host: {
    class: 'custom-control custom-switch',
  },
})
export class FormSwitchComponent extends FormFieldComponent {
  @HostBinding('class')
  @Input()
  labelPosition: 'before' | 'after' = 'after'

  @Input() checkedValue: any = true
  @Input() uncheckedValue: any = false
  @Input() set checked(v: any) {
    this.ctrl.setValue(!!v, { emitEvent: false })
  }

  get checked() {
    return this.ctrl.value
  }

  toggle(emitEvent: boolean = true) {
    this.ctrl.setValue(!this.ctrl.value, { emitEvent })
  }

  decodeValue(v: any) {
    if (v === this.checkedValue) {
      return true
    } else if (v === this.uncheckedValue) {
      return false
    }
  }

  encodeValue(v: any) {
    if (v) {
      return this.checkedValue
    } else {
      return this.uncheckedValue
    }
  }
}
