import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { AUTH_TOKEN, UserStorage } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import {
  addRentalImagesComplete,
  Channel,
  ChannelNameTokens,
  ConfirmDialogService,
  selectRentalCities,
  selectRentalTags,
  Toaster,
  untilDestroy,
  updateRentalBaseRateComplete,
  updateRentalComplete,
  updateRentalDetailsComplete,
  updateRentalInstructionsComplete,
} from '@tokeet-frontend/tv3-platform'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { createSearch, isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import {
  ActivateGVRListing,
  DeactivateGVRListing,
  DeleteGVRListing,
  getGVRListingDisplayNameRejectionReasons,
  getGVRListingIconRejectionReasons,
  getGVRListings,
  getGVRListingState,
  getGVRListingStatusTooltip,
  GetGVRRentalValidations,
  GVRListingViewModel,
  ListingsValidationFieldResult,
  ListingValidationFieldResultItem,
  selectGVRRentalValidations,
  selectGVRValidationsLoaded,
  UpdateGVRListingSuccess,
} from '@tokeet-frontend/channels'
import { Actions, ofType } from '@ngrx/effects'
import { DeleteRoomComplete, UpdateRoomComplete, UpsertRoomsComplete } from '@tokeet-frontend/rentals'
import { GVRListingDetailsOverlayService } from '../gvr-listing-details/gvr-listing-details-overlay.service'
import { LoadConnectionsByChannel, RemoveLocalConnectionsByIds } from '@tv3/store/connection/connection.actions'
import { getChannelConnectionId } from '@tv3/store/connection/connection.model'
import { RentalValidationFixHelperService } from '../../rental-validation-fix-helper.service'
import { orderBy, some, isNil } from 'lodash'
import { environment } from '@tv3/environments/environment'
import { WebsiteOverlayService } from '@tv3/containers/websites/overlays/website-overlay.service'
import * as lodash from 'lodash'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'
import { BookingFormulasOverlayService } from '@tv3/containers/formulas/overlay/booking-formulas-overlay.service'

@Component({
  selector: 'app-connect-gvr-wizard-step2',
  templateUrl: './connect-gvr-wizard-step2.component.html',
  styleUrls: ['./connect-gvr-wizard-step2.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConnectGVRWizardStep2Component
  extends SelectableRow<GVRListingViewModel>
  implements OnInit, AfterViewInit
{
  @Output() prev = new EventEmitter()
  @Output() next = new EventEmitter()

  @Input() channel: Channel

  @ViewChild('paginator', { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns = ['rental_name', 'displayNameStateText', 'iconStateText', 'statusView', 'action']

  expandedElement: any

  isEmptyTable$ = isEmptyTable(this.dataSource)

  listings: GVRListingViewModel[]

  statusCtrl = new FormControl('')
  searchCtrl = new FormControl('')
  tagsCtrl = new FormControl([])
  citiesCtrl = new FormControl([])

  tags$ = this.store.pipe(select(selectRentalTags))
  cities$ = this.store.pipe(select(selectRentalCities))

  rentalTabs = RentalDialogTab

  searchFn = createSearch<GVRListingViewModel>(['rental_name', 'cancellationPolicyType', 'statusView'])

  validations: ListingsValidationFieldResult = {}

  validationsLoaded$ = this.store.pipe(select(selectGVRValidationsLoaded))

  refreshListings$ = new BehaviorSubject<boolean>(true)

  isExpansionDetailRow = (i: number, row: GVRListingViewModel & { detailRow?: boolean }) => {
    return row.hasOwnProperty('detailRow')
  }
  constructor(
    private confirmDialog: ConfirmDialogService,
    private store: Store<any>,
    private toaster: Toaster,
    private confirm: ConfirmDialogService,
    private validationFixHelper: RentalValidationFixHelperService,
    private actions: Actions,
    protected storage: UserStorage,
    private listingDetailsOverlay: GVRListingDetailsOverlayService,
    private bookingFormulasOverlay: BookingFormulasOverlayService,
    private websiteOverlay: WebsiteOverlayService,
    @Inject(AUTH_TOKEN) private token$: BehaviorSubject<string>
  ) {
    super(storage, TableType.VRBORentals)
  }

  ngOnInit() {
    this.onRefresh()

    this.actions
      .pipe(
        ofType(
          updateRentalComplete,
          updateRentalDetailsComplete,
          UpdateRoomComplete,
          UpsertRoomsComplete,
          addRentalImagesComplete,
          DeleteRoomComplete,
          updateRentalInstructionsComplete,
          updateRentalBaseRateComplete
        ),
        untilDestroy(this)
      )
      .subscribe(() => {
        this.store.dispatch(GetGVRRentalValidations())
      })

    this.actions.pipe(ofType(UpdateGVRListingSuccess), untilDestroy(this)).subscribe(() => {
      this.store.dispatch(
        LoadConnectionsByChannel({ channelName: ChannelNameTokens.GoogleVacationRentals, channelId: '' })
      )
    })

    combineLatest([this.store.pipe(getGVRListings), this.store.pipe(select(selectGVRRentalValidations))])
      .pipe(untilDestroy(this))
      .subscribe(([listings, validations]) => {
        this.validations = validations
        this.listings = listings
        this.refreshListings$.next(true)
      })

    combineLatest([
      this.citiesCtrl.valueChanges.pipe(startWith('')),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.statusCtrl.valueChanges.pipe(startWith([])),
      this.searchCtrl.valueChanges.pipe(startWith([])),
      this.refreshListings$,
    ])
      .pipe(untilDestroy(this))
      .subscribe(([cities, tags, status, searchTerm]) => {
        let configs = this.listings
        switch (status) {
          case 'all':
            configs = this.listings
            break
          case 'active':
            configs = R.filter((listing) => !!listing.active, this.listings)
            break
          case 'inactive':
            configs = R.filter((listing) => !listing.active, this.listings)
            break
        }
        if (tags?.length > 0) {
          configs = R.filter((listing) => {
            return R.any(
              R.equals(true),
              R.map((tag) => R.contains(tag, listing.rental?.tags || []), tags)
            )
          }, configs)
        }
        if (cities?.length > 0) {
          configs = R.filter((listing) => R.includes(listing.rental?.address?.city || '', cities), configs)
        }
        configs = this.searchFn(configs, searchTerm)

        if (configs?.length !== this.dataSource.data?.length) {
          this.paginator?.firstPage()
        }
        this.dataSource.data = this.withExtraRow(configs || [], this.validations)
      })
  }

  onRefresh() {
    this.store.dispatch(GetGVRRentalValidations())
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort
  }

  onCancelFilters() {
    this.statusCtrl.patchValue(null)
    this.tagsCtrl.patchValue(null)
    this.citiesCtrl.patchValue(null)
    this.searchCtrl.patchValue(null)
  }

  get isFiltering() {
    return this.statusCtrl.value || this.tagsCtrl.value || this.citiesCtrl.value || this.searchCtrl.value
  }

  withExtraRow(listings: GVRListingViewModel[], validations: ListingsValidationFieldResult) {
    const items = R.map((listing: GVRListingViewModel) => {
      const hasError = some(validations[listing.rentalId], (t) => t.type === 'error')
      return {
        ...listing,
        detailRow: validations[listing.rentalId]?.length > 0,
        hasError,
        displayNameStateText: getGVRListingState(listing.displayNameState),
        displayNameRejectionReasons: getGVRListingDisplayNameRejectionReasons(
          listing.displayNameState,
          listing.displayNameDisapprovalReason
        )?.join(' '),
        iconStateText: getGVRListingState(listing.iconState),
        iconRejectionReasons: getGVRListingIconRejectionReasons(
          listing.iconState,
          listing.iconDisapprovalReasons
        )?.join(' '),
        statusView: lodash.startCase(listing.status),
        statusTooltip: getGVRListingStatusTooltip(listing.status),
      }
    }, listings)

    return orderBy(
      items,
      [(listing) => (listing.statusView === 'Active' ? 1 : isNil(validations[listing.rentalId]) ? -1 : 0)],
      ['asc']
    )
  }

  onComplete() {
    this.next.emit(true)
  }

  allInvalid() {
    return R.all((listing) => listing['detailRow'], this.dataSource.data)
  }

  setExpanded(row) {
    this.expandedElement = row
  }

  onToggleStatus(listing: GVRListingViewModel) {
    if (listing.active) {
      this.store.dispatch(DeactivateGVRListing({ rentalId: listing.rentalId }))
      this.store.dispatch(
        RemoveLocalConnectionsByIds({
          connectionIds: [getChannelConnectionId({ propertyId: listing.rentalId, roomId: listing.rentalId })],
        })
      )
    } else {
      this.store.dispatch(ActivateGVRListing({ rentalId: listing.rentalId }))
    }
  }

  onEditListing(listing: GVRListingViewModel) {
    this.listingDetailsOverlay.open(listing)
  }

  onDeleteListing(listing: GVRListingViewModel) {
    this.confirmDialog
      .confirm({
        title: 'Delete listing?',
        body: 'Are you sure you want to delete this listing?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteGVRListing({ rentalId: listing.rentalId }))
      })
  }

  onPublishWebsite(listing: GVRListingViewModel) {
    if (listing.websiteProvider === 'webready') {
      window.open(`${environment.webreadyUrl}login?token=${this.token$.value}`, '_blank')
    } else {
      this.websiteOverlay.openSide(listing.websiteId)
    }
  }

  onFixRental(listing: GVRListingViewModel, field: ListingValidationFieldResultItem) {
    if (listing.websiteProvider === 'webready') {
      this.confirm
        .confirm({
          title: 'Fix rental data?',
          body: "Please go to the Webready app to fix this issue, because you're trying to link a Webready website with this listing.",
          confirmText: 'Open Webready',
        })
        .subscribe(() => {
          window.open(`${environment.webreadyUrl}login?token=${this.token$.value}`, '_blank')
        })
      return
    }

    this.validationFixHelper.onFixRental(listing.rental, field, this.validations[listing.rental.id], () => {
      if (field.field === 'website') {
        if (field.code === 'website-unpublished') {
          this.onPublishWebsite(listing)
        } else if (field.code === 'website-not-associated') {
          this.onEditListing(listing)
        }
      }
    })
  }

  onBookingFormula() {
    this.bookingFormulasOverlay.open(this.channel)
  }
}
