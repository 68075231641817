import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { UserPreferenceService } from './user-preferences.service'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import {
  LoadUserPreferences,
  LoadUserPreferencesComplete,
  UpdateUserPreferences,
  UpdateUserPreferencesComplete,
  VisitTV3,
} from './user-preferences.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class UserPreferencesEffects {
  @Effect()
  loadPreferences$ = this.actions$.pipe(
    ofType(LoadUserPreferences),
    switchMap(() =>
      this.userPreferenceService.get().pipe(
        map((preferences) => LoadUserPreferencesComplete({ preferences })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updatePreferences$ = this.actions$.pipe(
    ofType(UpdateUserPreferences),
    switchMap(({ id, form, successMessage }) =>
      this.userPreferenceService.update(form).pipe(
        tap(() => successMessage && this.toast.success(successMessage)),
        map((preference) => UpdateUserPreferencesComplete({ update: { id, changes: preference } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  visitTV3$ = this.actions$.pipe(
    ofType(VisitTV3),
    switchMap(() =>
      this.userPreferenceService.update({ tv3_login: true }).pipe(
        map((preference) =>
          UpdateUserPreferencesComplete({ update: { id: preference.id, changes: { tv3Login: true } } })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private userPreferenceService: UserPreferenceService,
    private toast: Toaster
  ) {}
}
