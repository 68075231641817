import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromInvoice from './invoice.reducer'
import { EffectsModule } from '@ngrx/effects'
import { InvoiceEffects } from './invoice.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('invoice-lib', fromInvoice.invoiceReducer),
    EffectsModule.forFeature([InvoiceEffects]),
  ],
  declarations: [],
})
export class InvoiceLibraryModule {}
