import * as R from 'ramda'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  addAttachmentsComplete,
  asLocalDate,
  asUTCEpoch,
  Attachment,
  AttachmentService,
  ConfirmDialogService,
  Destroyable,
  ExpenseApprovalSettings,
  ExpenseApprovalStatus,
  expenseApprovalStatusOptions,
  ExpensePaidStatus,
  ExpenseView,
  methods,
  selectAllExpenseCategories,
  selectAllRentals,
  selectExpenseSettings,
  selectExpenseUserApproveItem,
  untilDestroy,
  UpdateExpense,
  validateForm,
} from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { map, observeOn, startWith, withLatestFrom } from 'rxjs/operators'
import { asyncScheduler } from 'rxjs'
import { AttachmentResponse } from '@tv3/interfaces/responses/attachment.response'
import * as moment from 'moment'
import { AuthService } from '@tv3/services/auth.service'
import { OnInit, ViewChild, Directive } from '@angular/core'
import { UploadBoxComponent, UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { EntityAttributesComponent } from '@tokeet-frontend/custom-attrs'
import { MatDialogRef } from '@angular/material/dialog'
import {
  createRecurringForm,
  getRecurringFormData,
  setRecurringFormData,
} from '@tv3/shared/recurring-form/recurring-form.component'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import * as lodash from 'lodash'

export enum ExpenseDialogTabs {
  Expense,
  Recurring,
  Comments,
  Activity,
  Attributes,
  Attachments,
}

export function getExpensePayloadFromForm(form: FormGroup) {
  const { recurrenceRule, ...formData } = form.getRawValue()

  let approvalStatus = ExpenseApprovalStatus.Pending

  if (formData.approval_status) {
    approvalStatus = formData.approval_status
  }

  return {
    rental_id: formData.rental_id,
    date: asUTCEpoch(formData.date),
    category: formData.category,
    amount: formData.amount,
    status: formData.status ? ExpensePaidStatus.Paid : ExpensePaidStatus.Unpaid,
    repeat: formData.repeat ? 1 : 0,
    method: formData.method,
    inquiry_id: formData.inquiry_id,
    description: formData.description,
    photo: formData.photo,
    due: asUTCEpoch(formData.due),
    recurrenceRule: getRecurringFormData(form.get('recurrenceRule') as FormGroup),
    approval_status: approvalStatus,
  }
}

@Directive()
export abstract class ExpenseDialog extends Destroyable implements OnInit {
  dialogRef: MatDialogRef<ExpenseDialog>

  @ViewChild(UploadBoxComponent) uploadBox: UploadBoxComponent
  @ViewChild('attrs') attrs: EntityAttributesComponent
  isDuplicate = false
  isEdit = false
  expenseId: string = null

  tabs = ExpenseDialogTabs
  activeTab = ExpenseDialogTabs.Expense

  uploadBoxOptions: UploadBoxOptions = {
    maxSize: 10 * 1024 * 1024, // 10MB,
    maxUploads: 10,
    autoUpload: true,
    allowedFileExtensions: ['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', 'xlsx'],
    token: this.authService.token,
    guide:
      'You can upload <strong>10</strong> file(s) at one time and file size should be less than <strong>10 MB</strong>.',
  }

  minDateDefault = moment().subtract(10, 'years').toDate()
  maxDateDefault = moment().add(10, 'years').toDate()

  repeatOptionsForm = createRecurringForm(this.fb)

  form = this.fb.group({
    rental_id: [],
    category: ['', [Validators.required]],
    inquiry_id: [],
    date: ['', [Validators.required]],
    due: [''],
    amount: ['', [Validators.required]],
    method: [''],
    description: ['', [Validators.minLength(2), Validators.maxLength(500)]],
    photo: [''],
    status: [false],
    approval_status: [ExpenseApprovalStatus.Pending],
    repeat: [0],
    recurrenceRule: this.repeatOptionsForm,
  })

  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  methodOptions = methods
  approvalStatuses = expenseApprovalStatusOptions

  category$ = this.form.get('category').valueChanges.pipe(startWith(''))
  categories$ = this.store.pipe(select(selectAllExpenseCategories))
  filteredCategories$ = this.category$.pipe(
    withLatestFrom(this.categories$),
    map(([category, categories]) => this.filterCategories(category, categories))
  )
  approvable$ = this.store.pipe(select(selectExpenseUserApproveItem(this.authService.user.id)))
  isAdmin = this.authService.isAdmin()

  attachments: Attachment[] = []

  isUploading = false
  lastUploadedAttachment: AttachmentResponse
  expenseSettings: ExpenseApprovalSettings

  constructor(
    protected fb: FormBuilder,
    protected authService: AuthService,
    protected attachmentService: AttachmentService,
    protected store: Store<fromRoot.State>,
    protected confirm: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.pipe(select(selectExpenseSettings), untilDestroy(this)).subscribe((s) => {
      this.expenseSettings = s
    })
  }

  private filterCategories(category: string, categories: string[]) {
    const filterValue = category.toLowerCase()

    if (R.isEmpty(category)) {
      return categories
    }

    return categories.filter((c) => c.toLowerCase().indexOf(filterValue) === 0)
  }

  onFileUploaded(data: any[]) {
    const attachments = lodash.map(data, (d) => Attachment.deserialize(d))

    this.store.dispatch(addAttachmentsComplete({ attachments }))
    this.attachments = [...this.attachments, ...attachments]

    if (this.isEdit) {
      this.store.dispatch(
        UpdateExpense({
          expenseId: this.expenseId,
          payload: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    }
  }

  onResetPhoto() {
    this.uploadBox?.reset()
    if (this.lastUploadedAttachment) {
      this.attachmentService.delete(this.lastUploadedAttachment.pkey).subscribe()
    }
    this.form.patchValue({ photo: '' })
  }

  downloadPhoto(url: string) {
    this.attachmentService
      .downloadFile(
        Attachment.deserialize({
          url,
          name: R.last(url.split('/')),
          'content-type': 'application/octet-stream',
        })
      )
      .subscribe()
  }

  onDownloadAttachment(item: Attachment) {
    this.attachmentService.downloadFile(item).subscribe()
  }

  onDeleteAttachment(item: Attachment) {
    this.confirm.confirm().subscribe(() => {
      this.attachments = lodash.filter(this.attachments, (i) => i.id !== item.id)
      this.store.dispatch(
        UpdateExpense({
          expenseId: this.expenseId as string,
          payload: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    })
  }

  onSelectInquiry(item: Inquiry) {
    this.form.get('rental_id').setValue(item.rentalId, { emitEvent: false })
    this.form.get('inquiry_id').setValue(item.id, { emitEvent: false })
  }

  setFormData(expense: ExpenseView) {
    this.expenseId = expense?.pkey
    this.form.patchValue({
      ...expense,
      date: asLocalDate(expense.date, false) || '',
      due: asLocalDate(expense.due, false) || '',
      photo: R.defaultTo('', this.attachmentService.getAttachmentDownloadUrl({ url: expense.photo } as any)),
      status: expense.status === 2,
      repeat: !!expense.repeat ? 1 : 0,
    })

    setRecurringFormData(this.repeatOptionsForm, expense.recurrenceRule)
  }

  onSaveAttrs() {
    this.attrs.save()
    this.dialogRef?.close()
  }

  onRemove() {}

  abstract onSave(form: FormGroup): void

  save() {
    if (!validateForm(this.repeatOptionsForm) || this.repeatOptionsForm.invalid) {
      this.activeTab = ExpenseDialogTabs.Recurring
      return
    }

    if (!validateForm(this.form, false) || this.form.invalid) {
      this.activeTab = ExpenseDialogTabs.Expense
      return
    }

    return this.onSave(this.form)
  }
}
