import { changeHue } from '../functions/change-hue'
export const defaultChartColors = [
  //
  '#6CADF3',
  '#F3857E',
  '#90CD80',
  '#EAD53C',
  '#7D98CF',
  '#78CCD2',
  '#B26BAD',
]

export function getDataColorByIndex(index: number, step = 100) {
  const color = defaultChartColors[index % defaultChartColors.length]
  const i = Math.floor(index / defaultChartColors.length)
  return changeHue(color, i * step)
}
