import { createAction, props } from '@ngrx/store'
import { InvoiceTemplate, InvoiceTemplateCreatePayload, InvoiceTemplateUpdatePayload } from './template.model'
import { Update } from '@ngrx/entity'
import { Invoice } from '@tokeet-frontend/invoices'

export const LoadInvoiceTemplates = createAction(
  '[InvoiceTemplates] Load Templates',
  props<{
    filters: {
      limit?: number
      skip?: number
    }
  }>()
)
export const LoadInvoiceTemplatesComplete = createAction(
  '[InvoiceTemplates] Load Templates Complete',
  props<{ templates: InvoiceTemplate[] }>()
)

export const DeleteInvoiceTemplate = createAction('[InvoiceTemplates] Delete Template', props<{ id: string }>())
export const DeleteInvoiceTemplateComplete = createAction(
  '[InvoiceTemplates] Delete Template Complete',
  props<{ id: string }>()
)

export const AddInvoiceTemplate = createAction(
  '[InvoiceTemplates] Add Template',
  props<{ template: InvoiceTemplateCreatePayload; tags?: string[] }>()
)
export const AddInvoiceTemplateComplete = createAction(
  '[InvoiceTemplates] Add Template Complete',
  props<{ template: InvoiceTemplate }>()
)

export const DuplicateInvoiceTemplate = createAction(
  '[InvoiceTemplates] Duplicate Template',
  props<{ template: InvoiceTemplate }>()
)

export const UpdateInvoiceTemplate = createAction(
  '[InvoiceTemplates] Update Template',
  props<{ template: InvoiceTemplateUpdatePayload; silent?: boolean }>()
)
export const UpdateInvoiceTemplateComplete = createAction(
  '[InvoiceTemplates] Update Template Complete',
  props<{ update: Update<InvoiceTemplate>; silent?: boolean }>()
)

export const DeleteInvoiceTemplates = createAction('[InvoiceTemplates] Delete Templates', props<{ ids: string[] }>())
export const DeleteInvoiceTemplatesComplete = createAction(
  '[InvoiceTemplates] Delete Templates Complete',
  props<{ ids: string[] }>()
)

export const ApplyInvoiceTemplate = createAction(
  '[InvoiceTemplates] Apply Template To Booking',
  props<{ bookingId: string; invoiceTemplateId: string }>()
)
export const ApplyInvoiceTemplateComplete = createAction(
  '[InvoiceTemplates] Apply Template To Booking Complete',
  props<{ invoice: Invoice }>()
)
