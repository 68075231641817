import { createFeatureSelector, createSelector } from '@ngrx/store'
import { State } from './rooms.state'
import * as reducer from './rooms.reducer'

export const roomsState = createFeatureSelector<State>('rooms-lib')

export const selectAllRooms = createSelector(roomsState, reducer.selectAll)

export const selectRoomsLoaded = createSelector(roomsState, (state) => state.isLoaded)

export const selectRooms = (rentalId: string) =>
  createSelector(selectAllRooms, (rooms) => {
    return rooms?.filter((room) => room.rental_id === rentalId).sort((a, b) => a.created - b.created)
  })
