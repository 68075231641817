<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <div class="d-flex align-items-center gap-2">
      <h6 class="mb-0">Website Details</h6>
    </div>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>

  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box">
      <div class="box-body">
        <div class="d-flex rounded-top overflow-hidden">
          <h6 class="section-title border-right flex-1 border-top-0">Portal Information</h6>
          <h6 class="section-title flex-1 border-top-0">Portal Instructions</h6>
        </div>
        <div class="row px-3">
          <div class="col-6">
            <mat-form-field2 class="mat-block">
              <input type="text" matInput required placeholder="Website Title" formControlName="title" />
              <mat-hint align="end">{{ form.get('title').value.length || 0 }}/50</mat-hint>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('required')">
                Website Title is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('minlength')">
                Website Title must be at least <strong>2</strong> characters long
              </mat-error>
              <mat-error *ngIf="form.get('title').touched && form.get('title').hasError('maxlength')">
                Website Title must be at most <strong>50</strong> characters long
              </mat-error>
            </mat-form-field2>
            <div class="d-flex gap-1">
              <mat-form-field2 class="mat-block">
                <input type="text" matInput required placeholder="Domain" formControlName="domain" />
              </mat-form-field2>
              <a href="javascript:;" (click)="onEditDomain()" class="btn btn-light-info align-input-row-end">
                <i class="fal fa-edit"></i>
              </a>
              <app-website-custom-domain-ssl
                *ngIf="isCustomDomain"
                [website]="website"
                class="align-input-row-end"
              ></app-website-custom-domain-ssl>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <div>
                  <p>
                    Most of your guest portal content comes from the booking details and rental instructions. AdvanceCM
                    will automatically show the associated rental instructions to the guest when they view the guest
                    portal. Please update your individual rental instructions to change the guest portal instructions
                    accordingly.
                    <br /><br />
                    You may also add content to your guest portal by adding new pages. Pages show as additional tiles on
                    the guest portal homepage. Click the lower left navigation to add a new page.
                    <br /><br />
                    You may insert a link to your guest portal using the data dictionary. We recommend including this
                    link in one of your email templates which is automatically sent to guests after booking.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
