import { deserialize, Expose, Serializable } from '@tokeet-frontend/tv3-platform'
import { PlanActivity } from '@tv3/types/plan-activity'
import { toMoment } from '@tokeet-frontend/tv3-platform'
import { SubscriptionDiscount } from '@tokeet-frontend/plans'

export enum ProductsForPlan {
  AdvanceCM = 'tokeet',
  Automata = 'automata',
  Signature = 'signature',
  Checklist = 'checklist',
  OwnerCenter = 'owner_center',
  Aireply = 'aireply',
  Rategenie = 'rategenie',
  Staffmagic = 'staffmagic',
  Webready = 'webready',
  PowerReporting = 'power_reporting',
  TokeetAI = 'tokeet_ai',
  SmartDevice = 'tokeet_smart_devices',
  Messaging = 'tokeet_messaging',
}

export const tokeetAddonProductNames = {
  [ProductsForPlan.AdvanceCM]: 'AdvanceCM',
  [ProductsForPlan.Automata]: 'Automata',
  [ProductsForPlan.Signature]: 'Signature',
  [ProductsForPlan.OwnerCenter]: 'Owner Center',
  [ProductsForPlan.Aireply]: 'Aireply',
  [ProductsForPlan.Rategenie]: 'Rategenie',
  [ProductsForPlan.Staffmagic]: 'Staffmagic',
  [ProductsForPlan.Webready]: 'Websites & Widgets',
  [ProductsForPlan.PowerReporting]: 'Power Reporting',
  [ProductsForPlan.SmartDevice]: 'Smart Device',
  [ProductsForPlan.Messaging]: 'Messaging',
}

export enum AdvanceCMAdditionalFeatureIds {
  Payments = 'tokeet_payments',
  ChannelManager = 'tokeet_channel_manager',
  PropertyManager = 'tokeet_property_manager',
}
export const advanceCMAdditionalFeatures: {
  [key: string]: { shortName: string; name: string; description: string; price: string }
} = {
  [AdvanceCMAdditionalFeatureIds.ChannelManager]: {
    shortName: 'Channel Manager',
    name: 'AdvanceCM API Channel Manager',
    description: 'Synchronize your rates, bookings, and availability in real-time.',
    price: '',
  },
  [AdvanceCMAdditionalFeatureIds.Payments]: {
    shortName: 'Payments',
    name: 'AdvanceCM Payments',
    description: 'Accept bookings online and send invoices to guests.',
    price: '0.5%',
  },
  [AdvanceCMAdditionalFeatureIds.PropertyManager]: {
    shortName: 'Property Manager',
    name: 'AdvanceCM Property Management System',
    description: 'Manage your bookings, guests, and rentals all in one place.',
    price: 'FREE',
  },
}

export enum PlanIntervalTypes {
  Monthly = 'month',
  Yearly = 'year',
}

export enum PlanTypes {
  Free = 'free',
  Entry = 'entry',
  Owner = 'owner',
  Agent = 'agent',
  Manager = 'manager',
  Unlimited = 'unlimited',
  TokeetV4 = 'tokeet',
}

export const planTypeLevels = {
  [PlanTypes.Free]: -1,
  [PlanTypes.Entry]: 0,
  [PlanTypes.Owner]: 1,
  [PlanTypes.Agent]: 2,
  [PlanTypes.Manager]: 3,
  [PlanTypes.Unlimited]: 4,
  [PlanTypes.TokeetV4]: 5,
}

export const allPlansFeatures = {
  [PlanTypes.Free]: ['Reservation Manager'],
  [PlanTypes.Entry]: ['Reservation Manager', 'Channel Manager', 'Mobile App'],
  [PlanTypes.Owner]: [
    'Reservation Manager',
    'Channel Manager',
    'Mobile App',
    'Website Builder',
    'Payment Gateway',
    'Integrations',
  ],
  [PlanTypes.Agent]: [
    'Reservation Manager',
    'Channel Manager',
    'Mobile App',
    'Website Builder',
    'Payment Gateway',
    'Integrations',
    'Custom Branding',
  ],
  [PlanTypes.Manager]: [
    'Reservation Manager',
    'Channel Manager',
    'Mobile App',
    'Website Builder',
    'Payment Gateway',
    'Integrations',
    'Custom Branding',
  ],
  [PlanTypes.Unlimited]: [
    'Reservation Manager',
    'Channel Manager',
    'Mobile App',
    'Website Builder',
    'Payment Gateway',
    'Integrations',
    'Custom Branding',
  ],
  [PlanTypes.TokeetV4]: [
    'Reservation Manager',
    'Channel Manager',
    'Mobile App',
    'Website Builder',
    'Payment Gateway',
    'Integrations',
    'Custom Branding',
  ],
}

export const unlimitedUsersThreshold = 200
export const unlimitedRentalsThreshold = 200

export const currentFreeTokeetPlanId = 'free_v3'

export class ActivePlanStatus extends Serializable<ActivePlanStatus> {
  @Expose({ name: 'plan_id' })
  planId: string

  @Expose({ name: 'plan_name' })
  planName: string

  rentals: number
  users: number
  product: string
  status: PlanActivity
  price: number

  trial_start: number
  trial_end: number
  start: number
  until: number
  timestamp: number
  products: string[]
  delinquent: boolean

  static deserialize(data: object): ActivePlanStatus {
    return deserialize(ActivePlanStatus, data)
  }
}

export class PlanOption extends Serializable<PlanOption> {
  id: string
  amount: number
  rentals: number
  name: string
  interval: 'month' | 'year'
  trial: string
  product: string
  interval_count: number
  users: number
  value: string

  static deserialize(data: object): PlanOption {
    return deserialize(PlanOption, data)
  }
}

export interface SubscriptionDto {
  plan_id: string
  trial_start: number
  trial_end: number
  cancel_at_period_end: boolean
  plan_name: string
  product: string
  gateway: string
  start: number
  plan: string
  users: number
  until: number
  rentals: number
  status: string
  txn: string
  timestamp: number
  price: number
}

export class PlanSubscription extends Serializable<PlanSubscription> {
  @Expose({ name: 'plan_id' })
  planId: string

  @Expose({ name: 'trial_start' })
  trialStart: number

  @Expose({ name: 'trial_end' })
  trialEnd: number

  @Expose({ name: 'cancel_at_period_end' })
  cancelAtPeriodEnd: boolean

  @Expose({ name: 'plan_name' })
  planName: string

  product: string
  gateway: string
  start: number
  plan: string
  users: number
  until: number
  rentals: number
  quantity?: number
  status: string
  txn: string
  timestamp: number
  price: number
  discount?: SubscriptionDiscount

  static deserialize(data: object): PlanSubscription {
    return deserialize(PlanSubscription, data)
  }
}

export interface ActivePlansByProduct {
  [product: string]: PlanSubscription
}

export function isSubscriptionValid(sub: PlanSubscription) {
  const now = toMoment().unix()

  if (!sub) return false

  if (sub.trialEnd && sub.trialEnd > now) {
    return true
  }

  return sub.until >= now
}
