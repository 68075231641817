import { Compiler, ComponentFactoryResolver, Injectable, Injector, NgModuleFactory } from '@angular/core'
import { MatDialogConfig, MatDialogContainer } from '@angular/material/dialog'
import { switchMap } from 'rxjs/operators'
import { SharedModule } from '@tv3/shared/shared.module'
import { Store } from '@ngrx/store'
import { ComponentType, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { defaults } from 'lodash'
import { from, of } from 'rxjs'
import { DialogService } from '@tokeet-frontend/tv3-platform'

export interface MatDialogConfigEx extends MatDialogConfig {
  injector?: Injector
}

export interface LazyComponent<T> {
  component: ComponentType<T>
  injector: Injector
  componentFactoryResolver: ComponentFactoryResolver
}

@Injectable({
  providedIn: SharedModule,
})
export class LazyOverlayService {
  constructor(
    private dialog: DialogService,
    store: Store<any>,
    private compiler: Compiler,
    private injector: Injector
  ) {
    dialog['_attachDialogContainer'] = _attachDialogContainer
  }

  openLargeSide<T>(component: ComponentType<T>, data: any, config?: MatDialogConfigEx) {
    config = defaults({}, config, {
      panelClass: ['drawer-full-90'],
      data,
    })

    const dialogRef = this.dialog.openDrawer(component, config)

    return dialogRef
  }

  openLazyOverlay<T>(lazyComponent: Promise<LazyComponent<T>>, data: any, config: MatDialogConfig = {}) {
    return from(lazyComponent).pipe(
      switchMap(({ component, injector, componentFactoryResolver }) =>
        of(this.openLargeSide(component, data, { ...config, injector, componentFactoryResolver }))
      )
    )
  }

  async wrapLazyComponentModule<T>(component: ComponentType<T>, module: any) {
    const moduleFactory = await this.loadModuleFactory(module)
    const moduleRef = moduleFactory.create(this.injector)
    return {
      component,
      injector: moduleRef.injector,
      componentFactoryResolver: moduleRef.componentFactoryResolver,
    }
  }

  private async loadModuleFactory(t: any) {
    if (t instanceof NgModuleFactory) {
      return t // AOT
    } else {
      return await this.compiler.compileModuleAsync(t)
    }
  }
}

/**
 * Attaches an MatDialogContainer to a dialog's already-created overlay.
 * @param overlay Reference to the dialog's underlying overlay.
 * @param config The dialog configuration.
 * @returns A promise resolving to a ComponentRef for the attached container.
 */
function _attachDialogContainer(overlay: OverlayRef, config: MatDialogConfigEx): MatDialogContainer {
  // NOTE: material 9.1 API doesn't allow us to set injector directly.
  // we're only allowed to set viewContainerRef with injector set, but in our scenarios,
  // we don't have viewContainerRef and i didn't found easy way to fake it properly
  const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector
  const injector = Injector.create({
    parent: config.injector || userInjector || this._injector,
    providers: [{ provide: MatDialogConfig, useValue: config }],
  })

  const containerPortal = new ComponentPortal(
    MatDialogContainer,
    config.viewContainerRef,
    injector,
    config.componentFactoryResolver
  )
  const containerRef = overlay.attach<MatDialogContainer>(containerPortal)

  return containerRef.instance
}
