import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Channel, Rental, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { tap } from 'rxjs/operators'
import { AuthService } from '@tv3/services/auth.service'
import {
  AgodaContentProperty,
  AgodaContentRatePlan,
  agodaRatePlanTypes,
} from '@tv3/store/channel-content/models/agoda.model'
import { AgodaContentService } from '@tv3/store/channel-content/agoda-content.service'
import * as lodash from 'lodash'
import { AgodaRateCreateComplete, AgodaRateUpdateComplete } from '@tv3/store/channel-content/conent.actions'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-agoda-content-rate-setup',
  templateUrl: './agoda-content-rate-setup.component.html',
  styleUrls: ['./agoda-content-rate-setup.component.scss'],
})
export class AgodaContentRateSetupComponent implements OnInit, OnChanges {
  @Input() channel: Channel
  @Input() connection: ConnectionView
  @Input() rental: Rental
  @Input() agodaProperty: AgodaContentProperty

  get agodaRatePlan(): AgodaContentRatePlan {
    return this.agodaProperty && lodash.get(this.agodaProperty.rates, 0)
  }
  agodaRatePlanTypes = agodaRatePlanTypes

  form = this.fb.group({
    name: [undefined, [Validators.required]],
    ratePlanType: [undefined, [Validators.required]],
    active: [],
    ratePlanId: [],
  })

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private auth: AuthService,
    private toaster: Toaster,
    private agodaContentService: AgodaContentService
  ) {}

  ngOnInit() {
    this.form.get('active').disable()
    this.form.get('ratePlanId').disable()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['agodaProperty'] && changes['agodaProperty'].currentValue) {
      this.setForm()
    }
  }

  setForm() {
    if (!this.agodaRatePlan) {
      return
    }
    this.form.patchValue({
      ...this.agodaRatePlan,
      active: this.agodaRatePlan.active ? 'Yes' : 'No',
    })
    this.form.get('ratePlanType').disable()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const { name, ratePlanType } = this.form.getRawValue()

    let request
    if (this.agodaRatePlan && this.agodaRatePlan.ratePlanId) {
      request = this.agodaContentService
        .updateRate(this.agodaProperty.propertyId, this.agodaRatePlan.ratePlanId, {
          name,
          ratePlanType,
        })
        .pipe(
          tap((res) =>
            this.store.dispatch(
              AgodaRateUpdateComplete({
                propertyId: res.propertyId,
                rateId: res.rateId,
              })
            )
          )
        )
    } else {
      request = this.agodaContentService
        .createRate(this.agodaProperty.propertyId, {
          name,
          ratePlanType,
        })
        .pipe(
          tap((res) =>
            this.store.dispatch(
              AgodaRateCreateComplete({
                propertyId: res.propertyId,
                rateId: res.rateId,
              })
            )
          )
        )
    }

    request.subscribe(({ warnings }) => {
      this.toaster.success('Rate saved successfully.')
      if (warnings && warnings.length) {
        this.toaster.warning('Rate Warnings: ' + warnings.join('\n'))
      }
    })
  }
}
