import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  ActionFailed,
  Channel,
  ConfirmDialogService,
  Destroyable,
  SaveForm,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'

import {
  ConnectRentalsUnitedSuccess,
  DisconnectRentalsUnited,
  RentalsUnitedStatus,
  selectRentalsUnitedStatus,
  ConnectRentalsUnited,
} from '@tokeet-frontend/channels'

import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ChannelConnectHelperService } from '../../channel-connect-helper.service'
@Component({
  selector: 'app-connect-ru-wizard-step1',
  templateUrl: './connect-ru-wizard-step1.component.html',
  styleUrls: ['./connect-ru-wizard-step1.component.scss'],
  providers: [ChannelConnectHelperService],
})
export class ConnectRUWizardStep1Component extends Destroyable implements OnInit {
  @Output() next = new EventEmitter()
  @Input() channel: Channel

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  })

  isConnecting = false
  status?: RentalsUnitedStatus
  showCredsForm = false
  isCredentialsWrong = false

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    protected storage: UserStorage,
    private store: Store<any>,
    private confirm: ConfirmDialogService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectRentalsUnitedStatus), untilDestroy(this)).subscribe((status) => {
      this.status = status
      this.isCredentialsWrong = status?.deactivated?.reason === 'wrong-credentials'
      this.showCredsForm = !status?.connected
    })

    this.actions.pipe(ofType(ConnectRentalsUnitedSuccess), untilDestroy(this)).subscribe(() => {
      this.next.emit()
      this.isConnecting = false
    })
    this.actions.pipe(ofType(ActionFailed), untilDestroy(this)).subscribe(() => {
      this.isConnecting = false
    })
  }

  @SaveForm()
  onConnect(form: FormGroup) {
    const { email, password } = this.form.getRawValue()
    this.store.dispatch(ConnectRentalsUnited({ email, password, update: !!this.status?.connected }))
    this.isConnecting = true
  }

  onChangeCreds() {
    this.showCredsForm = true
  }

  onDisconnect() {
    this.confirm
      .confirm({
        title: 'Disconnect Rentals United',
        body: 'Are you sure you want to disconnect Rentals United?',
      })
      .subscribe(() => {
        this.store.dispatch(DisconnectRentalsUnited())
      })
  }
}
