import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { CustomChannelEffects } from './effects'
import { reducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('custom-channels', reducer),
    EffectsModule.forFeature([CustomChannelEffects]),
  ],
  providers: [],
  declarations: [],
  exports: [],
})
export class CustomChannelModule {}
