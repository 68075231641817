<div class="container-box shadow-none">
  <div class="d-flex gap-3 align-items-center p-3">
    <img src="/assets/images/channel-wizards/listing-icon2.png" alt="" height="40" />
    <div>
      <h6 class="mb-0">Rates</h6>
      <div class="text-sm">
        As mentioned previously in this connection wizard, rates will now need to be pushed from AdvanceCM to Airbnb.
        <br />
        Until this is done, your availability will remain blocked and the connection will not be complete.
      </div>
    </div>
  </div>
  <div class="border-top p-4 d-flex gap-5">
    <div class="flex-fill">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">1</div>
              Price Settings Overview
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              Before creating rates to push to AirBnb let’s review where some of the values are set. Each connection
              details page has an option called “Price Settings” located in the Actions dropdown.
            </p>

            <img src="/assets/images/listing-wizards/airbnb/price-settings.png" alt="" class="w-75 mb-3" />
            <p>You will be able to view the price settings imported from AirBnb on the following page.</p>

            <div class="d-flex align-items-center gap-3 mt-3">
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="settings.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #settings>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">2</div>
              Adjust Price Settings
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>The following items can be adjusted in your Price Settings window.</p>

            <ul>
              <li>
                <b>Base Price</b>: This is the standard price that would apply if no rates were being pushed to AirBnb.
                Under most circumstances you will be pushing a rate to AirBnb so this is not necessary to adjust from
                the price settings window.
              </li>
              <li>
                <b>Weekend Price</b>: This price will also not need to be set here. AdvanceCM rates allow you to push
                different rates for specific days of the week. Weekend pricing will be set using AdvanceCM rates which
                we will cover shortly.
              </li>
              <li>
                <b>Weekly and Monthly Discount</b>: These settings will affect all bookings of 7 days or greater, and 30
                days or greater for Monthly. Simply add in the percentage discount you would like to apply for bookings
                that meet these minimum lengths of stay and the discount will apply in AirBnb.
              </li>
              <li>
                <b>Cleaning Fee and Security Deposit</b>: The only place where you can adjust these values is here in
                the Price Settings window.
              </li>
              <li>
                <b>Extra Guest Fee and Guests Included</b>: Can only be adjusted in the Price Settings window. Please
                note that these fields will be optional when creating rates to push to AirBnb but in order to change
                these values, you must change them under Price Settings.
              </li>
              <li><b>Listing Currency</b>: Can only be adjusted in the Price Settings window.</li>
            </ul>
            <p>
              Please hit the Price Settings button below to verify the details that have been imported from AirBnb. You
              can make adjustments and hit Save or close the window after confirming the values are correct.
            </p>

            <div class="d-flex align-items-center gap-3 mt-3">
              <button class="btn btn-light-info" (click)="onPriceSettings()">
                <i class="fas fa-money-bill"></i> Price Settings
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="addRates.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #addRates>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">3</div>
              Add Rates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              As mentioned previously in this connection wizard, rates will now need to be pushed from AdvanceCM to
              Airbnb. Until this is done, your availability will remain blocked and the connection will not be complete.
            </p>

            <p>If you have already prepared rates which you want to push to Airbnb please hit Continue to proceed.</p>
            <div class="d-flex align-items-center gap-3">
              <button class="btn btn-light-info" (click)="onAddStandardRate()">
                <i class="far fa-plus"></i> Add Standard Rate
              </button>
              <button class="btn btn-light-info" (click)="onAddDynamicRate()">
                <i class="far fa-plus"></i> Add Dynamic Rate
              </button>
              <div class="flex-fill"></div>
              <button class="btn btn-light-info" (click)="pushRates.open()">
                Next <i class="cs-icon cs-icon-arrow-down-long"></i>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel #pushRates>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="step-number mr-2">4</div>
              Push Rates
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p>
              This section will push the rates prepared by you previously to Airbnb. After completing this step you
              should see both the availability and rates match with your Airbnb rates calendar.
            </p>

            <p>
              After clicking the Push Rates button you will be asked to specify the AdvanceCM Rate Category and the
              Channel Rate Category.
            </p>

            <p>Here is a quick explanation of both values.</p>

            <div class="text-semi-bold">AdvanceCM Rate Category:</div>
            <p>
              This corresponds with the rate category you have created or selected when creating your rates in
              AdvanceCM.
            </p>

            <div class="text-semi-bold">Channel Rate Category:</div>
            <p>
              This corresponds with the rate name in Airbnb. By default the name of this rate will be Standard, but it
              is possible that you have renamed the standard rate in Airbnb and you will instead see that rate plan name
              as an option.
            </p>
            <div class="d-flex align-items-center gap-3">
              <button class="btn btn-light-info" (click)="onPushRates()">
                <i class="cs-icon cs-icon-upload"></i> Push Rates
              </button>
              <div class="flex-fill"></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-between">
  <button class="btn btn-outline-light" (click)="prev.emit()">
    <i class="cs-icon cs-icon-arrow-left-long text-xxs"></i>
    Previous
  </button>
  <button class="btn btn-secondary-info" (click)="next.emit()">
    Next
    <i class="cs-icon cs-icon-arrow-right-long text-xxs"></i>
  </button>
</div>
