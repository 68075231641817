import { filter, values, pick, map, get, compact } from 'lodash'
import { isSomething } from './is-something'

export function createSearch<T>(searchableDataKeys: string[]) {
  return (items: T[], term: string) => {
    term = `${term || ''}`.trim().toLowerCase()
    if (!term) {
      return items
    }

    return filter(items, (data) => {
      const dataKeys = searchableDataKeys && searchableDataKeys.length ? [...searchableDataKeys] : Object.keys(data)
      let searchableValues = []
      if (isSomething(dataKeys)) {
        searchableValues = map(searchableDataKeys, (key) => get(data, key, ''))
      } else {
        searchableValues = values(data)
      }

      const dataStr = compact(searchableValues)
        .map((v) => `${v || ''}`.toLowerCase())
        .join('◬')
      return dataStr.indexOf(term) !== -1
    })
  }
}
