import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { selectAllInquiriesByGuestName } from '@tv3/store/inquiry/inquiry.selectors'
import { SaveForm } from '@tokeet-frontend/tv3-platform'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { Message } from '@tv3/store/message/message.model'
import { MoveMessage } from '@tv3/store/message/message.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { BehaviorSubject } from 'rxjs'
import { SearchService } from '@tv3/store/search/search.service'

@Component({
  selector: 'app-move-message',
  templateUrl: './move-message.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./move-message.component.scss'],
})
export class MoveMessageComponent {
  form = this.fb.group({
    inquiry: [undefined, [Validators.required]],
  })

  search = new BehaviorSubject<string>('')
  inquiries$ = this.searchService
    .getInquiries(this.search)
    .pipe(map((inquiries) => R.filter((i) => i.id !== this.data.message.inquiryId, inquiries)))

  constructor(
    public dialogRef: MatDialogRef<MoveMessageComponent>,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private searchService: SearchService,
    @Inject(MAT_DIALOG_DATA) public data: { message: Message; inquiry: Inquiry }
  ) {}

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.store.dispatch(
      MoveMessage({
        toInquiryId: formValues.inquiry,
        fromInquiryId: this.data.inquiry.id,
        message: this.data.message,
      })
    )
    this.close()
  }
}
