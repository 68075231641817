import { Injectable } from '@angular/core'
import { ManageRentalTagsDialogComponent } from './manage-rental-tags-dialog.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Rental } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class ManageRentalTagsDialogService {
  constructor(private dialog: MatDialog) {}

  open(rental: Rental) {
    const defaultConfig: MatDialogConfig = {
      width: '600px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { rental },
    }

    return this.dialog.open(ManageRentalTagsDialogComponent, defaultConfig)
  }
}
