<app-attachment-upload
  *ngIf="!selectFromExisting"
  (done)="onDone($event)"
  (switchToRecentFiles)="selectFromExisting=true"
  (close)="onClose()"
></app-attachment-upload>
<app-attachment-select
  *ngIf="selectFromExisting"
  (done)="onDone($event)"
  (close)="onClose()"
></app-attachment-select>
