import * as momentNs from 'moment'
const moment = momentNs

export type TimeIput = number | momentNs.Moment
export type TimeStep = momentNs.unitOfTime.DurationConstructor

export const oneDaySeconds = 24 * 60 * 60

export function itemsInTimeRange(from: TimeIput, to: TimeIput, step: TimeStep = 'day', includeLast = true) {
  const dates: number[] = []

  const m = toMoment(from).startOf(step)
  const end = toMoment(to).endOf(step)

  do {
    dates.push(m.unix())
    m.add(1, step).startOf(step)
  } while (includeLast ? m.isSameOrBefore(end, step) : m.isBefore(end, step))

  return dates
}

export function toMoment(value?: TimeIput, utc = true, clone = true) {
  return moment.isMoment(value) ? (clone ? value.clone() : value) : (utc ? moment.utc : moment)(value && value * 1000)
}

export function epochToUTCDate(epoch: number) {
  return moment.utc(epoch * 1000).toDate()
}

export function localTodayToUTC() {
  const local = moment()
  const today = moment.utc({ date: local.date(), month: local.month(), year: local.year() })

  return today.startOf('date')
}
