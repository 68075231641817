import { Injectable } from '@angular/core'
import { asyncScheduler, EMPTY, from, Observable, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map, observeOn, switchMap, take, tap } from 'rxjs/operators'
import * as R from 'ramda'
import * as moment from 'moment'
import { HttpClient } from '@angular/common/http'
import { BookingEngineCostResponse } from '@tv3/interfaces/responses/booking-engine-cost.response'
import { BookingEngineCostRequest } from '@tv3/interfaces/requests/booking-engine-cost.request'
import { BookingEngineCost } from '@tv3/models/inquiry/booking-engine-cost'
import { AddInquiryForm } from '@tv3/interfaces/forms/add-inquiry.form'
import { Inquiry, InquiryPaymentStatus } from '@tv3/store/inquiry/inquiry.model'
import { UpdateQuoteDetailsForm } from '@tv3/interfaces/forms/update-quote-details.form'
import { BookingCostResolver } from '@tokeet/cost-resolver'
import { KEY_TOKEN } from '@tv3/services/auth.service'
import { environment } from '../../environments/environment'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  asUTCEpoch,
  isSomething,
  loadRentalsComplete,
  LocalStorage,
  RentalService,
  selectAllRentals,
  selectChannelByName,
  selectOnce,
  selectRentalsLoaded,
  selectSome,
  TOKEET_CHANNEL_ID,
  TOKEET_CHANNEL_RATE_CATEGORY,
  tokeetDashboardChannel,
} from '@tokeet-frontend/tv3-platform'
import { selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class InquiryChargeService {
  constructor(
    private http: HttpClient,
    private rentalService: RentalService,
    private store: Store<fromRoot.State>,
    private storage: LocalStorage
  ) {}

  canCallEngineCost(form: AddInquiryForm): boolean {
    return !!form.rentalId && !!form.adults && !!form.arriveDate && !!form.departDate
  }

  convertFormToBookingEngineRequest(
    form: AddInquiryForm,
    isChargeUserDefined = false,
    ignore_fees: string[] = []
  ): Observable<BookingEngineCostRequest> {
    const start = asUTCEpoch(form.arriveDate)
    const end = moment(form.departDate).isValid()
      ? asUTCEpoch(form.departDate)
      : moment
          .utc(start * 1000)
          .add(1, 'day')
          .unix()

    const request = {
      start: start,
      end: end,
      adults: form.adults,
      child: form.children || 0,
      category: TOKEET_CHANNEL_RATE_CATEGORY,
      rid: form.rentalId,
      discounts: R.filter((d: { amount: number }) => !R.isEmpty(d.amount), form.discounts),
      ignore_fees,
    } as BookingEngineCostRequest

    if (isChargeUserDefined) {
      request.usercharge = form.charge
    }

    if (form.source) {
      return this.store.pipe(
        selectOnce(selectChannelByName, { name: form.source }),
        map((channel) => {
          request.channel_id = (channel && channel.id) || TOKEET_CHANNEL_ID
          return request
        })
      )
    } else {
      request.channel_id = TOKEET_CHANNEL_ID
      return of(request)
    }
  }

  // set ignoreUserCharge to true to recalculate charge
  convertInquiryToBookingEngineRequest(
    form: UpdateQuoteDetailsForm,
    inquiry: Inquiry,
    ignoreUserCharge = false
  ): Observable<BookingEngineCostRequest> {
    const request = {
      start: inquiry.guestArrive,
      end: inquiry.guestDepart,
      adults: inquiry.numAdults,
      child: inquiry.numChild,
      category: form.rateCategory,
      rid: inquiry.rentalId,
      discounts: R.filter((d: { amount: number }) => !R.isEmpty(d.amount), form.discounts),
    } as BookingEngineCostRequest

    if (!ignoreUserCharge) {
      request.usercharge = form.charge
    }

    if (isSomething(inquiry.inquirySource)) {
      return this.store.pipe(
        selectOnce(selectChannelByName, { name: inquiry.inquirySource }),
        map((channel) => {
          request.channel_id = (channel && channel.id) || TOKEET_CHANNEL_ID
          return request
        })
      )
    } else {
      request.channel_id = TOKEET_CHANNEL_ID
      return of(request)
    }
  }

  /**
   * request can contain {userCharge: number} to make booking engine calculate with user defined charge
   */
  getEngineCost(request: BookingEngineCostRequest): Observable<BookingEngineCost | null> {
    const url = `@api/bookingengine/cost/${request.rid}`

    request.channel_id = request.channel_id || tokeetDashboardChannel.id

    if (request.adults === 0) {
      request.adults = 1
    }
    if (!request.category || request.category === 'default') {
      request.category = TOKEET_CHANNEL_RATE_CATEGORY
    }

    return this.http.post<BookingEngineCostResponse>(url, request).pipe(
      map((response) => {
        if (R.isEmpty(response.cost)) {
          return null
        } else {
          return BookingEngineCost.deserialize(response.cost)
        }
      }),
      catchError(() => EMPTY)
    )
  }

  getCharges(inquiry: Inquiry): Observable<Inquiry> {
    return this.store.pipe(
      selectOnce(selectRentalsLoaded),
      switchMap((isLoaded) => {
        if (isLoaded) {
          return this.store.pipe(selectOnce(selectAllRentals))
        } else {
          return this.rentalService.all().pipe(tap((rentals) => this.store.dispatch(loadRentalsComplete({ rentals }))))
        }
      }),
      switchMap((rentals) => {
        return <Observable<Inquiry>>from(
          BookingCostResolver.prepareBookingCharges(
            this.storage.get(KEY_TOKEN),
            environment.apiUrl,
            inquiry as any,
            rentals
          )
        ).pipe(
          observeOn(asyncScheduler),
          catchError(() => EMPTY)
        )
      })
    )
  }

  calculateRentalCharge(inquiry: Inquiry, ignoreUserCharge?: boolean): Observable<Inquiry> {
    return this.store.pipe(
      select(selectAllRentals),
      take(1),
      switchMap((rentals) => {
        if (isSomething(rentals)) {
          return of(rentals)
        } else {
          return this.rentalService.all().pipe(tap((rentals) => this.store.dispatch(loadRentalsComplete({ rentals }))))
        }
      }),
      switchMap((rentals) =>
        this.store.pipe(
          selectOnce(selectChannelByName, { name: inquiry.inquirySource }),
          map((channel) => ({ rentals, channel }))
        )
      ),
      concatMap(({ rentals, channel }) => {
        return <Observable<Inquiry>>(
          from(
            BookingCostResolver.calculateBookingCharges(
              this.storage.get(KEY_TOKEN),
              environment.apiUrl,
              inquiry as any,
              rentals,
              R.path(['charges', 'rateCategory'], inquiry),
              ignoreUserCharge,
              (channel && channel.id) || TOKEET_CHANNEL_ID
            )
          ).pipe(catchError(() => EMPTY))
        )
      })
    )
  }

  getBookingPaymentStatus(
    inquiryId: string
  ): Observable<{ paid: boolean; remaining: number; status: InquiryPaymentStatus }> {
    return this.store.pipe(
      selectSome(selectInquiry, { id: inquiryId }),
      switchMap((inquiry) => this.getBookingPaymentStatusForInquiry(inquiry))
    )
  }

  getBookingPaymentStatusForInquiry(
    inquiry: Inquiry
  ): Observable<{ paid: boolean; remaining: number; status: InquiryPaymentStatus }> {
    return of(inquiry).pipe(
      // filter(inquiry => inquiry && !!inquiry.booked),
      exhaustMap((inquiry) =>
        this.getCharges(inquiry).pipe(
          switchMap(({ charges }) => {
            let sum = 0
            if (R.is(String, charges.sum)) {
              sum = +parseFloat(<any>charges.sum).toFixed(2)
            } else if (R.is(Number, charges.sum)) {
              sum = +charges.sum.toFixed(2)
            } else {
              sum = charges.sum
            }
            if (!charges || sum === 0) {
              return of({
                paid: true,
                remaining: 0,
                status: InquiryPaymentStatus.Paid,
              })
            }

            const url = `@api/inquiry/paid/${inquiry.id}?amt=${sum}`
            return this.http.get<{ paid: number; remaining: number }>(url).pipe(
              map((response) => {
                const result = {
                  paid: !!response.paid,
                  remaining: response.remaining || 0,
                  status: !!response.paid
                    ? InquiryPaymentStatus.Paid
                    : response.remaining < sum
                    ? InquiryPaymentStatus.PartialPaid
                    : InquiryPaymentStatus.Unpaid,
                }

                if (result.remaining < 0) {
                  result.remaining = 0
                }

                return result
              }),
              catchError((error) => {
                this.store.dispatch(ActionFailed({ error }))
                return EMPTY
              })
            )
          })
        )
      )
    )
  }
}
