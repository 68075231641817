import * as R from 'ramda'
import { adapter, initialState, State } from './preferences.state'
import { Action, createReducer, on } from '@ngrx/store'
import {
  LoadPreferencesComplete,
  UpdateBrandingComplete,
  UpdatePreferencesComplete,
} from '@tv3/store/preferences/preferences.actions'
import { updateOne } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(LoadPreferencesComplete, (state, { preference }) =>
    adapter.setAll([preference], R.merge(state, { isLoaded: true }))
  ),
  on(UpdatePreferencesComplete, UpdateBrandingComplete, (state, { update }) => updateOne(adapter, update, state))
)

export function preferencesReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
