import { Pipe, PipeTransform } from '@angular/core'
import * as R from 'ramda'

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  transform(roleNumbers, roleHash) {
    roleNumbers = R.is(Array, roleNumbers) ? roleNumbers : [roleNumbers]
    let fRoles = [],
      delimiter = ', '
    for (let i = 0; i < roleNumbers.length; i++) {
      for (let j = 0; j < roleHash.length; j++) {
        if (roleHash[j].value + '' == roleNumbers[i] + '') {
          fRoles.push(roleHash[j].label)
        }
      }
    }
    return fRoles.join(delimiter)
  }
}
