import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import {
  AddCreditCard,
  AddCreditCardComplete,
  DeleteCreditCard,
  DeleteCreditCardComplete,
  LoadCards,
  LoadCardsComplete,
  ReplaceCreditCard,
  ReplaceCreditCardComplete,
} from './card.actions'
import { CardsService } from './card.service'

@Injectable()
export class CardEffect {
  @Effect()
  loadCards$ = this.actions$.pipe(
    ofType(LoadCards),
    exhaustMap(() =>
      this.billingService.creditCards().pipe(
        map((cards) => LoadCardsComplete({ cards })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  addCreditCard$ = this.actions$.pipe(
    ofType(AddCreditCard),
    concatMap(({ request }) =>
      this.billingService.addCCard(request).pipe(
        tap(() => this.toast.success('Credit card added successfully.')),
        map((card) => AddCreditCardComplete({ card })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  replaceCreditCard$ = this.actions$.pipe(
    ofType(ReplaceCreditCard),
    concatMap(({ request }) =>
      this.billingService.addCCard(request).pipe(
        tap(() => this.toast.success('Credit card updated successfully.')),
        map((card) => ReplaceCreditCardComplete({ card })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteCreditCard$ = this.actions$.pipe(
    ofType(DeleteCreditCard),
    concatMap(({ id }) =>
      this.billingService.deleteCCard(id).pipe(
        tap(() => this.toast.success('Credit card deleted successfully.')),
        map(() => DeleteCreditCardComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private billingService: CardsService, private toast: Toaster) {}
}
