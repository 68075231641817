<form [formGroup]="form" [formEditablePermission]="form">
  <div class="card bg-default2 position-relative">
    <button
      class="btn btn-secondary-danger btn-circle item-remove-btn"
      *ngIf="removable"
      (click)="onRemoveItem()"
      matTooltip="Remove this line item"
    >
      <i class="cs-icon cs-icon-trash"></i>
    </button>
    <div class="row">
      <div class="col-sm-8 col-xl-9 border-right">
        <div class="pl-4 pt-3 pr-2">
          <div class="row">
            <div class="col-sm-5">
              <mat-form-field2 class="mat-block">
                <input type="text" matInput [placeholder]="titles.item" formControlName="item" required />
                <mat-error *ngIf="form.get('item').touched && form.get('item').hasError('required')">
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-2">
              <mat-form-field2 class="w-100">
                <span matPrefix [innerHtml]="currency?.symbol" *ngIf="!(form.get('unitCost').value | isNil)"></span>
                <input
                  type="number"
                  matInput
                  [placeholder]="titles.unit"
                  formControlName="unitCost"
                  decimal="4"
                  required
                />
                <mat-error *ngIf="form.get('unitCost').touched && form.get('unitCost').hasError('required')">
                  Value is <strong>required</strong>
                </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-2">
              <mat-form-field2 class="w-100">
                <input
                  type="number"
                  matInput
                  [placeholder]="titles.qty"
                  decimal="4"
                  formControlName="qty"
                  required
                  pattern="^-?(0|[1-9]\d*)?$"
                />
                <mat-error *ngIf="form.get('qty').touched && form.get('qty').hasError('required')">
                  Value is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('qty').hasError('minNumber')">
                  Value must be at least <strong>1</strong>
                </mat-error>
                <mat-error *ngIf="form.get('qty').hasError('pattern')"> Only whole numbers allowed </mat-error>
              </mat-form-field2>
            </div>
            <div class="col-sm-3">
              <mat-form-field2
                class="w-100"
                [ngClass]="{ 'currency-input-has-value': !(form.get('discount').value | isNil) }"
              >
                <span matPrefix [innerHtml]="currency?.symbol" *ngIf="!(form.get('discount').value | isNil)"></span>
                <input
                  type="number"
                  matInput
                  [placeholder]="titles.discountFees"
                  formControlName="discount"
                  decimal="4"
                />
              </mat-form-field2>
            </div>
          </div>
          <mat-form-field2 class="mat-block">
            <textarea
              matInput
              maxlength="500"
              rows="3"
              formControlName="description"
              [placeholder]="titles.description"
              cdkTextareaAutosize
            ></textarea>
          </mat-form-field2>
        </div>
      </div>
      <div class="col-sm-4 col-xl-3">
        <div class="py-3 pr-3 pl-2">
          <div
            formArrayName="taxes"
            *ngFor="let taxForm of taxesForm.controls; let i = index"
            class="d-flex align-items-start gap-3"
            [class.mb-3]="!!i"
          >
            <app-select-search
              bindValue="formName"
              bindLabel="formName"
              [inline]="!!i"
              [hasButton]="true"
              [hasDelete]="true"
              [buttonText]="'Create a new tax'"
              [buttonTooltip]="'Add new tax to your account'"
              (buttonAction)="onNewTax()"
              (search)="searchTaxTerm = $event"
              (openedChange)="searchTaxTerm = ''"
              (delete)="onDeleteTax($event)"
              [multiple]="false"
              [items]="taxes"
              [group]="taxForm"
              [ctrlName]="'taxFormName'"
              placeholder="Select Tax"
              class="mat-block"
              style="width: 50%"
            >
            </app-select-search>
            <div
              [class.align-input-row-end]="!i"
              class="d-flex align-items-center gap-3"
              style="flex: 1; overflow: hidden"
            >
              <button
                class="btn btn-secondary-danger"
                (click)="onRemoveTax(i)"
                *ngIf="editable"
                matTooltip="Remove Tax"
              >
                <i class="cs-icon cs-icon-trash"></i>
              </button>
              <div
                *ngIf="item.taxes[i] as tax"
                class="text-right text-truncate"
                style="flex: 1; overflow: hidden; margin-top: 2px"
                matTooltipPosition="above"
                [matTooltip]="item | invoiceLineTaxTotal: [tax]:guests | tokeetCurrency: currency"
              >
                <strong>{{ item | invoiceLineTaxTotal: [tax]:guests | tokeetCurrency: currency }}</strong>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center gap-3 mt-4">
            <mat-form-field2 class="mat-block" [showLabel]="false">
              <mat-select placeholder="Partial Payment" [disabled]="!editable" formControlName="percent">
                <mat-option *ngFor="let item of partialPaymentOpts" [value]="item">
                  {{ item * 100 }}% {{ item === 1 ? 'Full Payment' : 'Partial Payment' }}
                </mat-option>
              </mat-select>
            </mat-form-field2>
            <button class="btn btn-primary btn-circle" matTooltip="Add new tax" (click)="onAddTax()" *ngIf="editable">
              <i class="cs-icon cs-icon-plus"></i>
            </button>
          </div>
          <div class="text-right mt-4">
            <span class="total-price">
              <strong>{{ item | invoiceLineTotal: true:guests | tokeetCurrency: currency }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
