import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ApiKey } from './api-keys.model'

export interface State extends EntityState<ApiKey> {
  isLoaded: boolean
}

export const adapter: EntityAdapter<ApiKey> = createEntityAdapter<ApiKey>({
  sortComparer: false,
  selectId: (apiKey: ApiKey) => apiKey.key,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
})
