import { AfterContentInit, Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { validateMinNumber } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { Destroyable, SaveForm } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { InvoiceTemplateDueDateRule } from '../../../../store/templates/template.model'
import * as lodash from 'lodash'

export interface EditInvoiceTemplateDueDateRuleComponentParams {
  cancelBtnClass?: string
  clearBtnClass?: string
  doneBtnClass?: string
  doneBtnIconClass?: string
}

@Component({
  selector: 'app-edit-invoice-template-rule',
  host: { class: 'modal-content' },
  templateUrl: './edit-invoice-template-due-date-rule.component.html',
  styleUrls: ['./edit-invoice-template-due-date-rule.component.scss'],
})
export class EditInvoiceTemplateDueDateRuleComponent extends Destroyable implements OnInit, AfterContentInit {
  config: EditInvoiceTemplateDueDateRuleComponentParams = {
    cancelBtnClass: 'btn btn-light',
    clearBtnClass: 'btn btn-secondary-light',
    doneBtnClass: 'btn btn-secondary-info',
    doneBtnIconClass: 'cs-icon cs-icon-save',
  }

  form = this.fb.group({
    days: ['', [Validators.required, validateMinNumber(1)]],
    operator: ['', [Validators.required]],
    event: ['', [Validators.required]],
  })

  isEditing = false

  constructor(
    public dialogRef: MatDialogRef<EditInvoiceTemplateDueDateRuleComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      rule: InvoiceTemplateDueDateRule
      config?: EditInvoiceTemplateDueDateRuleComponentParams
    },
    private fb: FormBuilder
  ) {
    super()
  }

  ngOnInit() {
    this.config = {
      ...this.config,
      ...this.data.config,
    }
    this.form.setValidators((form: AbstractControl) => {
      const { operator, event } = (form as FormGroup).getRawValue()
      const operatorCtrl = this.form.get('operator')
      if (event === 'invoice' && operator === 'before') {
        operatorCtrl.setErrors({ afterInvoice: true })
      } else {
        const errors = operatorCtrl.errors || {}
        delete errors.afterInvoice
        operatorCtrl.setErrors(lodash.isEmpty(errors) ? null : errors)
      }
      return null
    })

    const rule: any = R.pathOr({}, ['rule'], this.data)
    this.form.patchValue({
      days: rule.days,
      operator: rule.operator,
      event: rule.event,
    })
  }

  ngAfterContentInit(): void {
    this.isEditing = !R.isEmpty(this.data.rule) && !R.all(R.equals(null))(R.values(this.data.rule))
  }

  onClose() {
    this.dialogRef.close()
  }

  onClear() {
    this.form.reset()
  }

  @SaveForm()
  onSave(form: FormGroup) {
    const values = this.form.getRawValue() as InvoiceTemplateDueDateRule
    this.dialogRef.close(values)
  }
}
