<div class="p-3">
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="settingsForm">
        <mat-form-field2
          matTooltip="Your balance will be recharged when you hit this amount"
          matTooltipPosition="above"
        >
          <mat-label>Auto recharge when balance goes below ($)</mat-label>
          <input type="number" matInput formControlName="recharge_threshold" />
          <mat-error
            *ngIf="
              settingsForm.get('recharge_threshold').hasError('required') &&
              settingsForm.get('recharge_threshold').touched
            "
          >
            Value is required
          </mat-error>
          <mat-error *ngIf="settingsForm.get('recharge_threshold').hasError('positive')">
            Value should be greater then <strong>0</strong>
          </mat-error>
        </mat-form-field2>
        <mat-form-field2
          matTooltip="This amount will be your actual credit/balance after recharge"
          matTooltipPosition="above"
        >
          <mat-label>Bring balance back up to ($)</mat-label>
          <input type="number" matInput formControlName="recharge_balance" />
          <mat-error
            *ngIf="
              settingsForm.get('recharge_balance').hasError('required') && settingsForm.get('recharge_balance').touched
            "
          >
            Value is required
          </mat-error>
          <mat-error *ngIf="settingsForm.get('recharge_balance').hasError('positive')">
            Value should be greater then <strong>0</strong>
          </mat-error>
        </mat-form-field2>
        <div class="alert alert-secondary-warning">
          Note: Advance Intelligence credit will be deducted equally for every generated message even if it's not sent
          (i.e draft)
        </div>

        <div class="d-flex">
          <button class="btn btn-secondary-info ml-auto" (click)="onSave(settingsForm)">
            <i class="fa fa-save"></i> Save
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-6 border-left">
      <form [formGroup]="rechargeForm">
        <mat-form-field2 matTooltip="Your balance will be increased immediately" matTooltipPosition="above">
          <mat-label>Instant Recharge ($)</mat-label>
          <input type="number" matInput formControlName="recharge_amount" required />
          <mat-error
            *ngIf="
              rechargeForm.get('recharge_amount').hasError('required') && rechargeForm.get('recharge_amount').touched
            "
          >
            Value is required
          </mat-error>
          <mat-error *ngIf="rechargeForm.get('recharge_amount').hasError('positive')">
            Value should be greater then <strong>0</strong>
          </mat-error>
          <mat-error *ngIf="rechargeForm.get('recharge_amount').hasError('min')">
            Value should be greater than <strong>5</strong>
          </mat-error>
        </mat-form-field2>

        <div class="d-flex">
          <button [disabled]="isRecharging" class="btn btn-secondary-info ml-auto" (click)="onCharge(rechargeForm)">
            <i class="fa fa-save"></i> Recharge
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
