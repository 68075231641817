import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { RentalsSharedModule } from '@tv3/containers/rentals/rentals-shared.module'
import { isSomething, Rental } from '@tokeet-frontend/tv3-platform'
import { CopyRentalInfoDialogComponent } from './copy-rental-info-dialog.component'
import { filter, take } from 'rxjs/operators'

@Injectable({
  providedIn: RentalsSharedModule,
})
export class CopyRentalInfoDialogService {
  constructor(private dialog: MatDialog) {}

  open(rentals: Rental[]) {
    const defaultConfig: MatDialogConfig = {
      width: '550px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: { rentals },
    }
    return this.dialog
      .open(CopyRentalInfoDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        filter((res) => isSomething(res)),
        take(1)
      )
  }
}
