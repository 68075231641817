import { adapter, initialState, State } from './state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'
import {
  CreateAccountRateAutomationComplete,
  CreateGapNightAutomationComplete,
  CreateLastMinuteAutomationComplete,
  CreateMinStayAutomationComplete,
  CreateRatePushAutomationComplete,
  DeleteGapNightAutomationComplete,
  DeleteLastMinuteAutomationComplete,
  DeleteMinStayAutomationComplete,
  DeleteRatePushAutomationComplete,
  LoadAccountRateAutomationsComplete,
  UpdateGapNightAutomationComplete,
  UpdateLastMinuteAutomationComplete,
  UpdateMinStayAutomationComplete,
  UpdateRatePushAutomationComplete,
} from './actions'

export const reducer = createReducer(
  initialState,
  on(LoadAccountRateAutomationsComplete, (state, { items }) =>
    adapter.setAll(items, R.merge(state, { isLoaded: true }))
  ),
  on(
    CreateAccountRateAutomationComplete,
    //
    CreateMinStayAutomationComplete,
    CreateGapNightAutomationComplete,
    CreateLastMinuteAutomationComplete,
    CreateRatePushAutomationComplete,

    (state, { item }) => adapter.setOne(item, state)
  ),
  on(
    UpdateMinStayAutomationComplete,
    UpdateGapNightAutomationComplete,
    UpdateLastMinuteAutomationComplete,
    UpdateRatePushAutomationComplete,

    (state, { item }) => adapter.setOne(item, state)
  ),
  on(
    DeleteMinStayAutomationComplete,
    DeleteGapNightAutomationComplete,
    DeleteLastMinuteAutomationComplete,
    DeleteRatePushAutomationComplete,
    (state, { item }) => adapter.setOne(item, state)
  )
)

export function rateAutomationReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
