<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-cash-alt"></i> Homeaway Fees</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="empty" *ngIf="!feesForm.length && !loading">
    <p>No fees have been added yet.</p>
  </div>
  <app-loading-section *ngIf="loading"></app-loading-section>
  <form [formGroup]="form" *ngIf="!loading && !!feesForm.length">
    <ng-container formArrayName="fees" *ngFor="let fee of feesForm.controls; let i = index">
      <div class="row" [formGroupName]="i">
        <div class="col-3">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Name:" formControlName="name" type="text" required />
          </mat-form-field2>
        </div>
        <div class="col-3">
          <mat-form-field2 class="mat-block">
            <input matInput placeholder="Amount" formControlName="amount" type="number" required />
          </mat-form-field2>
        </div>
        <div class="col-3">
          <mat-form-field2 class="mat-block">
            <mat-select placeholder="Type" formControlName="type" required>
              <mat-option value="pct">Percent</mat-option>
              <mat-option value="flat">Flat</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
        <div class="col-3">
          <mat-form-field2 class="mat-block">
            <mat-select placeholder="Modality" formControlName="modality" required>
              <mat-option value="ps">Per Stay</mat-option>
              <mat-option value="pn">Per Night</mat-option>
              <mat-option value="pp">Per Person</mat-option>
              <mat-option value="pppn">Per Person/Per Night (PP/PN)</mat-option>
            </mat-select>
          </mat-form-field2>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Close</button>
  <button
    (click)="onEditFees()"
    type="button"
    matTooltip="Go to Fee Section in Rental section to edit this"
    class="btn btn-secondary-info create-btn"
  >
    <i class="fal fa-edit"></i> Edit Fees
  </button>
</div>
