import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DiscountCodeBookingsComponent } from './discount-code/overlay/bookings/discount-code-bookings.component'
import { DiscountCodeOverlayComponent } from './discount-code/overlay/discount-code-overlay.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { DiscountDialogService } from './discount-dialog.service'
import { DiscountCodeLogsComponent } from './discount-code/overlay/logs/discount-code-logs.component'
import { DiscountsTableComponent } from './discounts-table/discounts-table.component'
import { DiscountCodesTableComponent } from './discount-code/discount-codes-table.component'
import { LosDiscountTableComponent } from './los-discount/los-discount-table.component'
import { DiscountCodeDetailComponent } from './discount-code/overlay/details/discount-code-detail.component'
import { DiscountCodeOverlayService } from './discount-code/overlay/discount-code-overlay.service'
import { LosDiscountOverlayComponent } from './los-discount/overlay/los-discount-overlay.component'
import { LosDiscountOverlayService } from './los-discount/overlay/los-discount-overlay.service'
import { AddDiscountCodeDialogComponent } from './discount-code/add/add-discount-code-dialog.component'
import { AddLosDiscountDialogComponent } from './los-discount/add/add-los-discount-dialog.component'
import { ExportDiscountsDialogService } from '@tv3/containers/discounts/dialogs/export/export-discounts-dialog.service'

const components = [
  AddDiscountCodeDialogComponent,
  DiscountCodeOverlayComponent,
  DiscountCodeDetailComponent,
  DiscountCodeBookingsComponent,
  DiscountCodeLogsComponent,
  DiscountCodesTableComponent,

  LosDiscountTableComponent,
  LosDiscountOverlayComponent,
  AddLosDiscountDialogComponent,

  DiscountsTableComponent,
]

const services = [
  //
  DiscountCodeOverlayService,
  LosDiscountOverlayService,
  DiscountDialogService,
  ExportDiscountsDialogService,
]

const modules = [
  //
  SharedModule,
]

@NgModule({
  declarations: [...components],
  exports: [...components, ...modules],
  providers: [...services],
  imports: [CommonModule, ...modules],
})
export class DiscountsSharedModule {}
