<app-loading-section *ngIf="isLoading; else content"></app-loading-section>

<ng-template #content>
  <app-history-list
    *ngIf="!!activities?.length; else empty"
    [list]="activities || []"
    contentKey="log"
    userNameKey="creator_id"
    timeKey="created"
  ></app-history-list>
</ng-template>

<ng-template #empty>
  <ng-content></ng-content>
</ng-template>
