<div class="paginator-container d-flex align-items-center gap-3">
  <div class="paginator-range-label">{{ getRangeLabel(pageIndex, pageSize, length) }}</div>
  <div class="flex-fill"></div>

  <div class="paginator-page-size d-flex align-items-center gap-2" *ngIf="!hidePageSize">
    <div class="paginator-page-size-label">Items per page</div>

    <mat-form-field2
      *ngIf="_displayedPageSizeOptions.length > 1"
      class="paginator-page-size-select"
      [showLabel]="false"
      style="min-width: 5rem"
    >
      <mat-select
        [value]="pageSize"
        [disabled]="disabled"
        [aria-label]="_intl.itemsPerPageLabel"
        (selectionChange)="_changePageSize($event.value)"
      >
        <mat-option *ngFor="let pageSizeOption of _displayedPageSizeOptions" [value]="pageSizeOption">
          {{ pageSizeOption }}
        </mat-option>
      </mat-select>
    </mat-form-field2>

    <div class="paginator-page-size-value" *ngIf="_displayedPageSizeOptions.length <= 1">{{ pageSize }}</div>
  </div>

  <div class="paginator-range-actions d-flex align-items-center gap-2">
    <button
      type="button"
      class="paginator-navigation-previous btn btn-secondary-light btn-sm"
      (click)="firstPage()"
      [attr.aria-label]="_intl.firstPageLabel"
      [matTooltip]="_intl.firstPageLabel"
      [matTooltipDisabled]="_previousButtonsDisabled()"
      [matTooltipPosition]="'above'"
      [disabled]="_previousButtonsDisabled()"
      *ngIf="showFirstLastButtons"
    >
      <i class="cs-icon cs-icon-arrow-first paginator-icon text-sm"></i>
    </button>
    <button
      type="button"
      class="paginator-navigation-previous btn btn-secondary-light btn-sm"
      (click)="previousPage()"
      [attr.aria-label]="_intl.previousPageLabel"
      [matTooltip]="_intl.previousPageLabel"
      [matTooltipDisabled]="_previousButtonsDisabled()"
      [matTooltipPosition]="'above'"
      [disabled]="_previousButtonsDisabled()"
    >
      <i class="cs-icon cs-icon-arrow-left-long paginator-icon"></i>
    </button>
    <ng-container *ngFor="let item of getPageItems">
      <button
        type="button"
        class="paginator-navigation-btn btn btn-secondary-light btn-sm"
        [class.active]="item === pageIndex"
        (click)="gotoPage(item)"
      >
        {{ item + 1 }}
      </button>
    </ng-container>
    <button
      type="button"
      class="paginator-navigation-next btn btn-secondary-light btn-sm"
      (click)="nextPage()"
      [attr.aria-label]="_intl.nextPageLabel"
      [matTooltip]="_intl.nextPageLabel"
      [matTooltipDisabled]="_nextButtonsDisabled()"
      [matTooltipPosition]="'above'"
      [disabled]="_nextButtonsDisabled()"
    >
      <i class="cs-icon cs-icon-arrow-right-long paginator-icon"></i>
    </button>
    <button
      type="button"
      class="paginator-navigation-next btn btn-secondary-light btn-sm"
      (click)="lastPage()"
      [attr.aria-label]="_intl.lastPageLabel"
      [matTooltip]="_intl.lastPageLabel"
      [matTooltipDisabled]="_nextButtonsDisabled()"
      [matTooltipPosition]="'above'"
      [disabled]="_nextButtonsDisabled()"
      *ngIf="showFirstLastButtons"
    >
      <i class="cs-icon cs-icon-arrow-last paginator-icon text-sm"></i>
    </button>
  </div>
</div>
