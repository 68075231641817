function getDayIndex(dateEpoch: number): number {
  const oneDaySeconds = 24 * 3600
  return Math.floor(dateEpoch / oneDaySeconds)
}

export function isDayBetween(dateEpoch: number, startEpoch: number, endEpoch: number): boolean {
  const startIndex = getDayIndex(startEpoch)
  const endIndex = getDayIndex(endEpoch)
  const dateIndex = getDayIndex(dateEpoch)
  return dateIndex >= startIndex && dateIndex <= endIndex
}

export function dayDiff(endEpoch: number, startEpoch: number): number {
  const startIndex = getDayIndex(startEpoch)
  const endIndex = getDayIndex(endEpoch)
  return endIndex - startIndex
}
