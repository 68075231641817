<div class="main-content" [class.loading-section]="isUpdating$ | async">
  <div class="d-flex align-items-center gap-3 mb-3">
    <h6 class="mb-0">External Links</h6>
    <div class="flex-fill"></div>
    <button (click)="onSave(form)" type="button" [disabled]="1 | isReadonlyRole" class="btn btn-secondary-info">
      <span class="cs-icon cs-icon-save"></span> Save
    </button>
  </div>

  <form [formGroup]="form" [formEditablePermission]="form">
    <div class="container-box details-box overflow-hidden">
      <div class="box-body">
        <h6 class="section-title border-top-0">Add links to your social network pages</h6>
        <div class="row px-3">
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="url" matInput formControlName="facebook" placeholder="Facebook" />
              <mat-error *ngIf="form.get('facebook').touched && form.get('facebook').hasError('url')">
                URL is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="url" matInput formControlName="twitter" placeholder="Twitter" />
              <mat-error *ngIf="form.get('twitter').touched && form.get('twitter').hasError('url')">
                URL is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="url" matInput formControlName="instagram" placeholder="Instagram" />
              <mat-error *ngIf="form.get('instagram').touched && form.get('instagram').hasError('url')">
                URL is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="url" matInput formControlName="googleplus" placeholder="Google+" />
              <mat-error *ngIf="form.get('googleplus').touched && form.get('googleplus').hasError('url')">
                URL is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
          <div class="col-sm-6">
            <mat-form-field2 class="mat-block">
              <input type="url" matInput formControlName="blog" placeholder="Blog" />
              <mat-error *ngIf="form.get('blog').touched && form.get('blog').hasError('url')">
                URL is <strong>invalid</strong>
              </mat-error>
            </mat-form-field2>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
