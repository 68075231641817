import { Component, Inject, OnInit } from '@angular/core'
import {
  AlertDialogService,
  Channel,
  Destroyable,
  Rental,
  selectRentalById,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs'
import * as lodash from 'lodash'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { debounceTime, switchMap, tap } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'
import { ChannelPropertySetting } from '@tv3/store/channel-property-setting/channel-property-setting.model'
import { selectChannelPropertySetting } from '@tv3/store/channel-property-setting/channel-property-setting.selectors'
import { AgodaContentService } from '@tv3/store/channel-content/agoda-content.service'
import {
  AgodaContentProperty,
  AgodaContentRatePlan,
  AgodaContentRoom,
} from '@tv3/store/channel-content/models/agoda.model'
import {
  AgodaPropertyClosedComplete,
  AgodaPropertyCreateComplete,
  AgodaPropertyUpdateComplete,
  AgodaRateCreateComplete,
  AgodaRateUpdateComplete,
  AgodaRoomCreateComplete,
  AgodaRoomUpdateComplete,
} from '@tv3/store/channel-content/conent.actions'

export enum AgodaContentDialogTab {
  Property,
  Room,
  Rate,
  Product,
}

@Component({
  selector: 'app-agoda-content-dialog',
  templateUrl: './agoda-content-dialog.component.html',
  styleUrls: ['./agoda-content-dialog.component.scss'],
})
export class AgodaContentDialogComponent extends Destroyable implements OnInit {
  tab$ = new BehaviorSubject<AgodaContentDialogTab>(AgodaContentDialogTab.Property)
  tabs = AgodaContentDialogTab

  channel: Channel
  rental: Rental
  connection: ConnectionView

  agodaPropertySettings: ChannelPropertySetting
  agodaProperty: AgodaContentProperty

  get agodaRoom(): AgodaContentRoom {
    return this.agodaProperty && lodash.get(this.agodaProperty.rooms, 0)
  }

  get agodaRate(): AgodaContentRatePlan {
    return this.agodaProperty && lodash.get(this.agodaProperty.rates, 0)
  }

  constructor(
    private store: Store<fromRoot.State>,
    private actions: Actions,
    private alertDialog: AlertDialogService,
    private agodaContentService: AgodaContentService,
    public dialogRef: MatDialogRef<AgodaContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { channel: Channel; connection: ConnectionView; activeTab?: AgodaContentDialogTab }
  ) {
    super()
  }

  ngOnInit() {
    this.tab$.next(this.data.activeTab || AgodaContentDialogTab.Property)
    this.channel = this.data.channel
    this.connection = this.data.connection

    if (this.connection && this.connection.rentalId) {
      this.store
        .pipe(
          select(selectRentalById(this.connection.rentalId)),
          untilDestroy(this),
          tap((rental) => (this.rental = rental))
        )
        .subscribe()
    }

    if (this.connection) {
      // @ts-ignore
      const propertyId = this.connection.propertyId
      this.store
        .pipe(
          select(selectChannelPropertySetting(this.channel.name, propertyId)),
          tap((settings) => (this.agodaPropertySettings = settings)),
          debounceTime(500),
          switchMap(() => this.onRefreshProperty(propertyId))
        )
        .subscribe()
    }

    this.actions
      .pipe(
        ofType(
          AgodaPropertyCreateComplete,
          AgodaPropertyUpdateComplete,
          AgodaRoomCreateComplete,
          AgodaRoomUpdateComplete,
          AgodaRateUpdateComplete,
          AgodaRateCreateComplete,
          AgodaPropertyClosedComplete
        ),
        debounceTime(1500),
        switchMap((data) => this.onRefreshProperty(data.propertyId)),
        untilDestroy(this)
      )
      .subscribe()
  }

  onRefreshProperty(id) {
    return this.agodaContentService.get(id).pipe(tap((property) => (this.agodaProperty = property)))
  }

  onRentalChange(rental: Rental) {
    this.rental = rental
  }

  close() {
    this.dialogRef.close()
  }
}
