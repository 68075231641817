import { Component, OnInit } from '@angular/core'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { Rental } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import { generateRentalParser, parseString, parseURL } from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import * as R from 'ramda'
import { MatDialogRef } from '@angular/material/dialog'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { selectAllRentals } from '@tokeet-frontend/tv3-platform'
import { AddImportedCalendarConnectionComplete } from '@tv3/store/connection/connection.actions'
import { ConnectionService } from '@tv3/store/connection/connection.service'
import { AddImportedCalendarConnectionPayload } from '@tv3/store/connection/connection.types'
import { concatMap, last, tap } from 'rxjs/operators'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'

@Component({
  selector: 'app-import-ical-calendar-dialog',
  templateUrl: './import-ical-calendar-dialog.component.html',
  styleUrls: ['./import-ical-calendar-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class ImportIcalCalendarDialogComponent extends Destroyable implements OnInit {
  files: ParsedUploadFile<any>[] = []
  rentals: Rental[]

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Name', field: 'name', required: true, parse: parseString },
    {
      name: 'Linked Rental',
      field: 'rentalId',
      required: true,
      parse: (str, colDef) => generateRentalParser(this.rentals)(str, colDef),
    },
    { name: 'iCal URL', field: 'url', required: true, parse: parseURL },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${R.map(
      (col) => (!col.required ? col.name : col.name + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: ['Name, Linked Rental, iCal URL'],
    sample: [
      ['Name', 'Linked Rental', 'iCal URL'],
      ['Airbnb Apartment 53', 'Apartment 53', 'https://www.airbnb.com/calendar/ical/xxxx.ics?s=xxxx'],
      ['Airbnb BaiMarco', 'BaiMarco', 'https://www.airbnb.com/calendar/ical/xxxx.ics?s=xxxx'],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportIcalCalendarDialogComponent>,
    private store: Store<fromRoot.State>,
    private connectionService: ConnectionService,
    private toaster: Toaster
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectAllRentals), untilDestroy(this)).subscribe((rentals) => {
      this.rentals = rentals
    })
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  onCreate() {
    const items: any[] = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )
    const data = items.map((item: any) => {
      const rental = R.find((r) => r.id === item.rentalId, this.rentals)
      return {
        rentalid: rental.id,
        sendto: rental.email,
        name: item.name,
        calendar: item.url,
        book: true,
      } as AddImportedCalendarConnectionPayload
    })

    of(...data)
      .pipe(
        concatMap((payload) => {
          return this.connectionService
            .addImportedCalendarConnection(payload)
            .pipe(tap((connection) => this.store.dispatch(AddImportedCalendarConnectionComplete({ connection }))))
        }),
        last()
      )
      .subscribe(
        () => {
          this.toaster.success('All calendar added successfully.')
          this.dialogRef.close()
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onClose() {
    this.dialogRef.close()
  }

  hasErrors() {
    return !!this.files.find((file) => file.isError)
  }
}
