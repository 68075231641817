import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromWebsite from './website.reducer'
import { EffectsModule } from '@ngrx/effects'
import { WebsiteEffects } from './website.effects'
import { WebsiteFieldEffects } from '@tv3/store/website/website-field.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('website', fromWebsite.websiteReducer),
    EffectsModule.forFeature([WebsiteEffects, WebsiteFieldEffects]),
  ],
  declarations: [],
})
export class WebsiteModule {}
