<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex align-items-center gap-3 flex-fill">
        <div class="d-flex align-items-center gap-2">
          <div class="modal-title">Add Rental</div>
          <app-page-help articleId="-" videoId="-"></app-page-help>
        </div>
        <div class="flex-fill"></div>
        <button (click)="close()" type="button" class="btn btn-outline-light">
          <span class="far fa-times"></span>
          Cancel
        </button>
        <button (click)="save(form)" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="form" class="container-box p-3">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <h6 class="section-title text-uppercase">Rental Information</h6>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input
                    type="text"
                    formControlName="name"
                    matInput
                    required
                    placeholder="Name"
                    (input)="onNameChange($event.target.value)"
                    autocomplete="off"
                  />
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                    Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('allNumbers')">
                    Name cannot be numbers alone.
                  </mat-error>
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('allSpaces')">
                    Name cannot be spaces alone.
                  </mat-error>
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('minlength')">
                    Name must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('name').touched && form.get('name').hasError('maxlength')">
                    Name must be at most <strong>140</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('name').hasError('nameTaken')">
                    Name is already in use by another rental.
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input type="text" formControlName="phone" matInput required placeholder="Phone" />
                  <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('required')">
                    Phone is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('phone').touched && form.get('phone').hasError('allSpaces')">
                    Phone cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input
                    type="email"
                    formControlName="email"
                    matInput
                    required
                    placeholder="AdvanceCM Email"
                    autocomplete="off"
                  />
                  <span *ngIf="form.get('name').value.length > 0 || form.get('email').value.length > 0" matSuffix
                    >@{{ subDomain }}</span
                  >
                  <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('email').touched && form.get('email').hasError('minlength')">
                    Email handle must be at least <strong>3</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('email').hasError('emailTaken')">
                    Email is already in use by another rental.
                  </mat-error>
                  <mat-error *ngIf="form.get('email').hasError('rentalEmail')">
                    Email is <strong>invalid.</strong>
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input
                    type="email"
                    formControlName="registration"
                    matInput
                    placeholder="Registration Number"
                    autocomplete="off"
                  />
                </mat-form-field2>
              </div>
              <div class="col-md-12 colors-container">
                <h6 class="manual-label">Calendar Colors</h6>
                <app-color-boxes [ctrl]="form.get('color')"></app-color-boxes>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <h6 class="section-title text-uppercase">Rental Location</h6>
              </div>
              <div class="col-md-12">
                <mat-form-field2 *ngIf="isGoogleLoaded" class="mat-block">
                  <input
                    appGooglePlaces
                    (onAddressChange)="onPlaceChange($event)"
                    autocomplete="address-1"
                    type="text"
                    formControlName="address"
                    matInput
                    placeholder="Address"
                  />
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('minlength')">
                    Address must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('maxlength')">
                    Address must be at most <strong>140</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
                    Address cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
                <mat-form-field2 *ngIf="!isGoogleLoaded" class="mat-block">
                  <input
                    autocomplete="address-1"
                    type="text"
                    formControlName="address"
                    matInput
                    placeholder="Address"
                  />
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('minlength')">
                    Address must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('maxlength')">
                    Address must be at most <strong>140</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('address').touched && form.get('address').hasError('allSpaces')">
                    Address cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <mat-form-field2 *ngIf="isGoogleLoaded" class="mat-block">
                  <input
                    appGooglePlaces
                    (onAddressChange)="onCityChange($event)"
                    type="text"
                    formControlName="city"
                    matInput
                    required
                    placeholder="City"
                  />
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('required')">
                    City is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('minlength')">
                    City must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('maxlength')">
                    City must be at most <strong>50</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('allSpaces')">
                    City cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
                <mat-form-field2 *ngIf="!isGoogleLoaded" class="mat-block">
                  <input type="text" formControlName="city" matInput required placeholder="City" />
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('required')">
                    City is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('minlength')">
                    City must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('maxlength')">
                    City must be at most <strong>50</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('city').touched && form.get('city').hasError('allSpaces')">
                    City cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input type="text" formControlName="state" matInput placeholder="State / Province / Region" />
                  <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('minlength')">
                    Value must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('maxlength')">
                    Value must be at most <strong>50</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('state').touched && form.get('state').hasError('allSpaces')">
                    State cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
              </div>
              <div class="col-md-12">
                <app-select-search
                  [items]="countryList"
                  [ctrl]="form.get('country')"
                  [multiple]="false"
                  bindLabel="name"
                  placeholder="Country"
                  [inline]="false"
                ></app-select-search>
              </div>
              <div class="col-md-12">
                <mat-form-field2 class="mat-block">
                  <input type="text" formControlName="zip" matInput placeholder="Postal Code" />
                  <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('minlength')">
                    Value must be at least <strong>2</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('maxlength')">
                    Value must be at most <strong>50</strong> characters long
                  </mat-error>
                  <mat-error *ngIf="form.get('zip').touched && form.get('zip').hasError('allSpaces')">
                    Postal code cannot be spaces alone.
                  </mat-error>
                </mat-form-field2>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="alert alert-secondary-info mt20">
        <i class="fal fa-info-circle alert-icon"></i>
        <div class="alert-content">
          Want to change your AdvanceCM domain? Please go to your
          <a (click)="goToAccountSettings()" class="btn-light-info"><b> Account Settings</b></a
          ><br />
          If you've already connected your Airbnb account you can import your rentals into AdvanceCM. Check out your
          channel
          <a (click)="goToConnections()" class="btn-light-info"><b> Connection Details</b></a
          >.
        </div>
      </div>
    </div>
  </div>
</div>
