import { Injectable } from '@angular/core'
import { MatDialogConfig } from '@angular/material/dialog'
import { Channel, DialogService } from '@tokeet-frontend/tv3-platform'
import { BookingFormulasOverlayComponent } from './booking-formulas-overlay.component'

@Injectable()
export class BookingFormulasOverlayService {
  constructor(private dialog: DialogService) {}

  public open(channel: Channel) {
    const defaultConfig: MatDialogConfig = {
      data: { channel },
      panelClass: ['drawer-full-80'],
    }

    const dialogRef = this.dialog.openDrawer(BookingFormulasOverlayComponent, defaultConfig)

    return dialogRef
  }
}
