import { Component, Input } from '@angular/core'
import { Guest } from '@tv3/store/guest/guest.model'

@Component({
  selector: 'app-guest-invoice-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent {
  @Input() guest: Guest
}
