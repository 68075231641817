import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AirbnbChannelService, airbnbBookingDeclineReasons } from '@tokeet-frontend/channels'
import { Rental, SaveForm, Toaster } from '@tokeet-frontend/tv3-platform'
import { InquiryIsUpdated, InquiryUpdateComplete } from '@tv3/store/inquiry/inquiry-fields.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-decline-airbnb-booking-dialog',
  templateUrl: './decline-airbnb-booking-dialog.component.html',
  styleUrls: ['./decline-airbnb-booking-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class DeclineAirbnbBookingDialogComponent implements OnInit {
  form = this.fb.group({
    decline_reason: [undefined, [Validators.required]],
    decline_message_to_guest: [undefined, [Validators.required]],
    decline_message_to_airbnb: [undefined, []],
  })
  reasons = airbnbBookingDeclineReasons
  isLoading = false
  constructor(
    public dialogRef: MatDialogRef<DeclineAirbnbBookingDialogComponent>,
    private fb: FormBuilder,
    private store: Store<any>,
    private toaster: Toaster,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental; inquiry: Inquiry },
    private airbnbChannelService: AirbnbChannelService
  ) {}

  ngOnInit(): void {}

  @SaveForm()
  onDecline(form: FormGroup) {
    const payload = this.form.getRawValue()
    this.isLoading = true
    this.airbnbChannelService
      .declineBooking(this.data.inquiry.inquiryId, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Booking declined successfully')
          this.store.dispatch(
            InquiryUpdateComplete({ update: { id: this.data.inquiry.id, changes: { status: 'deny' } } })
          )
          this.store.dispatch(InquiryIsUpdated({ update: { id: this.data.inquiry.id, changes: { status: 'deny' } } }))
          this.dialogRef.close()
        },
        (error) => {
          this.toaster.error('Unable to decline booking', '', error)
        }
      )
  }
}
