<div class="container-box p-3">
  <!-- image gallery -->
  <div class="d-flex align-items-center gap-3">
    <h6 class="m-0">
      <span class="text-uppercase">Rental Gallery</span> -
      <small>{{ rental?.images?.length }}/{{ maxImagesCount }} Images</small>
    </h6>
    <div class="flex-fill"></div>
    <button
      class="btn btn-secondary-danger"
      matTooltip="Delete all selected images"
      (click)="deleteSelectedImages()"
      *ngIf="selection?.selected.length"
    >
      <i class="cs-icon cs-icon-trash"></i> Delete ({{ selection?.selected.length }})
    </button>

    <ul class="internal-module-switcher ml-3">
      <li class="module-item text-lg" [class.active]="viewType == 'list'" matTooltip="List View">
        <a (click)="viewType = 'list'"><i class="cs-icon cs-icon-list-check"></i></a>
      </li>
      <li class="module-item text-lg" [class.active]="viewType == 'grid'" matTooltip="Grid View">
        <a (click)="viewType = 'grid'"><i class="cs-icon cs-icon-grid"></i></a>
      </li>
    </ul>
  </div>

  <div class="mt-3">
    <ng-container *ngIf="viewType === 'grid'">
      <ng-template [ngTemplateOutlet]="cardView"></ng-template>
    </ng-container>
    <ng-container *ngIf="viewType === 'list'">
      <ng-template [ngTemplateOutlet]="listView"></ng-template>
    </ng-container>
  </div>
</div>

<ng-template #cardView>
  <div class="gallery">
    <div ngxDroppable [model]="images" (drop)="onDrop()" class="row">
      <div *ngFor="let image of images; let i = index" class="col-sm-3" ngxDraggable [model]="image">
        <div [ngClass]="{ 'primary-item-container': !!image?.primary }">
          <div
            class="gallery-img gallery-img-container"
            [class.selected]="selection.isSelected(image)"
            [class.highlight]="isHighlighted(image.id)"
            [attr.id]="image.id"
          >
            <a (click)="onOpenImage(i)" class="image-wrapper">
              <img [src]="getImageThumbUrl(image?.secureUrl)" />
            </a>
            <div
              *ngIf="!(1 | isReadonlyRole)"
              class="image-actions-wrapper d-flex justify-content-between align-items-start"
            >
              <div class="left-actions">
                <mat-checkbox
                  class="primary-btn"
                  *ngIf="!image?.primary"
                  (change)="makeImagePrimary(image)"
                  [disableRipple]="true"
                  matTooltip="This image will be shown as a rental image on all views if you mark it as primary."
                >
                  Mark as Primary
                </mat-checkbox>
                <span class="primary-btn" [class.active]="image?.primary" *ngIf="image?.primary">
                  <i class="fal fa-check-square"></i> Primary Image
                </span>
                <span
                  class="remove-image-btn text-danger px-3"
                  (click)="deleteImage(image)"
                  matTooltip="Delete this image"
                  ><i class="cs-icon cs-icon-trash"></i
                ></span>
              </div>
              <div class="right-actions">
                <mat-checkbox
                  class="select-btn"
                  labelPosition="before"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(image) : null"
                  [checked]="selection.isSelected(image)"
                  [disableRipple]="true"
                >
                  {{ selection.isSelected(image) ? 'Selected' : 'Select' }}
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <mat-form-field2 class="mat-block">
              <mat-label>Category</mat-label>
              <mat-select
                placeholder="Select Category"
                [disabled]="1 | isReadonlyRole"
                [(ngModel)]="image?.metadata.category"
                (selectionChange)="changeCategory(image, $event.value)"
              >
                <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</mat-option>
              </mat-select>
            </mat-form-field2>
          </div>
          <div class="category-desc">
            <mat-form-field2 class="mat-block">
              <mat-label>Description</mat-label>
              <textarea
                maxlength="280"
                matInput
                [disabled]="1 | isReadonlyRole"
                [(ngModel)]="image?.metadata.description"
                placeholder="Enter a description of this photo"
                rows="4"
                (change)="changeDescription(image, $event)"
                cdkTextareaAutosize
              ></textarea>
            </mat-form-field2>
          </div>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="rental?.images?.length === 0">
        <p>
          You have not uploaded any image(s) for your rental, please click UPLOAD button to add the image(s) for your
          rental.
        </p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #listView>
  <tokeet-frontend-image-table
    [images]="images"
    (primary)="makeImagePrimary($event)"
    (delete)="deleteImage($event)"
    (selectChange)="onSelectChange($event)"
  >
    <div class="text-muted m-3">
      You have not uploaded any image(s) for your rental, please click UPLOAD button to add the image(s) for your
      rental.
    </div>
  </tokeet-frontend-image-table>
</ng-template>
