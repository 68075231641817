import {
  ActivePlanStatus,
  allPlansFeatures,
  isSubscriptionValid,
  PlanIntervalTypes,
  PlanOption,
  PlanSubscription,
  PlanTypes,
  ProductsForPlan,
  unlimitedRentalsThreshold,
  unlimitedUsersThreshold,
} from '@tv3/store/plan/plan.model'
import { environment } from '../../../environments/environment'
import * as moment from 'moment'
import * as R from 'ramda'

export class ActivePlanStatusView extends ActivePlanStatus {
  isTrial: boolean
  isLegacy: boolean
  isActive: boolean

  constructor(statusData: ActivePlanStatus) {
    super(statusData)
    this.isTrial = statusData.status === 'trial'
    this.isLegacy = (statusData.planName + '').indexOf('legacy') > -1
    this.isActive = statusData.status !== 'inactive'
  }
}

export class PlanSubscriptionView extends PlanSubscription {
  isTrial: boolean
  isLegacy: boolean
  isExpired: boolean
  leftTrialDays = 0
  type: PlanTypes
  version: string
  isYearly: boolean
  isPaid: boolean
  isFree: boolean
  isValid: boolean
  isActive: boolean
  isDelinquent: boolean

  constructor(sub: PlanSubscription) {
    super(sub)
    this.isTrial = sub.status === 'trialing'
    this.isActive = sub.status === 'active'
    this.isValid = isSubscriptionValid(sub)

    this.isLegacy = sub.planName.indexOf('legacy') > -1
    this.isDelinquent = sub.status === 'past_due'
    if (this.isTrial) {
      const days = moment(sub.trialEnd * 1000).diff(moment(), 'days', true)
      this.leftTrialDays = Math.ceil(days < 0 ? 0 : days)
    }
    // @ts-ignore
    this.type = R.head(this.planId.split('_'))
    this.isYearly = /yr/gi.test(this.planId)
    this.isPaid = !/free/gi.test(this.planId)
    this.isFree = /free/gi.test(this.planId)
    this.version = R.last(this.planId.split('_'))
    this.isExpired = moment.utc().unix() > this.until
  }
}

export class PlanOptionView extends PlanOption {
  type: PlanTypes
  isMonthly: boolean
  isYearly: boolean
  monthlyPrice: number
  yearlyPrice: number
  oneRentalPrice: number
  version: string
  features: string[]

  constructor(plan: PlanOption) {
    super(plan)

    this.isYearly = this.interval === PlanIntervalTypes.Yearly
    this.isMonthly = this.interval === PlanIntervalTypes.Monthly
    // @ts-ignore
    this.type = R.head(this.id.split('_'))
    this.version = R.last(this.id.split('_'))

    // plan features
    if (this.product === ProductsForPlan.AdvanceCM) {
      const userFeature = `${this.users > unlimitedUsersThreshold ? 'Unlimited' : 'Up to ' + this.users} ${
        this.users > 1 ? 'Users' : 'User'
      }`
      const rentalFeature = `${this.rentals > unlimitedRentalsThreshold ? 'Unlimited' : 'Up to ' + this.rentals} ${
        this.rentals > 1 ? 'Rentals' : 'Rental'
      }`

      this.features = [...(R.has(this.type, allPlansFeatures) ? allPlansFeatures[this.type] : [])]

      if (this.id !== environment.plans.tokeetStandalone) {
        this.features.push(userFeature, rentalFeature)
      }
    } else {
      this.features = []
    }

    // plan price
    const monthlyPrice = this.isYearly ? this.amount / 12 : this.amount
    this.yearlyPrice = this.isYearly ? this.amount : this.amount * 12
    this.monthlyPrice = parseFloat((Math.round(monthlyPrice * 100) / 100).toFixed(2))
    this.oneRentalPrice = parseFloat((Math.round((this.monthlyPrice / this.rentals) * 100) / 100).toFixed(2))
  }
}
