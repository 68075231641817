import { createAction, props } from '@ngrx/store'
import { AddCreditCardRequest, Card } from '@tokeet-frontend/billing'

export const LoadCards = createAction('[Billing] Load Cards')
export const LoadCardsComplete = createAction('[Billing] Load Cards Complete', props<{ cards: Card[] }>())

export const AddCreditCard = createAction('[Billing] Add Credit Card', props<{ request: AddCreditCardRequest }>())
export const AddCreditCardComplete = createAction('[Billing] Add Credit Card Complete', props<{ card: Card }>())

export const ReplaceCreditCard = createAction(
  '[Billing] Replace Credit Card',
  props<{ request: AddCreditCardRequest }>()
)
export const ReplaceCreditCardComplete = createAction('[Billing] Replace Credit Card Complete', props<{ card: Card }>())

export const DeleteCreditCard = createAction('[Billing] Delete Credit Card', props<{ id: string }>())
export const DeleteCreditCardComplete = createAction('[Billing] Delete Credit Card Complete', props<{ id: string }>())
