<div class="d-grid gap-3" style="grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))">
  <ng-template
    *ngFor="let item of items"
    [ngTemplateOutlet]="card"
    [ngTemplateOutletContext]="{ item: item }"
  ></ng-template>
</div>

<ng-template #card let-item="item">
  <div class="grid-card" (click)="onEdit(item)">
    <div class="d-flex justify-content-between">
      <i class="far fa-box-up text-danger" style="font-size: 36px"></i>

      <div class="d-flex align-items-center gap-3" (click)="$event.stopPropagation()">
        <button class="btn btn-sm btn-secondary-danger" (click)="onDelete(item)">
          <i class="cs-icon cs-icon-trash"></i>
        </button>
      </div>
    </div>
    <div class="text-semi-bold mt-3">{{ channelsById[item.channel_id]?.friendlyName }}</div>
    <div class="text-light text-sm mt-3">
      Adjustment:
      <ng-container *ngIf="item.adjust_amt"> {{ item.adjust_amt }} </ng-container>
      <ng-container *ngIf="item.adjust_pct"> {{ item.adjust_pct }}% </ng-container>
    </div>
  </div>
</ng-template>
