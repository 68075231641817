import { Connection } from './connection.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

export interface State extends EntityState<Connection> {
  isLoaded: boolean
  isUpdating: boolean
  isChannelRefreshing: boolean
  isAirbnbListingStatusLoaded: boolean
}

export const adapter: EntityAdapter<Connection> = createEntityAdapter<Connection>({
  sortComparer: false,
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false,
  isUpdating: false,
  isChannelRefreshing: false,
  isAirbnbListingStatusLoaded: false,
})
