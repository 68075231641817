import { AbstractControl } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { filter, map, switchMap, take } from 'rxjs/operators'
import * as R from 'ramda'
import { isSomething } from '../functions'
import { selectOnce } from '../rx-operators'
import { selectAllRentals, selectedRentalId, Rental } from '../stores'

// @dynamic
export class RentalNameNotTakenValidator {
  static createValidator(store: Store<any>) {
    return (control: AbstractControl) => {
      return store.pipe(
        select(selectAllRentals),
        filter((rentals) => isSomething(rentals)),
        take(1),
        switchMap((rentals) =>
          store.pipe(
            selectOnce(selectedRentalId),
            map((id) => ({ id, rentals }))
          )
        ),
        map(({ id, rentals }) => {
          if (id) {
            const withoutRental = R.filter((r: Rental) => r.id !== id, rentals)
            return isRentalNameTaken(control.value, withoutRental)
          }
          return isRentalNameTaken(control.value, rentals)
        })
      )
    }
  }
}

export function isRentalNameTaken(name: string, rentals: Rental[]) {
  return R.any((r: Rental) => r.name === name)(rentals) ? { nameTaken: true } : null
}
