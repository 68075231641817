import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { CustomChannelService } from './service'
import {
  CreateCustomChannel,
  CreateCustomChannelComplete,
  DeleteCustomChannel,
  DeleteCustomChannelComplete,
  GetCustomChannels,
  GetCustomChannelsComplete,
  UpdateCustomChannel,
  UpdateCustomChannelComplete,
} from './actions'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class CustomChannelEffects {
  @Effect()
  getAll$ = this.actions$.pipe(
    ofType(GetCustomChannels),
    switchMap(() =>
      this.channelsService.all().pipe(
        map((items) => GetCustomChannelsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreateCustomChannel),
    concatMap(({ payload }) =>
      this.channelsService.create(payload).pipe(
        tap(() => this.toaster.success('Custom Channel created successfully.')),
        map((response) => CreateCustomChannelComplete({ item: response })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(UpdateCustomChannel),
    concatMap(({ id, payload }) =>
      this.channelsService.update(id, payload).pipe(
        tap(() => this.toaster.success('Custom Channel updated successfully.')),
        map((response) => UpdateCustomChannelComplete({ change: { id, changes: response } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DeleteCustomChannel),
    switchMap(({ id }) =>
      this.channelsService.delete(id).pipe(
        tap(() => this.toaster.success('Custom Channel deleted successfully')),
        map(() => DeleteCustomChannelComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )
  constructor(private actions$: Actions, private channelsService: CustomChannelService, private toaster: Toaster) {}
}
