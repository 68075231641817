import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { DataFeedService } from './datafeed.service'
import {
  CreateFeed,
  CreateFeedComplete,
  DeleteFeed,
  DeleteFeedComplete,
  DeleteFeeds,
  DeleteFeedsComplete,
  LoadFeeds,
  LoadFeedsComplete,
  PauseFeed,
  PauseFeedComplete,
  ResumeFeed,
  ResumeFeedComplete,
} from '@tv3/store/datafeed/datafeed.actions'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class DataFeedEffects {
  @Effect()
  loadDataFeeds$ = this.actions$.pipe(
    ofType(LoadFeeds),
    switchMap(() =>
      this.dataFeedService.all().pipe(
        map((feeds) => LoadFeedsComplete({ feeds })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createFeed$ = this.actions$.pipe(
    ofType(CreateFeed),
    concatMap(({ payload }) =>
      this.dataFeedService.create(payload.name, payload.type).pipe(
        map((feed) => CreateFeedComplete({ feed })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pauseFeed$ = this.actions$.pipe(
    ofType(PauseFeed),
    concatMap(({ id }) =>
      this.dataFeedService.pause(id).pipe(
        map(() => PauseFeedComplete({ update: { id, changes: { status: 0 } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  resumeFeed$ = this.actions$.pipe(
    ofType(ResumeFeed),
    concatMap(({ id }) =>
      this.dataFeedService.resume(id).pipe(
        map(() => ResumeFeedComplete({ update: { id, changes: { status: 1 } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeFeed$ = this.actions$.pipe(
    ofType(DeleteFeed),
    concatMap(({ id }) =>
      this.dataFeedService.remove(id).pipe(
        map(() => DeleteFeedComplete({ id })),
        tap(() => this.toast.success('Data Feed deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  removeFeeds$ = this.actions$.pipe(
    ofType(DeleteFeeds),
    concatMap(({ ids }) =>
      this.dataFeedService.removeMultiple(ids).pipe(
        map(() => DeleteFeedsComplete({ ids })),
        tap(() => this.toast.success('Data Feeds deleted successfully!')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private actions$: Actions, private dataFeedService: DataFeedService, private toast: Toaster) {}
}
