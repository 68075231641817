import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { EntityTagsEffects } from './effects'
import { EntityTagsService } from './service'
import { tagsReducer } from './reducer'
import { EntityTagsGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('entityTags', tagsReducer),
    EffectsModule.forFeature([EntityTagsEffects]),
  ],
  declarations: [],
  providers: [EntityTagsService, EntityTagsGuard],
  exports: [],
})
export class EntityTagsStoreModule {}
