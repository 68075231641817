import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { ChannelAdjustment, CreateChannelAdjustmentPayload, UpdateChannelAdjustmentPayload } from './model'

@Injectable()
export class ChannelAdjustmentService {
  constructor(private http: HttpClient) {}

  all() {
    const url = '@api/channeladjustment/all'

    return this.http.get<ChannelAdjustment[]>(url)
  }

  get(channelAdjustmentId: string) {
    const url = `@api/channeladjustment/${channelAdjustmentId}`

    return this.http.get<ChannelAdjustment>(url)
  }

  addChannelAdjustment(channelAdjustment: CreateChannelAdjustmentPayload) {
    const url = '@api/channeladjustment'

    return this.http.post<ChannelAdjustment>(url, channelAdjustment)
  }

  updateChannelAdjustment(channelAdjustmentId: string, channelAdjustment: UpdateChannelAdjustmentPayload) {
    const url = `@api/channeladjustment/${channelAdjustmentId}`

    return this.http.put<ChannelAdjustment>(url, channelAdjustment)
  }

  deleteChannelAdjustment(channelAdjustmentId: string) {
    const url = `@api/channeladjustment/${channelAdjustmentId}`

    return this.http.delete(url)
  }
}
