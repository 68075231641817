import * as lodash from 'lodash'
import * as moment from 'moment'

export const airbnbCancellationPolicies = [
  {
    value: 'flexible',
    title: 'Flexible',
    description: 'Full refund 1 day prior to arrival.',
  },
  {
    value: 'moderate',
    title: 'Moderate',
    description: 'Full refund up until 5 days prior to arrival.',
  },
  {
    value: 'better_strict_with_grace_period',
    title: 'Firm',
    description:
      'Full refund up until 30 days prior to arrival. 50% refund of accommodation fee up to 1 week prior to arrival, then non-refundable.',
  },
  {
    value: 'strict_14_with_grace_period',
    title: 'Strict 14 with grace period',
    description:
      'Full refund within 48 hours of booking and at least 14 days of checkin. After, 50% refund up until 1 week prior to arrival',
  },
  {
    value: 'super_strict_30',
    title: 'Super Strict 30',
    description: '50% refund of accommodation fee up until 30 days prior to arrival, then non-refundable.',
  },
  {
    value: 'super_strict_60',
    title: 'Super Strict 60',
    description: '50% refund of accommodation fee up until 60 days prior to arrival, then non-refundable.',
  },
]

export const airbnbLongTermCancellationPolicies = [
  {
    value: 'CANCEL_LONG_TERM_FAIR',
    title: 'Firm',
    description:
      'Full refund up to 30 days before check-in. After that, the first 30 days of the stay are non-refundable.',
  },
  {
    value: 'CANCEL_LONG_TERM_WITH_GRACE_PERIOD',
    title: 'Strict',
    description:
      'Full refund if cancelled within 48 hours of booking and at least 28 days before check-in. After that, the first 30 days of the stay are non-refundable.',
  },
]

export const airbnbListingExpectationTypes = [
  { value: 'requires_stairs', label: 'Must climb stairs.' },
  { value: 'potential_noise', label: 'Potential for noise.' },
  { value: 'has_pets', label: 'Pet(s) live on property.' },
  { value: 'limited_parking', label: 'No parking on property.' },
  { value: 'shared_spaces', label: 'Some spaces are shared.' },
  { value: 'limited_amenities', label: 'Amenity limitations.' },
  { value: 'surveillance', label: 'Surveillance or recording devices on property.' },
  { value: 'weapons', label: 'Weapons on property.' },
  { value: 'animals', label: 'Dangerous animals on property.' },
  { value: 'pool_or_jacuzzi_with_no_fence', label: 'Pool/hot tub without a gate or lock.' },
  { value: 'lake_or_river_or_water_body', label: 'Nearby lake, river, other body of water.' },
  { value: 'climbing_or_play_structure', label: 'Climbing or play structure.' },
  { value: 'heights_with_no_fence', label: 'Heights without rails or protection.' },
]

export interface AirbnbBookingSettingsResponse {
  cancellation_policy_settings: {
    lts_cancellation_policy_id: string //  "CANCEL_LONG_TERM_FAIR",
    cancellation_policy_category: string //  "super_strict_60",
    non_refundable_price_factor: number //  (0, 1)
  }
  guest_controls: {
    allows_smoking_as_host?: boolean

    allows_pets_as_host?: boolean
    pet_capacity?: number

    allows_infants_as_host?: boolean

    allows_children_as_host?: boolean
    children_not_allowed_details?: string

    allows_events_as_host?: boolean
  }
  check_in_time_start: string //  "8", "9", "10", ..., "25"or "FLEXIBLE". "NOT_SELECTED"
  check_in_time_end: string //  "9", "10", "11", ..., "26"or "FLEXIBLE". "NOT_SELECTED"
  check_out_time: number //  0-23,
  instant_booking_allowed_category: string // "well_reviewed_guests",
  instant_book_welcome_message: string // "hello"
  listing_expectations_for_guests: { type: string; added_details: string }[]
  listing_id: string
}

export const turnOffInstantBookingWarning = `For Instant Booking related issues or questions please contact your Airbnb support representative.`

export const airbnbInstantBookingAllowedCategories = [
  // {
  //   value: 'off',
  //   label: 'Off',
  // },
  {
    value: 'everyone',
    label: 'Everyone',
  },
  {
    value: 'well_reviewed_guests',
    label: 'Well Reviewed Guests',
  },
  {
    value: 'guests_with_verified_identity',
    label: 'Guests With Verified Identity',
  },
  {
    value: 'well_reviewed_guests_with_verified_identity',
    label: 'Well Reviewed Guests With Verified Identity',
  },
]

export function airbnbFixCheckinTime(checkinTime: string): string {
  if (checkinTime === 'NOT_SELECTED') {
    return 'FLEXIBLE'
  }
  return checkinTime
}

export const airbnbCheckinStartTimeOptions = [
  { value: 'FLEXIBLE', label: 'Flexible' },
  ...lodash.range(8, 25 + 1).map((i) => ({
    value: i.toString(),
    label: moment().hour(i).minute(0).format('h:mm A') + (i > 24 ? '(next day)' : ''),
  })),
]
export const airbnbCheckinEndTimeOptions = [
  { value: 'FLEXIBLE', label: 'Flexible' },
  ...lodash.range(9, 26 + 1).map((i) => ({
    value: i.toString(),
    label: moment().hour(i).minute(0).format('h:mm A') + (i > 24 ? '(next day)' : ''),
  })),
]

export const airbnbCheckoutTimeOptions = [
  ...lodash.range(0, 23).map((i) => ({ value: i, label: moment().hour(i).minute(0).format('h:mm A') })),
]

export interface AirbnbUpdateBookingSettingsPayload
  extends Omit<AirbnbBookingSettingsResponse, 'listing_expectations_for_guests' | 'listing_id'> {
  channelId: string
  propertyId: string | number
  roomid: string | number
}
