import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: 'root',
})
export class RategenieService {
  constructor(private http: HttpClient, private store: Store<fromRoot.State>) {}

  checkAccountSync(): Observable<boolean> {
    const url = `@rategenieApi/account/skip`
    return this.http.get(url).pipe(
      map((res: any) => res && res.skipped === 0),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  checkTokeetSync(): Observable<boolean> {
    const url = `@rategenieApi/account/tokeet/sync`
    return this.http.get(url).pipe(
      map((res: any) => res && !!res.sync),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  checkRentalSync(rentalId: string): Observable<boolean> {
    const url = `@rategenieApi/rental/skip/${rentalId}`
    return this.http.get(url).pipe(
      map((res: any) => res && res.skipped === 0),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }

  checkChannelConnectionSync(rentalId: string, roomId: string): Observable<boolean> {
    const url = `@rategenieApi/rental/channel/skip/${rentalId}/${roomId}`
    return this.http.get(url).pipe(
      map((res: any) => res && res.skipped === 0),
      catchError((error) => {
        this.store.dispatch(ActionFailed({ error }))
        return EMPTY
      })
    )
  }
}
