import { Component } from '@angular/core'
import * as R from 'ramda'
import { MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { CsvFileColumnDef, CsvFileParserGuide } from '@tv3/interfaces/files/csv-file-parser.file'
import { generateMappingParser, parseString } from '@tv3/shared/csv-file-parser/csv-data-parse-helper'
import {
  AddInventories,
  CreateInventoryPayload,
  InventoryCategory,
  InventoryCondition,
  InventoryStatus,
} from '@tokeet-frontend/inventories'
import { startCase, toLower } from 'lodash'

@Component({
  selector: 'app-import-inventory-dialog',
  templateUrl: './import-inventory-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-inventory-dialog.component.scss'],
})
export class ImportInventoryDialogComponent {
  files: ParsedUploadFile<CreateInventoryPayload>[] = []

  columnDefs: CsvFileColumnDef[] = <CsvFileColumnDef[]>[
    { name: 'Title', field: 'title', required: true, parse: parseString },
    { name: 'Category', field: 'category', required: true, parse: parseString },
    { name: 'Condition', field: 'condition', required: true, parse: parseString },
    { name: 'Rental Id', field: 'rental_id', required: false, parse: parseString },
    {
      name: 'Status',
      field: 'status',
      parse: generateMappingParser(
        {
          active: 1,
          inactive: 0,
        },
        'active'
      ),
      default: 1,
    },
  ]

  guide: CsvFileParserGuide = {
    description: `The columns in your CSV file should be (${this.columnDefs
      .map((col) => (col.required ? `${col.name}*` : col.name))
      .join(', ')}). You can get the rental ID on the details view.`,
    list: ['Title, Category, and Condition are required.', 'Date format "YYYY-MM-DD", such as "2016-02-13".'],
    sample: [['Title', 'Category', 'Condition', 'Rental Id', 'Status'], ...this.generateSampleData()],
  }

  private generateSampleData(): string[][] {
    const samples = [
      {
        title: 'Office Chair',
        category: InventoryCategory.Furniture,
        condition: InventoryCondition.New,
        rentalId: 'a3f19b90-64cb-4506-b269-cb9c7d0c254e',
      },
      {
        title: 'Copy Paper - 500 Sheets',
        category: InventoryCategory.Supplies,
        condition: InventoryCondition.Good,
        rentalId: 'a3f19b90-64cb-4506-b269-cb9c7d0c254e',
      },
    ]

    const activeStatus = Object.keys(InventoryStatus).find(
      (key) => InventoryStatus[key as keyof typeof InventoryStatus] === InventoryStatus.Active
    )

    return samples.map((sample) => [
      sample.title,
      startCase(toLower(sample.category)),
      startCase(toLower(sample.condition)),
      sample.rentalId,
      activeStatus || '',
    ])
  }

  constructor(
    public dialogRef: MatDialogRef<ImportInventoryDialogComponent>,
    private store: Store<fromRoot.State>,
    protected toast: Toaster
  ) {}

  close() {
    this.dialogRef.close()
  }

  onCsvFilesParsed(files: ParsedUploadFile<any>[]) {
    this.files = files
  }

  create() {
    if (R.any((file) => file.isError, this.files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    //  get parsed csv data
    const items: any[] = R.filter(
      (i) => !R.isEmpty(i),
      R.map((i) => i.item, R.flatten<any>(R.map((file) => file.items, this.files)))
    )

    this.store.dispatch(AddInventories({ requests: items }))

    this.close()
  }
}
