import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as R from 'ramda'
import { State } from './rule.state'
import { selectAll, selectEntities } from './rule.reducer'
import { selectAllInvoiceTemplateViews } from '../templates/template.selectors'
import { InvoiceRule, InvoiceRuleGroup } from './rule.model'
import { InvoiceTemplateView } from '../templates/template.model'

export const invoiceRuleState = createFeatureSelector<State>('invoice-rule-lib')

export const selectAllInvoiceRules = createSelector(invoiceRuleState, selectAll)

export const selectInvoiceRulesEntities = createSelector(invoiceRuleState, selectEntities)

export const selectInvoiceRulesLoaded = createSelector(invoiceRuleState, (state) => state.isLoaded)

export const isInvoiceRulesUpdating = createSelector(invoiceRuleState, (state) => state.isUpdating)

export const selectInvoiceRule = createSelector(selectInvoiceRulesEntities, (entities, props) => {
  return entities[props.id]
})

export const selectInvoiceRuleConditions = createSelector(selectInvoiceRulesEntities, (entities, props) => {
  const rule = entities[props.id]
  if (rule) {
    return rule.conditions
  } else {
    return []
  }
})

export const selectInvoiceRuleGroups = createSelector(
  selectAllInvoiceRules,
  selectAllInvoiceTemplateViews,
  (items, templates): InvoiceRuleGroup[] => {
    return R.pipe(
      R.groupBy((a: InvoiceRule) => a.group_id),
      R.values,
      R.reduce((acc, automations) => {
        automations = R.sortBy((a) => a.lastused || 0, automations).reverse()
        const ids = R.map((a) => a.pkey, automations)

        const automation = R.head(automations)
        const templateView = R.find((t: InvoiceTemplateView) => t.pkey === automation.template, templates)

        const group = {
          ...automation,
          ids,
          rentals: R.uniq(R.map((a) => a.settings.rental, automations)),
          channels: R.uniq(R.map((a) => a.settings.channel, automations)),
          items: automations,
          templateView,
        } as InvoiceRuleGroup
        return R.append(group, acc)
      }, [] as InvoiceRuleGroup[])
    )(items)
  }
)

export const selectInvoiceTemplateRules = (templateId: string) =>
  createSelector(selectInvoiceRuleGroups, (rules) => {
    return R.filter((rule: InvoiceRuleGroup) => rule.template === templateId, rules)
  })

export const selectInvoiceRulesTags = createSelector(selectAllInvoiceRules, (rules) => {
  return R.pipe(
    R.map((r: InvoiceRule) => R.pathOr([], ['attributes', 'tags'], r)),
    R.flatten,
    R.reject(R.isNil),
    R.reject(R.isEmpty),
    R.uniq,
    R.sort((a, b) => a.toString().localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
  )(rules)
})

export const selectIsAdding = createSelector(invoiceRuleState, (s: State) => s.isAdding)
export const selectIsUpdating = createSelector(invoiceRuleState, (s: State) => s.isUpdating)
