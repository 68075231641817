import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { of } from 'rxjs'
import { concatMap, map, switchMap, tap, toArray } from 'rxjs/operators'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { TaskResponse, UpdateTaskPayload, CreateTaskPayload } from '@tv3/store/task/task.model'
import * as R from 'ramda'

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private http: HttpClient, private toast: Toaster) {}

  all() {
    const url = '@api/task/all/?sort=created'

    return this.http.get<TaskResponse[]>(url)
  }

  getTasks(ids: string[]) {
    const url = `@api/task/retrieve/`

    return this.http.post<TaskResponse[]>(url, { pkeys: ids })
  }

  add(payload: CreateTaskPayload) {
    const url = '@api/task/'
    return this.http.post<TaskResponse>(url, payload)
  }

  addTasks(payload: CreateTaskPayload[]) {
    return of(...payload).pipe(
      concatMap((payload) => this.add(payload)),
      toArray()
    )
  }

  delete(taskId: string) {
    const url = `@api/task/delete/${taskId}`

    return this.http.delete<TaskResponse>(url)
  }

  deleteMultiple(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.delete(id)),
      toArray()
    )
  }

  update(id: string, payload: UpdateTaskPayload) {
    const url = `@api/task/${id}`

    return this.http.put<TaskResponse>(url, payload).pipe(
      switchMap((response) => this.getTasks([response.pkey])),
      map((tasks) => R.head(tasks))
    )
  }

  archive(id: string) {
    const url = `@api/task/${id}`
    return this.http.put<TaskResponse>(url, { archived: 1 })
  }

  archiveTasks(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.archive(id)),
      toArray()
    )
  }

  unarchive(id: string) {
    const url = `@api/task/${id}`
    return this.http.put<TaskResponse>(url, { archived: 0 })
  }

  unarchiveTasks(ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.unarchive(id)),
      toArray()
    )
  }

  changeStatus(status: number, taskId: string) {
    const url = `@api/task/${taskId}`
    return this.http.put<TaskResponse>(url, { status })
  }

  changeStatuses(status: number, ids: string[]) {
    return of(...ids).pipe(
      concatMap((id) => this.changeStatus(status, id)),
      toArray()
    )
  }
}
