import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, Input } from '@angular/core'
import { Chart, ChartConfiguration } from 'chart.js'
import * as lodash from 'lodash'
import { Destroyable, getDataColorByIndex, ExpenseCategoryReportItem } from '@tokeet-frontend/tv3-platform'
import { DecimalPipe } from '@angular/common'
import { ExpenseReportFilters } from '../report-filters/expense-report-filters.component'

@Component({
  selector: 'app-expense-category-chart',
  templateUrl: './expense-category-chart.component.html',
  styleUrls: ['./expense-category-chart.component.scss'],
})
export class ExpenseCategoryChartComponent extends Destroyable implements OnInit, AfterViewInit {
  @ViewChild('ctx') ctx: ElementRef<HTMLCanvasElement>

  @Input() set data(d: Record<string, ExpenseCategoryReportItem[]>) {
    this.drawChart(d)
  }
  @Input() filters: ExpenseReportFilters

  private chart: Chart

  constructor(private elRef: ElementRef) {
    super()
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.chart = new Chart(this.ctx.nativeElement, getChartOptions() as any)
  }

  drawChart(data: Record<string, ExpenseCategoryReportItem[]>) {
    if (!this.chart || !this.filters) return

    const categories = Object.keys(data)

    this.chart.data.labels = categories
    this.chart.data.datasets = [
      {
        label: 'Expenses',
        data: categories.map((c) => lodash.sumBy(data[c], (t) => t.amount)),
        backgroundColor: categories.map((c, i) => getDataColorByIndex(i)),
      },
    ]
    this.chart.update()
    setChartLegends(this.chart, this.elRef.nativeElement)
  }
}

function getChartOptions(): ChartConfiguration {
  const numberPipe = new DecimalPipe('en-US')
  return {
    type: 'doughnut',
    data: {
      labels: [],
      datasets: [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      legendCallback,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return `${data.labels[tooltipItem.index]}: ${numberPipe.transform(
              data.datasets[0].data[tooltipItem.index] as number,
              '1.0-2'
            )}`
          },
        },
      },
    },
  }
}

function legendCallback(chart) {
  const dataset = chart.data.datasets[0]
  const labels = chart.data.labels
  const numberPipe = new DecimalPipe('en-US')

  const renderLabels = () => {
    return dataset.data
      .map(
        (_, i) =>
          `<li class="text-nowrap legend-item d-flex align-items-center gap-1" id="legend-${i}-item">
            <i class="fas fa-circle" style="color: ${dataset.backgroundColor[i]}"></i>
            <span class="text-light legend-label">${labels[i]}: </span>
            <div class="flex-fill"></div>
            <span>${numberPipe.transform(dataset.data[i], '1.0-2')}</span>
          </li>
        `
      )
      .join('')
  }
  return `
    <ul class="d-flex flex-column flex-wrap gap-3 list-unstyled mx-2 text-sm">
      ${renderLabels()}
    </ul>`
}

const setChartLegends = (myChart: Chart, hostElement: HTMLElement) => {
  const containerElement = hostElement.querySelector('#legend-container')
  containerElement.innerHTML = myChart.generateLegend() as string

  const legendItemSelector = '.legend-item'
  const labelSelector = '.legend-label'

  const legendItems = containerElement.querySelectorAll(legendItemSelector)
  legendItems.forEach((item, i) => {
    item.addEventListener('click', (e: MouseEvent) => updateDataset(e.currentTarget as HTMLElement, i))
  })

  const updateDataset = (currentEl: HTMLElement, index: number) => {
    const meta = myChart.getDatasetMeta(0)
    const labelEl: HTMLElement = currentEl.querySelector(labelSelector)
    meta.data[index].hidden = !meta.data[index].hidden
    labelEl.style.textDecoration = meta.data[index].hidden ? 'line-through' : 'none'
    myChart.update()
  }
}
