import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { ensureLinkTag } from '@tokeet-frontend/tv3-platform'
import { fixDropdownClose } from '@tokeet-frontend/tv3-platform/components/dropdown'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { IconWebfontHash } from './icon-types'

if (environment.production) {
  enableProdMode()
}

ensureLinkTag(IconWebfontHash, `/assets/icons/${IconWebfontHash}/icons.css`)

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.environment === 'production') {
      navigator.serviceWorker.register('/ngsw-worker.js').catch((err) => {
        console.log('ServiceWorker failed', err)
      })
    }
  })
  .catch((err) => console.log(err))

fixDropdownClose()
