import { AbstractControl } from '@angular/forms'
import * as R from 'ramda'

export const percent = (includeLower = false, includeUpper = false) => {
  return (control: AbstractControl) => {
    const value = control.value
    if (R.isNil(value) || value === '') {
      return
    }

    if (value < 0 || value > 100 || (!includeLower && value === 0) || (!includeUpper && value === 100)) {
      return { percent: true }
    }
  }
}
