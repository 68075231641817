export interface DeactivationReasonItem {
  description: string
  value: string
}

export interface DeactivationReasonsParentItem extends DeactivationReasonItem {
  choices: DeactivationReasonItem[]
}

export interface AirbnbDeactivationReasonFlowResponse {
  description: string
  questions: {
    description: string
    key: string
    is_required: boolean
    type: string
    choices: DeactivationReasonsParentItem[]
  }[]
}

export const detailRequiredReasonIds = ['OtherReasons', 'OtherReason']
