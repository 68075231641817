import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromDataFeed from './datafeed.reducer'
import { EffectsModule } from '@ngrx/effects'
import { DataFeedEffects } from './datafeed.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('datafeed', fromDataFeed.dataFeedReducer),
    EffectsModule.forFeature([DataFeedEffects]),
  ],
  declarations: [],
})
export class DataFeedModule {}
