import { Component, HostBinding, Input } from '@angular/core'

export type ButtonType = 'success' | 'info' | 'warning' | 'danger'

@Component({
  selector: 'app-button',
  template: `
    <span *ngIf="loading" class="far fa-spinner fa-spin"></span>
    <span *ngIf="!loading" [class]="icon"></span>
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        cursor: pointer;
      }
      :host.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    `,
  ],
})
export class ButtonComponent {
  @Input() icon: string
  @Input() loading = false

  @Input() disabled = false

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.disabled || this.loading
  }
}
