import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { NoteResponse } from '@tv3/store/note/note.model'
import * as R from 'ramda'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConfirmDialogService, SaveForm } from '@tokeet-frontend/tv3-platform'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { isSavingNote, selectInquiry } from '@tv3/store/inquiry/inquiry.selectors'
import { AddNote, AddNoteComplete, DeleteNote } from '@tv3/store/note/note.actions'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { EditNoteDialogService } from '@tv3/containers/inquiries/dialogs/notes/note/edit-note-dialog/edit-note-dialog.service'
import { NgxPermissionsService } from 'ngx-permissions'
import { HasPermission } from 'libs/permission/src/lib/decorators/has-permission.decorator'
import { InquiryService } from '@tv3/store/inquiry/inquiry.service'
import { SetInquiryNotesReadStatus } from '@tv3/store/calendar/calendar.actions'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-note-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent extends Destroyable implements OnInit {
  maxLength = 500

  form = this.fb.group({
    note: ['', [Validators.required]],
  })

  notes: NoteResponse[] = []

  isSavingNote$ = this.store.pipe(select(isSavingNote))

  inquiry: Inquiry

  canEditNotes = this.permissions.getPermission('canEditNotes')

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    private fb: FormBuilder,
    private actions: Actions,
    private inquiryService: InquiryService,
    private permissions: NgxPermissionsService,
    private editNoteDialog: EditNoteDialogService,
    private confirm: ConfirmDialogService,
    private store: Store<fromRoot.State>,
    @Inject(MAT_DIALOG_DATA) public data: { inquiry: Inquiry }
  ) {
    super()
  }

  ngOnInit() {
    this.inquiry = this.data.inquiry
    this.notes = R.defaultTo([], this.data.inquiry.notes)

    this.store.pipe(select(selectInquiry, { id: this.inquiry.id }), untilDestroy(this)).subscribe((inquiry) => {
      this.inquiry = inquiry
      this.notes = R.pathOr([], ['notes'], inquiry)
    })

    this.inquiryService.readInquiryNote(this.inquiry.id).subscribe(() => {
      this.store.dispatch(SetInquiryNotesReadStatus({ id: this.inquiry.id, status: 0 }))
    })
    this.actions.pipe(ofType(AddNoteComplete), untilDestroy(this)).subscribe((data) => {
      this.store.dispatch(SetInquiryNotesReadStatus({ id: this.inquiry.id, status: 1 }))
    })
  }

  @HasPermission('canEditNotes')
  onEdit(note: NoteResponse) {
    this.editNoteDialog.open({ inquiry: this.inquiry, note })
  }

  @HasPermission('canEditNotes')
  onRemove(note: NoteResponse) {
    this.confirm.confirm().subscribe(() => {
      this.store.dispatch(DeleteNote({ inquiry: this.inquiry, note }))
    })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    this.store.dispatch(AddNote({ inquiry: this.inquiry, form: form.getRawValue() }))
    this.form.reset()
  }
}
