import { LoadAuthorizedIntegrationComplete, UnauthorizeIntegrationComplete } from './integration.actions'
import { adapter, initialState, State } from './integration.state'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadAuthorizedIntegrationComplete, (state, { data, integration }) => ({
    ...state,
    authorizedIntegrations: {
      ...state.authorizedIntegrations,
      [integration]: data,
    },
    isAuthorizedIntegrationLoaded: {
      ...state.isAuthorizedIntegrationLoaded,
      [integration]: true,
    },
  })),
  on(UnauthorizeIntegrationComplete, (state, { integration }) => ({
    ...state,
    authorizedIntegrations: {
      ...state.authorizedIntegrations,
      [integration]: null,
    },
  }))
)

export function integrationReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
