import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import * as R from 'ramda'
import { TagResponse, TagsType } from './tag.interfaces'
import { State } from './tag.state'

@Injectable()
export class TagService {
  constructor(private http: HttpClient) {}

  all(): Observable<{
    id: string
    tags: {
      inquiry: string[]
      invoice: string[]
      guest: string[]
      rental: string[]
      website: string[]
    }
  }> {
    const url = '@api/tag/all/'

    return this.http.get<TagResponse[]>(url).pipe(map((response) => this.mapTags(response)))
  }

  addTags(type: TagsType, tagState: State, tags: string[]) {
    if (tagState.id) {
      const url = `@api/tag/${tagState.id}`

      return this.http
        .put<TagResponse>(url, { ...tagState, [type]: R.concat(tagState[type], tags) })
        .pipe(map((response) => this.mapTags([response])))
    } else {
      const url = `@api/tag/`

      return this.http
        .post<TagResponse>(url, { ...tagState, [type]: R.concat(tagState[type], tags) })
        .pipe(map((response) => this.mapTags([response])))
    }
  }

  private mapTags(response: TagResponse[]) {
    const id: string = R.path(['pkey'], R.head(response))
    return {
      id: id,
      tags: R.reduce(
        (acc, value) => {
          return {
            inquiry: R.concat(acc.inquiry, R.defaultTo([], value.inquiry)),
            invoice: R.concat(acc.invoice, R.defaultTo([], value.invoice)),
            guest: R.concat(acc.guest, R.defaultTo([], value.guest)),
            rental: R.concat(acc.rental, R.defaultTo([], value.rental)),
            website: R.concat(acc.website, R.defaultTo([], value.website)),
          }
        },
        {
          inquiry: [],
          invoice: [],
          guest: [],
          rental: [],
          website: [],
        },
        response
      ),
    }
  }
}
