import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StaffPermissionsComponent } from './staff-permissions/staff-permissions.component'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatTooltipModule } from '@angular/material/tooltip'
import { StaffPermissionsPipe } from './staff-permissions/staff-permissions.pipe'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule, MatTooltipModule, Tv3PlatformModule],
  providers: [StaffPermissionsPipe],
  declarations: [StaffPermissionsComponent, StaffPermissionsPipe],
  exports: [StaffPermissionsComponent, StaffPermissionsPipe],
})
export class UsersLibModule {}
