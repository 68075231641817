import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ConfirmDialogParams, ConfirmDialogStatus } from '../confirm-dialog.interfaces'

@Component({
  selector: 'app-confirm-extra-dialog',
  templateUrl: './confirm-extra-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./confirm-extra-dialog.component.scss'],
})
export class ConfirmExtraDialogComponent implements OnInit {
  isConfirmed = false
  isChecked = false

  constructor(
    public dialogRef: MatDialogRef<ConfirmExtraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogParams
  ) {}

  ngOnInit(): void {
    this.isChecked = !!this.data.defaultExtraValue
  }

  toggleChecked() {
    this.isChecked = !this.isChecked
  }

  confirm() {
    this.isConfirmed = true
    if (this.data.extraCheckedRequired && !this.isChecked) {
      return
    }
    this.dialogRef.close({ status: ConfirmDialogStatus.Confirmed, isChecked: this.isChecked })
  }

  cancel() {
    this.dialogRef.close({ status: ConfirmDialogStatus.Canceled, isChecked: this.isChecked })
  }

  close() {
    this.dialogRef.close({ status: ConfirmDialogStatus.Closed, isChecked: this.isChecked })
  }
}
