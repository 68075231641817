import {
  AddWebsiteComplete,
  AddWebsitePage,
  AddWebsitePageComplete,
  DeleteWebsiteComplete,
  DeleteWebsitePage,
  DeleteWebsitePageComplete,
  LoadWebsiteComplete,
  LoadWebsitesComplete,
  LoadWebsiteTemplatesComplete,
  PublishWebsite,
  PublishWebsiteComplete,
  ToggleWebsiteOnlineBookings,
  ToggleWebsiteOnlineBookingsComplete,
  UpdateWebsite,
  UpdateWebsiteComplete,
  UpdateWebsiteComponents,
  UpdateWebsiteComponentsComplete,
  UpdateWebsiteContact,
  UpdateWebsiteContactComplete,
  UpdateWebsiteLinks,
  UpdateWebsiteLinksComplete,
  UpdateWebsitePage,
  UpdateWebsitePageComplete,
  UpdateWebsitePageStatus,
  UpdateWebsitePageStatusComplete,
  UpdateWebsiteRentals,
  UpdateWebsiteRentalsComplete,
  UpdateWebsiteResources,
  UpdateWebsiteResourcesComplete,
  UpdateWebsiteTemplateSettings,
  UpdateWebsiteTemplateSettingsComplete,
} from './website.actions'
import { adapter, initialState, State } from './website.state'
import { Action, createReducer, on } from '@ngrx/store'
import { updateOne, upsertOne } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(UpdateWebsiteComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(ToggleWebsiteOnlineBookingsComplete, (state, { update }) =>
    updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(AddWebsitePageComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsitePageComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsitePageStatusComplete, (state, { update }) =>
    updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(DeleteWebsitePageComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsiteResourcesComplete, (state, { update }) =>
    updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(UpdateWebsiteRentalsComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsiteComponentsComplete, (state, { update }) =>
    updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(UpdateWebsiteContactComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsiteLinksComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(UpdateWebsiteTemplateSettingsComplete, (state, { update }) =>
    updateOne(adapter, update, { ...state, isUpdating: false })
  ),
  on(PublishWebsiteComplete, (state, { update }) => updateOne(adapter, update, { ...state, isUpdating: false })),
  on(
    UpdateWebsite,
    ToggleWebsiteOnlineBookings,
    AddWebsitePage,
    UpdateWebsitePage,
    UpdateWebsitePageStatus,
    DeleteWebsitePage,
    UpdateWebsiteResources,
    UpdateWebsiteRentals,
    UpdateWebsiteComponents,
    UpdateWebsiteContact,
    UpdateWebsiteLinks,
    UpdateWebsiteTemplateSettings,
    PublishWebsite,
    (state) => ({ ...state, isUpdating: true })
  ),
  on(DeleteWebsiteComplete, (state, { id }) => adapter.removeOne(id, state)),
  on(AddWebsiteComplete, LoadWebsiteComplete, (state, { website }) => upsertOne(adapter, website, state)),
  on(LoadWebsitesComplete, (state, { websites }) => adapter.addAll(websites, { ...state, isLoaded: true })),
  on(LoadWebsiteTemplatesComplete, (state, { templates }) => ({ ...state, templates, isTemplatesLoaded: true }))
)

export function websiteReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
