import { AbstractControl, FormControl, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { map, take } from 'rxjs/operators'
import { selectSubdomain } from '../stores'

// @dynamic
export class RentalEmailValidator {
  static createValidator(store: Store<any>) {
    return (control: AbstractControl) => {
      return store.pipe(
        select(selectSubdomain),
        take(1),
        map((subDomain) => {
          const email = `${control.value}@${subDomain}`
          const wholeEmail = new FormControl()
          wholeEmail.patchValue(email)
          const isValid = Validators.email(wholeEmail) === null
          return isValid ? null : { rentalEmail: true }
        })
      )
    }
  }
}
