import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AirbnbSetVisibilityDialogComponent } from './airbnb-set-visibility-dialog.component'
import { Connection } from '@tv3/store/connection/connection.model'

@Injectable({
  providedIn: 'root',
})
export class AirbnbSetVisibilityDialogService {
  constructor(private dialog: MatDialog) {}

  open(connection: Connection) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { connection },
    }

    return this.dialog.open(AirbnbSetVisibilityDialogComponent, defaultConfig)
  }
}
