import { Injectable } from '@angular/core'
import { Channel, ChannelNameTokens, DialogService } from '@tokeet-frontend/tv3-platform'
import { BDCListingWizardComponent } from './bdc-listing-wizard/bdc-listing-wizard.component'
import { Connection } from '@tv3/store/connection/connection.model'
import { ComponentType } from '@angular/cdk/portal'
import { AirbnbListingWizardComponent } from './airbnb-listing-wizard/airbnb-listing-wizard.component'
import { AgodaListingWizardComponent } from './agoda-listing-wizard/agoda-listing-wizard.component'
import { TripListingWizardComponent } from './trip-listing-wizard/trip-listing-wizard.component'
import { ExpediaListingWizardComponent } from './expedia-listing-wizard/expedia-listing-wizard.component'
import { TiketListingWizardComponent } from './tiket-listing-wizard/tiket-listing-wizard.component'

export function hasListingWizard(channelName: ChannelNameTokens) {
  return [
    ChannelNameTokens.BookingAPI,
    ChannelNameTokens.AirBnBV2API,
    ChannelNameTokens.ExpediaAPI,
    ChannelNameTokens.AgodaAPI,
    ChannelNameTokens.Tiket,
    ChannelNameTokens.Trip,
  ].includes(channelName)
}

@Injectable()
export class ChannelListingWizardsService {
  constructor(private dialogService: DialogService) {}

  openWizard(channel: Channel, connection: Connection) {
    switch (channel.name) {
      case ChannelNameTokens.BookingAPI:
        return this.openDialog(BDCListingWizardComponent, channel, connection)
      case ChannelNameTokens.AirBnBV2API:
        return this.openDialog(AirbnbListingWizardComponent, channel, connection)
      case ChannelNameTokens.AgodaAPI:
        return this.openDialog(AgodaListingWizardComponent, channel, connection)
      case ChannelNameTokens.ExpediaAPI:
        return this.openDialog(ExpediaListingWizardComponent, channel, connection)
      case ChannelNameTokens.Tiket:
        return this.openDialog(TiketListingWizardComponent, channel, connection)
      case ChannelNameTokens.Trip:
        return this.openDialog(TripListingWizardComponent, channel, connection)
    }
  }

  private openDialog<T>(component: ComponentType<T>, channel: Channel, connection: Connection) {
    return this.dialogService
      .openVariableDialog(component, {
        width: '75%',
        height: 'auto',
        disableClose: true,
        data: { channel, connection },
      })
      .afterClosed()
  }
}
