import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ChannelAdjustmentService } from './service'
import { ActionFailed } from '../utility'
import {
  addChannelAdjustment,
  addChannelAdjustmentComplete,
  deleteChannelAdjustment,
  deleteChannelAdjustmentComplete,
  loadChannelAdjustments,
  loadChannelAdjustmentsComplete,
  updateChannelAdjustment,
  updateChannelAdjustmentComplete,
} from './actions'
import { Toaster } from '../../services'

@Injectable()
export class ChannelAdjustmentEffects {
  @Effect()
  all$ = this.actions$.pipe(
    ofType(loadChannelAdjustments),
    exhaustMap(() =>
      this.adjustmentService.all().pipe(
        map((items) => loadChannelAdjustmentsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  add$ = this.actions$.pipe(
    ofType(addChannelAdjustment),
    concatMap(({ payload }) =>
      this.adjustmentService.addChannelAdjustment(payload).pipe(
        map((item) => addChannelAdjustmentComplete({ item })),
        tap(() => this.toaster.success('Channel rate automation added successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  update$ = this.actions$.pipe(
    ofType(updateChannelAdjustment),
    concatMap(({ id, payload }) =>
      this.adjustmentService.updateChannelAdjustment(id, payload).pipe(
        map((item) => updateChannelAdjustmentComplete({ item })),
        tap(() => this.toaster.success('Channel rate automation updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(deleteChannelAdjustment),
    concatMap(({ id }) =>
      this.adjustmentService.deleteChannelAdjustment(id).pipe(
        map(() => deleteChannelAdjustmentComplete({ id })),
        tap(() => this.toaster.success('Channel rate automation deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private adjustmentService: ChannelAdjustmentService
  ) {}
}
