import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'
import { ExpediaListingWizardComponent } from './expedia-listing-wizard.component'
import { ExpediaListingWizardStep1Component } from './step1/expedia-listing-wizard-step1.component'
import { ExpediaListingWizardStep2Component } from './step2/expedia-listing-wizard-step2.component'
import { ExpediaListingWizardStep3Component } from './step3/expedia-listing-wizard-step3.component'
import { RatesSharedModule } from '@tv3/containers/rates/rates-shared.module'

const components = [
  //
  ExpediaListingWizardStep1Component,
  ExpediaListingWizardStep2Component,
  ExpediaListingWizardStep3Component,
  ExpediaListingWizardComponent,
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, Tv3PlatformModule, RatesSharedModule],
})
export class ExpediaListingWizardModule {}
