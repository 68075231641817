import { Observable, of } from 'rxjs'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'
import * as R from 'ramda'
import { SubscriptionPermissionCheckerService } from '@tv3/services/subscription-permission-checker.service'

@Injectable({ providedIn: 'root' })
export class SubscriptionPermissionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private subscriptionPermissionCheckerService: SubscriptionPermissionCheckerService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.subscriptionPermissionCheckerService
      .check(R.pathOr(undefined, ['data', 'permissions'], route))
      .pipe(catchError(() => of(false)))
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.subscriptionPermissionCheckerService
      .check(R.pathOr(undefined, ['data', 'permissions'], childRoute))
      .pipe(catchError(() => of(false)))
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.subscriptionPermissionCheckerService
      .check(R.pathOr(undefined, ['data', 'permissions'], route))
      .pipe(catchError(() => of(false)))
  }
}
