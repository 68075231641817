import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RentalStatusIconComponent } from './components/rental-status-icon/rental-status-icon.component'
import { OwnerAccessDialogComponent } from './components/owner-access/owner-access-dialog.component'
import { UserRestrictionsDialogComponent } from './components/user-restrictions/user-restrictions-dialog.component'
import { OwnerAccessDialogService } from './components/owner-access/owner-access-dialog.service'
import { UserRestrictionsDialogService } from './components/user-restrictions/user-restrictions-dialog.service'
import { ManageRentalTagsDialogComponent } from './components/manage-tags/manage-rental-tags-dialog.component'
import { ManageRentalTagsDialogService } from './components/manage-tags/manage-rental-tags-dialog.service'
import { Tv3PlatformModule } from '@tokeet-frontend/tv3-platform'

import { BookingFeeFormComponent } from './components/fees/booking-fee-form/booking-fee-form.component'
import { BookingFeesComponent } from './components/fees/booking-fees/booking-fees.component'
import { CopyFeesFromDialogComponent } from './components/fees/copy-fees-from-dialog/copy-fees-from-dailog.component'
import { CopyFeesToDialogComponent } from './components/fees/copy-fees-to-dialog/copy-fees-to-dialog.component'
import { SelectFeesTableComponent } from './components/fees/select-fees-table/select-fees-table.component'
import { FeesOverlayService } from './components/fees/fees-overlay/fees-overlay.service'
import { FeesOverlayComponent } from './components/fees/fees-overlay/fees-overlay.component'
import { RoomFormComponent } from './components/rooms/room-form/room-form.component'
import { RoomsStoreModule } from './store/rooms/rooms.module'
import { FeeStringPipe } from './pipes/fee-string.pipe'
import { TaxesOverlayService } from './components/taxes/taxes-overlay/taxes-overlay.service'
import { TaxesOverlayComponent } from './components/taxes/taxes-overlay/taxes-overlay.component'
import { TaxFormComponent } from './components/taxes/tax-form/tax-form.component'
import { EditRentalTaxesComponent } from './components/taxes/edit-rental-taxes/edit-rental-taxes.component'
import { SelectTaxesTableComponent } from './components/taxes/select-taxes-table/select-taxes-table.component'
import { CopyTaxesFromDialogComponent } from './components/taxes/copy-taxes-from-dialog/copy-taxes-from-dailog.component'
import { CopyTaxesToDialogComponent } from './components/taxes/copy-taxes-to-dialog/copy-taxes-to-dialog.component'
import { ImageTableComponent } from './components/image-table/image-table.component'
import { CopyRoomsFromDialogComponent } from './components/rooms/copy-rooms-from-dialog/copy-rooms-from-dialog.component'
import { CopyRoomsToDialogComponent } from './components/rooms/copy-rooms-to-dialog/copy-rooms-to-dialog.component'
import { SelectRoomsTableComponent } from './components/rooms/select-rooms-table/select-rooms-table.component'
import { EditBookingPreferencesDialogComponent } from './components/edit-booking-preferences/edit-booking-preferences-dialog.component'
import { EditBookingPreferencesDialogService } from './components/edit-booking-preferences/edit-booking-preferences-dialog.service'
import { SelectPaymentSchedulesTableComponent } from './components/payment-schedule/select-payment-schedules-table/select-payment-schedules-table.component'
import { CopySchedulesToDialogComponent } from './components/payment-schedule/copy-schedules-to-dialog/copy-schedules-to-dialog.component'
import { CopySchedulesFromDialogComponent } from './components/payment-schedule/copy-schedules-from-dialog/copy-schedules-from-dialog.component'
import { RentalRoomsComponent } from './components/rooms/rental-rooms/rental-rooms.component'
import { RentalPaymentScheduleComponent } from './components/payment-schedule/edit-payment-schedule/rental-payment-schedule.component'
import { RentalFeesComponent } from './components/fees/rental-fees/rental-fees.component'

const components = [
  RentalStatusIconComponent,
  OwnerAccessDialogComponent,
  UserRestrictionsDialogComponent,
  ManageRentalTagsDialogComponent,

  //
  BookingFeeFormComponent,
  BookingFeesComponent,
  RentalFeesComponent,
  SelectFeesTableComponent,
  CopyFeesFromDialogComponent,
  CopyFeesToDialogComponent,
  FeesOverlayComponent,
  // Rooms
  RentalRoomsComponent,
  RoomFormComponent,
  SelectRoomsTableComponent,
  CopyRoomsFromDialogComponent,
  CopyRoomsToDialogComponent,

  //
  ImageTableComponent,

  // Taxes
  TaxesOverlayComponent,
  TaxFormComponent,
  EditRentalTaxesComponent,
  SelectTaxesTableComponent,
  CopyTaxesFromDialogComponent,
  CopyTaxesToDialogComponent,

  //
  RentalPaymentScheduleComponent,
  SelectPaymentSchedulesTableComponent,
  CopySchedulesToDialogComponent,
  CopySchedulesFromDialogComponent,

  //
  EditBookingPreferencesDialogComponent,
]

const services = [
  //
  FeesOverlayService,
  OwnerAccessDialogService,
  UserRestrictionsDialogService,
  ManageRentalTagsDialogService,

  TaxesOverlayService,

  EditBookingPreferencesDialogService,
]

const pipes = [
  //
  FeeStringPipe,
]

@NgModule({
  imports: [CommonModule, Tv3PlatformModule, RoomsStoreModule],
  declarations: [
    //
    ...components,
    ...pipes,
  ],
  providers: [...services, ...pipes],
  exports: [
    //
    ...components,
    ...pipes,
  ],
})
export class RentalsLibraryModule {}
