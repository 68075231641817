<span
  matTooltip="Edit the order of columns on this table."
  matTooltipPosition="above"
  (click)="onToggleMenu(true)"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <i class="far fa-bars"></i>
</span>
<div class="d-none" #render>
  <ng-container #vc></ng-container>
</div>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="onToggleMenu(false)"
  [cdkConnectedOverlayOffsetX]="-5"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isActionsMenuOpen"
>
  <div class="bg-white dropdown-shadow rounded">
    <div class="pt-3" cdkDropList (cdkDropListDropped)="onDrop($event)" #list>
      <ng-container *ngFor="let item of columns">
        <div
          cdkDrag
          [cdkDragDisabled]="item.restricted"
          [cdkDragBoundary]="list"
          lockAxis="y"
          class="bg-white draggable-table-column-item"
          *ngIf="!item.restricted"
        >
          <i class="far fa-grip-dots-vertical" matTooltip="Reposition Column" matTooltipPosition="left"></i>
          <mat-checkbox
            [matTooltip]="item.visible ? 'Uncheck to hide column from list' : 'Check to see column in the list'"
            matTooltipPosition="left"
            [disableRipple]="true"
            [disabled]="item.restricted"
            [(ngModel)]="item.visible"
            >{{ item.label }}</mat-checkbox
          >
        </div>
      </ng-container>
    </div>
    <div class="d-flex justify-content-end gap-3 p-3">
      <button class="btn btn-secondary-warning btn-sm" (click)="onReset()">
        <span class="far fa-sync"></span>
        Reset
      </button>
      <button class="btn btn-secondary-secondary btn-sm" (click)="onToggleMenu(false)">
        <span class="far fa-times"></span>
        Cancel
      </button>
      <button class="btn btn-secondary-info btn-sm" (click)="onApply()">
        <span class="far fa-check"></span>
        Apply
      </button>
    </div>
  </div>
</ng-template>
