import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import * as fromCalendar from './calendar.reducer'
import { EffectsModule } from '@ngrx/effects'
import { CalendarEffects } from './calendar.effects'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('calendar', fromCalendar.calendarReducer),
    EffectsModule.forFeature([CalendarEffects]),
  ],
  declarations: [],
})
export class CalendarModule {}
