import { FormGroup } from '@angular/forms'

export function resetValidators(form: FormGroup) {
  let controls = form.controls
  for (let key in controls) {
    if (controls.hasOwnProperty(key)) {
      let control = controls[key]
      control.setValidators([])
      control.updateValueAndValidity()
    }
  }
}
