import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { DataFeed } from './datafeed.model'
import { Observable, of } from 'rxjs'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as fromRoot from '../state'
import { Store } from '@ngrx/store'
import { deserializeArray } from '@tokeet-frontend/tv3-platform'
import { concatMap, map, tap, toArray } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class DataFeedService {
  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private toast: Toaster) {}

  all(): Observable<DataFeed[]> {
    const url = '@api/datafeed/all/'

    return this.http.get<object[]>(url).pipe(deserializeArray<DataFeed>(DataFeed))
  }

  create(name: string, type: string): Observable<DataFeed> {
    const url = '@api/datafeed/'

    return this.http.post(url, { name, type }).pipe(
      map(DataFeed.deserialize),
      tap(() => this.toast.success('Data Feed created successfully!'))
    )
  }

  pause(feedId: string) {
    const url = `@api/datafeed/pause/${feedId}`

    return this.http.put(url, {}).pipe(tap(() => this.toast.success('Data Feed disabled successfully!')))
  }

  resume(feedId: string) {
    const url = `@api/datafeed/resume/${feedId}`

    return this.http.put(url, {}).pipe(tap(() => this.toast.success('Data Feed enabled successfully!')))
  }

  remove(feedId: string) {
    const url = `@api/datafeed/${feedId}`

    return this.http.delete<DataFeed>(url)
  }

  removeMultiple(ids: string[]): Observable<DataFeed[]> {
    return of(...ids).pipe(
      concatMap((id) => this.remove(id)),
      toArray()
    )
  }
}
