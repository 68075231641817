<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-sm-6">
        <h5 class="modal-title">
          Booking Settings
          <app-page-help articleId="-" videoId="-"></app-page-help>
        </h5>
      </div>
      <div class="col-sm-6 btn-holder text-right">
        <button (click)="onSave(form)" [disabled]="!isFullSync" type="button" class="btn btn-secondary-info">
          <span class="cs-icon cs-icon-save"></span>
          Save
        </button>
      </div>
      <a (click)="close()" class="cstm-close">
        <span class="far fa-times"></span>
      </a>
    </div>
    <div class="modal-body">
      <div class="main-content">
        <div class="alert alert-warning" *ngIf="!isFullSync">
          In order to manage booking settings, <strong>Full Synchronization</strong> should be enabled for this listing.
          <button class="btn btn-secondary-info" (click)="onUpdateSyncCategoryAirBnBV2()">
            <i class="fal fa-exchange"></i> Change Synchronization
          </button>
        </div>
        <form [formGroup]="form">
          <mat-accordion class="d-flex flex-column gap-3">
            <mat-expansion-panel [expanded]="true" class="bg-white rounded shadow2">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-md"> Policies </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" formGroupName="cancellation_policy_settings">
                <div class="col-sm-12">
                  <h6 class="mb-3">
                    Standard cancellation policy
                    <small class="text-muted d-block"
                      >Choose the policy that will apply to stays under 28 nights.</small
                    >
                  </h6>
                </div>
                <div class="col-sm-12">
                  <mat-radio-group formControlName="cancellation_policy_category">
                    <div *ngFor="let policy of cancellationPolicies" class="policy">
                      <mat-radio-button [value]="policy.value">
                        {{ policy.title }}
                      </mat-radio-button>
                      <div class="policy_description">
                        {{ policy.description }}
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6 class="mb-3">
                    Long-term stay cancellation policy
                    <small class="text-muted d-block"
                      >Choose the policy that will apply to stays 28 nights or longer.</small
                    >
                  </h6>
                </div>
                <div class="col-sm-12">
                  <mat-radio-group formControlName="lts_cancellation_policy_id">
                    <div *ngFor="let policy of longTermCancellationPolicies" class="policy">
                      <mat-radio-button [value]="policy.value">
                        {{ policy.title }}
                      </mat-radio-button>
                      <div class="policy_description">
                        {{ policy.description }}
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <input
                      type="number"
                      formControlName="non_refundable_price_factor"
                      matInput
                      placeholder="Non-refundable Discount"
                      matTooltip="In addition to selected cancellation policy, offer a non-refundable option—guests pay less, but you keep your payout no matter when they cancel."
                    />
                    <span matSuffix>%</span>
                    <mat-error *ngIf="cancellationPolicy.get('non_refundable_price_factor').hasError('min')">
                      Value should be greater than <strong>0</strong>
                    </mat-error>
                    <mat-error *ngIf="cancellationPolicy.get('non_refundable_price_factor').hasError('max')">
                      Value should be less than <strong>100</strong>
                    </mat-error>
                  </mat-form-field2>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <mat-select
                      placeholder="Instant Book"
                      formControlName="instant_booking_allowed_category"
                      matTooltip="When this is on, bookings are accepted automatically. When off, you’ll need to manually accept or decline booking requests for selected guest."
                    >
                      <mat-option [value]="item.value" *ngFor="let item of airbnbInstantBookingAllowedCategories">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field2>
                </div>
                <div class="col-sm-12">
                  <div class="alert alert-warning">{{ turnOffInstantBookingWarning }}</div>
                </div>
                <div class="col-sm-12">
                  <mat-form-field2 class="mat-block">
                    <textarea
                      formControlName="instant_book_welcome_message"
                      matInput
                      rows="3"
                      placeholder="Pre-booking message"
                      cdkTextareaAutosize
                    ></textarea>
                  </mat-form-field2>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="bg-white rounded shadow2">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-md"> House rules </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-grid gap-3" formGroupName="guest_controls" style="grid-template-columns: repeat(3, 1fr)">
                <mat-checkbox formControlName="allows_infants_as_host">Infants allowed</mat-checkbox>
                <mat-checkbox formControlName="allows_events_as_host">Events allowed</mat-checkbox>

                <mat-checkbox formControlName="allows_smoking_as_host"
                  >Smoking, vaping, e‑cigarettes allowed</mat-checkbox
                >

                <mat-checkbox formControlName="allows_children_as_host" (change)="onChildrenChanged($event)"
                  >Children allowed</mat-checkbox
                >

                <mat-checkbox formControlName="allows_pets_as_host">Pets allowed</mat-checkbox>
              </div>
              <div formGroupName="guest_controls" class="row mt-3">
                <div class="col-md-6">
                  <mat-form-field2 class="mat-block">
                    <input
                      formControlName="children_not_allowed_details"
                      matInput
                      placeholder="Children not allowed details"
                    />
                    <mat-error
                      *ngIf="
                        guestControls.get('children_not_allowed_details').touched &&
                        guestControls.get('children_not_allowed_details').hasError('required')
                      "
                    >
                      Details is required to provide details about why the place is not suitable for children.
                    </mat-error>
                  </mat-form-field2>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <mat-select placeholder="Check in start" formControlName="check_in_time_start">
                      <mat-option [value]="item.value" *ngFor="let item of airbnbCheckinStartTimeOptions">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field2>
                </div>
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <mat-select placeholder="Check in end" formControlName="check_in_time_end">
                      <mat-option [value]="item.value" *ngFor="let item of airbnbCheckinEndTimeOptions">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field2>
                </div>
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <mat-select placeholder="Checkout time" formControlName="check_out_time">
                      <mat-option [value]="item.value" *ngFor="let item of airbnbCheckoutTimeOptions">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field2>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="bg-white rounded shadow2">
              <mat-expansion-panel-header>
                <mat-panel-title class="text-md"> Listing Expectations for Guests</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngFor="let item of expectations.controls; let i = index" [formGroup]="item">
                <div class="col-sm-4">
                  <mat-form-field2 class="mat-block">
                    <mat-select placeholder="Type" formControlName="type" required>
                      <mat-option [value]="item.value" *ngFor="let item of airbnbListingExpectationTypes">
                        {{ item.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field2>
                </div>
                <div class="col">
                  <mat-form-field2 class="mat-block">
                    <input formControlName="added_details" matInput placeholder="Details" />
                    <a
                      href="javascript:;"
                      class="text-danger"
                      matTooltip="Remove this expectation"
                      (click)="onRemoveExpectation(i)"
                      matSuffix
                      ><i class="cs-icon cs-icon-trash"></i
                    ></a>
                  </mat-form-field2>
                </div>
              </div>
              <div>
                <button class="btn btn-secondary-info" (click)="onAddExpectation()">
                  <i class="far fa-plus"></i> Add Expectation
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </div>
    </div>
  </div>
</div>
