import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import {
  GenericExportDialogComponent,
  GenericExportDialogConfig,
} from '@tv3/shared/dialogs/generic-export-dialog/generic-export-dialog.component'
import * as moment from 'moment'
import { ConnectionView } from '@tv3/store/connection/connection.view'

@Injectable({
  providedIn: SharedModule,
})
export class ExportIcalCalendarDialogService {
  constructor(private dialog: MatDialog) {}

  private getConfig(data: ConnectionView[], filename: string): GenericExportDialogConfig {
    return {
      title: 'Export Calendars to CSV',
      description: 'You are about to download all calendars in current channel.',
      columns: [
        { name: 'Type', field: 'typeText' },
        { name: 'Name', field: 'propertyNameView' },
        { name: 'Linked Rental', field: 'rentalName' },
        { name: 'URL', field: 'url' },
        { name: 'Date Connected', field: 'linkDateOrCreatedView' },
      ],
      exportData: data,
      filename: filename,
      columnCustomizable: false,
    }
  }

  open(connections: ConnectionView[], filename = `channel-calendars - ${moment().format('MM_DD_YYYY')}`) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['fixed-modal'],
      data: { config: this.getConfig(connections, filename) },
    }

    return this.dialog.open(GenericExportDialogComponent, defaultConfig)
  }
}
