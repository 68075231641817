import * as R from 'ramda'
import { toNumber } from 'lodash'
import { TaxV3 } from '@tokeet-frontend/tv3-platform'
import { TaxTypes, TaxModalities } from '@tokeet/cost-resolver'
import { InvoiceItem } from '../..'

export function getInvoiceLineRawTotal(item: InvoiceItem): number {
  const total = (item.unitCost || 0) * (item.qty || 0) + (toNumber(item.discount) || 0)
  return total
}

export function getActualTaxAmount(tax: TaxV3, nights = 1, guests = 1) {
  switch (tax.modality) {
    case TaxModalities.PerNight:
      return tax.amount * nights
    case TaxModalities.PerPerson:
      return tax.amount * guests
    case TaxModalities.PerPersonPerNight:
      return tax.amount * nights * guests
    default:
      return tax.amount
  }
}

export function getInvoiceLineTaxTotal(item: InvoiceItem, taxes?: TaxV3[], guestCount = 1): number {
  taxes = taxes || item.taxes || []
  const lineRawTotal = getInvoiceLineRawTotal(item)
  const taxFlat = R.sum(
    R.map(
      (tax: TaxV3) => getActualTaxAmount(tax, item.qty, guestCount),
      R.filter((tax) => tax?.type === TaxTypes.Flat, taxes)
    )
  )
  const taxPercent = R.sum(
    R.map(
      (tax: TaxV3) => getActualTaxAmount(tax, item.qty, guestCount),
      R.filter((tax) => tax?.type === TaxTypes.Percent, taxes)
    )
  )

  const total = taxFlat + ((taxPercent || 0) / 100) * lineRawTotal

  return total
}

export function getInvoiceLineTotal(item: InvoiceItem, partial = true, guests = 1): number {
  let total = getInvoiceLineRawTotal(item) + getInvoiceLineTaxTotal(item, null, guests)

  // partial payment
  if (partial) {
    total *= item.percent || 1
  }

  return total
}

export function getInvoiceDiscountFeesTotal(invoiceItems: InvoiceItem[]): number {
  return R.reduce(
    (acc, item) => {
      return acc + (item.discount || 0)
    },
    0,
    invoiceItems
  )
}

export function getInvoiceTaxesTotal(invoiceItems: InvoiceItem[], guests = 1): number {
  return R.reduce(
    (acc, item) => {
      return acc + getInvoiceLineTaxTotal(item, null, guests)
    },
    0,
    invoiceItems
  )
}

export function getInvoiceTotal(invoiceItems: InvoiceItem[], partial = true, guests = 1): number {
  if (R.isNil(invoiceItems)) {
    return 0
  }
  const result = R.reduce(
    (acc, item) => {
      return acc + getInvoiceLineTotal(item, partial, guests)
    },
    0,
    invoiceItems
  )
  return result
}
