import { Component, OnInit, ViewChild } from '@angular/core'
import {
  ConfirmDialogService,
  DataCheckerService,
  DialogService,
  filterPredicateFactory,
  isEmptyTable,
  selectAllRentals,
  selectChannelByName,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'
import { MatSort } from '@angular/material/sort'
import { asyncScheduler, BehaviorSubject, combineLatest, of } from 'rxjs'
import { select, Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { createLocaleCompareSort } from '@tokeet-frontend/tv3-platform'
import { finalize, observeOn, take } from 'rxjs/operators'
import { FilterGroup } from '@tv3/services/utils/filters.service'
import { InquiryChargeService } from '@tv3/services/inquiry-charge.service'
import {
  DeleteLosDiscount,
  DeleteMultipleLosDiscount,
  LoadLosDiscounts,
  LoadLosDiscountsComplete,
  LosDiscountGuard,
  LosDiscountModality,
  LosDiscountView,
  losTokeetChannel,
  selectLosDiscountsLoaded,
} from '@tokeet-frontend/los-discount'
import { LosDiscountFilter } from '@tv3/store/los-discount/los-discount.filter'
import { MatPaginator } from '@angular/material/paginator'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { environment } from '@tv3/environments/environment'
import { LosDiscountOverlayService } from './overlay/los-discount-overlay.service'
import { AddLosDiscountDialogComponent } from './add/add-los-discount-dialog.component'

@Component({
  selector: 'app-los-discount-table',
  templateUrl: './los-discount-table.component.html',
  styleUrls: ['./los-discount-table.component.scss'],
})
export class LosDiscountTableComponent extends SelectableRow<LosDiscountView> implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  displayedColumns = [
    'select',
    'name',
    'amount',
    'rentalsView',
    'channelsView',
    'startView',
    'endView',
    'length',
    'guests',
    'createdView',
    'edit',
  ]

  filterGroup = FilterGroup.LosDiscounts
  tableType = TableType.LosDiscounts
  isEmptyTable$ = isEmptyTable(this.dataSource)

  isLoaded$ = this.store.pipe(select(selectLosDiscountsLoaded))

  items: LosDiscountView[] = []
  rentals$ = this.store.pipe(observeOn(asyncScheduler), select(selectAllRentals))
  channels$ = combineLatest([of(losTokeetChannel), this.store.pipe(select(selectChannelByName, { name: 'webready' }))])

  filters = this.losDiscountFilter.group
  searchTerm: string

  modalities = LosDiscountModality

  hasLOS = environment.features.losModality

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    public inquiryChargeService: InquiryChargeService,
    protected storage: UserStorage,
    private losDiscountFilter: LosDiscountFilter,
    private dialog: DialogService,
    private dataCheckerService: DataCheckerService,
    private losDiscountDialog: LosDiscountOverlayService,
    private confirmDialog: ConfirmDialogService
  ) {
    super(storage, TableType.LosDiscounts)
    this.dataCheckerService.check([LosDiscountGuard])
  }

  ngOnInit() {
    this.dataSource.filterPredicate = filterPredicateFactory(this.displayedColumns)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort()

    this.losDiscountFilter.group.changes.pipe(untilDestroy(this)).subscribe((items) => {
      this.items = this.dataSource.data = items
    })

    this.losDiscountFilter.group.currentFilters.pipe(untilDestroy(this)).subscribe(() => {
      this.paginator.firstPage()
    })
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    this.actions$
      .pipe(
        ofType(LoadLosDiscountsComplete),
        take(1),
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(LoadLosDiscounts({ reset: true }))
  }

  onSearch(term: string) {
    this.paginator.firstPage()
    this.dataSource.filter = (term + '').trim().toLowerCase()
  }

  onAdd() {
    this.dialog.openVariableDialog(AddLosDiscountDialogComponent, {
      width: '700px',
      height: 'auto',
    })
  }

  onEdit(item: LosDiscountView) {
    this.losDiscountDialog.open(item)
  }

  onDeleteSelected() {
    const selectedIds = this.selection.selected.map((item) => item.id)
    this.confirmDialog
      .confirm({
        title: 'Delete selected discounts?',
        body: 'Are you sure you want to delete selected discounts?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteMultipleLosDiscount({ ids: selectedIds }))
      })
  }

  onDelete(item: LosDiscountView) {
    this.confirmDialog
      .confirm({
        title: 'Delete discount?',
        body: 'Are you sure you want to delete this discount?',
      })
      .subscribe(() => {
        this.store.dispatch(DeleteLosDiscount({ id: item.id }))
      })
  }
}
