import { createAction, props } from '@ngrx/store'
import {
  AirbnbListingSectionDetails,
  AirbnbTabs,
} from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.state'
import { ConnectionView } from '@tv3/store/connection/connection.view'
import { AirbnbPhoto, AirbnbRoom } from '@tv3/containers/channels/connection-detail/airbnb-api/store/airbnb-api.models'

export const OpenTab = createAction(
  '[AirBnB Dialog] Open Tab',
  props<{ tab: AirbnbTabs; connection: ConnectionView }>()
)
export const OpenDetails = createAction(
  '[AirBnB Dialog] Open Details',
  props<{ details: AirbnbListingSectionDetails }>()
)
export const CloseDetails = createAction('[AirBnB Dialog] Close Details')

export const LoadPhotos = createAction('[AirBnB Dialog] Load Photos ', props<{ connection: ConnectionView }>())
export const LoadPhotosComplete = createAction(
  '[AirBnB Dialog] Load Photos Complete',
  props<{ photos: AirbnbPhoto[] }>()
)

export const LoadRooms = createAction('[AirBnB Dialog] Load Rooms', props<{ connection: ConnectionView }>())
export const LoadRoomsComplete = createAction('[AirBnB Dialog] Load Rooms Complete', props<{ rooms: AirbnbRoom[] }>())

export const UpdateOrder = createAction(
  '[AirBnB Dialog] Update Photo Order',
  props<{ connection: ConnectionView; photo: AirbnbPhoto; newOrder: number; orderByIds: { [key: string]: number } }>()
)
export const UpdateOrderComplete = createAction(
  '[AirBnB Dialog] Update Photo Order Complete',
  props<{ orderByIds: { [key: string]: number } }>()
)

export const UpdateCaption = createAction(
  '[AirBnB Dialog] Update Caption',
  props<{ connection: ConnectionView; photo: AirbnbPhoto }>()
)
export const UpdateCaptionComplete = createAction(
  '[AirBnB Dialog] Update Caption Complete',
  props<{ photo: AirbnbPhoto }>()
)

export const DeletePhoto = createAction(
  '[AirBnB Dialog] Delete Photo',
  props<{ connection: ConnectionView; photo: AirbnbPhoto }>()
)
export const DeletePhotoComplete = createAction(
  '[AirBnB Dialog] Delete Photo Complete',
  props<{ photo: AirbnbPhoto }>()
)
