import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { catchError, concatMap, map, mapTo, switchMap, tap, toArray } from 'rxjs/operators'
import { combineLatest, forkJoin, of, throwError } from 'rxjs'
import {
  AddInvoiceRule,
  AddInvoiceRuleCondition,
  AddInvoiceRuleConditionComplete,
  AddInvoiceRulesComplete,
  ArchiveInvoiceRule,
  ArchiveInvoiceRuleComplete,
  DeleteInvoiceRule,
  DeleteInvoiceRuleComplete,
  DeleteInvoiceRuleCondition,
  DeleteInvoiceRuleConditionComplete,
  DeleteInvoiceRules,
  DeleteInvoiceRulesComplete,
  DuplicateInvoiceRule,
  LoadInvoiceRules,
  LoadInvoiceRulesComplete,
  PauseInvoiceRule,
  PauseInvoiceRuleComplete,
  PauseInvoiceRuleReminder,
  PauseInvoiceRuleReminderComplete,
  RecreateInvoiceRule,
  ResumeInvoiceRule,
  ResumeInvoiceRuleComplete,
  ResumeInvoiceRuleReminder,
  ResumeInvoiceRuleReminderComplete,
  SetInvoiceRuleConditions,
  UnArchiveInvoiceRule,
  UnArchiveInvoiceRuleComplete,
  UpdateInvoiceRule,
  UpdateInvoiceRuleComplete,
  UpdateInvoiceRuleCondition,
  UpdateInvoiceRuleConditionComplete,
} from './rule.actions'
import { InvoiceRuleService } from './rule.service'
import { AddEntityTagsComplete, EntityTagsService } from '@tokeet-frontend/tags'
import { DataEntityType, Toaster } from '@tokeet-frontend/tv3-platform'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { SetInvoiceRuleConditionsComplete } from '@tokeet-frontend/invoices'

@Injectable()
export class InvoiceRuleEffects {
  @Effect()
  addInvoiceRule$ = this.actions$.pipe(
    ofType(AddInvoiceRule),
    concatMap(({ payload, tags, silent }) => {
      return this.invoiceRuleService.createMany(payload).pipe(
        switchMap((rules) => {
          if (!tags || !tags.length) return of({ rules, tags: [] })

          const tasks = rules.map((rule) => {
            return this.tagsService.addTags(DataEntityType.InvoiceRule, rule.group_id, tags)
          })

          return forkJoin(tasks).pipe(map((tags) => ({ rules, tags })))
        }),
        switchMap((response) => {
          const actions = [
            AddInvoiceRulesComplete({ rules: response.rules }),
            ...response.tags.map((item) => AddEntityTagsComplete({ item })),
          ]

          return of(...actions)
        }),
        tap(() => !silent && this.toaster.success('Invoice automation created successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  recreateInvoiceRule$ = this.actions$.pipe(
    ofType(RecreateInvoiceRule),
    switchMap(({ ids, payload }) => {
      return combineLatest([
        this.invoiceRuleService.deleteBatch(ids),
        this.invoiceRuleService.createMany(payload),
      ]).pipe(
        tap(() => this.toaster.success('Invoice automation updated successfully.')),
        switchMap(([_, rules]) => [
          UpdateInvoiceRuleComplete({ updates: rules.map((r) => ({ id: r.pkey, changes: r })) }),
          DeleteInvoiceRulesComplete({ ids }),
        ]),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  updateInvoiceRule$ = this.actions$.pipe(
    ofType(UpdateInvoiceRule),
    switchMap(({ items }) => {
      return of(...items).pipe(
        concatMap((item) => this.invoiceRuleService.update(item.id, item.payload)),
        toArray(),
        map((rules) => UpdateInvoiceRuleComplete({ updates: rules.map((r) => ({ id: r.pkey, changes: r })) })),
        tap(() => this.toaster.success('Invoice automation updated successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  duplicateInvoiceRule$ = this.actions$.pipe(
    ofType(DuplicateInvoiceRule),
    map(({ payload }) => AddInvoiceRule({ payload }))
  )

  @Effect()
  loadInvoiceRules$ = this.actions$.pipe(
    ofType(LoadInvoiceRules),
    concatMap(() =>
      this.invoiceRuleService.all().pipe(
        map((rules) => LoadInvoiceRulesComplete({ rules })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInvoiceRules$ = this.actions$.pipe(
    ofType(DeleteInvoiceRules),
    concatMap(({ ids, silent }) =>
      this.invoiceRuleService.deleteBatch(ids).pipe(
        map(() => DeleteInvoiceRulesComplete({ ids })),
        tap(() => !silent && this.toaster.success('Invoice automations deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pauseRuleReminder$ = this.actions$.pipe(
    ofType(PauseInvoiceRuleReminder),
    concatMap(({ ids }) =>
      this.invoiceRuleService.pauseRuleReminderBatch(ids).pipe(
        map((items) => PauseInvoiceRuleReminderComplete({ update: items.map((r) => ({ id: r.pkey, changes: r })) })),
        tap(() => this.toaster.success('Invoice automation reminder paused successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  resumeRuleReminder$ = this.actions$.pipe(
    ofType(ResumeInvoiceRuleReminder),
    concatMap(({ ids, when, days }) =>
      this.invoiceRuleService.resumeRuleReminderBatch(ids, when, days).pipe(
        map((items) => ResumeInvoiceRuleReminderComplete({ update: items.map((r) => ({ id: r.pkey, changes: r })) })),
        tap(() => this.toaster.success('Invoice automation reminder resumed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteInvoiceRule$ = this.actions$.pipe(
    ofType(DeleteInvoiceRule),
    concatMap(({ ids }) =>
      this.invoiceRuleService.deleteBatch(ids).pipe(
        map(() => DeleteInvoiceRuleComplete({ ids })),
        tap(() => this.toaster.success('Invoice automation deleted successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pauseInvoiceRule$ = this.actions$.pipe(
    ofType(PauseInvoiceRule),
    concatMap(({ ids }) =>
      this.invoiceRuleService.pauseBatch(ids).pipe(
        map(() => PauseInvoiceRuleComplete({ update: ids.map((id) => ({ id, changes: { status: 0 } })) })),
        tap(() => this.toaster.success('Invoice automation paused successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  resumeInvoiceRule$ = this.actions$.pipe(
    ofType(ResumeInvoiceRule),
    concatMap(({ ids }) =>
      this.invoiceRuleService.resumeBatch(ids).pipe(
        map(() => ResumeInvoiceRuleComplete({ update: ids.map((id) => ({ id, changes: { status: 1 } })) })),
        tap(() => this.toaster.success('Invoice automation resumed successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  archiveInvoiceRule$ = this.actions$.pipe(
    ofType(ArchiveInvoiceRule),
    concatMap(({ ids }) =>
      this.invoiceRuleService.pauseBatch(ids).pipe(
        concatMap(() =>
          this.invoiceRuleService.archiveBatch(ids).pipe(
            tap(() => this.toaster.success('Invoice automation archived successfully.')),
            concatMap(() => [
              PauseInvoiceRuleComplete({ update: ids.map((id) => ({ id, changes: { status: 0 } })) }),
              ArchiveInvoiceRuleComplete({ update: ids.map((id) => ({ id, changes: { archived: 1 } })) }),
            ]),
            catchError((error) => of(ActionFailed({ error })))
          )
        )
      )
    )
  )

  @Effect()
  unArchiveInvoiceRule$ = this.actions$.pipe(
    ofType(UnArchiveInvoiceRule),
    concatMap(({ ids }) =>
      this.invoiceRuleService.unarchiveBatch(ids).pipe(
        map(() => UnArchiveInvoiceRuleComplete({ update: ids.map((id) => ({ id, changes: { archived: 0 } })) })),
        tap(() => this.toaster.success('Invoice automation unarchived successfully.')),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  setConditions$ = this.actions$.pipe(
    ofType(SetInvoiceRuleConditions),
    switchMap(({ ids, items }) =>
      of(...ids).pipe(
        concatMap((id) =>
          this.invoiceRuleService.setConditions(id, items).pipe(catchError((error) => throwError(error)))
        ),
        toArray(),
        tap(() => this.toaster.success('Invoice automation conditions updated successfully.')),
        map((items) => SetInvoiceRuleConditionsComplete({ items })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  createCondition$ = this.actions$.pipe(
    ofType(AddInvoiceRuleCondition),
    switchMap(({ request }) =>
      this.invoiceRuleService.createCondition(request).pipe(
        tap(() => this.toaster.success('Invoice automation condition created successfully.')),
        map((condition) =>
          AddInvoiceRuleConditionComplete({
            response: {
              ruleId: request.ruleId,
              condition,
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  saveCondition$ = this.actions$.pipe(
    ofType(UpdateInvoiceRuleCondition),
    switchMap(({ request }) =>
      this.invoiceRuleService.saveCondition(request).pipe(
        tap(() => this.toaster.success('Invoice automation condition saved successfully.')),
        map((condition) =>
          UpdateInvoiceRuleConditionComplete({
            response: {
              ruleId: request.ruleId,
              condition,
            },
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteCondition$ = this.actions$.pipe(
    ofType(DeleteInvoiceRuleCondition),
    switchMap(({ request }) =>
      this.invoiceRuleService.deleteCondition(request).pipe(
        tap(() => this.toaster.success('Invoice automation condition deleted successfully.')),
        map(() => DeleteInvoiceRuleConditionComplete({ response: request })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private toaster: Toaster,
    private invoiceRuleService: InvoiceRuleService,
    private tagsService: EntityTagsService
  ) {}
}
