import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DiscountCode } from '@tokeet-frontend/discount-codes'
import { DiscountCodeDetailComponent } from './details/discount-code-detail.component'

export enum DiscountCodeDialogTab {
  DiscountCode,
  Activities,
  Logs,
}

@Component({
  selector: 'app-discount-code-overlay',
  templateUrl: './discount-code-overlay.component.html',
  styleUrls: ['./discount-code-overlay.component.scss'],
})
export class DiscountCodeOverlayComponent implements OnInit {
  @ViewChild('details') details: DiscountCodeDetailComponent

  activeTab = DiscountCodeDialogTab.DiscountCode
  tabs = DiscountCodeDialogTab
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { item?: Partial<DiscountCode> },
    public dialogRef: MatDialogRef<DiscountCodeOverlayComponent>
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close()
  }

  onSave() {
    this.details.save()
    if (!this.data.item || !this.data.item.id) {
      this.close()
    }
  }
}
