export const statusKey = {
  booked: 'booked',
  unbooked: 'unbooked',
  archived: 'archived',
  all: 'all',
}

export const statuses = [
  { name: 'Archived', value: statusKey.archived },
  { name: 'Booked', value: statusKey.booked },
  { name: 'Unbooked', value: statusKey.unbooked },
]

export const interactionType = [
  { name: 'Comment', value: 'comment' },
  { name: 'Meeting', value: 'meeting' },
  { name: 'Phone Call', value: 'phone call' },
]
