import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import {
  Account,
  asUTCEpoch,
  Destroyable,
  isSomething,
  Rental,
  selectAllRentals,
  selectCurrentAccount,
  selectRentalCities,
  selectRentalTags,
  selectSomeOnce,
  taxV3ModalityLabels,
  Toaster,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { Component, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { isEmptyTable, localeCompareSort } from '@tokeet-frontend/tv3-platform'
import { FeeTypes, TaxModalities } from '@tokeet/cost-resolver'
import { combineLatest, from, of } from 'rxjs'
import { flatMap, mergeMap, startWith, toArray } from 'rxjs/operators'
import * as R from 'ramda'
import { SelectionModel } from '@angular/cdk/collections'
import * as moment from 'moment'
import { BookingFee, FeeModalities } from '../../../store/fees/booking-fee'
import { BookingFeesService } from '../../../store/fees/booking-fees.service'

@Component({
  selector: 'app-fees-overlay',
  templateUrl: './fees-overlay.component.html',
  styleUrls: ['./fees-overlay.component.scss'],
})
export class FeesOverlayComponent extends Destroyable implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  dataSource = new MatTableDataSource<Rental>([])

  displayedColumns = ['select', 'name', 'description', 'cityView']

  isEmptyTable$ = isEmptyTable(this.dataSource)

  fees = []

  currencySymbol: string
  taxV3Modalities = TaxModalities
  taxV3ModalityLabels = taxV3ModalityLabels

  cities$ = this.store.pipe(select(selectRentalCities))
  tags$ = this.store.pipe(select(selectRentalTags))

  citiesCtrl = new FormControl([])
  tagsCtrl = new FormControl([])
  searchCtrl = new FormControl('')

  renderedData = []
  selection = new SelectionModel(true, [])

  defaultFee = {
    name: '',
    description: '',
    amount: null,
    type: FeeTypes.Flat,
    modality: FeeModalities.PerStay,
    channel_id: '',
    start: null,
    end: null,
    dow: [],
    refundable: null,
    taxable: null,
    status: true,
    guest_threshold: null,
    length_threshold: null,
    upfront: false,
  }

  account: Account
  currencyCode = 'USD'

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeesOverlayComponent>,
    private store: Store<any>,
    private toaster: Toaster,
    private bookingFeesService: BookingFeesService
  ) {
    super()
  }

  ngOnInit() {
    this.store.pipe(select(selectCurrentAccount), untilDestroy(this)).subscribe((account) => {
      this.currencyCode = account?.currency?.code || 'USD'
    })

    const form = this.defineForm()
    form.patchValue({
      ...this.defaultFee,
    })
    this.fees.push(form)

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = localeCompareSort

    combineLatest(
      this.store.pipe(selectSomeOnce(selectAllRentals)),
      this.tagsCtrl.valueChanges.pipe(startWith([])),
      this.citiesCtrl.valueChanges.pipe(startWith([])),
      this.searchCtrl.valueChanges.pipe(startWith(''))
    ).subscribe(([rentals, tags, cities, search]) => {
      if (isSomething(tags)) {
        rentals = R.filter(
          (r) =>
            R.any(
              R.equals(true),
              R.map((tag) => R.contains(tag, R.pathOr([], ['tags'], r)), tags)
            ),
          rentals
        )
      }
      if (isSomething(cities)) {
        rentals = R.filter((r) => R.contains(R.pathOr('', ['address', 'city'], r), cities), rentals)
      }
      if (isSomething(search)) {
        rentals = R.filter((r) => {
          const city = R.pathOr('', ['address', 'city'], r)
          const name = r.name
          const description = r.descriptionView
          return [name, description, city].join('').toLowerCase().indexOf(search.toLowerCase()) !== -1
        }, rentals)
      }
      this.dataSource.data = rentals
    })

    this.dataSource['_renderData'].subscribe((rows) => {
      this.renderedData = rows
    })
    this.dataSource['_data'].subscribe(() => {
      this.selection = new SelectionModel(true, [])
    })
  }

  isAnyInvalid(forms: FormGroup[]) {
    return R.any((f) => f.invalid, forms)
  }

  onSave() {
    if (this.isAnyInvalid(this.fees)) {
      R.forEach((form) => {
        form.markAllAsTouched()
      }, this.fees)
      return
    }

    if (R.isEmpty(this.selection.selected)) {
      this.toaster.warning('Please select at least one rental.')
      return
    }

    if (R.isEmpty(this.fees)) {
      this.toaster.warning('You have to create some fees first.')
      return
    }

    const fees: Partial<BookingFee>[] = this.fees.map((f) => f.getRawValue())

    let payloads = []
    R.forEach((fee) => {
      R.forEach((rental) => {
        payloads.push(this.toPayload(fee, rental))
      }, this.selection.selected)
    }, fees)

    of(payloads)
      .pipe(
        flatMap((payload) => from(payload)),
        mergeMap((payload) => this.bookingFeesService.createFee(payload.data)),
        toArray()
      )
      .subscribe(() => {
        this.toaster.success('Fees created successfully')
        this.onClose()
      })
  }

  onClose() {
    this.dialogRef.close()
  }

  onAdd() {
    const form = this.defineForm()
    form.patchValue({
      ...this.defaultFee,
    })
    this.fees.push(form)
  }

  onRemove(index: number) {
    this.fees = R.remove(index, 1, this.fees)
  }

  toPayload(fee, rental: Rental) {
    const start = moment
      .utc(asUTCEpoch(fee.start) * 1000)
      .startOf('date')
      .add(12, 'hours')
      .unix()
    const end = moment
      .utc(asUTCEpoch(fee.end) * 1000)
      .startOf('date')
      .add(12, 'hours')
      .unix()

    const data: Partial<BookingFee> = {
      account: rental.account,
      name: fee.name,
      description: fee.description,
      rental_id: rental.id,
      amount: fee.amount,
      type: fee.type,
      modality: fee.modality,
      refundable: fee.refundable ? 1 : 0,
      taxable: fee.taxable ? 1 : 0,
      guest_threshold: fee.guest_threshold,
      length_threshold: fee.length_threshold,
      channel_id: fee.channel_id,
      status: fee.status ? 1 : 0,
      upfront: fee.upfront ? 1 : 0,
      scheduling: {
        start: isSomething(fee.start) ? start : null,
        end: isSomething(fee.end) ? end : null,
        dow: fee.dow,
      },
    }

    return {
      id: fee.pkey,
      data,
    }
  }

  defineForm() {
    return this.fb.group({
      pkey: [''],
      name: ['', [Validators.required]],
      description: [''],
      amount: ['', [Validators.required, Validators.min(1), Validators.max(100)]],
      modality: ['ps', [Validators.required]],
      type: ['pct', [Validators.required]],
      dow: [[]],
      start: [null],
      end: [null],
      status: [true],
      refundable: [''],
      taxable: [''],
      channel_id: [null],
      guest_threshold: [''],
      length_threshold: [''],
      upfront: [false],
    })
  }

  masterToggle() {
    if (this.isAllSelected(this.selection, this.dataSource.data)) {
      this.selection.clear()
    } else {
      this.dataSource.data.forEach((row) => {
        this.selection.select(row)
      })
    }
  }

  isAllSelected<T>(selection: SelectionModel<T>, data: T[]) {
    return selection.selected?.length === data?.length
  }

  onCancelFilters() {
    this.tagsCtrl.reset()
    this.citiesCtrl.reset()
    this.searchCtrl.reset()
  }
}
