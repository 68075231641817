import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DialCodeItem, Storage } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'
import { Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class PhoneNumberService {
  private dialCodeStoreKey = 'dial-codes-khgaistd873'
  private storage = new Storage(localStorage)
  private validationCache = new Map<string, boolean>()
  private dialCodes: DialCodeItem[]

  constructor(private http: HttpClient) {
    this.dialCodes = this.storage.get(this.dialCodeStoreKey, [])
  }

  getDialCodes() {
    if (!lodash.isEmpty(this.dialCodes)) {
      return of(this.dialCodes)
    }

    return this.http.get<DialCodeItem[]>('@webready/public/utils/phone_number/dial_codes').pipe(
      tap((data) => {
        this.storage.set(this.dialCodeStoreKey, data)
        this.dialCodes = data
      })
    )
  }

  validatePhoneNumber(countryCode: string, phoneNumber: string): Observable<boolean> {
    if (!countryCode || !phoneNumber) {
      return of(false)
    }

    const key = `${countryCode}-${phoneNumber}`
    if (this.validationCache.has(key)) {
      return of(this.validationCache.get(key))
    }

    return this.http
      .post<{ valid: boolean }>(`@webready/public/utils/phone_number/validate`, { countryCode, phoneNumber })
      .pipe(
        map((data) => data.valid),
        tap((value) => this.validationCache.set(key, value))
      )
  }
}
