import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import {
  addAttachmentsComplete,
  Attachment,
  AttachmentService,
  getAttachments,
  isRecurringConditionChanged,
  isSomething,
  SaveForm,
  selectAttachmentsById,
  toMoment,
  untilDestroy,
  UploadBoxOptions,
} from '@tokeet-frontend/tv3-platform'
import { ConfirmDialogService } from '@tokeet-frontend/tv3-platform'
import {
  AddHoldEvent,
  DeleteHoldEvent,
  ReceateRecurringHoldEvents,
  UpdateHoldEvent,
} from '@tv3/store/calendar/calendar.actions'
import { CalendarEvent } from '@tv3/store/calendar/calendar.model'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { Destroyable } from '@tokeet-frontend/tv3-platform'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import {
  createHoldEventForm,
  getHoldEventFormData,
  isHoldEventChanged,
  setHoldEventFormData,
} from '../hold-event-form/hold-event-form.component'
import { HoldEventType } from '@tv3/constants/hold-event-types'
import { AuthService } from '@tv3/services/auth.service'
import * as lodash from 'lodash'

export enum HoldDialogTabs {
  Details,
  Recurring,
  Notes,
  Attachments,
  Logs,
}

export interface HoldDialogInputs {
  defaults?: {
    title?: string
    start?: number
    end?: number
    rentalIds?: string[]
  }
  hold?: CalendarEvent
  deletable?: boolean
  convertable?: boolean
}

@Component({
  selector: 'app-hold-dialog',
  templateUrl: './hold-dialog.component.html',
  styleUrls: ['./hold-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class HoldDialogComponent extends Destroyable implements OnInit {
  tabs = HoldDialogTabs
  activeTab = HoldDialogTabs.Details

  uploadBoxOptions: UploadBoxOptions = {
    autoUpload: true,
    resetAfterFailed: true,
    maxSize: 1024 * 1024 * 10,
    maxUploads: 1,
    token: this.authService.token,
    guide:
      'You can only upload one file that is 10MB or smaller, only PNG, JPG, PDF, WORD and EXCEL file types are allowed',
  }

  form: FormGroup
  get hold() {
    return this.data.hold
  }
  attachments: Attachment[] = []

  isEdit = false

  constructor(
    public dialogRef: MatDialogRef<HoldDialogComponent>,
    private store: Store<fromRoot.State>,
    private inquirySharedDialogsService: InquirySharedDialogsService,
    private confirmDialog: ConfirmDialogService,
    private fb: FormBuilder,
    private authService: AuthService,
    private attachmentService: AttachmentService,
    @Inject(MAT_DIALOG_DATA) public data: HoldDialogInputs
  ) {
    super()

    this.isEdit = !!data.hold?.id
    this.form = createHoldEventForm(this.fb, this.isEdit)
    if (this.isEdit) {
      setHoldEventFormData(this.form, this.hold)
    } else if (isSomething(data.defaults)) {
      const defaults = data.defaults
      const isSameDay =
        defaults.start && defaults.end && toMoment(defaults.start).isSame(toMoment(defaults.end), 'date')

      if (isSameDay) {
        defaults.end = toMoment(defaults.end).add(1, 'days').unix()
      }

      setHoldEventFormData(this.form, {
        title: defaults.title,
        type: HoldEventType.Maintenance,
        start: defaults.start,
        end: defaults.end,
        rentalIds: defaults.rentalIds || [],
      })
    }
  }

  ngOnInit() {
    if (this.hold?.id) {
      this.store.dispatch(getAttachments({ ids: this.hold.attachments || [] }))
      this.store
        .pipe(select(selectAttachmentsById(this.hold.attachments || [])), untilDestroy(this))
        .subscribe((attachments) => (this.attachments = attachments))
    }
  }

  close() {
    this.dialogRef.close()
  }

  onUploadComplete(data: any[]) {
    const attachments = lodash.map(data, (d) => Attachment.deserialize(d))
    this.store.dispatch(addAttachmentsComplete({ attachments }))
    this.attachments = [...this.attachments, ...attachments]
    if (this.isEdit) {
      this.store.dispatch(
        UpdateHoldEvent({
          holdId: this.hold.id,
          payload: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    }
  }

  onDownloadAttachment(item: Attachment) {
    this.attachmentService.downloadFile(item).subscribe()
  }

  onDeleteAttachment(item: Attachment) {
    this.confirmDialog.confirm().subscribe(() => {
      this.attachments = lodash.filter(this.attachments, (i) => i.id !== item.id)
      this.store.dispatch(
        UpdateHoldEvent({
          holdId: this.hold.id,
          payload: { attachments: this.attachments.map((t) => t.id) },
        })
      )
    })
  }

  convert() {
    const formValues = this.form.getRawValue()

    this.inquirySharedDialogsService.openAddInquiry({
      title: 'Create Booking',
      start: formValues.start,
      end: formValues.end,
      isBooking: true,
      convertFromHold: this.data.hold.id,
      defaults: {
        arriveDate: formValues.start,
        departDate: formValues.end,
        rentalId: formValues.rental_id,
        color: formValues.color,
        guestId: formValues.guest_id,
      },
    })

    this.close()
  }

  delete() {
    if (this.hold.group_id) {
      this.confirmDialog
        .confirmExtra({
          title: `Delete Recurring Event`,
          body: `Do you want to delete this event only or all events?`,
          extra: 'All Events',
          defaultExtraValue: false,
        })
        .subscribe(({ isChecked }) => {
          this.store.dispatch(
            DeleteHoldEvent({ id: this.hold.id, group_id: isChecked ? this.hold.group_id : undefined })
          )
          this.close()
        })
    } else {
      this.confirmDialog.confirm().subscribe(() => {
        this.store.dispatch(DeleteHoldEvent({ id: this.hold.id }))
        this.close()
      })
    }
  }

  update() {
    const [payload] = getHoldEventFormData(
      this.form,
      this.attachments.map((t) => t.id)
    )
    const updateRecurringEvents = () => {
      this.confirmDialog
        .confirmExtra({
          title: `Edit Recurring Event`,
          body: `Do you want to update this event only or all events?`,
          extra: 'All Events',
          defaultExtraValue: false,
        })
        .subscribe(({ isChecked }) => {
          this.store.dispatch(
            UpdateHoldEvent({
              payload,
              holdId: this.hold.id,
              group_id: isChecked ? this.hold.group_id : undefined,
            })
          )
        })
    }

    const recreateRecurringEvents = () => {
      this.confirmDialog
        .confirm({
          title: `Edit Recurring Event`,
          body: `Do you want to recreate all recurring events?`,
        })
        .subscribe(() => {
          this.store.dispatch(
            ReceateRecurringHoldEvents({
              payloads: getHoldEventFormData(
                this.form,
                this.attachments.map((t) => t.id),
                true
              ),
              group_id: this.hold.group_id,
            })
          )
        })
    }

    if (this.hold.group_id) {
      if (isRecurringConditionChanged(this.hold.recurrenceRule, payload.recurrenceRule)) {
        recreateRecurringEvents()
      } else if (isHoldEventChanged(this.hold.serialize() as any, payload)) {
        updateRecurringEvents()
      }
    } else if (payload.repeat) {
      this.store.dispatch(
        AddHoldEvent({
          payloads: getHoldEventFormData(
            this.form,
            this.attachments.map((t) => t.id),
            true
          ),
          recurring: true,
          holdId: this.hold.id,
        })
      )
    } else {
      this.store.dispatch(UpdateHoldEvent({ payload, holdId: this.hold.id }))
    }
  }

  add() {
    const isRecurring = !!this.form.get('repeat').value
    const items = getHoldEventFormData(
      this.form,
      this.attachments.map((t) => t.id),
      true
    )

    this.store.dispatch(
      AddHoldEvent({
        payloads: items,
        recurring: isRecurring,
      })
    )
  }

  @SaveForm()
  onSave(form: FormGroup) {
    if (this.isEdit) {
      this.update()
    } else {
      this.add()
    }
    this.close()
  }
}
