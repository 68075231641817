import { keyBy, toUpper } from 'lodash'
import { IdNameObjectItem } from './id-name-item'

export interface Country extends IdNameObjectItem<string> {
  currency?: string | null
}

export const countries: Country[] = [
  {
    name: 'Afghanistan',
    id: 'AF',
    currency: null,
  },
  {
    name: 'Åland Islands',
    id: 'AX',
    currency: 'EUR',
  },
  {
    name: 'Albania',
    id: 'AL',
    currency: null,
  },
  {
    name: 'Algeria',
    id: 'DZ',
    currency: null,
  },
  {
    name: 'American Samoa',
    id: 'AS',
    currency: 'USD',
  },
  {
    name: 'Andorra',
    id: 'AD',
    currency: 'EUR',
  },
  {
    name: 'Angola',
    id: 'AO',
    currency: null,
  },
  {
    name: 'Anguilla',
    id: 'AI',
    currency: 'XCD',
  },
  {
    name: 'Antarctica',
    id: 'AQ',
    currency: null,
  },
  {
    name: 'Antigua and Barbuda',
    id: 'AG',
    currency: 'XCD',
  },
  {
    name: 'Argentina',
    id: 'AR',
    currency: 'ARS',
  },
  {
    name: 'Armenia',
    id: 'AM',
    currency: null,
  },
  {
    name: 'Aruba',
    id: 'AW',
    currency: null,
  },
  {
    name: 'Australia',
    id: 'AU',
    currency: 'AUD',
  },
  {
    name: 'Austria',
    id: 'AT',
    currency: 'EUR',
  },
  {
    name: 'Azerbaijan',
    id: 'AZ',
    currency: null,
  },
  {
    name: 'Bahamas',
    id: 'BS',
    currency: 'BSD',
  },
  {
    name: 'Bahrain',
    id: 'BH',
    currency: null,
  },
  {
    name: 'Bangladesh',
    id: 'BD',
    currency: null,
  },
  {
    name: 'Barbados',
    id: 'BB',
    currency: null,
  },
  {
    name: 'Belarus',
    id: 'BY',
    currency: null,
  },
  {
    name: 'Belgium',
    id: 'BE',
    currency: 'EUR',
  },
  {
    name: 'Belize',
    id: 'BZ',
    currency: null,
  },
  {
    name: 'Benin',
    id: 'BJ',
    currency: null,
  },
  {
    name: 'Bermuda',
    id: 'BM',
    currency: null,
  },
  {
    name: 'Bhutan',
    id: 'BT',
    currency: 'INR',
  },
  {
    name: 'Bolivia(Plurinational State of)',
    id: 'BO',
    currency: null,
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    id: 'BQ',
    currency: 'USD',
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 'BA',
    currency: null,
  },
  {
    name: 'Botswana',
    id: 'BW',
    currency: null,
  },
  {
    name: 'Bouvet Island',
    id: 'BV',
    currency: 'NOK',
  },
  {
    name: 'Brazil',
    id: 'BR',
    currency: 'BRL',
  },
  {
    name: 'British Indian Ocean Territory',
    id: 'IO',
    currency: 'USD',
  },
  {
    name: 'Brunei Darussalam',
    id: 'BN',
    currency: null,
  },
  {
    name: 'Bulgaria',
    id: 'BG',
    currency: 'BGN',
  },
  {
    name: 'Burkina Faso',
    id: 'BF',
    currency: null,
  },
  {
    name: 'Burundi',
    id: 'BI',
    currency: null,
  },
  {
    name: 'Cabo Verde',
    id: 'CV',
    currency: null,
  },
  {
    name: 'Cambodia',
    id: 'KH',
    currency: null,
  },
  {
    name: 'Cameroon',
    id: 'CM',
    currency: 'XAF',
  },
  {
    name: 'Canada',
    id: 'CA',
    currency: 'CAD',
  },
  {
    name: 'Cayman Islands',
    id: 'KY',
    currency: null,
  },
  {
    name: 'Central African Republic',
    id: 'CF',
    currency: 'XAF',
  },
  {
    name: 'Chad',
    id: 'TD',
    currency: 'XAF',
  },
  {
    name: 'Chile',
    id: 'CL',
    currency: 'CLP',
  },
  {
    name: 'China',
    id: 'CN',
    currency: 'CNY',
  },
  {
    name: 'Christmas Island',
    id: 'CX',
    currency: 'AUD',
  },
  {
    name: 'Cocos (Keeling) Islands',
    id: 'CC',
    currency: 'AUD',
  },
  {
    name: 'Colombia',
    id: 'CO',
    currency: 'COP',
  },
  {
    name: 'Comoros',
    id: 'KM',
    currency: null,
  },
  {
    name: 'Congo',
    id: 'CG',
    currency: 'XAF',
  },
  {
    name: 'Congo (Democratic Republic of the)',
    id: 'CD',
    currency: null,
  },
  {
    name: 'Cook Islands',
    id: 'CK',
    currency: 'NZD',
  },
  {
    name: 'Costa Rica',
    id: 'CR',
    currency: null,
  },
  {
    name: "Côte d'Ivoire",
    id: 'CI',
    currency: null,
  },
  {
    name: 'Croatia',
    id: 'HR',
    currency: 'HRK',
  },
  {
    name: 'Cuba',
    id: 'CU',
    currency: null,
  },
  {
    name: 'Curaçao',
    id: 'CW',
    currency: 'ANG',
  },
  {
    name: 'Cyprus',
    id: 'CY',
    currency: 'EUR',
  },
  {
    name: 'Czech Republic',
    id: 'CZ',
    currency: 'CZK',
  },
  {
    name: 'Denmark',
    id: 'DK',
    currency: 'DKK',
  },
  {
    name: 'Djibouti',
    id: 'DJ',
    currency: null,
  },
  {
    name: 'Dominica',
    id: 'DM',
    currency: 'XCD',
  },
  {
    name: 'Dominican Republic',
    id: 'DO',
    currency: null,
  },
  {
    name: 'Ecuador',
    id: 'EC',
    currency: 'USD',
  },
  {
    name: 'Egypt',
    id: 'EG',
    currency: null,
  },
  {
    name: 'El Salvador',
    id: 'SV',
    currency: 'USD',
  },
  {
    name: 'Equatorial Guinea',
    id: 'GQ',
    currency: 'XAF',
  },
  {
    name: 'Eritrea',
    id: 'ER',
    currency: null,
  },
  {
    name: 'Estonia',
    id: 'EE',
    currency: 'EUR',
  },
  {
    name: 'Ethiopia',
    id: 'ET',
    currency: null,
  },
  {
    name: 'Falkland Islands (Malvinas)',
    id: 'FK',
    currency: null,
  },
  {
    name: 'Faroe Islands',
    id: 'FO',
    currency: 'DKK',
  },
  {
    name: 'Fiji',
    id: 'FJ',
    currency: 'FJD',
  },
  {
    name: 'Finland',
    id: 'FI',
    currency: 'EUR',
  },
  {
    name: 'France',
    id: 'FR',
    currency: 'EUR',
  },
  {
    name: 'French Guiana',
    id: 'GF',
    currency: 'EUR',
  },
  {
    name: 'French Polynesia',
    id: 'PF',
    currency: 'XPF',
  },
  {
    name: 'French Southern Territories',
    id: 'TF',
    currency: 'EUR',
  },
  {
    name: 'Gabon',
    id: 'GA',
    currency: 'XAF',
  },
  {
    name: 'Gambia',
    id: 'GM',
    currency: null,
  },
  {
    name: 'Georgia',
    id: 'GE',
    currency: null,
  },
  {
    name: 'Germany',
    id: 'DE',
    currency: 'EUR',
  },
  {
    name: 'Ghana',
    id: 'GH',
    currency: 'GHS',
  },
  {
    name: 'Gibraltar',
    id: 'GI',
    currency: null,
  },
  {
    name: 'Greece',
    id: 'GR',
    currency: 'EUR',
  },
  {
    name: 'Greenland',
    id: 'GL',
    currency: 'DKK',
  },
  {
    name: 'Grenada',
    id: 'GD',
    currency: 'XCD',
  },
  {
    name: 'Guadeloupe',
    id: 'GP',
    currency: 'EUR',
  },
  {
    name: 'Guam',
    id: 'GU',
    currency: 'USD',
  },
  {
    name: 'Guatemala',
    id: 'GT',
    currency: 'GTQ',
  },
  {
    name: 'Guernsey',
    id: 'GG',
    currency: null,
  },
  {
    name: 'Guinea',
    id: 'GN',
    currency: null,
  },
  {
    name: 'Guinea-Bissau',
    id: 'GW',
    currency: null,
  },
  {
    name: 'Guyana',
    id: 'GY',
    currency: null,
  },
  {
    name: 'Haiti',
    id: 'HT',
    currency: null,
  },
  {
    name: 'Heard Island and McDonald Islands',
    id: 'HM',
    currency: 'AUD',
  },
  {
    name: 'Holy See',
    id: 'VA',
    currency: 'EUR',
  },
  {
    name: 'Honduras',
    id: 'HN',
    currency: 'HNL',
  },
  {
    name: 'Hong Kong',
    id: 'HK',
    currency: 'HKD',
  },
  {
    name: 'Hungary',
    id: 'HU',
    currency: 'HUF',
  },
  {
    name: 'Iceland',
    id: 'IS',
    currency: 'ISK',
  },
  {
    name: 'India',
    id: 'IN',
    currency: 'INR',
  },
  {
    name: 'Indonesia',
    id: 'ID',
    currency: 'IDR',
  },
  {
    name: 'Iran (Islamic Republic of)',
    id: 'IR',
    currency: null,
  },
  {
    name: 'Iraq',
    id: 'IQ',
    currency: null,
  },
  {
    name: 'Ireland',
    id: 'IE',
    currency: 'EUR',
  },
  {
    name: 'Isle of Man',
    id: 'IM',
    currency: 'GBP',
  },
  {
    name: 'Israel',
    id: 'IL',
    currency: 'ILS',
  },
  {
    name: 'Italy',
    id: 'IT',
    currency: 'EUR',
  },
  {
    name: 'Jamaica',
    id: 'JM',
    currency: 'JMD',
  },
  {
    name: 'Japan',
    id: 'JP',
    currency: 'JPY',
  },
  {
    name: 'Jersey',
    id: 'JE',
    currency: 'GBP',
  },
  {
    name: 'Jordan',
    id: 'JO',
    currency: null,
  },
  {
    name: 'Kazakhstan',
    id: 'KZ',
    currency: null,
  },
  {
    name: 'Kenya',
    id: 'KE',
    currency: null,
  },
  {
    name: 'Kiribati',
    id: 'KI',
    currency: 'AUD',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    id: 'KP',
    currency: null,
  },
  {
    name: 'Korea (Republic of)',
    id: 'KR',
    currency: 'KRW',
  },
  {
    name: 'Kuwait',
    id: 'KW',
    currency: null,
  },
  {
    name: 'Kyrgyzstan',
    id: 'KG',
    currency: null,
  },
  {
    name: "Lao People's Democratic Republic",
    id: 'LA',
    currency: 'LAK',
  },
  {
    name: 'Latvia',
    id: 'LV',
    currency: 'EUR',
  },
  {
    name: 'Lebanon',
    id: 'LB',
    currency: null,
  },
  {
    name: 'Lesotho',
    id: 'LS',
    currency: null,
  },
  {
    name: 'Liberia',
    id: 'LR',
    currency: null,
  },
  {
    name: 'Libya',
    id: 'LY',
    currency: null,
  },
  {
    name: 'Liechtenstein',
    id: 'LI',
    currency: 'CHF',
  },
  {
    name: 'Lithuania',
    id: 'LT',
    currency: 'EUR',
  },
  {
    name: 'Luxembourg',
    id: 'LU',
    currency: 'EUR',
  },
  {
    name: 'Macao',
    id: 'MO',
    currency: null,
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    id: 'MK',
    currency: null,
  },
  {
    name: 'Madagascar',
    id: 'MG',
    currency: null,
  },
  {
    name: 'Malawi',
    id: 'MW',
    currency: null,
  },
  {
    name: 'Malaysia',
    id: 'MY',
    currency: 'MYR',
  },
  {
    name: 'Maldives',
    id: 'MV',
    currency: null,
  },
  {
    name: 'Mali',
    id: 'ML',
    currency: null,
  },
  {
    name: 'Malta',
    id: 'MT',
    currency: 'EUR',
  },
  {
    name: 'Marshall Islands',
    id: 'MH',
    currency: 'USD',
  },
  {
    name: 'Martinique',
    id: 'MQ',
    currency: 'EUR',
  },
  {
    name: 'Mauritania',
    id: 'MR',
    currency: null,
  },
  {
    name: 'Mauritius',
    id: 'MU',
    currency: null,
  },
  {
    name: 'Mayotte',
    id: 'YT',
    currency: 'EUR',
  },
  {
    name: 'Mexico',
    id: 'MX',
    currency: 'MXN',
  },
  {
    name: 'Micronesia (Federated States of)',
    id: 'FM',
    currency: 'USD',
  },
  {
    name: 'Moldova (Republic of)',
    id: 'MD',
    currency: 'MDL',
  },
  {
    name: 'Monaco',
    id: 'MC',
    currency: 'EUR',
  },
  {
    name: 'Mongolia',
    id: 'MN',
    currency: 'MNT',
  },
  {
    name: 'Montenegro',
    id: 'ME',
    currency: 'EUR',
  },
  {
    name: 'Montserrat',
    id: 'MS',
    currency: 'XCD',
  },
  {
    name: 'Morocco',
    id: 'MA',
    currency: 'MAD',
  },
  {
    name: 'Mozambique',
    id: 'MZ',
    currency: null,
  },
  {
    name: 'Myanmar',
    id: 'MM',
    currency: 'MMK',
  },
  {
    name: 'Namibia',
    id: 'NA',
    currency: null,
  },
  {
    name: 'Nauru',
    id: 'NR',
    currency: 'AUD',
  },
  {
    name: 'Nepal',
    id: 'NP',
    currency: null,
  },
  {
    name: 'Netherlands',
    id: 'NL',
    currency: 'EUR',
  },
  {
    name: 'New Caledonia',
    id: 'NC',
    currency: 'XPF',
  },
  {
    name: 'New Zealand',
    id: 'NZ',
    currency: 'NZD',
  },
  {
    name: 'Nicaragua',
    id: 'NI',
    currency: null,
  },
  {
    name: 'Niger',
    id: 'NE',
    currency: null,
  },
  {
    name: 'Nigeria',
    id: 'NG',
    currency: 'NGN',
  },
  {
    name: 'Niue',
    id: 'NU',
    currency: 'NZD',
  },
  {
    name: 'Norfolk Island',
    id: 'NF',
    currency: 'AUD',
  },
  {
    name: 'Northern Mariana Islands',
    id: 'MP',
    currency: 'USD',
  },
  {
    name: 'Norway',
    id: 'NO',
    currency: 'NOK',
  },
  {
    name: 'Oman',
    id: 'OM',
    currency: 'OMR',
  },
  {
    name: 'Pakistan',
    id: 'PK',
    currency: 'PKR',
  },
  {
    name: 'Palau',
    id: 'PW',
    currency: 'USD',
  },
  {
    name: 'Palestine, State of',
    id: 'PS',
    currency: null,
  },
  {
    name: 'Panama',
    id: 'PA',
    currency: 'PAB',
  },
  {
    name: 'Papua New Guinea',
    id: 'PG',
    currency: null,
  },
  {
    name: 'Paraguay',
    id: 'PY',
    currency: 'PYG',
  },
  {
    name: 'Peru',
    id: 'PE',
    currency: 'PEN',
  },
  {
    name: 'Philippines',
    id: 'PH',
    currency: 'PHP',
  },
  {
    name: 'Pitcairn',
    id: 'PN',
    currency: 'NZD',
  },
  {
    name: 'Poland',
    id: 'PL',
    currency: 'PLN',
  },
  {
    name: 'Portugal',
    id: 'PT',
    currency: 'EUR',
  },
  {
    name: 'Puerto Rico',
    id: 'PR',
    currency: 'USD',
  },
  {
    name: 'Qatar',
    id: 'QA',
    currency: null,
  },
  {
    name: 'Réunion',
    id: 'RE',
    currency: 'EUR',
  },
  {
    name: 'Romania',
    id: 'RO',
    currency: 'RON',
  },
  {
    name: 'Russian Federation',
    id: 'RU',
    currency: 'RUB',
  },
  {
    name: 'Rwanda',
    id: 'RW',
    currency: null,
  },
  {
    name: 'Saint Barthélemy',
    id: 'BL',
    currency: 'EUR',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    id: 'SH',
    currency: null,
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 'KN',
    currency: 'XCD',
  },
  {
    name: 'Saint Lucia',
    id: 'LC',
    currency: 'XCD',
  },
  {
    name: 'Saint Martin (French part)',
    id: 'MF',
    currency: 'EUR',
  },
  {
    name: 'Saint Pierre and Miquelon',
    id: 'PM',
    currency: 'EUR',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: 'VC',
    currency: 'XCD',
  },
  {
    name: 'Samoa',
    id: 'WS',
    currency: 'EUR',
  },
  {
    name: 'San Marino',
    id: 'SM',
    currency: 'EUR',
  },
  {
    name: 'Sao Tome and Principe',
    id: 'ST',
    currency: null,
  },
  {
    name: 'Saudi Arabia',
    id: 'SA',
    currency: null,
  },
  {
    name: 'Senegal',
    id: 'SN',
    currency: null,
  },
  {
    name: 'Serbia',
    id: 'RS',
    currency: 'RSD',
  },
  {
    name: 'Seychelles',
    id: 'SC',
    currency: null,
  },
  {
    name: 'Sierra Leone',
    id: 'SL',
    currency: null,
  },
  {
    name: 'Singapore',
    id: 'SG',
    currency: 'SGD',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    id: 'SX',
    currency: 'ANG',
  },
  {
    name: 'Slovakia',
    id: 'SK',
    currency: 'EUR',
  },
  {
    name: 'Slovenia',
    id: 'SI',
    currency: 'EUR',
  },
  {
    name: 'Solomon Islands',
    id: 'SB',
    currency: null,
  },
  {
    name: 'Somalia',
    id: 'SO',
    currency: null,
  },
  {
    name: 'South Africa',
    id: 'ZA',
    currency: 'ZAR',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    id: 'GS',
    currency: 'GBP',
  },
  {
    name: 'South Sudan',
    id: 'SS',
    currency: null,
  },
  {
    name: 'Spain',
    id: 'ES',
    currency: 'EUR',
  },
  {
    name: 'Sri Lanka',
    id: 'LK',
    currency: 'LKR',
  },
  {
    name: 'Sudan',
    id: 'SD',
    currency: null,
  },
  {
    name: 'Suriname',
    id: 'SR',
    currency: null,
  },
  {
    name: 'Svalbard and Jan Mayen',
    id: 'SJ',
    currency: 'NOK',
  },
  {
    name: 'Swaziland',
    id: 'SZ',
    currency: null,
  },
  {
    name: 'Sweden',
    id: 'SE',
    currency: 'SEK',
  },
  {
    name: 'Switzerland',
    id: 'CH',
    currency: 'CHF',
  },
  {
    name: 'Syrian Arab Republic',
    id: 'SY',
    currency: null,
  },
  {
    name: 'Taiwan, Province of China',
    id: 'TW',
    currency: null,
  },
  {
    name: 'Tajikistan',
    id: 'TJ',
    currency: null,
  },
  {
    name: 'Tanzania, United Republic of',
    id: 'TZ',
    currency: null,
  },
  {
    name: 'Thailand',
    id: 'TH',
    currency: 'THB',
  },
  {
    name: 'Timor-Leste',
    id: 'TL',
    currency: 'USD',
  },
  {
    name: 'Togo',
    id: 'TG',
    currency: null,
  },
  {
    name: 'Tokelau',
    id: 'TK',
    currency: 'NZD',
  },
  {
    name: 'Tonga',
    id: 'TO',
    currency: null,
  },
  {
    name: 'Trinidad and Tobago',
    id: 'TT',
    currency: 'TTD',
  },
  {
    name: 'Tunisia',
    id: 'TN',
    currency: 'TND',
  },
  {
    name: 'Turkey',
    id: 'TR',
    currency: 'TRY',
  },
  {
    name: 'Turkmenistan',
    id: 'TM',
    currency: null,
  },
  {
    name: 'Turks and Caicos Islands',
    id: 'TC',
    currency: 'USD',
  },
  {
    name: 'Tuvalu',
    id: 'TV',
    currency: 'AUD',
  },
  {
    name: 'Uganda',
    id: 'UG',
    currency: null,
  },
  {
    name: 'Ukraine',
    id: 'UA',
    currency: 'UAH',
  },
  {
    name: 'United Arab Emirates',
    id: 'AE',
    currency: 'AED',
  },
  {
    name: 'United Kingdom',
    id: 'GB',
    currency: 'GBP',
  },
  {
    name: 'United States of America',
    id: 'US',
    currency: 'USD',
  },
  {
    name: 'United States Minor Outlying Islands',
    id: 'UM',
    currency: 'USD',
  },
  {
    name: 'Uruguay',
    id: 'UY',
    currency: null,
  },
  {
    name: 'Uzbekistan',
    id: 'UZ',
    currency: null,
  },
  {
    name: 'Vanuatu',
    id: 'VU',
    currency: null,
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    id: 'VE',
    currency: 'VEF',
  },
  {
    name: 'Viet Nam',
    id: 'VN',
    currency: 'VND',
  },
  {
    name: 'Virgin Islands (British)',
    id: 'VG',
    currency: 'USD',
  },
  {
    name: 'Virgin Islands (U.S.)',
    id: 'VI',
    currency: 'USD',
  },
  {
    name: 'Wallis and Futuna',
    id: 'WF',
    currency: 'XPF',
  },
  {
    name: 'Western Sahara',
    id: 'EH',
    currency: 'MAD',
  },
  {
    name: 'Yemen',
    id: 'YE',
    currency: null,
  },
  {
    name: 'Zambia',
    id: 'ZM',
    currency: null,
  },
  {
    name: 'Zimbabwe',
    id: 'ZW',
    currency: 'USD',
  },
]

const countriesByCode: { [key: string]: Country } = keyBy(countries, 'id')
const countriesByName: { [key: string]: Country } = keyBy(countries, 'name')

export function getCountryCode(country: string): string {
  return countriesByName[country] && countriesByName[country].id
}

export function getCountryByCode(code: string): string {
  return countriesByCode[code] && countriesByCode[toUpper(code)].name
}
