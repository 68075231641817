import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog'
import { WebsiteOverlayComponent } from '@tv3/containers/websites/overlays/website-overlay.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { DialogService } from '@tokeet-frontend/tv3-platform'

@Injectable({
  providedIn: SharedModule,
})
export class WebsiteOverlayService {
  constructor(private dialog: DialogService) {}

  public openSide(websiteId: string): MatDialogRef<WebsiteOverlayComponent> {
    const defaultConfig: MatDialogConfig = {
      panelClass: ['drawer-full-80'],
      data: { websiteId },
    }

    const dialogRef = this.dialog.openDrawer(WebsiteOverlayComponent, defaultConfig)

    return dialogRef
  }
}
