/**
 * WARNING: You need to inject NgxPermissionsService and name it as "permissions"
 */
export function ExpectPermission(permission: string) {
  return function (target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
    let method = descriptor.value

    descriptor.value = async function (...params) {
      const hasPermission = await this.permissions.hasPermission(permission)

      if (!hasPermission) {
        return method.apply(this, params)
      } else {
        return null
      }
    }
  }
}
