<div class="modal-header">
  <div class="d-flex align-items-center gap-3 flex-1">
    <h6 class="modal-title d-flex align-items-center gap-2">
      <i class="far fa-user"></i>
      Multi-Factor Authentication (MFA) Configuration
      <app-page-help videoId="-" articleId="-"></app-page-help>
    </h6>
    <div class="flex-fill"></div>
  </div>
  <a (click)="close()" class="cstm-close">
    <span class="far fa-times"></span>
  </a>
</div>

<ng-container [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="steps.Settings" [ngTemplateOutlet]="mfaSettings"></ng-container>
  <ng-container *ngSwitchCase="steps.TOTPRegistration" [ngTemplateOutlet]="totpRegister"></ng-container>
  <ng-container *ngSwitchCase="steps.CodeVerification" [ngTemplateOutlet]="codeVerify"></ng-container>
</ng-container>

<ng-template #mfaSettings>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="d-flex gap-3">
        <div class="text-nowrap mt-4">
          <app-form-checkbox [checkedValue]="1" [uncheckedValue]="0" formControlName="status" class="mt-2"
            >Enable MFA</app-form-checkbox
          >
        </div>
        <mat-form-field2 class="mat-block">
          <mat-select formControlName="delivery" placeholder="Delivery Method">
            <mat-option [value]="item.id" *ngFor="let item of mfaDeliveryMethodOptions">{{ item.label }}</mat-option>
          </mat-select>
        </mat-form-field2>
        <button
          (click)="onResetTotp()"
          type="button"
          class="btn btn-secondary-danger align-input-row-end"
          matTooltip="You need to remove your account from the Authenticator App as well after deregister your device from here."
          *ngIf="isTotpSelected && (isTotpDeviceRegistered$ | async)"
        >
          <i class="cs-icon cs-icon-unlink"></i>
          Deregister auth device
        </button>
      </div>
      <div class="alert alert-secondary-info">
        <div class="alert-content">
          <p>
            Multi-Factor Authentication (MFA) adds an extra layer of security to your AdvanceCM account by requiring not
            only your password but also a second form of verification, such as a code sent to your email, mobile device
            or generated by an authenticator app.
          </p>
          <p>
            This ensures that even if your password is compromised, unauthorized access is prevented. Administrators can
            enforce MFA across the entire account, while individual users can choose their preferred method of
            verification. With MFA enabled, your account is better protected against fraudulent activities, ensuring
            your data and transactions remain secure.
          </p>
          <div class="text-bold">We highly recommend SMS, WhatsApp, or OTP delivery methods.</div>
        </div>
      </div>
      <div class="alert alert-secondary-warning" *ngIf="isAccountMFAEnabled$ | async">
        <div class="alert-content">
          MFA has been enabled for all users in this account by an administrator. Once account level MFA is enabled your
          user level MFA checkbox is ignored. However, you may still select your delivery method.
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      (click)="onClearBypassMFA()"
      type="button"
      class="btn btn-secondary-primary"
      *ngIf="isBypassMFA"
      matTooltip="You are skipping MFA on this machine."
    >
      <i class="far fa-ban"></i>
      Don't Skip MFA
    </button>

    <div class="flex-fill"></div>
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Cancel
    </button>

    <button (click)="onSave(form)" type="button" class="btn btn-secondary-info" [disabled]="isLoading">
      <i class="cs-icon cs-icon-save" *ngIf="!isLoading"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Save
    </button>
  </div>
</ng-template>

<ng-template #totpRegister>
  <div class="modal-body">
    <qrcode
      [qrdata]="totpRegisterURL"
      [width]="256"
      [errorCorrectionLevel]="'M'"
      class="d-flex justify-content-center"
    ></qrcode>
    <ol class="alert alert-secondary-info">
      <li>Open your Authenticator App.</li>
      <li>Add an account within the app and scan the QR code above.</li>
      <li>Enter the 6 digits code generated by your Authenticator App.</li>
    </ol>
    <mat-form-field2 class="mat-block">
      <input
        matInput
        type="text"
        inputmode="numeric"
        autocomplete="one-time-code"
        placeholder="Digit Code"
        [formControl]="codeCtrl"
      />
      <mat-error *ngIf="codeCtrl.touched && codeCtrl.hasError('required')">
        Code is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="codeCtrl.hasError('digit')"> Code should be <strong>digits</strong> </mat-error>
    </mat-form-field2>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Cancel
    </button>
    <button (click)="onVerifyTotpCode(codeCtrl)" type="button" class="btn btn-secondary-info" [disabled]="isLoading">
      <i class="cs-icon cs-icon-check" *ngIf="!isLoading"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Verify
    </button>
  </div>
</ng-template>

<ng-template #codeVerify>
  <div class="modal-body">
    <p class="text-muted">
      <ng-container [ngSwitch]="selectedDeliveryMethod">
        <ng-container *ngSwitchCase="MFADeliveryMethod.SMS">
          Please check your mobile device <span class="text-dark">****{{ recipientAddress }}</span> and enter the code
          below.
        </ng-container>
        <ng-container *ngSwitchCase="MFADeliveryMethod.WhatsApp">
          Please open your WhatsApp <span class="text-dark">****{{ recipientAddress }}</span> and enter the code below.
        </ng-container>
        <ng-container *ngSwitchCase="MFADeliveryMethod.TOTP">
          Please enter an OTP code from your authentication application.
        </ng-container>
        <ng-container *ngSwitchDefault>
          Please check your email <span class="text-dark">{{ recipientAddress }}</span> and enter the code below.
        </ng-container>
      </ng-container>
    </p>
    <mat-form-field2 class="mat-block">
      <input
        matInput
        type="text"
        inputmode="numeric"
        autocomplete="one-time-code"
        placeholder="MFA Code"
        [formControl]="codeCtrl"
      />
      <mat-error *ngIf="codeCtrl.touched && codeCtrl.hasError('required')">
        Code is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="codeCtrl.hasError('digit')"> Code should be <strong>digits</strong> </mat-error>
    </mat-form-field2>
  </div>
  <div class="modal-footer">
    <button (click)="onResendCode()" type="button" class="btn btn-secondary-warning" [disabled]="isLoading">
      <i class="cs-icon cs-icon-send" *ngIf="!isLoading"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>

      Resend Code
    </button>
    <div class="flex-fill"></div>
    <button (click)="close()" type="button" class="btn btn-light">
      <i class="far fa-times"></i>
      Cancel
    </button>
    <button (click)="onVerifyCode(codeCtrl)" type="button" class="btn btn-secondary-info" [disabled]="isLoading">
      <i class="cs-icon cs-icon-check" *ngIf="!isLoading"></i>
      <i class="far fa-spin fa-spinner" *ngIf="isLoading"></i>
      Verify
    </button>
  </div>
</ng-template>
