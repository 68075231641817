<div class="d-flex gap-3">
  <div class="container-box shadow-none flex-fill text-center py-5">
    <div class="w-75 m-auto">
      <img src="/assets/images/channel-wizards/complete-icon.png" alt="" height="50" />
      <h6 class="mt-2">Congratulations!</h6>
      <p>
        Now that you've connected AdvanceCM to your Trip.com account, you can link your individual listings to the
        appropriate AdvanceCM rentals.
      </p>
      <p>
        On the next screen, you'll see all your visible Trip.com listings. To complete the connection, select one of the
        listings under mapped rentals and follow the steps provided.
      </p>
      <div class="alert alert-secondary-warning2 align-items-start text-left">
        <i class="cs-icon cs-icon-alert-triangle2 alert-icon"></i>
        <div class="alert-content">
          <b>Note:</b> If you don't see your listings under mapped rentals, allow some time for Trip.com to process your
          request, then refresh the room list using the Actions button.
        </div>
      </div>
      <div class="d-flex gap-3 align-items-center justify-content-center mt-4">
        <button class="btn btn-secondary-info" (click)="reset.emit()">
          <i class="far fa-link"></i> Connect another account
        </button>
        <button class="btn btn-secondary-primary" (click)="channel.emit()">
          <i class="cs-icon cs-icon-list-square"></i> Channel Details
        </button>
      </div>
    </div>
  </div>
  <div class="channel-wizard-right-wrapper rounded px-4 py-5">
    <img src="/assets/images/channel-wizards/trip/complete.png" alt="" class="w-100" />
  </div>
</div>
<div class="mt-3 d-flex align-items-center justify-content-end">
  <button class="btn btn-secondary-info" (click)="done.emit()">
    <i class="cs-icon cs-icon-tasks"></i>
    Done
  </button>
</div>
