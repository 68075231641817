import { NgModule } from '@angular/core'
import { BookingDetailsComponent } from './sidebar/booking-details/booking-details.component'
import { MessageDialogComponent } from '../dialogs/message/message-dialog.component'
import { AddNoteDialogComponent } from '../dialogs/notes/note/add-note-dialog/add-note-dialog.component'
import { MessageDetailsComponent } from '../dialogs/message/message-details/message-details.component'
import { AddInquiryDialogComponent } from '../dialogs/shared/add-inquiry/add-inquiry-dialog.component'
import { SidebarExpansionPanelComponent } from './sidebar/sidebar-expansion-panel/sidebar-expansion-panel.component'
import { EditNoteDialogComponent } from '../dialogs/notes/note/edit-note-dialog/edit-note-dialog.component'
import { NotesDialogComponent } from '../dialogs/notes/notes/notes-dialog.component'
import { BookingChargesComponent } from './sidebar/booking-charges/booking-charges.component'
import { RelatedInvoicesComponent } from './sidebar/related-invoices/related-invoices.component'
import { OccupiedDatesComponent } from './sidebar/occupied-dates/occupied-dates.component'
import { EditGuestDialogComponent } from '../dialogs/shared/edit-guest/edit-guest-dialog.component'
import { SidebarNotesComponent } from './sidebar/sidebar-notes/sidebar-notes.component'
import { CanPreApprovePipe } from '../pipes/can-pre-approve.pipe'
import { CanDenyPipe } from '../pipes/can-deny.pipe'
import { IsThreadCheckedPipe } from '../pipes/is-thread-checked.pipe'
import { UpdateInquiryDetailsDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/update-details/update-inquiry-details-dialog.component'
import { UpdateQuoteDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/update-quote/update-quote-dialog.component'
import { EditAdditionalGuestsComponent } from '@tv3/containers/inquiries/dialogs/shared/edit-additional-guests/edit-additional-guests.component'
import { SharedModule } from '@tv3/shared/shared.module'
import { CommonModule } from '@angular/common'
import { EditorModule } from '@tinymce/tinymce-angular'
import { MessagesSharedModule } from '@tv3/containers/messages/messages-shared.module'
import { CalendarSharedModule } from '@tv3/containers/calendar/shared/calendar-shared.module'
import { MessageSidePanelService } from '@tv3/containers/inquiries/dialogs/message/message-side-panel.service'
import { NotesSidePanelService } from '@tv3/containers/inquiries/dialogs/notes/notes/notes-side-panel.service'
import { ViewBillingDialogComponent } from '@tv3/containers/inquiries/dialogs/shared/view-billing/view-billing-dialog.component'
import { ImportInquiriesDialogComponent } from '@tv3/containers/inquiries/dialogs/scoped/import/import-inquiries-dialog.component'
import { ExportInquiriesReportDialogComponent } from '@tv3/containers/inquiries/dialogs/scoped/export-report/export-inquiries-report-dialog.component'
import { AddNoteDialogService } from '@tv3/containers/inquiries/dialogs/notes/note/add-note-dialog/add-note-dialog.service'
import { CheckInStatusBarComponent } from '@tv3/containers/inquiries/inbox/check-in-status-bar/check-in-status-bar.component'
import { DefaultInboxComponent } from '@tv3/containers/inquiries/shared/default-inbox/default-inbox.component'
import { InquiriesActionBtnComponent } from '@tv3/containers/inquiries/shared/inquiries-action-btn/inquiries-action-btn.component'
import { MessageDetailsDialogService } from '@tv3/containers/inquiries/dialogs/message/message-details/message-details.dialog.service'
import { EditNoteDialogService } from '@tv3/containers/inquiries/dialogs/notes/note/edit-note-dialog/edit-note-dialog.service'
import { InquirySharedDialogsService } from '@tv3/containers/inquiries/dialogs/inquiry-shared-dialogs.service'
import { SelectInvoiceTemplateDialogComponent } from '@tv3/containers/invoices/templates/select-invoice-template/select-invoice-template.component'
import { SelectInvoiceTemplateDialogService } from '@tv3/containers/invoices/templates/select-invoice-template/select-invoice-template.service'
import { DiscountsSharedModule } from '@tv3/containers/discounts/discounts-shared.module'
import { BookingFormulaStoreModule } from '@tokeet-frontend/booking-formula'
import { UpdateQuoteInternalComponent } from '@tv3/containers/inquiries/dialogs/shared/update-quote/update-quote-internal/update-quote-internal.component'
import { UpdateQuoteExternalComponent } from '@tv3/containers/inquiries/dialogs/shared/update-quote/update-quote-external/update-quote-external.component'
import { GuestsSharedModule } from '@tv3/containers/guests/guests-shared.module'
import { CancelAirbnbBookingDialogComponent } from '../overlays/cancel-airbnb-booking-dialog/cancel-airbnb-booking-dialog.component'
import { DeclineAirbnbBookingDialogComponent } from '../overlays/decline-airbnb-booking-dialog/decline-airbnb-booking-dialog.component'
import { CancelAirbnbBookingDialogService } from '../overlays/cancel-airbnb-booking-dialog/cancel-airbnb-booking-dialog.service'
import { DeclineAirbnbBookingDialogService } from '../overlays/decline-airbnb-booking-dialog/decline-aibrnb-booking-dialog.service'
import { AirbnbReservationAlterationDialogService } from '../overlays/airbnb-reservation-alteration-dialog/airbnb-reservation-alteration-dialog.service'
import { AirbnbReservationAlterationDialogComponent } from '../overlays/airbnb-reservation-alteration-dialog/airbnb-reservation-alteration-dialog.component'
import { BookingScheduleDatesComponent } from '@tv3/containers/inquiries/shared/booking-schedule-dates/booking-schedule-dates.component'
import { BookingTotalPipe } from '@tv3/containers/inquiries/pipes/booking-total.pipe'
import { InquiriesListService } from '@tv3/containers/inquiries/shared/inquiries-list.service'
import { MoveDirective } from '@tv3/containers/inquiries/inbox/inbox-resize-handler.directive'
import { SmartDevicesSharedModule } from '@tv3/containers/smart-devices/smart-devices-shared.module'

const modules = [
  SharedModule,
  CommonModule,
  EditorModule,
  MessagesSharedModule,
  CalendarSharedModule,
  DiscountsSharedModule,
  BookingFormulaStoreModule,
  GuestsSharedModule,
  SmartDevicesSharedModule,
]

const components = [
  OccupiedDatesComponent,
  BookingDetailsComponent,
  BookingChargesComponent,
  RelatedInvoicesComponent,
  SidebarExpansionPanelComponent,
  SidebarNotesComponent,
  CheckInStatusBarComponent,
  DefaultInboxComponent,
  InquiriesActionBtnComponent,
  UpdateQuoteInternalComponent,
  UpdateQuoteExternalComponent,
  BookingScheduleDatesComponent,
  MoveDirective,
]

const pipes = [CanPreApprovePipe, CanDenyPipe, IsThreadCheckedPipe, BookingTotalPipe]

const dialogs = [
  MessageDialogComponent,
  NotesDialogComponent,
  AddNoteDialogComponent,
  EditNoteDialogComponent,
  MessageDetailsComponent,
  AddInquiryDialogComponent,
  EditGuestDialogComponent,
  EditAdditionalGuestsComponent,
  UpdateInquiryDetailsDialogComponent,
  UpdateQuoteDialogComponent,
  ViewBillingDialogComponent,
  ImportInquiriesDialogComponent,
  ExportInquiriesReportDialogComponent,
  SelectInvoiceTemplateDialogComponent,
  DeclineAirbnbBookingDialogComponent,
  CancelAirbnbBookingDialogComponent,
  AirbnbReservationAlterationDialogComponent,
]

const services = [
  MessageSidePanelService,
  NotesSidePanelService,
  AddNoteDialogService,
  MessageDetailsDialogService,
  EditNoteDialogService,
  InquirySharedDialogsService,
  SelectInvoiceTemplateDialogService,
  DeclineAirbnbBookingDialogService,
  CancelAirbnbBookingDialogService,
  AirbnbReservationAlterationDialogService,
  InquiriesListService,
]

@NgModule({
  imports: [...modules],
  declarations: [...pipes, ...components, ...dialogs],
  exports: [...components, ...dialogs, ...pipes, ...modules],
  providers: [...services],
})
export class InquiriesSharedModule {}
