import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { CtripChannelService } from './service'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'
import { ConnectCtrip, ConnectCtripSuccess, GetCtripStatus, GetCtripStatusSuccess } from './actions'

@Injectable()
export class CtripChannelEffects {
  @Effect()
  getStatus$ = this.actions$.pipe(
    ofType(GetCtripStatus),
    switchMap(() =>
      this.channelsService.getStatus().pipe(
        map((status) => GetCtripStatusSuccess({ status })),
        catchError((err) => of(ActionFailed({ error: err })))
      )
    )
  )

  @Effect()
  connect$ = this.actions$.pipe(
    ofType(ConnectCtrip),
    concatMap(({ hotelId }) =>
      this.channelsService.connect(hotelId).pipe(
        tap(() => this.toaster.success('Trip connected successfully')),
        map((status) => ConnectCtripSuccess({ status })),
        catchError((err) => of(ActionFailed({ error: err })))
      )
    )
  )

  constructor(private actions$: Actions, private channelsService: CtripChannelService, private toaster: Toaster) {}
}
