import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { delay } from 'rxjs/operators'
import { Destroyable, untilDestroy } from '../../rx-operators'
import { UserStorage } from '../../storage'

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent extends Destroyable implements OnInit {
  @Input() filterGroup: string

  @Input() inputTooltip = ''
  @Input() clearTooltip = ''
  @Input() inputPlaceholder = 'Search here...'

  @Output() search = new EventEmitter<string>()

  searchCtrl = new FormControl('')
  @Input() set ctrl(ctrl: FormControl) {
    this.searchCtrl = ctrl
  }

  get isEmpty() {
    return !this.searchCtrl.value
  }

  constructor(private storage: UserStorage) {
    super()
  }

  ngOnInit() {
    if (this.filterGroup) {
      const storageKey = `SEARCH::${this.filterGroup}`
      const initialTerm = this.storage.get(storageKey, '')

      this.searchCtrl.patchValue(initialTerm)

      this.search.pipe(delay(250), untilDestroy(this)).subscribe((term) => {
        this.storage.set(storageKey, term)
      })

      this.onSearch(initialTerm)
    }

    this.searchCtrl.valueChanges.pipe(untilDestroy(this)).subscribe((value) => {
      this.search.emit(value)
    })
  }

  onSearch(term: string) {
    this.search.emit(term)
  }

  onClearSearch() {
    this.search.emit('')
    this.searchCtrl.patchValue('')
  }
}
