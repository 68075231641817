<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">{{ config.title }}</h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <p [innerHtml]="config.help"></p>

        <app-select-search
          [inline]="false"
          bindValue="id"
          bindLabel="name"
          [items]="config.categories"
          [ctrl]="form.get('category')"
          [multiple]="false"
          placeholder="Select AdvanceCM rate category"
        >
          <ng-container class="error-container">
            <mat-error> Category is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>

        <app-select-search
          bindLabel="name"
          [items]="channelRoomRates$ | async"
          [ctrl]="form.get('channelRate')"
          [multiple]="false"
          [inline]="false"
          [placeholder]="'Select ' + config.channelFriendlyName + ' rate category'"
        >
          <ng-container class="error-container">
            <mat-error> Category is <strong>required</strong> </mat-error>
          </ng-container>
        </app-select-search>
        <p *ngIf="!isDefaultCategory()">
          The Rates for this category will be pushed to Channel only if there are no Channel Adjustment added to rates
          for this Rental & Channel. If Channel Adjustment is added, then Channel Adjusted default category rates will
          be pushed.
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
    <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
      <i class="fal fa-arrow-up"></i> {{ config.buttonText }}
    </button>
  </div>
</form>
