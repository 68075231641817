import { Injectable } from '@angular/core'
import { SharedModule } from '@tv3/shared/shared.module'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { BdcPropertySummaryDialogComponent } from '@tv3/containers/channels/content/bdc/dialogs/bdc-property-summary-dialog/bdc-property-summary-dialog.component'

@Injectable({
  providedIn: SharedModule,
})
export class BdcPropertySummaryDialogService {
  constructor(private dialog: MatDialog) {}

  public open(propertyId: number) {
    const defaultConfig: MatDialogConfig = {
      width: '700px',
      height: 'auto',
      data: { propertyId },
      panelClass: ['variable-modal'],
    }

    return this.dialog.open(BdcPropertySummaryDialogComponent, defaultConfig).afterClosed().pipe(take(1))
  }
}
