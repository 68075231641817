<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">
      <i *ngIf="config.titleIcon.length > 0" [class]="config.titleIcon"></i> {{ config.title }}
    </h5>
    <button (click)="close()" type="button" class="close">
      <i class="far fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <p [innerHtml]="config.help"></p>
      </div>
      <div class="col-sm-12">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="value" required matInput [placeholder]="config.placeholder" />
          <mat-error *ngIf="form.get('value').touched && form.hasError('required', ['value'])">
            <span class="text-capitalize">{{ config.placeholder }}</span> is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-secondary-danger">
      <i class="far fa-times"></i> cancel
    </button>
    <button (click)="save(form)" type="button" class="btn btn-secondary-info create-btn">
      <i class="cs-icon cs-icon-save"></i> {{ config.buttonText }}
    </button>
  </div>
</form>
