import { Injectable } from '@angular/core'
import { Effect, ofType, Actions } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import * as lodash from 'lodash'
import { combineLatest, of } from 'rxjs'
import { switchMap, catchError, map, take, tap, concatMap, toArray } from 'rxjs/operators'
import { isSomething } from '../../functions'
import { selectOnce } from '../../rx-operators'
import { Toaster } from '../../services'
import { ActionFailed, ActionSkipped } from '../utility'
import {
  addRateMapping,
  addRateMappingComplete,
  cleanRateMappings,
  deleteRateMapping,
  deleteRateMappingComplete,
  deleteRateMappings,
  deleteRateMappingsComplete,
  setRateMappingsLastSync,
  setRateMappingsLastSyncComplete,
  toggleRateMappingsSync,
  toggleRateMappingsSyncComplete,
  updateRateMappingsComplete,
} from './rental-rate-mapping.actions'
import { RateMappingResponse } from './rental-rate-mapping.model'
import { selectRateMappingForChannel, selectRentalRateMappings } from './rental-rate-mapping.selectors'
import { RateMappingService } from './rental-rate-mapping.service'
import { removeRateMappingForChannel, updateRentalComplete } from './rental.actions'
import { Rental } from './rental.models'
import { selectRentalById, selectRentalEntities } from './rental.selectors'
import { RentalService } from './rental.service'
import { GroupRateService, Rate } from '../rate'
import { updateRateMappings } from '..'

@Injectable()
export class RentalRateMappingEffects {
  @Effect()
  add$ = this.actions$.pipe(
    ofType(addRateMapping),
    switchMap(({ rentalId, payload }) =>
      this.rateMappings.addRateMapping(rentalId, payload).pipe(
        tap(() => this.toast.success('Rate Mapping added successfully.')),
        switchMap((item) =>
          this.store.pipe(
            selectOnce(selectRentalRateMappings(rentalId)),
            map((items) => [...items, item])
          )
        ),
        map((items) => addRateMappingComplete({ update: { id: rentalId, changes: { rateMap: items } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(deleteRateMapping),
    switchMap(({ rentalId, mappingId }) =>
      this.rateMappings.deleteRateMapping(rentalId, mappingId).pipe(
        tap(() => this.toast.success('Rate Mapping deleted successfully.')),
        map((items) => deleteRateMappingComplete({ update: { id: rentalId, changes: { rateMap: items } } })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  updateMultiple$ = this.actions$.pipe(
    ofType(updateRateMappings),
    switchMap(({ items }) =>
      of(...items).pipe(
        concatMap((item) =>
          this.rateMappings
            .updateRateMapping(item.rentalId, item.mappingId, item.payload)
            .pipe(map((mappings) => ({ rentalId: item.rentalId, mappings })))
        ),
        toArray(),
        map((data) => lodash.uniqBy(data.reverse(), (t) => t.rentalId)),
        tap(() => this.toast.success('Rate Mappings updated successfully.')),
        map((items) =>
          updateRateMappingsComplete({
            updates: items.map((t) => ({ id: t.rentalId, changes: { rateMap: t.mappings } })),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  toggleMultiple$ = this.actions$.pipe(
    ofType(toggleRateMappingsSync),
    switchMap(({ items }) =>
      of(...items).pipe(
        concatMap((item) =>
          this.rateMappings
            .setRateMappingAutoSync(item.rentalId, item.mappingId, item.enabled)
            .pipe(map((mappings) => ({ rentalId: item.rentalId, mappings })))
        ),
        toArray(),
        map((data) => lodash.uniqBy(data.reverse(), (t) => t.rentalId)),
        tap(() => this.toast.success('Rate Mappings updated successfully.')),
        map((items) =>
          toggleRateMappingsSyncComplete({
            updates: items.map((t) => ({ id: t.rentalId, changes: { rateMap: t.mappings } })),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  deleteMultiple$ = this.actions$.pipe(
    ofType(deleteRateMappings),
    switchMap(({ items }) =>
      of(...items).pipe(
        concatMap((item) =>
          this.rateMappings
            .deleteRateMapping(item.rentalId, item.mappingId)
            .pipe(map((mappings) => ({ rentalId: item.rentalId, mappings })))
        ),
        toArray(),
        map((data) => lodash.uniqBy(data.reverse(), (t) => t.rentalId)),
        tap(() => this.toast.success('Rate Mappings deleted successfully.')),
        map((items) =>
          deleteRateMappingsComplete({
            updates: items.map((t) => ({ id: t.rentalId, changes: { rateMap: t.mappings } })),
          })
        ),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  setRateMapLastSync$ = this.actions$.pipe(
    ofType(setRateMappingsLastSync),
    switchMap(({ items }) =>
      of(...items)
        .pipe(
          concatMap((item) =>
            this.rateMappings
              .updateRateMapping(item.rentalId, item.mappingId, { lastsync: item.date })
              .pipe(map((mappings) => ({ rentalId: item.rentalId, mappings })))
          ),
          toArray(),
          map((data) => lodash.uniqBy(data.reverse(), (t) => t.rentalId))
        )
        .pipe(
          map((items) =>
            setRateMappingsLastSyncComplete({
              updates: items.map((t) => ({ id: t.rentalId, changes: { rateMap: t.mappings } })),
            })
          ),
          catchError((error) => of(ActionFailed({ error })))
        )
    )
  )

  @Effect()
  removeRateMappingForChannel$ = this.actions$.pipe(
    ofType(removeRateMappingForChannel),
    switchMap(({ channelId }) =>
      this.store.pipe(select(selectRateMappingForChannel, { id: channelId })).pipe(
        take(1),
        switchMap((rateMaps) => {
          if (!rateMaps || !rateMaps.length) {
            return of(ActionSkipped())
          }
          return of(...rateMaps).pipe(
            concatMap((r) =>
              this.rateMappings
                .deleteRateMapping(r.rental_id, r.key)
                .pipe(
                  map((items) => updateRentalComplete({ update: { id: r.rental_id, changes: { rateMap: items } } }))
                )
            ),
            toArray(),
            tap(() => this.toast.success('Rate Mappings removed for this channel.')),
            switchMap((res) => res)
          )
        }),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  clean$ = this.actions$.pipe(
    ofType(cleanRateMappings),
    switchMap(({ rentalIds }) => {
      return combineLatest([this.groupRates.all(), this.store.pipe(selectOnce(selectRentalEntities))]).pipe(
        map(([groupRates, rentalsById]) => {
          const items = lodash.flatten(
            lodash.map(rentalIds, (rentalId) => this.cleanRateMappings(rentalsById[rentalId], groupRates))
          )
          return isSomething(items)
            ? deleteRateMappings({ items: items.map((t) => ({ rentalId: t.rental_id, mappingId: t.key })) })
            : ActionSkipped()
        })
      )
    })
  )

  private cleanRateMappings(rental: Rental, groupRates: Rate[]) {
    const deletingRateMappings: RateMappingResponse[] = []
    const rentalGroupRates = lodash.filter(groupRates, (g) => lodash.includes(g.rentalIds, rental.id))
    const rateCategories = this.rentals.getUniqueCategories([...rental.rates, ...rentalGroupRates])

    lodash.forEach(rental.rateMap, (rateMapping) => {
      if (!rateCategories.includes(rateMapping.category)) {
        deletingRateMappings.push(rateMapping)
      }
    })

    return deletingRateMappings
  }

  constructor(
    private actions$: Actions,
    private toast: Toaster,
    private store: Store<any>,
    private rentals: RentalService,
    private rateMappings: RateMappingService,
    private groupRates: GroupRateService
  ) {}
}
