import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import * as VCF from 'vcf'
import { Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import * as R from 'ramda'
import { get, isArray, slice } from 'lodash'
import { Guest } from '@tv3/store/guest/guest.model'
import { UpdateGuestRequest } from '@tv3/interfaces/requests/guest.request'
import { Observable, of } from 'rxjs'
import { concatMap, tap } from 'rxjs/operators'
import { AddGuestsComplete } from '@tv3/store/guest/guest.actions'
import { GuestService } from '@tv3/store/guest/guest.service'
import { getCountryCode, UploadBoxOptions } from '@tokeet-frontend/tv3-platform'
import { ParsedItem } from '@tv3/interfaces/files/parsed-item.file'
import { ParsedUploadFile } from '@tv3/interfaces/files/parsed-upload.file'
import { isEmail } from '@tv3/validators/string-validator/isEmail'
import { AmplitudeService } from '@tv3/services/amplitude.service'

@Component({
  selector: 'app-import-vcard-guests-dialog',
  templateUrl: './import-vcard-guests-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./import-vcard-guests-dialog.component.scss'],
})
export class ImportVcardGuestsDialogComponent {
  uploadBoxOptions: UploadBoxOptions = {
    allowedFileExtensions: ['.vcf'],
    maxSize: 5 * 1024 * 1024,
    autoUpload: false,
    maxUploads: 1,
  }

  columnDefs = [
    { field: 'name', label: 'Name', required: true },
    { field: 'email', label: 'Email', required: true, validators: [isEmail] },
    { field: 'phone', label: 'Phone' },
    { field: 'address.city', label: 'City' },
    { field: 'address.country', label: 'Country' },
  ]

  get requiredColumns() {
    return R.filter((col) => col.required, this.columnDefs)
  }

  guide = {
    description: `The property in your vCard file should be (${R.map(
      (col) => (!col.required ? col.label : col.label + '*'),
      this.columnDefs
    ).join(', ')}).`,
    list: ['Name and Email are required.'],
  }

  constructor(
    public dialogRef: MatDialogRef<ImportVcardGuestsDialogComponent>,
    private store: Store<fromRoot.State>,
    private guestService: GuestService,
    @Inject(MAT_DIALOG_DATA) public data: { guests: Guest[] },
    protected toast: Toaster,
    private amplitudeService: AmplitudeService
  ) {}

  onAfterAddingFile(fileItem) {
    this.parseFile(fileItem).subscribe()
  }

  private tryToCreateGuests(newGuests: UpdateGuestRequest[]): Observable<Guest[]> {
    if (R.isEmpty(newGuests)) {
      this.toast.success(`There are no new guests in vCard files}!`)
      return of([])
    }
    return this.guestService
      .createMany(newGuests, `vCard files uploaded successfully!`)
      .pipe(tap((guests: Guest[]) => this.store.dispatch(AddGuestsComplete({ guests }))))
  }

  private parseOneVCard(rawVCard) {
    const vcard = new VCF().parse(rawVCard.replace(/\r?\n/g, '\r\n'))

    const getFieldValue = (field) => {
      if (!field) {
        return ''
      }

      if (isArray(field) && field.length) {
        return field[0].valueOf()
      } else {
        return field.valueOf()
      }
    }
    const address = getFieldValue(vcard.get('adr'))

    const contact = {
      address: {},
      email: getFieldValue(vcard.get('email')),
      name: getFieldValue(vcard.get('fn')),
      phone: getFieldValue(vcard.get('tel')),
    }

    const [street, city, state, zip, country]: string[] = slice((address || '').split(';'), -5)
    contact.address = {
      address: street,
      city,
      state,
      country,
      country_code: getCountryCode(country),
      postal_code: zip,
    }

    return contact
  }

  private parseFile(fileItem: ParsedUploadFile<any>): Observable<any> {
    if (fileItem.file.size === 0) {
      fileItem.isError = true
      fileItem.errors = ["Please don't upload empty csv file."]
      return of(false)
    }

    fileItem.isHeader = false
    fileItem.errors = []
    fileItem.items = []

    return this.parseVCardFile(fileItem)
  }

  private parseVCardFile(fileItem: ParsedUploadFile<any>) {
    return new Observable((observer) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const vcards = R.map(
          (v) => `${v}\nEND:VCARD`,
          R.filter((v) => !!v.trim(), (<string>fileReader.result).split('END:VCARD'))
        )

        let hasError = false
        fileItem.items = R.map((rawVCard) => {
          let parsedItem: ParsedItem<any>
          parsedItem = {
            originalFields: [],
            errors: [],
            warnings: [],
            item: {},
          }
          const contact = this.parseOneVCard(rawVCard)
          this.columnDefs.map((item) => {
            const columnValue = get(contact, item.field)
            if (item.required && !columnValue) {
              parsedItem.isError = true
              hasError = true
              parsedItem.errors.push(`File does not contain "${item.label}" field.`)
            }

            R.forEach((validator) => {
              if (!validator(columnValue)) {
                parsedItem.isError = true
                hasError = true
                parsedItem.errors.push(`Value for "${item.label}" is invalid.`)
              }
            }, item.validators || [])
          })

          parsedItem.item = contact
          return parsedItem
        }, vcards)

        if (hasError) {
          fileItem.isError = true
          fileItem.errors.push('File contains errors, please check details in a rows.')
        }

        observer.next(true)
        observer.complete()
      }
      fileReader.readAsText(fileItem._file)
    })
  }

  getFileErrorText(file: ParsedUploadFile<any>): string {
    if (!file.isError) {
      return ''
    }
    return R.join('\n')(R.map((error) => `• ${error}\n`, file.errors))
  }

  getItemErrorText(item: ParsedItem<any>): string {
    if (!item.isError && !item.isWarning) {
      return ''
    }

    let result = R.join('\n')(R.map((error) => `• ${error}\n`, item.errors))
    if (item.isError && item.isWarning) {
      result += '--------------------\n'
    }

    result += R.join('\n')(R.map((warning) => `• ${warning}\n`, item.warnings))

    return result
  }

  downloadSample() {
    const elem = window.document.createElement('a')
    elem.href = 'assets/vcard-sample.vcf'
    elem.download = 'sample.vcf'
    elem.target = '_blank'
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  close() {
    this.dialogRef.close()
  }

  onCreate(files: ParsedUploadFile<any>[]) {
    if (R.any((file) => file.isError, files)) {
      this.toast.error('There are some errors in selected file(s) needs to be checked.')
      return
    }

    this.amplitudeService.logEvent('upload-guest-list-vcard')

    const items = R.map((p: any) => p.item, R.flatten(R.map((file) => file.items, files)))
    const uniqueItems = R.uniqBy((i) => i.email, items)
    if (items.length !== uniqueItems.length) {
      this.toast.error('Please check your file(s), duplicated email address exists')
      return
    }

    let newGuests = []
    R.forEach((guest) => {
      const g = R.pipe(
        R.pathOr([], ['guests']),
        R.find((i: Guest) => i.primaryEmail === guest.email)
      )(this.data)
      if (!g) {
        newGuests.push(guest)
      }
    }, items)
    newGuests = R.uniqBy((g) => g.email, newGuests)

    of([])
      .pipe(
        concatMap(() => {
          return this.tryToCreateGuests(newGuests)
        })
      )
      .subscribe(
        () => {
          this.close()
        },
        (error) => {
          this.toast.error('Error when import data. ' + error)
          console.log(error)
        }
      )
  }
}
