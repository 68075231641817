import * as R from 'ramda'
import { isNumber } from 'lodash'
import { getInvoiceLineRawTotal, getInvoiceLineTaxTotal } from './invoice-total'
import { TaxV2, TaxV3 } from '@tokeet-frontend/tv3-platform'
import { TaxTypes, TaxModalities } from '@tokeet/cost-resolver'
import { Invoice, InvoiceItem } from '../..'

export function taxV2ToV3(taxV2: TaxV2): TaxV3[] {
  if (!taxV2) {
    return []
  }
  return R.filter(
    (i) => !!i && !!i.amount,
    [
      isNumber(taxV2.flat)
        ? {
            name: 'Tax $',
            type: TaxTypes.Flat,
            amount: taxV2.flat || 0,
            modality: TaxModalities.PerStay,
          }
        : undefined,
      isNumber(taxV2.percent)
        ? {
            name: 'Tax %',
            type: TaxTypes.Percent,
            amount: taxV2.percent || 0,
            modality: TaxModalities.PerStay,
          }
        : undefined,
    ]
  )
}

export function setInvoiceItemTaxV3(item: InvoiceItem, force = false) {
  if (item.taxes && !force) {
    return
  }

  if (item.taxEx) {
    item.taxes = taxV2ToV3(item.taxEx)
    return
  }

  if (item.tax) {
    item.taxes = [{ name: 'Tax %', type: TaxTypes.Percent, amount: item.tax || 0, modality: TaxModalities.PerStay }]
    return
  }
}

export function setInvoiceItemTaxV1(item: InvoiceItem) {
  const rawTotal = getInvoiceLineRawTotal(item)
  const tax = getInvoiceLineTaxTotal(item)
  // @ts-ignore
  item.tax = ((tax / rawTotal) * 100).toFixed(2) * 1
}

export function upgradeInvoiceTax(invoice: Invoice): Invoice {
  R.forEach((item) => setInvoiceItemTaxV3(item), invoice.invoiceItems || []) // back compliance
  return invoice
}
