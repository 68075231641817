import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Store } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { BehaviorSubject, forkJoin, merge, Observable } from 'rxjs'
import { finalize, map, take, tap, withLatestFrom } from 'rxjs/operators'
import * as lodash from 'lodash'
import * as fromRoot from '@tv3/store/state'
import {
  Currency,
  DateRangePickerSelection,
  createLocaleCompareSort,
  isEmptyTable,
  ActionFailed,
  LoadGroupRatesComplete,
  loadRental,
  loadRentalComplete,
  cleanRateMappings,
} from '@tokeet-frontend/tv3-platform'
import { TableType } from '@tv3/shared/empty-table/table-type'

import {
  ConfirmDialogService,
  GroupRateService,
  GroupRatesChangedComplete,
  Rate,
  RateService,
  RateType,
  Rental,
  RentalService,
  selectAllRentals,
  Toaster,
  toMoment,
  untilDestroy,
  updateRentalComplete,
} from '@tokeet-frontend/tv3-platform'
import { SelectableRow } from '@tokeet-frontend/tv3-platform'
import { UserStorage } from '@tokeet-frontend/tv3-platform'
import { EditRateDialogService } from '../edit-rate-dialog/edit-rate-dialog.service'
import { EditRateDialogInput } from '../edit-rate-dialog/edit-rate-dialog.component'
import * as R from 'ramda'

@Component({
  selector: 'app-rental-rates-table',
  templateUrl: './rental-rates-table.component.html',
  styleUrls: ['./rental-rates-table.component.scss'],
})
export class RentalRatesTableComponent extends SelectableRow<Rate> implements OnInit, OnChanges {
  @Input() rental: Rental

  @Output() changeRates = new EventEmitter()

  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  rateTypes: { value: RateType | 'group'; label: string }[] = [
    { value: 'standard', label: 'Standard Rate' },
    { value: 'dynamic', label: 'Dynamic Rate' },
    { value: 'group', label: 'Group Rate' },
  ]

  isDeleting = false
  groupRatesLoaded = false

  tableType: TableType = TableType.RentalRates

  searchControl = new FormControl()
  categoryControl = new FormControl()
  typeControl = new FormControl()
  periodControl = new FormControl()
  rentalsControl = new FormControl()

  rentalRates$ = new BehaviorSubject<Rate[]>([])
  groupRates$ = new BehaviorSubject<Rate[]>([])

  rates$ = new BehaviorSubject<Rate[]>([])

  filteredRentals$ = new BehaviorSubject<Rental[]>([])
  categories$: Observable<string[]>

  rentals: Rental[]

  currency: string | Currency

  displayedColumns = ['select', 'name', 'category', 'period', 'nightly', 'weekly', 'monthly', 'minimumStay', 'edit']

  isEmptyTable$ = isEmptyTable(this.dataSource)

  isSelectAllRenderedItems = true

  constructor(
    protected storage: UserStorage,
    private store: Store<fromRoot.State>,
    private actions$: Actions,
    private toaster: Toaster,
    private rentalService: RentalService,
    private rateService: RateService,
    private groupRateService: GroupRateService,
    private confirmDialog: ConfirmDialogService,
    private editRateDialog: EditRateDialogService
  ) {
    super(storage)
  }

  ngOnInit() {
    this.currency = this.rental.currency

    this.loadSavedTableState()

    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortData = createLocaleCompareSort(this.sortDataAccessor)

    this.categories$ = this.rates$.pipe(
      map((rates) => this.rentalService.getUniqueCategories(rates)),
      tap((items) => {
        const chosenItem = this.categoryControl.value

        if (R.isNil(R.find((i) => i === chosenItem, items))) {
          this.categoryControl.patchValue(null)
        }
      })
    )

    this.prepareRentals()
    this.prepareRates()

    merge(
      this.rates$,
      this.searchControl.valueChanges,
      this.categoryControl.valueChanges,
      this.typeControl.valueChanges,
      this.periodControl.valueChanges,
      this.rentalsControl.valueChanges
    )
      .pipe(
        tap(() => {
          this.filterRates()
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.rates$.next(this.rates$.value)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.rental = lodash.get(changes, ['rental', 'currentValue'], this.rental)

    this.prepareRentalRates()
  }

  isRefreshing$ = new BehaviorSubject(false)

  onRefresh() {
    this.isRefreshing$.next(true)

    forkJoin([this.fetchGroupRates(), this.actions$.pipe(ofType(loadRentalComplete), take(1))])
      .pipe(
        finalize(() => {
          this.isRefreshing$.next(false)
        }),
        untilDestroy(this)
      )
      .subscribe()

    this.store.dispatch(loadRental({ id: this.rental.id }))
  }

  isRowSelectable = (rate: Rate) => {
    return !this.isGroupRate(rate)
  }

  onMasterToggle(checked: boolean) {
    if (checked) {
      this.isSelectAllRenderedItems = true
      this.masterToggle(true)
    } else {
      this.unselectAll()
    }
  }

  onSelectAll(all = false) {
    this.unselectAll()
    if (all) {
      this.masterToggle(false)
      this.isSelectAllRenderedItems = false
    } else {
      this.masterToggle(true)
      this.isSelectAllRenderedItems = true
    }
  }

  searchRate(rate: Rate) {
    this.searchControl.setValue(rate.name)
  }

  hasRentalRates = (rates: Rate[]) => {
    return !!rates.find((rate) => !this.isGroupRate(rate))
  }

  isGroupRate(rate: Rate) {
    return !rate.rentalId && rate.rentalIds
  }

  sortDataAccessor = (rate: Rate, property: string) => {
    switch (property) {
      case 'period':
        return rate.start
      case 'category':
        return this.getRateCategory(rate)
      case 'rentals':
        return rate.rentalIds ? rate.rentalIds.length : 0
      default:
        return rate[property]
    }
  }

  clearFilters() {
    this.searchControl.reset()
    this.categoryControl.reset()
    this.typeControl.reset()
    this.periodControl.reset()
    this.rentalsControl.reset()
  }

  filterRates() {
    const search = lodash.trim(this.searchControl.value).toLowerCase()
    const category = lodash.toString(this.categoryControl.value)
    const type = lodash.toString(this.typeControl.value)
    const period = this.periodControl.value as DateRangePickerSelection

    let rates = this.rates$.value

    if (search) {
      const dateFormat = 'MMM D, YYYY'

      rates = rates.filter((rate) => {
        if (lodash.toString(rate.name).toLowerCase().includes(search)) return true
        if (lodash.toString(rate.category).toLowerCase().includes(search)) return true

        const textCategories = rate.categories && lodash.toString(rate.categories.join('\n'))
        if (textCategories && textCategories.toLowerCase().includes(search)) return true

        const textPeriod = `${toMoment(rate.start).format(dateFormat)} - ${toMoment(rate.end).format(dateFormat)}`
        if (textPeriod && textPeriod.toLowerCase().includes(search)) return true

        return false
      })
    }

    if (category && category !== '*') {
      rates = rates.filter((rate) => {
        return lodash.toString(rate.category) === category || (rate.categories && rate.categories.includes(category))
      })
    }

    if (type && type !== '*') {
      if (type === 'group') {
        rates = rates.filter((rate) => !!rate.groupRateId)
      } else {
        rates = rates.filter((rate) => rate.type === type)
      }
    }

    if (period) {
      rates = rates.filter((rate) => {
        const start = toMoment(rate.start).startOf('date').unix()
        const end = toMoment(rate.end).startOf('date').unix()

        return (
          (start >= period.from && start <= period.to) ||
          (end >= period.from && end <= period.to) ||
          (start < period.from && end > period.to)
        )
      })
    }

    this.dataSource.data = rates
  }

  getRateCategory(rate: Rate) {
    if (rate.type === 'promotion') {
      return (rate.categories || []).join(', ') || 'promotion'
    } else {
      return rate.category || ''
    }
  }

  getRowTooltip = (rate: Rate) => {
    if (!this.isGroupRate(rate)) return null
    return 'This is a group rate and may only be managed in the group rates table.'
  }

  getRentalsTooltip = (rentalIds: string) => {
    const rentals = lodash.keyBy(this.rentals, (rental) => rental.id)

    const rentalNames = lodash.map(rentalIds, (rentalId) => {
      const rental = rentals[rentalId]

      return rental && rental.name
    })

    return lodash.compact(rentalNames).join(', ')
  }

  onAddStandardRate() {
    this.openEditRateDialog(null, 'standard')
  }

  onAddDynamicRate() {
    this.openEditRateDialog(null, 'dynamic')
  }

  onEdit(rate: Rate) {
    this.openEditRateDialog(rate)
  }

  onDelete(rate?: Rate) {
    const rates: Rate[] = rate ? [rate] : this.getSelected()

    this.onDeleteRentalRates(rates)
  }

  private prepareRates() {
    this.prepareGroupRates()

    merge(this.rentalRates$, this.groupRates$)
      .pipe(
        tap(() => {
          const rentalRates = [...this.rentalRates$.value]
          const groupRates = this.groupRates$.value

          groupRates.forEach((rate) => {
            if (lodash.includes(rate.rentalIds, this.rental.id)) {
              rentalRates.push(rate)
            }
          })

          this.rates$.next(rentalRates)
        })
      )
      .subscribe()
  }

  private fetchGroupRates() {
    return this.groupRateService.all().pipe(
      tap((rates) => {
        this.groupRates$.next(rates)
        this.groupRatesLoaded = true
      })
    )
  }

  private prepareGroupRates() {
    this.fetchGroupRates().subscribe()

    this.actions$
      .pipe(
        ofType(GroupRatesChangedComplete, LoadGroupRatesComplete),
        tap(({ rates }) => {
          this.groupRates$.next(rates)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private prepareRentalRates() {
    const rates = [...(this.rental.rates || []), ...(this.rental.promotions || [])]

    this.rentalRates$.next(rates)
  }

  private prepareRentals() {
    this.rates$
      .pipe(
        withLatestFrom(this.store.select(selectAllRentals)),
        map(([rates, rentals]) => {
          this.rentals = rentals

          const rentalIds = lodash
            .chain(rates)
            .map((rate) => rate.rentalIds)
            .flatten()
            .keyBy()
            .value()

          rentals = rentals.filter((rental) => rentalIds[rental.id])

          this.filteredRentals$.next(rentals)
        }),
        untilDestroy(this)
      )
      .subscribe()
  }

  private onDeleteRentalRates(rates: Rate[]) {
    const textRates = rates.length > 1 ? 'these rates' : 'this rate'

    let text = `Are you sure you want to delete ${textRates}?`

    this.confirmDialog
      .confirm({
        title: `Delete ${textRates}?`,
        body: `This action may also delete rate mappings. ${text}`,
      })
      .subscribe(() => {
        this.deleteRentalRates(rates)
      })
  }

  private deleteRentalRates(rates: Rate[]) {
    this.isDeleting = true

    this.rateService
      .deleteRates(this.rental.id, rates)
      .pipe(
        tap((d) =>
          this.store.dispatch(
            updateRentalComplete({
              update: { id: this.rental.id, changes: { rates: d.rates, promotions: d.promotions } },
            })
          )
        ),
        finalize(() => (this.isDeleting = false))
      )
      .subscribe(
        () => {
          this.toaster.success(`${rates.length > 1 ? 'Rates' : 'Rate'} deleted successfully.`)
          this.store.dispatch(
            cleanRateMappings({ rentalIds: lodash.uniq(lodash.flatten(lodash.map(rates, (r) => r.rentalIds || []))) })
          )
          this.changeRates.emit()
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  private updateRentalRates(rates: Rate[], promotions: Rate[]) {
    const changes: Partial<Rental> = {}

    if (rates) {
      changes.rates = rates
    }

    if (promotions) {
      changes.promotions = promotions
    }

    this.store.dispatch(
      updateRentalComplete({
        update: {
          id: this.rental.id,
          changes,
        },
      })
    )

    this.changeRates.emit()
  }

  private openEditRateDialog(rate: Rate = null, type: RateType = null) {
    let isGroupRate = false

    if (rate && this.isGroupRate(rate)) {
      isGroupRate = true
    }

    const params: EditRateDialogInput = {
      isGroupRate,
      rate,
      type,
    }

    if (isGroupRate) {
      params.groupRates = this.groupRates$.value
      params.rentals = this.rentals
    } else {
      params.rental = this.rental
    }

    this.editRateDialog
      .open(params)
      .afterClosed()
      .subscribe((result) => {
        if (result && result.groupRates) {
          this.groupRates$.next(result.groupRates)
        }
      })
  }

  get isFiltering(): boolean {
    return (
      this.searchControl.value || this.categoryControl.value || this.periodControl.value || this.rentalsControl.value
    )
  }
}
