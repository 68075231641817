import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import * as fromInventory from './reducer'
import { InventoryEffects } from './effects'
import { InventoryService } from './service'
import { InventoriesGuard } from './guard'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('inventories', fromInventory.inventoryReducer),
    EffectsModule.forFeature([InventoryEffects]),
  ],
  declarations: [],
  providers: [InventoriesGuard, InventoryService],
})
export class InventoriesStoreModule {}
