import { Injectable } from '@angular/core'
import { filter, take } from 'rxjs/operators'
import { CloudinaryUploadDialogComponent } from './cloudinary-upload-dialog.component'
import { CloudinaryUploadOptions } from './cloudinary-upload-dialog.interfaces'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { isSomething } from '../../functions'

@Injectable()
export class CloudinaryUploadDialogService {
  constructor(private dialog: MatDialog) {}

  open(options: CloudinaryUploadOptions) {
    const defaultConfig: MatDialogConfig = {
      width: '650px',
      height: 'auto',
      panelClass: ['variable-modal'],
      data: {
        options,
      },
    }

    return this.dialog
      .open(CloudinaryUploadDialogComponent, defaultConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter((images) => isSomething(images))
      )
  }
}
