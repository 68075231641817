import { Directive, OnInit, ElementRef, OnDestroy } from '@angular/core'

@Directive({
  selector: '[dropdownScrollContainer]',
})
export class DropdownScrollContainerDirective implements OnInit, OnDestroy {
  private _cleanup: () => void = null

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const elm = this.el.nativeElement

    const handler = () => {
      this.refreshPosition()
    }

    elm.addEventListener('scroll', handler)

    this._cleanup = () => {
      elm.removeEventListener('scroll', handler)
    }
  }

  ngOnDestroy() {
    this._cleanup?.()
  }

  private refreshPosition() {
    const event = new Event('ngxBootstrapPositioning')
    window.dispatchEvent(event)
  }
}
