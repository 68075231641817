import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import {
  Destroyable,
  Rental,
  ConfirmDialogService,
  ActionFailed,
  Toaster,
  TaxV3,
  updateRentalTaxes,
  updateRentalTaxesComplete,
} from '@tokeet-frontend/tv3-platform'
import { Store } from '@ngrx/store'
import { concatMap, finalize, switchMap, take, toArray } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { of } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  selector: 'app-copy-taxes-to-dialog',
  templateUrl: './copy-taxes-to-dialog.component.html',
  styleUrls: ['./copy-taxes-to-dialog.component.scss'],
  host: {
    class: 'modal-content',
  },
})
export class CopyTaxesToDialogComponent extends Destroyable implements OnInit {
  get rental() {
    return this.data.rental
  }
  selectedTaxes: TaxV3[] = []
  selectedRentals: Rental[] = []

  isLoading = false
  progress = 0

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    public dialogRef: MatDialogRef<CopyTaxesToDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rental: Rental }
  ) {
    super()
  }

  ngOnInit(): void {}

  onSelectFees(items: TaxV3[]) {
    this.selectedTaxes = items
  }

  onSelectRentals(items: Rental[]) {
    this.selectedRentals = items
  }

  saveTaxes() {
    const doAction = (rentalId: string) => {
      this.store.dispatch(updateRentalTaxes({ taxes: this.selectedTaxes, rentalId, silent: true }))
      return this.actions.pipe(ofType(updateRentalTaxesComplete), take(1))
    }

    return of(...this.selectedRentals).pipe(
      concatMap((rental, i) => {
        this.progress = i + 1
        return doAction(rental.id)
      }),
      toArray()
    )
  }

  onOverwrite() {
    this.isLoading = true
    this.confirmDialog
      .confirm({
        title: 'Overwrite Taxes?',
        body: 'This action will overwrite taxes in rentals.',
      })
      .pipe(
        switchMap(() => this.saveTaxes()),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.toaster.success('Taxes copied successfully')
          this.dialogRef.close('overwrite')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  onAdd() {
    this.isLoading = true
    this.saveTaxes()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Taxes copied successfully')
          this.dialogRef.close('copy')
        },
        (error) => {
          this.store.dispatch(ActionFailed({ error }))
        }
      )
  }

  close() {
    this.dialogRef.close()
  }
}
