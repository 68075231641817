import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'
import { Router } from '@angular/router'
import { AddInvoiceDialogService } from '@tv3/containers/invoices/invoices/add-invoice/add-invoice-dialog.service'
import { OpenInvoiceOverlay } from '@tv3/store/overlay/overlay.actions'
import { Store } from '@ngrx/store'
import { filter, take, tap } from 'rxjs/operators'
import { CreateInvoiceParams } from '@tv3/containers/invoices/invoices/add-invoice/add-invoice-dialog.component'
import { ApplyInvoiceTemplate, ApplyInvoiceTemplateComplete, Invoice, InvoiceTypes } from '@tokeet-frontend/invoices'
import { Destroyable, isSomething, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { SelectInvoiceTemplateDialogService } from '@tv3/containers/invoices/templates/select-invoice-template/select-invoice-template.service'
import { Actions, ofType } from '@ngrx/effects'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-related-invoices',
  templateUrl: './related-invoices.component.html',
  styleUrls: ['./related-invoices.component.scss'],
})
export class RelatedInvoicesComponent implements OnInit, OnDestroy {
  @Input() inquiry: Inquiry
  @Input() invoices: Invoice[] = []
  @Input() addable = true

  get guests() {
    return (this.inquiry && (this.inquiry.numAdults || 0) + (this.inquiry.numChild || 0)) || 1
  }

  invoiceTypes = InvoiceTypes

  applySub: Subscription

  constructor(
    private router: Router,
    private addInvoiceDialog: AddInvoiceDialogService,
    private store: Store<any>,
    private actions$: Actions,
    private selectInvoiceTemplateDialogService: SelectInvoiceTemplateDialogService
  ) {}

  ngOnInit() {
    this.applySub = this.actions$
      .pipe(
        ofType(ApplyInvoiceTemplateComplete),
        filter(({ invoice }) => invoice.inquiryId === this.inquiry.id)
      )
      .subscribe(({ invoice }) => {
        if (invoice) {
          this.store.dispatch(OpenInvoiceOverlay({ invoiceId: invoice.id }))
        }
      })
  }

  ngOnDestroy() {
    this.applySub.unsubscribe()
  }

  onEditInvoice(invoice: Invoice) {
    this.store.dispatch(OpenInvoiceOverlay({ invoiceId: invoice.id }))
  }

  onNewInvoice(type: InvoiceTypes) {
    this.addInvoiceDialog
      .open({
        inquiryId: this.inquiry.id,
        type,
      } as CreateInvoiceParams)
      .afterClosed()
      .pipe(take(1))
      .subscribe((invoice) => {
        if (invoice) {
          this.store.dispatch(OpenInvoiceOverlay({ invoiceId: invoice.id }))
        }
      })
  }

  onApplyInvoiceTemplate() {
    this.selectInvoiceTemplateDialogService
      .open()
      .pipe(filter((result) => isSomething(result)))
      .subscribe((result: { template: string }) => {
        const invoiceTemplateId = result.template
        this.store.dispatch(ApplyInvoiceTemplate({ bookingId: this.inquiry.id, invoiceTemplateId }))
      })
  }
}
