import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { forkJoin, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import * as fromRoot from '@tv3/store/state'
import { PaymentRuleService } from '@tv3/store/payment-rule/payment-rule.service'
import {
  CreatePaymentRule,
  CreatePaymentRuleComplete,
  LoadPaymentRules,
  LoadPaymentRulesComplete,
  PausePaymentRules,
  PausePaymentRulesComplete,
  RemovePaymentRule,
  RemovePaymentRuleComplete,
  TogglePaymentRuleStatus,
  TogglePaymentRuleStatusComplete,
} from '@tv3/store/payment-rule/payment-rule.actions'
import { Toaster } from '@tokeet-frontend/tv3-platform'
import { selectPaymentRulesByGateway } from '@tv3/store/payment-rule/payment-rule.selectors'
import { ActionFailed } from '@tokeet-frontend/tv3-platform'

@Injectable()
export class PaymentRuleEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadPaymentRules),
    switchMap(() =>
      this.paymentRule.all().pipe(
        map((rules) => LoadPaymentRulesComplete({ rules })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreatePaymentRule),
    switchMap(({ request }) =>
      this.paymentRule.create(request).pipe(
        tap(() => this.toast.success('Rule saved successfully.')),
        map((rules) => CreatePaymentRuleComplete({ rules })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  toggleStatus$ = this.actions$.pipe(
    ofType(TogglePaymentRuleStatus),
    switchMap(({ id, status }) => {
      return (status ? this.paymentRule.resume(id) : this.paymentRule.pause(id)).pipe(
        tap(() => this.toast.success(status ? 'Rule resumed successfully.' : 'Rule paused successfully.')),
        map(() => TogglePaymentRuleStatusComplete({ id, status })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  @Effect()
  remove$ = this.actions$.pipe(
    ofType(RemovePaymentRule),
    switchMap(({ id }) =>
      this.paymentRule.remove(id).pipe(
        tap(() => this.toast.success('Payment rule deleted successfully.')),
        map(() => RemovePaymentRuleComplete({ id })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  pauseRules$ = this.actions$.pipe(
    ofType(PausePaymentRules),
    switchMap(({ paymentGatewayId }) =>
      this.store.pipe(
        select(selectPaymentRulesByGateway(paymentGatewayId)),
        map((rules) => rules.map((rule) => rule.id)),
        take(1)
      )
    ),
    switchMap((ids: string[]) => {
      return forkJoin(ids.map((id) => this.paymentRule.pause(id))).pipe(
        tap(() => this.toast.success('All rules have been paused.')),
        map(() => PausePaymentRulesComplete({ ids })),
        catchError((error) => of(ActionFailed({ error })))
      )
    })
  )

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private paymentRule: PaymentRuleService,
    private toast: Toaster
  ) {}
}
