import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-connect-airbnb-wizard-step1',
  templateUrl: './connect-airbnb-wizard-step1.component.html',
  styleUrls: ['./connect-airbnb-wizard-step1.component.scss'],
})
export class ConnectAirbnbWizardStep1Component implements OnInit {
  @Input() isAuthorizing = false
  @Output() next = new EventEmitter()
  @Output() quick = new EventEmitter()
  @Output() advance = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}

  onQuick() {
    this.quick.emit()
  }

  onAdvance() {
    this.advance.emit()
  }
}
