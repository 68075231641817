import { createReducer, on } from '@ngrx/store'

import { adapter, initialState } from './state'
import {
  CreateCustomChannelComplete,
  DeleteCustomChannelComplete,
  GetCustomChannelsComplete,
  UpdateCustomChannelComplete,
} from './actions'
import { upsertMany } from '@tokeet-frontend/tv3-platform'

export const reducer = createReducer(
  initialState,
  on(GetCustomChannelsComplete, (state, { items }) => upsertMany(adapter, items, { ...state, isLoaded: true })),
  on(CreateCustomChannelComplete, (state, { item }) => adapter.addOne(item, state)),
  on(UpdateCustomChannelComplete, (state, { change }) => adapter.updateOne(change, state)),
  on(DeleteCustomChannelComplete, (state, { id }) => adapter.removeOne(id, state))
)

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
