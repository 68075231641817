import { Pipe, PipeTransform } from '@angular/core'
import { Inquiry } from '@tv3/store/inquiry/inquiry.model'

@Pipe({
  name: 'canPreApprove',
})
export class CanPreApprovePipe implements PipeTransform {
  transform(inquiry: Inquiry): boolean {
    return inquiry.inquirySource === 'airbnb' && inquiry.status === 'inquiry'
  }
}
