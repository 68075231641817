import { Component, Input, OnInit } from '@angular/core'
import { HelpVideoDialogService } from '../../dialogs/help-video-dialog/help-video-dialog.service'

@Component({
  selector: 'app-help-video-icon',
  templateUrl: './help-video-icon.component.html',
  styleUrls: ['./help-video-icon.component.scss'],
})
export class HelpVideoIconComponent implements OnInit {
  @Input() info = 'Click here to see a video tutorial.'
  @Input() videoId: string

  constructor(private helpVideoDialog: HelpVideoDialogService) {}

  ngOnInit(): void {}

  onOpen() {
    if (this.videoId) {
      this.helpVideoDialog.open(this.videoId)
    }
  }
}
