import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PromptDialogConfig } from './prompt-dialog.interfaces'
import { SaveForm } from '../../decorators'

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  host: { class: 'modal-content' },
})
export class PromptDialogComponent implements OnInit {
  config = {
    title: 'Prompt',
    help: '',
    buttonText: 'Ok',
    placeholder: '',
    titleIcon: '',
  } as PromptDialogConfig

  form = this.fb.group({
    value: [undefined, [Validators.required]],
  })

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogConfig
  ) {}

  ngOnInit() {
    this.config = { ...this.config, ...this.data }
    this.form.patchValue({ value: this.data.defaultValue })
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues.value)
  }
}
