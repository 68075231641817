import { LoadUserPreferencesComplete, UpdateUserPreferencesComplete } from './user-preferences.actions'
import { adapter, initialState, State } from './user-preferences.state'
import * as R from 'ramda'
import { Action, createReducer, on } from '@ngrx/store'

export const reducer = createReducer(
  initialState,
  on(LoadUserPreferencesComplete, (state, { preferences }) =>
    adapter.addOne(preferences, R.merge(state, { isLoaded: true }))
  ),
  on(UpdateUserPreferencesComplete, (state, { update }) => adapter.updateOne(update, state))
)

export function userPreferencesReducer(state: State | undefined, action: Action) {
  return reducer(state, action)
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors()
