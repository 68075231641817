import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AddGuestDialogComponent } from '@tv3/containers/guests/dialogs/add/add-guest-dialog.component'

@Injectable()
export class AddGuestDialogService {
  constructor(private dialog: MatDialog) {}

  open() {
    const defaultConfig: MatDialogConfig = {
      width: '750px',
      height: 'auto',
      panelClass: ['fixed-modal'],
    }

    return this.dialog.open(AddGuestDialogComponent, defaultConfig)
  }
}
