import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Inquiry, isBookingFromAirbnb, isInquiryFromAirbnb } from '@tv3/store/inquiry/inquiry.model'
import { Store, select } from '@ngrx/store'
import { isCancelingBooking, isConfirmingBooking } from '@tv3/store/inquiry/inquiry.selectors'
import { holiduCancelReasons, AirbnbChannelService } from '@tokeet-frontend/channels'
import {
  Destroyable,
  ConfirmDialogService,
  Toaster,
  Rental,
  selectRentalById,
  untilDestroy,
} from '@tokeet-frontend/tv3-platform'
import { finalize, switchMap, filter } from 'rxjs/operators'
import {
  CancelBooking,
  ConfirmBooking,
  InquiryIsUpdated,
  InquiryUpdateComplete,
  CancelBookingWithReason,
} from '@tv3/store/inquiry/inquiry-fields.actions'
import { isChannelCancelable } from '@tokeet-frontend/inquiry'
import { DeclineAirbnbBookingDialogService } from '@tv3/containers/inquiries/overlays/decline-airbnb-booking-dialog/decline-aibrnb-booking-dialog.service'
import { CancelAirbnbBookingDialogService } from '@tv3/containers/inquiries/overlays/cancel-airbnb-booking-dialog/cancel-airbnb-booking-dialog.service'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-book-btn',
  templateUrl: './book-btn.component.html',
  styleUrls: ['./book-btn.component.scss'],
})
export class BookBtnComponent extends Destroyable implements OnInit, OnChanges {
  _inquiry: Inquiry
  get inquiry() {
    return this._inquiry
  }
  @Input() set inquiry(inquiry: Inquiry) {
    this._inquiry = inquiry
    this.isAirbnbAPIBooking = isBookingFromAirbnb(inquiry)
    this.isAirbnbAPIInquiry = isInquiryFromAirbnb(inquiry)
  }

  @Input() isLoading = false
  @Input() btnSm = false

  rental: Rental

  isAirbnbAPIBooking = false
  isAirbnbAPIInquiry = false
  isAirbnbListingLinked = false

  get bookingStatus() {
    return this.inquiry?.status ?? null
  }

  get isBooked() {
    return this.inquiry?.booked ?? false
  }

  get isCanceled() {
    return this.inquiry?.canceled ?? false
  }

  get isReadOnlyBooking() {
    return this.inquiry?.inquirySource === 'marriott'
  }

  get isVrbo() {
    return /vrbo/.test(this.inquiry?.inquirySource)
  }

  get isHolidu() {
    return /holidu/.test(this.inquiry?.inquirySource)
  }

  isCanceling$ = this.store.pipe(select(isCancelingBooking))
  isConfirmingBooking$ = this.store.pipe(select(isConfirmingBooking))

  holiduCancelReasons = holiduCancelReasons

  rentalId$ = new BehaviorSubject<string>(null)
  constructor(
    private store: Store<any>,
    private toaster: Toaster,
    private confirmDialog: ConfirmDialogService,
    private airbnbChannelService: AirbnbChannelService,
    private declineAirbnbBookingDialog: DeclineAirbnbBookingDialogService,
    private cancelAirbnbBookingDialog: CancelAirbnbBookingDialogService
  ) {
    super()
  }

  ngOnInit(): void {
    this.rentalId$
      .pipe(
        filter((rentalId) => !!rentalId),
        switchMap((rentalId) => this.store.pipe(select(selectRentalById(rentalId)))),
        untilDestroy(this)
      )
      .subscribe((rental) => {
        this.rental = rental
        this.isAirbnbListingLinked = !!rental?.abb_listing_id
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inquiry?.currentValue) {
      this.rentalId$.next(this.inquiry.rentalId)
    }
  }

  onConfirmBooking() {
    this.store.dispatch(ConfirmBooking({ inquiry: this.inquiry }))
  }

  onAcceptAirbnbBooking() {
    this.isLoading = true
    this.airbnbChannelService
      .acceptBooking(this.inquiry.inquiryId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.toaster.success('Booking accepted successfully.')
          this.store.dispatch(InquiryUpdateComplete({ update: { id: this.inquiry.id, changes: { status: 'accept' } } }))
          this.store.dispatch(InquiryIsUpdated({ update: { id: this.inquiry.id, changes: { status: 'accept' } } }))
        },
        (error) => {
          this.toaster.error('Unable to accept booking', '', error)
        }
      )
  }

  onDeclineAirbnbBooking() {
    this.declineAirbnbBookingDialog.open(this.inquiry, this.rental)
  }

  onCancelAirbnbBooking() {
    this.cancelAirbnbBookingDialog.open(this.inquiry, this.rental)
  }

  onCancelBooking(byGuest = false) {
    if (this.isAirbnbAPIBooking && this.isAirbnbListingLinked) {
      this.onCancelAirbnbBooking()
      return
    }

    const additional = isChannelCancelable(this.inquiry.inquirySource)
      ? ''
      : 'Please note that this will only affect the booking in AdvanceCM and cancellation will be necessary on the originating channel.'
    this.confirmDialog
      .confirm({
        title: 'Cancel booking?',
        body: `Are you sure you want to cancel this booking? ${additional}`,
      })
      .subscribe(() => {
        this.store.dispatch(CancelBooking({ inquiry: this.inquiry, byGuest }))
      })
  }

  onCancelBookingWithReason(reason: string) {
    const additional = isChannelCancelable(this.inquiry.inquirySource)
      ? ''
      : 'Please note that this will only affect the booking in AdvanceCM and cancellation will be necessary on the originating channel.'
    this.confirmDialog
      .confirm({
        title: 'Cancel booking?',
        body: `Are you sure you want to cancel this booking? ${additional}`,
      })
      .subscribe(() => {
        this.store.dispatch(CancelBookingWithReason({ inquiry: this.inquiry, reason }))
      })
  }
}
