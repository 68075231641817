import { Branding } from '@tv3/models/account/branding'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as reducer from './preferences.reducer'
import * as state from './preferences.state'
import * as R from 'ramda'

export const preferenceState = createFeatureSelector<state.State>('preference')

const selectAllPreferences = createSelector(preferenceState, reducer.selectAll)

export const selectAccountPreference = createSelector(selectAllPreferences, (preferences) => R.head(preferences))

export const selectPreferencesLoaded = createSelector(preferenceState, (state: state.State) => {
  return state.isLoaded
})

export const selectDoubleBookingPreference = createSelector(selectAccountPreference, (preferences) => {
  return preferences.doubleBooking
})

export const selectIsDoubleBookingDisabled = createSelector(selectDoubleBookingPreference, (doubleBooking) => {
  return doubleBooking !== 1
})

export const selectBrandingPreference = createSelector(selectAccountPreference, (preferences) => {
  return preferences.branding || new Branding()
})
