import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects'
import { ActionFailed, Toaster } from '@tokeet-frontend/tv3-platform'
import { of } from 'rxjs'
import { switchMap, catchError, map, tap } from 'rxjs/operators'
import { LoadMessagingChannelConfigs } from '.'
import {
  DisableMessagingChannel,
  DisconnectMessagingChannel,
  DisconnectMessagingChannelComplete,
  EnableMessagingChannel,
  EnableMessagingChannelComplete,
  LoadMessagingChannelConfigsComplete,
} from './actions'
import { MessagingChannelsService } from './service'

@Injectable()
export class MessagingChannelEffects {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(LoadMessagingChannelConfigs),
    switchMap(() =>
      this.messaging.fetchChannels().pipe(
        map((data) => LoadMessagingChannelConfigsComplete({ data })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  enable = this.actions$.pipe(
    ofType(EnableMessagingChannel),
    switchMap(({ channel }) =>
      this.messaging.enableChannel(channel).pipe(
        tap(() => this.toaster.success('Channel enabled successfully')),
        map(() => EnableMessagingChannelComplete({ channel })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  disable = this.actions$.pipe(
    ofType(DisableMessagingChannel),
    switchMap(({ channel }) =>
      this.messaging.unsubscribe(channel).pipe(
        tap(() => this.toaster.success('Channel disabled successfully')),
        map(() => DisableMessagingChannel({ channel })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  @Effect()
  disconnect = this.actions$.pipe(
    ofType(DisconnectMessagingChannel),
    switchMap(({ channel }) =>
      this.messaging.unsubscribe(channel).pipe(
        tap(() => this.toaster.success('Channel disconnected successfully')),
        map(() => DisconnectMessagingChannelComplete({ channel })),
        catchError((error) => of(ActionFailed({ error })))
      )
    )
  )

  constructor(private toaster: Toaster, private actions$: Actions, private messaging: MessagingChannelsService) {}
}
