import { Component, Inject, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as fromRoot from '@tv3/store/state'
import { Channel, DataCheckerService, SaveForm, selectAllChannels } from '@tokeet-frontend/tv3-platform'
import { ChannelGuard } from '@tv3/guards/channel.guard'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { RentalsUnitedChannelService } from '@tokeet-frontend/channels'
import { environment } from '@tv3/environments/environment'
import { AuthService } from '@tv3/services/auth.service'

export interface SelectChannelDialogConfig {
  title?: string
  help?: string
  buttonIcon?: string
  buttonText?: string
}

@Component({
  selector: 'app-select-channel-dialog',
  templateUrl: './select-channel-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./select-channel-dialog.component.scss'],
})
export class SelectChannelDialogComponent implements OnInit {
  config = {
    title: 'Select Channel',
    help: 'Please select a channel from the list above.',
    buttonText: 'Select',
  } as SelectChannelDialogConfig

  form = this.fb.group({
    channel: ['', [Validators.required]],
  })

  /**
   * On September 30th AirBnb will be blocking any connection not authorized through the official API.
   * This means that users who are currently connected will be disabled on this date.
   * In order to prevent users from creating new AirBnb connections on this discontinued API we need to remove it as an option from the Add Channel list.
   *
   * Handler for version 1 is Handlers::ABBHandler
   * Handler for version 2 is Handlers::ABBV2Handler
   */
  channels$ = this.store.pipe(
    select(selectAllChannels),
    map((channels) => R.filter((c: Channel) => c.handler !== 'Handlers::ABBHandler', channels)),
    map((channels) =>
      R.sort(
        (a: Channel, b: Channel) =>
          R.pathOr('', ['friendlyName'], a)
            .toString()
            .localeCompare(R.pathOr('', ['friendlyName'], b), undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
        channels
      )
    )
  )

  constructor(
    public dialogRef: MatDialogRef<SelectChannelDialogComponent>,
    private fb: FormBuilder,
    private auth: AuthService,
    private store: Store<fromRoot.State>,
    private dataChecker: DataCheckerService,
    private rentalsUnited: RentalsUnitedChannelService,
    @Inject(MAT_DIALOG_DATA) public data: { config: SelectChannelDialogConfig }
  ) {
    this.dataChecker.check([ChannelGuard])
  }

  ngOnInit() {
    this.config = { ...this.config, ...this.data.config }
  }

  close() {
    this.dialogRef.close()
  }

  @SaveForm()
  save(form: FormGroup) {
    const formValues = form.getRawValue()
    this.dialogRef.close(formValues.channel)
  }
}
