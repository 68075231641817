import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as R from 'ramda'
import { ENVIRONMENT } from '../../tokens'
import {
  Channel,
  ChannelNameTokens,
  tokeetDashboardChannel,
  tokeetWebsitesChannel,
  tokeetWidgetChannel,
} from './channel.model'
import {
  AddAirBnBV1ChannelPayload,
  AddFlipKeyChannelPayload,
  AddPPIDChannelPayload,
  ConnectedChannelAccountResponse,
  UpdateAirBnBV1ChannelPayload,
} from './channel.types'
import { ChannelResponse } from './channel.interfaces'
import { deserializeArray } from '../../functions/deserialize-array'

@Injectable()
export class ChannelService {
  defaultChannels: Channel[] = [tokeetWebsitesChannel, tokeetDashboardChannel, tokeetWidgetChannel]

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment) {}

  static isAirBnBV1(channelName: ChannelNameTokens) {
    return channelName === ChannelNameTokens.AirBnBAPI
  }

  static isAirBnBV2(channelName: ChannelNameTokens) {
    return channelName === ChannelNameTokens.AirBnBV2API
  }

  static isBookingAPI(channelName: ChannelNameTokens) {
    return channelName === ChannelNameTokens.BookingAPI
  }

  getAirBnBV2AuthorizationUrl(accountId: number) {
    const { airbnbOAuthUrl, airbnbClientId, airbnbRedirectUrl } = this.environment.config
    return `${airbnbOAuthUrl}&client_id=${airbnbClientId}&state=${accountId}&redirect_uri=${encodeURI(
      airbnbRedirectUrl
    )}`
  }

  all(): Observable<Channel[]> {
    const url = '@api/channels/'
    return this.http.get<ChannelResponse[]>(url).pipe(
      map((channels) => R.filter((c) => c.token !== 'airbnb', channels)),
      map((channels) =>
        R.map((c) => {
          if (c.token === 'airbnbapiv2') {
            return {
              ...c,
              friendlyName: 'Airbnb API',
            }
          } else {
            return c
          }
        }, channels)
      ),
      deserializeArray<Channel>(Channel)
    )
  }

  addApiChannel(payload: AddPPIDChannelPayload) {
    const url = '@api/channel/config/api'
    return this.http.post(url, payload)
  }

  addFlipKeyChannel(payload: AddFlipKeyChannelPayload) {
    const url = '@api/flipkey/config/account'
    return this.http.post(url, payload)
  }

  verifyFlipKeyChannel(payload: AddFlipKeyChannelPayload) {
    const url = '@api/flipkey/verify/account'
    return this.http.post(url, payload)
  }

  addAirBnBV1Channel(payload: AddAirBnBV1ChannelPayload) {
    const url = '@api/abb/config/account'
    return this.http.post(url, payload)
  }

  updateAirBnBV1Channel(payload: UpdateAirBnBV1ChannelPayload) {
    const url = '@api/abb/update/account'
    return this.http.post(url, payload)
  }

  getConnectedAccounts(channelId: string): Observable<ConnectedChannelAccountResponse[]> {
    const url = `@api/channel/accounts/${channelId}`
    return this.http.get<ConnectedChannelAccountResponse[]>(url)
  }

  // This method unlinks all rentals and deletes the channel in the Channel Manager.
  disconnect(channelId: string) {
    const url = `@api/channel/${channelId}`
    return this.http.delete(url)
  }

  disconnectABB(channelId: string) {
    const url = `@api/abb/${channelId}`
    return this.http.delete(url)
  }

  disconnectABBV2(channelId: string) {
    const url = `@api/abbv2/${channelId}`
    return this.http.delete(url)
  }

  channelFromEmail(email: string): string | false {
    const channels = [
      { domain: 'messages.homeaway.com', channel: 'homeaway' },
      { domain: 'flipkey.com', channel: 'flipkey' },
      { domain: 'tripadvisor.com', channel: 'flipkey' },
      { domain: 'messages.homeaway.com', channel: 'homeaway' },
      { domain: 'holidaylettings.', channel: 'flipkey' },
    ]

    const numChannels = channels.length

    for (let i = 0; i < numChannels; i++) {
      if (email.indexOf(channels[i].domain) > 0) {
        return channels[i].channel
      }
    }

    return false
  }
}
