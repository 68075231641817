import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import {
  CreateExpensePayload,
  ExpenseApprovalSettings,
  ExpenseApprovalStatus,
  ExpenseView,
  IExpenseFilters,
  UpdateExpensePayload,
  UpdateExpenseSettingsPayload,
} from './expense.model'
import { Pagination } from '../../types/pagination'

export const LoadExpenses = createAction(
  '[Expense] Load Expenses',
  props<{ filters?: IExpenseFilters; pagination?: Pagination }>()
)
export const LoadExpensesComplete = createAction(
  '[Expense] Load Expenses Complete',
  props<{ expenses: ExpenseView[] }>()
)

// load expenses by ids
export const LoadExpensesByIds = createAction('[Expense] Load Expenses by ids', props<{ ids: string[] }>())
export const LoadExpensesByIdsComplete = createAction(
  '[Expense] Load Expenses by ids Complete',
  props<{ expenses: ExpenseView[] }>()
)

// todo: remove. left just for compatibility
export const LoadFilteredExpenses = createAction(
  '[Expense] Load Filtered Expenses',
  props<{ filters: IExpenseFilters }>()
)
export const LoadFilteredExpensesComplete = createAction(
  '[Expense] Load Filtered Expenses Complete',
  props<{ expenses: ExpenseView[] }>()
)
// ---

export const AddExpense = createAction('[Expense] Add Expense', props<{ payload: CreateExpensePayload }>())
export const AddExpenseComplete = createAction('[Expense] Add Expense Complete', props<{ expense: ExpenseView }>())

export const AddExpenses = createAction('[Expense] Add Expenses', props<{ items: CreateExpensePayload[] }>())
export const AddExpensesComplete = createAction('[Expense] Add Expenses Complete', props<{ expenses: ExpenseView[] }>())

export const UpdateExpense = createAction(
  '[Expense] Update Expense',
  props<{ payload: UpdateExpensePayload; expenseId: string }>()
)
export const UpdateExpenseComplete = createAction(
  '[Expense] Update Expense Complete',
  props<{ update: Update<ExpenseView> }>()
)

export const UpdateExpenseApprovalStatus = createAction(
  '[Expense] Update Expense ApprovalStatus',
  props<{ status: ExpenseApprovalStatus; expenseId: string }>()
)
export const UpdateExpenseApprovalStatusComplete = createAction(
  '[Expense] Update Expense ApprovalStatus Complete',
  props<{ update: Update<ExpenseView> }>()
)

export const DeleteExpense = createAction('[Expense] Delete Expense', props<{ id: string }>())
export const DeleteExpenseComplete = createAction('[Expense] Delete Expense Complete', props<{ id: string }>())

export const DeleteExpenses = createAction('[Expense] Delete Expenses', props<{ ids: string[] }>())
export const DeleteExpensesComplete = createAction('[Expense] Delete Expenses Complete', props<{ ids: string[] }>())

export const LoadExpenseSettings = createAction('[Expense] Load Expense settings')
export const LoadExpenseSettingsComplete = createAction(
  '[Expense] Load Expense Settings Complete',
  props<{ settings: ExpenseApprovalSettings }>()
)

export const UpdateExpenseSettings = createAction(
  '[Expense] Update Expense settings',
  props<{ payload: UpdateExpenseSettingsPayload }>()
)
export const UpdateExpenseSettingsComplete = createAction(
  '[Expense] Update Expense Settings Complete',
  props<{ settings: ExpenseApprovalSettings }>()
)
