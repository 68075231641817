import { Component, Inject, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ChannelsService } from '@tokeet-frontend/channels'
import { VrboListingFee } from 'libs/channels/src/lib/store/vrbo/model'
import { Store } from '@ngrx/store'
import { selectRentalById, selectSomeOnce } from '@tokeet-frontend/tv3-platform'
import { OpenRentalOverlay } from '@tv3/store/overlay/overlay.actions'
import { RentalDialogTab } from '@tv3/containers/rentals/overlays/rental-overlay/rental-overlay.component'

@Component({
  selector: 'app-vrbo-fees-dialog',
  templateUrl: './vrbo-fees-dialog.component.html',
  styleUrls: ['./vrbo-fees-dialog.component.css'],
  host: { class: 'modal-content' },
})
export class VrboFeesDialogComponent implements OnInit {
  fees: VrboListingFee[] = []
  form = this.fb.group({ fees: this.fb.array([]) })
  get feesForm() {
    return this.form.get('fees') as FormArray
  }
  loading = true
  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    public dialogRef: MatDialogRef<VrboFeesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rentalId: string },
    private channelsService: ChannelsService
  ) {}

  ngOnInit(): void {
    this.channelsService.getRentalFeesVrbo(this.data.rentalId).subscribe((fees) => {
      this.loading = false
      this.fees = fees
      this.feesForm.clear()
      this.fees.forEach((fee) => {
        this.feesForm.push(this.newFee(fee))
      })
      this.form.disable()
    })
  }

  newFee(fee: VrboListingFee): FormGroup {
    return this.fb.group({
      name: [fee.name],
      amount: [fee.amount],
      modality: [fee.modality],
      type: [fee.type],
    })
  }

  onEditFees() {
    this.store.pipe(selectSomeOnce(selectRentalById(this.data.rentalId))).subscribe((rental) => {
      this.store.dispatch(OpenRentalOverlay({ rental, activeTab: RentalDialogTab.Fees }))
    })
  }

  close() {
    this.dialogRef.close()
  }
}
