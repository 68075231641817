import { Pipe, PipeTransform } from '@angular/core'
import { MinStayAutomation } from '@tokeet-frontend/rate-automations'
import { getISOWeekdays } from '@tokeet-frontend/tv3-platform'
import * as lodash from 'lodash'

@Pipe({
  name: 'minStayDec',
})
export class MinStayDecPipe implements PipeTransform {
  weekdays = getISOWeekdays()
  transform(minStay: MinStayAutomation): string {
    return lodash
      .chain(minStay.days)
      .map((t) => `${this.weekdays[t.dow - 1]}: ${t.min_nights} Nights`)
      .join(', ')
      .value()
  }
}
