import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component'
import { ConfirmDialogParams, ConfirmDialogStatus } from '../confirm-dialog.interfaces'
import { SaveForm } from '../../../decorators'

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  host: { class: 'modal-content' },
  styleUrls: ['./confirm-delete-dialog.component.scss'],
})
export class ConfirmDeleteDialogComponent {
  form = this.fb.group({
    confirm: ['', [Validators.required, Validators.pattern(/^DELETE$/)]],
  })

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogParams
  ) {}

  @SaveForm()
  confirm(form: FormGroup) {
    this.dialogRef.close(ConfirmDialogStatus.Confirmed)
  }

  cancel() {
    this.dialogRef.close(ConfirmDialogStatus.Canceled)
  }

  close() {
    this.dialogRef.close(ConfirmDialogStatus.Closed)
  }
}
