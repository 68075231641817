<div class="modal-header">
  <h5 class="modal-title"><i class="fal fa-user-edit"></i> Edit Guest</h5>
  <button (click)="close()" type="button" class="close">
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="guestForm">
    <div class="row">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <i class="cs-icon cs-icon-user" matPrefix></i>
          <input type="text" matInput required placeholder="Name" formControlName="name" />
          <mat-error *ngIf="guestForm.get('name').touched && guestForm.get('name').hasError('required')">
            Name is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
      <div (click)="editEmail()" [class.click-disabled]="1 | isReadonlyRole" class="col-sm-4 position-relative">
        <i
          matTooltip="Edit the primary email or add a secondary email to this guest."
          class="cs-icon cs-icon-pencil btn-edit"
        ></i>
        <mat-form-field2 class="mat-block">
          <mat-label> Email </mat-label>
          <i class="cs-icon cs-icon-envelope" matPrefix></i>
          <input type="email" matInput required placeholder="Email" formControlName="email" readonly />
          <mat-error *ngIf="guestForm.get('email').touched && guestForm.get('email').hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="guestForm.get('email').touched && guestForm.get('email').hasError('email')">
            Email must have valid format
          </mat-error>
        </mat-form-field2>
      </div>
      <div (click)="editPhone()" [class.click-disabled]="1 | isReadonlyRole" class="col-sm-4 position-relative">
        <i
          matTooltip="Edit the primary phone number or add a secondary phone number to this guest."
          class="cs-icon cs-icon-pencil btn-edit"
        ></i>
        <mat-form-field2 class="mat-block">
          <i class="cs-icon cs-icon-phone" matPrefix></i>
          <input type="text" formControlName="phone" matInput placeholder="Phone" readonly />
          <mat-error *ngIf="guestForm.get('phone').touched && guestForm.get('phone').hasError('required')">
            Phone is <strong>required</strong>
          </mat-error>
        </mat-form-field2>
      </div>
    </div>
    <div class="row" formGroupName="address">
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="address" matInput placeholder="Address" />
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="city" matInput placeholder="City" />
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="state" matInput placeholder="State" />
        </mat-form-field2>
      </div>
      <div class="col-sm-4">
        <app-country-select
          [nameCtrl]="addressForm.get('country')"
          [codeCtrl]="addressForm.get('country_code')"
        ></app-country-select>
      </div>
      <div class="col-sm-4">
        <mat-form-field2 class="mat-block">
          <input type="text" formControlName="postal_code" matInput placeholder="Postal Code" />
        </mat-form-field2>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-light"><i class="far fa-times"></i> Cancel</button>
  <button (click)="save(guestForm)" type="button" class="btn btn-secondary-info create-btn">
    <i class="cs-icon cs-icon-save"></i> save
  </button>
</div>
