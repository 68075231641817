import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Channel, ChannelNameTokens, Destroyable, untilDestroy } from '@tokeet-frontend/tv3-platform'
import { FeedConfig } from '../../../../store/channels.model'
import { getChannelConfig } from '../../../../store/channels.selectors'
import { UpdateChannelConfig } from '../../../../store/channels.actions'
import { take } from 'rxjs/operators'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-feed-channel-config',
  templateUrl: './feed-channel-config.component.html',
  styleUrls: ['./feed-channel-config.component.scss'],
})
export class FeedChannelConfigComponent extends Destroyable implements OnInit {
  @Input() channel: Channel
  @Input() primaryButtonClass = 'btn btn-secondary-info '
  config: FeedConfig

  @Output() save = new EventEmitter()

  channelNames = ChannelNameTokens

  constructor(private store: Store<any>) {
    super()
  }

  ngOnInit() {
    this.store.pipe(getChannelConfig(this.channel.name), take(1), untilDestroy(this)).subscribe((config) => {
      this.config = config
    })
  }

  onSave(payload) {
    this.store.dispatch(UpdateChannelConfig(payload))
    this.save.emit(true)
  }
}
