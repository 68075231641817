<div class="">
  <div class="d-flex align-items-center gap-3 mb-3">
    <h6 class="mb-0">Booking Fees</h6>
    <div class="flex-fill"></div>
    <button (click)="fees.onAdd()" class="btn btn-secondary-info" type="button">
      <i class="far fa-plus"></i>
      Add Fee
    </button>
    <div class="dropdown" dropdown>
      <button class="btn btn-secondary-primary dropdown-toggle" dropdownToggle type="button">
        <i class="fal fa-copy"></i>
        Copy
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li>
          <button
            (click)="onCopyFrom()"
            matTooltip="Ability to copy fees from rental(s) to your selected rental."
            matTooltipPosition="left"
          >
            <i class="fal fa-copy"></i> Copy from Rental
          </button>
        </li>
        <li>
          <button
            (click)="onCopyTo()"
            matTooltip="Ability to copy fees to rental(s) from your selected rental."
            matTooltipPosition="left"
          >
            <i class="fal fa-paste"></i> Copy to Rental
          </button>
        </li>
      </ul>
    </div>
    <button (click)="fees.save()" [disabled]="fees.processing" type="button" class="btn btn-secondary-info create-btn">
      <i *ngIf="!fees.processing" class="cs-icon cs-icon-save"></i>
      <i *ngIf="fees.processing" class="far fa-spinner fa-spin"></i> Save
    </button>
  </div>
  <div class="alert alert-secondary-info">
    <i class="fal fa-info-circle alert-icon"></i>
    <div class="alert-content">
      Change fees associated with this rental. These adjustments will apply to any booking not imported from channels
      where these fees are charged to the guest up front.
    </div>
  </div>
  <app-booking-fees [rental]="rental" #fees class=""></app-booking-fees>
</div>
